import React from 'react';
import cx from 'classnames';
import { Box } from '@material-ui/core';
import SyntaxHighlighter, {
  SyntaxHighlighterProps
} from 'react-syntax-highlighter';
import { atelierForestLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyButton as DSCopyButton } from '@design-system-outdated/components';
import classNames from './SyntaxHighlighter.module.scss';
import { ReactElementLike } from 'prop-types';

export const DS_SYNTAX_TYPE = {
  DEFAULT: 'default',
  SECONDARY: 'secondary'
} as const;

type DSType = typeof DS_SYNTAX_TYPE[keyof typeof DS_SYNTAX_TYPE];

const DS_SYNTAX_TYPE_CLASSES = {
  [DS_SYNTAX_TYPE.DEFAULT]: classNames.default,
  [DS_SYNTAX_TYPE.SECONDARY]: classNames.secondary
};

const WITHOUT_INLINE_STYLES: DSType[] = [DS_SYNTAX_TYPE.SECONDARY];

interface DsSyntaxHighlighterProps extends SyntaxHighlighterProps {
  type?: DSType;
  copyIcon?: ReactElementLike;
}

const DsSyntaxHighlighter = ({
  code,
  withCopyBtn,
  children,
  language,
  fullWidth = false,
  style,
  type = DS_SYNTAX_TYPE.DEFAULT,
  showLineNumbers = true,
  copyIcon,
  customStyle,
  className,
  ...restProps
}: DsSyntaxHighlighterProps) => {
  const useInlineStyles = !WITHOUT_INLINE_STYLES.includes(type);

  const renderCopyButton = () => {
    return (
      <DSCopyButton
        message="Successfully copied code"
        text={code}
        tooltipText="Copy code"
        icon={copyIcon}
      />
    );
  };

  const highlighterClasses = cx('ds-syntax-highlighter', className, {
    'full-width': fullWidth
  });

  return (
    <Box
      className={cx(
        classNames.syntaxHighlighterWrapper,
        DS_SYNTAX_TYPE_CLASSES[type]
      )}
    >
      <SyntaxHighlighter
        language={language || 'python'}
        showLineNumbers={showLineNumbers}
        style={style || atelierForestLight}
        useInlineStyles={useInlineStyles}
        customStyle={customStyle}
        {...restProps}
        className={highlighterClasses}
      >
        {children}
      </SyntaxHighlighter>
      {withCopyBtn && code && renderCopyButton()}
    </Box>
  );
};
export default DsSyntaxHighlighter;
