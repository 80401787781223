import { connect } from 'react-redux';
import {
  FORM_FORGOT_PASSSWORD_FILL_EMAIL,
  FORM_FORGOT_PASSSWORD_FILL_PASSWORD
} from '@/constants/formTypes';

const mapStateToProps = state => {
  const { step, token, error, email, buttonText } = state.forgotPassword;
  const emailForm = state.form[FORM_FORGOT_PASSSWORD_FILL_EMAIL];
  const passwordForm = state.form[FORM_FORGOT_PASSSWORD_FILL_PASSWORD];
  return {
    step,
    token,
    emailForm,
    passwordForm,
    error,
    email,
    buttonText
  };
};

const withForgotPassword = connect(mapStateToProps, null);

export default withForgotPassword;
