export const uniqueRowId = 'id';

export const DEFAULT_FEATURES_SORTING = [
  { columnName: 'name', direction: 'desc' }
];

export const FEATURE_SERVER_COLUMNS_NAMES = {
  name: 'feature_key',
  featureType: 'feature_type',
  metricType: 'feature_val_type',
  drift: 'drift',
  missingValues: 'missing_values'
};

export enum FEATURE_TYPES {
  CATEGORICAL = 'CATEGORICAL',
  NUMERICAL = 'NUMERICAL'
}

export enum SOURCE_TYPE {
  model_input_features = 'model_input_features',
  model_output_features = 'model_output_features'
}

export const FEATURE_LEFT_COLUMNS = ['name'];

export const FEATURE_PAGE_SIZES = [10, 25, 50, 100];

export const FEATURE_MODEL_NAME_COLUMN_WIDTH = 298;

export const AUTO_COMPLETER_RULES = {
  FUNCTIONS: 'FUNCTIONS',
  FEATURES: 'FEATURES'
} as const;

export const FEATURE_COLUMNS = [
  {
    name: 'name',
    title: 'Name',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'name',
    hideForFilters: false,
    hideForSorting: false
  },
  {
    name: 'featureType',
    title: 'Feature Type',
    id: 'source--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'source',
    hideForFilters: true,
    hideForSorting: false
  },
  {
    name: 'metricType',
    title: 'Metric Type',
    id: 'type--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'type',
    hideForFilters: true,
    hideForSorting: false
  }
  // {
  //   name: 'drift',
  //   title: 'Drift',
  //   id: 'drift--metadata',
  //   type: 'double',
  //   source: 'metadata',
  //   serverField: 'drift',
  //   hideForFilters: true,
  //   hideForSorting: true
  // },
  // {
  //   name: 'missingValues',
  //   title: 'Missing Values',
  //   id: 'missingValues--metadata',
  //   type: 'double',
  //   source: 'metadata',
  //   serverField: 'missing_values',
  //   hideForFilters: true,
  //   hideForSorting: true
  // }
];

export const badgeColorsConfig = {
  input: { color: '#191a1c', backgroundColor: '#e5e5fe' },
  output: { color: '#191a1c', backgroundColor: '#fee8d7' }
};
