import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { Input } from '@DesignSystem/controllers';
import { WEBHOOK_AUTHORIZATION_HEADER_KEY } from '@model-registry/constants/webhooks';

const ModelRegistryHeadersFields = ({
  dataTest,
  keyName,
  value,
  setHeaders
}) => {
  const isInValid =
    String(keyName).toLocaleLowerCase() ===
    WEBHOOK_AUTHORIZATION_HEADER_KEY.toLocaleLowerCase();

  return (
    <Grid
      container
      spacing={1}
      alignContent="flex-start"
      alignItems="flex-start"
      data-test={`${dataTest}-headers-item`}
    >
      <Grid item xs={6}>
        <Input
          {...{
            'data-test': `${dataTest}-key`
          }}
          onChange={val =>
            setHeaders({
              key: val
            })
          }
          invalidBorder={isInValid}
          invalidMsg={
            isInValid && 'Authorization should be set using the Secret field'
          }
          name="key"
          value={keyName}
          placeholder="Enter key"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          {...{
            'data-test': `${dataTest}-value`
          }}
          onChange={val =>
            setHeaders({
              value: val
            })
          }
          name="value"
          value={value}
          placeholder="Enter key"
        />
      </Grid>
    </Grid>
  );
};

ModelRegistryHeadersFields.defaultProps = {
  keyName: '',
  dataTest: '',
  value: ''
};

ModelRegistryHeadersFields.propTypes = {
  dataTest: PropTypes.string,
  keyName: PropTypes.string,
  value: PropTypes.string,
  setHeaders: PropTypes.func.isRequired
};

export default ModelRegistryHeadersFields;
