// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoveModelVersionModal-module__moveModelVersionsModal--xziIq{width:460px}.MoveModelVersionModal-module__moveModelVersionsModal--xziIq .select-label{font-size:14px;font-weight:400}.MoveModelVersionModal-module__moveModelVersionsModal--xziIq .select-header{font-size:14px !important}.MoveModelVersionModal-module__moveModelVersionsModalNoModels--_QFph{font-size:12px;margin-top:10px;margin-bottom:10px}.MoveModelVersionModal-module__moveModelVersionsModalLabel--QJB74{color:var(--gray-5);font-weight:400;font-size:14px;margin-bottom:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moveModelVersionsModal": `MoveModelVersionModal-module__moveModelVersionsModal--xziIq`,
	"moveModelVersionsModalNoModels": `MoveModelVersionModal-module__moveModelVersionsModalNoModels--_QFph`,
	"moveModelVersionsModalLabel": `MoveModelVersionModal-module__moveModelVersionsModalLabel--QJB74`
};
module.exports = ___CSS_LOADER_EXPORT___;
