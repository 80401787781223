import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButton, {
  RADIO_SIZE
} from '@DesignSystem/controllers/RadioButton/RadioButton';
import OperatorListItem from './OperatorListItem';

const OperatorListGroup = ({
  selectedGroupOperator,
  operator,
  renderInputField
}) => {
  const showGroup = !!selectedGroupOperator;

  return (
    <FormControlLabel
      className="operator-list-item"
      tabIndex="-1"
      value={selectedGroupOperator?.operator ?? operator.operator}
      data-test="operator-list-group"
      control={<RadioButton />}
      label={
        <div className="group-list-class">
          <div className="filter-condition-row-text">{operator.label}</div>
          {showGroup &&
            operator.operators.map(o => {
              return (
                <OperatorListItem
                  key={o.operator}
                  disabled={o.operator !== selectedGroupOperator?.operator}
                  showLabel={false}
                  radioSize={RADIO_SIZE.SMALL}
                  operator={o}
                  renderInputField={renderInputField}
                />
              );
            })}
        </div>
      }
    />
  );
};

OperatorListGroup.defaultProps = {
  selectedGroupOperator: null
};

OperatorListGroup.propTypes = {
  selectedGroupOperator: PropTypes.object,
  operator: PropTypes.object.isRequired,
  renderInputField: PropTypes.func.isRequired
};

export default OperatorListGroup;
