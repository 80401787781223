import { useMiddleTruncation, useRefSizes } from '@shared/hooks';
import React from 'react';
import { Tooltip } from '@ds';

import styles from './ChangeColorButton.module.scss';

type ChangeColorMetricNameProps = {
  metricName: string;
};
const ChangeColorMetricName: React.FC<ChangeColorMetricNameProps> = ({
  metricName
}) => {
  const { refWidth, sizeRef } = useRefSizes();
  const { truncatedValue, isTextTruncated } = useMiddleTruncation({
    text: metricName,
    maxWidth: refWidth
  });
  return (
    <div ref={sizeRef} className={styles.metricName}>
      {isTextTruncated ? (
        <Tooltip wrapperDisplay="inline-flex" content={metricName}>
          <>{truncatedValue}</>
        </Tooltip>
      ) : (
        <>{truncatedValue}</>
      )}
    </div>
  );
};

export default ChangeColorMetricName;
