import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import organizationApi from '@shared/api/organizationApi';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';

const useOrganizationSettingsUpdateMutation = (organizationId, settingName) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    value =>
      organizationApi.updateDefaultOrganizationSettings({
        name: settingName,
        value,
        organizationId
      }),
    {
      onSuccess: async () => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_DELETE_ALERT_RULE,
            'Successfully updated setting for workspaces'
          )
        );
        await queryClient.invalidateQueries([
          'organizationPermissions',
          { organizationId }
        ]);
      },
      onError: async () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error updating organization workspaces settings.'
          )
        );
      }
    }
  );
};

export default useOrganizationSettingsUpdateMutation;
