import React from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@ds';
import { DSRefreshIcon } from '@ds-icons';

const ManualRefreshBtn = props => (
  <TextButton type="secondary" PrefixIcon={<DSRefreshIcon />} {...props}>
    Refresh
  </TextButton>
);

ManualRefreshBtn.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ManualRefreshBtn;
