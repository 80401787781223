import { EmptyExperimentTab } from '@shared';
import React from 'react';

const TextTabEmpty = () => (
  <EmptyExperimentTab>
    <EmptyExperimentTab.Icon iconName="text" />
    <EmptyExperimentTab.Title>
      This experiment did not log any text samples
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      Use <b>log_text()</b> on your Experiment object to report text samples
      during experiments.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/text/" />
  </EmptyExperimentTab>
);

export default TextTabEmpty;
