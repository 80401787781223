import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import './Input.scss';

const InputAutoGrow = ({
  placeholder,
  initialValue,
  onChange,
  required,
  title,
  invalidMsg
}) => {
  const editableRef = useRef();

  useEffect(() => {
    if (editableRef && initialValue) {
      editableRef.current.innerHTML = initialValue;
    }
  }, []);

  const handleChange = event => {
    const content = event.currentTarget.textContent;
    if (typeof content !== 'string') {
      event.preventDefault();
      return;
    }
    onChange(content);
  };

  return (
    <div className="ds-input-wrapper">
      {title && (
        <p className="ds-input-title">
          {title} {required && <span style={{ color: 'red' }}>*</span>}
        </p>
      )}
      <div
        id="editable-div"
        contentEditable
        className="ds-editable-content-input"
        placeholder={placeholder}
        onInput={handleChange}
        ref={editableRef}
      />
      {invalidMsg && <p className="invalid-label">{invalidMsg}</p>}
    </div>
  );
};

InputAutoGrow.defaultProps = {
  placeholder: '',
  initialValue: '',
  onChange: noop,
  title: null,
  required: false,
  invalidMsg: null
};

InputAutoGrow.propTypes = {
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  required: PropTypes.bool,
  invalidMsg: PropTypes.string
};

export default memo(InputAutoGrow);
