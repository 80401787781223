import React from 'react';
import classnames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';

export const SnackbarWrapper = props => (
  <Snackbar
    id="snackbar"
    className={classnames('snackbar-success', `snackbar-${props.keyclass}`)}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    data-test={`snackbar-${props.keyclass}`}
    {...props}
  />
);
