import React, { ForwardedRef, forwardRef } from 'react';
import IconButton, {
  IconButtonProps
} from '@design-system/components/buttons/IconButton/IconButton';
import { DSMoreOptionsVerticalIcon } from '@design-system/icons';
import { useActionMenuListContext } from './useActionMenuListContext';
import cx from 'classnames';
import styles from './ActionMenuList.module.scss';

type ActionMenuListTriggerProps = Omit<IconButtonProps, 'Icon' | 'ref'> & {
  Icon?: React.ReactNode;
};

const ActionMenuListTrigger = (
  { Icon, ...props }: ActionMenuListTriggerProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { openPopover, isPopoverOpened } = useActionMenuListContext();

  return (
    <IconButton
      onClick={openPopover}
      size="medium"
      Icon={Icon || <DSMoreOptionsVerticalIcon />}
      type="secondary"
      className={cx(styles.triggerButton, {
        [styles.active]: isPopoverOpened
      })}
      active={isPopoverOpened}
      ref={ref}
      {...props}
    />
  );
};

const ForwardedActionMenuListTrigger = forwardRef<
  HTMLButtonElement,
  ActionMenuListTriggerProps
>(ActionMenuListTrigger);
ForwardedActionMenuListTrigger.displayName = 'ActionMenuListTrigger';

export default ForwardedActionMenuListTrigger;
