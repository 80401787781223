import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  REPORTS_TABLE_COLUMN_HEADERS,
  REPORT_COLUMNS,
  SORT_BY_DIRECTION
} from '@reports/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../constants/colorConstants';

const StyledTableCell = withStyles({
  head: {
    color: 'white',
    backgroundColor: COLORS.primary,
    fontSize: 12
  },
  body: {
    fontSize: 12
  }
})(TableCell);

const StyledCheckboxHeaderCell = withStyles({
  head: {
    backgroundColor: COLORS.primary,
    fontSize: 12
  },
  body: {
    fontSize: 12
  }
})(TableCell);

const ReportsTableHeader = ({
  hasTemplates,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const getSortIcon = id => {
    if (orderBy !== id) return null;

    const SortIcon =
      order === SORT_BY_DIRECTION.ASC ? ArrowUpwardIcon : ArrowDownwardIcon;

    const style = { color: 'white', fontSize: 20, paddingLeft: 5 };

    return <SortIcon style={style} />;
  };

  const COLUMNS = REPORTS_TABLE_COLUMN_HEADERS.filter(column => {
    return hasTemplates ? column : column.id !== REPORT_COLUMNS.IS_TEMPLATE;
  });

  return (
    <TableHead>
      <TableRow>
        <StyledCheckboxHeaderCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            style={{ color: 'white' }}
          />
        </StyledCheckboxHeaderCell>
        {COLUMNS.map(({ id, label }) => (
          <StyledTableCell
            key={id}
            align="left"
            sortDirection={orderBy === id ? order : false}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : SORT_BY_DIRECTION.ASC}
              onClick={createSortHandler(id)}
              IconComponent={() => getSortIcon(id)}
              style={{ whiteSpace: 'nowrap', color: 'white' }}
            >
              {label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ReportsTableHeader.propTypes = {
  hasTemplates: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf([SORT_BY_DIRECTION.ASC, SORT_BY_DIRECTION.DESC])
    .isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default ReportsTableHeader;
