import api from '@shared/api';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { dialogTypes } from '../../../constants/alertTypes';
import { SORT_BY_DIRECTION } from '../../../constants/workspaceConstants';
import alertsUtil from '../../../util/alertsUtil';

const FILTER_NAME_MAP = {
  isPublic: 'public',
  isStarterProject: 'category',
  userName: 'owner'
};

const SORT_PARAM_NAME_MAP = {
  [SORT_BY_DIRECTION.ASC]: 'asc',
  [SORT_BY_DIRECTION.DESC]: 'desc'
};

const getCategoryFilterString = values => {
  return values.map(value => (value ? 'example' : 'workspace')).join(',');
};

/*
From:
  {
    "userName": ["fcarril", "nimrod2"],
    "isStarterProject": [true, false],
    "isPublic": [false, true]
  }
To:
  {
    "category": "example,workspace"
    "public": "true,false"
    "owner": "nimrod2,fcarril"
  }
*/
const getFiltersMap = filters => {
  const filtersMap = {};

  Object.entries(filters).forEach(([key, values]) => {
    if (values?.length === 0) return;

    const queryKey = FILTER_NAME_MAP[key];

    if (queryKey === 'category') {
      filtersMap[queryKey] = getCategoryFilterString(values);
    } else {
      filtersMap[queryKey] = values.join(',');
    }
  });

  return filtersMap;
};

const getProjects = async ({
  filters,
  pageSize,
  pageNumber,
  searchText,
  signal,
  sortByDirection,
  sortByProperty,
  workspaceName
}) => {
  const filtersMap = getFiltersMap(filters);
  const { data } = await api.get(`workspaces/${workspaceName}/projects`, {
    params: {
      count: pageSize,
      page: pageNumber + 1,
      sort: sortByProperty,
      order: SORT_PARAM_NAME_MAP[sortByDirection],
      search: searchText,
      ...filtersMap
    },
    signal
  });

  return data;
};

export default function useProjects(
  {
    filters,
    pageSize,
    pageNumber,
    searchText,
    sortByDirection,
    sortByProperty
  },
  config
) {
  const { workspace: workspaceName } = useParams();
  const dispatch = useDispatch();

  return useQuery(
    [
      'projects',
      {
        filters,
        pageSize,
        pageNumber,
        searchText,
        sortByDirection,
        sortByProperty,
        workspaceName
      }
    ],
    ({ signal }) =>
      getProjects({
        filters,
        pageSize,
        pageNumber,
        searchText,
        signal,
        sortByDirection,
        sortByProperty,
        workspaceName
      }),
    {
      keepPreviousData: true,
      refetchOnMount: true,
      retry: false,
      ...config,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your projects'
          )
        );
      }
    }
  );
}
