import isFunction from 'lodash/isFunction';
import flow from 'lodash/flow';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import uniqBy from 'lodash/uniqBy';
import isBoolean from 'lodash/isBoolean';
import isUndefined from 'lodash/isUndefined';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { ExperimentTemplate } from '@experiment-details/types';
import {
  calculateLockedState,
  generateEmptySection,
  generatePanelId,
  hasToRemoveTransformField,
  normalizePanel
} from '@experiment-management-shared';
import {
  getMaxLayoutY,
  initializeLayout,
  shiftLayoutByY
} from '@experiment-management-shared/utils/layout';
import {
  CURRENT_TEMPLATE_VERSION,
  DEFAULT_EXPERIMENT_DETAILS_TEMPLATE
} from '@experiment-details/constants/template';
import { DEFAULT_PANELS } from '@experiment-management-shared/utils/view';
import {
  DEFAULT_CHART_TEMPLATE_NAME,
  defaultFormFields,
  EXPERIMENT_VIEW_TAB_FIELDS
} from '@experiment-management-shared/constants/chartConstants';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import {
  COLOR_SINGLE_EXPERIMENT_KEY,
  METRIC_COLORS_V2
} from '@experiment-details/constants/panels';
import {
  Panel,
  PanelGlobalConfig,
  PanelGlobalConfigMap,
  PanelLayoutItem,
  PanelsLayout
} from '@experiment-management-shared/types';
import {
  DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE,
  EXPERIMENT_PANELS_GLOBAL_CONFIG_MAP,
  STEP
} from '@/lib/appConstants';

export type MigrationFunction = (
  template: ExperimentTemplate
) => ExperimentTemplate;

const LAYOUT_ID_KEY = 'i';

// This function is implemented because of limitation with introduction a new fields to template without changes on BE side
// the version field temporary is a part of panels object, and will migrate to separate field in rood of template object
const calculateTemplateVersion = (template: Partial<ExperimentTemplate>) => {
  return template?.panels?.version || 0;
};

export const normalizeTemplate = (template: ExperimentTemplate) => {
  let migratedTemplate = template;
  let version = calculateTemplateVersion(migratedTemplate);

  while (version < CURRENT_TEMPLATE_VERSION) {
    migratedTemplate = getMigrationFunction(version)(migratedTemplate);
    version = calculateTemplateVersion(migratedTemplate);
  }

  return migratedTemplate;
};

export const getMigrationFunction = (version: number) => {
  const migrationFunction = migrations[`templateMigrationV${version}`] as
    | MigrationFunction
    | undefined;

  if (isFunction(migrationFunction)) {
    return migrationFunction;
  }

  console.error(`Migration function for version ${version} is not defined`);
  return (template: ExperimentTemplate) => template;
};

export const getDefaultChartTitle = (metricNames: string[]) => {
  return metricNames.join(',');
};

const transformChartsToPanels = (template: ExperimentTemplate) => {
  const charts = template?.charts || [];

  const selectedOutliers = chartHelpers.calculateSelectedOutliers(
    template?.outliers?.isVisible
  );
  const selectedXAxis = template?.x_axis;
  const smoothingY = template?.smoothing;
  const transformY = template?.transformY || null;

  return charts.map(chart => {
    const chartType = chart?.type;

    const metricNames = [...chart.metricNames];
    const selectedYAxis = metricNames;
    const defaultSettings = get(defaultFormFields, chartType, {});
    const sampleSize = isUndefined(
      (defaultSettings as Partial<Panel>)?.sampleSize
    )
      ? null
      : DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE;

    return {
      ...defaultSettings,
      ...(sampleSize && { sampleSize }),
      chartName: getDefaultChartTitle(metricNames),
      chartType,
      chartId: chart?.chartId || generatePanelId(),
      metricNames,
      metrics: metricNames.map(metricName => ({ name: metricName })),
      selectedYAxis,
      selectedOutliers,
      selectedXAxis,
      smoothingY,
      transformY
    };
  });
};

// not empty, not autogenerated
export const isUserTemplate = (template: ExperimentTemplate) =>
  !!template?.template_id;

export const isAutoGeneratedTemplate = (template: ExperimentTemplate) =>
  !isUserTemplate(template) &&
  template.template_name === DEFAULT_CHART_TEMPLATE_NAME;

const combineLayouts = (layouts: PanelLayoutItem[][]) => {
  const resultLayout = layouts.reduce(
    (acc: PanelLayoutItem[], l) => acc.concat(l),
    []
  );

  return uniqBy(resultLayout, LAYOUT_ID_KEY);
};

export const getIsNeededRemoveCharts = (template: ExperimentTemplate) =>
  template?.charts?.length && isUserTemplate(template);

// we don't use compareExperimentColors anymore, the idea is to get rid of them
const normalizeMetricColorsV2 = (template: ExperimentTemplate) => {
  const isSingleExperimentColor = get(template, [
    METRIC_COLORS_V2,
    COLOR_SINGLE_EXPERIMENT_KEY,
    'isSingleExperimentColor'
  ]);

  const colors = get(
    template,
    [METRIC_COLORS_V2, COLOR_SINGLE_EXPERIMENT_KEY, 'colors'],
    []
  );

  return {
    ...template,
    [METRIC_COLORS_V2]: {
      [COLOR_SINGLE_EXPERIMENT_KEY]: {
        colors,
        isSingleExperimentColor,
        singleExperimentColor: isSingleExperimentColor
      }
    }
  };
};

export const removeSmoothingTransformYForTemplate = (
  template: ExperimentTemplate
) => {
  return hasToRemoveTransformField(template.transformY)
    ? { ...template, transformY: null }
    : template;
};

export const migrateConfusionMatrix = (template: ExperimentTemplate) => {
  const originalSection =
    template[EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX as 'confusionMatrix'];
  if (isObject(originalSection)) {
    const migratedSection = {
      ...DEFAULT_EXPERIMENT_DETAILS_TEMPLATE[
        EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX as 'confusionMatrix'
      ]
    };

    Object.keys(originalSection).forEach(key => {
      if (
        typeof migratedSection[key as never] ===
        typeof originalSection[key as never]
      ) {
        migratedSection[key as never] = originalSection[key as never];
      }
    });

    return {
      ...template,
      [EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX]: migratedSection
    } as ExperimentTemplate;
  }

  return template;
};

const migrations: Record<string, MigrationFunction> = {
  templateMigrationV0(template: ExperimentTemplate) {
    const isNeededToRemoveCharts = getIsNeededRemoveCharts(template);

    const initialChartLayout = template?.layout || [];
    const entireRowCharts = !!template?.entire_row_charts;
    const panels = template?.panels || {};
    const panelList = panels?.panels?.map(panel => normalizePanel(panel)) || [];

    const panelsFromCharts = transformChartsToPanels(template);
    const chartLayout = initializeLayout({
      panels: panelsFromCharts,
      initialLayout: initialChartLayout,
      entireRowCharts
    });

    const panelLayout = shiftLayoutByY(
      initializeLayout({
        panels: panelList,
        initialLayout: panels.layout,
        entireRowCharts
      }),
      getMaxLayoutY(chartLayout)
    );

    const processedTemplate = flow(
      removeSmoothingTransformYForTemplate,
      migrateConfusionMatrix,
      normalizeMetricColorsV2
    )(template);

    return {
      ...DEFAULT_EXPERIMENT_DETAILS_TEMPLATE,
      ...processedTemplate,
      charts: [],
      layout: DEFAULT_PANELS.layout,
      panels: {
        ...DEFAULT_EXPERIMENT_DETAILS_TEMPLATE[
          EXPERIMENT_VIEW_TAB_FIELDS.PANELS as 'panels'
        ],
        ...panels,
        layout: combineLayouts([chartLayout, panelLayout]),
        panels: [...panelsFromCharts, ...panelList],
        version: 1
      },
      autoSave: isNeededToRemoveCharts
    } as ExperimentTemplate;
  },

  templateMigrationV1(template: ExperimentTemplate) {
    const config = {
      selectedXAxis: template.x_axis,
      transformY: template.transformY,
      smoothingY: template.smoothing,
      selectedOutliers: chartHelpers.calculateSelectedOutliers(
        template?.outliers?.isVisible
      )
    } as PanelGlobalConfig;
    const panels = template.panels.panels || [];

    return {
      ...template,
      panels: {
        ...template.panels,
        panels: panels.map(panel => {
          const defaultFields = defaultFormFields[panel.chartType] ?? {};
          const normalizedPanel = {
            ...defaultFields,
            ...panel
          } as Panel;

          const locked = calculateLockedState(
            config,
            normalizedPanel,
            EXPERIMENT_PANELS_GLOBAL_CONFIG_MAP as PanelGlobalConfigMap
          );

          return {
            ...normalizedPanel,
            ...(isBoolean(locked) ? { locked } : {})
          } as Panel;
        }),
        version: 2
      }
    };
  },

  templateMigrationV2(template: ExperimentTemplate) {
    return {
      ...template,
      panels: {
        ...template.panels,
        compareXAxis: isUserTemplate(template) ? template.x_axis : STEP,
        version: 3
      }
    };
  },

  templateMigrationV3(template: ExperimentTemplate) {
    return {
      ...template,
      panels: {
        ...template.panels,
        sections: [
          {
            ...generateEmptySection(),
            autogenerated: isAutoGeneratedTemplate(template),
            panels: template.panels.panels,
            layout: template.panels.layout as PanelsLayout
          }
        ],
        version: 4
      }
    };
  },

  // Nested Metrics for Auto-Generated views
  templateMigrationV4(template: ExperimentTemplate) {
    if (!isAutoGeneratedTemplate(template)) {
      return { ...template, panels: { ...template.panels, version: 5 } };
    }

    let groupedPanels = groupBy(template.panels.sections[0].panels, panel => {
      const firstSelectedY = Array.isArray(panel.selectedYAxis)
        ? panel.selectedYAxis[0]
        : panel.selectedYAxis;

      const sectionParts = firstSelectedY.split('/');

      return sectionParts.length > 1 ? sectionParts[0] : '';
    });

    if (isEmpty(groupedPanels)) {
      groupedPanels = { '': [] };
    }

    const sections = Object.keys(groupedPanels)
      .sort()
      .map(sectionName => {
        const panels = groupedPanels[sectionName];

        return {
          ...generateEmptySection(),
          title: sectionName || 'Metrics',
          autogenerated: true,
          panels,
          layout: initializeLayout({ panels, initialLayout: null })
        };
      });

    return {
      ...template,
      panels: {
        ...template.panels,
        sections,
        version: 5
      }
    };
  }
};
