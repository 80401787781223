import TurndownService from 'turndown';

const turndownService = new TurndownService();
const htmlToMarkdown = html => {
  if (!html) return '';
  return turndownService.turndown(html);
};

const setDefaultState = oldState => {
  const newState = {
    projects: {},
    experiments: {}
  };
  if (oldState.projects) newState.projects = oldState.projects;

  if (oldState.experiments) newState.experiments = oldState.experiments;

  return newState;
};

const updateNoteInObject = (objectOfNotes, stateTarget, newNote) => {
  if (stateTarget === 'projects') {
    objectOfNotes[newNote.projectId] = newNote;
  } else {
    objectOfNotes[newNote.experimentKey] = newNote;
  }
  return objectOfNotes;
};

const notesSelectors = {
  appendNewNoteDataToExistingNotes(oldState, payload, stateTarget) {
    const newNote = payload.note;
    const newState = setDefaultState(oldState);

    const objectOfNotes = newState[stateTarget];
    const newObject = updateNoteInObject(objectOfNotes, stateTarget, newNote);

    newState[stateTarget] = newObject;
    return JSON.parse(JSON.stringify(newState));
  },

  manageNoteApiVersion(notes) {
    const notes_v1 = notes.msg;
    const { notes_v2 } = notes;
    let note = '';
    if (notes_v2 || notes_v2 === '') note = notes_v2;
    else if (notes_v1) note = htmlToMarkdown(notes_v1);

    return note;
  },

  cleanNotes() {
    const newState = setDefaultState({});
    return JSON.parse(JSON.stringify(newState));
  }
};

export default notesSelectors;
