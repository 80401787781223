import { SystemMetrics } from '@shared/types/systemMetrics';
import {
  getMetricParamsList,
  prepareMetricsTableRow,
  prepareMetricsTableRowFromLog
} from '../../utils';
import { ExperimentDetails } from '@shared/types';
import { SystemMetricsSingleTableRow } from '../../types';
import { ComparisonTableRow } from '@experiment-details/components/shared/ComparisonTable/types';

type useSystemMetricsSingleTableDataOpts = {
  experiments: ExperimentDetails[];
  systemMetrics: SystemMetrics[];
};
const useSystemMetricsComparisonTableData = ({
  experiments,
  systemMetrics
}: useSystemMetricsSingleTableDataOpts) => {
  let rowList: ComparisonTableRow[] = [];

  if (!systemMetrics.length) {
    return rowList;
  }

  const logParamsByUserSet = new Set<string>([]);

  const preparedMetricsData = systemMetrics.map(data => {
    const metricsList = prepareMetricsTableRow(data);
    data.logAdditionalSystemInfoList.forEach(item => {
      const row = prepareMetricsTableRowFromLog(item);
      logParamsByUserSet.add(row.key);
    });

    return metricsList;
  });

  const metricsParamListMap = new Map<string, SystemMetricsSingleTableRow>();
  const equalityMap = new Map<string, Array<string | number>>();

  preparedMetricsData.forEach((metricsParamList, experimentIdx) => {
    const { experimentKey } = experiments[experimentIdx];
    metricsParamList.forEach(metricData => {
      metricsParamListMap.set(`${experimentKey}_${metricData.key}`, metricData);
    });
  });

  const metricParamsList = getMetricParamsList(systemMetrics);
  const logParamsList: string[] = Array.from(logParamsByUserSet);

  [...metricParamsList, ...logParamsList].forEach(paramKey => {
    experiments.forEach((experimentDetails, experimentIdx) => {
      const currentParamData = metricsParamListMap.get(
        `${experimentDetails.experimentKey}_${paramKey}`
      );

      const key = currentParamData?.key ?? paramKey;
      const rowId = `${key}_${experimentDetails.experimentKey}`;
      const isMain = experimentIdx === 0;

      rowList.push({
        rowId,
        key,
        experiment: experimentDetails,
        isMain,
        ...currentParamData
      });

      const equalityList = equalityMap.get(key) ?? [];
      equalityMap.set(key, [
        ...equalityList,
        currentParamData?.value ?? 'empty'
      ]);
    });
  });

  rowList = rowList.map(data => {
    const equalList = equalityMap.get(data.key) ?? [];
    data.isEqual = new Set(equalList).size === 1;
    return data;
  });

  return rowList;
};

export default useSystemMetricsComparisonTableData;
