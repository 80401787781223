import { Context, createContext, useContext } from 'react';
import { noop } from 'lodash';

interface UserMenuContextType {
  closeMainMenu: () => void;
  mainMenuRef: HTMLDivElement | null;
}

export const UserMenuContext: Context<UserMenuContextType> = createContext<UserMenuContextType>(
  {
    closeMainMenu: noop,
    mainMenuRef: null
  }
);

export const useUserMenuContext = (): UserMenuContextType =>
  useContext<UserMenuContextType>(UserMenuContext);
