// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperimentsHeaderTable-module__experimentActionsContainer--klbp3{display:flex;justify-content:end;color:var(--gray-5)}.ExperimentsHeaderTable-module__experimentActionsContainer--klbp3 button{margin-left:8px}.ExperimentsHeaderTable-module__experimentActionsContainer--klbp3 .ExperimentsHeaderTable-module__moreIconContainer--O9h0X{padding:5px;display:flex;align-items:center;justify-content:center;margin-left:8px;cursor:pointer}.ExperimentsHeaderTable-module__experimentActionsContainer--klbp3 .ExperimentsHeaderTable-module__moreIconContainer--O9h0X:hover{color:var(--primary-color-hover)}.ExperimentsHeaderTable-module__activeMenu--qpG17{color:var(--primary-color)}.ExperimentsHeaderTable-module__experimentExtraActionIcon--MFP7S{color:var(--gray-5)}.ExperimentsHeaderTable-module__createTagContainer--sB1W4{margin-top:20px}.ExperimentsHeaderTable-module__createTagContainer--sB1W4 .ds-button{height:32px}.ExperimentsHeaderTable-module__experimentActionMenuContainer--RtvnZ{margin-left:-5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentActionsContainer": `ExperimentsHeaderTable-module__experimentActionsContainer--klbp3`,
	"moreIconContainer": `ExperimentsHeaderTable-module__moreIconContainer--O9h0X`,
	"activeMenu": `ExperimentsHeaderTable-module__activeMenu--qpG17`,
	"experimentExtraActionIcon": `ExperimentsHeaderTable-module__experimentExtraActionIcon--MFP7S`,
	"createTagContainer": `ExperimentsHeaderTable-module__createTagContainer--sB1W4`,
	"experimentActionMenuContainer": `ExperimentsHeaderTable-module__experimentActionMenuContainer--RtvnZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
