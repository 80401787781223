import { alertsActionTypes } from '../constants/actionTypes';
import { dialogTypes } from '../constants/alertTypes';

const { ADD_VISUALIZATION_MODAL } = dialogTypes;

const initialState = {
  snackbars: {},
  dialogs: {},
  throttlingPopupOpen: false,
  throttlingPopoverAnchor: null,
  customDialogs: {
    [ADD_VISUALIZATION_MODAL]: {}
  },
  limitedGroupedExperiments: true
};

const alertsReducer = (state = initialState, action) => {
  if (action.type.includes(alertsActionTypes.OPEN_SNACKBAR)) {
    return {
      ...state,
      snackbars: {
        [action.payload.key]: action.payload.props,
        ...state.snackbars
      }
    };
  }

  if (action.type.includes(alertsActionTypes.OPEN_DIALOG)) {
    return {
      ...state,
      dialogs: {
        [action.payload.key]: action.payload.props,
        ...state.dialogs
      }
    };
  }

  if (action.type.includes(alertsActionTypes.CLOSE_SNACKBAR)) {
    const { [action.payload.key]: omit, ...snackbars } = state.snackbars;
    return { ...state, snackbars };
  }

  if (action.type.includes(alertsActionTypes.CLOSE_DIALOG)) {
    const { [action.payload.key]: omit, ...dialogs } = state.dialogs;
    return { ...state, dialogs };
  }

  if (action.type.includes(alertsActionTypes.ADD_CUSTOM_DIALOG_STATE)) {
    const { type, options } = action.payload;
    return {
      ...state,
      customDialogs: {
        ...state.customDialogs,
        [type]: options
      }
    };
  }

  if (action.type.includes(alertsActionTypes.RESET_CUSTOM_DIALOG_STATE)) {
    const type = action.payload;
    return {
      ...state,
      customDialogs: {
        ...state.customDialogs,
        [type]: {}
      }
    };
  }

  if (action.type.includes(alertsActionTypes.HIDE_GROUPED_EXPERIMENTS_ALERT)) {
    return { ...state, limitedGroupedExperiments: false };
  }

  if (action.type === alertsActionTypes.OPEN_THROTTLING_POPUP) {
    return {
      ...state,
      throttlingPopupOpen: true,
      throttlingPopoverAnchor: action.payload.throttlingPopoverAnchor,
      experimentKey: action.payload.experimentKey
    };
  }

  if (action.type === alertsActionTypes.CLOSE_THROTTLING_POPUP) {
    return {
      ...state,
      throttlingPopupOpen: false,
      throttlingPopoverAnchor: null,
      experimentKey: null
    };
  }

  return state;
};

export default alertsReducer;
