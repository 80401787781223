import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { KeyboardArrowDownIcon } from '@Icons-outdated';
import styles from './Legend.module.scss';

const Expander = ({ expandhandler }) => {
  const [expanded, setExpanded] = useState(false);
  const clickHandler = () => {
    setExpanded(prevState => !prevState);
    expandhandler();
  };
  return (
    <span onClick={clickHandler} className={styles.expanderSection}>
      {expanded ? 'Collapse  ' : 'Expand  '}
      <KeyboardArrowDownIcon
        fontSize="small"
        className={cx(styles.expanderArrow, {
          [styles.expandedArrow]: expanded
        })}
      />
    </span>
  );
};

Expander.propTypes = {
  expandhandler: PropTypes.func.isRequired
};

export default Expander;
