const outputUtil = {
  formatGraphDefinitionOutput: text => {
    if (!text) {
      return null;
    }

    try {
      return JSON.stringify(JSON.parse(text), null, 4);
    } catch (e) {
      return text;
    }
  },

  formatInstalledPackagesOutput: packageList => {
    if (!packageList) {
      return null;
    }

    return packageList.join('\n');
  }
};

export default outputUtil;
