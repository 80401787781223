import { Table } from '@devexpress/dx-react-grid-material-ui';
import { ModelsIcon } from '@Icons-outdated';
import { first, isEmpty, difference } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EMPTY_TABLE_COLSPAN } from '@shared/constants/empty-table';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';

import { rulesTreeToMPMPredicates } from '@mpm/utils';
import { useModels, useModelsByID } from '../api';
import { getActiveWorkspaceName } from '@/reducers/ui/workspaceUiReducer';
import TextCell from '@shared/components/TableCells/TextCell';
import {
  AreaChartCell,
  LinkCell,
  GroupedModelsPipeline,
  OpenNotificationsCell
} from '../components/Cells';
import {
  DEFAULT_MODEL_SORTING,
  MODEL_COLUMNS_NAMES,
  MODEL_COLUMN_WIDTHS,
  MODEL_DISABLED_COLUMNS,
  MODEL_LEFT_COLUMNS,
  MODEL_PAGE_SIZES,
  MODEL_SERVER_COLUMNS_NAMES,
  NO_PADDING_COLUMNS,
  MODEL_SUMMARY_COLUMNS,
  MODELS_GROUPED_ROW_NAME
} from '../constants';
import { NoTrackedModelsView } from '../components/NoTrackedModelsView';
import { BETA_FEATURE_MODEL_PRODUCTION_MONITORING_MODELS_PIPELINE } from '@/lib/betaFeatures';

const GROUPING_COLUMN = 'pipelineName';

export function useMPMhomeProps() {
  const [columnWidths, setColumnWidths] = useState(MODEL_COLUMN_WIDTHS);
  const [selection, setSelection] = useState([]);
  const [columnSorting, setColumnSorting] = useState(DEFAULT_MODEL_SORTING);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState({
    rulesTree: generateEmptyRulesTree(),
    segmentId: ''
  });
  const [expandedGroup, setExpandedGroup] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);

  const currentWorkspaceName = useSelector(getActiveWorkspaceName);

  const modelsPipelineEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_PRODUCTION_MONITORING_MODELS_PIPELINE
  );

  const dataTypes = [
    {
      cols: [MODEL_COLUMNS_NAMES.numberOfPredictions],
      cell: ({ value, column }) => (
        <AreaChartCell value={value} column={column} />
      )
    },
    {
      cols: [MODEL_COLUMNS_NAMES.modelName],
      cell: LinkCell
    },
    { cols: [MODEL_COLUMNS_NAMES.alerts], cell: OpenNotificationsCell },
    {
      cols: [
        MODEL_COLUMNS_NAMES.pipelineName,
        MODEL_COLUMNS_NAMES.createdAt,
        MODEL_COLUMNS_NAMES.latestPrediction,
        MODEL_COLUMNS_NAMES.latestVersion
      ],
      cell: TextCell
    }
  ];

  const handleFilterQueryChange = useCallback(newQuery => {
    setQuery(newQuery);
  }, []);

  const sort = first(columnSorting);
  const sortColumn = MODEL_SERVER_COLUMNS_NAMES[sort?.columnName];
  const order = sort?.direction;

  const { isLoading, isError, data, isFetching, isPreviousData } = useModels(
    {
      page: currentPage + 1,
      pageSize: modelsPipelineEnabled ? 1000 : 10,
      sortColumn,
      order,
      predicates: query?.rulesTree
        ? rulesTreeToMPMPredicates(query.rulesTree)
        : []
    },
    {
      keepPreviousData: true,
      refetchOnMount: true
    }
  );

  const handlePageChange = num => {
    setCurrentPage(num);
  };

  useEffect(() => {
    if (window.innerWidth < 1800) {
      setColumnWidths(MODEL_COLUMN_WIDTHS);
    } else {
      setColumnWidths([MODEL_COLUMN_WIDTHS[0]]);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [pageSize, query]);

  useEffect(() => {
    if (isEmpty(selection)) {
      setActiveTabIndex(0);
    }
  }, [selection]);

  const handlePageSizeChange = useCallback(newPageSize => {
    setPageSize(newPageSize);
  }, []);

  const getDateCell = val => {
    return val ? new Date(val).toLocaleString() : '';
  };

  const mapDataToRows = useCallback(
    (models, pipelines) => {
      const modelRows =
        models?.map(model => {
          return {
            id: model.id,
            currentWorkspaceName,
            alerts: model.numberOfAlerts,
            modelName: model.name ? model.name : 'no name available',
            latestVersion: first(model.versions),
            latestPrediction: getDateCell(model.latestPrediction),
            createdAt: getDateCell(model.createdAt),
            numberOfPredictions: model.numberOfPredictions,
            pipelineName: MODELS_GROUPED_ROW_NAME,
            groupedRowsData: {
              alerts: '',
              createdAt: '',
              latestVersion: '',
              latestPrediction: '',
              numberOfPredictions: ''
            }
          };
        }) || [];

      const pipelineRows =
        modelsPipelineEnabled && data?.pipelines.length
          ? pipelines?.map(pipeline => {
              const {
                numberOfAlerts,
                createdAt,
                versions,
                latestPrediction,
                numberOfPredictions
              } = pipeline?.wholePipelineModel || {};
              return pipeline.pipelineModels.map(model => {
                return {
                  id: `${model.id}`,
                  currentWorkspaceName,
                  alerts: model.numberOfAlerts,
                  modelName: model.name || 'no name available',
                  latestVersion: first(model.versions),
                  latestPrediction: getDateCell(model.latestPrediction),
                  createdAt: getDateCell(model.createdAt),
                  numberOfPredictions: model.numberOfPredictions,
                  pipelineName: pipeline.pipelineDefinition.name,
                  groupedRowsData: {
                    alerts: numberOfAlerts,
                    createdAt: getDateCell(createdAt),
                    latestVersion: first(versions),
                    latestPrediction,
                    numberOfPredictions
                  }
                };
              });
            })
          : [];

      return [...modelRows, ...pipelineRows.flat()];
    },

    [currentWorkspaceName, data?.pipelines.length, modelsPipelineEnabled]
  );

  const groupedColumnRowsData = useMemo(() => {
    if (data?.pipelines.length) {
      return data.pipelines.map(pipeline => {
        const {
          graphViewJson,
          id,
          pipelineModelId,
          name
        } = pipeline.pipelineDefinition;
        return {
          id,
          pipelineModelId,
          currentWorkspaceName,
          graphViewJson,
          pipelineName: name
        };
      });
    }
    return [];
  }, [data, currentWorkspaceName]);

  const columnsForSummary = useMemo(() => {
    return MODEL_SUMMARY_COLUMNS.map(column => ({
      columnName: column,
      type: '',
      showInGroupFooter: false,
      alignByColumn: true
    }));
  }, []);

  useEffect(() => {
    if (data) {
      const { models = [], pipelines = [] } = data;
      const tableRows = mapDataToRows(models, pipelines);
      setRows(tableRows);
      setExpandedGroup([first(tableRows)?.pipelineName] || []);
    }
  }, [data, mapDataToRows]);

  const onSortingChange = useCallback(newSort => {
    setColumnSorting(newSort);
  }, []);

  const {
    data: selectionRowsData,
    isLoading: isLoadingSelection
  } = useModelsByID(
    {
      models: selection
    },
    { enabled: activeTabIndex === 1 }
  );

  const rowsFilteredForActiveTab = useMemo(() => {
    if (activeTabIndex === 1 && selectionRowsData) {
      return mapDataToRows(selectionRowsData.models, []);
    }

    return rows;
  }, [activeTabIndex, mapDataToRows, rows, selectionRowsData]);

  const renderEmptyState = props => {
    return (
      <Table.Cell
        {...props}
        style={{ borderBottom: 'unset' }}
        colSpan={EMPTY_TABLE_COLSPAN}
      >
        <NoTrackedModelsView
          icon={<ModelsIcon />}
          title="No models are being tracked"
        />
      </Table.Cell>
    );
  };

  const MODEL_COLUMNS = useMemo(() => {
    const columns = [
      {
        name: MODEL_COLUMNS_NAMES.modelName,
        title: 'Model Name',
        id: 'model_name--metadata',
        type: 'string',
        source: 'metadata',
        serverField: 'model_name'
      },
      {
        name: MODEL_COLUMNS_NAMES.alerts,
        title: 'Alerts',
        id: 'alerts--metadata',
        type: 'string',
        source: 'metadata',
        hideForFilters: true,
        hideForSorting: true
      },
      {
        name: MODEL_COLUMNS_NAMES.createdAt,
        title: 'Created at',
        id: 'createdAt--metadata',
        type: 'datetime',
        source: 'metadata',
        serverField: 'created_ts'
      },
      {
        name: MODEL_COLUMNS_NAMES.latestVersion,
        title: 'Latest version',
        id: 'latestVersion--metadata',
        type: 'string',
        source: 'metadata',
        serverField: 'version',
        hideForFilters: true,
        hideForSorting: true
      },
      {
        name: MODEL_COLUMNS_NAMES.latestPrediction,
        title: 'Latest prediction',
        id: 'latestPrediction--metadata',
        type: 'datetime',
        source: 'metadata',
        serverField: 'latest_prediction',
        hideForFilters: true,
        hideForSorting: true
      },
      {
        name: MODEL_COLUMNS_NAMES.numberOfPredictions,
        title: 'Number of predictions',
        id: 'numberOfPredictions--metadata',
        type: 'double',
        source: 'metadata',
        serverField: 'prediction_count',
        hideForFilters: true,
        hideForSorting: true
      }
    ];

    if (modelsPipelineEnabled && data?.pipelines.length) {
      columns.push({
        name: MODEL_COLUMNS_NAMES.pipelineName,
        title: 'Pipeline Name',
        id: 'pipeline_name--metadata',
        type: 'link',
        source: 'metadata',
        serverField: 'pipeline_name',
        hideForFilters: true,
        hideForSorting: true
      });
    }

    return columns;
  }, [modelsPipelineEnabled, data?.pipelines.length]);

  const DISABLED_MODEL_COLUMN_SORTING = MODEL_COLUMNS.filter(
    column => column.hideForSorting
  ).map(column => ({
    columnName: column.name,
    sortingEnabled: false
  }));

  const handleExpandedGroupsChange = useCallback(
    expandedGroups => {
      const expandedPipeline = difference(expandedGroups, expandedGroup);
      setExpandedGroup(expandedPipeline);
    },
    [expandedGroup]
  );

  const summaryCellCalculator = useCallback((...props) => {
    return props[2](props[1][0]?.groupedRowsData) || '';
  }, []);

  return {
    isLoading,
    data,
    query,
    isError,
    handleFilterQueryChange,
    tableProps: {
      renderEmptyState,
      columns: MODEL_COLUMNS,
      rowHeight: '58px',
      isFetching: (isFetching && isPreviousData) || isLoadingSelection,
      shouldHighlightLinks: true,
      removeHeaderPadding: true,
      noPaddingColumns: NO_PADDING_COLUMNS,
      maxHeight: modelsPipelineEnabled ? 215 : 265,
      rows: rowsFilteredForActiveTab,
      dataTypes,
      leftColumns: MODEL_LEFT_COLUMNS,
      rowIdKey: 'id',
      totalRowCount: activeTabIndex === 0 ? data?.total : selection.length,
      sortingConfig: {
        isDisabled: false,
        columnSorting,
        onSortingChange,
        disabledColumns: DISABLED_MODEL_COLUMN_SORTING
      },
      groupingConfig: {
        expandedGroups: expandedGroup,
        isDisabled: !data?.pipelines.length,
        groupedColumns:
          modelsPipelineEnabled && data?.pipelines.length
            ? [{ columnName: GROUPING_COLUMN }]
            : [],
        onExpandedGroupsChange: handleExpandedGroupsChange,
        columnsForSummary,
        groupedColumnRowsData,
        GroupedCellComponent: GroupedModelsPipeline,
        groupedCellsClassName: 'groupedDataCell',
        groupedColumnCellClassName: 'groupedColumnCellClassName',
        summaryCellCalculator
      },
      paginationConfig: {
        pageNumber: currentPage,
        isDisabled: modelsPipelineEnabled,
        isServerSidePagination: activeTabIndex !== 1,
        pageSizes: MODEL_PAGE_SIZES,
        onPageNumberChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange
      },
      columnWidthsConfig: {
        isDisabled: false,
        columnWidths,
        disabledColumns: MODEL_DISABLED_COLUMNS,
        onColumnWidthsChange: setColumnWidths
      },
      columnOrderConfig: {
        isDisabled: false,
        columnOrder,
        onColumnOrderChange: setColumnOrder
      },
      selectionConfig: {
        selection,
        isDisabled: false,
        onSelectionChange: setSelection
      },
      setActiveTabIndex,
      activeTabIndex
    }
  };
}
