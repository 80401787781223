// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScatterChartTooltip-module__popper--bT4LI{pointer-events:none}.ScatterChartTooltip-module__container--SaXsT{min-width:100px;max-width:48vw;max-height:624px;border-radius:10px;border:1px solid var(--gray-2);background:var(--white);box-shadow:0 0 4px 0 rgba(0,0,0,.08)}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__header--UXuPu{height:24px;padding:8px;border-bottom:1px solid var(--gray);display:flex;flex-direction:row;align-items:center;gap:8px}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__header--UXuPu .ScatterChartTooltip-module__color--v1sw9{--size: 8px;flex-shrink:0;width:var(--size);height:var(--size);border-radius:var(--size);background:var(--line-color, transparent)}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__header--UXuPu .ScatterChartTooltip-module__title--IHlFQ{color:var(--gray-7);font-size:12px;font-weight:500;line-height:18px}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__body--UwxRo{padding:4px 8px;color:var(--gray-7)}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__body--UwxRo .ScatterChartTooltip-module__line--oVlRU{display:flex;flex-direction:row;align-items:center;font-size:12px;line-height:18px;padding:0 3px}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__body--UwxRo .ScatterChartTooltip-module__line--oVlRU .ScatterChartTooltip-module__value--Kw1xG{margin-left:4px;font-weight:500}.ScatterChartTooltip-module__container--SaXsT .ScatterChartTooltip-module__body--UwxRo .ScatterChartTooltip-module__spacer--lvU9w{height:6px}.ScatterChartTooltip-module__textOverflow--l45J1{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.ScatterChartTooltip-module__circle--xxSiT{width:var(--size);height:var(--size);position:absolute;pointer-events:none;transform:translate(-50%, -50%);background:rgba(0,0,0,0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": `ScatterChartTooltip-module__popper--bT4LI`,
	"container": `ScatterChartTooltip-module__container--SaXsT`,
	"header": `ScatterChartTooltip-module__header--UXuPu`,
	"color": `ScatterChartTooltip-module__color--v1sw9`,
	"title": `ScatterChartTooltip-module__title--IHlFQ`,
	"body": `ScatterChartTooltip-module__body--UwxRo`,
	"line": `ScatterChartTooltip-module__line--oVlRU`,
	"value": `ScatterChartTooltip-module__value--Kw1xG`,
	"spacer": `ScatterChartTooltip-module__spacer--lvU9w`,
	"textOverflow": `ScatterChartTooltip-module__textOverflow--l45J1`,
	"circle": `ScatterChartTooltip-module__circle--xxSiT`
};
module.exports = ___CSS_LOADER_EXPORT___;
