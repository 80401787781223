import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { getSettingsRoute, SETTING_SECTIONS } from '@shared/utils/url';

import Grid from '@material-ui/core/Grid';

import AcademicSignupForm from './AcademicSignupForm';
import { useCurrentOrganization, useCurrentPaymentPlan } from '@shared/hooks';

import { getUserAcademicDetails } from '@/reducers/userReducer';
import { SLACK_INVITE_URL, SUPPORT_EMAIL, URLS } from '@/lib/appConstants';
import useAcademicMutation from '@auth/api/useAcademicMutation';

const AcademicSignup = ({ history, userAcademicDetails }) => {
  const { isAcademic: isAcademicOrganization } = useCurrentPaymentPlan();
  const isAcademicUser =
    get(userAcademicDetails, 'previouslyClaimed', false) ||
    isAcademicOrganization;
  const academicWorkspace = get(userAcademicDetails, 'academicWorkspace', '');
  const currentOrganization = useCurrentOrganization();

  const academicMutation = useAcademicMutation();

  useEffect(() => {
    if (isAcademicUser && currentOrganization && !currentOrganization.isAdmin) {
      const urlToGo = getSettingsRoute({
        organizationId: currentOrganization?.id,
        section: SETTING_SECTIONS.PAYMENT_PLAN,
        isAdmin: currentOrganization?.isAdmin
      });

      history.push(urlToGo);
    }
  }, [academicWorkspace, isAcademicUser, history, currentOrganization]);

  if (!currentOrganization?.id) {
    return null;
  }

  const handleSubmit = values => {
    academicMutation.mutate(values);
  };

  const updatedEntityLabel = () => (
    <span>
      {' '}
      organization:{' '}
      <Link to={`/organizations/${currentOrganization.id}`}>
        {currentOrganization?.name}
      </Link>{' '}
    </span>
  );

  return (
    <div className="wallpaper-plain payments academic-landing-page">
      <div className="container page-container projects-container">
        <Grid container>
          <Grid item xs={12}>
            <a className="return-link-path" href={URLS.PRICING}>
              ← Return to pricing plans
            </a>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h1>Academic Access</h1>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={12} className="academic-content-wrapper">
                <h3>Comet.ml values cutting-edge ML research</h3>
                <p className="larger-text-p">
                  Be part of the next generation of machine learning research
                  with Comet.ml for your machine learning experiment management
                  needs.
                </p>
                <h4>Do I qualify as an academic user?</h4>
                <p>
                  Current teachers, students, researchers, or faculty qualify
                  for academic access. If you do not fit this qualifications,
                  but still would like to request academic access, please
                  contact us using the methods below.
                </p>
                <h4>What does academic access include?</h4>
                <p>
                  Academics gain free, full access to the Starter plan. The
                  Starter plan includes unlimited private and public projects
                  with full collaboration and premium features such as
                  hyperparameter optimization. After successfully registering,
                  your personal
                  {updatedEntityLabel()}
                  will be transferred to an academic plan.
                </p>
                <h4>
                  Have additional questions? Contact us at{' '}
                  <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> or via{' '}
                  <a
                    href={SLACK_INVITE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Slack
                  </a>
                </h4>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                <AcademicSignupForm onSubmit={handleSubmit} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

AcademicSignup.propTypes = {
  history: PropTypes.object.isRequired,
  userAcademicDetails: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userAcademicDetails: getUserAcademicDetails(state)
  };
};

export default withRouter(connect(mapStateToProps)(AcademicSignup));
