import React, { useEffect, useRef, useState } from 'react';

import Select, {
  GroupBase,
  SingleValue as SingleValueType
} from 'react-select';

import cx from 'classnames';
import classes from './Select.module.scss';
import typography from '@ds-typography';
import {
  Option,
  DropdownIndicator,
  MenuList,
  GroupHeading,
  Group,
  Control,
  ClearIndicator,
  NoOptionsMessage,
  SelectContainer,
  SingleValue,
  ValueContainer,
  Menu
} from './components';
import { OptionType } from '../../../types';
import type {} from 'react-select/base';

import noop from 'lodash/noop';
import { SingleSelectProps } from './types';
import { createOptionFilter, getSelectValue } from './utils';

export type DSSelectProps = Omit<
  SingleSelectProps,
  'onChange' | 'options' | 'value'
> & {
  dataTest?: string;
  options: OptionType[];
  onValueChange?: (
    newValue: OptionType['value'],
    selectedOption: OptionType | null
  ) => void;
  value?: unknown;
};

const DSSelect = ({
  options,
  footerActionName = '',
  onFooterActionClick = noop,
  FooterActionIcon = null,
  PrefixIcon = null,
  invalid = false,
  helperText = '',
  size = 'default',
  dataTest,
  value,
  maxHeight,
  onValueChange = noop,
  isSearchable,
  menuIsOpen,
  tooltipPlacement = 'top',
  isRegexSearchEnabled,
  ...restProps
}: DSSelectProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const isWithFooter = footerActionName || FooterActionIcon;
  const selectedValue = getSelectValue(value, options);

  const handleChange = (newValue: SingleValueType<OptionType>) => {
    setIsFocused(false);
    onValueChange(newValue?.value, newValue);
  };

  const onCloseMenu = () => {
    setIsFocused(false);
    setInputValue('');
  };

  const onDomClick = (e: MouseEvent) => {
    if (!containerRef.current?.contains(e.target as Node)) {
      onCloseMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick);

    return () => {
      document.removeEventListener('mousedown', onDomClick);
    };
  }, [isFocused]);

  const onMenuInputFocus = (v: boolean) => {
    setIsFocused(v);
  };

  const filterOption = createOptionFilter(Boolean(isRegexSearchEnabled));

  return (
    <div data-test={dataTest} ref={containerRef}>
      <Select<OptionType, false, GroupBase<OptionType>>
        options={options}
        components={{
          Option,
          DropdownIndicator,
          MenuList,
          GroupHeading,
          Group,
          SelectContainer,
          Control,
          ClearIndicator,
          NoOptionsMessage,
          SingleValue,
          ValueContainer,
          Menu
        }}
        classNames={{
          menuPortal: () => classes.selectMenuPortal,
          control: state =>
            cx(classes.controlContainer, typography.dsBody, classes[size], {
              [classes.open]: state.menuIsOpen,
              [classes.disabledControl]: state.isDisabled,
              [classes.invalidControl]: invalid,
              [classes.noValueControl]: !state.hasValue
            }),
          singleValue: () => classes.singleValue,
          placeholder: () => classes.placeholder,
          indicatorSeparator: () => classes.decoratorSeparator,
          dropdownIndicator: state =>
            cx(classes.dropdownIndicator, {
              [classes.disabledDropdownIndicator]: state.isDisabled
            }),
          menuList: () =>
            cx(classes.menuList, {
              [classes.menuWithoutBottomPadding]: isWithFooter
            }),
          noOptionsMessage: () => classes.noOptionsMessage
        }}
        isClearable
        unstyled
        PrefixIcon={PrefixIcon}
        footerActionName={footerActionName}
        FooterActionIcon={FooterActionIcon}
        onFooterActionClick={onFooterActionClick}
        invalid={invalid}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        captureMenuScroll={!maxHeight}
        tooltipPlacement={tooltipPlacement}
        {...restProps}
        menuPlacement="auto"
        filterOption={filterOption}
        maxHeight={maxHeight}
        value={selectedValue}
        onChange={handleChange}
        isMulti={false}
        inputValue={inputValue}
        isSearchable={false}
        withSearch={isSearchable}
        autoFocus={false}
        onMenuInputFocus={onMenuInputFocus}
        onInputChange={val => setInputValue(val)}
        onMenuClose={onCloseMenu}
        menuIsOpen={isFocused || menuIsOpen}
        isFocused={isFocused || undefined}
        classNamePrefix="design-system-select"
        isRegexSearchEnabled={isRegexSearchEnabled}
      />

      {helperText && (
        <div
          className={cx(typography.dsBodySmall, classes.helperTextContainer, {
            [classes.invalidHelperText]: invalid
          })}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};

export default DSSelect;
