import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import DsSelect from '@DesignSystem/controllers/Select/Select';
import classNames from './RegisterModel.module.scss';

const RegisterModelSelect = ({
  dataTest,
  options = [],
  value,
  onChange,
  label,
  placeholder,
  extraOptionsProps
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classNames.registerModelSelect}
    >
      <Grid item xs={12}>
        <Box className={classNames.registerModelSelectLabel}>{label}</Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          className={classNames.registerModelSelectSelect}
          data-test={dataTest}
        >
          <DsSelect
            withInput={extraOptionsProps?.withInput}
            isClearable={extraOptionsProps?.isClearable}
            hideSource
            placeholder={placeholder}
            variant="outlined"
            data-test="modal-version-select-model"
            value={value}
            onChange={(...props) => onChange(...props)}
            options={options}
            extraOptionsProps={extraOptionsProps}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

RegisterModelSelect.defaultProps = {
  extraOptionsProps: null,
  dataTest: null
};

RegisterModelSelect.propTypes = {
  extraOptionsProps: PropTypes.object,
  dataTest: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RegisterModelSelect;
