import React from 'react';
import PropTypes from 'prop-types';
import { chartDataErrorTypes } from '@experiment-management-shared/constants/chartConstants';
import { useDispatch } from 'react-redux';
import { TextButton } from '@ds';
import typography from '@ds-typography';
import cx from 'classnames';
import loginActions from '@/actions/loginActions';

import classes from './ChartError.module.scss';

const {
  FETCHING_ERROR,
  EMPTY_DATA,
  TEMPLATE_NOT_FOUND,
  NO_PERMISSION_TO_SEE_PANEL,
  IS_NOT_AVAILABLE,
  PYODIDE_PY_ERROR,
  PY_SERVER_ERROR,
  PY_BUILT_IN_PANEL_NOT_AVAILABLE_NOT_LOGGED_IN
} = chartDataErrorTypes;

const renderPyErrorLabel = () => (
  <span>
    This panel has caused an error. Please see{' '}
    <a
      href="/docs/v2/guides/comet-dashboard/code-panels/python-panels/#make-the-most-of-python-panels"
      target="_blank"
      rel="noreferrer"
    >
      Python Panel Troubleshooting
    </a>{' '}
    for more information.
  </span>
);

const BuiltInPythonPanelNotLoggedInError = () => {
  const dispatch = useDispatch();

  return (
    <div
      className={cx(
        typography.dsBodyLarge,
        classes.notLoggedInPythonPanelContainer
      )}
    >
      This visualization is only available to logged-in users,{' '}
      <TextButton
        onClick={() => dispatch(loginActions.openLoginModal())}
        type="tertiary"
        size="XL"
      >
        {' '}
        log in here.
      </TextButton>
    </div>
  );
};

const errors = {
  [FETCHING_ERROR]: {
    text: 'Error fetching data',
    icon: 'cloud_off'
  },
  [EMPTY_DATA]: {
    text: 'No data for this chart',
    icon: 'warning'
  },
  [TEMPLATE_NOT_FOUND]: {
    text: 'This panel could not be found',
    icon: 'warning'
  },
  [NO_PERMISSION_TO_SEE_PANEL]: {
    text: 'You do not have permissions to see this panel',
    icon: 'warning'
  },
  [IS_NOT_AVAILABLE]: {
    text: 'Chart is not available',
    icon: 'warning'
  },
  [PYODIDE_PY_ERROR]: {
    render: renderPyErrorLabel,
    icon: 'warning'
  },
  [PY_SERVER_ERROR]: {
    text: 'Python panel is not available',
    icon: 'warning'
  },
  [PY_BUILT_IN_PANEL_NOT_AVAILABLE_NOT_LOGGED_IN]: {
    render: BuiltInPythonPanelNotLoggedInError,
    icon: 'warning'
  }
};

const ChartError = ({ type }) => {
  const { text, icon, render } = errors[type];

  return (
    <div className="chart-data-error">
      <i className="material-icons">{icon}</i>
      <p className="chart-data-error-msg">{(render && render()) || text}</p>
    </div>
  );
};

ChartError.propTypes = {
  type: PropTypes.string.isRequired
};

export default ChartError;
