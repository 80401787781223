import React from 'react';
import { Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { noop } from 'lodash';

import { Checkbox } from '@DesignSystem/controllers';
import DsAccordion from '@design-system-outdated/components/Accordion';
import classNames from './SegmentsPopover.module.scss';

const TOOLTIP_MESSAGE =
  "You can't uncheck the default segment if you haven't added new ones. You can create new segments on the model settings page";

type SegmntValue = {
  checked: boolean;
  color: string;
  id: string;
  segmentValue: string;
};

type SingleSegmentProps = {
  checkHandler: (groupId: string, id: string, checked: boolean) => void;
  isDisabled: boolean;
  expanded: boolean;
  expandHandler: (id: string, expandedItem: unknown) => void;
  groupId: string;
  values: SegmntValue[];
  title: string;
  isMaxSegmentsSelected: boolean;
};

export const SingleSegment = ({
  checkHandler,
  isDisabled = false,
  expanded = false,
  expandHandler = noop,
  groupId,
  values,
  title,
  isMaxSegmentsSelected
}: SingleSegmentProps) => {
  const handleChange = (groupId: string, id: string, event: Event) => {
    const target = event.target as HTMLInputElement;

    if (target) {
      const { checked } = target;
      checkHandler(groupId, id, checked);
    }
  };

  const handleExpandChange = (id: string, isExpandedItem: boolean) => {
    expandHandler(id, isExpandedItem);
  };

  return (
    <Box>
      <DsAccordion
        className={classNames.segmentsPopoverAccordion}
        classes={{
          details: classNames.segmentsPopoverDetails,
          summary: classNames.segmentsPopoverSummary
        }}
        title={title}
        id={groupId}
        expanded={expanded}
        handleExpandChange={handleExpandChange}
        content={values.map(val => {
          const { id, segmentValue, checked } = val;
          const disabledCheckBox =
            (isMaxSegmentsSelected && !checked) || isDisabled;
          return (
            <Box key={id} className={classNames.checkBoxValueContainer}>
              <Tooltip
                arrow
                classes={{
                  tooltip: classNames.iconTooltip,
                  arrow: classNames.iconTooltipArrow
                }}
                placement="top-start"
                title={isDisabled ? TOOLTIP_MESSAGE : ''}
              >
                <span>
                  <Checkbox
                    disabled={disabledCheckBox}
                    checked={checked}
                    onChange={event => handleChange(groupId, id, event)}
                    data-test={`segment-checkbox-${segmentValue}`}
                  />
                </span>
              </Tooltip>
              <span className={classNames.checkBoxValue}> {segmentValue}</span>
            </Box>
          );
        })}
      />
    </Box>
  );
};
