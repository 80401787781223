import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

export const REQUEST_INVITED_PROJECT_USERS = 'REQUEST_INVITED_PROJECT_USERS';
function requestInvitedProjectUsers() {
  return {
    type: REQUEST_INVITED_PROJECT_USERS
  };
}

export const RECEIVE_INVITED_PROJECT_USERS = 'RECEIVE_INVITED_PROJECT_USERS';
function receiveInvitedProjectUsers(invitedProjectUsers) {
  return {
    type: RECEIVE_INVITED_PROJECT_USERS,
    invitedProjectUsers
  };
}

export function fetchInvitedProjectUsers(forceReload = false) {
  return (dispatch, getState) => {
    const state = getState();
    const users = state.invitedProjectUsers.invitedProjectUsers;
    if (state.invitedProjectUsers && state.invitedProjectUsers.loading) {
      return Promise.resolve();
    }
    if (users && !forceReload) {
      return Promise.resolve(dispatch(receiveInvitedProjectUsers(users)));
    }

    dispatch(requestInvitedProjectUsers());
    return axios.get(`${BASE_API_URL}project/list/invites`).then(response => {
      const invitedProjectUsers = response.data
        .map(share => ({
          projectId: share.projectId,
          email: share.shareWithEmail,
          claimed: share.claimed
        }))
        .reduce((allUsers, user) => {
          if (allUsers[user.projectId]) {
            allUsers[user.projectId].push(user);
          } else {
            allUsers[user.projectId] = [user]; // eslint-disable-line no-param-reassign
          }
          return allUsers;
        }, {});
      dispatch(receiveInvitedProjectUsers(invitedProjectUsers));
    });
  };
}

export function inviteProjectUser(email, projectId) {
  return dispatch => {
    const data = {
      projectId,
      shareWithEmail: email
    };
    return axios
      .post(`${BASE_API_URL}project/invite`, JSON.stringify(data))
      .then(() => dispatch(fetchInvitedProjectUsers(true)));
  };
}

export function uninviteProjectUser(email, projectId) {
  return dispatch => {
    const data = {
      projectId,
      shareWithEmail: email
    };
    return axios
      .post(`${BASE_API_URL}project/uninvite`, JSON.stringify(data))
      .then(() => dispatch(fetchInvitedProjectUsers(true)));
  };
}
