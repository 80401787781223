import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { BasicModal } from '@DesignSystem/modals';
import { Input, Select } from '@DesignSystem/controllers';

import './ServiceAccounts.scss';

import useOrganizationWorkspaces from '@shared/api/useOrganizationWorkspaces';

const CreateServiceAccountModal = ({
  open,
  onClose,
  onPrimaryButtonClick,
  isPrimaryButtonDisabled
}) => {
  const { organizationId } = useParams();
  const [serviceName, setServiceName] = useState('');
  const [defaultWorkspaceId, setDefaultWorkspaceId] = useState(null);

  const { data: workspaces } = useOrganizationWorkspaces(organizationId);

  const workspaceOptions = useMemo(
    () =>
      workspaces?.map(workspace => ({
        label: workspace.name,
        value: workspace.id
      })) || [],
    [workspaces]
  );

  useEffect(() => {
    if (!open) {
      setServiceName('');
      setDefaultWorkspaceId(null);
    }
  }, [open]);

  const handlePrimaryButtonClick = useCallback(() => {
    onPrimaryButtonClick({
      name: serviceName,
      defaultWorkspaceId
    });
  }, [onPrimaryButtonClick, serviceName, defaultWorkspaceId]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Create Service Account"
      primaryButtonText="Create"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handlePrimaryButtonClick}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={!serviceName.length || isPrimaryButtonDisabled}
      content={
        <div className="create-service-account-content">
          <Input
            value={serviceName}
            onChange={setServiceName}
            placeholder="Enter name"
            title="Service account name"
            required
            height="36px"
          />

          <div className="default-workspace-select">
            <Select
              label="Default workspace"
              options={workspaceOptions}
              value={defaultWorkspaceId}
              onChange={setDefaultWorkspaceId}
              variant="outlined"
              width="340px"
              withInput
            />
          </div>

          <div className="create-account-label">
            Once the service account has been created, its API key will be
            available in the service account table.
          </div>
        </div>
      }
    />
  );
};

CreateServiceAccountModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  isPrimaryButtonDisabled: PropTypes.func.isRequired
};

export default CreateServiceAccountModal;
