// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PythonPanel-module__pythonIframeContainer--oilH6{display:flex;align-items:center;justify-content:center;width:100%;height:100%}.PythonPanel-module__pythonIframeContainer--oilH6 iframe{width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pythonIframeContainer": `PythonPanel-module__pythonIframeContainer--oilH6`
};
module.exports = ___CSS_LOADER_EXPORT___;
