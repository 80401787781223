import PropTypes from 'prop-types';
import moment from 'moment';

const DateCell = ({ value }) =>
  value ? moment(value).format('D MMM YYYY') : '';

DateCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default DateCell;
