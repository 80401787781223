import React, { useCallback, useMemo, useState } from 'react';
import { DropdownList } from '@DesignSystem/controllers';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import cx from 'classnames';
import {
  ArchiveIcon,
  MoveIcon,
  TagIcon,
  TightMoreIcon,
  TrashIcon
} from '@Icons-outdated';

import RegisterModel from '@shared/components/RegisterModel';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';

import ExperimentActionModals from './experiment-action-components/ExperimentActionModals';
import StopRunningExperimentButton from './experiment-action-components/StopRunningExperimentButton';
import { getIsArchivePage } from '@/selectors/routeSelectors';
import RestoreButton from './experiment-action-components/archived/RestoreButton';
import GitReproduceButton from './experiment-action-components/GitReproduceButton';

import styles from './ExperimentsHeaderTable.module.scss';
import { TagPropType } from './experiment-action-components/TagExperimentPopover';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { BETA_FEATURE_MODEL_REGISTRY_STREAMLINE } from '@/lib/betaFeatures';

const MENU_VALUES = {
  archive: 'acrhive',
  move: 'move',
  tag: 'tag',
  reproduce: 'reproduce',
  delete: 'delete'
};

const ARCHIVE_MENU_ITEMS = [MENU_VALUES.delete, MENU_VALUES.tag];
const NOT_ARCHIVE_MENU_ITEMS = [
  MENU_VALUES.tag,
  MENU_VALUES.move,
  MENU_VALUES.archive
];

const moreMenuOptions = [
  {
    label: 'Archive',
    value: MENU_VALUES.archive,
    prefixIcon: <ArchiveIcon className={styles.experimentExtraActionIcon} />
  },
  {
    label: 'Move to different project',
    value: MENU_VALUES.move,
    prefixIcon: <MoveIcon className={styles.experimentExtraActionIcon} />
  },
  {
    label: 'Tag',
    value: MENU_VALUES.tag,
    prefixIcon: <TagIcon className={styles.experimentExtraActionIcon} />
  },
  {
    label: 'Delete',
    value: MENU_VALUES.delete,
    prefixIcon: <TrashIcon className={styles.experimentExtraActionIcon} />
  }
];

const ExperimentActionsColumn = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isArchive = useSelector(getIsArchivePage);

  const openMenu = !!anchorEl;
  const { experimentKey } = useParams();
  const canEdit = useSelector(isUserAllowedToEditProject);
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const isModelStreamlineRegistrationEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_REGISTRY_STREAMLINE
  );

  const disableButtons = !experimentKey || !canEdit || !isUserLoggedIn;

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [tagAnchorEl, setTagAnchorEl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const handleClickItem = useCallback((value, option, e) => {
    if (value === MENU_VALUES.archive) {
      setIsArchiveModalOpen(true);
    }

    if (value === MENU_VALUES.move) {
      setIsMoveModalOpen(true);
    }

    if (value === MENU_VALUES.tag) {
      setTagAnchorEl(e.currentTarget);
    }

    if (value === MENU_VALUES.delete) {
      setIsDeleteModalOpen(true);
    }
  }, []);

  const menuOptions = useMemo(() => {
    const optionList = isArchive ? ARCHIVE_MENU_ITEMS : NOT_ARCHIVE_MENU_ITEMS;

    return moreMenuOptions
      .filter(option => optionList.includes(option.value))
      .map(option => ({ ...option, disabled: disableButtons }));
  }, [isArchive, disableButtons]);

  const renderTableActions = () => {
    if (isArchive) {
      return (
        <>
          <RestoreButton />
          <GitReproduceButton />
        </>
      );
    }

    return (
      <>
        {isModelStreamlineRegistrationEnabled && canEdit && (
          <RegisterModel experimentKey={experimentKey} />
        )}

        {row.runActive && !row.crashReason && (
          <StopRunningExperimentButton
            runActive={row.runActive}
            fileName={row.file_name}
            crashReason={row.crashReason}
            disable={disableButtons}
          />
        )}
        <GitReproduceButton />
      </>
    );
  };

  return (
    <>
      <div className={styles.experimentActionsContainer}>
        {renderTableActions()}

        <div
          className={cx(styles.moreIconContainer, {
            [styles.activeMenu]: openMenu
          })}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <TightMoreIcon />
        </div>
      </div>

      <DropdownList
        anchorEl={anchorEl}
        onClose={closeMenu}
        onClick={handleClickItem}
        items={menuOptions}
        width={isArchive ? '185px' : '200px'}
        withInput={false}
        horizontalPosition="left"
        transformOriginHorizontal="right"
        verticalPosition="top"
        dropdownWrapperClassName={styles.experimentActionMenuContainer}
      />

      {/* used for menu items only because they don't have buttons */}
      <ExperimentActionModals
        isArchiveModalOpen={isArchiveModalOpen}
        onIsArchiveModalOpen={setIsArchiveModalOpen}
        isMoveModalOpen={isMoveModalOpen}
        onIsMoveModalOpen={setIsMoveModalOpen}
        tagAnchorEl={tagAnchorEl}
        setTagAnchorEl={setTagAnchorEl}
        symlink={row.symlink}
        tags={row.tags}
        isDeleteModalOpen={isDeleteModalOpen}
        onIsDeleteModalOpen={setIsDeleteModalOpen}
      />
    </>
  );
};

ExperimentActionsColumn.propTypes = {
  row: PropTypes.shape({
    symlink: PropTypes.bool,
    tags: PropTypes.arrayOf(TagPropType),
    runActive: PropTypes.bool,
    file_name: PropTypes.string,
    crashReason: PropTypes.string
  }).isRequired
};

export default ExperimentActionsColumn;
