import React from 'react';
import TableEmptyState from '@shared/components/TableEmptyState';
import TableNoSearchResults from '@shared/components/TableNoSearchResults';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import MetricsTabEmpty from './MetricsTabEmpty';

type MetricsEmptyStateProps = {
  searchValue: string;
  isLoading: boolean;
  resetSearch: () => void;
};
const MetricsEmptyState: React.FC<MetricsEmptyStateProps> = ({
  searchValue,
  resetSearch,
  isLoading,
  ...props
}) => {
  if (searchValue) {
    return (
      <TableNoSearchResults
        buttonText="Clear search"
        clickHandler={resetSearch}
        colSpan="10"
        className="metricsTableBorder"
      />
    );
  }

  if (isLoading) {
    return (
      <TableEmptyState
        pictureHeight="50px"
        pictureWidth="55px"
        picturePath="/images/empty-states/no-paramaters.svg"
        materialProps={props}
        label={'Loading metrics...'}
      />
    );
  }

  const cellProps = props as Table.DataCellProps;

  return <MetricsTabEmpty {...cellProps} />;
};

export default MetricsEmptyState;
