import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';
import { DEBUGGER_GROUP_BUTTONS } from '@mpm/constants';
import { ActiveDebuggerButtonType, DebuggerResponse } from '@mpm/types';

type GetEventsProps = {
  modelId: string;
  workspaceId: string;
  activeButton: ActiveDebuggerButtonType;
};

type DebuggerEventsParams = {
  modelId: string;
  activeButton: ActiveDebuggerButtonType;
};

const PATH = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: '/errors/recent',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: '/labels/recent',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: '/events/recent'
};

const getEvents = ({ workspaceId, modelId, activeButton }: GetEventsProps) =>
  api.get(`mpm/${workspaceId}/models/${modelId}${PATH[activeButton]}`);

export function useDebuggerEvents(
  params: DebuggerEventsParams,
  config: QueryConfig<DebuggerResponse>
) {
  const activeWorkspace = useActiveWorkspace();
  const id = activeWorkspace?.id;
  return useQuery(
    ['debugger-events', { workspaceId: id, ...params }],
    () => getEvents({ workspaceId: id, ...params }),
    {
      refetchOnMount: true,
      ...config
    }
  );
}
