import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isNull from 'lodash/isNull';

import GenericModal from '@shared/components/GenericModal';

import alertsUtil from '@/util/alertsUtil';
import NewModel from './NewModel';
import UpdateModel from './UpdateModel';
import { getNumberOfRegisteredModels } from '@/reducers/modelRegistryReducer';
import modelRegistryActions from '@/actions/modelRegistryActions';
import SmallLoader from '@shared/components/SmallLoader';

const MODAL_AUTO_CLOSE_DELAY = 1000;

const RegisterModelModal = ({
  dispatch,
  experimentModel,
  modalId,
  numberOfRegisteredModels,
  workspace
}) => {
  const [isRegisteringNewModel, setIsRegisteringNewModel] = useState(false);

  useEffect(() => {
    dispatch(modelRegistryActions.fetchModelRegistryCount(workspace));
  }, []);

  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = () => {
    handleClose();
  };

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSuccessMessage = message => {
    setSuccessMessage(message);

    setTimeout(() => {
      handleClose();
    }, MODAL_AUTO_CLOSE_DELAY);
  };

  const handleNavigation = isRegisteringNewModel => {
    dispatch(modelRegistryActions.setModelVersionValidation());
    setIsRegisteringNewModel(isRegisteringNewModel);
  };

  const renderModalBody = () => {
    if (isNull(numberOfRegisteredModels)) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Fetching existing registered models"
          isFlexDisplay
        />
      );
    }

    if (isRegisteringNewModel || numberOfRegisteredModels === 0) {
      return (
        <NewModel
          navigationHandler={() => handleNavigation(false)}
          successMessageHandler={handleSuccessMessage}
          experimentModel={experimentModel}
          workspace={workspace}
        />
      );
    }

    return (
      <UpdateModel
        navigationHandler={() => handleNavigation(true)}
        successMessageHandler={handleSuccessMessage}
        experimentModel={experimentModel}
        workspace={workspace}
      />
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt register-model-modal"
      title="Register Model"
      onClose={handleSubmit}
      onCancel={handleClose}
      successMessage={successMessage}
      hideFooter
      isButtonDisabled
    >
      {renderModalBody()}
    </GenericModal>
  );
};

RegisterModelModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  experimentModel: PropTypes.object.isRequired,
  modalId: PropTypes.string.isRequired,
  numberOfRegisteredModels: PropTypes.number.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    numberOfRegisteredModels: getNumberOfRegisteredModels(state)
  };
};

export default connect(mapStateToProps)(RegisterModelModal);
