import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import DsAccordion from '@design-system-outdated/components/Accordion/Accordion';
import SyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter/SyntaxHighlighter';
import {
  ImageMetadata,
  useImagePanelMetadata
} from '@experiment-management-shared/api';
import { ImageAssetData } from '@experiment-management-shared/types';
import {
  ImageDetail,
  ImageFilterSection
} from '@experiment-management-shared/components/ImageDetails';

import { isEmpty, omit } from 'lodash';
import {
  GraphicAnnotationsSection,
  GraphicStepSection,
  GraphicsDetailsModal,
  GraphicsDetailsModalProps,
  Label
} from '../GridPanel';
import { Checkbox, ToggleButton } from '@ds';

import styles from './ImageDetailsModal.module.scss';

type ImageDetailsModalProps = Omit<
  GraphicsDetailsModalProps<ImageAssetData>,
  'renderAssetViewer' | 'sections'
> & {
  isGrayScale?: boolean;
  isSmoothed?: boolean;
  hiddenLabelNames?: string[];
  score?: number;
};

const DEFAULT_LABEL_NAMES: string[] = [];

export const ImageDetailsModal = ({
  assets,
  hiddenLabelNames: controlledHiddenLabelNames = DEFAULT_LABEL_NAMES,
  onChange,
  onClose,
  selectedAsset,
  score: controlledScore = 0
}: ImageDetailsModalProps) => {
  const [asset, setAsset] = useState(selectedAsset);
  const [hiddenLabelNames, setHiddenLabelNames] = useState(
    controlledHiddenLabelNames
  );
  const [isGrayScale, setIsGrayScale] = useState(false);
  const [isSmoothed, setIsSmoothed] = useState(true);
  const [score, setScore] = useState(controlledScore);
  const [labelsTextHidden, setLabelsTextHidden] = useState(false);

  const { data: imagesMetadata } = useImagePanelMetadata({
    experimentKeys: [asset.experimentKey],
    imagesIds: [asset.id]
  });

  useEffect(() => {
    setAsset(selectedAsset);
  }, [selectedAsset]);

  useEffect(() => {
    setHiddenLabelNames(controlledHiddenLabelNames);
  }, [controlledHiddenLabelNames]);

  useEffect(() => {
    setScore(controlledScore);
  }, [controlledScore]);

  const imageMetadata = imagesMetadata?.[0];
  const labels: Label[] = useMemo(() => {
    if (!imageMetadata) return [];

    return imageMetadata.annotations.map(annotation => {
      return {
        color: `#${imageMetadata.labelColorMap[annotation.label]}`,
        name: annotation.label,
        score: annotation.score
      };
    });
  }, [imageMetadata]);

  const formattedMetadata = useMemo(() => {
    if (!imageMetadata) return '';

    return getFormattedMetadata(imageMetadata);
  }, [imageMetadata]);

  const uniqueLabelNames = [...new Set(labels.map(l => l.name))];
  const hasVisibleLabelNames =
    hiddenLabelNames.length !== uniqueLabelNames.length;
  const hideLabelToggleIntermediate = Boolean(hiddenLabelNames.length);
  const allLabelNamesIsHidden =
    hiddenLabelNames.length === uniqueLabelNames.length;
  const onHideAnnotationsToggle = () => {
    if (!hasVisibleLabelNames) {
      return setHiddenLabelNames([]);
    }
    setHiddenLabelNames(uniqueLabelNames);
  };

  const handleChange = useCallback(
    (asset: ImageAssetData) => {
      onChange?.(asset);
      setAsset(asset);
    },
    [onChange]
  );

  const renderAssetViewer = ({ asset }: { asset: ImageAssetData }) => {
    return (
      <ImageDetail
        asset={asset}
        assets={assets}
        hiddenLabelNames={hiddenLabelNames}
        isGrayScale={isGrayScale}
        isSmoothed={isSmoothed}
        metadata={imageMetadata}
        score={score}
        isLabelsTextHidden={labelsTextHidden}
      />
    );
  };

  const renderSections = () => {
    if (!asset) return null;

    return (
      <>
        <DsAccordion
          content={
            <ImageFilterSection
              onChangeSettings={changes => {
                setIsGrayScale(changes.isGrayScale);
                setIsSmoothed(changes.isSmoothed);
              }}
            />
          }
          defaultExpanded
          id="image-analysis"
          title="Image Analysis"
        />
        <DsAccordion
          content={
            <GraphicStepSection
              asset={asset}
              assets={assets}
              onChangeSettings={({ asset: selectedAsset }) => {
                setAsset(selectedAsset as ImageAssetData);
              }}
            />
          }
          defaultExpanded
          id="asset-analysis"
          title="Asset Analysis"
        />

        {labels.length > 0 && (
          <DsAccordion
            content={
              <div>
                <div className={styles.annotationsToggle}>
                  <Checkbox
                    id="hide-annotations-toggle"
                    checked={hasVisibleLabelNames}
                    indeterminate={hideLabelToggleIntermediate}
                    onChange={onHideAnnotationsToggle}
                  />
                  <label htmlFor="hide-annotations-toggle">
                    Annotations labels
                  </label>
                </div>
                <GraphicAnnotationsSection
                  hiddenLabelNames={hiddenLabelNames}
                  labels={labels}
                  onChangeSettings={changes => {
                    setHiddenLabelNames(changes.hiddenLabelNames);
                    setScore(changes.score);
                  }}
                  score={score}
                  scoreSliderDisabled={allLabelNamesIsHidden}
                />
                <div className={styles.annotationsToggle}>
                  <ToggleButton
                    id="hide-labels-text-toggle"
                    checked={!labelsTextHidden}
                    onChange={() => setLabelsTextHidden(v => !v)}
                  />
                  <label htmlFor="hide-labels-text-toggle">
                    Display label and score
                  </label>
                </div>
              </div>
            }
            defaultExpanded
            id="annotation-labels"
            title="Annotations Labels"
          />
        )}

        {formattedMetadata ? (
          <DsAccordion
            content={
              <SyntaxHighlighter
                code={formattedMetadata}
                fullWidth
                language="json"
                showLineNumbers={false}
                style={github}
                withCopyBtn
              >
                {formattedMetadata}
              </SyntaxHighlighter>
            }
            defaultExpanded
            id="image-metadata-accordion"
            title="Image Metadata"
          />
        ) : null}
      </>
    );
  };

  return (
    <GraphicsDetailsModal
      assets={assets}
      onChange={handleChange}
      onClose={onClose}
      renderAssetViewer={renderAssetViewer}
      sections={renderSections()}
      selectedAsset={asset}
    />
  );
};

const METADATA_OMITTED_KEYS = ['annotations'];
const getFormattedMetadata = (imageMetadata: ImageMetadata) => {
  try {
    const parsedMetadata = omit(
      JSON.parse(imageMetadata.metadata),
      METADATA_OMITTED_KEYS
    );

    if (isEmpty(parsedMetadata)) return null;

    return JSON.stringify(parsedMetadata, null, 2);
  } catch (_) {
    return null;
  }
};
