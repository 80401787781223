import DuplicateReportButton from '@reports/components/DuplicateReportButton';
import EditReportButton from '@reports/components/EditReportButton';
import PreviewReportButton from '@reports/components/PreviewReportButton';
import RemoveReportButton from '@reports/components/RemoveReportButton';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ReportTableRowActions.module.scss';

const ReportTableRowActions = ({
  canEdit,
  isTemplate,
  name,
  onDuplicate,
  onRemove
}) => {
  return (
    <div className={styles.actions}>
      <PreviewReportButton isTemplate={isTemplate} name={name} />
      <EditReportButton canEdit={canEdit} isTemplate={isTemplate} name={name} />
      <DuplicateReportButton canEdit={canEdit} onDuplicate={onDuplicate} />
      <RemoveReportButton canEdit={canEdit} onRemove={onRemove} />
    </div>
  );
};

ReportTableRowActions.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ReportTableRowActions;
