import { systemMetricsTableActionTypes } from '@/constants/actionTypes';

const systemMetricActions = {
  updateEntireRowCharts(entireRowCharts) {
    return {
      type: systemMetricsTableActionTypes.UPDATE_ENTIRE_ROW_CHARTS,
      payload: entireRowCharts
    };
  },

  updateXAxisType(xAxisType, isCompare) {
    return {
      type: isCompare
        ? systemMetricsTableActionTypes.UPDATE_COMPARE_X_AXIS_TYPE
        : systemMetricsTableActionTypes.UPDATE_X_AXIS_TYPE,
      payload: xAxisType
    };
  }
};

export default systemMetricActions;
