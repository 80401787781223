// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DecisionTree-module__dsDecisionTreeItem--BEws4{font-size:14px;font-weight:400;margin:0 !important;margin-left:10px !important}.DecisionTree-module__dsDecisionTreeItemWrapper--P6fFJ:hover{background-color:var(--gray-1)}.DecisionTree-module__dsDecisionTreeItemSecondary--IrSpy{font-size:12px;color:var(--gray-6);margin-top:1px;margin-left:11px}.DecisionTree-module__dsDecisionTreeItemWrapper--P6fFJ:hover{background-color:var(--gray-1)}.DecisionTree-module__dsDecisionTreeCheckbox--BEwes{margin-left:10px;margin-top:-2px}.DecisionTree-module__dsDecisionTreeCheckboxMain--S8K3V{margin-left:10px !important}.DecisionTree-module__dsDecisionTreeSecondary--ULXSP{margin-top:-4px}.DecisionTree-module__dsDecisionTreeSecondary--ULXSP li{padding-left:44px !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsDecisionTreeItem": `DecisionTree-module__dsDecisionTreeItem--BEws4`,
	"dsDecisionTreeItemWrapper": `DecisionTree-module__dsDecisionTreeItemWrapper--P6fFJ`,
	"dsDecisionTreeItemSecondary": `DecisionTree-module__dsDecisionTreeItemSecondary--IrSpy`,
	"dsDecisionTreeCheckbox": `DecisionTree-module__dsDecisionTreeCheckbox--BEwes`,
	"dsDecisionTreeCheckboxMain": `DecisionTree-module__dsDecisionTreeCheckboxMain--S8K3V`,
	"dsDecisionTreeSecondary": `DecisionTree-module__dsDecisionTreeSecondary--ULXSP`
};
module.exports = ___CSS_LOADER_EXPORT___;
