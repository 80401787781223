import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Diff2HtmlUI } from 'diff2html/bundles/js/diff2html-ui.min';
import JSZip from 'jszip';

import alertsUtil from '@/util/alertsUtil';

import GenericModal from '@shared/components/GenericModal';
import SmallLoader from '@shared/components/SmallLoader';

const FETCH_ERROR = 'FETCH_ERROR';
const TOO_BIG_FILE_ERROR = 'TOO_BIG_FILE_ERROR';
const PATCH_DIFF_MODAL_ID = 'PatchDiffModal';

const PatchDiffModal = ({ url }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const diffNodeRef = useRef(null);

  const getPatchText = async url => {
    if (!url) return;

    const response = await fetch(url);
    const file = await response.blob();
    const zip = await JSZip.loadAsync(file);

    return zip.files['git_diff.patch'].async('text');
  };

  const renderDiff = async url => {
    if (!diffNodeRef.current) return;

    try {
      const patchText = await getPatchText(url);

      // Don't use highlight for files bigger than 100KB (performance issues)
      if (patchText.length > 1024 * 100) {
        setIsLoading(false);
        setError(TOO_BIG_FILE_ERROR);
        return;
      }

      const diff2HtmlUi = new Diff2HtmlUI(diffNodeRef.current, patchText, {
        drawFileList: false,
        matching: 'lines'
      });

      diff2HtmlUi.draw();
    } catch (e) {
      setError(FETCH_ERROR);
    }

    setIsLoading(false);
  };

  const renderError = () => {
    if (error === FETCH_ERROR) {
      return (
        <div className="patch-diff-modal-error">
          There was an error fetching the patch file
        </div>
      );
    }

    if (error === TOO_BIG_FILE_ERROR) {
      return (
        <div className="patch-diff-modal-error">
          The file is too big to display the diff. Please{' '}
          <a href={url} download="patch.zip">
            download
          </a>{' '}
          the patch to view it.
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    renderDiff(url);
  }, [diffNodeRef.current, url]);

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(PATCH_DIFF_MODAL_ID));
  };

  return (
    <GenericModal
      customClass="patch-diff-modal"
      title="Patch diff"
      onClose={handleClose}
    >
      <div className="generic-modal-body">
        <div ref={diffNodeRef} />
        {isLoading && <SmallLoader />}
        {renderError()}
      </div>
    </GenericModal>
  );
};

PatchDiffModal.propTypes = {
  url: PropTypes.string.isRequired
};

export default PatchDiffModal;
