import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import api from '@API/api';
import { PANEL_SECTIONS } from '@mpm-druid/constants';
import { QueryConfig } from '@shared/api';
import {
  NumericalDistributionData,
  IntervalType,
  PredicateQuery
} from '@mpm-druid/types';

type NumericalDistributionParams = {
  from: string;
  to: string;
  intervalType: IntervalType;
  predicates: PredicateQuery[];
  version: string | null;
  name?: string;
  source?: string;
};

const getNumericalDistributions = ({
  ...params
}: NumericalDistributionParams & { modelId: string }) =>
  api.post(`mpm/v2/features/numerical-distribution-pdf`, params);

export function useNumericalDistribution(
  params: NumericalDistributionParams,
  config: QueryConfig<NumericalDistributionData>
) {
  const { modelId } = useParams<{
    modelId: string;
  }>();

  return useQuery(
    [
      PANEL_SECTIONS.NUMERICAL_DISTRIBUTION,
      {
        modelId,
        ...params
      }
    ],
    () =>
      getNumericalDistributions({
        modelId,
        ...params
      }),
    config
  );
}
