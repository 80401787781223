import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import noop from 'lodash/noop';

import { IconButton, Tooltip } from '@ds';
import Popover from '@material-ui/core/Popover';

import AddVisualizationModalBtn from './AddVisualizationModalBtn';
import DuplicateTemplateButton from './DuplicateTemplateButton';
import EditTemplateButton from './EditTemplateButton';
import RemoveTemplateButton from './RemoveTemplateButton';
import { DSSettingsIcon, DSShowMetadataIcon } from '@ds-icons';

const ChartTemplateActions = ({
  editable,
  id,
  isUsingButton,
  name,
  onChangeTemplate,
  onOpenExamples,
  scope,
  type,
  isPy
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderViewInstancesBtn = () => (
    <Tooltip content="Preview" placement="top">
      <IconButton
        className="charts-gallery-actions-icon-button"
        onClick={onOpenExamples}
        type="secondary"
        Icon={<DSShowMetadataIcon />}
      />
    </Tooltip>
  );

  const renderCustomChartActions = () => {
    return (
      <>
        {!isPy && renderViewInstancesBtn()}

        <EditTemplateButton
          editable={editable}
          id={id}
          onChangeTemplate={onChangeTemplate}
        />
        <DuplicateTemplateButton id={id} name={name} />
        <RemoveTemplateButton editable={editable} id={id} scope={scope} />

        <div className="gallery-modal-actions-separator" />
      </>
    );
  };

  const renderActionsMenu = () => {
    return (
      <div
        className={cx('charts-gallery-actions', {
          'use-button': isUsingButton
        })}
      >
        {renderCustomChartActions()}
        <AddVisualizationModalBtn
          chartType={id}
          chartCategory={type}
          isVisibleInRow={!isUsingButton}
        />
      </div>
    );
  };

  const renderActionsMenuBtn = () => {
    return (
      <>
        <IconButton
          type="secondary"
          Icon={<DSSettingsIcon />}
          onClick={handleClick}
        />

        <Popover
          id="simple-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          {renderActionsMenu()}
        </Popover>
      </>
    );
  };

  return isUsingButton ? renderActionsMenuBtn() : renderActionsMenu();
};

ChartTemplateActions.defaultProps = {
  isUsingButton: false,
  onChangeTemplate: noop,
  onOpenExamples: noop
};

ChartTemplateActions.propTypes = {
  editable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isUsingButton: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChangeTemplate: PropTypes.func,
  onOpenExamples: PropTypes.func,
  scope: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isPy: PropTypes.bool.isRequired
};

export default ChartTemplateActions;
