import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import React from 'react';
import VirtualizedGrid from '../GraphicsVirtualizedGrid';
import {
  GALLERY_CONTAINER_HEIGHT,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from '../../../constants/graphics';
import { DSArrowDownIcon } from '@ds-icons';

export function ExpandedItem(props) {
  return (
    <ExpansionPanel {...props} onChange={props.onChange}>
      <ExpansionPanelSummary expandIcon={<DSArrowDownIcon />}>
        <Typography className="category-title">
          <strong>{props.categoryText}:</strong> {props.category} -{' '}
          <span className="categories-count">{props.countText}</span>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <VirtualizedGrid
          itemWidth={IMAGE_WIDTH}
          rowHeight={IMAGE_HEIGHT}
          containerHeight={GALLERY_CONTAINER_HEIGHT}
          items={props.items}
          rowItemsCount={props.rowItemsCount}
          handleOpenImagePreview={props.handleOpenImagePreview}
          handleSelectItem={props.handleSelectItem}
          selectedItems={props.selectedItems}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
