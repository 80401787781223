import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, TextButton } from '@ds';

import { Input, Select } from '@DesignSystem/controllers';
import InputAutoGrow from '@DesignSystem/controllers/Input/InputAutoGrow';

import { CloseModalIcon } from '@Icons-outdated';
import alertsUtil from '@/util/alertsUtil';
import GenericModal from '@shared/components/GenericModal';
import './CreateEditMetricModal.scss';

const METRIC_TYPE_OPTIONS = [
  {
    label: 'Confusion Matrix',
    value: 'CONFUSION_MATRIX'
  },
  {
    label: 'Numerical Features',
    value: 'NUMERICAL_FEATURE'
  },
  {
    label: 'Categorical Features',
    value: 'CATEGORICAL_FEATURE'
  }
];
const initialFormData = { name: '', transformType: '', definition: '' };

const CreateEditMetricModal = ({
  title,
  modalId,
  submitHandler,
  editFormData,
  type
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(editFormData || initialFormData);
  const { name, transformType, definition } = formData;
  const filledData = name && transformType && definition;

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    submitHandler(formData);
    handleClose();
  };

  const renderModalBody = () => {
    return (
      <div className="generic-modal-section">
        <Input
          title="Metric name"
          placeholder="Enter metric name"
          value={name}
          required
          onChange={value => {
            setFormData(prevState => {
              return { ...prevState, name: value };
            });
          }}
          height="36px"
          fontSize="14px"
        />
        <div className="styled-select-label">
          <Select
            label="Metric type"
            placeholder="Select metric type"
            value={transformType}
            required
            onChange={value => {
              setFormData(prevState => {
                return { ...prevState, transformType: value };
              });
            }}
            width="422px"
            options={METRIC_TYPE_OPTIONS}
            variant="outlined"
            withInput={false}
            data-test="select-metric-type"
          />
        </div>
        <InputAutoGrow
          title="Metric definition"
          placeholder="Enter metric definition"
          required
          initialValue={formData?.definition}
          onChange={value => {
            setFormData(prevState => {
              return { ...prevState, definition: value };
            });
          }}
        />
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt create-metric-modal"
      title={title}
      onCancel={handleClose}
      hideFooter
    >
      <span className="close-modal-icon-wrapper" onClick={handleClose}>
        <CloseModalIcon />
      </span>
      <div className="generic-modal-section">{renderModalBody()}</div>
      <div className="create-metric-modal-footer">
        <Button size="large" onClick={handleSubmit} disabled={!filledData}>
          {type === 'create' ? 'Create' : 'Done'}
        </Button>
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
      </div>
    </GenericModal>
  );
};

CreateEditMetricModal.defaultProps = {
  editFormData: null
};

CreateEditMetricModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.isRequired,
  editFormData: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default CreateEditMetricModal;
