import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { first, isEmpty } from 'lodash';

import { RoundedTabs } from '@DesignSystem/navigation';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';
import { Divider } from '@DesignSystem/data-display';
import { Table } from '@DesignSystem/tables';
import { rulesTreeToMPMPredicates } from '@mpm-druid/utils';
import { useFeatures, useFeaturesByName } from '@mpm-druid/api';
import SmallLoader from '@shared/components/SmallLoader';
import BadgeCell from '@shared/components/TableCells/BadgeCell';
import { AreaChartCell, OpenNotificationsCell, LinkCell } from '../Cells';
import { RenderEmptyState } from './RenderEmptyState';
import { FiltersButton } from '../FiltersButton';
import { SortButton } from '../SortButton';
import {
  MODEL_PAGE_SIZES,
  DEFAULT_FEATURES_SORTING,
  FEATURE_LEFT_COLUMNS,
  FEATURE_MODEL_NAME_COLUMN_WIDTH,
  FEATURE_PAGE_SIZES,
  FEATURE_SERVER_COLUMNS_NAMES,
  SOURCE_TYPE,
  FEATURE_COLUMNS,
  uniqueRowId,
  badgeColorsConfig
} from '@mpm-druid/constants';

const dataTypes = [
  {
    cols: ['featureType'],
    cell: ({ value }) => (
      <BadgeCell colorsConfig={badgeColorsConfig} value={value} />
    )
  },
  {
    cols: ['alerts'],
    cell: OpenNotificationsCell
  },
  {
    cols: ['missingValues', 'drift'],
    cell: ({ value, column }) => <AreaChartCell value={value} column={column} />
  },
  {
    cols: ['name'],
    cell: ({ value, row }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const history = useHistory();
      return (
        <LinkCell
          value={value}
          to={`${history.location.pathname}/${row.name}/${row.source}`}
        />
      );
    }
  }
];

const STORAGE_PAGES_KEY = 'features_pageSize';
const noPaddingCols = ['name'];
const disabledColumns = ['name'];

const parseSelection = selection => {
  if (!selection?.length) return [];
  return selection.map(item => {
    const [name, source] = item.split(' ');
    return {
      name,
      source
    };
  });
};

const mapDataToRows = features => {
  return features.map(feature => {
    return {
      id: `${feature.name} ${feature.source}`,
      name: feature.name,
      featureType:
        feature.source === SOURCE_TYPE.model_output_features
          ? 'Output'
          : 'Input',
      metricType: feature.type,
      drift: feature.drift,
      missingValues: feature.missingValues,
      source: feature.source
    };
  });
};

export const ModelFeatures = ({ model }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [columnSorting, setColumnSorting] = useState(DEFAULT_FEATURES_SORTING);
  const [selection, setSelection] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(() => {
    const pages = Number(localStorage.getItem(STORAGE_PAGES_KEY));
    if (MODEL_PAGE_SIZES.includes(pages)) return pages;
    return MODEL_PAGE_SIZES[0];
  });

  const [columnOrder, setColumnOrder] = useState([]);
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState({
    rulesTree: generateEmptyRulesTree(),
    segmentId: ''
  });
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'name', width: FEATURE_MODEL_NAME_COLUMN_WIDTH }
  ]);

  const { modelId } = useParams();

  const pagesSizeChangeHandler = value => {
    setPageSize(value);
    localStorage.setItem(STORAGE_PAGES_KEY, value);
  };

  const disabledFatureSortingColumns = FEATURE_COLUMNS.filter(
    column => column.hideForSorting
  ).map(column => ({
    columnName: column.name,
    sortingEnabled: false
  }));

  const sort = first(columnSorting);
  const sortColumn = FEATURE_SERVER_COLUMNS_NAMES[sort?.columnName];
  const order = sort?.direction;

  const { isLoading, isError, data, isFetching, isPreviousData } = useFeatures(
    {
      modelId,
      page: pageNumber + 1,
      pageSize,
      sortColumn,
      order,
      predicates: query?.rulesTree
        ? rulesTreeToMPMPredicates(query.rulesTree)
        : [],
      includeMetrics: false
    },
    { keepPreviousData: true, refetchOnMount: false }
  );

  useEffect(() => {
    if (isEmpty(selection)) {
      setActiveTabIndex(0);
    }
  }, [selection]);

  const {
    data: selectionRowsData,
    isLoading: isLoadingSelection
  } = useFeaturesByName(
    {
      featureIdentifiers: parseSelection(selection),
      modelId,
      includeMetrics: false
    },
    { refetchOnMount: true, enabled: activeTabIndex === 1 }
  );

  useEffect(() => {
    setPageNumber(0);
  }, [pageSize, query]);

  useEffect(() => {
    if (data) {
      setRows(mapDataToRows(data.features));
    }
  }, [data]);

  const handleSortClick = newSort => {
    setColumnSorting(newSort);
  };

  const rowsFilteredForActiveTab = useMemo(() => {
    if (activeTabIndex === 1 && selectionRowsData) {
      return mapDataToRows(selectionRowsData.features);
    }

    return rows;
  }, [activeTabIndex, rows, selectionRowsData]);

  if (isLoading)
    return (
      <SmallLoader
        primaryMessage={
          <p>
            Fetching features for <b>{model.name}</b>
          </p>
        }
      />
    );

  if (isError)
    return <h1> Error while fetching the model`&apos;`s features. </h1>;

  return (
    <div>
      <div className="model-features-header">
        <div className="model-features-header-top">
          <div className="model-features-header-title">Features</div>

          <FiltersButton
            onChange={setQuery}
            columns={FEATURE_COLUMNS}
            query={query}
          />

          <SortButton
            columnSorting={columnSorting}
            columns={FEATURE_COLUMNS}
            onChange={handleSortClick}
            disabled={false}
          />
        </div>
        <Divider margin={0} />
        <div className="model-features-header-tabs">
          <RoundedTabs
            tabs={[
              { label: 'All' },
              {
                label: selection.length
                  ? `Selected (${selection.length})`
                  : 'Selected',
                disabled: selection.length === 0
              }
            ]}
            activeTabIndex={activeTabIndex}
            onTabChange={setActiveTabIndex}
          />
        </div>
      </div>
      <Table
        shouldHighlightLinks
        isFetching={(isFetching && isPreviousData) || isLoadingSelection}
        dataTypes={dataTypes}
        columns={FEATURE_COLUMNS}
        noPaddingColumns={noPaddingCols}
        maxHeight={276}
        rows={rowsFilteredForActiveTab}
        leftColumns={FEATURE_LEFT_COLUMNS}
        rowHeight="60px"
        rowIdKey={uniqueRowId}
        renderEmptyState={RenderEmptyState}
        totalRowCount={
          activeTabIndex === 0 ? data?.total : rowsFilteredForActiveTab.length
        }
        sortingConfig={{
          isDisabled: false,
          columnSorting,
          disabledColumns: disabledFatureSortingColumns,
          onSortingChange: setColumnSorting
        }}
        paginationConfig={{
          isDisabled: false,
          isServerSidePagination: activeTabIndex !== 1,
          pageNumber,
          pageSize,
          pageSizes: MODEL_PAGE_SIZES,
          FEATURE_PAGE_SIZES,
          onPageNumberChange: setPageNumber,
          onPageSizeChange: pagesSizeChangeHandler
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          minColumnWidth: 220,
          disabledColumns,
          onColumnWidthsChange: setColumnWidths
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        selectionConfig={{
          selection,
          isDisabled: false,
          onSelectionChange: setSelection
        }}
      />
    </div>
  );
};
