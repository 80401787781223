export const flow = ({ dispatch }) => next => action => {
  if (typeof action === 'function') {
    return next(action);
  }

  if (Array.isArray(action)) {
    return action.forEach(a => dispatch(a));
  }

  return next(action);
};
