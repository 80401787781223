import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import constant from 'lodash/constant';
import isRegExp from 'lodash/isRegExp';

import cx from 'classnames';

import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor
} from '@dnd-kit/core';

import {
  arrayMove,
  rectSortingStrategy,
  SortableContext
} from '@dnd-kit/sortable';
import Box from '@material-ui/core/Box';

import { CloseIcon, InfoIcon } from '@Icons-outdated';
import flatten from 'lodash/flatten';

import {
  CHIP_TYPE_BADGE,
  CHIP_TYPE_TAG
} from '@DesignSystem/data-display/Chip/Chip';

import { Chip, SortableChip } from '../../data-display';
import DropdownListPopper from '../DropdownListPopper/DropdownListPopper';

import './MultipleAutocomplete.scss';
import { DSCloseIcon } from '@design-system/icons';

const WAYS_TO_ADD_VALUE = {
  option: 'OPTION',
  input: 'INPUT'
};

export const FILTERING_MODE = {
  STARTS_WITH: 'STARTS_WITH',
  NONE: 'NONE'
};

export const NO_ITEMS_STRATEGY = {
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  NO_MESSAGE: 'NO_MESSAGE',
  SHOW_MESSAGE_WITH_NO_EMPTY_INPUT: 'SHOW_MESSAGE_WITH_NO_EMPTY_INPUT'
};

const checkSeparatorMatch = (value, separator) => {
  if (isRegExp(separator)) {
    return value?.match(separator);
  }

  return value.includes(separator);
};
const getAnArrayValue = value => flatten([value]);

const MultipleAutocomplete = ({
  autoFocus,
  clearAll,
  onClearAll,
  noValuePlaceholder,
  withValuePlaceholder,
  label,
  description,
  options,
  value,
  onChangeValue,
  filterValue,
  onChangeFilterValue,
  renderListItem,
  renderChipLabel,
  separator,
  checkValueToAdd,
  filterMode,
  disabled,
  classes,
  sortable,
  loading,
  chipType,
  SuffixIcon,
  noItems,
  noItemsStrategy,
  checkChipValidity,
  errorLabel,
  renderAdditionalContent,
  maxValuesLimit
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const anchorEl = useRef(null);
  const inputEl = useRef(null);

  const handleFocusInput = () => setIsInputFocused(true);
  const handleBlurInput = () => setIsInputFocused(false);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2
      }
    })
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = value.indexOf(active.id);
      const newIndex = value.indexOf(over.id);

      onChangeValue(arrayMove(value, oldIndex, newIndex));
    }
  };

  const handleInputClick = () => {
    setIsDropdownOpen(true);
    if (inputEl.current) {
      inputEl.current.focus();
    }
  };

  const handleCloseMenu = () => {
    setIsDropdownOpen(false);
    onChangeFilterValue('');
  };

  const removeValue = valueToRemove => {
    const newValue = value.filter(
      currentValue => currentValue !== valueToRemove
    );
    onChangeValue(newValue);
  };

  // valueToAdd might be an array, might be a constant
  const addValue = (valueToAdd, addingWay = WAYS_TO_ADD_VALUE.option) => {
    const handledValueToAdd = getAnArrayValue(valueToAdd);

    const newValue = [...value];

    handledValueToAdd?.forEach(handledValue => {
      if (checkValueToAdd(handledValue)) {
        newValue.push(handledValue);
      }
    });

    if (newValue.length >= maxValuesLimit) {
      handleCloseMenu();
    }

    onChangeValue(newValue, addingWay);
  };

  // valueToAdd might be either an array or a single value
  const addValueViaInput = valueToAdd => {
    const handledValues = getAnArrayValue(valueToAdd);
    const newValuesToAdd = [];

    handledValues.forEach(newValue => {
      if (!value.includes(newValue) && newValue?.length) {
        newValuesToAdd.push(newValue);
      }
    });

    if (newValuesToAdd?.length) {
      addValue(newValuesToAdd, WAYS_TO_ADD_VALUE.input);
    }
    onChangeFilterValue('');
  };

  const handleChangeInputValue = e => {
    const { value: inputValue } = e.target;
    setIsDropdownOpen(true);

    if (separator && checkSeparatorMatch(inputValue, separator)) {
      const valuesToAdd = inputValue.split(separator);
      if (valuesToAdd?.length) {
        addValueViaInput(valuesToAdd);
      }
    } else {
      onChangeFilterValue(inputValue);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      addValueViaInput(filterValue);
    } else if (e.key === 'Backspace' && !filterValue?.length) {
      removeValue(value[value.length - 1]);
    }
  };

  const handleClickMenuOption = optionValue => {
    if (inputEl.current) {
      inputEl.current.focus();
    }

    if (!value.includes(optionValue)) {
      addValue(optionValue);
    }
  };

  const handleCloseChip = chipValue => () => {
    removeValue(chipValue);
  };

  const filteredOptions = useMemo(() => {
    if (isFunction(filterMode)) {
      return filterMode(options, filterValue);
    }

    switch (filterMode) {
      case FILTERING_MODE.STARTS_WITH:
        return options.filter(
          option =>
            !filterValue ||
            option.value.toLowerCase().startsWith(filterValue.toLowerCase())
        );
      case FILTERING_MODE.NONE:
        return options;
      default:
        return options;
    }
  }, [options, filterValue, filterMode]);

  const placeholder = value?.length ? withValuePlaceholder : noValuePlaceholder;

  const calculateIsPopperOpened = () => {
    switch (noItemsStrategy) {
      case NO_ITEMS_STRATEGY.SHOW_MESSAGE:
        return loading || isDropdownOpen;
      case NO_ITEMS_STRATEGY.NO_MESSAGE:
        return loading || (!!filteredOptions?.length && isDropdownOpen);
      case NO_ITEMS_STRATEGY.SHOW_MESSAGE_WITH_NO_EMPTY_INPUT:
        return loading || (isDropdownOpen && !!filterValue?.length);
      default:
        return isDropdownOpen;
    }
  };

  const renderChip = (chipLabel, chipValue, isValid = true) => {
    if (sortable) {
      return (
        <SortableChip
          classes="chip-container"
          key={chipValue}
          id={chipValue}
          label={chipLabel}
          onClose={handleCloseChip(chipValue)}
          type={chipType}
        />
      );
    }

    return (
      <div className="chip-container" key={chipValue}>
        <Chip
          label={chipLabel}
          onClose={handleCloseChip(chipValue)}
          type={chipType}
          invalid={!isValid}
        />
      </div>
    );
  };

  const renderDefaultChipsContainer = () => {
    return (
      <div
        className={cx(
          'autocomplete-chips-container',
          clearAll && value?.length && 'withRemoveAll'
        )}
      >
        {clearAll && value?.length ? (
          <Box className="autocomplete-fixed-wrapper">
            <Box className="autocomplete-remove-all">
              <CloseIcon onClick={onClearAll} />
            </Box>
          </Box>
        ) : null}
        {value.map(currentValue => {
          const foundOption = options.find(
            option => option.value === currentValue
          );

          const chipLabel =
            renderChipLabel !== noop
              ? renderChipLabel(foundOption, currentValue)
              : foundOption?.label || currentValue;

          const chipValidity = checkChipValidity(currentValue);

          return renderChip(chipLabel, currentValue, chipValidity);
        })}
      </div>
    );
  };

  const renderSortableChipsContainer = () => {
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={value} strategy={rectSortingStrategy}>
          {renderDefaultChipsContainer()}
        </SortableContext>
      </DndContext>
    );
  };

  const renderChipsContainer = () => {
    if (sortable) {
      return renderSortableChipsContainer();
    }

    return renderDefaultChipsContainer();
  };

  return (
    <div
      className={cx('ds-multiple-autocomplete-container', classes, {
        sortable
      })}
    >
      <div className="autocomplete-label">
        <span>{label}</span>
      </div>
      {description && (
        <div className="autocomplete-description">{description}</div>
      )}
      <div
        className={cx('autocomplete-input-container', {
          focused: isInputFocused,
          disabled,
          'has-suffix': Boolean(SuffixIcon)
        })}
        ref={anchorEl}
        onClick={handleInputClick}
      >
        {renderChipsContainer()}
        {!disabled && (
          <input
            placeholder={placeholder}
            value={filterValue}
            onChange={handleChangeInputValue}
            ref={inputEl}
            onKeyDown={handleKeyDown}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            autoFocus={autoFocus}
          />
        )}
        {Boolean(value.length) && (
          <div onClick={() => onChangeValue([])} className="clearIconContainer">
            <DSCloseIcon />
          </div>
        )}
        {Boolean(SuffixIcon) && <div className="suffix">{SuffixIcon}</div>}
      </div>

      {renderAdditionalContent()}

      {errorLabel && (
        <div className="error-label">
          <InfoIcon />
          {errorLabel}
        </div>
      )}

      <DropdownListPopper
        anchorEl={anchorEl?.current}
        open={calculateIsPopperOpened()}
        onClose={handleCloseMenu}
        items={filteredOptions}
        value={value}
        onClick={handleClickMenuOption}
        renderListItem={renderListItem}
        width={anchorEl?.current?.offsetWidth}
        classes={classes}
        loading={loading}
        noItems={noItems}
      />
    </div>
  );
};

MultipleAutocomplete.propTypes = {
  noValuePlaceholder: PropTypes.string,
  withValuePlaceholder: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.array,
  filterValue: PropTypes.string,
  onChangeFilterValue: PropTypes.func,
  onChangeValue: PropTypes.func,
  options: PropTypes.array,
  renderAdditionalContent: PropTypes.func,
  renderListItem: PropTypes.func,
  renderChipLabel: PropTypes.func,
  separator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp)
  ]),
  checkValueToAdd: PropTypes.func,
  filterMode: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([FILTERING_MODE.STARTS_WITH, FILTERING_MODE.NONE])
  ]),
  onClearAll: PropTypes.func,
  clearAll: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  sortable: PropTypes.bool,
  loading: PropTypes.bool,
  chipType: PropTypes.oneOf([CHIP_TYPE_TAG, CHIP_TYPE_BADGE]),
  SuffixIcon: PropTypes.object,
  noItems: PropTypes.func,
  noItemsStrategy: PropTypes.oneOf([
    NO_ITEMS_STRATEGY.NO_MESSAGE,
    NO_ITEMS_STRATEGY.SHOW_MESSAGE,
    NO_ITEMS_STRATEGY.SHOW_MESSAGE_WITH_NO_EMPTY_INPUT
  ]),
  checkChipValidity: PropTypes.func,
  errorLabel: PropTypes.string,
  maxValuesLimit: PropTypes.number
};

MultipleAutocomplete.defaultProps = {
  noValuePlaceholder: '',
  withValuePlaceholder: '',
  label: '',
  description: '',
  value: [],
  filterValue: '',
  options: [],
  onChangeFilterValue: noop,
  onChangeValue: noop,
  onClearAll: noop,
  renderListItem: noop,
  renderChipLabel: noop,
  separator: ',',
  checkValueToAdd: noop,
  renderAdditionalContent: noop,
  filterMode: FILTERING_MODE.STARTS_WITH,
  clearAll: false,
  disabled: false,
  classes: 'list',
  autoFocus: false,
  sortable: false,
  loading: false,
  chipType: CHIP_TYPE_TAG,
  SuffixIcon: null,
  noItems: null,
  noItemsStrategy: NO_ITEMS_STRATEGY.NO_MESSAGE,
  checkChipValidity: constant(true),
  errorLabel: null,
  maxValuesLimit: Infinity
};

export default MultipleAutocomplete;
