import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import noop from 'lodash/noop';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'fast-deep-equal';

import QueryFiltersBody from '@shared/components/QueryFiltersBody';
import QueryFiltersHeader from '@shared/components/QueryFiltersHeader';
import {
  getOperatorsForRule,
  getRulesCount
} from '@shared/utils/filterHelpers';

import { constructFilterDefinition } from '@experiment-management-shared/utils/constructFilterDefinition';
import useFiltersLibraryManagement from '@experiment-management-shared/hooks/useFiltersLibraryManagement';
import { FILTER_BEHAVIOUR_MODE } from '@shared/components/QueryFiltersBody/QueryFiltersBody';
import { Button, TextButton } from '@ds';

import useProject from '@API/project/useProject';

import styles from './QueryBuilderSidebar.module.scss';

const QueryBuilderSidebar = ({ onChange, query }) => {
  const { data: project, isLoading: isLoadingProject } = useProject();
  const { rulesTree, segmentId } = query;
  const rulesCount = getRulesCount(rulesTree);
  const { canEdit } = project;

  const handleClearRulesTree = useCallback(() => {
    onChange({ segmentId: '' });
  }, [onChange]);

  const handleFilterUpdate = useCallback(
    (newFilterId, newRulesTree) => {
      // in case user removed selected filter we need to clear queryBuilderId
      if (newFilterId) {
        onChange({ rulesTree: newRulesTree, segmentId: newFilterId });
      } else {
        handleClearRulesTree();
      }
    },
    [handleClearRulesTree, onChange]
  );

  const {
    isLoading,
    columns,
    filters,
    activeFilter,
    handleSaveFilter,
    handleSelectFilter,
    handleDeleteFilter
  } = useFiltersLibraryManagement({
    filterId: segmentId,
    rulesTree,
    onChange: handleFilterUpdate
  });

  const hasUnsavedChanges = useMemo(() => {
    return !isEqual(rulesTree, activeFilter?.rulesTree);
  }, [activeFilter, rulesTree]);

  useEffect(() => {
    onChange({ rulesTree: cloneDeep(activeFilter?.rulesTree) });
  }, [activeFilter, onChange]);

  if (isLoading || isLoadingProject) {
    return null;
  }

  const handleChangeRulesTree = newRulesTree => {
    // in case user manually removed all rules we need as well clear queryBuilderId not to force user save empty rules list to library
    if (getRulesCount(newRulesTree)) {
      onChange({ rulesTree: newRulesTree });
    } else {
      handleClearRulesTree();
    }
  };

  return (
    <div
      className={cx(
        'modal-sidebar-body',
        'custom-visualisation',
        styles.container
      )}
    >
      <div className={styles.innerContainer}>
        <div className={styles.filtersHeaderContainer}>
          <div className={styles.title}>Filters</div>
          <QueryFiltersHeader
            compactAlert
            canEdit={canEdit}
            activeFilter={activeFilter}
            filters={filters}
            onSelectFilter={handleSelectFilter}
            onDeleteFilter={handleDeleteFilter}
          />
        </div>
        <div className={styles.filtersBodyContainer}>
          <QueryFiltersBody
            mode={FILTER_BEHAVIOUR_MODE.OR_AND}
            columns={columns}
            editable={canEdit}
            rulesTree={rulesTree}
            onChange={handleChangeRulesTree}
            getOperatorsForRule={getOperatorsForRule}
            constructFilterDefinition={constructFilterDefinition}
          />
        </div>
        <div className={styles.filtersFooterContainer}>
          <TextButton onClick={handleClearRulesTree} disabled={!rulesCount}>
            Clear
          </TextButton>
          <Button onClick={handleSaveFilter} disabled={!hasUnsavedChanges}>
            Save Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

QueryBuilderSidebar.defaultProps = {
  onChange: noop
};

QueryBuilderSidebar.propTypes = {
  onChange: PropTypes.func,
  query: PropTypes.object.isRequired
};

export default QueryBuilderSidebar;
