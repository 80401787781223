import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Typography from '@material-ui/core/Typography';
import classNames from '@shared/components/RegisterModel/RegisterModel.module.scss';

const RegisterModelSubtitleLabel = ({ text, isFirstModelRegistration }) => (
  <Typography
    variant="text2"
    noWrap
    className={cx(
      classNames.registerModelSubtitle,
      isFirstModelRegistration && classNames.registerModelSubtitleNoMargin
    )}
  >
    {text}
  </Typography>
);

RegisterModelSubtitleLabel.defaultProps = {
  text: ''
};

RegisterModelSubtitleLabel.propTypes = {
  isFirstModelRegistration: PropTypes.bool.isRequired,
  text: PropTypes.string
};

export default RegisterModelSubtitleLabel;
