import { useQuery, useQueryClient } from 'react-query';
import useProject from '@API/project/useProject';
import { useCallback } from 'react';

const ENABLED_STATE = {
  isAutoExpand: true
};

const DISABLED_STATE = {
  isAutoExpand: false
};

const useExpandedExperimentsGroupsInvalidator = () => {
  const { data: project } = useProject();
  const projectId = project?.projectId;
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ['experimentGroups', { projectId, invalidator: true }],
    () => Promise.resolve(ENABLED_STATE)
  );

  const disableAutoGroupExpand = useCallback(() => {
    queryClient.setQueryData(
      ['experimentGroups', { projectId, invalidator: true }],
      DISABLED_STATE
    );
  }, [projectId, queryClient]);

  const invalidateExpandedGroups = useCallback(() => {
    queryClient.setQueryData(
      ['experimentGroups', { projectId, invalidator: true }],
      ENABLED_STATE
    );
  }, [projectId, queryClient]);

  return {
    isAutoGroupExpandEnabled: data?.isAutoExpand ?? true,
    disableAutoGroupExpand,
    invalidateExpandedGroups
  };
};

export default useExpandedExperimentsGroupsInvalidator;
