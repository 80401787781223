import defaultTo from 'lodash/defaultTo';
import chartHelpers from '../utils/chartHelpers';

type Experiment = {
  experimentKey: string;
  Name?: string;
  runActive: boolean;
};

type TitleProps = {
  aggregation?: string;
  metricName?: string;
  metrics?: unknown[];
  paramName?: string;
  selectedTargetVariable?: string;
  selectedXAxis?: string;
  selectedYAxis?: string | string[];
  selectedZAxis?: string | null;
};

export type UsePanelConfigsParams = {
  chartName: string;
  chartType: string;
  experiments: Experiment[];
  isAutoRefreshEnabled: boolean;
  title?: string;
  titleProps?: TitleProps;
};

const usePanelConfigs = ({
  chartName,
  chartType,
  experiments,
  isAutoRefreshEnabled,
  title,
  titleProps
}: UsePanelConfigsParams) => {
  const defaultTitle =
    chartName || chartHelpers.getDefaultChartNameByType(chartType, titleProps);

  return {
    shouldRefetch:
      isAutoRefreshEnabled &&
      experiments.some(experiment => experiment.runActive),
    actualTitle: defaultTo(title, defaultTitle)
  };
};

export default usePanelConfigs;
