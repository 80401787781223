import React from 'react';
import PropTypes from 'prop-types';

import './ConfusionMatrix.scss';

export const MatrixLegend = ({ ranges, isPercentage }) => {
  const colorFragments = [...ranges]
    .reverse()
    .map(({ color, maxValue, minValue }, revertedIndex) => {
      const suffix = isPercentage ? '%' : !revertedIndex ? '+' : '';
      let topValueText = null;

      if (isPercentage && !revertedIndex) {
        topValueText = (
          <div className="confusion-matrix-chart-colorbar-fragment-text confusion-matrix-chart-colorbar-fragment-top-text">
            {maxValue}
            {suffix}
          </div>
        );
      }

      return (
        <div
          key={color}
          className="confusion-matrix-chart-colorbar-fragment-container"
        >
          <div
            className="confusion-matrix-chart-colorbar-fragment"
            style={{ backgroundColor: color }}
          />
          {topValueText}
          <div className="confusion-matrix-chart-colorbar-fragment-text">
            {minValue}
            {suffix}
          </div>
        </div>
      );
    });

  const colorBar = (
    <div className="confusion-matrix-chart-colorbar">
      <div className="confusion-matrix-chart-colorbar-fragments">
        {colorFragments}
      </div>
    </div>
  );

  return colorBar;
};

MatrixLegend.defaultProps = {
  isPercentage: false
};

MatrixLegend.propTypes = {
  ranges: PropTypes.array.isRequired,
  isPercentage: PropTypes.bool
};
