import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';

const ArtifactAliases = ({ aliases }) => {
  if (!aliases || isEmpty(aliases)) return null;

  return (
    <div className="flex">
      {aliases.map(alias => {
        return (
          <span key={alias} className="artifact-table-cell-alias">
            {toUpper(alias)}
          </span>
        );
      })}
    </div>
  );
};

ArtifactAliases.defaultProps = {
  aliases: []
};

ArtifactAliases.propTypes = {
  aliases: PropTypes.array
};

export default ArtifactAliases;
