import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 13,
    fontWeight: 500,
    backgroundColor: 'rgb(36 36 36/90%)',
    padding: 10,
    lineHeight: 1.4
  },
  arrow: {
    fill: 'rgb(36 36 36/90%)'
  }
});

const StyledValidationTooltip = ({ title, children, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      classes={classes}
      title={title}
      placement="left"
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
};

StyledValidationTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired
};

export default StyledValidationTooltip;
