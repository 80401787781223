import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { Button, TextButton } from '@ds';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import useModelRegistryDeleteMutation from '@model-registry/api/useModelRegistryDeleteMutation';
import useModelRegistryUpdateMutation from '@model-registry/api/useModelRegistryUpdateMutation';
import ManageModelDescription from '@model-registry/components/ManageModelModal/ManageModelDescription';
import ManageModelName from '@model-registry/components/ManageModelModal/ManageModelName';
import ManageModelVisibilityToggle from '@model-registry/components/ManageModelModal/ManageModelVisibilityToogle';
import GenericModal from '@shared/components/GenericModal';

import alertsUtil from '../../../../util/alertsUtil';
import modelRegistryActions from '../../../../actions/modelRegistryActions';
import { getModelNameValidation } from '../../../../reducers/modelRegistryReducer';
import { DISABLE_PUBLIC_ACCESS } from '../../../../constants/configConstants';
import {
  MODEL_SCOPE_TYPES,
  INPUT_VALIDATION_FREQUENCY
} from '../../../../constants/modelRegistryConstants';

const { PUBLIC, PRIVATE } = MODEL_SCOPE_TYPES;

const ManageModelModal = ({
  dispatch,
  modalId,
  model,
  modelNameValidation,
  workspace
}) => {
  const currentScopeType = model.isPublic ? PUBLIC : PRIVATE;
  const [modelName, setModelName] = useState(model.modelName);
  const [modelDescription, setModelDescription] = useState(model.description);
  const [scopeType, setScopeType] = useState(currentScopeType);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [isInvalidModelName, setIsInvalidModelName] = useState(false);

  const handleValidateModelName = newModelName =>
    dispatch(modelRegistryActions.validateModelName(workspace, newModelName));

  useEffect(() => {
    handleValidateModelName(model.modelName);

    return () => {
      dispatch(modelRegistryActions.setModelNameValidation());
    };
  }, []);

  const debouncedValidateModelName = useCallback(
    debounce(handleValidateModelName, INPUT_VALIDATION_FREQUENCY),
    []
  );

  useEffect(() => {
    const { validName, modelNameExists } = modelNameValidation;
    if (!validName || modelNameExists) {
      setIsInvalidModelName(true);
    }
  }, [modelNameValidation]);

  const modelRegistryDeleteMutation = useModelRegistryDeleteMutation(workspace);
  const modelRegistryUpdateMutation = useModelRegistryUpdateMutation(
    {
      workspaceName: workspace,
      currentModelName: model.modelName
    },
    true
  );

  const handleClose = () => dispatch(alertsUtil.closeDialog(modalId));

  const handleUpdateModel = () => {
    modelRegistryUpdateMutation.mutate({
      registryModelId: model.registryModelId,
      description: modelDescription,
      isPublic: scopeType === PUBLIC,
      currentModelName: model.modelName,
      newModelName: modelNameValidation.finalModelName
    });
    setModelName('');
    handleClose();
  };

  const handleDeleteModel = () => {
    const onConfirm = () => {
      modelRegistryDeleteMutation.mutate(model.modelName);
      handleClose();
    };

    dispatch(
      modelRegistryActions.openDeleteModelConfirm(
        workspace,
        model.modelName,
        onConfirm
      )
    );
  };

  const handleModelNameChange = e => {
    const newModelName = e.target.value;
    setModelName(newModelName);
    setIsFieldsChanged(true);
    setIsInvalidModelName(false);

    if (!isEmpty(newModelName)) {
      debouncedValidateModelName(newModelName);
    }
  };

  const handleDescriptionChange = e => {
    setModelDescription(e.target.value);
    setIsFieldsChanged(true);
  };

  const handleSwitchChange = () => {
    setScopeType(prevScopeType =>
      prevScopeType === PRIVATE ? PUBLIC : PRIVATE
    );

    setIsFieldsChanged(true);
  };

  const isNameChangeInvalid = () => {
    const { finalModelName, modelNameExists } = modelNameValidation;

    if (isEqual(modelName, model.modelName)) return false;

    return isEmpty(finalModelName) || modelNameExists;
  };

  const isSubmitBtnDisabled = () =>
    isEmpty(modelName) || !isFieldsChanged || isNameChangeInvalid();

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title="Manage Model"
      onClose={handleClose}
      onCancel={handleClose}
      hideFooter
      isButtonDisabled
    >
      <DialogContent className="generic-modal-body">
        <ManageModelName
          modelName={modelName}
          model={model}
          modelNameValidation={modelNameValidation}
          handleModelNameChange={handleModelNameChange}
          isInvalidModelName={isInvalidModelName}
        />
        <ManageModelDescription
          handleDescriptionChange={handleDescriptionChange}
          modelDescription={modelDescription}
        />
        <ManageModelVisibilityToggle
          DISABLE_PUBLIC_ACCESS={DISABLE_PUBLIC_ACCESS}
          scopeType={scopeType}
          handleSwitchChange={handleSwitchChange}
        />
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton className="button" onClick={handleDeleteModel}>
          Delete Model
        </TextButton>
        <Button
          className="button blue submit-btn"
          onClick={handleUpdateModel}
          disabled={isSubmitBtnDisabled()}
        >
          Update
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

ManageModelModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  modelNameValidation: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    modelNameValidation: getModelNameValidation(state)
  };
};

export default connect(mapStateToProps)(ManageModelModal);
