import React from 'react';
import { Tooltip } from '@ds';
import { ComparisonTableCellRendererData } from '../types';
import styles from './ComparisonTableCell.module.scss';

type ComparisonTableKeyCellProps = ComparisonTableCellRendererData<string>;

const ComparisonTableKeyCell: React.FC<ComparisonTableKeyCellProps> = ({
  value
}) => {
  return (
    <div className={styles.comparisonTableExperimentCell}>
      <Tooltip content={value} placement="top" truncateOverflow>
        <>{value}</>
      </Tooltip>
    </div>
  );
};

export default ComparisonTableKeyCell;
