import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import uniq from 'lodash/uniq';
import isUndefined from 'lodash/isUndefined';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { SORT_TYPES } from '@experiment-management-shared/constants/tabConfigs';
import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';

import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import useAssetsForExperiments from '@experiment-management-shared/api/useAssetsForExperiments';
import useSelectedExperiments from '@experiment-details/hooks/useSelectedExperiments';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';

import SmallLoader from '@shared/components/SmallLoader';
import { generateRegexFromString } from '@shared/utils/displayHelpers';
import ExperimentAssetsConfigBar from '@experiment-details/components/ExperimentAssetsConfigBar';
import FullHeightToggle from './FullHeightToggle';
import MultipleTextTable from './MultipleTextTable';
import TextGroupedBy from './TextGroupedBy';
import CompareContentSelector from '@experiment-details/components/CompareContentSelector';

import styles from './TextTab.module.scss';
import TextTabEmpty from './TextTabEmpty';
import { TableMaxHeightProvider } from '@DesignSystem/tables';

const TEXT_ASSET_TYPE = 'text-sample';

const filterAssets = ({ query = '' }) => asset => {
  const regexExpression = generateRegexFromString(query);
  const { metadata } = asset;

  let metadataValue;
  try {
    const metadataObject = JSON.parse(metadata) || {};
    metadataValue = Object.values(metadataObject).join(' ');
  } catch (_) {
    metadataValue = '';
  }

  if (!metadataValue || !regexExpression) return false;

  return regexExpression.test(metadataValue);
};

const TextTab = ({ experiments }) => {
  const [assets, setAssets] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [, setAssetsHeightMap] = useState({});
  const [isFullHeight, setIsFullHeight] = useState(false);
  const [experiment] = experiments;

  const isComparePage = useSelector(selectIsComparePage);

  const { experiment1, experiment2 } = useSelectedExperiments(experiments);

  const experimentKeys = useMemo(() => {
    if (!experiment2?.experimentKey) {
      return [experiment1.experimentKey];
    }

    return [experiment1.experimentKey, experiment2.experimentKey];
  }, [experiment1.experimentKey, experiment2?.experimentKey]);

  const { data: assetSummaries, isLoading } = useAssetsForExperiments(
    {
      experiments,
      type: TEXT_ASSET_TYPE
    },
    {
      refetchInterval: false
    }
  );

  const assetsWithMetadata = useMemo(() => {
    return assetSummaries.map(asset => {
      let metadataObject;

      try {
        metadataObject = JSON.parse(asset.metadata) || {};
      } catch (_) {
        metadataObject = {};
      }

      return {
        ...asset,
        metadataObject
      };
    });
  }, [assetSummaries]);

  const metadataValues = useMemo(() => {
    const cellNames = assetsWithMetadata.reduce(
      (result, { metadataObject }) => {
        const metadataKeys = Object.keys(metadataObject);

        return result.concat(metadataKeys);
      },
      []
    );

    return uniq(cellNames).map(value => ({
      label: value,
      value: `metadataObject.${value}`
    }));
  }, [assetsWithMetadata]);

  const groupByOptions = useMemo(
    () => [
      { value: false, label: 'None' },
      { value: SORT_TYPES.STEP, label: 'Step' },
      { value: SORT_TYPES.CONTEXT, label: 'Context' },
      ...metadataValues
    ],
    [metadataValues]
  );

  const sortByOptions = useMemo(
    () => [{ value: SORT_TYPES.STEP, label: 'Step' }, ...metadataValues],
    [metadataValues]
  );

  const handleAssetsSearch = ({ assets, groupBy }) => {
    setAssets(assets);
    setGroupBy(groupBy);
  };

  const handleFullHeightChange = useCallback(checked => {
    setIsFullHeight(checked);
    setAssetsHeightMap({});
  }, []);

  const handleSetAssetHeight = useCallback((assetId, height) => {
    setAssetsHeightMap(state => {
      return {
        ...state,
        [assetId]: height
      };
    });
  }, []);

  const handleGetAssetHeight = useCallback(
    assetId => {
      let height;

      setAssetsHeightMap(state => {
        if (!isUndefined(state[assetId])) {
          height = state[assetId];
        }
        return state;
      });

      return height ?? (isFullHeight ? 'auto' : null);
    },
    [isFullHeight]
  );

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  const renderContent = () => {
    if (groupBy) {
      return (
        <TextGroupedBy
          assets={assets}
          isFullHeight={isFullHeight}
          experimentKeys={experimentKeys}
          setAssetHeight={handleSetAssetHeight}
          getAssetHeight={handleGetAssetHeight}
        />
      );
    }

    return (
      <TableMaxHeightProvider>
        <MultipleTextTable
          assets={assets}
          isFullHeight={isFullHeight}
          experimentKeys={experimentKeys}
          setAssetHeight={handleSetAssetHeight}
          getAssetHeight={handleGetAssetHeight}
        />
      </TableMaxHeightProvider>
    );
  };

  const isTabEmpty = !assetsWithMetadata.length;

  return (
    <div className={cx(styles.container, { [styles.compare]: isComparePage })}>
      <ExperimentAssetsConfigBar
        assets={assetsWithMetadata}
        buttons={
          <FullHeightToggle
            enabled={isFullHeight}
            onChange={handleFullHeightChange}
          />
        }
        experimentKey={experiment.experimentKey}
        filterAssets={filterAssets}
        groupByOptions={groupByOptions}
        onAssetsSearch={handleAssetsSearch}
        sortByOptions={sortByOptions}
        valuePathCategory={EXPERIMENT_VIEW_TAB_FIELDS.TEXT}
        TextSearchProps={{
          label: 'Search metadata',
          placeholder: 'Metadata (regex)'
        }}
        hideStepPlayer
        enableAutoResearch
        isTabEmpty={isTabEmpty}
      />

      {isComparePage && (
        <CompareContentSelector
          experiments={experiments}
          experiment1={experiment1}
          experiment2={experiment2}
        />
      )}

      {isTabEmpty && !isComparePage ? <TextTabEmpty /> : renderContent()}
    </div>
  );
};

TextTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default TextTab;
