import { useSelector } from 'react-redux';

import { getIsFirstSession } from '@/reducers/userReducer';

const useIsFirstSession = () => {
  const isFirstSession = useSelector(getIsFirstSession);

  return isFirstSession;
};

export default useIsFirstSession;
