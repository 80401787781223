import React from 'react';
import { StyledTooltip } from '@DesignSystem/data-display';
import { Handle, Position } from 'react-flow-renderer';
import cx from 'classnames';
import RedirectLink from '@shared/components/RedirectLink';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';

export const LineageNodeType = {
  EXPERIMENT: 'EXPERIMENT',
  MODEL_VERSION: 'MODEL_VERSION',
  ARTIFACT_VERSION: 'ARTIFACT_VERSION'
};

export const LineageNodeTypeText = {
  EXPERIMENT: 'Experiment',
  MODEL_VERSION: 'Model',
  ARTIFACT_VERSION: 'Artifact'
};

const TypeToResourceMap = {
  [LineageNodeType.ARTIFACT_VERSION]: REDIRECT_LINK_RESOURCES.ARTIFACT_VERSION,
  [LineageNodeType.EXPERIMENT]: REDIRECT_LINK_RESOURCES.EXPERIMENT,
  [LineageNodeType.MODEL_VERSION]: REDIRECT_LINK_RESOURCES.MODEL_REGISTRY
};

/* @TODO send the version from the backend */
export const getArtifactNameAndVersion = (label = '') => {
  const n = label.lastIndexOf('/');
  const name = label?.slice(0, n)?.trim();
  const version = label?.slice(n + 1)?.trim();

  return {
    name,
    version
  };
};

type LineageLayoutFlowNodeProps = {
  data: {
    label: string;
    url: string;
    deleted: boolean;
  };
  id: string;
  sourcePosition: Position | 'none';
  targetPosition: Position | 'none';
  type: keyof typeof LineageNodeType;
  currentVersion: string;
};

export const LineageLayoutFlowNode: React.FC<LineageLayoutFlowNodeProps> = ({
  data,
  id,
  sourcePosition,
  targetPosition,
  type,
  currentVersion
}) => {
  const { label, deleted } = data;

  const isCurrentVersion = id === currentVersion;

  return (
    <div
      className={cx('lineage-flow-node', type, {
        currentVersion: isCurrentVersion,
        deleted
      })}
    >
      <StyledTooltip title={label}>
        <div className="wrapper">
          <div className="box-color">
            {deleted && <p className="lineage-deleted"> Deleted </p>}
          </div>
          <div className="info">
            <RedirectLink resource={TypeToResourceMap[type]} args={[id]}>
              {label}
            </RedirectLink>
          </div>
          {sourcePosition !== 'none' && (
            <Handle type="source" position={sourcePosition} id={id} />
          )}

          {targetPosition !== 'none' && (
            <Handle type="target" position={targetPosition} id={id} />
          )}
        </div>
      </StyledTooltip>
    </div>
  );
};
