export const DEFAULT_OPEN_MODEL_REGISTRY_TAB_NAME = 'assets';

export const DETAIL_VIEW_TABS = {
  ASSETS: DEFAULT_OPEN_MODEL_REGISTRY_TAB_NAME,
  LINEAGE: 'lineage',
  NOTES: 'notes',
  API: 'api',
  HISTORY: 'history'
};

export const DETAIL_VIEW_TABS_ORDER = [
  DETAIL_VIEW_TABS.ASSETS,
  DETAIL_VIEW_TABS.LINEAGE,
  DETAIL_VIEW_TABS.NOTES,
  DETAIL_VIEW_TABS.API,
  DETAIL_VIEW_TABS.HISTORY
];

export const HEADER_TABS_LABEL_MAP = {
  [DETAIL_VIEW_TABS.ASSETS]: 'Assets',
  [DETAIL_VIEW_TABS.LINEAGE]: 'Lineage',
  [DETAIL_VIEW_TABS.NOTES]: 'Notes',
  [DETAIL_VIEW_TABS.API]: 'API',
  [DETAIL_VIEW_TABS.HISTORY]: 'History'
};

export const DETAIL_VIEW_TABS_ORDER_VIEW_ONLY = [
  DETAIL_VIEW_TABS.ASSETS,
  DETAIL_VIEW_TABS.LINEAGE,
  DETAIL_VIEW_TABS.NOTES,
  DETAIL_VIEW_TABS.HISTORY
];

export const HEADER_TABS = [
  {
    id: DETAIL_VIEW_TABS.ASSETS,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.ASSETS]
  },
  {
    id: DETAIL_VIEW_TABS.LINEAGE,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.LINEAGE]
  },
  {
    id: DETAIL_VIEW_TABS.NOTES,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.NOTES]
  },
  {
    id: DETAIL_VIEW_TABS.API,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.API]
  },
  {
    id: DETAIL_VIEW_TABS.HISTORY,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.HISTORY]
  }
];

export const HEADER_TABS_VIEW_ONLY = [
  {
    id: DETAIL_VIEW_TABS.ASSETS,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.ASSETS]
  },
  {
    id: DETAIL_VIEW_TABS.LINEAGE,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.LINEAGE]
  },
  {
    id: DETAIL_VIEW_TABS.NOTES,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.NOTES]
  },
  {
    id: DETAIL_VIEW_TABS.HISTORY,
    label: HEADER_TABS_LABEL_MAP[DETAIL_VIEW_TABS.HISTORY]
  }
];
