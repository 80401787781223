import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRightIcon } from '@Icons-outdated';
import noop from 'lodash/noop';

import styles from './ImageCard.module.scss';

const ImageCard = ({
  imgSrc,
  title,
  description,
  linkText,
  linkURL,
  onClickLink
}) => {
  return (
    <div className={styles.imageCardContainer}>
      <div>
        <img src={imgSrc} alt={title} />

        <div className={styles.labelContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>

      {linkURL && linkText && (
        <div className={styles.linkContainer}>
          <a
            href={linkURL}
            className={styles.imageCardLink}
            target="_blank"
            rel="noreferrer"
            onClick={onClickLink}
          >
            {linkText}
            <ArrowRightIcon />
          </a>
        </div>
      )}
    </div>
  );
};

ImageCard.defaultProps = {
  title: '',
  description: '',
  linkText: '',
  linkURL: '',
  onClickLink: noop
};

ImageCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  onClickLink: PropTypes.func
};

export default ImageCard;
