import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import useIsUserVerified from '@API/auth/useIsUserVerified';
import { resendVerificationEmail } from '@account-settings/api';
import { getUserEmail } from '@/reducers/userReducer';
import './VerifyEmailLink.scss';
import { BasicModal } from '@DesignSystem/modals';

function VerifyEmailLink() {
  const [isModalOpen, setIsModalOpen] = useState();
  const email = useSelector(getUserEmail);
  const resendVerificationEmailMutation = useMutation(resendVerificationEmail);
  const { data: isVerified } = useIsUserVerified();

  const renderVerifyEmailContent = useMemo(
    () => (
      <div className="verify-email-modal">
        <p>
          Email verification sent to <span>{email} </span>
        </p>
        Please check your email and click the ‘Confirm email’ button in order to
        receive comet emails and notifications.
      </div>
    ),
    [email]
  );

  if (isVerified) return null;

  return (
    <>
      <p className="verify-user-email-wrapper">
        In order to receive comet emails and notifications &nbsp;
        <a>
          <span
            onClick={() => {
              setIsModalOpen(true);
              resendVerificationEmailMutation.mutate();
            }}
          >
            verify your email
          </span>
        </a>
      </p>
      <BasicModal
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen && resendVerificationEmailMutation.isSuccess}
        title="Email Verification"
        primaryButtonText="Got it"
        onPrimaryButtonClick={() => setIsModalOpen(false)}
        content={renderVerifyEmailContent}
      />
    </>
  );
}

export default VerifyEmailLink;
