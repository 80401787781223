import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router';

import {
  PANEL_PREVIEW_PATH,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';
import CompareExperimentsRedirect from '@experiment-details/components/ExperimentDetails/CompareExperimentsRedirect';
import SingleExperimentDetailsRoute from '@experiment-details/components/ExperimentDetails/ExperimentDetailsRoutes/SingleExperimentDetailsRoute';
import ComparisonExperimentDetailRoute from '@experiment-details/components/ExperimentDetails/ExperimentDetailsRoutes/ComparisonExperimentDetailRoute';
import ProjectTypePageSelector from '@shared/components/ProjectTypePageSelector';
import EMProjectPageHeader from '@shared/components/EMProjectPageHeader';
import ProjectPageWrapper from '@shared/components/ProjectPageWrapper';
import LLMProjectPageHeader from '@shared/components/LLMProjectPageHeader';
import { LLM_TAB_PATHNAME } from '@experiment-management-shared/constants/llmConstants';
import LLMDashboardPage from '@projects/components/LLMDashboardPage/LLMDashboardPage';
import EMDashboardPage from '@projects/components/EMDashboardPage';
import NotesPage from '@projects/components/NotesPage';
import VizEditor from '@experiment-management-shared/components/VizEditor';

const PREFIX_VIZ_EDITOR_PATHS = [
  '',
  '/view/:viewId',
  '/view/:viewId/archive',
  '/view/:viewId/experiments',
  '/view/:viewId/panels',
  '/compare',
  '/:experimentKey',
  '/archive/:experimentKey',
  '/reports/template/:reportName',
  '/reports/template/:reportName/:editMode',
  '/reports/:reportName',
  '/reports/:reportName/:editMode'
];

const VIZ_EDITOR_PATHS = [
  `/${SELECT_PANEL_PATH}/new-custom-panel`,
  `/${SELECT_PANEL_PATH}/edit-code/:codeTemplateId/:revisionId?`,
  `/${SELECT_PANEL_PATH}/${PANEL_PREVIEW_PATH}/edit-code/:codeTemplateId/:revisionId?`
];

// combine all the possibilities for the PREFIX + VIZ PATHS
const getVizEditorPaths = matchPath => {
  return PREFIX_VIZ_EDITOR_PATHS.reduce((paths, prefixPath) => {
    const prefixPaths = VIZ_EDITOR_PATHS.map(path => {
      return `${matchPath}${prefixPath}${path}`;
    });

    return paths.concat(prefixPaths);
  }, []);
};

const DashboardRoutes = ({ match }) => (
  <Switch>
    {/* This route is using to redirect from old structure of url to new one */}
    <Route
      path={[
        `${match.path}/:baseExperimentKey/:compareExperimentKey/compare`,
        `${match.path}/archive/:baseExperimentKey/:compareExperimentKey/compare`
      ]}
      exact
      component={CompareExperimentsRedirect}
    />
    <Route
      path={[`${match.path}/compare`, `${match.path}/archive/compare`]}
      exact
      render={() => <ComparisonExperimentDetailRoute />}
    />
    <Route
      path={getVizEditorPaths(match.path)}
      exact
      render={() => (
        <ProjectTypePageSelector
          EMComponent={<ProjectPageWrapper content={<VizEditor />} />}
        />
      )}
    />
    <Route
      path={`${match.path}/notes`}
      exact
      render={() => (
        <ProjectTypePageSelector
          EMComponent={
            <ProjectPageWrapper
              header={<EMProjectPageHeader />}
              content={<NotesPage />}
            />
          }
        />
      )}
    />
    <Route
      path={[
        match.path,
        `${match.path}/view/:viewId?`,
        `${match.path}/archive`,
        `${match.path}/experiments`,
        `${match.path}/panels`,
        `${match.path}/view/:viewId/:section`,
        `${match.path}/${LLM_TAB_PATHNAME.prompts}`
      ]}
      exact
      render={() => (
        <ProjectTypePageSelector
          EMComponent={<EMDashboardPage />}
          LLMComponent={
            <ProjectPageWrapper
              header={<LLMProjectPageHeader />}
              content={<LLMDashboardPage />}
            />
          }
        />
      )}
    />

    <Route
      path={[
        `${match.path}/archive/:experimentKey`,
        `${match.path}/:experimentKey`
      ]}
      render={() => <SingleExperimentDetailsRoute />}
    />
  </Switch>
);

DashboardRoutes.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(DashboardRoutes);
