/* eslint-disable react/require-default-props */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import { LegendPill } from './LegendPill';
import { useDetectYScroll } from '../../../hooks';
import Expander from './Expander';
import styles from './Legend.module.scss';

const DEFAULT_LEGEND_HEIGHT = '100px';

export const TableLegend = ({ pills, onHover, onUnhover }) => {
  const [expanded, setExpanded] = useState(false);
  const legendRef = useRef();

  const { hasScroll } = useDetectYScroll(legendRef);

  const expandhandler = () => {
    setExpanded(prevState => !prevState);
  };

  const sortedPills = useMemo(() => {
    return sortBy(pills, ['text']);
  }, [pills]);

  const maxHeightValue = useMemo(() => {
    return expanded ? '100%' : DEFAULT_LEGEND_HEIGHT;
  }, [expanded]);

  return (
    <div className={styles.legendWrapper}>
      <div
        style={{ maxHeight: maxHeightValue }}
        className={styles.contentWrapper}
        ref={legendRef}
      >
        <div className={styles.pillsWrapper}>
          {sortedPills.map(point => {
            const id = point.id;
            return (
              <LegendPill
                id={id}
                onMouseEnter={onHover}
                onMouseLeave={onUnhover}
                key={id}
                dotColor={point.dotColor}
                text={point.text}
              />
            );
          })}
        </div>
      </div>
      {hasScroll && (
        <div className={styles.expanderWrapper}>
          <Expander expandhandler={expandhandler} />
        </div>
      )}
    </div>
  );
};

TableLegend.propTypes = {
  pills: PropTypes.array.isRequired,
  onHover: PropTypes.func.isRequired,
  onUnhover: PropTypes.func.isRequired
};
