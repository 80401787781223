import { onboardingActionTypes } from '@/constants/actionTypes';
import { CODE_FRAMEWORKS_LIST } from '@onboarding/constants';

const onboardingActions = {
  setFramework(frameworkValue) {
    const framework = CODE_FRAMEWORKS_LIST.find(
      fw => fw.value === frameworkValue
    );

    if (!framework) return;

    return dispatch => {
      dispatch({
        type: onboardingActionTypes.SET_FRAMEWORK,
        payload: { framework }
      });
    };
  }
};

export default onboardingActions;
