import React from 'react';
import { useSearchListContext } from '../useSearchListContext';

import classes from '../List.module.scss';

const DefaultNotFound = () => <span> No search results</span>;

type ListNotFoundProps = {
  children?: React.ReactNode;
};
const ListNotFound: React.FC<ListNotFoundProps> = ({ children }) => {
  const { isNotFound } = useSearchListContext();

  if (!isNotFound) return null;

  return (
    <div className={classes.searchableListContainerEmpty}>
      {children || <DefaultNotFound />}
    </div>
  );
};

export default ListNotFound;
