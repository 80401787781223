import api from '@shared/api';
import { useQuery } from 'react-query';

const getExperimentAllOutput = async ({ experimentKey, signal }) => {
  const { data } = await api.get('data-fetch/get/outputAll', {
    params: { experimentKey },
    signal
  });

  return data;
};

export default function useExperimentAllOutput({ experimentKey }, config) {
  return useQuery(
    ['experiment-all-output', { experimentKey }],
    ({ signal }) => {
      return getExperimentAllOutput({
        experimentKey,
        signal
      });
    },
    { enabled: !!experimentKey, refetchOnMount: true, ...config }
  );
}
