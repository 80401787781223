import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SettingsPanel from './SettingsPanel';
import { IS_REGEX, IS_EXACT_MATCH } from './constants';
import cx from 'classnames';
import { IconButton, SearchInput } from '@ds';
import { DSSettingsIcon } from '@design-system/icons';

const Searchbar = ({
  onTextChange,
  isExactMatch,
  setIsExactMatch,
  isRegex,
  setIsRegex,
  disabled
}) => {
  const [text, setText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const isSettingsOpen = Boolean(anchorEl);
  const handleCheckboxChange = value => {
    let newIsExactMatch;
    let newIsRegex;

    if (value === IS_EXACT_MATCH) {
      newIsExactMatch = !isExactMatch;
      newIsRegex = false;
    } else if (value === IS_REGEX) {
      newIsRegex = !isRegex;
      newIsExactMatch = false;
    }

    setIsRegex(newIsRegex);
    setIsExactMatch(newIsExactMatch);
  };

  useEffect(() => {
    onTextChange(text, isRegex, isExactMatch);
  }, [text, isRegex, isExactMatch]);

  return (
    <div className="searchbox">
      <div className={cx('textbox-container assets', { disabled })}>
        <div className="searchbox__search-field">
          <SearchInput
            placeholder="Search file or directory"
            onChange={e => setText(e.target.value)}
            value={text}
            disabled={disabled}
          />
        </div>

        <div className="settings-button">
          <IconButton
            onClick={event =>
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }
            type="primary"
            Icon={<DSSettingsIcon />}
            disabled={disabled}
          />
          <SettingsPanel
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            open={isSettingsOpen}
            handleCheckboxChange={handleCheckboxChange}
            isRegex={isRegex}
            isExactMatch={isExactMatch}
          />
        </div>
      </div>
    </div>
  );
};

Searchbar.propTypes = {
  onTextChange: PropTypes.func.isRequired,
  setIsExactMatch: PropTypes.func.isRequired,
  setIsRegex: PropTypes.func.isRequired,
  isRegex: PropTypes.bool.isRequired,
  isExactMatch: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

export default Searchbar;
