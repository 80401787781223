import { syntaxTree } from '@codemirror/language';
import { Diagnostic } from '@codemirror/lint';
import { EditorView } from '@uiw/react-codemirror';
import {
  getArgumentNumber,
  getText,
  isLabelColumn,
  isPredictionColumn
} from './utils';

export const customSqlLinter = (columnNames: string[]) => {
  return (view: EditorView): readonly Diagnostic[] => {
    const diagnostics: Diagnostic[] = [];
    const tree = syntaxTree(view.state);

    tree.iterate({
      enter: node => {
        const name = node.name;
        const text = getText(view, node);

        if (node.type.id === 0) {
          const from = node.from,
            to = node.to;
          diagnostics.push({
            from,
            to,
            severity: 'error',
            message: 'Invalid syntax'
          });
        } else if (name === 'ColumnName') {
          const columnName = text.slice(1, -1);
          if (!columnNames.includes(columnName)) {
            diagnostics.push({
              from: node.from,
              to: node.to,
              severity: 'error',
              message: 'Invalid column name'
            });
          } else if (
            node.node.parent?.name === 'CategoricalCometFunction' ||
            node.node.parent?.name === 'NumericalCometFunction'
          ) {
            const argumentNumber = getArgumentNumber(node.node);

            if (argumentNumber === 0 && !isLabelColumn(columnName)) {
              diagnostics.push({
                from: node.from,
                to: node.to,
                severity: 'error',
                message:
                  'Invalid column name, this argument should be a label column'
              });
            } else if (
              argumentNumber === 1 &&
              !isPredictionColumn(columnName)
            ) {
              diagnostics.push({
                from: node.from,
                to: node.to,
                severity: 'error',
                message:
                  'Invalid column name, this argument should be a predicate column'
              });
            }
          }
        }
      }
    });

    return diagnostics;
  };
};
