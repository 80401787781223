import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

type AlertRuleDeleteMutationProps = {
  ruleId: string[];
  deleteCallback: (id: string) => void;
};

export function useAlertRuleDelete() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ ruleId }: AlertRuleDeleteMutationProps) => {
      return api.delete(
        `mpm/${activeWorkspace?.id}/models/${modelId}/alert-rules/${ruleId}`
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries([
          'alert-rules',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        await queryClient.cancelQueries([
          'alertRulesById',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
      },
      onSuccess: (data, variables) => {
        const { ruleId, deleteCallback } = variables;
        deleteCallback(ruleId[0]);
        queryClient.invalidateQueries([
          'alert-rules',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        queryClient.invalidateQueries([
          'alertRulesById',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_DELETE_ALERT_RULE,
            'Alert rule was successfully deleted.'
          )
        );
      }
    }
  );
}
