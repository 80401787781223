import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'fast-deep-equal';
import pick from 'lodash/pick';
import {
  BATCH_CHART_CONFIG,
  BUILT_IN_CHART_TYPES,
  CUSTOM_CHART_TYPE
} from '../../lib/appConstants';

const { COLUMNS, MIN_COLUMN_WIDTH, MAX_COLUMN_HEIGHT } = BATCH_CHART_CONFIG;

const MAX_GRID_HEIGHT = 10000;
const MAX_ITERATIONS_FOR_SQUASHING = 10000;

export const cleanLayoutToCompare = (layout, needId = true) => {
  const compareFields = ['w', 'h', 'minW', 'maxH', 'x', 'y'];

  if (needId) {
    compareFields.push('i');
  }

  return layout.map(l => pick(l, compareFields));
};

export const getDefaultHW = (chartType, defaultConfig) => {
  switch (chartType) {
    case CUSTOM_CHART_TYPE:
      if (defaultConfig) {
        try {
          const parsedConfig = JSON.parse(defaultConfig);

          const { h = 1, w = 2 } = parsedConfig;
          return { h, w };
        } catch (e) {
          return {};
        }
      }
      break;
    case BUILT_IN_CHART_TYPES.curves:
      return { w: 3, h: 1 };
    case BUILT_IN_CHART_TYPES.image:
    case BUILT_IN_CHART_TYPES.video:
    case BUILT_IN_CHART_TYPES.pcd:
      return { w: 4, h: 2 };
    case BUILT_IN_CHART_TYPES.data:
      return { w: 6, h: 2 };
    default:
      break;
  }
  return {};
};

export const getInitialChartLayout = ({
  chartId,
  h = 1,
  layout,
  entireRowCharts,
  w: wInit
}) => {
  const w = wInit || (!layout && entireRowCharts ? COLUMNS : MIN_COLUMN_WIDTH);

  return {
    i: chartId,
    minW: MIN_COLUMN_WIDTH,
    x: 0,
    y: 0,
    w,
    h,
    maxH: MAX_COLUMN_HEIGHT
  };
};

const areLayoutsOverlaps = (layout1, layout2) =>
  layout1.x < layout2.x + layout2.w &&
  layout1.x + layout1.w > layout2.x &&
  layout1.y < layout2.y + layout2.h &&
  layout1.y + layout1.h > layout2.y;

const calculatePositionForUnplacedChart = (
  initialChartLayout,
  existingLayout
) => {
  for (let i = 0; i <= MAX_GRID_HEIGHT; i++) {
    for (let j = 0; j <= COLUMNS - initialChartLayout.w; j++) {
      const chartLayout = { ...initialChartLayout, x: j, y: i };
      if (!existingLayout.some(l => areLayoutsOverlaps(l, chartLayout))) {
        return chartLayout;
      }
    }
  }

  return initialChartLayout;
};

const squashLayoutVerticallyOneByOne = layout => {
  for (let i = 0; i < layout.length; i++) {
    const panelLayout = layout[i];
    // try if the current layout can be moved top
    if (panelLayout.y > 0) {
      const possiblePanelLayout = { ...panelLayout, y: panelLayout.y - 1 };
      const isNotOverlap = layout.every(
        l =>
          !areLayoutsOverlaps(l, possiblePanelLayout) ||
          l.i === possiblePanelLayout.i
      );

      if (isNotOverlap) {
        // here the original array item is mutated
        panelLayout.y = panelLayout.y - 1;
        return true;
      }
    }
  }

  return false;
};

const squashPanelsLayoutVertically = layout => {
  const retVal = cloneDeep(layout);
  let needToSquash = true;
  let iterationsCount = 0;

  while (needToSquash) {
    needToSquash = squashLayoutVerticallyOneByOne(retVal);
    iterationsCount += 1;

    // in case something went wrong with optimisation we need to stop it to prevent browser hanging
    if (needToSquash && iterationsCount > MAX_ITERATIONS_FOR_SQUASHING) {
      needToSquash = false;
    }
  }

  return retVal;
};

export const initializeSearchLayout = ({ panels, layout = [] }) => {
  const existingPanelsIds = panels.map(({ chartId }) => chartId);

  const existingLayoutItems = layout.filter(l =>
    existingPanelsIds.includes(l.i)
  );

  // sort layout items according to visual position on layout
  const sortedExistingLayoutItems = existingLayoutItems.sort((l1, l2) => {
    if (l1.y === l2.y) {
      return l1.x - l2.x;
    } else {
      return l1.y - l2.y;
    }
  });

  const retVal = [];

  sortedExistingLayoutItems.forEach(chartLayout => {
    retVal.push(calculatePositionForUnplacedChart(chartLayout, retVal));
  });

  return retVal;
};

export const initializeLayout = ({
  panels,
  initialLayout,
  entireRowCharts = false,
  sizeMap = undefined
}) => {
  const filteredPanelsById = panels.filter(({ chartId }) => !!chartId);
  const existingPanelsIds = panels.map(({ chartId }) => chartId);
  const potentialLayout = squashPanelsLayoutVertically(
    (initialLayout ?? []).filter(l => existingPanelsIds.includes(l.i))
  );

  return filteredPanelsById.map(({ chartId, chartType, defaultConfig }) => {
    // try to find already saved layout for the chart
    let chartLayout = potentialLayout.find(l => l.i === chartId);

    if (!chartLayout) {
      let { h, w } = getDefaultHW(chartType, defaultConfig);

      if (sizeMap && sizeMap[chartId]) {
        h = sizeMap[chartId].h;
        w = sizeMap[chartId].w;
      }

      const initialChartLayout = getInitialChartLayout({
        chartId,
        h,
        w,
        layout: potentialLayout,
        entireRowCharts
      });

      chartLayout = calculatePositionForUnplacedChart(
        initialChartLayout,
        potentialLayout
      );
      // we need to add just calculated chart position to our potentialLayout
      // to prevent taking the same position for new charts
      potentialLayout.push(chartLayout);
    }

    return chartLayout;
  });
};

export const getMaxLayoutY = layout => {
  return layout.reduce(
    (lowestY, grid) => Math.max(lowestY, grid?.y + grid?.h),
    0
  );
};

export const shiftLayoutByY = (layout, shift) =>
  layout.map(grid => ({ ...grid, y: grid.y + shift }));

export const areLayoutsArraysEqual = (layout1, layout2) => {
  return isEqual(
    cleanLayoutToCompare(sortBy(layout1 || [], l => l.i)),
    cleanLayoutToCompare(sortBy(layout2 || [], l => l.i))
  );
};

export const calculateLayoutForAddingPanel = (panels, layout, sizeMap) => {
  const calculatedLayout = initializeLayout({
    panels,
    initialLayout: layout,
    entireRowCharts: false,
    sizeMap
  });

  return {
    panels,
    layout: calculatedLayout
  };
};

export const calculateLayoutForRemovingPanel = (
  panels,
  layout,
  previousPanels
) => {
  // in case we are deleting panel and our existing layout is null,
  // we need to calculate layout to panels before deleting and only after that
  // recalculate layout after deleting panel
  let calculatedLayout = layout;

  if (layout === null) {
    calculatedLayout = initializeLayout({
      panels: previousPanels,
      initialLayout: layout,
      entireRowCharts: false
    });
  }

  return calculateLayoutForAddingPanel(panels, calculatedLayout);
};
