import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  menu: {
    fontSize: 14
  },
  icon: {
    fontSize: 14,
    marginRight: 9,
    marginTop: -2
  }
});

const StyledMenuItem = ({ Icon, text, ...menuItemProps }, ref) => {
  const classes = useStyles();
  return (
    <MenuItem ref={ref} className={classes.menu} {...menuItemProps}>
      {Icon && <Icon className={classes.icon} />} {text}
    </MenuItem>
  );
};

const StyledMenuItemRef = forwardRef(StyledMenuItem);

StyledMenuItemRef.defaultProps = {
  Icon: null
};

StyledMenuItemRef.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  text: PropTypes.string.isRequired
};

export default StyledMenuItemRef;
