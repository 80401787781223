import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import DocumentTitle from 'react-document-title';

import useSetProjectToRedux from '@experiment-management-shared/hooks/useSetProjectToRedux';
import useProject from '@API/project/useProject';
import PrivateProjectMessage from '@shared/components/PrivateProjectMessage';
import SmallLoader from '@shared/components/SmallLoader';
import { PROJECT_TYPE } from '@/constants/projectConstants';

const ProjectTypePageSelector = ({ EMComponent, LLMComponent }) => {
  const { projectName, workspace } = useParams();
  const { data: project, isError, isLoading } = useProject(
    {},
    { retry: false }
  );

  // @todo: fallback until we migrate all components that takes project related information from redux
  const hasProjectIdBeenSaved = useSetProjectToRedux({ isLoading, project });

  if (isError) {
    return <PrivateProjectMessage />;
  }

  if (isLoading || !hasProjectIdBeenSaved) {
    return (
      <div id="table" className="table-wrapper">
        <DocumentTitle title={`${workspace}/${projectName} · Loading`}>
          <SmallLoader
            primaryMessage="Loading..."
            secondaryMessage={
              <span>
                Fetching project data for <b>{projectName}</b>
              </span>
            }
          />
        </DocumentTitle>
      </div>
    );
  }

  if (project.type === PROJECT_TYPE.LLM) {
    return LLMComponent;
  }

  return EMComponent;
};

ProjectTypePageSelector.defaultProps = {
  EMComponent: null,
  LLMComponent: null
};

ProjectTypePageSelector.propTypes = {
  EMComponent: PropTypes.node,
  LLMComponent: PropTypes.node
};

export default ProjectTypePageSelector;
