import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import {
  ModelsPipelineDeleteIcon,
  ModelsPipelineReviewIcon
} from '@Icons-outdated';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import last from 'lodash/last';

import { StyledTooltip } from '@DesignSystem/data-display';
import {
  AREACHART_Y_RANGE,
  MODEL_COLUMNS_NAMES,
  MODELS_GROUPED_ROW_NAME,
  MODELS_GROUPED_COLUMN_NAME
} from '../../constants';
import { useDeletePipelineMutation } from '../../api';
import { DAGPreviewWithProvider } from '../DAGContent';
import AreaChart from './AreaChart';
import EmptyCell from '@shared/components/TableCells/EmptyCell';
import classes from './Cells.module.scss';

const NO_DECIMALS_COLUMNS = [MODEL_COLUMNS_NAMES.numberOfPredictions];

const columnsWithYLimited = ['drift', 'modelHealth'];

const AreaChartCell = ({ value, column }) => {
  const yRange = columnsWithYLimited.includes(column?.name)
    ? AREACHART_Y_RANGE
    : null;
  const hasData = value?.length > 0;

  return (
    <Box
      display="flex"
      overflow="hidden"
      alignItems="center"
      justifyContent="start"
    >
      <StyledTooltip
        placement="top"
        title={
          hasData
            ? `${column.title} of the last 30 days`
            : 'No data collected over the last 30 days'
        }
      >
        <div>
          {hasData ? (
            <AreaChart
              data={value}
              layout={{ showticklabels: false }}
              style={{ marginRight: '10px' }}
              yRange={yRange}
            />
          ) : (
            <EmptyCell />
          )}
        </div>
      </StyledTooltip>
      <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {hasData &&
          last(value)?.y?.toFixed(
            NO_DECIMALS_COLUMNS.includes(column.name) ? 0 : 2
          )}
      </p>
    </Box>
  );
};

const LinkCell = ({ value, row }) => (
  <Box display="flex">
    <Link
      style={{ color: 'black' }}
      to={`/${row.currentWorkspaceName}/model-production-monitoring/${row.id}`}
    >
      <span style={{ textDecoration: 'underline' }}>{value}</span>
    </Link>
  </Box>
);

export const OpenNotificationsCell = ({ value }) => {
  const hasOpenNotifications = value > 0;
  return (
    <Box alignItems="center" justifyContent="center">
      <span
        className={cx('notifications-indicator', {
          'has-open-notifications': hasOpenNotifications
        })}
      />
      {hasOpenNotifications ? `${value} Alerts` : ''}
    </Box>
  );
};

const GroupedModelsPipeline = ({ row, groupedColumnRowsData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isModelPipelineGroup = row.groupedBy === MODELS_GROUPED_COLUMN_NAME;
  const isModelsGroup = row.key === MODELS_GROUPED_ROW_NAME;
  const currentRowData = groupedColumnRowsData.find(
    rowData => rowData.pipelineName === row.key
  );
  const deletePipeline = useDeletePipelineMutation();

  const { currentWorkspaceName, pipelineModelId, id } = currentRowData || {};

  const deletePipelineHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    deletePipeline.mutate({ pipelinelId: id });
  };

  const handleOpenPipelineModal = event => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(true);
  };

  const handleCloseModal = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(false);
  };

  if (isModelPipelineGroup) {
    return (
      <div className={classes.pipelineGroupContentWrapper}>
        {isModelsGroup && (
          <span className={classes.pipelineGroupedValue}>
            {String(row.value)}
          </span>
        )}
        {!isModelsGroup && (
          <>
            <Link
              to={`/${currentWorkspaceName}/model-production-monitoring/${pipelineModelId}`}
            >
              <span className={classes.pipelineGroupedValue}>
                {String(row.value)}
              </span>
            </Link>
            <span className={classes.pipelineActionsWrapper}>
              <>
                <FullWidthBasicModal
                  open={isOpen}
                  onClose={handleCloseModal}
                  content={
                    <DAGPreviewWithProvider
                      graphViewJson={currentRowData?.graphViewJson}
                    />
                  }
                  withoutFooter
                  customHeaderContent={
                    <span className="header-section">
                      {currentRowData?.pipelineName}
                    </span>
                  }
                  className="DAGModal"
                />
              </>
              <Tooltip
                title="View DAG"
                placement="top"
                arrow
                classes={{
                  tooltip: classes.iconTooltip,
                  arrow: classes.iconTooltipArrow
                }}
              >
                <span className={classes.iconWrapper}>
                  <ModelsPipelineReviewIcon
                    className={classes.pipelineActionIcon}
                    onClick={event => handleOpenPipelineModal(event)}
                    data-test={`${row.value}-view-dag`}
                  />
                </span>
              </Tooltip>
              <Tooltip
                title="Delete DAG"
                placement="top"
                arrow
                classes={{
                  tooltip: classes.iconTooltip,
                  arrow: classes.iconTooltipArrow
                }}
              >
                <span className={classes.iconWrapper}>
                  <ModelsPipelineDeleteIcon
                    className={classes.pipelineActionIcon}
                    onClick={event => deletePipelineHandler(event)}
                    data-test={`${row.value}-delete-dag`}
                  />
                </span>
              </Tooltip>
            </span>
          </>
        )}
      </div>
    );
  }
};

GroupedModelsPipeline.propTypes = {
  row: PropTypes.object.isRequired,
  groupedColumnRowsData: PropTypes.array.isRequired
};

export { LinkCell, GroupedModelsPipeline, AreaChartCell };
