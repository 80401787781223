import { OptionProps, components } from 'react-select';
import React from 'react';
import { OptionType } from '../../../../types';
import OptionItem from './OptionItem/OptionItem';

const isExtraOption = (data: OptionType, selectedOption: OptionType) => {
  if (!data.extraOptions?.length) {
    return false;
  }

  return data.extraOptions.some(
    extraOption => extraOption.value === selectedOption.value
  );
};

const CustomOption = (props: OptionProps<OptionType>) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { onMouseMove, onMouseOver, ...restProps } = props.innerProps;
  const newProps = { ...props, innerProps: restProps };
  if (props.data.render) {
    return (
      <components.Option {...props}>
        {props.data.render(props.data)}
      </components.Option>
    );
  }

  const valueOptions = [...props.getValue()];
  const onSelect = (newValue: OptionType) => {
    const isExtraOptionValue = isExtraOption(props.data, newValue);
    if (isExtraOptionValue) {
      if (newValue.isDisabled) return;

      return props.selectOption(newValue);
    }

    if (props.data.groupName || props.data.isDisabled) return;

    props.selectOption(newValue);
  };

  return (
    <components.Option
      {...newProps}
      innerProps={{
        ...newProps.innerProps,
        onClick: e => {
          e.nativeEvent.stopImmediatePropagation();
        }
      }}
    >
      <OptionItem
        valueOptions={valueOptions}
        data={props.data}
        onSelect={onSelect}
        isOptionDisabled={props.isDisabled && !props.isSelected}
        isExtraDisabled={props.selectProps.selectionLimitReached}
        hideSecondary={props.selectProps.hideSecondary}
        truncateMiddle={props.selectProps.truncateMiddle}
        maxWidth={props.selectProps.maxWidth}
        tooltipPlacement={props.selectProps.tooltipPlacement}
        dataTest="select-option-item"
      />
    </components.Option>
  );
};

export default CustomOption;
