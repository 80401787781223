import cx from 'classnames';
import get from 'lodash/get';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import useProject from '@API/project/useProject';
import useSetProjectToRedux from '@experiment-management-shared/hooks/useSetProjectToRedux';
import { DashboardView } from '@experiment-management-shared/types';
import useDuplicateReportMutation from '@reports/api/useDuplicateReportMutation';
import useReport from '@reports/api/useReport';
import useReportRevisions from '@reports/api/useReportRevisions';
import useUpsertReportMutation from '@reports/api/useUpsertReportMutation';
import ReportAuthor from '@reports/components/ReportAuthor';
import ReportDescription from '@reports/components/ReportDescription';
import ReportHeader from '@reports/components/ReportHeader';
import ReportName from '@reports/components/ReportName';
import ReportSections from '@reports/components/ReportSections';
import { Report, ReportSection } from '@reports/types';
import {
  addViewToReport,
  generateNewReport,
  normalizeReport
} from '@reports/utils';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import { ReportEditMode } from '@routes/types';
import { reportRoute } from '@routes/utils/reports';
import { NEW_REPORT_NAME } from '@shared/constants';
import useStackState from '@shared/hooks/useStackState';
import { trackEvent } from '@shared/utils/eventTrack';

import DuplicateItemModal from '@reports/components/DuplicateItemModal';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import { viewEvents } from '@/constants/trackingEventTypes';
import PageMeta from '@/helpers/PageMeta';
import alertsUtil from '@/util/alertsUtil';
import SmallLoader from '@shared/components/SmallLoader';

import styles from './ReportPage.module.scss';

const { CATCH_ERROR_API, DUPLICATE_ITEM_MODAL } = dialogTypes;

const goToHash = (hash: string) => {
  if (hash === '') return;

  // Push onto callback queue so it runs after the DOM is updated,
  // this is required when navigating from a different page so that
  // the element is rendered on the page before trying to getElementById.
  setTimeout(() => {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, 0);
};

interface ReportPathParams {
  editMode?: string;
  projectName: string;
  reportName: string;
  workspace: string;
}

export type ReportPageProps = {
  isTemplate?: boolean;
};

const ReportPage = ({ isTemplate = false }: ReportPageProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    editMode: pathEditMode = '',
    projectName,
    reportName,
    workspace
  } = useParams<ReportPathParams>();
  const newReport = useMemo(generateNewReport, []);
  const isNewReport = reportName === NEW_REPORT_NAME;
  const initialReport = isNewReport ? newReport : null;
  const isValidEditMode = Object.values(REPORT_EDIT_MODES).includes(
    pathEditMode as ReportEditMode
  );
  const editMode: ReportEditMode = isValidEditMode
    ? (pathEditMode as ReportEditMode)
    : '';

  const [revisionId, setRevisionId] = useState<string | null>(null);
  const {
    pop: handleUndoReportChange,
    push: setReport,
    set: initializeReport,
    stack: reportStack,
    value: report
  } = useStackState<Report | null>({ initialValue: initialReport });

  const author = get(report, 'author');
  const avatarURL = get(report, 'authorProfileImageUrl');
  const description = get(
    report,
    ['data', 'description'],
    get(report, 'description')
  );
  const displayName = get(report, ['data', 'displayName']);
  const name = get(report, ['data', 'reportName'], get(report, 'reportName'));
  const reportId = get(report, 'reportId');
  const sections = get(report, ['data', 'sections']);

  const { data: project, isLoading: isProjectLoading } = useProject();
  const { data: savedReport, isLoading: isReportLoading } = useReport(
    { isTemplate, revisionId, reportName },
    { enabled: !isNewReport }
  );
  const { data: revisions = [] } = useReportRevisions(
    { reportId },
    { enabled: !isTemplate && !!reportId }
  );
  const upsertReportMutation = useUpsertReportMutation();
  const duplicateReportMutation = useDuplicateReportMutation();
  const canEdit = project?.canEdit;
  const projectId = project?.projectId;

  // @todo: fallback until we migrate all components that takes project related information from redux
  const hasProjectIdBeenSaved = useSetProjectToRedux({
    isLoading: isProjectLoading,
    project
  });

  useEffect(() => {
    trackEvent(viewEvents.REPORT_VIEWED, {
      isTemplate,
      projectName,
      reportName,
      workspace
    });
  }, [isTemplate, projectName, reportName, workspace]);

  // On first load / change revision / save report -> initialize the stack
  useEffect(() => {
    if (!savedReport) return;

    const normalizedReport = normalizeReport(savedReport);

    initializeReport(normalizedReport);
  }, [dispatch, history, initializeReport, savedReport]);

  // Add project view to report
  const hasAppliedViewRef = useRef(false);
  const dashboardData = location.state as {
    dashboard: DashboardView;
    name: string;
  } | null;
  useEffect(() => {
    if (
      !dashboardData ||
      (!isNewReport && !savedReport) ||
      hasAppliedViewRef.current
    ) {
      return;
    }

    const normalizedReport =
      isNewReport || !savedReport ? newReport : normalizeReport(savedReport);

    const reportWithView = addViewToReport({
      isNewReport,
      report: normalizedReport,
      dashboardData
    });

    initializeReport(reportWithView);
    hasAppliedViewRef.current = true;

    if (dashboardData.name) {
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_ADD_VIEW_TO_REPORT,
          `Successfully added ${dashboardData.name} view to your report.`
        )
      );
    }
  }, [
    dispatch,
    history,
    initializeReport,
    isNewReport,
    newReport,
    savedReport,
    dashboardData
  ]);

  useEffect(() => {
    if (projectId && !canEdit) {
      const viewURL = reportRoute({
        isTemplate,
        projectName,
        reportName,
        workspaceName: workspace
      });

      history.replace(viewURL);
    }
  }, [
    canEdit,
    history,
    isTemplate,
    projectId,
    projectName,
    reportName,
    workspace
  ]);

  useEffect(() => {
    if (isReportLoading) return;

    goToHash(location.hash);
  }, [isReportLoading, location.hash]);

  const hasUnsavedChanges = useMemo(() => {
    if (!savedReport && !report) return false;

    // Used JSON.stringify to clean up the `undefined` values
    // to match { a: { b: undefined, c: 1 } } === { a: { c: 1 } }
    const savedReportString = JSON.stringify(
      savedReport ? normalizeReport(savedReport) : null
    );
    const reportString = JSON.stringify(report);

    return savedReportString !== reportString;
  }, [report, savedReport]);

  const handleDuplicate = async (newReportName: string) => {
    try {
      if (!reportId) return;

      const duplicatedReport = await duplicateReportMutation.mutateAsync({
        isTemplate,
        newReportName,
        originalReportId: reportId,
        revisionId
      });

      if (duplicatedReport) {
        const url = reportRoute({
          projectName,
          reportName: duplicatedReport.reportName,
          workspaceName: workspace
        });

        setRevisionId(null);

        history.push(url);
      }
      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  const handleEditModeChange = (newEditMode?: ReportEditMode) => {
    const url = reportRoute({
      editMode: newEditMode,
      isTemplate,
      projectName,
      reportName,
      workspaceName: workspace
    });

    history.push(url);
  };

  const handleDescriptionChange = (newDescription: string) => {
    if (!report) return;

    setReport({
      ...report,
      data: {
        ...report.data,
        description: newDescription
      },
      description: newDescription
    });
  };

  const handleNameChange = ({
    displayName: newDisplayName,
    name: newName
  }: {
    displayName: string;
    name: string;
  }) => {
    if (!report) return;

    setReport({
      ...report,
      data: {
        ...report.data,
        displayName: newDisplayName,
        reportName: newName
      },
      reportName: newName
    });
  };

  const handleRevisionIdChange = async (newRevisionId: string) => {
    setRevisionId(newRevisionId);
  };

  const handleSectionsChange = (newSections: ReportSection[]) => {
    if (!report) return;

    setReport({
      ...report,
      data: {
        ...report.data,
        sections: newSections
      }
    });
  };

  const handleOpenDuplicateModal = () => {
    const duplicateItemModal = (
      <DuplicateItemModal
        entityName="report"
        modalId={DUPLICATE_ITEM_MODAL}
        onSave={handleDuplicate}
        originalItemName={reportName}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(DUPLICATE_ITEM_MODAL, duplicateItemModal)
    );
  };

  const handleSave = async () => {
    if (!name || !displayName) {
      dispatch(
        alertsUtil.openErrorDialog(
          CATCH_ERROR_API,
          'The report name is invalid'
        )
      );

      return;
    }

    try {
      await upsertReportMutation.mutateAsync({
        data: {
          displayName,
          // Duplicate name + description because revisions
          // only save for the `data` field
          description: description || '',
          reportName: name,
          sections: sections || []
        },
        description: description || '',
        displayName,
        editMode,
        reportId: isTemplate ? null : reportId,
        reportName: name
      });

      setRevisionId(null);
      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  if (isReportLoading || !report || !hasProjectIdBeenSaved) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching report data"
      />
    );
  }

  return (
    <div
      className={cx(styles.page, {
        [styles.viewOnly]: editMode !== REPORT_EDIT_MODES.EDIT
      })}
      /* ID used in SectionActions to detect the ClickAwayListener */
      id="report-page"
    >
      <PageMeta name={displayName} description={description}>
        {author && <meta name="author" content={author} />}
      </PageMeta>
      <ReportHeader
        canEdit={canEdit}
        canUndo={reportStack.length > 1}
        editMode={editMode}
        name={name || ''}
        hasUnsavedChanges={hasUnsavedChanges}
        isSaving={upsertReportMutation.isLoading}
        isTemplate={isTemplate}
        onChangeEditMode={handleEditModeChange}
        onDuplicate={handleOpenDuplicateModal}
        onSave={handleSave}
        onSelectRevisionId={handleRevisionIdChange}
        onUndo={handleUndoReportChange}
        revisionId={revisionId || ''}
        revisions={revisions}
      />
      {/* ID used in DownloadReportModal to apply custom styles on download */}
      <div className={styles.body} id="report-page-body">
        <ReportName
          displayName={displayName || ''}
          editMode={editMode}
          name={name || ''}
          onChange={handleNameChange}
          originalDisplayName={savedReport?.displayName || ''}
          originalName={savedReport?.reportName || ''}
          projectId={projectId}
        />
        <ReportDescription
          editMode={editMode}
          description={description || ''}
          onChange={handleDescriptionChange}
        />
        <div className={styles.socialLinks}>
          <ReportAuthor
            avatarURL={avatarURL}
            author={author}
            editMode={editMode}
          />
        </div>

        <ReportSections
          canEdit={canEdit}
          editMode={editMode}
          onChange={handleSectionsChange}
          isNewReport={isNewReport}
          projectId={projectId}
          sections={sections}
          projectName={projectName}
        />
      </div>
    </div>
  );
};

export default ReportPage;
