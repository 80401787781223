import React, { useState, MouseEvent } from 'react';
import TextInput, { TextInputProps } from '../TextInput/TextInput';
import classes from './PasswordInput.module.scss';
import cx from 'classnames';
import { DSHideIcon, DSShowIcon } from '@ds-icons';
import noop from 'lodash/noop';

interface PasswordInputProps extends TextInputProps {
  onHideIconClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const PasswordInput = ({
  onHideIconClick = noop,
  ...props
}: PasswordInputProps) => {
  const { size = 'default', disabled } = props;
  const [isHiddenValue, setIsHiddenValue] = useState(true);

  const handleClickHiddenValue = (e: MouseEvent<HTMLDivElement>) => {
    if (onHideIconClick) {
      onHideIconClick(e);
    }

    setIsHiddenValue(prevVal => !prevVal);
  };

  return (
    <div
      className={cx(classes.passwordInputContainer, classes[size], {
        [classes.disabled]: disabled
      })}
    >
      <TextInput type={isHiddenValue ? 'password' : 'text'} {...props} />
      <div
        className={classes.hideIconContainer}
        onClick={handleClickHiddenValue}
      >
        {isHiddenValue ? <DSHideIcon /> : <DSShowIcon />}
      </div>
    </div>
  );
};

export default PasswordInput;
