import React from 'react';
import pick from 'lodash/pick';
import { BuiltInPanelType } from '@experiment-management-shared/types';

import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import LineChart from '@experiment-management-shared/components/Charts/PlotlyChart/LineChart';
import ScatterChart from '@experiment-management-shared/components/Charts/PlotlyChart/ScatterChart';
import BarChart from '@experiment-management-shared/components/Charts/PlotlyChart/BarChart';
import ParallelCoordinatesChart from '@experiment-management-shared/components/Charts/PlotlyChart/ParallelCoordinatesChart';
import ScalarChart from '@experiment-management-shared/components/Charts/NoPlotlyCharts/ScalarChart/ScalarChart';
import ImagePanel from '@experiment-management-shared/components/Charts/NoPlotlyCharts/ImagePanel/ImagePanel';
import VideoPanel from '@experiment-management-shared/components/Charts/NoPlotlyCharts/VideoPanel/VideoPanel';
import PCDPanel from '@experiment-management-shared/components/Charts/NoPlotlyCharts/PCDPanel/PCDPanel';
import { DataPanel } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/DataPanel/DataPanel';
import CurvesChart from '@experiment-management-shared/components/Charts/PlotlyChart/CurvesChart';
import { PythonPanel } from '@experiment-management-shared/components/Charts/NoPlotlyCharts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChartComponentType = React.FC<any> & {
  CONFIG_PROPERTIES: string[];
};

const CHART_COMPONENTS_MAP: Record<string, ChartComponentType> = {
  [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: LineChart,
  [BUILT_IN_CHART_TYPES['BuiltIn/Scatter']]: ScatterChart,
  [BUILT_IN_CHART_TYPES['BuiltIn/Bar']]: BarChart,
  [BUILT_IN_CHART_TYPES[
    'BuiltIn/ParallelCoordinates'
  ]]: ParallelCoordinatesChart,
  [BUILT_IN_CHART_TYPES.scalar]: ScalarChart,
  [BUILT_IN_CHART_TYPES.image]: ImagePanel,
  [BUILT_IN_CHART_TYPES.video]: VideoPanel,
  [BUILT_IN_CHART_TYPES.pcd]: PCDPanel,
  [BUILT_IN_CHART_TYPES.data]: DataPanel,
  [BUILT_IN_CHART_TYPES.curves]: CurvesChart,
  [BUILT_IN_CHART_TYPES.python]: PythonPanel
};

type UseBuiltInChartComponentProps = {
  chartType: BuiltInPanelType;
  chartConfig: object;
  config: object;
};

const getComponent = ({
  chartType,
  chartConfig,
  config
}: UseBuiltInChartComponentProps) => {
  const ChartComponent = CHART_COMPONENTS_MAP[chartType as string];

  if (ChartComponent) {
    const properties: object = pick(
      { ...chartConfig, ...config },
      ChartComponent.CONFIG_PROPERTIES || []
    );

    return <ChartComponent {...properties} />;
  }

  console.error('Chart component is not implemented for: ', chartType);

  return null;
};

const useBuiltInChart = () => {
  return {
    getComponent
  };
};

export default useBuiltInChart;
