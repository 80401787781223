import { useMemo } from 'react';
import { getMetricChartsTemplate } from '@/reducers/experimentDetails/chartsReducer';
import useExperimentViewState from '@experiment-management-shared/hooks/useExperimentViewState';
import { useSelector } from 'react-redux';
import { COMPARISON_TABLE_DISPLAY_CONTENT } from '../newComparisonTableConfig';
import { ComparisonTableDefaultData, DetailViewValue } from '../types';
import { maxRowDecimals } from '@shared/utils/decimalUtils';

type UseComparisonDecimalPrecisionOptions<T> = {
  items: T[];
  experimentKey: string;
  detailView: DetailViewValue;
};

const useComparisonDecimalPrecision = <
  TData extends ComparisonTableDefaultData
>({
  items,
  experimentKey,
  detailView
}: UseComparisonDecimalPrecisionOptions<TData>) => {
  const currentTemplate = useSelector(getMetricChartsTemplate);
  const { viewName } = COMPARISON_TABLE_DISPLAY_CONTENT[detailView];

  const maxDecimalsInItems = useMemo(
    () => Math.max(...items.map(maxRowDecimals), 0),
    [items]
  );

  const [decimalsPrecision, setDecimalsPrecision] = useExperimentViewState({
    experimentKey,
    queryStringParameter: 'decimals',
    template: currentTemplate,
    valuePath: `${viewName}.decimalsPrecision`
  });

  const onChangeDecimalsPrecision = (value: number) => {
    const precision = value === maxDecimalsInItems ? null : value;

    setDecimalsPrecision(precision);
  };

  return {
    decimalsPrecision,
    maxDecimalsInItems,
    onChangeDecimalsPrecision
  };
};

export default useComparisonDecimalPrecision;
