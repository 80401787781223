import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import { notesActionTypes } from '../../../constants/actionTypes';

const useModelRegistryNotesMutation = ({ workspace, modelName }) => {
  const dispatch = useDispatch();
  const query = useQueryClient();

  return useMutation(
    notes =>
      modelRegistryApi.saveModelNotes({
        workspaceName: workspace,
        registryModelName: modelName,
        notes
      }),
    {
      onSuccess: async () => {
        await query.invalidateQueries([
          'modelRegistryNotes',
          { workspace, modelName }
        ]);

        dispatch({
          type: notesActionTypes.SET_LAST_SAVE,
          payload: { lastSave: Date.now() }
        });
      }
    }
  );
};

export default useModelRegistryNotesMutation;
