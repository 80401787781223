import React from 'react';
import { ActionMenuList, ListItem, Tooltip } from '@ds';
import { DSDownloadIcon } from '@ds-icons';

type AssetsTabActionsProps = {
  downloadAssetURL?: string;
};

const DirActions = ({ downloadAssetURL }: AssetsTabActionsProps) => {
  if (!downloadAssetURL) return null;
  return (
    <div data-test="dir-row-actions-button" style={{ pointerEvents: 'auto' }}>
      <ActionMenuList>
        <ActionMenuList.Trigger />
        <ActionMenuList.Body>
          <Tooltip content="Download" placement="top">
            <a
              role="button"
              download
              className="dir-download-icon"
              onClick={e => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
              href={downloadAssetURL}
            >
              <ListItem>
                <ListItem.PrefixContainer>
                  <ListItem.Icon Icon={<DSDownloadIcon />} />
                </ListItem.PrefixContainer>
                <ListItem.Text primary="Download" />
              </ListItem>
            </a>
          </Tooltip>
        </ActionMenuList.Body>
      </ActionMenuList>
    </div>
  );
};

export default DirActions;
