import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import { trackEvent } from '@shared/utils/eventTrack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  flatten,
  head,
  isArray,
  isEmpty,
  round,
  size,
  throttle,
  uniq
} from 'lodash';
import { NoLoggedDataView } from '@DesignSystem/charts/NoLoggedDataView';
import { Pill } from '@shared/components/Pill/Pill';
import { EmptyAnnotationsIcon } from '@Icons-outdated';
import { getNumbersMinMax } from '@experiment-management-shared/utils/graphics';
import { Slider, CircularProgress } from '@design-system-outdated/components';
import useMetadataForImages from '@API/assets/useMetadataForImages';
import useImagePanelData from '@API/panels/useImagePanelData';
import { Checkbox } from '@DesignSystem/controllers';
import { panelEvents } from '@/constants/trackingEventTypes';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import styles from './ImagePanel.module.scss';

const ImagePanelAnnotationTab = ({ experimentKeys, hiddenExperimentKeys }) => {
  const {
    filteredAnnotations,
    step,
    images,
    confidenceScore,
    chartName,
    experimentsCount
  } = useSelector(state =>
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.image })
  );

  const targetExperimentKeys = useMemo(() => {
    let retVal = [...experimentKeys];

    if (isArray(hiddenExperimentKeys)) {
      retVal = retVal.filter(key => !hiddenExperimentKeys.includes(key));
    }

    return retVal;
  }, [experimentKeys, hiddenExperimentKeys]);

  const { data: panelData } = useImagePanelData(
    {
      experimentKeys: targetExperimentKeys,
      images,
      step: step || 0
    },
    {
      keepPreviousData: true
    }
  );

  const { data: imagesMetadata } = useMetadataForImages(
    {
      experimentKeys: targetExperimentKeys,
      imagesIds: panelData?.data?.images.map(val => val.id)
    },
    {
      keepPreviousData: true,
      refetchOnMount: true,
      enabled: !!panelData
    }
  );

  const [confidence, setConfidence] = useState();
  const [minScore, setMinScore] = useState();
  const [maxScore, setMaxScore] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setConfidence(confidenceScore);
  }, [confidenceScore]);

  const updateConfidenceScore = useCallback(
    value => {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.image,
          'confidenceScore',
          value
        )
      );
    },
    [dispatch]
  );

  const throttledAnnotationsConfidenceHandler = useMemo(
    () => throttle(updateConfidenceScore, 300),
    []
  );

  useEffect(() => {
    trackEvent(panelEvents.IMAGE_PANEL_ANNOTATION_TAB_CLICKED, {
      chartName,
      step,
      imagesCount: images.length ?? 0,
      experimentsCount
    });
  }, []);

  const mergedImagesLabels = uniq(
    flatten(imagesMetadata?.map(imageMetadata => imageMetadata.labels))
  );

  const mergedLabelColorMap = useMemo(
    () => head(imagesMetadata)?.labelColorMap || {},
    [imagesMetadata]
  );

  useEffect(() => {
    if (imagesMetadata) {
      const scores = flatten(
        imagesMetadata.map(metadata => {
          return metadata.annotations.map(annot => annot.score);
        })
      );

      const [min, max] = getNumbersMinMax(scores);

      setMinScore(min);
      setMaxScore(max);
      if (!confidenceScore) {
        updateConfidenceScore(min);
      }
    }
  }, [imagesMetadata, updateConfidenceScore]);

  if (!imagesMetadata)
    return (
      <div className={styles.circularProgress}>
        <CircularProgress color="var(--primary-color)" size="30px" />
      </div>
    );

  const hasFilteredAnnotations = !isEmpty(filteredAnnotations);

  const updateFilteredAnnotations = value => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.image,
        'filteredAnnotations',
        value
      )
    );
  };

  if (isEmpty(imagesMetadata) || !mergedImagesLabels?.length) {
    return (
      <NoLoggedDataView
        icon={<EmptyAnnotationsIcon />}
        title="No logged annotations"
        link="https://www.comet.com/docs/v2/guides/comet-dashboard/more-in-projects/#image-panel"
        linkText="&nbsp;check out our documentation"
        body="In order to view annotations using the Image Panel you need to log it using `Experiment.log_image`"
      />
    );
  }

  return (
    <div className={styles.annotationTabContainer}>
      <div className={styles.fieldContainer}>
        <p>Confidence Score</p>
        <div className={styles.slider}>
          <Slider
            min={minScore}
            value={confidence}
            disabled={!minScore && !maxScore}
            valueLabelDisplay="off"
            step={0.1}
            onChange={value => {
              throttledAnnotationsConfidenceHandler(value);
              setConfidence(value);
            }}
            sliderSuffixFormat={val => round(val, 1)}
            max={maxScore}
          />
        </div>
      </div>

      <div className={styles.fieldContainer}>
        <p>Annotation Labels</p>
        <div className={styles.checkbox}>
          <Checkbox
            checked={!hasFilteredAnnotations}
            indeterminate={
              hasFilteredAnnotations &&
              size(filteredAnnotations) < size(mergedImagesLabels)
            }
            onChange={() => {
              if (hasFilteredAnnotations) {
                updateFilteredAnnotations([]);
              } else {
                updateFilteredAnnotations(mergedImagesLabels);
              }
            }}
          />
          <p>Annotations display</p>
        </div>
        <div className={styles.pillsGrid}>
          {mergedImagesLabels?.map(label => {
            const disabled = filteredAnnotations.includes(label);

            return (
              <Pill
                key={label}
                disabled={disabled}
                clickable
                onClick={() => {
                  let updated;
                  if (!disabled) {
                    updated = [...filteredAnnotations, label];
                  } else {
                    updated = filteredAnnotations.filter(e => e !== label);
                  }
                  updateFilteredAnnotations(updated);
                }}
                text={label}
                color={`#${mergedLabelColorMap[label]}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

ImagePanelAnnotationTab.propTypes = {
  experimentKeys: PropTypes.any.isRequired,
  hiddenExperimentKeys: PropTypes.shape({
    includes: PropTypes.func
  }).isRequired
};

export default ImagePanelAnnotationTab;
