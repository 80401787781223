import React from 'react';
import {
  GroupButtonProvider,
  GroupButtonProviderProps
} from './useGroupButtonContext';
import classes from './GroupButton.module.scss';
import GroupButtonItem from './GroupButtonItem';

type GroupButtonProps = GroupButtonProviderProps;

const GroupButton = ({ children, ...props }: GroupButtonProps) => {
  return (
    <GroupButtonProvider {...props}>
      <div className={classes.container} data-test="grouped-button-list">
        {children}
      </div>
    </GroupButtonProvider>
  );
};

GroupButton.Item = GroupButtonItem;
export default GroupButton;
