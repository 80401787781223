import { EmptyExperimentTab } from '@shared';
import React from 'react';
import classes from './ConfusionMatrix.module.scss';
import cx from 'classnames';

type ConfusionMatrixTabEmptyProps = {
  isComparePage: boolean;
};
const ConfusionMatrixTabEmpty = ({
  isComparePage
}: ConfusionMatrixTabEmptyProps) => {
  const experimentText = isComparePage
    ? 'These experiments'
    : 'This experiment';
  return (
    <EmptyExperimentTab
      className={cx(classes.emptyMessage, {
        [classes.isComparePage]: isComparePage
      })}
    >
      <EmptyExperimentTab.Icon iconName="confusionMatrix" />
      <EmptyExperimentTab.Title>
        {experimentText} did not log any confusion matrices
      </EmptyExperimentTab.Title>
      <EmptyExperimentTab.Description>
        Use <b>log_confusion_matrix()</b> on your Experiment object to report
        confusion matrices during experiments.
      </EmptyExperimentTab.Description>
      <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/confusion-matrix/" />
    </EmptyExperimentTab>
  );
};

export default ConfusionMatrixTabEmpty;
