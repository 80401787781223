import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import * as React from 'react';
import './VerticalStepper.scss';

export default function VerticalLinearStepper({
  steps,
  activeStep,
  changeCurrentStep
}) {
  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
            onClick={() => changeCurrentStep(index)}
            classes={{
              labelContainer: 'stepper-label-container'
            }}
          >
            {step.label}
          </StepLabel>
          <StepContent>{step.content}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
