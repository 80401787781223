import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './SimpleCard.scss';

export const DS_SIMPLE_CARD_TYPES = {
  DEFAULT: 'default',
  SECONDARY: 'secondary'
};

const SimpleCard = ({ children, className, withShadow, hoverable, type }) => {
  return (
    <div
      className={cx('ds-simple-card-container', className, type, {
        shadow: withShadow,
        hoverable
      })}
    >
      {children}
    </div>
  );
};

SimpleCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  withShadow: PropTypes.bool,
  hoverable: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(DS_SIMPLE_CARD_TYPES))
};

SimpleCard.defaultProps = {
  children: null,
  className: '',
  withShadow: false,
  hoverable: false,
  type: DS_SIMPLE_CARD_TYPES.DEFAULT
};

export default SimpleCard;
