import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { Table } from '@DesignSystem/tables';
import { DSColors } from '@design-system-outdated/constants';
import Box from '@material-ui/core/Box';
import {
  getUserUniqueName,
  getKeyForChangingRole,
  getKeyForRemovingFromWorkspace
} from '@shared/utils/permissions';
import useUserPermission from '@shared/api/useUserPermission';

import ManageUsersRolePopover from './ManageUsersRolePopover';
import DateCell from './DateCell';
import ManageUsersModalTableEmptyState from './ManageUsersModalTableEmptyState';
import ManageUsersModalAvatarCell from './ManageUsersModalAvatarCell';
import ManageUsersRoleCell from './ManageUsersRoleCell';
import ManageUsersModalRemoveCell from './ManageUsersModalRemoveCell';
import classNames from './ManageUsersModalTable.module.scss';

const getTableColumns = (disableActionColumns = false) =>
  [
    {
      name: 'username',
      id: 'name',
      title: 'Name / User Name'
    },
    {
      name: 'email',
      id: 'email',
      title: 'email'
    },
    {
      name: 'joinedAt',
      id: 'joinedAt',
      title: 'Joined'
    },
    !disableActionColumns && {
      name: 'workspaceRole',
      id: 'workspaceRole',
      title: 'Workspace Role'
    },
    !disableActionColumns && {
      name: 'remove',
      id: 'remove',
      title: ''
    }
  ].filter(Boolean);

const DEFAULT_COLUMN_WIDTH = [
  { columnName: 'username', width: 270 },
  { columnName: 'workspaceRole', width: 300 },
  { columnName: 'remove', width: 75 }
];

const ManageUsersModalTable = ({
  disableActionColumns,
  disableColumnsWidthChange,
  filterValue,
  members,
  onChangePermissions,
  onRemoveUsers,
  workspaceName,
  currentUser,
  ifCurrentWsOnlyOne,
  isFetching
}) => {
  const [popoverPosition, setPopoverPosition] = useState(null);
  const [popoverData, setPopoverData] = useState(null);
  const [columnOrder, setColumnOrder] = useState([]);
  const { getPermissionStatus } = useUserPermission();
  const currentUserName = getUserUniqueName(currentUser);

  const [columnWidths, setColumnWidths] = useState(DEFAULT_COLUMN_WIDTH);

  const dataTypes = [
    {
      cols: ['username'],
      cell: ManageUsersModalAvatarCell
    },
    {
      cols: ['joinedAt'],
      cell: DateCell
    },
    {
      cols: ['workspaceRole'],
      cell: ({ row }) => (
        <ManageUsersRoleCell
          row={row}
          popoverPosition={popoverPosition}
          setPopoverPosition={setPopoverPosition}
          setPopoverData={setPopoverData}
          popoverData={popoverData}
        />
      )
    },
    {
      cols: ['remove'],
      cell: ({ row }) => (
        <ManageUsersModalRemoveCell
          ifCurrentWsOnlyOne={ifCurrentWsOnlyOne}
          currentUser={currentUser}
          row={row}
          canRemoveUsers={getPermissionStatus({
            workspaceName,
            permissionKey: getKeyForRemovingFromWorkspace(
              currentUserName,
              row.username
            )
          })}
          onRemoveUsers={onRemoveUsers}
        />
      )
    }
  ];

  const rows = useMemo(() => {
    const filterValueLowerCased = filterValue?.toLowerCase();

    return members
      ?.map(userIns => {
        const { uniqueName, email = '' } = userIns || {};

        if (!filterValue?.length) {
          return userIns;
        }

        if (
          // filter by username
          uniqueName.toLowerCase().includes(filterValueLowerCased) ||
          // filter by email
          (email && email.toLowerCase().includes(filterValueLowerCased))
        ) {
          return userIns;
        }

        return null;
      })
      .filter(Boolean);
  }, [filterValue, members]);

  return (
    <Box className={classNames.manageUsersModalTable}>
      <ManageUsersRolePopover
        ifUserAdmin={popoverData?.isAdmin}
        username={getUserUniqueName(popoverData)}
        permissions={popoverData?.permissions}
        setPermissions={newPerms => {
          onChangePermissions(newPerms, getUserUniqueName(popoverData));
          setPopoverData(data => ({
            ...data,
            permissions: newPerms
          }));
        }}
        onClose={() => {
          setPopoverPosition(null);
          setPopoverData(null);
        }}
        popoverPosition={popoverPosition}
        currentUser={currentUser}
        ifChangeWsRoleDisabled={
          !getPermissionStatus({
            workspaceName,
            permissionKey: getKeyForChangingRole(
              currentUserName,
              getUserUniqueName(popoverData)
            )
          })
        }
      />
      <Table
        isFetching={isFetching}
        columnWidthsConfig={
          !disableColumnsWidthChange
            ? {
                isDisabled: false,
                columnWidths,
                onColumnWidthsChange: setColumnWidths,
                columnExtensions: [
                  { columnName: 'username', minWidth: 270 },
                  { columnName: 'remove', minWidth: 75, maxWidth: 75 }
                ]
              }
            : {}
        }
        columns={getTableColumns(disableActionColumns)}
        rowHeight="72px"
        rowIdKey="uniqueName"
        rows={rows}
        paginationConfig={{
          isDisabled: true
        }}
        selectionConfig={{
          isDisabled: true
        }}
        header={{
          headerColor: DSColors.grayColor1
        }}
        dataTypes={dataTypes}
        maxHeight={420}
        renderEmptyState={() => (
          <ManageUsersModalTableEmptyState filterValue={filterValue} />
        )}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
      />
    </Box>
  );
};

ManageUsersModalTable.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      isMember: PropTypes.bool,
      isAdmin: PropTypes.bool,
      username: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  isFetching: PropTypes.bool,
  disableActionColumns: PropTypes.bool,
  disableColumnsWidthChange: PropTypes.bool,
  onRemoveUsers: PropTypes.func,
  onChangePermissions: PropTypes.func,
  workspaceName: PropTypes.string.isRequired,
  filterValue: PropTypes.string,
  ifCurrentWsOnlyOne: PropTypes.bool
};

ManageUsersModalTable.defaultProps = {
  filterValue: '',
  isFetching: false,
  disableActionColumns: false,
  disableColumnsWidthChange: false,
  ifCurrentWsOnlyOne: false,
  onRemoveUsers: noop,
  onChangePermissions: noop
};

export default ManageUsersModalTable;
