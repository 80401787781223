import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { noop } from 'lodash';
import typography from '@ds-typography';

import './PaymentPlanCard.scss';
import Divider from '../Divider/Divider';
import Tag from '../Tag/Tag';

const PaymentPlanCard = ({
  active,
  badgeLabel,
  bullets,
  color,
  description,
  isAcademic,
  label,
  priceDescription,
  priceLabel,
  title,
  showTrialButton,
  onTrialClick
}) => {
  return (
    <div className={cx('ds-payment-plan-card', { active })}>
      <div className="color-line" style={{ backgroundColor: color }} />

      <div className="payment-plan-content-container">
        <div className="payment-plan-name-container">
          <div className="payment-title-container">
            <h3 className="payment-plan-title">{title}</h3>
            {badgeLabel && (
              <Tag
                backgroundColor={color}
                label={badgeLabel}
                marginLeft={18}
                color="white"
              />
            )}
          </div>

          {!isAcademic && (
            <div className="payment-price-container">
              <h1 style={{ color }}>{priceLabel}</h1>
              <span>{priceDescription}</span>
            </div>
          )}

          <div className="payment-description-container">
            <span>{label}</span>
            <span className="description-label">{description}</span>

            {showTrialButton && (
              <div className="card-trial-container">
                <button
                  onClick={onTrialClick}
                  className={cx(typography.dsAccented, typography.dsAction)}
                >
                  Free 30 days trial
                </button>
              </div>
            )}
          </div>
          {isAcademic && (
            <div className="is-academic-container">Free Academic Access</div>
          )}
        </div>
        <Divider orientation="horizontal" />
        <div className="payment-bullets-container">
          {bullets?.map(bullet => (
            <div className="payment-bullet" key={bullet}>
              {bullet}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

PaymentPlanCard.propTypes = {
  active: PropTypes.bool,
  badgeLabel: PropTypes.string,
  bullets: PropTypes.array,
  color: PropTypes.string.isRequired,
  description: PropTypes.string,
  isAcademic: PropTypes.bool,
  label: PropTypes.string,
  priceDescription: PropTypes.string,
  priceLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showTrialButton: PropTypes.bool,
  onTrialClick: PropTypes.func
};

PaymentPlanCard.defaultProps = {
  active: false,
  badgeLabel: null,
  bullets: [],
  description: '',
  isAcademic: false,
  label: '',
  priceDescription: '',
  showTrialButton: false,
  onTrialClick: noop
};

export default PaymentPlanCard;
