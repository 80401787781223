import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

import { CreateMetricDataType } from '@mpm/types';

type CreateMetricProps = {
  modelId: string;
  workspaceId: string;
  metricData: CreateMetricDataType;
};

const createMetric = ({
  workspaceId,
  modelId,
  metricData
}: CreateMetricProps) => {
  return api.post(
    `mpm/${workspaceId}/models/${modelId}/custom_transformations`,
    metricData
  );
};

export function useCreateMetric() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ metricData }: { metricData: CreateMetricDataType }) =>
      createMetric({ workspaceId: activeWorkspace?.id, modelId, metricData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'custom-metrics',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        queryClient.invalidateQueries(['getModelRegistryNameById']);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.SUCCESS_CUSTOM_METRIC_CREATED,
            'The metric was succesfully created.'
          )
        );
      }
    }
  );
}
