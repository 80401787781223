import React from 'react';
import { useParams } from 'react-router';

import { useExperimentRawData } from '@experiment-details/api';
import SmallLoader from '@shared/components/SmallLoader';

const RawData = () => {
  const { assetId, experimentKey, type } = useParams();
  const { data: rawData, isLoading } = useExperimentRawData({
    assetId,
    experimentKey,
    type
  });

  if (isLoading) {
    return <SmallLoader />;
  }

  if (!rawData) {
    return (
      <div className="empty-detail-raw">
        <div className="empty-detail-content">
          <i className="material-icons">warning</i> There is no data to display
        </div>
      </div>
    );
  }

  return <pre className="organic-code-no-additives">{rawData}</pre>;
};

export default RawData;
