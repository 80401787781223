import React from 'react';
import { useParams } from 'react-router-dom';

import { Tooltip } from '@ds';

import styles from './ExperimentNameCell.module.scss';

export const ExperimentNameCell = ({
  experimentKey,
  experimentName,
  shouldDisplayLink
}: {
  experimentKey: string;
  experimentName: string;
  shouldDisplayLink: boolean;
}) => {
  const { workspace, projectName } = useParams<{
    workspace: string;
    projectName: string;
  }>();

  const link = shouldDisplayLink ? (
    <a
      href={`/${workspace}/${projectName}/${experimentKey}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {experimentName}
    </a>
  ) : (
    <span>{experimentName}</span>
  );

  return (
    <Tooltip content={experimentName} placement="top">
      <div className={styles.container}>{link}</div>
    </Tooltip>
  );
};
