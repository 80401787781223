import React from 'react';

import Box from '@material-ui/core/Box';
import cx from 'classnames';

import PropTypes from 'prop-types';
import { Input } from '@DesignSystem/controllers';
import classNames from './RegisterModel.module.scss';

const RegisterModelTextField = ({
  dataTest,
  placeholder = [],
  value,
  onChange,
  label,
  helperText,
  errorText,
  isValid,
  inputProps
}) => (
  <Box>
    <Box className={classNames.registerModelLabel}>{label}</Box>
    <Input
      data-test={dataTest}
      className={cx(
        classNames.registerModelInput,
        !isValid && classNames.registerModelInputError
      )}
      onChange={val => {
        onChange(val);
      }}
      value={value}
      placeholder={placeholder}
      {...inputProps}
    />
    {helperText || errorText ? (
      <Box
        data-test={`${dataTest}-helper`}
        className={cx(
          classNames.registerModelSubLabel,
          !isValid && classNames.registerModelSubLabelError
        )}
      >
        {isValid ? helperText : errorText || helperText}
      </Box>
    ) : null}
  </Box>
);

RegisterModelTextField.defaultProps = {
  isValid: true,
  tooltipText: null,
  helperText: null,
  errorText: null,
  dataTest: null,
  inputProps: {}
};

RegisterModelTextField.propTypes = {
  dataTest: PropTypes.string,
  value: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorText: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  inputProps: PropTypes.shape(),
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RegisterModelTextField;
