import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { TextButton, IconButton, Tooltip } from '@ds';

import Popover from '@material-ui/core/Popover';

import useRemovePanelTemplateMutation from '@API/panels/useRemovePanelTemplateMutation';
import { DSDeleteIcon } from '@ds-icons';

const RemoveTemplateButton = ({ editable, id, scope }) => {
  const removePanelTemplateMutation = useRemovePanelTemplateMutation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const handleDelete = () => {
    removePanelTemplateMutation.mutate({ scope, templateId: id });

    handleClose();
  };

  const openConfirm = () => {
    setIsPopoverOpen(true);
  };

  return (
    <>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="template-popover">
          <div className="template-popover-image">
            <img src="/images/delete-icon.svg" />
          </div>
          <div className="template-popover-title">Are you sure?</div>
          <div className="template-popover-description">
            This will delete your panel.
          </div>

          <div className="template-popover-actions">
            <div className="template-popover-actions-cancel">
              <TextButton size="large" type="secondary" onClick={handleClose}>
                Cancel
              </TextButton>
            </div>
            <div className="template-popover-actions-confirm">
              <TextButton size="large" onClick={handleDelete}>
                Yes, Delete
              </TextButton>
            </div>
          </div>
        </div>
      </Popover>

      <Tooltip content="Delete" placement="top">
        <IconButton
          type="secondary"
          className="charts-gallery-actions-icon-button"
          onClick={openConfirm}
          disabled={!editable}
          ref={anchorEl}
          Icon={<DSDeleteIcon />}
          active={isPopoverOpen}
        />
      </Tooltip>
    </>
  );
};

RemoveTemplateButton.propTypes = {
  editable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired
};

export default RemoveTemplateButton;
