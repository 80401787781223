import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { EMPTY_TABLE_COLSPAN } from '@shared/constants/empty-table';

import styles from '../EmptyExperimentTab.module.scss';

type EmptyExperimentTabCellProps = {
  children: React.ReactNode;
  dataTest?: string;
} & Table.DataCellProps;
const EmptyExperimentTabCell: React.FC<EmptyExperimentTabCellProps> = ({
  children,
  dataTest,
  ...cellProps
}) => {
  return (
    <Table.Cell
      data-test={dataTest}
      colSpan={EMPTY_TABLE_COLSPAN}
      className={styles.emptyTabCell}
      {...cellProps}
    >
      {children}
    </Table.Cell>
  );
};

export default EmptyExperimentTabCell;
