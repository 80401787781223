// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportSection-module__divider--tL2m9{background-color:var(--gray-4);height:1px;margin:16px;padding:0;width:calc(100% - 32px)}.ReportSection-module__section--AGwLt{box-sizing:border-box;margin:16px 0}.ReportSection-module__section--AGwLt.ReportSection-module__viewOnly--NHttU .panels-tab{padding:0}@media print{.ReportSection-module__section--AGwLt{page-break-inside:avoid}.ReportSection-module__section--AGwLt:first-of-type{page-break-inside:auto}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `ReportSection-module__divider--tL2m9`,
	"section": `ReportSection-module__section--AGwLt`,
	"viewOnly": `ReportSection-module__viewOnly--NHttU`
};
module.exports = ___CSS_LOADER_EXPORT___;
