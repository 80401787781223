import { getDocLink } from '@shared';
import { QUICK_START_ROUTE } from '@shared/constants';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { useIsViewOnlyUser } from '@shared/hooks';

interface UseExperimentEmptyStateConfigs {
  title: string;
  description: string;
  onButtonClick: () => void;
  buttonText: string;
}

interface ParamsInterface {
  projectName: string;
  workspace: string;
}

const getDescription = (isArchive: boolean, isViewOnlyUser: boolean) => {
  if (isArchive) {
    return 'To archive an experiment, select experiments on the Experiment view';
  }

  if (isViewOnlyUser) {
    return 'To learn more about the Comet platform, why not to check out our documentation?';
  }

  return 'See the quick start guide to learn how to run your first experiments';
};

const useExperimentEmptyStateLabels = (
  isArchive: boolean
): UseExperimentEmptyStateConfigs => {
  const isViewOnlyUser = useIsViewOnlyUser();
  const history = useHistory();
  const { projectName, workspace } = useParams<ParamsInterface>();

  const buttonText = isViewOnlyUser
    ? 'View documentation'
    : 'Quick Start Guide';
  const description = getDescription(isArchive, isViewOnlyUser);
  const title = isArchive
    ? 'This project has no archived experiments'
    : 'This project has no experiments';

  const handleButtonClick = useCallback(() => {
    if (isViewOnlyUser) {
      window.open(getDocLink(), '_blank');
      return;
    }

    history.push(
      `/${workspace}/${QUICK_START_ROUTE}?project_name=${projectName}`
    );
  }, []);

  return {
    title,
    buttonText,
    description,
    onButtonClick: handleButtonClick
  };
};

export default useExperimentEmptyStateLabels;
