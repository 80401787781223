import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { BasicModal, DeleteModalBody } from '@DesignSystem/modals';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDeleteVersionModelMutation from '@API/model-registry/useDeleteVersionModelMutation';
import { findNextVersionForModel } from '@model-registry/utils';
import alertsUtil from '../../../../util/alertsUtil';
import { dialogTypes } from '../../../../constants/alertTypes';
import ConfirmDeleteContent from './ConfirmDeleteContent';

const CONFIRM_INPUT_TEXT_TO_CONFIRM_DELETE = 'DELETE';

const DeleteModelVersionModal = ({
  isLastModelVersion,
  handleRowMenuClose,
  handleRedirectOnDelete,
  versions = [],
  onClose,
  isOpen,
  modelVersionData
}) => {
  const { modelName, workspace } = useParams();
  const dispatch = useDispatch();

  const deleteVersionModelMutation = useDeleteVersionModelMutation({
    modelName,
    workspace,
    handleRedirectOnDelete
  });
  const [inputValue, setInputValue] = useState('');

  const handleCloseModal = useCallback(() => {
    setInputValue('');
    onClose();
    handleRowMenuClose();
  }, []);

  const handleConfirm = useCallback(async () => {
    onClose();

    if (inputValue === CONFIRM_INPUT_TEXT_TO_CONFIRM_DELETE) {
      const indexOfVersionToRemove = versions?.findIndex(
        ({ version }) => version === modelVersionData?.version
      );

      if (!isLastModelVersion) {
        deleteVersionModelMutation.mutate({
          modelItemId: modelVersionData.registryModelItemId,
          nextVersion: findNextVersionForModel(versions, indexOfVersionToRemove)
        });
        handleCloseModal();
      } else {
        dispatch(
          alertsUtil.openConfirmDialog(
            dialogTypes.CONFIRM_DELETE_MODEL,
            <ConfirmDeleteContent modelName={modelName} />,
            () => {
              dispatch(
                alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_MODEL)
              );
              deleteVersionModelMutation.mutate({
                modelItemId: modelVersionData.registryModelItemId,
                isLastVersion: true,
                nextVersion: null
              });
            },
            'Delete'
          )
        );
        handleCloseModal();
      }
    }
    handleRowMenuClose();
  }, [inputValue, workspace, modelName, modelVersionData]);

  const handleInputChange = useCallback(value => {
    setInputValue(value);
  }, []);

  return (
    <BasicModal
      open={isOpen}
      onClose={handleCloseModal}
      isPrimaryButtonDisabled={
        inputValue !== CONFIRM_INPUT_TEXT_TO_CONFIRM_DELETE
      }
      title="Delete Model Version"
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      onSecondaryButtonClick={handleCloseModal}
      content={
        <DeleteModalBody
          description={`The version "${modelVersionData?.version}" of model "${modelName}" will be removed. Once deleted, the
            model can not be recovered.`}
          onChangeCallback={handleInputChange}
        />
      }
    />
  );
};

DeleteModelVersionModal.propTypes = {
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string
    })
  ),
  handleRedirectOnDelete: PropTypes.bool,
  modelVersionData: PropTypes.shape({
    registryModelItemId: PropTypes.string,
    version: PropTypes.string
  }),
  isLastModelVersion: PropTypes.bool.isRequired,
  handleRowMenuClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

DeleteModelVersionModal.defaultProps = {
  versions: [],
  handleRedirectOnDelete: false,
  modelVersionData: {}
};

export default DeleteModelVersionModal;
