import React from 'react';
import isArray from 'lodash/isArray';
import { Stages } from '@DesignSystem/data-display';

const StageCell = props => {
  const { row: { Stage = [] } = {} } = props;

  return <Stages stages={isArray(Stage) ? Stage : [Stage]} />;
};

export default StageCell;
