import { onboardingEvents } from '@onboarding/constants';
import { useIsMounted } from '@shared/hooks';
import { trackEvent } from '@shared/utils/eventTrack';
import { useEffect, useState } from 'react';

const useLastSelectedFramework = () => {
  const [lastFramework, setLastFramework] = useState('pytorch');

  const isMounted = useIsMounted();

  useEffect(() => {
    return () => {
      if (!isMounted()) {
        trackEvent(onboardingEvents.QUICKSTART_FRAMEWORK_SELECT, {
          name: lastFramework
        });
      }
    };
  }, [isMounted, lastFramework]);

  return { onChange: setLastFramework };
};

export default useLastSelectedFramework;
