import React from 'react';
import { Pill } from '@shared/components/Pill/Pill';
import cx from 'classnames';

import styles from './ImageWithAnnotations.module.scss';

const LABEL_HEIGHT = 26;
const isOverflowing = (value: number) => value < 0;

type BoundingBoxAnnotation = {
  id: string;
  label: string;
  score?: number;
};
type AnnotationLabelProps = {
  annotation: BoundingBoxAnnotation;
  box: [number, number];
  scalingFactorWidth: number;
  scalingFactorHeight: number;
  zoomLevel: number;
  labelColorMap: Record<string, string>;
};
const AnnotationLabel: React.FC<AnnotationLabelProps> = ({
  annotation,
  box,
  scalingFactorWidth,
  scalingFactorHeight,
  zoomLevel,
  labelColorMap
}) => {
  const [x, y] = box;
  const scaleFactor = 1 / (zoomLevel / 100);

  const { calculatedStyles, isTopOverflowing } = React.useMemo(() => {
    const topOffset = y / scalingFactorHeight - LABEL_HEIGHT;
    const leftOffset = (x - 0.5) / scalingFactorWidth;
    const isTopOverflowing = isOverflowing(topOffset);
    const topPosition = isTopOverflowing ? LABEL_HEIGHT + topOffset : topOffset;
    const leftPosition = isOverflowing(leftOffset) ? 0 : leftOffset;

    return {
      calculatedStyles: {
        transform: `translate3d(${leftPosition}px, ${topPosition}px, 0px) scale(${scaleFactor})`
      },
      isTopOverflowing
    };
  }, [zoomLevel, scalingFactorWidth, scalingFactorHeight, box]);

  const formattedText = annotation?.score
    ? `${annotation.label} ${annotation.score}`
    : `${annotation.label}`;
  const pillColor = `#${labelColorMap[annotation.label]}`;

  return (
    <Pill
      className={cx(styles.pill, {
        [styles.isTopOverflowing]: isTopOverflowing
      })}
      animation
      style={calculatedStyles}
      text={formattedText}
      color={pillColor}
    />
  );
};

export default AnnotationLabel;
