import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import uniq from 'lodash/uniq';
import noop from 'lodash/noop';

import { TextButton } from '@ds';
import Grid from '@material-ui/core/Grid';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { MultipleAutocomplete } from '@DesignSystem/controllers';
import { NO_ITEMS_STRATEGY } from '@DesignSystem/controllers/MultipleAutocomplete/MultipleAutocomplete';
import useUserPermission from '@shared/api/useUserPermission';
import { MANAGEMENT_PERMISSIONS } from '@shared/constants/permissions';
import useInviteMembersList from '@API/workspace/useInviteMembersList';
import useWorkspaceMembers from '@API/workspace/useWorkspaceMembers';
import { useOrganizationUsers } from '@account-settings/api';
import { useCurrentOrganization, useDebouncedFilter } from '@shared/hooks';

import { isEmail, isValidEmail } from '@/lib/utils';

import './InviteMembersModal.scss';

const INCORRECT_EMAIL_ADRESS_MSG = 'Incorrect email address';

const checkValueToAdd = value => !!value.replaceAll(' ', '')?.length;

const InviteMembersModal = ({
  open,
  onClose,
  onPrimaryButtonClick,
  inviteMembersMutation,
  workspaceId,
  workspaceName,
  isFromTrial
}) => {
  const [membersValue, setMembersValue] = useState([]);
  const {
    debouncedFilterValue,
    setFilterValue,
    filterValue
  } = useDebouncedFilter('');

  const { getPermissionStatus } = useUserPermission();
  const isPossibleToInviteOutsideOrganization = getPermissionStatus({
    permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_OUT_OF_ORGANIZATION,
    workspaceName
  });

  const getTitle = () =>
    isFromTrial ? (
      'Welcome to your 30-day Comet Trial!'
    ) : (
      <span>
        Invite your teammates to workspace &quot;
        <span data-mask-test="workspace">{workspaceName}</span>&quot;
      </span>
    );

  const getSecondaryButtonText = () =>
    isFromTrial ? 'Skip, I will do it later' : 'Cancel';

  const currentOrganization = useCurrentOrganization();
  const { data: usernames = [] } = useInviteMembersList({
    filterValue: debouncedFilterValue,
    workspaceId,
    organizationId: currentOrganization?.id
  });
  const { data: workspaceMembers = [] } = useWorkspaceMembers(workspaceId);

  const {
    data: allOrgUsers = [],
    isLoading: isLoadingUsers
  } = useOrganizationUsers(currentOrganization?.id);

  const workspaceMembersUsernames = useMemo(
    () => workspaceMembers.map(({ username, email }) => username || email),
    [workspaceMembers]
  );
  const options = useMemo(
    () => usernames.map(username => ({ value: username })),
    [usernames]
  );
  const allOrgUsersUsernames = useMemo(
    () => allOrgUsers.map(({ username, email }) => username || email),
    [usernames]
  );

  useEffect(() => {
    if (!open) {
      setMembersValue([]);
      setFilterValue('');
    }
  }, [open, setFilterValue]);

  const handlePrimaryButtonClick = () => {
    const usernamesThatAreNotInWs = [];
    membersValue.reduce((acc, value) => {
      if (!workspaceMembersUsernames.includes(value)) {
        acc.push(value);
      }
      return acc;
    }, usernamesThatAreNotInWs);

    onPrimaryButtonClick(usernamesThatAreNotInWs);
  };

  const handleInviteAllClick = useCallback(() => {
    setMembersValue(uniq([...membersValue, ...allOrgUsersUsernames]));
  }, [allOrgUsers, workspaceMembers, membersValue, allOrgUsersUsernames]);

  const handleRemoveAllClick = () => setMembersValue([]);

  const renderMemberOption = option => {
    return option.value;
  };
  const isConsideredAsEmail = value => value.includes('@');

  const checkChipValidity = useCallback(
    value => {
      if (!isConsideredAsEmail(value)) {
        return true;
      }

      return isValidEmail(value);
    },
    [workspaceMembersUsernames]
  );

  const allUsersAreInvited =
    membersValue.length === allOrgUsersUsernames.length;

  const renderAdditionalContent = useCallback(() => {
    return (
      <Grid
        container
        justifyContent="flex-end"
        alignContent="center"
        alignItems="center"
        className="invite-members-modal-add-all"
      >
        <Grid item xs="auto">
          <TextButton
            type="tertiary"
            onClick={handleInviteAllClick}
            disabled={
              isLoadingUsers ||
              inviteMembersMutation?.isLoading ||
              allUsersAreInvited
            }
          >
            Invite all organization users
          </TextButton>
        </Grid>
      </Grid>
    );
  }, [handleInviteAllClick, isLoadingUsers, inviteMembersMutation?.isLoading]);

  const isValid = useMemo(() => membersValue.every(checkChipValidity), [
    membersValue
  ]);

  const renderChip = (chipOption, chipValue) => {
    if (
      isEmail(chipValue) &&
      isPossibleToInviteOutsideOrganization &&
      checkChipValidity(chipValue)
    ) {
      return `Invite “${chipValue}” by email`;
    }

    return chipValue;
  };

  const addCurrentValue = () => {
    if (!membersValue.includes(filterValue)) {
      setMembersValue(prevMembersValue => [...prevMembersValue, filterValue]);
    }

    setFilterValue('');
  };

  const renderNoItems = () => {
    if (!filterValue) {
      return <div className="invite-members-modal-no-items" />;
    }

    const byEmailMessage = filterValue.includes('@') ? ' by email' : '';

    return (
      <div className="invite-members-modal-no-items" onClick={addCurrentValue}>
        <span>+</span>Invite “{filterValue}”{byEmailMessage}
      </div>
    );
  };

  const renderInviteMembers = () => {
    return (
      <div className="invite-members-modal">
        <img
          className="invite-member-picture"
          src={`/images/account-settings/invite-${
            isFromTrial ? 'trial-' : ''
          }members.svg`}
        />

        {isFromTrial && (
          <div className="trial-invitation-label-container">
            <p>Comet is better together!</p>
            <p>You can now start inviting your teammates</p>
          </div>
        )}

        <div className="autocomplete-members">
          <MultipleAutocomplete
            onClearAll={handleRemoveAllClick}
            clearAll
            noValuePlaceholder="Username/email, comma separated"
            withValuePlaceholder="Add more Username/email"
            label="Invite member by Comet Username/email"
            options={options}
            separator={/[\s,]/}
            value={membersValue}
            onChangeValue={setMembersValue}
            filterValue={filterValue}
            onChangeFilterValue={setFilterValue}
            renderListItem={renderMemberOption}
            renderChipLabel={renderChip}
            checkValueToAdd={checkValueToAdd}
            checkChipValidity={checkChipValidity}
            errorLabel={!isValid && INCORRECT_EMAIL_ADRESS_MSG}
            noItemsStrategy={NO_ITEMS_STRATEGY.SHOW_MESSAGE_WITH_NO_EMPTY_INPUT}
            noItems={renderNoItems}
            autoFocus
            classes="invite-members-autocomplete list"
            renderAdditionalContent={renderAdditionalContent}
          />
        </div>
      </div>
    );
  };

  return (
    <FullWidthBasicModal
      open={open}
      onClose={onClose}
      title={getTitle()}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonText="Send Invite"
      secondaryButtonText={getSecondaryButtonText()}
      content={renderInviteMembers()}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={
        !membersValue.length || !isValid || inviteMembersMutation?.isLoading
      }
    />
  );
};

InviteMembersModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  inviteMembersMutation: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  onPrimaryButtonClick: PropTypes.func,
  workspaceId: PropTypes.string,
  isFromTrial: PropTypes.bool,
  workspaceName: PropTypes.string
};

InviteMembersModal.defaultProps = {
  open: false,
  inviteMembersMutation: {},
  onClose: noop,
  onPrimaryButtonClick: noop,
  workspaceId: '',
  isFromTrial: false,
  workspaceName: ''
};

export default InviteMembersModal;
