// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionText-module__text--_Ihde{width:100%;font-size:14px}.SectionText-module__text--_Ihde .MuiTextField-root{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `SectionText-module__text--_Ihde`
};
module.exports = ___CSS_LOADER_EXPORT___;
