import { useAnnotationsShapes } from '@shared/hooks/UseAnnotationsShapes';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styles from './ImageWithAnnotations.module.scss';
import noop from 'lodash';
import { useObserveResizeNode } from '@shared/hooks/useObserveResizeNode';
import { SvgCanvas } from '@shared/components/Canvas/SvgCanvas';
import AnnotationLabel from './AnnotationLabel';
import useBoundingBoxLineWidth from './hooks/useBoundingBoxLineWidth';
import useLazyImage from './hooks/useLazyImage';

function calcObjectFitImageSize(node) {
  let { width, height, naturalWidth, naturalHeight } = node;
  let iAR = naturalWidth / naturalHeight;
  let ctAR = width / height;

  // If the aspect ratio of the image > aspect ratio of the content box,
  // the rendered image width takes all the available space.
  // If the aspect ratio of the image < aspect ratio of the content box,
  // the rendered image height takes all the available space.
  let [w, h] = iAR >= ctAR ? [width, width / iAR] : [height * iAR, height];
  return { w, h };
}

export const ImageWithAnnotations = ({
  isSmoothed,
  isGrayScale,
  imagePath,
  annotations = [],
  labelColorMap,
  alt,
  className,
  onImageLoaded,
  type,
  placeholderSrc,
  zoomLevel,
  isLabelsTextHidden,
  maxHeight,
  imageWidth,
  originalImageSize
}) => {
  const { boxes, polygons } = useAnnotationsShapes(annotations, labelColorMap);

  const imageRef = useRef();
  const [originalImageWidth, setOriginalImageWidth] = useState(
    originalImageSize?.originalImageWidth || 0
  );
  const [originalImageHeight, setOriginalImageHeight] = useState(
    originalImageSize?.originalImageHeight || 0
  );
  const [imageRenderingWidth, setImageRenderingWidth] = useState(0);
  const [imageRenderingHeight, setImageRenderingHeight] = useState(0);
  const scalingFactorWidth = originalImageWidth / imageRenderingWidth;
  const scalingFactorHeight = originalImageHeight / imageRenderingHeight;
  const scalingFactor = Math.max(scalingFactorWidth, scalingFactorHeight);

  const handleImageOnLoad = event => {
    setOriginalImageWidth(
      originalImageSize?.originalImageWidth || event.target.naturalWidth
    );
    setOriginalImageHeight(
      originalImageSize?.originalImageHeight || event.target.naturalHeight
    );

    onImageLoaded();
  };

  const onResizeRef = useObserveResizeNode(node => {
    let { w, h } = calcObjectFitImageSize(node);

    setImageRenderingWidth(w);
    setImageRenderingHeight(h);
  });

  const boxLineWidth = useBoundingBoxLineWidth({
    originalImageWidth,
    imageRenderingWidth,
    zoomLevel,
    scalingFactor
  });

  const { src: imageSrc, isLoading } = useLazyImage({
    placeholderSrc,
    originalSrc: imagePath,
    onLoad: handleImageOnLoad
  });

  return (
    <div className={cx(styles.imageWithAnnotationsContainer, className)}>
      {type === 'image' ? (
        <>
          <SvgCanvas
            boxes={boxes}
            polygons={polygons}
            width={originalImageWidth}
            height={originalImageHeight}
            boxLineWidth={boxLineWidth}
          />

          <img
            ref={node => {
              imageRef.current = node;
              onResizeRef(node);
            }}
            alt={alt}
            className={cx(styles.imageWrapper, {
              [styles.pixelated]: !isSmoothed,
              [styles.grayscale]: isGrayScale
            })}
            src={imageSrc}
            style={{
              maxHeight
            }}
            width={imageWidth}
          />
        </>
      ) : (
        // should I change it here
        <video
          controls
          ref={node => {
            imageRef.current = node;
            onResizeRef(node);
          }}
          className={cx(styles.imageWrapper, {
            [styles.pixelated]: !isSmoothed,
            [styles.grayscale]: isGrayScale
          })}
        >
          <source src={imagePath} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {!isLoading && !isLabelsTextHidden && (
        <div
          style={{
            width: imageRenderingWidth + 'px',
            height: imageRenderingHeight + 'px'
          }}
          className={styles.labelsContainer}
        >
          {annotations.map(annotation =>
            annotation.boxes?.map(box => (
              <AnnotationLabel
                key={`${annotation.id}`}
                annotation={annotation}
                scalingFactorWidth={scalingFactorWidth}
                scalingFactorHeight={scalingFactorHeight}
                zoomLevel={zoomLevel}
                box={box}
                labelColorMap={labelColorMap}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

ImageWithAnnotations.defaultProps = {
  isGrayScale: false,
  isSmoothed: false,
  className: '',
  onImageLoaded: noop,
  placeholderSrc: null,
  zoomLevel: 100,
  type: 'image',
  isLabelsTextHidden: false,
  originalImageSize: null
};

ImageWithAnnotations.propTypes = {
  annotations: PropTypes.array.isRequired,
  imagePath: PropTypes.string.isRequired,
  labelColorMap: PropTypes.any.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  alt: PropTypes.string.isRequired,
  isGrayScale: PropTypes.any,
  isSmoothed: PropTypes.any,
  onImageLoaded: PropTypes.func,
  zoomLevel: PropTypes.number,
  maxHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  placeholderSrc: PropTypes.string.apply,
  isLabelsTextHidden: PropTypes.bool,
  originalImageSize: PropTypes.objectOf({
    originalImageWidth: PropTypes.number.isRequired,
    originalImageHeight: PropTypes.number.isRequired
  })
};
