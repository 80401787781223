import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';

export const DEFAULT_EDITOR_OPTIONS = {
  lineNumbers: true
};

const extraKeys = {
  'Ctrl-Q': cm => {
    cm.foldCode(cm.getCursor());
  },
  'Alt-F': 'findPersistent',
  'Ctrl-Space': 'autocomplete',
  'Ctrl-Enter': 'run',
  'Ctrl-S': 'save',
  'Shift-Tab': 'format'
};

const TABS_MAP = {
  code: {
    label: 'Code',
    tabOptions: {
      mode: 'javascript',
      gutters: [
        'CodeMirror-lint-markers',
        'CodeMirror-linenumbers',
        'CodeMirror-foldgutter'
      ],
      foldGutter: true,
      lint: { esversion: 10 },
      matchBrackets: true,
      extraKeys
    },
    supportedLanguages: [PANEL_CODE_LANGUAGES.JAVASCRIPT]
  },
  pyCode: {
    label: 'Code',
    tabOptions: {
      mode: 'python',
      gutters: [
        'CodeMirror-linenumbers',
        'CodeMirror-foldgutter',
        'CodeMirror-lint-markers'
      ],
      foldGutter: true,
      matchBrackets: true,
      autoCloseBrackets: true,
      lineWrapping: true,
      smartIndent: true,
      indentUnit: 4,
      extraKeys: {
        Tab: cm => {
          if (cm.somethingSelected()) {
            cm.indentSelection('add');
          } else {
            const spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
            cm.replaceSelection(spaces, 'end', '+input');
          }
        }
        // ...extraKeys
      }
    },
    supportedLanguages: [PANEL_CODE_LANGUAGES.PYTHON]
  },
  pyConfig: {
    label: 'Options',
    supportedLanguages: [PANEL_CODE_LANGUAGES.PYTHON]
  },
  description: {
    label: 'Description',
    tabOptions: {
      mode: 'markdown',
      lineNumbers: false,
      lineWrapping: true,
      placeholder: `Enter a description for your ${PANEL_ENTITY_NAME}`
    },
    supportedLanguages: [
      PANEL_CODE_LANGUAGES.PYTHON,
      PANEL_CODE_LANGUAGES.JAVASCRIPT
    ]
  },
  html: {
    label: 'HTML',
    tabOptions: {
      mode: 'text/html',
      gutters: [
        'CodeMirror-lint-markers',
        'CodeMirror-linenumbers',
        'CodeMirror-foldgutter'
      ],
      foldGutter: true,
      lint: true,
      extraKeys
    },
    supportedLanguages: [PANEL_CODE_LANGUAGES.JAVASCRIPT]
  },
  css: {
    label: 'CSS',
    tabOptions: {
      mode: 'css',
      gutters: ['CodeMirror-lint-markers'],
      lint: true
    },
    supportedLanguages: [PANEL_CODE_LANGUAGES.JAVASCRIPT]
  },
  resources: {
    label: 'Resources',
    supportedLanguages: [PANEL_CODE_LANGUAGES.JAVASCRIPT]
  },
  query: {
    label: 'Filters',
    supportedLanguages: [
      PANEL_CODE_LANGUAGES.PYTHON,
      PANEL_CODE_LANGUAGES.JAVASCRIPT
    ]
  }
};

const TABS_KEYS = Object.keys(TABS_MAP);

export default {
  map: TABS_MAP,
  keys: TABS_KEYS
};
