import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import cx from 'classnames';

import './SectionTabs.scss';
import { DSColors } from '@design-system-outdated/constants';
import {
  TabsPaginationBackIcon,
  TabsPaginationForwardIcon
} from '@Icons-outdated';
import { DSCloseIcon } from '@ds-icons';
import { IconButton } from '@ds';

const TabsPaginationBtn = props => {
  const { direction, disabled } = props;
  const leftDirection = direction === 'left';
  return (
    <div className="tabs-arrow-wrapper">
      <span
        className={cx('pagination-arrow', {
          disabled: disabled,
          'right-positioned': !leftDirection
        })}
      >
        {leftDirection && (
          <TabsPaginationBackIcon {...props} className="arrow" />
        )}
        {!leftDirection && (
          <TabsPaginationForwardIcon
            {...props}
            className={cx('arrow', {
              'right-arrow': !leftDirection
            })}
          />
        )}
      </span>
    </div>
  );
};

const LabelWithBadge = ({ label, notificationCount }) => {
  return (
    <Box display="flex">
      <span>{label}</span>
      {notificationCount !== undefined && notificationCount !== 0 && (
        <div className="notification-icon--fixed">
          <p>{notificationCount}</p>
        </div>
      )}
    </Box>
  );
};

const LabelWithCloseIcon = ({ label, clickHandler, index, keyProp }) => {
  const handleClick = ({ event, label, index, keyProp }) => {
    event.stopPropagation();
    event.preventDefault();
    clickHandler({ label, index, keyProp });
  };
  return (
    <div className="label-with-icon-wrapper">
      <span>{label}</span>
      <IconButton
        type="secondary"
        size="small"
        onClick={event => handleClick({ event, label, index, keyProp })}
        Icon={<DSCloseIcon />}
      />
    </div>
  );
};

const StyledTabs = withStyles({
  root: {
    maxWidth: 'fit-content',
    minHeight: 'unset',
    height: ({ tabHeight }) => tabHeight || 39
  },
  indicator: {
    backgroundColor: DSColors.primaryColor,
    borderRadius: 0,
    height: 2.5
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    display: 'flex',
    minWidth: 'fit-content',
    flex: 1,
    minHeight: 'unset',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 400,
    color: DSColors.grayColor6,
    whiteSpace: 'nowrap',
    opacity: 1,
    border: ({ isPill }) => isPill && `1px solid ${DSColors.grayColor3}`,
    borderRadius: ({ isPill }) => isPill && '35px',
    margin: ({ isPill }) => (isPill ? '0' : '0px 11px'),
    marginRight: ({ isPill }) => isPill && '20px',
    padding: ({ isPill }) => (isPill ? 0 : '0px 5px'),
    '& span': {
      padding: ({ isPill }) => isPill && '2px 6px'
    },
    '&:first-child': {
      marginLeft: 0,
      paddingLeft: 0
    },
    '&:hover': {
      color: DSColors.grayColor7
    }
  },
  selected: {
    border: ({ isPill }) => isPill && `1px solid ${DSColors.primaryColor}`,
    '& span': {
      color: ({ selectedTabColor }) => selectedTabColor,
      fontWeight: ({ selectedTabWeight }) => selectedTabWeight
    }
  }
})(Tab);

const SectionTabs = ({
  activeTabIndex,
  onTabChange,
  tabs,
  variant,
  withBottomBorder,
  tabHeight,
  selectedTabColor,
  closeClickHandler = null
}) => {
  const isPill = variant === 'pill';
  const isDefault = variant === 'default';
  const handleChange = (_, tabIndex) => {
    onTabChange(tabIndex);
  };

  return (
    <div
      className={cx(
        'ds-section-tabs-wrapper',
        withBottomBorder && !isPill ? 'bottom-bordered' : ''
      )}
    >
      <StyledTabs
        className="section-tabs"
        value={activeTabIndex}
        onChange={handleChange}
        TabIndicatorProps={{ style: { display: isPill && 'none' } }}
        withBottomBorder={!isPill && withBottomBorder}
        tabHeight={tabHeight}
        scrollable
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={TabsPaginationBtn}
      >
        {tabs.map(
          (
            {
              label,
              key,
              disabled = false,
              notificationCount,
              tooltip = false,
              dataTest = null
            },
            index
          ) => {
            const tabElement = (
              <StyledTab
                data-test={dataTest}
                key={key || label}
                label={
                  closeClickHandler ? (
                    <LabelWithCloseIcon
                      label={label}
                      clickHandler={closeClickHandler}
                      index={index}
                      keyProp={key}
                    />
                  ) : (
                    <LabelWithBadge
                      label={label}
                      notificationCount={notificationCount}
                    />
                  )
                }
                notificationCount={notificationCount}
                disabled={disabled}
                isPill={isPill}
                selectedTabColor={
                  selectedTabColor ||
                  (isPill || isDefault ? DSColors.primaryColor : 'black')
                }
                selectedTabWeight={isPill || isDefault ? 500 : 400}
                varient={variant}
              />
            );

            return tooltip ? (
              <Tooltip arrow title={tooltip} placement="top">
                <Box ml={1.5} textAlign="center">
                  {tabElement}
                </Box>
              </Tooltip>
            ) : (
              tabElement
            );
          }
        )}
      </StyledTabs>
    </div>
  );
};

SectionTabs.defaultProps = {
  withBottomBorder: true,
  tabHeight: 0,
  variant: 'default',
  selectedTabColor: null
};

SectionTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['default', 'pill', 'secondary']),
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  withBottomBorder: PropTypes.bool,
  tabHeight: PropTypes.number,
  selectedTabColor: PropTypes.string
};

export default SectionTabs;
