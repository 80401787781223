import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import find from 'lodash/find';

import { trackMetricKindSelection } from '@shared/utils/eventTrack';
import { formatColumnsToSelectOptions } from '@shared/utils/selectComponentHelper';
import { BasicModal } from '@DesignSystem/modals';

import useColumns from '@API/project/useColumns';
import { SortableCombobox } from '@ds';
import '../ColumnsModal.scss';

const GroupByModal = ({
  onClose,
  onChange,
  selectedColumnNames,
  excludedColumns,
  columnsNameMap,
  placeholder,
  title
}) => {
  const {
    data: [columns, extraCols],
    isLoading: isLoadingColumns
  } = useColumns({
    extraCols: true,
    divideExtraCols: true
  });
  const [allSelectedOptions, setAllSelectedOptions] = useState([]);
  const allSelectedValues = allSelectedOptions.map(({ value }) => value);

  const getInitialSelectedOptions = useCallback(() => {
    if (isLoadingColumns) return [];

    const selectedColumns = selectedColumnNames
      .map(columnName => {
        return [...columns, ...extraCols].find(
          column => column.name === columnName
        );
      })
      .filter(Boolean);

    return formatColumnsToSelectOptions(selectedColumns, columnsNameMap);
  }, [
    columns,
    extraCols,
    isLoadingColumns,
    selectedColumnNames,
    columnsNameMap
  ]);

  useEffect(() => {
    const initialSelectedOptions = getInitialSelectedOptions();

    setAllSelectedOptions(initialSelectedOptions);
  }, [getInitialSelectedOptions]);

  const options = useMemo(() => {
    const normalizedColumns = (columns || []).filter(column => {
      return !find(excludedColumns, {
        name: column.name,
        source: column.source
      });
    });

    return formatColumnsToSelectOptions(normalizedColumns, columnsNameMap);
  }, [columns, excludedColumns, columnsNameMap]);

  if (isLoadingColumns) return null;

  const handleClearCurrentChanges = () => {
    const initialSelectedOptions = getInitialSelectedOptions();

    setAllSelectedOptions(initialSelectedOptions);
  };

  const handleColumnToggle = (newColumns, action) => {
    trackMetricKindSelection('group-by', action);
    setAllSelectedOptions(newColumns);
  };

  const handleResetDefaultColumns = () => {
    setAllSelectedOptions([]);
  };

  const handleUpdateSelectedColumns = () => {
    onChange(allSelectedValues);
  };

  const renderColumnsSelect = () => (
    <div className="columns-modal-select-container">
      <SortableCombobox
        className="multi-select"
        value={allSelectedValues}
        onValueChange={(_, selectedOptions) =>
          handleColumnToggle(selectedOptions)
        }
        options={options}
        placeholder={placeholder}
        maxWidth={460}
        truncateMiddle
        data-test="group-by-column-select"
        maxHeight={300}
        isRegexSearchEnabled
        searchPlaceholder="Search (regex)"
      />
    </div>
  );

  return (
    <BasicModal
      open
      className="columns-modal"
      title={title}
      onClose={onClose}
      content={renderColumnsSelect()}
      primaryButtonText="Done"
      onPrimaryButtonClick={handleUpdateSelectedColumns}
      secondaryButtonText="Reset default"
      onSecondaryButtonClick={handleResetDefaultColumns}
      footerActions={[
        {
          onClick: handleClearCurrentChanges,
          disabled: false,
          key: 'clear-changes',
          text: 'Clear changes',
          type: 'tertiary'
        }
      ]}
    />
  );
};

GroupByModal.defaultProps = {
  onClose: noop,
  onChange: noop,
  selectedColumnNames: [],
  excludedColumns: [],
  columnsNameMap: {},
  title: 'Group experiments by columns',
  placeholder: 'Select columns to group experiments by'
};

GroupByModal.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  selectedColumnNames: PropTypes.arrayOf(PropTypes.string),
  excludedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired
    })
  ),
  columnsNameMap: PropTypes.object,
  title: PropTypes.string,
  placeholder: PropTypes.string
};

export default GroupByModal;
