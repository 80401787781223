import {
  SystemMetrics,
  SystemMetricsAdditionalLog
} from '@shared/types/systemMetrics';
import { SystemMetricsSingleTableRow } from './types';

const NON_STRING_ALLOWED_METRICS = ['pid'];

export const isValidMetricKey = (key: string, value: unknown) =>
  typeof value === 'string' || NON_STRING_ALLOWED_METRICS.includes(key);

export const prepareMetricsTableRowFromLog = (
  item: SystemMetricsAdditionalLog[number]
): SystemMetricsSingleTableRow => ({
  value: item.value,
  key: `manually logged: ${item.key}`
});

export const prepareMetricsTableRow = (
  systemMetrics: SystemMetrics
): SystemMetricsSingleTableRow[] => {
  const rows = [] as SystemMetricsSingleTableRow[];

  Object.entries(systemMetrics).forEach(([key, value]) => {
    if (isValidMetricKey(key, value)) {
      rows.push({ key, value: `${value}` });
    }
  });

  systemMetrics.logAdditionalSystemInfoList.forEach(item => {
    rows.push(prepareMetricsTableRowFromLog(item));
  });

  return rows;
};

export const getMetricParamsList = (
  systemMetrics: SystemMetrics[]
): string[] => {
  const uniqueParamSet = new Set<string>();

  systemMetrics.forEach(metric => {
    for (const key in metric) {
      if (isValidMetricKey(key, metric[key as keyof SystemMetrics])) {
        uniqueParamSet.add(key);
      }
    }
  });

  return Array.from(uniqueParamSet);
};
