import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import MultipleTextTable from './MultipleTextTable';

const TextGroupedBy = ({
  assets,
  isFullHeight,
  experimentKeys,
  setAssetHeight,
  getAssetHeight
}) => {
  const [expanded, setExpanded] = useState({});

  return Object.entries(assets).map(([category, categoryAssets]) => {
    const handleExpanded = (event, isExpanded) => {
      setExpanded({
        ...expanded,
        [category]: isExpanded
      });
    };

    const isExpanded = expanded[category];

    return (
      <ExpansionPanel
        key={category}
        expanded={isExpanded}
        onChange={handleExpanded}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{category}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {isExpanded && (
            <MultipleTextTable
              assets={categoryAssets}
              isFullHeight={isFullHeight}
              experimentKeys={experimentKeys}
              setAssetHeight={setAssetHeight}
              getAssetHeight={getAssetHeight}
            />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
};

TextGroupedBy.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFullHeight: PropTypes.bool.isRequired,
  experimentKeys: PropTypes.array.isRequired,
  setAssetHeight: PropTypes.func.isRequired,
  getAssetHeight: PropTypes.func.isRequired
};

export default TextGroupedBy;
