import PropTypes from 'prop-types';
import { useQueries } from 'react-query';
import { getExperimentActive } from '@experiment-management-shared/utils/experimentHelpers';
import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import assetsApi from '../../util/assetsApi';
import { ExperimentDetails } from '@shared/types';
import { QueryConfig } from '@shared/api';
import { AxiosResponse } from 'axios';
import { AssetData } from '@shared/types/assetData';

const getAssets = async (experimentKey: string, type: string) => {
  return (
    (await assetsApi.fetchAssetsForExperiment(experimentKey, type))?.data || []
  );
};

type UseAssetsForExperimentsOpts = {
  experiments: ExperimentDetails[];
  type: string;
};

const useAssetsForExperiments = (
  { experiments, type }: UseAssetsForExperimentsOpts,
  options: QueryConfig<AxiosResponse<AssetData[]>> = {}
) => {
  const response = useQueries(
    experiments.map<QueryConfig<AxiosResponse<AssetData[]>>>(experiment => {
      const { experimentKey } = experiment;
      const isActive = getExperimentActive(experiment);

      return {
        queryKey: ['assetsForExperiments', { experimentKey, type }],
        queryFn: () => getAssets(experimentKey, type),
        refetchInterval: isActive ? FETCH_EXPERIMENT_POLLING_LENGTH : false,
        refetchOnMount: 'always',
        ...options
      };
    })
  );

  const retVal = {
    isLoading: false,
    isError: false,
    data: [] as AssetData[]
  };

  response.reduce((acc, r) => {
    r.data?.data;
    acc.isLoading = acc.isLoading || r.isLoading;
    acc.isError = acc.isError || r.isError;
    acc.data = acc.data.concat((r.data ?? []) as AssetData[]);
    return acc;
  }, retVal);

  // useDeepMemo is using here po preserve the same link to the array in case data is not changed
  retVal.data = useDeepMemo(() => {
    return retVal.data;
  }, [retVal.data]);

  return retVal;
};
export default useAssetsForExperiments;

export const IAssetData = PropTypes.shape({
  assetId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  step: PropTypes.number,
  link: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
  dir: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  canView: PropTypes.bool.isRequired,
  audio: PropTypes.bool.isRequired,
  video: PropTypes.bool.isRequired,
  histogram: PropTypes.bool.isRequired,
  image: PropTypes.bool.isRequired
});
