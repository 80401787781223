import { useEffect, useMemo, useState } from 'react';
import {
  DATETIME_CELL_KEYS,
  METRIC_COLUMNS_NAME,
  METRIC_TABLE_COLUMNS,
  STEP_CELL_KEYS,
  VALUE_CELL_KEYS
} from '../metricsTableConfig';
import { PARAM_ACTION_COLUMN } from '@experiment-details/components/params/helpers';
import { Metric } from '@shared/types';
import { useScrollbarWidth } from '@shared/hooks';
import { VIRTUALIZATION_TABLE_LIMIT } from '@DesignSystem/tables/Table/Table';
import { getTextWidth } from '@shared/hooks/useMiddleTruncation';

const ACTION_COLUMN_WIDTH = 60;

type Column = {
  columnName: string;
  width: number;
};

const DATETIME_CELL_WIDTH = 155;

const defaultColumnWidths: Column[] = [
  { columnName: PARAM_ACTION_COLUMN, width: ACTION_COLUMN_WIDTH },
  DATETIME_CELL_KEYS.map(columnName => ({
    columnName,
    width: DATETIME_CELL_WIDTH
  })),
  STEP_CELL_KEYS.map(columnName => ({
    columnName,
    width: 140
  })),
  VALUE_CELL_KEYS.map(columnName => ({
    columnName,
    width: 140
  })),
  {
    columnName: METRIC_COLUMNS_NAME.NAME,
    width: 200
  }
].flat();

const columnExtensions = [
  {
    columnName: METRIC_COLUMNS_NAME.NAME,
    minWidth: 200
  },
  DATETIME_CELL_KEYS.map(columnName => ({
    columnName,
    minWidth: DATETIME_CELL_WIDTH
  })),
  STEP_CELL_KEYS.map(columnName => ({
    columnName,
    minWidth: 60
  })),
  VALUE_CELL_KEYS.map(columnName => ({
    columnName,
    minWidth: 60
  })),
  { columnName: PARAM_ACTION_COLUMN, minWidth: ACTION_COLUMN_WIDTH }
].flat();

type UseMetricTableColumnWidthsOpts = {
  rowData: Metric[];
  containerWidth: number;
  defaultHiddenColumnNames: string[];
};

export const MetricNameCellPadding = 50;

const useMetricTableColumnWidths = ({
  rowData,
  containerWidth,
  defaultHiddenColumnNames
}: UseMetricTableColumnWidthsOpts) => {
  let scrollbarWidth = useScrollbarWidth();
  const shouldBeVirtualized = rowData.length > VIRTUALIZATION_TABLE_LIMIT;
  if (!shouldBeVirtualized) {
    scrollbarWidth = 0;
  }

  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

  useEffect(() => {
    let keyColumnWidth = 200;

    rowData.forEach(row => {
      const contentWidth =
        getTextWidth(row[METRIC_COLUMNS_NAME.NAME as 'name'], {
          fontSize: 13
        }) + MetricNameCellPadding;
      keyColumnWidth = Math.max(keyColumnWidth, contentWidth);
    });
    const actionColumnWidth = defaultHiddenColumnNames.includes(
      PARAM_ACTION_COLUMN
    )
      ? 0
      : ACTION_COLUMN_WIDTH;

    const availableWidth =
      containerWidth - keyColumnWidth - scrollbarWidth - actionColumnWidth - 2;

    setColumnWidths(prev =>
      prev.map(col => {
        if (DATETIME_CELL_KEYS.includes(col.columnName)) {
          const width = Math.max(
            (availableWidth * 0.3) / DATETIME_CELL_KEYS.length,
            DATETIME_CELL_WIDTH
          );
          return {
            ...col,
            width
          };
        }
        if (STEP_CELL_KEYS.includes(col.columnName)) {
          return {
            ...col,
            width: Math.max((availableWidth * 0.3) / STEP_CELL_KEYS.length, 100)
          };
        }
        if (VALUE_CELL_KEYS.includes(col.columnName)) {
          return {
            ...col,
            width: Math.max(
              (availableWidth * 0.4) / VALUE_CELL_KEYS.length,
              100
            )
          };
        }

        if (col.columnName === METRIC_COLUMNS_NAME.NAME) {
          return {
            ...col,
            width: keyColumnWidth
          };
        }

        return col;
      })
    );
  }, [containerWidth]);

  const disabledColumns = useMemo(
    () =>
      rowData.length
        ? [PARAM_ACTION_COLUMN]
        : METRIC_TABLE_COLUMNS.map(col => col.name),
    [rowData.length]
  );

  return {
    columnWidths,
    onColumnWidthsChange: setColumnWidths,
    columnExtensions,
    disabledColumns
  };
};

export default useMetricTableColumnWidths;
