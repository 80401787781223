import api from '@shared/api';
import { CodePanelRevision } from './types';

interface CreateCodeAndTemplateBody {
  projectId: string;
  name: string;
  code: string;
}

interface TemplateAndRevision {
  templateId: string;
  revisionId: string;
}

interface RevisionResponse {
  revisionId: string;
}

export const createCodePanelTemplateAndRevision = async (
  body: CreateCodeAndTemplateBody
) => {
  return api.post<TemplateAndRevision>('code-panels/templates', body);
};

export const updateCodePanelRevision = async (
  templateId: string,
  newRevision: Partial<CodePanelRevision>
) => {
  return api.post<RevisionResponse>(
    `code-panels/templates/${templateId}/revisions`,
    newRevision
  );
};
