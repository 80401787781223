import { useRef } from 'react';
import isEqual from 'fast-deep-equal';

export default function useDeepMemo(memoFn, key) {
  const ref = useRef();

  if (!ref.current || !isEqual(key, ref.current.key)) {
    const value = memoFn();

    if (!ref.current || !isEqual(value, ref.current.value)) {
      ref.current = { key, value };
    }
  }

  return ref.current.value;
}
