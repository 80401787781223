import { useEffect } from 'react';

const useBeamer = username => {
  useEffect(() => {
    if (window.beamerId) {
      // eslint-disable-next-line
      window.beamer_config = {
        product_id: window.beamerId,
        user_id: username,
        selector: 'togglebeamer',
        counter: false,
        callback: unreadCount => {
          const beamerEvent = new CustomEvent('beamerUpdate', {
            detail: {
              eventCount: unreadCount
            }
          });
          window.dispatchEvent(beamerEvent);
        },
        onopen: () => {
          const beamerEvent = new CustomEvent('beamerUpdate', {
            detail: {
              eventCount: 0
            }
          });
          window.dispatchEvent(beamerEvent);
        }
      };

      const beamerURL = 'https://app.getbeamer.com/js/beamer-embed.js';

      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');

      script.defer = 'defer';
      script.src = beamerURL;
      head.appendChild(script);
    }
  }, [username]);
};

export default useBeamer;
