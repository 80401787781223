import { useQuery } from 'react-query';

import api from '@API/api';
import { AlertRule } from '@mpm/types';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';

type AlertRulesByIDParams = {
  modelId: string;
  alertRuleIds: string[];
};

type GetAlertRulesByIDProps = AlertRulesByIDParams & { workspaceId: string };

type AlertRulesResponse = {
  alertRules: AlertRule[];
  total: number;
};

const getAlertRulesByID = ({
  workspaceId,
  modelId,
  ...restParams
}: GetAlertRulesByIDProps) => {
  return api.post(
    `mpm/${workspaceId}/models/${modelId}/alert-rules/getById`,
    restParams
  );
};

export function useAlertRulesByID(
  params: AlertRulesByIDParams,
  config: QueryConfig<AlertRulesResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['alertRulesById', { workspaceId: activeWorkspace?.id, ...params }],
    () => getAlertRulesByID({ workspaceId: activeWorkspace?.id, ...params }),
    {
      enabled: !!activeWorkspace?.id && config.enabled
    }
  );
}
