import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Tooltip } from '@ds';

import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import Paper from '@material-ui/core/Paper';
import SearchBar from 'material-ui-search-bar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';

import useProjectTags from '@API/project/useProjectTags';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

import { DSDeleteIcon, DSTagIcon } from '@ds-icons';

const ACTION_ICON = {
  EVERY: {
    actionClass: 'add',
    materialIconType: 'check',
    tooltipText:
      'All selected experiments have this tag. Click to remove tag from all selected experiments.'
  },
  NONE: {
    actionClass: 'hide-icon',
    materialIconType: 'add_circle',
    tooltipText: 'Add tag to all selected experiments'
  },
  SOME: {
    actionClass: 'remove',
    materialIconType: 'remove',
    tooltipText:
      'At least one selected experiment has this tag. Click to remove tag from all selected experiments.'
  }
};

const TagSelectButton = ({
  canEdit,
  disabled,
  onChange,
  onRemove,
  selectedExperiments
}) => {
  const dispatch = useDispatch();
  const { data: tags, isLoading: isLoadingTags } = useProjectTags();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  if (isLoadingTags) return null;

  const handleToggle = event => {
    setAnchorEl(event.currentTarget);
    setIsOpen(currentIsOpen => !currentIsOpen);
    setSearchValue('');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const isAtLeastOneExperimentWithTag = tagName => {
    return selectedExperiments.some(experiment => {
      return experiment.tags.includes(tagName);
    });
  };

  const isTagOnAllExperiments = tagName => {
    if (isEmpty(selectedExperiments)) return false;

    return selectedExperiments.every(experiment => {
      if (experiment.hasOwnProperty('tags')) {
        return experiment.tags.includes(tagName);
      }

      return false;
    });
  };

  const getTagUpdates = tagName => {
    if (isAtLeastOneExperimentWithTag(tagName)) {
      return selectedExperiments.map(experiment => {
        return {
          experimentId: experiment.experimentKey,
          addedTags: [],
          deletedTags: [tagName]
        };
      });
    }

    return selectedExperiments.map(experiment => {
      const tagsToAdd = experiment.tags.includes(tagName) ? [] : [tagName];

      return {
        experimentId: experiment.experimentKey,
        addedTags: tagsToAdd,
        deletedTags: []
      };
    });
  };

  const applySelectedTag = tagName => {
    const tagUpdates = getTagUpdates(tagName);

    onChange(tagUpdates);
  };

  const filterBySearch = () => {
    if (!searchValue.length) {
      return tags;
    }

    return tags.filter(tag => {
      return tag.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const handleCreateTagForProject = tagName => {
    if (isEmpty(tagName)) return;

    const tagNameList = tags.map(tag => tag.name);

    if (!tagNameList.includes(tagName)) {
      setSearchValue('');
    }

    applySelectedTag(tagName);
  };

  const handleConfirmedDeleteTag = tagName => {
    onRemove(tagName);
    dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_EXPERIMENT_TAG));
  };

  const handleDeleteTag = tagName => {
    dispatch(
      alertsUtil.openConfirmDialog(
        dialogTypes.CONFIRM_DELETE_EXPERIMENT_TAG,
        'This action will delete this tag.',
        () => handleConfirmedDeleteTag(tagName),
        'Delete tag'
      )
    );
  };

  const renderActionIcon = tagName => {
    let key;

    if (isTagOnAllExperiments(tagName)) {
      key = 'EVERY';
    } else if (isAtLeastOneExperimentWithTag(tagName)) {
      key = 'SOME';
    } else {
      key = 'NONE';
    }

    const { actionClass, materialIconType, tooltipText } = ACTION_ICON[key];

    return (
      <Tooltip content={tooltipText}>
        <i className={`material-icons ${actionClass}`}>{materialIconType}</i>
      </Tooltip>
    );
  };

  const renderCreateTagBtn = () => {
    return (
      <Button
        size="large"
        className="button blue create-tag-btn"
        onClick={() => handleCreateTagForProject(searchValue)}
      >
        Create this tag
      </Button>
    );
  };

  const renderDeleteTagBtn = tagName => {
    return (
      <Tooltip content="Delete tag from project" placement="right-end">
        <IconButton
          className="delete-tag-icon white-color"
          Icon={<DSDeleteIcon />}
          onClick={() => handleDeleteTag(tagName)}
        />
      </Tooltip>
    );
  };

  const renderListItem = listItem => {
    const { name, color } = listItem;

    return (
      <ListItem
        classes={{ container: 'select-list-item' }}
        key={name}
        onClick={() => applySelectedTag(name)}
      >
        <ListItemText
          className="select-list-text"
          primary={
            <div className="tag-label-wrap">
              <div className="tag-action-container">
                {canEdit && renderActionIcon(name)}
              </div>
              <div className="tag-color" style={{ backgroundColor: color }} />
              <p className="select-list-template-name">{name}</p>
            </div>
          }
        />
        <ListItemSecondaryAction className="delete-tag-button-container select-list-secondary-actions delete-button-show">
          {canEdit && renderDeleteTagBtn(name)}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderListItems = () => {
    const listItemsSortedByName = filterBySearch().sort();
    const hasItems = listItemsSortedByName.length && tags.length;

    if (!hasItems) {
      return (
        <ListSubheader className="list-message" component="div">
          <div className="list-message-content">
            {!tags.length ? (
              'Type a tag name and hit enter to create a tag'
            ) : (
              <span>
                <p>
                  0 tags matching:
                  <strong>{searchValue}</strong>
                </p>
                {renderCreateTagBtn()}
              </span>
            )}
          </div>
        </ListSubheader>
      );
    }

    return listItemsSortedByName.map(renderListItem);
  };

  const renderPopover = () => {
    if (!isOpen) return null;

    return (
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="select-list-container"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          <List className="select-list">
            <ListItem className="select-list-search">
              <SearchBar
                onCancelSearch={() => setSearchValue('')}
                onChange={setSearchValue}
                onRequestSearch={handleCreateTagForProject}
                value={searchValue}
              />
            </ListItem>
            <Divider />
            {renderListItems()}
          </List>
        </Paper>
      </Popover>
    );
  };

  return (
    <>
      <Button
        size="large"
        disabled={disabled}
        onClick={handleToggle}
        PrefixIcon={<DSTagIcon />}
      >
        Tag
      </Button>

      {renderPopover()}
    </>
  );
};

TagSelectButton.defaultProps = {
  onChange: noop,
  onRemove: noop
};

TagSelectButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  selectedExperiments: PropTypes.array.isRequired
};

export default TagSelectButton;
