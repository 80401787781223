import useColumns from '@API/project/useColumns';
import useExperimentMetrics from '@experiment-management-shared/api/useExperimentMetrics';
import {
  formatValueForSelectOption,
  minMaxColumnToOption
} from '@shared/utils/selectComponentHelper';
import { getIsExperimentPage } from '@shared/utils/url';
import { useCallback, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { useExperimentUniqueParams } from '@experiment-management-shared/api';
import { SOURCES } from '@experiment-management-shared/constants/source';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import { formatNameForDisplay } from '@shared/utils/displayHelpers';

const METRICS_ONLY = [
  BUILT_IN_CHART_TYPES['BuiltIn/Line'],
  BUILT_IN_CHART_TYPES['BuiltIn/Bar']
];

const WITH_EXTRA_OPTIONS = [
  BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']
];

const sortOptions = options =>
  sortBy(options, [option => option.source.toLowerCase()]);

const addSourceLabelToOptions = (sortedOptions, groups) => {
  const indexes = {};

  groups.forEach(group => {
    indexes[group] = sortedOptions.findIndex(option => option.source === group);
  });

  return sortedOptions;
};

const getSortedOptionsBySource = (options, sources, isExtraOptions = false) => {
  const optionsBySource = options
    .filter(col => sources.includes(col.source))
    .map(option => {
      return formatValueForSelectOption(
        option.name,
        option.source,
        [],
        isExtraOptions ? minMaxColumnToOption(option) : undefined,
        option
      );
    });

  return sortOptions(optionsBySource);
};

const useChartTabYValues = ({ type }) => {
  const isExperimentPage = getIsExperimentPage();
  const isParamsNeeded = !METRICS_ONLY.includes(type);

  const { data: experimentMetrics = [] } = useExperimentMetrics({
    enabled: isExperimentPage
  });

  const { data: experimentParams = [] } = useExperimentUniqueParams({
    enabled: isParamsNeeded && isExperimentPage
  });

  const { data: projectColumns = [] } = useColumns();
  const isWithExtraOptions = WITH_EXTRA_OPTIONS.includes(type);

  // experiment level
  const transformToOption = useCallback(
    source => option => {
      // column has more information about the option
      const optionColumn = projectColumns?.find(
        column => column.name === option.name
      );

      return {
        value: option.name,
        label: formatNameForDisplay(option.name),
        source,
        type: optionColumn?.type || '',
        name: option.name,
        extraOptions: isWithExtraOptions
          ? minMaxColumnToOption(optionColumn)
          : undefined
      };
    },
    [projectColumns, isWithExtraOptions]
  );

  const getYValuesForExperiment = useCallback(() => {
    const options = experimentMetrics.map(transformToOption(SOURCES.metrics));

    if (isParamsNeeded) {
      options.push(...experimentParams.map(transformToOption(SOURCES.params)));
      return addSourceLabelToOptions(sortOptions(options), [
        SOURCES.metrics,
        SOURCES.params
      ]);
    }

    return sortOptions(options);
  }, [experimentMetrics, experimentParams, isParamsNeeded, transformToOption]);

  // <----------

  // project level
  const getYValuesForProject = useCallback(() => {
    const sources = [SOURCES.metrics];

    if (isParamsNeeded) {
      sources.push(SOURCES.params);
    }

    const options = getSortedOptionsBySource(
      projectColumns,
      sources,
      isWithExtraOptions
    );

    if (isParamsNeeded) {
      return addSourceLabelToOptions(options, [
        SOURCES.metrics,
        SOURCES.params
      ]);
    }

    return options;
  }, [isParamsNeeded, projectColumns, isWithExtraOptions]);
  // <-------------

  const yValues = useMemo(() => {
    if (isExperimentPage) {
      return getYValuesForExperiment();
    }

    return getYValuesForProject();
  }, [getYValuesForExperiment, isExperimentPage, getYValuesForProject]);

  return yValues;
};

export default useChartTabYValues;
