import React from 'react';
import { useExperimentMetrics } from '@experiment-details/api';
import SmallLoader from '@shared/components/SmallLoader';
import { ExperimentDetails } from '@shared/types';
import MetricsTableContainer from './MetricsTableContainer';

type MetricsTabProps = {
  experiments: ExperimentDetails[];
};
const MetricsTab: React.FC<MetricsTabProps> = ({ experiments }) => {
  const [experiment] = experiments;
  const { data: metrics = [], isLoading } = useExperimentMetrics({
    experimentKey: experiment.experimentKey
  });

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching experiment metrics"
        isFlexDisplay
      />
    );
  }

  return (
    <MetricsTableContainer
      experiment={experiment}
      items={metrics}
      isFetching={isLoading}
    />
  );
};

export default MetricsTab;
