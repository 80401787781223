import { useMutation, useQueryClient } from 'react-query';

import api from '../api';

import useProject from '../project/useProject';

const setExperimentColor = ({ color, experimentKey }) => {
  return api.post('experiment/color/set', {
    color,
    experiment_key: experimentKey
  });
};

export default function useSetExperimentColorMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ color, experimentKey }) => setExperimentColor({ color, experimentKey }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['experiments', { projectId }]);
        await queryClient.cancelQueries(['experimentsDetails', { projectId }]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['experiments', { projectId }]);
        queryClient.invalidateQueries(['experimentsDetails', { projectId }]);
      }
    }
  );
}
