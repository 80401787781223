import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { AxiosError } from 'axios';

type UpsertExperimentOtherParams = {
  experimentKey: string;
  name: string;
  value: string | number;
};

const upsertExperimentOther = async ({
  experimentKey,
  name,
  value
}: UpsertExperimentOtherParams) => {
  await api.post('experiment/update/other', {
    experimentKey,
    metricName: name,
    metricValue: value
  });
};

export default function useUpsertExperimentOtherMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, UpsertExperimentOtherParams>(
    ({ experimentKey, name, value }) => {
      return upsertExperimentOther({
        experimentKey,
        name,
        value
      });
    },
    {
      onMutate: async ({ experimentKey }) => {
        await queryClient.cancelQueries([
          'experiment-others',
          { experimentKey }
        ]);
      },
      onSuccess: async (_, { experimentKey }) => {
        await queryClient.invalidateQueries([
          'experiment-others',
          { experimentKey }
        ]);
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error updating this param.'
          )
        );
      }
    }
  );
}
