import { isEmpty } from 'lodash';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { namesToColumns } from '@experiment-management-shared/utils/reactGridHelpers';
import { trackMetricKindSelection } from '@shared/utils/eventTrack';
import { formatColumnsToSelectOptions } from '@shared/utils/selectComponentHelper';

import React, { useEffect, useMemo, useState } from 'react';
import { BasicModal } from '@DesignSystem/modals';
import CustomizeColumnsModalSelect from './CustomizeColumnsModalSelect';
import '../ColumnsModal.scss';

const CustomizeColumnsModal = ({
  onUpdateColumns,
  onClose,
  selectedColumnNames,
  data,
  extraCols,
  isLoading,
  defaultColumns,
  fixedColumnsNames = [],
  shouldTrackMetrics = false,
  columnsNameMap
}) => {
  const [allSelectedOptions, setAllSelectedOptions] = useState([]);
  const [formattedSelectedColumns, setFormattedSelectedColumns] = useState([]);
  const [, setIsColumnsReset] = useState(false);

  const formattedDefaultColumns = useMemo(() => {
    if (isLoading) return [];

    return formatColumnsToSelectOptions(
      namesToColumns(data, defaultColumns),
      columnsNameMap
    );
  }, [data, isLoading, columnsNameMap]);

  useEffect(() => {
    // when user opened popup, and we received columns from API we need to ignore all other changes of columns
    // to preserve edit state during user manipulations
    if (!isLoading && isEmpty(formattedSelectedColumns)) {
      const columns = formatColumnsToSelectOptions(
        namesToColumns([...data, ...extraCols], selectedColumnNames),
        columnsNameMap
      );
      setFormattedSelectedColumns(columns);
      setAllSelectedOptions([...columns]);
    }
  }, [data, extraCols, isLoading, selectedColumnNames, columnsNameMap]);

  const formattedSelectOptions =
    useMemo(
      () => {
        if (isLoading) return [];

        return formatColumnsToSelectOptions(data, columnsNameMap);
      },
      [data, isLoading, columnsNameMap],
      columnsNameMap
    ) || [];

  if (isLoading) return null;

  const handleClearCurrentChanges = () => {
    setAllSelectedOptions(formattedSelectedColumns);
    setIsColumnsReset(false);
  };

  const handleColumnToggle = (newSelectedOptions, action) => {
    if (shouldTrackMetrics) {
      trackMetricKindSelection('customize-columns', action);
    }

    setAllSelectedOptions(newSelectedOptions);
    setIsColumnsReset(false);
  };

  const handleResetDefaultColumns = () => {
    setAllSelectedOptions(formattedDefaultColumns);
    setIsColumnsReset(true);
  };

  const handleUpdateSelectedColumns = () => {
    onUpdateColumns(allSelectedOptions.map(({ value }) => value));
  };

  return (
    <BasicModal
      open
      className="columns-modal"
      title="Customize Columns"
      onClose={onClose}
      content={
        <CustomizeColumnsModalSelect
          allSelectedOptions={allSelectedOptions}
          handleColumnToggle={handleColumnToggle}
          options={formattedSelectOptions}
          fixedColumns={fixedColumnsNames}
        />
      }
      primaryButtonText="Done"
      onPrimaryButtonClick={handleUpdateSelectedColumns}
      secondaryButtonText="Reset default"
      onSecondaryButtonClick={handleResetDefaultColumns}
      footerActions={[
        {
          onClick: handleClearCurrentChanges,
          disabled: false,
          key: 'clear-changes',
          type: 'tertiary',
          text: 'Clear changes',
          size: 'large'
        }
      ]}
    />
  );
};

CustomizeColumnsModal.defaultProps = {
  onClose: noop,
  onUpdateColumns: noop,
  selectedColumnNames: [],
  data: [],
  extraCols: [],
  isLoading: false,
  shouldTrackMetrics: true,
  columnsNameMap: {}
};

CustomizeColumnsModal.propTypes = {
  shouldTrackMetrics: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateColumns: PropTypes.func,
  extraCols: PropTypes.arrayOf({
    name: PropTypes.string,
    title: PropTypes.string
  }),
  data: PropTypes.arrayOf({
    name: PropTypes.string,
    title: PropTypes.string
  }),
  fixedColumnsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedColumnNames: PropTypes.arrayOf(PropTypes.string),
  columnsNameMap: PropTypes.object
};

export default CustomizeColumnsModal;
