import { experimentEvents } from '@/constants/trackingEventTypes';
import {
  CPU_MEMORY_AVAILABLE_PATTERN,
  CPU_MEMORY_USAGE_PATTERN,
  CPU_OVERALL_PATTERN,
  CPU_PERCENT_PATTERN,
  CPU_UTILIZED_PATTERN,
  DISK_PERCENT_USED_PATTERN,
  DISK_READ_PATTERN,
  DISK_USED_PATTERN,
  DISK_WRITE_PATTERN,
  GPU_MEMORY_USAGE_PATTERN,
  GPU_MEMORY_UTILIZATION_PATTERN,
  GPU_POWER_USAGE_PATTERN,
  GPU_TEMPERATURE_PATTERN,
  GPU_UTILIZATION_PATTERN,
  NETWORK_RECEIVE_PATTERN,
  NETWORK_SEND_PATTERN
} from '@experiment-management-shared/utils/experimentHelpers';
import { trackEvent } from '@shared/utils/eventTrack';
import useDeepCompareEffect from 'use-deep-compare-effect';

type UseSystemMetricBIOpts = {
  isLoading: boolean;
  systemMetricNames: string[];
};
const useSystemMetricBI = ({
  isLoading,
  systemMetricNames
}: UseSystemMetricBIOpts) => {
  useDeepCompareEffect(() => {
    if (isLoading) return;

    trackEvent(experimentEvents.EXPERIMENT_DETAIL_SYSTEM_METRIC, {
      isCPUDataExist: systemMetricNames.some(
        m =>
          CPU_OVERALL_PATTERN.test(m) ||
          CPU_PERCENT_PATTERN.test(m) ||
          CPU_UTILIZED_PATTERN.test(m)
      ),
      isDiskDataExist: systemMetricNames.some(
        m =>
          DISK_PERCENT_USED_PATTERN.test(m) ||
          DISK_READ_PATTERN.test(m) ||
          DISK_USED_PATTERN.test(m) ||
          DISK_WRITE_PATTERN.test(m)
      ),
      isGPUDataExist: systemMetricNames.some(
        m =>
          GPU_MEMORY_USAGE_PATTERN.test(m) ||
          GPU_MEMORY_UTILIZATION_PATTERN.test(m) ||
          GPU_POWER_USAGE_PATTERN.test(m) ||
          GPU_TEMPERATURE_PATTERN.test(m) ||
          GPU_UTILIZATION_PATTERN.test(m)
      ),
      isRAMDataExist: systemMetricNames.some(
        m =>
          CPU_MEMORY_AVAILABLE_PATTERN.test(m) ||
          CPU_MEMORY_USAGE_PATTERN.test(m)
      ),
      isNetworkDataExist: systemMetricNames.some(
        m => NETWORK_SEND_PATTERN.test(m) || NETWORK_RECEIVE_PATTERN.test(m)
      )
    });
    // On dependencies needed only metricNames property to only trigger the event once, when page is rendered
  }, [systemMetricNames, isLoading]);
};

export default useSystemMetricBI;
