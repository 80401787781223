import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import cx from 'classnames';
import { SearchInput } from '@ds';

import styles from './ExperimentBar.module.scss';

const SEARCH_TEXT_DELAY = 300;

export type ExperimentBarSearchProps = {
  searchText?: string;
  setSearchText: (value: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const ExperimentBarSearch = ({
  searchText = '',
  setSearchText,
  label = 'Search by name',
  placeholder = 'Search (regex)',
  disabled = false
}: ExperimentBarSearchProps) => {
  const [search, setSearch] = useState<string>(searchText);

  useEffect(() => {
    setSearch(searchText);
  }, [searchText]);

  const handleDebouncedSearchChange = useCallback(
    debounce(setSearchText, SEARCH_TEXT_DELAY),
    [setSearchText]
  );

  const handleSearchChange = useCallback(
    event => {
      const value = event.target.value;

      setSearch(value);
      handleDebouncedSearchChange(value);
    },
    [handleDebouncedSearchChange]
  );

  return (
    <div className={cx(styles.component, styles.search)}>
      <div className={styles.title}>{label}</div>
      <div className={styles.componentWrapper}>
        <SearchInput
          disabled={disabled}
          placeholder={placeholder}
          value={search}
          onChange={handleSearchChange}
          onClearIconClick={() => setSearchText('')}
        />
      </div>
    </div>
  );
};

export default ExperimentBarSearch;
