import moment from 'moment';
import { getURLQueryParams } from '@shared/utils/url';

import {
  CHART_GALLERY_COLUMNS,
  TEMPLATE_SCOPE_TYPES,
  CHART_GALLERY_TYPES,
  PANEL_CODE_LANGUAGES
} from '@experiment-management-shared/constants/chartsGallery';

import { BASE_API_URL } from '@/lib/appConstants';
import { trimSuffix } from '@/helpers/generalHelpers';

export const formatRevision = revisionId => {
  return moment(revisionId).format('MM/DD/YYYY hh:mm a');
};

export const getVisualizationThumbnailURL = templateId =>
  `${BASE_API_URL}image/code-visualizations/thumbnail/download?templateId=${templateId}`;

export const getTemplateIdFromURL = codeTemplateId => {
  const params = getURLQueryParams();
  const chartId = params.get('chartId');
  const templateId = codeTemplateId
    ? trimSuffix(codeTemplateId, `&chartId=${chartId}`)
    : null;

  return templateId;
};

const { CUSTOM, FEATURED } = CHART_GALLERY_TYPES;
const { INTERNAL } = TEMPLATE_SCOPE_TYPES;

export const normalizeTemplateForGallery = (
  {
    code,
    revisionId,
    templateId,
    templateName,
    scopeType,
    owner,
    rank,
    editable
  },
  galleryScopeType
) => ({
  [CHART_GALLERY_COLUMNS.ID]: templateId,
  [CHART_GALLERY_COLUMNS.CODE_LANGUAGE]:
    code.type || PANEL_CODE_LANGUAGES.JAVASCRIPT,
  [CHART_GALLERY_COLUMNS.DESCRIPTION]: code.description,
  [CHART_GALLERY_COLUMNS.LAST_SAVED]: revisionId,
  [CHART_GALLERY_COLUMNS.NAME]: templateName,
  [CHART_GALLERY_COLUMNS.THUMBNAIL]: getVisualizationThumbnailURL(templateId),
  [CHART_GALLERY_COLUMNS.TYPE]: scopeType === INTERNAL ? FEATURED : CUSTOM,
  [CHART_GALLERY_COLUMNS.OWNER]: owner,
  [CHART_GALLERY_COLUMNS.RANK]: rank,
  [CHART_GALLERY_COLUMNS.VOTE_COUNT]: rank.voteCount,
  scopeType,
  editable,
  galleryScopeType
});

export const isValidMessage = (origin, scopeType) => {
  // Since we are using a sandboxed iframe without the allow-same-origin
  // attribute, messages received from the iframe will have an origin ===
  // 'null', so we must explicitly check this origin and only handle
  // messages that we have defined.

  // However, since we control the code of our Featured panels, we are able
  // to set allow-same-origin to true and so as long as the scope type
  // is INTERNAL (Featured), we allow messages to be received

  return origin === 'null' || scopeType === TEMPLATE_SCOPE_TYPES.INTERNAL;
};
