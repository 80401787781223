import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { DSArrowDownIcon } from '@ds-icons';

import { Tooltip } from '@ds';

import { BreadcrumbItemProps } from '@shared/types';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import useBreadcrumbsTrackEvent from '@shared/hooks/useBreadcrumbsTrackEvent';

import styles from './BreadcrumbItem.module.scss';
import { Popover } from '@material-ui/core';
import UserMenuWorkspacesSelector from '@platform/components/UserMenu/components/UserMenuWorkspaces/UserMenuWorkspacesSelector';
import { usePopoverState } from '@shared/hooks';

const HomeItem = (itemParams: BreadcrumbItemProps) => {
  const { dataMaskTest, title } = itemParams;
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const trackEvent = useBreadcrumbsTrackEvent();
  const {
    openPopover,
    onClosePopover,
    closePopover,
    isPopoverOpened,
    anchorElement
  } = usePopoverState();

  const onWorkspaceClick = useCallback(
    (workspace: string) => {
      trackEvent(itemParams, { workspace });
      closePopover();
    },
    [trackEvent, itemParams]
  );

  return (
    <>
      <Tooltip
        content={title}
        size="small"
        enterDelay={300}
        enterNextDelay={300}
        wrapper={false}
        arrow={false}
      >
        <button
          className={cx(styles.homeItem, styles.item, {
            [styles.active]: isPopoverOpened
          })}
          data-test="breadcrumbs-workspace"
          onClick={openPopover}
          disabled={!isUserLoggedIn}
        >
          <span data-mask-test={dataMaskTest}>{title}</span>
          <DSArrowDownIcon />
        </button>
      </Tooltip>
      <Popover
        id="workspace-breadcrumbs-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          className: styles.workspaceSelectorContainer
        }}
        elevation={0}
        transitionDuration={0}
        open={isPopoverOpened}
        anchorEl={anchorElement}
        onClose={onClosePopover}
      >
        <UserMenuWorkspacesSelector
          onChange={onWorkspaceClick}
          dataTestPrefix="breadcrumbs-workspace"
        />
      </Popover>
    </>
  );
};

export default HomeItem;
