import React, { HTMLProps } from 'react';
import classes from './Checkbox.module.scss';
import cx from 'classnames';

import { DSCheckboxIcon, DSIndeterminateCheckboxIcon } from './icons';

export interface CheckboxProps
  extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  size?: 'default' | 'small';
  indeterminate?: boolean;
}

const Checkbox = ({
  size = 'default',
  indeterminate = false,
  ...props
}: CheckboxProps) => {
  const renderIcon = () => {
    if (indeterminate) {
      return (
        <div className={cx(classes.indeterminateIconContainer)}>
          <DSIndeterminateCheckboxIcon />
        </div>
      );
    }

    return (
      <div className={classes.checkboxIconContainer}>
        <DSCheckboxIcon />
      </div>
    );
  };

  return (
    <label className={cx(classes.checkboxInputContainer, classes[size])}>
      <input type="checkbox" {...props} />
      <div className={classes.checkboxContainer}>{renderIcon()}</div>
    </label>
  );
};

export default Checkbox;
