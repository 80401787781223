import React, { HTMLProps } from 'react';
import cx from 'classnames';
import typography from '@ds-typography';

import classes from './ListItemTitle.module.scss';
import { DSArrowRightIcon } from '@ds-icons';
import { ListTitleSize, ListTitleType } from '@design-system/types';

export interface ListItemTitleProps
  extends Omit<HTMLProps<HTMLLIElement>, 'size'> {
  type?: ListTitleType;
  active?: boolean;
  disabled?: boolean;
  text: string;
  withIcon?: boolean;
  size?: ListTitleSize;
}

const ListItemTitle = ({
  type = 'primary',
  disabled = false,
  active = false,
  text,
  withIcon = false,
  size = 'default',
  ...restProps
}: ListItemTitleProps) => {
  return (
    <li
      className={cx(
        classes[type],
        classes.listItemTitleContainer,
        typography.dsBody,
        typography.dsAccented,
        classes[size],
        {
          [classes.active]: active,
          [classes.disabled]: disabled,
          [classes.clickable]: withIcon
        }
      )}
      {...restProps}
    >
      {withIcon && (
        <div className={classes.iconContainer}>
          <DSArrowRightIcon />
        </div>
      )}
      <span className={classes.textContainer}>{text}</span>
    </li>
  );
};

export default ListItemTitle;
