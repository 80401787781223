import React, { useState, useEffect, useMemo } from 'react';
import { DeleteSecondaryIcon, EditIcon } from '@Icons-outdated';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import { DSColors } from '@design-system-outdated/constants';
import { Button } from '@ds';
import { Table } from '@/DesignSystem/components/tables';
import { dialogTypes } from '@/constants/alertTypes';
import {
  useCreateMetric,
  useMetricDelete,
  useMetricEdit,
  useCustomMetrics
} from '../../api';
import TextCell from '@shared/components/TableCells/TextCell';
import {
  CUSTOM_TRANSFORMATIONS_COLUMNS,
  CUSTOM_TRANSFORMATIONS_TABLE_HEIGHT,
  CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS,
  RIGHT_COLUMNS,
  METRIC_TYPES,
  DELETE_METRIC_BODY_TEXT
} from '@mpm/constants';
import alertsUtil from '@/util/alertsUtil';
import CreateEditMetricModal from '@mpm/components/CreateEditMetricModal';
import DeleteMetricModal from '@shared/components/DeleteMetricModal';
import SmallLoader from '@shared/components/SmallLoader';
import MoreActionsCell from '@shared/components/TableCells/MoreActionsCell';
import TableEmptyState from '@shared/components/TableEmptyState';
import EmptyCell from '@shared/components/TableCells/EmptyCell';
import './CustomTransformationsPage.scss';
import {
  TransformType,
  CustomMetricType,
  CreateMetricDataType,
  EditMetricDataType
} from '@mpm/types';

type MetricTypeCellProps = {
  value: TransformType;
};

type MetricRow = {
  definition: string;
  id: string;
  name: string;
  transformType: TransformType;
};

const MetricTypeCell = ({ value }: MetricTypeCellProps) => {
  return (
    <Box>
      {value ? <span>{METRIC_TYPES[value] || value}</span> : <EmptyCell />}
    </Box>
  );
};

const mapDataToRows = (customMetrics: CustomMetricType[]) => {
  return customMetrics.map(metric => {
    const { id, name, transformType, definition } = metric;
    return {
      id,
      name,
      transformType,
      definition
    };
  });
};

const CustomMetrics = () => {
  const [rows, setRows] = useState<MetricRow[]>([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'actions', width: 25 }
  ]);
  const dispatch = useDispatch();
  const createMetric = useCreateMetric();
  const deleteMetric = useMetricDelete();
  const editMetric = useMetricEdit();

  const { modelId } = useParams<{ modelId: string }>();
  const {
    isLoading,
    isError,
    data,
    isFetching,
    isPreviousData
  } = useCustomMetrics(
    {
      modelId
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const deleteMetricHandler = (row: MetricRow) => {
    const modalId = dialogTypes.CONFIRM_DELETE_METRIC;
    const deleteCustomMetric = () => {
      const metricId = row.id;
      deleteMetric.mutate({ metricId });
    };

    const deleteRuleModal = (
      <DeleteMetricModal
        modalId={modalId}
        title="Delete this metric from MPM?"
        subTitle={DELETE_METRIC_BODY_TEXT(row.name)}
        submitHandler={deleteCustomMetric}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, deleteRuleModal));
  };

  const editMetricHandler = (row: MetricRow) => {
    const modalId = dialogTypes.CONFIRM_EDIT_METRIC;
    const editCustomMetric = (formData: EditMetricDataType) => {
      editMetric.mutate({ metricData: formData });
    };

    const createCustomMetric = (
      <CreateEditMetricModal
        title="Edit Metric"
        modalId={modalId}
        editFormData={row}
        submitHandler={editCustomMetric}
        type="edit"
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, createCustomMetric));
  };

  const actionsList = useMemo(
    () => [
      {
        title: 'Edit',
        icon: <EditIcon fill="#8C95A8" />,
        clickHandler: editMetricHandler
      },
      {
        title: 'Delete',
        icon: <DeleteSecondaryIcon />,
        clickHandler: deleteMetricHandler
      }
    ],
    []
  );

  const dataTypes = useMemo(
    () => [
      {
        cols: ['name'],
        cell: ({ value }: { value: string }) => (
          <TextCell value={value} isBold />
        )
      },
      {
        cols: ['transformType'],
        cell: MetricTypeCell
      },
      {
        cols: ['definition'],
        cell: TextCell
      },
      {
        cols: ['actions'],
        cell: ({ row }: { row: MetricRow }) => (
          <MoreActionsCell row={row} actionsList={actionsList} />
        )
      }
    ],
    []
  );

  const handleCreateMetric = () => {
    const modalId = dialogTypes.SUCCESS_CUSTOM_METRIC_CREATED;
    const createMetricHandler = (formData: CreateMetricDataType) => {
      createMetric.mutate({ metricData: formData });
    };

    const createCustomMetric = (
      <CreateEditMetricModal
        title="Create Metric"
        modalId={modalId}
        submitHandler={createMetricHandler}
        type="create"
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, createCustomMetric));
  };

  useEffect(() => {
    if (data) {
      setRows(mapDataToRows(data));
    }
  }, [data]);

  if (isLoading)
    return <SmallLoader primaryMessage={<p>Fetching custom models</p>} />;

  if (isError) {
    return <h1> Error while fetching custom models. </h1>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="1"
      className="custom-metrics-wrapper"
    >
      <Box display="flex" justifyContent="space-between" marginBottom="31px">
        <span className="metric-subtitle">Custom metrics</span>
        <Button onClick={handleCreateMetric} data-test="create-metric-button">
          Create Metric
        </Button>
      </Box>
      <Table
        isFetching={isFetching && isPreviousData}
        dataTypes={dataTypes}
        columns={CUSTOM_TRANSFORMATIONS_COLUMNS}
        rows={rows}
        rowHeight="72px"
        rowIdKey="id"
        renderEmptyState={props => (
          <TableEmptyState
            label="There are no custom metrics"
            materialProps={props}
            pictureHeight="46px"
            pictureWidth="58px"
            picturePath="/images/noMetricsIcon.svg"
          />
        )}
        header={{
          headerColor: DSColors.grayColor1
        }}
        maxHeight={CUSTOM_TRANSFORMATIONS_TABLE_HEIGHT}
        noPaddingColumns={CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS}
        rightColumns={RIGHT_COLUMNS}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          onColumnWidthsChange: setColumnWidths
        }}
        paginationConfig={{
          isDisabled: true
        }}
        selectionConfig={{
          isDisabled: true
        }}
      />
    </Box>
  );
};

export default CustomMetrics;
