import { useQuery } from 'react-query';
import modelRegistryApi from '../../../util/modelRegistryApi';

const getModelPendingRequests = async ({
  workspace,
  modelName,
  page,
  limit
}) => {
  const { data = {} } =
    (await modelRegistryApi.fetchModelPendingRequests({
      workspace,
      modelName,
      page,
      limit
    })) || {};

  return data;
};

const useModelRegistryPendingRequests = (
  { workspace, modelName, limit, page, updateLen = 0 },
  config
) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    [
      'modelRegistry',
      { workspace, modelName, limit, page, updateLen },
      'pending-requests'
    ],
    () =>
      getModelPendingRequests({
        workspace,
        modelName,
        limit,
        page
      }),
    {
      refetchOnMount: true,
      ...config,
      enabled: !!modelName && !!workspace && configEnabled
    }
  );
};

export default useModelRegistryPendingRequests;
