import { get, isEmpty } from 'lodash';
import { parseJSON } from '../shared/utils/jsonHelpers';

export const getCurrentPanelOptions = ({
  instance,
  template,
  modifiedOptions,
  jsOptionsFromCode,
  revisionId,
  isPy
}) => {
  const parsedInstanceConfig = parseJSON(instance && instance.config, {});
  const parsedPyConfig =
    (template && parseJSON(template.code && template.code.pyConfig, {})) || {};

  if (!isEmpty(modifiedOptions)) {
    return modifiedOptions;
  }

  if (!isEmpty(parsedInstanceConfig)) {
    return parsedInstanceConfig;
  }

  // for js panels there aren't template options, they're taken from code directly
  if (jsOptionsFromCode && !isPy) {
    return get(jsOptionsFromCode, revisionId, {});
  }

  if (!isEmpty(parsedPyConfig) && isPy) {
    return parsedPyConfig;
  }

  return {};
};
