import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';

import { CheckedIcon, CrossIcon, SearchIcon, TrashIcon } from '@Icons-outdated';
import { Input } from '@DesignSystem/controllers';
import { Button } from '@ds';
import { BasicModal } from '@DesignSystem/modals';
import useProjectTags from '@API/project/useProjectTags';
import { refPropType } from '@shared/constants/propTypes';

import noop from 'lodash/noop';

import styles from './ExperimentActionsModal.module.scss';
import useExperimentTagActions from './hooks/useExperimentTagActions';

export const TagPropType = PropTypes.shape({
  name: PropTypes.string,
  color: PropTypes.string
});

const TAG_EXPERIMENT_DEFAULT_VALUES = {
  searchValue: '',
  tagNameToDelete: null
};

const TagExperimentPopover = ({ anchorEl, onClose, tags: experimentTags }) => {
  const [hoveredTagName, setHoveredTagName] = useState(null);
  const [tagNameToDelete, setTagNameToDelete] = useState(
    TAG_EXPERIMENT_DEFAULT_VALUES.tagNameToDelete
  );
  const [searchValue, setSearchValue] = useState(
    TAG_EXPERIMENT_DEFAULT_VALUES.searchValue
  );

  const { data: tags, isLoading: isLoadingTags } = useProjectTags();

  useEffect(() => {
    if (anchorEl) {
      setTagNameToDelete(TAG_EXPERIMENT_DEFAULT_VALUES.tagNameToDelete);
      setSearchValue(TAG_EXPERIMENT_DEFAULT_VALUES.searchValue);
    }
  }, [anchorEl]);

  const openDeleteModal = tagName => setTagNameToDelete(tagName);
  const closeDeleteModal = () => {
    setTagNameToDelete(TAG_EXPERIMENT_DEFAULT_VALUES.tagNameToDelete);
  };

  const filteredTags = useMemo(() => {
    if (!searchValue.length) {
      return tags;
    }

    return tags.filter(tag => {
      return tag.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [tags, searchValue]);

  const isTagSelected = tagName => {
    return experimentTags?.includes(tagName);
  };

  const {
    applySelectedTag,
    handleCreateTagForProject,
    handleRemoveTag
  } = useExperimentTagActions({
    closeDeleteModal,
    isTagSelected,
    searchValue,
    setSearchValue
  });

  const handleKeyDown = e => {
    if (!filteredTags?.length && searchValue?.length && e.key === 'Enter') {
      handleCreateTagForProject();
    }
  };

  const renderTag = tag => {
    return (
      <div
        key={tag.name}
        className={styles.tagContainer}
        onClick={() => applySelectedTag(tag.name)}
        onMouseOver={() => setHoveredTagName(tag.name)}
        onMouseLeave={() => setHoveredTagName(null)}
      >
        <div className={styles.mainTagItem}>
          <div className={styles.isSelectedTagContainer}>
            {isTagSelected(tag.name) && <CheckedIcon />}
          </div>
          <div
            className={styles.tagColorSection}
            style={{ backgroundColor: tag.color }}
          />

          <div className={styles.tagNameLabel}>{tag.name}</div>
        </div>
        <div className={styles.hoverableActions}>
          {hoveredTagName === tag.name && (
            <div
              className={styles.deleteContainer}
              onClick={e => {
                e.stopPropagation();
                openDeleteModal(tag.name);
              }}
            >
              <TrashIcon />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!filteredTags?.length) {
      if (!searchValue.length) {
        return (
          <div className={styles.noMatchesContainer}>
            Type a tag name and hit enter to create a tag
          </div>
        );
      }

      return (
        <div className={styles.noMatchesContainer}>
          <div>
            0 tags matching:{' '}
            <p className={styles.noTagsSearchValue}>{searchValue}</p>
          </div>

          <div className={styles.createTagButtonContainer}>
            <Button onClick={handleCreateTagForProject}>Create this tag</Button>
          </div>
        </div>
      );
    }

    return <div className={styles.tagList}>{filteredTags.map(renderTag)}</div>;
  };

  if (isLoadingTags) {
    return null;
  }

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        className={styles.tagPopoverWrapper}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={styles.experimentTagsContainer}>
          <Input
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Search or type to create new"
            PostfixIcon={searchValue ? CrossIcon : SearchIcon}
            onPostfixIconClick={searchValue ? () => setSearchValue('') : noop}
            withoutBorder
            onKeyDown={handleKeyDown}
          />
          {renderContent()}
        </div>
      </Popover>

      <BasicModal
        open={Boolean(tagNameToDelete)}
        onClose={closeDeleteModal}
        title="Delete this tag?"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeDeleteModal}
        onPrimaryButtonClick={() => handleRemoveTag(tagNameToDelete)}
        className={styles.deleteTagModalWrapper}
      />
    </>
  );
};

TagExperimentPopover.propTypes = {
  anchorEl: refPropType.isRequired,
  onClose: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(TagPropType).isRequired
};

export default TagExperimentPopover;
