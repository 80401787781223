import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { MODEL_VERSION_PENDING_REQUESTS_ACTIONS } from '@model-registry/constants/request-status';

import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes } from '../../../constants/alertTypes';

const useModelRegistryStatusRequestAction = ({ workspace, modelName }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ status, comment, pendingRequestId }) =>
      modelRegistryApi.updateModelItemStatus({
        status,
        comment,
        pendingRequestId
      }),
    {
      onSuccess: (data, { msg } = {}) => {
        queryClient.invalidateQueries([
          'modelRegistry',
          { workspace, modelName },
          'pending-requests'
        ]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_CHANGE_MODEL_APPROVAL_STATUS,
            msg
          )
        );
      },
      onError: (err, { status }) =>
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            `There was an error ${
              status === MODEL_VERSION_PENDING_REQUESTS_ACTIONS.APPROVE
                ? 'approving'
                : 'rejecting'
            } status for model version.`
          )
        )
    }
  );
};

export default useModelRegistryStatusRequestAction;
