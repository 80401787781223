import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { Button, TextButton } from '@ds';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const NO_SCROLL_CLASS = 'no-scroll-modal-open';
const SCROLL_ELEMENT = document.body;

const GenericModal = ({
  buttonText,
  cancelText,
  children,
  customButtons,
  customClass,
  hasCancelButton,
  hasResetButton,
  hideFooter,
  isButtonDisabled,
  isConfirmModal,
  isNoScrollBackdrop,
  modalId,
  onCancel,
  onClose,
  onReset,
  shouldHideTitle,
  showPrimaryButton,
  submitButtonDataCy,
  successMessage,
  style,
  title
}) => {
  useEffect(() => {
    if (!isNoScrollBackdrop) return;

    SCROLL_ELEMENT.classList.add(NO_SCROLL_CLASS);

    return () => {
      SCROLL_ELEMENT.classList.remove(NO_SCROLL_CLASS);
    };
  }, [isNoScrollBackdrop]);

  const handleKeyPress = e => {
    if (!isButtonDisabled && e.key === 'Enter') {
      onClose();
    }
  };

  const renderContent = () => {
    return (
      <>
        {!shouldHideTitle && (
          <DialogTitle className="generic-modal-header">{title}</DialogTitle>
        )}
        {children}
        {!hideFooter && renderFooter()}
      </>
    );
  };

  const renderCustomButtons = () => {
    if (isEmpty(customButtons)) return null;

    return customButtons.map(btnConfig => {
      const config = {
        onClick: () => {},
        disabled: true,
        buttonText: '',
        type: 'tertiary',
        ...btnConfig
      };

      return (
        <Button
          key={config.buttonText}
          className={config.className}
          onClick={config.onClick}
          type={config.type}
          disabled={config.isButtonDisabled}
          size="large"
        >
          {config.buttonText}
        </Button>
      );
    });
  };

  const renderFooter = () => {
    return (
      <DialogActions className="generic-modal-footer">
        {renderCustomButtons()}
        {hasResetButton && (
          <TextButton onClick={onReset} size="large">
            Reset
          </TextButton>
        )}
        {(isConfirmModal || hasCancelButton) && (
          <TextButton onClick={onCancel} size="large">
            {cancelText}
          </TextButton>
        )}
        {showPrimaryButton && (
          <Button
            size="large"
            onClick={onClose}
            disabled={isButtonDisabled}
            data-test={submitButtonDataCy}
          >
            {buttonText}
          </Button>
        )}
      </DialogActions>
    );
  };

  const renderSuccessMessage = () => (
    <div className="modal-success-msg">
      <i className="material-icons">check_circle_outline</i>
      <span className="modal-text">{successMessage}</span>
    </div>
  );

  const classes = classNames('generic-modal-wrapper', customClass);

  return (
    <div
      className={classes}
      data-test="generic-modal"
      id={modalId}
      onKeyPress={handleKeyPress}
      style={style}
    >
      {!isEmpty(successMessage) ? renderSuccessMessage() : renderContent()}
    </div>
  );
};

GenericModal.defaultProps = {
  buttonText: 'Done',
  cancelText: 'Cancel',
  children: null,
  customClass: null,
  hasResetButton: false,
  hasCancelButton: false,
  hideFooter: false,
  isButtonDisabled: false,
  isConfirmModal: false,
  modalId: '',
  onCancel: noop,
  onClose: noop,
  onReset: noop,
  showPrimaryButton: true,
  shouldHideTitle: false,
  successMessage: '',
  isNoScrollBackdrop: false,
  customButtons: [],
  submitButtonDataCy: '',
  style: {},
  title: ''
};

GenericModal.propTypes = {
  buttonText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  customButtons: PropTypes.array,
  customClass: PropTypes.string,
  hasCancelButton: PropTypes.bool,
  hasResetButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  isConfirmModal: PropTypes.bool,
  isNoScrollBackdrop: PropTypes.bool,
  modalId: PropTypes.string,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  shouldHideTitle: PropTypes.bool,
  showPrimaryButton: PropTypes.bool,
  submitButtonDataCy: PropTypes.string,
  successMessage: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default GenericModal;
