export default {
  pyConfig: {
    title: 'Matplotlib Chart',
    xlabel: 'steps',
    ylabel: 'values',
    metrics: ['loss']
  },
  pyCode: `# Comet Python Panels BETA, full documentation available at:
# https://www.comet.com/docs/v2/guides/comet-dashboard/code-panels/python-panels/

from comet_ml import API, ui
import matplotlib.pyplot as plt

# Get available metrics
api = API()
metrics = api.get_panel_metrics_names()

# Make chart interactive by adding a dropdown menu
selected_metric = ui.dropdown('Select a metric:', metrics)

# Use API to fetch the metric data for all experiments in the panel scope
experiment_keys = api.get_panel_experiment_keys()
if experiment_keys and selected_metric:
    metrics = api.get_metrics_for_chart(experiment_keys, [selected_metric])

    # Visualize the data
    figure, ax = plt.subplots()
    for experiment_key in metrics:
        for metric in metrics[experiment_key]["metrics"]:
            ax.plot(metric['steps'], metric['values'])

    ui.display(figure)
else:
    ui.display("No data to plot")
`
};
