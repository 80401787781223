import { Box } from '@material-ui/core';
import { InfoIcon } from '@Icons-outdated';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from './InfoIconWithTooltip.module.scss';

export const InfoIconWithTooltip = ({ title, className, ...rest }) => {
  return (
    <Tooltip title={title} arrow placement="top">
      <Box className={className} {...rest}>
        <InfoIcon className={classNames.dsInfoIcon} fontSize="11px" />
      </Box>
    </Tooltip>
  );
};

InfoIconWithTooltip.defaultProps = {
  className: ''
};

InfoIconWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};
