import React from 'react';

import ListItem from '@ds/lists/ListItem/ListItem';
import ExtraOptionItems from './ExtraOptionItems';
import { OptionType, TooltipPlacementType } from '@design-system/types';
import ConditionalTooltip from '@design-system/components/shared/ConditionalTooltip/ConditionalTooltip';
import useMiddleTruncatedLabel from './hooks/useTruncatedLabel';
import ExtraOptionItem from './ExtraOptionItem';

type OptionItemProps = {
  onSelect: (data: OptionType) => void;
  data: OptionType;
  valueOptions: OptionType[];
  isOptionDisabled?: boolean;
  isExtraDisabled?: boolean;
  hideSecondary?: boolean;
  dataTest?: string;
  extraOptionsActionTooltipText?: string;
  truncateMiddle?: boolean;
  maxWidth?: number;
  tooltipPlacement?: TooltipPlacementType;
};
const OptionItem: React.FC<OptionItemProps> = ({
  valueOptions,
  data,
  onSelect,
  isOptionDisabled,
  isExtraDisabled,
  hideSecondary,
  dataTest,
  extraOptionsActionTooltipText = 'Additional Options',
  truncateMiddle = false,
  maxWidth,
  tooltipPlacement = 'left'
}) => {
  const {
    label,
    secondary,
    extraOptions = [],
    prefixIcon,
    value: optionValue,
    tooltipText
  } = data;
  const hasExtraOptions = Boolean(extraOptions.length);
  const isSelected = valueOptions?.some(option => option.value === optionValue);
  const secondaryText = hideSecondary ? undefined : secondary;

  let truncateMaxWidth = maxWidth;
  if (maxWidth && hasExtraOptions) {
    truncateMaxWidth = maxWidth - 30;
  }
  const { isTextTruncated, truncatedValue } = useMiddleTruncatedLabel({
    enabled: truncateMiddle,
    text: label,
    maxWidth: truncateMaxWidth
  });

  let tooltipContent = tooltipText;
  if (isTextTruncated) {
    tooltipContent = label;
  }

  return (
    <ListItem
      active={isSelected}
      disabled={isOptionDisabled}
      onClick={() => onSelect(data)}
      dataTest={dataTest}
    >
      {prefixIcon && (
        <ListItem.PrefixContainer>
          <ListItem.Icon Icon={prefixIcon} />
        </ListItem.PrefixContainer>
      )}
      <ConditionalTooltip
        content={tooltipContent}
        placement={tooltipPlacement}
        wrapperDisplay="block"
      >
        <ListItem.Text primary={truncatedValue} secondary={secondaryText} />
      </ConditionalTooltip>

      {hasExtraOptions && (
        <ExtraOptionItems
          extraOptionsActionTooltipText={extraOptionsActionTooltipText}
          isExtraDisabled={isExtraDisabled}
          label={label}
          maxWidth={maxWidth}
        >
          {extraOptions.map(option => (
            <ExtraOptionItem
              key={option.label}
              label={option.label}
              secondary={option.secondary}
              tooltipText={option.tooltipText}
              isActive={valueOptions?.some(
                valueOption => option.value === valueOption.value
              )}
              isDisabled={
                option.isDisabled &&
                !valueOptions?.some(
                  valueOption => option.value === valueOption.value
                )
              }
              onClick={() => {
                onSelect(option);
              }}
              truncateMiddle={truncateMiddle}
              maxWidth={maxWidth}
              tooltipPlacement={tooltipPlacement}
            />
          ))}
        </ExtraOptionItems>
      )}
    </ListItem>
  );
};

export default OptionItem;
