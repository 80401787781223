import Plotly from 'plotly.js/lib/core';
import Axes from 'plotly.js/src/plots/cartesian/axes.js';
import ViolinPlotHelpers from 'plotly.js/src/traces/violin/helpers.js';
import plotComponentFactory from './plotlyFactory';

import Aggregate from 'plotly.js/lib/aggregate';
import Bar from 'plotly.js/lib/bar';
import Box from 'plotly.js/lib/box';
import Parcoords from 'plotly.js/lib/parcoords';
import Violin from 'plotly.js/lib/violin';

// Paths: https://github.com/plotly/plotly.js/blob/master/lib/index.js
Plotly.register([Bar, Box, Violin, Parcoords, Aggregate]);

const Plot = plotComponentFactory(Plotly);

export { Axes, Plot, Plotly, ViolinPlotHelpers };
