import React, { useState } from 'react';
import cx from 'classnames';

import { TRANSFORM_TYPES_OBJECT } from '@experiment-management-shared/constants/chartConstants';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import typography from '@ds-typography';
import { IconButton, RadioButton, Tooltip, Select, ToggleButton } from '@ds';
import { DSMoreIcon } from '@ds-icons';
import Popover from '@material-ui/core/Popover';

import styles from './AdditionalSettingsButton.module.scss';

type AdditionalSettingsButtonProps = {
  disabled: boolean;
  showLockedPanelsWarning: boolean;
  isAutoRefreshEnabled: boolean;
  onAutoRefreshChange: (event: unknown) => void;
  handleTransformYChange: (event: unknown) => void;
  handleOutliersChange: (value: boolean) => void;
  transformY: string | null;
  showOutliers: boolean;
};
const AdditionalSettingsButton = ({
  disabled,
  showLockedPanelsWarning,
  isAutoRefreshEnabled,
  onAutoRefreshChange,
  handleTransformYChange,
  handleOutliersChange,
  transformY,
  showOutliers
}: AdditionalSettingsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { LOG_VALUE, LOG_BASE_10, LOG_SCALE } = TRANSFORM_TYPES_OBJECT;
  const transformYOptions = [{ value: 'none', label: 'None' }].concat(
    [LOG_VALUE, LOG_BASE_10, LOG_SCALE].map(type =>
      formatValueForSelectOption(type)
    )
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip content="Additional dashboard settings" placement="top">
        <IconButton
          onClick={handleClick}
          type="secondary"
          Icon={<DSMoreIcon />}
          disabled={disabled}
          active={Boolean(anchorEl)}
          data-test="experiment-additional"
        />
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: -6,
          horizontal: 'right'
        }}
        classes={{ paper: styles.popoverContainer }}
      >
        <div className={styles.fieldsContainer}>
          <div className={styles.toggleContainer}>
            <label htmlFor="auto-refresh" className={styles.legendToggle}>
              <ToggleButton
                id="auto-refresh"
                checked={isAutoRefreshEnabled}
                onChange={onAutoRefreshChange}
                color="primary"
              />
              Auto refresh
            </label>
          </div>

          <div>
            <div
              className={cx(
                typography.dsBody,
                typography.dsAccented,
                styles.label
              )}
            >
              Y-axis transformation
            </div>
            <Select
              onValueChange={handleTransformYChange}
              options={transformYOptions}
              value={transformY ?? transformYOptions[0].value}
              isClearable={false}
            />
          </div>

          <div className={cx(typography.dsBody)}>
            <div
              className={cx(
                typography.dsBody,
                typography.dsAccented,
                styles.label
              )}
            >
              Outliers
            </div>
            <div className={styles.radioContainer}>
              <RadioButton
                checked={showOutliers}
                id="show"
                onChange={() => handleOutliersChange(true)}
              />
              <label className={styles.radioLabel} htmlFor="show">
                Show
              </label>
              <RadioButton
                checked={!showOutliers}
                id="ignore"
                onChange={() => handleOutliersChange(false)}
              />

              <label className={styles.radioLabel} htmlFor="ignore">
                Ignore
              </label>
            </div>
          </div>
        </div>

        {showLockedPanelsWarning && (
          <div className={cx(styles.warning, typography.dsBody)}>
            Some panels use the panel level settings. You can unlock a panel to
            use the dashboard one.
          </div>
        )}
      </Popover>
    </>
  );
};

export default AdditionalSettingsButton;
