// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssetThumbnail-module__thumbnail--KmRT4{display:flex;align-items:center;justify-content:center;width:100%;height:100%;overflow:auto}.AssetThumbnail-module__thumbnail--KmRT4 .pointer{cursor:pointer}.AssetThumbnail-module__thumbnail--KmRT4 a{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.AssetThumbnail-module__thumbnail--KmRT4 img{width:100%;height:100%;object-fit:contain}.AssetThumbnail-module__noThumbnail--zHDLb{display:flex;align-items:center;text-align:center;width:88px;height:88px;border:1px solid var(--section-border-color);border-radius:10px;padding:16px;font-size:12px;line-height:18px;color:var(--gray-5);cursor:default}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": `AssetThumbnail-module__thumbnail--KmRT4`,
	"noThumbnail": `AssetThumbnail-module__noThumbnail--zHDLb`
};
module.exports = ___CSS_LOADER_EXPORT___;
