import React from 'react';
import styles from './OtherSingleTableCell.module.scss';
import { TextInput, Tooltip } from '@ds';
import { useOtherSingleTableContext } from '../otherSingleTableContext';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import MetricsTableSubmitActions from '@experiment-details/components/metrics/actions/MetricsTableSubmitActions';

type OtherSingleTableKeyEditCellProps = DataTypeProvider.ValueEditorProps;

const OtherSingleTableKeyEditCell: React.FC<OtherSingleTableKeyEditCellProps> = ({
  column,
  row,
  value,
  onValueChange,
  onBlur,
  onFocus,
  autoFocus
}) => {
  const actionKey = row.editable ? row.name : 'newEntity';
  const {
    onSubmitOtherParam,
    onResetOtherParam
  } = useOtherSingleTableContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onValueChange(e.target.value);

  return (
    <div className={styles.othersTableKeyCell}>
      <MetricsTableSubmitActions
        onSubmit={() => onSubmitOtherParam(actionKey)}
        onCancel={() => onResetOtherParam(actionKey)}
      />
      {row.editable ? (
        <Tooltip content={value} placement="top" truncateOverflow>
          <b>{value}</b>
        </Tooltip>
      ) : (
        <div className={styles.othersTableEditCellContainer}>
          <TextInput
            autoFocus={autoFocus}
            value={value}
            placeholder={column.title}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

export default OtherSingleTableKeyEditCell;
