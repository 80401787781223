import { useMutation } from 'react-query';
import history from '../../history';
import modelRegistryApi from '../../util/modelRegistryApi';
import { WORKSPACE_URL_HASHES } from '../../constants/workspaceConstants';

const useDeleteModelMutation = workspaceName => {
  return useMutation(
    ({ modelName }) => modelRegistryApi.deleteModel(workspaceName, modelName),
    {
      onSuccess: () => {
        history.push(`/${workspaceName}${WORKSPACE_URL_HASHES.MODEL_REGISTRY}`);
      }
    }
  );
};

export default useDeleteModelMutation;
