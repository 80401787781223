import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { useParams } from 'react-router';

import {
  BUILT_IN_CHART_TYPES,
  PANEL_WITH_LEGEND_MODE,
  PANEL_WITH_SAMPLE_SIZES
} from '@experiment-management-shared/constants/chartConstants';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { NEW_VIEW } from '@/constants/dashboardConstants';
import { DISABLE_SHARE } from '@/lib/appConstants';

import SharePanelModal from '../PlotlyChart/SharePanelModal';
import ShareEmbeddedPanelCodeModal from './ShareEmbeddedPanelCodeModal';
import styles from './ChartHeaderMenu.module.scss';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { LEGEND_MODE } from '@experiment-management-shared/constants';

export const SAMPLE_SIZE_KEY = 'sample-size';
export const CHART_EXPORT_KEY = 'export-chart';
export const LEGEND_MODE_KEY = 'legend-mode';
export const MOVE_TO_SECTION_KEY = 'move-to-section';
export const RESET_ZOOM = 'reset-zoom';
export const EMBED_PANEL = 'embed-panel';
export const SAMPLE_SIZE = 'sample-size';
export const SHARE_PANEL = 'share-panel';

const ITEMS_WITH_SUBMENU = [
  SAMPLE_SIZE_KEY,
  CHART_EXPORT_KEY,
  LEGEND_MODE_KEY,
  MOVE_TO_SECTION_KEY
];

const EXPORT_MENU_ITEMS = [
  {
    label: 'JPEG',
    value: 'jpeg'
  },
  {
    label: 'SVG',
    value: 'svg'
  },
  {
    label: 'Data as JSON',
    value: 'json'
  }
];

const LEGEND_MODE_MENU_ITEMS = [
  {
    label: 'Auto',
    value: LEGEND_MODE.AUTO
  },
  {
    label: 'On',
    value: LEGEND_MODE.ON
  },
  {
    label: 'Off',
    value: LEGEND_MODE.OFF
  }
];

const useChartHeaderMenu = ({
  chartId,
  chartType,
  experimentKeys,
  instanceId,
  isCustomPanel,
  legendMode,
  panelMoveConfig,
  projectId,
  templateId,
  sampleSize,
  sampleSizes,
  sectionId,
  hiddenMenuItems,
  disableResetZoom,

  onClearChartConstraints,
  onClose,
  onDeleteChart,
  onEditChart,
  onExportData,
  onResetZoom,
  handleOnExportJson,
  onChangeLegendMode,
  onChangeSampleSize,
  setOpenedSubmenu,
  transformYConfig
}) => {
  const dispatch = useDispatch();
  const { viewId } = useParams();

  const handleClearChartConstraints = useCallback(() => {
    onClearChartConstraints();
    onClose();
  }, [onClose, onClearChartConstraints]);

  const handleDeleteChart = useCallback(() => {
    onDeleteChart();
    onClose();
  }, [onDeleteChart, onClose]);

  const handleEditChart = useCallback(() => {
    onEditChart();
    onClose();
  }, [onEditChart, onClose]);

  const handleExport = useCallback(
    ({ value: format }) => {
      if (format === 'json') {
        handleOnExportJson();
      } else {
        onExportData(format);
      }
      onClose();
      setOpenedSubmenu('');
    },
    [onClose, setOpenedSubmenu, handleOnExportJson, onExportData]
  );

  const handleResetZoom = useCallback(() => {
    onResetZoom();
    onClose();
  }, [onResetZoom, onClose]);

  const handleSampleSize = useCallback(
    ({ value }) => {
      onChangeSampleSize({ chartId, newSampleSize: value });
      onClose();
      setOpenedSubmenu('');
    },
    [onChangeSampleSize, chartId, onClose, setOpenedSubmenu]
  );

  const handleLegendMode = useCallback(
    ({ value }) => {
      if (isFunction(onChangeLegendMode)) {
        onChangeLegendMode({ chartId, legendMode: value });
      }
      onClose();
      setOpenedSubmenu('');
    },
    [onChangeLegendMode, chartId, onClose, setOpenedSubmenu]
  );

  const handleYAxisTransformation = useCallback(() => {
    if (isFunction(transformYConfig?.onChange)) {
      transformYConfig.onChange();
    }
    onClose();
  }, [transformYConfig, onClose]);

  const handleMoveToSection = useCallback(
    ({ value }) => {
      if (isFunction(panelMoveConfig.move)) {
        panelMoveConfig.move(chartId, sectionId, value);
      }
      onClose();
    },
    [panelMoveConfig, onClose, chartId, sectionId]
  );

  const handleShareEmbeddedPanelCode = useCallback(() => {
    const modalProps = {
      chartId,
      experimentKeys,
      instanceId,
      isCustomPanel,
      projectId,
      templateId,
      viewId
    };

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.SHARE_PANEL_MODAL,
        <ShareEmbeddedPanelCodeModal {...modalProps} />
      )
    );

    onClose();
  }, [
    onClose,
    dispatch,
    chartId,
    experimentKeys,
    instanceId,
    isCustomPanel,
    projectId,
    templateId,
    viewId
  ]);

  const handleSharePanel = useCallback(() => {
    const modalProps = {
      chartId,
      instanceId,
      isCustomPanel,
      projectId,
      templateId,
      viewId
    };

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.SHARE_PANEL_MODAL,
        <SharePanelModal {...modalProps} />
      )
    );

    onClose();
  }, [
    chartId,
    instanceId,
    isCustomPanel,
    projectId,
    templateId,
    viewId,
    onClose,
    dispatch
  ]);

  const menuItems = useMemo(() => {
    const sampleSizesLabels = sampleSizes.map(({ value }) =>
      formatValueForSelectOption(value)
    );

    return [
      {
        handler: handleEditChart,
        value: 'edit-chart',
        label: 'Edit'
      },

      {
        condition: () => isFunction(panelMoveConfig?.move),
        value: MOVE_TO_SECTION_KEY,
        label: 'Move to section',
        subMenu: {
          items: panelMoveConfig?.options,
          withInput: false,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          width: '178px',
          dropdownWrapperClassName: styles.subMenu,
          onClick: handleMoveToSection,
          value: sectionId,
          onClose: () => setOpenedSubmenu('')
        }
      },

      {
        value: CHART_EXPORT_KEY,
        label: 'Export',
        subMenu: {
          items: EXPORT_MENU_ITEMS,
          withInput: false,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          width: '120px',
          dropdownWrapperClassName: styles.subMenu,
          onClick: handleExport,
          onClose: () => setOpenedSubmenu('')
        }
      },
      {
        condition: () =>
          chartType === BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates'],
        handler: handleClearChartConstraints,
        value: 'clear-constraints',
        label: 'Clear Constraints'
      },
      {
        condition: () =>
          chartType !== BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates'],
        disabled: disableResetZoom,
        handler: handleResetZoom,
        value: 'reset-zoom',
        label: 'Reset zoom'
      },
      {
        condition: () => Boolean(transformYConfig),
        handler: handleYAxisTransformation,
        value: 'edit-chart',
        label: transformYConfig?.isLog
          ? 'Toggle Y-axis linear scale'
          : 'Toggle Y-axis log scale'
      },
      {
        condition: () =>
          !isEmpty(sampleSizes) && PANEL_WITH_SAMPLE_SIZES.includes(chartType),
        value: SAMPLE_SIZE_KEY,
        label: 'Sample size',
        subMenu: {
          items: sampleSizesLabels,
          withInput: false,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          width: '120px',
          dropdownWrapperClassName: styles.subMenu,
          onClick: handleSampleSize,
          value: sampleSize,
          onClose: () => setOpenedSubmenu('')
        }
      },
      {
        condition: () =>
          isFunction(onChangeLegendMode) &&
          PANEL_WITH_LEGEND_MODE.includes(chartType),
        value: LEGEND_MODE_KEY,
        label: 'Legend display',
        subMenu: {
          items: LEGEND_MODE_MENU_ITEMS,
          withInput: false,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          width: '120px',
          dropdownWrapperClassName: styles.subMenu,
          onClick: handleLegendMode,
          value: legendMode,
          onClose: () => setOpenedSubmenu('')
        }
      },
      {
        condition: () =>
          (isCustomPanel || viewId !== NEW_VIEW) && !DISABLE_SHARE,
        handler: handleSharePanel,
        value: 'share-panel',
        label: 'Share'
      },
      {
        condition: () =>
          (isCustomPanel || viewId !== NEW_VIEW) && !DISABLE_SHARE,
        handler: handleShareEmbeddedPanelCode,
        value: 'embed-panel',
        label: 'Embed'
      },
      {
        handler: handleDeleteChart,
        value: 'delete-chart',
        label: 'Delete'
      }
    ];
  }, [
    sampleSizes,
    handleEditChart,
    panelMoveConfig?.options,
    panelMoveConfig?.move,
    handleMoveToSection,
    sectionId,
    handleExport,
    handleClearChartConstraints,
    disableResetZoom,
    handleResetZoom,
    handleYAxisTransformation,
    transformYConfig,
    handleSampleSize,
    sampleSize,
    handleLegendMode,
    legendMode,
    handleSharePanel,
    handleShareEmbeddedPanelCode,
    handleDeleteChart,
    setOpenedSubmenu,
    chartType,
    onChangeLegendMode,
    isCustomPanel,
    viewId
  ]);

  const filteredMenuItems = useMemo(
    () =>
      menuItems.filter(({ condition, value }) => {
        return !hiddenMenuItems.includes(value) && (!condition || condition());
      }),
    [menuItems, hiddenMenuItems]
  );

  const handleMenuClick = useCallback(
    item => {
      if (ITEMS_WITH_SUBMENU.includes(item.value)) {
        setOpenedSubmenu(item.value);
      } else {
        item.handler();
      }
    },
    [setOpenedSubmenu]
  );

  return [filteredMenuItems, handleMenuClick];
};

export default useChartHeaderMenu;
