import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_CHART_TEMPLATE_NAME } from '@experiment-management-shared/constants/chartConstants';
import get from 'lodash/get';

import visualizationsActions from '@/actions/visualizationsActions';
import dashboardChartsActions from '@/actions/dashboardChartsActions';

export const creatorCustomPanelInstance = projectId => ({
  templateName,
  revisionId,
  templateId
}) => {
  return {
    metadata: {
      isStatic: false
    },
    config: '{}',
    instanceName: templateName,
    order: 0,
    source: 'experiment',
    queryBuilderId: '',
    projectId,
    templateRevisionId: revisionId,
    templateId,
    useDefaultConfig: false,
    userSkippedVersion: revisionId
  };
};

const useSuggestedPanels = ({
  experimentKey,
  projectId,
  handleAddCustomPanel,
  currentTemplate,
  sectionId
}) => {
  const dispatch = useDispatch();
  const viewTemplateName = get(
    currentTemplate,
    'template_name',
    DEFAULT_CHART_TEMPLATE_NAME
  );

  useEffect(() => {
    const isAutoGeneratedView =
      viewTemplateName === DEFAULT_CHART_TEMPLATE_NAME;

    if (isAutoGeneratedView) {
      dispatch(dashboardChartsActions.setActiveSectionId(sectionId));
      dispatch(
        visualizationsActions.fetchPanelSuggestionsForExperiment(experimentKey)
      ).then(templates => {
        const createInstance = creatorCustomPanelInstance(projectId);
        const instancesToCreate = templates.map(createInstance);

        instancesToCreate.forEach(instance => {
          handleAddCustomPanel(instance);
        });
      });
    }
  }, [
    experimentKey,
    dispatch,
    projectId,
    viewTemplateName,
    handleAddCustomPanel,
    sectionId
  ]);
};

export default useSuggestedPanels;
