import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import classes from './VirtualizedList.module.scss';

export const DEFAULT_LIST_HEIGHT = 280;
export const DEFAULT_ROW_HEIGHT = 60;

export type VirtualizedListProps<T> = {
  maxHeight?: number;
  itemList: T[];
  isEmpty?: boolean;
  children: (item: T) => React.ReactNode;
};
const VirtualizedList = <T extends object>({
  maxHeight = DEFAULT_LIST_HEIGHT,
  itemList,
  isEmpty = false,
  children
}: VirtualizedListProps<T>) => {
  const parentRef = React.useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: itemList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => DEFAULT_ROW_HEIGHT
  });
  const items = rowVirtualizer.getVirtualItems();

  if (isEmpty) return null;

  return (
    <div
      ref={parentRef}
      className={classes.virtualizedBodyContainer}
      style={{
        height: maxHeight
      }}
    >
      <div
        className={classes.virtualizedBody}
        style={{
          height: rowVirtualizer.getTotalSize()
        }}
      >
        <div
          className={classes.virtualizedItemList}
          style={{
            transform: `translateY(${items[0]?.start ?? 0}px)`
          }}
        >
          {items.map(virtualRow => (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
            >
              {children(itemList[virtualRow.index] as T)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VirtualizedList;
