import { userActionTypes } from '../constants/actionTypes';

const initialState = {
  academicDetails: {
    academicWorkspace: '',
    userAcademicEmail: '',
    previouslyClaimed: false
  },
  apiKey: '',
  apiKeys: [],
  claimResult: null,
  defaultWorkspaceName: null,
  email: '',
  featureToggles: [],
  getTeams: { teams: [] },
  gitHub: false,
  hasExperiments: true,
  identityProviderUser: false,
  isBeta: false,
  isReadOnly: false,
  isSkipQuickstart: true,
  isUserLoggedIn: true,
  isUserLoggedInWithGitHub: false,
  canClaimAnonData: false,
  loading: false,
  loggedIn: true,
  loginMsg: '',
  optInNotificationsActive: false,
  optInMpmNotificationActive: false,
  paymentPlan: {},
  readOnly: false,
  showPostSignupForm: true,
  suspended: false,
  userName: '',
  username: '',
  isFirstSession: false
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === userActionTypes.REQUEST_USER) {
    return {
      ...state,
      loading: true
    };
  }
  if (type === userActionTypes.RECEIVE_USER) {
    return {
      ...state,
      loading: false,
      ...action.user
    };
  }
  if (type === userActionTypes.SET_USER_PROFILE_IMAGES) {
    return {
      ...state,
      profileImages: action.profileImages
    };
  }
  if (type === userActionTypes.TOGGLE_LOGIN_MODAL) {
    return {
      ...state,
      isLoginModalOpen: action.isOpen,
      modalId: action.uniqueId
    };
  }

  if (type === userActionTypes.RECEIVED_API_KEY) {
    return {
      ...state,
      apiKey: action.apiKey,
      isFetching: false
    };
  }
  if (type === userActionTypes.RECEIVED_OPT_IN_NOTIFICATION) {
    return {
      ...state,
      optInNotificationsActive: action.optInNotificationActive
    };
  }

  if (type === userActionTypes.RECEIVED_OPT_IN_MPM_NOTIFICATION) {
    return {
      ...state,
      optInMpmNotificationActive: action.optInMpmNotificationActive
    };
  }

  if (type === userActionTypes.SET_USER_ACADEMIC_DETAILS) {
    return {
      ...state,
      academicDetails: payload
    };
  }

  if (type === userActionTypes.SET_ORGANIZATION) {
    return {
      ...state,
      organizations: [action.organization]
    };
  }

  if (type === userActionTypes.SET_IS_FIRST_SESSION) {
    return {
      ...state,
      isFirstSession: payload.value
    };
  }

  return state;
};

export default userReducer;

export const getUser = state => state.user;

export const getIsUserLoggedInByAll = state =>
  state.user.isUserLoggedIn || state.user.isUserLoggedInWithGitHub;
export const getIsUserLoggedIn = state => state.user.isUserLoggedIn;
export const getIsUserLoggedInWithGitHub = state =>
  state.user.isUserLoggedInWithGitHub;
export const getUsername = state => state.user.username;
export const getUserEmail = state => state.user.email;
export const getAPIKey = state => state.user.apiKey;
export const getIsBeta = state => state.user.isBeta;
export const getShowPostSignupForm = state => state.user.showPostSignupForm;
export const getProfileImages = state => state.user.profileImages;

export const getUserAcademicDetails = state => state.user.academicDetails;

export const getIsFirstSession = state => state?.user?.isFirstSession;
