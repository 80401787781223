// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestStatus-module__dsRequestStatus--oHGGO{padding:0;font-size:13px !important;font-weight:normal;text-decoration:underline;transition:all .1s linear;display:inline-block}.RequestStatus-module__dsRequestStatus--oHGGO:hover{text-decoration:none;color:var(--primary-color)}.RequestStatus-module__dsRequestStatus--oHGGO.RequestStatus-module__dsStatusSelected--sMRvw{text-decoration:none;border:1px solid;padding:4px 6px;font-weight:500;height:20px;line-height:10px}.RequestStatus-module__dsRequestStatus--oHGGO.RequestStatus-module__dsStatusSelected--sMRvw.RequestStatus-module__dsStatusNoAction--HjxiI{pointer-events:none}.RequestStatus-module__dsRequestStatus--oHGGO.RequestStatus-module__dsStatusNoStatus--vSLHD{border:none !important;background-color:rgba(0,0,0,0) !important;color:#191a1c !important}.RequestStatus-module__dsRequestStatus--oHGGO.RequestStatus-module__dsStatusNoStatus--vSLHD:hover{color:var(--primary-color) !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsRequestStatus": `RequestStatus-module__dsRequestStatus--oHGGO`,
	"dsStatusSelected": `RequestStatus-module__dsStatusSelected--sMRvw`,
	"dsStatusNoAction": `RequestStatus-module__dsStatusNoAction--HjxiI`,
	"dsStatusNoStatus": `RequestStatus-module__dsStatusNoStatus--vSLHD`
};
module.exports = ___CSS_LOADER_EXPORT___;
