import React from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';

import { TextButton, IconButton, Tooltip } from '@ds';
import Divider from '@material-ui/core/Divider';
import { formatBytes } from '@/helpers/generalHelpers';
import {
  isRemoteFile,
  getAssetPreviewConfig
} from '@experiment-management-shared/utils/assetsUtils';

import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import { getDownloadAssetURL } from '@/util/assetsApi';
import { DSCloseIcon, DSDownloadIcon } from '@ds-icons';

const AssetPreviewContainer = ({ closeHandler, experimentKey, file }) => {
  const { assetId, artifactVersionId, fileName, fileSize, link } = file;

  const {
    PreviewComponent,
    Icon: PreviewIcon,
    label: previewLabel,
    optionalProps = {}
  } = getAssetPreviewConfig(file);

  const renderPreviewForType = () => {
    if (isRemoteFile(file)) {
      return <EmptyPreview message="No preview available for remote assets." />;
    }

    const requiredProps = {
      assetId,
      artifactVersionId,
      experimentKey
    };

    return (
      <div className="asset-preview">
        <PreviewComponent {...requiredProps} {...optionalProps} />
      </div>
    );
  };

  const renderOptionalSubheaderItem = () => {
    let label = 'Size';
    let value = formatBytes(fileSize);

    if (isRemoteFile(file) && link) {
      label = 'Remote Link';
      value = (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      );
    }

    return (
      <div className="subheader-item">
        <div className="subheader-label">{label}</div>
        <div className="subheader-value">{value}</div>
      </div>
    );
  };

  const downloadAssetURL = getDownloadAssetURL(
    experimentKey,
    assetId,
    artifactVersionId
  );

  return (
    <Resizable
      className="file-preview-container"
      style={{ position: 'fixed', zIndex: 601 }}
      defaultSize={{
        width: 600,
        height: '100%'
      }}
      maxHeight="100%"
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
    >
      <div className="file-preview-header" title={fileName}>
        {fileName}
      </div>
      <div className="file-preview-subheader">
        <div className="flex">
          <div className="subheader-item">
            <div className="subheader-label">File Type</div>
            <div className="subheader-value">
              <PreviewIcon
                style={{ color: '#191a1c', fontSize: 14, marginRight: 5 }}
              />
              {previewLabel}
            </div>
          </div>

          <Divider style={{ height: 20, width: 1, margin: '0 25px' }} />

          {renderOptionalSubheaderItem()}
        </div>

        <Tooltip content="Download" placement="top">
          <a
            href={downloadAssetURL}
            download={fileName}
            role="button"
            className="file-preview-download-button"
          >
            <IconButton Icon={<DSDownloadIcon />} />
          </a>
        </Tooltip>
      </div>
      <Divider variant="middle" />
      <div className="file-preview-body">{renderPreviewForType()}</div>
      <div className="file-preview-footer">
        <TextButton
          onClick={closeHandler}
          PrefixIcon={<DSCloseIcon />}
          type="secondary"
        >
          Close
        </TextButton>
      </div>
    </Resizable>
  );
};

AssetPreviewContainer.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  experimentKey: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired
};

export default AssetPreviewContainer;
