import React from 'react';
import { Tooltip } from '@ds';
import { MetricCellRendererData } from '../helpers';
import { truncateValue } from '@shared/utils/decimalUtils';
import { useMetricDataContext } from '../hooks/metricDataContext';

type MetricsTableValueCellProps = MetricCellRendererData;

const MetricsTableValueCell: React.FC<MetricsTableValueCellProps> = ({
  value
}) => {
  const { decimalsPrecision } = useMetricDataContext();

  return (
    <Tooltip
      content={truncateValue(value, decimalsPrecision)}
      placement="top"
      truncateOverflow
    >
      {truncateValue(value, decimalsPrecision)}
    </Tooltip>
  );
};

export default MetricsTableValueCell;
