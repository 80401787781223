export const DEFAULT_CUSTOM_TRANSFORMATIONS_SORTING = [
  { columnName: 'name', direction: 'asc' }
];

export const CUSTOM_TRANSFORMATIONS_COLUMNS = [
  {
    name: 'name',
    title: 'METRIC NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'name',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'transformType',
    title: 'METRIC TYPE',
    id: 'transformType--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'transformType',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'definition',
    title: 'METRIC DEFINITION',
    id: 'definition--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'definition',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS = ['actions'];

export const RIGHT_COLUMNS = ['actions'];

export const CUSTOM_TRANSFORMATIONS_TABLE_HEIGHT = 223;

export const SEGMENTS_TABLE_HEIGHT = 223;

export const NAV_ITEMS_VALUES = {
  MODEL_OVERVIEW: 'modelOverview',
  CUSTOM_METRICS: 'customMetrics',
  SEGMENTS: 'segments'
};

export const METRIC_TYPES = {
  NUMERICAL_FEATURE: 'numerical feature',
  CATEGORICAL_FEATURE: 'categorical feature',
  CONFUSION_MATRIX: 'confusion matrix'
};

export const DELETE_METRIC_BODY_TEXT = (name: string) => {
  return `The metric ${name} will be permanently deleted and cannot be recovered.`;
};

export const DELETE_SEGMENT_BODY_TEXT = (name: string) => {
  return `The segment ${name} will be permanently deleted and cannot be recovered.`;
};

export const TOOLTIP_MESSAGE =
  'Data drift will be computed by comparing the baseline distribution to the latest distribution in production';

// Segments
export const SEGMENTS_COLUMNS = [
  {
    name: 'name',
    title: 'SEGMENT NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'segmentValue',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'createdAt',
    title: 'CREATED AT',
    id: 'createdAt--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'createdAt',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'featureBreakdown',
    title: 'FEATURE BREAKDOWN',
    id: 'featureBreakdown--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'segments',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'createdBy',
    title: 'CREATED BY',
    id: 'createdBy--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'createdBy',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'description',
    title: 'DESCRIPTION',
    id: 'description--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'description',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const LIMITED_SEGMENTS_TOOLTIP_MESSAGE =
  'You can create up to 10 segments';

export const MAX_ALLOWED_SEGMENTS = 10;

export const MODAL_TYPE = {
  CREATE: 'create',
  EDIT: 'edit'
};
