import React from 'react';
import { VERSION } from '@/constants/configConstants';
import { Tooltip } from '@ds';
import { DSCopyIcon, DSSuccessIcon } from '@design-system/icons';

import styles from './UserMenuAppVersion.module.scss';
import menuStyles from '../../UserMenu.module.scss';
import { useCopyToClipboard } from '@shared/hooks';

const UserMenuAppVersion = () => {
  const { copied, onCopy } = useCopyToClipboard(VERSION);

  const tooltipText = copied
    ? 'Version was copied '
    : 'Click to copy version number';

  if (!VERSION) return null;

  return (
    <>
      <div className={menuStyles.divider} />
      <Tooltip content={tooltipText} wrapperDisplay="block">
        <div onClick={onCopy} className={styles.userMenuVersion}>
          <span>Version {VERSION}</span>
          {copied ? (
            <DSSuccessIcon className={styles.successIcon} />
          ) : (
            <DSCopyIcon />
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default UserMenuAppVersion;
