import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { Table } from '@devexpress/dx-react-grid-material-ui';
import ReactPlaceholder from 'react-placeholder';
import { TextRow } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

import { CENTERED_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import Cell from '@experiment-management-shared/components/TableCells';

const TableCellTemplate = props => {
  const { className, column, row } = props;

  const isReady = () => {
    const hasMetadata = !isEmpty(row);
    const isArchivedExperiment = row.archived;

    return hasMetadata || isArchivedExperiment;
  };

  const {
    onTogglePinned,
    pinnedExperimentKeys,
    cellsMap,
    decimalsPrecision,
    openLinksInNewTab,
    onToggleVisibility,
    onHoverExperimentNameCell,
    onClickRowActionMenu,
    fixedColumnTitle,
    columns,
    ...cellProps
  } = props;

  const placeholder = (
    <TextRow color="#E0E0E0" style={{ marginTop: 0, borderRadius: '30px' }} />
  );

  return (
    <Table.Cell
      {...cellProps}
      className={classnames(
        className,
        column.name,
        'table-cell',
        'default-table-cell',
        {
          'text-center': CENTERED_COLUMNS.includes(column.name)
        }
      )}
    >
      <ReactPlaceholder
        customPlaceholder={placeholder}
        type="text"
        rows={1}
        ready={isReady()}
      >
        <Cell cellsMap={cellsMap} {...props} />
      </ReactPlaceholder>
    </Table.Cell>
  );
};

TableCellTemplate.propTypes = {
  className: PropTypes.string,
  column: PropTypes.object.isRequired,
  columns: PropTypes.array,
  cellsMap: PropTypes.object,
  decimalsPrecision: PropTypes.number,
  row: PropTypes.object.isRequired,
  openLinksInNewTab: PropTypes.bool,
  onHoverExperimentNameCell: PropTypes.func,
  onClickRowActionMenu: PropTypes.func,
  onToggleVisibility: PropTypes.func,
  fixedColumnTitle: PropTypes.string,
  columnsActions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      action: PropTypes.func
    })
  ),
  onTogglePinned: PropTypes.func,
  pinnedExperimentKeys: PropTypes.arrayOf(PropTypes.string)
};

TableCellTemplate.defaultProps = {
  columns: [],
  cellsMap: undefined,
  columnsActions: [],
  className: '',
  onClickRowActionMenu: noop,
  onHoverExperimentNameCell: noop,
  openLinksInNewTab: false,
  decimalsPrecision: null,
  fixedColumnTitle: null
};

export default TableCellTemplate;
