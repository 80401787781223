import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const XLabelCell = ({
  cellValue,
  cellSize,
  cellFontSize,
  mousePosition,
  xCoordinate,
  rotateLabels
}) => {
  const activeRowCell = mousePosition?.x === xCoordinate;

  return (
    <div
      className={cx('confusion-matrix-chart-xlabel-cell', {
        'confusion-matrix-chart-xlabel-cell-activeCell': activeRowCell
      })}
      style={{
        fontSize: cellFontSize,
        width: cellSize
      }}
    >
      <span
        className={cx('confusion-matrix-chart-xlabel-cell-content', {
          'confusion-matrix-chart-xlabel-cell-content-rotated': rotateLabels
        })}
      >
        {cellValue}
      </span>
    </div>
  );
};

XLabelCell.defaultProps = {
  mousePosition: null
};

XLabelCell.propTypes = {
  cellValue: (PropTypes.string || PropTypes.number).isRequired,
  mousePosition: PropTypes.object || null,
  cellSize: PropTypes.number.isRequired,
  cellFontSize: PropTypes.number.isRequired,
  xCoordinate: PropTypes.number.isRequired,
  rotateLabels: PropTypes.bool.isRequired
};
