import { useQuery } from 'react-query';

import api from '@API/api';
import { QueryConfig } from '@shared/api';
import { useParams } from 'react-router-dom';

type Metric = {
  metricId: string;
  name: string;
  cometSql: string;
};

type CustomMetricsResponse = { data: Metric[] };

const getCustomMetrics = (modelId: string) =>
  api.get(`/mpm/v2/custom-metrics?modelId=${modelId}`);

export function useCustomMetrics(config: QueryConfig<CustomMetricsResponse>) {
  const { modelId } = useParams<{ modelId: string }>();

  return useQuery(
    ['custom-metrics', { modelId }],
    () => getCustomMetrics(modelId),
    {
      ...config
    }
  );
}
