import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import workspaceActions from '@/actions/workspaceActions';
import { getIsQuickStartPage, getWorkspaceName } from '@shared/utils/url';
import { BreadcrumbItemProps } from '@shared/types';

function useBreadcrumbsDataSource() {
  const dispatch = useDispatch();

  const setItems = useCallback(
    (items: BreadcrumbItemProps[]) => {
      dispatch(workspaceActions.setBreadcrumbsItems(items));
    },
    [dispatch]
  );

  const resetItems = useCallback(() => {
    // we need to clean breadcrumbs in case we don't have workspace or in case it Quick Start page
    if (getIsQuickStartPage() || !getWorkspaceName()) {
      dispatch(workspaceActions.setBreadcrumbsItems([]));
    }
  }, [dispatch]);

  return {
    setItems,
    resetItems
  };
}

export default useBreadcrumbsDataSource;
