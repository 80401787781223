import { SystemMetrics } from '@shared/types/systemMetrics';
import { prepareMetricsTableRow } from '../../utils';

type useSystemMetricsSingleTableDataOpts = {
  systemMetrics: SystemMetrics;
};
const useSystemMetricsSingleTableData = ({
  systemMetrics
}: useSystemMetricsSingleTableDataOpts) => {
  return {
    rowList: prepareMetricsTableRow(systemMetrics)
  };
};

export default useSystemMetricsSingleTableData;
