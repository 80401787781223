// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Snackbar-module__snackbar--Y581x{right:100px;top:10px}.Snackbar-module__snackbar--Y581x .MuiSnackbarContent-root{border-left:7px solid #00cb9c;position:fixed;right:100px;top:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": `Snackbar-module__snackbar--Y581x`
};
module.exports = ___CSS_LOADER_EXPORT___;
