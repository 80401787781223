import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import invert from 'invert-color';
import Tooltip from 'rc-tooltip';

import { MatrixCell } from './MatrixCell';
import { YLabelCell } from './YLabelCell';
import { XLabelCell } from './XLabelCell';
import './ConfusionMatrix.scss';

export const MatrixGrid = ({
  matrix,
  ranges,
  labels,
  highlightPositions,
  onCellHoverChange,
  onCellClick,
  cellStyles,
  isPercentage,
  tooltipConfig
}) => {
  const [mousePosition, setMousePosition] = useState(null);
  const { customCellSize, cutomCellFontSize } = cellStyles;
  const {
    visibleTooltip = false,
    TooltipOverlayComponent,
    tooltipOverlayProps,
    tooltipClassname
  } = tooltipConfig;

  useEffect(() => {
    onCellHoverChange(mousePosition);
  }, [mousePosition, onCellHoverChange]);

  const numberOfRows = labels.length;

  const cellSize = useMemo(() => {
    if (customCellSize) return customCellSize;
    if (numberOfRows <= 12) return 60;
    if (numberOfRows >= 19) return 42.5;
    return 42;
  }, [numberOfRows, customCellSize]);

  const cellFontSize = useMemo(() => {
    if (cutomCellFontSize) return cutomCellFontSize;
    if (numberOfRows >= 19) return 12;
    return 15;
  }, [numberOfRows, cutomCellFontSize]);

  const maxLabelLength = useMemo(() => {
    if (numberOfRows <= 12) return 6;
    if (numberOfRows <= 18) return 4;
    return 2;
  }, [numberOfRows]);

  // If there is a label with more than N characters, show the vertical mode
  const showVerticalColumnLabels = labels.some(
    label => label.length > maxLabelLength
  );

  const getBackgroundColorByValue = value => {
    for (const range of ranges) {
      const { color, maxValue } = range;

      if (value < maxValue) return color;
    }

    return ranges[ranges.length - 1].color;
  };

  const grid = () =>
    matrix.map((row, y) => {
      const yLabelCell = (
        <YLabelCell
          key={row}
          cellValue={labels[y]}
          cellSize={cellSize}
          cellFontSize={cellFontSize}
          mousePosition={mousePosition}
          yCoordinate={y}
        />
      );
      let rowList = row.map((cellValue, x) => {
        const backgroundColor = getBackgroundColorByValue(cellValue);
        const color = invert(backgroundColor, { threshold: 0.3 });
        const key = `confusion-matrix-cell-${x}-${y}`;

        const isHighlighted = highlightPositions.some(position => {
          return position.row === y && position.col === x;
        });

        const toggleExamples = () => onCellClick(x, y);

        const isHovered =
          mousePosition && mousePosition?.x === x && mousePosition?.y === y;
        const overlay = (
          <TooltipOverlayComponent
            {...tooltipOverlayProps}
            matrix={matrix}
            labels={labels}
            x={x}
            y={y}
            isHighlighted={isHighlighted}
          />
        );

        return (
          <Tooltip
            destroyTooltipOnHide
            key={key}
            mouseLeaveDelay={0}
            visible={!!visibleTooltip && isHovered}
            overlayClassName={tooltipClassname}
            overlay={overlay}
            placement="right"
            trigger={['hover']}
          >
            <MatrixCell
              key={key}
              color={color}
              backgroundColor={backgroundColor}
              cellValue={cellValue}
              isPercentage={isPercentage}
              clickCellHandler={toggleExamples}
              setMousePosition={setMousePosition}
              isHighlighted={isHighlighted}
              cellFontSize={cellFontSize}
              cellSize={cellSize}
              cellCoordinates={{ x, y }}
            />
          </Tooltip>
        );
      });
      rowList = [yLabelCell].concat(rowList);
      const key = `confusion-matrix-row-${y}`;

      return (
        <div key={key} className="confusion-matrix-chart-row">
          {rowList}
        </div>
      );
    });
  const xLabelsRow = () => (
    <div
      key="confusion-matrix-row-xLabels"
      className="confusion-matrix-chart-row"
    >
      {labels.map((label, index) => (
        <XLabelCell
          key={label}
          cellValue={label}
          cellSize={cellSize}
          cellFontSize={cellFontSize}
          mousePosition={mousePosition}
          xCoordinate={index}
          rotateLabels={showVerticalColumnLabels}
        />
      ))}
    </div>
  );
  return (
    <div className="confusion-matrix-grid-wrapper">
      {grid()}
      {xLabelsRow()}
    </div>
  );
};

MatrixGrid.defaultProps = {
  isPercentage: false,
  highlightPositions: [],
  onCellHoverChange: noop,
  onCellClick: noop,
  cellStyles: {},
  tooltipConfig: {}
};

MatrixGrid.propTypes = {
  matrix: PropTypes.array.isRequired,
  ranges: PropTypes.array.isRequired,
  highlightPositions: PropTypes.array,
  labels: PropTypes.array.isRequired,
  isPercentage: PropTypes.bool,
  onCellHoverChange: PropTypes.func,
  onCellClick: PropTypes.func,
  cellStyles: PropTypes.object,
  tooltipConfig: PropTypes.object
};
