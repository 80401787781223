import React from 'react';
import { ClearIndicatorProps, components } from 'react-select';
import { OptionType } from '../../../../types';
import { DSCloseIcon } from '@ds-icons';
import classes from '../Select.module.scss';

const ClearIndicator = (props: ClearIndicatorProps<OptionType>) => {
  return (
    <components.ClearIndicator {...props}>
      <div className={classes.closeIconContainer}>
        <DSCloseIcon />
      </div>
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
