import React from 'react';

import Select, { ActionMeta, GroupBase, MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import cx from 'classnames';
import classes from './Select.module.scss';
import typography from '@ds-typography';
import {
  Option,
  DropdownIndicator,
  MenuList,
  GroupHeading,
  Group,
  Control,
  ClearIndicator,
  MultiValueRemove,
  SelectContainer,
  MultiValueContainer,
  MultiValueLabel,
  NoOptionsMessage,
  Placeholder
} from './components';
import { OptionType } from '../../../types';

import noop from 'lodash/noop';
import { CreatableSelectProps, MultipleSelectProps } from './types';
import { createOptionFilter, getSelectValues, valueToList } from './utils';

export type ComboboxProps = Omit<
  MultipleSelectProps & CreatableSelectProps,
  'onChange' | 'options' | 'value'
> & {
  options?: OptionType[];
  creatable?: boolean;
  value?: OptionType['value'] | OptionType['value'][];
  onValueChange?: (
    values: OptionType['value'][],
    valuesList: MultiValue<OptionType>,
    meta?: ActionMeta<OptionType>
  ) => void;
  maxSelectedLimit?: number;
  'data-test'?: string;
};

const Combobox = ({
  options = [],
  footerActionName = '',
  onFooterActionClick = noop,
  FooterActionIcon = null,
  PrefixIcon = null,
  invalid = false,
  helperText = '',
  size = 'default',
  creatable = false,
  closeMenuOnSelect = false,
  components,
  value = [],
  fixedOptionValues = [],
  onValueChange = noop,
  maxSelectedLimit = Infinity,
  maxHeight,
  maxMenuHeight,
  isClearable,
  tooltipPlacement = 'top',
  isRegexSearchEnabled,
  ...restProps
}: ComboboxProps) => {
  const valueList = valueToList(value);
  const Component = creatable ? CreatableSelect : Select;
  const isWithFooter = footerActionName || FooterActionIcon;

  const selectedValues = getSelectValues(
    [...fixedOptionValues, ...valueList],
    options
  );

  const handleChange = (
    newValues: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const values = newValues.map(item => item.value);
    const newOptions = getSelectValues(
      [...fixedOptionValues, ...values],
      [...newValues]
    );

    const valuesList = newOptions.map(v => v.value);
    onValueChange(valuesList, newOptions, actionMeta);
  };

  const selectedValuesLength = selectedValues.length;
  const selectionLimitReached = selectedValuesLength >= maxSelectedLimit;
  const hasEditableValue = Boolean(
    valueList?.filter(v => !fixedOptionValues.includes(v)).length
  );

  const isComboboxClearable = hasEditableValue ? isClearable : false;

  const filterOption = createOptionFilter(Boolean(isRegexSearchEnabled));

  return (
    <>
      <Component<OptionType, true, GroupBase<OptionType>>
        options={options}
        components={{
          Option,
          DropdownIndicator,
          MenuList,
          GroupHeading,
          Group,
          Control,
          ClearIndicator,
          MultiValueContainer,
          MultiValueRemove,
          MultiValueLabel,
          NoOptionsMessage,
          SelectContainer,
          Placeholder,
          ...components
        }}
        classNames={{
          control: state =>
            cx(
              classes.controlContainer,
              typography.dsBody,
              classes[size],
              classes.multiControl,
              {
                [classes.open]: state.menuIsOpen,
                [classes.disabledControl]: state.isDisabled,
                [classes.invalidControl]: invalid,
                [classes.noValueControl]: !state.hasValue
              }
            ),
          placeholder: () => classes.placeholder,
          indicatorSeparator: () => classes.decoratorSeparator,
          dropdownIndicator: state =>
            cx(classes.dropdownIndicator, {
              [classes.disabledDropdownIndicator]: state.isDisabled
            }),
          menuList: () =>
            cx(classes.comboboxMenuList, {
              [classes.menuWithoutBottomPadding]: isWithFooter
            }),
          multiValue: data =>
            cx(classes.multiValueContainer, classes[size], {
              [classes.invalidMultiValue]: data.data.isInvalid,
              [classes.isFixed]: data.selectProps.fixedOptionValues?.includes(
                data.data.value
              ),
              [classes.disabledMultiValue]:
                data.data.isDisabled || data.selectProps.isDisabled
            }),
          multiValueLabel: () => classes.multiValueLabel,
          indicatorsContainer: () => classes.multiIndicatorsContainer,
          noOptionsMessage: () => classes.noOptionsMessage,
          menuPortal: () => classes.selectMenuPortal
        }}
        unstyled
        PrefixIcon={PrefixIcon}
        footerActionName={footerActionName}
        FooterActionIcon={FooterActionIcon}
        onFooterActionClick={onFooterActionClick}
        invalid={invalid}
        closeMenuOnSelect={
          closeMenuOnSelect || selectedValuesLength >= maxSelectedLimit - 1
        }
        hideSelectedOptions={false}
        captureMenuScroll={!maxHeight}
        menuPortalTarget={document.body}
        tooltipPlacement={tooltipPlacement}
        {...restProps}
        maxMenuHeight={maxMenuHeight || maxHeight}
        filterOption={filterOption}
        isClearable={isComboboxClearable}
        maxHeight={maxHeight}
        isOptionDisabled={() => selectionLimitReached}
        isSearchable={!selectionLimitReached}
        selectionLimitReached={selectionLimitReached}
        fixedOptionValues={fixedOptionValues}
        onChange={handleChange}
        value={selectedValues}
        menuPosition="fixed"
        isMulti
      />
      {helperText && (
        <div
          className={cx(typography.dsBodySmall, classes.helperTextContainer, {
            [classes.invalidHelperText]: invalid
          })}
        >
          {helperText}
        </div>
      )}
    </>
  );
};

export default Combobox;
