import { RESOURCE_TYPES } from '@experiment-management-shared/constants/visualizationConstants';
import randomstring from 'randomstring';
import getSrcDocHtml from './srcDocHtml';
import getSrcDocCss from './srcDocCss';
import getSrcDocJs from './srcDocJs';

import {
  IS_ON_PREMISE,
  JAVASCRIPT_SDK_VERSION
} from '@/constants/configConstants';

const DOM_TO_IMAGE_URL = IS_ON_PREMISE
  ? '/js/2.6.0-dom-to-image.min.js'
  : 'https://cdnjs.cloudflare.com/ajax/libs/dom-to-image/2.6.0/dom-to-image.min.js';

const { JAVASCRIPT, CSS } = RESOURCE_TYPES;
const HTML_CONFIG = {
  headTitle: 'Comet.ml Chart Builder',
  requiredJavascript: [DOM_TO_IMAGE_URL]
};

const createSrcDocWithUserCode = ({
  colorMap = {},
  experimentKeys = [],
  experimentNames = {},
  options = null,
  projectToken,
  showConsole = false,
  userCode,
  resources,
  instanceId,
  projectId,
  isPy,
  pyConfig,
  apiKey
}) => {
  const { code, html, css, pyCode } = userCode;
  const SRC_DOC_VERSION = randomstring.generate(3);
  const COMBINED_HTML = getSrcDocHtml(html, showConsole);
  const COMBINED_CSS = getSrcDocCss(css, !isPy);
  const COMBINED_JS = getSrcDocJs({
    code: isPy ? pyCode : code,
    colorMap,
    experimentKeys,
    experimentNames,
    options,
    projectToken,
    showConsole,
    instanceId,
    projectId,
    isPy,
    pyConfig,
    apiKey
  });

  const getLinkTags = (isOnlyRequired = false) => {
    const cssResourceUrls = !isOnlyRequired
      ? resources
          .filter(resource => {
            return resource.type === CSS.value;
          })
          .map(resource => resource.url)
      : [];

    const urls = [...cssResourceUrls, HTML_CONFIG.requiredCss];
    return urls.reduce((result, url) => {
      return (result += `<link href="${url}"
      rel="stylesheet"></link>\n`);
    }, '');
  };

  const getScriptTags = (isOnlyRequired = false) => {
    const javascriptResourceUrls = !isOnlyRequired
      ? resources
          .filter(resource => {
            return !resource.type || resource.type === JAVASCRIPT.value;
          })
          .map(resource => resource.url)
      : [];

    const urls = [...javascriptResourceUrls, ...HTML_CONFIG.requiredJavascript];
    return urls.reduce((result, url) => {
      return (result += `<script src="${url}"></script>\n`);
    }, '');
  };

  const HEAD = `
    <head>
      <meta charset="UTF-8">
      <meta name="javascript-sdk-version" content="${JAVASCRIPT_SDK_VERSION}">
      <meta name="source-version" content="${SRC_DOC_VERSION}">
      <title>${HTML_CONFIG.headTitle}</title>
      ${getLinkTags(isPy)}
      ${getScriptTags(isPy)}
      ${COMBINED_CSS}
    </head>
  `;

  const BODY = `<body>${COMBINED_HTML}${COMBINED_JS}</body>`;

  return `
<!-- ${isPy ? 'PY' : 'JS'} -->

<!DOCTYPE html><html>${HEAD}${BODY}</html>`;
};

export default createSrcDocWithUserCode;
