import React from 'react';
import PropTypes from 'prop-types';

import TableEmptyState from '@shared/components/TableEmptyState';

const ModelRegistryWebhooksEmpty = ({ materialProps, origin, dataTest }) => (
  <TableEmptyState
    dataTest={`${dataTest}-empty-state`}
    label="No webhooks created yet"
    materialProps={materialProps}
    pictureHeight="54px"
    pictureWidth="57px"
    picturePath="/images/model-registry/no-webhooks-icon.svg"
    additionalText={
      <div>
        Webhooks send notifications to external services when certain events{' '}
        <br />
        in the model registry happen. Learn more at{' '}
        <a
          href={`${origin}/docs/v2/guides/model-management/webhooks/#configure-the-webhook`}
          target="_blank"
          rel="noreferrer"
        >
          our documentation.
        </a>
      </div>
    }
  />
);

ModelRegistryWebhooksEmpty.defaultProps = {};

ModelRegistryWebhooksEmpty.propTypes = {
  materialProps: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired,
  dataTest: PropTypes.string.isRequired
};

export default ModelRegistryWebhooksEmpty;
