// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-syntax-highlighter{background:var(--gray-1) !important;border-radius:5px;padding:12px 16px !important}.ds-syntax-highlighter code{line-height:24px;color:var(--black)}.ds-syntax-highlighter .react-syntax-highlighter-line-number{color:var(--gray-4)}.ds-syntax-highlighter.full-width{width:100%}.SyntaxHighlighter-module__lineNumberContainerStyle--u3ulP{background-color:#fff;border-right:1px solid #eee;color:var(--gray-4);float:left;margin-right:10px;padding-right:10px;text-align:right}.SyntaxHighlighter-module__syntaxHighlighterWrapper--hT61z{position:relative;width:100%}.SyntaxHighlighter-module__syntaxHighlighterWrapper--hT61z.SyntaxHighlighter-module__secondary--n5LL9 .hljs{padding:16px 32px 16px 16px !important;background-color:var(--gray-1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineNumberContainerStyle": `SyntaxHighlighter-module__lineNumberContainerStyle--u3ulP`,
	"syntaxHighlighterWrapper": `SyntaxHighlighter-module__syntaxHighlighterWrapper--hT61z`,
	"secondary": `SyntaxHighlighter-module__secondary--n5LL9`
};
module.exports = ___CSS_LOADER_EXPORT___;
