import { organizationActionTypes } from '../constants/actionTypes';

const organizationsActions = {
  setActiveOrganization(organizationId) {
    return dispatch => {
      dispatch({
        type: organizationActionTypes.SET_ACTIVE,
        payload: { activeOrganizationId: organizationId }
      });
    };
  },

  setOrganizations(organizations) {
    return dispatch => {
      dispatch({
        type: organizationActionTypes.SET_ORGANIZATION_LIST,
        payload: { organizations }
      });
    };
  }
};

export default organizationsActions;
