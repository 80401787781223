import { axiosInstance } from '@API/api';
import configureStore from '@app/configureStore';
import { setupInterceptors } from '@app/network';
import api from '@shared/api';
import axios from 'axios';
import userActions from '../actions/userActions';
import {
  IS_PRODUCTION_ENV,
  IS_STAGING_ENV
} from '../constants/configConstants';
import history from '../history';

export const store = configureStore({}, history);

if (!IS_STAGING_ENV && !IS_PRODUCTION_ENV) {
  window.store = store;
  window.state = store.getState;
}

// @deprecated
setupInterceptors(store, axios);
// @deprecated
setupInterceptors(store, axiosInstance);
setupInterceptors(store, api);

// Get the party started. (App initialization)
export default store.dispatch(userActions.fetchUser()).then(() => {
  if (store.getState().user.isUserLoggedIn) {
    axios.defaults.withCredentials = true;
  }
});
