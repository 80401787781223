import React from 'react';

import classNames from './GraphicsTile.module.scss';
import { isUndefined } from 'lodash';
import {
  ImageAssetData,
  VideoAssetData
} from '@experiment-management-shared/types';

type GraphicsTileFooterProps = {
  asset: VideoAssetData | ImageAssetData;
};
const GraphicsTileFooter: React.FC<GraphicsTileFooterProps> = ({ asset }) => {
  const { step, name: assetName } = asset;
  return (
    <p
      className={classNames.step}
      data-test={`graphics-tile-step-${assetName}`}
    >
      {!isUndefined(step) && `Step ${step}`}
    </p>
  );
};

export default GraphicsTileFooter;
