import { useMutation, useQueryClient } from 'react-query';

import api from '@API/api';

import useProject from '@API/project/useProject';

const upsertQuerySegment = ({ rulesTree, name, projectId, filterId }) => {
  return api.put(`query/filters/${filterId}`, {
    name,
    rulesTree,
    projectId,
    filterId
  });
};

export default function useUpdateFilterMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ rulesTree, name, filterId }) =>
      upsertQuerySegment({ rulesTree, name, projectId, filterId }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['filters', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['filters', { projectId }]);
      }
    }
  );
}
