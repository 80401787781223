import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import noop from 'lodash/noop';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';

import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  formatDate,
  formatFileSize
} from '@experiment-management-shared/utils/filesTreeUtils';
import useResponsiveWidth from '../utils/useResponsiveWidth';
import { getDownloadAssetURL } from '@/util/assetsApi';
import { DIR_TYPES } from '@experiment-management-shared/constants/fileBrowser';

import TruncatedCell from './TruncatedCell';
import { Tooltip } from '@ds';
import AssetFileActions from './AssetFileActions';

const FileRow = ({
  experimentKey,
  file,
  handleOnClick,
  handleOnHover,
  isCurrent
}) => {
  const {
    assetId,
    createdAt,
    dir,
    fileName,
    fileSize,
    level,
    link,
    remote,
    runContext,
    step
  } = file;

  const downloadAssetURL = getDownloadAssetURL(experimentKey, assetId);

  const style = {
    paddingLeft: level * 30 + 16,
    paddingRight: 5
  };

  const classes = classnames('files-browser-file-row', {
    'is-current-row': isCurrent
  });
  const respWidth = useResponsiveWidth();

  const renderFileName = () => {
    return (
      <Box className="files-system-row-name-wrapper">
        <span className="files-system-row-name-icon">
          <DescriptionIcon />
        </span>
        <Tooltip content={fileName} placement="top">
          <span
            className={classnames(
              'truncated-string',
              'files-system-row-name-text',
              'name'
            )}
          >
            {fileName}
          </span>
        </Tooltip>
      </Box>
    );
  };

  const handleRowClick = () => {
    handleOnClick(file);
  };

  return (
    <div
      onClick={handleRowClick}
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => {
        handleOnHover(file);
      }}
    >
      <Box
        component={Grid}
        style={{ pointerEvents: 'none' }}
        key={fileName}
        container
        className={classnames(classes, 'files-system-file-row-name')}
      >
        <Grid
          item
          xs={5}
          className={classnames('files-system-file-name', respWidth.name)}
        >
          <div style={style}>{renderFileName()}</div>
        </Grid>
        <Grid item xs={1} className={respWidth.location}>
          <span>{remote ? 'Remote' : 'Local'}</span>
        </Grid>
        <TruncatedCell xs={1}>
          <span>{remote ? '' : formatFileSize(fileSize)}</span>
        </TruncatedCell>
        <TruncatedCell xs={2}>{formatDate(createdAt)}</TruncatedCell>
        <TruncatedCell xs={1}>{step}</TruncatedCell>
        <TruncatedCell xs={1}>{runContext}</TruncatedCell>
        <Grid item xs={1} onClick={e => e.stopPropagation()}>
          <AssetFileActions
            downloadAssetURL={downloadAssetURL}
            link={link}
            isRemote={remote}
            isEmbedding={dir === DIR_TYPES.EMBEDDINGS}
            assetId={assetId}
            experimentKey={experimentKey}
            fileName={fileName}
          />
        </Grid>
      </Box>
    </div>
  );
};

FileRow.defaultProps = {
  handleOnClick: noop,
  handleOnHover: noop,
  viewClickHandler: noop,
  experimentKey: ''
};

FileRow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  experimentKey: PropTypes.string,
  file: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func,
  handleOnHover: PropTypes.func,
  isCurrent: PropTypes.bool.isRequired,
  viewClickHandler: PropTypes.func
};

export default connect()(FileRow);
