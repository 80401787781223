import { InputLabel } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useColumns from '@API/project/useColumns';
import { DEFAULT_LEGEND_KEYS } from '@experiment-management-shared/constants/chartConstants';
import { formatColumnsToSelectOptions } from '@shared/utils/selectComponentHelper';

import { Combobox } from '@ds';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { DASHBOARD_PREVIEW_CHART_ID } from '@/lib/appConstants';
import { getSelectedLegendKeys } from '@/reducers/dashboardChartsReducer';
import PropTypes from 'prop-types';

const MAX_SELECTED_OPTIONS = 3;

function TooltipKeys({ chartType, disabled }) {
  const { data: columns } = useColumns();
  const dispatch = useDispatch();

  const options = useMemo(() => {
    return formatColumnsToSelectOptions(columns);
  }, [columns]);

  const selectedLegendKeys = useSelector(state =>
    getSelectedLegendKeys(state, {
      chartType,
      chartId: DASHBOARD_PREVIEW_CHART_ID
    })
  );

  const fixedValues = (disabled ? selectedLegendKeys : DEFAULT_LEGEND_KEYS).map(
    selectedLegendKey => selectedLegendKey.value
  );

  const handleSelect = (formKey, selectedOption) => {
    const value = isEmpty(selectedOption)
      ? DEFAULT_LEGEND_KEYS
      : selectedOption;

    dispatch(
      dashboardChartsActions.updateChartFormKey(chartType, formKey, value)
    );
  };

  const renderLegendKeysSelect = () => {
    const numSelected = selectedLegendKeys.length;
    const selectValue = selectedLegendKeys.map(({ value }) => value);

    return (
      <div className="modal-input-group">
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
        >
          <InputLabel className="modal-input-label" style={{ margin: 0 }}>
            Legend Keys
          </InputLabel>
          <span className="circle-divider" />
          <div style={{ fontSize: 14, fontWeight: 500 }}>
            {numSelected}/
            <span style={{ color: '#5155f5', marginLeft: 1 }}>
              {MAX_SELECTED_OPTIONS}
            </span>
          </div>
        </div>

        <div style={{ width: '400px' }}>
          <Combobox
            maxSelectedLimit={MAX_SELECTED_OPTIONS}
            isDisabled={disabled}
            value={selectValue}
            maxWidth={400}
            truncateMiddle
            onValueChange={(_, fullSelectedOptions) => {
              handleSelect('selectedLegendKeys', fullSelectedOptions);
            }}
            options={options}
            fixedOptionValues={fixedValues}
            placeholder="placeholder"
            maxHeight={280}
            isRegexSearchEnabled
            searchPlaceholder="Search (regex)"
          />
        </div>
      </div>
    );
  };

  return <div>{renderLegendKeysSelect()}</div>;
}

TooltipKeys.defaultProps = {
  disabled: false
};

TooltipKeys.propTypes = {
  chartType: PropTypes.string,
  disabled: PropTypes.bool
};

export default TooltipKeys;
