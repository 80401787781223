// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfusionMatricesContainer-module__mainContainer--mn23F{--legent-container-width: 94px;position:relative;padding:16px var(--legent-container-width) 54px var(--experiment-details-layout-padding)}.ConfusionMatricesContainer-module__mainContainer--mn23F .ConfusionMatricesContainer-module__matricesContainer--t3zSW{display:flex;flex-direction:row;align-items:flex-start;overflow-x:auto;gap:34px}.ConfusionMatricesContainer-module__mainContainer--mn23F .ConfusionMatricesContainer-module__legendContainer--OEmvU{position:absolute;top:0;right:0;bottom:0;width:var(--legent-container-width);min-height:500px;padding:73px 0 0 24px}.ConfusionMatricesContainer-module__mainContainer--mn23F .ConfusionMatricesContainer-module__legendContainer--OEmvU .ConfusionMatricesContainer-module__legendWrapper--X01im{height:400px}.ConfusionMatricesContainer-module__mainContainer--mn23F .ConfusionMatricesContainer-module__legendContainer--OEmvU .ConfusionMatricesContainer-module__legendWrapper--X01im .confusion-matrix-chart-colorbar{padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `ConfusionMatricesContainer-module__mainContainer--mn23F`,
	"matricesContainer": `ConfusionMatricesContainer-module__matricesContainer--t3zSW`,
	"legendContainer": `ConfusionMatricesContainer-module__legendContainer--OEmvU`,
	"legendWrapper": `ConfusionMatricesContainer-module__legendWrapper--X01im`
};
module.exports = ___CSS_LOADER_EXPORT___;
