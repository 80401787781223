import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { VOTE_TYPES } from '@experiment-management-shared/constants/chartsGallery';
import { IconButton } from '@ds';

import useVotePanelMutation from '@API/panels/useVotePanelMutation';
import { DSArrowDownIcon, DSArrowUpIcon } from '@ds-icons';

const { UPVOTE, DOWNVOTE, NOVOTE } = VOTE_TYPES;

const ChartVote = ({ rank, galleryScopeType, isUnrankable }) => {
  const votePanelMutation = useVotePanelMutation();
  const { templateId, userVoteType, voteCount } = rank;

  const handleUnvote = () => {
    votePanelMutation.mutate({
      scope: galleryScopeType,
      templateId,
      voteType: NOVOTE
    });
  };

  const handleVote = voteType => {
    if (userVoteType === voteType) {
      handleUnvote();
    } else {
      votePanelMutation.mutate({
        scope: galleryScopeType,
        templateId,
        voteType
      });
    }
  };

  const renderVoteButton = voteType => {
    const Icon = voteType === UPVOTE ? DSArrowUpIcon : DSArrowDownIcon;
    const buttonClass = cx('vote-action-btn', voteType);

    return (
      <IconButton
        size="small"
        type={userVoteType === voteType ? 'primary' : 'secondary'}
        className={buttonClass}
        onClick={() => handleVote(voteType)}
        disabled={isUnrankable}
        Icon={<Icon />}
      />
    );
  };

  return (
    <div className="vote-action-container">
      {renderVoteButton(UPVOTE)}
      <span>{voteCount}</span>
      {renderVoteButton(DOWNVOTE)}
    </div>
  );
};

ChartVote.propTypes = {
  galleryScopeType: PropTypes.string.isRequired,
  isUnrankable: PropTypes.bool.isRequired,
  rank: PropTypes.object.isRequired
};

export default ChartVote;
