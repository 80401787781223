import { useMemo } from 'react';
import { UseQueryOptions, useQueries } from 'react-query';

export default function useCombinedQueries<T = unknown, E = Error>(
  queries: UseQueryOptions<T, E, T>[]
) {
  const results = useQueries(queries);

  const data = useMemo(() => {
    return results.map(result => result.data).filter(Boolean) || [];
  }, [results]);

  const isLoading = useMemo(() => {
    return results.some(result => result.isLoading);
  }, [results]);

  const isError = useMemo(() => {
    return results.some(result => result.isError);
  }, [results]);

  const isFetching = useMemo(() => {
    return results.some(result => result.isFetching);
  }, [results]);

  const isPreviousData = useMemo(() => {
    return results.every(result => result.isPreviousData);
  }, [results]);

  return { data, isLoading, isError, isFetching, isPreviousData };
}
