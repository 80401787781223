import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from '@ds';

import { UNTITLED_CHART_TEMPLATE_NAME } from '@experiment-management-shared/constants/chartConstants';

import useSelectedProjectView from '@API/project/useSelectedProjectView';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import ExportFileModal from '@experiment-management-shared/components/ExportFileModal';
import { DSDownloadIcon } from '@ds-icons';

const ExportTableModalButton = ({
  closeMenuHandler,
  totalExperiments,
  ...modalProps
}) => {
  const dispatch = useDispatch();
  const { projectName, workspace } = useParams();
  const { data: selectedProjectView } = useSelectedProjectView();

  const handleClick = () => {
    closeMenuHandler();
    const modalId = dialogTypes.EXPORT_TABLE_MODAL;
    const viewName =
      selectedProjectView?.templateName ?? UNTITLED_CHART_TEMPLATE_NAME;

    const exportFileModal = (
      <ExportFileModal
        modalId={modalId}
        projectName={projectName}
        viewName={viewName}
        workspace={workspace}
        {...modalProps}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, exportFileModal));
  };

  return (
    <Button
      size="large"
      disabled={!totalExperiments}
      onClick={handleClick}
      PrefixIcon={<DSDownloadIcon />}
    >
      Export CSV
    </Button>
  );
};

ExportTableModalButton.propTypes = {
  closeMenuHandler: PropTypes.func.isRequired,
  totalExperiments: PropTypes.number.isRequired
};

export default ExportTableModalButton;
