import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

const regenerateApiKey = ({ organizationId, name }) =>
  organizationApi.regenerateApiKey({
    organizationId,
    name
  });

const useRegenerateApiKeyMutation = organizationId => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(name => regenerateApiKey({ name, organizationId }), {
    onSuccess: res => {
      const serviceAccountWithRegenerated = res?.data || {};

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SERVICE_ACCOUNT_REGENERATE_API_KEY,
          'Successfully regenerated an API key'
        )
      );

      queryClient.setQueriesData(
        ['organizations', organizationId, 'service-accounts'],
        oldSettingsAccounts => {
          const newSettingsAccounts = [...oldSettingsAccounts];
          const indexSettingsAccount = newSettingsAccounts.findIndex(
            account => account.name === serviceAccountWithRegenerated.name
          );

          if (indexSettingsAccount > -1) {
            newSettingsAccounts[
              indexSettingsAccount
            ] = serviceAccountWithRegenerated;
          }

          return newSettingsAccounts;
        }
      );
    }
  });
};

export default useRegenerateApiKeyMutation;
