import axios from 'axios';
import { BASE_API_URL } from '@/lib/appConstants';
import { Metric } from '@shared/types';

type FetchArgs = {
  experimentKey: string;
  signal?: AbortSignal;
};
const metricsApi = {
  fetchMetrics({ experimentKey }: FetchArgs) {
    const url = `${BASE_API_URL}data-fetch/get/metrics`;

    return axios.get<{
      results: Metric[];
      version: string;
    }>(url, {
      params: { experimentKey }
    });
  }
};
export default metricsApi;
