import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import { Button } from '@ds';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import Radio from '@material-ui/core/Radio/Radio';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import useProject from '@API/project/useProject';
import cx from 'classnames';

import useUserPermission from '@shared/api/useUserPermission';
import { MANAGEMENT_PERMISSIONS } from '@shared/constants/permissions';
import { useActiveWorkspaceName } from '@shared/hooks';

import projectsActions from '@/actions/projectsActions';
import DocsLink from '@shared/components/DocsLink';
import { DOCS_LINK_TYPE } from '@/constants/urlConstants';

const VisibilitySection = () => {
  const { data: project } = useProject();
  const { projectId, projectName, isPublic: isProjectPublic } = project;
  const [isPublic, setIsPublic] = useState(isProjectPublic);

  const dispatch = useDispatch();
  const { getPermissionStatus } = useUserPermission();
  const activeWorkspaceName = useActiveWorkspaceName();

  const handleChange = value => setIsPublic(value);

  const savePrivacySettings = () => {
    dispatch(
      projectsActions.changeProjectsPrivacy(
        projectId,
        isPublic,
        projectName,
        activeWorkspaceName
      )
    );
  };

  const isDisabled = !getPermissionStatus({
    workspaceName: activeWorkspaceName,
    permissionKey: MANAGEMENT_PERMISSIONS.CHANGE_PROJECT_PRIVACY
  });
  const isPrivate = !isPublic;

  const radioButtonForPublic = (
    <span>
      <Radio
        label="Public"
        className={cx('radio-public', 'option', isDisabled && 'disabled')}
        checked={isPublic}
        onChange={() => handleChange(true)}
        disabled={isDisabled}
      />
      <div className="value">Public</div>
      <div className="label">Anyone on the internet can see this project</div>
    </span>
  );
  const radioButtonForPrivate = (
    <span>
      <Radio
        label="Private"
        className={cx(
          'radio-radio-private',
          'option',
          isDisabled && 'disabled'
        )}
        checked={isPrivate}
        onChange={() => handleChange(false)}
        disabled={isDisabled}
      />
      <div className="value">Private</div>
      <div className="label">
        You choose who can see experiments for this project
      </div>
    </span>
  );

  const content = () => {
    return (
      <div className="visibility-section">
        <DocsLink type={DOCS_LINK_TYPE.MANAGE_VISIBILITY_SECTION} />
        <FormGroup>
          <RadioGroup>
            <Grid container>
              <Grid item xs={3}>
                {isDisabled ? (
                  <Tooltip
                    arrow
                    title="Cannot change project visibility"
                    placement="top"
                  >
                    {radioButtonForPublic}
                  </Tooltip>
                ) : (
                  radioButtonForPublic
                )}
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4}>
                {isDisabled ? (
                  <Tooltip
                    arrow
                    title="Cannot change project visibility"
                    placement="top"
                  >
                    {radioButtonForPrivate}
                  </Tooltip>
                ) : (
                  radioButtonForPrivate
                )}
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <Button
                  size="large"
                  onClick={savePrivacySettings}
                  disabled={isDisabled}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </RadioGroup>
        </FormGroup>
      </div>
    );
  };

  return (
    <section>
      <h4 className="section">Project Visibility</h4>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12}>
          <Paper className="settings-section-paper" elevation={4}>
            {content()}
          </Paper>
        </Grid>
      </Grid>
    </section>
  );
};

export default VisibilitySection;
