import CodeMirror from '@uiw/react-codemirror';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './CometSQLSyntax.module.scss';
import { COLUMN_PREFIXES, customSql, customSqlParser } from './language';
import { Feature, Label } from '@mpm-druid/types';
import { SOURCE_TYPE } from '@mpm-druid/constants';

export const CometSQLSyntax = ({
  debug = false,
  features,
  labels,
  onChange,
  onValidQueryChange,
  query: initialQuery
}: {
  debug?: boolean;
  features: Feature[];
  labels: Label[];
  onChange?: (query: string) => void;
  onValidQueryChange?: (query: string) => void;
  query?: string;
}) => {
  const [query, setQuery] = useState(initialQuery || '');

  const columnNames = useMemo(() => {
    return [
      ...features
        .filter(
          (feature: Feature) =>
            feature.source === SOURCE_TYPE.model_input_features
        )
        .map((feature: Feature) => {
          return `${COLUMN_PREFIXES.feature}${feature.name}`;
        }),
      ...features
        .filter(
          (feature: Feature) =>
            feature.source === SOURCE_TYPE.model_output_features
        )
        .map((feature: Feature) => {
          return `${COLUMN_PREFIXES.prediction}${feature.name}`;
        }),
      ...labels.map((label: Label) => {
        return `${COLUMN_PREFIXES.label}${label.name}`;
      })
    ];
  }, [features, labels]);

  useEffect(() => {
    if (debug) {
      console.info('Parser', query, customSqlParser.parse(query).toString());
    }
  }, [debug, query]);

  const { language, linter } = useMemo(() => {
    return customSql({ columnNames });
  }, [columnNames]);

  return (
    <div className={styles.editor}>
      <CodeMirror
        className={styles.codemirror}
        value={query}
        onChange={(newQuery, viewUpdate) => {
          setQuery(query);
          onChange?.(newQuery);

          const diagnostics = linter(viewUpdate.view);
          if (diagnostics.length === 0) {
            onValidQueryChange?.(newQuery);
          }
        }}
        basicSetup={{
          lineNumbers: false
        }}
        height="100%"
        extensions={[language]}
      />
    </div>
  );
};
