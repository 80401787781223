import React from 'react';
import {
  PanelType,
  Panel,
  LineDash,
  LegendMode,
  LegendIconType
} from '@experiment-management-shared/types';

import {
  CircleIcon,
  DashDotLineIcon,
  DashLineIcon,
  DottedLineIcon,
  SolidLineIcon,
  SquareIcon
} from '@shared/utils/svgIcons';

type ChartState = Record<PanelType, Panel[]> & {
  numberOfCharts: number;
};

export const INITIAL_CHART_STATE: ChartState = {
  line: [],
  scatter: [],
  bar: [],
  parallel: [],
  scalar: [],
  image: [],
  data: [],
  custom: [],
  video: [],
  pcd: [],
  curves: [],
  python: [],
  numberOfCharts: 0
};

export const LINE_GROUP_BY_PARAMETER = {
  NO_PARAM_VALUE: '@@@NO_PARAM_VALUE@@@',
  NO_PARAM_NAME: '@@@NO_PARAM_NAME@@@'
};

export const LINE_DASH_OPTIONS: LineDash[] = [
  'solid',
  '2px',
  '8px',
  'longdashdot'
];

export const LEGEND_ICON_MAP: Record<
  LegendIconType,
  React.FunctionComponent
> = {
  solid: SolidLineIcon,
  '2px': DottedLineIcon,
  '8px': DashLineIcon,
  longdashdot: DashDotLineIcon,
  circle: CircleIcon,
  square: SquareIcon
};

export const LEGEND_MODE: Record<string, LegendMode> = {
  AUTO: 'auto',
  ON: 'on',
  OFF: 'off'
};
