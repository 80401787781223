import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

type FeatureParams = {
  modelId: string;
  featureId?: string;
};

const getFeature = ({
  modelId,
  workspaceId,
  featureId
}: FeatureParams & { workspaceId: string }) =>
  api.get(`/mpm/${workspaceId}/models/${modelId}/features/${featureId}`);

export function useFeature({ featureId, modelId }: FeatureParams) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['model-feature', { workspaceId: activeWorkspace?.id, featureId }],
    () => getFeature({ workspaceId: activeWorkspace?.id, featureId, modelId }),
    {
      enabled: !!activeWorkspace?.id && !!featureId
    }
  );
}
