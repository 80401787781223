import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import { TablePagination } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import ManageModelModal from '@model-registry/components/ManageModelModal/ManageModelModal';
import { getRegisteredModelURL } from '@shared/utils/model-streamline-registration';
import ModelRegistryTabEmptyState from '@model-registry/components/ModelRegistryTab/ModelRegistryTabEmptyState';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import CardsDisplay from '@shared/components/WorkspaceSubComponents/CardsDisplay';
import DisplayToggle from '@shared/components/WorkspaceSubComponents/DisplayToggle';
import FiltersPopover from '@shared/components/WorkspaceSubComponents/FiltersPopover';
import SortByPopover from '@shared/components/WorkspaceSubComponents/SortByPopover';
import SmallLoader from '@shared/components/SmallLoader';
import StyledSearchBar from '@shared/components/StyledComponents/StyledSearchBar';

import workspaceActions from '@/actions/workspaceActions';
import { getTabState } from '@/reducers/ui/workspaceUiReducer';
import {
  FILTERS,
  SEARCHABLE_FIELDS,
  WORKSPACE_PAGE_TABS,
  TAB_STATE_KEYS,
  WORKSPACE_TABS_COLUMNS,
  getSortByItemsByKey,
  searchItems,
  sortItems,
  filterItems
} from '@/constants/workspaceConstants';
import {
  getRegistryModelsArray,
  getLastRegistryModelsFetch
} from '@/reducers/modelRegistryReducer';
import TableDisplay from '@shared/components/WorkspaceSubComponents/TableDisplay';
import modelRegistryActions from '../../../../actions/modelRegistryActions';
import SingleCard from '@shared/components/WorkspaceSubComponents/SingleCard';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '../../../../util/alertsUtil';
import { BETA_FEATURE_MODEL_REGISTRY_STREAMLINE } from '@/lib/betaFeatures';
import {
  useActiveWorkspace,
  useCurrentOrganization,
  useIsUserMemberOfWorkspace
} from '@shared/hooks';

import useUserPermission from '@shared/api/useUserPermission';
import { MANAGEMENT_PERMISSIONS_SERVER } from '@shared/constants/permissions';

const { MODEL_REGISTRY } = WORKSPACE_PAGE_TABS;
const {
  IS_CARD_DISPLAY,
  SORT_PROPERTY,
  SORT_DIRECTION,
  SELECTED_FILTERS,
  PAGE_SIZE,
  PAGE_NUMBER
} = TAB_STATE_KEYS;

const ModelRegistryTab = ({
  dispatch,
  entityName,
  isCardDisplay,
  lastDataFetch,
  sortByProperty,
  sortByDirection,
  selectedFilters,
  items,
  pageSize,
  pageNumber,
  workspace
}) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(modelRegistryActions.fetchModelRegistryList(workspace));
  }, []);

  const filters = useMemo(() => {
    return FILTERS[MODEL_REGISTRY].getFilters(items);
  }, [items]);
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const isModelStreamlineRegistrationEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_REGISTRY_STREAMLINE
  );

  const handleSearchChange = useCallback(text => setSearchText(text), []);
  const handleCancelSearch = useCallback(() => setSearchText(''), []);
  const handleSortByChange = useCallback((property, direction) => {
    dispatch(
      workspaceActions.updateTabStateByKey(
        MODEL_REGISTRY,
        SORT_DIRECTION,
        direction
      )
    );
    dispatch(
      workspaceActions.updateTabStateByKey(
        MODEL_REGISTRY,
        SORT_PROPERTY,
        property
      )
    );
  }, []);

  const handleFilterChange = useCallback(
    updatedFilter => {
      let update = {};

      if (!isEmpty(updatedFilter)) {
        update = {
          ...selectedFilters,
          ...updatedFilter
        };
      }

      dispatch(
        workspaceActions.updateTabStateByKey(
          MODEL_REGISTRY,
          SELECTED_FILTERS,
          update
        )
      );
    },
    [selectedFilters]
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      const newPageSize = parseInt(event.target.value, 10);

      dispatch(
        workspaceActions.updateTabState(MODEL_REGISTRY, {
          [PAGE_SIZE]: newPageSize,
          [PAGE_NUMBER]: 0
        })
      );
    },
    [dispatch]
  );

  const handlePageNumberChange = useCallback(
    (event, newPage) => {
      dispatch(
        workspaceActions.updateTabStateByKey(
          MODEL_REGISTRY,
          PAGE_NUMBER,
          newPage
        )
      );
    },
    [dispatch]
  );

  const handleSetIsCardDisplay = isCardDisplay => {
    dispatch(
      workspaceActions.updateTabStateByKey(
        MODEL_REGISTRY,
        IS_CARD_DISPLAY,
        isCardDisplay
      )
    );
  };

  const filteredItems = useMemo(() => {
    return filterItems(items, selectedFilters);
  }, [selectedFilters, items]);

  const searchedItems = useMemo(() => {
    const searchableFields = SEARCHABLE_FIELDS[MODEL_REGISTRY];
    return searchItems(filteredItems, searchText, searchableFields);
  }, [filteredItems, searchText]);

  const visibleItems = useMemo(() => {
    return sortItems(searchedItems, sortByDirection, sortByProperty);
  }, [searchedItems, sortByDirection, sortByProperty]);

  const paginatedItems = useMemo(
    () =>
      visibleItems.slice(
        pageNumber * pageSize,
        pageNumber * pageSize + pageSize
      ),
    [visibleItems, pageNumber, pageSize]
  );
  const itemCount = visibleItems.length;

  useEffect(() => {
    handlePageNumberChange(null, 0);
  }, [filteredItems, searchText, handlePageNumberChange]);

  const currentOrganization = useCurrentOrganization();
  const isAdmin = currentOrganization?.isAdmin;
  const activeWorkspace = useActiveWorkspace();
  const { getServerPermissionStatus } = useUserPermission();
  const ifCurrentUserWsOwner = getServerPermissionStatus({
    workspaceName: activeWorkspace?.name,
    permissionKey: MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
  });

  const canDeleteModel = isAdmin || ifCurrentUserWsOwner;

  const renderActionFields = () => {
    return (
      <div className="action-fields-container">
        <div className="field-group">
          <FiltersPopover
            handleFilterChange={handleFilterChange}
            selectedFilters={selectedFilters}
            filters={filters}
          />
          <StyledSearchBar
            entityName={entityName}
            handleCancelSearch={handleCancelSearch}
            handleSearchChange={handleSearchChange}
          />
        </div>
        <div className="field-group">
          <SortByPopover
            handleSortByChange={handleSortByChange}
            sortByProperty={sortByProperty}
            sortByDirection={sortByDirection}
            items={getSortByItemsByKey(MODEL_REGISTRY)}
          />
          <DisplayToggle
            isCardDisplay={isCardDisplay}
            handleIsCardDisplay={handleSetIsCardDisplay}
          />
        </div>
      </div>
    );
  };

  const getMenuItemConfigs = model => {
    return [
      {
        label: 'Edit',
        Icon: CreateIcon,
        handler: () => {
          const modalId = dialogTypes.MANAGE_MODEL_MODAL;
          const modal = (
            <ManageModelModal
              modalId={modalId}
              model={model}
              workspace={workspace}
            />
          );
          dispatch(alertsUtil.openCustomModal(modalId, modal));
        }
      },
      {
        label: 'Delete',
        Icon: DeleteIcon,
        disabled: !canDeleteModel,
        handler: () => {
          dispatch(
            modelRegistryActions.openDeleteModelConfirm(
              workspace,
              model.modelName
            )
          );
        }
      }
    ];
  };

  const renderBody = () => {
    if (isNull(lastDataFetch)) {
      return (
        <SmallLoader
          dataTest="model-registry-tab-loading"
          primaryMessage="Loading..."
          secondaryMessage={`Fetching ${entityName}`}
        />
      );
    }

    if (isEmpty(visibleItems)) {
      if (!searchText && isModelStreamlineRegistrationEnabled) {
        return <ModelRegistryTabEmptyState />;
      }

      return (
        <div className="content-not-found" data-test="model-registry-tab-empty">
          <h3 className="page-notice">
            {`This workspace has no matching ${entityName} items.`}
          </h3>
        </div>
      );
    }

    const renderTablePagination = () => {
      return (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          count={itemCount}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handlePageNumberChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={isCardDisplay ? 'cards per page' : 'rows per page'}
        />
      );
    };

    return isCardDisplay ? (
      <CardsDisplay
        items={paginatedItems}
        renderPagination={renderTablePagination}
        renderCard={item => {
          const {
            modelName,
            isPublic,
            description,
            numberOfVersions,
            lastUpdated,
            imagePath
          } = item;

          return (
            <SingleCard
              key={modelName}
              title={modelName}
              isEditable={isUserAMemberOfWorkspace}
              isPublic={isPublic}
              description={description}
              lastUpdated={lastUpdated}
              itemCount={{ label: 'Versions', count: numberOfVersions }}
              imagePath={imagePath}
              viewLinkConfig={{
                entityName: 'model',
                url: getRegisteredModelURL(workspace, modelName)
              }}
              menuItemConfigs={getMenuItemConfigs(item)}
            />
          );
        }}
      />
    ) : (
      <Fragment data-test="model-registry-tab-table">
        <TableDisplay
          isEditable={isUserAMemberOfWorkspace}
          items={paginatedItems}
          columns={WORKSPACE_TABS_COLUMNS.MODEL_REGISTRY}
          handleSortByChange={handleSortByChange}
          sortByDirection={sortByDirection}
          sortByProperty={sortByProperty}
          getURLForRowItem={item => {
            const { modelName } = item;
            return getRegisteredModelURL(workspace, modelName);
          }}
          getMenuItemConfigs={getMenuItemConfigs}
          renderTablePagination={renderTablePagination}
        />
      </Fragment>
    );
  };

  return (
    <div>
      {renderActionFields()}
      {renderBody()}
    </div>
  );
};

ModelRegistryTab.defaultProps = {
  lastDataFetch: null
};

ModelRegistryTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  isCardDisplay: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  lastDataFetch: PropTypes.number,
  selectedFilters: PropTypes.object.isRequired,
  sortByDirection: PropTypes.string.isRequired,
  sortByProperty: PropTypes.string.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    isCardDisplay,
    sortByProperty,
    sortByDirection,
    selectedFilters,
    pageSize,
    pageNumber
  } = getTabState(state, { tabKey: WORKSPACE_PAGE_TABS.MODEL_REGISTRY });

  const { workspace } = props.match.params;

  return {
    isCardDisplay,
    items: getRegistryModelsArray(state),
    lastDataFetch: getLastRegistryModelsFetch(state),
    selectedFilters,
    sortByDirection,
    sortByProperty,
    pageSize,
    pageNumber,
    workspace
  };
};

export default withRouter(connect(mapStateToProps)(ModelRegistryTab));
