import flow from 'lodash/flow';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import { ExperimentTemplate } from '@experiment-details/types';
import {
  Panel,
  PanelGlobalConfig,
  PanelLayoutItem
} from '@experiment-management-shared/types';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';

const cleanTemplateFromUndefinedLayoutFields = (
  template: ExperimentTemplate
) => {
  const result = { ...template };

  if (result?.panels?.layout?.length) {
    result.panels.layout = result.panels.layout.map(
      layout => omitBy(layout, isUndefined) as PanelLayoutItem
    );
  }

  if (isArray(result?.panels?.panels)) {
    result.panels.panels = result.panels.panels.map(
      panel => omitBy(panel, isUndefined) as Panel
    );
  }

  return result;
};

export const cleanTemplateFromNotNeededFields = (
  template: ExperimentTemplate
) => {
  return flow(
    cleanTemplateFromUndefinedLayoutFields,
    filteredTemplate => omitBy(filteredTemplate, isUndefined),
    filteredTemplate => omitBy(filteredTemplate, isNull)
  )(template);
};

export const calculatePanelGlobalConfig = (
  template: Partial<ExperimentTemplate>
) => {
  return {
    selectedXAxis: template.x_axis,
    transformY: template.transformY,
    smoothingY: template.smoothing,
    selectedOutliers: chartHelpers.calculateSelectedOutliers(
      template?.outliers?.isVisible
    )
  } as PanelGlobalConfig;
};
