import { useEffect } from 'react';
import { Edge, Node } from 'react-flow-renderer';
import { getLineageNodeName, updateHiddenLineageItems } from '../utils';

type UseDeletedNodesMapOpts = {
  isHidden: boolean;
  nodes: Node[];
  edges: Edge[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  mainNodeId: string;
};
const useDeletedNodesIsHidden = ({
  nodes,
  edges,
  isHidden,
  setNodes,
  setEdges,
  mainNodeId
}: UseDeletedNodesMapOpts) => {
  const mainNode = nodes.find(node => node.id === mainNodeId) as Node;
  const mainNodeName = getLineageNodeName(mainNode);

  const deletedNodesMap = getDeletedNodesMap({
    nodes,
    edges,
    mainNodeName,
    mainNodeType: mainNode.type
  });

  const nodeHiddenSelect = (item: Node) => {
    return deletedNodesMap[item.id];
  };
  const edgeHiddenSelect = (item: Edge) => {
    return deletedNodesMap[item.target];
  };

  useEffect(() => {
    setNodes(updateHiddenLineageItems(isHidden, nodeHiddenSelect));
    setEdges(updateHiddenLineageItems(isHidden, edgeHiddenSelect));
  }, [isHidden, setEdges, setNodes]);
};

type GetDeletedNodesMapOpts = {
  deletedNodesMap?: Record<string, boolean>;
  nodes: Node[];
  edges: Edge[];
  mainNodeName: string;
  mainNodeType?: string;
};
function getDeletedNodesMap({
  deletedNodesMap = {},
  nodes,
  edges,
  mainNodeName,
  mainNodeType
}: GetDeletedNodesMapOpts) {
  const remainingNodes = nodes.filter(node => {
    const hasChildren = edges.some(edge => edge.source === node.id);

    if (hasChildren) return true;

    const shouldBeHidden =
      node.data.deleted &&
      (node.type !== mainNodeType || getLineageNodeName(node) !== mainNodeName);

    deletedNodesMap[node.id] = shouldBeHidden;

    return !shouldBeHidden;
  });

  if (remainingNodes.length === nodes.length) return deletedNodesMap;

  const remainingEdges = edges.filter(edge => {
    return !deletedNodesMap[edge.source] && !deletedNodesMap[edge.target];
  });

  return getDeletedNodesMap({
    deletedNodesMap,
    nodes: remainingNodes,
    edges: remainingEdges,
    mainNodeName,
    mainNodeType
  });
}

export default useDeletedNodesIsHidden;
