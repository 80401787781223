import { SORT_TYPES } from '@experiment-management-shared/constants/tabConfigs';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import toString from 'lodash/toString';
import {
  GRAPHICS_ASSETS_FIELDS_MAP,
  GRAPHICS_ASSETS_TYPES,
  GRAPHICS_FIELDS_MAP
} from '@experiment-details/constants/graphics';
import { generateRegexFromString } from '@shared/utils/displayHelpers';

export const generateTitle = (figName, figCounter) =>
  figName || `figure_${figCounter}`;

// Function to merge two arrays alternatively
export const alternateMerge = (arr1, arr2, emptyValue = null) => {
  const res = [];
  const array1Length = arr1.length;
  const array2Length = arr2.length;
  let i = 0;

  while (i < array1Length || i < array2Length) {
    res.push(arr1[i] ?? emptyValue);
    res.push(arr2[i] ?? emptyValue);
    i += 1;
  }

  return res;
};

export const toNumber = n => Number(n) || 0;

export const filterAssets = ({ query = '' }) => asset => {
  const { type } = asset;
  const regexExpression = generateRegexFromString(query);
  const {
    [GRAPHICS_FIELDS_MAP.name?.[type]]: name,
    figName,
    figCounter
  } = asset;
  const title = generateTitle(name || figName, figCounter);

  if (!title || !regexExpression) return false;

  return regexExpression.test(title);
};

export const IMAGE_KEY_PREFIX = '%_';

export const groupAssetsBy = groupBy => assets => {
  if (!groupBy) return assets;

  return assets.reduce((categories, asset) => {
    const [groupByKey, value] = groupBy.split(IMAGE_KEY_PREFIX);
    const categoryKey =
      groupByKey === 'metadata'
        ? getMetadataValue(asset, value)
        : get(asset, groupByKey);

    if (!categories[categoryKey]) {
      categories[categoryKey] = [];
    }

    categories[categoryKey].push(asset);
    return categories;
  }, {});
};

export const getMetadataValue = (asset, key) => {
  try {
    const metadata = JSON.parse(get(asset, 'metadata'));
    return get(metadata, key);
  } catch (error) {
    console.error('Error parsing JSON for asset:', asset.id, error);
    return null;
  }
};

export const sortAssets = sortBy => graphicsAssets => {
  return [...graphicsAssets].sort((imageA, imageB) => {
    const [sortByKey, value] = sortBy.split(IMAGE_KEY_PREFIX);

    if (sortByKey === SORT_TYPES.NAME) {
      const titleA = generateTitle(
        imageA[GRAPHICS_ASSETS_FIELDS_MAP.name],
        imageA.figCounter
      );
      const titleB = generateTitle(
        imageB[GRAPHICS_ASSETS_FIELDS_MAP.name],
        imageB.figCounter
      );

      return titleA.localeCompare(titleB);
    }

    if (sortByKey === SORT_TYPES.METADATA) {
      const valueA = getMetadataValue(imageA, value, '');
      const valueB = getMetadataValue(imageB, value, '');
      if (isNumber(valueA) && isNumber(valueB)) {
        return valueA - valueB;
      }
      return toString(valueA).localeCompare(toString(valueB));
    }

    const valueA = get(imageA, sortBy, '');
    const valueB = get(imageB, sortBy, '');

    return toNumber(valueA) - toNumber(valueB);
  });
};

export const mapItemsToGraphicsAssets = (
  item = {},
  type = GRAPHICS_ASSETS_TYPES.image
) => ({
  ...item,
  type,
  [GRAPHICS_ASSETS_FIELDS_MAP.id]: item[GRAPHICS_FIELDS_MAP.id[type]],
  [GRAPHICS_ASSETS_FIELDS_MAP.path]: item[GRAPHICS_FIELDS_MAP.path[type]],
  [GRAPHICS_ASSETS_FIELDS_MAP.name]: item[GRAPHICS_FIELDS_MAP.name[type]],
  [GRAPHICS_ASSETS_FIELDS_MAP.thumbnailPath]:
    item[GRAPHICS_FIELDS_MAP.thumbnailPath[type]],
  [GRAPHICS_ASSETS_FIELDS_MAP.fileName]:
    item[GRAPHICS_FIELDS_MAP.fileName[type]]
});
