import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

const PageMeta = ({ title, name, description, children }) => {
  // Helmet sometimes behaves strangely when dealing with nested JSX,
  // so we break each tag out separately here:
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {name && !title && <title>{`${name} - Comet.ml`}</title>}
      {name && <meta name="name" content={name} />}
      {name && <meta itemProp="name" content={name} />}
      {description && <meta name="description" content={description} />}
      {description && <meta itemProp="description" content={description} />}
      {children}
    </Helmet>
  );
};

PageMeta.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
};

PageMeta.defaultProps = {
  title: '',
  name: '',
  description: ''
};

export default PageMeta;
