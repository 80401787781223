import { chartColors } from '@design-system-outdated/constants';
import reduce from 'lodash/reduce';

export function getChartNameByText(name = '') {
  const len = Object.keys(chartColors).length;
  const sum = reduce(name.split(), (memo, val) => memo + val.charCodeAt(0), 0);
  const key = Object.keys(chartColors)[sum % len];
  return key;
}

export * from './files';
export * from './routes';
export * from './redirect-link';
export * from './color';
export * from './code';
export * from './markdown';
export * from './generate';
export * from './interpolation';
