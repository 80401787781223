import { useCallback, useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import {
  GroupAggregation,
  LegendIconType,
  LegendSortMap,
  PanelCometMetadata,
  PanelCometMetadataType,
  PanelHoverDataSource,
  PanelMetric,
  PanelTrace,
  TooltipDataPoint
} from '@experiment-management-shared/types';
import {
  generateLegendKeysString,
  generateSortLegendItemsFunction,
  getCometMetadataType,
  getHoverParamsFromCometMetadata,
  getIsHighlightedTrace,
  truncateLegendValue
} from '@experiment-management-shared/components/Charts/Legends/helpers';
import useBarChartTraceHighlight from '@experiment-management-shared/components/Charts/PlotlyChart/BarChart/useBarChartTraceHighlight';

const calculateCometMetadataItems = (filteredTraces: PanelTrace[]) => {
  return filteredTraces.reduce<LegendItemSource[]>((acc, trace) => {
    if (trace.cometMetadataArray) {
      trace.cometMetadataArray.forEach((cometMetadata, index) => {
        acc.push({
          cometMetadata,
          color: trace?.marker?.color?.[index] ?? ''
        });
      });
    }

    if (trace.cometMetadata) {
      acc.push({
        cometMetadata: trace.cometMetadata,
        color: (trace?.line?.color ?? trace?.marker?.color ?? '') as string
      });
    }

    return acc;
  }, []);
};

type LegendItemSource = {
  cometMetadata: PanelCometMetadata;
  color: string;
};

const useBarChartLegend = ({
  data,
  hoveredPoint,
  isAggregationActive,
  isStackedBarChart,
  metrics,
  experimentKeys,
  groupAggregation
}: {
  data: PanelTrace[];
  hoveredPoint?: TooltipDataPoint;
  isAggregationActive: boolean;
  isStackedBarChart: boolean;
  metrics: PanelMetric[];
  experimentKeys: string[];
  groupAggregation?: GroupAggregation;
}) => {
  const cometMetadataType = getCometMetadataType(data[0]);

  const {
    dataWithHighlight,
    updateHoveredData,
    hoveredData
  } = useBarChartTraceHighlight({
    hoveredPoint,
    data,
    isStackedBarChart
  });

  const metricsAggregationMap = useMemo(
    () =>
      metrics.reduce<Record<string, string>>((acc, m) => {
        acc[m.name] = m.aggregation;
        return acc;
      }, {}),
    [metrics]
  );

  const metricsSortMap = useMemo(() => {
    return metrics.reduce<LegendSortMap>((acc, m, index) => {
      acc[m.name] = index;
      return acc;
    }, {});
  }, [metrics]);

  const experimentsSortMap = useMemo(() => {
    return experimentKeys.reduce<LegendSortMap>((acc, e, index) => {
      acc[e] = index;
      return acc;
    }, {});
  }, [experimentKeys]);

  const legendItems = useMemo(() => {
    let cometMetadataItems: LegendItemSource[] = [];

    switch (cometMetadataType) {
      case 'metric':
        cometMetadataItems = uniqBy(
          calculateCometMetadataItems(
            data.filter(
              trace => trace?.cometMetadata || trace?.cometMetadataArray
            )
          ),
          (item: LegendItemSource) => item.cometMetadata.experimentKey
        );

        break;
      case 'experiment_metric':
      case 'group_by_metric':
      case 'group_by_param':
        cometMetadataItems = calculateCometMetadataItems(
          data.filter(
            trace => trace?.cometMetadata || trace?.cometMetadataArray
          )
        );
        break;
    }

    const items = cometMetadataItems.map(({ color, cometMetadata }) => {
      const {
        experimentKey,
        metricName,
        paramName,
        paramValue
      } = getHoverParamsFromCometMetadata(cometMetadata);
      const {
        experimentName,
        legendKeys
      } = cometMetadata as PanelCometMetadata;
      const aggregation = metricsAggregationMap[metricName];

      let value = '';

      switch (cometMetadataType) {
        case 'metric':
          value = `${experimentName}${generateLegendKeysString(legendKeys)}`;
          break;
        case 'experiment_metric':
          value = `${metricName}${generateLegendKeysString(legendKeys)}`;
          break;
        case 'group_by_metric':
          if (isAggregationActive) {
            if (isStackedBarChart) {
              value = `${groupAggregation} ${experimentName}`;
            } else {
              value = `${groupAggregation} ${metricName} (${aggregation})`;
            }
          } else {
            value = `${metricName}`;
          }
          break;
        case 'group_by_param':
          if (isAggregationActive) {
            value = `${groupAggregation} ${metricName} (${aggregation}) - ${paramName}: ${paramValue}`;
          } else {
            value = `${metricName} - ${paramName}: ${paramValue}`;
          }
          break;
      }

      const { fullLabel, processedValue } = truncateLegendValue(value);

      return {
        color,
        iconType: 'square' as LegendIconType,
        label: processedValue,
        ...(fullLabel && { fullLabel }),
        key: value,
        metaData: {
          experimentKey,
          metricName,
          paramValue,
          paramName
        }
      };
    });

    if (
      cometMetadataType === 'metric' ||
      (cometMetadataType === 'group_by_metric' &&
        isAggregationActive &&
        isStackedBarChart)
    ) {
      items.sort(
        generateSortLegendItemsFunction([
          { property: 'experimentKey', map: experimentsSortMap }
        ])
      );
    } else {
      items.sort(
        generateSortLegendItemsFunction([
          { property: 'metricName', map: metricsSortMap }
        ])
      );
    }
    return items;
  }, [
    cometMetadataType,
    data,
    experimentsSortMap,
    groupAggregation,
    isAggregationActive,
    isStackedBarChart,
    metricsAggregationMap,
    metricsSortMap
  ]);

  const calculateIsLegendItemHighlighted = useCallback(
    item => {
      if (!hoveredData.source || !cometMetadataType) {
        return false;
      }

      const map: Record<
        PanelHoverDataSource,
        Record<PanelCometMetadataType, string[]>
      > = {
        global: {
          metric: [],
          experiment_metric: [],
          group_by_metric: [],
          group_by_param: []
        },
        table: {
          metric: ['experimentKey'],
          experiment_metric: ['experimentKey'],
          group_by_metric: isStackedBarChart ? ['experimentKey'] : [],
          group_by_param: []
        },
        chart: {
          metric: ['experimentKey'],
          experiment_metric: ['metricName'],
          group_by_metric: isStackedBarChart
            ? ['experimentKey']
            : ['metricName'],
          group_by_param: ['paramName', 'paramValue']
        },
        legend: {
          metric: ['experimentKey', 'metricName'],
          experiment_metric: ['metricName'],
          group_by_metric: isStackedBarChart
            ? ['experimentKey']
            : ['metricName'],
          group_by_param: ['metricName', 'paramName', 'paramValue']
        }
      };

      const keys = map[hoveredData.source]?.[cometMetadataType] ?? [];

      return keys.length > 0
        ? getIsHighlightedTrace(hoveredData, item.metaData, keys)
        : false;
    },
    [hoveredData, cometMetadataType, isStackedBarChart]
  );

  const onHoverLegendItem = useCallback(
    item => {
      updateHoveredData({
        metricName: item.metaData.metricName,
        paramName: item.metaData.paramName,
        paramValue: item.metaData.paramValue,
        experimentKey: item.metaData.experimentKey,
        source: 'legend'
      });
    },
    [updateHoveredData]
  );

  const onUnhoverLegendItem = useCallback(() => {
    updateHoveredData();
  }, [updateHoveredData]);

  return {
    items: legendItems,
    onHoverItem: onHoverLegendItem,
    onUnhoverItem: onUnhoverLegendItem,
    calculateIsItemHighlighted: calculateIsLegendItemHighlighted,
    dataWithHighlight
  };
};

export default useBarChartLegend;
