import { Button } from '@ds';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CodeMirror from '@uiw/react-codemirror';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import { usePythonPanelCode } from '@experiment-management-shared/api';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import classes from './PythonCodeTab.module.scss';
import { DSPlayIcon } from '@design-system/icons';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { trackEvent } from '@shared/utils/eventTrack';
import { panelEvents } from '@/constants/trackingEventTypes';
import { generatePythonPanelCodeVersion } from '@experiment-management-shared/utils';
import { getDefaultPythonCodeExtensions } from '@shared';

const DEBOUNCE_DELAY = 200;

const PythonPanelCodeTab = () => {
  const [code, setCode] = useState<string | null>(null);

  const dispatch = useDispatch();
  const panelForm = useSelector(state =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.python })
  );

  const changePanelFormCode = useCallback(
    (c: string) => {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.python,
          'code',
          c
        )
      );
    },
    [dispatch]
  );

  const changePanelFormCodeVersion = useCallback(() => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.python,
        'codeVersion',
        generatePythonPanelCodeVersion()
      )
    );
  }, [dispatch]);

  const onClickRun = () => {
    if (code !== null) {
      changePanelFormCodeVersion();
      trackEvent(panelEvents.CLICK_PYTHON_RUN);
    }
  };

  const debouncedClickRun = useCallback(debounce(onClickRun, DEBOUNCE_DELAY), [
    code
  ]);

  const {
    data: revisionCode = null,
    isFetched,
    isLoading: isLoadingRevision
  } = usePythonPanelCode(
    {
      revisionId: panelForm.revisionId,
      templateId: panelForm.templateId
    },
    {
      refetchOnMount: true
    }
  );

  useEffect(() => {
    if (revisionCode !== null) {
      changePanelFormCode(revisionCode);
    }
  }, [changePanelFormCode, revisionCode]);

  useEffect(() => {
    if (revisionCode !== null && isFetched) {
      changePanelFormCodeVersion();
    }
  }, [changePanelFormCodeVersion, revisionCode, isFetched]);

  useEffect(() => {
    setCode(panelForm.code);
  }, [panelForm.code]);

  return (
    <>
      <div className={classes.runCodeButtonContainer}>
        <Button
          size="small"
          PrefixIcon={<DSPlayIcon />}
          onClick={debouncedClickRun}
          loading={isLoadingRevision}
          disabled={!code}
        >
          Run code
        </Button>
      </div>
      <div className={classes.tab}>
        <CodeMirror
          value={code || ''}
          extensions={getDefaultPythonCodeExtensions()}
          onChange={changePanelFormCode}
          className={classes.pythonCodeMirror}
          placeholder="Please, enter the code"
        />
      </div>
    </>
  );
};

export default PythonPanelCodeTab;
