import React from 'react';
import styles from './UserMenuProfile.module.scss';
import useCurrentUser from '@API/auth/useCurrentUser';
import useProfileImageUrl from './hooks/useProfileImageUrl';
import { Avatar } from '@DesignSystem/data-display';
import { useCurrentOrganization, useCurrentPaymentPlan } from '@shared/hooks';
import { PAYMENT_PLANS } from '@shared/constants';
import { Tooltip } from '@ds';
import { Skeleton } from '@material-ui/lab';
import useIsGuestUser from '@shared/hooks/organization/useIsGuestUser';
import { InfoIcon } from '@Icons-outdated';

const AVATAR_WIDTH = 32;

const UserMenuProfile = () => {
  const { data: userData, isLoading } = useCurrentUser();
  const { paymentPlanName } = useCurrentPaymentPlan();
  const currentOrganization = useCurrentOrganization();
  const profileImageUrl = useProfileImageUrl({
    userData,
    width: AVATAR_WIDTH
  });
  const isGuestUser = useIsGuestUser();
  const currentPaymentPlan = paymentPlanName && PAYMENT_PLANS[paymentPlanName];
  const isAdmin = Boolean(currentOrganization?.isAdmin);

  const userName = userData?.username || '';
  let memberType = 'member';
  if (isAdmin) {
    memberType = 'admin';
  }

  if (isLoading) {
    return (
      <Skeleton
        className={styles.userMenuProfileSkeleton}
        variant="rect"
        animation="pulse"
      />
    );
  }

  return (
    <div className={styles.userMenuProfile}>
      <div className={styles.userMenuProfileAvatar}>
        <Avatar src={profileImageUrl} width={AVATAR_WIDTH} />
      </div>

      <div className={styles.userMenuProfileInfo}>
        <div
          className={styles.userMenuProfileUsername}
          data-test="user-menu-username"
        >
          <Tooltip
            className="truncated-string"
            wrapperDisplay="inline-flex"
            content={userName}
          >
            <>{userName}</>
          </Tooltip>
        </div>
        <div
          className={styles.userMenuProfileCurrentPlan}
          data-test="user-menu-current-payment-plan"
        >
          {!isGuestUser && (
            <>
              {currentPaymentPlan?.displayName} / {memberType}
            </>
          )}

          {isGuestUser && (
            <div className={styles.userMenuProfileGuestInfo}>
              <span>Guest</span>
              <Tooltip
                wrapperDisplay="inline-flex"
                content="Not an organization member"
              >
                <InfoIcon />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMenuProfile;
