import { onboardingEvents } from '@onboarding/constants';
import { useIsFirstSession } from '@shared/hooks';
import { trackEvent } from '@shared/utils/eventTrack';
import { useEffect } from 'react';
import useIsOnOnboardingPage from '../useIsOnOnboardingPage';

const usePageExit = () => {
  const isFirstSession = useIsFirstSession();
  const isOnboardingPage = useIsOnOnboardingPage();

  useEffect(() => {
    const listener = () => {
      const isGetStarted = window.location.pathname === '/get-started';

      trackEvent(onboardingEvents.PAGE_EXIT, {
        event_page: isGetStarted ? 'get_started_page' : 'quickstart_page'
      });
    };

    if (isFirstSession && isOnboardingPage) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }

    return () => window.removeEventListener('beforeunload', listener);
  }, [isFirstSession, isOnboardingPage]);
};

export default usePageExit;
