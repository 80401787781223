// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostSignUpQuestions-module__postSignUpForm--DSTfm{font-size:16px;color:var(--gray-6);height:480px}.PostSignUpQuestions-module__asterrisk--ltwjY{color:var(--warning-color)}.PostSignUpQuestions-module__fieldOptions--Q3CFl{margin-top:32px;display:flex;flex-wrap:wrap}.PostSignUpQuestions-module__fieldOptions--Q3CFl:last-of-type{margin-bottom:10px}.PostSignUpQuestions-module__fieldOption--FXYAX{margin-bottom:20px}.PostSignUpQuestions-module__fieldOption--FXYAX:not(:last-of-type){margin-right:16px}.PostSignUpQuestions-module__postSignUpField--nd9b2:not(:last-of-type){margin-bottom:22px}.PostSignUpQuestions-module__postSignUpForm--DSTfm,.PostSignUpQuestions-module__fieldOptions--Q3CFl{width:700px;max-width:700px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postSignUpForm": `PostSignUpQuestions-module__postSignUpForm--DSTfm`,
	"asterrisk": `PostSignUpQuestions-module__asterrisk--ltwjY`,
	"fieldOptions": `PostSignUpQuestions-module__fieldOptions--Q3CFl`,
	"fieldOption": `PostSignUpQuestions-module__fieldOption--FXYAX`,
	"postSignUpField": `PostSignUpQuestions-module__postSignUpField--nd9b2`
};
module.exports = ___CSS_LOADER_EXPORT___;
