// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueryBuilderSidebar-module__container--k7tDh{--filters-header-height: 52px;--filters-footer-height: 60px;padding-bottom:16px;min-height:1px}.QueryBuilderSidebar-module__container--k7tDh .QueryBuilderSidebar-module__innerContainer--sCvJ8{height:100%;padding:10px 0}.QueryBuilderSidebar-module__container--k7tDh .QueryBuilderSidebar-module__innerContainer--sCvJ8 .QueryBuilderSidebar-module__filtersHeaderContainer--R4AZ7{display:flex;justify-content:space-between;align-items:center;height:var(--filters-header-height);border-bottom:1px solid var(--gray)}.QueryBuilderSidebar-module__container--k7tDh .QueryBuilderSidebar-module__innerContainer--sCvJ8 .QueryBuilderSidebar-module__filtersHeaderContainer--R4AZ7 .QueryBuilderSidebar-module__title--B8FV6{font-weight:700;font-size:16px;color:var(--black)}.QueryBuilderSidebar-module__container--k7tDh .QueryBuilderSidebar-module__innerContainer--sCvJ8 .QueryBuilderSidebar-module__filtersBodyContainer--XsxZ2{height:calc(100% - var(--filters-header-height) - var(--filters-footer-height));overflow-y:auto;padding:24px 24px 24px 10px}.QueryBuilderSidebar-module__container--k7tDh .QueryBuilderSidebar-module__innerContainer--sCvJ8 .QueryBuilderSidebar-module__filtersFooterContainer--q2rb5{display:flex;justify-content:flex-end;align-items:center;gap:8px;height:var(--filters-footer-height);border-top:1px solid var(--gray)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QueryBuilderSidebar-module__container--k7tDh`,
	"innerContainer": `QueryBuilderSidebar-module__innerContainer--sCvJ8`,
	"filtersHeaderContainer": `QueryBuilderSidebar-module__filtersHeaderContainer--R4AZ7`,
	"title": `QueryBuilderSidebar-module__title--B8FV6`,
	"filtersBodyContainer": `QueryBuilderSidebar-module__filtersBodyContainer--XsxZ2`,
	"filtersFooterContainer": `QueryBuilderSidebar-module__filtersFooterContainer--q2rb5`
};
module.exports = ___CSS_LOADER_EXPORT___;
