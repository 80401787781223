import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import md5 from 'md5';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import noop from 'lodash/noop';

import {
  BUILT_IN_CHART_TYPES,
  CHART_CONFIG_MODIFIERS,
  DEFAULT_CUSTOM_RANGE,
  LINE_CHART_LINE_WIDTH,
  LINE_TYPE_STRATEGY
} from '@experiment-management-shared/constants/chartConstants';
import {
  LEGEND_MODE,
  LINE_DASH_OPTIONS
} from '@experiment-management-shared/constants';

import chartHelpers from '@experiment-management-shared/utils/chartHelpers';

import { OptimizedPlot } from '@DesignSystem/charts';
import CurvesChartTooltip from './CurvesChartTooltip';
import { useCurvesChartTooltip } from './useCurvesChartTooltip';
import useCurvesChartLegend from './useCurvesChartLegend';

import { getLegendsKeysMap, isVisibleOnChart } from '../../ChartHelpers';

import { CHART_COLORS, CHART_COLORS_LIGHT } from '@/constants/colorConstants';
import ChartContainer from '@experiment-management-shared/components/Charts/Chart/ChartContainer';
import { GeneralChartContainerProps } from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import {
  CustomRange,
  DataSource,
  Experiment,
  LegendMode,
  LineChartFillType,
  LineChartHoverMode,
  LineTypeStrategy,
  PanelCometMetadataType,
  PanelTrace,
  PanelType
} from '@experiment-management-shared/types';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';
import { useCurvesPanelData } from '@experiment-management-shared/api/useCurvesPanelData';

import useChartDataSourceChangeNotificator from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataSourceChangeNotificator';
import useChartDataRevision from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataRevision';
import usePlotlyBaseChart from '@experiment-management-shared/components/Charts/Chart/chartHooks/usePlotlyBaseChart';
import { getExperimentsColorMap } from '@experiment-management-shared/utils/experimentHelpers';
import useChartTraceClick from '@experiment-management-shared/hooks/useChartTraceClick';
import LegendWrapper from '@experiment-management-shared/components/Charts/Legends/LegendWrapper';
import { Legend } from '@experiment-management-shared/components/Charts/Legends';

import {
  generateGuaranteeUniquenessFunction,
  generateLegendLabelForLineChart,
  truncateLegendValueForExport
} from '@experiment-management-shared/components/Charts/Legends/helpers';
import { GridPanelSlider } from '@experiment-management-shared/components/Charts/NoPlotlyCharts';

type CurvesChartProps = {
  containerProps: GeneralChartContainerProps;
  isChartPreview?: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  hiddenExperimentKeys?: string[];
  isAutoRefreshEnabled: boolean;
  title?: string;
  chartName?: string;
  onChangeLegendMode?: never;
  onDataSourceChange?: () => void;
  onFinishRender?: () => void;
  enableDataRevision?: boolean;
  isPendingRendering?: boolean;
  dataRevision?: number;
  revision?: number;
  selectedLegendKeys?: never;
  legendMode: LegendMode;
  hiddenMenuItems?: string[];
  chartClickEnabled?: boolean;
  customRange?: CustomRange;
  customXAxisTitle: string;
  showXAxisTitle: boolean;
  showXAxisTickLabels: boolean;
  customYAxisTitle: string;
  showYAxisTitle: boolean;
  showYAxisTickLabels: boolean;
  assetNames: string[];
  step: number;
  fillType?: LineChartFillType;
  hoverMode?: LineChartHoverMode;
  lineTypeStrategy?: LineTypeStrategy;
  experimentsCount?: number;
  isStepsEditable?: boolean;
};
const CurvesChart = ({
  containerProps,
  isChartPreview = false,
  experiments,
  experimentKeys,
  hiddenExperimentKeys,
  isAutoRefreshEnabled,
  title,
  chartName = '',
  onChangeLegendMode,
  onDataSourceChange = noop,
  onFinishRender = noop,
  enableDataRevision = false,
  isPendingRendering = false,
  dataRevision = 0,
  revision = 0,
  selectedLegendKeys,
  legendMode = LEGEND_MODE.AUTO,
  hiddenMenuItems,
  chartClickEnabled = true,
  customRange = DEFAULT_CUSTOM_RANGE,
  customXAxisTitle = '',
  showXAxisTitle = false,
  showXAxisTickLabels = true,
  customYAxisTitle = '',
  showYAxisTitle = false,
  showYAxisTickLabels = true,
  lineTypeStrategy = LINE_TYPE_STRATEGY.AUTO as LineTypeStrategy,
  hoverMode = 'closest',
  experimentsCount,
  assetNames,
  step,
  isStepsEditable = true,
  fillType = CHART_CONFIG_MODIFIERS.LINE.value as LineChartFillType
}: CurvesChartProps) => {
  const chartType: PanelType = BUILT_IN_CHART_TYPES.curves as PanelType;
  const fill = get(CHART_CONFIG_MODIFIERS, [fillType, 'config'], {});
  const metricNames = assetNames;

  const isMultiMetricsChart = metricNames.length > 1;
  const showLegend = useMemo(() => {
    const multiMetricWithColorPerMetric =
      lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST && isMultiMetricsChart;

    if (legendMode === LEGEND_MODE.AUTO && multiMetricWithColorPerMetric) {
      return true;
    }

    return legendMode === LEGEND_MODE.ON;
  }, [isMultiMetricsChart, legendMode, lineTypeStrategy]);

  const { actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    title,
    chartName,
    chartType
  });

  const [localStep, setLocalStep] = useState(step);
  useEffect(() => {
    setLocalStep(step);
  }, [step]);

  const assets = useMemo(() => {
    return assetNames.map(name => ({
      name,
      step: localStep
    }));
  }, [assetNames, localStep]);

  const {
    data: { steps, traces },
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useCurvesPanelData({
    experimentKeys,
    assets
  });

  const rawDataSources = useDeepMemo(() => {
    if (isLoading) return [];
    const uniqueMap: Record<string, number> = {};

    const localDataSources = traces
      .filter(item => {
        return (
          item &&
          isArray(item.x) &&
          item.x.length &&
          isArray(item.y) &&
          item.y.length &&
          isString(item.name)
        );
      })
      .map(item => {
        if (!item) return item;

        const uniqueName = `${item.experiment_key}${item.name}`;

        if (isUndefined(uniqueMap[uniqueName])) {
          uniqueMap[uniqueName] = 0;
          return item;
        }

        uniqueMap[uniqueName] += 1;

        return {
          ...item,
          name: `${item.name}(${uniqueMap[uniqueName]})`
        };
      });

    if (isArray(hiddenExperimentKeys)) {
      return chartHelpers.applyVisibilityOfExperiments(
        localDataSources,
        hiddenExperimentKeys
      );
    } else {
      return localDataSources;
    }
  }, [isLoading, chartType, hiddenExperimentKeys, traces, experimentKeys]);

  useChartDataSourceChangeNotificator({
    isLoading,
    dataSources: rawDataSources,
    onDataSourceChange
  });

  const {
    delayedDataSources: dataSources,
    hasDelayedDataSources,
    isInitialRenderDelayed,
    calculatedRevision
  } = useChartDataRevision<DataSource[]>({
    enableDataRevision,
    isPendingRendering,
    dataRevision,
    dataSources: rawDataSources,
    revision
  });

  const {
    setExportData,
    setExportLayout,
    containerRef,
    handleExportJSON,
    handleExportData,
    handleResetZoom,
    disableResetZoom,
    chartBaseLayout,
    PlotlyProps
  } = usePlotlyBaseChart({
    revision: calculatedRevision,
    isChartPreview,
    onFinishRender,
    title: actualTitle,
    chartType
  });

  const { xAxisTitle, yAxisTitle } = useMemo(() => {
    return chartHelpers.calculateCurvesChartAxisTitles({
      showXAxisTitle,
      customXAxisTitle,
      showYAxisTitle,
      customYAxisTitle,
      assetNames
    });
  }, [
    showXAxisTitle,
    customXAxisTitle,
    showYAxisTitle,
    customYAxisTitle,
    assetNames
  ]);

  const experimentColorMap = useDeepMemo(
    () => getExperimentsColorMap(experiments),
    [experiments]
  );

  const legendKeysMap = useDeepMemo(() => {
    return getLegendsKeysMap(dataSources, experiments, selectedLegendKeys);
  }, [dataSources, experiments, selectedLegendKeys]);

  useEffect(() => {
    if (isError || isEmpty(dataSources)) {
      onFinishRender();
    }
  }, [dataSources, isError, isLoading, onFinishRender]);

  const {
    handleLinePointHover,
    handleLinePointBeforeHover,
    handleLinePointUnHover,
    tooltipData,
    clickableRef
  } = useCurvesChartTooltip();

  const { handleChartClick } = useChartTraceClick({
    hoveredPoint: tooltipData?.point,
    traceClickable: chartClickEnabled,
    traceClickableRef: clickableRef
  });

  const getDefaultCurvesChartConfig = useCallback(
    dataSource => {
      const { experiment_key: experimentKey, x, y } = dataSource;
      const metricName = dataSource.name;

      return {
        cometMetadataType:
          lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST
            ? 'experiment_metric'
            : 'metric',
        cometMetadata: {
          ...legendKeysMap[experimentKey],
          metricName
        },
        x,
        y,
        type: 'scattergl',
        mode: 'lines',
        name: `${experimentKey} ${metricName}`,
        legendgroup: metricName,
        metricName,
        ...fill
      };
    },
    [lineTypeStrategy, legendKeysMap, fill]
  );

  const getLineColor = useCallback(
    (dataSource, showMainColor = true) => {
      const { experiment_key, name } = dataSource;
      const index = parseInt(md5(name).slice(-8), 16);

      const defaultPrimary = CHART_COLORS[index % CHART_COLORS.length];
      const defaultLight =
        CHART_COLORS_LIGHT[index % CHART_COLORS_LIGHT.length];

      if (lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST) {
        return showMainColor ? defaultPrimary : defaultLight;
      }

      return showMainColor
        ? get(experimentColorMap, [experiment_key, 'primary'], defaultPrimary)
        : get(experimentColorMap, [experiment_key, 'light'], defaultLight);
    },
    [experimentColorMap, lineTypeStrategy]
  );

  // In order to keep the same dash type on changing the steps
  const lineDashByExperimentAndNameMap = useRef<Record<string, string>>({});
  const getLineDash = useCallback(
    ({ experiment_key, name }, lineDashIndexMap) => {
      if (lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST) {
        return LINE_DASH_OPTIONS[0];
      }

      const key = `${experiment_key}_${name}`;
      if (lineDashByExperimentAndNameMap.current[key]) {
        return lineDashByExperimentAndNameMap.current[key];
      }

      if (lineDashIndexMap.hasOwnProperty(experiment_key)) {
        lineDashIndexMap[experiment_key] += 1;
      } else {
        lineDashIndexMap[experiment_key] = 0;
      }

      const index = lineDashIndexMap[experiment_key];
      lineDashByExperimentAndNameMap.current[key] =
        LINE_DASH_OPTIONS[index % LINE_DASH_OPTIONS.length];

      return lineDashByExperimentAndNameMap.current[key];
    },
    [lineTypeStrategy]
  );

  const data = useMemo(() => {
    const lineDashIndexMap = {};

    return dataSources.map(dataSource => {
      return {
        ...getDefaultCurvesChartConfig(dataSource),
        showlegend: true,
        hoverinfo: 'name',
        line: {
          color: getLineColor(dataSource),
          dash: getLineDash(dataSource, lineDashIndexMap),
          width: LINE_CHART_LINE_WIDTH
        },
        visible: isVisibleOnChart(dataSource)
      } as PanelTrace;
    });
  }, [dataSources, getDefaultCurvesChartConfig, getLineColor, getLineDash]);

  const layout = useMemo(() => {
    return {
      ...chartBaseLayout,
      spikedistance: -1,
      hoverdistance: -1,
      hovermode: hoverMode,
      xaxis: {
        ...chartBaseLayout.xaxis,
        ...chartHelpers.generateAxisTitle(xAxisTitle),
        showticklabels: showXAxisTickLabels,
        showspikes: true,
        spikemode: 'across',
        spikedash: 'dot',
        spikecolor: '#8C95A8',
        spikethickness: 1
      },
      yaxis: {
        ...chartBaseLayout.yaxis,
        ...chartHelpers.generateAxisTitle(yAxisTitle),
        showticklabels: showYAxisTickLabels
      }
    };
  }, [
    chartBaseLayout,
    hoverMode,
    showXAxisTickLabels,
    showYAxisTickLabels,
    xAxisTitle,
    yAxisTitle
  ]);

  const downloadableLayout = useMemo(
    () => ({
      ...layout,
      margin: {
        ...layout.margin,
        b: 40,
        l: 40,
        r: 100,
        t: 16
      },
      showlegend: showLegend
    }),
    [layout, showLegend]
  );

  useEffect(() => {
    setExportLayout(downloadableLayout);
  }, [downloadableLayout, setExportLayout]);

  useEffect(() => {
    const guaranteeUniqueness = generateGuaranteeUniquenessFunction();

    const exportData = data
      .filter(trace => trace?.visible !== 'legendonly')
      .map(trace => {
        if (!(trace?.cometMetadata && trace?.showlegend !== false)) {
          return trace;
        }

        return {
          ...trace,
          name: guaranteeUniqueness(
            truncateLegendValueForExport(
              generateLegendLabelForLineChart(
                trace.cometMetadataType as PanelCometMetadataType,
                trace.cometMetadata,
                isMultiMetricsChart
              )
            )
          )
        };
      });

    setExportData(exportData as never);
  }, [data, isMultiMetricsChart, setExportData]);

  const {
    items,
    calculateIsItemHighlighted,
    onHoverItem,
    onUnhoverItem,
    dataWithHighlight
  } = useCurvesChartLegend({
    data: data,
    hoveredPoint: tooltipData?.point,
    isMultiMetricsChart,
    experimentKeys,
    metricNames
  });

  const renderStepsSlider = () => {
    if (!isStepsEditable) {
      return null;
    }

    const marks = steps.map(step => {
      return {
        label: step ?? 0,
        value: step ?? 0
      };
    });
    const minStep = Math.min(...marks.map(mark => mark.value));
    const maxStep = Math.max(...marks.map(mark => mark.value));

    return (
      <GridPanelSlider
        max={maxStep}
        min={minStep}
        marks={marks}
        step={null}
        onChange={setLocalStep}
        value={localStep}
        valueLabelDisplay="off"
      />
    );
  };

  return (
    <ChartContainer
      {...containerProps}
      isError={isError}
      isFetching={isFetching || hasDelayedDataSources}
      isLoading={isLoading || isInitialRenderDelayed}
      isPreviousData={isPreviousData}
      title={actualTitle}
      hasData={!isEmpty(dataSources)}
      hiddenMenuItems={hiddenMenuItems}
      onExportJSON={handleExportJSON}
      onExportData={handleExportData}
      onResetZoom={handleResetZoom}
      disableResetZoom={disableResetZoom}
      legendMode={legendMode}
      onChangeLegendMode={onChangeLegendMode}
      customHeaderContent={renderStepsSlider()}
    >
      <LegendWrapper
        legend={
          showLegend && (
            <Legend
              items={items}
              calculateIsItemHighlighted={calculateIsItemHighlighted}
              onHoverItem={onHoverItem}
              onUnhoverItem={onUnhoverItem}
            />
          )
        }
        chartContainerRef={containerRef as React.RefObject<HTMLDivElement>}
      >
        {tooltipData && (
          <CurvesChartTooltip
            tooltipData={tooltipData}
            chartData={data}
            groupsOrder={metricNames}
            experimentsCount={experimentsCount}
            highlightRelatedGroups={
              lineTypeStrategy === LINE_TYPE_STRATEGY.AUTO
            }
          />
        )}
        <OptimizedPlot
          {...PlotlyProps}
          customRange={customRange}
          data={dataWithHighlight}
          layout={layout}
          onClick={handleChartClick}
          onBeforeHover={handleLinePointBeforeHover}
          onHover={handleLinePointHover}
          onUnhover={handleLinePointUnHover}
        />
      </LegendWrapper>
    </ChartContainer>
  );
};

CurvesChart.CONFIG_PROPERTIES = [
  'containerProps',
  'isChartPreview',
  'experiments',
  'experimentKeys',
  'hiddenExperimentKeys',
  'isAutoRefreshEnabled',
  'title',
  'chartName',
  'onChangeLegendMode',
  'onDataSourceChange',
  'onFinishRender',
  'enableDataRevision',
  'isPendingRendering',
  'dataRevision',
  'revision',
  'selectedLegendKeys',
  'hiddenMenuItems',
  'chartClickEnabled',
  'customRange',
  'customXAxisTitle',
  'showXAxisTitle',
  'showXAxisTickLabels',
  'customYAxisTitle',
  'showYAxisTitle',
  'showYAxisTickLabels',
  'assetNames',
  'step',
  'fillType',
  'hoverMode',
  'lineTypeStrategy',
  'experimentsCount',
  'metricColorMap',
  'isStepsEditable',
  'legendMode'
];

export default CurvesChart;
