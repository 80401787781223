import queryString from 'query-string';
import get from 'lodash/get';
import useExperimentViewState from '@experiment-management-shared/hooks/useExperimentViewState';

const useExperimentParsedQueryConfig = ({
  location,
  experimentKey,
  currentTemplate,
  valuePathCategory
}) => {
  const parsedQuery = queryString.parse(location.search, {
    parseBooleans: true
  });

  const defaultSearch = get(parsedQuery, 'search', '');

  const [groupBy, setGroupBy] = useExperimentViewState({
    experimentKey,
    queryStringParameter: 'groupBy',
    template: currentTemplate,
    valuePath: `${valuePathCategory}.groupBy`
  });
  const [orderBy, setOrderBy] = useExperimentViewState({
    experimentKey,
    queryStringParameter: 'orderBy',
    template: currentTemplate,
    valuePath: `${valuePathCategory}.orderBy`
  });
  const [sortBy, setSortBy] = useExperimentViewState({
    experimentKey,
    queryStringParameter: 'sortBy',
    template: currentTemplate,
    valuePath: `${valuePathCategory}.sortBy`
  });

  return {
    defaultSearch,
    groupBy,
    setGroupBy,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy
  };
};

export default useExperimentParsedQueryConfig;
