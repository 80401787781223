import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import { Button } from '@ds';
import DsSyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter';
import InfoBox from '@design-system-outdated/components/InfoBox';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Table } from '@DesignSystem/tables';
import { parseMetadata } from '@artifacts/utils/helpers';
import { isValidHttpUrl } from '@/helpers/generalHelpers';

import styles from './ArtifactVersionSnowflakeTab.module.scss';

export const parseSample = sample => {
  let retVal = [];
  const sampleString = defaultTo(sample, '[]');

  try {
    retVal = JSON.parse(sampleString);
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return retVal;
};

const ArtifactVersionSnowflakeTab = ({ artifactVersion }) => {
  const { metadata } = artifactVersion;

  const parsedMetadata = useMemo(() => parseMetadata(metadata), [metadata]);
  const parsedSample = useMemo(() => parseSample(parsedMetadata.sample), [
    parsedMetadata.sample
  ]);

  const [columnWidths, setColumnWidths] = useState([]);

  const { columns, rows, noPaddingColumns } = useMemo(() => {
    const retVal = {
      columns: [],
      noPaddingColumns: [],
      rows: parsedSample
    };

    if (parsedSample.length > 0) {
      Object.keys(parsedSample[0]).forEach(key => {
        retVal.noPaddingColumns.push(key);

        retVal.columns.push({
          name: key,
          title: key,
          id: key,
          type: 'string',
          source: 'api'
        });
      });
    }

    return retVal;
  }, [parsedSample]);

  useEffect(() => {
    setColumnWidths(
      columns.map(({ name }) => ({
        columnName: name,
        width: 'auto'
      }))
    );
  }, [columns]);

  const onLinkClick = () => {
    window.open(parsedMetadata.snowflake_ui_url, '_blank');
  };

  if (parsedMetadata.error) {
    return (
      <div className={styles.errorWrapper}>
        <InfoBox
          title="There was an error recording this Snowflake Artifact"
          text={parsedMetadata.error}
        />
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>SQL Query</div>
          {isValidHttpUrl(parsedMetadata.snowflake_ui_url) && (
            <Button onClick={onLinkClick} type="primary">
              Open Snowflake UI
            </Button>
          )}
        </div>
        <div>
          <DsSyntaxHighlighter
            language="SQL"
            style={vs}
            withCopyBtn
            fullWidth
            code={parsedMetadata.query}
          >
            {parsedMetadata.query}
          </DsSyntaxHighlighter>
        </div>
      </div>
      {!!rows.length && (
        <div>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionTitle}>Sample data</div>
          </div>
          <div>
            <Table
              columns={columns}
              rows={rows}
              noPaddingColumns={noPaddingColumns}
              selectionConfig={{
                isDisabled: true
              }}
              paginationConfig={{
                isDisabled: true
              }}
              columnWidthsConfig={{
                isDisabled: false,
                columnWidths,
                onColumnWidthsChange: setColumnWidths
              }}
              columnOrderConfig={{
                isDisabled: true
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ArtifactVersionSnowflakeTab.propTypes = {
  artifactVersion: PropTypes.object.isRequired
};

export default ArtifactVersionSnowflakeTab;
