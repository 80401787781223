import useInvitesForTeam from '@API/workspace/useInvitesForTeam';
import useWorkspaceMembers from '@API/workspace/useWorkspaceMembers';
import { useMemo } from 'react';

const useWorkspaceAllMembers = workspaceId => {
  const { data: members = [] } = useWorkspaceMembers(workspaceId);
  const { data: invites = [] } = useInvitesForTeam(workspaceId);

  const allMembers = useMemo(
    () => [...members, ...invites.map(item => ({ ...item, isEmail: true }))],
    [members, invites]
  );
  const allMembersCount = useMemo(
    () => members?.length || 0 + invites?.length || 0,
    [members, invites]
  );

  return {
    members: allMembers,
    memberCount: allMembersCount
  };
};

export default useWorkspaceAllMembers;
