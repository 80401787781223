import { useMutation, useQueryClient } from 'react-query';

import api from '../api';

import useProject from './useProject';

const selectDefaultProject = ({ projectId, viewId }) => {
  if (!viewId) {
    return api.post('dashboard-templates/project/remove', {
      projectId
    });
  }

  return api.post('dashboard-templates/project/set', {
    project_id: projectId,
    template_id: viewId
  });
};

export default function useSelectDefaultProjectMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ viewId }) =>
      selectDefaultProject({ projectId: project?.projectId, viewId }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projectViews', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['projectViews', { projectId }]);
      }
    }
  );
}
