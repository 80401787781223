import isUndefined from 'lodash/isUndefined';
import { useQuery } from 'react-query';

import { useExperimentKeys } from '@experiment-management-shared/hooks';

import hyperParamsApi from './API/hyperParamsApi';
import { QueryConfig } from '@shared/api';
import { HyperParam } from '@shared/types/hyperParam';

export type ExperimentParamsResponse = {
  data: {
    experimentKey: string;
    results: HyperParam[];
    version: string;
  };
};

export type UseExperimentParamsConfig<T> = QueryConfig<
  ExperimentParamsResponse[],
  T
>;

const useExperimentParams = <T extends object>(
  config: UseExperimentParamsConfig<T>
) => {
  const experiments = useExperimentKeys();

  const enabled = !!(isUndefined(config?.enabled)
    ? experiments?.length
    : config?.enabled && experiments?.length);

  return useQuery(
    ['experiment-params', { experiments }],
    async ({ signal }) => {
      const respPromises = experiments.map(experiment =>
        hyperParamsApi.fetchHyperParams({ experimentKey: experiment, signal })
      );

      const responses = await Promise.all(respPromises);

      // add an experiment key
      const extendedResponses = responses.map((response, idx) => ({
        data: {
          ...response.data,
          experimentKey: experiments[idx]
        }
      }));

      return extendedResponses;
    },
    {
      ...config,
      enabled
    }
  );
};

export default useExperimentParams;
