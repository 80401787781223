import ReportPage from '@reports/components/ReportPage';
import ReportsPage from '@reports/components/ReportsPage';
import {
  REPORTS_TABLE,
  REPORT_EDIT,
  REPORT_TEMPLATE_EDIT,
  REPORT_TEMPLATE_VIEW,
  REPORT_VIEW
} from '@routes/constants/reports';
import React from 'react';
import { Route, Switch } from 'react-router';
import ProjectTypePageSelector from '@shared/components/ProjectTypePageSelector';
import ProjectPageWrapper from '@shared/components/ProjectPageWrapper';
import EMProjectPageHeader from '@shared/components/EMProjectPageHeader';

const ReportsRoutes = () => {
  return (
    <Switch>
      <Route
        path={REPORTS_TABLE}
        exact
        render={() => (
          <ProjectTypePageSelector
            EMComponent={
              <ProjectPageWrapper
                header={<EMProjectPageHeader />}
                content={<ReportsPage />}
              />
            }
          />
        )}
      />
      <Route
        path={REPORT_TEMPLATE_VIEW}
        exact
        render={() => <ReportPage isTemplate />}
      />
      <Route
        path={REPORT_TEMPLATE_EDIT}
        exact
        render={() => <ReportPage isTemplate />}
      />
      <Route path={REPORT_VIEW} exact component={ReportPage} />
      <Route path={REPORT_EDIT} exact component={ReportPage} />
    </Switch>
  );
};

export default ReportsRoutes;
