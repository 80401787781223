export const KEYWORDS = [
  'SELECT',
  'FROM',
  'MODEL',
  'WHERE',
  'CASE',
  'WHEN',
  'THEN',
  'ELSE',
  'END',
  'IS',
  'NOT',
  'NULL',
  'FILTER'
] as const;
export const MATH_OPERATORS = ['+', '-', '*', '/'] as const;
export const WHERE_OPERATORS = ['=', '!=', '<', '<=', '>', '>=', '<>'] as const;
export const MULTIPLE_WHERE_OPERATORS = ['OR', 'AND'] as const;
export const NUMERIC_ONE_ARGUMENT_FUNCTIONS = [
  'APPROX_COUNT_DISTINCT',
  'AVG',
  'COUNT',
  'MAX',
  'MIN',
  'STDDEV_POP',
  'STDDEV_SAMP',
  'SUM',
  'VAR_POP',
  'VAR_SAMP'
] as const;
export const CATEGORICAL_COMET_FUNCTIONS = [
  'F1',
  'FNR',
  'FPR',
  'LOG_LOSS',
  'PRECISION',
  'RECALL',
  'TNR',
  'TPR'
] as const;
export const NUMERICAL_COMET_FUNCTIONS = [
  'ACCURACY',
  'MAE',
  'MAPE',
  'MAX_ERROR',
  // 'R2SCORE',
  'RMSE'
] as const;
export const NUMERIC_ONE_ARGUMENT_OPERATORS = [
  'ABS',
  'CEIL',
  'FLOOR',
  'EXP',
  'LN',
  'LOG10',
  'SQRT'
] as const;
export const NUMERIC_MANY_ARGUMENTS_OPERATORS = ['GREATEST', 'LEAST'] as const;

export type CometSQLColumnType = 'feature' | 'label' | 'prediction';
export const COLUMN_PREFIXES: Record<CometSQLColumnType, string> = {
  feature: 'feature_',
  label: 'label_',
  prediction: 'prediction_'
};
