import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@DesignSystem/modals';

import RequestStatusAlreadyExist from '@model-registry/components/ModelVersionRequestStatusModal/RequestStatusAlreadyExist';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { PENDING_REQUESTS_TAB_ID } from '@model-registry/constants';
import { OPEN_MODAL_ORIGIN } from '@model-registry/constants/request-status';
import { ORGANIZATIONS_SETTINGS } from '@shared/constants/organization';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import useOrganizationSettingByName from '@shared/api/useOrganizationSettingByName';
import RequestStatusForm from '@model-registry/components/ModelVersionRequestStatusModal/RequestStatusForm';
import { trackEvent } from '@shared/utils/eventTrack';
import { useDispatch } from 'react-redux';
import { SUB_PATHS } from '../../../../constants/urlConstants';
import { useCurrentOrganization } from '@shared/hooks';

import classNames from './ModelVersionRequestStatusModal.module.scss';
import { modelRegistryEvents } from '@/constants/trackingEventTypes';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import ModelStatusChangeConfirmation from './ModelStatusChangeConfirmation';

const getPrimaryButtonText = ({ requestedStatus }) => {
  if (requestedStatus) {
    return 'Go to the pending requests';
  }

  return 'Submit for review';
};

const ModelVersionRequestStatusModal = ({
  open,
  onClose,
  onUpdate,
  onUpdateMutation,
  status,
  requestedStatus,
  ifCurrentUserWsOwner,
  workspace,
  modelName,
  selectedVersion,
  openOrigin
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [changedStatus, setChangedStatus] = useState(status);
  const [statusOptions, setStatusOptions] = useState([]);
  const currentOrganization = useCurrentOrganization();

  const {
    data: organizationStatuses,
    isLoading: isOrganizationStatusesLoading
  } = useOrganizationSettingByName(currentOrganization?.id, {
    settingName: ORGANIZATIONS_SETTINGS.MODEL_ITEM_SUPPORTED_STATUSES
  });

  const primaryButtonText = useMemo(
    () =>
      getPrimaryButtonText({
        requestedStatus
      }),
    [requestedStatus]
  );
  const isPrimaryButtonEnabled = useMemo(() => {
    if (!ifCurrentUserWsOwner) {
      return (!requestedStatus && changedStatus !== status) || requestedStatus;
    }

    if (requestedStatus) return !onUpdateMutation?.isLoading;

    return changedStatus !== status && !onUpdateMutation?.isLoading;
  }, [ifCurrentUserWsOwner, changedStatus, status, requestedStatus]);
  const onPrimaryButtonClick = useCallback(() => {
    if (requestedStatus) {
      onClose();
      const query = queryString.stringify({
        tab: PENDING_REQUESTS_TAB_ID
      });
      history.push(
        `/${workspace}/${SUB_PATHS.MODEL_REGISTRY}/${modelName}?${query}`
      );
      return;
    }

    onUpdate({
      status: changedStatus,
      comment,
      isOwner: ifCurrentUserWsOwner,
      isReviewRequired: ifCurrentUserWsOwner
    });
    trackEvent(modelRegistryEvents.STATUS_MODEL_SUBMITTED);
    onClose();
  }, [requestedStatus, ifCurrentUserWsOwner, changedStatus, comment]);

  const isSecondaryButtonEnabled = useMemo(() => {
    if (!ifCurrentUserWsOwner && !requestedStatus) return false;
    if (!ifCurrentUserWsOwner && requestedStatus) return true;

    if (requestedStatus) return !onUpdateMutation?.isLoading;

    return changedStatus !== status && !onUpdateMutation?.isLoading;
  }, [
    ifCurrentUserWsOwner,
    changedStatus,
    status,
    requestedStatus,
    onUpdateMutation
  ]);
  const onSecondaryButtonClick = useCallback(() => {
    // if OWNER click on secondary button and there is no pending request on him for this model,
    // then we need to just update status without approval flow
    if (ifCurrentUserWsOwner && !requestedStatus) {
      dispatch(
        alertsUtil.openCustomModal(
          dialogTypes.CONFIRM_STATUS_CHANGE,
          <ModelStatusChangeConfirmation
            onClose={() => {
              dispatch(
                alertsUtil.closeDialog(dialogTypes.CONFIRM_STATUS_CHANGE)
              );
            }}
            onPrimaryButtonClick={() => {
              onUpdate({
                status: changedStatus,
                comment,
                isOwner: ifCurrentUserWsOwner
              });
              trackEvent(modelRegistryEvents.STATUS_MODEL_SUBMITTED);

              dispatch(
                alertsUtil.closeDialog(dialogTypes.CONFIRM_STATUS_CHANGE)
              );
            }}
            isPrimaryButtonDisabled={false}
            additionalClasses={{
              paper: classNames.modalVersionRequestStatusConfirmationModal
            }}
          />
        )
      );
    }

    return onClose();
  }, [ifCurrentUserWsOwner, changedStatus, requestedStatus, comment, onClose]);
  const secondaryButtonTooltip = useMemo(
    () => ({
      visible: !ifCurrentUserWsOwner && !requestedStatus,
      text: (
        <div>
          Only owners can skip <br />
          the review process
        </div>
      ),
      position: 'left'
    }),
    [requestedStatus, ifCurrentUserWsOwner]
  );

  useEffect(() => {
    if (!isOrganizationStatusesLoading && organizationStatuses?.length) {
      setStatusOptions(organizationStatuses.map(formatValueForSelectOption));
    }
  }, [organizationStatuses, isOrganizationStatusesLoading]);

  useEffect(() => {
    if (!open) {
      setComment('');
      setChangedStatus(null);
    }

    if (open) {
      trackEvent(modelRegistryEvents.STATUS_MODEL_OPEN, {
        model_version: selectedVersion?.version,
        experiment_model_id:
          selectedVersion?.experimentModel?.experimentModelId,
        registry_model_id: selectedVersion?.registryModelItemId,
        registry_model_name: modelName,
        open_origin: openOrigin
      });
    }
  }, [open]);

  useEffect(() => {
    if (status && !changedStatus) {
      setChangedStatus(status);
    }
  }, [status]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      additionalClasses={{
        paper: classNames.modalVersionRequestStatusModal
      }}
      title="Change status"
      type="modal-version-request-status-modal"
      dataTest="modal-version-request-status-modal"
      primaryButtonText={primaryButtonText}
      secondaryButtonText={
        requestedStatus ? 'Cancel' : 'Skip review and change status'
      }
      secondaryButtonTooltip={secondaryButtonTooltip}
      onSecondaryButtonClick={onSecondaryButtonClick}
      isSecondaryButtonDisabled={!isSecondaryButtonEnabled}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isPrimaryButtonDisabled={!isPrimaryButtonEnabled}
      content={
        requestedStatus ? (
          <RequestStatusAlreadyExist
            status={status}
            requestedStatus={requestedStatus}
          />
        ) : (
          <RequestStatusForm
            status={status}
            comment={comment}
            setChangedStatus={setChangedStatus}
            setComment={setComment}
            ifCurrentUserWsOwner={ifCurrentUserWsOwner}
            statusOptions={statusOptions}
          />
        )
      }
    />
  );
};

ModelVersionRequestStatusModal.defaultProps = {
  status: null,
  requestedStatus: null
};

ModelVersionRequestStatusModal.propTypes = {
  openOrigin: PropTypes.oneOf([
    OPEN_MODAL_ORIGIN.table,
    OPEN_MODAL_ORIGIN.side_menu,
    OPEN_MODAL_ORIGIN.model_version
  ]).isRequired,
  ifCurrentUserWsOwner: PropTypes.bool.isRequired,
  selectedVersion: PropTypes.shape({
    version: PropTypes.string.isRequired,
    registryModelItemId: PropTypes.string.isRequired,
    experimentModel: PropTypes.shape({
      experimentModelId: PropTypes.string.isRequired
    })
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateMutation: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired
  }).isRequired,
  status: PropTypes.string,
  requestedStatus: PropTypes.string,
  workspace: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired
};

export default ModelVersionRequestStatusModal;
