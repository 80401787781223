import { OptionType } from '@design-system/types';
import React from 'react';

import { components, MultiValueGenericProps } from 'react-select';

const MultiValueContainer = (props: MultiValueGenericProps<OptionType>) => {
  return <components.MultiValueContainer {...props} />;
};

export default MultiValueContainer;
