// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardButton-module__experimentButton--qsjp1{--height: 28px;--subLabelSize: 11px;display:flex;justify-content:center;align-items:center;position:relative;background:var(--gray-1);height:var(--height);padding:0 10px;border-radius:3px;color:var(--gray-6);font-style:normal;font-weight:400;font-size:14px;line-height:21px}.DashboardButton-module__experimentButton--qsjp1 svg{margin-right:8px}.DashboardButton-module__experimentButton--qsjp1.DashboardButton-module__noLabel--rL92h{padding:0 6px}.DashboardButton-module__experimentButton--qsjp1.DashboardButton-module__noLabel--rL92h svg{margin:0 2px}.DashboardButton-module__experimentButton--qsjp1.DashboardButton-module__hasSubLabel--yfEL5{background:var(--gray-3)}.DashboardButton-module__experimentButton--qsjp1[disabled],.DashboardButton-module__experimentButton--qsjp1[disabled]:hover,.DashboardButton-module__experimentButton--qsjp1[disabled]:active{opacity:.5;background:var(--gray-1);color:var(--gray-6)}.DashboardButton-module__experimentButton--qsjp1.DashboardButton-module__open--glvGT,.DashboardButton-module__experimentButton--qsjp1:hover,.DashboardButton-module__experimentButton--qsjp1:active{color:var(--gray-7);background:var(--gray-4)}.DashboardButton-module__experimentButton--qsjp1 .DashboardButton-module__subLabel--m9y8h{position:absolute;top:0;right:0;transform:translate(50%, -50%);display:flex;align-items:center;justify-content:center;border-radius:var(--subLabelSize);background:var(--gray-1);font-size:10px;line-height:var(--subLabelSize);padding:0 2px;height:var(--subLabelSize);min-width:var(--subLabelSize)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentButton": `DashboardButton-module__experimentButton--qsjp1`,
	"noLabel": `DashboardButton-module__noLabel--rL92h`,
	"hasSubLabel": `DashboardButton-module__hasSubLabel--yfEL5`,
	"open": `DashboardButton-module__open--glvGT`,
	"subLabel": `DashboardButton-module__subLabel--m9y8h`
};
module.exports = ___CSS_LOADER_EXPORT___;
