import React from 'react';
import { OptionType } from '@design-system/types';
import { ContainerProps, components } from 'react-select';

const SelectContainer = ({
  children,
  ...props
}: ContainerProps<OptionType>) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-test': props.selectProps['data-test']
      })}
    >
      {children}
    </components.SelectContainer>
  );
};

export default SelectContainer;
