import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useProjectParams from '@API/project/useProjectParams';
import TextField from '@material-ui/core/TextField';
import { IconButton, Tooltip, LinkButton } from '@ds';
import { ExperimentCircleColorPicker } from '@experiment-management-shared/components';

import useCanEditProject from '@API/project/useCanEditProject';
import useSetExperimentNameMutation from '@API/experiments/useSetExperimentNameMutation';
import { experimentActionTypes } from '@/constants/actionTypes';
import { getExperimentUrl } from '@shared/utils/url';
import { getIsArchivePage } from '@/selectors/routeSelectors';
import { DSCheckMarkIcon, DSCloseIcon, DSEditIcon } from '@ds-icons';

const EditExperimentNameField = ({ row }) => {
  const { projectName, workspace } = useProjectParams();
  const dispatch = useDispatch();
  const isArchive = useSelector(getIsArchivePage);
  const { pathname } = useLocation();

  const experimentUrl = getExperimentUrl({
    isArchive,
    projectName,
    experimentKey: row?.experimentKey,
    workspace
  });

  const experimentPageOpened = pathname === experimentUrl;

  const { data: canEdit } = useCanEditProject();
  const { Name = '', experimentKey } = row;

  const [isEditMode, setIsEditMode] = useState(false);
  const [experimentName, setExperimentName] = useState(String(Name));

  const { mutate } = useSetExperimentNameMutation();

  const handleStartEditMode = () => {
    setIsEditMode(true);
  };

  const handleStopEditMode = () => {
    setIsEditMode(false);
  };

  const handleChange = event => {
    setExperimentName(event.target.value);
  };

  const handleUpdateExperimentName = useCallback(() => {
    const isExperimentNameEdited = isEditMode && experimentName !== Name;
    if (isExperimentNameEdited) {
      mutate({
        experimentKey,
        name: experimentName
      });

      // Compatibility with the experiment name
      dispatch({
        type: experimentActionTypes.SET_EXPERIMENT_NAME,
        payload: {
          experimentKey,
          experimentName
        }
      });
    }

    handleStopEditMode();
  }, [experimentKey, dispatch, isEditMode, experimentName, Name, mutate]);

  const handleKeyDown = event => {
    if (event.key === 'Shift') {
      event.stopPropagation();
    }

    if (event.key === 'Enter') {
      handleUpdateExperimentName();
    }
  };

  const renderTextField = () => {
    return (
      <TextField
        classes={{ root: 'edit-experiment-name-textfield' }}
        value={experimentName}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fullWidth
      />
    );
  };

  const renderEditModeButtons = () => {
    return (
      <div className="flex">
        <IconButton
          Icon={<DSCheckMarkIcon />}
          size="small"
          onClick={handleUpdateExperimentName}
        />
        <IconButton
          Icon={<DSCloseIcon />}
          size="small"
          type="secondary"
          onClick={handleStopEditMode}
        />
      </div>
    );
  };

  const renderViewFields = () => {
    return (
      <>
        <div className="name-and-color">
          <ExperimentCircleColorPicker experiment={row} />
          {experimentName}
          {canEdit && (
            <Tooltip placement="top" content="Edit name">
              <IconButton
                type="secondary"
                Icon={<DSEditIcon />}
                onClick={handleStartEditMode}
              />
            </Tooltip>
          )}
        </div>
        {!experimentPageOpened && (
          <LinkButton
            to={experimentUrl}
            text="Open Experiment"
            openInNewTab
            external
          />
        )}
      </>
    );
  };

  const renderEditFields = () => {
    return (
      <>
        {renderTextField()}
        {renderEditModeButtons()}
      </>
    );
  };

  return (
    <div className="edit-experiment-name-field">
      <div className="mode-fields-container">
        {isEditMode ? renderEditFields() : renderViewFields()}
      </div>
    </div>
  );
};

EditExperimentNameField.propTypes = {
  row: PropTypes.object.isRequired
};

export default EditExperimentNameField;
