import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import {
  DEFAULT_CHART_TEMPLATE_NAME,
  EMPTY_CHART_TEMPLATE_NAME
} from '@experiment-management-shared/constants/chartConstants';
import TemplateSelect, {
  BY_COMET_LABEL
} from '@experiment-management-shared/components/TemplateSelect/TemplateSelect';
import chartActions from '@/actions/experimentDetails/chartActionsPlotly';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { experimentEvents } from '@/constants/trackingEventTypes';
import { getProjectsMap } from '@/reducers/projectsReducer';

const sortTemplates = (template1, template2) => {
  return template1.template_name.localeCompare(template2.template_name);
};

const sortTemplateList = (templates = []) => {
  const userSavedTemplatesSorted = templates
    .filter(template => {
      return chartHelpers.isUserSavedTemplate(template.template_id);
    })
    .sort(sortTemplates);

  const staticTemplates = templates
    .filter(template => {
      return !chartHelpers.isUserSavedTemplate(template.template_id);
    })
    .sort(sortTemplates);

  return [...staticTemplates, ...userSavedTemplatesSorted];
};

const ExperimentDetailsSelectView = ({
  templates: userTemplates,
  experimentKey,
  defaultTemplateName,
  currentTemplateProjectId,
  currentTemplateId,
  currentTemplateName,
  onDiscardChanges,
  onSaveNewTemplate,
  onUpdateTemplateName,
  hasUnsavedChanges,
  onUpdateTemplate
}) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjectsMap);
  const baseTrackEvent = useBaseTrackEvent();

  const onDiscardSendBI = () => {
    baseTrackEvent(
      experimentEvents.SINGLE_EXPERIMENT_PAGE_DISCARD_CHANGED_VIEW
    );
  };

  const templates = useMemo(() => sortTemplateList(userTemplates), [
    userTemplates
  ]);

  const getProjectDefaultTemplate = useCallback(
    template => {
      const projectId = get(template, 'project_id');

      const { projectName } = projects[projectId] || '';

      if (template.template_name === EMPTY_CHART_TEMPLATE_NAME) {
        return projectName;
      }

      if (template.template_name === DEFAULT_CHART_TEMPLATE_NAME) {
        return BY_COMET_LABEL;
      }

      return projectName;
    },
    [projects]
  );

  const handleTemplateChange = useCallback(
    template => {
      dispatch(
        chartActions.setCurrentTemplateAndMigrateCharts(template, experimentKey)
      );
    },
    [experimentKey, dispatch]
  );

  const handleDiscardChanges = useCallback(() => {
    onDiscardSendBI();
    onDiscardChanges();
  }, [onDiscardChanges]);

  const handleSetProjectTemplate = useCallback(
    (template, withoutNotification = false) => {
      dispatch(
        chartActions.setProjectTemplate(
          template,
          experimentKey,
          withoutNotification
        )
      );
      handleTemplateChange(template);
    },
    [dispatch, experimentKey, handleTemplateChange]
  );

  const handleDeleteTemplate = useCallback(
    template => {
      const isDeletingTemplateInView =
        currentTemplateName === template.template_name;

      if (isDeletingTemplateInView) {
        const defaultTemplate = templates.find(
          temp => temp.template_name === DEFAULT_CHART_TEMPLATE_NAME
        );

        handleSetProjectTemplate(defaultTemplate, true);

        dispatch(
          chartActions.setCurrentTemplateAndMigrateCharts(
            defaultTemplate,
            experimentKey
          )
        );
      }

      dispatch(chartActions.deleteTemplate(template, experimentKey));
    },
    [
      dispatch,
      experimentKey,
      currentTemplateName,
      templates,
      handleSetProjectTemplate,
      userTemplates
    ]
  );

  const handleDiscardNewView = useCallback(
    template => {
      onDiscardSendBI();
      handleTemplateChange(template);
    },
    [handleTemplateChange]
  );

  const handleSaveUnsavedView = useCallback(
    ({ asDefault, newViewName, viewToGo, isSaveNewView }) => {
      if (isSaveNewView) {
        const isCreated = onSaveNewTemplate(newViewName, asDefault, true);

        if (isCreated) {
          handleTemplateChange(viewToGo);
        }
      } else {
        onUpdateTemplate();
        handleTemplateChange(viewToGo);
      }
    },
    [onSaveNewTemplate, handleTemplateChange, onUpdateTemplate]
  );

  const onChangeTemplate = template => {
    handleTemplateChange(template);

    baseTrackEvent(experimentEvents.SINGLE_EXPERIMENT_PAGE_SWITCHED_VIEW, {
      before_view_name: currentTemplateName,
      after_view_name: template?.template_name
    });
  };

  return (
    <TemplateSelect
      templates={templates}
      defaultTemplateName={defaultTemplateName}
      currentTemplateId={currentTemplateId}
      currentTemplateName={currentTemplateName}
      currentTemplateProjectId={currentTemplateProjectId}
      hasUnsavedChanges={hasUnsavedChanges}
      disabledDischardChanges={!hasUnsavedChanges}
      onChangeTemplate={onChangeTemplate}
      onChangeDefaultTemplate={handleSetProjectTemplate}
      onDeleteTemplate={handleDeleteTemplate}
      onDiscardChanges={handleDiscardChanges}
      generateSecondaryLabel={getProjectDefaultTemplate}
      onDiscardNewView={handleDiscardNewView}
      onSaveUnsavedView={handleSaveUnsavedView}
      onEditName={onUpdateTemplateName}
    />
  );
};

ExperimentDetailsSelectView.propTypes = {
  templates: PropTypes.array.isRequired,
  experimentKey: PropTypes.string.isRequired,
  defaultTemplateName: PropTypes.string.isRequired,
  currentTemplateProjectId: PropTypes.string.isRequired,
  currentTemplateId: PropTypes.string.isRequired,
  currentTemplateName: PropTypes.string.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
  onSaveNewTemplate: PropTypes.func.isRequired,
  onUpdateTemplateName: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  onUpdateTemplate: PropTypes.func.isRequired
};

export default ExperimentDetailsSelectView;
