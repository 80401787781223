import React from 'react';
import styles from './Canvas.module.scss';

interface BoundingBox {
  size: [number, number, number, number];
  color: string;
}

interface BoundingPolygon {
  points: number[];
  color: string;
}

type BoxProps = {
  box: BoundingBox;
  boxLineWidth: number;
};
const Box: React.FC<BoxProps> = ({ box, boxLineWidth }) => {
  const [x, y, w, h] = box.size;
  const borderColor = `#${box.color}`;

  return (
    <rect
      x={x}
      y={y}
      width={w}
      height={h}
      stroke={borderColor}
      strokeWidth={boxLineWidth}
      fill="none"
    />
  );
};

type PolygonProps = {
  polygon: BoundingPolygon;
};
const Polygon: React.FC<PolygonProps> = ({ polygon }) => {
  const pointsString = polygon.points.join(',');
  const fillColor = `#${polygon.color}`;

  return <polygon points={pointsString} fill={fillColor} fillOpacity="0.6" />;
};

type SvgCanvasProps = {
  boxes: BoundingBox[];
  polygons: BoundingPolygon[];
  boxLineWidth: number;
  width: number;
  height: number;
};
export const SvgCanvas: React.FC<SvgCanvasProps> = ({
  boxes,
  polygons,
  boxLineWidth,
  width,
  height
}) => {
  if (!boxes && !polygons) return null;

  return (
    <svg className={styles.canvas} viewBox={`0 0 ${width} ${height}`}>
      {boxes.map(box => (
        <Box
          key={`rect-${box.size[0]}-${box.size[1]}`}
          boxLineWidth={boxLineWidth}
          box={box}
        />
      ))}
      {polygons.map(polygon => (
        <Polygon
          key={`polygon-${polygon.points.join(',')}`}
          polygon={polygon}
        />
      ))}
    </svg>
  );
};
