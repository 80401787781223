import { useMutation, useQueryClient } from 'react-query';

import useProject from '@API/project/useProject';
import api from '@shared/api';
import { AxiosError } from 'axios';

type GetShareCode = {
  projectId: string;
};

type CreateShareCodeAPI = {
  shareCode: string;
};

const createShareCode = async ({ projectId }: GetShareCode) => {
  const {
    data: { shareCode }
  } = await api.get<CreateShareCodeAPI>('share-project/add-share-link', {
    params: { projectId }
  });

  return shareCode;
};

export function useCreateShareCodeMutation() {
  const queryClient = useQueryClient();
  const { data: project } = useProject();
  const projectId = project?.projectId;

  return useMutation<string, AxiosError>(
    () => {
      if (!projectId) {
        throw new Error('Project ID is empty');
      }

      return createShareCode({ projectId });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['share-code', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['share-code', { projectId }]);
      }
    }
  );
}
