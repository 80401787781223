let count = 0;

const alertsHelper = {
  getKeyProps: (first, second, dispatch, closeAction) => {
    let key;
    let propsOrGetProps;

    // Define key and pogp
    if (typeof first === 'string') {
      key = first;
      propsOrGetProps = second;
    } else {
      count += 1;
      key = `Snackbar_${count}`;
      propsOrGetProps = first;
    }

    const close = () => {
      dispatch(closeAction(key));
    };

    let props =
      typeof propsOrGetProps === 'function'
        ? propsOrGetProps(close, key)
        : propsOrGetProps;

    if (!props) {
      props = {};
    }

    // Set default props
    props.timestamp = Date.now();
    props.open = true;

    // onClose monkey patch
    const originalOnRequestClose = props.onClose;
    props.onClose = buttonClicked => {
      close();
      if (originalOnRequestClose) originalOnRequestClose(buttonClicked);
    };

    return { key, props };
  }
};

export default alertsHelper;
