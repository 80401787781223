import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const TruncatedCell = ({ children, className, style, ...sizes }) => (
  <Box component={Grid} item {...sizes} style={style}>
    <span className={cx('truncated-string', className)}>{children}</span>
  </Box>
);

TruncatedCell.defaultProps = {
  style: {},
  className: '',
  md: null,
  children: null
};

TruncatedCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  xs: PropTypes.number.isRequired,
  md: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object
};

export default TruncatedCell;
