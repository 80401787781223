import { useQuery } from 'react-query';
import workspaceApi from '@/util/workspaceApi';
import { OrganizationWorkspace, ServerTeam } from '@shared/types';

export const mapWorkspaces = (
  workspace: ServerTeam
): OrganizationWorkspace => ({
  id: workspace?.team?.teamId,
  teamId: workspace.teamId,
  name: workspace.teamName,
  projectsCount: workspace.project_count,
  membersCount: workspace.totalMembers,
  createdBy: workspace?.createdBy || workspace?.team?.createdBy,
  createdAt: workspace?.createdAt || workspace?.team?.created_at,
  organizationId: workspace?.team?.organizationId,
  collaborationFeaturesDisabled: workspace.collaborationFeaturesDisabled
});

interface OrganizationWorkspacesByOrganizationId {
  enabled?: boolean;
}

const getOrganizationWorkspacesByOrganizationId = async (
  organizationId: string
): Promise<ServerTeam[]> => {
  const result = await workspaceApi.fetchAllWorkspacesForUserByOrganizationId(
    organizationId
  );

  return result?.data;
};

//  all the workspaces of a current user by org id
export default function useAllUserWorkspacesByOrganizationId(
  organizationId: string,
  config: OrganizationWorkspacesByOrganizationId
) {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['workspaces', 'all', 'byOrganizationId'],
    async () => {
      const workspaces = await getOrganizationWorkspacesByOrganizationId(
        organizationId
      );

      return workspaces.map(mapWorkspaces);
    },
    {
      enabled: configEnabled && !!organizationId
    }
  );
}
