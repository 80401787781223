import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FilterContainer from '@shared/components/FilterContainer';

const FilterPopover = ({
  className,
  selectedFilters,
  filter,
  handleFilterChange,
  disabled
}) => {
  const { groupLabel, filterKey, options } = filter;

  const isFilterChecked = (_, optionValue) => {
    return selectedFilters.includes(optionValue);
  };

  const handleFilterSelect = optionValue => {
    let newSelectedValues;
    if (isFilterChecked(filterKey, optionValue)) {
      newSelectedValues = selectedFilters.filter(
        value => value !== optionValue
      );
    } else {
      newSelectedValues = [...selectedFilters, optionValue];
    }

    handleFilterChange(filterKey, newSelectedValues);
  };

  const handleClearAll = () => {
    handleFilterChange(filterKey, []);
  };

  const renderOptions = () => {
    if (isEmpty(options)) {
      return (
        <ListItem className="filter-list-item">
          <ListItemText>No Options</ListItemText>
        </ListItem>
      );
    }
    return options.map(({ label, value }) => {
      return (
        <ListItem key={value} className="filter-list-item">
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFilterChecked(filterKey, value)}
                  onChange={() => handleFilterSelect(value)}
                  color="primary"
                />
              }
              label={label}
            />
          </FormGroup>
        </ListItem>
      );
    });
  };

  const selectedLabel = selectedFilters.length
    ? `(${selectedFilters.length})`
    : 'All';

  return (
    <FilterContainer
      className={className}
      groupLabel={groupLabel}
      selectedLabel={selectedLabel}
      resetSelectionHandler={handleClearAll}
      disabled={disabled}
    >
      <List className="project-list-sort-list">{renderOptions()}</List>
    </FilterContainer>
  );
};

FilterPopover.defaultProps = {
  className: ''
};

FilterPopover.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

export default FilterPopover;
