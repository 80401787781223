import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { getActiveWorkspaceName } from '@/reducers/ui/workspaceUiReducer';

const GuestOnlyRoute = ({
  activeWorkspaceName,
  isUserLoggedIn,
  redirectTo,
  ...routeProps
}) => {
  if (isUserLoggedIn) {
    const workspacePath = `/${activeWorkspaceName}${WORKSPACE_URL_HASHES.PROJECTS}`;
    const path = redirectTo || workspacePath;

    return <Redirect to={path} />;
  }

  return <Route {...routeProps} />;
};

GuestOnlyRoute.defaultProps = {
  redirectTo: '/'
};

GuestOnlyRoute.propTypes = {
  activeWorkspaceName: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string
};

const mapStateToProps = state => ({
  activeWorkspaceName: getActiveWorkspaceName(state),
  isUserLoggedIn: getIsUserLoggedIn(state)
});

export default connect(mapStateToProps)(GuestOnlyRoute);
