import PropTypes from 'prop-types';

export const IConfusionMatrixData = PropTypes.shape({
  version: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  rowLabel: PropTypes.string.isRequired,
  columnLabel: PropTypes.string.isRequired,
  type: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  matrix: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired
});
