import React from 'react';
import MuiSlider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/styles';

export const StyledSlider = React.memo(
  withStyles({
    root: {
      color: '#b3b9c8'
    },
    rail: {
      height: '8px',
      marginTop: '-2px',
      borderRadius: '4px',
      width: '100.5%'
    },
    track: {
      color: '#b3b9c8'
    },
    thumb: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: '#fff',
      border: '1px solid #b3b9c8',
      boxSizing: 'border-box',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
      top: '10px'
    },
    mark: {
      backgroundColor: '#b3b9c8',
      width: '4px',
      height: '4px',
      borderRadius: '50%'
    }
  })(MuiSlider)
);
