import React from 'react';
import PropTypes from 'prop-types';

import { BasicModal } from '@DesignSystem/modals';
import classNames from './GraphicsTabConfirmationDialog.module.scss';

const GraphicsTabConfirmationDialog = ({
  onPrimaryButtonClick,
  onClose,
  onSecondaryButtonClick,
  open,
  dataTest
}) => {
  return (
    <BasicModal
      open={open}
      additionalClasses={{
        paper: classNames.graphicsTabConfirmationDialog
      }}
      onClose={onClose}
      title="The image panel was successfully added to the Panels"
      dataTest={dataTest}
      primaryButtonText="View in Project Panels"
      secondaryButtonText="Back to Graphics Tab"
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      content={null}
    />
  );
};

GraphicsTabConfirmationDialog.defaultProps = {
  dataTest: null
};

GraphicsTabConfirmationDialog.propTypes = {
  dataTest: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired
};

export default GraphicsTabConfirmationDialog;
