import React, { useCallback } from 'react';
import { getRedirectUrl } from '@shared';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import { TooltipDataPoint } from '@experiment-management-shared/types';

type UseChartTraceClickParams = {
  traceClickable?: boolean;
  traceClickableRef?: React.MutableRefObject<boolean>;
  hoveredPoint?: TooltipDataPoint;
};

const useChartTraceClick = ({
  traceClickable = false,
  traceClickableRef,
  hoveredPoint
}: UseChartTraceClickParams) => {
  const experimentKey = hoveredPoint?.data?.cometMetadata?.experimentKey;

  const handleChartClick = useCallback(() => {
    const clickableBaseOnClickableRef = traceClickableRef
      ? traceClickableRef.current
      : true;

    if (experimentKey && traceClickable && clickableBaseOnClickableRef) {
      const redirectUrl = getRedirectUrl(REDIRECT_LINK_RESOURCES.EXPERIMENT, [
        experimentKey
      ]);

      window.open(redirectUrl, '_blank');
    }
  }, [experimentKey, traceClickable, traceClickableRef]);

  return {
    handleChartClick
  };
};

export default useChartTraceClick;
