import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@ds';

import experimentActions from '@/actions/experimentActions';
import { dialogTypes } from '@/constants/alertTypes';
import { DOCS_ROOT_URL } from '@/constants/configConstants';
import alertsUtil from '@/util/alertsUtil';

import {
  DSCheckMarkIcon,
  DSRunningIcon,
  DSStopPlusCircleIcon
} from '@ds-icons';

const StopExperimentButton = ({ disabled, experiments, isMenuItem }) => {
  const dispatch = useDispatch();
  const [hasUserRequestedStop, setUserRequestedStop] = useState(false);

  const activeExperiments = experiments.filter(({ runActive }) => runActive);
  const areRunningInJupyterNotebook = experiments.every(
    experiment => experiment.file_name === 'Jupyter interactive'
  );
  const isAnySelectedExperimentActive = !!activeExperiments.length;

  const handleCloseConfirm = () => {
    dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_STOP_EXPERIMENT));
  };

  const handleConfirmOverride = () => {
    activeExperiments.forEach(({ experimentKey }) => {
      dispatch(experimentActions.stopRunningExperiment(experimentKey));
    });

    setUserRequestedStop(true);
    handleCloseConfirm();
  };

  const handleStopExperiment = () => {
    const stopExperimentsDocsUrl = `${DOCS_ROOT_URL}/guides/comet-dashboard/more-in-projects/#stop-an-experiment`;

    const confirmMessage = (
      <span className="confirm-message-container">
        <span>Are you sure you want to stop running these experiments?</span>
        <span>
          You can learn more about stopping a running experiment
          <a href={stopExperimentsDocsUrl}> here</a>.
        </span>
      </span>
    );

    dispatch(
      alertsUtil.openConfirmDialog(
        dialogTypes.CONFIRM_STOP_EXPERIMENT,
        confirmMessage,
        handleConfirmOverride,
        'Stop experiments'
      )
    );
  };

  const experimentText = isMenuItem ? 'experiments' : '';

  const isStopping = hasUserRequestedStop && isAnySelectedExperimentActive;
  let buttonText = `Stop ${experimentText}`;
  let ButtonIcon = DSStopPlusCircleIcon;

  if (isStopping) {
    buttonText = 'Stopping..';
    ButtonIcon = DSRunningIcon;
  } else if (hasUserRequestedStop) {
    buttonText = 'Stopped';
    ButtonIcon = DSCheckMarkIcon;
  }

  if (areRunningInJupyterNotebook) return null;

  const isDisabled = disabled || !isAnySelectedExperimentActive || isStopping;

  return (
    <Button
      size="large"
      disabled={isDisabled}
      onClick={handleStopExperiment}
      PrefixIcon={<ButtonIcon />}
    >
      {buttonText}
    </Button>
  );
};

StopExperimentButton.defaultProps = {
  isMenuItem: false
};

StopExperimentButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  experiments: PropTypes.array.isRequired,
  isMenuItem: PropTypes.bool
};

export default StopExperimentButton;
