import React, { memo, useMemo } from 'react';
import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  MANAGEMENT_PERMISSIONS_SERVER,
  WORKSPACE_OWNER_VALUE,
  WORKSPACE_MEMBER_VALUE
} from '@shared/constants/permissions';
import {
  formatPermissionValueToString,
  getPermissionByType,
  getPermissionValueByType,
  getUserUniqueName,
  isUserPermissionValid,
  updatePermissionByType
} from '@shared/utils/permissions';
import DecisionTree, { CONTROL_TYPES } from '@shared/components/DecisionTree';
import classNames from './ManageUsersModalPopover.module.scss';

const CANNOT_CHANGE_MY_ROLE_IN_WS_TOOLTIP = "You can't update your own role";
const CANNOT_CHANGE_ORG_ADMIN_ROLE_IN_WS_TOOLTIP =
  "You can't change the role, since this user is an organization admin";

const WORKSPACE_MEMBER_PERMISSIONS = [
  {
    key: MANAGEMENT_PERMISSIONS_SERVER.INVITE_USERS,
    label: 'Invite Users (IU)',
    text: 'Invite users to workspace'
  },
  {
    key: MANAGEMENT_PERMISSIONS_SERVER.PROJECT_VISIBILITY,
    label: 'Project Settings (PS)',
    text: 'Change project settings and visibility'
  }
];

const ManageUsersRolePopover = ({
  ifUserAdmin,
  ifChangeWsRoleDisabled,
  username,
  permissions,
  onClose,
  setPermissions,
  popoverPosition = {},
  currentUser
}) => {
  const currentUserUserName = getUserUniqueName(currentUser);
  const wsManagementPermissionValue = getPermissionValueByType(
    permissions,
    MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
  );
  const ifChangeMadeForCurrentUser = currentUserUserName === username;
  const ifDisabled = ifChangeWsRoleDisabled || ifUserAdmin;

  const manageUsersRolePopoverDecisionTreeProps = useMemo(() => {
    const checkboxesOptions = WORKSPACE_MEMBER_PERMISSIONS.map(perm => ({
      ...perm,
      value: perm.key,
      checked: isUserPermissionValid(
        getPermissionByType(permissions, perm.key)
      ),
      color: 'primary'
    }));

    return {
      controlType: CONTROL_TYPES.RADIO,
      controlValue: wsManagementPermissionValue,
      onChange: ev =>
        setPermissions(
          updatePermissionByType(
            permissions,
            MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT,
            ev?.target?.value
          )
        ),
      options: [
        {
          key: `${MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT}-owner`,
          value: WORKSPACE_OWNER_VALUE,
          label: 'Workspace Owner',
          text: 'Full permissions to all workspace resources'
        },
        {
          key: `${MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT}-member`,
          value: WORKSPACE_MEMBER_VALUE,
          label: 'Workspace Member',
          text: 'Limited permissions. You can give customized ones',
          disabled: ifDisabled,
          tooltip: ifDisabled
            ? {
                arrow: true,
                title: ifChangeMadeForCurrentUser
                  ? CANNOT_CHANGE_MY_ROLE_IN_WS_TOOLTIP
                  : CANNOT_CHANGE_ORG_ADMIN_ROLE_IN_WS_TOOLTIP,
                placement: 'left'
              }
            : null,
          list:
            wsManagementPermissionValue === WORKSPACE_MEMBER_VALUE
              ? {
                  options: checkboxesOptions,
                  controlType: CONTROL_TYPES.CHECKBOX,
                  onChange: (ev, { value }) =>
                    setPermissions(
                      updatePermissionByType(
                        permissions,
                        value,
                        formatPermissionValueToString(ev?.target?.checked)
                      )
                    )
                }
              : null
        }
      ]
    };
  }, [
    permissions,
    wsManagementPermissionValue,
    ifChangeMadeForCurrentUser,
    ifChangeWsRoleDisabled,
    ifUserAdmin
  ]);

  return (
    <Popover
      elevation={0}
      classes={{
        paper: classNames.manageUsersModalPopover
      }}
      id="manageUsersWorkspaceRolePopover"
      open={popoverPosition?.y && popoverPosition?.x}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: popoverPosition?.y || 0,
        left: popoverPosition?.x || 0
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={onClose}
    >
      <DecisionTree {...manageUsersRolePopoverDecisionTreeProps} />
    </Popover>
  );
};

ManageUsersRolePopover.propTypes = {
  ifUserAdmin: PropTypes.bool,
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    isAdmin: PropTypes.bool
  }),
  ifChangeWsRoleDisabled: PropTypes.bool,
  popoverPosition: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string
  }),
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      permissionName: PropTypes.string,
      permissionValue: PropTypes.string
    })
  ),
  username: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setPermissions: PropTypes.func.isRequired
};

ManageUsersRolePopover.defaultProps = {
  popoverPosition: {},
  ifChangeWsRoleDisabled: false,
  permissions: [],
  username: '',
  currentUser: {},
  ifUserAdmin: false
};

export default memo(ManageUsersRolePopover);
