import React, { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  addEdge,
  NodeTypes,
  ReactFlowInstance
} from 'react-flow-renderer';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@ds';
import { InputNodeIcon, OutputNodeIcon } from '@Icons-outdated';
import { useModels, useCreateModelPipeline } from '@mpm/api';
import SmallLoader from '@shared/components/SmallLoader';
import { DragModelItem } from './DragModelItem';
import { ModelNode, NodeDataType } from './ModelNode';
import { NODE_TYPES } from '@mpm/constants';
import { EditPencilField } from './EditPencilField';
import { ButtonTooltipContent } from './ButtonTooltipContent';
import { EmptyDAG } from './EmptyDAG';
import classNames from './DAGContent.module.scss';
import './FlowStyles.scss';

type DAGContentProps = {
  closeModal: () => void;
};

export const DAGContent = ({ closeModal }: DAGContentProps) => {
  const reactFlowWrapper = useRef<HTMLInputElement>(null);
  const [pipelineName, setPipelineName] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<
    ReactFlowInstance<NodeDataType, undefined> | undefined
  >(undefined);

  const { data, isLoading } = useModels(
    {
      page: 1,
      pageSize: 1000
    },
    {
      keepPreviousData: true,
      refetchOnMount: false
    }
  );

  const allowSaveBtn = useMemo(() => {
    const hasInput = nodes.some(node => node?.data.inputModel === true);
    const hasOutput = nodes.some(node => node?.data.outputModel === true);
    return pipelineName.length && hasInput && hasOutput;
  }, [nodes, pipelineName]);

  const createPipeline = useCreateModelPipeline(closeModal);

  const nodeTypes: NodeTypes = useMemo(
    () => ({
      modelNode: props => <ModelNode {...props} />
    }),
    []
  );

  const onConnect = useCallback(
    connection =>
      setEdges(eds =>
        addEdge(
          { ...connection, animated: true, style: { stroke: '#5F677E' } },
          eds
        )
      ),
    [setEdges]
  );

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDeleteNode = useCallback(
    id => {
      setNodes(prevNodes => prevNodes.filter(node => node.id !== id));
      setEdges(prevEdges =>
        prevEdges.filter(edge => edge.source !== id && edge.target !== id)
      );
    },
    [setEdges, setNodes]
  );

  const onSetNodeType = useCallback(
    (id, type) => {
      setNodes(prevNodes =>
        prevNodes.map(node => {
          if (node?.data?.id === id) {
            return {
              ...node,
              data: { ...node.data, [type]: !node.data[type] }
            };
          }
          if (type === NODE_TYPES.OUTPUT) {
            return {
              ...node,
              data: { ...node.data, [type]: false }
            };
          }
          return node;
        })
      );
    },
    [setNodes]
  );

  const onDrop = useCallback(
    event => {
      event.preventDefault();
      if (
        reactFlowWrapper.current != null &&
        reactFlowInstance?.project != undefined
      ) {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        const transferId = event.dataTransfer.getData('drag/Id');
        const transferName = event.dataTransfer.getData('drag/name');

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top
        });
        const newNode = {
          id: transferId,
          type: 'modelNode',
          title: transferName,
          position,
          data: {
            title: `${transferName}`,
            id: transferId,
            inputModel: false,
            outputModel: false,
            onDeleteNode,
            onSetNodeType
          }
        };

        setNodes(prevNodes => {
          return prevNodes
            .filter(node => node.id !== transferId)
            .concat(newNode);
        });
      }
    },
    [reactFlowInstance, setNodes, onDeleteNode, onSetNodeType]
  );

  const onDragStart = (event: React.DragEvent, id: string, name: string) => {
    event.dataTransfer.setData('drag/id', id);
    event.dataTransfer.setData('drag/name', name);
    event.dataTransfer.effectAllowed = 'move';
  };

  const createPipelineHandler = () => {
    const restoreData = reactFlowInstance?.toObject();
    const pipelineNodes = nodes.map(node => {
      const { id, inputModel, outputModel } = node?.data || {};
      return {
        nodeId: id,
        data: {
          modelId: id,
          inputModel,
          outputModel
        }
      };
    });
    const pipelineEdges = edges.map(edge => {
      const { source, target } = edge;
      return { from: source, to: target };
    });
    createPipeline.mutate({
      pipelineData: {
        name: pipelineName,
        nodes: pipelineNodes,
        edges: pipelineEdges,
        graphViewJson: JSON.stringify(restoreData)
      }
    });
  };

  if (isLoading)
    return (
      <SmallLoader
        primaryMessage="Loading MPM models"
        secondaryMessage={<span>It might take a few seconds.</span>}
      />
    );

  return (
    <ReactFlowProvider>
      <div className={classNames.DAGContentWrapper}>
        <div className={classNames.panelMainSection}>
          <div className={classNames.panelMainSectionLeftColumn}>
            <div className={classNames.leftColumnHeader}>
              <span>Select models</span>
              <p>
                To define a new pipeline, drag the relevant models to the right
                hand side and link these together. You can mark multiple models
                as inputs to the pipeline and a single model as the output, this
                allows MPM to know which model features should be tracked at the
                pipeline level.
              </p>
            </div>
            <div className={classNames.dragItemsList}>
              {data &&
                data?.models.length > 0 &&
                data.models.map(model => {
                  const { id, name } = model;
                  return (
                    <DragModelItem
                      key={id}
                      id={id}
                      name={name}
                      onDragStart={onDragStart}
                    />
                  );
                })}
            </div>
          </div>
          <div
            className={classNames.panelMainSectionRightColumn}
            ref={reactFlowWrapper}
            data-test="drop-models-section"
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              snapToGrid
            >
              <div className={classNames.nameFieldContainer}>
                <EditPencilField
                  title="DAG name"
                  placeholder="DAG name"
                  value={pipelineName}
                  setValue={setPipelineName}
                />
              </div>
              {!nodes.length && <EmptyDAG />}
              {nodes.length > 0 && (
                <Controls
                  showInteractive={false}
                  className={classNames.controlsMenu}
                />
              )}
              {nodes.length > 0 && (
                <div className={classNames.legendContainer}>
                  <div className={classNames.legendItem}>
                    <InputNodeIcon className={classNames.inputIcon} />
                    <span className={classNames.legendText}>Input</span>
                  </div>
                  <div className={classNames.legendItem}>
                    <OutputNodeIcon className={classNames.outputIcon} />
                    <span className={classNames.legendText}>Output</span>
                  </div>
                </div>
              )}
            </ReactFlow>
          </div>
        </div>

        <div className={classNames.panelFooter}>
          <Tooltip
            title={!allowSaveBtn ? <ButtonTooltipContent /> : ''}
            placement="bottom-end"
            arrow
            classes={{
              tooltip: classNames.buttonTooltip,
              arrow: classNames.buttonTooltipArrow
            }}
          >
            <span>
              <Button
                disabled={!allowSaveBtn}
                onClick={createPipelineHandler}
                style={!allowSaveBtn ? { pointerEvents: 'none' } : {}}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </ReactFlowProvider>
  );
};

DAGContent.propTypes = {
  closeModal: PropTypes.func.isRequired
};
