import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import './Avatar.scss';

const Avatar = ({
  width,
  src,
  outlined,
  style,
  square,
  backgroundColor,
  borderColor,
  letters,
  showLetters,
  lettersFontSize,
  lettersFontWeight,
  lettersLineHeight,
  type
}) => {
  return (
    <div
      className={cx('ds-avatar-container', {
        outlined,
        [type]: true,
        square
      })}
      style={{
        minWidth: width,
        minHeight: width,
        ...style,
        ...(borderColor
          ? { borderRadius: '50%', border: `1px solid ${borderColor}` }
          : {})
      }}
    >
      {!showLetters ? (
        <img
          src={src}
          style={{
            ...style,
            width,
            height: width
          }}
          loading="lazy"
        />
      ) : (
        <div
          className={cx('letters-container', {
            outlined,
            square,
            [type]: true
          })}
          style={{
            ...style,
            width,
            height: width,
            backgroundColor
          }}
        >
          <span
            style={{
              fontSize: lettersFontSize,
              fontWeight: lettersFontWeight,
              lineHeight: lettersLineHeight
            }}
          >
            {letters}
          </span>
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  type: PropTypes.oneOf(['basic', 'small']),
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outlined: PropTypes.bool,
  style: PropTypes.object,
  square: PropTypes.bool,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  letters: PropTypes.string,
  showLetters: PropTypes.bool,
  lettersFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lettersFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lettersLineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Avatar.defaultProps = {
  type: 'basic',
  src: null,
  width: '114px',
  outlined: false,
  style: {},
  square: false,
  backgroundColor: 'none',
  borderColor: false,
  letters: null,
  showLetters: false,
  lettersFontSize: '14px',
  lettersFontWeight: '500',
  lettersLineHeight: '21px'
};

export default Avatar;
