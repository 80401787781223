import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { StyledTooltip } from '@DesignSystem/data-display';
import { ArrowTightIcon } from '@Icons-outdated';
import { animated, useSpring } from 'react-spring';
import difference from 'lodash/difference';

import { VIEWS_EXCLUDED_IN_COMPARE } from '@experiment-management-shared/constants/experimentConstants';
import { VIEW_INFO } from '@experiment-details/constants/experiment-details';

import styles from './ExperimentDetailsSidebar.module.scss';

const COLLAPSED_MENU_WIDTH = '50px';
const EXPANDED_MENU_WIDTH = '184px';

const ExperimentDetailsSidebar = ({
  detailView,
  handleDetailViewChange,
  isMenuCollapsed,
  onExpandCollapseClick,
  isComparison
}) => {
  const VIEWS = Object.keys(VIEW_INFO);
  const COMPARE_VIEWS = difference(VIEWS, VIEWS_EXCLUDED_IN_COMPARE);

  const tabNames = isComparison ? COMPARE_VIEWS : VIEWS;
  const [isHovered, setIsHovered] = useState(false);

  const handleExpandCollapseClick = () => {
    onExpandCollapseClick(!isMenuCollapsed);
  };

  const renderDetailItemLabel = tabName => {
    const { displayName } = VIEW_INFO[tabName];
    const { icon: Icon } = VIEW_INFO[tabName];

    return (
      <>
        <div className={styles.itemIconContainer}>
          <Icon className={styles.itemIcon} />
        </div>
        {!isMenuCollapsed && displayName}
      </>
    );
  };

  const collapseAnimationProps = useSpring({
    width: isMenuCollapsed ? COLLAPSED_MENU_WIDTH : EXPANDED_MENU_WIDTH
  });

  const arrowAnimationProps = useSpring({
    opacity: isHovered || isMenuCollapsed ? 1 : 0
  });

  const renderTabItem = tabName => {
    const id = `${tabName}-tab`;
    const { displayName } = VIEW_INFO[tabName];

    return (
      <StyledTooltip
        key={tabName}
        title={displayName}
        placement="right"
        disableHoverListener={!isMenuCollapsed}
      >
        <li
          onClick={() => handleDetailViewChange(tabName)}
          key={tabName}
          id={id}
          className={cx(styles.detailItem, {
            [styles.selectedDetailItem]: detailView === tabName
          })}
          role="button"
          data-test={displayName}
        >
          {renderDetailItemLabel(tabName)}
        </li>
      </StyledTooltip>
    );
  };

  return (
    <animated.div
      className={cx(styles.sideBarMenu, {
        [styles.collapsed]: isMenuCollapsed
      })}
      style={collapseAnimationProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <animated.div
        className={styles.collapseExpandButton}
        style={arrowAnimationProps}
        onClick={handleExpandCollapseClick}
      >
        <ArrowTightIcon />
      </animated.div>

      <ul>{tabNames.map(renderTabItem)}</ul>
    </animated.div>
  );
};

ExperimentDetailsSidebar.propTypes = {
  detailView: PropTypes.string.isRequired,
  handleDetailViewChange: PropTypes.func.isRequired,
  isMenuCollapsed: PropTypes.bool.isRequired,
  onExpandCollapseClick: PropTypes.func.isRequired,
  isComparison: PropTypes.bool.isRequired
};

export default ExperimentDetailsSidebar;
