import React from 'react';

import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';

import {
  AGGREGATION_TYPES_OBJECT,
  TOOLTIP_MAX_DIGITS_AFTER_PERIOD,
  TRANSFORM_TYPES_OBJECT
} from '@experiment-management-shared/constants/chartConstants';

import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import SmallLoader from '@shared/components/SmallLoader';

import { GRID_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import {
  DATE_TIME_COLUMN_NAMES,
  formatDateTime
} from '@shared/utils/displayHelpers';
import { calculateExperimentName } from '@experiment-management-shared/utils/experimentHelpers';
import { NAME_KEY } from '@experiment-management-shared/constants/experimentConstants';
import { Axes } from '@DesignSystem/charts/Plot';
import moment from 'moment/moment';
import { truncateValue } from '@shared/utils/decimalUtils';
import { ChartError } from '@experiment-management-shared/components/Charts/ChartError';

export const getBarMetrics = ({
  aggregationX,
  metricName,
  metricNames,
  metrics
}) => {
  if (metrics) return metrics.filter(metric => metric.name);

  const aggregation = aggregationX || AGGREGATION_TYPES_OBJECT.LAST;

  return (metricNames || [metricName])
    .map(name => ({
      aggregation,
      name
    }))
    .filter(metric => metric.name);
};

export const getExperimentKeyFromDataSource = dataSource => {
  return get(dataSource, 'experiment_key', '');
};

export const getMetricNameFromDataSource = dataSource => {
  return get(dataSource, 'metrics[0].metricName', '');
};

export const getTraceNameFromDataSource = dataSource => {
  const experimentKey = getExperimentKeyFromDataSource(dataSource);
  const metricName = getMetricNameFromDataSource(dataSource);
  return `${experimentKey} ${metricName}`;
};

export const isTransformActive = (
  smoothingX,
  smoothingY,
  transformX,
  transformY
) => {
  return (
    smoothingX !== 0 ||
    smoothingY !== 0 ||
    [transformX, transformY].some(transformType => {
      return Object.values(TRANSFORM_TYPES_OBJECT).includes(transformType);
    })
  );
};

export const isVisibleOnChart = dataSource =>
  dataSource.isVisibleOnDashboard === false ? 'legendonly' : true;

export const getFormattedPlotlyData = (val, axis) => {
  let retVal = 'NA';

  if (isBoolean(val)) {
    retVal = val.toString();
  } else if (val !== '' && !isNull(val) && !isUndefined(val)) {
    retVal = truncateValue(val, TOOLTIP_MAX_DIGITS_AFTER_PERIOD);
  }

  if (axis) {
    try {
      if (axis.type === 'date') {
        retVal = moment(val).format('MM/DD/YYYY h:mm:ss.SSS A');
      } else {
        let value = Axes.hoverLabelText(axis, retVal);

        // Plotly library can return HTML as part of formatted value
        // here is implemented parsing of HTML and convert it to valid JSX

        const sup = /<sup>\d+<\/sup>/;
        if (isString(value) && sup.test(value)) {
          const [supValue] = value.match(sup)[0].match(/\d+/);

          value = (
            <>
              {value.split(sup)[0]}
              <sup>{supValue}</sup>
            </>
          );
        }

        retVal = value || retVal;
      }
    } catch (e) {
      /* empty */
    }
  }

  return retVal;
};

export const getExperimentValueForLegendKey = (legendKey, experiment) => {
  if (!experiment) {
    return '';
  }

  if (legendKey === GRID_COLUMNS.EXPERIMENT_TAGS) {
    return (experiment?.tags || []).join(', ') || null;
  }

  let value = get(experiment, legendKey, null);

  if (DATE_TIME_COLUMN_NAMES.includes(legendKey)) {
    value = formatDateTime(value);
  }

  return value;
};

export const getLegendsKeysMap = (
  dataSources,
  experiments,
  selectedLegendKeys
) => {
  const experimentsMap = mapKeys(
    experiments,
    experiment => experiment.experimentKey
  );

  const legendKeys =
    Array.isArray(selectedLegendKeys) && !isEmpty(selectedLegendKeys)
      ? selectedLegendKeys
      : [];

  return dataSources.reduce((acc, dataSource) => {
    const experimentKey = getExperimentKeyFromDataSource(dataSource);
    const experiment = experimentsMap[experimentKey];

    acc[experimentKey] = {
      experimentKey,
      experimentName: calculateExperimentName(experiment),
      legendKeys: legendKeys
        .filter(key => key.value !== NAME_KEY)
        .map(key => {
          return {
            title: key.value,
            value: getExperimentValueForLegendKey(key.value, experiment)
          };
        })
    };

    return acc;
  }, {});
};

export const renderLoader = () => (
  <SmallLoader
    disableAnimations
    isFlexDisplay
    primaryMessage="Loading..."
    secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
  />
);

export const renderChartError = (type, renderChartHeader = noop) => (
  <div className="chart-data-error-container">
    {renderChartHeader()}
    <ChartError type={type} />
  </div>
);
