import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import api from '@shared/api';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { dialogTypes } from '../../../constants/alertTypes';
import {
  FILTERS,
  WORKSPACE_PAGE_TABS
} from '../../../constants/workspaceConstants';
import alertsUtil from '../../../util/alertsUtil';

const getWorkspaceFilters = async ({ signal, workspaceName }) => {
  const { data } = await api.get(`workspaces/${workspaceName}/filters`, {
    signal
  });
  const usernamesString = data?.rows?.[0]?.userNames;
  const projectsOwners = usernamesString ? usernamesString.split(',') : [];

  return FILTERS[WORKSPACE_PAGE_TABS.PROJECTS].getFilters(projectsOwners);
};

export default function useWorkspaceFilters(params, config) {
  const workspaceName = useWorkspaceName();
  const history = useHistory();
  const dispatch = useDispatch();

  return useQuery(
    ['projectsOwners', { workspaceName }],
    ({ signal }) => getWorkspaceFilters({ signal, workspaceName }),
    {
      refetchOnMount: true,
      ...config,
      initialData: [],
      retry: false,
      onError: error => {
        const msg = error?.response?.data?.msg;

        if (/Non existent workspace/.test(msg)) {
          history.push('/404');
          return;
        }

        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching filters for your workspace'
          )
        );
      }
    }
  );
}
