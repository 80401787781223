import { SearchInput } from '@design-system/components/inputs';
import React from 'react';
import { useSearchListContext } from '../useSearchListContext';
import { SearchInputProps } from '@ds/inputs/SearchInput/SearchInput';
import classes from '../List.module.scss';

type ListSearchProps = Omit<SearchInputProps, 'value' | 'onClearIconClick'> &
  Partial<Pick<SearchInputProps, 'onClearIconClick'>>;
const ListSearch: React.FC<ListSearchProps> = ({
  placeholder = 'Search',
  onChange,
  onClearIconClick,
  ...searchProps
}) => {
  const { searchValue, setSearchValue, onResetSearch } = useSearchListContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    if (!onChange) return;

    onChange(e);
  };

  const handleOnClearIconClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    onResetSearch();

    if (!onClearIconClick) return;
    onClearIconClick(e);
  };

  return (
    <div className={classes.listSearchField}>
      <SearchInput
        {...searchProps}
        placeholder={placeholder}
        value={searchValue}
        autoFocus
        onChange={handleOnChange}
        onClearIconClick={handleOnClearIconClick}
        searchIconClassName={classes.searchIcon}
      />
    </div>
  );
};

export default ListSearch;
