// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageUsersModalTable-module__manageUsersModalTable--R08SQ{border:1px solid #e2e2e2;border-radius:5px;overflow:hidden;border-top:0;border-bottom:0}.ManageUsersModalTable-module__manageUsersModalTable--R08SQ td{background:#fff !important}.ManageUsersModalTable-module__manageUsersModalTable--R08SQ .dx-table-header-cell:last-child span{opacity:0 !important}.ManageUsersModalTable-module__manageUsersModalTable--R08SQ .dx-table-cell{font-size:14px;color:var(--gray-6);height:72px}.ManageUsersModalTable-module__manageUsersModalTableRemove--b4dtG:hover{background-color:rgba(0,0,0,0) !important}.ManageUsersModalTable-module__manageUsersModalTableRemoveDisabled--pAUTq{opacity:.5}.ManageUsersModalTable-module__manageUsersModalTableAvatarLabel--Fvmwr{font-weight:500;font-size:14px !important;color:var(--gray-6)}.ManageUsersModalTable-module__manageUsersModalTableEmptyCell--AGKeK{background-color:var(--gray-4);height:1px;width:21px}.ManageUsersModalTable-module__manageUsersModalTableEmptyCellNoName--Ha7E7{margin-left:40px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageUsersModalTable": `ManageUsersModalTable-module__manageUsersModalTable--R08SQ`,
	"manageUsersModalTableRemove": `ManageUsersModalTable-module__manageUsersModalTableRemove--b4dtG`,
	"manageUsersModalTableRemoveDisabled": `ManageUsersModalTable-module__manageUsersModalTableRemoveDisabled--pAUTq`,
	"manageUsersModalTableAvatarLabel": `ManageUsersModalTable-module__manageUsersModalTableAvatarLabel--Fvmwr`,
	"manageUsersModalTableEmptyCell": `ManageUsersModalTable-module__manageUsersModalTableEmptyCell--AGKeK`,
	"manageUsersModalTableEmptyCellNoName": `ManageUsersModalTable-module__manageUsersModalTableEmptyCellNoName--Ha7E7`
};
module.exports = ___CSS_LOADER_EXPORT___;
