import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DateRange, RadioSelector, Select } from '@DesignSystem/controllers';
import { chartColors } from '@design-system-outdated/constants';
import { Button } from '@ds';
import { mapModelVersions } from '@mpm/utils';
import { PanelContainer } from '../PanelContainer';
import { SegmentsPopover } from '../SegmentsPopover';
import { useMPMPerformanceParams } from '../../hooks';
import {
  INTERVAL_TYPE_OPTIONS,
  PERFORMANCE_DATE_RANGES,
  MPM_CHART_DISABLED_ZOOM,
  TRANSFORM_TYPES,
  MPM_CHART_DATA_TYPES,
  PANEL_NAMES,
  PANEL_SECTIONS
} from '../../constants';
import './ModelFeaturePerformancePage.scss';

const defaultVersions = [
  {
    label: 'All versions',
    value: null
  }
];

const chartColorsArr = Object.values(chartColors);

export const ModelFeaturePerformancePage = ({
  activeSegmentGroupColorIds,
  match,
  feature,
  model,
  setActiveSegmentGroupColorIds,
  isSegmentsData
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSegmentsCounter, setSelectedSegmentsCounter] = useState(0);
  const versions = defaultVersions.concat(mapModelVersions(model.versions));
  const { isLabeled, customTransformations } = model;

  const customTransformationTabs = useMemo(() => {
    if (customTransformations?.length) {
      const transformations = customTransformations
        .filter(
          element =>
            (element.transformType === TRANSFORM_TYPES.CATEGORICAL_FEATURE &&
              feature?.type === MPM_CHART_DATA_TYPES.CATEGORICAL) ||
            (element.transformType === TRANSFORM_TYPES.NUMERICAL_FEATURE &&
              feature?.type === MPM_CHART_DATA_TYPES.NUMERICAL)
        )
        .map(item => {
          const { name, definition, transformType } = item;
          const value =
            transformType === TRANSFORM_TYPES.NUMERICAL_FEATURE
              ? PANEL_SECTIONS.NUMERICAL_FEATURE_CUSTOM_METRIC
              : PANEL_SECTIONS.CATEGORICAL_FEATURE_CUSTOM_METRIC;
          return {
            label: name,
            value,
            definition,
            transformType
          };
        });
      if (transformations.length) {
        transformations.unshift({
          label: 'Distribution',
          value: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
          valType: String(feature.type).toUpperCase()
        });
        return transformations;
      }
    }
    return [];
  }, [feature, model]);

  const {
    version,
    onUpdateVersion,

    from,
    to,
    intervalType,
    dateRange,
    setStartDate,
    setEndDate,
    calcMaxDate,
    setDateRange,
    onUpdateIntervalType,
    onChangeRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  } = useMPMPerformanceParams(versions);

  const openSegmentsPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="model-performance-tab">
      <div className="model-performance-tab-panels">
        <div className="model-performance-tab-header">
          <Button
            type="secondary"
            size="small"
            onClick={openSegmentsPopover}
            active={!!anchorEl}
          >
            Compare Segments ({selectedSegmentsCounter})
          </Button>
          <SegmentsPopover
            activeSegmentGroupColorIds={activeSegmentGroupColorIds}
            anchorEl={anchorEl}
            counterHandler={setSelectedSegmentsCounter}
            match={match}
            setActiveSegmentGroupColorIds={setActiveSegmentGroupColorIds}
            setAnchorEl={setAnchorEl}
          />
          <Select
            onChange={onUpdateVersion}
            // @todo: fetch the versions from the model
            options={versions}
            value={version}
            withInput
          />

          <div className="model-performance-tab-header-divider" />

          <Select
            onChange={onUpdateIntervalType}
            options={INTERVAL_TYPE_OPTIONS}
            value={intervalType}
          />

          <div className="model-performance-tab-header-divider" />

          <div className="model-performance-tab-header-date-ranges">
            {PERFORMANCE_DATE_RANGES[intervalType]?.map(days => (
              <RadioSelector
                isSelected={days === dateRange}
                key={`date-range-${days}`}
                onClick={() => {
                  setDateRange(days);
                  setEndDate(null);
                  setStartDate(new Date());
                }}
                text={`${days}d`}
              />
            ))}
          </div>

          <DateRange
            startDate={handledCalendarStartDate}
            endDate={handledCalendarEndDate}
            onChange={onChangeRange}
            calcMaxDate={calcMaxDate}
          />
        </div>

        <div className="model-performance-tab-content">
          <PanelContainer
            title={PANEL_NAMES.DISTRIBUTION}
            tabs={customTransformationTabs}
            from={from}
            intervalType={intervalType}
            section={PANEL_SECTIONS.FEATURE_DISTRIBUTION}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            segmentColorIds={activeSegmentGroupColorIds}
            isLabeled={isLabeled}
            isSegmentsData={isSegmentsData}
            feature={feature}
          />

          <PanelContainer
            title={PANEL_NAMES.DRIFT}
            from={from}
            intervalType={intervalType}
            section={PANEL_SECTIONS.DRIFT}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            segmentColorIds={activeSegmentGroupColorIds}
            isLabeled={isLabeled}
            isSegmentsData={isSegmentsData}
            feature={feature}
          />

          <PanelContainer
            title={PANEL_NAMES.MISSING_VALUES}
            from={from}
            intervalType={intervalType}
            section={PANEL_SECTIONS.MISSING_VALUES}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            segmentColorIds={activeSegmentGroupColorIds}
            isLabeled={isLabeled}
            isSegmentsData={isSegmentsData}
            feature={feature}
          />
        </div>
      </div>
    </div>
  );
};

ModelFeaturePerformancePage.propTypes = {
  activeSegmentGroupColorIds: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.string,
      segmentValue: PropTypes.string
    })
  ).isRequired,
  match: PropTypes.object.isRequired,
  feature: PropTypes.shape().isRequired,
  model: PropTypes.shape({
    versions: PropTypes.array,
    customTransformations: PropTypes.array,
    isLabeled: PropTypes.bool.isRequired
  }).isRequired,
  setActiveSegmentGroupColorIds: PropTypes.func.isRequired,
  isSegmentsData: PropTypes.bool.isRequired
};

ModelFeaturePerformancePage.defaultProps = {};
