import { Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import isNull from 'lodash/isNull';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AGGREGATION_TYPES_ARRAY,
  CHART_ANNOTATIONS,
  OUTLIERS_VALUES
} from '@experiment-management-shared/constants/chartConstants';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';

import useColumns from '@API/project/useColumns';
import RadioButton from '@DesignSystem/controllers/RadioButton/RadioButton';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import useChartTabYValues from '@experiment-management-shared/hooks/useChartTabYValues';
import { Checkbox } from '@DesignSystem/controllers';
import { get } from 'lodash';
import { Select } from '@ds';

const ANNOTATIONS_KEY = 'annotations';

const X_AXIS_KEY = 'selectedXAxis';
const Y_AXIS_KEY = 'selectedYAxis';
const Z_AXIS_KEY = 'selectedZAxis';

const chartType = 'scatter';

function ScatterChartDataTab() {
  const dispatch = useDispatch();

  const scatterChartForm = useSelector(
    state => state.dashboardCharts.chartForm.scatter
  );

  const { data: columns } = useColumns();
  const metricColumnsMap = columns.reduce((obj, column) => {
    if (column.source === 'metrics') {
      obj[column.name] = column;
    }
    return obj;
  }, {});
  const paramColumnsMap = columns.reduce((obj, column) => {
    if (column.source === 'params') {
      obj[column.name] = column;
    }
    return obj;
  }, {});

  const yValues = useChartTabYValues({ type: chartType });
  const currentAnnotations = get(
    scatterChartForm,
    ANNOTATIONS_KEY,
    CHART_ANNOTATIONS
  );

  const updateSelectedAxes = (key, selectedOption) => {
    const currentChartForm = scatterChartForm;
    const selectedAxes = {
      [X_AXIS_KEY]: currentChartForm[X_AXIS_KEY],
      [Y_AXIS_KEY]: currentChartForm[Y_AXIS_KEY],
      [Z_AXIS_KEY]: currentChartForm[Z_AXIS_KEY]
    };

    if (selectedAxes.hasOwnProperty(key)) {
      selectedAxes[key] = selectedOption;
    }

    return Object.values(selectedAxes);
  };

  const getValuesFoundInMap = (axisValues, columnsMapBySource) => {
    return axisValues.filter(value => {
      if (isNull(value)) return false;
      return columnsMapBySource.hasOwnProperty(value);
    });
  };

  const handleFormSelect = (formFieldName, value) => {
    if (value) {
      const selectedAxes = updateSelectedAxes(formFieldName, value);

      const updatedForm = {
        ...scatterChartForm,
        [formFieldName]: value,
        metricNames: getValuesFoundInMap(selectedAxes, metricColumnsMap),
        params: getValuesFoundInMap(selectedAxes, paramColumnsMap)
      };

      dispatch(dashboardChartsActions.updateChartForm(chartType, updatedForm));
    } else {
      handleUpdateChartForm(formFieldName, value);
    }
  };

  const handleUpdateChartForm = (key, valueToUpdate) => {
    const updatedForm = {
      ...scatterChartForm,
      [key]: valueToUpdate
    };

    dispatch(dashboardChartsActions.updateChartForm(chartType, updatedForm));
  };

  const renderYAxisSelect = () => {
    const formKey = Y_AXIS_KEY;
    const formValue = scatterChartForm[formKey];

    return (
      <div className="modal-input-group mb-40">
        <InputLabel className="modal-input-label">Y-Axis</InputLabel>
        <Select
          data-test="scatter-chart-y-axis-select"
          value={formValue}
          onValueChange={value => handleFormSelect(formKey, value)}
          options={yValues}
          maxWidth={260}
          truncateMiddle
          maxHeight={280}
          isSearchable
          menuShouldBlockScroll
          isRegexSearchEnabled
        />
      </div>
    );
  };

  const getIsAggregationDisabled = aggregationType => {
    const axisKey = {
      aggregationZ: Z_AXIS_KEY,
      aggregationY: Y_AXIS_KEY,
      aggregationX: X_AXIS_KEY
    };

    const formKey = axisKey[aggregationType];
    const formValue = scatterChartForm[formKey];

    if (isNull(formValue)) return false;

    return paramColumnsMap.hasOwnProperty(formValue);
  };

  const renderXAxisSelect = () => {
    const formKey = X_AXIS_KEY;
    const formValue = scatterChartForm[formKey];

    return (
      <div className="modal-input-group mb-40">
        <InputLabel className="modal-input-label">X-Axis</InputLabel>
        <Select
          data-test="scatter-chart-x-axis-select"
          value={formValue}
          maxWidth={260}
          truncateMiddle
          onValueChange={value => handleFormSelect(formKey, value)}
          options={yValues}
          maxHeight={280}
          isSearchable
          menuShouldBlockScroll
          isRegexSearchEnabled
        />
      </div>
    );
  };

  const renderZAxisSelect = () => {
    const formKey = Z_AXIS_KEY;
    const formValue = scatterChartForm[formKey];

    return (
      <div className="modal-input-group mb-40">
        <InputLabel className="modal-input-label">Z-Axis</InputLabel>
        <Select
          data-test="scatter-chart-z-axis-select"
          maxWidth={260}
          truncateMiddle
          value={formValue}
          onValueChange={value => handleFormSelect(formKey, value)}
          options={yValues}
          maxHeight={280}
          isSearchable
          menuShouldBlockScroll
          isRegexSearchEnabled
        />
      </div>
    );
  };

  /* add Disabled state to select */

  const renderAggregationSelect = type => {
    const isAggregationDisabled = getIsAggregationDisabled(type);
    const formValue = scatterChartForm[type];

    const options = AGGREGATION_TYPES_ARRAY.map(type =>
      formatValueForSelectOption(type)
    );

    return (
      <div className="modal-input-group half">
        <InputLabel className="modal-input-label">Aggregation</InputLabel>

        <Select
          value={formValue}
          onValueChange={selectedOption =>
            handleFormSelect(type, selectedOption)
          }
          options={options}
          maxWidth={130}
          truncateMiddle
          isClearable={false}
          placeholder={
            isAggregationDisabled ? 'Not supported for params' : 'Select...'
          }
          menuShouldBlockScroll
        />
      </div>
    );
  };

  const renderYAxisControls = () => {
    return (
      <Box display="flex">
        {renderYAxisSelect()}
        {renderAggregationSelect('aggregationY')}
      </Box>
    );
  };

  const renderXAxisControls = () => {
    return (
      <Box display="flex">
        {renderXAxisSelect()}
        {renderAggregationSelect('aggregationX')}
      </Box>
    );
  };

  const renderZAxisControls = () => {
    return (
      <Box display="flex">
        <div>{renderZAxisSelect()}</div>
        {renderAggregationSelect('aggregationZ')}
      </Box>
    );
  };

  const handleOutlierSelect = value => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        'scatter',
        'selectedOutliers',
        value
      )
    );
  };

  const handleAnnotationSelect = (e, name) => {
    const { checked } = e.target;

    const updatedAnnotations = {
      ...currentAnnotations,
      [name]: {
        ...currentAnnotations[name],
        checked
      }
    };

    handleUpdateChartForm(ANNOTATIONS_KEY, updatedAnnotations);
  };

  const renderOutliersSelect = () => {
    const formKey = 'selectedOutliers';
    const formValue = scatterChartForm[formKey];

    const options = [
      { label: 'Show', value: OUTLIERS_VALUES.SHOW },
      { label: 'Ignore', value: OUTLIERS_VALUES.NOT_VISIBLE }
    ];

    return (
      <div className="modal-input-group mb-40">
        <InputLabel className="modal-input-label">Outliers</InputLabel>

        <Box display="flex">
          {options.map(({ value, label }) => (
            <Box
              marginRight="28px"
              display="flex"
              alignItems="center"
              key={value}
            >
              <RadioButton
                onClick={() => handleOutlierSelect(value)}
                checked={value === formValue}
              />
              <p className="radio-label">{label}</p>
            </Box>
          ))}
        </Box>
      </div>
    );
  };

  const renderAnnotationSelect = () => {
    const checkboxes = Object.values(currentAnnotations);

    return (
      <div>
        <InputLabel
          className="modal-input-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px'
          }}
        >
          Annotations
        </InputLabel>
        <Box display="flex">
          {checkboxes.map(({ name, label, checked }) => {
            return (
              <Box display="flex" alignItems="center" key={label}>
                <Checkbox
                  checked={checked}
                  onChange={e => handleAnnotationSelect(e, name)}
                  size="small"
                />
                <p
                  style={{
                    marginLeft: 8,
                    marginRight: 28,
                    whiteSpace: 'nowrap'
                  }}
                >
                  {label}
                </p>
              </Box>
            );
          })}
        </Box>
      </div>
    );
  };

  return (
    <div className="scatter-chart-data-tab">
      {renderYAxisControls()}
      {renderXAxisControls()}
      {renderZAxisControls()}
      {renderOutliersSelect()}
      {renderAnnotationSelect()}
    </div>
  );
}

export default ScatterChartDataTab;
