import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

const LinkCell = ({ text, href, component, target, ...props }) => {
  return (
    <Box
      component={component}
      href={href}
      target={target}
      rel="noopener noreferrer"
      {...props}
    >
      {text}
    </Box>
  );
};

LinkCell.defaultProps = {
  component: 'a',
  target: '_blank',
  href: null
};

LinkCell.propTypes = {
  text: PropTypes.string.isRequired,
  component: PropTypes.string,
  target: PropTypes.oneOf([PropTypes.string, null]),
  href: PropTypes.string
};

export default LinkCell;
