// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsTableRow-module__badge--aWUkQ{background-color:#dbeafe;border-radius:4px;color:#1e40af;font-size:12px;margin-left:8px;padding:4px 8px}.ReportsTableRow-module__row--W7ZBt{height:60px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `ReportsTableRow-module__badge--aWUkQ`,
	"row": `ReportsTableRow-module__row--W7ZBt`
};
module.exports = ___CSS_LOADER_EXPORT___;
