import { IconButton, Tooltip } from '@ds';

import { reportRoute } from '@routes/utils/reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { DSShowIcon } from '@ds-icons';

const PreviewReportButton = ({ isTemplate, name }) => {
  const { projectName, workspace } = useParams();
  const history = useHistory();

  const viewUrl = reportRoute({
    isTemplate,
    projectName,
    reportName: name,
    workspaceName: workspace
  });

  const handleClick = () => history.push(viewUrl);

  return (
    <Tooltip content="Preview" placement="bottom" arrow={false}>
      <IconButton
        Icon={<DSShowIcon />}
        onClick={handleClick}
        type="secondary"
      />
    </Tooltip>
  );
};

PreviewReportButton.propTypes = {
  isTemplate: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};

export default PreviewReportButton;
