import { HighlightStyle, LRLanguage } from '@codemirror/language';
import { styleTags, tags as t } from '@lezer/highlight';
import { KEYWORDS, MULTIPLE_WHERE_OPERATORS } from './constants';
import { parser } from './comet-sql.grammar.js';

export const customSqlLanguage = LRLanguage.define({
  parser: parser.configure({
    props: [
      styleTags({
        ...Object.fromEntries(KEYWORDS.map(keyword => [keyword, t.keyword])),
        ...Object.fromEntries(
          MULTIPLE_WHERE_OPERATORS.map(operator => [
            operator,
            t.compareOperator
          ])
        ),
        MODEL: t.variableName,
        All: t.variableName,
        ColumnName: t.variableName,
        MathOperator: t.operator,
        ComputeNumericFunction: t.function(t.variableName),
        ComputeNumericFunctionOneArgument: t.function(t.variableName),
        NumericalCometFunction: t.function(t.variableName),
        CategoricalCometFunction: t.function(t.variableName),
        NumericOperator: t.function(t.variableName),
        NumericOperatorOneArgument: t.function(t.variableName),
        NumericOperatorTwoArgument: t.function(t.variableName),
        NumericOperatorMultipleArgument: t.function(t.variableName),
        Number: t.number,
        StringLiteral: t.string,
        WhereOperator: t.compareOperator,
        '(': t.paren,
        ')': t.paren
      })
    ]
  })
});

export const customSqlHighlightStyle = HighlightStyle.define([
  { tag: t.keyword, color: '#cf222e' },
  { tag: t.variableName, color: '#226cb5' },
  { tag: t.paren, color: '#339933' },
  { tag: t.string, color: '#032f62' },
  { tag: t.number, color: '#0550ae' },
  { tag: t.operator, color: '#cf222e' },
  { tag: t.compareOperator, color: '#9c131c' },
  { tag: t.function(t.variableName), color: '#0550ae' }
]);
