// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoveExperimentModal-module__moveExperimentModalContainer--RAJz5{color:var(--gray-6);margin-top:24px;width:433px;text-align:center}.MoveExperimentModal-module__moveExperimentModalContainer--RAJz5 a{color:var(--primary-color);text-decoration:underline}.MoveExperimentModal-module__wayToMoveFields--ukkdX{display:flex;margin-top:39px}.MoveExperimentModal-module__wayToMoveFields--ukkdX .MoveExperimentModal-module__wayToMoveField--Dv3pL{cursor:pointer;display:flex;font-weight:500}.MoveExperimentModal-module__wayToMoveFields--ukkdX .MoveExperimentModal-module__wayToMoveField--Dv3pL:first-child{margin-right:32px}.MoveExperimentModal-module__wayToMoveFields--ukkdX .MoveExperimentModal-module__wayToMoveFieldLabel--EFAom{margin-left:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moveExperimentModalContainer": `MoveExperimentModal-module__moveExperimentModalContainer--RAJz5`,
	"wayToMoveFields": `MoveExperimentModal-module__wayToMoveFields--ukkdX`,
	"wayToMoveField": `MoveExperimentModal-module__wayToMoveField--Dv3pL`,
	"wayToMoveFieldLabel": `MoveExperimentModal-module__wayToMoveFieldLabel--EFAom`
};
module.exports = ___CSS_LOADER_EXPORT___;
