import cx from 'classnames';
import noop from 'lodash/noop';
import React from 'react';

import { Box, Tooltip, makeStyles } from '@material-ui/core';
import MuiSlider, {
  SliderProps as MuiSliderProps
} from '@material-ui/core/Slider';

import './Slider.scss';

const useStyles = makeStyles({
  root: {
    '& .MuiSlider-rail, .MuiSlider-track': {
      height: '4px !important;'
    },
    '& .MuiSlider-mark': {
      height: '4px !important;'
    },
    '& .Mui-disabled': {
      marginTop: '-5px'
    },
    '& .MuiSlider-thumb.Mui-disabled': {
      background: 'var(--gray-4)'
    }
  },
  marked: {
    marginBottom: 0,
    '& .MuiSlider-mark': {
      color: 'var(--gray-4)'
    },
    '& .MuiSlider-markLabel': {
      display: 'none'
    }
  }
});

export type SliderProps = Omit<
  MuiSliderProps,
  'onChange' | 'onChangeCommitted'
> & {
  hideValueLabel?: boolean;
  onChange?: (v: number) => void;
  onChangeCommitted?: (v: number) => void;
  sliderSuffixFormat?: (v: number) => React.ReactNode;
  value: number;
  suffixWidth?: number;
  variant?: 'type-1' | 'type-2';
};

const defaultSliderSuffixFormat = (value: number) => String(+value);
const defaultValueLabelFormat = (value: number) => value.toFixed(1);
const toNumber = (n: number | number[]) => {
  if (Array.isArray(n)) {
    return n.length ? n[0] : 0;
  }

  return n;
};

const CHARACTER_WIDTH = 8.2;
const SEPARATOR_WIDTH = 4;

const calculateMinSuffixWidth = (min: number, max: number, step: number) => {
  let fractionalPartLength = 0;
  const isInteger = Number.isInteger(step);
  const maxLengthNumber = Math.max(Math.floor(min), Math.floor(max));

  if (!isInteger) {
    fractionalPartLength = String(step - Math.floor(step)).length - 2;
  }

  const characterWidth =
    (String(maxLengthNumber).length + fractionalPartLength) * CHARACTER_WIDTH;

  return characterWidth + (isInteger ? 0 : SEPARATOR_WIDTH);
};

export const Slider = ({
  disabled = false,
  hideValueLabel = false,
  max = 100,
  min = 0,
  onChange = noop,
  onChangeCommitted = noop,
  sliderSuffixFormat = defaultSliderSuffixFormat,
  step = 1,
  value = 0,
  suffixWidth,
  valueLabelDisplay = 'auto',
  valueLabelFormat = defaultValueLabelFormat,
  variant = 'type-1',
  ...rest
}: SliderProps) => {
  const classes = useStyles();
  const hasSameMinMaxValue = min === max;
  const minSuffixWidth =
    sliderSuffixFormat === defaultSliderSuffixFormat
      ? calculateMinSuffixWidth(min, max, step as number)
      : undefined;

  return (
    <div className={cx('ds-slider-container', variant)}>
      <Tooltip
        disableTouchListener={!hasSameMinMaxValue}
        disableFocusListener={!hasSameMinMaxValue}
        disableHoverListener={!hasSameMinMaxValue}
        placement="top"
        title="Slider not applicable, only single value is displayed"
      >
        <Box display="flex" alignItems="center">
          <MuiSlider
            disabled={disabled || hasSameMinMaxValue}
            max={max}
            min={hasSameMinMaxValue ? -1 : min}
            onChange={(event, newValue) => onChange(toNumber(newValue))}
            onChangeCommitted={(event, newValue) =>
              onChangeCommitted(toNumber(newValue))
            }
            step={step}
            value={value}
            valueLabelDisplay={valueLabelDisplay}
            valueLabelFormat={valueLabelFormat}
            {...rest}
            classes={{ ...classes, ...rest.classes }}
          />
          {!hideValueLabel && (
            <div
              className="value-label"
              style={{ minWidth: suffixWidth ?? minSuffixWidth }}
            >
              {sliderSuffixFormat(value)}
            </div>
          )}
        </Box>
      </Tooltip>
    </div>
  );
};

export default Slider;
