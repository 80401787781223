import { useEffect } from 'react';

const useSegment = username => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.identify(username);
    }
  }, [username]);
};

export default useSegment;
