import React from 'react';
import cx from 'classnames';

import { TextButton } from '@ds';
import typography from '@ds-typography';

import styles from './PanelsNoSearchResults.module.scss';

type PanelsNoSearchResultsProps = {
  regexpError?: boolean;
  resetSearch: () => void;
};

const PanelsNoSearchResults = ({
  regexpError = false,
  resetSearch
}: PanelsNoSearchResultsProps) => {
  return (
    <div className={styles.container}>
      <img
        src="/images/empty-states/no-search-results.svg"
        alt="no search results"
      />
      <div className={cx(typography.dsLightSmall, styles.header)}>
        No search results
      </div>
      {regexpError && (
        <div className={cx(typography.dsBody, styles.error)}>
          The search string is not valid regex
        </div>
      )}
      <TextButton type="primary" size="small" onClick={resetSearch}>
        Clear search
      </TextButton>
    </div>
  );
};

export default PanelsNoSearchResults;
