import { push } from 'connected-react-router';

import { workspaceActionTypes } from '../constants/actionTypes';
import {
  MIN_ITEM_COUNT_FOR_TABLE_DISPLAY,
  WORKSPACE_URL_HASHES
} from '../constants/workspaceConstants';

import artifactActions from './artifactActions';
import modelRegistryActions from './modelRegistryActions';

import { dialogTypes, snackbarTypes } from '../constants/alertTypes';
import { getTabState } from '../reducers/ui/workspaceUiReducer';
import alertsUtil from '../util/alertsUtil';
import workspaceApi from '../util/workspaceApi';

const workspaceActions = {
  updateTabStateByKey(tabKey, stateKey, update) {
    return {
      type: workspaceActionTypes.SET_TAB_STATE_BY_KEY,
      payload: { tabKey, stateKey, update }
    };
  },

  updateTabState(tabKey, update) {
    return {
      type: workspaceActionTypes.SET_TAB_STATE,
      payload: { tabKey, update }
    };
  },

  setItemCountAndDisplay(tabKey, itemCount, isSearchActive = false) {
    return (dispatch, getState) => {
      const state = getState();

      const { isCardDisplay: currentDisplay } = getTabState(state, { tabKey });

      const initialDisplay = itemCount < MIN_ITEM_COUNT_FOR_TABLE_DISPLAY;
      const isCardDisplay =
        itemCount === 0 && !isSearchActive ? initialDisplay : currentDisplay;

      dispatch({
        type: workspaceActionTypes.SET_ITEM_COUNT_AND_DISPLAY,
        payload: {
          tabKey,
          itemCount,
          isCardDisplay
        }
      });
    };
  },

  fetchItemCounts(workspaceName) {
    return dispatch => {
      dispatch(modelRegistryActions.fetchModelRegistryCount(workspaceName));
      dispatch(artifactActions.fetchArtifactsCount(workspaceName));
    };
  },

  setManageWorkspaceModalState({ open, workspaceName, workspaceId }) {
    return dispatch => {
      dispatch({
        type: workspaceActionTypes.SET_MANAGE_WORKSPACE_MODAL_STATE,
        payload: { open, workspaceName, workspaceId }
      });
    };
  },

  setActiveWorkspaceName(workspaceName) {
    return dispatch => {
      dispatch({
        type: workspaceActionTypes.SET_ACTIVE_WORKSPACE_NAME,
        payload: { workspaceName }
      });
    };
  },

  setAllWorkspaceList(workspaces) {
    return dispatch => {
      dispatch({
        type: workspaceActionTypes.SET_ALL_USER_WORKSPACE_LIST,
        payload: { workspaces }
      });
    };
  },

  claimWorkspaceInvite(workspaceCode) {
    return dispatch => {
      return workspaceApi
        .claimInviteWorkspaceCode(workspaceCode)
        .then(response => {
          const { team, msg } = response.data;
          if (team) {
            dispatch(push(`/${team.teamName}`));
          }
          dispatch(
            alertsUtil.openSnackbarDialog(
              dialogTypes.TEAM_INVITE_CODE_MESSAGE,
              msg
            )
          );
        })
        .catch(error => {
          const errorMessage = error.response.data.msg;
          dispatch(
            alertsUtil.openErrorDialog(
              'error-snackbar-dialog',
              `Error: ${errorMessage}`
            )
          );
        });
    };
  },

  switchTeam(
    teamName,
    withoutRedirection = false,
    { url = '', showDialog = true } = {}
  ) {
    return dispatch => {
      dispatch(workspaceActions.setActiveWorkspaceName(teamName));
      if (!withoutRedirection) {
        dispatch(push(url || `/${teamName}${WORKSPACE_URL_HASHES.PROJECTS}`));
        if (showDialog) {
          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_SNACKBAR_DIALOG,
              `Your active workspace has been changed to: ${teamName}.`
            )
          );
        }
      }
    };
  },

  setBreadcrumbsItems(items) {
    return {
      type: workspaceActionTypes.SET_BREADCRUMBS_ITEMS,
      payload: { items }
    };
  }
};

export default workspaceActions;
