import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ds';
import { MoveExperimentModal } from '@experiment-management-shared/components';
import { DSMoveIcon } from '@ds-icons';

const MoveExperimentModalButton = ({
  disabled,
  selectedExperiments,
  onMove
}) => {
  const [isOpen, setIsOpen] = useState();
  const handleClick = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleClick}
        size="large"
        PrefixIcon={<DSMoveIcon />}
      >
        Move
      </Button>
      <MoveExperimentModal
        redirect={false}
        onMove={onMove}
        selectedExperiments={selectedExperiments.map(
          ({ experimentKey }) => experimentKey
        )}
        open={isOpen}
        onClose={onClose}
      />
    </>
  );
};

MoveExperimentModalButton.defaultProps = {
  disabled: false
};

MoveExperimentModalButton.propTypes = {
  disabled: PropTypes.bool
};

export default MoveExperimentModalButton;
