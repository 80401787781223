import React from 'react';
import { useSearchListContext } from '../useSearchListContext';
import classes from '../List.module.scss';

const DefaultListEmpty = () => <span>Empty list</span>;

type ListEmptyProps = {
  children?: React.ReactNode;
};
const ListEmpty: React.FC<ListEmptyProps> = ({ children }) => {
  const { isEmpty } = useSearchListContext();

  if (!isEmpty) return null;

  return (
    <div className={classes.searchableListContainerEmpty}>
      {children || <DefaultListEmpty />}
    </div>
  );
};

export default ListEmpty;
