import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import isArray from 'lodash/isArray';

import useProjectViews from '@API/project/useProjectViews';
import useExperiments from '@API/experiments/useExperiments';
import useColumns from '@API/project/useColumns';
import { DEFAULT_VIEW } from '@experiment-management-shared/utils/view';

import { fromTemplateToDashboard } from '@experiment-management-shared/utils/EMView';
import { chartDataErrorTypes } from '@experiment-management-shared/constants/chartConstants';

import { ChartError } from '@experiment-management-shared/components/Charts/ChartError';

import SmallLoader from '@shared/components/SmallLoader';
import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import { getPanelByIdFromSections } from '@experiment-management-shared';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import useBuiltInChart from '@experiment-management-shared/hooks/useBuiltInChart';

const BuiltInEmbeddedPanel = ({
  chartId,
  hardcodedExperimentKeys,
  onLoad,
  projectId,
  revision,
  viewId
}) => {
  const [errorType, setErrorType] = useState(null);

  const {
    data: views,
    isLoading: isLoadingViews,
    isError: isErrorViews
  } = useProjectViews();

  const {
    data: columns,
    isLoading: isLoadingColumns,
    isError: isErrorColumns
  } = useColumns();

  const view = useMemo(() => {
    if (!isArray(views) || isLoadingViews || isLoadingColumns) return null;
    const template = views.find(v => v.templateId === viewId);

    if (!template) {
      setErrorType(chartDataErrorTypes.TEMPLATE_NOT_FOUND);
    }

    return fromTemplateToDashboard(template, DEFAULT_VIEW, columns);
  }, [views, isLoadingViews, isLoadingColumns, columns, viewId]);

  const chartConfig = useMemo(() => {
    if (!view) return null;

    const config = getPanelByIdFromSections(
      view?.panels?.sections || [],
      chartId
    );

    if (!config) {
      setErrorType(chartDataErrorTypes.TEMPLATE_NOT_FOUND);
    }

    return config;
  }, [chartId, view]);

  const {
    data: experimentsData,
    isIdle: isIdleExperiments,
    isLoading: isLoadingExperiments
  } = useExperiments(
    {
      targetExperimentKeys: hardcodedExperimentKeys,
      view,
      revision
    },
    { refetchOnMount: true, enabled: !isLoadingColumns && !isLoadingViews }
  );

  const [experimentKeys, experiments] = useMemo(() => {
    if (isIdleExperiments || isLoadingExperiments) return [null, null];

    if (!experimentsData?.experiments.length) {
      setErrorType(chartDataErrorTypes.EMPTY_DATA);
      return [null, null];
    }

    return [
      experimentsData?.experiments.map(e => e.experimentKey),
      experimentsData?.experiments
    ];
  }, [isIdleExperiments, isLoadingExperiments, experimentsData]);

  const config = useMemo(() => {
    if (!chartConfig || !experimentKeys || !view) return null;

    return {
      metricNames: [chartConfig.metricName],
      ...chartConfig,
      experimentKeys,
      experiments,
      hiddenExperimentKeys: view.hiddenExperimentKeys,
      isChartPreview: false,
      projectId
    };
  }, [chartConfig, experimentKeys, experiments, view, projectId]);

  useEffect(() => {
    if (!config) return;

    const { chartName, chartType } = config;

    const name =
      chartName || chartHelpers.getDefaultChartNameByType(chartType, config);

    onLoad(name);
  }, [config, onLoad]);

  const builtInChart = useBuiltInChart();

  const containerProps = useMemo(() => {
    return {
      chartId: config?.chartId,
      chartType: config?.chartType,
      experimentKeys: config?.experimentKeys,
      hideHeader: true,
      projectId
    };
  }, [config?.chartId, config?.chartType, config?.experimentKeys, projectId]);

  if (errorType || isErrorColumns || isErrorViews) {
    const type = errorType ?? chartDataErrorTypes.FETCHING_ERROR;

    return (
      <div className="chart-data-error-container">
        <ChartError type={type} />
      </div>
    );
  }

  if (!config) {
    return (
      <SmallLoader
        disableAnimations
        isFlexDisplay
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  return builtInChart.getComponent({
    chartType: config.chartType,
    chartConfig: config,
    config: {
      containerProps,
      isImageClickable: true,
      hideVideo: true
    }
  });
};

BuiltInEmbeddedPanel.defaultProps = {
  chartId: '',
  hardcodedExperimentKeys: null,
  onLoad: noop,
  projectId: '',
  revision: 0,
  viewId: ''
};

BuiltInEmbeddedPanel.propTypes = {
  chartId: PropTypes.string,
  hardcodedExperimentKeys: PropTypes.array,
  onLoad: PropTypes.func,
  projectId: PropTypes.string,
  revision: PropTypes.number,
  viewId: PropTypes.string
};

export default BuiltInEmbeddedPanel;
