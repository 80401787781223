import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FilterContainer from '@shared/components/FilterContainer';

const FiltersPopover = ({ selectedFilters, filters, handleFilterChange }) => {
  const isFilterChecked = (filterKey, optionValue) => {
    return get(selectedFilters, filterKey, []).includes(optionValue);
  };

  const handleFilterSelect = (filterKey, optionValue) => {
    const currentFiltersForKey = get(selectedFilters, filterKey, []);

    let newSelectedValues;
    if (isFilterChecked(filterKey, optionValue)) {
      newSelectedValues = currentFiltersForKey.filter(
        value => value !== optionValue
      );
    } else {
      newSelectedValues = [...currentFiltersForKey, optionValue];
    }

    handleFilterChange({ [filterKey]: newSelectedValues });
  };

  const handleClearAll = () => {
    handleFilterChange({});
  };

  const selectedFiltersCount = useMemo(() => {
    return Object.values(selectedFilters).reduce((sum, arr) => {
      sum += arr.length;
      return sum;
    }, 0);
  }, [selectedFilters]);

  const renderItems = () => {
    return filters.map(filter => {
      const { groupLabel, filterKey, options } = filter;

      return (
        <Fragment key={groupLabel}>
          <ListItem className="filter-list-title">
            <ListItemText>{groupLabel}</ListItemText>
          </ListItem>
          {options.map(({ label, value }) => {
            return (
              <ListItem key={value} className="filter-list-item">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFilterChecked(filterKey, value)}
                        onChange={() => handleFilterSelect(filterKey, value)}
                        color="primary"
                      />
                    }
                    label={label}
                  />
                </FormGroup>
              </ListItem>
            );
          })}
        </Fragment>
      );
    });
  };

  return (
    <FilterContainer
      groupLabel="Filter"
      selectedLabel={`(${selectedFiltersCount})`}
      resetSelectionHandler={handleClearAll}
    >
      {renderItems()}
    </FilterContainer>
  );
};

FiltersPopover.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default FiltersPopover;
