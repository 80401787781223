import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import Divider from '@material-ui/core/Divider';

import { DIR_TYPE } from '@experiment-management-shared/constants/fileBrowser';
import Metadata from './Metadata';
import { canShowPreviewInModal } from '@experiment-management-shared/utils/filesTreeUtils';
import TextPreview from '@experiment-management-shared/components/AssetsPreview/TextPreview';

const fileImageUrl = '/images/asset-tab/asset-preview.png';

const AudioIcon = () => <i className="material-icons image">audiotrack</i>;
const FolderIcon = () => <i className="material-icons image">folder</i>;
const VideoIcon = () => <i className="material-icons image">videocam</i>;

const Legend = ({ experimentKey, item, viewClickHandler }) => {
  if (!item) {
    return (
      <div className="files-browser-legend">
        <FolderIcon />
        <div className="folder-title">Select a file to preview</div>
      </div>
    );
  }

  const {
    assetId,
    audio,
    canView,
    compressedAssetLink,
    fileName,
    fileSize,
    link,
    remote,
    type,
    video
  } = item;

  const renderPreview = () => {
    if (remote)
      return (
        <div>
          <div
            style={{ padding: 20, display: 'flex', flexDirection: 'column' }}
          >
            <span style={{ fontWeight: '500', color: '#343d4e' }}>
              {fileName}
            </span>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </div>
          <Divider variant="middle" />
        </div>
      );

    if (type === DIR_TYPE) return <FolderIcon />;

    if (video) return <VideoIcon />;

    if (audio) return <AudioIcon />;

    if (canView) {
      const src = compressedAssetLink || link;

      return <img className="image" src={src} />;
    }

    if (canShowPreviewInModal(item)) {
      return (
        <TextPreview
          assetId={assetId}
          experimentKey={experimentKey}
          fileName={fileName}
          fileSize={fileSize}
          className="asset-tab-text-preview"
        />
      );
    }

    return <img className="image asset-preview" src={fileImageUrl} />;
  };

  return (
    <div className="files-browser-legend">
      {renderPreview()}
      <Metadata
        experimentKey={experimentKey}
        item={item}
        onViewClick={viewClickHandler}
      />
    </div>
  );
};

Legend.defaultProps = {
  experimentKey: '',
  item: null,
  viewClickHandler: noop
};

Legend.propTypes = {
  experimentKey: PropTypes.string,
  item: PropTypes.object,
  viewClickHandler: PropTypes.func
};

export default Legend;
