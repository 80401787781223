import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { BasicModal } from '@DesignSystem/modals';
import cx from 'classnames';
import { useCurrentOrganization, useCurrentPaymentPlan } from '@shared/hooks';

const TrialIsOverModal = () => {
  const { isSuspended } = useCurrentPaymentPlan();
  const currentOrganization = useCurrentOrganization();
  const history = useHistory();
  const location = useLocation();

  const isAdmin = currentOrganization?.isAdmin;

  const primaryButtonText = isAdmin
    ? 'Provide billing details'
    : 'Switch organization';

  const contentText = isAdmin
    ? `We hope you enjoyed your free trial. If you want to continue with
  Comet service and unlock more features, please provide your credit
  card details.`
    : `We hope you enjoyed your free trial. We are waiting for the 'Organization Admin' to update the payment plan. You can however switch to another organization while you wait.`;

  const isBillingOpen =
    location?.pathname === `/organizations/${currentOrganization?.id}/billing`;

  const isSelectOrganizationOpen = location?.pathname === '/organizations';

  const isOpen = isSuspended && !isBillingOpen && !isSelectOrganizationOpen;

  const handlePrimaryButtonClick = () => {
    if (isAdmin) {
      return history.push(
        `/organizations/${currentOrganization?.id}/billing?initialOpenUpgradeCard=true`
      );
    }

    return history.push('/organizations');
  };

  return (
    <BasicModal
      open={isOpen}
      hideCross
      content={
        <div className="trial-modal-container">
          <img src="/images/account-settings/trial-is-over.svg" />
          <div className="trial-label-container">{contentText}</div>
        </div>
      }
      title="Your trial is over"
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      className={cx('trial-modal-wrapper', { open: isOpen })}
    />
  );
};

export default TrialIsOverModal;
