import React, {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  useCallback
} from 'react';
import cx from 'classnames';
import { isEmpty, difference } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import noop from 'lodash/noop';

import { RoundedTabs } from '@DesignSystem/navigation';
import { Divider } from '@DesignSystem/data-display';
import { Table } from '@DesignSystem/tables';
import { InfoWithTooltipIcon, DeleteAlertIcon } from '@Icons-outdated';
import {
  DELETE_SINGLE_RULE_MODAL_BODY_TEXT,
  DELETE_SINGLE_RULE_MODAL_TITLE,
  ALERT_NOTIFICATIONS_RIGHT_COLUMN,
  ALERT_RULES_COLUMNS,
  ALERT_RULES_LEFT_COLUMN,
  ALERT_RULES_NAME_COLUMN_WIDTH,
  ALERT_RULES_PAGE_SIZES,
  ALERT_RULES_PREVIEW_PAGE_SIZES,
  DEFAULT_ALERT_RULES_SORTING,
  disabledRulesSortingColumns,
  RULES_INFO_TOOLTIP_MESSAGE,
  uniqueRowIdKey
} from '@mpm-druid/constants';
import {
  useAlertRules,
  useAlertRulesByID,
  useAlertRulesDeleteById
} from '@mpm-druid/api';
import { dialogTypes } from '@/constants/alertTypes';
import TextCell from '@shared/components/TableCells/TextCell';
import { OpenNotificationsCell, AlertRulesTableCellType } from '../Cells';
import alertsUtil from '@/util/alertsUtil';
import DeleteItemsModal from '@shared/components/DeleteItemsModal';
import SmallLoader from '@shared/components/SmallLoader';
import { Tooltip } from '@ds';
import DateCell from '@shared/components/TableCells/DateCell';
import AlertRulesActions from './AlertRulesActions';
import MoreActionsCell from '@shared/components/TableCells/MoreActionsCell';
import TableEmptyState from '@shared/components/TableEmptyState';
import { formatUnixForTable, getTimeIntervalFromCron } from '@mpm-druid/utils';
import { ModelType } from '@mpm-druid/types';

const disabledColumns = ['name', 'description'];

type AlertRulesPageProps = {
  model: ModelType;
  previewPage?: boolean;
  dataTracker?: Dispatch<boolean>;
};

type TableDataType = {
  cols: [string];
  cell: React.FC<AlertRulesTableCellType>;
};

export const AlertRulesPage = ({
  model,
  previewPage = false,
  dataTracker = noop
}: AlertRulesPageProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [columnSorting, setColumnSorting] = useState(
    DEFAULT_ALERT_RULES_SORTING
  );
  const [selection, setSelection] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(ALERT_RULES_PAGE_SIZES[0]);
  const ALERT_RULES_ACTIONS_COLUMN_WIDTH = previewPage ? 25 : 33;

  const [columnOrder, setColumnOrder] = useState([]);
  const [rows, setRows] = useState<unknown[]>([]);
  const [totalAlertRules, setTotalAlertRules] = useState(0);
  const [columnWidths, setColumnWidths] = useState([
    {
      columnName: 'name',
      width: ALERT_RULES_NAME_COLUMN_WIDTH
    },
    {
      columnName: 'actions',
      width: ALERT_RULES_ACTIONS_COLUMN_WIDTH
    }
  ]);

  const noPaddingColumns = ['actions', ...(previewPage ? [] : ['name'])];

  const deleteAlertRulesById = useAlertRulesDeleteById();
  const dispatch = useDispatch();

  const tableHeight = selection.length > 0 ? 336 : 276;

  const { modelId } = useParams<{ modelId: string }>();
  const {
    isLoading,
    isError,
    data,
    isFetching,
    isPreviousData
  } = useAlertRules(
    {
      modelId,
      page: pageNumber + 1,
      pageSize
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const deleteCallback = useCallback(ids => {
    setSelection(prevSelection => difference(prevSelection, ids));
  }, []);

  const handleDeleteRule = (row: AlertRulesTableCellType) => {
    const modalId = dialogTypes.CONFIRM_DELETE_ALERT_RULE;
    const deleteRuleHandler = () => {
      const ruleId = [row.id];
      deleteAlertRulesById.mutate({
        monitorIds: ruleId,
        deleteCallback
      });
    };

    const deleteRuleModal = (
      <DeleteItemsModal
        modalId={modalId}
        title={DELETE_SINGLE_RULE_MODAL_TITLE}
        subTitle={DELETE_SINGLE_RULE_MODAL_BODY_TEXT(row.name)}
        submitHandler={deleteRuleHandler}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, deleteRuleModal));
  };

  const actionsList = useMemo(
    () => [
      {
        title: 'Delete Alert',
        subtitle: 'Remove alert rule',
        icon: <DeleteAlertIcon />,
        clickHandler: handleDeleteRule
      }
    ],
    []
  );

  const dataTypes = useMemo<TableDataType[]>(
    () => [
      {
        cols: ['name'],
        cell: ({ value }) => {
          return <TextCell value={value} />;
        }
      },
      {
        cols: ['openNotifications'],
        cell: OpenNotificationsCell
      },
      {
        cols: ['createdAt'],
        cell: ({ value }) => (
          <DateCell
            value={value}
            formatter={value => {
              return formatUnixForTable(value, 'YYYY-MM-DD HH:mm:ss');
            }}
          />
        )
      },
      {
        cols: ['modelVersion'],
        cell: ({ value }) => <TextCell value={value || 'All'} />
      },
      {
        cols: ['intervalType'],
        cell: ({ value }) => <TextCell value={getTimeIntervalFromCron(value)} />
      },
      {
        cols: ['actions'],
        cell: ({ row }) => (
          <MoreActionsCell row={row} actionsList={actionsList} />
        )
      }
    ],
    []
  );

  useEffect(() => {
    if (previewPage) {
      setPageSize(ALERT_RULES_PREVIEW_PAGE_SIZES);
    }
  }, [previewPage]);

  const deleteRulesByIds = () => {
    const alertRuleIds = selection;
    deleteAlertRulesById.mutate({ monitorIds: alertRuleIds });
    setSelection([]);
  };

  useEffect(() => {
    if (data?.alertRules.length === 0 && data?.total > 0) {
      const lastPage = Math.max(Math.ceil(data.total / pageSize) - 1, 0);
      setPageNumber(lastPage);
    } else if (data?.alertRules) {
      setRows(data.alertRules);
      setTotalAlertRules(data.total);
    }
  }, [data]);

  useEffect(() => {
    if (typeof dataTracker === 'function') {
      if (data && data.alertRules.length > 0 && previewPage) {
        dataTracker(true);
      } else if (previewPage) {
        dataTracker(false);
      }
    }
  }, [data, previewPage, dataTracker]);

  useEffect(() => {
    if (isEmpty(selection)) {
      setActiveTabIndex(0);
    }
  }, [selection]);

  const {
    data: selectionRowsData,
    isLoading: isLoadingSelection
  } = useAlertRulesByID(
    {
      modelId,
      monitorIds: selection
    },
    { enabled: activeTabIndex === 1 }
  );

  useEffect(() => {
    setPageNumber(0);
  }, [pageSize]);

  const rowsFilteredForActiveTab = useMemo(() => {
    if (activeTabIndex === 1 && selectionRowsData) {
      return selectionRowsData.data;
    }

    return rows;
  }, [activeTabIndex, rows, selectionRowsData]);

  if (isLoading)
    return (
      <SmallLoader
        primaryMessage={
          <p>
            Fetching alert rules for <b>{model.name}</b>
          </p>
        }
      />
    );

  if (isError) {
    return <h1> Error while fetching the model`s alert rules. </h1>;
  }

  return (
    <>
      <div
        className={cx('model-features-header', {
          'alerts-preview-header': previewPage
        })}
      >
        <div className="model-features-header-top">
          <div className="model-features-header-title">Alert Rules</div>
          {previewPage && totalAlertRules > 0 && (
            <Tooltip content={RULES_INFO_TOOLTIP_MESSAGE} placement="top">
              <span>
                <InfoWithTooltipIcon className="alerts-info-icon" />
              </span>
            </Tooltip>
          )}
        </div>
        {!previewPage && (
          <>
            <Divider margin={0} />
            <div className="model-features-header-tabs">
              <RoundedTabs
                tabs={[
                  { label: 'All' },
                  {
                    label: selection.length
                      ? `Selected (${selection.length})`
                      : 'Selected',
                    disabled: selection.length === 0
                  }
                ]}
                activeTabIndex={activeTabIndex}
                onTabChange={setActiveTabIndex}
              />
            </div>
          </>
        )}
      </div>
      <Table
        shouldHighlightLinks
        noPaddingColumns={noPaddingColumns}
        isFetching={(isFetching && isPreviousData) || isLoadingSelection}
        dataTypes={dataTypes}
        columns={ALERT_RULES_COLUMNS}
        maxHeight={tableHeight}
        rows={rowsFilteredForActiveTab}
        leftColumns={ALERT_RULES_LEFT_COLUMN}
        rightColumns={ALERT_NOTIFICATIONS_RIGHT_COLUMN}
        rowHeight="60px"
        rowIdKey={uniqueRowIdKey}
        renderEmptyState={props => (
          <TableEmptyState
            label="No alert triggers have been created"
            materialProps={props}
            pictureHeight="56px"
            pictureWidth="63px"
            picturePath="/images/alert-rules-empty.svg"
          />
        )}
        totalRowCount={
          activeTabIndex === 0
            ? totalAlertRules
            : rowsFilteredForActiveTab.length
        }
        sortingConfig={{
          isDisabled: false,
          columnSorting,
          disabledColumns: disabledRulesSortingColumns,
          onSortingChange: setColumnSorting
        }}
        paginationConfig={{
          isDisabled: previewPage,
          isServerSidePagination: activeTabIndex !== 1,
          pageNumber,
          pageSize,
          pageSizes: ALERT_RULES_PAGE_SIZES,
          onPageNumberChange: setPageNumber,
          onPageSizeChange: setPageSize
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          disabledColumns,
          onColumnWidthsChange: setColumnWidths
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        selectionConfig={{
          selection,
          isDisabled: previewPage,
          onSelectionChange: setSelection
        }}
      />
      {selection.length > 0 && (
        <AlertRulesActions
          selectedItemsTotal={selection.length}
          deleteItemsHandler={deleteRulesByIds}
        />
      )}
    </>
  );
};
