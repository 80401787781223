import React from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@ds';

import CircularProgress from '@material-ui/core/CircularProgress';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';
import { DSDownloadIcon, DSOpenInNewIcon } from '@ds-icons';

export const OutputTabBar = ({
  activeTab,
  outputTabs,
  waitingForDownloadData,
  onViewRaw,
  onExportData,
  onTabChange,
  isDisabled
}) => (
  <div className="output-bar-wrapper">
    <div className="tabs-wrapper">
      <StyledTabs
        value={activeTab}
        onChange={onTabChange}
        disabled={isDisabled}
      >
        {outputTabs.map(value => (
          <StyledTab
            key={value}
            value={value}
            label={value}
            disabled={isDisabled}
          />
        ))}
      </StyledTabs>
    </div>
    <div className="buttons-wrapper">
      <TextButton
        onClick={onViewRaw}
        type="secondary"
        PrefixIcon={<DSOpenInNewIcon />}
        disabled={isDisabled}
      >
        Raw data
      </TextButton>
      {waitingForDownloadData ? (
        <CircularProgress size={12} />
      ) : (
        <TextButton
          onClick={onExportData}
          PrefixIcon={<DSDownloadIcon />}
          type="secondary"
          disabled={isDisabled}
        >
          Download
        </TextButton>
      )}
    </div>
  </div>
);

OutputTabBar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  outputTabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  waitingForDownloadData: PropTypes.bool.isRequired,
  onViewRaw: PropTypes.func.isRequired,
  onExportData: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

export default OutputTabBar;
