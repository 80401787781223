import { useEffect, useState } from 'react';
import history from '@/history';

// use this instead of useLocation
// useLocation triggers a re-render causing performance issues
// on this critical bottle neck
export default function usePathname() {
  const [pathname, setPathname] = useState(history.location.pathname);

  useEffect(() => {
    const unsubscribe = history.listen(newLocation => {
      setPathname(newLocation.pathname);
    });

    return () => unsubscribe();
  }, []);

  return pathname;
}
