import { useCallback, useState } from 'react';

const useBatchChartContainerGridHandlers = () => {
  const [isLayoutEditing, setIsLayoutEditing] = useState(false);

  const setIsEdit = useCallback(() => setIsLayoutEditing(true), []);
  const setIsNotEdit = useCallback(() => setIsLayoutEditing(false), []);

  return {
    onDragStart: setIsEdit,
    onDragStop: setIsNotEdit,
    onResizeStart: setIsEdit,
    onResizeStop: setIsNotEdit,
    onMouseUp: setIsNotEdit,
    isLayoutEditing
  };
};

export default useBatchChartContainerGridHandlers;
