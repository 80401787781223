import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { calculateExperimentName } from '@experiment-management-shared/utils/experimentHelpers';

import NoteTab from '@experiment-details/components/NoteTab';
import styles from './NoteCompareTab.module.scss';

function NoteCompareTab({ experiments }) {
  const renderExperimentNotes = experiment => (
    <div key={experiment.experimentKey}>
      <h5 className={cx(styles.compareNotesTitle, 'text-center text-overflow')}>
        {calculateExperimentName(experiment)}
      </h5>

      <NoteTab experiments={[experiment]} isComparePage />
    </div>
  );

  return (
    <div className={styles.compareNotesContainer}>
      {experiments.map(renderExperimentNotes)}
    </div>
  );
}

NoteCompareTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default NoteCompareTab;
