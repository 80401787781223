import React, { useState } from 'react';
import cx from 'classnames';
import typography from '@ds-typography';
import { useUpdateOnboardingFlagMutation } from '@onboarding/api';
import { CrossCircleIcon } from '@Icons-outdated';
import useCurrentUser from '@API/auth/useCurrentUser';
import { useIsOnOnboardingPage } from '@onboarding/hooks';
import { useOnboardingFirstClick } from '@onboarding/hooks/biEvents';

import styles from './HelpSidebar.module.scss';
import HelpSidebar from './HelpSidebar';

const HelpButton = () => {
  const [open, setOpen] = useState(false);

  const { onSendFirstClickEvent } = useOnboardingFirstClick();

  const [helpButtonHovered, setHelpButtonHovered] = useState(false);
  const openSidebar = () => {
    onSendFirstClickEvent('[Help button]');
    setOpen(true);
  };
  const closeSidebar = () => setOpen(false);

  const { data: currentUser } = useCurrentUser();
  const isOnOnboardingPage = useIsOnOnboardingPage();

  const updateOnboardingFlagMutation = useUpdateOnboardingFlagMutation();

  const onCloseHelpCenterClick = () => {
    onSendFirstClickEvent('[Hide help button]');
    updateOnboardingFlagMutation.mutate({ flags: { hideHelpCenter: true } });
  };

  if (!currentUser || !isOnOnboardingPage || currentUser.hideHelpCenter) {
    return null;
  }

  return (
    <>
      <HelpSidebar open={open} onClose={closeSidebar} />
      <div
        className={styles.helpButtonContainer}
        onMouseEnter={() => setHelpButtonHovered(true)}
        onMouseLeave={() => setHelpButtonHovered(false)}
      >
        {helpButtonHovered && (
          <div
            className={styles.removeHelpButtonContainer}
            onClick={onCloseHelpCenterClick}
          >
            <CrossCircleIcon />
          </div>
        )}
        <button
          onClick={openSidebar}
          className={cx(
            typography.dsAction,
            typography.dsAccented,
            styles.helpButton
          )}
        >
          Help
        </button>
      </div>
    </>
  );
};

export default HelpButton;
