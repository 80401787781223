export const ON_SUCCESS = 'Password successfully changed';
export const LOADING_SEND_EMAIL = 'Sending email...';
export const INVALID_EMAIL_ADDRESS = 'Invalid email address';
export const NOT_MATCH_PASSWORD = "Passwords don't match";
export const TOO_SHORT_PASSWORD =
  'Your password must be longer than six characters';
export const EMAIL_SENT =
  'Check your email for a link to reset your password.If it does not appear in the next few minutes, check your email spam folder';
export const NO_MATCHING_ACCOUNT =
  "We couldn't find an account matching those details";
export const NEW_PASSWORD_ERROR = "We couldn't reset your password";
