import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import useCurrentUser from '@API/auth/useCurrentUser';
import workspaceApi from '@/util/workspaceApi';
import workspaceActions from '@/actions/workspaceActions';
import { OrganizationMember, Workspace } from '@shared/types';

interface ServerWorkspace {
  createdAt: number;
  default: boolean;
  membersPreview: OrganizationMember[] | null;
  organizationId: string;
  projectCount: number;
  totalMembers: number;
  collaborationFeaturesDisabled: boolean;

  // old and new workspaces have either of them
  workspaceCreator?: string;
  createdBy?: string;

  workspaceId: string;
  workspaceName: string;
  workspaceOwner: string;

  storageUsage: number;
}

interface UseAllUserWorkspacesConfig {
  enabled?: boolean;
}

export const mapWorkspaceFromNewEndpoint = (
  workspace: ServerWorkspace
): Workspace => ({
  id: workspace.workspaceId,
  isDefault: workspace.default,
  name: workspace.workspaceName,
  projectsCount: workspace.projectCount,
  membersCount: workspace.totalMembers,
  createdBy: workspace.createdBy || workspace.workspaceCreator || '',
  organizationId: workspace.organizationId,
  createdAt: workspace?.createdAt,
  storageUsage: workspace?.storageUsage,
  collaborationFeaturesDisabled: workspace.collaborationFeaturesDisabled
});

const getOrganizationWorkspaces = async (): Promise<ServerWorkspace[]> => {
  const result = await workspaceApi.fetchAllWorkspacesForUser();

  return result?.data;
};

//  all the workspaces of a current user
export default function useAllUserWorkspaces(
  config?: UseAllUserWorkspacesConfig
) {
  const dispatch = useDispatch();
  const { data: currentUser } = useCurrentUser();
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['workspaces', 'all'],
    async () => {
      const workspaces = await getOrganizationWorkspaces();

      return workspaces.map(mapWorkspaceFromNewEndpoint);
    },
    {
      enabled: configEnabled && !!currentUser?.apiKey,
      onSuccess: resWorkspaces => {
        dispatch(workspaceActions.setAllWorkspaceList(resWorkspaces));
      }
    }
  );
}
