import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { InfoIcon } from '@Icons-outdated';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import classNames from './InfoBlock.module.scss';

const InfoBlock = ({ text, classes = {}, renderIcon }) => {
  return (
    <Grid container spacing={1} className={classes?.container}>
      <Grid item xs="auto">
        <span className={cx(classNames.dsInfoBlockIcon, classes.icon)}>
          {renderIcon ? renderIcon() : null}
        </span>
      </Grid>
      <Grid item xs="auto">
        <Typography
          variant="text2"
          className={cx(classNames.dsInfoBlockText, classes?.text)}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

InfoBlock.defaultProps = {
  renderIcon: () => <InfoIcon />,
  classes: {
    text: null,
    container: null
  }
};

InfoBlock.propTypes = {
  text: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  renderIcon: PropTypes.func,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    container: PropTypes.string
  })
};

export default InfoBlock;
