import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { AlertWarningIcon } from '@Icons-outdated';

import './Alert.scss';

export const ALERT_TYPES = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error'
};

export const Alert = ({ message, type, closeHandler }) => {
  return (
    <div className={cx('alert-wrapper', `alert-wrapper--${ALERT_TYPES[type]}`)}>
      <AlertWarningIcon />
      <span className={cx('message-wrapper')}>{message}</span>
      <CloseIcon onClick={closeHandler} />
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired
};
