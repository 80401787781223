import ExtraOptions from '@DesignSystem/controllers/DropdownList/ExtraOptions';
import { StyledTooltip } from '@DesignSystem/data-display';
import { truncateMiddleOfStringToFitWidth } from '@shared/utils/displayHelpers';

import { Box } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import { AutoSizer, List } from 'react-virtualized';
import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';

const blue = '#5155F5';
const bgColor = '#e5e6f3';
const fixedFontColor = '#9696a2';
const fixedBgColor = '#efeff2';
const placeholderColor = '#b3b9c8';
const disabledBackgroundColor = '#f7f8fa';
const border = '1px solid #d6dae2';

const PERFORMANCE_IMPROVEMENT_THRESHOLD = 1000;
const SEARCH_DEBOUNCE_TIMEOUT = 300;

const isFixedOption = state => {
  const { fixedOptionValues } = state.selectProps;
  return fixedOptionValues.includes(state.data.value);
};

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    border,
    fontSize: 13,
    fontWeight: 400,
    boxShadow: 0,
    '&:hover': { border },
    backgroundColor: state.isDisabled
      ? disabledBackgroundColor
      : styles.backgroundColor,
    borderColor: state.isFocused && 'var(--primary-color) !important'
  }),
  singleValue: styles => ({
    ...styles,
    color: '#191A1C',
    fontSize: 14,
    fontWeight: 400
  }),
  indicatorSeparator: styles => ({
    ...styles,
    backgroundColor: 'var(--gray-3) !important',
    marginRight: 4,
    marginTop: 6,
    marginBottom: 6
  }),
  multiValue: (styles, state) => {
    return {
      ...styles,
      backgroundColor: isFixedOption(state) ? fixedBgColor : bgColor
    };
  },
  multiValueLabel: (styles, state) => {
    const isFixed = isFixedOption(state);
    return {
      ...styles,
      padding: 0,
      paddingRight: isFixed ? 6 : 0,
      color: isFixed ? fixedFontColor : blue
    };
  },
  multiValueRemove: (styles, state) => {
    return {
      ...styles,
      display: isFixedOption(state) ? 'none' : styles.display
    };
  },
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 8
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: blue,
    width: '30px',
    paddingRight: '4px',
    paddingLeft: '4px',
    cursor: 'pointer'
  }),
  menu: styles => ({
    ...styles,
    paddingTop: 0,
    zIndex: '999'
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: 0
  }),
  menuPortal: styles => ({
    ...styles,
    paddingTop: 0,
    zIndex: 9999
  }),
  groupHeading: styles => ({
    ...styles,
    color: 'black',
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    marginBottom: 0
  }),
  placeholder: styles => ({
    ...styles,
    color: placeholderColor,
    fontStyle: 'italic'
  }),
  option: styles => ({
    ...styles,
    wordBreak: 'break-all'
  })
};

const extendTheme = (theme, withSpacing) => {
  const spacing = {
    ...theme.spacing,
    controlHeight: 32,
    baseUnit: 2
  };

  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#F5F7FA',
      primary75: '#969BF7',
      primary50: '#BBBFF9',
      primary25: '#F5F7FA'
    },
    ...(withSpacing && { spacing })
  };
};

// eslint-disable-next-line
const createMenuList = rowHeight => ({ children, maxHeight }) => (
  <AutoSizer style={{ height: maxHeight, padding: 0, width: '100%' }}>
    {({ width }) => (
      <List
        height={maxHeight}
        noRowsRenderer={() => (
          <div className="react-select-no-matches">No matches</div>
        )}
        rowCount={children.length || 0}
        rowHeight={rowHeight}
        rowRenderer={({ index, style }) => (
          <div key={children[index].key} style={style}>
            {children[index]}
          </div>
        )}
        width={width}
      />
    )}
  </AutoSizer>
);

const MultiValue = props => (
  <components.MultiValue {...props}>{props.data.label}</components.MultiValue>
);

export const Option = props => {
  const { selectProps } = props;
  const { truncateMiddle, width } = selectProps;

  const extraOptionsWrapperRef = useRef();

  const labelClass = classNames('react-select-option-label', {
    selected: props.isSelected,
    disabled: props.isDisabled
  });

  const containerClass = classNames('react-select-option-container', {
    disabled: props.isDisabled
  });

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleClick = (optionValue, option) => {
    props.selectOption(option);
  };

  const isTruncateMiddle = truncateMiddle && width;

  const label = useMemo(() => {
    if (isTruncateMiddle) {
      return truncateMiddleOfStringToFitWidth(props.data.label, width);
    }

    return props.data.label;
  }, [isTruncateMiddle, width, props.data.label]);

  const optionTooltipLabel = useMemo(() => {
    if (isTruncateMiddle && label !== props.data.label) {
      return props.data.label;
    }

    return '';
  }, [isTruncateMiddle, label, props.data.label]);

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: e => {
          e.stopPropagation();
          e.preventDefault();
          if (
            e.target !== extraOptionsWrapperRef.current &&
            !isSubmenuOpen &&
            isFunction(props.innerProps.onClick)
          ) {
            props.innerProps.onClick();
          }
        }
      }}
    >
      <StyledTooltip title={optionTooltipLabel} placement="top">
        <div className={containerClass}>
          <Box padding="8px 5px" display="flex" justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <p className={labelClass}>{label}</p>
              {selectProps.displaySource ? (
                <p className="ds-select-secondary-label">{props.data.source}</p>
              ) : null}
            </Box>
            {props.data.extraOptions && (
              <StyledTooltip
                title="Additional Options"
                type="base"
                placement="top"
                open={showTooltip}
                disableHoverListener
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <div
                  onClick={() => setShowTooltip(false)}
                  style={{ pointerEvents: 'all' }}
                >
                  <ExtraOptions
                    onClick={handleClick}
                    setIsSubmenuOpen={setIsSubmenuOpen}
                    ref={extraOptionsWrapperRef}
                    options={props.data.extraOptions}
                  />
                </div>
              </StyledTooltip>
            )}
          </Box>
        </div>
      </StyledTooltip>
    </components.Option>
  );
};

const StyledSelect = props => {
  const components = { MultiValue, Option, ...props.components };
  const { options, ...otherProps } = props;

  const filterOptions = (searchValue, callback) => {
    const retVal = searchValue
      ? options.filter(option =>
          option?.value?.toLowerCase()?.includes(searchValue?.toLowerCase())
        )
      : options;
    callback(retVal);
  };

  const getFilteredOptions = useCallback(
    debounce(filterOptions, SEARCH_DEBOUNCE_TIMEOUT),
    []
  );

  if (props.rowHeight) {
    components.MenuList = createMenuList(props.rowHeight);
  }

  if (props.isCreatable) {
    return (
      <Creatable
        {...props}
        styles={{ ...customStyles, ...(props.additionalStyles || {}) }}
        components={components}
        theme={theme => extendTheme(theme)}
      />
    );
  }

  // AsyncSelect is using here to resolve performance issue with Select in case it has a lot of options,
  // PERFORMANCE_IMPROVEMENT_THRESHOLD constant is defined base on common sense
  if (options.length > PERFORMANCE_IMPROVEMENT_THRESHOLD) {
    return (
      <AsyncSelect
        cacheOptions
        loadOptions={getFilteredOptions}
        defaultOptions={options}
        styles={customStyles}
        theme={theme => extendTheme(theme, true)}
        {...otherProps}
        components={components}
      />
    );
  }

  return (
    <Select
      styles={customStyles}
      theme={theme => extendTheme(theme, true)}
      {...props}
      components={components}
    />
  );
};

StyledSelect.defaultProps = {
  displaySource: false,
  additionalStyles: {},
  fixedOptionValues: [],
  isCreatable: false,
  options: []
};

StyledSelect.propTypes = {
  displaySource: PropTypes.bool,
  fixedOptionValues: PropTypes.array,
  additionalStyles: PropTypes.object,
  isCreatable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object)
};

export default StyledSelect;
