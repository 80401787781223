import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { CreditCardForm } from '@DesignSystem/forms';

import { useUpdateOrganizationPaymentMutation } from '@account-settings/api';

const DEFAULT_COUNTRY = 'US';

const UpdateCardDetailModal = ({ open, onClose, organizationId }) => {
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [postalCode, setPostalCode] = useState('');

  const { isLoading, mutateAsync } = useUpdateOrganizationPaymentMutation(
    organizationId
  );

  const handlePrimaryButtonClick = async () => {
    try {
      await mutateAsync({
        address,
        city,
        state,
        postalCode,
        country
      });

      onClose();
    } catch {
      //  it's handled in a mutation
    }
  };

  useEffect(() => {
    if (!open) {
      setCity('');
      setAddress('');
      setCountry(DEFAULT_COUNTRY);
      setState('');
      setPostalCode('');
    }
  }, [open]);

  const isInvalidForm = !city || !country || !address;

  return (
    <FullWidthBasicModal
      open={open}
      onClose={onClose}
      content={
        <CreditCardForm
          address={address}
          onChangeAddress={setAddress}
          city={city}
          onChangeCity={setCity}
          state={state}
          onChangeState={setState}
          country={country}
          onChangeCountry={setCountry}
          postalCode={postalCode}
          onChangePostalCode={setPostalCode}
        />
      }
      title="Update card details"
      primaryButtonText="Submit change"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handlePrimaryButtonClick}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={isInvalidForm || isLoading}
      isPrimaryButtonLoading={isLoading}
    />
  );
};

UpdateCardDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired
};

export default UpdateCardDetailModal;
