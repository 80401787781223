import React from 'react';
import { ListItem, Tooltip } from '@design-system/components';
import { DSCopyIcon, DSSuccessIcon } from '@design-system/icons';
import cx from 'classnames';

import styles from './UserMenuApiKey.module.scss';
import useCurrentUser from '@API/auth/useCurrentUser';
import { hideApiKeyPart } from '@shared/utils/apiKeys';
import { useCopyToClipboard } from '@shared/hooks';

const UserMenuApiKeyCopy = () => {
  const { data: user } = useCurrentUser();
  const apiKey = user?.apiKey || '';
  const apiKeyToShow = hideApiKeyPart(user?.apiKey);
  const { copied, onCopy } = useCopyToClipboard(apiKey);

  const tooltipText = copied ? 'API Key was copied ' : 'Click to copy API Key';

  return (
    <div className={styles.apiKeyManageField}>
      <Tooltip content={tooltipText} wrapperDisplay="block">
        <ListItem onClick={onCopy}>
          <ListItem.Text primary={apiKeyToShow} />
          <ListItem.ActionContainer>
            <ListItem.Icon
              Icon={
                copied ? (
                  <DSSuccessIcon
                    className={cx(styles.apiKeyManageIcon, styles.successIcon)}
                  />
                ) : (
                  <DSCopyIcon className={styles.apiKeyManageIcon} />
                )
              }
            />
          </ListItem.ActionContainer>
        </ListItem>
      </Tooltip>
    </div>
  );
};

export default UserMenuApiKeyCopy;
