import React from 'react';
import PropTypes from 'prop-types';

import { Menu } from '@DesignSystem/navigation';
import { DS_MENU_TYPES } from '@DesignSystem/navigation/Menu/Menu';
import { CODE_FRAMEWORKS_LIST } from './quickStartCodeSnippets';
import styles from './QuickStartGuide.module.scss';

const QuickStartMenu = ({ frameworkValue, onChangeFramework }) => {
  return (
    <div className={styles.quickStartMenuWrapper}>
      <div className={styles.quickStartMenu}>
        <Menu
          items={CODE_FRAMEWORKS_LIST}
          activeItem={frameworkValue}
          onClickItem={onChangeFramework}
          type={DS_MENU_TYPES.MENU_1}
          containerClassName={styles.frameworkMenuContainer}
        />
      </div>
    </div>
  );
};

QuickStartMenu.propTypes = {
  frameworkValue: PropTypes.string.isRequired,
  onChangeFramework: PropTypes.func.isRequired
};

export default QuickStartMenu;
