export const PASSWORD_POLICIES_NON_APPLICABLE_VALUES = [true, null];

export const PASSWORD_ERRORS_MAP = {
  TOO_SHORT:
    'The password is too short. Password length must be at lease X characters.',
  TOO_LONG:
    'The password is too long. Password length must be up to Y characters.',
  INSUFFICIENT_LOWERCASE:
    'The password must contain at least one lowercase letter.',
  INSUFFICIENT_UPPERCASE:
    'The password must contain at least one uppercase letter.',
  INSUFFICIENT_DIGIT: 'The password must contain at least one digit.',
  INSUFFICIENT_ALPHABETICAL: 'The password must contain at least one letter.',
  INSUFFICIENT_SPECIAL:
    'The password must contain at least one special character.',
  ILLEGAL_USERNAME: 'The password must not contain username.',
  ILLEGAL_REPEATED_CHARS: "The password can't contain repeated characters.",
  ILLEGAL_WORD: 'The password must not contain common words.',
  ILLEGAL_ALPHABETICAL_SEQUENCE:
    'The password must not contain consecutive chars.',
  ILLEGAL_NUMERICAL_SEQUENCE:
    'The password must not contain consecutive digits.',
  ILLEGAL_QWERTY_SEQUENCE:
    'The password must not contain consecutive characters.'
};
