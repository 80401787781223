import React from 'react';
import Timeline from '@material-ui/lab/Timeline';

import EmptyTabMessage from '@model-registry/components/EmptyTabMessage';
import PropTypes from 'prop-types';
import { HISTORY_MESSAGES } from '@model-registry/constants';
import { useSelector } from 'react-redux';
import { getIfCanShowHistoryByFF } from '@model-registry/utils/history';
import ModelRegistryHistoryTimelineGroup from './ModelRegistryHistoryTimelineGroup';
import ModelRegistryHistoryCard from './ModelRegistryHistoryCard';

import classNames from './ModelRegistryHistory.module.scss';
import SmallLoader from '@shared/components/SmallLoader';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import {
  BETA_FEATURE_MODEL_APPROVAL_PROCESS,
  BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
} from '@/lib/betaFeatures';

// TODO add correct history for the TAGS!!!
export default function ModelRegistryHistory({
  isLoading,
  allHistoryItemsKeys,
  isHistoryEmpty,
  groupedHistoryItemsByDate
}) {
  const isModelRegistryTagsFromStagesEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
    });
  });
  const isModelApprovalProcessEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_APPROVAL_PROCESS
    });
  });

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching model history"
      />
    );
  }

  if (isHistoryEmpty) {
    return (
      <div
        data-test="model-registry-history-empty"
        className={classNames.modelRegistryHistoryTabContainer}
      >
        <EmptyTabMessage type="history" />
      </div>
    );
  }

  const ifHistoryEmpty = allHistoryItemsKeys.length === 0;

  return (
    <div
      data-test="model-registry-history-container"
      className={classNames.modelRegistryHistoryTabContainer}
    >
      <Timeline className={classNames.modelRegistryHistoryTimelineContainer}>
        {!ifHistoryEmpty
          ? allHistoryItemsKeys.map(groupedHistoryItemsDay => {
              const historyList =
                groupedHistoryItemsByDate[groupedHistoryItemsDay];
              const historyItems = historyList
                .map(historyItem => {
                  const ifShouldShowHistoryCard = getIfCanShowHistoryByFF({
                    ...historyItem,
                    isModelApprovalProcessEnabled
                  });

                  if (!ifShouldShowHistoryCard) return null;
                  return (
                    // We do not have any unique ID from the BE
                    // eslint-disable-next-line react/jsx-key
                    <ModelRegistryHistoryCard
                      key={historyItem.date}
                      {...historyItem}
                      date={groupedHistoryItemsDay}
                      isModelRegistryTagsFromStagesEnabled={
                        isModelRegistryTagsFromStagesEnabled
                      }
                      isModelApprovalProcessEnabled={
                        isModelApprovalProcessEnabled
                      }
                    />
                  );
                })
                .filter(Boolean);

              if (!historyItems?.length) {
                return null;
              }

              return (
                // We do not have any unique ID from the BE
                // eslint-disable-next-line react/jsx-key
                <ModelRegistryHistoryTimelineGroup
                  title={groupedHistoryItemsDay}
                >
                  {historyItems}
                </ModelRegistryHistoryTimelineGroup>
              );
            })
          : null}
        {!ifHistoryEmpty ? (
          <ModelRegistryHistoryTimelineGroup
            key="no_more_history"
            title={HISTORY_MESSAGES.no_more_history}
          />
        ) : null}
      </Timeline>
    </div>
  );
}

ModelRegistryHistory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  allHistoryItemsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  isHistoryEmpty: PropTypes.bool.isRequired,
  groupedHistoryItemsByDate: PropTypes.object.isRequired
};
