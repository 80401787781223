import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import { GET_PYTHON_PANEL_URL } from './constants';
import useIsServerCustomPanelsEnabled from '../../hooks/custom-server-panels/useIsServerCustomPanelsEnabled';

interface GetComputeEngineResponse {
  computeURL: string;
}

const getComputeEngine = async (
  projectId: string,
  signal: AbortSignal | undefined
): Promise<string> => {
  const response = await api.get<GetComputeEngineResponse>(
    'code-panels/compute/get',
    {
      params: { projectId },
      signal
    }
  );

  return response?.data?.computeURL.replace(GET_PYTHON_PANEL_URL, '');
};

const useComputeEngine = (projectId: string, config?: QueryConfig<string>) => {
  const outEnabled = config?.enabled ?? true;
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();

  return useQuery(
    ['computeUrl', { projectId }],
    ({ signal }) => getComputeEngine(projectId, signal),
    {
      ...config,
      enabled: outEnabled && isServerCustomPanelsEnabled
    }
  );
};

export default useComputeEngine;
