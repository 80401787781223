const shared = (state = {}, action) => {
  switch (action.type) {
    case 'PROJECT_SHARE_LIST': {
      const list = action.data;
      const { projectId } = action;

      if (!projectId) return { ...state };

      return { ...state, [projectId]: { loading: false, list } };
    }

    case 'PROJECT_SHARE_ERROR': {
      const { projectId } = action;

      return { ...state, loading: false, [projectId]: null };
    }

    default:
      return state;
  }
};

export default shared;
