import React from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import { IconButton } from '@ds';
import { DSRefreshIcon } from '@ds-icons';

const EmbeddedPanelHeader = ({
  isReady,
  onRefresh,
  projectId,
  title,
  viewId
}) => {
  const projectURL =
    projectId && viewId
      ? `/api/projects/redirect?projectId=${projectId}&viewId=${viewId}`
      : '/';

  return (
    <header>
      <div className="embedded-panel-header-left-section">
        <a
          className="view-project-link"
          href={projectURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="hide-mobile" src="/images/logo_comet_dark.png" />
          <img className="show-mobile" src="/images/comet-only-logo.png" />

          {isReady && <span className="hide-mobile">View Project</span>}
        </a>
      </div>
      <div className="embedded-panel-header-middle-section">
        <a
          className="embedded-panel-view-project-link"
          href={projectURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1 className="embedded-panel-header-title">{title}</h1>
        </a>
      </div>
      <div className="embedded-panel-header-right-section">
        <IconButton
          aria-label="refresh"
          disabled={!isReady}
          onClick={onRefresh}
          size="XL"
          type="secondary"
          Icon={<DSRefreshIcon />}
        />
      </div>
    </header>
  );
};

EmbeddedPanelHeader.defaultProps = {
  isReady: false,
  onRefresh: noop,
  projectId: null,
  title: 'Panel',
  viewId: null
};

EmbeddedPanelHeader.propTypes = {
  isReady: PropTypes.bool,
  onRefresh: PropTypes.func,
  projectId: PropTypes.string,
  title: PropTypes.string,
  viewId: PropTypes.string
};

export default EmbeddedPanelHeader;
