import React from 'react';
import cx from 'classnames';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import noop from 'lodash/noop';

import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import { DSHideIcon, DSShowIcon, DSShowIndeterminateIcon } from '@ds-icons';
import { IconButton, Tooltip } from '@ds';
import { useReactGridContext } from '@experiment-management-shared/components/ReactGrid/reactGridContext';

const VisibilityAllButton = () => {
  const {
    experimentKeys,
    hiddenExperimentKeys,
    handleAllVisibilityChange = noop
  } = useReactGridContext();
  const handleClick = (show: boolean) => {
    const keys = show
      ? difference(hiddenExperimentKeys, experimentKeys)
      : uniq([...hiddenExperimentKeys, ...experimentKeys]);

    handleAllVisibilityChange(keys);
  };

  const isHiddenAll =
    Boolean(experimentKeys.length) &&
    experimentKeys.every(key => hiddenExperimentKeys.includes(key));
  const isHiddenPartially =
    Boolean(experimentKeys.length) &&
    experimentKeys.some(key => hiddenExperimentKeys.includes(key));

  const showAll = isHiddenAll || isHiddenPartially;
  const tooltipAction = showAll ? 'Show all' : 'Hide all';
  const tooltipMessage = `${tooltipAction} experiments in ${PANEL_ENTITY_NAME}s`;
  const Icon = isHiddenAll
    ? DSHideIcon
    : isHiddenPartially
    ? DSShowIndeterminateIcon
    : DSShowIcon;

  return (
    <div
      className={cx(
        'react-grid-visibility-button',
        'react-grid-visibility-all-button',
        {
          'hidden-state': isHiddenAll
        }
      )}
    >
      <Tooltip placement="top" content={tooltipMessage}>
        <IconButton
          type="secondary"
          onClick={() => handleClick(showAll)}
          Icon={<Icon />}
        />
      </Tooltip>
    </div>
  );
};

export default VisibilityAllButton;
