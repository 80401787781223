import React from 'react';

import TableEmptyState from '@shared/components/TableEmptyState';
import TableNoSearchResults from '@shared/components/TableNoSearchResults';
import { EmptyExperimentTab } from '@shared';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';

type HyperParametersEmptyStateProps = {
  searchValue: string;
  isLoading: boolean;
  resetSearch: () => void;
};
const HyperParametersEmptyState: React.FC<HyperParametersEmptyStateProps> = ({
  isLoading,
  searchValue,
  resetSearch,
  ...props
}) => {
  const isComparePage = useSelector(selectIsComparePage);
  const experimentText = isComparePage
    ? 'These experiments'
    : 'This experiment';
  if (searchValue) {
    return (
      <TableNoSearchResults
        buttonText="Clear search"
        clickHandler={resetSearch}
        {...props}
      />
    );
  }

  if (isLoading) {
    return (
      <TableEmptyState
        pictureHeight="50px"
        pictureWidth="55px"
        picturePath="/images/empty-states/no-paramaters.svg"
        materialProps={props}
        label={'Loading parameters...'}
      />
    );
  }

  const cellProps = props as Table.DataCellProps;

  return (
    <EmptyExperimentTab.Cell {...cellProps}>
      <EmptyExperimentTab>
        <EmptyExperimentTab.Icon iconName="hyperparams" />
        <EmptyExperimentTab.Title>
          {experimentText} did not log any hyperparameters
        </EmptyExperimentTab.Title>
        <EmptyExperimentTab.Description>
          Use <b>log_parameters()</b> on your Experiment object to log
          hyperparameters during experiments.
        </EmptyExperimentTab.Description>
        <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/metrics-and-parameters" />
      </EmptyExperimentTab>
    </EmptyExperimentTab.Cell>
  );
};

export default HyperParametersEmptyState;
