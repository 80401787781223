import { useMutation, useQueryClient } from 'react-query';

import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';

import api from '../api';

import useProject from '../project/useProject';
import useProjectTags from '../project/useProjectTags';

const OPTIMISTIC_TAG_COLOR = '#ed4a7b';

const getNewTagNames = (tagUpdates, tags) => {
  const currentTagNames = tags.map(tag => tag.name);
  const newTagNames = [];

  for (const { addedTags } of tagUpdates) {
    for (const tagName of addedTags) {
      if (
        !currentTagNames.includes(tagName) &&
        !newTagNames.includes(tagName)
      ) {
        newTagNames.push(tagName);
      }
    }
  }

  return newTagNames;
};

const updateTags = ({ tagUpdates }) => {
  return api.post('tags/update-experiment-tags', { tagUpdates });
};

export default function useUpdateTagsMutation() {
  const { data: project } = useProject();
  const { data: tags } = useProjectTags();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(updateTags, {
    onMutate: async ({ tagUpdates }) => {
      await queryClient.cancelQueries(['experiments', { projectId }]);
      await queryClient.cancelQueries(['projectTags', { projectId }]);
      await queryClient.cancelQueries(['experimentsDetails', { projectId }]);

      queryClient.setQueryData(['projectTags', { projectId }], oldTags => {
        const newTags = getNewTagNames(tagUpdates, tags).map(name => ({
          color: OPTIMISTIC_TAG_COLOR,
          name
        }));

        return sortBy([...oldTags, ...newTags], tag => tag.name.toLowerCase());
      });

      queryClient
        .getQueryCache()
        .findAll(['experiments', { projectId }])
        .forEach(({ queryKey }) => {
          queryClient.setQueryData(queryKey, experimentsData => {
            // In case of refetching experiments the data could be undefined
            if (!experimentsData) {
              return experimentsData;
            }

            const experiments = experimentsData.experiments.map(experiment => {
              const tagUpdate = tagUpdates.find(
                update => update.experimentId === experiment.experimentKey
              );

              if (!tagUpdate) return experiment;

              const newTags = difference(
                union(experiment.tags, tagUpdate.addedTags),
                tagUpdate.deletedTags
              );

              if (isEqual(experiment.tags, newTags)) return experiment;

              return { ...experiment, tags: newTags };
            });

            return { ...experimentsData, experiments };
          });
        });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['experiments', { projectId }]);
      queryClient.invalidateQueries(['experimentGroups', { projectId }]);
      queryClient.invalidateQueries(['projectTags', { projectId }]);
      queryClient.invalidateQueries(['experimentsDetails', { projectId }]);
    }
  });
}
