// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsTable-module__table--cspeD .MuiSvgIcon-root{font-size:22px}.ReportsTable-module__table--cspeD .MuiTableCell-root{font-size:14px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ReportsTable-module__table--cspeD`
};
module.exports = ___CSS_LOADER_EXPORT___;
