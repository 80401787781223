import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { DSColors } from '@design-system-outdated/constants';
import classNames from './ManageUsersModalTable.module.scss';

const width = '36px';
const avatarStylesForPendingUser = {
  width,
  outlined: true,
  backgroundColor: DSColors.grayColor1,
  type: 'small',
  showLetters: true
};

const ManageUsersModalAvatarCell = ({ row }) => {
  const isPendingUser = !row.username;
  const avatarProps = row.avatar
    ? {
        width,
        src: row.avatar
      }
    : avatarStylesForPendingUser;

  return (
    <Grid
      spacing={2}
      wrap="nowrap"
      container
      alignItems="center"
      alignContent="center"
      justifyContent="flex-start"
    >
      <Grid item>
        <Avatar {...avatarProps} />
      </Grid>
      <Grid item xs zeroMinWidth>
        {!isPendingUser ? (
          <Typography
            noWrap
            component="div"
            className={classNames.manageUsersModalTableAvatarLabel}
          >
            {row?.uniqueName}
          </Typography>
        ) : (
          <div className={classNames.manageUsersModalTableEmptyCellNoName}>
            <div className={classNames.manageUsersModalTableEmptyCell} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

ManageUsersModalAvatarCell.propTypes = {
  row: PropTypes.shape({
    avatar: PropTypes.string,
    uniqueName: PropTypes.string
  })
};

ManageUsersModalAvatarCell.defaultProps = {
  row: {}
};

export default ManageUsersModalAvatarCell;
