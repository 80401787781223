import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

import { SegmentDataType } from '@mpm/types';

type EditSegmentMutationProps = {
  segmentData: SegmentDataType;
  id: string;
};

export function useEditSegmentMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ segmentData, id }: EditSegmentMutationProps) => {
      return api.put(
        `mpm/${activeWorkspace?.id}/models/${modelId}/segments/${id}`,
        segmentData
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'mpm-segments',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_EDIT_SEGMENT,
            'The segment was successfully edited.'
          )
        );
      },
      onError: (err: Error) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_SEGMENT_EDITED,
            err?.message
          )
        );
      }
    }
  );
}
