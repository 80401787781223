import React, { ReactNode } from 'react';
import typography from '@ds-typography';
import cx from 'classnames';

import Tooltip, {
  TooltipProps as MUITooltipProps
} from '@material-ui/core/Tooltip';
import classes from './Tooltip.module.scss';
import {
  TooltipPlacementType,
  TooltipSizeType,
  TooltipWrapperDisplayType
} from '@design-system/types/tooltips';

export interface TooltipProps
  extends Omit<MUITooltipProps, 'title' | 'content'> {
  arrow?: boolean;
  content: ReactNode;
  disableListeners?: boolean;
  placement?: TooltipPlacementType;
  size?: TooltipSizeType;
  truncateOverflow?: boolean;
  wrapper?: boolean;
  wrapperDisplay?: TooltipWrapperDisplayType;
}

const DSTooltip = ({
  arrow = true,
  children,
  content,
  disableListeners = false,
  truncateOverflow = false,
  placement = 'top',
  size = 'default',
  wrapper = true,
  wrapperDisplay = 'inline-block',
  ...restProps
}: TooltipProps) => {
  return (
    <Tooltip
      title={content || ''}
      arrow={arrow}
      placement={placement}
      classes={{
        tooltip: cx(
          classes.tooltipContainer,
          classes[size],
          typography.dsBodySmall
        ),
        arrow: cx(classes.arrow, classes[size])
      }}
      disableFocusListener={disableListeners}
      disableHoverListener={disableListeners}
      disableTouchListener={disableListeners}
      {...restProps}
    >
      {wrapper ? (
        <span
          tabIndex={-1}
          style={{ '--wrapper-display': wrapperDisplay } as React.CSSProperties}
          className={cx(classes.childrenWrapper, {
            [classes.childrenWrapperTruncate]: truncateOverflow
          })}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </Tooltip>
  );
};

export default DSTooltip;
