import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { FREE_PLANS } from '@shared/constants';

import { useCurrentOrganization, useUpdatePlanMutation } from '@shared/hooks';

const useUpdatePaymentPlan = () => {
  const [isUpdatedPaymentPlan, setIsUpdatedPaymentPlan] = useState(false);
  const { search } = useLocation();
  const updatePlanMutation = useUpdatePlanMutation();

  const currentOrganization = useCurrentOrganization();

  const { plan } = queryString.parse(search);

  // no need in different dependencies
  useEffect(() => {
    if (
      plan &&
      !FREE_PLANS.includes(plan) &&
      currentOrganization?.id &&
      !isUpdatedPaymentPlan
    ) {
      updatePlanMutation.mutate({ plan });
      setIsUpdatedPaymentPlan(true);
    }
  }, [currentOrganization?.id]);
};

export default useUpdatePaymentPlan;
