import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Button, TextButton } from '@ds';
import { useCurrentOrganization } from '@shared/hooks';

const InviteTeamMembers = ({ onClose }) => {
  const history = useHistory();
  const currentOrganization = useCurrentOrganization();

  const handleInviteClick = () => {
    history.push(`/organizations/${currentOrganization?.id}/workspaces`);
  };

  return (
    <div className="upgraded-and-invite-modal">
      <div className="upgraded-plan-picture" />
      <div className="upgrade-plan-label">
        Now, let&apos;s invite team members so you can start collaborating
        together
      </div>
      <Button
        size="large"
        className="invite-team-members-btn"
        onClick={handleInviteClick}
      >
        Invite Team Members
      </Button>
      <TextButton size="large" onClick={onClose}>
        Skip, I will do it later
      </TextButton>
    </div>
  );
};

InviteTeamMembers.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default InviteTeamMembers;
