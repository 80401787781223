import { compact } from 'lodash';
import {
  formatColumnLabelWithKind,
  KIND_TYPE,
  mixColumnNameWithKind
} from '@API/helpers/v2_helpers';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import toLower from 'lodash/toLower';
import { formatNameForDisplay } from '@shared/utils/displayHelpers';

export const formatValueForSelectOption = (
  value,
  source,
  excludedValuesToFormat = [],
  extraOptions = [],
  option = {},
  nameMap
) => {
  const label = excludedValuesToFormat?.includes(value)
    ? value
    : formatNameForDisplay(value, nameMap);

  return {
    ...option,
    value,
    label,
    source,
    secondary: source,
    extraOptions
  };
};

export const getGroupedOptionList = (list, groupedBy) => {
  const groupedListMap = {};

  list.forEach(item => {
    const key = item[groupedBy];
    const listByKey = groupedListMap[key];

    if (!listByKey) {
      groupedListMap[key] = [item];
      return;
    }

    listByKey.push(item);
  });

  return Object.keys(groupedListMap).map(key => ({
    label: key,
    options: groupedListMap[key]
  }));
};

export const formatArrayAsSelectOptions = values => {
  return values.map(value => formatValueForSelectOption(value));
};

export const orderColumnsInAscOrder = columns =>
  orderBy(
    uniqBy(columns, ({ id }) => id),
    ({ id, name }) => `${toLower(formatNameForDisplay(name))}${id}`,
    'asc'
  );

export const minMaxColumnToOption = (column, rest = {}, nameMap) => {
  return compact(
    column?.kinds?.map(kind => {
      if (![KIND_TYPE.MAX, KIND_TYPE.MIN].includes(kind)) return null;

      return {
        value: mixColumnNameWithKind(column.name, kind),
        source: column.source,
        label: formatColumnLabelWithKind(
          formatNameForDisplay(column.name, nameMap),
          kind
        ),
        kind,
        ...rest
      };
    })
  );
};

export const formatColumnsToSelectOptions = (columns, nameMap) => {
  return columns.map(column => {
    const subOptions = minMaxColumnToOption(column, {}, nameMap);

    return formatValueForSelectOption(
      column.name,
      column.source,
      [],
      subOptions,
      {},
      nameMap
    );
  });
};
