import { orderBy } from 'lodash';

export const getModelId = (workspaceName, modelName) =>
  `${workspaceName}-${modelName}`;

export const sortOptionsByLabel = options =>
  options.sort(({ label: labelA }, { label: labelB }) => {
    return labelA.toLowerCase().localeCompare(labelB.toLowerCase());
  });

export const formatRegistryModelsListToReactSelectOptions = models =>
  sortOptionsByLabel(
    models.map(({ registryModelId, modelName }) => ({
      value: registryModelId,
      source: registryModelId,
      label: modelName
    }))
  );

export const formatWorkspacesListToReactSelectOptions = (models = []) => {
  if (!Array.isArray(models)) return [];

  return sortOptionsByLabel(
    models.map(({ name }) => ({
      value: name,
      source: name,
      label: name
    }))
  );
};

export const findNextVersionForModel = (versions, indexOfVersionToRemove) => {
  // if we are removing the last version return -1
  if (versions?.length === 1) {
    return null;
  }

  // if this is the first version in list, redirect to next version
  if (indexOfVersionToRemove === 0) {
    return versions[1];
  }

  // if this is the one in the middle, we will take the previous
  return versions[indexOfVersionToRemove - 1];
};

export const findModelVersionInVersionsArray = (versions, version) =>
  versions.find(modelVersion => modelVersion.version === version);

export const reverseGroupedHistory = data => {
  const reversedData = {};
  const sortedByDate = orderBy(
    Object.keys(data),
    date => new Date(date),
    'desc'
  );

  sortedByDate.forEach(date => {
    reversedData[date] = data[date];
  });

  return reversedData;
};

// SMELLY: Stages to tags migration on FE
export const formatStagesListToTags = stages =>
  stages?.length
    ? stages.map(stage =>
        typeof stage === 'string'
          ? { tag: stage }
          : { ...stage, tag: stage.stage }
      )
    : [];

export const ifAllAssetsIsRemote = assets =>
  assets?.length && assets?.every(asset => !!asset.remote);
