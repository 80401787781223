import get from 'lodash/get';
import last from 'lodash/last';
import mapKeys from 'lodash/mapKeys';

import {
  CHART_COLORS,
  lightenColor,
  PRIMARY_TO_LIGHT_COLOR_MAP
} from '@/constants/colorConstants';
import {
  ACTIVE_KEY,
  ARCHIVED_KEY,
  IMPORTED_FROM_KEY,
  NAME_KEY
} from '@/lib/appConstants';
import { MAX_LENGTH_EXPERIMENT_NAME_FROM_KEY } from '../constants/compareTableConstants';
import { ExperimentDetails } from '@shared/types';

export const getExperimentColor = (experiment: ExperimentDetails) => {
  const { experimentKey } = experiment;
  const experimentColor = experiment.comet_chart_color;

  if (experimentColor) {
    return {
      experimentKey,
      primary: experimentColor,
      light: lightenColor(experimentColor)
    };
  }

  const index = parseInt(`${last(experimentKey)}`, 16);

  const roundRobinColor = CHART_COLORS[index % CHART_COLORS.length];

  return {
    experimentKey,
    primary: roundRobinColor,
    light: PRIMARY_TO_LIGHT_COLOR_MAP[roundRobinColor]
  };
};

export const getExperimentImportedFrom = (experiment: ExperimentDetails) => {
  return get(experiment, [IMPORTED_FROM_KEY], undefined);
};

export const getExperimentArchived = (experiment: ExperimentDetails) => {
  return get(experiment, [ARCHIVED_KEY], null);
};

export const getExperimentActive = (experiment: ExperimentDetails) => {
  return get(experiment, [ACTIVE_KEY], null);
};

export const getExperimentName = (experiment: ExperimentDetails) => {
  return get(experiment, [NAME_KEY], null);
};
export const calculateExperimentName = (experiment: ExperimentDetails) => {
  let retVal = '';

  if (experiment) {
    retVal = getExperimentName(experiment) ?? '';
    const key = experiment.experimentKey;

    if (retVal === key || !retVal) {
      retVal = key.slice(0, MAX_LENGTH_EXPERIMENT_NAME_FROM_KEY);
    }
  }

  return retVal;
};

export const getExperimentsColorMap = (experiments: ExperimentDetails[]) => {
  return mapKeys(
    experiments.map(getExperimentColor),
    experiment => experiment.experimentKey
  );
};

export const getIsExperimentRun = ({
  runActive = false,
  crashReason = null
} = {}) => runActive && !crashReason;

export const CPU_MEMORY_AVAILABLE_PATTERN = /sys\.ram\.available/;
export const CPU_MEMORY_USAGE_PATTERN = /sys\.ram\.used/;
export const CPU_OVERALL_PATTERN = /sys\.compute\.overall/;
export const CPU_PERCENT_PATTERN = /sys\.cpu\.percent\.[0-9]{1,3}/;
export const CPU_UTILIZED_PATTERN = /sys\.compute\.utilized/;
export const DISK_PERCENT_USED_PATTERN = /sys\.disk\.percent\.used/;
export const DISK_READ_PATTERN = /sys\.disk\.read_bps/;
export const DISK_USED_PATTERN = /sys\.disk\.root\.used/;
export const DISK_WRITE_PATTERN = /sys\.disk\.write_bps/;
export const GPU_MEMORY_USAGE_PATTERN = /sys\.gpu\.[0-9]{1,3}\.used_memory/;
export const GPU_MEMORY_UTILIZATION_PATTERN = /sys\.gpu\.[0-9]{1,3}\.memory_utilization/;
export const GPU_POWER_USAGE_PATTERN = /sys\.gpu\.[0-9]{1,3}\.power_usage/;
export const GPU_TEMPERATURE_PATTERN = /sys\.gpu\.[0-9]{1,3}\.temperature/;
export const GPU_UTILIZATION_PATTERN = /sys\.gpu\.[0-9]{1,3}\.gpu_utilization/;
export const NETWORK_RECEIVE_PATTERN = /sys\.network\.receive_bps/;
export const NETWORK_SEND_PATTERN = /sys\.network\.send_bps/;
