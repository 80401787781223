import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isObject from 'lodash/isObject';

import modelRegistryApi from '../../util/modelRegistryApi';
import alertsUtil from '../../util/alertsUtil';
import { dialogTypes, snackbarTypes } from '../../constants/alertTypes';

const useModelRegistryUpdateItemMutation = ({
  workspace,
  modelName,
  registryModelId,
  invalidateQueriesKeys
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ values, registryModelItemId }) =>
      modelRegistryApi.updateModelItem({
        registryModelItemId,
        ...values
      }),
    {
      onSuccess: (
        data,
        { snackbarMsg = 'Successfully updated model item.' } = {}
      ) => {
        queryClient.invalidateQueries([
          'modelRegistry',
          { workspace, modelName }
        ]);
        queryClient.invalidateQueries([
          'modelRegistryById',
          { columns: [], registryModelId },
          'metadata'
        ]);

        if (isObject(invalidateQueriesKeys)) {
          Object.keys(invalidateQueriesKeys).forEach(invalidateQueryKey => {
            queryClient.invalidateQueries(
              invalidateQueriesKeys[invalidateQueryKey]
            );
          });
        }

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_API_RESPONSE,
            snackbarMsg
          )
        );
      },
      onError: (
        data,
        { snackbarMsgErr = 'There was an error updating your model item.' } = {}
      ) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            snackbarMsgErr
          )
        );
      }
    }
  );
};

export default useModelRegistryUpdateItemMutation;
