import { CodeTabID, COMPARE_TAB } from '@experiment-details/constants/code';
import { AssetData } from '@shared/types/assetData';
import {
  getFileBasename,
  truncateMiddleOfStringToFitWidth
} from '@shared/utils/displayHelpers';

export const isDiffTab = (tab: CodeTabID) => {
  return tab === COMPARE_TAB.SPLIT || tab === COMPARE_TAB.UNIFIED;
};

export const isSingleTab = (tab: CodeTabID) => {
  return tab === COMPARE_TAB.EXPERIMENT_1 || tab === COMPARE_TAB.EXPERIMENT_2;
};

export const getFileExtension = (fileName: string, defaultExtension = '') => {
  const extension = fileName.substr(fileName.lastIndexOf('.') + 1);

  if (extension === fileName) return defaultExtension;

  return extension?.toLowerCase();
};

const getSourceCodeType = (assetSummary: AssetData) => {
  try {
    const { source_code_type } = JSON.parse(assetSummary.metadata as string);

    return source_code_type;
  } catch (e) {
    return null;
  }
};

const SOURCE_CODE_TYPES = {
  EXPERIMENT_CREATION: 'experiment_creation',
  MANUAL: 'manual',
  PYTHON_SCRIPT_NAME: 'python_script_name'
};

export const prepareFileOptions = (
  assets: AssetData[] = [],
  maxSelectWidth = 350
) => {
  return assets
    .map(assetSummary => ({
      fullLabel: assetSummary.fileName,
      shortLabel: getFileBasename(assetSummary.fileName),
      label: truncateMiddleOfStringToFitWidth(
        assetSummary.fileName,
        maxSelectWidth
      ),
      sourceCodeType: getSourceCodeType(assetSummary),
      tooltipText: assetSummary.fileName,
      value: assetSummary.assetId,
      experimentKey: assetSummary.experimentKey,
      searchString: assetSummary.fileName
    }))
    .sort((a, z) => {
      if (a.sourceCodeType === SOURCE_CODE_TYPES.EXPERIMENT_CREATION) {
        if (z.sourceCodeType === SOURCE_CODE_TYPES.EXPERIMENT_CREATION) {
          return a.label.localeCompare(z.label);
        }

        return -1;
      }
      if (z.sourceCodeType === SOURCE_CODE_TYPES.EXPERIMENT_CREATION) {
        return 1;
      }

      if (a.sourceCodeType === SOURCE_CODE_TYPES.PYTHON_SCRIPT_NAME) {
        if (z.sourceCodeType === SOURCE_CODE_TYPES.PYTHON_SCRIPT_NAME) {
          return a.label.localeCompare(z.label);
        }

        return -1;
      }
      if (z.sourceCodeType === SOURCE_CODE_TYPES.PYTHON_SCRIPT_NAME) {
        return 1;
      }

      return a.label.localeCompare(z.label);
    });
};
