import { useCallback, useMemo } from 'react';
import {
  LegendIconType,
  LegendSortMap,
  PanelCometMetadataType,
  PanelHoverDataSource,
  PanelTrace,
  TooltipDataPoint
} from '@experiment-management-shared/types';
import {
  getCometMetadataType,
  getHoverParamsFromCometMetadata,
  getIsHighlightedTrace,
  generateLegendLabelForLineChart,
  generateSortLegendItemsFunction,
  truncateLegendValue
} from '@experiment-management-shared/components/Charts/Legends/helpers';
import useCurvesChartTraceHighlight from './useCurvesChartTraceHighlight';

const useCurvesChartLegend = ({
  data,
  hoveredPoint,
  isMultiMetricsChart,
  experimentKeys,
  metricNames
}: {
  data: PanelTrace[];
  hoveredPoint?: TooltipDataPoint;
  isMultiMetricsChart: boolean;
  experimentKeys: string[];
  metricNames: string[];
}) => {
  const cometMetadataType = getCometMetadataType(data[0]);

  const {
    dataWithHighlight,
    updateHoveredData,
    hoveredData
  } = useCurvesChartTraceHighlight({
    hoveredPoint,
    data
  });

  const metricsSortMap = useMemo(() => {
    return metricNames.reduce<LegendSortMap>((acc, m, index) => {
      acc[m] = index;
      return acc;
    }, {});
  }, [metricNames]);

  const experimentsSortMap = useMemo(() => {
    return experimentKeys.reduce<LegendSortMap>((acc, e, index) => {
      acc[e] = index;
      return acc;
    }, {});
  }, [experimentKeys]);

  const legendItems = useMemo(() => {
    let filteredTraces: PanelTrace[] = [];

    switch (cometMetadataType) {
      case 'metric':
      case 'experiment_metric':
        filteredTraces = data.filter(
          trace =>
            trace?.visible !== 'legendonly' &&
            trace?.cometMetadata &&
            trace?.showlegend !== false
        );
        break;
    }

    const items = filteredTraces.map(trace => {
      const { cometMetadata } = trace;
      const {
        experimentKey,
        metricName,
        paramName,
        paramValue
      } = getHoverParamsFromCometMetadata(cometMetadata);

      const value = generateLegendLabelForLineChart(
        cometMetadataType,
        cometMetadata,
        isMultiMetricsChart
      );

      const { fullLabel, processedValue } = truncateLegendValue(value);

      return {
        color: (trace?.line?.color ?? '') as string,
        iconType: (trace?.line?.dash ?? 'line') as LegendIconType,
        label: processedValue,
        ...(fullLabel && { fullLabel }),
        key: value,
        metaData: {
          experimentKey,
          metricName,
          paramValue,
          paramName
        }
      };
    });

    switch (cometMetadataType) {
      case 'metric':
        items.sort(
          generateSortLegendItemsFunction([
            { property: 'metricName', map: metricsSortMap },
            { property: 'experimentKey', map: experimentsSortMap }
          ])
        );
        break;
      default:
        items.sort(
          generateSortLegendItemsFunction([
            { property: 'metricName', map: metricsSortMap }
          ])
        );
        break;
    }

    return items;
  }, [
    cometMetadataType,
    data,
    experimentsSortMap,
    isMultiMetricsChart,
    metricsSortMap
  ]);

  const calculateIsLegendItemHighlighted = useCallback(
    item => {
      if (!hoveredData.source || !cometMetadataType) {
        return false;
      }

      const map: Record<
        PanelHoverDataSource,
        Record<PanelCometMetadataType, string[]>
      > = {
        global: {
          metric: [],
          experiment_metric: [],
          group_by_metric: [],
          group_by_param: []
        },
        table: {
          metric: ['experimentKey'],
          experiment_metric: ['experimentKey'],
          group_by_metric: [],
          group_by_param: []
        },
        chart: {
          metric: ['experimentKey'],
          experiment_metric: ['metricName'],
          group_by_metric: [],
          group_by_param: []
        },
        legend: {
          metric: ['experimentKey', 'metricName'],
          experiment_metric: ['metricName'],
          group_by_metric: [],
          group_by_param: []
        }
      };

      const keys = map[hoveredData.source]?.[cometMetadataType] ?? [];

      return keys.length > 0
        ? getIsHighlightedTrace(hoveredData, item.metaData, keys)
        : false;
    },
    [cometMetadataType, hoveredData]
  );

  const onHoverLegendItem = useCallback(
    item => {
      updateHoveredData({
        metricName: item.metaData.metricName,
        paramName: item.metaData.paramName,
        paramValue: item.metaData.paramValue,
        experimentKey: item.metaData.experimentKey,
        source: 'legend'
      });
    },
    [updateHoveredData]
  );

  const onUnhoverLegendItem = useCallback(() => {
    updateHoveredData();
  }, [updateHoveredData]);

  return {
    items: legendItems,
    onHoverItem: onHoverLegendItem,
    onUnhoverItem: onUnhoverLegendItem,
    calculateIsItemHighlighted: calculateIsLegendItemHighlighted,
    dataWithHighlight
  };
};

export default useCurvesChartLegend;
