export const CHAIN_COLORS = [
  '5899DA',
  '945FCF',
  'ED4A7B',
  'F4B400',
  'BF399E',
  'FB9341',
  '12A4B4',
  '525DF4',
  'EF6868',
  '6C8893',
  '2E6497'
];

export const RESIZEABLE_POSITIONS = {
  top: false,
  right: false,
  bottom: false,
  left: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false
};
