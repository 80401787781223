import { useMutation } from 'react-query';

import { Panel } from '@experiment-management-shared/types';
import { createCodePanelTemplateAndRevision } from '@experiment-management-shared/api/python-panels/api';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants';

interface CreateRevisionCode {
  chart: Panel;
  onAddPanel: (chart: Panel, chartType: string) => void;
  onCloseModal: () => void;
}

export default function useCreateRevisionCodeMutation(projectId: string) {
  return useMutation(
    ({ chart }: CreateRevisionCode) =>
      createCodePanelTemplateAndRevision({
        code: chart.code || '',
        projectId,
        name:
          chart.chartName ||
          chartHelpers.getDefaultChartNameByType(BUILT_IN_CHART_TYPES.python)
      }),
    {
      onSuccess: (response, { chart, onAddPanel }) => {
        const templateId = response?.data?.templateId || '';
        const revisionId = response?.data?.revisionId || '';

        const newChart: Panel = {
          ...chart,
          code: null,
          codeVersion: '',
          templateId,
          revisionId
        };

        onAddPanel(newChart, newChart.chartType);
      },
      onSettled: (data, error, { onCloseModal }) => {
        onCloseModal();
      }
    }
  );
}
