import React, { ReactElement, ReactNode } from 'react';
import noop from 'lodash/noop';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import { getRedirectUrl } from '@shared/utils';
import { Box } from '@material-ui/core';

export interface RedirectLinkProps
  extends React.HTMLProps<HTMLHtmlElement | HTMLLinkElement> {
  redirectUrl?: string | null;
  target?: string;
  args?: string[];
  resource: REDIRECT_LINK_RESOURCES;
  children: ReactNode;
  component?: ReactElement | string;
}

const RedirectLink = ({
  redirectUrl = null,
  resource,
  args = [],
  children,
  target = '_blank',
  component = 'a',
  ...props
}: RedirectLinkProps) => {
  if (!redirectUrl && args.length === 0) {
    console.warn('Should have either ({redirectUrl}) or ({args})');
    return <span>{children}</span>;
  }

  const url = redirectUrl || getRedirectUrl(resource, args);
  const onClick = component !== 'a' ? () => window.open(url, target) : noop;

  return (
    <Box
      data-test="redirect-link"
      href={url}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      target={target}
      key={`url-${url}`}
      rel="noopener noreferrer"
      component={component as React.ElementType}
      onClick={onClick}
      {...props}
    >
      {children}
    </Box>
  );
};

export default RedirectLink;
