export const MODEL_STREAMLINE_REGISTRATION_VISIBILITY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};

export const MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE = {
  NO_MODELS: 'noModels',
  NEW_VERSION: 'newVersion',
  NEW_MODEL: 'newModel',
  ERROR: 'error',
  SUCCESS: 'success'
};
