export const mapStringOptionsToSelectOptions = (strList = []) =>
  strList.map(str => ({
    value: str,
    source: str,
    label: str
  }));

export const mapStageOptionsToSelectOptions = (stageList = []) =>
  stageList?.map(({ color, stage }) => ({
    value: stage,
    source: stage,
    label: stage,
    color
  }));
