import React, { useEffect, useMemo } from 'react';

import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import {
  ACTIVE_FETCH_INTERVAL,
  BUILT_IN_CHART_TYPES,
  CHART_AXIS_STYLING,
  CHART_CONFIG_MODIFIERS,
  DEFAULT_CUSTOM_RANGE,
  DEFAULT_GROUPING_STATE
} from '@experiment-management-shared/constants/chartConstants';

import { OptimizedPlot } from '@DesignSystem/charts';
import BarChartTooltip from './BarChartTooltip';
import { useBarChartTooltip } from './useBarChartTooltip';
import {
  getGroupedBars,
  useGetBarColor,
  getSortedSeries
} from '@experiment-management-shared/components/Charts/PlotlyChart/helpers/barChart';
import ChartContainer, {
  GeneralChartContainerProps
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import {
  Aggregation,
  BarChartType,
  BarMode,
  BarOrientation,
  CustomRange,
  DataSource,
  Experiment,
  Grouping,
  LegendMode,
  MetricColorMap,
  PanelCometMetadata,
  PanelGlobalConfig,
  PanelMetric,
  PanelType
} from '@experiment-management-shared/types';
import usePanelSampleSize from '@experiment-management-shared/hooks/usePanelSampleSize';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';
import {
  getBarMetrics,
  getLegendsKeysMap
} from '@experiment-management-shared/components/Charts/ChartHelpers';
import useExperimentsPanelData from '@experiment-management-shared/api/useExperimentsPanelData';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import useChartDataSourceChangeNotificator from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataSourceChangeNotificator';
import useChartDataRevision from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataRevision';
import usePlotlyBaseChart from '@experiment-management-shared/components/Charts/Chart/chartHooks/usePlotlyBaseChart';
import { getExperimentsColorMap } from '@experiment-management-shared/utils/experimentHelpers';
import useChartTraceClick from '@experiment-management-shared/hooks/useChartTraceClick';
import { LEGEND_MODE } from '@experiment-management-shared/constants';
import { Legend } from '@experiment-management-shared/components/Charts/Legends';
import LegendWrapper from '@experiment-management-shared/components/Charts/Legends/LegendWrapper';
import useBarChartLegend from '@experiment-management-shared/components/Charts/PlotlyChart/BarChart/useBarChartLegend';
import {
  generateGuaranteeUniquenessFunction,
  generateLegendKeysString,
  getHoverParamsFromCometMetadata,
  truncateLegendValueForExport
} from '@experiment-management-shared/components/Charts/Legends/helpers';

type BarChartProps = {
  containerProps: GeneralChartContainerProps;
  isChartPreview?: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  hiddenExperimentKeys?: string[];
  isAutoRefreshEnabled: boolean;
  activeIntervalFetchDelay?: number;
  title?: string;
  chartName?: string;
  onChangeLegendMode?: never;
  onDataSourceChange?: () => void;
  onFinishRender?: () => void;
  enableDataRevision?: boolean;
  isPendingRendering?: boolean;
  dataRevision?: number;
  revision?: number;
  sampleSize?: number;
  sampleSizes?: never;
  handleSampleSizeChange?: never;
  fetchFull?: boolean;
  selectedLegendKeys: never;
  legendMode: LegendMode;
  hiddenMenuItems?: string[];
  chartClickEnabled?: boolean;
  customRange: CustomRange;
  panelGlobalConfig?: PanelGlobalConfig;
  customXAxisTitle: string;
  showXAxisTitle: boolean;
  customYAxisTitle: string;
  showYAxisTitle: boolean;
  metrics: PanelMetric[];
  aggregationX?: Aggregation; // not exist in current implementation, legacy data structure
  metricName?: string; // not exist in current implementation, legacy data structure
  metricNames?: string[]; // not exist in current implementation, legacy data structure
  grouping: Grouping;
  metricColorMap?: MetricColorMap;
  plotType: BarChartType;
  orientation?: BarOrientation;
  locked?: boolean;
  showLock?: boolean;
  onLockClick?: unknown;
  globalConfigMap: Record<string, string[]>;
};

const BarChart = ({
  containerProps,
  isChartPreview = false,
  experiments,
  experimentKeys,
  hiddenExperimentKeys,
  isAutoRefreshEnabled,
  activeIntervalFetchDelay = ACTIVE_FETCH_INTERVAL,
  title,
  chartName = '',
  onChangeLegendMode,
  onDataSourceChange = noop,
  onFinishRender = noop,
  enableDataRevision = false,
  isPendingRendering = false,
  dataRevision = 0,
  revision = 0,
  sampleSize: panelSampleSize,
  sampleSizes,
  handleSampleSizeChange,
  fetchFull = false,
  selectedLegendKeys,
  legendMode = LEGEND_MODE.AUTO,
  hiddenMenuItems,
  chartClickEnabled = true,
  customRange = DEFAULT_CUSTOM_RANGE,
  panelGlobalConfig,
  customXAxisTitle = '',
  showXAxisTitle = false,
  customYAxisTitle = '',
  showYAxisTitle = false,
  metricColorMap,
  metrics: initialMetrics,
  aggregationX,
  metricName,
  metricNames,
  grouping = DEFAULT_GROUPING_STATE.BAR as Grouping,
  orientation = 'v',
  plotType = CHART_CONFIG_MODIFIERS.BAR.value as BarChartType,
  locked = false,
  showLock,
  onLockClick,
  globalConfigMap
}: BarChartProps) => {
  const chartType: PanelType = BUILT_IN_CHART_TYPES['BuiltIn/Bar'] as PanelType;
  const showLegend = legendMode === LEGEND_MODE.ON;

  // taking into account that data migration is not conducted the chart itself can have different input params,
  // the next part of code is making this migration on fly
  const metrics = useMemo(() => {
    return getBarMetrics({
      aggregationX,
      metricName,
      metricNames,
      metrics: initialMetrics
    });
  }, [aggregationX, metricName, metricNames, initialMetrics]);

  const params = useMemo(() => {
    return grouping?.groupByParameter ? [grouping.groupByParameter] : [];
  }, [grouping.groupByParameter]);

  const { sampleSize } = useMemo(() => {
    const extendedConfig = chartHelpers.extendConfigWithGlobalConfig(
      {
        sampleSize: panelSampleSize,
        locked,
        chartType
      },
      panelGlobalConfig,
      globalConfigMap
    );

    return {
      ...extendedConfig
    };
  }, [locked, chartType, panelSampleSize, panelGlobalConfig, globalConfigMap]);

  const {
    computedSampleSize,
    computedHandleSampleSizeChange
  } = usePanelSampleSize({
    sampleSize,
    sampleSizes,
    handleSampleSizeChange
  });

  const { shouldRefetch, actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    title,
    chartName,
    chartType,
    titleProps: {
      metrics
    }
  });

  const {
    data: panelData,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useExperimentsPanelData(
    {
      experimentKeys,
      fetchFull,
      metrics,
      params,
      sampleSize: computedSampleSize,
      type: chartType
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );

  const rawDataSources = useDeepMemo(() => {
    if (isLoading) return [];

    const data = chartHelpers.toDataSources({
      data: panelData,
      experimentKeys,
      selectedXAxis: undefined,
      type: chartType
    });

    const localDataSources = chartHelpers.normalizeDataSource({
      dataSources: data,
      selectedXAxis: undefined,
      selectedYAxis: undefined,
      chartType
    });

    if (isArray(hiddenExperimentKeys)) {
      return chartHelpers.applyVisibilityOfExperiments(
        localDataSources,
        hiddenExperimentKeys
      );
    } else {
      return localDataSources;
    }
  }, [chartType, panelData, isLoading, experimentKeys, hiddenExperimentKeys]);

  useChartDataSourceChangeNotificator({
    isLoading,
    dataSources: rawDataSources,
    onDataSourceChange
  });

  const {
    delayedDataSources: dataSources,
    hasDelayedDataSources,
    isInitialRenderDelayed,
    calculatedRevision
  } = useChartDataRevision<DataSource[]>({
    enableDataRevision,
    isPendingRendering,
    dataRevision,
    dataSources: rawDataSources,
    revision
  });

  const {
    setExportData,
    setExportLayout,
    containerRef,
    handleExportJSON,
    handleExportData,
    handleResetZoom,
    disableResetZoom,
    currentLayout,
    chartBaseLayout,
    PlotlyProps
  } = usePlotlyBaseChart({
    revision: calculatedRevision,
    isChartPreview,
    onFinishRender,
    orientation,
    title: actualTitle,
    chartType
  });

  const { xAxisTitle, yAxisTitle } = useMemo(() => {
    return chartHelpers.calculateBarChartAxisTitles({
      showXAxisTitle,
      customXAxisTitle,
      showYAxisTitle,
      customYAxisTitle,
      metrics
    });
  }, [
    showXAxisTitle,
    customXAxisTitle,
    showYAxisTitle,
    customYAxisTitle,
    metrics
  ]);

  const experimentColorMap = useDeepMemo(
    () => getExperimentsColorMap(experiments),
    [experiments]
  );

  const legendKeysMap = useDeepMemo(() => {
    return getLegendsKeysMap(dataSources, experiments, selectedLegendKeys);
  }, [dataSources, experiments, selectedLegendKeys]);

  useEffect(() => {
    if (isError || isEmpty(dataSources)) {
      onFinishRender();
    }
  }, [dataSources, isError, isLoading, onFinishRender]);

  const isAggregationActive =
    plotType === CHART_CONFIG_MODIFIERS.BAR.value ||
    plotType === CHART_CONFIG_MODIFIERS.STACKED_BAR.value;
  const isStackedBarChart =
    plotType === CHART_CONFIG_MODIFIERS.STACKED_BAR.value;
  const isStandardBarChart = plotType === CHART_CONFIG_MODIFIERS.BAR.value;
  const orientationAxis = orientation === 'v' ? 'y' : 'x';

  const {
    handlePointBeforeHover,
    handlePointHover,
    handlePointUnHover,
    tooltipData
  } = useBarChartTooltip(PlotlyProps?.ref as never);

  const { handleChartClick } = useChartTraceClick({
    hoveredPoint: tooltipData?.point,
    traceClickable: chartClickEnabled
  });

  const getBarColor = useGetBarColor({
    experimentColorMap,
    metricColorMap
  });

  const axisWithCategory = orientation === 'v' ? 'xaxis' : 'yaxis';

  const previousLayout = useMemo(() => {
    // check why is this necessary
    if (!isEmpty(currentLayout) && !isChartPreview) {
      return chartBaseLayout;
    }

    return {
      ...chartBaseLayout,
      margin: {
        ...chartBaseLayout.margin
      }
    };
  }, [chartBaseLayout, currentLayout, isChartPreview]);

  const showticklabels = useMemo(() => {
    const hasMultipleMetrics = metrics.length > 1;
    const isStackedChartWithGroupingByParameter =
      plotType === CHART_CONFIG_MODIFIERS.STACKED_BAR.value &&
      grouping.groupByParameter &&
      grouping.enabled;

    // showticklabels should be boolean, if it's undefined the ticks are shown
    return !!(
      (plotType === CHART_CONFIG_MODIFIERS.BAR.value ||
        isStackedChartWithGroupingByParameter) &&
      hasMultipleMetrics
    );
  }, [grouping.enabled, grouping.groupByParameter, metrics.length, plotType]);

  const layout = useMemo(() => {
    const xAxisData = {
      ...chartHelpers.generateAxisTitle(xAxisTitle)
    };

    const yAxisData = {
      ...chartHelpers.generateAxisTitle(yAxisTitle)
    };

    return {
      ...previousLayout,
      barmode: (plotType === CHART_CONFIG_MODIFIERS.BAR.value
        ? 'group'
        : 'stack') as BarMode,
      [axisWithCategory === 'yaxis' ? 'xaxis' : 'yaxis']: {
        ...CHART_AXIS_STYLING,
        ...(axisWithCategory === 'yaxis' ? xAxisData : yAxisData)
      },
      [axisWithCategory]: {
        ...previousLayout[axisWithCategory],
        ...(axisWithCategory === 'xaxis' ? xAxisData : yAxisData),
        showticklabels,
        // used for breaking down
        showdividers: false
      }
    };
  }, [
    axisWithCategory,
    plotType,
    previousLayout,
    showticklabels,
    xAxisTitle,
    yAxisTitle
  ]);

  const data = useMemo(() => {
    const series = getSortedSeries({
      dataSources,
      getBarColor,
      isAggregationActive,
      metricColorMap,
      legendKeysMap,
      metrics,
      orientation,
      orientationAxis,
      plotType,
      grouping
    });

    if (grouping.enabled) {
      return getGroupedBars({
        data: series,
        grouping,
        orientation,
        metrics,
        metricColorMap
      });
    }

    return series;
  }, [
    dataSources,
    getBarColor,
    grouping,
    isAggregationActive,
    metricColorMap,
    metrics,
    orientation,
    orientationAxis,
    legendKeysMap,
    plotType
  ]);

  const {
    items,
    calculateIsItemHighlighted,
    onHoverItem,
    onUnhoverItem,
    dataWithHighlight
  } = useBarChartLegend({
    data,
    hoveredPoint: tooltipData?.point,
    isAggregationActive,
    experimentKeys,
    metrics,
    groupAggregation: grouping?.aggregation,
    isStackedBarChart
  });

  const downloadableLayout = useMemo(
    () => ({
      ...layout,
      [axisWithCategory]: {
        ...layout[axisWithCategory],
        showticklabels: true
      },
      margin: {
        ...layout.margin,
        b: 40,
        l: 40,
        r: 100,
        t: 16
      },
      showlegend: showLegend
    }),
    [axisWithCategory, layout, showLegend]
  );

  useEffect(() => {
    setExportLayout(downloadableLayout as never);
  }, [downloadableLayout, setExportLayout]);

  useEffect(() => {
    const guaranteeUniqueness = generateGuaranteeUniquenessFunction();

    const exportData = data.map(trace => {
      if (!trace?.cometMetadata && !trace?.cometMetadataArray) return trace;

      const cometMetadata = trace.cometMetadataArray
        ? trace.cometMetadataArray[0]
        : trace.cometMetadata;
      const {
        metricName,
        paramName,
        paramValue
      } = getHoverParamsFromCometMetadata(cometMetadata);
      const {
        experimentName,
        legendKeys
      } = cometMetadata as PanelCometMetadata;

      let value = trace.name;
      let showlegend = trace.showlegend;

      switch (trace.cometMetadataType) {
        case 'metric':
          if (isStackedBarChart) {
            value = `${metricName}`;
          } else {
            value = `${experimentName}${generateLegendKeysString(legendKeys)}`;
          }
          break;
        case 'experiment_metric':
          if (isStandardBarChart) {
            showlegend = false;
          } else {
            value = `${metricName}${generateLegendKeysString(legendKeys)}`;
          }

          break;
        case 'group_by_metric':
          if (isAggregationActive) {
            showlegend = false;
          } else {
            value = `${metricName}`;
          }
          break;
        case 'group_by_param':
          if (isAggregationActive) {
            value = `${grouping.aggregation} - ${paramName}: ${paramValue}`;
          } else {
            value = `${metricName} - ${paramName}: ${paramValue}`;
          }
          break;
      }

      return {
        ...trace,
        showlegend,
        name: guaranteeUniqueness(truncateLegendValueForExport(value))
      };
    });

    setExportData(exportData as never);
  }, [
    data,
    isStackedBarChart,
    isStandardBarChart,
    grouping?.aggregation,
    setExportData,
    isAggregationActive
  ]);

  return (
    <ChartContainer
      {...containerProps}
      isError={isError}
      isFetching={isFetching || hasDelayedDataSources}
      isLoading={isLoading || isInitialRenderDelayed}
      isPreviousData={isPreviousData}
      title={actualTitle}
      hasData={!isEmpty(dataSources)}
      locked={locked}
      showLock={showLock}
      onLockClick={onLockClick as never}
      hiddenMenuItems={hiddenMenuItems}
      onExportJSON={handleExportJSON}
      onExportData={handleExportData}
      onResetZoom={handleResetZoom}
      disableResetZoom={disableResetZoom}
      sampleSize={computedSampleSize}
      sampleSizes={sampleSizes}
      onChangeSampleSize={computedHandleSampleSizeChange}
      legendMode={legendMode}
      onChangeLegendMode={onChangeLegendMode}
      fullScreenType={'badges'}
    >
      <LegendWrapper
        legend={
          showLegend && (
            <Legend
              items={items}
              calculateIsItemHighlighted={calculateIsItemHighlighted}
              onHoverItem={onHoverItem}
              onUnhoverItem={onUnhoverItem}
            />
          )
        }
        chartContainerRef={containerRef as React.RefObject<HTMLDivElement>}
      >
        {tooltipData && <BarChartTooltip tooltipData={tooltipData} />}
        <OptimizedPlot
          {...PlotlyProps}
          customRange={customRange}
          data={dataWithHighlight}
          layout={layout}
          onClick={handleChartClick}
          onBeforeHover={handlePointBeforeHover}
          onHover={handlePointHover}
          onUnhover={handlePointUnHover}
        />
      </LegendWrapper>
    </ChartContainer>
  );
};

BarChart.CONFIG_PROPERTIES = [
  'containerProps',
  'isChartPreview',
  'experiments',
  'experimentKeys',
  'hiddenExperimentKeys',
  'isAutoRefreshEnabled',
  'activeIntervalFetchDelay',
  'title',
  'chartName',
  'onChangeLegendMode',
  'onDataSourceChange',
  'onFinishRender',
  'enableDataRevision',
  'isPendingRendering',
  'dataRevision',
  'revision',
  'sampleSize',
  'sampleSizes',
  'handleSampleSizeChange',
  'fetchFull',
  'selectedLegendKeys',
  'hiddenMenuItems',
  'chartClickEnabled',
  'panelGlobalConfig',
  'customXAxisTitle',
  'showXAxisTitle',
  'customYAxisTitle',
  'showYAxisTitle',
  'customRange',
  'onLockClick',
  'metricColorMap',
  'metrics',
  'aggregationX',
  'metricName',
  'metricNames',
  'grouping',
  'orientation',
  'plotType',
  'locked',
  'showLock',
  'onLockClick',
  'globalConfigMap',
  'legendMode'
];

export default BarChart;
