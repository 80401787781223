import React, { useState } from 'react';

import { DateRange, RadioSelector, Select } from '@DesignSystem/controllers';
import { mapModelVersions } from '@mpm-druid/utils';
import { PanelContainer } from '../PanelContainer';
import { useMPMPerformanceParams } from '@mpm-druid/hooks';
import { Button } from '@ds';
import { PredicatesPopover } from '../PredicatesPopover';
import {
  INTERVAL_TYPE_OPTIONS,
  PERFORMANCE_DATE_RANGES,
  MPM_CHART_DISABLED_ZOOM,
  PANEL_NAMES,
  PANEL_SECTIONS,
  DEFAULT_VERSION,
  CHART_COLORS,
  FEATURE_TYPES
} from '@mpm-druid/constants';
import './ModelFeaturePerformancePage.scss';
import {
  FeatureDetails,
  ModelDetailsType,
  PredicateQuery
} from '@mpm-druid/types';

type ModelFeaturePerformancePageProps = {
  model: ModelDetailsType;
  feature: FeatureDetails;
  predicates: PredicateQuery[];
  setPredicates: (predicates: PredicateQuery[]) => void;
};

export const ModelFeaturePerformancePage = ({
  feature,
  model,
  predicates,
  setPredicates
}: ModelFeaturePerformancePageProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const versions = DEFAULT_VERSION.concat(mapModelVersions(model.versions));
  const distributionSection =
    feature?.type === FEATURE_TYPES.CATEGORICAL
      ? PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES
      : PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL;

  const {
    version,
    onUpdateVersion,

    from,
    to,
    intervalType,
    dateRange,
    setStartDate,
    setEndDate,
    calcMaxDate,
    setDateRange,
    onUpdateIntervalType,
    onChangeRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  } = useMPMPerformanceParams(versions);

  const openPredicatesPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="model-performance-tab">
      <div className="model-performance-tab-panels">
        <div className="model-performance-tab-header">
          <Button
            size="medium"
            type="secondary"
            onClick={openPredicatesPopover}
            active={!!anchorEl}
          >
            Filters{' '}
            {predicates.length - 1 > 0 ? `(${predicates.length - 1})` : ''}
          </Button>
          <PredicatesPopover
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handlePredicatesChange={setPredicates}
          />
          <Select
            onChange={onUpdateVersion}
            options={versions}
            value={version}
            withInput
          />

          <div className="model-performance-tab-header-divider" />

          <Select
            onChange={onUpdateIntervalType}
            options={INTERVAL_TYPE_OPTIONS}
            value={intervalType}
          />

          <div className="model-performance-tab-header-divider" />

          <div className="model-performance-tab-header-date-ranges">
            {PERFORMANCE_DATE_RANGES[intervalType]?.map(days => (
              <RadioSelector
                isSelected={days === dateRange}
                key={`date-range-${days}`}
                onClick={() => {
                  setDateRange(days);
                  setEndDate(null);
                  setStartDate(new Date());
                }}
                text={`${days}d`}
              />
            ))}
          </div>

          <DateRange
            startDate={handledCalendarStartDate}
            endDate={handledCalendarEndDate}
            onChange={onChangeRange}
            calcMaxDate={calcMaxDate}
          />
        </div>

        <div className="model-performance-tab-content">
          <PanelContainer
            title={PANEL_NAMES.DISTRIBUTION}
            from={from}
            to={to}
            intervalType={intervalType}
            section={distributionSection}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            feature={feature}
            modelId={model.id}
            model={model}
          />

          <PanelContainer
            title={PANEL_NAMES.DRIFT}
            from={from}
            to={to}
            intervalType={intervalType}
            section={PANEL_SECTIONS.FEATURE_DRIFT}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            feature={feature}
            modelId={model.id}
            model={model}
          />

          <PanelContainer
            title={PANEL_NAMES.MISSING_VALUES}
            from={from}
            to={to}
            intervalType={intervalType}
            section={PANEL_SECTIONS.FEATURE_MISSING_VALUES}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            feature={feature}
            modelId={model.id}
            model={model}
          />
        </div>
      </div>
    </div>
  );
};
