import React from 'react';
import PropTypes from 'prop-types';

const ConfirmDeleteContent = ({ modelName }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>This is the only version of this model.</span>
      <span>
        Deleting this version will delete the model ({modelName}) from the
        registry
      </span>
    </div>
  );
};

ConfirmDeleteContent.propTypes = {
  modelName: PropTypes.string.isRequired
};

export default ConfirmDeleteContent;
