import React from 'react';
import PropTypes from 'prop-types';

import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';
import { GRID_COLUMNS } from '../../constants/experimentGridConstants';

const COLUMNS_WITHOUT_LEFT_DIVIDER = [
  GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME,
  GRID_COLUMNS.EXPERIMENT_DETAILS_ACTIONS
];

const FixedColumnCell = props => {
  const {
    showLeftDivider,
    tableColumn: { column: { name: columnName } = {} } = {}
  } = props;

  return (
    <TableFixedColumns.Cell
      {...props}
      className={classNames('react-grid-fixed-cell', columnName)}
      showLeftDivider={
        showLeftDivider && COLUMNS_WITHOUT_LEFT_DIVIDER.includes(columnName)
          ? false
          : showLeftDivider
      }
    />
  );
};

FixedColumnCell.propTypes = {
  showLeftDivider: PropTypes.bool.isRequired,
  tableColumn: PropTypes.shape({
    column: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default FixedColumnCell;
