import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import uniq from 'lodash/uniq';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { Tooltip } from '@ds';
import { Table } from '@DesignSystem/tables';

import styles from './AudioTab.module.scss';
import AudioActionsCell from '@experiment-details/components/AudioTab/AudioActionsCell';
import AudioCell from '@experiment-details/components/AudioTab/AudioCell';
import { EmptyTableMessage } from '@shared';

const COLUMN = {
  AUDIO: 'link',
  NAME: 'fileName',
  CONTEXT: 'runContext',
  STEP: 'step',
  METADATA_KEY: 'metadata',
  ACTIONS: 'rowActionMenu'
};

const DEFAULT_COLUMNS = [
  {
    name: COLUMN.AUDIO,
    title: 'Audio',
    type: 'string'
  },
  {
    name: COLUMN.NAME,
    title: 'Name',
    type: 'string'
  },
  {
    name: COLUMN.CONTEXT,
    title: 'Context',
    type: 'string'
  },
  {
    name: COLUMN.STEP,
    title: 'Step',
    type: 'string'
  }
];

const SUFFIX_COLUMNS = [
  {
    name: COLUMN.ACTIONS,
    title: '  ',
    type: 'string'
  }
];

const RIGHT_COLUMNS = [COLUMN.ACTIONS];

export const DEFAULT_COLUMN_WIDTH = 100;

export const DEFAULT_COLUMN_MAX_MAX_WIDTH = {
  width: DEFAULT_COLUMN_WIDTH,
  min: 100,
  max: 800
};
export const MIN_MAX_COLUMN_WIDTH_MAP = {
  [COLUMN.AUDIO]: {
    min: 300,
    width: 500,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [COLUMN.STEP]: {
    min: 100,
    width: 'auto',
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [COLUMN.ACTIONS]: {
    min: 40,
    width: 40,
    max: 40
  }
};

export const COMPARE_MIN_MAX_COLUMN_WIDTH_MAP = {
  [COLUMN.AUDIO]: {
    min: 300,
    width: 300,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  }
};

const parseMetadata = (metadata: string): MetadataMap => {
  try {
    return JSON.parse(metadata) || {};
  } catch (_) {
    return {};
  }
};

const calculateWidthsMap = (
  columns: TableColumn[],
  hasMultipleExperiments: boolean
) => {
  return columns.map(column => {
    const defaultBoundaries =
      MIN_MAX_COLUMN_WIDTH_MAP[column.name] ?? DEFAULT_COLUMN_MAX_MAX_WIDTH;
    const boundaries = hasMultipleExperiments
      ? COMPARE_MIN_MAX_COLUMN_WIDTH_MAP[column.name] ?? defaultBoundaries
      : defaultBoundaries;

    return {
      columnName: column.name,
      ...boundaries
    };
  });
};

type TableColumn = {
  title: string;
  name: string;
  type: string;
};

type MetadataMap = Record<string, string | number | object>;

type RawAudioAsset = {
  assetId: string;
  fileName: string;
  link: string;
  metadata: string;
  runContext: string;
  step: number;
};

type AudioAsset = RawAudioAsset & {
  metadataMap: MetadataMap;
};

type AudiosTableProps = {
  assets: RawAudioAsset[];
  hasMultipleExperiments: boolean;
};

const AudiosTable = ({ assets, hasMultipleExperiments }: AudiosTableProps) => {
  const [columnWidths, setColumnWidths] = useState(
    calculateWidthsMap(
      [...DEFAULT_COLUMNS, ...SUFFIX_COLUMNS],
      hasMultipleExperiments
    )
  );
  const [columnOrder, setColumnOrder] = useState();

  const processedAssets = useMemo(() => {
    return assets.map<AudioAsset>(asset => {
      return {
        ...asset,
        metadataMap: parseMetadata(asset.metadata)
      };
    });
  }, [assets]);

  const metadataColumns = useMemo(
    () =>
      uniq(
        processedAssets.reduce<string[]>(
          (result, { metadataMap }) => result.concat(Object.keys(metadataMap)),
          []
        )
      ),
    [processedAssets]
  );

  const columns: TableColumn[] = useMemo(() => {
    return [
      ...DEFAULT_COLUMNS,
      ...metadataColumns.map(name => ({ name, title: name, type: 'string' })),
      ...SUFFIX_COLUMNS
    ];
  }, [metadataColumns]);

  const dataTypes = useMemo(() => {
    return [
      {
        cols: [COLUMN.AUDIO],
        cell: ({ row }: { row: AudioAsset }) => {
          return <AudioCell link={row.link} />;
        }
      },
      {
        cols: [COLUMN.CONTEXT, COLUMN.STEP],
        cell: ({ value }: { value: string }) => {
          return (
            <div className={cx(styles.cellContent, styles.ellipsis)}>
              {value}
            </div>
          );
        }
      },
      {
        cols: metadataColumns,
        cell: ({
          row,
          column
        }: {
          row: AudioAsset;
          column: { name: string };
        }) => {
          const value = row.metadataMap[column.name];
          const formattedValue = isObject(value)
            ? JSON.stringify(value, null, '  ')
            : value;

          return (
            <Tooltip content={formattedValue} placement="top" wrapper={false}>
              <div className={cx(styles.cellContent, styles.ellipsis)}>
                {formattedValue}
              </div>
            </Tooltip>
          );
        }
      },
      {
        cols: [COLUMN.ACTIONS],
        cell: ({ row }: { row: AudioAsset }) => {
          return <AudioActionsCell link={row.link} />;
        }
      }
    ];
  }, [metadataColumns]);

  const handleColumnWidthsChange = useCallback(rawColumnWidths => {
    // check min/max boundaries and fix if size is out
    const columnWidths = rawColumnWidths.map(
      (c: { columnName: string | number; width?: number }) => {
        if (isNumber(c.width)) {
          const boundaries =
            MIN_MAX_COLUMN_WIDTH_MAP[c.columnName] ||
            DEFAULT_COLUMN_MAX_MAX_WIDTH;

          return {
            ...c,
            width: Math.max(Math.min(c.width, boundaries.max), boundaries.min)
          };
        }

        return c;
      }
    );

    setColumnWidths(columnWidths);
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table
        columns={columns}
        dataTypes={dataTypes}
        rows={processedAssets}
        isFetching={false}
        rightColumns={RIGHT_COLUMNS}
        noPaddingColumns={RIGHT_COLUMNS}
        columnWidthsConfig={{
          isDisabled: isEmpty(processedAssets),
          columnWidths,
          onColumnWidthsChange: handleColumnWidthsChange
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder: columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        renderEmptyState={materialProps => (
          <EmptyTableMessage {...materialProps} message="No audio samples" />
        )}
        paginationConfig={{ isDisabled: true }}
        selectionConfig={{ isDisabled: true }}
        sortingConfig={{ isDisabled: true }}
        rowIdKey="assetId"
      />
    </div>
  );
};

export default React.memo(AudiosTable);
