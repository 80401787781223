import React from 'react';
import PropTypes from 'prop-types';
import Ansi from 'ansi-to-react';

class Terminal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const span = document.querySelector('.stdout');
    if (span) span.scrollTop = span.scrollHeight;
  }

  backspace(text) {
    const retval = [];

    for (let i = 0; i < text.length; i++) {
      if (text[i] == '\x08') {
        if (retval.length > 0) {
          retval.pop();
        } // nothing to pop
      } else {
        retval.push(text[i]);
      }
    }

    return retval.join('');
  }

  cropAndJoinText(text) {
    if (!text || !text.length) return null;

    const { trimmed } = this.props;

    if (!trimmed) return this.backspace(text.join(''));
    return text.join('\n');
  }

  render() {
    const text = this.cropAndJoinText(this.props.data);
    const Class = this.props.Classes;
    if (!text) {
      return (
        <div className="empty-detail-partial">There is no data to display</div>
      );
    }

    return <Ansi className={Class}>{text}</Ansi>;
  }
}

Terminal.defaultProps = {
  data: []
};

Terminal.propTypes = {
  data: PropTypes.array,
  trimmed: PropTypes.bool.isRequired
};

export default Terminal;
