import React from 'react';

import { StyledTooltip } from '@DesignSystem/data-display';
import {
  HuggingFaceIcon,
  InfoOutlinedIcon,
  KerasIcon,
  LLMIcon,
  PythonIcon,
  PytorchIcon,
  PytorchLightningIcon,
  ScikitLearnIcon,
  XGBoostIcon
} from '@Icons-outdated';
import { addEnvImports } from '@onboarding/utils';

import {
  HUGGING_FACE_CODE_SNIPPET,
  KERAS_CODE_SNIPPET,
  LLM_EXAMPLE,
  PYTORCH_CODE_SNIPPET,
  PYTORCH_LIGHTNING_CODE_SNIPPET,
  SCIKIT_LEARN_CODE_SNIPPET,
  XGBOOST_CODE_SNIPPET
} from './framework-examples';

import styles from './QuickStartGuide.module.scss';

export const DefaultImportSection = (
  <p>
    Import comet_ml at the top of your code and define your Experiment Class.{' '}
    <StyledTooltip
      title="An Experiment automatically logs script output, code,
and command-line arguments and, for the supported
 frameworks, also logs hyperparameters, metrics, and
 model configuration."
      placement="top"
    >
      <span className={styles.infoIconContainer}>
        <InfoOutlinedIcon />
      </span>
    </StyledTooltip>
  </p>
);

export const CODE_FRAMEWORKS = {
  PYTORCH: {
    value: 'pytorch',
    label: 'Pytorch',
    prefixIcon: PytorchIcon,
    snippetFileName: `Pytorch_example.py`,
    codeSnippet: PYTORCH_CODE_SNIPPET,
    colabLink: `https://colab.research.google.com/github/comet-ml/comet-examples/blob/master/integrations/model-training/pytorch/notebooks/Comet_and_Pytorch.ipynb`,
    docLink: 'integrations/ml-frameworks/pytorch/'
  },
  PYTORCH_LIGHTNING: {
    value: 'pytorch-lightning',
    label: 'Pytorch Lightning',
    prefixIcon: PytorchLightningIcon,
    snippetFileName: `Pytorch_light_example.py`,
    codeSnippet: PYTORCH_LIGHTNING_CODE_SNIPPET,
    colabLink: `https://colab.research.google.com/github/comet-ml/comet-examples/blob/master/integrations/model-training/pytorch-lightning/notebooks/Comet_and_Pytorch_Lightning.ipynb`,
    docLink: 'integrations/ml-frameworks/pytorch-lightning/',
    importSection: (
      <p>
        Import comet_ml and at the top of your code along with the CometLogger
        from pytorch_lightning.
      </p>
    )
  },
  KERAS: {
    value: 'keras',
    label: 'Keras',
    prefixIcon: KerasIcon,
    snippetFileName: `Keras_example.py`,
    codeSnippet: KERAS_CODE_SNIPPET,
    colabLink: `https://colab.research.google.com/github/comet-ml/comet-examples/blob/master/integrations/model-training/keras/notebooks/Comet_with_Keras.ipynb`,
    docLink: 'integrations/ml-frameworks/keras/',
    importSection: (
      <div>
        {DefaultImportSection}
        <p>
          Once an Experiment is created, Comet will auto-log metrics,
          hyperparams, model graph and more.
        </p>
      </div>
    )
  },
  SCIKIT_LEARN: {
    value: 'scikit-learn',
    label: 'Scikit-learn',
    prefixIcon: ScikitLearnIcon,
    snippetFileName: `Scikit-learn_example.py`,
    codeSnippet: SCIKIT_LEARN_CODE_SNIPPET,
    colabLink: `https://colab.research.google.com/drive/1fWdJl-NWcb5ryB8OdM-GFWrqz4e0cgJe?usp=sharing`,
    docLink: 'integrations/ml-frameworks/scikit-learn/',
    importSection: (
      <div>
        {DefaultImportSection}
        <p>
          Our scikit-learn integration will automatically log all your
          hyperparameters.
        </p>
      </div>
    )
  },
  HUGGING_FACE: {
    value: 'hugging-face',
    label: 'Hugging Face',
    prefixIcon: HuggingFaceIcon,
    snippetFileName: `Hugging_Face_example.py`,
    codeSnippet: HUGGING_FACE_CODE_SNIPPET,
    colabLink: `https://colab.research.google.com/github/comet-ml/comet-examples/blob/master/integrations/model-training/hugging_face/notebooks/Comet_with_Hugging_Face_Trainer.ipynb`,
    docLink: 'integrations/ml-frameworks/huggingface/',
    importSection: (
      <p>
        Once you have comet_ml installed, all you need to start using Comet
        alongside Hugging Face is to configure the COMET_API_KEY variable.
        Hugging Face will create a Comet Experiment behind the scenes and log
        all the data.
      </p>
    )
  },
  XGBoost: {
    value: 'xgboost',
    label: 'XGBoost',
    prefixIcon: XGBoostIcon,
    snippetFileName: `XGBoost_example.py`,
    codeSnippet: XGBOOST_CODE_SNIPPET,
    colabLink: `https://colab.research.google.com/drive/13nw6dhKfW9c6QExco7P_TyPHItmDyB9u?usp=sharing`,
    docLink: 'integrations/ml-frameworks/xgboost/',
    importSection: (
      <p>
        Import comet_ml at the top of your code and define your Experiment
        Class.
      </p>
    )
  },
  LLM: {
    value: 'llm',
    label: 'Large Language Model',
    prefixIcon: LLMIcon,
    snippetFileName: 'llm_example.py',
    codeSnippet: LLM_EXAMPLE,
    colabLink:
      'https://colab.research.google.com/github/comet-ml/comet-llm/blob/main/examples/CometLLM_Prompts.ipynb',
    docLink: 'api-and-sdk/llm-sdk/overview/',
    installCode: 'pip install comet_llm',
    installationSection:
      'Install Comet’s LLM SDK using pip from the command line.',
    importSection:
      'Import comet_llm at the top of your code and import the logging function.',
    logSection:
      'If you are using chains or would like to log chat conversations, you can use the comet_llm SDK.'
  },
  PYTHON: {
    value: 'python',
    label: 'Any Python code',
    prefixIcon: PythonIcon,
    snippetFileName: `Pytorch_example.py`,
    codeSnippet: PYTORCH_CODE_SNIPPET,
    colabLink:
      'https://colab.research.google.com/github/comet-ml/comet-examples/blob/master/integrations/model-training/pytorch/notebooks/Comet_and_Pytorch.ipynb',
    docLink: 'integrations/ml-frameworks/pytorch/'
  }
};

export const CODE_FRAMEWORKS_LIST = Object.values(CODE_FRAMEWORKS);

export const getFrameworkCodeSnippet = (
  apiKey,
  projectName,
  activeWorkspaceName
) => {
  const envCode = addEnvImports();
  const llmEnvCode = addEnvImports('comet_llm');

  const frameworkCode = {
    [CODE_FRAMEWORKS.PYTORCH.value]: {
      IMPORT: `${envCode}
from comet_ml import Experiment
from comet_ml.integration.pytorch import log_model\n
experiment = Experiment(
  api_key="${apiKey}",
  project_name="${projectName}",
  workspace="${activeWorkspaceName}"
)`,
      LOGGING: `# Report multiple hyperparameters using a dictionary:\nhyper_params = {\n   "learning_rate": 0.5,\n   "steps": 100000,\n   "batch_size": 50,\n}\nexperiment.log_parameters(hyper_params)\n
# Initialize and train your model\n# model = TheModelClass()\n# train(model)\n
# Seamlessly log your Pytorch model
log_model(experiment, model=model, model_name="TheModel")\n`
    },
    [CODE_FRAMEWORKS.PYTORCH_LIGHTNING.value]: {
      IMPORT: `${envCode}
import comet_ml
from pytorch_lightning.loggers import CometLogger\n
# arguments made to CometLogger are passed on to the comet_ml.Experiment class\ncomet_logger = CometLogger()`,
      LOGGING: `# Add CometLogger to your Trainer\ntrainer = Trainer(logger=comet_logger)
\n# Report multiple hyperparameters using a dictionary:\nhyper_params = {\n    "learning_rate": 0.5,\n    "steps": 100000,\n    "batch_size": 50,\n}\ncomet_logger.log_parameters(hyper_params)
    `
    },
    [CODE_FRAMEWORKS.KERAS.value]: {
      IMPORT: `${envCode}
from comet_ml import Experiment
experiment = Experiment(
  api_key="${apiKey}",
  project_name="${projectName}",
  workspace="${activeWorkspaceName}"
)`,
      LOGGING: `# Report multiple hyperparameters using a dictionary:
hyper_params = {
  "learning_rate": 0.5,
  "steps": 100000,
  "batch_size": 50,
}
experiment.log_parameters(hyper_params)
    
# Or report single hyperparameters:
hidden_layer_size = 50
experiment.log_parameter("hidden_layer_size", hidden_layer_size)
    
# Long any time-series metrics:
train_accuracy = 3.14
experiment.log_metric("accuracy", train_accuracy, step=0)`
    },
    [CODE_FRAMEWORKS.SCIKIT_LEARN.value]: {
      IMPORT: `${envCode}
from comet_ml import Experiment
from comet_ml.integration.sklearn import log_model

experiment = Experiment(
  api_key="${apiKey}",
  project_name="${projectName}",
  workspace="${activeWorkspaceName}"
)`,
      LOGGING: `# Build your model and fit
# ...
# clf.fit(X_train_scaled, y_train)
    
experiment.log_parameters(params)
experiment.log_metrics(metrics)

# Seamlessly log your SKLearn model
log_model(experiment, model=clf, model_name="TheModel")`
    },
    [CODE_FRAMEWORKS.HUGGING_FACE.value]: {
      IMPORT: `$ export COMET_API_KEY="${apiKey}"`,
      LOGGING: `import comet_ml 

experiment = comet_ml.get_global_experiment()
experiment.log_metric("custom_metric" , 1.0)`
    },
    [CODE_FRAMEWORKS.XGBoost.value]: {
      IMPORT: `${envCode}
from comet_ml import Experiment
import xgboost as xgb
    
experiment = Experiment(
  api_key="${apiKey}",
  project_name="${projectName}",
  workspace="${activeWorkspaceName}"
)`,
      LOGGING: 'model = xgb.fit()'
    },
    [CODE_FRAMEWORKS.LLM.value]: {
      IMPORT: `${llmEnvCode}
import comet_llm

comet_llm.log_prompt(
    api_key = "${apiKey}",
    project = "llm-general",
    prompt = "<input prompt>",
    output = "<response>",
    tags = ["prompt", "gpt-3.5"]
)`,
      LOGGING: `${llmEnvCode}
import comet_llm
import time

# Create a chain
user_question = "Is Nando's open ?"
comet_llm.start_chain(
    project = "llm-general",
    api_key = "${apiKey}",
    inputs = {"final_prompt": user_question},
    tags = ["prompt-chain", "gpt-3.5"]
)

# Fetch context to check opening hours
with comet_llm.Span(
    category = "context-retrieval",
    inputs = {"action": "fetch-opening-hours"},
) as span:
    time.sleep(0.2)
    result = "7 days a week: 11am to 11pm"
    span.set_outputs(outputs = {"opening-hours": result})

# Format response to user query
with comet_llm.Span(
    category = "llm-call",
    inputs = {
        "user_question": user_question,
        "context": result,
    },
) as span:
    time.sleep(0.65)
    response = "Yes, Nando's is currently open"
    span.set_outputs(outputs = {"result": response})

# Finish chain
comet_llm.end_chain(outputs = {"output": response})`
    },
    [CODE_FRAMEWORKS.PYTHON.value]: {
      IMPORT: `${envCode}
from comet_ml import Experiment
          
experiment = Experiment(
  api_key="${apiKey}",
  project_name="${projectName}",
  workspace="${activeWorkspaceName}"
)`,
      LOGGING: `# Report multiple hyperparameters using a dictionary:\nhyper_params = {\n    "learning_rate": 0.5,\n    "steps": 100000,\n    "batch_size": 50,\n}\nexperiment.log_parameters(hyper_params)\n\n# Or report single hyperparameters:\nhidden_layer_size = 50\nexperiment.log_parameter("hidden_layer_size", hidden_layer_size)\n\n# Long any time-series metrics:\ntrain_accuracy = 3.14\nexperiment.log_metric("accuracy", train_accuracy, step=0)\n\n# Run your code and go to /`
    }
  };

  return frameworkCode;
};

export const CODE_SNIPPETS = {
  INSTALL: `pip install comet_ml`
};
