import React from 'react';

import { LLMSyntaxHighlighter } from './LLMSyntaxHighlighter';
import { SidebarTabProps } from '../types/SidebarTabProps';

export default function LLMMetadataTab({
  data,
  selectedChainNode
}: SidebarTabProps) {
  return (
    <LLMSyntaxHighlighter
      data={selectedChainNode?.metadata || data?.metadata}
    />
  );
}
