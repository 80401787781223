import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useProject from '@API/project/useProject';
import useProjectParams from '@API/project/useProjectParams';
import { SavedReport } from '@reports/types';
import { reportsRoute } from '@routes/utils/reports';
import api, { QueryConfig } from '@shared/api';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

type BaseGetReportParams = {
  isTemplate?: boolean;
  projectId: string;
  revisionId?: string | null;
  signal?: AbortSignal;
};

type GetReportParamsById = BaseGetReportParams & {
  projectName?: never;
  reportId: string;
  reportName?: never;
  workspace?: never;
};

type GetReportParamsByName = BaseGetReportParams & {
  projectName: string;
  reportId?: never;
  reportName: string;
  workspace: string;
};

type GetReportParams = GetReportParamsById | GetReportParamsByName;

type NameValidationResponse = {
  finalName: string;
  nameExist: boolean;
  originalName: string;
  validatedName: string;
};

const getReport = async ({
  isTemplate,
  projectId,
  projectName,
  reportId,
  reportName,
  revisionId,
  signal,
  workspace
}: GetReportParams) => {
  const { data: report } = await api.get<SavedReport>('reports/get', {
    params: {
      isTemplate,
      projectName,
      reportId,
      reportName,
      revisionId,
      workspace
    },
    signal
  });

  // Fixes old reports with discrepancies between
  // report.reportName and report.data.reportName
  const patchedReport = {
    ...report,
    data: {
      ...report.data,
      reportName: report.reportName
    }
  };

  if (!revisionId) {
    return patchedReport;
  }

  const {
    data: { finalName, nameExist, originalName: validatedName }
  } = await api.post<NameValidationResponse>('reports/name-validation', {
    projectId,
    reportName: report.data.displayName
  });

  if (nameExist) return patchedReport;

  // The API returns a different `displayName` and `reportName` than
  // the `data.displayName` and `data.reportName` if previous version
  // of the report had a different `reportName`
  return {
    ...report,
    data: {
      ...report.data,
      reportName: finalName
    },
    displayName: validatedName,
    reportName: finalName
  };
};

type BaseUseReportParams = {
  isTemplate?: boolean;
  revisionId?: string | null;
};

type BaseUseReportParamsById = BaseUseReportParams & {
  reportId: string;
  reportName?: never;
};

type BaseUseReportParamsByName = BaseUseReportParams & {
  reportId?: never;
  reportName: string;
};

type UseReportParams = BaseUseReportParamsById | BaseUseReportParamsByName;

export default function useReport(
  { isTemplate, reportId, reportName, revisionId }: UseReportParams,
  config: QueryConfig<SavedReport>
) {
  const { data: project } = useProject();
  const { workspace, projectName } = useProjectParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = project?.projectId;
  const configEnabled = config.enabled ?? true;
  const enabled = configEnabled && !!reportName && !!projectId;

  return useQuery(
    [
      'report',
      {
        isTemplate,
        projectId,
        projectName,
        reportId,
        reportName,
        revisionId,
        workspace
      }
    ],
    ({ signal }) => {
      if (!projectId) {
        throw new Error('Project ID is empty');
      }

      if (reportId) {
        return getReport({
          isTemplate,
          projectId,
          reportId,
          revisionId,
          signal
        });
      }

      if (!workspace || !projectName || !reportName) {
        throw new Error(
          'Workspace, project name and report name cannnot be empty'
        );
      }

      return getReport({
        isTemplate,
        projectId,
        projectName,
        reportName,
        revisionId,
        signal,
        workspace
      });
    },
    {
      ...config,
      enabled,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your report'
          )
        );

        if (!projectName || !workspace) {
          return;
        }

        const reportsURL = reportsRoute({
          projectName,
          workspaceName: workspace
        });

        history.push(reportsURL);
      }
    }
  );
}
