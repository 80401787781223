export const FORGOT_PASSWORD_STEPS = {
  FILL_EMAIL: 'FILL_EMAIL',
  FILL_PASSWORD: 'FILL_PASSWORD',
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  EMAIL_SENT: 'EMAIL_SENT'
};

// TEXT CONTENT - APPLICATION
export const HP_OPTIMIZATION_CODE = {
  code: `
  # Declare your hyperparameters search:
  config = {
      "algorithm": "bayes",
      "parameters": {
          "size": {"type": "integer", "min": 1, "max": 10},
          "lr": {"type": "float", "min": 0.1, "max": 1.0},
      },
      "spec": {
          "metric": "loss",
          "objective": "minimize",
      },
  }
  optimizer = Optimizer(config, api_key="API_KEY")

  for experiment in optimizer.get_experiments(project_name="optimizer-007"):
    
      # Test the model
      loss = fit(experiment.get_parameter("size"),
                 experiment.get_parameter("lr"))
    
      # Report the loss, if not auto-logged:
      experiment.log_metric("loss", loss)
  `
};

export const PAYMENT_MESSAGE_UPGRADE_COMPLETE =
  'Thank you for upgrading! Your workspace plan has been updated.';

export const TOOLTIP_TRIAL_EXPIRED =
  'Your trial has expired. Please add a payment method for your plan.';

export const TOOLTIP_TRIAL_REMAINING =
  'Days remaining in your payment plan trial.';

export const ERROR_PAGE_MESSAGES = {
  404: '404 - Page Not Found.',
  DEFAULT: 'Something went wrong.'
};
