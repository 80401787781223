import React, { useCallback } from 'react';

import { SearchInput } from '@ds';
import { PanelGlobalConfig } from '@experiment-management-shared/types';
import XAxisButton from './XAxisButton';
import SmoothingButton from './SmoothiingButton';
import AdditionalSettingsButton from './AdditionalSettingsButton';

import styles from './DashboardChartsOperationsToolbar.module.scss';
import { useSyncSearchWithURL } from '@shared/hooks';

type DashboardChartsOperationsToolbarProps = {
  hasLockedPanels: boolean;
  onChange: (changes: unknown) => void;
  searchText: string;
  setSearchText: (value: string) => void;
  disableAllButtons: boolean;
  panelGlobalConfig: PanelGlobalConfig;
};
const DashboardChartsOperationsToolbar = ({
  hasLockedPanels,
  onChange,
  searchText,
  setSearchText,
  disableAllButtons,
  panelGlobalConfig
}: DashboardChartsOperationsToolbarProps) => {
  const { searchValue, onSearchChange } = useSyncSearchWithURL({
    fieldName: 'panelSearch',
    defaultValue: searchText,
    onSearchUpdated: setSearchText
  });

  const handleSearchChange = useCallback(event => {
    onSearchChange(event.target.value);
  }, []);

  const onClearIconClick = () => {
    setSearchText('');
  };

  const renderSearchBox = () => {
    return (
      <div className={styles.searchContainer}>
        <SearchInput
          disabled={disableAllButtons}
          size="small"
          placeholder="Search panel (regex)"
          value={searchValue}
          onChange={handleSearchChange}
          onClearIconClick={onClearIconClick}
          data-test="dashboard-charts-search"
        />
      </div>
    );
  };

  const renderGlobalConfigButtons = () => {
    return (
      <div className={styles.globalConfigContainer}>
        <XAxisButton
          disabled={disableAllButtons}
          panelGlobalConfig={panelGlobalConfig}
          onChange={onChange}
          showLockedPanelsWarning={hasLockedPanels}
        ></XAxisButton>
        <SmoothingButton
          disabled={disableAllButtons}
          panelGlobalConfig={panelGlobalConfig}
          onChange={onChange}
          showLockedPanelsWarning={hasLockedPanels}
        />
        <AdditionalSettingsButton
          disabled={disableAllButtons}
          panelGlobalConfig={panelGlobalConfig}
          onChange={onChange}
          showLockedPanelsWarning={hasLockedPanels}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderSearchBox()}
      {renderGlobalConfigButtons()}
    </div>
  );
};

export default DashboardChartsOperationsToolbar;
