import { SortableElement } from 'react-sortable-hoc';
import { components } from 'react-select';
import React from 'react';

export default SortableElement(props => {
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const innerProps = { ...props.innerProps, onMouseDown };

  return (
    <components.MultiValue
      className="sortable-select-multi-value sort-multi-value"
      {...props}
      innerProps={innerProps}
    />
  );
});
