import { MENU_VALUES } from '@API/workspace';
import useInviteWorkspaceUsersMutation from '@API/workspace/useInviteWorkspaceUsersMutation';
import useUpdateDefaultWorkspaceMutation from '@API/workspace/useUpdateDefaultWorkspaceMutation';
import { Input } from '@DesignSystem/controllers';
import { SearchIcon } from '@Icons-outdated';
import first from 'lodash/first';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { AutoSizer, List, WindowScroller } from 'react-virtualized';
import useUserPermission from '@shared/api/useUserPermission';
import useOrganizationWorkspaces from '@shared/api/useOrganizationWorkspaces';
import { MANAGEMENT_PERMISSIONS } from '@shared/constants/permissions';

import workspaceActions from '@/actions/workspaceActions';
import { useDeleteWorkspaceMutation } from '@account-settings/api';
import { useCurrentOrganization, useCurrentPaymentPlan } from '@shared/hooks';

import AdminSettingsWorkspaceCard from './AdminSettingsWorkspaceCard';
import SmallLoader from '@shared/components/SmallLoader';
import { UpgradeToInvite } from '../../UpgradeToInvite';
import { CreateWorkspace } from '../../CreateWorkspace';
import { DeleteWorkspaceModal } from '../../DeleteWorkspaceModal';
import { InviteMembersModal } from '../../InviteMembersModal';

import './workspaces.scss';

const Workspaces = () => {
  const { organizationId } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: workspaces = [], isLoading } = useOrganizationWorkspaces(
    organizationId
  );

  const [inviteToWorkspace, setInviteToWorkspace] = useState(null);
  const [isFromTrial, setIsFromTrial] = useState(() => {
    const { fromTrial = false } = queryString.parse(search);

    return !!fromTrial;
  });
  const [workspaceToRemove, setWorkspaceToRemove] = useState(null);
  const [isUpgradeToInviteOpen, setIsUpgradeToInviteOpen] = useState(false);

  const { getPermissionStatus } = useUserPermission();

  const updateDefaultWorkspaceMutation = useUpdateDefaultWorkspaceMutation();
  const deleteWorkspaceMutation = useDeleteWorkspaceMutation(organizationId);

  const inviteMembersMutation = useInviteWorkspaceUsersMutation({
    workspaceId: inviteToWorkspace?.id,
    workspaceName: inviteToWorkspace?.name
  });

  const currentOrganization = useCurrentOrganization();
  const {
    isPossibleToInviteUsers: isPossibleToInviteUsersByPaymentPlan
  } = useCurrentPaymentPlan();

  const filteredWorkspaces = useMemo(() => {
    return (workspaces || []).filter(ws => {
      return (
        !searchValue ||
        ws.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  }, [workspaces, searchValue]);

  useEffect(() => {
    if (isFromTrial && workspaces?.length) {
      setInviteToWorkspace(first(workspaces));
    }
  }, [isFromTrial, workspaces]);

  const handleClickMenu = workspace => option => {
    if (option.value === MENU_VALUES.deleteWorkspace) {
      return setWorkspaceToRemove(workspace);
    }

    if (option.value === MENU_VALUES.default) {
      return updateDefaultWorkspaceMutation.mutate(workspace.id);
    }

    if (option.value === MENU_VALUES.manageUsers) {
      dispatch(
        workspaceActions.setManageWorkspaceModalState({
          open: true,
          workspaceId: workspace?.id,
          workspaceName: workspace?.name
        })
      );
    }
  };

  const closeUpgradeToInviteModal = () => setIsUpgradeToInviteOpen(false);

  const handleClickInvite = workspace => () => {
    if (!isPossibleToInviteUsersByPaymentPlan) {
      if (currentOrganization?.isAdmin) {
        setIsUpgradeToInviteOpen(true);
      }
    } else {
      setInviteToWorkspace(workspace);
    }
  };

  const closeInvitationModal = useCallback(() => {
    setInviteToWorkspace(null);
    setIsFromTrial(false);
    history.replace(pathname);
  }, [history]);
  const handleInvitationConfirm = async members => {
    await inviteMembersMutation.mutateAsync(members);
    closeInvitationModal();
  };

  const renderCard = ({ index, style }) => {
    const workspace = filteredWorkspaces[index];

    return (
      <div style={style} key={workspace.id}>
        <AdminSettingsWorkspaceCard
          createdAt={workspace.createdAt}
          createdBy={workspace.createdBy}
          isAdmin={currentOrganization?.isAdmin}
          isDefault={workspace.isDefault}
          key={workspace.id}
          onClickExtraMenu={handleClickMenu(workspace)}
          onClickInvite={handleClickInvite(workspace)}
          projectsCount={workspace.projectsCount}
          workspaceId={workspace.id}
          workspaceName={workspace.name}
          workspaceStorageUsage={workspace.storageUsage}
          disabledInviteButton={
            !getPermissionStatus({
              workspaceName: workspace?.name,
              permissionKey:
                MANAGEMENT_PERMISSIONS.INVITE_USERS_FROM_ORGANIZATION
            })
          }
        />
      </div>
    );
  };
  const closeDeleteModal = () => setWorkspaceToRemove(null);
  const handleDeleteConfirm = () => {
    deleteWorkspaceMutation.mutate(workspaceToRemove.id);
    setWorkspaceToRemove(null);
  };

  const list = React.useRef(null);
  const onScroll = React.useCallback(({ scrollTop }) => {
    list.current?.scrollTo(scrollTop);
  }, []);

  return (
    <>
      <InviteMembersModal
        open={Boolean(inviteToWorkspace)}
        onClose={closeInvitationModal}
        onPrimaryButtonClick={handleInvitationConfirm}
        workspaceId={inviteToWorkspace?.id}
        isFromTrial={isFromTrial}
        workspaceName={inviteToWorkspace?.name}
        inviteMembersMutation={inviteMembersMutation}
      />

      <DeleteWorkspaceModal
        open={Boolean(workspaceToRemove)}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteConfirm}
        workspaceName={workspaceToRemove?.name || ''}
      />

      <UpgradeToInvite
        open={Boolean(isUpgradeToInviteOpen)}
        onClose={closeUpgradeToInviteModal}
      />

      <div className="workspace-tab">
        <div className="page-title">Workspaces</div>

        {isLoading ? (
          <SmallLoader
            primaryMessage="Loading..."
            secondaryMessage="Loading workspaces"
          />
        ) : (
          <>
            <div className="workspaces-controller-header">
              <Input
                InlinePrefixIcon={SearchIcon}
                placeholder="Search"
                onChange={setSearchValue}
                value={searchValue}
              />

              <CreateWorkspace organizationId={organizationId} />
            </div>

            <AutoSizer disableHeight>
              {({ width }) => (
                <WindowScroller onScroll={onScroll}>
                  {({ height, isScrolling, onChildScroll, scrollTop }) => (
                    <List
                      autoHeight
                      height={height}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowCount={filteredWorkspaces.length}
                      rowHeight={136}
                      width={width}
                      style={{ height: '100% !important' }}
                      rowRenderer={renderCard}
                    />
                  )}
                </WindowScroller>
              )}
            </AutoSizer>
          </>
        )}
      </div>
    </>
  );
};

export default Workspaces;
