import React, { ReactNode, forwardRef, ForwardedRef } from 'react';
import {
  ButtonSize,
  HTMLButtonWithoutSizeType,
  IconButtonType
} from '@design-system/types';
import cx from 'classnames';

import classes from './IconButton.module.scss';

const DEFAULT_ICON_BUTTON_TYPE: IconButtonType = 'primary';
const DEFAULT_ICON_BUTTON_SIZE: ButtonSize = 'medium';

export interface IconButtonProps extends HTMLButtonWithoutSizeType {
  Icon: ReactNode;
  type?: IconButtonType;
  size?: ButtonSize;
  active?: boolean;
}

const IconButton = (
  {
    Icon,
    type = DEFAULT_ICON_BUTTON_TYPE,
    size = DEFAULT_ICON_BUTTON_SIZE,
    active = false,
    ...buttonProps
  }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => (
  <button
    {...buttonProps}
    ref={ref}
    className={cx(
      buttonProps.className,
      classes.iconButtonContainer,
      classes[type],
      classes[size],
      {
        [classes.active]: active
      }
    )}
  >
    <div className={cx(classes.iconContainer)}>{Icon}</div>
  </button>
);

const ForwardedIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  IconButton
);
ForwardedIconButton.displayName = 'IconButton';
export default ForwardedIconButton;
