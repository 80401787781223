import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import styles from './StringLinkSamples.module.scss';

const LinkSamples = ({ samples }) => {
  const list = samples.map(({ index, sample }) => {
    const key = `${sample}-${index}`;

    return (
      <Tooltip
        key={key}
        mouseLeaveDelay={0}
        overlay={
          <div className="confusion-matrix-chart-tooltip-container">
            <div className="confusion-matrix-chart-tooltip-wrappable-label">
              {sample}
            </div>
            <div className="confusion-matrix-chart-tooltip-divider" />
            <div className="confusion-matrix-chart-tooltip-text">
              Click to open in new tab
            </div>
          </div>
        }
        overlayClassName="confusion-matrix-chart-tooltip"
        placement="top"
      >
        <div className={styles.sampleLink}>
          <a href={sample} target="_blank" rel="noopener noreferrer">
            #{index}: {sample}
          </a>
        </div>
      </Tooltip>
    );
  });

  return <div className={styles.stringSamplesList}>{list}</div>;
};

LinkSamples.propTypes = {
  samples: PropTypes.array.isRequired
};

export default LinkSamples;
