// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperimentNameCell-module__container--N3_tE{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;height:30px;padding:0 4px 10px;background-color:var(--white);font-weight:500;cursor:default;font-size:12px;line-height:18px;color:var(--gray-5);position:sticky;top:0;z-index:100}.ExperimentNameCell-module__container--N3_tE a{color:var(--gray-5);font-weight:500}.ExperimentNameCell-module__container--N3_tE a:visited{color:var(--gray-5)}.ExperimentNameCell-module__container--N3_tE a:hover{color:var(--gray-6)}.ExperimentNameCell-module__container--N3_tE a:active{color:var(--gray-7)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ExperimentNameCell-module__container--N3_tE`
};
module.exports = ___CSS_LOADER_EXPORT___;
