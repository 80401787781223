import { KIND_TYPE, KIND_UNIQ_SYMBOL } from '@API/helpers/v2_helpers';
import {
  APP_LOCATION,
  IS_ON_PREMISE,
  SHOULD_LOAD_ANALYTICS
} from '../../lib/appConstants';
import { getExperimentKeys, getProjectName, getWorkspaceName } from './url';

import {
  dashboardEvents,
  experimentEvents
} from '../../constants/trackingEventTypes';

const getExtraParameters = () => {
  const teamName = getWorkspaceName();

  if (!teamName) return {};

  const projectName = getProjectName();

  if (!projectName) return { teamName, workspace_name: teamName };

  const experimentKeys = getExperimentKeys();
  return {
    projectName,
    project_name: projectName,
    teamName,
    workspace_name: teamName,
    ...experimentKeys
  };
};

export const trackEvent = (name, param, callback = () => {}) => {
  if (IS_ON_PREMISE) return;

  try {
    const extraParameters = getExtraParameters();
    const parameters = {
      ...extraParameters,
      ...param
    };

    name += '_FE';
    if (SHOULD_LOAD_ANALYTICS && window.analytics) {
      window.analytics.track(name, parameters);
      callback(name, parameters);
    } else {
      const analyticsResponse = JSON.stringify({
        name,
        parameters
      });
      console.info(analyticsResponse);
      return;
    }
  } catch (error) {
    console.error('track() error: ', error);
  }
};

export const timer = {
  start: () => {
    const startTime = new Date().getTime();
    return startTime;
  },
  stop: startTime => {
    const outTime = new Date().getTime();
    return (outTime - startTime) / 1000;
  }
};

export const trackCompareViewEvent = ({
  canEdit,
  experimentKeys,
  isOwner,
  isPublic,
  isShared,
  isUserLoggedIn,
  projectId
}) => {
  trackEvent(experimentEvents.EXPERIMENT_DETAIL_VIEWED, {
    canEdit,
    experimentKeys,
    isOwner,
    isPublic,
    isShared,
    isUserLoggedIn,
    projectId,
    usedWhere: APP_LOCATION.MULTIPLE_EXPERIMENTS_COMPARE
  });
};

export const trackListViewExperiment = (props, callback = () => {}) => {
  const {
    isUserLoggedIn,
    isOwner,
    isShared,
    isPublic,
    projectId,
    canEdit
  } = props;
  const experimentParams = {
    usedWhere: APP_LOCATION.DASHBOARD_PAGE,
    isOwner,
    isShared,
    isPublic,
    canEdit,
    projectId,
    isUserLoggedIn
  };

  trackEvent(experimentEvents.EXPERIMENT_LIST_VIEWED, experimentParams);

  callback();
};

export const trackMetricKindSelection = (
  dropdownName = '',
  { action, option } = {}
) => {
  // https://react-select.com/advanced#action-meta
  // 'select-option' is an action for the react-select change
  if (
    dropdownName &&
    action === 'select-option' &&
    [KIND_TYPE.MAX, KIND_TYPE.MIN].includes(option.kind)
  ) {
    const [columnName, kind] = option.value.split(KIND_UNIQ_SYMBOL);

    trackEvent(dashboardEvents.SELECT_METRIC_KIND_OPTION, {
      dropdownName,
      columnName,
      kind
    });
  }
};
