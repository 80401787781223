import React from 'react';
import { Tooltip } from '@ds';

type GraphicsTileTooltipProps = {
  children: React.ReactNode;
  tooltipText: string;
  isSelectionDisabled: boolean;
};
const GraphicsTileTooltip: React.FC<GraphicsTileTooltipProps> = ({
  tooltipText,
  isSelectionDisabled,
  children
}) => {
  if (!isSelectionDisabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip content={tooltipText} placement="top">
      <>{children}</>
    </Tooltip>
  );
};

export default GraphicsTileTooltip;
