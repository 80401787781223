// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtherSingleTableCell-module__othersTableKeyCell--_jtma{display:flex;justify-content:space-between;align-items:center;gap:16px}.OtherSingleTableCell-module__othersTableEditCellContainer--mMwAQ{padding-top:13px;padding-bottom:14px}.OtherSingleTableCell-module__othersTableActionCell--iknMs{display:flex;justify-content:flex-end}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"othersTableKeyCell": `OtherSingleTableCell-module__othersTableKeyCell--_jtma`,
	"othersTableEditCellContainer": `OtherSingleTableCell-module__othersTableEditCellContainer--mMwAQ`,
	"othersTableActionCell": `OtherSingleTableCell-module__othersTableActionCell--iknMs`
};
module.exports = ___CSS_LOADER_EXPORT___;
