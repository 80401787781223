import { Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { filter, find, isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MetadataIcon } from '@Icons-outdated';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { Slider } from '@design-system-outdated/components';
import { IconButton, TextButton, Select } from '@ds';
import { StyledTooltip } from '@DesignSystem/data-display';
import useColumns from '@API/project/useColumns';
import dashboardChartsActions from '@/actions/dashboardChartsActions';

import { BUILT_IN_CHART_TYPES } from '@/lib/appConstants';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import useChartTabYValues from '@experiment-management-shared/hooks/useChartTabYValues';
import classNames from './ParallelChartDataTab.module.scss';
import { DSRemoveIcon } from '@ds-icons';

const chartType = BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates'];
const emptySelectValue = { value: 'null', label: null, source: null };

function ParallelChartDataTab() {
  const dispatch = useDispatch();

  const {
    data: [tableColumns, extraCols]
  } = useColumns({
    extraCols: true,
    divideExtraCols: true
  });

  const parallelChartForm = useSelector(state =>
    getChartFormByType(state, { chartType })
  );

  const handleSelectDecimalPrecision = value => {
    const formattedValue = `.${value}f`;

    const updatedForm = {
      ...parallelChartForm,
      selectedDecimalPrecision: formattedValue
    };

    dispatch(dashboardChartsActions.updateChartForm(chartType, updatedForm));
  };

  const yValues = useChartTabYValues({ type: chartType });

  const getYAxisMultiSelectedOptions = () => {
    const { metrics, params, selectedTargetVariable } = parallelChartForm;

    const selectedOptionNames = [...metrics, ...params];

    const withoutTargetVariable = selectedOptionNames.filter(
      name => name !== selectedTargetVariable
    );

    const sortedWithTargetVariable = selectedTargetVariable
      ? [selectedTargetVariable, ...withoutTargetVariable]
      : withoutTargetVariable;

    return sortedWithTargetVariable
      .map(optionName => {
        const all = filter([...tableColumns, ...extraCols], {
          name: optionName
        });
        if (all.length > 1) {
          return find(all, opt => opt.source !== 'log_other');
        }
        return find([...tableColumns, ...extraCols], { name: optionName });
      })
      .filter(column => !isUndefined(column))
      .map(column => formatValueForSelectOption(column.name, column.source));
  };

  const [yAxises, setYAxises] = useState(getYAxisMultiSelectedOptions());

  const filterSelectedOptionsBySource = (selectedOptions, source) => {
    return selectedOptions
      .filter(option => {
        return option.source === source;
      })
      .map(option => option.value);
  };

  const handleMultiSelect = selectedOptions => {
    const updatedForm = {
      ...parallelChartForm,
      metrics: filterSelectedOptionsBySource(selectedOptions, 'metrics'),
      params: filterSelectedOptionsBySource(selectedOptions, 'params')
    };

    dispatch(dashboardChartsActions.updateChartForm(chartType, updatedForm));
  };

  useEffect(() => {
    handleMultiSelect(yAxises);
  }, [yAxises]);

  const handleSelectTargetVariable = (
    selectedOption,
    fullSelectedOptionValue
  ) => {
    let updatedTargetVariableSource = {};
    if (selectedOption) {
      updatedTargetVariableSource = mergeTargetVariableToSource(
        fullSelectedOptionValue
      );
    } else {
      updatedTargetVariableSource = removeTargetVariableFromMetricsAndParams();
    }

    const updatedForm = {
      ...parallelChartForm,
      selectedTargetVariable: selectedOption,
      ...updatedTargetVariableSource
    };

    if (isEmpty(yAxises)) {
      setYAxises([fullSelectedOptionValue]);
    } else {
      setYAxises(prev => {
        return prev.map((val, i) =>
          i === 0 ? fullSelectedOptionValue || val : val
        );
      });
    }

    dispatch(dashboardChartsActions.updateChartForm(chartType, updatedForm));
  };

  const removeTargetVariableFromMetricsAndParams = newTargetVariable => {
    const { metrics, params, selectedTargetVariable } = parallelChartForm;
    const oldTargetVariable = selectedTargetVariable;

    return {
      metrics: metrics.filter(
        name => name !== oldTargetVariable && name !== newTargetVariable
      ),
      params: params.filter(
        name => name !== oldTargetVariable && name !== newTargetVariable
      )
    };
  };

  const mergeTargetVariableToSource = targetVariableOption => {
    const { value, source } = targetVariableOption;

    const formWithTargetVariableRemoved = removeTargetVariableFromMetricsAndParams(
      value
    );

    return {
      ...formWithTargetVariableRemoved,
      [source]: [...formWithTargetVariableRemoved[source], value]
    };
  };

  const renderTargetVariableSelect = () => {
    const formKey = 'selectedTargetVariable';
    const formValue = parallelChartForm[formKey];

    return (
      <div className="full-width-parallel-input" style={{ marginBottom: 40 }}>
        <InputLabel className="modal-input-label ">
          <span className="target-variable-label">Target Variable </span>
          <Tooltip
            title="The target variable choice impacts the color of the lines. We
                recommend setting it to your main metric (i.e accuracy, f1).
                Using the colors, viewers can differentiate better performing
                experiments without tracing the line to a certain axis."
            placement="top-start"
          >
            <div style={{ display: 'inline-block' }}>
              <MetadataIcon />
            </div>
          </Tooltip>
        </InputLabel>
        <Select
          maxWidth={400}
          truncateMiddle
          value={formValue}
          onValueChange={handleSelectTargetVariable}
          options={yValues}
          maxHeight={280}
          isSearchable
          menuShouldBlockScroll
          isRegexSearchEnabled
        />
      </div>
    );
  };

  const renderDecimalPrecisionSelect = () => {
    const {
      selectedDecimalPrecision,
      selectedTargetVariable
    } = parallelChartForm;

    return (
      <div className="modal-input-group">
        <InputLabel className="modal-input-label">Decimal Precision</InputLabel>
        <Slider
          value={selectedDecimalPrecision.replace(/\D/g, '')}
          onChange={handleSelectDecimalPrecision}
          disabled={!selectedTargetVariable}
          variant="type-2"
          min={0}
          max={7}
        />
      </div>
    );
  };

  const renderAxisSelect = ({ onChange, value, index }) => {
    return (
      <div
        key={`${value}_${index}`}
        style={{ marginBottom: 12, marginRight: -23 }}
      >
        <Box display="flex" alignItems="center">
          <div style={{ marginRight: 6 }} className="full-width-parallel-input">
            <Select
              value={value}
              placeholder="Select.."
              maxWidth={400}
              truncateMiddle
              onValueChange={onChange}
              options={yValues}
              maxHeight={280}
              isClearable={false}
              isSearchable
              menuShouldBlockScroll
              isRegexSearchEnabled
            />
          </div>
          <StyledTooltip title="Remove field" placement="top" arrow>
            <span>
              <IconButton
                type="secondary"
                Icon={<DSRemoveIcon />}
                className={classNames.parallelChartRemoveIcon}
                onClick={() => {
                  const filtered = yAxises.filter((e, i) => i !== index);
                  setYAxises(filtered);
                }}
              />
            </span>
          </StyledTooltip>
        </Box>
      </div>
    );
  };

  const handleSelectChange = index => (newValue, newFullValue) => {
    const updatedAxis = yAxises.map((val, i) => {
      if (i === index) {
        return newFullValue;
      }
      return val;
    });
    setYAxises(updatedAxis);
  };

  const renderYAxisSelect = () => (
    <>
      <InputLabel className="modal-input-label">Y-Axis</InputLabel>
      <div className="full-width-parallel-input" style={{ marginBottom: 12 }}>
        <Select
          isDisabled
          placeholder="Select target variable..."
          maxWidth={400}
          truncateMiddle
          options={yValues}
          value={parallelChartForm.selectedTargetVariable}
          maxHeight={280}
          hideSecondary
          menuShouldBlockScroll
        />
      </div>
      {yAxises.map(({ value }, index) => {
        if (index === 0) return null;
        return renderAxisSelect({
          onChange: handleSelectChange(index),
          index,
          value
        });
      })}
      <div className="mb-40">
        <TextButton
          onClick={() => {
            setYAxises(prev => [...prev, emptySelectValue]);
          }}
          style={{ marginTop: 12 }}
          withoutPadding
        >
          + Add field
        </TextButton>
      </div>
    </>
  );

  return (
    <div className="parallel-chart-data-tab">
      {renderTargetVariableSelect()}
      {renderYAxisSelect()}
      {renderDecimalPrecisionSelect()}
    </div>
  );
}

export default ParallelChartDataTab;
