// Copied from ./displayHelpers.js to avoid dependency violations error
export const getTextWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

const truncateMiddleOfStringToFitWidth = (
  string: string,
  maxWidth?: number,
  symbol = '...'
) => {
  if (!string || !maxWidth) return string;

  const textWidth = getTextWidth(string);

  const widthOfTextToTruncate = textWidth - maxWidth;
  const avgWidthOfCharacter = textWidth / string.length;
  const numCharactersToTruncate = Math.ceil(
    widthOfTextToTruncate / avgWidthOfCharacter
  );

  if (textWidth > maxWidth) {
    const halfLength = string.length / 2;

    const start = string.substring(0, halfLength - numCharactersToTruncate / 2);
    const end = string.substring(halfLength + numCharactersToTruncate / 2);

    return `${start}${symbol}${end}`;
  }

  return string;
};

type UseTruncatedLabelOpts = {
  enabled: boolean;
  text: string;
  maxWidth?: number;
};
const useMiddleTruncatedLabel = ({
  enabled,
  text,
  maxWidth
}: UseTruncatedLabelOpts) => {
  const isEnabled = enabled && maxWidth;

  if (!isEnabled) {
    return {
      isTextTruncated: false,
      truncatedValue: text
    };
  }

  const truncatedValue = truncateMiddleOfStringToFitWidth(text, maxWidth);
  const isTextTruncated = truncatedValue !== text;

  return {
    isTextTruncated,
    truncatedValue
  };
};

export default useMiddleTruncatedLabel;
