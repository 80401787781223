import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, matchPath } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import cx from 'classnames';
import useTapfiliate from '@shared/utils/useTapfiliate';

import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import ManageUsersModal from '@account-settings/components/ManageUsersModal';
import { AcademicSignup, SignInSSOPage } from '@auth';
import { getSettingsRoute, SETTING_SECTIONS } from '@shared/utils/url';
import {
  DetectedExperiment,
  GetStarted,
  HelpButton,
  OnboardingBiEvents,
  PostSignUpQuestions,
  useIsGetStartedAvailable
} from '@features/onboarding';

import About from '@platform/components/About';
import Alerts from '@platform/components/Alerts';
import Analytics from '@platform/components/Analytics';
import ClaimInvite from '@auth/components/ClaimInvite/ClaimInvite';
import EmbeddedPanelPage from '@experiment-management-shared/components/EmbeddedPanelPage';
import ErrorPage from '@platform/components/ErrorPage';
import OAuthCallbackPage from '@auth/components/OAuthCallbackPage';
import ScrollToTop from '@platform/components/ScrollToTop';
import SmallLoader from '@shared/components/SmallLoader';
import SentryBoundary from '@platform/components/SentryBoundary';
import LoginPage from '@auth/components/LoginPage';
import SignupPage from '@auth/components/SignupPage';
import ResetPasswordPage from '@auth/components/ResetPasswordPage';
import PricingPage from '@platform/components/PricingPage/PricingPage';

import GuestOnlyRoute from '@routes/components/GuestOnlyRoute';
import PrivateRoute from '@routes/components/PrivateRoute';
import WorkspaceRoutes from '@routes/components/WorkspaceRoutes';
import useBeamer from '@routes/hooks/useBeamer';
import useSegment from '@routes/hooks/useSegment';
import useSentry from '@routes/hooks/useSentry';
import useClaimInvite from '@routes/hooks/useClaimInvite';
import useGoogleAnalytics from '@routes/hooks/useGoogleAnalytics';
import useHubspot from '@routes/hooks/useHubspot';
import useLoginToken from '@routes/hooks/useLoginToken';
import useQuickStartFramework from '@routes/hooks/useQuickStartFramework';
import useShareableURL from '@routes/hooks/useShareableURL';

import {
  SHOULD_SHOW_RESET_PASSWORD,
  SHOULD_SHOW_SIGN_UP_BUTTON,
  LOGIN_REDIRECT_URL_GUEST_USER,
  BASE_API_URL,
  DISABLE_PUBLIC_ACCESS
} from './constants/configConstants';
import { APP_PATHS } from './constants/generalConstants';
import { URLS } from './constants/urlConstants';

import { WORKSPACE_URL_HASHES } from './constants/workspaceConstants';
import {
  getAPIKey,
  getIsUserLoggedIn,
  getIsUserLoggedInWithGitHub,
  getUsername
} from './reducers/userReducer';
import HeaderNavBar from '@platform/components/HeaderNavBar';

import {
  useActiveWorkspaceName,
  useAllUserWorkspaces,
  useCurrentOrganization,
  useDefaultWorkspace,
  useInitIsFirstSession,
  useIsFirstSession
} from '@shared/hooks';

import workspaceActions from './actions/workspaceActions';

import organizationsActions from './actions/organizationsActions';
import { useRedirectURL } from '@auth/hooks';
import NoAccessToResource from '@shared/components/NoAccessToResource';
import { AnonymousModeBanner } from '@experiment-details/components/AnonymousMode';
import useClaimAnonymousExperimentMutation from '@experiment-management-shared/api/useClaimAnonymousExperimentMutation';
import { getAnonymousURLQueries } from '@experiment-details/utils/anonymous';
import {
  AccountSettings,
  AdminSettings,
  OrganizationList,
  TrialIsOverModal
} from '@account-settings/components';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';

const FULL_SCREEN_ROUTES = [
  '/claim',
  '/embedded-panel',
  '/login',
  '/raw/',
  '/reset-password',
  '/signup',
  `/${SELECT_PANEL_PATH}/`
];

const HIDE_HEADER_ROUTES = [...FULL_SCREEN_ROUTES];

const Routes = ({ history, isUserLoggedIn, loading, location, username }) => {
  const { pathname } = location;
  const dispatch = useDispatch();
  const defaultWorkspace = useDefaultWorkspace();
  const defaultWorkspaceName = defaultWorkspace?.name;
  const { data: allWorkspaces } = useAllUserWorkspaces();
  const isUserLoggedInWithGithub = useSelector(getIsUserLoggedInWithGitHub);
  const isGetStartedAvaialable = useIsGetStartedAvailable();
  const [openedSuccessClaimedModal, setOpenedSuccessClaimedModal] = useState(
    false
  );
  const [isClaimed, setIsClaimed] = useState(false);

  const {
    claimResult,
    claimStatus,
    loginToken,
    errorMessage,
    resetToken: forgotPasswordToken,
    ref: affiliateRef,
    workspaceName
  } = queryString.parse(window.location.search, { parseBooleans: true });
  const claimAnonymousExperimentMutation = useClaimAnonymousExperimentMutation();

  useSentry(username);
  useSegment(username);
  useClaimInvite(claimResult, claimStatus);
  useGoogleAnalytics();
  useHubspot(pathname);
  useLoginToken();
  useQuickStartFramework();
  useShareableURL();
  useBeamer(username);
  useTapfiliate(isUserLoggedIn, affiliateRef);
  useInitIsFirstSession(workspaceName);
  useRedirectURL();

  const isFirstSession = useIsFirstSession();
  const currentActiveWorkspaceName = useActiveWorkspaceName();
  const currentOrganization = useCurrentOrganization();

  useEffect(() => {
    if (!currentActiveWorkspaceName && defaultWorkspaceName) {
      dispatch(workspaceActions.setActiveWorkspaceName(defaultWorkspaceName));

      if (defaultWorkspace?.organizationId) {
        dispatch(
          organizationsActions.setActiveOrganization(
            defaultWorkspace?.organizationId
          )
        );
      }
    }
  }, [
    currentActiveWorkspaceName,
    dispatch,
    defaultWorkspaceName,
    defaultWorkspace?.organizationId
  ]);

  // it's for the cases where users get redirected
  useEffect(() => {
    if (
      currentActiveWorkspaceName &&
      !currentOrganization &&
      allWorkspaces?.length
    ) {
      const organizationIdToMakeActive = allWorkspaces.find(
        workspace => workspace?.name === useActiveWorkspaceName
      )?.organizationId;

      if (organizationIdToMakeActive) {
        dispatch(
          organizationsActions.setActiveOrganization(organizationIdToMakeActive)
        );
      }
    }
  }, [
    currentActiveWorkspaceName,
    currentOrganization,
    allWorkspaces,
    dispatch
  ]);

  const pageWrapClass = useMemo(() => {
    const isPublicPath = matchPath(pathname, {
      path: [
        ...APP_PATHS.PUBLIC_PATHS,
        '/embedded-panel',
        // ALRE REMOVE ME
        '/:workspace/quickstart'
      ],
      exact: true,
      strict: false
    });

    if (isPublicPath) {
      return 'page-public-wrap';
    }

    return 'page-app-wrap';
  }, [pathname]);

  const showAlerts = !pathname.includes('/raw/');
  const isAlertsAddPage = pathname.includes('/alerts/add');
  const showHeader = !HIDE_HEADER_ROUTES.some(path => pathname.includes(path));
  const workspacePath = `/${defaultWorkspaceName}${WORKSPACE_URL_HASHES.PROJECTS}`;

  if (loading || loginToken) {
    return <SmallLoader />;
  }

  if (
    pathname !== APP_PATHS.ROOT_PATH &&
    !isUserLoggedIn &&
    DISABLE_PUBLIC_ACCESS
  ) {
    window.location.href = APP_PATHS.ROOT_PATH;
    return null;
  }

  if (!isUserLoggedIn && !errorMessage && LOGIN_REDIRECT_URL_GUEST_USER) {
    window.location.href = `${BASE_API_URL}${LOGIN_REDIRECT_URL_GUEST_USER}`;
    return null;
  }

  const renderNavBar = () => {
    const isClosedPath = pathname === '/closed';
    const isHomepage = pathname === '/' || isClosedPath;
    const isUserInTrialMode = pathname.indexOf('/view') === 0;
    const signedUpUserWithGithub = isFirstSession && isUserLoggedInWithGithub;
    const shouldRedirect = (isUserLoggedIn && isUserInTrialMode) || isHomepage;

    if (shouldRedirect) {
      if (forgotPasswordToken) {
        return (
          <Redirect
            to={`/account-settings/profile?resetToken=${forgotPasswordToken}`}
          />
        );
      }

      return <Redirect to="/space" />;
    }

    // Redirect after sign up for github users
    if (signedUpUserWithGithub && isGetStartedAvaialable) {
      return <Redirect to="/get-started" />;
    }

    if (pathname === '/space') {
      return null;
    }

    return <HeaderNavBar />;
  };

  const settingsRoute = getSettingsRoute({
    section: SETTING_SECTIONS.RESET_PASSWORD
  });

  const isNeedResetPasswordRedirect =
    pathname === '/' && forgotPasswordToken && isUserLoggedIn;

  return (
    <div className={cx(pageWrapClass, { alertsPage: isAlertsAddPage })}>
      <ConnectedRouter history={history}>
        <HelpButton />
        <OnboardingBiEvents />
        <ScrollToTop />
        <TrialIsOverModal />
        <PostSignUpQuestions />
        {isUserLoggedIn && <ManageUsersModal />}
        {showHeader && renderNavBar()}
        <AnonymousModeBanner
          openedSuccessClaimedModal={openedSuccessClaimedModal}
          setOpenedSuccessClaimedModal={setOpenedSuccessClaimedModal}
        />
        <DetectedExperiment />
        <SentryBoundary>
          <Switch>
            {!isNeedResetPasswordRedirect && (
              <PrivateRoute
                path="/"
                exact
                render={() => <Redirect to="/space" />}
              />
            )}
            <Route path="/404" exact component={ErrorPage} />
            <Route path="/about" exact component={About} />
            <Route path="/no-access" exact component={NoAccessToResource} />
            <Route
              path="/claim-anonymous"
              exact
              render={() => {
                const { isNewUser, redirectTo } = getAnonymousURLQueries();

                if (isClaimed) {
                  if (!isNewUser) {
                    history.push(`/${workspaceName}`);
                    setOpenedSuccessClaimedModal(true);
                  } else {
                    history.push(redirectTo);
                  }
                }

                if (claimAnonymousExperimentMutation.isLoading) return;

                setIsClaimed(true);
                claimAnonymousExperimentMutation.mutate({
                  workspaceName,
                  isNewUser
                });
                if (!isNewUser) {
                  setOpenedSuccessClaimedModal(true);
                }
              }}
            />
            <PrivateRoute path="/get-started" exact component={GetStarted} />
            <PrivateRoute
              path="/academics"
              exact
              redirectTo={URLS.PRICING}
              component={AcademicSignup}
            />
            <Route path="/auth/callback" exact component={OAuthCallbackPage} />
            {/* @deprecated */}
            <Route
              path="/careers/:jobId?"
              render={() => <Redirect to={URLS.CAREERS} />}
            />
            <Route path="/claim" exact component={ClaimInvite} />
            <PrivateRoute
              path="/closed"
              exact
              render={() => <Redirect to="/space" />}
            />
            {/* @deprecated */}
            <Route
              path="/contact"
              exact
              render={() => {
                window.location.href = URLS.CONTACT_US_NEW_SITE;
                return null;
              }}
            />
            <Route path="/embedded-panel" exact component={EmbeddedPanelPage} />
            <Route
              path="/faq"
              render={() => {
                window.location.href = URLS.FAQ;
                return null;
              }}
            />
            <Route
              path="/login"
              exact
              render={() => {
                return <GuestOnlyRoute path="/login" component={LoginPage} />;
              }}
            />
            <Route
              path="/login/sso"
              exact
              render={() => {
                return (
                  <GuestOnlyRoute path="/login/sso" component={SignInSSOPage} />
                );
              }}
            />
            <Route path="/pricing" exact component={PricingPage} />
            {SHOULD_SHOW_RESET_PASSWORD && (
              <GuestOnlyRoute
                path="/reset-password"
                exact
                redirectTo={settingsRoute}
                component={ResetPasswordPage}
              />
            )}
            {SHOULD_SHOW_SIGN_UP_BUTTON && (
              <GuestOnlyRoute path="/signup" exact component={SignupPage} />
            )}
            <PrivateRoute
              path="/space"
              exact
              render={() =>
                defaultWorkspaceName && <Redirect to={workspacePath} />
              }
            />
            <PrivateRoute
              path="/account-settings"
              component={AccountSettings}
            />

            <Route path="/organizations" component={OrganizationList} exact />

            <Route
              path="/organizations/:organizationId"
              component={AdminSettings}
            />

            <Route path="/:workspace" component={WorkspaceRoutes} />
            <Route
              render={router => {
                if (router.match.isExact) {
                  return null;
                }

                return <ErrorPage />;
              }}
            />
          </Switch>
        </SentryBoundary>
        <Analytics />
        {showAlerts && <Alerts />}
      </ConnectedRouter>
    </div>
  );
};

Routes.defaultProps = {
  isUserLoggedIn: false,
  loading: false,
  username: ''
};

Routes.propTypes = {
  history: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  username: PropTypes.string
};

const mapStateToProps = state => {
  return {
    apiKey: getAPIKey(state),
    isUserLoggedIn: getIsUserLoggedIn(state),
    location: state.router.location,
    username: getUsername(state)
  };
};

export default connect(mapStateToProps)(Routes);
