import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { convertMetadataV1 } from '@API/helpers/v2_helpers';
import { IMPORTED_FROM_KEY } from '@experiment-management-shared/constants/experimentConstants';
import { axiosInstance, cancelWrapper } from '../api';
import { isArray } from 'lodash';
import { getPanelsColumns, uniqColumns } from '@API/experiments/utils';

export const TARGET_COLUMNS = [
  {
    source: 'tag',
    keyName: 'tags'
  },
  {
    source: 'pinned',
    keyName: 'pinned'
  },
  {
    source: 'symlink',
    keyName: 'symlink'
  },
  {
    source: 'run',
    keyName: 'run'
  },
  {
    source: 'log_other',
    keyName: 'Name'
  },
  {
    source: 'metadata',
    keyName: 'file_name'
  },
  {
    source: 'metadata',
    keyName: 'file_path'
  },
  {
    source: 'metadata',
    keyName: 'projectId'
  },
  {
    source: 'metadata',
    keyName: 'duration'
  },
  {
    source: 'pinned',
    keyName: 'pinned'
  },
  {
    source: 'end_time',
    keyName: 'end_server_timestamp'
  },
  {
    source: 'metadata',
    keyName: 'start_server_timestamp'
  },
  {
    source: 'log_other',
    keyName: IMPORTED_FROM_KEY
  },
  {
    source: 'symlink',
    keyName: 'symlink'
  }
];

const getExperimentsWithMetadata = params => {
  return cancelWrapper(async cancelToken => {
    const {
      data: { metadata }
    } = await axiosInstance({
      cancelToken,
      data: params,
      method: 'post',
      url: 'query/metadata-v2'
    });

    return metadata.map((obj, index) => {
      const metadataV1 = obj[params.experiments[index]];
      return convertMetadataV1(metadataV1);
    });
  });
};

const getQueryParams = ({ params, projectId }) => {
  const { experimentKeys, template, isArchive } = params;
  let columns = TARGET_COLUMNS;

  if (template && template.panels && isArray(template.panels.panels)) {
    const panelsColumns = getPanelsColumns(template.panels.panels);
    columns = uniqColumns([...TARGET_COLUMNS, ...panelsColumns]);
  }

  return {
    columns,
    experiments: experimentKeys,
    isArchive,
    projectId
  };
};

export default function useExperimentsDetails(params, options) {
  const queryParams = getQueryParams({
    params,
    projectId: params.projectId
  });

  return useQuery(
    ['experimentsDetails', queryParams],
    () => getExperimentsWithMetadata(queryParams),
    {
      enabled: Boolean(params.projectId),
      ...options
    }
  );
}

export const IExperimentDetails = PropTypes.shape({
  experimentKey: PropTypes.string.isRequired,
  throttlingReasons: PropTypes.array,
  pinned: PropTypes.bool,
  symlink: PropTypes.bool,
  last_message_time: PropTypes.number,
  processing: PropTypes.bool,
  runActive: PropTypes.bool,
  active: PropTypes.bool,
  throttle: PropTypes.bool,
  hasImages: PropTypes.bool,
  'Imported from': PropTypes.string,
  server_timestamp: PropTypes.number,
  local_timestamp: PropTypes.number,
  projectId: PropTypes.string,
  archived: PropTypes.bool,
  Name: PropTypes.string,
  file_name: PropTypes.string,
  file_path: PropTypes.string,
  duration: PropTypes.number,
  end_server_timestamp: PropTypes.number,
  start_server_timestamp: PropTypes.number,
  comet_chart_color: PropTypes.string
});
