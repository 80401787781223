import React from 'react';
import PropTypes from 'prop-types';

import './ConfusionMatrix.scss';

export const ExperimentsMatrixTooltip = ({
  matrix,
  labels,
  x,
  y,
  rowMatrix,
  columnMatrix
}) => {
  return (
    <div className="confusion-matrix-chart-tooltip-container">
      <div className="confusion-matrix-chart-tooltip-label">
        Actual: {labels[y]}
      </div>
      <div className="confusion-matrix-chart-tooltip-label">
        Predicted: {labels[x]}
      </div>

      <div className="confusion-matrix-chart-tooltip-label">
        Counts: {matrix[y][x]}
      </div>

      <div className="confusion-matrix-chart-tooltip-label">
        Row: {rowMatrix[y][x]}%
      </div>

      <div className="confusion-matrix-chart-tooltip-label">
        Columns: {columnMatrix[y][x]}%
      </div>

      <div className="confusion-matrix-chart-tooltip-divider" />

      <div className="confusion-matrix-chart-tooltip-text">
        Click cell to inspect
      </div>
    </div>
  );
};

ExperimentsMatrixTooltip.propTypes = {
  matrix: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rowMatrix: PropTypes.array.isRequired,
  columnMatrix: PropTypes.array.isRequired
};
