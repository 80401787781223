import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import queryString from 'query-string';

import get from 'lodash/get';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  DETAIL_VIEWS,
  DEFAULT_OPEN_EXPERIMENT_TAB,
  DETAIL_VIEW_SETTINGS
} from '@experiment-management-shared/constants/experimentConstants';

import { VIEW_INFO } from '../../constants/experiment-details';

import ExperimentDetailsSidebar from './ExperimentDetailsSidebar/ExperimentDetailsSidebar';
import styles from './ExperimentDetailsWrapper.module.scss';

const getQueryExperimentTab = () => {
  const { 'experiment-tab': openExperimentTab } = queryString.parse(
    window.location.search
  );

  if (
    get(
      DETAIL_VIEW_SETTINGS,
      [DETAIL_VIEWS.ASSETS_AND_ARTIFACTS, 'internalTabs'],
      {}
    ).hasOwnProperty(openExperimentTab)
  ) {
    return DETAIL_VIEWS.ASSETS_AND_ARTIFACTS;
  }

  if (VIEW_INFO[openExperimentTab]) {
    return openExperimentTab;
  }

  return DEFAULT_OPEN_EXPERIMENT_TAB;
};

const COMPONENT_FIELDS = {
  SINGLE_VIEW: 'component',
  COMPARE_VIEW: 'compareComponent'
};

const ExperimentDetailsWrapper = ({
  projectId,
  experiments,
  onDetailViewChange,
  isComparison
}) => {
  const queryExperimentTab = getQueryExperimentTab();
  const [detailView, setDetailView] = useState(queryExperimentTab);

  const isMenuCollapsedByMediaQuery = useMediaQuery('(max-width:1064px)');
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(
    isMenuCollapsedByMediaQuery
  );

  useEffect(() => {
    setIsMenuCollapsed(isMenuCollapsedByMediaQuery);
  }, [isMenuCollapsedByMediaQuery]);

  const Tab =
    VIEW_INFO[detailView][
      isComparison
        ? COMPONENT_FIELDS.COMPARE_VIEW
        : COMPONENT_FIELDS.SINGLE_VIEW
    ];
  const [experiment] = experiments;

  useEffect(() => {
    setDetailView(queryExperimentTab);
  }, [queryExperimentTab]);

  const handleDetailViewChange = tabName => {
    onDetailViewChange(tabName);
    setDetailView(tabName);
  };

  const shouldBeFullHeightClass =
    !isComparison &&
    [DETAIL_VIEWS.HTML, DETAIL_VIEWS.NOTE, DETAIL_VIEWS.OTHERPARAMS].includes(
      detailView
    );

  return (
    <div className={styles.runDetail} id={experiment.experimentKey}>
      <div className={styles.viewMenuWrapper}>
        <ExperimentDetailsSidebar
          detailView={detailView}
          handleDetailViewChange={handleDetailViewChange}
          isMenuCollapsed={isMenuCollapsed}
          onExpandCollapseClick={setIsMenuCollapsed}
          isComparison={isComparison}
        />

        <div
          className={classnames(styles.view, {
            [styles.collapsed]: isMenuCollapsed
          })}
        >
          <div
            className={classnames(styles.experimentDetailWrapper, {
              [styles.fullHeight]: shouldBeFullHeightClass,
              'contained-wrap': !shouldBeFullHeightClass
            })}
          >
            <Tab projectId={projectId} experiments={experiments} />
          </div>
        </div>
      </div>
    </div>
  );
};

ExperimentDetailsWrapper.defaultProps = {
  isComparison: false
};

ExperimentDetailsWrapper.propTypes = {
  projectId: PropTypes.string.isRequired,
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  onDetailViewChange: PropTypes.func.isRequired,
  isComparison: PropTypes.bool
};

export default ExperimentDetailsWrapper;
