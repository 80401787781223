import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

const deleteServiceAccount = ({ organizationId, name }) =>
  organizationApi.deleteServiceAccount({
    organizationId,
    name
  });

const useDeleteServiceAccountMutation = organizationId => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(name => deleteServiceAccount({ name, organizationId }), {
    onSuccess: res => {
      const removedServiceAccount = res?.data || {};

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SERVICE_ACCOUNT_REMOVED,
          'Successfully removed a service account'
        )
      );

      queryClient.setQueriesData(
        ['organizations', organizationId, 'service-accounts'],
        oldSettingsAccounts =>
          oldSettingsAccounts.filter(
            account => account.name !== removedServiceAccount.name
          )
      );
    }
  });
};

export default useDeleteServiceAccountMutation;
