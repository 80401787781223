import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import useUpdateTagsMutation from '@API/experiments/useUpdateTagsMutation';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

import Tag from '../Tag';

const ExperimentTag = ({ experimentKey, ...restProps }) => {
  const { tagName } = restProps;
  const updateTags = useUpdateTagsMutation();
  const dispatch = useDispatch();

  const handleConfirmDelete = () => {
    const tagUpdates = [
      {
        experimentId: experimentKey,
        addedTags: [],
        deletedTags: [tagName]
      }
    ];

    updateTags.mutate({ tagUpdates });
    dispatch(
      alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_TAG_FROM_EXPERIMENT)
    );
  };

  const handleDeleteTagFromExperiment = () => {
    dispatch(
      alertsUtil.openConfirmDialog(
        dialogTypes.CONFIRM_DELETE_TAG_FROM_EXPERIMENT,
        'This will remove the tag from your experiment',
        handleConfirmDelete,
        'Delete'
      )
    );
  };

  return <Tag {...restProps} onDeleteTag={handleDeleteTagFromExperiment} />;
};

ExperimentTag.defaultProps = {
  isDeleteDisabled: false,
  experimentKey: undefined
};

ExperimentTag.propTypes = {
  isDeleteDisabled: PropTypes.bool,
  experimentKey: PropTypes.string,
  tagColor: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired
};

export default ExperimentTag;
