import get from 'lodash/get';

import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import { LLM_TAB_PATHNAME } from '@experiment-management-shared/constants/llmConstants';
import { projectsActionTypes } from '@/constants/actionTypes';

const initialState = {
  errors: null,
  error: null,
  validatedProjectName: null,
  successMessage: null,
  EMActiveHeaderTab: EXPERIMENT_TAB_PATHNAME.experiments,
  LLMActiveHeaderTab: LLM_TAB_PATHNAME.prompts,
  activeExperimentsHeaderTab: 0,
  isFetchingProject: false,
  selectedProjectId: '',
  selectedProjectShareableLinks: [],
  maxAllowedPinnedExperiments: 10,
  storedSelectedExperiments: [],
  editViewName: '',
  viewHasUnsavedChanges: false
};

export function projectsUIReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case projectsActionTypes.FETCH_PROJECT:
      return { ...state, isFetchingProject: true };

    case projectsActionTypes.SET_PROJECT:
      return { ...state, isFetchingProject: false };

    case projectsActionTypes.CLEAR_PROJECTS_ERRORS:
      return { ...state, errors: null, error: null };

    case projectsActionTypes.VALIDATE_PROJECT_BEING_ADDED:
      return { ...state, validatedProjectName: action.validatedProjectName };

    case projectsActionTypes.SET_SELECTED_PROJECT_ID:
      return { ...state, selectedProjectId: payload };

    case projectsActionTypes.SET_ACTIVE_EM_HEADER_TAB: {
      return { ...state, EMActiveHeaderTab: payload };
    }

    case projectsActionTypes.SET_ACTIVE_LLM_HEADER_TAB: {
      return { ...state, LLMActiveHeaderTab: payload };
    }

    case projectsActionTypes.SET_SHAREABLE_LINKS: {
      return { ...state, selectedProjectShareableLinks: payload };
    }

    case projectsActionTypes.SET_ACTIVE_EXPERIMENTS_HEADER_TAB: {
      const { activeExperimentsHeaderTab } = payload;

      return { ...state, activeExperimentsHeaderTab };
    }

    case projectsActionTypes.SET_STORED_EXPERIMENT_SELECTION: {
      const { selection } = payload;

      return { ...state, storedSelectedExperiments: selection };
    }

    case projectsActionTypes.SET_EDIT_VIEW_NAME: {
      return { ...state, editViewName: payload };
    }

    case projectsActionTypes.SET_VIEW_HAS_UNSAVED_CHANGES: {
      return { ...state, viewHasUnsavedChanges: payload };
    }

    case projectsActionTypes.CLEAR_PROJECTS_UI:
      return initialState;

    default:
      break;
  }

  return state;
}

export const getSelectedProjectId = state =>
  state.ui.projects.selectedProjectId;
export const getValidatedProjectName = state =>
  state.ui.projects.validatedProjectName;

export const getShareableLinks = state =>
  state.ui.projects.selectedProjectShareableLinks;

export const getStoredSelectedExperiments = state =>
  state.ui.projects.storedSelectedExperiments;

export const getActiveEMHeaderTab = state =>
  state.ui.projects.EMActiveHeaderTab;

export const getActiveLLMHeaderTab = state =>
  state.ui.projects.LLMActiveHeaderTab;

export const getActiveExperimentsHeaderTab = state =>
  state.ui.projects.activeExperimentsHeaderTab;

export const getMaxAllowedPinnedExperiments = state =>
  state.ui.projects.maxAllowedPinnedExperiments;

export const getViewHasUnsavedChanges = state =>
  state.ui.projects.viewHasUnsavedChanges;

export const getEditViewName = state =>
  get(state, ['ui', 'projects', 'editViewName'], '');
