import { SORT_TYPES } from '@experiment-management-shared/constants/tabConfigs';

export const IMAGE_WIDTH = 282;
export const GALLERY_CONTAINER_HEIGHT = 776;
export const IMAGE_HEIGHT = 282;

export const GROUP_BY_OPTIONS = [
  { value: false, label: 'None' },
  { value: SORT_TYPES.NAME, label: 'Name' },
  { value: SORT_TYPES.STEP, label: 'Step' },
  { value: SORT_TYPES.ASSET_TYPE, label: 'Asset Type' },
  { value: SORT_TYPES.CONTEXT, label: 'Context' }
];

export const SORT_BY_OPTIONS = [
  { value: SORT_TYPES.NAME, label: 'Name' },
  { value: SORT_TYPES.STEP, label: 'Step' },
  { value: SORT_TYPES.CREATED_AT, label: 'Log Time' }
];

export const GRAPHICS_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video'
};

export const GRAPHICS_ASSETS_TYPES = {
  image: 'image',
  video: 'video'
};

export const GRAPHICS_ASSETS_FIELDS_MAP = {
  id: 'id',
  path: 'path',
  name: 'name',
  type: 'type',
  step: 'step',
  fileName: 'fileName',
  thumbnailPath: 'thumbnailPath'
};

export const GRAPHICS_FIELDS_MAP = {
  id: {
    image: 'imageId',
    video: 'assetId'
  },
  name: {
    image: 'figName',
    video: 'userFileName'
  },
  fileName: {
    image: 'fileName',
    video: 'userFileName'
  },
  path: {
    image: 'imagePath',
    video: 'videoPath'
  },
  thumbnailPath: {
    image: 'thumbnailPath',
    video: 'thumbnailPath'
  }
};
