import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import cx from 'classnames';

import '../TableCells.scss';

export const TextCell = ({ value, isBold }) => {
  return (
    <Box>
      {value ? (
        <span className={cx('text-cell', { 'bold-cell': isBold })}>
          {value}
        </span>
      ) : (
        <Box className="empty-cell" />
      )}
    </Box>
  );
};

TextCell.defaultProps = {
  value: '',
  isBold: false
};

TextCell.propTypes = {
  value: PropTypes.string,
  isBold: PropTypes.bool
};

export default TextCell;
