import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getIsHeaderTableShown } from '@/reducers/experimentDetails/uiReducer';
import ExperimentsDetailsGridWrapper from './ExperimentPageHeader/ExperimentsDetailsGridWrapper';

const ExperimentPageHeader = ({
  availableColumns,
  isArchive,
  isLoadingExperimentsData,
  projectId,
  rows,
  isComparison
}) => {
  const isHeaderTableShown = useSelector(getIsHeaderTableShown);

  return (
    <div className="experiment-page-header-container">
      {isHeaderTableShown && (
        <ExperimentsDetailsGridWrapper
          availableColumns={availableColumns}
          id="experiments-table"
          data-test="single-table-experiments"
          isArchive={isArchive}
          projectId={projectId}
          isLoadingExperimentsData={isLoadingExperimentsData}
          resizingEnabled
          rows={rows}
          isComparison={isComparison}
        />
      )}
    </div>
  );
};

ExperimentPageHeader.defaultProps = {
  availableColumns: [],
  isLoadingExperimentsData: false,
  rows: []
};

ExperimentPageHeader.propTypes = {
  availableColumns: PropTypes.array,
  isArchive: PropTypes.bool.isRequired,
  isLoadingExperimentsData: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  rows: PropTypes.array,
  isComparison: PropTypes.bool.isRequired
};

export default ExperimentPageHeader;
