import React from 'react';
import isEmpty from 'lodash/isEmpty';

export const getModelNameValidationTooltipText = (
  newModelName,
  modelNameValidation
) => {
  const {
    finalModelName,
    modelNameExists,
    originalModelName
  } = modelNameValidation;

  if (isEmpty(newModelName)) {
    return 'You must give your model a name';
  }

  if (!isEmpty(originalModelName) && isEmpty(finalModelName)) {
    return 'A model name cannot contain only special characters';
  }

  if (modelNameExists) {
    return 'This name already exists, choose another name.';
  }

  return `Your model will be named ${finalModelName}`;
};

export const getModelVersionValidationTooltipText = modelVersionValidation => {
  const { validVersion, versionExists } = modelVersionValidation;

  const helpIcon = (
    <i className="material-icons help-icon" style={{ margin: '0 4px' }}>
      help_outline
    </i>
  );

  if (!validVersion) {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        You must use semantic versioning. Please click {helpIcon} for more info.
      </span>
    );
  }

  if (versionExists) {
    return 'This model version already exists, choose another version.';
  }
};

export const MODEL_SCOPE_TYPES = {
  PUBLIC: 'Public',
  PRIVATE: 'Private'
};

export const DEFAULT_MODEL_VERSION = '1.0.0';

export const INPUT_VALIDATION_FREQUENCY = 400;
