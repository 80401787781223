import React from 'react';
import PropTypes from 'prop-types';

const EmptyMessage = ({ message }) => {
  return (
    <div className="row">
      <div className="col-md-10 col-centered panel-title-wrapper">
        <h4>{message}</h4>
      </div>
    </div>
  );
};

EmptyMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default EmptyMessage;
