import React, { ReactNode } from 'react';

import { Button } from '@ds';
import './NoTrackedModelsView.scss';

type NoTrackedModelsViewProps = {
  title: string;
  icon: ReactNode;
};

export function NoTrackedModelsView({ title, icon }: NoTrackedModelsViewProps) {
  return (
    <div className="emptyStateContainer">
      {icon}
      <h4>{title}</h4>
      <p>
        To learn more about production monitoring, checkout the MPM
        documentation or reach out to the Comet team
      </p>
      <a
        href="/docs/v2/guides/model-production-monitoring/mpm-overview/"
        target="_blank"
      >
        <Button size="XL" data-test="mpm-documentation">
          To MPM Documentation
        </Button>
      </a>
    </div>
  );
}
