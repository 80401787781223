// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperimentDetails-module__experimentDetailsContainer--NiJ42{display:flex;flex-direction:column;flex:1;padding:0}.ExperimentDetails-module__experimentDetailContainer--dH3Ec{display:flex;flex-direction:column;flex:1;padding-top:10px}.ExperimentDetails-module__experimentDetailContainer--dH3Ec .MuiTableCell-head{color:#fff}.ExperimentDetails-module__experimentDetailContainer--dH3Ec .detail-cell{height:calc(100vh - 50px)}.ExperimentDetails-module__comparison--xp8BG .detail-table-header thead tr:first-of-type{color:#333}.ExperimentDetails-module__comparison--xp8BG .compare-tabs .ExperimentDetails-module__material-icons--HvLwq{font-size:15px;color:var(--primary-color)}.ExperimentDetails-module__comparison--xp8BG .compare-tab{align-items:center;display:flex;justify-content:center;width:100%}.ExperimentDetails-module__comparison--xp8BG .compare-appbar{background-color:#fff;border-bottom:1px solid #f0f0f0;box-shadow:none;height:48px;color:#000}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentDetailsContainer": `ExperimentDetails-module__experimentDetailsContainer--NiJ42`,
	"experimentDetailContainer": `ExperimentDetails-module__experimentDetailContainer--dH3Ec`,
	"comparison": `ExperimentDetails-module__comparison--xp8BG`,
	"material-icons": `ExperimentDetails-module__material-icons--HvLwq`
};
module.exports = ___CSS_LOADER_EXPORT___;
