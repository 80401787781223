// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageDetail-module__container--HbpYb{display:flex;flex-direction:column;align-items:center;height:100%;width:100%}.ImageDetail-module__image--cIvIf{display:flex;align-items:center;justify-content:center;height:calc(100% - 60px);padding-top:60px;width:100%}.ImageDetail-module__title--QHISG{margin-top:20px;color:var(--gray-5)}.ImageDetail-module__imageWrapper--xOZio{height:100%;max-width:100%;object-fit:contain}.ImageDetail-module__zoomControlContainer--iMrqz{height:24px;background:var(--gray-1);display:flex;align-items:center;justify-content:center;padding:0 5px;border-radius:12px;width:fit-content;margin-bottom:40px;margin-top:15px;position:absolute;user-select:none}.ImageDetail-module__zoomControlContainer--iMrqz p{margin:0 5px;color:var(--gray-5)}.ImageDetail-module__zoomIconWrapper--zkW9f{cursor:pointer;color:var(--gray-3)}.ImageDetail-module__zoomActive--sXg04{color:var(--gray-5)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ImageDetail-module__container--HbpYb`,
	"image": `ImageDetail-module__image--cIvIf`,
	"title": `ImageDetail-module__title--QHISG`,
	"imageWrapper": `ImageDetail-module__imageWrapper--xOZio`,
	"zoomControlContainer": `ImageDetail-module__zoomControlContainer--iMrqz`,
	"zoomIconWrapper": `ImageDetail-module__zoomIconWrapper--zkW9f`,
	"zoomActive": `ImageDetail-module__zoomActive--sXg04`
};
module.exports = ___CSS_LOADER_EXPORT___;
