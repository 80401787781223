import React from 'react';
import classes from './BuiltInGallery.module.scss';
import typography from '@ds-typography';
import { GalleryChart } from '@experiment-management-shared/types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants';

interface BuiltInGalleryProps {
  charts: GalleryChart[];
}

const BuiltInGallery = ({ charts }: BuiltInGalleryProps) => {
  const location = useLocation();

  const filteredCharts = charts.filter(
    chart => chart.id !== BUILT_IN_CHART_TYPES.python
  );
  const pythonChart = charts?.find(
    chart => chart.id === BUILT_IN_CHART_TYPES.python
  );

  const renderChart = (chart: GalleryChart) => {
    const hash = `#${SELECT_PANEL_PATH}?chartCategory=${chart.type}&chartType=${chart.id}`;

    return (
      <Link to={{ ...location, hash }}>
        <div
          className={cx(classes.galleryChartContainer, {
            [classes.extendedChart]: chart.isExtended
          })}
          key={chart.id}
        >
          {chart.isNew && <div className={classes.isNewBadge}>New</div>}
          <div className={classes.pictureAndName}>
            <img src={chart.thumbnail} alt={chart.id} />
            <p className={classes.chartName}>{chart.name}</p>
          </div>
          {chart.isExtended && (
            <div className={classes.chartDescriptionContainer}>
              {chart.descriptionTitle && (
                <div className={classes.chartDescriptionTitle}>
                  {chart.descriptionTitle}
                </div>
              )}
              <div
                className={cx(
                  classes.chartDescription,
                  typography.dsBodySmall,
                  typography.dsDefaultWeight
                )}
              >
                {chart.description}
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  };
  return (
    <div
      className={cx(
        classes.builtInGalleryContainer,
        typography.dsAccented,
        typography.dsBody
      )}
    >
      <div className={classes.galleryChartGrid}>
        {filteredCharts.map(renderChart)}
        {pythonChart && (
          <div className={classes.pythonChartContainer}>
            <p className={classes.pythonTitle}>Create your own panel</p>
            <div>{renderChart(pythonChart)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuiltInGallery;
