import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PrismaZoom from 'react-prismazoom';

import cx from 'classnames';

import './ZoomImage.scss';
import { useRefSizes } from '@shared/hooks';

const MAX_ZOOM_LEVEL = 10;
const DEFAULT_ZOOM_LEVEL = 1;
const ANIMATION_DURATION = 0;

const ZoomImage = (
  {
    src,
    alt,
    className,
    disableZooming,
    renderCustomImageComponent,
    zoomActionHandler
  },
  zoomRef
) => {
  const [currentZoom, setCurrentZoom] = useState(DEFAULT_ZOOM_LEVEL);
  const { refHeight, refWidth, sizeRef } = useRefSizes();

  useEffect(() => {
    zoomRef?.current?.reset();
  }, [src]);

  if (disableZooming) {
    return <img src={src} alt={alt} className={className} />;
  }

  return (
    <div className="zoom-image-container" ref={sizeRef}>
      <PrismaZoom
        maxZoom={MAX_ZOOM_LEVEL}
        onZoomChange={val => {
          setCurrentZoom(val);
          zoomActionHandler(val);
        }}
        ref={zoomRef}
        animDuration={ANIMATION_DURATION}
      >
        {renderCustomImageComponent ? (
          renderCustomImageComponent(refHeight, refWidth)
        ) : (
          <img
            src={src}
            alt={alt}
            style={{
              maxHeight: refHeight
            }}
            width={refWidth}
            className={cx(className, 'zoom-image', {
              'zoom-out': currentZoom >= MAX_ZOOM_LEVEL
            })}
          />
        )}
      </PrismaZoom>
    </div>
  );
};

ZoomImage.propTypes = {
  src: PropTypes.string,
  renderCustomImageComponent: PropTypes.func,
  alt: PropTypes.string,
  className: PropTypes.string,
  disableZooming: PropTypes.bool,
  zoomActionHandler: PropTypes.func
};

ZoomImage.defaultProps = {
  alt: null,
  src: '',
  className: null,
  zoomActionHandler: () => {},
  renderCustomImageComponent: null,
  disableZooming: false
};

export default forwardRef(ZoomImage);
