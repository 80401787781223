import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '@ds';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { Input } from '@DesignSystem/controllers';
import { useCreateWorkspaceMutation } from '@account-settings/api';
import { useCurrentOrganization } from '@shared/hooks';

import './CreateWorkspace.scss';

const CreateWorkspace = () => {
  const history = useHistory();
  const [isOpenSetUp, setIsOpenSetUp] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isNameTaken, setIsNameTaken] = useState(false);
  const [isCountExceeded, setIsCountExceeded] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const createWorkspaceMutation = useCreateWorkspaceMutation(setWorkspaceName);
  const currentOrganization = useCurrentOrganization();

  const handleOpenSetUp = () => {
    setIsOpenSetUp(true);
  };

  const handleCloseSetUp = (withCleanInput = true) => {
    setIsOpenSetUp(false);
    if (withCleanInput) {
      setWorkspaceName('');
    }
  };

  const handleOpenSuccess = () => {
    setIsOpenSuccess(true);
  };

  const handleCloseSuccess = (withoutRedirection = true) => {
    if (!withoutRedirection) {
      history.push({
        pathname: `/${workspaceName}`
      });
    }
    setIsOpenSuccess(false);
    setWorkspaceName('');
  };

  const handleCreateWorkspaceClick = async () => {
    try {
      setIsNameTaken(false);
      await createWorkspaceMutation.mutateAsync({
        workspaceName,
        organizationId: currentOrganization?.id
      });
      handleCloseSetUp(false);
      handleOpenSuccess();
    } catch (e) {
      if (e?.response?.data?.msg === 'Workspace name already exists.') {
        setIsNameTaken(true);
      } else if (e?.response?.data?.msg === 'Max Workspace count exceeded') {
        setIsCountExceeded(true);
      }
    }
  };

  useEffect(() => {
    setIsNameTaken(false);
    setIsCountExceeded(false);
  }, [workspaceName]);

  const errorLabel = useMemo(() => {
    if (workspaceName.length > 80) {
      return 'Your workspace name must have fewer than 80 characters.';
    }

    if (isNameTaken) {
      return `Error! workspace name “${workspaceName}” already exists, please enter another name`;
    }

    if (isCountExceeded) {
      return 'Error! Max workspace count exceeded';
    }

    return '';
  }, [workspaceName, isNameTaken, isCountExceeded]);

  const renderCreateWorkspaceModal = () => {
    return (
      <div className="create-workspace-modal">
        <img
          className="scalable-image"
          src="/images/account-settings/set-up-workspace.svg"
          alt=""
        />
        <div className="create-workspace-label">
          In a workspace you can have many projects, register models, manage
          artifacts and monitor models.
        </div>
        <Input
          onChange={setWorkspaceName}
          value={workspaceName}
          title="Workspace name"
          placeholder="Enter name"
          required
          height="36px"
          invalidMsg={errorLabel}
          invalidBorder={Boolean(errorLabel)}
        />
      </div>
    );
  };

  const renderSuccessfullyCreatedWorkspaceModal = () => {
    return (
      <div className="successfully-created-workspace-modal">
        <div className="all-set-label">You are all set</div>
        <div className="successfully-created-workspace-label full-width-modal-title">
          You successfully created a workspace
        </div>
        <img src="/images/account-settings/successfully-create-workspace.svg" />
        <Button onClick={() => handleCloseSuccess(false)}>
          Let&apos;s Get Started
        </Button>
      </div>
    );
  };

  const createWorkspacePrimaryButtonLabel = createWorkspaceMutation.isLoading
    ? 'Creating workspace'
    : 'Create workspace';

  return (
    <>
      <FullWidthBasicModal
        open={isOpenSetUp}
        onClose={handleCloseSetUp}
        title="Set up your workspace"
        onPrimaryButtonClick={handleCreateWorkspaceClick}
        onSecondaryButtonClick={handleCloseSetUp}
        primaryButtonText={createWorkspacePrimaryButtonLabel}
        secondaryButtonText="Cancel"
        isPrimaryButtonDisabled={
          !workspaceName.length ||
          Boolean(errorLabel) ||
          createWorkspaceMutation.isLoading
        }
        isPrimaryButtonLoading={createWorkspaceMutation.isLoading}
        content={renderCreateWorkspaceModal()}
      />

      <FullWidthBasicModal
        open={isOpenSuccess}
        onClose={handleCloseSuccess}
        content={renderSuccessfullyCreatedWorkspaceModal()}
        withoutFooter
      />
      <Button onClick={handleOpenSetUp} size="large">
        Create Workspace
      </Button>
    </>
  );
};

export default CreateWorkspace;
