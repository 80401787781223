import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import type { CodePanelRevision } from './types';

interface GetCodePanelRevisionId {
  templateId?: string;
  revisionId?: string;
  signal?: AbortSignal;
}

const getCodePanelRevision = async ({
  templateId,
  revisionId,
  signal
}: GetCodePanelRevisionId): Promise<string> => {
  const response = await api.get<CodePanelRevision>(
    `code-panels/templates/${templateId}/revisions/${revisionId}`,
    { signal }
  );

  return response.data.code;
};

const usePythonPanelCode = (
  { templateId, revisionId }: GetCodePanelRevisionId = {},
  config: QueryConfig<string> = {}
) => {
  const outEnabled = config?.enabled ?? true;

  return useQuery(
    ['code-panel', { templateId, revisionId }],
    ({ signal }) => getCodePanelRevision({ templateId, revisionId, signal }),
    {
      ...config,
      enabled: !!(outEnabled && templateId && revisionId)
    }
  );
};

export default usePythonPanelCode;
