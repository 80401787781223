import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers';
import { flow } from '../middleware/flow';

export default function configureStore(initialState, history) {
  const middlewareForRouter = routerMiddleware(history);
  const enhancer = compose(
    applyMiddleware(flow, thunkMiddleware, middlewareForRouter)
  );
  return createStore(createRootReducer(history), initialState, enhancer);
}
