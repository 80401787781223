import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import noop from 'lodash/noop';

import CloseIcon from '@material-ui/icons/Close';
import { DragIcon } from '@Icons-outdated';

import get from 'lodash/get';
import { IConfusionMatrixData } from './IConfusionMatrixData';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { getExperimentColor } from '@experiment-management-shared/utils/experimentHelpers';
import ConfusionMatrixExample from './ConfusionMatrixExample';

import styles from './ConfusionMatrixExamples.module.scss';

const ConfusionMatrixExamples = ({
  experiment,
  title,
  data,
  positions,
  highlightedCell,
  onClose,
  onCloseCell,
  DraggableProps
}) => {
  const { labels, sampleMatrix, type } = data;

  const renderExampleBody = () => {
    return positions.map(({ row, col }) => {
      const key = `confusion-matrix-cell-example-${row}-${col}`;
      const actualValue = labels[row];
      const predictedValue = labels[col];
      const samples = get(sampleMatrix, [row, col], null);

      const isHighlighted =
        highlightedCell &&
        highlightedCell.x === col &&
        highlightedCell.y === row;

      return (
        <ConfusionMatrixExample
          actualValue={actualValue}
          experimentKey={experiment.experimentKey}
          key={key}
          onClose={() => onCloseCell({ col, row })}
          predictedValue={predictedValue}
          samples={samples}
          type={type}
          isHighlighted={isHighlighted}
        />
      );
    });
  };

  const color = getExperimentColor(experiment).primary;

  return (
    <Draggable handle={`.${styles.dragHandle}`} {...DraggableProps}>
      <div className={styles.confusionMatrixExamples}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.color} style={{ background: color }} />
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.dragHandle}>
            <DragIcon />
          </div>
          <div className={styles.closeContainer}>
            <CloseIcon className={styles.closeIcon} onClick={onClose} />
          </div>
        </div>
        <div>{renderExampleBody()}</div>
      </div>
    </Draggable>
  );
};

ConfusionMatrixExamples.defaultProps = {
  highlightedCell: null,
  onClose: noop,
  onCloseCell: noop,
  DraggableProps: {}
};

ConfusionMatrixExamples.propTypes = {
  experiment: IExperimentDetails.isRequired,
  title: PropTypes.string.isRequired,
  data: IConfusionMatrixData.isRequired,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      col: PropTypes.number.isRequired
    })
  ).isRequired,
  highlightedCell: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  onClose: PropTypes.func,
  onCloseCell: PropTypes.func,
  DraggableProps: PropTypes.object
};

export default ConfusionMatrixExamples;
