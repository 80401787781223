// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicStepSection-module__container--DgZUr{line-height:1;padding:10px;width:100%}.GraphicStepSection-module__slider--nCC1T{width:100%;margin-left:3px;margin-top:15px}.GraphicStepSection-module__sliderBlock--lkbfI{margin-top:30px;margin-bottom:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GraphicStepSection-module__container--DgZUr`,
	"slider": `GraphicStepSection-module__slider--nCC1T`,
	"sliderBlock": `GraphicStepSection-module__sliderBlock--lkbfI`
};
module.exports = ___CSS_LOADER_EXPORT___;
