import { convertWebhookDataToCometPayload } from '@model-registry/utils/webhooks';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import modelRegistryApi from '@/util/modelRegistryApi';

const useModelRegistryEditWebhookMutation = ({ workspaceName, modelName }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ id, ...updatedData }) => {
      const dataToSave = {
        ...updatedData
      };

      const payload = convertWebhookDataToCometPayload(
        { workspaceName, modelName },
        dataToSave
      );

      return modelRegistryApi.updateWebhook(
        {
          id
        },
        payload
      );
    },
    {
      onSuccess: async (
        data,
        { snackbarMsg = 'Successfully edited a webhook.' } = {}
      ) => {
        await queryClient.invalidateQueries([
          'modelRegistry',
          { workspaceName, modelName },
          'webhooks'
        ]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_MODEL_REGISTRY_EDIT_WEBHOOK,
            snackbarMsg
          )
        );
      },
      onError: (
        data,
        { snackbarMsgErr = 'There was an error editing a webhook.' } = {}
      ) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            snackbarMsgErr
          )
        );
      }
    }
  );
};

export default useModelRegistryEditWebhookMutation;
