import { useSelector } from 'react-redux';
import { isBetaFeatureEnabled } from '../../reducers/betaFeaturesReducer';
import { Selector } from 'reselect';

const useBetaFeatureEnabled = (featureName: string): boolean => {
  const isFeatureEnabled = useSelector(state => {
    return (isBetaFeatureEnabled as Selector)(state, {
      featureName
    });
  });

  return !!isFeatureEnabled;
};

export default useBetaFeatureEnabled;
