import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, TextButton } from '@ds';

import { CloseModalIcon } from '@Icons-outdated';
import alertsUtil from '@/util/alertsUtil';
import GenericModal from '@shared/components/GenericModal';

const DeleteMetricModal = ({ modalId, title, subTitle, submitHandler }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    submitHandler();
    handleClose();
  };

  return (
    <GenericModal
      customClass="generic-modal-alt delete-metric-modal"
      title={title}
      onCancel={handleClose}
      hideFooter
    >
      <span className="close-modal-icon-wrapper" onClick={handleClose}>
        <CloseModalIcon />
      </span>
      <div className="generic-modal-section">{subTitle}</div>
      <div className="delete-metric-modal-footer">
        <Button
          size="large"
          onClick={handleSubmit}
          data-test="delete-metric-button"
        >
          Delete
        </Button>
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
      </div>
    </GenericModal>
  );
};

DeleteMetricModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired
};

export default DeleteMetricModal;
