import { useQuery } from 'react-query';

import api from '@API/api';
import { AlertRule } from '@mpm-druid/types';
import { QueryConfig } from '@shared/api';

type AlertRulesByIDParams = {
  modelId: string;
  monitorIds: string[];
};

type AlertRulesResponse = {
  monitorIds: AlertRule[];
  total: number;
};

const getAlertRulesByID = (params: AlertRulesByIDParams) => {
  return api.post(`/mpm/v2/monitors/getByIds`, params);
};

export function useAlertRulesByID(
  params: AlertRulesByIDParams,
  config: QueryConfig<AlertRulesResponse>
) {
  return useQuery(
    ['alertRulesById', { ...params }],
    () => getAlertRulesByID({ ...params }),
    {
      enabled: config.enabled
    }
  );
}
