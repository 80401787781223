import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import workspaceApi from '@/util/workspaceApi';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes, dialogTypes } from '@/constants/alertTypes';

const useDeleteWorkspaceMutation = organizationId => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(workspaceId => workspaceApi.deleteWorkspace(workspaceId), {
    onSuccess: () => {
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_SNACKBAR_DIALOG,
          'Your workspace has been successfully deleted.'
        )
      );
      queryClient.invalidateQueries(['workspaces', 'all']);
      queryClient.resetQueries(['workspaces', 'all']);

      if (organizationId) {
        queryClient.invalidateQueries(['organizations', organizationId]);
      }
    },
    onError: err => {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_DELETE_WORKSPACE,
          err?.response?.data?.msg ||
            'There was an error deleting this workspace'
        )
      );
    }
  });
};

export default useDeleteWorkspaceMutation;
