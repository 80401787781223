import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { unix } from 'moment';
import sortBy from 'lodash/sortBy';

import { TextButton } from '@ds';

import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import RedirectLink from '@shared/components/RedirectLink';

const ViewExistingModelsPopper = ({ experimentModel }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const formattedTimestamp = timestamp =>
    unix(timestamp / 1000).format('MM/DD/YYYY');

  const renderItems = () => {
    const sortedRecords = sortBy(
      experimentModel.registryRecords,
      'createdAt'
    ).reverse();

    return sortedRecords.map(modelItem => {
      const {
        registryModelName,
        version,
        createdAt,
        registryModelItemId
      } = modelItem;

      return (
        <ListItem
          className="existing-models-list-item"
          key={`${registryModelName}-${version}`}
        >
          <div className="list-item-cell model-name">
            <RedirectLink
              args={[registryModelItemId]}
              resource={REDIRECT_LINK_RESOURCES.MODEL_REGISTRY}
            >
              {registryModelName}
            </RedirectLink>
          </div>
          <div className="list-item-cell">{version}</div>
          <div className="list-item-cell">{formattedTimestamp(createdAt)}</div>
        </ListItem>
      );
    });
  };

  return (
    <>
      <TextButton onClick={handleClick} size="small">
        View Existing
      </TextButton>
      <Popover
        className="existing-models-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        elevation={2}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Paper>
          <List className="existing-models-list">
            <ListItem className="existing-models-list-item">
              <div className="list-item-cell model-name">Name</div>
              <div className="list-item-cell">Version</div>
              <div className="list-item-cell">Date Created</div>
            </ListItem>
            {renderItems()}
          </List>
        </Paper>
      </Popover>
    </>
  );
};

ViewExistingModelsPopper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  experimentModel: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default ViewExistingModelsPopper;
