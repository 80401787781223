import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Box } from '@material-ui/core';

import { ModelType } from '@mpm/types';
import { AlertRulesPage } from '../AlertRulesPage';
import { AlertNotificationsPage } from '../AlertNotificationsPage';
import { LinkButton } from '@ds';

type AlertsPageProps = {
  model: ModelType;
};

export const AlertsPage = ({ model }: AlertsPageProps) => {
  const [hasNotifications, setHasNotifications] = useState(false);
  const [hasRules, setHasRules] = useState(false);
  const { pathname } = useLocation();

  return (
    <Box className="alerts-page-wrapper">
      <Box className="alerts-page-section-wrapper">
        <AlertNotificationsPage
          model={model}
          previewPage
          dataTracker={setHasNotifications}
        />
        <Box className="alerts-page-link-wrapper">
          <LinkButton
            to={`${pathname}/notifications`}
            disabled={!hasNotifications}
            text="See All Alert Notifications"
          />
        </Box>
      </Box>
      <Box className="alerts-page-section-wrapper">
        <AlertRulesPage model={model} previewPage dataTracker={setHasRules} />
        <Box className="alerts-page-link-wrapper">
          <LinkButton
            to={`${pathname}/rules`}
            disabled={!hasRules}
            text="See All Alert Rules"
          />
        </Box>
      </Box>
    </Box>
  );
};
