import { Context, createContext, useContext } from 'react';

interface ComparisonTableContextType {
  decimalsPrecision: number;
}

export const ComparisonTableContext: Context<ComparisonTableContextType> = createContext<ComparisonTableContextType>(
  {
    decimalsPrecision: Infinity
  }
);

export const useComparisonTableContext = (): ComparisonTableContextType =>
  useContext<ComparisonTableContextType>(ComparisonTableContext);
