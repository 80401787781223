import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { useIsUserMemberOfWorkspace } from '@shared/hooks';
import { TextButton } from '@ds';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import RegisterModelModal from './RegisterModelModal';
import { DSPlusIcon } from '@ds-icons';

const RegisterModelModalBtn = ({ dispatch, experimentModel, workspace }) => {
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();

  if (!isUserAMemberOfWorkspace) return null;

  const modalId = dialogTypes.REGISTER_MODEL_MODAL;

  return (
    <TextButton
      size="small"
      PrefixIcon={<DSPlusIcon />}
      onClick={event => {
        event.stopPropagation();
        dispatch(
          alertsUtil.openCustomModal(
            modalId,
            <RegisterModelModal
              modalId={modalId}
              experimentModel={experimentModel}
              workspace={workspace}
            />
          )
        );
      }}
      data-test="register-model-directory"
    >
      Register
    </TextButton>
  );
};

RegisterModelModalBtn.propTypes = {
  dispatch: PropTypes.func.isRequired,
  experimentModel: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = (state, { match }) => {
  const { workspace } = match.params;

  return {
    workspace
  };
};

export default withRouter(connect(mapStateToProps)(RegisterModelModalBtn));
