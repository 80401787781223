// The opposite of escapeString
export function unescapeString(string) {
  let str = '';
  for (let i = 0; i < string.length; ++i) {
    const ch1 = string[i];
    const ch2 = string[i + 1];
    if (ch1 === '\\' && (ch2 === '[' || ch2 === ']')) {
      // skip
    } else {
      str += ch1;
    }
  }
  return str;
}

export function getTokensList(expression, tokens) {
  if (!tokens) return '';
  const list = [];
  for (let token of tokens) {
    const { start, end } = token;
    list.push('   ' + expression.slice(start, end));
  }
  return list;
}
