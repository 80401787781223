import axios from 'axios';
import { BASE_API_URL } from '../../lib/appConstants';

const organizationApi = {
  createServiceAccount({ organizationId, name, defaultWorkspaceId }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/service-account`;
    return axios.post(url, {
      name,
      defaultWorkspaceId
    });
  },

  fetchServiceAccounts(organizationId) {
    const url = `${BASE_API_URL}organizations/${organizationId}/service-account`;

    return axios.get(url);
  },

  fetchOrganizations() {
    const url = `${BASE_API_URL}organizations`;
    return axios.get(url, {
      params: { withoutExtendedData: true }
    });
  },

  fetchMembers(organizationId) {
    const url = `${BASE_API_URL}organizations/${organizationId}/members`;
    return axios.get(url);
  },

  fetchOrganizationSettings(organizationId, { signal, queryParams }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/settings${
      queryParams || ''
    }`;
    return axios.get(url, { signal });
  },

  fetchOrganizationSetting(organizationId, { signal, settingName }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/setting/name=${settingName}`;
    return axios.get(url, { signal });
  },

  regenerateApiKey({ organizationId, name }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/service-account/${name}/regenerate-api-key`;
    return axios.post(url);
  },

  deleteServiceAccount({ organizationId, name }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/service-account/${name}`;
    return axios.delete(url);
  },

  setMemberRole({ organizationId, username, role }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/members/${username}`;
    return axios.patch(url, { role });
  },

  updateDefaultOrganizationSettings({ value, name, organizationId }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/default-setting`;
    return axios.post(url, { name, value });
  },

  removeMember({ organizationId, username }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/members/${username}`;
    return axios.delete(url);
  },

  removeInvitation({ organizationId, email }) {
    const url = `${BASE_API_URL}organizations/${organizationId}/un-invite`;
    return axios.post(url, {
      email
    });
  },

  fetchOrganization(organizationId) {
    const url = `${BASE_API_URL}organizations/${organizationId}`;
    return axios.get(url);
  },

  fetchOrganizationInvitedUsers(organizationId) {
    const url = `${BASE_API_URL}organizations/${organizationId}/list-email-invites`;
    return axios.get(url);
  },

  changeOrganizationName(organizationId, newOrganizationName) {
    const url = `${BASE_API_URL}organizations`;
    return axios.patch(url, {
      id: organizationId,
      name: newOrganizationName
    });
  },

  fetchWorkspaceUsersPermissions({ workspaceId, signal }) {
    return axios.get(`${BASE_API_URL}permissions/workspace/${workspaceId}`, {
      signal
    });
  },

  updateUsersPermissionsInWorkspace({ workspaceId, usersPermissions }) {
    return axios.post(`${BASE_API_URL}permissions/workspace/users`, {
      workspaceId,
      usersPermissions
    });
  },

  fetchUserOrganizationPermissions({ organizationId, username, signal }) {
    return axios.get(
      `${BASE_API_URL}permissions/organization/${organizationId}/user/${username}`,
      { signal }
    );
  }
};

export default organizationApi;
