import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useCurrentUser from '@API/auth/useCurrentUser';
import { trackEvent } from '@shared/utils/eventTrack';

import userApi from '@/util/userApi';
import { userActionTypes } from '@/constants/actionTypes';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { developerEvents } from '@/constants/trackingEventTypes';

const useUpdateApiKey = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data: user } = useCurrentUser();

  return useMutation(() => userApi.generateApiKey(user.apiKey), {
    onSuccess: res => {
      dispatch({
        type: userActionTypes.RECEIVED_API_KEY,
        apiKey: res.data.apiKey || null
      });

      queryClient.setQueriesData('current-user', oldUser => ({
        ...oldUser,
        apiKey: res.data.apiKey
      }));

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_KEY_GEN,
          'Successfully generated API Key'
        )
      );

      trackEvent(developerEvents.DEVELOPER_GENERATED_API_KEY);
    }
  });
};

export default useUpdateApiKey;
