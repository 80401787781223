import React from 'react';
import PropTypes from 'prop-types';

const isStringStartingWithURLProtocol = str => {
  const protocols = ['http://', 'https://', 'www.'];

  try {
    return protocols.some(protocol => {
      return str.substring(0, protocol.length) === protocol;
    });
  } catch (e) {
    return false;
  }
};

const StringOrLink = ({ str }) => {
  if (isStringStartingWithURLProtocol(str)) {
    const www = 'www';
    const href = str.substring(0, www.length) === www ? `//${str}` : str;

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {str}
      </a>
    );
  }

  return str;
};

StringOrLink.defaultProps = {
  str: ''
};

StringOrLink.propTypes = {
  str: PropTypes.string
};

export default StringOrLink;
