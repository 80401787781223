import { EmptyExperimentTab } from '@shared';
import React from 'react';

const OutputTabEmpty = () => {
  return (
    <EmptyExperimentTab>
      <EmptyExperimentTab.Icon iconName="output" />
      <EmptyExperimentTab.Title>
        This experiment did not log any output
      </EmptyExperimentTab.Title>
      <EmptyExperimentTab.Description>
        Enable <b>auto_output_logging</b> on your Experiment object to log
        output during experiments.
      </EmptyExperimentTab.Description>
      <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentinit" />
    </EmptyExperimentTab>
  );
};

export default OutputTabEmpty;
