import { Table } from '@DesignSystem/tables';
import React from 'react';
import {
  SYSTEM_METRICS_COLUMNS_NAME,
  SYSTEM_METRICS_TABLE_COLUMNS
} from './systemMetricsSingleConfig';
import SystemMetricSingleTableCell from './cells/SystemMetricSingleTableCell';
import { SystemMetrics } from '@shared/types/systemMetrics';
import useSystemMetricsSingleTableColumnWidths from './hooks/useSystemMetricsSingleTableColumnWidths';
import useSystemMetricsSingleTableData from './hooks/useSystemMetricsSingleTableData';
import useSystemMetricsTableSorting from '../hooks/useSystemMetricsTableSorting';
import SystemMetricsEmptyTable from '../SystemMetricsEmptyTable';

const disabledConfig = {
  isDisabled: true
};
const dataTypes = [
  {
    cols: [SYSTEM_METRICS_COLUMNS_NAME.KEY, SYSTEM_METRICS_COLUMNS_NAME.VALUE],
    cell: SystemMetricSingleTableCell
  }
];

type SystemMetricsSingleTableProps = {
  systemMetrics: SystemMetrics;
  isLoading: boolean;
};
const SystemMetricsSingleTable: React.FC<SystemMetricsSingleTableProps> = ({
  systemMetrics,
  isLoading
}) => {
  const {
    columnWidths,
    onColumnWidthsChange
  } = useSystemMetricsSingleTableColumnWidths();
  const { rowList } = useSystemMetricsSingleTableData({ systemMetrics });
  const sortingConfig = useSystemMetricsTableSorting();

  return (
    <Table
      dataTypes={dataTypes}
      columns={SYSTEM_METRICS_TABLE_COLUMNS}
      rows={rowList}
      rowIdKey="key"
      height="auto"
      dataTest="system-metrics-single-table"
      isFetching={isLoading}
      paginationConfig={disabledConfig}
      columnOrderConfig={disabledConfig}
      editingConfig={disabledConfig}
      selectionConfig={disabledConfig}
      columnWidthsConfig={{
        isDisabled: false,
        columnWidths,
        onColumnWidthsChange
      }}
      sortingConfig={sortingConfig}
      renderEmptyState={SystemMetricsEmptyTable}
    />
  );
};

export default SystemMetricsSingleTable;
