import React from 'react';

import classNames from './DAGContent.module.scss';

export function ButtonTooltipContent() {
  return (
    <div className={classNames.tooltipButtonWrapper}>
      <div className={classNames.tooltipButtonTitle}>
        In order to save you need to:
      </div>
      <p className={classNames.tooltipButtonItemList}>1. Define DAG name</p>
      <p className={classNames.tooltipButtonItemList}>
        2. Define at least one model input
      </p>
      <p className={classNames.tooltipButtonItemList}>
        3. Define one model output
      </p>
    </div>
  );
}
