import useCurrentUser from '@API/auth/useCurrentUser';
import { useMutation, useQueryClient } from 'react-query';
import userApi from '@/util/userApi';

const useUpdateProjectViewMutation = () => {
  const { data: currentUser } = useCurrentUser();
  const username = currentUser?.username;
  const queryClient = useQueryClient();

  return useMutation(
    config => userApi.updateUserFlags({ flags: config, username }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['userSettings']);
      }
    }
  );
};

export default useUpdateProjectViewMutation;
