import { useEffect, useState } from 'react';

export function useAnnotationsShapes(annotations, labelColorMap) {
  const [boxes, setBoxes] = useState([]);
  const [polygons, setPolygons] = useState([]);

  // assign color to each annotation by its label
  useEffect(() => {
    setBoxes([]);
    setPolygons([]);

    if (annotations) {
      annotations.forEach(annot => {
        annot.boxes?.forEach(box => {
          const color = labelColorMap[annot.label];
          const b = { size: box, color };
          setBoxes(prev => [...prev, b]);
        });

        annot.points?.forEach(points => {
          const color = labelColorMap[annot.label];
          const polygon = { points, color };
          setPolygons(prev => [...prev, polygon]);
        });
      });
    }
  }, [annotations, labelColorMap]);

  return { boxes, polygons };
}
