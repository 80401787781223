// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Canvas-module__canvas--kzbxT{width:100%;object-fit:contain;position:absolute;height:100%;top:0;left:0;z-index:50;bottom:0;margin:auto 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": `Canvas-module__canvas--kzbxT`
};
module.exports = ___CSS_LOADER_EXPORT___;
