import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from '../ModelRegistryWebhooksTab.module.scss';
import { IconButton, TextButton } from '@ds';
import typography from '@ds-typography';
import cx from 'classnames';
import {
  DSCheckMarkIcon,
  DSCloseIcon,
  DSCopyIcon,
  DSErrorIcon
} from '@ds-icons';
import { WebhookNotifierResponse, WebhookStatus } from '@model-registry/types';
import { Popover } from '@material-ui/core';
import Snackbar from '@design-system-outdated/components/Snackbar';
import {
  buildWebhookNotifierSummary,
  transformHttpToCurlResponse
} from './helper';

interface WebhookTestPopoverProps {
  response: WebhookNotifierResponse;
  anchorEl: HTMLElement | null;
  url: string;
  onClose: () => void;
  hideStatusIcon?: boolean;
  anchorOrigin: {
    vertical: 'top' | 'center' | 'bottom' | number;
    horizontal: 'left' | 'center' | 'right' | number;
  };
  transformOrigin: {
    vertical: 'top' | 'center' | 'bottom' | number;
    horizontal: 'left' | 'center' | 'right' | number;
  };
}

const SUCCESS_TIMEOUT = 3000;

const WebhookResponsePopover: React.FC<WebhookTestPopoverProps> = ({
  response,
  onClose,
  hideStatusIcon = false,
  url,
  anchorEl,
  anchorOrigin,
  transformOrigin
}) => {
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  useEffect(() => {
    if (showSuccessIcon) {
      const timer = setTimeout(() => {
        setShowSuccessIcon(false);
        setIsSnackbarOpen(false);
      }, SUCCESS_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [showSuccessIcon]);

  const isError = response.status !== WebhookStatus.SUCCESS;

  const renderTitle = () => {
    if (hideStatusIcon) {
      return isError ? 'Test failed' : 'Test success';
    }
    return (
      <>
        {isError ? <DSErrorIcon /> : <DSCheckMarkIcon />}
        {isError ? 'Test failed' : 'Test success'}
      </>
    );
  };

  const handleCopyClick = () => {
    setShowSuccessIcon(true);
    setIsSnackbarOpen(true);
  };

  const summaryContentText = buildWebhookNotifierSummary(response, url);

  return (
    <>
      <Snackbar
        onClose={() => setIsSnackbarOpen(false)}
        open={isSnackbarOpen}
        message="Copied response to clipboard"
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        elevation={0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: classNames.modelRegistryWebhooksPopoverPaper }}
      >
        <div
          data-test="webhook-test-popover"
          className={classNames.modelRegistryWebhooksPopoverContainer}
        >
          <div className={classNames.modelRegistryWebhooksPopoverHeader}>
            <div
              className={cx(
                typography.dsBody,
                typography.dsAccented,
                classNames.modelRegistryWebhooksPopoverTitle,
                { [classNames.Error]: isError }
              )}
            >
              {renderTitle()}
            </div>
            <IconButton
              data-test="webhook-test-popover-close-btn"
              type="secondary"
              size="small"
              Icon={<DSCloseIcon />}
              onClick={onClose}
            />
          </div>
          <div
            className={cx(
              typography.dsBodySmall,
              classNames.modelRegistryWebhooksPopoverContentText
            )}
          >
            {summaryContentText}
          </div>
          <CopyToClipboard
            text={
              response.status != WebhookStatus.INTERNAL_ERROR
                ? transformHttpToCurlResponse(response.httpResponse, url)
                : summaryContentText
            }
          >
            <TextButton
              type="secondary"
              onClick={handleCopyClick}
              className={
                classNames.modelRegistryWebhooksPopoverContentTextButton
              }
              size="small"
              label="Copy full response"
              PrefixIcon={
                showSuccessIcon ? <DSCheckMarkIcon /> : <DSCopyIcon />
              }
            >
              Copy full response
            </TextButton>
          </CopyToClipboard>
        </div>
      </Popover>
    </>
  );
};

export default WebhookResponsePopover;
