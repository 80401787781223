import { useMutation, useQueryClient } from 'react-query';

import api from '../api';

import useProject from '../project/useProject';

const setExperimentName = ({ experimentKey, name }) => {
  return api.post('experiment-name/set/name-for-experiment', {
    experimentKey,
    name
  });
};

export default function useSetExperimentNameMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ experimentKey, name }) => setExperimentName({ experimentKey, name }),
    {
      onMutate: async ({ experimentKey }) => {
        await queryClient.cancelQueries(['experiments', { projectId }]);
        await queryClient.cancelQueries(['experiment', { experimentKey }]);
        await queryClient.cancelQueries(['experimentsDetails', { projectId }]);
        await queryClient.cancelQueries(['experimentName', { projectId }]);
      },
      onSuccess: (data, { experimentKey }) => {
        queryClient.invalidateQueries(['experiments', { projectId }]);
        queryClient.invalidateQueries(['experiment', { experimentKey }]);
        queryClient.invalidateQueries(['experimentsDetails', { projectId }]);
        queryClient.invalidateQueries(['experimentName', { projectId }]);
      }
    }
  );
}
