import { useEffect, useMemo, useState, useCallback } from 'react';
import { useQueryString } from 'use-route-as-state';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { add, sub } from 'date-fns';

import {
  DEFAULT_INTERVAL_VALUE,
  INITIAL_QUERY_STATE,
  INTERVAL_TYPE_VALUES,
  PERFORMANCE_DATE_RANGES
} from '../constants';
import {
  getMPMFrom,
  getMPMIntervalType,
  getMPMTo
} from '../../../reducers/ui/mpm';
import mpmActions from '../../../actions/mpmActions';
import {
  formatDateFromCalendar,
  handleIntervalTypeValue,
  handleVersionValue,
  fromUTCToDateWithoutTimezone,
  isValidTimeRangeValues,
  calculateRangeTo,
  calculateRangeFrom
} from '../utils';

export const useMPMPerformanceParams = versions => {
  const [params, updateParams] = useQueryString(INITIAL_QUERY_STATE);
  const [savedDailyHourlyParams, setSavedDailyHourlyParams] = useState(null);
  const [areParamsInitialized, setAreParamsInitialized] = useState(false);

  const storeFrom = useSelector(getMPMFrom);
  const storeTo = useSelector(getMPMTo);
  const storeIntervalType = useSelector(getMPMIntervalType);

  // Use always the same now date while the user is in the performance page
  // @todo: It's still going to change on every time the user moves to another tab
  //        and go back to the performance page, making the panels cache useless
  const now = useMemo(() => new Date().toISOString(), []);

  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  const from = useMemo(() => {
    if (startDate && endDate) {
      return formatDateFromCalendar(startDate, true);
    }

    if (dateRange) {
      return calculateRangeFrom(now, dateRange);
    }

    if (storeFrom) {
      return storeFrom;
    }

    if (params.from) {
      return params.from;
    }

    return formatDateFromCalendar(moment(now), true);
  }, [dateRange, endDate, now, startDate, params?.from, storeFrom]);

  const to = useMemo(() => {
    if (startDate && endDate) {
      return formatDateFromCalendar(endDate, false);
    }
    if (dateRange) {
      return calculateRangeTo(now);
    }

    if (storeTo) {
      return storeTo;
    }

    if (params.to) {
      return params.to;
    }

    return null;
  }, [dateRange, endDate, now, startDate, params?.to, storeTo]);

  useEffect(() => {
    if (areParamsInitialized) {
      dispatch(
        mpmActions.setTimeRange({
          from,
          to
        })
      );
    }
  }, [from, to, dispatch, areParamsInitialized]);

  useEffect(() => {
    if (areParamsInitialized) {
      dispatch(mpmActions.setIntervalType(params?.intervalType));
    }
  }, [params?.intervalType, dispatch, areParamsInitialized]);

  useEffect(() => {
    if (from && to && areParamsInitialized) {
      updateParams(prevParams => ({
        ...prevParams,
        from,
        to
      }));
    }
  }, [from, to, updateParams, areParamsInitialized]);

  useEffect(() => {
    // put some value to params if date range is not set
    if ((!params.from && !from) || (!params?.to && !to)) {
      setDateRange(DEFAULT_INTERVAL_VALUE);
    }
  }, [params?.from, from, params?.to, to]);

  // INIT AND VALIDATIONS
  useEffect(() => {
    if (!areParamsInitialized && from && to && now) {
      const result = {
        ...INITIAL_QUERY_STATE,
        ...params
      };

      result.from = from;
      result.to = to;
      result.intervalType = handleIntervalTypeValue(
        storeIntervalType || result.intervalType
      );

      if (!isValidTimeRangeValues(result)) {
        result.from = calculateRangeFrom(now);
        result.to = calculateRangeTo(now, false);
      }

      result.version = handleVersionValue(result.version, versions);

      updateParams(result);
      setAreParamsInitialized(true);
    }
  }, [
    areParamsInitialized,
    from,
    to,
    updateParams,
    versions,
    params,
    now,
    storeIntervalType
  ]);

  const calcMaxDate = start => {
    const currentTime = new Date(now);

    const maxInterval = PERFORMANCE_DATE_RANGES[params?.intervalType][2];

    const localMaxDate = add(start, {
      days: maxInterval
    });

    const isAllowedTime = localMaxDate <= currentTime;

    if (isAllowedTime) {
      return localMaxDate;
    }
    return currentTime;
  };

  const isEndDateToday = useMemo(
    () => (end, start) => end?.toDateString() === start?.toDateString(),
    []
  );

  const handleNewRange = useCallback(
    ([start, end]) => {
      setDateRange(null);
      setStartDate(start);
      if (
        isEndDateToday(end, start) &&
        params?.intervalType === INTERVAL_TYPE_VALUES.DAILY
      ) {
        setEndDate(
          add(start, {
            days: 1
          })
        );
      } else {
        setEndDate(end);
      }
    },
    [params?.intervalType, isEndDateToday]
  );

  const handleUpdateIntervalType = intervalType => {
    if (params.intervalType === intervalType) return;
    if (savedDailyHourlyParams?.intervalType !== intervalType) {
      setSavedDailyHourlyParams({
        prevStartDate: startDate,
        prevEndDate: endDate,
        prevDateRange: dateRange,
        prevIntervalType: params?.intervalType
      });
    }

    if (!isValidTimeRangeValues({ ...params, intervalType })) {
      setDateRange(DEFAULT_INTERVAL_VALUE);
      setStartDate(null);
      setEndDate(null);
      updateParams(prevParams => ({ ...prevParams, intervalType }));
      return;
    }
    if (
      isValidTimeRangeValues({ ...params, intervalType }) &&
      intervalType === INTERVAL_TYPE_VALUES.HOURLY
    ) {
      updateParams(prevParams => ({ ...prevParams, intervalType }));
      return;
    }
    if (
      intervalType === INTERVAL_TYPE_VALUES.DAILY &&
      dateRange !== DEFAULT_INTERVAL_VALUE
    ) {
      if (dateRange) {
        setDateRange(null);
        setStartDate(sub(new Date(), { days: dateRange }));
        setEndDate(new Date());

        updateParams(prevParams => ({ ...prevParams, intervalType }));
      } else updateParams(prevParams => ({ ...prevParams, intervalType }));

      return;
    }
    if (savedDailyHourlyParams) {
      const {
        prevStartDate,
        prevEndDate,
        prevDateRange
      } = savedDailyHourlyParams;

      setStartDate(prevStartDate);
      setEndDate(prevEndDate);
      setDateRange(prevDateRange);
    } else {
      setDateRange(DEFAULT_INTERVAL_VALUE);
      setStartDate(null);
      setEndDate(null);
    }

    updateParams(prevParams => ({ ...prevParams, intervalType }));
  };

  const handleUpdateVersion = version => {
    updateParams(prevParams => ({ ...prevParams, version }));
  };

  const handledCalendarStartDate =
    startDate ||
    (!dateRange ? fromUTCToDateWithoutTimezone(params.from) : null);

  const handledCalendarEndDate =
    endDate || (!dateRange ? fromUTCToDateWithoutTimezone(params.to) : null);

  return {
    version: handleVersionValue(params.version, versions),
    onUpdateVersion: handleUpdateVersion,

    // time values
    calcMaxDate,
    from: params.from,
    to: params.to,
    intervalType: params.intervalType,
    dateRange,
    setStartDate,
    setEndDate,
    setDateRange,
    onUpdateIntervalType: handleUpdateIntervalType,
    onChangeRange: handleNewRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  };
};
