import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton } from '@ds';

import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

import Tooltip from '@material-ui/core/Tooltip';
import BaseSharePanelModal from '@experiment-management-shared/components/BaseSharePanelModal/BaseSharePanelModal';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { DSCopyIcon } from '@ds-icons';

const SharePanelModal = props => {
  const dispatch = useDispatch();
  const {
    chartId,
    instanceId,
    isCustomPanel,
    projectId,
    templateId,
    viewId
  } = props;
  const queryParameters = queryString.stringify(
    {
      chartId: isCustomPanel ? null : chartId,
      instanceId,
      projectId,
      templateId,
      viewId
    },
    {
      skipEmptyString: true,
      skipNull: true
    }
  );
  const pathURL = isCustomPanel ? '/embedded' : '/embedded-panel';
  const embeddedPanelURL = `${window.location.origin}${pathURL}/?${queryParameters}`;

  const handleClick = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_SHARE_LINK,
        'Successfully copied share panel link to clipboard'
      )
    );
  };

  return (
    <BaseSharePanelModal {...props} title="Share Panel">
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Panel URL</InputLabel>

        <div className="share-panel-link">
          <InputBase
            className="modal-input-base share-panel-link-input"
            value={embeddedPanelURL}
            readOnly
          />
          <Tooltip
            aria-label="copy"
            placement="bottom"
            title="Copy Share Panel URL to clipboard"
          >
            <div>
              <CopyToClipboard text={embeddedPanelURL}>
                <IconButton Icon={<DSCopyIcon />} onClick={handleClick} />
              </CopyToClipboard>
            </div>
          </Tooltip>
        </div>
      </div>
    </BaseSharePanelModal>
  );
};

SharePanelModal.propTypes = {
  chartId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
  isCustomPanel: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  viewId: PropTypes.string.isRequired
};

export default SharePanelModal;
