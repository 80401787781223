import { TextButton } from '@ds';
import typography from '@ds-typography';
import React, { useCallback, useEffect, useState } from 'react';
import { BETA_FEATURES_DOWNLOAD_REPORTS } from '@/lib/betaFeatures';
import { useOrganizationNameMutation } from '@account-settings/api';
import { bytesToGB, getAvailableStorage } from '@account-settings/utils';
import { useCurrentOrganization } from '@shared/hooks';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';

import { Input } from '@DesignSystem/controllers';
import { Divider } from '@DesignSystem/data-display';
import { BasicModal } from '@DesignSystem/modals';
import { SimpleCard } from '@DesignSystem/surfaces';

import './general.scss';
import AdminSettingsBillingPlan from './AdminSettingsBillingPlan/AdminSettingsBillingPlan';

const General = () => {
  const currentOrganization = useCurrentOrganization();
  const organizationNameMutation = useOrganizationNameMutation(
    currentOrganization?.id
  );
  const shouldDisplayDownloadReports = useBetaFeatureEnabled(
    BETA_FEATURES_DOWNLOAD_REPORTS
  );

  const [newOrganizationName, setNewOrganizationName] = useState(
    currentOrganization?.name
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleNameClick = useCallback(() => {
    setNewOrganizationName(currentOrganization?.name);
    setIsDialogOpen(true);
  }, [currentOrganization?.name]);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleDialogSave = useCallback(() => {
    organizationNameMutation.mutate({
      organizationName: newOrganizationName
    });
  }, [organizationNameMutation, newOrganizationName]);

  const handleOrganizationNameChange = useCallback(value => {
    setNewOrganizationName(value);
  }, []);

  useEffect(() => {
    if (organizationNameMutation.isSuccess) {
      setIsDialogOpen(false);
    }
  }, [organizationNameMutation.isSuccess]);

  const renderDownloadReports = () => {
    if (!shouldDisplayDownloadReports) return null;

    return (
      <>
        <div className={typography.dsBody}>
          <div style={{ color: 'var(--gray-6)' }}>User usage report</div>

          <div style={{ color: 'var(--gray-5)', margin: '12px 0 16px' }}>
            The user usage report provides detailed usage data for each user
            over the past year, broken down by month. It includes metrics like
            UI access days, logged experiments, logged prompts and registered
            models.
          </div>

          {currentOrganization?.id ? (
            <a
              href={`/api/organizations/${currentOrganization?.id}/userUsageReport`}
              target="_blank"
              rel="noreferrer"
            >
              <TextButton type="tertiary" size="medium">
                Download report
              </TextButton>
            </a>
          ) : null}
        </div>

        <div className={typography.dsBody}>
          <div style={{ color: 'var(--gray-6)' }}>Workspace usage report</div>

          <div style={{ color: 'var(--gray-5)', margin: '12px 0 16px' }}>
            The workspace usage report provides detailed usage data for each
            workspace over the past year, broken down by month. It includes
            metrics like logged experiments, logged prompts, registered models
            and related usage metrics.
          </div>

          {currentOrganization?.id ? (
            <a
              href={`/api/organizations/${currentOrganization?.id}/workspaceUsageReport`}
              target="_blank"
              rel="noreferrer"
            >
              <TextButton type="tertiary" size="medium">
                Download report
              </TextButton>
            </a>
          ) : null}
        </div>
      </>
    );
  };

  const renderStorageConsumption = () => {
    const usageInGB = bytesToGB(currentOrganization?.storageUsage);
    const availableGB = getAvailableStorage(currentOrganization);

    return (
      <div>
        <h4>Storage</h4>

        <SimpleCard>
          <div className="overview-card">
            <div className="details-wrapper">
              <p>Used</p>
              <p className="highlight">{usageInGB} GB</p>
            </div>
            {availableGB !== null ? (
              <>
                <Divider orientation="vertical" />
                <div className="details-wrapper">
                  <p>Entitled</p>
                  <p>{availableGB} GB</p>
                </div>
              </>
            ) : null}
          </div>
        </SimpleCard>
      </div>
    );
  };

  return (
    <>
      <div className="general-tab-container">
        <div className="page-title">General</div>

        <div className="general-tab-content">
          <div>
            <div className="org-label">Organization name</div>
            <div className="general-tab-input">
              <Input
                asButton
                onClick={handleNameClick}
                value={currentOrganization?.name}
              />
            </div>
          </div>

          <AdminSettingsBillingPlan />

          {renderDownloadReports()}

          {renderStorageConsumption()}
        </div>
      </div>
      <BasicModal
        open={isDialogOpen}
        type="edit"
        onClose={handleDialogClose}
        title="Organization Name"
        primaryButtonText="Save"
        onPrimaryButtonClick={handleDialogSave}
        isPrimaryBtnLoading={organizationNameMutation.isLoading}
        content={
          <Input
            style={{ width: 340 }}
            value={newOrganizationName}
            onChange={handleOrganizationNameChange}
          />
        }
      />
    </>
  );
};

export default General;
