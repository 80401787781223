import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ds';

import {
  SLACK_INVITE_URL,
  GITHUB_ISSUE_TRACKING_URL,
  ERROR_PAGE_MESSAGES
} from '@/lib/appConstants';

class ErrorPage extends Component {
  renderErrorTitle() {
    const { errorCode, isDefaultError } = this.props;

    if (isDefaultError) {
      return <h1>{ERROR_PAGE_MESSAGES.DEFAULT}</h1>;
    }

    if (ERROR_PAGE_MESSAGES[errorCode]) {
      return <h1>{ERROR_PAGE_MESSAGES[errorCode]}</h1>;
    }

    return (
      <h1>
        {errorCode} - {ERROR_PAGE_MESSAGES.DEFAULT}
      </h1>
    );
  }

  renderContinueBtn() {
    return (
      <Button size="XL" onClick={this.props.handleError}>
        Continue
      </Button>
    );
  }

  render() {
    return (
      <div className="error-container">
        <div className="about-page error-sentry">
          <section className="about-hero">
            <div className="container">
              <div className="row">
                <div className="about-hero-content">
                  {this.renderErrorTitle()}
                  <p>
                    We are sorry for the inconvenience. This error has been
                    reported.
                  </p>
                  <p>
                    If you have any urgent issues, please contact us directly.
                  </p>
                  <p>
                    Submit feature requests in our{' '}
                    <a
                      href={GITHUB_ISSUE_TRACKING_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Github repo
                    </a>
                    .{' '}
                    <a
                      href={SLACK_INVITE_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join our slack channel
                    </a>{' '}
                    for help with bugs + questions
                  </p>
                  {this.props.hasContinueBtn ? this.renderContinueBtn() : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

ErrorPage.defaultProps = {
  errorCode: '404',
  isDefaultError: false,
  hasContinueBtn: false,
  handleError: () => {}
};

ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  hasContinueBtn: PropTypes.bool,
  isDefaultError: PropTypes.bool,
  handleError: PropTypes.func
};

export default ErrorPage;
