import React from 'react';

import { Button } from '@ds';
import typography from '@ds-typography';
import { APIKeyInput } from '@shared';
import cx from 'classnames';

import useCurrentUser from '@API/auth/useCurrentUser';

import { useUpdateApiKey } from '@account-settings/api';
import { useIsViewOnlyUser } from '@shared/hooks';

import './APIKeys.scss';
import ManageAPIKey from './ManageAPIKey';

const APIKeys = () => {
  const { data: user } = useCurrentUser();
  const updateApiKeyMutation = useUpdateApiKey();
  const isViewOnlyUser = useIsViewOnlyUser();

  if (!user) {
    return null;
  }

  return (
    <div className={cx('api-keys-container', typography.dsBody)}>
      <h4 className="page-title">API Keys</h4>

      <p className="field-label">
        API Keys are used to log experiments as well as authenticate to Comet
        APIs. Each user has a single API key that can be used across all
        workspaces and organizations they are a member of.
      </p>

      {!isViewOnlyUser && (
        <p className="api-input-container">
          <APIKeyInput
            title="Your Current API Key"
            CopyComponent={
              <Button size="large" className="key-copy-button">
                Copy
              </Button>
            }
          />
        </p>
      )}

      {!isViewOnlyUser && (
        <ManageAPIKey
          onGenerateKey={updateApiKeyMutation.mutateAsync}
          isGenerating={updateApiKeyMutation.isLoading}
        />
      )}

      {isViewOnlyUser && (
        <p className="field-label view-only-label">
          As a view only user, you do not have an API key. If you wish to log
          data to the Comet platform, you can reach out to your Comet Admin and
          ask them to update your organization role to &quot;Member&quot; or
          &quot;Admin&quot;.
        </p>
      )}
    </div>
  );
};

export default APIKeys;
