import { useMemo } from 'react';
import useProjectTags from '@API/project/useProjectTags';
import find from 'lodash/find';

export type ExperimentTagData = {
  color: string;
  name: string;
};

type UseExperimentTagsOptions = {
  filteredList?: string[];
  maxVisible?: number | null;
};

const useExperimentTags = ({
  filteredList = []
}: UseExperimentTagsOptions): ExperimentTagData[] => {
  const { data: projectTags, isLoading, error } = useProjectTags();

  const tagList = useMemo(() => {
    if (isLoading || error) {
      return [];
    }

    const sortedFilteredTags = [...filteredList]
      .sort()
      .map(tagName => find(projectTags, { name: tagName }))
      .filter(Boolean) as ExperimentTagData[];

    return sortedFilteredTags;
  }, [filteredList, projectTags, isLoading, error]);

  return tagList;
};

export default useExperimentTags;
