import React from 'react';
import EmptyArtifactsTabIcon from '../icons/EmptyArtifactsTabIcon.svg';
import EmptyAssetsTabIcon from '../icons/EmptyAssetsTabIcon.svg';
import EmptyAudioTabIcon from '../icons/EmptyAudioTabIcon.svg';
import EmptyCodeTabIcon from '../icons/EmptyCodeTabIcon.svg';
import EmptyConfusionMatrixTabIcon from '../icons/EmptyConfusionMatrixTabIcon.svg';
import EmptyGraphDefinitionTabIcon from '../icons/EmptyGraphDefinitionTabIcon.svg';
import EmptyGraphicsTabIcon from '../icons/EmptyGraphicsTabIcon.svg';
import EmptyHistogramsTabIcon from '../icons/EmptyHistogramsTabIcon.svg';
import EmptyHTMLTabIcon from '../icons/EmptyHTMLTabIcon.svg';
import EmptyHyperparamsTabIcon from '../icons/EmptyHyperparamsTabIcon.svg';
import EmptyInstalledPackagesTabIcon from '../icons/EmptyInstalledPackagesTabIcon.svg';
import EmptyMetricsTabIcon from '../icons/EmptyMetricsTabIcon.svg';
import EmptyOtherTabIcon from '../icons/EmptyOtherTabIcon.svg';
import EmptyOutputTabIcon from '../icons/EmptyOutputTabIcon.svg';
import EmptySystemMetricsTabIcon from '../icons/EmptySystemMetricsTabIcon.svg';
import EmptyTextTabIcon from '../icons/EmptyTextTabIcon.svg';

import styles from '../EmptyExperimentTab.module.scss';

const IconMap = {
  artifacts: EmptyArtifactsTabIcon,
  assets: EmptyAssetsTabIcon,
  audio: EmptyAudioTabIcon,
  code: EmptyCodeTabIcon,
  confusionMatrix: EmptyConfusionMatrixTabIcon,
  graphDefinition: EmptyGraphDefinitionTabIcon,
  graphics: EmptyGraphicsTabIcon,
  histograms: EmptyHistogramsTabIcon,
  html: EmptyHTMLTabIcon,
  hyperparams: EmptyHyperparamsTabIcon,
  installedPackages: EmptyInstalledPackagesTabIcon,
  metrics: EmptyMetricsTabIcon,
  other: EmptyOtherTabIcon,
  output: EmptyOutputTabIcon,
  systemMetrics: EmptySystemMetricsTabIcon,
  text: EmptyTextTabIcon
};

type EmptyExperimentTabIconProps = {
  iconName: keyof typeof IconMap;
};
const EmptyExperimentTabIcon: React.FC<EmptyExperimentTabIconProps> = ({
  iconName
}) => {
  const IconComponent = IconMap[iconName];
  return <div className={styles.emptyTabIcon}>{<IconComponent />}</div>;
};

export default EmptyExperimentTabIcon;
