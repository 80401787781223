import { createSelector } from 'reselect';
import omit from 'lodash/omit';
import { modelRegistryActionTypes } from '../constants/actionTypes';
import { trimPrefix } from '../helpers/generalHelpers';

const initialState = {
  map: {},
  notes: {},
  lastModelsFetch: null,
  numberOfRegisteredModels: null,
  isDeleteModelModalOpen: false,
  isEditModelModalOpen: false,
  modelNameValidation: {
    finalModelName: '',
    originalModelName: '',
    validName: true,
    modelNameExists: false
  },
  modelVersionValidation: {
    validVersion: true,
    versionExists: false
  }
};

const modelRegistryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === modelRegistryActionTypes.SET_REGISTERED_MODEL) {
    const { modelId, model } = payload;

    const modelMap = {
      ...state.map,
      [modelId]: { ...state.map[modelId], ...model }
    };

    return {
      ...state,
      map: modelMap
    };
  }

  if (type === modelRegistryActionTypes.DELETE_REGISTERED_MODEL) {
    const { modelId } = payload;

    const mapWithModelIdRemoved = omit(state.map, modelId);

    return {
      ...state,
      map: mapWithModelIdRemoved
    };
  }

  if (type === modelRegistryActionTypes.SET_REGISTERED_MODEL_WITH_NEW_NAME) {
    const { oldModelId, newModelId, model } = payload;
    const mapWithOldModelIdRemoved = omit(state.map, oldModelId);

    const map = {
      ...mapWithOldModelIdRemoved,
      [newModelId]: { ...state.map[oldModelId], ...model }
    };

    return {
      ...state,
      map
    };
  }

  if (type === modelRegistryActionTypes.SET_MODEL_REGISTRY_MAP) {
    const { map } = payload;

    return {
      ...state,
      map,
      lastModelsFetch: Date.now()
    };
  }

  if (type === modelRegistryActionTypes.SET_MODEL_REGISTRY_COUNT) {
    const { numberOfRegisteredModels } = payload;

    return {
      ...state,
      numberOfRegisteredModels
    };
  }

  if (type === modelRegistryActionTypes.SET_MODEL_NAME_VALIDATION) {
    const { modelNameValidation } = payload;

    if (!modelNameValidation) {
      return {
        ...state,
        modelNameValidation: initialState.modelNameValidation
      };
    }

    return {
      ...state,
      modelNameValidation
    };
  }

  if (type === modelRegistryActionTypes.SET_MODEL_VERSION_VALIDATION) {
    const { modelVersionValidation } = payload;

    if (!modelVersionValidation) {
      return {
        ...state,
        modelVersionValidation: initialState.modelVersionValidation
      };
    }

    return {
      ...state,
      modelVersionValidation
    };
  }

  if (type === modelRegistryActionTypes.OPEN_DELETE_MODEL_MODAL) {
    return {
      ...state,
      isDeleteModelModalOpen: true
    };
  }

  if (type === modelRegistryActionTypes.CLOSE_DELETE_MODEL_MODAL) {
    return {
      ...state,
      isDeleteModelModalOpen: false
    };
  }

  if (type === modelRegistryActionTypes.OPEN_EDIT_MODEL_MODAL) {
    return {
      ...state,
      isEditModelModalOpen: true
    };
  }

  if (type === modelRegistryActionTypes.CLOSE_EDIT_MODEL_MODAL) {
    return {
      ...state,
      isEditModelModalOpen: false
    };
  }

  return state;
};

export default modelRegistryReducer;

export const getModelRegistryMap = state => {
  return state.modelRegistry?.map || {};
};
export const getNumberOfRegisteredModels = state =>
  state.modelRegistry.numberOfRegisteredModels;
export const getModelNameValidation = state =>
  state.modelRegistry.modelNameValidation;

export const getModelVersionValidation = state =>
  state.modelRegistry.modelVersionValidation;

const getWorkspaceName = (_, props) => props.workspace;

export const getRegistryModelsArray = createSelector(
  [getModelRegistryMap],
  map => Object.values(map)
);

export const getModelRegistryNames = createSelector(
  [getModelRegistryMap, getWorkspaceName],
  (map, workspaceName) => {
    return Object.keys(map).map(modelId =>
      trimPrefix(modelId, `${workspaceName}-`)
    );
  }
);

export const getLastRegistryModelsFetch = state =>
  state.modelRegistry.lastModelsFetch;
