import { IconButton, Tooltip } from '@ds';
import PropTypes from 'prop-types';
import React from 'react';
import { DSCopyIcon } from '@ds-icons';

const DuplicateReportButton = ({ canEdit, onDuplicate }) => {
  return (
    <Tooltip content="Duplicate" placement="bottom" arrow={false}>
      <IconButton
        type="secondary"
        onClick={onDuplicate}
        disabled={!canEdit}
        Icon={<DSCopyIcon />}
      />
    </Tooltip>
  );
};

DuplicateReportButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  iconStyles: PropTypes.object,
  onDuplicate: PropTypes.func.isRequired
};

export default DuplicateReportButton;
