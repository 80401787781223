import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { PredicateQuery } from '@mpm-druid/types';

type ValidatePredicatesParams = {
  predicates: PredicateQuery[];
};

type ValidatePredicatesProps = {
  modelId: string;
  predicates: PredicateQuery[];
};

export type ValidatePredicatesResponse = {
  validation: {
    [key: string]: PredicateQuery;
  };
  predicatesBatchId: string | null;
};

const validatePredicates = ({ ...params }: ValidatePredicatesProps) =>
  api.post(`/mpm/v2/predicates`, params);

export function useValidatePredicates(params: ValidatePredicatesParams) {
  const { modelId } = useParams<{ modelId: string }>();
  const { predicates } = params;

  return useMutation(() => validatePredicates({ predicates, modelId }));
}
