import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import modelRegistryApi from '../../util/modelRegistryApi';
import { modelRegistryActionTypes } from '../../constants/actionTypes';

const getModelId = (workspaceName, modelName) =>
  `${workspaceName}-${modelName}`;

const getModelRegistry = async (workspace, modelName) => {
  const { data = {} } =
    (await modelRegistryApi.fetchRegisteredModel(workspace, modelName)) || {};

  return data;
};

const useModelRegistry = ({ workspace, modelName }, config) => {
  const configEnabled = config?.enabled ?? true;
  const dispatch = useDispatch();

  return useQuery(
    ['modelRegistry', { workspace, modelName }],
    () => getModelRegistry(workspace, modelName),
    {
      enabled: configEnabled && !!modelName && !!workspace,
      refetchOnMount: true,
      onSuccess: model => {
        dispatch({
          type: modelRegistryActionTypes.SET_REGISTERED_MODEL,
          payload: {
            modelId: getModelId(workspace, model.modelName),
            model
          }
        });
      }
    }
  );
};

export default useModelRegistry;
