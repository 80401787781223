import React from 'react';
import { Redirect, useParams } from 'react-router';
import useProject from '@API/project/useProject';
import ProjectTypePageSelector from '@shared/components/ProjectTypePageSelector';
import ProjectPageWrapper from '@shared/components/ProjectPageWrapper';
import SingleExperimentDetails from '../ExperimentDetailWrappers/SingleExperimentDetails';
import { PROJECT_TYPE } from '@/constants/projectConstants';
import { LLM_TAB_PATHNAME } from '@experiment-management-shared/constants/llmConstants';
import ExperimentDetailViewConfiguration from './ExperimentDetailViewConfiguration';
import ExperimentDetailsNavBar from '../ExperimentDetailsNavBar/ExperimentDetailsNavBar';

const SingleExperimentDetailsRoute = () => {
  const { workspace, projectName, experimentKey } = useParams<{
    workspace: string;
    projectName: string;
    experimentKey: string;
  }>();
  const { data: project } = useProject();

  if (project?.type === PROJECT_TYPE.LLM) {
    return (
      <Redirect
        to={`/${workspace}/${projectName}/${LLM_TAB_PATHNAME.prompts}?promptId=${experimentKey}`}
      />
    );
  }

  return (
    <ProjectTypePageSelector
      EMComponent={
        <ProjectPageWrapper
          header={
            <ExperimentDetailsNavBar
              subheaderRightSection={
                <ExperimentDetailViewConfiguration
                  experimentKey={experimentKey}
                />
              }
            />
          }
          content={<SingleExperimentDetails />}
        />
      }
    />
  );
};

export default SingleExperimentDetailsRoute;
