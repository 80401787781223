import React from 'react';
import Tooltip, {
  TooltipProps
} from '../../../components/tooltips/Tooltip/Tooltip';

type ConditionalTooltipProps = Omit<TooltipProps, 'content'> & {
  content?: React.ReactNode;
};
const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
  children,
  content,
  ...otherProps
}) => {
  if (!content) {
    return children;
  }

  return (
    <Tooltip content={content} {...otherProps}>
      {children}
    </Tooltip>
  );
};

export default ConditionalTooltip;
