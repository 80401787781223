import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { ACADEMIC_SIGNUP_FORM } from '@/constants/formTypes';

const selector = formValueSelector(ACADEMIC_SIGNUP_FORM);

const mapStateToProps = state => {
  const { email, position, isPublishing } = selector(
    state,
    'email',
    'position',
    'isPublishing'
  );
  return {
    email,
    position,
    isPublishing
  };
};

const AcademicSignupRedux = connect(mapStateToProps);

export default AcademicSignupRedux;
