/**
 * Created by Will on 30/3/2018.
 */
import React from 'react';
import './About.scss';

export default function About() {
  return (
    <div className="about-page">
      <section className="about-hero">
        <div className="container">
          <div className="row">
            <div className="about-hero-content">
              <h1>About us</h1>
              <p>
                Comet is doing for Machine Learning what GitHub did for code. We
                allow data science teams to automagically track their datasets,
                code changes, experimentation history and models creating
                efficiency, transparency, and reproducibility. Our vision is to
                allow every company to harness the power of AI.
              </p>
              <p>
                Comet.ml is the first platform built for ML that enables
                engineers and data scientists to efficiently maintain their
                preferred workflow and tools, while easily tracking previous
                work and collaborating throughout the iterative process.
                Comet.ml also optimizes models with bayesian hyperparameter
                optimization - a type of algorithm - which saves time typically
                spent on manual tuning ML models. As a result, users have
                increased visibility of data science, ML results, and progress
                throughout an organization.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="team">
        <div className="container">
          <h2>Our Team</h2>
          <div className="profiles">
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Gideon.jpg')"
                }}
              />
              <h3>Gideon Mendels</h3>
              <h4>CEO & Co-Founder</h4>
              <p>
                Gideon is an experienced data scientist and entrepreneur. He
                worked on Deep Learning research at Google and Columbia
                University and previously co-founded Groupwize.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Nimrod.jpg')"
                }}
              />
              <h3>Nimrod Lahav</h3>
              <h4>CTO & Co-Founder</h4>
              <p>
                Nimrod is an experienced technology leader and open source
                contributor. He previously led teams at Wix.com, vmware and
                co-founded Groupwize.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Camden.jpg')"
                }}
              />
              <h3>Camden Rudisill</h3>
              <h4>Software Engineer</h4>
              <p>
                Camden is a self-taught full-stack developer who previously
                worked at Planet Labs. He loves building developer tools and
                enhancing user experience.
              </p>
            </div>

            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Dhruv.png')"
                }}
              />
              <h3>Dhruv Nair</h3>
              <h4>Data Scientist</h4>
              <p>
                Dhruv is a Data Scientist, with a background in micro and
                nanoscale device physics. He has extensive R&D experience, and
                has worked on thin film photovoltaics, autonomous environmental
                sensing, and computer vision.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Chris.png')"
                }}
              />
              <h3>Chris Robert</h3>
              <h4>Senior Back End Engineer</h4>
              <p>
                Chris is a Senior Backend Engineer who has previously worked at
                Youtube, and lead the Machine Learning Platform team at Workday.
                He strongly believes that Comet&apos;s product is something that
                the industry needs.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Doug.png')"
                }}
              />
              <h3>Douglas Blank</h3>
              <h4>Head of Research</h4>
              <p>
                Dr. Blank was a professor of Computer Science at Bryn Mawr
                College before joining Comet. Doug has 25 years of experience in
                Deep Learning and Robotics, and is a contributor to the open
                source Jupyter Project, a core tool in Data Science.
              </p>
            </div>

            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Boris.png')"
                }}
              />
              <h3>Boris Feld</h3>
              <h4>Senior Software Engineer</h4>
              <p>
                Boris is a Software Engineer that have sailed between the worlds
                of infrastructure and development tooling, always with a focus
                on quality and performance. He has previously managed the
                infrastructure of a Machine-Learning shop and has several years
                of open-source contribution under his belt, both as external
                contributor and as a Mercurial core contributor.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Thales.png')"
                }}
              />
              <h3>Thales Ceolin</h3>
              <h4>DevOps Engineer</h4>
              <p>
                Thales Ceolin is a Senior DevOps Enigneer working for Comet,
                with a background in Python Development, Linux SysAdmin, Cloud
                Infrastructure Automation as well as building software lifecycle
                pipelines. He previously worked for Financial Times, Razorfish
                and Acrobatiq.
              </p>
            </div>

            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Sarah.png')"
                }}
              />
              <h3>Sarah Herr</h3>
              <h4>Software Engineer</h4>
              <p>
                Sarah is a fullstack engineer who has previously worked in
                enterprise technology. She loves helping people make their
                development process more efficient.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Fernando.png')"
                }}
              />
              <h3>Fernando Carril</h3>
              <h4>Software Engineer</h4>
              <p>
                Fernando is a Software Engineer with a strong background in
                JavaScript, focused on quality and performance. He loves
                enhancing the user experience and building a better web for
                everyone.
              </p>
            </div>
            <div className="team-member">
              <div
                className="headshot"
                style={{
                  backgroundImage: "url('images/team-images/Niko.png')"
                }}
              />
              <h3>Niko Laskaris</h3>
              <h4>Customer Facing Data Scientist</h4>
              <p>
                Niko is a data scientist working with Comet&apos;s great
                enterprise customers. Before joining Comet, he worked on climate
                research at Yale University, applied computer vision for logo
                detection in images, and founded an educational consulting
                company in Vermont.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="investors">
        <div className="container">
          <h2>Investors</h2>
          <div className="logos row">
            <div className="col-md-2 col-sm-4 col-xs-6">
              <img
                src="images/investor-logos/trilogy.jpg"
                alt="Trilogy Equity"
              />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <img
                src="images/investor-logos/two-sigma.png"
                alt="Two Sigma Ventures"
              />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <img
                src="images/investor-logos/founders-co-op.png"
                alt="Founders' Co-op"
              />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <img src="images/investor-logos/fathom.jpg" alt="Fathom." />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6 techstars">
              <img src="images/investor-logos/techstars.png" alt="Techstars" />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6 amazon">
              <img src="images/investor-logos/amazon.svg" alt="Amazon" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
