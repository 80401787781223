import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@ds';
import noop from 'lodash/noop';
import RuleSummary from '@shared/components/QueryFiltersBody/subcomponents/RuleSummary';
import { generateNewRule, ISimpleRule } from '@shared/utils/filterHelpers';

import styles from './AddRuleGroup.module.scss';
import { DSPlusIcon } from '@ds-icons';

const AndRulesGroup = ({
  editable,
  ruleIndex,
  columns,
  rules = [],
  onChange,
  constructFilterDefinition,
  getOperatorsForRule,
  constructRuleLabel
}) => {
  const hasRules = rules.filter(r => !r.isNewRule).length > 0;

  const handleRemoveRule = useCallback(
    index => {
      onChange(
        ruleIndex,
        rules.filter((rule, i) => index !== i)
      );
    },
    [onChange, ruleIndex, rules]
  );

  const handleChangeRule = useCallback(
    (index, rule) => {
      onChange(ruleIndex, [
        ...rules.slice(0, index),
        rule,
        ...rules.slice(index + 1)
      ]);
    },
    [onChange, ruleIndex, rules]
  );

  const handleAddNewRule = useCallback(() => {
    onChange(ruleIndex, [...rules, generateNewRule()]);
  }, [onChange, ruleIndex, rules]);

  const renderRules = () => {
    return rules.map((rule, index) => {
      return (
        <RuleSummary
          // eslint-disable-next-line react/no-array-index-key
          key={`query-element-${index}`}
          columns={columns}
          editable={editable}
          rule={rule}
          ruleIndex={index}
          onChange={handleChangeRule}
          onRemove={handleRemoveRule}
          getOperatorsForRule={getOperatorsForRule}
          constructFilterDefinition={constructFilterDefinition}
          constructRuleLabel={constructRuleLabel}
        />
      );
    });
  };

  const renderAddButton = () => {
    return (
      <div className={styles.buttonWrapper}>
        <TextButton
          size="small"
          disabled={!editable}
          data-test={`and-add-button-${ruleIndex}`}
          onClick={handleAddNewRule}
          PrefixIcon={<DSPlusIcon />}
        >
          AND
        </TextButton>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {hasRules && <div className={styles.decoration} />}
      {renderRules()}
      {hasRules && renderAddButton()}
    </div>
  );
};

AndRulesGroup.defaultProps = {
  editable: undefined,
  constructFilterDefinition: noop,
  constructRuleLabel: undefined
};

AndRulesGroup.propTypes = {
  editable: PropTypes.bool,
  ruleIndex: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  rules: PropTypes.arrayOf(ISimpleRule).isRequired,
  onChange: PropTypes.func.isRequired,
  constructFilterDefinition: PropTypes.func,
  getOperatorsForRule: PropTypes.func.isRequired,
  constructRuleLabel: PropTypes.func
};

export default AndRulesGroup;
