import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import cx from 'classnames';

import { QuickstartGuideIcon } from '@Icons-outdated';
import { Sidebar } from '@DesignSystem/modals';

import ExploreTopics from './ExploreTopics';
import CometResources from './CometResources';
import HelpActions from './HelpActions';
import { useActiveWorkspaceName } from '@shared/hooks';

import styles from './HelpSidebar.module.scss';
import { useIsViewOnlyUser } from '@shared/hooks';

const HelpSidebar = ({ open, onClose }) => {
  const history = useHistory();

  const currentWorkspaceName = useActiveWorkspaceName();
  const isViewOnlyUser = useIsViewOnlyUser();

  const showQuickStartButton = !isViewOnlyUser;

  const handleQuickstartGuideClick = () => {
    history.push(`/${currentWorkspaceName}/quick-start`);
    onClose();
  };

  const renderContent = () => (
    <>
      {showQuickStartButton && (
        <div
          className={styles.quickStartGuideContainer}
          onClick={handleQuickstartGuideClick}
        >
          <div className={cx(styles.helpSidebarFieldTitle)}>
            <QuickstartGuideIcon />
            <span>Quickstart Guide</span>
          </div>
          <div className={styles.helpSidebarSecondaryLabel}>
            A step by step guide for quick Comet integration with your machine
            learning code.
          </div>
        </div>
      )}
      <ExploreTopics noTopMargin={!showQuickStartButton} />
      <CometResources />
      <HelpActions />
    </>
  );

  return (
    <Sidebar
      open={open}
      onClose={onClose}
      content={renderContent()}
      contentClassName={styles.helpSidebarContent}
      title="Support & learn"
      side="right"
    />
  );
};

HelpSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default HelpSidebar;
