import { useEffect, useState } from 'react';
import * as ComLink from 'comlink';
// eslint-disable-next-line import/default
import Worker from '../concat.worker';
import { DATA_PANEL_CONCAT_OPTIONS } from '@experiment-management-shared/constants/chartConstants';

const CONCAT_WORKER = new Worker();
const { concatRows, concatColumns } = ComLink.wrap(CONCAT_WORKER);

const ADVANCED_FEATURES_CONFIG = {
  enablePivot: true,
  enableRowGroup: true,
  enableValue: true
};

const CONCAT_FUNC_BY_AXIS = {
  [DATA_PANEL_CONCAT_OPTIONS.rows]: concatRows,
  [DATA_PANEL_CONCAT_OPTIONS.columns]: concatColumns
};

function useConcatTables({
  areAdvancedFeaturesEnabled,
  tables,
  joinType,
  index,
  axis
}) {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();

  useEffect(() => {
    (async () => {
      if (!tables) return;
      const concatFunc = CONCAT_FUNC_BY_AXIS[axis];

      const { mergedRows, mergedColumns } = await concatFunc({
        tables,
        joinType,
        index
      });

      setColumns(
        mergedColumns.map(field => ({
          field,
          ...(areAdvancedFeaturesEnabled ? ADVANCED_FEATURES_CONFIG : {})
        }))
      );
      setRows(mergedRows);
    })();
  }, [axis, index, joinType, tables]);

  return {
    columns,
    rows
  };
}

export default useConcatTables;
