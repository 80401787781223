import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsNeededToResetLocalChanges } from '@/reducers/experimentDetails/uiReducer';
import uiActions from '@/actions/experimentDetails/uiActions';

// needed to clear out local smoothing
// because for discard changes a template id doesn't change and it
// doesn't cause the reset of the local values
const useIsNeededToResetLocalChanges = ({ setLocalSmoothing }) => {
  const dispatch = useDispatch();
  const isNeededToResetLocalChanges = useSelector(
    getIsNeededToResetLocalChanges
  );

  useEffect(() => {
    if (isNeededToResetLocalChanges) {
      setLocalSmoothing(null);
      dispatch(uiActions.setIsNeededToResetLocalChanges(false));
    }
  }, [isNeededToResetLocalChanges, dispatch, setLocalSmoothing]);
};

export default useIsNeededToResetLocalChanges;
