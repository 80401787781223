import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

type UseCopyToClipboardOpts = {
  delay: number;
};
const useCopyToClipboard = (
  copyText: string,
  options?: UseCopyToClipboardOpts
) => {
  const defaultOptions: UseCopyToClipboardOpts = {
    delay: 2000
  };
  const { delay } = options || defaultOptions;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), delay);
    }
  }, [copied, delay]);

  const onCopy = () => {
    const result = copy(copyText);
    setCopied(result);
  };

  return {
    onCopy,
    copied
  };
};

export default useCopyToClipboard;
