import { Column } from '@devexpress/dx-react-grid';
import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';
import {
  DETAIL_VIEWS,
  DETAIL_VIEW_SETTINGS
} from '@experiment-management-shared/constants/experimentConstants';
import { AllowedCompareKeys } from './types';

export const COMPARISON_TABLE_DISPLAY_CONTENT = {
  [DETAIL_VIEWS.HYPER_PARAMETERS]: {
    name: 'Hyperparameters',
    Icon: DETAIL_VIEW_SETTINGS[DETAIL_VIEWS.HYPER_PARAMETERS].icon,
    viewName: EXPERIMENT_VIEW_TAB_FIELDS.HYPERPARAMS,
    searchFieldPlaceholder: 'param name'
  },
  [DETAIL_VIEWS.METRICS]: {
    name: 'Metrics',
    Icon: DETAIL_VIEW_SETTINGS[DETAIL_VIEWS.SYSTEM_METRICS].icon,
    viewName: EXPERIMENT_VIEW_TAB_FIELDS.METRICS,
    searchFieldPlaceholder: 'metric name'
  },
  [DETAIL_VIEWS.OTHERPARAMS]: {
    name: 'Other',
    Icon: DETAIL_VIEW_SETTINGS[DETAIL_VIEWS.OTHERPARAMS].icon,
    viewName: EXPERIMENT_VIEW_TAB_FIELDS.OTHER,
    searchFieldPlaceholder: 'param name'
  }
};

const KEY_TITLE = 'Name';
const EXPERIMENT_NAME_TITLE = 'Experiment';
const VALUE_TITLE = 'Value';
const MIN_VALUE_TITLE = 'Min Value';
const MAX_VALUE_TITLE = 'Max Value';

export const COMPARISON_COLUMNS_NAME = {
  KEY: 'key',
  EXPERIMENT: 'experiment',
  CURRENT_VALUE: 'valueCurrent',
  MIN_VALUE: 'valueMin',
  MAX_VALUE: 'valueMax'
};

export const DEFAULT_COMPARISON_TABLE_COLUMNS: Column[] = [
  { name: COMPARISON_COLUMNS_NAME.KEY, title: KEY_TITLE },
  { name: COMPARISON_COLUMNS_NAME.EXPERIMENT, title: EXPERIMENT_NAME_TITLE },
  { name: COMPARISON_COLUMNS_NAME.CURRENT_VALUE, title: VALUE_TITLE }
];

export const getAdditionalComparisonColumns = (keys: AllowedCompareKeys[]) => {
  const columnsMap = {
    valueMin: {
      name: COMPARISON_COLUMNS_NAME.MIN_VALUE,
      title: MIN_VALUE_TITLE
    },
    valueMax: {
      name: COMPARISON_COLUMNS_NAME.MAX_VALUE,
      title: MAX_VALUE_TITLE
    }
  } as Record<AllowedCompareKeys, Column>;

  const ADDITIONAL_COMPARISON_COLUMNS = [] as Column[];
  keys.forEach(key => {
    if (columnsMap[key]) {
      ADDITIONAL_COMPARISON_COLUMNS.push(columnsMap[key]);
    }
  });

  return ADDITIONAL_COMPARISON_COLUMNS;
};

export const COMPARISON_VALUE_CELL_KEYS = [
  COMPARISON_COLUMNS_NAME.CURRENT_VALUE,
  COMPARISON_COLUMNS_NAME.MIN_VALUE,
  COMPARISON_COLUMNS_NAME.MAX_VALUE
];
