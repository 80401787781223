import React from 'react';

import {
  useOnboardingFirstClickWholeApp,
  usePageExit
} from '@onboarding/hooks/biEvents';

const OnboardingBiEvents = () => {
  usePageExit();
  useOnboardingFirstClickWholeApp();

  return <></>;
};

export default OnboardingBiEvents;
