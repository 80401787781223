import { AssetData, PCDAssetData } from '@experiment-management-shared/types';
import { transformAssetDataToPCDAsset } from '@experiment-management-shared/utils';
import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

type SearchResultAPI = {
  assets: AssetData[];
};

const getPCDSearch = async ({
  experimentKeys,
  searchPhrase
}: {
  experimentKeys: string[];
  searchPhrase: string;
}) => {
  if (searchPhrase.length < 3 || experimentKeys.length === 0) {
    return [];
  }

  const { data } = await api.post<SearchResultAPI>('asset/search-by-filter', {
    assetType: '3d-image',
    experiments: experimentKeys,
    imageSearchPhrase: searchPhrase,
    onlyLatest: true
  });

  return data.assets.map(asset => transformAssetDataToPCDAsset(asset));
};

type UsePCDSearchParams = {
  experimentKeys: string[];
  searchPhrase: string;
};

export function usePCDSearch(
  { experimentKeys, searchPhrase }: UsePCDSearchParams,
  config?: QueryConfig<PCDAssetData[]>
) {
  return useQuery(
    ['pcdSearch', { experimentKeys, searchPhrase }],
    () => getPCDSearch({ experimentKeys, searchPhrase }),
    config
  );
}
