import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './LegendWrapper.module.scss';

type LegendWrapperProps = {
  children: ReactNode;
  legend: ReactNode;
  chartContainerRef?: React.RefObject<HTMLDivElement>;
};

const LegendWrapper = ({
  children,
  legend,
  chartContainerRef
}: LegendWrapperProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.legendWrapper}>{legend}</div>
      <div
        ref={chartContainerRef}
        className={cx('chart-tooltip-container', styles.chartWrapper)}
      >
        {children}
      </div>
    </div>
  );
};

export default LegendWrapper;
