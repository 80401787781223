import React from 'react';
import { PAYMENT_PLANS } from '@shared/constants';
import { useCurrentPaymentPlan } from '@shared/hooks';

import styles from './AdminSettingsBillingPlan.module.scss';

type BillingPlanTypeProps = {
  planName: string;
  planNameColor?: string;
};
const BillingPlanType: React.FC<BillingPlanTypeProps> = ({
  planName,
  planNameColor
}) => (
  <div className={styles.billingPlanTypeContainer}>
    <b className={styles.billingPlanTypeField}>Plan type:</b>
    <span
      className={styles.billingPlanTypeValue}
      style={{
        color: planNameColor
      }}
    >
      {planName}
    </span>
  </div>
);

const AdminSettingsBillingPlan = () => {
  const { paymentPlanName, isAcademic, isFree } = useCurrentPaymentPlan();

  const currentPaymentPlan = paymentPlanName
    ? PAYMENT_PLANS[paymentPlanName]
    : null;

  if (isFree) {
    return (
      <BillingPlanType
        planName={`Organizations (${isAcademic ? 'Academic' : 'Startup'})`}
      />
    );
  }

  return (
    <BillingPlanType
      planName={currentPaymentPlan?.name || ''}
      planNameColor={currentPaymentPlan?.color}
    />
  );
};

export default AdminSettingsBillingPlan;
