import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import get from 'lodash/get';
import find from 'lodash/find';
import replace from 'lodash/replace';
import orderBy from 'lodash/orderBy';
import Grid from '@material-ui/core/Grid/Grid';
import FilesBrowser from '@experiment-management-shared/components/FilesBrowser';
import Searchbar from './Searchbar';
import Legend from './Legend';
import AssetPreviewContainer from '@experiment-management-shared/components/AssetsPreview/AssetPreviewContainer';
import { isAssetWithPreview } from '@experiment-management-shared/utils/assetsUtils';
import { TableMaxHeightProvider } from '@DesignSystem/tables';

const createRegexExpression = query => {
  try {
    return new RegExp(query);
  } catch (e) {
    return null;
  }
};

export const searchFunction = (text, isRegex, isExactMath) => {
  return item => {
    const lowerCaseText = (text || '').toLowerCase();
    const { fileName, dir } = item;

    if (isRegex) {
      const regexExpression = createRegexExpression(text);
      if (!regexExpression) return false;
      return fileName.match(regexExpression) || dir.match(regexExpression);
    }

    // fix: fileName or dir can be null, then this get an error and page stuck
    const lowerCaseFilename = (fileName || '')?.toLowerCase();
    const lowerCaseDir = (dir || '')?.toLowerCase();

    if (isExactMath) {
      return (
        lowerCaseFilename === lowerCaseText || lowerCaseDir === lowerCaseText
      );
    }

    return (
      lowerCaseFilename.includes(lowerCaseText) ||
      lowerCaseDir.includes(lowerCaseText)
    );
  };
};

const AssetsStorage = ({
  assets,
  location,
  experiment,
  useQueryParams,
  preventQueryParamsUpdate,
  experimentModels,
  showDirActions,
  emptyRowsComponent
}) => {
  const { experimentKey } = experiment;
  const { pathname, search } = location;
  const parsedQuery = queryString.parse(search);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [assetId, setAssetId] = useState(get(parsedQuery, 'assetId', null));
  const [assetPath, setAssetPath] = useState(
    get(parsedQuery, 'assetPath', null)
  );
  const [currentFile, setCurrentFile] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isRegex, setIsRegex] = useState(false);
  const [isExactMatch, setIsExactMatch] = useState(false);

  useQueryParams(
    { assetId, assetPath },
    [assetId, assetPath, pathname],
    experimentKey,
    { preventUpdate: preventQueryParamsUpdate }
  );

  useEffect(() => {
    const currentFile = find(assets, { assetId });

    setCurrentFile(currentFile);
  }, [assetId, assets]);

  const onTextChange = (searchText, isRegex, isExactMatch) => {
    setSearchText(searchText);
    setIsRegex(isRegex);
    setIsExactMatch(isExactMatch);
  };

  const handleOnClick = ({ assetId, dir }) => {
    setIsPreviewOpen(false);
    setAssetId(assetId);

    if (assetId) {
      setAssetPath(replace(dir, '/', ','));
    } else {
      setAssetPath(null);
    }
  };

  const handleViewClick = selectedAssetId => {
    if (selectedAssetId) {
      setAssetId(selectedAssetId);
    }

    setIsPreviewOpen(true);
  };

  const files = useMemo(() => {
    const filter = searchFunction(searchText, isRegex, isExactMatch);

    const filteredAssets = assets.filter(filter);
    const mappedAssets = filteredAssets.map(asset => {
      if (asset.dir === 'others') {
        const pathArr = asset.fileName.split('/');
        const fileName = pathArr.pop();
        const dir = ['others', ...pathArr].join('/');
        return { ...asset, fileName, dir };
      }

      return asset;
    });

    return orderBy(mappedAssets, ['dir']);
  }, [assets, searchText, isRegex, isExactMatch]);

  const getCurrentFilePath = () => {
    if (!assetPath) return [];

    return assetPath.split(',');
  };

  const isSearch = !!searchText;
  const showPreview = isPreviewOpen && isAssetWithPreview(currentFile);
  const hasAssets = Boolean(assets.length);

  return (
    <div className="assets-tab">
      <Grid className="assets-tab-grid" container>
        <Grid item xs={hasAssets ? 9 : 12}>
          <Searchbar
            onTextChange={onTextChange}
            isRegex={isRegex}
            setIsExactMatch={setIsExactMatch}
            setIsRegex={setIsRegex}
            isExactMatch={isExactMatch}
            disabled={!hasAssets}
          />
          <TableMaxHeightProvider>
            {height => (
              <FilesBrowser
                showDirActions={showDirActions}
                experimentModels={experimentModels}
                experimentKey={experimentKey}
                files={files}
                isSearchActive={!!searchText || isExactMatch}
                listContainerHeight={Math.max(height - 160, 500)}
                rowHeight={60}
                width="100%"
                handleOnClick={handleOnClick}
                forceExpansion={isSearch}
                currentSelectedRow={currentFile}
                currentFilePath={getCurrentFilePath()}
                viewClickHandler={handleViewClick}
                emptyRowsComponent={emptyRowsComponent}
              />
            )}
          </TableMaxHeightProvider>
        </Grid>
        {hasAssets && (
          <Grid item xs={3}>
            <Legend
              experimentKey={experimentKey}
              item={currentFile}
              viewClickHandler={() => setIsPreviewOpen(true)}
            />
          </Grid>
        )}
      </Grid>

      {showPreview && (
        <AssetPreviewContainer
          experimentKey={experimentKey}
          file={currentFile}
          closeHandler={() => setIsPreviewOpen(false)}
        />
      )}
    </div>
  );
};

AssetsStorage.defaultProps = {
  assets: [],
  experimentModels: [],
  emptyRowsComponent: null,
  showDirActions: true,
  preventQueryParamsUpdate: false,
  experiment: {
    experimentKey: ''
  }
};

AssetsStorage.propTypes = {
  experiment: PropTypes.shape({
    experimentKey: PropTypes.string
  }),
  emptyRowsComponent: PropTypes.elementType,
  showDirActions: PropTypes.bool,
  experimentModels: PropTypes.array,
  assets: PropTypes.array,
  preventQueryParamsUpdate: PropTypes.bool,
  useQueryParams: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(AssetsStorage);
