import { components } from 'react-select';
import { Box } from '@material-ui/core';
import React from 'react';

import { CrossIcon } from '@Icons-outdated';

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <Box className="sortable-select-multi-value-remove">
      <CrossIcon fontSize="small" />
    </Box>
  </components.MultiValueRemove>
);

export default MultiValueRemove;
