import React from 'react';
import PropTypes from 'prop-types';

const INDENT_WIDTH = 50;

const IndentTree = ({ level, style, ...props }) => {
  const indent = level * INDENT_WIDTH;
  const parentStyle = style || {};

  return <div style={{ width: indent, ...parentStyle }} {...props} />;
};

IndentTree.defaultProps = {
  style: null
};

IndentTree.propTypes = {
  level: PropTypes.number.isRequired,
  style: PropTypes.object
};

export default IndentTree;
