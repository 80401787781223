import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';

import { Button, IconButton, TextButton } from '@ds';

import './BasicModal.scss';
import { DSCloseIcon } from '@ds-icons';

const StyledDialog = withStyles({
  paper: {
    padding: '32px 60px',
    fontSize: '14px',
    lineHeight: '21px'
  }
})(Dialog);

export const BasicModal = ({
  content,
  hideCross,
  isPrimaryBtnLoading,
  isPrimaryButtonDisabled,
  isSecondaryButtonDisabled,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  open,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonType,
  secondaryButtonTooltip,
  styles,
  title,
  type = '',
  className,
  additionalClasses,
  footerActions,
  disableBackdropClick,
  dataTest,
  disableEnforceFocus
}) => {
  const hasFooter = !!(
    primaryButtonText ||
    footerActions?.length ||
    secondaryButtonText
  );

  const renderSecondaryButton = () => {
    const btn = (
      <TextButton
        size="large"
        type={secondaryButtonType || 'tertiary'}
        className="footer-button footer-secondary-button"
        onClick={onSecondaryButtonClick}
        disabled={isSecondaryButtonDisabled}
      >
        {secondaryButtonText}
      </TextButton>
    );

    return secondaryButtonTooltip?.visible ? (
      <Tooltip
        arrow
        placement={secondaryButtonTooltip?.position || 'top'}
        title={secondaryButtonTooltip?.text}
      >
        <span>{btn}</span>
      </Tooltip>
    ) : (
      btn
    );
  };

  const renderFooter = () => {
    if (!hasFooter) {
      return null;
    }

    return (
      <div className="basic-modal-footer">
        {primaryButtonText ? (
          <div className="basic-modal-footer-button basic-modal-footer-primary-button">
            <Button
              size="large"
              data-test={`${dataTest}-primary-button`}
              className="footer-button footer-primary-button"
              onClick={onPrimaryButtonClick}
              disabled={isPrimaryButtonDisabled}
              isLoading={isPrimaryBtnLoading}
            >
              {primaryButtonText}
            </Button>
          </div>
        ) : null}

        {footerActions?.length
          ? footerActions.map(button => (
              <div
                key={button.key}
                className="basic-modal-footer-button basic-modal-footer-additional-button"
              >
                <Button
                  key={button.key}
                  type={button.type || 'primary'}
                  onClick={button.onClick}
                  size={button.size || 'large'}
                  className={cx(
                    'footer-button footer-additional-button',
                    button.className
                  )}
                  disabled={button.disabled}
                >
                  {button.text}
                </Button>
              </div>
            ))
          : null}

        {secondaryButtonText ? (
          <Box
            data-test={`${dataTest}-secondary-button`}
            className={cx(
              'basic-modal-footer-button',
              'basic-modal-footer-secondary-button',
              {
                'basic-modal-secondary-button-margin': !footerActions?.length
              }
            )}
          >
            {renderSecondaryButton()}
          </Box>
        ) : null}
      </div>
    );
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      styles={styles}
      className={cx('ds-basic-modal-paper', className)}
      classes={additionalClasses}
      disableBackdropClick={disableBackdropClick}
      data-test={dataTest}
      disableEnforceFocus={disableEnforceFocus}
    >
      {!hideCross && (
        <IconButton
          onClick={onClose}
          size="XL"
          data-test="modal-close-icon"
          Icon={<DSCloseIcon />}
          className="basic-modal-close-icon"
          type="secondary"
        />
      )}
      <div className={cx('ds-basic-modal', type)}>
        <div className="basic-modal-title">
          <span>{title}</span>
        </div>
        <div
          className={cx('basic-modal-content', {
            'basic-modal-content-with-footer': hasFooter
          })}
        >
          {content}
        </div>
        {renderFooter()}
      </div>
    </StyledDialog>
  );
};

BasicModal.propTypes = {
  styles: PropTypes.shape(),
  additionalClasses: PropTypes.shape(),
  content: PropTypes.node,
  hideCross: PropTypes.bool,
  isPrimaryBtnLoading: PropTypes.bool,
  isPrimaryButtonDisabled: PropTypes.bool,
  isSecondaryButtonDisabled: PropTypes.bool,
  secondaryButtonTooltip: PropTypes.shape({
    text: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired
  }),
  onClose: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  open: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  secondaryButtonType: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  footerActions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
      type: PropTypes.string,
      disabled: PropTypes.bool,
      className: PropTypes.string
    })
  ),
  disableBackdropClick: PropTypes.bool,
  dataTest: PropTypes.string,
  disableEnforceFocus: PropTypes.bool
};

BasicModal.defaultProps = {
  styles: {},
  additionalClasses: {},
  content: null,
  hideCross: false,
  isPrimaryBtnLoading: false,
  isPrimaryButtonDisabled: false,
  isSecondaryButtonDisabled: false,
  secondaryButtonTooltip: null,
  onClose: noop,
  onPrimaryButtonClick: noop,
  onSecondaryButtonClick: noop,
  open: false,
  primaryButtonText: null,
  secondaryButtonText: null,
  secondaryButtonType: null,
  title: '',
  type: 'basic-modal',
  dataTest: 'basic-modal',
  className: '',
  footerActions: [],
  disableBackdropClick: false
};
