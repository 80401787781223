import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ChartHeader from '@experiment-management-shared/components/Charts/Chart/ChartHeader';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';

import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';

import usePanelInstance from '@API/panels/usePanelInstance';
import usePanelTemplate from '@API/panels/usePanelTemplate';

import CustomChart from './CustomChart';
import { getCurrentPanelOptions } from '@/helpers/panelOptions';
import { chartDataErrorTypes } from '@/lib/appConstants';
import { ChartError } from '../ChartError';
import SmallLoader from '@shared/components/SmallLoader';
import { useIsServerCustomPanelsEnabled } from '@experiment-management-shared/hooks';
import { CHART_EXPORT_KEY } from '@experiment-management-shared/components/Charts/Chart/useChartHeaderMenu';

const CUSTOM_CHART_ERROR_HIDE_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  'edit-chart',
  'embed-panel',
  'share-panel',
  'reset-zoom'
];

const CustomChartInstance = ({
  dataPanelsRows,
  instanceId,
  ...CustomChartProps
}) => {
  const {
    data: instance,
    isError: isErrorInstance,
    isLoading
  } = usePanelInstance({
    instanceId
  });
  const { data: template, isError: isErrorTemplate } = usePanelTemplate({
    templateId: instance?.templateId
  });

  const { deleteConfig, onDeleteChart } = CustomChartProps;

  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();
  useEffect(() => {
    const isPy = template?.code.type === PANEL_CODE_LANGUAGES.PYTHON;

    if (isPy && !isServerCustomPanelsEnabled) {
      window.initPyodideWorker(window.location.origin);
    }
  }, [template]);

  const options = useMemo(() => {
    if (!instance || !template) {
      return null;
    }

    return getCurrentPanelOptions({
      instance,
      template,
      revisionId: instance.templateRevisionId,
      isPy: template?.code?.type === PANEL_CODE_LANGUAGES.PYTHON
    });
  }, [instance, template]);

  const renderHeader = () => (
    <ChartHeader
      hiddenMenuItems={CUSTOM_CHART_ERROR_HIDE_MENU_ITEMS}
      onDeleteChart={() => onDeleteChart(deleteConfig)}
      title="Panel"
      hideFullscreenBtn
    />
  );

  const renderChartError = chartError => (
    <div className="chart-data-error-container">
      {renderHeader()}

      {chartError && <ChartError type={chartError} />}
    </div>
  );

  if (isErrorTemplate) {
    return renderChartError(chartDataErrorTypes.NO_PERMISSION_TO_SEE_PANEL);
  }

  if (isErrorInstance) {
    return renderChartError(chartDataErrorTypes.FETCHING_ERROR);
  }

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  return (
    <CustomChart
      dataPanelsRows={dataPanelsRows}
      options={options}
      instance={instance}
      instanceId={instanceId}
      templateId={instance.templateId}
      {...CustomChartProps}
    />
  );
};

CustomChartInstance.propTypes = {
  dataPanelsRows: PropTypes.object.isRequired,
  instanceId: PropTypes.string.isRequired
};

export default React.memo(CustomChartInstance);
