import React from 'react';
import PropTypes from 'prop-types';

import useCurrentUser from '@API/auth/useCurrentUser';

import { Box } from '@material-ui/core';
import DsAccordion from '@design-system-outdated/components/Accordion';
import SyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter';
import EmptyTabMessage from '@model-registry/components/EmptyTabMessage';
import { ifAllAssetsIsRemote } from '@model-registry/utils';
import { BASE_API_URL } from '@/constants/configConstants';
import SmallLoader from '@shared/components/SmallLoader';
import classNames from './ModelVersionApiTab.module.scss';

const ABSOLUTE_API_URL = new URL(BASE_API_URL, window.location.origin).href;
const CODE_TYPES = {
  CODE: 'code',
  COPY: 'copy'
};

const getModelVersionRESTApi = ({
  workspace,
  modelName,
  version,
  isRemoteModel,
  apiKey,
  type = CODE_TYPES.COPY
}) => {
  if (type !== CODE_TYPES.COPY) {
    return !isRemoteModel
      ? `curl \\\n    -H "Authorization: <API_KEY>" \\\n    "${ABSOLUTE_API_URL}rest/v2/registry-model/item/download?workspaceName=${workspace}&modelName=${modelName}&version=${version}" \\\n    > ${modelName}.zip`
      : `curl \\\n    -H "Authorization: <API_KEY>" \\\n    "${ABSOLUTE_API_URL}rest/v2/registry-model/item/download-instructions?workspaceName=${workspace}&modelName=${modelName}&version=${version}" \\\n    > ${modelName}.zip`;
  }

  return !isRemoteModel
    ? `curl -H "Authorization: ${apiKey}" "${ABSOLUTE_API_URL}rest/v2/registry-model/item/download?workspaceName=${workspace}&modelName=${modelName}&version=${version}" > ${modelName}.zip`
    : `curl -H "Authorization: ${apiKey}" "${ABSOLUTE_API_URL}rest/v2/registry-model/item/download-instructions?workspaceName=${workspace}&modelName=${modelName}&version=${version}" > ${modelName}.zip`;
};

const getModelVersionSDK = ({
  workspace,
  modelName,
  version,
  isRemoteModel
}) => {
  return !isRemoteModel
    ? `from comet_ml.api import API\n\napi = API() \napi.download_registry_model("${workspace}", "${modelName}", version="${version}", output_path="./", expand=True, stage=None)`
    : `from comet_ml import API\n\napi = API() \nmodel = api.get_model("${workspace}", "${modelName}") \nmodel.download("${version}", ".")`;
};

const getModelVersionsApiTabInfo = ({
  workspace,
  modelName,
  version,
  apiKey,
  isRemoteModel
}) =>
  [
    !isRemoteModel && {
      name: 'CLI',
      language: 'python',
      code: `comet models download \\\n    --workspace "${workspace}" \\ \n    --model-name "${modelName}" \\ \n    --model-version "${version}"`,
      copy: `comet models download --workspace "${workspace}" --model-name "${modelName}" --model-version "${version}"`
    },
    {
      name: 'REST API',
      language: 'bash',
      code: getModelVersionRESTApi({
        workspace,
        modelName,
        version,
        isRemoteModel,
        apiKey,
        type: CODE_TYPES.CODE
      }),
      copy: getModelVersionRESTApi({
        workspace,
        modelName,
        version,
        isRemoteModel,
        apiKey
      })
    },
    {
      name: 'Python SDK',
      language: 'python',
      code: getModelVersionSDK({
        workspace,
        modelName,
        version,
        isRemoteModel
      }),
      copy: getModelVersionSDK({
        workspace,
        modelName,
        version,
        isRemoteModel
      })
    }
  ].filter(Boolean);

const ModelVersionApiTab = ({
  selectedVersion,
  workspace,
  modelName,
  isEmpty
}) => {
  const { data: { apiKey } = {}, isLoading } = useCurrentUser() || {};
  const isRemoteModel = ifAllAssetsIsRemote(selectedVersion?.assets);

  if (isLoading) {
    return <SmallLoader />;
  }

  if (isEmpty) {
    return <EmptyTabMessage type="api" />;
  }

  return (
    <Box px={5} py={3} className={classNames.modelRegistryVersionApi}>
      {getModelVersionsApiTabInfo({
        workspace,
        modelName,
        version: selectedVersion.version,
        apiKey,
        isRemoteModel
      }).map(({ code, name, copy, language }) => (
        <Box key={name}>
          <DsAccordion
            defaultExpanded
            className={classNames.modelRegistryVersionApi}
            content={
              <SyntaxHighlighter
                language={language}
                className={classNames.modelRegistryVersionApiCode}
                code={copy || code}
                withCopyBtn
              >
                {code}
              </SyntaxHighlighter>
            }
            title={name}
            id="model-registry-version"
          />
        </Box>
      ))}
    </Box>
  );
};

ModelVersionApiTab.defaultProps = {
  selectedVersion: {},
  isEmpty: false
};

ModelVersionApiTab.propTypes = {
  isEmpty: PropTypes.bool,
  selectedVersion: PropTypes.shape({
    experimentModel: PropTypes.shape({
      models: PropTypes.array,
      experimentKey: PropTypes.string
    }),
    autoGenerated: PropTypes.bool,
    stages: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(PropTypes.string),
    restApiUrl: PropTypes.string,
    userName: PropTypes.string,
    comment: PropTypes.string,
    version: PropTypes.string,
    registryModelItemId: PropTypes.string
  }),
  modelName: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired
};

export default ModelVersionApiTab;
