import { AssetStep, VideoAssetData } from '@experiment-management-shared/types';
import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

type VideoAssetsData = {
  videos: Omit<VideoAssetData, 'name'>[];
};

const getVideoPanelData = async ({
  assetNames,
  experimentKeys,
  step
}: {
  assetNames: string[];
  experimentKeys: string[];
  step: AssetStep;
}) => {
  const { data } = await api.post<VideoAssetsData>('video/filter-data', {
    experiments: experimentKeys,
    maxResults: 100,
    step: step || 0,
    videoNames: assetNames
  });

  return data.videos.map(video => ({
    ...video,
    name: video.userFileName
  }));
};

type VideoPanelDataQueryParams = {
  assetNames: string[];
  experimentKeys: string[];
  step: AssetStep;
};

export function useVideoPanelData(
  { assetNames, experimentKeys, step }: VideoPanelDataQueryParams,
  config: QueryConfig<VideoAssetData[]>
) {
  const configEnabled = config?.enabled ?? true;
  const conditionsEnabled = !!assetNames?.length && !!experimentKeys?.length;

  return useQuery(
    [
      'videoPanelData',
      // copy and sort arrays to not trigger request every time order of parameters is changed
      {
        assetNames: assetNames.slice().sort(),
        experimentKeys: experimentKeys.slice().sort(),
        step
      }
    ],
    () => getVideoPanelData({ assetNames, experimentKeys, step }),
    { ...config, enabled: configEnabled && conditionsEnabled }
  );
}
