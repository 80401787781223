import useBaseTrackEvent from './useBaseTrackEvent';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import { useCallback } from 'react';
import { getMinMaxColumnsCount } from '@shared/utils/getMinMaxColumnsCount';
import useTrackEventLocation from './useTrackEventLocation';

type SortColumnsArgs = {
  sortedColumnsCount: number;
};

type ColumnsEventArgs = {
  columnNames: string[];
};

const useTrackTableEventsBI = () => {
  const baseTrackEvent = useBaseTrackEvent();
  const location = useTrackEventLocation();

  const onSortingSendBI = useCallback(
    ({ sortedColumnsCount }: SortColumnsArgs) => {
      baseTrackEvent(dashboardEvents.PROJECT_SORT, {
        location,
        number_of_sort_columns: sortedColumnsCount
      });
    },
    [location, baseTrackEvent]
  );

  const onUpdateColumnsSendBI = useCallback(
    ({ columnNames }: ColumnsEventArgs) => {
      baseTrackEvent(dashboardEvents.PROJECT_UPDATE_COLUMNS, {
        location,
        number_of_columns: columnNames.length,
        number_of_min_max_columns: getMinMaxColumnsCount(columnNames)
      });
    },
    [location, baseTrackEvent]
  );

  const onUpdateGroupsSendBI = useCallback(
    ({ columnNames }: ColumnsEventArgs) => {
      baseTrackEvent(dashboardEvents.PROJECT_UPDATE_GROUPS, {
        location,
        number_of_grouping_columns: columnNames.length,
        number_of_min_max_grouping_columns: getMinMaxColumnsCount(columnNames)
      });
    },
    [location, baseTrackEvent]
  );

  return {
    onSortingSendBI,
    onUpdateColumnsSendBI,
    onUpdateGroupsSendBI
  };
};

export default useTrackTableEventsBI;
