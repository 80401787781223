import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const rpcApi = {
  fetchRPCs(experimentKey) {
    const url = `${BASE_API_URL}rpc/get-rpc-calls?experimentKey=${experimentKey}`;
    return axios.get(url);
  },

  sendRPC(RPC) {
    const url = `${BASE_API_URL}rpc/send-rpc`;
    return axios.post(url, JSON.stringify(RPC));
  }
};

export default rpcApi;
