import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { getMPMPanelURL } from '@mpm/utils';
import { QueryConfig } from '@shared/api';

type MPMDataBySectionParams = {
  section: string;
  propFeatureId?: string;
};

type GetMPMDataBySectionProps = {
  workspaceId: string;
  modelId: string;
  featureId?: string;
  section: string;
  propFeatureId?: string;
};

const getMPMDataBySection = ({
  workspaceId,
  modelId,
  featureId,
  ...restParams
}: GetMPMDataBySectionProps) =>
  api.get(
    getMPMPanelURL(workspaceId, modelId, featureId, restParams?.section),
    restParams
  );

export function useMPMQueryBySection(
  params: MPMDataBySectionParams,
  config: QueryConfig<unknown>
) {
  const activeWorkspace = useActiveWorkspace();
  const { propFeatureId, section } = params || {};
  const { featureId, modelId } = useParams<{
    featureId: string;
    modelId: string;
  }>();
  const requestFeaturId = featureId || propFeatureId;
  return useQuery(
    [
      section,
      {
        workspaceId: activeWorkspace?.id,
        modelId,
        requestFeaturId,
        ...params
      }
    ],
    () =>
      getMPMDataBySection({
        workspaceId: activeWorkspace?.id,
        modelId,
        featureId: requestFeaturId,
        ...params
      }),
    config
  );
}
