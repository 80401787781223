import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
import cx from 'classnames';
import { CalendarIcon } from '@Icons-outdated';
import './DateRange.scss';
import { noop } from 'lodash';

const formatDate = date => (isValid(date) ? format(date, 'MMM d, yyyy') : '');

const DateRangeCustomInput = ({ onClick, isOpen, startDate, endDate }) => {
  const isOneOfValuesValid = isValid(startDate) && isValid(endDate);

  return (
    <div className="label-container">
      {isOneOfValuesValid && (
        <div className="range-value" onClick={onClick}>
          {formatDate(startDate)} - {formatDate(endDate)}
        </div>
      )}
      <CalendarIcon
        className={cx('calendar-icon', {
          active: isOpen || isOneOfValuesValid,
          'with-left-margin': isOneOfValuesValid
        })}
        onClick={onClick}
      />
    </div>
  );
};

DateRangeCustomInput.defaultProps = {
  onClick: noop,
  startDate: null,
  endDate: null
};

DateRangeCustomInput.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default DateRangeCustomInput;
