import { Workspace } from '@shared/types';
import { useActiveWorkspaceName, useWorkspaces } from '../workspace';
import useCurrentOrganization from './useCurrentOrganization';
import useOrganizationWorkspaces from '@shared/api/useOrganizationWorkspaces';

const useIsGuestUser = () => {
  const currentWorkspaceName = useActiveWorkspaceName();
  const currentOrganization = useCurrentOrganization();

  const { data: adminWorkspaceList } = useOrganizationWorkspaces(
    currentOrganization?.id,
    {
      enabled: currentOrganization?.isAdmin
    }
  );
  const { data: userWorkspaceList } = useWorkspaces();

  const workspaces: Workspace[] = adminWorkspaceList || userWorkspaceList || [];

  return !workspaces.some(workspace => workspace.name === currentWorkspaceName);
};

export default useIsGuestUser;
