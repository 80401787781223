import React, { useState } from 'react';
import { ExperimentDetails, Metric } from '@shared/types';
import useMetricRowData from './hooks/useMetricRowData';
import useMetricDecimalsPrecision from './hooks/useMetricDecimalsPrecision';
import { useSelector } from 'react-redux';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import useTableRowSearch from '@shared/hooks/useTableRowSearch';
import DeleteMetricParamModal from './modals/DeleteMetricParamModal';
import { debounce } from 'lodash';
import ExperimentBar from '../ExperimentBar';
import { Slider } from '@design-system-outdated';
import useDeleteMetricApi from './hooks/useDeleteMetricApi';
import { TextButton } from '@design-system/components';
import { DSPlusIcon } from '@design-system/icons';
import AddMetricParamModal from './modals/AddMetricParamModal';
import EditMetricParamModal from './modals/EditMetricParamModal';
import MetricsTableNew from './MetricsTable';
import useMetricModalState from './hooks/useMetricModalState';
import useMetricTabBI from './hooks/useMetricTabBI';
import { experimentEvents } from '@/constants/trackingEventTypes';

type MetricsTableContainerProps = {
  items: Metric[];
  experiment: ExperimentDetails;
  isFetching: boolean;
};
const MetricsTableContainer: React.FC<MetricsTableContainerProps> = ({
  items,
  experiment,
  isFetching
}) => {
  const { sendMetricTabBI } = useMetricTabBI();
  const addMetricModalState = useMetricModalState();
  const editMetricModalState = useMetricModalState();
  const experimentKey = experiment.experimentKey;
  const canEdit = useSelector(isUserAllowedToEditProject);
  const { searchText, handleSearch, resetSearch } = useTableRowSearch();
  const { rowData, defaultHiddenColumnNames } = useMetricRowData({
    items,
    searchText
  });

  const { onDeleteMetric, deleteModalConfig } = useDeleteMetricApi({
    experimentKey
  });

  const {
    decimalsPrecision,
    maxDecimalsInItems,
    onChangeDecimalsPrecision
  } = useMetricDecimalsPrecision({
    items,
    experimentKey
  });

  const [
    tempDecimalsPrecision,
    onChangeTempDecimalsPrecision
  ] = useState<number>();
  const debouncedOnChangeDecimalsPrecision = debounce(
    onChangeDecimalsPrecision,
    300
  );

  const addMetric = () => {
    addMetricModalState.onOpenModal({
      callback: () => {
        sendMetricTabBI(
          experiment,
          experimentEvents.EXPERIMENT_DETAILS_ADD_METRIC_CLICKED
        );
      }
    });
  };
  const editMetric = (row: Metric) => {
    editMetricModalState.onOpenModal({
      defaultFormData: row,
      callback: () => {
        sendMetricTabBI(
          experiment,
          experimentEvents.EXPERIMENT_DETAILS_EDIT_METRIC_CLICKED
        );
      }
    });
  };

  const isEmptyTab = !items.length;

  return (
    <div className="table-tab-container">
      <ExperimentBar
        rightContainerChildren={
          <ExperimentBar.ActionList>
            <TextButton
              onClick={addMetric}
              PrefixIcon={<DSPlusIcon />}
              disabled={!canEdit}
            >
              Add metric
            </TextButton>
          </ExperimentBar.ActionList>
        }
      >
        <ExperimentBar.Search
          searchText={searchText}
          setSearchText={handleSearch}
          placeholder="Name (regex)"
          disabled={isEmptyTab}
        />

        <ExperimentBar.Custom
          label="Decimal precision"
          component={
            <Slider
              min={0}
              max={maxDecimalsInItems}
              step={1}
              value={
                tempDecimalsPrecision ?? decimalsPrecision ?? maxDecimalsInItems
              }
              onChange={onChangeTempDecimalsPrecision}
              onChangeCommitted={debouncedOnChangeDecimalsPrecision}
            />
          }
        />
      </ExperimentBar>

      <AddMetricParamModal
        rowData={rowData}
        experiment={experiment}
        onClose={addMetricModalState.onCloseModal}
        isOpened={addMetricModalState.isOpened}
      />
      <EditMetricParamModal
        experiment={experiment}
        onClose={editMetricModalState.onCloseModal}
        isOpened={editMetricModalState.isOpened}
        defaultFormData={editMetricModalState.defaultFormData}
      />
      <DeleteMetricParamModal {...deleteModalConfig} />

      <MetricsTableNew
        decimalsPrecision={decimalsPrecision}
        maxDecimalsInItems={maxDecimalsInItems}
        onDeleteMetric={onDeleteMetric}
        onEditMetric={editMetric}
        rowData={rowData}
        isFetching={isFetching}
        searchText={searchText}
        resetSearch={resetSearch}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  );
};

export default MetricsTableContainer;
