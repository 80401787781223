import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DSEditIcon, DSDeleteIcon } from '@ds-icons';
import { DSColors } from '@design-system-outdated/constants';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { Button } from '@ds';
import { Table } from '@/DesignSystem/components/tables';
import { dialogTypes } from '@/constants/alertTypes';
import {
  useCreateMetric,
  useMetricDelete,
  useMetricEdit,
  useCustomMetrics
} from '@mpm-druid/api';
import TextCell from '@shared/components/TableCells/TextCell';
import {
  CUSTOM_TRANSFORMATIONS_COLUMNS,
  CUSTOM_TRANSFORMATIONS_TABLE_HEIGHT,
  CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS,
  RIGHT_COLUMNS,
  DELETE_METRIC_BODY_TEXT,
  INTERVAL_TYPE_VALUES
} from '@mpm-druid/constants';
import alertsUtil from '@/util/alertsUtil';
import DeleteMetricModal from '@shared/components/DeleteMetricModal';
import SmallLoader from '@shared/components/SmallLoader';
import MoreActionsCell from '@shared/components/TableCells/MoreActionsCell';
import TableEmptyState from '@shared/components/TableEmptyState';
import { CustomMetricPreview } from './CustomMetricPreview';
import { CustomMetricType, ModelDetailsType } from '@mpm-druid/types';
import styles from './CustomTransformationsPage.modules.scss';

type MetricRow = {
  cometSql: string;
  metricId: string;
  name: string;
};

const initialMetricState = {
  name: '',
  cometSql: '',
  intervalType: INTERVAL_TYPE_VALUES.DAILY,
  metricId: null
};

const CustomMetrics = ({ model }: { model: ModelDetailsType }) => {
  const [rows, setRows] = useState<MetricRow[]>([]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'name', width: 450 },
    { columnName: 'actions', width: 25 }
  ]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [metricData, setMetricData] = useState<
    CustomMetricType & { intervalType: string }
  >(initialMetricState);
  const dispatch = useDispatch();
  const isEditMode = !!metricData.metricId;

  const createMetric = useCreateMetric();
  const deleteMetric = useMetricDelete();
  const editMetric = useMetricEdit();

  const {
    isLoading,
    isError,
    data,
    isFetching,
    isPreviousData
  } = useCustomMetrics({ keepPreviousData: true, refetchOnMount: true });

  useEffect(() => {
    if (data?.data.length) {
      setRows(data.data);
    }
  }, [data]);

  const deleteMetricHandler = (row: MetricRow) => {
    const modalId = dialogTypes.CONFIRM_DELETE_METRIC;
    const deleteCustomMetric = () => {
      const metricId = row.metricId;
      deleteMetric.mutate({ metricId });
    };

    const deleteRuleModal = (
      <DeleteMetricModal
        modalId={modalId}
        title="Delete this metric from MPM?"
        subTitle={DELETE_METRIC_BODY_TEXT(row.name)}
        submitHandler={deleteCustomMetric}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, deleteRuleModal));
  };

  const editMetricHandler = (row: MetricRow) => {
    const { name, metricId, cometSql } = row;
    setMetricData(prev => {
      return { ...prev, name, metricId, cometSql };
    });
    setIsOpenModal(true);
  };

  const dataTypes = useMemo(() => {
    const actionsList = [
      {
        title: 'Edit',
        icon: <DSEditIcon />,
        clickHandler: editMetricHandler
      },
      {
        title: 'Delete',
        icon: <DSDeleteIcon />,
        clickHandler: deleteMetricHandler
      }
    ];

    return [
      {
        cols: ['name'],
        cell: ({ value }: { value: string }) => (
          <TextCell value={value} isBold />
        )
      },
      {
        cols: ['definition'],
        cell: TextCell
      },
      {
        cols: ['actions'],
        cell: ({ row }: { row: MetricRow }) => (
          <MoreActionsCell row={row} actionsList={actionsList} />
        )
      }
    ];
  }, []);

  const handleCreateEditMetric = () => {
    const { name, cometSql, metricId } = metricData;
    if (metricId) {
      editMetric.mutate({ name, cometSql, metricId });
    } else {
      createMetric.mutate({ name, cometSql });
    }
    handleCloseModal();
  };

  if (isLoading)
    return <SmallLoader primaryMessage={<p>Fetching custom models</p>} />;

  if (isError) {
    return <h1> Error while fetching custom models. </h1>;
  }

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setMetricData(initialMetricState);
  };

  return (
    <div className={styles.customMetricsWrapper}>
      <div className={styles.customMetricsHeader}>
        <span className={styles.metricSubtitle}>Custom metrics</span>
        <Button onClick={handleOpenModal} data-test="create-metric-button">
          Create Metric
        </Button>
      </div>
      <Table
        isFetching={isFetching && isPreviousData}
        dataTypes={dataTypes}
        columns={CUSTOM_TRANSFORMATIONS_COLUMNS}
        rows={rows}
        rowHeight="72px"
        rowIdKey="id"
        renderEmptyState={props => (
          <TableEmptyState
            label="There are no custom metrics"
            materialProps={props}
            pictureHeight="46px"
            pictureWidth="58px"
            picturePath="/images/noMetricsIcon.svg"
          />
        )}
        header={{
          headerColor: DSColors.grayColor1
        }}
        maxHeight={CUSTOM_TRANSFORMATIONS_TABLE_HEIGHT}
        noPaddingColumns={CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS}
        rightColumns={RIGHT_COLUMNS}
        columnOrderConfig={{
          isDisabled: true
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          onColumnWidthsChange: setColumnWidths
        }}
        paginationConfig={{
          isDisabled: true
        }}
        selectionConfig={{
          isDisabled: true
        }}
      />
      <FullWidthBasicModal
        open={isOpenModal}
        onClose={handleCloseModal}
        headerTitle={true}
        title={isEditMode ? 'Edit Metric' : 'Add New Metric'}
        onPrimaryButtonClick={handleCreateEditMetric}
        onSecondaryButtonClick={handleCloseModal}
        primaryButtonText={isEditMode ? 'Edit Metric' : 'Create Metric'}
        secondaryButtonText="Cancel"
        isPrimaryButtonDisabled={!metricData?.name || !metricData.cometSql}
        isPrimaryButtonLoading={false}
        content={
          <CustomMetricPreview
            features={model.features}
            labels={model.labels}
            metricData={metricData}
            metricDataHandler={setMetricData}
          />
        }
        variant={undefined}
        stepActiveIndex={undefined}
        stepAmount={undefined}
        withoutFooter={undefined}
        className={undefined}
        footer={undefined}
        withBackButton={undefined}
        onBackButtonClick={undefined}
        customHeaderContent={undefined}
        titleMaskTest={undefined}
      />
    </div>
  );
};

export default CustomMetrics;
