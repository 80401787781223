import React from 'react';
import PropTypes from 'prop-types';

import uniq from 'lodash/uniq';
import Select from '../Select';
import MultiValueContainer from './MultiValueContainer';
import MultiValueRemove from './MultiValueRemove';
import MultiValueLabel from './MultiValueLabel';
import ClearIndicator from '../shared/ClearIndicator';
import Control from './Control';

import './ManageStagesToTagsSelect.scss';

// SMELLY: Stages to tags migration on FE
const ManageStagesToTagsSelect = props => {
  const {
    handleStagesChange,
    value,
    options,
    placeholder,
    label,
    formatCreateLabel
  } = props;

  return (
    <Select
      className="stage-select manage-stages-select"
      value={value}
      onChange={handleStagesChange}
      options={uniq([...options, ...value])}
      label={label}
      menuPlacement="bottom"
      isMulti
      isSearchable
      isClearable
      isCreatable
      closeMenuOnSelect={false}
      additionalStyles={{
        option: styles => ({
          ...styles,
          wordBreak: 'break-all',
          background: 'none !important'
        }),
        placeholder: styles => ({
          ...styles,
          color: 'var(--gray-4)',
          fontStyle: 'normal'
        })
      }}
      hideSelectedOptions={false}
      placeholder={placeholder}
      openMenuOnFocus
      components={{
        MultiValueContainer,
        MultiValueLabel,
        MultiValueRemove,
        Control,
        ClearIndicator
      }}
      openMenuOnClick
      formatCreateLabel={formatCreateLabel}
    />
  );
};

ManageStagesToTagsSelect.defaultProps = {
  value: [],
  options: []
};

ManageStagesToTagsSelect.propTypes = {
  handleStagesChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formatCreateLabel: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      source: PropTypes.string,
      value: PropTypes.string
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      source: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default ManageStagesToTagsSelect;
