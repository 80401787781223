import React from 'react';
import { truncateValue } from '@shared/utils/decimalUtils';
import { useComparisonTableContext } from '../context/newComparisonTableContext';
import { ComparisonTableCellRendererData, valuesDiffKeyMap } from '../types';
import { Tooltip } from '@ds';
import cx from 'classnames';
import styles from './NewComparisonTableCell.module.scss';

type ComparisonTableValueCellProps = ComparisonTableCellRendererData<string>;

const ComparisonTableValueCell: React.FC<ComparisonTableValueCellProps> = ({
  row,
  column,
  value
}) => {
  const { decimalsPrecision } = useComparisonTableContext();
  const columnName = column.name as keyof typeof valuesDiffKeyMap;
  const diffKey = `${columnName}Diff`;
  const diffValue = row[diffKey] || '';
  const isNegativeDiff = Math.sign(parseFloat(`${diffValue}`)) === -1;

  const hasDiffValue = Boolean(diffValue) && diffValue !== '0.00';

  return (
    <div className={styles.comparisonTableExperimentCell}>
      <Tooltip content={value} placement="top" truncateOverflow>
        <>{truncateValue(value, decimalsPrecision)}</>
      </Tooltip>

      {hasDiffValue && (
        <div
          className={cx(styles.comparisonTableExperimentCellDiff, {
            [styles.isNegative]: isNegativeDiff
          })}
        >
          {!isNegativeDiff && '+'}
          {diffValue}%
        </div>
      )}
    </div>
  );
};

export default ComparisonTableValueCell;
