import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import {
  PANEL_ENTITY_NAME,
  RESOURCE_TYPES
} from '@experiment-management-shared/constants/visualizationConstants';

import {
  PANEL_CODE_LANGUAGES,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';

import { vizActionTypes } from '@/constants/actionTypes';
import { iFrameConfig } from '@experiment-management-shared/constants';
// eslint-disable-next-line
import defaultJSTemplate from '!!raw-loader!@experiment-management-shared/constants/customPanelTemplates/defaultJSTemplate.js';
import defaultPyTemplate from '@experiment-management-shared/constants/customPanelTemplates/defaultPyTemplate';
// eslint-disable-next-line
import defaultCSSTemplate from '!!raw-loader!@experiment-management-shared/constants/customPanelTemplates/defaultCSSTemplate.css';

import {
  getInternalResourcesForNewTemplate,
  getInternalResourceVersionMap
} from './ui/visualizationsUiReducer';
import { getSelectedProjectId } from './ui/projectsUiReducer';
import { parseJSON } from '../shared/utils/jsonHelpers';

export const LATEST_REVISION_ID = 'latest';

export const NEW_TEMPLATE = {
  teamId: '',
  templateName: `New custom ${PANEL_ENTITY_NAME}`,
  scopeType: TEMPLATE_SCOPE_TYPES.PRIVATE,
  editable: true,
  code: {
    code: defaultJSTemplate,
    pyCode: defaultPyTemplate.pyCode,
    html: `<div id="${iFrameConfig.chartMountId}"></div>`,
    css: defaultCSSTemplate,
    description: '',
    defaultConfig: '',
    userResources: [],
    pyConfig: defaultPyTemplate.pyConfig,
    type: PANEL_CODE_LANGUAGES.PYTHON
  }
};

const initialState = {
  map: {},
  keys: [],
  instanceMap: {},
  preview: NEW_TEMPLATE.code,
  revisionIds: {},
  tokens: {},
  galleryTemplateMap: {
    [TEMPLATE_SCOPE_TYPES.PUBLIC]: {},
    [TEMPLATE_SCOPE_TYPES.INTERNAL]: {},
    [TEMPLATE_SCOPE_TYPES.WORKSPACE]: {}
  },
  templateInstancesMap: {}
};

const visualizationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === vizActionTypes.SET_CODE_PREVIEW) {
    const preview = get(payload, 'codePreview', NEW_TEMPLATE.code);

    return {
      ...state,
      preview
    };
  }

  if (type === vizActionTypes.SET_CODE_PREVIEW_LANGUAGE) {
    return {
      ...state,
      preview: {
        ...state.preview,
        type: payload.codeLanguage
      }
    };
  }

  if (type === vizActionTypes.SET_DEFAULT_CONFIG) {
    return {
      ...state,
      preview: {
        ...state.preview,
        defaultConfig: payload
      }
    };
  }

  if (type === vizActionTypes.SET_PY_CONFIG) {
    return {
      ...state,
      preview: {
        ...state.preview,
        pyConfig: payload
      }
    };
  }

  if (type === vizActionTypes.SET_VISUALIZATION_TEMPLATE) {
    const { revisionId = LATEST_REVISION_ID, templateId, template } = payload;
    const parsedPyConfig = parseJSON(template.code.pyConfig, []);

    const templateMap = {
      ...state.map,
      [templateId]: {
        ...state.map[templateId],
        [revisionId]: {
          ...template,
          code: {
            ...template.code,
            pyConfig: parsedPyConfig
          }
        }
      }
    };

    return {
      ...state,
      preview: state.preview,
      map: templateMap,
      keys: Object.keys(templateMap)
    };
  }

  if (type === vizActionTypes.UPDATE_VISUALIZATION_TEMPLATE) {
    const { revisionId = LATEST_REVISION_ID, templateId, template } = payload;
    const currentTemplateInstance = get(state, ['map', templateId, revisionId]);

    return {
      ...state,
      map: {
        ...state.map,
        [templateId]: {
          ...state.map[templateId],
          [revisionId]: {
            ...currentTemplateInstance,
            ...template
          }
        }
      }
    };
  }

  if (type === vizActionTypes.SET_VISUALIZATION_TEMPLATES) {
    const { map, keys } = payload;

    return {
      ...state,
      map,
      keys
    };
  }

  if (type === vizActionTypes.DELETE_VISUALIZATION_TEMPLATE) {
    const { templateId } = payload;

    const { galleryTemplateMap } = state;

    const updatedGalleryMap = Object.keys(galleryTemplateMap).reduce(
      (map, scopeType) => {
        if (galleryTemplateMap[scopeType].hasOwnProperty(templateId)) {
          const {
            [templateId]: templateIdRemovedFromGallery,
            ...updatedGalleryMap
          } = galleryTemplateMap[scopeType];
          map[scopeType] = updatedGalleryMap;
        }

        return map;
      },
      {}
    );

    const {
      [templateId]: templateIdRemovedFromMap,
      ...updatedTemplateMap
    } = state.map;

    return {
      ...state,
      map: updatedTemplateMap,
      keys: Object.keys(updatedTemplateMap),
      galleryTemplateMap: {
        ...state.galleryTemplateMap,
        ...updatedGalleryMap
      }
    };
  }

  if (type === vizActionTypes.SET_VISUALIZATION_INSTANCE) {
    const { instanceId, instance } = payload;

    return {
      ...state,
      instanceMap: {
        ...state.instanceMap,
        [instanceId]: instance
      }
    };
  }

  if (type === vizActionTypes.SET_REVISION_IDS) {
    const { revisions, templateId } = payload;

    return {
      ...state,
      revisionIds: {
        ...state.revisionIds,
        [templateId]: revisions
      }
    };
  }

  if (type === vizActionTypes.SET_PROJECT_TOKEN) {
    const { projectId, token } = payload;

    return {
      ...state,
      tokens: {
        ...state.tokens,
        [projectId]: token
      }
    };
  }

  if (type === vizActionTypes.SET_GALLERY_TEMPLATE_MAP) {
    const { scopeType, templatesByScopeType } = payload;

    return {
      ...state,
      galleryTemplateMap: {
        ...state.galleryTemplateMap,
        [scopeType]: templatesByScopeType
      }
    };
  }

  if (type === vizActionTypes.SET_GALLERY_TEMPLATE) {
    const { templateId, template, scopeType } = payload;

    const galleryTemplateMap = {
      ...state.galleryTemplateMap,
      [scopeType]: {
        ...state.galleryTemplateMap[scopeType],
        [templateId]: { [LATEST_REVISION_ID]: template }
      }
    };

    return { ...state, galleryTemplateMap };
  }

  if (type === vizActionTypes.SET_PREVIEW_TEMPLATE_INSTANCES) {
    const { instances, templateId } = payload;

    return {
      ...state,
      templateInstancesMap: {
        ...state.templateInstancesMap,
        [templateId]: instances
      }
    };
  }

  return state;
};

export default visualizationsReducer;

const getPreview = state => state.visualizations.preview;
export const getTemplateMap = state => state.visualizations.map;
export const getRevisionIdsMap = state => state.visualizations.revisionIds;
export const getProjectTokens = state => state.visualizations.tokens;
export const getTemplateInstancesMap = state =>
  state.visualizations.templateInstancesMap;

export const getTemplateId = (_, props) => props.templateId;
export const getRevisionId = (_, props) =>
  defaultTo(props.revisionId, LATEST_REVISION_ID);
export const getProjectId = (state, props) => {
  return get(props, 'projectId', getSelectedProjectId(state));
};

export const makeGetVisualizationTemplate = (defaultTemplate = null) => {
  return createSelector(
    getTemplateId,
    getRevisionId,
    getTemplateMap,
    (templateId, revisionId, templateMap) =>
      get(templateMap, [templateId, revisionId], defaultTemplate)
  );
};

export const makeGetVisualizationTemplateRevisionIds = () => {
  return createSelector(
    getTemplateId,
    getRevisionIdsMap,
    (templateId, revisionIdsMap) => get(revisionIdsMap, templateId, [])
  );
};

export const makeGetLatestRevisonId = () => {
  return createSelector(
    getTemplateId,
    getRevisionIdsMap,
    (templateId, revisionIdsMap) =>
      last(get(revisionIdsMap, templateId, [])) || 0
  );
};

export const makeGetProjectToken = () => {
  return createSelector([getProjectId, getProjectTokens], (projectId, tokens) =>
    get(tokens, projectId, null)
  );
};

export const makeGetTemplateInstances = () => {
  return createSelector(
    [getTemplateId, getTemplateInstancesMap],
    (templateId, templateInstancesMap) =>
      get(templateInstancesMap, templateId, null)
  );
};

export const getProjectToken = makeGetProjectToken();

export const getCurrentTemplate = createSelector(
  [getTemplateMap, getTemplateId, getRevisionIdsMap, getRevisionId],
  (templateMap, templateId, revisionIdsMap, revisionId) => {
    const latestRevisionId = last(get(revisionIdsMap, templateId, []));

    const revision = get(templateMap, [templateId, revisionId], null);

    if (!revision) {
      return get(templateMap, [templateId, latestRevisionId], NEW_TEMPLATE);
    }

    return revision;
  }
);

export const getPyConfigPreview = createSelector(
  [getPreview],
  preview => preview.pyConfig
);

export const getCodeLanguagePreview = createSelector(
  [getPreview],
  preview => preview.type
);

export const getTemplateNameById = createSelector(
  [getTemplateMap, getTemplateId, getRevisionId],
  (templateMap, templateId, revisionId) => {
    return get(
      templateMap,
      [templateId, revisionId, 'templateName'],
      NEW_TEMPLATE.templateName
    );
  }
);

export const getCodePreview = createSelector([getPreview], preview => {
  return isEmpty(preview) ? NEW_TEMPLATE.code : preview;
});

export const getResourcesBySource = (
  source,
  versionMap,
  internalDefaultResources
) => {
  const internalResources = defaultTo(
    source?.internalResources,
    internalDefaultResources
  )
    .filter(resource => {
      const { name, enabled } = resource;
      return enabled && versionMap.hasOwnProperty(name);
    })
    .map(resource => {
      const { name, version } = resource;
      return {
        url: versionMap[name][version],
        type: RESOURCE_TYPES.JAVASCRIPT.value
      };
    });

  const userResources = defaultTo(source?.userResources, []);

  return [...internalResources, ...userResources];
};

export const getResourceUrlsForPreview = createSelector(
  [
    getCodePreview,
    getInternalResourceVersionMap,
    getInternalResourcesForNewTemplate
  ],
  (codePreview, versionMap, internalResourcesForNewTemplate) => {
    return getResourcesBySource(
      codePreview,
      versionMap,
      internalResourcesForNewTemplate
    );
  }
);

export const getResourcesForTemplate = createSelector(
  [
    getCurrentTemplate,
    getInternalResourceVersionMap,
    getInternalResourcesForNewTemplate
  ],
  (currentTemplate, versionMap, internalResourcesForNewTemplate) => {
    return getResourcesBySource(
      currentTemplate.code,
      versionMap,
      internalResourcesForNewTemplate
    );
  }
);

export const getQueryBuilderId = createSelector(
  [getCurrentTemplate],
  currentTemplate => {
    return get(currentTemplate, 'queryBuilderId', '');
  }
);
