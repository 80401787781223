import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import api from '@API/api';
import { getTeamIdForActiveWorkspace } from '@/reducers/ui/workspaceUiReducer';

type ModelDistributionsParams = {
  modelId: string;
};

const getDistributions = ({
  modelId,
  workspaceId
}: ModelDistributionsParams & { workspaceId: string }) =>
  api.get(`mpm/${workspaceId}/models/${modelId}/distributions`);

export function useModelDistributions({ modelId }: ModelDistributionsParams) {
  const workspaceId = useSelector(getTeamIdForActiveWorkspace);

  return useQuery(
    [`model-distributions-${modelId}`, { workspaceId }],
    () => getDistributions({ modelId, workspaceId }),
    {
      enabled: !!workspaceId
    }
  );
}
