import React from 'react';

import classes from './PythonEnvironmentTab.module.scss';
import cx from 'classnames';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import typography from '@ds-typography';

import { useCurrentPythonEnvironment } from '@experiment-management-shared/api';
import useCurrentOrganization from '@shared/hooks/organization/useCurrentOrganization';
import { getDefaultPythonCodeExtensions } from '@shared';
import { codeSnippetClasses } from '@shared/styles';
import { LinkButton } from '@ds';

const PythonEnvironmentTab = () => {
  const currentOrganization = useCurrentOrganization();

  const { data: pythonEnvironment } = useCurrentPythonEnvironment(
    currentOrganization?.id || ''
  );

  return (
    <div className={classes.pythonEnvironmentTabContainer}>
      <p className={classes.pythonEnvironmentTabLabel}>
        The Python environment is shared between all panels and is managed at an
        Organization Level. You can update the Python environment in the Admin
        Dashboard{' '}
        <LinkButton
          text="here"
          openInNewTab
          to={`/organizations/${currentOrganization?.id}/python-environment`}
        />
        . Comet pre-installs a set of Python packages in all environments, read
        more about this in{' '}
        <LinkButton
          text="our documentation"
          to="/docs/v2/guides/comet-ui/experiment-management/visualizations/python-panel/"
        />
        .
      </p>

      <div>
        <p
          className={cx(typography.dsAccented, classes.environmentDetailsLabel)}
        >
          Environment details
        </p>

        <p className={classes.environmentDetailsField}>
          Environment Version: {pythonEnvironment?.version}
        </p>
        <p className={classes.environmentDetailsField}>
          Python version: {pythonEnvironment?.pythonVersion}
        </p>
        <p className={classes.environmentDetailsField}>
          Included Python Packages:
        </p>
      </div>

      <div
        className={cx(
          classes.environmentRequirementsBlock,
          codeSnippetClasses.readOnlyPythonSnippet
        )}
      >
        <CodeMirror
          value={pythonEnvironment?.customRequirements?.join('\n')}
          extensions={[
            ...getDefaultPythonCodeExtensions(),
            EditorView.editable.of(false)
          ]}
          className={classes.pythonCodeMirror}
          placeholder="No additional python packages"
          basicSetup={{
            highlightActiveLineGutter: false,
            highlightActiveLine: false
          }}
        />
      </div>
    </div>
  );
};

export default PythonEnvironmentTab;
