import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import StyledMenuItem from '@shared/components/StyledComponents/StyledMenuItem';

const ItemMenuBtn = ({
  iconType,
  iconSize,
  menuItemConfigs,
  className,
  dataTestId
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef();

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuButtonClick = () => {
    setIsMenuOpen(true);
  };

  const handleMenuItemClick = handler => {
    handler();
    handleClose();
  };

  const renderMenuButton = () => {
    return (
      <IconButton ref={buttonRef} onClick={handleMenuButtonClick}>
        <i className="material-icons" style={{ fontSize: iconSize }}>
          {iconType}
        </i>
      </IconButton>
    );
  };

  const renderMenuItems = () => {
    return menuItemConfigs.map(item => {
      const { label, handler, Icon, dataTest, disabled } = item;

      return (
        <StyledMenuItem
          disabled={disabled}
          data-test={dataTest}
          key={label}
          Icon={Icon}
          text={label}
          onClick={() => handleMenuItemClick(handler)}
        />
      );
    });
  };

  return (
    <>
      <span
        className={className}
        data-test={`single-card-settings-${dataTestId}`}
      >
        {renderMenuButton()}
      </span>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={handleClose}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

ItemMenuBtn.defaultProps = {
  iconType: 'settings',
  iconSize: '20px',
  dataTestId: '',
  className: ''
};

ItemMenuBtn.propTypes = {
  dataTestId: PropTypes.string,
  iconType: PropTypes.string,
  iconSize: PropTypes.string,
  menuItemConfigs: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default ItemMenuBtn;
