import React from 'react';
import { ListItem, Tooltip } from '@ds';
import {
  useActiveWorkspaceName,
  usePopoverState,
  useWorkspaces
} from '@shared/hooks';
import { DSArrowRightIcon } from '@design-system/icons';
import { Popover } from '@material-ui/core';
import UserMenuWorkspacesSelector from './UserMenuWorkspacesSelector';
import cx from 'classnames';

import styles from './UserMenuWorkspaces.module.scss';
import { useUserMenuContext } from '../../userMenuContext';
import useCloseMainMenuOnBackdrop from './hooks/useCloseMainMenuOnBackdrop';
import useIsGuestUser from '@shared/hooks/organization/useIsGuestUser';

const UserMenuWorkspaces = () => {
  const isGuestUser = useIsGuestUser();
  const { data: workspaces = [] } = useWorkspaces();
  const currentWorkspaceName = useActiveWorkspaceName();
  const useHasMultipleWorkspaces = workspaces.length > 1;
  const { closeMainMenu } = useUserMenuContext();
  const closeMainMenuOnBackdrop = useCloseMainMenuOnBackdrop();

  const isWorkspaceSelectorDisabled = isGuestUser || !useHasMultipleWorkspaces;

  const {
    openPopover,
    onClosePopover,
    isPopoverOpened,
    anchorElement
  } = usePopoverState({
    isBlocked: isWorkspaceSelectorDisabled,
    onClose: closeMainMenuOnBackdrop
  });

  return (
    <>
      <Tooltip content={currentWorkspaceName} wrapperDisplay="block">
        <ListItem
          onClick={openPopover}
          active={isPopoverOpened}
          isReadonly={isWorkspaceSelectorDisabled}
        >
          <b className={styles.workspaceSelectorType}>Workspace:</b>
          <span
            data-test="user-menu-current-workspace"
            className={cx(styles.workspaceSelectorName, 'truncated-string')}
          >
            {currentWorkspaceName}
          </span>
          {!isWorkspaceSelectorDisabled && (
            <ListItem.ActionContainer>
              <ListItem.Icon Icon={<DSArrowRightIcon />} />
            </ListItem.ActionContainer>
          )}
        </ListItem>
      </Tooltip>
      <Popover
        id="workspace-select-popover"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          className: styles.workspaceSelectorContainer
        }}
        elevation={0}
        transitionDuration={0}
        open={isPopoverOpened}
        anchorEl={anchorElement}
        onClose={onClosePopover}
      >
        <UserMenuWorkspacesSelector
          dataTestPrefix="user-menu-workpspace-search"
          onChange={closeMainMenu}
          onViewAll={closeMainMenu}
        />
      </Popover>
    </>
  );
};

export default UserMenuWorkspaces;
