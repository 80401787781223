import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import noop from 'lodash/noop';

import { IconButton } from '@material-ui/core';
import { Tooltip } from '@ds';
import { useSelector } from 'react-redux';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { CancelIcon } from '@shared/utils/svgIcons';

import styles from './Tag.module.scss';

const Tag = ({ tagColor, tagName, isDeleteDisabled, onDeleteTag }) => {
  const isUserLogged = useSelector(state => getIsUserLoggedIn(state));

  return (
    <Tooltip content={tagName}>
      <div className={styles.tag} style={{ '--color': tagColor }}>
        <span className={cx(styles.label, 'truncated-string')}>{tagName}</span>
        {!isDeleteDisabled && (
          <IconButton
            onClick={onDeleteTag}
            className={styles.deleteIcon}
            disabled={!isUserLogged}
          >
            <CancelIcon />
          </IconButton>
        )}
      </div>
    </Tooltip>
  );
};

Tag.defaultProps = {
  isDeleteDisabled: false,
  onDeleteTag: noop
};

Tag.propTypes = {
  isDeleteDisabled: PropTypes.bool,
  onDeleteTag: PropTypes.func,
  tagColor: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired
};

export default Tag;
