import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import startCase from 'lodash/startCase';

import ProjectPageHeader from '@shared/components/ProjectPageHeader';
import { LLM_TAB_PATHNAME } from '@experiment-management-shared/constants/llmConstants';
import projectsActions from '../../../actions/projectsActions';
import { getActiveLLMHeaderTab } from '@/reducers/ui/projectsUiReducer';

const LLM_TABS = [
  {
    id: LLM_TAB_PATHNAME.prompts,
    name: startCase(LLM_TAB_PATHNAME.prompts)
  }
  // TODO uncomment when page is implemented
  // ,
  // {
  //   id: LLM_TAB_PATHNAME.embeddings,
  //   name: startCase(LLM_TAB_PATHNAME.embeddings)
  // },
  // {
  //   id: LLM_TAB_PATHNAME.playground,
  //   name: startCase(LLM_TAB_PATHNAME.playground)
  // }
];

type getLLMRoutesOpts = {
  projectName: string;
  workspace: string;
};
const getLLMRoutes = ({ projectName, workspace }: getLLMRoutesOpts) => {
  return LLM_TABS.map(tab => ({
    ...tab,
    path: `/${workspace}/${projectName}/${tab.id}`
  }));
};

const LLMProjectPageHeader = () => {
  const dispatch = useDispatch();
  const { projectName, workspace } = useParams<{
    projectName: string;
    workspace: string;
  }>();
  const activeTab = useSelector(getActiveLLMHeaderTab);

  const handleTabChange = (tabId: string) => {
    dispatch(projectsActions.setActiveLLMHeaderTab(tabId));
  };

  const routes = getLLMRoutes({
    projectName,
    workspace
  });

  return (
    <ProjectPageHeader
      activeTab={activeTab}
      routes={routes}
      handleTabChange={handleTabChange}
    />
  );
};

export default LLMProjectPageHeader;
