import { useMemo } from 'react';

type Asset = {
  experimentKey: string;
  name: string;
};

export function usePreviewAssets<T extends Asset>({
  assetNames = [],
  assets = [],
  experimentKeys = []
}: {
  assetNames?: string[];
  assets?: T[];
  experimentKeys?: string[];
}) {
  return useMemo(() => {
    const newPreviewAssets: T[] = [];

    assetNames.forEach(name => {
      experimentKeys.forEach(key => {
        const previewAsset = assets.find(
          asset => asset.experimentKey === key && asset.name === name
        );

        if (previewAsset) {
          newPreviewAssets.push(previewAsset);
        }
      });
    });

    return newPreviewAssets;
  }, [assetNames, assets, experimentKeys]);
}
