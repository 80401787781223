// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionActions-module__actions--RJJ_B{min-height:216px;position:relative}.SectionActions-module__actions--RJJ_B.SectionActions-module__viewOnly--A7ePG{min-height:auto}.SectionActions-module__buttons--UIBh4{display:none;flex-direction:column;margin:16px 0;position:absolute;right:0;top:0;transform:translateX(50%);z-index:20}.SectionActions-module__buttons--UIBh4>.SectionActions-module__buttonContainer--JnNfp:not(:last-child){margin-bottom:8px}.SectionActions-module__children--btjLY{padding:8px 16px}.SectionActions-module__children--btjLY>*{padding:8px 12px}.SectionActions-module__children--btjLY.SectionActions-module__viewOnly--A7ePG{padding:0}.SectionActions-module__children--btjLY.SectionActions-module__viewOnly--A7ePG>*{padding:8px 0 16px}.SectionActions-module__childrenOverlay--V2nFY{cursor:text;height:100%;position:absolute;width:100%;z-index:10}.SectionActions-module__editable--GBByT{border:1px solid rgba(0,0,0,0);border-radius:8px}.SectionActions-module__editable--GBByT:hover{background-color:#f7f8fa;border:1px solid #5055f5}.SectionActions-module__editable--GBByT:hover .SectionActions-module__buttons--UIBh4{display:flex}.SectionActions-module__editing--l316N{background-color:#fdfdff;border:1px solid #ddd}@media print{.SectionActions-module__children--btjLY{position:relative}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `SectionActions-module__actions--RJJ_B`,
	"viewOnly": `SectionActions-module__viewOnly--A7ePG`,
	"buttons": `SectionActions-module__buttons--UIBh4`,
	"buttonContainer": `SectionActions-module__buttonContainer--JnNfp`,
	"children": `SectionActions-module__children--btjLY`,
	"childrenOverlay": `SectionActions-module__childrenOverlay--V2nFY`,
	"editable": `SectionActions-module__editable--GBByT`,
	"editing": `SectionActions-module__editing--l316N`
};
module.exports = ___CSS_LOADER_EXPORT___;
