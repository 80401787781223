import useCurrentUser from '@API/auth/useCurrentUser';
import { Input } from '@DesignSystem/controllers';
import { Snackbar } from '@DesignSystem/feedback';
import { Table } from '@DesignSystem/tables';
import { SearchIcon } from '@Icons-outdated';
import React, { useCallback, useMemo, useState } from 'react';
import { GenericActionsCell } from '@DesignSystem/generic-table-cells/ActionsCell/ActionsCell';
import { useParams } from 'react-router-dom';
import { DSColors } from '@design-system-outdated/constants';
import { useDebouncedFilter } from '@shared/hooks';
import { useUndoSnackbarState } from '@account-settings/hooks';

import {
  useOrganizationEmailInvitations,
  useOrganizationUsers,
  useRemoveInvitationFromOrganizationMutation,
  useUpdateMemberRoleMutation,
  useRemoveUserFromOrganizationMutation,
  useOrganizationUserRoles
} from '../../../api';

import DeleteUserFromOrganizationModal from './DeleteUserFromOrganizationModal';
import './Users.scss';
import UsersTableCells from './UsersTableCells';
import SmallLoader from '@shared/components/SmallLoader';
import { ORGANIZATION_USER_ROLES } from '@shared/constants';
import TableNoSearchResults from '@shared/components/TableNoSearchResults/TableNoSearchResults';

const COLUMNS = [
  {
    name: 'username',
    id: 'username',
    title: 'Name / User name'
  },
  {
    name: 'email',
    id: 'email',
    title: 'Email'
  },
  {
    name: 'joinedAt',
    id: 'joinedAt',
    title: 'Joined'
  },
  {
    name: 'role',
    id: 'role',
    title: 'Role'
  },
  {
    name: 'actions',
    id: 'actions',
    title: ' '
  }
];

const NO_PADDING_COLUMNS = ['actions'];

const RIGHT_COLUMNS = ['actions'];

const paginationConfig = {
  isDisabled: true
};

const selectionConfig = {
  isDisabled: true
};

const Users = () => {
  const [lastChangedMember, setLastChangedMember] = useState(null);
  const [columnOrder, setColumnOrder] = useState([]);
  const [sorting, setSorting] = useState([]);

  const { organizationId } = useParams();
  const {
    debouncedFilterValue,
    setFilterValue,
    filterValue
  } = useDebouncedFilter('');
  const updateMemberRoleMutation = useUpdateMemberRoleMutation(organizationId);

  const removeUserFromOgranizatioMutation = useRemoveUserFromOrganizationMutation(
    organizationId
  );
  const removeInvitationFromOrganizationMutation = useRemoveInvitationFromOrganizationMutation(
    organizationId
  );

  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'actions', width: 25 }
  ]);

  const { data: users = [], isLoading: isLoadingUsers } = useOrganizationUsers(
    organizationId
  );
  const { data: currentUser } = useCurrentUser();
  const {
    data: emailInvitations = [],
    isLoading: isLoadingInvitations
  } = useOrganizationEmailInvitations(organizationId);

  const [userToRemove, setUserToRemove] = useState(null);
  const { data: userRoles = [] } = useOrganizationUserRoles(organizationId);

  const closeDeleteModal = () => setUserToRemove(null);
  const handleConfirmDelete = () => {
    if (userToRemove?.isInvitation) {
      removeInvitationFromOrganizationMutation.mutate({
        email: userToRemove.email
      });
    } else {
      removeUserFromOgranizatioMutation.mutate(userToRemove.username);
    }
    closeDeleteModal();
  };

  const filteredUsers = useMemo(() => {
    if (!debouncedFilterValue?.length) {
      return users;
    }

    return users.filter(account => {
      const lowerValue = debouncedFilterValue.toLowerCase();

      return (
        (account.username || '').toLowerCase().includes(lowerValue) ||
        (account.email || '').toLowerCase().includes(lowerValue)
      );
    });
  }, [users, debouncedFilterValue]);

  const filteredEmailInvitations = useMemo(() => {
    if (!debouncedFilterValue?.length) {
      return emailInvitations;
    }

    return emailInvitations.filter(invitation => {
      const lowerValue = debouncedFilterValue.toLowerCase();

      return invitation.email.toLowerCase().includes(lowerValue);
    });
  }, [emailInvitations, debouncedFilterValue]);

  const handleUpdateRole = useCallback(
    (value, username, previousRole) => {
      const newMemberRole = {
        role: value,
        previousRole,
        username
      };

      setLastChangedMember(newMemberRole);
      updateMemberRoleMutation.mutate(newMemberRole);
    },
    [updateMemberRoleMutation]
  );

  const dataTypes = useMemo(
    () => [
      {
        cols: ['username'],
        cell: UsersTableCells.NameCell
      },
      {
        cols: ['role'],
        cell: ({ value, row }) => (
          <UsersTableCells.RoleCell
            value={value}
            onChangeRole={newValue => {
              newValue !== value &&
                handleUpdateRole(newValue, row.username, row.role);
            }}
            disabled={row.disabledRoleChanging}
            isSameUser={!!row.isSameUser}
            roles={userRoles}
          />
        )
      },
      {
        cols: ['joinedAt', 'lastActivityAt'],
        cell: UsersTableCells.DateCell
      },
      {
        cols: ['actions'],
        cell: ({ row }) => (
          <GenericActionsCell
            onClick={() => setUserToRemove(row)}
            options={[
              {
                label: 'Delete user',
                value: 'Delete user',
                disabled: row.disabledRemoving,
                tooltipText: row.disabledRemoving
                  ? 'You cannot remove yourself from the organization'
                  : null
              }
            ]}
          />
        )
      }
    ],
    [setUserToRemove, handleUpdateRole]
  );

  const renderEmptyState = props => <TableNoSearchResults {...props} />;

  const handleUndoClick = useCallback(() => {
    updateMemberRoleMutation.mutate({
      role: lastChangedMember.previousRole,
      username: lastChangedMember.username
    });
  }, [updateMemberRoleMutation, lastChangedMember]);

  const handleCloseSnackbar = useCallback(() => {
    setLastChangedMember(null);
  }, []);

  const {
    isOpen: isOpenSnackbar,
    onClose: onCloseSnackbar,
    onUndoClick
  } = useUndoSnackbarState({
    isSuccess: updateMemberRoleMutation.isSuccess,
    resetMutationState: updateMemberRoleMutation.reset,
    onUndoClick: handleUndoClick,
    onClose: handleCloseSnackbar,
    isLoading: updateMemberRoleMutation.isLoading
  });

  const rows = useMemo(() => {
    const formattedUsers = filteredUsers.map(user => ({
      ...user,
      id: user.username,
      isInvitation: false,
      disabledRoleChanging: currentUser?.username === user.username,
      isSameUser: currentUser?.username === user.username,
      disabledRemoving: currentUser?.username === user.username
    }));

    const formattedInvitations = filteredEmailInvitations.map(invitation => ({
      ...invitation,
      id: invitation.id,
      role: ORGANIZATION_USER_ROLES.MEMBER,
      isInvitation: true,
      disabledRoleChanging: true,
      disabledRemoving: false
    }));

    return [...formattedUsers, ...formattedInvitations];
  }, [filteredUsers, filteredEmailInvitations, currentUser]);

  return (
    <>
      <Snackbar open={updateMemberRoleMutation.isLoading} message="Saving..." />

      <Snackbar
        open={isOpenSnackbar}
        onClose={onCloseSnackbar}
        message="Settings saved"
        withUndo
        onUndoClick={onUndoClick}
      />

      <DeleteUserFromOrganizationModal
        open={Boolean(userToRemove)}
        onClose={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        userName={userToRemove?.username || userToRemove?.email || ''}
      />
      <div className="users-tab-wrapper">
        <div className="page-title">Users</div>
        <div className="users-tab-controlled-header">
          <Input
            InlinePrefixIcon={SearchIcon}
            placeholder="Search"
            onChange={setFilterValue}
            value={filterValue}
          />

          {!isLoadingUsers && !isLoadingInvitations && (
            <div className="users-amount">
              <span>{users?.length || 0} users, </span>
              <span>{emailInvitations?.length || 0} pending invitation</span>
            </div>
          )}
        </div>
        <div className="users-tab-content-container">
          {isLoadingUsers && (
            <SmallLoader
              primaryMessage="Loading..."
              secondaryMessage="Loading users"
            />
          )}
          {!!users.length && (
            <Table
              columns={COLUMNS}
              rowHeight="70px"
              rowIdKey="id"
              rows={rows}
              paginationConfig={paginationConfig}
              selectionConfig={selectionConfig}
              header={{
                headerColor: DSColors.grayColor1
              }}
              maxHeight={308}
              dataTypes={dataTypes}
              noPaddingColumns={NO_PADDING_COLUMNS}
              rightColumns={RIGHT_COLUMNS}
              renderEmptyState={renderEmptyState}
              columnOrderConfig={{
                isDisabled: false,
                columnOrder,
                onColumnOrderChange: setColumnOrder
              }}
              columnWidthsConfig={{
                isDisabled: false,
                columnWidths,
                onColumnWidthsChange: setColumnWidths
              }}
              sortingConfig={{
                columnSorting: sorting,
                onSortingChange: setSorting,
                isDisabled: false
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
