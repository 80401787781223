import { SearchIcon } from '@Icons-outdated';
import { Popover } from '@material-ui/core';
import cx from 'classnames';
import noop, { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';

import { Menu } from '@DesignSystem/navigation';
import { DS_MENU_TYPES } from '@DesignSystem/navigation/Menu/Menu';

import ListItem from './ListItem';
import Input from '../Input/Input';
import './DropdownList.scss';

const DEFAULT = 'default';
const SECONDARY = 'secondary';
const WIDE = 'wide';

export const DRDOPDOWN_HOVER_TYPES = {
  DEFAULT: 'default-hover',
  MENU: 'menu-hover'
};

export const DROPDOWN_LIST_TYPES = {
  DEFAULT,
  SECONDARY,
  WIDE
};

const DropdownList = ({
  anchorEl,
  extraOptionsProps,
  hideSource,
  horizontalPosition,
  inputValue,
  itemHeight: itemSize,
  items,
  noOptionsComponent,
  maxHeight,
  minWidth,
  onChangeInput,
  onClick,
  onClose,
  renderListItem: parentRenderListItem,
  transformOriginHorizontal,
  transformOriginVertical,
  value,
  verticalPosition,
  virtualizedThreshold,
  width,
  withInput,
  withoutHover,
  dropdownWrapperClassName,
  type,
  footer,
  hoverType,
  truncateMiddle,
  ...otherProps
}) => {
  const open = !!anchorEl;
  const isWithGroups = items?.some(option => option.groupName);
  const totalItemsHeight = itemSize * items.length;

  const renderInput = () => {
    return (
      <div className="dropdown-input-container">
        <Input
          placeholder="Search..."
          onChange={onChangeInput}
          value={inputValue}
          PrefixIcon={SearchIcon}
          withoutBorder
          autoFocus
        />
      </div>
    );
  };

  const renderItem = ({ index, style }) => {
    const item = items[index];
    return (
      <ListItem
        item={item}
        width={width}
        style={style}
        hideSource={hideSource}
        key={item.value}
        extraOptionsProps={extraOptionsProps}
        isWithGroups={isWithGroups}
        onClick={onClick}
        selectedValue={value}
        parentRenderListItem={parentRenderListItem}
        withoutHover={withoutHover}
        hoverType={hoverType}
        truncateMiddle={truncateMiddle}
      />
    );
  };

  const renderItems = () => {
    if (isEmpty(items)) {
      return (
        <div style={{ width, textAlign: 'center' }}>
          {noOptionsComponent ? (
            noOptionsComponent()
          ) : (
            <span style={{ color: 'var(--primary-color)' }}>No options</span>
          )}
        </div>
      );
    }

    if (!maxHeight || !width || items.length < virtualizedThreshold) {
      const style = { overflow: 'auto' };

      if (maxHeight) {
        style.maxHeight = maxHeight;
      }

      if (type === SECONDARY) {
        return (
          <Menu
            items={items}
            activeItem={value}
            type={DS_MENU_TYPES.SECONDARY}
            onClickItem={onClick}
          />
        );
      }

      return (
        <div style={style}>
          {items.map(item => {
            return (
              <ListItem
                hideSource={hideSource}
                item={item}
                key={item.value}
                extraOptionsProps={extraOptionsProps}
                isWithGroups={isWithGroups}
                onClick={onClick}
                selectedValue={value}
                parentRenderListItem={parentRenderListItem}
                withoutHover={withoutHover}
                hoverType={hoverType}
                width={width}
                truncateMiddle={truncateMiddle}
              />
            );
          })}
        </div>
      );
    }

    return (
      <div
        style={{
          height:
            !isEmpty(maxHeight) && totalItemsHeight > parseInt(maxHeight, 10)
              ? maxHeight
              : totalItemsHeight
        }}
      >
        <AutoSizer>
          {({ width: listWidth, height }) => {
            return (
              <FixedSizeList
                height={height}
                width={listWidth}
                itemSize={itemSize}
                itemCount={items.length}
              >
                {renderItem}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      </div>
    );
  };

  const renderFooter = () => {
    if (!footer) {
      return null;
    }

    return <div className="ds-dropdown-list-footer-container">{footer}</div>;
  };

  const { useListAsAnchor, ...popoverOtherProp } = otherProps;

  return (
    <Popover
      classes={{
        paper: cx('ds-dropdown-list', type, {
          'without-top-padding': withInput
        })
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      elevation={0}
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformOriginHorizontal
      }}
      PaperProps={{
        style: {
          width,
          minWidth
        }
      }}
      className={dropdownWrapperClassName}
      {...popoverOtherProp}
    >
      {withInput && renderInput()}
      {renderItems()}
      {renderFooter()}
    </Popover>
  );
};

DropdownList.propTypes = {
  anchorEl: PropTypes.object,
  horizontalPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputValue: PropTypes.string,
  itemHeight: PropTypes.number,
  items: PropTypes.array,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeInput: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  renderListItem: PropTypes.func,
  transformOriginHorizontal: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  transformOriginVertical: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  verticalPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  virtualizedThreshold: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withInput: PropTypes.bool,
  noOptionsComponent: PropTypes.any,
  withoutHover: PropTypes.bool,
  dropdownWrapperClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(DROPDOWN_LIST_TYPES)),
  footer: PropTypes.node,
  hoverType: PropTypes.oneOf(Object.values(DRDOPDOWN_HOVER_TYPES)),
  truncateMiddle: PropTypes.bool
};

DropdownList.defaultProps = {
  anchorEl: null,
  horizontalPosition: 'left',
  inputValue: null,
  itemHeight: 59,
  items: [],
  minWidth: undefined,
  maxHeight: undefined,
  onChangeInput: noop,
  onClick: noop,
  renderListItem: null,
  transformOriginHorizontal: 'left',
  transformOriginVertical: 'top',
  value: null,
  verticalPosition: 'bottom',
  noOptionsComponent: null,
  virtualizedThreshold: 20,
  width: undefined,
  withInput: false,
  withoutHover: false,
  dropdownWrapperClassName: '',
  type: DROPDOWN_LIST_TYPES.DEFAULT,
  footer: null,
  hoverType: DRDOPDOWN_HOVER_TYPES.DEFAULT,
  truncateMiddle: false
};

export default DropdownList;
