import React, { useEffect, useState } from 'react';
import { Button, TextButton } from '@ds';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getURLQueryParams } from '@shared/utils/url';

import {
  GALLERY_TAB,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';

import visualizationsActions from '@/actions/visualizationsActions';
import { ROOT_URL } from '@/constants/configConstants';

import { makeGetTemplateInstances } from '@/reducers/visualizationsReducer';

import SmallLoader from '@shared/components/SmallLoader';
import MarkdownIFrame from '@shared/components/MarkdownIFrame';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';
import { DSArrowLeftIcon } from '@ds-icons';

const DESCRIPTION_STYLES = `<style>
html,
body {
  background-color: #f7f8fa;
  font-family: 'Roboto', sans-serif;
}
</style>`;

const TAB_VALUES = {
  DESCRIPTION: 'description',
  EXAMPLES: 'examples'
};

const generateEmbeddedURL = ({
  instanceId,
  projectId,
  templateId,
  viewId = 'default-view'
}) => {
  const parameters = queryString.stringify({
    instanceId,
    projectId,
    templateId,
    viewId
  });

  return `${ROOT_URL}embedded/?${parameters}`;
};

const PanelPreviewDialog = ({ dispatch, instances, open, template }) => {
  const {
    description,
    name: title,
    id,
    owner = null,
    rank = { voteCount: 0 },
    thumbnail,
    type
  } = template;
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(TAB_VALUES.EXAMPLES);
  const queryParams = getURLQueryParams(location.hash);

  const handleCancel = () => {
    history.goBack();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!open) return;

    dispatch(visualizationsActions.fetchInstancesPreview(id)).then(
      templateInstances => {
        if (description && !templateInstances.length) {
          setTabValue(TAB_VALUES.DESCRIPTION);
        }
      }
    );
  }, [description, dispatch, id, open]);

  const renderDescription = () => {
    return (
      <div className="chart-gallery-preview-description">
        <MarkdownIFrame textString={description} styles={DESCRIPTION_STYLES} />
      </div>
    );
  };

  const renderFooter = () => {
    const galleryTab = queryParams.get(GALLERY_TAB);
    const name = `${galleryTab} Panels`;
    const hash = `#${SELECT_PANEL_PATH}?chartCategory=${type}&chartType=${id}`;

    return (
      <div className="chart-modal-footer">
        <div className="chart-modal-footer-left">
          <TextButton
            size="XL"
            PrefixIcon={<DSArrowLeftIcon />}
            className="chart-modal-footer-back"
            onClick={handleCancel}
          >
            Back
          </TextButton>

          <div className="chart-modal-footer-name">{name}</div>
        </div>
        <div className="chart-modal-footer-right">
          <Link
            to={{
              ...location,
              hash
            }}
          >
            <Button size="large" className="chart-modal-footer-done">
              Add
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  const renderExample = instance => {
    const {
      projectId,
      projectName,
      userName,
      viewId,
      workspaceName
    } = instance;
    const embeddedURL = generateEmbeddedURL(instance);
    const viewURL = `/api/projects/redirect?projectId=${projectId}&viewId=${viewId}`;

    return (
      <div className="chart-gallery-examples-panel" key={embeddedURL}>
        <div className="chart-gallery-examples-panel-links">
          <div className="chart-gallery-examples-panel-owner">
            <strong>Owner:</strong>
            <Link data-mask-test="username" to={`/${userName}`}>
              {userName}
            </Link>
          </div>

          <div className="chart-gallery-examples-panel-project">
            <strong>Project:</strong>
            <a href={viewURL} rel="noopener noreferrer" target="_blank">
              {workspaceName} / {projectName}
            </a>
          </div>
        </div>

        <iframe src={embeddedURL} />
      </div>
    );
  };

  const renderExamples = () => {
    if (!instances) {
      return (
        <SmallLoader
          isFlexDisplay
          primaryMessage="Loading..."
          secondaryMessage="Generating panel examples"
        />
      );
    }

    if (!instances.length) {
      return (
        <div className="chart-gallery-examples-error-container">
          <div className="chart-gallery-examples-error">
            <i className="material-icons">warning</i>
            <p className="chart-gallery-examples-error-msg">
              This panel has no available examples for preview
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="chart-gallery-examples-panels">
        {instances.map(renderExample)}
      </div>
    );
  };

  const renderContent = () => {
    if (tabValue === TAB_VALUES.EXAMPLES) return renderExamples();

    if (tabValue === TAB_VALUES.DESCRIPTION) return renderDescription();

    return null;
  };

  const renderSidebar = () => {
    return (
      <div className="chart-gallery-preview-sidebar">
        <div className="chart-gallery-preview-sidebar-template-details">
          <div className="chart-gallery-preview-sidebar-title">{title}</div>

          {owner && (
            <div className="chart-gallery-preview-sidebar-owner">
              <span>Owner: {owner}</span>
            </div>
          )}

          <div className="chart-gallery-preview-sidebar-rank">
            <span>Rank: {rank.voteCount}</span>
          </div>

          <div className="chart-gallery-preview-sidebar-thumbnail">
            <img src={thumbnail} className="charts-gallery-thumbnail" />
          </div>
        </div>
      </div>
    );
  };

  const renderTabBar = () => (
    <StyledTabs onChange={handleTabChange} value={tabValue}>
      <StyledTab label="Examples" value={TAB_VALUES.EXAMPLES} />
      <StyledTab
        disabled={!description}
        label="Description"
        value={TAB_VALUES.DESCRIPTION}
      />
    </StyledTabs>
  );

  const renderTabs = () => (
    <div className="chart-gallery-preview-tabs">
      {renderTabBar()}
      {renderContent()}
    </div>
  );

  return (
    <>
      <DialogContent className="chart-gallery-preview">
        <div className="chart-gallery-preview-container">
          {renderSidebar()}
          {renderTabs()}
        </div>
      </DialogContent>

      <DialogActions
        className="panel-preview panel-preview-templates chart-gallery-preview-footer"
        disableSpacing
      >
        {renderFooter()}
      </DialogActions>
    </>
  );
};

PanelPreviewDialog.defaultProps = {
  instances: null,
  open: false
};

PanelPreviewDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  instances: PropTypes.array,
  open: PropTypes.bool,
  template: PropTypes.object.isRequired
};

const mapStateToProps = () => {
  const getTemplateInstances = makeGetTemplateInstances();

  return (state, props) => ({
    instances: getTemplateInstances(state, {
      templateId: props.template.id
    })
  });
};

export default connect(mapStateToProps)(PanelPreviewDialog);
