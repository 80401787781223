import PropTypes from 'prop-types';

const MemberName = ({ isEmail, email, lastName, firstName, username }) => {
  if (isEmail) {
    return email;
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return username;
};

MemberName.propTypes = {
  avatar: PropTypes.string,
  isWsOwner: PropTypes.bool
};

MemberName.defaultProps = {
  avatar: null,
  isWsOwner: undefined
};

export default MemberName;
