import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ds';
import { DSCloseCircleIcon } from '@ds-icons';

export const DismissSelectionButton = ({ dismissHandler, disabled }) => {
  return (
    <Button
      PrefixIcon={<DSCloseCircleIcon />}
      onClick={dismissHandler}
      disabled={disabled}
      size="large"
    >
      Dismiss
    </Button>
  );
};

DismissSelectionButton.propTypes = {
  dismissHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
