import React from 'react';
import PropTypes from 'prop-types';

import SearchBar from 'material-ui-search-bar';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const StyledSearchBar = ({
  entityName,
  handleCancelSearch,
  handleSearchChange,
  searchValue,
  style = {},
  ...searchBarProps
}) => {
  return (
    <SearchBar
      {...searchBarProps}
      className="list-search-bar"
      value={searchValue}
      placeholder={`Search ${entityName}`}
      searchIcon={<SearchIcon />}
      closeIcon={<CloseIcon />}
      onCancelSearch={handleCancelSearch}
      onChange={handleSearchChange}
      onRequestSearch={handleSearchChange}
      style={{
        minWidth: 225,
        ...style
      }}
    />
  );
};

StyledSearchBar.defaultProps = {
  entityName: '',
  searchValue: ''
};

StyledSearchBar.propTypes = {
  entityName: PropTypes.string,
  handleCancelSearch: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  style: PropTypes.object
};

export default StyledSearchBar;
