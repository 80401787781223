import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLORS } from '@/constants/colorConstants';

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: COLORS.primary,
    height: '3px'
  },
  root: {
    minHeight: '52px'
  }
})(Tabs);

export default StyledTabs;
