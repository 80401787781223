import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { formatDateTime, formatTimeNumber } from '@shared/utils/displayHelpers';
import ExperimentTag from '@shared/components/TableCells/ExperimentTag';
import ActiveStatusIcon from '@experiment-management-shared/components/ActiveStatusIcon';
import useProjectTags from '@API/project/useProjectTags';

const typeDisplay = (value, type) => {
  if (type === 'datetime') {
    return formatDateTime(value);
  }
  if (type === 'timenumber') {
    return formatTimeNumber(value);
  }
  if (type === 'boolean') {
    return String(value);
  }

  return String(value);
};

const groupedTagsDisplay = (tagName, tags) => {
  if (!tagName) {
    return <div className="grouped-row-cell">No tags</div>;
  }

  const color = tags.find(tag => tag.name === tagName)?.color;

  return (
    <div className="grouped-row-cell">
      <ExperimentTag key={tagName} tagName={tagName} tagColor={color} />
    </div>
  );
};

const groupedStatusDisplay = runActive => {
  return (
    <div className="grouped-row-cell">
      <ActiveStatusIcon loading={runActive} success={!runActive} />
    </div>
  );
};

const GroupContent = props => {
  const { column, row, expanded } = props;
  const { data: tags = [] } = useProjectTags();
  let cell;
  if (row.groupedBy === 'experimentTags') {
    cell = groupedTagsDisplay(row.value, tags);
  } else if (row.groupedBy === 'runActive') {
    cell = groupedStatusDisplay(row.value);
  } else {
    cell = typeDisplay(row.value, column.type);
  }

  return (
    <div
      className={cx('react-grid-group-content', {
        'react-grid-group-active': expanded
      })}
    >
      <div className="react-grid-group-content-title">{column.title}:</div>
      <div className="react-grid-group-content-value">{cell}</div>
    </div>
  );
};

GroupContent.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  row: PropTypes.object.isRequired,
  expanded: PropTypes.array.isRequired
};

export default GroupContent;
