import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DashboardButton from '@experiment-management-shared/components/DashboardButton/index';
import ExperimentsFiltersSidebarContainer from '@experiment-management-shared/components/ExperimentFiltersSidebarContainer';
import { getRulesCount } from '@shared/utils/filterHelpers';

const filtersIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.723858 0.223858 0.5 0.5 0.5H11.5C11.7761 0.5 12 0.723858 12 1C12 1.27614 11.7761 1.5 11.5 1.5H0.5C0.223858 1.5 0 1.27614 0 1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 4.5C1.5 4.22386 1.72386 4 2 4H10C10.2761 4 10.5 4.22386 10.5 4.5C10.5 4.77614 10.2761 5 10 5H2C1.72386 5 1.5 4.77614 1.5 4.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8C3 7.72386 3.22386 7.5 3.5 7.5H8.5C8.77614 7.5 9 7.72386 9 8C9 8.27614 8.77614 8.5 8.5 8.5H3.5C3.22386 8.5 3 8.27614 3 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 11.5C4.5 11.2239 4.72386 11 5 11H7C7.27614 11 7.5 11.2239 7.5 11.5C7.5 11.7761 7.27614 12 7 12H5C4.72386 12 4.5 11.7761 4.5 11.5Z"
      fill="currentColor"
    />
  </svg>
);

const FiltersButton = ({ onChange, query, showButtonLabel, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const rulesCount = getRulesCount(query.rulesTree);

  const countLabel = rulesCount ? `${rulesCount}` : '';
  const label = showButtonLabel ? 'Filters' : '';

  return (
    <>
      <DashboardButton
        tooltipTitle="Filter experiments"
        disabled={disabled}
        isOpened={isOpen}
        label={label}
        subLabel={countLabel}
        onClick={() => setIsOpen(true)}
        icon={filtersIcon}
      />

      <ExperimentsFiltersSidebarContainer
        isOpen={isOpen}
        onChange={onChange}
        onClose={() => setIsOpen(false)}
        query={query}
      />
    </>
  );
};

FiltersButton.defaultProps = {
  disabled: false
};

FiltersButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  showButtonLabel: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

export default FiltersButton;
