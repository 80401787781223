import { OptionType } from '@design-system/types';
import React from 'react';
import { ValueContainerProps, components } from 'react-select';
import SingleValue from './SingleValue';

const ValueContainer = ({
  children,
  selectProps,
  ...props
}: ValueContainerProps<OptionType>) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
    innerProps: {},
    getClassNames: props.getClassNames,
    isDisabled: selectProps.isDisabled
  };

  return (
    <components.ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, child => {
        if (child) return child;

        if (selectProps.value) {
          return (
            <SingleValue
              {...commonProps}
              data={selectProps.value as OptionType}
            >
              {selectProps.getOptionLabel(props.getValue()[0])}
            </SingleValue>
          );
        }

        return (
          <components.Placeholder
            {...commonProps}
            key="placeholder"
            isFocused={Boolean(selectProps.isFocused)}
          >
            {selectProps.placeholder}
          </components.Placeholder>
        );
      })}
    </components.ValueContainer>
  );
};
export default ValueContainer;
