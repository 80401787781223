import { HyperParam } from '@shared/types/hyperParam';
import useExperimentParams, {
  ExperimentParamsResponse,
  UseExperimentParamsConfig
} from './useExperimentParams';

const getExperimentParamsMap = (responses: ExperimentParamsResponse[]) => {
  const experimentParamsMap = {} as Record<string, HyperParam[]>;

  responses.forEach(paramResp => {
    const data = paramResp?.data;
    if (!data) return;
    const { experimentKey, results } = data;

    experimentParamsMap[experimentKey] = results;
  });
  return experimentParamsMap;
};

const useExperimentKeyParamMap = (
  config: UseExperimentParamsConfig<Record<string, HyperParam[]>> = {}
) => {
  return useExperimentParams({
    ...config,
    select: getExperimentParamsMap
  });
};

export default useExperimentKeyParamMap;
