import React from 'react';
import PropTypes from 'prop-types';

const SidebarActionsFooter = ({ children, isVisible }) => {
  return (
    <div
      className="sidebar-actions-footer"
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      {children}
    </div>
  );
};

SidebarActionsFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default SidebarActionsFooter;
