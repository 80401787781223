import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';
import classNames from './FieldsList.module.scss';

const FieldsListItem = ({
  dataTest,
  uniqueKey,
  index,
  node,
  removeButton: {
    element: removeButtonEl = null,
    tooltip: removeButtonTooltip
  } = {}
}) => {
  return (
    <Grid key={uniqueKey} item>
      <Grid
        data-test={dataTest ? `${dataTest}-item-${index}` : `item-${index}`}
        container
        spacing={1}
        alignItems="stretch"
        alignContent="stretch"
        justifyContent="center"
      >
        <Grid item xs={11}>
          {node}
        </Grid>
        <Grid item xs={1}>
          <Grid
            container
            alignItems="stretch"
            alignContent="stretch"
            justifyContent="center"
          >
            <Grid item className={classNames.dsFieldsListRemoveBtnWrapper}>
              {removeButtonEl && removeButtonTooltip ? (
                <Tooltip arrow placement="top" title={removeButtonTooltip}>
                  <span>{removeButtonEl}</span>
                </Tooltip>
              ) : (
                removeButtonEl
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FieldsListItem.defaultProps = {
  dataTest: null
};

FieldsListItem.propTypes = {
  dataTest: PropTypes.string,
  uniqueKey: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  node: PropTypes.node.isRequired,
  removeButton: PropTypes.shape({
    element: PropTypes.node.isRequired,
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  }).isRequired
};

export default FieldsListItem;
