import React, { useCallback, useState } from 'react';

import {
  ACTIVE_FETCH_INTERVAL,
  BUILT_IN_CHART_TYPES
} from '@/lib/appConstants';
import {
  useVideoPanelData,
  useVideoPanelSteps
} from '@experiment-management-shared/api';
import ChartContainer, {
  GeneralChartContainerProps
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import {
  UsePanelConfigsParams,
  usePanelConfigs
} from '@experiment-management-shared/hooks';
import { AssetStep, VideoAssetData } from '@experiment-management-shared/types';

import {
  AssetThumbnailRendererProps,
  GridPanelSlider,
  GridPanelTiles,
  usePreviewAssets,
  useStepsProps,
  useTargetExperimentKeys
} from '../GridPanel';
import { VideoDetailsModal } from './VideoDetailsModal';
import { VideoThumbnail } from './VideoThumbnail';
import {
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
} from '@experiment-management-shared/components/Charts/Chart/useChartHeaderMenu';

const HIDDEN_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
];

type Experiment = UsePanelConfigsParams['experiments'][number];

type VideoPanelProps = {
  activeIntervalFetchDelay?: number;
  chartName?: string;
  containerProps: GeneralChartContainerProps;
  experimentKeys: string[];
  experimentNameAsLink?: boolean;
  experiments?: Experiment[];
  experimentsCount?: number;
  hiddenExperimentKeys?: string[];
  isAssetClickable?: boolean;
  isAutoRefreshEnabled?: boolean;
  isStepsEditable?: boolean;
  step?: AssetStep;
  title?: string;
  // backward compatibility - assetNames
  videos: string[];
};

const DEFAULT_EXPERIMENTS: Experiment[] = [];
const DEFAULT_HIDDEN_EXPERIMENT_KEYS: string[] = [];

export const VideoPanel = ({
  activeIntervalFetchDelay = ACTIVE_FETCH_INTERVAL,
  chartName = '',
  containerProps,
  experimentKeys,
  experimentNameAsLink = false,
  experiments = DEFAULT_EXPERIMENTS,
  experimentsCount = 5,
  hiddenExperimentKeys = DEFAULT_HIDDEN_EXPERIMENT_KEYS,
  isAssetClickable = false,
  isAutoRefreshEnabled = false,
  isStepsEditable = true,
  step = 0,
  title,
  videos: assetNames
}: VideoPanelProps) => {
  const { actualTitle, shouldRefetch } = usePanelConfigs({
    chartName,
    chartType: BUILT_IN_CHART_TYPES.video,
    experiments,
    isAutoRefreshEnabled,
    title
  });
  const targetExperimentKeys = useTargetExperimentKeys({
    experimentKeys,
    experimentsCount,
    hiddenExperimentKeys
  });
  const { data: steps, isLoading: isLoadingStepsData } = useVideoPanelSteps(
    { assetNames, experimentKeys: targetExperimentKeys },
    {
      enabled: isStepsEditable,
      keepPreviousData: true,
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );
  const { sliderProps, stepAPIValue } = useStepsProps({
    isLoading: isLoadingStepsData,
    step,
    steps
  });
  const {
    data: serverAssets,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useVideoPanelData(
    {
      assetNames,
      experimentKeys: targetExperimentKeys,
      step: stepAPIValue || 0
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true,
      keepPreviousData: true
    }
  );

  const previewAssets = usePreviewAssets<VideoAssetData>({
    assetNames,
    assets: serverAssets,
    experimentKeys: targetExperimentKeys
  });

  const [previewAsset, setPreviewAsset] = useState<VideoAssetData | null>(null);

  const videoPanelRenderAssetThumbnail = useCallback(
    ({ asset, onClick, src }: AssetThumbnailRendererProps<VideoAssetData>) => {
      return <VideoThumbnail name={asset?.name} onClick={onClick} src={src} />;
    },
    []
  );

  const handleAssetClick = (asset?: VideoAssetData) => {
    if (isAssetClickable && asset) {
      setPreviewAsset(asset);
    }
  };

  const renderStepsSlider = () => {
    if (!isStepsEditable) {
      return null;
    }

    return <GridPanelSlider {...sliderProps} />;
  };

  return (
    <>
      {previewAsset && (
        <VideoDetailsModal
          assets={previewAssets}
          onClose={() => setPreviewAsset(null)}
          selectedAsset={previewAsset}
        />
      )}
      <ChartContainer
        {...containerProps}
        customHeaderContent={renderStepsSlider()}
        hasData={Boolean(assetNames?.length)}
        hiddenMenuItems={HIDDEN_MENU_ITEMS}
        isError={isError}
        isFetching={isFetching}
        isLoading={isLoading}
        isPreviousData={isPreviousData}
        title={actualTitle}
      >
        <GridPanelTiles
          assetNames={assetNames}
          assets={serverAssets}
          experimentKeys={targetExperimentKeys}
          experimentNameAsLink={experimentNameAsLink}
          experiments={experiments}
          isAssetClickable={isAssetClickable}
          onAssetClick={handleAssetClick}
          renderAssetThumbnail={videoPanelRenderAssetThumbnail}
        />
      </ChartContainer>
    </>
  );
};

VideoPanel.CONFIG_PROPERTIES = [
  'activeIntervalFetchDelay',
  'chartName',
  'containerProps',
  'experimentKeys',
  'experimentNameAsLink',
  'experiments',
  'experimentsCount',
  'hiddenExperimentKeys',
  'isAssetClickable',
  'isAutoRefreshEnabled',
  'isStepsEditable',
  'step',
  'title',
  'videos'
];

export default VideoPanel;
