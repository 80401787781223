import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import get from 'lodash/get';

import { RoundedTabs } from '@DesignSystem/navigation';

import {
  ARCHIVED_EXPERIMENTS_SIDEBAR_TABS,
  EXPERIMENTS_SIDEBAR_TABS
} from '@projects/constants';

import FiltersButton from '@experiment-management-shared/components/DashboardButton/FiltersButton';
import SortButton from '@experiment-management-shared/components/DashboardButton/SortButton';
import ColumnsButton from '@experiment-management-shared/components/DashboardButton/ColumnsButton';
import GroupByButton from '@experiment-management-shared/components/DashboardButton/GroupByButton';

import { EXCLUDED_VIRTUAL_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import { getNameColumnWidth } from '@experiment-management-shared/utils/EMView';
import { projectsActionTypes } from '@/constants/actionTypes';
import Divider from '@material-ui/core/Divider';

const ExperimentsHeader = ({
  allowSorting,
  dashboardTable,
  dashboardQuery,
  hiddenExperimentKeys,
  activeExperimentsHeaderTab,
  isArchive,
  onColumnsChange,
  onGroupByChange,
  onQueryChanges,
  onSortingChanges,
  showButtonLabels
}) => {
  const dispatch = useDispatch();

  const {
    columnGrouping,
    columnOrders,
    columnSorting: allColumnSorting,
    columnWidths,
    selection
  } = dashboardTable;
  const columnSorting = useMemo(() => {
    return allColumnSorting.filter(({ columnName }) => columnName !== 'pinned');
  }, [allColumnSorting]);

  const handleTabChange = useCallback(
    newTabIndex => {
      dispatch({
        type: projectsActionTypes.SET_ACTIVE_EXPERIMENTS_HEADER_TAB,
        payload: { activeExperimentsHeaderTab: newTabIndex }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const tabsAvailabilityArray = isArchive
      ? [false, !selection.length, true]
      : [false, !selection.length, !hiddenExperimentKeys.length];

    const isEmptyTab = tabsAvailabilityArray[activeExperimentsHeaderTab];

    if (isEmptyTab) {
      handleTabChange(0);
    }
  }, [
    activeExperimentsHeaderTab,
    handleTabChange,
    hiddenExperimentKeys,
    selection,
    isArchive
  ]);

  const getExperimentHeaderTabs = () => {
    const counts = {
      Hidden: hiddenExperimentKeys.length,
      Selected: selection.length
    };

    const tabs = isArchive
      ? ARCHIVED_EXPERIMENTS_SIDEBAR_TABS
      : EXPERIMENTS_SIDEBAR_TABS;

    return tabs.map((tab, index) => {
      const { label } = tab;
      const count = get(counts, label, '');

      return {
        label: count ? `${label} (${count})` : label,
        disabled: index === 0 ? false : !count
      };
    });
  };

  const nameWidth = getNameColumnWidth(columnWidths);

  return (
    <div className="experiments-header">
      <div className="experiments-header-main">
        {isArchive ? (
          <div
            data-test="archive-tab-title"
            className="experiments-header-title"
          >
            Archive
          </div>
        ) : (
          <div
            data-test="experiments-tab-title"
            className="experiments-header-title"
          >
            Experiments
          </div>
        )}

        <div className="experiments-header-buttons">
          <FiltersButton
            onChange={onQueryChanges}
            query={dashboardQuery}
            showButtonLabel={showButtonLabels}
          />

          <SortButton
            columnSorting={columnSorting}
            disabled={!allowSorting}
            onChange={onSortingChanges}
            showButtonLabel={showButtonLabels}
          />

          <ColumnsButton
            onChange={onColumnsChange}
            selectedColumnNames={columnOrders}
            showButtonLabel={showButtonLabels}
          />

          <GroupByButton
            onChange={onGroupByChange}
            selectedColumnNames={columnGrouping}
            showButtonLabel={showButtonLabels}
            excludedColumns={EXCLUDED_VIRTUAL_COLUMNS}
          />
        </div>
      </div>

      <Divider variant="middle" />

      <div className="experiments-header-tabs-container">
        <RoundedTabs
          tabs={getExperimentHeaderTabs()}
          activeTabIndex={activeExperimentsHeaderTab}
          onTabChange={handleTabChange}
          containerWidth={nameWidth}
        />
      </div>
    </div>
  );
};

ExperimentsHeader.defaultProps = {
  allowSorting: true
};

ExperimentsHeader.propTypes = {
  allowSorting: PropTypes.bool,
  dashboardTable: PropTypes.object.isRequired,
  dashboardQuery: PropTypes.object.isRequired,
  hiddenExperimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeExperimentsHeaderTab: PropTypes.number.isRequired,
  isArchive: PropTypes.bool.isRequired,
  onColumnsChange: PropTypes.func.isRequired,
  onGroupByChange: PropTypes.func.isRequired,
  onQueryChanges: PropTypes.func.isRequired,
  onSortingChanges: PropTypes.func.isRequired,
  showButtonLabels: PropTypes.bool.isRequired
};

export default ExperimentsHeader;
