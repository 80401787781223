import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MemberName from './MemberName';
import { DSColors } from '@design-system-outdated/constants';

const MemberTooltip = props => {
  const { isWsOwner } = props;
  const name = <MemberName {...props} />;
  const additionalTooltipText = isWsOwner ? (
    <Box component={Typography} variant="text2" color={DSColors.grayColor5}>
      Owner
    </Box>
  ) : (
    ''
  );

  return (
    <Box>
      <Box>{name}</Box>
      {additionalTooltipText}
    </Box>
  );
};

MemberTooltip.propTypes = {
  avatar: PropTypes.string,
  isWsOwner: PropTypes.bool
};

MemberTooltip.defaultProps = {
  avatar: null,
  isWsOwner: undefined
};

export default MemberTooltip;
