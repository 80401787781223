import sortedIndex from 'lodash/sortedIndex';

const linearInterpolate = (
  x: number,
  x0: number,
  x1: number,
  y0: number,
  y1: number
) => {
  return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
};

const handleBorderCoordinate = (
  x: number,
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  nullifyOutOfRange: boolean
): number | null => {
  if (x === x0) {
    return y0;
  }

  if (x === x1) {
    return y1;
  }

  if (nullifyOutOfRange) {
    return null;
  }

  if (x < x0) {
    return x0;
  }

  return x1;
};

/**
 Array linear interpolation for monotonically increasing sample points
 * @param xToInterpolate - The x-coordinates at which to evaluate the interpolated values
 * @param x - The x-coordinates of the data points, must be increasing
 * @param y - The y-coordinates of the data points, same length as x.
 * @param nullifyOutOfRange - If the coordinates out of the x range should be null
 * @returns interpolatedArray
 */
export const interpolateArray = (
  xToInterpolate: number[],
  x: number[],
  y: number[],
  nullifyOutOfRange = true
) => {
  const res: (number | null)[] = [];

  if (x?.length !== y?.length) {
    return [];
  }

  xToInterpolate.forEach(value => {
    if (value <= x[0] || value >= x[x.length - 1]) {
      const interpolatedValue = handleBorderCoordinate(
        value,
        x[0],
        y[0],
        x[x.length - 1],
        y[y.length - 1],
        nullifyOutOfRange
      );

      res.push(interpolatedValue);
    } else {
      const index = sortedIndex(x, value) - 1;
      const interpolatedValue = linearInterpolate(
        value,
        x[index],
        x[index + 1],
        y[index],
        y[index + 1]
      );

      res.push(interpolatedValue);
    }
  });

  return res;
};
