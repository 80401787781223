import React from 'react';
import { useParams } from 'react-router';

import { useIsUserMemberOfWorkspace } from '@shared/hooks';
import useModelRegistryNotes from '@model-registry/api/useModelRegistryNotes';
import useModelRegistryNotesMutation from '@model-registry/api/useModelRegistryNotesMutation';
import ModelRegistryNotes from '@model-registry/components/ModelRegistryNotes';

const ModelRegistryNotesTab = () => {
  const { modelName, workspace } = useParams();
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const { data: modelNotes, isLoading, isError } = useModelRegistryNotes({
    modelName,
    workspace
  });
  const updateNotesMutation = useModelRegistryNotesMutation({
    modelName,
    workspace
  });

  return (
    <ModelRegistryNotes
      isEmpty={isError}
      dataTest="model-registry-notes-tab-content"
      isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
      isLoading={isLoading}
      modelNotes={modelNotes}
      handleUpdate={newNotes => {
        updateNotesMutation.mutate(newNotes);
      }}
    />
  );
};
export default ModelRegistryNotesTab;
