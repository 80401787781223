import { useQuery } from 'react-query';

import organizationApi from '@shared/api/organizationApi';

const getOrganizationSettingByName = async (
  organizationId,
  { signal, settingName }
) => {
  const { data } = await organizationApi.fetchOrganizationSetting(
    organizationId,
    {
      settingName,
      signal
    }
  );

  return data?.value;
};

const useOrganizationSettingByName = (organizationId, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['organizationSetting', { organizationId }],
    ({ signal }) =>
      getOrganizationSettingByName(organizationId, {
        signal,
        ...config
      }),
    {
      enabled: !!organizationId && configEnabled
    }
  );
};

export default useOrganizationSettingByName;
