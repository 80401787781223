import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const billingApi = {
  addPayment(organizationId, data) {
    const url = `${BASE_API_URL}organizations/${organizationId}/payments/new`;
    return axios.post(url, JSON.stringify(data));
  },

  updatePlan(organizationId, plan) {
    const data = { plan };
    const url = `${BASE_API_URL}organizations/${organizationId}/payments/update`;
    return axios.post(url, JSON.stringify(data));
  },

  fetchBilling(organizationId) {
    const url = `${BASE_API_URL}organizations/${organizationId}/billing`;

    return axios.get(url);
  },

  updatePayment(organizationId, token) {
    const url = `${BASE_API_URL}organizations/${organizationId}/payments/update-card?organizationId=${organizationId}&token=${token}`;
    return axios.post(url);
  },

  cancelPaymentPlan(organizationId) {
    const url = `${BASE_API_URL}organizations/${organizationId}/payments/cancel`;
    return axios.post(url);
  },

  saveUserAcademicDetails({
    position = null,
    published = null,
    academicEmail = null,
    graduationYear = null,
    positionOther = null,
    organizationId = null
  } = {}) {
    const data = {
      academicEmail,
      position,
      published,
      graduationYear,
      positionOther,
      organizationId
    };
    const url = `${BASE_API_URL}academic/save`;
    return axios.post(url, JSON.stringify(data));
  },

  sendPlanNotify() {}
};

export default billingApi;
