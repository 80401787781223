import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import findIndex from 'lodash/findIndex';

import {
  PanelGlobalConfig,
  PanelSection,
  PanelSections
} from '@experiment-management-shared/types';
import {
  calculatePanelsCount,
  generateEmptySection,
  getSectionById
} from '@experiment-management-shared';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import useTrackEventLocation from '@shared/hooks/useTrackEventLocation';
import usePanelMoveToSection from '@experiment-management-shared/hooks/usePanelMoveToSection';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import { generateRegexFromString } from '@shared/utils/displayHelpers';
import { TextButton } from '@ds';
import { DSPlusIcon } from '@ds-icons';
import PanelSectionWrapper, {
  FilteredPanelsSectionMap,
  FilteredPanelsSectionsMap,
  PanelSectionEventsMap,
  PanelSharedConfig
} from './PanelSectionWrapper';
import {
  Active,
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
  closestCenter,
  getClientRect
} from '@dnd-kit/core';
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext
} from '@dnd-kit/sortable';

import styles from './PanelSectionsWrapper.module.scss';

type PanelSectionsWrapperProps = {
  search: string;
  sections: PanelSections;
  panelGlobalConfig: PanelGlobalConfig;
  onSectionsChange: (sections: PanelSections) => void;
  panelHandlers?: Record<string, (value: unknown) => void>;
  resetSearch: () => void;
  scrollContainer?: HTMLElement;
  defaultSampleSize: number;
  eventsMap: PanelSectionEventsMap;
  configModifier?: (config: unknown) => unknown;
  panelSharedConfig: PanelSharedConfig;
};

const PanelSectionsWrapper = ({
  search,
  sections,
  panelGlobalConfig,
  onSectionsChange,
  panelHandlers,
  resetSearch,
  scrollContainer = window.document.documentElement,
  defaultSampleSize,
  eventsMap,
  configModifier,
  panelSharedConfig
}: PanelSectionsWrapperProps) => {
  const isSearchMode = Boolean(search);
  const scrollSectionIdRef = useRef<string>('');
  const dragTopCoordinate = useRef<number>(0);
  const baseTrackEvent = useBaseTrackEvent();
  const trackEventLocation = useTrackEventLocation();

  const [active, setActive] = useState<Active | null>(null);

  const measuringConfig = useMemo(
    () => ({
      draggable: {
        measure: (element: HTMLElement) => {
          const rect = getClientRect(element);

          if (dragTopCoordinate.current === 0) {
            dragTopCoordinate.current = rect.top;
          }

          return {
            ...rect,
            top: dragTopCoordinate.current
          };
        }
      }
    }),
    []
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // need to prevent panels rerender after user click on drag handle,
        // the drag will be initialized when user will move mouse at least for 1 pixel
        distance: 1
      }
    })
  );

  const searchRegex = generateRegexFromString(search);

  const { sectionsFilterMap, searchTerm } = useMemo(() => {
    let map = sections.reduce<FilteredPanelsSectionsMap>((acc, section) => {
      acc[section.id] = undefined;
      return acc;
    }, {});

    if (search) {
      if (!searchRegex) {
        map = sections.reduce<FilteredPanelsSectionsMap>((acc, section) => {
          acc[section.id] = {};
          return acc;
        }, {});
      } else {
        map = sections.reduce<FilteredPanelsSectionsMap>((acc, section) => {
          acc[section.id] = section.panels
            .filter(panel =>
              chartHelpers.regexSearchPanelsByName(
                searchRegex,
                chartHelpers.extendConfigWithGlobalConfig(
                  panel,
                  panelGlobalConfig,
                  panelSharedConfig.globalConfigMap
                )
              )
            )
            .reduce<FilteredPanelsSectionMap>((acc1, panel) => {
              acc1[panel.chartId] = true;
              return acc1;
            }, {});
          return acc;
        }, {});
      }
    }

    return {
      sectionsFilterMap: map,
      searchTerm: search
    };
  }, [
    sections,
    search,
    searchRegex,
    panelGlobalConfig,
    panelSharedConfig.globalConfigMap
  ]);

  const searchTermRef = useRef('');

  useEffect(() => {
    const totalPanelsCount = calculatePanelsCount(sections);
    const filteredPanelsCount = Object.keys(sectionsFilterMap).reduce(
      (acc, key) =>
        acc +
        (sectionsFilterMap[key]
          ? Object.keys(sectionsFilterMap[key] ?? {}).length
          : 0),
      0
    );

    if (searchTerm && searchTermRef.current !== searchTerm) {
      searchTermRef.current = searchTerm;
      baseTrackEvent(eventsMap.searchPanels, {
        search_term: searchTerm,
        total_number_of_panels: totalPanelsCount,
        matched_number_of_panels: filteredPanelsCount
      });
    }
  }, [
    baseTrackEvent,
    eventsMap.searchPanels,
    sectionsFilterMap,
    searchTerm,
    sections
  ]);

  const { activeSection, activeSectionFilterMap } = useMemo(
    () => ({
      activeSection: getSectionById(sections, (active?.id ?? '') as string),
      activeSectionFilterMap: active?.id
        ? sectionsFilterMap[active.id]
        : undefined
    }),
    [sections, active?.id, sectionsFilterMap]
  );

  const panelMoveConfig = usePanelMoveToSection({
    sections,
    onSectionsChange
  });

  const handleDragCancel = useCallback(() => {
    setActive(null);
  }, []);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    dragTopCoordinate.current = 0;
    setActive(event.active);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = sections.findIndex(
          section => section.id === active.id
        );
        const newIndex = sections.findIndex(section => section.id === over.id);

        onSectionsChange(arrayMove(sections, oldIndex, newIndex));
        handleDragCancel();
      }
    },
    [handleDragCancel, onSectionsChange, sections]
  );

  const handleToggleSectionBIEvent = useCallback(
    (open: boolean) => {
      baseTrackEvent('PANELS_SECTION_TOGGLED', {
        location: trackEventLocation,
        open
      });
    },
    [baseTrackEvent, trackEventLocation]
  );

  const handleAddSectionBIEvent = useCallback(
    (position: 'above' | 'below' | 'page_bottom') => {
      baseTrackEvent('PANELS_SECTION_ADDED', {
        location: trackEventLocation,
        position
      });
    },
    [baseTrackEvent, trackEventLocation]
  );

  const handleAddSection = useCallback(() => {
    onSectionsChange([...sections, generateEmptySection('New section')]);
    handleAddSectionBIEvent('page_bottom');
  }, [handleAddSectionBIEvent, onSectionsChange, sections]);

  const handleDeleteSection = useCallback(
    (id: string) => {
      const sectionToDelete = getSectionById(sections, id);
      let updatedSections: PanelSections = [];

      // special handling that not allow to delete last section (it's actually re-creating automatically )
      if (sections.length === 1) {
        updatedSections.push(generateEmptySection());
      } else {
        updatedSections = sections.filter(section => section.id !== id);
      }

      baseTrackEvent('PANELS_SECTION_DELETED', {
        location: trackEventLocation,
        number_of_panels: sectionToDelete.panels.length,
        autogenerated_section: sectionToDelete.autogenerated
      });

      onSectionsChange(updatedSections);
    },
    [baseTrackEvent, onSectionsChange, sections, trackEventLocation]
  );

  const handleAddSectionById = useCallback(
    (id: string, shift: number) => {
      const index = findIndex(sections, section => section.id === id);
      const updatedSections = sections.slice();
      const newSection = generateEmptySection('New section');

      updatedSections.splice(index + shift, 0, newSection);
      onSectionsChange(updatedSections);
      handleAddSectionBIEvent(shift === 0 ? 'above' : 'below');
      scrollSectionIdRef.current = newSection.id;
    },
    [handleAddSectionBIEvent, onSectionsChange, sections]
  );

  const onSectionChange = useCallback(
    (updatedSection: PanelSection) => {
      onSectionsChange(
        sections.map(section => {
          if (section.id === updatedSection.id) {
            return updatedSection;
          }

          return section;
        })
      );
    },
    [onSectionsChange, sections]
  );

  const scrollTo = useCallback(
    node => {
      if (scrollContainer && node) {
        const TOOLBAR_HEIGHT = 64;
        const top =
          scrollContainer.scrollTop +
          node.getBoundingClientRect().top -
          TOOLBAR_HEIGHT;

        scrollContainer.scroll({ top, behavior: 'smooth' });
      }
    },
    [scrollContainer]
  );

  const renderPanelSection = ({
    filterMap,
    section,
    scrollToRef,
    isDragPreview = false
  }: {
    filterMap: FilteredPanelsSectionMap | undefined;
    section: PanelSection;
    scrollToRef?: (node: HTMLElement) => void;
    isDragPreview?: boolean;
  }) => {
    return (
      <PanelSectionWrapper
        scrollToRef={scrollToRef}
        key={section.id}
        filterMap={filterMap}
        section={section}
        panelGlobalConfig={panelGlobalConfig}
        isSearchMode={isSearchMode}
        onSectionChange={onSectionChange}
        panelHandlers={panelHandlers}
        resetSearch={resetSearch}
        regexpError={!searchRegex}
        handleDeleteSection={handleDeleteSection}
        handleAddSectionById={handleAddSectionById}
        isDragPreview={isDragPreview}
        defaultSampleSize={defaultSampleSize}
        eventsMap={eventsMap}
        configModifier={configModifier}
        panelSharedConfig={panelSharedConfig}
        handleToggleSectionBIEvent={handleToggleSectionBIEvent}
        panelMoveConfig={panelMoveConfig}
      />
    );
  };

  return (
    <div className={styles.panelSectionsWrapper}>
      <DndContext
        sensors={sensors}
        measuring={measuringConfig}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={sections}
          strategy={verticalListSortingStrategy}
        >
          <div className={styles.sectionsContainer}>
            {sections.map(section => {
              let scrollToRef;
              if (section.id === scrollSectionIdRef.current) {
                scrollToRef = scrollTo;
                scrollSectionIdRef.current = '';
              }

              return renderPanelSection({
                filterMap: sectionsFilterMap[section.id],
                section,
                scrollToRef
              });
            })}
          </div>
        </SortableContext>
        <DragOverlay dropAnimation={null}>
          {activeSection &&
            renderPanelSection({
              filterMap: activeSectionFilterMap,
              section: activeSection,
              isDragPreview: true
            })}
        </DragOverlay>
      </DndContext>
      <div className={styles.addSectionContainer}>
        <TextButton
          type="primary"
          size="medium"
          PrefixIcon={<DSPlusIcon />}
          onClick={handleAddSection}
          data-test="add-section-button"
        >
          Add section
        </TextButton>
      </div>
    </div>
  );
};

export default PanelSectionsWrapper;
