import useProject from '@API/project/useProject';
import { SavedReport } from '@reports/types';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes, snackbarTypes } from '../../../constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';

const DUPLICATE_REPORT_ERRORS = {
  NAME_ALREADY_EXISTS: 'NAME_ALREADY_EXISTS'
};

type NameValidationResponse = {
  finalName: string;
  nameExist: boolean;
  originalName: string;
  validatedName: string;
};

const duplicateReport = async ({
  isTemplate,
  newReportName,
  originalReportId,
  projectId,
  revisionId
}: {
  isTemplate: boolean;
  newReportName: string;
  originalReportId: string;
  projectId: string;
  revisionId?: string | null;
}): Promise<SavedReport> => {
  const {
    data: { nameExist }
  } = await api.post<NameValidationResponse>('reports/name-validation', {
    projectId,
    reportName: newReportName
  });

  if (nameExist) {
    throw new Error(DUPLICATE_REPORT_ERRORS.NAME_ALREADY_EXISTS);
  }

  const { data: originalReport } = await api.get<SavedReport>('reports/get', {
    params: {
      isTemplate,
      reportId: originalReportId,
      revisionId
    }
  });

  const {
    description: originalDescription,
    data: originalData
  } = originalReport;

  const { data: duplicatedReport } = await api.post('reports/upsert', {
    data: {
      ...originalData,
      displayName: newReportName,
      reportName: newReportName
    },
    description: originalDescription,
    displayName: newReportName,
    projectId,
    reportName: newReportName
  });

  return duplicatedReport;
};

interface DuplicateReportParams {
  isTemplate: boolean;
  newReportName: string;
  originalReportId: string;
  projectId?: string;
  revisionId?: string | null;
}

export default function useDuplicateReportMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const projectId = project?.projectId;

  return useMutation(
    ({
      isTemplate,
      newReportName,
      originalReportId,
      projectId: reportProjectId = projectId,
      revisionId
    }: DuplicateReportParams) => {
      return duplicateReport({
        isTemplate,
        newReportName,
        originalReportId,
        projectId: reportProjectId,
        revisionId
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['reports', { projectId }]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['reports', { projectId }]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_API_RESPONSE,
            'Successfully duplicated report.'
          )
        );
      },
      onError: (error: Error) => {
        const errorMessage =
          error?.message === DUPLICATE_REPORT_ERRORS.NAME_ALREADY_EXISTS
            ? 'This report name already exists, please choose another.'
            : 'There was an error duplicating your report';

        dispatch(
          alertsUtil.openErrorDialog(dialogTypes.CATCH_ERROR_API, errorMessage)
        );
      }
    }
  );
}
