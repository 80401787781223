import React from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '@ds';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import classes from './ModelRegistryPendingRequestsTab.module.scss';

import { MODEL_VERSION_PENDING_REQUESTS_ACTIONS } from '@model-registry/constants/request-status';

const ModelRegistryPendingRequestsActions = ({
  ifCurrentUserWsOwner,
  ifCurrentUserOwnerOfRequest,
  onClick,
  isLoading,
  modelVersion,
  modelName
}) => {
  if (ifCurrentUserWsOwner) {
    return (
      <div className={classes.pendingRequestButtonContainer}>
        <TextButton
          size="small"
          data-test={`model-registry-pending-requests-approve-status-change-${modelName}-${modelVersion}`}
          key={MODEL_VERSION_PENDING_REQUESTS_ACTIONS.APPROVE}
          disabled={isLoading}
          onClick={() =>
            onClick(MODEL_VERSION_PENDING_REQUESTS_ACTIONS.APPROVE)
          }
        >
          Approve
        </TextButton>
        <div className={classes.divider} />
        <TextButton
          size="small"
          data-test={`model-registry-pending-requests-reject-status-change-${modelName}-${modelVersion}`}
          key={MODEL_VERSION_PENDING_REQUESTS_ACTIONS.REJECT}
          disabled={isLoading}
          onClick={() => onClick(MODEL_VERSION_PENDING_REQUESTS_ACTIONS.REJECT)}
        >
          Reject
        </TextButton>
      </div>
    );
  }

  const cancelButton = (
    <Grid item xs="auto">
      <TextButton
        size="small"
        disabled={!ifCurrentUserOwnerOfRequest || isLoading}
        data-test={`model-registry-pending-requests-cancel-status-change-${modelName}-${modelVersion}`}
        onClick={() => onClick(MODEL_VERSION_PENDING_REQUESTS_ACTIONS.CANCEL)}
      >
        Cancel
      </TextButton>
    </Grid>
  );

  return (
    <Grid container spacing={1} alignItems="center">
      {!ifCurrentUserOwnerOfRequest ? (
        <Tooltip
          arrow
          placement="top"
          title="Only the requester can cancel the request"
        >
          <span>{cancelButton}</span>
        </Tooltip>
      ) : (
        <span>{cancelButton}</span>
      )}
    </Grid>
  );
};

ModelRegistryPendingRequestsActions.defaultProps = {};

ModelRegistryPendingRequestsActions.propTypes = {
  ifCurrentUserOwnerOfRequest: PropTypes.bool.isRequired,
  modelVersion: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ifCurrentUserWsOwner: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ModelRegistryPendingRequestsActions;
