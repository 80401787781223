import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPatch } from 'diff';

import { OutputFormatType } from 'diff2html/lib/types';

import { Diff2HtmlUI } from 'diff2html/bundles/js/diff2html-ui.min';
import 'diff2html/bundles/css/diff2html.min.css';

import './SimpleDiff.scss';

const SimpleDiff = ({
  fileName1,
  content1,
  fileName2,
  content2,
  diffOutputFormat,
  filePath
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const node = containerRef.current;

    if (!node) return;

    const diff = createPatch(
      filePath,
      content1,
      content2,
      fileName1,
      fileName2
    );

    const diff2HtmlUi = new Diff2HtmlUI(node, diff, {
      inputFormat: 'diff',
      fileListToggle: false,
      highlight: true,
      matching: 'lines',
      outputFormat: diffOutputFormat,
      synchronisedScroll: true
    });

    diff2HtmlUi.draw();
  });

  return <div className="simple-diff-container" ref={containerRef} />;
};

SimpleDiff.defaultProps = {
  fileName1: '',
  fileName2: '',
  diffOutputFormat: OutputFormatType.LINE_BY_LINE,
  filePath: ''
};

SimpleDiff.propTypes = {
  fileName1: PropTypes.string,
  content1: PropTypes.string.isRequired,
  fileName2: PropTypes.string,
  content2: PropTypes.string.isRequired,
  diffOutputFormat: PropTypes.string,
  filePath: PropTypes.string
};

export default SimpleDiff;
