import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { calculateExperimentName } from '@experiment-management-shared/utils/experimentHelpers';
import cx from 'classnames';

import OutputTab from '@experiment-details/components/OutputTab';

const OutputCompareTab = ({ experiments }) => {
  const [activeExperiment, setActiveExperiment] = useState(experiments[0]);

  return (
    <div className="output-compare-tab">
      <div className="stdout-buttons">
        {experiments.map(e => {
          return (
            <Button
              key={e.experimentKey}
              onClick={() => setActiveExperiment(e)}
              className={cx({
                active: activeExperiment.experimentKey === e.experimentKey
              })}
            >
              <span className="text-overflow">
                {calculateExperimentName(e)}
              </span>
            </Button>
          );
        })}
      </div>

      <OutputTab experiments={[activeExperiment]} />
    </div>
  );
};

OutputCompareTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default OutputCompareTab;
