import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import React from 'react';
import { isIframed } from '@/helpers/generalHelpers';

const style = {
  position: 'fixed',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0px)'
};

export const DialogWrapper = props => {
  const isIframe = isIframed();

  return (
    <div style={style}>
      <Dialog
        {...props}
        className={classnames(
          `dialog-${props.keyclass} general-dialog-wrapper`
        )}
        maxWidth={false}
        disableAutoFocus={isIframe}
        disableEnforceFocus={isIframe}
      />
    </div>
  );
};
