import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';
import { Table as DxTable } from '@devexpress/dx-react-grid-material-ui';

import { TextButton, Tooltip } from '@ds';
import { EMPTY_TABLE_COLSPAN } from '@shared/constants/empty-table';
import './TableEmptyState.scss';

const TableEmptyState = ({
  label,
  additionalText,
  picturePath,
  pictureWidth,
  pictureHeight,
  icon,
  materialProps,
  tooltipMessage,
  buttonText,
  clickHandler,
  dataTest
}) => (
  <DxTable.Cell
    data-test={dataTest}
    {...materialProps}
    className={cx('table-empty-state', materialProps?.className)}
    colSpan={EMPTY_TABLE_COLSPAN}
  >
    <div className="empty-state-container">
      <div
        className="empty-state-picture"
        style={{
          width: pictureWidth,
          height: pictureHeight,
          backgroundImage: `url(${picturePath})`
        }}
      />
      {icon}
      <div className="empty-state-label">{label}</div>
      {additionalText && (
        <div className="empty-state-additionalText">{additionalText}</div>
      )}
      <Tooltip content={tooltipMessage} placement="top">
        <span>
          {buttonText && (
            <TextButton type="primary" size="small" onClick={clickHandler}>
              {buttonText}
            </TextButton>
          )}
        </span>
      </Tooltip>
    </div>
  </DxTable.Cell>
);

TableEmptyState.propTypes = {
  dataTest: PropTypes.string,
  label: PropTypes.string,
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  materialProps: PropTypes.object,
  picturePath: PropTypes.string,
  pictureWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pictureHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipMessage: PropTypes.string,
  buttonText: PropTypes.string,
  clickHandler: PropTypes.func,
  tooltipClasses: PropTypes.object,
  icon: PropTypes.object
};

TableEmptyState.defaultProps = {
  additionalText: null,
  dataTest: null,
  label: 'Not found',
  materialProps: null,
  picturePath: '',
  pictureWidth: '55px',
  pictureHeight: '50px',
  tooltipMessage: '',
  buttonText: '',
  clickHandler: noop,
  tooltipClasses: {},
  icon: null
};

export default TableEmptyState;
