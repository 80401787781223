import Markdown from '@reports/components/Markdown';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './SectionText.module.scss';

const SectionText = ({ editMode, onChange, text }) => {
  return (
    <div className={styles.text}>
      <Markdown
        editable={editMode === REPORT_EDIT_MODES.EDIT}
        markdown={text}
        onChange={onChange}
      />
    </div>
  );
};

SectionText.propTypes = {
  editMode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default SectionText;
