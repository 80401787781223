import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, TextButton } from '@ds';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import { formatArrayAsSelectOptions } from '@shared/utils/selectComponentHelper';

import Select from '@shared/components/Select';

import GenericModal from '@shared/components/GenericModal';
import alertsUtil from '@/util/alertsUtil';

import artifactActions from '@/actions/artifactActions';

const EditArtifactVersionModal = ({
  artifactName,
  artifactVersion,
  artifactVersionNumber,
  dispatch,
  modalId,
  workspaceName
}) => {
  const { alias, artifactVersionId, tags } = artifactVersion;

  const [editedAliases, setEditAliases] = useState(alias);
  const [editedTags, setEditedTags] = useState(tags);

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    const updatedArtifactVersionFields = {
      artifactVersionId,
      tags: editedTags,
      alias: editedAliases
    };

    dispatch(
      artifactActions.updateArtifactVersionLabels(
        updatedArtifactVersionFields,
        workspaceName,
        artifactName,
        artifactVersionNumber
      )
    );

    handleClose();
  };

  const renderAliasField = () => {
    const options = formatArrayAsSelectOptions(alias);
    const selectedValue = formatArrayAsSelectOptions(editedAliases);

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Aliases</InputLabel>
        <Select
          className="stage-select"
          value={selectedValue}
          onChange={selected => {
            setEditAliases(selected.map(({ value }) => value));
          }}
          options={options}
          placeholder="Create or select an alias"
          isMulti
          isSearchable
          isClearable
          isCreatable
        />
      </div>
    );
  };

  const renderTagsField = () => {
    const options = formatArrayAsSelectOptions(tags);
    const selectedValue = formatArrayAsSelectOptions(editedTags);

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Tags</InputLabel>
        <Select
          className="stage-select"
          value={selectedValue}
          onChange={selected => {
            setEditedTags(selected.map(({ value }) => value));
          }}
          options={options}
          placeholder="Create or select a tag"
          menuPlacement="top"
          isMulti
          isSearchable
          isClearable
          isCreatable
        />
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title="Edit version"
      hideFooter
    >
      <DialogContent className="generic-modal-body">
        <div
          className="generic-modal-group"
          style={{
            flexDirection: 'column',
            alignSelf: 'flexStart',
            width: '100%'
          }}
        >
          {renderAliasField()}
          {renderTagsField()}
        </div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton onClick={handleClose} size="large">
          Cancel
        </TextButton>
        <Button
          className="button blue submit-btn"
          onClick={handleSubmit}
          size="large"
        >
          Save
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

EditArtifactVersionModal.propTypes = {
  artifactName: PropTypes.string.isRequired,
  artifactVersion: PropTypes.object.isRequired,
  artifactVersionNumber: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default EditArtifactVersionModal;
