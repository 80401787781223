import { ORGANIZATIONS_SETTINGS } from '../constants/organization';

export const getOrganizationSettingsByName = (settings = [], settingName) => {
  if (!Array.isArray(settings)) return [];
  if (!Object.values(ORGANIZATIONS_SETTINGS).includes(settingName)) {
    return new Error(
      `Wrong setting name '${settingName}', should be one of ${Object.values(
        ORGANIZATIONS_SETTINGS
      )}`
    );
  }

  const foundSettingByName =
    (settings?.length && settings?.find(({ name }) => name === settingName)) ||
    {};

  return foundSettingByName?.value;
};
