import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

type DismissAlertMutationProps = {
  notificationIds: string[];
  dismissCallback: (notificationIds: string[]) => void;
};

export function useDismissAlertNotification() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ notificationIds }: DismissAlertMutationProps) => {
      return api.post(`/mpm/v2/monitor-notification/dismiss`, {
        modelId,
        monitorNotificationIds: notificationIds
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['alertNotifications', { modelId }]);
        await queryClient.cancelQueries([
          'alertNotificationsById',
          { modelId }
        ]);
      },
      onSuccess: (_data, { notificationIds, dismissCallback }) => {
        queryClient.invalidateQueries(['alertNotifications', { modelId }]);
        queryClient.invalidateQueries(['alertNotificationsById', { modelId }]);
        queryClient.invalidateQueries(['alert-rules', { modelId }]);
        queryClient.invalidateQueries(['alerts-count', { modelId }]);
        dismissCallback(notificationIds);
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_DISMISSED_ALERT_NOTIFICATIONS,
            'Alert notifications were successfully dismissed.'
          )
        );
      }
    }
  );
}
