import {
  DebuggerNoErrorsIcon,
  DebuggerNoLabelsIcon,
  DebuggerNoPredictionsIcon
} from '@Icons-outdated';

import {
  OPERATOR_TYPE,
  INPUT_TYPE,
  RULE_TYPE
} from '@shared/utils/filterHelpers';
import { DRIFT_ALGORITHMS } from './alerts';

export const DEFAULT_SEGMENTS_LINE_COLOR = '#5899da';

export const DEFAULT_SEGMENTS_ID = 'all';

export const DEFAULT_SEGMENT_VALUE = 'all predictions';

export const DEFAULT_MAX_SELECTED_SEGMENT_VALUES = 5;

export const PANEL_NAMES = {
  ACCURACY_METRICS: 'Accuracy Metrics',
  INPUT_METRICS: 'Input Metrics',
  SYSTEM_METRICS: 'System Metrics',
  DISTRIBUTION: 'Distribution',
  DRIFT: 'Drift',
  MISSING_VALUES: 'Missing values',
  DISPARATE_IMPACT_TIME: 'Disparate Impact Over Time',
  DISPARATE_IMPACT_GROUP: 'Disparate Impact Per Group',
  OUTPUT_FEATURES: 'Output Features'
};

export const PANEL_SECTIONS = {
  // Model (Accuracy Metrics)
  ACCURACY: 'ACCURACY',
  PRECISION: 'PRECISION',
  RECALL: 'RECALL',
  CONFUSION_MATRIX: 'CONFUSION_MATRIX',
  AUC: 'AUC',
  ACCURACY_REGRESSION: 'ACCURACY_REGRESSION',
  // Model (Input Metrics)
  DATA_DRIFT: 'DATA_DRIFT',
  DATA_DRIFT_BY_FEATURE: 'DATA_DRIFT_BY_FEATURE',
  // Model (System Metrics)
  NUMBER_OF_PREDICTIONS: 'NUMBER_OF_PREDICTIONS',
  NUMBER_OF_INGESTION_ERRORS: 'NUMBER_OF_INGESTION_ERRORS',
  // Features (Distribution), Model (Output Features)
  FEATURE_DISTRIBUTION: 'FEATURE_DISTRIBUTION',
  FEATURE_DISTRIBUTION_CATEGORICAL: 'FEATURE_DISTRIBUTION_CATEGORICAL',
  NUMERICAL_DISTRIBUTION: 'NUMERICAL_DISTRIBUTION',

  CONFUSION_MATRIX_CUSTOM_METRIC: 'CONFUSION_MATRIX_CUSTOM_METRIC',
  NUMERICAL_FEATURE_CUSTOM_METRIC: 'NUMERICAL_FEATURE_CUSTOM_METRIC',
  CATEGORICAL_FEATURE_CUSTOM_METRIC: 'CATEGORICAL_FEATURE_CUSTOM_METRIC',
  // Features (Drift)
  DRIFT: 'DRIFT',
  // Features (Missing values)
  MISSING_VALUES: 'MISSING_VALUES',
  // Fairness (Disparate Impact Over Time)
  DISTRIBUTION_PER_TIME: 'DISTRIBUTION_PER_TIME',
  // Fairness (Disparate Impact Per Group)
  DISTRIBUTION_PER_GROUP: 'DISTRIBUTION_PER_GROUP'
} as const;

export const PANEL_DATA_URL_MAP_SECTION = {
  [PANEL_SECTIONS.ACCURACY]: 'accuracy',
  [PANEL_SECTIONS.PRECISION]: 'precision',
  [PANEL_SECTIONS.RECALL]: 'recall',
  [PANEL_SECTIONS.CONFUSION_MATRIX]: 'confusionMatrix',
  [PANEL_SECTIONS.CONFUSION_MATRIX_CUSTOM_METRIC]: 'confusionMatrix',
  [PANEL_SECTIONS.NUMERICAL_FEATURE_CUSTOM_METRIC]:
    'custom_metrics/percentiles',
  [PANEL_SECTIONS.CATEGORICAL_FEATURE_CUSTOM_METRIC]:
    'custom_metrics/categorical_values',
  [PANEL_SECTIONS.AUC]: 'auc',
  [PANEL_SECTIONS.ACCURACY_REGRESSION]: 'accuracyRegression',
  [PANEL_SECTIONS.DATA_DRIFT]: 'inputDrift',
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE]: 'inputDriftByFeature',
  [PANEL_SECTIONS.NUMBER_OF_PREDICTIONS]: 'numberOfPredictions',
  [PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS]: 'numberOfErrors',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION]: 'distribution',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL]: 'categorical-distribution',
  [PANEL_SECTIONS.NUMERICAL_DISTRIBUTION]: 'numerical-distribution-pdf',
  [PANEL_SECTIONS.DRIFT]: 'drift',
  [PANEL_SECTIONS.MISSING_VALUES]: 'missingValues',
  [PANEL_SECTIONS.DISTRIBUTION_PER_TIME]: 'categorical-distribution',
  [PANEL_SECTIONS.DISTRIBUTION_PER_GROUP]: 'categorical-distribution'
};

export const CHART_TYPES = {
  BAR: 'bar',
  CONFUSION_MATRIX: 'confusion-matrix',
  LINE: 'line',
  LINE_SEGMENTS_BARS_DATA: 'line-segments-bars-data',
  LINE_WITH_PERCENTILE: 'line-with-percentile',
  FAIRNESS_TIME: 'fairness-time',
  FAIRNESS_GROUPED: 'fairness-grouped',
  NON_SUPPORTED_SEGMENTS: 'non-supported-segments',
  STACKED: 'stacked',
  // multiple type charts can be inferred by getMPMChartType only
  MULTIPLE_TYPE_CHART: 'multiple',
  CUSTOM_METRIC_CONFUSION_MATRIX: 'custom-metric-confusion-matrix',
  CUSTOM_METRIC_NUMERICAL_FEATURE: 'custom-metric-numerical-feature',
  CUSTOM_METRIC_CATEGORICAL_FEATURE: 'custom-metric-categorical-feature',
  NUMERICAL_DISTRIBUTION: 'numerical-distribution'
} as const;

export const CHART_TYPES_BY_SECTION = {
  [PANEL_SECTIONS.ACCURACY]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.PRECISION]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.RECALL]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.CONFUSION_MATRIX]: CHART_TYPES.CONFUSION_MATRIX,
  [PANEL_SECTIONS.AUC]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.ACCURACY_REGRESSION]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.DATA_DRIFT]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE]: CHART_TYPES.STACKED,
  [PANEL_SECTIONS.NUMBER_OF_PREDICTIONS]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS]: CHART_TYPES.LINE,
  // Features page
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION]: CHART_TYPES.MULTIPLE_TYPE_CHART,
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL]:
    CHART_TYPES.MULTIPLE_TYPE_CHART,
  [PANEL_SECTIONS.NUMERICAL_DISTRIBUTION]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.DRIFT]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.MISSING_VALUES]: CHART_TYPES.LINE,
  // Fairness page
  [PANEL_SECTIONS.DISTRIBUTION_PER_TIME]: CHART_TYPES.FAIRNESS_TIME,
  [PANEL_SECTIONS.DISTRIBUTION_PER_GROUP]: CHART_TYPES.FAIRNESS_GROUPED,
  // CUSTOM METRICS SECTIONS
  [PANEL_SECTIONS.CONFUSION_MATRIX_CUSTOM_METRIC]:
    CHART_TYPES.CUSTOM_METRIC_CONFUSION_MATRIX,
  [PANEL_SECTIONS.NUMERICAL_FEATURE_CUSTOM_METRIC]:
    CHART_TYPES.CUSTOM_METRIC_NUMERICAL_FEATURE,
  [PANEL_SECTIONS.CATEGORICAL_FEATURE_CUSTOM_METRIC]:
    CHART_TYPES.CUSTOM_METRIC_CATEGORICAL_FEATURE
};

export const MODEL_TYPES = {
  CLASSIFICATION: 'CLASSIFICATION',
  REGRESSION: 'REGRESSION',
  UNDEFINED: 'UNDEFINED'
} as const;

export const MODEL_FEATURES_TYPES = {
  OUTPUT_FEATURES: 'model_output_features',
  INPUT_FEATURES: 'model_input_features'
} as const;

export const TRANSFORM_TYPES = {
  NUMERICAL_FEATURE: 'NUMERICAL_FEATURE',
  CATEGORICAL_FEATURE: 'CATEGORICAL_FEATURE',
  CONFUSION_MATRIX: 'CONFUSION_MATRIX'
};

export const ACCURACY_METRICS_CLASSIFICATION_TABS = [
  {
    label: 'Accuracy',
    value: PANEL_SECTIONS.ACCURACY
  },
  {
    label: 'Precision',
    value: PANEL_SECTIONS.PRECISION
  },
  {
    label: 'Recall',
    value: PANEL_SECTIONS.RECALL
  },
  {
    label: 'Confusion Matrix',
    value: PANEL_SECTIONS.CONFUSION_MATRIX
  }
];

export const ACCURACY_METRICS_REGRESSION_TABS = [
  {
    label: 'MAPE',
    value: PANEL_SECTIONS.ACCURACY_REGRESSION,
    metricType: 'MAPE'
  },
  {
    label: 'MAE',
    value: PANEL_SECTIONS.ACCURACY_REGRESSION,
    metricType: 'MAE'
  },
  {
    label: 'MSE',
    value: PANEL_SECTIONS.ACCURACY_REGRESSION,
    metricType: 'MSE'
  },
  {
    label: 'RMSE',
    value: PANEL_SECTIONS.ACCURACY_REGRESSION,
    metricType: 'RMSE'
  },
  {
    label: 'MAX_ERROR',
    value: PANEL_SECTIONS.ACCURACY_REGRESSION,
    metricType: 'MAX_ERROR'
  }
];

export const INPUT_PANEL_TABS = [
  {
    label: 'Data Drift',
    value: PANEL_SECTIONS.DATA_DRIFT
  },
  {
    label: 'Data Drift By Feature',
    value: PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE
  }
];

export const SYSTEM_PANEL_TABS = [
  {
    label: 'Number Of Predictions',
    value: PANEL_SECTIONS.NUMBER_OF_PREDICTIONS
  },
  {
    label: 'Number Of Ingestion Errors',
    value: PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS
  }
];

export const METRIC_TYPES_MAP_SECTION = {
  input_drift_EMD: PANEL_SECTIONS.DATA_DRIFT,
  input_drift_PSI: PANEL_SECTIONS.DATA_DRIFT,
  input_drift_KL: PANEL_SECTIONS.DATA_DRIFT,
  input_drift: PANEL_SECTIONS.DATA_DRIFT,
  number_of_predictions: PANEL_SECTIONS.NUMBER_OF_PREDICTIONS,
  number_of_errors: PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS
};

export const OUTPUT_DISTRIBUTION_MAP_SECTION = {
  feature_drift: PANEL_SECTIONS.DRIFT,
  feature_drift_EMD: PANEL_SECTIONS.DRIFT,
  feature_drift_PSI: PANEL_SECTIONS.DRIFT,
  feature_drift_KL: PANEL_SECTIONS.DRIFT,
  feature_missing_values: PANEL_SECTIONS.MISSING_VALUES,
  percentile_min: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
  percentile_10: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
  percentile_25: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
  percentile_50: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
  percentile_75: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
  percentile_90: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
  percentile_max: PANEL_SECTIONS.FEATURE_DISTRIBUTION
};

export const NON_HANDLED_SEGMENTS_SECTIONS = [
  PANEL_SECTIONS.ACCURACY_REGRESSION,
  PANEL_SECTIONS.CONFUSION_MATRIX_CUSTOM_METRIC,
  PANEL_SECTIONS.CONFUSION_MATRIX,
  PANEL_SECTIONS.ACCURACY,
  PANEL_SECTIONS.PRECISION,
  PANEL_SECTIONS.RECALL,
  PANEL_SECTIONS.AUC,
  PANEL_SECTIONS.DATA_DRIFT,
  PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE,
  PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS,
  PANEL_SECTIONS.NUMERICAL_FEATURE_CUSTOM_METRIC,
  PANEL_SECTIONS.CATEGORICAL_FEATURE_CUSTOM_METRIC
];

export const MPM_LINE_CHART_TYPES = {
  default: 'default',
  percentile: 'percentile',
  stacked: 'stacked',
  segmentCategorical: 'segmentCategorical',
  nonSupportedSegments: 'nonSupportedSegments',
  fairness: 'fairness'
};

export const MPM_CHART_DATA_TYPES = {
  NUMERICAL: 'numerical',
  CATEGORICAL: 'categorical',
  SEGMENTS_CATEGORICAL: 'segmentsCategorical',
  SEGMENTS_NUMERICAL: 'segmentsNumerical'
};

export const MULTIPLE_TYPE_SECTIONS = {
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION]: {
    [MPM_CHART_DATA_TYPES.CATEGORICAL]: CHART_TYPES.BAR,
    [MPM_CHART_DATA_TYPES.SEGMENTS_CATEGORICAL]:
      CHART_TYPES.LINE_SEGMENTS_BARS_DATA,
    [MPM_CHART_DATA_TYPES.NUMERICAL]: CHART_TYPES.LINE_WITH_PERCENTILE,
    [MPM_CHART_DATA_TYPES.SEGMENTS_NUMERICAL]: CHART_TYPES.LINE_WITH_PERCENTILE
  },
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL]: {
    [MPM_CHART_DATA_TYPES.CATEGORICAL]: CHART_TYPES.BAR,
    [MPM_CHART_DATA_TYPES.SEGMENTS_CATEGORICAL]:
      CHART_TYPES.LINE_SEGMENTS_BARS_DATA,
    [MPM_CHART_DATA_TYPES.NUMERICAL]: CHART_TYPES.LINE_WITH_PERCENTILE,
    [MPM_CHART_DATA_TYPES.SEGMENTS_NUMERICAL]: CHART_TYPES.LINE_WITH_PERCENTILE
  }
};

export const LINE_VARIANTS = {
  [CHART_TYPES.LINE]: MPM_LINE_CHART_TYPES.default,
  [CHART_TYPES.LINE_WITH_PERCENTILE]: MPM_LINE_CHART_TYPES.percentile,
  [CHART_TYPES.STACKED]: MPM_LINE_CHART_TYPES.stacked,
  [CHART_TYPES.LINE_SEGMENTS_BARS_DATA]:
    MPM_LINE_CHART_TYPES.segmentCategorical,
  [CHART_TYPES.NON_SUPPORTED_SEGMENTS]:
    MPM_LINE_CHART_TYPES.nonSupportedSegments
};

// Values are mapped to objects from src/components/workspace/mpm/sections/add-alert/Constants.js
// Show 'Create Alert' Tooltip button for the charts below:
export const MPM_ALERT_ALLOWED_CHARTS = {
  // inputDrift
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION]: ['feature_metrics', 'percentile'], // TODO
  // inputDrift
  [PANEL_SECTIONS.DATA_DRIFT]: ['model_performance', 'input_drift'],
  // inputDriftByFeature
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE]: ['feature_metrics', 'feature_drift'],
  // numberOfPredictions
  [PANEL_SECTIONS.NUMBER_OF_PREDICTIONS]: [
    'system_metrics',
    'number_of_predictions'
  ],
  // numberOfErrors
  [PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS]: [
    'system_metrics',
    'number_of_errors'
  ],
  // drift
  [PANEL_SECTIONS.DRIFT]: ['feature_metrics', 'feature_drift'],
  // missingValues
  [PANEL_SECTIONS.MISSING_VALUES]: ['feature_metrics', 'feature_missing_values']
};

export const ALLOWED_ALERT_CREATE_BUTTON_SECTIONS = [
  // [PANEL_SECTIONS.FEATURE_DISTRIBUTION] - (for Numerical features)
  PANEL_SECTIONS.DATA_DRIFT,
  PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE,
  PANEL_SECTIONS.NUMBER_OF_PREDICTIONS,
  PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS,
  PANEL_SECTIONS.DRIFT,
  PANEL_SECTIONS.MISSING_VALUES
];

export const PERCENTILE_OPTION_LIST = [
  {
    label: 'Minimum - Maximum',
    value: 'minmax',
    filter: ['min', 'p50', 'max']
  },
  {
    label: '10th Percentile - 90th Percentile',
    value: 'p10-p90',
    filter: ['p10', 'p50', 'p90']
  },
  {
    label: '25th Percentile - 75th Percentile',
    value: 'p25-p75',
    filter: ['p25', 'p50', 'p75']
  },
  {
    label: 'No Shadow',
    value: 'none',
    filter: ['', '', '']
  }
];

export const PERCENTILE_TYPE_MAP = {
  minmax_min: 'min',
  minmax_median: '50',
  minmax_max: 'max',
  ['p10-p90_min']: '10',
  ['p10-p90_median']: '50',
  ['p10-p90_max']: '90',
  ['p25-p75_min']: '25',
  ['p25-p75_median']: '50',
  ['p25-p75_max']: '75',
  none_median: '50'
};

export const DRIFT_ALGORITHMS_OPTIONS = [
  {
    label: 'Earth Movers Distance',
    value: DRIFT_ALGORITHMS.EMD
  },
  {
    label: 'Population Stability Index',
    value: DRIFT_ALGORITHMS.PSI
  },
  {
    label: 'Kullback-Leibler divergence',
    value: DRIFT_ALGORITHMS.KL
  }
];

export const INTERVAL_TYPE_VALUES = {
  HOURLY: 'hourly',
  DAILY: 'daily'
} as const;

export const DEFAULT_INTERVAL_TYPE_VALUE = INTERVAL_TYPE_VALUES.DAILY;

export const INTERVAL_TYPE_OPTIONS = [
  {
    label: 'Daily',
    value: INTERVAL_TYPE_VALUES.DAILY
  },
  {
    label: 'Hourly',
    value: INTERVAL_TYPE_VALUES.HOURLY
  }
];

export const PERCENTILE_VALUES = {
  MIN: 'min',
  MAX: 'max'
};

export const PERFORMANCE_DATE_RANGES = {
  [INTERVAL_TYPE_VALUES.DAILY]: [7, 30, 90],
  [INTERVAL_TYPE_VALUES.HOURLY]: [1, 3, 7]
} as const;

export const DEFAULT_INTERVAL_VALUE = 7;
export const MIN_AMOUNT_DAYS_DAILY_MODE = 1;

export const MPM_OPERATOR_FILTERS = {
  [RULE_TYPE.STRING]: [
    {
      label: 'contains',
      operator: OPERATOR_TYPE.CONTAINS,
      inputType: INPUT_TYPE.STRING
    }
  ],
  [RULE_TYPE.TIME_NUMBER]: [
    {
      label: 'equal',
      operator: OPERATOR_TYPE.EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'not equal',
      operator: OPERATOR_TYPE.NOT_EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    }
  ],
  [RULE_TYPE.DATETIME]: [
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ],
  [RULE_TYPE.DOUBLE]: [
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ],
  [RULE_TYPE.BOOLEAN]: [
    {
      label: 'is true',
      operator: OPERATOR_TYPE.IS_TRUE,
      inputType: INPUT_TYPE.NONE,
      value: true
    },
    {
      label: 'is false',
      operator: OPERATOR_TYPE.IS_FALSE,
      inputType: INPUT_TYPE.NONE,
      value: false
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ]
};

export const MPM_CHART_DISABLED_ZOOM = true;

export const STREAM_ACTIONS = {
  PLAY: 'play',
  PAUSE: 'pause'
} as const;

export const DEBUGGER_GROUP_BUTTONS = {
  INGESTIONS: 'ingestions',
  LABELS: 'labels',
  PREDICTIONS: 'predictions'
} as const;

export const DEBUGGER_LABELS = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: 'Ingestion error',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: 'Label',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: 'Prediction'
};

export const DEBUGGER_LABELS_COLORS = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: '#ef6868',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: '#19a979',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: '#5899da'
};

export const RESPONSE_DATA = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: 'errors',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: 'labels',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: 'events'
} as const;

export const EMPTY_DEBUGGER_EVENTS_ICONS = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: DebuggerNoErrorsIcon,
  [DEBUGGER_GROUP_BUTTONS.LABELS]: DebuggerNoLabelsIcon,
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: DebuggerNoPredictionsIcon
};

export const EMPTY_DEBUGGER_EVENTS_MESSAGES = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]:
    'No Ingestion errors recorded in the previous 24 hours',
  [DEBUGGER_GROUP_BUTTONS.LABELS]:
    'No Labels recorded in the previous 24 hours',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]:
    'No Predictions recorded in the previous 24 hours'
};

export const NODE_TYPES = {
  DEFAULT: 'default',
  INPUT: 'inputModel',
  OUTPUT: 'outputModel'
} as const;

export const INITIAL_QUERY_STATE = {
  version: null,
  intervalType: DEFAULT_INTERVAL_TYPE_VALUE
};

// Custom Metric Properties which can be used by user creating the metric from the Create Metric Form
export const CONFUSION_MATRIX_CUSTOM_METRIC_PROPERTY = 'confusion_matrix';
export const CONFUSION_MATRIX_NBP_CUSTOM_METRIC_PROPERTY = 'nb_predictions';
export const NUMERICAL_FEATURE_CUSTOM_METRIC_PROPERTY = 'quantiles';
export const CATEGORICAL_FEATURE_CUSTOM_METRIC_PROPERTY = 'values';

export const TOOLTIP_FEATURE_VIEW_SECTION = [
  PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION
];

export const PREFIX_MPM_PATH =
  '/:workspace/model-production-monitoring/:modelId';
