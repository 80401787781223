import { DIR_TYPE } from '@experiment-management-shared/constants/fileBrowser';

const DFS = (treeFiles, sortFunction) => {
  const itemsInOrder = []; // The order which the files/dir are found

  const extractData = subTree => {
    subTree.type = DIR_TYPE;

    const temp = [];
    subTree.files.forEach(file => {
      temp.push(file);
    });

    temp.sort(sortFunction);
    temp.forEach(file => itemsInOrder.push(file));
  };

  const dfsFunction = subTree => {
    itemsInOrder.push(subTree);

    Object.keys(subTree.dirs).forEach(key => {
      const newSubTree = subTree.dirs[key];
      dfsFunction(newSubTree);
    });

    extractData(subTree);

    return itemsInOrder;
  };

  return dfsFunction(treeFiles.tree);
};

export default DFS;
