import { useMutation, useQueryClient } from 'react-query';

import api from '../api';

import useProject from '../project/useProject';

const archiveExperiments = ({ experiments }) => {
  return api.post('private-logger/delete/bulk', {
    deleteThis: experiments
  });
};

export default function useArchiveExperimentsMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ experimentKeys }) => {
      const experiments = experimentKeys.map(experimentKey => ({
        experimentKey,
        projectId
      }));

      return archiveExperiments({ experiments });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['experiments', { projectId }]);
        await queryClient.cancelQueries(['experimentsDetails', { projectId }]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['experiments', { projectId }]);
        queryClient.invalidateQueries(['experimentGroups', { projectId }]);
        queryClient.invalidateQueries(['aggregations', { projectId }]);
        queryClient.invalidateQueries(['experimentsDetails', { projectId }]);
      }
    }
  );
}
