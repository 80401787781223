import React from 'react';
import { AddPanelIcon } from '@Icons-outdated';

import styles from './PanelSectionsWrapper.module.scss';

type PanelSectionEmptyStateProps = {
  onAddPanelButtonClick: () => void;
};

const PanelSectionEmptyState = ({
  onAddPanelButtonClick
}: PanelSectionEmptyStateProps) => {
  return (
    <div className={styles.panelSectionEmptyState}>
      <div className={styles.main} onClick={onAddPanelButtonClick}>
        <AddPanelIcon />
        <span className={styles.title}>Add panel</span>
        <span className={styles.description}>
          Visualize your experiment data
        </span>
      </div>
      <div />
      <div />
    </div>
  );
};

export default PanelSectionEmptyState;
