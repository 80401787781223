import { useCallback } from 'react';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { BreadcrumbItemProps } from '@shared/types';

const LINK_EVENT = 'BREADCRUMBS_LINK_CLICK';
const WORKSPACE_EVENT = 'BREADCRUMBS_WORKSPACE_CLICK';
const EXPERIMENT_KEY_EVENT = 'BREADCRUMBS_EXPERIMENT_KEY_COPY';
const COMPARE_EVENT = 'BREADCRUMBS_COMPARE_CLICK';
const COMPARE_LINK_EVENT = 'BREADCRUMBS_COMPARE_LINK_CLICK';
const COMPARE_EXPERIMENT_KEY_COPY_EVENT =
  'BREADCRUMBS_COMPARE_EXPERIMENT_KEY_COPY';

function useBreadcrumbsTrackEvent() {
  const baseTrackEvent = useBaseTrackEvent();

  return useCallback(
    (item: BreadcrumbItemProps, params: Record<string, string> = {}) => {
      switch (item.type) {
        case 'link':
          baseTrackEvent(LINK_EVENT, {
            path: item.path
          });
          break;
        case 'home':
          baseTrackEvent(WORKSPACE_EVENT, {
            targetWorkspace: params.workspace
          });
          break;
        case 'experiment':
          baseTrackEvent(EXPERIMENT_KEY_EVENT, {
            experimentKey: item.id
          });
          break;
        case 'compare':
          if (params.experimentKey) {
            baseTrackEvent(COMPARE_EXPERIMENT_KEY_COPY_EVENT, {
              experimentKey: params.experimentKey
            });
          } else if (params.path) {
            baseTrackEvent(COMPARE_LINK_EVENT, {
              path: params.path
            });
          } else {
            baseTrackEvent(COMPARE_EVENT, {
              experimentKeys: item.ids
            });
          }
          break;
      }
    },
    [baseTrackEvent]
  );
}

export default useBreadcrumbsTrackEvent;
