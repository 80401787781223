import { useMutation } from 'react-query';

import api from '@shared/api';

type SignInSSOParams = {
  email: string;
};

type SSOResponse =
  | {
      sso: false;
      url?: never;
    }
  | {
      sso: true;
      url: string;
    };

const signInSSO = async ({ email }: SignInSSOParams) => {
  const { data } = await api.post<SSOResponse>('auth/sso-check', {
    email
  });

  if (!data.sso) {
    throw new Error(
      'The email address you entered is not associated with a known SSO account'
    );
  }

  return data;
};

export function useSSOMutation() {
  return useMutation(({ email }: SignInSSOParams) => {
    return signInSSO({ email });
  });
}
