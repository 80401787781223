import { OrganizationUserRole } from '@shared/types';

export const ORGANIZATION_USER_ROLES: {
  [key in OrganizationUserRole]: OrganizationUserRole;
} = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  VIEW_ONLY_MEMBER: 'VIEW_ONLY_MEMBER'
};

export const ORGANIZATIONS_SETTINGS = {
  WORKSPACE_CREATOR_BECOMES_OWNER: 'workspace-creator-becomes-owner',
  ONLY_ADMIN_INVITE_FROM_OUTSIDE_ORGANIZATION:
    'only-admin-invite-from-outside-organization',
  MODEL_ITEM_SUPPORTED_STATUSES: 'model-item-supported-statuses'
};
