import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/styles';
import { IconButton, Tooltip } from '@ds';
import { DSRefreshIcon } from '@ds-icons';

const StyledPopover = withStyles({
  paper: {
    minWidth: 200
  }
})(Popover);

const FilterContainer = ({
  children,
  className,
  groupLabel,
  resetSelectionHandler,
  selectedLabel,
  disabled
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleReset = () => {
    handleClose();
    resetSelectionHandler();
  };

  return (
    <div className={cx('project-filter-button-container', className)}>
      <Button
        className="project-filter-button"
        onClick={handleOpen}
        disabled={disabled}
      >
        <span className="project-filter-label">{groupLabel} </span>
        {selectedLabel}

        <KeyboardArrowDownIcon className="project-filter-icon" />
      </Button>
      <StyledPopover
        className="project-filter-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="project-filter-group-label">
          {groupLabel}
          <Tooltip content="Reset selection">
            <IconButton
              Icon={<DSRefreshIcon />}
              size="small"
              onClick={handleReset}
            />
          </Tooltip>
        </div>
        {children}
      </StyledPopover>
    </div>
  );
};

FilterContainer.defaultProps = {
  className: ''
};

FilterContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  className: PropTypes.string,
  groupLabel: PropTypes.string.isRequired,
  resetSelectionHandler: PropTypes.func.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default FilterContainer;
