import React from 'react';
import { Box, Grid, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatModelVersionStatusToText } from '@shared/utils/model-registry-request-status';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { MODEL_VERSION_STATUSES } from '@shared/constants/model-registry-request-status';
import { MetadataIcon } from '@Icons-outdated';
import Select from '@shared/components/Select';
import classNames from './ModelVersionRequestStatusModal.module.scss';
import Input from '@DesignSystem/controllers/Input/Input';

const RequestStatusForm = ({
  status,
  ifCurrentUserWsOwner,
  setChangedStatus,
  statusOptions,
  comment,
  setComment
}) => {
  return (
    <Box className={classNames.modalVersionRequestStatus}>
      <Box className={classNames.modalVersionRequestStatusLabel}>
        Select a status
      </Box>
      <Box
        className={classNames.modalVersionRequestStatusWrapper}
        data-test="modal-version-request-status-select"
      >
        <Select
          defaultValue={{
            ...formatValueForSelectOption(status),
            label: status
              ? formatModelVersionStatusToText(status)
              : MODEL_VERSION_STATUSES.NA
          }}
          onChange={({ value }) => setChangedStatus(value)}
          options={statusOptions}
          isSearchable={false}
          isClearable={false}
        />
      </Box>
      {!ifCurrentUserWsOwner && (
        <Box mt={2} className={classNames.modalVersionRequestStatusLabel}>
          The &quot;Workspace Owner&quot; will need to approve your status
          change request
        </Box>
      )}
      {ifCurrentUserWsOwner && (
        <Box mt={2} className={classNames.modalVersionRequestStatusLabel}>
          As a &quot;Workspace Owner&quot; you can change the model status with
          or without submitting for review
        </Box>
      )}
      <Box className={classNames.modalVersionRequestStatusComment}>
        <Grid container alignContent="center" alignItems="center">
          <Grid item>Comment</Grid>
          <Grid item>
            <Tooltip
              title={
                ifCurrentUserWsOwner ? (
                  <div>
                    <div>All model changes will be</div>
                    <div>reflected in History tab</div>
                  </div>
                ) : (
                  'Comments will be sent along with your request and be reflected in History Tab'
                )
              }
              placement="top"
              arrow
            >
              <Box className={classNames.modalVersionRequestStatusCommentIcon}>
                <MetadataIcon />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box className={classNames.modalVersionRequestStatusCommentInput}>
        <Input
          placeholder="Enter changes ..."
          multiline
          value={comment}
          onChange={setComment}
          height="80px"
        />
      </Box>
    </Box>
  );
};

RequestStatusForm.defaultProps = {
  status: null
};

RequestStatusForm.propTypes = {
  status: PropTypes.string,
  ifCurrentUserWsOwner: PropTypes.bool.isRequired,
  setChangedStatus: PropTypes.func.isRequired,
  statusOptions: PropTypes.array.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired
};

export default RequestStatusForm;
