// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelsHeader-module__wrapper--e9hsN{padding:15px 20px}.ModelsHeader-module__roundButtonsWrapper--Q_WhA{width:163px}.ModelsHeader-module__filters--FvYxV{display:flex;width:100%;align-items:center;justify-content:space-between}.ModelsHeader-module__title--Jzkgn{font-style:normal;font-weight:bold;font-size:16px;color:#191a1c;margin:0;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ModelsHeader-module__wrapper--e9hsN`,
	"roundButtonsWrapper": `ModelsHeader-module__roundButtonsWrapper--Q_WhA`,
	"filters": `ModelsHeader-module__filters--FvYxV`,
	"title": `ModelsHeader-module__title--Jzkgn`
};
module.exports = ___CSS_LOADER_EXPORT___;
