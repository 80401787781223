import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import SystemMetricsCharts from './SystemMetricsCharts';

const SystemMetricChartsWrapper = ({
  entireRowCharts,
  experiments,
  maxTotalMemory,
  totalRam,
  metricNames,
  isComparePage,
  xAxisType
}) => {
  if (!metricNames.length) return null;

  return (
    <SystemMetricsCharts
      entireRowCharts={entireRowCharts}
      experiments={experiments}
      metricNames={metricNames}
      maxTotalMemory={maxTotalMemory}
      totalRam={totalRam}
      isComparePage={isComparePage}
      xAxisType={xAxisType}
    />
  );
};

SystemMetricChartsWrapper.defaultProps = {
  maxTotalMemory: 0,
  totalRam: 16000000000,
  metricNames: []
};

SystemMetricChartsWrapper.propTypes = {
  entireRowCharts: PropTypes.bool.isRequired,
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  isComparePage: PropTypes.bool.isRequired,
  maxTotalMemory: PropTypes.number,
  totalRam: PropTypes.number,
  metricNames: PropTypes.arrayOf(PropTypes.string),
  xAxisType: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const { isComparePage } = props;
  const {
    entireRowCharts,
    xAxisType,
    compareXAxisType
  } = state.runDetails.systemMetricsChart;

  return {
    entireRowCharts,
    xAxisType: isComparePage ? compareXAxisType : xAxisType
  };
};

export default connect(mapStateToProps)(SystemMetricChartsWrapper);
