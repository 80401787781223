import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DSCopyIcon } from '@ds-icons';
import { BreadcrumbItemProps } from '@shared/types';
import { Tooltip } from '@ds';

import useBreadcrumbsTrackEvent from '@shared/hooks/useBreadcrumbsTrackEvent';

import styles from './BreadcrumbItem.module.scss';

const ExperimentItem = (itemParams: BreadcrumbItemProps) => {
  const { id, dataMaskTest, title, isLast = false } = itemParams;
  const dispatch = useDispatch();
  const trackEvent = useBreadcrumbsTrackEvent();

  const handleCopyClick = useCallback(() => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_TEXT,
        'Successfully copied experiment key to clipboard'
      )
    );

    trackEvent(itemParams);
  }, [dispatch, trackEvent, itemParams]);

  return (
    <Tooltip
      content={title}
      size="small"
      enterDelay={300}
      enterNextDelay={300}
      wrapper={false}
      arrow={false}
    >
      <span
        className={cx(styles.item, styles.experimentItem, {
          [styles.last]: isLast
        })}
        onClick={handleCopyClick}
      >
        <CopyToClipboard text={id as string}>
          <span>
            <span data-mask-test={dataMaskTest}>{title}</span>
            <div className={styles.copy}>
              <DSCopyIcon />
            </div>
          </span>
        </CopyToClipboard>
      </span>
    </Tooltip>
  );
};

export default ExperimentItem;
