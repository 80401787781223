import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleCard } from '@DesignSystem/surfaces';
import { DS_SIMPLE_CARD_TYPES } from '@DesignSystem/surfaces/SimpleCard/SimpleCard';
import SyntaxHighlighter, {
  DS_SYNTAX_TYPE
} from '@design-system-outdated/components/SyntaxHighlighter';
import { TextButton } from '@ds';
import { useOnboardingFirstClick } from '@onboarding/hooks/biEvents';

import styles from './QuickStartGuide.module.scss';
import { CODE_SNIPPETS, DefaultImportSection } from './quickStartCodeSnippets';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

const QuickStartCodeSnippet = ({
  importSection,
  installCode,
  installationSection,
  logCode,
  logSection,
  importCode,
  PrefixIcon
}) => {
  const { onSendFirstClickEvent } = useOnboardingFirstClick();

  const dispatch = useDispatch();

  const getAllCode = () => `${importCode}\n\n${logCode}`;

  const handleCopyAllClick = () => {
    onSendFirstClickEvent('[Copy all click]');

    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_TEXT,
        'Successfully copied code'
      )
    );
  };

  return (
    <SimpleCard withShadow type={DS_SIMPLE_CARD_TYPES.SECONDARY}>
      <div className={styles.codeSnippetContainer}>
        <div className={styles.snippetTitle}>
          <div className={styles.snippetIconContainer}>
            <PrefixIcon />
          </div>
          <h4 className={styles.snippetTitleLabel}>
            Integrate Comet with your own code
          </h4>
        </div>

        <div className={styles.frameworkSnippet}>
          <div className={styles.snippetBlock}>
            <p>{installationSection}</p>
            <SyntaxHighlighter
              withCopyBtn
              language="bash"
              code={installCode}
              showLineNumbers={false}
              type={DS_SYNTAX_TYPE.SECONDARY}
            >
              {installCode}
            </SyntaxHighlighter>
          </div>

          <div className={styles.snippetBlock}>
            {importSection}
            <SyntaxHighlighter
              withCopyBtn
              language="python"
              code={importCode}
              showLineNumbers={false}
              type={DS_SYNTAX_TYPE.SECONDARY}
            >
              {importCode}
            </SyntaxHighlighter>
          </div>

          <div className={styles.snippetBlock}>
            <p>{logSection}</p>
            <SyntaxHighlighter
              withCopyBtn
              language="python"
              code={logCode}
              showLineNumbers={false}
              type={DS_SYNTAX_TYPE.SECONDARY}
            >
              {logCode}
            </SyntaxHighlighter>
          </div>
        </div>

        <div className={styles.copyAllContainer}>
          <CopyToClipboard text={getAllCode()}>
            <TextButton type="tertiary" onClick={handleCopyAllClick}>
              Copy all
            </TextButton>
          </CopyToClipboard>
        </div>
      </div>
    </SimpleCard>
  );
};

QuickStartCodeSnippet.defaultProps = {
  installCode: CODE_SNIPPETS.INSTALL,
  importSection: DefaultImportSection,
  installationSection: 'Install Comet Using pip from the command line.',
  logSection:
    'Log all your data through the Experiment class during your training loop or after.'
};

QuickStartCodeSnippet.propTypes = {
  importSection: PropTypes.node,
  installCode: PropTypes.string,
  installationSection: PropTypes.node,
  logCode: PropTypes.string.isRequired,
  logSection: PropTypes.node,
  importCode: PropTypes.string.isRequired,
  PrefixIcon: PropTypes.element.isRequired
};

export default QuickStartCodeSnippet;
