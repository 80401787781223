import cx from 'classnames';
import { isEmpty, isNull, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import useCurrentUser from '@API/auth/useCurrentUser';
import useProject from '@API/project/useProject';
import { Badge } from '@DesignSystem/data-display';
import { SectionTabs } from '@DesignSystem/navigation';
import DashboardChartPreview from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/DashboardChartPreview';
import ChartNameField from '@experiment-management-shared/components/ChartNameField';
import PanelModalFooter from '@experiment-management-shared/components/PanelModalFooter';
import {
  BUILT_IN_CHART_TYPES,
  PANEL_WITH_LEGEND_MODE
} from '@experiment-management-shared/constants/chartConstants';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import withWidth from '@material-ui/core/withWidth';
import { trackEvent } from '@shared/utils/eventTrack';
import {
  getIsSingleExperimentPage,
  getURLQueryParams
} from '@shared/utils/url';

import { useActiveWorkspace, useCurrentOrganization } from '@shared/hooks';
import { panelEvents } from '@/constants/trackingEventTypes';

import useCurrentPanelSource from '@/helpers/custom-hooks/useCurrentPanelSource';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import { usePanelPreviewExperiments } from '@experiment-management-shared/hooks';
import { BUILT_IN_PANEL_TABS_MAP } from './consts';

import {
  useUpdateRevisionCodeMutation,
  useCreateRevisionCodeMutation
} from '@experiment-management-shared/api/python-panels';

import '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/charts-tabs.scss';
import useIsEnvironmentsEnabled from '../../../shared/hooks/organization/useIsEnvironmentsEnabled';
import { Prompt } from 'react-router-dom';

const AddBuiltInVisualizationModal = ({
  chartFormByType,
  chartFormsMap,
  chartType,
  dispatch,
  experiments,
  formType,
  isEditMode,
  experimentKeys,
  hiddenExperimentKeys,
  selectedLegendKeys,
  projectId,
  width,
  onAddBuiltInPanel,
  onEditBuiltInPanel,
  hideGoBackButton
}) => {
  const [initialChartState, setInitialChartState] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const { data: project } = useProject();
  const location = useLocation();
  const queryParams = getURLQueryParams(location.hash);
  const chartId = queryParams.get('chartId');
  const chartCategory = queryParams.get('chartCategory');

  const { data: currentUser } = useCurrentUser();
  const currentSource = useCurrentPanelSource();
  const history = useHistory();
  const isSingleExperimentPage = useSelector(getIsSingleExperimentPage);
  const currentOrganization = useCurrentOrganization();
  const currentWorkspace = useActiveWorkspace();
  const isComparePage = useSelector(selectIsComparePage);
  const previewExperiments = usePanelPreviewExperiments(
    selectedLegendKeys,
    experimentKeys,
    experiments,
    projectId
  );

  const isPythonEnvironmentEnabled = useIsEnvironmentsEnabled();

  // python panels
  const updateRevisionCodeMutation = useUpdateRevisionCodeMutation();
  const createRevisionCodeMutation = useCreateRevisionCodeMutation(projectId);

  const areBorderedSidebars = chartType === BUILT_IN_CHART_TYPES.python;
  const isLargeSidebar = chartType === BUILT_IN_CHART_TYPES.python;

  const handleCloseModal = () => {
    history.push({ ...location, hash: '' }, { preventScroll: true });
    setInitialChartState(null);
  };

  const handleResetForm = useCallback(() => {
    setActiveTabIndex(0);
    dispatch(
      dashboardChartsActions.updateChartForm(formType, initialChartState)
    );
  }, [formType, dispatch, initialChartState]);

  const initializePythonPanels = useCallback(() => {
    const arePyPanelsInitialized =
      chartFormByType.code !== null && chartFormByType.codeVersion !== '';

    if (arePyPanelsInitialized) {
      setInitialChartState(chartFormByType);
    }
  }, [chartFormByType]);

  const hasPyPanelBeenUpdated = useMemo(() => {
    if (formType !== BUILT_IN_CHART_TYPES.python || !initialChartState) {
      return null;
    }

    return chartFormByType?.code !== initialChartState?.code;
  }, [formType, initialChartState, chartFormByType]);

  useEffect(() => {
    if (chartFormByType && !initialChartState) {
      if (formType === BUILT_IN_CHART_TYPES.python) {
        initializePythonPanels();
      } else {
        setInitialChartState(chartFormByType);
      }
    }
  }, [formType, initialChartState, chartFormByType, initializePythonPanels]);

  const renderBuiltInPreview = () => {
    return (
      <DashboardChartPreview
        chartType={chartType}
        chartForm={chartFormsMap}
        experimentKeys={experimentKeys}
        hiddenExperimentKeys={hiddenExperimentKeys}
        experiments={previewExperiments}
        isEditing={isEditMode}
        projectId={projectId}
        isComparePage={isComparePage}
        onPanelChange={changes => {
          dispatch(dashboardChartsActions.updateChartForm(chartType, changes));
        }}
      />
    );
  };

  const handleEditChart = () => {
    if (chartType === BUILT_IN_CHART_TYPES.python) {
      updateRevisionCodeMutation.mutate({
        chart: chartFormsMap[chartType],
        onEditPanel: onEditBuiltInPanel,
        chartId,
        chartFormsMap,
        chartType,
        onCloseModal: handleCloseModal
      });
      return;
    }

    onEditBuiltInPanel(chartId, chartFormsMap, chartType);

    handleCloseModal();
  };

  const handleAddChart = () => {
    const newChart = {
      chartType,
      ...chartFormByType
    };

    trackEvent(panelEvents.PANEL_ADDED, {
      organization_id: currentOrganization?.id,
      organization_name: currentOrganization?.name,
      workspace_id: currentWorkspace?.id,
      workspace_name: currentWorkspace?.name,
      project_id: project?.projectId,
      project_name: project?.projectName,
      panel_location: currentSource,
      panel_type: chartCategory,
      panel_name: newChart.chartType,
      user_id: currentUser?.username,
      grouping: newChart.grouping?.enabled ? newChart.grouping : null,
      legend_display: PANEL_WITH_LEGEND_MODE.includes(newChart.chartType)
        ? newChart.legendMode
        : 'NA'
    });

    if (chartType === BUILT_IN_CHART_TYPES.python) {
      createRevisionCodeMutation.mutate({
        chart: newChart,
        onAddPanel: onAddBuiltInPanel,
        onCloseModal: handleCloseModal
      });
      return;
    }

    onAddBuiltInPanel(newChart, chartType);

    if (chartType === BUILT_IN_CHART_TYPES.scalar) {
      trackEvent(panelEvents.ADD_BUILT_IN_SCALAR_PANEL_CLICK, {
        isSavePanel: true,
        isSingleExperiment: isSingleExperimentPage,
        paramName: newChart.paramName,
        metricName: newChart.metricName,
        aggregation: !newChart.paramName ? newChart.aggregation : ''
      });
    }

    handleCloseModal();
  };

  const handleSubmit = () => {
    setClickedSubmit(true);

    if (isEditMode) {
      handleEditChart();
    } else {
      handleAddChart();
    }
  };

  const handleUpdateChartName = newChartName => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        formType,
        'chartName',
        newChartName
      )
    );
  };

  const renderBadges = () => (
    <div className="chart-badges">
      {chartFormByType.selectedXAxis && (
        <Badge label={`x: ${chartFormByType.selectedXAxis}`} />
      )}
      {Array.isArray(chartFormByType.selectedYAxis)
        ? chartFormByType.selectedYAxis.map(value => (
            <Badge key={value} label={`y: ${value}`} />
          ))
        : chartFormByType.selectedYAxis && (
            <Badge label={`y: ${chartFormByType.selectedYAxis}`} />
          )}
    </div>
  );

  const renderChartPreviewHeader = () => {
    const placeholder = chartHelpers.getDefaultChartNameByType(
      chartType,
      chartFormByType
    );

    return (
      <div className="chart-preview-header add-visualization-modal">
        <ChartNameField
          placeholder={placeholder}
          chartNameEditHandler={handleUpdateChartName}
          currentName={chartFormByType.chartName}
          label={activePanelConfig?.nameLabel}
        />
        {renderBadges()}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        className={cx('chart-modal-content-wrapper', {
          'with-border': areBorderedSidebars
        })}
      >
        <div className={cx('chart-modal-content', 'right', width)}>
          {renderChartPreviewHeader()}
          {renderBuiltInPreview()}
        </div>
      </div>
    );
  };

  const handleTabChange = index => {
    setActiveTabIndex(index);
  };

  const activePanelConfig = useMemo(() => {
    const config = BUILT_IN_PANEL_TABS_MAP[chartType];

    if (!config) return null;

    if (
      chartType === BUILT_IN_CHART_TYPES.python &&
      !isPythonEnvironmentEnabled
    ) {
      const tabs = config.tabs.filter(
        tab => tab.value !== 'python-environment'
      );

      return {
        ...config,
        tabs
      };
    }

    // disable annotations tab if no images selected
    if (chartType === BUILT_IN_CHART_TYPES.image) {
      const tabs = config.tabs.map(value => ({
        ...value,
        disabled: isEmpty(chartFormByType?.images)
      }));

      return { ...config, tabs };
    }

    return config;
  }, [chartFormByType?.images, chartType, isPythonEnvironmentEnabled]);

  const CurrentChartView = activePanelConfig?.tabs[activeTabIndex]?.component;

  const currentChartViewParams = useMemo(() => {
    const retVal = {};

    if (
      chartType === BUILT_IN_CHART_TYPES.image ||
      chartType === BUILT_IN_CHART_TYPES.video ||
      chartType === BUILT_IN_CHART_TYPES.pcd ||
      chartType === BUILT_IN_CHART_TYPES.data ||
      chartType === BUILT_IN_CHART_TYPES.curves ||
      chartType === BUILT_IN_CHART_TYPES.python
    ) {
      retVal.experimentKeys = experimentKeys;
      retVal.hiddenExperimentKeys = hiddenExperimentKeys;
    }

    return retVal;
  }, [chartType, hiddenExperimentKeys, experimentKeys]);

  const renderSidebar = () => {
    return (
      <div
        className={cx('modal-sidebar', {
          large: isLargeSidebar
        })}
      >
        <div
          className={cx('modal-sidebar-tabs', {
            'with-border': areBorderedSidebars,
            large: isLargeSidebar
          })}
        >
          <SectionTabs
            tabHeight={isLargeSidebar ? 36 : 30}
            variant="secondary"
            activeTabIndex={activeTabIndex}
            tabs={activePanelConfig?.tabs}
            onTabChange={handleTabChange}
          />
        </div>
        <div
          className={cx('modal-sidebar-body', {
            'with-border': areBorderedSidebars
          })}
        >
          <CurrentChartView
            chartType={chartType}
            isEditing={isEditMode}
            {...currentChartViewParams}
          />
        </div>
      </div>
    );
  };

  if (!chartType) return null;

  return (
    <>
      <Prompt
        when={chartType === BUILT_IN_CHART_TYPES.python}
        message={() => {
          if (clickedSubmit || !hasPyPanelBeenUpdated) {
            return true;
          }

          return 'Are you sure you leave without saving the changes?';
        }}
      />
      <div className="panel-preview">
        {renderSidebar()}
        {renderBody()}
      </div>

      <PanelModalFooter
        hideGoBackButton={hideGoBackButton || isEditMode}
        handleSubmit={handleSubmit}
        handleReset={handleResetForm}
      />
    </>
  );
};

AddBuiltInVisualizationModal.defaultProps = {
  selectedLegendKeys: [],
  hiddenExperimentKeys: null,
  hideGoBackButton: false,
  onEditBuiltInPanel: noop,
  onAddBuiltInPanel: noop
};

AddBuiltInVisualizationModal.propTypes = {
  chartFormByType: PropTypes.object.isRequired,
  chartFormsMap: PropTypes.object.isRequired,
  chartType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  experiments: PropTypes.array.isRequired,
  formType: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  hideGoBackButton: PropTypes.bool,
  experimentKeys: PropTypes.array.isRequired,
  hiddenExperimentKeys: PropTypes.array,
  selectedLegendKeys: PropTypes.arrayOf(PropTypes.object),
  projectId: PropTypes.string.isRequired,
  onAddBuiltInPanel: PropTypes.func,
  onEditBuiltInPanel: PropTypes.func,
  width: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const params = getURLQueryParams();
  const chartType = params.get('chartType');
  const chartId = params.get('chartId');

  const formType = chartType;
  const chartFormsMap = state.dashboardCharts.chartForm;

  return {
    chartFormByType: getChartFormByType(state, { chartType }),
    selectedLegendKeys: chartFormsMap[chartType]?.selectedLegendKeys || [],
    chartFormsMap,
    chartType,
    formType,
    isEditMode: !isNull(chartId),
    projectId: getSelectedProjectId(state)
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
    mergeProps
  )(withWidth()(AddBuiltInVisualizationModal))
);
