import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import { PythonEnvironmentVersion } from '@shared/types';

const getCurrentPythonEnvironment = async (
  organizationId: string,
  signal: AbortSignal | undefined
): Promise<PythonEnvironmentVersion> => {
  const response = await api.get<PythonEnvironmentVersion>(
    `/code-panels/environments/current`,
    { params: { orgId: organizationId }, signal }
  );

  return response.data;
};

const useCurrentPythonEnvironment = (
  organizationId: string,
  config?: QueryConfig<PythonEnvironmentVersion>
) => {
  return useQuery(
    ['organization-python-environment', { organizationId }],
    ({ signal }) => getCurrentPythonEnvironment(organizationId, signal),
    config
  );
};

export default useCurrentPythonEnvironment;
