import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ds';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

import ConfirmWarning from '@shared/components/ConfirmWarning';
import { getActiveEMHeaderTab } from '@/reducers/ui/projectsUiReducer';
import { DSArchiveIcon } from '@ds-icons';

const ArchiveExperimentButton = ({
  disabled,
  onArchiveExperiments,
  selection
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isAnyExperimentSymlinked = selection.some(({ symlink }) => symlink);

  const handleArchiveExperiments = () => {
    const experimentKeys = selection.map(({ experimentKey }) => experimentKey);

    onArchiveExperiments(experimentKeys);
  };

  const handleCloseArchiveConfirm = () => {
    dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_ARCHIVE_EXPERIMENT));
  };

  const handleConfirmOverride = () => {
    handleArchiveExperiments();
    handleCloseArchiveConfirm();
  };

  const activeHeaderTab = useSelector(getActiveEMHeaderTab);

  const handleOpenArchiveConfirm = () => {
    const numberOfExperimentsToArchive = selection.length;

    const msg =
      numberOfExperimentsToArchive === 1
        ? '1 experiment'
        : `${numberOfExperimentsToArchive} experiments`;

    const headerText = `Are you sure you want to archive ${msg}?`;

    const archivePageUrl = `${location.pathname.replace(
      `/${activeHeaderTab}`,
      ''
    )}/archive`;

    const linkToArchivePage = (
      <Link
        to={archivePageUrl}
        onClick={handleCloseArchiveConfirm}
        style={{ marginLeft: 5 }}
      >
        archive page
      </Link>
    );

    const bodyContent = (
      <div>
        <div className="bullet-list">
          <span className="bullet" />
          <strong style={{ marginRight: 5 }}>
            Your archived experiments
          </strong>{' '}
          can be restored on the {linkToArchivePage}.
        </div>
        {isAnyExperimentSymlinked && (
          <div className="bullet-list">
            <span className="bullet" />
            <strong style={{ marginRight: 5 }}>Symlink experiments</strong> will
            be removed from this project only.
          </div>
        )}
      </div>
    );

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.CONFIRM_ARCHIVE_EXPERIMENT,
        <ConfirmWarning
          modalId={dialogTypes.CONFIRM_ARCHIVE_EXPERIMENT}
          onConfirm={handleConfirmOverride}
          headerText={headerText}
          bodyContent={bodyContent}
          buttonText="Yes"
          style={{ minWidth: 600 }}
        />
      )
    );
  };

  return (
    <Button
      size="large"
      disabled={disabled}
      onClick={handleOpenArchiveConfirm}
      PrefixIcon={<DSArchiveIcon />}
    >
      Archive
    </Button>
  );
};

ArchiveExperimentButton.defaultProps = {
  disabled: false,
  selection: []
};

ArchiveExperimentButton.propTypes = {
  disabled: PropTypes.bool,
  onArchiveExperiments: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.object)
};

export default ArchiveExperimentButton;
