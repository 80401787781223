import axios from 'axios';
import queryString from 'query-string';
import { BASE_API_URL } from '../lib/appConstants';
import { AssetData } from '@shared/types/assetData';
import { AssetRenamePayload } from '@API/assets/useRenameAsset';

const assetsApi = {
  fetchAssetsForExperiment(
    experimentKey: string,
    type: string,
    signal?: AbortSignal
  ) {
    const url = `${BASE_API_URL}asset/list`;

    return axios.get<AssetData[]>(url, {
      params: {
        experimentKey,
        type,
        signal
      }
    });
  },

  returnRequestLink({
    experimentKey,
    dir
  }: {
    experimentKey: string;
    dir: string;
  }) {
    return `${BASE_API_URL}asset/dir/download?experimentKey=${experimentKey}&dir=${dir}`;
  },

  renameAsset(payload: AssetRenamePayload) {
    return axios.put(`${BASE_API_URL}asset/rename`, payload);
  }
};

export const getEmbeddedProjectorURL = (
  assetId: string,
  experimentKey: string
) => {
  const encodedConfig = encodeURIComponent(
    `${BASE_API_URL}asset/download?assetId=${assetId}&experimentKey=${experimentKey}`
  );

  return `/projector/?config=${encodedConfig}`;
};

export const getDownloadAssetURL = (
  experimentKey: string,
  assetId: string,
  artifactVersionId = ''
) => {
  const searchQuery = queryString.stringify(
    {
      experimentKey,
      assetId,
      artifactVersionId
    },
    {
      skipEmptyString: true
    }
  );

  return `${BASE_API_URL}asset/download?${searchQuery}`;
};

export default assetsApi;
