import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { ONBOARDING_PATHS } from '@onboarding/constants';

const useIsOnOnboardingPage = () => {
  const location = useLocation();

  const areNeededRoutes = useMemo(() => {
    return ONBOARDING_PATHS.some(
      path => !!matchPath(location.pathname, { path })
    );
  }, [location.pathname]);

  return areNeededRoutes;
};

export default useIsOnOnboardingPage;
