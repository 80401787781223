import React, { useCallback } from 'react';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';

import { Tooltip } from '@ds';
import { Link } from 'react-router-dom';
import { BreadcrumbItemProps } from '@shared/types';
import useBreadcrumbsTrackEvent from '@shared/hooks/useBreadcrumbsTrackEvent';

import styles from './BreadcrumbItem.module.scss';

const LinkItem = (itemParams: BreadcrumbItemProps) => {
  const {
    dataMaskTest,
    path = '',
    title,
    isLast = false,
    isFirst = false,
    onClick
  } = itemParams;

  const trackEvent = useBreadcrumbsTrackEvent();

  const clickHandler = useCallback(() => {
    trackEvent(itemParams);

    if (isFunction(onClick)) {
      onClick();
    }
  }, [trackEvent, itemParams, onClick]);

  let item = (
    <Link
      className={cx(styles.item, styles.linkItem, { [styles.first]: isFirst })}
      data-mask-test={dataMaskTest}
      to={path}
      onClick={clickHandler}
    >
      {isFirst ? <span>{title}</span> : title}
    </Link>
  );

  if (isLast) {
    item = (
      <span
        className={cx(styles.item, styles.linkItem, styles.last)}
        data-mask-test={dataMaskTest}
      >
        {title}
      </span>
    );
  }

  return (
    <Tooltip
      content={title}
      size="small"
      enterDelay={300}
      enterNextDelay={300}
      wrapper={false}
      arrow={false}
    >
      {item}
    </Tooltip>
  );
};

export default LinkItem;
