import { combineReducers } from 'redux';
import { projectsUIReducer } from './projectsUiReducer';
import { experimentsUiReducer } from './experimentsUiReducer';
import visualizationsUiReducer from './visualizationsUiReducer';
import workspaceUiReducer from './workspaceUiReducer';
import mpmUiReducer from './mpm';
import { onboardingReducer } from './onboardingReducer';
import organizationUIReducer from './organizationsUiReducer';

export default combineReducers({
  projects: projectsUIReducer,
  experiments: experimentsUiReducer,
  visualizations: visualizationsUiReducer,
  workspace: workspaceUiReducer,
  mpm: mpmUiReducer,
  onboarding: onboardingReducer,
  organizations: organizationUIReducer
});
