import { debounce, isNumber, last } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { IMAGE_PANEL_DEFAULT_STEP } from '@/lib/appConstants';
import { AssetStep } from '@experiment-management-shared/types';

const DELAYS_MS = 250;

export function useStepsProps({
  isLoading = true,
  step = null,
  steps = []
}: {
  isLoading?: boolean;
  step?: AssetStep;
  steps?: AssetStep[];
}) {
  const [stepValue, setStepValue] = useState(0);
  const [stepAPIValue, setStepAPIValue] = useState(step);
  useEffect(() => {
    if (stepAPIValue !== step) {
      setStepAPIValue(step);
    }
  }, [step]);

  const stepsArray = useMemo(() => {
    const localSteps = (steps || []).slice();

    // in case we don't have any step available we need to add 0 because it is treated as default value
    if (!localSteps.length) {
      localSteps.push(IMAGE_PANEL_DEFAULT_STEP);
    }

    const sortedSteps = localSteps.sort((s1, s2) => Number(s1) - Number(s2));

    // edge case: if array steps includes null and 0 remove null as it's the same as 0
    if (sortedSteps.includes(0) && sortedSteps.includes(null)) {
      const filtered = sortedSteps.filter(e => e !== null);
      return filtered;
    }

    return sortedSteps;
  }, [steps]);

  // set stepValue base on saved data in redux
  useEffect(() => {
    if (isLoading) {
      return;
    }

    // check if saved value available for current images and experiments
    const localStepInvalid = !stepsArray.includes(stepAPIValue);

    if (localStepInvalid) {
      // Select the first available option from list
      setStepValue(0);
      setStepAPIValue(stepsArray[0]);
    } else {
      // invalidate stepValue base on saved data in redux
      const index = stepsArray.indexOf(stepAPIValue);

      if (index !== -1) {
        setStepValue(index);
      }
    }
    // stepValue ignored in purpose
  }, [isLoading, stepsArray, stepAPIValue]);

  const handleDebouncedChangeStep = useMemo(
    () => debounce(s => setStepAPIValue(s), DELAYS_MS),
    []
  );

  const handleChangeStep = (index: number) => {
    setStepValue(index);
    handleDebouncedChangeStep(stepsArray[index]);
  };

  const stepSliderLabelFormat = (value: number) => {
    const lastStep = last(stepsArray) || IMAGE_PANEL_DEFAULT_STEP;
    const currentStep = stepsArray[value] || IMAGE_PANEL_DEFAULT_STEP;

    return isNumber(lastStep) && isNumber(currentStep)
      ? `${currentStep}/${lastStep}`
      : '';
  };

  return {
    sliderProps: {
      disabled: isLoading,
      // workaround to recreate slider component in case we have only one possible value, it will force mark to be rendered from the beginning of component
      key:
        stepsArray.length === 1 ? 'panel-one-value' : 'panel-multiple-values',
      max: stepsArray.length - 1,
      min: 0,
      onChange: handleChangeStep,
      sliderSuffixFormat: stepSliderLabelFormat,
      step: 1,
      value: stepValue,
      valueLabelDisplay: 'off' as const,
      valueLabelFormat: (value: number) => String(stepsArray[value])
    },
    stepAPIValue
  };
}
