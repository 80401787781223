import { useMutation, useQueryClient } from 'react-query';
import modelRegistryApi from '../../util/modelRegistryApi';

const registerNewVersionOfExistingModel = async ({
  experimentModelId,
  registryModelName,
  description,
  version
}) => {
  const newModelItem = {
    experimentModelId,
    registryModelName,
    version,
    comment: description,
    stages: []
  };

  return modelRegistryApi.createNewModelItem(newModelItem);
};

const useModelRegistryAddNewVersOfExistModelMutation = ({ workspaceName }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ experimentModelId, registryModelName, description, version }) =>
      registerNewVersionOfExistingModel({
        experimentModelId,
        registryModelName,
        description,
        version
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'modelRegistryList',
          { workspaceName }
        ]);
      }
    }
  );
};

export default useModelRegistryAddNewVersOfExistModelMutation;
