import React from 'react';
import PropTypes from 'prop-types';
import { StyledTooltip as Tooltip } from '@DesignSystem/data-display';

import { Box } from '@material-ui/core';
import get from 'lodash/get';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import './SourceExperiment.scss';
import RedirectLink from '@shared/components/RedirectLink';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';

const SourceExperiment = ({ data, text }) => {
  const { experimentModel } = data || {};
  const assets = get(data, 'assets[0]', {});
  const assetId = get(assets, 'assetId', '');
  const assetPath = get(assets, 'dir', '').split('/');
  const { experimentKey, deletedExperimentKey } = experimentModel || {};

  if (deletedExperimentKey) {
    return (
      <Box className="ds-source-experiment-cell disabled">
        <Tooltip placement="top" title="Source experiment has been deleted">
          <span>{text}</span>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box className="ds-source-experiment-cell">
      <RedirectLink
        resource={REDIRECT_LINK_RESOURCES.EXPERIMENT}
        args={[
          experimentKey,
          {
            'experiment-tab': DETAIL_VIEWS.ASSETS_AND_ARTIFACTS,
            assetId,
            assetPath
          }
        ]}
      >
        {text}
      </RedirectLink>
    </Box>
  );
};

SourceExperiment.propTypes = {
  data: PropTypes.shape({
    experimentModel: PropTypes.shape({
      experimentModel: PropTypes.shape({
        experimentKey: PropTypes.string.isRequired
      }),
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          assetId: PropTypes.string,
          dir: PropTypes.string
        })
      ),
      deletedExperimentKey: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  text: PropTypes.string.isRequired
};

export default SourceExperiment;
