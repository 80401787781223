import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import notesActions from '@/actions/notesActions';
import projectsActions from '@/actions/projectsActions';
import { getCurrentProject } from '@/reducers/projectsReducer';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import { MarkdownEditor, SmallLoader } from '@shared/components';
import { uploadProjectNoteFile } from './helpers';

const NotesPage = ({
  dispatch,
  isLoading,
  isReadOnly,
  markdown,
  projectId
}) => {
  const handleSave = (noteValue, shouldSaveNoteInStore = false) => {
    return dispatch(
      notesActions.saveProjectNote(noteValue, projectId, shouldSaveNoteInStore)
    );
  };

  const handleAddImage = useCallback(
    file => {
      return uploadProjectNoteFile({ file, projectId });
    },
    [projectId]
  );

  useEffect(() => {
    dispatch(
      projectsActions.setActiveEMHeaderTab(EXPERIMENT_TAB_PATHNAME.notes)
    );
    dispatch(notesActions.fetchProjectNote(projectId));

    return () => dispatch(notesActions.cleanNotes());
  }, [dispatch, projectId]);

  const renderEditor = () => {
    if (isLoading) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Fetching experiment notes"
        />
      );
    }

    return (
      <MarkdownEditor
        onAddImage={handleAddImage}
        onChange={handleSave}
        editable={!isReadOnly}
        markdown={markdown}
      />
    );
  };

  return (
    <div className="long-container long-container-break">
      <div className="project-note">{renderEditor()}</div>
    </div>
  );
};

NotesPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  markdown: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const projectId = getSelectedProjectId(state);
  const projectNotes = get(state, 'notes.projects', {});

  return {
    isLoading: !projectNotes[projectId],
    isReadOnly: !getCurrentProject(state).canEdit,
    markdown: get(projectNotes, `${projectId}.value`, ''),
    projectId
  };
};

export default connect(mapStateToProps)(NotesPage);
