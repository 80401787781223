import { useState } from 'react';

const useSystemMetricsComparisonTableColumnWidths = () => {
  const [columnWidths, onColumnWidthsChange] = useState([]);

  return {
    columnWidths,
    onColumnWidthsChange
  };
};

export default useSystemMetricsComparisonTableColumnWidths;
