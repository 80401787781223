import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import FieldsListRemoveIcon from './FieldsListRemoveIcon';
import FieldsListAddBtn from './FieldsListAddBtn';
import FieldsListItem from './FieldsListItem';

const FieldsList = ({
  dataTest,
  items = [],
  uniqueKey,
  renderItem,
  isItemValid,
  onRemoveButtonClick,
  removeButtonTooltip,
  addButtonText,
  onAddButtonClick,
  header
}) => {
  const isEmptyList = items?.length === 0;
  const isOneElementInTheList = items?.length === 1;
  const isAllNodesValid = items?.every(isItemValid);

  return (
    <Box data-test={`${dataTest}-wrapper`}>
      {!isEmptyList && header}
      <Grid container direction="column" spacing={1}>
        {items?.map((data, index) => {
          const key = `${uniqueKey}-${index}`;

          return (
            <FieldsListItem
              dataTest={dataTest}
              key={key}
              uniqueKey={key}
              index={index}
              removeButton={{
                element: (
                  <FieldsListRemoveIcon
                    dataTest={dataTest}
                    disabled={isOneElementInTheList}
                    onRemoveButtonClick={() => onRemoveButtonClick(index)}
                    index={index}
                  />
                ),
                tooltip: !isOneElementInTheList && removeButtonTooltip
              }}
              node={renderItem(data, index)}
            />
          );
        })}
      </Grid>

      <FieldsListAddBtn
        dataTest={dataTest}
        text={addButtonText}
        onAddButtonClick={onAddButtonClick}
        disabled={!isAllNodesValid}
      />
    </Box>
  );
};

FieldsList.defaultProps = {
  dataTest: 'ds-fields-list',
  addButtonText: undefined,
  header: null,
  removeButtonTooltip: null,
  items: [],
  uniqueKey: 'field-item'
};

FieldsList.propTypes = {
  dataTest: PropTypes.string,
  uniqueKey: PropTypes.string,
  addButtonText: PropTypes.string,
  header: PropTypes.node,
  onAddButtonClick: PropTypes.func.isRequired,
  removeButtonTooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  renderItem: PropTypes.func.isRequired,
  isItemValid: PropTypes.func.isRequired,
  onRemoveButtonClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string
    })
  )
};

export default FieldsList;
