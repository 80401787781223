import { createSelector } from 'reselect';
import { mpmActionTypes } from '../../constants/actionTypes';

const initialState = {
  from: null,
  to: null,
  intervalType: null
};

const mpmUiReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === mpmActionTypes.SET_TIME_RANGE) {
    return {
      ...state,
      from: payload.from,
      to: payload.to
    };
  }

  if (type === mpmActionTypes.SET_INTERVAL_TYPE) {
    return {
      ...state,
      intervalType: payload.intervalType
    };
  }

  return state;
};

export const getMPM = state => state.ui.mpm;

export const getMPMFrom = createSelector(getMPM, mpm => {
  return mpm.from;
});

export const getMPMTo = createSelector(getMPM, mpm => {
  return mpm.to;
});

export const getMPMIntervalType = createSelector(getMPM, mpm => {
  return mpm.intervalType;
});

export default mpmUiReducer;
