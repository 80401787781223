import { useQuery } from 'react-query';
import api from '@shared/api';
import outputUtil from '@/util/outputUtil';

const getExperimentGraphDefinition = async ({ experimentKey, signal }) => {
  const { data } = await api.get('data-fetch/get/graph', {
    params: { experimentKey },
    signal
  });

  return outputUtil.formatGraphDefinitionOutput(data.graph);
};

export default function useExperimentGraphDefinition(
  { experimentKey },
  config
) {
  return useQuery(
    ['experiment-graph-definition', { experimentKey }],
    ({ signal }) => {
      return getExperimentGraphDefinition({
        experimentKey,
        signal
      });
    },
    { enabled: !!experimentKey, refetchOnMount: true, ...config }
  );
}
