import { useMutation, useQueryClient } from 'react-query';
import { useActiveWorkspace } from '@shared/hooks';

import api from '../api';

const removePanelTemplate = ({ templateId }) => {
  return api.post('code-visualizations/template/delete', {
    templateId
  });
};

export default function useRemovePanelTemplateMutation() {
  const queryClient = useQueryClient();
  const activeWorkspace = useActiveWorkspace();

  return useMutation(({ templateId }) => removePanelTemplate({ templateId }), {
    onMutate: async ({ scope }) => {
      await queryClient.cancelQueries([
        'panelTemplates',
        { teamId: activeWorkspace?.id, scope }
      ]);

      await queryClient.cancelQueries([
        'panelTemplatesCount',
        { teamId: activeWorkspace?.id }
      ]);
    },
    onSuccess: async (_data, { scope }) => {
      await queryClient.invalidateQueries([
        'panelTemplates',
        { teamId: activeWorkspace?.id, scope }
      ]);

      await queryClient.invalidateQueries([
        'panelTemplatesCount',
        { teamId: activeWorkspace?.id }
      ]);
    }
  });
}
