import React from 'react';
import { LinkButton, ListItem } from '@ds';
import { DSArrowRightIcon, DSKeyIcon } from '@design-system/icons';
import { Popover } from '@material-ui/core';
import UserMenuApiKeyCopy from './UserMenuApiKeyCopy';

import styles from './UserMenuApiKey.module.scss';
import { useUserMenuContext } from '../../userMenuContext';
import { usePopoverState } from '@shared/hooks';
import useCloseMainMenuOnBackdrop from '../UserMenuWorkspaces/hooks/useCloseMainMenuOnBackdrop';

const UserMenuApiKeyMenuItem = () => {
  const { closeMainMenu } = useUserMenuContext();
  const closeMainMenuOnBackdrop = useCloseMainMenuOnBackdrop();

  const {
    openPopover,
    onClosePopover,
    isPopoverOpened,
    anchorElement
  } = usePopoverState({
    onClose: closeMainMenuOnBackdrop
  });

  return (
    <>
      <ListItem
        onClick={openPopover}
        active={isPopoverOpened}
        data-test="user-menu-api-key-link"
      >
        <ListItem.PrefixContainer>
          <ListItem.Icon Icon={<DSKeyIcon />} />
        </ListItem.PrefixContainer>
        <ListItem.Text primary="API Key" />
        <ListItem.ActionContainer>
          <ListItem.Icon Icon={<DSArrowRightIcon />} />
        </ListItem.ActionContainer>
      </ListItem>
      <Popover
        id="api-key-menu-popover"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          className: styles.apiKeyManageContainer
        }}
        elevation={0}
        transitionDuration={0}
        open={isPopoverOpened}
        anchorEl={anchorElement}
        onClose={onClosePopover}
      >
        <UserMenuApiKeyCopy />
        <LinkButton
          type="tertiary"
          to="/account-settings/apiKeys"
          text="Manage API Keys"
          accented
          fullWidth
          className={styles.apiKeyManageBtn}
          onClick={closeMainMenu}
        />
      </Popover>
    </>
  );
};

export default UserMenuApiKeyMenuItem;
