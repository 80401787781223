import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes } from '../../../constants/alertTypes';
import history from '../../../history';
import { WORKSPACE_URL_HASHES } from '../../../constants/workspaceConstants';
import { SUB_PATHS } from '../../../constants/urlConstants';
import { modelRegistryActionTypes } from '../../../constants/actionTypes';
import { getModelId } from '../utils';

const updateModelRegistry = async ({
  registryModelId,
  description,
  isPublic,
  newModelName,
  currentModelName
}) => {
  await modelRegistryApi.updateModel({
    registryModelId,
    registryModelName: newModelName,
    modelName: newModelName,
    description,
    isPublic
  });

  return {
    currentModelName,
    newModelName,
    isPublic,
    registryModelId,
    description
  };
};

const useModelRegistryUpdateMutation = (
  { workspaceName, currentModelName },
  updatingModelInList = false
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ registryModelId, description, isPublic, newModelName }) =>
      updateModelRegistry({
        registryModelId,
        description,
        isPublic,
        newModelName,
        currentModelName
      }),
    {
      onMutate: async ({
        newModelName,
        registryModelId,
        isPublic,
        description
      }) => {
        if (currentModelName !== newModelName) {
          if (document.location.hash !== WORKSPACE_URL_HASHES.MODEL_REGISTRY) {
            const newModelLink = `/${workspaceName}/${SUB_PATHS.MODEL_REGISTRY}/${newModelName}`;
            history.push(newModelLink);
          }
        }

        if (updatingModelInList) {
          dispatch({
            type: modelRegistryActionTypes.SET_REGISTERED_MODEL_WITH_NEW_NAME,
            payload: {
              newModelId: getModelId(workspaceName, newModelName),
              oldModelId: getModelId(workspaceName, currentModelName),
              model: {
                registryModelId,
                registryModelName: newModelName,
                modelName: newModelName,
                description,
                isPublic
              }
            }
          });
        } else {
          dispatch({
            type: modelRegistryActionTypes.CLOSE_EDIT_MODEL_MODAL
          });
        }
      },
      onSuccess: ({ registryModelId }) => {
        queryClient.invalidateQueries([
          'modelRegistry',
          { workspace: workspaceName, modelName: currentModelName }
        ]);
        queryClient.invalidateQueries([
          'getModelRegistryNameById',
          { workspaceId: workspaceName, modelId: registryModelId }
        ]);
      },
      onError: () =>
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error deleting your model.'
          )
        )
    }
  );
};

export default useModelRegistryUpdateMutation;
