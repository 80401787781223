import React from 'react';
import PropTypes from 'prop-types';

import { BasicModal } from '@DesignSystem/modals';
import classNames from './ModelRegistryWebhooksTab.module.scss';

const ModelRegistryDeleteWebhookConfirmation = ({
  webhookName,
  onPrimaryButtonClick,
  onClose,
  onSecondaryButtonClick,
  open,
  dataTest
}) => {
  return (
    <BasicModal
      open={open}
      additionalClasses={{
        paper: classNames.modelRegistryWebhooksDeleteModal
      }}
      onClose={onClose}
      title="Delete webhook?"
      dataTest={dataTest}
      primaryButtonText="Yes, delete"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      content={`This action cannot be undone. Future events will no longer be delivered to ${webhookName} webhook.`}
    />
  );
};

ModelRegistryDeleteWebhookConfirmation.defaultProps = {
  dataTest: null
};

ModelRegistryDeleteWebhookConfirmation.propTypes = {
  dataTest: PropTypes.string,
  webhookName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired
};

export default ModelRegistryDeleteWebhookConfirmation;
