import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import PauseOutlinedIcon from '@material-ui/icons/PauseOutlined';

import classNames from './StepSlider.module.scss';

const TIMER_TIME = 1000;
let timer;

const StepSliderButtons = ({
  resetStep,
  advanceRight,
  trackCallback,
  sliderValue
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const play = () => {
    setIsPlaying(true);
    timer = setInterval(advanceRight, TIMER_TIME);
    trackCallback();
  };

  const pause = () => {
    clearInterval(timer);
    setIsPlaying(false);
  };

  useEffect(() => {
    if (isPlaying) {
      play();
    }

    return () => {
      pause();
    };
  }, [sliderValue, isPlaying]);
  useEffect(() => pause, []);

  return (
    <>
      <button type="button" className={classNames.dsStepSliderAnimate}>
        <Tooltip title="Reset" placement="top" arrow>
          <span
            className={classNames.dsStepSliderAnimateButton}
            onClick={() => {
              pause();
              resetStep();
            }}
          >
            <StopOutlinedIcon />
          </span>
        </Tooltip>
      </button>

      <button
        type="button"
        className={classNames.dsStepSliderAnimate}
        onClick={isPlaying ? pause : play}
      >
        <Tooltip title="Animate steps" placement="top" arrow>
          <span className={classNames.dsStepSliderAnimateButton}>
            {isPlaying ? <PauseOutlinedIcon /> : <PlayArrowOutlinedIcon />}
          </span>
        </Tooltip>
      </button>
    </>
  );
};

StepSliderButtons.defaultProps = {
  trackCallback: noop
};

StepSliderButtons.propTypes = {
  resetStep: PropTypes.func.isRequired,
  advanceRight: PropTypes.func.isRequired,
  trackCallback: PropTypes.func,
  sliderValue: PropTypes.number.isRequired
};

export default StepSliderButtons;
