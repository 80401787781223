import useCurrentUser from '@API/auth/useCurrentUser';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getUserAvatarURL, normalizeAvatarURL } from '../../../../lib/utils';
import styles from './ReportAuthor.module.scss';

const ReportAuthor = ({ author, avatarURL, avatarWidth, editMode }) => {
  const { data: user = {} } = useCurrentUser();
  const {
    email: userEmail,
    isLoggedInWithGithub: isUserLoggedInWithGitHub,
    username
  } = user;

  const currentAvatarURL = useMemo(() => {
    if (avatarURL && REPORT_EDIT_MODES.VIEW === editMode) {
      return normalizeAvatarURL(avatarURL, avatarWidth);
    }

    return getUserAvatarURL({
      isUserLoggedInWithGitHub,
      userEmail,
      username,
      width: avatarWidth
    });
  }, [
    avatarWidth,
    avatarURL,
    editMode,
    isUserLoggedInWithGitHub,
    userEmail,
    username
  ]);

  const authorName =
    REPORT_EDIT_MODES.VIEW === editMode ? author || username : username;

  return (
    <div
      className={cx(styles.author, {
        [styles.viewOnly]: REPORT_EDIT_MODES.EDIT !== editMode
      })}
    >
      <div>
        <img
          src={currentAvatarURL}
          style={{
            borderRadius: avatarWidth / 2,
            height: avatarWidth,
            width: avatarWidth
          }}
        />
      </div>

      <div className={styles.label}>
        By{' '}
        <span className={styles.username}>
          <Link to={`/${authorName}`}>{authorName}</Link>
        </span>
      </div>
    </div>
  );
};

ReportAuthor.defaultProps = {
  author: '',
  avatarURL: '',
  avatarWidth: 30
};

ReportAuthor.propTypes = {
  author: PropTypes.string,
  avatarURL: PropTypes.string,
  avatarWidth: PropTypes.number,
  editMode: PropTypes.string.isRequired
};

export default ReportAuthor;
