import githubSanitizeRules from 'hast-util-sanitize/lib/github';
import { merge } from 'lodash';
import highlight from 'rehype-highlight';
import katex from 'rehype-katex';
import sanitize from 'rehype-sanitize';
import stringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import math from 'remark-math';
import parse from 'remark-parse';
import raw from 'rehype-raw';
import remark2rehype from 'remark-rehype';
import { unified } from 'unified';

const sanitizeRules = merge(githubSanitizeRules, {
  attributes: { '*': ['className', 'style'] }
});

const convertLinksToNewTabLinks = (html: string) => {
  return html.replace(/<a href="[^"]+"/g, match => `${match} target="_blank"`);
};

const standarizeMarkdown = (markdown: string) => {
  return (
    markdown
      // Replace with #title -> # title
      .replace(/^(#+)(\w+)/gm, (_, hashSequence, word) => {
        return `${hashSequence} ${word}`;
      })
      // Replace |====| with |----| (tables)
      .replace(/^([=|\s|\\|]+)$/gm, (_, matchedString) => {
        return matchedString.replace(/=/g, '-');
      })
  );
};

export const markdownToHTML = (markdown: string) => {
  const standardMarkdown = standarizeMarkdown(markdown);
  const file = unified()
    .use(parse)
    .use(remarkGfm)
    .use(math)
    // .use(emoji)
    .use(remark2rehype, { allowDangerousHtml: true })
    .use(raw)
    .use(highlight)
    .use(katex)
    .use(stringify)
    .use(sanitize, sanitizeRules)
    .processSync(standardMarkdown);

  const content = String(file);

  if (content === 'string') {
    return convertLinksToNewTabLinks(content);
  }

  return content;
};
