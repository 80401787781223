import { useEffect } from 'react';
import { IS_ON_PREMISE } from '@/constants/configConstants';

export const fireTapfiliateConv = (id, metadata = {}) => {
  if (!window.tapfiliate) return;
  window.tapfiliate('conversion', id, 0, { meta_data: metadata });
};

export const mountTapfiliate = () => {
  if (window.tapfiliate || IS_ON_PREMISE) return;

  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.src = 'https://script.tapfiliate.com/tapfiliate.js';
  head.appendChild(script);
  // eslint-disable-next-line
  (function (t, a, p) {
    t.TapfiliateObject = a;
    t[a] =
      t[a] ||
      function () {
        // eslint-disable-next-line
        (t[a].q = t[a].q || []).push(arguments);
      };
  })(window, 'tapfiliate');

  window.tapfiliate('create', '34108-5fda78', {
    integration: 'javascript'
  });
};

const useTapfiliate = (isUserLoggedIn, affiliateRef) => {
  useEffect(() => {
    if (!isUserLoggedIn) {
      if (!window.tapfiliate) mountTapfiliate();
      if (affiliateRef) window.tapfiliate('detect');
    }
  }, [isUserLoggedIn, affiliateRef]);
};

export default useTapfiliate;
