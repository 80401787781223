import { EmptyExperimentTab } from '@shared';
import React from 'react';

const AssetsTabEmpty = () => (
  <EmptyExperimentTab>
    <EmptyExperimentTab.Icon iconName="assets" />
    <EmptyExperimentTab.Title>
      This experiment did not report any assets
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      Use <b>log_asset()</b> on your Experiment object to save files during
      experiments.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/assets/" />
  </EmptyExperimentTab>
);

export default AssetsTabEmpty;
