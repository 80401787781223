import { MODEL_REGISTRY_WEBHOOKS_COLUMNS } from '@model-registry/constants/webhooks';
import { convertWebhookDataToCometPayload } from '@model-registry/utils/webhooks';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import modelRegistryApi from '@/util/modelRegistryApi';

const useModelRegistryCreateWebhookMutation = ({
  workspaceName,
  modelName
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    data => {
      const newData = {
        ...data
      };
      delete newData[MODEL_REGISTRY_WEBHOOKS_COLUMNS.id];

      return modelRegistryApi.createWebhook(
        convertWebhookDataToCometPayload({ workspaceName, modelName }, newData)
      );
    },
    {
      onSuccess: async (
        data,
        { snackbarMsg = 'Successfully created a webhook.' } = {}
      ) => {
        await queryClient.invalidateQueries([
          'modelRegistry',
          { workspaceName, modelName },
          'webhooks'
        ]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_MODEL_REGISTRY_CREATE_WEBHOOK,
            snackbarMsg
          )
        );
      },
      onError: (
        data,
        { snackbarMsgErr = 'There was an error creating a webhook.' } = {}
      ) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            snackbarMsgErr
          )
        );
      }
    }
  );
};

export default useModelRegistryCreateWebhookMutation;
