import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CrossedEyeIcon, EyeIcon } from '@Icons-outdated';
import { Input } from '@DesignSystem/controllers';
import styles from './APIKeyInput.module.scss';

const APIKeyInputWritable = ({ title, value, onChange, ...rest }) => {
  const [isOpenKey, setIsOpenKey] = useState(false);

  const handleIsOpenKey = () => setIsOpenKey(prevVal => !prevVal);

  return (
    <div
      className={cx(
        styles.APIInputContainer,
        !isOpenKey && styles.APIInputContainerHidden
      )}
    >
      <Input
        title={title}
        value={value}
        lineHeight="21px"
        PostfixIcon={isOpenKey ? EyeIcon : CrossedEyeIcon}
        onPostfixIconClick={handleIsOpenKey}
        onChange={onChange}
        inputType={isOpenKey ? 'text' : 'password'}
        {...rest}
      />
    </div>
  );
};

APIKeyInputWritable.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string
};

APIKeyInputWritable.defaultProps = {
  value: '',
  title: ''
};

export default APIKeyInputWritable;
