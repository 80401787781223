import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import isFunction from 'lodash/isFunction';

import { Divider } from '@DesignSystem/data-display';

import { TextButton } from '@ds';
import FiltersSelect from '@shared/components/QueryFiltersHeader/FiltersSelect';
import { IFilterDetails } from '@experiment-management-shared/api/useListFilters';

import styles from './QueryFiltersHeader.module.scss';
import { DSArrowDownIcon } from '@ds-icons';

const QueryFiltersHeader = ({
  canEdit,
  disableSave,
  disableClear,
  entityName,
  totalEntities,
  activeFilter,
  filters,
  onClearQuery,
  onSaveQuery,
  onSelectFilter,
  onDeleteFilter
}) => {
  return (
    <div className={styles.container}>
      {canEdit && isFunction(onSaveQuery) && (
        <>
          <TextButton
            size="small"
            type="secondary"
            disabled={disableSave}
            onClick={onSaveQuery}
            className={styles.button}
          >
            Add to library
          </TextButton>
          <Divider orientation="vertical" className={styles.divider} />
        </>
      )}

      {canEdit && isFunction(onClearQuery) && (
        <>
          <TextButton
            size="small"
            type="secondary"
            disabled={disableClear}
            onClick={onClearQuery}
            className={styles.button}
          >
            Clear
          </TextButton>
          <Divider orientation="vertical" className={styles.divider} />
        </>
      )}

      {isFunction(onSelectFilter) && isFunction(onDeleteFilter) && (
        <FiltersSelect
          entityName={entityName}
          activeFilter={activeFilter}
          onSelectFilter={onSelectFilter}
          onDeleteFilter={onDeleteFilter}
          filters={filters}
          totalEntities={totalEntities}
          PopoverProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            }
          }}
        >
          <TextButton
            size="small"
            type="secondary"
            className={cx(styles.button, styles.library)}
            PostfixIcon={<DSArrowDownIcon />}
          >
            Filter library
          </TextButton>
        </FiltersSelect>
      )}
    </div>
  );
};

QueryFiltersHeader.defaultProps = {
  canEdit: false,
  disableSave: false,
  disableClear: false,
  entityName: undefined,
  totalEntities: undefined,
  activeFilter: undefined,
  onClearQuery: undefined,
  onSaveQuery: undefined,
  onSelectFilter: undefined,
  onDeleteFilter: undefined,
  filters: []
};

QueryFiltersHeader.propTypes = {
  canEdit: PropTypes.bool,
  disableSave: PropTypes.bool,
  disableClear: PropTypes.bool,
  entityName: PropTypes.bool,
  totalEntities: PropTypes.number,
  activeFilter: IFilterDetails,
  filters: PropTypes.arrayOf(IFilterDetails),
  onClearQuery: PropTypes.func,
  onSaveQuery: PropTypes.func,
  onSelectFilter: PropTypes.func,
  onDeleteFilter: PropTypes.func
};

export default QueryFiltersHeader;
