import React from 'react';
import {
  MODEL_REGISTRY_HISTORY_TITLE_BY_TYPE,
  MODEL_REGISTRY_HISTORY_TYPES
} from '@model-registry/constants';
import { getModelVersionStatusText } from '@model-registry/utils/request-status';

const getOriginalAndModifiedValues = props => {
  const { newValue = {}, previousValue = {}, actionType } = props;
  let original = previousValue.note;
  let modified = newValue.note;

  switch (actionType) {
    case MODEL_REGISTRY_HISTORY_TYPES.privacy_changed:
      original = previousValue.isPublic;
      modified = newValue.isPublic;
      break;
    case MODEL_REGISTRY_HISTORY_TYPES.name_changed:
      original = previousValue.modelName;
      modified = newValue.modelName;
      break;
    case MODEL_REGISTRY_HISTORY_TYPES.version_moved:
      original = previousValue.modelName;
      modified = newValue.modelName;
      break;
    case MODEL_REGISTRY_HISTORY_TYPES.stage_added:
      original = previousValue.stage;
      modified = newValue.stage;
      break;
    case MODEL_REGISTRY_HISTORY_TYPES.stage_deleted:
      original = previousValue.stage;
      modified = newValue.stage;
      break;
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_changed:
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_cancelled:
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_change:
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_rejected:
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_approved:
      original = previousValue;
      modified = newValue;
      break;
    default:
      break;
  }

  return {
    original,
    modified
  };
};

export const getHistoryCardComment = props => {
  const { comment } = props || {};

  if (comment) {
    return <>Comment: &quot;{comment}&quot;</>;
  }

  return '';
};

export const getIfCanShowHistoryByFF = ({
  actionType,
  isModelApprovalProcessEnabled
}) => {
  if (
    [
      MODEL_REGISTRY_HISTORY_TYPES.version_status_request_change,
      MODEL_REGISTRY_HISTORY_TYPES.version_status_request_cancelled,
      MODEL_REGISTRY_HISTORY_TYPES.version_status_request_rejected,
      MODEL_REGISTRY_HISTORY_TYPES.version_status_request_approved,
      MODEL_REGISTRY_HISTORY_TYPES.version_status_changed,
      MODEL_REGISTRY_HISTORY_TYPES.version_status_added
    ].includes(actionType) &&
    !isModelApprovalProcessEnabled
  ) {
    return false;
  }

  return true;
};

export const getHistoryCardTitleByType = props => {
  const { actionType, isModelRegistryTagsFromStagesEnabled } = props || {};

  // SMELLY: Stages to tags migration on FE
  if (
    isModelRegistryTagsFromStagesEnabled &&
    [
      MODEL_REGISTRY_HISTORY_TYPES.stage_added,
      MODEL_REGISTRY_HISTORY_TYPES.stage_deleted
    ].includes(actionType)
  ) {
    if (actionType === MODEL_REGISTRY_HISTORY_TYPES.stage_added) {
      return MODEL_REGISTRY_HISTORY_TITLE_BY_TYPE[
        MODEL_REGISTRY_HISTORY_TYPES.tag_added
      ];
    }

    if (actionType === MODEL_REGISTRY_HISTORY_TYPES.stage_deleted) {
      return MODEL_REGISTRY_HISTORY_TITLE_BY_TYPE[
        MODEL_REGISTRY_HISTORY_TYPES.tag_deleted
      ];
    }
  }

  return MODEL_REGISTRY_HISTORY_TITLE_BY_TYPE[actionType];
};

export const getHistoryCardContentByType = props => {
  const {
    actionType,
    registryModelName,
    registryModelItemVersion,
    isModelRegistryTagsFromStagesEnabled
  } = props || {};
  const { original, modified } = getOriginalAndModifiedValues(props);

  switch (actionType) {
    case MODEL_REGISTRY_HISTORY_TYPES.name_changed:
      return (
        <>
          changed the name from {`'${original}'`} to {`'${modified}'`}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.description_changed:
      return <>changed the model description of {registryModelName}</>;
    case MODEL_REGISTRY_HISTORY_TYPES.privacy_changed:
      return (
        <>
          changed the visibility of {registryModelName} from{' '}
          {`'${original === 'true' ? 'Public' : 'Private'}'`} to{' '}
          {`'${modified === 'true' ? 'Public' : 'Private'}'`}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_assets_changed:
      return (
        <>
          changed version of asset from {original} to {modified}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_created:
      return (
        <>
          created version {registryModelItemVersion} for {registryModelName}{' '}
          model
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_deleted:
      return (
        <>
          deleted version {registryModelItemVersion} from {registryModelName}{' '}
          model
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_moved:
      return (
        <>
          moved version {registryModelItemVersion} from {original} model to{' '}
          {modified} model
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_downloaded:
      return (
        <>
          downloaded model version {registryModelItemVersion} of{' '}
          {registryModelName}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.notes_created:
      return <>created a note for {registryModelName}</>;
    case MODEL_REGISTRY_HISTORY_TYPES.notes_changed:
      return <>changed a note on {registryModelName}</>;
    case MODEL_REGISTRY_HISTORY_TYPES.notes_deleted:
      return <>deleted a note from {registryModelName}</>;
    // SMELLY: Stages to tags migration on FE
    case MODEL_REGISTRY_HISTORY_TYPES.stage_added:
      return (
        <>
          added {`'${modified}'`}{' '}
          {isModelRegistryTagsFromStagesEnabled ? 'tag' : 'stage'} on{' '}
          {registryModelName} model on version {registryModelItemVersion}
        </>
      );
    // SMELLY: Stages to tags migration on FE
    case MODEL_REGISTRY_HISTORY_TYPES.stage_deleted:
      return (
        <>
          deleted {`'${original}'`}{' '}
          {isModelRegistryTagsFromStagesEnabled ? 'tag' : 'stage'} on{' '}
          {registryModelName} model on version {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_notes_created:
      return (
        <>
          created a note for {registryModelName} on version{' '}
          {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_notes_changed:
      return (
        <>
          changed a note in {registryModelName} on version{' '}
          {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_notes_deleted:
      return (
        <>
          deleted a note in {registryModelName} for version{' '}
          {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_changed:
      if (!original?.registryModelStatus) {
        return (
          <>
            {modified?.changedBy} added &apos;
            {getModelVersionStatusText(modified?.registryModelStatus)}
            &apos; status on {registryModelName} - {registryModelItemVersion}
          </>
        );
      }

      return (
        <>
          changed the status from &apos;
          {getModelVersionStatusText(original?.registryModelStatus)}&apos; to
          &apos;
          {getModelVersionStatusText(modified?.registryModelStatus)}&apos; on{' '}
          {registryModelName} - {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_change:
      return (
        <>
          {modified?.changedBy} request to change the status from &apos;
          {getModelVersionStatusText(original?.registryModelStatus)}&apos; to
          &apos;
          {getModelVersionStatusText(modified?.registryModelStatus)}&apos; on{' '}
          {registryModelName} - {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_approved:
      return (
        <>
          approved {original?.changedBy} request to change the status from
          &apos;
          {getModelVersionStatusText(original?.registryModelStatus)}&apos; to
          &apos;
          {getModelVersionStatusText(modified?.registryModelStatus)}&apos; on{' '}
          {registryModelName} - {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_rejected:
      return (
        <>
          rejected {original?.changedBy} request to change the status from
          &apos;{getModelVersionStatusText(original?.registryModelStatus)}&apos;
          to &apos;
          {getModelVersionStatusText(modified?.registryModelStatus)}&apos; on{' '}
          {registryModelName} - {registryModelItemVersion}
        </>
      );
    case MODEL_REGISTRY_HISTORY_TYPES.version_status_request_cancelled:
      return (
        <>
          {modified?.changedBy} cancelled the request to change the status from
          &apos;{getModelVersionStatusText(original?.registryModelStatus)}&apos;
          to &apos;
          {getModelVersionStatusText(modified?.registryModelStatus)}&apos; on{' '}
          {registryModelName} - {registryModelItemVersion}
        </>
      );
    default:
      return null;
  }
};
