import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';
import { ModelType } from '@mpm-druid/types';

type ModelsParams = {
  page?: number;
  pageSize?: number;
  modelIds?: string[];
  sortColumn?: string;
  order?: string;
  predicates?: unknown[];
};

type ModelsResponse = {
  models: ModelType[];
  total: number;
};

const getModels = ({
  ...restParams
}: ModelsParams & { workspaceId: string }) => {
  return api.post(`mpm/v2/model/search`, restParams);
};

export function useModels(
  params: ModelsParams,
  config: QueryConfig<ModelsResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['models-search', { workspaceId: activeWorkspace?.id, ...params }],
    () => getModels({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config,
      enabled:
        !!activeWorkspace?.id && (!('enabled' in config) || config.enabled)
    }
  );
}
