import { Metric } from '@shared/types';
import { noop } from 'lodash';
import { Context, createContext, useContext } from 'react';

interface MetricDataContextType {
  onEditMetric: (rowData: Metric) => void;
  onDeleteMetric: (key: string) => void;
  decimalsPrecision: number;
}

export const MetricDataContext: Context<MetricDataContextType> = createContext<MetricDataContextType>(
  {
    onEditMetric: noop,
    onDeleteMetric: noop,
    decimalsPrecision: Infinity
  }
);

export const useMetricDataContext = (): MetricDataContextType =>
  useContext<MetricDataContextType>(MetricDataContext);
