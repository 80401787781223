import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import PropTypes from 'prop-types';
import React from 'react';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { useExperimentGraphDefinition } from '@experiment-details/api';
import BaseCodeView from '@experiment-details/components/Code/BaseCodeView';
import GraphDefinitionTabEmpty from './GraphDefinitionTabEmpty';

const GraphDefinitionTab = props => {
  const [experiment] = props.experiments;
  const { data: graphDefinition, isLoading } = useExperimentGraphDefinition({
    experimentKey: experiment.experimentKey
  });

  return (
    <div className="graph-definition__tab">
      <BaseCodeView
        {...props}
        data={graphDefinition}
        detailView={DETAIL_VIEWS.GRAPH_DEFINITION}
        isLoading={isLoading}
        emptyView={<GraphDefinitionTabEmpty />}
      />
    </div>
  );
};

GraphDefinitionTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default GraphDefinitionTab;
