import React, { useCallback } from 'react';

import { Box, Grid } from '@material-ui/core';
import { Switch } from '@DesignSystem/controllers';
import useOrganizationSettings from '@shared/api/useOrganizationSettings';
import useOrganizationSettingsUpdateMutation from '@account-settings/api/useOrganizationSettingsUpdateMutation';
import { ORGANIZATIONS_SETTINGS } from '@shared/constants/organization';
import { Snackbar } from '@DesignSystem/feedback';
import SmallLoader from '@shared/components/SmallLoader';

import classNames from './CustomizeOrganizationSettings.module.scss';
import { useCurrentOrganization } from '@shared/hooks';

const CustomizeOrganizationSettings = () => {
  const currentOrganization = useCurrentOrganization();
  const { data: value, isLoading } = useOrganizationSettings(
    currentOrganization?.id,
    {
      settingName:
        ORGANIZATIONS_SETTINGS.ONLY_ADMIN_INVITE_FROM_OUTSIDE_ORGANIZATION
    }
  );

  const updateWorkspaceMutation = useOrganizationSettingsUpdateMutation(
    currentOrganization?.id,
    ORGANIZATIONS_SETTINGS.ONLY_ADMIN_INVITE_FROM_OUTSIDE_ORGANIZATION
  );

  const changeOrganizationSettingsForWorkspace = useCallback(() => {
    updateWorkspaceMutation.mutate(!value);
  }, [value]);

  const isSaving = updateWorkspaceMutation.status === 'loading';

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <div className={classNames.organizationSettingsContainer}>
      <Snackbar open={isSaving} message="Saving..." />

      <Box component="h4" className="page-title" mb={3}>
        Organization Settings
      </Box>

      <Box className="field-label" mb={6}>
        Customize your organization settings
      </Box>

      <Grid
        container
        alignItems="flex-start"
        alignContent="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={1}>
          <Switch
            classes={{
              root: classNames.organizationSettingsToggle
            }}
            disabled={isSaving}
            id="ONLY_ADMIN_INVITE_FROM_OUTSIDE_ORGANIZATION"
            checked={value}
            onChange={changeOrganizationSettingsForWorkspace}
            color="primary"
          />
        </Grid>
        <Grid item xs={11}>
          <label
            htmlFor="ONLY_ADMIN_INVITE_FROM_OUTSIDE_ORGANIZATION"
            onClick={changeOrganizationSettingsForWorkspace}
            className={classNames.organizationSettingsToggleLabel}
          >
            Only organization Admins can invite users into the organization
          </label>

          <small className={classNames.organizationSettingsToggleText}>
            When disabled, all members in the organization can invite users into
            the organization which can influence billing. When enabled, only
            organization admins will be able to invite users to the
            organization.
          </small>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomizeOrganizationSettings;
