import useCurrentUser from '@API/auth/useCurrentUser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsFirstSession } from '@/reducers/userReducer';
import userActions from '@/actions/userActions';

const useInitIsFirstSession = () => {
  const isFirstSession = useSelector(getIsFirstSession);

  const dispatch = useDispatch();
  const { data: currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser && !currentUser.shownPostSignupForm && !isFirstSession) {
      dispatch(userActions.setIsFirstSession(true));
    }
  }, [currentUser, isFirstSession, dispatch]);
};

export default useInitIsFirstSession;
