import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import cx from 'classnames';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';

import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import useAssetsForExperiments from '@experiment-management-shared/api/useAssetsForExperiments';
import useSelectedExperiments from '@experiment-details/hooks/useSelectedExperiments';

import SmallLoader from '@shared/components/SmallLoader';
import ExperimentAssetsConfigBar from '@experiment-details/components/ExperimentAssetsConfigBar';
import AudiosGroupedBy from './AudiosGroupedBy';
import MultipleAudiosTable from './MultipleAudiosTable';
import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';

import styles from './AudioTab.module.scss';
import AudioTabEmpty from './AudioTabEmpty';
import { TableMaxHeightProvider } from '@DesignSystem/tables';

const AUDIO_ASSET_TYPE = 'audio';

const AudioTab = ({ experiments }) => {
  const [assets, setAssets] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [experiment] = experiments;

  const isComparePage = useSelector(selectIsComparePage);

  const { experiment1, experiment2 } = useSelectedExperiments(experiments);

  const experimentKeys = useMemo(() => {
    if (!experiment2?.experimentKey) {
      return [experiment1.experimentKey];
    }

    return [experiment1.experimentKey, experiment2.experimentKey];
  }, [experiment1.experimentKey, experiment2?.experimentKey]);

  const { data: assetSummaries, isLoading } = useAssetsForExperiments(
    {
      experiments,
      type: AUDIO_ASSET_TYPE
    },
    {
      refetchInterval: false
    }
  );
  const isTabEmpty = !assetSummaries.length && !isLoading;

  const handleAssetsSearch = ({ assets, groupBy }) => {
    setAssets(assets);
    setGroupBy(groupBy);
  };

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  const renderContent = () => {
    if (groupBy) {
      return (
        <AudiosGroupedBy assets={assets} experimentKeys={experimentKeys} />
      );
    }

    return (
      <TableMaxHeightProvider>
        <MultipleAudiosTable assets={assets} experimentKeys={experimentKeys} />
      </TableMaxHeightProvider>
    );
  };

  return (
    <div className={cx(styles.container, { [styles.compare]: isComparePage })}>
      <ExperimentAssetsConfigBar
        assets={assetSummaries}
        experimentKey={experiment.experimentKey}
        onAssetsSearch={handleAssetsSearch}
        valuePathCategory={EXPERIMENT_VIEW_TAB_FIELDS.AUDIO}
        enableAutoResearch
        hideStepPlayer
        isTabEmpty={isTabEmpty}
      />

      {isComparePage && (
        <CompareContentSelector
          experiments={experiments}
          experiment1={experiment1}
          experiment2={experiment2}
        />
      )}

      {isTabEmpty && !isComparePage ? <AudioTabEmpty /> : renderContent()}
    </div>
  );
};

AudioTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default AudioTab;
