import { find, findIndex, isNumber, last, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { GRAPHIC_DEFAULT_STEP } from '@/lib/appConstants';
import { Slider } from '@design-system-outdated/components';
import { AssetStep } from '@experiment-management-shared/types';

import styles from './GraphicStepSection.module.scss';

export type GraphicStepAsset = {
  experimentKey: string;
  name: string;
  step: AssetStep;
};

export type GraphicStepSectionChanges = {
  asset: GraphicStepAsset;
};

export function GraphicStepSection<T extends GraphicStepAsset>({
  asset,
  assets,
  onChangeSettings
}: {
  asset: T;
  assets: T[];
  onChangeSettings: (changes: GraphicStepSectionChanges) => void;
}) {
  const steps = useMemo(() => {
    if (!assets) return [];

    const steps = assets
      .filter(({ name, step }) => {
        return name === asset.name && (step !== undefined || step !== null);
      })
      .map(image => image.step || 0);

    return uniq(steps.sort((a, b) => a - b));
  }, [asset, assets]);

  // stores the index of image in imageSteps array
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (!steps) return;

    const newStepIndex = findIndex(steps, step => {
      const actualStep = asset.step ?? 0;

      return step === actualStep;
    });

    setStepIndex(newStepIndex);
  }, [asset, steps]);

  const handleStepSliderChange = (index: number) => {
    const newAsset = find(assets, ({ name, step }) => {
      const actualStep = steps[index] ?? 0;

      return name === asset.name && step === actualStep;
    });

    // @todo: fix this, it's broken in all the grid panels
    // we don't have the assets for all the steps beforehand
    // it's needed a new endpoint or reuse an endpoint from
    // the experiment page to get all the assets (not only the steps)
    if (!newAsset) return;

    onChangeSettings({ asset: newAsset });
  };

  const stepSliderLabelFormat = (index: number) => {
    const lastStep = last(steps) || GRAPHIC_DEFAULT_STEP;
    const currentStep = steps[index] || GRAPHIC_DEFAULT_STEP;

    return isNumber(lastStep) && isNumber(currentStep)
      ? `${currentStep}/${lastStep}`
      : '';
  };

  return (
    <div className={styles.container}>
      <p>Step</p>
      <div className={styles.slider}>
        <Slider
          max={steps.length - 1}
          min={0}
          onChange={index => setStepIndex(index)}
          onChangeCommitted={handleStepSliderChange}
          sliderSuffixFormat={stepSliderLabelFormat}
          step={1}
          value={stepIndex}
          valueLabelFormat={index => steps[index]}
        />
      </div>
    </div>
  );
}
