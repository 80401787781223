import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import alertsUtil from '../../util/alertsUtil';
import { dialogTypes } from '../../constants/alertTypes';

const useErrorMessageParam = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { errorMessage } = queryString.parse(search);

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.ERROR_MESSAGE_PARAM_MODAL,
          errorMessage
        )
      );
    }
  }, [errorMessage, dispatch]);
};

export default useErrorMessageParam;
