import { EmptyExperimentTab } from '@shared';
import React from 'react';

const AudioTabEmpty = () => (
  <EmptyExperimentTab>
    <EmptyExperimentTab.Icon iconName="audio" />
    <EmptyExperimentTab.Title>
      This experiment did not log any audio assets
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      Use <b>log_audio()</b> on your Experiment object to report audios during
      experiments.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/audio/" />
  </EmptyExperimentTab>
);

export default AudioTabEmpty;
