import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import get from 'lodash/get';
import noop from 'lodash/noop';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import {
  SORT_BY_DIRECTION,
  COLUMN_FORMAT_KEYS,
  DEFAULT_PINNED_ITEM_CONFIG
} from '@/constants/workspaceConstants';
import tableCellFormats from './tableCellFormats';
import {
  NEW_GENERAL_PROJECT_NAME,
  PROJECT_TYPE
} from '@/constants/projectConstants';

const TableDisplay = ({
  columns,
  getURLForRowItem,
  handleSortByChange,
  isEditable,
  isFetchingNewData,
  items,
  sortByDirection,
  sortByProperty,
  getMenuItemConfigs,
  getPinnedItemConfig,
  renderTablePagination
}) => {
  const isDesc = direction => {
    return direction === SORT_BY_DIRECTION.DESC;
  };

  const getNewSortDirection = () => {
    const currentSortDirection = sortByDirection;

    const newSortDirection = isDesc(currentSortDirection)
      ? SORT_BY_DIRECTION.ASC
      : SORT_BY_DIRECTION.DESC;

    return newSortDirection;
  };

  const handleSortBy = columnKey => {
    const newSortDirection = getNewSortDirection();
    handleSortByChange(columnKey, newSortDirection);
  };

  const renderColHeader = displayName => {
    return <span className="project-list-table-head">{displayName}</span>;
  };

  const renderColHeaderWithSort = (columnKey, label) => {
    const isColumnSorted = columnKey === sortByProperty;
    const icon = isDesc(sortByDirection) ? 'arrow_downward' : 'arrow_upward';

    return (
      <span
        className="project-list-table-head project-list-table-head-sortable"
        onClick={() => handleSortBy(columnKey)}
      >
        <div className="project-list-head-cell">
          {label}
          {isColumnSorted ? <i className="material-icons">{icon}</i> : null}
        </div>
      </span>
    );
  };

  const getTableCellsForRow = item => {
    return columns.map((column, index) => {
      const { dataKey, formatKey, textAlign = 'left', className } = column.cell;
      const { isPinned, color, handler, text } = getPinnedItemConfig(
        item,
        index
      );

      let content = get(item, dataKey, null);
      if (dataKey === 'projectName' && content === 'general') {
        content = NEW_GENERAL_PROJECT_NAME;
      }

      if (dataKey === 'experimentCount' && item.type === PROJECT_TYPE.LLM) {
        content = '___';
      }

      let cellContent = content;
      if (tableCellFormats.hasOwnProperty(formatKey)) {
        if (
          formatKey === COLUMN_FORMAT_KEYS.IMAGE ||
          formatKey === COLUMN_FORMAT_KEYS.LINK ||
          formatKey === COLUMN_FORMAT_KEYS.SIMPLE_LINK
        ) {
          const url = getURLForRowItem(item);
          cellContent = tableCellFormats[formatKey](
            content,
            url,
            {
              isPinned,
              color,
              text
            },
            className
          );
        } else if (formatKey === COLUMN_FORMAT_KEYS.MENU_BTN) {
          const menuItemConfigs = getMenuItemConfigs(item);
          if (!isEditable) return;

          if (isPinned && handler) {
            cellContent = tableCellFormats[COLUMN_FORMAT_KEYS.PINNED_ITEM](
              handler
            );
          } else {
            cellContent = tableCellFormats[formatKey](menuItemConfigs);
          }
        } else {
          cellContent = tableCellFormats[formatKey](content);
        }
      }

      return (
        <TableCell key={dataKey} style={{ textAlign }}>
          {cellContent}
        </TableCell>
      );
    });
  };

  const renderTableRows = () => {
    const opacity = isFetchingNewData ? 0.3 : 1.0;
    return items.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={index} style={{ opacity }}>
          {getTableCellsForRow(item, index)}
        </TableRow>
      );
    });
  };

  const renderTableHeader = () => {
    const headerCells = columns
      .filter(column => !column.header.isHidden)
      .map(column => {
        const { header, cell } = column;

        let cellContent;
        if (header.isSortable) {
          cellContent = renderColHeaderWithSort(cell.dataKey, header.label);
        } else {
          cellContent = renderColHeader(header.label);
        }

        return (
          <TableCell key={cell.dataKey} colSpan={header.colSpan || 1}>
            {cellContent}
          </TableCell>
        );
      });

    return (
      <TableHead>
        <TableRow>{headerCells}</TableRow>
      </TableHead>
    );
  };

  const renderTableFooter = () => {
    if (!renderTablePagination) return null;

    return (
      <TableFooter>
        <TableRow>{renderTablePagination()}</TableRow>
      </TableFooter>
    );
  };

  return (
    <Table classes={{ root: 'comet-table-container' }}>
      {renderTableHeader()}
      <TableBody>{renderTableRows()}</TableBody>
      {renderTableFooter()}
    </Table>
  );
};

TableDisplay.defaultProps = {
  getPinnedItemConfig: () => DEFAULT_PINNED_ITEM_CONFIG,
  isFetchingNewData: false,
  renderTablePagination: noop
};

TableDisplay.propTypes = {
  columns: PropTypes.array.isRequired,
  getMenuItemConfigs: PropTypes.func.isRequired,
  getPinnedItemConfig: PropTypes.func,
  getURLForRowItem: PropTypes.func.isRequired,
  handleSortByChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isFetchingNewData: PropTypes.bool,
  items: PropTypes.array.isRequired,
  renderTablePagination: PropTypes.func,
  sortByDirection: PropTypes.string.isRequired,
  sortByProperty: PropTypes.string.isRequired
};

export default withRouter(TableDisplay);
