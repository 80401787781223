import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import cx from 'classnames';

import { Button, TextButton } from '@ds';
import { Divider, StyledTooltip } from '@DesignSystem/data-display';
import { HelpIcon } from '@Icons-outdated';
import { SimpleCard } from '@DesignSystem/surfaces';
import { useActiveOrganizationPaymentDetails } from '@account-settings/api';

import { URLS } from '@/lib/appConstants';
import { FREE_PLANS, PAYMENT_PLANS } from '@shared/constants';
import UpgradePlanModal from './UpgradePlanModal';
import SmallLoader from '@shared/components/SmallLoader';
import { CancelPaymentPlanModal } from './UpdatePlanComponents';
import { getTrialInEndsLabel } from '@/helpers/organizationHelpers';
import { useIsViewOnlyEnabled, useCurrentPaymentPlan } from '@shared/hooks';
import { INITIAL_OPEN_UPGRADE_CARD } from '@account-settings/constants';

const BillingOverview = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const isUpgradePopupOpenedByDefault = Boolean(
    searchParams.get(INITIAL_OPEN_UPGRADE_CARD)
  );

  const [isUpgradeOpen, setIsUpgradeOpen] = useState(
    isUpgradePopupOpenedByDefault
  );
  const [isCancelPlanOpen, setIsCancelPlanOpen] = useState(false);

  const {
    paymentPlanName,
    isSuspended,
    isTrial,
    isAcademic,
    isStartupFree,
    timestampWhenTrialEnds
  } = useCurrentPaymentPlan();

  const paymentPlan = PAYMENT_PLANS[paymentPlanName];
  const {
    data: paymentDetails,
    isLoading
  } = useActiveOrganizationPaymentDetails({ refetchOnMount: true });

  const isViewOnlyEnabled = useIsViewOnlyEnabled();

  const renderThirdUserDetailsColumn = () => {
    return (
      <div className="details-wrapper">
        <p>{isViewOnlyEnabled ? 'View only members' : 'Pending'}</p>
        <p>
          {isViewOnlyEnabled
            ? paymentDetails?.viewOnlyMembersCount
            : paymentDetails?.pendingMembersCount}
        </p>
      </div>
    );
  };

  const openUpgradePlanModal = useCallback(() => setIsUpgradeOpen(true), []);
  const closeUpgradePlanModal = () => {
    setIsUpgradeOpen(false);
    if (isUpgradePopupOpenedByDefault) {
      history.replace(pathname);
    }
    if (isSuspended) {
      history.push('/');
    }
  };
  const harshCloseUpgradePlanModal = () => setIsUpgradeOpen(false);

  const closeCancelPlanModal = () => setIsCancelPlanOpen(false);

  const formatStartDate = () => {
    if (
      paymentDetails?.startedAt &&
      moment(paymentDetails?.startedAt).isValid()
    ) {
      return moment(paymentDetails?.startedAt).format('MM.DD.YYYY');
    }

    return <div className="no-date-data" />;
  };

  const IS_POSSIBLE_TO_CANCEL_PLAN = paymentDetails?.allMembersCount <= 1;

  const currentPlan = useMemo(() => {
    if (isAcademic) {
      return 'Academic';
    }

    if (isStartupFree) {
      return 'Startup';
    }

    return paymentPlan?.displayName;
  }, [isAcademic, paymentPlan?.displayName, isStartupFree]);

  const handleCancelPlan = () => {
    setIsCancelPlanOpen(true);
  };

  useEffect(() => {
    if (isSuspended) {
      openUpgradePlanModal();
    }
  }, [isSuspended, openUpgradePlanModal]);

  return (
    <>
      <UpgradePlanModal
        open={isUpgradeOpen}
        onClose={closeUpgradePlanModal}
        onHarshClose={harshCloseUpgradePlanModal}
      />
      <CancelPaymentPlanModal
        open={isCancelPlanOpen}
        onClose={closeCancelPlanModal}
      />
      {!isLoading ? (
        <>
          <div className="overview-container">
            <h4>Plan details</h4>
            <SimpleCard className={cx({ 'no-bottom-margin': isTrial })}>
              <div className="overview-card">
                <div className="details-wrapper">
                  <p>Current plan </p>
                  <p style={{ color: paymentPlan?.color }}>{currentPlan}</p>
                </div>
                <Divider orientation="vertical" />
                <div className="details-wrapper">
                  <p>Start date</p>
                  <p>{formatStartDate()}</p>
                </div>
                <div className="details-wrapper">
                  {!isAcademic && (
                    <Button onClick={openUpgradePlanModal} size="large">
                      Upgrade now
                    </Button>
                  )}

                  {!FREE_PLANS.includes(paymentPlanName) && !isAcademic && (
                    <TextButton
                      className="cancel-plan-btn"
                      onClick={handleCancelPlan}
                      disabled={!IS_POSSIBLE_TO_CANCEL_PLAN}
                    >
                      <StyledTooltip
                        title={
                          IS_POSSIBLE_TO_CANCEL_PLAN
                            ? ''
                            : `Canceling the payment plan is only allowed if you don't have any other members in your organization (except yourself).`
                        }
                      >
                        <span>Cancel plan</span>
                      </StyledTooltip>
                    </TextButton>
                  )}
                </div>
              </div>
            </SimpleCard>
            {isTrial && (
              <div
                className="trial-period-left-label"
                style={{ color: paymentPlan?.color }}
              >
                You have {getTrialInEndsLabel(timestampWhenTrialEnds)} left to
                your &apos;{paymentPlan?.displayName}&apos; trial
              </div>
            )}

            <h4>Users details</h4>
            <SimpleCard className="users-details">
              <div className="overview-card">
                <div className="details-wrapper">
                  <p>Members </p>
                  <p className="highlight">{paymentDetails?.allMembersCount}</p>
                </div>
                <Divider orientation="vertical" />
                <div className="details-wrapper">
                  <p>Admins</p>
                  <p>{paymentDetails?.adminMembersCount}</p>
                </div>
                <Divider orientation="vertical" />
                {renderThirdUserDetailsColumn()}
              </div>
            </SimpleCard>
          </div>
          <div className="overview-footer">
            <HelpIcon />
            <p>
              <span>Questions? </span>
              <a
                href={URLS.CONTACT_US_NEW_SITE}
                target="_blank"
                rel="noreferrer"
              >
                Contact support
              </a>
            </p>
          </div>
        </>
      ) : (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Loading billing overview"
        />
      )}
    </>
  );
};

BillingOverview.propTypes = {};

export default BillingOverview;
