import {
  AssetsAndArtifactsIcon,
  AudioIcon,
  CodeIcon,
  ConfusionMatrixIcon,
  GraphDefinitionIcon,
  GraphicsIcon,
  HistogramsIcon,
  HTMLIcon,
  HyperparametersIcon,
  InstalledPackagesIcon,
  MetricsIcon,
  NotesIcon,
  OtherIcon,
  OutputIcon,
  PanelsIcon,
  SystemMetricsIcon,
  TextIcon
} from '@Icons-outdated';

export const FETCH_EXPERIMENT_POLLING_LENGTH = 15000;
export const SAVE_NOTE_FREQUENCY_MS = 250;
export const EXPERIMENT_DETAILS_POLLING_LENGTH = 20000;

// EXPERIMENTS - CONSTANTS
export const DEFAULT_EXPERIMENTS_PER_PAGE = 25;
export const MAX_EXPERIMENTS_PER_PAGE = 100;
export const EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT = 150;

export const EXP_KEY_LENGTH_EXPORT = 9;

// EXPERIMENTS - CHARTS
export const WALL = 'wall';
export const STEP = 'step';
export const DURATION = 'duration';
export const EPOCH = 'epoch';

// EXPERIMENTS
export const EXPERIMENT_TAB_PATHNAME = {
  panels: 'panels',
  experiments: 'experiments',
  notes: 'notes',
  archive: 'archive',
  manage: 'manage',
  reports: 'reports'
};

export const TABS_REQUIRES_MANAGE_PERMISSIONS = [
  EXPERIMENT_TAB_PATHNAME.manage,
  EXPERIMENT_TAB_PATHNAME.archive
];

// EXPERIMENTS - DETAILS
export const DETAIL_VIEWS = {
  ASSETS_AND_ARTIFACTS: 'assetStorage',
  AUDIO: 'audio',
  CODE: 'code',
  CONFUSION_MATRIX: 'confusionMatrix',
  GRAPH_DEFINITION: 'graph',
  HISTOGRAM: 'histograms',
  HTML: 'html',
  HYPER_PARAMETERS: 'params',
  IMAGES: 'images',
  INSTALLED_PACKAGES: 'installedPackages',
  METRICS: 'metrics',
  NOTE: 'notes',
  OTHERPARAMS: 'other',
  PANELS: 'panels',
  STDOUT: 'stdout',
  SYSTEM_METRICS: 'systemMetrics',
  TEXT: 'text'
};

export const VIEWS_EXCLUDED_IN_COMPARE = [DETAIL_VIEWS.ASSETS_AND_ARTIFACTS];

export const DETAIL_VIEW_SETTINGS = {
  [DETAIL_VIEWS.ASSETS_AND_ARTIFACTS]: {
    displayName: 'Assets & Artifacts',
    icon: AssetsAndArtifactsIcon,
    internalTabs: {
      assets: { value: 'assets', label: 'Assets' },
      artifacts: { value: 'artifacts', label: 'Artifacts' }
    }
  },
  [DETAIL_VIEWS.AUDIO]: { displayName: 'Audio', icon: AudioIcon },
  [DETAIL_VIEWS.CODE]: { displayName: 'Code', icon: CodeIcon },
  [DETAIL_VIEWS.CONFUSION_MATRIX]: {
    displayName: 'Confusion matrix',
    icon: ConfusionMatrixIcon
  },
  [DETAIL_VIEWS.GRAPH_DEFINITION]: {
    displayName: 'Graph definition',
    icon: GraphDefinitionIcon
  },
  [DETAIL_VIEWS.HISTOGRAM]: {
    displayName: 'Histograms',
    icon: HistogramsIcon
  },
  [DETAIL_VIEWS.HTML]: { displayName: 'HTML', icon: HTMLIcon },
  [DETAIL_VIEWS.HYPER_PARAMETERS]: {
    displayName: 'Hyperparameters',
    icon: HyperparametersIcon
  },
  [DETAIL_VIEWS.IMAGES]: { displayName: 'Graphics', icon: GraphicsIcon },
  [DETAIL_VIEWS.INSTALLED_PACKAGES]: {
    displayName: 'Installed packages',
    icon: InstalledPackagesIcon
  },
  [DETAIL_VIEWS.METRICS]: { displayName: 'Metrics', icon: MetricsIcon },
  [DETAIL_VIEWS.NOTE]: { displayName: 'Notes', icon: NotesIcon },
  [DETAIL_VIEWS.OTHERPARAMS]: { displayName: 'Other', icon: OtherIcon },
  [DETAIL_VIEWS.PANELS]: {
    displayName: 'Panels',
    icon: PanelsIcon
  },
  [DETAIL_VIEWS.STDOUT]: { displayName: 'Output', icon: OutputIcon },
  [DETAIL_VIEWS.SYSTEM_METRICS]: {
    displayName: 'System metrics',
    icon: SystemMetricsIcon
  },
  [DETAIL_VIEWS.TEXT]: { displayName: 'Text', icon: TextIcon }
};

export const DEFAULT_OPEN_EXPERIMENT_TAB = DETAIL_VIEWS.PANELS;

export const IMPORTED_FROM_KEY = 'Imported from';
export const ARCHIVED_KEY = 'archived';
export const ACTIVE_KEY = 'active';
export const NAME_KEY = 'Name';

export const MIN_COMPARE_EXPERIMENTS_COUNT_TO_SELECT = 3;
export const MIN_COMPARE_EXPERIMENTS_COUNT = 2;
export const MAX_COMPARE_EXPERIMENTS_COUNT = 4;
export const COMPARE_EXPERIMENT_1_KEY = 'experiment1';
export const COMPARE_EXPERIMENT_2_KEY = 'experiment2';
