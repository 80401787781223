import { useQuery } from 'react-query';

import api from '../api';

const getPanelInstance = async ({ instanceId }) => {
  return api.get('code-visualizations/instance/get', { instanceId });
};

export default function usePanelInstance({ instanceId }, config) {
  const enabled = Boolean(instanceId);

  return useQuery(
    ['panelInstance', { instanceId }],
    () => getPanelInstance({ instanceId }),
    { enabled, ...config }
  );
}
