import { createSelector } from 'reselect';

import { biEventActionTypes } from '../../constants/actionTypes';

const initialState = {
  isFirstQuickstartClickDone: false,
  isFirstGetStartedClickDone: false
};

export function onboardingReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case biEventActionTypes.FIRST_GET_STARTED_CLICK_DONE:
      return {
        ...state,
        isFirstGetStartedClickDone: true
      };

    case biEventActionTypes.FIRST_QUICK_START_CLICK_DONE:
      return {
        ...state,
        isFirstQuickstartClickDone: true
      };

    default:
      break;
  }

  return state;
}

export const getBIEventsOnboarding = state => state?.biEvents?.onboarding;

export const getIsBIEventsGetStartedFirstClick = createSelector(
  [getBIEventsOnboarding],
  biEventsOnboarding => biEventsOnboarding?.isFirstGetStartedClickDone
);

export const getIsBIEventsQuckstartFirstClick = createSelector(
  [getBIEventsOnboarding],
  biEventsOnboarding => biEventsOnboarding?.isFirstQuickstartClickDone
);
