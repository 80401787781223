import cx from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import useCurrentUser from '@API/auth/useCurrentUser';
import { useHistory } from 'react-router';

import { ImageCard } from '@DesignSystem/data-display';
import { CopyButton } from '@design-system-outdated/components';
import { Button, TextButton } from '@ds';
import {
  useIsGetStartedAvailable,
  useUpdateGetStartedShownStatus
} from '@onboarding/hooks';
import { APIKeyInput } from '@shared';

import {
  HuggingFaceIcon,
  KerasIcon,
  KeyIcon,
  LangChainIcon,
  PythonIcon,
  PytorchIcon,
  PytorchLightningIcon,
  ScikitLearnIcon,
  TensorFlowIcon,
  XGBoostIcon
} from '@Icons-outdated';
import { useOnboardingFirstClick } from '@onboarding/hooks/biEvents';

import { useActiveWorkspaceName, useDefaultWorkspace } from '@shared/hooks';

import { IS_ON_PREMISE } from '@/lib/appConstants';
import styles from './GetStarted.module.scss';
import useUpdatePaymentPlan from './useUpdatePaymentPlan';

const DEMO_PROJECTS = [
  {
    name: 'llm-demo',
    description:
      'Large Language Model: Tracking prompts, chains and chat conversations.',
    imgUrl: '/images/onboarding/llm-project.png'
  },
  {
    name: 'pytorch-autoencoder',
    description:
      'Pytorch image project: autoencoders to de-noise MNIST image data.',
    imgUrl: '/images/onboarding/pytorch-autoencoder.png'
  },
  {
    name: 'rossmann-forecasting',
    description: 'XGBoost project: forecasting the Rossmann store sales.',
    imgUrl: '/images/onboarding/rossmann-forecasting.png'
  },
  {
    name: 'nlp-movie-reviews',
    description:
      'Keras NLP project: sentiment analysis for IMDB movie reviews.',
    imgUrl: '/images/onboarding/nlp-movie-reviews.png'
  }
];

const GetStarted = () => {
  const history = useHistory();
  const apiKeyContainerRef = useRef();
  const currentWorkspaceName = useActiveWorkspaceName();
  const isGetStartedAvailable = useIsGetStartedAvailable();
  const { onSendFirstClickEvent } = useOnboardingFirstClick();

  const { data: currentUser } = useCurrentUser();
  const defaultWorkspace = useDefaultWorkspace();
  const defaultWorkspaceName = defaultWorkspace?.name;

  const username = currentUser?.username;

  useUpdatePaymentPlan();
  useUpdateGetStartedShownStatus({ isQuickStart: false });

  const handleToQuickStart = useCallback(
    e => {
      e?.stopPropagation();

      if (currentWorkspaceName) {
        history.push(
          `/${currentWorkspaceName}/quick-start?fromGetStarted=true`
        );
        onSendFirstClickEvent('[To Quick start page button]');
      }
    },
    [currentWorkspaceName, history, onSendFirstClickEvent]
  );

  const handleCopyIconClick = () => {
    onSendFirstClickEvent('[On Copy Button Click]');
  };

  useEffect(() => {
    if (!isGetStartedAvailable) {
      handleToQuickStart();
    }
  }, [handleToQuickStart, isGetStartedAvailable]);

  if (!username || !currentWorkspaceName) {
    return null;
  }

  const shouldDisplayDemoProjects = !IS_ON_PREMISE;

  return (
    <>
      <div className={styles.getStartedContainer}>
        <div className={styles.preTitleContainer}>
          <span className={styles.preTitleLabel}>
            Welcome <span data-mask-test="username">{username}</span>
          </span>
          <TextButton
            onClick={() => {
              history.push(`/${defaultWorkspaceName}`);
              onSendFirstClickEvent('[Explore the platform on my own]');
            }}
          >
            Explore the platform on my own
          </TextButton>
        </div>
        <h1 className={styles.titleContainer}>Get started with Comet</h1>
        <div className={styles.postTitleContainer}>
          Add two lines of code to your notebook or script and automatically
          start tracking code, hyperparameters, metrics, and more, so you can
          compare and reproduce training runs.
        </div>

        <div className={styles.getStartedBlocks}>
          <div
            className={cx(styles.getStartedBlock, styles.integrateCometBlock)}
          >
            <div className={styles.blockTitle}>Integrate Comet</div>
            <div className={styles.blockContent}>
              <div className={styles.integrationLabelBlock}>
                <div>
                  A step by step guide for quick Comet integration with your
                  machine learning code. Includes examples and Colab links to
                  easily start logging data.
                </div>

                <Button
                  size="large"
                  className={styles.toQuickStartButton}
                  onClick={handleToQuickStart}
                >
                  To the Quickstart guide
                </Button>
              </div>

              <div className={styles.integrationIconBlock}>
                <div className={styles.integrationIconBlockRow}>
                  <PytorchLightningIcon
                    width="30"
                    height="30"
                    viewBox="0 0 20 25"
                  />
                  <HuggingFaceIcon width="30" height="30" viewBox="0 0 22 21" />
                  <LangChainIcon height="35" className={styles.langChainIcon} />
                </div>
                <div className={styles.integrationIconBlockRow}>
                  <ScikitLearnIcon width="33" height="30" viewBox="0 0 27 15" />
                  <XGBoostIcon width="30" height="30" />
                  <KerasIcon width="29" height="30" viewBox="0 0 27 20" />
                </div>
                <div className={styles.integrationIconBlockRow}>
                  <TensorFlowIcon width="30" height="30" viewBox="0 0 22 22" />

                  <PytorchIcon width="30" height="30" viewBox="0 0 18 25" />
                  <PythonIcon width="30" height="30" viewBox="0 0 22 22" />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(styles.getStartedBlock, styles.copyApiKeyBlock)}
            ref={apiKeyContainerRef}
          >
            <div className={styles.blockTitle}>Copy your API key</div>
            <div className={styles.blockContent}>
              <div>
                API Keys are used to log experiments as well as authenticate to
                Comet APIs.
              </div>

              <div className={styles.copyAPIKeyContainer}>
                <KeyIcon className={styles.keyIcon} />
                <APIKeyInput
                  CopyComponent={({ value }) => (
                    <div className={styles.copyButton}>
                      <CopyButton
                        text={value}
                        tooltipText="Copy API key"
                        onClick={handleCopyIconClick}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {shouldDisplayDemoProjects && (
          <div className={styles.exploreDemoProjectsContainer}>
            <div className={styles.exporeDemoProjectsLabel}>
              Explore our demo projects
            </div>

            <div className={styles.demoProjectsContainer}>
              {DEMO_PROJECTS.map(project => (
                <div className={styles.demoProjectContainer} key={project.name}>
                  <ImageCard
                    title={project.name}
                    description={project.description}
                    imgSrc={project.imgUrl}
                    linkText="Explore Project"
                    linkURL={`/${username}/${project.name}`}
                    onClickLink={() =>
                      onSendFirstClickEvent(
                        `[Open a demo project]: ${project.name}`
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GetStarted;
