export default function (userHtml, showConsole) {
  const id = showConsole
    ? 'screenshot-container'
    : 'screenshot-container-no-console';

  return `
    <div class="iframe-container">
      <div id=${id}>
        <div class="user-html-container">${userHtml}</div>
      </div>
    </div>
  `;
}
