import React from 'react';
import PropTypes from 'prop-types';
import {
  getPermissionByType,
  isUserPermissionValid
} from '@shared/utils/permissions';
import { MANAGEMENT_PERMISSIONS_SERVER } from '@shared/constants/permissions';

const ManageUsersRoleCellText = ({ userPermissions = [] }) => {
  if (
    isUserPermissionValid(
      getPermissionByType(
        userPermissions,
        MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
      )
    )
  ) {
    return 'Workspace owner';
  }

  const text = 'Workspace member';
  const additionalText = [];

  if (
    isUserPermissionValid(
      getPermissionByType(
        userPermissions,
        MANAGEMENT_PERMISSIONS_SERVER.INVITE_USERS
      )
    )
  ) {
    additionalText.push('IU');
  }

  if (
    isUserPermissionValid(
      getPermissionByType(
        userPermissions,
        MANAGEMENT_PERMISSIONS_SERVER.PROJECT_VISIBILITY
      )
    )
  ) {
    additionalText.push('PS');
  }

  return (
    <span>
      {`${text}${
        additionalText.length ? ` (${additionalText.join(',')})` : ''
      }`}
    </span>
  );
};

ManageUsersRoleCellText.propTypes = {
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      permissionName: PropTypes.string,
      permissionValue: PropTypes.string
    })
  ).isRequired
};

export default ManageUsersRoleCellText;
