import React from 'react';
import cx from 'classnames';

import '../Menu/Menu.scss';
import { Link, useLocation } from 'react-router-dom';

const DEFAULT = 'default';
const SECONDARY = 'secondary';
const MENU_1 = 'menu1';

export const DS_MENU_TYPES = {
  SECONDARY,
  DEFAULT,
  MENU_1
};

export type LinkedMenuItem = {
  label: string;
  path: string;
  PrefixIcon?: React.FC;
  disabled?: boolean;
};

type LinkedMenuProps = {
  items: Array<LinkedMenuItem>;
  type?: keyof typeof DS_MENU_TYPES;
  containerClassName?: string;
};

const LinkedMenu: React.FC<LinkedMenuProps> = ({
  items,
  type = DS_MENU_TYPES.DEFAULT,
  containerClassName
}) => {
  const { pathname } = useLocation();

  return (
    <div className={cx('ds-menu', type, containerClassName)}>
      <ul>
        {items.map(({ PrefixIcon, ...item }) => (
          <li key={item.path}>
            <Link
              className={cx('menu-item', {
                active: pathname === item.path,
                disabled: item.disabled
              })}
              to={location => ({ ...location, pathname: item.path })}
            >
              {PrefixIcon && (
                <div className="menu-item-prefix-icon">
                  <PrefixIcon />
                </div>
              )}
              <div className="menu-item-label">{item.label}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinkedMenu;
