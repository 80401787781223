import { components } from 'react-select';
import React from 'react';

const ValueContainer = props => (
  <components.ValueContainer
    {...props}
    className="sortable-select-value-container"
  />
);

export default ValueContainer;
