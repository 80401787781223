// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmbeddedCodeModal-module__code--TJgNP{background-color:var(--gray-1);color:var(--gray-6);font-family:"Menlo";font-size:12px;line-height:18px;margin-bottom:30px;padding:26px 19px}.EmbeddedCodeModal-module__footer--HU5oh{align-items:center;display:flex;justify-content:flex-end;gap:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"code": `EmbeddedCodeModal-module__code--TJgNP`,
	"footer": `EmbeddedCodeModal-module__footer--HU5oh`
};
module.exports = ___CSS_LOADER_EXPORT___;
