import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  BUILT_IN_CHART_TYPES,
  DASHBOARD_PANELS_GLOBAL_CONFIG_MAP,
  DEFAULT_DASHBOARD_SAMPLE_SIZE,
  SAMPLE_SIZES
} from '@/lib/appConstants';
import PanelSectionsWrapper from '@experiment-management-shared/components/PanelSectionsWrapper';
import DashboardChartsOperationsToolbar from '@projects/components/PanelsPage/DashboardChartsOperationsToolbar';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import usePanelModalHandlers from '@experiment-management-shared/hooks/usePanelModalHandlers';
import {
  calculateHasLockedPanels,
  calculatePanelsCount
} from '@experiment-management-shared';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import {
  PANEL_SOURCES,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';
import PanelModal from '@experiment-management-shared/components/PanelModal';
import { useSelector } from 'react-redux';
import { getActiveExperimentsHeaderTab } from '@/reducers/ui/projectsUiReducer';
import { getSectionId } from '@/reducers/dashboardChartsReducer';

const PANELS_WITH_LOCK = [
  BUILT_IN_CHART_TYPES['BuiltIn/Line'],
  BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
  BUILT_IN_CHART_TYPES['BuiltIn/Bar']
];

const EVENTS_MAP = {
  addPanel: dashboardEvents.PROJECT_PAGE_PANELS_ADD_PANEL_CLICKED,
  lockClick: dashboardEvents.PROJECT_PAGE_PANELS_LOCK_ICON_CLICKED,
  searchPanels: dashboardEvents.PROJECT_PAGE_PANELS_SEARCH
};

const DashboardChartsWrapper = ({ dashboard, experiments, onChange }) => {
  const sectionId = useSelector(getSectionId);

  const {
    panels: {
      config: panelGlobalConfig,
      search: searchText,
      sections,
      isAutoRefreshEnabled
    }
  } = dashboard;

  const hasLockedPanels = calculateHasLockedPanels(sections);
  const hasPanels = calculatePanelsCount(sections) > 0;

  const activeExperimentsHeaderTab = useSelector(getActiveExperimentsHeaderTab);
  const isHiddenTab = activeExperimentsHeaderTab === 2;
  const hiddenExperimentKeys = useMemo(() => {
    if (isHiddenTab) return [];

    return dashboard.hiddenExperimentKeys;
  }, [dashboard.hiddenExperimentKeys, isHiddenTab]);

  const setSearchText = useCallback(
    value => {
      onChange({
        panels: {
          search: value
        }
      });
    },
    [onChange]
  );

  const onSectionsChange = useCallback(
    sections => {
      onChange({
        panels: {
          sections
        }
      });
    },
    [onChange]
  );

  const {
    handleAddBuiltInPanel,
    handleAddCustomPanel,
    handleEditBuiltInPanel,
    handleEditCustomPanel
  } = usePanelModalHandlers({
    sections: sections,
    onSectionsChange,
    panelGlobalConfig,
    globalConfigMap: DASHBOARD_PANELS_GLOBAL_CONFIG_MAP
  });

  const experimentKeys = useDeepMemo(() => {
    return experiments.map(experiment => experiment.experimentKey);
  }, [experiments]);

  const panelHandlers = useMemo(() => {
    return {
      onClearChartConstraints: () => {
        onChange({ hiddenExperimentKeys: [] });
      },
      onMarkExperimentsVisible: (chartId, highlightedExperimentKeys) => {
        const newHiddenExperimentKeys = experimentKeys.filter(
          experimentKey => !highlightedExperimentKeys.includes(experimentKey)
        );

        onChange({ hiddenExperimentKeys: newHiddenExperimentKeys });
      }
    };
  }, [experimentKeys, onChange]);

  const panelSharedConfig = useMemo(
    () => ({
      isAutoRefreshEnabled,
      activeIntervalFetchDelay: 10000,
      showLockCharts: PANELS_WITH_LOCK,
      globalConfigMap: DASHBOARD_PANELS_GLOBAL_CONFIG_MAP,
      sampleSizes: SAMPLE_SIZES,
      fetchFull: false,
      hiddenExperimentKeys,
      experiments,
      experimentKeys
    }),
    [experimentKeys, experiments, hiddenExperimentKeys, isAutoRefreshEnabled]
  );

  const renderPanelModal = () => {
    // Workaround to show a popup in case we have invalid state
    // Examples: No section id in redux
    const isModalOpen = Boolean(sectionId);

    if (isModalOpen && location.hash.includes(`#${SELECT_PANEL_PATH}`)) {
      return (
        <PanelModal
          experiments={experiments}
          experimentKeys={experimentKeys}
          hiddenExperimentKeys={hiddenExperimentKeys}
          onAddBuiltInPanel={handleAddBuiltInPanel}
          onAddCustomPanel={handleAddCustomPanel}
          onEditBuiltInPanel={handleEditBuiltInPanel}
          onEditCustomPanel={handleEditCustomPanel}
          source={PANEL_SOURCES.PROJECT}
        />
      );
    }

    return null;
  };

  const renderBody = () => {
    return (
      <PanelSectionsWrapper
        search={searchText}
        sections={sections}
        panelGlobalConfig={panelGlobalConfig}
        panelHandlers={panelHandlers}
        onSectionsChange={onSectionsChange}
        setSearch={setSearchText}
        defaultSampleSize={DEFAULT_DASHBOARD_SAMPLE_SIZE}
        eventsMap={EVENTS_MAP}
        panelSharedConfig={panelSharedConfig}
      />
    );
  };

  return (
    <div className="dashboard-wrapper">
      <DashboardChartsOperationsToolbar
        hasLockedPanels={hasLockedPanels}
        onChange={onChange}
        searchText={searchText}
        setSearchText={setSearchText}
        disableAllButtons={!hasPanels}
        panelGlobalConfig={panelGlobalConfig}
      />
      {renderPanelModal()}
      {renderBody()}
    </div>
  );
};

DashboardChartsWrapper.propTypes = {
  dashboard: PropTypes.object.isRequired,
  experiments: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DashboardChartsWrapper;
