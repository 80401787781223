import { isMetricNumerical } from '@API/experiments/utils';
import { Tooltip, withStyles } from '@material-ui/core';

export const disableNotNumericalOptionsForGrouping = options => {
  return options.map(yOption => {
    if (isMetricNumerical(yOption)) {
      return yOption;
    }

    return {
      disabled: true,
      tooltipText:
        'Not numerical values are not allowed to be used for grouping',
      ...yOption
    };
  });
};

export const WhiteTooltip = withStyles({
  tooltip: {
    padding: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px'
  }
})(Tooltip);
