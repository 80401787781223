import {
  DEFAULT_COLUMN_NAMES,
  DEFAULT_COLUMN_WIDTHS,
  DEFAULT_DECIMAL_PRECISION,
  REQUIRED_COLUMN_NAMES,
  REQUIRED_COLUMN_WIDTHS
} from '@experiment-management-shared/constants/experimentGridConstants';
import {
  DEFAULT_EXPERIMENTS_PER_PAGE,
  STEP
} from '@experiment-management-shared/constants/experimentConstants';
import { OUTLIERS_VALUES } from '@experiment-management-shared/constants/chartConstants';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';
import { DEFAULT_DASHBOARD_SAMPLE_SIZE } from '@/constants/configConstants';

export const DEFAULT_QUERY = {
  rulesTree: generateEmptyRulesTree(),
  segmentId: ''
};

export const DEFAULT_SORTING = [
  { columnName: 'runActive', direction: 'desc' },
  { columnName: 'end_server_timestamp', direction: 'desc' }
];

export const DEFAULT_PANEL_GLOBAL_CONFIG = {
  selectedXAxis: STEP,
  transformY: null,
  smoothingY: 0,
  selectedOutliers: OUTLIERS_VALUES.SHOW,
  sampleSize: DEFAULT_DASHBOARD_SAMPLE_SIZE
};

export const DEFAULT_PANELS = {
  search: '',
  isAutoRefreshEnabled: true,
  isVisible: true,
  layout: null,
  panels: [],
  sections: [],
  config: DEFAULT_PANEL_GLOBAL_CONFIG
};

export const DEFAULT_TABLE = {
  search: '',
  columnGrouping: [],
  columnOrders: [...REQUIRED_COLUMN_NAMES, ...DEFAULT_COLUMN_NAMES],
  columnSorting: DEFAULT_SORTING,
  columnWidths: [...REQUIRED_COLUMN_WIDTHS, ...DEFAULT_COLUMN_WIDTHS],
  decimalsPrecision: DEFAULT_DECIMAL_PRECISION,
  expandedGroups: [],
  isVisible: true,
  pageNumber: 0,
  pageSize: DEFAULT_EXPERIMENTS_PER_PAGE,
  selection: []
};

export const DEFAULT_VIEW = {
  hiddenExperimentKeys: [],
  panels: DEFAULT_PANELS,
  pinnedExperimentKeys: [],
  query: DEFAULT_QUERY,
  table: DEFAULT_TABLE
};
