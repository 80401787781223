import React from 'react';
import { Tooltip } from '@ds';
import { formatDateTime } from '@shared/utils/displayHelpers';
import { MetricCellRendererData } from '../helpers';

type MetricsTableTimeCellProps = MetricCellRendererData;

const MetricsTableTimeCell: React.FC<MetricsTableTimeCellProps> = ({
  value
}) => {
  return (
    <Tooltip content={formatDateTime(value)} placement="top" truncateOverflow>
      <>{formatDateTime(value)}</>
    </Tooltip>
  );
};

export default MetricsTableTimeCell;
