// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareReportModal-module__copyEmbeddedButton--U1WW_{color:var(--primary-color);cursor:pointer;font-size:14px;line-height:24px;white-space:nowrap}.ShareReportModal-module__copyEmbeddedButton--U1WW_:hover{color:var(--primary-color-hover)}.ShareReportModal-module__copyEmbeddedText--fmHNp{margin-left:4px}.ShareReportModal-module__description--UM52R{color:var(--gray-6);font-size:14px;line-height:21px}.ShareReportModal-module__footer--g2a6D{align-items:flex-start;display:flex;justify-content:space-between;gap:64px}.ShareReportModal-module__shareableSection--mi5CX{display:flex;flex-direction:column;gap:8px}.ShareReportModal-module__toggleContainer--DWTiR{align-items:center;display:flex;gap:8px}.ShareReportModal-module__toggleDescription--o32qC{font-size:11px}.ShareReportModal-module__url--lJYzY{border:1px solid var(--gray-3);color:var(--black);border-radius:4px;flex:1;font-weight:400;line-height:21px;margin-right:14px;overflow:hidden;padding:3px 15px;text-overflow:ellipsis;white-space:nowrap}.ShareReportModal-module__urlContainer--hjaGF{display:flex;padding:10px 0 28px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyEmbeddedButton": `ShareReportModal-module__copyEmbeddedButton--U1WW_`,
	"copyEmbeddedText": `ShareReportModal-module__copyEmbeddedText--fmHNp`,
	"description": `ShareReportModal-module__description--UM52R`,
	"footer": `ShareReportModal-module__footer--g2a6D`,
	"shareableSection": `ShareReportModal-module__shareableSection--mi5CX`,
	"toggleContainer": `ShareReportModal-module__toggleContainer--DWTiR`,
	"toggleDescription": `ShareReportModal-module__toggleDescription--o32qC`,
	"url": `ShareReportModal-module__url--lJYzY`,
	"urlContainer": `ShareReportModal-module__urlContainer--hjaGF`
};
module.exports = ___CSS_LOADER_EXPORT___;
