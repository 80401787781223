import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { useDispatch } from 'react-redux';

type UseExperimentArtifactsParams = {
  experimentKey: string;
  direction: 'all' | 'input' | 'output';
};

const getExperimentArtifacts = async ({
  experimentKey,
  direction,
  signal
}: UseExperimentArtifactsParams & {
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get<{
    artifactVersions: string[];
  }>('data-fetch/get/artifacts', {
    params: { experimentKey, filter: direction },
    signal
  });

  return data.artifactVersions ? data.artifactVersions : [];
};

export default function useExperimentArtifacts(
  { experimentKey, direction = 'all' }: UseExperimentArtifactsParams,
  options: QueryConfig<unknown> = {}
) {
  const dispatch = useDispatch();

  return useQuery(
    ['experiment-artifacts', { experimentKey, direction }],
    ({ signal }) => {
      return getExperimentArtifacts({
        experimentKey,
        direction,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your experiment artifacts.'
          )
        );
      },
      ...options
    }
  );
}
