import React, { useEffect, useMemo, useState } from 'react';
import { useUpsertExperimentMetricMutation } from '@experiment-management-shared/api';
import { useSelector } from 'react-redux';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import styles from './Tabs.module.scss';

import { IconButton } from '@ds';
import { CopyButton as DSCopyButton } from '@design-system-outdated';
import * as yml from 'js-yaml';
import { Experiment } from '@experiment-management-shared/types';
import { Select } from '@DesignSystem/controllers';
import { DSDislikeIcon, DSLikeIcon } from '@ds-icons';

import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import { jsonLanguage } from '@codemirror/lang-json';
import { yamlLanguage } from '@codemirror/lang-yaml';

const CODE_TYPE_YAML = 'yaml';
const CODE_TYPE_JSON = 'json';

const options = [
  { value: CODE_TYPE_YAML, label: 'YAML' },
  { value: CODE_TYPE_JSON, label: 'JSON' }
];

export const LLMSyntaxHighlighter = ({
  data,
  experiment = null,
  withFeedback = false
}: {
  data: unknown;
  experiment?: Experiment | null;
  withFeedback?: boolean;
}) => {
  const upsertExperimentMetricMutation = useUpsertExperimentMetricMutation();

  const canEdit = useSelector(isUserAllowedToEditProject);
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const [userFeedback, setUserFeedback] = React.useState(
    experiment?.user_feedback
  );

  useEffect(() => {
    setUserFeedback(experiment?.user_feedback);
  }, [experiment?.user_feedback]);

  const submitUserFeedback = (value: string) => {
    upsertExperimentMetricMutation.mutate({
      experimentKey: experiment!.experimentKey,
      metric: { name: 'user_feedback', valueCurrent: value }
    });
    setUserFeedback(value);
  };

  const [codeLanguage, setCodeLanguage] = useState(CODE_TYPE_YAML);

  function renderFeedbackActions() {
    return (
      <>
        <IconButton
          Icon={<DSLikeIcon />}
          type={userFeedback == 1 ? 'primary' : 'secondary'}
          disabled={!isUserLoggedIn || !canEdit}
          onClick={() => submitUserFeedback('1')}
          size="small"
        />
        <IconButton
          size="small"
          type={userFeedback == 0 ? 'primary' : 'secondary'}
          Icon={<DSDislikeIcon />}
          disabled={!isUserLoggedIn || !canEdit}
          onClick={() => submitUserFeedback('0')}
        />
      </>
    );
  }

  const formattedCodeByLanguage = useMemo(() => {
    return codeLanguage == CODE_TYPE_YAML
      ? yml.dump(data, { lineWidth: -1 }).trim()
      : JSON.stringify(data, null, 2);
  }, [codeLanguage, data]);

  const renderHeader = () => {
    return (
      <div className={styles.syntaxHighlighterHeaderContainer}>
        {withFeedback && renderFeedbackActions()}
        <DSCopyButton
          message="Successfully copied code"
          text={formattedCodeByLanguage}
          tooltipText="Copy code"
        />
      </div>
    );
  };

  const languageExtension =
    codeLanguage === CODE_TYPE_JSON ? jsonLanguage : yamlLanguage;

  return (
    <div className={styles.llmHighlightContainer} style={{ width: '100%' }}>
      <div className={styles.codeBlockContainer}>
        <div className={styles.codeBlockInnerContainer}>
          <Select
            value={codeLanguage}
            onChange={setCodeLanguage}
            options={options}
          />
          {renderHeader()}
        </div>
      </div>

      <div className={styles.codeMirror}>
        <CodeMirror
          value={formattedCodeByLanguage}
          extensions={[
            languageExtension,
            EditorView.lineWrapping,
            EditorState.readOnly.of(true)
          ]}
        />
      </div>
    </div>
  );
};
