import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const CardsDisplay = ({ items, renderCard, renderPagination }) => {
  const renderItemsAsCards = () =>
    items.map((item, index) => renderCard(item, index));

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="cards-display-container">{renderItemsAsCards()}</div>
      {/* Need to maintain the structure with table to prevent next error:
       validateDOMNesting(...): <td> cannot appear as a child of <div>. */}
      <table>
        <tfoot>
          <tr>
            <td />
            {renderPagination()}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

CardsDisplay.defaultProps = {
  renderPagination: noop
};

CardsDisplay.propTypes = {
  items: PropTypes.array.isRequired,
  renderCard: PropTypes.func.isRequired,
  renderPagination: PropTypes.func
};

export default CardsDisplay;
