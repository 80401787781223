import React, { useState, useRef, useMemo, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Divider from '@material-ui/core/Divider';

import { TextButton, Button } from '@ds';
import { SinglePredicate } from './SinglePredicate';
import {
  useModelDetails,
  usePredicates,
  useValidatePredicates
} from '@mpm-druid/api';
import {
  Feature,
  Label,
  Predicate,
  PredicateQuery,
  FeatureOption,
  OperatorOption
} from '@mpm-druid/types';
import styles from './PredicatesPopover.module.scss';
import { SingleValue } from 'react-select';
import { OptionType } from '@design-system/types';
import { ValidatePredicatesResponse } from '@mpm-druid/api/useValidatePredicates';
import {
  FEATURE_TYPES,
  INITIAL_QUERY_STATE,
  SOURCE_TYPE
} from '@mpm-druid/constants';
import { DSPlusIcon } from '@ds-icons';
import { useQueryString } from 'use-route-as-state';

type PredicatesPopoverProps = {
  anchorEl: HTMLElement | null;
  setAnchorEl: (element: HTMLElement | null) => void;
  handlePredicatesChange: (predicates: PredicateQuery[]) => void;
};

const splittingOperators: string[] = ['>', '<', '<=', '>=', '=', '!='];

const defaultId = uuidv4();

const initialPredicate = {
  id: defaultId,
  isTextMode: false,
  isDisabledTextSwitch: false,
  feature: null,
  operator: null,
  threshold: '',
  predicateStr: '',
  errorMessage: ''
};

export function getPredicateFromNode(nodeData: Predicate) {
  const { isTextMode, predicateStr, feature, operator, threshold } = nodeData;
  if (isTextMode) return predicateStr;
  let str = '';
  if (feature?.value) str += `${feature?.value} `;
  if (operator?.value) str += `${operator?.value} `;
  if (threshold !== '') {
    str +=
      feature?.type === FEATURE_TYPES.CATEGORICAL
        ? `'${threshold}'`
        : `${threshold}`;
  }

  return str.trim();
}

export const PredicatesPopover = ({
  anchorEl,
  setAnchorEl,
  handlePredicatesChange
}: PredicatesPopoverProps) => {
  const [predicatesTree, setPredicatesTree] = useState<Predicate[]>([
    initialPredicate
  ]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, updateParams] = useQueryString(INITIAL_QUERY_STATE);
  const { modelId } = useParams<{ modelId: string }>();
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const { data: modelData } = useModelDetails(
    {
      modelId
    },
    { refetchOnMount: false }
  );

  const predicatesList: PredicateQuery[] = useMemo(() => {
    const defaultPredicate = [{ key: 'all', query: '' }];
    if (
      predicatesTree.length === 1 &&
      !getPredicateFromNode(predicatesTree[0])
    ) {
      return defaultPredicate;
    }
    return defaultPredicate.concat(
      predicatesTree.map(predicate => {
        return {
          key: predicate.id,
          query: getPredicateFromNode(predicate)
        };
      })
    );
  }, [predicatesTree]);

  const validatePredicateMutation = useValidatePredicates({
    predicates: predicatesList
  });

  const batchPredicatesId = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramName = 'predicatesBatchId';
    const paramValue = queryParams.get(paramName);

    const localStorageKey = `predicatesBatchId_${modelId}`;
    const storageKey = localStorage.getItem(localStorageKey);

    return paramValue || storageKey;
  }, [modelId]);

  const { data: restoredPredicates } = usePredicates(
    {
      predicatesBatchId: batchPredicatesId
    },
    { keepPreviousData: true, enabled: !!batchPredicatesId }
  );

  const FEATURES_OPTIONS = useMemo(() => {
    if (!modelData?.model) return [];

    const { features, labels } = modelData.model;

    if (features?.length) {
      return [
        {
          label: 'Features',
          options: features
            .filter(
              (feature: Feature) =>
                feature.source === SOURCE_TYPE.model_input_features
            )
            .map((feature: Feature) => {
              return {
                label: `feature_${feature.name}`,
                value: `feature_${feature.name}`,
                type: feature.type
              };
            })
        },
        {
          label: 'Predictions',
          options: features
            .filter(
              (feature: Feature) =>
                feature.source === SOURCE_TYPE.model_output_features
            )
            .map((feature: Feature) => {
              return {
                label: `prediction_${feature.name}`,
                value: `prediction_${feature.name}`,
                type: feature.type
              };
            })
        },
        {
          label: 'Labels',
          options: labels.map((label: Label) => {
            return {
              label: `label_${label.name}`,
              value: `label_${label.name}`,
              type: label.type
            };
          })
        }
      ];
    }
    return [];
  }, [modelData]);

  useEffect(() => {
    if (
      !restoredPredicates?.predicates ||
      !Array.isArray(restoredPredicates.predicates) ||
      FEATURES_OPTIONS.length === 0
    ) {
      return;
    }
    if (
      restoredPredicates.predicates.length === 1 &&
      !restoredPredicates.predicates[0]
    ) {
      setPredicatesTree([initialPredicate]);
      return;
    }
    const restoredTree: Predicate[] = [];
    restoredPredicates.predicates.forEach(query => {
      if (query) {
        const [feature, operator, threshold] = query.split(' ');

        if (splittingOperators.includes(operator)) {
          const matchFeatureOption = FEATURES_OPTIONS.flatMap(
            option => option.options
          ).find(option => option?.value === feature);

          if (matchFeatureOption) {
            restoredTree.push({
              ...initialPredicate,
              id: uuidv4(),
              feature: matchFeatureOption,
              operator: { value: operator, label: operator },
              threshold: removeQuotes(threshold)
            });
          } else {
            restoredTree.push({
              ...initialPredicate,
              id: uuidv4(),
              predicateStr: query,
              isTextMode: true,
              isDisabledTextSwitch: true
            });
          }
        } else {
          restoredTree.push({
            ...initialPredicate,
            id: uuidv4(),
            predicateStr: query,
            isTextMode: true,
            isDisabledTextSwitch: true
          });
        }
      }
    });

    setPredicatesTree(restoredTree);
  }, [restoredPredicates]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyPredicates = async () => {
    let hasEmptyQueries = false;
    const newPredicateTree = predicatesTree.map(predicate => {
      if (!getPredicateFromNode(predicate)) {
        hasEmptyQueries = true;
        return {
          ...predicate,
          errorMessage: "Filter can't be empty"
        };
      }
      return {
        ...predicate,
        errorMessage: ''
      };
    });
    if (hasEmptyQueries && predicatesTree.length > 1) {
      setPredicatesTree(newPredicateTree);
      return;
    } else if (hasEmptyQueries && predicatesTree.length === 1) {
      setPredicatesTree([initialPredicate]);
    } else {
      setPredicatesTree(prev => {
        return prev.map(item => {
          return { ...item, errorMessage: '' };
        });
      });
    }
    try {
      const data: ValidatePredicatesResponse = await validatePredicateMutation.mutateAsync();
      if (data.predicatesBatchId !== null) {
        const validPredicates = [...predicatesList];
        handlePredicatesChange(validPredicates);
        updateParams(prevParams => ({
          ...prevParams,
          predicatesBatchId: `${data.predicatesBatchId}`
        }));
        localStorage.setItem(
          `predicatesBatchId_${modelId}`,
          `${data.predicatesBatchId}`
        );
        setAnchorEl(null);
      }
      if (data.predicatesBatchId === null) {
        setPredicatesTree((prev: Predicate[]) => {
          return prev.map((leaf: Predicate) => {
            const validationData = data.validation[leaf.id];
            if (validationData && validationData.message) {
              return {
                ...leaf,
                errorMessage: 'Invalid filter'
              };
            }
            return leaf;
          });
        });
      }
    } catch (_) {
      /* empty */
    }
  };

  const handleSwitchText = (idToSwitchText: string) => {
    setPredicatesTree(prev => {
      return prev.map(predicate => {
        if (predicate.id === idToSwitchText) {
          return {
            ...predicate,
            predicateStr: getPredicateFromNode(predicate),
            feature: null,
            operator: null,
            threshold: '',
            isTextMode: !predicate.isTextMode
          };
        }
        return predicate;
      });
    });
  };

  const handleValueChange = (
    idToChangeValue: string,
    newValue: string | FeatureOption | OperatorOption | SingleValue<OptionType>,
    key: string
  ) => {
    setPredicatesTree(prev => {
      return prev.map(predicate => {
        if (predicate.id === idToChangeValue) {
          if (
            key === 'feature' &&
            newValue &&
            typeof newValue === 'object' &&
            'type' in newValue &&
            predicate.feature?.type !== newValue.type
          ) {
            return {
              ...predicate,
              operator: null,
              threshold: newValue.type === FEATURE_TYPES.CATEGORICAL ? '' : 0,
              [key]: newValue
            };
          }
          return {
            ...predicate,
            [key]: newValue
          };
        }
        return predicate;
      });
    });
  };

  const handleAddNewFilter = () => {
    setPredicatesTree(prev => {
      const updatedPredicates = [
        ...prev,
        { ...initialPredicate, id: uuidv4() }
      ];
      return updatedPredicates;
    });

    setTimeout(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight;
      }
    }, 50);
  };

  const handleRemoveItem = (idToRemove: string) => {
    if (predicatesTree.length === 1) {
      setPredicatesTree([initialPredicate]);
      return;
    }
    setPredicatesTree(prev => {
      const updatedPredicates = prev.filter(
        predicate => predicate.id !== idToRemove
      );
      return updatedPredicates;
    });
  };

  return (
    <Popover
      id="predicates-panel-selection"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      classes={{ paper: `${styles.predicatesPanelPopover}` }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disableEnforceFocus
    >
      <div className={styles.predicatesWrapper}>
        <div className={styles.menuColumn}>
          <div ref={scrollableDivRef} className={styles.predicatesContainer}>
            {predicatesTree.map((tree, idx) => {
              const {
                id,
                isTextMode,
                isDisabledTextSwitch,
                feature,
                operator,
                threshold,
                predicateStr,
                errorMessage
              } = tree;

              return (
                <>
                  <SinglePredicate
                    key={id}
                    id={id}
                    isTextMode={isTextMode}
                    isDisabledTextSwitch={isDisabledTextSwitch}
                    feature={feature}
                    featureOptions={FEATURES_OPTIONS}
                    operator={operator}
                    threshold={threshold}
                    predicateStr={predicateStr}
                    handleSwitchText={handleSwitchText}
                    handleRemoveItem={handleRemoveItem}
                    handleValueChange={handleValueChange}
                    errorMessage={errorMessage}
                  />
                  {idx !== predicatesTree.length - 1 && (
                    <Divider className={styles.divider} />
                  )}
                </>
              );
            })}
            <div className={styles.addFilterContainer}>
              <TextButton
                type="primary"
                size="medium"
                PrefixIcon={<DSPlusIcon />}
                onClick={() => {
                  handleAddNewFilter();
                }}
              >
                Add Filter
              </TextButton>
            </div>
          </div>
          <div className={styles.bottomActions}>
            <TextButton onClick={handleClose}>Close</TextButton>
            <Button onClick={handleApplyPredicates}>Apply</Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};

function removeQuotes(str: string) {
  const trimmedStr = str.trim();
  if (trimmedStr.startsWith("'") && trimmedStr.endsWith("'")) {
    return trimmedStr.slice(1, -1);
  }
  return trimmedStr;
}
