import React, { useCallback, useMemo } from 'react';
import { TextButton } from '@ds';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import noop from 'lodash/noop';
import uniq from 'lodash/uniq';

import {
  DEFAULT_COLUMN_WIDTH,
  GRID_COLUMNS,
  REQUIRED_COLUMN_NAMES
} from '@experiment-management-shared/constants/experimentGridConstants';
import ReactGrid from '@experiment-management-shared/components/ReactGrid';
import { SPECIAL_CELL_COMPONENTS } from '@experiment-management-shared/components/TableCells';
import { transformColumnNameToColumn } from '@experiment-management-shared/utils/reactGridHelpers';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';

import ExpandingSearchBar from '@design-system-outdated/components/ExpandingSearchBar';
import {
  SEARCH_BAR_VARIANT,
  SEARCH_MODE_TYPE
} from '@design-system-outdated/components/ExpandingSearchBar/ExpandingSearchBar';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { useTableHeight } from '@shared/hooks/useTableHeight';
import EmptySectionMessage from '@projects/components/EmptySectionMessage';
import ThrottlingPopover from '@experiment-management-shared/components/ThrottlingPopover';
import VisibilityAllButton from '@experiment-management-shared/components/TableCells/cell/VisibilityAllButton';
import {
  hasDashboardTableAnyGrouping,
  hasDashboardQueryAnyRule,
  hasDashboardTableSearch,
  hasAnyExperimentInGrouping
} from '@experiment-management-shared/utils/EMView';
import { allowToHaveOnlyOneLowLevelGroupOpened } from '@experiment-management-shared';
import projectsActions from '@/actions/projectsActions';
import visualizationsActions from '@/actions/visualizationsActions';
import { dialogTypes } from '@/constants/alertTypes';

import alertsUtil from '@/util/alertsUtil';
import { NoResultsIcon } from '@shared/utils/svgIcons';

import { getActiveExperimentsHeaderTab } from '@/reducers/ui/projectsUiReducer';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import ExperimentsHeader from './ExperimentsHeader';
import useTrackTableEventsBI from '@/shared/hooks/useTrackTableEventsBI';

const DashboardTable = (
  {
    availableColumns,
    className,
    dashboardTable: table,
    dashboardQuery,
    hiddenExperimentKeys,
    pinnedExperimentKeys,
    experimentsGroupsMap,
    groupAggregations,
    experiments,
    isArchive,
    isFetchingExperiments,
    isLoadingAnyExperiments,
    isPreviousDataExperiments,
    onChange,
    onSave,
    onHoverExperimentNameCell,
    pageTotalGrouped,
    selectedExperiments,
    showButtonLabels,
    totalExperiments,
    ReactGridProps
  },
  refObject
) => {
  const dispatch = useDispatch();
  const activeExperimentsHeaderTab = useSelector(getActiveExperimentsHeaderTab);
  const { ref, exportRef } = refObject;

  const baseTrackEvent = useBaseTrackEvent();

  const selectedColumns = useMemo(() => {
    const columns = table.displayColumnOrders ?? table.columnOrders;

    return columns.map(transformColumnNameToColumn);
  }, [table.columnOrders, table.displayColumnOrders]);

  const rows = useMemo(() => {
    return experiments.map(experiment => ({
      ...experiment,
      // @todo: change name
      pinned: pinnedExperimentKeys.includes(experiment.experimentKey),
      // @todo: change name
      isVisibleOnDashboard: !hiddenExperimentKeys.includes(
        experiment.experimentKey
      )
    }));
  }, [experiments, hiddenExperimentKeys, pinnedExperimentKeys]);

  const height = useTableHeight({
    maxHeight: 320,
    rowHeight: 50,
    currentPage: table.pageNumber,
    rows
  });

  const {
    onSortingSendBI,
    onUpdateColumnsSendBI,
    onUpdateGroupsSendBI
  } = useTrackTableEventsBI();

  const getNewColumnWidths = useCallback(
    newColumnNames => {
      const columnNamesWithWidths = table.columnWidths.map(
        column => column.columnName
      );

      const columnNamesWithoutWidth = newColumnNames.filter(
        columnName => !columnNamesWithWidths.includes(columnName)
      );

      if (!columnNamesWithoutWidth.length) return table.columnWidths;

      const newColumnWidths = columnNamesWithoutWidth.map(columnName => ({
        columnName,
        width: DEFAULT_COLUMN_WIDTH
      }));

      return [...table.columnWidths, ...newColumnWidths];
    },
    [table.columnWidths]
  );

  const handleColumnsChange = useCallback(
    (selectedColumnNames = []) => {
      const columnNames = uniq([
        ...REQUIRED_COLUMN_NAMES,
        ...selectedColumnNames
      ]);

      onChange({
        table: {
          columnWidths: getNewColumnWidths(columnNames),
          columnOrders: columnNames
        }
      });
      onUpdateColumnsSendBI({
        columnNames: selectedColumnNames
      });
    },
    [getNewColumnWidths, onChange, onUpdateColumnsSendBI]
  );

  const handleTogglePinned = useCallback(
    experimentKey => {
      const isExperimentPinned = pinnedExperimentKeys.includes(experimentKey);
      const newPinnedExperimentKeys = isExperimentPinned
        ? pinnedExperimentKeys.filter(key => key !== experimentKey)
        : [...pinnedExperimentKeys, experimentKey];

      if (newPinnedExperimentKeys.length > 10) {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            `You have reached the max amount of pinned experiments (10)`
          )
        );

        return;
      }

      onChange({
        pinnedExperimentKeys: newPinnedExperimentKeys
      });
    },
    [dispatch, pinnedExperimentKeys, onChange]
  );

  const handleToggleVisibility = useCallback(
    experimentKey => {
      onChange(({ hiddenExperimentKeys: prevHiddenExperimentKeys }) => {
        const isExperimentHidden = prevHiddenExperimentKeys.includes(
          experimentKey
        );
        const newHiddenExperimentKeys = isExperimentHidden
          ? prevHiddenExperimentKeys.filter(key => key !== experimentKey)
          : [...prevHiddenExperimentKeys, experimentKey];

        return {
          hiddenExperimentKeys: newHiddenExperimentKeys
        };
      });
    },
    [onChange]
  );

  const handleExpandedGroupsChange = useCallback(
    expandedGroups => {
      let newExpandedGroups = allowToHaveOnlyOneLowLevelGroupOpened(
        expandedGroups,
        table?.columnGrouping
      );

      onChange({
        table: { expandedGroups: newExpandedGroups, pageNumber: 0 }
      });
    },
    [onChange, table.columnGrouping]
  );

  const handleGroupingChange = useCallback(
    newColumnGrouping => {
      const columnNames = uniq([...table.columnOrders, ...newColumnGrouping]);

      onChange({
        table: {
          columnGrouping: newColumnGrouping,
          columnOrders: columnNames,
          columnWidths: getNewColumnWidths(columnNames),
          expandedGroups: [],
          pageNumber: 0
        }
      });

      onUpdateGroupsSendBI({
        columnNames: newColumnGrouping
      });
    },
    [getNewColumnWidths, onChange, table.columnOrders, onUpdateGroupsSendBI]
  );

  const handlePageSizeChange = useCallback(
    newPageSize => {
      onChange({
        table: {
          pageNumber: 0,
          pageSize: newPageSize
        }
      });
    },
    [onChange]
  );

  const handleColumnWidthsChange = useCallback(
    columnWidths => {
      onChange({
        table: { columnWidths }
      });
    },
    [onChange]
  );

  const handlePageNumberChange = useCallback(
    pageNumber => {
      onChange({
        table: { pageNumber }
      });
    },
    [onChange]
  );

  const handleOrderChange = useCallback(
    columnOrders => {
      onChange({
        table: { columnOrders }
      });
    },
    [onChange]
  );

  const handleQueryChange = useCallback(
    newQuery => {
      onChange({ query: newQuery, table: { selection: [], pageNumber: 0 } });
    },
    [onChange]
  );

  const handleSelectionChange = useCallback(
    selection => {
      dispatch(projectsActions.setStoredSelectedExperiments(selection));

      onChange({
        table: { selection }
      });
    },
    [onChange, dispatch]
  );

  const handleSortingChange = useCallback(
    columnSorting => {
      onChange({
        table: { columnSorting }
      });
      onSortingSendBI({
        sortedColumnsCount: columnSorting.length
      });
    },
    [onChange, onSortingSendBI]
  );

  const handleMouseOut = () => {
    dispatch(
      visualizationsActions.setHoveredExperimentTrace({
        experimentKey: null,
        source: null
      })
    );
  };

  const handleSearchChange = useCallback(
    search => {
      if (search) {
        baseTrackEvent(dashboardEvents.PROJECT_SEARCH, {
          search_term: search
        });
      }

      onChange({
        table: { search, pageNumber: 0 }
      });
    },
    [baseTrackEvent, onChange]
  );

  const handleSearchClear = useCallback(() => {
    onChange({
      table: {
        search: '',
        searchMode: {
          type: SEARCH_MODE_TYPE.FOCUS
        }
      }
    });
  }, [onChange]);

  const handleFiltersClear = () => {
    onChange({
      query: {
        segmentId: '',
        rulesTree: generateEmptyRulesTree()
      },
      table: {
        selection: []
      }
    });
  };

  const handleFiltersAndSearchClear = () => {
    onChange({
      query: {
        segmentId: '',
        rulesTree: generateEmptyRulesTree()
      },
      table: {
        selection: [],
        search: '',
        searchMode: {
          type: SEARCH_MODE_TYPE.STANDARD
        }
      }
    });
  };

  const reactGridContextAdditionalData = useMemo(() => {
    return {
      handleAllVisibilityChange: keys =>
        onChange({
          hiddenExperimentKeys: keys
        }),
      hiddenExperimentKeys
    };
  }, [hiddenExperimentKeys, onChange]);

  const headerRowComponents = useMemo(() => {
    return {
      [GRID_COLUMNS.NAME]: {
        prefix: !isArchive ? <VisibilityAllButton /> : undefined,
        suffix:
          // Search box should be shown only for 'All' tab, to do that is checking if activeExperimentsHeaderTab is equal to 0
          activeExperimentsHeaderTab === 0 ? (
            <ExpandingSearchBar
              mode={table.searchMode}
              value={table.search}
              onChange={handleSearchChange}
              variant={
                isArchive ? SEARCH_BAR_VARIANT.DARK : SEARCH_BAR_VARIANT.BRIGHT
              }
            />
          ) : undefined
      }
    };
  }, [
    isArchive,
    activeExperimentsHeaderTab,
    table.searchMode,
    handleSearchChange
  ]);

  const hasAnyRule = hasDashboardQueryAnyRule(dashboardQuery);
  const hasAnySearch = hasDashboardTableSearch(table);
  const hasAnyGrouping = hasDashboardTableAnyGrouping(table);
  const hasExperimentsInGrouping = hasAnyExperimentInGrouping(
    experimentsGroupsMap
  );
  const hasNoExperimentsToShow =
    !rows.length &&
    !hasExperimentsInGrouping &&
    !isLoadingAnyExperiments &&
    (hasAnyRule || hasAnySearch);

  const renderNoDataCell = useCallback(
    props => {
      let message = 'No matching results for search and selected filters';
      let buttonText = 'Clear search & filters';
      let handler = handleFiltersAndSearchClear;

      if (hasAnyRule && !hasAnySearch) {
        message = 'There is no data for the selected filters';
        buttonText = 'Clear filters';
        handler = handleFiltersClear;
      } else if (!hasAnyRule && hasAnySearch) {
        message = "We couldn't find experiment names with matching input text";
        buttonText = 'Try different input text';
        handler = handleSearchClear;
      }

      return (
        <td colSpan={props.colSpan}>
          <EmptySectionMessage
            button={<TextButton onClick={handler}>{buttonText}</TextButton>}
            description={message}
            icon={<NoResultsIcon />}
            title="No results found"
          />
        </td>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAnyRule, hasAnySearch]
  );

  const renderBody = () => {
    return (
      <div
        className={cx('experiments-body', {
          'experiments-body-overflow':
            !!height || hasAnyGrouping || hasNoExperimentsToShow,
          'has-no-experiments-to-show': hasNoExperimentsToShow
        })}
        onMouseLeave={handleMouseOut}
      >
        <ThrottlingPopover rows={rows} />
        <ReactGrid
          availableColumns={availableColumns}
          allowColumnReordering
          allowPagination={!hasNoExperimentsToShow}
          allowSelection
          columns={selectedColumns}
          contextAdditionalData={reactGridContextAdditionalData}
          cellsMap={SPECIAL_CELL_COMPONENTS}
          data-test="dashboard-table--react-grid"
          decimalsPrecision={table.decimalsPrecision}
          experimentsGroupsMap={experimentsGroupsMap}
          groupAggregations={groupAggregations}
          headerRowComponents={headerRowComponents}
          experimentsForExport={
            selectedExperiments.length > 0 ? selectedExperiments : rows
          }
          exportHandler={onSave}
          isArchive={isArchive}
          isBackgroundFetchingExperiments={isFetchingExperiments}
          isPreviousDataExperiments={isPreviousDataExperiments}
          onColumnWidthsChange={handleColumnWidthsChange}
          onCurrentPageChange={handlePageNumberChange}
          onExpandedGroupsChange={handleExpandedGroupsChange}
          onHoverExperimentNameCell={onHoverExperimentNameCell}
          onOrderChange={handleOrderChange}
          onPageSizeChange={handlePageSizeChange}
          onSelectionChange={handleSelectionChange}
          onSortingChange={handleSortingChange}
          onTogglePinned={isArchive ? undefined : handleTogglePinned}
          onToggleVisibility={isArchive ? undefined : handleToggleVisibility}
          pageTotalGrouped={pageTotalGrouped}
          pinnedExperimentKeys={pinnedExperimentKeys}
          reactGrid={table}
          ref={exportRef}
          resizingEnabled
          rows={rows}
          shouldUseServerSidePagination
          totalExperiments={totalExperiments}
          renderNoDataCell={renderNoDataCell}
          {...ReactGridProps}
        />
      </div>
    );
  };

  return (
    <div className={cx('experiments-table', className)} ref={ref}>
      <ExperimentsHeader
        allowSorting={ReactGridProps.allowSorting}
        hiddenExperimentKeys={hiddenExperimentKeys}
        dashboardQuery={dashboardQuery}
        dashboardTable={table}
        isArchive={isArchive}
        activeExperimentsHeaderTab={activeExperimentsHeaderTab}
        onColumnsChange={handleColumnsChange}
        onGroupByChange={handleGroupingChange}
        onQueryChanges={handleQueryChange}
        onSortingChanges={handleSortingChange}
        showButtonLabels={showButtonLabels}
      />

      {renderBody()}
    </div>
  );
};

DashboardTable.defaultProps = {
  availableColumns: [],
  className: '',
  onHoverExperimentNameCell: noop,
  pageTotalGrouped: null,
  showButtonLabels: false,
  ReactGridProps: {},
  groupAggregations: null,
  isLoadingAnyExperiments: false
};

DashboardTable.propTypes = {
  availableColumns: PropTypes.array,
  className: PropTypes.string,
  dashboardTable: PropTypes.object.isRequired,
  dashboardQuery: PropTypes.object.isRequired,
  hiddenExperimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  pinnedExperimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  experimentsGroupsMap: PropTypes.object,
  groupAggregations: PropTypes.object,
  experiments: PropTypes.array.isRequired,
  isArchive: PropTypes.bool.isRequired,
  isFetchingExperiments: PropTypes.bool.isRequired,
  isLoadingAnyExperiments: PropTypes.bool,
  isPreviousDataExperiments: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onHoverExperimentNameCell: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  pageTotalGrouped: PropTypes.number,
  selectedExperiments: PropTypes.array.isRequired,
  showButtonLabels: PropTypes.bool,
  totalExperiments: PropTypes.number.isRequired,
  ReactGridProps: PropTypes.object
};

const DashboardTableWithRef = React.forwardRef(DashboardTable);

DashboardTableWithRef.defaultProps = DashboardTable.defaultProps;
DashboardTableWithRef.propTypes = DashboardTable.propTypes;

export default DashboardTableWithRef;
