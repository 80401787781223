// EXPERIMENTS - COLUMNS

// columns that are being computed on the go by the BE and don't exists in the database
// therefore won't be supported in the metadata-v2 endpoint
export const METADATA_VIRTUAL_COLUMNS = ['runActive'];

export const EXCLUDED_VIRTUAL_COLUMNS = [
  {
    id: 'runActive--computed_metadata',
    name: 'runActive',
    type: 'boolean',
    source: 'computed_metadata'
  }
];

export const GRID_COLUMNS = {
  PINNED: 'pinned',
  OWNER: 'Owner',
  STATUS: 'Status',
  NAME: 'Name',
  PINNED_ROW_ACTION_MENU_COLUMN_NAME: 'rowActionMenu',
  SINGLE_EXPERIMENT_BTN: 'singleExperimentBtn',
  EXPERIMENT_TAGS: 'experimentTags',
  EXPERIMENT_KEY: 'experimentKey',
  IS_VISIBLE_ON_DASHBOARD: 'isVisibleOnDashboard',
  RUN_ACTIVE: 'runActive',
  STAGE: 'Stage',
  TAGS: 'Tags',
  RUN: 'run',
  SYMLINK: 'symlink',
  METRIC_VALUE: 'metricValue',
  PARAM_VALUE: 'paramValue',
  MIN: 'min',
  MAX: 'max',
  ADD_TO_GRAPH: 'addToGraph',
  END_SERVER_TIMESTAMP: 'end_server_timestamp',
  FILE_NAME: 'file_name',
  DURATION: 'duration',
  HAS_CRASHED: 'hasCrashed',
  EXPERIMENT_DETAILS_ACTIONS: 'experimentDetailsActions'
};

export const DIRECTIONS = {
  DESC: 'desc'
};

export const REQUIRED_COLUMN_SORTING = [
  { columnName: GRID_COLUMNS.PINNED, direction: DIRECTIONS.DESC }
];

export const DEFAULT_COLUMN_SORTING = [
  { columnName: GRID_COLUMNS.RUN_ACTIVE, direction: DIRECTIONS.DESC }
];

export const COLUMNS_DISABLED_SORTING = [
  { columnName: GRID_COLUMNS.PINNED, sortingEnabled: false },
  { columnName: GRID_COLUMNS.SINGLE_EXPERIMENT_BTN, sortingEnabled: false },
  { columnName: GRID_COLUMNS.EXPERIMENT_TAGS, sortingEnabled: false },
  { columnName: GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD, sortingEnabled: false }
];

export const COLUMNS_DISABLED_DRAG = [
  GRID_COLUMNS.NAME,
  GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME
];

export const COLUMNS_DISABLED_DRAG_AND_RESIZE = [
  GRID_COLUMNS.PINNED,
  GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
  GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD,
  GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME
];

export const COLUMNS_DISABLED_FOR_EXPORT = [
  GRID_COLUMNS.PINNED,
  GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
  GRID_COLUMNS.RUN_ACTIVE,
  GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD
];

export const COLUMNS_DISABLED_FOR_SUMMARY = [
  GRID_COLUMNS.NAME,
  GRID_COLUMNS.PINNED,
  GRID_COLUMNS.TAGS,
  GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
  GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD,
  GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME,
  GRID_COLUMNS.RUN,
  GRID_COLUMNS.SYMLINK
];

export const TABLE_PAGE_SIZES = [10, 25, 50, 100];

export const DEFAULT_COLUMN_WIDTH = 150;

export const DEFAULT_DECIMAL_PRECISION = 3;
export const MAX_DECIMAL_PRECISION = 20;

export const MANUALLY_ADDED_COLUMNS = [
  {
    id: 'tag--metadata',
    name: GRID_COLUMNS.EXPERIMENT_TAGS,
    source: 'tags',
    type: 'string'
  },
  {
    name: GRID_COLUMNS.NAME,
    id: 'Name--log_other',
    type: 'string',
    source: 'log_other'
  }
];

export const METADATA_FETCH_ONLY_COLUMNS = [
  {
    source: GRID_COLUMNS.PINNED,
    keyName: GRID_COLUMNS.PINNED
  },
  {
    source: GRID_COLUMNS.SYMLINK,
    keyName: GRID_COLUMNS.SYMLINK
  },
  {
    source: GRID_COLUMNS.RUN,
    keyName: GRID_COLUMNS.RUN
  },
  {
    source: 'log_other',
    keyName: GRID_COLUMNS.NAME
  },
  {
    source: 'log_other',
    keyName: GRID_COLUMNS.HAS_CRASHED
  }
];

export const CENTERED_COLUMNS = [
  GRID_COLUMNS.METRIC_VALUE,
  GRID_COLUMNS.PARAM_VALUE,
  GRID_COLUMNS.MIN,
  GRID_COLUMNS.MAX,
  GRID_COLUMNS.ADD_TO_GRAPH
];

export const REQUIRED_COLUMN_NAMES = [
  GRID_COLUMNS.PINNED,
  GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
  GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD,
  GRID_COLUMNS.NAME
];

export const REQUIRED_COLUMN_WIDTHS = [
  {
    columnName: GRID_COLUMNS.PINNED,
    width: 0
  },
  {
    columnName: GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
    width: 38
  },
  {
    columnName: GRID_COLUMNS.RUN_ACTIVE,
    width: 45
  },
  {
    columnName: GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD,
    width: 46
  },

  {
    columnName: GRID_COLUMNS.NAME,
    width: 280
  }
];

export const GET_VALUE_FOR_SORTING = {
  isVisibleOnDashboard: row => !row.isVisibleOnDashboard
};

export const DEFAULT_COLUMN_NAMES = [
  GRID_COLUMNS.NAME,
  GRID_COLUMNS.EXPERIMENT_TAGS,
  GRID_COLUMNS.END_SERVER_TIMESTAMP,
  GRID_COLUMNS.FILE_NAME,
  GRID_COLUMNS.DURATION
];

export const DEFAULT_COLUMN_WIDTHS = DEFAULT_COLUMN_NAMES.map(columnName => {
  return {
    columnName,
    width: DEFAULT_COLUMN_WIDTH
  };
});
