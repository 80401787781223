import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import TableEmptyState from '@shared/components/TableEmptyState';

const TableNoSearchResults = ({ buttonText, clickHandler, ...props }) => (
  <TableEmptyState
    materialProps={props}
    label="No search results"
    pictureHeight="50px"
    pictureWidth="55px"
    picturePath="/images/no-search-results.svg"
    buttonText={buttonText}
    clickHandler={clickHandler}
  />
);

TableNoSearchResults.propTypes = {
  buttonText: PropTypes.string,
  clickHandler: PropTypes.func
};

TableNoSearchResults.defaultProps = {
  buttonText: '',
  clickHandler: noop
};

export default TableNoSearchResults;
