import { EmptyExperimentTab } from '@shared';
import React from 'react';
import classes from './ArtifactsTab.module.scss';

const ArtifactsTabEmpty = () => (
  <EmptyExperimentTab className={classes.artifactsEmptyMessage}>
    <EmptyExperimentTab.Icon iconName="artifacts" />
    <EmptyExperimentTab.Title>
      This experiment did not report any artifacts
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      Use <b>log_artifact()</b> on your Experiment object to save files during
      experiments.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentlog_artifact" />
  </EmptyExperimentTab>
);

export default ArtifactsTabEmpty;
