import React from 'react';
import PropTypes from 'prop-types';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';

import HTMLTab from '@experiment-details/components/HTMLTab';
import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import useSelectedExperiments from '@experiment-details/hooks/useSelectedExperiments';

const minHeight = { minHeight: '400px' };

const HTMLCompareTab = ({ experiments }) => {
  const { experiment1, experiment2 } = useSelectedExperiments(experiments);
  return (
    <div className="full-height">
      <CompareContentSelector
        experiments={experiments}
        experiment1={experiment1}
        experiment2={experiment2}
      />
      <div className="full-height overflow-hidden">
        <div className="col-md-6 full-height">
          <HTMLTab
            style={minHeight}
            experiments={[experiment1]}
            isComparePage
          />
        </div>

        <div className="col-md-6 full-height">
          <HTMLTab
            style={minHeight}
            experiments={[experiment2]}
            isComparePage
          />
        </div>
      </div>
    </div>
  );
};

HTMLCompareTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default HTMLCompareTab;
