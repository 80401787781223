/* eslint-disable react/prop-types */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import isEqual from 'fast-deep-equal';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import { Plot } from './Plot';

// A Plot update could fire several update events
const PLOT_UPDATE_DELAY = 500;

const getCustomRangeValue = (value, type) => {
  if (type === 'log') {
    return Math.log10(value);
  }

  return value;
};

const PlotComponent = (props, ref) => {
  const [initialRange, setInitialRange] = useState(null);

  const {
    data,
    onUpdate,
    onFinishRender,
    customRange,
    layout,
    disableZoom = false,
    ...restProps
  } = props;

  const isCustomRange = [
    ...Object.values(customRange.x),
    ...Object.values(customRange.y)
  ].some(val => val !== '');

  const handleFinishRender = useCallback(
    debounce(onFinishRender, PLOT_UPDATE_DELAY),
    [onFinishRender]
  );

  const layoutWithCustomRange = useMemo(() => {
    if (isNull(initialRange) || !isCustomRange) return layout;

    if (isCustomRange) {
      const minY =
        customRange.y.min === ''
          ? initialRange.y.min
          : getCustomRangeValue(customRange.y.min, layout.yaxis.type);
      const maxY =
        customRange.y.max === ''
          ? initialRange.y.max
          : getCustomRangeValue(customRange.y.max, layout.yaxis.type);

      const minX =
        customRange.x.min === ''
          ? initialRange.x.min
          : getCustomRangeValue(customRange.x.min, layout.xaxis.type);
      const maxX =
        customRange.x.max === ''
          ? initialRange.x.max
          : getCustomRangeValue(customRange.x.max, layout.xaxis.type);

      return {
        ...layout,
        xaxis: { ...layout.xaxis, autorange: false, range: [minX, maxX] },
        yaxis: { ...layout.yaxis, autorange: false, range: [minY, maxY] }
      };
    }
    return layout;
  }, [layout, initialRange, customRange, isCustomRange]);

  if (disableZoom) {
    layoutWithCustomRange.xaxis = {
      ...layoutWithCustomRange.xaxis,
      fixedrange: true
    };
    layoutWithCustomRange.yaxis = {
      ...layoutWithCustomRange.yaxis,
      fixedrange: true
    };
  }

  // Only recalculate the original range in case any axis type (linear/log) changes
  useEffect(() => {
    setInitialRange(null);
  }, [layout?.xaxis?.type, layout?.yaxis?.type]);

  const handleUpdate = useCallback(
    (...args) => {
      handleFinishRender();

      if (isCustomRange && initialRange === null) {
        const currentLayout = get(args, ['0', 'layout'], {});
        const [minX, maxX] = get(currentLayout, ['xaxis', 'range'], []);
        const [minY, maxY] = get(currentLayout, ['yaxis', 'range'], []);

        setInitialRange({
          x: { min: minX, max: maxX },
          y: { min: minY, max: maxY }
        });
      }

      onUpdate(...args);
    },
    [onUpdate, handleFinishRender, initialRange, isCustomRange]
  );

  return (
    <Plot
      {...restProps}
      data={data}
      layout={layoutWithCustomRange}
      ref={ref}
      onUpdate={handleUpdate}
    />
  );
};

const OptimizedPlot = React.memo(
  React.forwardRef(PlotComponent),
  // We shouldn't abuse of doing a deep equal comparison with React.memo,
  // in this case is needed because re-render the <Plot> unnecessary has a several
  // performance impact (more than doing the props deep equal comparison)
  isEqual
);

OptimizedPlot.displayName = 'OptimizedPlot';

OptimizedPlot.defaultProps = {
  customRange: {
    x: {
      min: '',
      max: ''
    },
    y: {
      min: '',
      max: ''
    }
  },
  onFinishRender: noop,
  onUpdate: noop
};

OptimizedPlot.propTypes = {
  customRange: PropTypes.object,
  data: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  onFinishRender: PropTypes.func,
  onUpdate: PropTypes.func
};

export default OptimizedPlot;
