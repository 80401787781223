export const MODEL_VERSION_PENDING_REQUESTS_ACTIONS = {
  APPROVE: 'approve',
  CANCEL: 'cancel',
  REJECT: 'reject'
};

export const MODEL_VERSION_PENDING_REQUESTS_COLUMNS = {
  request: 'request',
  version: 'version',
  createdBy: 'createdBy',
  createdTs: 'createdTs',
  comment: 'comment',
  actions: 'actions'
};

export const MODEL_REGISTRY_PENDING_REQUEST_LEFT_COLUMNS = [
  MODEL_VERSION_PENDING_REQUESTS_COLUMNS.request
];

export const MODEL_REGISTRY_PENDING_REQUEST_RIGHT_COLUMNS = [
  MODEL_VERSION_PENDING_REQUESTS_COLUMNS.actions
];

export const MODEL_VERSION_PENDING_REQUESTS_DEFAULT_COLUMNS_ORDER = [
  ...MODEL_REGISTRY_PENDING_REQUEST_LEFT_COLUMNS,
  MODEL_VERSION_PENDING_REQUESTS_COLUMNS.version,
  MODEL_VERSION_PENDING_REQUESTS_COLUMNS.createdBy,
  MODEL_VERSION_PENDING_REQUESTS_COLUMNS.createdTs,
  MODEL_VERSION_PENDING_REQUESTS_COLUMNS.comment,
  ...MODEL_REGISTRY_PENDING_REQUEST_RIGHT_COLUMNS
];

export const OPEN_MODAL_ORIGIN = {
  model_version: 'MODEL_VERSION',
  table: 'TABLE',
  side_menu: 'SIDE_MENU'
};
