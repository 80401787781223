import React from 'react';

import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import useCompareExperimentsKeys from '@shared/hooks/useCompareExperimentsKeys';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';
import AssetsStorage from '@experiment-management-shared/components/AssetsStorage';
import useExperimentModels from '@shared/api/useExperimentsModels';
import useAssetsForExperiments from '@experiment-management-shared/api/useAssetsForExperiments';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { useDispatch } from 'react-redux';
import { SmallLoader } from '@shared';
import AssetsTabEmpty from './AssetsTabEmpty';

const AssetsContainer = ({ experiment }) => {
  const dispatch = useDispatch();
  const { experimentKey } = experiment;

  const { data: experimentModels = [] } = useExperimentModels(experimentKey, {
    enabled: !!experimentKey,
    fetchOnMount: 'always'
  });

  const { data: assets, isLoading } = useAssetsForExperiments(
    {
      experiments: [experiment]
    },
    {
      refetchInterval: false,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_FETCH_ASSETS_FOR_EXPERIMENT,
            'There was an error fetching assets for this experiment.'
          )
        );
      }
    }
  );

  const params = useParams();
  const { experimentKeys } = useCompareExperimentsKeys();
  const experimentKeyParam = get(params, 'experimentKey', experimentKeys[0]);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <AssetsStorage
      assets={assets}
      isLoading={isLoading}
      experimentModels={experimentModels}
      experiment={experiment}
      preventQueryParamsUpdate={experimentKey !== experimentKeyParam}
      useQueryParams={useQueryParamsForExperiments}
      emptyRowsComponent={<AssetsTabEmpty />}
    />
  );
};

AssetsContainer.propTypes = {
  experiment: IExperimentDetails.isRequired
};

export default AssetsContainer;
