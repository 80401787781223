import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';

import './Menu.scss';
import { DSArrowRightIcon } from '@ds-icons';

const DEFAULT = 'default';
const SECONDARY = 'secondary';
const MENU_1 = 'menu1';

export const DS_MENU_TYPES = {
  SECONDARY,
  DEFAULT,
  MENU_1
};

const Menu = ({ items, onClickItem, activeItem, type, containerClassName }) => {
  return (
    <div className={cx('ds-menu', type, containerClassName)}>
      <ul>
        {items.map(item => (
          <li
            className={cx('menu-item', {
              active: activeItem === item.value,
              disabled: item.disabled,
              'submenu-item': !!item.subMenu
            })}
            key={item?.key || item.value}
            onClick={e => {
              if (!item.disabled) {
                e.persist();
                onClickItem(item, e);
              }
            }}
          >
            {item.prefixIcon && (
              <div className="menu-item-prefix-icon">
                <item.prefixIcon />
              </div>
            )}
            <div className="menu-item-label">{item.label}</div>
            {item.subMenu && (
              <div className="submenu-open-icon">
                <DSArrowRightIcon />{' '}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      prefixIcon: PropTypes.function,
      value: PropTypes.string.isRequired
    })
  ),
  onClickItem: PropTypes.func,
  activeItem: PropTypes.string,
  type: PropTypes.oneOf(Object.values(DS_MENU_TYPES)),
  containerClassName: PropTypes.string
};

Menu.defaultProps = {
  items: [],
  onClickItem: noop,
  activeItem: null,
  type: DS_MENU_TYPES.DEFAULT,
  containerClassName: ''
};

export default Menu;
