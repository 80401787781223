import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes } from '../../../constants/alertTypes';

const getModelRegistryNotes = async registryModelItemId => {
  const { data = {} } =
    (await modelRegistryApi.fetchModelVersionNotes(registryModelItemId)) || {};

  return data?.note;
};

const useModelRegistryVersionNotes = registryModelItemId => {
  const dispatch = useDispatch();

  return useQuery(
    ['modelRegistryVersionNotes', { registryModelItemId }],
    () => getModelRegistryNotes(registryModelItemId),
    {
      enabled: !!registryModelItemId,
      refetchOnMount: true,
      onError: () =>
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your model version notes.'
          )
        )
    }
  );
};

export default useModelRegistryVersionNotes;
