// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoteTab-module__tabContainer--FXFP1{padding:var(--experiment-details-layout-padding)}.NoteTab-module__emptyMessage--xdPyB{padding-top:271px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `NoteTab-module__tabContainer--FXFP1`,
	"emptyMessage": `NoteTab-module__emptyMessage--xdPyB`
};
module.exports = ___CSS_LOADER_EXPORT___;
