import usePathname from '@shared/hooks/usePathname';
import { matchPath, useParams } from 'react-router';

export default function useDashboardPageSectionName() {
  const { section } = useParams();
  const pathname = usePathname();

  if (section) {
    return section;
  }

  return matchPath(pathname, {
    exact: true,
    path: '/:workspace/:projectName/:section'
  })?.params.section;
}
