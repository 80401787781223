import {
  COMPOUND_KEY_SEPARATOR,
  GROUP_VALUE_KEY
} from '@experiment-management-shared/constants/aggregation';
import { getColumnType, KIND_TYPE } from '@API/helpers/v2_helpers';
import {
  AGGREGATION_TYPES_OBJECT,
  AGGREGATIONS
} from '@experiment-management-shared/constants/chartConstants';
import { filterNaNValues } from '@shared/utils/dashboardHelpers';

const backendAggregationValueFields = {
  [KIND_TYPE.LAST]: 'aggregatedNumLast',
  [KIND_TYPE.MIN]: 'aggregatedNumMin',
  [KIND_TYPE.MAX]: 'aggregatedNumMax'
};

export const getAggregationFieldByColumnName = columnName => {
  const columnType = getColumnType(columnName);

  return backendAggregationValueFields[columnType] || KIND_TYPE.LAST;
};

export const getAggregationByCompoundKey = ({ compoundKey, aggregations }) => {
  if (!aggregations) {
    return null;
  }

  const groupValues = compoundKey.split(COMPOUND_KEY_SEPARATOR);
  const aggregationDepth = groupValues?.length;
  let currentLevelAggregations = { ...aggregations };

  for (let i = 0; i < aggregationDepth; i++) {
    const orderedGroupsValue = groupValues[i];
    const keys = Object.keys(currentLevelAggregations || {});
    const neededKey = keys.find(groupKey =>
      groupKey.endsWith(`${GROUP_VALUE_KEY}${orderedGroupsValue}`)
    );

    if (neededKey) {
      currentLevelAggregations = currentLevelAggregations[neededKey];
    } else {
      currentLevelAggregations = null;
    }
  }

  return currentLevelAggregations?.aggregations;
};

export const getListAggregatedValue = (
  values,
  aggregation = AGGREGATION_TYPES_OBJECT.LAST
) => {
  try {
    if (!values?.length) return null;

    const filteredValues = filterNaNValues(values);

    const aggregationFunc = AGGREGATIONS[aggregation];
    if (!filteredValues.length || !aggregationFunc) return null;

    return aggregationFunc(filteredValues);
  } catch {
    return null;
  }
};
