import { useQuery } from 'react-query';

import { NEW_VIEW } from '@/constants/dashboardConstants';
import useProject from '@API/project/useProject';
import { ProjectTemplate } from '@experiment-management-shared/types';
import { projectViewsStore } from '@projects/utils';
import api, { QueryConfig } from '@shared/api';

const VIEW_VERSION = 3;

const getProjectView = async ({ projectId }: { projectId: string }) => {
  const row = await projectViewsStore.get([projectId, NEW_VIEW]);
  const localView = row?.view;
  const { data: serverView } = await api.get<ProjectTemplate>(
    'dashboard-templates/project/get',
    {
      params: {
        projectId,
        version: VIEW_VERSION
      }
    }
  );

  // Server view unsaved view has priority
  if (serverView.unsavedView) {
    return serverView;
  }

  // If there is no unsaved server view, try local view
  // if empty, the default server view
  return localView ?? serverView;
};

export default function useLLMProjectView(
  params: null,
  config: QueryConfig<ProjectTemplate>
) {
  const { data: project } = useProject();
  const projectId = project?.projectId;
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['llmProjectView', { projectId }],
    () => getProjectView({ projectId }),
    {
      ...config,
      enabled: configEnabled && !!projectId
    }
  );
}
