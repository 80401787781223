import React from 'react';

import './ChartTooltip.scss';

type IndicatorProps = {
  position: { left: number; top: number };
  color: string;
};

export const Indicator = ({ position, color }: IndicatorProps) => {
  return (
    <span
      className="tooltip-color-indicator"
      style={{ ...position, borderColor: color }}
      id="tooltip-indicator"
    />
  );
};
