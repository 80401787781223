// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MetricsTable-module__metricsTable--K6qTc{position:relative;z-index:5;padding-left:var(--experiment-details-layout-padding);padding-right:var(--experiment-details-layout-padding);padding-bottom:var(--experiment-details-layout-padding);width:100%;height:100%}.MetricsTable-module__metricsTable--K6qTc .ds-react-grid{border:1px solid var(--gray);border-radius:5px;overflow:hidden;width:100%;max-width:100%}.MetricsTable-module__metricsTable--K6qTc .ds-react-grid.virtualized{height:var(--table-maxheight)}.MetricsTable-module__metricsTable--K6qTc .ds-react-grid table colgroup col:last-child{display:none}.MetricsTable-module__metricsTable--K6qTc .ds-react-grid .dx-table-header-cell:has(+.actions){border-right-color:rgba(0,0,0,0)}.MetricsTable-module__metricsTable--K6qTc .ds-react-grid tr:hover:not(.unequalValueRow),.MetricsTable-module__metricsTable--K6qTc .ds-react-grid tr:hover:not(.unequalValueRow) td.TableFixedCell-fixedCell{background:#f7f8fa}.MetricsTable-module__metricsTable--K6qTc .MuiTable-root{width:100%;max-width:100%}.MetricsTable-module__metricsTable--K6qTc .empty-tab-message{padding-top:80px}.MetricsTable-module__metricsTable--K6qTc th.dx-table-header-cell.dx-table-band-cell.banded_header__Max{border-right:none}.MetricsTable-module__metricTableDeleteModalContent--y5uJr{text-align:center}.MetricsTable-module__metricTableDeleteModal--ADvVa .MuiDialog-paperScrollPaper{max-width:90%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metricsTable": `MetricsTable-module__metricsTable--K6qTc`,
	"metricTableDeleteModalContent": `MetricsTable-module__metricTableDeleteModalContent--y5uJr`,
	"metricTableDeleteModal": `MetricsTable-module__metricTableDeleteModal--ADvVa`
};
module.exports = ___CSS_LOADER_EXPORT___;
