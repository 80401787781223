import { useEffect, useState } from 'react';
import {
  COMPARISON_COLUMNS_NAME,
  COMPARISON_VALUE_CELL_KEYS
} from '../comparisonTableConfig';
import { ComparisonTableRow } from '../types';

const defaultColumnWidths = [
  COMPARISON_VALUE_CELL_KEYS.map(columnName => ({
    columnName,
    width: 250
  }))
].flat();

type UseComparisonTableColumnWidthsOpts = {
  rowData: ComparisonTableRow[];
};
const useComparisonTableColumnWidths = ({
  rowData
}: UseComparisonTableColumnWidthsOpts) => {
  const [columnWidths, onColumnWidthsChange] = useState(defaultColumnWidths);

  useEffect(() => {
    let maxWidth = 200;

    rowData.forEach(row => {
      const contentWidth =
        row[COMPARISON_COLUMNS_NAME.KEY as 'key'].length * 5 + 105;
      maxWidth = Math.max(maxWidth, contentWidth);
    });

    onColumnWidthsChange(prev => [
      ...prev,
      {
        columnName: COMPARISON_COLUMNS_NAME.KEY,
        width: maxWidth
      }
    ]);
  }, []);

  return {
    columnWidths,
    onColumnWidthsChange
  };
};

export default useComparisonTableColumnWidths;
