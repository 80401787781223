import React from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { WorkspaceCard } from '../../WorkspaceCard';
import { useWorkspaceAllMembers } from '@account-settings/hooks';

const AdminSettingsWorkspaceCard = ({
  createdAt,
  createdBy,
  isAdmin,
  isDefault,
  onClickExtraMenu,
  onClickInvite,
  projectsCount,
  workspaceId,
  workspaceName,
  workspaceStorageUsage,
  disabledInviteButton
}) => {
  const { members, memberCount } = useWorkspaceAllMembers(workspaceId);

  return (
    <WorkspaceCard
      createdAt={createdAt}
      createdBy={createdBy}
      isAdmin={isAdmin}
      isDefault={isDefault}
      members={members}
      membersCount={memberCount}
      onClickExtraMenu={onClickExtraMenu}
      onClickInvite={onClickInvite}
      projectsCount={projectsCount}
      workspaceName={workspaceName}
      workspaceId={workspaceId}
      workspaceStorageUsage={workspaceStorageUsage}
      disabledInviteButton={disabledInviteButton}
    />
  );
};

AdminSettingsWorkspaceCard.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  createdBy: PropTypes.string,
  isAdmin: PropTypes.bool,
  isDefault: PropTypes.bool,
  onClickExtraMenu: PropTypes.func,
  onClickInvite: PropTypes.func,
  projectsCount: PropTypes.number,
  workspaceId: PropTypes.string,
  workspaceName: PropTypes.string,
  workspaceStorageUsage: PropTypes.number.isRequired,
  disabledInviteButton: PropTypes.bool
};

AdminSettingsWorkspaceCard.defaultProps = {
  createdAt: null,
  createdBy: null,
  isAdmin: false,
  isDefault: false,
  onClickExtraMenu: noop,
  onClickInvite: noop,
  projectsCount: null,
  workspaceId: 0,
  workspaceName: null,
  disabledInviteButton: false
};

export default AdminSettingsWorkspaceCard;
