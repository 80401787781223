import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { IntervalType, RouteParams, PredicateQuery } from '@mpm-druid/types';

import {
  getMPMPanelURL,
  getMPMChartType,
  transformSegmentsToStackedPlotData,
  transformFeaturesToBarPlot,
  transformToChartLines,
  transformToLinePlotFromBarData,
  transformStackedDataToChartLines,
  transformStackedDataToAggregatedLines
} from '@mpm-druid/utils';
import api from '@API/api';
import { CHART_TYPES, PANEL_SECTIONS, SOURCE_TYPE } from '@mpm-druid/constants';

const NULL_DATA = {
  panelData: [],
  chartType: null
};

type GetPanelDataProps = {
  from: string;
  to: string;
  modelId?: string;
  version: string | null;
  intervalType: IntervalType;
  predicates: PredicateQuery[];
  section?: PANEL_SECTIONS;
  cometSql?: string;
  featureName?: string;
  featureSource?: SOURCE_TYPE;
  inputFeatureKeys?: (string | null)[] | null | undefined;
  algorithmType?: string;
  colors?: string[];
};

const POST_REQUEST_SECTIONS: PANEL_SECTIONS[] = [
  PANEL_SECTIONS.CUSTOM_METRICS,
  PANEL_SECTIONS.DATA_DRIFT,
  PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE,
  PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED,
  PANEL_SECTIONS.NUMBER_OF_PREDICTIONS,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES,
  PANEL_SECTIONS.FEATURE_DRIFT,
  PANEL_SECTIONS.FEATURE_MISSING_VALUES
];

const getPanelData = async ({
  from,
  to,
  modelId,
  version,
  intervalType,
  predicates,
  section,
  cometSql,
  featureName,
  featureSource,
  inputFeatureKeys,
  algorithmType,
  colors
}: GetPanelDataProps) => {
  if (!modelId || !section) {
    return NULL_DATA;
  }

  const requestURL: string = getMPMPanelURL(section);
  let serverResult;
  if (POST_REQUEST_SECTIONS.includes(section)) {
    serverResult = await api.post(requestURL, {
      from,
      to,
      intervalType,
      version,
      modelId,
      cometSql,
      name: featureName,
      source: featureSource,
      inputFeatureKeys,
      algorithmType,
      predicates
    });
  } else {
    serverResult = await api.get(requestURL, {
      modelId,
      from,
      to,
      intervalType,
      version,
      algorithmType
    });
  }

  let result = serverResult?.data;

  const chartType = getMPMChartType(section, serverResult.data);
  if (!serverResult?.data || serverResult.data?.length === 0) {
    return {
      ...NULL_DATA
    };
  }

  if (chartType === CHART_TYPES.LINE) {
    result = transformToChartLines({
      responseData: serverResult,
      predicates
    });
  }

  if (chartType === CHART_TYPES.BAR) {
    result = transformFeaturesToBarPlot(
      serverResult.data,
      colors,
      intervalType
    );
  }

  if (chartType === CHART_TYPES.LINE_CATEGORICAL_BAR_DATA) {
    result = transformToLinePlotFromBarData(
      serverResult.data,
      colors,
      predicates
    );
  }

  if (chartType === CHART_TYPES.STACKED) {
    result = transformSegmentsToStackedPlotData(
      serverResult.data,
      colors,
      intervalType
    );
  }

  if (chartType === CHART_TYPES.STACKED_TO_LINES) {
    result = transformStackedDataToChartLines({
      responseData: serverResult,
      predicates
    });
  }

  if (chartType === CHART_TYPES.STACKED_TO_AGGREGATED_LINES) {
    result = transformStackedDataToAggregatedLines({
      responseData: serverResult,
      predicates
    });
  }

  return {
    panelData: result,
    chartType
  };
};

export function useMPMPanelData(
  {
    from,
    to,
    version,
    intervalType,
    predicates,
    section,
    featureName,
    featureSource,
    inputFeatureKeys,
    cometSql,
    algorithmType,
    colors
  }: GetPanelDataProps,
  config = {}
) {
  const { modelId } = useParams<RouteParams>();

  return useQuery(
    [
      'mpm-panel',
      {
        modelId,
        from,
        to,
        version,
        intervalType,
        predicates,
        section,
        featureName,
        featureSource,
        inputFeatureKeys,
        cometSql,
        algorithmType,
        colors
      }
    ],
    () =>
      getPanelData({
        modelId,
        from,
        to,
        version,
        intervalType,
        predicates,
        section,
        featureName,
        featureSource,
        inputFeatureKeys,
        cometSql,
        algorithmType,
        colors
      }),
    config
  );
}
