import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DSSaveViewIcon } from '@ds-icons';
import { TextButton } from '@ds';
import { BasicModal } from '@DesignSystem/modals';
import { Checkbox, Input, RadioButton } from '@DesignSystem/controllers';

import styles from './SaveViewButton.module.scss';

const DEFAULT_VALUES = {
  isNewView: true,
  asDefault: false,
  viewName: ''
};

const SaveViewButton = ({ disabled, onClick, hideOverwriteOption }) => {
  const [isOpenSaveViewModal, setIsOpenSaveViewModal] = useState(false);

  const [isNewView, setIsNewView] = useState(DEFAULT_VALUES.isNewView);
  const [asDefault, setAsDefault] = useState(DEFAULT_VALUES.asDefault);
  const [viewName, setViewName] = useState(DEFAULT_VALUES.viewName);

  useEffect(() => {
    if (isOpenSaveViewModal) {
      setIsNewView(DEFAULT_VALUES.isNewView);
      setAsDefault(DEFAULT_VALUES.asDefault);
      setViewName(DEFAULT_VALUES.viewName);
    }
  }, [isOpenSaveViewModal]);

  const openModal = () => setIsOpenSaveViewModal(true);
  const closeModal = () => setIsOpenSaveViewModal(false);

  const handleSaveClick = useCallback(() => {
    onClick({
      asDefault,
      viewName,
      isNewView
    });

    closeModal();
  }, [asDefault, isNewView, viewName, onClick]);

  const renderSaveViewContent = () => {
    return (
      <div className={styles.saveViewModalContainer}>
        <div className={styles.saveViewField}>
          <div
            className={styles.saveViewFieldLabelContainer}
            onClick={() => setIsNewView(true)}
          >
            <RadioButton checked={isNewView} />
            <span className={styles.saveViewFieldLabel}>
              Save as a new view
            </span>
          </div>

          {isNewView && (
            <div className={styles.saveNewViewInputs}>
              <Input
                placeholder="Name of new view"
                onChange={setViewName}
                value={viewName}
              />
              <div
                className={styles.setAsDefaultContainer}
                onClick={() => setAsDefault(val => !val)}
              >
                <Checkbox checked={asDefault} />
                <span className={styles.setAsDefaultLabel}>Set as default</span>
              </div>
            </div>
          )}
        </div>

        {!hideOverwriteOption && (
          <div className={styles.saveViewField}>
            <div
              className={styles.saveViewFieldLabelContainer}
              onClick={() => setIsNewView(false)}
            >
              <RadioButton checked={!isNewView} />
              <span className={styles.saveViewFieldLabel}>
                Overwrite existing view
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const isSavingDisabled = Boolean(isNewView && !viewName);

  return (
    <>
      <BasicModal
        content={renderSaveViewContent()}
        open={isOpenSaveViewModal}
        onClose={closeModal}
        title="Save panel view for this experiment"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={handleSaveClick}
        onSecondaryButtonClick={closeModal}
        isPrimaryButtonDisabled={isSavingDisabled}
      />
      <TextButton
        className={styles.saveViewButton}
        type="secondary"
        size="medium"
        onClick={openModal}
        disabled={disabled}
        PrefixIcon={<DSSaveViewIcon />}
      >
        Save View
      </TextButton>
    </>
  );
};

SaveViewButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  hideOverwriteOption: PropTypes.bool
};

SaveViewButton.defaultProps = {
  disabled: false,
  hideOverwriteOption: false
};

export default SaveViewButton;
