import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ImageLoader = ({ fallbackSrc, loader, loadingClassName, src }) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCompletedLoading, setHasCompletedLoading] = useState(false);

  useEffect(() => {
    if (hasError) {
      setIsLoading(true);
      setCurrentSrc(src);
    }
  }, [fallbackSrc]);

  useEffect(() => {
    setIsLoading(true);
    setHasCompletedLoading(false);
    setHasError(false);
    setCurrentSrc(src);
  }, [src]);

  useEffect(() => {
    let hasChangedImage = false;

    setIsLoading(true);

    const image = new window.Image();

    image.addEventListener('load', () => {
      if (hasChangedImage) return;
      setIsLoading(false);
    });
    image.addEventListener('error', () => {
      if (hasChangedImage) return;
      setHasError(true);

      if (currentSrc !== fallbackSrc) {
        setCurrentSrc(fallbackSrc);
      } else {
        setIsLoading(false);
      }
    });

    image.src = currentSrc;

    return () => {
      hasChangedImage = true;
    };
  }, [currentSrc]);

  const handleImageLoad = () => {
    setHasCompletedLoading(true);
  };

  if (isLoading) {
    return <div className={loadingClassName}>{loader}</div>;
  }

  const className = hasCompletedLoading ? '' : loadingClassName;
  const imageSrc = hasError ? fallbackSrc : src;

  return <img className={className} onLoad={handleImageLoad} src={imageSrc} />;
};

ImageLoader.defaultProps = {
  fallbackSrc: '',
  loader: null,
  loadingClassName: ''
};

ImageLoader.propTypes = {
  fallbackSrc: PropTypes.string,
  loader: PropTypes.node,
  loadingClassName: PropTypes.string,
  src: PropTypes.string.isRequired
};

export default ImageLoader;
