import last from 'lodash/last';

import {
  ExperimentGroupsMap,
  GroupsPreQueryData,
  TableExperimentGroups
} from '@experiment-management-shared/types';
import { COMPOUND_KEY_SEPARATOR } from '@experiment-management-shared/constants/aggregation';

export const getGroupPossibleValuesFromGroupsMap = (
  groupsMap: ExperimentGroupsMap,
  path: string[]
): string[] => {
  if (!groupsMap) return [];

  if (path.length === 0) {
    return groupsMap.order.map(v => String(v)) || [];
  } else {
    return getGroupPossibleValuesFromGroupsMap(
      groupsMap.children?.[path[0]],
      path.slice(1)
    );
  }
};

export const groupsMapToPossibleExpandedGroups = (
  groupsMap: ExperimentGroupsMap,
  path = '',
  retVal: string[] = []
): string[] => {
  if (!groupsMap) return [];

  groupsMap.order.forEach(key => {
    const stringKey = String(key);
    const pathValue =
      path === '' ? stringKey : `${path}${COMPOUND_KEY_SEPARATOR}${stringKey}`;
    retVal.push(pathValue);
    groupsMapToPossibleExpandedGroups(
      groupsMap.children[String(key)],
      pathValue,
      retVal
    );
  });

  return retVal;
};

export const groupsMapToExperimentsGroups = (
  groupsMap: ExperimentGroupsMap,
  path: GroupsPreQueryData[] = [],
  rows: unknown[] = []
): TableExperimentGroups[] | unknown[] => {
  if (!groupsMap) return rows;
  const deepPath = path.slice();
  let currentPath: GroupsPreQueryData | undefined;

  if (deepPath.length) {
    currentPath = deepPath.shift();
  }

  return groupsMap.order.map(key => {
    return {
      key,
      childRows: groupsMapToExperimentsGroups(
        groupsMap.children[String(key)],
        key === currentPath?.value ? deepPath : [],
        key === currentPath?.value ? rows : []
      )
    };
  });
};

export const allowToHaveOnlyOneLowLevelGroupOpened = (
  expandedGroups: string[],
  columnGrouping?: string[]
) => {
  let retVal = expandedGroups;
  const lowerGroupIndex = columnGrouping?.length ?? 0;
  const lastExpandedGroup = last(expandedGroups);
  const lastExpandedGroupValues =
    lastExpandedGroup?.split(COMPOUND_KEY_SEPARATOR) || [];

  if (lastExpandedGroupValues.length === lowerGroupIndex) {
    retVal = expandedGroups.filter(group => {
      const groupValues = group?.split(COMPOUND_KEY_SEPARATOR) || [];

      return (
        lastExpandedGroup === group || groupValues.length < lowerGroupIndex
      );
    });
  }

  return retVal;
};

export const allowToHaveOnlyOneAnyLevelGroupOpened = (
  expandedGroups: string[]
) => {
  const currentExpandedGroup = last(expandedGroups);
  // only keep open 1 group at the same time
  const groups = currentExpandedGroup?.split(COMPOUND_KEY_SEPARATOR) || [];
  return groups
    .map((currentGroup, index, allGroups) => {
      return allGroups.slice(0, index + 1).join(COMPOUND_KEY_SEPARATOR);
    })
    .reduce((acc, currentGroup, index) => {
      if (acc.length !== index) return acc;

      if (expandedGroups.includes(currentGroup)) {
        acc.push(currentGroup);
      }

      return acc;
    }, [] as string[]);
};
