import { useQueryClient, useMutation } from 'react-query';
import { useCurrentOrganization } from '@shared/hooks';

import workspaceApi from '@/util/workspaceApi';

const useCreateWorkspaceMutation = setWorkspaceName => {
  const queryClient = useQueryClient();
  const currentOrganization = useCurrentOrganization();

  return useMutation(
    ({ workspaceName }) => {
      return workspaceApi.createWorkspace({
        name: workspaceName,
        organizationId: currentOrganization?.id
      });
    },
    {
      onSuccess: async resp => {
        setWorkspaceName(resp?.data?.team_name);
        await queryClient.invalidateQueries(['workspaces', 'all']);

        await queryClient.invalidateQueries(
          ['organizations', currentOrganization?.id],
          { active: true }
        );

        await queryClient.resetQueries(
          ['organizations', currentOrganization?.id],
          {
            inactive: true
          }
        );
      }
    }
  );
};

export default useCreateWorkspaceMutation;
