import React from 'react';
import { NoticeProps, components } from 'react-select';
import typography from '@ds-typography';
import { OptionType } from '@design-system/types';

const NoOptionsMessage = (props: NoticeProps<OptionType>) => {
  return (
    <div className={typography.dsBodyLarge}>
      <components.NoOptionsMessage {...props} />
    </div>
  );
};

export default NoOptionsMessage;
