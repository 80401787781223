import React from 'react';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import SaveViewButton from '@experiment-management-shared/components/SaveViewButton/SaveViewButton';
import ExpandTableButton from '../ExpandTableButton/ExpandTableButton';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';

import styles from './ExperimentDetailViewConfiguration.module.scss';

import { getIsHeaderTableShown } from '@/reducers/experimentDetails/uiReducer';
import ExperimentDetailsSelectView from '../ExperimentDetailsSelectView/ExperimentDetailsSelectView';
import useExperimentViewUnsavedChanges from './hooks/useExperimentViewUnsavedChanges';
import useExperimentDetailActions from './hooks/useExperimentDetailActions';
import useExperimentDetailViewInitialization from './hooks/useExperimentDetailViewInitialization';
import useExperimentDetailsTemplateWrapper from '@experiment-details/hooks/useExperimentDetailsTemplateWrapper';
import { ExperimentTemplate } from '@experiment-details/types';

type ExperimentDetailViewConfigurationProps = {
  experimentKey: string;
  isComparisonView?: boolean;
};
const ExperimentDetailViewConfiguration: React.FC<ExperimentDetailViewConfigurationProps> = ({
  experimentKey,
  isComparisonView = false
}) => {
  const isHeaderTableShown = useSelector(getIsHeaderTableShown);

  const templateWrapper = useExperimentDetailsTemplateWrapper(experimentKey);

  const {
    currentTemplate,
    isLoadingProjectTemplate,
    isLoadingUserTemplates,
    projectTemplate,
    userTemplates
  } = templateWrapper;

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const canEdit = useSelector(isUserAllowedToEditProject);

  const isUserLoggedInAndCanEdit = isUserLoggedIn && canEdit;

  const originalTemplate = userTemplates.find(
    (template: ExperimentTemplate) =>
      template.template_name === currentTemplate.template_name
  );

  useExperimentDetailViewInitialization({
    isLoadingProjectTemplate,
    isLoadingUserTemplates,
    isUserLoggedIn,
    userTemplates,
    experimentKey
  });

  const hasUnsavedChanges = useExperimentViewUnsavedChanges({
    userTemplates,
    currentTemplate,
    originalTemplate,
    isLoadingUserTemplates,
    isLoadingProjectTemplate
  });

  const {
    handleSaveNewTemplate,
    handleDiscardChanges,
    handleUpdateTemplateName,
    handleSaveViewClick,
    handleClickIsTableShown,
    handleUpdateTemplate
  } = useExperimentDetailActions({
    experimentKey,
    currentTemplate,
    userTemplates,
    originalTemplate
  });

  if (!currentTemplate || !projectTemplate) {
    return null;
  }

  const renderTemplateSelect = () => {
    if (!isUserLoggedIn) {
      return (
        <div
          className={styles.notLoggedInUserViewName}
          title={currentTemplate.template_name}
        >
          {currentTemplate.template_name}
        </div>
      );
    }

    return (
      <ExperimentDetailsSelectView
        defaultTemplateName={projectTemplate.template_name}
        currentTemplateId={currentTemplate.template_id}
        currentTemplateName={currentTemplate.template_name}
        currentTemplateProjectId={currentTemplate.project_id}
        experimentKey={experimentKey}
        templates={userTemplates}
        hasUnsavedChanges={hasUnsavedChanges}
        onSaveNewTemplate={handleSaveNewTemplate}
        onDiscardChanges={handleDiscardChanges}
        onUpdateTemplateName={handleUpdateTemplateName}
        onUpdateTemplate={handleUpdateTemplate}
      />
    );
  };

  return (
    <div className={styles.experimentDetailRouteWrapper}>
      {renderTemplateSelect()}
      <SaveViewButton
        onClick={handleSaveViewClick}
        disabled={!isUserLoggedInAndCanEdit || !hasUnsavedChanges}
        hideOverwriteOption={isEmpty(currentTemplate.template_id)}
      />

      <ExpandTableButton
        isOpened={isHeaderTableShown}
        onClick={handleClickIsTableShown}
        isComparisonView={isComparisonView}
      />
    </div>
  );
};

export default ExperimentDetailViewConfiguration;
