import { useMutation, useQueryClient } from 'react-query';
import { TEMPLATE_SCOPE_TYPES } from '@experiment-management-shared/constants/chartsGallery';
import { useActiveWorkspace } from '@shared/hooks';

import { axiosInstance, cancelWrapper } from '../api';

const duplicatePanelTemplate = ({ teamId, templateId, templateName }) =>
  cancelWrapper(async cancelToken => {
    const { data: originalTemplate } = await axiosInstance.get(
      'code-visualizations/template/get',
      {
        cancelToken,
        params: { templateId }
      }
    );

    const { data } = await axiosInstance({
      cancelToken,
      data: {
        code: originalTemplate.code,
        createdFrom: templateId,
        queryBuilderId: '',
        scopeType: TEMPLATE_SCOPE_TYPES.PRIVATE,
        teamId,
        templateName
      },
      method: 'post',
      url: 'code-visualizations/template/create'
    });

    return data;
  });

export default function useDuplicatePanelTemplateMutation() {
  const queryClient = useQueryClient();
  const activeWorkspace = useActiveWorkspace();

  return useMutation(
    ({ templateId, templateName }) =>
      duplicatePanelTemplate({
        teamId: activeWorkspace?.id,
        templateId,
        templateName
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries([
          'panelTemplates',
          { teamId: activeWorkspace?.id, scope: TEMPLATE_SCOPE_TYPES.WORKSPACE }
        ]);

        await queryClient.cancelQueries([
          'panelTemplatesCount',
          { teamId: activeWorkspace?.id }
        ]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'panelTemplates',
          { teamId: activeWorkspace?.id, scope: TEMPLATE_SCOPE_TYPES.WORKSPACE }
        ]);

        await queryClient.invalidateQueries([
          'panelTemplatesCount',
          { teamId: activeWorkspace?.id }
        ]);
      }
    }
  );
}
