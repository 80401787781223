import React from 'react';
import DsAccordion from '@design-system-outdated/components/Accordion/Accordion';
import { LLMSyntaxHighlighter } from './LLMSyntaxHighlighter';
import styles from './Tabs.module.scss';
import { SidebarTabProps } from '../types/SidebarTabProps';

export default function LLMInputsOutputsTab({
  data,
  selectedChainNode,
  experiment
}: SidebarTabProps) {
  return (
    <div className={styles.inputsOutputsContainer}>
      <DsAccordion
        defaultExpanded
        title="Inputs"
        TransitionProps={{
          timeout: 0
        }}
        content={
          <LLMSyntaxHighlighter
            data={selectedChainNode?.inputs || data?.chain_inputs}
          />
        }
        id="outputs_accordion"
      />
      <DsAccordion
        defaultExpanded
        title="Outputs"
        TransitionProps={{
          timeout: 0
        }}
        classes={{
          summaryContent: styles.accordionSummeryContent,
          summary: styles.accordionSummeryContainer
        }}
        content={
          <LLMSyntaxHighlighter
            withFeedback
            experiment={experiment}
            data={selectedChainNode?.outputs || data?.chain_outputs}
          />
        }
        id="inputs_accordion"
      />
    </div>
  );
}
