import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import flow from 'lodash/flow';

import {
  useExperimentKeyParamMap,
  useExperimentUniqueParams
} from '@experiment-management-shared/api';
import { Tooltip } from '@ds';

import {
  PARAM_TREE_COLUMNS,
  cleanInternalNodesWithoutChildren,
  generatedParameterTree,
  getParamValuesWithDecimalPrecisions,
  paramTableConfig,
  getParamValuesAmongExperiments,
  PARAM_ACTION_COLUMN,
  LEFT_COLUMNS
} from '../helpers';
import StringOrLink from '@shared/components/StringOrLink';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';

import classNames from '../HyperParametersTab.module.scss';
import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@/lib/appConstants';
import MetricsTableEditableActions from '@experiment-details/components/metrics/actions/MetricsTableEditableActions';
import useHyperParametersBI from './useHyperParametersBI';
import { experimentEvents } from '@/constants/trackingEventTypes';
import { generateRegexFromString } from '@shared/utils/displayHelpers';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';

const useHyperparametersTableData = ({
  experiments,
  hideIdenticalParams,
  searchValue,
  decimalsPrecision,
  onEditParameterClick,
  isNestedParamApplied,
  onDeleteParameterClick
}) => {
  const {
    sendHyperParametersTabBI,
    sendCompareHyperParametersTabBI
  } = useHyperParametersBI();
  const canEdit = useSelector(isUserAllowedToEditProject);

  const [columnOrder, setColumnOrder] = useState();
  const [columnWidths, setColumnWidths] = useState(
    paramTableConfig.defaultColumnWidths
  );
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [sorting, setSorting] = useState([]);

  const { data: experimentKeyParamMap, isLoading } = useExperimentKeyParamMap({
    refetchOnMount: true,
    refetchInterval: FETCH_EXPERIMENT_POLLING_LENGTH
  });
  const { data: uniqueParams } = useExperimentUniqueParams();

  const isComparePage = useSelector(selectIsComparePage);

  const columns = useMemo(() => {
    const result = [
      {
        name: PARAM_TREE_COLUMNS.NAME,
        title: 'NAME'
      }
    ];

    const experimentColumns = experiments.map(experiment => ({
      name: experiment.experimentKey,
      title: experiment.Name,
      color: experiment.comet_chart_color
    }));

    result.push(...experimentColumns);

    if (!isComparePage) {
      result.push({
        name: PARAM_ACTION_COLUMN,
        title: '   '
      });
    }

    return result;
  }, [experiments, isComparePage]);

  const experimentColumnNames = useMemo(() => {
    return experiments.map(experiment => experiment.experimentKey);
  }, [experiments]);

  const dataTypes = useMemo(() => {
    return [
      {
        cols: LEFT_COLUMNS,
        cell: ({ value }) => (
          <Tooltip content={value} placement="top" truncateOverflow>
            <>{value}</>
          </Tooltip>
        )
      },
      {
        cols: experimentColumnNames,
        cell: ({ value }) => (
          <Tooltip content={value} placement="top" truncateOverflow>
            <StringOrLink str={value} />
          </Tooltip>
        )
      },
      {
        cols: [PARAM_ACTION_COLUMN],
        cell: ({ row }) => {
          if (!row[PARAM_TREE_COLUMNS.EDITABLE]) {
            return null;
          }

          // there is one experiment only, so the array with 1 element is returned
          const [experimentValue] = Object.values(
            getParamValuesAmongExperiments(row)
          );

          return (
            <div className={classNames.paramActionColumn}>
              <MetricsTableEditableActions
                onEdit={() =>
                  onEditParameterClick({
                    name: row[PARAM_TREE_COLUMNS.ORIGINAL_NAME],
                    value: experimentValue
                  })
                }
                onDelete={() =>
                  onDeleteParameterClick(row[PARAM_TREE_COLUMNS.ORIGINAL_NAME])
                }
                disabled={!canEdit}
              />
            </div>
          );
        }
      }
    ];
  }, [onDeleteParameterClick, onEditParameterClick]);

  const disabledColumnsForSorting = useMemo(() => {
    return columns
      .slice(1)
      .map(column => ({ columnName: column.name, sortingEnabled: false }));
  }, [columns]);

  const rows = useMemo(() => {
    if (!uniqueParams?.length || !Object.keys(experimentKeyParamMap).length) {
      return [];
    }

    return generatedParameterTree(
      uniqueParams,
      experimentKeyParamMap,
      isNestedParamApplied
    );
  }, [uniqueParams, experimentKeyParamMap, isNestedParamApplied]);

  const handledRows = useMemo(() => {
    const filterByIdentityConfig = paramRows =>
      paramRows.filter(row => !hideIdenticalParams || !row.equal);

    const filterByText = paramRows => {
      if (!searchValue) {
        return paramRows;
      }

      const searchRegex = generateRegexFromString(searchValue);

      return paramRows.filter(
        row =>
          !!row[PARAM_TREE_COLUMNS.FILTERING_VALUES].find(paramFilter => {
            if (searchRegex) {
              return searchRegex.test(paramFilter);
            }
            return paramFilter
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          })
      );
    };

    const addDecimalsPrecision = paramRows =>
      paramRows.map(row =>
        getParamValuesWithDecimalPrecisions(row, decimalsPrecision)
      );

    const updateRows = flow(
      filterByText,
      filterByIdentityConfig,
      data =>
        isNestedParamApplied ? cleanInternalNodesWithoutChildren(data) : data,
      addDecimalsPrecision
    );

    return updateRows(rows);
  }, [
    decimalsPrecision,
    hideIdenticalParams,
    searchValue,
    rows,
    isNestedParamApplied
  ]);

  const sendToggleBI = useCallback(
    isExpanded => {
      const newState = isExpanded ? 'collapsed' : 'expanded';
      if (isComparePage) {
        sendCompareHyperParametersTabBI(
          experiments,
          experimentEvents.EXPERIMENT_DETAIL_NESTED_PARAM_GROUP_TOGGLED,
          { newState }
        );
      } else {
        sendHyperParametersTabBI(
          experiments[0],
          experimentEvents.EXPERIMENT_DETAIL_NESTED_PARAM_GROUP_TOGGLED,
          { newState }
        );
      }
    },
    [
      experiments,
      sendCompareHyperParametersTabBI,
      sendHyperParametersTabBI,
      isComparePage
    ]
  );

  useEffect(() => {
    // open rows by default
    if (rows?.length) {
      setExpandedRowIds(rows.map(row => row[PARAM_TREE_COLUMNS.ID]));
    }
  }, [rows]);

  const treeConfig = useMemo(() => {
    return {
      ...paramTableConfig.treeDataConfig,
      expandedRowIds,
      onExpandedRowIdsChange: setExpandedRowIds
    };
  }, [expandedRowIds]);

  const onToggleExpandedRow = useCallback(
    row => {
      if (!row.leaf && window.getSelection()?.toString()?.length) {
        return;
      }

      setExpandedRowIds(ids => {
        if (row.leaf) {
          return ids;
        }

        const isExpanded = ids.includes(row.id);

        sendToggleBI(isExpanded);

        if (isExpanded) {
          return ids.filter(id => id !== row.id);
        }

        return [row.id, ...ids];
      });
    },
    [sendToggleBI]
  );

  return {
    columns,
    treeConfig,
    onToggleExpandedRow,
    handledRows,
    disabledColumnsForSorting,
    isLoading,
    columnOrder,
    setColumnOrder,
    columnWidths,
    setColumnWidths,
    sorting,
    setSorting,
    dataTypes,
    isComparePage
  };
};

export default useHyperparametersTableData;
