import { useCallback, useMemo } from 'react';

import {
  Panel,
  PanelLayoutItem,
  PanelSections
} from '@experiment-management-shared/types';

import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import useTrackEventLocation from '@shared/hooks/useTrackEventLocation';
import {
  calculateLayoutForAddingPanel,
  calculateLayoutForRemovingPanel
} from '@experiment-management-shared/utils/layout';
import { OptionType } from '@design-system/types';

export type PanelMoveConfig = {
  move: (chartId: string, oldSectionId: string, newSectionId: string) => void;
  options: OptionType[];
  disabled: boolean;
};

type UsePanelMoveToSectionParams = {
  sections: PanelSections;
  onSectionsChange: (sections: PanelSections) => void;
};

const usePanelMoveToSection = ({
  sections,
  onSectionsChange
}: UsePanelMoveToSectionParams) => {
  const baseTrackEvent = useBaseTrackEvent();
  const trackEventLocation = useTrackEventLocation();

  const sectionsOptions: OptionType[] = useMemo(() => {
    return sections.map(section => ({
      value: section.id,
      label: section.title
    }));
  }, [sections]);

  const handleMovePanelToSection = useCallback(
    (chartId: string, oldSectionId: string, newSectionId: string) => {
      if (newSectionId !== oldSectionId) {
        let layoutItem: PanelLayoutItem | undefined;
        let panel: Panel;
        let tempSections = sections.map(section => {
          if (section.id === oldSectionId) {
            layoutItem = section.layout.find(l => l.i === chartId);
            const panels = section.panels.filter(p => {
              const equal = p.chartId === chartId;

              if (equal) {
                panel = p;
              }
              return !equal;
            });

            return {
              ...section,
              ...calculateLayoutForRemovingPanel(
                panels,
                section.layout,
                section.panels
              )
            };
          }
          return section;
        });

        tempSections = tempSections.map(section => {
          if (section.id === newSectionId && panel) {
            const panels = [...section.panels, panel];
            const sizeMap = layoutItem
              ? {
                  [panel.chartId]: {
                    w: layoutItem.w,
                    h: layoutItem.h
                  }
                }
              : undefined;

            return {
              ...section,
              ...calculateLayoutForAddingPanel(panels, section.layout, sizeMap)
            };
          }
          return section;
        });

        onSectionsChange(tempSections);

        baseTrackEvent('PANEL_MOVE_TO_SECTION', {
          old_section_id: oldSectionId,
          new_section_id: newSectionId,
          chart_id: chartId,
          location: trackEventLocation
        });
      }
    },
    [baseTrackEvent, onSectionsChange, sections, trackEventLocation]
  );

  return useMemo(
    () =>
      ({
        move: handleMovePanelToSection,
        options: sectionsOptions
      } as PanelMoveConfig),
    [handleMovePanelToSection, sectionsOptions]
  );
};

export default usePanelMoveToSection;
