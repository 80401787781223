import React from 'react';
import cx from 'classnames';
import { Box } from '@material-ui/core';

import '../TableCells.scss';

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const BadgeCell = ({ value, colorsConfig }) => (
  <Box>
    <div
      className={cx('badge-wrapper')}
      style={{ ...colorsConfig[value.toLowerCase()] }}
    >
      {capitalizeFirstLetter(value)}
    </div>
  </Box>
);

export default BadgeCell;
