import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import {
  WORKSPACE_PAGE_TAB_LABELS,
  WORKSPACE_PAGE_TABS,
  WORKSPACE_URL_HASHES
} from '@/constants/workspaceConstants';
import { BreadcrumbItemProps } from '@shared/types';
import useBreadcrumbsDataSource from '@shared/hooks/useBreadcrumbsDataSource';

function useModelRegistryPageBreadcrumbs() {
  const breadcrumbsDataSource = useBreadcrumbsDataSource();
  const { pathname } = useLocation();
  const { workspace } = useParams<Record<string, string | undefined>>();

  const items = useMemo(() => {
    const retVal: BreadcrumbItemProps[] = [
      {
        id: 'home',
        type: 'link',
        title: workspace ?? '',
        path: `/${workspace}`,
        dataMaskTest: 'workspace'
      },
      {
        id: 'tab-level-1',
        type: 'link',
        path: `/${workspace}${
          WORKSPACE_URL_HASHES[WORKSPACE_PAGE_TABS.MODEL_REGISTRY]
        }`,
        title: WORKSPACE_PAGE_TAB_LABELS[WORKSPACE_PAGE_TABS.MODEL_REGISTRY]
      }
    ];

    const splitPathname = pathname.split('/').slice(1);

    splitPathname.forEach((pathPart: string, index) => {
      if (index === 0 || index === 1) return;

      if (index === 3) {
        retVal.push({
          id: 'tab-level-2',
          type: 'link',
          path: '/' + splitPathname.slice(0, index).join('/') + '?tab=versions',
          title: 'Versions'
        });
      }

      retVal.push({
        id: pathPart + index,
        type: 'link',
        path: '/' + splitPathname.slice(0, index + 1).join('/'),
        title: pathPart
      });
    });

    return retVal;
  }, [workspace, pathname]);

  useEffect(() => {
    breadcrumbsDataSource.setItems(items);
    return () => {
      breadcrumbsDataSource.resetItems();
    };
  }, [breadcrumbsDataSource.setItems, breadcrumbsDataSource.resetItems, items]);
}

export default useModelRegistryPageBreadcrumbs;
