import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, ListItem, ListItemText } from '@material-ui/core';
import cx from 'classnames';
import Checkbox from '@DesignSystem/controllers/Checkbox/Checkbox';
import RadioButton from '@DesignSystem/controllers/RadioButton/RadioButton';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from './DecisionTree.module.scss';

export const CONTROL_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio'
};

export const CONTROL_TYPES_MAP_TO_COMPONENTS = {
  [CONTROL_TYPES.CHECKBOX]: ({ checked, onChange, color, disabled, value }) => (
    <Checkbox
      data-test="ds-decision-tree-list-item-checkbox"
      checked={checked}
      disabled={disabled}
      color={color}
      value={value}
      onChange={ev => onChange(ev, { value })}
    />
  ),
  [CONTROL_TYPES.RADIO]: ({ disabled, value, color }) => (
    <RadioButton
      data-test="ds-decision-tree-list-item-radiobutton"
      nowrap
      disabled={disabled}
      value={value}
      color={color}
    />
  )
};

export const getControlByType = (controlType, props) => {
  const controlComponentFunc = CONTROL_TYPES_MAP_TO_COMPONENTS[controlType];

  if (controlComponentFunc) return controlComponentFunc(props);

  console.error(`Control type: ${controlType} is not supported`);
  return <span />;
};

const DecisionTreeItem = ({
  value,
  label,
  text,
  controlType,
  tooltip,
  disabled,
  onChange,
  color,
  checked,
  classes: {
    primary: primaryClassName,
    secondary: secondaryClassName,
    label: labelClassName
  }
}) => {
  const treeItem = (
    <ListItem key={value} className={classNames.dsDecisionTreeItemWrapper}>
      <ListItemText
        className={classNames.dsDecisionTreeItem}
        primary={
          <FormControlLabel
            control={getControlByType(controlType, {
              disabled,
              onChange,
              color,
              checked,
              value
            })}
            value={value}
            className={labelClassName}
            label={
              <>
                <div
                  className={cx(
                    classNames.dsDecisionTreeItem,
                    primaryClassName
                  )}
                >
                  {label}
                </div>
                {text && (
                  <div
                    className={cx(
                      classNames.dsDecisionTreeItemSecondary,
                      secondaryClassName
                    )}
                  >
                    {text}
                  </div>
                )}
              </>
            }
          />
        }
      />
    </ListItem>
  );

  return tooltip ? (
    <Tooltip {...tooltip}>
      <span>{treeItem}</span>
    </Tooltip>
  ) : (
    treeItem
  );
};

DecisionTreeItem.propTypes = {
  classes: PropTypes.shape({
    label: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string
  }),
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    placement: PropTypes.string,
    arrow: PropTypes.bool
  }),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  list: PropTypes.shape({
    options: PropTypes.array,
    controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
    onChange: PropTypes.func.isRequired
  })
};

DecisionTreeItem.defaultProps = {
  classes: {},
  tooltip: null,
  checked: false,
  disabled: false,
  color: 'primary',
  onChange: () => null,
  list: null
};

export default DecisionTreeItem;
