import { createSelector } from 'reselect';
import { matchPath } from 'react-router';

import * as projectsUiSelectors from '../reducers/ui/projectsUiReducer';
import { SUB_PATHS } from '@/constants/urlConstants';

export const getRouterLocationPathname = state =>
  state.router.location.pathname;

// we need maintain this selector to identify page only by URL,
// it uses to switch SUB_PATH for getIsArchivePage from experimentDetails single or comparison page
export const getIsExperimentArchivePage = createSelector(
  [getRouterLocationPathname],
  pathname => {
    const archivePaths = [
      '/:workspace/:projectName/archive/:experimentKey',
      '/:workspace/:projectName/archive/compare'
    ];

    return archivePaths.some(
      path => !!matchPath(pathname, { path, exact: false, strict: false })
    );
  }
);

export const getIsArchivePage = createSelector(
  [getRouterLocationPathname, projectsUiSelectors.getActiveEMHeaderTab],
  (pathname, activeTab) => {
    const archivePaths = [
      '/:workspace/:projectName/archive',
      '/:workspace/:projectName/view/:viewId/archive'
    ];

    const isArchivePath = archivePaths.some(
      path => !!matchPath(pathname, { path, exact: false, strict: false })
    );

    return isArchivePath || activeTab === SUB_PATHS.ARCHIVE;
  }
);

export const getIsExperimentsPage = state => {
  const { pathname } = state.router.location;
  const paths = ['/:workspace/:projectName/view/:viewId/experiments'];

  return paths.some(
    path => !!matchPath(pathname, { path, exact: false, strict: false })
  );
};

export const getIsPanelsPage = state => {
  const { pathname } = state.router.location;
  const paths = ['/:workspace/:projectName/view/:viewId/panels'];

  return paths.some(
    path => !!matchPath(pathname, { path, exact: false, strict: false })
  );
};

export const getIsReportsPage = state => {
  const { pathname } = state.router.location;
  const paths = ['/:workspace/:projectName/reports'];

  return paths.some(
    path => !!matchPath(pathname, { path, exact: false, strict: false })
  );
};
