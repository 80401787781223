import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Button.scss';
import { noop } from 'lodash';

const IconButton = ({ Icon, active, disabled, onClick }) => {
  return (
    <button
      className={cx('ds-icon-button', {
        activeState: active
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon />
    </button>
  );
};

IconButton.defaultProps = {
  Icon: null,
  active: false,
  disabled: false,
  onClick: noop
};

IconButton.propTypes = {
  Icon: PropTypes.elementType,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default IconButton;
