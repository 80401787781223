import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '@ds';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { isValidEmail } from '@/lib/utils';
import { useSSOMutation } from '@auth/api';
import { REDIRECT_URL_AFTER_LOGIN_KEY } from '@auth/hooks';

import styles from './SignInSSOModal.module.scss';

export const SignInSSOModal = ({
  onClose,
  onError,
  shouldKeepLocation = true
}: {
  onClose?: () => void;
  onError?: (error: string) => void;
  shouldKeepLocation?: boolean;
}) => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const ssoMutation = useSSOMutation();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      history.push('/login');
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = await ssoMutation.mutateAsync({ email });

      if (shouldKeepLocation) {
        window.localStorage.setItem(
          REDIRECT_URL_AFTER_LOGIN_KEY,
          window.location.href
        );
      }

      window.location.href = data.url;
    } catch (error) {
      if (!(error instanceof Error)) return;

      if (onError) {
        onError(error.message);
      } else {
        history.push(
          `/login?errorMessage=${encodeURIComponent(error.message)}`
        );
      }
    }
  };

  return (
    <Dialog maxWidth="md" open className="text-center login-modal">
      <form onSubmit={handleSubmit}>
        <DialogTitle className="modal-title">
          <span className={styles.title}>Single Sign On</span>
          <IconButton
            classes={{ root: styles.closeButton }}
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
          <div className={styles.subtitle}>
            Sign it with your company single sign on email address
          </div>
        </DialogTitle>
        <DialogContent>
          <FormGroup className="form-wrapper-grid">
            <TextField
              autoFocus
              InputProps={{
                disableUnderline: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              label="Email"
              placeholder="user@example.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              margin="normal"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !isValidEmail(email) ||
              ssoMutation.isLoading ||
              ssoMutation.isSuccess
            }
          >
            Sign in
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
