import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Popover } from '@material-ui/core';
import { SearchableList } from '@ds';

const QueryFilterList = ({
  columns,
  onClose,
  anchorEl,
  onSelect,
  constructFilterDefinition
}) => {
  const columnsWithoutTags = useMemo(() => {
    return columns.filter(
      ({ id }) => !id.includes('--tag') && id !== 'tag--metadata'
    );
  }, [columns]);

  const items = useMemo(() => {
    return constructFilterDefinition(columnsWithoutTags);
  }, [columnsWithoutTags, constructFilterDefinition]);

  return (
    <Popover
      anchorEl={anchorEl?.current}
      open={open}
      onClose={onClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        style: {
          width: '340px',
          padding: 2
        }
      }}
    >
      <div>
        <SearchableList
          itemList={items}
          searchField="label"
          isRegexSearchEnabled
        >
          <SearchableList.VirtualizedResult maxHeight={560}>
            {data => (
              <SearchableList.Option
                valueOptions={[]}
                data={data}
                onSelect={data => onSelect(data.value, data)}
                dataTest="filter-option-item"
                truncateMiddle
                maxWidth={320}
                tooltipPlacement="top"
              />
            )}
          </SearchableList.VirtualizedResult>
          <SearchableList.NotFound>No options</SearchableList.NotFound>
        </SearchableList>
      </div>
    </Popover>
  );
};

QueryFilterList.defaultProps = {
  anchorEl: null,
  onClose: noop,
  onSelect: noop,
  constructFilterDefinition: noop
};

QueryFilterList.propTypes = {
  anchorEl: PropTypes.object,
  columns: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  constructFilterDefinition: PropTypes.func
};

export default QueryFilterList;
