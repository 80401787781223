// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader-module__loaderBarContainer--_Np5r{height:2px}.Loader-module__loaderBarContainer--_Np5r .MuiLinearProgress-colorPrimary{background-color:var(--gray-2)}.Loader-module__loaderBarContainer--_Np5r .MuiLinearProgress-root{height:2px}.Loader-module__loaderBarContainer--_Np5r .MuiLinearProgress-barColorPrimary{color:var(--primary-color);animation-duration:4s}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderBarContainer": `Loader-module__loaderBarContainer--_Np5r`
};
module.exports = ___CSS_LOADER_EXPORT___;
