import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';

import { IAssetData } from '@experiment-management-shared/api/useAssetsForExperiments';
import {
  CONFUSION_MATRIX_CELL_VALUES,
  CONFUSION_MATRIX_COLOR_DISTRIBUTIONS
} from '@experiment-management-shared/constants/chartConstants';
import { MatrixLegend } from '@DesignSystem/confusion-matrix';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import ConfusionMatricesExamples from './ConfusionMatricesExamples';
import ConfusionMatrixContainer from './ConfusionMatrixContainer';
import { getRanges } from '@/helpers/confusionMatrixHelpers';

import styles from './ConfusionMatricesContainer.module.scss';

const MAX_SELECTED_EXAMPLES = 5;

const COLOR_SCALE_BY_CELL_VALUES = {
  [CONFUSION_MATRIX_CELL_VALUES.COUNTS]: [
    '#FFFFFF',
    '#E8EAFF',
    '#D2D6FF',
    '#A5ABFF',
    '#5D63F3',
    '#0F19B3'
  ],
  [CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_COLUMN]: [
    '#FFFFFF',
    '#FFCCE5',
    '#FF99CC',
    '#FF66B3',
    '#FF3399'
  ],
  [CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_ROW]: [
    '#FFFFFF',
    '#BFF0E7',
    '#7FE0CF',
    '#40D1B8',
    '#00C2A0'
  ]
};

const buildID = assetId => `container_${assetId}`;

const ConfusionMatricesContainer = ({
  experiments,
  assetSummaries,
  assets,
  cellValue,
  colorDistribution,
  assetsIds,
  onChangeAssets
}) => {
  const [matricesMap, setMatricesMap] = useState({});
  const [matricesExampleMap, setMatricesExampleMap] = useState({});
  const [highlightedCellObject, setHighlightedCellObject] = useState({});
  const containerRef = useRef();

  const isPercentage = [
    CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_ROW,
    CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_COLUMN
  ].includes(cellValue);

  const colorScale = COLOR_SCALE_BY_CELL_VALUES[cellValue];

  const unitedMatrix = useMemo(() => {
    let retVal = [];

    assetsIds.forEach(id => {
      if (matricesMap[id]) {
        retVal = retVal.concat(matricesMap[id][cellValue]);
      }
    });

    return retVal;
  }, [matricesMap, cellValue, assetsIds]);

  useEffect(() => {
    // remove not existing matrices from example
    Object.keys(matricesExampleMap).forEach(key => {
      if (!assetsIds.includes(key)) {
        delete matricesExampleMap[key];
      }
    });
  }, [assetsIds, matricesExampleMap]);

  const ranges = useMemo(() => {
    return getRanges(
      unitedMatrix,
      colorScale,
      isPercentage,
      colorDistribution === CONFUSION_MATRIX_COLOR_DISTRIBUTIONS.SMART
    );
  }, [unitedMatrix, colorDistribution, colorScale, isPercentage]);

  const stepsMap = useMemo(() => {
    const retVal = {};

    experiments.forEach(experiment => {
      const experimentAssets = groupBy(
        assetSummaries.filter(
          a => a.experimentKey === experiment.experimentKey
        ),
        'fileName'
      );

      if (!retVal[experiment.experimentKey]) {
        retVal[experiment.experimentKey] = {};
      }

      Object.keys(experimentAssets).forEach(groupKey => {
        retVal[experiment.experimentKey][groupKey] = experimentAssets[groupKey]
          .sort((assetA, assetB) => assetA.step - assetB.step)
          .map(a => ({
            step: a.step,
            assetId: a.assetId
          }));
      });
    });

    return retVal;
  }, [experiments, assetSummaries]);

  const handleStepChange = (assetId, newAssetId) => {
    onChangeAssets(assetsIds.map(id => (id === assetId ? newAssetId : id)));
  };

  const calculateInitialPopupCoordinates = ({ assetId, row }) => {
    const retVal = { x: 0, y: 0 };
    const verticalGap = 12;
    const popupWidth = 430;
    const popupHeight = 152;
    const container = containerRef.current;
    const matrixContainer = document.getElementById(buildID(assetId));
    const matrixRow = matrixContainer.getElementsByClassName(
      'confusion-matrix-chart-row'
    )[row];

    const containerPosition = container.getBoundingClientRect();
    const matrixContainerPosition = matrixContainer.getBoundingClientRect();
    const matrixRowPosition = matrixRow.getBoundingClientRect();

    retVal.y = Math.max(
      0,
      Math.min(
        matrixRowPosition.bottom + verticalGap - containerPosition.top,
        containerPosition.height - verticalGap - popupHeight
      )
    );
    retVal.x = Math.max(
      0,
      Math.min(
        matrixContainerPosition.left - containerPosition.left,
        containerPosition.width - popupWidth
      )
    );

    return retVal;
  };

  const handleCellClick = useCallback(
    ({ col, data, row, assetId }) => {
      setMatricesExampleMap(state => {
        const retVal = { ...state };

        if (!retVal[assetId]) {
          const asset = assets.find(a => a.assetId === assetId) || {};
          const experiment = experiments.find(
            e => e.experimentKey === asset.experimentKey
          );

          retVal[assetId] = {
            experiment,
            title: asset.fileName,
            data,
            positions: [],
            popupPosition: calculateInitialPopupCoordinates({
              assetId,
              row
            })
          };
        }

        const filteredPositions = retVal[assetId].positions.filter(
          p => !(p.row === row && p.col === col)
        );

        // the clicked position that is opened
        if (retVal[assetId].positions.length !== filteredPositions.length) {
          retVal[assetId].positions = filteredPositions;
        } else {
          retVal[assetId].positions = [
            ...retVal[assetId].positions,
            { col, row }
          ];
        }

        // cut examples if we can more that MAX_SELECTED_EXAMPLES
        if (retVal[assetId].positions.length > MAX_SELECTED_EXAMPLES) {
          retVal[assetId].positions = retVal[assetId].positions.slice(
            -1 * MAX_SELECTED_EXAMPLES
          );
        }

        // delete asset from map in case there is no positions
        if (retVal[assetId].positions.length === 0) {
          delete retVal[assetId];
        }

        return retVal;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets]
  );

  const handleCloseExample = useCallback(({ assetId }) => {
    setMatricesExampleMap(state => {
      const retVal = { ...state };

      delete retVal[assetId];

      return retVal;
    });
  }, []);

  const handleCloseExampleCell = useCallback(({ assetId }, { col, row }) => {
    setMatricesExampleMap(state => {
      if (state[assetId]) {
        const retVal = { ...state };

        const filteredPositions = retVal[assetId].positions.filter(
          p => !(p.row === row && p.col === col)
        );

        // the clicked position that is opened
        if (filteredPositions.length === 0) {
          delete retVal[assetId];
        } else {
          retVal[assetId].positions = filteredPositions;
        }

        return retVal;
      }
      return state;
    });
  }, []);

  const sensors = useSensors(useSensor(MouseSensor));
  const isSortable = assets.length > 1;

  const handleDragEnd = event => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = assetsIds.indexOf(active.id);
      const newIndex = assetsIds.indexOf(over.id);

      onChangeAssets(arrayMove(assetsIds, oldIndex, newIndex));
    }
  };

  return (
    <div ref={containerRef} className={styles.mainContainer}>
      <div className={styles.matricesContainer}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={assetsIds}
            strategy={horizontalListSortingStrategy}
          >
            {assets.map(asset => {
              const experiment = find(
                experiments,
                e => e.experimentKey === asset.experimentKey
              );

              const id = buildID(asset.assetId);

              return (
                <ConfusionMatrixContainer
                  key={`${asset.experimentKey}_${asset.fileName}`}
                  id={id}
                  ranges={ranges}
                  stepsArray={stepsMap[asset.experimentKey][asset.fileName]}
                  isSortable={isSortable}
                  isPercentage={isPercentage}
                  experiment={experiment}
                  asset={asset}
                  cellValue={cellValue}
                  highlightPositions={
                    matricesExampleMap[asset.assetId]?.positions
                  }
                  onCellClick={handleCellClick}
                  onCellHighlight={setHighlightedCellObject}
                  onStepChange={handleStepChange}
                  setMatricesMap={setMatricesMap}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
      <div className={styles.legendContainer}>
        <div className={styles.legendWrapper}>
          <MatrixLegend ranges={ranges} isPercentage={isPercentage} />
        </div>
      </div>

      <ConfusionMatricesExamples
        matricesExampleMap={matricesExampleMap}
        highlightedCellObject={highlightedCellObject}
        onCloseExample={handleCloseExample}
        onCloseExampleCell={handleCloseExampleCell}
      />
    </div>
  );
};

ConfusionMatricesContainer.defaultProps = {
  assets: [],
  onChangeAssets: noop
};

ConfusionMatricesContainer.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  assetSummaries: PropTypes.arrayOf(IAssetData).isRequired,
  assets: PropTypes.arrayOf(IAssetData),
  colorDistribution: PropTypes.oneOf(
    Object.values(CONFUSION_MATRIX_COLOR_DISTRIBUTIONS)
  ).isRequired,
  cellValue: PropTypes.oneOf(Object.values(CONFUSION_MATRIX_CELL_VALUES))
    .isRequired,
  assetsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeAssets: PropTypes.func
};

export default ConfusionMatricesContainer;
