import { useMutation, useQueryClient } from 'react-query';
import organizationApi from '@shared/api/organizationApi';

const useOrganizationNameMutation = organizationId => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ organizationName }) => {
      return organizationApi.changeOrganizationName(
        organizationId,
        organizationName
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizations', 'list']);
      }
    }
  );
};

export default useOrganizationNameMutation;
