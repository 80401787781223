import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import { TextButton } from '@ds';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { DSOpenInNewIcon } from '@ds-icons';

const DETAIL_VIEW_TO_PATH = {
  [DETAIL_VIEWS.GRAPH_DEFINITION]: 'graph',
  [DETAIL_VIEWS.INSTALLED_PACKAGES]: 'installedPackages',
  [DETAIL_VIEWS.CODE]: 'code'
};

const BaseCodeCompareHeader = ({
  assetId,
  detailView,
  experiment,
  isDiff,
  isDataEmpty
}) => {
  const { workspace, projectName } = useParams();
  const { experimentKey } = experiment;

  const viewPath = DETAIL_VIEW_TO_PATH[detailView];
  const handleViewRaw = () => {
    const url = assetId
      ? `/${workspace}/${projectName}/${experimentKey}/${assetId}/raw/asset`
      : `/${workspace}/${projectName}/${experimentKey}/raw/${viewPath}`;

    window.open(url, '_blank', 'noopener');
  };

  const button = (
    <TextButton
      onClick={handleViewRaw}
      type="secondary"
      PrefixIcon={<DSOpenInNewIcon />}
      disabled={isDataEmpty}
    >
      View raw
    </TextButton>
  );

  if (isDiff) {
    return <div className="diff-content-header">{button}</div>;
  }

  return (
    <div className="diff-content-header no-diff">
      <div className="no-changes">(No difference between experiments)</div>

      {button}
    </div>
  );
};

BaseCodeCompareHeader.defaultProps = {
  assetId: null,
  detailView: null
};

BaseCodeCompareHeader.propTypes = {
  assetId: PropTypes.string,
  detailView: PropTypes.string,
  experiment: IExperimentDetails.isRequired,
  isDiff: PropTypes.bool.isRequired,
  isDataEmpty: PropTypes.bool
};

export default BaseCodeCompareHeader;
