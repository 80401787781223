// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LLMGroupedCellComponent-module__llmGroupedCellComponent--EAJZZ{display:flex;font-size:14px;margin:5px 0 5px 12px}.LLMGroupedCellComponent-module__llmGroupedCellComponent--EAJZZ.LLMGroupedCellComponent-module__expanded--jSSnD{color:var(--primary-color)}.LLMGroupedCellComponent-module__llmGroupedCellComponent--EAJZZ .LLMGroupedCellComponent-module__title--gPqFg{font-weight:bold;margin-right:4px}.LLMGroupedCellComponent-module__llmGroupedCellComponent--EAJZZ .LLMGroupedCellComponent-module__value--_MONb{font-weight:bold}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"llmGroupedCellComponent": `LLMGroupedCellComponent-module__llmGroupedCellComponent--EAJZZ`,
	"expanded": `LLMGroupedCellComponent-module__expanded--jSSnD`,
	"title": `LLMGroupedCellComponent-module__title--gPqFg`,
	"value": `LLMGroupedCellComponent-module__value--_MONb`
};
module.exports = ___CSS_LOADER_EXPORT___;
