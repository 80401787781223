import { OptionType } from '@design-system/types';
import { isArray } from 'lodash';
import { createFilter } from 'react-select';

export const valueToList = (
  value?: OptionType['value'] | OptionType['value'][]
) => {
  if (!value) return [];

  if (isArray(value)) return value;

  return [value];
};

const getFullList = (options: OptionType[]): OptionType[] => {
  const fullList: OptionType[] = [];

  options.forEach(option => {
    if (option.options?.length) {
      fullList.push(...getFullList(option.options));
    } else {
      fullList.push(option);
    }

    if (option.extraOptions?.length) {
      fullList.push(...option.extraOptions);
    }
  });

  return fullList;
};

export const getSelectValue = (value: unknown, options: OptionType[]) => {
  if (value === undefined) return null;

  if (value === null) {
    return options.find(option => option.value === null);
  }

  const list = getFullList(options);

  return list.find(({ value: v }) => v === value);
};

export const getSelectValues = (
  selectedValues: unknown[],
  options: OptionType[]
) => {
  const optionsMap: Record<string, OptionType> = {};
  const setOptionsMapValue = (option: OptionType) => {
    optionsMap[`${option.value}`] = option;
  };
  options.forEach(option => {
    setOptionsMapValue(option);

    option.options?.forEach(nestedOption => {
      setOptionsMapValue(nestedOption);
      nestedOption.extraOptions?.forEach(extraOption =>
        setOptionsMapValue(extraOption)
      );
    });

    option.extraOptions?.forEach(extraOption =>
      setOptionsMapValue(extraOption)
    );
  });

  const uniqueValues = [...new Set(selectedValues)];

  const selectedOptions: OptionType[] = [];
  uniqueValues.forEach(selectedValue => {
    const option = optionsMap[`${selectedValue}`];
    if (!option) return;

    selectedOptions.push(option);
  });

  return selectedOptions;
};

export const createOptionFilter = (isRegexEnabled: boolean) => {
  const baseFilter = createFilter<OptionType>({
    ignoreAccents: false,
    stringify: option =>
      `${option.value} ${option.label} ${option.data.searchString}`
  });

  const optionFilter: typeof baseFilter = (option, inputValue) => {
    if (!isRegexEnabled) {
      return baseFilter(option, inputValue);
    }

    if (!inputValue) {
      return true;
    }

    try {
      const regex = new RegExp(inputValue, 'i');
      const stringToTest = `${option.value} ${option.label} ${option.data.searchString}`;
      return regex.test(stringToTest);
    } catch (e) {
      // Invalid regex, fall back to base filter
      return baseFilter(option, inputValue);
    }
  };

  return optionFilter;
};
