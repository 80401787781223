import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

import styles from './Loader.module.scss';

const Loader = ({ show }) => {
  return (
    <div className={styles.loaderBarContainer}>
      {show && <LinearProgress />}
    </div>
  );
};

Loader.propTypes = {
  show: PropTypes.bool
};

Loader.defaultProps = {
  show: false
};

export default Loader;
