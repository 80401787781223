// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActiveStatusIconDialog-module__statusIconDialogPopover--PtzVE{background:#fff;border:1px solid #f0f2f6;box-shadow:0px 0px 8px rgba(0,0,0,.13);border-radius:4px;width:356px}.ActiveStatusIconDialog-module__statusIconDialogContainer--H6cu6{padding:16px 0}.ActiveStatusIconDialog-module__statusIconDialogTitle--FvIuL{font-size:14px;margin-top:0;padding:0 18px 16px 18px;border-bottom:1px solid var(--gray-line)}.ActiveStatusIconDialog-module__statusIconDialogParagraph--GpuDL{padding:0 16px;font-size:12px;line-height:18px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIconDialogPopover": `ActiveStatusIconDialog-module__statusIconDialogPopover--PtzVE`,
	"statusIconDialogContainer": `ActiveStatusIconDialog-module__statusIconDialogContainer--H6cu6`,
	"statusIconDialogTitle": `ActiveStatusIconDialog-module__statusIconDialogTitle--FvIuL`,
	"statusIconDialogParagraph": `ActiveStatusIconDialog-module__statusIconDialogParagraph--GpuDL`
};
module.exports = ___CSS_LOADER_EXPORT___;
