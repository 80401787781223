import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import modelRegistryApi from '../../util/modelRegistryApi';
import alertsUtil from '../../util/alertsUtil';
import { dialogTypes, snackbarTypes } from '../../constants/alertTypes';
import history from '../../history';
import { WORKSPACE_URL_HASHES } from '../../constants/workspaceConstants';
import { SUB_PATHS } from '../../constants/urlConstants';

const useDeleteVersionModelMutation = ({
  workspace,
  modelName,
  handleRedirectOnDelete
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ modelItemId, isLastVersion, nextVersion }) =>
      modelRegistryApi.deleteModelRegistryItem(modelItemId).then(() => ({
        modelItemId,
        isLastVersion,
        nextVersion
      })),
    {
      onSuccess: async ({ modelItemId, isLastVersion, nextVersion }) => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_API_RESPONSE,
            'Successfully deleted model item.'
          )
        );

        if (isLastVersion) {
          history.push(`/${workspace}${WORKSPACE_URL_HASHES.MODEL_REGISTRY}`);
        } else {
          await queryClient.invalidateQueries([
            'modelRegistry',
            { workspace, modelName }
          ]);
          await queryClient.invalidateQueries([
            'modelRegistryById',
            { registryModelId: modelItemId }
          ]);

          if (handleRedirectOnDelete) {
            if (nextVersion) {
              history.push(
                `/${workspace}/${SUB_PATHS.MODEL_REGISTRY}/${modelName}/${nextVersion?.version}`
              );
            } else {
              history.push(
                `/${workspace}${WORKSPACE_URL_HASHES.MODEL_REGISTRY}`
              );
            }
          }
        }
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error deleting your model item.'
          )
        );
      }
    }
  );
};

export default useDeleteVersionModelMutation;
