import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { generatePath, withRouter } from 'react-router';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import Alert from '@material-ui/lab/Alert';

import useExperiments from '@API/experiments/useExperiments';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import { MAX_EXPERIMENTS_PER_PAGE } from '@experiment-management-shared/constants/experimentConstants';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';
import {
  useExperimentKeys,
  useIsServerCustomPanelsEnabled
} from '@experiment-management-shared/hooks';

import useFiltersLibraryManagement from '@experiment-management-shared/hooks/useFiltersLibraryManagement';
import CodeEditor from './CodeEditor';
import CodePreview from './CodePreview';
import VizEditorFooter from './VizEditorFooter';
import VizEditorHeader from './VizEditorHeader';
import SmallLoader from '@shared/components/SmallLoader';

import SaveViewModal from '@experiment-management-shared/components/SaveViewModal';

import visualizationsActions from '@/actions/visualizationsActions';
import {
  NEW_TEMPLATE,
  getCodePreview,
  makeGetVisualizationTemplate,
  makeGetLatestRevisonId,
  getCodeLanguagePreview
} from '@/reducers/visualizationsReducer';
import {
  hasInternalResourcesBeenFetched,
  selectQueryBuilderIdRevision,
  isErrorFetchingDataForChart,
  getSrcDoc
} from '@/reducers/ui/visualizationsUiReducer';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { MESSAGE_FETCHING_VISUALIZATION_TEMPLATE } from '@/constants/messages';
import { getTemplateIdFromURL } from '@experiment-management-shared/utils/visualizationsHelper';
import {
  getTemplateName,
  selectTemplateQueryBuilderId
} from '@/selectors/visualizationSelectors';
import DocsLink from '@shared/components/DocsLink';
import { DOCS_LINK_TYPE, SUB_PATHS } from '@/constants/urlConstants';
import CodeConsole from './CodeConsole';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import PythonBetaWarning from './PythonBetaWarning';

import {
  iFrameConfig,
  PROJECT_TOKEN_REFRESH_INTERVAL,
  PYTHON_PANEL_REFETCH_INTERVAL,
  PYTHON_PANEL_RETRY_COUNT,
  PYTHON_PANEL_RETRY_DELAY
} from '@experiment-management-shared/constants';
import usePrevious from '@/helpers/custom-hooks/usePrevious';
import { useActiveWorkspace } from '@shared/hooks';

import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import { usePythonPanelURL } from '@experiment-management-shared/api';
import useThumbnailActions from '@experiment-management-shared/components/VizEditor/hooks/useThumbnailActions';
import randomstring from 'randomstring';
import useProjectToken from '@API/panels/useProjectToken';

const VizEditor = ({
  canEdit,
  codePreview,
  templateId,
  currentTemplate,
  dispatch,
  projectId,
  hasInternalResourcesBeenFetched,
  history,
  isErrorFetchingDataForChart,
  latestRevisionId,
  location,
  match,
  templateName,
  currentCodeLanguage,
  queryBuilderId,
  queryBuilderIdRevision
}) => {
  const [codeVersion, setLocalCodeVersion] = useState(null);
  const [codeLanguageJustChanged, setCodeLanguageJustChanged] = useState(false);
  const isCodeAlreadyRun = !!codeVersion;

  const srcDoc = useSelector(getSrcDoc);

  const newPanelInstanceId = useMemo(() => {
    return `NEW_INSTANCE_${new Date().getTime()}`;
  }, []);

  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();

  const isPy = currentCodeLanguage === PANEL_CODE_LANGUAGES.PYTHON;
  const showConsole = !isPy || !isServerCustomPanelsEnabled;
  const isSrcDocNeeded = !isPy || !isServerCustomPanelsEnabled;
  const isProjectTokenNeeded = isPy && isServerCustomPanelsEnabled;

  const { data: projectToken } = useProjectToken(
    { chartId: newPanelInstanceId },
    {
      refetchInterval: PROJECT_TOKEN_REFRESH_INTERVAL,
      refetchOnMount: true,
      enabled: isProjectTokenNeeded
    }
  );

  useEffect(() => {
    setCodeLanguageJustChanged(true);
  }, [currentCodeLanguage]);

  const activeWorkspace = useActiveWorkspace();

  const hardcodedExperimentKeys = useExperimentKeys();

  const { params, path } = match;
  const { revisionId, projectName, workspace: workspaceName } = params;

  const isExperimentsHardcoded = !!hardcodedExperimentKeys.length;
  const { isLoading, activeFilter } = useFiltersLibraryManagement({
    filterId: queryBuilderId
  });

  const {
    data: experimentsData,
    isError: isErrorExperiments,
    isIdle: isIdleExperiments,
    isLoading: isLoadingExperiments
  } = useExperiments(
    {
      targetExperimentKeys: isExperimentsHardcoded
        ? hardcodedExperimentKeys
        : null,
      view: {
        query: { rulesTree: activeFilter?.rulesTree },
        table: {
          columnSorting: [],
          columnOrders: [],
          pageSize: MAX_EXPERIMENTS_PER_PAGE,
          pageNumber: 0
        }
      }
    },
    {
      refetchOnMount: true,
      enabled: !isLoading
    }
  );

  const experimentKeys = useMemo(() => {
    return experimentsData?.experiments?.map(exp => exp.experimentKey);
  }, [experimentsData]);

  const {
    data: customPanelServerUrl,
    isLoading: isLoadingServerPythonPanel,
    isFetching: isFetchingServerPythonPanel,
    isError: serverPythonPanelError
  } = usePythonPanelURL(
    {
      projectToken,
      projectName,
      workspaceName,
      code: codePreview.pyCode || '',
      options: codePreview?.pyConfig,
      instanceId: newPanelInstanceId,
      experimentKeys,
      codeVersion: codeVersion
    },
    {
      enabled: !!(
        projectName &&
        workspaceName &&
        isPy &&
        isCodeAlreadyRun &&
        projectToken
      ),
      refetchIntervalInBackground: true,
      refetchInterval: PYTHON_PANEL_REFETCH_INTERVAL,
      retry: PYTHON_PANEL_RETRY_COUNT,
      keepPreviousData: true,
      retryDelay: PYTHON_PANEL_RETRY_DELAY
    }
  );

  const isInitialLoadingServerPythonPanel =
    isFetchingServerPythonPanel && isLoadingServerPythonPanel && isPy;

  const templateScopeType = get(currentTemplate, 'scopeType', null);

  const { onReceiveMessage, clearThumbnail } = useThumbnailActions(
    templateId,
    templateScopeType
  );

  useEffect(() => {
    return () => {
      clearThumbnail();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', onReceiveMessage, false);

    return () => {
      window.removeEventListener('message', onReceiveMessage);
    };
  }, [onReceiveMessage]);

  const prevTemplateId = useRef(templateId);
  const prevLatestRevisionId = usePrevious(latestRevisionId);
  const prevRevisionId = usePrevious(revisionId);

  const [isFetchingTemplate, setIsFetchingTemplate] = useState(
    !isEmpty(templateId)
  );

  useEffect(() => {
    dispatch(visualizationsActions.fetchInternalResources());

    return () => {
      dispatch(visualizationsActions.setCodePreview());
      dispatch(visualizationsActions.setSrcDoc(''));
      dispatch(visualizationsActions.resetMutableTemplateFields());
    };
  }, []);

  useEffect(() => {
    if (isPy && !isServerCustomPanelsEnabled) {
      window.initPyodideWorker();
    }
  }, [isPy, isServerCustomPanelsEnabled]);

  useEffect(() => {
    const handlePyWorkerEvents = event => {
      const { messageTypes, iframeId } = iFrameConfig;

      const codePreviewIframe = document.getElementById(iframeId);
      if (codePreviewIframe) {
        codePreviewIframe.contentWindow.postMessage(
          {
            ...event.data,
            messageType: messageTypes.ADD_PY_OUTPUT
          },
          '*'
        );
      }
    };

    window.PYODIDE_WORKER.addEventListener('message', handlePyWorkerEvents);

    return () => {
      window.PYODIDE_WORKER.removeEventListener(
        'message',
        handlePyWorkerEvents
      );
    };
  }, []);

  useEffect(() => {
    if (canEdit && templateId) {
      dispatch(visualizationsActions.fetchTemplateRevisions(templateId));
    }
  }, [canEdit, dispatch, templateId]);

  const handleReplaceRevisionPath = useCallback(
    revisionId => {
      const pathname = generatePath(path, {
        ...params,
        revisionId
      });

      history.replace({
        ...location,
        pathname
      });
    },
    [history, location, path, params]
  );

  const handleRunButtonClick = useCallback(() => {
    setLocalCodeVersion(randomstring.generate(6));
    setCodeLanguageJustChanged(false);
  }, [projectName]);

  useEffect(() => {
    if (!revisionId && templateId) {
      handleReplaceRevisionPath(SUB_PATHS.LATEST_REVISION);
    }
  }, [revisionId, templateId, handleReplaceRevisionPath]);

  useEffect(() => {
    if (templateId && revisionId) {
      // it handles the case when new latest revision id is just loaded, so there is no any need to load the old revision id
      if (
        (prevLatestRevisionId !== latestRevisionId &&
          prevLatestRevisionId === Number(revisionId)) ||
        (prevRevisionId === revisionId &&
          latestRevisionId === prevLatestRevisionId)
      ) {
        return;
      }

      if (latestRevisionId === Number(revisionId)) {
        return;
      }

      if (isNull(prevTemplateId.current)) {
        prevTemplateId.current = templateId;
        return;
      }

      dispatch(
        visualizationsActions.fetchVisualizationTemplate(templateId, revisionId)
      ).then(({ newRevisionId }) => {
        if (revisionId === SUB_PATHS.LATEST_REVISION) {
          handleReplaceRevisionPath(newRevisionId);
        }
      });
    }
  }, [
    revisionId,
    templateId,
    latestRevisionId,
    prevLatestRevisionId,
    prevRevisionId,
    handleReplaceRevisionPath
  ]);

  useEffect(() => {
    if (templateId === currentTemplate.templateId) {
      setIsFetchingTemplate(false);
    }
  }, [templateId, currentTemplate.templateId]);

  if (
    isFetchingTemplate ||
    isLoadingExperiments ||
    isIdleExperiments ||
    isLoading ||
    !hasInternalResourcesBeenFetched
  ) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_VISUALIZATION_TEMPLATE}
      />
    );
  }

  const renderFetchingError = () => {
    let message = 'Error fetching data.';

    if (isErrorExperiments) {
      message = 'Error fetching experiments.';
    }

    if (isErrorFetchingDataForChart) {
      message = 'This template does not exist.';
    }
    return (
      <div className="content-not-found">
        <h3>{message}</h3>
      </div>
    );
  };

  const handleSaveNewTemplate = newTemplateName => {
    const { thumbnail, version, ...newCode } = codePreview;
    const newTemplate = {
      code: { ...currentTemplate.code, ...newCode },
      templateName: newTemplateName,
      teamId: activeWorkspace?.id
    };

    if (newCode.defaultConfig)
      newCode.defaultConfig = JSON.stringify(newCode.defaultConfig);

    dispatch(visualizationsActions.createTemplate(newTemplate));
  };

  const handleUpdateTemplate = () => {
    dispatch(
      visualizationsActions.updateVisualizationTemplate(templateId, revisionId)
    );
  };

  const openSaveViewModal = () => {
    const saveViewModal = (
      <SaveViewModal
        defaultNewTemplateName={templateName}
        entityType={PANEL_ENTITY_NAME}
        modalId={dialogTypes.SAVE_TEMPLATE_MODAL}
        saveNewTemplateHandler={handleSaveNewTemplate}
        submitButtonDataCy="save-panel-submit"
        templateId={currentTemplate.templateId}
        templateName={templateName}
        updateTemplateHandler={handleUpdateTemplate}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(dialogTypes.SAVE_TEMPLATE_MODAL, saveViewModal)
    );
  };

  const handleRedirect = () => {
    window.close();
  };

  const renderOutdatedVersionWarning = () => {
    const { revisionId } = currentTemplate;

    if (revisionId && latestRevisionId && revisionId !== latestRevisionId) {
      return (
        <Alert
          severity="warning"
          className="custom-alert warning"
          style={{ marginLeft: '50px', marginRight: '10px' }}
        >
          This is a previous version of the panel
        </Alert>
      );
    }
  };

  const errorLabel =
    isPy && isServerCustomPanelsEnabled && serverPythonPanelError
      ? 'Python panel is not available'
      : '';

  const renderBetaPythonPanelWarning = () => {
    if (isPy) {
      return <PythonBetaWarning />;
    }

    return null;
  };

  return (
    <div className="viz-editor-page-container">
      <VizEditorHeader />
      <div className="long-container">
        <div className="account-header">
          <div style={{ display: 'flex' }}>
            {renderOutdatedVersionWarning()}
            {renderBetaPythonPanelWarning()}
          </div>
          <div>
            <DocsLink
              type={
                isPy
                  ? DOCS_LINK_TYPE.PYTHON_PANELS
                  : DOCS_LINK_TYPE.JAVASCRIPT_PANELS
              }
            />
          </div>
        </div>
        <>
          {isErrorFetchingDataForChart || isErrorExperiments ? (
            renderFetchingError()
          ) : (
            <div className="viz-editor-container">
              <div className="viz-editor">
                <CodeEditor
                  onRunButtonClick={handleRunButtonClick}
                  experiments={experimentsData?.experiments || []}
                  totalExperiments={experimentsData?.total || 0}
                  queryBuilderId={queryBuilderId}
                  queryBuilderIdRevision={queryBuilderIdRevision}
                />
                <div className="viz-editor-right">
                  <CodePreview
                    projectId={projectId}
                    iframeUrl={isPy && customPanelServerUrl}
                    templateScopeType={templateScopeType}
                    showRunLabel={!isCodeAlreadyRun || codeLanguageJustChanged}
                    errorLabel={errorLabel}
                    srcDoc={isSrcDocNeeded ? srcDoc : ''}
                    showIsLoading={isInitialLoadingServerPythonPanel}
                  />
                  {showConsole && (
                    <CodeConsole templateScopeType={templateScopeType} />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
        <VizEditorFooter
          openSaveModal={openSaveViewModal}
          redirectHandler={handleRedirect}
        />
      </div>
    </div>
  );
};

VizEditor.defaultProps = {
  templateId: ''
};

VizEditor.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  codePreview: PropTypes.object.isRequired,
  templateId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasInternalResourcesBeenFetched: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  isErrorFetchingDataForChart: PropTypes.bool.isRequired,
  latestRevisionId: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  templateName: PropTypes.string.isRequired,
  currentCodeLanguage: PropTypes.string.isRequired,
  queryBuilderId: PropTypes.string.isRequired,
  queryBuilderIdRevision: PropTypes.number.isRequired,
  srcDoc: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
  const getVisualizationTemplate = makeGetVisualizationTemplate(NEW_TEMPLATE);
  const getLatestRevisionId = makeGetLatestRevisonId();

  return (state, props) => {
    const { codeTemplateId, revisionId } = props.match.params;
    const templateId = getTemplateIdFromURL(codeTemplateId);

    return {
      canEdit: isUserAllowedToEditProject(state),
      codePreview: getCodePreview(state),
      templateId,
      currentTemplate: getVisualizationTemplate(state, {
        revisionId,
        templateId
      }),
      projectId: getSelectedProjectId(state),
      hasInternalResourcesBeenFetched: hasInternalResourcesBeenFetched(state),
      isErrorFetchingDataForChart: isErrorFetchingDataForChart(state, {
        chartId: templateId
      }),
      latestRevisionId: getLatestRevisionId(state, {
        templateId
      }),
      templateName: getTemplateName(state, { templateId, revisionId }),
      queryBuilderId: selectTemplateQueryBuilderId(state, {
        templateId,
        revisionId
      }),
      queryBuilderIdRevision: selectQueryBuilderIdRevision(state),
      currentCodeLanguage: getCodeLanguagePreview(state)
    };
  };
};

export default withRouter(connect(makeMapStateToProps)(VizEditor));
