import React, { useState } from 'react';
import { components, GroupProps } from 'react-select';
import { OptionType } from '../../../../types';
import styles from '../Select.module.scss';

const Group = (props: GroupProps<OptionType>) => {
  const groupData = props.data as OptionType;
  const [groupIsExpanded, setGroupIsExpanded] = useState(groupData.isExpanded);
  const isNotCollapsable = groupIsExpanded === undefined;

  const headingProps = {
    ...props.headingProps,
    data: {
      ...props.headingProps.data,
      isExpanded: groupIsExpanded
    },
    onToggleExpand: () => {
      if (isNotCollapsable) return;

      setGroupIsExpanded(v => !v);
    }
  };

  const isChildrenVisible = isNotCollapsable || groupIsExpanded;

  return (
    <components.Group
      {...props}
      className={styles.groupListContainer}
      headingProps={headingProps}
    >
      {isChildrenVisible && props.children}
    </components.Group>
  );
};

export default Group;
