import { getNumOfDaysUntilTimestamp } from './generalHelpers';

export const findDefaultUserOrganization = organizations => {
  const defaultOrganization = organizations?.find(
    organization => organization.default
  );

  if (defaultOrganization) {
    return defaultOrganization;
  }

  return organizations[0];
};

export const getTrialInEndsLabel = timestampWhenTrialEnds => {
  const numOfDaysToRemind =
    getNumOfDaysUntilTimestamp(timestampWhenTrialEnds) + 1;

  if (numOfDaysToRemind > 0) {
    const daysText = `day${numOfDaysToRemind > 1 ? 's' : ''}`;

    return `${numOfDaysToRemind} ${daysText}`;
  }

  return '';
};
