// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurvesPanelDataTab-module__tab--mUodV{display:flex;flex-direction:column;gap:48px}.CurvesPanelDataTab-module__section--oeCq9{display:flex;flex-direction:column;gap:4px;color:var(--gray-6)}.CurvesPanelDataTab-module__slider--qnFnJ{margin-left:6px}.CurvesPanelDataTab-module__sliderSuffix--oo6YZ{display:flex;justify-content:flex-end;min-width:80px;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `CurvesPanelDataTab-module__tab--mUodV`,
	"section": `CurvesPanelDataTab-module__section--oeCq9`,
	"slider": `CurvesPanelDataTab-module__slider--qnFnJ`,
	"sliderSuffix": `CurvesPanelDataTab-module__sliderSuffix--oo6YZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
