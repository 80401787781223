import React, { MouseEvent } from 'react';
import cx from 'classnames';
import classes from './ListComponents.module.scss';
import { useListItemContext } from '../useListItemContext';

import RadioButton from '@ds/controllers/Radio/RadioButton';
import type { RadioButtonProps } from '@ds/controllers/Radio/RadioButton';

export type ListRadioButtonProps = RadioButtonProps;

const preventPropagation = (e: MouseEvent<HTMLInputElement>) => {
  e?.stopPropagation();
};
const ListRadioButton = (props: ListRadioButtonProps) => {
  const { disabled, active, isWithSecondaryText } = useListItemContext();

  return (
    <div
      className={cx(classes.radioContainer, classes.prefixElementContainer, {
        [classes.moreTopRadioButtonMargin]: isWithSecondaryText
      })}
    >
      <RadioButton
        disabled={disabled}
        checked={active}
        onClick={preventPropagation}
        {...props}
      />
    </div>
  );
};

export default ListRadioButton;
