import { useState, useCallback, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';

import {
  TooltipData,
  TooltipDataPoint,
  TooltipPosition
} from '@experiment-management-shared/types';
import {
  BUILT_IN_CHART_TYPES,
  TOOLTIP_WAIT_TIMEOUT_FOR_RESET
} from '@experiment-management-shared/constants/chartConstants';
import useChartTooltipTrackEvent from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartTooltipTrackEvent';

const CLICKABLE_DISTANCE = 20;

const pointHasChanged = (
  point: TooltipDataPoint | undefined,
  prevPoint: TooltipDataPoint | undefined
) => {
  return point !== prevPoint;
};

const normalizePoints = (
  event: MouseEvent,
  selectedPoints: TooltipDataPoint[]
) => {
  const { offsetX } = event;

  const xDistances = selectedPoints.map(point => {
    const x = (point.bbox.x1 + point.bbox.x0) / 2;
    return Math.abs(x - offsetX);
  });

  const minXDistance = Math.min(...xDistances);
  const pointIndex = xDistances.findIndex(
    xDistance => xDistance === minXDistance
  );

  return selectedPoints.filter(
    p =>
      p.x === selectedPoints[pointIndex].x &&
      selectedPoints[pointIndex].data?.cometMetadata
  );
};

const getClosestYPoint = (
  event: MouseEvent,
  selectedPoints: TooltipDataPoint[]
) => {
  const { offsetY } = event;

  const yDistances = selectedPoints.map(point => {
    const y = (point.bbox.y1 + point.bbox.y0) / 2;
    return Math.abs(y - offsetY);
  });

  const minYDistance = Math.min(...yDistances);
  const pointIndex = yDistances.findIndex(
    (yDistance: number) => yDistance === minYDistance
  );

  return selectedPoints[pointIndex];
};

const calculateIsClickable = (
  event: MouseEvent,
  point: TooltipDataPoint | undefined
) => {
  if (!point) return false;
  const { offsetY, offsetX } = event;

  return (
    Math.hypot(
      offsetX - (point.bbox.x0 + point.bbox.x1) / 2,
      offsetY - (point.bbox.y0 + point.bbox.y1) / 2
    ) <= CLICKABLE_DISTANCE
  );
};

const calculateOffsetYBoundaries = (event: MouseEvent) => {
  let max = 1000;

  const chartElement = event.target;

  if (chartElement) {
    max = parseInt(
      (chartElement as SVGRectElement).getAttribute('height') || '0'
    );
  }

  return { min: 0, max };
};

export const useCurvesChartTooltip = () => {
  const { interact, stop } = useChartTooltipTrackEvent();
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
  const pointsRef = useRef<TooltipDataPoint[]>([]);
  const currentPointRef = useRef<TooltipDataPoint>();
  const clickableRef = useRef<boolean>(false);

  const processHover = useCallback(
    (point, boundaries) => {
      if (!point) return;
      const { yaxis, x, y } = point;
      const minYCoord = boundaries.min + yaxis._offset;
      const maxYCoord = boundaries.max + yaxis._offset;
      const xCoord = point.bbox.x0 + (point.bbox.x1 - point.bbox.x0) / 2;
      const yCoord = point.bbox.y0 + (point.bbox.y1 - point.bbox.y0) / 2;
      const color = point.data.line?.color;
      const position: TooltipPosition = { left: xCoord, top: yCoord };

      let normalizedPosition: TooltipPosition | undefined = undefined;
      if (yCoord > maxYCoord) {
        normalizedPosition = {
          left: xCoord,
          top: maxYCoord
        };
      } else if (yCoord < minYCoord) {
        normalizedPosition = {
          left: xCoord,
          top: minYCoord
        };
      }

      interact(BUILT_IN_CHART_TYPES.curves);
      setTooltipData({
        x,
        y,
        color,
        point,
        position,
        normalizedPosition
      });
    },
    [interact]
  );

  const resetTooltip = useMemo(() => {
    return debounce(() => {
      stop();
      setTooltipData(null);
      pointsRef.current = [];
      currentPointRef.current = undefined;
    }, TOOLTIP_WAIT_TIMEOUT_FOR_RESET);
  }, [stop]);

  const handleLinePointHover = useCallback(
    eventData => {
      resetTooltip.cancel();
      pointsRef.current = normalizePoints(eventData.event, eventData.points);

      const point = getClosestYPoint(eventData.event, pointsRef.current);

      if (pointHasChanged(point, currentPointRef.current)) {
        currentPointRef.current = point;
        processHover(point, calculateOffsetYBoundaries(eventData.event));
      }

      clickableRef.current = calculateIsClickable(
        eventData.event,
        currentPointRef.current
      );
    },
    [processHover, resetTooltip]
  );

  const handleLinePointBeforeHover = useCallback(
    event => {
      if (pointsRef.current.length === 0) return;
      const point = getClosestYPoint(event, pointsRef.current);

      if (pointHasChanged(point, currentPointRef.current)) {
        currentPointRef.current = point;
        processHover(point, calculateOffsetYBoundaries(event));
      }

      clickableRef.current = calculateIsClickable(
        event,
        currentPointRef.current
      );
    },
    [processHover]
  );

  const handleLinePointUnHover = useCallback(() => {
    resetTooltip();
  }, [resetTooltip]);

  return {
    handleLinePointHover,
    handleLinePointBeforeHover,
    handleLinePointUnHover,
    clickableRef,
    tooltipData
  };
};
