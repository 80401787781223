import { filesActionTypes } from '../constants/actionTypes';

const filesReducer = (state = {}, action) => {
  switch (action.type) {
    case filesActionTypes.REQUEST_FILES_FOR_PROJECT:
      return { ...state, loading: true };

    case filesActionTypes.RECEIVE_FILES_FOR_PROJECT:
      return { ...state, loading: false, files: action.files };

    default:
      return state;
  }
};

export default filesReducer;
