import { useEffect, useState } from 'react';
import noop from 'lodash/noop';

const DEFAULT_DEBOUNCE_DELAY = 300;

const useDebounce = ({
  delay = DEFAULT_DEBOUNCE_DELAY,
  onDebounceChange = noop,
  value: initialValue
}) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    onDebounceChange(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(timeoutId);
  }, [delay, value]);

  return [value, setValue, debouncedValue];
};

export default useDebounce;
