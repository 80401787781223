import { Button } from '@ds';
import { NEW_REPORT_NAME } from '@shared/constants';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import { reportRoute } from '@routes/utils/reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DSNotesIcon } from '@ds-icons';

const NewReportButton = ({ canEdit }) => {
  const { projectName, workspace } = useParams();

  const NEW_REPORT_URL = reportRoute({
    editMode: REPORT_EDIT_MODES.EDIT,
    projectName,
    reportName: NEW_REPORT_NAME,
    workspaceName: workspace
  });

  return (
    <Link to={NEW_REPORT_URL}>
      <Button disabled={!canEdit} PrefixIcon={<DSNotesIcon />}>
        New Report
      </Button>
    </Link>
  );
};

NewReportButton.propTypes = {
  canEdit: PropTypes.bool.isRequired
};

export default NewReportButton;
