import workspaceActions from '@/actions/workspaceActions';
import { sortArrayOfObjectsByKey } from '@/helpers/generalHelpers';
import { useWorkspaces } from '@shared/hooks';
import { useDispatch } from 'react-redux';

const useWorkspacesSearch = () => {
  const dispatch = useDispatch();
  const { data: workspaces = [] } = useWorkspaces();

  const onSelectWorkspace = (workspaceName: string) => {
    dispatch(workspaceActions.switchTeam(workspaceName));
  };

  const workspaceList = sortArrayOfObjectsByKey(workspaces, 'name');

  return {
    workspaceList,
    onSelectWorkspace
  };
};

export default useWorkspacesSearch;
