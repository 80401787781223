import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { DSColorSelectionIcon } from '@ds-icons';
import { IconButton, Tooltip } from '@ds';

import ChangeColorModal from './ChangeColorModal';

const ChangeColorButton = ({ disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <>
      <Tooltip content="Change colors" placement="top">
        <IconButton
          onClick={openModal}
          Icon={<DSColorSelectionIcon />}
          type="secondary"
          disabled={disabled}
        />
      </Tooltip>
      <ChangeColorModal open={isModalOpen} onClose={closeModal} />
    </>
  );
};

ChangeColorButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default ChangeColorButton;
