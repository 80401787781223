import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { IconButton } from '@ds';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import './Pagination.scss';
import {
  DSArrowLeftIcon,
  DSArrowLeftSecondaryIcon,
  DSArrowRightIcon,
  DSArrowRightSecondaryIcon
} from '@ds-icons';

const StyledSelect = withStyles({
  icon: {
    color: '#5155F5',
    height: 20,
    width: 20
  },
  root: {
    color: '#5155F5',
    fontSize: 14
  }
})(Select);

const Pagination = ({
  currentPage,
  onCurrentPageChange,
  onPageSizeChange,
  pageSize,
  pageSizes,
  customStyle,
  totalCount,
  totalPages
}) => {
  const from = pageSize * currentPage + 1;
  const to = Math.min(pageSize * (currentPage + 1), totalCount);

  const selectPageSize = (
    <StyledSelect
      disableUnderline
      onChange={event => onPageSizeChange(event.target.value)}
      renderValue={() => `${from}-${to}`}
      value={pageSize}
    >
      {pageSizes.map(size => (
        <MenuItem key={size} value={size}>
          {size}
        </MenuItem>
      ))}
    </StyledSelect>
  );

  if (totalCount === 0) return null;

  return (
    <div
      className="pagination-wrapper"
      data-test="pagination"
      style={{ ...(customStyle.wrapper || {}) }}
    >
      <div style={customStyle} className="ds-react-grid-pagination">
        <div className="react-grid-pagination-buttons">
          <IconButton
            Icon={<DSArrowLeftSecondaryIcon />}
            aria-label="first-page"
            disabled={currentPage === 0}
            onClick={() => onCurrentPageChange(0)}
          />

          <IconButton
            Icon={<DSArrowLeftIcon />}
            aria-label="previous-page"
            disabled={currentPage === 0}
            onClick={() => onCurrentPageChange(currentPage - 1)}
            data-test="prev-pagination-btn"
          />
        </div>

        <div className="react-grid-pagination-page-info">
          <span>Showing</span>
          {selectPageSize}
          <span>of {totalCount}</span>
        </div>

        <div className="react-grid-pagination-buttons">
          <IconButton
            Icon={<DSArrowRightIcon />}
            aria-label="next-page"
            disabled={currentPage === totalPages - 1}
            onClick={() => onCurrentPageChange(currentPage + 1)}
            data-test="right-pagination-button"
          />

          <IconButton
            Icon={<DSArrowRightSecondaryIcon />}
            aria-label="last-page"
            disabled={currentPage === totalPages - 1}
            onClick={() => onCurrentPageChange(totalPages - 1)}
          />
        </div>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  customStyle: noop
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  customStyle: PropTypes.any,
  onCurrentPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizes: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default Pagination;
