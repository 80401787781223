import React from 'react';
import cx from 'classnames';
import styles from './TextChunksHighlight.module.scss';

function sanitizeString(regex) {
  return regex.replace(/[#-.]|[[-^]|[?{}]/g, '\\$&');
}

// even with hidden the text overflow produces performance issues
// better to truncate the text that we put in the DOM
const MAX_TEXT_LENGTH = 5_000;

export function TextChunksHighlight({
  children: text = '',
  chunks = [],
  className,
  enabled = true
}) {
  const truncatedText = text.substring(0, MAX_TEXT_LENGTH);
  if (!chunks?.length || !enabled) {
    return <span className={className}>{truncatedText}</span>;
  }

  const matches = [
    ...truncatedText.matchAll(
      new RegExp(sanitizeString(chunks.join('|')), 'ig')
    )
  ];
  const startText = truncatedText.slice(0, matches[0]?.index);

  return (
    <span className={cx(className, styles.textHighlightContainer)}>
      {startText}
      {matches.map((match, i) => {
        const startIndex = match.index;
        const currentText = match[0];
        const endIndex = startIndex + currentText.length;
        const nextIndex = matches[i + 1]?.index;
        const untilNextText = truncatedText.slice(endIndex, nextIndex);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>
            <mark>{currentText}</mark>
            {untilNextText}
          </span>
        );
      })}
    </span>
  );
}
