import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';

import debounce from 'lodash/debounce';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import Histogram3DChart from '@experiment-management-shared/components/Charts/PlotlyChart/Histogram3DChart';

const HIDE_MENU_ITEMS = ['edit-chart', 'embed-panel', 'share-panel'];

const Histogram = ({
  assetId: chartId,
  experimentKey,
  fileName,
  ignoreOutliers,
  maxSteps,
  projectId,
  experiments,
  handleDeleteHistogram
}) => {
  const [wasVisible, setWasVisible] = useState(false);
  const [ref, inView] = useInView();

  const changeToVisible = useCallback(
    debounce(() => setWasVisible(true), 300),
    []
  );

  useEffect(() => {
    if (inView) {
      changeToVisible();
    } else {
      changeToVisible.cancel();
    }
  }, [inView, changeToVisible]);

  const isAutoRefreshEnabled =
    experiments.find(e => e.experimentKey === experimentKey)?.runActive ||
    false;

  const containerProps = useMemo(
    () => ({
      chartId,
      chartType: 'histogram3d',
      experimentKeys: [experimentKey],
      onDeleteChart: handleDeleteHistogram,
      deleteConfig: { chartId },
      projectId
    }),
    [chartId, experimentKey, handleDeleteHistogram, projectId]
  );

  if (!wasVisible) {
    return <div ref={ref} style={{ height: 500 }} />;
  }

  return (
    <Histogram3DChart
      containerProps={containerProps}
      hiddenMenuItems={HIDE_MENU_ITEMS}
      ignoreOutliers={ignoreOutliers}
      maxSteps={maxSteps}
      isAutoRefreshEnabled={isAutoRefreshEnabled}
      title={fileName}
    />
  );
};

Histogram.defaultProps = {
  assetId: '',
  fileName: ''
};

Histogram.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  assetId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  experimentKey: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  ignoreOutliers: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  handleDeleteHistogram: PropTypes.func.isRequired
};

export default Histogram;
