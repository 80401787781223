import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { castValue } from '@API/experiments/utils';
import { QuickStartGuide } from '@onboarding';

import ModelRegistryCard from '@model-registry/components/ModelRegistryPage/ModelRegistryPage';
import WorkspacePage from '@platform/components/WorkspacePage';
import PrivateRoute from './PrivateRoute';
import ProjectRoutes from './ProjectRoutes';
import { SUB_PATHS } from '@/constants/urlConstants';
import ArtifactRoutes from './ArtifactRoutes';
import ModelProductionMonitoringRoutes from './ModelProductionMonitoringRoutes';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import {
  BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL,
  BETA_FEATURE_MODEL_REGISTRY_CARD_DETAILS_V2,
  BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID
} from '@/lib/betaFeatures';
import workspaceActions from '@/actions/workspaceActions';
import organizationsActions from '@/actions/organizationsActions';
import mpmActions from '@/actions/mpmActions';
import {
  useActiveWorkspaceName,
  useCurrentOrganization,
  useAllUserWorkspaces,
  useIsViewOnlyUser
} from '@shared/hooks';

import { getIsUserLoggedIn } from '@/reducers/userReducer';
import ModelRegistryDetails from '@model-registry/components/ModelRegistryDetails';

const WorkspaceRoutes = ({
  isBetaModelRegistryDetailsPageEnabled,
  isMPMInternalEnabled,
  isMPMDruidEnabled,
  dispatch,
  match
}) => {
  const { workspace } = match.params;
  const { data: workspaces } = useAllUserWorkspaces();
  const currentActiveWorkspaceName = useActiveWorkspaceName();
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const currentOrganization = useCurrentOrganization();
  const isViewOnlyUser = useIsViewOnlyUser();

  useEffect(() => {
    if (
      currentActiveWorkspaceName !== workspace &&
      castValue(workspace) &&
      (workspaces?.length || !isUserLoggedIn)
    ) {
      dispatch(workspaceActions.setActiveWorkspaceName(workspace));
      // tryout to find a matching organization
      const userWorkspace = workspaces?.find(wp => wp.name === workspace);

      // if it's the part of the user list
      if (userWorkspace?.organizationId) {
        dispatch(
          organizationsActions.setActiveOrganization(
            userWorkspace.organizationId
          )
        );
      }
    }
  }, [currentActiveWorkspaceName, dispatch, workspace, workspaces]);

  useEffect(() => {
    if (currentActiveWorkspaceName && isMPMInternalEnabled) {
      dispatch(mpmActions.fetchModelsCount(isMPMDruidEnabled));
    }
  }, [
    currentActiveWorkspaceName,
    isMPMInternalEnabled,
    isMPMDruidEnabled,
    dispatch
  ]);

  return (
    <>
      <Switch>
        <Route path={match.path} exact component={WorkspacePage} />
        {isBetaModelRegistryDetailsPageEnabled ? (
          <Route
            path={`${match.path}/${SUB_PATHS.MODEL_REGISTRY}/:modelName/:modelVersion`}
            exact
            component={ModelRegistryDetails}
          />
        ) : null}

        <Route
          path={`${match.path}/${SUB_PATHS.MODEL_REGISTRY}/:modelName`}
          exact
          component={ModelRegistryCard}
        />

        <Route
          path={`${match.path}/${SUB_PATHS.ARTIFACTS}`}
          component={ArtifactRoutes}
        />

        {isMPMInternalEnabled && (
          <Route
            path={`${match.path}/${SUB_PATHS.MODEL_PRODUCTION_MONITORING}`}
            component={ModelProductionMonitoringRoutes}
          />
        )}
        {!isViewOnlyUser && (
          <PrivateRoute
            path={`${match.path}/quick-start`}
            exact
            render={() =>
              currentOrganization?.id ? <QuickStartGuide /> : null
            }
          />
        )}
        <Route path={`${match.path}/:projectName`} component={ProjectRoutes} />
      </Switch>
    </>
  );
};

WorkspaceRoutes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isBetaModelRegistryDetailsPageEnabled: PropTypes.bool.isRequired,
  isMPMInternalEnabled: PropTypes.bool.isRequired,
  isMPMDruidEnabled: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isMPMInternalEnabled: isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL
  }),
  isBetaModelRegistryDetailsPageEnabled: isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_MODEL_REGISTRY_CARD_DETAILS_V2
  }),
  isMPMDruidEnabled: isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID
  }),
  username: state.user.username
});

export default withRouter(connect(mapStateToProps)(WorkspaceRoutes));
