import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import {
  BUILT_IN_CHART_TYPES,
  DEFAULT_CUSTOM_RANGE,
  UNSET_CUSTOM_RANGE
} from '@experiment-management-shared/constants/chartConstants';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import { TextInput, ToggleButton } from '@ds';

import styles from './GenericVizAxisTab.module.scss';

const DEBOUNCED_RANGE = 200;

function GenericVizAxisTab({ chartType }) {
  const dispatch = useDispatch();

  const chartForm = useSelector(state =>
    getChartFormByType(state, { chartType })
  );

  const showTickSwitch =
    chartType === BUILT_IN_CHART_TYPES['BuiltIn/Line'] ||
    chartType === BUILT_IN_CHART_TYPES['BuiltIn/Scatter'] ||
    chartType === BUILT_IN_CHART_TYPES.curves;
  const customXAxisTitle = get(chartForm, 'customXAxisTitle', '');
  const customYAxisTitle = get(chartForm, 'customYAxisTitle', '');
  const showXAxisTitle = get(chartForm, 'showXAxisTitle', false);
  const showYAxisTitle = get(chartForm, 'showYAxisTitle', false);
  const showXAxisTickLabels = get(chartForm, 'showXAxisTickLabels', false);
  const showYAxisTickLabels = get(chartForm, 'showYAxisTickLabels', false);
  const customRange = get(chartForm, 'customRange', DEFAULT_CUSTOM_RANGE);
  const [rangeX, setRangeX] = useState(customRange?.x ?? UNSET_CUSTOM_RANGE);
  const [rangeY, setRangeY] = useState(customRange?.y ?? UNSET_CUSTOM_RANGE);

  const {
    xAxisTitlePlaceholder,
    yAxisTitlePlaceholder
  } = chartHelpers.calculateAxisTitlePlaceholder(chartType, chartForm);

  useEffect(() => {
    setRangeX(customRange?.x ?? UNSET_CUSTOM_RANGE);
    setRangeY(customRange?.y ?? UNSET_CUSTOM_RANGE);
  }, [customRange]);

  const debouncedRangeUpdate = useCallback(
    debounce((axisType, type, newValue) => {
      const update = {
        ...customRange,
        [axisType]: {
          ...customRange[axisType],
          [type]: newValue
        }
      };

      dispatch(
        dashboardChartsActions.updateChartFormKey(
          chartType,
          'customRange',
          update
        )
      );
    }, DEBOUNCED_RANGE),
    [customRange, dispatch]
  );

  const generateToggleButton = (title, value, property) => {
    const id = `toggle-id-${property}`;
    return (
      <div className={cx(styles.toggleContainer, property)}>
        <ToggleButton
          id={id}
          checked={value}
          onChange={() =>
            dispatch(
              dashboardChartsActions.updateChartFormKey(
                chartType,
                property,
                !value
              )
            )
          }
          color="primary"
        />
        <label className={styles.label} htmlFor={id}>
          {title}
        </label>
      </div>
    );
  };

  const generateTitleInput = (value, property, placeholder) => {
    const id = `title-id-${property}`;

    return (
      <div className={styles.titleContainer}>
        <label className={cx(styles.label, styles.input)} htmlFor={id}>
          Custom label
        </label>
        <TextInput
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={event => {
            dispatch(
              dashboardChartsActions.updateChartFormKey(
                chartType,
                property,
                event.target.value
              )
            );
          }}
        />
      </div>
    );
  };

  const generateCustomRange = (value, property, setter) => {
    const minId = `range-id-min-${property}`;
    const maxId = `range-id-max-${property}`;

    return (
      <div className={styles.customRangeContainer}>
        <label className={cx(styles.label, styles.input)}>Custom range</label>
        <div className={styles.customRange}>
          <div className={styles.inputWrapper}>
            <label htmlFor={minId}>Min</label>
            <TextInput
              id={minId}
              value={value.min}
              onChange={event => {
                const min = event.target.value;
                setter(state => ({
                  ...state,
                  min
                }));
                debouncedRangeUpdate(property, 'min', min);
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <label htmlFor={maxId}>Max</label>
            <TextInput
              id={maxId}
              value={value.max}
              onChange={event => {
                const max = event.target.value;
                setter(state => ({
                  ...state,
                  max
                }));
                debouncedRangeUpdate(property, 'max', max);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.axisTitle}>X-AXIS</div>
      {generateToggleButton('Label', showXAxisTitle, 'showXAxisTitle')}
      {showXAxisTitle
        ? generateTitleInput(
            customXAxisTitle,
            'customXAxisTitle',
            xAxisTitlePlaceholder
          )
        : null}
      {showTickSwitch &&
        generateToggleButton(
          'Tick Labels',
          showXAxisTickLabels,
          'showXAxisTickLabels'
        )}
      {generateCustomRange(rangeX, 'x', setRangeX)}
      <div className={styles.axisTitle}>Y-AXIS</div>
      {generateToggleButton('Label', showYAxisTitle, 'showYAxisTitle')}
      {showYAxisTitle
        ? generateTitleInput(
            customYAxisTitle,
            'customYAxisTitle',
            yAxisTitlePlaceholder
          )
        : null}
      {showTickSwitch &&
        generateToggleButton(
          'Tick Labels',
          showYAxisTickLabels,
          'showYAxisTickLabels'
        )}
      {generateCustomRange(rangeY, 'y', setRangeY)}
    </div>
  );
}

GenericVizAxisTab.propTypes = {
  chartType: PropTypes.string
};

export default GenericVizAxisTab;
