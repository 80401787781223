export const FREE_PRICE = 'Free';

export const PLAN_STARTUP = 'startup_workspace';
export const PLAN_TEAMS = 'teams_workspace';
export const PLAN_TEAMS_PRO = 'pro_workspace';
export const PLAN_ENTERPRISE = 'enterprise_workspace';

export const ORGANIZATION_PLAN_COMMUNITY = 'individual_organization';
export const ORGANIZATION_PLAN_STARTER = 'starter_organization';
export const ORGANIZATION_PLAN_ENTERPRISE = 'enterprise_organization';

// THESE PLANS INCLUDE BOTH ORGANIZATION AND WORKSPACE
export const PAYMENT_PLANS = {
  [FREE_PRICE]: null,
  [PLAN_ENTERPRISE]: {
    priority: 3,
    prev: ORGANIZATION_PLAN_STARTER,
    plan: PLAN_ENTERPRISE,
    displayName: 'Enterprise',
    name: 'Enterprise',
    price: null,
    bullets: [
      'Unlimited Private and Public Projects',
      `Unlimited Teams and Members, Single Sign-On Enabled`,
      'Flexible Deployment – On-premises, VPC or Cloud',
      'Model Management',
      'Dedicated MLOps Support',
      'Phone, Email and Dedicated Slack Channel',
      'Support and Services SLAs',
      'Hyperparameter Search',
      'Artifacts'
    ],
    label: 'Unlimited training hours',
    priceLabel: 'Contact Us',
    color: '#5154C3',
    hourPrice: 0
  },
  // ORGANIZATIONS
  [ORGANIZATION_PLAN_COMMUNITY]: {
    priority: 0,
    next: ORGANIZATION_PLAN_STARTER,
    plan: ORGANIZATION_PLAN_COMMUNITY,
    displayName: 'Community',
    name: 'Community',
    price: 0,
    bullets: [
      'Unlimited Private and Public Projects',
      '1 Member',
      'Rest API Access',
      'Community Slack Support',
      '100GB Storage Credits',
      'Hyperparameter Search',
      'Artifacts'
    ],
    label: 'Free forever',
    description: 'Personal use only',
    priceLabel: '$0',
    priceDescription: 'free plan',
    color: '#FFB42E',
    hourPrice: 0,
    cta: 'Upgrade to Add Collaborators'
  },
  [ORGANIZATION_PLAN_STARTER]: {
    priority: 2,
    next: ORGANIZATION_PLAN_STARTER,
    plan: ORGANIZATION_PLAN_STARTER,
    displayName: 'Starter',
    name: 'Starter',
    price: 50,
    bullets: [
      'Up To 10 Members',
      'Unlimited Private and Public Projects',
      'Model Registry',
      'API Account',
      'Dedicated Slack Channel (Guaranteed SLA)',
      '500GB Storage Credits',
      'Hyperparameter Search',
      'Artifacts'
    ],
    label: '5,000 annual organization training hours',
    description: ' $1 / training hour beyond',
    priceLabel: '$50',
    priceDescription: 'per user / month',
    color: '#52AEA4',
    hourPrice: 1
  },
  [ORGANIZATION_PLAN_ENTERPRISE]: {
    priority: 3,
    plan: PLAN_ENTERPRISE,
    displayName: 'Enterprise',
    name: 'Enterprise',
    price: null,
    bullets: [
      'Unlimited Private and Public Projects',
      `Unlimited Teams and Members, Single Sign-On Enabled`,
      'Flexible Deployment – On-premises, VPC or Cloud',
      'Model Management',
      'Dedicated MLOps Support',
      'Phone, Email and Dedicated Slack Channel',
      'Support and Services SLAs',
      'Hyperparameter Search',
      'Artifacts'
    ],
    label: 'Unlimited training hours',
    priceLabel: 'Contact Us',
    color: '#5154C3',
    hourPrice: 1
  },
  // the old workspaces
  [PLAN_STARTUP]: {
    priority: 1,
    plan: PLAN_STARTUP,
    displayName: 'Startup Workspace',
    name: 'Startup Workspace',
    price: 39,
    bullets: [
      'Unlimited public & private projects',
      'Bayesian hyperparameter optimization',
      'REST API Access'
    ],
    priceLabel: '$39',
    priceDescription: 'per user / month',
    hourPrice: 0,
    color: '#52AEA4'
  },
  [PLAN_TEAMS]: {
    priority: 1,
    next: PLAN_TEAMS_PRO,
    plan: PLAN_TEAMS,
    displayName: 'Teams Workspace',
    displayNameAcademic: 'Teams - Academic',
    name: 'teams',
    price: 179,
    bullets: [
      'Unlimited Private and Public Projects',
      'Up To 5 Members',
      'REST API Access',
      'Community Slack and Email Support',
      '250GB Storage Credits',
      'Hyperparameter Search',
      'Artifacts'
    ],
    priceLabel: '$179',
    priceDescription: 'per user / month',
    color: '#1ab1a4',
    hourPrice: 0
  },
  [PLAN_TEAMS_PRO]: {
    priority: 2,
    prev: PLAN_TEAMS,
    next: PLAN_TEAMS_PRO,
    plan: PLAN_TEAMS_PRO,
    displayName: 'Teams Pro Workspace',
    displayNameAcademic: 'Teams - Academic',
    name: 'teams pro',
    price: 249,
    bullets: [
      'Unlimited Private and Public Projects',
      'Up To 5 Members',
      'Rest API Access',
      'Model Management',
      'API Account',
      'Dedicated Slack Channel (Guaranteed SLA)',
      '500GB Storage Credits',
      'Hyperparameter Search',
      'Artifacts'
    ],
    priceLabel: '$249',
    priceDescription: 'per user / month',
    color: '#ff594c',
    hourPrice: 0
  }
};

export const PLAN_DISPLAY_NAME = {
  [PLAN_STARTUP]: {
    display: 'Startup Workspace',
    name: 'startup',
    plan: PLAN_STARTUP
  },
  [PLAN_TEAMS]: {
    display: 'Teams Workspace',
    name: 'teams',
    plan: PLAN_TEAMS
  },
  [PLAN_TEAMS_PRO]: {
    display: 'Teams Pro Workspace',
    name: 'pro',
    plan: PLAN_TEAMS_PRO
  },
  [PLAN_ENTERPRISE]: {
    display: 'Enterprise Workspace',
    name: 'enterprise',
    plan: PLAN_ENTERPRISE
  },
  [ORGANIZATION_PLAN_STARTER]: {
    display: 'Starter',
    name: 'Starter',
    plan: ORGANIZATION_PLAN_STARTER
  }
};

export const FREE_PLANS = [ORGANIZATION_PLAN_COMMUNITY];

export const PAID_PLANS = [
  PLAN_DISPLAY_NAME[PLAN_STARTUP].plan,
  PLAN_DISPLAY_NAME[PLAN_TEAMS].plan,
  PLAN_DISPLAY_NAME[PLAN_TEAMS_PRO].plan,
  PLAN_DISPLAY_NAME[PLAN_ENTERPRISE].plan,
  PLAN_DISPLAY_NAME[ORGANIZATION_PLAN_STARTER].plan
];
