import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import { snackbarTypes } from '../../../../constants/alertTypes';
import alertsUtil from '../../../../util/alertsUtil';

const updateMemberRole = ({ username, organizationId, role }) =>
  organizationApi.setMemberRole({ username, organizationId, role });

const useUpdateMemberRoleMutation = organizationId => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const queryKey = ['organizations', organizationId, 'members'];

  return useMutation(
    ({ username, role }) =>
      updateMemberRole({ username, role, organizationId }),
    {
      onMutate: async updatedMember => {
        await queryClient.cancelQueries(queryKey, { active: true });

        const previousMembers = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, oldMembers => {
          return oldMembers.map(member => {
            if (member.username === updatedMember.username) {
              return { ...member, ...updatedMember };
            }

            return member;
          });
        });

        return { previousMembers };
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(queryKey, { active: true });
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(queryKey, context.previousMembers);

        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_PAYMENT_CHANGED,
            err?.response?.data?.msg || err?.message
          )
        );
      }
    }
  );
};

export default useUpdateMemberRoleMutation;
