import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { matchPath, useLocation } from 'react-router';

import Snackbar from '@design-system-outdated/components/Snackbar';
import {
  MPM_LINE_CHART_TYPES,
  PANEL_NAMES,
  CHART_TYPES,
  TOOLTIP_FEATURE_VIEW_SECTION,
  PREFIX_MPM_PATH
} from '@mpm/constants';
import { usePanelResize } from '@/DesignSystem/hooks';
import { useTooltip } from '@mpm/hooks';
import {
  CHART_BASIC_CONFIG,
  CHART_BASIC_LAYOUT,
  makeLineChartNotifShape,
  makeLineChartTooltipShape,
  makeTriangleTooltipShape,
  addHoverLinesOpacity,
  formatToLocal
} from '@mpm/utils';
import { DisparateValueBox } from '../DisparateValueBox';
import OptimizedPlot from '@DesignSystem/charts/OptimizedPlot';
import { ChartTooltip, Indicator } from '../ChartTooltip';
import EmptyStatePreviewBox from '../EmptyStateChartBox/EmptyStatePreviewBox';
import UnhandledSegmentsChartBox from '../UnhandledSegmentsChartBox/UnhandledSegmentsChartBox';
import UnhandledSegmentsAccuracyTab from '../UnhandledSegmentsChartBox/UnhandledSegmentsAccuracyTab';
import { NumericalDistributionChart } from '../NumericalDistributionChart/NumericalDistributionChart';
import './LineChart.scss';
import { SUPPORT_EMAIL } from '@/lib/appConstants';

export const LineChart = ({
  data,
  height,
  disableZoom,
  alertNotifications,
  showCustomTooltip,
  emptyStateBoxConfig,
  layoutData,
  percentileMode,
  featureValue,
  section,
  variant,
  fairnessData,
  sensitiveGroup,
  setHoverLineData,
  ...props
}) => {
  const containerRef = useRef();
  const plotRef = useRef();
  const [counter, setCounter] = useState(1);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const { showEmptyStateBox, boxIcon, boxMessage } = emptyStateBoxConfig;

  const {
    tabData,
    panelTitle,
    isLabeled,
    isSegmentsData,
    chartType,
    numericalDistributionData,
    allowedNumericalDistribution,
    from,
    to,
    driftAlgorithm,
    feature,
    numericalLoading
  } = props;
  const showNonSupportedSegments =
    (variant === MPM_LINE_CHART_TYPES.nonSupportedSegments &&
      panelTitle !== PANEL_NAMES.ACCURACY_METRICS) ||
    (panelTitle === PANEL_NAMES.ACCURACY_METRICS &&
      isSegmentsData &&
      isLabeled);

  const isFairnessTimeType = chartType === CHART_TYPES.FAIRNESS_TIME;

  const {
    inspectMode,
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    hideTooltipHandler,
    closeTooltipHandler,
    handleClick
  } = useTooltip(tabData);

  const featureId = useMemo(() => {
    return (
      tabData?.featureId ||
      data.find(feature => feature?.name === tooltipData?.title)?.featureId
    );
  }, [tooltipData, tabData]);

  useEffect(() => {
    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      if (tooltipVisible) {
        const pointData = tooltipData.data.data;
        const hoverInfo = {
          segmentId: pointData?.segmentId,
          xAxis: pointData?.x[tooltipData.data.pointIndex],
          color: tooltipData.color,
          tooltipVisible
        };
        setHoverLineData(prev => {
          return { ...prev, ...hoverInfo };
        });
      } else {
        setHoverLineData({});
      }
    }
  }, [tooltipData, tooltipVisible, chartType, setHoverLineData]);

  usePanelResize(plotRef, containerRef);

  const allYZeroes = useMemo(() => {
    if (data.length && variant === MPM_LINE_CHART_TYPES.default) {
      return data.every(line => line.y.every(val => val === 0));
    }
    return false;
  }, [data, variant]);

  const chartShapes = useMemo(() => {
    const alertShapes =
      alertNotifications?.map(point =>
        makeLineChartNotifShape(formatToLocal(point.createdAt))
      ) || [];
    if (tooltipData?.x && tooltipVisible) {
      return [
        ...alertShapes,
        makeLineChartTooltipShape(tooltipData),
        makeTriangleTooltipShape(tooltipData, allYZeroes)
      ];
    }
    return alertShapes;
  }, [alertNotifications, tooltipData, tooltipVisible, allYZeroes]);

  const { pathname } = useLocation();
  const isPerformancePage = matchPath(
    pathname,
    `${PREFIX_MPM_PATH}/performance`
  )?.isExact;

  const showViewFeatureAction =
    TOOLTIP_FEATURE_VIEW_SECTION.includes(section) && isPerformancePage;

  const chartData = useMemo(() => {
    if (!tooltipVisible || !tooltipData) {
      return data;
    }
    const lineTitle = tooltipData?.title;
    const segmentId = tooltipData?.data?.data?.segmentId;
    return addHoverLinesOpacity(data, lineTitle, segmentId);
  }, [data, tooltipData, tooltipVisible]);

  const layout = useMemo(() => {
    let yAxisProps = {
      ...CHART_BASIC_LAYOUT.yaxis
    };
    if (allYZeroes) {
      yAxisProps = {
        ...CHART_BASIC_LAYOUT.yaxis,
        autorange: false,
        range: [-0.06, 10]
      };
    }
    return {
      ...CHART_BASIC_LAYOUT,
      height,
      shapes: chartShapes,
      spikedistance: -1,
      hoverdistance: -1,
      hovermode: 'closest',
      datarevision: counter,
      yaxis: yAxisProps,
      ...layoutData
    };
  }, [counter, height, chartShapes, inspectMode, layoutData, allYZeroes]);

  const copyClickHandler = () => {
    setIsSnackbarOpen(true);
  };

  useEffect(() => {
    // Effect is required to Redraw the Chart changing layout.shapes from AddAlertPage, percentileMode, featureValue
    setCounter(prevVal => prevVal + 1);
    closeTooltipHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    layoutData.shapes,
    percentileMode,
    featureValue,
    fairnessData,
    sensitiveGroup
  ]);

  useEffect(() => {
    let timer;
    if (isSnackbarOpen) {
      timer = setTimeout(() => setIsSnackbarOpen(false), 700);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isSnackbarOpen]);

  return (
    <div>
      <div
        ref={containerRef}
        className={cx('ds-line-chart-container', {
          'numerical-distribution-chart': allowedNumericalDistribution
        })}
      >
        {showEmptyStateBox && (
          <EmptyStatePreviewBox message={boxMessage} icon={boxIcon} />
        )}
        {showNonSupportedSegments && (
          <Snackbar open={isSnackbarOpen} message={`Copied ${SUPPORT_EMAIL}`} />
        )}
        {showNonSupportedSegments && (
          <UnhandledSegmentsChartBox copyClickHandler={copyClickHandler} />
        )}

        {!isLabeled && panelTitle === PANEL_NAMES.ACCURACY_METRICS && (
          <UnhandledSegmentsAccuracyTab />
        )}
        {tooltipVisible && (
          <>
            <Indicator position={tooltipPosition} color={tooltipData.color} />
            <ChartTooltip
              tooltipData={tooltipData}
              position={tooltipPosition}
              closeHandler={closeTooltipHandler}
              inspectMode={inspectMode}
              orientation={tooltipOrientation}
              section={section}
              driftAlgorithm={driftAlgorithm}
              chartType={chartType}
              featureId={featureId}
              tabData={tabData}
              showViewFeatureAction={showViewFeatureAction}
              percentileMode={percentileMode}
              feature={feature}
            />
          </>
        )}
        <OptimizedPlot
          config={CHART_BASIC_CONFIG}
          data={chartData}
          layout={layout}
          useResizeHandler
          ref={plotRef}
          disableZoom={disableZoom}
          onHover={handleLinePointHover}
          onUnhover={hideTooltipHandler}
          onClick={handleClick}
          layoutProps={layoutData}
        />
        {isFairnessTimeType && (
          <span className="disparte-box-contatiner">
            <DisparateValueBox data={data[0]?.avgValue} />
          </span>
        )}
        {allowedNumericalDistribution && (
          <div className="numerical-distribution-chart-wrapper">
            <NumericalDistributionChart
              numericalDistributionData={numericalDistributionData}
              layoutData={layoutData}
              tabData={tabData}
              outerTooltipData={tooltipData}
              outerTooltipVisible={tooltipVisible}
              from={from}
              to={to}
              featureName={props?.feature.name}
              isLoading={numericalLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

LineChart.defaultProps = {
  data: [],
  height: 'auto',
  disableZoom: false,
  alertNotifications: [],
  showCustomTooltip: true,
  showEmptyStateBox: false,
  layoutData: {},
  emptyStateBoxConfig: {},
  percentileMode: '',
  featureValue: ''
};

LineChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  alertNotifications: PropTypes.array,
  disableZoom: PropTypes.bool,
  showCustomTooltip: PropTypes.bool,
  showEmptyStateBox: PropTypes.bool,
  layoutData: PropTypes.object,
  emptyStateBoxConfig: PropTypes.shape({
    showEmptyStateBox: PropTypes.bool,
    boxIcon: PropTypes.node,
    boxMessage: PropTypes.string
  }),
  section: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  percentileMode: PropTypes.string,
  featureValue: PropTypes.string,
  panelTitle: PropTypes.string.isRequired
};
