import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';

import { Select } from '@DesignSystem/controllers';
import { InfoIcon } from '@Icons-outdated';
import {
  useGetModelRegistryNameById,
  useModelDistributions,
  useModelDistributionSet
} from '../../api';
import { TOOLTIP_MESSAGE } from '../../constants';
import { Tooltip } from '@ds';
import { useActiveWorkspace } from '@shared/hooks';

import './CustomTransformationsPage.scss';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const MODEL_HEALTH = [
  {
    label: 'Accuracy',
    value: 'ACCURACY'
  },
  {
    label: 'Output data drift',
    value: 'OUTPUT_DATA_DRIFT'
  }
];

const DRIFT_CALCULATION_OPTIONS = [
  { groupName: 'Time based', label: 'Time based' },
  {
    label: 'Previous Time Window',
    value: 'prev_time_window',
    disabled: false
  },
  {
    groupName: 'Training distribution',
    label: 'Training distribution',
    disabled: false
  }
];

const RenderTooltip = () => {
  return (
    <Tooltip content={TOOLTIP_MESSAGE} placement="right">
      <span className="icon-wrapper">
        <InfoIcon className="overview-info-icon" />
      </span>
    </Tooltip>
  );
};

type ModelOverviewProps = {
  createdAt: string;
  modelId: string;
  name: string;
};

type DistributionData = {
  name: string;
  id: string;
};

type DistributionState = {
  modelHealth: string;
  baseLine: string;
};

const initialState = {
  modelHealth: '',
  baseLine: 'prev_time_window'
};

const ModelOverview = ({ createdAt, modelId, name }: ModelOverviewProps) => {
  const [formData, setFormData] = useState<DistributionState>(initialState);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { modelHealth, baseLine } = formData;
  const activeWorkspace = useActiveWorkspace();

  const { isLoading, data } = useModelDistributions({ modelId });
  const {
    data: modelData,
    isLoading: modelDataLoading
  } = useGetModelRegistryNameById({
    modelId,
    workspaceId: activeWorkspace?.id
  });
  const { distributionId } = modelData?.model || {};
  const setModelDistribution = useModelDistributionSet();

  useEffect(() => {
    if (distributionId && data?.length) {
      const activeOption = data.find(
        ({ id }: DistributionData) => id === distributionId
      )?.id;
      setFormData(prevState => {
        return { ...prevState, baseLine: activeOption };
      });
    } else {
      setFormData(prevState => {
        return { ...prevState, baseLine: initialState.baseLine };
      });
    }
  }, [distributionId, data]);

  const driftOptions = useMemo(() => {
    let options = [...DRIFT_CALCULATION_OPTIONS];
    if (data?.length) {
      options = options.concat(
        data.map(({ name, id }: DistributionData) => {
          return {
            label: name,
            value: id
          };
        })
      );
    } else {
      options.push({
        label: 'No training distributions available',
        value: 'not_available_distributions',
        disabled: true
      });
    }

    return options;
  }, [data]);

  const changeHandler = (value: string) => {
    if (value !== formData.baseLine) {
      const id = value === DRIFT_CALCULATION_OPTIONS[1].value ? null : value;
      setModelDistribution.mutate({
        distributionId: id,
        prevValue: formData.baseLine,
        changeDistributionHandler: setFormData
      });
    }
    setFormData(prevState => {
      return { ...prevState, baseLine: value };
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="300px"
      className="model-overview-wrapper"
    >
      <h4>Model Overview</h4>
      <Box display="flex" marginBottom="18px">
        <span className="data-content">Model name:&nbsp;</span>
        <span className="data-value">{name}</span>
      </Box>
      <Box display="flex" marginBottom="18px">
        <span className="data-content">Created date:&nbsp;</span>
        <span className="data-value">
          {moment(createdAt).format('MM/DD/YYYY')}
        </span>
      </Box>
      {/* Will be enabled after backend implementation */}
      {/* <Select
        label="Modal health:"
        placeholder="Select..."
        value={modelHealth}
        onChange={value => {
          setFormData(prevState => {
            return { ...prevState, modelHealth: value };
          });
        }}
        options={MODEL_HEALTH}
        width="260px"
        variant="outlined"
        withInput={false}
      /> */}
      <Select
        label="Baseline for data drift calculation:"
        value={baseLine}
        onChange={changeHandler}
        disabled={isLoading || modelDataLoading}
        options={driftOptions}
        width="260px"
        variant="outlined"
        withInput={false}
        withTooltip
        renderTooltip={RenderTooltip}
      />
    </Box>
  );
};

export default ModelOverview;
