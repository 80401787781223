import React from 'react';
import { useSearchListContext } from '../useSearchListContext';
import VirtualizedList, {
  VirtualizedListProps
} from '../../../shared/VirtualizedList/VirtualizedList';

type SearchableListVirtualizedResultProps<T> = Pick<
  VirtualizedListProps<T>,
  'children' | 'maxHeight'
>;
const SearchableListVirtualizedResult = <T extends object>({
  maxHeight,
  children
}: SearchableListVirtualizedResultProps<T>) => {
  const { itemList, isNotFound, isEmpty } = useSearchListContext();

  return (
    <VirtualizedList
      itemList={itemList as T[]}
      maxHeight={maxHeight}
      isEmpty={isNotFound || isEmpty}
    >
      {children}
    </VirtualizedList>
  );
};

export default SearchableListVirtualizedResult;
