import { Context, createContext, useContext } from 'react';

interface ReactGridContextType {
  handleAllVisibilityChange?: (keys: string[]) => void;
  hiddenExperimentKeys: string[];
  experimentKeys: string[];
}

export const ReactGridContext: Context<ReactGridContextType> = createContext<ReactGridContextType>(
  {
    hiddenExperimentKeys: [],
    experimentKeys: []
  }
);

export const useReactGridContext = (): ReactGridContextType =>
  useContext<ReactGridContextType>(ReactGridContext);
