import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import unzip from 'lodash/unzip';
import zip from 'lodash/zip';
import last from 'lodash/last';
import isNumber from 'lodash/isNumber';
import debounce from 'lodash/debounce';
import { findIndex } from 'lodash/array';

import { CONFUSION_MATRIX_CELL_VALUES } from '@experiment-management-shared/constants/chartConstants';
import useAssetById from '@experiment-management-shared/api/useAssetById';
import { IAssetData } from '@experiment-management-shared/api/useAssetsForExperiments';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { getExperimentColor } from '@experiment-management-shared/utils/experimentHelpers';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIcon } from '@Icons-outdated';
import { Slider } from '@design-system-outdated/components';
import InfoBox from '@design-system-outdated/components/InfoBox';
import SmallLoader from '@shared/components/SmallLoader';
import { matrixToPercentageValues } from '@/helpers/confusionMatrixHelpers';
import ConfusionMatrix from './ConfusionMatrix';

import styles from './ConfusionMatrixContainer.module.scss';

const DELAYS_MS = 250;

const calculateStep = (stepsArray, asset) => {
  return findIndex(stepsArray, s => s.step === asset.step);
};

const ConfusionMatrixContainer = ({
  id,
  cellValue,
  ranges,
  stepsArray,
  isSortable,
  isPercentage,
  experiment,
  asset,
  highlightPositions,
  onCellHighlight,
  onCellClick,
  onStepChange,
  setMatricesMap
}) => {
  const [stepValue, setStepValue] = useState(calculateStep(stepsArray, asset));

  const { data, isLoading, isError } = useAssetById(
    {
      experimentKey: asset.experimentKey,
      assetId: asset.assetId,
      silent: true
    },
    {
      keepPreviousData: true
    }
  );

  useEffect(() => {
    setStepValue(calculateStep(stepsArray, asset));
  }, [stepsArray, asset]);

  const stepSliderLabelFormat = value => {
    const lastStep = last(stepsArray)?.step;
    const currentStep = stepsArray[value]?.step;

    return isNumber(lastStep) && isNumber(currentStep)
      ? `${currentStep}/${lastStep}`
      : '';
  };

  const handleDebouncedChangeStep = useCallback(
    debounce(s => {
      onStepChange(asset.assetId, s.assetId);
    }, DELAYS_MS),
    [asset, onStepChange]
  );

  const handleChangeStep = index => {
    setStepValue(index);
    handleDebouncedChangeStep(stepsArray[index]);
  };

  const renderStepsSlider = () => {
    if (stepsArray.length === 1) {
      return null;
    }

    return (
      <div className={styles.sliderBox}>
        <div className={styles.sliderTitle}>Step</div>
        <div className={styles.sliderWrapper}>
          <Slider
            min={0}
            max={stepsArray.length - 1}
            step={1}
            valueLabelDisplay="off"
            valueLabelFormat={value => stepsArray[value]}
            sliderSuffixFormat={stepSliderLabelFormat}
            value={stepValue}
            onChange={handleChangeStep}
          />
        </div>
      </div>
    );
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    node,
    setActivatorNodeRef
  } = useSortable({ id: asset.assetId });

  const style = {
    transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
    transition,
    '--matrix-container-width': `${
      node.current?.parentNode.clientWidth || 500
    }px`
  };

  const { matrix = [] } = data ?? {};

  const computedMatrixMap = useMemo(() => {
    const zippedMatrix = zip(...matrix);
    const percentageMatrix = matrixToPercentageValues(zippedMatrix);

    return {
      [CONFUSION_MATRIX_CELL_VALUES.COUNTS]: matrix,
      [CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_COLUMN]: unzip(percentageMatrix),
      [CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_ROW]: matrixToPercentageValues(
        matrix
      )
    };
  }, [matrix]);

  useEffect(() => {
    if (!isLoading) {
      setMatricesMap(map => {
        return {
          ...map,
          [asset.assetId]: computedMatrixMap
        };
      });
    }
  }, [asset.assetId, computedMatrixMap, isLoading, setMatricesMap]);

  const handleCellClick = useCallback(
    ({ col, data, row }) => {
      onCellClick({ col, data, row, assetId: asset.assetId });
    },
    [onCellClick, asset]
  );

  const handleCellHighlight = useCallback(
    event => {
      onCellHighlight({ [asset.assetId]: event });
    },
    [onCellHighlight, asset]
  );

  const color = getExperimentColor(experiment).primary;

  const header = (
    <div className={styles.header}>
      <div className={styles.titleContainer}>
        <div className={styles.color} style={{ background: color }} />
        <div className={styles.title}>{asset.fileName}</div>
      </div>
      {isSortable && (
        <div
          className={styles.dragHandle}
          {...listeners}
          ref={setActivatorNodeRef}
        >
          <DragIcon />
        </div>
      )}
      <div className={styles.sliderContainer}>{renderStepsSlider()}</div>
    </div>
  );

  const renderBody = () => {
    if (isError && !isLoading) {
      return <InfoBox title="Error" text="Error fetching Matrix data" />;
    }

    if (isLoading && !data) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Loading Matrix data"
        />
      );
    }

    return (
      <ConfusionMatrix
        cellValue={cellValue}
        isPercentage={isPercentage}
        highlightPositions={highlightPositions}
        ranges={ranges}
        onCellClick={handleCellClick}
        onCellHighlight={handleCellHighlight}
        data={data}
        computedMatrixMap={computedMatrixMap}
      />
    );
  };

  return (
    <div
      id={id}
      className={styles.confusionMatrixContainer}
      {...attributes}
      style={style}
      ref={setNodeRef}
      data-test="matrix-container"
    >
      {header}
      <div className={styles.body}>{renderBody()}</div>
    </div>
  );
};

ConfusionMatrixContainer.defaultProps = {
  id: '',
  cellValue: CONFUSION_MATRIX_CELL_VALUES.COUNTS,
  highlightPositions: [],
  onCellClick: noop,
  onCellHighlight: noop,
  onStepChange: noop
};

ConfusionMatrixContainer.propTypes = {
  id: PropTypes.string,
  experiment: IExperimentDetails.isRequired,
  asset: IAssetData.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      minValue: PropTypes.number.isRequired,
      maxValue: PropTypes.number.isRequired
    })
  ).isRequired,
  stepsArray: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      assetId: PropTypes.string.isRequired
    })
  ).isRequired,
  isPercentage: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  cellValue: PropTypes.string,
  highlightPositions: PropTypes.array,
  onCellClick: PropTypes.func,
  onCellHighlight: PropTypes.func,
  onStepChange: PropTypes.func,
  setMatricesMap: PropTypes.func.isRequired
};

export default ConfusionMatrixContainer;
