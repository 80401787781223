import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ColorPickerPopover from '@design-system-outdated/components/ColorPickerPopover/ColorPickerPopover';
import noop from 'lodash/noop';

import styles from './ColorPreview.module.scss';

const ColorPreview = ({ color, onChange, presetColors }) => {
  const colorLabel = color?.toUpperCase();
  const colorRef = useRef();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openPopover = () => setIsPopoverOpen(true);
  const closePopover = () => setIsPopoverOpen(false);

  return (
    <>
      <ColorPickerPopover
        value={color}
        anchorEl={colorRef?.current}
        open={isPopoverOpen}
        onClose={closePopover}
        onChange={onChange}
        presetColors={presetColors}
      />
      <div className={styles.colorPreviewContainer} onClick={openPopover}>
        <div className={styles.colorName}>{colorLabel}</div>
        <div
          className={styles.colorContainer}
          style={{ backgroundColor: color }}
          ref={colorRef}
        />
      </div>
    </>
  );
};

ColorPreview.defaultProps = {
  onChange: noop,
  presetColors: []
};

ColorPreview.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  presetColors: PropTypes.array
};

export default ColorPreview;
