import { AssetStep } from '@experiment-management-shared/types';
import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

type ImageStepsAPI = {
  steps: AssetStep[];
};

const getImageSteps = async ({
  assetNames,
  experimentKeys
}: {
  assetNames: string[];
  experimentKeys: string[];
}) => {
  const { data } = await api.post<ImageStepsAPI>('image/filter-steps', {
    experiments: experimentKeys,
    imageNames: assetNames
  });

  return data.steps || [];
};

type ImagePanelStepsQueryParams = {
  assetNames: string[];
  experimentKeys: string[];
};

export function useImagePanelSteps(
  { assetNames, experimentKeys }: ImagePanelStepsQueryParams,
  config: QueryConfig<AssetStep[]>
) {
  const configEnabled = config?.enabled ?? true;
  const conditionsEnabled = !!assetNames?.length && !!experimentKeys?.length;

  return useQuery(
    [
      'imagePanelSteps',
      // copy and sort arrays to not trigger request every time order of parameters is changed
      {
        assetNames: assetNames.slice().sort(),
        experimentKeys: experimentKeys.slice().sort()
      }
    ],
    () => getImageSteps({ assetNames, experimentKeys }),
    { ...config, enabled: configEnabled && conditionsEnabled }
  );
}
