import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { TextButton } from '@ds';
import queryString from 'query-string';

import onboardingActions from '@/actions/onboardingActions';
import { useActiveWorkspaceName } from '@shared/hooks';

import { getSelectedFramework } from '@/reducers/ui/onboardingReducer';
import useCurrentUser from '@API/auth/useCurrentUser';
import {
  useIsGetStartedAvailable,
  useUpdateGetStartedShownStatus
} from '@onboarding/hooks';
import {
  useLastSelectedFramework,
  useOnboardingFirstClick
} from '@onboarding/hooks/biEvents';
import { PROJECT_CREATED_BY_DEFAULT_NAME } from '@shared/constants';
import QuickStartCodeSnippet from './QuickStartCodeSnippet';
import QuickStartExample from './QuickStartExample';
import QuickStartMenu from './QuickStartMenu';
import { getFrameworkCodeSnippet } from './quickStartCodeSnippets';

import styles from './QuickStartGuide.module.scss';
import { DSArrowDirectionLeftIcon } from '@ds-icons';

const QuickStartGuide = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const framework = useSelector(getSelectedFramework);
  const {
    fromGetStarted = false,
    project_name: queryProjectName
  } = queryString.parse(search);

  const projectName = queryProjectName || PROJECT_CREATED_BY_DEFAULT_NAME;
  const activeWorkspaceName = useActiveWorkspaceName();
  const isGetStartedAvailable = useIsGetStartedAvailable();

  const redirectToGetStartedShown = fromGetStarted && isGetStartedAvailable;

  useUpdateGetStartedShownStatus({
    isQuickStart: true
  });

  const { onSendFirstClickEvent } = useOnboardingFirstClick();

  // bi events
  const { onChange: handleChangeLastFramework } = useLastSelectedFramework();

  const handleChangeFramework = useCallback(
    framework => {
      onSendFirstClickEvent(`[Framework select]: ${framework.value}`);
      dispatch(onboardingActions.setFramework(framework.value));
      handleChangeLastFramework(framework.value);
    },
    [dispatch, handleChangeLastFramework, onSendFirstClickEvent]
  );

  const { data: user } = useCurrentUser();

  const { prefixIcon: PrefixIcon } = framework;

  if (!framework || !user) {
    return null;
  }

  const { apiKey } = user;
  const { IMPORT: importCode, LOGGING: logCode } = getFrameworkCodeSnippet(
    apiKey,
    projectName,
    activeWorkspaceName
  )[framework.value];

  const handleClickGetStarted = () => {
    history.push(`/get-started`);
    onSendFirstClickEvent('[Return to get started page]');
  };

  const handleRedirectToProject = () => {
    history.push(`/`);
  };

  const redirectButtonLabel = redirectToGetStartedShown
    ? `Return to 'Get Started'`
    : `Return to Projects`;
  const redirectButtonClick = redirectToGetStartedShown
    ? handleClickGetStarted
    : handleRedirectToProject;

  return (
    <div className={styles.quickStartPage}>
      <div className={styles.returnToGetStarted}>
        <TextButton
          type="secondary"
          PrefixIcon={<DSArrowDirectionLeftIcon />}
          onClick={redirectButtonClick}
        >
          {redirectButtonLabel}
        </TextButton>
      </div>
      <div className={styles.quickStartContainer}>
        <div className={styles.quickStartContent}>
          <QuickStartMenu
            frameworkValue={framework.value}
            onChangeFramework={handleChangeFramework}
          />

          <div className={styles.quickStartCodeSnippet}>
            <div className={styles.quickStartTitleSection}>
              <h2 className={styles.quickStartTitle}>Quickstart Guide</h2>
              <div className={styles.quickStartLabel}>
                Select the framework and follow the instructions to integrate
                Comet with your own code or use our ready-to-run examples on the
                right.
              </div>
            </div>

            <QuickStartCodeSnippet
              importSection={framework.importSection}
              installCode={framework.installCode}
              installationSection={framework.installationSection}
              logCode={logCode?.trim()}
              logSection={framework.logSection}
              importCode={importCode?.trim()}
              PrefixIcon={PrefixIcon}
            />
          </div>

          <div className={styles.quickStartExample}>
            <QuickStartExample
              codeSnippet={framework.codeSnippet}
              snippetFileName={framework.snippetFileName}
              colabLink={framework.colabLink}
              docLink={framework.docLink}
              frameworkValue={framework.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickStartGuide;
