import React from 'react';
import { SingleValue } from 'react-select';

import { Select, Tooltip, TextButton, IconButton } from '@ds';
import { Input } from '@DesignSystem/controllers';
import { DSDeleteIcon } from '@ds-icons';
import { InfoIcon } from '@Icons-outdated';
import styles from './PredicatesPopover.module.scss';
import { FEATURE_TYPES } from '@mpm-druid/constants';
import { FeatureOption, OperatorOption } from '@mpm-druid/types';
import { OptionType } from '@design-system/types';

const TOOLTIP_MESSAGE = 'Text Filters support AND and OR operators';

const CATEGORICAL_OPERATORS_OPTIONS = [
  { label: '=', value: '=' },
  { label: '!=', value: '!=' }
];

const NUMERICAL_OPERATORS_OPTIONS = [
  { label: '>', value: '>' },
  { label: '<', value: '<' },
  { label: '<=', value: '<=' },
  { label: '>=', value: '>=' },
  { label: '=', value: '=' },
  { label: '!=', value: '!=' }
];

type SinglePredicateProps = {
  id: string;
  isTextMode: boolean;
  isDisabledTextSwitch: boolean;
  feature: FeatureOption | null;
  featureOptions:
    | { label: string; options: OperatorOption[] }[]
    | OptionType[]
    | {
        label: OptionType['label'];
        options: (OperatorOption & { type: string })[];
      };
  operator: OperatorOption | null;
  threshold: string | number;
  predicateStr: string;
  errorMessage: string;
  handleSwitchText: (id: string) => void;
  handleRemoveItem: (id: string) => void;
  handleValueChange: (
    id: string,
    newValue: string | FeatureOption | OperatorOption | SingleValue<OptionType>,
    key: string
  ) => void;
};

export const SinglePredicate = ({
  id,
  isTextMode,
  isDisabledTextSwitch,
  feature,
  featureOptions,
  operator,
  threshold,
  predicateStr,
  errorMessage,
  handleSwitchText,
  handleRemoveItem,
  handleValueChange
}: SinglePredicateProps) => {
  const operatorsOptions =
    feature?.type === FEATURE_TYPES.NUMERICAL
      ? NUMERICAL_OPERATORS_OPTIONS
      : CATEGORICAL_OPERATORS_OPTIONS;

  const inputType =
    feature?.type === FEATURE_TYPES.NUMERICAL ? 'number' : 'text';

  return (
    <div className={styles.singlePredicateWrapper}>
      {!isTextMode && (
        <div className={styles.textButtonWrapper}>
          <TextButton
            size="small"
            type="tertiary"
            onClick={() => handleSwitchText(id)}
            disabled={isDisabledTextSwitch}
          >
            Switch to text
          </TextButton>
          <Tooltip content={TOOLTIP_MESSAGE} placement="top">
            <InfoIcon className={styles.overviewInfoIcon} />
          </Tooltip>
        </div>
      )}
      <div className={`${styles.inputDataContainer} input-data-container`}>
        {!isTextMode && (
          <>
            <div className={styles.featureSelectWrapper}>
              <Select
                value={feature?.value}
                onValueChange={(_, optionData) => {
                  handleValueChange(id, optionData, 'feature');
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                options={featureOptions}
                isSearchable
                placeholder="Feature name"
              />
            </div>
            <div className={styles.operatorSelectWrapper}>
              <Select
                value={operator?.value}
                onValueChange={(_, optionData) =>
                  handleValueChange(id, optionData, 'operator')
                }
                options={operatorsOptions}
                placeholder="="
                isClearable={false}
              />
            </div>
            <div
              className={`${styles.thresholdWrapper} input-threshold-container`}
            >
              <Input
                value={threshold}
                height="36px"
                onChange={(newValue: string) => {
                  if (inputType === 'number' && newValue.length > 10) return;
                  handleValueChange(id, newValue, 'threshold');
                }}
                inputType={inputType}
              />
            </div>
          </>
        )}
        {isTextMode && (
          <div
            className={`${styles.predicateStrWrapper} predicate-str-wrapper`}
          >
            <Input
              value={predicateStr}
              onChange={(newValue: string) =>
                handleValueChange(id, newValue, 'predicateStr')
              }
              height="36px"
              fontSize="14px"
            />
          </div>
        )}
        <IconButton
          type="secondary"
          onClick={() => handleRemoveItem(id)}
          Icon={<DSDeleteIcon />}
        />
      </div>
      <div className={styles.errorMessage}>{errorMessage}</div>
    </div>
  );
};
