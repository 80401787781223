import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsSingleExperimentColor } from '@experiment-details/hooks';
import { useExperimentPanelColors } from '@experiment-management-shared/hooks';
import { CHART_COLORS } from '@/lib/appConstants';

const useChangeColorState = () => {
  const colorMap = useExperimentPanelColors();
  const isSingleExperimentColorFromTemplate = useIsSingleExperimentColor();

  const [updatedColors, setUpdatedColors] = useState({});
  const [isSingleColor, setIsSingleColor] = useState(
    isSingleExperimentColorFromTemplate
  );

  useEffect(() => {
    setIsSingleColor(isSingleExperimentColorFromTemplate);
  }, [isSingleExperimentColorFromTemplate]);

  const handledColorMap = useMemo(() => {
    // get the primary value of a color map
    const primaryColors = Object.entries(colorMap).reduce(
      (res, [key, { primary }]) => {
        res[key] = primary;
        return res;
      },
      {}
    );

    return {
      ...primaryColors,
      ...updatedColors
    };
  }, [colorMap, updatedColors]);

  const defaultColorMap = useMemo(() => {
    const metrics = Object.keys(colorMap);

    return metrics.reduce((res, metric, idx) => {
      res[metric] = CHART_COLORS[idx % CHART_COLORS.length];
      return res;
    }, {});
  }, [colorMap]);

  const updateAllMetricsWithOneColor = useCallback(
    color => {
      const metricKeys = Object.keys(colorMap);

      setUpdatedColors(() =>
        metricKeys.reduce((res, metricKey) => {
          res[metricKey] = color;
          return res;
        }, {})
      );
    },
    [colorMap]
  );

  const handleResetColors = useCallback(() => {
    setUpdatedColors(defaultColorMap);
  }, [defaultColorMap]);

  const handleResetDefault = useCallback(() => {
    handleResetColors();
    setIsSingleColor(false);
  }, [handleResetColors]);

  const handleToggleSingleColor = useCallback(() => {
    const newValue = !isSingleColor;
    if (newValue) {
      updateAllMetricsWithOneColor(CHART_COLORS[0]);
    } else {
      handleResetColors();
    }

    setIsSingleColor(newValue);
  }, [isSingleColor, updateAllMetricsWithOneColor, handleResetColors]);

  const handleUpdateColor = useCallback(
    ({ metricName, color }) => {
      if (isSingleColor) {
        updateAllMetricsWithOneColor(color);
      } else {
        setUpdatedColors(prevColors => ({
          ...prevColors,
          [metricName]: color
        }));
      }
    },
    [isSingleColor, updateAllMetricsWithOneColor]
  );

  const handleClearChanges = useCallback(() => {
    setUpdatedColors({});
    setIsSingleColor(isSingleExperimentColorFromTemplate);
  }, [isSingleExperimentColorFromTemplate]);

  return {
    colorMap: handledColorMap,
    isSingleColor,

    onUpdateColorMap: handleUpdateColor,
    onClearChanges: handleClearChanges,
    onResetDefault: handleResetDefault,
    onToggleSingleColor: handleToggleSingleColor
  };
};

export default useChangeColorState;
