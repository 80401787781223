// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminSettingsBillingPlan-module__billingPlanTypeContainer--F4ett{display:flex;align-items:center;gap:6px}.AdminSettingsBillingPlan-module__billingPlanTypeField--AUSeO{font-weight:400;font-size:14px;color:#5f677e}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billingPlanTypeContainer": `AdminSettingsBillingPlan-module__billingPlanTypeContainer--F4ett`,
	"billingPlanTypeField": `AdminSettingsBillingPlan-module__billingPlanTypeField--AUSeO`
};
module.exports = ___CSS_LOADER_EXPORT___;
