import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import MultipleAudiosTable from './MultipleAudiosTable';

const AudiosGroupedBy = ({ assets, experimentKeys }) => {
  const [expanded, setExpanded] = useState({});

  return Object.entries(assets).map(([category, categoryAssets]) => {
    const handleExpanded = (event, isExpanded) => {
      setExpanded({
        ...expanded,
        [category]: isExpanded
      });
    };

    const isExpanded = expanded[category];

    return (
      <ExpansionPanel
        key={category}
        expanded={isExpanded}
        onChange={handleExpanded}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{category}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {isExpanded && (
            <MultipleAudiosTable
              assets={categoryAssets}
              experimentKeys={experimentKeys}
            />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
};

AudiosGroupedBy.propTypes = {
  experimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AudiosGroupedBy;
