import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { BasicModal, DeleteModalBody } from '@DesignSystem/modals';
import { useParams } from 'react-router-dom';
import useDeleteModelMutation from '@API/model-registry/useDeleteModelMutation';

const CONFIRM_INPUT_TEXT_TO_CONFIRM_DELETE = 'DELETE';

const DeleteModelModal = ({ isOpen, onClose }) => {
  const { modelName, workspace } = useParams();

  const [inputValue, setInputValue] = useState('');
  const deleteMutation = useDeleteModelMutation(workspace);
  const handleConfirm = useCallback(async () => {
    onClose();

    if (inputValue === CONFIRM_INPUT_TEXT_TO_CONFIRM_DELETE) {
      deleteMutation.mutate({ modelName });
    }
  }, [inputValue, deleteMutation, modelName, onClose]);

  const handleInputChange = useCallback(value => {
    setInputValue(value);
  }, []);

  return (
    <BasicModal
      open={isOpen}
      onClose={onClose}
      title="Delete Model"
      isPrimaryButtonDisabled={
        inputValue !== CONFIRM_INPUT_TEXT_TO_CONFIRM_DELETE
      }
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      onSecondaryButtonClick={onClose}
      content={
        <DeleteModalBody
          description={`The model "${modelName}" will be removed. Once deleted, the
            model can not be recovered.`}
          onChangeCallback={handleInputChange}
        />
      }
    />
  );
};

DeleteModelModal.propTypes = {
  isLastModelVersion: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default DeleteModelModal;
