import React from 'react';
import { Tooltip } from '@ds';
import { ComparisonTableCellRendererData } from '../types';
import styles from './NewComparisonTableCell.module.scss';
import { useMiddleTruncation, useRefSizes } from '@shared/hooks';

type ComparisonTableKeyCellProps = ComparisonTableCellRendererData<string>;

const ComparisonTableKeyCell: React.FC<ComparisonTableKeyCellProps> = ({
  value
}) => {
  const { refWidth = 0, sizeRef } = useRefSizes();
  const { truncatedValue } = useMiddleTruncation({
    text: value,
    maxWidth: refWidth - 10,
    fontConfig: {
      fontSize: 14,
      fontWeight: 'bold'
    }
  });

  return (
    <div ref={sizeRef} className={styles.comparisonTableExperimentCell}>
      <Tooltip wrapperDisplay="block" content={value} placement="top">
        <>{truncatedValue}</>
      </Tooltip>
    </div>
  );
};

export default ComparisonTableKeyCell;
