// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfusionMatrix-module__confusionMatrix--UKSlt{margin:0 auto}.ConfusionMatrix-module__confusionMatrix--UKSlt .ConfusionMatrix-module__confusionMatrixContainer--gxUf0{display:flex}.ConfusionMatrix-module__confusionMatrix--UKSlt .ConfusionMatrix-module__yAxisTitle--f2qHC{color:var(--gray-4);font-size:10px;line-height:12px;font-weight:500;writing-mode:tb-rl;transform:rotate(-180deg);text-align:center;padding:40px 0 0 12px}.ConfusionMatrix-module__confusionMatrix--UKSlt .ConfusionMatrix-module__xAxisTitle--lwNL1{color:var(--gray-4);font-size:10px;line-height:12px;font-weight:500;text-align:center;padding:0 0 6px 60px}.ConfusionMatrix-module__confusionMatrix--UKSlt .confusion-matrix-chart-ylabel-cell{min-width:5px}.confusion-matrix div.ConfusionMatrix-module__emptyMessage--tZaAT{padding-top:156px}.confusion-matrix div.ConfusionMatrix-module__emptyMessage--tZaAT.ConfusionMatrix-module__isComparePage--WZKwG{padding-top:137px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confusionMatrix": `ConfusionMatrix-module__confusionMatrix--UKSlt`,
	"confusionMatrixContainer": `ConfusionMatrix-module__confusionMatrixContainer--gxUf0`,
	"yAxisTitle": `ConfusionMatrix-module__yAxisTitle--f2qHC`,
	"xAxisTitle": `ConfusionMatrix-module__xAxisTitle--lwNL1`,
	"emptyMessage": `ConfusionMatrix-module__emptyMessage--tZaAT`,
	"isComparePage": `ConfusionMatrix-module__isComparePage--WZKwG`
};
module.exports = ___CSS_LOADER_EXPORT___;
