import { useEffect, useRef } from 'react';
import isEqual from 'fast-deep-equal';
import isFunction from 'lodash/isFunction';

type UseChartDataSourceChangeNotificatorParams = {
  onDataSourceChange: () => void;
  isLoading?: boolean;
  dataSources: unknown;
};
const useChartDataSourceChangeNotificator = ({
  onDataSourceChange,
  isLoading,
  dataSources
}: UseChartDataSourceChangeNotificatorParams) => {
  const prevDataSources = useRef<unknown>([]);

  useEffect(() => {
    if (!isLoading && !isEqual(prevDataSources.current, dataSources)) {
      prevDataSources.current = dataSources;
      if (isFunction(onDataSourceChange)) {
        onDataSourceChange();
      }
    }
  }, [dataSources, isLoading, onDataSourceChange]);
};
export default useChartDataSourceChangeNotificator;
