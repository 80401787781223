export const IMAGE_ANNOTATIONS_COLORS = [
  'E51772',
  '0096C7',
  '00B4D8',
  '12A592',
  '16CAB2',
  'FB7628',
  'FF4747',
  'FF8900',
  'FFBD00',
  '41EAD4',
  '49A5BD',
  '6E1D89',
  '860DAB',
  'CF0057',
  'FFD51D'
];
