import keymirror from 'keymirror';

export const paymentEvents = keymirror({
  // Submit selection of collaborators
  ADD_COLLABORATOR_SUBMITTED: null,
  // Add collaborator using email or username
  ADD_COLLABORATOR_FROM_MODAL: null,
  // Add collaborator modal loaded
  MODAL_LOADED_ADD_COLLABORATOR: null,
  // Plan select use get started
  PLAN_SELECT_USED_GET_STARTED: null,
  // Plan select use purchase now
  PLAN_SELECT_USED_PURCHASE_NOW: null,
  // New userapp plan selection page
  MODAL_LOADED_PROCESS_PAYMENT: null,
  // New userapp plan selection page
  MODAL_ACTION_SET_WORKSPACE_NAME: null,
  // New userapp plan selection page
  MODAL_LOADED_CREATE_NEW_WORKSPACE: null,
  // New userapp plan selection page
  NEW_USER_APP_PLAN_SELECTION_PAGE: null,
  // A user selects a payment plan
  PRICING_CLICKED_PAYMENT_PLAN: null,
  // Pricing table viewed
  PRICING_TABLE_VIEWED: null
});

export const signupEvents = keymirror({
  // Event to track the list of ML libraries selected, separated by "|"
  SIGNUP_STEP_LIBRARY_LIST_ENGAGED: null,
  // Event for library on signup step clicked and the length of time on page when click
  SIGNUP_STEP_LIBRARY_CLICKED: null,
  // Event for hover and click behavior over the pip install
  SIGNUP_STEP_COMET_PIP_INSTALL_CLICKED: null,
  // Event for hover and click behavior over the tracking code, with param for library
  SIGNUP_STEP_TRACKING_CODE_CLICKED: null,
  // Event for clicking the "Listen for my experiment" button
  SIGNUP_STEP_LISTEN_FOR_EXPERIMENT_CLICKED: null,
  // Event for tracking the TIME in seconds between clicking button and getting response
  SIGNUP_STEP_USER_EXPERIMENT_RECEIVED: null,
  // Event for clicking the "View Experiment" button
  SIGNUP_STEP_USER_VIEW_EXPERIMENT_CLICKED: null,
  // Event for clicking "See how comet works" panel
  SIGNUP_STEP_USER_EXPAND_SKIP_EXPERIMENT_PANEL: null,
  // Event for when "Skip setup and get started" is clicked, with param for source of button
  SIGNUP_STEP_USER_SKIP_SETUP_CLICKED: null,
  // Event for submitting the form of additional user information post signup
  POST_SIGNUP_USER_INFO_SUBMITTED: null
});

export const developerEvents = keymirror({
  // Event to track when a user generates a new API key
  DEVELOPER_GENERATED_API_KEY: null
});

export const experimentEvents = keymirror({
  // Project list was viewed
  PROJECTS_LIST_VIEWED: null,
  // Experiment detail was viewed with details
  EXPERIMENT_DETAIL_VIEWED: null,
  // Experiment list was viewed
  EXPERIMENT_LIST_VIEWED: null,
  // Expanded experiment or single experiment page's experiment tab selected
  EXPERIMENT_DETAIL_TAB_SELECTED: null,
  EXPERIMENT_DETAIL_SYSTEM_METRIC: null,
  REPRODUCE_TAB_OPEN: null,
  VIEW_GIT_PATCH: null,
  DOWNLOAD_GIT_PATCH: null,
  SINGLE_EXPERIMENT_PAGE_REGISTER_MODEL_SIDEBAR_OPENED: null,
  SINGLE_EXPERIMENT_PAGE_SWITCHED_VIEW: null,
  SINGLE_EXPERIMENT_PAGE_DISCARD_CHANGED_VIEW: null,
  EXPERIMENT_DETAILS_ADD_METRIC_CLICKED: null,
  EXPERIMENT_DETAILS_ADD_METRICS_MODAL_ADD_CLICKED: null,
  EXPERIMENT_DETAILS_EDIT_METRIC_CLICKED: null,
  EXPERIMENT_DETAILS_EDIT_METRIC_DONE_CLICKED: null,
  EXPERIMENT_COMPARE_COLUMN_CHANGED: null,
  EXPERIMENT_COMPARE_HIDE_IDENTICAL_METRICS_CLICKED: null,
  EXPERIMENT_DETAIL_NESTED_PARAM_VIEWED: null,
  EXPERIMENT_DETAIL_NESTED_PARAM_GROUP_TOGGLED: null
});

export const emailEvents = keymirror({
  // Event to track when a user changes email address
  EMAIL_CHANGED: null
});

export const cssFileEvent = keymirror({
  // Event to track when a user changes password
  FAIL_TO_LOAD_CSS_FILE: null
});

export const viewEvents = keymirror({
  AUTO_GENERATED_RENDERED: null,
  PROJECT_VIEWED: null,
  PROJECT_PANELS_PAGE_VIEWED: null,
  PROJECT_EXPERIMENT_TABLE_PAGE_VIEWED: null,
  PROJECT_NOTES_PAGE_VIEWED: null,
  PROJECT_ARCHIVE_PAGE_VIEWED: null,
  PROJECT_MANAGE_PAGE_VIEWED: null,
  PROJECT_REPORTS_PAGE_VIEWED: null,
  REPORT_VIEWED: null,
  WORKSPACE_VIEWED: null,
  VIEWED_WHATS_NEW_FE: null,
  REDESIGN_LEGACY_UI: null,
  REDESIGN_NEW_UI: null
});

export const dashboardEvents = keymirror({
  SELECT_METRIC_KIND_OPTION: null,
  PROJECT_SEARCH: null,
  PROJECT_FILTER: null,
  PROJECT_SORT: null,
  PROJECT_UPDATE_COLUMNS: null,
  PROJECT_UPDATE_GROUPS: null,
  PROJECT_PAGE_PANELS_SEARCH: null,
  PROJECT_PAGE_PANELS_X_AXIS_UPDATED: null,
  PROJECT_PAGE_PANELS_SAMPLE_SIZE_UPDATED: null,
  PROJECT_PAGE_PANELS_SMOOTHING_UPDATED: null,
  PROJECT_PAGE_PANELS_Y_AXIS_TRANSFORMATION_UPDATED: null,
  PROJECT_PAGE_PANELS_OUTLIERS_UPDATED: null,
  PROJECT_PAGE_PANELS_ADD_PANEL_CLICKED: null,
  PROJECT_PAGE_PANELS_LOCK_ICON_CLICKED: null,
  PROJECT_PAGE_SWITCHED_VIEW: null,
  PROJECT_PAGE_DISCARD_CHANGED_VIEW: null
});

export const panelEvents = keymirror({
  ADD_BUILT_IN_SCALAR_PANEL_CLICK: null,
  OPEN_SCALAR_PANEL_ADDING: null,
  IMAGE_PANEL_ANNOTATION_TAB_CLICKED: null,
  VIDEO_PLAYED: null,
  PANEL_ADDED: null,
  PANEL_RENDERED: null,
  PANEL_TOOLTIP_INTERACTED: null,
  CLICK_PYTHON_RUN: null
});

export const llmEvents = keymirror({
  EMPTY_SEARCH_QUERY_RESULT: null
});

export const modelRegistryEvents = keymirror({
  STATUS_MODEL_OPEN: null,
  STATUS_MODEL_SUBMITTED: null
});
