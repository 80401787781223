import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { QueryConfig } from '@shared/api';

import api from '@API/api';
import { getTeamIdForActiveWorkspace } from '@/reducers/ui/workspaceUiReducer';

type FeatureValuesParams = {
  modelId: string;
  featureId?: string | null;
};

type FeatureValuesResponse = {
  values: string[];
};

const getFeatureValues = ({
  modelId,
  workspaceId,
  featureId,
  ...restParams
}: FeatureValuesParams & { workspaceId: string }) =>
  api.get(
    `mpm/${workspaceId}/models/${modelId}/features/${featureId}/unique_values`,
    restParams
  );

export function useFeatureValues(
  params: FeatureValuesParams,
  config: QueryConfig<FeatureValuesResponse>
) {
  const workspaceId = useSelector(getTeamIdForActiveWorkspace);
  const { featureId } = params;
  return useQuery(
    ['model-feature-values', { workspaceId, ...params }],
    () => getFeatureValues({ workspaceId, ...params }),
    {
      ...config,
      enabled: !!workspaceId && !!featureId && (config?.enabled ?? true)
    }
  );
}
