import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import useRemoveExperimentsMutation from '@API/experiments/useRemoveExperimentsMutation';
import { BasicModal } from '@DesignSystem/modals';

import styles from '../ExperimentActionsModal.module.scss';

const DeleteModal = ({ open, onClose }) => {
  const history = useHistory();
  const { workspace, projectName, experimentKey } = useParams();

  const removeExperimentsMutation = useRemoveExperimentsMutation();

  const handleDelete = () => {
    removeExperimentsMutation.mutate({ experimentKeys: [experimentKey] });
    onClose();

    history.push(`/${workspace}/${projectName}`);
  };

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      onPrimaryButtonClick={handleDelete}
      onSecondaryButtonClick={onClose}
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      title="Are you sure?"
      content={
        <div className={styles.deleteModalContainer}>
          This will permanently delete the experiment.
        </div>
      }
    />
  );
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DeleteModal;
