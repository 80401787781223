import api from '@shared/api';

export const uploadProjectNoteFile = async ({
  file,
  projectId
}: {
  file: File;
  projectId: string;
}) => {
  const formData = new FormData();
  formData.append('file', file);

  const {
    data: { msg }
  } = await api.post<{ msg: string }>(
    `upload/note-images?projectId=${projectId}`,
    formData,
    { headers: { 'content-type': 'multipart/form-data' } }
  );

  return msg;
};
