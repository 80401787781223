import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isNull from 'lodash/isNull';

import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';

import GenericModal from '@shared/components/GenericModal';
import alertsActions from '@/actions/alertsActions';

export const ConfirmWarning = ({
  bodyContent,
  buttonText,
  dispatch,
  headerText,
  modalId,
  onConfirm,
  style,
  title
}) => {
  const onCancel = () => {
    dispatch(alertsActions.closeDialog(modalId));
  };

  return (
    <GenericModal
      customClass="generic-modal-alt light confirm-modal"
      title={
        <span className="confirm-modal-title">
          <i className="material-icons warning-icon">warning</i>
          {title}
        </span>
      }
      onCancel={onCancel}
      onClose={onConfirm}
      buttonText={buttonText}
      isConfirmModal
      style={style}
    >
      <DialogContent style={{ padding: 20 }}>
        <div className="confirm-modal-header">{headerText}</div>
        {!isNull(bodyContent) && (
          <div className="confirm-modal-body">
            <Divider className="confirm-modal-divider" />
            <div className="confirm-modal-body-content">{bodyContent}</div>
          </div>
        )}
      </DialogContent>
    </GenericModal>
  );
};

ConfirmWarning.defaultProps = {
  bodyContent: null,
  buttonText: 'Continue',
  style: {},
  title: 'Are you sure?'
};

ConfirmWarning.propTypes = {
  bodyContent: PropTypes.element,
  buttonText: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  modalId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string
};

export default connect()(ConfirmWarning);
