import { useUserMenuContext } from '@platform/components/UserMenu/userMenuContext';
import React from 'react';

const isClickInsideTarget = <T extends HTMLElement>(
  e: React.MouseEvent<T, MouseEvent>,
  targetEl: Element | null
) => {
  if (!targetEl) return false;

  return document.elementsFromPoint(e.pageX, e.pageY).includes(targetEl);
};

const useCloseMainMenuOnBackdrop = <T extends HTMLElement>() => {
  const { closeMainMenu, mainMenuRef } = useUserMenuContext();

  return (e: React.MouseEvent<T, MouseEvent>) => {
    if (isClickInsideTarget(e, mainMenuRef)) return;

    closeMainMenu();
  };
};

export default useCloseMainMenuOnBackdrop;
