import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import isEqual from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';

import {
  generateEmptyRulesTree,
  getOperatorsForRule,
  getRulesCount
} from '@shared/utils/filterHelpers';

import QueryFiltersBody from '@shared/components/QueryFiltersBody';
import QueryFiltersHeader from '@shared/components/QueryFiltersHeader';
import { constructFilterDefinition } from '@experiment-management-shared/utils/constructFilterDefinition';
import useFiltersLibraryManagement from '@experiment-management-shared/hooks/useFiltersLibraryManagement';
import { FILTER_BEHAVIOUR_MODE } from '@shared/components/QueryFiltersBody/QueryFiltersBody';
import { Button, TextButton } from '@ds';
import {
  getTotalExperimentsInProject,
  isUserAllowedToEditProject
} from '@/reducers/projectsReducer';
import SmallLoader from '@shared/components/SmallLoader';
import visualizationsActions from '@/actions/visualizationsActions';

import styles from './QueryTab.module.scss';

const QueryTab = ({
  canEdit,
  queryBuilderId,
  queryBuilderIdRevision,
  totalExperimentsInProject
}) => {
  const dispatch = useDispatch();
  const [rulesTree, setRulesTree] = useState(generateEmptyRulesTree());

  const rulesCount = getRulesCount(rulesTree);

  const handleFilterUpdate = useCallback(
    newFilterId => {
      dispatch(
        visualizationsActions.updateTemplateQueryBuilderIdField(
          newFilterId || ''
        )
      );
    },
    [dispatch]
  );

  const {
    isLoading,
    columns,
    filters,
    activeFilter,
    handleSaveFilter,
    handleSelectFilter,
    handleDeleteFilter
  } = useFiltersLibraryManagement({
    filterId: queryBuilderId,
    rulesTree,
    onChange: handleFilterUpdate
  });

  useEffect(() => {
    setRulesTree(cloneDeep(activeFilter?.rulesTree));
  }, [activeFilter, queryBuilderIdRevision]);

  const hasUnsavedChanges = useMemo(() => {
    return !isEqual(rulesTree, activeFilter?.rulesTree);
  }, [activeFilter, rulesTree]);

  const handleChangeRulesTree = newRulesTree => {
    // in case user manually removed all rules we need as well clear queryBuilderId not to force user save empty rules list to library
    if (getRulesCount(newRulesTree)) {
      setRulesTree(newRulesTree);
    } else {
      handleClearRulesTree();
    }
  };

  const handleClearRulesTree = () => {
    dispatch(visualizationsActions.updateTemplateQueryBuilderIdField(''));
  };

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching filters"
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Filters</div>
        <QueryFiltersHeader
          activeFilter={activeFilter}
          filters={filters}
          canEdit={canEdit}
          onSelectFilter={handleSelectFilter}
          onDeleteFilter={handleDeleteFilter}
          totalEntities={totalExperimentsInProject}
        />
      </div>
      <div className={styles.body}>
        <QueryFiltersBody
          mode={FILTER_BEHAVIOUR_MODE.OR_AND}
          editable={canEdit}
          rulesTree={rulesTree}
          columns={columns}
          onChange={handleChangeRulesTree}
          getOperatorsForRule={getOperatorsForRule}
          constructFilterDefinition={constructFilterDefinition}
        />
      </div>
      <div className={styles.footer}>
        <TextButton onClick={handleClearRulesTree} disabled={!rulesCount}>
          Clear
        </TextButton>
        <Button
          type="primary"
          onClick={handleSaveFilter}
          disabled={!hasUnsavedChanges}
        >
          Save Filter
        </Button>
      </div>
    </div>
  );
};

QueryTab.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  queryBuilderId: PropTypes.string.isRequired,
  queryBuilderIdRevision: PropTypes.number.isRequired,
  totalExperimentsInProject: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    canEdit: isUserAllowedToEditProject(state),
    totalExperimentsInProject: getTotalExperimentsInProject(state)
  };
};

export default connect(mapStateToProps)(QueryTab);
