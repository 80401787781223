import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import {
  MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE,
  MODEL_STREAMLINE_REGISTRATION_VISIBILITY
} from '@shared/constants/model-streamline-registration';
import { getModelNameValidationTooltipText } from '@shared/utils/model-streamline-registration';
import RegisterModelSubtitleLabel from './RegisterModelSubtitleLabel';
import RegisterModelVersionTextField from './RegisterModelVersionTextField';
import RegisterModelTextField from './RegisterModelTextField';
import RegisterModelSelectModelToRegister from './RegisterModelSelectModelToRegister';
import RegisterModelRadioButtons from './RegisterModelRadioButtons';
import RegisterModelSwitchVisibility from './RegisterModelSwitchVisibility';

const RegisterModelFlowAsNewModel = ({
  allAvailableModelsOptions,
  modelVisibility,
  setModelVisibility,
  description,
  setDescription,
  modelVersion,
  setModelVersion,
  selectedModel,
  setSelectedModel,
  modelNameToRegister,
  setModelNameToRegister,
  setCreationStatus,
  creationStatus,
  validationNewModelVersion,
  validationNewModelName,
  modelAssetsInfo,
  isFirstModelRegistration
}) => {
  return (
    <Grid container spacing={4} direction="column">
      <RegisterModelSelectModelToRegister
        selectedModel={selectedModel}
        allAvailableModelsOptions={allAvailableModelsOptions}
        setSelectedModel={setSelectedModel}
        modelAssetsInfo={modelAssetsInfo}
      />
      {selectedModel && (
        <>
          <Grid item xs={12}>
            <RegisterModelSubtitleLabel
              text={`
              Register ${selectedModel?.label}
            `}
              isFirstModelRegistration={isFirstModelRegistration}
            />
            {!isFirstModelRegistration && (
              <RegisterModelRadioButtons
                value={creationStatus}
                onChange={(e, val) => setCreationStatus(val)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <RegisterModelTextField
              label="Model name"
              dataTest="streamline-model-sidebar-new-model-name-to-register"
              isValid={validationNewModelName?.isValid}
              value={modelNameToRegister}
              placeholder={selectedModel?.label}
              onChange={setModelNameToRegister}
              helperText={
                validationNewModelName?.originalModelName ===
                  modelNameToRegister &&
                getModelNameValidationTooltipText(
                  modelNameToRegister,
                  validationNewModelName
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <RegisterModelVersionTextField
              setModelVersion={setModelVersion}
              modelVersion={modelVersion}
              validationNewModelVersion={validationNewModelVersion}
            />
          </Grid>
          <Grid item xs={12}>
            <RegisterModelTextField
              dataTest="streamline-model-sidebar-model-description-to-register"
              label="Add description"
              value={description}
              placeholder="Enter text ..."
              onChange={setDescription}
              inputProps={{
                height: '84px',
                multiline: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RegisterModelSwitchVisibility
              value={modelVisibility}
              onChange={setModelVisibility}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

RegisterModelFlowAsNewModel.defaultProps = {
  modelAssetsInfo: null,
  selectedModel: null,
  modelNameToRegister: '',
  modelVersion: '',
  description: '',
  allAvailableModelsOptions: [],
  validationNewModelName: {},
  validationNewModelVersion: {}
};

RegisterModelFlowAsNewModel.propTypes = {
  modelVisibility: PropTypes.oneOf([
    MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PUBLIC,
    MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE
  ]).isRequired,
  validationNewModelName: PropTypes.shape({
    isValid: PropTypes.string,
    finalModelName: PropTypes.string,
    modelNameExists: PropTypes.bool,
    originalModelName: PropTypes.bool,
    validName: PropTypes.bool
  }),
  validationNewModelVersion: PropTypes.shape({
    validVersion: PropTypes.bool,
    versionExists: PropTypes.bool,
    isValid: PropTypes.bool
  }),
  description: PropTypes.string,
  isFirstModelRegistration: PropTypes.bool.isRequired,
  modelVersion: PropTypes.string,
  modelNameToRegister: PropTypes.string,
  setModelNameToRegister: PropTypes.func.isRequired,
  selectedModel: PropTypes.shape({
    experimentKey: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  modelAssetsInfo: PropTypes.shape({
    assetId: PropTypes.string,
    assetPath: PropTypes.string
  }),
  setSelectedModel: PropTypes.func.isRequired,
  setModelVersion: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  setModelVisibility: PropTypes.func.isRequired,
  setCreationStatus: PropTypes.func.isRequired,
  creationStatus: PropTypes.oneOf([
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION,
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL
  ]).isRequired,
  allAvailableModelsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default RegisterModelFlowAsNewModel;
