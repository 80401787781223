import { isEmpty, omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import DsAccordion from '@design-system-outdated/components/Accordion/Accordion';
import SyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter/SyntaxHighlighter';
import { usePCDBoxes } from '@experiment-management-shared/api';
import { PCDAssetData } from '@experiment-management-shared/types';

import {
  GraphicAnnotationsSection,
  GraphicStepSection,
  GraphicsDetailsModal,
  GraphicsDetailsModalProps,
  Label
} from '../GridPanel';
import { PCDDetail } from './PCDDetail';

type PCDDetailsModalProps = Omit<
  GraphicsDetailsModalProps<PCDAssetData>,
  'renderAssetViewer' | 'sections'
> & {
  hiddenLabelNames?: string[];
  score?: number;
};

const DEFAULT_LABEL_NAMES: string[] = [];

const colorToHex = (n: number, padStart = 2) => {
  if (n < 0 || n > 255) {
    throw new Error('Invalid input: Numbers must be in the range 0-255.');
  }

  return n.toString(16).padStart(padStart, '0');
};

function rgbToHex(rgb: number[]): string {
  if (rgb.length !== 3) {
    throw new Error('Invalid input: Array must have exactly 3 elements.');
  }

  return '#' + rgb.map(n => colorToHex(n)).join('');
}

export const PCDDetailsModal = ({
  assets,
  hiddenLabelNames: initialHiddenLabelNames = DEFAULT_LABEL_NAMES,
  onClose,
  selectedAsset,
  score: initialScore = 0
}: PCDDetailsModalProps) => {
  const [asset, setAsset] = useState(selectedAsset);
  const [hiddenLabelNames, setHiddenLabelNames] = useState(
    initialHiddenLabelNames
  );
  const [score, setScore] = useState(initialScore);

  const { data: boxes } = usePCDBoxes({ url: asset?.boxes });

  useEffect(() => {
    setAsset(selectedAsset);
  }, [selectedAsset]);

  useEffect(() => {
    setHiddenLabelNames(initialHiddenLabelNames);
  }, [initialHiddenLabelNames]);

  useEffect(() => {
    setScore(initialScore);
  }, [initialScore]);

  const metadataObject: object = useMemo(() => {
    try {
      if (!asset?.metadata) return null;

      return JSON.parse(asset?.metadata);
    } catch (_) {
      return null;
    }
  }, [asset?.metadata]);

  const formattedMetadata = useMemo(() => {
    if (!metadataObject) return null;

    const filteredMetadata = omit(metadataObject, [
      'xRange',
      'yRange',
      'zRange'
    ]);

    if (isEmpty(filteredMetadata)) return null;

    return JSON.stringify(filteredMetadata, null, 2);
  }, [metadataObject]);

  const labels: Label[] = useMemo(() => {
    if (!boxes) return [];

    return boxes.map(box => {
      return {
        color: rgbToHex(box.color),
        name: `${box.label} (${box.name})`,
        score: box.score
      };
    });
  }, [boxes]);

  const renderAssetViewer = ({ asset }: { asset: PCDAssetData }) => {
    return (
      <PCDDetail
        asset={asset}
        hiddenLabelNames={hiddenLabelNames}
        score={score}
      />
    );
  };

  const renderSections = () => {
    if (!asset) return null;

    return (
      <>
        <DsAccordion
          content={
            <GraphicStepSection
              asset={asset}
              assets={assets}
              onChangeSettings={({ asset: selectedAsset }) => {
                setAsset(selectedAsset as PCDAssetData);
              }}
            />
          }
          defaultExpanded
          id="asset-analysis"
          title="Asset Analysis"
        />

        {labels.length > 0 && (
          <DsAccordion
            content={
              <GraphicAnnotationsSection
                hiddenLabelNames={hiddenLabelNames}
                labels={labels}
                onChangeSettings={changes => {
                  setHiddenLabelNames(changes.hiddenLabelNames);
                  setScore(changes.score);
                }}
                score={score}
              />
            }
            defaultExpanded
            id="annotation-labels"
            title="Annotation Labels"
          />
        )}

        {formattedMetadata ? (
          <DsAccordion
            content={
              <SyntaxHighlighter
                code={formattedMetadata}
                fullWidth
                language="json"
                showLineNumbers={false}
                style={github}
                withCopyBtn
              >
                {formattedMetadata}
              </SyntaxHighlighter>
            }
            defaultExpanded
            id="asset-metadata-accordion"
            title="Asset Metadata"
          />
        ) : null}
      </>
    );
  };

  return (
    <GraphicsDetailsModal
      assets={assets}
      onClose={onClose}
      onChange={setAsset}
      renderAssetViewer={renderAssetViewer}
      sections={renderSections()}
      selectedAsset={asset}
    />
  );
};
