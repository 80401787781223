import React from 'react';
import { EmptyExperimentTab } from '@shared';

type GraphicsEmptyStateProps = {
  isComparePage?: boolean;
};
const GraphicsEmptyState = ({ isComparePage }: GraphicsEmptyStateProps) => {
  if (isComparePage) {
    return (
      <div className="full-height overflow-hidden">
        <div className="col-md-6 full-height">
          <EmptyExperimentTab>
            <EmptyExperimentTab.Title>
              No graphic assets
            </EmptyExperimentTab.Title>
          </EmptyExperimentTab>
        </div>

        <div className="col-md-6 full-height">
          <EmptyExperimentTab>
            <EmptyExperimentTab.Title>
              No graphic assets
            </EmptyExperimentTab.Title>
          </EmptyExperimentTab>
        </div>
      </div>
    );
  }
  return (
    <EmptyExperimentTab>
      <EmptyExperimentTab.Icon iconName="graphics" />
      <EmptyExperimentTab.Title>
        This experiment did not log any image, graphic, video or figure assets
      </EmptyExperimentTab.Title>
      <EmptyExperimentTab.Description>
        Use <b>log_image()</b> , <b>log_video()</b> or <b>log_figure()</b> on
        your Experiment object to log images, graphics, videos or figures during
        experiments.
      </EmptyExperimentTab.Description>
      <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/images/" />
    </EmptyExperimentTab>
  );
};

export default GraphicsEmptyState;
