import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import alertsUtil from '@/util/alertsUtil';
import { Tooltip, IconButton } from '@ds';
import { DSCopyIcon } from '@ds-icons';

const StyledCopyButton = ({
  ButtonIcon,
  text,
  tooltipContent,
  disabled,
  className
}) => {
  const dispatch = useDispatch();

  return (
    <CopyToClipboard text={text}>
      <Tooltip
        content={tooltipContent}
        wrapperDisplay="block"
        className={className}
      >
        <IconButton
          className="styled-copy-button"
          Icon={<ButtonIcon />}
          size="small"
          onClick={() => dispatch(alertsUtil.openCopySuccessSnackbar())}
          disabled={disabled}
          type="secondary"
        />
      </Tooltip>
    </CopyToClipboard>
  );
};

StyledCopyButton.defaultProps = {
  ButtonIcon: DSCopyIcon,
  text: '',
  tooltipContent: '',
  disabled: false
};

StyledCopyButton.propTypes = {
  ButtonIcon: PropTypes.elementType,
  tooltipContent: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool
};

export default StyledCopyButton;
