import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Input, { DS_INPUT_TYPES } from '@DesignSystem/controllers/Input/Input';
import { CancelIcon, ConfirmIcon } from '@Icons-outdated';

import styles from './TemplateSelect.module.scss';
import { getEditViewName } from '@/reducers/ui/projectsUiReducer';

const ViewListEditableItem = ({
  onEditNewName,
  template,
  onEditName,
  defaultNewViewName,
  defaultEditNameView,
  setEditNameView
}) => {
  const defaultEditName = useSelector(getEditViewName);

  return (
    <div
      className={styles.editNameContainer}
      onClick={e => e.stopPropagation()}
    >
      <div>
        <Input
          onKeyDown={e => {
            e.stopPropagation();
          }}
          onChange={onEditNewName}
          value={defaultEditName}
          type={DS_INPUT_TYPES.UNDERLINED}
        />
      </div>

      <div className={styles.editNameActions}>
        <ConfirmIcon
          onClick={() => {
            onEditName({ newName: defaultEditName, template });
            onEditNewName(defaultNewViewName);
            setEditNameView(defaultEditNameView);
          }}
        />
        <CancelIcon
          onClick={() => {
            onEditNewName(defaultNewViewName);
            setEditNameView(defaultEditNameView);
          }}
        />
      </div>
    </div>
  );
};

ViewListEditableItem.propTypes = {
  onEditNewName: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  onEditName: PropTypes.func.isRequired,
  defaultNewViewName: PropTypes.string.isRequired,
  defaultEditNameView: PropTypes.object.isRequired,
  setEditNameView: PropTypes.func.isRequired
};

export default ViewListEditableItem;
