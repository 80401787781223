import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isFunction from 'lodash/isFunction';

import { trackEvent } from '@shared/utils/eventTrack';
import { onboardingEvents } from '@onboarding/constants';
import useOnboardingFirstClickStatus from './useOnboardingFirstClickStatus';

const classesToExceptForEvent = [
  'quickStartContainer',
  'ds-simple-card-container',
  'quickStartLabel',
  'fullExampleTitle',
  'not-focusable',
  'snippetBlock',

  'getStartedBlock',
  'postTitleContainer',
  'preTitleLabel',
  'blockTitle'
];

const tagsToExceptForEvent = [
  'SPAN',
  'P',
  'H4',
  'H2',
  'H1',
  'PRE',
  'svg',
  'path'
];

const doesClassNameContain = className => {
  if (!isFunction(className?.includes)) {
    return false;
  }

  return !!classesToExceptForEvent.find(cn => className.includes(cn));
};

const handleClick = target => {
  if (
    doesClassNameContain(target.className) ||
    tagsToExceptForEvent.find(tagName => target.tagName === tagName)
  ) {
    return null;
  }

  if (target.tagName === 'IMG' && target.src.includes('/logo_comet')) {
    return { value: '[Home Button]', isFromList: true };
  }

  if (target.tagName === 'DIV' && target.className.includes('login-menu')) {
    return { value: '[User menu is open]', isFromList: true };
  }

  if (
    target.tagName === 'svg' &&
    target?.className?.baseVal?.includes('HelpSidebar')
  ) {
    return { value: '[Help Header Button]', isFromList: true };
  }

  return {
    value: {
      tagName: target.tagName,
      innerText: target.tagName === 'A' ? target.innerText : null,
      className: target.className
    },
    isFromList: false
  };
};

const useOnboardingFirstClickWholeApp = () => {
  const dispatch = useDispatch();

  const {
    shouldSend,
    onFirstClick,
    pageName
  } = useOnboardingFirstClickStatus();

  useEffect(() => {
    const handler = e => {
      const clickInfo = handleClick(e.target);

      if (clickInfo) {
        trackEvent(onboardingEvents.FIRST_CLICK, {
          event_page: pageName,
          click_event: clickInfo.isFromList ? clickInfo.value : null,
          other_click: clickInfo.isFromList ? null : clickInfo.value
        });
      }

      onFirstClick();
    };

    if (shouldSend) {
      window.addEventListener('click', handler);
    }

    if (!shouldSend) {
      window.removeEventListener('click', handler);
    }

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [shouldSend, onFirstClick, dispatch, pageName]);
};

export default useOnboardingFirstClickWholeApp;
