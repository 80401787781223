import classes from './ListComponents.module.scss';
import cx from 'classnames';
import typography from '@ds-typography';
import React, { useEffect } from 'react';
import { useListItemContext } from '../useListItemContext';
import { getIsDefaultActiveStyle } from '../helpers';

export interface ListTextProps {
  primary: string;
  secondary?: string;
}

const ListText = ({ primary, secondary = '' }: ListTextProps) => {
  const {
    isWithCheckbox,
    isWithRadioButton,
    active,
    disabled,
    setIsWithSecondaryText
  } = useListItemContext();

  useEffect(() => {
    setIsWithSecondaryText(!!secondary);
  }, [setIsWithSecondaryText, secondary]);

  return (
    <div className={classes.textContainer}>
      <div
        className={cx(classes.primaryText, {
          [classes.disabled]: disabled,
          [typography.dsAccented]: getIsDefaultActiveStyle(
            active,
            isWithCheckbox,
            isWithRadioButton
          )
        })}
      >
        {primary}
      </div>
      {secondary && (
        <div
          className={cx(classes.secondaryText, typography.dsBodySmall, {
            [classes.disabled]: disabled
          })}
        >
          {secondary}
        </div>
      )}
    </div>
  );
};

export default ListText;
