import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

import { EditMetricDataType } from '@mpm/types';

type MetricEditMutationProps = {
  metricData: EditMetricDataType;
};

export function useMetricEdit() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ metricData }: MetricEditMutationProps) => {
      return api.put(
        `mpm/${activeWorkspace?.id}/models/${modelId}/custom_transformations/${metricData.id}`,
        metricData
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'custom-metrics',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        queryClient.invalidateQueries(['getModelRegistryNameById']);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_EDIT_METRIC,
            'The metric was successfully edited.'
          )
        );
      }
    }
  );
}
