import { find, get, isEmpty, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  GRAPHICS_ASSETS_FIELDS_MAP,
  GRAPHICS_ASSETS_TYPES,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from '@experiment-details/constants/graphics';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';
import { alternateMerge } from '@experiment-details/utils/graphics';
import { ImageDetailsModal } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/ImagePanel/ImageDetailsModal';
import { VideoDetailsModal } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/VideoPanel/VideoDetailsModal';

import GraphicsConfigBar from '../GraphicsConfigBar';
import GroupedGraphicsList from '../GraphicsGallery/GroupedGraphicsList';
import VirtualizedGrid from '../GraphicsVirtualizedGrid';

import styles from './GraphicsGalleryCompare.module.scss';
import { TableMaxHeightProvider } from '@DesignSystem/tables';

const GraphicsGalleryCompare = ({
  experiments,
  experiment1,
  experiment2,
  experimentKey,
  experiment1Graphics,
  experiment2Graphics,
  graphics,
  location
}) => {
  const { pathname, search } = location;
  const parsedQuery = queryString.parse(search, {
    parseBooleans: true
  });

  const [groupBy, setGroupBy] = useState(false);
  const [filteredGraphicsAssets, setFilteredGraphicsAssets] = useState([]);

  const [previewGraphicsId, setPreviewGraphicsId] = useState(
    get(parsedQuery, 'graphicsAssetId', null)
  );
  const [previewGraphics, setPreviewGraphics] = useState(graphics);

  useQueryParamsForExperiments(
    {
      graphicsAssetId: previewGraphicsId
    },
    [previewGraphicsId, pathname],
    experimentKey
  );

  useEffect(() => {
    if (previewGraphicsId) {
      openImageModal(previewGraphicsId, graphics);
    }
  }, [previewGraphicsId, graphics]);

  const onGraphicAssetsFilter = (newFilteredGraphicAssets, groupedBy) => {
    setFilteredGraphicsAssets(newFilteredGraphicAssets);
    setGroupBy(groupedBy);
  };

  const [initialAsset, setInitialAsset] = useState();

  const getMergedGraphics = useCallback(
    graphicsToDisplay => {
      const baseGraphicsIds = experiment1Graphics.map(
        graphicAsset => graphicAsset[GRAPHICS_ASSETS_FIELDS_MAP.id]
      );
      const compareGraphicsIds = experiment2Graphics.map(
        graphicAsset => graphicAsset[GRAPHICS_ASSETS_FIELDS_MAP.id]
      );

      const filteredExperiment1Images = graphicsToDisplay.filter(graphicAsset =>
        baseGraphicsIds.includes(graphicAsset[GRAPHICS_ASSETS_FIELDS_MAP.id])
      );
      const filteredExperiment2Images = graphicsToDisplay.filter(graphicAsset =>
        compareGraphicsIds.includes(graphicAsset[GRAPHICS_ASSETS_FIELDS_MAP.id])
      );

      const mergedExperimentsImages = alternateMerge(
        filteredExperiment1Images,
        filteredExperiment2Images
      );

      return mergedExperimentsImages;
    },
    [experiment1Graphics, experiment2Graphics]
  );

  const filteredGraphicsWithPlaceholders = useMemo(() => {
    if (!groupBy) return getMergedGraphics(filteredGraphicsAssets);
    return mapValues(filteredGraphicsAssets, images =>
      getMergedGraphics(images)
    );
  }, [filteredGraphicsAssets, getMergedGraphics, groupBy]);

  const openImageModal = (graphicsAssetId, imagesFilteredByStep) => {
    const graphicsAssetInside = find(
      imagesFilteredByStep,
      asset => asset[GRAPHICS_ASSETS_FIELDS_MAP.id] === graphicsAssetId
    );
    if (!graphicsAssetInside) return;
    setInitialAsset(graphicsAssetInside);
  };

  const handleOpenGraphicsPreview = (graphicsAsset, graphics) => {
    const graphicsAssetId = graphicsAsset[GRAPHICS_ASSETS_FIELDS_MAP.id];
    setPreviewGraphicsId(graphicsAssetId);
    setPreviewGraphics(graphics);
    openImageModal(graphicsAssetId, graphics);
  };

  const renderList = () => {
    return (
      <TableMaxHeightProvider>
        {containerHeight => (
          <VirtualizedGrid
            itemWidth={IMAGE_WIDTH}
            handleOpenImagePreview={handleOpenGraphicsPreview}
            rowHeight={IMAGE_HEIGHT}
            rowItemsCount={2}
            containerHeight={containerHeight}
            items={filteredGraphicsWithPlaceholders}
          />
        )}
      </TableMaxHeightProvider>
    );
  };

  const renderBody = () => {
    if (isEmpty(filteredGraphicsAssets)) {
      return <div className="charts-gallery-empty-results">No Results</div>;
    }

    if (groupBy) {
      return (
        <GroupedGraphicsList
          handleOpenImagePreview={handleOpenGraphicsPreview}
          filteredImages={filteredGraphicsWithPlaceholders}
          groupBy={groupBy}
          rowItemsCount={2}
        />
      );
    }

    return renderList();
  };

  const graphicType = initialAsset?.[GRAPHICS_ASSETS_FIELDS_MAP.type];

  return (
    <>
      {initialAsset && graphicType === GRAPHICS_ASSETS_TYPES.image ? (
        <ImageDetailsModal
          assets={previewGraphics}
          onClose={() => {
            setInitialAsset(null);
            setPreviewGraphicsId(null);
          }}
          onChange={asset => setPreviewGraphicsId(asset.id)}
          selectedAsset={initialAsset}
        />
      ) : null}
      {initialAsset && graphicType === GRAPHICS_ASSETS_TYPES.video ? (
        <VideoDetailsModal
          assets={previewGraphics}
          onChange={asset => setPreviewGraphicsId(asset.id)}
          onClose={() => {
            setInitialAsset(null);
            setPreviewGraphicsId(null);
          }}
          selectedAsset={initialAsset}
        />
      ) : null}
      <div className={styles.graphicsTabCompare}>
        <GraphicsConfigBar
          experimentKey={experimentKey}
          graphicAssets={graphics}
          onGraphicAssetsFilter={onGraphicAssetsFilter}
        />
        <CompareContentSelector
          experiments={experiments}
          experiment1={experiment1}
          experiment2={experiment2}
        />
        <div className={styles.graphicsTabCompareList}>{renderBody()}</div>
      </div>
    </>
  );
};

GraphicsGalleryCompare.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  experiment1: IExperimentDetails.isRequired,
  experiment2: IExperimentDetails.isRequired,
  experimentKey: PropTypes.string.isRequired,
  experiment1Graphics: PropTypes.array.isRequired,
  experiment2Graphics: PropTypes.array.isRequired,
  graphics: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(connect()(GraphicsGalleryCompare));
