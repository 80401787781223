// Based off of https://www.npmjs.com/package/mui-redux-alerts
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SnackbarWrapper } from './SnackbarWrapper';
import { DialogWrapper } from './DialogWrapper';

const cleanAndOrder = obj =>
  Object.keys(obj)
    .map(key => ({ open: true, key, ...obj[key] }))
    .sort((a, b) => a.timestamp - b.timestamp)
    .map(_p => {
      const p = _p;
      delete p.timestamp;
      return p;
    });

const Alerts = ({ alerts }) => (
  <div>
    {cleanAndOrder(alerts.snackbars).map(p => {
      return <SnackbarWrapper key={p.key} {...p} keyclass={p.key} />;
    })}
    {cleanAndOrder(alerts.dialogs).map(p => {
      return <DialogWrapper key={p.key} {...p} keyclass={p.key} />;
    })}
  </div>
);

Alerts.propTypes = {
  alerts: PropTypes.shape({
    dialogs: PropTypes.object.isRequired,
    snackbars: PropTypes.object.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  alerts: state.alerts
});

export default connect(mapStateToProps)(Alerts);
