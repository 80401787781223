import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import Paper from '@material-ui/core/Paper';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import { TEMPLATE_SCOPE_TYPES } from '@experiment-management-shared/constants/chartsGallery';

import ChartNameField from '@experiment-management-shared/components/ChartNameField';
import visualizationsActions from '@/actions/visualizationsActions';
import { iFrameConfig } from '@experiment-management-shared/constants';
import { getTemplateIdFromURL } from '@experiment-management-shared/utils/visualizationsHelper';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { getProjectToken } from '@/reducers/visualizationsReducer';
import { getTemplateName } from '@/selectors/visualizationSelectors';

import SmallLoader from '@shared/components/SmallLoader';
import { CodeIframe } from '@experiment-management-shared/components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DSErrorIcon } from '@ds-icons';

const CodePreview = ({
  canEdit,
  projectId,
  srcDoc,
  templateName,
  templateScopeType,
  token,
  showRunLabel,
  iframeUrl,
  showIsLoading,
  errorLabel
}) => {
  const dispatch = useDispatch();

  const renderHint = () => {
    if (showIsLoading) {
      return (
        <div className="run-code-hint">
          <CircularProgress size={30} />
        </div>
      );
    }

    if (showRunLabel) {
      return (
        <div className="run-code-hint">
          To run this panel, press the green play button in the top right corner
          of the code editor
        </div>
      );
    }

    if (errorLabel)
      return (
        <div className="run-code-hint error">
          <DSErrorIcon />
          {errorLabel}
        </div>
      );

    return null;
  };

  useEffect(() => {
    dispatch(visualizationsActions.fetchProjectToken(projectId));
  }, [projectId]);

  const handleUpdateTemplateName = newTemplateName => {
    dispatch(visualizationsActions.updateTemplateNameField(newTemplateName));
  };

  const { INTERNAL, PRIVATE } = TEMPLATE_SCOPE_TYPES;
  const isUserAllowedToExtendedPermissions =
    canEdit || [INTERNAL, PRIVATE].includes(templateScopeType);

  const renderIframe = () => {
    if (showRunLabel) {
      return null;
    }

    return (
      <CodeIframe
        isExtendedPermissions={isUserAllowedToExtendedPermissions}
        isInternalScopeType={templateScopeType === INTERNAL}
        srcDoc={srcDoc}
        url={iframeUrl}
        id={iFrameConfig.iframeId}
      />
    );
  };

  if (!token) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Generating panel token"
      />
    );
  }

  return (
    <Paper className="code-preview" elevation={1}>
      <div className="chart-preview-header">
        <ChartNameField
          maxWidth={400}
          placeholder={`Default ${PANEL_ENTITY_NAME} name`}
          chartNameEditHandler={handleUpdateTemplateName}
          currentName={templateName}
          isCodeEditor
        />
      </div>

      <div className="chart-preview-panel">
        {renderHint()}
        {renderIframe()}
      </div>
    </Paper>
  );
};

CodePreview.defaultProps = {
  templateId: null,
  token: null,
  iframeUrl: null,
  showIsLoading: false,
  errorLabel: ''
};

CodePreview.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  srcDoc: PropTypes.string.isRequired,
  templateId: PropTypes.string,
  templateName: PropTypes.string.isRequired,
  templateScopeType: PropTypes.string.isRequired,
  token: PropTypes.string,
  showRunLabel: PropTypes.bool.isRequired,
  iframeUrl: PropTypes.string,
  showIsLoading: PropTypes.bool,
  errorLabel: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const { codeTemplateId, revisionId } = props.match.params;
  const templateId = getTemplateIdFromURL(codeTemplateId);

  return {
    canEdit: isUserAllowedToEditProject(state),
    templateName: getTemplateName(state, { templateId, revisionId }),
    token: getProjectToken(state)
  };
};

export default withRouter(connect(mapStateToProps)(CodePreview));
