export const MAX_GROUPING_TRACES = 25;

export const getMaxDisplayedNumbers = (
  metricNumber: number
): {
  metricAmount: number;
  traceAmount: number;
} => {
  if (!metricNumber) {
    return { metricAmount: 0, traceAmount: 0 };
  }

  const traceAmount = Math.floor(MAX_GROUPING_TRACES / metricNumber);

  if (traceAmount) {
    return {
      metricAmount: metricNumber,
      traceAmount
    };
  }

  // metricNumber > MAX_TRACES
  return {
    metricAmount: MAX_GROUPING_TRACES,
    traceAmount: 1
  };
};
