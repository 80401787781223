import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import isUndefined from 'lodash/isUndefined';

import { OutputFormatType } from 'diff2html/lib/types';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { calculateExperimentName } from '@experiment-management-shared/utils/experimentHelpers';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';
import SmallLoader from '@shared/components/SmallLoader';
import SimpleDiff from '@experiment-details/components/Code/SimpleDiff';
import SimpleCode from '@experiment-details/components/Code/SimpleCode';
import BaseCodeCompareHeader from '@experiment-details/components/Code/BaseCodeCompareHeader';
import BaseCodeCompareTabBar from '@experiment-details/components/Code/BaseCodeCompareTabBar';
import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import { COMPARE_TAB } from '@experiment-details/constants/code';
import { isDiffTab, isSingleTab } from '@experiment-details/utils/code';
import GitCompareButton from './GitCompareButton';
import {
  EXPERIMENT_NUMBER,
  SELECTOR_TYPE
} from '@experiment-details/constants/general';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import useSelectedExperiments from '@experiment-details/hooks/useSelectedExperiments';
import useExperimentCodeWrapper from '@experiment-details/hooks/useExperimentCodeWrapper';

const MAX_SELECT_INPUT_WIDTH = 540;
const DIFF_OUTPUT_FORMATS = {
  split: OutputFormatType.SIDE_BY_SIDE,
  unified: OutputFormatType.LINE_BY_LINE
};

const CodeCompareTab = ({ experiments }) => {
  const [experiment] = experiments;

  const { experiment1, experiment2 } = useSelectedExperiments(experiments);
  const location = useLocation();
  const { baseFile: file1 = '', compareFile: file2 = '' } = queryString.parse(
    location.search
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(COMPARE_TAB.EXPERIMENT_1);

  const {
    fileOptions: experiment1FileOptions,
    fileOption: experiment1FileOption,
    setFileOption: setExperiment1FileOption,
    isLoading: isLoadingExperiment1,
    code: experiment1Code
  } = useExperimentCodeWrapper({
    experiment: experiment1,
    file: file1,
    fileField: 'fullLabel',
    maxSelectWidth: MAX_SELECT_INPUT_WIDTH
  });

  const {
    fileOptions: experiment2FileOptions,
    fileOption: experiment2FileOption,
    setFileOption: setExperiment2FileOption,
    isLoading: isLoadingExperiment2,
    code: experiment2Code
  } = useExperimentCodeWrapper({
    experiment: experiment2,
    file: file2,
    fileField: 'fullLabel',
    maxSelectWidth: MAX_SELECT_INPUT_WIDTH
  });

  const isLoading = isLoadingExperiment1 || isLoadingExperiment2;

  const isDiff = Boolean(
    experiment1Code && experiment2Code && experiment1Code !== experiment2Code
  );

  useQueryParamsForExperiments(
    {
      baseFile: experiment1FileOption.fullLabel,
      compareFile: experiment2FileOption.fullLabel
    },
    [experiment1FileOption.fullLabel, experiment2FileOption.fullLabel],
    experiment.experimentKey,
    {
      preventUpdate:
        isUndefined(experiment1FileOption.fullLabel) ||
        isUndefined(experiment2FileOption.fullLabel)
    }
  );

  useEffect(() => {
    if (!experiment1FileOptions.length) return;

    const fileOption =
      experiment1FileOptions.find(option => option.fullLabel === file1) ||
      experiment1FileOptions[0];

    setExperiment1FileOption(fileOption);
  }, [file1, experiment1FileOptions]);

  useEffect(() => {
    if (!experiment2FileOptions.length) return;

    const fileOption =
      experiment2FileOptions.find(option => option.fullLabel === file2) ||
      experiment2FileOptions[0];

    setExperiment2FileOption(fileOption);
  }, [file2, experiment2FileOptions]);

  // handle case when user open the page first time, and we want to select diff view by default
  useEffect(() => {
    if (isLoading || isLoaded) return;
    setIsLoaded(true);

    if (isDiff && !isDiffTab(selectedTab)) {
      setSelectedTab(COMPARE_TAB.SPLIT);
    }
  }, [isDiff, isLoading, isLoaded, selectedTab]);

  // handle case when user select the experiment that doesn't have diff view
  useEffect(() => {
    if (isLoading || !isLoaded) return;

    if (!isDiff && isDiffTab(selectedTab)) {
      setSelectedTab(COMPARE_TAB.EXPERIMENT_1);
    }
  }, [isDiff, isLoading, isLoaded, selectedTab]);

  const handleSelectTab = (e, value) => {
    setSelectedTab(value);
  };

  const handleExperimentFileChange = useCallback((experimentSide, option) => {
    // eslint-disable-next-line default-case
    switch (experimentSide) {
      case EXPERIMENT_NUMBER.EXPERIMENT_1:
        setExperiment1FileOption(option);
        break;
      case EXPERIMENT_NUMBER.EXPERIMENT_2:
        setExperiment2FileOption(option);
        break;
    }
  }, []);

  const renderSingleView = () => {
    const experiment =
      selectedTab === COMPARE_TAB.EXPERIMENT_1 ? experiment1 : experiment2;
    const code =
      selectedTab === COMPARE_TAB.EXPERIMENT_1
        ? experiment1Code
        : experiment2Code;
    const assetId =
      selectedTab === COMPARE_TAB.EXPERIMENT_1
        ? experiment1FileOption.value
        : experiment2FileOption.value;

    return (
      <div>
        {Boolean(code) && (
          <BaseCodeCompareHeader
            isDiff={isDiff}
            experiment={experiment}
            assetId={assetId}
            detailView={DETAIL_VIEWS.CODE}
          />
        )}

        <SimpleCode code={code} isLoading={isLoading} experiment={experiment} />
      </div>
    );
  };

  const renderDiffView = () => {
    if (!isDiff) return null;

    return (
      <SimpleDiff
        fileName1={experiment1FileOption.fullLabel}
        content1={experiment1Code}
        fileName2={experiment2FileOption.fullLabel}
        content2={experiment2Code}
        diffOutputFormat={DIFF_OUTPUT_FORMATS[selectedTab]}
      />
    );
  };

  if (isLoading) {
    return <SmallLoader />;
  }

  const diffView = isDiffTab(selectedTab);
  const singleView = isSingleTab(selectedTab);

  return (
    <div className="code-compare__tab">
      <CompareContentSelector
        experiment1={experiment1}
        experiment2={experiment2}
        experiments={experiments}
        experiment1FileOptions={experiment1FileOptions}
        experiment1FileOption={experiment1FileOption}
        experiment2FileOptions={experiment2FileOptions}
        experiment2FileOption={experiment2FileOption}
        onExperimentFileChange={handleExperimentFileChange}
        type={SELECTOR_TYPE.OUTLINED}
      />
      <div className="code-tab-compare-tab-container">
        <BaseCodeCompareTabBar
          experimentName1={calculateExperimentName(experiment1)}
          experimentName2={calculateExperimentName(experiment2)}
          isDiff={isDiff}
          selectedTab={selectedTab}
          tabSelectHandler={handleSelectTab}
        />
        <GitCompareButton
          experimentKey1={experiment1.experimentKey}
          experimentKey2={experiment2.experimentKey}
        />
      </div>
      {singleView && renderSingleView()}
      {diffView && renderDiffView()}
    </div>
  );
};

CodeCompareTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default CodeCompareTab;
