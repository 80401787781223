import { useQuery } from 'react-query';

import { axiosInstance, cancelWrapper } from '@API/api';
import useProject from '@API/project/useProject';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';

const getExperimentsPanelParameters = ({
  experimentKeys,
  fetchFull,
  metrics,
  metricNames,
  projectId,
  params,
  sampleSize,
  type
}) => {
  if (
    [
      BUILT_IN_CHART_TYPES['BuiltIn/Line'],
      BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
      BUILT_IN_CHART_TYPES['BuiltIn/Scatter']
    ].includes(type)
  ) {
    const normalizedMetrics =
      type === BUILT_IN_CHART_TYPES['BuiltIn/Bar'] && metrics?.length > 0
        ? metrics.map(({ name }) => name)
        : metricNames;

    return {
      independentMetrics: true,
      fetchFull,
      metrics: normalizedMetrics,
      params,
      projectId,
      sampleSize,
      targetExperiments: experimentKeys
    };
  }

  return null;
};

const getExperimentsPanelData = async ({ data, type }) => {
  return cancelWrapper(async cancelToken => {
    if (
      [
        BUILT_IN_CHART_TYPES['BuiltIn/Line'],
        BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
        BUILT_IN_CHART_TYPES['BuiltIn/Scatter']
      ].includes(type)
    ) {
      const response = await axiosInstance({
        cancelToken,
        data,
        method: 'post',
        url: 'dashboard/plotly-multi-metric-chart'
      });

      return response.data;
    }

    throw new Error(`Non existent type: ${type}`);
  });
};

export default function useExperimentsPanelData(data, config) {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  const panelParameters = getExperimentsPanelParameters({
    projectId,
    ...data
  });

  return useQuery(
    [
      'panel',
      {
        panelParameters,
        projectId,
        type: data.type
      }
    ],
    () => getExperimentsPanelData({ data: panelParameters, type: data.type }),
    {
      keepPreviousData: true,
      ...config
    }
  );
}
