import React, { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import { ModelOverviewIcon, CustomMetricsIcon } from '@Icons-outdated';
import ModelSettings from './ModelSettings';
import CustomMetrics from './CustomMetrics';
import ModelOverview from './ModelOverview';
import { NAV_ITEMS_VALUES } from '@mpm-druid/constants';
import { ModelDetailsType } from '@mpm-druid/types';
import styles from './CustomTransformationsPage.modules.scss';

type CustomTransformationsPageProps = {
  model: ModelDetailsType;
};

type NavItem = {
  label: string;
  prefixIcon: React.ReactNode;
  value: string;
};

export const CustomTransformationsPage = ({
  model
}: CustomTransformationsPageProps) => {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const { panel } = queryParams;

  const navItems = useMemo(() => {
    return [
      {
        label: 'Model overview',
        value: NAV_ITEMS_VALUES.MODEL_OVERVIEW,
        prefixIcon: ModelOverviewIcon
      },
      {
        label: 'Custom metrics',
        value: NAV_ITEMS_VALUES.CUSTOM_METRICS,
        prefixIcon: CustomMetricsIcon
      }
    ];
  }, []);

  const [activeItem, setActiveItem] = useState(() => {
    return panel === NAV_ITEMS_VALUES.CUSTOM_METRICS
      ? navItems[1].value
      : navItems[0].value;
  });
  const { createdAt, name, id } = model;
  const handleNavChange = (menuItem: NavItem) => {
    setActiveItem(menuItem.value);
  };

  const renderActivePanel = useCallback(
    param => {
      switch (param) {
        case NAV_ITEMS_VALUES.MODEL_OVERVIEW:
          return (
            <ModelOverview createdAt={createdAt} modelId={id} name={name} />
          );
        case NAV_ITEMS_VALUES.CUSTOM_METRICS:
          return <CustomMetrics model={model} />;
        default:
          return <CustomMetrics model={model} />;
      }
    },
    [createdAt, name, id, model]
  );

  return (
    <div className={styles.customTransformationWrapper}>
      <ModelSettings
        navItems={navItems}
        activeItem={activeItem}
        handleNavChange={handleNavChange}
      />
      <div className={styles.rightPanelWrapper}>
        {renderActivePanel(activeItem)}
      </div>
    </div>
  );
};
