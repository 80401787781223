const KEY_TITLE = 'Name';
const VALUE_TITLE = 'Value';

export const SYSTEM_METRICS_COLUMNS_NAME = {
  KEY: 'key',
  VALUE: 'value'
};

export const SYSTEM_METRICS_TABLE_COLUMNS = [
  { name: SYSTEM_METRICS_COLUMNS_NAME.KEY, title: KEY_TITLE },
  { name: SYSTEM_METRICS_COLUMNS_NAME.VALUE, title: VALUE_TITLE }
];
