import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Engine } from '@babylonjs/core/Engines/engine';
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import type { Particle } from '@babylonjs/core/Particles/particle';
import { PointsCloudSystem } from '@babylonjs/core/Particles/pointsCloudSystem';
import { Scene } from '@babylonjs/core/scene';

import { PCDBox } from '@experiment-management-shared/api';

export const MESH_NAMES = {
  BOX: 'box',
  CLICKABLE_ANCHOR: 'clickable-anchor',
  POINTS: 'points'
};

export type BoxState = {
  labelName: string;
  score: number;
};

export const createChangeAnchor = ({
  camera,
  scene
}: {
  camera: ArcRotateCamera;
  scene: Scene;
}) => () => {
  const ray = scene.createPickingRay(
    scene.pointerX,
    scene.pointerY,
    Matrix.Identity(),
    null
  );
  const hit = scene.pickWithRay(ray);
  const previousRadius = camera.radius;

  if (hit?.pickedPoint) {
    camera.target = hit.pickedPoint;
  }
  camera.radius = previousRadius;
};

export const initializeScene = (canvas: HTMLCanvasElement) => {
  const engine = new Engine(canvas, true);
  const scene = new Scene(engine);
  const camera = new ArcRotateCamera('camera', 0, 0, 0, Vector3.Zero(), scene);
  // camera.lowerRadiusLimit = 0;
  camera.allowUpsideDown = false;
  camera.upVector = new Vector3(0, 0, Math.PI);
  scene.useRightHandedSystem = true;
  camera.attachControl(canvas, true);

  engine.runRenderLoop(() => {
    scene.render();
  });

  return { camera, engine, scene };
};

export const processJSONLFileInChunks = ({
  signal,
  url
}: {
  signal?: AbortSignal;
  url?: string;
}) => async (callback: (lines: string[]) => void) => {
  if (!url) return;

  try {
    // Fetch the JSONL file using streaming
    const response = await fetch(url, { credentials: 'include', signal });

    if (!response.ok || !response.body) {
      throw new Error(
        `Failed to fetch JSONL file: ${response.status} ${response.statusText}`
      );
    }

    // Create a ReadableStream from the response body
    const reader = response.body.getReader();
    let chunk;
    let done = false;
    const textDecoder = new TextDecoder('utf-8');

    let previousPartialLine = '';
    // Process the JSONL file in chunks
    while (!done) {
      ({ value: chunk, done } = await reader.read());

      const chunkText = previousPartialLine + textDecoder.decode(chunk);
      const lines = chunkText.split('\n');
      previousPartialLine = lines.pop() || '';

      if (lines.length) {
        callback(lines);
      }
    }

    if (previousPartialLine) {
      callback(previousPartialLine.split('\n'));
    }
  } catch (_) {
    // Abort signal
  }
};

export function textPointToPoint(textPoint: string) {
  return textPoint
    .substring(1, textPoint.length - 1)
    .split(',')
    .map(n => Number(n));
}

const CENTER_ALLOWED_MESH_NAMES = [MESH_NAMES.BOX, MESH_NAMES.POINTS];
export function calculateSceneExtents(
  scene: Scene
): { min: Vector3; max: Vector3 } {
  let minX = Infinity;
  let minY = Infinity;
  let minZ = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  let maxZ = -Infinity;

  scene.meshes.forEach(mesh => {
    if (!CENTER_ALLOWED_MESH_NAMES.includes(mesh.name)) {
      return;
    }

    const boundingBox = mesh.getBoundingInfo().boundingBox;

    minX = Math.min(minX, boundingBox.minimumWorld.x);
    minY = Math.min(minY, boundingBox.minimumWorld.y);
    minZ = Math.min(minZ, boundingBox.minimumWorld.z);

    maxX = Math.max(maxX, boundingBox.maximumWorld.x);
    maxY = Math.max(maxY, boundingBox.maximumWorld.y);
    maxZ = Math.max(maxZ, boundingBox.maximumWorld.z);
  });

  return {
    min: new Vector3(minX, minY, minZ),
    max: new Vector3(maxX, maxY, maxZ)
  };
}

export function addPointsToScene({
  scene,
  textPoints
}: {
  scene: Scene;
  textPoints: string[];
}) {
  const pcs = new PointsCloudSystem(MESH_NAMES.POINTS, 2, scene);

  pcs.addPoints(textPoints.length, (particle: Particle, i: number) => {
    const [x, y, z, r, g, b] = textPointToPoint(textPoints[i]);

    particle.position.x = x;
    particle.position.y = y;
    particle.position.z = z;
    particle.color = new Color4(r / 255, g / 255, b / 255, 1);
  });

  // Build the points cloud system
  return pcs.buildMeshAsync();
}

export function centerCamera({
  camera,
  scene
}: {
  camera: ArcRotateCamera;
  scene: Scene;
}) {
  const { min, max } = calculateSceneExtents(scene);

  const xRange = Math.abs(min.x - max.x);
  const yRange = Math.abs(min.y - max.y);

  camera.setPosition(
    new Vector3(
      // Rotated the x-axis in 45 degrees (* 1.5)
      Math.floor((min.x + max.x) / 2) + xRange * 1.5,
      Math.floor((min.y + max.y) / 2) + yRange,
      xRange * 2
    )
  );

  camera.setTarget(
    new Vector3((min.x + max.x) / 2, (min.y + max.y) / 2, (min.z + max.z) / 2)
  );
}

export function addBoxes({ boxes, scene }: { boxes: PCDBox[]; scene: Scene }) {
  for (const box of boxes) {
    const labelName = `${box.label} (${box.name})`;
    const boxPoints = box.segments
      .flat()
      .map(([x, y, z]) => new Vector3(x, y, z));

    const [r, g, b] = box.color;

    box.segments.forEach(segment => {
      const segmentPoints = segment.map(([x, y, z]) => new Vector3(x, y, z));
      const lineMesh = MeshBuilder.CreateLines(
        MESH_NAMES.BOX,
        {
          points: segmentPoints,
          colors: segmentPoints.map(
            () => new Color4(r / 255, g / 255, b / 255, 1)
          )
        },
        scene
      );

      lineMesh.state = JSON.stringify({
        labelName,
        score: box.score
      } as BoxState);
      // Start invisible to calculate the center first and then make them visible
      lineMesh.visibility = 0;
    });

    // Calculate the bounding box dimensions
    let minPoint = boxPoints[0].clone();
    let maxPoint = boxPoints[0].clone();

    // Find the min and max points
    boxPoints.forEach(point => {
      minPoint = Vector3.Minimize(minPoint, point);
      maxPoint = Vector3.Maximize(maxPoint, point);
    });

    const size = maxPoint.subtract(minPoint);

    // Create an invisible box mesh for picking
    const invisibleBox: Mesh = MeshBuilder.CreateBox(
      MESH_NAMES.CLICKABLE_ANCHOR,
      { width: size.x, height: size.y, depth: size.z },
      scene
    );
    invisibleBox.position = minPoint.add(size.scale(0.5));
    invisibleBox.isVisible = false;
  }
}
