import { useQuery } from 'react-query';

import useProject from '../project/useProject';

import { axiosInstance, cancelWrapper } from '../api';

const getProjectToken = async ({ projectId }) =>
  cancelWrapper(async cancelToken => {
    const {
      data: { token }
    } = await axiosInstance({
      cancelToken,
      data: { projectId },
      method: 'post',
      url: 'token/projects'
    });

    return token;
  });

export default function useProjectToken(
  { chartId, selectedProjectId = null },
  config
) {
  const { data: project } = useProject();
  const projectId = selectedProjectId ?? project?.projectId;

  return useQuery(
    ['projectToken', { chartId, projectId }],
    () => getProjectToken({ projectId }),
    config
  );
}
