import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { BasicModal } from '@DesignSystem/modals';
import { RadioButton, Select } from '@DesignSystem/controllers';
import useProject from '@API/project/useProject';

import useMoveExperimentsMutation from '@API/experiments/useMoveExperimentsMutation';
import useSymlinkExperimentsMutation from '@API/experiments/useSymlinkExperimentsMutation';
import styles from './MoveExperimentModal.module.scss';
import { PROJECT_TYPE } from '../../../constants/projectConstants';
import { useActiveWorkspace, useAllUserWorkspaces } from '@shared/hooks';

import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import { BETA_FEATURE_MOVE_EXPERIMENT_TO_ANOTHER_WS } from '@/lib/betaFeatures';
import useProjects from '@API/project/useProjects';
import { noop } from 'lodash';

const MOVE_DEFAULT_VALUES = {
  isSymlink: false,
  projectMoveTo: null
};

export const MoveExperimentModal = ({
  open,
  onClose,
  onMove,
  selectedExperiments,
  redirect
}) => {
  const history = useHistory();
  const { experimentKey } = useParams();
  const isMoveExperimentToWsEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MOVE_EXPERIMENT_TO_ANOTHER_WS
  );
  const moveExperimentsMutation = useMoveExperimentsMutation(
    isMoveExperimentToWsEnabled
  );
  const symlinkExperimentsMutation = useSymlinkExperimentsMutation(
    isMoveExperimentToWsEnabled
  );
  const { data: allWorkspaces, isLoading } = useAllUserWorkspaces({
    enabled: isMoveExperimentToWsEnabled
  });

  const { data: currentProject } = useProject();
  const currentWorkspace = useActiveWorkspace();

  const [isSymlink, setIsSymlink] = useState(MOVE_DEFAULT_VALUES.isSymlink);
  const [projectMoveTo, setProjectMoveTo] = useState(
    MOVE_DEFAULT_VALUES.projectMoveTo
  );
  const [workspaceMoveTo, setWorkspaceMoveTo] = useState({
    value: currentWorkspace?.id,
    label: currentWorkspace?.name
  });
  const { data: projects, isLoading: isLoadingProjects } = useProjects(
    workspaceMoveTo?.label,
    {
      enabled: !!workspaceMoveTo?.label && open
    }
  );

  const modalTitle = `${
    selectedExperiments ? 'Move experiments' : 'Move experiment'
  }`;

  const allWorkspacesOptions = useMemo(
    () =>
      (!isLoading &&
        allWorkspaces?.map(workspace => ({
          value: workspace?.id,
          label: workspace?.name
        }))) ||
      [],
    [allWorkspaces, isLoading]
  );

  const projectOptions = useMemo(
    () =>
      !isLoadingProjects
        ? (projects || [])
            ?.filter(
              ({ isStarterProject, projectId, type }) =>
                !isStarterProject &&
                projectId !== currentProject.projectId &&
                type === PROJECT_TYPE.EM
            )
            .map(project => {
              const { projectId, projectName } = project;
              return {
                value: projectId,
                label: projectName
              };
            })
        : [],
    [projects, currentProject, isLoadingProjects]
  );

  const renderContent = () => {
    return (
      <div className={styles.moveExperimentModalContainer}>
        {isMoveExperimentToWsEnabled && (
          <Select
            label="Workspaces list"
            variant="outlined"
            value={workspaceMoveTo?.value}
            onChange={(value, option) => {
              setWorkspaceMoveTo(option);
              setProjectMoveTo({});
            }}
            options={allWorkspacesOptions}
            withInput
            hideSource
          />
        )}
        <Select
          label="Projects list"
          variant="outlined"
          value={projectMoveTo?.value}
          onChange={(value, option) => setProjectMoveTo(option)}
          options={projectOptions}
          withInput
          disabled={
            (isMoveExperimentToWsEnabled && !workspaceMoveTo?.value) || false
          }
          hideSource
        />
        <div className={styles.wayToMoveFields}>
          <div
            className={styles.wayToMoveField}
            onClick={() => setIsSymlink(false)}
          >
            <RadioButton checked={!isSymlink} />
            <span className={styles.wayToMoveFieldLabel}>{modalTitle}</span>
          </div>
          <div
            className={styles.wayToMoveField}
            onClick={() => setIsSymlink(true)}
          >
            <RadioButton checked={isSymlink} />
            <span className={styles.wayToMoveFieldLabel}>Symlink</span>
          </div>
        </div>
      </div>
    );
  };

  const handleMoveExperimentToSelectedProject = async () => {
    try {
      await moveExperimentsMutation
        .mutateAsync({
          experiments: selectedExperiments || [experimentKey],
          projectId: projectMoveTo?.value
        })
        .then(() => {
          onMove();
        });

      if (redirect) {
        history.push(
          `/${workspaceMoveTo?.label}/${projectMoveTo?.label}/${experimentKey}`
        );
      }
    } catch {
      // eslint-disable-next-line no-empty
    }

    onClose();
  };

  const handleSymlinkExperimentToSelectedProject = () => {
    symlinkExperimentsMutation.mutate({
      experiments: selectedExperiments || [experimentKey],
      projectId: projectMoveTo?.value
    });

    onClose();
  };

  const handleDoneClick = () => {
    if (isSymlink) {
      handleSymlinkExperimentToSelectedProject();
    } else {
      handleMoveExperimentToSelectedProject();
    }
  };

  useEffect(() => {
    if (open) {
      setIsSymlink(MOVE_DEFAULT_VALUES.isSymlink);
      setProjectMoveTo(MOVE_DEFAULT_VALUES.projectMoveTo);
      setWorkspaceMoveTo({
        value: currentWorkspace?.id,
        label: currentWorkspace?.name
      });
    }
  }, [open, currentWorkspace]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      content={renderContent()}
      title={modalTitle + ' to a different project'}
      primaryButtonText="Done"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleDoneClick}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={!projectMoveTo?.value}
    />
  );
};

MoveExperimentModal.defaultProps = {
  redirect: true,
  selectedExperiments: null,
  onMove: noop
};

MoveExperimentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedExperiments: PropTypes.array,
  redirect: PropTypes.bool,
  onMove: PropTypes.func
};
