import get from 'lodash/get';
import { MODEL_VERSION_STATUSES } from '@shared/constants/model-registry-request-status';

export const getSnackbarMessagesForStatusUpdate = ({
  isError,
  isReviewRequired,
  isOwner,
  statusName,
  currentVersion,
  modelName
}) => {
  if (!isError) {
    return `You have successfully ${
      !isReviewRequired && isOwner
        ? 'added'
        : 'requested review to change the status to'
    } '${statusName}' ${
      !isReviewRequired && isOwner ? 'status for' : 'for'
    } '${modelName} Version ${currentVersion}' `;
  }

  return `There was an error ${
    !isReviewRequired && isOwner ? 'adding' : 'requesting'
  } new '${statusName}' status for '${modelName} Version ${currentVersion}'`;
};

export const getModelVersionStatusText = status =>
  status || MODEL_VERSION_STATUSES[status] || MODEL_VERSION_STATUSES.NA;
export const getModelVersionStatus = data => get(data, 'status.status');
export const getModelVersionRequestedStatus = data =>
  get(data, 'status.requestedStatus');
