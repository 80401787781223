import {
  AllowedCompareKeys,
  ComparisonTableDefaultData,
  ComparisonTableRow
} from '../types';
import { ExperimentDetails } from '@shared/types';
import { getExperimentValueName } from '../utils';
import { isStringInSearchText } from '@/helpers/generalHelpers';
import { generateRegexFromString } from '@shared/utils/displayHelpers';

type UseComparisonRowDataOpts<TData> = {
  experiments: ExperimentDetails[];
  experimentsData: TData[][];
  searchText: string;
  valueKeys: AllowedCompareKeys[];
};
/// TODO Algoritrm should be improved for performance and refactored for maintance
const useComparisonRowData = <TData extends ComparisonTableDefaultData>({
  experiments,
  experimentsData,
  searchText,
  valueKeys
}: UseComparisonRowDataOpts<TData>) => {
  const rowData: ComparisonTableRow[] = [];

  if (!experimentsData.length) {
    return rowData;
  }

  /// get list of full available params to be able fill empty rows if experiment has not this param
  const uniqueParamSet = new Set<string>();
  const experimentDetailsMap = new Map();

  experimentsData.forEach((experimentParamsList, idx) => {
    const currentExperiment = experiments[idx];
    experimentParamsList.forEach(param => {
      uniqueParamSet.add(param.name);
      experimentDetailsMap.set(
        `${currentExperiment.experimentKey}_${param.name}`,
        param
      );
    });
  });

  const fullParamsNameList = Array.from(uniqueParamSet);

  const searchRegex = generateRegexFromString(searchText);
  // first of all, filter all params
  const paramSearchList = fullParamsNameList.filter(paramName => {
    if (searchRegex) {
      return searchRegex.test(paramName);
    }

    return isStringInSearchText(paramName, searchText);
  });
  const experimentKeys = experiments.map(
    experiment => experiment.experimentKey
  );

  // prepare data for table and fill Map for Equality logic
  paramSearchList.forEach(paramName => {
    const rowItem: ComparisonTableRow = {
      rowId: paramName,
      key: paramName
    };
    experimentKeys.forEach(experimentKey => {
      const experimentData =
        experimentDetailsMap.get(`${experimentKey}_${paramName}`) ?? {};
      valueKeys.forEach(valueKey => {
        rowItem[getExperimentValueName(valueKey, experimentKey)] =
          experimentData[valueKey];
      });
    });

    rowData.push(rowItem);
  });

  return rowData;
};

export default useComparisonRowData;
