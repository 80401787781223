import { forgotPasswordActionTypes } from '../constants/actionTypes';
import { FORGOT_PASSWORD_STEPS } from '../lib/appConstants';
import {
  NO_MATCHING_ACCOUNT,
  NEW_PASSWORD_ERROR
} from '@auth/components/ForgotPasswordModal/texts';

export const initialState = {
  step: FORGOT_PASSWORD_STEPS.FILL_EMAIL,
  buttonText: 'Send',
  error: null,
  token: null
};

const forgotPasswordReducer = (state = initialState, action) => {
  const { type, token, email } = action;
  if (type === forgotPasswordActionTypes.RESET_SUCCESS) {
    return {
      ...state,
      buttonText: 'Close',
      step: FORGOT_PASSWORD_STEPS.SUCCESS
    };
  }
  if (type === forgotPasswordActionTypes.EMAIL_SENT) {
    return {
      ...state,
      buttonText: 'Close',
      step: FORGOT_PASSWORD_STEPS.EMAIL_SENT
    };
  }
  if (type === forgotPasswordActionTypes.ERROR_WITH_TEXT) {
    return {
      ...state,
      error: action.payload
    };
  }
  if (type === forgotPasswordActionTypes.RESET_ERROR) {
    return {
      ...state,
      error: null
    };
  }
  if (type === forgotPasswordActionTypes.EMAIL_SENT_ERROR) {
    return {
      ...state,
      buttonText: 'Go back',
      error: NO_MATCHING_ACCOUNT
    };
  }
  if (type === forgotPasswordActionTypes.NEW_PASSWORD_ERROR) {
    return {
      ...state,
      buttonText: 'Close',
      error: NEW_PASSWORD_ERROR
    };
  }
  if (type === forgotPasswordActionTypes.UPDATE_EMAIL) {
    return {
      ...state,
      email
    };
  }
  if (type === forgotPasswordActionTypes.LOADING) {
    return {
      ...state,
      step: FORGOT_PASSWORD_STEPS.LOADING
    };
  }
  if (type === forgotPasswordActionTypes.RECEIVED_TOKEN) {
    return {
      ...state,
      step: FORGOT_PASSWORD_STEPS.FILL_PASSWORD,
      token,
      buttonText: 'Confirm'
    };
  }
  if (type === forgotPasswordActionTypes.RESET_STATE) {
    return {
      ...state,
      ...initialState
    };
  }
  return { ...state };
};

export default forgotPasswordReducer;
