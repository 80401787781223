import React from 'react';
import { Workspace } from '@shared/types';
import { ListItem, Tooltip } from '@ds';
import { DSDefaultViewIcon } from '@design-system/icons';
import cx from 'classnames';

import styles from './UserMenuWorkspaces.module.scss';

type WorkspaceSelectItemProps = {
  workspace: Workspace;
  currentWorkspaceName: string;
  dataTestPrefix?: string;
  onSelect: (name: string) => void;
};
const WorkspaceSelectItem: React.FC<WorkspaceSelectItemProps> = ({
  workspace,
  currentWorkspaceName,
  dataTestPrefix = '',
  onSelect
}) => {
  const { name, isDefault } = workspace;
  const handleSelect = () => {
    onSelect(name);
  };

  const isActive = currentWorkspaceName === name;
  const dataTest = dataTestPrefix ? `${dataTestPrefix}-${name}` : '';

  return (
    <ListItem onClick={handleSelect} active={isActive} dataTest={dataTest}>
      <Tooltip className="truncated-string" content={name}>
        <span
          className={cx({
            [styles.workspaceItemNameActive]: isActive
          })}
        >
          {name}
        </span>
      </Tooltip>

      {isDefault && (
        <ListItem.ActionContainer>
          <Tooltip content="Default workspace">
            <ListItem.Icon
              Icon={
                <DSDefaultViewIcon
                  className={styles.workspaceItemDefaultIcon}
                />
              }
            />
          </Tooltip>
        </ListItem.ActionContainer>
      )}
    </ListItem>
  );
};

export default WorkspaceSelectItem;
