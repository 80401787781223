const findTemplate = (templates, value, keysMap) => {
  return templates.find(template => {
    if (template[keysMap.templateId]) {
      return template[keysMap.templateId] === value;
    }

    // auto generated, empty
    return template[keysMap.templateName] === value;
  });
};

const useTemplateSelectActions = ({
  canEdit,
  onDeleteTemplate,
  onChangeDefaultTemplate,
  templates,
  KEYS_MAP,
  currentTemplateName,
  hasUnsavedChanges,
  onChangeTemplate,
  onSaveUnsavedView,
  asDefault,
  newViewName,
  viewToGo,
  isSaveNewView,
  closeViewWasntSaved,
  onDiscardNewView,
  handleCloseViewList,
  openViewWasntSaved
}) => {
  const handleDeleteTemplate = template => {
    onDeleteTemplate(template);
  };

  const handleSetProjectTemplate = template => {
    onChangeDefaultTemplate(template);
    handleCloseViewList();
  };

  const handleTemplateChange = templateValue => {
    const foundTemplate = findTemplate(templates, templateValue, KEYS_MAP);

    if (foundTemplate[KEYS_MAP.templateName] !== currentTemplateName) {
      if (hasUnsavedChanges && canEdit) {
        openViewWasntSaved(foundTemplate);
      } else {
        onChangeTemplate(foundTemplate);
        handleCloseViewList();
      }
    }
  };

  const handleSaveNewView = () => {
    onSaveUnsavedView({
      asDefault,
      newViewName,
      viewToGo,
      isSaveNewView
    });
    closeViewWasntSaved();
  };

  const handleDiscardNewView = () => {
    onDiscardNewView(viewToGo);
    closeViewWasntSaved();
  };

  return {
    handleDiscardNewView,
    handleSaveNewView,
    handleTemplateChange,
    handleSetProjectTemplate,
    handleDeleteTemplate
  };
};

export default useTemplateSelectActions;
