import React from 'react';
import ExperimentBarSelect from '@experiment-details/components/ExperimentBar/ExperimentBarSelect';
import ExperimentBarSearch from '@experiment-details/components/ExperimentBar/ExperimentBarSearch';
import ExperimentBarSortSelect from '@experiment-details/components/ExperimentBar/ExperimentBarSortSelect';
import ExperimentBarCustom from '@experiment-details/components/ExperimentBar/ExperimentBarCustom';
import ExperimentBarToggle from '@experiment-details/components/ExperimentBar/ExperimentBarToggle';
import ExperimentActionList from '@experiment-details/components/ExperimentBar/ExperimentActionList';

import styles from './ExperimentBar.module.scss';

type ExperimentBarProps = {
  children: React.ReactNode;
  rightContainerChildren?: React.ReactNode;
};

const ExperimentBar = ({
  children,
  rightContainerChildren
}: ExperimentBarProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapContainer}>{children}</div>
      <div className={styles.spacer}></div>
      <div className={styles.rightContainer}>{rightContainerChildren}</div>
    </div>
  );
};

ExperimentBar.Search = ExperimentBarSearch;
ExperimentBar.Select = ExperimentBarSelect;
ExperimentBar.SortSelect = ExperimentBarSortSelect;
ExperimentBar.Custom = ExperimentBarCustom;
ExperimentBar.Toggle = ExperimentBarToggle;
ExperimentBar.ActionList = ExperimentActionList;

export default ExperimentBar;
