// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InstalledPackagesTab-module__emptyMessage--RruoY{padding-top:191px}.installed-packages__compare .InstalledPackagesTab-module__emptyMessage--RruoY{padding-top:134px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyMessage": `InstalledPackagesTab-module__emptyMessage--RruoY`
};
module.exports = ___CSS_LOADER_EXPORT___;
