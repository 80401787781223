import { useQuery } from 'react-query';

import api from '@API/api';
import { QueryConfig } from '@shared/api';
import { ModelDetailsType } from '@mpm-druid/types';

type ModelRegistryParams = {
  modelId: string;
};

type ModelResponse = {
  model: ModelDetailsType;
};

const getModel = ({ modelId }: ModelRegistryParams) =>
  api.get(`/mpm/v2/model/details`, { modelId });

export function useModelDetails(
  { modelId }: ModelRegistryParams,
  config?: QueryConfig<ModelResponse>
) {
  return useQuery(['model-details', { modelId }], () => getModel({ modelId }), {
    refetchOnMount: true,
    enabled: !!modelId,
    ...config
  });
}
