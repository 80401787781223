import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import api from '@shared/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import useProject from '@API/project/useProject';
import { Metric } from '@shared/types';
import { AxiosError } from 'axios';

type UpsertExperimentMetricParams = {
  experimentKey: string;
  metric: Metric;
};
const upsertExperimentMetric = async ({
  experimentKey,
  metric
}: UpsertExperimentMetricParams) => {
  const { data } = await api.post('experiment/update/metric', {
    experimentKey,
    metricName: metric.name,
    lastMetricValue: metric.valueCurrent,
    lastMetricStep: metric.stepCurrent,
    lastMetricTime: metric.timestampCurrent,
    minMetricValue: metric.valueMin,
    minMetricStep: metric.stepMin,
    minMetricTime: metric.timestampMin,
    maxMetricValue: metric.valueMax,
    maxMetricStep: metric.stepMax,
    maxMetricTime: metric.timestampMax
  });

  return data;
};

export default function useUpsertExperimentMetricMutation() {
  const dispatch = useDispatch();
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation<
    void,
    AxiosError<{
      code: number;
      msg: string;
    }>,
    UpsertExperimentMetricParams
  >(
    ({ experimentKey, metric }) => {
      return upsertExperimentMetric({
        experimentKey,
        metric
      });
    },
    {
      onMutate: async ({
        experimentKey
      }: {
        experimentKey: string;
        metric: Metric;
      }) => {
        await queryClient.cancelQueries([
          'experiment-metrics',
          { experimentKey }
        ]);
      },
      onSuccess: async (data, { experimentKey }) => {
        await queryClient.invalidateQueries(['experiments', { projectId }]);
        await queryClient.invalidateQueries([
          'experimentGroups',
          { projectId }
        ]);
        await queryClient.invalidateQueries([
          'experiment-metrics',
          { experimentKey }
        ]);
        await queryClient.invalidateQueries(['aggregations', { projectId }]);
      },
      onError: e => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            e?.response?.data?.msg ?? 'There was an error updating this metric.'
          )
        );
      }
    }
  );
}
