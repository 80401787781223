import { useLocation } from 'react-router';
import queryString from 'query-string';
import { MAX_COMPARE_EXPERIMENTS_COUNT } from '@experiment-management-shared/constants/experimentConstants';

import useDeepMemo from './useDeepMemo';

const useCompareExperimentsKeys = () => {
  const { search } = useLocation();

  const experimentKeys: string[] = useDeepMemo(() => {
    try {
      const parsedData = queryString.parse(search);
      const experiments = (parsedData?.experiments ?? '') as string;

      // don't allow to put more than maximum possible experiments to compare
      return experiments
        .split(',')
        .filter(k => !!k)
        .slice(0, MAX_COMPARE_EXPERIMENTS_COUNT);
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [search]);

  return { experimentKeys };
};

export default useCompareExperimentsKeys;
