import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

const useActiveTabFromURL = ({ tabNameInURL = 'tab', tabsOrder }) => {
  const { search } = useLocation();
  const history = useHistory();
  const { [tabNameInURL]: activeTabName } = queryString.parse(search);

  const [activeTabIndex, setActiveTabIndex] = useState(
    (activeTabName && tabsOrder.indexOf(activeTabName)) || 0
  );

  const changeHistory = useCallback(
    tabIndex => {
      setActiveTabIndex(tabIndex);

      const query = queryString.stringify({
        [tabNameInURL]: tabsOrder[tabIndex]
      });

      history.push(`?${query}`, { preventScroll: true });
    },
    [history, tabNameInURL, tabsOrder]
  );

  // Add the tab name in the URL if it is empty
  useEffect(() => {
    if (!activeTabName) {
      const query = queryString.stringify({
        [tabNameInURL]: tabsOrder[0]
      });

      history.replace(`?${query}`, { preventScroll: true });
    }
  }, [activeTabName, history, tabNameInURL, tabsOrder]);

  // Keep the track of the active tab index if the URL changes (back button)
  useEffect(() => {
    if (activeTabName) {
      if (tabsOrder.indexOf(activeTabName) > -1) {
        setActiveTabIndex(tabsOrder.indexOf(activeTabName));
      } else {
        changeHistory(0);
      }
    }
  }, [activeTabName, changeHistory, tabsOrder]);

  return [activeTabIndex, changeHistory];
};

export default useActiveTabFromURL;
