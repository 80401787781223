import React from 'react';
import { TextButton } from '@ds';
import { DSCloseCircleIcon, DSPlusIcon } from '@design-system/icons';
import ExperimentBar from '../ExperimentBar';

type AddItemToolbarProps = {
  additionalControls?: React.ReactNode;
  rightActionsPanel?: React.ReactNode;
  canEdit: boolean;
  entityName?: string;
  searchHandler: (text: string) => void;
  searchValue: string;
  onEdit: () => void;
  onCancel: () => void;
  isAddingMode: boolean;
  isEmptyTab?: boolean;
};

const AddItemToolbar: React.FC<AddItemToolbarProps> = ({
  canEdit,
  entityName = '',
  searchHandler,
  searchValue,
  onEdit,
  onCancel,
  additionalControls = null,
  rightActionsPanel = null,
  isAddingMode,
  isEmptyTab
}) => {
  const buttonText = isAddingMode ? 'Cancel' : `Add ${entityName}`;

  const toggleEditMode = () => {
    if (!isAddingMode) {
      onEdit();
    } else {
      onCancel();
    }
  };

  return (
    <ExperimentBar
      rightContainerChildren={
        <ExperimentBar.ActionList>
          {rightActionsPanel}
          <TextButton
            onClick={toggleEditMode}
            PrefixIcon={isAddingMode ? <DSCloseCircleIcon /> : <DSPlusIcon />}
            disabled={!canEdit}
          >
            {buttonText}
          </TextButton>
        </ExperimentBar.ActionList>
      }
    >
      <ExperimentBar.Search
        searchText={searchValue}
        setSearchText={searchHandler}
        placeholder="Name (regex)"
        disabled={isEmptyTab}
      />

      {additionalControls}
    </ExperimentBar>
  );
};

export default AddItemToolbar;
