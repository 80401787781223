import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import uniqBy from 'lodash/uniqBy';
import MultiValue from './MultiValue';
import MultiValueLabel from './MultiValueLabel';
import Input from './Input';
import MultiValueRemove from './MultiValueRemove';
import Control from './Control';
import ClearIndicator from '../shared/ClearIndicator';
import ValueContainer from './ValueContainer';

import Select, { Option } from '../Select';
import { arrayMove } from './utils';

const SortableSelect = SortableContainer(Select);

const SelectSortable = ({
  onChange,
  value,
  fixedOptionValues,
  ...selectProps
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const fixedValues = value.filter(option =>
      fixedOptionValues.includes(option.value)
    );

    const newlySortedValues = arrayMove(value, oldIndex, newIndex);

    onChange(uniqBy([...fixedValues, ...newlySortedValues], 'value'));
  };

  return (
    <SortableSelect
      className="multi-select"
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      onChange={onChange}
      value={value}
      components={{
        Option,
        ClearIndicator,
        ValueContainer,
        Control,
        MultiValue,
        Input,
        MultiValueRemove,
        MultiValueLabel
      }}
      fixedOptionValues={fixedOptionValues}
      {...selectProps}
    />
  );
};

SelectSortable.propTypes = {
  fixedOptionValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selectProps: PropTypes.object,
  value: PropTypes.array.isRequired
};

SelectSortable.defaultProps = {
  fixedOptionValues: [],
  selectProps: {}
};

export default SelectSortable;
