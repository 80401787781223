import React, { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import { CircularProgress, Grid, Box } from '@material-ui/core';
import LineageWithReactFlowProvider, {
  LineageLayoutFlowNode,
  LineageNodeType
} from '@shared/components/LineageLayoutFlow';
import EmptyTabMessage from '@model-registry/components/EmptyTabMessage';
import useModelRegistryLineage from '@model-registry/api/useModelRegistryLineage';
import PropTypes from 'prop-types';
import getLineageLayout from '@shared/hooks/useLineageLayout';
import { useSelector } from 'react-redux';
import { getIsUserLoggedIn } from '@/reducers/userReducer';

const ModelVersionLineageTab = ({ selectedVersion, workspace }) => {
  const isUserLoggedIn = useSelector(state => getIsUserLoggedIn(state));
  const { data, isLoading } = useModelRegistryLineage({
    workspace,
    registryModelItemId: selectedVersion.registryModelItemId
  });
  const { edges: initialEdges, nodes: initialNodes } = getLineageLayout(data);

  const nodeTypes = useMemo(
    () => ({
      [LineageNodeType.ARTIFACT_VERSION]: props => (
        <LineageLayoutFlowNode
          {...props}
          currentVersion={selectedVersion.registryModelItemId}
          workspace={workspace}
        />
      ),
      [LineageNodeType.MODEL_VERSION]: props => (
        <LineageLayoutFlowNode
          {...props}
          currentVersion={selectedVersion.registryModelItemId}
          workspace={workspace}
        />
      ),
      [LineageNodeType.EXPERIMENT]: props => (
        <LineageLayoutFlowNode
          {...props}
          currentVersion={selectedVersion.registryModelItemId}
          workspace={workspace}
        />
      )
    }),
    [selectedVersion.registryModelItemId]
  );

  if (isUserLoggedIn && isLoading) {
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        spacing={4}
        direction="column"
        data-test="model-version-lineage"
      >
        <Grid item xs>
          <Box p={4}>
            <CircularProgress color="primary" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (isEmpty(data) || !isUserLoggedIn) {
    return <EmptyTabMessage type="lineage" />;
  }

  return (
    <LineageWithReactFlowProvider
      nodeTypes={nodeTypes}
      legendNodes={[
        LineageNodeType.ARTIFACT_VERSION,
        LineageNodeType.MODEL_VERSION,
        LineageNodeType.EXPERIMENT
      ]}
      mainNodeId={selectedVersion.registryModelItemId}
      defaultEdges={initialEdges}
      defaultNodes={initialNodes}
    />
  );
};

ModelVersionLineageTab.defaultProps = {
  selectedVersion: {}
};

ModelVersionLineageTab.propTypes = {
  workspace: PropTypes.string.isRequired,
  selectedVersion: PropTypes.shape({
    experimentModel: PropTypes.shape({
      models: PropTypes.array,
      experimentKey: PropTypes.string
    }),
    stages: PropTypes.arrayOf(PropTypes.string),
    restApiUrl: PropTypes.string,
    userName: PropTypes.string,
    comment: PropTypes.string,
    version: PropTypes.string,
    registryModelItemId: PropTypes.string
  })
};

export default ModelVersionLineageTab;
