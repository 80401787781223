import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import reverse from 'lodash/reverse';

import {
  getUniqValuesFromArray,
  getUniqValuesFromArrayOfObjects
} from '../helpers/generalHelpers';

export const SEARCH_TEXT_FETCH_FREQUENCY = 400;

export const WORKSPACE_PAGE_TABS = {
  PROJECTS: 'PROJECTS',
  PANELS_GALLERY: 'PANELS_GALLERY',
  MODEL_REGISTRY: 'MODEL_REGISTRY',
  ARTIFACTS: 'ARTIFACTS',
  MODEL_PRODUCTION_MONITORING: 'MODEL_PRODUCTION_MONITORING'
};

const {
  PROJECTS,
  MODEL_REGISTRY,
  ARTIFACTS,
  MODEL_PRODUCTION_MONITORING,
  PANELS_GALLERY
} = WORKSPACE_PAGE_TABS;

export const WORKSPACE_URL_HASHES = {
  [PROJECTS]: '#projects',
  [MODEL_REGISTRY]: '#model-registry',
  [ARTIFACTS]: '#artifacts',
  [MODEL_PRODUCTION_MONITORING]: '#model-production-monitoring',
  [PANELS_GALLERY]: '#panels-gallery'
};

export const WORKSPACE_PAGE_TAB_LABELS = {
  [PROJECTS]: 'Projects',
  [PANELS_GALLERY]: 'Panels Gallery',
  [MODEL_REGISTRY]: 'Model Registry',
  [ARTIFACTS]: 'Artifacts',
  [MODEL_PRODUCTION_MONITORING]: 'Model Production Monitoring'
};

export const MIN_ITEM_COUNT_FOR_TABLE_DISPLAY = 5;

export const TAB_STATE_KEYS = {
  FILTERABLE_VALUES: 'filterableValues',
  FILTERED_ROW_COUNT: 'filteredRowCount',
  ITEM_COUNT: 'itemCount',
  IS_CARD_DISPLAY: 'isCardDisplay',
  SORT_PROPERTY: 'sortByProperty',
  SORT_DIRECTION: 'sortByDirection',
  SELECTED_FILTERS: 'selectedFilters',
  PAGE_SIZE: 'pageSize',
  PAGE_NUMBER: 'pageNumber',
  VISIBLE_ITEMS: 'visibleItems'
};

export const SEARCHABLE_FIELDS = {
  [PROJECTS]: ['projectName', 'userName', 'projectDesc'],
  [ARTIFACTS]: ['name', 'type', 'artifactId', 'project'],
  [MODEL_REGISTRY]: [
    'modelName',
    'description',
    'userName',
    'latestVersion.version'
  ]
};
export const SORT_BY_DIRECTION = {
  ASC: 'SORT/ASC',
  DESC: 'SORT/DESC'
};

export const SORT_TOOLTIP = {
  ALPHA: {
    [SORT_BY_DIRECTION.ASC]: 'A to Z',
    [SORT_BY_DIRECTION.DESC]: 'Z to A'
  },
  DATE: {
    [SORT_BY_DIRECTION.ASC]: 'Old to new',
    [SORT_BY_DIRECTION.DESC]: 'New to old'
  },
  NUMBER: {
    [SORT_BY_DIRECTION.ASC]: 'Low to high',
    [SORT_BY_DIRECTION.DESC]: 'High to low'
  }
};

export const DEFAULT_SORT_STATE = {
  [PROJECTS]: {
    PROPERTY: 'projectName',
    DIRECTION: SORT_BY_DIRECTION.ASC
  },
  [ARTIFACTS]: {
    PROPERTY: 'name',
    DIRECTION: SORT_BY_DIRECTION.ASC
  },
  [MODEL_REGISTRY]: {
    PROPERTY: 'modelName',
    DIRECTION: SORT_BY_DIRECTION.ASC
  }
};

export const FILTERS = {
  [PROJECTS]: {
    getFilters: owners => {
      return [
        {
          groupLabel: 'Owner',
          filterKey: 'userName',
          options: owners.map(username => ({
            label: username,
            value: username
          }))
        },
        {
          groupLabel: 'Visibility',
          filterKey: 'isPublic',
          options: [
            { label: 'Public', value: true },
            { label: 'Private', value: false }
          ]
        },
        {
          groupLabel: 'Category',
          filterKey: 'isStarterProject',
          options: [
            { label: 'Examples', value: true },
            { label: 'Workspace', value: false }
          ]
        }
      ];
    }
  },
  [ARTIFACTS]: {
    getFilters: items => {
      return [
        {
          groupLabel: 'Tags',
          filterKey: 'tags',
          options: getUniqValuesFromArray(items, 'tags').map(tag => ({
            label: tag,
            value: tag
          }))
        },

        {
          groupLabel: 'Visibility',
          filterKey: 'isPublic',
          options: [
            { label: 'Public', value: true },
            { label: 'Private', value: false }
          ]
        },
        {
          groupLabel: 'Type',
          filterKey: 'type',

          options: getUniqValuesFromArrayOfObjects(items, 'type').map(type => ({
            label: type,
            value: type
          }))
        }
      ];
    }
  },
  [MODEL_REGISTRY]: {
    getFilters: items => {
      return [
        {
          groupLabel: 'Owner',
          filterKey: 'userName',
          options: getUniqValuesFromArrayOfObjects(
            items,
            'userName'
          ).map(userName => ({ label: userName, value: userName }))
        },
        {
          groupLabel: 'Visibility',
          filterKey: 'isPublic',
          options: [
            { label: 'Public', value: true },
            { label: 'Private', value: false }
          ]
        }
      ];
    }
  }
};

export const COLUMN_FORMAT_KEYS = {
  LINK: 'link',
  IMAGE: 'image',
  IS_PUBLIC: 'isPublic',
  LONG_TEXT: 'longText',
  DATE: 'date',
  MENU_BTN: 'menuBtn',
  STAGE_BADGE: 'stage-badge',
  EXPERIMENT_KEY: 'experiment-key',
  PINNED_ITEM: 'pinnedItem',
  SIMPLE_LINK: 'simpleLink'
};

export const WORKSPACE_TABS_COLUMNS = {
  [PROJECTS]: [
    {
      header: {
        isHidden: true
      },
      cell: {
        dataKey: 'imagePath',
        formatKey: COLUMN_FORMAT_KEYS.IMAGE
      }
    },
    {
      header: {
        label: 'Project name',
        isSortable: true,
        colSpan: 2,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'projectName',
        formatKey: COLUMN_FORMAT_KEYS.SIMPLE_LINK,
        className: 'truncated-string project-name-link'
      }
    },
    {
      header: {
        label: 'Visibility'
      },
      cell: {
        dataKey: 'isPublic',
        formatKey: COLUMN_FORMAT_KEYS.IS_PUBLIC,
        textAlign: 'center'
      }
    },
    {
      header: {
        label: 'Description',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'projectDesc',
        formatKey: COLUMN_FORMAT_KEYS.LONG_TEXT
      }
    },
    {
      header: {
        label: 'Last updated',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.DATE
      },
      cell: {
        dataKey: 'lastUpdated',
        formatKey: COLUMN_FORMAT_KEYS.DATE
      }
    },
    {
      header: {
        label: 'Created at',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.DATE
      },
      cell: {
        dataKey: 'created_at',
        formatKey: COLUMN_FORMAT_KEYS.DATE
      }
    },
    {
      header: {
        label: 'Owner'
      },
      cell: {
        dataKey: 'userName'
      }
    },
    {
      header: {
        label: 'Experiments',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.NUMBER
      },
      cell: {
        dataKey: 'experimentCount',
        textAlign: 'center'
      }
    },
    {
      header: {},
      cell: {
        dataKey: 'actions',
        formatKey: COLUMN_FORMAT_KEYS.MENU_BTN
      }
    }
  ],
  [MODEL_REGISTRY]: [
    {
      header: {
        isHidden: true
      },
      cell: {
        dataKey: 'imagePath',
        formatKey: COLUMN_FORMAT_KEYS.IMAGE
      }
    },
    {
      header: {
        label: 'Model name',
        isSortable: true,
        colSpan: 2,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'modelName',
        formatKey: COLUMN_FORMAT_KEYS.LINK
      }
    },
    {
      header: {
        label: 'Visibility'
      },
      cell: {
        dataKey: 'isPublic',
        formatKey: COLUMN_FORMAT_KEYS.IS_PUBLIC,
        textAlign: 'center'
      }
    },
    {
      header: {
        label: 'Description',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'description',
        formatKey: COLUMN_FORMAT_KEYS.LONG_TEXT
      }
    },
    {
      header: {
        label: 'Last updated',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.DATE
      },
      cell: {
        dataKey: 'lastUpdated',
        formatKey: COLUMN_FORMAT_KEYS.DATE
      }
    },
    {
      header: {
        label: 'Created at',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.DATE
      },
      cell: {
        dataKey: 'createdAt',
        formatKey: COLUMN_FORMAT_KEYS.DATE
      }
    },
    {
      header: {
        label: 'Owner'
      },
      cell: {
        dataKey: 'userName'
      }
    },
    {
      header: {
        label: 'Versions',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.NUMBER
      },
      cell: {
        dataKey: 'numberOfVersions',
        textAlign: 'center'
      }
    },
    {
      header: {},
      cell: {
        formatKey: COLUMN_FORMAT_KEYS.MENU_BTN
      }
    }
  ],
  [ARTIFACTS]: [
    {
      header: {
        label: 'Artifact name',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'name'
      }
    },
    {
      header: {
        label: 'Type',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'type'
      }
    },
    {
      header: {
        label: 'ID',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'artifactId'
      }
    },
    {
      header: {
        label: 'Project',
        isSortable: true,
        sortTooltip: SORT_TOOLTIP.ALPHA
      },
      cell: {
        dataKey: 'project'
      }
    }
  ]
};

export const getSortByItemsByKey = tabKey => {
  return WORKSPACE_TABS_COLUMNS[tabKey]
    .filter(column => column.header.isSortable)
    .map(column => {
      return {
        key: column.cell.dataKey,
        label: column.header.label,
        tooltip: column.header.sortTooltip
      };
    });
};

export const filterItems = (items, selectedFilters) => {
  if (isEmpty(selectedFilters)) return items;

  return items.filter(item => {
    const filterKeys = Object.keys(selectedFilters);
    return filterKeys.every(filterKey => {
      if (isEmpty(selectedFilters[filterKey])) return true;

      if (Array.isArray(item[filterKey])) {
        return item[filterKey].some(val =>
          selectedFilters[filterKey].includes(val)
        );
      }
      return selectedFilters[filterKey].includes(item[filterKey]);
    });
  });
};

export const sortItems = (
  items,
  sortByDirection,
  sortByProperty,
  customSort
) => {
  if (customSort) {
    const sortedAscending = [...items].sort((a, b) => {
      return customSort(get(a, sortByProperty), get(b, sortByProperty));
    });

    return sortByDirection === SORT_BY_DIRECTION.ASC
      ? sortedAscending
      : reverse(sortedAscending);
  }

  return [...items].sort((itemA, itemB) => {
    const isNumberColumn =
      isNumber(itemA[sortByProperty]) || isNumber(itemB[sortByProperty]);

    const defaultValue = isNumberColumn ? 0 : '';
    const valueA = defaultTo(itemA[sortByProperty], defaultValue);
    const valueB = defaultTo(itemB[sortByProperty], defaultValue);

    if (isNumberColumn) {
      return sortByDirection === SORT_BY_DIRECTION.ASC
        ? valueA - valueB
        : valueB - valueA;
    }

    return sortByDirection === SORT_BY_DIRECTION.ASC
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  });
};

export const searchItems = (items, searchText, searchableFields) => {
  if (isEmpty(searchableFields) || isEmpty(searchText)) return items;
  return items.filter(item => {
    const searchableContent = searchableFields.map(field => {
      const content = get(item, field, '');
      return lowerCase(content);
    });

    return searchableContent.some(content =>
      content.includes(lowerCase(searchText))
    );
  });
};

export const DEFAULT_PINNED_ITEM_CONFIG = {
  isPinned: false,
  handler: undefined,
  color: '',
  text: ''
};
