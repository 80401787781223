import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { formatDateTime, formatTimeNumber } from '@shared/utils/displayHelpers';
import useProjectTags from '@API/project/useProjectTags';
import { GRID_COLUMNS } from '@experiment-management-shared/utils/LLMView';
import ExperimentTag from '@shared/components/TableCells/ExperimentTag';

import styles from './LLMGroupedCellComponent.module.scss';

const typeDisplay = (value, type) => {
  if (type === 'datetime') {
    return formatDateTime(value);
  }
  if (type === 'timenumber') {
    return formatTimeNumber(value);
  }

  return String(value);
};

const groupedTagsDisplay = (tagName, tags) => {
  if (!tagName) {
    return <div className="grouped-row-cell">No tags</div>;
  }

  const color = tags.find(tag => tag.name === tagName)?.color;

  return (
    <div className="grouped-row-cell">
      <ExperimentTag
        key={tagName}
        tagName={tagName}
        tagColor={color}
        isDeleteDisabled
      />
    </div>
  );
};

const LLMGroupedCellComponent = ({ column, row, expanded }) => {
  const { data: tags = [] } = useProjectTags();

  let cell;
  if (row.groupedBy === GRID_COLUMNS.TAGS) {
    cell = groupedTagsDisplay(row.value, tags);
  } else {
    cell = typeDisplay(row.value, column.type);
  }

  return (
    <div
      className={cx(styles.llmGroupedCellComponent, {
        [styles.expanded]: expanded
      })}
    >
      <div className={styles.title}>{column.title}:</div>
      <div className={styles.value}>{cell}</div>
    </div>
  );
};

LLMGroupedCellComponent.defaultProps = {
  expanded: false
};

LLMGroupedCellComponent.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  row: PropTypes.object.isRequired,
  expanded: PropTypes.bool
};

export default LLMGroupedCellComponent;
