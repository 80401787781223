import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import { TextButton } from '@ds';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';

import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import { getURLQueryParams } from '@shared/utils/url';

import { Switch } from '@DesignSystem/controllers';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';

import {
  PANEL_PREVIEW_PATH,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';
import { trackEvent } from '@shared/utils/eventTrack';
import CustomChart from '@experiment-management-shared/components/Charts/PlotlyChart/CustomChart';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';

import PanelModalFooter from '@experiment-management-shared/components/PanelModalFooter';
import ChartNameField from '@experiment-management-shared/components/ChartNameField';
import { NEW_VIEW } from '@/constants/dashboardConstants';
import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';

import { NEW_TEMPLATE } from '@/reducers/visualizationsReducer';
import { iFrameConfig } from '@experiment-management-shared/constants';

import usePanelInstance from '@API/panels/usePanelInstance';
import usePanelTemplate from '@API/panels/usePanelTemplate';
import usePanelTemplateRevisions from '@API/panels/usePanelTemplateRevisions';
import useProject from '@API/project/useProject';

import useCurrentUser from '@API/auth/useCurrentUser';
import { panelEvents } from '@/constants/trackingEventTypes';
import { useActiveWorkspace, useCurrentOrganization } from '@shared/hooks';

import useCurrentPanelSource from '@/helpers/custom-hooks/useCurrentPanelSource';
import { parseJSON } from '@shared/utils/jsonHelpers';
import { getCurrentPanelOptions } from '@/helpers/panelOptions';
import { isValidMessage } from '@experiment-management-shared/utils/visualizationsHelper';

import SmallLoader from '@shared/components/SmallLoader';
import TabLabelWithBadge from '@shared/components/TabLabelWithBadge';
import VizHistoryPopover from '@experiment-management-shared/components/VizHistoryPopover';
import ModalOptionsContainer from '@experiment-management-shared/components/AddCustomVisualizationModal/subcomponents/ModalOptionsContainer';
import QueryBuilderSidebar from '@experiment-management-shared/components/AddCustomVisualizationModal/subcomponents/QueryBuilderSidebar';
import { DSArrowDownIcon, DSCodeIcon } from '@ds-icons';
import { useIsServerCustomPanelsEnabled } from '@experiment-management-shared/hooks';

const OPTIONS = 'Options';
const FILTERS = 'Filters';

const AddCustomVisualizationModal = ({
  experimentKeys,
  instanceId,
  onAddCustomPanel,
  onEditCustomPanel,
  source
}) => {
  const isEditMode = !!instanceId;
  const params = getURLQueryParams();
  const chartId = params.get('chartId');
  const templateId = params.get('chartType');

  const history = useHistory();
  const location = useLocation();
  const { viewId } = useParams();
  const queryClient = useQueryClient();
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();

  const { data: project } = useProject();
  const { projectId } = project;
  const { data: instance, isLoading: isLoadingInstance } = usePanelInstance({
    instanceId
  });

  const { data: template, isLoading: isLoadingTemplate } = usePanelTemplate({
    projectId,
    // revisionId: templateRevisionId,
    templateId
  });
  const { data: templateRevisions } = usePanelTemplateRevisions({
    templateId
  });

  const isStatic = get(instance, ['metadata', 'isStatic'], false);

  const instanceRevisionId = get(
    instance,
    'templateRevisionId',
    template?.revisionId
  );

  const [numberOfExperiments, setNumberOfExperiments] = useState(null);
  const currentInstanceName = get(instance, 'instanceName', '');
  const [activeTab, setActiveTab] = useState(OPTIONS);
  const [instanceName, setInstanceName] = useState(NEW_TEMPLATE.templateName);
  const [isStaticToggled, setIsStaticToggled] = useState(isStatic);
  const [lastFormReset, setLastFormReset] = useState(0);
  const [modifiedOptions, setModifiedOptions] = useState({});
  const currentOrganization = useCurrentOrganization();
  const currentWorkspace = useActiveWorkspace();
  const { data: currentUser } = useCurrentUser();
  const currentSource = useCurrentPanelSource();
  const queryParams = getURLQueryParams(location.hash);
  const chartCategory = queryParams.get('chartCategory');
  const [query, setQuery] = useState({
    rulesTree: generateEmptyRulesTree(),
    segmentId: ''
  });
  const [templateSaveRevision, setTemplateSaveRevision] = useState(0);

  // js options from code taken with events
  const [jsOptionsFromCode, setJsOptionsFromCode] = useState({});
  // if null, there is no loaded value yet
  const [isPy, setIsPy] = useState(null);

  const [revisionId, setRevisionId] = useState(0);
  const revisionButtonRef = useRef(null);

  const { segmentId: queryBuilderId } = query;

  const latestRevisionId = useMemo(() => {
    if (!templateRevisions) return 0;

    return Math.max(...templateRevisions);
  }, [templateRevisions]);

  useEffect(() => {
    const isPython = template?.code?.type === PANEL_CODE_LANGUAGES.PYTHON;
    setIsPy(isPython);

    const isPyodidePythonPanel = isPython && !isServerCustomPanelsEnabled;

    if (isPyodidePythonPanel) {
      window.initPyodideWorker(location.origin);
    }
  }, [template, isServerCustomPanelsEnabled]);

  const currentOptions = useMemo(() => {
    return getCurrentPanelOptions({
      modifiedOptions,
      instance,
      template,
      isPy,
      jsOptionsFromCode,
      revisionId
    });
  }, [
    modifiedOptions,
    instance,
    template,
    isPy,
    jsOptionsFromCode,
    revisionId
  ]);

  const initialQueryBuilderId = isEditMode
    ? get(instance, 'queryBuilderId', '')
    : get(template, 'queryBuilderId', '');

  useEffect(() => {
    if ((isEditMode && isLoadingInstance) || isLoadingTemplate) return;

    setQuery(prevQuery => ({
      ...prevQuery,
      ...{ segmentId: initialQueryBuilderId }
    }));
  }, [initialQueryBuilderId, isEditMode, isLoadingInstance, isLoadingTemplate]);

  useEffect(() => {
    if (templateSaveRevision) {
      queryClient.invalidateQueries('panelTemplateRevisions', { templateId });
    }
  }, [queryClient, templateId, templateSaveRevision]);

  const updateVersion = () => {
    setRevisionId(latestRevisionId);
  };

  useEffect(() => {
    if (!template) return;

    if (!instance || !instance.instanceName) {
      setInstanceName(template.templateName);
    }
  }, [instance, template]);

  useEffect(() => {
    setRevisionId(instanceRevisionId);
  }, [instanceRevisionId]);

  useEffect(() => {
    if (!currentInstanceName) return;

    setInstanceName(currentInstanceName);
  }, [currentInstanceName]);

  const handleReceiveMessage = useCallback(
    event => {
      if (!template) return;

      const { data, origin } = event;
      const { messageTypes } = iFrameConfig;

      const scopeType = get(template, 'scopeType', '');

      if (isValidMessage(origin, scopeType)) {
        const { messageType, ...payload } = data;

        if (messageType === messageTypes.SEND_CHART_OPTIONS) {
          if (['null', 'undefined', instanceId].includes(payload.instanceId)) {
            setJsOptionsFromCode(prevOptionsByRevisionId => {
              if (prevOptionsByRevisionId.hasOwnProperty(revisionId)) {
                return prevOptionsByRevisionId;
              }

              return {
                ...prevOptionsByRevisionId,
                [revisionId]: payload.options
              };
            });
          }
        }
      }
    },
    [template, instanceId, revisionId]
  );

  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [handleReceiveMessage]);

  const handleQueryChange = useCallback(
    newQuery => {
      setQuery(prevQuery => ({
        ...prevQuery,
        ...newQuery
      }));
    },
    [setQuery]
  );

  const handleResetForm = () => {
    const resetNameTo = get(
      template,
      'templateName',
      NEW_TEMPLATE.templateName
    );

    setInstanceName(resetNameTo);
    setLastFormReset(lastFormReset + 1);
  };

  const handleCloseModal = () => {
    handleResetForm();
    history.push({ ...location, hash: '' });
  };

  const handleAddChart = newInstance => {
    onAddCustomPanel(newInstance);

    trackEvent(panelEvents.PANEL_ADDED, {
      organization_id: currentOrganization?.id,
      organization_name: currentOrganization?.name,
      workspace_id: currentWorkspace?.id,
      workspace_name: currentWorkspace?.name,
      project_id: project?.projectId,
      project_name: project?.projectName,
      panel_location: currentSource,
      panel_type: chartCategory,
      panel_name: newInstance.instanceName,
      panel_id: newInstance.templateId,
      user_id: currentUser?.username
    });

    handleCloseModal();
  };

  const handleEditChart = updatedInstance => {
    onEditCustomPanel(chartId, updatedInstance);
    handleCloseModal();
  };

  const handleSubmit = () => {
    const userSkippedVersion = get(
      instance,
      'latestRevisionId',
      template.revisionId
    );

    const config = isEmpty(modifiedOptions)
      ? get(instance, 'config', JSON.stringify({}))
      : JSON.stringify(modifiedOptions);

    const newInstance = {
      ...instance,
      metadata: {
        isStatic: isStaticToggled
      },
      config,
      instanceName,
      projectId,
      order: 0,
      queryBuilderId,
      source,
      templateRevisionId: revisionId || userSkippedVersion,
      templateId,
      useDefaultConfig: true,
      userSkippedVersion,
      viewId: viewId !== NEW_VIEW ? viewId : null
    };

    if (chartId) {
      handleEditChart(newInstance);
    } else {
      handleAddChart(newInstance);
    }
  };

  const handleUpdateChartName = newChartName => {
    setInstanceName(newChartName);
  };

  const handleSwitchChange = () => {
    setIsStaticToggled(prevIsStaticToggled => !prevIsStaticToggled);
  };

  const labelType = 'Static';
  const renderSwitchLabel = label => {
    return (
      <label
        className={cx('scope-switch-label', {
          active: label === labelType && isStaticToggled
        })}
        style={{ fontSize: '13px', marginBottom: 0 }}
      >
        {label}
      </label>
    );
  };

  const openEditPanelTab = () => {
    const revisionPath = revisionId ? `/${revisionId}` : '';
    const url = `${location.pathname}/${SELECT_PANEL_PATH}/${PANEL_PREVIEW_PATH}/edit-code/${templateId}${revisionPath}`;
    const opener = window.open(url);

    opener.onCustomPanelSave = () => {
      setTemplateSaveRevision(currentRevisionId => currentRevisionId + 1);
    };
  };

  const renderEditCodeButton = () => {
    return (
      <TextButton onClick={openEditPanelTab} PrefixIcon={<DSCodeIcon />}>
        Edit Code
      </TextButton>
    );
  };

  const renderChartPreviewHeader = () => {
    const placeholder = template.templateName;

    return (
      <div className="chart-preview-header add-visualization-modal">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ChartNameField
            lastFormReset={lastFormReset}
            placeholder={placeholder}
            chartNameEditHandler={handleUpdateChartName}
            currentName={instanceName}
            label="Chart title"
          />
          <Divider
            className="button-divider"
            orientation="vertical"
            style={{
              backgroundColor: '#bebebe'
            }}
          />
          {renderEditCodeButton()}
        </div>
        <div className="switch-container">
          {renderSwitchLabel(labelType)}
          <Tooltip title="Static panels do not refresh" placement="top">
            <Switch
              checked={isStaticToggled}
              onChange={handleSwitchChange}
              color="primary"
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const [historyMenuAnchorEl, setHistoryMenuAnchorEl] = useState(null);

  const openHistoryPopover = () => {
    setHistoryMenuAnchorEl(revisionButtonRef.current);
  };

  const closeHistoryPopover = () => {
    setHistoryMenuAnchorEl(null);
  };

  const optionsEditor = useMemo(() => {
    return (
      <ModalOptionsContainer
        optionsToResetTo={(instance && parseJSON(instance.config, {})) || {}}
        onChangeModifiedOptions={setModifiedOptions}
        currentOptions={currentOptions}
        isPy={isPy}
      />
    );
  }, [isPy, currentOptions, setModifiedOptions, instance]);

  const renderCustomChartPreview = () => {
    const currentInstance = {
      ...instance,
      instanceName,
      queryBuilderId,
      templateRevisionId: revisionId
    };

    return (
      <div className="custom-chart-preview">
        <CustomChart
          chartId={chartId}
          experimentKeys={experimentKeys}
          instance={currentInstance}
          instanceId={instanceId}
          isPreview
          onUpdateVersion={updateVersion}
          onChangeNumberOfExperiments={setNumberOfExperiments}
          templateId={templateId}
          options={currentOptions}
        />
      </div>
    );
  };

  const renderBody = () => {
    if (!template || (isEditMode && !instance)) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
        />
      );
    }

    return (
      <div className="chart-modal-content-wrapper">
        <div className="chart-modal-content right">
          {renderChartPreviewHeader()}
          {renderCustomChartPreview()}
        </div>
      </div>
    );
  };

  const handleSelectRevision = newRevisionId => {
    setRevisionId(newRevisionId);
    closeHistoryPopover();
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderActiveTab = () => {
    if (activeTab === OPTIONS) {
      return (
        <div className="modal-sidebar-body custom-visualisation">
          {optionsEditor}
        </div>
      );
    }

    if (activeTab === FILTERS) {
      return <QueryBuilderSidebar onChange={handleQueryChange} query={query} />;
    }

    return null;
  };

  const renderTabs = () => {
    return [OPTIONS, FILTERS].map(tabName => {
      const label =
        tabName === FILTERS && isNumber(numberOfExperiments) ? (
          <TabLabelWithBadge
            labelText={tabName}
            badgeCount={numberOfExperiments}
          />
        ) : (
          tabName
        );

      return (
        <Tab
          key={tabName}
          value={tabName}
          label={label}
          style={{ minWidth: 100 }}
        />
      );
    });
  };

  const renderTabBar = () => {
    return (
      <Tabs className="tab-bar" value={activeTab} onChange={handleTabChange}>
        {renderTabs()}
      </Tabs>
    );
  };

  const renderVersionHistory = () => {
    return (
      <>
        <TextButton
          ref={revisionButtonRef}
          className="chart-preview-revisions"
          disabled={isEmpty(templateRevisions)}
          onClick={openHistoryPopover}
          PostfixIcon={<DSArrowDownIcon />}
        >
          <span className="chart-preview-revisions-label">Version History</span>
        </TextButton>

        <VizHistoryPopover
          anchorEl={historyMenuAnchorEl}
          currentRevisionId={revisionId}
          onClose={closeHistoryPopover}
          onItemClick={handleSelectRevision}
          revisionIds={templateRevisions}
          PopoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }}
        />
      </>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="modal-sidebar">
        <div className="modal-sidebar-header">
          {renderTabBar()}
          {renderVersionHistory()}
          <div className="modal-sidebar-header-border" />
        </div>
        {renderActiveTab()}
      </div>
    );
  };

  return (
    <>
      <div className="panel-preview">
        {renderSidebar()}
        {renderBody()}
      </div>

      <PanelModalFooter
        handleSubmit={handleSubmit}
        handleReset={handleResetForm}
      />
    </>
  );
};

AddCustomVisualizationModal.defaultProps = {
  experimentKeys: [],
  instanceId: null,
  onAddCustomPanel: noop,
  onEditCustomPanel: noop
};

AddCustomVisualizationModal.propTypes = {
  experimentKeys: PropTypes.array,
  instanceId: PropTypes.string,
  onAddCustomPanel: PropTypes.func,
  onEditCustomPanel: PropTypes.func,
  source: PropTypes.string.isRequired
};

export default AddCustomVisualizationModal;
