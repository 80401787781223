import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import history from '../history';
import { artifactActionTypes } from '@/constants/actionTypes';
import artifactsApi from '../util/artifactsApi';
import alertsUtil from '../util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import workspaceActions from './workspaceActions';
import { WORKSPACE_PAGE_TABS } from '@/constants/workspaceConstants';
import { createArtifactVersionKey } from '@artifacts/constants/constants';
import { getActiveWorkspaceName } from '@/reducers/ui/workspaceUiReducer';

const artifactActions = {
  fetchArtifactsForWorkspace(workspace, type) {
    return dispatch => {
      return artifactsApi
        .fetchArtifacts(workspace, type)
        .then(response => {
          const { artifacts } = response.data;

          const artifactsMap = artifacts.reduce((map, artifact) => {
            return { ...map, [artifact.artifactId]: artifact };
          }, {});

          dispatch({
            type: artifactActionTypes.SET_ARTIFACTS_MAP,
            payload: {
              map: artifactsMap
            }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching your workspace artifacts.'
            )
          );
        });
    };
  },

  fetchArtifact(workspace, artifactName) {
    return dispatch => {
      return artifactsApi
        .fetchArtifact(workspace, artifactName)
        .then(response => {
          const artifact = get(response, 'data', {});
          const { artifactId } = artifact;

          dispatch({
            type: artifactActionTypes.SET_ARTIFACT,
            payload: {
              artifact,
              artifactId,
              workspace,
              artifactName
            }
          });
        })
        .catch(error => {
          const errorMessage = get(error, ['response', 'data', 'msg'], '');
          const errorDetail = isEmpty(errorMessage) ? '' : `: ${errorMessage}`;

          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              `Unable to fetch artifact${errorDetail}`
            )
          );
        });
    };
  },

  updateArtifact(updatedArtifactFields) {
    return dispatch => {
      return artifactsApi
        .updateArtifact(updatedArtifactFields)
        .then(response => {
          const artifactId = get(response, ['data', 'artifactId'], '');

          const {
            artifactName: name,
            artifactType: type,
            description,
            emoji,
            isPublic,
            tags
          } = updatedArtifactFields;

          dispatch({
            type: artifactActionTypes.SET_ARTIFACT,
            payload: {
              artifactId,
              artifact: { name, type, description, emoji, isPublic, tags }
            }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error updating your artifact.'
            )
          );
        });
    };
  },

  fetchArtifactVersion(workspace, artifactName, version) {
    return dispatch => {
      return artifactsApi
        .fetchArtifactVersion(workspace, artifactName, version)
        .then(response => {
          const artifactVersion = get(response, 'data', {});

          dispatch({
            type: artifactActionTypes.SET_ARTIFACT_VERSION,
            payload: {
              versionMapId: createArtifactVersionKey(
                workspace,
                artifactName,
                version
              ),
              artifactVersion
            }
          });
        })
        .catch(error => {
          const errorMessage = get(error, ['response', 'data', 'msg'], '');
          const errorDetail = isEmpty(errorMessage) ? '' : `: ${errorMessage}`;

          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              `Unable to fetch artifact version${errorDetail}`
            )
          );
        });
    };
  },

  updateArtifactVersionLabels(
    updatedArtifactVersionFields,
    workspace,
    artifactName,
    version
  ) {
    const { artifactVersionId, tags, alias } = updatedArtifactVersionFields;

    return dispatch => {
      return artifactsApi
        .updateArtifactVersionLabels(artifactVersionId, tags, alias)
        .then(() => {
          const versionMapId = createArtifactVersionKey(
            workspace,
            artifactName,
            version
          );

          dispatch({
            type: artifactActionTypes.SET_ARTIFACT_VERSION,
            payload: {
              versionMapId,
              artifactVersion: { alias, tags }
            }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error updating your artifact version.'
            )
          );
        });
    };
  },

  updateArtifactVersionMetadata(
    updatedArtifactVersionFields,
    workspace,
    artifactName,
    version
  ) {
    const { artifactVersionId, metadata } = updatedArtifactVersionFields;

    const metadataStringified = JSON.stringify(metadata);

    return dispatch => {
      return artifactsApi
        .updateArtifactVersionMetadata(artifactVersionId, metadataStringified)
        .then(() => {
          const versionMapId = createArtifactVersionKey(
            workspace,
            artifactName,
            version
          );

          dispatch({
            type: artifactActionTypes.SET_ARTIFACT_VERSION,
            payload: {
              versionMapId,
              artifactVersion: { metadata: metadataStringified }
            }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error updating your artifact version.'
            )
          );
        });
    };
  },

  openDeleteArtifactModal(artifactId, redirectURL = null) {
    return (dispatch, getState) => {
      const workspaceName = getActiveWorkspaceName(getState());
      const msg = `This will delete your artifact permanently`;
      const confirmHandler = () => {
        dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_MODAL));

        return artifactsApi.deleteArtifact(artifactId).then(() => {
          dispatch({
            type: artifactActionTypes.DELETE_ARTIFACT,
            payload: { artifactId }
          });

          dispatch(artifactActions.fetchArtifactsCount(workspaceName));

          if (redirectURL) {
            history.push(redirectURL);
          }
        });
      };
      const buttonText = 'Delete';

      dispatch(
        alertsUtil.openConfirmDialog(
          dialogTypes.CONFIRM_DELETE_MODAL,
          msg,
          confirmHandler,
          buttonText
        )
      );
    };
  },

  openDeleteArtifactVersionModal(
    artifactVersionId,
    versionMapId,
    redirectURL = null
  ) {
    return dispatch => {
      const msg = `This will delete your artifact version permanently`;
      const confirmHandler = () => {
        dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_MODAL));

        return artifactsApi
          .deleteArtifactVersion(artifactVersionId)
          .then(() => {
            dispatch({
              type: artifactActionTypes.DELETE_ARTIFACT_VERSION,
              payload: {
                versionMapId
              }
            });

            if (redirectURL) {
              history.push(redirectURL);
            }
          });
      };
      const buttonText = 'Delete';

      dispatch(
        alertsUtil.openConfirmDialog(
          dialogTypes.CONFIRM_DELETE_MODAL,
          msg,
          confirmHandler,
          buttonText
        )
      );
    };
  },

  fetchArtifactsCount(workspaceName) {
    return dispatch => {
      return artifactsApi
        .fetchArtifactsCount(workspaceName)
        .then(response => {
          const artifactCount = get(response, ['data', 'artifactCount'], 0);

          dispatch(
            workspaceActions.setItemCountAndDisplay(
              WORKSPACE_PAGE_TABS.ARTIFACTS,
              artifactCount
            )
          );
        })

        .catch(error => {
          const { msg } = error.response.data;
          console.error(
            'There was an error fetching the artifacts count:',
            msg
          );
        });
    };
  }

  // fetchArtifactComments(artifactVersionId, version, alias) {
  //   return dispatch => {
  //     dispatch({
  //       type: artifactActionTypes.SET_ARTIFACT_VERSION_COMMENTS,
  //       payload: { artifactVersionId, comments: mockVersionComments }
  //     });

  //     return Promise.resolve();
  //     // return artifactsApi
  //     //   .fetchArtifactComments(artifactVersionId, version, alias)
  //     //   .then(response => {
  //     //     const { comments } = response.data;

  //     //     dispatch({
  //     //       type: artifactActionTypes.SET_ARTIFACT_VERSION_COMMENTS,
  //     //       payload: { artifactVersionId, comments }
  //     //     });
  //     //   })
  //     //   .catch(() => {
  //     //     dispatch(
  //     //       alertsUtil.openErrorDialog(
  //     //         dialogTypes.CATCH_ERROR_API,
  //     //         'There was an error fetching your artifact comments.'
  //     //       )
  //     //     );
  //     //   });
  //   };
  // },

  // fetchArtifactUsage(artifactVersionId, version, alias) {
  //   return dispatch => {
  //     dispatch({
  //       type: artifactActionTypes.SET_ARTIFACT_VERSION_USAGE,
  //       payload: { artifactVersionId, usage: mockVersionUsage }
  //     });

  //     return Promise.resolve();
  //     // return artifactsApi
  //     //   .fetchArtifactUsage(artifactVersionId, version, alias)
  //     //   .then(response => {
  //     //     const { usage } = response.data;

  //     //     dispatch({
  //     //       type: artifactActionTypes.SET_ARTIFACT_VERSION_USAGE,
  //     //       payload: { artifactVersionId, usage }
  //     //     });
  //     //   })
  //     //   .catch(() => {
  //     //     dispatch(
  //     //       alertsUtil.openErrorDialog(
  //     //         dialogTypes.CATCH_ERROR_API,
  //     //         'There was an error fetching your artifact usage.'
  //     //       )
  //     //     );
  //     //   });
  //   };
  // },

  // addCommentToArtifactVersion(artifactVersionId, markdown) {
  //   return dispatch => {
  //     const mockNewComment = {
  //       ...mockVersionComments[0],
  //       text: markdown,
  //       comment_id: randomstring.generate(6)
  //     };

  //     dispatch({
  //       type: artifactActionTypes.SET_ARTIFACT_VERSION_COMMENT,
  //       payload: { artifactVersionId, comment: mockNewComment }
  //     });

  //     // return artifactsApi
  //     //   .addCommentToArtifactVersion({ comment: markdown })
  //     //   .then(response => {
  //     //     const { comment } = response.data;
  //     //     // hopefully this post request will return the comment with all of
  //     //     // necessary data (user, id, created_at) to add it to the store
  //     //     // otherwise we will need to refetch the comments
  //     //     dispatch({
  //     //       type: artifactActionTypes.SET_ARTIFACT_VERSION_COMMENT,
  //     //       payload: { artifactVersionId, comment }
  //     //     });
  //     //   })
  //     //   .catch(() => {
  //     //     dispatch(
  //     //       alertsUtil.openErrorDialog(
  //     //         dialogTypes.CATCH_ERROR_API,
  //     //         'There was an error adding your comment.'
  //     //       )
  //     //     );
  //     //   });
  //   };
  // },

  // deleteCommentFromArtifactVersion(artifactVersionId, commentId) {
  //   return dispatch => {
  //     dispatch({
  //       type: artifactActionTypes.DELETE_ARTIFACT_VERSION_COMMENT,
  //       payload: { artifactVersionId, commentId }
  //     });

  //     // return artifactsApi
  //     //   .deleteCommentFromArtifactVersion(commentId)
  //     //   .then(() => {
  //     //     dispatch({
  //     //       type: artifactActionTypes.DELETE_ARTIFACT_VERSION_COMMENT,
  //     //       payload: { artficatVersionId, commentId }
  //     //     });
  //     //   })
  //     //   .catch(() => {
  //     //     dispatch(
  //     //       alertsUtil.openErrorDialog(
  //     //         dialogTypes.CATCH_ERROR_API,
  //     //         'There was an error deleting your comment.'
  //     //       )
  //     //     );
  //     //   });
  //   };
  // }
};

export default artifactActions;
