import { IconButton, Tooltip } from '@ds';
import PropTypes from 'prop-types';
import React from 'react';
import { DSShareIcon } from '@ds-icons';

const ShareReportButton = ({ onShare }) => {
  return (
    <Tooltip content="Share" placement="bottom" arrow={false}>
      <IconButton onClick={onShare} Icon={<DSShareIcon />} type="secondary" />
    </Tooltip>
  );
};

ShareReportButton.defaultProps = {
  iconStyles: {}
};

ShareReportButton.propTypes = {
  iconStyles: PropTypes.object,
  onShare: PropTypes.func.isRequired
};

export default ShareReportButton;
