import { noop } from 'lodash';
import { useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useObserveResizeNode(onChange: (node: HTMLElement) => void) {
  return useCallback((node: HTMLElement | null) => {
    if (!node) return noop;

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (node) {
          onChange(node);
        }
      });
    });

    resizeObserver.observe(node);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
}
