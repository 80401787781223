import { SORT_TYPES } from '@experiment-management-shared/constants/tabConfigs';
import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { ExpandedItem } from './ExpandedItem';
import {
  GALLERY_CONTAINER_HEIGHT,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from '../../../constants/graphics';
import { IMAGE_KEY_PREFIX } from '../../../utils/graphics';
import { TableMaxHeightProvider } from '@DesignSystem/tables';

const DEFAULT_ROW_HEIGHT = 52;
// The padding that MUI adds for the transition when expending an item
const DEFAULT_MUI_PADDING = 20;

function calculateDynamicHeight(width, imagesCount, rowItemsCount) {
  const itemsPerRow = rowItemsCount || Math.floor(width / IMAGE_WIDTH);
  const rowCount = Math.ceil(imagesCount / itemsPerRow);

  return rowCount > 3
    ? GALLERY_CONTAINER_HEIGHT + DEFAULT_ROW_HEIGHT + DEFAULT_MUI_PADDING
    : rowCount * IMAGE_HEIGHT + DEFAULT_ROW_HEIGHT + DEFAULT_MUI_PADDING;
}

export default function GroupedImagesList({
  filteredImages,
  groupBy,
  handleOpenImagePreview,
  handleSelectItem,
  selectedItems,
  rowItemsCount
}) {
  const [panels, setPanels] = useState({});
  const listRef = useRef();

  useEffect(() => {
    setPanels({});
    listRef.current.recomputeRowHeights();
  }, [groupBy]);

  const getRowHeight = (width, { index }) => {
    const groupName = Object.keys(filteredImages)[index];
    const images = filteredImages[groupName];

    return panels[groupName]
      ? calculateDynamicHeight(width, images.length, rowItemsCount)
      : DEFAULT_ROW_HEIGHT;
  };

  const handleToggle = (category, expanded) => {
    setPanels(prevSetPanels => ({
      ...prevSetPanels,
      [category]: expanded
    }));
    listRef.current.recomputeRowHeights();
  };

  const getCategoryText = () => {
    const [groupByKey, metadataKey] = groupBy.split(IMAGE_KEY_PREFIX);
    if (groupByKey === SORT_TYPES.CONTEXT) return 'Context';
    if (groupByKey === SORT_TYPES.NAME) return 'Name';
    if (groupByKey === SORT_TYPES.ASSET_TYPE) return 'Asset Type';
    if (groupByKey === SORT_TYPES.STEP) return 'Step';
    if (groupByKey === SORT_TYPES.METADATA) return metadataKey;

    return 'No category';
  };

  const categories = Object.keys(filteredImages);

  return (
    <TableMaxHeightProvider>
      {containerHeight => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              width={width}
              ref={listRef}
              height={containerHeight}
              rowCount={categories.length}
              rowHeight={props => getRowHeight(width, props)}
              rowRenderer={({ index, ...props }) => {
                const category = categories[index];
                const imagesForCategory = filteredImages[category];
                const filteredImagesForCategory = imagesForCategory.filter(
                  Boolean
                );
                const isCategoryOpen = Boolean(panels[category]);
                const imagesCount = filteredImagesForCategory.length;
                const countText =
                  imagesCount > 1
                    ? `${imagesCount} items`
                    : `${imagesCount} item`;

                return (
                  <ExpandedItem
                    handleSelectItem={handleSelectItem}
                    selectedItems={selectedItems}
                    key={category}
                    onChange={(event, expanded) => {
                      handleToggle(category, expanded, index);
                    }}
                    expanded={isCategoryOpen}
                    categoryText={getCategoryText()}
                    category={category}
                    countText={countText}
                    items={imagesForCategory}
                    handleOpenImagePreview={image =>
                      handleOpenImagePreview(image, filteredImagesForCategory)
                    }
                    rowItemsCount={rowItemsCount}
                    {...props}
                  />
                );
              }}
            />
          )}
        </AutoSizer>
      )}
    </TableMaxHeightProvider>
  );
}
