import { modelRegistryActionTypes } from '@/constants/actionTypes';
import modelRegistryApi from '../util/modelRegistryApi';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '../util/alertsUtil';
import workspaceActions from './workspaceActions';
import { WORKSPACE_PAGE_TABS } from '@/constants/workspaceConstants';
import { getItemCountForTab } from '@/reducers/ui/workspaceUiReducer';

const getModelId = (workspaceName, modelName) =>
  `${workspaceName}-${modelName}`;

const modelRegistryActions = {
  fetchRegisteredModel(workspaceName, modelName) {
    return dispatch => {
      return modelRegistryApi
        .fetchRegisteredModel(workspaceName, modelName)
        .then(response => {
          const model = response.data;

          dispatch({
            type: modelRegistryActionTypes.SET_REGISTERED_MODEL,
            payload: {
              modelId: getModelId(workspaceName, model.modelName),
              model
            }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching your model.'
            )
          );
        });
    };
  },

  fetchModelRegistryList(workspaceName) {
    return dispatch => {
      return modelRegistryApi
        .getAllWorkspaceModels(workspaceName)
        .then(response => {
          const { registryModels } = response.data;

          const modelsMap = registryModels.reduce(
            (map, model) => ({
              ...map,
              [getModelId(workspaceName, model.modelName)]: model
            }),
            {}
          );

          dispatch({
            type: modelRegistryActionTypes.SET_MODEL_REGISTRY_MAP,
            payload: {
              map: modelsMap
            }
          });

          dispatch({
            type: modelRegistryActionTypes.SET_MODEL_REGISTRY_COUNT,
            payload: { numberOfRegisteredModels: registryModels.length }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching your model registry list.'
            )
          );
        });
    };
  },

  fetchModelRegistryCount(workspaceName) {
    return dispatch => {
      return modelRegistryApi
        .fetchModelRegistryCount(workspaceName)
        .then(response => {
          const { registryModelCount } = response.data;

          dispatch({
            type: modelRegistryActionTypes.SET_MODEL_REGISTRY_COUNT,
            payload: { numberOfRegisteredModels: registryModelCount }
          });

          dispatch(
            workspaceActions.setItemCountAndDisplay(
              WORKSPACE_PAGE_TABS.MODEL_REGISTRY,
              registryModelCount
            )
          );
        })
        .catch(error => {
          const { msg } = error.response.data;
          console.error(
            'There was an error fetching the model registry count:',
            msg
          );
        });
    };
  },

  deleteModel(workspaceName, modelName) {
    return (dispatch, getState) => {
      const { MODEL_REGISTRY } = WORKSPACE_PAGE_TABS;

      const state = getState();
      const currentModelCount = getItemCountForTab(state, {
        tabKey: MODEL_REGISTRY
      });

      return modelRegistryApi
        .deleteModel(workspaceName, modelName)
        .then(() => {
          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_API_RESPONSE,
              'Successfully deleted model.'
            )
          );

          dispatch(
            workspaceActions.setItemCountAndDisplay(
              WORKSPACE_PAGE_TABS.MODEL_REGISTRY,
              currentModelCount - 1
            )
          );

          dispatch({
            type: modelRegistryActionTypes.DELETE_REGISTERED_MODEL,
            payload: { modelId: getModelId(workspaceName, modelName) }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error deleting your model.'
            )
          );
        });
    };
  },

  openDeleteModelConfirm(workspace, modelName, onConfirm = null) {
    return dispatch => {
      const type = dialogTypes.CONFIRM_DELETE_MODEL;
      const msg = `This will delete ${modelName} permanently`;
      const confirmHandler = () => {
        dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_MODEL));
        if (onConfirm) {
          onConfirm();
        } else {
          dispatch(modelRegistryActions.deleteModel(workspace, modelName));
        }
      };
      const buttonText = 'Delete';

      dispatch(
        alertsUtil.openConfirmDialog(type, msg, confirmHandler, buttonText)
      );
    };
  },

  createNewModel(
    experimentModelId,
    registryModelName,
    description,
    version,
    isPublic
  ) {
    return dispatch => {
      const newModel = {
        experimentModelId,
        registryModelName,
        description,
        isPublic,
        version,
        comment: '',
        stages: []
      };

      return modelRegistryApi.createNewModel(newModel).catch(() => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error creating your new model.'
          )
        );
      });
    };
  },

  createNewModelItem(experimentModelId, registryModelName, version) {
    return dispatch => {
      const newModelItem = {
        experimentModelId,
        registryModelName,
        version,
        comment: '',
        stages: []
      };

      return modelRegistryApi.createNewModelItem(newModelItem).catch(() => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error creating your new model version.'
          )
        );
      });
    };
  },

  validateModelName(workspaceName, modelName) {
    return dispatch => {
      return modelRegistryApi
        .validateModelName(workspaceName, modelName)
        .then(response => {
          const {
            finalModelName,
            originalModelName,
            validName,
            modelNameExists
          } = response.data;

          dispatch(
            modelRegistryActions.setModelNameValidation({
              finalModelName,
              originalModelName,
              validName,
              modelNameExists
            })
          );
        });
    };
  },

  setModelNameValidation(modelNameValidation = null) {
    return dispatch => {
      dispatch({
        type: modelRegistryActionTypes.SET_MODEL_NAME_VALIDATION,
        payload: { modelNameValidation }
      });
    };
  },

  validateModelVersion(version, registryModelId) {
    return dispatch => {
      const versionToValidate = registryModelId
        ? { registryModelId, version }
        : { version };

      return modelRegistryApi
        .validateModelVersion(versionToValidate)
        .then(response => {
          const { validVersion, versionExists } = response.data;

          dispatch(
            modelRegistryActions.setModelVersionValidation({
              validVersion,
              versionExists
            })
          );
        });
    };
  },

  setModelVersionValidation(modelVersionValidation = null) {
    return dispatch => {
      dispatch({
        type: modelRegistryActionTypes.SET_MODEL_VERSION_VALIDATION,
        payload: { modelVersionValidation }
      });
    };
  }
};

export default modelRegistryActions;
