import React from 'react';
import PropTypes from 'prop-types';

import isArray from 'lodash/isArray';
import cx from 'classnames';

import { formatDateTime, formatTimeNumber } from '@shared/utils/displayHelpers';
import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import classes from './GroupedColumnCell.module.scss';

const typeDisplay = (value, type) => {
  if (type === 'datetime') {
    return formatDateTime(value);
  }
  if (type === 'timenumber') {
    return formatTimeNumber(value);
  }

  return String(value);
};

const ExpandIcon = ({ expanded }) => {
  return (
    <div
      className={cx([classes.groupIcon], {
        [classes.groupIconExpanded]: expanded
      })}
    >
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.28074 5.59938C5.65506 5.28745 5.65506 4.71255 5.28074 4.40062L1.2797 1.06642C0.771525 0.642938 0 1.0043 0 1.6658L0 8.3342C0 8.9957 0.771526 9.35706 1.2797 8.93358L5.28074 5.59938Z" />
      </svg>
    </div>
  );
};

const GroupContent = ({ column, row, expanded }) => {
  const cellValue = typeDisplay(row.value, column.type);

  return (
    <div
      className={cx(classes.groupContent, {
        [classes.groupActive]: expanded
      })}
    >
      <div className={classes.groupContentValue}>{cellValue}</div>
    </div>
  );
};

GroupContent.propTypes = {
  expanded: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};

const GroupedColumnCell = ({ ...props }) => {
  const { GroupedCellComponent, groupedColumns, style, ...cellProps } = props;

  const modifiedStyles = { ...style };

  if (props.row?.groupedBy && isArray(groupedColumns)) {
    const index = groupedColumns
      .map(c => c.columnName)
      .indexOf(props.row.groupedBy);
    if (index >= 0) {
      modifiedStyles['--indent-level'] = index;
    }
  }

  return (
    <TableGroupRow.Cell
      showColumnsWhenGrouped
      {...cellProps}
      className={cx([classes.groupRowWrapper], props.className)}
      contentComponent={() =>
        GroupedCellComponent ? (
          <GroupedCellComponent {...props} />
        ) : (
          <GroupContent {...props} />
        )
      }
      iconComponent={ExpandIcon}
      style={modifiedStyles}
    />
  );
};

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired
};

GroupedColumnCell.defaultProps = {
  className: '',
  groupedColumns: [],
  groupedRowsData: []
};

GroupedColumnCell.propTypes = {
  className: PropTypes.string,
  groupedColumns: PropTypes.arrayOf(
    PropTypes.shape({ columnName: PropTypes.string.isRequired })
  ),
  groupedRowsData: PropTypes.array,
  GroupedCellComponent: PropTypes.node.isRequired
};

export default GroupedColumnCell;
