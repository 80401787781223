// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPreview-module__colorPreviewContainer--HzXIA{height:36px;width:100%;border:1px solid var(--gray-3);color:var(--black);display:flex;justify-content:space-between;align-items:center;border-radius:4px;cursor:pointer}.ColorPreview-module__colorPreviewContainer--HzXIA:hover{border-color:var(--gray-4)}.ColorPreview-module__colorName--JtOhN{margin-left:15px;font-size:14px;line-height:21px}.ColorPreview-module__colorContainer--DMPIa{height:24px;width:24px;border-radius:4px;margin-right:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPreviewContainer": `ColorPreview-module__colorPreviewContainer--HzXIA`,
	"colorName": `ColorPreview-module__colorName--JtOhN`,
	"colorContainer": `ColorPreview-module__colorContainer--DMPIa`
};
module.exports = ___CSS_LOADER_EXPORT___;
