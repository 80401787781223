import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import cx from 'classnames';
import last from 'lodash/last';
import { Column } from '@devexpress/dx-react-grid';

import { StyledTooltip } from '@DesignSystem/data-display';
import { AREACHART_Y_RANGE, MODEL_COLUMNS_NAMES } from '@mpm-druid/constants';
import AreaChart from './AreaChart';
import EmptyCell from '@shared/components/TableCells/EmptyCell';
import { AlertRule } from '@mpm-druid/types';

type AreaChartCellProps = {
  value: {
    x: string;
    y: number;
  }[];
  column: Column;
};

type LinkCellProps = { value: string | null; to: string };

export type AlertRulesTableCellType = {
  id: string;
  name: string;
  value: string | null;
  row: AlertRule;
};

const NO_DECIMALS_COLUMNS = [MODEL_COLUMNS_NAMES.numberOfPredictions];

const columnsWithYLimited = ['drift', 'modelHealth'];

const AreaChartCell = ({ value, column }: AreaChartCellProps) => {
  const yRange = columnsWithYLimited.includes(column?.name)
    ? AREACHART_Y_RANGE
    : null;
  const hasData = value?.length && value?.length > 0;

  return (
    <Box
      display="flex"
      overflow="hidden"
      alignItems="center"
      justifyContent="start"
    >
      <StyledTooltip
        placement="top"
        title={
          hasData
            ? `${column.title} of the last 30 days`
            : 'No data collected over the last 30 days'
        }
      >
        <div>
          {hasData ? (
            <AreaChart
              data={value}
              layout={{ showticklabels: false }}
              style={{ marginRight: '10px' }}
              yRange={yRange}
            />
          ) : (
            <EmptyCell />
          )}
        </div>
      </StyledTooltip>
      <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {hasData &&
          last(value)?.y?.toFixed(
            NO_DECIMALS_COLUMNS.includes(column.name) ? 0 : 2
          )}
      </p>
    </Box>
  );
};

const LinkCell = ({ value, to }: LinkCellProps) => (
  <Box display="flex">
    <Link style={{ color: 'black' }} to={to}>
      <span style={{ textDecoration: 'underline' }}>{value}</span>
    </Link>
  </Box>
);

export const OpenNotificationsCell = ({ value }: AlertRulesTableCellType) => {
  const hasOpenNotifications = typeof value === 'number' && value > 0;
  return (
    <Box alignItems="center" justifyContent="center">
      <span
        className={cx('notifications-indicator', {
          'has-open-notifications': hasOpenNotifications
        })}
      />
      {hasOpenNotifications ? `${value} Alerts` : ''}
    </Box>
  );
};

export { LinkCell, AreaChartCell };
