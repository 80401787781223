import React, { useState } from 'react';
import { Button } from '@ds';
import { getRulesCount } from '@shared/utils/filterHelpers';
import { FiltersSidebarContainer } from '../FiltersSidebarContainer';
import { DSFilterIcon } from '@ds-icons';
import { ColumnType, QueryRule } from '@mpm-druid/types';

type FiltersButtonProps = {
  onChange: (newQuery: QueryRule) => void;
  query: QueryRule;
  columns: ColumnType[];
};

export const FiltersButton = ({
  onChange,
  query,
  columns
}: FiltersButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const rulesCount = getRulesCount(query.rulesTree);
  const countLabel = rulesCount ? ` (${rulesCount})` : '';
  return (
    <div style={{ padding: '0 5px 0 10px' }}>
      <Button
        type="secondary"
        size="small"
        PrefixIcon={<DSFilterIcon />}
        onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
        iconSize="small"
        active={isOpen}
      >
        Filters {countLabel}
      </Button>

      <FiltersSidebarContainer
        isOpen={isOpen}
        title="models"
        onChange={onChange}
        onClose={() => setIsOpen(false)}
        query={query}
        columns={columns}
      />
    </div>
  );
};
