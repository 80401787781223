import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';

import { FullWidthBasicModal } from '@DesignSystem/modals';
import { URLS } from '@/lib/appConstants';
import {
  PAYMENT_PLANS,
  FREE_PLANS,
  ORGANIZATION_PLAN_ENTERPRISE,
  PAID_PLANS
} from '@shared/constants';
import { SelectPaymentPlan, PurchasePlan } from './UpdatePlanComponents';
import {
  useAddOrganizationPaymentMutation,
  useActiveOrganizationPaymentDetails
} from '@account-settings/api';
import {
  useCurrentOrganization,
  useCurrentPaymentPlan,
  useUpdatePlanMutation
} from '@shared/hooks';

import InviteTeamMembers from './UpdatePlanComponents/InviteTeamMembers';
import { UPGRADE_MODAL_STEP_AMOUNT } from '@account-settings/constants';

const SELECT_PLAN = 'select-plan';
const INVITE_TEAM_MEMBERS = 'invite-team-members';
const DEFAULT_STEP_AMOUNT = 2;
const DEFAULT_COUNTRY = 'US';

const UpgradePlanModal = ({ open, onClose, onHarshClose }) => {
  const { search } = useLocation();
  const history = useHistory();
  const currentOrganization = useCurrentOrganization();

  const { paymentPlanName: activePaymentPlan } = useCurrentPaymentPlan();
  const [selectedPlan, setSelectedPlan] = useState(activePaymentPlan);
  const [currentTab, setCurrentTab] = useState(SELECT_PLAN);

  const { data: billingDetails } = useActiveOrganizationPaymentDetails();

  const selectedPaymentPlan = PAYMENT_PLANS[selectedPlan];
  const addOrganizationPaymentMutation = useAddOrganizationPaymentMutation(
    currentOrganization
  );
  const updatePlanMutation = useUpdatePlanMutation();
  const { isFree } = useCurrentPaymentPlan();

  // ADD PAYMENT CARD FORM
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [postalCode, setPostalCode] = useState('');
  const [stepAmount, setStepAmount] = useState(() => {
    const stepAmountFromQuery = queryString.parse(search)[
      UPGRADE_MODAL_STEP_AMOUNT
    ];

    if (+stepAmountFromQuery === 3) {
      return 3;
    }

    return DEFAULT_STEP_AMOUNT;
  });

  useEffect(() => {
    if (!open) {
      setStepAmount(2);
    }
  }, [history, open]);

  useEffect(() => {
    setAddress('');
    setCity('');
    setState('');
    setCountry(DEFAULT_COUNTRY);
    setPostalCode('');
  }, [currentTab]);

  useEffect(() => {
    if (!open) {
      setSelectedPlan(activePaymentPlan);
      setCurrentTab(SELECT_PLAN);
    }
  }, [open, activePaymentPlan]);

  const IS_CURRENT_PLAN_HAS_PAYMENT_METHOD = !FREE_PLANS.includes(
    activePaymentPlan
  );
  const IS_SELECT_PLAN_TAB_OPEN = currentTab === SELECT_PLAN;
  const INVITE_TEAM_MEMBERS_OPEN = currentTab === INVITE_TEAM_MEMBERS;

  const IS_ENTERPRISE_SELECTED = selectedPlan === ORGANIZATION_PLAN_ENTERPRISE;
  const IS_SELECTED_PLAN_NEED_PAYMENT_METHOD = PAID_PLANS.includes(
    selectedPlan
  );
  const HAS_ADDITIONAL_STEPS = stepAmount > DEFAULT_STEP_AMOUNT;
  const DOES_CURRENT_USER_HAVE_A_CARD = !!billingDetails?.creditCard?.id;
  const IS_TRIAL =
    IS_SELECTED_PLAN_NEED_PAYMENT_METHOD && !DOES_CURRENT_USER_HAVE_A_CARD;
  const IS_BACK_BUTTON_SHOWN =
    !IS_SELECT_PLAN_TAB_OPEN && !INVITE_TEAM_MEMBERS_OPEN;

  const getButtonText = () => {
    if (INVITE_TEAM_MEMBERS_OPEN) {
      return null;
    }

    if (isFree) {
      return 'Close';
    }

    if (IS_SELECT_PLAN_TAB_OPEN) {
      if (selectedPlan === activePaymentPlan) {
        if (
          IS_SELECTED_PLAN_NEED_PAYMENT_METHOD &&
          !DOES_CURRENT_USER_HAVE_A_CARD
        ) {
          return 'Continue to billing';
        }

        return 'Close';
      }

      if (selectedPlan === ORGANIZATION_PLAN_ENTERPRISE) {
        return 'Continue';
      }

      if (!IS_CURRENT_PLAN_HAS_PAYMENT_METHOD) {
        return 'Continue to billing';
      }

      if (
        selectedPaymentPlan?.priority <
        PAYMENT_PLANS[activePaymentPlan]?.priority
      ) {
        return 'Downgrade plan';
      }

      if (
        selectedPaymentPlan?.priority ===
        PAYMENT_PLANS[activePaymentPlan]?.priority
      ) {
        return 'Switch plan';
      }

      return 'Upgrade plan';
    }

    if (IS_SELECTED_PLAN_NEED_PAYMENT_METHOD) {
      if (HAS_ADDITIONAL_STEPS) {
        return 'Submit Payment';
      }

      return 'Purchase plan';
    }

    return 'Close';
  };

  const isLoading =
    addOrganizationPaymentMutation.isLoading || updatePlanMutation.isLoading;

  const isPrimaryButtonDisabled = () => {
    if (isLoading) {
      return isLoading;
    }

    if (!IS_SELECT_PLAN_TAB_OPEN) {
      return !address || !country || !city;
    }

    return false;
  };

  const addPaymentMethod = async (fromTrial = false) => {
    try {
      await addOrganizationPaymentMutation.mutateAsync({
        address,
        city,
        state,
        postalCode,
        country,
        plan: selectedPlan,
        amount: billingDetails?.allMembersCount,
        fromTrial
      });

      if (HAS_ADDITIONAL_STEPS) {
        setCurrentTab(INVITE_TEAM_MEMBERS);
      } else {
        onHarshClose();
      }
    } catch {
      // handled in the mutation
    }
  };

  const updatePaymentPlan = async (trialPaymentPlan = null) => {
    try {
      await updatePlanMutation.mutateAsync({
        plan: trialPaymentPlan || selectedPlan
      });

      if (HAS_ADDITIONAL_STEPS) {
        setCurrentTab(INVITE_TEAM_MEMBERS);
      } else {
        onClose();
      }

      if (trialPaymentPlan) {
        history.push(
          `/organizations/${currentOrganization?.id}/workspaces?fromTrial=true`
        );
      }
    } catch {
      // handled in the mutation
    }
  };

  const handleClickTrialPlan = trialPaymentPlan => {
    updatePaymentPlan(trialPaymentPlan);
  };

  const getModalTab = () => {
    if (IS_SELECT_PLAN_TAB_OPEN) {
      return {
        activeStepIndex: 0,
        title: 'Pick a plan for your organization',
        render: () => (
          <SelectPaymentPlan
            selectedPlan={selectedPlan}
            onChangeSelectedPlan={setSelectedPlan}
            onClickTrialPlan={handleClickTrialPlan}
          />
        )
      };
    }

    if (INVITE_TEAM_MEMBERS_OPEN) {
      return {
        activeStepIndex: 2,
        render: () => <InviteTeamMembers onClose={onClose} />,
        title: 'You successfully upgraded your plan'
      };
    }

    return {
      activeStepIndex: 1,
      render: () => (
        <PurchasePlan
          selectedPlan={selectedPlan}
          address={address}
          onChangeAddress={setAddress}
          city={city}
          onChangeCity={setCity}
          state={state}
          onChangeState={setState}
          country={country}
          onChangeCountry={setCountry}
          postalCode={postalCode}
          onChangePostalCode={setPostalCode}
        />
      ),
      title: `Purchase ‘${selectedPaymentPlan?.displayName}’ plan for “${currentOrganization?.name}”`
    };
  };
  const handlePrimaryButtonClick = () => {
    if (isFree) {
      onClose();
    }

    if (selectedPlan === activePaymentPlan) {
      // trial
      if (IS_TRIAL) {
        if (IS_SELECT_PLAN_TAB_OPEN) {
          setCurrentTab(selectedPlan);
          return;
        }

        addPaymentMethod(true);
        return;
      }

      onClose();
      return;
    }

    if (IS_ENTERPRISE_SELECTED) {
      onClose();

      window.open(URLS.CONTACT_US_NEW_SITE, '_blank')?.focus();
      return;
    }

    if (IS_SELECT_PLAN_TAB_OPEN) {
      if (
        !IS_ENTERPRISE_SELECTED &&
        (!IS_SELECTED_PLAN_NEED_PAYMENT_METHOD ||
          IS_CURRENT_PLAN_HAS_PAYMENT_METHOD)
      ) {
        updatePaymentPlan();
      } else {
        setCurrentTab(selectedPlan);
      }

      return;
    }

    if (
      !IS_SELECT_PLAN_TAB_OPEN &&
      !IS_CURRENT_PLAN_HAS_PAYMENT_METHOD &&
      IS_SELECTED_PLAN_NEED_PAYMENT_METHOD
    ) {
      addPaymentMethod();
    }
  };

  const handleBackButtonClick = () => {
    if (IS_BACK_BUTTON_SHOWN) {
      setCurrentTab(SELECT_PLAN);
    }
  };

  const currentTabSettings = getModalTab();

  return (
    <FullWidthBasicModal
      title={currentTabSettings?.title}
      open={open}
      onClose={onClose}
      content={
        <div className="upgrade-plan-modal">{currentTabSettings?.render()}</div>
      }
      primaryButtonText={getButtonText()}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
      isPrimaryButtonLoading={isLoading}
      stepActiveIndex={currentTabSettings?.activeStepIndex}
      stepAmount={stepAmount}
      withoutFooter={INVITE_TEAM_MEMBERS_OPEN}
      withBackButton={IS_BACK_BUTTON_SHOWN}
      onBackButtonClick={handleBackButtonClick}
    />
  );
};

UpgradePlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onHarshClose: PropTypes.func.isRequired
};

export default UpgradePlanModal;
