import { BUILT_IN_CHART_TYPES } from '@/lib/appConstants';
import BarChartDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/BarChartTabs/BarChartDataTab';
import DataPanelDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/DataPanelTabs';
import GenericVizAxisTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/GenericVizTabs/GenericVizAxisTab';
import GenericVizGroupTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/GenericVizTabs/GenericVizGroupTab';
import GenericVizLegendTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/GenericVizTabs/GenericVizLegendTab';
import ImagePanelAnnotationTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/ImagePanelTabs/ImagePanelAnnotationTab';
import ImagePanelDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/ImagePanelTabs/ImagePanelDataTab';
import PCDPanelDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/PCDPanelTabs/PCDPanelDataTab';
import CurvesPanelDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/CurvesPanelTabs/CurvesPanelDataTab';
import VideoPanelDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/VideoPanelTabs/VideoPanelDataTab';
import LineChartDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/LineChartTabs/LineChartDataTab';
import ParallelChartDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/ParallelChartTabs/ParallelChartDataTab';
import ScalarChartDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/ScalarChartTabs/ScalarChartDataTab';
import ScalarChartDescriptionTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/ScalarChartTabs/ScalarChartDescriptionTab';
import ScatterChartDataTab from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/ScatterChartTabs/ScatterChartDataTab';
import {
  PythonCodeTab,
  PythonEnvironmentTab
} from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/PythonPanelTabs';

export const BUILT_IN_PANEL_TABS_MAP = {
  [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: {
    nameLabel: 'Chart title',
    tabs: [
      { label: 'Data', value: 'data', component: LineChartDataTab },
      {
        label: 'Group',
        value: 'group',
        component: GenericVizGroupTab
      },
      {
        label: 'Axis',
        value: 'axis',
        component: GenericVizAxisTab
      },
      {
        label: 'Legend',
        value: 'legend',
        component: GenericVizLegendTab
      }
    ]
  },
  [BUILT_IN_CHART_TYPES['BuiltIn/Scatter']]: {
    nameLabel: 'Chart title',
    tabs: [
      {
        label: 'Data',
        value: 'data',
        component: ScatterChartDataTab
      },
      {
        label: 'Axis',
        value: 'axis',
        component: GenericVizAxisTab
      },
      {
        label: 'Legend',
        value: 'legend',
        component: GenericVizLegendTab
      }
    ]
  },
  [BUILT_IN_CHART_TYPES['BuiltIn/Bar']]: {
    nameLabel: 'Chart title',
    tabs: [
      {
        label: 'Data',
        value: 'data',
        component: BarChartDataTab
      },
      {
        label: 'Group',
        value: 'group',
        component: GenericVizGroupTab
      },
      {
        label: 'Axis',
        value: 'axis',
        component: GenericVizAxisTab
      },
      {
        label: 'Legend',
        value: 'legend',
        component: GenericVizLegendTab
      }
    ]
  },
  [BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']]: {
    nameLabel: 'Chart title',
    tabs: [{ label: 'Data', value: 'data', component: ParallelChartDataTab }]
  },
  [BUILT_IN_CHART_TYPES.scalar]: {
    nameLabel: 'Chart title',
    tabs: [
      { label: 'Scalar', value: 'scalar', component: ScalarChartDataTab },
      {
        label: 'Options',
        value: 'Options',
        component: ScalarChartDescriptionTab
      }
    ]
  },
  [BUILT_IN_CHART_TYPES.image]: {
    nameLabel: 'Grid title',
    tabs: [
      { label: 'Data', value: 'data', component: ImagePanelDataTab },
      {
        label: 'Annotation',
        value: 'annotation',
        component: ImagePanelAnnotationTab
      }
    ]
  },
  [BUILT_IN_CHART_TYPES.video]: {
    nameLabel: 'Grid title',
    tabs: [{ label: 'Data', value: 'data', component: VideoPanelDataTab }]
  },
  [BUILT_IN_CHART_TYPES.data]: {
    nameLabel: 'Grid title',
    tabs: [{ label: 'Data', value: 'data', component: DataPanelDataTab }]
  },
  [BUILT_IN_CHART_TYPES.pcd]: {
    nameLabel: 'Grid title',
    tabs: [{ label: 'Data', value: 'data', component: PCDPanelDataTab }]
  },
  [BUILT_IN_CHART_TYPES.curves]: {
    nameLabel: 'Logged Curve',
    tabs: [
      { label: 'Data', value: 'data', component: CurvesPanelDataTab },
      {
        label: 'Axis',
        value: 'axis',
        component: GenericVizAxisTab
      },
      {
        label: 'Legend',
        value: 'legend',
        component: GenericVizLegendTab
      }
    ]
  },
  [BUILT_IN_CHART_TYPES.python]: {
    nameLabel: 'Chart title',
    tabs: [
      { label: 'Code', value: 'code', component: PythonCodeTab },
      {
        label: 'Python Environment',
        value: 'python-environment',
        component: PythonEnvironmentTab
      }
    ]
  }
};
