import CheckIcon from '@material-ui/icons/Check';
import RoundCheckbox from '@design-system-outdated/components/RoundCheckbox/RoundCheckbox';
import React from 'react';
import cx from 'classnames';
import classNames from './GraphicsTile.module.scss';

type GraphicsTileCheckboxProps = {
  onClick: () => void;
  isChecked: boolean;
  isDisabled: boolean;
};
const GraphicsTileCheckbox: React.FC<GraphicsTileCheckboxProps> = ({
  onClick,
  isChecked,
  isDisabled
}) => {
  return (
    <RoundCheckbox
      dataTest="graphics-image-tile-checkbox"
      checked={isChecked}
      className={cx(classNames.imageTileContainerCheckboxContainer, {
        [classNames.imageTileContainerCheckboxContainerActive]: isChecked,
        [classNames.imageTileContainerCheckboxContainerDisabled]: isDisabled
      })}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDisabled) {
          onClick();
        } else {
          e?.nativeEvent?.stopImmediatePropagation();
        }
      }}
      checkedIcon={<CheckIcon />}
      color="primary"
    />
  );
};

export default GraphicsTileCheckbox;
