// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicsGallery-module__galleryContainer--nQozP{flex:1;padding-bottom:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"galleryContainer": `GraphicsGallery-module__galleryContainer--nQozP`
};
module.exports = ___CSS_LOADER_EXPORT___;
