import { useQuery } from 'react-query';

import { axiosInstance, cancelWrapper } from '../api';

const getParameters = ({ experimentKeys, searchPhrase, maxResults }) => {
  return {
    experiments: experimentKeys,
    searchPhrase,
    maxResults
  };
};

const getVideosSearch = async ({ parameters }) => {
  if (
    parameters.searchPhrase?.length < 3 ||
    parameters.experiments?.length === 0
  ) {
    return null;
  }

  return cancelWrapper(async cancelToken => {
    return axiosInstance({
      cancelToken,
      data: parameters,
      method: 'post',
      url: 'video/search-by-filter'
    });
  });
};

export default function useVideoSearch(data, config) {
  const parameters = getParameters({
    ...data
  });

  return useQuery(
    ['videosSearch', { parameters }],
    () => getVideosSearch({ parameters }),
    {
      ...config
    }
  );
}
