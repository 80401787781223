import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';

type UseExperimentCodeParams = {
  experimentKey: string;
};

const getExperimentCode = async ({
  experimentKey,
  signal
}: {
  experimentKey: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get('data-fetch/get/code', {
    params: { experimentKey },
    signal
  });

  return data;
};

export default function useExperimentCode(
  { experimentKey }: UseExperimentCodeParams,
  options: QueryConfig<{
    code: string;
    fileName: string;
  }> = {}
) {
  return useQuery(
    ['experiment-code', { experimentKey }],
    ({ signal }) => {
      return getExperimentCode({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      ...options
    }
  );
}
