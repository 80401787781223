import { useQuery } from 'react-query';

import api from '@API/api';

import { QueryConfig } from '@shared/api';
import { Point } from '@mpm-druid/types';

type AlertPreviewParams = {
  modelId: string;
  name: string;
  runCronExpression: string;
  evaluationWindowHours: number;
  alertSpec: {
    type: string;
    rule: string;
    threshold: number;
  };
  metricSpec: {
    customMetricId?: string;
    type?: string;
    attributeName?: string;
  };
  predicate?: string;
  delayHours: number;
  modelVersion?: string | null;
  refDriftDatasetModelId: string | null;
  totalRangeWindowHours: number;
  totalRangeIntervalType: 'DAILY' | 'HOURLY';
  description?: string;
};

type AlertPreviewResponse = {
  previewMetricResultValue: Point;
  metricResults: Point[];
};

const getAlertPreview = ({ ...restParams }: AlertPreviewParams) =>
  api.post(`/mpm/v2/monitors/preview`, restParams);

export function useAlertPreview(
  params: AlertPreviewParams,
  config: QueryConfig<AlertPreviewResponse>
) {
  const {
    modelId,
    runCronExpression,
    evaluationWindowHours,
    metricSpec,
    predicate,
    delayHours,
    modelVersion,
    refDriftDatasetModelId,
    totalRangeWindowHours,
    totalRangeIntervalType
  } = params;
  return useQuery(
    [
      'alert-preview',
      {
        modelId,
        runCronExpression,
        evaluationWindowHours,
        metricSpec,
        predicate,
        delayHours,
        modelVersion,
        refDriftDatasetModelId,
        totalRangeWindowHours,
        totalRangeIntervalType
      }
    ],
    () => getAlertPreview({ ...params }),
    {
      ...config
    }
  );
}
