import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes } from '../../../constants/alertTypes';

const getModelRegistryLineage = async ({ registryModelItemId, workspace }) => {
  const { data } = await modelRegistryApi.fetchModelItemLineage({
    registryModelItemId,
    workspace
  });

  return data?.lineage;
};

const useModelRegistryLineage = ({ registryModelItemId, workspace }) => {
  const dispatch = useDispatch();

  return useQuery(
    ['modelRegistryLineage', { registryModelItemId }],
    () => getModelRegistryLineage({ registryModelItemId, workspace }),
    {
      enabled: !!registryModelItemId && !!workspace,
      refetchOnMount: true,
      onError: () =>
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your model lineage.'
          )
        )
    }
  );
};

export default useModelRegistryLineage;
