import useComputeEngine from './useComputeEngine';
import { useQuery, useQueryClient } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import { AxiosResponse } from 'axios';
import { restartComputeEngine } from '@experiment-management-shared/api/custom-server-panels/helpers';

const keepAliveComputeEngine = (
  computeEngine: string,
  signal?: AbortSignal
) => {
  return api.get<void>(`${computeEngine}/api/keep-alive`, { signal });
};

const useComputeKeepAlive = (
  projectId: string,
  config?: QueryConfig<void | AxiosResponse>
) => {
  const queryClient = useQueryClient();
  const {
    data: computeEngine = null,
    isLoading: isComputeEngineIsLoading,
    isFetching: isComputeEngineFetching
  } = useComputeEngine(projectId);

  const outEnabled = config?.enabled ?? true;
  return useQuery(
    ['compute-engine-keep-alive', { projectId }],
    ({ signal }) => {
      if (computeEngine) {
        return keepAliveComputeEngine(computeEngine, signal);
      }
    },
    {
      ...config,
      enabled: !!(
        outEnabled &&
        computeEngine &&
        projectId &&
        !isComputeEngineIsLoading &&
        !isComputeEngineFetching
      ),
      onError: async (err: Error) => {
        config?.onError?.(err);
        await restartComputeEngine(queryClient, projectId);
      }
    }
  );
};

export default useComputeKeepAlive;
