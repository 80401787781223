// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoIconWithTooltip-module__dsInfoIcon--EXTPh{margin-bottom:-2px;fill:var(--gray-6);color:var(--gray-6)}.InfoIconWithTooltip-module__dsInfoIcon--EXTPh svg{fill:var(--gray-6)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsInfoIcon": `InfoIconWithTooltip-module__dsInfoIcon--EXTPh`
};
module.exports = ___CSS_LOADER_EXPORT___;
