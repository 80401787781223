// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperimentDetailViewConfiguration-module__experimentDetailRouteWrapper--xrmtu{display:flex;height:100%;align-items:center;gap:8px}.ExperimentDetailViewConfiguration-module__notLoggedInUserViewName--voMxZ{color:var(--gray-4);font-size:13px;font-weight:500;margin-right:25px;max-width:120px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentDetailRouteWrapper": `ExperimentDetailViewConfiguration-module__experimentDetailRouteWrapper--xrmtu`,
	"notLoggedInUserViewName": `ExperimentDetailViewConfiguration-module__notLoggedInUserViewName--voMxZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
