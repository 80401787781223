import React, { createContext, useContext, useMemo, useState } from 'react';

export type SearchListContextType<T = unknown> = {
  itemList: T[];
  searchValue: string;
  isEmpty: boolean;
  isNotFound: boolean;
  setSearchValue: (v: string) => void;
  onResetSearch: () => void;
};

export const SearchListContext = createContext<
  SearchListContextType | undefined
>(undefined);

type SearchListProviderProps<T, K = keyof T> = {
  itemList: T[];
  searchField: K;
  searchValue?: string;
  isRegexSearchEnabled?: boolean;
  children: React.ReactNode;
};
export const SearchListProvider = <T extends object>({
  itemList,
  searchField,
  searchValue = '',
  isRegexSearchEnabled = false,
  children
}: SearchListProviderProps<T>) => {
  const [filterValue, setFilterValue] = useState(searchValue);

  const filteredItems = useMemo(() => {
    return itemList.filter((itemData: T) => {
      if (!filterValue) return true;

      if (isRegexSearchEnabled) {
        try {
          const regex = new RegExp(filterValue, 'i');
          return regex.test(itemData[searchField] as string);
        } catch (e) {
          // Invalid regex, fall back to base filter
          return (itemData[searchField] as string)
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        }
      }

      return (itemData[searchField] as string)
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    });
  }, [filterValue, itemList, searchField]);

  const onResetSearch = () => {
    setFilterValue('');
  };

  const isEmpty = !filterValue && !itemList.length;
  const isNotFound = Boolean(filterValue) && !filteredItems.length;

  return (
    <SearchListContext.Provider
      value={{
        itemList: filteredItems,
        searchValue: filterValue,
        isEmpty,
        isNotFound,
        setSearchValue: setFilterValue,
        onResetSearch
      }}
    >
      {children}
    </SearchListContext.Provider>
  );
};

export const useSearchListContext = (): SearchListContextType => {
  const ctx = useContext(SearchListContext);

  if (ctx === undefined) {
    throw new Error('useListContext must be used within a SearchListProvider');
  }

  return ctx;
};
