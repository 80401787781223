import randomstring from 'randomstring';
import { LINE_TYPE_STRATEGY } from '@experiment-management-shared/constants/chartConstants';
import {
  Panel,
  PanelGlobalConfig,
  PanelGlobalConfigMap,
  PanelSection,
  PanelSections
} from '@experiment-management-shared/types';

export const generatePanelId = () => randomstring.generate(6);
export const generatePythonPanelCodeVersion = () => randomstring.generate(6);

export const calculateLineTypeStrategy = ({
  isComparePage
}: {
  isComparePage: boolean;
}) => (isComparePage ? LINE_TYPE_STRATEGY.AUTO : LINE_TYPE_STRATEGY.FIRST);

const DEPRECATED_SMOOTHING_TRANSFORMATION = 'smoothing';

// it's used to remove transformY = 'smoothing' since the option is deprecated
export const hasToRemoveTransformField = (transform?: string | null) =>
  transform === DEPRECATED_SMOOTHING_TRANSFORMATION;

export const normalizePanel = (panel: Panel) => {
  const newPanel = { ...panel };

  if (hasToRemoveTransformField(panel.transformX)) {
    newPanel.transformX = null;
  }

  if (hasToRemoveTransformField(panel.transformY)) {
    newPanel.transformY = null;
  }

  return newPanel;
};

// state should be calculated only in case locked is not defined or false,
// in case the panel already locked we don't want to modify this flag automatically
export const calculateLockedState = (
  panelGlobalConfig: PanelGlobalConfig,
  panel: Panel,
  globalConfigMap: PanelGlobalConfigMap
) => {
  if (panel.locked) return true;

  const keysForEqual = globalConfigMap[panel.chartType];
  if (keysForEqual) {
    return keysForEqual.some(key => panelGlobalConfig[key] !== panel[key]);
  }

  return false;
};

export const generateEmptySection = (title = 'Metrics'): PanelSection => {
  return {
    id: randomstring.generate(6),
    title,
    autogenerated: false,
    expanded: true,
    panels: [],
    layout: []
  };
};

export const getSectionById = (
  sections: PanelSections,
  id: string
): PanelSection =>
  sections.find(section => section.id === id) || generateEmptySection();

export const calculateHasLockedPanels = (sections: PanelSection[]): boolean =>
  sections.some(section => section.panels.some(panel => panel.locked));

export const calculatePanelsCount = (sections: PanelSection[]): number =>
  sections.reduce((acc, section) => acc + section.panels.length, 0);

export const getPanelByIdFromSections = (
  sections: PanelSections,
  id: string
): Panel | undefined => {
  for (let i = 0; i < sections.length; i++) {
    const panel = sections[i].panels.find(panel => panel.chartId === id);
    if (panel) return panel;
  }
};
