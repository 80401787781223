import React, { useMemo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

import { TextButton } from '@ds';

import { Box, Grid } from '@material-ui/core';
import { SectionTabs } from '@DesignSystem/navigation';
import useModelRegistry from '@API/model-registry/useModelRegistry';
import useAssetsForExperiment from '@API/assets/useAssetsForExperiment';
import {
  SourceExperiment,
  Stages,
  StyledTooltip
} from '@DesignSystem/data-display';
import DetailsAssetsTab from '@model-registry/components/DetailsAssetsTab';
import ModelVersionApiTab from '@model-registry/components/ModelVersionApiTab';
import MoveModelVersionModal from '@model-registry/components/MoveModelVersionModal';
import ModelVersionLineageTab from '@model-registry/components/ModelVersionLineageTab';
import useExperimentNameByExperimentKey from '@experiment-management-shared/api/useExperimentNameByExperimentKey';
import ModelVersionHistoryTab from '@model-registry/components/ModelVersionHistoryTab';
import ModelVersionNotesTab from '@model-registry/components/ModelVersionNotesTab';
import ModelVersionRequestStatusModal from '@model-registry/components/ModelVersionRequestStatusModal';
import { OPEN_MODAL_ORIGIN } from '@model-registry/constants/request-status';
import useHandleStatusUpdate from '@model-registry/hooks/useHandleStatusUpdate';
import { formatModelVersionStatusToText } from '@shared/utils/model-registry-request-status';
import RequestStatus from '@shared/components/RequestStatus';
import useUserPermission from '@shared/api/useUserPermission';
import { MANAGEMENT_PERMISSIONS_SERVER } from '@shared/constants/permissions';
import { useSelector } from 'react-redux';
import Tags from '@shared/components/Tags';
import { DeleteModelVersionModal } from '@model-registry/components/DeleteModelModal';
import {
  formatStagesListToTags,
  ifAllAssetsIsRemote
} from '@model-registry/utils';
import { MAX_LENGTH_EXPERIMENT_KEY } from '@experiment-management-shared/constants/compareTableConstants';

import SmallLoader from '@shared/components/SmallLoader';

import ModelRegistryHeader from '@model-registry/components/ModelRegistryHeader';

import ModelRegistrySelectVersions from '@model-registry/components/ModelRegistrySelectVersions';
import {
  DETAIL_VIEW_TABS,
  DETAIL_VIEW_TABS_ORDER,
  DETAIL_VIEW_TABS_ORDER_VIEW_ONLY,
  HEADER_TABS,
  HEADER_TABS_VIEW_ONLY
} from '@model-registry/constants/detailsConstants';

import useActiveTabFromURL from '@/helpers/custom-hooks/useActiveTabFromUrl';
import { SUB_PATHS } from '@/constants/urlConstants';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import {
  BETA_FEATURE_MODEL_APPROVAL_PROCESS,
  BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
} from '@/lib/betaFeatures';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { getDownloadModelRegistryItemURL } from '@model-registry/utils/utils';
import {
  useCurrentOrganization,
  useIsUserMemberOfWorkspace,
  useIsViewOnlyUser
} from '@shared/hooks';
import { DSDeleteIcon, DSMoveIcon, DSDownloadIcon } from '@ds-icons';

const ModelRegistryDetails = () => {
  const { modelName, workspace, modelVersion } = useParams();
  const isUserLoggedIn = useSelector(state => getIsUserLoggedIn(state));
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const { data: model = {}, isLoading: isLoadingModel } = useModelRegistry({
    modelName,
    workspace
  });

  const currentOrganization = useCurrentOrganization();
  const isAdmin = currentOrganization?.isAdmin;

  const [isDeleteVersionModalOpen, setIsDeleteVersionModalOpen] = useState(
    false
  );
  const history = useHistory();
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const latestModelVersion = useMemo(() => {
    const versions = get(model, 'versions', []);
    return last(sortBy(versions, 'createdAt')) || {};
  }, [model]);
  const selectedVersion = useMemo(
    () =>
      get(model, 'versions', []).find(
        ({ version }) => version === modelVersion
      ) || {},
    [model, modelVersion, modelName]
  );
  const isRemoteModel = ifAllAssetsIsRemote(selectedVersion.assets);
  const stageList = selectedVersion?.stageList || selectedVersion?.stages;
  const experimentKey = selectedVersion?.experimentModel?.experimentKey || '';
  const assetModelName = selectedVersion?.experimentModel?.modelName || '';
  const autoGenerated = selectedVersion?.autoGenerated;
  const selectedVersionStatus = selectedVersion?.status;
  const selectedVersionRequestedStatus = selectedVersion?.requestedStatus;
  const selectedModelVersion = selectedVersion?.version;
  const isLatest = selectedVersion?.version === latestModelVersion?.version;
  const isLastModelVersion = model.versions?.length === 1;

  const { data: experimentName } = useExperimentNameByExperimentKey(
    experimentKey
  );
  const {
    data: selectedVersionAssets = [],
    isLoading: isLoadingAssets
  } = useAssetsForExperiment(experimentKey, {
    filterByDir: `models/${assetModelName}`
  });

  const isModelApprovalProcessEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_APPROVAL_PROCESS
    });
  });
  const isModelRegistryTagsFromStagesEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
    });
  });

  const { getServerPermissionStatus } = useUserPermission();
  const ifCurrentUserWsOwner = getServerPermissionStatus({
    workspaceName: workspace,
    permissionKey: MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
  });

  const isViewOnlyUser = useIsViewOnlyUser();

  const viewTabsOrder = isViewOnlyUser
    ? DETAIL_VIEW_TABS_ORDER_VIEW_ONLY
    : DETAIL_VIEW_TABS_ORDER;

  const [activeTabIndex, setActiveTabIndex] = useActiveTabFromURL({
    tabNameInURL: 'tab',
    tabsOrder: viewTabsOrder
  });

  const isAdminOrWsOwner = isAdmin || ifCurrentUserWsOwner;
  const canChangeStatus = isUserAMemberOfWorkspace;

  const getHeaderTabs = useCallback(() => {
    const tabs = isViewOnlyUser ? HEADER_TABS_VIEW_ONLY : HEADER_TABS;
    return tabs
      .map((tab, index) => {
        if (!isUserLoggedIn && tab.id === DETAIL_VIEW_TABS.HISTORY) {
          return null;
        }

        return {
          ...tab,
          label: (
            <Box>
              <span className="model-registry-tabs-label">{tab.label} </span>
              {index === 0 && selectedVersionAssets?.length ? (
                <span className="model-registry-tabs-grey">
                  ({selectedVersionAssets?.length})
                </span>
              ) : null}
            </Box>
          ),
          key: tab.id
        };
      })
      .filter(Boolean);
  }, [
    selectedVersion,
    selectedVersionAssets,
    model,
    autoGenerated,
    isUserLoggedIn,
    isViewOnlyUser
  ]);

  const sortedModelVersions = useMemo(() => {
    return (model.versions || []).sort(
      ({ version }, { version: versionNext }) => {
        if (version < versionNext) return 1;
        if (version > versionNext) return -1;
        return 0;
      }
    );
  }, [model.versions]);

  const downloadUrl = getDownloadModelRegistryItemURL({
    ...selectedVersion,
    modelName: model?.modelName,
    version: selectedVersion?.version
  });

  const changeButton = (
    <TextButton
      size="small"
      disabled={!canChangeStatus}
      onClick={() => setStatusModalOpen(true)}
    >
      Change
    </TextButton>
  );

  const handleStatusUpdate = useHandleStatusUpdate({
    currentVersion: selectedModelVersion,
    registryModelItemId: selectedVersion?.registryModelItemId,
    registryModelId: model?.registryModelId,
    workspace,
    modelName,
    ifCurrentUserWsOwner,
    deps: [selectedVersion, model?.registryModelId],
    invalidateQueriesKeys: {
      pendingRequests: [
        'modelRegistry',
        { workspace, modelName },
        'pending-requests'
      ]
    }
  });

  if (isLoadingModel) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching model"
      />
    );
  }

  return (
    <Box>
      <DeleteModelVersionModal
        isLastModelVersion={isLastModelVersion}
        versions={sortedModelVersions}
        handleRowMenuClose={() => null}
        handleRedirectOnDelete
        isOpen={isDeleteVersionModalOpen}
        onClose={() => setIsDeleteVersionModalOpen(false)}
        modelVersionData={selectedVersion}
      />
      <MoveModelVersionModal
        model={model}
        modelVersionData={selectedVersion}
        workspace={workspace}
        isLastModelVersion={isLastModelVersion}
        isOpen={isMoveModalOpen}
        onClose={() => setIsMoveModalOpen(false)}
        onSuccess={({ targetModelName, version, targetWorkspaceName }) => {
          history.push(
            `/${targetWorkspaceName}/${SUB_PATHS.MODEL_REGISTRY}/${targetModelName}/${version}`
          );
        }}
      />
      <ModelVersionRequestStatusModal
        selectedVersion={selectedVersion}
        workspace={workspace}
        modelName={modelName}
        open={statusModalOpen}
        onClose={() => setStatusModalOpen(false)}
        status={selectedVersionStatus}
        requestedStatus={selectedVersionRequestedStatus}
        onUpdate={handleStatusUpdate.handler}
        onUpdateMutation={handleStatusUpdate.mutation}
        ifCurrentUserWsOwner={ifCurrentUserWsOwner}
        openOrigin={OPEN_MODAL_ORIGIN.model_version}
      />
      <ModelRegistryHeader
        isModelVersionDetailsPage
        model={{ ...model, ...selectedVersion }}
        modelName={
          <ModelRegistrySelectVersions
            versions={sortedModelVersions}
            selectedVersion={selectedVersion}
            workspaceName={workspace}
            modelName={model.modelName}
          />
        }
        tabs={
          <Box className="model-registry-tabs" data-test="model-registry-tabs">
            <SectionTabs
              tabs={getHeaderTabs()}
              activeTabIndex={activeTabIndex}
              onTabChange={setActiveTabIndex}
              primaryColorSelectedTab={false}
              selectedTabColor="#191A1C"
            />
          </Box>
        }
        nameLabel={isLatest ? 'Latest' : null}
        actions={[
          {
            key: 'export',
            icon: <DSDownloadIcon />,
            href: downloadUrl,
            disabled: autoGenerated || isRemoteModel,
            tooltip:
              isRemoteModel && 'You cannot directly download remote models'
          },
          {
            key: 'move',
            icon: <DSMoveIcon />,
            onClick: () => setIsMoveModalOpen(true),
            disabled: !isUserAMemberOfWorkspace
          },
          {
            key: 'delete',
            icon: <DSDeleteIcon />,
            onClick: () => setIsDeleteVersionModalOpen(true),
            disabled: !isUserAMemberOfWorkspace || !isAdminOrWsOwner,
            tooltip:
              !isAdminOrWsOwner &&
              'Only organization admins or workspace owners can delete models'
          }
        ]}
      >
        {/* SMELLY: Stages to tags migration on FE */}
        {!isModelRegistryTagsFromStagesEnabled && stageList?.length ? (
          <>
            <Box className="brief-label">Stage</Box>
            <Box className="brief">
              <Stages stages={stageList} />
            </Box>
          </>
        ) : null}
        {isModelApprovalProcessEnabled && (
          <>
            <Box className="brief-label">Status</Box>
            <Box className="brief">
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item xs="auto">
                  <RequestStatus
                    dataTest={`model-registry-request-status-${selectedModelVersion}`}
                    status={selectedVersionStatus}
                    formatTextUtil={formatModelVersionStatusToText}
                  />
                </Grid>
                <Grid item xs="auto">
                  {!canChangeStatus ? (
                    <StyledTooltip
                      title={
                        'Only organization admins or members of this workspace can request to change the status'
                      }
                      placement="top"
                      arrow
                    >
                      <div>{changeButton}</div>
                    </StyledTooltip>
                  ) : (
                    changeButton
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {/* SMELLY: Stages to tags migration on FE */}
        {isModelRegistryTagsFromStagesEnabled && stageList?.length ? (
          <>
            <Box className="brief-label">Tags</Box>
            <Box className="brief">
              <Tags
                list={formatStagesListToTags(stageList)}
                withPopup
                maxWidth="110px"
              />
            </Box>
          </>
        ) : null}
        {experimentKey && (
          <>
            <Box className="brief-label">Source Experiment</Box>
            <Box className="brief">
              <SourceExperiment
                data={selectedVersion}
                text={
                  experimentName ||
                  experimentKey.slice(0, MAX_LENGTH_EXPERIMENT_KEY)
                }
              />
            </Box>
          </>
        )}
      </ModelRegistryHeader>

      {viewTabsOrder[activeTabIndex] === 'assets' && (
        <DetailsAssetsTab
          isLoadingAssets={isLoadingAssets}
          assets={selectedVersionAssets}
          selectedVersion={selectedVersion}
        />
      )}
      {viewTabsOrder[activeTabIndex] === 'lineage' && (
        <ModelVersionLineageTab
          selectedVersion={selectedVersion}
          workspace={workspace}
        />
      )}
      {viewTabsOrder[activeTabIndex] === 'notes' && (
        <ModelVersionNotesTab
          selectedVersion={selectedVersion}
          modelName={modelName}
          workspace={workspace}
        />
      )}
      {viewTabsOrder[activeTabIndex] === 'api' && (
        <ModelVersionApiTab
          selectedVersion={selectedVersion}
          modelName={modelName}
          workspace={workspace}
          isEmpty={selectedVersionAssets?.length === 0}
        />
      )}
      {viewTabsOrder[activeTabIndex] === 'history' && (
        <ModelVersionHistoryTab selectedVersion={selectedVersion} />
      )}
    </Box>
  );
};

export default ModelRegistryDetails;
