import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { FREE_PLANS, PAYMENT_PLANS } from '@shared/constants';

import useCurrentOrganization from './useCurrentOrganization';

import billingApi from '@/util/billingApi';

const useUpdatePlanMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const currentOrganization = useCurrentOrganization();
  const activePaymentPlan = currentOrganization?.paymentPlan;

  return useMutation(
    async ({ plan }) => billingApi.updatePlan(currentOrganization?.id, plan),
    {
      onSuccess: async (resp, { plan }) => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_PAYMENT_CHANGED,
            `You have successfully upgraded your plan from ${PAYMENT_PLANS[activePaymentPlan]?.displayName} to ${PAYMENT_PLANS[plan]?.displayName} `
          )
        );

        queryClient.invalidateQueries([
          'organizations',
          currentOrganization?.id,
          'billing'
        ]);

        queryClient.invalidateQueries(['organizations', 'list']);

        if (!FREE_PLANS.includes(plan)) {
          billingApi.sendPlanNotify();
        }
      },
      onError: e => {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_PAYMENT_CHANGED,
            e?.response?.data?.msg || e?.message
          )
        );
      }
    }
  );
};

export default useUpdatePlanMutation;
