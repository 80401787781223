import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';

import projectsActions from '../../../../actions/projectsActions';
import ManagePage from './ManagePage';
import useNoScroll from '../../../../helpers/custom-hooks/useNoScroll';

const ManagePageWrapper = () => {
  const dispatch = useDispatch();

  useNoScroll();

  useEffect(() => {
    dispatch(
      projectsActions.setActiveEMHeaderTab(EXPERIMENT_TAB_PATHNAME.manage)
    );

    return () => {
      dispatch(
        projectsActions.setActiveEMHeaderTab(
          EXPERIMENT_TAB_PATHNAME.experiments
        )
      );
    };
  }, [dispatch]);

  const renderBody = () => {
    return (
      <div className="full-page-modal-body">
        <ManagePage />
      </div>
    );
  };

  return (
    <div className="full-page-modal-container">
      <div className="long-container">{renderBody()}</div>
    </div>
  );
};

export default ManagePageWrapper;
