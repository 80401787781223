import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';

import { getModelVersionValidation } from '@/reducers/modelRegistryReducer';
import {
  INPUT_VALIDATION_FREQUENCY,
  getModelVersionValidationTooltipText
} from '@/constants/modelRegistryConstants';
import modelRegistryActions from '@/actions/modelRegistryActions';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';

const ModelVersionField = ({
  dispatch,
  label,
  modelVersionValidation,
  placeholder,
  registryModelId,
  value,
  versionChangeHandler
}) => {
  const { validVersion, versionExists } = modelVersionValidation;

  const handleValidateModelVersion = modelVersion => {
    if (registryModelId) {
      dispatch(
        modelRegistryActions.validateModelVersion(modelVersion, registryModelId)
      );
    } else {
      dispatch(modelRegistryActions.validateModelVersion(modelVersion));
    }
  };

  const debouncedValidateModelVersion = useCallback(
    debounce(handleValidateModelVersion, INPUT_VALIDATION_FREQUENCY),
    [registryModelId]
  );

  const handleChangeModelVersion = e => {
    const newVersion = e.target.value;

    versionChangeHandler(newVersion);

    if (!isEmpty(newVersion)) {
      debouncedValidateModelVersion(newVersion, registryModelId);
    }
  };

  const renderHelpLink = () => {
    return (
      <Tooltip
        title="Click to visit semver.org to learn how to use semantic versioning"
        placement="right"
      >
        <a href="https://semver.org/" target="_blank" rel="noopener noreferrer">
          <i className="material-icons help-icon">help_outline</i>
        </a>
      </Tooltip>
    );
  };

  const validationTooltipText = getModelVersionValidationTooltipText(
    modelVersionValidation
  );

  const isInvalidVersion = !validVersion || versionExists;

  const isOpen = !isEmpty(value) && isInvalidVersion;

  return (
    <div className="generic-modal-section">
      <InputLabel className="modal-input-label">
        <span className="label-group">
          {label} {renderHelpLink()}
        </span>
      </InputLabel>
      <StyledValidationTooltip title={validationTooltipText} open={isOpen}>
        <InputBase
          className="modal-input-base"
          onChange={handleChangeModelVersion}
          value={value}
          placeholder={placeholder}
        />
      </StyledValidationTooltip>
    </div>
  );
};

ModelVersionField.defaultProps = {
  registryModelId: null,
  placeholder: ''
};

ModelVersionField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  modelVersionValidation: PropTypes.object.isRequired,
  registryModelId: PropTypes.string,
  value: PropTypes.string.isRequired,
  versionChangeHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return { modelVersionValidation: getModelVersionValidation(state) };
};

export default connect(mapStateToProps)(ModelVersionField);
