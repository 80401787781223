import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BADGE_COLORS } from '@experiment-management-shared/constants/chartConstants';

export default function MetaBadge({ text, customClass, colorClass }) {
  if (!text) return null;

  const className = classNames(
    customClass,
    'meta-badge',
    BADGE_COLORS[colorClass]
  );

  return <span className={className}>{text}</span>;
}

MetaBadge.defaultProps = {
  text: '',
  colorClass: BADGE_COLORS.blue,
  customClass: ''
};

MetaBadge.propTypes = {
  text: PropTypes.string,
  colorClass: PropTypes.string,
  customClass: PropTypes.string
};
