import useCurrentUser from '@API/auth/useCurrentUser';
import { useQuery } from 'react-query';
import organizationApi from '@shared/api/organizationApi';
import { useCurrentOrganization } from '@shared/hooks';

const getUserOrganizationPermissions = async ({
  organizationId,
  username,
  signal
}) => {
  const response = await organizationApi.fetchUserOrganizationPermissions({
    organizationId,
    username,
    signal
  });

  return response?.data?.userPermissions;
};

const useUserOrganizationPermissions = (params, config) => {
  const currentOrganization = useCurrentOrganization();
  const { data: currentUser } = useCurrentUser();

  const username = currentUser?.username;
  const organizationId = currentOrganization?.id;

  return useQuery(
    ['organization-permissions', { organizationId, username }],
    ({ signal }) =>
      getUserOrganizationPermissions({ signal, organizationId, username }),
    {
      ...config,
      enabled:
        (config?.enabled ? config?.enabled : true) &&
        !!organizationId &&
        !!username
    }
  );
};

export default useUserOrganizationPermissions;
