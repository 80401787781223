import React from 'react';

import { Tooltip } from '@ds';

import styles from './AssetNameCell.module.scss';

const AssetNameCell = ({ assetName }: { assetName: string }) => {
  return (
    <div className={styles.container}>
      <Tooltip content={assetName} placement="top">
        <span className={styles.text}>{assetName}</span>
      </Tooltip>
    </div>
  );
};

export default AssetNameCell;
