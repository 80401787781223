import { axiosInstance } from '@API/api';
import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const workspaceApi = {
  fetchAllWorkspacesForUser() {
    const url = `${BASE_API_URL}workspaces`;
    return axios.get(url, {
      params: {
        withoutExtendedData: true
      }
    });
  },

  fetchAllWorkspacesForUserByOrganizationId(organizationId) {
    const url = `${BASE_API_URL}workspaces/filterByOrganizationId/${organizationId}`;
    return axios.get(url);
  },

  validateTeamName(teamName) {
    const data = { teamName };
    const url = `${BASE_API_URL}workspaces/validate`;
    return axios.post(url, JSON.stringify(data));
  },

  removeTeamMember(data) {
    const url = `${BASE_API_URL}workspaces/removeFromTeam`;
    return axios.post(url, JSON.stringify(data));
  },

  fetchOrganizationWorkspaces(organizationId) {
    const url = `${BASE_API_URL}workspaces`;
    return axios.get(url, {
      params: {
        organizationId,
        withoutExtendedData: true
      }
    });
  },

  fetchWorkspaceMembers(workspaceId, cancelToken) {
    const url = `workspaces/${workspaceId}/getAllMembers`;
    return axiosInstance.get(url, { cancelToken });
  },

  createWorkspace({ organizationId, name }) {
    const url = `${BASE_API_URL}workspaces`;
    return axios.post(url, {
      organizationId,
      name
    });
  },

  deleteWorkspace(workspaceId) {
    const url = `${BASE_API_URL}workspaces/${workspaceId}`;
    return axios.delete(url);
  },

  fetchInvitesForWorkspace(workspaceId, cancelToken) {
    const url = `workspaces/list-email-invites?teamId=${workspaceId}`;
    return axiosInstance.get(url, { cancelToken });
  },

  setUsersDefaultWorkspace(workspaceId) {
    const data = {
      teamId: workspaceId
    };
    const url = `${BASE_API_URL}workspaces/default/set`;
    return axios.post(url, data);
  },

  inviteUserByEmailToWorkspace(workspaceId, shareWithEmail) {
    const data = {
      teamId: workspaceId,
      shareWithEmail
    };
    const url = `${BASE_API_URL}workspaces/invite`;
    return axios.post(url, data);
  },

  addWorkspaceMemberBulk({ users }) {
    const url = `${BASE_API_URL}workspaces/addToWorkspaceBulk`;
    return axios.post(url, { users });
  },

  claimInviteWorkspaceCode(workspaceCode) {
    const url = `${BASE_API_URL}workspaces/claim?workspaceCode=${workspaceCode}`;
    return axios.get(url);
  },

  claimAnonymousData(workspaceName, isNewUser) {
    const data = {
      workspaceName,
      isNewUser
    };
    const url = `${BASE_API_URL}workspaces/claimData`;
    return axios.post(url, data);
  }
};

export default workspaceApi;
