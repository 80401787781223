import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const VISUALIZATIONS_API_PATH = `${BASE_API_URL}code-visualizations/`;

const visualizationsApi = {
  createTemplate(template) {
    const url = `${VISUALIZATIONS_API_PATH}template/create`;
    return axios.post(url, JSON.stringify(template));
  },

  fetchTemplate(templateId, revisionId, projectId) {
    const url = `${VISUALIZATIONS_API_PATH}template/get`;
    return axios.get(url, {
      params: {
        revisionId,
        templateId,
        projectId
      }
    });
  },

  fetchGalleryTemplatesByScope(teamId, scope) {
    const scopeParam = `&scope=${scope}`;
    const url = `${VISUALIZATIONS_API_PATH}template/get-all?teamId=${teamId}${scopeParam}`;
    return axios.get(url);
  },

  deleteTemplate(templateId) {
    const data = { templateId };
    const url = `${VISUALIZATIONS_API_PATH}template/delete`;
    return axios.post(url, JSON.stringify(data));
  },

  updateTemplate(template) {
    const url = `${VISUALIZATIONS_API_PATH}template/update`;
    return axios.post(url, JSON.stringify(template));
  },

  createInstance(instance) {
    const url = `${VISUALIZATIONS_API_PATH}instance/create`;
    return axios.post(url, JSON.stringify(instance));
  },

  uploadThumbnail(templateId, file) {
    const url = `${BASE_API_URL}upload/visualization-thumbnail?templateId=${templateId}`;
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  fetchDefaultInternalResources() {
    const url = `${VISUALIZATIONS_API_PATH}template/code/resources/defaults/get`;
    return axios.get(url);
  },

  fetchTemplateRevisionIds(templateId) {
    const url = `${VISUALIZATIONS_API_PATH}template/${templateId}/revisions/`;
    return axios.get(url);
  },

  createProjectToken(projectId) {
    const url = `${BASE_API_URL}token/projects`;
    return axios.post(url, { projectId });
  },

  validateProjectToken(token) {
    const url = `${BASE_API_URL}token/validate`;
    return axios.get(url, { params: { token } });
  },

  importTemplate(teamId, templateFile) {
    const url = `${VISUALIZATIONS_API_PATH}template/upload?teamId=${teamId}`;

    return axios({
      method: 'POST',
      url,
      data: templateFile,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },

  fetchTemplateCounts(teamId) {
    const url = `${VISUALIZATIONS_API_PATH}template/get-counts?teamId=${teamId}`;
    return axios.get(url);
  },

  fetchInstancesPreview(templateId, limit) {
    const url = `${VISUALIZATIONS_API_PATH}instances-preview-list`;

    return axios.get(url, { params: { templateId, limit } });
  },

  fetchPanelSuggestionsForExperiment(experimentKey) {
    const url = `${VISUALIZATIONS_API_PATH}experiment-view-suggestions?experimentKey=${experimentKey}`;
    return axios.get(url);
  }
};

export const getExportTemplateURL = templateId => {
  return `${VISUALIZATIONS_API_PATH}template/${templateId}/download`;
};

export default visualizationsApi;
