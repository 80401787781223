import { useQuery } from 'react-query';
import flatten from 'lodash/flatten';
import isUndefined from 'lodash/isUndefined';
import uniqBy from 'lodash/uniqBy';
import { useExperimentKeys } from '@experiment-management-shared/hooks';
import metricsApi from './API/metricsApi';

const useExperimentMetrics = (config = {}) => {
  const experiments = useExperimentKeys();
  const enabled = isUndefined(config?.enabled)
    ? !!experiments?.length
    : config?.enabled && !!experiments?.length;

  return useQuery(
    ['experiment-metrics', { experiments }],
    async () => {
      const respPromises = experiments.map(experiment =>
        metricsApi.fetchMetrics({ experimentKey: experiment })
      );

      const responses = await Promise.all(respPromises);

      return uniqBy(
        flatten(responses.map(response => response?.data?.results)),
        metric => metric.name
      );
    },
    {
      ...config,
      enabled
    }
  );
};

export default useExperimentMetrics;
