import React from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@ds';

import styles from './OrButton.module.scss';
import { DSMinusIcon, DSPlusIcon } from '@ds-icons';

export const OR_BUTTON_MODE = {
  ADD: 'add',
  REMOVE: 'remove'
};

const OrButton = ({ onClick, mode, ruleIndex }) => {
  return (
    <div>
      {mode === OR_BUTTON_MODE.REMOVE && (
        <div className={styles.removeButtonContainer}>
          <div className={styles.label}>OR</div>
          <TextButton
            size="small"
            data-test={`or-delete-button-${ruleIndex}`}
            onClick={onClick}
            PrefixIcon={<DSMinusIcon />}
            withoutPadding
          >
            OR
          </TextButton>
        </div>
      )}
      {mode === OR_BUTTON_MODE.ADD && (
        <TextButton
          size="small"
          data-test="or-add-button"
          onClick={onClick}
          PrefixIcon={<DSPlusIcon />}
        >
          OR
        </TextButton>
      )}
    </div>
  );
};

OrButton.defaultProps = {
  mode: OR_BUTTON_MODE.ADD,
  ruleIndex: 0
};

OrButton.propTypes = {
  mode: PropTypes.oneOf([OR_BUTTON_MODE.ADD, OR_BUTTON_MODE.REMOVE]),
  ruleIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired
};

export default OrButton;
