import { useEffect, useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getIsExperimentPage } from '@shared/utils/url';
import { NEW_REPORT_NAME } from '@shared/constants';
import useCompareExperimentsKeys from '@shared/hooks/useCompareExperimentsKeys';
import { BreadcrumbItemProps, BreadcrumbItemType } from '@shared/types';
import { getIsArchivePage } from '@/selectors/routeSelectors';
import useBreadcrumbsDataSource from '@shared/hooks/useBreadcrumbsDataSource';
import { useExperimentName } from '@experiment-management-shared/hooks';

const sanitizePathname = (pathname: string, viewId?: string) => {
  if (viewId) {
    return pathname.replace(`/view/${viewId}`, '');
  }

  return pathname;
};

function useProjectPageBreadcrumbs() {
  const breadcrumbsDataSource = useBreadcrumbsDataSource();
  const { pathname } = useLocation();
  const {
    projectName,
    viewId,
    workspace,
    reportName,
    experimentKey
  } = useParams<Record<string, string | undefined>>();

  const { data: experimentNames } = useExperimentName({
    experimentKeys: [experimentKey || '']
  });

  const experimentName = experimentNames?.[0] || '';

  // taking into account that path sometimes can contain view id, we need extract this part to simplify the below calculation
  const sanitizedPathname = sanitizePathname(pathname, viewId);

  const isArchivePage = useSelector(getIsArchivePage);

  const isReportPage = !!matchPath(pathname, {
    path: '/:workspace/:projectName/reports/:reportName/:editMode?',
    exact: true,
    strict: false
  });

  const isReportTemplatePage = !!matchPath(pathname, {
    path: '/:workspace/:projectName/reports/template/:reportName/:editMode?',
    exact: true,
    strict: false
  });

  const isExperimentPage = useSelector(getIsExperimentPage);
  const { experimentKeys } = useCompareExperimentsKeys();

  const baseItems: BreadcrumbItemProps[] = useMemo(() => {
    return [
      {
        id: 'home',
        type: 'link',
        title: workspace ?? '',
        path: `/${workspace}`,
        dataMaskTest: 'workspace'
      },
      {
        id: 'project-name',
        type: 'link',
        path:
          `/${workspace}/${projectName}` + (viewId ? `/view/${viewId}` : ''),
        title: projectName ?? ''
      }
    ];
  }, [projectName, viewId, workspace]);

  const items = useMemo(() => {
    const splitPathname = sanitizedPathname.split('/').slice(1);

    if (isExperimentPage) {
      const retVal = [...baseItems];

      if (experimentKey) {
        retVal.push({
          id: experimentKey,
          title: experimentName,
          type: 'experiment',
          dataMaskTest: 'experiment-name'
        } as BreadcrumbItemProps);
      }

      if (experimentKeys.length) {
        retVal.push({
          id: 'compare',
          type: 'compare',
          path: `/${workspace}/${projectName}${
            isArchivePage ? '/archive' : ''
          }`,
          title: `Compare (${experimentKeys?.length || ''})`,
          dataMaskTest: 'experiment-name',
          ids: experimentKeys
        } as BreadcrumbItemProps);
      }

      return retVal;
    }

    if (isReportPage || isReportTemplatePage) {
      const retVal = [
        ...baseItems,
        {
          id: 'tab-level-2',
          type: 'link',
          path: `/${workspace}/${projectName}/reports`,
          title: 'Reports'
        } as BreadcrumbItemProps
      ];

      const additionalPath = splitPathname.slice(3);

      additionalPath.forEach((path, index) => {
        let type: BreadcrumbItemType = 'link';

        if (
          ((isReportPage && reportName === NEW_REPORT_NAME) ||
            isReportTemplatePage) &&
          index === 0
        ) {
          type = 'text';
        }

        retVal.push({
          id: path + index,
          type,
          path: '/' + splitPathname.slice(0, 4 + index).join('/'),
          title: path
        });
      });

      return retVal;
    }

    return baseItems;
  }, [
    baseItems,
    experimentKey,
    experimentKeys,
    experimentName,
    isArchivePage,
    isExperimentPage,
    isReportPage,
    isReportTemplatePage,
    projectName,
    reportName,
    sanitizedPathname,
    workspace
  ]);

  useEffect(() => {
    breadcrumbsDataSource.setItems(items);
    return () => {
      breadcrumbsDataSource.resetItems();
    };
  }, [breadcrumbsDataSource.setItems, breadcrumbsDataSource.resetItems, items]);
}

export default useProjectPageBreadcrumbs;
