import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { useIsServerCustomPanelsEnabled } from '@experiment-management-shared';

const PythonBetaWarning = () => {
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();

  return (
    <Alert
      severity={isServerCustomPanelsEnabled ? 'info' : 'warning'}
      className="custom-alert warning"
    >
      {isServerCustomPanelsEnabled
        ? 'Powered by Comet Compute'
        : 'This is a BETA version of Python panels'}
    </Alert>
  );
};

export default PythonBetaWarning;
