import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import { MAX_LENGTH_EXPERIMENT_KEY } from '@experiment-management-shared/constants/compareTableConstants';

import useArtifactsPageBreadcrumbs from '@artifacts/hooks/useArtifactsPageBreadcrumbs';

import ArtifactDetails from '@experiment-management-shared/components/ArtifactDetails';
import ArtifactTags from '@experiment-management-shared/components/ArtifactTags';
import { formatBytes, formatTimestampForTable } from '@/helpers/generalHelpers';
import { createArtifactVersionKey } from '@artifacts/constants/constants';
import EditArtifactVersionModalBtn from '@artifacts/components/EditArtifactVersionModalBtn';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';
import ArtifactAliases from '@experiment-management-shared/components/ArtifactAliases';
import artifactActions from '@/actions/artifactActions';
import DownloadArtifactVersionBtn from '@artifacts/components/DownloadArtifactVersionBtn';
import { renderVersionState } from '@artifacts/utils/helpers';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import { IconButton, Tooltip } from '@ds';
import { DSDeleteIcon } from '@ds-icons';

const ArtifactVersionPageHeader = ({
  tabs,
  activeTab,
  artifactVersion,
  artifactVersionNumber,
  isUserAMemberOfWorkspace,
  tabChangeHandler
}) => {
  const {
    added,
    alias,
    experimentKey,
    sizeInBytes,
    tags,
    owner,
    artifactVersionId,
    state,
    artifact
  } = artifactVersion;

  const dispatch = useDispatch();
  const { workspace, artifactName } = useParams();
  useArtifactsPageBreadcrumbs();

  const handleOpenDeleteArtifactVersionModal = e => {
    if (e) {
      e.preventDefault();
    }

    const versionMapId = createArtifactVersionKey(
      workspace,
      artifactName,
      artifactVersionNumber
    );

    const redirectURL = `/${workspace}/artifacts/${artifactName}`;

    dispatch(
      artifactActions.openDeleteArtifactVersionModal(
        artifactVersionId,
        versionMapId,
        redirectURL
      )
    );
  };

  const renderTabs = () => {
    return tabs.map(tab => {
      const label = !tab.suffix ? (
        tab.name
      ) : (
        <div>
          {tab.name} <span style={{ color: '#8d95a9' }}>{tab.suffix}</span>
        </div>
      );

      return <StyledTab key={tab.id} value={tab.id} label={label} />;
    });
  };

  const renderTabBar = () => {
    return (
      <StyledTabs
        className="charts-gallery-tab-bar"
        value={activeTab}
        onChange={tabChangeHandler}
      >
        {renderTabs()}
      </StyledTabs>
    );
  };

  const name = (
    <div className="flex justifyCenter alignCenter">
      <span className="artifact-name-version">
        Version {artifactVersionNumber}{' '}
      </span>
      <ArtifactAliases aliases={alias} />
    </div>
  );

  return (
    <div className="artifact-header">
      <ArtifactDetails
        name={name}
        metadata={[
          { label: 'Added on', value: formatTimestampForTable(added) },
          {
            label: 'Source experiment',
            value: experimentKey.slice(0, MAX_LENGTH_EXPERIMENT_KEY),
            resource: REDIRECT_LINK_RESOURCES.EXPERIMENT,
            redirectUrlArgs: [
              experimentKey,
              {
                'experiment-tab': 'artifacts'
              }
            ]
          },
          { label: 'Owner', value: owner },
          { label: 'Status', value: renderVersionState(state) },
          {
            label: 'Size',
            value: isNull(sizeInBytes) ? 'N/A' : formatBytes(sizeInBytes)
          }
        ]}
      >
        <div className="artifact-header-buttons">
          <div className="artifact-action-container">
            <EditArtifactVersionModalBtn
              artifactVersion={artifactVersion}
              disabled={!isUserAMemberOfWorkspace}
            />
          </div>

          <div className="artifact-action-container">
            <DownloadArtifactVersionBtn
              artifactVersionId={artifactVersionId}
              disabled={!isUserAMemberOfWorkspace && !artifact.isPublic}
            />
          </div>
          <div className="artifact-action-container">
            <Tooltip content="Delete version">
              <IconButton
                Icon={<DSDeleteIcon />}
                type="secondary"
                onClick={handleOpenDeleteArtifactVersionModal}
                disabled={!isUserAMemberOfWorkspace}
              />
            </Tooltip>
          </div>
        </div>
      </ArtifactDetails>
      {!isEmpty(tags) && (
        <div className="artifact-header-tags">
          <ArtifactTags tagNames={tags} />
        </div>
      )}
      {renderTabBar()}
    </div>
  );
};

ArtifactVersionPageHeader.propTypes = {
  artifactVersion: PropTypes.object.isRequired,
  artifactVersionNumber: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      suffix: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  isUserAMemberOfWorkspace: PropTypes.bool.isRequired,
  tabChangeHandler: PropTypes.func.isRequired
};

export default ArtifactVersionPageHeader;
