// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FullWidthSidebar-module__dsFullWidthSidebar--UmTgE{--sidebar-width: 380px;--actions-panel-height: 60px;display:flex;justify-content:flex-end}.FullWidthSidebar-module__dsFullWidthSidebar--UmTgE .MuiDialog-paperFullScreen{overflow:hidden}.FullWidthSidebar-module__dsFullWidthSidebar--UmTgE .MuiDialog-container{width:var(--sidebar-width)}.FullWidthSidebar-module__dsFullWidthSidebarHeader--GuVwK{align-items:center;position:sticky;top:0;z-index:30;background:#fff;border-bottom:1px solid var(--gray);padding:20px}.FullWidthSidebar-module__dsFullWidthSidebarContent--iT_r6{padding:20px;overflow-y:auto;overflow-x:hidden;height:calc(100% - 100px);position:relative}.FullWidthSidebar-module__dsFullWidthSidebarTitle--MaXV5{color:var(--black);font-size:16px;font-weight:700;margin-bottom:5px;line-height:21px}.FullWidthSidebar-module__dsFullWidthSidebarTitleText--qg609{font-size:14px;font-weight:400}.FullWidthSidebar-module__dsFullWidthSidebarActions--pv_fD{position:sticky;bottom:0;z-index:30;right:0;width:var(--sidebar-width);height:var(--actions-panel-height);border-top:1px solid var(--gray-3);padding:14px 20px;display:flex;flex-direction:row;align-items:center;justify-content:space-between;background:var(--white)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsFullWidthSidebar": `FullWidthSidebar-module__dsFullWidthSidebar--UmTgE`,
	"dsFullWidthSidebarHeader": `FullWidthSidebar-module__dsFullWidthSidebarHeader--GuVwK`,
	"dsFullWidthSidebarContent": `FullWidthSidebar-module__dsFullWidthSidebarContent--iT_r6`,
	"dsFullWidthSidebarTitle": `FullWidthSidebar-module__dsFullWidthSidebarTitle--MaXV5`,
	"dsFullWidthSidebarTitleText": `FullWidthSidebar-module__dsFullWidthSidebarTitleText--qg609`,
	"dsFullWidthSidebarActions": `FullWidthSidebar-module__dsFullWidthSidebarActions--pv_fD`
};
module.exports = ___CSS_LOADER_EXPORT___;
