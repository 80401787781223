import React from 'react';
import PropTypes from 'prop-types';
import classNames from './GraphicsVirtualizedGrid.module.scss';
import GraphicsTile from '../GraphicsTile';
import { GRAPHICS_ASSETS_FIELDS_MAP } from '@experiment-details/constants/graphics';

export default function GridRowRenderer({
  assets,
  style,
  rowIndex,
  itemsPerRow,
  onClick,
  showSelection,
  handleSelectItem,
  selectedItems
}) {
  const items = [];
  const fromIndex = rowIndex * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, assets.length);

  for (let i = fromIndex; i < toIndex; i++) {
    const asset = assets[i];

    if (!asset) {
      items.push(
        <div key={`empty-image-${i}`} className={classNames.hiddenFlexItem} />
      );
    } else {
      items.push(
        <GraphicsTile
          selection={{
            show: showSelection,
            disabled: asset[GRAPHICS_ASSETS_FIELDS_MAP.type] === 'video'
          }}
          asset={asset}
          key={asset[GRAPHICS_ASSETS_FIELDS_MAP.id]}
          onOpenAsset={onClick}
          handleSelectItem={handleSelectItem}
          selectedItems={selectedItems}
        />
      );
    }
  }

  const placeholdersCount = itemsPerRow - items.length;

  // add placeholder divs to avoid flex grow on last row.
  if (placeholdersCount !== 0) {
    for (let i = 0; i < placeholdersCount; i++) {
      items.push(
        <div key={`placeholder-${i}`} className={classNames.hiddenFlexItem} />
      );
    }
  }

  return (
    <div className={classNames.row} style={style}>
      {items}
    </div>
  );
}

GridRowRenderer.defaultProps = {
  assets: [],
  selectedItems: [],
  showSelection: false
};

GridRowRenderer.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  assets: PropTypes.array,
  showSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  style: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  itemsPerRow: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};
