import { Node } from 'react-flow-renderer';

export const getLineageNodeName = (node: Node) => {
  const [modelName] = node.data.label.split(' / ');
  return modelName;
};

export const updateHiddenLineageItems = <T extends Pick<Node, 'id' | 'hidden'>>(
  isToggleHidden: boolean,
  itemHiddenSelect: (item: T) => boolean
) => (list: T[]) =>
  list.map(item => {
    item.hidden = isToggleHidden && itemHiddenSelect(item);
    return item;
  });
