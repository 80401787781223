import { experimentDetailsUiActionTypes } from '../../constants/actionTypes';

const uiActions = {
  setIsHeaderTableShownStatus(status) {
    return dispatch => {
      dispatch({
        type: experimentDetailsUiActionTypes.SET_IS_HEADER_TABLE_SHOWN,
        payload: {
          status
        }
      });
    };
  },

  setIsNeededToResetLocalChanges(status) {
    return dispatch => {
      dispatch({
        type:
          experimentDetailsUiActionTypes.SET_IS_NEEDED_TO_RESET_LOCAL_CHANGES,
        payload: {
          status
        }
      });
    };
  }
};

export default uiActions;
