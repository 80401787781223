// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PanelConfigBar-module__rightContainer--xglca{display:flex;justify-content:end;align-items:center;gap:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightContainer": `PanelConfigBar-module__rightContainer--xglca`
};
module.exports = ___CSS_LOADER_EXPORT___;
