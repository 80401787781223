import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import noop from 'lodash/noop';

import { BETA_FEATURE_CUSTOM_LOGO } from '@/lib/betaFeatures';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';

import './TextLogo.scss';

function TextLogo({ isCustomLogoEnabled, onClick }) {
  const imageSrc = isCustomLogoEnabled
    ? '/images/custom.png'
    : `/images/logo_comet_dark.png`;

  return (
    <div className="text-logo" onClick={onClick}>
      <img src={imageSrc} alt="Comet Logo" />
    </div>
  );
}

TextLogo.defaultProps = {
  onClick: noop
};

TextLogo.propTypes = {
  isCustomLogoEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

const mapStateToProps = state => {
  return {
    isCustomLogoEnabled: isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_CUSTOM_LOGO
    })
  };
};

export default connect(mapStateToProps)(TextLogo);
