import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import defaultTo from 'lodash/defaultTo';
import noop from 'lodash/noop';

import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import SearchBar from 'material-ui-search-bar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import GenericModal from '@shared/components/GenericModal';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { isStringInSearchText } from '@/helpers/generalHelpers';

const SelectHistogramsModal = ({ dispatch, items, onSaveState }) => {
  const initialCheckboxes = items.reduce((checkboxes, item) => {
    checkboxes[item.id] = false;

    return checkboxes;
  }, {});
  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
  const [searchValue, setSearchValue] = useState('');
  const filteredItems = useMemo(() => {
    return items
      .filter(({ name }) => isStringInSearchText(name, searchValue))
      .sort();
  }, [items, searchValue]);

  const handleCheckboxChange = id => {
    setCheckboxes({
      ...checkboxes,
      [id]: !defaultTo(checkboxes[id], false)
    });
  };

  const handleClose = () => {
    const ids = Object.entries(checkboxes)
      .filter(([, show]) => {
        return show === false;
      })
      .map(([id]) => id);

    onSaveState(ids);

    dispatch(alertsUtil.closeDialog(dialogTypes.SELECT_HISTOGRAMS_MODAL));
  };

  const handleSearchChange = value => {
    setSearchValue(value);
  };

  const renderList = () => {
    const listItems = filteredItems.map(({ id, name }) => {
      return (
        <ListItem
          key={id}
          onClick={() => handleCheckboxChange(id)}
          dense
          button
        >
          <Checkbox checked={defaultTo(checkboxes[id], false)} />
          <ListItemText primary={name} />
        </ListItem>
      );
    });

    return <List component="nav">{listItems}</List>;
  };

  const renderSearchBar = () => {
    return (
      <SearchBar
        onChange={handleSearchChange}
        onRequestSearch={handleSearchChange}
        onCancelSearch={() => setSearchValue('')}
      />
    );
  };

  return (
    <GenericModal
      title="Select Histograms"
      onClose={handleClose}
      onReset={() => setCheckboxes({})}
      hasResetButton
    >
      <DialogContent className="select-metrics-modal">
        <h3 className="modal-body-message">Select the histograms</h3>
        <div className="select-metrics-body">
          <div className="select-metrics-search-bar">{renderSearchBar()}</div>
          <div className="select-metrics-list">{renderList()}</div>
        </div>
      </DialogContent>
    </GenericModal>
  );
};

SelectHistogramsModal.defaultProps = {
  items: [],
  onSaveState: noop
};

SelectHistogramsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  onSaveState: PropTypes.func
};

export default connect()(SelectHistogramsModal);
