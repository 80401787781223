import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { TextButton } from '@ds';
import RegisterModelSidebar from './RegisterModelSidebar';
import classNames from './RegisterModel.module.scss';
import useRegisterModelSidebarIsOpenedEvent from './hooks/useRegisterModelSidebarIsOpenedEvent';

const RegisterModel = ({ experimentKey }) => {
  const [open, setOpen] = useState(false);
  const sentIsOpenedEvent = useRegisterModelSidebarIsOpenedEvent();

  const onOpenSidebar = () => {
    setOpen(true);
    sentIsOpenedEvent();
  };

  return (
    <Box className={classNames.registerModelButton}>
      <TextButton
        onClick={onOpenSidebar}
        data-test="streamline-model-sidebar-open-btn"
      >
        Register Model
      </TextButton>
      <RegisterModelSidebar
        open={open}
        setOpen={setOpen}
        experimentKey={experimentKey}
      />
    </Box>
  );
};

RegisterModel.defaultProps = {};

RegisterModel.propTypes = {
  experimentKey: PropTypes.string.isRequired
};

export default RegisterModel;
