import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router';

import get from 'lodash/get';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  DETAIL_VIEWS,
  DETAIL_VIEW_SETTINGS
} from '@experiment-management-shared/constants/experimentConstants';

import history from '@/history';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';
import AssetsContainer from './AssetsContainer';
import ExperimentArtifactsTab from './ExperimentArtifactsTab';
import { Link } from 'react-router-dom';

const { ASSETS_AND_ARTIFACTS } = DETAIL_VIEWS;
const TABS = get(
  DETAIL_VIEW_SETTINGS,
  [ASSETS_AND_ARTIFACTS, 'internalTabs'],
  {}
);

const AssetsAndArtifactsTab = ({ experiments }) => {
  const [experiment] = experiments;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const experimentTab = searchParams.get('experiment-tab');
  const viewId = searchParams.get('viewId');

  const activeTab = get(TABS, [experimentTab, 'value'], TABS.assets.value);

  useEffect(() => {
    if (viewId) {
      const query = queryString.stringify({
        'experiment-tab': activeTab.toLowerCase(),
        viewId
      });
      history.push(`?${query}`, { preventScroll: true });
    }
  }, [activeTab, viewId]);

  const renderActiveTab = () => {
    const tabComponents = {
      assets: {
        Component: AssetsContainer,
        props: { experiment }
      },
      artifacts: {
        Component: ExperimentArtifactsTab,
        props: { experimentKey: experiment.experimentKey }
      }
    };

    const { Component, props } = tabComponents[activeTab];

    return <Component {...props} />;
  };

  const getSearchParams = v => {
    searchParams.set('experiment-tab', v);
    return searchParams.toString();
  };

  return (
    <div className="assets-and-artifacts-tab">
      <div className="tab-container">
        <StyledTabs value={activeTab}>
          {Object.values(TABS).map(({ value, label }) => (
            <StyledTab
              key={value}
              value={value}
              label={label}
              component={Link}
              to={location => ({
                ...location,
                search: getSearchParams(value)
              })}
            />
          ))}
        </StyledTabs>
      </div>
      {renderActiveTab()}
    </div>
  );
};

AssetsAndArtifactsTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default AssetsAndArtifactsTab;
