import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import some from 'lodash/some';
import queryString from 'query-string';
import isString from 'lodash/isString';

import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';
import {
  EPOCH,
  DURATION,
  WALL,
  STEP
} from '@experiment-management-shared/constants/experimentConstants';
import { generatePanelId } from '@experiment-management-shared/utils/panel';
import { APP_LOCATION } from '@/lib/appConstants';

export const axisTypeToDataSourceField = {
  [DURATION]: 'durations',
  [STEP]: 'steps',
  [WALL]: 'timestamps',
  [EPOCH]: 'epochs'
};

export const isNaNValue = value => {
  return !isString(value) && Number.isNaN(Number(value));
};

export const filterNaNValues = values => {
  return values.filter(v => !isNaNValue(v));
};

export const filterOutNotValidNumbers = values => {
  return values.filter(
    value => !isUndefined(value) && !isNaNValue(value) && !isNull(value)
  );
};

const isValidArray = arr => some(arr, val => Boolean(val) || isNumber(val));

const hasData = (dataSourceField, dataSource) => {
  const independentMetricValues = get(
    dataSource,
    ['metrics', 0, dataSourceField],
    []
  );

  const { step, epoch } = axisTypeToDataSourceField;

  if ([step, epoch].includes(dataSourceField)) {
    const dependentMetricValues = get(dataSource, dataSourceField, []);

    return (
      isValidArray(independentMetricValues) ||
      isValidArray(dependentMetricValues)
    );
  }

  return isValidArray(independentMetricValues);
};

const dashboardHelpers = {
  fixPanelIdsInLayout: (layout, chartIdsMapOldToNew) =>
    layout.map(panel => {
      const { i: chartIdOld } = panel;

      return {
        ...panel,
        i: chartIdsMapOldToNew[chartIdOld]
      };
    }),

  generatePanelIdsForSection: section => {
    const sectionPanels = section?.panels?.panels || [];
    const panelsMapOldToNewIds = {};
    const newPanels = sectionPanels.map(panel => {
      const chartId = generatePanelId();
      panelsMapOldToNewIds[panel.chartId] = chartId;
      return { ...panel, chartId };
    });

    // layoutV2 save changes that we do when we are editing the chart in real time
    // so, when we Duplicate the section, it make sense to copy the layoutV2 in `layout` for the duplicated section
    // so that it will have the updated coordinates
    const { layout, layoutV2 } = section?.panels || {};
    // but, if there are no changes to layout, we will take the original layout when duplicating the panel.
    const duplicatedLayout = layoutV2 || layout;
    const newLayoutForPanel =
      duplicatedLayout &&
      dashboardHelpers.fixPanelIdsInLayout(
        duplicatedLayout,
        panelsMapOldToNewIds
      );

    return {
      ...section,
      panels: {
        ...section.panels,
        layout: newLayoutForPanel,
        layoutV2: newLayoutForPanel,
        panels: newPanels
      }
    };
  },

  toChartsArray(chartsObject) {
    const {
      line = [],
      scatter = [],
      bar = [],
      parallel = [],
      custom = [],
      scalar = [],
      image = [],
      video = [],
      pcd = [],
      curves = [],
      data = [],
      python = []
    } = chartsObject;

    return [
      ...line,
      ...scatter,
      ...bar,
      ...parallel,
      ...custom,
      ...scalar,
      ...image,
      ...video,
      ...pcd,
      ...data,
      ...curves,
      ...python
    ];
  },

  checkForInvalidMetricData(metricData) {
    if (!metricData || isEmpty(metricData[0])) return true;
    return metricData.some(obj => isUndefined(obj) || isNull(obj));
  },

  getDataSortedByOrderedExperimentKeys(metricData, orderedExperimentKeys) {
    const orderedData = orderedExperimentKeys
      .filter(experimentKey => !isEmpty(metricData[experimentKey]))
      .map(experimentKey => metricData[experimentKey]);

    return isEmpty(orderedData) ? null : orderedData;
  },

  removeExperimentsWithoutData(dataSources, selectedXAxis) {
    return dataSources.filter(dataSource => {
      if (axisTypeToDataSourceField.hasOwnProperty(selectedXAxis)) {
        const dataSourceField = axisTypeToDataSourceField[selectedXAxis];
        return hasData(dataSourceField, dataSource);
      }

      return !isEmpty(dataSource.metrics) || !isEmpty(dataSource.params);
    });
  },

  isOneExperimentAddBuiltInPanel({ search, pathname }) {
    const { 'experiment-tab': experimentTab } = queryString.parse(search);
    return (
      experimentTab === EXPERIMENT_VIEW_TAB_FIELDS.PANELS &&
      !pathname.includes(APP_LOCATION.MULTIPLE_EXPERIMENTS_COMPARE)
    );
  }
};

export default dashboardHelpers;
