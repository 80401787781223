import { getPasswordValidationErrorMsg } from '@auth/utils';
import forgotPassword from '../util/forgotPassword';
import { forgotPasswordActionTypes } from '../constants/actionTypes';
import alertsUtil from '../util/alertsUtil';
import { snackbarTypes } from '../constants/alertTypes';

const forgotPasswordActions = {
  setToken(token) {
    return {
      type: forgotPasswordActionTypes.RECEIVED_TOKEN,
      token
    };
  },

  sendEmail({ apiFunctionArgs }) {
    return function (dispatch) {
      dispatch({ type: forgotPasswordActionTypes.RESET_ERROR });
      dispatch({ type: forgotPasswordActionTypes.LOADING });
      dispatch({
        type: forgotPasswordActionTypes.UPDATE_EMAIL,
        email: apiFunctionArgs.email
      });

      forgotPassword
        .sendEmail(apiFunctionArgs)
        .then(response => {
          dispatch({
            type: forgotPasswordActionTypes.EMAIL_SENT,
            data: response.data,
            experimentKey: apiFunctionArgs.experimentKey
          });
        })
        .catch(error => {
          if (error) {
            dispatch({
              type: forgotPasswordActionTypes.EMAIL_SENT_ERROR,
              payload: error.response.data.msg
            });
          }
        });

      return {
        type: forgotPasswordActionTypes.EMAIL_SEND_START
      };
    };
  },

  sendNewPassword(apiFunctionArgs, { onSuccess, onError }) {
    return function (dispatch) {
      dispatch({ type: forgotPasswordActionTypes.RESET_ERROR });

      return forgotPassword
        .sendNewPassword(apiFunctionArgs)
        .then(() => {
          dispatch({
            type: forgotPasswordActionTypes.EMAIL_SEND_START
          });
          onSuccess();
        })
        .catch(error => {
          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.ERROR_CHANGE_PASSWORD,
              getPasswordValidationErrorMsg(error?.response?.data?.msg)
            )
          );
          onError();
        });
    };
  }
};

export default forgotPasswordActions;
