import { push } from 'connected-react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import billingApi from '@/util/billingApi';
import { useActiveWorkspaceName, useCurrentOrganization } from '@shared/hooks';

const useAcademicMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspaceName = useActiveWorkspaceName();
  const currentOrganization = useCurrentOrganization();

  return useMutation(
    ({ position, isPublishing, email, otherPublishing, graduationDate }) => {
      return billingApi.saveUserAcademicDetails({
        position,
        published: isPublishing,
        academicEmail: email,
        graduationYear: graduationDate,
        positionOther: otherPublishing,
        organizationId: currentOrganization?.id
      });
    },
    {
      onSuccess: () => {
        const confirmMessage =
          "Thanks for applying for our free academic access. We've sent you an email to confirm your academic email address. Please follow the url in the email in the next 30 days to maintain your academic account";
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CONFIRM_PLAN_ACADEMIC_MODAL,
            confirmMessage,
            'Academic Account Submission'
          )
        );

        queryClient.invalidateQueries(['organizations', 'list']);

        dispatch(push(`/${activeWorkspaceName}`));
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_ACADEMIC_MODAL,
            'There was an issue submiting your academic request'
          )
        );
      }
    }
  );
};

export default useAcademicMutation;
