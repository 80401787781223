import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import alerts from './alertsReducer';
import { projectsReducer } from './projectsReducer';
import currentExperiments from './experimentsReducer';
import user from './userReducer';
import files from './filesReducer';
import payments from './paymentsReducer';
import betaFeatures from './betaFeaturesReducer';
import dashboardCharts from './dashboardChartsReducer';

import invitedProjectUsers from './invitedProjectUsers';
import notes from './notesReducer';
import projectSecretLinks from './projectSecretLinks';
import projectSharedList from './projectSharedList';
import runDetails from './experimentDetails';
import forgotPassword from './forgotPasswordReducer';
import ui from './ui/uiReducer';
import visualizations from './visualizationsReducer';
import modelRegistry from './modelRegistryReducer';
import reportsReducer from './reportsReducer';
import artifactsReducer from './artifactsReducer';
import biEventsReducer from './biEvents/biEventsReducer';

export default history =>
  combineReducers({
    alerts,
    artifacts: artifactsReducer,
    betaFeatures,
    currentExperiments,
    dashboardCharts,
    files,
    forgotPassword,
    form: formReducer,
    invitedProjectUsers,
    modelRegistry,
    notes,
    payments,
    projects: projectsReducer,
    projectSecretLinks,
    projectSharedList,
    reports: reportsReducer,
    router: connectRouter(history),
    runDetails,
    ui,
    user,
    visualizations,
    biEvents: biEventsReducer
  });
