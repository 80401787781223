import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import toLower from 'lodash/toLower';
import reduce from 'lodash/reduce';
import difference from 'lodash/difference';

import { artifactActionTypes } from '@/constants/actionTypes';
import { createArtifactVersionKey } from '@artifacts/constants/constants';

const initialState = {
  map: {},
  versionMap: {},
  versionCommentsMap: {},
  versionUsageMap: {}
};

const artifactsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === artifactActionTypes.SET_ARTIFACTS_MAP) {
    const { map } = payload;

    return {
      ...state,
      map
    };
  }

  if (type === artifactActionTypes.SET_ARTIFACT) {
    const { artifact, artifactId, workspace, artifactName } = payload;

    const currentArtifact = get(state, ['map', artifactId], {});
    const versions = get(artifact, 'versions', []);

    const versionMap = versions.reduce((map, version) => {
      const { version: versionNumber } = version;
      const versionMapId = createArtifactVersionKey(
        workspace,
        artifactName,
        versionNumber
      );

      return { ...map, [versionMapId]: version };
    }, {});

    if (isEmpty(versionMap)) {
      return {
        ...state,
        map: { ...state.map, [artifactId]: { ...currentArtifact, ...artifact } }
      };
    }

    return {
      ...state,
      map: { ...state.map, [artifactId]: { ...currentArtifact, ...artifact } },
      versionMap
    };
  }

  if (type === artifactActionTypes.SET_ARTIFACT_VERSION) {
    const { versionMapId, artifactVersion } = payload;

    const currentMap = get(state, 'versionMap', {});

    const updatedArtifactVersion = {
      ...get(currentMap, versionMapId, {}),
      ...artifactVersion
    };

    const newAliases = get(artifactVersion, 'alias', []).map(toLower);

    if (!isEmpty(newAliases)) {
      const versionMapWithUniqueAliases = reduce(
        currentMap,
        (newMap, versionDetails, mapId) => {
          const currAliases = get(versionDetails, 'alias', []).map(toLower);

          newMap[mapId] = {
            ...versionDetails,
            alias: difference(currAliases, newAliases)
          };

          return newMap;
        },
        {}
      );

      return {
        ...state,
        versionMap: {
          ...versionMapWithUniqueAliases,
          [versionMapId]: updatedArtifactVersion
        }
      };
    }

    return {
      ...state,
      versionMap: {
        ...currentMap,
        [versionMapId]: updatedArtifactVersion
      }
    };
  }

  if (type === artifactActionTypes.DELETE_ARTIFACT) {
    const { artifactId } = payload;

    return {
      ...state,
      map: omit(state.map, artifactId)
    };
  }

  if (type === artifactActionTypes.DELETE_ARTIFACT_VERSION) {
    const { versionMapId } = payload;

    return {
      ...state,
      versionMap: omit(state.versionMap, versionMapId)
    };
  }

  if (type === artifactActionTypes.SET_ARTIFACT_VERSION_COMMENTS) {
    const { artifactVersionId, comments } = payload;

    return {
      ...state,
      versionCommentsMap: {
        ...state.versionCommentsMap,
        [artifactVersionId]: comments
      }
    };
  }

  if (type === artifactActionTypes.SET_ARTIFACT_VERSION_COMMENT) {
    const { artifactVersionId, comment } = payload;

    return {
      ...state,
      versionCommentsMap: {
        ...state.versionCommentsMap,
        [artifactVersionId]: [
          ...state.versionCommentsMap[artifactVersionId],
          comment
        ]
      }
    };
  }

  if (type === artifactActionTypes.DELETE_ARTIFACT_VERSION_COMMENT) {
    const { artifactVersionId, commentId } = payload;

    return {
      ...state,
      versionCommentsMap: {
        ...state.versionCommentsMap,
        [artifactVersionId]: filter(
          state.versionCommentsMap[artifactVersionId],
          ({ comment_id }) => comment_id !== commentId
        )
      }
    };
  }

  if (type === artifactActionTypes.SET_ARTIFACT_VERSION_USAGE) {
    const { artifactVersionId, usage } = payload;

    return {
      ...state,
      versionUsageMap: {
        ...state.versionUsageMap,
        [artifactVersionId]: usage
      }
    };
  }

  return state;
};

export default artifactsReducer;

export const getArtifactsMap = state => state.artifacts.map;
const getVersionMap = state => state.artifacts.versionMap;
const getVersionCommentsMap = state => state.artifacts.versionCommentsMap;
const getVersionUsageMap = state => state.artifacts.versionUsageMap;

const getWorkspace = (_, props) => get(props, 'workspace', '');
const getArtifactName = (_, props) => get(props, 'artifactName', '');
const getArtifactVersionNumber = (_, props) =>
  get(props, 'artifactVersionNumber', '');
const getArtifactVersionId = (_, props) => get(props, 'artifactVersionId', '');

export const getArtifactsArray = createSelector([getArtifactsMap], map =>
  Object.values(map)
);

export const getArtifactVersionsArray = createSelector([getVersionMap], map =>
  Object.values(map)
);

export const getArtifactByName = createSelector(
  [getArtifactsMap, getArtifactName],
  (map, name) => find(map, { name }) || {}
);

export const getArtifactVersion = createSelector(
  [getWorkspace, getArtifactName, getArtifactVersionNumber, getVersionMap],
  (workspace, artifactName, artifactVersionNumber, versionMap) => {
    const versionMapId = createArtifactVersionKey(
      workspace,
      artifactName,
      artifactVersionNumber
    );
    return get(versionMap, versionMapId, {});
  }
);

export const getArtifactVersionFiles = createSelector(
  [getVersionMap, getArtifactVersionId],
  (versionMap, artifactVersionId) => {
    const artifactVersion = find(versionMap, { artifactVersionId }, {});
    return get(artifactVersion, 'files', []);
  }
);

export const getArtifactVersionComments = createSelector(
  [getArtifactVersionId, getVersionCommentsMap],
  (versionId, versionCommentsMap) => get(versionCommentsMap, versionId, [])
);

export const getArtifactVersionUsage = createSelector(
  [getArtifactVersionId, getVersionUsageMap],
  (versionId, versionUsageMap) => get(versionUsageMap, versionId, [])
);
