import React, { useCallback, useEffect, useState, useMemo } from 'react';
import remove from 'lodash/remove';

import { FullWidthBasicModal } from '@DesignSystem/modals';
import useWorkspaceMembers from '@API/workspace/useWorkspaceMembers';
import useInvitesForTeam from '@API/workspace/useInvitesForTeam';
import useWorkspaceUsersPermissions from '@shared/api/useWorkspaceUsersPermissions';
import {
  getUserPermissionsByUserName,
  getUserUniqueName,
  ifPermissionsEqual,
  updateUserPermissionsByUserNameInList
} from '@shared/utils/permissions';
import { useDispatch, useSelector } from 'react-redux';
import ManageUsersModalFooter from './ManageUsersModalFooter';
import ManageUsersModalContent from './ManageUsersModalContent';
import useCurrentUser from '@API/auth/useCurrentUser';

import classNames from './ManageUsersModal.module.scss';
import {
  useActiveWorkspace,
  useDebouncedFilter,
  useCurrentOrganization,
  useAllUserWorkspaces
} from '@shared/hooks';

import workspaceActions from '../../../../actions/workspaceActions';
import { getManageUsersModalState } from '@/reducers/ui/workspaceUiReducer';
import { useOrganizationUsers } from '../../api';

import { ORGANIZATION_USER_ROLES } from '@shared/constants';

const removePermissionByUserName = ({
  username,
  usernamesOfUsersWithChangedPermissions,
  setUsernamesOfUsersWithChangedPermissions
}) => {
  const newUsersChanged = [...usernamesOfUsersWithChangedPermissions];
  remove(newUsersChanged, usN => usN === username);
  setUsernamesOfUsersWithChangedPermissions(newUsersChanged);
};

const ManageUsersModal = () => {
  const {
    debouncedFilterValue,
    setFilterValue,
    filterValue
  } = useDebouncedFilter('');

  const dispatch = useDispatch();
  const { data: workspaces } = useAllUserWorkspaces();
  const activeWorkspace = useActiveWorkspace();
  const { open, workspaceName, workspaceId } = useSelector(
    getManageUsersModalState
  );
  const [removedUsersUsernames, setRemovedUsersUsernames] = useState([]);
  const [
    usernamesOfUsersWithChangedPermissions,
    setUsernamesOfUsersWithChangedPermissions
  ] = useState([]);

  // set workspace depending on should it be default or the one that we opened
  const currentWorkspace = useMemo(
    () => ({
      workspaceName: workspaceName || activeWorkspace?.name,
      workspaceId: workspaceId || activeWorkspace?.id
    }),
    [activeWorkspace, workspaceName, workspaceId]
  );

  const {
    data: members = [],
    isLoading: isLoadingMembers
  } = useWorkspaceMembers(currentWorkspace.workspaceId, {
    enabled: open
  });
  const {
    data: invitedMembers = [],
    isLoading: isLoadingInvitedMembers
  } = useInvitesForTeam(currentWorkspace.workspaceId, {
    enabled: open
  });
  const { data: permissions = [] } = useWorkspaceUsersPermissions(
    currentWorkspace.workspaceId,
    {
      enabled: open
    }
  );

  const ifCurrentWsDefault = useMemo(() => {
    return (workspaces || []).find(
      workspace => workspace.id === currentWorkspace?.workspaceId
    )?.isDefault;
  }, [workspaces, currentWorkspace]);
  const ifCurrentWsOnlyOne = workspaces?.length === 1;

  const currentOrganization = useCurrentOrganization();
  const { data: organizationUsers = [] } = useOrganizationUsers(
    currentOrganization?.id
  );
  const [changedPermissions, setChangedPermissions] = useState([]);
  const { data: currentUser = {} } = useCurrentUser() || { data: {} };
  const activeUser = {
    ...currentUser,
    isAdmin: currentOrganization?.isAdmin
  };

  const resetChangesForRoles = (permissionsToReset = []) => {
    setChangedPermissions(permissionsToReset);
    setUsernamesOfUsersWithChangedPermissions([]);
    setRemovedUsersUsernames([]);
  };
  const resetAllValues = () => {
    setFilterValue('');
    resetChangesForRoles();
  };

  useEffect(() => {
    if (open && permissions.length && currentWorkspace.workspaceId) {
      setChangedPermissions(permissions);
    }
  }, [permissions, currentWorkspace.workspaceId, open]);

  useEffect(() => {
    if (!open) {
      resetAllValues();
    }
  }, [open, setFilterValue, setChangedPermissions]);

  const allCurrentWorkspaceMembers = useMemo(() => {
    const allUsers = [...members, ...invitedMembers];

    return allUsers
      ?.map(userIns => {
        const uniqueName = userIns.isMember
          ? getUserUniqueName(userIns)
          : userIns.email;

        if (removedUsersUsernames.includes(uniqueName)) return null;

        const userPermissions = getUserPermissionsByUserName(
          changedPermissions,
          uniqueName
        );
        const userInOrg =
          organizationUsers.find(
            organizationUser =>
              getUserUniqueName(organizationUser) === uniqueName
          ) || {};

        return {
          uniqueName,
          ...userIns,
          permissions: userPermissions,
          isAdmin: userInOrg?.role === ORGANIZATION_USER_ROLES.ADMIN
        };
      })
      .filter(Boolean);
  }, [
    // open,
    organizationUsers,
    members,
    invitedMembers,
    changedPermissions,
    // permissions,
    removedUsersUsernames
  ]);

  const onRemoveUsers = username => {
    setRemovedUsersUsernames(users => [...users, username]);

    // remove change of permissions
    removePermissionByUserName({
      username,
      setUsernamesOfUsersWithChangedPermissions,
      usernamesOfUsersWithChangedPermissions
    });
  };

  const onChangePermissions = useCallback(
    (newPermissions, userName) => {
      const originalUserPermissions = getUserPermissionsByUserName(
        permissions,
        userName
      );
      const ifPermissionsChanged = !ifPermissionsEqual(
        originalUserPermissions,
        newPermissions
      );
      const usernameIndex = usernamesOfUsersWithChangedPermissions.indexOf(
        userName
      );
      const userExistsInChangedList = usernameIndex !== -1;

      if (ifPermissionsChanged) {
        // add to the list that permissions were changed, but check with the original one as well, maybe they was changed to the original state
        if (!userExistsInChangedList) {
          setUsernamesOfUsersWithChangedPermissions([
            ...usernamesOfUsersWithChangedPermissions,
            userName
          ]);
        }
      } else if (!ifPermissionsChanged && userExistsInChangedList) {
        // permissions does not changed from original, but we have that user in list - remove him
        const newChangedUsersNamesList = [
          ...usernamesOfUsersWithChangedPermissions
        ];
        newChangedUsersNamesList.splice(usernameIndex, 1);
        setUsernamesOfUsersWithChangedPermissions(newChangedUsersNamesList);
      }

      setChangedPermissions(
        updateUserPermissionsByUserNameInList(
          changedPermissions,
          userName,
          newPermissions
        )
      );
    },
    [permissions, changedPermissions, usernamesOfUsersWithChangedPermissions]
  );

  const manageUsersModalCount =
    +members.length + +invitedMembers.length - removedUsersUsernames?.length;

  return (
    <FullWidthBasicModal
      key={currentWorkspace.workspaceId}
      className={classNames.manageUsersModalPopup}
      open={open}
      onClose={() => {
        resetAllValues();
        dispatch(
          workspaceActions.setManageWorkspaceModalState({ open: false })
        );
      }}
      title={`Manage users for “${currentWorkspace.workspaceName}” workspace`}
      titleMaskTest="Manage users for “workspace” workspace"
      primaryButtonText="Save"
      content={
        <ManageUsersModalContent
          isFetching={isLoadingInvitedMembers || isLoadingMembers}
          currentUser={activeUser}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          debouncedFilterValue={debouncedFilterValue}
          members={allCurrentWorkspaceMembers}
          manageUsersModalCount={manageUsersModalCount}
          workspace={currentWorkspace}
          onChangePermissions={onChangePermissions}
          onRemoveUsers={onRemoveUsers}
          ifCurrentWsOnlyOne={ifCurrentWsOnlyOne}
        />
      }
      footer={
        <ManageUsersModalFooter
          ifCurrentWsDefault={ifCurrentWsDefault}
          currentUser={activeUser}
          resetChangesForRoles={() => resetChangesForRoles(permissions)}
          permissions={permissions}
          changedPermissions={changedPermissions}
          usernamesOfUsersWithChangedPermissions={
            usernamesOfUsersWithChangedPermissions
          }
          removedUsersUsernames={removedUsersUsernames}
          workspace={currentWorkspace}
          activeWorkspace={activeWorkspace}
          workspaces={workspaces}
        />
      }
    />
  );
};

export default ManageUsersModal;
