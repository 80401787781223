import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';

import { IconButton, Tooltip } from '@ds';

import useProjectParams from '@API/project/useProjectParams';
import { getIsExperimentPage, getExperimentUrl } from '@shared/utils/url';
import { ExperimentCircleColorPicker } from '@experiment-management-shared/components';
import { EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT } from '@experiment-management-shared/constants/experimentConstants';
import { MAX_LENGTH_EXPERIMENT_NAME_FROM_KEY } from '@experiment-management-shared/constants/compareTableConstants';
import {
  getIsArchivePage,
  getIsExperimentsPage
} from '@/selectors/routeSelectors';
import { alertsActionTypes } from '@/constants/actionTypes';
import { getHoveredExperimentTrace } from '@/reducers/ui/visualizationsUiReducer';
import ActiveStatusIcon from '@experiment-management-shared/components/ActiveStatusIcon';
import ExperimentMetadataPopover from './ExperimentMetadataPopover';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import RedirectLink from '@shared/components/RedirectLink';
import { DSLinkIcon, DSOfflineIcon, DSShowMetadataIcon } from '@ds-icons';
import { getLinkLocationFrom } from '@shared/utils/experimentNavHelpers';
import PinExperimentButton from '@experiment-management-shared/components/ReactGrid/PinExperimentButton';
import VisibilityButton from '@experiment-management-shared/components/TableCells/cell/VisibilityButton';

const ArchivedLabel = <div className="archived-label">Archived</div>;

const ExperimentName = ({
  columns,
  decimalsPrecision,
  openInNewTab,
  row,
  onHoverExperimentNameCell,
  onToggleVisibility,
  onTogglePinned,
  pinnedExperimentKeys
}) => {
  const location = useLocation();
  const { projectName, workspace } = useProjectParams();
  const isArchive = useSelector(getIsArchivePage);
  const isExperimentPage = useSelector(getIsExperimentPage);
  const isExperimentsPage = useSelector(getIsExperimentsPage);

  const {
    archived,
    pinned,
    Name,
    nameCellProps: { renderColorButton = true, url: nameCellUrl } = {},
    experimentKey,
    processing,
    runActive,
    symlink: isSymlinked,
    throttle: experimentThrottled,
    hasCrashed,
    crashReason
  } = row;

  const dispatch = useDispatch();
  const [metadataAnchorEl, setMetadataAnchorEl] = useState(null);
  const renderVisibilityButton = isFunction(onToggleVisibility);
  const renderPinButton = isFunction(onTogglePinned);

  const { experimentKey: hoveredExperimentKey } = useSelector(
    getHoveredExperimentTrace
  );

  const isExperimentHovered = hoveredExperimentKey === experimentKey;

  const experimentUrl = getExperimentUrl({
    isArchive,
    projectName,
    experimentKey: row?.experimentKey,
    workspace
  });
  const experimentTab = queryString.parse(window.location.search)[
    'experiment-tab'
  ];
  const experimentTabSearch = experimentTab
    ? `?experiment-tab=${experimentTab}`
    : '';

  const linkLocationFrom = getLinkLocationFrom({
    isArchive,
    isExperimentsPage
  });

  const onHoverDebounced = useMemo(() => {
    return debounce(() => {
      onHoverExperimentNameCell(row.experimentKey);
    }, EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT);
  }, [onHoverExperimentNameCell, row.experimentKey]);

  const onMouseEnter = useCallback(() => {
    onHoverDebounced();
  }, [onHoverDebounced]);

  const handleMouseLeave = useCallback(() => {
    onHoverDebounced.cancel();
  }, [onHoverDebounced]);

  const handlePopoverClose = useCallback(() => {
    setMetadataAnchorEl(null);
  }, []);

  const handleThrottlingClick = ev => {
    const key = ev.currentTarget.parentElement.getAttribute(
      'data-experiment-key'
    );

    dispatch({
      type: alertsActionTypes.OPEN_THROTTLING_POPUP,
      payload: {
        experimentKey: key,
        throttlingPopoverAnchor: ev.currentTarget
      }
    });
  };

  const handleToggleMetadataPopover = event =>
    setMetadataAnchorEl(event.target);

  const getCellText = () => {
    if (Name === experimentKey || !Name) {
      return experimentKey?.slice(0, MAX_LENGTH_EXPERIMENT_NAME_FROM_KEY);
    }

    return Name;
  };

  const renderSingleExperimentPageLink = () => {
    const cellText = getCellText();
    const testId = `experiment-name-link_${experimentKey}`;

    let prevLocation = location;
    if (isExperimentPage) {
      prevLocation = null;
    }
    if (location.state?.prevLocation) {
      prevLocation = location.state.prevLocation;
    }

    if (!isEmpty(nameCellUrl) || !isEmpty(experimentUrl)) {
      return (
        <Tooltip
          content={cellText}
          placement="top"
          enterDelay={700}
          enterNextDelay={700}
          wrapperDisplay="flex"
        >
          {openInNewTab ? (
            <RedirectLink
              resource={REDIRECT_LINK_RESOURCES.EXPERIMENT}
              args={[row.experimentKey]}
              className={cx('experiment-name-link', {
                hovered: isExperimentHovered
              })}
              data-test={testId}
              data-mask-test="Experiment name"
            >
              {cellText}
            </RedirectLink>
          ) : (
            <Link
              className={cx('experiment-name-link', {
                hovered: isExperimentHovered
              })}
              to={{
                pathname: nameCellUrl || experimentUrl,
                search: experimentTabSearch,
                state: {
                  from: linkLocationFrom,
                  prevLocation
                }
              }}
              data-test={testId}
              data-mask-test="Experiment name"
            >
              {cellText}
            </Link>
          )}
        </Tooltip>
      );
    }

    return <div>{cellText}</div>;
  };

  return (
    <>
      <div
        className="experiment-name-cell"
        onMouseEnter={onMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {renderVisibilityButton && (
          <div className="experiment-name-cell-visibility">
            <VisibilityButton
              row={row}
              onToggleVisibility={onToggleVisibility}
              className="react-grid-visibility-button"
            />
          </div>
        )}
        {renderColorButton ? (
          <ExperimentCircleColorPicker experiment={row} />
        ) : null}

        <div className="cell-content">
          {renderSingleExperimentPageLink()}

          {archived && ArchivedLabel}

          <ActiveStatusIcon
            loading={runActive}
            throttle={experimentThrottled}
            crash={hasCrashed}
            crashReason={crashReason}
            onClick={handleThrottlingClick}
            data-experiment-key={row.experimentKey}
          />
          {processing && (
            <div className="icon-cell">
              <Tooltip
                content="Offline experiment"
                placement="top"
                wrapperDisplay="unset"
              >
                <DSOfflineIcon />
              </Tooltip>
            </div>
          )}
          {isSymlinked && (
            <div className="icon-cell status-icon">
              <Tooltip
                content="This experiment is symlinked from another project"
                placement="top"
                wrapperDisplay="unset"
              >
                <DSLinkIcon />
              </Tooltip>
            </div>
          )}
        </div>

        <div
          className={cx('experiment-name-cell-actions name-cell-actions', {
            'experiment-name-cell-actions-is-pinned': pinned,
            hovered: !!metadataAnchorEl
          })}
        >
          <ExperimentMetadataPopover
            anchorEl={metadataAnchorEl}
            decimalsPrecision={decimalsPrecision}
            columns={columns}
            row={row}
            onClose={handlePopoverClose}
          />

          <Tooltip placement="top" content="Show metadata">
            <div className="experiment-name-cell-actions-metadata">
              <IconButton
                type="secondary"
                Icon={<DSShowMetadataIcon />}
                onClick={handleToggleMetadataPopover}
                active={!!metadataAnchorEl}
              />
            </div>
          </Tooltip>

          {renderPinButton && (
            <div className="experiment-name-cell-actions-pin">
              <PinExperimentButton
                experimentKey={experimentKey}
                onTogglePinned={onTogglePinned}
                pinned={Boolean(pinned)}
                pinnedExperimentKeys={pinnedExperimentKeys}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ExperimentName.defaultProps = {
  onToggleVisibility: undefined,
  onTogglePinned: undefined,
  pinnedExperimentKeys: [],
  decimalsPrecision: null,
  openInNewTab: false,
  onHoverExperimentNameCell: noop
};

ExperimentName.propTypes = {
  onToggleVisibility: PropTypes.func,
  onTogglePinned: PropTypes.func,
  pinnedExperimentKeys: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.array.isRequired,
  decimalsPrecision: PropTypes.number,
  onHoverExperimentNameCell: PropTypes.func,
  openInNewTab: PropTypes.bool,
  row: PropTypes.object.isRequired
};

export default ExperimentName;
