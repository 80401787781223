import React from 'react';
import PropTypes from 'prop-types';

import InfoIcon from '@material-ui/icons/Info';

const IsTruncatedBlock = ({ onExportData }) => (
  <div className="is-truncated-wrapper" data-test="is-truncated-block">
    <InfoIcon />
    <span>Only the end of this output is shown.&nbsp;</span>
    <span className="download-label" onClick={onExportData}>
      Download full log
    </span>
  </div>
);

IsTruncatedBlock.propTypes = {
  onExportData: PropTypes.func.isRequired
};

export default IsTruncatedBlock;
