// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ{height:100px;border:1px solid var(--gray-2);border-radius:5px;margin-bottom:8px}.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ.ConfusionMatrixExample-module__highlighted--M7Cpt{border:1px solid var(--black)}.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ .ConfusionMatrixExample-module__header--GvdvS{height:31px;padding:4px 6px 6px;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ .ConfusionMatrixExample-module__header--GvdvS .ConfusionMatrixExample-module__title--G35dN{font-weight:500;font-size:14px;line-height:21px;color:var(--color-warning);overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ .ConfusionMatrixExample-module__header--GvdvS .ConfusionMatrixExample-module__title--G35dN.ConfusionMatrixExample-module__correct--JDj9j{color:var(--color-turtoise)}.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ .ConfusionMatrixExample-module__body--ygree{display:flex;height:61px;padding:0 6px;overflow:auto}.ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ .ConfusionMatrixExample-module__body--ygree .ConfusionMatrixExample-module__empty--vcJa2{margin:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confusionMatrixExample": `ConfusionMatrixExample-module__confusionMatrixExample--VJGZJ`,
	"highlighted": `ConfusionMatrixExample-module__highlighted--M7Cpt`,
	"header": `ConfusionMatrixExample-module__header--GvdvS`,
	"title": `ConfusionMatrixExample-module__title--G35dN`,
	"correct": `ConfusionMatrixExample-module__correct--JDj9j`,
	"body": `ConfusionMatrixExample-module__body--ygree`,
	"empty": `ConfusionMatrixExample-module__empty--vcJa2`
};
module.exports = ___CSS_LOADER_EXPORT___;
