import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

import useExperimentsMetrics from '@experiment-management-shared/api/useExperimentsMetrics';
import {
  CHART_COLORS,
  lightenColor,
  PRIMARY_TO_LIGHT_COLOR_MAP
} from '@/constants/colorConstants';
import { getMetricColorsForCurrentTemplate } from '@/reducers/experimentDetails/chartsReducer';
import {
  selectExperimentKeys,
  selectIsComparePage
} from '@/reducers/ui/experimentsUiReducer';

const useExperimentPanelColors = () => {
  const experimentKeys = useSelector(selectExperimentKeys);
  const experiments = useMemo(() => {
    return experimentKeys.map(experimentKey => ({
      experimentKey,
      isActive: false
    }));
  }, [experimentKeys]);
  const { data: experimentsMetrics } = useExperimentsMetrics(
    { experiments },
    { refetchInterval: false, refetchOnMount: false }
  );
  const metricNames = useMemo(() => {
    if (!experimentsMetrics) return [];

    return uniq(experimentsMetrics.flat().map(({ name }) => name));
  }, [experimentsMetrics]);

  const isComparePage = useSelector(selectIsComparePage);
  const templateColors = useSelector(getMetricColorsForCurrentTemplate);

  return useMemo(() => {
    return metricNames.reduce((map, metricName, idx) => {
      const colorIndex = idx % CHART_COLORS.length;
      let primary = isComparePage ? CHART_COLORS[0] : CHART_COLORS[colorIndex];
      let light = PRIMARY_TO_LIGHT_COLOR_MAP[primary];

      if (templateColors[metricName]) {
        primary = templateColors[metricName];
        light = lightenColor(primary);
      }

      // eslint-disable-next-line no-param-reassign
      map[metricName] = { primary, light };
      return map;
    }, {});
  }, [isComparePage, metricNames, templateColors]);
};

export default useExperimentPanelColors;
