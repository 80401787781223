import React from 'react';
import { DSArrowDownIcon, DSArrowUpIcon } from '@design-system/icons';
import { Tooltip } from '@design-system/components';
import ExpandTableIcon from './icons/expand-table-icon.svg';
import styles from './ExpandTableButton.module.scss';

type ExpandTableButtonProps = {
  isOpened: boolean;
  onClick: () => void;
  isComparisonView: boolean;
};
const ExpandTableButton: React.FC<ExpandTableButtonProps> = ({
  isOpened,
  onClick,
  isComparisonView
}) => {
  const experimentCopy = isComparisonView ? 'experiments' : 'experiment';
  const actionCopy = isOpened ? 'Hide' : 'Show';

  return (
    <Tooltip
      content={`${actionCopy} ${experimentCopy} table`}
      placement="bottom-end"
    >
      <div
        data-test="expand-table-button"
        className={styles.expandTableButton}
        onClick={onClick}
      >
        <ExpandTableIcon />
        {isOpened ? <DSArrowUpIcon /> : <DSArrowDownIcon />}
      </div>
    </Tooltip>
  );
};

export default ExpandTableButton;
