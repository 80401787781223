import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import {
  EmptyExperimentTab,
  MarkdownEditor,
  SmallLoader
} from '@shared/components';
import { uploadProjectNoteFile } from './helpers';
import { ExperimentDetails } from '@shared/types';
import useProject from '@API/project/useProject';
import {
  useExperimentNoteMutation,
  useExperimentNote
} from '@experiment-details/api';
import cx from 'classnames';

import styles from './NoteTab.module.scss';

type NoteTabProps = {
  experiments: ExperimentDetails[];
  isComparePage?: boolean;
};

const NoteTab = ({ experiments, isComparePage }: NoteTabProps) => {
  const { data: project } = useProject();
  const canEdit = useSelector(isUserAllowedToEditProject);
  const [experiment] = experiments;

  const experimentNoteMutation = useExperimentNoteMutation();

  const { data, isLoading } = useExperimentNote({
    experimentKey: experiment.experimentKey
  });

  const handleAddImage = useCallback(
    file => {
      return uploadProjectNoteFile({ file, projectId: project?.projectId });
    },
    [project?.projectId]
  );

  const handleSave = useCallback(
    (note: string) => {
      experimentNoteMutation.mutate({
        experimentKey: experiment.experimentKey,
        note
      });
    },
    [experiment.experimentKey]
  );

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching experiment notes"
      />
    );
  }

  if (!canEdit && !data) {
    if (isComparePage) {
      return (
        <EmptyExperimentTab>
          <EmptyExperimentTab.Title>No notes</EmptyExperimentTab.Title>
        </EmptyExperimentTab>
      );
    }
    return (
      <EmptyExperimentTab className={styles.emptyMessage}>
        <EmptyExperimentTab.Icon iconName="output" />
        <EmptyExperimentTab.Title>
          There are no notes to show
        </EmptyExperimentTab.Title>
        <EmptyExperimentTab.Description>
          You are not authorised to create notes.
        </EmptyExperimentTab.Description>
      </EmptyExperimentTab>
    );
  }

  return (
    <div className={cx(styles.tabContainer, 'note-tab')}>
      <MarkdownEditor
        editable={canEdit}
        markdown={data ?? ''}
        onChange={handleSave}
        onAddImage={handleAddImage}
      />
    </div>
  );
};

export default NoteTab;
