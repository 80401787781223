import useExperimentNameByExperimentKey from '@experiment-management-shared/api/useExperimentNameByExperimentKey';

// Example: /:workspaceName/:projectName/:experimentKey
const EXPERIMENT_KEY_SEGMENT_POSITION = 3;

const getExperimentKeyFromCellValue = (urlString: string) => {
  const url = new URL(urlString);
  return url.pathname.split('/')[EXPERIMENT_KEY_SEGMENT_POSITION];
};

export const useExperimentNameFromUrlString = (urlString: string) => {
  const experimentKey = getExperimentKeyFromCellValue(urlString);
  const { data: experimentName } = useExperimentNameByExperimentKey(
    experimentKey,
    {
      enabled: !!experimentKey
    }
  );
  return experimentName;
};
