import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Box from '@material-ui/core/Box';
import { MODEL_STREAMLINE_REGISTRATION_VISIBILITY } from '@shared/constants/model-streamline-registration';
import { Switch } from '@DesignSystem/controllers';
import classNames from './RegisterModel.module.scss';

const RegisterModelSwitchVisibility = ({ onChange, value }) => {
  const onChangeCb = useCallback(() => {
    onChange(
      value === MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE
        ? MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PUBLIC
        : MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE
    );
  }, [value]);

  return (
    <Box className={classNames.registerModelSwitch}>
      <Grid
        container
        alignItems="flex-start"
        alignContent="flex-start"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        <Grid item xs={1}>
          <Switch
            data-test="streamline-model-sidebar-visibility-switcher"
            className={classNames.registerModelSwitcher}
            checked={value === MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE}
            onChange={onChangeCb}
            color="primary"
          />
        </Grid>
        <Grid
          item
          xs={10}
          className={classNames.registerModelSwitchLabelWrapper}
          onClick={onChangeCb}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classNames.registerModelSwitchLabel}>
                This model is private
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classNames.registerModelSwitchText}>
                Only members of the workspace wil have access to this model
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

RegisterModelSwitchVisibility.defaultProps = {};

RegisterModelSwitchVisibility.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([
    MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE,
    MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PUBLIC
  ]).isRequired
};

export default RegisterModelSwitchVisibility;
