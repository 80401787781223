import React, { useEffect, ReactNode } from 'react';
import classes from './ListComponents.module.scss';
import cx from 'classnames';

import { ListActionSize } from '@design-system/types';
import { useListItemContext } from '../useListItemContext';

export interface ListActionContainerProps {
  children: ReactNode;
  size?: ListActionSize;
  visibleOnHoverOnly?: boolean;
}

const ListActionContainer = ({
  children,
  size = 'small',
  visibleOnHoverOnly = false
}: ListActionContainerProps) => {
  const {
    setActionSize,
    setActionsVisibleOnHoverOnly,
    isWithSecondaryText
  } = useListItemContext();

  useEffect(() => {
    setActionSize(size);
  }, [size, setActionSize]);

  useEffect(() => {
    setActionsVisibleOnHoverOnly(visibleOnHoverOnly);
  }, [visibleOnHoverOnly, setActionsVisibleOnHoverOnly]);

  return (
    <div
      className={cx(classes.actionsContainer, classes[size], {
        [classes.stickToTheTopActions]: isWithSecondaryText
      })}
    >
      {children}
    </div>
  );
};

export default ListActionContainer;
