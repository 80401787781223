import { AssetStep } from '@experiment-management-shared/types';
import api, { QueryConfig } from '@shared/api';
import { useCombinedQueries } from '@shared/hooks';
import { AxiosError } from 'axios';
import { sortBy, uniq } from 'lodash';
import { UseQueryOptions } from 'react-query';

const BE_CURVE_TYPE = 'curve';

export type CurveAssetInfo = {
  assetId: string;
  experimentKey: string;
  fileName: string;
  link: string;
  step: AssetStep;
};

const getCurveAssets = async ({
  experimentKey,
  signal
}: {
  experimentKey?: string;
  signal?: AbortSignal;
}) => {
  if (!experimentKey) {
    return [];
  }

  const { data } = await api.get<CurveAssetInfo[]>('asset/list', {
    params: {
      experimentKey,
      type: BE_CURVE_TYPE
    },
    signal
  });

  return data;
};

export function useCurvePlainAssets(
  { experimentKeys }: { experimentKeys: string[] },
  config?: QueryConfig<CurveAssetInfo[]>
) {
  const queries = experimentKeys.map(experimentKey => {
    return {
      queryKey: ['curveAssetInfo', { experimentKey } as const],
      queryFn: ({ signal }: { signal?: AbortSignal }) => {
        return getCurveAssets({ experimentKey, signal });
      },
      enabled: !!experimentKey,
      refetchOnMount: true,
      ...config
    } as UseQueryOptions<CurveAssetInfo[], AxiosError, CurveAssetInfo[]>;
  });

  const query = useCombinedQueries(queries);

  return {
    ...query,
    data: query.data.flat().filter(Boolean) as CurveAssetInfo[]
  };
}

export function useCurveAssets(
  { experimentKeys }: { experimentKeys: string[] },
  config?: QueryConfig<CurveAssetInfo[]>
) {
  const { data: allAssets, ...query } = useCurvePlainAssets(
    { experimentKeys },
    config
  );

  return {
    ...query,
    data: {
      assets: allAssets,
      names: sortBy(uniq(allAssets.map(asset => asset.fileName))),
      steps: sortBy(uniq(allAssets.map(asset => asset.step)))
    }
  };
}
