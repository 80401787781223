import React from 'react';
import useOrganizationSelect from './hooks/useOrganizationSelect';
import UserMenuOrganizationSelectItem from './UserMenuOrganizationSelectItem';

import styles from './UserMenuOrganization.module.scss';
import { useUserMenuContext } from '../../userMenuContext';
import { Organization } from '@shared/types';

type UserMenuOrganizationSelectorProps = {
  currentOrganizationName: string;
};
const UserMenuOrganizationSelector: React.FC<UserMenuOrganizationSelectorProps> = ({
  currentOrganizationName
}) => {
  const { organizationList, onChangeOrganization } = useOrganizationSelect();
  const { closeMainMenu } = useUserMenuContext();

  const handleSelectOrganization = (organization: Organization) => {
    onChangeOrganization(organization);
    closeMainMenu();
  };

  return (
    <div className={styles.organizationSelectList}>
      {organizationList.map(organization => (
        <UserMenuOrganizationSelectItem
          key={organization.id}
          organization={organization}
          onSelect={handleSelectOrganization}
          currentOrganizationName={currentOrganizationName}
        />
      ))}
    </div>
  );
};

export default UserMenuOrganizationSelector;
