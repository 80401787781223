import React, { useState } from 'react';
import ReactFlow, {
  Controls,
  Edge,
  Node,
  NodeTypes,
  ReactFlowProvider,
  useEdgesState,
  useNodesState
} from 'react-flow-renderer';
import styles from './LineageLayoutFlow.module.scss';
import cx from 'classnames';
import { LineageNodeTypeText } from './LineageLayoutFlowNode';
import { ToggleButton } from '@ds';
import useDeletedNodesIsHidden from './hooks/useDeletedNodesIsHidden';
import useUpdateLineageLayout from './hooks/useUpdateLineageLayout';

type LineageLayoutFlowProps = {
  nodeTypes: NodeTypes;
  legendNodes: Array<keyof typeof LineageNodeTypeText>;
  mainNodeId: string;
  defaultNodes: Node[];
  defaultEdges: Edge[];
};
const LineageLayoutFlow: React.FC<LineageLayoutFlowProps> = ({
  defaultNodes = [],
  defaultEdges = [],
  nodeTypes,
  mainNodeId,
  legendNodes
}) => {
  const [hiddenDeletedNodes, setHiddenDeletedNodes] = useState(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);

  useDeletedNodesIsHidden({
    isHidden: hiddenDeletedNodes,
    nodes,
    edges,
    setEdges,
    setNodes,
    mainNodeId
  });
  useUpdateLineageLayout({
    nodes,
    edges,
    mainNodeId,
    setNodes
  });

  return (
    <div className={styles.lineageFlowContainer}>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <Controls showInteractive={false} />
        <div className={styles.legendContainer}>
          <div className={styles.legendInfo}>
            {legendNodes.map(value => (
              <div key={value} className={cx(styles.legendField, value)}>
                <div className={cx('circle', 'box-color')} />
                <p>{LineageNodeTypeText[value]}</p>
              </div>
            ))}
          </div>
          <div className={styles.legendControls}>
            <label
              htmlFor="hidden-deleted-nodes-toggle"
              className={styles.legendToggle}
            >
              <ToggleButton
                id="hidden-deleted-nodes-toggle"
                checked={!hiddenDeletedNodes}
                onChange={() => setHiddenDeletedNodes(v => !v)}
                color="primary"
              />
              Show deleted items
            </label>
          </div>
        </div>
      </ReactFlow>
    </div>
  );
};

const LineageWithReactFlowProvider: React.FC<LineageLayoutFlowProps> = props => (
  <ReactFlowProvider>
    <LineageLayoutFlow {...props} />
  </ReactFlowProvider>
);

export default LineageWithReactFlowProvider;
