/* eslint-disable react/function-component-definition */
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';

import useProjectTags from '@API/project/useProjectTags';
import {
  generateEmptyRulesTree,
  TAG_COLUMN_NAME
} from '@shared/utils/filterHelpers';

import { getIsArchivePage } from '@/selectors/routeSelectors';
import { axiosInstance, cancelWrapper } from '../api';
import { generateToColumn } from '../experiments/utils';
import useColumns from '../project/useColumns';
import useProject from '../project/useProject';

const getFilterOptions = ({ group, isArchive, projectId } = {}) => {
  return cancelWrapper(async cancelToken => {
    if (projectId) {
      const {
        data: { groups }
      } = await axiosInstance({
        cancelToken,
        data: {
          deleted: isArchive,
          group,
          rulesTree: generateEmptyRulesTree(),
          projectId
        },
        method: 'post',
        url: 'query/experiments/groups/search'
      });

      const optionValues = groups
        .map(({ value }) => {
          // it is filtering special value 'Undefined' that BE created for groups
          // Endpoint into undefined with this code if user manually logged 'undefined'
          // (doesn't meter Upper ar Lover case)it will not be shown in dropdown list
          // this is reverting of CM-5563
          if (value?.toLowerCase() === 'undefined') return undefined;

          // it is filtering empty string if it was logged by user
          // the empty string option will net be presented in dropdown but user
          // can achieve the same result using 'is empty'/ 'is not empty' operators
          if (value === '') return undefined;

          return value;
        })
        .filter(value => value !== undefined && value !== null);

      return sortBy(optionValues).map(value => ({
        label: value,
        value
      }));
    }
  });
};

const getQueryParams = ({ columns, params, projectId }) => {
  const { columnName, isArchive } = params;

  if (!columns) return {};

  const toColumn = generateToColumn(columns);

  return {
    group: toColumn(columnName),
    isArchive,
    projectId
  };
};

function useFilterOptions(params, config) {
  const { data: columns, isLoading: isLoadingColumns } = useColumns({
    extraCols: true
  });
  const { data: project, isLoading: isLoadingProject } = useProject();
  const projectId = params.projectId || project?.projectId;

  const queryParams = getQueryParams({
    columns,
    params,
    projectId
  });
  const enabled =
    params.shouldFetchOptions &&
    !isLoadingColumns &&
    !isLoadingProject &&
    !!queryParams;

  return useQuery(
    ['filters', queryParams],
    () => getFilterOptions(queryParams),
    { enabled, ...config }
  );
}

// this component is used to allow useFilterOptions to be called
// conditionally
const UseFilterOptionsWrapper = ({
  columnName,
  shouldFetchOptions,
  setOptions
}) => {
  const isArchive = useSelector(getIsArchivePage);
  const isTags = columnName === TAG_COLUMN_NAME;

  const { data: columnOptions } = useFilterOptions({
    columnName,
    isArchive,
    shouldFetchOptions: shouldFetchOptions && !isTags
  });

  const { data: tags } = useProjectTags({
    enabled: shouldFetchOptions && isTags
  });

  const tagsOptions = useMemo(() => {
    if (!isArray(tags)) {
      return null;
    }

    return tags
      .map(({ name }) => name)
      .sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      })
      .map(value => ({
        label: value,
        value
      }));
  }, [tags]);

  const options = isTags ? tagsOptions : columnOptions;

  useEffect(() => {
    if (isArray(options)) {
      setOptions(options);
    }
  }, [options, setOptions]);

  return null;
};

export default UseFilterOptionsWrapper;
