import { useQuery } from 'react-query';

import { axiosInstance, cancelWrapper } from '../api';

const getPanelTemplateRevisions = async ({ templateId }) =>
  cancelWrapper(async cancelToken => {
    const {
      data: { revisions }
    } = await axiosInstance.get(
      `code-visualizations/template/${templateId}/revisions/`,
      { cancelToken }
    );

    return revisions;
  });

export default function usePanelTemplateRevisions({ templateId }, config) {
  const enabled = Boolean(templateId);

  return useQuery(
    ['panelTemplateRevisions', { templateId }],
    () => getPanelTemplateRevisions({ templateId }),
    { enabled, ...config }
  );
}
