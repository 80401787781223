import { castValue } from '@API/experiments/utils';
import useProjectsCount from '@projects/api/useProjectsCount';
import {
  useCurrentPaymentPlan,
  useIsUserMemberOfWorkspace
} from '@shared/hooks';
import cx from 'classnames';
import findKey from 'lodash/findKey';
import identity from 'lodash/identity';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';

import ModelRegistryTab from '@model-registry/components/ModelRegistryTab';
import { trackEvent } from '@shared/utils/eventTrack';

import projectsActions from '@/actions/projectsActions';
import workspaceActions from '@/actions/workspaceActions';
import { ModelProductionMonitoringHome as ModelProductionMonitoringHomeDruid } from '@mpm-druid/components';
import { ModelProductionMonitoringHome } from '@mpm/components';

import { DEFAULT_APP_TITLE_BASE } from '@/constants/generalConstants';
import { viewEvents } from '@/constants/trackingEventTypes';
import {
  WORKSPACE_PAGE_TABS,
  WORKSPACE_PAGE_TAB_LABELS,
  WORKSPACE_URL_HASHES
} from '@/constants/workspaceConstants';
import {
  BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID,
  BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL
} from '@/lib/betaFeatures';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import { getItemCountsForAllTabs } from '@/reducers/ui/workspaceUiReducer';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import ArtifactsTab from '@artifacts/components/ArtifactsTab';
import CreateProjectModalBtn from '@projects/components/CreateProjectModalBtn';
import ProjectsTab from '@projects/components/ProjectsTab/ProjectsTab';
import TabLabelWithBadge from '@shared/components/TabLabelWithBadge';

import StyledTab from '@shared/components/StyledComponents/StyledTab';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import useBreadcrumbsDataSource from '@shared/hooks/useBreadcrumbsDataSource';
import { Link } from 'react-router-dom';
import styles from './WorkspacePage.module.scss';

const WorkspacePage = ({
  dispatch,
  history,
  isMPMInternalEnabled,
  isMPMDruidEnabled,
  isUserLoggedIn,
  itemCounts,
  location,
  match
}) => {
  const activeTab = findKey(
    WORKSPACE_URL_HASHES,
    hash => hash === location.hash
  );

  const { workspace } = match.params;
  const workspaceURLParam = match.params.workspace;
  const isMPM = activeTab === WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING;
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const projectsTotalCount = useProjectsCount();
  const breadcrumbsDataSource = useBreadcrumbsDataSource();

  const TAB_MAP = {
    [WORKSPACE_PAGE_TABS.PROJECTS]: true,
    [WORKSPACE_PAGE_TABS.MODEL_REGISTRY]: true,
    [WORKSPACE_PAGE_TABS.ARTIFACTS]: true,
    [WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING]: isMPMInternalEnabled
  };

  const { paymentPlanName } = useCurrentPaymentPlan();

  useEffect(() => {
    trackEvent(viewEvents.WORKSPACE_VIEWED, {
      isUserAMemberOfWorkspace,
      isUserLoggedIn,
      workspace,
      workspacePlan: paymentPlanName
    });
  }, [paymentPlanName, isUserAMemberOfWorkspace, isUserLoggedIn, workspace]);

  useEffect(() => {
    if (castValue(workspaceURLParam)) {
      dispatch(workspaceActions.fetchItemCounts(workspaceURLParam));
    }

    return () => {
      dispatch(projectsActions.clearProjectsUi());
    };
  }, [workspaceURLParam]);

  useEffect(() => {
    if (!activeTab) {
      history.replace(WORKSPACE_URL_HASHES.PROJECTS);
    }
  }, [activeTab]);

  useEffect(() => {
    const items = [
      {
        id: 'home',
        type: 'home',
        title: workspace,
        dataMaskTest: 'workspace'
      }
    ];

    breadcrumbsDataSource.setItems(items);
    return () => {
      breadcrumbsDataSource.resetItems();
    };
  }, [
    breadcrumbsDataSource.setItems,
    breadcrumbsDataSource.resetItems,
    workspace
  ]);

  const getTabCount = tabName => {
    if (isMPM && !isMPMInternalEnabled) return null;

    if (tabName === WORKSPACE_PAGE_TABS.PROJECTS) {
      return projectsTotalCount;
    }

    return itemCounts[tabName];
  };

  const renderTabs = () => {
    const visibleTabs = keys(pickBy(TAB_MAP, identity));

    return visibleTabs.map(tab => {
      const label = (
        <TabLabelWithBadge
          labelText={WORKSPACE_PAGE_TAB_LABELS[tab]}
          badgeCount={getTabCount(tab)}
        />
      );

      return (
        <StyledTab
          key={tab}
          value={tab}
          component={Link}
          to={location => ({ ...location, hash: WORKSPACE_URL_HASHES[tab] })}
          label={label}
          data-test={`${tab.toLowerCase()}-tab-count`}
        />
      );
    });
  };
  const renderTabBar = () => {
    return (
      <StyledTabs className={styles.tabBar} value={activeTab}>
        {renderTabs()}
      </StyledTabs>
    );
  };

  const renderCreateProjectBtn = () => {
    if (
      activeTab === WORKSPACE_PAGE_TABS.PROJECTS &&
      isUserLoggedIn &&
      isUserAMemberOfWorkspace
    ) {
      return <CreateProjectModalBtn />;
    }

    return null;
  };

  const renderHeader = () => {
    return (
      <div className={cx(styles.header, styles.bottomBorder)}>
        <div className={styles.headerContainer}>
          <div className={styles.tabBarContainer}>
            {renderTabBar()}
            {renderCreateProjectBtn()}
          </div>
        </div>
      </div>
    );
  };

  const renderActiveTab = () => {
    const entityName = WORKSPACE_PAGE_TAB_LABELS[activeTab];
    if (activeTab === WORKSPACE_PAGE_TABS.PROJECTS) {
      return <ProjectsTab entityName={entityName} />;
    }

    if (activeTab === WORKSPACE_PAGE_TABS.ARTIFACTS) {
      return <ArtifactsTab entityName={entityName} />;
    }

    if (
      activeTab === WORKSPACE_PAGE_TABS.MODEL_REGISTRY &&
      TAB_MAP[WORKSPACE_PAGE_TABS.MODEL_REGISTRY]
    ) {
      return <ModelRegistryTab entityName={entityName} />;
    }

    if (
      activeTab === WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING &&
      TAB_MAP[WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING]
    ) {
      return isMPMDruidEnabled ? (
        <ModelProductionMonitoringHomeDruid />
      ) : (
        <ModelProductionMonitoringHome />
      );
    }

    return null;
  };

  const renderBody = () => {
    return (
      <div className={cx({ [styles.body]: !isMPM })}>{renderActiveTab()}</div>
    );
  };

  return (
    <DocumentTitle title={`${workspaceURLParam} ${DEFAULT_APP_TITLE_BASE}`}>
      <div className={cx(styles.container, styles.whiteBG)}>
        {renderHeader()}
        {renderBody()}
      </div>
    </DocumentTitle>
  );
};

WorkspacePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isMPMInternalEnabled: PropTypes.bool.isRequired,
  isMPMDruidEnabled: PropTypes.bool.isRequired,
  itemCounts: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isMPMInternalEnabled: isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL
  }),
  isMPMDruidEnabled: isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID
  }),
  isUserLoggedIn: getIsUserLoggedIn(state),
  itemCounts: getItemCountsForAllTabs(state)
});

export default connect(mapStateToProps)(WorkspacePage);
