// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SystemMetricsTab-module__systemMetricTable--jJ5K4{padding:var(--experiment-details-layout-padding)}.SystemMetricsTab-module__systemMetricTable--jJ5K4 .ds-react-grid{border:1px solid var(--gray);border-radius:5px;overflow:hidden}.SystemMetricsTab-module__systemMetricTable--jJ5K4:not(.SystemMetricsTab-module__systemMetricComparePage--tIszp) .ds-react-grid tr:hover,.SystemMetricsTab-module__systemMetricTable--jJ5K4:not(.SystemMetricsTab-module__systemMetricComparePage--tIszp) .ds-react-grid tr:hover td.TableFixedCell-fixedCell{background-color:#f7f8fa}.SystemMetricsTab-module__emptyMessage--Tpdmv{padding-top:271px}.SystemMetricsTab-module__emptyMessage--Tpdmv.SystemMetricsTab-module__isComparePage--nmfZE{padding-top:252px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"systemMetricTable": `SystemMetricsTab-module__systemMetricTable--jJ5K4`,
	"systemMetricComparePage": `SystemMetricsTab-module__systemMetricComparePage--tIszp`,
	"emptyMessage": `SystemMetricsTab-module__emptyMessage--Tpdmv`,
	"isComparePage": `SystemMetricsTab-module__isComparePage--nmfZE`
};
module.exports = ___CSS_LOADER_EXPORT___;
