// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewComparisonTable-module__comparisonTable--sIalm{padding-left:var(--experiment-details-layout-padding);padding-right:var(--experiment-details-layout-padding);padding-bottom:var(--experiment-details-layout-padding);width:100%}.NewComparisonTable-module__comparisonTable--sIalm .ds-react-grid{border:1px solid var(--gray);border-radius:5px;overflow:hidden;width:100%;max-width:100%}.NewComparisonTable-module__comparisonTable--sIalm .ds-react-grid.virtualized{height:var(--table-maxheight)}.NewComparisonTable-module__comparisonTable--sIalm .ds-react-grid table colgroup col:last-child{display:none}.NewComparisonTable-module__comparisonTable--sIalm .MuiTable-root{width:100%;max-width:100%}.NewComparisonTable-module__comparisonTable--sIalm .dx-table-cell-row-child{display:none}.NewComparisonTable-module__comparisonTable--sIalm .table-row-view-not-equal,.NewComparisonTable-module__comparisonTable--sIalm .table-row-view-not-equal td.TableFixedCell-fixedCell{background-color:#ffeae9}.NewComparisonTable-module__comparisonTable--sIalm .table-row-view-not-equal:hover,.NewComparisonTable-module__comparisonTable--sIalm .table-row-view-not-equal:hover td.TableFixedCell-fixedCell{background-color:#ffe0df}.NewComparisonTable-module__comparisonTable--sIalm .ds-react-grid tr:not(.table-row-view-not-equal):hover,.NewComparisonTable-module__comparisonTable--sIalm .ds-react-grid tr:not(.table-row-view-not-equal):hover td.TableFixedCell-fixedCell{background-color:#f7f8fa}.NewComparisonTable-module__comparisonTable--sIalm .table-row-view-is-main{border-top:1px solid #ffeae9 !important}.table-tab-contaner__metrics .empty-tab-message{padding-top:101px}.table-tab-contaner__other .empty-tab-message{padding-top:101px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comparisonTable": `NewComparisonTable-module__comparisonTable--sIalm`
};
module.exports = ___CSS_LOADER_EXPORT___;
