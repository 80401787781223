import { AssetStep } from '@experiment-management-shared/types';
import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

type PCDStepsAPI = {
  steps: AssetStep[];
};

const getPCDSteps = async ({
  assetNames,
  experimentKeys
}: {
  assetNames: string[];
  experimentKeys: string[];
}) => {
  const { data } = await api.post<PCDStepsAPI>('asset/filter-steps', {
    assetNames,
    assetType: '3d-image',
    experiments: experimentKeys
  });

  return data.steps || [];
};

type PCDPanelStepsQueryParams = {
  assetNames: string[];
  experimentKeys: string[];
};

export function usePCDPanelSteps(
  { assetNames, experimentKeys }: PCDPanelStepsQueryParams,
  config: QueryConfig<AssetStep[]>
) {
  const configEnabled = config?.enabled ?? true;
  const conditionsEnabled = !!assetNames?.length && !!experimentKeys?.length;

  return useQuery(
    [
      'pcdPanelSteps',
      // copy and sort arrays to not trigger request every time order of parameters is changed
      {
        assetNames: assetNames.slice().sort(),
        experimentKeys: experimentKeys.slice().sort()
      }
    ],
    () => getPCDSteps({ assetNames, experimentKeys }),
    { ...config, enabled: configEnabled && conditionsEnabled }
  );
}
