import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import { axiosInstance, cancelWrapper } from '../api';

const getParameters = ({ experimentKeys, videos = [] }) => {
  // copy and sort arrays to not trigger request every time order of parameters is changed
  return {
    experiments: experimentKeys?.slice().sort(),
    videoNames: videos?.slice().sort()
  };
};

const getImagesSteps = async ({ parameters }) => {
  if (
    isEmpty(parameters.videoNames) ||
    parameters.videoNames.length === 0 ||
    isEmpty(parameters.experiments) ||
    parameters.experiments.length === 0
  ) {
    return null;
  }

  return cancelWrapper(async cancelToken => {
    return axiosInstance({
      cancelToken,
      data: parameters,
      method: 'post',
      url: 'video/filter-steps'
    });
  });
};

export default function useVideoSteps(data, config) {
  const parameters = getParameters({
    ...data
  });

  return useQuery(
    ['videosSteps', { parameters }],
    () => getImagesSteps({ parameters }),
    {
      ...config
    }
  );
}
