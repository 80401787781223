import React, { useCallback, useState } from 'react';

import { PencilIcon } from '@Icons-outdated';
import { Input } from '@DesignSystem/controllers';
import { ClickAwayListener } from '@material-ui/core';
import classNames from './DAGContent.module.scss';

type EditPencilFieldProps = {
  title: string;
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

export function EditPencilField({
  title,
  placeholder,
  value,
  setValue
}: EditPencilFieldProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const handleOutsideClick = useCallback(() => {
    if (isEditMode) {
      handleSubmit();
    }
  }, [handleSubmit, isEditMode]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleOutsideClick}>
      <span className={classNames.editFieldWrapper}>
        {isEditMode && (
          <Input
            data-test="pipeline-input-name"
            placeholder={placeholder}
            value={value}
            onChange={setValue}
            onKeyDown={handleKeyDown}
            className={classNames.nameInput}
          />
        )}
        {!isEditMode && value && (
          <span className={classNames.nameValueTitle}>{value}</span>
        )}
        {!isEditMode && !value && <span>{title}</span>}
        {!isEditMode && (
          <PencilIcon
            data-test="pencil-icon"
            onClick={() => {
              setIsEditMode(true);
            }}
          />
        )}
      </span>
    </ClickAwayListener>
  );
}
