// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RemainingTags-module__remainingTagsList--VZSjQ{padding:16px;display:flex;gap:6px;max-width:400px;min-width:300px;flex-wrap:wrap}.RemainingTags-module__remainingTagsList--VZSjQ>span{max-width:100%}.RemainingTags-module__remainingTagsContainer--GNI9X{cursor:pointer;display:flex;position:absolute;right:0;top:0;bottom:0;background:#fff;padding-left:4px;padding-right:4px;align-items:center;transition:opacity 100ms}.table-selection-row:hover .RemainingTags-module__remainingTagsContainer--GNI9X{background:#f7f8fa}.RemainingTags-module__container--KGz38{translate:0 6px;border-radius:5px;box-shadow:0 0 12px rgba(0,0,0,.12),0px 0px 1px rgba(0,0,0,.14);min-height:85px;max-height:500px}.RemainingTags-module__remainingTag--zAzh7{flex-shrink:0}.RemainingTags-module__remainingTag--zAzh7:first-child{flex-shrink:2}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"remainingTagsList": `RemainingTags-module__remainingTagsList--VZSjQ`,
	"remainingTagsContainer": `RemainingTags-module__remainingTagsContainer--GNI9X`,
	"container": `RemainingTags-module__container--KGz38`,
	"remainingTag": `RemainingTags-module__remainingTag--zAzh7`
};
module.exports = ___CSS_LOADER_EXPORT___;
