import React from 'react';
import PropTypes from 'prop-types';
import UpdateHyperParameterModal from './UpdateHyperParameterModal';
import DeleteParameterModal from './DeleteParameterModal';

const HyperParameterModals = ({
  isUpdateModalOpen,
  closeUpdateModal,
  isEdit,
  parameterKey,
  parameterValue,
  deleteParamName,
  closeDeleteParam,
  isNestedParamApplied
}) => {
  return (
    <>
      <UpdateHyperParameterModal
        open={isUpdateModalOpen}
        onClose={closeUpdateModal}
        isEdit={isEdit}
        parameterValue={parameterValue}
        parameterKey={parameterKey}
        isNestedParamApplied={isNestedParamApplied}
      />

      <DeleteParameterModal
        open={!!deleteParamName}
        onClose={closeDeleteParam}
        parameterKey={deleteParamName}
      />
    </>
  );
};

HyperParameterModals.propTypes = {
  isUpdateModalOpen: PropTypes.bool.isRequired,
  closeUpdateModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  parameterKey: PropTypes.string,
  parameterValue: PropTypes.string,
  closeDeleteParam: PropTypes.func.isRequired,
  deleteParamName: PropTypes.string,
  isNestedParamApplied: PropTypes.bool.isRequired
};

HyperParameterModals.defaultProps = {
  parameterKey: '',
  parameterValue: '',
  deleteParamName: ''
};

export default HyperParameterModals;
