// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssetNameCell-module__container--XKpdE{display:flex;width:140px;background-color:var(--white);padding-right:8px;align-items:center;position:sticky;left:0;z-index:200}.AssetNameCell-module__text--jtA5V{padding:4px 7px;background:var(--gray-1);border-radius:4px;cursor:default;font-size:12px;line-height:18px;color:var(--gray-6);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:132px;display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AssetNameCell-module__container--XKpdE`,
	"text": `AssetNameCell-module__text--jtA5V`
};
module.exports = ___CSS_LOADER_EXPORT___;
