const OAuthCallbackPage = () => {
  if (window.onLoginSuccess) {
    window.onLoginSuccess();
  }

  window.close();

  return null;
};

export default OAuthCallbackPage;
