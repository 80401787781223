import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniqBy from 'lodash/uniqBy';
import { Switch, Select } from '@DesignSystem/controllers';

import { Combobox } from '@ds';
import { LineChart } from '../LineChart';
import { TableLegend } from '@DesignSystem/data-display';
import { LineChartIcon } from '@Icons-outdated';
import {
  CHART_TYPES,
  DRIFT_ALGORITHMS_OPTIONS,
  PANEL_SECTIONS
} from '@mpm-druid/constants';
import './LineChartContainer.scss';
import { changeColorOpacity } from '@shared';

export const LineChartContainer = ({
  data,
  disableZoom,
  section,
  chartType,
  tabData,
  panelTitle,
  driftAlgorithm,
  driftAlgorithmType,
  availableDriftAlgorithms,
  setDriftAlgorithmType,
  checkedPercentCategorySwitch,
  handlePercentCategorySwitch,
  featureValue,
  setFeatureValue,
  featureValueOptions,
  driftFeatureOptions,
  driftFeaturesValue,
  setDriftFeaturesValue,
  driftFeaturesList,
  setDriftFeaturesList,
  ...props
}) => {
  const [handledPlotData, setHandledPlotData] = useState([]);

  const isCategoricalDistribution =
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL ||
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES;

  const isLinesFromFeatureDrift =
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE &&
    chartType === CHART_TYPES.STACKED_TO_LINES;

  const isDriftMultiFeatures =
    section === PANEL_SECTIONS.DATA_DRIFT ||
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED ||
    (section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE &&
      !isLinesFromFeatureDrift);

  const pillsData = useMemo(() => {
    if (!data) return [];
    if (data.length === 1 && data[0].query === 'all') return [];
    if (
      chartType === CHART_TYPES.LINE_WITH_PERCENTILE &&
      data.every(item => item.query === 'all')
    ) {
      return [];
    }
    if (
      chartType === CHART_TYPES.LINE ||
      chartType === CHART_TYPES.LINE_CATEGORICAL_BAR_DATA
    ) {
      const arr = uniqBy(
        data.map(trace => ({
          dotColor: trace.line?.color,
          text: trace.query || 'all',
          id: trace.predicateKey
        })),
        'text'
      );
      return arr;
    }

    if (chartType === CHART_TYPES.STACKED) {
      const arr = uniqBy(
        data.map(trace => ({
          dotColor: trace.line?.color,
          text: trace.name,
          id: trace.featureId
        })),
        'text'
      );
      return arr;
    }

    if (chartType === CHART_TYPES.STACKED_TO_LINES) {
      const arr = uniqBy(
        data.map(trace => ({
          dotColor: trace.line?.color,
          text: trace.query || 'all',
          id: trace.featureId
        })),
        'text'
      );
      return arr;
    }

    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      return uniqBy(
        data
          .filter(item => item.lineTrace === true)
          .map(trace => ({
            dotColor: trace.line?.color,
            text: trace.query || 'all',
            id: trace.predicateKey
          })),
        'text'
      );
    }

    return [];
  }, [data, chartType]);

  useEffect(() => {
    setHandledPlotData(data);
  }, [data, chartType]);

  const handleLegendHover = (id, text) => {
    let hoverData = handledPlotData.map(trace => {
      if (
        trace?.predicateKey === id ||
        trace?.featureId === id ||
        trace?.query === text
      ) {
        return trace;
      }

      return {
        ...trace,
        fillcolor: changeColorOpacity(trace.fillcolor, 0.1),
        line: {
          ...trace.line,
          color: changeColorOpacity(trace.line?.color, 0.2)
        }
      };
    });
    setHandledPlotData(hoverData);
  };

  const handleLegendUnhover = () => {
    setHandledPlotData(data);
  };

  if (!data?.length) {
    return (
      <div>
        <div className="line-chart-no-data-container">
          <LineChartIcon />
          <span>No data for this chart</span>
        </div>
      </div>
    );
  }
  return (
    <>
      {isCategoricalDistribution && (
        <div className="labeled-select-container">
          <Select
            label="Feature Value"
            horizontalLabel={true}
            options={featureValueOptions}
            value={featureValue}
            onChange={setFeatureValue}
            variant="outlined"
          />
        </div>
      )}
      <div className="drift-features-selector-wrapper">
        {availableDriftAlgorithms && (
          <div className="labeled-select-container">
            <Select
              label="Drift Metrics"
              options={DRIFT_ALGORITHMS_OPTIONS}
              value={driftAlgorithmType}
              onChange={setDriftAlgorithmType}
              variant="outlined"
              horizontalLabel={true}
            />
          </div>
        )}
        {isLinesFromFeatureDrift && (
          <div className="labeled-select-container">
            <Select
              label="Drift Feature Value"
              options={driftFeatureOptions}
              value={driftFeaturesValue}
              onChange={setDriftFeaturesValue}
              variant="outlined"
              horizontalLabel={true}
              hideSource
              withInput
            />
          </div>
        )}
        {isDriftMultiFeatures && (
          <div className="combobox-container">
            <span>Features</span>
            <Combobox
              options={driftFeatureOptions}
              value={driftFeaturesList}
              name="featuresList"
              onValueChange={selectedOptions => {
                if (selectedOptions?.length <= 10) {
                  setDriftFeaturesList(selectedOptions);
                }
              }}
            />
          </div>
        )}
      </div>

      {pillsData.length > 0 && {} && (
        <div className="line-chart-legend-container">
          <TableLegend
            onUnhover={handleLegendUnhover}
            onHover={handleLegendHover}
            pills={pillsData}
          />
        </div>
      )}

      <LineChart
        height={240}
        data={handledPlotData}
        disableZoom={disableZoom}
        section={section}
        featureValue={featureValue}
        panelTitle={panelTitle}
        tabData={tabData}
        chartType={chartType}
        driftAlgorithm={driftAlgorithm}
        variant="default"
        {...props}
      />
      {isCategoricalDistribution && (
        <div className="data-type-toggle">
          <span className="data-type-title">{'Percentages / Categories'} </span>
          <Switch
            checked={checkedPercentCategorySwitch}
            onChange={handlePercentCategorySwitch}
            color="primary"
          />
        </div>
      )}
    </>
  );
};

LineChartContainer.defaultProps = {
  data: [],
  percentileMode: '',
  onChangePercentileMode: noop,
  disableZoom: false,
  section: '',
  panelTitle: '',
  chartType: null,
  driftAlgorithmType: '',
  availableDriftAlgorithms: false,
  setDriftAlgorithmType: noop,
  checkedPercentCategorySwitch: false,
  handlePercentCategorySwitch: noop
};

LineChartContainer.propTypes = {
  data: PropTypes.array,
  chartType: PropTypes.string,
  percentileMode: PropTypes.string,
  onChangePercentileMode: PropTypes.func,
  disableZoom: PropTypes.bool,
  section: PropTypes.string,
  tabData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  panelTitle: PropTypes.string,
  driftAlgorithmType: PropTypes.string,
  availableDriftAlgorithms: PropTypes.boolean,
  setDriftAlgorithmType: PropTypes.func,
  checkedPercentCategorySwitch: PropTypes.bool,
  handlePercentCategorySwitch: PropTypes.func
};
