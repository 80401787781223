import { useQuery } from 'react-query';
import api from '@shared/api';
import outputUtil from '@/util/outputUtil';

const getExperimentRawData = async ({
  assetId,
  experimentKey,
  signal,
  type
}) => {
  if (type === 'asset') {
    if (!assetId) return null;

    const { data: asset } = await api.get('asset/download', {
      params: { assetId, experimentKey },
      signal
    });

    return outputUtil.formatGraphDefinitionOutput(asset);
  }

  if (type === 'code') {
    const {
      data: { code }
    } = await api.get('data-fetch/get/code', {
      params: { experimentKey },
      signal
    });

    return code;
  }

  if (type === 'graph') {
    const {
      data: { graph }
    } = await api.get('data-fetch/get/graph', {
      params: { experimentKey },
      signal
    });

    return outputUtil.formatGraphDefinitionOutput(graph);
  }

  if (type === 'installedPackages') {
    const {
      data: { packages }
    } = await api.get('data-fetch/get/installed-packages', {
      params: { experimentKey },
      signal
    });

    return packages?.join('\r\n');
  }

  if (type === 'output') {
    const {
      data: { stdout }
    } = await api.get('data-fetch/get/outputAll', {
      params: { experimentKey },
      signal
    });

    return stdout?.join('\r\n');
  }

  return null;
};

export default function useExperimentRawData(
  { assetId, experimentKey, type },
  config
) {
  return useQuery(
    ['experiment-raw-data', { assetId, experimentKey, type }],
    ({ signal }) => {
      return getExperimentRawData({
        assetId,
        experimentKey,
        signal,
        type
      });
    },
    { enabled: !!experimentKey, refetchOnMount: true, ...config }
  );
}
