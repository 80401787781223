import React from 'react';

import './EmptyStatePreviewBox.scss';

type EmptyStatePreviewBoxProps = {
  icon: React.ReactNode;
  message: string;
};

const EmptyStatePreviewBox = ({ icon, message }: EmptyStatePreviewBoxProps) => {
  return (
    <div className="empty-state-box">
      {icon}
      <span className="empty-state-box-message">{message}</span>
    </div>
  );
};

export default EmptyStatePreviewBox;
