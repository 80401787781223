// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridPanelSlider-module__container--XAUX5{min-width:150px;max-width:300px;width:100%;display:flex;justify-content:stretch;align-items:center;margin:0 16px;--slider-title-width: 30px;--slider-title-margin: 12px}.GridPanelSlider-module__container--XAUX5 .GridPanelSlider-module__title--qil42{flex-basis:var(--slider-title-width);margin-right:var(--slider-title-margin);font-weight:500;font-size:14px;color:var(--gray-6)}.GridPanelSlider-module__container--XAUX5 .GridPanelSlider-module__wrapper--VrcAM{flex-basis:calc(100% - var(--slider-title-width) - var(--slider-title-margin))}.GridPanelSlider-module__container--XAUX5 .GridPanelSlider-module__wrapper--VrcAM .value-label{min-width:65px;color:var(--black);justify-content:flex-start}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GridPanelSlider-module__container--XAUX5`,
	"title": `GridPanelSlider-module__title--qil42`,
	"wrapper": `GridPanelSlider-module__wrapper--VrcAM`
};
module.exports = ___CSS_LOADER_EXPORT___;
