import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import useColumns from '@API/project/useColumns';
import useExperimentsDetails from '@API/experiments/useExperimentsDetails';
import { EXPERIMENT_DETAILS_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import useProject from '@API/project/useProject';
import { trackEvent } from '@shared/utils/eventTrack';

import {
  getExperimentActive,
  getExperimentArchived
} from '@/experiment-management-shared/utils/experimentHelpers';
import { experimentEvents } from '@/constants/trackingEventTypes';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import { getIsExperimentArchivePage } from '@/selectors/routeSelectors';

import { APP_LOCATION } from '@/constants/generalConstants';
import { getIsUserLoggedInWithGitHub } from '@/reducers/userReducer';

import ExperimentDetails from '../ExperimentDetails';
import useExperimentHasNewPanelsMessage from './hooks/useExperimentHasNewPanelsMessage';
import useExperimentDetailsTemplateWrapper from '@experiment-details/hooks/useExperimentDetailsTemplateWrapper';

const SingleExperimentDetails = () => {
  const { experimentKey, projectName, workspace } = useParams();
  const { data: columns } = useColumns();
  const history = useHistory();
  const location = useLocation();

  const projectId = useSelector(getSelectedProjectId);
  const isArchive = useSelector(getIsExperimentArchivePage);
  const isUserLoggedIn = useSelector(getIsUserLoggedInWithGitHub);

  const { data: project } = useProject();

  const [refetchInterval, setRefetchInterval] = useState(null);
  const [sentBIEvent, setSentBIEvent] = useState(false);

  const templateWrapper = useExperimentDetailsTemplateWrapper(experimentKey);

  const experimentKeys = useMemo(() => (experimentKey ? [experimentKey] : []), [
    experimentKey
  ]);

  const {
    data: experimentsData,
    isLoading: isLoadingMetadata,
    isIdle: isIdleMetadata
  } = useExperimentsDetails(
    {
      experimentKeys,
      isArchive,
      projectId,
      template: templateWrapper.currentTemplate
    },
    {
      enabled: Boolean(projectId) && !templateWrapper.isLoadingProjectTemplate,
      refetchInterval,
      refetchOnMount: true
    }
  );

  const { experiment } = useMemo(() => {
    let localRun = experimentsData ? experimentsData[0] : null;

    // we check if page url corresponds to archive experiment status
    if (localRun) {
      localRun =
        getExperimentArchived(localRun) === isArchive ? localRun : null;
    }

    return {
      experiment: localRun
    };
  }, [experimentsData, isArchive]);

  useExperimentHasNewPanelsMessage({
    experimentKey,
    isActive: experiment?.runActive
  });

  const experiments = useMemo(() => {
    return experiment ? [experiment] : [];
  }, [experiment]);

  const handleDetailViewChange = useCallback(
    tabName => {
      const { viewId } = queryString.parse(location.search);
      const query = queryString.stringify({
        'experiment-tab': tabName,
        viewId
      });

      const locationState = location.state || {};

      history.push(`?${query}`, { preventScroll: true, ...locationState });

      trackEvent(experimentEvents.EXPERIMENT_DETAIL_TAB_SELECTED, {
        tab_selected: tabName.toUpperCase()
      });
    },
    [history, location]
  );

  useEffect(() => {
    // enable/disable metadata pooling depend on if experiment is active
    if (!refetchInterval && getExperimentActive(experiment)) {
      setRefetchInterval(EXPERIMENT_DETAILS_POLLING_LENGTH);
    } else if (refetchInterval && !getExperimentActive(experiment)) {
      setRefetchInterval(null);
    }
  }, [refetchInterval, experiment]);

  useEffect(() => {
    if (experiment && !sentBIEvent) {
      const {
        experimentKey: localExperimentKey,
        runActive,
        start_server_timestamp: startServerTimestamp
      } = experiment;

      trackEvent(experimentEvents.EXPERIMENT_DETAIL_VIEWED, {
        canEdit: project?.canEdit,
        createdAt: startServerTimestamp,
        experimentKeys: [localExperimentKey],
        isActive: runActive,
        isOwner: project?.isOwner,
        isPublic: project?.isPublic,
        isShared: project?.isShared,
        isUserLoggedIn,
        projectId,
        usedWhere: APP_LOCATION.SINGLE_EXPERIMENT
      });

      setSentBIEvent(true);
    }
  }, [sentBIEvent, experiment?.experimentKey]);

  return (
    <ExperimentDetails
      experimentKeys={experimentKeys}
      experiments={experiments}
      isArchive={isArchive}
      columns={columns}
      projectId={projectId}
      isLoadingMetadata={isLoadingMetadata}
      isIdleMetadata={isIdleMetadata}
      workspace={workspace}
      projectName={projectName}
      onDetailViewChange={handleDetailViewChange}
    />
  );
};

export default SingleExperimentDetails;
