import { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

const PLOTLY_RELAYOUT_EVENT = 'plotly_relayout';
const DEFAULT_ZOOM_DATA = { autosize: true };
const useChartZoomData = ({ graphDiv, setCurrentLayout, initialLayout }) => {
  const [zoomData, setZoomData] = useState(DEFAULT_ZOOM_DATA);

  const disableResetZoom = useMemo(() => {
    return (
      zoomData?.autosize ||
      (zoomData?.['xaxis.autorange'] && zoomData?.['yaxis.autorange'])
    );
  }, [zoomData]);

  useEffect(() => {
    // plotly charts get created from scratch all the time
    // so we don't need to unsubscribe
    if (graphDiv) {
      graphDiv.on(PLOTLY_RELAYOUT_EVENT, event => {
        // validation if event contains zoom related data
        if (
          event?.['xaxis.autorange'] ||
          event?.['yaxis.autorange'] ||
          event?.['xaxis.range[0]'] ||
          event?.['xaxis.range[1]'] ||
          event?.['yaxis.range[0]'] ||
          event?.['yaxis.range[1]']
        ) {
          setZoomData(event);
        }
      });
    }
  }, [graphDiv]);

  const handleResetZoom = useCallback(() => {
    if (!isEmpty(initialLayout)) {
      setCurrentLayout(null);
      setZoomData(DEFAULT_ZOOM_DATA);
    }
  }, [initialLayout, setCurrentLayout]);

  return { disableResetZoom, handleResetZoom };
};

export default useChartZoomData;
