import { useMemo } from 'react';
import {
  getCometMetadataType,
  getHighlightProperties
} from '@experiment-management-shared/components/Charts/Legends/helpers';
import useBaseChartTraceHighlight from '@experiment-management-shared/hooks/useBaseChartTraceHighlight';
import {
  PanelCometMetadataType,
  PanelHoverDataSource,
  PanelTrace,
  TooltipDataPoint
} from '@experiment-management-shared/types';

type UseCurvesChartTraceHighlightParams = {
  data: PanelTrace[];
  hoveredPoint?: TooltipDataPoint;
};

const useCurvesChartTraceHighlight = ({
  data,
  hoveredPoint = undefined
}: UseCurvesChartTraceHighlightParams) => {
  const { hoveredData, updateHoveredData } = useBaseChartTraceHighlight({
    hoveredPoint,
    tracesNumber: data?.length ?? 0
  });

  const dataWithHighlight = useMemo(() => {
    if (!hoveredData.source) return data;

    return data.map(trace => {
      const cometMetadataType = getCometMetadataType(trace);

      if (!cometMetadataType) return trace;

      const map: Record<
        PanelHoverDataSource,
        Record<PanelCometMetadataType, string[]>
      > = {
        global: {
          metric: ['experimentKey'],
          experiment_metric: [],
          group_by_metric: [],
          group_by_param: []
        },
        table: {
          metric: ['experimentKey'],
          experiment_metric: ['experimentKey'],
          group_by_metric: [],
          group_by_param: []
        },
        chart: {
          metric: ['experimentKey'],
          experiment_metric: ['metricName'],
          group_by_metric: [],
          group_by_param: []
        },
        legend: {
          metric: ['experimentKey', 'metricName'],
          experiment_metric: ['metricName'],
          group_by_metric: [],
          group_by_param: []
        }
      };

      const keys =
        map[hoveredData.source as PanelHoverDataSource]?.[cometMetadataType] ??
        [];

      return keys.length > 0
        ? {
            ...trace,
            ...getHighlightProperties(trace, hoveredData, keys)
          }
        : trace;
    });
  }, [data, hoveredData]);

  return {
    dataWithHighlight,
    hoveredData,
    updateHoveredData
  };
};

export default useCurvesChartTraceHighlight;
