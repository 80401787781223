import isEqual from 'lodash/isEqual';
import {
  MANAGEMENT_PERMISSIONS,
  MANAGEMENT_PERMISSIONS_SERVER
} from '../constants/permissions';

export const getPermissionStatusByKey = ({
  permissionKey,
  projectVisibilityStatus,
  inviteUsersStatus,
  managementStatus,
  onlyAdminsCanInviteOutsideOrganizationStatus
}) => {
  if (permissionKey === MANAGEMENT_PERMISSIONS.INVITE_USERS_FROM_ORGANIZATION) {
    return inviteUsersStatus || managementStatus;
  }

  if (
    permissionKey === MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE ||
    permissionKey === MANAGEMENT_PERMISSIONS.REMOVE_USERS_FROM_WORKSPACE ||
    permissionKey === MANAGEMENT_PERMISSIONS.REMOVE_YOURSELF_FROM_WORKSPACE
  ) {
    return managementStatus;
  }

  if (permissionKey === MANAGEMENT_PERMISSIONS.CHANGE_PROJECT_PRIVACY) {
    return projectVisibilityStatus || managementStatus;
  }

  if (
    permissionKey === MANAGEMENT_PERMISSIONS.INVITE_USERS_OUT_OF_ORGANIZATION
  ) {
    if (onlyAdminsCanInviteOutsideOrganizationStatus) {
      return false;
    }

    return inviteUsersStatus || managementStatus;
  }

  console.error(`${permissionKey} is not considered for the system`);
  return false;
};

export const getKeyForRemovingFromWorkspace = (username, currentUsername) =>
  currentUsername === username
    ? MANAGEMENT_PERMISSIONS.REMOVE_YOURSELF_FROM_WORKSPACE
    : MANAGEMENT_PERMISSIONS.REMOVE_USERS_FROM_WORKSPACE;

export const getKeyForChangingRole = (username, currentUsername) =>
  currentUsername === username
    ? MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE_FOR_YOURSELF
    : MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE;

export const getPermissionByType = (userPermissions = [], type) => {
  if (!Array.isArray(userPermissions)) return [];
  // if unkown type of permissions return empty
  if (!Object.values(MANAGEMENT_PERMISSIONS_SERVER).includes(type)) return [];

  const permission = userPermissions?.filter(
    ({ permissionName }) => permissionName === type
  );

  return (permission && permission[0]) || {};
};

export const getUserUniqueName = user => user?.username || user?.email;

export const updatePermissionByType = (userPermissions = [], type, value) =>
  userPermissions?.map(permission => {
    if (permission?.permissionName === type) {
      return {
        ...permission,
        permissionValue: value
      };
    }

    return permission;
  });

export const getPermissionValueByType = (userPermissions, type) =>
  getPermissionByType(userPermissions, type)?.permissionValue;

export const ifPermissionValueTruthful = permissionValue =>
  permissionValue === 'true';

export const isUserPermissionValid = ({ permissionValue }) =>
  ifPermissionValueTruthful(permissionValue);

export const formatPermissionValueToString = permissionValue =>
  permissionValue ? 'true' : 'false';

export const formatPermissionValueToBoolean = permissionValue =>
  permissionValue === 'true';

export const updateUserPermissionsByUserNameInList = (
  permissions = [],
  username,
  newPermissions = []
) => {
  if (!Array.isArray(permissions)) return [];

  return permissions.map(({ userName, permissions: permissionsInside }) => {
    if (username === userName) {
      return {
        userName,
        permissions: newPermissions
      };
    }

    return { userName, permissions: permissionsInside };
  });
};

export const getUserPermissionsByUserName = (permissions = [], username) => {
  if (!Array.isArray(permissions)) return [];

  const foundPermissionsByUser =
    (permissions?.length &&
      permissions?.find(({ userName }) => username === userName)) ||
    {};

  return foundPermissionsByUser?.permissions || [];
};

// ifCheckManagementPermissionsEquality is flag, that says
// to use if we need to check, whether the MANAGEMENT permission was changed first, and if not
// function will return that permissions are equal
// if you need to check the deep e  equality of permissions, don't pass the third argument
export const ifPermissionsEqual = (
  oldPermissions = [],
  newPermissions = [],
  ifCheckManagementPermissionsEquality
) => {
  const isEqualPermissions = isEqual(oldPermissions, newPermissions);

  if (ifCheckManagementPermissionsEquality && !isEqualPermissions) {
    const oldWorkspaceManagement = getPermissionValueByType(
      oldPermissions,
      MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
    );
    const newWorkspaceManagement = getPermissionValueByType(
      newPermissions,
      MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
    );

    // check first, if permissions for workspace owner was changed, if not return that we didn't change them
    return (
      oldWorkspaceManagement === newWorkspaceManagement && isEqualPermissions
    );
  }

  return isEqualPermissions;
};
