import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';

import {
  PANEL_CODE_LANGUAGES,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';

import ChartNameField from '@experiment-management-shared/components/ChartNameField';
import visualizationsActions from '@/actions/visualizationsActions';
import { DISABLE_PUBLIC_ACCESS } from '@/constants/configConstants';
import { getTemplateIdFromURL } from '@experiment-management-shared/utils/visualizationsHelper';
import {
  getCodeLanguagePreview,
  getCurrentTemplate
} from '@/reducers/visualizationsReducer';
import { getMutableScopeType } from '@/reducers/ui/visualizationsUiReducer';
import { getTemplateName } from '@/selectors/visualizationSelectors';

import Select from '@shared/components/Select';
import { useIsServerCustomPanelsEnabled } from '@experiment-management-shared';

const languageOptions = [
  { label: 'Python', value: PANEL_CODE_LANGUAGES.PYTHON },
  { label: 'Javascript', value: PANEL_CODE_LANGUAGES.JAVASCRIPT }
];

const { INTERNAL, PUBLIC, PRIVATE } = TEMPLATE_SCOPE_TYPES;

const WIDTHS = [2, 3, 4, 6];
const HEIGHTS = [1, 2, 3];

const SIZES = WIDTHS.map(w => {
  return HEIGHTS.map(h => {
    return {
      label: `${w}x${h}`,
      value: { w, h }
    };
  });
}).flat();

const parseDims = (w, h) => {
  const size = find(SIZES, s => s.value.w === w && s.value.h === h);
  if (!size) return SIZES[0];

  return size;
};

const parseDefaultDims = template => {
  const defaultConfig = template.code.defaultConfig
    ? JSON.parse(template.code.defaultConfig)
    : null;

  if (!defaultConfig || !defaultConfig.w || !defaultConfig.h) {
    return SIZES[0];
  }

  return parseDims(defaultConfig.w, defaultConfig.h);
};

const VizEditorHeader = ({
  currentTemplate,
  dispatch,
  mutableScopeType,
  templateName,
  currentCodeLanguage,
  templateId
}) => {
  const defaultDims = parseDefaultDims(currentTemplate);
  const [dims, setDims] = useState(defaultDims);
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();
  const isPy = currentCodeLanguage === PANEL_CODE_LANGUAGES.PYTHON;

  const hidePublicScope = isPy && isServerCustomPanelsEnabled;

  const currentScopeType = isEmpty(mutableScopeType)
    ? currentTemplate.scopeType
    : mutableScopeType;

  const currentLanguageOption = languageOptions[isPy ? 0 : 1];

  const handleScopeType = event => {
    dispatch(visualizationsActions.setTemplateScopeType(event.target.value));
  };

  useEffect(() => {
    if (hidePublicScope && currentScopeType !== PRIVATE) {
      dispatch(visualizationsActions.setTemplateScopeType(PRIVATE));
    }
  }, [dispatch, currentScopeType, hidePublicScope]);

  const handleCodeLanguage = ({ value }) => {
    dispatch(visualizationsActions.setCodePreviewLanguage(value));
    dispatch(visualizationsActions.runCodePreview(templateId));
  };

  const handleUpdateTemplateName = newTemplateName => {
    dispatch(visualizationsActions.updateTemplateNameField(newTemplateName));
  };

  const handleChangeDims = ({ label, value }) => {
    dispatch(
      visualizationsActions.setDefaultConfig({
        ...value
      })
    );
    dispatch(visualizationsActions.setIsTemplateSaved(false));
    setDims({ label, value });
  };

  const renderScopeTypeToggle = () => {
    const isFeaturedPanel = INTERNAL === currentScopeType;

    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="position"
          onChange={handleScopeType}
          value={isFeaturedPanel ? PUBLIC : currentScopeType}
        >
          <FormControlLabel
            value={PRIVATE}
            control={<Radio color="primary" />}
            disabled={isFeaturedPanel}
            label="Private"
          />
          {!hidePublicScope && (
            <FormControlLabel
              value={PUBLIC}
              control={<Radio color="primary" />}
              disabled={isFeaturedPanel}
              label="Public"
            />
          )}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderLanguageSelect = () => {
    return (
      <Select
        value={currentLanguageOption}
        onChange={handleCodeLanguage}
        options={languageOptions}
        isSearchable={false}
        isClearable={false}
      />
    );
  };

  const renderSizeSelect = () => {
    return (
      <>
        <div className="viz-editor-header-settings">Default Size:</div>
        <div className="viz-editor-header-settings-dropdown">
          <Select
            value={dims}
            onChange={handleChangeDims}
            options={SIZES}
            isSearchable={false}
            isClearable={false}
          />
        </div>
      </>
    );
  };

  return (
    <div className="viz-editor-header">
      <div className="viz-editor-header-left">
        <div className="viz-editor-header-logo">
          <img src="/images/logo_comet_light.png" />
        </div>

        <div className="viz-editor-header-separator" />

        <div className="viz-editor-header-name">
          <div className="viz-editor-header-name-label">Panel name</div>
          <div className="viz-editor-header-name-field">
            <ChartNameField
              placeholder={`Default ${PANEL_ENTITY_NAME} name`}
              chartNameEditHandler={handleUpdateTemplateName}
              currentName={templateName}
              isCodeEditor
            />
          </div>
        </div>
      </div>

      <div className="viz-editor-header-right">
        {renderSizeSelect()}
        {currentCodeLanguage && (
          <>
            <div className="viz-editor-header-settings">Language:</div>
            <div className="viz-editor-header-settings-dropdown">
              {renderLanguageSelect()}
            </div>
          </>
        )}
        {!DISABLE_PUBLIC_ACCESS && (
          <>
            <div className="viz-editor-header-settings">Settings:</div>
            <div className="viz-editor-header-settings-toggle">
              {renderScopeTypeToggle()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

VizEditorHeader.propTypes = {
  currentTemplate: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  mutableScopeType: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  currentCodeLanguage: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const { codeTemplateId, revisionId } = props.match.params;
  const templateId = getTemplateIdFromURL(codeTemplateId);

  return {
    currentTemplate: getCurrentTemplate(state, { templateId }),
    mutableScopeType: getMutableScopeType(state),
    currentCodeLanguage: getCodeLanguagePreview(state),
    templateId,
    templateName: getTemplateName(state, { templateId, revisionId })
  };
};

export default withRouter(connect(mapStateToProps)(VizEditorHeader));
