import React from 'react';
import PropTypes from 'prop-types';
import { goBack } from '@shared/utils/url';
import { isIframed } from '@/helpers/generalHelpers';
import LoginModal from '@auth/components/LoginModal';
import useErrorMessageParam from '@/helpers/custom-hooks/useErrorMessageParam';

const LoginPage = ({ workspaceCode }) => {
  useErrorMessageParam();
  const isInIframe = isIframed();

  return (
    <div className="full-page-view login-page">
      <LoginModal
        cancelText="Back"
        onClose={goBack}
        isModal={false}
        open
        showLogin
        showRegister={false}
        workspaceCode={workspaceCode}
        DialogProps={{
          disableBackdropClick: true,
          hideBackdrop: true,
          disableAutoFocus: isInIframe,
          disableEnforceFocus: isInIframe
        }}
      />
    </div>
  );
};

LoginPage.defaultProps = {
  workspaceCode: null
};

LoginPage.propTypes = {
  workspaceCode: PropTypes.string
};

export default LoginPage;
