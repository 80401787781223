import RawData from '@experiment-details/components/RawData';
import ReportsRoutes from '@reports';
import { REPORTS_BASE } from '@routes/constants/reports';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import projectsActions from '@/actions/projectsActions';
import DashboardRoutes from './DashboardRoutes';

const ProjectRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const { projectName, workspace } = match.params;

  useEffect(() => {
    return () => {
      dispatch(projectsActions.clearProjectsUi());
    };
  }, [dispatch, projectName, workspace]);

  return (
    <Switch>
      <Route
        path={[
          `${match.path}/:experimentKey/raw/:type`,
          `${match.path}/:experimentKey/:assetId/raw/:type`
        ]}
        exact
        component={RawData}
      />
      <Route component={ReportsRoutes} path={REPORTS_BASE} />
      <Route component={DashboardRoutes} path={match.path} />
    </Switch>
  );
};

ProjectRoutes.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ProjectRoutes);
