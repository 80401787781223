import {
  Completion,
  CompletionContext,
  CompletionResult
} from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';
import { SyntaxNode } from '@lezer/common';
import { EditorState } from '@uiw/react-codemirror';
import { SUGGESTIONS } from './suggestions';
import { getArgumentNumber, isLabelColumn, isPredictionColumn } from './utils';

function getNodesFromPosition(state: EditorState, position: number) {
  const tree = syntaxTree(state);
  const nodes: SyntaxNode[] = [];

  tree.iterate({
    enter: node => {
      if (node.from <= position && position <= node.to) {
        nodes.push(node.node);
      }
    }
  });

  return {
    names: nodes.map(node => node.name),
    nodes
  };
}

function getNodeBeforeCursorIgnoringWhitespace(
  state: EditorState,
  cursorPos: number
) {
  const doc = state.doc;
  let lastNonWhitespacePos = cursorPos;

  while (
    lastNonWhitespacePos > 0 &&
    /\s/.test(doc.sliceString(lastNonWhitespacePos - 1, lastNonWhitespacePos))
  ) {
    lastNonWhitespacePos -= 1;
  }

  return getNodesFromPosition(state, lastNonWhitespacePos);
}

function getLastValidNode(nodes: SyntaxNode[]) {
  return [...nodes].reverse().find(node => node.type.id !== 0);
}

function getChildren(node: SyntaxNode | null) {
  const children: SyntaxNode[] = [];

  let nextChild = node?.firstChild;
  while (nextChild) {
    children.push(nextChild);
    nextChild = nextChild.nextSibling;
  }

  return children;
}

function getChildrenNames(node: SyntaxNode | null) {
  return getChildren(node).map(node => node.name);
}

function last<T>(arr: T[]): T {
  return arr[arr.length - 1];
}

export const sqlCompletionSource = (columnNames: string[], debug = false) => {
  return (context: CompletionContext): CompletionResult | null => {
    const { state, pos } = context;
    const {
      nodes: currentNodes,
      names: currentNodeNames
    } = getNodesFromPosition(state, pos);
    const {
      nodes: previousNodes,
      names: previousNodeNames
    } = getNodeBeforeCursorIgnoringWhitespace(state, pos);
    const currentValidNode = getLastValidNode(currentNodes);
    const previousValidNode = getLastValidNode(previousNodes);

    if (debug) {
      console.info(
        'children names',
        last(currentNodes).parent?.name,
        getChildrenNames(last(currentNodes).parent)
      );
      console.info('node names', currentNodeNames);
      console.info('previous names', previousNodeNames);
      console.info('current valid node', currentValidNode?.name);
      console.info('previous valid node', previousValidNode?.name);
    }

    if (!previousValidNode || !currentValidNode) return null;

    const completions: Completion[] = [];
    const completionResult = { from: pos, options: completions };

    const keywordReplaceNode =
      last(currentNodes).type.id === 0 ? last(currentNodes) : null;

    if (previousValidNode.name === 'Query') {
      completions.push(SUGGESTIONS.SELECT(keywordReplaceNode));
    }
    if (
      previousValidNode.name === 'SELECT' &&
      currentValidNode.name !== 'SELECT'
    ) {
      completions.push(...Object.values(SUGGESTIONS.ComputeNumericFunction));
    }
    if (
      previousValidNode.name === 'ColumnName' &&
      currentValidNode.name === 'ColumnName'
    ) {
      const query = state.doc
        .sliceString(previousValidNode.from, pos)
        .replace(/"/g, '');

      let filteredColumnNames = columnNames;

      if (
        currentValidNode.parent?.name === 'CategoricalCometFunction' ||
        currentValidNode.parent?.name === 'NumericalCometFunction'
      ) {
        const argumentNumber = getArgumentNumber(currentValidNode);

        if (argumentNumber === 0) {
          filteredColumnNames = columnNames.filter(isLabelColumn);
        } else if (argumentNumber === 1) {
          filteredColumnNames = columnNames.filter(isPredictionColumn);
        }
      }

      completions.push(
        ...SUGGESTIONS.ColumnName(filteredColumnNames, previousValidNode, query)
      );
    }
    if (previousValidNode.name === 'All') {
      completions.push(SUGGESTIONS.All(previousValidNode));
    }
    if (currentValidNode.name === 'ComputeFunction') {
      if (previousValidNode.name === 'MathOperator') {
        completions.push(...Object.values(SUGGESTIONS.ComputeNumericFunction));
      } else if (currentValidNode.parent?.name === 'CaseWhenExpression') {
        const caseWhenChildrenNames = getChildrenNames(currentValidNode.parent);

        if (caseWhenChildrenNames.includes('ELSE')) {
          completions.push(SUGGESTIONS.END(keywordReplaceNode));
        } else {
          completions.push(SUGGESTIONS.ELSE(keywordReplaceNode));
        }
      } else {
        const firstNonComputeFunctionNode = [...currentNodes]
          .filter(node => node.type.id !== 0)
          .reverse()
          .find(node => node.name !== 'ComputeFunction');

        if (firstNonComputeFunctionNode?.name === 'SelectStatement') {
          completions.push(SUGGESTIONS.FROM(keywordReplaceNode));
          completions.push(...SUGGESTIONS.MathOperators(keywordReplaceNode));
        }
      }
    }
    if (
      currentValidNode.name === 'SelectStatement' &&
      previousValidNode.name === 'FROM'
    ) {
      completions.push(SUGGESTIONS.MODEL(keywordReplaceNode));
    }
    if (
      previousValidNode.name === 'MODEL' &&
      currentValidNode.name !== 'MODEL'
    ) {
      completions.push(SUGGESTIONS.WHERE(keywordReplaceNode));
    }
    if (
      previousValidNode.name === 'WHERE' &&
      currentValidNode.name === 'WhereClause'
    ) {
      completions.push(
        ...SUGGESTIONS.ColumnName(columnNames, keywordReplaceNode)
      );
    }
    if (currentValidNode.name === 'WhereCondition') {
      if (previousValidNode.name === 'ColumnName') {
        completions.push(...SUGGESTIONS.WhereOperators(keywordReplaceNode));
      }
    }
    if (currentValidNode.name === 'CaseWhenExpression') {
      if (previousValidNode.name === 'CASE') {
        completions.push(SUGGESTIONS.WHEN(keywordReplaceNode));
      } else if (previousValidNode.name === 'WHEN') {
        completions.push(
          ...SUGGESTIONS.ColumnName(columnNames, keywordReplaceNode)
        );
        // + numeric operators (abs ...)
      } else if (previousNodeNames.includes('WhereCondition')) {
        completions.push(SUGGESTIONS.THEN(keywordReplaceNode));
      } else if (
        previousValidNode.name === 'THEN' ||
        previousValidNode.name === 'ELSE'
      ) {
        completions.push(...Object.values(SUGGESTIONS.NumericOperators));
        // + numeric operators (abs ...)
      }
    }
    if (currentValidNode.name === 'MultipleWhereCondition') {
      completions.push(...Object.values(SUGGESTIONS.NumericOperators));
    }
    if (currentValidNode.parent?.name === 'NumericOperator') {
      completions.push(...Object.values(SUGGESTIONS.NumericOperators));
      completions.push(
        ...SUGGESTIONS.ColumnName(columnNames, keywordReplaceNode)
      );
    }

    return completions.length > 0 ? completionResult : null;
  };
};
