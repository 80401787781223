import { useMutation, useQueryClient } from 'react-query';

import api from '@API/api';

import useProject from '@API/project/useProject';

const upsertQuerySegment = ({ rulesTree, name, projectId }) => {
  return api.post('query/filters', {
    name,
    rulesTree,
    projectId
  });
};

export default function useCreateFilterMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ rulesTree, name }) => upsertQuerySegment({ rulesTree, name, projectId }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['filters', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['filters', { projectId }]);
      }
    }
  );
}
