import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const projectsApi = {
  validateProjectName(data) {
    return axios
      .post(`${BASE_API_URL}projects/validate-name`, JSON.stringify(data))
      .then(res => res.data);
  },
  fetchProjectDataByOwner(owner, projectName) {
    const url = `${BASE_API_URL}projects/get/projectByName?owner=${owner}&projectName=${projectName}`;
    return axios.get(url);
  },
  fetchProjectById(projectId) {
    const url = `${BASE_API_URL}projects/get/project?projectId=${projectId}`;
    return axios.get(url);
  },
  fetchWorkspaceProjects(workspace) {
    const url = `${BASE_API_URL}projects/getAll?workspace=${workspace}`;
    return axios.get(url);
  },
  createProjects(projectParams) {
    const url = `${BASE_API_URL}projects/new`;
    return axios.post(url, JSON.stringify(projectParams));
  },
  updateProject(updatedProject) {
    const url = `${BASE_API_URL}projects/update`;
    return axios.post(url, updatedProject);
  },
  changeProjectsPrivacy(data) {
    const url = `${BASE_API_URL}projects/privacy`;
    return axios.post(url, JSON.stringify(data));
  },
  addShareableLink(projectId) {
    const url = `${BASE_API_URL}share-project/add-share-link?projectId=${projectId}`;
    return axios.get(url);
  },
  getShareableLinks(projectId) {
    const url = `${BASE_API_URL}share-project/get-project-share-links?projectId=${projectId}`;
    return axios.get(url);
  },
  deleteShareableLink(projectId, shareCode) {
    const url = `${BASE_API_URL}share-project/delete-project-share-link?projectId=${projectId}&shareCode=${shareCode}`;
    return axios.get(url);
  },
  moveExperiments({ projectId, experimentKeys }) {
    const url = `${BASE_API_URL}projects/moveExperiments`;
    return axios.post(url, {
      projectId,
      experimentKeys
    });
  },
  addSymlinks({ projectId, experimentKeys }) {
    const url = `${BASE_API_URL}projects/addSymlinks`;
    return axios.post(url, {
      projectId,
      experimentKeys
    });
  }
};

export default projectsApi;
