import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import last from 'lodash/last';
import values from 'lodash/values';

import { Button, IconButton, Tooltip } from '@ds';
import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import WarningIcon from '@material-ui/icons/Warning';
import { Loader } from 'react-loaders';

import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { RESOURCE_TYPES } from '@experiment-management-shared/constants/visualizationConstants';

import Select from '@shared/components/Select';
import { getInternalResourceVersionMap } from '@/reducers/ui/visualizationsUiReducer';
import StyledSwitch from '@shared/components/StyledComponents/StyledSwitch';
import alertsUtil from '@/util/alertsUtil';
import { DSCopyIcon, DSDeleteIcon, DSPlusIcon } from '@ds-icons';

const resourceTypeOptions = values(RESOURCE_TYPES);

const ResourcesTab = ({
  dispatch,
  internalResources,
  userResources,
  updateResourceHandler,
  versionMap
}) => {
  const { JAVASCRIPT } = RESOURCE_TYPES;

  const [pastedUrl, setPastedUrl] = useState('');
  const [CDNMap, setCDNMap] = useState({});
  const [selectedResourceType, setSelectedResourceType] = useState(JAVASCRIPT);

  const isDuplicateResource = userResources.some(
    resource => resource.url === pastedUrl
  );

  const updateInternalResourceByKey = (resourceName, key, eventValue) => {
    const updatedResources = internalResources.map(resource => {
      if (resource.name === resourceName) {
        return { ...resource, [key]: eventValue };
      }
      return resource;
    });

    updateResourceHandler('internalResources', updatedResources);
  };

  const handleEnableSwitch = name => event => {
    updateInternalResourceByKey(name, 'enabled', event.target.checked);
  };

  const handleVersionChange = (name, selectedOption) => {
    updateInternalResourceByKey(name, 'version', selectedOption.value);
  };

  const handleResourceTypeSelect = selectedOption => {
    setSelectedResourceType(selectedOption);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !isDuplicateResource) {
      handleAddUserResource();
    }
  };

  const handleAddUserResource = () => {
    setPastedUrl('');

    setCDNMap(prevState => ({
      ...prevState,
      [pastedUrl]: {
        isWaiting: true,
        isValidated: false
      }
    }));

    const updatedResources = uniqBy(
      [...userResources].concat({
        url: pastedUrl,
        type: selectedResourceType.value
      }),
      'url'
    );

    updateResourceHandler('userResources', updatedResources);
  };

  const handleDeleteUserResource = resourceToDelete => {
    const updatedResources = userResources.filter(
      resource => resource.url !== resourceToDelete
    );

    updateResourceHandler('userResources', updatedResources);
  };

  const renderSwitch = (resourceName, isResourceEnabled) => {
    return (
      <StyledSwitch
        checked={isResourceEnabled}
        onChange={handleEnableSwitch(resourceName)}
        color="primary"
      />
    );
  };

  const renderVersionField = (resourceName, version) => {
    const options = Object.keys(versionMap[resourceName]).map(version => {
      return formatValueForSelectOption(version);
    });

    return (
      <Select
        value={formatValueForSelectOption(version)}
        onChange={selectedOption =>
          handleVersionChange(resourceName, selectedOption)
        }
        options={options}
      />
    );
  };

  const renderUserResourceTypeSelect = () => {
    return (
      <Select
        className="user-resource-type-select"
        value={selectedResourceType}
        onChange={handleResourceTypeSelect}
        options={resourceTypeOptions}
        menuPlacement="top"
      />
    );
  };

  const renderAddUserResourceField = () => {
    return (
      <div className="add-resource-container">
        <div className="add-resource-header">Additional Resources</div>
        <div className="resource-field">
          <InputBase
            className="input-base resource-input"
            placeholder={`Paste ${selectedResourceType.label} URL`}
            onChange={event => setPastedUrl(event.target.value)}
            onKeyDown={handleKeyDown}
            value={pastedUrl}
          />
          {renderUserResourceTypeSelect()}
          {isDuplicateResource ? (
            <Tooltip
              placement="right"
              content="This resource has already been added"
            >
              <div className="warning-icon">
                <WarningIcon />
              </div>
            </Tooltip>
          ) : (
            <Button
              onClick={handleAddUserResource}
              disabled={isEmpty(pastedUrl)}
              PrefixIcon={<DSPlusIcon />}
            >
              Add
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderCDNValidationIcon = url => {
    if (!CDNMap[url] || CDNMap[url].isWaiting) {
      return (
        <div className="icon-loader-container">
          <Loader
            type="ball-scale-multiple"
            color="#5055f5"
            innerClassName="icon-loader"
          />
        </div>
      );
    }

    const { isValidated } = CDNMap[url];

    const icon = isValidated ? 'check_circle' : 'cancel';

    return (
      <Tooltip
        content={!isValidated ? 'This URL is invalid' : ''}
        placement="top"
      >
        <i
          className={cx('cdn-validate-icon material-icons', {
            'is-valid': isValidated
          })}
        >
          {icon}
        </i>
      </Tooltip>
    );
  };

  const handleValidateCDN = url => {
    return axios({
      url,
      method: 'get',
      withCredentials: false
    })
      .then(({ status }) => {
        if (status === 200) {
          setCDNMap(prevState => ({
            ...prevState,
            [url]: {
              isWaiting: false,
              isValidated: true
            }
          }));
        }
      })
      .catch(() => {
        setCDNMap(prevState => ({
          ...prevState,
          [url]: {
            isWaiting: false,
            isValidated: false
          }
        }));
      });
  };

  const renderCopyURLBtn = url => {
    return (
      <Tooltip content={`Click to copy ${url}`} placement="top">
        <CopyToClipboard text={url}>
          <IconButton
            type="secondary"
            Icon={<DSCopyIcon />}
            onClick={() => dispatch(alertsUtil.openCopySuccessSnackbar())}
          />
        </CopyToClipboard>
      </Tooltip>
    );
  };

  const renderInternalResourcesTable = () => {
    return (
      <Table className="resources-table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {internalResources.map(resource => {
            const { name, version, enabled } = resource;
            const url = get(versionMap, [name, version], '');

            return (
              <TableRow key={name}>
                <TableCell>{name}</TableCell>
                <TableCell>{renderVersionField(name, version)}</TableCell>
                <TableCell>{renderSwitch(name, enabled)}</TableCell>
                <TableCell>{renderCopyURLBtn(url)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const renderUserResourcesTable = () => {
    if (isEmpty(userResources)) return null;

    return (
      <Table className="resources-table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Valid</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {userResources.map(resource => {
            const { url, type } = resource;

            if (!CDNMap[url] || CDNMap[url].isWaiting) {
              handleValidateCDN(url);
            }

            const name = last(url.split('/')) || url;

            return (
              <TableRow key={url}>
                <TableCell className="url-cell">{name}</TableCell>
                <TableCell>{RESOURCE_TYPES[type].label}</TableCell>
                <TableCell>{renderCDNValidationIcon(url)}</TableCell>
                <TableCell>
                  <div className="resource-tab-actions">
                    {renderCopyURLBtn(url)}

                    <Tooltip content="Click to remove resource" placement="top">
                      <IconButton
                        type="secondary"
                        onClick={() => handleDeleteUserResource(url)}
                        Icon={<DSDeleteIcon />}
                      />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className="resources-tab-container">
      <div className="resource-list-container">
        {renderInternalResourcesTable()}
        {renderUserResourcesTable()}
      </div>
      {renderAddUserResourceField()}
    </div>
  );
};

ResourcesTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  internalResources: PropTypes.array.isRequired,
  updateResourceHandler: PropTypes.func.isRequired,
  userResources: PropTypes.array.isRequired,
  versionMap: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    versionMap: getInternalResourceVersionMap(state)
  };
};

export default connect(mapStateToProps)(ResourcesTab);
