import React from 'react';
import { EmptyExperimentTab } from '@shared';
import classes from './InstalledPackagesTab.module.scss';

const InstalledPackagesTabEmpty = () => (
  <EmptyExperimentTab className={classes.emptyMessage}>
    <EmptyExperimentTab.Icon iconName="installedPackages" />
    <EmptyExperimentTab.Title>
      This experiment did not report any installed packages
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      Enable <b>log_env_details</b> on{' '}
      <EmptyExperimentTab.Link path="api-and-sdk/python-sdk/reference/Experiment/#experimentinit">
        experiment creation
      </EmptyExperimentTab.Link>
      , or use <b>set_pip_packages()</b> on your Experiment object to capture
      installed packages.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentset_pip_packages" />
  </EmptyExperimentTab>
);

export default InstalledPackagesTabEmpty;
