import { FeatureColumnType } from '../types/common';

export const DAILY_CRON_VALUE = `0 0 0 * * ?`;
export const HOURLY_CRON_VALUE = `0 0 * * * ?`;

export const uniqueRowIdKey = 'id';

export const DEFAULT_ALERT_NOTIFICATIONS_SORTING = [
  { columnName: 'notificationDate', direction: 'asc' }
];

export const DEFAULT_ALERT_RULES_SORTING = [
  { columnName: 'createdDate', direction: 'asc' }
];

export const ALERT_NOTIFICATIONS_STATUSES = ['open', 'resolved'];

export const ALERT_NOTIFICATIONS_COLUMNS: FeatureColumnType[] = [
  {
    name: 'name',
    title: 'NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'ruleName',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'status',
    title: 'STATUS',
    id: 'status--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'status',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'notificationDate',
    title: 'NOTIFICATION DATE',
    id: 'notificationDate--metadata',
    type: 'datetime',
    source: 'metadata',
    serverField: 'createdAt',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'trigger',
    title: 'TRIGGER',
    id: 'trigger--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'ruleId',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'modelVersion',
    title: 'VERSION',
    id: 'modelVersion--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'modelVersion',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'intervalType',
    title: 'FREQUENCY',
    id: 'intervalType-metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'intervalType',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const ALERT_RULES_COLUMNS: FeatureColumnType[] = [
  {
    name: 'name',
    title: 'NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'name',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'openNotifications',
    title: 'OPEN NOTIFICATIONS',
    id: 'openNotifications--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'createdAt',
    title: 'CREATED DATE',
    id: 'createdAt--metadata',
    type: 'datetime',
    source: 'metadata',
    serverField: 'createdAt',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'modelVersion',
    title: 'VERSION',
    id: 'modelVersion--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'modelVersion',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'intervalType',
    title: 'FREQUENCY',
    id: 'intervalType--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'intervalType',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'createdBy',
    title: 'CREATED BY',
    id: 'createdBy--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'createdBy',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'description',
    title: 'DESCRIPTION',
    id: 'description--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'description',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const ALERT_NOTIFICATIONS_LEFT_COLUMN = ['name'];

export const ALERT_NOTIFICATIONS_RIGHT_COLUMN = ['actions'];

export const ALERT_RULES_LEFT_COLUMN = ['name'];

export const ALERT_NOTIFICATIONS_PAGE_SIZES = [10, 25, 50, 100];

export const ALERT_NOTIFICATIONS_PREVIEW_PAGE_SIZES = 5;

export const ALERT_RULES_PAGE_SIZES = [10, 25, 50, 100];

export const ALERT_RULES_PREVIEW_PAGE_SIZES = 5;

export const ALERT_NOTIFICATIONS_NAME_COLUMN_WIDTH = 298;

export const ALERT_RULES_NAME_COLUMN_WIDTH = 298;

export const disabledTableSortingColumns = (columns: FeatureColumnType[]) =>
  columns
    .filter(column => column.hideForSorting)
    .map(column => ({
      columnName: column.name,
      sortingEnabled: false
    }));

export const disabledNotificationSortingColumns = disabledTableSortingColumns(
  ALERT_NOTIFICATIONS_COLUMNS
);

export const disabledRulesSortingColumns = disabledTableSortingColumns(
  ALERT_RULES_COLUMNS as FeatureColumnType[]
);

export const RULES_INFO_TOOLTIP_MESSAGE =
  'An Alert Rule describes the circumstances under which you want to be alerted and how you want to be notified.';

export const NOTIFICATIONS_INFO_TOOLTIP_MESSAGE =
  'This is a list of open and resolved notifications created from your Alert Triggers';

export const METRIC_CATEGORY_OPTIONS = [
  { label: 'Feature metrics', value: 'feature_metrics' },
  { label: 'System metrics', value: 'system_metrics' },
  { label: 'Custom metrics', value: 'custom_metrics' }
];

export const METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL = {
  feature_metrics: 'Feature metrics metric',
  system_metrics: 'System metrics metric',
  custom_metrics: 'Custom metrics metric'
};

export const DRIFT_ALGORITHMS = {
  EMD: 'EMD',
  PSI: 'PSI',
  KL: 'KL'
} as const;

export const CATEGORICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS = {
  name: 'metricSpecType',
  options: [
    { groupName: 'Drift', label: 'Drift' },
    {
      label: 'Earth Movers Distance',
      value: 'CATEGORICAL_EMD_DRIFT'
    },
    {
      label: 'Population Stability Index',
      value: 'CATEGORICAL_PSI_DRIFT'
    },
    {
      label: 'Kullback-Leibler divergence',
      value: 'CATEGORICAL_KL_DIVERGENCE_DRIFT'
    },
    {
      groupName: 'Missing values',
      label: 'Missing values'
    },
    {
      label: 'Number of missing values',
      value: 'MISSING_VALUES'
    }
  ],
  label: 'Metric'
};

export const NUMERICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS = {
  name: 'metricSpecType',
  options: [
    { groupName: 'Drift', label: 'Drift' },
    {
      label: 'Earth Movers Distance',
      value: 'NUMERICAL_EMD_DRIFT'
    },
    {
      label: 'Population Stability Index',
      value: 'NUMERICAL_PSI_DRIFT'
    },
    {
      label: 'Kullback-Leibler divergence',
      value: 'NUMERICAL_KL_DIVERGENCE_DRIFT'
    },
    {
      groupName: 'Missing values',
      label: 'Missing values'
    },
    {
      label: 'Number of missing values',
      value: 'MISSING_VALUES'
    },
    { groupName: 'Percentiles', label: 'Percentiles' },
    { label: 'Minimum', value: 'MIN' },
    {
      label: '10th Percentile',
      value: 'PERCENTILE_10'
    },
    {
      label: '25th Percentile',
      value: 'PERCENTILE_25'
    },
    { label: 'Median', value: 'MEDIAN' },
    {
      label: '75th Percentile',
      value: 'PERCENTILE_75'
    },
    {
      label: '90th Percentile',
      value: 'PERCENTILE_90'
    },
    { label: 'Maximum', value: 'MAX' }
  ],
  label: 'Metric'
};

export const DRIFT_METRICS = [
  'NUMERICAL_EMD_DRIFT',
  'NUMERICAL_PSI_DRIFT',
  'NUMERICAL_KL_DIVERGENCE_DRIFT',
  'CATEGORICAL_EMD_DRIFT',
  'CATEGORICAL_PSI_DRIFT',
  'CATEGORICAL_KL_DIVERGENCE_DRIFT'
];

export const SYSTEM_METRICS = [
  {
    label: 'Number Of Predictions',
    value: 'COUNT'
  }
];

export const ALERT_OPERATOR_OPTIONS = [
  { label: 'Greater Than', value: 'GREATER_THAN' },
  { label: 'Greater or equal than', value: 'GREATER_EQUAL' },
  { label: 'Less than', value: 'LESS_THAN' },
  { label: 'Less or equal than', value: 'LESS_EQUAL' }
] as const;

export const DEFAULT_VERSIONS = [
  {
    label: 'All versions',
    value: 'all'
  }
];

export const SEGMENT_OPTIONS = [{ label: 'All', value: 'all' }];

export const DRIFT_CALCULATION_OPTIONS = [
  { groupName: 'Time based', label: 'Time based' },
  {
    label: 'Previous Time Window',
    value: null,
    disabled: false
  },
  {
    groupName: 'Training distribution',
    label: 'Training distribution',
    disabled: false
  }
];

export const ALERT_TYPE_OPTIONS = [{ label: 'Threshold', value: 'Manual' }];

export const NOTIFICATION_TYPES = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
  TEAMS: 'TEAMS'
} as const;

export const EMPTY_DEFAULT_CHART_OVERVIEW_MESSAGE =
  'In order to see the ‘Alert Preview’ graph, please choose the metric you want to track & the specific parameters.';

export const EMPTY_HOURLY_CHART_OVERVIEW_MESSAGE =
  'No metric data has been recorded for the last 7 days.';

export const EMPTY_DAILY_CHART_OVERVIEW_MESSAGE =
  'No metric data has been recorded for the last 30 days.';

export const EMPTY_OVERVIEW_MESSAGE =
  'No metric data has been recorded for filled parameters.';
