import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import { useSelector } from 'react-redux';

import { ModelsPage } from '@mpm/components/ModelsPage';
import { ModelsPage as ModelsPageDruid } from '@mpm-druid/components/ModelsPage';
import { BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID } from '@/lib/betaFeatures';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';

const ModelProductionMonitoringRoutes = ({ match }) => {
  const isMPMDruidEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID
    });
  });

  return (
    <Switch>
      <Route
        path={`${match.path}/:modelId`}
        component={isMPMDruidEnabled ? ModelsPageDruid : ModelsPage}
      />
    </Switch>
  );
};

ModelProductionMonitoringRoutes.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ModelProductionMonitoringRoutes);
