import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const checkedIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" />
    <path
      d="M4.81657 6.43965L3.5 7.87504L7.03863 11L12.5 5.32595L11.0692 4L6.92706 8.30342L4.81657 6.43965Z"
      fill="white"
    />
  </svg>
);

const icon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="2.5"
      fill="white"
      stroke="#E2E2E2"
    />
  </svg>
);

const indeterminateIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" />
    <rect x="4" y="7" width="8" height="2" fill="white" />
  </svg>
);

const CustomCheckbox = props => {
  return (
    <Checkbox
      checkedIcon={checkedIcon}
      color="primary"
      icon={icon}
      indeterminateIcon={indeterminateIcon}
      {...props}
    />
  );
};

const iconSize = 26;

const StyledCheckbox = withStyles({
  root: {
    color: '#5155F5',
    height: iconSize,
    width: iconSize
  }
})(CustomCheckbox);

export default StyledCheckbox;
