import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';

import './Chip.scss';
import { RemoveTagIcon } from '@design-system-outdated/icons';

export const CHIP_TYPE_TAG = 'ds-tag-type';
export const CHIP_TYPE_BADGE = 'ds-badge-type';

const Chip = ({ label, onClick, onClose, type, invalid, isDeletable }) => {
  return (
    <div className={cx('ds-chip', type, { invalid })} onClick={onClick}>
      <div className="chip-label">{label}</div>
      {isDeletable && (
        <RemoveTagIcon
          className="chip-close-icon"
          onClick={onClose}
          // Keeps focus on the input when selecting item
          onMouseDown={e => e.preventDefault()}
        />
      )}
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.oneOf([CHIP_TYPE_TAG, CHIP_TYPE_BADGE]),
  invalid: PropTypes.bool,
  isDeletable: PropTypes.bool
};

Chip.defaultProps = {
  label: '',
  onClick: noop,
  onClose: noop,
  isDeletable: true,
  type: CHIP_TYPE_TAG,
  invalid: false
};

export default Chip;
