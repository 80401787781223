import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import noop from 'lodash/noop';

import Tooltip from '@material-ui/core/Tooltip';

import styles from './DashboardButton.module.scss';

const DashboardButton = ({
  tooltipTitle,
  disabled,
  label,
  subLabel,
  isOpened,
  onClick,
  icon
}) => {
  const hasSubLabel = !!subLabel.length;
  const noLabel = !label.length;

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <div>
        <button
          type="button"
          className={cx(styles.experimentButton, {
            [styles.open]: isOpened,
            [styles.noLabel]: noLabel,
            [styles.hasSubLabel]: hasSubLabel
          })}
          disabled={disabled}
          onClick={onClick}
        >
          {icon}
          {label}
          {hasSubLabel && <div className={styles.subLabel}>{subLabel}</div>}
        </button>
      </div>
    </Tooltip>
  );
};

DashboardButton.defaultProps = {
  tooltipTitle: '',
  disabled: false,
  label: '',
  subLabel: '',
  isOpened: false,
  icon: null,
  onClick: noop
};

DashboardButton.propTypes = {
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  isOpened: PropTypes.bool,
  icon: PropTypes.elementType,
  onClick: PropTypes.func
};

export default DashboardButton;
