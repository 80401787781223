import React from 'react';

import { BottomActionsPanel } from '@DesignSystem/bottom-actions-panel';
import { DeleteSelectionModalButton } from '@DesignSystem/bottom-actions-panel/panel-buttons';
import {
  DELETE_MULTY_RULES_MODAL_BODY_TEXT,
  DELETE_MULTY_RULES_MODAL_TITLE
} from '@mpm-druid/constants';

type AlertRulesActionsProps = {
  selectedItemsTotal: number;
  deleteItemsHandler: () => void;
};

const AlertRulesActions = ({
  selectedItemsTotal,
  deleteItemsHandler
}: AlertRulesActionsProps) => {
  return (
    <BottomActionsPanel
      selectedItemsTotal={selectedItemsTotal}
      absolute
      labelItems="rules"
    >
      <DeleteSelectionModalButton
        modalTitle={DELETE_MULTY_RULES_MODAL_TITLE(selectedItemsTotal)}
        bodyText={DELETE_MULTY_RULES_MODAL_BODY_TEXT(selectedItemsTotal)}
        deleteHandler={deleteItemsHandler}
      />
    </BottomActionsPanel>
  );
};

export default AlertRulesActions;
