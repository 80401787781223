import axios, { CancelToken } from 'axios';
import { BASE_API_URL } from '../constants/configConstants';

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL
});

axiosInstance.defaults.withCredentials = true;

export const cancelWrapper = callback => {
  // Create a new CancelToken source for this request
  const source = CancelToken.source();

  const promise = Promise.resolve().then(() => callback(source.token));

  // Cancel the request if React Query calls the `promise.cancel` method
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

const methodWrapper = method => (url, parameters = {}, config = {}) => {
  const dataKey = method === 'get' ? 'params' : 'data';

  return cancelWrapper(async cancelToken => {
    const { data } = await axiosInstance({
      [dataKey]: parameters,
      // Pass the source token to your request
      cancelToken,
      method,
      url,
      ...config
    });

    return data;
  });
};

const api = {
  delete: methodWrapper('delete'),
  get: methodWrapper('get'),
  post: methodWrapper('post'),
  put: methodWrapper('put'),
  patch: methodWrapper('patch')
};

export default api;
