import typography from '@ds-typography';
import {
  ButtonIconSize,
  ButtonSize,
  TextButtonType
} from '@design-system/types/buttons';

export const buttonTypographyMap: { [key in ButtonSize]: string } = {
  small: typography.dsActionSmall,
  medium: typography.dsAction,
  large: typography.dsAction,
  XL: typography.dsActionLarge
};

export const DEFAULT_BUTTON_TYPE: TextButtonType = 'primary';
export const DEFAULT_BUTTON_SIZE: ButtonSize = 'medium';
export const DEFAULT_BUTTON_ICON_SIZE: ButtonIconSize = 'medium';
