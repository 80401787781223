// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddRuleGroup-module__container--z2Gpg{position:relative;padding-left:16px}.AddRuleGroup-module__container--z2Gpg .AddRuleGroup-module__decoration--AOeFz{width:10px;position:absolute;left:0;top:26px;bottom:15px;border:1px solid var(--gray);border-right:none;border-radius:4px 0 0 4px}.AddRuleGroup-module__container--z2Gpg .AddRuleGroup-module__buttonWrapper--d9B6W{margin-top:2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddRuleGroup-module__container--z2Gpg`,
	"decoration": `AddRuleGroup-module__decoration--AOeFz`,
	"buttonWrapper": `AddRuleGroup-module__buttonWrapper--d9B6W`
};
module.exports = ___CSS_LOADER_EXPORT___;
