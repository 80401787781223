import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';

import castArray from 'lodash/castArray';

import BuiltInEmbeddedPanel from './BuiltInEmbeddedPanel';
import EmbeddedPanelHeader from './EmbeddedPanelHeader';

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const logViewEvent = ({ chartId, projectId, viewId }) => {
  if (!window.analytics) return;

  const params = {
    chartId,
    isInIframe: isInIframe(),
    projectId,
    viewId
  };

  if (params.isInIframe && window.document.referrer) {
    params.referrer = window.document.referrer;
  }

  window.analytics.track('embedded_panel_view_built_in_fe', params);
};

const EmbeddedPanelPage = () => {
  const [isPanelReady, setIsPanelReady] = useState(false);
  const [panelName, setPanelName] = useState('Panel');
  const [revision, setRevision] = useState(0);

  const {
    chartId,
    experimentKeys: hardcodedExperimentKeys,
    projectId,
    viewId
  } = queryString.parse(window.location.search, { arrayFormat: 'comma' });

  const handleRefresh = useCallback(() => setRevision(r => r + 1), []);
  const handlePanelLoad = useCallback(name => {
    setPanelName(name);
    setIsPanelReady(true);
  }, []);

  useEffect(() => {
    logViewEvent({ chartId, projectId, viewId });
  }, [chartId, projectId, viewId]);

  return (
    <div className="embedded-panel-page">
      <EmbeddedPanelHeader
        isReady={isPanelReady}
        onRefresh={handleRefresh}
        projectId={projectId}
        title={panelName}
        viewId={viewId}
      />

      <main className="embedded-panel-chart">
        <BuiltInEmbeddedPanel
          chartId={chartId}
          hardcodedExperimentKeys={
            hardcodedExperimentKeys ? castArray(hardcodedExperimentKeys) : null
          }
          onLoad={handlePanelLoad}
          projectId={projectId}
          revision={revision}
          viewId={viewId}
        />
      </main>
    </div>
  );
};

export default EmbeddedPanelPage;
