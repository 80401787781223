import {
  REQUEST_PROJECT_SECRET_LINKS,
  RECEIVE_PROJECT_SECRET_LINKS
} from '../actions/projectSecretLinks';

const projects = (state = { projectSecretLinks: [] }, action) => {
  switch (action.type) {
    case REQUEST_PROJECT_SECRET_LINKS:
      return { ...state, loading: true };
    case RECEIVE_PROJECT_SECRET_LINKS:
      return {
        ...state,
        loading: false,
        projectSecretLinks: action.projectSecretLinks
      };
    default:
      return state;
  }
};

export default projects;
