import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { matchPath, useLocation } from 'react-router';

import {
  CHART_TYPES,
  TOOLTIP_FEATURE_VIEW_SECTION,
  PREFIX_MPM_PATH
} from '@mpm-druid/constants';
import { usePanelResize } from '@/DesignSystem/hooks';
import { useTooltip } from '@mpm-druid/hooks';
import {
  CHART_BASIC_CONFIG,
  CHART_BASIC_LAYOUT,
  makeLineChartNotifShape,
  makeLineChartTooltipShape,
  makeTriangleTooltipShape,
  addHoverLinesOpacity
} from '@mpm-druid/utils';
import OptimizedPlot from '@DesignSystem/charts/OptimizedPlot';
import { ChartTooltip, Indicator } from '../ChartTooltip';
import EmptyStatePreviewBox from '../EmptyStateChartBox/EmptyStatePreviewBox';
import './LineChart.scss';

export const LineChart = ({
  data,
  height,
  disableZoom,
  showTooltip,
  emptyStateBoxConfig,
  layoutData,
  percentileMode,
  featureValue,
  section,
  setHoverLineData,
  ...props
}) => {
  const containerRef = useRef();
  const plotRef = useRef();
  const [counter, setCounter] = useState(1);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const { showEmptyStateBox, boxIcon, boxMessage } = emptyStateBoxConfig;
  const {
    tabData,
    chartType,
    allowedNumericalDistribution,
    driftAlgorithm,
    feature
  } = props;

  const {
    inspectMode,
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    hideTooltipHandler,
    closeTooltipHandler,
    handleClick
  } = useTooltip();

  useEffect(() => {
    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      if (tooltipVisible && showTooltip) {
        const pointData = tooltipData.data.data;
        const hoverInfo = {
          segmentId: pointData?.segmentId,
          xAxis: pointData?.x[tooltipData.data.pointIndex],
          color: tooltipData.color,
          tooltipVisible
        };
        setHoverLineData(prev => {
          return { ...prev, ...hoverInfo };
        });
      } else {
        setHoverLineData({});
      }
    }
  }, [tooltipData, tooltipVisible, chartType, setHoverLineData]);

  usePanelResize(plotRef, containerRef);

  const allYZeroes = data?.length
    ? data.every(slice => slice.areAllYZeroes)
    : false;

  const chartShapes = useMemo(() => {
    const alertShapes = [];
    if (!data || data?.length === 0) return alertShapes;
    for (const slice of data) {
      if (!slice.alertNotifications?.length) continue;
      for (const notification of slice.alertNotifications) {
        alertShapes.push(
          makeLineChartNotifShape(notification, slice.line.color)
        );
      }
    }
    if (tooltipData?.x && tooltipVisible && showTooltip) {
      return [
        ...alertShapes,
        makeLineChartTooltipShape(tooltipData),
        makeTriangleTooltipShape(tooltipData, allYZeroes)
      ];
    }
    return alertShapes;
  }, [data, tooltipData, tooltipVisible, allYZeroes, showTooltip]);

  const { pathname } = useLocation();
  const isPerformancePage = matchPath(
    pathname,
    `${PREFIX_MPM_PATH}/performance`
  )?.isExact;

  const showViewFeatureAction =
    TOOLTIP_FEATURE_VIEW_SECTION.includes(section) && isPerformancePage;

  const chartData = useMemo(() => {
    if (!tooltipVisible || !tooltipData || !showTooltip) {
      return data;
    }
    const lineId =
      tooltipData?.data?.data?.query || tooltipData?.data?.data?.name;
    return addHoverLinesOpacity(data, tooltipData?.title, lineId);
  }, [data, tooltipData, tooltipVisible, showTooltip]);

  const layout = useMemo(() => {
    let yAxisProps = {
      ...CHART_BASIC_LAYOUT.yaxis
    };
    if (allYZeroes) {
      yAxisProps = {
        ...CHART_BASIC_LAYOUT.yaxis,
        autorange: false,
        range: [-0.06, 10]
      };
    }
    return {
      ...CHART_BASIC_LAYOUT,
      height,
      shapes: chartShapes,
      spikedistance: -1,
      hoverdistance: -1,
      hovermode: 'closest',
      datarevision: counter,
      yaxis: yAxisProps,
      ...layoutData
    };
  }, [counter, height, chartShapes, inspectMode, layoutData, allYZeroes]);

  useEffect(() => {
    // Effect is required to Redraw the Chart changing layout.shapes from AddAlertPage, percentileMode, featureValue
    setCounter(prevVal => prevVal + 1);
    closeTooltipHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, layoutData.shapes, percentileMode, featureValue]);

  useEffect(() => {
    let timer;
    if (isSnackbarOpen) {
      timer = setTimeout(() => setIsSnackbarOpen(false), 700);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isSnackbarOpen]);

  return (
    <div>
      <div
        ref={containerRef}
        className={cx('ds-line-chart-container', {
          'numerical-distribution-chart': allowedNumericalDistribution
        })}
      >
        {showEmptyStateBox && (
          <EmptyStatePreviewBox message={boxMessage} icon={boxIcon} />
        )}

        {tooltipVisible && showTooltip && (
          <>
            <Indicator position={tooltipPosition} color={tooltipData.color} />
            <ChartTooltip
              tooltipData={tooltipData}
              position={tooltipPosition}
              closeHandler={closeTooltipHandler}
              inspectMode={inspectMode}
              orientation={tooltipOrientation}
              section={section}
              chartType={chartType}
              driftAlgorithm={driftAlgorithm}
              tabData={tabData}
              showViewFeatureAction={showViewFeatureAction}
              percentileMode={percentileMode}
              feature={feature}
            />
          </>
        )}
        <OptimizedPlot
          config={CHART_BASIC_CONFIG}
          data={chartData}
          layout={layout}
          useResizeHandler
          ref={plotRef}
          disableZoom={disableZoom}
          onHover={handleLinePointHover}
          onUnhover={hideTooltipHandler}
          onClick={handleClick}
          layoutProps={layoutData}
        />
      </div>
    </div>
  );
};

LineChart.defaultProps = {
  data: [],
  height: 'auto',
  disableZoom: false,
  showTooltip: true,
  showEmptyStateBox: false,
  layoutData: {},
  emptyStateBoxConfig: {},
  percentileMode: '',
  featureValue: ''
};

LineChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  disableZoom: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showEmptyStateBox: PropTypes.bool,
  layoutData: PropTypes.object,
  emptyStateBoxConfig: PropTypes.shape({
    showEmptyStateBox: PropTypes.bool,
    boxIcon: PropTypes.node,
    boxMessage: PropTypes.string
  }),
  section: PropTypes.string.isRequired,
  percentileMode: PropTypes.string,
  featureValue: PropTypes.string,
  panelTitle: PropTypes.string.isRequired
};
