import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButton, {
  RADIO_SIZE
} from '@DesignSystem/controllers/RadioButton/RadioButton';
import { INPUT_TYPE } from '@shared/utils/filterHelpers';

const OperatorListItem = ({
  disabled,
  showInput,
  showLabel,
  radioSize,
  operator,
  renderInputField
}) => {
  const show = operator.inputType !== INPUT_TYPE.NONE && showInput;

  return (
    <FormControlLabel
      className="operator-list-item"
      tabIndex="-1"
      value={operator.operator}
      data-test="operator-list-item"
      control={<RadioButton size={radioSize} />}
      label={
        <>
          {showLabel && (
            <div className="filter-condition-row-text">{operator.label}</div>
          )}
          {show && (
            <div className="input-field-wrapper">
              {renderInputField(operator, disabled)}
            </div>
          )}
        </>
      }
    />
  );
};

OperatorListItem.defaultProps = {
  disabled: false,
  showInput: true,
  showLabel: true,
  radioSize: RADIO_SIZE.STANDARD
};

OperatorListItem.propTypes = {
  disabled: PropTypes.bool,
  showInput: PropTypes.bool,
  showLabel: PropTypes.bool,
  radioSize: PropTypes.oneOf([RADIO_SIZE.STANDARD, RADIO_SIZE.SMALL]),
  operator: PropTypes.object.isRequired,
  renderInputField: PropTypes.func.isRequired
};

export default OperatorListItem;
