import { useUpsertExperimentMetricMutation } from '@experiment-management-shared';
import { ExperimentDetails, Metric } from '@shared/types';
import { useEffect, useState } from 'react';
import { validateMetricData } from '../helpers';
import useMetricTabBI from './useMetricTabBI';
import { experimentEvents } from '@/constants/trackingEventTypes';

type UseEditMetricApiOpts = {
  experiment: ExperimentDetails;
  onClose: () => void;
  isOpened: boolean;
  defaultFormData: Metric;
};
const useEditMetricApi = ({
  experiment,
  onClose,
  isOpened,
  defaultFormData
}: UseEditMetricApiOpts) => {
  const { experimentKey } = experiment;
  const { sendMetricTabBI } = useMetricTabBI();
  const [metricData, setMetricData] = useState({} as Metric);

  const upsertExperimentMetricMutation = useUpsertExperimentMetricMutation();

  useEffect(() => {
    if (isOpened) {
      setMetricData(defaultFormData);
    }
  }, [isOpened]);

  const isValid =
    validateMetricData(metricData) &&
    JSON.stringify(metricData) !== JSON.stringify(defaultFormData);

  const onConfirm = () => {
    upsertExperimentMetricMutation.mutate(
      {
        experimentKey,
        metric: metricData
      },
      {
        onSuccess: () => {
          onClose();
          sendMetricTabBI(
            experiment,
            experimentEvents.EXPERIMENT_DETAILS_EDIT_METRIC_DONE_CLICKED
          );
        }
      }
    );
  };

  const onFieldChange = (v: string, key: keyof Metric) => {
    let value: string | number = v;
    if (key.startsWith('timestamp')) {
      value = new Date(v).getTime();
    }
    if (key.startsWith('step')) {
      value = Number(v);
    }
    setMetricData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const isFetching = upsertExperimentMetricMutation.isLoading;

  return {
    onConfirm,
    onClose,
    isFetching,
    isValid,
    onFieldChange,
    formData: metricData
  };
};

export default useEditMetricApi;
