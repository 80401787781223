import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from '@material-ui/core';
import classNames from './FieldsList.module.scss';

const FieldsListAddBtn = ({ dataTest, text, onAddButtonClick, disabled }) => (
  <Button
    className={cx(classNames.dsFieldsListAddBtn, {
      [classNames.dsFieldsListAddBtnDisabled]: disabled
    })}
    data-test={dataTest ? `${dataTest}-add-btn` : 'add-btn'}
    disabled={disabled}
    variant="text"
    onClick={onAddButtonClick}
  >
    {text || '+ Add field'}
  </Button>
);

FieldsListAddBtn.defaultProps = {
  dataTest: null,
  disabled: false,
  text: null
};

FieldsListAddBtn.propTypes = {
  onAddButtonClick: PropTypes.func.isRequired,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string
};

export default FieldsListAddBtn;
