import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash/debounce';

import {
  PanelHoverData,
  TooltipDataPoint
} from '@experiment-management-shared/types';
import { getHoveredExperimentTrace } from '@/reducers/ui/visualizationsUiReducer';
import {
  getGroupByParamName,
  getGroupByParamValue
} from '@experiment-management-shared/components/Charts/Legends/helpers';
import visualizationsActions from '@/actions/visualizationsActions';

const DEFAULT_HOVER_DATA: PanelHoverData = {
  experimentKey: null,
  metricName: null,
  paramName: null,
  paramValue: null,
  source: null
};

const calculateTimeout = (length: number) => {
  if (length <= 20) {
    return 20;
  }

  if (length <= 50) {
    return 30;
  }

  if (length <= 100) {
    return 45;
  }

  if (length <= 200) {
    return 70;
  }

  return 150;
};

type UseChartTraceHighlightParams = {
  hoveredPoint?: TooltipDataPoint;
  tracesNumber: number;
};

const useBaseChartTraceHighlight = ({
  hoveredPoint = undefined,
  tracesNumber = 0
}: UseChartTraceHighlightParams) => {
  const timeout = calculateTimeout(tracesNumber);
  const dispatch = useDispatch();
  const {
    experimentKey: globalExperimentKey,
    source: globalSource
  } = useSelector(getHoveredExperimentTrace);
  const [hoveredData, setHoveredData] = useState<PanelHoverData>({
    ...DEFAULT_HOVER_DATA,
    experimentKey: globalExperimentKey,
    source: globalSource
  });

  const updateHoveredData = useCallback(
    (updateData: Partial<PanelHoverData> = { ...DEFAULT_HOVER_DATA }) => {
      let isNoDifference = false;

      setHoveredData(state => {
        isNoDifference =
          Object.keys(updateData).filter(key => {
            return updateData[key] !== state[key];
          })?.length === 0;

        if (isNoDifference) {
          return state;
        }

        return {
          ...DEFAULT_HOVER_DATA,
          ...updateData
        };
      });

      return !isNoDifference;
    },
    []
  );

  useEffect(() => {
    setHoveredData(state => {
      if (state.experimentKey !== globalExperimentKey || !globalSource) {
        return {
          ...DEFAULT_HOVER_DATA,
          ...(globalExperimentKey && { experimentKey: globalExperimentKey }),
          ...(globalSource && { source: globalSource })
        };
      }

      return state;
    });
  }, [globalExperimentKey, globalSource]);

  const handleDebouncedHover = useMemo(() => {
    return debounce(({ experimentKey, metricName, paramName, paramValue }) => {
      const hasChanged = updateHoveredData({
        experimentKey,
        metricName,
        paramName,
        paramValue,
        source: 'chart'
      });

      if (hasChanged) {
        dispatch(
          visualizationsActions.setHoveredExperimentTrace({
            experimentKey,
            source: (experimentKey ? 'global' : null) as never
          })
        );
      }
    }, timeout);
  }, [updateHoveredData, dispatch, timeout]);

  const handleDebouncedUnhover = useMemo(() => {
    return debounce(() => {
      updateHoveredData();
      dispatch(
        visualizationsActions.setHoveredExperimentTrace({
          experimentKey: null,
          source: null as never
        })
      );
    }, timeout);
  }, [dispatch, timeout, updateHoveredData]);

  useEffect(() => {
    if (hoveredPoint) {
      const experimentKey = hoveredPoint?.data?.cometMetadata?.experimentKey;
      const metricName = hoveredPoint?.data?.cometMetadata?.metricName;

      // line grouping by param
      const paramName = getGroupByParamName(hoveredPoint.data.cometMetadata);
      const paramValue = getGroupByParamValue(hoveredPoint.data.cometMetadata);

      handleDebouncedUnhover.cancel();

      handleDebouncedHover({
        experimentKey,
        metricName,
        paramName,
        paramValue
      });
    } else {
      handleDebouncedHover.cancel();
      handleDebouncedUnhover();
    }
  }, [hoveredPoint, handleDebouncedHover, handleDebouncedUnhover]);

  return {
    hoveredData,
    updateHoveredData
  };
};

export default useBaseChartTraceHighlight;
