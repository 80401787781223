// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomizeOrganizationSettings-module__organizationSettingsContainer--yq0Eo{max-width:650px}.CustomizeOrganizationSettings-module__organizationSettingsToggleLabel--DFRGE{font-weight:400;font-size:14px;margin-top:-5px;display:block;color:var(--gray-6);cursor:pointer}.CustomizeOrganizationSettings-module__organizationSettingsToggleText--BfS4H{line-height:18px;font-size:12px;display:block;color:var(--gray-5)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"organizationSettingsContainer": `CustomizeOrganizationSettings-module__organizationSettingsContainer--yq0Eo`,
	"organizationSettingsToggleLabel": `CustomizeOrganizationSettings-module__organizationSettingsToggleLabel--DFRGE`,
	"organizationSettingsToggleText": `CustomizeOrganizationSettings-module__organizationSettingsToggleText--BfS4H`
};
module.exports = ___CSS_LOADER_EXPORT___;
