import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import useProject from '@API/project/useProject';

import { DSArrowDownIcon, DSDiscardChangesIcon } from '@ds-icons';
import { TextButton } from '@ds';

import { DropdownList } from '@DesignSystem/controllers';
import { DROPDOWN_LIST_TYPES } from '@DesignSystem/controllers/DropdownList/DropdownList';

import {
  AUTO_GENERATED_TEMPLATE_NAME,
  NEW_VIEW_TEMPLATE_NAME
} from '@/lib/appConstants';
import useTemplateSelectActions from './hooks/useTemplateSelectActions';
import DeleteThisView from './modals/DeleteThisView';
import ThisViewWasntSaved from './modals/ThisViewWasntSaved';
import ViewListItemActions from './ViewListItemActions';
import ViewListEditableItem from './ViewListEditableItem';

import styles from './TemplateSelect.module.scss';
import projectsActions from '@/actions/projectsActions';

const PROJECT_LEVEL_KEYS = {
  templateId: 'templateId',
  templateName: 'templateName',
  projectId: 'projectId'
};

const EXPERIMENT_LEVEL_KEYS = {
  templateId: 'template_id',
  templateName: 'template_name',
  projectId: 'project_id'
};

export const BY_COMET_LABEL = 'By Comet';

const DEFAULT_STATE_VALUES = {
  searchValue: '',
  viewToGo: null,
  selectedViewToDelete: null,
  newViewName: '',
  asDefault: false,
  isSaveNewView: true,
  editNameView: null,
  editNewName: ''
};

const renderTemplateName = ({ label }) => {
  return <div className={styles.nameContainer}>{label}</div>;
};

const TemplateSelect = ({
  templates,
  onChangeTemplate,
  onChangeDefaultTemplate,
  onDeleteTemplate,
  onDiscardChanges,
  currentTemplateId,
  currentTemplateName,
  defaultTemplateName,
  isProjectLevel,
  generateSecondaryLabel,
  dividerIndex,
  disabledDischardChanges,
  onSaveUnsavedView,
  onDiscardNewView,
  hasUnsavedChanges,
  currentTemplateProjectId,
  onEditName
}) => {
  const dispatch = useDispatch();
  const { data: project } = useProject();
  const { projectId, canEdit } = project;

  const [searchValue, setSearchValue] = useState(
    DEFAULT_STATE_VALUES.searchValue
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewToGo, setViewToGo] = useState(DEFAULT_STATE_VALUES.viewToGo);
  const [selectedViewToDelete, setSelectedViewToDelete] = useState(
    DEFAULT_STATE_VALUES.selectedViewToDelete
  );

  const handleOpenViewList = event => {
    setSearchValue('');
    setAnchorEl(event.currentTarget);
  };

  const handleCloseViewList = () => {
    setAnchorEl(null);
  };

  // save unsaved view
  const [newViewName, setNewViewName] = useState(
    DEFAULT_STATE_VALUES.newViewName
  );
  const [asDefault, setAsDefault] = useState(DEFAULT_STATE_VALUES.asDefault);
  const [isSaveNewView, setIsSaveNewView] = useState(
    DEFAULT_STATE_VALUES.isSaveNewView
  );

  const [editNameView, setEditNameView] = useState(
    DEFAULT_STATE_VALUES.editNameView
  );

  const handleCurrentTemplateName = () => {
    if (
      !currentTemplateId &&
      currentTemplateName === NEW_VIEW_TEMPLATE_NAME &&
      isProjectLevel
    ) {
      return AUTO_GENERATED_TEMPLATE_NAME;
    }

    return currentTemplateName;
  };

  const onEditNewName = useCallback(
    newName => {
      dispatch(projectsActions.setEditViewName(newName));
    },
    [dispatch]
  );

  useEffect(() => {
    if (anchorEl) {
      batchedUpdates(() => {
        setNewViewName(DEFAULT_STATE_VALUES.newViewName);
        setAsDefault(DEFAULT_STATE_VALUES.asDefault);
        setIsSaveNewView(DEFAULT_STATE_VALUES.isSaveNewView);
        onEditNewName(DEFAULT_STATE_VALUES.editNewName);
        setEditNameView(DEFAULT_STATE_VALUES.editNameView);
        setViewToGo(DEFAULT_STATE_VALUES.viewToGo);
      });
    }
  }, [anchorEl, onEditNewName]);

  useEffect(() => {
    if (!viewToGo) {
      setNewViewName(DEFAULT_STATE_VALUES.newViewName);
    }
  }, [viewToGo]);

  const KEYS_MAP = isProjectLevel ? PROJECT_LEVEL_KEYS : EXPERIMENT_LEVEL_KEYS;

  const openDeleteModal = view => setSelectedViewToDelete(view);
  const closeDeleteModal = () => setSelectedViewToDelete(null);

  const openViewWasntSaved = view => setViewToGo(view);
  const closeViewWasntSaved = () => setViewToGo(null);

  const filterBySearch = useCallback(
    temps => {
      if (!searchValue.length) {
        return temps;
      }

      return temps.filter(template => {
        // New View is replaced with Auto Generated view for project view
        const templateName =
          template[KEYS_MAP.templateName] === NEW_VIEW_TEMPLATE_NAME &&
          isProjectLevel
            ? AUTO_GENERATED_TEMPLATE_NAME
            : template[KEYS_MAP.templateName];

        return templateName.toLowerCase().includes(searchValue.toLowerCase());
      });
    },
    [searchValue, KEYS_MAP.templateName, isProjectLevel]
  );

  const filteredTemplates = useMemo(() => filterBySearch(templates), [
    filterBySearch,
    templates
  ]);

  const {
    handleDiscardNewView,
    handleSaveNewView,
    handleTemplateChange,
    handleSetProjectTemplate,
    handleDeleteTemplate
  } = useTemplateSelectActions({
    canEdit,
    onDeleteTemplate,
    onChangeDefaultTemplate,
    templates: filteredTemplates,
    KEYS_MAP,
    currentTemplateName,
    hasUnsavedChanges,
    onChangeTemplate,
    onSaveUnsavedView,
    asDefault,
    newViewName,
    viewToGo,
    isSaveNewView,
    closeViewWasntSaved,
    onDiscardNewView,
    handleCloseViewList,
    openViewWasntSaved
  });

  const getLabel = (templateId, templateName) => {
    if (isProjectLevel) {
      if (templateId) {
        return templateName;
      }

      return AUTO_GENERATED_TEMPLATE_NAME;
    }

    return templateName;
  };

  const currentValue = useMemo(() => {
    if (isProjectLevel && !currentTemplateId) {
      return NEW_VIEW_TEMPLATE_NAME;
    }

    return currentTemplateId || currentTemplateName;
  }, [currentTemplateId, currentTemplateName, isProjectLevel]);

  const renderListItem = (template, idx) => {
    const templateId = get(template, KEYS_MAP.templateId);
    const templateName = get(template, KEYS_MAP.templateName);

    const isDeleteViewDisabled = !canEdit || !templateId;
    const isDefaultView = defaultTemplateName === templateName;
    const isEditDisabled = !canEdit || !templateId;

    return {
      // for List item key is using template ID field, but for "Auto Generated" and
      // "Empty view" it is equal for empty string, because of that we are using templateName as fallback
      value: templateId || templateName,
      label: getLabel(templateId, templateName),
      secondaryLabel: generateSecondaryLabel(template),
      withBottomDivider: dividerIndex === idx && !searchValue,
      renderActions: isHovered => (
        <ViewListItemActions
          isHovered={isHovered}
          isEditDisabled={isEditDisabled}
          onEditNewName={onEditNewName}
          templateName={templateName}
          setEditNameView={setEditNameView}
          template={template}
          isDeleteViewDisabled={isDeleteViewDisabled}
          openDeleteModal={openDeleteModal}
          isDefaultView={isDefaultView}
          handleSetProjectTemplate={handleSetProjectTemplate}
        />
      ),
      render:
        editNameView && templateId === editNameView[KEYS_MAP.templateId]
          ? () => (
              <ViewListEditableItem
                onEditNewName={onEditNewName}
                template={template}
                onEditName={onEditName}
                defaultNewViewName={DEFAULT_STATE_VALUES.newViewName}
                defaultEditNameView={DEFAULT_STATE_VALUES.editNameView}
                setEditNameView={setEditNameView}
              />
            )
          : null
    };
  };

  const isWasntSavedViewOutOfProject = currentTemplateProjectId !== projectId;

  const renderFooter = () => {
    return (
      <div className={styles.selectTemplateFooterContainer}>
        <TextButton
          PrefixIcon={<DSDiscardChangesIcon />}
          onClick={onDiscardChanges}
          disabled={disabledDischardChanges}
          size="small"
        >
          Discard changes
        </TextButton>
      </div>
    );
  };

  return (
    <>
      <TextButton
        type="primary"
        size="medium"
        PostfixIcon={<DSArrowDownIcon />}
        onClick={handleOpenViewList}
        active={Boolean(anchorEl)}
      >
        <div className={styles.currentTemplateName}>
          {handleCurrentTemplateName()}
        </div>
      </TextButton>

      <DropdownList
        withInput
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseViewList}
        items={filteredTemplates.map(renderListItem)}
        type={DROPDOWN_LIST_TYPES.WIDE}
        width="305px"
        maxHeight="240px"
        onChangeInput={setSearchValue}
        footer={renderFooter()}
        onClick={handleTemplateChange}
        renderListItem={renderTemplateName}
        value={currentValue}
        transformOriginVertical={-6}
      />

      <DeleteThisView
        handleDeleteTemplate={handleDeleteTemplate}
        selectedViewToDelete={selectedViewToDelete}
        keysMap={KEYS_MAP}
        closeDeleteModal={closeDeleteModal}
      />

      <ThisViewWasntSaved
        isWasntSavedViewOutOfProject={isWasntSavedViewOutOfProject}
        currentTemplateId={currentTemplateId}
        setIsSaveNewView={setIsSaveNewView}
        isSaveNewView={isSaveNewView}
        newViewName={newViewName}
        setNewViewName={setNewViewName}
        setAsDefault={setAsDefault}
        asDefault={asDefault}
        handleSaveNewView={handleSaveNewView}
        handleDiscardNewView={handleDiscardNewView}
        viewToGo={viewToGo}
        closeViewWasntSaved={closeViewWasntSaved}
      />
    </>
  );
};

TemplateSelect.defaultProps = {
  isProjectLevel: false,
  dividerIndex: -1,
  disabledDischardChanges: false
};

TemplateSelect.propTypes = {
  templates: PropTypes.array.isRequired,
  onChangeTemplate: PropTypes.func.isRequired,
  onChangeDefaultTemplate: PropTypes.func.isRequired,
  onDeleteTemplate: PropTypes.func.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
  onSaveUnsavedView: PropTypes.func.isRequired,
  onDiscardNewView: PropTypes.func.isRequired,
  defaultTemplateName: PropTypes.string.isRequired,
  currentTemplateId: PropTypes.string.isRequired,
  currentTemplateName: PropTypes.string.isRequired,
  disabledDischardChanges: PropTypes.bool,
  generateSecondaryLabel: PropTypes.func.isRequired,
  dividerIndex: PropTypes.number,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  currentTemplateProjectId: PropTypes.string.isRequired,
  onEditName: PropTypes.func.isRequired,

  // the field below is needed for handling different data:
  // the difference is:
  // - names of the fields (templateId against template_id, ...)
  // - handling of the autogenerated template
  isProjectLevel: PropTypes.bool
};

export default TemplateSelect;
