import { useQuery } from 'react-query';

import api from '@API/api';
import { QueryConfig } from '@shared/api';
import { SOURCE_TYPE } from '@mpm-druid/constants';
import { useParams } from 'react-router';

type FeaturesByNameParams = {
  modelId: string;
  featureIdentifiers: { name: string; source: SOURCE_TYPE }[];
  includeMetrics: boolean;
};

const getFeaturesByName = ({
  modelId,
  featureIdentifiers,
  includeMetrics
}: FeaturesByNameParams) => {
  return api.post(
    `mpm/v2/features/byNameAndSource?includeMetrics=${includeMetrics}`,
    {
      modelId,
      featureIdentifiers
    }
  );
};

export function useFeaturesByName(
  params: FeaturesByNameParams,
  config: QueryConfig<string>
) {
  const { modelId } = useParams<{ modelId: string }>();
  return useQuery(
    ['features-mame-type', { ...params, modelId }],
    () => getFeaturesByName({ ...params, modelId }),
    {
      ...config
    }
  );
}
