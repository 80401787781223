// format the data that comes from the backend to the data that GRID can display
// it comes in format { source: string, values: array }
// as source can have several data values, when we select columns that have MIN, MAX kinds, we need to format data
// so grid can show it and read + we need to add Version column value to the data
// data: [{ 'registryModelItemId' : { source: @String, values: @Array } }], modelVersions: [ { registryModelItemId: @String, version: @String } ]
// return [{ column_name: value (@String || @Array) }]
import { convertMetadataV1 } from '@API/helpers/v2_helpers';
import { SUB_PATHS } from '@/constants/urlConstants';
import modelRegistryApi from '@/util/modelRegistryApi';

export const formatFieldsInSingleModelVersionData = ({
  newData,
  modelVersion,
  ifDetailsPageEnabled,
  modelName,
  workspace,
  isAdminOrWsOwner,
  isUserAMemberOfWorkspace
}) => {
  const data = {
    ...newData
  };

  /* TODO: bad smelly piece of code */
  data.SourceExperiment = data.Name || data.SourceExperiment;
  data.Name = modelVersion.version;
  data.nameCellProps = {
    url: ifDetailsPageEnabled
      ? `/${workspace}/${SUB_PATHS.MODEL_REGISTRY}/${modelName}/${modelVersion.version}`
      : false,
    renderColorButton: false
  };
  data.Stage = modelVersion.stageList || modelVersion.stages;
  data.CreatedAt = data.CreatedAt || modelVersion.createdAt;
  data.LastUpdatedAt = data.LastUpdatedAt || modelVersion.lastUpdated;
  data.isAdminOrWsOwner = isAdminOrWsOwner;
  data.isUserAMemberOfWorkspace = isUserAMemberOfWorkspace;
  data.renderColorButton = false;
  data.tags = data.Stage?.length
    ? data.Stage?.map(stage => ({
        ...stage,
        tag: stage.stage
      }))
    : [];
  data.status = {
    status: modelVersion?.status,
    requestedStatus: modelVersion?.requestedStatus
  };
  data.rowData = modelVersion;

  return data;
};

export const formatSingleModelVersionData = ({
  dataForModelVersion = {},
  modelVersion,
  modelName,
  workspace,
  ifDetailsPageEnabled,
  searchedValue,
  isAdminOrWsOwner,
  isUserAMemberOfWorkspace
}) => {
  const newData = convertMetadataV1(dataForModelVersion, true);
  if (!modelVersion) return null;

  const data = formatFieldsInSingleModelVersionData({
    newData,
    modelVersion,
    modelName,
    workspace,
    ifDetailsPageEnabled,
    isAdminOrWsOwner,
    isUserAMemberOfWorkspace
  });

  const { rowData, nameCellProps, ...findInProps } = data;

  // perform search by string
  if (
    !searchedValue ||
    (searchedValue &&
      Object.values(findInProps).some((val = '') =>
        String(val)
          .toString()
          .toLocaleLowerCase()
          .includes(searchedValue.toLocaleLowerCase())
      ))
  ) {
    return data;
  }
};

export const formatAndFilterDataForGrid = ({
  gridData = [],
  modelName,
  modelVersions = [],
  searchedValue = '',
  workspace,
  ifDetailsPageEnabled,
  isAdminOrWsOwner,
  isUserAMemberOfWorkspace
}) => {
  const flattenGridDataForEachModelById = {};
  gridData.forEach(gridDataItem => {
    const [registryModelItemId] = Object.keys(gridDataItem);

    flattenGridDataForEachModelById[registryModelItemId] =
      gridDataItem[registryModelItemId];
  });

  return modelVersions
    .map(modelVersion => {
      const { registryModelItemId } = modelVersion;
      const dataForModelVersion =
        flattenGridDataForEachModelById[registryModelItemId];

      if (dataForModelVersion) {
        return formatSingleModelVersionData({
          dataForModelVersion,
          modelVersion,
          modelName,
          workspace,
          ifDetailsPageEnabled,
          searchedValue,
          isAdminOrWsOwner,
          isUserAMemberOfWorkspace
        });
      }

      return null;
    })
    .filter(Boolean);
};

export const getDownloadModelRegistryItemURL = model =>
  modelRegistryApi.getDownloadModelItemURL(model?.registryModelItemId);
