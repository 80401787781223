import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import uniq from 'lodash/uniq';
import remove from 'lodash/remove';

import { Table } from '@DesignSystem/tables';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { InfoIcon } from '@Icons-outdated';

import moment from 'moment/moment';
import InfoBlock from '@design-system-outdated/components/InfoBlock';
import { DSColors } from '@design-system-outdated/constants';
import {
  MODEL_VERSION_PENDING_REQUESTS_ACTIONS,
  MODEL_VERSION_PENDING_REQUESTS_DEFAULT_COLUMNS_ORDER,
  MODEL_REGISTRY_PENDING_REQUEST_LEFT_COLUMNS,
  MODEL_REGISTRY_PENDING_REQUEST_RIGHT_COLUMNS
} from '@model-registry/constants/request-status';
import RequestStatus from '@shared/components/RequestStatus';
import Tooltip from '@material-ui/core/Tooltip';
import useModelRegistryStatusRequestAction from '@model-registry/api/useModelRegistryStatusRequestAction';
import ModelRegistryPendingRequestsActions from '@model-registry/components/ModelRegistryPendingRequestsTab/ModelRegistryPendingRequestsActions';
import Typography from '@material-ui/core/Typography';
import ModelVersionStatusActionsModal from '@model-registry/components/ModelVersionStatusActionsModal/ModelVersionStatusActionsModal';
import { formatModelVersionStatusToText } from '@shared/utils/model-registry-request-status';
import { TABLE_PAGE_SIZES } from '@experiment-management-shared/constants/experimentGridConstants';
import useModelRegistryPendingRequests from '@model-registry/api/useModelRegistryPendingRequests';
import {
  HISTORY_TAB_ID,
  MODEL_REGISTRY_TAB_ID
} from '@model-registry/constants';
import SmallLoader from '@shared/components/SmallLoader';
import TableEmptyState from '@shared/components/TableEmptyState';
import LinkCell from '@shared/components/TableCells/LinkCell';
import classNames from './ModelRegistryPendingRequestsTab.module.scss';

const getMessageForSnackbar = (status, activeRowData) => {
  const statusTextFrom = formatModelVersionStatusToText(
    activeRowData?.currentStatus
  );
  const statusTextTo = formatModelVersionStatusToText(
    activeRowData?.requestedStatus
  );
  if (status === MODEL_VERSION_PENDING_REQUESTS_ACTIONS.CANCEL) {
    return `You successfully cancelled the request to change status from "${statusTextFrom}" to "${statusTextTo}"`;
  }

  return `You successfully ${
    status === MODEL_VERSION_PENDING_REQUESTS_ACTIONS.APPROVE
      ? 'approved'
      : 'rejected'
  } the request to change status from "${statusTextFrom}" to "${statusTextTo}"`;
};

const getDefaultColumnWidths = ifCurrentUserWsOwner => [
  { columnName: 'request', width: 380 },
  { columnName: 'actions', width: ifCurrentUserWsOwner ? 175 : 120 }
];

const getColumnOrders = selectedColumns => {
  const newCols = [
    ...MODEL_REGISTRY_PENDING_REQUEST_LEFT_COLUMNS,
    ...selectedColumns
  ];
  remove(newCols, el =>
    MODEL_REGISTRY_PENDING_REQUEST_RIGHT_COLUMNS.includes(el)
  );
  return uniq([...newCols, ...MODEL_REGISTRY_PENDING_REQUEST_RIGHT_COLUMNS]);
};

const ModelRegistryPendingRequestsTab = ({
  currentUser,
  modelName,
  workspace,
  model,
  ifCurrentUserWsOwner
}) => {
  const [pendingRequestsCurrentPage, setPendingRequestsCurrentPage] = useState(
    0
  );
  const [pendingRequestsPageSize, setPendingRequestsPageSize] = useState(
    TABLE_PAGE_SIZES[0]
  );
  const [statusForActionModal, setStatusForActionModal] = useState(null);
  const [activeRowData, setActiveRowData] = useState(null);
  const [columnWidths, setColumnWidths] = useState(
    getDefaultColumnWidths(ifCurrentUserWsOwner)
  );
  const [columnOrders, setColumnOrders] = useState(
    MODEL_VERSION_PENDING_REQUESTS_DEFAULT_COLUMNS_ORDER
  );
  const modelUpdateStatusRequestMutation = useModelRegistryStatusRequestAction({
    workspace,
    modelName
  });
  const onCloseModal = () => {
    setStatusForActionModal(null);
    setActiveRowData(null);
  };

  const paginationConfig = useMemo(
    () => ({
      isDisabled: false,
      isServerSidePagination: true,
      pageNumber: pendingRequestsCurrentPage,
      pageSizes: TABLE_PAGE_SIZES,
      pageSize: pendingRequestsPageSize,
      onPageNumberChange: setPendingRequestsCurrentPage,
      onPageSizeChange: pageSZ => {
        setPendingRequestsPageSize(pageSZ);
        setPendingRequestsCurrentPage(0);
      }
    }),
    [pendingRequestsCurrentPage, pendingRequestsPageSize]
  );

  const {
    data: { totalResultsCount, pendingRequests = [] } = {},
    isLoading: isLoadingPendingRequests
  } = useModelRegistryPendingRequests(
    {
      workspace,
      modelName,
      page: pendingRequestsCurrentPage + 1,
      limit: pendingRequestsPageSize
    },
    {
      enabled: !!workspace && !!modelName
    }
  );

  useEffect(() => {
    setColumnWidths(getDefaultColumnWidths(ifCurrentUserWsOwner));
  }, [ifCurrentUserWsOwner]);

  const onClickAction = useCallback(
    ({ status, comment, row }) => {
      const data = row || activeRowData || {};

      modelUpdateStatusRequestMutation.mutate({
        pendingRequestId: data?.pendingRequestId,
        status,
        comment,
        msg: getMessageForSnackbar(status, data)
      });
      onCloseModal();
    },
    [model, activeRowData]
  );

  const dataTypes = [
    {
      cols: ['request'],
      cell: ({ row }) => (
        <Box
          component={Grid}
          container
          alignItems="center"
          alignContent="center"
          spacing={2}
          pb={1}
        >
          <Grid item xs="auto">
            Change status from
          </Grid>
          <RequestStatus
            status={row.currentStatus}
            component={Grid}
            item
            xs="auto"
          />
          <Grid item xs="auto">
            to
          </Grid>
          <RequestStatus
            status={row.requestedStatus}
            component={Grid}
            item
            xs="auto"
          />
        </Box>
      )
    },
    {
      cols: ['version'],
      cell: ({ row }) => (
        <LinkCell
          component={Link}
          className={classNames.modelRegistryPendingRequestsLinkCell}
          text={row.version}
          target={null}
          to={`/${workspace}/model-registry/${modelName}/${row.version}`}
        />
      )
    },
    {
      cols: ['comment', 'createdBy'],
      cell: ({ value }) => (
        <Box>
          <Tooltip arrow placement="top" title={value}>
            <Typography
              noWrap
              component="div"
              variant="text2"
              className={classNames.modelRegistryPendingRequestsTablenoWrap}
            >
              {value}
            </Typography>
          </Tooltip>
        </Box>
      )
    },
    {
      cols: ['actions'],
      cell: ({ row }) => (
        <ModelRegistryPendingRequestsActions
          modelVersion={row.version}
          modelName={row.modelName}
          isLoading={modelUpdateStatusRequestMutation.isLoading}
          ifCurrentUserWsOwner={ifCurrentUserWsOwner}
          ifCurrentUserOwnerOfRequest={row.createdBy === currentUser?.username}
          onClick={async status => {
            setActiveRowData(row);

            if (status !== MODEL_VERSION_PENDING_REQUESTS_ACTIONS.CANCEL) {
              setStatusForActionModal(status);
              return;
            }

            onClickAction({ status, comment: '', row });
          }}
        />
      )
    }
  ];
  const rows = useMemo(
    () =>
      (pendingRequests || []).map(pendingRequest => ({
        ...pendingRequest,
        createdTs:
          pendingRequest.createdTs &&
          moment(pendingRequest.createdTs).format('D/MM/YYYY HH:mm A')
      })),
    [pendingRequests]
  );
  const tableColumns = useMemo(
    () => [
      { name: 'request', title: 'Request' },
      { name: 'version', title: 'Version' },
      { name: 'createdBy', title: 'Requester' },
      { name: 'createdTs', title: 'Requested at' },
      { name: 'comment', title: 'Comment' },
      {
        name: 'actions',
        title: (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs>
              <span>Actions</span>
            </Grid>
            <Grid item xs>
              <Tooltip
                title={
                  <div>
                    The ‘Workspace owner’ can approve or <br />
                    reject the pending requests
                  </div>
                }
                arrow
                placement="top"
              >
                <Box mt={0.5}>
                  <InfoIcon
                    className={classNames.modelRegistryPendingRequestsInfoIcon}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }
    ],
    [ifCurrentUserWsOwner, classNames]
  );

  const renderEmptyState = useCallback(props => {
    return (
      <TableEmptyState
        label="No pending requests"
        materialProps={props}
        pictureHeight="52px"
        pictureWidth="56px"
        picturePath="/images/account-settings/no-pending-requests.svg"
        additionalText={
          <div>
            Requests to change model version status will appear <br /> in this
            page to be approved by the workspace owner
          </div>
        }
      />
    );
  }, []);

  const updateColumnOrders = newColOrder =>
    setColumnOrders(getColumnOrders(newColOrder));

  if (isLoadingPendingRequests) {
    return <SmallLoader />;
  }

  return (
    <Box
      className={classNames.modelRegistryPendingRequestsContainer}
      data-test="model-registry-pending-requests-tab-content"
    >
      <ModelVersionStatusActionsModal
        ifCurrentUserWsOwner={ifCurrentUserWsOwner}
        open={statusForActionModal !== null}
        onClose={onCloseModal}
        action={statusForActionModal}
        onUpdate={onClickAction}
      />
      <Box className={classNames.modelRegistryPendingRequestsInformation}>
        <InfoBlock
          text={
            <span>
              ‘Workspace owners’ can approve or reject the ‘Change status’
              pending requests.
            </span>
          }
        />
      </Box>
      <Box className={classNames.modelRegistryPendingRequestsTable}>
        <Table
          columns={tableColumns}
          leftColumns={MODEL_REGISTRY_PENDING_REQUEST_LEFT_COLUMNS}
          rightColumns={MODEL_REGISTRY_PENDING_REQUEST_RIGHT_COLUMNS}
          dataTypes={dataTypes}
          rowHeight="60px"
          rowIdKey="pendingRequestId"
          rows={rows || []}
          selectionConfig={{
            isDisabled: true
          }}
          paginationConfig={paginationConfig}
          header={{
            headerColor: DSColors.grayColor1
          }}
          maxHeight={345}
          isFetching={false}
          columnWidthsConfig={{
            isDisabled: false,
            columnWidths,
            onColumnWidthsChange: setColumnWidths
          }}
          onOrderChange={updateColumnOrders}
          totalRowCount={totalResultsCount}
          renderEmptyState={renderEmptyState}
          sortingConfig={{
            isDisabled: true
          }}
          columnOrderConfig={{
            isDisabled: false,
            columnOrder: columnOrders,
            onColumnOrderChange: updateColumnOrders
          }}
        />
      </Box>
      <Box m={2}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          className={classNames.modelRegistryPendingRequestsInformation}
        >
          <Box
            component={Grid}
            item
            xs="auto"
            className={classNames.modelRegistryPendingRequestsProTip}
          >
            <InfoBlock
              renderIcon={() => (
                <Box
                  className={
                    classNames.modelRegistryPendingRequestsProTipIconWrapper
                  }
                >
                  <i
                    className={cx(
                      classNames.modelRegistryPendingRequestsProTipIcon,
                      'material-icons'
                    )}
                  >
                    lightbulb_outline
                  </i>
                </Box>
              )}
              text={
                <span>
                  <b>Pro tip:</b>{' '}
                  <span>
                    closed requests are found in the{' '}
                    <Link
                      to={`/${workspace}/model-registry/${modelName}?${MODEL_REGISTRY_TAB_ID}=${HISTORY_TAB_ID}`}
                    >
                      History tab
                    </Link>
                    .
                  </span>
                </span>
              }
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

ModelRegistryPendingRequestsTab.defaultProps = {
  workspace: '',
  modelName: ''
};

ModelRegistryPendingRequestsTab.propTypes = {
  model: PropTypes.shape({
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        version: PropTypes.string,
        registryModelItemId: PropTypes.string
      })
    )
  }).isRequired,
  currentUser: PropTypes.shape({
    username: PropTypes.string
  }).isRequired,
  modelName: PropTypes.string,
  workspace: PropTypes.string,
  ifCurrentUserWsOwner: PropTypes.bool.isRequired
};

export default ModelRegistryPendingRequestsTab;
