import { OrganizationUserRole } from '@shared/types';
import useCurrentOrganization from './useCurrentOrganization';
import { ORGANIZATION_USER_ROLES } from '@shared/constants';

const useCurrentOrganizationUserRole = (): OrganizationUserRole => {
  const currentOrganization = useCurrentOrganization();

  return currentOrganization?.role || ORGANIZATION_USER_ROLES.MEMBER;
};

export default useCurrentOrganizationUserRole;
