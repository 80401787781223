import { OptionType } from '@design-system/types';
import React from 'react';
import cx from 'classnames';
import classes from '../Select.module.scss';

import { components, MultiValueRemoveProps } from 'react-select';
import { DSCloseIcon } from '@ds-icons';

const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => {
  return (
    <div className={classes.multiRemoveContainer}>
      <components.MultiValueRemove {...props}>
        <button
          className={cx(classes.clearComboboxValueContainer)}
          disabled={props.data.isDisabled}
        >
          <div className={classes.clearIconContainer}>
            <DSCloseIcon />
          </div>
        </button>
      </components.MultiValueRemove>
    </div>
  );
};

export default MultiValueRemove;
