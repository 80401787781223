import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import groupBy from 'lodash/groupBy';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import Popover from '@material-ui/core/Popover';

import { formatRevision } from '@experiment-management-shared/utils/visualizationsHelper';

const VizHistoryPopover = ({
  anchorEl,
  currentRevisionId,
  generateLink,
  onClose,
  onItemClick,
  revisionIds,
  PopoverProps
}) => {
  const groupRevisions = () => {
    if (!revisionIds.length) return {};

    const revisionsWithLabels = sortBy(revisionIds)
      .reverse()
      .map((revisionId, index) => {
        const date = moment(revisionId);

        const label =
          index === 0
            ? 'Latest'
            : date.calendar(null, {
                sameDay: '[Today]',
                lastDay: '[Yesterday]',
                lastWeek: 'MMMM',
                sameElse: 'MMMM'
              });

        return {
          date: formatRevision(revisionId),
          label,
          revisionId
        };
      });

    return groupBy(revisionsWithLabels, 'label');
  };

  const renderRevisions = () => {
    const groupedRevisions = groupRevisions();

    const revisionGroups = Object.entries(groupedRevisions).map(
      ([label, revisions]) => {
        const items = revisions.map(({ date, revisionId }) => {
          const isCurrentRevision = revisionId == currentRevisionId;
          const itemClassName = cx('visualization-history-item', {
            'visualization-history-item-current': isCurrentRevision
          });

          if (!generateLink) {
            return (
              <div
                className={itemClassName}
                key={revisionId}
                onClick={() => onItemClick(revisionId)}
              >
                {date}
              </div>
            );
          }

          const path = generateLink(revisionId);

          return (
            <Link key={revisionId} to={path} onClick={onClose}>
              <div className={itemClassName}>{date}</div>
            </Link>
          );
        });

        const revisionIds = revisions
          .map(revision => revision.revisionId)
          .join('-');

        return (
          <div
            className="visualization-history-group-container"
            key={revisionIds}
          >
            <div className="visualization-history-header">{label}</div>

            <div className="visualization-history-items-container">{items}</div>
          </div>
        );
      }
    );

    return revisionGroups.reduce((prev, curr) => {
      const separator = <div className="visualization-history-separator" />;

      return [prev, separator, curr];
    }, []);
  };
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{ paper: 'visualization-history-popover' }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      {...PopoverProps}
    >
      {renderRevisions()}
    </Popover>
  );
};

VizHistoryPopover.defaultProps = {
  anchorEl: null,
  currentRevisionId: null,
  generateLink: null,
  onClose: noop,
  onItemClick: noop,
  revisionIds: [],
  PopoverProps: {}
};

VizHistoryPopover.propTypes = {
  anchorEl: PropTypes.node,
  currentRevisionId: PropTypes.number,
  generateLink: PropTypes.func,
  onClose: PropTypes.func,
  onItemClick: PropTypes.func,
  revisionIds: PropTypes.array,
  PopoverProps: PropTypes.object
};

export default VizHistoryPopover;
