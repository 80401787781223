import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { withStyles } from '@material-ui/core/styles';

import useArtifactsPageBreadcrumbs from '@artifacts/hooks/useArtifactsPageBreadcrumbs';

import ArtifactDetails from '@experiment-management-shared/components/ArtifactDetails';
import ArtifactTags from '@experiment-management-shared/components/ArtifactTags';
import MarkdownIFrame from '@shared/components/MarkdownIFrame';
import EditArtifactModalBtn from '@artifacts/components/EditArtifactModalBtn';
import artifactActions from '@/actions/artifactActions';
import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';
import { IconButton, Tooltip } from '@ds';
import { DSDeleteIcon } from '@ds-icons';
const StyledExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  }
})(ExpansionPanel);

const DESCRIPTION_STYLES = `<style>
html,
body {
  color: #191a1c;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  margin-top: -5px;
}
</style>`;

const ArtifactPageHeader = ({ artifact, isUserAMemberOfWorkspace }) => {
  const {
    artifactId,
    description,
    isPublic,
    name,
    emoji,
    tags,
    type
  } = artifact;

  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const { workspace } = useParams();
  const dispatch = useDispatch();
  useArtifactsPageBreadcrumbs();

  const handleDetailExpansion = () => {
    setIsDetailsExpanded(prevIsDetailsExpanded => !prevIsDetailsExpanded);
  };

  const handleOpenDeleteArtifactModal = () => {
    const redirectURL = `/${workspace}/${WORKSPACE_URL_HASHES.ARTIFACTS}`;
    dispatch(artifactActions.openDeleteArtifactModal(artifactId, redirectURL));
  };

  const renderDescription = () => {
    return (
      <div>
        <Divider />
        <div className="artifact-detail-container">
          <div className="artifact-detail-header">DESCRIPTION</div>
          <div style={{ height: 70 }}>
            <MarkdownIFrame
              textString={description}
              styles={DESCRIPTION_STYLES}
            />
          </div>
        </div>
      </div>
    );
  };

  const expansionPanelButtonText = isDetailsExpanded
    ? 'Minimize'
    : 'Show description';
  const ExpansionPanelButtonIcon = isDetailsExpanded
    ? KeyboardArrowUpIcon
    : KeyboardArrowDownIcon;

  return (
    <div className="artifact-header">
      <ArtifactDetails
        name={name}
        emoji={emoji}
        metadata={[
          { label: 'isPublic', value: isPublic },
          { label: 'Type', value: type },
          {
            label: 'ID',
            value: artifactId,
            dataMaskTest: '00000000-0000-0000-0000-000000000000'
          }
        ]}
      >
        <div className="artifact-header-buttons">
          <div className="artifact-action-container">
            <EditArtifactModalBtn
              artifact={artifact}
              disabled={!isUserAMemberOfWorkspace}
              isIconButton
            />
          </div>

          <div className="artifact-action-container">
            <Tooltip content="Delete artifact" placement="top">
              <IconButton
                Icon={<DSDeleteIcon />}
                type="secondary"
                onClick={handleOpenDeleteArtifactModal}
                disabled={!isUserAMemberOfWorkspace}
              />
            </Tooltip>
          </div>
        </div>
      </ArtifactDetails>

      {!isEmpty(tags) && (
        <div className="artifact-header-tags">
          <ArtifactTags tagNames={tags} />
        </div>
      )}
      <StyledExpansionPanel
        expanded={isDetailsExpanded}
        onChange={handleDetailExpansion}
        style={{ boxShadow: 'none' }}
      >
        <ExpansionPanelSummary style={{ padding: 0 }}>
          <div className="expansion-panel-summary">
            <div className="expansion-panel-summary-group">
              {expansionPanelButtonText}
              <ExpansionPanelButtonIcon
                style={{
                  marginLeft: 5,
                  fontSize: 18
                }}
              />
            </div>
          </div>
        </ExpansionPanelSummary>
        <div>{renderDescription()}</div>
      </StyledExpansionPanel>
    </div>
  );
};

ArtifactPageHeader.propTypes = {
  artifact: PropTypes.object.isRequired,
  isUserAMemberOfWorkspace: PropTypes.bool.isRequired
};

export default ArtifactPageHeader;
