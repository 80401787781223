import React from 'react';
import { CircleCheckedIcon } from '@Icons-outdated';
import {
  PAYMENT_PLANS,
  ORGANIZATION_PLAN_STARTER,
  PLAN_ENTERPRISE,
  ORGANIZATION_PLAN_COMMUNITY
} from '@shared/constants';

const renderCell = (_, value) => (value ? <CircleCheckedIcon /> : null);

export const COMPARE_PLANS_COLUMNS = [
  {
    key: 'feature',
    label: 'Features',
    className: 'feature-column'
  },
  {
    key: ORGANIZATION_PLAN_COMMUNITY,
    label: PAYMENT_PLANS[ORGANIZATION_PLAN_COMMUNITY].displayName,
    render: renderCell,
    headStyle: { color: PAYMENT_PLANS[ORGANIZATION_PLAN_COMMUNITY].color },
    align: 'center',
    headAlign: 'center'
  },
  {
    key: ORGANIZATION_PLAN_STARTER,
    label: PAYMENT_PLANS[ORGANIZATION_PLAN_STARTER].displayName,
    render: renderCell,
    headStyle: { color: PAYMENT_PLANS[ORGANIZATION_PLAN_STARTER].color },
    align: 'center',
    headAlign: 'center'
  },
  {
    key: PLAN_ENTERPRISE,
    label: PAYMENT_PLANS[PLAN_ENTERPRISE].displayName,
    render: renderCell,
    headStyle: { color: PAYMENT_PLANS[PLAN_ENTERPRISE].color },
    align: 'center',
    headAlign: 'center'
  }
];

export const COMPARE_PLANS_ROWS = [
  {
    key: 'unlimited',
    feature: 'Unlimited Private and Public Projects',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'restAPI',
    feature: 'REST API Access',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'slack',
    feature: 'Community Slack Support',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'dedicated-slack',
    feature: 'Dedicated Slack Support Channel',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'email',
    feature: 'Email Support',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'phone',
    feature: 'Phone Support',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: false,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'hyperparameter',
    feature: 'Hyperparameter Search',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'artifacts',
    feature: 'Artifacts',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: '10+members',
    feature: '10+ Team Members',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: false,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'model-registry',
    feature: 'Model Registry',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'api-account',
    feature: 'API Account',
    [ORGANIZATION_PLAN_COMMUNITY]: true,
    [ORGANIZATION_PLAN_STARTER]: true,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'flexible-deployment',
    feature: 'Flexible Deployment (On-prem, VPC, Cloud)',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: false,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'dedicated-mlops-support',
    feature: 'Dedicated MLOps Support',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: false,
    [PLAN_ENTERPRISE]: true
  },
  {
    key: 'single-sign-on',
    feature: 'Single Sign On',
    [ORGANIZATION_PLAN_COMMUNITY]: false,
    [ORGANIZATION_PLAN_STARTER]: false,
    [PLAN_ENTERPRISE]: true
  }
];
