import React from 'react';
import { push } from 'connected-react-router';
import randomstring from 'randomstring';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import {
  calculateExperimentName,
  getExperimentColor
} from '@experiment-management-shared/utils/experimentHelpers';

import {
  PANEL_CODE_LANGUAGES,
  TEMPLATE_SCOPE_TYPES,
  TEMPLATE_SCOPE_TYPE_LABELS
} from '@experiment-management-shared/constants/chartsGallery';

import { vizActionTypes } from '@/constants/actionTypes';
import visualizationsApi from '../util/visualizationsApi';
import alertsUtil from '../util/alertsUtil';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import createSrcDocWithUserCode from '@experiment-management-shared/utils/customPanelDoc/srcDocCreator';
import { templateDataErrorTypes } from '@experiment-management-shared/constants/visualizationConstants';
import {
  getCurrentTemplate,
  getProjectToken,
  getResourceUrlsForPreview,
  LATEST_REVISION_ID,
  NEW_TEMPLATE,
  makeGetLatestRevisonId,
  getPyConfigPreview,
  getCodeLanguagePreview
} from '@/reducers/visualizationsReducer';
import { trimSuffix } from '@/helpers/generalHelpers';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import {
  getMutableTemplateFields,
  getMutableScopeType,
  getMutableTemplateQueryBuilderId
} from '@/reducers/ui/visualizationsUiReducer';
import { SUB_PATHS } from '@/constants/urlConstants';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { getTeamIdForActiveWorkspace } from '@/reducers/ui/workspaceUiReducer';
import ImportFileModal from '@experiment-management-shared/components/ImportFileModal';
import { stringifyJSON } from '@shared/utils/jsonHelpers';
import { getAPIKey } from '@/reducers/userReducer';
import { parsePyConfigOfTemplate } from '@/helpers/templateHelpers';

// Use only the fields accepted by the API, or the endpoint will throw an error
const CREATE_INSTANCE_FIELDS = [
  'config',
  'instanceName',
  'order',
  'projectId',
  'queryBuilderId',
  'metadata',
  'source',
  'templateId',
  'templateRevisionId',
  'useDefaultConfig',
  'userSkippedVersion',
  'viewId'
];

const visualizationsActions = {
  resetIsFetchingTemplateFlag(templateId) {
    return {
      type: vizActionTypes.FETCH_VISUALIZATION_TEMPLATE,
      payload: { templateId }
    };
  },

  fetchVisualizationTemplate(templateId, revisionIdToFetch) {
    return (dispatch, getState) => {
      const state = getState();

      const projectId = getSelectedProjectId(state);
      const getLatestRevisionId = makeGetLatestRevisonId();
      const latestRevisionId =
        getLatestRevisionId(state, { templateId }) || null;

      const revisionId =
        revisionIdToFetch === SUB_PATHS.LATEST_REVISION
          ? latestRevisionId
          : revisionIdToFetch;

      dispatch(visualizationsActions.resetIsFetchingTemplateFlag(templateId));

      return visualizationsApi
        .fetchTemplate(templateId, revisionId, projectId)
        .then(response => {
          const template = parsePyConfigOfTemplate(response.data);
          dispatch(visualizationsActions.setCodePreview(template.code));

          dispatch({
            type: vizActionTypes.SET_VISUALIZATION_TEMPLATE,
            payload: {
              revisionId,
              templateId,
              template
            }
          });

          return { newRevisionId: template.revisionId, ...template };
        })
        .catch(error => {
          const { code, msg } = error.response.data;

          if (code === 401 && msg === 'No such template') {
            dispatch(
              visualizationsActions.addTemplateFetchingError(templateId)
            );

            dispatch({
              type: vizActionTypes.DELETE_VISUALIZATION_TEMPLATE,
              payload: {
                templateId
              }
            });
          } else {
            dispatch(
              alertsUtil.openErrorDialog(
                dialogTypes.CATCH_ERROR_API,
                'There was an error fetching your visualization template'
              )
            );
          }
        });
    };
  },

  addTemplateFetchingError(
    chartId,
    reason = templateDataErrorTypes.TEMPLATE_NOT_FOUND
  ) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.ADD_TEMPLATE_FETCHING_ERROR,
        payload: { chartId, lastError: Date.now(), reason }
      });
    };
  },

  fetchGalleryTemplatesByScope(
    optionalTeamId,
    scopeType = TEMPLATE_SCOPE_TYPES.WORKSPACE
  ) {
    return (dispatch, getState) => {
      dispatch({
        type: vizActionTypes.FETCH_ALL_VISUALIZATION_TEMPLATES
      });

      const state = getState();
      const defaultTeamId = getTeamIdForActiveWorkspace(state);
      const teamId = defaultTo(optionalTeamId, defaultTeamId);

      dispatch(visualizationsActions.fetchTemplateCounts());

      return visualizationsApi
        .fetchGalleryTemplatesByScope(teamId, scopeType)
        .then(response => {
          const { templates } = response.data;

          const templatesByScopeType = templates.reduce((map, template) => {
            map[template.templateId] = {
              [LATEST_REVISION_ID]: template
            };

            return map;
          }, {});

          dispatch({
            type: vizActionTypes.SET_GALLERY_TEMPLATE_MAP,
            payload: {
              scopeType,
              templatesByScopeType
            }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching your visualization templates'
            )
          );
        });
    };
  },

  createTemplate(changedTemplateFields, shouldRedirect = true) {
    return (dispatch, getState) => {
      const state = getState();
      const teamId = getTeamIdForActiveWorkspace(state);
      const queryBuilderId = getMutableTemplateQueryBuilderId(state);

      const mutableScopeType = getMutableScopeType(state);
      const scopeType = isEmpty(mutableScopeType)
        ? NEW_TEMPLATE.scopeType
        : mutableScopeType;
      const { editable, ...newTemplate } = NEW_TEMPLATE;

      const handledChangedTemplateFields = {
        ...changedTemplateFields,
        code: {
          ...changedTemplateFields.code,
          pyConfig: stringifyJSON(changedTemplateFields.code.pyConfig),
          defaultConfig: stringifyJSON(changedTemplateFields.code.defaultConfig)
        }
      };

      const templateToCreate = {
        ...newTemplate,
        ...handledChangedTemplateFields,
        queryBuilderId,
        scopeType,
        teamId
      };

      return visualizationsApi
        .createTemplate(templateToCreate)
        .then(response => {
          const { revisionId, templateId } = response.data;

          dispatch({
            type: vizActionTypes.SET_VISUALIZATION_TEMPLATE,
            payload: {
              revisionId,
              templateId,
              template: {
                ...parsePyConfigOfTemplate(templateToCreate),
                revisionId,
                editable: true
              }
            }
          });

          const state = getState();

          if (shouldRedirect) {
            dispatch(
              visualizationsActions.redirectAfterTemplateCreation(
                templateId,
                revisionId
              )
            );
          }

          const { thumbnail } = state.ui.visualizations;

          if (!thumbnail) {
            return;
          }

          return window
            .fetch(thumbnail)
            .then(r => r.blob())
            .then(image => {
              return dispatch(
                visualizationsActions.uploadThumbnail(templateId, image)
              );
            });
        })
        .then(() => {
          if (window.onCustomPanelSave) {
            window.onCustomPanelSave();
          }
        })
        .catch(err => {
          const { msg } = err.response.data;

          const alertMsg =
            msg === 'Duplicate template name found in team'
              ? 'This panel name already exists'
              : 'There was an error creating your visualization template';

          dispatch(
            alertsUtil.openErrorDialog(dialogTypes.CATCH_ERROR_API, alertMsg)
          );

          return alertMsg;
        });
    };
  },

  redirectAfterTemplateCreation(templateId = null, revisionId) {
    return (dispatch, getState) => {
      const state = getState();
      const { pathname } = state.router.location;

      let redirectPath;
      if (pathname.includes('/edit-code/')) {
        redirectPath = `${
          pathname.split('/edit-code/')[0]
        }/edit-code/${templateId}`;
      } else {
        redirectPath = `${trimSuffix(
          pathname,
          'new-custom-panel'
        )}edit-code/${templateId}/${revisionId}`;
      }

      dispatch(
        push({
          pathname: redirectPath
        })
      );
    };
  },

  updateVisualizationTemplate(templateId, revisionId) {
    return (dispatch, getState) => {
      const state = getState();
      const { preview } = state.visualizations;
      const currentTemplate = getCurrentTemplate(state, {
        templateId,
        revisionId
      });
      const teamId = getTeamIdForActiveWorkspace(state);
      const { thumbnail, version, ...newCode } = preview;
      const mutableTemplateFields = getMutableTemplateFields(state);

      if (newCode.pyConfig) {
        newCode.pyConfig = stringifyJSON(newCode.pyConfig, '');
      }
      newCode.defaultConfig = stringifyJSON(newCode.defaultConfig);

      const scopeType = isEmpty(mutableTemplateFields.scopeType)
        ? currentTemplate.scopeType || 'PRIVATE'
        : mutableTemplateFields.scopeType;
      const templateName = isEmpty(mutableTemplateFields.templateName)
        ? currentTemplate.templateName
        : mutableTemplateFields.templateName;
      const queryBuilderId = isEmpty(mutableTemplateFields.queryBuilderId)
        ? currentTemplate.queryBuilderId
        : mutableTemplateFields.queryBuilderId;

      let updatedTemplate = {
        code: newCode,
        lastVersion: new Date().getTime(),
        teamId,
        templateId,
        queryBuilderId,
        templateName,
        scopeType
      };

      return visualizationsApi
        .updateTemplate(updatedTemplate)
        .then(response => {
          const { revisionId } = response.data;

          updatedTemplate = parsePyConfigOfTemplate(updatedTemplate);
          dispatch({
            type: vizActionTypes.SET_VISUALIZATION_TEMPLATE,
            payload: {
              revisionId,
              template: { ...updatedTemplate, revisionId },
              templateId
            }
          });

          dispatch({
            type: vizActionTypes.UPDATE_VISUALIZATION_TEMPLATE,
            payload: {
              template: { ...updatedTemplate, revisionId },
              templateId
            }
          });

          dispatch(visualizationsActions.setIsTemplateSaved(true));

          return dispatch(
            visualizationsActions.fetchTemplateRevisions(templateId)
          );
        })
        .then(() => {
          if (window.onCustomPanelSave) {
            window.onCustomPanelSave();
          }
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error updating your visualization template'
            )
          );
        });
    };
  },

  createInstance(instance) {
    const newInstance = pick(instance, CREATE_INSTANCE_FIELDS);

    return (dispatch, getState) => {
      const state = getState();

      if (!isUserAllowedToEditProject(state)) {
        const instanceId = randomstring.generate(16);

        dispatch({
          type: vizActionTypes.SET_VISUALIZATION_INSTANCE,
          payload: {
            instanceId,
            instance: { ...instance }
          }
        });

        return Promise.resolve({ instanceId });
      }

      return visualizationsApi
        .createInstance(newInstance)
        .then(response => {
          const { instanceId } = response.data;

          dispatch({
            type: vizActionTypes.SET_VISUALIZATION_INSTANCE,
            payload: {
              instanceId,
              instance: {
                ...instance,
                ...response.data
              }
            }
          });

          return response.data;
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error creating your visualization instance'
            )
          );
        });
    };
  },

  runCodePreview(templateId, codePreview, experiments) {
    return (dispatch, getState) => {
      const state = getState();
      const preview = defaultTo(codePreview, state.visualizations.preview);

      dispatch(
        visualizationsActions.createSrcDoc(templateId, preview, experiments)
      );
    };
  },

  createSrcDoc(templateId, codePreview, experiments = []) {
    return (dispatch, getState) => {
      const state = getState();
      const pyConfig = getPyConfigPreview(state);
      const apiKey = getAPIKey(state);
      const resourceUrls = getResourceUrlsForPreview(state);
      const userCode = { ...codePreview, version: randomstring.generate(6) };
      const projectId = getSelectedProjectId(state);
      const currentCodeLanguage = getCodeLanguagePreview(state);
      const isPy = currentCodeLanguage === PANEL_CODE_LANGUAGES.PYTHON;
      const colorMap = experiments.reduce((acc, e) => {
        acc[e.experimentKey] = getExperimentColor(e).primary;
        return acc;
      }, {});
      const experimentNames = experiments.reduce((acc, e) => {
        acc[e.experimentKey] = calculateExperimentName(e);
        return acc;
      }, {});

      return dispatch(visualizationsActions.fetchProjectToken()).then(token => {
        const srcDoc = createSrcDocWithUserCode({
          colorMap,
          experimentKeys: experiments.map(e => e.experimentKey),
          experimentNames,
          projectToken: token,
          showConsole: true,
          userCode,
          resources: resourceUrls,
          instanceId: templateId,
          projectId,
          isPy,
          pyConfig,
          apiKey
        });

        dispatch(visualizationsActions.setSrcDoc(srcDoc));
      });
    };
  },

  clearThumbnail() {
    return (dispatch, getState) => {
      const state = getState();
      const { thumbnail } = state.ui.visualizations;

      if (thumbnail) {
        window.URL.revokeObjectURL(thumbnail);
      }

      dispatch({
        type: vizActionTypes.SET_THUMBNAIL,
        payload: { thumbnail: null }
      });
    };
  },

  setThumbnail(thumbnail) {
    return {
      type: vizActionTypes.SET_THUMBNAIL,
      payload: { thumbnail }
    };
  },

  uploadThumbnail(templateId, file) {
    return dispatch => {
      return visualizationsApi.uploadThumbnail(templateId, file).catch(() => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error uploading your thumbnail'
          )
        );
      });
    };
  },

  setSrcDoc(srcDoc) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_SRC_DOC,
        payload: srcDoc
      });
    };
  },

  setCodePreview(codePreview) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_CODE_PREVIEW,
        payload: { codePreview }
      });
    };
  },

  setCodePreviewLanguage(codeLanguage) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_CODE_PREVIEW_LANGUAGE,
        payload: { codeLanguage }
      });
    };
  },

  setDefaultConfig(config) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_DEFAULT_CONFIG,
        payload: config
      });
    };
  },

  setPyConfig(config) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_PY_CONFIG,
        payload: config
      });
    };
  },

  setIsTemplateSaved(isSaved = false) {
    return {
      type: vizActionTypes.SET_IS_TEMPLATE_SAVED,
      payload: isSaved
    };
  },

  fetchInternalResources() {
    return dispatch => {
      return visualizationsApi
        .fetchDefaultInternalResources()
        .then(response => {
          const internalResources = response.data.resources;

          dispatch({
            type: vizActionTypes.SET_INTERNAL_RESOURCES,
            payload: { internalResources }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching the default resources for visualizations'
            )
          );
        });
    };
  },

  updateTemplateQueryBuilderIdField(queryBuilderId) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_TEMPLATE_QUERY_BUILDER_ID,
        payload: { queryBuilderId }
      });
      dispatch(visualizationsActions.setIsTemplateSaved(false));
    };
  },

  updateTemplateNameField(templateName) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_TEMPLATE_NAME_FIELD,
        payload: { templateName }
      });
      dispatch(visualizationsActions.setIsTemplateSaved(false));
    };
  },

  setTemplateScopeType(scopeType) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_TEMPLATE_SCOPE_TYPE,
        payload: { scopeType }
      });
      dispatch(visualizationsActions.setIsTemplateSaved(false));
    };
  },

  resetMutableTemplateFields() {
    return {
      type: vizActionTypes.RESET_MUTABLE_TEMPLATE_FIELDS
    };
  },

  setSavedGalleryTab(activeTab) {
    return {
      type: vizActionTypes.SET_SAVED_GALLERY_TAB,
      payload: { activeTab }
    };
  },

  fetchTemplateRevisions(templateId) {
    return dispatch => {
      return visualizationsApi
        .fetchTemplateRevisionIds(templateId)
        .then(response => {
          const { revisions } = response.data;

          dispatch({
            type: vizActionTypes.SET_REVISION_IDS,
            payload: { revisions, templateId }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching the revisions for the visualization template'
            )
          );
        });
    };
  },

  fetchProjectToken(customProjectId) {
    return (dispatch, getState) => {
      const state = getState();
      const projectId = defaultTo(customProjectId, getSelectedProjectId(state));
      const token = getProjectToken(state, { projectId });
      const validateTokenPromise = token
        ? visualizationsApi.validateProjectToken(token)
        : Promise.reject();

      return validateTokenPromise
        .then(() => token)
        .catch(() => {
          return visualizationsApi
            .createProjectToken(projectId)
            .then(response => {
              const { token } = response.data;

              dispatch({
                type: vizActionTypes.SET_PROJECT_TOKEN,
                payload: { projectId, token }
              });

              return token;
            });
        });
    };
  },

  importTemplate(templateToImport) {
    return (dispatch, getState) => {
      const state = getState();
      const teamId = getTeamIdForActiveWorkspace(state);
      return visualizationsApi
        .importTemplate(teamId, templateToImport)
        .then(() => {
          dispatch(visualizationsActions.fetchGalleryTemplatesByScope());

          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_API_RESPONSE,
              'Successfully imported panel. You can find your panel in the workspace tab of the Panels Gallery.'
            )
          );
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error importing your panel.'
            )
          );
        });
    };
  },

  openImportTemplateModal() {
    return dispatch => {
      const modalId = dialogTypes.IMPORT_FILE_MODAL;

      const handleSubmit = file => {
        const formData = new FormData();
        formData.append('file', file);

        dispatch(visualizationsActions.importTemplate(formData));
      };

      const modal = (
        <ImportFileModal
          modalId={modalId}
          title="Import Panel"
          submitHandler={handleSubmit}
        />
      );

      dispatch(alertsUtil.openCustomModal(modalId, modal));
    };
  },

  fetchTemplateCounts() {
    return (dispatch, getState) => {
      const teamId = getTeamIdForActiveWorkspace(getState());

      return visualizationsApi
        .fetchTemplateCounts(teamId)
        .then(response => {
          const { tabs } = response.data;

          const templateCountsMap = tabs.reduce((map, { tab, count }) => {
            const tabName =
              tab === 'My Panels' ? TEMPLATE_SCOPE_TYPE_LABELS.WORKSPACE : tab;

            map[tabName] = count;
            return map;
          }, {});

          dispatch({
            type: vizActionTypes.SET_GALLERY_TEMPLATE_COUNTS,
            payload: { templateCountsMap }
          });
        })
        .catch(() => {
          console.error('Failed to fetch template counts');
        });
    };
  },

  fetchInstancesPreview(templateId, limit) {
    return dispatch => {
      return visualizationsApi
        .fetchInstancesPreview(templateId, limit)
        .then(response => {
          const { instances } = response.data;

          dispatch({
            type: vizActionTypes.SET_PREVIEW_TEMPLATE_INSTANCES,
            payload: { templateId, instances }
          });

          return instances;
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching the instances.'
            )
          );
        });
    };
  },

  fetchPanelSuggestionsForExperiment(experimentKey) {
    return dispatch => {
      return visualizationsApi
        .fetchPanelSuggestionsForExperiment(experimentKey)
        .then(response => {
          const suggestedTemplateIds = get(
            response,
            ['data', 'template_ids'],
            []
          );

          const promises = suggestedTemplateIds.map(templateId => {
            return dispatch(
              visualizationsActions.fetchVisualizationTemplate(templateId)
            );
          });

          return Promise.all(promises);
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_API,
              'There was an error fetching panel suggestions for this experiment.'
            )
          );
        });
    };
  },

  setHoveredExperimentTrace({ experimentKey = null, source = 'table' }) {
    return dispatch => {
      dispatch({
        type: vizActionTypes.SET_HOVERED_EXPERIMENT_TRACE,
        payload: { experimentKey, source }
      });
    };
  }
};

export default visualizationsActions;
