import { useMutation } from 'react-query';
import modelRegistryApi from '../../util/modelRegistryApi';

const getModelRegistryNameValidationResult = async (workspace, modelName) => {
  const { data } = await modelRegistryApi.validateModelName(
    workspace,
    modelName
  );

  return data;
};

const useModelRegistryNameValidationMutation = workspaceName =>
  useMutation(modelName =>
    getModelRegistryNameValidationResult(workspaceName, modelName)
  );

export default useModelRegistryNameValidationMutation;
