import api from '@API/api';
import { useQuery } from 'react-query';

import { QueryConfig } from '@shared/api';

type AlertNotificationParams = {
  modelId: string;
};

type AlertCountResponse = {
  numNotificationsOpen: number;
  numNotificationsResolved: number;
};

const getAlertCount = ({ modelId }: AlertNotificationParams) =>
  api.get(`/mpm/v2/monitor-notification/count?modelId=${modelId}`);

export function useAlertsNotificationCount(
  { modelId }: AlertNotificationParams,
  config: QueryConfig<AlertCountResponse>
) {
  return useQuery(
    ['alerts-count', { modelId }],
    () => getAlertCount({ modelId }),
    {
      ...config,
      enabled: !!modelId,
      retry: false
    }
  );
}
