import {
  DebuggerNoErrorsIcon,
  DebuggerNoLabelsIcon,
  DebuggerNoPredictionsIcon
} from '@Icons-outdated';
import { chartColors } from '@design-system-outdated/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RouteObject } from 'use-route-as-state';

import {
  OPERATOR_TYPE,
  INPUT_TYPE,
  RULE_TYPE
} from '@shared/utils/filterHelpers';
import { DRIFT_ALGORITHMS } from './alerts';
import { Version } from '../types/common';

export const PANEL_NAMES = {
  CUSTOM_METRICS: 'Custom Metrics',
  INPUT_METRICS: 'Input Metrics',
  NUMBER_OF_PREDICTIONS: 'Number Of Predictions',
  DISTRIBUTION: 'Distribution',
  DRIFT: 'Drift',
  MISSING_VALUES: 'Missing Values',
  OUTPUT_FEATURES: 'Output Features'
} as const;

export enum PANEL_SECTIONS {
  // Model (Custom Metrics)
  CUSTOM_METRICS = 'CUSTOM_METRICS',
  // Model (Input Metrics)
  DATA_DRIFT = 'DATA_DRIFT',
  DATA_DRIFT_BY_FEATURE = 'DATA_DRIFT_BY_FEATURE',
  DATA_DRIFT_BY_FEATURE_AGGREGATED = 'DATA_DRIFT_BY_FEATURE_AGGREGATED',
  // Model (System Metrics)
  NUMBER_OF_PREDICTIONS = 'NUMBER_OF_PREDICTIONS',
  // Features (Distribution), Model (Output Features)
  FEATURE_DISTRIBUTION_NUMERICAL = 'FEATURE_DISTRIBUTION_NUMERICAL',
  FEATURE_DISTRIBUTION_CATEGORICAL = 'FEATURE_DISTRIBUTION_CATEGORICAL',
  FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES = 'FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES',
  NUMERICAL_DISTRIBUTION = 'NUMERICAL_DISTRIBUTION',

  // Features (Drift)
  FEATURE_DRIFT = 'FEATURE_DRIFT',
  // Features (Missing values)
  FEATURE_MISSING_VALUES = 'FEATURE_MISSING_VALUES'
}

export const PANEL_DATA_URL_MAP_SECTION = {
  [PANEL_SECTIONS.CUSTOM_METRICS]: 'custom-metrics/query',
  [PANEL_SECTIONS.DATA_DRIFT]: 'model/inputDrift',
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE]: 'model/input-drift-by-features',
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED]:
    'model/input-drift-by-features',
  [PANEL_SECTIONS.NUMBER_OF_PREDICTIONS]: 'model/numberOfPredictions',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL]: 'features/distribution',
  [PANEL_SECTIONS.NUMERICAL_DISTRIBUTION]:
    'features/numerical-distribution-pdf',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL]:
    'features/categorical-distribution',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES]:
    'features/distribution',
  [PANEL_SECTIONS.FEATURE_MISSING_VALUES]: 'features/missingValues',
  [PANEL_SECTIONS.FEATURE_DRIFT]: 'features/drift'
};

export const CHART_TYPES = {
  LINE: 'line',
  LINE_CATEGORICAL_BAR_DATA: 'line-categorical-bar-data',
  LINE_WITH_PERCENTILE: 'line-with-percentile',
  BAR: 'bar',
  STACKED: 'stacked',
  STACKED_TO_LINES: 'stacked-to-lines',
  STACKED_TO_AGGREGATED_LINES: 'stacked-to-aggregated-lines',
  // multiple type charts can be inferred by getMPMChartType only
  MULTIPLE_TYPE_CHART: 'multiple',
  NUMERICAL_DISTRIBUTION: 'numerical-distribution'
} as const;

export const CHART_TYPE_BY_SECTION = {
  [PANEL_SECTIONS.CUSTOM_METRICS]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.DATA_DRIFT]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE]: CHART_TYPES.STACKED,
  [PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED]:
    CHART_TYPES.STACKED_TO_AGGREGATED_LINES,
  [PANEL_SECTIONS.NUMBER_OF_PREDICTIONS]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.FEATURE_MISSING_VALUES]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL]:
    CHART_TYPES.LINE_WITH_PERCENTILE,
  [PANEL_SECTIONS.NUMERICAL_DISTRIBUTION]: CHART_TYPES.LINE,
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL]:
    CHART_TYPES.MULTIPLE_TYPE_CHART,
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES]:
    CHART_TYPES.MULTIPLE_TYPE_CHART,
  [PANEL_SECTIONS.FEATURE_DRIFT]: CHART_TYPES.LINE
};

export const INPUT_PANEL_TABS = [
  {
    label: 'Data Drift By Feature',
    value: PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE,
    section: PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE
  },
  {
    label: 'Data Drift',
    value: PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED,
    section: PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED
  }
];

export enum MPM_CHART_DATA_TYPES {
  NUMERICAL = 'NUMERICAL',
  CATEGORICAL = 'CATEGORICAL'
}

export const PANEL_SECTION_TO_METRIC_CATEGORY = {
  [PANEL_SECTIONS.CUSTOM_METRICS]: 'custom_metrics',
  [PANEL_SECTIONS.NUMBER_OF_PREDICTIONS]: 'system_metrics',
  [PANEL_SECTIONS.FEATURE_DRIFT]: 'feature_metrics',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES]:
    'feature_metrics',
  [PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL]: 'feature_metrics',
  [PANEL_SECTIONS.FEATURE_MISSING_VALUES]: 'feature_metrics'
};

export const ALLOWED_ALERT_CREATE_BUTTON_SECTIONS = [
  PANEL_SECTIONS.CUSTOM_METRICS,
  PANEL_SECTIONS.NUMBER_OF_PREDICTIONS,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL,
  PANEL_SECTIONS.FEATURE_DRIFT,
  PANEL_SECTIONS.FEATURE_MISSING_VALUES
];

export const PERCENTILE_OPTION_LIST = [
  {
    label: 'Minimum - Maximum',
    value: 'minmax',
    filter: ['min', 'p50', 'max']
  },
  {
    label: '10th Percentile - 90th Percentile',
    value: 'p10-p90',
    filter: ['p10', 'p50', 'p90']
  },
  {
    label: '25th Percentile - 75th Percentile',
    value: 'p25-p75',
    filter: ['p25', 'p50', 'p75']
  },
  {
    label: 'No Shadow',
    value: 'none',
    filter: ['', '', '']
  }
];

export const PERCENTILE_TYPE_MAP = {
  minmax_min: 'MIN',
  minmax_median: 'MEDIAN',
  minmax_max: 'MAX',
  ['p10-p90_min']: 'PERCENTILE_10',
  ['p10-p90_median']: 'MEDIAN',
  ['p10-p90_max']: 'PERCENTILE_90',
  ['p25-p75_min']: 'PERCENTILE_25',
  ['p25-p75_median']: 'MEDIAN',
  ['p25-p75_max']: 'PERCENTILE_75',
  none_median: 'MEDIAN'
} as const;

export const DRIFT_ALGORITHMS_OPTIONS = [
  {
    label: 'Earth Movers Distance',
    value: DRIFT_ALGORITHMS.EMD
  },
  {
    label: 'Population Stability Index',
    value: DRIFT_ALGORITHMS.PSI
  },
  {
    label: 'Kullback-Leibler divergence',
    value: DRIFT_ALGORITHMS.KL
  }
];

export const INTERVAL_TYPE_VALUES = {
  HOURLY: 'HOURLY',
  DAILY: 'DAILY'
} as const;

export const DEFAULT_INTERVAL_TYPE_VALUE = INTERVAL_TYPE_VALUES.DAILY;

export const INTERVAL_TYPE_OPTIONS = [
  {
    label: 'Daily',
    value: INTERVAL_TYPE_VALUES.DAILY
  },
  {
    label: 'Hourly',
    value: INTERVAL_TYPE_VALUES.HOURLY
  }
];

export const PERCENTILE_VALUES = {
  MIN: 'min',
  MAX: 'max'
};

export const PERFORMANCE_DATE_RANGES = {
  [INTERVAL_TYPE_VALUES.DAILY]: [7, 30, 90],
  [INTERVAL_TYPE_VALUES.HOURLY]: [1, 3, 7]
} as const;

export const DEFAULT_INTERVAL_VALUE = 7;
export const MIN_AMOUNT_DAYS_DAILY_MODE = 1;

export const MPM_OPERATOR_FILTERS = {
  [RULE_TYPE.STRING]: [
    {
      label: 'contains',
      operator: OPERATOR_TYPE.CONTAINS,
      inputType: INPUT_TYPE.STRING
    }
  ],
  [RULE_TYPE.TIME_NUMBER]: [
    {
      label: 'equal',
      operator: OPERATOR_TYPE.EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'not equal',
      operator: OPERATOR_TYPE.NOT_EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    }
  ],
  [RULE_TYPE.DATETIME]: [
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ],
  [RULE_TYPE.DOUBLE]: [
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ],
  [RULE_TYPE.BOOLEAN]: [
    {
      label: 'is true',
      operator: OPERATOR_TYPE.IS_TRUE,
      inputType: INPUT_TYPE.NONE,
      value: true
    },
    {
      label: 'is false',
      operator: OPERATOR_TYPE.IS_FALSE,
      inputType: INPUT_TYPE.NONE,
      value: false
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ]
};

export const MPM_CHART_DISABLED_ZOOM = true;

export const STREAM_ACTIONS = {
  PLAY: 'play',
  PAUSE: 'pause'
} as const;

export const DEBUGGER_GROUP_BUTTONS = {
  INGESTIONS: 'ingestions',
  LABELS: 'labels',
  PREDICTIONS: 'predictions'
} as const;

export const DEBUGGER_LABELS = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: 'Ingestion error',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: 'Label',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: 'Prediction'
};

export const DEBUGGER_LABELS_COLORS = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: '#ef6868',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: '#19a979',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: '#5899da'
};

export const RESPONSE_DATA = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: 'errors',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: 'events',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: 'events'
} as const;

export const EMPTY_DEBUGGER_EVENTS_ICONS = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: DebuggerNoErrorsIcon,
  [DEBUGGER_GROUP_BUTTONS.LABELS]: DebuggerNoLabelsIcon,
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: DebuggerNoPredictionsIcon
};

export const EMPTY_DEBUGGER_EVENTS_MESSAGES = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]:
    'No Ingestion errors recorded in the previous 24 hours',
  [DEBUGGER_GROUP_BUTTONS.LABELS]:
    'No Labels recorded in the previous 24 hours',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]:
    'No Predictions recorded in the previous 24 hours'
};

export const NODE_TYPES = {
  DEFAULT: 'default',
  INPUT: 'inputModel',
  OUTPUT: 'outputModel'
} as const;

export const INITIAL_QUERY_STATE: RouteObject = {
  version: null,
  intervalType: DEFAULT_INTERVAL_TYPE_VALUE
} as const;

export const TOOLTIP_FEATURE_VIEW_SECTION = [
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES
];

export const PREFIX_MPM_PATH =
  '/:workspace/model-production-monitoring/:modelId';

export const DEFAULT_VERSION: Version[] = [
  {
    label: 'All versions',
    value: null
  }
];

export const CHART_COLORS = Object.values(chartColors);
