// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageUsersModalPopover-module__manageUsersModalPopover--XmK88{margin-top:26px;padding:8px 0;box-shadow:0 0 8px rgba(0,0,0,.25) !important;width:354px;min-height:148px}.ManageUsersModalPopover-module__manageUsersModalMemberPermissions--khy7w{color:var(--gray-6);cursor:pointer;display:flex}.ManageUsersModalPopover-module__manageUsersModalMemberPermissions--khy7w span{cursor:pointer}.ManageUsersModalPopover-module__manageUsersModalMemberPermissionsNoPointer--LAzRO{cursor:default}.ManageUsersModalPopover-module__manageUsersModalMemberPermissionsActive--AW99p{color:var(--primary-color)}.ManageUsersModalPopover-module__manageUsersModalMemberPermissionsDisabled--z7No1{pointer-events:none;opacity:.3}.ManageUsersModalPopover-module__manageUsersModalMemberArrow--YsxAm{font-size:1rem !important;margin-left:5px;margin-top:1px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageUsersModalPopover": `ManageUsersModalPopover-module__manageUsersModalPopover--XmK88`,
	"manageUsersModalMemberPermissions": `ManageUsersModalPopover-module__manageUsersModalMemberPermissions--khy7w`,
	"manageUsersModalMemberPermissionsNoPointer": `ManageUsersModalPopover-module__manageUsersModalMemberPermissionsNoPointer--LAzRO`,
	"manageUsersModalMemberPermissionsActive": `ManageUsersModalPopover-module__manageUsersModalMemberPermissionsActive--AW99p`,
	"manageUsersModalMemberPermissionsDisabled": `ManageUsersModalPopover-module__manageUsersModalMemberPermissionsDisabled--z7No1`,
	"manageUsersModalMemberArrow": `ManageUsersModalPopover-module__manageUsersModalMemberArrow--YsxAm`
};
module.exports = ___CSS_LOADER_EXPORT___;
