// PAYMENTS
export const STRIPE_PROD_API_KEY = 'pk_live_QzYeyWpyh83R21NiHIrmF8Lt';
export const STRIPE_TEST_API_KEY = 'pk_test_fX51iecEVtrXMBXrTDVbh1gi';

export const SLACK_INVITE_URL = 'http://chat.comet.ml';
export const GITHUB_ISSUE_TRACKING_URL =
  'https://github.com/comet-ml/issue-tracking/issues';
export const QUERY_PARAMS =
  'name, valueMax, valueMin, valueCurrent, timestampMax, timestampMin, timestampCurrent,runContextMax, runContextMin, runContextCurrent, stepMax, stepMin, stepCurrent';

// ANALYTICS
export const DOWNGRADE_EMAIL = 'mail@comet-ml.com';
export const DOWNGRADE_EMAIL_LINK = `mailto:${DOWNGRADE_EMAIL}?subject=Request%20to%20adjust%20Comet.ml%20account&body=Account%20email%3A%20ACCOUNT_EMAIL%0ACurrent%20plan%3A%20ACCOUNT_PLAN`;
export const ENTERPRISE_EMAIL_ADDRESS = 'enterprise@comet-ml.com';
export const DEBUG = true;

export const DEFAULT_APP_TITLE = 'Comet.ml | Supercharging Machine Learning';
export const DEFAULT_APP_TITLE_BASE = '| Comet.ml';
export const MISSING_EXPERIMENT_DOWNLOAD_BATCH_SIZE = 100;

export const APP_LOCATION = {
  // Page value for single experiment pages
  SINGLE_EXPERIMENT: 'experiment',
  // Page value for dashboard page
  DASHBOARD_PAGE: 'project',
  // Page value for list of projects/user's page
  MULTIPLE_PROJECTS_PAGE: 'MULTIPLE_PROJECTS_PAGE',
  // Page value for experiment comparison
  MULTIPLE_EXPERIMENTS_COMPARE: 'compare',
  // Page value for visualization editor
  REPORT_PAGE: 'report',
  // Page value for visualization editor
  VISUALIZATION_EDITOR: 'viz-editor'
};

// CONFIGURATION - ROUTES
// List of url path's that are public facing, for determining page min-width
export const APP_PATHS = {
  ROOT_PATH: '/',
  PUBLIC_PATHS: ['/claim', '/about', '/pricing']
};

export const SUPPORT_EMAIL = 'support@comet.com';

export const COMET_HEADER_HEIGHT = 60;
