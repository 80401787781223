import {
  getIfPasswordValid,
  getNormalizedPasswordPolicies,
  getPasswordTooltipText
} from '@auth/utils';
import { Box, IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Close from '@material-ui/icons/Close';
import GitHubIcon from '@material-ui/icons/GitHub';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { trackEvent } from '@shared/utils/eventTrack';

import alertsActions from '@/actions/alertsActions';
import userActions from '@/actions/userActions';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import { URLS } from '@/constants/urlConstants';
import withUser from '@auth/utils/withUser';
import {
  BASE_API_URL,
  IS_LDAP,
  IS_ONLINE_MODE,
  SHOULD_SHOW_BASIC_AUTH,
  SHOULD_SHOW_FORGOT_PASSWORD,
  SHOULD_SHOW_GITHUB_AUTH,
  SHOULD_SHOW_GOOGLE_AUTH,
  SHOULD_SHOW_OKTA_AUTH,
  SHOULD_SHOW_ONELOGIN_AUTH,
  SHOULD_SHOW_SIGN_UP_BUTTON,
  SHOULD_SHOW_SSO_AUTH
} from '@/lib/appConstants';
import { isValidEmail } from '@/lib/utils';
import alertsUtil from '@/util/alertsUtil';
import userApi from '@/util/userApi';
import ForgotPasswordModal from '@auth/components/ForgotPasswordModal';
import { CrossedEyeIcon, EyeIcon, KeyIcon } from '@Icons-outdated';
import SimpleSnackbar from './SimpleSnackbar';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';
import { getWorkspaceName } from '@shared/utils/url';
import { SignInSSOModal } from '@auth/components';

import './LoginModal.scss';

const LOGIN_LABEL = IS_LDAP ? 'Username' : 'Email / Username';
const LOGIN_PLACEHOLDER = IS_LDAP ? 'username' : 'user@example.com';

const currentURLEncoded = encodeURIComponent(window.location.href);
const returnURLParam = `returnUrl=${currentURLEncoded}`;

const UTM_PARAMETERS = [
  'http_referer',
  'session_id',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_TrafficCategory',
  'utm_term'
];

const UTM_PARAMETERS_STORAGE_KEY = 'purser_visitor';

const getUTMStorageParameters = () => {
  try {
    const parameters = JSON.parse(
      localStorage.getItem(UTM_PARAMETERS_STORAGE_KEY)
    );

    if (!parameters) return {};

    parameters.http_referer = parameters.referrer;

    return parameters;
  } catch (_) {
    return {};
  }
};

const getUTMParameters = search => {
  const queryParameters = queryString.parse(search);
  const utmStorageParameters = getUTMStorageParameters();

  return UTM_PARAMETERS.reduce((utmParameters, key) => {
    const value = queryParameters[key] || utmStorageParameters[key];

    if (value) {
      // eslint-disable-next-line no-param-reassign
      utmParameters[key] = value;
    }

    return utmParameters;
  }, {});
};

class LoginModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showRegister: props.showRegister,
      showLogin: props.showLogin,
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      isPasswordHidden: true,
      snackBarOpen: false,
      snackBarMessage: '',
      hasAcceptedTOS: false,
      isSubscribe: false,
      passwordPolicy: null,
      isTooltipOpen: false,
      showSSOModal: props.showSSOModal
    };

    // set axios defaults
    axios.defaults.headers.post['Content-Type'] =
      'application/json;charset=utf-8';

    this.loadLoginModal = this.loadLoginModal.bind(this);
    this.loadRegistrationModal = this.loadRegistrationModal.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.login = this.login.bind(this);
    this.signInWithGithub = this.signInWithGithub.bind(this);
    this.getFailureMsg = this.getFailureMsg.bind(this);
    this.openForgotPasswordModal = this.openForgotPasswordModal.bind(this);
    this.githubAuthContent = this.githubAuthContent.bind(this);
    this.basicAuthContent = this.basicAuthContent.bind(this);
    this.handleToggleTOS = this.handleToggleTOS.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.getReturnURL = this.getReturnURL.bind(this);
  }

  componentDidMount() {
    axios.get(`${BASE_API_URL}auth/password-policy`).then(res => {
      this.setState({ passwordPolicy: res.data });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  oneLoginAuthContent() {
    return (
      <div className="github-signin-wrapper">
        <FormGroup>
          <Button
            variant="text"
            style={{
              backgroundColor: '#F5005B',
              color: 'white',
              width: '100%',
              borderRadius: '40px',
              height: '45px'
            }}
            href="/onelogin-login"
            endIcon={<img width="70px" src="/images/oneLogin.png" />}
          >
            {this.state.showLogin ? 'Sign in with' : 'Sign up with'}
          </Button>
        </FormGroup>
      </div>
    );
  }

  getNewPasswordTooltipText() {
    const { passwordPolicy, password, username } = this.state;

    const tooltipText = getPasswordTooltipText({
      passwordPolicy,
      password,
      username,
      fallback: () => {
        this.setState({ isTooltipOpen: false });
      }
    });

    if (tooltipText) return tooltipText;

    this.setState({ isTooltipOpen: false });
  }

  // eslint-disable-next-line class-methods-use-this
  getReturnURL() {
    const { returnUrl = window.location.href } = queryString.parse(
      window.location.search
    );

    const url = new URL(returnUrl, window.location.origin);

    // Bad origin in the returnUrl query string parameter
    if (url.origin !== window.location.origin) {
      return window.location.href;
    }

    return url.href;
  }

  getIsBtnDisabled() {
    const normalizedPasswordPoliciesResults = getNormalizedPasswordPolicies(
      this.state.passwordPolicy,
      {
        value: this.state.password,
        username: this.state.username
      }
    );

    if (!normalizedPasswordPoliciesResults) return true;

    return (
      !getIfPasswordValid({
        password: this.state.password,
        resultNotToMatch: normalizedPasswordPoliciesResults?.resultNotToMatch,
        resultToMatch: normalizedPasswordPoliciesResults?.resultToMatch
      }) ||
      (IS_ONLINE_MODE && !this.state.hasAcceptedTOS)
    );
  }

  getFailureMsg() {
    let failureMsg = '';
    if (!this.state.email || !isValidEmail(this.state.email)) {
      failureMsg += 'Enter a valid email address. ';
    } else if (!this.state.password.length || this.state.password.length < 7) {
      failureMsg += 'Your password must be longer than six characters. ';
    } else if (!this.state.username.length || this.state.username.length < 5) {
      failureMsg += 'Your username must be longer than four characters. ';
    } else if (!this.state.firstName.length || !this.state.lastName.length) {
      failureMsg += 'Please fill in first and last name.';
    } else {
      failureMsg += 'Error with registration.';
    }
    return failureMsg;
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleToggleTOS = () =>
    this.setState(prevState => ({ hasAcceptedTOS: !prevState.hasAcceptedTOS }));

  handleSubscribe = () =>
    this.setState(prevState => ({ isSubscribe: !prevState.isSubscribe }));

  signInWithGoogle = () => {
    const { isModal, onClose, workspaceCode } = this.props;

    const workspaceInviteParam = workspaceCode
      ? `&workspaceCode=${workspaceCode}`
      : '';

    window.location.href = `${BASE_API_URL}google/start?${returnURLParam}${workspaceInviteParam}`;

    if (isModal) {
      onClose();
    }
  };

  signInWithGithub() {
    const { isModal, onClose, workspaceCode, canClaimAnonData } = this.props;
    const anonymousWorkspaceName =
      canClaimAnonData && isModal && getWorkspaceName();
    const utmParameters = getUTMParameters(window.location.search);

    const workspaceInviteParam = workspaceCode
      ? `&workspaceCode=${workspaceCode}`
      : '';

    const utmParam =
      Object.keys(utmParameters).length > 0
        ? `&${queryString.stringify(utmParameters)}`
        : '';

    const authCallbackURL = encodeURIComponent(
      `${window.location.origin}/auth/callback`
    );
    const returnURLParam = `returnUrl=${authCallbackURL}`;
    const popupURL = `${BASE_API_URL}github/start?${returnURLParam}${workspaceInviteParam}${utmParam}`;

    this.openOAuthPopup(
      popupURL,
      anonymousWorkspaceName &&
        `${window.location.origin}/claim-anonymous?workspaceName=${anonymousWorkspaceName}`
    );

    if (isModal) {
      onClose();
    }
  }

  openForgotPasswordModal() {
    this.props.onClose();
    this.props.dispatch(
      alertsActions.openDialog(dialogTypes.FORGOT_PASSWORD, {
        children: <ForgotPasswordModal />
      })
    );
  }

  openOAuthPopup(url, redirectUrl) {
    const opener = window.open(
      url,
      '',
      'top=100,left=100,height=600,width=600,scrollbars=yes'
    );

    const intervalId = setInterval(() => {
      if (opener?.closed) {
        onLoginSuccess();
      }
    }, 500);

    const onLoginSuccess = () => {
      clearInterval(intervalId);
      const returnURL = this.getReturnURL();

      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      }

      if (window.location.href === returnURL) {
        window.location.reload();
      } else {
        window.location.href = returnURL;
      }
    };

    if (opener) {
      opener.onLoginSuccess = onLoginSuccess;
    }
  }

  createAccount(e) {
    const { isModal, canClaimAnonData } = this.props;
    const anonymousWorkspaceName =
      canClaimAnonData && isModal && getWorkspaceName();
    e.preventDefault();

    if (this.state.showLogin) {
      return this.setState({ showLogin: false, showRegister: true });
    }

    const utmParameters = getUTMParameters(window.location.search);

    const data = {
      isSubscribe: this.state.isSubscribe,
      subscribeToNewsletter: this.state.isSubscribe,
      userName: this.state.username,
      email: this.state.email,
      plainTextPassword: this.state.password,
      apiKey:
        this.props.useExistingApiKey && !this.props.isReadOnly
          ? this.props.apiKey
          : null,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      signupSource: window.location.href,
      ...utmParameters
    };

    const isEmailValid = isValidEmail(this.state.email);
    if (isEmailValid) {
      if (this.state.showRegister) {
        const isPasswordValid = this.state.password.length > 6;
        const areNamesValid =
          this.state.firstName !== '' && this.state.lastName !== '';

        if (isEmailValid && isPasswordValid && areNamesValid) {
          this.props.dispatch(
            userActions.createUser(data, {
              workspaceCode: this.props.workspaceCode,
              workspaceName: anonymousWorkspaceName,
              canClaimAnonData
            })
          );
        } else {
          const failureMsg = this.getFailureMsg();
          this.props.dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_KEY_GEN,
              failureMsg
            )
          );
        }
      } else {
        this.props.history.push('closed');
        window.location.href = `${window.location.origin}/closed`;
        this.props.onClose(() => {});
      }
    } else {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: 'Please enter a valid email address'
      });
    }
  }

  constructSignUp() {
    const authOptions = [];
    if (SHOULD_SHOW_BASIC_AUTH) {
      authOptions.push(this.basicAuthContent());
    }

    const final = [];
    for (let i = 0; i < authOptions.length; i++) {
      final.push(authOptions[i]);
    }

    return final;
  }

  loadLoginModal() {
    this.setState({ showLogin: true, showRegister: false });
  }

  loadRegistrationModal() {
    this.setState({ showLogin: false, showRegister: true });
  }

  login(e) {
    const { isModal, canClaimAnonData } = this.props;
    const workspaceName = getWorkspaceName();
    e.preventDefault();
    if (this.state.showRegister) {
      return this.setState({ showLogin: true, showRegister: false });
    }

    const { utm_medium, utm_source, utm_content, utm_term } = queryString.parse(
      location.search
    );
    const utms = {};
    if (utm_medium !== undefined) utms.utm_medium = utm_medium;
    if (utm_source !== undefined) utms.utm_source = utm_source;
    if (utm_content !== undefined) utms.utm_content = utm_content;
    if (utm_term !== undefined) utms.utm_term = utm_term;

    const data = {
      email: this.state.email,
      plainTextPassword: this.state.password,
      ...utms
    };
    userApi
      .loginUser(data, this.props.workspaceCode)
      .then(response => {
        trackEvent('login', { method: 'Form' });
        trackEvent('LOGIN_SUCCESS_FE');
        const { claimResult, defaultWorkspace } = response.data;

        if (canClaimAnonData && isModal) {
          window.location.href = `/claim-anonymous?workspaceName=${workspaceName}`;
          return;
        }

        if (!claimResult) {
          if (isModal) {
            window.location.reload();
          } else {
            window.location.href = this.getReturnURL();
          }
          return;
        }

        const { claimMsg, claimSuccess, claimedWorkspace } = claimResult;
        const claimQueryString = queryString.stringify({
          claimResult: claimMsg,
          claimStatus: claimSuccess
        });
        const workspace = claimedWorkspace || defaultWorkspace;
        const redirectPath = isModal
          ? window.location.pathname
          : `/${workspace}`;

        window.location.href = `${redirectPath}?${claimQueryString}`;
      })
      .catch(error => {
        let failureMsg = 'Login failed.';
        if (error && error.responseJSON && error.responseJSON.message) {
          failureMsg = error.responseJSON.message;
        }

        this.props.dispatch(
          alertsUtil.openErrorDialog(dialogTypes.CATCH_ERROR_LOGIN, failureMsg)
        );
      });
  }

  githubAuthContent() {
    return (
      <div className="github-signin-wrapper">
        <FormGroup>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            style={{
              color: 'white',
              width: '100%',
              borderRadius: '40px',
              height: '45px'
            }}
            onClick={this.signInWithGithub}
            endIcon={<GitHubIcon htmlColor="white" />}
          >
            {this.state.showLogin
              ? ' Sign in with GitHub'
              : ' Sign up with GitHub'}
          </Button>
        </FormGroup>
      </div>
    );
  }

  ssoAuthContent() {
    return (
      <div className="github-signin-wrapper">
        <FormGroup>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            style={{
              color: 'white',
              width: '100%',
              borderRadius: '40px',
              height: '45px'
            }}
            onClick={() => this.setState({ showSSOModal: true })}
            endIcon={<KeyIcon />}
          >
            {this.state.showLogin ? ' Sign in with SSO' : ' Sign up with SSO'}
          </Button>
        </FormGroup>
      </div>
    );
  }

  basicAuthContent() {
    const { workspaceCode } = this.props;
    const emailUsernameLabel = this.state.showLogin ? LOGIN_LABEL : 'Email';

    return (
      <div className="form-wrapper-grid">
        <FormGroup>
          <TextField
            InputProps={{
              disableUnderline: true
            }}
            InputLabelProps={{
              shrink: true
            }}
            label={emailUsernameLabel}
            placeholder={LOGIN_PLACEHOLDER}
            value={this.state.email}
            onChange={this.handleChange('email')}
            required
            margin="normal"
            id="email"
            data-test="login-email"
          />

          {this.state.showRegister && (
            <Box display="flex" justifyContent="space-between">
              <TextField
                className="halfInput"
                InputProps={{
                  disableUnderline: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                label="First name"
                placeholder="First name"
                value={this.state.firstName}
                onChange={this.handleChange('firstName')}
                required
                margin="normal"
                id="firstName"
                data-test="register-firstName"
              />

              <TextField
                className="halfInput"
                InputProps={{
                  disableUnderline: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                props
                label="Last name"
                placeholder="Last name"
                value={this.state.lastName}
                onChange={this.handleChange('lastName')}
                required
                margin="normal"
                id="lastName"
                data-test="register-lastName"
              />
            </Box>
          )}
          {this.state.showRegister && (
            <TextField
              InputProps={{
                disableUnderline: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              label="Username"
              placeholder="Username"
              value={this.state.username}
              onChange={this.handleChange('username')}
              required
              margin="normal"
              id="username"
              data-test="login-username"
            />
          )}
          {this.state.showRegister ? (
            <StyledValidationTooltip
              title={this.getNewPasswordTooltipText()}
              open={this.state.isTooltipOpen}
            >
              <TextField
                InputProps={{
                  onFocus: () => this.setState({ isTooltipOpen: true }),
                  onKeyUp: () => this.setState({ isTooltipOpen: true }),
                  autoComplete: 'off',
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      onClick={() =>
                        this.setState(prevState => ({
                          isPasswordHidden: !prevState.isPasswordHidden
                        }))
                      }
                    >
                      <IconButton style={{ backgroundColor: 'transparent' }}>
                        {this.state.isPasswordHidden ? (
                          <CrossedEyeIcon />
                        ) : (
                          <EyeIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
                label="Password"
                placeholder="******"
                value={this.state.password}
                onChange={this.handleChange('password')}
                required
                margin="normal"
                type={this.state.isPasswordHidden ? 'password' : 'text'}
                id="password"
                data-test="register-repassword"
              />
            </StyledValidationTooltip>
          ) : (
            <TextField
              InputProps={{
                disableUnderline: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              label="Password"
              placeholder="******"
              value={this.state.password}
              onChange={this.handleChange('password')}
              required
              margin="normal"
              type="password"
              id="password"
              data-test="login-password"
            />
          )}

          {this.state.showRegister && IS_ONLINE_MODE && (
            <>
              <Box
                display="flex"
                paddingTop="10px"
                justifyContent="start"
                alignItems="center"
              >
                <Checkbox
                  checked={this.state.hasAcceptedTOS}
                  value="hasAcceptedTOS"
                  icon={
                    <Box
                      data-test="signup-tos-checkbox"
                      onClick={this.handleToggleTOS}
                      width="18px"
                      height="18px"
                      border="1px solid #DAD8D8"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon
                      style={{ fontSize: '18px' }}
                      data-test="signup-tos-checkbox"
                      onClick={this.handleToggleTOS}
                    />
                  }
                  classes={{ root: 'checkbox' }}
                />

                <p style={{ fontSize: 13, fontWeight: 300 }}>
                  <span>
                    <span>I have read and agree to the </span>
                    <a
                      href={URLS.PRIVACY}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    <span> and </span>
                    <a
                      href={URLS.TERMS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>
                  </span>
                </p>
              </Box>

              <Box
                display="flex"
                paddingTop="10px"
                justifyContent="start"
                alignItems="center"
              >
                <Checkbox
                  checked={this.state.isSubscribe}
                  value="isSubscribe"
                  icon={
                    <Box
                      onClick={this.handleSubscribe}
                      width="18px"
                      height="18px"
                      border="1px solid #DAD8D8"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon
                      style={{
                        fontSize: '18px'
                      }}
                      onClick={this.handleSubscribe}
                    />
                  }
                  classes={{ root: 'checkbox' }}
                />
                <p style={{ fontSize: 13, fontWeight: 300 }}>
                  Please send me emails containing Comet updates and promotions
                </p>
              </Box>
            </>
          )}
          <div className="form-button-wrapper">
            {this.state.showLogin && (
              <Button
                type="submit"
                disableElevation
                onClick={e => this.login(e)}
                color={this.state.showLogin ? 'primary' : 'default'}
                variant="contained"
                id="login-button"
              >
                Login {workspaceCode && 'and claim'}
              </Button>
            )}
            {this.state.showRegister && (
              <Button
                type="submit"
                disableElevation
                disabled={this.getIsBtnDisabled()}
                onClick={e => this.createAccount(e)}
                color={this.state.showRegister ? 'primary' : 'default'}
                variant="contained"
                id="submit-register"
                data-test="submit-register"
              >
                Create account {workspaceCode && 'and claim'}
              </Button>
            )}
          </div>
        </FormGroup>
      </div>
    );
  }

  googleAuthContent() {
    return (
      <div className="github-signin-wrapper">
        <FormGroup>
          <Button
            variant="text"
            style={{
              backgroundColor: '#F5005B',
              color: 'white',
              width: '100%',
              borderRadius: '40px',
              height: '45px'
            }}
            onClick={this.signInWithGoogle}
            endIcon={<img width="15px" src="/images/GoogleLogo.png" />}
          >
            {this.state.showLogin
              ? 'Sign in with Google'
              : 'Sign up with Google'}
          </Button>
        </FormGroup>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  oktaAuthContent() {
    return (
      <div className="github-signin-wrapper">
        <FormGroup>
          <Button
            variant="text"
            style={{
              backgroundColor: '#F5005B',
              color: 'white',
              width: '100%',
              borderRadius: '40px',
              height: '45px'
            }}
            href="/okta-login"
            endIcon={<img width="50px" src="/images/okta.png" />}
          >
            {this.state.showLogin ? 'Sign in with' : 'Sign up with'}
          </Button>
        </FormGroup>
      </div>
    );
  }

  renderOauthBtns() {
    const authOptions = [];

    if (SHOULD_SHOW_SSO_AUTH) {
      authOptions.push(this.ssoAuthContent());
    }

    if (SHOULD_SHOW_GITHUB_AUTH) {
      authOptions.push(this.githubAuthContent());
    }

    if (SHOULD_SHOW_GOOGLE_AUTH) {
      authOptions.push(this.googleAuthContent());
    }
    if (SHOULD_SHOW_OKTA_AUTH) {
      authOptions.push(this.oktaAuthContent());
    }
    if (SHOULD_SHOW_ONELOGIN_AUTH) {
      authOptions.push(this.oneLoginAuthContent());
    }

    if (!authOptions.length) {
      return null;
    }

    return (
      <>
        <Box display="flex" justifyContent="center" className="authBtnsWrapper">
          {authOptions}
        </Box>
        <DividerWithText> or </DividerWithText>
      </>
    );
  }

  renderSSOModal() {
    return (
      <SignInSSOModal
        onClose={() => this.setState({ showSSOModal: false })}
        onError={message => {
          this.props.dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.ERROR_MESSAGE_PARAM_MODAL,
              message
            )
          );

          this.setState({ showSSOModal: false });
        }}
        shouldKeepLocation={this.props.isModal}
      />
    );
  }

  render() {
    const {
      workspaceCode,
      fullScreen,
      open,
      onClose,
      isModal,
      DialogProps
    } = this.props;

    // eslint-disable-next-line react/no-unused-state

    let forgotPasswordButton;
    if (isModal) {
      forgotPasswordButton = (
        <a
          style={{
            fontSize: '13px',
            whiteSpace: 'nowrap',
            fontWeight: 300
          }}
          className="forgot-password"
          onClick={this.openForgotPasswordModal}
        >
          Forgot Password
        </a>
      );
    } else {
      forgotPasswordButton = (
        <Link
          style={{
            fontSize: '13px',
            whiteSpace: 'nowrap',
            fontWeight: 300
          }}
          className="forgot-password"
          to="/reset-password"
        >
          Forgot Password
        </Link>
      );
    }

    const defaultTitle = workspaceCode
      ? this.state.showLogin
        ? 'Log in to claim your Comet invite'
        : 'Sign up to claim your Comet invite'
      : this.state.showLogin
      ? 'Log in to Comet'
      : 'Sign up for Comet';

    const displayTitle = this.props.customTitle
      ? this.props.customTitle
      : defaultTitle;

    return (
      <div id="login-modal">
        <Dialog
          maxWidth="md"
          fullScreen={fullScreen}
          open={open}
          className="text-center login-modal"
          onClose={() => onClose()}
          data-test="login-modal"
          TransitionProps={{ tabIndex: 'none' }}
          {...DialogProps}
        >
          <form
            onSubmit={this.state.showRegister ? this.createAccount : this.login}
          >
            <DialogTitle className={fullScreen ? '' : 'modal-title'}>
              <h3
                data-test="login-modal-title"
                style={{ fontSize: 22, margin: 0, padding: 0 }}
              >
                {displayTitle}
              </h3>
              <IconButton
                style={{
                  borderRadius: 10,
                  padding: 5,
                  right: 20
                }}
                className="close-button"
                onClick={() => onClose()}
              >
                <Close fontSize="small" />
              </IconButton>
            </DialogTitle>

            {this.renderOauthBtns()}

            <> {this.constructSignUp().map(val => val)} </>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={0}>
                  <Grid item>
                    {SHOULD_SHOW_SIGN_UP_BUTTON && this.state.showLogin && (
                      <Box display="flex" alignItem="center">
                        <p
                          style={{
                            fontSize: '13px',
                            whiteSpace: 'nowrap',
                            fontWeight: 300
                          }}
                        >
                          Don&apos;t have an account?
                          <a onClick={this.loadRegistrationModal}> Sign up</a>
                        </p>
                        {SHOULD_SHOW_FORGOT_PASSWORD && forgotPasswordButton}
                      </Box>
                    )}
                    {this.state.showRegister && (
                      <div style={{ fontSize: '14px', fontWeight: 300 }}>
                        Have an account already?{' '}
                        <a
                          onClick={this.loadLoginModal}
                          data-test="login-modal-open-login"
                        >
                          Log in
                        </a>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Dialog>
        <SimpleSnackbar
          open={this.state.snackBarOpen}
          onClose={() => this.setState({ snackBarOpen: false })}
          message={this.state.snackBarMessage}
        />
        {this.state.showSSOModal ? this.renderSSOModal() : null}
      </div>
    );
  }
}

LoginModal.defaultProps = {
  cancelText: 'Cancel',
  onClose() {},
  fullScreen: false,
  isModal: true,
  useExistingApiKey: false,
  workspaceCode: null,
  DialogProps: null,
  showSSOModal: false
};

LoginModal.propTypes = {
  apiKey: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  canClaimAnonData: PropTypes.bool,
  workspaceCode: PropTypes.string,
  isModal: PropTypes.bool,
  isReadOnly: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool.isRequired,
  showSSOModal: PropTypes.bool,
  showRegister: PropTypes.bool.isRequired,
  useExistingApiKey: PropTypes.bool,
  DialogProps: PropTypes.object
};

export default withRouter(withUser(LoginModal));

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  border: {
    borderBottom: '1.8px solid lightgray',
    width: '15%'
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 16,
    color: 'lightgray'
  }
}));

export const DividerWithText = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};
