import React from 'react';

import { DSArrowRightIcon } from '@ds-icons';
import { BreadcrumbItemProps, BreadcrumbItemType } from '@shared/types';
import HomeItem from '@platform/components/Breadcrumbs/components/HomeItem';
import LinkItem from '@platform/components/Breadcrumbs/components/LinkItem';
import TextItem from '@platform/components/Breadcrumbs/components/TextItem';
import ExperimentItem from '@platform/components/Breadcrumbs/components/ExperimentItem';
import CompareItem from '@platform/components/Breadcrumbs/components/CompareItem';
import CollapseItem from '@platform/components/Breadcrumbs/components/CollapseItem';

import styles from './BreadcrumbItem.module.scss';

const COMPONENTS_MAP: Record<
  BreadcrumbItemType,
  React.FunctionComponent<BreadcrumbItemProps>
> = {
  home: HomeItem,
  link: LinkItem,
  text: TextItem,
  experiment: ExperimentItem,
  compare: CompareItem,
  collapse: CollapseItem
};

const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  const { isLast } = props;
  const Item = COMPONENTS_MAP[props.type];

  return (
    <>
      <Item {...props}></Item>
      {!isLast && (
        <div className={styles.divider}>
          <DSArrowRightIcon />
        </div>
      )}
    </>
  );
};

export default BreadcrumbItem;
