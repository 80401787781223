import React from 'react';
import { BasicModal } from '@DesignSystem/modals';
import { useExperimentKeys } from '@experiment-management-shared/hooks';

import useDeleteHyperParameterMutation from '../../../api/hyperParameters/useDeleteHyperParameterMutation';
import classNames from '../HyperParametersTab.module.scss';

type DeleteParameterModalProps = {
  open: boolean;
  onClose: () => void;
  parameterKey?: string;
};
const DeleteParameterModal: React.FC<DeleteParameterModalProps> = ({
  open,
  onClose,
  parameterKey = ''
}) => {
  // it's shown on one experiment detail view only, so there is one experiment only
  const [experimentKey] = useExperimentKeys();
  const deleteHyperParameterMutation = useDeleteHyperParameterMutation();

  const handlePrimaryButtonClick = () => {
    deleteHyperParameterMutation.mutate({ experimentKey, name: parameterKey });
    onClose();
  };

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Are you sure?"
      content={
        <div className={classNames.deleteHyperParameterContainer}>
          The parameter <b>{parameterKey}</b> will be removed. Once deleted, it
          cannot be recovered.
        </div>
      }
      primaryButtonText="Yes, delete"
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={handlePrimaryButtonClick}
    />
  );
};

export default DeleteParameterModal;
