// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtherSingleTable-module__othersTable--VJXAH{position:relative;z-index:5;padding-left:var(--experiment-details-layout-padding);padding-right:var(--experiment-details-layout-padding);padding-bottom:var(--experiment-details-layout-padding)}.OtherSingleTable-module__othersTable--VJXAH .ds-react-grid{border:1px solid var(--gray);border-radius:5px;overflow:hidden}.OtherSingleTable-module__othersTable--VJXAH .ds-react-grid.virtualized{height:var(--table-maxheight)}.OtherSingleTable-module__othersTable--VJXAH .ds-react-grid tr:hover,.OtherSingleTable-module__othersTable--VJXAH .ds-react-grid tr:hover td.TableFixedCell-fixedCell{background-color:#f7f8fa}.OtherSingleTable-module__othersTable--VJXAH .empty-tab-message{padding-top:120px}.OtherSingleTable-module__otherTableDeleteModalContent--nZfGW{text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"othersTable": `OtherSingleTable-module__othersTable--VJXAH`,
	"otherTableDeleteModalContent": `OtherSingleTable-module__otherTableDeleteModalContent--nZfGW`
};
module.exports = ___CSS_LOADER_EXPORT___;
