import cx from 'classnames';
import isEqual from 'fast-deep-equal';
import {
  defaultTo,
  difference,
  get,
  intersection,
  noop,
  pick,
  uniq
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

import GridLayout from 'react-grid-layout';
import { DragIcon } from '@Icons-outdated';
import { Tooltip } from '@ds';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { BATCH_CHART_CONFIG } from '@experiment-management-shared/constants/chartConstants';
import {
  areLayoutsArraysEqual,
  initializeLayout,
  initializeSearchLayout
} from '@experiment-management-shared/utils/layout';
import { useObserveResizeNode } from '@shared/hooks/useObserveResizeNode';
import useBatchChartContainerGridHandlers from '@experiment-management-shared/hooks/useBatchChartContainerGridHandlers';

import ChartPlaceholderWrapper from './ChartPlaceholderWrapper';
const { COLUMNS, ROW_HEIGHT } = BATCH_CHART_CONFIG;

const usePassThrough = (callback, id) => {
  return useCallback(
    (...args) => {
      callback(id, ...args);
    },
    [id, callback]
  );
};

// eslint-disable-next-line react/display-name
const Chart = React.memo(
  ({
    config,
    dataPanelsRows,
    dataRevision,
    isFullscreen,
    isPendingRendering,
    onDataSourceChange,
    onExitFullscreen,
    onFinishRender,
    onFullscreen,
    onPanelChange,
    onUpdateDataPanelRows,
    onVisibilityChange,
    visibilityDebounceDelay,
    panelSharedConfig
  }) => {
    const id = config.chartId;

    const handleDataSourceChange = usePassThrough(onDataSourceChange, id);
    const handleFinishRender = usePassThrough(onFinishRender, id);
    const handlePanelChange = usePassThrough(onPanelChange, id);
    const handleVisibilityChange = usePassThrough(onVisibilityChange, id);
    const handleUpdateDataPanelRows = usePassThrough(onUpdateDataPanelRows, id);

    const ChartProps = useMemo(() => {
      return {
        config,
        dataRevision,
        enableDataRevision: true,
        isFullscreen,
        isPendingRendering,
        onDataSourceChange: handleDataSourceChange,
        onExitFullscreen,
        onFinishRender: handleFinishRender,
        onFullscreen,
        panelSharedConfig
      };
    }, [
      config,
      dataRevision,
      handleDataSourceChange,
      handleFinishRender,
      isFullscreen,
      onExitFullscreen,
      onFullscreen,
      isPendingRendering,
      panelSharedConfig
    ]);

    return (
      <ChartPlaceholderWrapper
        dataPanelsRows={dataPanelsRows}
        onPanelChange={handlePanelChange}
        onUpdateDataPanelRows={handleUpdateDataPanelRows}
        onVisibilityChange={handleVisibilityChange}
        visibilityDebounceDelay={visibilityDebounceDelay}
        ChartProps={ChartProps}
      />
    );
  },
  isEqual
);

Chart.propTypes = {
  config: PropTypes.object.isRequired,
  dataRevision: PropTypes.number.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  isPendingRendering: PropTypes.bool.isRequired,
  onDataSourceChange: PropTypes.func.isRequired,
  onExitFullscreen: PropTypes.func.isRequired,
  onFinishRender: PropTypes.func.isRequired,
  onFullscreen: PropTypes.func.isRequired,
  onPanelChange: PropTypes.func.isRequired,
  onUpdateDataPanelRows: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  visibilityDebounceDelay: PropTypes.number.isRequired,
  panelSharedConfig: PropTypes.object
};

const BatchChartContainer = ({
  configs,
  entireRowCharts,
  isSearchMode,
  layout,
  onLayoutChange,
  onPanelChange,
  parallelRender,
  visibilityDebounceDelay,
  width,
  panelSharedConfig,
  shouldSkipLayoutInitialization
}) => {
  const skipLayoutInitialization = useRef(shouldSkipLayoutInitialization);
  const chartsVisibilityRef = useRef({});
  const [dataRevisions, setDataRevisions] = useState({});
  const [dataPanelsRows, setDataPanelsRows] = useState({});
  const [renderingQueue, setRenderingQueue] = useState([]);
  const [renderQueue, setRenderQueue] = useState([]);

  // Clean states on chart IDs changes
  const chartIds = configs.map(config => config.chartId).sort();
  useDeepCompareEffect(() => {
    const cleanObject = object => {
      const newObject = pick(object, chartIds);

      if (Object.keys(newObject).length === Object.keys(object).length) {
        return object;
      }

      return newObject;
    };

    const cleanQueue = queue => {
      const newQueue = intersection(queue, chartIds);

      if (newQueue.length === queue.length) return queue;

      return newQueue;
    };

    chartsVisibilityRef.current = cleanObject(chartsVisibilityRef.current);

    batchedUpdates(() => {
      setDataRevisions(cleanObject);
      setRenderingQueue(cleanQueue);
      setRenderQueue(cleanQueue);
    });
  }, [chartIds]);

  const getDataRevision = useCallback(id => defaultTo(dataRevisions[id], 0), [
    dataRevisions
  ]);

  const checkRenderQueue = () => {
    if (!renderQueue.length) return;

    const renderAmount = parallelRender - renderingQueue.length;

    if (renderAmount <= 0) return;

    const renderQueueOrdered = [...renderQueue].sort((idA, idB) => {
      const visibilityA = get(chartsVisibilityRef.current, idA, 0);
      const visibilityB = get(chartsVisibilityRef.current, idB, 0);

      return visibilityB - visibilityA;
    });
    const currentRenders = renderQueueOrdered.slice(0, parallelRender);

    batchedUpdates(() => {
      setDataRevisions(currentDataRevisions => {
        const newDataRevisions = currentRenders.reduce((result, id) => {
          // eslint-disable-next-line no-param-reassign
          result[id] = defaultTo(currentDataRevisions[id], 0) + 1;

          return result;
        }, {});

        return {
          ...currentDataRevisions,
          ...newDataRevisions
        };
      });
      setRenderingQueue(queue => uniq([...queue, ...currentRenders]));
      setRenderQueue(queue => {
        const pendingRenderQueue = difference(queue, currentRenders);

        if (isEqual(queue, pendingRenderQueue)) return queue;

        return pendingRenderQueue;
      });
    });
  };

  useEffect(checkRenderQueue, [parallelRender, renderingQueue, renderQueue]);

  const handleChartVisibilityChange = useCallback((id, inView, entry) => {
    const value = get(entry, 'intersectionRatio', 0);

    chartsVisibilityRef.current[id] = value;
  }, []);

  const handleDataSourceChange = useCallback(id => {
    setRenderQueue(queue => {
      if (queue.includes(id)) return queue;

      return [...queue, id];
    });
  }, []);

  const handleFinishRender = useCallback(id => {
    setRenderingQueue(queue => {
      const newQueue = queue.filter(queueId => queueId !== id);

      if (newQueue.length === queue.length) return queue;

      return newQueue;
    });
  }, []);

  const calculatedLayout = useMemo(() => {
    const retVal = initializeLayout({
      panels: configs,
      initialLayout: layout,
      entireRowCharts
    });

    if (isSearchMode) {
      return initializeSearchLayout({ panels: configs, layout: retVal });
    }

    return retVal;
  }, [configs, entireRowCharts, isSearchMode, layout]);

  const handleLayoutChange = useCallback(
    newLayout => {
      if (
        !isSearchMode &&
        !areLayoutsArraysEqual(newLayout, calculatedLayout) &&
        !skipLayoutInitialization.current
      ) {
        onLayoutChange(newLayout);
      }

      skipLayoutInitialization.current = false;
    },
    [isSearchMode, calculatedLayout, onLayoutChange]
  );

  const handleUpdateDataPanelRows = useCallback((id, name, rows) => {
    setDataPanelsRows(previousDataPanelsRows => ({
      ...previousDataPanelsRows,
      [name]: rows
    }));
  }, []);

  const ChartsProps = useMemo(() => {
    return configs.map(config => {
      const id = config.chartId;
      const dataRevision = getDataRevision(id);
      const isPendingRendering = renderQueue.includes(id);

      return {
        config,
        dataPanelsRows,
        dataRevision,
        isPendingRendering,
        onDataSourceChange: handleDataSourceChange,
        onFinishRender: handleFinishRender,
        onPanelChange,
        onUpdateDataPanelRows: handleUpdateDataPanelRows,
        onVisibilityChange: handleChartVisibilityChange,
        visibilityDebounceDelay,
        panelSharedConfig
      };
    });
  }, [
    configs,
    getDataRevision,
    renderQueue,
    dataPanelsRows,
    handleDataSourceChange,
    handleFinishRender,
    onPanelChange,
    handleUpdateDataPanelRows,
    handleChartVisibilityChange,
    visibilityDebounceDelay,
    panelSharedConfig
  ]);

  const {
    onDragStart,
    onDragStop,
    onResizeStart,
    onResizeStop,
    onMouseUp,
    isLayoutEditing
  } = useBatchChartContainerGridHandlers();

  const renderChart = ChartProps => {
    const {
      config: { chartId }
    } = ChartProps;

    return (
      <div className="chart-grid-item" key={chartId}>
        <Chart {...ChartProps} />
        <Tooltip
          content="To drag and resize panels, first clear the search."
          disableListeners={!isSearchMode}
          wrapper={false}
        >
          <div
            className={cx('chart-drag-handle', {
              disabled: isSearchMode
            })}
            onMouseUp={onMouseUp}
          >
            <DragIcon />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <GridLayout
      className={cx('edit-dashboard-layout-container', {
        'react-grit-layout-editing': isLayoutEditing
      })}
      cols={COLUMNS}
      draggableHandle=".chart-drag-handle"
      resizeHandle={
        <div>
          <Tooltip
            content="To drag and resize panels, first clear the search."
            disableListeners={!isSearchMode}
            wrapper={false}
          >
            <div
              className={cx('react-resizable-handle', {
                disabled: isSearchMode
              })}
            ></div>
          </Tooltip>
        </div>
      }
      isDraggable={!isSearchMode}
      isResizable={!isSearchMode}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      layout={calculatedLayout}
      onLayoutChange={handleLayoutChange}
      rowHeight={ROW_HEIGHT}
      width={width}
    >
      {ChartsProps.map(renderChart)}
    </GridLayout>
  );
};

BatchChartContainer.defaultProps = {
  configs: [],
  entireRowCharts: false,
  isSearchMode: false,
  layout: null,
  onLayoutChange: noop,
  onPanelChange: noop,
  parallelRender: 2,
  shouldSkipLayoutInitialization: false,
  visibilityDebounceDelay: 1000,
  width: 980
};

BatchChartContainer.propTypes = {
  configs: PropTypes.array,
  entireRowCharts: PropTypes.bool,
  isSearchMode: PropTypes.bool,
  layout: PropTypes.array,
  onLayoutChange: PropTypes.func,
  onPanelChange: PropTypes.func,
  parallelRender: PropTypes.number,
  visibilityDebounceDelay: PropTypes.number,
  width: PropTypes.number,
  panelSharedConfig: PropTypes.object,
  shouldSkipLayoutInitialization: PropTypes.bool
};

const WidthContainer = props => {
  const [containerWidth, setContainerWidth] = useState(null);

  const resizeRef = useObserveResizeNode(node =>
    setContainerWidth(node.clientWidth)
  );

  if (!containerWidth) {
    return <div ref={resizeRef} style={{ height: '400px', width: '100%' }} />;
  }

  return (
    <div ref={resizeRef} style={{ width: '100%' }}>
      <BatchChartContainer {...props} width={containerWidth} />
    </div>
  );
};

export default WidthContainer;
