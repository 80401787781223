import React from 'react';
import { Table, TableMaxHeightProvider } from '@DesignSystem/tables';
import MetricsEmptyState from './MetricsEmptyState';
import {
  DATETIME_CELL_KEYS,
  METRIC_COLUMNS_NAME,
  METRIC_TABLE_COLUMNS,
  METRIC_TABLE_COLUMN_BANDS,
  STEP_CELL_KEYS,
  VALUE_CELL_KEYS,
  disableConfig
} from './metricsTableConfig';
import { Metric } from '@shared/types';
import MetricsTableTimeCell from './cells/MetricsTableTimeCell';
import MetricsTableValueCell from './cells/MetricsTableValueCell';
import MetricsTableNameCell from './cells/MetricsTableNameCell';
import useMetricTableColumnWidths from './hooks/useMetricTableColumnWidths';
import MetricsTableStepCell from './cells/MetricsTableStepCell';
import { MetricDataContext } from './hooks/metricDataContext';
import styles from './MetricsTable.module.scss';
import { PARAM_ACTION_COLUMN } from '../params/helpers';
import MetricsTableActionCell from './cells/MetricsTableActionCell';
import { useRefSizes } from '@shared/hooks';

const dataTypes = [
  {
    cols: DATETIME_CELL_KEYS,
    cell: MetricsTableTimeCell
  },
  {
    cols: STEP_CELL_KEYS,
    cell: MetricsTableStepCell
  },
  {
    cols: VALUE_CELL_KEYS,
    cell: MetricsTableValueCell
  },
  {
    cols: [METRIC_COLUMNS_NAME.NAME],
    cell: MetricsTableNameCell
  },
  {
    cols: [PARAM_ACTION_COLUMN],
    cell: MetricsTableActionCell
  }
];

type MetricsTableNewProps = {
  searchText: string;
  resetSearch: () => void;
  rowData: Metric[];
  isFetching: boolean;
  maxDecimalsInItems: number;
  decimalsPrecision: number;
  onEditMetric: (rowData: Metric) => void;
  onDeleteMetric: (key: string) => void;
  defaultHiddenColumnNames: string[];
};
const MetricsTableNew: React.FC<MetricsTableNewProps> = ({
  searchText,
  resetSearch,
  rowData,
  isFetching,
  onDeleteMetric,
  onEditMetric,
  decimalsPrecision,
  maxDecimalsInItems,
  defaultHiddenColumnNames
}) => {
  const { refWidth = 0, sizeRef } = useRefSizes();
  const {
    columnWidths,
    onColumnWidthsChange,
    columnExtensions,
    disabledColumns
  } = useMetricTableColumnWidths({
    rowData,
    containerWidth: refWidth,
    defaultHiddenColumnNames
  });

  return (
    <div className={styles.metricsTable} ref={sizeRef}>
      <TableMaxHeightProvider>
        <MetricDataContext.Provider
          value={{
            onEditMetric,
            onDeleteMetric,
            decimalsPrecision: decimalsPrecision ?? maxDecimalsInItems
          }}
        >
          <Table
            dataTypes={dataTypes}
            columns={METRIC_TABLE_COLUMNS}
            rows={rowData}
            headerCellCentered
            columnBands={METRIC_TABLE_COLUMN_BANDS}
            rowIdKey="name"
            height="auto"
            isFetching={isFetching}
            paginationConfig={disableConfig}
            columnOrderConfig={disableConfig}
            selectionConfig={disableConfig}
            columnWidthsConfig={{
              isDisabled: false,
              columnWidths,
              onColumnWidthsChange,
              columnExtensions,
              disabledColumns
            }}
            resizingMode="widget"
            rightColumns={[PARAM_ACTION_COLUMN]}
            renderEmptyState={materialProps => (
              <MetricsEmptyState
                searchValue={searchText}
                resetSearch={resetSearch}
                isLoading={isFetching}
                {...materialProps}
              />
            )}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            shouldRemoveStubCells
          />
        </MetricDataContext.Provider>
      </TableMaxHeightProvider>
    </div>
  );
};

export default MetricsTableNew;
