import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import { getURLQueryParams } from '@shared/utils/url';
import { CHART_GALLERY_TYPES } from '@experiment-management-shared/constants/chartsGallery';

import AddBuiltInVisualizationModal from '@experiment-management-shared/components/AddBuiltInVisualizationModal';
import AddCustomVisualizationModal from '@experiment-management-shared/components/AddCustomVisualizationModal';

export const AddVisualizationModal = props => {
  const { CUSTOM, FEATURED } = CHART_GALLERY_TYPES;
  const params = getURLQueryParams();
  const chartCategory = params.get('chartCategory');
  const isCustomVisualization = [CUSTOM, FEATURED].includes(chartCategory);

  const Modal = isCustomVisualization
    ? AddCustomVisualizationModal
    : AddBuiltInVisualizationModal;

  return <Modal {...props} />;
};

AddVisualizationModal.defaultProps = {
  currentForm: {},
  hiddenExperimentKeys: null,
  onAddBuiltInPanel: noop,
  onAddCustomPanel: noop,
  onEditBuiltInPanel: noop,
  onEditCustomPanel: noop,
  isCustomVisualization: false,
  panels: null
};

AddVisualizationModal.propTypes = {
  currentForm: PropTypes.object,
  experimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  hiddenExperimentKeys: PropTypes.array,
  isCustomVisualization: PropTypes.bool,
  onAddBuiltInPanel: PropTypes.func,
  onAddCustomPanel: PropTypes.func,
  onEditBuiltInPanel: PropTypes.func,
  onEditCustomPanel: PropTypes.func,
  panels: PropTypes.array
};
