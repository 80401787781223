import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes, snackbarTypes } from '../../../constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';

const deleteProject = ({ projectId }) => {
  return api.get('projects/delete', {
    params: {
      projectId,
      deleteAll: true
    }
  });
};

export default function useDeleteProjectMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const workspaceName = useWorkspaceName();

  return useMutation(({ projectId }) => deleteProject({ projectId }), {
    onMutate: async () => {
      await queryClient.cancelQueries(['projects', { workspaceName }]);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['projects', { workspaceName }]);

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_DELETE_PROJECT,
          'Project successfully deleted.'
        )
      );
    },
    onError: error => {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_EDIT_PROJECT,
          error?.response?.data?.msg
        )
      );
    }
  });
}
