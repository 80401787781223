import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';

type FeaturesByIdParams = {
  modelId: string;
  features: string[];
};

const getFeaturesByID = ({
  workspaceId,
  modelId,
  ...restParams
}: FeaturesByIdParams & { workspaceId: string }) => {
  return api.post(
    `mpm/${workspaceId}/models/${modelId}/features/getById`,
    restParams
  );
};

export function useFeaturesByID(
  params: FeaturesByIdParams,
  config: QueryConfig<string>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['modelsByID', { workspaceId: activeWorkspace?.id, ...params }],
    () => getFeaturesByID({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config
    }
  );
}
