export const REDIRECT_URL_AFTER_LOGIN_KEY = 'redirectURLAfterLogin';

export const useRedirectURL = () => {
  const redirectURL = window.localStorage.getItem(REDIRECT_URL_AFTER_LOGIN_KEY);

  if (redirectURL) {
    const url = new URL(redirectURL);

    if (url.host !== window.location.host) return;

    window.localStorage.removeItem(REDIRECT_URL_AFTER_LOGIN_KEY);

    window.location.href = redirectURL;
  }
};
