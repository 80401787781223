import './Billing.scss';
import React, { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { SectionTabs } from '@DesignSystem/navigation';
import { Invoices, BillingOverview } from './tabs';
import PaymentDetails from './tabs/PaymentDetails';
import { StripeService } from '@account-settings/utils';
import { useCurrentPaymentPlan } from '@shared/hooks';

const BILLING_TABS = [
  {
    value: 'overview',
    label: 'Overview',
    component: BillingOverview
  },
  {
    value: 'payment_details',
    label: 'Payment Details',
    component: PaymentDetails
  },
  {
    value: 'invoices',
    label: 'Invoices',
    component: Invoices
  }
];

function Billing() {
  const [activeTab, setActiveTab] = useState(0);
  const { isWithBillingDetails } = useCurrentPaymentPlan();

  const handleTabChange = currentTabIndex => setActiveTab(currentTabIndex);
  const ActiveTab = BILLING_TABS[activeTab].component;

  return (
    <div className="billing_container">
      <div className="page-title">Billing</div>
      <Elements stripe={StripeService.stripePromise}>
        {isWithBillingDetails && (
          <SectionTabs
            tabs={BILLING_TABS}
            activeTabIndex={activeTab}
            onTabChange={handleTabChange}
            primaryColorSelectedTab={false}
            tabHeight={35}
            withBottomBorder
          />
        )}
        <ActiveTab />
      </Elements>
    </div>
  );
}

export default Billing;
