import { Box, InputLabel } from '@material-ui/core';
import { get } from 'lodash';
import castArray from 'lodash/castArray';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isMetricNumerical } from '@API/experiments/utils';
import { RadioButton } from '@DesignSystem/controllers';
import CustomSwitch from '@DesignSystem/controllers/Switch/Switch';
import { StyledTooltip } from '@DesignSystem/data-display';
import { Select } from '@ds';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import {
  BUILT_IN_CHART_TYPES,
  CHART_GROUPING_AGGREGATIONS,
  CHART_GROUPING_RANGE,
  DEFAULT_GROUPING_STATE,
  DISABLED_BAR_CHART_AGGREGATION
} from '@/lib/appConstants';
import useColumns from '@API/project/useColumns';
import { getIsSingleExperimentPage } from '@shared/utils/url';

const AGGREGATION_KEY = 'aggregation';
const RANGE_KEY = 'range';
const GROUP_BY_PARAMETER_KEY = 'groupByParameter';

const noGroupByParamOption = {
  label: 'None',
  value: null
};

function GenericVizGroupTab({ chartType }) {
  const chartFormByChartType = useSelector(
    state => state.dashboardCharts.chartForm[chartType]
  );

  const isLineChart = chartType === BUILT_IN_CHART_TYPES['BuiltIn/Line'];

  const dispatch = useDispatch();

  const isSingleExperimentPage = useSelector(getIsSingleExperimentPage);

  const withRange = isLineChart;

  const enabled = get(chartFormByChartType, ['grouping', 'enabled'], false);
  const plotType = get(chartFormByChartType, 'plotType', '');

  const isWithGroupingByParam = !isSingleExperimentPage;

  // to check if we have categorical options
  // and take params for grouping
  const { data: columns } = useColumns(
    {},
    {
      enabled
    }
  );

  const params = useMemo(() => {
    if (!columns?.length) {
      return [];
    }

    return columns.filter(column => column.source === 'params');
  }, [columns]);

  const paramOptions = useMemo(() => {
    return [
      noGroupByParamOption,
      ...params.map(column => ({
        label: column.name,
        value: column.name,
        secondary: column.source
      }))
    ];
  }, [params]);

  const isAggregationDisabled = DISABLED_BAR_CHART_AGGREGATION.includes(
    plotType
  );

  const metricNames = useMemo(() => {
    if (isLineChart) {
      return chartFormByChartType?.selectedYAxis
        ? castArray(chartFormByChartType?.selectedYAxis)
        : [];
    }

    return (
      chartFormByChartType?.metrics
        ?.map(metric => metric.name)
        .filter(Boolean) || []
    );
  }, [chartFormByChartType, isLineChart]);

  const isGroupingButtonDisabled = useMemo(() => {
    return metricNames?.some(
      metricName =>
        !isMetricNumerical(columns.find(column => column.name === metricName))
    );
  }, [metricNames, columns]);

  const {
    [AGGREGATION_KEY]: aggregation,
    [RANGE_KEY]: range,
    [GROUP_BY_PARAMETER_KEY]: groupByParameter
  } = chartFormByChartType.grouping;

  const handleGroupingChange = useCallback(
    (key, value) => {
      const currentGrouping = get(
        chartFormByChartType,
        'grouping',
        DEFAULT_GROUPING_STATE[chartType.toUpperCase()]
      );

      const updatedForm = {
        ...chartFormByChartType,
        grouping: { ...currentGrouping, [key]: value }
      };

      dispatch(dashboardChartsActions.updateChartForm(chartType, updatedForm));
    },
    [chartFormByChartType, chartType, dispatch]
  );

  useEffect(() => {
    if (isGroupingButtonDisabled && enabled) {
      handleGroupingChange('enabled', false);
    }
  }, [isGroupingButtonDisabled, enabled]);

  return (
    <div className="line-chart-group-tab">
      <div className="switch-container">
        <StyledTooltip
          title={
            isGroupingButtonDisabled
              ? 'Grouping is disabled due to not-numerical metrics selected'
              : ''
          }
        >
          <div>
            <CustomSwitch
              checked={enabled}
              onChange={e => handleGroupingChange('enabled', e.target.checked)}
              disabled={isGroupingButtonDisabled}
            />
          </div>
        </StyledTooltip>

        <InputLabel className="switch-label">
          {enabled ? 'Grouping on' : 'Grouping off'}
        </InputLabel>
      </div>

      {enabled && (
        <div className="grouping-options">
          {isWithGroupingByParam && (
            <>
              <InputLabel className="modal-input-label">Group by</InputLabel>

              <Box width={400} marginBottom="40px">
                <Select
                  maxWidth={400}
                  truncateMiddle
                  value={groupByParameter}
                  options={paramOptions}
                  onValueChange={value =>
                    handleGroupingChange(GROUP_BY_PARAMETER_KEY, value)
                  }
                  isClearable={false}
                  maxHeight={280}
                  isSearchable
                  menuShouldBlockScroll
                  isRegexSearchEnabled
                />
              </Box>
            </>
          )}

          <InputLabel className="modal-input-label">
            Grouping aggregation
          </InputLabel>
          <Box width={400} marginBottom="40px">
            <Select
              maxWidth={400}
              truncateMiddle
              disabled={isAggregationDisabled}
              value={aggregation}
              options={Object.values(CHART_GROUPING_AGGREGATIONS)}
              onValueChange={value =>
                handleGroupingChange(AGGREGATION_KEY, value)
              }
              isClearable={false}
              menuShouldBlockScroll
            />
          </Box>

          {withRange && (
            <>
              <InputLabel className="modal-input-label" style={{ margin: 0 }}>
                Range
              </InputLabel>
              <div className="range-container">
                {Object.values(CHART_GROUPING_RANGE).map(({ value, label }) => (
                  <Box
                    marginRight="28px"
                    display="flex"
                    alignItems="center"
                    key={value}
                  >
                    <RadioButton
                      onClick={() => handleGroupingChange(RANGE_KEY, value)}
                      checked={value === range}
                    />
                    <p className="radio-label">{label}</p>
                  </Box>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default GenericVizGroupTab;
