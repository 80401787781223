import { useExperimentsOthers } from '@experiment-details/api';
import useDeepMemo from '@shared/hooks/useDeepMemo';

const HAS_NESTED_PARAMS_KEY = 'hasNestedParams';
const TRUE_VALUE = 'true';

const getIsNestedParamsApplied = (responses, experimentKeys) => {
  if (!responses?.length || !experimentKeys?.length) {
    return null;
  }

  // all the experiments have to have hasNestedParams = true
  return responses.every(response => {
    return (
      response?.find(otherParam => otherParam.name === HAS_NESTED_PARAMS_KEY)
        ?.valueCurrent === TRUE_VALUE
    );
  });
};

const useIsNestedParamsAllowed = experiments => {
  const { data: others } = useExperimentsOthers({ experiments });
  const experimentKeys = experiments.map(
    experiment => experiment.experimentKey
  );

  return useDeepMemo(() => getIsNestedParamsApplied(others, experimentKeys), [
    others,
    experimentKeys
  ]);
};
export default useIsNestedParamsAllowed;
