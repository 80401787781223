import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import RequestStatus from '@shared/components/RequestStatus';

const RequestStatusCell = props => {
  const { row, action } = props;
  const canChangeStatus = row?.isUserAMemberOfWorkspace;

  return (
    <RequestStatus
      showNoneAsButton={false}
      status={get(row, 'status.status')}
      action={action ? () => action(row) : noop}
      textFormatterProps={{ extendedText: true }}
      withTooltip
      tooltipText={
        !canChangeStatus &&
        'Only organization admins or members of this workspace can request to change the status'
      }
      disabled={!canChangeStatus}
      dataTest={`model-registry-request-status-${row?.rowData?.version}`}
    />
  );
};

RequestStatusCell.propTypes = {
  row: PropTypes.shape({
    Status: PropTypes.shape({
      status: PropTypes.string,
      requestedStatus: PropTypes.string
    })
  }).isRequired,
  action: PropTypes.func.isRequired
};

export default RequestStatusCell;
