import { alertsActionTypes } from '../constants/actionTypes';
import alertsHelper from '../helpers/alertsHelper';

const alertsActions = {
  closeSnackbar: key => ({
    type: `${alertsActionTypes.CLOSE_SNACKBAR}: ${key}`,
    payload: { key }
  }),

  closeDialog: key => {
    return {
      type: `${alertsActionTypes.CLOSE_DIALOG}: ${key}`,
      payload: { key }
    };
  },

  openSnackbar: (keyOrPropsOrGetProps, propsOrGetProps) => dispatch => {
    const payload = alertsHelper.getKeyProps(
      keyOrPropsOrGetProps,
      propsOrGetProps,
      dispatch,
      alertsActions.closeSnackbar
    );
    dispatch({
      type: `${alertsActionTypes.OPEN_SNACKBAR}: ${payload.key}`,
      payload
    });
  },

  openDialog: (keyOrPropsOrGetProps, propsOrGetProps) => dispatch => {
    const payload = alertsHelper.getKeyProps(
      keyOrPropsOrGetProps,
      propsOrGetProps,
      dispatch,
      alertsActions.closeDialog
    );
    dispatch({
      type: `${alertsActionTypes.OPEN_DIALOG}: ${payload.key}`,
      payload
    });
  },

  addCustomDialogState: (type, options) => dispatch => {
    dispatch({
      type: `${alertsActionTypes.ADD_CUSTOM_DIALOG_STATE}: ${type}`,
      payload: { type, options }
    });
  },

  resetCustomDialogState: type => dispatch => {
    dispatch({
      type: `${alertsActionTypes.RESET_CUSTOM_DIALOG_STATE}: ${type}`,
      payload: type
    });
  },

  hideGroupedExperimentsAlert: () => dispatch => {
    dispatch({
      type: `${alertsActionTypes.HIDE_GROUPED_EXPERIMENTS_ALERT}`
    });
  }
};

export default alertsActions;
