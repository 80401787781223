import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { generate } from 'randomstring';
import cx from 'classnames';
import { refPropType } from '@shared/constants/propTypes';
import noop from 'lodash/noop';

import DropdownList, {
  DROPDOWN_LIST_TYPES
} from '../DropdownList/DropdownList';

const changeOpenStatusSpecificMenu = ({ status, forValue, menus }) =>
  menus?.map(currentMenu => ({
    ...currentMenu,
    open: currentMenu.parentValue === forValue ? status : currentMenu.open
  }));

// only secondary type of dropdown is supported
const MultiLevelDropdownList = ({
  anchorEl: parentAnchorElement,
  onClick,
  onClose,
  value,
  width,
  items,
  withInput,
  horizontalPosition,
  transformOriginHorizontal,
  dropdownWrapperClassName,
  verticalPosition,
  globalMenuStyles
}) => {
  const [openMenuChildren, setOpenMenuChildren] = useState([]);

  // needed to support multiple levels
  useEffect(() => {
    const filteredOpenMenuChildren = openMenuChildren.filter(({ anchorEl }) =>
      document.contains(anchorEl)
    );

    if (filteredOpenMenuChildren?.length !== openMenuChildren?.length) {
      setOpenMenuChildren(filteredOpenMenuChildren);
    }
  }, [openMenuChildren]);

  useEffect(() => {
    setOpenMenuChildren([]);
  }, [parentAnchorElement]);

  const buildHandleClose = useCallback(
    menu => () => {
      setOpenMenuChildren(menus =>
        changeOpenStatusSpecificMenu({
          status: false,
          forValue: menu?.value,
          menus
        })
      );

      if (menu?.subMenu?.onClose) {
        menu.subMenu.onClose();
      }
    },
    []
  );

  const handleClick = useCallback(
    onClickHandler => (item, event) => {
      if (item?.subMenu?.items && event.currentTarget) {
        setOpenMenuChildren(menus => {
          const isAlreadyInList = menus?.find(
            currentMenu => currentMenu?.parentValue === item.value
          );

          if (isAlreadyInList) {
            return changeOpenStatusSpecificMenu({
              status: true,
              forValue: item.value,
              menus
            });
          }

          const subMenu = item?.subMenu || {};

          return [
            ...menus,
            {
              anchorEl: event.currentTarget,
              items: subMenu.items,
              parentValue: item.value,
              value: subMenu.value,
              withInput: subMenu.withInput,
              width: subMenu.width,
              verticalPosition: subMenu.verticalPosition,
              horizontalPosition: subMenu.horizontalPosition,
              transformOriginHorizontal: subMenu.transformOriginHorizontal,
              dropdownWrapperClassName: cx(subMenu.dropdownWrapperClassName, {
                [dropdownWrapperClassName]: globalMenuStyles
              }),
              key: generate(6),
              onClose: buildHandleClose(item),
              onClick: subMenu.onClick || onClick,
              open: true
            }
          ];
        });
      }
      onClickHandler(item);
    },
    [onClick, buildHandleClose, dropdownWrapperClassName, globalMenuStyles]
  );

  return (
    <>
      <DropdownList
        anchorEl={parentAnchorElement}
        onClick={handleClick(onClick)}
        onClose={onClose}
        value={value}
        width={width}
        items={items}
        withInput={withInput}
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
        transformOriginHorizontal={transformOriginHorizontal}
        dropdownWrapperClassName={dropdownWrapperClassName}
        type={DROPDOWN_LIST_TYPES.SECONDARY}
      />
      {openMenuChildren.map(menu => (
        <DropdownList
          key={menu.key}
          anchorEl={menu.open ? menu.anchorEl : null}
          onClick={handleClick(menu.onClick)}
          onClose={menu.onClose}
          value={menu.value}
          width={menu.width}
          items={menu.items}
          withInput={menu.withInput}
          verticalPosition={menu.verticalPosition}
          horizontalPosition={menu.horizontalPosition}
          transformOriginHorizontal={menu.transformOriginHorizontal}
          dropdownWrapperClassName={menu.dropdownWrapperClassName}
          type={DROPDOWN_LIST_TYPES.SECONDARY}
        />
      ))}
    </>
  );
};

MultiLevelDropdownList.defaultProps = {
  onClose: noop,
  width: null,
  withInput: false,
  horizontalPosition: 'left',
  transformOriginHorizontal: 'left',
  dropdownWrapperClassName: null,
  verticalPosition: 'bottom',
  globalMenuStyles: false,
  onClick: noop,
  value: null
};

MultiLevelDropdownList.propTypes = {
  anchorEl: refPropType.isRequired,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.array.isRequired,
  withInput: PropTypes.bool,
  horizontalPosition: PropTypes.string,
  transformOriginHorizontal: PropTypes.string,
  dropdownWrapperClassName: PropTypes.string,
  verticalPosition: PropTypes.string,
  globalMenuStyles: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string
};

export default MultiLevelDropdownList;
