import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Button, TextButton, Tooltip } from '@ds';
import { Input, Select } from '@DesignSystem/controllers';
import { InfoIcon, CloseModalIcon } from '@Icons-outdated';
import { useFeatures, useFeatureValues } from '@mpm/api';
import { MPM_CHART_DATA_TYPES, MODAL_TYPE } from '@mpm/constants';
import { createSegmentPostRequest } from '@mpm/utils';
import alertsUtil from '@/util/alertsUtil';
import GenericModal from '@shared/components/GenericModal';
import './CreateEditSegmentModal.scss';

const FEATURE_TOOLTIP_MESSAGE =
  'Choose the categorical feature for which MPM metrics will be broken down by';

const VALUES_TOOLTIP_MESSAGE = `Choose the unique values of the 'Feature breakdown'`;

const PAGE_NUMBER = 1;
const PAGE_SIZE = 1000;

const RenderTooltip = ({ message }) => {
  return (
    <Tooltip content={message} placement="top">
      <span className="segment-icon-wrapper">
        <InfoIcon className="segments-info-icon" />
      </span>
    </Tooltip>
  );
};

const initialFormData = {
  name: '',
  featureBreakdownId: '',
  segmentValues: [],
  description: ''
};

const CreateEditSegmentModal = ({
  title,
  modalId,
  submitHandler,
  editFormData,
  type
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(editFormData || initialFormData);
  const { name, featureBreakdownId, segmentValues } = formData;

  const filledData = name && featureBreakdownId && segmentValues?.length;
  const { pathname } = useLocation();
  const { params } = matchPath(pathname, {
    path: `/:workspace/model-production-monitoring/:modelId/settings`,
    exact: true
  });

  const isEditModal = type === MODAL_TYPE.EDIT;
  const { data: modelFeatures } = useFeatures(
    {
      modelId: params.modelId,
      page: PAGE_NUMBER,
      pageSize: PAGE_SIZE
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const { data: featureValues } = useFeatureValues(
    {
      modelId: params.modelId,
      featureId: featureBreakdownId
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const featuresBreakdownOptions = useMemo(() => {
    if (modelFeatures) {
      const { features } = modelFeatures;
      return features
        .filter(feature => feature.type === MPM_CHART_DATA_TYPES.CATEGORICAL)
        .map(feature => {
          return { label: feature.name, value: feature.id };
        });
    }
    return [];
  }, [modelFeatures]);

  const featureValuesOptions = useMemo(() => {
    if (featureValues) {
      const { values } = featureValues;
      return values.map(feature => {
        return { label: feature, value: feature };
      });
    }
    return [];
  }, [featureValues]);

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    submitHandler(createSegmentPostRequest(formData));
    handleClose();
  };

  return (
    <GenericModal
      customClass="generic-modal-alt create-segment-modal"
      title={title}
      onCancel={handleClose}
      hideFooter
    >
      <span className="close-modal-icon-wrapper" onClick={handleClose}>
        <CloseModalIcon />
      </span>
      <div className="generic-modal-section">
        <div className="generic-modal-section body-segment-modal">
          <Input
            title="Segment name"
            placeholder="Enter segment name"
            value={name}
            required
            onChange={value => {
              setFormData(prevState => {
                return { ...prevState, name: value };
              });
            }}
            height="36px"
            fontSize="14px"
          />
          <div className="styled-select-label">
            <Select
              label="Feature breakdown"
              placeholder="Select feature breakdown"
              value={featureBreakdownId}
              disabled={!featuresBreakdownOptions.length || isEditModal}
              required
              onChange={value => {
                setFormData(prevState => {
                  return {
                    ...prevState,
                    segmentValues: [],
                    featureBreakdownId: value
                  };
                });
              }}
              width="422px"
              options={featuresBreakdownOptions}
              variant="outlined"
              withInput={false}
              withTooltip
              renderTooltip={() => (
                <RenderTooltip message={FEATURE_TOOLTIP_MESSAGE} />
              )}
            />
          </div>
          <div className="styled-select-label">
            <Select
              label="Segment values"
              placeholder="Select segment values"
              value={segmentValues}
              disabled={!featureValuesOptions.length}
              required
              onChange={value => {
                setFormData(prevState => {
                  return { ...prevState, segmentValues: value };
                });
              }}
              width="422px"
              options={featureValuesOptions}
              variant="outlined"
              withInput={false}
              isMulti
              withTooltip
              renderTooltip={() => (
                <RenderTooltip message={VALUES_TOOLTIP_MESSAGE} />
              )}
            />
          </div>
          <div className="styled-description-label">
            <Input
              title="Description"
              placeholder="Enter description..."
              height="80px"
              value={formData?.description}
              onChange={value => {
                setFormData(prevState => {
                  return { ...prevState, description: value };
                });
              }}
              multiline
            />
          </div>
        </div>
      </div>
      <div className="create-segment-modal-footer">
        <Button size="large" onClick={handleSubmit} disabled={!filledData}>
          {type === 'create' ? 'Create' : 'Done'}
        </Button>
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
      </div>
    </GenericModal>
  );
};

CreateEditSegmentModal.defaultProps = {
  editFormData: null
};

CreateEditSegmentModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  editFormData: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default CreateEditSegmentModal;
