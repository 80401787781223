import React, { ReactElement } from 'react';
import classes from './LinkButton.module.scss';
import {
  HTMLButtonWithoutSizeType,
  LinkButtonType
} from '@design-system/types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { DSOpenInNewIcon } from '@ds-icons';
import typography from '@ds-typography';

const DEFAULT_LINK_BUTTON_TYPE = 'primary';

export interface LinkButtonProps extends HTMLButtonWithoutSizeType {
  to: string;
  text: string;
  type?: LinkButtonType;
  external?: boolean;
  accented?: boolean;
  openInNewTab?: boolean;
  fullWidth?: boolean;
}

const LinkButton = ({
  to,
  text,
  type = DEFAULT_LINK_BUTTON_TYPE,
  external = false,
  accented = false,
  openInNewTab = false,
  fullWidth = false,
  ...buttonProps
}: LinkButtonProps) => {
  const renderLinkComponent = (): ReactElement => {
    const target = openInNewTab ? '_blank' : '_self';
    const rel = openInNewTab ? 'noreferrer noopener' : '';

    const isAbsolutePath =
      to?.startsWith('http://') || to?.startsWith('https://');

    if (external || isAbsolutePath) {
      return (
        <a href={to} target={target} rel={rel}>
          <button {...buttonProps}>
            {text}
            {external && (
              <p className={classes.linkIconContainer}>
                <DSOpenInNewIcon />
              </p>
            )}
          </button>
        </a>
      );
    }

    return (
      <Link to={to} target={target} rel={rel}>
        <button {...buttonProps}>
          {text}
          {external && (
            <p className={classes.linkIconContainer}>
              <DSOpenInNewIcon />
            </p>
          )}
        </button>
      </Link>
    );
  };

  return (
    <p
      className={cx(
        classes.linkButtonContainer,
        classes[type],
        typography.dsBody,
        {
          [classes.disabled]: buttonProps.disabled,
          [typography.dsAccented]: accented,
          [classes.fullWidth]: fullWidth
        }
      )}
    >
      {renderLinkComponent()}
    </p>
  );
};

export default LinkButton;
