import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import isFinite from 'lodash/isFinite';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';
import chartActions from '@/actions/experimentDetails/chartActionsPlotly';

export const getOutliers = isVisible => ({
  isVisible,
  percentile: isVisible ? 0 : 5
});

export const QUERY_PARAM_KEYS = {
  SMOOTHING: 'smoothing',
  VIEW: 'view',
  OUTLIERS: 'showOutliers',
  SEARCH: 'search',
  X_AXIS: 'xAxis',
  COMPARE_X_AXIS: 'compareXAxis',
  TRANSFORM_Y: 'transformY'
};

const useMetricChartQueryParams = ({
  location,
  experimentKey,
  currentTemplate,
  searchText
}) => {
  const dispatch = useDispatch();
  // Overwrite initial current template values with the query-string parameters
  // Query Parameters > Template

  const { pathname, search } = location;

  const {
    outliers: { isVisible: showOutliers },
    smoothing,
    x_axis: xAxis,
    panels: { compareXAxis },
    transformY
  } = currentTemplate;

  useEffect(() => {
    const {
      [QUERY_PARAM_KEYS.OUTLIERS]: paramShowOutliers,
      [QUERY_PARAM_KEYS.SMOOTHING]: paramSmoothing,
      [QUERY_PARAM_KEYS.X_AXIS]: paramXAxis,
      [QUERY_PARAM_KEYS.COMPARE_X_AXIS]: paramCompareXAxis,
      [QUERY_PARAM_KEYS.TRANSFORM_Y]: paramTransformY
    } = queryString.parse(search, {
      parseBooleans: true
    });

    const queryStringUpdates = {};

    if (!isUndefined(paramShowOutliers)) {
      queryStringUpdates.outliers = getOutliers(paramShowOutliers);
    }

    if (!isUndefined(paramSmoothing)) {
      const val = Number(paramSmoothing);
      queryStringUpdates.smoothing = isFinite(val) ? val : 0;
    }

    if (!isUndefined(paramXAxis)) {
      queryStringUpdates.x_axis = paramXAxis;
    }

    if (!isUndefined(paramCompareXAxis)) {
      queryStringUpdates.panels = { compareXAxis: paramCompareXAxis };
    }

    if (!isUndefined(paramTransformY)) {
      queryStringUpdates.transformY = paramTransformY;
    }

    if (isEmpty(queryStringUpdates)) return;

    dispatch(
      chartActions.updateChartTemplate(experimentKey, queryStringUpdates)
    );
  }, [dispatch, experimentKey]);

  useQueryParamsForExperiments(
    {
      'experiment-tab': DETAIL_VIEWS.PANELS,
      search: searchText,
      smoothing,
      showOutliers,
      xAxis,
      compareXAxis,
      transformY
    },
    [
      searchText,
      smoothing,
      xAxis,
      compareXAxis,
      showOutliers,
      transformY,
      pathname
    ],
    experimentKey
  );
};

export default useMetricChartQueryParams;
