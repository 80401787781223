import { useEffect } from 'react';
import { Edge, Node, useReactFlow } from 'react-flow-renderer';
import getLineageLayout from '@shared/hooks/useLineageLayout';

type UseUpdateLineageLayoutOpts = {
  nodes: Node[];
  edges: Edge[];
  mainNodeId: string;
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
};

const useUpdateLineageLayout = ({
  nodes,
  edges,
  mainNodeId,
  setNodes
}: UseUpdateLineageLayoutOpts) => {
  const { setCenter } = useReactFlow();
  const hiddenNodesLength = nodes.filter(n => n.hidden).length;

  useEffect(() => {
    const { dagreGraph } = getLineageLayout({
      nodes: nodes.filter(n => !n.hidden),
      edges: edges.filter(n => !n.hidden)
    });

    setNodes(nds =>
      nds.map(node => {
        const graphNode = dagreGraph.node(node.id);
        if (!graphNode) {
          return node;
        }

        const { x, y } = graphNode;
        node.position = { x, y };

        if (node.id === mainNodeId) {
          setCenter(x + 100, y + 20, { zoom: 1.4, duration: 1500 });
        }

        return node;
      })
    );
  }, [hiddenNodesLength, setCenter]);
};

export default useUpdateLineageLayout;
