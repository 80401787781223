import { useQuery } from 'react-query';

import modelRegistryApi from '../../util/modelRegistryApi';

const getModelRegistryItemsDetails = async (registryModelId, columns) => {
  const resp = await modelRegistryApi.fetchModelItemsDetails(
    registryModelId,
    columns.filter(({ source }) => source)
  );

  return resp?.data?.metadata || [];
};

const useModelRegistryItemsDetails = (
  { columns = [], registryModelId },
  config = {}
) =>
  useQuery(
    ['modelRegistryById', { columns, registryModelId }, 'metadata'],
    () => getModelRegistryItemsDetails(registryModelId, columns),
    {
      enabled: !!registryModelId && !!columns?.length,
      refetchOnMount: true,
      ...config
    }
  );

export default useModelRegistryItemsDetails;
