import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import get from 'lodash/get';

import { TableSelection } from '@devexpress/dx-react-grid-material-ui';

const ReactGridRow = props => {
  const { withGrouping, ...restProps } = props;

  const isFirstOfUnpinnedGroup = get(
    restProps,
    ['tableRow', 'row', 'isFirstOfUnpinnedGroup'],
    false
  );
  return (
    <TableSelection.Row
      data-test="react-grid-row"
      className={cx('table-selection-row', {
        'pinned-group-row-end': isFirstOfUnpinnedGroup,
        'with-grouping': withGrouping
      })}
      {...restProps}
    />
  );
};
ReactGridRow.propTypes = {
  highlighted: PropTypes.bool.isRequired,
  tableRow: PropTypes.object.isRequired,
  withGrouping: PropTypes.bool
};

ReactGridRow.defaultProps = {
  withGrouping: false
};

export default ReactGridRow;
