import api from '@shared/api';
import { green, red } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ValidIcon from '@material-ui/icons/CheckCircle';
import InvalidIcon from '@material-ui/icons/Error';
import { NEW_REPORT_NAME } from '@shared/constants';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import useDebounce from '@shared/hooks/useDebounce';
import cx from 'classnames';
import PropTypes from 'prop-types';
import randomstring from 'randomstring';
import React, { useEffect, useRef, useState } from 'react';
import styles from './ReportName.module.scss';

const ReportName = ({
  displayName: reportDisplayName,
  editMode,
  name,
  onChange,
  projectId,
  originalDisplayName,
  originalName
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isValidName, setIsValidName] = useState(!!name);
  const changeNameTokenRef = useRef('');

  useEffect(() => {
    setIsValidName(!!name);
  }, [name]);

  const handleNameChange = async newDisplayName => {
    if (newDisplayName === reportDisplayName) return;

    if (newDisplayName === originalDisplayName) {
      onChange({
        displayName: originalDisplayName,
        name: originalName
      });
      return;
    }

    if (
      !newDisplayName ||
      [NEW_REPORT_NAME, 'template'].includes(newDisplayName)
    ) {
      setIsValidName(false);
      setIsDirty(true);
      return;
    }

    const changeNameToken = randomstring.generate(6);
    changeNameTokenRef.current = changeNameToken;

    try {
      const {
        data: { finalName, nameExist, originalName: validatedName }
      } = await api.post('reports/name-validation', {
        projectId,
        reportName: newDisplayName
      });

      if (
        changeNameToken !== changeNameTokenRef.current ||
        validatedName === originalName
      ) {
        return;
      }

      if (nameExist && finalName !== name) {
        throw new Error('Name already exist');
      }

      onChange({
        displayName: validatedName,
        name: finalName
      });

      setIsDirty(true);
    } catch (e) {
      if (changeNameToken !== changeNameTokenRef.current) return;

      setIsValidName(false);
      setIsDirty(true);
    }
  };

  const [displayName, setDisplayName] = useDebounce({
    onDebounceChange: handleNameChange,
    value: reportDisplayName
  });

  if (editMode === REPORT_EDIT_MODES.EDIT) {
    const icon = isValidName ? (
      <ValidIcon style={{ color: green[500] }} />
    ) : (
      <Tooltip title="The report name is invalid or there is already a report with the same name">
        <InvalidIcon style={{ color: red[500] }} />
      </Tooltip>
    );

    return (
      <div className={styles.name}>
        <TextField
          onChange={e => {
            changeNameTokenRef.current = null;
            setDisplayName(e.target.value);
          }}
          placeholder="Report Name"
          value={displayName}
          variant="outlined"
        />

        {isDirty && !isValidName && <div className={styles.valid}>{icon}</div>}
      </div>
    );
  }

  return <div className={cx(styles.name, styles.viewOnly)}>{displayName}</div>;
};

ReportName.propTypes = {
  displayName: PropTypes.string.isRequired,
  editMode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  originalDisplayName: PropTypes.string.isRequired,
  originalName: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ReportName;
