import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router';

import { Button, TextButton } from '@ds';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import { formatArrayAsSelectOptions } from '@shared/utils/selectComponentHelper';

import Select from '@shared/components/Select';

import GenericModal from '@shared/components/GenericModal';
import alertsUtil from '@/util/alertsUtil';

import { COLORS } from '@/constants/colorConstants';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';
import artifactActions from '@/actions/artifactActions';
import { EMOJI_LIST } from '@artifacts/constants/constants';
import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';

const useStyles = makeStyles({
  label: {
    fontSize: '16px'
  }
});

const StyledRadio = (
  <Radio
    size="small"
    style={{ color: COLORS.primary, zIndex: 0, fontSize: 20 }}
  />
);

const ARTIFACT_VISIBILITY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};

const EditArtifactModal = ({ artifact, dispatch, modalId, workspaceName }) => {
  const {
    artifactId,
    description,
    emoji,
    isPublic,
    name,
    project,
    tags,
    type
  } = artifact;

  const history = useHistory();
  const { hash } = useLocation();
  const classes = useStyles();

  const [editedEmoji, setEditedEmoji] = useState(emoji);
  const [editedName, setEditedName] = useState(name);
  const [editedType, setEditedType] = useState(type);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedTags, setEditedTags] = useState(tags);
  const [editedVisibility, setEditedVisibility] = useState(
    isPublic ? ARTIFACT_VISIBILITY.PUBLIC : ARTIFACT_VISIBILITY.PRIVATE
  );

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    const updatedArtifactFields = {
      artifactId,
      artifactName: editedName,
      workspaceName,
      projectName: project,
      description: editedDescription,
      artifactType: editedType,
      isPublic: editedVisibility === ARTIFACT_VISIBILITY.PUBLIC,
      tags: editedTags,
      emoji: editedEmoji
    };

    dispatch(artifactActions.updateArtifact(updatedArtifactFields)).then(() => {
      if (editedName !== name && hash !== WORKSPACE_URL_HASHES.ARTIFACTS) {
        const newPath = `/${workspaceName}/artifacts/${editedName}`;
        history.replace(newPath, { preventScroll: true });
      }
    });
    handleClose();
  };

  const renderNameField = () => {
    const emojiOptions = EMOJI_LIST.map(emojiOption => ({
      label: emojiOption,
      value: emojiOption
    }));
    return (
      <div
        className="generic-modal-section row"
        style={{
          justifyContent: 'flex-start'
        }}
      >
        <div style={{ margin: '0 15px 0 10px' }}>
          <InputLabel className="modal-input-label">Symbol</InputLabel>
          <Select
            className="generic-modal-select emoji-select"
            classNamePrefix="emoji-select"
            value={{ label: editedEmoji, value: editedEmoji }}
            onChange={selectedOption => setEditedEmoji(selectedOption.value)}
            options={emojiOptions}
          />
        </div>
        <div className="flex column grow">
          <InputLabel className="modal-input-label">Name</InputLabel>
          <StyledValidationTooltip
            title="Artifact name is required"
            open={isEmpty(editedName)}
          >
            <InputBase
              className="modal-input-base"
              onChange={e => setEditedName(e.target.value)}
              value={editedName}
              placeholder="Enter name"
            />
          </StyledValidationTooltip>
        </div>
      </div>
    );
  };

  const renderVisibilityField = () => {
    const getLabel = (visibility, visibilityDescription) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
          {visibility}
          <div className="circle-divider" />
          <span style={{ color: '#777777' }}>{visibilityDescription}</span>{' '}
        </div>
      );
    };

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Visibility</InputLabel>
        <RadioGroup
          value={editedVisibility}
          onChange={e => setEditedVisibility(e.target.value)}
          style={{ fontSize: '15px' }}
        >
          <FormControlLabel
            classes={classes}
            value={ARTIFACT_VISIBILITY.PUBLIC}
            control={StyledRadio}
            label={getLabel('Public', 'Anyone can see this artifact')}
            labelPlacement="end"
          />
          <FormControlLabel
            classes={classes}
            value={ARTIFACT_VISIBILITY.PRIVATE}
            control={StyledRadio}
            label={getLabel('Private', 'You choose who can see this artifact')}
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
    );
  };

  const renderTypeField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Type</InputLabel>
        <StyledValidationTooltip
          title="Artifact type is required"
          open={isEmpty(editedType)}
        >
          <InputBase
            className="modal-input-base"
            onChange={e => setEditedType(e.target.value)}
            value={editedType}
            placeholder="Enter type"
          />
        </StyledValidationTooltip>
      </div>
    );
  };

  const renderDescriptionField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Description</InputLabel>
        <InputBase
          className="modal-input-base"
          onChange={e => setEditedDescription(e.target.value)}
          value={editedDescription}
          placeholder="Enter description"
          minRows={3}
          maxRows={8}
          multiline
        />
      </div>
    );
  };

  const renderTagsField = () => {
    const options = formatArrayAsSelectOptions(tags);
    const selectedValue = formatArrayAsSelectOptions(editedTags);

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Tags</InputLabel>
        <Select
          className="stage-select"
          value={selectedValue}
          onChange={selected => {
            setEditedTags(selected.map(({ value }) => value));
          }}
          options={options}
          placeholder="Create or select a tag"
          menuPlacement="top"
          isMulti
          isSearchable
          isClearable
          isCreatable
        />
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title="Edit artifact info"
      hideFooter
    >
      <DialogContent className="generic-modal-body">
        <div
          className="generic-modal-group"
          style={{
            flexDirection: 'column',
            alignSelf: 'flexStart',
            width: '100%'
          }}
        >
          {renderNameField()}
          {renderVisibilityField()}
          <Divider style={{ margin: '-15px 0 20px 0' }} />
          {renderTypeField()}
          {renderDescriptionField()}
          {renderTagsField()}
        </div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
        <Button
          size="large"
          onClick={handleSubmit}
          disabled={[editedName, editedType].some(isEmpty)}
        >
          Save
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

EditArtifactModal.propTypes = {
  artifact: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default EditArtifactModal;
