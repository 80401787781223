import { ROOT_DIR } from '@experiment-management-shared/constants/fileBrowser';

/** Note:
  The reason for an external state and not using the react state is because
  the infinite scroll is messing up with directories open/close state.
 * */

class FoldersState {
  constructor() {
    this.forceExpansion = false;
    this.state = { [ROOT_DIR]: true };
  }

  updateState(newState) {
    this.state = newState;
  }

  isOpen(dir) {
    if (dir === ROOT_DIR) return true;
    if (this.forceExpansion) return true;
    if (this.state[dir] === undefined) return false;
    return this.state[dir];
  }

  expansion(force) {
    this.forceExpansion = force;
  }
}

export default FoldersState;
export { FoldersState };
