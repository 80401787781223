import { isArray, isBoolean, isNumber } from 'lodash';
import { parse } from 'papaparse';
import get from 'lodash/get';

// Check if table column has only unique values.
export const isColumnValueUnique = (table, column) => {
  const grouped = table.reduce((grouped, row) => {
    const colValue = row[column];
    grouped[colValue] = (grouped[colValue] || 0) + 1;
    return grouped;
  }, {});

  return Object.keys(grouped).every(key => {
    return grouped[key] === 1;
  });
};

// merge all columns in tables and modify label name if repeated
export const mergeColumnsAndModifyLabelForRepeated = (tables, index) => {
  const mergedColumns = [index];

  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];
    const firstRow = table[0];
    const columns = Object.keys(firstRow);
    for (const col of columns) {
      const formatColumnName = `${col}_${i}`;
      if (index !== col && !mergedColumns.includes(formatColumnName)) {
        mergedColumns.push(formatColumnName);
      }
    }
  }

  return mergedColumns;
};

/**
 * Returns columns that appear in all tables.
 * @param tables list of tables
 * @returns innerMergedColumns
 */
export const innerMergeColumns = tables => {
  const columnsCountMap = {};
  const columns = [];

  for (const table of tables) {
    const firstRow = table[0];
    const cols = Object.keys(firstRow);
    // takes the first row from each table get the keys and add each one to columnNames
    for (const col of cols) {
      columnsCountMap[col] = columnsCountMap[col] + 1 || 1;
      if (columnsCountMap[col] === tables.length) {
        columns.push(col);
      }
    }
  }
  return columns;
};

// put index column first in array

export const setIndexColumnFirst = ({ index, columns }) => {
  return [index, ...columns.filter(col => col !== index)];
};
/**
 * Merge all columns in array of tables doesn't allow duplicates.
 * @param tables list of tables
 * @returns mergedColumns
 */
export const outerMergeColumns = tables => {
  const mergedColumns = new Set();

  for (const table of tables) {
    const firstRow = table[0];
    const cols = Object.keys(firstRow);
    // takes the first row from each table get the keys and add each one to columnNames
    for (const col of cols) {
      mergedColumns.add(col);
    }

    Object.keys(firstRow).forEach(colName => mergedColumns.add(colName));
  }

  return [...mergedColumns];
};

export const combineRowsByKey = (rows, key) => {
  return rows.reduce((accumulator, current) => {
    const itemIndex = accumulator.findIndex(item => item[key] === current[key]);

    if (itemIndex != -1) {
      accumulator[itemIndex] = { ...accumulator[itemIndex], ...current };
    } else {
      accumulator = accumulator.concat(current);
    }

    return accumulator;
  }, []);
};

export const getCommonIndexesForTables = tables => {
  const mergedColumns = innerMergeColumns(tables);
  const uniqueColumns = new Set();

  const noneUniqueValues = new Set();

  for (const table of tables) {
    for (const column of mergedColumns) {
      const isUnique = isColumnValueUnique(table, column);
      if (isUnique) {
        uniqueColumns.add(column);
      } else {
        noneUniqueValues.add(column);
      }
    }
  }

  return [...uniqueColumns].filter(value => !noneUniqueValues.has(value));
};

export const getActiveExperiments = ({
  experimentKeys,
  hiddenExperimentKeys,
  experimentsCount
}) => {
  let retVal = [...experimentKeys];

  if (isArray(hiddenExperimentKeys)) {
    retVal = retVal.filter(key => !hiddenExperimentKeys.includes(key));
  }

  if (retVal.length > experimentsCount) {
    retVal.length = experimentsCount;
  }

  return retVal;
};

const DEFAULT_EMPTY_COLUMN_NAME = 'idx';

const renameEmptyColumnsInRow = row => {
  const normalizedRow = {};
  Object.keys(row).forEach(columnName => {
    if (columnName === '') {
      normalizedRow[DEFAULT_EMPTY_COLUMN_NAME] = row[columnName];
    } else {
      normalizedRow[columnName] = row[columnName];
    }
  });
  return normalizedRow;
};

export const downloadAndParseCSVsBatch = assetsBatch => {
  return assetsBatch.map(({ compressedAssetLink, experimentKey }) => {
    return new Promise((resolve, reject) => {
      parse(compressedAssetLink, {
        download: true,
        error: reject,
        header: true,
        withCredentials: true,
        worker: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: res => {
          const resWithKeys = res.data.map(row => {
            // since pandas add empty index column we need rename empty columns to 'idx'
            const normalizedRow = renameEmptyColumnsInRow(row);

            return { experimentKey, ...normalizedRow };
          });
          resolve(resWithKeys);
        }
      });
    });
  });
};

export const COLUMN_CONFIG = {
  sortable: true,
  filter: true,
  resizable: true,
  cellRenderer: ({ colDef, data }) => {
    const value = get(data, colDef.field, '-');

    if (isBoolean(value) || isNumber(value)) {
      return String(value);
    }
    return value;
  },
  cellStyle: {
    fontSize: '13px',
    background: 'white'
  },
  headerClass: 'ag-grid-header-data-panel'
};
