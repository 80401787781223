import usePathname from '@shared/hooks/usePathname';
import { matchPath } from 'react-router';

export default function useProjectParams(): {
  workspace?: string;
  projectName?: string;
} {
  const pathname = usePathname();

  const route = matchPath(pathname, {
    path: '/:workspace/:projectName'
  });

  return route?.params || {};
}
