import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import DocumentTitle from 'react-document-title';
import useProject from '@API/project/useProject';

import styles from './ProjectPageWrapper.module.scss';

const ProjectPageWrapper = ({ header, headerHeight, fixedHeight, content }) => {
  const { projectName, workspace } = useParams();

  const {
    data: { projectDesc }
  } = useProject();

  const style = { '--header-height': header ? headerHeight : '0' };
  const baseTitle = `${workspace}/${projectName}`;
  const documentTitle = isEmpty(projectDesc)
    ? baseTitle
    : `${baseTitle}: ${projectDesc}`;

  return (
    <DocumentTitle title={documentTitle}>
      <>
        {header}
        <div
          style={style}
          className={cx(styles.contentContainer, {
            [styles.fixedHeight]: fixedHeight
          })}
        >
          {content}
        </div>
      </>
    </DocumentTitle>
  );
};

ProjectPageWrapper.defaultProps = {
  header: null,
  headerHeight: '52px',
  fixedHeight: false
};

ProjectPageWrapper.propTypes = {
  header: PropTypes.node,
  headerHeight: PropTypes.string,
  fixedHeight: PropTypes.bool,
  content: PropTypes.node.isRequired
};

export default ProjectPageWrapper;
