import React from 'react';
import cx from 'classnames';

import classNames from './GraphicsTile.module.scss';
import TextOverflow from '@shared/components/TextOverflow/TextOverflow';
import {
  ImageAssetData,
  VideoAssetData
} from '@experiment-management-shared/types';
import GraphicsTileBody from './GraphicsTileBody';
import GraphicsTileFooter from './GraphicsTileFooter';
import GraphicsTileCheckbox from './GraphicsTileCheckbox';
import GraphicsTileTooltip from './GraphicsTileTooltip';

type GraphicsTileProps = {
  selection: {
    show: boolean;
    disabled: boolean;
  };
  selectedItems: string[];
  asset: VideoAssetData | ImageAssetData;
  onOpenAsset: (asset: VideoAssetData | ImageAssetData) => void;
  handleSelectItem: (asset: VideoAssetData | ImageAssetData) => void;
};
const GraphicsTile: React.FC<GraphicsTileProps> = ({
  asset,
  onOpenAsset,
  handleSelectItem,
  selectedItems,
  selection
}) => {
  const { name: assetName, id: imageId } = asset;

  const isSelectionDisabledByLackOfName = !assetName;
  const isShowSelection = selection?.show;
  const isSelectionDisabled =
    selection?.disabled || isSelectionDisabledByLackOfName;

  const tooltipText = isSelectionDisabledByLackOfName
    ? 'Images without name cannot be compared.'
    : 'You can only compare images from this tab. You can create video panel from the panel tab/page.';

  const isChecked = selectedItems.includes(imageId);

  return (
    <div
      className={cx(classNames.imageTileContainer, {
        [classNames.imageTileContainerHover]: isShowSelection
      })}
      onClick={() => onOpenAsset(asset)}
      data-test={`graphics-tile-${asset.name}`}
    >
      <TextOverflow
        className={classNames.imageName}
        emptyTextPlaceholder="-"
        maxVisibleLength={30}
        originalText={assetName}
        data-test={`graphics-tile-name-${assetName}`}
        disableHoverListener={false}
        tooltipMode="dark"
        renderAsHTML={false}
      />

      <GraphicsTileBody asset={asset} />

      <GraphicsTileFooter asset={asset} />

      {isShowSelection && (
        <div className={classNames.checkboxContainer}>
          <GraphicsTileTooltip
            tooltipText={tooltipText}
            isSelectionDisabled={isSelectionDisabled}
          >
            <GraphicsTileCheckbox
              isChecked={isChecked}
              isDisabled={isSelectionDisabled}
              onClick={() => handleSelectItem(asset)}
            />
          </GraphicsTileTooltip>
        </div>
      )}
    </div>
  );
};

export default GraphicsTile;
