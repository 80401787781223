import { AllowedCompareKeys, ComparisonTableRow } from '../types';
import { calcPercentageChange, getExperimentValueName } from '../utils';
import { ExperimentDetails } from '@shared/types';

const EXPERIMENT_COUNT_FOR_PERCENTAGE = 2;

type UseRowDataFormattingOpts = {
  rowData: ComparisonTableRow[];
  filterDuplicates: boolean;
  defaultHiddenColumnNames: string[];
  valueKeys: AllowedCompareKeys[];
  experiments: ExperimentDetails[];
};
const useRowDataFormatting = ({
  rowData,
  filterDuplicates,
  defaultHiddenColumnNames,
  valueKeys,
  experiments
}: UseRowDataFormattingOpts) => {
  const experimentKeys = experiments.map(
    experiment => experiment.experimentKey
  );
  const isShouldCalculateDiffs =
    experiments.length === EXPERIMENT_COUNT_FOR_PERCENTAGE;

  const availableValuieKeys = valueKeys.filter(
    key => !defaultHiddenColumnNames.some(name => name.startsWith(key))
  );

  rowData = rowData.map(data => {
    const isValuesEqual = availableValuieKeys.every(valueKey => {
      const valueList = experimentKeys.map(
        experimentKey => data[getExperimentValueName(valueKey, experimentKey)]
      );
      return valueList.every(v => v === valueList[0]);
    });

    data.isEqual = isValuesEqual;
    return data;
  });

  // filter rowData by isEqual or not
  if (filterDuplicates) {
    rowData = rowData.filter(item => !item.isEqual);
  }

  // calculate diffs
  if (isShouldCalculateDiffs) {
    rowData = rowData.map(row => {
      const rowData = { ...row };
      availableValuieKeys.forEach(valueKey => {
        const firstValueKey = getExperimentValueName(
          valueKey,
          experimentKeys[0]
        );
        const secondValueKey = getExperimentValueName(
          valueKey,
          experimentKeys[1]
        );
        const firstExperimentValue = row[firstValueKey];
        const secondExperimentValue = row[secondValueKey];

        rowData[`${firstValueKey}Diff`] = calcPercentageChange(
          secondExperimentValue,
          firstExperimentValue
        );
        rowData[`${secondValueKey}Diff`] = calcPercentageChange(
          firstExperimentValue,
          secondExperimentValue
        );
      });
      return rowData;
    });
  }

  return rowData;
};

export default useRowDataFormatting;
