import React from 'react';
import { Box } from '@material-ui/core';

import { Menu } from '@DesignSystem/navigation';
import './CustomTransformationsPage.scss';

type NavItem = {
  label: string;
  prefixIcon: React.ReactNode;
  value: string;
};

type ModelSettingsProps = {
  navItems: NavItem[];
  activeItem: string;
  handleNavChange: (menuItem: NavItem) => void;
};

const ModelSettings = ({
  navItems,
  activeItem,
  handleNavChange
}: ModelSettingsProps) => {
  return (
    <Box className="model-settings">
      <h2>Model Settings</h2>
      <Box>
        <Menu
          items={navItems}
          onClickItem={handleNavChange}
          activeItem={activeItem}
        />
      </Box>
    </Box>
  );
};

export default ModelSettings;
