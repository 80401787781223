import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { singleExperimentPanelEvents } from '@experiment-details/constants/events';
import { trackEvent } from '@shared/utils/eventTrack';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import uiActions from '@/actions/experimentDetails/uiActions';
import chartActions from '@/actions/experimentDetails/chartActionsPlotly';
import { getIsHeaderTableShown } from '@/reducers/experimentDetails/uiReducer';

const useExperimentDetailActions = ({
  experimentKey,
  userTemplates,
  currentTemplate,
  originalTemplate
}) => {
  const dispatch = useDispatch();
  const isHeaderTableShown = useSelector(getIsHeaderTableShown);

  const saveAndSetTemplate = useCallback(
    async (template, withoutRedirection, shouldSetAsDefaultView) => {
      const responseTemplate = await dispatch(
        chartActions.saveTemplate(experimentKey, template)
      );

      if (!withoutRedirection) {
        dispatch(
          chartActions.setCurrentTemplateAndMigrateCharts(
            responseTemplate,
            experimentKey
          )
        );
      }

      if (shouldSetAsDefaultView) {
        dispatch(
          chartActions.setProjectTemplate(responseTemplate, experimentKey)
        );
      }
    },
    [dispatch, experimentKey]
  );

  const handleSaveNewTemplate = (
    templateName,
    shouldSetAsDefaultView = false,
    withoutRedirection = false
  ) => {
    const userTemplateNames = userTemplates.map(
      template => template.template_name
    );

    if (userTemplateNames.includes(templateName)) {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_SAVE_DUPLICATE_TEMPLATE_NAME,
          'This view name already exists.'
        )
      );

      return false;
    }

    saveAndSetTemplate(
      {
        ...currentTemplate,
        template_id: null,
        template_name: templateName
      },
      withoutRedirection,
      shouldSetAsDefaultView
    );

    return true;
  };

  const handleDiscardChanges = useCallback(() => {
    dispatch(
      chartActions.setCurrentTemplateAndMigrateCharts(
        originalTemplate,
        experimentKey
      )
    );

    dispatch(uiActions.setIsNeededToResetLocalChanges(true));
  }, [originalTemplate, experimentKey, dispatch]);

  const handleUpdateTemplateName = useCallback(
    ({ newName, template }) => {
      saveAndSetTemplate(
        {
          ...template,
          template_id: template.template_id,
          template_name: newName
        },
        currentTemplate.template_id !== template.template_id,
        false
      );
    },
    [saveAndSetTemplate, currentTemplate]
  );

  const handleUpdateTemplate = () => {
    dispatch(chartActions.saveTemplate(experimentKey, currentTemplate));
  };

  const handleSaveViewClick = ({ viewName, asDefault, isNewView }) => {
    trackEvent(
      singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_SAVE_VIEW
    );

    if (isNewView) {
      handleSaveNewTemplate(viewName, asDefault);
      return;
    }

    handleUpdateTemplate();
  };

  const handleClickIsTableShown = () => {
    dispatch(uiActions.setIsHeaderTableShownStatus(!isHeaderTableShown));
  };

  return {
    handleSaveNewTemplate,
    handleDiscardChanges,
    handleUpdateTemplateName,
    handleSaveViewClick,
    handleClickIsTableShown,
    handleUpdateTemplate
  };
};

export default useExperimentDetailActions;
