import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import ModelRegistryCreateEditModalContent from './ModelRegistryCreateEditModalContent';
import classNames from './ModelRegistryWebhooksTab.module.scss';
import { Button, TextButton } from '@ds';
import WebhookResponsePopover from '@model-registry/components/ModelRegistryWebhooksTab/WebhookStatus/WebhookResponsePopover';
import { convertTestResponseToNotifierResponse } from '@model-registry/components/ModelRegistryWebhooksTab/WebhookStatus/helper';

const ModelRegistryCreateWebhookModal = ({
  isNew,
  dataTest,
  onPrimaryButtonClick,
  onTestButtonClick,
  onSecondaryButtonClick,
  isPrimaryButtonDisabled,
  modelRegistryTestWebhookMutation,
  isGithubIntegrationActive,
  setIsGithubIntegrationActive,
  onClose,
  open,
  eventTrigger = [],
  setEventTrigger,
  webhookName,
  setWebhookName,
  authKey,
  setAuthKey,
  url,
  isUrlValid,
  setUrl,
  headers,
  setHeaders
}) => {
  const [anchorEl, setAnchorEl] = useState();

  const testBtnRef = React.useRef(null);

  const onTestBtnClick = () => {
    onTestButtonClick();
    setAnchorEl(testBtnRef.current);
  };

  const renderWebhookResponsePopover = () => {
    if (!modelRegistryTestWebhookMutation.data) {
      return null;
    }

    return (
      <WebhookResponsePopover
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: -30,
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        response={convertTestResponseToNotifierResponse(
          modelRegistryTestWebhookMutation.data.data
        )}
        url={url}
      />
    );
  };

  const footer = (
    <div className={classNames.modelRegistryWebhooksFullWidthModalFooter}>
      {renderWebhookResponsePopover()}
      {!isNew && (
        <TextButton
          className="footer-button"
          size="large"
          onClick={onSecondaryButtonClick}
        >
          {'Delete'}
        </TextButton>
      )}
      <Button
        ref={testBtnRef}
        size="large"
        type="tertiary"
        onClick={onTestBtnClick}
        className="footer-button"
        disabled={isPrimaryButtonDisabled}
        loading={modelRegistryTestWebhookMutation?.isLoading}
      >
        Test webhook
      </Button>
      <Button
        className="footer-button"
        size="large"
        onClick={onPrimaryButtonClick}
        disabled={isPrimaryButtonDisabled}
      >
        {isNew ? 'Create' : 'Update'}
      </Button>
    </div>
  );

  return (
    <>
      <FullWidthBasicModal
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
        open={open}
        className={classNames.modelRegistryWebhooksFullWidthModal}
        onClose={onClose}
        title={
          <span data-test={`${dataTest}-modal-title`}>
            {isNew ? 'Create Webhook' : 'Edit Webhook'}
          </span>
        }
        footer={footer}
        PaperProps={{
          'data-test': `${dataTest}-modal`
        }}
        content={
          <ModelRegistryCreateEditModalContent
            isGithubIntegrationActive={isGithubIntegrationActive}
            setIsGithubIntegrationActive={setIsGithubIntegrationActive}
            dataTest={dataTest}
            eventTrigger={eventTrigger}
            setEventTrigger={setEventTrigger}
            webhookName={webhookName}
            setWebhookName={setWebhookName}
            authKey={authKey}
            setAuthKey={setAuthKey}
            url={url}
            isUrlValid={isUrlValid}
            setUrl={setUrl}
            headers={headers}
            setHeaders={setHeaders}
          />
        }
      />
    </>
  );
};

ModelRegistryCreateWebhookModal.defaultProps = {
  isUrlValid: true,
  isPrimaryButtonDisabled: false,
  headers: [],
  isNew: false,
  dataTest: null,
  authKey: null
};

ModelRegistryCreateWebhookModal.propTypes = {
  dataTest: PropTypes.string,
  isNew: PropTypes.bool,
  isUrlValid: PropTypes.bool,
  isPrimaryButtonDisabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isGithubIntegrationActive: PropTypes.bool.isRequired,
  setIsGithubIntegrationActive: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onTestButtonClick: PropTypes.func.isRequired,
  modelRegistryTestWebhookMutation: PropTypes.shape().isRequired,
  eventTrigger: PropTypes.arrayOf(PropTypes.string).isRequired,
  webhookName: PropTypes.string.isRequired,
  authKey: PropTypes.string,
  url: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ),
  setEventTrigger: PropTypes.func.isRequired,
  setWebhookName: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
  setAuthKey: PropTypes.func.isRequired,
  setHeaders: PropTypes.func.isRequired
};

export default ModelRegistryCreateWebhookModal;
