import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { useExperimentInstalledPackages } from '@experiment-details/api';
import {
  COMPARE_EXPERIMENT_1_KEY,
  COMPARE_EXPERIMENT_2_KEY,
  DETAIL_VIEWS
} from '@experiment-management-shared/constants/experimentConstants';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import BaseCodeCompareView from '@experiment-details/components/Code/BaseCodeCompareView';
import InstalledPackagesTab from '@experiment-details/components/InstalledPackagesTab';

const GraphDefinitionCompareTab = props => {
  const { experiments } = props;
  const location = useLocation();
  const {
    [COMPARE_EXPERIMENT_1_KEY]: experimentKey1 = experiments[0].experimentKey,
    [COMPARE_EXPERIMENT_2_KEY]: experimentKey2 = experiments[1].experimentKey
  } = queryString.parse(location.search);

  const experiment1 = experiments.find(
    ({ experimentKey }) => experimentKey === experimentKey1
  );
  const experiment2 = experiments.find(
    ({ experimentKey }) => experimentKey === experimentKey2
  );

  const {
    data: experiment1InstalledPackages,
    isLoading: isExperiment1Loading
  } = useExperimentInstalledPackages({
    experimentKey: experimentKey1
  });

  const {
    data: experiment2InstalledPackages,
    isLoading: isExperiment2Loading
  } = useExperimentInstalledPackages({
    experimentKey: experimentKey2
  });

  return (
    <div className="installed-packages__compare">
      <BaseCodeCompareView
        {...props}
        detailView={DETAIL_VIEWS.INSTALLED_PACKAGES}
        experiment1={experiment1}
        experiment1Code={experiment1InstalledPackages}
        experiment2={experiment2}
        experiment2Code={experiment2InstalledPackages}
        hardcodedFileName="installed-packages"
        isLoading={isExperiment1Loading || isExperiment2Loading}
        TabComponent={InstalledPackagesTab}
      />
    </div>
  );
};

GraphDefinitionCompareTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default GraphDefinitionCompareTab;
