import React from 'react';

import styles from './ExperimentDetailsNavBar.module.scss';
import { TextButton } from '@design-system/components';
import { DSArrowLeftIcon } from '@design-system/icons';
import useBackToProjectRoute from './hooks/useBackToProjectRoute';
import useProjectPageBreadcrumbs from '@shared/hooks/useProjectPageBreadcrumbs';

type ExperimentDetailsNavBarProps = {
  subheaderRightSection: React.ReactNode;
};
const ExperimentDetailsNavBar: React.FC<ExperimentDetailsNavBarProps> = ({
  subheaderRightSection
}) => {
  useProjectPageBreadcrumbs();
  const { returnToProjectLabel, onReturnToProject } = useBackToProjectRoute();

  return (
    <div className={styles.experimentDetailsNavBar}>
      <TextButton
        type="secondary"
        size="medium"
        data-test="return-to-project-btn"
        PrefixIcon={<DSArrowLeftIcon />}
        onClick={onReturnToProject}
      >
        {returnToProjectLabel}
      </TextButton>

      {subheaderRightSection}
    </div>
  );
};

export default ExperimentDetailsNavBar;
