import PropTypes from 'prop-types';

import moment from 'moment';
import { Duration } from 'luxon';

import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isFinite from 'lodash/isFinite';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { formatDateAsUtc, formatDateTime } from '@shared/utils/displayHelpers';
import cloneDeep from 'lodash/cloneDeep';

export const TAG_COLUMN_NAME = 'tag';

export const SOURCE = {
  ENV_DETAILS: 'env_details',
  METADATA: 'metadata',
  MESSAGE_TIME: 'message_time',
  METRICS: 'metrics',
  PARAMS: 'params',
  LOG_OTHER: 'log_other',
  COMPUTED_METADATA: 'computed_metadata',
  TAG: 'tag',
  LLM: 'llm_data'
};

export const OPERATOR_TYPE = {
  EQUAL: 'equal',
  NOT_EQUAL: 'not_equal',
  BEGINS_WITH: 'begins_with',
  NOT_BEGINS_WITH: 'not_begins_with',
  ENDS_WITH: 'ends_with',
  NOT_ENDS_WITH: 'not_ends_with',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not_contains',
  IS_NULL: 'is_null',
  IS_NOT_NULL: 'is_not_null',
  IS_EMPTY: 'is_empty',
  IS_NOT_EMPTY: 'is_not_empty',

  GREATER: 'greater',
  GREATER_OR_EQUAL: 'greater_or_equal',
  GREATER_RELATIVE: 'greater_relative',
  LESS: 'less',
  LESS_OR_EQUAL: 'less_or_equal',
  LESS_RELATIVE: 'less_relative',
  BETWEEN: 'between',
  NOT_BETWEEN: 'not_between',

  IS_TRUE: 'is_true',
  IS_FALSE: 'is_false',

  CONTAIN: 'contain',
  NOT_CONTAIN: 'not_contain',
  HAS_TAG: 'has_tag',
  NOT_HAS_TAG: 'not_has_tag',
  START_WITH: 'start_with',
  NOT_START_WITH: 'not_start_with',
  END_WITH: 'end_with',
  NOT_END_WITH: 'not_end_with'
};

export const RULE_TYPE = {
  STRING: 'string',
  TIME_NUMBER: 'timenumber',
  DATETIME: 'datetime',
  DOUBLE: 'double',
  BOOLEAN: 'boolean'
};

export const INPUT_TYPE = {
  STRING: 'string',
  TIME_NUMBER: 'timenumber',
  DATETIME: 'datetime',
  DOUBLE: 'double',
  BOOLEAN: 'boolean',
  RELATIVE: 'relative',
  NONE: 'none'
};

export const NO_VALUES_OPERATORS = [
  OPERATOR_TYPE.IS_NULL,
  OPERATOR_TYPE.IS_NOT_NULL,
  OPERATOR_TYPE.IS_EMPTY,
  OPERATOR_TYPE.IS_NOT_EMPTY,
  OPERATOR_TYPE.IS_TRUE,
  OPERATOR_TYPE.IS_FALSE,
  OPERATOR_TYPE.HAS_TAG,
  OPERATOR_TYPE.NOT_HAS_TAG
];

const GENERIC_OPERATORS = {
  [RULE_TYPE.STRING]: [
    {
      label: 'is',
      operator: OPERATOR_TYPE.EQUAL,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'is not',
      operator: OPERATOR_TYPE.NOT_EQUAL,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'begins with',
      operator: OPERATOR_TYPE.BEGINS_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: "doesn't begin with",
      operator: OPERATOR_TYPE.NOT_BEGINS_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'ends with',
      operator: OPERATOR_TYPE.ENDS_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: "doesn't end with",
      operator: OPERATOR_TYPE.NOT_ENDS_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'contains',
      operator: OPERATOR_TYPE.CONTAINS,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: "doesn't contain",
      operator: OPERATOR_TYPE.NOT_CONTAINS,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE
    },
    {
      label: 'is empty',
      operator: OPERATOR_TYPE.IS_EMPTY,
      inputType: INPUT_TYPE.NONE
    },
    {
      label: 'is not empty',
      operator: OPERATOR_TYPE.IS_NOT_EMPTY,
      inputType: INPUT_TYPE.NONE
    }
  ],
  [RULE_TYPE.TIME_NUMBER]: [
    {
      label: 'equal',
      operator: OPERATOR_TYPE.EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'not equal',
      operator: OPERATOR_TYPE.NOT_EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'greater or equal',
      operator: OPERATOR_TYPE.GREATER_OR_EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'less or equal',
      operator: OPERATOR_TYPE.LESS_OR_EQUAL,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.TIME_NUMBER
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE
    }
  ],
  [RULE_TYPE.DATETIME]: [
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      operators: [
        {
          label: 'greater than',
          operator: OPERATOR_TYPE.GREATER,
          inputType: INPUT_TYPE.DATETIME
        },
        {
          label: 'greater than',
          operator: OPERATOR_TYPE.GREATER_RELATIVE,
          inputType: INPUT_TYPE.RELATIVE
        }
      ]
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      operators: [
        {
          label: 'less than',
          operator: OPERATOR_TYPE.LESS,
          inputType: INPUT_TYPE.DATETIME
        },
        {
          label: 'less than',
          operator: OPERATOR_TYPE.LESS_RELATIVE,
          inputType: INPUT_TYPE.RELATIVE
        }
      ]
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.DATETIME
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ],
  [RULE_TYPE.DOUBLE]: [
    {
      label: 'equal',
      operator: OPERATOR_TYPE.EQUAL,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'not equal',
      operator: OPERATOR_TYPE.NOT_EQUAL,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'greater than',
      operator: OPERATOR_TYPE.GREATER,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'greater or equal',
      operator: OPERATOR_TYPE.GREATER_OR_EQUAL,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'less than',
      operator: OPERATOR_TYPE.LESS,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'less or equal',
      operator: OPERATOR_TYPE.LESS_OR_EQUAL,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'between',
      operator: OPERATOR_TYPE.BETWEEN,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'not between',
      operator: OPERATOR_TYPE.NOT_BETWEEN,
      inputType: INPUT_TYPE.DOUBLE
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ],
  [RULE_TYPE.BOOLEAN]: [
    {
      label: 'is true',
      operator: OPERATOR_TYPE.IS_TRUE,
      inputType: INPUT_TYPE.NONE,
      value: true
    },
    {
      label: 'is false',
      operator: OPERATOR_TYPE.IS_FALSE,
      inputType: INPUT_TYPE.NONE,
      value: false
    },
    {
      label: 'is null',
      operator: OPERATOR_TYPE.IS_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    },
    {
      label: 'is not null',
      operator: OPERATOR_TYPE.IS_NOT_NULL,
      inputType: INPUT_TYPE.NONE,
      value: null
    }
  ]
};

const TAG_OPERATORS = {
  [RULE_TYPE.STRING]: [
    {
      label: 'has tags',
      operator: OPERATOR_TYPE.HAS_TAG,
      inputType: INPUT_TYPE.NONE,
      value: ''
    },
    {
      label: 'has no tags',
      operator: OPERATOR_TYPE.NOT_HAS_TAG,
      inputType: INPUT_TYPE.NONE,
      value: ''
    },
    {
      label: 'is',
      operator: OPERATOR_TYPE.EQUAL,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'is not',
      operator: OPERATOR_TYPE.NOT_EQUAL,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'begins with',
      operator: OPERATOR_TYPE.START_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: "doesn't begin with",
      operator: OPERATOR_TYPE.NOT_START_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'ends with',
      operator: OPERATOR_TYPE.END_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: "doesn't end with",
      operator: OPERATOR_TYPE.NOT_END_WITH,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: 'contains',
      operator: OPERATOR_TYPE.CONTAIN,
      inputType: INPUT_TYPE.STRING
    },
    {
      label: "doesn't contain",
      operator: OPERATOR_TYPE.NOT_CONTAIN,
      inputType: INPUT_TYPE.STRING
    }
  ]
};

const isValidDate = value => isString(value) && moment(value).isValid();

const isValidDouble = val => {
  return (
    // eslint-disable-next-line no-restricted-globals
    (isNumber(val) && !isNaN(val)) ||
    // eslint-disable-next-line no-restricted-globals
    (isString(val) && !isNaN(val) && val !== '')
  );
};

export const getRulesCount = (rule = {}) => {
  let retVal = 0;
  if (isArray(rule.rules)) {
    retVal += rule.rules.reduce((acc, r) => {
      let count = acc;
      if (isArray(r.rules)) {
        count += getRulesCount(r);
      } else {
        count += 1;
      }
      return count;
    }, retVal);
  }

  return retVal;
};

export const getOperatorsForRule = (source, type) => {
  let operators;
  switch (source) {
    case SOURCE.TAG:
      operators = TAG_OPERATORS;
      break;
    default:
      operators = GENERIC_OPERATORS;
      break;
  }

  return get(operators, type, []);
};

export const getOperatorObjectForRule = (source, type, operatorValue) => {
  const operators = getOperatorsForRule(source, type);

  for (let i = 0; i < operators.length; i++) {
    const operatorObject = operators[i];

    if (isArray(operatorObject.operators)) {
      const subOperator = operatorObject.operators.find(
        localOperator => localOperator.operator === operatorValue
      );

      if (subOperator) {
        return subOperator;
      }
    }

    if (operatorObject.operator === operatorValue) {
      return operatorObject;
    }
  }

  return {};
};

export const getLabelForRule = (source, type, operator) => {
  const operatorObject = getOperatorObjectForRule(source, type, operator);
  return operatorObject.label || operator || '';
};

export const getIsBetween = operator => {
  return (
    operator === OPERATOR_TYPE.BETWEEN || operator === OPERATOR_TYPE.NOT_BETWEEN
  );
};

export const getIsEqual = operator => {
  return (
    operator === OPERATOR_TYPE.EQUAL ||
    operator === OPERATOR_TYPE.NOT_EQUAL ||
    operator === OPERATOR_TYPE.GREATER_OR_EQUAL ||
    operator === OPERATOR_TYPE.LESS_OR_EQUAL
  );
};

export const getIsNoValue = operator => {
  return NO_VALUES_OPERATORS.includes(operator);
};

export const getIsRelative = operator => {
  return (
    operator === OPERATOR_TYPE.GREATER_RELATIVE ||
    operator === OPERATOR_TYPE.LESS_RELATIVE
  );
};

export const getIsValidBetweenValueStructure = value => {
  return isArray(value) && value?.length === 2;
};

function formatValue(rule) {
  const isBetween = getIsBetween(rule?.operator);

  if (rule.type === RULE_TYPE.DATETIME) {
    const isRelative = getIsRelative(rule.operator);

    if (isRelative) {
      return `${rule.value} days ago`;
    }
    if (isBetween) {
      const date1 = formatDateTime(rule.value[0]);
      const date2 = formatDateTime(rule.value[1]);
      return `${date1} and ${date2}`;
    }

    return formatDateTime(rule.value);
  }

  if (rule.type === RULE_TYPE.TIME_NUMBER) {
    if (isBetween) {
      const date1 = secondsToHMSFormat(rule.value[0]);
      const date2 = secondsToHMSFormat(rule.value[1]);
      return `${date1} and ${date2}`;
    }

    return secondsToHMSFormat(rule.value);
  }

  if (isBetween) {
    return `${rule.value[0]} and ${rule.value[1]}`;
  }

  return `${rule.value}`;
}

export function isRuleValueDefined(rule) {
  if (isNil(rule.source) || isNil(rule.type) || isNil(rule.operator)) {
    return false;
  }

  if (getIsNoValue(rule.operator)) {
    return true;
  }

  const isBetween = getIsBetween(rule?.operator);
  const isArrayForBetweenValid = getIsValidBetweenValueStructure(rule?.value);

  if (isBetween && !isArrayForBetweenValid) {
    return false;
  }

  if (rule.type === RULE_TYPE.TIME_NUMBER) {
    return isBetween
      ? isNumber(rule.value[0]) && isNumber(rule.value[1])
      : isNumber(rule.value);
  }

  if (rule.type === RULE_TYPE.DATETIME) {
    const isRelative = getIsRelative(rule.operator);
    if (isRelative) {
      return isFinite(rule.value);
    }

    return isBetween
      ? isValidDate(rule.value[0]) && isValidDate(rule.value[1])
      : isValidDate(rule.value);
  }

  if (rule.type === RULE_TYPE.DOUBLE) {
    return isBetween
      ? isValidDouble(rule.value[0]) && isValidDouble(rule.value[1])
      : isValidDouble(rule.value);
  }

  if (rule.type === RULE_TYPE.STRING) {
    return isString(rule.value);
  }

  return true;
}

export function getSummaryString(rule) {
  if (!isRuleValueDefined(rule)) {
    return 'not yet defined';
  }

  const label = getLabelForRule(rule.source, rule.type, rule.operator);
  return label + (getIsNoValue(rule.operator) ? '' : ` ${formatValue(rule)}`);
}

export const getFilterDefaultValue = (filterValue, inputType, isBetween) => {
  const isArrayForBetweenValid = getIsValidBetweenValueStructure(filterValue);

  if (inputType === INPUT_TYPE.RELATIVE) {
    const defaultValue = 5;

    if (isFinite(filterValue)) {
      return [filterValue, defaultValue];
    }

    return [defaultValue, defaultValue];
  }

  if (inputType === INPUT_TYPE.DATETIME) {
    const defaultValue = new Date();
    if (isBetween) {
      if (
        !isArrayForBetweenValid ||
        !isValidDate(filterValue?.[0]) ||
        !isValidDate(filterValue?.[1])
      ) {
        return [
          isValidDate(filterValue)
            ? moment.utc(filterValue).toDate()
            : defaultValue,
          defaultValue
        ];
      }

      return [
        moment.utc(filterValue[0]).toDate(),
        moment.utc(filterValue[1]).toDate()
      ];
    }

    if (!isValidDate(filterValue)) {
      return [
        isValidDate(filterValue?.[0])
          ? moment.utc(filterValue[0]).toDate()
          : defaultValue,
        defaultValue
      ];
    }

    return [moment.utc(filterValue).toDate(), defaultValue];
  }

  if (inputType === INPUT_TYPE.TIME_NUMBER) {
    const defaultValue = ['00', '00', '00'];

    if (isBetween) {
      if (
        !isArrayForBetweenValid ||
        !isNumber(filterValue?.[0]) ||
        !isNumber(filterValue?.[1])
      ) {
        return [
          isNumber(filterValue)
            ? secondsToHMSFormat(filterValue).split(':')
            : defaultValue,
          defaultValue
        ];
      }

      return [
        secondsToHMSFormat(filterValue[0]).split(':'),
        secondsToHMSFormat(filterValue[1]).split(':')
      ];
    }

    if (!isNumber(filterValue)) {
      return [
        isNumber(filterValue?.[0])
          ? secondsToHMSFormat(filterValue[0]).split(':')
          : defaultValue,
        defaultValue
      ];
    }

    return [secondsToHMSFormat(filterValue).split(':'), defaultValue];
  }

  if (inputType === INPUT_TYPE.DOUBLE) {
    const defaultValue = '';

    if (isBetween) {
      if (
        !isArrayForBetweenValid ||
        !isValidDouble(filterValue?.[0]) ||
        !isValidDouble(filterValue?.[1])
      ) {
        return [
          isValidDouble(filterValue) ? filterValue.toString() : defaultValue,
          defaultValue
        ];
      }

      return [filterValue[0].toString(), filterValue[1].toString()];
    }

    if (!isValidDouble(filterValue)) {
      return [
        isValidDouble(filterValue?.[0])
          ? filterValue[0].toString()
          : defaultValue,
        defaultValue
      ];
    }

    return [filterValue.toString(), defaultValue];
  }

  // There are not any logic for next to types,
  // but it should return two items array to be comparable with used Input control
  if (
    inputType === INPUT_TYPE.STRING ||
    inputType === INPUT_TYPE.BOOLEAN ||
    inputType === INPUT_TYPE.NONE
  ) {
    return ['', ''];
  }

  return filterValue;
};

export const NULL_OPERATORS = [
  OPERATOR_TYPE.IS_NULL,
  OPERATOR_TYPE.IS_NOT_NULL
];

export const HMSFormatToSeconds = timeString => {
  if (!isString(timeString)) return timeString;
  const timeArray = timeString.split(':');
  const timeObject = {
    hours: Number(timeArray[0]),
    minutes: Number(timeArray[1]),
    seconds: Number(timeArray[2])
  };
  const time = Duration.fromObject(timeObject);
  return time.as('seconds');
};

export const secondsToHMSFormat = secondsInput => {
  const seconds = Number(secondsInput);
  const hours = (seconds / 3600 > 0 ? Math.floor(seconds / 3600) : 0)
    .toString()
    .padStart(2, '0');
  const minutes = (seconds / 60 > 0
    ? Math.floor((seconds - hours * 3600) / 60)
    : 0
  )
    .toString()
    .padStart(2, '0');
  const remainingSeconds = (seconds - hours * 3600 - minutes * 60)
    .toString()
    .padStart(2, '0');

  return `${hours}:${minutes}:${remainingSeconds}`;
};

const fixRuleForCompatibilityWithAPI = rule => {
  const newRule = {
    ...rule
  };

  if (NULL_OPERATORS.includes(rule.operator)) {
    delete newRule.value;
  }

  // The BE doesn't support 'is_true' and 'is_false' operator,
  // because eof that this operator changing functionality is applied
  if (rule.operator === OPERATOR_TYPE.IS_TRUE) {
    newRule.operator = OPERATOR_TYPE.EQUAL;
    newRule.value = 1;
  }

  if (rule.operator === OPERATOR_TYPE.IS_FALSE) {
    newRule.operator = OPERATOR_TYPE.EQUAL;
    newRule.value = 0;
  }

  if (rule.type === RULE_TYPE.DATETIME) {
    if (newRule.operator === OPERATOR_TYPE.GREATER_RELATIVE) {
      newRule.operator = OPERATOR_TYPE.GREATER;
      newRule.value = convertRelativeToAPIDate(newRule.value);
    }

    if (newRule.operator === OPERATOR_TYPE.LESS_RELATIVE) {
      newRule.operator = OPERATOR_TYPE.LESS;
      newRule.value = convertRelativeToAPIDate(newRule.value);
    }
  }

  return newRule;
};

export const prepareRulesTreeForRequest = (rulesTree, rules = []) => {
  const retVal =
    rulesTree && rulesTree.rules.length > 0
      ? cloneDeep(rulesTree)
      : generateEmptyRulesTree();

  retVal.rules = retVal.rules.map(andRuleGroup => {
    const group = generateEmptyAndGroup();

    group.rules = [...andRuleGroup.rules, ...rules]
      .map(fixRuleForCompatibilityWithAPI)
      .filter(isRuleValueDefined);

    return group;
  });

  retVal.rules = retVal.rules.filter(
    andRuleGroup => andRuleGroup.rules.length > 0
  );

  return retVal;
};

export const filterRulesTree = (rule, columns = []) => {
  if (isArray(rule.rules)) {
    // eslint-disable-next-line no-param-reassign
    rule.rules = rule.rules
      .map(r => {
        if (isArray(r.rules)) {
          return filterRulesTree(r, columns);
        }

        if (!columns.some(c => c.name === r.field)) {
          return null;
        }
        return r;
      })
      .filter(r => !!r);
  }

  return rule;
};

export const extendColumnsWithTagSource = columns => {
  if (
    !find(
      columns,
      c => c.source === TAG_COLUMN_NAME && c.id === TAG_COLUMN_NAME
    )
  ) {
    return [
      ...columns,
      {
        name: TAG_COLUMN_NAME,
        id: TAG_COLUMN_NAME,
        type: 'string',
        source: TAG_COLUMN_NAME
      }
    ];
  }

  return columns;
};

export const convertRelativeToAPIDate = days => {
  const date = moment().subtract(days, 'days').toISOString();

  return formatDateAsUtc(date);
};

const tokenize = str => {
  return str.trim().toLocaleLowerCase().split(/\s+/).filter(Boolean);
};

export const matchString = (source, search) => {
  const sourceTokens = tokenize(source);
  const searchTokens = tokenize(search);

  return searchTokens.every(searchToken => {
    return sourceTokens.some(sourceToken => sourceToken.includes(searchToken));
  });
};

export const RULE_GROUP_CONDITION = {
  OR: 'OR',
  AND: 'AND'
};

export const generateEmptyAndGroup = () => ({
  condition: RULE_GROUP_CONDITION.AND,
  rules: []
});

export const generateEmptyRulesTree = () => ({
  condition: RULE_GROUP_CONDITION.OR,
  rules: [generateEmptyAndGroup()]
});

export const generateNewRule = () => ({ isNewRule: true });

export const ISimpleRule = PropTypes.shape({
  field: PropTypes.string.isRequired,
  operator: PropTypes.oneOf(Object.values(OPERATOR_TYPE)),
  source: PropTypes.oneOf(Object.values(SOURCE)),
  type: PropTypes.oneOf(Object.values(RULE_TYPE)),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ])
});

export const IAndGroupRule = PropTypes.shape({
  rules: PropTypes.arrayOf(PropTypes.oneOfType([ISimpleRule])),
  condition: PropTypes.oneOf([RULE_GROUP_CONDITION.AND])
});

export const IOrGroupRule = PropTypes.shape({
  rules: PropTypes.arrayOf(PropTypes.oneOfType([IAndGroupRule])),
  condition: PropTypes.oneOf([RULE_GROUP_CONDITION.OR])
});
