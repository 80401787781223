import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextButton } from '@ds';

import Lottie from 'lottie-react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import RegisterModelSuccessfully from '../../../../public/images/model-registry/register-model-successfully.svg';
import RegisterModelFlowSuccessGif from './RegisterModelFlowSuccessGif.json';

import classNames from './RegisterModel.module.scss';

const RegisterModelFlowSuccess = ({ onClose, onSuccess }) => {
  const animationRef = useRef();
  const [timer, setTimer] = useState(null);
  const [isAnimationHidden, setIsAnimationHidden] = useState(false);

  useEffect(() => {
    setTimer(
      setTimeout(() => {
        setIsAnimationHidden(true);
      }, 1000 * 15)
    );

    return () => {
      clearTimeout(timer);
      setTimer(null);
      setIsAnimationHidden(false);
    };
  }, []);

  return (
    <>
      {!isAnimationHidden && (
        <Lottie
          ref={animationRef}
          className={classNames.registerModelSuccessAnimation}
          animationData={RegisterModelFlowSuccessGif}
        />
      )}
      <Grid
        data-test="streamline-model-sidebar-success"
        container
        direction="column"
        spacing={4}
        justifyContent="center"
        className={classNames.registerModelSuccess}
      >
        <Grid item xs={12}>
          <Box
            className={classNames.registerModelSuccessTitle}
            alignContent="center"
          >
            You successfully registered your model
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            direction="column"
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs>
              <RegisterModelSuccessfully />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} alignContent="center">
          <Grid
            className={classNames.registerModelSuccessActions}
            container
            spacing={1}
            direction="column"
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs>
              <Button
                size="large"
                onClick={onSuccess}
                data-test="streamline-model-sidebar-success-btn-view"
              >
                View in Model Registry
              </Button>
            </Grid>
            <Grid item xs>
              <TextButton
                size="large"
                onClick={onClose}
                data-test="streamline-model-sidebar-success-back"
              >
                Back to experiment
              </TextButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

RegisterModelFlowSuccess.defaultProps = {};

RegisterModelFlowSuccess.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RegisterModelFlowSuccess;
