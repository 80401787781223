// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accordion-module__dsAccordion--V0bBI{background:rgba(0,0,0,0) !important}.Accordion-module__dsAccordion--V0bBI .Accordion-module__accordionSummaryContainer--sBJ4L{display:flex}.Accordion-module__dsAccordionSummary--kBaDo{background:#fff !important;padding-left:24px;height:44px;min-height:44px !important}.Accordion-module__dsAccordionSummaryName--CKsFg{font-size:14px !important;padding-left:8px}.Accordion-module__dsAccordionSummary--kBaDo svg{font-size:17px}.Accordion-module__dsAccordionSummary--kBaDo svg path{fill:var(--primary-color)}.Accordion-module__dsAccordionDetails--JmzHe{padding:0 !important}.Accordion-module__dsAccordionExpandIcon--rtreu{transform:rotate(270deg)}.Mui-expanded .Accordion-module__dsAccordionExpandIcon--rtreu{transform:rotate(180deg)}.Accordion-module__dsAccordion--V0bBI .Accordion-module__expandIcon--um8ek{position:absolute;left:-2px}.Accordion-module__dsAccordion--V0bBI .MuiCollapse-hidden .MuiCollapse-wrapper{visibility:visible}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsAccordion": `Accordion-module__dsAccordion--V0bBI`,
	"accordionSummaryContainer": `Accordion-module__accordionSummaryContainer--sBJ4L`,
	"dsAccordionSummary": `Accordion-module__dsAccordionSummary--kBaDo`,
	"dsAccordionSummaryName": `Accordion-module__dsAccordionSummaryName--CKsFg`,
	"dsAccordionDetails": `Accordion-module__dsAccordionDetails--JmzHe`,
	"dsAccordionExpandIcon": `Accordion-module__dsAccordionExpandIcon--rtreu`,
	"expandIcon": `Accordion-module__expandIcon--um8ek`
};
module.exports = ___CSS_LOADER_EXPORT___;
