export const ROOT_DIR = '/';

export const DIR_TYPE = 'DIR';

export const FILE_TYPE = 'FILE';

export const DEFAULT_SORT = 'fileName';

export const DEFAULT_IS_IN_ORDER = true;
export const MIN_WIDTH_FIRST_CELL = 180;
export const WIDTH_FIRST_CELL_MD = 230;
export const WIDTH_FIRST_CELL_LG = 310;
export const WIDTH_FIRST_CELL_XL = 600;

export const DIR_TYPES = {
  EMBEDDINGS: 'embeddings'
};
