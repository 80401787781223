import { useQuery } from 'react-query';
import organizationApi from '@shared/api/organizationApi';

const mapInvitations = invitations =>
  invitations.map(invitation => ({
    id: invitation.inviteEmail,
    email: invitation.inviteEmail,
    workspaceId: invitation.teamId
  }));

const getOrganizationEmailInvitiatons = async organizationId => {
  const resp = await organizationApi.fetchOrganizationInvitedUsers(
    organizationId
  );
  const emailInvitations = resp?.data || [];

  return mapInvitations(emailInvitations.filter(({ claimed }) => !claimed));
};

const useOrganizationEmailInvitations = organizationId => {
  return useQuery(['organizations', organizationId, 'email-invitiatons'], () =>
    getOrganizationEmailInvitiatons(organizationId)
  );
};

export default useOrganizationEmailInvitations;
