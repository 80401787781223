import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import './Divider.scss';

export const DIVIDER_ORIENTATIONS = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

const Divider = ({ orientation, className, margin }) => {
  return (
    <hr
      className={cx('ds-divider', orientation, className)}
      style={{ marginTop: margin, marginBottom: margin }}
    />
  );
};

Divider.defaultProps = {
  orientation: DIVIDER_ORIENTATIONS.horizontal,
  className: '',
  margin: 20
};

Divider.propTypes = {
  orientation: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Divider;
