// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicsTabConfirmationDialog-module__graphicsTabConfirmationDialog--eBao0{width:460px}.GraphicsTabConfirmationDialog-module__graphicsTabConfirmationDialog--eBao0 .basic-modal-title{text-align:center;margin-bottom:0;margin-top:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphicsTabConfirmationDialog": `GraphicsTabConfirmationDialog-module__graphicsTabConfirmationDialog--eBao0`
};
module.exports = ___CSS_LOADER_EXPORT___;
