import React from 'react';
import { OtherParam } from '@shared/types';

import styles from './OtherSingleTable.module.scss';
import useTableRowSearch from '@shared/hooks/useTableRowSearch';
import useOthersTableColumnWidths from './hooks/useOtherSingleTableColumnWidths';
import { useSelector } from 'react-redux';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { Table, TableMaxHeightProvider } from '@DesignSystem/tables';
import {
  OTHERS_COLUMNS_NAME,
  OTHERS_TABLE_COLUMNS
} from './otherSingleTableConfig';
import OtherSingleTableEmpty from './OtherSingleTableEmpty';
import useOthersTableAPI from './hooks/useOtherSingleTableAPI';
import { OtherSingleTableContext } from './otherSingleTableContext';
import AddItemToolbarNew from '../../metrics/AddItemToolbar';
import OthersTableKeyCell from './cells/OtherSingleTableKeyCell';
import OthersTableKeyEditCell from './cells/OtherSingleTableKeyEditCell';
import OthersTableValueCell from './cells/OtherSingleTableValueCell';
import OthersTableValueEditCell from './cells/OtherSingleTableValueEditCell';
import OtherSingleDownload from './OtherSingleDownload';
import { PARAM_ACTION_COLUMN } from '@experiment-details/components/params/helpers';
import OtherSingleTableActionCell from './cells/OtherSingleTableActionCell';
import DeleteOtherParamModal from './modals/DeleteOtherParamModel';

const dataTypes = [
  {
    cols: [OTHERS_COLUMNS_NAME.KEY],
    cell: OthersTableKeyCell,
    editorCell: OthersTableKeyEditCell
  },
  {
    cols: [OTHERS_COLUMNS_NAME.VALUE],
    cell: OthersTableValueCell,
    editorCell: OthersTableValueEditCell
  },
  {
    cols: [PARAM_ACTION_COLUMN],
    cell: OtherSingleTableActionCell,
    editorCell: () => null
  }
];

const disabledConfig = {
  isDisabled: true
};

type OtherSingleTableProps = {
  items: OtherParam[];
  experimentKey: string;
  experimentName?: string;
};
const OtherSingleTable: React.FC<OtherSingleTableProps> = ({
  items,
  experimentKey,
  experimentName
}) => {
  const canEdit = useSelector(isUserAllowedToEditProject);
  const { searchText, handleSearch, resetSearch } = useTableRowSearch();
  const { columnWidths, onColumnWidthsChange } = useOthersTableColumnWidths();

  const {
    rowList,
    editingRowIds,
    addedRows,
    onAddOtherParam,
    changeAddedOtherParams,
    onEditOtherParams,
    changeEditedOtherParams,
    onResetOtherParam,
    onSubmitOtherParam,
    onDeleteOtherParam,
    isFetching,
    deleteModalConfig
  } = useOthersTableAPI({
    items,
    experimentKey,
    searchText
  });

  const editingConfig = {
    isDisabled: false,
    editingRowIds,
    addedRows,
    onRowChangesChange: changeEditedOtherParams,
    onAddedRowsChange: changeAddedOtherParams
  };

  return (
    <div className="table-tab-container">
      <AddItemToolbarNew
        onEdit={onAddOtherParam}
        onCancel={() => onResetOtherParam('newEntity')}
        canEdit={canEdit}
        searchHandler={handleSearch}
        searchValue={searchText}
        isAddingMode={!!addedRows.length}
        rightActionsPanel={
          <OtherSingleDownload params={items} experimentName={experimentName} />
        }
        isEmptyTab={!items.length}
      />

      <DeleteOtherParamModal {...deleteModalConfig} />

      <div className={styles.othersTable}>
        <TableMaxHeightProvider>
          <OtherSingleTableContext.Provider
            value={{
              onEditOtherParams,
              onResetOtherParam,
              onDeleteOtherParam,
              onSubmitOtherParam
            }}
          >
            <Table
              dataTypes={dataTypes}
              columns={OTHERS_TABLE_COLUMNS}
              rows={rowList}
              rowIdKey="name"
              height="auto"
              isFetching={isFetching}
              paginationConfig={disabledConfig}
              columnOrderConfig={disabledConfig}
              editingConfig={editingConfig}
              selectionConfig={disabledConfig}
              columnWidthsConfig={{
                isDisabled: false,
                columnWidths,
                onColumnWidthsChange
              }}
              renderEmptyState={materialProps => (
                <OtherSingleTableEmpty
                  searchValue={searchText}
                  resetSearch={resetSearch}
                  isLoading={isFetching}
                  {...materialProps}
                />
              )}
            />
          </OtherSingleTableContext.Provider>
        </TableMaxHeightProvider>
      </div>
    </div>
  );
};

export default OtherSingleTable;
