import React from 'react';

import { EmptyDAGIcon } from '@Icons-outdated';
import classNames from './DAGContent.module.scss';

export function EmptyDAG() {
  return (
    <div className={classNames.emptyDAGWrapper}>
      <div className={classNames.emptyDAGContent}>
        <EmptyDAGIcon />
        <div className={classNames.emptyDAGMessage}>
          Drag and drop models to create DAG
        </div>
      </div>
    </div>
  );
}
