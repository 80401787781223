import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import isEmpty from 'lodash/isEmpty';
import { TextButton } from '@ds';
import { useParams } from 'react-router';
import { Popover } from '@material-ui/core';

import { trackEvent } from '@shared/utils/eventTrack';

import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { snackbarTypes } from '@/constants/alertTypes';
import { experimentEvents } from '@/constants/trackingEventTypes';
import { DOCS_ROOT_URL } from '@/constants/configConstants';
import alertsUtil from '@/util/alertsUtil';

import PatchDiffModal from './PatchDiffModal';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { DSReproduceIcon } from '@ds-icons';
import useExperimentGitDetails, {
  GitDetails
} from '@experiment-details/api/useExperimentGitDetails';

const PATCH_DIFF_MODAL_ID = 'PatchDiffModal';

const EMPTY_RESPONSE: GitDetails = {
  branch: '',
  gitHead: '',
  gitOrigin: '',
  gitUsername: '',
  hostname: '',
  ip: '',
  linkToBranch: '',
  linkToGitHead: '',
  linkToPatch: '',
  notebookAvailable: false,
  notebookLink: '',
  patchExists: false,
  reproduceCommands: [],
  runCommand: ''
};

const GitReproduceButton = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const canEdit = useSelector(isUserAllowedToEditProject);

  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const openPopover = (element: Element | null) => setAnchorEl(element);
  const closePopover = () => setAnchorEl(null);

  const wrapperRef = useRef(null);
  const { experimentKey, workspace } = useParams<{
    experimentKey: string;
    workspace: string;
  }>();

  const { data = EMPTY_RESPONSE, isLoading } = useExperimentGitDetails(
    { experimentKey },
    {
      enabled: Boolean(anchorEl),
      refetchOnMount: false
    }
  );

  const {
    branch,
    gitHead,
    gitOrigin,
    gitUsername,
    hostname,
    ip,
    linkToBranch,
    linkToGitHead,
    linkToPatch,
    notebookAvailable,
    notebookLink,
    patchExists,
    reproduceCommands,
    runCommand
  } = data;

  const isGitDetailsAvailable =
    !isEmpty(gitHead) || !isEmpty(branch) || patchExists;
  const notebookDownloadFileName = `comet-${experimentKey}.ipynb`;
  const outputFileName = 'patch.zip';

  useEffect(() => {
    if (anchorEl) {
      trackEvent(experimentEvents.REPRODUCE_TAB_OPEN, {
        experimentKey,
        workspace
      });
    }
  }, [experimentKey, anchorEl, workspace]);

  const toggleDetailsPane = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = e;

    const container = anchorEl === null ? currentTarget : null;
    openPopover(container);
  };

  const handleCopyNotification = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_API_KEY,
        'Successfully copied commands to clipboard'
      )
    );
  };

  const handleOpenPatchView = () => {
    const patchViewModal = <PatchDiffModal url={linkToPatch} />;

    dispatch(alertsUtil.openCustomModal(PATCH_DIFF_MODAL_ID, patchViewModal));

    logViewPatch();
  };

  const logDownloadPatch = () => {
    trackEvent(experimentEvents.DOWNLOAD_GIT_PATCH, {
      experimentKey,
      workspace
    });
  };

  const logViewPatch = () => {
    trackEvent(experimentEvents.VIEW_GIT_PATCH, {
      experimentKey,
      workspace
    });
  };

  const renderCopyButton = (textToCopy: string) => (
    <div className="copy-button">
      <CopyToClipboard text={textToCopy}>
        <i className="material-icons" onClick={handleCopyNotification}>
          file_copy
        </i>
      </CopyToClipboard>
    </div>
  );

  const renderEnvironmentInfo = () => (
    <div className="details-section">
      <h6 className="details-section-title">Environment Information</h6>
      {ip ? (
        <div className="environment-info">
          <p>
            <i className="material-icons">location_on</i> {ip}
          </p>
          <p>
            <i className="material-icons">computer</i> {hostname}
          </p>
          <p>
            <i className="material-icons">person</i> {gitUsername}
          </p>
        </div>
      ) : (
        <p className="details-nowrap">No environment information available</p>
      )}
    </div>
  );

  const renderPatch = () => {
    if (!patchExists) {
      return <p>No patch available</p>;
    }

    return (
      <p className="git-reproduce-details-pane-patch">
        <a onClick={handleOpenPatchView}>View</a>

        <a
          href={linkToPatch}
          download={outputFileName}
          onClick={logDownloadPatch}
        >
          Download
        </a>
      </p>
    );
  };

  const renderGitLink = (ref: string, link: string, type: string) => {
    if (!ref) {
      return <p>{`No ${type} available`}</p>;
    }

    if (!link) {
      return <p>{ref}</p>;
    }

    return (
      <p>
        <a href={`https://${link}`} target="_blank" rel="noopener noreferrer">
          {ref}
        </a>
      </p>
    );
  };

  const renderGitInfo = () => (
    <div className="details-section">
      <h6 className="details-section-title">Git Information</h6>
      {isGitDetailsAvailable ? (
        <div className="git-info-available">
          <div className="git-links">
            <p>commit</p>
            <p>branch</p>
            <p>patch</p>
          </div>
          <div className="git-links">
            {renderGitLink(gitHead, linkToGitHead, 'commit')}
            {renderGitLink(branch, linkToBranch, 'branch')}
            {renderPatch()}
          </div>
        </div>
      ) : (
        <div className="git-info-unavailable">
          <p className="details-nowrap">
            Not Available -{' '}
            <a
              href={`${DOCS_ROOT_URL}/guides/comet-dashboard/more-in-experiments/#reproduce-an-experiment`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to learn about Git Integration
            </a>
          </p>
        </div>
      )}
    </div>
  );

  const renderReproduceInstructions = () => {
    const isReproduceCommandsAvailable =
      reproduceCommands && !isEmpty(reproduceCommands);

    if (!isReproduceCommandsAvailable) return null;

    let gitCommandsList = [] as string[];

    if (gitOrigin !== null) {
      const [dirName] = gitOrigin
        .slice(gitOrigin.lastIndexOf('/') + 1)
        .split('.');
      gitCommandsList = [`git clone ${gitOrigin}`, `cd ${dirName}`];
    }

    const commandsList = [...gitCommandsList, ...reproduceCommands];
    const instructionText = commandsList.join('\n');

    return (
      <div className="details-section">
        <h6 className="details-section-title">Reproduce</h6>
        <div className="instructions" data-test="reproduce-instructions">
          {commandsList.map(command => {
            const shellCommand = command.split(' ')[0];
            const shellCommandArgs = command.slice(shellCommand.length);
            return (
              <p key={shellCommandArgs}>
                <span className="shell-icon">
                  <i className="material-icons">attach_money</i>
                </span>
                <span className="shell-command">{shellCommand}</span>
                <span className="shell-args">{shellCommandArgs}</span>
              </p>
            );
          })}
          {renderCopyButton(instructionText)}
        </div>
      </div>
    );
  };

  const renderRunInstructions = () => (
    <div className="details-section">
      <h6 className="details-section-title">Run</h6>
      {runCommand ? (
        <div className="instructions" data-test="run-instructions">
          <p>
            <span className="shell-icon">
              <i className="material-icons">attach_money</i>
            </span>
            <span className="shell-command">{runCommand}</span>
          </p>
          {renderCopyButton(runCommand)}
        </div>
      ) : (
        <p className="details-nowrap">No run command available.</p>
      )}
    </div>
  );

  const renderNotebookInfo = () => {
    if (!notebookLink) return;

    return (
      <div className="details-section">
        <h6 className="details-section-title">Notebook Information</h6>
        <p className="details-nowrap">
          <a href={notebookLink} download={notebookDownloadFileName}>
            Download Notebook
          </a>
        </p>
      </div>
    );
  };

  const renderDetailsPane = () => {
    if (isLoading) {
      return null;
    }

    return (
      <Popover
        className="git-reproduce-details-pane"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {renderEnvironmentInfo()}
        {notebookAvailable ? (
          renderNotebookInfo()
        ) : (
          <span>
            {renderGitInfo()}
            {renderReproduceInstructions()}
            {renderRunInstructions()}
          </span>
        )}
      </Popover>
    );
  };

  return (
    <div ref={wrapperRef}>
      <TextButton
        type="secondary"
        PrefixIcon={<DSReproduceIcon />}
        onClick={toggleDetailsPane}
        disabled={!isUserLoggedIn || !canEdit}
        active={Boolean(anchorEl)}
      >
        Reproduce
      </TextButton>

      {renderDetailsPane()}
    </div>
  );
};

export default GitReproduceButton;
