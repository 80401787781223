import React from 'react';
import { Tooltip } from '@ds';
import { OtherCellRendererData } from './types';

type OtherSingleTableValueCellProps = OtherCellRendererData;

const OtherSingleTableValueCell: React.FC<OtherSingleTableValueCellProps> = ({
  value
}) => {
  return (
    <Tooltip content={value} placement="top" truncateOverflow>
      <>{value}</>
    </Tooltip>
  );
};

export default OtherSingleTableValueCell;
