import Grid from '@material-ui/core/Grid';
import cx from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Slider } from '../Slider';
import classNames from './StepSlider.module.scss';
import StepSliderButtons from './StepSliderButtons';

const StepSlider = props => {
  const {
    stepsMap = {},
    valuesMap = {},
    uniqueSteps,
    onMessage,
    onStepChange,
    trackCallback,
    defaultSliderValue,
    maxStepSize,
    noStep,
    hidePlayButtons,
    valueChangeDependencies
  } = props;
  const isStepAll = step => step === defaultSliderValue || step === undefined;
  const [sliderValue, setSliderValue] = useState(defaultSliderValue);
  const [isEdit, setIsEdit] = useState(false);
  const [nonExistingStep, setNonExistingStep] = useState(null);
  const [textboxValue, setTextboxValue] = useState(null);

  const mapStepToValue = step => stepsMap[step];
  const mapValueToStep = value => valuesMap[value];

  const handleOnStepChange = val => {
    if (Number.isNaN(val)) return;

    const isDisableSearchFilter = val === defaultSliderValue;
    const value = val === noStep ? null : mapStepToValue(val);

    if (value) {
      setNonExistingStep(null);
      setSliderValue(val);
      setTextboxValue(value);
    }
    onStepChange(value, isDisableSearchFilter);
  };

  useEffect(() => {
    setSliderValue(defaultSliderValue);
  }, valueChangeDependencies || []);

  const translateStep = value => {
    let step = mapValueToStep(value);
    if (value === '') {
      step = defaultSliderValue;
      setNonExistingStep(null);
    } else if (!step && step !== 0) {
      step = noStep;
      setNonExistingStep(true);
    }

    return step;
  };

  const handleTextChange = event => {
    const { value } = event.target;

    if (Number.isNaN(value) || value.length > maxStepSize) return sliderValue;

    const step = translateStep(value);

    setTextboxValue(value);
    if (step) handleOnStepChange(step);
    return step;
  };

  const handleStepSearchValueChange = val => {
    if (sliderValue < defaultSliderValue) {
      setNonExistingStep(noStep);
    } else {
      setNonExistingStep(null);
      setSliderValue(val);
      handleOnStepChange(val);
    }
  };

  const advanceRight = () => {
    const newSliderValue = (sliderValue + 1) % uniqueSteps.length;

    setSliderValue(newSliderValue);
    setNonExistingStep(null);
    handleOnStepChange(newSliderValue);
  };

  const enableEditText = isEditInside => setIsEdit(!isEditInside);

  const handleTextBlur = useCallback(() => {
    if (nonExistingStep) onMessage('Step does not exist');
    setIsEdit(!isEdit);

    handleOnStepChange(sliderValue);
  }, [nonExistingStep, isEdit, sliderValue]);

  const resetStep = () => {
    setIsEdit(false);
    setNonExistingStep(false);
    setSliderValue(defaultSliderValue);
    setTextboxValue('');
    handleOnStepChange(defaultSliderValue);
  };

  const stepDisplay = step => {
    if (isStepAll(step)) return 'All';
    if (step === noStep) return 'N/A';
    if (step === null) return 'other';
    return step;
  };

  const stepToShow = nonExistingStep ? noStep : sliderValue;
  const uniqueStepsLength = uniqueSteps?.length - 1;
  const isDisabled = uniqueStepsLength === defaultSliderValue;
  const value = isDisabled ? defaultSliderValue : stepToShow;

  const renderDisplayStepNumber = useCallback(
    val => {
      const sliderInput = nonExistingStep ? noStep : mapStepToValue(val);

      if (!isEdit) {
        return (
          <div
            className={classNames.dsStepSliderLabelContainer}
            onClick={() => enableEditText(isEdit)}
          >
            {stepDisplay(sliderInput)}
          </div>
        );
      }

      if (isEdit) {
        return (
          <input
            className={cx(
              classNames.dsStepSliderLabelContainer,
              classNames.dsStepSliderLabelContainerInput
            )}
            onChange={handleTextChange}
            onMouseLeave={handleTextBlur}
            value={textboxValue?.toString()}
            style={{ width: textboxValue?.length * 10 }}
            autoFocus
          />
        );
      }
    },
    [nonExistingStep, isEdit, textboxValue, stepsMap, valuesMap]
  );

  return (
    <div className={cx(classNames.dsStepSlider, classNames.dsStepSliderFlex)}>
      <Grid container className={classNames.dsStepSliderRow} spacing={1}>
        <Grid item xs="auto" className={cx(classNames.dsStepSliderContainer)}>
          <Slider
            className={classNames.dsStepSliderSlider}
            hideValueLabel
            min={defaultSliderValue}
            max={uniqueStepsLength}
            step={1}
            defaultValue={defaultSliderValue}
            valueLabelDisplay="off"
            value={value}
            onChange={handleStepSearchValueChange}
          />
        </Grid>
        <Grid item xs="auto">
          {renderDisplayStepNumber(sliderValue)}
        </Grid>
        {!hidePlayButtons && (
          <Grid item xs="auto">
            <StepSliderButtons
              resetStep={resetStep}
              advanceRight={advanceRight}
              sliderValue={sliderValue}
              trackCallback={trackCallback}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

StepSlider.defaultProps = {
  hidePlayButtons: false,
  stepsMap: {},
  valuesMap: {},
  onMessage: noop,
  trackCallback: noop,
  valueChangeDependencies: []
};

StepSlider.propTypes = {
  hidePlayButtons: PropTypes.bool,
  stepsMap: PropTypes.object,
  valuesMap: PropTypes.object,
  uniqueSteps: PropTypes.array.isRequired,
  onMessage: PropTypes.func,
  valueChangeDependencies: PropTypes.array,
  onStepChange: PropTypes.func.isRequired,
  trackCallback: PropTypes.func,
  defaultSliderValue: PropTypes.number.isRequired,
  maxStepSize: PropTypes.number.isRequired,
  noStep: PropTypes.number.isRequired
};

export default StepSlider;
