import React from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { useHistory, useParams } from 'react-router';
import queryString from 'query-string';

import { DSShowIcon, DSNotificationsIcon } from '@ds-icons';
import { CloseInspectTooltipIcon } from '@Icons-outdated';
import './ChartTooltip.scss';
import {
  ALLOWED_ALERT_CREATE_BUTTON_SECTIONS,
  PERCENTILE_TYPE_MAP,
  SOURCE_TYPE,
  PANEL_SECTIONS,
  CHART_TYPES
} from '@mpm-druid/constants';
import { PanelTab, Feature } from '@mpm-druid/types';
import { getTooltipHeaderTitle, parseTooltipTemplate } from '@mpm-druid/utils';

type PercentileMode = 'minmax' | 'p10-p90' | 'p25-p75' | 'none';
type PercentileTypeMapKey = keyof typeof PERCENTILE_TYPE_MAP;

type ChartTooltipProps = {
  tooltipData: {
    x: string;
    y: number;
    title?: string;
    color: string;
    hovertemplate: string;
    text: string;
    percentileType: string;
    data: {
      pointIndex: number;
      data: {
        x: string;
      };
    };
    headerTitle?: string;
  };
  position: { left: number; top: number };
  closeHandler: () => void;
  inspectMode: boolean;
  orientation: 'left' | 'right';
  section: PANEL_SECTIONS;
  chartType: typeof CHART_TYPES[keyof typeof CHART_TYPES];
  driftAlgorithm: string;
  hideInspection: () => void;
  tabData: PanelTab;
  showViewFeatureAction: boolean;
  percentileMode: PercentileMode;
  feature: Feature;
};

export const ChartTooltip = ({
  tooltipData,
  position,
  closeHandler = noop,
  inspectMode,
  orientation,
  section,
  chartType,
  driftAlgorithm,
  hideInspection,
  tabData,
  showViewFeatureAction,
  percentileMode,
  feature
}: ChartTooltipProps) => {
  const history = useHistory();
  const { intervalType, version } = queryString.parse(history.location.search);
  const { modelId, workspace } = useParams<{
    modelId: string;
    workspace: string;
  }>();
  const featureDetails = feature || tabData?.feature;
  const {
    x,
    y,
    title,
    color,
    hovertemplate,
    text,
    data,
    headerTitle
  } = tooltipData;
  const leftOrientation = orientation === 'left';

  const pointIdx = data?.pointIndex;

  const xValuesList = tooltipData?.data?.data?.x;

  const showCreateAlertBtn = ALLOWED_ALERT_CREATE_BUTTON_SECTIONS.includes(
    section
  );

  const percentileTypeKey = `${percentileMode}_${tooltipData.percentileType}` as PercentileTypeMapKey;
  const percentileType = PERCENTILE_TYPE_MAP[percentileTypeKey];

  const handleCreateAlert = () => {
    let featureName = undefined;
    if (featureDetails) {
      featureName =
        featureDetails?.source === SOURCE_TYPE.model_input_features
          ? `feature_${featureDetails.name}`
          : featureDetails?.source === SOURCE_TYPE.model_output_features
          ? `prediction_${featureDetails.name}`
          : undefined;
    }
    const queryParams = {
      section,
      intervalType,
      version,
      customMetricId: tabData?.metricId ?? '',
      featureName,
      driftAlgorithm,
      percentileType,
      filterData: title !== 'all' ? title : ''
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(
        ([, value]) => value !== undefined && value !== null && value !== ''
      )
    ) as Record<string, string>;
    const queryString = new URLSearchParams(filteredParams).toString();
    const url = `/${workspace}/model-production-monitoring/${modelId}/alerts/add?${queryString}`;
    history.push(url);
  };

  const handleViewFeatureDetails = () => {
    history.push({
      pathname: `/${workspace}/model-production-monitoring/${modelId}/features/${featureDetails.name}/${featureDetails.source}`,
      search: location.search
    });
  };

  return (
    <span
      className={cx('version-tooltip-wrapper', {
        'left-oriented-tooltip-wrapper': leftOrientation
      })}
      style={{ ...position }}
    >
      <div
        className={cx('version-tooltip', {
          'left-oriented-version-tooltip': leftOrientation,
          'inspectMode-version-tooltip': inspectMode
        })}
      >
        {inspectMode && (
          <CloseInspectTooltipIcon
            className={cx('version-tooltip-close-icon', {
              'left-oriented-close-icon': leftOrientation
            })}
            onClick={closeHandler}
          />
        )}

        <div className="tooltip-header">
          {getTooltipHeaderTitle({
            headerTitle,
            xValue: x,
            xValuesList,
            intervalType,
            chartType,
            pointIdx
          })}
        </div>
        <div
          className={cx('inspect-message', {
            'without-inspection': hideInspection
          })}
        >
          {title && (
            <div className="tooltip-indicator-wrapper">
              <div
                className="color-indicator"
                style={{ backgroundColor: color }}
              />
              <div className="tooltip-indicator-title">{title}</div>
            </div>
          )}
          <>{parseTooltipTemplate(hovertemplate, text, y, data)}</>
        </div>
        {!inspectMode && !hideInspection && (
          <p className="tooltip-button-text">Click graph to inspect</p>
        )}
        {inspectMode && (showCreateAlertBtn || showViewFeatureAction) && (
          <div className="tooltip-actions-wrapper">
            {showCreateAlertBtn && (
              <div onClick={handleCreateAlert} className="tooltip-action">
                <div className="tooltip-icon-wrapper">
                  <DSNotificationsIcon className="tooltip-icon" />
                </div>
                <span>Create Alert</span>
              </div>
            )}
            {showViewFeatureAction && (
              <div
                onClick={handleViewFeatureDetails}
                className="tooltip-action"
              >
                <div className="tooltip-icon-wrapper">
                  <DSShowIcon className="tooltip-icon" />
                </div>
                <span>View Feature</span>
              </div>
            )}
          </div>
        )}
      </div>
    </span>
  );
};
