import React, { useState } from 'react';

import { Input } from '@DesignSystem/controllers';
import { Button } from '@ds';
import { useInfixPostfixParser } from '../../hooks';
import './InfixPostfixParserPage.scss';

export const InfixPostfixParserPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [evaluateValue, setEvaluateValue] = useState(false);

  const [
    parsedTokens,
    postfixExpression,
    evaluatedValue,
    formulaError
  ] = useInfixPostfixParser(inputValue, evaluateValue);

  const inputHandler = value => {
    setEvaluateValue(false);
    setInputValue(value);
  };

  return (
    <div className="parser-wrapper">
      <h3>Infix Postfix parser!</h3>
      <div className="parser-content">
        <div className="usage-description">
          <p>
            Allowed properties: [label], [prediction], [probability] which are
            equal to 1 by default
          </p>
          <p>Allowed functions: abs(), average()</p>
          <p>Allowed operators: +, -, *, /, (, )</p>
        </div>
        <label htmlFor="formula">Please insert formula for calculations</label>
        <div className="input-section">
          <span className="input-wrapper">
            <Input
              value={inputValue}
              onChange={inputHandler}
              name="formula"
              placeholder="Please insert formula"
              className="formula-input"
            />
          </span>
          <Button
            onClick={() => {
              setEvaluateValue(true);
            }}
            disabled={!inputValue}
          >
            Evaluate Formula
          </Button>
        </div>
        {formulaError && <p className="formula-error">{formulaError}</p>}
        <h4>Output results</h4>
        <label htmlFor="tokens">Parsed Tokens:</label>
        <Input readOnly name="tokens" value={parsedTokens} />
        <label htmlFor="postfix">Postfix Expression:</label>
        <Input readOnly name="postfix" value={postfixExpression} />
        <label htmlFor="value">Evaluated value:</label>
        <Input readOnly name="value" value={evaluatedValue} />
      </div>
    </div>
  );
};
