import React from 'react';
import { ListItem, Tooltip } from '@ds';
import { Avatar } from '@DesignSystem/data-display';
import cx from 'classnames';

import styles from './UserMenuOrganization.module.scss';
import { DSArrowRightIcon } from '@design-system/icons';

type UserMenuOrganizationItemProps = {
  onClick: React.MouseEventHandler;
  avatarLetters: string;
  avatarUrl: string;
  title: string;
  dataTestId: string;
  isActive?: boolean;
  showRightArrow?: boolean;
  isReadonly?: boolean;
  isGuestUser?: boolean;
};
const UserMenuOrganizationItem: React.FC<UserMenuOrganizationItemProps> = ({
  onClick,
  avatarLetters,
  avatarUrl,
  title,
  dataTestId,
  isActive = false,
  showRightArrow = false,
  isReadonly = false,
  isGuestUser = false
}) => {
  return (
    <Tooltip content={title} wrapperDisplay="block">
      <ListItem
        onClick={onClick}
        active={isActive}
        isReadonly={isReadonly}
        data-test={dataTestId}
      >
        <div
          className={cx(styles.organizationInfo, {
            [styles.organizationInfoBold]: isActive || isGuestUser
          })}
        >
          {!isGuestUser && (
            <Avatar
              src={avatarUrl}
              square
              width="20px"
              showLetters
              lettersLineHeight="1"
              letters={avatarLetters.toUpperCase()}
              backgroundColor="#12A4B4"
              lettersFontSize="12px"
            />
          )}
          <span className="truncated-string">{title}</span>
        </div>
        {showRightArrow && (
          <ListItem.ActionContainer>
            <ListItem.Icon Icon={<DSArrowRightIcon />} />
          </ListItem.ActionContainer>
        )}
      </ListItem>
    </Tooltip>
  );
};

export default UserMenuOrganizationItem;
