import {
  DURATION,
  WALL
} from '@experiment-management-shared/constants/experimentConstants';
import { systemMetricsTableActionTypes } from '@/constants/actionTypes';

const initialChartState = {
  entireRowCharts: false,
  xAxisType: WALL,
  compareXAxisType: DURATION
};

const systemMetricsChartReducer = (state = initialChartState, action) => {
  const { type, payload } = action;

  if (type === systemMetricsTableActionTypes.UPDATE_ENTIRE_ROW_CHARTS) {
    return {
      ...state,
      entireRowCharts: payload
    };
  }

  if (type === systemMetricsTableActionTypes.UPDATE_X_AXIS_TYPE) {
    return {
      ...state,
      xAxisType: payload
    };
  }

  if (type === systemMetricsTableActionTypes.UPDATE_COMPARE_X_AXIS_TYPE) {
    return {
      ...state,
      compareXAxisType: payload
    };
  }

  return state;
};

export default systemMetricsChartReducer;
