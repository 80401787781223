import TextField from '@material-ui/core/TextField';
import { markdownToHTML } from '@shared/utils';
import cx from 'classnames';
import 'github-markdown-css/github-markdown.css';
import 'katex/dist/katex.css';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './Markdown.module.scss';

const Markdown = ({
  editable,
  markdown: initialMarkdown,
  onChange,
  placeholder,
  InputProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [markdown, setMarkdown] = useState(initialMarkdown);
  const [html, setHTML] = useState(null);

  useEffect(() => {
    setMarkdown(initialMarkdown);
  }, [initialMarkdown]);

  useEffect(() => {
    let cancelled = false;

    const processMarkdown = async () => {
      const newHTML = await markdownToHTML(markdown);

      if (!cancelled) {
        setHTML(newHTML);
      }
    };

    if (markdown) {
      processMarkdown();
    } else {
      setHTML('');
    }

    return () => {
      cancelled = true;
    };
  }, [markdown]);

  const handleChange = event => {
    setMarkdown(event.target.value);
  };

  const handleEditMarkdown = () => {
    if (!editable) return;

    setIsEditing(true);
  };

  const handleSave = () => {
    if (markdown !== initialMarkdown) {
      onChange(markdown);
    }

    setIsEditing(false);
  };

  if (isEditing) {
    const lines = markdown.split('\n').length;
    const rows = Math.max(lines, 3);

    return (
      <div>
        <TextField
          autoFocus
          onChange={handleChange}
          multiline
          onBlur={handleSave}
          placeholder={placeholder}
          rows={rows}
          value={markdown}
          variant="outlined"
          style={{ width: '100%' }}
          {...InputProps}
        />
      </div>
    );
  }

  if (!html && !editable) {
    return null;
  }

  return (
    <div
      className={cx({ [styles.editable]: editable })}
      onClick={handleEditMarkdown}
    >
      {!html && <div className={styles.placeholder}>{placeholder}</div>}
      {html && (
        <div
          className={styles.content}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
      )}
    </div>
  );
};

Markdown.defaultProps = {
  editable: false,
  markdown: '',
  onChange: noop,
  placeholder: 'Add text/markdown...',
  InputProps: {}
};

Markdown.propTypes = {
  editable: PropTypes.bool,
  markdown: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  InputProps: PropTypes.object
};

export default Markdown;
