import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import { Button } from '@ds';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  PANEL_PREVIEW_PATH,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';
import { TEMPLATE_SCOPE_TYPES } from '@experiment-management-shared/constants/chartsGallery';

import GenericModal from '@shared/components/GenericModal';
import { dialogTypes } from '@/constants/alertTypes';

import alertsUtil from '../../../util/alertsUtil';

import usePanelTemplate from '../../../api/panels/usePanelTemplate';
import useProjectParams from '../../../api/project/useProjectParams';
import { getViewHasUnsavedChanges } from '@/reducers/ui/projectsUiReducer';

const BaseSharePanelModal = ({
  chartId,
  children,
  isCustomPanel,
  templateId,
  title
}) => {
  const dispatch = useDispatch();
  const { data: template } = usePanelTemplate({ templateId });
  const { workspace } = useProjectParams();
  const isPrivateTemplate =
    template?.scopeType === TEMPLATE_SCOPE_TYPES.PRIVATE;
  const isUnsavedView = useSelector(getViewHasUnsavedChanges);

  const editTemplateURL = `${window.location.pathname}/${SELECT_PANEL_PATH}/${PANEL_PREVIEW_PATH}/edit-code/${templateId}?chartId=${chartId}`;
  const showPrivatePanelAlert = isCustomPanel && isPrivateTemplate;
  const showUnsavedPanelAlert = !isCustomPanel && isUnsavedView;

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(dialogTypes.SHARE_PANEL_MODAL));
  };

  return (
    <GenericModal
      customClass="generic-modal-alt share-panel-modal"
      hideFooter
      onClose={handleClose}
      title={title}
    >
      <DialogContent className="generic-modal-body">
        {!showUnsavedPanelAlert && children}

        {showPrivatePanelAlert && (
          <Alert className="share-panel-warning" severity="warning">
            <div>
              This Panel is marked as private and would only be visible to{' '}
              {workspace} collaborators, to share it outside this workspace
              change panel permissions to public.
            </div>

            <div className="share-panel-warning-link">
              <Link onClick={handleClose} to={editTemplateURL}>
                Click here to change panel permissions
              </Link>
            </div>
          </Alert>
        )}

        {showUnsavedPanelAlert && (
          <Alert className="share-panel-warning" severity="warning">
            This view has unsaved changes. Save the view first in order to share
            this panel.
          </Alert>
        )}
      </DialogContent>

      <DialogActions className="generic-modal-footer-alt right">
        <Button size="large" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

BaseSharePanelModal.propTypes = {
  chartId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isCustomPanel: PropTypes.bool.isRequired,
  templateId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default BaseSharePanelModal;
