import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import upperFirst from 'lodash/upperFirst';

import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';

import GenericModal from '@shared/components/GenericModal';
import alertsUtil from '@/util/alertsUtil';

const { isUserSavedTemplate } = chartHelpers;

const SaveTemplateModal = ({
  GenericModalProps,
  defaultNewTemplateName,
  defaultViewCheckboxLabel,
  entityType,
  isUpdateTemplateDisabled,
  modalId,
  saveNewTemplateHandler,
  showDefaultViewCheckbox,
  submitButtonDataCy,
  templateId,
  templateName,
  updateTemplateHandler
}) => {
  const dispatch = useDispatch();

  const SAVE = 'save';
  const SAVE_AS = 'saveAs';

  const isDefaultView = !templateId;
  const initialSaveType =
    isDefaultView || isUpdateTemplateDisabled ? SAVE_AS : SAVE;
  const [saveType, setSaveType] = useState(initialSaveType);
  const [newTemplateName, setNewTemplateName] = useState(
    defaultNewTemplateName
  );
  const [shouldSetAsDefaultView, setShouldSetAsDefaultView] = useState(false);

  const onClose = () => {
    if (saveType === SAVE_AS) {
      saveNewTemplateHandler(newTemplateName, shouldSetAsDefaultView);
    } else {
      updateTemplateHandler();
    }
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const isBtnDisabled = () => {
    return newTemplateName.length === 0 && saveType === SAVE_AS;
  };

  return (
    <GenericModal
      title={`Save ${upperFirst(entityType)}`}
      onClose={onClose}
      isButtonDisabled={isBtnDisabled()}
      buttonText="Save"
      submitButtonDataCy={submitButtonDataCy}
      {...GenericModalProps}
    >
      <DialogContent className="generic-modal-body">
        <div className="generic-modal-group">
          <div className="generic-modal-group-item">
            <FormGroup>
              <RadioGroup
                name="radio"
                value={saveType}
                onChange={event => setSaveType(event.target.value)}
              >
                {isUserSavedTemplate(templateId) && !isUpdateTemplateDisabled && (
                  <FormControlLabel
                    value={SAVE}
                    control={<Radio color="primary" />}
                    label={
                      <span>
                        Save changes to the {entityType}{' '}
                        <strong>{templateName}</strong>
                      </span>
                    }
                  />
                )}
                <FormControlLabel
                  value={SAVE_AS}
                  control={<Radio color="primary" />}
                  label={`Create new ${entityType}`}
                />
              </RadioGroup>
              {saveType === SAVE_AS && (
                <TextField
                  label={`${upperFirst(entityType)} name`}
                  placeholder="Name"
                  value={newTemplateName}
                  onChange={event => setNewTemplateName(event.target.value)}
                  disabled={saveType === SAVE}
                  required
                  margin="normal"
                  id="name"
                />
              )}
              {showDefaultViewCheckbox && saveType === SAVE_AS && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldSetAsDefaultView}
                      onChange={e =>
                        setShouldSetAsDefaultView(e.target.checked)
                      }
                      size="small"
                      style={{ color: '#5155f5' }}
                    />
                  }
                  label={defaultViewCheckboxLabel}
                  style={{ marginTop: 30 }}
                />
              )}
            </FormGroup>
          </div>
        </div>
      </DialogContent>
    </GenericModal>
  );
};

SaveTemplateModal.defaultProps = {
  defaultNewTemplateName: '',
  defaultViewCheckboxLabel: 'Set as the default view for this project',
  entityType: 'view',
  GenericModalProps: {},
  isUpdateTemplateDisabled: false,
  showDefaultViewCheckbox: false,
  templateId: null,
  templateName: ''
};

SaveTemplateModal.propTypes = {
  defaultNewTemplateName: PropTypes.string,
  defaultViewCheckboxLabel: PropTypes.string,
  entityType: PropTypes.string,
  GenericModalProps: PropTypes.object,
  isUpdateTemplateDisabled: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  saveNewTemplateHandler: PropTypes.func.isRequired,
  showDefaultViewCheckbox: PropTypes.bool,
  templateId: PropTypes.string,
  templateName: PropTypes.string,
  updateTemplateHandler: PropTypes.func.isRequired
};

export default SaveTemplateModal;
