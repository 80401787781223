import { useCallback, useRef } from 'react';

import {
  STEP,
  EPOCH
} from '@experiment-management-shared/constants/experimentConstants';
import { defaultFormFields } from '@experiment-management-shared/constants/chartConstants';
import { generatePanelId } from '@experiment-management-shared/utils/panel';

import useColumns from './useColumns';
import useFullColumnsData from './useFullColumnsData';

const SUGGESTED_CUSTOM_PANELS_DEFAULT_CONFIG = JSON.stringify({ w: 2, h: 2 });

const getSuggestedColumns = ({ suggestedTableColumns }) => {
  return suggestedTableColumns.map(
    ({ projectColumnId }) => projectColumnId.split('--')[0]
  );
};

const getSuggestedGroups = ({ suggestedGroupingColumns }) => {
  return suggestedGroupingColumns.map(
    ({ projectColumnId }) => projectColumnId.split('--')[0]
  );
};

const getSuggestedPanels = (
  { suggestedCharts = [], suggestedFeaturePanels = [] },
  { columns, baseChartId }
) => {
  const builtInPanels = suggestedCharts.map(
    ({ hasStep, projectColumnId, type }) => {
      const column = columns.find(col => col.id === projectColumnId);

      if (!column) return null;

      const { name: metricName } = column;

      const chartId = `${baseChartId.current}-${column.name}`;

      return {
        ...defaultFormFields[type],
        chartId,
        chartType: type,
        metricNames: [metricName],
        selectedXAxis: hasStep ? STEP : EPOCH,
        selectedYAxis: metricName
      };
    }
  );

  const customPanels = suggestedFeaturePanels.map(
    ({
      defaultConfig = SUGGESTED_CUSTOM_PANELS_DEFAULT_CONFIG,
      templateId
    }) => {
      const chartId = `${baseChartId.current}-${templateId}`;

      return {
        chartId,
        chartType: 'custom',
        defaultConfig,
        templateId
      };
    }
  );

  return [...customPanels, ...builtInPanels].filter(Boolean);
};

export default function useSuggestedViewFields() {
  const { data: columns } = useColumns();
  const baseChartId = useRef(generatePanelId());

  const getSuggestedViewFields = useCallback(
    suggestedFieldsData => {
      if (!columns) return {};

      return {
        columns: getSuggestedColumns(suggestedFieldsData),
        panels: getSuggestedPanels(suggestedFieldsData, {
          baseChartId,
          columns
        }),
        groups: getSuggestedGroups(suggestedFieldsData)
      };
    },
    [columns]
  );

  return useFullColumnsData(null, {
    select: getSuggestedViewFields
  });
}
