import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

import { PipelineDefinitionType } from '@mpm/types';

type CreateModelPipelineMutationProps = {
  pipelineData: {
    name: string;
    nodes: PipelineDefinitionType['nodes'];
    edges: PipelineDefinitionType['edges'];
    graphViewJson: string;
  };
};

type CloseModalType = () => void;

const createPipeline = ({
  workspaceId,
  pipelineData
}: CreateModelPipelineMutationProps & { workspaceId: string }) => {
  return api.post(`mpm/${workspaceId}/models/pipeline`, pipelineData);
};

export function useCreateModelPipeline(closeModal: CloseModalType) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const activeWorkspace = useActiveWorkspace();

  return useMutation(
    ({ pipelineData }: CreateModelPipelineMutationProps) =>
      createPipeline({
        workspaceId: activeWorkspace?.id,
        pipelineData
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'models',
          { workspaceId: activeWorkspace?.id }
        ]);
        closeModal();
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.SUCCESS_MODEL_PIPELINE_CREATED,
            'The pipeline was succesfully created.'
          )
        );
      },
      onError: (err: Error) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_PIPELINE_CREATED,
            err?.message
          )
        );
      }
    }
  );
}
