import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import moment from 'moment';

export const capitalizeFirstLetter = string => {
  if (string && string.length > 1) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }
  return string;
};

export const sortArrayOfObjectsByKey = (arr, key) => {
  return sortBy(arr, [key]);
};

export const sortArrayOfNumbersAscending = arr => {
  return arr.slice().sort((a, b) => a - b);
};

export const formatTimestampsToDate = timestamps => {
  return timestamps.map(timestamp => new Date(timestamp));
};

export const formatTimestampsToSeconds = timestamps => {
  return timestamps.map(timestamp => new Date(timestamp).getTime() / 1000);
};

export const formatTimestampForTable = timestamp => {
  return timestamp
    ? moment.unix(timestamp / 1000).format('MM/DD/YYYY HH:mm:ss A')
    : '-';
};

export const getNumOfDaysUntilTimestamp = timestamp => {
  if (!timestamp) return 0;

  const now = moment(new Date());
  const end = moment(timestamp);
  const duration = moment.duration(end.diff(now));
  const diff = Math.floor(duration.asDays());

  return diff && diff > -1 ? diff : 0;
};

export const trimPrefix = (str, prefix) => {
  if (!str || !prefix) {
    return str;
  }

  const indexOfPrefix = str.indexOf(prefix);
  if (str.includes(prefix) && indexOfPrefix === 0) {
    return str.substring(prefix.length, str.length);
  }

  return str;
};

export const trimSuffix = (str, suffix) => {
  if (!str || !suffix) {
    return str;
  }

  const startIndexOfSuffix = str.indexOf(suffix);
  if (startIndexOfSuffix !== -1) {
    return str.substring(0, str.indexOf(suffix));
  }

  return str;
};

export const getUniqValuesFromArrayOfObjects = (items, key) => {
  if (!items) return [];

  return items.reduce((uniqValues, item) => {
    if (!isNull(item[key]) && !uniqValues.includes(item[key])) {
      uniqValues.push(item[key]);
    }
    return uniqValues;
  }, []);
};

export const getUniqValuesFromArray = (items, key) => {
  if (!items) return [];

  const allValues = items.reduce((values, item) => {
    values.push(...get(item, key, []));
    return values;
  }, []);

  return uniq(allValues).sort();
};

export const isStringInSearchText = (string, searchText) => {
  return toLower(string).includes(toLower(searchText));
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0 || isNull(bytes)) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// https://github.com/Rombecchi/version-compare/blob/master/version-compare.js
export const compareSemanticVersions = (v1, v2, options) => {
  const lexicographical = (options && options.lexicographical) || false;

  const zeroExtend = (options && options.zeroExtend) || true;
  let v1parts = (v1 || '0').split('.');
  let v2parts = (v2 || '0').split('.');

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-zαß]*$/ : /^\d+[A-Za-zαß]?$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(function (x) {
      const match = /[A-Za-zαß]/.exec(x);
      return Number(
        // eslint-disable-next-line prefer-template
        match ? x.replace(match[0], '.' + x.charCodeAt(match.index)) : x
      );
    });
    v2parts = v2parts.map(function (x) {
      const match = /[A-Za-zαß]/.exec(x);
      return Number(
        // eslint-disable-next-line prefer-template
        match ? x.replace(match[0], '.' + x.charCodeAt(match.index)) : x
      );
    });
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1;
    }

    if (v1parts[i] == v2parts[i]) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
};

export const isIframed = () => window.location !== window.parent.location;

export const isValidHttpUrl = url => {
  let retVal = false;

  try {
    const newUrl = new URL(url);
    retVal = newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return retVal;
};
