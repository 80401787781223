import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';
import { useHistory, useParams } from 'react-router';
import queryString from 'query-string';

import { DSShowIcon, DSNotificationsIcon } from '@ds-icons';
import { CloseInspectTooltipIcon } from '@Icons-outdated';
import './ChartTooltip.scss';
import {
  ALLOWED_ALERT_CREATE_BUTTON_SECTIONS,
  FEATURE_TYPES,
  PERCENTILE_TYPE_MAP,
  PANEL_SECTIONS
} from '@mpm/constants';
import { getTooltipHeaderTitle, parseTooltipTemplate } from '@mpm/utils';

export const ChartTooltip = ({
  tooltipData,
  position,
  closeHandler,
  inspectMode,
  orientation,
  section,
  chartType,
  driftAlgorithm,
  hideInspection,
  featureId,
  tabData,
  showViewFeatureAction,
  feature,
  percentileMode
}) => {
  const history = useHistory();
  const { intervalType, version } = queryString.parse(history.location.search);
  const { modelId, workspace, featureId: pathFeatureId } = useParams();

  const queryFeatureId = featureId || pathFeatureId;
  const {
    x,
    y,
    title,
    color,
    hovertemplate,
    text,
    data,
    headerTitle
  } = tooltipData;
  const leftOrientation = orientation === 'left';

  const pointIdx = data?.pointIndex;
  const isNumericalFeature =
    (tabData?.valType || feature?.type?.toUpperCase()) ===
    FEATURE_TYPES.NUMERICAL;

  const xValuesList = tooltipData?.data?.data?.x;

  const showCreateAlertBtn =
    ALLOWED_ALERT_CREATE_BUTTON_SECTIONS.includes(section) ||
    (section === PANEL_SECTIONS.FEATURE_DISTRIBUTION && isNumericalFeature);

  const percentileType =
    PERCENTILE_TYPE_MAP[`${percentileMode}_${tooltipData.percentileType}`];

  const handleCreateAlert = () => {
    history.push(
      `/${workspace}/model-production-monitoring/${modelId}/alerts/add?section=${section}&intervalType=${intervalType}` +
        `&version=${version}&modelFeatureId=${queryFeatureId}&driftAlgorithmParam=${driftAlgorithm}&` +
        `percentileType=${percentileType}`
    );
  };

  const handleViewFeatureDetails = () => {
    history.push({
      pathname: `/${workspace}/model-production-monitoring/${modelId}/features/${featureId}`,
      search: location.search
    });
  };

  return (
    <span
      className={cx('version-tooltip-wrapper', {
        'left-oriented-tooltip-wrapper': leftOrientation
      })}
      style={{ ...position }}
    >
      <div
        className={cx('version-tooltip', {
          'left-oriented-version-tooltip': leftOrientation,
          'inspectMode-version-tooltip': inspectMode
        })}
      >
        {inspectMode && (
          <CloseInspectTooltipIcon
            className={cx('version-tooltip-close-icon', {
              'left-oriented-close-icon': leftOrientation
            })}
            onClick={closeHandler}
          />
        )}

        <div className="tooltip-header">
          {getTooltipHeaderTitle({
            headerTitle,
            xValue: x,
            xValuesList,
            intervalType,
            chartType,
            pointIdx
          })}
        </div>
        <div
          className={cx('inspect-message', {
            'without-inspection': hideInspection
          })}
        >
          {title && (
            <span className="tooltip-indicator-wrapper">
              <div
                className="color-indicator"
                style={{ backgroundColor: color }}
              />
              <span className="tooltip-indicator-title">{title}</span>
            </span>
          )}
          <>{parseTooltipTemplate(hovertemplate, text, y, data)}</>
        </div>
        {!inspectMode && !hideInspection && (
          <p className="tooltip-button-text">Click graph to inspect</p>
        )}
        {inspectMode && (showCreateAlertBtn || showViewFeatureAction) && (
          <div className="tooltip-actions-wrapper">
            {showCreateAlertBtn && (
              <div onClick={handleCreateAlert} className="tooltip-action">
                <div className="tooltip-icon-wrapper">
                  <DSNotificationsIcon className="tooltip-icon" />
                </div>
                <span>Create Alert</span>
              </div>
            )}
            {showViewFeatureAction && (
              <div
                onClick={handleViewFeatureDetails}
                className="tooltip-action"
              >
                <div className="tooltip-icon-wrapper">
                  <DSShowIcon className="tooltip-icon" />
                </div>
                <span>View Feature</span>
              </div>
            )}
          </div>
        )}
      </div>
    </span>
  );
};

ChartTooltip.defaultProps = {
  closeHandler: noop,
  hideInspection: false,
  showViewFeatureAction: false
};

ChartTooltip.propTypes = {
  tooltipData: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  closeHandler: PropTypes.func,
  inspectMode: PropTypes.bool.isRequired,
  orientation: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  hideInspection: PropTypes.bool,
  showViewFeatureAction: PropTypes.bool
};
