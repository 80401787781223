import useExperiments from '@API/experiments/useExperiments';
import useColumns from '@API/project/useColumns';
import useGroupAggregations from '@experiment-management-shared/api/useGroupAggregations';
import { useExperimentsGroups } from '@experiment-management-shared/hooks';
import { TextButton } from '@ds';
import SectionActions from '@reports/components/SectionActions';
import SectionPanels from '@reports/components/SectionPanels';
import SectionTable from '@reports/components/SectionTable';
import SectionText from '@reports/components/SectionText';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import cx from 'classnames';
import { intersection, isEmpty, pick, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ReportSection.module.scss';
import { DSArrowDownIcon, DSArrowRightIcon } from '@ds-icons';

const ReportSection = ({
  editMode,
  hasStartFocus,
  onChange,
  onDelete,
  onDuplicate,
  onMoveDown,
  onMoveUp,
  position,
  projectId,
  projectName,
  section,
  sectionsAmount
}) => {
  const refetchInterval = section.panels.isAutoRefreshEnabled ? 30000 : false;
  const { data: columns } = useColumns({ extraCols: true });

  const groups = useMemo(() => {
    const grouping = section.table.columnGrouping || [];
    const columnNames = columns?.map(column => column.name);

    return intersection(grouping, columnNames);
  }, [columns, section.table.columnGrouping]);

  const view = useMemo(
    () => ({
      query: pick(section.query, ['rulesTree']),
      table: pick(section.table, [
        'columnOrders',
        'columnSorting',
        'expandedGroups'
      ])
    }),
    [section.query, section.table]
  );

  const applyChangeExpandedGroups = useCallback(
    newExpandedGroups => {
      onChange({
        ...section,
        table: { ...section.table, expandedGroups: newExpandedGroups }
      });
    },
    [onChange, section]
  );

  const { totalGroups, experimentsGroupsMap } = useExperimentsGroups({
    columnGrouping: groups,
    isArchive: false,
    view,
    onExpand: applyChangeExpandedGroups,
    queryConfig: {
      refetchInterval,
      refetchOnMount: true
    }
  });

  const { data: groupAggregations } = useGroupAggregations(
    {
      groups,
      view
    },
    { refetchInterval, refetchOnMount: true }
  );

  const [pageNumber, setPageNumber] = useState(0);

  const experimentView = useMemo(() => {
    return {
      panels: {
        ...pick(section.panels, ['panels'])
      },
      query: pick(section.query, ['rulesTree']),
      table: {
        ...pick(section.table, [
          'columnOrders',
          'columnSorting',
          'expandedGroups',
          'pageSize'
        ]),
        pageNumber
      }
    };
  }, [pageNumber, section]);

  const {
    data: experimentsData,
    isIdle: isIdleExperiments,
    isLoading: isLoadingExperiments
  } = useExperiments(
    {
      groupsQuery: groups.length ? experimentsGroupsMap.path : undefined,
      targetExperimentKeys: isEmpty(section.table.frozenExperimentKeys)
        ? null
        : section.table.frozenExperimentKeys,
      view: experimentView
    },
    { refetchInterval, refetchOnMount: true }
  );
  const { data: pinnedExperimentData } = useExperiments(
    {
      ignorePagination: true,
      targetExperimentKeys: section.table.pinnedExperimentKeys || [],
      view: experimentView
    },
    {
      initialData: { experiments: [], total: 0 },
      refetchInterval,
      refetchOnMount: true
    }
  );

  const [isEditing, setIsEditing] = useState(hasStartFocus);

  const experiments = useMemo(() => {
    return uniqBy(
      [
        ...(pinnedExperimentData?.experiments || []).map(experiment => ({
          ...experiment,
          pinned: true
        })),
        ...(experimentsData?.experiments || [])
      ],
      'experimentKey'
    );
  }, [experimentsData?.experiments, pinnedExperimentData?.experiments]);

  const isExperimentsVisible = section.query.isVisible;
  const isReadOnly = editMode !== REPORT_EDIT_MODES.EDIT || !isEditing;

  useEffect(() => {
    if (editMode !== REPORT_EDIT_MODES.EDIT) {
      setIsEditing(false);
    }
  }, [editMode]);

  const handleChange = key => value => {
    onChange({
      ...section,
      [key]: value
    });
  };

  const handleExperimentsVisibilityToggle = () => {
    onChange({
      ...section,
      query: {
        ...section.query,
        isVisible: !isExperimentsVisible
      },
      table: {
        ...section.table,
        isVisible: !isExperimentsVisible
      }
    });
  };

  const handlePanelsVisibilityToggle = () => {
    onChange({
      ...section,
      panels: {
        ...section.panels,
        isVisible: !section.panels.isVisible
      }
    });
  };

  const handleQueryChange = query => {
    onChange({
      ...section,
      query,
      table: {
        ...section.table,
        isFrozen: false,
        frozenExperimentKeys: null
      }
    });
  };

  const hasPanels = !!section.panels.panels.length;
  const shouldDisplayPanels =
    !isReadOnly || (section.panels.isVisible && hasPanels);
  const shouldDisplayTable = !isReadOnly || section.table.isVisible;

  const sectionEditMode = isEditing
    ? REPORT_EDIT_MODES.EDIT
    : REPORT_EDIT_MODES.VIEW;

  if (isIdleExperiments || isLoadingExperiments) {
    return null;
  }

  return (
    <div
      className={cx(styles.section, {
        [styles.viewOnly]: !isEditing
      })}
      id={section.id}
    >
      <SectionActions
        editMode={editMode}
        id={section.id}
        isEditing={isEditing}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onEditingChange={setIsEditing}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        position={position}
        sectionsAmount={sectionsAmount}
      >
        <SectionText
          editMode={sectionEditMode}
          onChange={handleChange('topText')}
          text={section.topText}
        />

        {!isReadOnly && <div className={styles.divider} />}

        {sectionEditMode === REPORT_EDIT_MODES.EDIT && (
          <div>
            <TextButton
              size="large"
              type="secondary"
              PrefixIcon={
                section.panels.isVisible ? (
                  <DSArrowDownIcon />
                ) : (
                  <DSArrowRightIcon />
                )
              }
              onClick={handlePanelsVisibilityToggle}
            >
              Panels
            </TextButton>
          </div>
        )}

        {shouldDisplayPanels && (
          <SectionPanels
            experiments={experiments}
            hiddenExperimentKeys={section.hiddenExperimentKeys}
            onChange={handleChange('panels')}
            onChangeHiddenExperimentKeys={handleChange('hiddenExperimentKeys')}
            panels={section.panels}
            projectId={projectId}
            projectName={projectName}
          />
        )}

        {!isReadOnly && <div className={styles.divider} />}

        {sectionEditMode === REPORT_EDIT_MODES.EDIT && (
          <div>
            <TextButton
              size="large"
              PrefixIcon={
                isExperimentsVisible ? (
                  <DSArrowDownIcon />
                ) : (
                  <DSArrowRightIcon />
                )
              }
              type="secondary"
              onClick={handleExperimentsVisibilityToggle}
            >
              Experiments
            </TextButton>
          </div>
        )}

        {shouldDisplayTable && (
          <SectionTable
            availableColumns={columns}
            onQueryChange={handleQueryChange}
            query={section.query}
            editMode={sectionEditMode}
            onChange={handleChange('table')}
            onChangeHiddenExperimentKeys={handleChange('hiddenExperimentKeys')}
            experiments={experiments}
            experimentsGroupsMap={experimentsGroupsMap}
            groupAggregations={groupAggregations}
            hiddenExperimentKeys={section.hiddenExperimentKeys}
            isLoading={isLoadingExperiments}
            onCurrentPageChange={setPageNumber}
            pageNumber={pageNumber}
            pageTotalGrouped={totalGroups}
            table={section.table}
            totalExperiments={experimentsData?.total}
          />
        )}

        <SectionText
          editMode={sectionEditMode}
          onChange={handleChange('bottomText')}
          text={section.bottomText}
        />
      </SectionActions>
    </div>
  );
};

ReportSection.propTypes = {
  editMode: PropTypes.string.isRequired,
  hasStartFocus: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  sectionsAmount: PropTypes.number.isRequired
};

export default React.memo(ReportSection);
