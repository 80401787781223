import { useMutation, useQueryClient } from 'react-query';

import useProject from '../project/useProject';
import { axiosInstance, cancelWrapper } from '../api';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { useDispatch } from 'react-redux';
import projectsApi from '@/util/projectsApi';

const symlinkExperiments = ({ experiments, projectId }) =>
  projectsApi.addSymlinks({
    experimentKeys: experiments,
    projectId
  });

const oldSymlinkExperiments = ({ experiments, projectId }) => {
  return cancelWrapper(cancelToken => {
    const promises = experiments.map(experimentKey => {
      return axiosInstance({
        cancelToken,
        data: {
          experimentKey,
          projectId
        },
        method: 'post',
        url: 'projects/symlink'
      });
    });

    return Promise.all(promises);
  });
};

export default function useSymlinkExperimentsMutation(
  isMoveToOtherWSEnabled = false
) {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;
  const dispatch = useDispatch();

  return useMutation(
    ({ experiments, projectId: symlinkToProjectId }) =>
      isMoveToOtherWSEnabled
        ? symlinkExperiments({
            experiments,
            projectId: symlinkToProjectId
          })
        : oldSymlinkExperiments({
            experiments,
            projectId: symlinkToProjectId
          }),
    {
      onMutate: async ({ projectId: symlinkToProjectId }) => {
        await queryClient.cancelQueries(['experiments', { projectId }]);
        await queryClient.cancelQueries([
          'experiments',
          { projectId: symlinkToProjectId }
        ]);
      },
      onSuccess: async (_data, { projectId: symlinkToProjectId }) => {
        await queryClient.invalidateQueries(['experiments', { projectId }]);
        await queryClient.invalidateQueries([
          'experiments',
          { projectId: symlinkToProjectId }
        ]);
      },
      onError: err => {
        if (isMoveToOtherWSEnabled) {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.MOVE_PROJECT_ERROR,
              err?.response?.data?.msg || 'Unable to move project to workspace'
            )
          );
        }
      }
    }
  );
}
