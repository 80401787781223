// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfusionMatrixBar-module__confusionMatrixBar--tJNbQ{display:flex;align-items:center;height:115px;padding:0 var(--experiment-details-layout-padding)}.ConfusionMatrixBar-module__selectContainer--GOZ4V{margin-left:20px;width:180px;height:75px}.ConfusionMatrixBar-module__selectContainer--GOZ4V.ConfusionMatrixBar-module__cellSelect--LrdcH{width:180px}.ConfusionMatrixBar-module__selectContainer--GOZ4V.ConfusionMatrixBar-module__distributionSelect--ToIKJ{width:136px}.ConfusionMatrixBar-module__selectContainer--GOZ4V .select-label{font-weight:500;font-size:14px;color:var(--gray-6)}.ConfusionMatrixBar-module__infoIconContainer--W0DpY{color:var(--gray-6);cursor:pointer;margin-left:4px}.ConfusionMatrixBar-module__infoIconContainer--W0DpY svg{margin-bottom:-0.5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confusionMatrixBar": `ConfusionMatrixBar-module__confusionMatrixBar--tJNbQ`,
	"selectContainer": `ConfusionMatrixBar-module__selectContainer--GOZ4V`,
	"cellSelect": `ConfusionMatrixBar-module__cellSelect--LrdcH`,
	"distributionSelect": `ConfusionMatrixBar-module__distributionSelect--ToIKJ`,
	"infoIconContainer": `ConfusionMatrixBar-module__infoIconContainer--W0DpY`
};
module.exports = ___CSS_LOADER_EXPORT___;
