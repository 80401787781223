import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getUserUniqueName } from '@shared/utils/permissions';
import ManageUsersRoleCellText from './ManageUsersRoleCellText';
import classNames from './ManageUsersModalPopover.module.scss';

const ManageUsersRoleCell = ({
  row,
  setPopoverPosition,
  setPopoverData,
  popoverData
}) => {
  const refEl = useRef(null);
  const userPermissions = row?.permissions;
  const rowUserName = getUserUniqueName(row);
  const ifInvitedByEmail = !row.isMember;
  const userNameInActivePopover = useMemo(
    () => getUserUniqueName(popoverData),
    [popoverData]
  );

  const clickableElement = useMemo(
    () => (
      <span
        onClick={() => {
          const elPosition = refEl?.current.getBoundingClientRect() || {};
          setPopoverPosition(elPosition);
          setPopoverData(row);
        }}
        className={cx(
          classNames.manageUsersModalMemberPermissions,
          userNameInActivePopover === rowUserName &&
            classNames.manageUsersModalMemberPermissionsActive,
          ifInvitedByEmail &&
            classNames.manageUsersModalMemberPermissionsDisabled
        )}
      >
        <span ref={refEl}>
          <ManageUsersRoleCellText userPermissions={userPermissions} />
        </span>
        <KeyboardArrowDownIcon
          className={classNames.manageUsersModalMemberArrow}
        />
      </span>
    ),
    [ifInvitedByEmail, userPermissions]
  );

  return (
    <>
      {ifInvitedByEmail ? (
        <Tooltip
          arrow
          placement="left"
          title="Cannot change roles for users invited by email"
        >
          <span>{clickableElement}</span>
        </Tooltip>
      ) : (
        clickableElement
      )}
    </>
  );
};

ManageUsersRoleCell.defaultProps = {
  popoverData: null
};

ManageUsersRoleCell.propTypes = {
  row: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        permissionName: PropTypes.string,
        permissionValue: PropTypes.string
      })
    ),
    isMember: PropTypes.bool,
    isAdmin: PropTypes.bool,
    username: PropTypes.string
  }).isRequired,
  popoverData: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        permissionName: PropTypes.string,
        permissionValue: PropTypes.string
      })
    ),
    isMember: PropTypes.bool,
    isAdmin: PropTypes.bool,
    username: PropTypes.string
  }),
  setPopoverPosition: PropTypes.func.isRequired,
  setPopoverData: PropTypes.func.isRequired
};

export default ManageUsersRoleCell;
