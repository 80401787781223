import { useQuery } from 'react-query';
import { prepareRulesTreeForRequest } from '@shared/utils/filterHelpers';
import useFiltersLibraryManagement from '@experiment-management-shared/hooks/useFiltersLibraryManagement';
import { axiosInstance, cancelWrapper } from '../api';
import useProject from '../project/useProject';

const getExperimentsForCustomPanels = ({ rulesTree, projectId }) => {
  return cancelWrapper(async cancelToken => {
    // the preparation is done here because as a part of preparation we are doing
    // converting from relative data to absolute that invalidate queryParams every
    // time and course to unnecessary request to BE
    const preparedRulesTree = prepareRulesTreeForRequest(rulesTree);

    const {
      data: { experiments }
    } = await axiosInstance({
      cancelToken,
      data: {
        deleted: false,
        limit: 50,
        rulesTree: preparedRulesTree,
        multipleSort: [],
        projectId,
        page: 1
      },
      method: 'post',
      url: 'query/experiments/search'
    });

    return experiments.map(experiment => experiment.experimentKey);
  });
};

export default function useExperimentsForCustomPanels(
  { projectId, queryBuilderId },
  config
) {
  const { data: project, isLoading: isLoadingProject } = useProject();
  const { isLoading, activeFilter } = useFiltersLibraryManagement({
    filterId: queryBuilderId
  });

  const queryParams = {
    rulesTree: activeFilter?.rulesTree,
    projectId: projectId ?? project?.projectId
  };

  return useQuery(
    ['panelsExperiments', queryParams],
    () => getExperimentsForCustomPanels(queryParams),
    {
      enabled: !!queryBuilderId && !isLoading && !isLoadingProject,
      keepPreviousData: true,
      ...config
    }
  );
}
