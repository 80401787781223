import moment from 'moment';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import userActions from '@/actions/userActions';
import userApi from '@/util/userApi';
import { uncacheImage } from '@account-settings/utils';
import usePathname from '@shared/hooks/usePathname';
import { ServerUser, User } from '@shared/types';
import { getWorkspaceName } from '@shared/utils/url';

const mapUser = (serverUser: ServerUser): User => ({
  ...serverUser,
  username: serverUser?.userName,
  email: serverUser?.email,
  profileImage: serverUser?.profileImages?.large
    ? uncacheImage(serverUser?.profileImages?.large)
    : '',
  isLoggedInWithGithub: serverUser?.gitHub,
  apiKey: (serverUser?.apiKeys?.length && serverUser.apiKeys[0]) || '',
  workspaces: (serverUser?.getTeams?.teams || []).map(team => ({
    id: team?.teamId,
    projectsCount: team?.project_count,
    name: team?.teamName,
    createdBy: team?.createdBy,
    membersCount: team?.totalMembers,
    createdAt: moment(team?.createdAt),
    isDefault: team?.default_team,
    collaborationFeaturesDisabled: team?.collaborationFeaturesDisabled
  })),
  organizations: serverUser?.organizations,
  shownGetStarted: serverUser?.shownGetStartedFlag,
  hideHelpCenter: serverUser?.hideHelpCenterFlag,
  hasExperiments: serverUser?.hasExperiments,
  shownPostSignupForm: serverUser?.showPostSignupForm,
  loading: false
});

const getCurrentUser = async (workspaceName: string): Promise<User> => {
  const user = await userApi.fetchUser({ workspaceName, apiKey: undefined });

  return mapUser(user.data);
};

function useWorkspaceName() {
  // in order to re-trigger on change the pathname
  usePathname();

  return getWorkspaceName();
}

export default function useCurrentUser(params = {}) {
  const dispatch = useDispatch();
  const workspaceName = useWorkspaceName();
  const { workspaceName: workspaceNameInQuery = '' } = queryString.parse(
    window.location.search
  );

  return useQuery(
    ['current-user', workspaceName],
    () =>
      getCurrentUser(workspaceName || (workspaceNameInQuery as string) || ''),
    {
      ...params,
      onSuccess: data => {
        dispatch(userActions.receiveUser(mapUser(data)));
      }
    }
  );
}
