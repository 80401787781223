import cx from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import styles from './Pill.module.scss';

const HEX_OPACITY_PREFIX = '4C';

type PillProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  color: string;
  text: string;
  clickable: boolean;
  disabled: boolean;
  fontSize: string;
  animation: boolean;
};
export function Pill({
  text,
  color,
  disabled,
  style,
  className,
  fontSize,
  clickable,
  animation,
  ...rest
}: PillProps) {
  return (
    <div
      style={{
        backgroundColor: disabled ? color + HEX_OPACITY_PREFIX : color,
        ...style
      }}
      className={cx(
        styles.pill,
        { [styles.clickable]: clickable },
        { [styles.animation]: animation },
        className
      )}
      {...rest}
    >
      <p style={{ fontSize }}>{text}</p>
    </div>
  );
}

Pill.defaultProps = {
  clickable: false,
  disabled: false,
  fontSize: '12px',
  className: null,
  animation: false,
  style: {}
};

Pill.propTypes = {
  color: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  className: PropTypes.string,
  animation: PropTypes.bool,
  style: PropTypes.object
};
