// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-module__tag--Sk6Tc{--color: transparent;background-color:var(--color);color:#fff;height:23.5px;border-radius:20px;display:flex;padding:0 4px 0 8px;align-items:center;max-width:260px}.Tag-module__tag--Sk6Tc:hover .Tag-module__deleteIcon--dqqVQ{width:17px}.Tag-module__tag--Sk6Tc .Tag-module__deleteIcon--dqqVQ{width:0;height:17px;transition:.25s ease-in-out;padding:0}.Tag-module__tag--Sk6Tc:active{box-shadow:none;background-color:var(--color);border-color:var(--color)}.Tag-module__tag--Sk6Tc path{fill:#fff}.Tag-module__tag--Sk6Tc circle{fill:rgba(255,255,255,.6)}.Tag-module__label--q76Y3{font-size:12px;line-height:1;margin-right:4px;white-space:nowrap}.column-tag-list.tag-row-container{white-space:nowrap;position:static}.column-tag-list.tag-row-container>span{flex-shrink:0}.column-tag-list.tag-row-container .Tag-module__tag--Sk6Tc{max-width:100%}.remaining-tag-list .Tag-module__tag--Sk6Tc{max-width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag-module__tag--Sk6Tc`,
	"deleteIcon": `Tag-module__deleteIcon--dqqVQ`,
	"label": `Tag-module__label--q76Y3`
};
module.exports = ___CSS_LOADER_EXPORT___;
