import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { IconButton } from '@ds';
import Menu from '@material-ui/core/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import StyledTooltip from '@shared/components/StyledComponents/StyledTooltip';
import StyledMenuItem from '@shared/components/StyledComponents/StyledMenuItem';
import MarkdownIFrame from '@shared/components/MarkdownIFrame';
import ArtifactDetails from '@experiment-management-shared/components/ArtifactDetails';
import { COLORS } from '@/constants/colorConstants';
import ArtifactTags from '@experiment-management-shared/components/ArtifactTags';
import EditArtifactModalBtn from '@artifacts/components/EditArtifactModalBtn';
import artifactActions from '@/actions/artifactActions';
import { SUB_PATHS } from '@/constants/urlConstants';
import { Link } from 'react-router-dom';
import { DSMoreOptionsVerticalIcon } from '@ds-icons';

const ArtifactRow = ({ artifact, workspaceName, isUserAMemberOfWorkspace }) => {
  const {
    artifactId,
    description,
    emoji,
    isPublic,
    name,
    tags,
    type,
    versionsCount
  } = artifact;

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = event => {
    if (event) {
      event.preventDefault();
    }
    setAnchorEl(null);
  };

  const handleDeleteArtifact = event => {
    if (event) {
      event.preventDefault();
    }

    dispatch(artifactActions.openDeleteArtifactModal(artifactId));

    handleCloseMenu();
  };

  const renderArtifactMenu = () => {
    return (
      <>
        <IconButton
          className="row-menu-button"
          onClick={handleOpenMenu}
          type="secondary"
          Icon={<DSMoreOptionsVerticalIcon />}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <EditArtifactModalBtn
            artifact={artifact}
            isMenuButton
            closeHandler={handleCloseMenu}
            disabled={!isUserAMemberOfWorkspace}
          />
          <StyledMenuItem
            Icon={DeleteIcon}
            text="Delete"
            onClick={handleDeleteArtifact}
            disabled={!isUserAMemberOfWorkspace}
          />
        </Menu>
      </>
    );
  };

  const artifactURL = `/${workspaceName}/${
    SUB_PATHS.ARTIFACTS
  }/${encodeURIComponent(name)}`;

  return (
    <Link
      key={artifactId}
      to={artifactURL}
      className="artifact-row-container artifact-row"
    >
      <div className="row-left">
        <div className="row-emoji">{emoji}</div>
        <ArtifactDetails
          name={name}
          metadata={[
            { label: 'isPublic', value: isPublic },
            { label: 'Type', value: type },
            { label: 'ID', value: artifactId.slice(0, 8) }
          ]}
        >
          {!isEmpty(description) && (
            <StyledTooltip
              title={<MarkdownIFrame textString={description} />}
              placement="bottom-start"
              interactive
              minWidth={500}
            >
              <DescriptionIcon
                style={{
                  fontSize: 18,
                  color: COLORS.primary,
                  marginLeft: 5
                }}
              />
            </StyledTooltip>
          )}
        </ArtifactDetails>
      </div>

      <div className="row-right">
        <div className="artifact-row-version-tags">
          <div className="row-versions">{versionsCount} versions</div>
          <ArtifactTags tagNames={tags} />
        </div>
        {renderArtifactMenu()}
      </div>
    </Link>
  );
};

ArtifactRow.propTypes = {
  artifact: PropTypes.object.isRequired,
  isUserAMemberOfWorkspace: PropTypes.bool.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default ArtifactRow;
