import { betaFeaturesActionTypes } from '../constants/actionTypes';

const betaFeaturesActions = {
  setFeatures: features => ({
    type: betaFeaturesActionTypes.SET_FEATURE_TOGGLES,
    payload: {
      features
    }
  })
};

export default betaFeaturesActions;
