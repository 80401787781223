import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ReactPlayer from 'react-player';

import { Box } from '@material-ui/core';
import SmallLoader from '@shared/components/SmallLoader';

import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import { BASE_API_URL } from '@/lib/appConstants';
import { trackEvent } from '@shared/utils/eventTrack';
import { panelEvents } from '@/constants/trackingEventTypes';
import useCurrentUser from '@API/auth/useCurrentUser';
import useProject from '@API/project/useProject';
import { useCurrentOrganization } from '@shared/hooks';
import useCurrentPanelSource from '@/helpers/custom-hooks/useCurrentPanelSource';
import { useActiveWorkspace } from '@shared/hooks';

import { isGraphicGif } from '@experiment-management-shared/utils/graphics';

const type = 'video';

const VideoPreview = ({
  fileName,
  artifactVersionId,
  assetId,
  experimentKey
}) => {
  const vidRef = useRef(null);
  const { data: currentUser } = useCurrentUser();
  const { data: project } = useProject();
  const currentOrganization = useCurrentOrganization();
  const currentSource = useCurrentPanelSource();
  const currentWorkspace = useActiveWorkspace();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFinishLoad = () => {
    setHasLoaded(true);
  };

  const handleError = () => {
    setHasLoaded(true);
    setIsError(true);
  };

  const isGIF = isGraphicGif(fileName);
  const baseURL = `${BASE_API_URL}asset/download`;
  const searchQuery = queryString.stringify({
    assetId,
    experimentKey,
    artifactVersionId
  });

  // this is to send a BE event when the video unmounts
  // we check if it was playing, then we will send an event if time > 0
  useEffect(() => {
    return () => {
      const timePlayed = vidRef?.current?.getCurrentTime();
      if (timePlayed) {
        trackEvent(panelEvents.VIDEO_PLAYED, {
          organization_id: currentOrganization?.id,
          organization_name: currentOrganization?.name,
          workspace_id: currentWorkspace?.id,
          workspace_name: currentWorkspace?.name,
          project_id: project?.projectId,
          project_name: project?.projectName,
          panel_location: currentSource,
          panel_type: 'video',
          total_time_watched: timePlayed,
          user_id: currentUser?.username
        });
      }
    };
  }, [vidRef]);

  if (isError) {
    return <EmptyPreview message={`No ${type} preview available.`} />;
  }

  if (isGIF) {
    return (
      <>
        {!hasLoaded && (
          <SmallLoader
            primaryMessage="Loading..."
            secondaryMessage={`Fetching ${type} data`}
          />
        )}
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
        >
          <img
            onLoad={handleFinishLoad}
            onError={handleError}
            src={`${baseURL}?${searchQuery}`}
            alt={fileName}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      {!hasLoaded && (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={`Fetching ${type} data`}
        />
      )}
      <ReactPlayer
        ref={vidRef}
        url={`${baseURL}?${searchQuery}`}
        width="100%"
        onReady={handleFinishLoad}
        onError={handleError}
        controls
      />
    </>
  );
};

VideoPreview.defaultProps = {
  artifactVersionId: null
};

VideoPreview.propTypes = {
  artifactVersionId: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired
};

export default VideoPreview;
