import { DOCS_ROOT_URL } from '@/constants/configConstants';
import React from 'react';

type EmptyExperimentLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  path: string;
};
const EmptyExperimentLink: React.FC<EmptyExperimentLinkProps> = ({
  path,
  ...props
}) => {
  return (
    <a
      {...props}
      href={`${DOCS_ROOT_URL}/${path}`}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};

export default EmptyExperimentLink;
