import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { reverseGroupedHistory } from '@model-registry/utils';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes } from '../../../constants/alertTypes';

const getModelRegistryItemHistory = async registryModelItemId =>
  modelRegistryApi
    .fetchModelVersionHistory(registryModelItemId)
    .then(data => reverseGroupedHistory(data?.data || {}));

const useModelRegistryVersionHistory = registryModelItemId => {
  const dispatch = useDispatch();

  return useQuery(
    ['modelRegistryVersionHistory', { registryModelItemId }],
    () => getModelRegistryItemHistory(registryModelItemId),
    {
      enabled: !!registryModelItemId,
      refetchOnMount: true,
      onError: () =>
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your model history.'
          )
        )
    }
  );
};

export default useModelRegistryVersionHistory;
