import { useEffect, useState } from 'react';

type UseLazyImageOpts = {
  placeholderSrc?: string;
  originalSrc: string;
  onLoad?: (e: Event) => void;
};
const useLazyImage = ({
  placeholderSrc = '',
  originalSrc,
  onLoad
}: UseLazyImageOpts) => {
  const [src, setSrc] = useState(placeholderSrc ?? originalSrc);

  useEffect(() => {
    if (placeholderSrc && placeholderSrc !== src) {
      setSrc(placeholderSrc);
    }

    if (src === originalSrc) return;

    const img = new Image();

    img.src = originalSrc;

    img.onload = function (e) {
      setSrc(originalSrc);

      if (onLoad) {
        onLoad(e);
      }

      img.remove();
    };

    return () => {
      img.src = '';
      img.remove();
    };
  }, [originalSrc, placeholderSrc]);

  const isLoading = src !== originalSrc;

  return { src, isLoading };
};

export default useLazyImage;
