import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

const ManageModelDescription = ({
  handleDescriptionChange,
  modelDescription
}) => {
  return (
    <div className="generic-modal-section">
      <InputLabel className="modal-input-label">Description</InputLabel>
      <InputBase
        className="modal-input-base"
        onChange={handleDescriptionChange}
        value={modelDescription}
        placeholder="Enter description"
        minRows={3}
        maxRows={8}
        multiline
      />
    </div>
  );
};

ManageModelDescription.propTypes = {
  handleDescriptionChange: PropTypes.func.isRequired,
  modelDescription: PropTypes.string.isRequired
};

export default ManageModelDescription;
