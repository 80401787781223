import { notesActionTypes } from '@/constants/actionTypes';
import notesSelectors from '../selectors/notesSelectors';

const initialState = {
  lastSave: null
};

const notesReducer = (state = initialState, action) => {
  if (action.type === notesActionTypes.RECEIVE_PROJECT_NOTE) {
    const { payload } = action;
    const newNotesState = notesSelectors.appendNewNoteDataToExistingNotes(
      state,
      payload,
      'projects'
    );

    return {
      ...state,
      ...newNotesState
    };
  }

  if (action.type === notesActionTypes.CLEAN_NOTES) {
    const newNotesState = notesSelectors.cleanNotes(state, action);

    return {
      ...state,
      ...newNotesState
    };
  }

  if (action.type === notesActionTypes.SET_LAST_SAVE) {
    const { lastSave } = action.payload;

    return {
      ...state,
      lastSave
    };
  }

  return state;
};

export default notesReducer;
