import { useMutation, useQueryClient } from 'react-query';

import without from 'lodash/without';

import api from '../api';

import useProject from './useProject';

const removeProjectTag = ({ projectId, tagName }) => {
  return api.post('tags/delete-tag-from-project', { projectId, tagName });
};

export default function useRemoveProjectTagMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ tagName }) => removeProjectTag({ projectId, tagName }),
    {
      onMutate: async ({ tagName }) => {
        await queryClient.cancelQueries(['experiments', { projectId }]);
        await queryClient.cancelQueries(['projectTags', { projectId }]);

        queryClient.setQueryData(['projectTags', { projectId }], oldTags => {
          return oldTags?.filter(tag => tag.name !== tagName);
        });

        queryClient
          .getQueryCache()
          .findAll(['experiments', { projectId }])
          .forEach(({ queryKey }) => {
            queryClient.setQueryData(queryKey, experimentsData => {
              // In case of refetching experiments the data could be undefined
              if (!experimentsData) {
                return experimentsData;
              }

              const experiments = experimentsData.experiments.map(
                experiment => {
                  if (!experiment.tags.includes(tagName)) return experiment;

                  return {
                    ...experiment,
                    tags: without(experiment.tags, tagName)
                  };
                }
              );

              return { ...experimentsData, experiments };
            });
          });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['experiments', { projectId }]);
        queryClient.invalidateQueries(['experimentGroups', { projectId }]);
        queryClient.invalidateQueries(['projectTags', { projectId }]);
      }
    }
  );
}
