import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import noop from 'lodash/noop';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import GenericModal from '@shared/components/GenericModal';
import alertsActions from '@/actions/alertsActions';

const AlertWarning = ({ content, dispatch, modalId, title, callback }) => {
  const onClose = () => {
    dispatch(alertsActions.closeDialog(modalId));

    callback();
  };

  return (
    <GenericModal title={title} buttonText="Continue" onClose={onClose}>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="generic-modal-body"
        >
          <span className="generic-modal-group">
            <i className="material-icons warning-icon">warning</i> {content}
          </span>
        </DialogContentText>
      </DialogContent>
    </GenericModal>
  );
};

AlertWarning.defaultProps = {
  callback: noop,
  content: 'An unknown error occured.',
  title: 'Error Found'
};

AlertWarning.propTypes = {
  callback: PropTypes.func,
  content: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default connect()(AlertWarning);
