import React from 'react';
import { Box } from '@material-ui/core';

import { Table } from '@DesignSystem/tables';
import SmallLoader from '@shared/components/SmallLoader';
import { useMPMhomeProps } from '@mpm/hooks';
import ModelsHeader from './ModelsHeader';

export const ModelProductionMonitoringHome = () => {
  const {
    tableProps,
    isLoading,
    isError,
    handleFilterQueryChange,
    query
  } = useMPMhomeProps();

  if (isLoading)
    return (
      <div id="table">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={<span>Fetching models</span>}
        />
      </div>
    );
  if (isError) return <h1>Error while fetching the models.</h1>;

  return (
    <Box display="flex" flexDirection="column" bgcolor="white">
      <ModelsHeader
        setActiveTabIndex={tableProps.setActiveTabIndex}
        activeTabIndex={tableProps.activeTabIndex}
        columns={tableProps.columns}
        columnSorting={tableProps.sortingConfig.columnSorting}
        selection={tableProps.selectionConfig.selection}
        onSortingChange={tableProps.sortingConfig.onSortingChange}
        onFilterChange={handleFilterQueryChange}
        query={query}
      />
      <Table {...tableProps} />
    </Box>
  );
};

ModelProductionMonitoringHome.defaultProps = {};
