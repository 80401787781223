import React from 'react';
import PropTypes from 'prop-types';
import { CreditCardForm } from '@DesignSystem/forms';
import { SafeIcon, InfoIcon } from '@Icons-outdated';
import currency from 'currency.js';
import { Divider, StyledTooltip } from '@DesignSystem/data-display';
import { PAYMENT_PLANS } from '@shared/constants';
import { useActiveOrganizationPaymentDetails } from '@account-settings/api';

const PurchasePlan = ({
  address,
  city,
  country,
  onChangeAddress,
  onChangeCity,
  onChangeCountry,
  onChangePostalCode,
  onChangeState,
  postalCode,
  selectedPlan,
  state
}) => {
  const { data: paymentDetails } = useActiveOrganizationPaymentDetails();

  const paymentPlan = PAYMENT_PLANS[selectedPlan];
  const membersCount = paymentDetails?.allMembersCount;
  const total = membersCount * paymentPlan?.price;

  return (
    <div className="purchase-plan-container">
      <div className="credit-card-container">
        <CreditCardForm
          address={address}
          onChangeAddress={onChangeAddress}
          city={city}
          onChangeCity={onChangeCity}
          state={state}
          onChangeState={onChangeState}
          country={country}
          onChangeCountry={onChangeCountry}
          postalCode={postalCode}
          onChangePostalCode={onChangePostalCode}
        />
      </div>

      <div className="purchase-description-container">
        <div className="safe-label">
          <SafeIcon />
          <span>Safe & secured payment</span>
        </div>

        <div className="purchase-summary-container">
          <div className="purchase-summary-row">
            <div>Number of users</div>
            <div>{membersCount}</div>
          </div>
          <div className="purchase-summary-row">
            <div>Monthly fee per user</div>
            <div>{paymentPlan?.priceLabel}</div>
          </div>
          <div className="purchase-summary-row-description">
            <div>{paymentPlan?.label}</div>
          </div>

          <Divider orientation="horizontal" margin={32} />
          <div className="purchase-summary-row total-row">
            <div>
              Total / month
              <StyledTooltip
                title="Monthly fee per user + additional training hours (over 5000)"
                placement="top"
              >
                <div>
                  <InfoIcon />
                </div>
              </StyledTooltip>
            </div>
            <div>Starts at {currency(total).format(true)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

PurchasePlan.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  onChangeCity: PropTypes.func.isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  onChangePostalCode: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired,
  postalCode: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

export default PurchasePlan;
