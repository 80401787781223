import { flatten, uniqBy } from 'lodash';
import useExperimentParams, {
  ExperimentParamsResponse,
  UseExperimentParamsConfig
} from './useExperimentParams';
import { HyperParam } from '@shared/types/hyperParam';

const getUniqueNames = (responses: ExperimentParamsResponse[]) => {
  return uniqBy(
    flatten(responses.map(response => response?.data?.results)),
    param => param.name
  );
};

const useExperimentUniqueParams = (
  config: UseExperimentParamsConfig<HyperParam[]> = {}
) => {
  return useExperimentParams({
    ...config,
    select: getUniqueNames
  });
};

export default useExperimentUniqueParams;
