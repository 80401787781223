/* eslint-disable import/prefer-default-export */

import { THUMBNAIL_ASPECT_RATIO } from '@experiment-management-shared/constants/chartsGallery';

const MAX_QUALITY = 1;

export const getCenteredSquareCrop = image => {
  const min = Math.min(image.width, image.height);
  const x = (image.width - min) / 2;
  const y = (image.height - min) / 2;

  return {
    unit: 'px',
    aspect: 1,
    width: min,
    height: min,
    x,
    y
  };
};

export const getCropToAspectRatio = image => {
  const min = Math.min(image.width, image.height);
  const width = min * THUMBNAIL_ASPECT_RATIO;
  const height = min;
  const x = (image.width - width) / 2;
  const y = (image.height - min) / 2;

  return {
    aspect: THUMBNAIL_ASPECT_RATIO,
    x,
    y,
    width,
    height,
    unit: 'px'
  };
};

export const getCenteredSquareImage = (image, fileName = 'file.jpg') => {
  const crop = getCenteredSquareCrop(image);

  return getCroppedImage(image, crop, fileName);
};

export const getCroppedImage = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        blob.name = fileName;
        resolve(blob);
      },
      'image/jpeg',
      MAX_QUALITY
    );
  });
};
