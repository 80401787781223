import { useQuery } from 'react-query';

import api from '@API/api';
import { QueryConfig } from '@shared/api';

type PredicatesParams = {
  predicatesBatchId: string | null;
};

type PredictesResposne = {
  predicates: string[];
};

const getPredicates = (predicatesBatchId: string | null) =>
  api.get(`/mpm/v2/predicates/${predicatesBatchId}`);

export function usePredicates(
  params: PredicatesParams,
  config: QueryConfig<PredictesResposne>
) {
  const { predicatesBatchId } = params;
  return useQuery(
    ['mpm-druid-predicates', { predicatesBatchId }],
    () => getPredicates(predicatesBatchId),
    {
      ...config
    }
  );
}
