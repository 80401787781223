import axios from 'axios';
import { HISTORY_GROUP_BY_OPTIONS } from '@model-registry/constants';
import { BASE_API_URL } from '../lib/appConstants';

const modelRegistryApi = {
  fetchRegisteredModel(workspaceName, modelName) {
    const url = `${BASE_API_URL}registry/model/details?workspaceName=${workspaceName}&modelName=${modelName}`;
    return axios.get(url);
  },

  fetchModelRegistryList(workspaceName) {
    const url = `${BASE_API_URL}registry/model/workspace?workspaceName=${workspaceName}`;
    return axios.get(url);
  },

  fetchModelRegistryCount(workspaceName) {
    const url = `${BASE_API_URL}registry/model/count?workspaceName=${workspaceName}`;
    return axios.get(url);
  },

  fetchModelRegistryView(registryModelId) {
    const url = `${BASE_API_URL}registry/${registryModelId}/view`;
    return axios.get(url);
  },

  updateModelRegistryView(registryModelId, columns) {
    const url = `${BASE_API_URL}registry/${registryModelId}/view`;
    return axios.post(url, {
      registryModelId,
      columnsState: JSON.stringify(columns)
    });
  },

  fetchModelRegistryColumnNames(registryModelId) {
    const url = `${BASE_API_URL}registry/${registryModelId}/column-names`;
    return axios.get(url);
  },

  getAllWorkspaceModels(workspaceName, signal) {
    const url = `${BASE_API_URL}registry/model?workspaceName=${workspaceName}`;
    return axios.get(url, { signal });
  },

  updateModel(model) {
    const url = `${BASE_API_URL}registry/model`;
    return axios.put(url, JSON.stringify(model));
  },

  moveModelVersion({
    targetRegistryModelId,
    registryModelId,
    registryModelItemId,
    comment
  }) {
    const url = `${BASE_API_URL}registry/${registryModelId}/model-items/${registryModelItemId}/modelAssignment`;
    return axios.post(url, JSON.stringify({ targetRegistryModelId, comment }));
  },

  updateModelItem(modelItem) {
    const url = `${BASE_API_URL}registry/model/item`;
    return axios.put(url, JSON.stringify(modelItem));
  },

  changeModelItemStatus({
    registryModelItemId: modelItemId,
    status,
    comment,
    isReviewRequired
  }) {
    const url = `${BASE_API_URL}registry/model/item/change-status`;
    return axios.post(
      url,
      JSON.stringify({
        status,
        comment,
        modelItemId,
        isReviewRequired: isReviewRequired || undefined
      })
    );
  },

  updateModelItemStatus({ pendingRequestId, status, comment }) {
    const url = `${BASE_API_URL}registry/model/item/manage-change-status`;
    return axios.post(
      url,
      JSON.stringify({ status, comment, pendingRequestId })
    );
  },

  fetchModelItemLineage({ registryModelItemId, workspace }) {
    const url = `${BASE_API_URL}registry/model/item/lineage?registryModelItemId=${registryModelItemId}&workspace=${workspace}`;
    return axios.get(url);
  },

  deleteModel(workspaceName, modelName) {
    const url = `${BASE_API_URL}registry/model?workspaceName=${workspaceName}&modelName=${modelName}`;
    return axios.delete(url);
  },

  deleteModelRegistryItem(modelItemId) {
    const url = `${BASE_API_URL}registry/model/item?modelItemId=${modelItemId}`;
    return axios.delete(url);
  },

  fetchModelNotes(workspaceName, modelName) {
    const url = `${BASE_API_URL}registry/model/notes?workspaceName=${workspaceName}&modelName=${modelName}`;
    return axios.get(url);
  },

  fetchModelPendingRequests({ workspace, modelName, page, limit, order }) {
    const url = `${BASE_API_URL}registry/model/pending-requests?workspaceName=${workspace}&modelName=${modelName}&limit=${limit}&order=${order}&page=${page}`;
    return axios.get(url);
  },

  fetchModelVersionNotes(registryModelItemId) {
    const url = `${BASE_API_URL}registry/model/notes/item/${registryModelItemId}`;
    return axios.get(url);
  },

  fetchModelItemsDetails(registryModelId, columns) {
    const url = `${BASE_API_URL}registry/${registryModelId}/model-items/details`;
    return axios.post(url, { columns });
  },

  fetchModelVersionHistory(registryModelItemId) {
    const url = `${BASE_API_URL}registry/model/history/item`;
    return axios.post(url, {
      registryModelItemId,
      groupedBy: HISTORY_GROUP_BY_OPTIONS.day
    });
  },

  saveModelNotes(notes) {
    const url = `${BASE_API_URL}registry/model/notes`;
    return axios.put(url, JSON.stringify(notes));
  },

  saveModelVersionNotes({ registryModelItemId, notes }) {
    const url = `${BASE_API_URL}registry/model/notes/item/${registryModelItemId}`;
    return axios.put(url, {
      note: notes
    });
  },

  createNewModel(newModel) {
    const url = `${BASE_API_URL}registry/model`;
    return axios.post(url, JSON.stringify(newModel));
  },

  createNewModelItem(newModelItem) {
    const url = `${BASE_API_URL}registry/model/item`;
    return axios.post(url, JSON.stringify(newModelItem));
  },

  validateModelName(workspaceName, modelName) {
    const url = `${BASE_API_URL}registry/model/validate-name`;
    return axios.post(url, JSON.stringify({ workspaceName, modelName }));
  },

  validateModelVersion(versionToValidate) {
    const url = `${BASE_API_URL}registry/model/validate-version`;
    return axios.post(url, JSON.stringify(versionToValidate));
  },

  fetchWebhooksList({ workspaceName, modelName }) {
    const url = `${BASE_API_URL}registry/model/webhook?modelName=${modelName}&workspaceName=${workspaceName}`;
    return axios.get(url);
  },

  createWebhook(values) {
    const url = `${BASE_API_URL}registry/model/webhook/url`;
    return axios.post(url, JSON.stringify(values));
  },

  testWebhook(values) {
    const url = `${BASE_API_URL}registry/model/webhook/url/test`;
    return axios.post(url, JSON.stringify(values));
  },

  updateWebhook({ id, modelName, workspaceName }, values) {
    const url = `${BASE_API_URL}registry/model/webhook/url?workspaceName=${workspaceName}&modelName=${modelName}&id=${id}`;
    return axios.put(url, JSON.stringify(values));
  },

  deleteWebhook({ id, modelName, workspaceName }) {
    const url = `${BASE_API_URL}registry/model/webhook/url?workspaceName=${workspaceName}&modelName=${modelName}&id=${id}`;
    return axios.delete(url);
  },

  getRedirectURL(registryModelItemId) {
    return `${BASE_API_URL}registry/model/item/redirect?registryModelItemId=${registryModelItemId}`;
  },

  getDownloadModelItemURL(registryModelItemId) {
    const relativeOrAbsoluteURL = `${BASE_API_URL}registry/model/item/download?modelItemId=${registryModelItemId}`;

    return new URL(relativeOrAbsoluteURL, window.location.origin).href;
  }
};

export default modelRegistryApi;
