import PropTypes from 'prop-types';
import React from 'react';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { useExperimentHTML } from '@experiment-details/api';
import SmallLoader from '@shared/components/SmallLoader';

import styles from './HTMLTab.module.scss';
import HTMLTabEmpty from './HTMLTabEmpty';
import { EmptyExperimentTab } from '@shared';

const HTMLTab = ({ experiments, isComparePage }) => {
  const [experiment] = experiments;

  const { data: html, isLoading } = useExperimentHTML({
    experimentKey: experiment?.experimentKey
  });

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  if (!html) {
    if (isComparePage) {
      return (
        <EmptyExperimentTab>
          <EmptyExperimentTab.Title>No HTML</EmptyExperimentTab.Title>
        </EmptyExperimentTab>
      );
    }
    return <HTMLTabEmpty />;
  }

  return (
    <div className={styles.container}>
      <iframe
        sandbox="allow-scripts"
        srcDoc={html}
        style={{
          height: '100%',
          width: '100%',
          overflow: 'visible',
          border: 'none',
          overflowX: 'scroll'
        }}
      />
    </div>
  );
};

HTMLTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default HTMLTab;
