import React from 'react';
import PropTypes from 'prop-types';

import { EditIcon, GreyDeleteSelectionIcon } from '@Icons-outdated';
import ThreeDotsMenu from '@design-system-outdated/components/ThreeDotsMenu';

const ModelRegistryWebhooksThreeDotsMenu = ({
  dataTest,
  onCloseRowMenuClick,
  setIsOpenDeleteConfirmationModal,
  anchorEl,
  activeRowData,
  ifCurrentUserWsOwner,
  tooltip,
  setIsOpenCreateEditWebhookModal,
  setActiveWebhookData,
  setAnchorEl
}) => (
  <ThreeDotsMenu
    dataTest={`${dataTest}-row-action-menu`}
    handleClose={onCloseRowMenuClick}
    anchorEl={anchorEl}
    activeRowData={activeRowData}
    items={[
      {
        text: 'Edit',
        getIcon: ({ className }) => <EditIcon className={className} />,
        disabled: !ifCurrentUserWsOwner,
        tooltip: !ifCurrentUserWsOwner && tooltip,
        action: () => {
          setIsOpenCreateEditWebhookModal(true);
          setActiveWebhookData(activeRowData);
          setAnchorEl(null);
        },
        dataTest: `${dataTest}-row-action-menu-edit`
      },
      {
        text: 'Delete',
        getIcon: ({ className }) => (
          <GreyDeleteSelectionIcon className={className} />
        ),
        disabled: !ifCurrentUserWsOwner,
        tooltip: !ifCurrentUserWsOwner && tooltip,
        action: () => {
          setIsOpenDeleteConfirmationModal(true);
          setAnchorEl(null);
        },
        dataTest: `${dataTest}-row-action-menu-delete`
      }
    ]}
    id="model-registry-webhook-row-action-menu"
  />
);

ModelRegistryWebhooksThreeDotsMenu.defaultProps = {
  anchorEl: null,
  activeRowData: {},
  tooltip: null
};

ModelRegistryWebhooksThreeDotsMenu.propTypes = {
  ifCurrentUserWsOwner: PropTypes.bool.isRequired,
  onCloseRowMenuClick: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  activeRowData: PropTypes.object,
  anchorEl: PropTypes.object,
  setIsOpenDeleteConfirmationModal: PropTypes.func.isRequired,
  setIsOpenCreateEditWebhookModal: PropTypes.func.isRequired,
  setActiveWebhookData: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  dataTest: PropTypes.string.isRequired
};

export default ModelRegistryWebhooksThreeDotsMenu;
