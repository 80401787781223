import { debounce, noop } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const SEARCH_TEXT_DELAY = 300;

type UseSyncSearchWithURLOpts = {
  fieldName: string;
  defaultValue?: string;
  onSearchUpdated?: (v: string) => void;
};
const useSyncSearchWithURL = ({
  fieldName,
  defaultValue,
  onSearchUpdated = noop
}: UseSyncSearchWithURLOpts) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const valueFromURL = queryParams.get(fieldName) ?? '';

  const [searchValue, setSearchValue] = useState<string>(defaultValue || '');

  const updateQueryParams = (v: string) => {
    queryParams.set(fieldName, v);

    if (v === '') {
      queryParams.delete(fieldName);
    }
    history.replace({ ...history.location, search: queryParams.toString() });
  };

  const syncAllSources = debounce((v: string) => {
    updateQueryParams(v);
    onSearchUpdated(v);
  }, SEARCH_TEXT_DELAY);

  const onSearchChange = (v: string) => {
    setSearchValue(v);
    syncAllSources(v);
  };

  useEffect(() => {
    onSearchUpdated(valueFromURL);
  }, []);

  useEffect(() => {
    if (searchValue && defaultValue === '') {
      updateQueryParams('');
    }
    setSearchValue(defaultValue || '');
  }, [defaultValue]);

  return {
    onSearchChange,
    searchValue
  };
};

export default useSyncSearchWithURL;
