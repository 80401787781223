import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DateRange, RadioSelector, Select } from '@DesignSystem/controllers';
import { Button } from '@ds';
import { chartColors } from '@design-system-outdated/constants';
import {
  INPUT_PANEL_TABS,
  INTERVAL_TYPE_OPTIONS,
  MPM_CHART_DISABLED_ZOOM,
  PERFORMANCE_DATE_RANGES,
  SYSTEM_PANEL_TABS,
  ACCURACY_METRICS_CLASSIFICATION_TABS,
  ACCURACY_METRICS_REGRESSION_TABS,
  MODEL_TYPES,
  TRANSFORM_TYPES,
  PANEL_NAMES,
  MODEL_FEATURES_TYPES,
  PANEL_SECTIONS
} from '@mpm/constants';
import { useMPMPerformanceParams } from '@mpm/hooks';
import './ModelPerformancePage.scss';
import { PanelContainer } from '../PanelContainer';
import { SegmentsPopover } from '../SegmentsPopover';
import { mapModelVersions } from '@mpm/utils';

const defaultVersions = [
  {
    label: 'All versions',
    value: null
  }
];

const chartColorsArr = Object.values(chartColors);

export const ModelPerformancePage = ({
  activeSegmentGroupColorIds,
  match,
  model,
  setActiveSegmentGroupColorIds,
  isSegmentsData
}) => {
  const versions = defaultVersions.concat(mapModelVersions(model.versions));
  const {
    isLabeled,
    modelType,
    customTransformations,
    isAucApplicable,
    features
  } = model;

  const [selectedSegmentsCounter, setSelectedSegmentsCounter] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const allowedAccuracyTab =
    modelType === MODEL_TYPES.CLASSIFICATION ||
    modelType === MODEL_TYPES.REGRESSION;

  const {
    version,
    onUpdateVersion,

    from,
    to,
    intervalType,
    dateRange,
    setStartDate,
    setEndDate,
    calcMaxDate,
    setDateRange,
    onUpdateIntervalType,
    onChangeRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  } = useMPMPerformanceParams(versions);

  const accuracyMetricsTabs = useMemo(() => {
    const panelTabs =
      (modelType === MODEL_TYPES.CLASSIFICATION && [
        ...ACCURACY_METRICS_CLASSIFICATION_TABS
      ]) ||
      (modelType === MODEL_TYPES.REGRESSION && [
        ...ACCURACY_METRICS_REGRESSION_TABS
      ]) ||
      [];

    if (isAucApplicable) {
      panelTabs.push({
        label: 'AUC',
        value: PANEL_SECTIONS.AUC
      });
    }

    if (customTransformations?.length) {
      const transformations = customTransformations
        .filter(
          element => element.transformType === TRANSFORM_TYPES.CONFUSION_MATRIX
        )
        .map(item => {
          const { name, definition, transformType } = item;
          return {
            label: name,
            value: PANEL_SECTIONS.CONFUSION_MATRIX_CUSTOM_METRIC,
            definition,
            transformType
          };
        });
      return [...panelTabs, ...transformations];
    }
    return panelTabs;
  }, [customTransformations, modelType, isAucApplicable]);

  const outputMetricsTabs = useMemo(() => {
    if (features?.length) {
      const tabs = features
        .filter(
          feature => feature.type === MODEL_FEATURES_TYPES.OUTPUT_FEATURES
        )
        .map(feature => {
          const { key, id, valType } = feature;
          return {
            label: key,
            value: PANEL_SECTIONS.FEATURE_DISTRIBUTION,
            valType: valType,
            featureId: id
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      return tabs;
    }
    return [];
  }, [features]);

  const openSegmentsPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="model-performance-tab">
      <div className="model-performance-tab-panels">
        <div className="model-performance-tab-header">
          <Button
            size="small"
            type="secondary"
            onClick={openSegmentsPopover}
            active={!!anchorEl}
          >
            Compare Segments ({selectedSegmentsCounter})
          </Button>
          <SegmentsPopover
            activeSegmentGroupColorIds={activeSegmentGroupColorIds}
            anchorEl={anchorEl}
            counterHandler={setSelectedSegmentsCounter}
            match={match}
            setActiveSegmentGroupColorIds={setActiveSegmentGroupColorIds}
            setAnchorEl={setAnchorEl}
          />
          <Select
            onChange={onUpdateVersion}
            options={versions}
            value={version}
            withInput
          />
          <div className="model-performance-tab-header-divider" />
          <Select
            onChange={onUpdateIntervalType}
            options={INTERVAL_TYPE_OPTIONS}
            value={intervalType}
          />
          <div className="model-performance-tab-header-divider" />
          <div className="model-performance-tab-header-date-ranges">
            {PERFORMANCE_DATE_RANGES[intervalType]?.map(days => (
              <RadioSelector
                isSelected={days === dateRange}
                key={`date-range-${days}`}
                onClick={() => {
                  setDateRange(days);
                  setEndDate(null);
                  setStartDate(new Date());
                }}
                text={`${days}d`}
              />
            ))}
          </div>
          <DateRange
            startDate={handledCalendarStartDate}
            endDate={handledCalendarEndDate}
            onChange={onChangeRange}
            calcMaxDate={calcMaxDate}
          />
        </div>

        <div className="model-performance-tab-content">
          {allowedAccuracyTab && (
            <PanelContainer
              title={PANEL_NAMES.ACCURACY_METRICS}
              tabs={accuracyMetricsTabs}
              from={from}
              intervalType={intervalType}
              to={to}
              version={version}
              colors={chartColorsArr}
              disableZoom={MPM_CHART_DISABLED_ZOOM}
              segmentColorIds={activeSegmentGroupColorIds}
              isLabeled={isLabeled}
              isSegmentsData={isSegmentsData}
            />
          )}

          <PanelContainer
            title={PANEL_NAMES.OUTPUT_FEATURES}
            tabs={outputMetricsTabs}
            from={from}
            intervalType={intervalType}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            segmentColorIds={activeSegmentGroupColorIds}
            isLabeled={isLabeled}
            isSegmentsData={isSegmentsData}
          />

          <PanelContainer
            title={PANEL_NAMES.INPUT_METRICS}
            tabs={INPUT_PANEL_TABS}
            from={from}
            intervalType={intervalType}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            segmentColorIds={activeSegmentGroupColorIds}
            isLabeled={isLabeled}
            isSegmentsData={isSegmentsData}
          />

          <PanelContainer
            title={PANEL_NAMES.SYSTEM_METRICS}
            tabs={SYSTEM_PANEL_TABS}
            from={from}
            intervalType={intervalType}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            segmentColorIds={activeSegmentGroupColorIds}
            isLabeled={isLabeled}
            isSegmentsData={isSegmentsData}
          />
        </div>
      </div>
    </div>
  );
};

ModelPerformancePage.propTypes = {
  activeSegmentGroupColorIds: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.string,
      segmentValue: PropTypes.string
    })
  ).isRequired,
  match: PropTypes.object.isRequired,
  model: PropTypes.shape({
    versions: PropTypes.array,
    isLabeled: PropTypes.bool,
    modelType: PropTypes.string,
    customTransformations: PropTypes.array
  }).isRequired,
  setActiveSegmentGroupColorIds: PropTypes.func.isRequired,
  isSegmentsData: PropTypes.bool.isRequired
};

ModelPerformancePage.defaultProps = {};
