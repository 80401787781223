import useProject from '@API/project/useProject';
import api from '@shared/api';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes } from '../../../constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';

const getReports = async ({ projectId, signal }) => {
  const { data } = await api.get('reports/list', {
    params: {
      projectId
    },
    signal
  });

  return data.reports;
};

export default function useReports(params, config) {
  const { data: project } = useProject();
  const dispatch = useDispatch();
  const projectId = params?.projectId ?? project?.projectId;

  return useQuery(
    ['reports', { projectId }],
    ({ signal }) => getReports({ projectId, signal }),
    {
      enabled: !!projectId,
      refetchOnMount: true,
      ...config,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your reports'
          )
        );
      }
    }
  );
}
