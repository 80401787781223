// Primary Colors
const primaryColor = '#5155f5';
const secondaryColor = '#e5e5fe';
const primaryColorHover = '#3438d0';
const primaryColorClick = '#262ab5';
const primaryColorClick5 = '#5154c3';
const primaryColorClick4 = '#7c7fd2';
const primaryColorClick3 = '#a8a9e1';
const primaryColorClick2 = '#d3d4f0';

// Text & Action Colors
const blackColor = '#191a1c';
const grayColor7 = '#3d4355';
const grayColor6 = '#5f677e';
const grayColor5 = '#8c95a8';
const grayColor4 = '#b3b9c8';
const grayColor3 = '#dce0ea';
const grayColor2 = '#eaecf2';
const grayColor1 = '#f5f7fa';
const whiteColor = '#ffffff';

// System Colors
const warningColor = '#f14668';
const successColor = '#00d41f';
const mainHeaderColor = '#373d4d';
const grayLineColor = '#f4f4f5';
const backgroundColor = '#f4f4f5';

// Plans Colors
const planTeamProColor = '#ff594c';
const planFreeColor = '#ffb42e';
const pineGreen = '#52AEA4';

export const chartColors = {
  light_blue: '#5899DA',
  purple: '#945FCF',
  pink: '#ED4A7B',
  green: '#19A979',
  yellow: '#F4B400',
  burgundy: '#BF399E',
  orange: '#FB9341',
  turquoise: '#12A4B4',
  blue: '#525DF4',
  red: '#EF6868',
  grey: '#6C8893',
  dark_blue: '#2E6497'
} as const;

const DSColors = {
  primaryColor,
  secondaryColor,
  primaryColorHover,
  primaryColorClick,
  primaryColorClick2,
  primaryColorClick3,
  primaryColorClick4,
  primaryColorClick5,

  blackColor,
  pineGreen,
  orangeColor: chartColors.orange,
  green: chartColors.green,
  grayColor1,
  grayColor2,
  grayColor3,
  grayColor4,
  grayColor5,
  grayColor6,
  grayColor7,
  whiteColor,

  warningColor,
  successColor,
  mainHeaderColor,
  grayLineColor,
  backgroundColor,

  planTeamProColor,
  planFreeColor
};

export default DSColors;
