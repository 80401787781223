import React from 'react';

import Modal, { ModalProps } from '@material-ui/core/Modal';

export const SmartBackdrop = ({ onClose, style }: ModalProps) => {
  return (
    <Modal open onClose={onClose} style={{ zIndex: 1000, ...style }}>
      <span></span>
    </Modal>
  );
};
