import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import Snackbar from '@design-system-outdated/components/Snackbar';

import flow from 'lodash/flow';
import get from 'lodash/get';
import invert from 'lodash/invert';
import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import helperSortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {
  DISABLE_SEARCH_FILTER,
  MAX_STEP_SIZE,
  NO_STEP,
  SORT_TYPES
} from '@experiment-management-shared/constants/tabConfigs';

import {
  COMPARE_EXPERIMENT_1_KEY,
  COMPARE_EXPERIMENT_2_KEY
} from '@experiment-management-shared/constants/experimentConstants';

import StepSlider from '@design-system-outdated/components/StepSlider';
import useExperimentParsedQueryConfig from '@experiment-details/hooks/useExperimentParsedQueryConfig';
import { trackEvent } from '@shared/utils/eventTrack';

import ExperimentBar from '@experiment-details/components/ExperimentBar';
import { generateRegexFromString } from '@shared/utils/displayHelpers';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc'
};

const groupByOptions = [
  { value: false, label: 'None' },
  { value: 'fileName', label: 'Name' },
  { value: SORT_TYPES.STEP, label: 'Step' },
  { value: SORT_TYPES.CONTEXT, label: 'Context' }
];

const sortByOptions = [
  { value: SORT_TYPES.NAME, label: 'Name' },
  { value: SORT_TYPES.STEP, label: 'Step' }
];

const toNumber = n => Number(n) || 0;

const order = orderBy => assets => {
  return orderBy === ORDER.DESC ? [...assets].reverse() : assets;
};

const filterAssets = ({ query = '' }) => asset => {
  const regexExpression = generateRegexFromString(query);
  const { fileName: name } = asset;

  if (!name || !regexExpression) return false;

  return regexExpression.test(name);
};

const sortAssets = sortBy => assets => {
  return [...assets].sort((a, b) => {
    if (sortBy === SORT_TYPES.STEP) return toNumber(a.step) - toNumber(b.step);

    const sortByKey = sortBy === SORT_TYPES.NAME ? 'fileName' : sortBy;

    const valueA = get(a, sortByKey, '');
    const valueB = get(b, sortByKey, '');

    if (isNumber(valueA) && isNumber(valueB)) {
      return valueA - valueB;
    }

    return String(valueA).localeCompare(String(valueB));
  });
};

const defaultGroupAssetsBy = groupBy => assets => {
  if (!groupBy) return assets;

  return assets.reduce((listOfCategories, asset) => {
    const category = get(asset, groupBy);

    if (!listOfCategories[category]) {
      listOfCategories[category] = [];
    }

    listOfCategories[category].push(asset);

    return listOfCategories;
  }, {});
};

const ExperimentAssetsConfigBar = ({
  assets,
  buttons,
  currentTemplate,
  customControlComponents,
  enableAutoResearch,
  experimentKey,
  filterAssets,
  groupAssetsBy,
  groupByOptions,
  hideSortBy,
  hideStepPlayer,
  hideStepSlider,
  history,
  location,
  onAssetsSearch,
  sortAssets,
  sortByOptions,
  valuePathCategory,
  TextSearchProps,
  isTabEmpty
}) => {
  const {
    defaultSearch,
    groupBy,
    setGroupBy,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy
  } = useExperimentParsedQueryConfig({
    location,
    experimentKey,
    currentTemplate,
    valuePathCategory
  });
  const [isDisableSearchFilter, setIsDisableSearchFilter] = useState(true);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [step, setStep] = useState(null);
  const [text, setText] = useState(defaultSearch);

  useEffect(() => {
    onAssetsSearch({
      assets: getAssets(assets),
      groupBy
    });
  }, [
    // remove the enableAutoResearch once we're sure the
    // assets prop doesn't change on every render
    enableAutoResearch && assets,
    groupBy,
    orderBy,
    isDisableSearchFilter,
    sortBy,
    step,
    text
  ]);

  useEffect(() => {
    if (!message) {
      setIsShowMessage(false);
      return;
    }

    setIsShowMessage(true);
  }, [message]);

  useEffect(() => {
    const { pathname } = location;
    const parsedQuery = queryString.parse(location.search);
    const tabName = get(parsedQuery, 'experiment-tab', '');
    const experiments = get(parsedQuery, 'experiments', '');
    const experimentKey1 = get(parsedQuery, COMPARE_EXPERIMENT_1_KEY, '');
    const experimentKey2 = get(parsedQuery, COMPARE_EXPERIMENT_2_KEY, '');

    const queryParamsObject = {
      experiments,
      orderBy,
      sortBy,
      groupBy,
      search: text
    };

    if (experimentKey1) {
      queryParamsObject[COMPARE_EXPERIMENT_1_KEY] = experimentKey1;
    }

    if (experimentKey2) {
      queryParamsObject[COMPARE_EXPERIMENT_2_KEY] = experimentKey2;
    }

    const stringifiedQuery = queryString.stringify(queryParamsObject, {
      skipNull: true,
      skipEmptyString: true
    });

    const search = `?experiment-tab=${tabName}&${stringifiedQuery}`;

    if (tabName) {
      history.replace({
        pathname,
        search,
        state: {
          preventScroll: true
        }
      });
    }
  }, [orderBy, sortBy, groupBy, text]);

  const handleStepChange = (step, isDisableSearchFilter) => {
    setIsDisableSearchFilter(isDisableSearchFilter);
    setStep(step);
  };

  const searchByText = query => assets => {
    if (query && query.length > 0) {
      return assets.filter(filterAssets({ query }));
    }

    return assets;
  };

  const searchByStep = () => files => {
    if (isDisableSearchFilter) return files;
    // todo: no step in the video

    return files.filter(asset => asset.step === step);
  };

  const getAssets = flow(
    searchByText(text),
    searchByStep(),
    sortAssets(sortBy),
    order(orderBy),
    groupAssetsBy(groupBy)
  );

  const getStepSliderProps = useCallback(() => {
    let uniqueSteps = uniqBy(assets, asset => asset.step);
    uniqueSteps = helperSortBy(uniqueSteps, 'step');
    uniqueSteps = uniqueSteps.filter(stepIns => isNumber(stepIns.step));
    const stepsMap = uniqueSteps.map(stepIns => stepIns.step);
    const valuesMap = invert(stepsMap);

    return {
      stepsMap,
      uniqueSteps,
      valuesMap
    };
  }, [assets]);

  const renderCustomControlComponents = () => {
    return customControlComponents.map(data => {
      return (
        <ExperimentBar.Custom
          key={data.label}
          label={data.label}
          component={data.component}
        />
      );
    });
  };

  return (
    <ExperimentBar rightContainerChildren={buttons}>
      <ExperimentBar.Search
        searchText={text}
        setSearchText={setText}
        placeholder="Name (regex)"
        disabled={isTabEmpty}
        {...TextSearchProps}
      />
      {!hideSortBy && (
        <ExperimentBar.SortSelect
          label="Sort by"
          changeHandler={option => setSortBy(option.value)}
          orderChangeHandler={() =>
            setOrderBy(orderBy === ORDER.ASC ? ORDER.DESC : ORDER.ASC)
          }
          orderDirection={orderBy}
          options={sortByOptions}
          value={sortBy}
          disabled={isTabEmpty}
        />
      )}
      <ExperimentBar.Select
        label="Group by"
        changeHandler={option => setGroupBy(option.value)}
        options={groupByOptions}
        value={groupBy}
        disabled={isTabEmpty}
      />
      {!hideStepSlider && (
        <ExperimentBar.Custom
          label="Step"
          wrapperType="stepSlider"
          component={
            <StepSlider
              {...getStepSliderProps()}
              trackCallback={() => trackEvent('GALLERY_PLAY_BUTTON')}
              hidePlayButtons={hideStepPlayer}
              onStepChange={handleStepChange}
              onMessage={setMessage}
              handleOnStepChange={handleStepChange}
              defaultSliderValue={DISABLE_SEARCH_FILTER}
              maxStepSize={MAX_STEP_SIZE}
              noStep={NO_STEP}
              disabled={isTabEmpty}
            />
          }
        />
      )}
      {renderCustomControlComponents()}
      {isShowMessage && (
        <Snackbar
          open={isShowMessage && !!message}
          message={message}
          autoHideDuration={1000}
        />
      )}
    </ExperimentBar>
  );
};

ExperimentAssetsConfigBar.defaultProps = {
  assets: [],
  buttons: null,
  customControlComponents: [],
  enableAutoResearch: false,
  filterAssets,
  groupAssetsBy: defaultGroupAssetsBy,
  groupByOptions,
  hideSortBy: false,
  hideStepPlayer: false,
  hideStepSlider: false,
  onAssetsSearch: noop,
  sortAssets,
  sortByOptions,
  TextSearchProps: {}
};

ExperimentAssetsConfigBar.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
  customControlComponents: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, component: PropTypes.node })
  ),
  buttons: PropTypes.node,
  currentTemplate: PropTypes.object.isRequired,
  enableAutoResearch: PropTypes.bool,
  experimentKey: PropTypes.string.isRequired,
  filterAssets: PropTypes.func,
  groupAssetsBy: PropTypes.func,
  groupByOptions: PropTypes.arrayOf(PropTypes.object),
  hideSortBy: PropTypes.bool,
  hideStepSlider: PropTypes.bool,
  hideStepPlayer: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onAssetsSearch: PropTypes.func,
  sortAssets: PropTypes.func,
  sortByOptions: PropTypes.arrayOf(PropTypes.object),
  valuePathCategory: PropTypes.string.isRequired,
  TextSearchProps: PropTypes.object,
  isTabEmpty: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const { experimentKey } = props;
  const { chartsPlotly } = state.runDetails;
  const { currentTemplate } = get(chartsPlotly, experimentKey, chartsPlotly);

  return {
    currentTemplate
  };
};

export default withRouter(connect(mapStateToProps)(ExperimentAssetsConfigBar));
