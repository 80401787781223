import { useCallback, useMemo, useRef } from 'react';

import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import useTrackEventLocation from '@shared/hooks/useTrackEventLocation';
import { panelEvents } from '@/constants/trackingEventTypes';
import debounce from 'lodash/debounce';
import { Grouping } from '@experiment-management-shared/types';

const WAIT_TIMEOUT_FOR_LOG_EVENT = 1000;
const MIN_INTERACTION_DURATION = 2000;

type ChartTooltipLocalData = {
  points: number;
  type: string;
  startTime?: Date;
  endTime?: Date;
  grouping?: Grouping | null;
};

const DEFAULT_LOCAL_DATA: ChartTooltipLocalData = {
  points: 0,
  type: ''
};

const useChartTooltipTrackEvent = () => {
  const baseTrackEvent = useBaseTrackEvent();
  const trackEventLocation = useTrackEventLocation();
  const dataRef = useRef<ChartTooltipLocalData>({ ...DEFAULT_LOCAL_DATA });

  const logEvent = useCallback(
    (
      type: string,
      duration: number,
      points: number,
      grouping?: Grouping | null
    ) => {
      baseTrackEvent(panelEvents.PANEL_TOOLTIP_INTERACTED, {
        location: trackEventLocation,
        chart_type: type,
        duration_of_interaction: duration,
        number_of_interacted_points: points,
        grouping: grouping?.enabled ? grouping : null
      });
    },
    [baseTrackEvent, trackEventLocation]
  );

  const logEventDebounced = useMemo(() => {
    return debounce((localData: ChartTooltipLocalData) => {
      const duration =
        (localData.endTime as Date).getTime() -
        (localData.startTime as Date).getTime();

      if (duration >= MIN_INTERACTION_DURATION) {
        logEvent(
          localData.type,
          duration,
          localData.points,
          localData.grouping
        );
      }

      dataRef.current = { ...DEFAULT_LOCAL_DATA };
    }, WAIT_TIMEOUT_FOR_LOG_EVENT);
  }, [logEvent]);

  const interact = useCallback(
    (type: string, grouping?: Grouping | null) => {
      logEventDebounced.cancel();
      if (!dataRef.current.startTime) {
        dataRef.current.type = type;
        dataRef.current.startTime = new Date();
        dataRef.current.grouping = grouping;
      }
      dataRef.current.points = dataRef.current.points + 1;
    },
    [logEventDebounced]
  );

  const stop = useCallback(() => {
    if (dataRef.current.startTime) {
      logEventDebounced({
        ...dataRef.current,
        endTime: new Date()
      });
    }
  }, [logEventDebounced]);

  return {
    interact,
    stop
  };
};

export default useChartTooltipTrackEvent;
