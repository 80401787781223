import React from 'react';
import { useActiveWorkspace } from '@shared/hooks';

import WorkspaceMembersTable from '@projects/components/WorkspaceMembersTable';

const ManagePageWorkspaces = () => {
  const activeWorkspace = useActiveWorkspace();

  return (
    <WorkspaceMembersTable
      workspaceId={activeWorkspace?.id}
      workspaceName={activeWorkspace?.name}
    />
  );
};

export default ManagePageWorkspaces;
