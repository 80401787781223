import React, { createContext, useContext } from 'react';

export type GroupButtonContextType = {
  changeActiveItem: (v: string) => void;
  activeItem: string;
};

export const GroupButtonContext = createContext<
  GroupButtonContextType | undefined
>(undefined);

export type GroupButtonProviderProps = {
  selectedValue: string;
  onValueChange: (v: string) => void;
  children: React.ReactNode;
};
export const GroupButtonProvider = ({
  selectedValue,
  onValueChange,
  children
}: GroupButtonProviderProps) => {
  const value = {
    changeActiveItem: onValueChange,
    activeItem: selectedValue
  };

  return (
    <GroupButtonContext.Provider value={value}>
      {children}
    </GroupButtonContext.Provider>
  );
};

export const useGroupButtonContext = (): GroupButtonContextType => {
  const ctx = useContext(GroupButtonContext);

  if (ctx === undefined) {
    throw new Error(
      'useGroupButtonContext must be used within a GroupButtonProvider'
    );
  }

  return ctx;
};
