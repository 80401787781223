import useTableRowSearch from '@shared/hooks/useTableRowSearch';
import React, { useState } from 'react';
import useComparisonDecimalPrecision from './hooks/useComparisonDecimalPrecision';
import { ExperimentDetails } from '@shared/types';
import ComparisonTableHeader from './ComparisonTableHeader';
import { Table, TableMaxHeightProvider } from '@DesignSystem/tables';
import ComparisonEmptyState from './ComparisonEmptyState';
import {
  COMPARISON_COLUMNS_NAME,
  COMPARISON_VALUE_CELL_KEYS,
  DEFAULT_COMPARISON_TABLE_COLUMNS,
  getAdditionalComparisonColumns
} from './comparisonTableConfig';
import ComparisonTableValueCell from './cells/ComparisonTableValueCell';
import ComparisonTableKeyCell from './cells/ComparisonTableKeyCell';
import ComparisonTableExperimentCell from './cells/ComparisonTableExperimentCell';
import { ComparisonTableContext } from './context/comparisonTableContext';
import {
  AllowedCompareKeys,
  ComparisonTableDefaultData,
  DetailViewValue
} from './types';
import useComparisonTableColumnWidths from './hooks/useComparisonTableColumnWidths';
import useComparisonRowData from './hooks/useComparisonRowData';

import styles from './ComparisonTable.module.scss';
import {
  getCellClassNameComparisonTable,
  getCellRowspanComparisonTable,
  getRowClassNameComparisonTable
} from './utils';
import { Table as DXTable } from '@devexpress/dx-react-grid-material-ui';
import cx from 'classnames';

const dataTypes = [
  {
    cols: [COMPARISON_COLUMNS_NAME.KEY],
    cell: ComparisonTableKeyCell
  },
  {
    cols: [COMPARISON_COLUMNS_NAME.EXPERIMENT],
    cell: ComparisonTableExperimentCell
  },
  {
    cols: COMPARISON_VALUE_CELL_KEYS,
    cell: ComparisonTableValueCell
  }
];

type ComparisonTableProps<TData> = {
  experiments: ExperimentDetails[];
  experimentsData: TData[][];
  detailView: DetailViewValue;
  emptyView: (materialProps: DXTable.DataCellProps) => React.ReactNode;
  isLoading: boolean;
  valueKeys: AllowedCompareKeys[];
};
const ComparisonTable = <TData extends ComparisonTableDefaultData>({
  experiments,
  experimentsData,
  emptyView,
  detailView,
  isLoading,
  valueKeys
}: ComparisonTableProps<TData>) => {
  const [firstExperiment] = experiments;
  const { searchText, handleSearch, resetSearch } = useTableRowSearch();
  const [filterDuplicates, setFilterDuplicates] = useState(false);

  const {
    decimalsPrecision,
    maxDecimalsInItems,
    onChangeDecimalsPrecision
  } = useComparisonDecimalPrecision<TData>({
    items: experimentsData.flat(),
    experimentKey: firstExperiment.experimentKey,
    detailView
  });

  const rowData = useComparisonRowData({
    experiments,
    experimentsData,
    filterDuplicates,
    searchText,
    valueKeys
  });

  const { columnWidths, onColumnWidthsChange } = useComparisonTableColumnWidths(
    {
      rowData
    }
  );

  const columns = [
    ...DEFAULT_COMPARISON_TABLE_COLUMNS,
    ...getAdditionalComparisonColumns(valueKeys)
  ];

  return (
    <div
      className={cx('table-tab-container', `table-tab-contaner__${detailView}`)}
    >
      <ComparisonTableHeader
        decimalsPrecision={decimalsPrecision}
        searchHandler={handleSearch}
        searchValue={searchText}
        detailView={detailView}
        maxDecimalsInItems={maxDecimalsInItems}
        onChangeDecimalsPrecision={onChangeDecimalsPrecision}
        onChangeRemoveDuplicates={setFilterDuplicates}
      />
      <div className={styles.comparisonTable}>
        <TableMaxHeightProvider>
          <ComparisonTableContext.Provider
            value={{
              decimalsPrecision
            }}
          >
            <Table
              dataTypes={dataTypes}
              columns={columns}
              rows={rowData}
              rowIdKey="rowId"
              height="auto"
              isFetching={isLoading}
              paginationConfig={{
                isDisabled: true
              }}
              columnOrderConfig={{
                isDisabled: true
              }}
              selectionConfig={{
                isDisabled: true
              }}
              columnWidthsConfig={{
                isDisabled: false,
                columnWidths,
                onColumnWidthsChange
              }}
              getCellClassName={getCellClassNameComparisonTable}
              getCellRowspan={getCellRowspanComparisonTable(experiments.length)}
              getRowClassName={getRowClassNameComparisonTable}
              renderEmptyState={materialProps => (
                <ComparisonEmptyState
                  searchValue={searchText}
                  resetSearch={resetSearch}
                  isLoading={isLoading}
                  emptyView={emptyView}
                  {...materialProps}
                />
              )}
            />
          </ComparisonTableContext.Provider>
        </TableMaxHeightProvider>
      </div>
    </div>
  );
};

export default ComparisonTable;
