import axios from 'axios';
import { UseQueryOptions } from 'react-query';
import { BASE_API_URL } from '@/constants/configConstants';

const axiosInstance = axios.create({
  baseURL: BASE_API_URL
});

axiosInstance.defaults.withCredentials = true;

export type QueryConfig<TQueryFnData, TData = TQueryFnData> = Omit<
  UseQueryOptions<
    TQueryFnData,
    Error,
    TData,
    [string, Record<string, unknown>, ...string[]]
  >,
  'queryKey' | 'queryFn'
>;

export default axiosInstance;
