// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportHistoryPopover-module__popover--qkASC.MuiPopover-paper{max-height:400px}.ReportHistoryPopover-module__groupContainer--Lf4s0{padding:12px 0}.ReportHistoryPopover-module__groupContainer--Lf4s0 .ReportHistoryPopover-module__header--TCYPe{color:#333;font-size:15px;font-weight:500;padding:4px 16px}.ReportHistoryPopover-module__groupContainer--Lf4s0 .ReportHistoryPopover-module__itemsContainer--DxeAr .ReportHistoryPopover-module__item--pBupj{color:#7178f3;cursor:pointer;font-size:13px;font-weight:500;padding:4px 16px}.ReportHistoryPopover-module__groupContainer--Lf4s0 .ReportHistoryPopover-module__itemsContainer--DxeAr .ReportHistoryPopover-module__item--pBupj:hover{background-color:#f3f3f3}.ReportHistoryPopover-module__groupContainer--Lf4s0 .ReportHistoryPopover-module__itemsContainer--DxeAr .ReportHistoryPopover-module__item--pBupj.ReportHistoryPopover-module__itemCurrent--EVvBg{background-color:#6168e3;color:#f3f3f3}.ReportHistoryPopover-module__separator--QmcEj{border-bottom:1px solid #eee}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `ReportHistoryPopover-module__popover--qkASC`,
	"groupContainer": `ReportHistoryPopover-module__groupContainer--Lf4s0`,
	"header": `ReportHistoryPopover-module__header--TCYPe`,
	"itemsContainer": `ReportHistoryPopover-module__itemsContainer--DxeAr`,
	"item": `ReportHistoryPopover-module__item--pBupj`,
	"itemCurrent": `ReportHistoryPopover-module__itemCurrent--EVvBg`,
	"separator": `ReportHistoryPopover-module__separator--QmcEj`
};
module.exports = ___CSS_LOADER_EXPORT___;
