import { useCallback } from 'react';
import { useParams } from 'react-router';

import useProjectViews from './useProjectViews';

export default function useProjectView(params, config) {
  const { viewId: urlViewId } = useParams();
  const projectViewId = params?.viewId ?? urlViewId;

  const getSelectedView = useCallback(
    views => views.find(view => view.templateId === projectViewId),
    [projectViewId]
  );

  return useProjectViews(null, {
    enabled: !!projectViewId,
    ...config,
    select: getSelectedView
  });
}
