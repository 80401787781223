import queryString from 'query-string';
import cx from 'classnames';

import { TextButton, IconButton, Tooltip } from '@ds';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import without from 'lodash/without';

import SearchIcon from '@material-ui/icons/Search';

import {
  GALLERY_TAB,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';

import {
  CHART_GALLERY_COLUMNS,
  CHART_GALLERY_COLUMN_NAMES,
  SORT_BY_DIRECTION,
  TEMPLATE_SCOPE_TYPES,
  TEMPLATE_SCOPE_TYPES_BY_LABEL,
  TEMPLATE_SCOPE_TYPE_LABELS,
  getBuildInCharts
} from '@experiment-management-shared/constants/chartsGallery';

import usePanelTemplates from '@API/panels/usePanelTemplates';
import useImportPanelMutation from '@API/panels/useImportPanelMutation';
import usePanelTemplatesCount from '@API/panels/usePanelTemplatesCount';
import GalleryTable from '@experiment-management-shared/components/PanelModal/GalleryTable';
import visualizationsActions from '@/actions/visualizationsActions';
import { dialogTypes } from '@/constants/alertTypes';
import { DISABLE_PUBLIC_ACCESS } from '@/constants/configConstants';
import { DOCS_LINK_TYPE } from '@/constants/urlConstants';

import { getSavedGalleryTab } from '@/reducers/ui/visualizationsUiReducer';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import alertsUtil from '@/util/alertsUtil';

import { useActiveWorkspace } from '@shared/hooks';

import DocsLink from '@shared/components/DocsLink';
import ImportFileModal from '@experiment-management-shared/components/ImportFileModal';
import SmallLoader from '@shared/components/SmallLoader';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';
import TabLabelWithBadge from '@shared/components/TabLabelWithBadge';
import SortByPopover from '@shared/components/WorkspaceSubComponents/SortByPopover';
import GallerySearchBar from './GallerySearchBar';
import { DSImportIcon, DSPlusIcon } from '@ds-icons';

const SORTABLE_COLUMNS = [
  CHART_GALLERY_COLUMNS.DESCRIPTION,
  CHART_GALLERY_COLUMNS.LAST_SAVED,
  CHART_GALLERY_COLUMNS.NAME,
  CHART_GALLERY_COLUMNS.VISIBILITY,
  CHART_GALLERY_COLUMNS.OWNER,
  CHART_GALLERY_COLUMNS.VOTE_COUNT
].map(key => ({
  key,
  label: CHART_GALLERY_COLUMN_NAMES[key]
}));

const { BUILT_IN, INTERNAL, WORKSPACE, PUBLIC } = TEMPLATE_SCOPE_TYPES;

const hashWithGalleryTabParam = `${SELECT_PANEL_PATH}?${GALLERY_TAB}`;

const GalleryModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const importPanelMutation = useImportPanelMutation();
  const buildInCharts = useSelector(state => getBuildInCharts(state));

  const {
    data: internalTemplates,
    isLoading: isLoadingInternal
  } = usePanelTemplates({ scope: INTERNAL });
  const {
    data: publicTemplates,
    isLoading: isLoadingPublic
  } = usePanelTemplates({ scope: PUBLIC });
  const {
    data: workspaceTemplates,
    isLoading: isLoadingWorkspace
  } = usePanelTemplates({ scope: WORKSPACE });
  const { data: templatesCount } = usePanelTemplatesCount();

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const savedGalleryTab = useSelector(getSavedGalleryTab);
  const activeWorkspace = useActiveWorkspace();

  const parsedHash = queryString.parse(location.hash);
  const tabLabelFromParam = parsedHash[hashWithGalleryTabParam];
  const currentTabFromParam = TEMPLATE_SCOPE_TYPES_BY_LABEL[tabLabelFromParam];

  const getDefaultActiveTab = () => {
    if (currentTabFromParam) {
      return currentTabFromParam;
    }

    return isNull(savedGalleryTab)
      ? TEMPLATE_SCOPE_TYPES.BUILT_IN
      : savedGalleryTab;
  };

  const [activeTab, setActiveTab] = useState(getDefaultActiveTab());
  const [searchText, setSearchText] = useState('');
  const [sortByDirection, setSortByDirection] = useState(
    SORT_BY_DIRECTION.DESC
  );
  const [sortByProperty, setSortByProperty] = useState(
    CHART_GALLERY_COLUMNS.VOTE_COUNT
  );
  const showControls = activeTab !== TEMPLATE_SCOPE_TYPES.BUILT_IN;
  const centerTabContent = activeTab === TEMPLATE_SCOPE_TYPES.BUILT_IN;

  useEffect(() => {
    if (!currentTabFromParam && activeTab) {
      const hash = `#${hashWithGalleryTabParam}=${TEMPLATE_SCOPE_TYPE_LABELS[activeTab]}`;
      history.replace({ ...location, hash }, { preventScroll: true });
      return;
    }

    if (currentTabFromParam !== activeTab) {
      setActiveTab(currentTabFromParam);
    }

    dispatch(visualizationsActions.setSavedGalleryTab(activeTab));
  }, [activeTab, currentTabFromParam, dispatch, history, location]);

  useEffect(() => setSearchText(''), [tabLabelFromParam]);

  const templatesByTabLabel = useMemo(() => {
    const templateByLabelMap = {
      [BUILT_IN]: buildInCharts,
      [INTERNAL]: internalTemplates,
      [WORKSPACE]: workspaceTemplates,
      [PUBLIC]: publicTemplates
    };

    return templateByLabelMap[activeTab] || [];
  }, [activeTab, internalTemplates, workspaceTemplates, publicTemplates]);

  const chartTemplates = useMemo(() => {
    return templatesByTabLabel
      .filter(({ name, description, owner }) => {
        const searchableContent = [name, description, owner].map(content =>
          defaultTo(content, '').toLowerCase()
        );

        return searchableContent.some(content =>
          content.includes(searchText.toLowerCase())
        );
      })
      .sort((templateA, templateB) => {
        const { LAST_SAVED, VOTE_COUNT } = CHART_GALLERY_COLUMNS;
        const isNumberColumn = [LAST_SAVED, VOTE_COUNT].includes(
          sortByProperty
        );
        const defaultValue = isNumberColumn ? 0 : '';
        const valueA = get(templateA, sortByProperty, defaultValue);
        const valueB = get(templateB, sortByProperty, defaultValue);

        if (isNumberColumn) {
          return sortByDirection === SORT_BY_DIRECTION.ASC
            ? valueA - valueB
            : valueB - valueA;
        }

        return sortByDirection === SORT_BY_DIRECTION.ASC
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });
  }, [templatesByTabLabel, searchText, sortByDirection, sortByProperty]);

  const handleCancelSearch = useCallback(() => setSearchText(''), []);
  const handleSearchChange = useCallback(text => setSearchText(text), []);
  const handleSortByChange = useCallback((property, direction) => {
    setSortByDirection(direction);
    setSortByProperty(property);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    dispatch(visualizationsActions.setSavedGalleryTab(newValue));

    const hash = `#${hashWithGalleryTabParam}=${TEMPLATE_SCOPE_TYPE_LABELS[newValue]}`;

    history.replace({ ...location, hash }, { preventScroll: true });
  };

  const handleTemplatesChange = () => {
    queryClient.invalidateQueries([
      'panelTemplates',
      { teamId: activeWorkspace?.id }
    ]);
    queryClient.invalidateQueries([
      'panelTemplatesCount',
      { teamId: activeWorkspace?.id }
    ]);
  };

  const getTabCount = tabName => {
    if (tabName === TEMPLATE_SCOPE_TYPE_LABELS.BUILT_IN) {
      return buildInCharts.length;
    }

    const countTabName =
      TEMPLATE_SCOPE_TYPE_LABELS.WORKSPACE === tabName ? 'My Panels' : tabName;

    const tabCount = templatesCount?.tabs.find(
      ({ tab }) => countTabName === tab
    );

    return tabCount?.count ?? 0;
  };

  const openCreatePanelTab = () => {
    // @todo: create only one path for creating custom panels
    //        and pass the experiment keys as querystring
    const basePathname = location.pathname.includes('/reports/')
      ? location.pathname.split('/reports/')[0]
      : location.pathname;
    const url = `${basePathname}/${SELECT_PANEL_PATH}/new-custom-panel${location.search}`;
    const opener = window.open(url);

    opener.onCustomPanelSave = handleTemplatesChange;
  };

  const openImportTemplateModal = () => {
    const modalId = dialogTypes.IMPORT_FILE_MODAL;

    const handleSubmit = file => {
      const templateToImport = new FormData();
      templateToImport.append('file', file);

      importPanelMutation.mutate({ templateToImport });
    };

    const modal = (
      <ImportFileModal
        modalId={modalId}
        title="Import Panel"
        submitHandler={handleSubmit}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, modal));
  };

  const renderTabs = () => {
    let tabs = [BUILT_IN, INTERNAL, WORKSPACE, PUBLIC];

    if (!isUserLoggedIn) {
      tabs = without(tabs, WORKSPACE);
    }

    if (DISABLE_PUBLIC_ACCESS) {
      tabs = without(tabs, PUBLIC);
    }

    return tabs.map(scopeType => {
      const tabName = TEMPLATE_SCOPE_TYPE_LABELS[scopeType];
      const label = (
        <TabLabelWithBadge
          labelText={tabName}
          badgeCount={getTabCount(tabName)}
        />
      );

      return <StyledTab key={tabName} value={scopeType} label={label} />;
    });
  };

  const renderTabBar = () => {
    return (
      <StyledTabs
        className={`charts-gallery-tab-bar ${TEMPLATE_SCOPE_TYPE_LABELS[activeTab]}`}
        value={activeTab}
        onChange={handleTabChange}
      >
        {renderTabs()}
      </StyledTabs>
    );
  };

  const renderActiveTab = () => {
    const panelType =
      activeTab === TEMPLATE_SCOPE_TYPES.WORKSPACE
        ? 'Workspace'
        : TEMPLATE_SCOPE_TYPE_LABELS[activeTab];

    const isLoadingMap = {
      [INTERNAL]: isLoadingInternal,
      [PUBLIC]: isLoadingPublic,
      [WORKSPACE]: isLoadingWorkspace
    };

    if (isLoadingMap[activeTab]) {
      const loadingMessage = `Fetching ${panelType} panels`;

      return (
        <SmallLoader
          isFlexDisplay
          primaryMessage="Loading..."
          secondaryMessage={loadingMessage}
        />
      );
    }

    return (
      <div
        className={cx('gallery-modal-tab-content', {
          centered: centerTabContent
        })}
      >
        {showControls && (
          <div className="gallery-modal-tab-controls-container">
            <div className="gallery-modal-tab-controls">
              <div className="gallery-modal-tab-controls-count">
                {getTabCount(panelType)} panels
              </div>

              <div className="gallery-modal-separator" />

              <div className="gallery-modal-tab-controls-sort">
                <SortByPopover
                  className="gallery-modal-sort"
                  handleSortByChange={handleSortByChange}
                  items={SORTABLE_COLUMNS}
                  sortByDirection={sortByDirection}
                  sortByProperty={sortByProperty}
                />
              </div>

              <div className="gallery-modal-separator" />

              <div className="gallery-modal-tab-controls-search">
                <GallerySearchBar
                  handleCancelSearch={handleCancelSearch}
                  handleSearchChange={handleSearchChange}
                  SearchBarProps={{
                    classes: {
                      iconButtonHidden:
                        'gallery-modal-tab-controls-search-hidden',
                      searchContainer:
                        'gallery-modal-tab-controls-search-container',
                      searchIconButton: 'gallery-modal-tab-controls-search-icon'
                    },
                    placeholder: 'Search...',
                    searchIcon: <SearchIcon />,
                    value: searchText
                  }}
                />
              </div>
            </div>
            <div>
              <Tooltip content="Import Panel" placement="top">
                <IconButton
                  Icon={<DSImportIcon />}
                  onClick={openImportTemplateModal}
                  size="XL"
                  type="secondary"
                />
              </Tooltip>
            </div>
          </div>
        )}

        <GalleryTable
          activeTab={activeTab}
          chartTemplates={chartTemplates}
          onChangeTemplate={handleTemplatesChange}
        />
      </div>
    );
  };

  const renderSubHeader = () => {
    return (
      <div className="gallery-modal-subheader">
        <div className="gallery-modal-tabs">
          {renderTabBar()}

          <div className="gallery-modal-separator" />

          <TextButton PrefixIcon={<DSPlusIcon />} onClick={openCreatePanelTab}>
            Create new
          </TextButton>
        </div>

        <DocsLink type={DOCS_LINK_TYPE.JAVASCRIPT_PANELS} />
      </div>
    );
  };

  return (
    <div className="gallery-modal-body">
      {renderSubHeader()}
      {renderActiveTab()}
    </div>
  );
};

export default GalleryModal;
