import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';
import { BasicModal } from '@DesignSystem/modals';
import Input from '@DesignSystem/controllers/Input/Input';
import uniqBy from 'lodash/uniqBy';

import { MetadataIcon } from '@Icons-outdated';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { ManageStagesToTagsSelect } from '@shared/components/Select';

import './ManageStageToTagsModal.scss';

const useStyles = makeStyles({
  paper: {
    width: 460,
    minHeight: 445
  }
});

// SMELLY: Stages to tags migration on FE
const ManageStageToTagsModal = ({
  open,
  onClose,
  onUpdate,
  stages: initialStages = [],
  description,
  options,
  isModelRegistryTagsFromStagesEnabled
}) => {
  const classes = useStyles();
  const [stages, setStages] = useState([]);
  const [changesInputValue, setChangesInputValue] = useState(description);

  useEffect(() => {
    setChangesInputValue(description);
  }, [description]);

  useEffect(() => {
    return () => {
      setChangesInputValue('');
    };
  }, []);

  useEffect(() => {
    setStages(initialStages);
  }, [initialStages]);

  const allStages = useMemo(() => uniqBy([...stages, ...options], 'value'), [
    stages,
    options
  ]);
  const handleUpdate = useCallback(() => {
    onUpdate(
      stages.map(({ value }) => value),
      changesInputValue
    );
  }, [stages, changesInputValue]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      additionalClasses={classes}
      title={
        isModelRegistryTagsFromStagesEnabled ? 'Manage tags' : 'Manage stage'
      }
      type="manage-stage-modal"
      primaryButtonText="Update"
      secondaryButtonText="Cancel"
      content={
        <Box className="model-registry-manage-stages">
          <Box className="model-registry-manage-stages-label">
            Create or select{' '}
            {isModelRegistryTagsFromStagesEnabled ? 'tag' : 'stage'}
          </Box>
          <Box className="model-registry-manage-stages-wrapper">
            <ManageStagesToTagsSelect
              handleStagesChange={setStages}
              value={stages}
              options={allStages}
              label={
                isModelRegistryTagsFromStagesEnabled
                  ? 'Create or select a tag'
                  : 'Create or select a stage'
              }
              placeholder={
                isModelRegistryTagsFromStagesEnabled
                  ? 'Create or select a tag'
                  : 'Create or select a stage'
              }
              formatCreateLabel={labelVal => (
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  spacing={1}
                  className="manage-stages-select-add"
                >
                  <Grid item>
                    <AddIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    Add {`"${labelVal}"`}{' '}
                    {isModelRegistryTagsFromStagesEnabled ? 'tag' : 'stage'}
                  </Grid>
                </Grid>
              )}
            />
          </Box>
          <Box className="model-registry-manage-stages-description">
            <Grid container alignContent="center" alignItems="center">
              <Grid item>Document your changes</Grid>
              <Grid item>
                <Tooltip
                  title={
                    <div>
                      <div>All model changes will be</div>
                      <div>reflected in History tab</div>
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <Box className="model-registry-manage-stages-description-icon">
                    <MetadataIcon />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box className="model-registry-manage-stages-description-input">
            <Input
              multiline
              value={changesInputValue}
              onChange={setChangesInputValue}
              height="80px"
            />
          </Box>
        </Box>
      }
      onPrimaryButtonClick={handleUpdate}
      onSecondaryButtonClick={onClose}
    />
  );
};

ManageStageToTagsModal.defaultProps = {
  description: '',
  stages: [],
  options: []
};

ManageStageToTagsModal.propTypes = {
  isModelRegistryTagsFromStagesEnabled: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  stages: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      source: PropTypes.string,
      value: PropTypes.string,
      color: PropTypes.string
    })
  ),
  description: PropTypes.string
};

export default ManageStageToTagsModal;
