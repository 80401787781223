import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import omit from 'lodash/omit';
import get from 'lodash/get';
import SaveViewButton from '@experiment-management-shared/components/SaveViewButton/SaveViewButton';

import useProject from '@API/project/useProject';
import useSelectDefaultProjectMutation from '@API/project/useSelectDefaultProjectMutation';
import useSelectedProjectView from '@API/project/useSelectedProjectView';
import useUpsertViewMutation from '@API/project/useUpsertViewMutation';

import {
  fromDashboardToTemplate,
  getOriginalViewId
} from '@experiment-management-shared/utils/EMView';
import { NEW_VIEW_TEMPLATE_NAME } from '@/constants/dashboardConstants';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const SaveTemplateButton = ({ canEdit, dashboard, hasUnsavedChanges }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectName, workspace, section } = useParams();
  const { data: project } = useProject();

  const { data: selectedView } = useSelectedProjectView();

  const selectDefaultProjectMutation = useSelectDefaultProjectMutation();
  const upsertViewMutation = useUpsertViewMutation();
  const originalTemplateId = getOriginalViewId(selectedView);

  const dashboardView = useMemo(() => {
    return fromDashboardToTemplate({
      dashboard,
      project,
      selectedView
    });
  }, [dashboard, project, selectedView]);

  const handleSaveNewView = async (templateName, shouldSetAsDefaultView) => {
    try {
      const { template_id: newViewId } = await upsertViewMutation.mutateAsync({
        view: {
          ...omit(dashboardView, 'templateId'),
          createdFromTemplateId: dashboardView.templateId,
          templateName,
          unsavedView: false
        }
      });

      if (shouldSetAsDefaultView) {
        await selectDefaultProjectMutation.mutateAsync({ viewId: newViewId });
      }

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_API_RESPONSE,
          `Your new view was saved successfully`
        )
      );

      history.push(
        `/${workspace}/${projectName}/view/${newViewId}/${section ?? 'panels'}`
      );
    } catch (error) {
      const { msg } = error.response.data;

      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_SAVE_TEMPLATE,
          `There was an error saving your dashboard view: ${msg}`
        )
      );
    }
  };

  const handleClickOverwriteView = async () => {
    try {
      await upsertViewMutation.mutateAsync({
        view: {
          ...dashboardView,
          templateId: originalTemplateId,
          unsavedView: false
        }
      });

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_API_RESPONSE,
          `Your view was successfully updated`
        )
      );
    } catch (error) {
      const { msg } = error.response.data;

      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_SAVE_TEMPLATE,
          `There was an error saving your dashboard view: ${msg}`
        )
      );
    }
  };

  const isNewView =
    get(selectedView, 'templateName') === NEW_VIEW_TEMPLATE_NAME ||
    !selectedView;
  const hasChanges =
    canEdit && (selectedView?.unsavedView || hasUnsavedChanges);
  const isViewFromCurrentProject =
    selectedView?.projectId === project?.projectId;

  const handleSaveView = ({ asDefault, isNewView, viewName }) => {
    if (!isNewView) {
      handleClickOverwriteView();
      return;
    }

    handleSaveNewView(viewName, asDefault);
  };

  return (
    <SaveViewButton
      onClick={handleSaveView}
      disabled={!hasChanges}
      hideOverwriteOption={isNewView || !isViewFromCurrentProject}
    />
  );
};

SaveTemplateButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dashboard: PropTypes.object.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired
};

export default SaveTemplateButton;
