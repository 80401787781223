import { useCallback, useMemo, useRef, useState } from 'react';
import randomstring from 'randomstring';

import { PanelType } from '@experiment-management-shared/types';

import useChartLayout from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartLayout';
import useChartZoomData from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartZoomData';
import useChartExport from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartExport';
import { usePanelResize } from '@/DesignSystem/hooks';

type UsePlotlyBaseChartParams = {
  revision?: number;
  onFinishRender?: unknown;
  isChartPreview?: boolean;
  orientation?: unknown;
  yAxisRange?: unknown;
  selectedXAxis?: unknown;
  title?: string;
  chartType: PanelType;
};

const usePlotlyBaseChart = ({
  revision,
  onFinishRender,
  isChartPreview,
  orientation,
  yAxisRange,
  selectedXAxis,
  title,
  chartType
}: UsePlotlyBaseChartParams) => {
  const containerRef = useRef<HTMLDivElement>();
  const plotRef = useRef();
  const [graphDiv, setGraphDiv] = useState(null);
  const [initialLayout, setInitialLayout] = useState(null);

  const {
    setCurrentLayout,
    currentLayout,
    chartBaseLayout,
    handleSaveLayout
  } = useChartLayout({
    selectedXAxis,
    yAxisRange: yAxisRange as never,
    orientation,
    initialLayout,
    setInitialLayout
  });

  const { disableResetZoom, handleResetZoom } = useChartZoomData({
    graphDiv,
    setCurrentLayout,
    initialLayout
  });

  const {
    setExportData,
    setExportLayout,
    handleExportData,
    handleExportJSON
  } = useChartExport({
    graphDiv,
    title,
    chartType
  });

  usePanelResize(plotRef, containerRef);

  const handleInitializeChart = useCallback((figure, newGraphDiv) => {
    setGraphDiv(newGraphDiv);
  }, []);

  const isPreviewCheck = isChartPreview ? Date.now() : null;
  const chartRevision = useMemo(() => {
    if (isPreviewCheck) return Date.now();

    return revision;
  }, [isPreviewCheck, revision]);

  const PlotlyProps = useMemo(() => {
    return {
      className: 'plotly-chart',
      divId: randomstring.generate(6),
      config: { displayModeBar: false },
      onInitialized: handleInitializeChart,
      onFinishRender,
      onUpdate: handleSaveLayout,
      ref: plotRef,
      revision: chartRevision,
      useResizeHandler: true
    };
  }, [chartRevision, handleInitializeChart, handleSaveLayout, onFinishRender]);

  return {
    setExportData,
    setExportLayout,
    setCurrentLayout,
    containerRef,
    handleExportJSON,
    handleExportData,
    handleResetZoom,
    disableResetZoom,
    currentLayout,
    chartBaseLayout,
    PlotlyProps
  };
};

export default usePlotlyBaseChart;
