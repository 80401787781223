import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { noop } from 'lodash';
import './ActiveStatusIcon.scss';
import ActiveStatusIconDialog from './ActiveStatusIconDialog';
import {
  DSCheckMarkIcon,
  DSCloseIcon,
  DSErrorIcon,
  DSRunningIcon
} from '@ds-icons';
import { IconButton, Tooltip } from '@ds';

const ActiveStatusIcon = ({
  success,
  throttle,
  crash,
  crashReason,
  loading,
  onClick,
  ...other
}) => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const iconRef = useRef();

  const handleOpenErrorDialog = () => {
    setIsErrorDialogOpen(true);
  };

  const renderIcon = () => {
    if (crash || crashReason) {
      return (
        <Tooltip
          content="Crashed experiment, click for detail"
          arrow
          placement="top"
        >
          <IconButton
            className="failed-experiment-icon icon-error cell-error "
            ref={iconRef}
            type="secondary"
            onClick={handleOpenErrorDialog}
            Icon={<DSCloseIcon />}
          />
        </Tooltip>
      );
    }

    if (throttle) {
      return (
        <div className="active-status-warning-icon">
          <Tooltip
            content={
              throttle && loading
                ? 'This experiment is being throttled. Please click to view details'
                : 'Throttled experiment, click for details'
            }
            placement="top"
          >
            <IconButton
              Icon={<DSErrorIcon />}
              onClick={onClick}
              type="secondary"
            />
          </Tooltip>
        </div>
      );
    }

    if (loading) {
      return (
        <Tooltip content="Experiment currently running" placement="top">
          <DSRunningIcon className="spin icon-cell" />
        </Tooltip>
      );
    }

    if (success) {
      return (
        <Tooltip content="Experiment finished running" placement="top">
          <DSCheckMarkIcon className="icon-cell icon-success" />
        </Tooltip>
      );
    }

    return null;
  };

  return (
    <>
      <ActiveStatusIconDialog
        ref={iconRef}
        onClose={() => setIsErrorDialogOpen(false)}
        open={isErrorDialogOpen}
        title="Failed Experiment"
        content={crashReason}
      />
      <div
        ref={iconRef}
        className="react-grid-table-icon status-icon"
        {...other}
      >
        {renderIcon()}
      </div>
    </>
  );
};

ActiveStatusIcon.defaultProps = {
  crash: false,
  crashReason: null,
  loading: false,
  success: false,
  throttle: false,
  onClick: noop
};

ActiveStatusIcon.propTypes = {
  crash: PropTypes.bool,
  crashReason: PropTypes.string,
  throttle: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  success: PropTypes.bool
};

export default ActiveStatusIcon;
