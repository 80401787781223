// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddSectionButton-module__button--e4eDu{display:flex;justify-content:center;padding:0 14px;margin:-10px 0;width:100%}.AddSectionButton-module__button--e4eDu>*{visibility:hidden}.AddSectionButton-module__button--e4eDu:hover>*{visibility:visible}.AddSectionButton-module__separator--XG8AS{border-bottom:1px solid #5055f5;border-top:1px solid #5055f5;margin:14px 16px;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `AddSectionButton-module__button--e4eDu`,
	"separator": `AddSectionButton-module__separator--XG8AS`
};
module.exports = ___CSS_LOADER_EXPORT___;
