import { OptionType } from '@design-system/types';
import React from 'react';
import cx from 'classnames';
import classes from '../Select.module.scss';

import { components, MultiValueGenericProps } from 'react-select';

const MultiValueLabel = (props: MultiValueGenericProps<OptionType>) => {
  const { data } = props;

  const badgeColor = data.badgeColor;

  return (
    <components.MultiValueLabel {...props}>
      {data?.badgeColor && (
        <div
          className={cx(classes.badgeColorContainer)}
          style={{ '--badge-color': badgeColor } as React.CSSProperties}
        />
      )}
      <div className={cx(classes.multiLabelContainer)}>{data.label}</div>
    </components.MultiValueLabel>
  );
};

export default MultiValueLabel;
