import { useEffect } from 'react';
import queryString from 'query-string';

import axios from 'axios';

import { axiosInstance } from '@API/api';
import sharedAxiosInstance from '@shared/api';

const useShareableURL = () => {
  const { shareable } = queryString.parse(window.location.search, {
    parseBooleans: true
  });

  useEffect(() => {
    if (!shareable) return;

    // @todo: remove once everything is migrated to react-query
    axios.defaults.params = {};
    axios.defaults.params.share = shareable;
    axiosInstance.defaults.params = {
      share: shareable
    };
    sharedAxiosInstance.defaults.params = {
      share: shareable
    };
  }, [shareable]);
};

export default useShareableURL;
