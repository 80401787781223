import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Table } from '@devexpress/dx-react-grid-material-ui';

import StyledCheckbox from '@shared/components/StyledComponents/StyledCheckbox';

const SelectionCell = ({
  className,
  dataId,
  lastToggledExperimentKey,
  onToggle,
  selected: controlledSelected,
  row,
  ...restProps
}) => {
  const [selected, setSelected] = useState(controlledSelected);
  const { experimentKey } = row;

  useEffect(() => {
    setSelected(controlledSelected);
  }, [controlledSelected]);

  const handleChange = event => {
    setSelected(event.target.checked);

    onToggle(event);
  };

  const testId = `experiment-selection-cell_${experimentKey}`;

  return (
    <Table.Cell
      {...restProps}
      className={cx('react-grid-selection-cell', className)}
    >
      <StyledCheckbox
        checked={selected}
        onChange={handleChange}
        data-test={testId}
        style={{
          padding: 0,
          backgroundColor:
            experimentKey === lastToggledExperimentKey
              ? 'rgba(0, 0, 0, 0.10)'
              : ''
        }}
        inputProps={{ 'data-id': dataId }}
      />
    </Table.Cell>
  );
};

SelectionCell.propTypes = {
  onToggle: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired
};

export default SelectionCell;
