import useVideoSearch from '@API/panels/useVideoSearch';
import useVideoSteps from '@API/panels/useVideoSteps';
import { MultipleAutocomplete } from '@DesignSystem/controllers';
import { Slider } from '@design-system-outdated/components';
import {
  FILTERING_MODE,
  NO_ITEMS_STRATEGY
} from '@DesignSystem/controllers/MultipleAutocomplete/MultipleAutocomplete';
import { InputLabel } from '@material-ui/core';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CHIP_TYPE_BADGE } from '@DesignSystem/data-display/Chip/Chip';

import { VideoThumbnail } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/VideoPanel/VideoThumbnail';
import {
  BUILT_IN_CHART_TYPES,
  VIDEO_PANEL_DEFAULT_STEP
} from '@experiment-management-shared/constants/chartConstants';
import { SearchIcon } from '@Icons-outdated';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { useDebouncedFilter } from '@shared/hooks';

import { getChartFormByType } from '@/reducers/dashboardChartsReducer';

const DELAYS_MS = 250;
const MIN_EXPERIMENT_COUNT = 1;
const MAX_EXPERIMENT_COUNT = 25;

const VideoPanelDataTab = ({ experimentKeys, hiddenExperimentKeys }) => {
  const dispatch = useDispatch();
  const videoPanelForm = useSelector(state =>
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.video })
  );

  const [videosValue, setVideosValue] = useState(videoPanelForm.videos);
  const [stepValue, setStepValue] = useState(0);
  const [experimentsCountValue, setExperimentsCountValue] = useState(
    videoPanelForm.experimentsCount
  );

  const {
    debouncedFilterValue: autocompleteDebouncedFilterValue,
    setFilterValue: setAutocompleteFilterValue,
    filterValue: autocompleteFilterValue
  } = useDebouncedFilter('');

  const targetExperimentKeys = useMemo(() => {
    let retVal = [...experimentKeys];

    if (isArray(hiddenExperimentKeys)) {
      retVal = retVal.filter(key => !hiddenExperimentKeys.includes(key));
    }

    if (retVal.length > videoPanelForm?.experimentsCount) {
      retVal.length = videoPanelForm?.experimentsCount;
    }

    return retVal;
  }, [experimentKeys, hiddenExperimentKeys, videoPanelForm?.experimentsCount]);

  const {
    data: searchData = [],
    isLoading: isLoadingSearchData
  } = useVideoSearch({
    experimentKeys: targetExperimentKeys,
    searchPhrase: autocompleteDebouncedFilterValue,
    maxResults: 100
  });

  const { data: stepsData = [], isLoading: isLoadingStepsData } = useVideoSteps(
    {
      experimentKeys: targetExperimentKeys,
      videos: videosValue
    },
    {
      keepPreviousData: true,
      refetchOnMount: true
    }
  );

  const stepsArray = useMemo(() => {
    const sortedSteps = (stepsData?.data?.steps || []).slice();

    // in case we don't have any step available we need to add 0 because it is treated as default value
    if (!sortedSteps.length) {
      sortedSteps.push(VIDEO_PANEL_DEFAULT_STEP);
    }

    // edge case: if array steps includes null and 0 remove null as it's the same as 0
    if (sortedSteps.includes(0) && sortedSteps.includes(null)) {
      const filtered = sortedSteps.filter(e => e !== null);
      return filtered;
    }

    return sortedSteps.sort((s1, s2) => s1 - s2);
  }, [stepsData]);

  useEffect(() => {
    if (isLoadingStepsData) {
      return;
    }

    // check if saved value available for current images and experiments
    const localStepInvalid = !stepsArray.includes(videoPanelForm?.step);

    if (localStepInvalid) {
      // Select the first available option from list
      setStepValue(0);
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.video,
          'step',
          stepsArray[0]
        )
      );
    } else {
      // invalidate stepValue base on saved data in redux
      const index = stepsArray.indexOf(videoPanelForm.step);

      if (index !== -1 && index !== stepValue) {
        setStepValue(index);
      }
    }
    // stepValue ignored in purpose
  }, [stepsArray, videoPanelForm.step]);

  useEffect(() => {
    const isFormErased =
      videoPanelForm.videos.length === 0 && videosValue.length > 0;
    if (!isFormErased) return;

    setVideosValue([]);
  }, [videoPanelForm.videos.length, videosValue.length]);

  const options = useMemo(() => {
    const retVal = (searchData?.data?.videos || []).map(video => ({
      value: video.userFileName,
      video
    }));

    return retVal.filter(o => !videosValue.includes(o.value));
  }, [searchData, videosValue]);

  const handleChangeVideosValue = value => {
    setVideosValue(value);

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.video,
        'videos',
        value
      )
    );
  };

  const handleDebouncedChangeStep = useCallback(
    debounce(
      step =>
        dispatch(
          dashboardChartsActions.updateChartFormKey(
            BUILT_IN_CHART_TYPES.video,
            'step',
            step
          )
        ),
      DELAYS_MS
    ),
    []
  );

  const handleChangeStep = index => {
    setStepValue(index);
    handleDebouncedChangeStep(stepsArray[index]);
  };

  const handleDebouncedChangeExperimentsCount = useCallback(
    debounce(
      count =>
        dispatch(
          dashboardChartsActions.updateChartFormKey(
            BUILT_IN_CHART_TYPES.video,
            'experimentsCount',
            count
          )
        ),
      DELAYS_MS
    ),
    []
  );

  const handleChangeExperimentsCount = count => {
    setExperimentsCountValue(count);
    handleDebouncedChangeExperimentsCount(count);
  };

  const renderOption = option => {
    const video = option.video || {};
    const thumbnailPath = video?.thumbnailPath;

    return (
      <>
        <div className="ds-dropdown-popper-item-content">
          <VideoThumbnail name={option.value} src={thumbnailPath} />
        </div>
        <div className="ds-dropdown-popper-label">{video?.userFileName}</div>
      </>
    );
  };

  const checkValueToAdd = value =>
    options.find(o => o.value === value) && !videosValue.includes(value);

  const renderImagesAutocomplete = () => {
    const isInputDisabled = videosValue.length >= 10;
    const noItemsStrategy =
      autocompleteDebouncedFilterValue.length >= 3 && options.length === 0
        ? NO_ITEMS_STRATEGY.SHOW_MESSAGE
        : NO_ITEMS_STRATEGY.NO_MESSAGE;

    return (
      <MultipleAutocomplete
        noValuePlaceholder="Enter at least 3 characters to begin search"
        withValuePlaceholder="Select more videos"
        label="Video name"
        description="The video names will be presented as a row, you can move the badges to change video order. Select up to 10 videos."
        options={options}
        value={videosValue}
        onChangeValue={handleChangeVideosValue}
        filterValue={autocompleteFilterValue}
        onChangeFilterValue={setAutocompleteFilterValue}
        renderListItem={renderOption}
        checkValueToAdd={checkValueToAdd}
        disabled={isInputDisabled}
        classes="grid graphics-panel-data-tab-autocomplete"
        loading={isLoadingSearchData}
        sortable
        chipType={CHIP_TYPE_BADGE}
        filterMode={FILTERING_MODE.NONE}
        SuffixIcon={<SearchIcon />}
        noItemsStrategy={noItemsStrategy}
        maxValuesLimit={10}
      />
    );
  };

  const stepSliderLabelFormat = value => {
    const lastStep = last(stepsArray) || VIDEO_PANEL_DEFAULT_STEP;
    const currentStep = stepsArray[value] || VIDEO_PANEL_DEFAULT_STEP;

    return isNumber(lastStep) && isNumber(currentStep)
      ? `${currentStep}/${lastStep}`
      : '';
  };

  const renderStepsSlider = () => {
    // workaround to recreate slider component in case we have only one possible value, it will force mark to be rendered from the beginning of component
    const key = stepsArray.length === 1 ? 'one-value' : 'multiple-values';

    return (
      <div
        className="modal-input-group"
        style={{ marginBottom: 60, marginTop: 60 }}
      >
        <InputLabel className="modal-input-label">Step</InputLabel>
        <div className="slider-wrapper">
          <Slider
            key={key}
            disabled={isLoadingStepsData}
            min={0}
            max={stepsArray.length - 1}
            step={1}
            valueLabelFormat={value => stepsArray[value] || 0}
            sliderSuffixFormat={stepSliderLabelFormat}
            value={stepValue}
            onChange={handleChangeStep}
          />
        </div>
      </div>
    );
  };

  const renderExperimentsSlider = () => (
    <div className="modal-input-group" style={{ marginBottom: 60 }}>
      <InputLabel className="modal-input-label">
        Max number of experiments to show
      </InputLabel>
      <div className="slider-wrapper">
        <Slider
          value={experimentsCountValue}
          min={MIN_EXPERIMENT_COUNT}
          max={MAX_EXPERIMENT_COUNT}
          step={1}
          valueLabelFormat={value => value}
          onChange={handleChangeExperimentsCount}
        />
      </div>
    </div>
  );

  return (
    <div className="image-video-panel-data-tab">
      {renderImagesAutocomplete()}
      {renderStepsSlider()}
      {renderExperimentsSlider()}
    </div>
  );
};

VideoPanelDataTab.defaultProps = {
  experimentKeys: [],
  hiddenExperimentKeys: []
};

VideoPanelDataTab.propTypes = {
  experimentKeys: PropTypes.array,
  hiddenExperimentKeys: PropTypes.array
};

export default VideoPanelDataTab;
