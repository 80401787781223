import moment from 'moment';
import fileSizeFormatter from 'filesize';
import keymirror from 'keymirror';

import last from 'lodash/last';
import { ROOT_DIR } from '@experiment-management-shared/constants/fileBrowser';

const yesterday = moment().subtract(1, 'day');

const isPastDay = date => {
  return moment(date).isAfter(yesterday, 'day');
};

export const formatDate = date => {
  if (isPastDay(date)) {
    return moment(date).fromNow();
  }

  return moment(date).format('M/D hh:mm A');
};

export const subTreeTemplate = (
  relativePath,
  dir,
  level,
  parentRelativePath
) => ({
  files: [],
  dirs: {},
  relativePath,
  dir,
  level,
  parentRelativePath,
  id: relativePath
});

export const getDirInSubTree = (subTree, name) => subTree.dirs[name];

export const getFileLevel = (relativePath, dirLevel) =>
  relativePath === ROOT_DIR ? 0 : dirLevel;

export const processDir = _dir => {
  const dir = removeLastSlashFromPath(_dir);
  const dirArr = dir.split('/');

  return { dir, dirArr };
};

export const removeLastSlashFromPath = path => {
  let end = path.length;

  if (end === 1) return path;
  const start = 0;

  if (path[end - 1] === '/') end -= 1;

  path = path.substring(start, end);

  return path;
};

export const formatFileSize = fileSize => {
  return fileSize >= 0 ? fileSizeFormatter(fileSize) : null;
};

export const getFileExtension = (fullFileName = '') => {
  if (!fullFileName) return '';
  const fileName = last(fullFileName.split('/'));

  return last(fileName.split('.'))?.toLowerCase();
};

export const removeFileExtension = fileName => {
  if (!fileName) return '';
  return fileName.replace(/\.[^/.]+$/, '');
};

export const LANGUAGE_BY_EXTENSION = {
  html: 'html',
  js: 'javascript',
  json: 'json',
  md: 'markdown',
  MLmodel: 'yaml',
  MLproject: 'yaml',
  py: 'python',
  R: 'R',
  rst: 'rust',
  yaml: 'yaml',
  yml: 'yaml'
};

export const FILE_EXTENSIONS = keymirror({
  csv: null,
  html: null,
  js: null,
  json: null,
  log: null,
  md: null,
  MLmodel: null,
  MLproject: null,
  py: null,
  rst: null,
  tsv: null,
  txt: null,
  yaml: null,
  yml: null
});

export const TEXT_EXTENSIONS = [
  'txt',
  'log',
  'yaml',
  'yml',
  'json',
  'js',
  'py',
  'csv',
  'tsv',
  'md',
  'rst',
  'MLmodel',
  'MLproject'
];

export const IMAGE_EXTENSIONS = [
  '3ds',
  '3dm',
  'max',
  'bmp',
  'dds',
  'gif',
  'jpg',
  'jpeg',
  'png',
  'psd',
  'xcf',
  'tga',
  'thm',
  'tif',
  'tiff',
  'yuv',
  'ai',
  'eps',
  'ps',
  'svg',
  'dwg',
  'dxf',
  'gpx',
  'kml',
  'kmz'
];

export const AUDIO_EXTENSIONS = [
  'aac',
  'mid',
  'midi',
  'mp3',
  'oga',
  'wav',
  'weba',
  '3gp',
  '3g2'
];

export const VIDEO_EXTENSIONS = [
  'gif',
  'mp4',
  'mov',
  'mpv',
  'mpeg',
  'ogg',
  'ogv',
  'webm',
  'mpg',
  'avi'
];

export const getLanguageByFileName = fileName => {
  const fileExtension = getFileExtension(fileName);

  return LANGUAGE_BY_EXTENSION[fileExtension] || 'plaintext';
};

export const hasTextExtension = fileName => {
  const fileExtension = getFileExtension(fileName);

  return TEXT_EXTENSIONS.includes(fileExtension);
};

export const canShowPreviewInLegend = ({ fileName, fileSize }) => {
  // If it's a text extension and the size is less than 100KB
  return hasTextExtension(fileName) && fileSize < 1024 * 100;
};

export const canShowPreviewInModal = ({ fileName, fileSize }) => {
  // If it's a text extension and the size is less than 1MB
  return hasTextExtension(fileName) && fileSize < 1024 * 1024;
};
