import { GenericActionsCell } from '@DesignSystem/generic-table-cells/ActionsCell/ActionsCell';
import { hideApiKeyPart } from '@shared/utils/apiKeys';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyButton } from '@design-system-outdated';

import './ServiceAccounts.scss';

const DateCell = ({ value }) => moment(value).format('DD MMM YYYY');

const APIKeyCell = ({ value }) => {
  return (
    <CopyToClipboard text={value}>
      <div className="customization-api-key-cell">
        {hideApiKeyPart(value)}
        <div className="copy-icon-wrapper">
          <CopyButton
            text={value}
            message="Successfully copied API key to clipboard"
            tooltipText="Copy API key"
          />
        </div>
      </div>
    </CopyToClipboard>
  );
};

const NameCell = ({ value }) => (
  <span className="customization-table-name-cell">{value}</span>
);

const actionOptions = [
  {
    label: 'Regenerate API key',
    value: 'regenerate'
  },
  {
    label: 'Delete',
    value: 'delete'
  }
];

const ActionsCell = ({ onRegenerateClick, onDeleteClick }) => {
  const handleClickOption = value => {
    if (value === actionOptions[0].value) {
      return onRegenerateClick();
    }

    return onDeleteClick();
  };

  return (
    <GenericActionsCell options={actionOptions} onClick={handleClickOption} />
  );
};

ActionsCell.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  onRegenerateClick: PropTypes.func.isRequired
};

const cellPropTypes = {
  value: PropTypes.string.isRequired
};

NameCell.propTypes = cellPropTypes;
APIKeyCell.propTypes = cellPropTypes;

const CustomizationTableCells = {
  APIKeyCell,
  ActionsCell,
  DateCell,
  NameCell
};

export default CustomizationTableCells;
