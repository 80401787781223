import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

type AllFeaturesProps = {
  modelId: string;
  modelVersion: string | null;
};

const getFeatures = ({
  modelId,
  workspaceId,
  modelVersion
}: AllFeaturesProps & { workspaceId: string }) =>
  api.get(`/mpm/${workspaceId}/models/${modelId}/features`, {
    modelVersion
  });

export function useAllFeatures({ modelVersion, modelId }: AllFeaturesProps) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    [
      'all-model-features',
      { workspaceId: activeWorkspace?.id, modelVersion, modelId }
    ],
    () =>
      getFeatures({ workspaceId: activeWorkspace?.id, modelVersion, modelId }),
    {
      enabled: !!activeWorkspace?.id
    }
  );
}
