// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.APIKeyInput-module__APIInputContainer--__lm4{display:flex;align-items:center;position:relative}.APIKeyInput-module__APIInputContainer--__lm4 p.ds-input-title{margin-bottom:12px}.APIKeyInput-module__APIInputContainer--__lm4 svg{color:#8c95a8}.APIKeyInput-module__APIInputContainer--__lm4 .ds-input-wrapper{min-width:230px}.APIKeyInput-module__APIInputContainerHidden--OyS5x .ds-input input{font-style:normal;font-weight:normal;speak:none;font-variant:normal;text-transform:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;letter-spacing:3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"APIInputContainer": `APIKeyInput-module__APIInputContainer--__lm4`,
	"APIInputContainerHidden": `APIKeyInput-module__APIInputContainerHidden--OyS5x`
};
module.exports = ___CSS_LOADER_EXPORT___;
