import React from 'react';
import { ExperimentDetails } from '@shared/types';
import { Table } from '@DesignSystem/tables';
import useSystemMetricsComparisonTableColumnWidths from './hooks/useSystemMetricsComparisonTableColumnWidths';
import {
  SYSTEM_METRICS_COMPARISON_COLUMNS_NAME,
  SYSTEM_METRICS_COMPARISON_TABLE_COLUMNS
} from './systemMetricsComparisonConfig';
import SystemMetricSingleTableCell from '../SystemMetricsSingleTable/cells/SystemMetricSingleTableCell';
import useSystemMetricsComparisonTableData from './hooks/useSystemMetricsComparisonTableData';
import { SystemMetrics } from '@shared/types/systemMetrics';

import styles from './SystemMetricsComparisonTable.module.scss';
import ComparisonTableKeyCell from '@experiment-details/components/shared/ComparisonTable/cells/ComparisonTableKeyCell';
import ComparisonTableExperimentCell from '@experiment-details/components/shared/ComparisonTable/cells/ComparisonTableExperimentCell';
import {
  getCellClassNameComparisonTable,
  getCellRowspanComparisonTable,
  getRowClassNameComparisonTable
} from '@experiment-details/components/shared/ComparisonTable/utils';
import useSystemMetricsComparisonTableSorting from '../hooks/useSystemMetricsTableSorting';
import SystemMetricsEmptyTable from '../SystemMetricsEmptyTable';

const dataTypes = [
  {
    cols: [SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.KEY],
    cell: ComparisonTableKeyCell
  },
  {
    cols: [SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.EXPERIMENT],
    cell: ComparisonTableExperimentCell
  },
  {
    cols: [SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.VALUE],
    cell: SystemMetricSingleTableCell
  }
];

type SystemMetricsComparisonTableProps = {
  systemMetrics: SystemMetrics[];
  experiments: ExperimentDetails[];
  isLoading: boolean;
};
const SystemMetricsComparisonTable: React.FC<SystemMetricsComparisonTableProps> = ({
  systemMetrics,
  experiments,
  isLoading
}) => {
  const {
    columnWidths,
    onColumnWidthsChange
  } = useSystemMetricsComparisonTableColumnWidths();
  const rowList = useSystemMetricsComparisonTableData({
    experiments,
    systemMetrics
  });
  const sortingConfig = useSystemMetricsComparisonTableSorting();

  return (
    <div className={styles.systemMetricsComparisonTable}>
      <Table
        dataTest="system-metrics-comparison-table"
        dataTypes={dataTypes}
        columns={SYSTEM_METRICS_COMPARISON_TABLE_COLUMNS}
        rows={rowList}
        rowIdKey="rowId"
        height="auto"
        isFetching={isLoading}
        paginationConfig={{
          isDisabled: true
        }}
        columnOrderConfig={{
          isDisabled: true
        }}
        selectionConfig={{
          isDisabled: true
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          onColumnWidthsChange
        }}
        sortingConfig={sortingConfig}
        getCellClassName={getCellClassNameComparisonTable}
        getCellRowspan={getCellRowspanComparisonTable(experiments.length)}
        getRowClassName={getRowClassNameComparisonTable}
        renderEmptyState={SystemMetricsEmptyTable}
      />
    </div>
  );
};

export default SystemMetricsComparisonTable;
