import useAllUserWorkspaces from './useAllUserWorkspaces';
import { Workspace } from '@shared/types';

const useDefaultWorkspace = (): Workspace | null => {
  const { data: workspaces } = useAllUserWorkspaces();

  return (
    workspaces?.find(workspace => workspace?.isDefault) ||
    (workspaces?.length ? workspaces[0] : null)
  );
};

export default useDefaultWorkspace;
