import React from 'react';

import { BottomActionsPanel } from '@DesignSystem/bottom-actions-panel';
import { DismissSelectionButton } from '@DesignSystem/bottom-actions-panel/panel-buttons';
import { useDismissAlertNotification } from '@mpm/api';

type AlertNotificationsActionsProps = {
  selectedItemsIds: string[];
  openStatusNotifications: string[];
  dismissCallback: (notificationIds: string[]) => void;
};

const AlertNotificationsActions = ({
  selectedItemsIds,
  openStatusNotifications,
  dismissCallback
}: AlertNotificationsActionsProps) => {
  const dismissAlertNotification = useDismissAlertNotification();
  const enabledDismissBtn = openStatusNotifications?.length > 0;

  const handleDismissClick = () => {
    dismissAlertNotification.mutate({
      notificationIds: openStatusNotifications,
      dismissCallback
    });
  };
  return (
    <BottomActionsPanel
      selectedItemsTotal={selectedItemsIds.length}
      absolute
      labelItems="notifications"
    >
      <DismissSelectionButton
        dismissHandler={handleDismissClick}
        disabled={!enabledDismissBtn}
      />
    </BottomActionsPanel>
  );
};

export default AlertNotificationsActions;
