import { useMemo } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import { PANEL_SOURCES } from '@experiment-management-shared/constants/visualizationConstants';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';

const useCurrentPanelSource = () => {
  const location = useLocation();

  const currentSource = useMemo(() => {
    const IS_COMPARISON = location?.pathname?.includes('/compare');
    const { chartCategory, chartType } = queryString.parse(location?.hash);
    const { 'experiment-tab': experimentTab } = queryString.parse(
      location?.search
    );
    const IS_THE_EXPERIMENT_LEVEL = !!experimentTab;

    if (IS_COMPARISON && IS_THE_EXPERIMENT_LEVEL) {
      return PANEL_SOURCES.DIFF;
    }

    if (IS_THE_EXPERIMENT_LEVEL) {
      if (
        experimentTab === DETAIL_VIEWS.IMAGES &&
        chartCategory === 'built-in' &&
        [
          BUILT_IN_CHART_TYPES.image,
          BUILT_IN_CHART_TYPES.video,
          BUILT_IN_CHART_TYPES.pcd
        ].includes(chartType)
      ) {
        return PANEL_SOURCES.SINGLE_EXPERIMENT_GRAPHICS_TAB;
      }

      return PANEL_SOURCES.SINGLE_EXPERIMENT;
    }

    const IS_REPORTS = location?.pathname?.includes('/reports');

    if (IS_REPORTS) {
      return PANEL_SOURCES.REPORTS;
    }

    return PANEL_SOURCES.PROJECT;
  }, [location?.search, location?.pathname]);

  return currentSource;
};

export default useCurrentPanelSource;
