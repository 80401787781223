import { Box } from '@material-ui/core';
import { TextButton, Button } from '@ds';
import { isNull } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';

import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import { getURLQueryParams } from '@shared/utils/url';

import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import { DSArrowLeftIcon } from '@ds-icons';

function PanelModalFooter({ handleSubmit, handleReset, hideGoBackButton }) {
  const { hash } = useLocation();
  const history = useHistory();
  const queryParams = getURLQueryParams(hash);
  const chartId = queryParams.get('chartId');
  const location = useLocation();
  const isEditMode = !isNull(chartId);
  const chartType = queryParams.get('chartType');
  const { isRequiredFieldsEmptyForChartType } = chartHelpers;

  const handleCloseModal = () => {
    history.push({ ...location, hash: '' }, { preventScroll: true });
  };

  const handleCancel = () => {
    if (isEditMode) {
      handleCloseModal();
    } else {
      handleReset();
      history.push({ ...location, hash: `#${SELECT_PANEL_PATH}` });
    }
  };

  const chartFormByType = useSelector(state =>
    getChartFormByType(state, { chartType })
  );

  return (
    <div className="gallery-modal-footer">
      {hideGoBackButton ? (
        <div />
      ) : (
        <TextButton
          onClick={handleCancel}
          PrefixIcon={<DSArrowLeftIcon />}
          size="large"
        >
          Back
        </TextButton>
      )}
      <Box display="flex">
        <TextButton
          size="large"
          onClick={handleReset}
          className="add-modal-reset-button"
        >
          Reset
        </TextButton>
        <Button
          disabled={isRequiredFieldsEmptyForChartType(
            chartType,
            chartFormByType
          )}
          size="large"
          onClick={handleSubmit}
        >
          Done
        </Button>
      </Box>
    </div>
  );
}

PanelModalFooter.defaultProps = {
  hideGoBackButton: false
};

PanelModalFooter.propTypes = {
  hideGoBackButton: PropTypes.bool,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.any.isRequired
};

export default PanelModalFooter;
