import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { Button, TextButton } from '@ds';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { useCurrentOrganization } from '@shared/hooks';

import {
  UPGRADE_MODAL_STEP_AMOUNT,
  INITIAL_OPEN_UPGRADE_CARD
} from '@account-settings/constants';

const UpgradeToInvite = ({ open, onClose }) => {
  const history = useHistory();
  const currentOrganization = useCurrentOrganization();

  const redirectToBilling = () => {
    history.push(
      `/organizations/${currentOrganization?.id}/billing?${INITIAL_OPEN_UPGRADE_CARD}=true&${UPGRADE_MODAL_STEP_AMOUNT}=3`
    );
  };

  const renderUpgradeToInvite = () => (
    <div className="upgrade-to-invite-modal">
      <div className="upgrade-to-invite-picture" />
      <div className="upgrade-to-invite-label">
        In order to invite teammates to your workspace please upgrade your
        payment plan
      </div>
      <Button
        size="large"
        className="continue-to-billing-btn"
        onClick={redirectToBilling}
      >
        Continue to plan selection
      </Button>
      <TextButton
        size="large"
        onClick={onClose}
        className="billing-cancel-button"
      >
        Cancel
      </TextButton>
    </div>
  );

  return (
    <FullWidthBasicModal
      title="Upgrade to invite teammates"
      withoutFooter
      content={renderUpgradeToInvite()}
      open={open}
      onClose={onClose}
    />
  );
};

UpgradeToInvite.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

UpgradeToInvite.defaultProps = {
  open: false,
  onClose: noop
};

export default UpgradeToInvite;
