import React from 'react';
import PropTypes from 'prop-types';

import './Badge.scss';

const Badge = ({ label }) => {
  return <div className="ds-badge">{label}</div>;
};

Badge.propTypes = {
  label: PropTypes.string.isRequired
};

export default Badge;
