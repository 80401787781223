// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenuWorkspaces-module__workspaceSelectorContainer--Tkeoq{translate:-2px 8px;box-shadow:-5px 8px 12px rgba(0,0,0,.12),0px 0px 1px rgba(0,0,0,.14)}div:has(svg.UserMenuWorkspaces-module__workspaceItemDefaultIcon--xmb0y)>svg.UserMenuWorkspaces-module__workspaceItemDefaultIcon--xmb0y{color:#00d41f;width:14px;height:14px}.UserMenuWorkspaces-module__workspaceSelectorType--alsSw{padding-right:4px;font-weight:500}.UserMenuWorkspaces-module__workspaceItemNameActive--NsDFW{font-weight:500}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workspaceSelectorContainer": `UserMenuWorkspaces-module__workspaceSelectorContainer--Tkeoq`,
	"workspaceItemDefaultIcon": `UserMenuWorkspaces-module__workspaceItemDefaultIcon--xmb0y`,
	"workspaceSelectorType": `UserMenuWorkspaces-module__workspaceSelectorType--alsSw`,
	"workspaceItemNameActive": `UserMenuWorkspaces-module__workspaceItemNameActive--NsDFW`
};
module.exports = ___CSS_LOADER_EXPORT___;
