import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

// this hook must be wrapped by the <Elements stripe={StripeService.stripePromise}> </Elements> component
const useAppStripe = () => {
  const elements = useElements();
  const stripe = useStripe();

  const getToken = async ({ address, city, state, postalCode, country }) => {
    const { token, error } = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        address_line1: address,
        address_city: city,
        address_country: country,
        address_zip: postalCode,
        address_state: state
      }
    );

    return { token, error };
  };

  return { getToken };
};

export default useAppStripe;
