import React from 'react';
import cx from 'classnames';
import { unix } from 'moment';
import noop from 'lodash/noop';

import { DEBUGGER_LABELS_COLORS, DEBUGGER_LABELS } from '@mpm-druid/constants';
import { ActiveDebuggerButtonType, DebuggerEventType } from '@mpm-druid/types';
import classNames from './DebuggerPage.module.scss';

type DebuggerEventProps = {
  activeButton: ActiveDebuggerButtonType;
  eventData: DebuggerEventType;
  timestamp?: number;
  isActive?: boolean;
  clickEventHandler?: React.Dispatch<
    React.SetStateAction<DebuggerEventType | null>
  >;
  key?: string | number;
};

const DebuggerEvent = ({
  activeButton,
  timestamp = 0,
  isActive = false,
  eventData,
  clickEventHandler = noop
}: DebuggerEventProps) => {
  return (
    <div
      data-test="debugger-event"
      className={cx(
        classNames.debuggerEventWrapper,
        isActive && classNames.activeEvent
      )}
      onClick={() => clickEventHandler(eventData)}
    >
      <span
        style={{ backgroundColor: DEBUGGER_LABELS_COLORS[activeButton] }}
        className={classNames.debuggerEventIndicator}
      />
      <span>{DEBUGGER_LABELS[activeButton]}</span>
      <span className={classNames.debuggerEventDate}>
        {timestamp && (
          <>
            <span className={classNames.debuggerEventDateDays}>{`${unix(
              timestamp / 1000
            ).format('DD/MM/YYYY')} `}</span>
            <span>{unix(timestamp / 1000).format('HH:mm:ss')}</span>
          </>
        )}
      </span>
    </div>
  );
};

export default DebuggerEvent;
