import { useEffect, useState } from 'react';

export function useTableHeight({ maxHeight, rowHeight, rows, currentPage }) {
  const [height, setHeight] = useState(0);
  const [isResizing, setIsResizing] = useState(false);

  const rowsHeight = rows.length * parseInt(rowHeight, 10);
  const givenHeight = document.body.clientHeight - maxHeight;

  useEffect(() => {
    window.addEventListener('resize', () => setIsResizing(prev => !prev));
    return () => {
      window.removeEventListener('resize', () => setIsResizing(prev => !prev));
    };
  }, []);

  useEffect(() => {
    setHeight(rowsHeight > givenHeight ? givenHeight : null);
  }, [currentPage, rowsHeight, givenHeight, isResizing]);

  return height;
}
