import { useQuery, UseQueryResult } from 'react-query';

import api from '@API/api';
import { Monitor } from '@mpm-druid/types';

type AlertRulesParams = {
  modelId: string;
  page: number;
  pageSize: number;
};

type ConfigProps = {
  keepPreviousData: boolean;
  refetchOnMount: boolean;
};

type AlertRulesResponse = {
  alertRules: Monitor[];
  total: number;
};

const getAlertRules = ({ modelId, page, pageSize }: AlertRulesParams) =>
  api.get(
    `/mpm/v2/monitors?modelId=${modelId}&page=${page}&pageSize=${pageSize}`
  );

export function useAlertRules(
  params: AlertRulesParams,
  config: ConfigProps
): UseQueryResult<AlertRulesResponse> {
  return useQuery(
    ['alert-rules', { ...params }],
    () => getAlertRules({ ...params }),
    {
      ...config,
      enabled: !!params.page && !!params.pageSize,
      retry: false,
      select: data => {
        return {
          ...data,
          alertRules: data.data.map((alertRule: Monitor) => {
            return {
              id: alertRule.id,
              name: alertRule.name,
              openNotifications: alertRule.numMonitorNotificationsOpened,
              createdAt: alertRule.createdAt,
              modelVersion: alertRule.modelVersion,
              intervalType: alertRule.runCronExpression,
              createdBy: alertRule.createdBy,
              description: alertRule.description
            };
          })
        };
      }
    }
  );
}
