import React, { useState } from 'react';
import moment from 'moment';
import currency from 'currency.js';

import { Button } from '@ds';

import { Divider, StyledTooltip } from '@DesignSystem/data-display';
import { SimpleCard } from '@DesignSystem/surfaces';
import { InfoIcon, PaymentMethodIcon } from '@Icons-outdated';
import { FREE_PRICE, PAYMENT_PLANS } from '@shared/constants';
import UpdateCardDetailModal from './UpdateCardDetailModal';
import SmallLoader from '@shared/components/SmallLoader';
import { useActiveOrganizationPaymentDetails } from '@account-settings/api';
import { useCurrentOrganization, useCurrentPaymentPlan } from '@shared/hooks';

export default function PaymentDetails() {
  const [updateCardOpen, setUpdateCardOpen] = useState(false);
  const {
    data: billingDetails,
    isLoading
  } = useActiveOrganizationPaymentDetails();
  const openModal = () => setUpdateCardOpen(true);
  const closeModal = () => setUpdateCardOpen(false);

  const isTherePaymentMethod = !!billingDetails?.creditCard?.id;
  const currentOrganization = useCurrentOrganization();
  const { paymentPlanName: paymentPlan, isWithHours } = useCurrentPaymentPlan();
  const activePaymentPlan = PAYMENT_PLANS[paymentPlan];
  const nextPrice = activePaymentPlan?.price;

  const formatBillingDate = () =>
    (isTherePaymentMethod || billingDetails?.nextPaymentDate) &&
    moment(billingDetails?.nextPaymentDate).isValid() ? (
      moment(billingDetails?.nextPaymentDate).format('MM-DD-YYYY')
    ) : (
      <div className="no-date-data" />
    );

  const formatExpDate = () =>
    `${
      billingDetails?.creditCard?.expMonth
    } / ${billingDetails?.creditCard?.expYear?.toString()?.slice(2)}`;

  const renderPaymentMethod = () => {
    if (!isTherePaymentMethod) {
      return (
        <div className="no-payments">
          <PaymentMethodIcon />
          <div className="no-payment-label">
            {paymentPlan === FREE_PRICE
              ? 'No payment card'
              : 'No payment for free plan'}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="details-wrapper ">
          <p>Card number</p>
          <p className="card-number-label">
            **** **** **** {billingDetails?.creditCard?.last4}
          </p>
        </div>
        <Divider orientation="vertical" />
        <div className="details-wrapper">
          <p>Expiration date</p>
          <p>{formatExpDate()}</p>
        </div>
        <div className="details-wrapper">
          <Button onClick={openModal} size="large">
            Change card
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <UpdateCardDetailModal
        open={updateCardOpen}
        onClose={closeModal}
        organizationId={currentOrganization?.id}
      />

      {!isLoading ? (
        <div className="payment-details-container">
          <h4>Upcoming billing</h4>
          <SimpleCard>
            <div className="overview-card">
              <div className="details-wrapper">
                <p>Billing date </p>
                <p>{formatBillingDate()}</p>
              </div>
              <Divider orientation="vertical" />
              <div className="details-wrapper">
                <p>
                  Seat count
                  {(isTherePaymentMethod || billingDetails?.seatsCount) && (
                    <StyledTooltip title={`${nextPrice}$ per user a month`}>
                      <div className="info-icon-container">
                        <InfoIcon />
                      </div>
                    </StyledTooltip>
                  )}
                </p>
                <p>{billingDetails?.seatsCount || 0}</p>
              </div>
              {isWithHours && (
                <>
                  <Divider orientation="vertical" />
                  <div className="details-wrapper">
                    <p>
                      Hours used
                      {(isTherePaymentMethod ||
                        activePaymentPlan?.hourPrice) && (
                        <StyledTooltip
                          title={`${activePaymentPlan?.hourPrice}$ per traning hour`}
                        >
                          <div className="info-icon-container">
                            <InfoIcon />
                          </div>
                        </StyledTooltip>
                      )}
                    </p>
                    <p>{billingDetails?.currentUsageHours || 0}</p>
                  </div>
                </>
              )}
              <Divider orientation="vertical" />
              <div className="details-wrapper">
                <p>Current total</p>
                <p>
                  {currency(billingDetails?.estimatedNextInvoiceToPay).format(
                    true
                  )}
                </p>
              </div>
            </div>
          </SimpleCard>

          <h4>Payment method</h4>
          <SimpleCard>
            <div className="overview-card payment-method-container">
              {renderPaymentMethod()}
            </div>
          </SimpleCard>
        </div>
      ) : (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Loading payment details"
        />
      )}
    </>
  );
}
