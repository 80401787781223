import { useQuery } from 'react-query';

import experimentApi from './experimentApi';

const getExperimentNameByExperimentKey = async experimentKey => {
  const {
    data: { experimentKeyAndNames }
  } = await experimentApi.getExperimentNameByExperimentKey(experimentKey);

  return experimentKeyAndNames.find(
    ({ experimentKey: experimentKeyInside }) =>
      experimentKeyInside === experimentKey
  )?.name;
};

export default function useExperimentNameByExperimentKey(
  experimentKey,
  config
) {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['experimentName', { experimentKey }],
    () => getExperimentNameByExperimentKey(experimentKey),
    {
      enabled: configEnabled && !!experimentKey
    }
  );
}
