import last from 'lodash/last';
import queryString from 'query-string';
import { matchPath } from 'react-router';
import isString from 'lodash/isString';
import { PARSE_OPTIONS } from '@shared/constants/url';

import { ROOT_ROUTES, WORKSPACE_ROUTES } from '@/constants/urlConstants';

export const getURLQueryParams = (urlString = null) => {
  const urlToParse = urlString || window.location.href;
  const search = last(urlToParse.split('?'));
  const params = new URLSearchParams(search);

  return params;
};

export const goBack = () => {
  const { referrer } = window.document;

  if (!referrer) {
    window.location.href = '/';
    return;
  }

  const node = window.document.createElement('a');
  node.href = referrer;

  const url =
    node.origin === window.location.origin && referrer !== window.location.href
      ? referrer
      : '/';

  // Prevents redirection loop:
  // /?openlogin=1 -> /login (back) -> /?openlogin=1 -> /login
  const [urlWithoutQueryString] = url.split('?');

  window.location.href = urlWithoutQueryString;
};

export const SETTING_SECTIONS = {
  GENERAL: 'general',
  RESET_PASSWORD: 'resetPassword',
  PAYMENT_PLAN: 'paymentPlan',
  COLLABORATORS: 'collaborators'
};

const newAccountSettingsMap = {
  [SETTING_SECTIONS.GENERAL]: '',
  [SETTING_SECTIONS.RESET_PASSWORD]: 'profile',
  [SETTING_SECTIONS.PAYMENT_PLAN]: 'organizations',
  [SETTING_SECTIONS.COLLABORATORS]: 'workspaces'
};

export const getSettingsRoute = ({
  organizationId = '',
  section,
  isAdmin = ''
}) => {
  if (section === SETTING_SECTIONS.PAYMENT_PLAN && isAdmin && organizationId) {
    return `/organizations/${organizationId}/billing`;
  }

  const { token } = queryString.parse(window.location.search);
  const resetTokenSearch = token ? `?resetToken=${token}` : '';

  return `/account-settings/${newAccountSettingsMap[section]}${resetTokenSearch}`;
};

export const getWorkspaceName = () => {
  const match = matchPath(window.location.pathname, {
    path: '/:workspaceName',
    exact: false,
    strict: false
  });

  const workspaceName = match?.params.workspaceName;

  return ROOT_ROUTES.includes(workspaceName) ? null : workspaceName;
};

export const getProjectName = () => {
  const match = matchPath(window.location.pathname, {
    path: '/:workspaceName/:projectName',
    exact: false,
    strict: false
  });

  const projectName = match?.params.projectName;

  return WORKSPACE_ROUTES.includes(projectName) ? null : projectName;
};

export const getIsQuickStartPage = () => {
  const { pathname } = window.location;

  return routeChecker(pathname, ['/:workspace/quick-start']);
};

export const routeChecker = (pathname, routes) => {
  return routes.some(config => {
    const route = isString(config) ? config : config.route;
    const excludedParameters = isString(config) ? null : config.exclude;

    const match = matchPath(pathname, {
      path: route,
      exact: true,
      strict: false
    });

    if (!match) return false;

    if (excludedParameters) {
      return Object.entries(excludedParameters).every(
        ([key, excludedValues]) => {
          return !excludedValues.includes(match.params[key]);
        }
      );
    }

    return true;
  });
};

export const getIsCompareExperimentPage = () => {
  const { pathname } = window.location;

  return routeChecker(pathname, [
    '/:workspace/:projectName/compare',
    '/:workspace/:projectName/archive/compare'
  ]);
};

export const getIsSingleExperimentPage = () => {
  const { pathname } = window.location;

  return routeChecker(pathname, [
    '/:workspace/:projectName/archive/:experimentKey',
    {
      route: '/:workspace/:projectName/:experimentKey',
      exclude: {
        experimentKey: [
          'files',
          'notes',
          'reports',
          'view',
          'compare',
          'prompts'
        ]
      }
    }
  ]);
};

export const getIsExperimentPage = () =>
  getIsCompareExperimentPage() || getIsSingleExperimentPage();

export const getExperimentKeys = () => {
  if (getIsSingleExperimentPage()) {
    const matchArchive = matchPath(window.location.pathname, {
      path: '/:workspace/:projectName/archive/:experimentKey',
      exact: false,
      strict: false
    });

    if (matchArchive) {
      return { experiment_key: matchArchive?.params?.experimentKey };
    }

    const matchSingleExperiment = matchPath(window.location.pathname, {
      path: '/:workspace/:projectName/:experimentKey',
      exact: false,
      strict: false
    });

    return { experiment_key: matchSingleExperiment?.params?.experimentKey };
  }

  if (getIsCompareExperimentPage()) {
    const { experiments } = queryString.parse(
      window.location.search,
      PARSE_OPTIONS
    );

    return { experiment_keys: experiments };
  }

  return {};
};

export const getExperimentUrl = ({
  isArchive,
  projectName,
  experimentKey,
  workspace
}) => {
  if (!workspace || !projectName || !experimentKey) return '';

  if (isArchive) {
    return `/${workspace}/${projectName}/archive/${experimentKey}`;
  }

  return `/${workspace}/${projectName}/${experimentKey}`;
};
