import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

// the original props.style has the left field automatically set
const StubHeaderCell = props => (
  <Table.StubHeaderCell
    {...props}
    style={{ ...(props?.style || {}), width: '100%' }}
  />
);

export default StubHeaderCell;
