import useTableRowSearch from '@shared/hooks/useTableRowSearch';
import React, { useState } from 'react';
import useComparisonDecimalPrecision from './hooks/useNewComparisonDecimalPrecision';
import { ExperimentDetails } from '@shared/types';
import { Table, TableMaxHeightProvider } from '@DesignSystem/tables';
import ComparisonEmptyState from './ComparisonEmptyState';
import { ComparisonTableContext } from './context/newComparisonTableContext';
import {
  AllowedCompareKeys,
  ComparisonTableDefaultData,
  DetailViewValue,
  VisibleColumnsTypeValue
} from './types';
import useComparisonTableColumnWidths from './hooks/useNewComparisonTableColumnWidths';
import useComparisonRowData from './hooks/useNewComparisonRowData';

import styles from './NewComparisonTable.module.scss';
import { getRowClassNameComparisonTable } from './utils';
import { Table as DXTable } from '@devexpress/dx-react-grid-material-ui';
import cx from 'classnames';
import useTableColumnNames from './hooks/useTableColumnNames';
import ParameterHeaderContent from '@experiment-details/components/params/ParameterHeaderContent';
import ExperimentBar from '@experiment-details/components/ExperimentBar';
import ComparisonTableDecimalSlider from './ComparisonTableDecimalSlider';
import { GroupButton } from '@ds';
import useDefaultHiddenColumnNames from './hooks/useDefaultHiddenColumnNames';
import useRowDataFormatting from './hooks/useRowDataFormatting';
import useMetricTabBI from '@experiment-details/components/metrics/hooks/useMetricTabBI';
import { experimentEvents } from '@/constants/trackingEventTypes';
import { useRefSizes } from '@shared/hooks';

type NewComparisonTableProps<TData> = {
  experiments: ExperimentDetails[];
  experimentsData: TData[][];
  detailView: DetailViewValue;
  emptyView: (materialProps: DXTable.DataCellProps) => React.ReactNode;
  isLoading: boolean;
  valueKeys: AllowedCompareKeys[];
};
const NewComparisonTable = <TData extends ComparisonTableDefaultData>({
  experiments,
  experimentsData,
  emptyView,
  detailView,
  isLoading,
  valueKeys
}: NewComparisonTableProps<TData>) => {
  const [firstExperiment] = experiments;
  const { refWidth = 0, sizeRef } = useRefSizes();
  const { sendCompareMetricTabBI } = useMetricTabBI();
  const { searchText, handleSearch, resetSearch } = useTableRowSearch();
  const [filterDuplicates, setFilterDuplicates] = useState(false);
  const [
    visibleColumnsType,
    setVisibleColumnsType
  ] = useState<VisibleColumnsTypeValue>('valueCurrent');

  const {
    decimalsPrecision,
    maxDecimalsInItems,
    onChangeDecimalsPrecision
  } = useComparisonDecimalPrecision<TData>({
    items: experimentsData.flat(),
    experimentKey: firstExperiment.experimentKey,
    detailView
  });

  const { columnBands, columns, dataTypes } = useTableColumnNames({
    experiments,
    valueKeys
  });

  const defaultHiddenColumnNames = useDefaultHiddenColumnNames({
    visibleColumnsType,
    columns
  });

  const rowData = useComparisonRowData({
    experiments,
    experimentsData,
    searchText,
    valueKeys
  });

  const formattedRowData = useRowDataFormatting({
    rowData,
    filterDuplicates,
    defaultHiddenColumnNames,
    experiments,
    valueKeys
  });

  const {
    columnWidths,
    onColumnWidthsChange,
    disabledColumns
  } = useComparisonTableColumnWidths({
    rowData: formattedRowData,
    columns,
    defaultHiddenColumnNames,
    containerWidth: refWidth
  });

  const onChangeVisibleColumns = (v: string) =>
    setVisibleColumnsType(prev => {
      const valuesMap = {
        valueCurrent: 'Last',
        valueMin: 'Min',
        valueMax: 'Max',
        all: 'All'
      };
      const value = v as VisibleColumnsTypeValue;
      sendCompareMetricTabBI(
        experiments,
        experimentEvents.EXPERIMENT_COMPARE_COLUMN_CHANGED,
        {
          newColumn: valuesMap[value],
          oldColumn: valuesMap[prev]
        }
      );
      return value;
    });

  const onChangeFilterDuplicates = (v: boolean) => {
    setFilterDuplicates(v);
    sendCompareMetricTabBI(
      experiments,
      experimentEvents.EXPERIMENT_COMPARE_HIDE_IDENTICAL_METRICS_CLICKED,
      {
        newState: v
      }
    );
  };

  return (
    <div
      className={cx('table-tab-container', `table-tab-contaner__${detailView}`)}
    >
      <ExperimentBar
        rightContainerChildren={
          <GroupButton
            selectedValue={visibleColumnsType}
            onValueChange={onChangeVisibleColumns}
          >
            <GroupButton.Item value="valueCurrent">Last</GroupButton.Item>
            <GroupButton.Item value="valueMin">Min</GroupButton.Item>
            <GroupButton.Item value="valueMax">Max</GroupButton.Item>
            <GroupButton.Item value="all">All</GroupButton.Item>
          </GroupButton>
        }
      >
        <ExperimentBar.Search
          searchText={searchText}
          setSearchText={handleSearch}
          placeholder="Name (regex)"
        />
        <ExperimentBar.Custom
          label="Decimal precision"
          component={
            <ComparisonTableDecimalSlider
              decimalsPrecision={decimalsPrecision}
              maxDecimalsInItems={maxDecimalsInItems}
              onChangeDecimalsPrecision={onChangeDecimalsPrecision}
            />
          }
        />
        <ExperimentBar.Toggle
          label="Hide identical metrics"
          name="hide-parameters-toggle"
          onChange={onChangeFilterDuplicates}
        />
      </ExperimentBar>
      <div className={styles.comparisonTable} ref={sizeRef}>
        <TableMaxHeightProvider>
          <ComparisonTableContext.Provider
            value={{
              decimalsPrecision
            }}
          >
            <Table
              dataTypes={dataTypes}
              columns={columns}
              columnBands={columnBands}
              rows={formattedRowData}
              rowIdKey="rowId"
              height="auto"
              isFetching={isLoading}
              paginationConfig={{
                isDisabled: true
              }}
              columnOrderConfig={{
                isDisabled: true
              }}
              selectionConfig={{
                isDisabled: true
              }}
              columnWidthsConfig={{
                isDisabled: false,
                columnWidths,
                onColumnWidthsChange,
                disabledColumns
              }}
              header={{
                renderContent: ParameterHeaderContent
              }}
              resizingMode="widget"
              getRowClassName={getRowClassNameComparisonTable}
              renderEmptyState={materialProps => (
                <ComparisonEmptyState
                  searchValue={searchText}
                  resetSearch={resetSearch}
                  isLoading={isLoading}
                  emptyView={emptyView}
                  {...materialProps}
                />
              )}
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              shouldRemoveStubCells
            />
          </ComparisonTableContext.Provider>
        </TableMaxHeightProvider>
      </div>
    </div>
  );
};

export default NewComparisonTable;
