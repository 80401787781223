import {
  ArtifactsHelpIcon,
  IntegrationHelpIcon,
  RegisterHelpIcon,
  VisualizationHelpIcon
} from '@Icons-outdated';
import { getDocLink } from '@shared';
import React from 'react';
import cx from 'classnames';

import styles from './HelpSidebar.module.scss';

interface ExploreTopicsProps {
  noTopMargin?: boolean;
}
const ExploreTopics = ({ noTopMargin }: ExploreTopicsProps) => {
  return (
    <div>
      <div
        className={cx(styles.helpSidebarSectionTitle, {
          [styles.noTopMargin]: noTopMargin
        })}
      >
        Explore popular topics
      </div>

      <div className={styles.exploreBlocks}>
        <a
          href={getDocLink('integrations/overview')}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.exploreBlock}>
            <div>
              <IntegrationHelpIcon />
            </div>
            <div className={styles.exploreBlockLabel}>
              Integrations with other frameworks
            </div>
          </div>
        </a>

        <a
          href={getDocLink(
            'guides/comet-dashboard/more-in-projects/#visualize-project-data-with-panels'
          )}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.exploreBlock}>
            <div>
              <VisualizationHelpIcon />
            </div>
            <div className={styles.exploreBlockLabel}>
              Visualize Project data with Panels
            </div>
          </div>
        </a>

        <a
          href={getDocLink('guides/model-management/using-model-registry/')}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.exploreBlock}>
            <div>
              <RegisterHelpIcon />
            </div>
            <div className={styles.exploreBlockLabel}>
              Use the model registry
            </div>
          </div>
        </a>

        <a
          href={getDocLink(
            'guides/data-management/using-artifacts/#use-artifacts'
          )}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.exploreBlock}>
            <div>
              <ArtifactsHelpIcon />
            </div>
            <div className={styles.exploreBlockLabel}>
              Getting started with artifacts
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ExploreTopics;
