import PropTypes from 'prop-types';
import React from 'react';

import Tags from '@shared/components/Tags';

const TagsCell = ({ row }) => <Tags list={row.tags} />;

TagsCell.propTypes = {
  row: PropTypes.object.isRequired
};

export default TagsCell;
