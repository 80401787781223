import React, { useRef, useEffect, useMemo } from 'react';
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  NodeTypes
} from 'react-flow-renderer';

import { ModelNode } from './ModelNode';
import classNames from './DAGContent.module.scss';
import './FlowStyles.scss';

type DAGPreviewContentProps = {
  graphViewJson: string;
};

const DAGPreviewContent = ({ graphViewJson }: DAGPreviewContentProps) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const { setViewport } = useReactFlow();
  useEffect(() => {
    if (graphViewJson) {
      try {
        const flow = JSON.parse(graphViewJson);
        if (flow) {
          const { x = 0, y = 0, zoom = 1 } = flow.viewport;
          setNodes(flow.nodes || []);
          setEdges(flow.edges || []);
          setViewport({ x, y, zoom });
        }
      } catch (_) {
        // eslint-disable-next-line no-empty
      }
    }
  }, [setNodes, setViewport, graphViewJson, setEdges]);

  const nodeTypes: NodeTypes = useMemo(
    () => ({
      modelNode: props => {
        return <ModelNode {...props} preview />;
      }
    }),
    []
  );

  const clickHandler: React.MouseEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className={classNames.DAGContentWrapper}>
      <div className={classNames.panelMainSection}>
        <div
          className={classNames.panelMainSectionRightColumn}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            onClick={clickHandler}
          >
            <Controls
              showInteractive={false}
              className={classNames.controlsMenu}
            />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
};

export function DAGPreviewWithProvider({
  graphViewJson
}: DAGPreviewContentProps) {
  return (
    <ReactFlowProvider>
      <DAGPreviewContent graphViewJson={graphViewJson} />
    </ReactFlowProvider>
  );
}
