export const HISTORY_GROUP_BY_OPTIONS = {
  day: 'DAY'
};

export const HISTORY_MESSAGES = {
  no_history: 'There is no history for this model yet.',
  no_more_history: 'No more history records'
};

export const MODEL_REGISTRY_HISTORY_TYPES = {
  name_changed: 'MODEL_NAME_CHANGED',
  description_changed: 'MODEL_DESCRIPTION_CHANGED',
  privacy_changed: 'MODEL_PRIVACY_CHANGED',
  version_created: 'MODEL_VERSION_CREATED',
  version_deleted: 'MODEL_VERSION_DELETED',
  version_assets_changed: 'MODEL_VERSION_ASSETS_CHANGED',
  version_moved: 'MODEL_VERSION_MOVED',
  version_downloaded: 'MODEL_VERSION_DOWNLOADED',
  stage_added: 'MODEL_VERSION_STAGE_ADDED',
  stage_deleted: 'MODEL_VERSION_STAGE_DELETED',
  tag_added: 'MODEL_VERSION_TAG_ADDED',
  tag_deleted: 'MODEL_VERSION_TAG_DELETED',
  notes_created: 'MODEL_NOTE_CREATED',
  notes_changed: 'MODEL_NOTE_CHANGED',
  notes_deleted: 'MODEL_NOTE_DELETED',
  version_notes_created: 'MODEL_VERSION_NOTE_CREATED',
  version_notes_changed: 'MODEL_VERSION_NOTE_CHANGED',
  version_notes_deleted: 'MODEL_VERSION_NOTE_DELETED',
  version_status_request_change: 'MODEL_VERSION_STATUS_REQUEST_CHANGE',
  version_status_request_cancelled: 'MODEL_VERSION_STATUS_REQUEST_CANCELLED',
  version_status_request_rejected: 'MODEL_VERSION_STATUS_REQUEST_REJECTED',
  version_status_request_approved: 'MODEL_VERSION_STATUS_REQUEST_APPROVED',
  version_status_changed: 'MODEL_VERSION_STATUS_CHANGED',
  version_status_added: 'MODEL_VERSION_STATUS_ADDED'
};

export const MODEL_REGISTRY_HISTORY_TITLE_BY_TYPE = {
  [MODEL_REGISTRY_HISTORY_TYPES.name_changed]: 'name changed',
  [MODEL_REGISTRY_HISTORY_TYPES.description_changed]: 'description changed',
  [MODEL_REGISTRY_HISTORY_TYPES.privacy_changed]: 'privacy changed',
  [MODEL_REGISTRY_HISTORY_TYPES.version_created]: 'version created',
  [MODEL_REGISTRY_HISTORY_TYPES.version_deleted]: 'version deleted',
  [MODEL_REGISTRY_HISTORY_TYPES.version_assets_changed]:
    'asset version changed',
  [MODEL_REGISTRY_HISTORY_TYPES.version_moved]: 'version moved',
  [MODEL_REGISTRY_HISTORY_TYPES.version_downloaded]: 'version downloaded',
  [MODEL_REGISTRY_HISTORY_TYPES.tag_added]: 'tag added',
  [MODEL_REGISTRY_HISTORY_TYPES.tag_deleted]: 'tag deleted',
  [MODEL_REGISTRY_HISTORY_TYPES.stage_added]: 'stage added',
  [MODEL_REGISTRY_HISTORY_TYPES.stage_deleted]: 'stage deleted',
  [MODEL_REGISTRY_HISTORY_TYPES.notes_created]: 'notes created',
  [MODEL_REGISTRY_HISTORY_TYPES.notes_changed]: 'notes changed',
  [MODEL_REGISTRY_HISTORY_TYPES.notes_deleted]: 'notes deleted',
  [MODEL_REGISTRY_HISTORY_TYPES.version_notes_created]:
    'model version notes created',
  [MODEL_REGISTRY_HISTORY_TYPES.version_notes_changed]:
    'model version notes changed',
  [MODEL_REGISTRY_HISTORY_TYPES.version_notes_deleted]:
    'model version notes deleted',
  [MODEL_REGISTRY_HISTORY_TYPES.version_status_request_change]:
    'request to change model version status',
  [MODEL_REGISTRY_HISTORY_TYPES.version_status_request_cancelled]:
    'change status request cancelled',
  [MODEL_REGISTRY_HISTORY_TYPES.version_status_request_rejected]:
    'change status request rejected',
  [MODEL_REGISTRY_HISTORY_TYPES.version_status_request_approved]:
    'change status request approved',
  [MODEL_REGISTRY_HISTORY_TYPES.version_status_changed]: 'status changed',
  [MODEL_REGISTRY_HISTORY_TYPES.version_status_added]: 'status changed'
};

export const PENDING_REQUESTS_TAB_ID = 'pending-requests';
export const VERSIONS_TAB_ID = 'versions';
export const NOTES_TAB_ID = 'notes';
export const HISTORY_TAB_ID = 'history';
export const WEBHOOKS_TAB_ID = 'webhooks';
export const MODEL_REGISTRY_TAB_ID = 'tab';
export const MODEL_REGISTRY_TABS_ORDER = [
  VERSIONS_TAB_ID,
  NOTES_TAB_ID,
  HISTORY_TAB_ID,
  WEBHOOKS_TAB_ID,
  PENDING_REQUESTS_TAB_ID
];

export const HEADER_TABS_LABEL_MAP = {
  [VERSIONS_TAB_ID]: 'Model Versions',
  [NOTES_TAB_ID]: 'Notes',
  [HISTORY_TAB_ID]: 'History',
  [WEBHOOKS_TAB_ID]: 'Webhooks',
  [PENDING_REQUESTS_TAB_ID]: 'Pending requests'
};

export const PENDING_REQUESTS_HEADER_TAB = {
  id: PENDING_REQUESTS_TAB_ID,
  label: HEADER_TABS_LABEL_MAP[PENDING_REQUESTS_TAB_ID],
  notificationCount: 100,
  dataTest: 'model-registry-pending-requests-tab-label'
};

export const WEBHOOKS_HEADER_TAB = {
  id: WEBHOOKS_TAB_ID,
  label: HEADER_TABS_LABEL_MAP[WEBHOOKS_TAB_ID],
  dataTest: 'model-registry-webhooks-tab-label'
};

export const HEADER_TABS = [
  {
    id: VERSIONS_TAB_ID,
    label: HEADER_TABS_LABEL_MAP[VERSIONS_TAB_ID],
    dataTest: 'model-registry-versions-tab-label'
  },
  {
    id: NOTES_TAB_ID,
    label: HEADER_TABS_LABEL_MAP[NOTES_TAB_ID],
    dataTest: 'model-registry-notes-tab-label'
  },
  {
    id: HISTORY_TAB_ID,
    label: HEADER_TABS_LABEL_MAP[HISTORY_TAB_ID],
    dataTest: 'model-registry-history-tab-label'
  }
];
