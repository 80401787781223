import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import { IconButton, Tooltip } from '@ds';

import TextField from '@material-ui/core/TextField';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';

import useDimensions from '@/helpers/custom-hooks/useDimensions';
import { DSCloseIcon, DSEditIcon } from '@ds-icons';

const EditableTextField = ({
  textChangeHandler,
  textSubmitHandler,
  currentName,
  className,
  entityName,
  lastFormReset,
  placeholder
}) => {
  const defaultText = useRef(placeholder);

  const [text, setText] = useState(currentName);
  const [newPlaceholder, setNewPlaceholder] = useState(placeholder);
  const [isEditMode, setIsEditMode] = useState(false);
  const [textFieldRef, { width }] = useDimensions([
    isEditMode,
    defaultText.current
  ]);
  const [textFieldWidth, setTextFieldWidth] = useState(width);

  const defaultWidth = useRef();
  useEffect(() => {
    if (!defaultWidth.current) {
      defaultWidth.current = width;
    }
    setTextFieldWidth(width);
  }, [width]);

  useEffect(() => {
    setText(currentName);
  }, [currentName]);

  useEffect(() => {
    defaultText.current = placeholder;
    setNewPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    setText(currentName);
    setIsEditMode(false);
    setTextFieldWidth(defaultWidth.current);
  }, [lastFormReset]);

  const handleEditText = e => {
    const { value } = e.target;

    setText(value);

    if (value !== text) {
      textChangeHandler(value);
    }
  };

  const handleSubmitText = e => {
    handleEditText(e);
    setIsEditMode(false);

    textSubmitHandler(text);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmitText(e);
    }
  };

  const handleCloseEditMode = () => {
    setIsEditMode(false);
  };

  const renderEditButton = () => {
    const tooltipText = `this ${entityName}'s name`;

    return (
      <Tooltip content={`Click to edit ${tooltipText}`} placement="top">
        <IconButton
          type="secondary"
          size="small"
          className="edit-name-btn"
          onClick={() => setIsEditMode(true)}
          Icon={<DSEditIcon />}
        />
      </Tooltip>
    );
  };

  const renderReadOnlyField = () => {
    return (
      <>
        <div ref={textFieldRef} className="text-field-container">
          {text || newPlaceholder}
        </div>
        {renderEditButton()}
      </>
    );
  };

  const renderEditableField = () => {
    const MIN_FIELD_WIDTH = 100;
    return (
      <>
        <div
          className="text-field-container"
          style={{ width: Math.max(textFieldWidth, MIN_FIELD_WIDTH) }}
        >
          <TextField
            className="editable-text-field"
            placeholder={defaultText.current}
            value={text}
            onChange={handleEditText}
            onKeyDown={handleKeyDown}
            onBlur={handleSubmitText}
          />
        </div>
        <Tooltip content="Click to close edit mode" placement="top">
          <IconButton
            size="small"
            Icon={<DSCloseIcon />}
            onClick={handleCloseEditMode}
            type="secondary"
          />
        </Tooltip>
      </>
    );
  };

  return (
    <div className={className}>
      {isEditMode ? renderEditableField() : renderReadOnlyField()}
    </div>
  );
};

EditableTextField.defaultProps = {
  className: 'chart-name-field',
  entityName: PANEL_ENTITY_NAME,
  lastFormReset: 0,
  placeholder: 'Name',
  textChangeHandler: noop,
  textSubmitHandler: noop
};

EditableTextField.propTypes = {
  currentName: PropTypes.string.isRequired,
  className: PropTypes.string,
  entityName: PropTypes.string,
  lastFormReset: PropTypes.number,
  placeholder: PropTypes.string,
  textChangeHandler: PropTypes.func,
  textSubmitHandler: PropTypes.func
};

export default connect()(EditableTextField);
