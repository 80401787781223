import React, { ForwardedRef, forwardRef } from 'react';

import { ButtonProps, DefaultButtonType } from '../../../types';

import {
  buttonTypographyMap,
  DEFAULT_BUTTON_ICON_SIZE,
  DEFAULT_BUTTON_SIZE,
  DEFAULT_BUTTON_TYPE
} from '@ds/buttons/constants';
import cx from 'classnames';

// ALEX
import { CircularProgress } from '@material-ui/core';
import { getIconPaddingClass, getLoaderSize } from '@ds/buttons/helpers';

import genericButtonClasses from '../GenericButton.module.scss';
import classes from './Button.module.scss';

const Button = (
  {
    type = DEFAULT_BUTTON_TYPE,
    size = DEFAULT_BUTTON_SIZE,
    PrefixIcon = null,
    PostfixIcon = null,
    loading = false,
    iconSize = DEFAULT_BUTTON_ICON_SIZE,
    children,
    active,
    ...buttonProps
  }: ButtonProps<DefaultButtonType>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  // small / medium icon might be applied to small buttons only
  const isSmallIcon = iconSize === 'small';

  const smallIconClass = {
    [genericButtonClasses.smallIconContainer]: size === 'small' && isSmallIcon,
    [genericButtonClasses.mediumIconContainer]: size === 'small' && !isSmallIcon
  };

  const iconPaddingClass =
    getIconPaddingClass(!!PrefixIcon || loading, !!PostfixIcon) || '';

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={cx(
        buttonProps.className,
        genericButtonClasses.dsButtonContainer,
        classes[type],
        classes[size],
        buttonTypographyMap[size],
        {
          [classes[iconPaddingClass]]: !!iconPaddingClass,
          [classes.active]: active
        }
      )}
    >
      {loading && (
        <div className={genericButtonClasses.loader}>
          <CircularProgress size={getLoaderSize(size)} />
        </div>
      )}
      {PrefixIcon && (
        <div
          className={cx(
            genericButtonClasses.iconContainer,
            genericButtonClasses.prefixIcon,
            smallIconClass
          )}
        >
          {PrefixIcon}
        </div>
      )}
      <div className={genericButtonClasses.childrenWrapper}>{children}</div>
      {PostfixIcon && (
        <div
          className={cx(
            genericButtonClasses.iconContainer,
            genericButtonClasses.postfixIcon,
            smallIconClass
          )}
        >
          {PostfixIcon}
        </div>
      )}
    </button>
  );
};

const ForwardedButton = forwardRef<
  HTMLButtonElement,
  ButtonProps<DefaultButtonType>
>(Button);
ForwardedButton.displayName = 'Button';

export default ForwardedButton;
