import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { useHover } from '../../hooks';
import NewStyledTooltip from '@shared/components/StyledComponents/NewStyledTooltip';

const useStyles = makeStyles({
  tooltip: {
    minWidth: '57px',
    height: '32px',
    fontSize: '12px',
    fontWeight: 400,
    border: '1px solid #c4c4c4',
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: '#5f677e',
    padding: '9px 2px',
    textAlign: 'center',
    marginLeft: '-2px'
  },
  arrow: {
    color: '#c4c4c4',
    marginLeft: '-2px'
  }
});

type ValueLabelProps = {
  children: React.ReactElement<
    { ref: React.MutableRefObject<null> },
    string | React.JSXElementConstructor<unknown>
  >;
  value: number;
};

export function ValueLabel(
  props: ValueLabelProps,
  matrixDates: string[],
  hourlyFormat: boolean
) {
  const { children, value } = props;
  const elRef = useRef(null);
  const [hovered] = useHover(elRef);

  const displayValue = moment
    .utc(matrixDates[value])
    .format(hourlyFormat ? 'Do MMM, ha' : 'Do MMM');
  const classes = useStyles();

  return (
    <NewStyledTooltip
      open={hovered}
      placement="top"
      title={displayValue}
      modifiedstyles={classes}
      arrow
    >
      {React.cloneElement(children, { ref: elRef }, null)}
    </NewStyledTooltip>
  );
}
