import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import dashboardHelpers from '@shared/utils/dashboardHelpers';
import {
  BUILT_IN_CHART_TYPES,
  OUTLIERS_VALUES,
  TRANSFORM_TYPES_OBJECT,
  X_AXIS_OPTIONS_MAP
} from '@experiment-management-shared/constants/chartConstants';
import {
  formatNameForDisplay,
  truncateByMaxCharacters
} from '@shared/utils/displayHelpers';
import {
  formatTimestampsToDate,
  formatTimestampsToSeconds,
  sortArrayOfNumbersAscending
} from '@/helpers/generalHelpers';

const chartHelpers = {
  isUserSavedTemplate(templateId) {
    return (
      !isNull(templateId) && !isUndefined(templateId) && !isEmpty(templateId)
    );
  },

  createXYValueMap(xValues, yValues) {
    return xValues.reduce((obj, xValue, index) => {
      obj[xValue] = yValues[index];
      return obj;
    }, {});
  },

  sortAndFormatValuesByXTimestamps(xAxisTimestamps, yValues) {
    const yValuesMappedToXTimestamps = this.createXYValueMap(
      xAxisTimestamps,
      yValues
    );
    const xAxisTimestampsSorted = sortArrayOfNumbersAscending(xAxisTimestamps);

    return {
      xValues: formatTimestampsToDate(xAxisTimestampsSorted),
      yValues: xAxisTimestampsSorted.map(
        xAxisTimestamp => yValuesMappedToXTimestamps[xAxisTimestamp]
      )
    };
  },

  sortAndFormatValuesByXSeconds(xAxisTimestamps, yValues) {
    const yValuesMappedToXTimestamps = this.createXYValueMap(
      xAxisTimestamps,
      yValues
    );
    const xAxisTimestampsSorted = sortArrayOfNumbersAscending(xAxisTimestamps);

    return {
      xValues: formatTimestampsToSeconds(xAxisTimestampsSorted),
      yValues: xAxisTimestampsSorted.map(
        xAxisTimestamp => yValuesMappedToXTimestamps[xAxisTimestamp]
      )
    };
  },

  getDefaultChartNameByType(chartType, chartOptions = {}) {
    const {
      metrics = [],
      selectedXAxis,
      selectedYAxis,
      selectedZAxis,
      selectedTargetVariable,
      metricName,
      aggregation,
      paramName,
      fileName
    } = chartOptions;

    const emptySelectionText = '(Select a metric)';
    const defaultXAxis = selectedXAxis || emptySelectionText;
    const defaultYAxis = selectedYAxis || emptySelectionText;
    const defaultTargetVar =
      formatNameForDisplay(selectedTargetVariable) || emptySelectionText;

    let barName = metrics
      ?.filter(metric => metric.name)
      .map(metric => metric.name)
      .join(', ');

    const nameByType = {
      [BUILT_IN_CHART_TYPES[
        'BuiltIn/Line'
      ]]: `${defaultYAxis} VS ${defaultXAxis}`,
      [BUILT_IN_CHART_TYPES['BuiltIn/Scatter']]: selectedZAxis
        ? `${defaultYAxis} VS ${defaultXAxis} VS ${selectedZAxis}`
        : `${defaultYAxis} VS ${defaultXAxis}`,
      [BUILT_IN_CHART_TYPES['BuiltIn/Bar']]: barName || defaultXAxis,
      [BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']]: defaultTargetVar,
      [BUILT_IN_CHART_TYPES.scalar]: `${aggregation} of ${
        paramName || metricName
      }`,
      [BUILT_IN_CHART_TYPES.image]: `Grid title`,
      [BUILT_IN_CHART_TYPES.video]: `Grid title`,
      [BUILT_IN_CHART_TYPES.pcd]: `Grid title`,
      [BUILT_IN_CHART_TYPES.curves]: 'Logged curve',
      [BUILT_IN_CHART_TYPES.data]: fileName,
      [BUILT_IN_CHART_TYPES.python]: 'Python panel'
    };

    return get(nameByType, chartType, `Custom ${PANEL_ENTITY_NAME} name`);
  },

  getQuantileFloorAndCeiling(array, percentile) {
    const arrayCopy = array.slice();

    function sortNumber(a, b) {
      return a - b;
    }

    arrayCopy.sort(sortNumber);
    let ceiling;
    let floor;
    let fraction;
    let i;

    let index = ((100 - percentile) / 100) * (arrayCopy.length - 1);
    if (Math.floor(index) == index) {
      ceiling = arrayCopy[index];
    } else {
      i = Math.floor(index);
      fraction = index - i;
      ceiling = arrayCopy[i] + (arrayCopy[i + 1] - arrayCopy[i]) * fraction;
    }

    index = (percentile / 100) * (arrayCopy.length - 1);
    if (Math.floor(index) == index) {
      floor = arrayCopy[index];
    } else {
      i = Math.floor(index);
      fraction = index - i;
      floor = arrayCopy[i] + (arrayCopy[i + 1] - arrayCopy[i]) * fraction;
    }

    return { ceiling, floor };
  },

  filterOutliersPlotly(xValues, yValues, zValues, min, max) {
    const points = xValues.map((xValue, index) => {
      return {
        x: xValue,
        y: yValues[index]
      };
    });

    const pointsAfterYOutliersFiltered = points.filter(point => {
      return point.y < max && point.y > min;
    });

    if (!pointsAfterYOutliersFiltered.length) {
      return { x: [], y: [] };
    }

    const pointsX = pointsAfterYOutliersFiltered.map(point => point.x);
    const pointsY = pointsAfterYOutliersFiltered.map(point => point.y);

    return {
      x: pointsX,
      y: pointsY,
      // @todo: review outliers
      z: zValues
    };
  },

  getPointsWithOutliersRemoved({
    xValues,
    yValues,
    zValues = null,
    percentile
  }) {
    const quantile = chartHelpers.getQuantileFloorAndCeiling(
      yValues,
      percentile
    );
    const filteredPointsByOutliers = chartHelpers.filterOutliersPlotly(
      xValues,
      yValues,
      zValues,
      quantile.floor,
      quantile.ceiling
    );
    return {
      x: filteredPointsByOutliers.x,
      y: filteredPointsByOutliers.y,
      z: filteredPointsByOutliers.z
    };
  },

  getDimensionMap(experimentMap, experiments, values, min, max) {
    values.forEach((value, index) => {
      const experimentKey = experiments[index];
      const isWithinConstraint = value >= min && value <= max;
      const isInMap = experimentMap[experimentKey];
      if (isWithinConstraint && isInMap) {
        experimentMap[experimentKey] += 1;
      } else if (isWithinConstraint) {
        experimentMap[experimentKey] = 1;
      }
    });

    return experimentMap;
  },

  getLastDataPoint(dataArr) {
    const { length } = dataArr;
    return dataArr[length - 1];
  },

  calculateAxisType(currentType, transform) {
    // currentType contain information that was detected by library (or current function) when the chart is first rendered,
    // in case the chart was saved with 'log scale' selected, this value will be equal to 'log', so to fix the case when user want to change the
    // Axis transformation to 'initial' we supply '-' default value of Plotly library to force it determinate type according to datasource
    return transform === TRANSFORM_TYPES_OBJECT.LOG_SCALE
      ? 'log'
      : currentType === 'log'
      ? '-'
      : currentType;
  },

  getSmoothedValues(values, smoothing) {
    const smoothingWeight = smoothing === 1 ? 0.9999 : smoothing;
    let last = 0;
    let numAccum = 0;
    return values.map(value => {
      if (!isFinite(value)) {
        return value;
      }
      last = last * smoothingWeight + (1 - smoothingWeight) * value;
      numAccum += 1;
      let debiasWeight = 1;
      if (smoothingWeight !== 1.0) {
        debiasWeight = 1.0 - Math.pow(smoothingWeight, numAccum);
      }
      return last / debiasWeight;
    });
  },

  getLogValues(values, type) {
    if (type === TRANSFORM_TYPES_OBJECT.LOG_VALUE) {
      return values.map(Math.log);
    }

    return values.map(Math.log10);
  },

  getMovingAverageValues(values) {
    let runningSum = 0;
    return values.map((val, index) => {
      const numOfPreviousValues = index + 1;
      runningSum += val;
      return runningSum / numOfPreviousValues;
    });
  },

  applyTransformByType(type, values, smoothingValue) {
    let calculatedValues = values;

    switch (type) {
      case TRANSFORM_TYPES_OBJECT.LOG_VALUE:
        calculatedValues = chartHelpers.getLogValues(
          values,
          TRANSFORM_TYPES_OBJECT.LOG_VALUE
        );
        break;
      case TRANSFORM_TYPES_OBJECT.LOG_BASE_10:
        calculatedValues = chartHelpers.getLogValues(values);
        break;
      case TRANSFORM_TYPES_OBJECT.MOVING_AVERAGE:
        calculatedValues = chartHelpers.getMovingAverageValues(values);
        break;
      default:
        break;
    }

    return chartHelpers.getSmoothedValues(
      calculatedValues || values,
      smoothingValue
    );
  },

  removeExistingConstraints(dataForChart) {
    if (dataForChart) {
      const { metrics } = dataForChart;
      metrics.forEach(metric => {
        delete metric.constraintrange;
      });
    }

    return dataForChart;
  },

  getConstraintsFromDimensions(dimensions) {
    return (dimensions || []).reduce((result, dimension) => {
      if (dimension.constraintrange) {
        result = {
          ...result,
          [dimension.label]: dimension.constraintrange
        };
      }

      return result;
    }, {});
  },

  // @TODO idealy we should modify the backend to return label as an object with { keyName , kind }
  // we can do metric.label.keyName === selectedTargetVariable.label && selectedTargetVariable.kind === metric.label.kind
  sortParallelCoordinateMetrics(metrics, selectedTargetVariable) {
    const metricsCopy = metrics.slice();
    const indexOfTarget = findIndex(
      metrics,
      metric => metric.label === selectedTargetVariable
    );
    const targetMetric = metricsCopy.splice(indexOfTarget, 1)[0];
    metricsCopy.push(targetMetric);
    return metricsCopy;
  },

  // this method is removing everything except string saved in array of images,
  // taking into account that some images could be uploaded to comet without name,
  // and added to panel from Graphics tab
  sanitizeImagesNameArray(images) {
    if (isArray(images)) {
      return images.filter(
        imageName => isString(imageName) && imageName !== ''
      );
    }
    return [];
  },

  isRequiredFieldsEmptyForChartType(chartType, chartFormByType) {
    if (chartType === BUILT_IN_CHART_TYPES['BuiltIn/Line']) {
      return isEmpty(chartFormByType.selectedYAxis);
    }

    if (chartType === BUILT_IN_CHART_TYPES['BuiltIn/Bar']) {
      const hasValidMetric = chartFormByType.metrics?.some(
        ({ name }) => !!name
      );
      return !hasValidMetric && isNull(chartFormByType.selectedXAxis);
    }

    if (chartType === BUILT_IN_CHART_TYPES['BuiltIn/Scatter']) {
      return (
        isNull(chartFormByType.selectedXAxis) ||
        isNull(chartFormByType.selectedYAxis)
      );
    }

    if (chartType === BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']) {
      return (
        !chartFormByType.selectedTargetVariable ||
        chartFormByType.metrics.length + chartFormByType.params.length < 2
      );
    }

    if (chartType === BUILT_IN_CHART_TYPES.scalar) {
      return !(chartFormByType.metricName || chartFormByType.paramName);
    }

    if (chartType === BUILT_IN_CHART_TYPES.image) {
      return !chartHelpers.sanitizeImagesNameArray(chartFormByType.images)
        .length;
    }

    if (chartType === BUILT_IN_CHART_TYPES.video) {
      return !chartFormByType.videos?.length;
    }

    if (chartType === BUILT_IN_CHART_TYPES.pcd) {
      return !chartFormByType.assetNames?.length;
    }

    if (chartType === BUILT_IN_CHART_TYPES.curves) {
      return !chartFormByType.assetNames?.length;
    }

    if (chartType === BUILT_IN_CHART_TYPES.data) {
      return !chartFormByType.fileName;
    }

    if (chartType === BUILT_IN_CHART_TYPES.python) {
      return !chartFormByType.codeVersion;
    }

    return false;
  },

  flattenExperimentsAndValues({
    dataSources,
    metricName,
    paramName,
    hiddenExperimentKeys
  }) {
    const unhiddehDataSources = dataSources.filter(
      dataSource => !hiddenExperimentKeys?.includes(dataSource?.experiment_key)
    );

    const flattenedExperiments = [];

    if (paramName) {
      unhiddehDataSources.forEach(experiment => {
        flattenedExperiments.push({
          experiment_key: experiment?.experiment_key,
          value: experiment?.params?.[paramName],
          timestamp: null
        });
      });

      flattenedExperiments.reverse();
    } else if (metricName) {
      const experiments = unhiddehDataSources.map(experiment => {
        const neededMetric = experiment.metrics?.find(
          metric => metric.metricName === metricName
        );

        return {
          experiment_key: experiment?.experiment_key,
          values: neededMetric?.values.map(value => value),
          timestamps: neededMetric?.timestamps
        };
      });

      for (let i = 0; i < experiments.length; i++) {
        const { values, timestamps, experiment_key } = experiments[i];
        for (let j = 0; j < values?.length; j++) {
          flattenedExperiments.push({
            value: values[j],
            timestamp: timestamps[j],
            experiment_key
          });
        }
      }

      flattenedExperiments.sort(
        (aExperiment, bExperiment) =>
          aExperiment.timestamp - bExperiment.timestamp
      );
    }

    return [
      flattenedExperiments,
      flattenedExperiments.map(({ value }) => value)
    ];
  },

  toDataSources({ data, experimentKeys, selectedXAxis, type }) {
    if (isEmpty(data)) return [];

    if (
      [
        BUILT_IN_CHART_TYPES['BuiltIn/Line'],
        BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
        BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
        BUILT_IN_CHART_TYPES.scalar
      ].includes(type)
    ) {
      const panelData = data.experiments;

      const metricData = dashboardHelpers.getDataSortedByOrderedExperimentKeys(
        panelData,
        experimentKeys
      );

      const isMetricDataInvalid = dashboardHelpers.checkForInvalidMetricData(
        metricData
      );

      if (isMetricDataInvalid) {
        return [];
      }

      if (type === BUILT_IN_CHART_TYPES['BuiltIn/Bar']) {
        return metricData.filter(dataSource => {
          return !dataSource.empty && !isEmpty(dataSource.metrics);
        });
      }

      return dashboardHelpers.removeExperimentsWithoutData(
        metricData,
        selectedXAxis
      );
    }

    return [];
  },

  normalizeDataSource({
    dataSources,
    selectedXAxis,
    selectedYAxis,
    chartType
  }) {
    if (
      [
        BUILT_IN_CHART_TYPES['BuiltIn/Line'],
        BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
        BUILT_IN_CHART_TYPES['BuiltIn/Scatter']
      ].includes(chartType)
    ) {
      const dataSourcesValuesNormalized = dataSources?.map(dataSource => {
        if (!Array.isArray(dataSource.metrics)) return dataSource;

        const metrics = dataSource.metrics.map(metric => {
          if (!Array.isArray(metric.values)) return metric;

          const values = metric.values.map(v => {
            if (isNaN(Number(v))) return v;

            return Number(v);
          });

          return { ...metric, values };
        });

        return { ...dataSource, metrics };
      });

      if (
        [
          BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
          BUILT_IN_CHART_TYPES['BuiltIn/Scatter']
        ].includes(chartType)
      ) {
        return dataSourcesValuesNormalized;
      }

      // This mapping is needed for multi-metrics line charts
      return dataSourcesValuesNormalized.reduce((result, dataSource) => {
        const selectedXAxisMetric = dataSource.metrics.find(
          metric => metric.metricName === selectedXAxis
        );

        const metricSources = dataSource.metrics
          .filter(({ metricName }) => {
            const yMetrics = Array.isArray(selectedYAxis) ? selectedYAxis : [];

            return (
              metricName !== selectedXAxis || yMetrics.includes(metricName)
            );
          })
          .map(metric => {
            return {
              ...dataSource,
              steps: metric.steps,
              metrics: [metric, selectedXAxisMetric].filter(Boolean)
            };
          });

        return result.concat(metricSources);
      }, []);
    }

    return dataSources;
  },

  applyVisibilityOfExperiments(dataSources, hiddenExperimentKeys) {
    return dataSources.map(dataSource => ({
      ...dataSource,
      isVisibleOnDashboard: !hiddenExperimentKeys.includes(
        dataSource.experiment_key
      )
    }));
  },

  sortArraysBy(values, sortingKey) {
    const keys = Object.keys(values);

    const valuesInArray = (values[sortingKey] || []).map((value, index) => {
      return keys.reduce((result, key) => {
        result[key] = values[key][index];

        return result;
      }, {});
    });

    const sortedValuesInArray = sortBy(valuesInArray, sortingKey);

    return keys.reduce((result, key) => {
      result[key] = sortedValuesInArray.map(value => value[key]);

      return result;
    }, {});
  },

  truncateAxisTitle(text) {
    return truncateByMaxCharacters(text, 25);
  },

  calculateLineChartAxisTitles(props) {
    let xAxisTitle = '';
    let yAxisTitle = '';

    if (props.showXAxisTitle) {
      xAxisTitle = props.customXAxisTitle
        ? props.customXAxisTitle
        : X_AXIS_OPTIONS_MAP[props.selectedXAxis] ?? props.selectedXAxis;
    }

    if (props.showYAxisTitle) {
      yAxisTitle = props.customYAxisTitle
        ? props.customYAxisTitle
        : props.metricNames.join(',');
    }

    return {
      xAxisTitle: chartHelpers.truncateAxisTitle(xAxisTitle),
      yAxisTitle: chartHelpers.truncateAxisTitle(yAxisTitle)
    };
  },

  calculateBarChartAxisTitles(props) {
    let xAxisTitle = '';
    let yAxisTitle = '';

    if (props.showXAxisTitle) {
      xAxisTitle = props.customXAxisTitle ? props.customXAxisTitle : '';
    }

    if (props.showYAxisTitle) {
      yAxisTitle = props.customYAxisTitle
        ? props.customYAxisTitle
        : (props.metrics || []).map(m => m.name).join(',');
    }

    return {
      xAxisTitle: chartHelpers.truncateAxisTitle(xAxisTitle),
      yAxisTitle: chartHelpers.truncateAxisTitle(yAxisTitle)
    };
  },

  calculateScatterChartAxisTitles(props) {
    let xAxisTitle = '';
    let yAxisTitle = '';

    if (props.showXAxisTitle) {
      xAxisTitle = props.customXAxisTitle
        ? props.customXAxisTitle
        : props.selectedXAxis;
    }

    if (props.showYAxisTitle) {
      yAxisTitle = props.customYAxisTitle
        ? props.customYAxisTitle
        : props.selectedYAxis;
    }

    return {
      xAxisTitle: chartHelpers.truncateAxisTitle(xAxisTitle),
      yAxisTitle: chartHelpers.truncateAxisTitle(yAxisTitle)
    };
  },

  calculateCurvesChartAxisTitles(props) {
    let xAxisTitle = '';
    let yAxisTitle = '';

    if (props.showXAxisTitle) {
      xAxisTitle = props.customXAxisTitle ? props.customXAxisTitle : '';
    }

    if (props.showYAxisTitle) {
      yAxisTitle = props.customYAxisTitle
        ? props.customYAxisTitle
        : props.assetNames.join(',');
    }

    return {
      xAxisTitle: chartHelpers.truncateAxisTitle(xAxisTitle),
      yAxisTitle: chartHelpers.truncateAxisTitle(yAxisTitle)
    };
  },

  calculateAxisTitlePlaceholder(type, props) {
    let data = {};

    switch (type) {
      case BUILT_IN_CHART_TYPES['BuiltIn/Line']:
        data = chartHelpers.calculateLineChartAxisTitles(props);
        break;
      case BUILT_IN_CHART_TYPES['BuiltIn/Bar']:
        data = chartHelpers.calculateBarChartAxisTitles(props);
        break;
      case BUILT_IN_CHART_TYPES['BuiltIn/Scatter']:
        data = chartHelpers.calculateScatterChartAxisTitles(props);
        break;
      case BUILT_IN_CHART_TYPES.curves:
        data = chartHelpers.calculateCurvesChartAxisTitles(props);
        break;
    }

    return {
      xAxisTitlePlaceholder: data.xAxisTitle || 'Enter axis label',
      yAxisTitlePlaceholder: data.yAxisTitle || 'Enter axis label'
    };
  },

  generateAxisTitle(text) {
    if (!text) return { title: undefined };

    return {
      title: {
        text,
        font: {
          color: '#5f677e',
          size: 12,
          family: 'Roboto, sans-serif'
        },
        standoff: 16
      }
    };
  },

  calculatePercentile(selectedOutliers) {
    return selectedOutliers === OUTLIERS_VALUES.SHOW ? 0 : 5;
  },

  calculateSelectedOutliers(checked) {
    return checked ? OUTLIERS_VALUES.SHOW : OUTLIERS_VALUES.NOT_VISIBLE;
  },

  extendConfigWithGlobalConfig(config, globalConfig, globalConfigMap) {
    if (config.locked || !globalConfig) return config;

    const globalKeys = globalConfigMap[config.chartType];

    if (globalKeys) {
      const globalOverrideConfig = {};
      globalKeys.forEach(key => {
        globalOverrideConfig[key] = globalConfig[key];
      });

      return {
        ...config,
        ...globalOverrideConfig
      };
    }

    return config;
  },

  regexSearchPanelsByName(searchRegex, panel) {
    const title =
      panel?.chartName ||
      panel?.chartTitle ||
      panel?.instanceName ||
      chartHelpers.getDefaultChartNameByType(panel?.chartType, panel);

    return searchRegex.test(title);
  }
};

export default chartHelpers;
