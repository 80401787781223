import { useParams } from 'react-router-dom';
import get from 'lodash/get';

import useCompareExperimentsKeys from '@shared/hooks/useCompareExperimentsKeys';
import useQueryParamsForTab from '@shared/hooks/useQueryParamsForTab';

const useQueryParamsForExperiments = (
  updatedParams,
  dependencies,
  experimentKey = null,
  { preventUpdate = false } = {}
) => {
  const params = useParams();
  const { experimentKeys } = useCompareExperimentsKeys();
  const experimentKeyParam = get(params, 'experimentKey', experimentKeys[0]);
  const preventURLUpdate =
    preventUpdate || experimentKey !== experimentKeyParam;

  return useQueryParamsForTab(updatedParams, dependencies, {
    preventUpdate: preventURLUpdate
  });
};

export default useQueryParamsForExperiments;
