import { ColumnType } from '../types/common';

const MODEL_NAME_COLUMN_WIDTH = 298;

const DEFAULT_MODEL_SORTING = [{ columnName: 'modelName', direction: 'desc' }];

const MODEL_COLUMN_WIDTHS = [
  { columnName: 'modelName', width: MODEL_NAME_COLUMN_WIDTH }
];

const NO_PADDING_COLUMNS = ['modelName'];

const AREACHART_Y_RANGE = [-0.09, 1.1];

const MODEL_COLUMNS_NAMES = {
  alerts: 'alerts',
  createdAt: 'createdAt',
  latestVersion: 'latestVersion',
  modelName: 'modelName',
  latestPrediction: 'latestPrediction',
  numberOfPredictions: 'numberOfPredictions'
};

const MODEL_COLUMNS = [
  {
    name: MODEL_COLUMNS_NAMES.modelName,
    title: 'Model Name',
    id: 'model_name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'model_name',
    hideForFilters: false,
    hideForSorting: false
  },
  {
    name: MODEL_COLUMNS_NAMES.alerts,
    title: 'Alerts',
    id: 'alerts--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: MODEL_COLUMNS_NAMES.createdAt,
    title: 'Created at',
    id: 'createdAt--metadata',
    type: 'datetime',
    source: 'metadata',
    serverField: 'created_ts',
    hideForFilters: false,
    hideForSorting: false
  },
  {
    name: MODEL_COLUMNS_NAMES.latestVersion,
    title: 'Latest version',
    id: 'latestVersion--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'version',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: MODEL_COLUMNS_NAMES.latestPrediction,
    title: 'Latest prediction',
    id: 'latestPrediction--metadata',
    type: 'datetime',
    source: 'metadata',
    serverField: 'latest_prediction',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: MODEL_COLUMNS_NAMES.numberOfPredictions,
    title: 'Number of predictions',
    id: 'numberOfPredictions--metadata',
    type: 'double',
    source: 'metadata',
    serverField: 'prediction_count',
    hideForFilters: true,
    hideForSorting: true
  }
] as ColumnType[];

const MODEL_SERVER_COLUMNS_NAMES: { [key: string]: string } = {
  alerts: 'alerts',
  createdAt: 'created_ts',
  latestVersion: 'version',
  modelName: 'model_name',
  latestPrediction: 'latest_prediction',
  numberOfPredictions: 'prediction_count'
} as const;

const MODEL_LEFT_COLUMNS = [MODEL_COLUMNS_NAMES.modelName];

const MODEL_DISABLED_COLUMNS = [MODEL_COLUMNS_NAMES.modelName];

const MODEL_PAGE_SIZES = [10, 25, 50, 100];

export {
  MODEL_NAME_COLUMN_WIDTH,
  DEFAULT_MODEL_SORTING,
  AREACHART_Y_RANGE,
  MODEL_LEFT_COLUMNS,
  NO_PADDING_COLUMNS,
  MODEL_DISABLED_COLUMNS,
  MODEL_PAGE_SIZES,
  MODEL_COLUMNS_NAMES,
  MODEL_COLUMNS,
  MODEL_COLUMN_WIDTHS,
  MODEL_SERVER_COLUMNS_NAMES
};
