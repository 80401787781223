import React from 'react';
import cx from 'classnames';
import typography from '@ds-typography';

import styles from '../EmptyExperimentTab.module.scss';

const EmptyExperimentTabTitle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cx(typography.dsLightSmall, styles.emptyTabTitle, className)}
    {...props}
  />
));
EmptyExperimentTabTitle.displayName = 'EmptyExperimentTabTitle';

export default EmptyExperimentTabTitle;
