import React from 'react';
import { useHistory } from 'react-router';

import { Button, LinkButton } from '@ds';
import { Select } from '@DesignSystem/controllers';
import { RenderTooltip } from '../RenderTooltip';
import styles from './FairnessPage.module.scss';

type Option = { label: string; value: string };

type GetStartedFairnessProps = {
  isFairnessSupported: boolean;
  areSegmentsDefined: boolean;
  positiveOutcome: string;
  setPositiveOutcome: (val: string) => void;
  protectedAttribute: string;
  setProtectedAttribute: (val: string) => void;
  referenceGroup: string;
  setReferenceGroup: (value: string, isFromGettingStarted: boolean) => void;
  setShowCharts: (val: boolean) => void;
  positiveOutcomeOptions: Option[];
  protectedAttributeOptions: Option[];
  referenceGroupOptions: Option[];
};

export function GetStartedFairness({
  isFairnessSupported,
  areSegmentsDefined,
  positiveOutcome,
  setPositiveOutcome,
  protectedAttribute,
  setProtectedAttribute,
  referenceGroup,
  setReferenceGroup,
  setShowCharts,
  positiveOutcomeOptions,
  protectedAttributeOptions,
  referenceGroupOptions
}: GetStartedFairnessProps) {
  const history = useHistory();

  const disabledTrackingBtn =
    !positiveOutcome || !protectedAttribute || !referenceGroup;

  const onClickCreateSegmentHandler = () => {
    history.push('./settings?panel=segments');
  };

  return (
    <div className={styles.getStartedContent}>
      <span className={styles.getStartedContentLeft}>
        <img src="/images/fairness-empty.svg" />
      </span>
      {isFairnessSupported && areSegmentsDefined && (
        <div className={styles.getStartedContentRight}>
          <div className={styles.getStartedContentRightTitle}>
            Get started with model fairness
          </div>

          <span className={styles.getStartedContentRightText}>
            Model fairness can be tracked using the “Disparate Impact” metric.
            To compute this metric, you will need first to define the 3 fields
            below. Once these are defined, you will be able to view model
            fairness metrics for all other classes in this protected attribute.
          </span>

          <div className={styles.getStartedFormWrapper}>
            <span>
              <Select
                label="Positive Outcome"
                value={positiveOutcome}
                required
                onChange={setPositiveOutcome}
                options={positiveOutcomeOptions}
                variant="outlined"
                withInput={false}
                withTooltip
                renderTooltip={() => (
                  <RenderTooltip message=" Prediction value that is associated with a favorable outcome for this model" />
                )}
              />
            </span>
            <span>
              <Select
                label="Protected Attribute"
                value={protectedAttribute}
                required
                onChange={setProtectedAttribute}
                options={protectedAttributeOptions}
                variant="outlined"
                withInput={false}
                withTooltip
                renderTooltip={() => (
                  <RenderTooltip message="Select the model feature that you want to use compare across" />
                )}
              />
            </span>
            <span>
              <Select
                label="Reference Group"
                value={referenceGroup}
                required
                onChange={value => setReferenceGroup(value, true)}
                options={referenceGroupOptions}
                variant="outlined"
                withInput={false}
                withTooltip
                disabled={!protectedAttribute || !positiveOutcome}
                renderTooltip={() => (
                  <RenderTooltip message="Select the value taken by the protected attribute that will serve as the baseline" />
                )}
              />
            </span>
            <Button
              onClick={() => {
                setShowCharts(true);
              }}
              size="medium"
              disabled={disabledTrackingBtn}
            >
              Start tracking fairness metric
            </Button>
          </div>

          <LinkButton
            openInNewTab
            to="/docs/v2/guides/model-production-monitoring/model-fairness"
            text="Learn more about model fairness"
          />
        </div>
      )}
      {isFairnessSupported && !areSegmentsDefined && (
        <div className={styles.getStartedContentRight}>
          <div className={styles.getStartedContentRightTitle}>
            Get started with model fairness
          </div>

          <div className={styles.getStartedContentRightText}>
            <span>
              Ensuring a model is fair is key part of monitoring models and can
              even be a regulatory requirement.
            </span>
            <span>
              Disparate Impact is a common metric for tracking model fairness
              and the one that has been implemented by Comet. This metric relies
              solely on model predictions and ensure that one group of user is
              not receiving preferential treatment compared to another group.
            </span>
            <span>
              Before viewing the “Disparate Impact” metric, you will need to
              define segments for your model. These segments will be used as the
              protected attributes that can be chosen when computing the
              fairness metrics.
            </span>
            <Button
              onClick={onClickCreateSegmentHandler}
              size="large"
              className={styles.createSegment}
            >
              Create Model Segments
            </Button>
          </div>
        </div>
      )}
      {!isFairnessSupported && (
        <div className={styles.getStartedContentRight}>
          <div className={styles.getStartedContentRightTitle}>
            Model fairness is not available as no categorical output features
            have been logged
          </div>

          <div className={styles.getStartedContentRightText}>
            <span>
              Ensuring a model is fair is the key part of monitoring models and
              can even be a regulatory requirement.
            </span>
            <span>
              Disparate Impact is a common metric for tracking model fairness
              and the one that has been implemented by Comet. This metric relies
              solely on model predictions and ensure that one group of user is
              not receiving preferential treatment compared to another group.
            </span>
            <span>
              The “Disparate Impact” metric is not defined for regression
              models. If there is another fairness metric you would like us to
              track please reach out at product@comet.com.
            </span>

            <LinkButton
              openInNewTab
              to="/docs/v2/guides/model-production-monitoring/model-fairness"
              text="Learn more about model fairness"
            />
          </div>
        </div>
      )}
    </div>
  );
}
