import { useUpsertExperimentMetricMutation } from '@experiment-management-shared';
import { ExperimentDetails, Metric } from '@shared/types';
import { useEffect, useState } from 'react';
import { getDefaultNewMetricMap, validateMetricData } from '../helpers';
import useMetricTabBI from './useMetricTabBI';
import { experimentEvents } from '@/constants/trackingEventTypes';

type UseAddMetricApiOpts = {
  experiment: ExperimentDetails;
  rowData: Metric[];
  onClose: () => void;
  isOpened: boolean;
};
const useAddMetricApi = ({
  experiment,
  rowData,
  onClose,
  isOpened
}: UseAddMetricApiOpts) => {
  const { experimentKey } = experiment;
  const { sendMetricTabBI } = useMetricTabBI();
  const [metricData, setMetricData] = useState(getDefaultNewMetricMap);

  useEffect(() => {
    if (isOpened) {
      setMetricData(getDefaultNewMetricMap);
    }
  }, [isOpened]);

  const upsertExperimentMetricMutation = useUpsertExperimentMetricMutation();

  const isValid = validateMetricData(metricData);
  const isDuplicateName = rowData.some(item => item.name === metricData.name);

  const onConfirm = () => {
    upsertExperimentMetricMutation.mutate(
      {
        experimentKey,
        metric: metricData
      },
      {
        onSuccess: () => {
          setMetricData(getDefaultNewMetricMap);
          sendMetricTabBI(
            experiment,
            experimentEvents.EXPERIMENT_DETAILS_ADD_METRICS_MODAL_ADD_CLICKED
          );
          onClose();
        }
      }
    );
  };

  const onFieldChange = (v: string, key: keyof Metric) => {
    let value: string | number = v;
    if (key.startsWith('timestamp')) {
      value = new Date(v).getTime();
    }
    setMetricData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const isFetching = upsertExperimentMetricMutation.isLoading;

  return {
    onConfirm,
    isFetching,
    isValid: isValid && !isDuplicateName,
    isDuplicateName,
    onFieldChange,
    formData: metricData
  };
};

export default useAddMetricApi;
