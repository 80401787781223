import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { AcceptedCardList } from '@DesignSystem/payment';

import './CreditCardForm.scss';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { Input, Select } from '@DesignSystem/controllers';
import { DSColors } from '@design-system-outdated/constants';
import {
  COUNTRY_CONSTANTS,
  COUNTRIES_WITH_STATES
} from '../../../../constants/countryConstants';
import { URLS } from '../../../../constants/urlConstants';

const countryOptions = COUNTRY_CONSTANTS.map(({ id, value }) => ({
  value: id,
  label: value
}));

const cardNumberElementType = 'cardNumber';
const cardExpiryElementType = 'cardExpiry';
const cardCvcElementType = 'cardCvc';

const createOptions = placeholder => {
  const options = {
    style: {
      base: {
        fontSize: '14px',
        backgroundColor: 'white',
        color: DSColors.blackColor,
        fontFamily: '"Roboto", system-ui, sans-serif',
        lineHeight: '21px',
        height: '36px',
        '::placeholder': {
          color: DSColors.grayColor3
        },
        padding: '10px 14px'
      },
      invalid: {
        color: DSColors.blackColor
      }
    }
  };

  if (placeholder) {
    options.placeholder = placeholder;
  }
  return {
    options
  };
};

const CreditCardForm = ({
  address,
  city,
  country,
  onChangeAddress,
  onChangeCity,
  onChangeCountry,
  onChangePostalCode,
  onChangeState,
  postalCode,
  state
}) => {
  const [stripeElementFocused, setStripeElementFocused] = useState(null);

  const handleFocusElement = useCallback(
    focusEvent => setStripeElementFocused(focusEvent?.elementType),
    []
  );

  const handleBlurElement = useCallback(
    () => setStripeElementFocused(null),
    []
  );

  useEffect(() => {
    if (!COUNTRIES_WITH_STATES.includes(country) && state) {
      onChangeState('');
    }
  }, [country, state, onChangeState]);

  return (
    <form className="ds-credit-card-form">
      <div className="accepted-cards-field">
        <span className="accept-label">We accept: </span>
        <AcceptedCardList />
      </div>

      <div className="card-form-container">
        <div className="form-field">
          <div className="form-address">
            <Input
              required
              placeholder="Enter address"
              value={address}
              onChange={onChangeAddress}
              title="Address"
            />
          </div>

          <div>
            <Input
              required
              placeholder="Enter city"
              value={city}
              onChange={onChangeCity}
              title="City"
            />
          </div>
        </div>

        <div className="form-field">
          <div className="form-address">
            <Input
              placeholder="Enter state"
              value={state}
              onChange={onChangeState}
              title="State"
              disabled={!COUNTRIES_WITH_STATES.includes(country)}
            />
          </div>

          <div className="form-postal-code-container">
            <div>
              <Input
                placeholder="Enter postal code"
                value={postalCode}
                onChange={onChangePostalCode}
                title="Postal code"
              />
            </div>
          </div>
        </div>

        <div className="form-field">
          <div className="country-container">
            <Select
              label="Country"
              required
              options={countryOptions}
              value={country}
              onChange={onChangeCountry}
              variant="outlined"
              placeholder="Select the country"
              withInput
            />
          </div>
        </div>

        <div className="form-field">
          <div>
            <p className="form-label-input">
              Card information <span className="required-mark">*</span>
            </p>
            <div className="stripe-card-container">
              <CardNumberElement
                {...createOptions('Card number')}
                className={cx(
                  'card-number-container',
                  'stripe-element-container',
                  { focused: stripeElementFocused === cardNumberElementType }
                )}
                onFocus={handleFocusElement}
                onBlur={handleBlurElement}
              />
              <CardExpiryElement
                {...createOptions()}
                className={cx(
                  'card-expiry-container',
                  'stripe-element-container',
                  { focused: stripeElementFocused === cardExpiryElementType }
                )}
                onFocus={handleFocusElement}
                onBlur={handleBlurElement}
              />
              <CardCvcElement
                {...createOptions()}
                className={cx(
                  'card-cvc-container',
                  'stripe-element-container',
                  { focused: stripeElementFocused === cardCvcElementType }
                )}
                onFocus={handleFocusElement}
                onBlur={handleBlurElement}
              />
            </div>
          </div>
        </div>

        <div className="privacy-label-container">
          <p>
            Your privacy is super important to us - we’ll only use your
            information as described in our{' '}
            <a
              className="policy-button"
              href={URLS.TERMS}
              target="_blank"
              rel="noopener noreferrer"
            >
              term of use
            </a>{' '}
            and{' '}
            <a
              className="policy-button"
              href={URLS.PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </form>
  );
};

CreditCardForm.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  onChangeCity: PropTypes.func.isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  onChangePostalCode: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired
};

export default CreditCardForm;
