import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';
import { ModelType, PipelineType } from '@mpm/types';

type ModelsParams = {
  page: number;
  pageSize: number;
  sortColumn?: string;
  order?: string;
  predicates?: [] | Array<unknown>;
};

type ModelsResponse = {
  columns: string[];
  models: ModelType[];
  pipelines: PipelineType[];
  total: number;
};

const getModels = ({
  workspaceId,
  ...restParams
}: ModelsParams & { workspaceId: string }) => {
  return api.post(`mpm/${workspaceId}/models/search`, restParams);
};

export function useModels(
  params: ModelsParams,
  config: QueryConfig<ModelsResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['models', { workspaceId: activeWorkspace?.id, ...params }],
    () => getModels({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config,
      enabled: !!activeWorkspace?.id
    }
  );
}
