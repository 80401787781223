// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperimentDetailsSidebar-module__sideBarMenu--YvvsY{position:relative;background-color:#fff}.ExperimentDetailsSidebar-module__sideBarMenu--YvvsY:not(.ExperimentDetailsSidebar-module__collapsed--B3B1b) .ExperimentDetailsSidebar-module__collapseExpandButton--aDhg6 svg{transform:rotate(180deg);margin-left:-3px}.ExperimentDetailsSidebar-module__detailItem--Z2V0i{height:46px;padding-top:12.5px;padding-bottom:12.5px;padding-left:20px;font-size:14px;line-height:21px;display:flex;align-items:center;cursor:pointer;margin-bottom:6px;white-space:nowrap}.ExperimentDetailsSidebar-module__detailItem--Z2V0i:hover{background-color:#f3f4fe;color:var(--primary-color)}.ExperimentDetailsSidebar-module__detailItem--Z2V0i:hover .ExperimentDetailsSidebar-module__itemIcon--sRqxD{color:var(--primary-color)}.ExperimentDetailsSidebar-module__selectedDetailItem--W88j6{background-color:#f3f4fe;color:var(--primary-color)}.ExperimentDetailsSidebar-module__selectedDetailItem--W88j6 .ExperimentDetailsSidebar-module__itemIcon--sRqxD{color:var(--primary-color)}.ExperimentDetailsSidebar-module__itemIconContainer--iZo5B{margin-right:12px;display:flex;align-items:center}.ExperimentDetailsSidebar-module__itemIcon--sRqxD{color:var(--gray-6);height:16px}.ExperimentDetailsSidebar-module__collapseExpandButton--aDhg6{position:absolute;z-index:10;top:85px;right:-13px;width:26px;height:26px;border-radius:100%;box-shadow:0 0 2px .5px rgba(0,0,0,.25);background-color:#fff;border:1px solid var(--gray-1);display:flex;justify-content:center;align-items:center;cursor:pointer}.ExperimentDetailsSidebar-module__collapseExpandButton--aDhg6:hover{background-color:var(--background-color)}.ExperimentDetailsSidebar-module__collapseExpandButton--aDhg6 svg{margin-right:-2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBarMenu": `ExperimentDetailsSidebar-module__sideBarMenu--YvvsY`,
	"collapsed": `ExperimentDetailsSidebar-module__collapsed--B3B1b`,
	"collapseExpandButton": `ExperimentDetailsSidebar-module__collapseExpandButton--aDhg6`,
	"detailItem": `ExperimentDetailsSidebar-module__detailItem--Z2V0i`,
	"itemIcon": `ExperimentDetailsSidebar-module__itemIcon--sRqxD`,
	"selectedDetailItem": `ExperimentDetailsSidebar-module__selectedDetailItem--W88j6`,
	"itemIconContainer": `ExperimentDetailsSidebar-module__itemIconContainer--iZo5B`
};
module.exports = ___CSS_LOADER_EXPORT___;
