import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  MouseEvent
} from 'react';
import cx from 'classnames';
import { DSCloseIcon, DSSearchIcon } from '@ds-icons';

import TextInput, { TextInputProps } from '../TextInput/TextInput';
import classes from './SearchInput.module.scss';

export interface SearchInputProps extends TextInputProps {
  onClearIconClick: (e: MouseEvent<HTMLDivElement>) => void;
  searchIconClassName?: string;
}

const SearchInput = ({
  onClearIconClick,
  searchIconClassName,
  ...inputProps
}: SearchInputProps) => {
  const {
    value,
    defaultValue,
    disabled,
    size = 'default',
    onChange
  } = inputProps;
  const [localValue, setLocalValue] = useState<typeof value>('');

  useEffect(() => {
    if (defaultValue !== undefined) {
      setLocalValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }

      setLocalValue(e.target.value);
    },
    [onChange]
  );

  return (
    <div
      className={cx(classes.searchInputContainer, classes[size], {
        [classes.disabled]: disabled
      })}
    >
      <div className={cx(classes.searchIconContainer, searchIconClassName)}>
        <DSSearchIcon />
      </div>

      <TextInput {...inputProps} onChange={handleChange} />

      {localValue && !disabled && (
        <div
          className={classes.crossIconContainer}
          onMouseDown={e => {
            e.stopPropagation();
          }}
          onClick={onClearIconClick}
        >
          <DSCloseIcon />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
