import React from 'react';

import { Slider, SliderProps } from '@design-system-outdated/components';

import styles from './GridPanelSlider.module.scss';

export const GridPanelSlider = ({ ...sliderProps }: SliderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Step</div>
      <div className={styles.wrapper}>
        <Slider {...sliderProps} />
      </div>
    </div>
  );
};

export default GridPanelSlider;
