import { convertWebhookDataToCometPayload } from '@model-registry/utils/webhooks';
import { useMutation } from 'react-query';
import modelRegistryApi from '@/util/modelRegistryApi';

const useModelRegistryTestWebhookMutation = ({ workspaceName, modelName }) => {
  return useMutation(data => {
    return modelRegistryApi.testWebhook(
      convertWebhookDataToCometPayload({ workspaceName, modelName }, data)
    );
  });
};

export default useModelRegistryTestWebhookMutation;
