import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';

import MultipleHistogramList from './MultipleHistogramList';

const HistogramsGroupedBy = ({
  assets,
  experiments,
  ignoreOutliers,
  maxSteps,
  handleDeleteHistogram
}) => {
  const [expanded, setExpanded] = useState({});

  return Object.entries(assets).map(([category, categoryAssets]) => {
    const handleExpanded = (event, isExpanded) => {
      setExpanded({
        ...expanded,
        [category]: isExpanded
      });
    };

    const isExpanded = expanded[category];

    return (
      <ExpansionPanel
        key={category}
        expanded={isExpanded}
        onChange={handleExpanded}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{category}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {isExpanded && (
            <MultipleHistogramList
              assets={categoryAssets}
              experiments={experiments}
              ignoreOutliers={ignoreOutliers}
              maxSteps={maxSteps}
              handleDeleteHistogram={handleDeleteHistogram}
            />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
};

HistogramsGroupedBy.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  ignoreOutliers: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  handleDeleteHistogram: PropTypes.func.isRequired
};

export default HistogramsGroupedBy;
