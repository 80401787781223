import React from 'react';
import PropTypes from 'prop-types';

const EmptyPreview = ({ message }) => {
  return <h3 className="empty-preview-message">{message}</h3>;
};

EmptyPreview.propTypes = {
  message: PropTypes.string.isRequired
};

export default EmptyPreview;
