import { Tooltip, withStyles } from '@material-ui/core';
import { DATA_PANEL_MAX_EXPERIMENTS } from '@/lib/appConstants';

export const CustomTooltip = withStyles({
  tooltip: {
    background: '#373D4D',
    maxWidth: '100%',
    padding: '5px 10px',
    borderRadius: '8px',
    marginTop: '2px'
  },
  arrow: {
    '&:before': {
      background: '#373D4D',
      borderRadius: '2px'
    }
  }
})(Tooltip);

export const stepSliderLabelFormat = value => {
  const lastStep = DATA_PANEL_MAX_EXPERIMENTS;
  const currentStep = value;

  return `${currentStep}/${lastStep}`;
};
