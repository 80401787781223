import useProject from '@API/project/useProject';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';

const removeReports = ({ projectId, reportIds }) => {
  return api.post('reports/delete', {
    projectId,
    reportIds
  });
};

export default function useDeleteReportsMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ projectId: reportProjectId = projectId, reportIds }) => {
      return removeReports({ projectId: reportProjectId, reportIds });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['reports', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['reports', { projectId }]);
      }
    }
  );
}
