import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';

import EditableField from '@shared/components/EditableField';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';

function ChartNameField({
  chartNameEditHandler,
  currentName,
  entityName = PANEL_ENTITY_NAME,
  maxWidth,
  isCodeEditor,
  placeholder,
  label,
  className
}) {
  const canEdit = useSelector(isUserAllowedToEditProject);

  return (
    <EditableField
      className={className}
      editHandler={chartNameEditHandler}
      canEdit={canEdit}
      dataTestPrefix="chart-title-field"
      currentName={currentName}
      tooltipText={
        isCodeEditor
          ? `default ${entityName} name`
          : `this ${entityName}'s display name in your project view`
      }
      maxWidth={maxWidth}
      isCodeEditor={isCodeEditor}
      placeholder={placeholder}
      label={label}
    />
  );
}

ChartNameField.defaultProps = {
  entityName: PANEL_ENTITY_NAME,
  isCodeEditor: false,
  placeholder: 'Name',
  maxWidth: 300,
  label: '',
  className: ''
};

ChartNameField.propTypes = {
  chartNameEditHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentName: PropTypes.string.isRequired,
  entityName: PropTypes.string,
  isCodeEditor: PropTypes.bool,
  placeholder: PropTypes.string,
  maxWidth: PropTypes.number,
  label: PropTypes.string
};

export default ChartNameField;
