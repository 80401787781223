import React from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@DesignSystem/modals';
import { useCancelPaymentPlan } from '@account-settings/api';
import { PAYMENT_PLANS } from '@shared/constants';
import { useCurrentOrganization, useCurrentPaymentPlan } from '@shared/hooks';

const CancelPaymentPlanModal = ({ open, onClose }) => {
  const currentOrganization = useCurrentOrganization();
  const { paymentPlanName } = useCurrentPaymentPlan();

  const cancelPaymentPlanMutation = useCancelPaymentPlan(
    currentOrganization?.id
  );

  const handleConfirm = async () => {
    try {
      await cancelPaymentPlanMutation.mutateAsync();
      onClose();
    } catch (e) {
      // handled in a mutation
    }
  };

  return (
    <BasicModal
      open={open}
      title="Are You Sure?"
      content={
        <div className="payment-plan-cancel-modal-container">
          This will cancel your payment plan for{' '}
          <b>{currentOrganization?.name}</b> of type{' '}
          <b>{PAYMENT_PLANS[paymentPlanName]?.name}</b>.
        </div>
      }
      onClose={onClose}
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      isPrimaryBtnLoading={cancelPaymentPlanMutation.isLoading}
      isPrimaryButtonDisabled={cancelPaymentPlanMutation.isLoading}
      onSecondaryButtonClick={onClose}
    />
  );
};

CancelPaymentPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CancelPaymentPlanModal;
