import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import JSONEditor from 'jsoneditor';
import { Button } from '@ds';
import Tooltip from '@material-ui/core/Tooltip';

import 'jsoneditor/dist/jsoneditor.css';
import SidebarActionsFooter from './SidebarActionsFooter';
import { DSCheckMarkIcon, DSDiscardChangesIcon } from '@ds-icons';

const OptionsEditorJSON = ({ onApply, options, optionsToResetTo }) => {
  const [isOptionsChanged, setIsOptionsChanged] = useState(false);
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);

  const onEditorChange = useCallback(() => {
    try {
      editorRef.current.get();
      setIsOptionsChanged(true);
    } catch {
      setIsOptionsChanged(false);
    }
  }, []);

  useEffect(() => {
    const container = editorContainerRef.current;
    if (!container) return;

    const editor = new JSONEditor(container, {
      mainMenuBar: false,
      mode: 'code',
      onChange: onEditorChange
    });

    editorRef.current = editor;

    return () => editor.destroy();
  }, [onEditorChange]);

  useEffect(() => {
    if (editorRef.current && editorRef.current._debouncedValidate) {
      editorRef.current.set(options);
    }
  }, [options]);

  const handleResetOptions = () => {
    if (editorRef.current && editorRef.current._debouncedValidate) {
      editorRef.current.set(optionsToResetTo);
      setIsOptionsChanged(true);
    }
  };

  const applyOptions = () => {
    const editorOptions = editorRef.current.get();
    onApply(editorOptions);

    setIsOptionsChanged(false);
  };

  return (
    <div className="custom-chart-options-editor-container">
      <div className="custom-chart-options-editor" ref={editorContainerRef} />
      <SidebarActionsFooter isVisible={isOptionsChanged}>
        <Tooltip title="Reset to options from panel code" placement="top">
          <Button
            size="large"
            onClick={handleResetOptions}
            PrefixIcon={<DSDiscardChangesIcon />}
          >
            Reset
          </Button>
        </Tooltip>
        <Button
          size="large"
          disabled={!isOptionsChanged}
          onClick={applyOptions}
          PrefixIcon={<DSCheckMarkIcon />}
        >
          Apply Options
        </Button>
      </SidebarActionsFooter>
    </div>
  );
};

OptionsEditorJSON.propTypes = {
  onApply: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  optionsToResetTo: PropTypes.object.isRequired
};

export default OptionsEditorJSON;
