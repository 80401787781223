// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueryFiltersHeader-module__container--ZzLPj{display:flex;align-items:center;height:32px}.QueryFiltersHeader-module__container--ZzLPj .QueryFiltersHeader-module__button--bj7Be{margin:0 4px}.QueryFiltersHeader-module__container--ZzLPj .QueryFiltersHeader-module__divider--l7qzX{height:20px}.QueryFiltersHeader-module__container--ZzLPj .QueryFiltersHeader-module__divider--l7qzX:last-child{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QueryFiltersHeader-module__container--ZzLPj`,
	"button": `QueryFiltersHeader-module__button--bj7Be`,
	"divider": `QueryFiltersHeader-module__divider--l7qzX`
};
module.exports = ___CSS_LOADER_EXPORT___;
