import { useQuery, UseQueryResult } from 'react-query';

import api from '@API/api';
import { AlertRule } from '@mpm/types';
import { useActiveWorkspace } from '@shared/hooks';

type AlertRulesParams = {
  modelId: string;
  page: number;
  pageSize: number;
};

type ConfigProps = {
  keepPreviousData: boolean;
  refetchOnMount: boolean;
};

type AlertRulesResponse = {
  alertRules: AlertRule[];
  total: number;
};

const getAlertRules = ({
  modelId,
  workspaceId,
  ...restParams
}: AlertRulesParams & { workspaceId: string }) =>
  api.get(`mpm/${workspaceId}/models/${modelId}/alert-rules`, restParams);

export function useAlertRules(
  params: AlertRulesParams,
  config: ConfigProps
): UseQueryResult<AlertRulesResponse> {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['alert-rules', { workspaceId: activeWorkspace?.id, ...params }],
    () => getAlertRules({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config,
      enabled: !!activeWorkspace?.id,
      select: data => {
        return {
          ...data,
          alertRules: data.alertRules.map((alertRule: AlertRule) => {
            return {
              id: alertRule.id,
              name: alertRule.name,
              openNotifications: alertRule.numAlertInstancesOpened,
              createdAt: alertRule.createdAt,
              modelVersion: alertRule.modelVersion,
              intervalType: alertRule.intervalType,
              createdBy: alertRule.createdBy,
              description: alertRule.description
            };
          })
        };
      }
    }
  );
}
