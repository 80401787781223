import { useQuery } from 'react-query';
import { cancelWrapper, axiosInstance } from '../../../../api/api';

const mapUsers = users =>
  users
    .filter(user => !user.deleted)
    .map(user => ({
      username: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      avatar: user.avatar,
      email: user.email,
      joinedAt: user.joinedAt,
      lastActivityAt: user.lastActivityAt,
      pending: user.pending
    }));

const getOrganizationMembers = organizationId =>
  cancelWrapper(async cancelToken => {
    const { data = [] } = await axiosInstance.get(
      `organizations/${organizationId}/members`,
      {
        cancelToken
      }
    );

    return mapUsers(data);
  });

const useOrganizationUsers = organizationId =>
  useQuery(
    ['organizations', organizationId, 'members'],
    () => getOrganizationMembers(organizationId),
    {
      enabled: !!organizationId
    }
  );

export default useOrganizationUsers;
