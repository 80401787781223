import { useQuery } from 'react-query';

import { axiosInstance, cancelWrapper } from '../api';

const getParameters = ({ experimentKeys, imageSearchPhrase }) => {
  return {
    experiments: experimentKeys,
    imageSearchPhrase
  };
};

const getImagesSearch = async ({ parameters }) => {
  if (
    parameters.imageSearchPhrase?.length < 3 ||
    parameters.experiments?.length === 0
  ) {
    return null;
  }

  return cancelWrapper(async cancelToken => {
    return axiosInstance({
      cancelToken,
      data: parameters,
      method: 'post',
      url: 'image/search-by-filter'
    });
  });
};

export default function useImagesSearch(data, config) {
  const parameters = getParameters({
    ...data
  });

  return useQuery(
    ['imagesSearch', { parameters }],
    () => getImagesSearch({ parameters }),
    {
      ...config
    }
  );
}
