import primary from './PrimaryCheckboxFrame.svg';
import hover from './HoverCheckboxFrame.svg';
import selected from './SelectedCheckboxFrame.svg';
import disabled from './DisabledCheckboxFrame.svg';
import multiple from './MultipleCheckboxFrame.svg';

export default {
  primary,
  hover,
  selected,
  disabled,
  multiple
};
