// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegendWrapper-module__container--OhwrZ{--gap: 16px;padding:0 var(--gap) var(--gap) var(--gap);display:flex;flex-direction:column;align-items:stretch;width:100%;height:100%}.LegendWrapper-module__container--OhwrZ .LegendWrapper-module__legendWrapper--JNLqs{margin:0 calc(-1*var(--gap));position:relative}.LegendWrapper-module__container--OhwrZ .LegendWrapper-module__chartWrapper--gt15P{display:flex;flex:1;min-height:80%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LegendWrapper-module__container--OhwrZ`,
	"legendWrapper": `LegendWrapper-module__legendWrapper--JNLqs`,
	"chartWrapper": `LegendWrapper-module__chartWrapper--gt15P`
};
module.exports = ___CSS_LOADER_EXPORT___;
