import { CloseIcon, KeyboardArrowDownIcon } from '@Icons-outdated';
import { Box } from '@material-ui/core';
import cx from 'classnames';
import { flatten, isArray, isEmpty, isString, noop, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { truncateMiddleOfStringToFitWidth } from '@shared/utils/displayHelpers';
import { Tooltip } from '@ds';

import DropdownList from '../DropdownList/DropdownList';

import { Chip } from '../../data-display';
import { CHIP_TYPE_BADGE } from '../../data-display/Chip/Chip';
import './Select.scss';
import { useObserveResizeNode } from '@shared/hooks/useObserveResizeNode';

export const DS_SELECT_HOVER_TYPES = {
  DEFAULT: 'default-hover',
  MENU: 'menu-hover'
};

export const DS_SELECT_TYPES = {
  STANDARD: 'standard',
  OUTLINED: 'outlined'
};

const VALUE_PLACEMENTS = {
  left: 'left',
  center: 'center'
};

const Select = ({
  label,
  options,
  valueAlign,
  value,
  onChange,
  placeholder,
  noOptionsComponent,
  width,
  withInput,
  withTooltip,
  stayOpen,
  renderTooltip,
  renderSelectedValue,
  isClearable,
  onChangeInput,
  inputValue: controlledInputValue,
  variant,
  isMulti,
  fixedValues,
  hideSource,
  extraOptionsProps,
  disabled,
  required,
  'data-test': dataTestId,
  hoverType,
  truncateMiddle,
  horizontalLabel
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState(controlledInputValue);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedMultiValues, setSelectedMultiValues] = useState([]);
  const [dropdownListMinWidth, setDropdownListMinWidth] = useState(null);

  const open = Boolean(anchorEl);

  const rootClasses = {
    standard: variant === DS_SELECT_TYPES.STANDARD,
    outlined: variant === DS_SELECT_TYPES.OUTLINED
  };

  const selectClasses = {
    ...rootClasses,
    focused: open,
    disabled,
    isMulti
  };

  const localOptions = useMemo(() => {
    let filteredOptions = options;

    if (isMulti) {
      filteredOptions = options.filter(({ value: optionValue }) => {
        return !selectedMultiValues.find(multiValue => {
          return multiValue === optionValue || multiValue.value === optionValue;
        });
      });
    }

    if (inputValue === '') return filteredOptions;

    return filteredOptions.filter(val => {
      const strValue = isString(val.label) ? val.label : val.value;

      return strValue?.toLowerCase().includes(inputValue.toLowerCase());
    });
  }, [inputValue, isMulti, options, selectedMultiValues]);

  const extraOptions = flatten(
    localOptions?.map(({ extraOptions }) => extraOptions)
  );

  useEffect(() => {
    setInputValue(controlledInputValue);
  }, [controlledInputValue]);

  useEffect(() => {
    const newOption = [...options, ...extraOptions].find(
      option => option?.value === value
    );
    if (newOption) {
      setSelectedOption(newOption);
    } else {
      setSelectedOption(null);
    }
  }, [value, options, extraOptions]);

  const handleChangeInput = useCallback(
    newValue => {
      if (onChangeInput) {
        onChangeInput(newValue);
      } else {
        setInputValue(newValue);
      }
    },
    [onChangeInput]
  );

  useEffect(() => {
    if (!anchorEl) {
      handleChangeInput('');
    }
  }, [anchorEl, handleChangeInput]);

  const handleClick = event => {
    if (event.target === event.currentTarget && !disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const clearValue = () => {
    if (isMulti) {
      onChange([], []);
    } else {
      onChange(null, null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isMulti) {
      const val = isArray(value) ? value : [value];
      setSelectedMultiValues(val);
    }
  }, [isMulti, value]);

  const onResizeRef = useObserveResizeNode(node =>
    setDropdownListMinWidth(node.clientWidth)
  );

  const handleItemClick = (newValue, fullValueData) => {
    if (isMulti) {
      const arr = uniq([...selectedMultiValues, newValue]);
      const fullArrData = uniq([...selectedMultiValues, fullValueData]);
      setSelectedMultiValues(arr);
      onChange(arr, fullArrData);
      if (!stayOpen) {
        handleClose();
      }
    } else {
      onChange(newValue, fullValueData);
      handleClose();
    }
  };

  const headerClasses = {
    active: open,
    'left-aligned': valueAlign === VALUE_PLACEMENTS.left
  };

  const renderMultiSelectBadge = currentValue => {
    const isDeletable = !fixedValues.includes(currentValue?.value);

    return (
      <Chip
        label={currentValue.label || currentValue}
        key={currentValue.value || currentValue}
        isDeletable={isDeletable}
        type={CHIP_TYPE_BADGE}
        onClose={() => {
          const filteredArr = selectedMultiValues.filter(
            multiValue =>
              multiValue !== currentValue ||
              multiValue.value !== currentValue.value
          );

          setSelectedMultiValues(filteredArr);
          onChange(filteredArr, filteredArr);
        }}
      />
    );
  };

  const hasValue = Boolean(selectedOption);

  const isTruncateMiddle = width && truncateMiddle;

  const displayedValue = useMemo(() => {
    if (!selectedOption) return undefined;

    if (renderSelectedValue !== noop) {
      return renderSelectedValue(selectedOption);
    }

    if (isTruncateMiddle) {
      return truncateMiddleOfStringToFitWidth(selectedOption.label, width);
    }

    return selectedOption.label;
  }, [selectedOption, width, isTruncateMiddle, renderSelectedValue]);

  const tooltipTitle = useMemo(() => {
    if (isTruncateMiddle && displayedValue !== selectedOption?.label) {
      return selectedOption.label;
    }

    return '';
  }, [isTruncateMiddle, displayedValue, selectedOption?.label]);

  const renderPlaceholder = () => {
    return variant === DS_SELECT_TYPES.STANDARD ? label : placeholder;
  };

  return (
    <div
      className={cx('ds-select-container', {
        'horizontal-wrapper': horizontalLabel
      })}
    >
      {variant === DS_SELECT_TYPES.OUTLINED && label && (
        <Box
          display="flex"
          alignItems="center"
          className={cx({ 'horizontal-label': horizontalLabel })}
        >
          <span className="select-label">
            {label} {required && <span className="required-label">*</span>}
          </span>
          {withTooltip && renderTooltip()}
        </Box>
      )}
      <Tooltip content={tooltipTitle} wrapperDisplay="block" placement="top">
        <div
          ref={onResizeRef}
          onClick={handleClick}
          className={cx('ds-select', selectClasses)}
          title={
            typeof selectedOption?.label === 'string'
              ? selectedOption.label
              : selectedOption?.value
          }
          data-test={dataTestId}
        >
          <div
            style={{ pointerEvents: 'none' }}
            className={cx('select-header', headerClasses)}
          >
            {isMulti ? (
              <Box
                display="flex"
                flexWrap="wrap"
                flexBasis="100%"
                maxWidth="100%"
                marginTop="5px"
              >
                <div
                  style={{ padding: 0, marginRight: 0 }}
                  className="multi-select-badge-container"
                />
                {selectedMultiValues.length ? (
                  selectedMultiValues?.map(currentValue => {
                    return renderMultiSelectBadge(currentValue);
                  })
                ) : (
                  <Box
                    className={cx('value-wrapper', {
                      'select-placeholder': !hasValue
                    })}
                    marginBottom="5px"
                    marginTop="1px"
                    paddingY="2px"
                  >
                    {renderPlaceholder()}
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                flex="1 1 100%"
                overflow="hidden"
                className="value-wrapper-container"
              >
                {selectedOption?.prefixIcon && (
                  <div className="prefix-icon">
                    {' '}
                    {selectedOption.prefixIcon}
                  </div>
                )}
                <div
                  className={cx('value-wrapper', {
                    'select-placeholder': !hasValue
                  })}
                >
                  {hasValue ? displayedValue : renderPlaceholder()}
                </div>
              </Box>
            )}

            <div className="icons-container">
              {isClearable && !disabled && value && !isEmpty(value) && (
                <div className="close-icon-wrapper" onClick={clearValue}>
                  <CloseIcon />
                </div>
              )}

              <div
                className={cx({
                  'divider-wrapper': variant === DS_SELECT_TYPES.OUTLINED,
                  'arrow-wrapper': variant === DS_SELECT_TYPES.STANDARD
                })}
              >
                <KeyboardArrowDownIcon
                  className={cx('icon', {
                    arrowDivider: variant === DS_SELECT_TYPES.OUTLINED
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
      <DropdownList
        maxHeight="300px"
        minWidth={dropdownListMinWidth}
        anchorEl={anchorEl}
        hideSource={hideSource}
        onClose={handleClose}
        width={width}
        itemHeight={hideSource ? 38 : 59}
        withInput={withInput}
        value={value}
        noOptionsComponent={noOptionsComponent}
        onClick={handleItemClick}
        items={localOptions}
        inputValue={inputValue}
        onChangeInput={handleChangeInput}
        extraOptionsProps={extraOptionsProps}
        hoverType={hoverType}
        truncateMiddle={truncateMiddle}
      />
    </div>
  );
};

Select.defaultProps = {
  'data-test': null,
  disabled: false,
  extraOptionsProps: null,
  inputValue: '',
  isClearable: false,
  isMulti: false,
  hideSource: false,
  label: '',
  onChange: noop,
  onChangeInput: null,
  placeholder: 'Select...',
  required: false,
  value: null,
  valueAlign: VALUE_PLACEMENTS.center,
  variant: DS_SELECT_TYPES.STANDARD,
  withInput: false,
  fixedValues: [],
  options: [],
  withTooltip: false,
  stayOpen: false,
  renderTooltip: noop,
  renderSelectedValue: noop,
  noOptionsComponent: null,
  width: 'auto',
  hoverType: DS_SELECT_HOVER_TYPES.DEFAULT,
  truncateMiddle: false,
  horizontalLabel: false
};

Select.propTypes = {
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  extraOptionsProps: PropTypes.object,
  stayOpen: PropTypes.bool,
  hideSource: PropTypes.bool,
  inputValue: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
  options: PropTypes.array,
  fixedValues: PropTypes.array,
  placeholder: PropTypes.string,
  noOptionsComponent: PropTypes.any,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  valueAlign: PropTypes.string,
  variant: PropTypes.oneOf([
    DS_SELECT_TYPES.STANDARD,
    DS_SELECT_TYPES.OUTLINED
  ]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withInput: PropTypes.bool,
  withTooltip: PropTypes.bool,
  renderTooltip: PropTypes.func,
  renderSelectedValue: PropTypes.func,
  hoverType: PropTypes.oneOf(Object.values(DS_SELECT_HOVER_TYPES)),
  truncateMiddle: PropTypes.bool,
  horizontalLabel: PropTypes.bool
};

export default Select;
