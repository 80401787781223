import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import isEmpty from 'lodash/isEmpty';

import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';

import ChartNameField from '@experiment-management-shared/components/ChartNameField';
import { getVisualizationThumbnailURL } from '@experiment-management-shared/utils/visualizationsHelper';
import visualizationsActions from '@/actions/visualizationsActions';
import { getTemplateName } from '@/selectors/visualizationSelectors';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import MarkdownIFrame from '@shared/components/MarkdownIFrame';
import { useThumbnailActions } from '@experiment-management-shared/components/VizEditor/hooks';

const MARKDOWN = 'Markdown';
const PREVIEW = 'Preview';

const DESCRIPTION_STYLES = `<style>
html,
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}
</style>`;

const DescriptionTab = ({
  canEdit,
  children,
  description,
  dispatch,
  templateId,
  templateName,
  thumbnail
}) => {
  const [activeTab, setActiveTab] = useState(MARKDOWN);
  const { openModal } = useThumbnailActions(templateId);
  const handleThumbnailClick = () => {
    if (canEdit) {
      openModal();
    }
  };

  const handleUpdateTemplateName = newTemplateName => {
    dispatch(visualizationsActions.updateTemplateNameField(newTemplateName));
  };

  const renderThumbnail = () => {
    if (thumbnail) {
      return <img className="chart-thumbnail-image" src={thumbnail} />;
    }

    if (templateId) {
      const url = getVisualizationThumbnailURL(templateId);

      return <img className="chart-thumbnail-image" src={url} />;
    }

    return <i className="material-icons thumbnail-chart-icon">photo_camera</i>;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabs = () => {
    return [MARKDOWN, PREVIEW].map(tabName => {
      return (
        <Tab
          key={tabName}
          value={tabName}
          label={tabName}
          disabled={tabName === PREVIEW && isEmpty(description)}
          style={{ minWidth: 'fit-content' }}
        />
      );
    });
  };

  const renderTabBar = () => {
    return (
      <Tabs className="tab-bar" value={activeTab} onChange={handleTabChange}>
        {renderTabs()}
      </Tabs>
    );
  };

  const renderActiveTab = () => {
    if (activeTab === MARKDOWN) {
      return children;
    }

    return (
      <MarkdownIFrame textString={description} styles={DESCRIPTION_STYLES} />
    );
  };

  return (
    <div className="non-code-editor-tab">
      <div className="description-tab-header">
        <Tooltip
          title="Click to edit thumbnail"
          placement="top"
          disableHoverListener={!canEdit}
        >
          <div
            className="chart-thumbnail chart-thumbnail-select"
            onClick={handleThumbnailClick}
          >
            {renderThumbnail()}
          </div>
        </Tooltip>
        <div className="chart-name-container">
          <InputLabel className="modal-input-label">Chart Name</InputLabel>
          <ChartNameField
            placeholder={`Default ${PANEL_ENTITY_NAME} name`}
            chartNameEditHandler={handleUpdateTemplateName}
            currentName={templateName}
            isCodeEditor
          />
        </div>
      </div>
      {renderTabBar()}
      {renderActiveTab()}
    </div>
  );
};

DescriptionTab.defaultProps = {
  thumbnail: null,
  templateId: null
};

DescriptionTab.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  templateId: PropTypes.string,
  templateName: PropTypes.string.isRequired,
  thumbnail: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const { revisionId } = props.match.params;
  const { templateId } = props;

  return {
    canEdit: isUserAllowedToEditProject(state),
    thumbnail: state.ui.visualizations.thumbnail,
    templateName: getTemplateName(state, { templateId, revisionId })
  };
};

export default withRouter(connect(mapStateToProps)(DescriptionTab));
