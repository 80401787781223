import React, { useState, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';

import Divider from '@material-ui/core/Divider';

import SmallLoader from '@shared/components/SmallLoader';
import ArtifactVersionRow from './ArtifactVersionRow';
import {
  compareSemanticVersions,
  getUniqValuesFromArray,
  getUniqValuesFromArrayOfObjects
} from '@/helpers/generalHelpers';
import FilterPopover from '@shared/components/FilterPopover';
import {
  filterItems,
  searchItems,
  sortItems,
  SORT_TOOLTIP,
  SORT_BY_DIRECTION
} from '@/constants/workspaceConstants';
import StyledSearchBar from '@shared/components/StyledComponents/StyledSearchBar';
import SortByPopover from '@shared/components/WorkspaceSubComponents/SortByPopover';
import RadioFilterPopover from './RadioFilterPopover';
import { useExperimentArtifacts } from '@experiment-details/api';
import ArtifactsTabEmpty from './ArtifactsTabEmpty';

const FILTERABLE_KEYS = {
  ARTIFACT_NAME: {
    key: 'artifactName',
    label: 'Name',
    tooltip: SORT_TOOLTIP.ALPHA
  },
  ARTIFACT_TYPE: {
    key: 'artifactType',
    label: 'Type',
    tooltip: SORT_TOOLTIP.ALPHA
  },
  ADDED: { key: 'added', label: 'Added on', tooltip: SORT_TOOLTIP.DATE },
  SIZE: { key: 'size', label: 'Size', tooltip: SORT_TOOLTIP.NUMBER },
  VERSION: { key: 'version', label: 'Version', tooltip: SORT_TOOLTIP.NUMBER },
  TAGS: { key: 'tags', label: 'Tags' },
  ALIAS: { key: 'alias', label: 'Alias' }
};

const {
  ARTIFACT_NAME,
  ARTIFACT_TYPE,
  ADDED,
  SIZE,
  VERSION,
  TAGS,
  ALIAS
} = FILTERABLE_KEYS;

const getFilters = items => {
  return [
    {
      groupLabel: ARTIFACT_NAME.label,
      filterKey: ARTIFACT_NAME.key,
      options: getUniqValuesFromArrayOfObjects(items, ARTIFACT_NAME.key).map(
        state => ({
          label: upperFirst(state),
          value: state
        })
      )
    },
    {
      groupLabel: ARTIFACT_TYPE.label,
      filterKey: ARTIFACT_TYPE.key,
      options: getUniqValuesFromArrayOfObjects(items, ARTIFACT_TYPE.key).map(
        type => ({
          label: type,
          value: type
        })
      )
    },
    {
      groupLabel: TAGS.label,
      filterKey: TAGS.key,
      options: getUniqValuesFromArray(items, TAGS.key).map(tag => ({
        label: tag,
        value: tag
      }))
    },
    {
      groupLabel: ALIAS.label,
      filterKey: ALIAS.key,
      options: getUniqValuesFromArray(items, ALIAS.key).map(alias => ({
        label: alias,
        value: alias
      }))
    }
  ];
};

const DIRECTION_FILTER = {
  groupLabel: 'Direction',
  filterKey: 'direction',
  options: [
    { label: 'All', value: 'all' },
    { label: 'Input', value: 'input' },
    { label: 'Output', value: 'output' }
  ]
};

const SORT_ITEMS = [ARTIFACT_NAME, ARTIFACT_TYPE, ADDED, SIZE, VERSION];

const ExperimentArtifactsTab = ({ experimentKey }) => {
  const { workspace: workspaceName } = useRouteMatch();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedDirectionFilter, setSelectedDirectionFilter] = useState(
    get(DIRECTION_FILTER, ['options', 0], {})
  );
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState({
    property: ARTIFACT_NAME.key,
    direction: SORT_BY_DIRECTION.ASC
  });

  const {
    data: artifactVersions = [],
    isLoading,
    isFetching
  } = useExperimentArtifacts(
    {
      experimentKey,
      direction: selectedDirectionFilter.value
    },
    {
      keepPreviousData: true
    }
  );

  const isDataEmpty = !artifactVersions.length;

  const handleFilterChange = useCallback(
    (filterKey, values) => {
      const update = {
        ...selectedFilters,
        [filterKey]: values
      };
      setSelectedFilters(update);
    },
    [selectedFilters]
  );

  const handleSearchChange = useCallback(text => setSearchText(text), []);
  const handleCancelSearch = useCallback(() => setSearchText(''), []);

  const handleSortByChange = useCallback((property, direction) => {
    setSort({ property, direction });
  }, []);

  const handleAssetDirectionFilter = (_, value) => {
    setSelectedDirectionFilter(value);
  };

  const filteredItems = useMemo(() => {
    return filterItems(artifactVersions, selectedFilters);
  }, [selectedFilters, artifactVersions]);

  const searchedItems = useMemo(() => {
    const searchableFields = [
      ARTIFACT_NAME.key,
      ARTIFACT_TYPE.key,
      VERSION.key
    ];
    return searchItems(filteredItems, searchText, searchableFields);
  }, [filteredItems, searchText]);

  const visibleRows = useMemo(() => {
    if (sort.property === VERSION.key) {
      return sortItems(
        searchedItems,
        sort.direction,
        sort.property,
        compareSemanticVersions
      );
    }

    return sortItems(searchedItems, sort.direction, sort.property);
  }, [searchedItems, sort]);

  const renderActionFields = () => {
    const filters = getFilters(artifactVersions);
    return (
      <div className="action-fields-container">
        <div className="field-group">
          {filters.map(filter => (
            <Fragment key={filter.filterKey}>
              <FilterPopover
                className="light"
                handleFilterChange={handleFilterChange}
                selectedFilters={get(selectedFilters, [filter.filterKey], [])}
                filter={filter}
                disabled={isDataEmpty}
              />

              <Divider orientation="vertical" style={{ height: 20 }} />
            </Fragment>
          ))}

          <RadioFilterPopover
            className="light"
            handleFilterChange={handleAssetDirectionFilter}
            selectedOption={selectedDirectionFilter}
            filter={DIRECTION_FILTER}
            disabled={isDataEmpty}
          />

          <StyledSearchBar
            entityName="Artifacts"
            handleCancelSearch={handleCancelSearch}
            handleSearchChange={handleSearchChange}
            style={{ marginLeft: 10, minWidth: 'auto' }}
            disabled={isDataEmpty}
          />
        </div>
        <div className="field-group">
          <SortByPopover
            className="light"
            handleSortByChange={handleSortByChange}
            sortByProperty={sort.property}
            sortByDirection={sort.direction}
            items={SORT_ITEMS}
            disabled={isDataEmpty}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching artifact versions"
      />
    );
  }

  const renderEmptyState = () => {
    if (isDataEmpty) return <ArtifactsTabEmpty />;

    if (!visibleRows.length)
      return (
        <h3 className="empty-preview-message">
          No matching artifact versions.
        </h3>
      );

    return null;
  };

  const renderArtifactVersionRows = () => {
    return (
      <div style={{ opacity: isFetching ? 0.3 : 1.0 }}>
        {visibleRows.map(artifactVersion => {
          const { artifactVersionId } = artifactVersion;

          return (
            <ArtifactVersionRow
              key={artifactVersionId}
              artifactVersion={artifactVersion}
              workspaceName={workspaceName}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {renderActionFields()}
      {renderEmptyState()}
      {renderArtifactVersionRows()}
    </div>
  );
};

ExperimentArtifactsTab.propTypes = {
  experimentKey: PropTypes.string.isRequired
};

export default ExperimentArtifactsTab;
