import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

export default function useDashboardSectionRedirect({
  section,
  workspace,
  projectName,
  targetSection
}) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (targetSection && section !== targetSection) {
      history.replace({
        ...location,
        pathname: `/${workspace}/${projectName}/${targetSection}`
      });
    }
  }, [targetSection, history, location, projectName, section, workspace]);
}
