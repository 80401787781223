import find from 'lodash/find';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import last from 'lodash/last';

export const DEFAULT_NUM_BINS = 30;
export const OUTLIERS_PERCENTAGE = 0.1;

export const isHistogramEmpty = ({
  index_values = [],
  values = [],
  version
}) => {
  if (version === 1) {
    return !(values || []).length;
  }

  return !index_values.length;
};

export const getHistogramValuesByVersion = ({
  index_values,
  values,
  version
}) => {
  if (version === 1) return values || [];

  if (!index_values.length) return [];

  const indexes = index_values.map(([index]) => index);
  const maxIndex = Math.max(...indexes);
  const valuesV1 = Array(maxIndex + 1).fill(0);

  index_values.forEach(([index, value]) => {
    valuesV1[index] = value;
  });

  return valuesV1;
};

const createBucketsLeft = ({ offset, start, step, stop }) => {
  const values = [-Infinity, offset, +Infinity];
  let value = start;

  while (value <= stop) {
    values.splice(1, 0, offset - value);
    values.splice(-1, 0, offset + value);

    value *= step;
  }

  return values;
};

const createBuckets = (bucketsLeft, values) => {
  return values.map((value, i) => ({
    count: value,
    left: bucketsLeft[i],
    right: i < bucketsLeft.length ? bucketsLeft[i + 1] : Infinity
  }));
};

const createBucketsFromHistogram = histogram => {
  const { offset, start, step, stop } = histogram;
  const bucketsLeft = createBucketsLeft({
    offset,
    start,
    step,
    stop
  });

  const values = getHistogramValuesByVersion(histogram);

  return createBuckets(bucketsLeft, values);
};

const getEdgeValues = (buckets, ignoreOutliers = OUTLIERS_PERCENTAGE) => {
  const totalCount = buckets.reduce((partialCount, bucket) => {
    return partialCount + bucket.count;
  }, 0);
  const limitCount = (totalCount * ignoreOutliers) / 100;
  const firstBucketWithValue = find(
    buckets,
    bucket => bucket.count > limitCount
  );
  const lastBucketWithValue = findLast(
    buckets,
    bucket => bucket.count > limitCount
  );
  const max = get(lastBucketWithValue, 'right', 0);
  const min = get(firstBucketWithValue, 'left', 0);

  return { max, min };
};

// https://github.com/tensorflow/tensorboard/blob/6461f9fd6a5055769a274da5799c28363e34eca7/tensorboard/plugins/histogram/tf_histogram_dashboard/histogramCore.ts#L90
// Terminology note: _buckets_ are the input to this function,
// while _bins_ are our output.
const getBinCounts = ({
  buckets,
  max,
  min,
  numBins = DEFAULT_NUM_BINS
} = {}) => {
  if (max === min) {
    // Create bins even if all the data has a single value.
    max = min * 1.1 + 1;
    min = min / 1.1 - 1;
  }

  const binWidth = (max - min) / numBins;

  const binRange = Array(numBins)
    .fill(null)
    .map((_, i) => {
      return min + binWidth * i;
    });

  let bucketIndex = 0;
  const bins = binRange.map(binLeft => {
    const binRight = binLeft + binWidth;

    // Take the count of each existing bucket, multiply it by the proportion
    // of overlap with the new bin, then sum and store as the count for the
    // new bin. If no overlap, will add to zero, if 100% overlap, will include
    // the full count into new bin.
    let binY = 0;

    while (bucketIndex < buckets.length) {
      const bucket = buckets[bucketIndex];
      // Clip the right edge because right-most edge can be
      // infinite-sized.
      const bucketRight = Math.min(max, bucket.right);
      const bucketLeft = Math.max(min, bucket.left);

      const intersect =
        Math.min(bucketRight, binRight) - Math.max(bucketLeft, binLeft);
      const count = (intersect / (bucketRight - bucketLeft)) * bucket.count;

      binY += intersect > 0 ? count : 0;

      // If `bucketRight` is bigger than `binRight`, then this bin is
      // finished and there is data for the next bin, so don't increment
      // `bucketIndex`.
      if (bucketRight > binRight) {
        break;
      }
      bucketIndex += 1;
    }

    return { x: binLeft, y: binY };
  });

  return [
    { x: bins[0].x - binWidth, y: 0 },
    ...bins,
    { x: bins[bins.length - 1].x + binWidth, y: 0 }
  ];
};

export const getFilteredDataSources = (dataSources, maxSteps) => {
  let steps = 0;
  const histogramsMaxValues = dataSources.map(({ histogram }) => {
    const values = getHistogramValuesByVersion(histogram);

    return Math.max(...values);
  });
  const maxValue = Math.max(...histogramsMaxValues);
  const maxValueIndex = histogramsMaxValues.findIndex(
    value => value === maxValue
  );

  const filteredDataSources = dataSources.filter((v, index) => {
    const currentIndexValue = (maxSteps / dataSources.length) * index;

    if (maxValueIndex === index || currentIndexValue >= steps) {
      steps += 1;
      return true;
    }

    return false;
  });

  if (last(filteredDataSources) !== last(dataSources)) {
    filteredDataSources.push(last(dataSources));
  }

  return filteredDataSources;
};

export const removeZeroYValues = bins => {
  let x = bins.map(bin => bin.x);
  let y = bins.map(bin => bin.y);
  const indexes = Array(bins.length)
    .fill(null)
    .map((_, index) => index)
    .filter((_, index) => {
      const prevIndex = Math.max(index - 1, 0);
      const nextIndex = Math.min(index + 1, bins.length - 1);

      return !!y[index] || !!y[prevIndex] || !!y[nextIndex];
    });

  x = x.filter((_, index) => indexes.includes(index));
  y = y.filter((_, index) => indexes.includes(index));

  return { x, y };
};

export const getHistogramsBinCounts = (
  histograms = [],
  ignoreOutliers = OUTLIERS_PERCENTAGE
) => {
  const histogramsWithValues = histograms.filter(
    histogram => !isHistogramEmpty(histogram.histogram)
  );
  const histogramsData = histogramsWithValues.map(
    histogram => histogram.histogram
  );
  const histogramSteps = histogramsWithValues.map(histogram => histogram.step);
  const histogramsBuckets = histogramsData.map(createBucketsFromHistogram);
  const histogramsEdgeValues = histogramsBuckets.map(bucket =>
    getEdgeValues(bucket, ignoreOutliers)
  );
  const histogramsMaxs = histogramsEdgeValues.map(edgeValues => edgeValues.max);
  const histogramsMins = histogramsEdgeValues.map(edgeValues => edgeValues.min);
  const stepMax = Math.max(...histogramSteps);
  const stepMin = Math.min(...histogramSteps);
  const xMax = Math.max(...histogramsMaxs);
  const xMin = Math.min(...histogramsMins);
  const steps = histogramsWithValues.map(({ step }, index) => {
    const buckets = histogramsBuckets[index];
    const bins = getBinCounts({
      buckets,
      max: xMax,
      min: xMin
    });

    return {
      bins,
      step
    };
  }, []);

  return {
    stepMax,
    stepMin,
    steps,
    xMax,
    xMin
  };
};

const firstColor = {
  r: 198,
  g: 62,
  b: 23
};

const lastColor = {
  r: 255,
  g: 162,
  b: 112
};

const getSpecificColor = (index, range, color) => {
  return Math.round(
    firstColor[color] + ((lastColor[color] - firstColor[color]) * index) / range
  );
};

export const getColor = (index, range) => {
  const colors = [
    getSpecificColor(index, range, 'r'),
    getSpecificColor(index, range, 'g'),
    getSpecificColor(index, range, 'b')
  ];

  const hexColor = colors
    .map(color => color.toString(16).padStart(2, '0'))
    .join('');

  return `#${hexColor}`;
};
