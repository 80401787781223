import React from 'react';
import FormMetricModal from './FormMetricModal';
import { ExperimentDetails, Metric } from '@shared/types';
import useAddMetricApi from '../hooks/useAddMetricApi';

export type AddMetricParamModalProps = {
  rowData: Metric[];
  experiment: ExperimentDetails;
  onClose: () => void;
  isOpened: boolean;
};
const AddMetricParamModal: React.FC<AddMetricParamModalProps> = ({
  rowData,
  experiment,
  onClose,
  isOpened
}) => {
  const addMetricModalConfig = useAddMetricApi({
    rowData,
    experiment,
    onClose,
    isOpened
  });
  return (
    <FormMetricModal
      {...addMetricModalConfig}
      title="Add metric"
      primaryButtonText="Add"
      onClose={onClose}
      isOpened={isOpened}
    />
  );
};

export default AddMetricParamModal;
