import {
  CONFUSION_MATRIX_CELL_VALUES,
  CONFUSION_MATRIX_COLOR_DISTRIBUTIONS,
  EXPERIMENT_VIEW_TAB_FIELDS
} from '@experiment-management-shared/constants/chartConstants';
import {
  STEP,
  WALL
} from '@experiment-management-shared/constants/experimentConstants';
import {
  DEFAULT_IGNORE_OUTLIERS,
  DEFAULT_MAX_STEPS_PER_HISTOGRAM
} from '@experiment-management-shared/constants/histogram';
import { SORT_TYPES } from '@experiment-management-shared/constants/tabConfigs';
import { COLOR_SINGLE_EXPERIMENT_KEY, METRIC_COLORS_V2 } from './panels';
import { ExperimentTemplate } from '@experiment-details/types';

export const CURRENT_TEMPLATE_VERSION = 5;

const INITIAL_METRIC_COLOR_STATE = {
  [COLOR_SINGLE_EXPERIMENT_KEY]: {
    isSingleExperimentColor: false,
    singleExperimentColor: false,
    colors: []
  }
};

export const DEFAULT_EXPERIMENT_DETAILS_TEMPLATE: ExperimentTemplate = {
  charts: [],
  smoothing: 0,
  x_axis: WALL,
  transformY: null,
  template_name: '',
  project_id: '',
  template_id: '',
  entire_row_charts: false,
  outliers: { isVisible: true, percentile: 0 },
  metricColors: [],
  [METRIC_COLORS_V2]: INITIAL_METRIC_COLOR_STATE,
  layout: null,
  version: 0,
  [EXPERIMENT_VIEW_TAB_FIELDS.AUDIO as 'audio']: {
    groupBy: false,
    orderBy: 'desc',
    sortBy: SORT_TYPES.NAME
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX as 'confusionMatrix']: {
    assetsIds: [],
    compareAssetsIds: [],
    cellValue: CONFUSION_MATRIX_CELL_VALUES.COUNTS,
    colorDistribution: CONFUSION_MATRIX_COLOR_DISTRIBUTIONS.EQUAL
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.HISTOGRAM as 'histogram']: {
    groupBy: false,
    ignoreOutliers: DEFAULT_IGNORE_OUTLIERS,
    maxSteps: DEFAULT_MAX_STEPS_PER_HISTOGRAM,
    orderBy: 'desc',
    sortBy: SORT_TYPES.NAME
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.IMAGES as 'images']: {
    groupBy: false,
    orderBy: 'desc',
    sortBy: SORT_TYPES.NAME
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.HYPERPARAMS as 'hyperParams']: {
    decimalsPrecision: null
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.OTHER as 'otherParams']: {
    decimalsPrecision: null
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.METRICS as 'metrics']: {
    decimalsPrecision: null
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.PANELS as 'panels']: {
    isAutoRefreshEnabled: true,
    layout: null,
    panels: [],
    sections: [],
    version: CURRENT_TEMPLATE_VERSION,
    compareXAxis: STEP
  },
  [EXPERIMENT_VIEW_TAB_FIELDS.TEXT as 'text']: {
    groupBy: false,
    orderBy: 'desc',
    sortBy: SORT_TYPES.STEP
  }
};
