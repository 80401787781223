import React, { ReactNode } from 'react';

import { SearchListProvider } from './useSearchListContext';
import {
  ListEmpty,
  ListFooter,
  ListFooterActionLink,
  ListNotFound,
  ListSearch,
  SearchableListResult,
  SearchableListVirtualizedResult
} from './components';
import classes from './List.module.scss';
import OptionItem from '../../inputs/Select/components/OptionItem/OptionItem';

interface ListProps<T, K = keyof T> {
  itemList: T[];
  searchValue?: string;
  searchField: K;
  searchInputDataTest?: string;
  isRegexSearchEnabled?: boolean;
  searchPlaceholder?: string;
  children: ReactNode;
}

const SearchableList = <T extends object>({
  children,
  searchInputDataTest = 'searchable-list-search',
  searchPlaceholder,
  ...searchOptions
}: ListProps<T>) => {
  const defaultPlaceholder = searchOptions.isRegexSearchEnabled
    ? 'Search (regex)'
    : 'Search...';

  return (
    <div className={classes.searchableListContainer}>
      <SearchListProvider {...searchOptions}>
        <ListSearch
          data-test={searchInputDataTest}
          placeholder={searchPlaceholder || defaultPlaceholder}
        />
        {children}
      </SearchListProvider>
    </div>
  );
};

SearchableList.Result = SearchableListResult;
SearchableList.VirtualizedResult = SearchableListVirtualizedResult;
SearchableList.NotFound = ListNotFound;
SearchableList.Empty = ListEmpty;
SearchableList.Footer = ListFooter;
SearchableList.FooterActionLink = ListFooterActionLink;
SearchableList.Option = OptionItem;
export default SearchableList;
