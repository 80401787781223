import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import LockIcon from '@material-ui/icons/Lock';
import PublicIcon from '@material-ui/icons/Public';
import RedirectLink from '@shared/components/RedirectLink';

const VISIBILITY_LABEL = 'isPublic';

const ArtifactDetails = ({ name, emoji, metadata, children }) => {
  const renderMetadata = () => {
    return (
      <div className="artifact-metadata">
        {metadata.map(
          (
            { dataMaskTest, label, value, resource, redirectUrlArgs },
            index
          ) => {
            if (label === VISIBILITY_LABEL) {
              const VisibilityIcon = value ? PublicIcon : LockIcon;
              const visibilityText = value ? 'Public' : 'Private';
              return (
                <span className="artifact-metadata-detail" key={value}>
                  <VisibilityIcon className="artifact-metadata-visibility-icon" />
                  <span className="artifact-metadata-value">
                    {visibilityText}
                  </span>
                  <Divider orientation="vertical" style={{ height: 20 }} />
                </span>
              );
            }
            return (
              <div key={label} className="artifact-metadata-detail">
                <span className="artifact-metadata-label">{label}:</span>
                <span
                  className="artifact-metadata-value"
                  data-mask-test={dataMaskTest}
                >
                  {resource && redirectUrlArgs ? (
                    <RedirectLink resource={resource} args={redirectUrlArgs}>
                      {value}
                    </RedirectLink>
                  ) : (
                    value
                  )}
                </span>

                {index !== metadata.length - 1 && (
                  <Divider orientation="vertical" style={{ height: 20 }} />
                )}
              </div>
            );
          }
        )}
      </div>
    );
  };

  return (
    <div className="artifact-details">
      <div>
        <div className="artifact-name">
          {emoji && <div className="artifact-emoji">{emoji}</div>} {name}{' '}
          {children}
        </div>
        {renderMetadata()}
      </div>
    </div>
  );
};

ArtifactDetails.propTypes = {
  children: PropTypes.node.isRequired,
  emoji: PropTypes.string.isRequired,
  metadata: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default ArtifactDetails;
