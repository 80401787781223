// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LLMDashboardHeader-module__llmDashboardHeader--LehMU{display:flex;flex-direction:row;align-items:center;height:var(--llm-header-height);padding:7px 20px 0;gap:16px}.LLMDashboardHeader-module__spacer--uscaz{padding-right:15px}.LLMDashboardHeader-module__searchInputWrapper--QCPzU{max-width:800px;flex-grow:1}.LLMDashboardHeader-module__operatorItemRowContainer--YRq5m{display:flex;align-items:center;justify-content:space-between;width:100%;color:var(--gray-6)}.LLMDashboardHeader-module__operatorsListHeader--l1P5k{font-size:14px;display:flex;align-items:center;color:#191a1c;margin:0 18px}.LLMDashboardHeader-module__operatorsListHeader--l1P5k p{margin-left:7px}.LLMDashboardHeader-module__operatorItemRowInnerContainer--DMom8{display:flex;align-items:center}.LLMDashboardHeader-module__operatorContainer--vXzDs{padding:5px;border-radius:3px;margin-left:7px;border:1px solid var(--gray-3)}.LLMDashboardHeader-module__searchBarContainer--rJkhg{display:flex;column-gap:6px;flex-grow:1;height:36px}.LLMDashboardHeader-module__searchBarContainer--rJkhg button{margin-top:auto;margin-bottom:auto}.LLMDashboardHeader-module__searchDropdownWrapper--t0kwo{margin-top:4px}.LLMDashboardHeader-module__selectWrapper--oHou4{width:200px;flex-shrink:0}.LLMDashboardHeader-module__controlsContainer--uyeQw{display:flex}.LLMDashboardHeader-module__popperContainer--K8itp{padding:12px 8px !important}.LLMDashboardHeader-module__markdown--LI866{padding:5px;border-radius:3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"llmDashboardHeader": `LLMDashboardHeader-module__llmDashboardHeader--LehMU`,
	"spacer": `LLMDashboardHeader-module__spacer--uscaz`,
	"searchInputWrapper": `LLMDashboardHeader-module__searchInputWrapper--QCPzU`,
	"operatorItemRowContainer": `LLMDashboardHeader-module__operatorItemRowContainer--YRq5m`,
	"operatorsListHeader": `LLMDashboardHeader-module__operatorsListHeader--l1P5k`,
	"operatorItemRowInnerContainer": `LLMDashboardHeader-module__operatorItemRowInnerContainer--DMom8`,
	"operatorContainer": `LLMDashboardHeader-module__operatorContainer--vXzDs`,
	"searchBarContainer": `LLMDashboardHeader-module__searchBarContainer--rJkhg`,
	"searchDropdownWrapper": `LLMDashboardHeader-module__searchDropdownWrapper--t0kwo`,
	"selectWrapper": `LLMDashboardHeader-module__selectWrapper--oHou4`,
	"controlsContainer": `LLMDashboardHeader-module__controlsContainer--uyeQw`,
	"popperContainer": `LLMDashboardHeader-module__popperContainer--K8itp`,
	"markdown": `LLMDashboardHeader-module__markdown--LI866`
};
module.exports = ___CSS_LOADER_EXPORT___;
