import { TextButton } from '@ds';
import Popover from '@material-ui/core/Popover';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ArrowDownward, CrossIcon } from '@Icons-outdated';
import './SortModal.scss';

const SortModal = ({
  anchorEl,
  currentFields,
  isAddFieldButtonDisabled,
  isAddFieldButtonHidden,
  onChangeFields,
  onClose,
  onReset,
  onToggleDirection,
  renderSelectField,
  ...popoverProps
}) => {
  const handleAddField = () => {
    onChangeFields([...currentFields, '']);
  };

  const handleRemoveField = index => {
    onChangeFields([
      ...currentFields.slice(0, index),
      ...currentFields.slice(index + 1)
    ]);
  };

  const sortButton = (index, direction = 'asc') => {
    return (
      <button
        type="button"
        onClick={() => onToggleDirection(index)}
        data-test="sorting-models-order-btn"
      >
        <ArrowDownward className={cx('sort-arrow', direction)} />
      </button>
    );
  };

  const deleteFieldButton = index => {
    return (
      <button
        type="button"
        onClick={() => handleRemoveField(index)}
        disabled={currentFields.length === 1}
      >
        <CrossIcon />
      </button>
    );
  };

  const renderField = ({ sortKey, direction }, index) => {
    return (
      <div key={sortKey} className="field">
        {renderSelectField(sortKey, index)}
        <div className="field-actions">
          {sortButton(index, direction)}
          {deleteFieldButton(index)}
        </div>
      </div>
    );
  };

  return (
    <Popover
      classes={{ root: 'ds-sort-modal' }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      elevation={0}
      disableEnforceFocus
      {...popoverProps}
    >
      <div className="header">
        <div className="header-title">Sort</div>
        <TextButton
          onClick={onReset}
          data-test="reset-model-sorting"
          type="tertiary"
        >
          Reset to default
        </TextButton>
      </div>

      <div className="body">{currentFields.map(renderField)}</div>

      {!isAddFieldButtonHidden && (
        <div className="footer">
          <TextButton
            onClick={handleAddField}
            disabled={isAddFieldButtonDisabled}
            type="tertiary"
          >
            Add another field
          </TextButton>
        </div>
      )}
    </Popover>
  );
};

SortModal.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  currentFields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      direction: PropTypes.string
    })
  ).isRequired,
  isAddFieldButtonDisabled: PropTypes.bool,
  isAddFieldButtonHidden: PropTypes.bool,
  onChangeFields: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggleDirection: PropTypes.func.isRequired,
  renderSelectField: PropTypes.func.isRequired
};

SortModal.defaultProps = {
  anchorEl: null,
  isAddFieldButtonDisabled: false,
  isAddFieldButtonHidden: false
};

export default SortModal;
