import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'react-loaders';

const SmallLoader = ({
  animationType,
  disableAnimations,
  isFlexDisplay,
  primaryMessage,
  secondaryMessage,
  dataTest
}) => (
  <div
    data-test={dataTest}
    className={classnames({
      'loader-flex': isFlexDisplay,
      'loader-wrapper': !isFlexDisplay,
      'loader-wrapper-disable-animations': disableAnimations
    })}
  >
    {!disableAnimations && (
      <Loader
        type={animationType}
        color="#5055f5"
        innerClassName="loader-inner"
      />
    )}
    {primaryMessage ? (
      <h3 className="primary-loading-message loader-message">
        {primaryMessage}
      </h3>
    ) : null}
    {secondaryMessage ? (
      <h3 className="secondary-loading-message loader-message">
        {secondaryMessage}
      </h3>
    ) : null}
  </div>
);

SmallLoader.defaultProps = {
  dataTest: 'small-loader',
  animationType: 'ball-scale-multiple',
  disableAnimations: false,
  isFlexDisplay: false,
  primaryMessage: null,
  secondaryMessage: null
};

SmallLoader.propTypes = {
  dataTest: PropTypes.string,
  animationType: PropTypes.string,
  disableAnimations: PropTypes.bool,
  isFlexDisplay: PropTypes.bool,
  primaryMessage: PropTypes.node,
  secondaryMessage: PropTypes.node
};

export default SmallLoader;
