import { Button } from '@ds';
import { BasicModal } from '@DesignSystem/modals';
import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { snackbarTypes } from '../../../../constants/alertTypes';
import alertsUtil from '../../../../util/alertsUtil';
import styles from './EmbeddedCodeModal.module.scss';

const EmbeddedCodeModal = ({ modalId, url }) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleClick = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_SHARE_LINK,
        'Successfully copied embedded report code to clipboard'
      )
    );

    onClose();
  };

  const snippet = `<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="600" src="${url}">`;

  return (
    <BasicModal
      title="Copy embedded code"
      onClose={onClose}
      open
      content={
        <div className={styles.modal}>
          <div className={styles.code}>{snippet}</div>

          <div className={styles.footer}>
            <Button onClick={onClose} size="large" type="tertiary">
              Cancel
            </Button>

            <CopyToClipboard text={snippet}>
              <Button onClick={handleClick} size="large">
                Copy
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      }
    />
  );
};

EmbeddedCodeModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default EmbeddedCodeModal;
