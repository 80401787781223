import { minMaxColumnToOption } from '@shared/utils/selectComponentHelper';

export const constructFilterDefinition = columns => {
  if (columns.length === 0) {
    return [];
  }
  return columns.map(column => {
    const { type, name: field, source } = column;

    const subOptions = minMaxColumnToOption(column, {
      field,
      type
    });

    return {
      field,
      label: field,
      value: field,
      type,
      source,
      secondary: source,
      extraOptions: subOptions
    };
  });
};
