// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StringLinkSamples-module__stringSamplesList--kPrSy{display:flex;flex-direction:column;min-height:0;min-width:0;width:100%;gap:6px}.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleLink--Hj72L{height:18px;line-height:1}.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleString--rrUTR,.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleLink--Hj72L a{flex-shrink:0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;line-height:18px;color:var(--black);cursor:pointer}.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleString--rrUTR:hover,.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleLink--Hj72L a:hover{color:var(--primary-color)}.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleString--rrUTR:active,.StringLinkSamples-module__stringSamplesList--kPrSy .StringLinkSamples-module__sampleLink--Hj72L a:active{color:var(--primary-color-click)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stringSamplesList": `StringLinkSamples-module__stringSamplesList--kPrSy`,
	"sampleLink": `StringLinkSamples-module__sampleLink--Hj72L`,
	"sampleString": `StringLinkSamples-module__sampleString--rrUTR`
};
module.exports = ___CSS_LOADER_EXPORT___;
