import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import pick from 'lodash/pick';

import {
  MetricColorMap,
  PanelGlobalConfig
} from '@experiment-management-shared/types';

import {
  BUILT_IN_CHART_TYPES,
  CUSTOM_CHART_TYPE,
  defaultFormFields
} from '@experiment-management-shared/constants';
import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import {
  CHART_GALLERY_TYPES,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';

export type UsePanelModalMenuHandlersParams = {
  sectionId: string;
  panelGlobalConfig: PanelGlobalConfig;
  addPanelEventName: string;
  defaultSampleSize: number;
  metricColorMap?: MetricColorMap;
};

const usePanelModalMenuHandlers = ({
  sectionId,
  panelGlobalConfig,
  addPanelEventName,
  defaultSampleSize,
  metricColorMap
}: UsePanelModalMenuHandlersParams) => {
  const baseTrackEvent = useBaseTrackEvent();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const handleEditBuiltInPanelClick = useCallback(
    config => {
      const { chartId, chartType } = config;

      const chartCategory = 'built-in';
      const hash = `#${SELECT_PANEL_PATH}?chartCategory=${chartCategory}&chartType=${chartType}&chartId=${chartId}`;
      const formKeys = Object.keys(defaultFormFields[chartType]);
      const chartForm = pick(config, formKeys);

      if (
        BUILT_IN_CHART_TYPES['BuiltIn/Line'] === chartType ||
        BUILT_IN_CHART_TYPES['BuiltIn/Bar'] === chartType ||
        BUILT_IN_CHART_TYPES.python === chartType
      ) {
        chartForm.metricColorMap = metricColorMap;
      }

      dispatch(dashboardChartsActions.setActiveSectionId(sectionId));
      dispatch(dashboardChartsActions.resetChartForm());
      dispatch(dashboardChartsActions.updateChartForm(chartType, chartForm));

      history.push({ ...location, hash });
    },
    [dispatch, history, location, metricColorMap, sectionId]
  );

  const handleEditCustomPanelClick = useCallback(
    ({ chartId, scopeType, templateId, instanceId }) => {
      const { FEATURED, CUSTOM } = CHART_GALLERY_TYPES;
      const { INTERNAL } = TEMPLATE_SCOPE_TYPES;
      const chartCategory = scopeType === INTERNAL ? FEATURED : CUSTOM;
      const hash = `#${SELECT_PANEL_PATH}?chartCategory=${chartCategory}&chartType=${templateId}&chartId=${chartId}&instanceId=${instanceId}`;

      dispatch(dashboardChartsActions.setActiveSectionId(sectionId));
      history.push({ ...location, hash });
    },
    [dispatch, sectionId, history, location]
  );

  const handleAddPanelClick = useCallback(() => {
    const hash = `#${SELECT_PANEL_PATH}`;
    history.replace({ ...location, hash });

    dispatch(dashboardChartsActions.setActiveSectionId(sectionId));
    dispatch(dashboardChartsActions.resetChartForm());

    dispatch(
      dashboardChartsActions.updateChartForm(
        BUILT_IN_CHART_TYPES['BuiltIn/Line'],
        {
          ...panelGlobalConfig,
          sampleSize: panelGlobalConfig.sampleSize ?? defaultSampleSize,
          metricColorMap
        }
      )
    );

    dispatch(
      dashboardChartsActions.updateChartForm(
        BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
        {
          sampleSize: panelGlobalConfig.sampleSize ?? defaultSampleSize,
          metricColorMap
        }
      )
    );

    dispatch(
      dashboardChartsActions.updateChartForm(BUILT_IN_CHART_TYPES.python, {
        metricColorMap
      })
    );

    dispatch(
      dashboardChartsActions.updateChartForm(
        BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
        {
          sampleSize: panelGlobalConfig.sampleSize ?? defaultSampleSize
        }
      )
    );

    baseTrackEvent(addPanelEventName);
  }, [
    addPanelEventName,
    baseTrackEvent,
    defaultSampleSize,
    dispatch,
    history,
    location,
    metricColorMap,
    panelGlobalConfig,
    sectionId
  ]);

  const handleEditPanelClick = useCallback(
    config =>
      config.chartType === CUSTOM_CHART_TYPE
        ? handleEditCustomPanelClick(config)
        : handleEditBuiltInPanelClick(config),
    [handleEditBuiltInPanelClick, handleEditCustomPanelClick]
  );

  return {
    handleAddPanelClick,
    handleEditPanelClick,
    handleEditBuiltInPanelClick,
    handleEditCustomPanelClick
  };
};

export default usePanelModalMenuHandlers;
