import { useCallback, useMemo } from 'react';
import {
  LegendIconType,
  PanelHoverDataSource,
  PanelTrace,
  TooltipDataPoint
} from '@experiment-management-shared/types';
import {
  generateLegendLabelForScatterChart,
  getHoverParamsFromCometMetadata,
  getIsHighlightedTrace,
  truncateLegendValue
} from '@experiment-management-shared/components/Charts/Legends/helpers';
import useScatterChartTraceHighlight from '@experiment-management-shared/components/Charts/PlotlyChart/ScatterChart/useScatterChartTraceHighlight';

const useScatterChartLegend = ({
  data,
  hoveredPoint
}: {
  data: PanelTrace[];
  hoveredPoint?: TooltipDataPoint;
}) => {
  const {
    dataWithHighlight,
    updateHoveredData,
    hoveredData
  } = useScatterChartTraceHighlight({
    hoveredPoint,
    data
  });

  const legendItems = useMemo(() => {
    const filteredTraces: PanelTrace[] = data.filter(
      trace =>
        trace?.visible !== 'legendonly' &&
        trace?.cometMetadata &&
        trace?.showlegend !== false
    );

    return filteredTraces.map(trace => {
      const { cometMetadata } = trace;
      const { experimentKey } = getHoverParamsFromCometMetadata(cometMetadata);

      const value = generateLegendLabelForScatterChart(cometMetadata);
      const { fullLabel, processedValue } = truncateLegendValue(value);

      return {
        color: (trace?.line?.color ?? '') as string,
        iconType: 'circle' as LegendIconType,
        label: processedValue,
        ...(fullLabel && { fullLabel }),
        key: value,
        metaData: {
          experimentKey
        }
      };
    });
  }, [data]);

  const calculateIsLegendItemHighlighted = useCallback(
    item => {
      if (!hoveredData.source) {
        return false;
      }

      const map: Record<PanelHoverDataSource, string[]> = {
        global: [],
        table: ['experimentKey'],
        chart: ['experimentKey'],
        legend: ['experimentKey']
      };

      const keys = map[hoveredData.source] ?? [];

      return keys.length > 0
        ? getIsHighlightedTrace(hoveredData, item.metaData, keys)
        : false;
    },
    [hoveredData]
  );

  const onHoverLegendItem = useCallback(
    item => {
      updateHoveredData({
        experimentKey: item.metaData.experimentKey,
        source: 'legend'
      });
    },
    [updateHoveredData]
  );

  const onUnhoverLegendItem = useCallback(() => {
    updateHoveredData();
  }, [updateHoveredData]);

  return {
    items: legendItems,
    onHoverItem: onHoverLegendItem,
    onUnhoverItem: onUnhoverLegendItem,
    calculateIsItemHighlighted: calculateIsLegendItemHighlighted,
    dataWithHighlight
  };
};

export default useScatterChartLegend;
