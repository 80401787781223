import axios from 'axios';
import { BASE_API_URL } from '../../lib/appConstants';

const chartApi = {
  fetchProjectTemplate(projectId, experimentKey) {
    const url = `${BASE_API_URL}chart-templates/project/get?projectId=${projectId}&experimentKey=${experimentKey}`;

    return axios.get(url);
  },

  fetchUserTemplates(projectId, experimentKey) {
    const url = `${BASE_API_URL}chart-templates/project/get-all?projectId=${projectId}&experimentKey=${experimentKey}`;

    return axios.get(url);
  },

  saveTemplate(data) {
    const url = `${BASE_API_URL}chart-templates/upsert`;
    return axios.post(url, JSON.stringify(data));
  },

  deleteTemplate(data) {
    const url = `${BASE_API_URL}chart-templates/delete`;
    return axios.post(url, JSON.stringify(data));
  },

  setProjectTemplate(data) {
    const url = `${BASE_API_URL}chart-templates/project/set`;
    return axios.post(url, JSON.stringify(data));
  }
};

export default chartApi;
