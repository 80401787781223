import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ds';
import { Box, Typography } from '@material-ui/core';
import typography from '@ds-typography';
import cx from 'classnames';
import {
  MODEL_REGISTRY_WEBHOOKS_COLUMNS,
  MODEL_REGISTRY_WEBHOOKS_LEFT_COLUMNS,
  MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS,
  MODEL_REGISTRY_WEBHOOKS_TABLE_COLUMNS,
  MODEL_VERSION_WEBHOOKS_DEFAULT_COLUMNS_ORDER,
  WEBHOOKS_EVENT_TRIGGERS_MAP
} from '@model-registry/constants/webhooks';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Table } from '@DesignSystem/tables';
import useModelRegistryWebhooks from '@model-registry/api/useModelRegistryWebhooks';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import { classNames as threeDotsMenuClassNames } from '@design-system-outdated/components/ThreeDotsMenu';
import ThreeDotsMenuAnchor from '@design-system-outdated/components/ThreeDotsMenu/ThreeDotsMenuAnchor';
import isEmpty from 'lodash/isEmpty';
import { DSColors } from '@design-system-outdated/constants';
import useModelRegistryDeleteWebhookMutation from '../../api/useModelRegistryDeleteWebhookMutation';
import useModelRegistryEditWebhookMutation from '../../api/useModelRegistryEditWebhookMutation';
import useModelRegistryCreateWebhookMutation from '../../api/useModelRegistryCreateWebhookMutation';
import { getFieldValue, isValidHttpUrl } from '../../utils/webhooks';
import ModelRegistryWebhooksEmpty from './ModelRegistryWebhooksEmpty';
import ModelRegistryWebhooksThreeDotsMenu from './ModelRegistryWebhooksThreeDotsMenu';
import SmallLoader from '@shared/components/SmallLoader';
import classNames from './ModelRegistryWebhooksTab.module.scss';
import ModelRegistryDeleteWebhookConfirmation from './ModelRegistryDeleteWebhookConfirmation';
import ModelRegistryCreateWebhookModal from './ModelRegistryCreateWebhookModal';

import { ROOT_URL } from '@/constants/configConstants';
import EmptyCell from '@shared/components/TableCells/EmptyCell';
import useModelRegistryTestWebhookMutation from '../../api/useModelRegistryTestWebhookMutation';
import ModelRegistryWebhooksStatusCell from '@model-registry/components/ModelRegistryWebhooksTab/WebhookStatus/ModelRegistryWebhooksStatusCell';

export const dataTest = 'model-registry-webhooks';

const { origin } = new URL(ROOT_URL, window.location.origin);

const getDefaultColumnWidths = () => [
  { columnName: MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName, width: 200 },
  { columnName: MODEL_REGISTRY_WEBHOOKS_COLUMNS.rowActionMenu, width: 40 },
  { columnName: MODEL_REGISTRY_WEBHOOKS_COLUMNS.status, width: 120 }
];

const COLUMNS_EXTENSION = [
  {
    columnName: MODEL_REGISTRY_WEBHOOKS_COLUMNS.status,
    align: 'center'
  }
];

const getColumnOrders = selectedColumns => {
  const newCols = [...selectedColumns];
  remove(newCols, el => MODEL_REGISTRY_WEBHOOKS_LEFT_COLUMNS.includes(el));
  remove(newCols, el => MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS.includes(el));
  return uniq([
    ...MODEL_REGISTRY_WEBHOOKS_LEFT_COLUMNS,
    ...newCols,
    ...MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS
  ]);
};

const EMPTY_WEBHOOK_DATA = {
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName]: '',
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.url]: '',
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.authKey]: '',
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.githubUrl]: false,
  // for creating a webhook the name of the column will be triggers, not eventTriggers
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers]: '',
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.id]: '',
  [MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers]: []
};

const NON_OWNER_TOOLTIP = (
  <span>
    Only workspace owners <br /> can create webhooks
  </span>
);

const ModelRegistryWebhooksTab = ({
  workspace,
  modelName,
  ifCurrentUserWsOwner
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    isOpenDeleteConfirmationModal,
    setIsOpenDeleteConfirmationModal
  ] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [
    isOpenCreateEditWebhookModal,
    setIsOpenCreateEditWebhookModal
  ] = useState(false);
  const [activeRowData, setActiveRowData] = useState(null);
  const [activeWebhookData, setActiveWebhookData] = useState({});
  const [columnWidths, setColumnWidths] = useState(
    getDefaultColumnWidths(ifCurrentUserWsOwner)
  );
  const [columnOrders, setColumnOrders] = useState(
    MODEL_VERSION_WEBHOOKS_DEFAULT_COLUMNS_ORDER
  );
  const onCloseRowMenuClick = () => {
    setActiveRowData(null);
    setAnchorEl(null);
  };

  const modelRegistryDeleteWebhookMutation = useModelRegistryDeleteWebhookMutation(
    {
      workspaceName: workspace,
      modelName
    }
  );
  const modelRegistryEditWebhookMutation = useModelRegistryEditWebhookMutation({
    workspaceName: workspace,
    modelName
  });
  const modelRegistryCreateWebhookMutation = useModelRegistryCreateWebhookMutation(
    {
      workspaceName: workspace,
      modelName
    }
  );

  const modelRegistryTestWebhookMutation = useModelRegistryTestWebhookMutation({
    workspaceName: workspace,
    modelName
  });

  const {
    data: { webhooksList = [] } = {},
    isLoading: isLoadingWebhooks
  } = useModelRegistryWebhooks(
    {
      workspaceName: workspace,
      modelName
    },
    {
      enabled: !!workspace && !!modelName
    }
  );

  useEffect(() => {
    if (!isOpenCreateEditWebhookModal) {
      modelRegistryTestWebhookMutation.reset();
    }
  }, [isOpenCreateEditWebhookModal]);

  const anchorElThreeDots = useCallback(
    row => (
      <ThreeDotsMenuAnchor
        active={row?.id === activeRowData?.id}
        setAnchorEl={setAnchorEl}
        setHoveredRowData={setActiveRowData}
        rowData={row}
        dataTest={`${dataTest}-row-action-menu-btn-${row?.id}`}
      />
    ),
    [activeRowData, setActiveRowData, setActiveRowData]
  );

  useEffect(() => {
    setColumnWidths(getDefaultColumnWidths(ifCurrentUserWsOwner));
  }, [ifCurrentUserWsOwner]);

  const dataTypes = useMemo(
    () => [
      {
        cols: [MODEL_REGISTRY_WEBHOOKS_COLUMNS.status],
        cell: ModelRegistryWebhooksStatusCell
      },
      {
        cols: [
          MODEL_REGISTRY_WEBHOOKS_COLUMNS.url,
          MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdAt,
          MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdBy
        ],
        cell: ({ value }) => (
          <Box>
            <Tooltip arrow placement="top" title={value}>
              <Typography
                noWrap
                component="div"
                variant="text2"
                className={classNames.modelRegistryWebhooksTableNoWrap}
              >
                {value}
              </Typography>
            </Tooltip>
          </Box>
        )
      },
      {
        cols: [MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName],
        cell: ({ value }) => {
          if (isEmpty(value?.trim())) {
            return <EmptyCell />;
          }

          return (
            <Box>
              <Tooltip arrow placement="top" title={value}>
                <div
                  className={cx(
                    classNames.modelRegistryWebhooksTableNoWrap,
                    classNames.textEllipsis,
                    typography.dsAccented
                  )}
                >
                  {value}
                </div>
              </Tooltip>
            </Box>
          );
        }
      },
      {
        cols: [MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers],
        cell: ({ value }) => {
          const triggers = value
            ?.map(event => WEBHOOKS_EVENT_TRIGGERS_MAP[event])
            .join(', ');

          return (
            <Box>
              <Tooltip arrow placement="top" title={triggers}>
                <Typography
                  noWrap
                  component="div"
                  variant="text2"
                  className={classNames.modelRegistryWebhooksTableNoWrap}
                >
                  {triggers}
                </Typography>
              </Tooltip>
            </Box>
          );
        }
      },
      {
        cols: [MODEL_REGISTRY_WEBHOOKS_COLUMNS.rowActionMenu],
        cell: ({ row }) => anchorElThreeDots(row)
      }
    ],
    [setAnchorEl, setActiveRowData, activeRowData]
  );

  const renderEmptyState = useCallback(props => {
    return (
      <ModelRegistryWebhooksEmpty
        materialProps={props}
        origin={origin}
        dataTest={dataTest}
      />
    );
  }, []);

  const getRowClassName = useCallback(
    row => {
      if (row?.id === activeRowData?.id) {
        return cx(
          threeDotsMenuClassNames.dsThreeDotsMenuTableRowActive,
          threeDotsMenuClassNames.dsThreeDotsMenuTableRow
        );
      }

      return threeDotsMenuClassNames.dsThreeDotsMenuTableRow;
    },
    [activeRowData]
  );

  const createWebhookBtn = useMemo(
    () => (
      <Button
        disabled={!ifCurrentUserWsOwner}
        data-test={`${dataTest}-create-btn`}
        onClick={() => {
          setIsOpenCreateEditWebhookModal(true);
          setActiveWebhookData(EMPTY_WEBHOOK_DATA);
        }}
      >
        Create Webhook
      </Button>
    ),
    [ifCurrentUserWsOwner]
  );

  const isCreateEditDataValid = useMemo(() => {
    const webhookName = (
      getFieldValue(
        MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName,
        activeWebhookData
      ) || ''
    ).trim();

    return (
      !isEmpty(webhookName) &&
      getFieldValue(MODEL_REGISTRY_WEBHOOKS_COLUMNS.url, activeWebhookData) &&
      !isEmpty(
        getFieldValue(
          MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers,
          activeWebhookData
        ) || []
      ) &&
      isUrlValid
    );
  }, [activeWebhookData, isUrlValid]);

  const modalDataTest = useMemo(
    () => (!activeRowData ? `${dataTest}-create` : `${dataTest}-edit`),
    [activeRowData]
  );

  if (isLoadingWebhooks) {
    return <SmallLoader dataTest={`${dataTest}-loading`} />;
  }

  const updateColumnOrders = newColOrder =>
    setColumnOrders(getColumnOrders(newColOrder));

  return (
    <Box
      className={classNames.modelRegistryWebhooksTab}
      data-test={`${dataTest}-tab-content`}
    >
      <ModelRegistryWebhooksThreeDotsMenu
        dataTest={dataTest}
        onCloseRowMenuClick={onCloseRowMenuClick}
        anchorEl={anchorEl}
        activeRowData={activeRowData}
        ifCurrentUserWsOwner={ifCurrentUserWsOwner}
        setAnchorEl={setAnchorEl}
        setActiveWebhookData={setActiveWebhookData}
        setIsOpenCreateEditWebhookModal={setIsOpenCreateEditWebhookModal}
        setIsOpenDeleteConfirmationModal={setIsOpenDeleteConfirmationModal}
      />
      <ModelRegistryCreateWebhookModal
        dataTest={modalDataTest}
        isNew={!activeRowData}
        open={isOpenCreateEditWebhookModal}
        modelRegistryTestWebhookMutation={modelRegistryTestWebhookMutation}
        onTestButtonClick={() => {
          modelRegistryTestWebhookMutation.mutate(activeWebhookData);
        }}
        isPrimaryButtonDisabled={
          modelRegistryEditWebhookMutation.isLoading ||
          modelRegistryCreateWebhookMutation.isLoading ||
          !isCreateEditDataValid
        }
        isGithubIntegrationActive={
          getFieldValue(
            MODEL_REGISTRY_WEBHOOKS_COLUMNS.githubUrl,
            activeWebhookData
          ) || ''
        }
        setIsGithubIntegrationActive={val =>
          setActiveWebhookData(oldData => ({
            ...oldData,
            [MODEL_REGISTRY_WEBHOOKS_COLUMNS.githubUrl]: val
          }))
        }
        onClose={() => {
          setIsOpenCreateEditWebhookModal(false);
          setActiveRowData(null);
        }}
        onPrimaryButtonClick={async () => {
          if (!isEmpty(activeRowData)) {
            await modelRegistryEditWebhookMutation.mutate(activeWebhookData);
          } else {
            await modelRegistryCreateWebhookMutation.mutate(activeWebhookData);
          }

          setIsOpenCreateEditWebhookModal(false);
          setActiveRowData(null);
        }}
        onSecondaryButtonClick={async () => {
          if (!isEmpty(activeRowData)) {
            setIsOpenDeleteConfirmationModal(true);
          }
        }}
        url={
          getFieldValue(
            MODEL_REGISTRY_WEBHOOKS_COLUMNS.url,
            activeWebhookData
          ) || ''
        }
        isUrlValid={isUrlValid}
        headers={
          getFieldValue(
            MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers,
            activeWebhookData
          ) || []
        }
        webhookName={
          getFieldValue(
            MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName,
            activeWebhookData
          ) || ''
        }
        authKey={
          getFieldValue(
            MODEL_REGISTRY_WEBHOOKS_COLUMNS.authKey,
            activeWebhookData
          ) || ''
        }
        eventTrigger={
          getFieldValue(
            MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers,
            activeWebhookData
          ) || []
        }
        setEventTrigger={val => {
          setActiveWebhookData(oldData => {
            const newEvents = [
              ...(getFieldValue(
                MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers,
                oldData
              ) || [])
            ];
            const index = newEvents.indexOf(val);
            if (index !== -1) {
              newEvents.splice(index, 1);
            } else {
              newEvents.push(val);
            }

            return {
              ...oldData,
              [MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers]: newEvents
            };
          });
        }}
        setUrl={val => {
          setActiveWebhookData(oldData => ({
            ...oldData,
            [MODEL_REGISTRY_WEBHOOKS_COLUMNS.url]: val
          }));
          setIsUrlValid(isValidHttpUrl(val));
        }}
        setAuthKey={val => {
          setActiveWebhookData(oldData => ({
            ...oldData,
            [MODEL_REGISTRY_WEBHOOKS_COLUMNS.authKey]: val
          }));
        }}
        setWebhookName={val => {
          setActiveWebhookData(oldData => ({
            ...oldData,
            [MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName]: val
          }));
        }}
        setHeaders={(index, data) => {
          setActiveWebhookData(oldData => {
            let newHeaders = [
              ...(getFieldValue(
                MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers,
                activeWebhookData
              ) || [])
            ];

            if (data) {
              newHeaders[index] = {
                ...newHeaders[index],
                ...data
              };
            } else {
              delete newHeaders[index];
              newHeaders = newHeaders?.filter(Boolean) || [];
            }

            return {
              ...oldData,
              [MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers]: newHeaders
            };
          });
        }}
      />
      <ModelRegistryDeleteWebhookConfirmation
        dataTest={`${dataTest}-delete-modal`}
        webhookName={getFieldValue(
          MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName,
          activeRowData
        )}
        open={isOpenDeleteConfirmationModal}
        onClose={() => {
          setIsOpenDeleteConfirmationModal(false);
          setIsOpenCreateEditWebhookModal(false);
          setActiveRowData(null);
        }}
        onSecondaryButtonClick={() => {
          setIsOpenDeleteConfirmationModal(false);
        }}
        onPrimaryButtonClick={() => {
          modelRegistryDeleteWebhookMutation.mutate(
            getFieldValue(MODEL_REGISTRY_WEBHOOKS_COLUMNS.id, activeRowData)
          );
          setIsOpenDeleteConfirmationModal(false);
          setIsOpenCreateEditWebhookModal(false);
          setActiveRowData(null);
        }}
      />
      <Box
        className={classNames.modelRegistryWebhooksTabHeader}
        data-test={`${dataTest}-page-header`}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            {webhooksList?.length ? (
              <Box
                className={classNames.modelRegistryWebhooksTabHeaderText}
                data-test={`${dataTest}-info-text`}
              >
                Webhooks send notifications to external services when certain
                events in the model registry happen. Learn more at{' '}
                <a
                  data-test={`${dataTest}-docs-link`}
                  href={`${origin}/docs/v2/guides/model-management/webhooks/#configure-the-webhook`}
                  target="_blank"
                  rel="noreferrer"
                >
                  our documentation.
                </a>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              <Grid item xs="auto">
                {!ifCurrentUserWsOwner ? (
                  <Tooltip arrow placement="top" title={NON_OWNER_TOOLTIP}>
                    <span>{createWebhookBtn}</span>
                  </Tooltip>
                ) : (
                  createWebhookBtn
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={classNames.modelRegistryWebhooksTable}
        data-test={`${dataTest}-table-wrapper`}
      >
        <Table
          getRowClassName={getRowClassName}
          columns={MODEL_REGISTRY_WEBHOOKS_TABLE_COLUMNS}
          className={threeDotsMenuClassNames.dsThreeDotsMenuTable}
          leftColumns={MODEL_REGISTRY_WEBHOOKS_LEFT_COLUMNS}
          rightColumns={MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS}
          dataTypes={dataTypes}
          rowHeight="60px"
          rowIdKey={MODEL_REGISTRY_WEBHOOKS_COLUMNS.id}
          rows={webhooksList || []}
          selectionConfig={{
            isDisabled: true
          }}
          noPaddingColumns={MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS}
          header={{
            headerColor: DSColors.grayColor1
          }}
          maxHeight={345}
          isFetching={false}
          columnWidthsConfig={{
            isDisabled: false,
            columnWidths,
            onColumnWidthsChange: setColumnWidths,
            columnExtensions: COLUMNS_EXTENSION
          }}
          onOrderChange={updateColumnOrders}
          totalRowCount={webhooksList?.length || 0}
          renderEmptyState={renderEmptyState}
          sortingConfig={{
            isDisabled: true
          }}
          paginationConfig={{
            isDisabled: true
          }}
          columnOrderConfig={{
            isDisabled: false,
            columnOrder: columnOrders,
            onColumnOrderChange: updateColumnOrders
          }}
        />
      </Box>
    </Box>
  );
};

ModelRegistryWebhooksTab.defaultProps = {};

ModelRegistryWebhooksTab.propTypes = {
  workspace: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  ifCurrentUserWsOwner: PropTypes.bool.isRequired
};

export default ModelRegistryWebhooksTab;
