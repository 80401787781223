import React from 'react';
import PropTypes from 'prop-types';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const SortingIcon = ({ direction }) => {
  const fontSize = '14px';

  return direction === 'asc' ? (
    <ArrowUpward style={{ fontSize }} />
  ) : (
    <ArrowDownward style={{ fontSize }} />
  );
};

SortingIcon.propTypes = {
  direction: PropTypes.string
};

SortingIcon.defaultProps = {
  direction: undefined
};

export default SortingIcon;
