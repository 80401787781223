import { FROM_LOCATION_MAP } from '@shared/utils/experimentNavHelpers';
import { Location } from 'history';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';

const labelMap = {
  [FROM_LOCATION_MAP.EXPERIMENT_TABLE]: 'Return to experiments',
  [FROM_LOCATION_MAP.ARCHIVE]: 'Return to archive',
  [FROM_LOCATION_MAP.PANELS]: 'Return to Project',
  default: 'Return to Project'
};
const useBackToProjectRoute = () => {
  const history = useHistory();
  const { state } = useLocation<{
    from: string;
    prevLocation: Location;
  }>();
  const params = useParams<{
    projectName: string;
    workspace: string;
  }>();

  const returnToProjectLabel = labelMap[state?.from] || labelMap.default;

  let onReturnToProject = () =>
    history.push(generatePath('/:workspace/:projectName', params));

  if (state?.prevLocation) {
    onReturnToProject = () => history.push(state.prevLocation);
  }

  return {
    returnToProjectLabel,
    onReturnToProject
  };
};

export default useBackToProjectRoute;
