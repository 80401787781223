import React, { useState } from 'react';

import { DebuggerActionsPanel } from './DebuggerActionsPanel';
import DebuggerMainSection from './DebuggerMainSection';
import classNames from './DebuggerPage.module.scss';
import { DEBUGGER_GROUP_BUTTONS, STREAM_ACTIONS } from '@mpm-druid/constants';
import {
  ActiveDebuggerButtonType,
  ActiveStreamActionType
} from '@mpm-druid/types';

export const DebuggerPage = () => {
  const [activeButton, setActiveButton] = useState<ActiveDebuggerButtonType>(
    DEBUGGER_GROUP_BUTTONS.PREDICTIONS
  );
  const [
    activeStreamAction,
    setActiveStreamAction
  ] = useState<ActiveStreamActionType>(STREAM_ACTIONS.PLAY);
  return (
    <div className={classNames.debuggerPageWrapper}>
      <DebuggerActionsPanel
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        activeAction={activeStreamAction}
        setActiveAction={setActiveStreamAction}
      />
      <DebuggerMainSection
        activeButton={activeButton}
        activeAction={activeStreamAction}
      />
    </div>
  );
};
