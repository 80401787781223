import { useQuery } from 'react-query';

import api from '@API/api';

import { QueryConfig } from '@shared/api';
import { FeatureDetails } from '@mpm-druid/types';

type FeaturesParams = {
  modelId: string;
  page: number;
  pageSize: number;
  sortColumn?: string;
  order?: string;
  predicates?: [] | Array<unknown>;
  includeMetrics: boolean;
};

type FeaturesResponse = {
  features: FeatureDetails[];
  total: number;
};

const getFeatures = ({ ...restParams }: FeaturesParams) =>
  api.post(
    `/mpm/v2/features/search?includeMetrics=${restParams.includeMetrics}`,
    restParams
  );

export function useFeatures(
  params: FeaturesParams,
  config: QueryConfig<FeaturesResponse>
) {
  return useQuery(
    ['model-features', { ...params }],
    () => getFeatures({ ...params }),
    {
      ...config
    }
  );
}
