import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';
import { ClickAwayListener, Popper } from '@material-ui/core';

import './DropdownListPopper.scss';

const DropdownListPopper = ({
  anchorEl,
  onClose,
  width,
  onClick,
  value,
  items,
  renderListItem,
  classes,
  loading,
  noItems,
  renderPopperFooter,
  ...genericPopperProps
}) => {
  if (!anchorEl) {
    return null;
  }

  const handleClick = item => () => {
    onClick(item.value, item);
  };

  const renderPopperContent = () => {
    if (loading) {
      return <div className="ds-dropdown-list-message">LOADING ...</div>;
    }

    if (!items?.length) {
      if (noItems) return noItems();

      return <div className="ds-dropdown-list-message">NO RESULTS</div>;
    }

    return items.map(item => {
      const selected = Array.isArray(value)
        ? value.includes(item.value)
        : value === item.value;

      return (
        <div
          className={cx('ds-dropdown-popper-item', {
            selected
          })}
          key={item.value}
          onClick={handleClick(item)}
          // Keeps focus on the input when selecting item
          onMouseDown={e => e.preventDefault()}
        >
          {renderListItem !== noop
            ? renderListItem(item)
            : item.label || item.value}
        </div>
      );
    });
  };

  return (
    <Popper anchorEl={anchorEl} {...genericPopperProps}>
      <ClickAwayListener onClickAway={onClose}>
        {/* a workaround with the width of Popper */}
        <div style={{ width }}>
          <div className={cx('ds-dropdown-list-popper-container', classes)}>
            {renderPopperContent()}
            {renderPopperFooter()}
          </div>
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

DropdownListPopper.propTypes = {
  anchorEl: PropTypes.object,
  items: PropTypes.array,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  renderListItem: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.string,
  loading: PropTypes.bool,
  noItems: PropTypes.func,
  renderPopperFooter: PropTypes.func
};

DropdownListPopper.defaultProps = {
  anchorEl: null,
  items: [],
  onClick: noop,
  onClose: noop,
  open: false,
  renderPopperFooter: noop,
  renderListItem: noop,
  value: null,
  width: 0,
  classes: 'list',
  loading: false,
  noItems: null
};

export default DropdownListPopper;
