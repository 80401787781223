import React from 'react';
import cx from 'classnames';
import { Select } from '@ds';
import { OptionType } from '@design-system/types';
import { SingleValue } from 'react-select';

import styles from './ExperimentBar.module.scss';
import { DSSelectProps } from '@design-system/components/inputs/Select/Select';

type ExperimentBarSelectProps = {
  label: string;
  options: OptionType[];
  changeHandler: (newValue: SingleValue<OptionType>) => void;
  value: string;
  dataTest?: string;
  isSearchable?: boolean;
  disabled?: boolean;
} & Pick<DSSelectProps, 'searchPlaceholder' | 'isRegexSearchEnabled'>;

const ExperimentBarSelect = ({
  changeHandler,
  label,
  options,
  value,
  dataTest,
  isSearchable,
  disabled,
  ...selectProps
}: ExperimentBarSelectProps) => {
  return (
    <div className={cx(styles.component, styles.select)}>
      <div className={styles.title}>{label}</div>
      <div className={styles.componentWrapper}>
        <Select
          {...selectProps}
          value={value}
          onValueChange={(_, v) => changeHandler(v)}
          options={options}
          isClearable={false}
          dataTest={dataTest}
          isSearchable={isSearchable}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default ExperimentBarSelect;
