import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Cropper from 'react-easy-crop';

import { BasicModal } from '@DesignSystem/modals';
import { Avatar } from '@DesignSystem/data-display';
import { Slider } from '@design-system-outdated/components';
import { getCroppedImg, formImageFileFromUrl, AVATAR_WIDTH } from './helpers';

import './UploadAvatar.scss';

const UploadAvatarModals = ({
  open: openChangeProfilePicture,
  onOpen: onOpenChangeProfilePicture,
  onClose: onCloseChangeProfilePicture,
  onUploadPhoto,
  isPhotoUpdating,
  profileImage
}) => {
  const fileInput = useRef();
  const [uploadFile, setUploadFile] = useState();
  const [uploadUrl, setUploadUrl] = useState();

  const [cropPhotoModalOpen, setCropPhotoModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [finalLookModalOpen, setFinalLookModalOpen] = useState(false);

  const resetFile = () => {
    setUploadFile(false);
    fileInput.current.value = null;
    setUploadUrl(null);
  };

  const closeUploadModalAndReset = () => {
    onCloseChangeProfilePicture();
    resetFile();
  };

  useEffect(() => {
    const isImage =
      uploadFile?.type === 'image/png' || uploadFile?.type === 'image/jpeg';

    if (isImage) {
      const uploadedURL = URL.createObjectURL(uploadFile);
      setUploadUrl(uploadedURL);
    } else {
      setUploadFile(null);
    }
  }, [uploadFile]);

  const handleUpload = () => {
    fileInput.current.click();
  };

  const handleFileInput = () => {
    setUploadFile(fileInput.current.files[0]);
  };

  const renderUploadImageModal = () => (
    <div className="upload-image-modal">
      <div className="avatar-container">
        <Avatar src={uploadUrl || profileImage} width={AVATAR_WIDTH} outlined />
      </div>
    </div>
  );

  // CROP MODAL
  const openCropPhotoModal = () => setCropPhotoModalOpen(true);
  const closeCropPhotoModal = () => {
    setCropPhotoModalOpen(false);
    resetFile();
  };

  const onCropComplete = useCallback((croppedArea, areaPixels) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const renderCropImageModal = () => (
    <div className="crop-modal-content">
      <div className="crop-container">
        <Cropper
          image={uploadUrl}
          zoom={zoom}
          crop={crop}
          aspect={1}
          showGrid={false}
          cropShape="round"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <div className="slider-container">
        <Slider value={zoom} min={1} max={2} step={0.1} onChange={setZoom} />
      </div>
    </div>
  );

  const handleUploadImageBtnClick = () => {
    onCloseChangeProfilePicture();
    openCropPhotoModal();
  };

  // FINAL LOOK MODAL
  const openFinalLookPhotoModal = () => setFinalLookModalOpen(true);
  const closeFinalLookPhotoModal = () => {
    setFinalLookModalOpen(false);
    resetFile();
  };

  const handleUploadPhotoButton = async () => {
    closeCropPhotoModal();
    try {
      const img = await getCroppedImg(uploadUrl, croppedAreaPixels);

      setCroppedImage(img);

      openFinalLookPhotoModal();
      closeCropPhotoModal();
    } catch (e) {
      closeCropPhotoModal();
    }
  };

  const renderFinalLookModalContent = () => (
    <div className="final-look-content">
      <Avatar src={croppedImage} outlined />
    </div>
  );

  const handleTryAnotherClick = () => {
    onOpenChangeProfilePicture();
    closeFinalLookPhotoModal();
  };

  const uploadPhoto = () => {
    formImageFileFromUrl(croppedImage, async img => {
      await onUploadPhoto(img);
      closeFinalLookPhotoModal();
    });
  };

  useEffect(() => {
    if (uploadUrl) {
      handleUploadImageBtnClick();
    }
  }, [handleUploadImageBtnClick, uploadUrl]);

  return (
    <>
      <input
        type="file"
        className="hidden"
        accept="image/*"
        ref={fileInput}
        onInput={handleFileInput}
        data-test="hidden-file-input"
      />

      <BasicModal
        open={openChangeProfilePicture}
        onClose={closeUploadModalAndReset}
        title="Change Profile Picture"
        content={renderUploadImageModal()}
        primaryButtonText="Upload Image"
        onPrimaryButtonClick={handleUpload}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeUploadModalAndReset}
      />

      <BasicModal
        open={cropPhotoModalOpen}
        onClose={closeCropPhotoModal}
        title="What's Your Best Angle"
        content={renderCropImageModal()}
        primaryButtonText="Crop"
        onPrimaryButtonClick={handleUploadPhotoButton}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeCropPhotoModal}
      />

      <BasicModal
        open={finalLookModalOpen}
        onClose={closeFinalLookPhotoModal}
        title="Like That Look?"
        content={renderFinalLookModalContent()}
        primaryButtonText="Yes, upload this picture"
        onPrimaryButtonClick={uploadPhoto}
        secondaryButtonText="Try another"
        onSecondaryButtonClick={handleTryAnotherClick}
        isPrimaryButtonDisabled={isPhotoUpdating}
      />
    </>
  );
};

UploadAvatarModals.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onUploadPhoto: PropTypes.func.isRequired,
  isPhotoUpdating: PropTypes.bool.isRequired,
  profileImage: PropTypes.string.isRequired
};

export default UploadAvatarModals;
