import get from 'lodash/get';

import mpmApi from '../util/mpmApi';
import workspaceActions from './workspaceActions';
import { getTeamIdForActiveWorkspace } from '../reducers/ui/workspaceUiReducer';
import { WORKSPACE_PAGE_TABS } from '../constants/workspaceConstants';
import { mpmActionTypes } from '../constants/actionTypes';

const mpmActions = {
  fetchModelsCount(isMPMDruidEnabled) {
    return (dispatch, getState) => {
      const state = getState();
      const workspaceId = getTeamIdForActiveWorkspace(state);
      if (!workspaceId) {
        return null;
      }

      return mpmApi
        .fetchModelsCount(workspaceId, isMPMDruidEnabled)
        .then(response => {
          const modelCount = get(
            response,
            ['data', isMPMDruidEnabled ? 'modelCount' : 'monitoredModelCount'],
            0
          );

          dispatch(
            workspaceActions.setItemCountAndDisplay(
              WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING,
              modelCount
            )
          );
        })

        .catch(() => {
          console.error('There was an error fetching the models count:');
        });
    };
  },

  setTimeRange({ from, to }) {
    return dispatch => {
      dispatch({
        type: mpmActionTypes.SET_TIME_RANGE,
        payload: {
          from,
          to
        }
      });
    };
  },

  setIntervalType(intervalType) {
    return dispatch => {
      dispatch({
        type: mpmActionTypes.SET_INTERVAL_TYPE,
        payload: { intervalType }
      });
    };
  }
};

export default mpmActions;
