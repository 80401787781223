import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import noop from 'lodash/noop';

import { IconButton, Tooltip } from '@ds';

import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import { DSHideIcon, DSShowIcon } from '@ds-icons';

const VisibilityButton = ({ className, onToggleVisibility, row }) => {
  const { experimentKey, isVisibleOnDashboard } = row;

  const handleClick = () => {
    onToggleVisibility(experimentKey);
  };

  const tooltipAction = isVisibleOnDashboard ? 'Hide' : 'Show';
  const tooltipMessage = `${tooltipAction} experiment in ${PANEL_ENTITY_NAME}s`;
  const Icon = isVisibleOnDashboard ? DSShowIcon : DSHideIcon;

  return (
    <div
      className={cx('react-grid-table-icon', className, {
        'hidden-state': !isVisibleOnDashboard
      })}
    >
      <Tooltip placement="top" content={tooltipMessage}>
        <IconButton type="secondary" onClick={handleClick} Icon={<Icon />} />
      </Tooltip>
    </div>
  );
};

VisibilityButton.defaultProps = {
  className: '',
  onToggleVisibility: noop
};

VisibilityButton.propTypes = {
  className: PropTypes.string,
  onToggleVisibility: PropTypes.func,
  row: PropTypes.object.isRequired
};

export default VisibilityButton;
