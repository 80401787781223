import useWorkspaces from './useWorkspaces';
import useActiveWorkspaceName from './useActiveWorkspaceName';
import { Workspace } from '@shared/types';

const useActiveWorkspace = (): Workspace => {
  const { data: workspaces } = useWorkspaces();
  const activeWorkspaceName = useActiveWorkspaceName();

  return workspaces?.find(
    workspace => workspace.name === activeWorkspaceName
  ) as Workspace;
};

export default useActiveWorkspace;
