import useCurrentOrganizationUserRole from './useCurrentOrganizationUserRole';
import { ORGANIZATION_USER_ROLES } from '@shared/constants';
import useIsViewOnlyEnabled from './useIsViewOnlyEnabled';

const useIsViewOnlyUser = (): boolean => {
  const role = useCurrentOrganizationUserRole();
  const enabled = useIsViewOnlyEnabled();

  return enabled && role === ORGANIZATION_USER_ROLES.VIEW_ONLY_MEMBER;
};

export default useIsViewOnlyUser;
