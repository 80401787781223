import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';
import { PythonEnvironmentVersion } from '@shared/types';

interface EnvironmentVersionsResponse {
  environments: PythonEnvironmentVersion[];
}
const getOrganizationPythonEnvironmentVersions = async (
  organizationId: string
): Promise<PythonEnvironmentVersion[]> => {
  try {
    const response = await api.get<EnvironmentVersionsResponse>(
      `/code-panels/environments?orgId=${organizationId}`
    );
    return response?.data?.environments;
  } catch {
    return [];
  }
};
const useOrganizationPythonEnvironmentVersions = (
  organizationId: string,
  config: QueryConfig<PythonEnvironmentVersion[]> = {}
) => {
  return useQuery(
    ['python-environment-versions', { organizationId }],
    () => getOrganizationPythonEnvironmentVersions(organizationId),
    config
  );
};

export default useOrganizationPythonEnvironmentVersions;
