import React, { ChangeEvent, HTMLProps } from 'react';
import classes from './TextArea.module.scss';
import cx from 'classnames';
import typography from '@ds-typography';
import { TextAreaSize } from '@design-system/types';

interface TextAreaProps extends Omit<HTMLProps<HTMLTextAreaElement>, 'size'> {
  size?: TextAreaSize;
  invalid?: boolean;
  helperText?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
  size = 'default',
  invalid = false,
  helperText = '',
  ...props
}: TextAreaProps) => {
  return (
    <div
      className={cx(
        classes.textareaContainer,
        typography.dsBody,
        classes[size],
        {
          [classes.invalidContainer]: invalid
        }
      )}
    >
      <textarea {...props} />
      {helperText && (
        <div
          className={cx(classes.helperTextContainer, typography.dsBodySmall)}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};

export default TextArea;
