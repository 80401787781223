// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyTabMessage-module__emptyTabMessageText--vXXBC{font-size:18px !important;font-weight:300;line-height:27px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyTabMessageText": `EmptyTabMessage-module__emptyTabMessageText--vXXBC`
};
module.exports = ___CSS_LOADER_EXPORT___;
