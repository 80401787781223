import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@DesignSystem/modals';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Input } from '@DesignSystem/controllers';
import RadioButton from '@DesignSystem/controllers/RadioButton/RadioButton';
import useModelRegistryUpdateMutation from '@model-registry/api/useModelRegistryUpdateMutation';
import { getModelNameValidation } from '../../../../reducers/modelRegistryReducer';
import { useDebouncedFilter } from '@shared/hooks';

import { getModelNameValidationTooltipText } from '@/constants/modelRegistryConstants';
import modelRegistryActions from '@/actions/modelRegistryActions';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 460,
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 60px 32px 60px'
  }
});

const sx = {
  modalWrapper: {
    width: 460,
    borderRadius: 5,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 60px 32px 60px'
  },
  closeIcon: {
    position: 'absolute',
    top: 12,
    right: 12,
    '& svg': {
      fontSize: '22px',
      color: '#8C95A8'
    }
  },
  title: {
    fontWeight: 500,
    fontSize: 22,
    lineHeight: '33px',
    color: '#191A1C'
  },
  content: {
    marginTop: 14,
    width: '100%'
  },
  label: {
    fontSize: 14,
    lineHeight: '21px',
    color: '#5F677E',
    '& span': {
      color: 'red'
    }
  },
  textfield: {
    borderRadius: 4,
    height: 36,
    padding: '4px 8px',
    boxSizing: 'border-box',
    fontSize: 14
  },
  multitextfield: {
    fontSize: 14,
    borderRadius: 4,
    height: 80,
    boxSizing: 'border-box',
    paddingTop: 24,
    paddingLeft: 8
  }
};

const EditModelModal = ({ model, isOpen, onClose }) => {
  const { workspace } = useParams();

  const dispatch = useDispatch();
  const classes = useStyles();

  const modelRegistryUpdateMutation = useModelRegistryUpdateMutation(
    { workspaceName: workspace, currentModelName: model?.modelName },
    false
  );
  const [isPublic, setIsPublic] = useState(model.isPublic || false);
  const [isValidatingModelName, setIsValidatingModelName] = useState(false);
  const [inputModelDescription, setInputModelDescription] = useState(
    model?.description
  );

  const {
    debouncedFilterValue: debouncedModelName,
    filterValue: inputModelName,
    setFilterValue: setInputModelName
  } = useDebouncedFilter(model.modelName);

  const newModelNameValidation = useSelector(getModelNameValidation);

  const tooltipText = getModelNameValidationTooltipText(
    debouncedModelName,
    newModelNameValidation
  );

  const isValidModelName =
    inputModelName === model.modelName ||
    newModelNameValidation.finalModelName === model.modelName ||
    (!newModelNameValidation.modelNameExists &&
      !!newModelNameValidation.finalModelName &&
      inputModelName === newModelNameValidation.originalModelName);

  useEffect(() => {
    if (debouncedModelName !== '' && model.modelName !== debouncedModelName) {
      setIsValidatingModelName(true);

      dispatch(
        modelRegistryActions.validateModelName(workspace, debouncedModelName)
      )
        .then(() => setIsValidatingModelName(false))
        .catch(() => setIsValidatingModelName(false));
    }
  }, [debouncedModelName, model.modelName]);

  useEffect(() => {
    if (isOpen) {
      setInputModelName(model.modelName);
      setInputModelDescription(model.description);
      setIsPublic(model.isPublic);
    }
  }, [isOpen]);

  const handleConfirm = useCallback(async () => {
    await modelRegistryUpdateMutation.mutate({
      registryModelId: model?.registryModelId,
      description: inputModelDescription,
      isPublic,
      newModelName: newModelNameValidation.finalModelName || model.modelName
    });

    onClose();
  }, [
    workspace,
    model,
    inputModelDescription,
    isPublic,
    newModelNameValidation
  ]);

  const handleInputNameChange = useCallback(value => {
    setInputModelName(value);
  }, []);

  const handleInputDescriptionChange = useCallback(value => {
    setInputModelDescription(value);
  }, []);

  const handleVisibilityChange = useCallback((ev, value) => {
    setIsPublic(value);
  }, []);

  const isTooltipOpen =
    !isValidModelName &&
    debouncedModelName === inputModelName &&
    !isValidatingModelName;

  const renderContent = () => {
    return (
      <Box sx={sx.content}>
        <Box sx={sx.label}>Name</Box>
        <Box sx={{ mt: '10px' }}>
          <StyledValidationTooltip title={tooltipText} open={isTooltipOpen}>
            <Input
              value={inputModelName}
              placeholder="Enter model name"
              onChange={handleInputNameChange}
            />
          </StyledValidationTooltip>
        </Box>
        <Box sx={{ ...sx.label, mt: '32px' }}>Description</Box>
        <Box sx={{ mt: '10px' }}>
          <Input
            multiline
            rows={4}
            placeholder="Enter description"
            value={inputModelDescription}
            onChange={handleInputDescriptionChange}
          />
        </Box>
        <Box mt={4}>
          <Box sx={sx.label}>Visibility</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <RadioGroup
            value={String(isPublic)}
            onChange={handleVisibilityChange}
          >
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <FormControlLabel
                control={<RadioButton style={{ padding: '9px' }} />}
                label={<Box sx={{ fontSize: 14 }}>Private</Box>}
                labelPlacement="end"
                value="false"
              />
              <FormControlLabel
                control={<RadioButton style={{ padding: '9px' }} />}
                label={<Box sx={{ fontSize: 14 }}>Public</Box>}
                labelPlacement="end"
                value="true"
              />
            </Box>
          </RadioGroup>
        </Box>
      </Box>
    );
  };

  return (
    <BasicModal
      additionalClasses={{
        paper: classes.paper
      }}
      open={isOpen}
      title="Edit Model"
      isPrimaryButtonDisabled={!isValidModelName}
      primaryButtonText="Update"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      onSecondaryButtonClick={onClose}
      content={renderContent()}
      onClose={onClose}
    />
  );
};

EditModelModal.propTypes = {
  model: PropTypes.shape({
    registryModelId: PropTypes.string,
    modelName: PropTypes.string,
    isPublic: PropTypes.bool,
    description: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default EditModelModal;
