import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import alertsUtil from '../../../../util/alertsUtil';
import { snackbarTypes } from '../../../../constants/alertTypes';

const removeInvitationFromWorkspace = ({ organizationId, email }) =>
  organizationApi.removeInvitation({ organizationId, email });

const useRemoveInvitationFromOrganizationMutation = organizationId => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ email }) => removeInvitationFromWorkspace({ organizationId, email }),
    {
      onSuccess: (resp, { email }) => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_REMOVE_INVITATION_FROM_ORGANIZATION,
            `You have successfully deleted “${email}” from your organization`
          )
        );

        queryClient.invalidateQueries(['organizations'], { active: true });
        queryClient.resetQueries(['organizations'], { inactive: true });

        queryClient.resetQueries('workspace');
      },
      onError: e => {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_PAYMENT_CHANGED,
            e?.response?.data?.msg || e?.message
          )
        );
      }
    }
  );
};

export default useRemoveInvitationFromOrganizationMutation;
