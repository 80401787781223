import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import DsSelect from '@DesignSystem/controllers/Select/Select';

import './ModelRegistrySelectVersions.scss';
import { SUB_PATHS } from '@/constants/urlConstants';

const ModelRegistrySelectVersions = ({
  selectedVersion,
  versions,
  workspaceName,
  modelName
}) => {
  const history = useHistory();
  const handleChangeVersion = version =>
    history.push(
      `/${workspaceName}/${SUB_PATHS.MODEL_REGISTRY}/${modelName}/${version}`
    );

  const options = useMemo(
    () =>
      versions.map(({ version }) => ({
        value: version,
        label: `Version ${version}`
      })),
    [versions]
  );

  return (
    <Box className="model-registry-select">
      <DsSelect
        value={selectedVersion.version}
        width={260}
        onChange={handleChangeVersion}
        options={options}
      />
    </Box>
  );
};

ModelRegistrySelectVersions.propTypes = {
  selectedVersion: PropTypes.shape({
    version: PropTypes.string.isRequired
  }),
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired
    })
  ),
  workspaceName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired
};

ModelRegistrySelectVersions.defaultProps = {
  selectedVersion: null,
  versions: []
};

export default ModelRegistrySelectVersions;
