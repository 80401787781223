import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

import { NOTIFICATION_TYPES, DRIFT_ALGORITHMS } from '@mpm/constants';
import { TimeRangeInterval } from '@mpm/types';

type NotificationMethodType = {
  type: typeof NOTIFICATION_TYPES[keyof typeof NOTIFICATION_TYPES];
  metadata?: {
    email: string | null;
  };
};

type AddAlertMutationProps = {
  modelId: string;
  payload: {
    description: string | null;
    driftAlgorithm: typeof DRIFT_ALGORITHMS[keyof typeof DRIFT_ALGORITHMS];
    featureId: string | null;
    intervalType: TimeRangeInterval;
    metricType: string;
    modelVersion: string | null;
    name: string;
    notificationMethods: null;
    notificationMethodsV2: NotificationMethodType[];
    threshold: {
      thresholdType: string;
      thresholdOperator: string;
      value: string | null;
    };
  };
};

type AddAlertProps = AddAlertMutationProps & { workspaceId: string };

const addAlert = ({ workspaceId, payload, modelId }: AddAlertProps) => {
  return api.post(`/mpm/${workspaceId}/models/${modelId}/alert-rules`, payload);
};

export function useAddAlertMutation() {
  const activeWorkspace = useActiveWorkspace();
  const history = useHistory();
  const dispatch = useDispatch();

  return useMutation(
    ({ payload, modelId }: AddAlertMutationProps) =>
      addAlert({ payload, workspaceId: activeWorkspace?.id, modelId }),
    {
      onSuccess: async () => {
        history.push('./rules');
      },
      onError: (error: Error) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_ALERT_CREATED,
            error?.message
          )
        );
      }
    }
  );
}
