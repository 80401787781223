import { useQuery } from 'react-query';

import queryString from 'query-string';
import api from '../api';
import { ROOT_ROUTES, SUB_PATHS } from '@/constants/urlConstants';
import useProjectParams from './useProjectParams';

const getProject = async ({ workspace, projectName, projectId }) => {
  if (projectId) {
    return api.get('projects/get/project', {
      projectId
    });
  }

  return api.get('projects/get/projectByName', {
    owner: workspace,
    projectName
  });
};

export default function useProject(params, config) {
  // For some specific pages like embedded panel we already have projectID, so instead of using 'projects/get/projectByName' we need to use 'projects/get/project'
  const { projectId } = queryString.parse(window.location.search);
  const { workspace, projectName } = useProjectParams();

  const isValidWorkspace = !ROOT_ROUTES.includes(workspace);

  const isProjectPage = ![
    SUB_PATHS.ARTIFACTS,
    SUB_PATHS.MODEL_PRODUCTION_MONITORING,
    SUB_PATHS.MODEL_REGISTRY,
    undefined
  ].includes(projectName);

  const enabled = (isProjectPage || !!projectId) && isValidWorkspace;

  return useQuery(
    ['project', { workspace, projectName, projectId, ...params }],
    () => getProject({ workspace, projectName, projectId, ...params }),
    { enabled, ...config }
  );
}
