import React from 'react';

import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import alertsUtil from '../../../../util/alertsUtil';
import useRenameAssetMutation from '@API/assets/useRenameAsset';
import { ActionMenuList, ListItem, Tooltip } from '@ds';
import isEmpty from 'lodash/isEmpty';
import {
  DSCopyIcon,
  DSDownloadIcon,
  DSEditIcon,
  DSOpenInNewIcon
} from '@ds-icons';
import { BasicModal } from '@DesignSystem/modals';
import { Input } from '@DesignSystem/controllers';
import { getEmbeddedProjectorURL } from '../../../../util/assetsApi';

type AssetsTabActionsProps = {
  link: string;
  downloadAssetURL: string;
  fileName: string;
  assetId: string;
  experimentKey: string;
  isRemote: boolean;
  isEmbedding: boolean;
};

const AssetFileActions = ({
  assetId,
  experimentKey,
  link,
  isRemote,
  isEmbedding,
  downloadAssetURL,
  fileName
}: AssetsTabActionsProps) => {
  const dispatch = useDispatch();
  const renameMutation = useRenameAssetMutation();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [newAssetName, setNewAssetName] = React.useState(fileName);

  const handleCopyAssetLink = () =>
    dispatch(alertsUtil.openCopySuccessSnackbar());

  const handleRenameAsset = () => {
    renameMutation.mutate({
      assetId,
      experimentKey,
      newAssetName: newAssetName
    });
    setIsDialogOpen(false);
  };

  return (
    <div style={{ pointerEvents: 'auto' }}>
      <BasicModal
        open={isDialogOpen}
        type="edit"
        onClose={() => setIsDialogOpen(false)}
        title="Rename asset"
        primaryButtonText="Update"
        isPrimaryButtonDisabled={isEmpty(newAssetName)}
        onPrimaryButtonClick={handleRenameAsset}
        isPrimaryBtnLoading={renameMutation.isLoading}
        content={
          <Input
            style={{ width: 340 }}
            value={newAssetName}
            onChange={(value: string) => setNewAssetName(value)}
          />
        }
      />
      <ActionMenuList>
        <ActionMenuList.Trigger />
        <ActionMenuList.Body>
          <Tooltip content="Rename" placement="top">
            <ListItem onClick={() => setIsDialogOpen(true)}>
              <ListItem.PrefixContainer>
                <ListItem.Icon Icon={<DSEditIcon />} />
              </ListItem.PrefixContainer>
              <ListItem.Text primary="Rename" />
            </ListItem>
          </Tooltip>

          {isRemote ? (
            <Tooltip placement="top" content="Copy Link">
              <CopyToClipboard text={link}>
                <ListItem onClick={handleCopyAssetLink}>
                  <ListItem.PrefixContainer>
                    <ListItem.Icon Icon={<DSCopyIcon />} />
                  </ListItem.PrefixContainer>
                  <ListItem.Text primary="Copy" />
                </ListItem>
              </CopyToClipboard>
            </Tooltip>
          ) : (
            <Tooltip content="Download" placement="top">
              <a href={downloadAssetURL} download={fileName} role="button">
                <ListItem>
                  <ListItem.PrefixContainer>
                    <ListItem.Icon Icon={<DSDownloadIcon />} />
                  </ListItem.PrefixContainer>
                  <ListItem.Text primary="Download" />
                </ListItem>
              </a>
            </Tooltip>
          )}

          {isEmbedding && (
            <Tooltip content="Open in Projector" placement="top">
              <a
                href={getEmbeddedProjectorURL(assetId, experimentKey)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItem>
                  <ListItem.PrefixContainer>
                    <ListItem.Icon Icon={<DSOpenInNewIcon />} />
                  </ListItem.PrefixContainer>
                  <ListItem.Text primary="View in embedding" />
                </ListItem>
              </a>
            </Tooltip>
          )}
        </ActionMenuList.Body>
      </ActionMenuList>
    </div>
  );
};

export default AssetFileActions;
