import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import OptimizedPlot from '@DesignSystem/charts/OptimizedPlot';
import { ChartTooltip, Indicator } from '../ChartTooltip';
import { CHART_BASIC_CONFIG, getDateRangeMessage } from '@mpm-druid/utils';
import { useNumericalTooltip } from '@mpm-druid/hooks';
import { CHART_TYPES } from '@mpm-druid/constants';
import './NumericalDistributionChart.scss';

export function NumericalDistributionChart({
  numericalDistributionData,
  layoutData,
  tabData,
  outerTooltipData,
  outerTooltipVisible,
  from,
  to,
  featureName,
  isLoading
}) {
  const tooltipTitle = tabData?.label || featureName;
  const {
    inspectMode,
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    hideTooltipHandler,
    closeTooltipHandler,
    handleClick
  } = useNumericalTooltip({ title: `${tooltipTitle}:`, subTitle: 'Density:' });

  const unAvailableData = useMemo(() => {
    return numericalDistributionData.panelData.every(item => {
      return isEmpty(item) || isEmpty(item?.x);
    });
  }, [numericalDistributionData.panelData]);

  if (isLoading) {
    return (
      <div className="numerical-chart-loading">
        <span className="primary-label">Loading... </span>
        <span className="secondary-label">Fetching chart data</span>
      </div>
    );
  }

  return (
    <div className="numerical-distribution-container">
      {tooltipVisible && (
        <>
          <Indicator position={tooltipPosition} color={tooltipData.color} />
          <ChartTooltip
            tooltipData={tooltipData}
            position={tooltipPosition}
            closeHandler={closeTooltipHandler}
            inspectMode={inspectMode}
            orientation={tooltipOrientation}
            chartType={CHART_TYPES.LINE}
            hideInspection={true}
          />
        </>
      )}
      <OptimizedPlot
        config={CHART_BASIC_CONFIG}
        data={numericalDistributionData.panelData}
        layout={numericalDistributionData.layoutData}
        useResizeHandler
        disableZoom={true}
        layoutProps={layoutData}
        onHover={handleLinePointHover}
        onUnhover={hideTooltipHandler}
        onClick={handleClick}
      />
      {unAvailableData && (
        <div className="distribution-unavailable-message">
          No Distributions available
        </div>
      )}
      <div className="distribution-date-range-message">
        Feature Distribution -{' '}
        {getDateRangeMessage(outerTooltipData, outerTooltipVisible, from, to)}
      </div>
    </div>
  );
}

NumericalDistributionChart.defaultProps = {
  numericalDistributionData: [],
  layoutData: {},
  outerTooltipData: {},
  outerTooltipVisible: false
};

NumericalDistributionChart.propTypes = {
  numericalDistributionData: PropTypes.object,
  layoutData: PropTypes.object,
  outerTooltipData: PropTypes.object,
  outerTooltipVisible: PropTypes.bool,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};
