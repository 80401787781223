import React from 'react';
import cx from 'classnames';

import styles from './AutoCompleter.module.scss';

type CompleterItemProps = {
  Icon: React.ElementType;
  value: string;
  clickHandler: (value: string) => void;
  isActive: boolean;
  key: string;
  filterPrefix: string;
};

export function CompleterItem({
  Icon,
  value,
  clickHandler,
  isActive,
  key,
  filterPrefix = ''
}: CompleterItemProps) {
  const clickItemHandler = () => {
    clickHandler(value);
  };
  return (
    <div
      onClick={clickItemHandler}
      className={cx(styles.completerItemWrapper, {
        [styles.active]: isActive
      })}
      key={key}
    >
      <Icon />
      <span>
        {filterPrefix && <b>{filterPrefix}</b>}
        <span>{value.slice(filterPrefix.length)}</span>
      </span>
    </div>
  );
}
