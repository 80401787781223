import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Button, TextButton } from '@ds';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import EditableTextField from './EditableTextField';
import modelRegistryActions from '@/actions/modelRegistryActions';
import {
  getModelNameValidation,
  getModelVersionValidation
} from '@/reducers/modelRegistryReducer';
import {
  getModelNameValidationTooltipText,
  DEFAULT_MODEL_VERSION,
  MODEL_SCOPE_TYPES,
  INPUT_VALIDATION_FREQUENCY
} from '@/constants/modelRegistryConstants';
import ModelVersionField from './ModelVersionField';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';

const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#5055f5'
    },
    color: '#5055f5'
  },
  checked: {},
  track: {}
})(Switch);

const { PUBLIC, PRIVATE } = MODEL_SCOPE_TYPES;

const NewModel = ({
  dispatch,
  experimentModel,
  modelNameValidation,
  modelVersionValidation,
  navigationHandler,
  successMessageHandler,
  workspace
}) => {
  const queryClient = useQueryClient();
  const [newModelName, setNewModelName] = useState(experimentModel.modelName);
  const [newModelVersion, setNewModelVersion] = useState(DEFAULT_MODEL_VERSION);
  const [newModelDescription, setNewModelDescription] = useState('');
  const [scopeType, setScopeType] = useState(PRIVATE);

  useEffect(() => {
    handleValidateModelName(newModelName);

    return () => {
      dispatch(modelRegistryActions.setModelNameValidation());
    };
  }, []);

  const handleValidateModelName = modelName => {
    dispatch(modelRegistryActions.validateModelName(workspace, modelName));
  };

  const debouncedValidateModelName = useCallback(
    debounce(handleValidateModelName, INPUT_VALIDATION_FREQUENCY),
    []
  );

  const handleTextChange = modelName => {
    setNewModelName(modelName);

    if (!isEmpty(modelName)) {
      debouncedValidateModelName(modelName);
    }
  };

  const handleSwitchChange = () => {
    setScopeType(prevScopeType =>
      prevScopeType === PRIVATE ? PUBLIC : PRIVATE
    );
  };

  const handleRegisterNewModel = () => {
    const { experimentModelId, experimentKey } = experimentModel;
    const isPublic = scopeType === PUBLIC;

    dispatch(
      modelRegistryActions.createNewModel(
        experimentModelId,
        newModelName,
        newModelDescription,
        newModelVersion,
        isPublic
      )
    ).then(() => {
      queryClient.invalidateQueries(
        'modelRegistry',
        { experimentKey },
        'experiments'
      );
      successMessageHandler('New model successfully created');
    });
  };

  const renderModelNameField = () => {
    const { validName, modelNameExists } = modelNameValidation;

    return (
      <div className="generic-modal-section">
        <StyledValidationTooltip
          title={getModelNameValidationTooltipText(
            newModelName,
            modelNameValidation
          )}
          open={!validName || modelNameExists}
        >
          <span>
            <EditableTextField
              entityName="model"
              placeholder={newModelName}
              textChangeHandler={handleTextChange}
              currentName={newModelName}
            />
          </span>
        </StyledValidationTooltip>
      </div>
    );
  };

  const renderVersionField = () => {
    return (
      <div className="generic-modal-section">
        <ModelVersionField
          label="Version"
          versionChangeHandler={setNewModelVersion}
          value={newModelVersion}
        />
      </div>
    );
  };

  const renderDescriptionField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Description</InputLabel>
        <InputBase
          className="modal-input-base"
          onChange={e => setNewModelDescription(e.target.value)}
          value={newModelDescription}
          placeholder="Enter description"
          minRows={3}
          maxRows={8}
          multiline
        />
      </div>
    );
  };

  const renderSwitchLabel = labelScopeType => {
    return (
      <label
        className={cx('scope-switch-label', {
          active: scopeType === labelScopeType
        })}
        style={{ fontSize: '13px' }}
      >
        {labelScopeType}
      </label>
    );
  };

  const renderVisibilityToggle = () => {
    return (
      <div className="generic-modal-section">
        <div>
          {renderSwitchLabel(PUBLIC)}
          <StyledSwitch
            checked={scopeType === PRIVATE}
            onChange={handleSwitchChange}
            color="primary"
          />
          {renderSwitchLabel(PRIVATE)}
        </div>
      </div>
    );
  };

  const getSaveBtnTooltipText = () => {
    if (isEmpty(newModelName)) {
      return 'You must give your new model a name';
    }

    if (isEmpty(newModelVersion)) {
      return 'You must give your new model a version';
    }

    if (modelNameValidation.modelNameExists) {
      return 'You must give your model a valid name';
    }

    if (
      !modelVersionValidation.validVersion ||
      modelVersionValidation.versionExists
    ) {
      return 'You must give your model a valid version';
    }
  };

  const tooltipText = getSaveBtnTooltipText();

  return (
    <>
      <DialogContent className="generic-modal-body">
        {renderModelNameField()}
        {renderVersionField()}
        {renderDescriptionField()}
        {renderVisibilityToggle()}
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton size="large" onClick={navigationHandler}>
          Back
        </TextButton>
        <Tooltip
          title={tooltipText}
          placement="top"
          disableHoverListener={isEmpty(getSaveBtnTooltipText())}
        >
          <span>
            <Button
              size="large"
              onClick={handleRegisterNewModel}
              disabled={!isEmpty(tooltipText)}
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

NewModel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  experimentModel: PropTypes.object.isRequired,
  modelNameValidation: PropTypes.object.isRequired,
  modelVersionValidation: PropTypes.object.isRequired,
  navigationHandler: PropTypes.func.isRequired,
  successMessageHandler: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    modelNameValidation: getModelNameValidation(state),
    modelVersionValidation: getModelVersionValidation(state)
  };
};

export default connect(mapStateToProps)(NewModel);
