import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import api from '@shared/api';
import { useQuery } from 'react-query';

const getExperimentVideos = async ({ experimentKey, signal }) => {
  const { data } = await api.get('data-fetch/get/videos', {
    params: { experimentKey },
    signal
  });

  return data.videos;
};

export default function useExperimentVideos(
  { experimentKey, isActive },
  config
) {
  return useQuery(
    ['experiment-videos', { experimentKey }],
    ({ signal }) => {
      return getExperimentVideos({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      refetchInterval: isActive ? FETCH_EXPERIMENT_POLLING_LENGTH : null,
      ...config
    }
  );
}
