// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyButton-module__copyIconWrapper--gvt76{cursor:pointer;position:absolute;top:14px;right:14px}.CopyButton-module__copyIconWrapper--gvt76 svg{color:var(--gray-5);fill:var(--gray-5)}.CopyButton-module__copyIconWrapper--gvt76 svg:hover{color:var(--gray-6);fill:var(--gray-6)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyIconWrapper": `CopyButton-module__copyIconWrapper--gvt76`
};
module.exports = ___CSS_LOADER_EXPORT___;
