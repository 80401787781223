import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes, snackbarTypes } from '../../../constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';
import { PROJECT_TYPE } from '@/constants/projectConstants';

const editProject = ({
  description,
  isPublic,
  type,
  name,
  projectId,
  userName,
  workspaceId
}) => {
  return api.post('projects/update', {
    projectId,
    projectName: name,
    projectDesc: description,
    isPublic,
    type,
    teamId: workspaceId,
    userName
  });
};

export default function useEditProjectMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const workspaceName = useWorkspaceName();

  return useMutation(
    ({
      description,
      isPublic,
      type = PROJECT_TYPE.EM,
      name,
      projectId,
      userName,
      workspaceId
    }) =>
      editProject({
        description,
        isPublic,
        type,
        name,
        projectId,
        userName,
        workspaceId
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projects', { workspaceName }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['projects', { workspaceName }]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_EDIT_PROJECT,
            'Project successfully updated.'
          )
        );
      },
      onError: error => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_EDIT_PROJECT,
            error?.response?.data?.msg
          )
        );
      }
    }
  );
}
