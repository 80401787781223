import React from 'react';

import Popover from '@material-ui/core/Popover';
import { Link, useLocation } from 'react-router-dom';
import {
  NAV_ITEMS_VALUES,
  CLASSIFICATION_METRICS,
  REGRESSION_METRICS
} from '@mpm-druid/constants';
import { ListItem, ListItemTitle } from '@ds';

export type ClassificationMetricType = typeof CLASSIFICATION_METRICS[number];
export type RegressionMetricType = typeof REGRESSION_METRICS[number];

type MetricPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  metricClickHandler: (
    metric: ClassificationMetricType | RegressionMetricType
  ) => void;
};

export const MetricPopover = ({
  anchorEl,
  onClose,
  metricClickHandler
}: MetricPopoverProps) => {
  const location = useLocation();
  const basePath = location.pathname.substring(
    0,
    location.pathname.lastIndexOf('/')
  );
  const newPath = `${basePath}/settings`;
  const queryParams = { panel: NAV_ITEMS_VALUES.CUSTOM_METRICS };

  const selectMetricHandler = (
    metric: ClassificationMetricType | RegressionMetricType
  ) => {
    metricClickHandler(metric);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{
        vertical: -6,
        horizontal: 'right'
      }}
    >
      <div className="custom-metrics-container">
        <div className="custom-metrics-list">
          <ListItemTitle text="Classification Metrics" />
          {CLASSIFICATION_METRICS.map((metric: ClassificationMetricType) => {
            return (
              <ListItem
                onClick={() => selectMetricHandler(metric)}
                key={metric.name}
              >
                <ListItem.Text primary={metric.name} />
              </ListItem>
            );
          })}
          <ListItemTitle text="Regression Metrics" />
          {REGRESSION_METRICS.map((metric: RegressionMetricType) => {
            return (
              <ListItem
                onClick={() => selectMetricHandler(metric)}
                key={metric.name}
              >
                <ListItem.Text primary={metric.name} />
              </ListItem>
            );
          })}
        </div>
        <div className="custom-metrics-link">
          <Link
            to={{
              pathname: newPath,
              search: new URLSearchParams(queryParams).toString()
            }}
          >
            Create a custom metric
          </Link>
        </div>
      </div>
    </Popover>
  );
};
