import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import cx from 'classnames';

import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';
import ExpandIcon from './ExpandIcon';
import GroupContent from './GroupContent';

const GroupCellComponent = props => {
  const { columnGrouping, style, ...cellProps } = props;
  const modifiedStyles = { ...style };

  if (props.row?.groupedBy && isArray(columnGrouping)) {
    const INITIAL_PADDING = 8;
    const LEVEL_PADDING = 20;
    const index = columnGrouping.indexOf(props.row.groupedBy);
    if (index >= 0) {
      modifiedStyles.paddingLeft = INITIAL_PADDING + LEVEL_PADDING * index;
    }
  }

  return (
    <TableGroupRow.Cell
      showColumnsWhenGrouped
      {...cellProps}
      className={cx('react-grid-group-row', props.className)}
      contentComponent={() => <GroupContent {...props} />}
      style={modifiedStyles}
      iconComponent={ExpandIcon}
    />
  );
};

GroupCellComponent.defaultProps = {
  columnGrouping: [],
  className: ''
};

GroupCellComponent.propTypes = {
  columnGrouping: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};

export default GroupCellComponent;
