import axios from 'axios';

import { BASE_API_URL } from '../lib/appConstants';

const BASE_MPM_URL = `${BASE_API_URL}mpm`;

const mpmApi = {
  fetchModelsCount(workspace, isMPMDruidEnabled) {
    const url = isMPMDruidEnabled
      ? `${BASE_MPM_URL}/v2/model/count?workspaceId=${workspace}`
      : `${BASE_MPM_URL}/${workspace}/models/count`;

    return axios.get(url);
  }
};

export default mpmApi;
