import { useState } from 'react';
import defaultTo from 'lodash/defaultTo';

const useTableRowSearch = (defaultValue = '') => {
  const [searchText, setSearchText] = useState(defaultValue);

  const handleSearch = (searchText: string) => {
    const newSearchText = defaultTo(searchText, '');
    setSearchText(newSearchText);
  };

  const resetSearch = () => {
    setSearchText('');
  };

  return { searchText, handleSearch, resetSearch };
};

export default useTableRowSearch;
