import { useQuery } from 'react-query';

import useProject from '../project/useProject';
import { cancelWrapper, axiosInstance } from '../api';

const getReports = ({ projectId }) =>
  cancelWrapper(async cancelToken => {
    const {
      data: { reports }
    } = await axiosInstance.get('reports/list', {
      cancelToken,
      params: { projectId }
    });

    return reports;
  });

export default function useReports() {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  return useQuery(['reports', { projectId }], () => getReports({ projectId }), {
    enabled: !!projectId
  });
}
