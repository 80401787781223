import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';

import useOverflowRow from '@shared/hooks/useOverflowRow';
import Tag from './Tag';
import TagsPopup from './TagsPopup';

import classNames from './Tag.module.scss';

const Tags = ({ list = [], withOverflow, withPopup, maxWidth }) => {
  const { isOverflowing, setRef } = useOverflowRow();
  const tagLabels = useMemo(
    () =>
      (list?.length &&
        [...list]
          .sort(
            (a, b) => a?.tag?.toLocaleLowerCase() > b?.tag?.toLocaleLowerCase()
          )
          .map(tag => <Tag key={tag.tag} {...tag} />)) ||
      [],
    [list]
  );

  return (
    <Box
      className={cx(
        classNames.dsTagContainerWrapper,
        isOverflowing && classNames.dsTagContainerOverflowed
      )}
    >
      <Box
        ref={setRef}
        className={classNames.dsTagContainer}
        style={{
          maxWidth: maxWidth || 'auto'
        }}
      >
        {tagLabels}
        {withOverflow && isOverflowing && (
          <p
            aria-haspopup="true"
            type="textOnly"
            className={cx(
              isOverflowing && classNames.dsTagContainerOverflowedThreeDots,
              withPopup && classNames.dsTagContainerOverflowedThreeDotsAction,
              withPopup && classNames.dsTagContainerOverflowedPopupHandler
            )}
          >
            ...
          </p>
        )}
        {withOverflow && withPopup && isOverflowing && (
          <TagsPopup
            className={classNames.dsTagContainerOverflowedPopup}
            tags={list}
          />
        )}
      </Box>
    </Box>
  );
};

Tags.defaultProps = {
  maxWidth: null,
  withOverflow: true,
  withPopup: false
};

Tags.propTypes = {
  maxWidth: PropTypes.string,
  withPopup: PropTypes.bool,
  withOverflow: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      color: PropTypes.string
    })
  ).isRequired
};

export default Tags;
