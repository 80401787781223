import { combineReducers } from 'redux';
import chartsPlotly from './chartsReducer';
import systemMetricsChart from './systemMetricsChartReducer';
import ui from './uiReducer';

const runDetails = combineReducers({
  chartsPlotly,
  systemMetricsChart,
  ui
});

export default runDetails;
