import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@design-system-outdated/components';
import Tooltip from '@material-ui/core/Tooltip';
import {
  MODEL_VERSION_STATUSES,
  MODEL_VERSION_STATUSES_COLORS
} from '@shared/constants/model-registry-request-status';

import ColorBox from '@shared/components/ColorBox';
import { Box } from '@material-ui/core';
import cx from 'classnames';
import { formatModelVersionStatusToText } from '@shared/utils/model-registry-request-status';
import classNames from './RequestStatus.module.scss';

const RequestStatus = ({
  status,
  action,
  component = 'span',
  showNoneAsButton,
  textFormatterProps,
  withTooltip = false,
  tooltipText,
  dataTest,
  disabled
}) => {
  const applyButtonStyles = status || showNoneAsButton;
  const el = useMemo(
    () => (
      <ColorBox
        className={cx(
          classNames.dsRequestStatus,
          !applyButtonStyles && classNames.dsStatusNoStatus,
          applyButtonStyles && classNames.dsStatusSelected,
          applyButtonStyles && !action && classNames.dsStatusNoAction
        )}
        type="textOnly"
        disabled={disabled}
        component={Button}
        onClick={action}
        text={formatModelVersionStatusToText(status, textFormatterProps)}
        bgColor={
          MODEL_VERSION_STATUSES_COLORS[status || MODEL_VERSION_STATUSES.NA] ||
          null
        }
      />
    ),
    [applyButtonStyles, action, status, textFormatterProps, classNames]
  );

  return (
    <Box component={component} data-test={dataTest}>
      {withTooltip ? (
        <Tooltip arrow title={tooltipText || 'Click to change'} placement="top">
          <span>{el}</span>
        </Tooltip>
      ) : (
        el
      )}
    </Box>
  );
};

RequestStatus.defaultProps = {
  disabled: false,
  showNoneAsButton: true,
  component: 'span',
  action: null,
  textFormatterProps: {},
  withTooltip: false,
  tooltipText: false,
  dataTest: null
};

RequestStatus.propTypes = {
  status: PropTypes.oneOf([
    null,
    MODEL_VERSION_STATUSES.STAGING,
    MODEL_VERSION_STATUSES.DEVELOPMENT,
    MODEL_VERSION_STATUSES.QA,
    MODEL_VERSION_STATUSES.PRODUCTION
  ]).isRequired,
  textFormatterProps: PropTypes.shape({
    extendedText: PropTypes.bool
  }),
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  withTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  showNoneAsButton: PropTypes.bool,
  action: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};

export default RequestStatus;
