import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent
} from 'react';

import { IconButton } from '@ds';
import { DSCheckMarkIcon, DSEditIcon } from '@ds-icons';

import styles from './PanelSectionsWrapper.module.scss';

const MIN_FIELD_WIDTH = 100;
const HORIZONTAL_PADDING = 10;

type PanelSectionTitleProps = {
  placeholder: string;
  title: string;
  count: number;
  onChange: (value: string) => void;
};

const PanelSectionTitle = ({
  placeholder,
  title,
  count,
  onChange
}: PanelSectionTitleProps) => {
  const [text, setText] = useState<string>(title);
  const [isEditMode, setIsEditMode] = useState(false);

  const textFieldRef = useRef<HTMLSpanElement>(null);

  const width = textFieldRef.current
    ? textFieldRef.current.getBoundingClientRect().width + HORIZONTAL_PADDING
    : MIN_FIELD_WIDTH;

  useEffect(() => {
    setText(title);
  }, [title]);

  const handleEditText = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    setText(value);
  }, []);

  const triggerOnChange = useCallback(() => {
    if (text === '') {
      setText(placeholder);
    }

    onChange(text || placeholder);
  }, [placeholder, text, onChange]);

  const handleSubmitText = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      handleEditText(event);
      setIsEditMode(false);

      triggerOnChange();
    },
    [handleEditText, triggerOnChange]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setIsEditMode(false);

        triggerOnChange();
      }
    },
    [triggerOnChange]
  );

  const handleEditClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsEditMode(true);
    },
    []
  );

  const handleDoneClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsEditMode(false);
    },
    []
  );

  const renderReadOnlyField = () => {
    return (
      <>
        <span className={styles.title}>{text}</span>
        <span data-test="section-panels-count">{` (${count})`}</span>
        <IconButton
          className={styles.editName}
          type="secondary"
          size="small"
          onClick={handleEditClick}
          Icon={<DSEditIcon />}
        />
      </>
    );
  };

  const renderEditableField = () => {
    return (
      <>
        <div
          style={{
            width: Math.max(width, MIN_FIELD_WIDTH)
          }}
        >
          <input
            type="text"
            placeholder={placeholder}
            value={text}
            onChange={handleEditText}
            onKeyDown={handleKeyDown}
            onBlur={handleSubmitText}
            onClick={e => e.stopPropagation()}
            autoFocus
          />
        </div>
        <IconButton
          size="small"
          Icon={<DSCheckMarkIcon />}
          onClick={handleDoneClick}
          type="secondary"
        />
      </>
    );
  };

  return (
    <div className={styles.panelSectionTitle}>
      <span className={styles.widthMeter} ref={textFieldRef}>
        {text}
      </span>
      {isEditMode ? renderEditableField() : renderReadOnlyField()}
    </div>
  );
};
export default PanelSectionTitle;
