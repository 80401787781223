// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageSamples-module__imageSamplesList--Ukavd{display:flex;flex-direction:row;gap:2px}.ImageSamples-module__imageSamplesList--Ukavd .ImageSamples-module__sampleImage--sf5eA{width:60px;height:60px;border:1px solid var(--gray-3);border-radius:5px;overflow:hidden}.ImageSamples-module__imageSamplesList--Ukavd .ImageSamples-module__sampleImage--sf5eA:hover{border:1px solid var(--primary-color);cursor:pointer}.ImageSamples-module__imageSamplesList--Ukavd .ImageSamples-module__sampleImage--sf5eA .ImageSamples-module__loaderContainer--seS5p{display:flex;align-items:center;justify-content:center;height:100%}.ImageSamples-module__imageSamplesList--Ukavd .ImageSamples-module__sampleImage--sf5eA img{height:auto;width:100%;image-rendering:pixelated}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageSamplesList": `ImageSamples-module__imageSamplesList--Ukavd`,
	"sampleImage": `ImageSamples-module__sampleImage--sf5eA`,
	"loaderContainer": `ImageSamples-module__loaderContainer--seS5p`
};
module.exports = ___CSS_LOADER_EXPORT___;
