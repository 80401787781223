import { useMutation, useQueryClient } from 'react-query';

import userApi from '@/util/userApi';

import useOptInNotifications from './useOptInNotifications';

const useUpdateOptInNotifications = () => {
  const queryClient = useQueryClient();

  const { data: isActiveOptInNotification } = useOptInNotifications();

  return useMutation(
    () => userApi.setOptInNotification(!isActiveOptInNotification),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['opt-in-notification']);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['opt-in-notification']);
      }
    }
  );
};

export default useUpdateOptInNotifications;
