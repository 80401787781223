import useProjectViews from './useProjectViews';

const getDefaultProjectView = views => {
  return views.find(view => view.projectDefault);
};

export default function useDefaultProjectView() {
  return useProjectViews(null, {
    select: getDefaultProjectView
  });
}
