/* eslint-disable no-useless-escape */
/* eslint-disable no-continue */
import { useEffect, useState, useCallback } from 'react';

const OPERAND_PATTERN = /([0-9]+([.][0-9]*)?|[.][0-9]+)|\[label\]|\[prediction\]|\[probability\]/;
const PROPERTY_PATTERN = /\[label\]|\[prediction\]|\[probability\]/;
const ALLOWED_CHARS_PATTERN = /[\[\]()+\-*/0-9.a-z\s]/g; // May be skipped this check (gi)
const OPERATORS_LIST = ['+', '-', '*', '/'];
const FUNCTIONS_LIST = ['abs', 'average'];
const NEGATION = 'neg';

// Tokens list
const TOKENS_LIST = {
  '(': { symbol: '(', pattern: /\(/, priority: 2 },
  ')': { symbol: ')', pattern: /\)/, priority: 2 },
  '+': { symbol: '+', pattern: /\+/, priority: 3, calc: (a, b) => a + b },
  '-': { symbol: '-', pattern: /\-/, priority: 3, calc: (a, b) => a - b },
  '*': { symbol: '*', pattern: /\*/, priority: 4, calc: (a, b) => a * b },
  '/': { symbol: '/', pattern: /\//, priority: 4, calc: (a, b) => a / b },
  neg: { symbol: 'neg', pattern: /neg/, priority: 4, calc: a => -1 * a },
  average: { symbol: 'average', pattern: /average/, priority: -1 },
  abs: { symbol: 'abs', pattern: /abs/, priority: -1 },
  label: { symbol: '[label]', pattern: /\[label\]/, priority: -1 },
  prediction: {
    symbol: '[prediction]',
    pattern: /\[prediction\]/,
    priority: -1
  },
  probability: {
    symbol: '[probability]',
    pattern: /\[probability\]/,
    priority: -1
  },
  operand: { pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/, priority: -1 }
};

export const useInfixPostfixParser = (formula, evaluateValue) => {
  const [parsedTokens, setParsedTokens] = useState('');
  const [postfixExpression, setPostfixExpression] = useState('');
  const [evaluatedValue, setEvaluatedValue] = useState('');
  const [formulaError, setFormulaError] = useState('');

  useEffect(() => {
    if (formulaError) {
      setParsedTokens('');
      setPostfixExpression('');
      setEvaluatedValue('');
    }
  }, [formulaError]);

  const getOperatorPriority = useCallback(operator => {
    if (TOKENS_LIST[operator]?.priority) {
      return TOKENS_LIST[operator].priority;
    }
    throw Error('Not allowed char, operator or property...');
  }, []);

  const validateInputTokens = useCallback(expression => {
    if (OPERATORS_LIST.includes(expression[expression.length - 1])) {
      throw Error('The last position operator is not allowed ...');
    }
    for (let i = 0; i < expression.length; i++) {
      if (
        OPERATORS_LIST.includes(expression[i]) &&
        OPERATORS_LIST.includes(expression[i + 1]) &&
        expression[i + 1] !== '-'
      ) {
        throw Error('Two or more operators in a row ...');
      }
      if (
        expression[i] === '(' &&
        OPERATORS_LIST.includes(expression[i + 1]) &&
        expression[i + 1] !== '-'
      ) {
        throw Error(
          'Operator after opening bracket error. Should be an operand...'
        );
      }
      if (expression[i] === ')' && OPERATORS_LIST.includes(expression[i - 1])) {
        throw Error(
          'Operator before closing bracket error. Should be an operand...'
        );
      }
    }
  }, []);

  const getParsedTokens = useCallback(strFormula => {
    let trimmedFormula = strFormula.replace(/\s/g, ''); // clean all string spaces
    const parsedFormula = [];
    const containsAllowedChars = ALLOWED_CHARS_PATTERN.test(trimmedFormula);
    if (!containsAllowedChars) {
      throw Error('Invalid char, operator or property exists in formula...');
    }
    const tokenPatterns = Object.values(TOKENS_LIST);
    /* const tokenPatterns = infix.split(/([\+\-\*\/\^\(\)])/).clean() */
    // More sofisticated approach of splitting :TBD
    let foundToken = true;
    while (trimmedFormula.length && foundToken) {
      foundToken = false;
      for (const tokenPattern of tokenPatterns) {
        const matches = tokenPattern.pattern.exec(trimmedFormula);
        if (matches && matches.index === 0) {
          const tokenLength = matches[0].length;
          parsedFormula.push(matches[0]);
          trimmedFormula =
            trimmedFormula.length >= tokenLength
              ? trimmedFormula.slice(tokenLength)
              : trimmedFormula;
          foundToken = true;
          break;
        }
      }
    }
    if (!foundToken) {
      throw Error('Invalid function, property or char exists in formula...');
    }
    setParsedTokens(parsedFormula);
    return parsedFormula;
  }, []);

  const getPostfixExpression = useCallback(
    infixString => {
      const tokens = getParsedTokens(infixString); // array
      validateInputTokens(tokens);
      const postfixExpression = [];
      const stack = [];
      let current = null;
      let previous = null;

      for (let index = 0; index < tokens.length; index++) {
        previous = current;
        current = tokens[index];
        if (!isNaN(parseFloat(current)) || OPERAND_PATTERN.test(current)) {
          postfixExpression.push(current);
          continue;
        }
        if (TOKENS_LIST[current]) {
          // check for unary minus sign
          if (current === '-') {
            if (
              previous === null ||
              previous === '(' ||
              OPERATORS_LIST.includes(previous)
            ) {
              stack.push(NEGATION);
              continue;
            }
          }
          // always push open bracket to stack
          if (
            current === '(' ||
            FUNCTIONS_LIST.includes(current) ||
            !stack.length
          ) {
            stack.push(current);
            continue;
          }
          // Check for closed bracket
          if (current === ')') {
            // check for aggregation functions with one value
            if (previous === '(') {
              throw Error('Empty brackets or missed bracket...');
            }
            while (stack.length && stack[stack.length - 1] !== '(') {
              postfixExpression.push(stack.pop());
            }
            if (stack[stack.length - 1] === '(') {
              stack.pop();
            } else {
              throw Error('Missed opening bracket');
            }
            if (
              stack.length &&
              FUNCTIONS_LIST.includes(stack[stack.length - 1])
            ) {
              postfixExpression.push(stack.pop());
            }
            continue;
          }
          if (stack.length) {
            const currentOperandPriority = TOKENS_LIST[current].priority;
            const topStackOperandPriority = getOperatorPriority(
              stack[stack.length - 1]
            );
            if (topStackOperandPriority === -1) {
              return [];
            }
            if (currentOperandPriority > topStackOperandPriority) {
              stack.push(current);
              continue;
            }
            if (currentOperandPriority < topStackOperandPriority) {
              while (
                stack.length &&
                currentOperandPriority <
                  getOperatorPriority(stack[stack.length - 1])
              ) {
                postfixExpression.push(stack.pop());
              }
              if (
                stack.length &&
                currentOperandPriority ===
                  getOperatorPriority(stack[stack.length - 1])
              ) {
                postfixExpression.push(stack.pop());
                stack.push(current);
                continue;
              }
              stack.push(current);
              continue;
            }
            if (currentOperandPriority === topStackOperandPriority) {
              postfixExpression.push(stack.pop());
              stack.push(current);
              continue;
            }
          }
        } else {
          throw Error('Unhandled token is detected!');
        }
      }
      while (stack.length) {
        const token = stack.pop();
        if (token === '(') {
          throw Error('Missed closing bracket');
        }
        postfixExpression.push(token);
      }
      setPostfixExpression(postfixExpression);
      return postfixExpression;
    },
    [getOperatorPriority, getParsedTokens, validateInputTokens]
  );

  const getTokenValue = useCallback(token => {
    const value = parseFloat(token);
    if (!isNaN(value)) {
      return value;
    }
    if (PROPERTY_PATTERN.test(token)) {
      /* return parseFloat(Math.random().toFixed(3)) */ return 1;
    }
  }, []);

  const evaluatePostfix = useCallback(
    expression => {
      const stack = [];
      for (let i = 0; i < expression.length; i++) {
        const token = expression[i];

        if (OPERAND_PATTERN.test(token)) {
          stack.push(getTokenValue(token));
          continue;
        }
        if (OPERATORS_LIST.includes(token)) {
          const token1 = stack.pop();
          const token2 = stack.pop();
          if (token1 == null || token2 == null) {
            throw Error('Formula error ...');
          }
          stack.push(TOKENS_LIST[token].calc(token2, token1));
          continue;
        }
        if (!stack.length) {
          throw Error('Formula error ...');
        }
        if (token === TOKENS_LIST.abs.symbol) {
          stack.push(Math.abs(stack.pop()));
          continue;
        }
        if (token === TOKENS_LIST.average.symbol) {
          continue;
        }
        if (token === TOKENS_LIST.neg.symbol) {
          stack.push(-1 * stack.pop());
          continue;
        }
      }
      const evaluation = stack.pop();
      if (stack.length) {
        throw Error('Formula error ...');
      }
      setEvaluatedValue(evaluation);
      return evaluation;
    },
    [getTokenValue]
  );

  useEffect(() => {
    if (evaluateValue) {
      try {
        setFormulaError('');
        const expression = getPostfixExpression(formula);
        evaluatePostfix(expression);
      } catch (error) {
        setFormulaError(error.message);
      }
    }
  }, [getPostfixExpression, evaluateValue, formula, evaluatePostfix]);

  return [parsedTokens, postfixExpression, evaluatedValue, formulaError];
};
