// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelVersionApiTab-module__modelRegistryVersionApi--SQh_S{width:800px}.ModelVersionApiTab-module__modelRegistryVersionApiCopy--xJlCN{cursor:pointer;position:absolute;right:21px;top:70px;text-align:center}.ModelVersionApiTab-module__modelRegistryVersionApiCopy--xJlCN svg{color:var(--gray-3);will-change:color;transition:color .4s ease-out}.ModelVersionApiTab-module__modelRegistryVersionApiCopy--xJlCN:hover svg{color:var(--gray-5)}.ModelVersionApiTab-module__modelRegistryVersionApiCode--oZmd6{padding-right:55px !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelRegistryVersionApi": `ModelVersionApiTab-module__modelRegistryVersionApi--SQh_S`,
	"modelRegistryVersionApiCopy": `ModelVersionApiTab-module__modelRegistryVersionApiCopy--xJlCN`,
	"modelRegistryVersionApiCode": `ModelVersionApiTab-module__modelRegistryVersionApiCode--oZmd6`
};
module.exports = ___CSS_LOADER_EXPORT___;
