// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PanelsTab-module__panelsTab--lEziV .PanelsTab-module__content--WELQy{padding:0 14px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelsTab": `PanelsTab-module__panelsTab--lEziV`,
	"content": `PanelsTab-module__content--WELQy`
};
module.exports = ___CSS_LOADER_EXPORT___;
