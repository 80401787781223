import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';

import { TextButton, Button } from '@ds';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';

import Select from '@shared/components/Select';
import SmallLoader from '@shared/components/SmallLoader';
import modelRegistryActions from '@/actions/modelRegistryActions';
import {
  getModelRegistryMap,
  getModelRegistryNames,
  getLastRegistryModelsFetch
} from '@/reducers/modelRegistryReducer';
import ModelVersionField from './ModelVersionField';
import { DSPlusIcon } from '@ds-icons';

const UpdateModel = ({
  dispatch,
  experimentModel,
  lastDataFetch,
  modelNames,
  modelsMap,
  navigationHandler,
  successMessageHandler,
  workspace
}) => {
  const queryClient = useQueryClient();
  const { experimentModelId, experimentKey } = experimentModel;

  const [newModelVersion, setNewModelVersion] = useState('');
  const [selectedModel, setSelectedModel] = useState(null);

  const selectedModelId = get(selectedModel, 'value', '');
  const selectedModelName = get(selectedModel, 'label', '');

  const model = get(modelsMap, selectedModelId, {});
  const selectedModelVersions = get(model, 'versions', []);

  const sortedVersions = last(sortBy(selectedModelVersions, 'createdAt'));
  const currentVersion = get(sortedVersions, 'version', '1.0.0');

  const handleUpdateModel = () => {
    dispatch(
      modelRegistryActions.createNewModelItem(
        experimentModelId,
        selectedModelName,
        newModelVersion
      )
    ).then(() => {
      queryClient.invalidateQueries(
        'modelRegistry',
        { experimentKey },
        'experiments'
      );
      successMessageHandler('Model successfully saved');
    });
  };

  useEffect(() => {
    if (isNull(selectedModel)) return;

    if (isEmpty(selectedModelVersions)) {
      dispatch(
        modelRegistryActions.fetchRegisteredModel(workspace, selectedModelName)
      );
    }
  }, [
    selectedModel,
    selectedModelName,
    workspace,
    selectedModelVersions,
    dispatch
  ]);

  useEffect(() => {
    dispatch(modelRegistryActions.fetchModelRegistryList(workspace));
  }, []);

  const getTooltipText = () => {
    if (isNull(selectedModel)) {
      return 'You need to select a model to update';
    }

    if (isEmpty(newModelVersion)) {
      return 'You must create a version for this model';
    }

    if (newModelVersion === currentVersion) {
      return 'The new version cannot be the same as the current version';
    }
  };

  const renderRegisteredModelSelect = () => {
    const options = modelNames.map(modelName => {
      return { value: `${workspace}-${modelName}`, label: modelName };
    });

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">
          Search a registered model
        </InputLabel>
        <Select
          className="generic-modal-select"
          value={selectedModel}
          onChange={selectedOption => setSelectedModel(selectedOption)}
          options={options}
          placeholder="Enter model name"
          isClearable
          maxMenuHeight={220}
        />
      </div>
    );
  };

  const renderCurrentVersionField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Current Version</InputLabel>
        <span className="modal-text">{currentVersion}</span>
      </div>
    );
  };

  const renderCreateVersionField = () => {
    return (
      <div className="generic-modal-section">
        <ModelVersionField
          label="Create Version"
          registryModelId={model.registryModelId}
          versionChangeHandler={setNewModelVersion}
          placeholder={currentVersion}
          value={newModelVersion}
        />
      </div>
    );
  };

  const renderModalSubheader = () => {
    return (
      <div className="generic-modal-section">
        <p className="generic-modal-subheader">Save to Existing</p>
      </div>
    );
  };

  const renderSaveButton = () => {
    const tooltipText = getTooltipText();

    return (
      <div className="generic-modal-section">
        <Tooltip
          title={tooltipText}
          placement="top"
          disableHoverListener={isEmpty(tooltipText)}
        >
          <span>
            <Button
              size="large"
              onClick={handleUpdateModel}
              disabled={!isEmpty(tooltipText)}
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  };

  const renderRegisterNewButton = () => {
    return (
      <TextButton
        size="large"
        PrefixIcon={<DSPlusIcon />}
        onClick={navigationHandler}
      >
        Register New Model
      </TextButton>
    );
  };

  const renderLoader = () => {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching registered models"
        isFlexDisplay
      />
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderModalSubheader()}
        {renderRegisteredModelSelect()}
        {renderCurrentVersionField()}
        {renderCreateVersionField()}
        {renderSaveButton()}
      </>
    );
  };

  return (
    <>
      <DialogContent className="generic-modal-body">
        {isNull(lastDataFetch) ? renderLoader() : renderContent()}
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt left">
        {renderRegisterNewButton()}
      </DialogActions>
    </>
  );
};

UpdateModel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  experimentModel: PropTypes.object.isRequired,
  lastDataFetch: PropTypes.number.isRequired,
  modelNames: PropTypes.array.isRequired,
  modelsMap: PropTypes.object.isRequired,
  navigationHandler: PropTypes.func.isRequired,
  successMessageHandler: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const { workspace } = props;

  return {
    lastDataFetch: getLastRegistryModelsFetch(state),
    modelsMap: getModelRegistryMap(state),
    modelNames: getModelRegistryNames(state, { workspace })
  };
};

export default connect(mapStateToProps)(UpdateModel);
