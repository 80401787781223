import React from 'react';
import cx from 'classnames';

import { Tooltip } from '@ds';
import { BreadcrumbItemProps } from '@shared/types';

import styles from './BreadcrumbItem.module.scss';

const TextItem = ({
  dataMaskTest,
  title,
  isLast = false
}: BreadcrumbItemProps) => {
  return (
    <Tooltip
      content={title}
      size="small"
      enterDelay={300}
      enterNextDelay={300}
      wrapper={false}
      arrow={false}
    >
      <span
        className={cx(styles.item, styles.textItem, { [styles.last]: isLast })}
        data-mask-test={dataMaskTest}
      >
        {title}
      </span>
    </Tooltip>
  );
};

export default TextItem;
