import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@ds';
import { getDownloadArtifactVersionURL } from '@/util/artifactsApi';
import { DSDownloadIcon } from '@ds-icons';

const DownloadArtifactVersionBtn = ({ artifactVersionId, disabled }) => {
  const preventNavigation = e => {
    e.stopPropagation();
  };

  return (
    <Tooltip content="Download version assets">
      <a href={getDownloadArtifactVersionURL(artifactVersionId)}>
        <IconButton
          Icon={<DSDownloadIcon />}
          type="secondary"
          disabled={disabled}
          onClick={preventNavigation}
        />
      </a>
    </Tooltip>
  );
};

DownloadArtifactVersionBtn.defaultProps = {
  disabled: false
};

DownloadArtifactVersionBtn.propTypes = {
  artifactVersionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default DownloadArtifactVersionBtn;
