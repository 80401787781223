import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes, snackbarTypes } from '../../../constants/alertTypes';
import { useActiveWorkspace } from '@shared/hooks';

import alertsUtil from '../../../util/alertsUtil';

const deleteExampleProject = ({ projectId, workspaceId }) => {
  return api.post('workspaces/remove-starter-project', {
    projectId,
    teamId: workspaceId
  });
};

export default function useDeleteExampleProjectMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const workspaceName = useWorkspaceName();
  const workspace = useActiveWorkspace();

  return useMutation(
    ({ projectId }) => {
      return deleteExampleProject({ projectId, workspaceId: workspace.id });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projects', { workspaceName }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['projects', { workspaceName }]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_SNACKBAR_DIALOG,
            'Successfuly removed example project from your workspace'
          )
        );
      },
      onError: error => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_EDIT_PROJECT,
            error?.response?.data?.msg
          )
        );
      }
    }
  );
}
