import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '@ds';

import isEqual from 'fast-deep-equal';
import noop from 'lodash/noop';
import cloneDeep from 'lodash/cloneDeep';

import {
  generateEmptyAndGroup,
  generateEmptyRulesTree,
  generateNewRule,
  getRulesCount,
  IOrGroupRule
} from '@shared/utils/filterHelpers';
import AndRulesGroup from '@shared/components/QueryFiltersBody/subcomponents/AndRulesGroup';
import OrButton, {
  OR_BUTTON_MODE
} from '@shared/components/QueryFiltersBody/subcomponents/OrButton';

import styles from './QueryFiltersBody.module.scss';
import { DSPlusIcon } from '@ds-icons';

export const FILTER_BEHAVIOUR_MODE = {
  AND: 'and',
  OR_AND: 'or_and'
};

const QueryFiltersBody = ({
  mode,
  columns,
  editable,
  rulesTree: savedRulesTree,
  onChange,
  constructFilterDefinition,
  getOperatorsForRule,
  constructRuleLabel
}) => {
  const isOrAndMode = mode === FILTER_BEHAVIOUR_MODE.OR_AND;
  const [rulesTree, setRulesTree] = useState(savedRulesTree);
  const rulesCount = getRulesCount(rulesTree);
  const showAddRuleButton = rulesCount === 0;
  const showAddOrGroupButton = rulesCount > 0 && isOrAndMode;

  useEffect(() => {
    setRulesTree(savedRulesTree);
  }, [savedRulesTree]);

  const handleAddRule = () => {
    const localRulesTree = generateEmptyRulesTree();

    localRulesTree.rules[0].rules.push(generateNewRule());

    setRulesTree(localRulesTree);
  };

  const handleAddAndGroup = () => {
    const localRulesTree = generateEmptyRulesTree();
    const emptyAndGroup = generateEmptyAndGroup();

    emptyAndGroup.rules.push(generateNewRule());
    localRulesTree.rules = [...rulesTree.rules, emptyAndGroup];

    setRulesTree(localRulesTree);
  };

  const handleRemoveAndGroup = index => {
    const localRulesTree = generateEmptyRulesTree();

    localRulesTree.rules = rulesTree.rules.filter((rule, i) => index !== i);

    setRulesTree(localRulesTree);
  };

  const updateRulesTree = useCallback(
    newRulesTree => {
      if (isEqual(savedRulesTree, newRulesTree)) {
        setRulesTree(savedRulesTree);
        return;
      }

      setRulesTree(newRulesTree);
      onChange(newRulesTree);
    },
    [onChange, savedRulesTree]
  );

  const handleOrGroupChange = useCallback(
    (index, rules) => {
      const localRulesTree = cloneDeep(rulesTree);

      if (rules.length === 0 && isOrAndMode) {
        localRulesTree.rules = localRulesTree.rules.filter(
          (rule, i) => index !== i
        );
      } else {
        localRulesTree.rules[index].rules = rules;
      }

      updateRulesTree(localRulesTree);
    },
    [isOrAndMode, rulesTree, updateRulesTree]
  );

  const renderAddRuleButton = () => {
    return (
      <div>
        <TextButton
          data-test="add-filter-button"
          onClick={handleAddRule}
          PrefixIcon={<DSPlusIcon />}
        >
          Add a filter condition
        </TextButton>
      </div>
    );
  };

  const renderOrButton = () => {
    return <OrButton onClick={handleAddAndGroup} />;
  };

  const renderFilterElements = () => {
    return (rulesTree?.rules || []).map((rule, index) => {
      const needOr = index > 0 && isOrAndMode;

      return (
        <>
          {needOr && (
            <OrButton
              // eslint-disable-next-line react/no-array-index-key
              key={`or-button-${index}`}
              mode={OR_BUTTON_MODE.REMOVE}
              ruleIndex={index}
              onClick={() => handleRemoveAndGroup(index)}
            />
          )}
          <AndRulesGroup
            // eslint-disable-next-line react/no-array-index-key
            key={`and-rule-group-${index}`}
            columns={columns}
            editable={editable}
            ruleIndex={index}
            rules={rule.rules}
            onChange={handleOrGroupChange}
            getOperatorsForRule={getOperatorsForRule}
            constructFilterDefinition={constructFilterDefinition}
            constructRuleLabel={constructRuleLabel}
          />
        </>
      );
    });
  };

  return (
    <div className={styles.container}>
      {renderFilterElements()}
      {showAddRuleButton && editable && renderAddRuleButton()}
      {showAddOrGroupButton && editable && renderOrButton()}
    </div>
  );
};

QueryFiltersBody.defaultProps = {
  mode: FILTER_BEHAVIOUR_MODE.AND,
  columns: [],
  editable: true,
  onChange: noop,
  constructFilterDefinition: noop,
  constructRuleLabel: undefined
};

QueryFiltersBody.propTypes = {
  mode: PropTypes.oneOf([
    FILTER_BEHAVIOUR_MODE.AND,
    FILTER_BEHAVIOUR_MODE.OR_AND
  ]),
  columns: PropTypes.array,
  editable: PropTypes.bool,
  rulesTree: IOrGroupRule.isRequired,
  onChange: PropTypes.func,
  constructFilterDefinition: PropTypes.func,
  getOperatorsForRule: PropTypes.func.isRequired,
  constructRuleLabel: PropTypes.func
};

export default QueryFiltersBody;
