import {
  WebhookHttpResponse,
  WebhookNotifierResponse,
  WebhookStatus,
  WebhookTestResponse
} from '@model-registry/types';

export function convertTestResponseToNotifierResponse(
  data: WebhookTestResponse
): WebhookNotifierResponse {
  const { statusText, statusCode, body, headers, errorMsg, isError } = data;

  if (isError && errorMsg) {
    return {
      status: WebhookStatus.INTERNAL_ERROR,
      internalError: errorMsg
    };
  } else {
    return {
      status: isError ? WebhookStatus.HTTP_ERROR : WebhookStatus.SUCCESS,
      httpResponse: {
        statusCode,
        headers,
        body,
        statusText
      }
    };
  }
}

export const transformHttpToCurlResponse = (
  response: WebhookHttpResponse,
  url: string
) => {
  const { statusCode, headers, body, statusText } = response;
  const urlObj = new URL(url);
  const host = urlObj.host;
  const path = urlObj.pathname;

  const requestString = `> POST ${path} HTTP\n> Host: ${host}\n>\n`;
  const responseString = `< HTTP ${statusCode} ${statusText}\n< ${Object.entries(
    headers
  )
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n< ')}\n<\n${body}`;

  return `${requestString}${responseString}`;
};

const buildInternalError = (response: WebhookNotifierResponse, url: string) =>
  `We encountered an error calling ${url}.\n\n${response.internalError}`;

const buildHttpResponseSummary = (
  response: WebhookHttpResponse,
  url: string
) => {
  const { statusCode, headers, body, statusText } = response;
  const responseHeaderLines = Object.entries(headers || {})
    .map(([name, value]) => `< ${name}: ${value}`)
    .join('\n');

  return `Received status code ${statusCode} ${statusText} for ${url}\n\n${body}${responseHeaderLines}`;
};

export const buildWebhookNotifierSummary = (
  response: WebhookNotifierResponse,
  url: string
) => {
  if (response.status === WebhookStatus.INTERNAL_ERROR) {
    return buildInternalError(response, url);
  }

  return buildHttpResponseSummary(response.httpResponse, url);
};
