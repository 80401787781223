import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Resizable } from 're-resizable';
import cx from 'classnames';

import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

import { TextButton } from '@ds';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';

import { iFrameConfig } from '@experiment-management-shared/constants';

import { getSrcDoc } from '@/reducers/ui/visualizationsUiReducer';
import { isValidMessage } from '@experiment-management-shared/utils/visualizationsHelper';

const { messageTypes, consoleMethodTypes } = iFrameConfig;

const CodeConsole = ({ templateScopeType }) => {
  const srcDoc = useSelector(getSrcDoc);
  const [consoleOutput, setConsoleOutput] = useState([]);
  const userCodeIndex = srcDoc.indexOf('// %__START_USER_CODE__%');
  const linesBeforeUserCode = srcDoc.substring(0, userCodeIndex).split('\n')
    .length;

  const clearSrcDocConsoleOutput = useCallback(() => {
    const { iframeId } = iFrameConfig;

    const codePreviewIframe = document.getElementById(iframeId);
    if (codePreviewIframe) {
      codePreviewIframe.contentWindow.postMessage(
        {
          messageType: messageTypes.CLEAR_CONSOLE_LIST
        },
        '*'
      );
    }
  }, []);

  const clearConsoleOutput = () => {
    clearSrcDocConsoleOutput();
    setConsoleOutput([]);
  };

  const handleReceiveMessage = useCallback(
    event => {
      const { data, origin } = event;

      if (isValidMessage(origin, templateScopeType)) {
        const { messageType, ...payload } = data;
        if (messageType === messageTypes.CLEAR_CONSOLE) {
          clearConsoleOutput();
        }

        if (messageType === messageTypes.UPDATE_CONSOLE) {
          // ugly fix to filter out specific CSS error message
          const output = [];
          if (isArray(payload.consoleOutput)) {
            for (const i of payload.consoleOutput) {
              if (
                !(
                  i.type === 'log' &&
                  i.values.length === 2 &&
                  i.values[0].indexOf('Error while reading CSS rules from') ===
                    0
                )
              ) {
                output.push(i);
              }
            }
          }

          setConsoleOutput(output);
        }
      }
    },
    [templateScopeType]
  );

  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [handleReceiveMessage]);

  const formatValue = (value, type) => {
    if (isObjectLike(value)) {
      return JSON.stringify(value);
    }

    if (isString(value) && type === consoleMethodTypes.log) {
      return `"${value}"`;
    }

    if (isNil(value)) {
      return `${value}`;
    }

    return value;
  };

  const renderErrorDetails = ({ lineNumber, columnNumber }) => {
    const adjLineNumber = lineNumber - linesBeforeUserCode;

    if (isNaN(adjLineNumber)) return null;

    const detail = columnNumber
      ? `line: ${adjLineNumber}, col: ${columnNumber}`
      : `line: ${adjLineNumber}`;

    return <span>{detail}</span>;
  };

  const renderOutputByType = ({ type, values, details }) => {
    const displayValue = values
      .map(value => formatValue(value, type))
      .join('  ');

    if (type === consoleMethodTypes.error) {
      return (
        <>
          <CancelIcon fontSize="small" />

          <div className="display-value-container">
            {displayValue}
            {renderErrorDetails(details)}
          </div>
        </>
      );
    }

    if (type === consoleMethodTypes.warn) {
      return (
        <>
          <WarningIcon fontSize="small" />

          <div className="display-value-container">{displayValue}</div>
        </>
      );
    }

    return displayValue;
  };

  const renderListItems = () => {
    return consoleOutput.map((output, index) => {
      const { type } = output;

      const modifierClass = {
        [consoleMethodTypes[type]]: consoleMethodTypes.hasOwnProperty(type)
      };

      return (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={cx('code-console-list-item', modifierClass)}>
          {renderOutputByType(output)}
        </li>
      );
    });
  };

  return (
    <Resizable
      className="code-console"
      defaultSize={{
        height: '100px',
        width: '100%'
      }}
      enable={{
        top: true,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
    >
      <div className="editor-window-header">
        <p className="header-title">Console</p>
        <div className="btn-group right">
          <TextButton
            className="primary-light-button"
            onClick={clearConsoleOutput}
            data-test="clear-console-button"
          >
            Clear
          </TextButton>
        </div>
      </div>
      <div className="code-console-list-container">
        <ul className="code-console-list">{renderListItems()}</ul>
      </div>
    </Resizable>
  );
};

CodeConsole.propTypes = {
  templateScopeType: PropTypes.string.isRequired
};

export default CodeConsole;
