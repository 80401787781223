import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useDeleteReportsMutation from '@reports/api/useDeleteReportsMutation';
import useDuplicateReportMutation from '@reports/api/useDuplicateReportMutation';
import ReportTableRowActions from '@reports/components/ReportTableRowActions';
import { REPORT_COLUMNS } from '@reports/constants';
import { generateReportURL } from '@reports/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import DuplicateItemModal from '@reports/components/DuplicateItemModal';
import TextOverflow from '@shared/components/TextOverflow/TextOverflow';
import { dialogTypes } from '@/constants/alertTypes';
import { normalizeAvatarURL } from '@/lib/utils';
import alertsUtil from '@/util/alertsUtil';
import styles from './ReportsTableRow.module.scss';

const { DUPLICATE_ITEM_MODAL } = dialogTypes;

const formatDate = date => {
  return date ? moment.unix(date / 1000).format('MM/DD/YYYY') : '-';
};

const avatarWidth = 26;

const {
  AUTHOR,
  DESCRIPTION,
  DISPLAY_NAME,
  LAST_UPDATED,
  REPORT_ID,
  REPORT_NAME
} = REPORT_COLUMNS;

const ReportsTableRow = ({
  [AUTHOR]: author,
  [DESCRIPTION]: description,
  [DISPLAY_NAME]: displayName,
  [LAST_UPDATED]: lastUpdated,
  [REPORT_ID]: reportId,
  [REPORT_NAME]: reportName,
  authorProfileImageUrl,
  canEdit,
  hasTemplates,
  isItemSelected,
  isTemplate,
  onSelectRow
}) => {
  const deleteReportsMutation = useDeleteReportsMutation();
  const duplicateReportMutation = useDuplicateReportMutation();
  const dispatch = useDispatch();
  const { projectName, workspace } = useParams();
  const viewUrl = generateReportURL({
    isTemplate,
    projectName,
    reportName,
    workspace
  });
  const name = displayName || reportName;

  const handleDuplicate = newReportName => {
    duplicateReportMutation.mutate({
      isTemplate,
      newReportName,
      originalReportId: reportId
    });
  };

  const handleOpenDuplicateModal = () => {
    const duplicateItemModal = (
      <DuplicateItemModal
        entityName="report"
        modalId={DUPLICATE_ITEM_MODAL}
        onSave={handleDuplicate}
        originalItemName={name}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(DUPLICATE_ITEM_MODAL, duplicateItemModal)
    );
  };

  const handleDelete = () => {
    dispatch(
      alertsUtil.openConfirmDialog(
        dialogTypes.CONFIRM_DELETE_REPORT,
        `This will delete the report: ${name}`,
        () => {
          deleteReportsMutation.mutate({ reportIds: [reportId] });
          dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_REPORT));
        },
        'Delete'
      )
    );
  };

  const renderTemplateBadge = () => {
    if (!isTemplate) return null;

    return <span className={styles.badge}>template</span>;
  };

  return (
    <TableRow
      key={reportId}
      className={styles.row}
      selected={isItemSelected}
      role="checkbox"
      hover
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onChange={event => onSelectRow(event, reportId)}
        />
      </TableCell>
      <TableCell className="reports-table-row-name">
        <Link to={viewUrl} style={{ whiteSpace: 'nowrap' }}>
          {name}
        </Link>
      </TableCell>
      {hasTemplates && (
        <TableCell className="reports-table-row-owner">
          {renderTemplateBadge()}
        </TableCell>
      )}
      <TableCell className="reports-table-row-owner">
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={normalizeAvatarURL(authorProfileImageUrl, avatarWidth)}
            style={{
              borderRadius: avatarWidth / 2,
              width: avatarWidth,
              height: avatarWidth,
              marginRight: 10
            }}
          />
          {author}
        </span>
      </TableCell>
      <TableCell className="reports-table-row-last-saved">
        {formatDate(lastUpdated)}
      </TableCell>
      <TableCell className="reports-table-row-description">
        <TextOverflow
          emptyTextPlaceholder="-"
          maxVisibleLength={100}
          originalText={description}
          tooltipMode="light"
          renderAsHTML
        />
      </TableCell>
      <TableCell align="right" className="reports-table-row-actions">
        <ReportTableRowActions
          canEdit={canEdit}
          isTemplate={isTemplate}
          name={reportName}
          onDuplicate={handleOpenDuplicateModal}
          onRemove={handleDelete}
        />
      </TableCell>
    </TableRow>
  );
};

ReportsTableRow.defaultProps = {
  displayName: ''
};

ReportsTableRow.propTypes = {
  author: PropTypes.string.isRequired,
  authorProfileImageUrl: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  hasTemplates: PropTypes.bool.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired
};

export default ReportsTableRow;
