import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import first from 'lodash/first';

import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { COLORS } from '@/constants/colorConstants';
import FilterContainer from '@shared/components/FilterContainer';

const StyledRadio = (
  <Radio
    size="small"
    style={{ color: COLORS.primary, zIndex: 0, fontSize: 20 }}
  />
);

const RadioFilterPopover = ({
  className,
  selectedOption,
  filter,
  handleFilterChange,
  disabled
}) => {
  const { groupLabel, filterKey, options } = filter;

  const handleResetSelection = () => {
    handleFilterChange(filterKey, first(options));
  };

  const handleRadioSelect = e => {
    const { value } = e.target;

    const option = find(options, { value });

    handleFilterChange(filterKey, option);
  };

  const renderOptions = () => {
    if (isEmpty(options)) {
      return (
        <ListItem className="filter-list-item">
          <ListItemText>No Options</ListItemText>
        </ListItem>
      );
    }
    return options.map(({ label, value }) => {
      return (
        <FormControlLabel
          className="filter-list-item"
          key={value}
          control={StyledRadio}
          value={value}
          label={label}
        />
      );
    });
  };

  return (
    <FilterContainer
      className={className}
      groupLabel={groupLabel}
      selectedLabel={selectedOption.label}
      resetSelectionHandler={handleResetSelection}
      disabled={disabled}
    >
      <FormControl component="fieldset" fullWidth>
        <RadioGroup value={selectedOption.value} onChange={handleRadioSelect}>
          {renderOptions()}
        </RadioGroup>
      </FormControl>
    </FilterContainer>
  );
};

RadioFilterPopover.defaultProps = {
  className: ''
};

RadioFilterPopover.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default RadioFilterPopover;
