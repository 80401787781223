import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import {
  OUTLIERS_VALUES,
  SAMPLE_SIZES,
  TRANSFORM_TYPES_OBJECT
} from '@experiment-management-shared/constants/chartConstants';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { PanelGlobalConfig } from '@experiment-management-shared/types';
import typography from '@ds-typography';
import { IconButton, RadioButton, Tooltip, Select } from '@ds';
import { DSMoreIcon } from '@ds-icons';
import Popover from '@material-ui/core/Popover';

import styles from './Button.module.scss';
import { OptionType } from '@design-system/types';

type AdditionalSettingsButtonProps = {
  disabled: boolean;
  panelGlobalConfig: PanelGlobalConfig;
  onChange: (changes: unknown) => void;
  showLockedPanelsWarning: boolean;
};
const AdditionalSettingsButton = ({
  disabled,
  panelGlobalConfig,
  onChange,
  showLockedPanelsWarning
}: AdditionalSettingsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const baseTrackEvent = useBaseTrackEvent();

  const { LOG_VALUE, LOG_BASE_10, LOG_SCALE } = TRANSFORM_TYPES_OBJECT;
  const transformYOptions = [{ value: 'none', label: 'None' }].concat(
    [LOG_VALUE, LOG_BASE_10, LOG_SCALE].map(type =>
      formatValueForSelectOption(type)
    )
  ) as OptionType[];

  const sampleSizeOptions = SAMPLE_SIZES.map(({ value }) =>
    formatValueForSelectOption(value)
  ) as OptionType[];

  const onChangeTransformValue = useCallback(
    (selectEvent: unknown) => {
      const value = selectEvent === 'none' ? null : selectEvent;

      onChange({
        panels: {
          config: {
            ...panelGlobalConfig,
            transformY: value
          }
        }
      });

      baseTrackEvent(
        dashboardEvents.PROJECT_PAGE_PANELS_Y_AXIS_TRANSFORMATION_UPDATED,
        {
          old_value: panelGlobalConfig.transformY,
          new_value: value
        }
      );
    },
    [baseTrackEvent, onChange, panelGlobalConfig]
  );

  const onChangeOutliersValue = useCallback(
    value => {
      onChange({
        panels: {
          config: {
            ...panelGlobalConfig,
            selectedOutliers: value
          }
        }
      });

      baseTrackEvent(dashboardEvents.PROJECT_PAGE_PANELS_OUTLIERS_UPDATED, {
        old_value: panelGlobalConfig.selectedOutliers,
        new_value: value
      });
    },
    [baseTrackEvent, onChange, panelGlobalConfig]
  );

  const onChangeSampleSizeValue = useCallback(
    (selectEvent: unknown) => {
      onChange({
        panels: {
          config: {
            ...panelGlobalConfig,
            sampleSize: selectEvent
          }
        }
      });

      baseTrackEvent(dashboardEvents.PROJECT_PAGE_PANELS_SAMPLE_SIZE_UPDATED, {
        old_value: panelGlobalConfig.sampleSize,
        new_value: selectEvent
      });
    },
    [baseTrackEvent, onChange, panelGlobalConfig]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip content="Additional dashboard settings" placement="top">
        <IconButton
          onClick={handleClick}
          type="secondary"
          Icon={<DSMoreIcon />}
          disabled={disabled}
          active={Boolean(anchorEl)}
          data-test="dashboard-additional"
        />
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: -6,
          horizontal: 'right'
        }}
        classes={{ paper: styles.popoverContainer }}
      >
        <div className={styles.fieldsContainer}>
          <div>
            <div className="select-label">Sample size</div>
            <Select
              onValueChange={onChangeSampleSizeValue}
              options={sampleSizeOptions}
              value={panelGlobalConfig.sampleSize}
              isClearable={false}
            />
          </div>

          <div>
            <div className="select-label">Y-axis transformation</div>
            <Select
              onValueChange={onChangeTransformValue}
              options={transformYOptions}
              value={panelGlobalConfig.transformY ?? transformYOptions[0].value}
              isClearable={false}
            />
          </div>

          <div className={cx(typography.dsBody)}>
            <div
              className={cx(
                typography.dsBody,
                typography.dsAccented,
                styles.label
              )}
            >
              Outliers
            </div>
            <div className={styles.radioContainer}>
              <RadioButton
                checked={
                  panelGlobalConfig.selectedOutliers === OUTLIERS_VALUES.SHOW
                }
                id="show"
                onChange={() => onChangeOutliersValue(OUTLIERS_VALUES.SHOW)}
              />
              <label className={styles.radioLabel} htmlFor="show">
                Show
              </label>
              <RadioButton
                checked={
                  panelGlobalConfig.selectedOutliers ===
                  OUTLIERS_VALUES.NOT_VISIBLE
                }
                id="ignore"
                onChange={() =>
                  onChangeOutliersValue(OUTLIERS_VALUES.NOT_VISIBLE)
                }
              />

              <label className={styles.radioLabel} htmlFor="ignore">
                Ignore
              </label>
            </div>
          </div>
        </div>

        {showLockedPanelsWarning && (
          <div className={cx(styles.warning, typography.dsBody)}>
            Some panels use the panel level settings. You can unlock a panel to
            use the dashboard one.
          </div>
        )}
      </Popover>
    </>
  );
};

export default AdditionalSettingsButton;
