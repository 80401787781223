import React, { useState, useMemo, useEffect } from 'react';
import {
  matchPath,
  useHistory,
  RouteComponentProps,
  useLocation
} from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import { Button } from '@ds';
import { SectionNavBar, SectionTabs } from '@DesignSystem/navigation';
import {
  useFeatureDetails,
  useModelDetails,
  useAlertsNotificationCount,
  usePredicates
} from '@mpm-druid/api';
import { BETA_FEATURE_MODEL_PRODUCTION_MONITORING_PARSER } from '@/lib/betaFeatures';
import { SUB_PATHS } from '@/constants/urlConstants';
import { useMPMPageBreadcrumbs } from '@mpm-druid/hooks';
import SmallLoader from '@shared/components/SmallLoader';
import { CometSQLSyntaxPage } from '../CometSQLSyntax';
import { ModelFeaturePerformancePage } from '../ModelFeaturePerformancePage';
import { PredicateQuery, RouteParams } from '@mpm-druid/types';
import { ModelFeatures } from '../ModelFeatures';
import { CustomTransformationsPage } from '../CustomTransformationsPage';
import { DebuggerPage } from '../DebuggerPage';
import { ModelPerformancePage } from '../ModelPerformancePage';
import { useActiveWorkspace } from '@shared/hooks';
import { AlertNotificationsPage } from '../AlertNotificationsPage';
import { AlertRulesPage } from '../AlertRulesPage';
import { AlertsPage } from '../AlertsPage';
import { AddAlertPage } from '../AddAlertPage';
import './ModelsPage.scss';
import { DSPlusIcon } from '@ds-icons';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';

type ModelsPageProps = {
  match: RouteComponentProps<RouteParams>['match'];
};

const initialPredicate: PredicateQuery[] = [{ key: 'all', query: '' }];

export const ModelsPage = ({ match }: ModelsPageProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [predicates, setPredicates] = useState<PredicateQuery[]>(
    initialPredicate
  );

  const location = useLocation();
  const activeWorkspace = useActiveWorkspace();
  const { workspace, modelId } = match.params;
  const { data: modelData, isLoading, isError } = useModelDetails({
    modelId
  });
  const history = useHistory();
  const { data: alertNotificationData } = useAlertsNotificationCount(
    {
      modelId
    },
    {
      refetchInterval: 60000,
      notifyOnChangeProps: ['data']
    }
  );

  const batchPredicatesId = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramName = 'predicatesBatchId';
    const paramValue = queryParams.get(paramName);
    const localStorageKey = `predicatesBatchId_${modelId}`;
    const storageKey = localStorage.getItem(localStorageKey);
    return paramValue || storageKey;
  }, [modelId, location.search]);

  const { data: restoredPredicates } = usePredicates(
    {
      predicatesBatchId: batchPredicatesId
    },
    { keepPreviousData: true, enabled: !!batchPredicatesId }
  );

  useEffect(() => {
    if (
      restoredPredicates?.predicates &&
      Array.isArray(restoredPredicates?.predicates) &&
      predicates === initialPredicate
    ) {
      const predicates = cloneDeep(initialPredicate);
      for (const query of restoredPredicates.predicates) {
        if (query) {
          predicates.push({ query: query, key: uuidv4() });
        }
      }
      setPredicates(predicates);
    }
  }, [restoredPredicates]);

  const modelPageTabs = useMemo(() => {
    const counter =
      (alertNotificationData &&
        alertNotificationData?.numNotificationsOpen +
          alertNotificationData?.numNotificationsResolved) ||
      0;
    const notifications = counter > 99 ? '99+' : counter;
    const tabs = [
      { id: 'PERFORMANCE', label: 'Model Performance', url: 'performance' },
      { id: 'FEATURES', label: 'Features', url: 'features' },
      {
        id: 'ALERTS',
        label: 'Alerts',
        url: 'alerts',
        notificationCount: notifications
      },
      { id: 'SETTINGS', label: 'Settings', url: 'settings' },
      { id: 'DEBUGGER', label: 'Debugger', url: 'debugger' }
    ];
    return tabs;
  }, [alertNotificationData]);

  const isParserEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_PRODUCTION_MONITORING_PARSER
  );

  const featurePageMatchPatch = matchPath(history.location.pathname, {
    path: `${match.path}/features/:featureName/:featureSource`
  });

  const alertsPageMatchPath = matchPath(history.location.pathname, {
    path: `${match.path}/alerts`
  });
  const { data: featureData } = useFeatureDetails({
    modelId,
    featureName: (featurePageMatchPatch?.params as { featureName: string })
      ?.featureName,
    featureSource: (featurePageMatchPatch?.params as { featureSource: string })
      ?.featureSource,
    includeMetrics: false
  });

  const modelTabsIndexes = modelPageTabs.reduce((map, { id }, index) => {
    map[id] = index;
    return map;
  }, {} as Record<string, number>);

  useMPMPageBreadcrumbs({
    model: modelData?.model?.name,
    feature: featureData?.name
  });

  const handleTabChange = (newActiveTabIndex: number) => {
    history.push(`${match.url}/${modelPageTabs[newActiveTabIndex].url}`);
    setActiveTabIndex(newActiveTabIndex);
  };

  const handleAddAlert = () => {
    history.push(`${match.url}/alerts/add`);
  };

  if (isError && !activeWorkspace?.id) {
    return <h4>This is a private project</h4>;
  }

  if (isLoading || !activeWorkspace || !modelData) {
    return (
      <div style={{ height: 'calc(100vh - var(--navigation-header-height))' }}>
        <SmallLoader isFlexDisplay />
      </div>
    );
  }

  return (
    <>
      <SectionNavBar
        isSticky={!!alertsPageMatchPath}
        style={{ top: 'var(--navigation-header-height)' }}
        documentTitle={`${workspace}/${SUB_PATHS.MODEL_PRODUCTION_MONITORING}/${modelId}`}
      >
        <div className="model-page-header">
          <SectionTabs
            variant="secondary"
            tabs={modelPageTabs}
            tabHeight={39}
            activeTabIndex={activeTabIndex}
            onTabChange={handleTabChange}
            withBottomBorder={false}
          />
          {activeTabIndex === modelTabsIndexes.ALERTS &&
            !history.location.pathname.includes('/alerts/add') && (
              <Button onClick={handleAddAlert} PrefixIcon={<DSPlusIcon />}>
                Add Alert Rule
              </Button>
            )}
        </div>
      </SectionNavBar>

      <Switch>
        <Route
          path={`${match.path}/features`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.FEATURES);
            return <ModelFeatures model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/features/:featureName/:featureSource`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.FEATURES);
            return (
              <ModelFeaturePerformancePage
                feature={featureData}
                model={modelData.model}
                predicates={predicates}
                setPredicates={setPredicates}
              />
            );
          }}
        />
        <Route
          path={`${match.path}/performance`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.PERFORMANCE);
            return (
              <ModelPerformancePage
                model={modelData.model}
                predicates={predicates}
                setPredicates={setPredicates}
              />
            );
          }}
        />
        {isParserEnabled && (
          <Route
            path={`${match.path}/comet-sql`}
            exact
            render={() => {
              return <CometSQLSyntaxPage model={modelData.model} />;
            }}
          />
        )}
        <Route
          path={`${match.path}/settings`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.SETTINGS);
            return <CustomTransformationsPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/debugger`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.DEBUGGER);
            return <DebuggerPage />;
          }}
        />
        <Route
          path={`${match.path}/alerts`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AlertsPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/alerts/notifications`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AlertNotificationsPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/alerts/rules`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AlertRulesPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/alerts/add`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AddAlertPage model={modelData.model} />;
          }}
        />
        <Route path="*">
          <Redirect to={`${match.url}/performance`} />
        </Route>
      </Switch>
    </>
  );
};
