// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntegerSamples-module__integerSamplesList--FQfPS{display:flex;flex-direction:row;gap:2px}.IntegerSamples-module__integerSamplesList--FQfPS .IntegerSamples-module__sampleInteger--zq64X{position:relative;width:60px;height:60px;border:1px solid var(--gray-3);border-radius:5px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;cursor:pointer;font-size:12px;color:var(--gray-6);text-align:center;line-height:60px}.IntegerSamples-module__integerSamplesList--FQfPS .IntegerSamples-module__sampleInteger--zq64X:hover .IntegerSamples-module__iconWrapper--TDth7{display:flex}.IntegerSamples-module__integerSamplesList--FQfPS .IntegerSamples-module__sampleInteger--zq64X:active .IntegerSamples-module__iconWrapper--TDth7{display:flex;color:var(--gray-6)}.IntegerSamples-module__integerSamplesList--FQfPS .IntegerSamples-module__sampleInteger--zq64X .IntegerSamples-module__iconWrapper--TDth7{display:none;position:absolute;top:-25px;left:50px;height:20px;width:20px;border-radius:20px;align-items:center;justify-content:center;color:var(--gray-1)}.IntegerSamples-module__integerSamplesList--FQfPS .IntegerSamples-module__sampleInteger--zq64X .IntegerSamples-module__iconWrapper--TDth7:hover{color:var(--gray-2)}.IntegerSamples-module__integerSamplesList--FQfPS .IntegerSamples-module__sampleInteger--zq64X .IntegerSamples-module__iconWrapper--TDth7 svg{transform:scale(0.6875)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integerSamplesList": `IntegerSamples-module__integerSamplesList--FQfPS`,
	"sampleInteger": `IntegerSamples-module__sampleInteger--zq64X`,
	"iconWrapper": `IntegerSamples-module__iconWrapper--TDth7`
};
module.exports = ___CSS_LOADER_EXPORT___;
