import { useSelector } from 'react-redux';
import { isSingleExperimentColorByDefault } from '@/reducers/experimentDetails/chartsReducer';

const useIsSingleExperimentColor = () => {
  const isSingleExperimentByDefault = useSelector(
    isSingleExperimentColorByDefault
  );

  return isSingleExperimentByDefault;
};

export default useIsSingleExperimentColor;
