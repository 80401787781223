import React from 'react';
import PropTypes from 'prop-types';

import styles from './DisparateValueBox.module.scss';

export function DisparateValueBox({ data }) {
  return (
    <div className={styles.boxWrapper}>
      <div className={styles.boxValue}>{data ? data.toFixed(3) : '---'}</div>
      <div className={styles.boxMessage}>Average disparate impact</div>
    </div>
  );
}

DisparateValueBox.defaultProps = {
  data: ''
};

DisparateValueBox.propTypes = {
  data: PropTypes.number
};
