import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter/SyntaxHighlighter';

import InfoBlock from '@design-system-outdated/components/InfoBlock';
import cx from 'classnames';
import { ROOT_URL } from '@/constants/configConstants';
import classNames from './RegisterModel.module.scss';
import { LinkButton } from '@ds';

const CODE = `from comet_ml import Experiment
exp = Experiment()
exp.log_model("model_name", "../modelfile.h5")`;

const { origin } = new URL(ROOT_URL, window.location.origin);

const RegisterModelFlowNoExperimentModels = () => {
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classNames.registerModelNoModelsContainer}
    >
      <Grid item xs={12}>
        <Box className={classNames.registerModelNoModelsTitle}>
          There are no models logged to this experiment
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classNames.registerModelNoModelsSubTitle}>
          Logging a model allows you to keep track of all model files and
          information and use it in production through the model registry.
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classNames.registerModelNoModelsLabel}>
          Easily log model using:
        </Box>
        <SyntaxHighlighter
          className={classNames.registerModelNoModelsCode}
          code={CODE}
          withCopyBtn
          showLineNumbers={false}
          fontSize={12}
        >
          {CODE}
        </SyntaxHighlighter>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} mb={1}>
          <LinkButton
            openInNewTab
            to={`${origin}/docs/v2/guides/model-management/using-model-registry/`}
            text="Learn more about how to log and register models"
          />
        </Box>
      </Grid>
      <Grid item xs={12} className={classNames.registerModelNoModelsImg}>
        <img
          src="/images/model-registry/register-model-example.png"
          alt="register-model-example"
        />
      </Grid>
      <Grid item className={classNames.registerModelNoModelsProTip}>
        <InfoBlock
          renderIcon={() => (
            <Box className={classNames.registerModelNoModelsProTipIconWrapper}>
              <i
                className={cx(
                  classNames.registerModelNoModelsProTipIcon,
                  'material-icons'
                )}
              >
                lightbulb_outline
              </i>
              <b className={classNames.registerModelNoModelsProTipIconText}>
                Pro Tip:
              </b>{' '}
            </Box>
          )}
          classes={{
            container: classNames.registerModelNoModelsProTipContainer
          }}
          text={
            <span>
              <span>
                {' '}
                Logged models don&apos;t count against your storage limits
              </span>
            </span>
          }
        />
      </Grid>
    </Grid>
  );
};

RegisterModelFlowNoExperimentModels.defaultProps = {};

RegisterModelFlowNoExperimentModels.propTypes = {};

export default RegisterModelFlowNoExperimentModels;
