export const LLM_EXAMPLE = `# coding: utf-8

import comet_llm
import time

# Login to Comet if needed
comet_llm.init()

def log_prompt():
    comet_llm.log_prompt(
        project = "llm-general",
        prompt = "<input prompt>",
        output = "<response>",
        tags = ["prompt", "gpt-3.5"]
    )

def log_chain():
    # Create a chain
    user_question = "Is Nando's open ?"
    comet_llm.start_chain(
        project="llm-general",
        inputs={"final_prompt": user_question},
        tags=["prompt-chains", "gpt-4"]
    )

    # Fetch context to check opening hours
    with comet_llm.Span(
        category="context-retrieval",
        inputs={"action": "fetch-opening-hours"},
    ) as span:
        time.sleep(0.2)
        result = "7 days a week: 11am to 11pm"
        span.set_outputs(outputs={"opening-hours": result})

    # Format response to user query
    with comet_llm.Span(
        category="llm-call",
        inputs={
            "user_question": user_question,
            "context": result,
        },
    ) as span:
        time.sleep(0.65)
        response = "Yes, Nando's is currently open"
        span.set_outputs(outputs={"result": response})

    # Finish chain
    comet_llm.end_chain(outputs={"output": response})

if __name__ == "__main__":
    log_prompt()
    log_chain()`;
