import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import MultiLevelDropdownList from '@DesignSystem/controllers/MultiLevelDropdownList/MultiLevelDropdownList';
import { refPropType } from '@shared/constants/propTypes';
import noop from 'lodash/noop';

import styles from './ChartHeaderMenu.module.scss';
import useChartHeaderMenu from './useChartHeaderMenu';
import { trimSuffix } from '@/helpers/generalHelpers';

const ChartHeaderMenu = ({
  anchorEl,
  instanceId,
  panelMoveConfig,
  projectId,
  templateId,
  transformYConfig,
  chartId,
  title,
  chartType,
  sampleSize,
  sampleSizes,
  sectionId,
  isCustomPanel,
  legendMode,
  experimentKeys,
  hiddenMenuItems,
  disableResetZoom,

  onChangeLegendMode,
  onChangeSampleSize,
  onClose,
  onClearChartConstraints,
  onDeleteChart,
  onEditChart,
  onExportJSON,
  onResetZoom,
  onExportData
}) => {
  const [openedSubmenu, setOpenedSubmenu] = useState('');

  const chartTitle =
    chartType === 'histogram3d' ? trimSuffix(title, '.json') : title;

  const handleOnExportJson = useCallback(() => {
    onExportJSON(`${chartTitle}_chart_data`);
  }, [chartTitle, onExportJSON]);

  const [menuItems, onMenuClick] = useChartHeaderMenu({
    chartId,
    chartType,
    experimentKeys,
    instanceId,
    isCustomPanel,
    legendMode,
    panelMoveConfig,
    projectId,
    templateId,
    sampleSize,
    sampleSizes,
    sectionId,
    hiddenMenuItems,
    disableResetZoom,

    onClearChartConstraints,
    onClose,
    onDeleteChart,
    onEditChart,
    onExportData,
    onResetZoom,
    handleOnExportJson,
    onChangeLegendMode,
    onChangeSampleSize,
    setOpenedSubmenu,
    transformYConfig
  });

  return (
    <MultiLevelDropdownList
      anchorEl={anchorEl}
      onClose={onClose}
      items={menuItems}
      width="178px"
      withInput={false}
      verticalPosition="top"
      horizontalPosition="left"
      transformOriginHorizontal="right"
      dropdownWrapperClassName={styles.dropdownWrapper}
      onClick={onMenuClick}
      value={openedSubmenu}
      globalMenuStyles
    />
  );
};

ChartHeaderMenu.defaultProps = {
  sampleSize: null,
  onChangeSampleSize: noop
};

ChartHeaderMenu.propTypes = {
  anchorEl: refPropType.isRequired,
  sampleSize: PropTypes.number,
  sectionId: PropTypes.string,
  instanceId: PropTypes.string.isRequired,
  panelMoveConfig: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  chartId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  transformYConfig: PropTypes.object,
  chartType: PropTypes.string.isRequired,
  sampleSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  isCustomPanel: PropTypes.bool.isRequired,
  legendMode: PropTypes.string,
  experimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  hiddenMenuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  disableResetZoom: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClearChartConstraints: PropTypes.func.isRequired,
  onDeleteChart: PropTypes.func.isRequired,
  onEditChart: PropTypes.func.isRequired,
  onExportJSON: PropTypes.func.isRequired,
  onResetZoom: PropTypes.func.isRequired,
  onExportData: PropTypes.func.isRequired,
  onChangeLegendMode: PropTypes.func,
  onChangeSampleSize: PropTypes.func
};

export default ChartHeaderMenu;
