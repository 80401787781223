import React from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { URLS } from '@/constants/urlConstants';

const PricingPage = () => {
  const {
    openlogin,
    opensignup,
    payNow,
    returnPlan,
    signuptitle
  } = queryString.parse(window.location.search);

  if (openlogin) {
    return <Redirect to="/login" />;
  }

  if (opensignup) {
    const signupUrl = queryString.stringifyUrl({
      url: '/signup',
      query: {
        plan: returnPlan === 'academic_plan' ? 'academic' : returnPlan,
        payNow,
        title: signuptitle
      }
    });

    return <Redirect to={signupUrl} />;
  }

  window.location.href = URLS.PRICING;
  return null;
};

export default PricingPage;
