// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkspacePage-module__header--AvHGv{background-color:var(--white)}.WorkspacePage-module__header--AvHGv.WorkspacePage-module__bottomBorder--BksI4{border-bottom:1px solid var(--gray-2)}.WorkspacePage-module__body--z40s0{}.WorkspacePage-module__container--Py9nY{flex:1;background-color:var(--background-color)}.WorkspacePage-module__container--Py9nY.WorkspacePage-module__whiteBG--f4Sfo{background-color:var(--white)}.WorkspacePage-module__headerContainer--Hmy86{min-width:980px;margin:0 auto}.WorkspacePage-module__tabBarContainer--PDpXC{display:flex;align-items:center;justify-content:space-between}.WorkspacePage-module__tabBar--bRmm7{}.WorkspacePage-module__tabBar--bRmm7 .MuiTabs-indicator{background-color:var(--primary-color)}.WorkspacePage-module__tabBar--bRmm7 [role*=tab]{color:var(--black);min-width:fit-content}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `WorkspacePage-module__header--AvHGv`,
	"bottomBorder": `WorkspacePage-module__bottomBorder--BksI4`,
	"body": `WorkspacePage-module__body--z40s0 container`,
	"container": `WorkspacePage-module__container--Py9nY`,
	"whiteBG": `WorkspacePage-module__whiteBG--f4Sfo`,
	"headerContainer": `WorkspacePage-module__headerContainer--Hmy86 container`,
	"tabBarContainer": `WorkspacePage-module__tabBarContainer--PDpXC`,
	"tabBar": `WorkspacePage-module__tabBar--bRmm7 tab-bar`
};
module.exports = ___CSS_LOADER_EXPORT___;
