import { FeatureColumnType } from '../types';

export const uniqueRowIdKey = 'id';

export const DEFAULT_ALERT_NOTIFICATIONS_SORTING = [
  { columnName: 'notificationDate', direction: 'asc' }
];

export const DEFAULT_ALERT_RULES_SORTING = [
  { columnName: 'createdDate', direction: 'asc' }
];

export const ALERT_NOTIFICATIONS_STATUSES = ['open', 'resolved'];

export const ALERT_NOTIFICATIONS_COLUMNS: FeatureColumnType[] = [
  {
    name: 'name',
    title: 'NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'ruleName',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'status',
    title: 'STATUS',
    id: 'status--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'status',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'notificationDate',
    title: 'NOTIFICATION DATE',
    id: 'notificationDate--metadata',
    type: 'datetime',
    source: 'metadata',
    serverField: 'createdAt',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'trigger',
    title: 'TRIGGER',
    id: 'trigger--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'ruleId',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'modelVersion',
    title: 'VERSION',
    id: 'modelVersion--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'modelVersion',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'intervalType',
    title: 'FREQUENCY',
    id: 'intervalType-metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'intervalType',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const ALERT_RULES_COLUMNS: FeatureColumnType[] = [
  {
    name: 'name',
    title: 'NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'name',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'openNotifications',
    title: 'OPEN NOTIFICATIONS',
    id: 'openNotifications--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'createdAt',
    title: 'CREATED DATE',
    id: 'createdAt--metadata',
    type: 'datetime',
    source: 'metadata',
    serverField: 'createdAt',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'modelVersion',
    title: 'VERSION',
    id: 'modelVersion--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'modelVersion',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'intervalType',
    title: 'FREQUENCY',
    id: 'intervalType--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'intervalType',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'createdBy',
    title: 'CREATED BY',
    id: 'createdBy--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'createdBy',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'description',
    title: 'DESCRIPTION',
    id: 'description--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'description',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const ALERT_NOTIFICATIONS_LEFT_COLUMN = ['name'];

export const ALERT_NOTIFICATIONS_RIGHT_COLUMN = ['actions'];

export const ALERT_RULES_LEFT_COLUMN = ['name'];

export const ALERT_NOTIFICATIONS_PAGE_SIZES = [10, 25, 50, 100];

export const ALERT_NOTIFICATIONS_PREVIEW_PAGE_SIZES = 5;

export const ALERT_RULES_PAGE_SIZES = [10, 25, 50, 100];

export const ALERT_RULES_PREVIEW_PAGE_SIZES = 5;

export const ALERT_NOTIFICATIONS_NAME_COLUMN_WIDTH = 298;

export const ALERT_RULES_NAME_COLUMN_WIDTH = 298;

export const disabledTableSortingColumns = (columns: FeatureColumnType[]) =>
  columns
    .filter(column => column.hideForSorting)
    .map(column => ({
      columnName: column.name,
      sortingEnabled: false
    }));

export const disabledNotificationSortingColumns = disabledTableSortingColumns(
  ALERT_NOTIFICATIONS_COLUMNS
);

export const disabledRulesSortingColumns = disabledTableSortingColumns(
  ALERT_RULES_COLUMNS as FeatureColumnType[]
);

export const RULES_INFO_TOOLTIP_MESSAGE =
  'An Alert Rule describes the circumstances under which you want to be alerted and how you want to be notified.';

export const NOTIFICATIONS_INFO_TOOLTIP_MESSAGE =
  'This is a list of open and resolved notifications created from your Alert Triggers';

export const METRIC_CATEGORY_OPTIONS = [
  { label: 'Model Performance', value: 'model_performance' },
  { label: 'Feature metrics', value: 'feature_metrics' },
  { label: 'System metrics', value: 'system_metrics' }
];

export const METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL = {
  model_performance: 'Model Performance metric',
  feature_metrics: 'Feature metrics metric',
  system_metrics: 'System metrics metric'
};

export const DRIFT_ALGORITHMS = {
  EMD: 'EMD',
  PSI: 'PSI',
  KL: 'KL'
} as const;

export const CATEGORICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS = {
  name: 'outputDistribution',
  options: [
    { groupName: 'Drift', label: 'Drift' },
    {
      label: 'Earth Movers Distance',
      value: 'feature_drift_EMD',
      driftAlgorithm: DRIFT_ALGORITHMS.EMD
    },
    {
      label: 'Population Stability Index',
      value: 'feature_drift_PSI',
      driftAlgorithm: DRIFT_ALGORITHMS.PSI
    },
    {
      label: 'Kullback-Leibler divergence',
      value: 'feature_drift_KL',
      driftAlgorithm: DRIFT_ALGORITHMS.KL
    },
    {
      groupName: 'Missing values',
      label: 'Missing values'
    },
    {
      label: 'Number of missing values',
      value: 'feature_missing_values',
      driftAlgorithm: undefined
    }
  ],
  label: 'Output distribution metric'
};

export const NUMERICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS = {
  name: 'outputDistribution',
  options: [
    { groupName: 'Drift', label: 'Drift' },
    {
      label: 'Earth Movers Distance',
      value: 'feature_drift_EMD',
      driftAlgorithm: DRIFT_ALGORITHMS.EMD
    },
    {
      label: 'Population Stability Index',
      value: 'feature_drift_PSI',
      driftAlgorithm: DRIFT_ALGORITHMS.PSI
    },
    {
      label: 'Kullback-Leibler divergence',
      value: 'feature_drift_KL',
      driftAlgorithm: DRIFT_ALGORITHMS.KL
    },
    {
      groupName: 'Missing values',
      label: 'Missing values'
    },
    {
      label: 'Number of missing values',
      value: 'feature_missing_values',
      driftAlgorithm: undefined
    },
    { groupName: 'Percentiles', label: 'Percentiles' },
    { label: 'Minimum', value: 'percentile_min', driftAlgorithm: undefined },
    {
      label: '10th Percentile',
      value: 'percentile_10',
      driftAlgorithm: undefined
    },
    {
      label: '25th Percentile',
      value: 'percentile_25',
      driftAlgorithm: undefined
    },
    { label: 'Median', value: 'percentile_50', driftAlgorithm: undefined },
    {
      label: '75th Percentile',
      value: 'percentile_75',
      driftAlgorithm: undefined
    },
    {
      label: '90th Percentile',
      value: 'percentile_90',
      driftAlgorithm: undefined
    },
    { label: 'Maximum', value: 'percentile_max', driftAlgorithm: undefined }
  ],
  label: 'Output distribution metric'
};

export const FEATURE_TYPES = {
  CATEGORICAL: 'CATEGORICAL',
  NUMERICAL: 'NUMERICAL'
} as const;

export const RESPONSE_PERCENTILES = {
  percentile_min: 'min',
  percentile_10: 'p10',
  percentile_25: 'p25',
  percentile_50: 'p50',
  percentile_75: 'p75',
  percentile_90: 'p90',
  percentile_max: 'max'
};

export const METRIC_TYPE_WITH_DRIFT_METHODS = {
  model_performance: [
    { groupName: 'Drift', label: 'Input Data Drift' },
    {
      label: 'Earth Movers Distance',
      value: 'input_drift_EMD',
      driftAlgorithm: DRIFT_ALGORITHMS.EMD
    },
    {
      label: 'Population Stability Index',
      value: 'input_drift_PSI',
      driftAlgorithm: DRIFT_ALGORITHMS.PSI
    },
    {
      label: 'Kullback-Leibler divergence',
      value: 'input_drift_KL',
      driftAlgorithm: DRIFT_ALGORITHMS.KL
    }
  ],
  system_metrics: [
    {
      label: 'Number Of Predictions',
      value: 'number_of_predictions'
    },
    {
      label: 'Number Of Ingestion Errors',
      value: 'number_of_errors'
    }
  ]
};

export const NAME_TO_METRIC_TYPE_MAP = {
  input_drift_EMD: 'input_drift',
  input_drift_PSI: 'input_drift',
  input_drift_KL: 'input_drift',
  feature_drift_EMD: 'feature_drift',
  feature_drift_PSI: 'feature_drift',
  feature_drift_KL: 'feature_drift'
};

export const ALERT_OPERATOR_OPTIONS = [
  { label: 'Greater Than', value: 'greater_than' },
  { label: 'Greater or equal than', value: 'greater_equal' },
  { label: 'Less than', value: 'less_than' },
  { label: 'Less or equal than', value: 'less_equal' }
] as const;

export const DEFAULT_VERSIONS = [
  {
    label: 'All versions',
    value: 'all'
  }
];

export const SEGMENT_OPTIONS = [{ label: 'All', value: 'all' }];

export const TIME_PERIOD_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Hourly', value: 'hourly' }
];

export const ALERT_TYPE_OPTIONS = [
  { label: 'Threshold', value: 'threshold' }
  // { label: 'Change threshold', value: 'threshold_change' }
];

export const NOTIFICATION_TYPES = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
  TEAMS: 'TEAMS'
} as const;

export const EMPTY_DEFAULT_CHART_OVERVIEW_MESSAGE =
  'In order to see the ‘Alert Preview’ graph, please choose the metric you want to track & the specific parameters.';

export const EMPTY_HOURLY_CHART_OVERVIEW_MESSAGE =
  'No metric data has been recorded for the last 7 days.';

export const EMPTY_DAILY_CHART_OVERVIEW_MESSAGE =
  'No metric data has been recorded for the last 30 days.';
