import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PaymentPlanCard, StyledTooltip } from '@DesignSystem/data-display';
import { SimpleTable } from '@DesignSystem/tables';
import { RadioButton } from '@DesignSystem/controllers';
import {
  PAYMENT_PLANS,
  ORGANIZATION_PLAN_STARTER,
  PAID_PLANS,
  ORGANIZATION_PLAN_COMMUNITY,
  ORGANIZATION_PLAN_ENTERPRISE,
  FREE_PLANS
} from '@shared/constants';
import { COMPARE_PLANS_COLUMNS, COMPARE_PLANS_ROWS } from '../helpers';
import { useCurrentPaymentPlan } from '@shared/hooks';
import { useActiveOrganizationPaymentDetails } from '@account-settings/api';

const defaultDisplayedPaymentPlans = [
  ORGANIZATION_PLAN_COMMUNITY,
  ORGANIZATION_PLAN_STARTER,
  ORGANIZATION_PLAN_ENTERPRISE
];
const MOST_POPULAR = [ORGANIZATION_PLAN_STARTER];

const SelectPaymentPlan = ({
  selectedPlan,
  onChangeSelectedPlan,
  onClickTrialPlan
}) => {
  const { data: billingDetails } = useActiveOrganizationPaymentDetails();
  const {
    paymentPlanName: activePaymentPlan,
    isAcademic,
    isStartupFree
  } = useCurrentPaymentPlan();

  const displayedPaymentPlans = useMemo(() => {
    let resultList = [...defaultDisplayedPaymentPlans];

    if (isAcademic) {
      return [activePaymentPlan];
    }

    if (!defaultDisplayedPaymentPlans.includes(activePaymentPlan)) {
      resultList = [
        defaultDisplayedPaymentPlans[0],
        activePaymentPlan,
        defaultDisplayedPaymentPlans[1],
        defaultDisplayedPaymentPlans[2]
      ];
    }

    return resultList;
  }, [activePaymentPlan, isAcademic]);

  const renderCards = () => (
    <div className="payment-plans">
      {displayedPaymentPlans.map(displayedPaymentPlan => {
        const paymentPlan = PAYMENT_PLANS[displayedPaymentPlan];
        const IS_COMMUNITY_DISABLED =
          displayedPaymentPlan === ORGANIZATION_PLAN_COMMUNITY &&
          PAID_PLANS.includes(activePaymentPlan) &&
          billingDetails?.allMembersCount > 1;

        const IS_ALLOWED_PAYMENT_PLAN = defaultDisplayedPaymentPlans.includes(
          displayedPaymentPlan
        );

        return (
          <div key={paymentPlan?.plan} className="payment-plan-container">
            <StyledTooltip
              title={
                IS_COMMUNITY_DISABLED
                  ? 'Only organizations with one member \n can be downgraded to the free plan'
                  : ''
              }
              placement="top"
            >
              <div className="payment-plan-radio-container">
                <RadioButton
                  checked={selectedPlan === displayedPaymentPlan}
                  onClick={() => onChangeSelectedPlan(displayedPaymentPlan)}
                  disabled={IS_COMMUNITY_DISABLED || !IS_ALLOWED_PAYMENT_PLAN}
                />
              </div>
            </StyledTooltip>

            <PaymentPlanCard
              active={selectedPlan === displayedPaymentPlan}
              color={paymentPlan?.color}
              description={paymentPlan?.description}
              bullets={paymentPlan?.bullets}
              isAcademic={
                isAcademic &&
                displayedPaymentPlan !== ORGANIZATION_PLAN_COMMUNITY
              }
              label={paymentPlan?.label}
              priceDescription={paymentPlan?.priceDescription}
              priceLabel={paymentPlan?.priceLabel}
              title={paymentPlan?.displayName}
              showTrialButton={
                displayedPaymentPlan === ORGANIZATION_PLAN_STARTER &&
                FREE_PLANS.includes(activePaymentPlan) &&
                !isAcademic &&
                !isStartupFree
              }
              onTrialClick={() => onClickTrialPlan(displayedPaymentPlan)}
              badgeLabel={
                MOST_POPULAR.includes(displayedPaymentPlan)
                  ? 'Most popular'
                  : null
              }
            />
          </div>
        );
      })}
    </div>
  );

  const renderComparePlans = () => {
    return (
      <div className="payment-plans-grid">
        <div className="full-width-modal-title">
          <span>Compare Plans</span>
        </div>
        <SimpleTable
          columns={COMPARE_PLANS_COLUMNS}
          rows={COMPARE_PLANS_ROWS}
        />
      </div>
    );
  };
  return (
    <>
      {renderCards()}
      {renderComparePlans()}
    </>
  );
};

SelectPaymentPlan.propTypes = {
  selectedPlan: PropTypes.string.isRequired,
  onChangeSelectedPlan: PropTypes.func.isRequired,
  onClickTrialPlan: PropTypes.func.isRequired
};

export default SelectPaymentPlan;
