import { CHART_CONFIG_MODIFIERS } from '@experiment-management-shared/constants/chartConstants';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { useCallback } from 'react';
import { CHART_COLORS } from '@/constants/colorConstants';

import {
  BarChartType,
  BarOrientation,
  DataSource,
  DataSourceParams,
  Grouping,
  LegendsKeyMap,
  MetricColorMap,
  OrientationAxis,
  PanelCometMetadataType,
  PanelMetric,
  PanelTrace,
  PanelYValue
} from '@experiment-management-shared/types';
import {
  getExperimentKeyFromDataSource,
  getMetricNameFromDataSource,
  getTraceNameFromDataSource,
  isVisibleOnChart
} from '@experiment-management-shared/components/Charts/ChartHelpers';
import { darkenColor } from '@shared';
import { getListAggregatedValue } from '@experiment-management-shared/utils';

const getXValue = (groupByField: string): 'experimentKey' | 'metricName' => {
  return groupByField === 'metricName' ? 'experimentKey' : 'metricName';
};

export const isVertical = (orientation: BarOrientation): boolean =>
  orientation === 'v';

const COLOR_DARKEN_RANGE = 0.4;

interface GetSeriesArgs {
  dataSources: DataSource[];
  getBarColor: (ds: DataSource, index: number) => string;
  metricColorMap?: MetricColorMap | null;
  legendKeysMap: LegendsKeyMap;
  metrics?: PanelMetric[];
  orientation: BarOrientation;
  plotType: BarChartType;
  orientationAxis?: OrientationAxis;
  isAggregationActive?: boolean;
  grouping?: Grouping;
  cometMetadataType?: PanelCometMetadataType;
}

//  for the same group data series all the param values are the same since it's the same experiment
const getGroupParamValueMap = (groupedDs: PanelTrace[]): DataSourceParams =>
  groupedDs?.[0]?.params || {};
export const getSeriesWithAggregations = ({
  cometMetadataType,
  dataSources,
  getBarColor,
  metricColorMap = null,
  legendKeysMap,
  metrics,
  orientation,
  plotType,
  grouping
}: GetSeriesArgs): PanelTrace[] => {
  const metricNames = metrics?.map(metric => metric.name) || [];

  const experimentsData = dataSources.map((dataSource, index) => {
    const name = getTraceNameFromDataSource(dataSource);
    const experimentKey = getExperimentKeyFromDataSource(dataSource);
    // group by parameter
    const params = dataSource.params || {};

    return {
      color: getBarColor(dataSource, index),
      dataSource,
      dataSourceMetrics: dataSource.metrics,
      experimentKey,
      name,
      visible: isVisibleOnChart(dataSource),
      params
    };
  });

  const data = experimentsData.reduce<PanelTrace[]>((acc, experimentData) => {
    const { dataSourceMetrics } = experimentData;

    const metricsData =
      metrics
        ?.map((metric, index) => {
          const dataSourceMetric = dataSourceMetrics.find(
            m => m.metricName === metric.name
          );
          const metricColor =
            metricColorMap && metricColorMap[metric.name]?.primary;
          const value = getListAggregatedValue(
            dataSourceMetric?.values || [],
            metric.aggregation
          );

          return {
            ...experimentData,
            color:
              metricColorMap && metricColor
                ? metricColor
                : darkenColor(
                    experimentData.color,
                    index * (COLOR_DARKEN_RANGE / metricNames.length)
                  ),

            metricName: metric.name,
            value
          };
        })
        .filter(
          metricData =>
            metricData.value !== undefined && metricData.visible === true
        ) || [];

    acc.push(...metricsData);

    return acc;
  }, []);

  const isGroupedByParameterStackedBarChart =
    grouping?.groupByParameter &&
    grouping?.enabled &&
    plotType === CHART_CONFIG_MODIFIERS.STACKED_BAR.value;

  const isGroupedByExperimentKey =
    plotType === CHART_CONFIG_MODIFIERS.BAR.value ||
    isGroupedByParameterStackedBarChart;

  const groupByField = isGroupedByExperimentKey
    ? 'experimentKey'
    : 'metricName';

  // name could be dynamic in the future
  const groupsByName = groupBy(data, groupByField);
  const isBarVertical = isVertical(orientation);

  const traces: PanelTrace[] = Object.entries(groupsByName).map(
    ([key, groupData]) => {
      const xField = getXValue(groupByField);
      const color = groupData.map(metric => metric.color);
      const groupNames: (string | undefined)[] = groupData.map(
        metric => metric[xField]
      );

      const groupParamValues = getGroupParamValueMap(groupData);

      const groupMetricValues: PanelYValue[] = groupData?.map(
        metric => metric.value || ''
      );
      const visible = groupData.map(metric => metric.visible);

      const cometMetadataArray = groupData.map((metric, i) => {
        const dataKeys = [
          {
            title: metric.metricName,
            value: groupMetricValues[i],
            axis: isBarVertical ? 'yaxis' : 'xaxis'
          }
        ];

        return {
          ...legendKeysMap[metric?.experimentKey || ''],
          dataKeys,
          metricName: metric.metricName
        };
      });

      return {
        ...get(CHART_CONFIG_MODIFIERS, [plotType, 'config']),
        params: groupParamValues,
        cometMetadataType,
        cometMetadataArray,
        marker: { color },
        name: key,
        orientation,
        visible,
        x: isBarVertical ? groupNames : groupMetricValues,
        y: isBarVertical ? groupMetricValues : groupNames
      };
    }
  );

  return traces;
};

export const getSeriesWithoutAggregations = ({
  cometMetadataType,
  dataSources,
  legendKeysMap,
  getBarColor,
  orientation,
  orientationAxis,
  plotType
}: GetSeriesArgs): PanelTrace[] => {
  const result: PanelTrace[] = [];

  dataSources.forEach((dataSource, index) => {
    const { metrics } = dataSource;

    metrics.forEach(metric => {
      const { metricName } = metric;
      const name = `${dataSource.experiment_key} ${metricName}`;
      const params = dataSource.params || {};
      const values = { [orientationAxis || 'x']: metric.values };
      const barColor = getBarColor(dataSource, index);

      const trace = {
        cometMetadataType,
        cometMetadata: {
          ...legendKeysMap[dataSource.experiment_key],
          metricName
        },
        // custom config for modifiers (example: box plot chart)
        ...get(CHART_CONFIG_MODIFIERS, [plotType, 'config']),
        // x or y data values
        ...values,
        fillcolor: barColor,
        line: { color: barColor },
        metricName,
        name,
        showlegend: false,
        orientation,
        params,
        visible: isVisibleOnChart(dataSource)
      };

      result.push(trace);
    });
  });

  return result;
};

export const sortByMetricName = (traces: PanelTrace[]) => {
  const metricNames = traces.map(trace => trace.metricName);

  return sortBy(traces, trace => metricNames.indexOf(trace.metricName));
};

export const getSortedSeries = ({
  dataSources,
  getBarColor,
  isAggregationActive,
  metricColorMap,
  legendKeysMap,
  metrics,
  orientation,
  orientationAxis,
  plotType,
  grouping
}: GetSeriesArgs) => {
  let data;
  const cometMetadataType: PanelCometMetadataType = metricColorMap
    ? 'experiment_metric'
    : 'metric';

  if (isAggregationActive) {
    data = getSeriesWithAggregations({
      cometMetadataType,
      dataSources,
      getBarColor,
      metricColorMap,
      legendKeysMap,
      metrics,
      orientation,
      plotType,
      grouping
    });
  } else {
    data = getSeriesWithoutAggregations({
      cometMetadataType,
      dataSources,
      legendKeysMap,
      getBarColor,
      orientation,
      orientationAxis,
      plotType
    });
  }

  return sortByMetricName(data);
};

export const useGetBarColor = ({
  experimentColorMap = {},
  metricColorMap
}: {
  experimentColorMap?: unknown;
  metricColorMap?: MetricColorMap;
}) => {
  return useCallback(
    (dataSource, index) => {
      const defaultPrimaryColor = CHART_COLORS[index % CHART_COLORS.length];

      if (metricColorMap) {
        const metricName = getMetricNameFromDataSource(dataSource);

        return get(
          metricColorMap,
          [metricName, 'primary'],
          defaultPrimaryColor
        );
      }

      const experimentKey = getExperimentKeyFromDataSource(dataSource);

      return get(
        experimentColorMap,
        [experimentKey, 'primary'],
        defaultPrimaryColor
      );
    },
    [experimentColorMap, metricColorMap]
  );
};
