import { useCallback, useState } from 'react';

import last from 'lodash/last';

const DEFAULT_STACK_SIZE = 10;

function useStackState<T>({
  initialValue,
  size = DEFAULT_STACK_SIZE
}: {
  initialValue: T;
  size?: number;
}) {
  const [stack, setStack] = useState([initialValue]);

  const clear = useCallback(() => {
    setStack([]);
  }, []);

  const pop = useCallback(() => {
    setStack(prevStack => prevStack.slice(0, prevStack.length - 1));
  }, []);

  const push = useCallback(
    value => {
      setStack(prevStack => [...prevStack.slice(-size + 1), value]);
    },
    [size]
  );

  const set = useCallback(value => {
    setStack([value]);
  }, []);

  return {
    clear,
    pop,
    push,
    set,
    stack,
    value: last(stack)
  };
}

export default useStackState;
