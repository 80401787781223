import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { IconButton } from '@ds';
import React from 'react';
import { DSMoreOptionsVerticalIcon } from '@ds-icons';

const RowActionIcon = props => {
  const { row, onClickRowActionMenu } = props;

  return (
    <Box className="row-action-menu">
      <Box className="row-action-menu-wrapper">
        <IconButton
          size="XL"
          onClick={ev => onClickRowActionMenu(row, ev)}
          Icon={<DSMoreOptionsVerticalIcon />}
        />
      </Box>
    </Box>
  );
};

RowActionIcon.propTypes = {
  onClickRowActionMenu: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
};

export default RowActionIcon;
