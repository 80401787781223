import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';
import Collapse from '@material-ui/core/Collapse';

import { SectionTabs } from '@DesignSystem/navigation';
import { KeyboardArrowDownIcon, InfoIcon } from '@Icons-outdated';
import { PANEL_NAMES, PANEL_SECTIONS } from '@mpm/constants';

import './Panel.scss';

export const Panel = ({
  title,
  renderChart,
  tabs,
  onChangeTab,
  isLabeled,
  isSegmentsData
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isPanelExpanded, setPanelExpanded] = useState(true);

  const showAccuracyLabel =
    !isLabeled && title === PANEL_NAMES.ACCURACY_METRICS;

  const showNonSupportSegmentsLabel =
    (isSegmentsData &&
      tabs[activeTabIndex]?.value ===
        PANEL_SECTIONS.NUMBER_OF_INGESTION_ERRORS) ||
    (isLabeled && isSegmentsData && title === PANEL_NAMES.ACCURACY_METRICS);

  const defaultCollapsed =
    (isSegmentsData && title === PANEL_NAMES.ACCURACY_METRICS) ||
    (!isLabeled && title === PANEL_NAMES.ACCURACY_METRICS);

  useEffect(() => {
    const collapsed = localStorage.getItem(title);
    if (collapsed) {
      setPanelExpanded(collapsed === 'true');
    } else if (defaultCollapsed) {
      setPanelExpanded(false);
    } else setPanelExpanded(true);
  }, [title, isLabeled, isSegmentsData, showAccuracyLabel]);

  const handleChangeTab = tabIndex => {
    setActiveTabIndex(tabIndex);
    onChangeTab((tabs && tabs[tabIndex]) || 0, tabIndex);
  };

  return (
    <div className="ds-panel">
      <div
        className={cx('ds-panel-header', {
          'ds-panel-header-expanded': isPanelExpanded
        })}
      >
        <KeyboardArrowDownIcon
          onClick={() => {
            setPanelExpanded(!isPanelExpanded);
            localStorage.setItem(title, !isPanelExpanded);
          }}
          className={cx('expander-arrow', {
            'expanded-arrow': isPanelExpanded
          })}
        />
        <p
          className="ds-panel-header-title"
          onClick={() => setPanelExpanded(prev => !prev)}
        >
          {title}
        </p>
        {showAccuracyLabel && (
          <span className="header-message">
            No labels have been recorded for this model.&nbsp;
            <a
              href="/docs/v2/guides/model-production-monitoring/mpm-integrations/#sending-labels-to-mpm"
              target="_blank"
              rel="noreferrer"
            >
              <span className="link"> Learn more</span>
            </a>
          </span>
        )}
        {showNonSupportSegmentsLabel && (
          <span className="header-message">
            <InfoIcon />
            Not yet supported when using Segments
          </span>
        )}
      </div>
      <Collapse in={isPanelExpanded}>
        <div className="ds-panel-content">
          {!!tabs?.length && (
            <SectionTabs
              onTabChange={handleChangeTab}
              activeTabIndex={activeTabIndex}
              tabs={tabs}
              tabHeight={30}
            />
          )}
          {renderChart((tabs && tabs[activeTabIndex]) || 0, activeTabIndex)}
        </div>
      </Collapse>
    </div>
  );
};

Panel.defaultProps = {
  title: '',
  renderChart: noop,
  tabs: null,
  onChangeTab: noop,
  isLabeled: undefined
};

Panel.propTypes = {
  title: PropTypes.string,
  renderChart: PropTypes.func,
  tabs: PropTypes.array,
  onChangeTab: PropTypes.func,
  isLabeled: PropTypes.bool,
  isSegmentsData: PropTypes.bool.isRequired
};
