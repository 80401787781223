import { useQuery } from 'react-query';

import { HISTORY_GROUP_BY_OPTIONS } from '@model-registry/constants';
import { reverseGroupedHistory } from '@model-registry/utils';
import api from '../api';

const getModelRegistryHistory = payload =>
  api.post(`/registry/model/history`, payload).then(reverseGroupedHistory);

export const useModelRegistryGroupedHistory = (
  { modelName, workspaceName },
  config
) => {
  return useQuery(
    ['modelRegistryHistory', { modelName, workspaceName }],
    () =>
      getModelRegistryHistory({
        modelName,
        workspaceName,
        groupedBy: HISTORY_GROUP_BY_OPTIONS.day
      }),
    {
      ...config,
      refetchOnMount: true
    }
  );
};
