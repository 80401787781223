import React from 'react';
import { SearchableList } from '@ds';
import useWorkspacesSearch from './hooks/useWorkspacesSearch';

import WorkspaceSelectItem from './WorkspaceSelectItem';
import { useActiveWorkspaceName } from '@shared/hooks';
import { noop } from 'lodash';
import { Workspace } from '@shared/types';

type UserMenuWorkspacesSelectorProps = {
  onChange?: (workspaceName: string) => void;
  onViewAll?: () => void;
  dataTestPrefix?: string;
};
const UserMenuWorkspacesSelector: React.FC<UserMenuWorkspacesSelectorProps> = ({
  onChange = noop,
  onViewAll = noop,
  dataTestPrefix = ''
}) => {
  const currentWorkspaceName = useActiveWorkspaceName();
  const { workspaceList, onSelectWorkspace } = useWorkspacesSearch();

  const handleSelectWorkspace = (workspaceName: string) => {
    onSelectWorkspace(workspaceName);
    onChange(workspaceName);
  };

  return (
    <SearchableList itemList={workspaceList} searchField="name">
      <SearchableList.Result maxHeight={320}>
        {(workspace: Workspace) => (
          <WorkspaceSelectItem
            key={workspace.id}
            workspace={workspace}
            currentWorkspaceName={currentWorkspaceName}
            onSelect={handleSelectWorkspace}
            dataTestPrefix={dataTestPrefix}
          />
        )}
      </SearchableList.Result>
      <SearchableList.NotFound />
      <SearchableList.Footer>
        <SearchableList.FooterActionLink
          text="View all workspaces"
          to="/account-settings/workspaces"
          onClick={onViewAll}
        />
      </SearchableList.Footer>
    </SearchableList>
  );
};

export default UserMenuWorkspacesSelector;
