import axios from 'axios';
import { BASE_API_URL } from '@/lib/appConstants';

const notesApi = {
  fetchProjectNote(projectId) {
    const url = `${BASE_API_URL}notes/project/get?projectId=${projectId}`;
    return axios.get(url);
  },

  saveProjectNote(data) {
    const url = `${BASE_API_URL}notes/project/update`;
    return axios.post(url, JSON.stringify(data));
  }
};

export default notesApi;
