// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SecondaryModal-module__secondaryModalContainer--Aw9wC{font-size:16px}.SecondaryModal-module__modalTitle--_EWjr{font-weight:500;font-size:28px;line-height:39px;padding:0;margin:0;margin-bottom:32px;color:var(--black);text-align:left}.SecondaryModal-module__secondaryModalContainer--Aw9wC .MuiPaper-root.MuiDialog-paper{padding:32px;max-width:none}.SecondaryModal-module__modalActionButtons--ydM7D{display:flex;justify-content:end}.SecondaryModal-module__modalActionButtons--ydM7D button{height:38px;font-weight:400}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondaryModalContainer": `SecondaryModal-module__secondaryModalContainer--Aw9wC`,
	"modalTitle": `SecondaryModal-module__modalTitle--_EWjr`,
	"modalActionButtons": `SecondaryModal-module__modalActionButtons--ydM7D`
};
module.exports = ___CSS_LOADER_EXPORT___;
