// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelVersionStatusActionsModal-module__modalVersionStatusAction--k53_z{width:340px;display:flex;flex-direction:column}.ModelVersionStatusActionsModal-module__modalVersionStatusActionModal--b8CTI{width:460px;min-height:346px}.ModelVersionStatusActionsModal-module__modalVersionStatusActionLabel--WlTnh{color:var(--gray-6)}.ModelVersionStatusActionsModal-module__modalVersionStatusActionApproveComment--jpx5p{background-color:var(--gray-1);padding:10px;border-radius:6px;font-weight:400;color:var(--gray-6);line-height:21px;font-size:14px;margin-top:25px}.ModelVersionStatusActionsModal-module__modalVersionStatusActionComment--mB22X{margin-top:10px}.ModelVersionStatusActionsModal-module__modalVersionStatusActionCommentIcon--uIb_l{display:flex;margin-left:-2px;cursor:pointer;align-items:center}.ModelVersionStatusActionsModal-module__modalVersionStatusActionCommentInput--x8564{margin-top:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalVersionStatusAction": `ModelVersionStatusActionsModal-module__modalVersionStatusAction--k53_z`,
	"modalVersionStatusActionModal": `ModelVersionStatusActionsModal-module__modalVersionStatusActionModal--b8CTI`,
	"modalVersionStatusActionLabel": `ModelVersionStatusActionsModal-module__modalVersionStatusActionLabel--WlTnh`,
	"modalVersionStatusActionApproveComment": `ModelVersionStatusActionsModal-module__modalVersionStatusActionApproveComment--jpx5p`,
	"modalVersionStatusActionComment": `ModelVersionStatusActionsModal-module__modalVersionStatusActionComment--mB22X`,
	"modalVersionStatusActionCommentIcon": `ModelVersionStatusActionsModal-module__modalVersionStatusActionCommentIcon--uIb_l`,
	"modalVersionStatusActionCommentInput": `ModelVersionStatusActionsModal-module__modalVersionStatusActionCommentInput--x8564`
};
module.exports = ___CSS_LOADER_EXPORT___;
