import { AssetData, PCDAssetData } from '@experiment-management-shared/types';

export const transformAssetDataToPCDAsset = (
  asset: AssetData
): PCDAssetData => {
  const boxes = asset.items.find(item => item.assetItemType === 'boxes')
    ?.assetItemLink;
  const points = asset.items.find(item => item.assetItemType === 'points')
    ?.assetItemLink;

  return {
    boxes,
    experimentKey: asset.experimentKey,
    id: asset.assetId,
    metadata: asset.metadata || null,
    name: asset.userFileName,
    points,
    thumbnailPath: asset.thumbnailLink,
    step: asset.step,
    type: asset.assetType,
    path: asset.path
  };
};
