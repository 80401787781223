import React from 'react';
import { Tooltip } from '@ds';
import { MetricCellRendererData } from '../helpers';
import metricCellStyles from './MetricCell.module.scss';
import { useMiddleTruncation, useRefSizes } from '@shared/hooks';

type MetricsTableNameCellProps = MetricCellRendererData;

const MetricsTableNameCell: React.FC<MetricsTableNameCellProps> = ({
  value
}) => {
  const { refWidth = 0, sizeRef } = useRefSizes();
  const { truncatedValue } = useMiddleTruncation({
    text: value,
    maxWidth: refWidth - 10,
    fontConfig: {
      fontSize: 14
    }
  });

  return (
    <div className={metricCellStyles.metricNameCell} ref={sizeRef}>
      <Tooltip content={value} placement="top" truncateOverflow>
        <>{truncatedValue}</>
      </Tooltip>
    </div>
  );
};

export default MetricsTableNameCell;
