import { useMutation, useQueryClient } from 'react-query';

import { Panel } from '@experiment-management-shared/types';
import { updateCodePanelRevision } from '@experiment-management-shared/api/python-panels/api';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

interface UpdateRevisionCode {
  chart: Panel;
  onEditPanel: (
    chartId: string,
    chartFormsMap: Record<string, Panel>,
    chartType: string
  ) => void;
  chartId: string;
  chartFormsMap: Record<string, Panel>;
  chartType: string;
  onCloseModal: () => void;
}

export default function useUpdateRevisionCodeMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ chart }: UpdateRevisionCode) =>
      updateCodePanelRevision(chart?.templateId || '', {
        code: chart.code || ''
      }),
    {
      onMutate: async ({ chart }) => {
        await queryClient.cancelQueries([
          'code-panel',
          { templateId: chart.templateId }
        ]);
      },
      onSuccess: async (
        response,
        { onEditPanel, chartId, chartFormsMap, chartType, chart }
      ) => {
        const newRevisionId = response?.data?.revisionId || '';
        const copyPanelForm = cloneDeep(chartFormsMap);

        set(copyPanelForm, [chartType, 'revisionId'], newRevisionId);
        set(copyPanelForm, [chartType, 'code'], null);
        set(copyPanelForm, [chartType, 'codeVersion'], '');

        onEditPanel(chartId, copyPanelForm, chartType);

        queryClient.removeQueries([
          'code-panel',
          { templateId: chart.templateId }
        ]);

        queryClient.removeQueries([
          'custom-panel-server-url',
          { codeVersion: chart.codeVersion }
        ]);
      },
      onSettled: (data, error, { onCloseModal }) => {
        onCloseModal();
      }
    }
  );
}
