import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import PropTypes from 'prop-types';
import React from 'react';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { useExperimentInstalledPackages } from '@experiment-details/api';
import BaseCodeView from '@experiment-details/components/Code/BaseCodeView';
import InstalledPackagesTabEmpty from './InstalledPackagesTabEmpty';

const InstalledPackagesTab = props => {
  const [experiment] = props.experiments;
  const { data: packages, isLoading } = useExperimentInstalledPackages({
    experimentKey: experiment.experimentKey
  });

  return (
    <BaseCodeView
      {...props}
      data={packages}
      detailView={DETAIL_VIEWS.INSTALLED_PACKAGES}
      isLoading={isLoading}
      emptyView={<InstalledPackagesTabEmpty />}
    />
  );
};

InstalledPackagesTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default InstalledPackagesTab;
