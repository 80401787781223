import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sum from 'lodash/sum';
import zipWith from 'lodash/zipWith';
import { Switch, Select } from '@DesignSystem/controllers';

import { LineChart } from '../LineChart';
import { TableLegend } from '@DesignSystem/data-display';
import { LineChartIcon } from '@Icons-outdated';
import {
  CHART_TYPES,
  DEFAULT_SEGMENTS_ID,
  MPM_LINE_CHART_TYPES,
  PERCENTILE_OPTION_LIST,
  DRIFT_ALGORITHMS_OPTIONS,
  PANEL_SECTIONS
} from '../../constants';
import './LineChartContainer.scss';
import { changeColorOpacity } from '@shared';

export const LineChartContainer = ({
  data,
  variant,
  percentileMode,
  onChangePercentileMode,
  disableZoom,
  section,
  chartType,
  tabData,
  segmentColorIds,
  panelTitle,
  fairnessData,
  driftAlgorithm,
  driftAlgorithmType,
  allowedDriftAlgorithms,
  setDriftAlgorithmType,
  checkedPercentCategorySwitch,
  handlePercentCategorySwitch,
  ...props
}) => {
  const [handledPlotData, setHandledPlotData] = useState([]);
  const [featureValue, setFeatureValue] = useState(data[0]?.featureValue);
  const [unavailableChartData, setUnavailableChartData] = useState(false);

  const isSegmentsData =
    segmentColorIds?.length > 1 ||
    segmentColorIds[0]?.id !== DEFAULT_SEGMENTS_ID;

  const showFeatureDistributionToggle =
    (section === PANEL_SECTIONS.FEATURE_DISTRIBUTION ||
      section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL) &&
    variant === MPM_LINE_CHART_TYPES.segmentCategorical;

  const {
    filterFeature,
    segmentsValuesOptions,
    referenceGroup,
    setReferenceGroup,
    sensitiveGroup,
    setSensitiveGroup
  } = fairnessData || {};

  const featureValueOptions = useMemo(() => {
    const options = [];
    if (data?.length && variant === MPM_LINE_CHART_TYPES.segmentCategorical) {
      uniqBy(data, 'featureValue').forEach(item => {
        if (item.featureValue) {
          options.push({ label: item.featureValue, value: item.featureValue });
        }
      });
    }
    return options;
  }, [data, variant]);

  const pillsData = useMemo(() => {
    if (!data) return [];
    /*
          since we add an invisble trace to fix unrelated issue
          we have to make sure to slice it from the pills data
        */

    if (chartType === CHART_TYPES.STACKED) {
      const arr = data.map(trace => ({
        dotColor: trace.line?.color,
        text: trace.name,
        segmentId: trace.segmentId
      }));

      return arr;
    }

    if (chartType === CHART_TYPES.LINE_SEGMENTS_BARS_DATA) {
      const arr = data
        .filter(item => item.featureValue === featureValue)
        .map(trace => ({
          dotColor: trace.line?.color,
          text: trace.name,
          segmentId: trace.segmentId
        }));
      return arr;
    }

    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE && isSegmentsData) {
      return data
        .filter(item => item.lineTrace === true)
        .map(trace => ({
          dotColor: trace.line?.color,
          text: trace.name,
          segmentId: trace.segmentId
        }));
    }

    if (chartType === CHART_TYPES.FAIRNESS_TIME) {
      return [
        {
          dotColor: handledPlotData?.[0]?.line?.color,
          text: 'Disparate Impact'
        }
      ];
    }

    if (isSegmentsData) {
      const arr = data.map(trace => ({
        dotColor: trace.line?.color,
        text: trace.name,
        segmentId: trace.segmentId
      }));

      return arr;
    }

    return [];
  }, [data, chartType, featureValue, isSegmentsData, handledPlotData]);

  useEffect(() => {
    if (chartType === CHART_TYPES.LINE_SEGMENTS_BARS_DATA && featureValue) {
      setHandledPlotData(
        data.filter(item => item.featureValue === featureValue)
      );
    } else if (chartType === CHART_TYPES.FAIRNESS_TIME) {
      const uniqueFeaturesData = data.filter(
        item => item.featureValue === filterFeature
      );
      const featureNames = uniq(data.map(item => item.name));
      if (uniqueFeaturesData.length > 0) {
        let sensitiveValue;
        let referenceValue;

        if (featureNames.includes(sensitiveGroup)) {
          sensitiveValue = sensitiveGroup;
        } else {
          sensitiveValue =
            uniqueFeaturesData[1]?.name || uniqueFeaturesData[0]?.name;
          setSensitiveGroup(sensitiveValue);
        }

        if (featureNames.includes(referenceGroup)) {
          referenceValue = referenceGroup;
        } else {
          referenceValue = uniqueFeaturesData[0].name;
          setReferenceGroup(referenceValue);
        }
        // all values array
        const sensitiveFeaturesData = data.filter(
          item => item.name === sensitiveValue
        );
        // one outcome value obj
        const sensitiveOutcomeData = sensitiveFeaturesData?.find(
          item => item.featureValue === filterFeature
        );
        // all values array
        const referenceFeaturesData = data.filter(
          item => item.name === referenceValue
        );
        // one outcome value obj
        const referenceOutcomeData = referenceFeaturesData?.find(
          item => item.featureValue === filterFeature
        );
        if (
          !sensitiveOutcomeData?.y.length ||
          !referenceOutcomeData?.y.length
        ) {
          setHandledPlotData([]);
          setUnavailableChartData(true);
          return;
        }

        const sensitiveFeaturesDataYValues = sensitiveFeaturesData.map(
          item => item.y
        );
        const referenceFeaturesDataYValues = referenceFeaturesData.map(
          item => item.y
        );

        const totalSensitivePredictions = zipWith(
          ...sensitiveFeaturesDataYValues,
          (...args) => sum(args)
        );
        const totalReferencePredictions = zipWith(
          ...referenceFeaturesDataYValues,
          (...args) => sum(args)
        );
        const yValues = sensitiveOutcomeData.y.map((item, idx) => {
          const calcValue =
            item /
            totalSensitivePredictions[idx] /
            (referenceOutcomeData?.y[idx] / totalReferencePredictions[idx]);
          return isNaN(calcValue) || calcValue === Infinity ? null : calcValue;
        });

        const avgValue =
          sum(sensitiveOutcomeData?.y) /
          sum(totalSensitivePredictions) /
          (sum(referenceOutcomeData?.y) / sum(totalReferencePredictions));

        const markerX = [];
        const markerY = [];
        yValues.forEach((value, idx) => {
          if (
            typeof value === 'number' &&
            !yValues[idx - 1] &&
            !yValues[idx + 1]
          ) {
            markerX.push(sensitiveOutcomeData.x[idx]);
            markerY.push(value);
          }
        });
        const fairnessChartData = [
          {
            ...sensitiveOutcomeData,
            y: yValues,
            name: 'Disparate Impact',
            mode: yValues.length === 1 ? 'markers' : 'lines',
            avgValue: avgValue
          }
        ];
        if (markerY.length > 0) {
          fairnessChartData.push({
            ...sensitiveOutcomeData,
            x: markerX,
            y: markerY,
            name: 'Disparate Impact',
            mode: 'markers'
          });
        }
        setHandledPlotData(fairnessChartData);
        setUnavailableChartData(false);
      } else {
        setHandledPlotData([]);
        setUnavailableChartData(true);
      }
    } else {
      setHandledPlotData(data);
    }
  }, [data, chartType, featureValue, fairnessData]);

  const handleUnhover = () => {
    if (chartType === CHART_TYPES.FAIRNESS_TIME) return;
    let unhoverData = data;
    if (chartType === CHART_TYPES.LINE_SEGMENTS_BARS_DATA && featureValue) {
      unhoverData = unhoverData.filter(
        item => item.featureValue === featureValue
      );
    }
    setHandledPlotData(unhoverData);
  };

  const onChangefeatureValue = val => {
    setFeatureValue(val);
  };

  const handleHover = (id, text) => {
    if (chartType === CHART_TYPES.FAIRNESS_TIME) return;

    let hoverData = data.map(trace => {
      if (
        trace?.segmentId === id ||
        (!trace.segmentId && trace.name === text)
      ) {
        return trace;
      }

      return {
        ...trace,
        fillcolor: changeColorOpacity(trace.fillcolor, 0.1),
        line: {
          ...trace.line,
          color: changeColorOpacity(trace.line?.color, 0.2)
        }
      };
    });
    if (chartType === CHART_TYPES.LINE_SEGMENTS_BARS_DATA && featureValue) {
      hoverData = hoverData.filter(item => item.featureValue === featureValue);
    }
    setHandledPlotData(hoverData);
  };

  const renderNoData = () => {
    return (
      <div>
        {chartType === CHART_TYPES.FAIRNESS_TIME && (
          <div className="labeled-select-container">
            <Select
              width="239px"
              label="Sensitive group"
              options={segmentsValuesOptions}
              value={sensitiveGroup}
              onChange={setSensitiveGroup}
              variant="outlined"
              disabled={!segmentsValuesOptions?.length || !filterFeature}
            />
          </div>
        )}
        {allowedDriftAlgorithms && (
          <div className="labeled-select-container">
            <Select
              width="239px"
              label="Drift Metrics"
              options={DRIFT_ALGORITHMS_OPTIONS}
              value={driftAlgorithmType}
              onChange={setDriftAlgorithmType}
              variant="outlined"
            />
          </div>
        )}
        <div className="line-chart-no-data-container">
          <LineChartIcon />

          <span>No data for this chart</span>
        </div>
      </div>
    );
  };

  const areFeaturesEmpty = useMemo(() => {
    return !!(
      (data?.length && data.every(item => item?.x.length === 0)) ||
      (chartType === CHART_TYPES.FAIRNESS_TIME && !handledPlotData)
    );
  }, [data, chartType, handledPlotData]);

  if (
    ((!data?.length || areFeaturesEmpty) &&
      chartType !== CHART_TYPES.NON_SUPPORTED_SEGMENTS) ||
    unavailableChartData
  ) {
    return renderNoData();
  }

  return (
    <>
      {variant === MPM_LINE_CHART_TYPES.percentile && (
        <div className="labeled-select-container">
          <Select
            label="Percentiles"
            horizontalLabel={true}
            options={PERCENTILE_OPTION_LIST}
            value={percentileMode}
            onChange={onChangePercentileMode}
            variant="outlined"
          />
        </div>
      )}

      {variant === MPM_LINE_CHART_TYPES.segmentCategorical && (
        <div className="labeled-select-container">
          <Select
            label="Feature Value"
            horizontalLabel={true}
            options={featureValueOptions}
            value={featureValue}
            onChange={onChangefeatureValue}
            variant="outlined"
          />
        </div>
      )}

      {chartType === CHART_TYPES.FAIRNESS_TIME && (
        <div className="labeled-select-container">
          <Select
            label="Sensitive group"
            options={segmentsValuesOptions}
            value={sensitiveGroup}
            onChange={setSensitiveGroup}
            variant="outlined"
            disabled={!segmentsValuesOptions?.length || !filterFeature}
            horizontalLabel={true}
          />
        </div>
      )}

      {allowedDriftAlgorithms && (
        <div className="labeled-select-container">
          <Select
            label="Drift Metrics"
            options={DRIFT_ALGORITHMS_OPTIONS}
            value={driftAlgorithmType}
            onChange={setDriftAlgorithmType}
            variant="outlined"
            horizontalLabel={true}
          />
        </div>
      )}

      {pillsData.length > 0 && (
        <div className="line-chart-legend-container">
          <TableLegend
            onUnhover={handleUnhover}
            onHover={handleHover}
            pills={pillsData}
          />
        </div>
      )}

      <LineChart
        height={240}
        alertNotifications={data[0]?.alertNotifications}
        data={handledPlotData}
        disableZoom={disableZoom}
        section={section}
        variant={variant}
        percentileMode={percentileMode}
        featureValue={featureValue}
        panelTitle={panelTitle}
        tabData={tabData}
        isSegmentsData={isSegmentsData}
        fairnessData={fairnessData}
        sensitiveGroup={sensitiveGroup}
        chartType={chartType}
        driftAlgorithm={driftAlgorithm}
        {...props}
      />
      {showFeatureDistributionToggle && (
        <div className="data-type-toggle">
          <span className="data-type-title">{'Percentages / Categories'} </span>
          <Switch
            checked={checkedPercentCategorySwitch}
            onChange={handlePercentCategorySwitch}
            color="primary"
          />
        </div>
      )}
    </>
  );
};

LineChartContainer.defaultProps = {
  variant: MPM_LINE_CHART_TYPES.default,
  data: [],
  percentileMode: '',
  onChangePercentileMode: noop,
  disableZoom: false,
  section: '',
  segmentColorIds: [],
  panelTitle: '',
  chartType: null,
  fairnessData: {},
  driftAlgorithmType: '',
  allowedDriftAlgorithms: false,
  setDriftAlgorithmType: noop,
  checkedPercentCategorySwitch: false,
  handlePercentCategorySwitch: noop
};

LineChartContainer.propTypes = {
  variant: PropTypes.oneOf([
    MPM_LINE_CHART_TYPES.default,
    MPM_LINE_CHART_TYPES.percentile,
    MPM_LINE_CHART_TYPES.stacked,
    MPM_LINE_CHART_TYPES.nonSupportedSegments,
    MPM_LINE_CHART_TYPES.segmentCategorical
  ]),
  data: PropTypes.array,
  chartType: PropTypes.string,
  percentileMode: PropTypes.string,
  onChangePercentileMode: PropTypes.func,
  disableZoom: PropTypes.bool,
  section: PropTypes.string,
  tabData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  segmentColorIds: PropTypes.array,
  panelTitle: PropTypes.string,
  fairnessData: PropTypes.object,
  driftAlgorithmType: PropTypes.string,
  allowedDriftAlgorithms: PropTypes.boolean,
  setDriftAlgorithmType: PropTypes.func,
  checkedPercentCategorySwitch: PropTypes.bool,
  handlePercentCategorySwitch: PropTypes.func
};
