import React, { forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router';

import { DSColors } from '@design-system-outdated/constants';
import {
  CHART_TYPES,
  TOOLTIP_FEATURE_VIEW_SECTION,
  PREFIX_MPM_PATH,
  PANEL_SECTIONS
} from '@mpm/constants';
import { addHoverBarsOpacity, modifyBarData } from '@mpm/utils';
import { usePanelResize } from '@/DesignSystem/hooks';
import { useTooltip } from '@mpm/hooks';
import OptimizedPlot from '@DesignSystem/charts/OptimizedPlot';
import { ChartTooltip } from '../ChartTooltip';
import './BarChart.scss';

const config = {
  displayModeBar: false
};

const BarChart = (
  { data, height, disableZoom, section, chartType, layoutData, ...props },
  plotRef
) => {
  const containerRef = useRef();
  const { tabData, showCustomTooltip, feature, intervalType } = props;
  const {
    inspectMode,
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    hideTooltipHandler,
    closeTooltipHandler,
    handleClick
  } = useTooltip(tabData, chartType);

  const featureId = tabData?.featureId;

  usePanelResize(plotRef, containerRef);

  const { pathname } = useLocation();
  const isPerformancePage = matchPath(
    pathname,
    `${PREFIX_MPM_PATH}/performance`
  )?.isExact;

  const showViewFeatureAction =
    TOOLTIP_FEATURE_VIEW_SECTION.includes(section) && isPerformancePage;

  const chartData = useMemo(() => {
    if (!tooltipVisible || !tooltipData) {
      if (
        intervalType === 'daily' &&
        (section === PANEL_SECTIONS.FEATURE_DISTRIBUTION ||
          section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL)
      ) {
        return modifyBarData(data);
      }
      return data;
    }

    const barIndex = tooltipData?.data?.pointIndex;
    const barTitle = tooltipData?.title;

    if (
      intervalType === 'daily' &&
      (section === PANEL_SECTIONS.FEATURE_DISTRIBUTION ||
        section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL)
    ) {
      const hoverData = addHoverBarsOpacity(
        modifyBarData(data),
        barTitle,
        barIndex
      );
      return hoverData;
    }

    return addHoverBarsOpacity(data, barTitle, barIndex);
  }, [data, tooltipData, tooltipVisible, intervalType]);

  const maxDataLength = useMemo(() => {
    let maxLength = 0;

    for (let i = 0; i < data.length; i++) {
      const currentLength = data[i].x?.length;
      if (currentLength > maxLength) {
        maxLength = currentLength;
      }
    }

    return maxLength;
  }, [data, chartType, layoutData]);

  // is substracts 0.1 for every 30 points
  const barGap = useMemo(() => {
    if (maxDataLength >= 10 && maxDataLength <= 120) {
      const val = 0.7 - 0.1 * Math.round(maxDataLength / 30);
      return val >= 0.1 ? val : 0.1;
    }
    if (maxDataLength > 120 && maxDataLength < 130) {
      return 0.355;
    }
    if (maxDataLength >= 130 && maxDataLength <= 144) {
      return 0.45;
    }
    if (maxDataLength > 144) {
      return 0.5;
    }

    return 0.875;
  }, [maxDataLength]);

  const layout = useMemo(() => {
    const baseLayout = {
      barmode: 'stack',
      bargap: barGap,
      height,
      autosize: true,
      showlegend: false,
      margin: { b: 40, l: 40, pad: 10, r: 10, t: 0 },
      hovermode: showCustomTooltip && 'closest',
      hoverdistance: 8,
      yaxis: {
        automargin: true,
        autorange: true,
        zerolinecolor: '#E2E2E2',
        gridcolor: DSColors.grayColor3,
        tickfont: {
          color: DSColors.grayColor5,
          size: 11
        }
      },
      xaxis: {
        automargin: true,
        autorange: true,
        showgrid: false,
        tickfont: {
          color: DSColors.grayColor6,
          size: 11
        },
        tickformat: '%d %b',
        zerolinecolor: 'white'
      }
    };

    if (chartType === CHART_TYPES.FAIRNESS_GROUPED) {
      const groupedLayout = {
        ...baseLayout,
        barmode: 'group',
        bargap: 0.7,
        bargroupgap: 0.4
      };
      return groupedLayout;
    }
    return baseLayout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, barGap, inspectMode, chartType]);

  return (
    <div ref={containerRef} className="ds-bar-chart-container">
      {tooltipVisible && (
        <ChartTooltip
          tooltipData={tooltipData}
          position={tooltipPosition}
          closeHandler={closeTooltipHandler}
          inspectMode={inspectMode}
          orientation={tooltipOrientation}
          section={section}
          chartType={chartType}
          featureId={featureId}
          tabData={tabData}
          showViewFeatureAction={showViewFeatureAction}
          feature={feature}
        />
      )}
      <OptimizedPlot
        data={chartData}
        layout={layout}
        config={config}
        useResizeHandler
        ref={plotRef}
        disableZoom={disableZoom}
        onHover={handleLinePointHover}
        onUnhover={hideTooltipHandler}
        onClick={handleClick}
      />
    </div>
  );
};

const BarChartRef = forwardRef(BarChart);

BarChart.defaultProps = {
  data: [],
  height: 'auto',
  disableZoom: false,
  chartType: CHART_TYPES.BAR,
  layoutData: null
};

BarChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  disableZoom: PropTypes.bool,
  section: PropTypes.string.isRequired,
  chartType: PropTypes.string,
  layoutData: PropTypes.object
};

export default BarChartRef;
