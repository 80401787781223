import cx from 'classnames';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { markdownLanguage } from '@codemirror/lang-markdown';
import { markdownToHTML } from '@shared/utils';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';

import 'github-markdown-css/github-markdown.css';
import 'katex/dist/katex.css';
import styles from './MarkdownEditor.module.scss';

type MarkdownEditorProps = {
  editable?: boolean;
  markdown?: string;
  onAddImage?: (file: File) => Promise<string>;
  onChange: (newMarkdown: string) => void;
  placeholder?: string;
};

const MARKDOWN_EXTENSIONS = [markdownLanguage];

export const MarkdownEditor = ({
  editable = true,
  markdown: initialMarkdown = '',
  onAddImage,
  onChange = noop,
  placeholder = 'Add text/markdown...'
}: MarkdownEditorProps) => {
  const [editorView, setEditorView] = useState<EditorView | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [markdown, setMarkdown] = useState(initialMarkdown);
  const [html, setHTML] = useState('');

  const insertMarkdown = useCallback(
    (beforeText = '', afterText = '') => {
      if (!editorView) return;

      const { from, to } = editorView.state.selection.main;
      const text = editorView.state.doc.sliceString(from, to);
      const newText = `${beforeText}${text}${afterText}`;

      editorView.focus();

      editorView.dispatch({
        changes: {
          from: from,
          to: to,
          insert: newText
        },
        selection: { anchor: from + beforeText.length + text.length }
      });
    },
    [editorView]
  );

  const onDropAccepted = useCallback(
    async (files: File[]) => {
      if (files.length !== 1 || !onAddImage) return;

      const url = await onAddImage(files[0]);

      if (!url) return;

      insertMarkdown('![', `](${url})`);
    },
    [insertMarkdown, onAddImage]
  );

  const { getRootProps, getInputProps, open: openFileDialog } = useDropzone({
    accept: { 'image/*': [] },
    disabled: !onAddImage,
    onDropAccepted,
    maxFiles: 1,
    noClick: true
  });

  useEffect(() => {
    let cancelled = false;

    const processMarkdown = async () => {
      const newHTML = await markdownToHTML(markdown);

      if (!cancelled) {
        setHTML(newHTML);
      }
    };

    if (markdown) {
      processMarkdown();
    } else {
      setHTML('');
    }

    return () => {
      cancelled = true;
    };
  }, [markdown]);

  const handleSave = () => {
    if (markdown !== initialMarkdown) {
      onChange(markdown);
    }
  };

  const renderEditableMarkdown = () => {
    return (
      <div className={styles.editableArea}>
        <div className={styles.textareaContainer} {...getRootProps()}>
          <input {...getInputProps()} />
          <CodeMirror
            value={markdown}
            autoFocus
            onBlur={handleSave}
            onCreateEditor={view => {
              setEditorView(view);
            }}
            onChange={newMarkdown => {
              setMarkdown(newMarkdown);
            }}
            placeholder={placeholder}
            extensions={MARKDOWN_EXTENSIONS}
          />
        </div>
      </div>
    );
  };

  const renderViewMarkdown = () => {
    if (!html) return null;

    return (
      <div
        className={styles.markdown}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    );
  };

  const renderContent = () => {
    if (isEditing) {
      return renderEditableMarkdown();
    }

    return renderViewMarkdown();
  };

  const renderToolbar = () => {
    return (
      <div className={styles.toolbar}>
        <ul>
          <li>
            <button onClick={() => insertMarkdown('# ')}>h1</button>
          </li>
          <li>
            <button onClick={() => insertMarkdown('## ')}>h2</button>
          </li>
          <li>
            <button onClick={() => insertMarkdown('### ')}>h3</button>
          </li>
        </ul>
        <ul>
          <li>
            <button onClick={() => insertMarkdown('[', '](url)')}>
              <span
                className="material-icons MuiIcon-root material-icon"
                aria-hidden="true"
              >
                link
              </span>
            </button>
          </li>
          {onAddImage ? (
            <li>
              <div>
                <button onClick={openFileDialog}>
                  <span className="material-icons MuiIcon-root">image</span>
                </button>
              </div>
            </li>
          ) : null}
        </ul>
        <ul>
          <li>
            <button onClick={() => insertMarkdown('**', '**')}>
              <span className="material-icons MuiIcon-root material-icon">
                format_bold
              </span>
            </button>
          </li>
          <li>
            <button onClick={() => insertMarkdown('_', '_')}>
              <span className="material-icons MuiIcon-root material-icon">
                format_italic
              </span>
            </button>
          </li>
          <li>
            <button onClick={() => insertMarkdown('`', '`')}>
              <span className="material-icons MuiIcon-root material-icon">
                code
              </span>
            </button>
          </li>
        </ul>
        <ul>
          <li>
            <button onClick={() => insertMarkdown('- ')}>
              <span className="material-icons MuiIcon-root material-icon">
                format_list_bulleted
              </span>
            </button>
          </li>
          <li>
            <button onClick={() => insertMarkdown('1. ')}>
              <span className="material-icons MuiIcon-root material-icon">
                format_list_numbered
              </span>
            </button>
          </li>
          <li>
            <button onClick={() => insertMarkdown('> ')}>
              <span className="material-icons MuiIcon-root material-icon">
                format_quote
              </span>
            </button>
          </li>
        </ul>
      </div>
    );
  };

  if (!editable) {
    return (
      <div className={styles.markdownContainer}>
        <div className={cx(styles.content, styles.view)}>
          {renderViewMarkdown()}
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.markdownContainer, styles.editingContainer)}>
      <div className={styles.toolbarContainer}>
        <div className={styles.tabContainer}>
          <div
            className={cx(styles.tabItem, { [styles.tabActive]: isEditing })}
            onClick={() => setIsEditing(true)}
          >
            Write
          </div>
          <div
            className={cx(styles.tabItem, { [styles.tabActive]: !isEditing })}
            onClick={() => setIsEditing(false)}
          >
            Preview
          </div>
        </div>

        {isEditing ? renderToolbar() : null}
      </div>

      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};
