import { useCurrentOrganization } from '@shared/hooks';
import useOrganizationBilling from './useOrganizationBilling';

const useActiveOrganizationPaymentDetails = (config = {}) => {
  const currentOrganization = useCurrentOrganization();
  const data = useOrganizationBilling(currentOrganization?.id, config);

  return data;
};

export default useActiveOrganizationPaymentDetails;
