import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Box } from '@material-ui/core';
import { SearchIcon, CrossIcon } from '@Icons-outdated';
import { Button } from '@ds';
import React, { useCallback, useMemo, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import useModelRegistryView, {
  getDefaultCustomizeModelColumns,
  PINNED_LEFT_MODEL_REGISTRY_COLUMNS
} from '@API/model-registry/useModelRegistryView';
import { GRID_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import useModelRegistryColumnsMutation from '@API/model-registry/useModelRegistryColumnsMutation';
import useModelRegistry from '@API/model-registry/useModelRegistry';
import isEqual from 'lodash/isEqual';
import useModelRegistryColumnNames from '@API/model-registry/useModelRegistryColumnNames';
import useModelRegistryItemsDetails from '@API/model-registry/useModelRegistryItemsDetails';
import useModelRegistryUpdateItemMutation from '@API/model-registry/useModelRegistryUpdateItemMutation';
import { KIND_UNIQ_SYMBOL } from '@API/helpers/v2_helpers';
import MoveModelVersionModal from '@model-registry/components/MoveModelVersionModal';
import {
  getModelVersionRequestedStatus,
  getModelVersionStatus
} from '@model-registry/utils/request-status';
import ModelVersionRequestStatusModal from '@model-registry/components/ModelVersionRequestStatusModal';
import { OPEN_MODAL_ORIGIN } from '@model-registry/constants/request-status';
import useHandleStatusUpdate from '@model-registry/hooks/useHandleStatusUpdate';
import { DeleteModelVersionModal } from '@model-registry/components/DeleteModelModal';
import ManageStageToTagsModal from '@model-registry/components/ManageStageToTagsModal';
import Input from '@DesignSystem/controllers/Input/Input';
import { dialogTypes } from '@/constants/alertTypes';

import CustomizeColumnsModal from '@experiment-management-shared/components/ColumnsModal/CustomizeColumnsModal';
import ModelVersionsGrid from './modal-versions-grid/ModelVersionsGrid';
import ModelVersionsGridRowActionMenu from './modal-versions-grid/ModelVersionsGridRowActionMenu';

import './ModelVersionsTab.scss';
import alertUtils from '@/util/alertsUtil';
import { mapStageOptionsToSelectOptions } from '@shared/components/Select';
import history from '@/history';
import { SUB_PATHS } from '@/constants/urlConstants';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import {
  BETA_FEATURE_MODEL_APPROVAL_PROCESS,
  BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
} from '@/lib/betaFeatures';
import {
  useCurrentOrganization,
  useDebouncedFilter,
  useIsUserMemberOfWorkspace
} from '@shared/hooks';

import { DSColumnsIcon } from '@ds-icons';

const getHoveredRowData = (row, prop) => get(row, `rowData.${prop}`);

const ModelVersionsTab = ({ model, isUserLoggedIn, ifCurrentUserWsOwner }) => {
  const { registryModelId, isPublic } = model;
  const dispatch = useDispatch();
  const location = useLocation();
  const { modelName, workspace } = useParams();
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const { search: initialSearchValue = '' } = queryString.parse(
    location.search
  );
  useModelRegistry({ modelName, workspace });

  const currentOrganization = useCurrentOrganization();
  const isAdmin = currentOrganization?.isAdmin;
  const isAdminOrWsOwner = isAdmin || ifCurrentUserWsOwner;

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    debouncedFilterValue: debouncedSearchValue,
    setFilterValue: setDebouncedSearchValue,
    filterValue: searchValue
  } = useDebouncedFilter(initialSearchValue);

  const isModelRegistryTagsFromStagesEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
    });
  });
  const isModelApprovalProcessEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_APPROVAL_PROCESS
    });
  });

  // SMELLY: Stages to tags migration on FE
  const [stageToTagsModalOpen, setStageToTagsModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [hoveredRowData, setHoveredRowData] = useState({});
  const [openOrigin, setOpenOrigin] = useState(null);
  const [isDeleteVersionModalOpen, setIsDeleteVersionModalOpen] = useState(
    false
  );
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

  const defaultColumnsForModelRegistry = useMemo(
    () =>
      getDefaultCustomizeModelColumns({
        isModelRegistryTagsFromStagesEnabled,
        isModelApprovalProcessEnabled
      }),
    [isModelRegistryTagsFromStagesEnabled, isModelApprovalProcessEnabled]
  );

  const { data: savedModelRegistryView } = useModelRegistryView(
    registryModelId,
    {
      enabled: Boolean(
        registryModelId &&
          ((!isPublic && isUserAMemberOfWorkspace) ||
            isPublic ||
            !isUserLoggedIn)
      )
    },
    {
      isModelApprovalProcessEnabled,
      isModelRegistryTagsFromStagesEnabled
    }
  );
  const { columnsState } = savedModelRegistryView || {};
  const columnsMutation = useModelRegistryColumnsMutation({ registryModelId });
  const {
    data: modelRegistryColumnNames = {},
    isLoading: isModelRegistryColumnNamesLoading
  } =
    useModelRegistryColumnNames({
      registryModelId,
      isModelApprovalProcessEnabled,
      isModelRegistryTagsFromStagesEnabled
    }) || {};
  const {
    columns: columnsForModelRegistry = [],
    extraCols: extraColumnsForModelRegistry
  } = modelRegistryColumnNames || {};

  const updateModelRegistryItemStateMutation = useModelRegistryUpdateItemMutation(
    { workspace, modelName }
  );
  const filteredColumnsForModelRegistry = useMemo(
    () =>
      [
        ...PINNED_LEFT_MODEL_REGISTRY_COLUMNS.map(columnName => ({
          name: columnName,
          id: columnName,
          type: 'string',
          kinds: [],
          source: ''
        })),
        ...columnsForModelRegistry
      ].filter(({ name }) => name !== GRID_COLUMNS.NAME),
    [columnsForModelRegistry]
  );

  const handleRowMenuClick = (rowData, ev) => {
    setHoveredRowData(rowData);
    setAnchorEl({ x: ev.clientX, y: ev.clientY });
  };

  const handleRowMenuClose = () => {
    setHoveredRowData({});
    setAnchorEl(null);
    setOpenOrigin(null);
  };

  const handleCopyLinkClick = () => {
    setAnchorEl(null);
    dispatch(alertUtils.openCopySuccessSnackbar());
  };

  const handleDeleteVersionClick = useCallback(() => {
    setAnchorEl(null);
    setIsDeleteVersionModalOpen(true);
  }, [dispatch, hoveredRowData]);

  const handleMoveVersionClick = useCallback(() => {
    setAnchorEl(null);
    setIsMoveModalOpen(true);
  }, [dispatch, hoveredRowData]);

  const handleAddStageToTagsClick = () => {
    setAnchorEl(null);
    setStageToTagsModalOpen(true);
  };
  const handleManageStatusClick = () => {
    setAnchorEl(null);
    setStatusModalOpen(true);
    setOpenOrigin(OPEN_MODAL_ORIGIN.side_menu);
  };

  const selectedStagesOrTags = useMemo(() => {
    return mapStageOptionsToSelectOptions(
      reduce(
        model?.versions,
        (stages, version) =>
          uniq([...stages, ...(version?.stageList || [])], 'value'),
        []
      )
    );
  }, [model, hoveredRowData]);

  const stagesOrTagsOptions = useMemo(() => {
    return mapStageOptionsToSelectOptions(
      getHoveredRowData(hoveredRowData, 'stageList')
    );
  }, [model, hoveredRowData]);

  const handleManageStageToTagsModalClose = useCallback(() => {
    setStageToTagsModalOpen(false);
    handleRowMenuClose();
  }, []);

  const handleStagesToTagsUpdate = useCallback(
    (newStages, description) => {
      updateModelRegistryItemStateMutation.mutate({
        values: {
          comment: description,
          stages: newStages
        },
        registryModelItemId: getHoveredRowData(
          hoveredRowData,
          'registryModelItemId'
        )
      });

      handleManageStageToTagsModalClose();
    },
    [handleManageStageToTagsModalClose, hoveredRowData]
  );

  const handleManageStatusModalClose = useCallback(() => {
    setStatusModalOpen(false);
    handleRowMenuClose();
  }, []);

  const handleStatusUpdate = useHandleStatusUpdate({
    currentVersion: getHoveredRowData(hoveredRowData, 'version'),
    registryModelItemId: getHoveredRowData(
      hoveredRowData,
      'registryModelItemId'
    ),
    registryModelId: model?.registryModelId,
    workspace,
    modelName,
    ifCurrentUserWsOwner,
    deps: [
      handleManageStageToTagsModalClose,
      hoveredRowData,
      model?.registryModelId
    ],
    invalidateQueriesKeys: {
      pendingRequests: [
        'modelRegistry',
        { workspace, modelName },
        'pending-requests'
      ]
    }
  });

  const closeCustomizeColumnsDialog = () =>
    dispatch(
      alertUtils.closeDialog(dialogTypes.CUSTOMIZE_MODEL_REGISTRY_COLUMNS_MODAL)
    );

  const handleExperimentError = () =>
    dispatch(
      alertUtils.openErrorDialog(
        dialogTypes.CATCH_ERROR_API,
        'The experiment for this version model does not exist'
      )
    );

  const {
    columnsToFetch: selectedColumnsToFetchModelRegistryItemsDetails,
    columnsToShowToUser: selectedVisibleColumnsForUser
  } = useMemo(() => {
    if (!columnsState) {
      return {
        columnsToFetch: [],
        columnsToShowToUser: []
      };
    }

    const columns = isEmpty(columnsState)
      ? defaultColumnsForModelRegistry
      : columnsState;
    // we need this to get the name of source experiment and show it in the table, even if Name column is not selected in the view
    if (!columns.includes(GRID_COLUMNS.NAME)) {
      columns.push(GRID_COLUMNS.NAME);
    }

    // we need to filter columns that was saved by user, with all the columns user can select
    // so that we can request data for all the columns for the user
    const columnsToFetch = [
      ...(columns || []).map(column => {
        // split names, because some columns contain %%% in names, and we need to remove it
        const columnName = column.split(KIND_UNIQ_SYMBOL) || [];
        let realColumnName = columnName[0];
        const kind = columnName[1];
        const findColumn = columnsForModelRegistry.find(
          columnInfo => columnInfo.name === realColumnName
        );

        // SMELLY: Stages to tags migration on FE
        if (
          isModelRegistryTagsFromStagesEnabled &&
          realColumnName === GRID_COLUMNS.TAGS
        ) {
          realColumnName = GRID_COLUMNS.STAGE;
        }

        return {
          keyName: realColumnName,
          source: findColumn?.source || undefined,
          kind
        };
      })
    ].filter(Boolean);

    return {
      columnsToFetch,
      columnsToShowToUser: columnsToFetch
        .map(item => {
          let keyName = item?.keyName;
          if (
            [
              GRID_COLUMNS.PINNED,
              GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME,
              GRID_COLUMNS.NAME
            ].includes(keyName)
          ) {
            return null;
          }

          // SMELLY: Stages to tags migration on FE
          if (
            isModelRegistryTagsFromStagesEnabled &&
            keyName === GRID_COLUMNS.STAGE
          ) {
            keyName = GRID_COLUMNS.TAGS;
          }

          return keyName;
        })
        .filter(Boolean)
    };
  }, [
    columnsForModelRegistry,
    defaultColumnsForModelRegistry,
    columnsState,
    savedModelRegistryView
  ]);

  const {
    data: modelRegistryDataForColumns,
    isLoading: isLoadingModelRegistryVersions,
    isFetched: isFetchedModelRegistryVersions
  } = useModelRegistryItemsDetails({
    columns: selectedColumnsToFetchModelRegistryItemsDetails,
    registryModelId,
    versions: model?.versions
  });
  const columnsActions = useMemo(() => {
    if (columnsState?.includes(GRID_COLUMNS.STATUS)) {
      return [
        {
          columnName: GRID_COLUMNS.STATUS,
          action: row => {
            setOpenOrigin(OPEN_MODAL_ORIGIN.table);
            setStatusModalOpen(true);
            setHoveredRowData(row);
          }
        }
      ];
    }
    return [];
  }, [columnsState, ifCurrentUserWsOwner]);

  const handleOpenCustomizeModal = () =>
    dispatch(
      alertUtils.openCustomModal(
        dialogTypes.CUSTOMIZE_MODEL_REGISTRY_COLUMNS_MODAL,
        <CustomizeColumnsModal
          isModelRegistryTagsFromStagesEnabled={
            isModelRegistryTagsFromStagesEnabled
          }
          fixedColumnsNames={[
            ...PINNED_LEFT_MODEL_REGISTRY_COLUMNS,
            isModelApprovalProcessEnabled && GRID_COLUMNS.STATUS
          ]}
          onUpdateColumns={async selectedColumns => {
            let columnsToUpdate = selectedColumns;
            // make sense to save only if the columns are changed
            if (!isEqual(columnsToUpdate, columnsState)) {
              // SMELLY: Stages to tags migration on FE
              if (
                isModelRegistryTagsFromStagesEnabled &&
                columnsToUpdate.indexOf(GRID_COLUMNS.TAGS)
              ) {
                columnsToUpdate = columnsToUpdate.map(columnName =>
                  columnName === GRID_COLUMNS.TAGS
                    ? GRID_COLUMNS.STAGE
                    : columnName
                );
              }

              await columnsMutation.mutate({ columns: columnsToUpdate });
            }

            closeCustomizeColumnsDialog();
          }}
          selectedColumnNames={columnsState}
          isLoading={isModelRegistryColumnNamesLoading}
          defaultColumns={defaultColumnsForModelRegistry}
          data={filteredColumnsForModelRegistry}
          extraCols={extraColumnsForModelRegistry}
          onClose={closeCustomizeColumnsDialog}
          shouldTrackMetrics={false}
        />
      )
    );

  const isLastModelVersion = model?.versions?.length <= 1;

  return (
    <Box
      className="model-version-tab"
      data-test="model-registry-versions-tab-content"
    >
      <ModelVersionsGridRowActionMenu
        handleExperimentError={() => {
          handleExperimentError();
          handleRowMenuClose();
        }}
        hoveredRowData={hoveredRowData}
        anchorEl={anchorEl}
        handleRowMenuClose={handleRowMenuClose}
        handleManageStatusClick={handleManageStatusClick}
        handleAddStageClick={handleAddStageToTagsClick}
        handleCopyLinkClick={handleCopyLinkClick}
        handleDeleteVersionClick={handleDeleteVersionClick}
        handleMoveVersionClick={handleMoveVersionClick}
      />
      <DeleteModelVersionModal
        isLastModelVersion={isLastModelVersion}
        handleRowMenuClose={handleRowMenuClose}
        handleRedirectOnDelete={false}
        isOpen={isDeleteVersionModalOpen}
        onClose={() => setIsDeleteVersionModalOpen(false)}
        modelVersionData={hoveredRowData?.rowData}
      />
      <MoveModelVersionModal
        model={model}
        workspace={workspace}
        isOpen={isMoveModalOpen}
        onClose={() => {
          setIsMoveModalOpen(false);
          handleRowMenuClose();
        }}
        isLastModelVersion={isLastModelVersion}
        onSuccess={({ targetModelName, version, targetWorkspaceName }) => {
          if (isLastModelVersion) {
            history.push(
              `/${targetWorkspaceName}/${SUB_PATHS.MODEL_REGISTRY}/${targetModelName}/${version}`
            );
          }
        }}
        modelVersionData={hoveredRowData?.rowData}
      />
      <ManageStageToTagsModal
        isModelRegistryTagsFromStagesEnabled={
          isModelRegistryTagsFromStagesEnabled
        }
        open={stageToTagsModalOpen}
        onClose={handleManageStageToTagsModalClose}
        stages={stagesOrTagsOptions || []}
        description={getHoveredRowData(hoveredRowData, 'comment')}
        onUpdate={handleStagesToTagsUpdate}
        options={selectedStagesOrTags}
      />
      <ModelVersionRequestStatusModal
        workspace={workspace}
        modelName={modelName}
        open={statusModalOpen}
        onClose={handleManageStatusModalClose}
        status={getModelVersionStatus(hoveredRowData)}
        requestedStatus={getModelVersionRequestedStatus(hoveredRowData)}
        onUpdate={handleStatusUpdate.handler}
        onUpdateMutation={handleStatusUpdate.mutation}
        ifCurrentUserWsOwner={ifCurrentUserWsOwner}
        selectedVersion={hoveredRowData?.rowData}
        openOrigin={openOrigin}
      />
      <Box className="model-version-add-columns-wrapper">
        <Button
          data-test="model-registry-add-columns"
          disabled={!isUserAMemberOfWorkspace}
          PrefixIcon={<DSColumnsIcon />}
          onClick={handleOpenCustomizeModal}
          type="secondary"
        >
          Add Columns ({selectedVisibleColumnsForUser?.length})
        </Button>
        <Box className="model-version-tab-search">
          <Input
            placeholder="Search"
            value={searchValue}
            onChange={setDebouncedSearchValue}
            InlinePrefixIcon={SearchIcon}
            onPostfixIconClick={() => setDebouncedSearchValue('')}
            height="32px"
            PostfixIcon={CrossIcon}
          />
        </Box>
      </Box>
      <Box className="model-version-grid-wrapper">
        <ModelVersionsGrid
          isAdminOrWsOwner={isAdminOrWsOwner}
          isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
          workspace={workspace}
          columnsActions={columnsActions}
          model={model}
          searchValue={debouncedSearchValue}
          isRowMenuOpened={row =>
            row?.rowData?.version &&
            row?.rowData?.version ===
              getHoveredRowData(hoveredRowData, 'version')
          }
          gridData={modelRegistryDataForColumns}
          availableColumns={columnsForModelRegistry}
          selectedColumns={selectedVisibleColumnsForUser}
          handleRowMenuClick={handleRowMenuClick}
          isLoading={
            !isFetchedModelRegistryVersions &&
            isUserLoggedIn &&
            isLoadingModelRegistryVersions
          }
        />
      </Box>
    </Box>
  );
};

ModelVersionsTab.propTypes = {
  model: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  ifCurrentUserWsOwner: PropTypes.bool.isRequired
};

export default ModelVersionsTab;
