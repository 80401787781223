/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';
import billingActions from '../actions/billingActions';
import loginActions from '@/actions/loginActions';
import isFunction from 'lodash/isFunction';

export const UNAUTHORIZED_ERROR_CODE = 401;
export const NO_PAYMENT_PLAN_ERROR_CODE = 402;
export const COMET_FE_VERSION_HEADER_NAME = 'comet-react-ver';

const excludeUnauthorizedURLs = [
  '/api/code-visualizations/template/get',
  '/api/code-panels/compute/get',
  url =>
    url.includes('/api/code-visualizations/template') &&
    url.endsWith('/revisions/'),
  '/api/code-panels/templates/'
];

const isValidUnauthorizedURL = url => {
  return excludeUnauthorizedURLs.every(excludeURL => {
    if (isFunction(excludeURL)) {
      return !excludeURL(url);
    }

    return !url.includes(excludeURL);
  });
};

export const setupInterceptors = (store, instance) => {
  instance.defaults.headers.common[COMET_FE_VERSION_HEADER_NAME] =
    window.VERSION;

  instance.interceptors.response.use(
    response => response,
    error => {
      const status = get(error, 'response.status', 0);

      if (status === NO_PAYMENT_PLAN_ERROR_CODE) {
        store.dispatch(
          billingActions.paymentPlanPermissionsError(
            'Please upgrade workspace payment plan to enable this feature'
          )
        );

        return Promise.reject(error);
      }

      const responseURL = get(error, 'request.responseURL', '');

      if (
        status === UNAUTHORIZED_ERROR_CODE &&
        isValidUnauthorizedURL(responseURL)
      ) {
        if (!store.getState().user.isUserLoggedIn) {
          store.dispatch(loginActions.openLoginModal());
        }

        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
};
