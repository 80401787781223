import ExperimentKey from './cell/ExperimentKey';
import ResizableExperimentTagList from '@shared/components/TableCells/ResizableExperimentTagList/ResizableExperimentTagList';
import VisibilityButton from './cell/VisibilityButton';
import ExperimentName from './cell/ExperimentName';
import SourceExperimentCell from './cell/SourceExperimentCell';
import RequestStatusCell from './cell/RequestStatusCell';
import TagsCell from './cell/TagsCell';
import RowActionIcon from './cell/RowActionIcon';
import StageCell from './cell/StageCell';
import RunActive from './cell/RunActive';
import SingleExperimentBtn from './cell/SingleExperimentButton';

export { default } from './Cell';

export const SPECIAL_CELL_COMPONENTS = {
  experimentKey: ExperimentKey,
  experimentTags: ResizableExperimentTagList,
  isVisibleOnDashboard: VisibilityButton,
  Name: ExperimentName,
  SourceExperiment: SourceExperimentCell,
  Status: RequestStatusCell,
  Tags: TagsCell,
  rowActionMenu: RowActionIcon,
  Stage: StageCell,
  runActive: RunActive,
  singleExperimentBtn: SingleExperimentBtn
};
