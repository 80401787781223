import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CHART_COLORS } from '@/constants/colorConstants';
import StyledTooltip from '@shared/components/StyledComponents/StyledTooltip';

const ArtifactTags = ({ tagNames, maxVisible }) => {
  const isHiddenTags = tagNames.length > maxVisible;

  const renderTags = (isTooltip = false) => {
    const visibleTagNames = isTooltip
      ? tagNames
      : tagNames.slice(0, maxVisible);

    return (
      <div className={cx('artifact-tags', { 'tags-tooltip': isTooltip })}>
        {visibleTagNames.map((tagName, index) => {
          return (
            <p
              key={tagName}
              className="tag-label"
              style={{
                backgroundColor: CHART_COLORS[index % CHART_COLORS.length]
              }}
            >
              {tagName}
            </p>
          );
        })}
        {isHiddenTags && !isTooltip ? '...' : ''}
      </div>
    );
  };

  return (
    <StyledTooltip
      title={renderTags(true)}
      disableHoverListener={!isHiddenTags}
      minWidth="fit-content"
    >
      {renderTags()}
    </StyledTooltip>
  );
};

ArtifactTags.defaultProps = {
  maxVisible: 5,
  tagNames: []
};

ArtifactTags.propTypes = {
  maxVisible: PropTypes.number,
  tagNames: PropTypes.array
};

export default ArtifactTags;
