// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenuAppVersion-module__userMenuVersion--RDlQ1{display:flex;align-items:center;justify-content:center;height:36px;text-transform:uppercase;gap:8px;cursor:pointer}.UserMenuAppVersion-module__userMenuVersion--RDlQ1 span{font-weight:700;font-size:11px;color:#b3b9c8}.UserMenuAppVersion-module__userMenuVersion--RDlQ1 svg{width:10px;height:10px;color:#b3b9c8}.UserMenuAppVersion-module__userMenuVersion--RDlQ1 .UserMenuAppVersion-module__successIcon--LPD2t{color:#00d41f}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenuVersion": `UserMenuAppVersion-module__userMenuVersion--RDlQ1`,
	"successIcon": `UserMenuAppVersion-module__successIcon--LPD2t`
};
module.exports = ___CSS_LOADER_EXPORT___;
