import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes, snackbarTypes } from '../../../constants/alertTypes';
import history from '../../../history';
import { WORKSPACE_URL_HASHES } from '../../../constants/workspaceConstants';

const deleteModelRegistry = async (workspace, modelName) => {
  const { data = {} } =
    (await modelRegistryApi.deleteModel(workspace, modelName)) || {};

  return data?.notes;
};

const useModelRegistryDeleteMutation = workspace => {
  const dispatch = useDispatch();

  return useMutation(modelName => deleteModelRegistry(workspace, modelName), {
    onSuccess: async () => {
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_API_RESPONSE,
          'Successfully deleted model.'
        )
      );

      history.push(`/${workspace}${WORKSPACE_URL_HASHES.MODEL_REGISTRY}`);
    },
    onError: () =>
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_API,
          'There was an error deleting your model.'
        )
      )
  });
};

export default useModelRegistryDeleteMutation;
