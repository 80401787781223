import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ExpandIcon = ({ expanded }) => {
  return (
    <div
      className={cx('react-grid-group-icon', {
        'react-grid-group-icon-expanded': expanded
      })}
    >
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.28074 5.59938C5.65506 5.28745 5.65506 4.71255 5.28074 4.40062L1.2797 1.06642C0.771525 0.642938 0 1.0043 0 1.6658L0 8.3342C0 8.9957 0.771526 9.35706 1.2797 8.93358L5.28074 5.59938Z" />
      </svg>
    </div>
  );
};

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired
};

export default ExpandIcon;
