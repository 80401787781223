import { Slider } from '@design-system-outdated/components';
import { MAX_DECIMAL_PRECISION } from '@experiment-management-shared/constants/experimentGridConstants';
import { ReportTable } from '@reports/types';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './DecimalPrecisionSlider.module.scss';

type DecimalPrecisionSliderProps = {
  onChange: (tableUpdates: ReportTable) => void;
  table: ReportTable;
};

const DecimalPrecisionSlider = ({
  onChange,
  table
}: DecimalPrecisionSliderProps) => {
  const { decimalPrecision } = table;

  const [debouncedDecimalPrecision, setDebouncedDecimalPrecision] = useState(
    decimalPrecision ?? MAX_DECIMAL_PRECISION
  );

  return (
    <div className={styles.root}>
      <div className={styles.label}>Decimal Precision</div>

      <div className={styles.sliderContainer}>
        <Slider
          max={MAX_DECIMAL_PRECISION}
          value={debouncedDecimalPrecision}
          onChange={(value: number) => setDebouncedDecimalPrecision(value)}
          onChangeCommitted={(value: number) => {
            onChange({
              ...table,
              decimalPrecision: value
            });
          }}
        />
      </div>
    </div>
  );
};

DecimalPrecisionSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired
};

export default DecimalPrecisionSlider;
