import { getIsCollaborationEnabled } from '@/reducers/ui/workspaceUiReducer';
import useProject from '@API/project/useProject';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import { ProjectHeaderRoute } from '@shared/components/ProjectPageHeader/types';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

export type EMTabPathType = typeof EXPERIMENT_TAB_PATHNAME[keyof typeof EXPERIMENT_TAB_PATHNAME];
type EMProjectRouteMap = Record<
  EMTabPathType,
  {
    path: string;
    isHidden: boolean;
  }
>;

const useEMProjectRoutes = (): ProjectHeaderRoute[] => {
  const { projectName, workspace, viewId } = useParams<{
    projectName: string;
    workspace: string;
    viewId?: string;
  }>();

  const {
    data: { canEdit, isGeneral }
  } = useProject();

  const isCollaborationEnabled = useSelector(getIsCollaborationEnabled);

  let basePath = `/${workspace}/${projectName}`;
  if (viewId) {
    basePath = `${basePath}/view/${viewId}`;
  }

  const routesMap: EMProjectRouteMap = {
    [EXPERIMENT_TAB_PATHNAME.panels]: {
      path: `${basePath}/${EXPERIMENT_TAB_PATHNAME.panels}`,
      isHidden: false
    },
    [EXPERIMENT_TAB_PATHNAME.experiments]: {
      path: `${basePath}/${EXPERIMENT_TAB_PATHNAME.experiments}`,
      isHidden: false
    },
    [EXPERIMENT_TAB_PATHNAME.notes]: {
      path: `/${workspace}/${projectName}/${EXPERIMENT_TAB_PATHNAME.notes}`,
      isHidden: false
    },
    [EXPERIMENT_TAB_PATHNAME.archive]: {
      path: `${basePath}/${EXPERIMENT_TAB_PATHNAME.archive}`,
      isHidden: !canEdit
    },
    [EXPERIMENT_TAB_PATHNAME.manage]: {
      path: `${basePath}/panels#${EXPERIMENT_TAB_PATHNAME.manage}`,
      isHidden: !isCollaborationEnabled || isGeneral || !canEdit
    },
    [EXPERIMENT_TAB_PATHNAME.reports]: {
      path: `/${workspace}/${projectName}/${EXPERIMENT_TAB_PATHNAME.reports}`,
      isHidden: false
    }
  };

  return Object.keys(routesMap)
    .filter(routeName => !routesMap[routeName].isHidden)
    .map(routeName => ({
      id: routeName,
      name: startCase(routeName),
      ...routesMap[routeName]
    }));
};

export default useEMProjectRoutes;
