import { QueryKey, useMutation, useQueryClient } from 'react-query';
import assetsApi from '../../util/assetsApi';
import alertsUtil from '../../util/alertsUtil';
import { dialogTypes } from '../../constants/alertTypes';
import { useDispatch } from 'react-redux';
import { AssetData } from '@experiment-management-shared/types';

export type AssetRenamePayload = {
  assetId: string;
  experimentKey: string;
  newAssetName: string;
};

const ASSETS_FOR_EXPERIMENTS = 'assetsForExperiments';
const ASSETS_FOR_EXPERIMENT = 'assetsForExperiment';

const useRenameAsset = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateQueryData = (
    queryKey: QueryKey,
    newAssetName: string,
    assetId: string
  ) => {
    const previousData = queryClient.getQueryData(queryKey);

    queryClient.setQueryData<AssetData[]>(queryKey, oldData => {
      if (!oldData) return [];
      return oldData.map(asset => {
        if (asset.assetId === assetId) {
          return { ...asset, fileName: newAssetName };
        }
        return asset;
      });
    });

    return previousData;
  };

  return useMutation(
    (payload: AssetRenamePayload) => assetsApi.renameAsset(payload),
    {
      onMutate: async variables => {
        await queryClient.cancelQueries([
          ASSETS_FOR_EXPERIMENT,
          { experimentKey: variables.experimentKey }
        ]);
        await queryClient.cancelQueries(ASSETS_FOR_EXPERIMENTS);

        const previousAssetsForExperiment = updateQueryData(
          [ASSETS_FOR_EXPERIMENT, { experimentKey: variables.experimentKey }],
          variables.newAssetName,
          variables.assetId
        );

        const previousAssetsForExperiments = updateQueryData(
          [ASSETS_FOR_EXPERIMENTS, { experimentKey: variables.experimentKey }],
          variables.newAssetName,
          variables.assetId
        );

        return { previousAssetsForExperiment, previousAssetsForExperiments };
      },
      onSettled: (data, error, { experimentKey }, context) => {
        if (error) {
          queryClient.setQueryData(
            [ASSETS_FOR_EXPERIMENT, { experimentKey }],
            context?.previousAssetsForExperiment
          );
          queryClient.setQueryData(
            [ASSETS_FOR_EXPERIMENTS, { experimentKey }],
            context?.previousAssetsForExperiments
          );

          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_FETCH_ASSETS_FOR_EXPERIMENT,
              'There was an error renaming the asset.'
            )
          );
        } else {
          queryClient.invalidateQueries([
            ASSETS_FOR_EXPERIMENT,
            { experimentKey }
          ]);
          queryClient.invalidateQueries([
            ASSETS_FOR_EXPERIMENTS,
            { experimentKey }
          ]);
        }
      }
    }
  );
};

export default useRenameAsset;
