// eslint-disable-next-line import/no-unresolved
import semver from 'semver';
import isEmpty from 'lodash/isEmpty';
import { SUB_PATHS } from '@/constants/urlConstants';

export const findModelDirInAssets = (experimentAssets, modelName) =>
  experimentAssets.filter(({ dir }) => dir.includes(modelName));

export const getPlaceholderForModelVersion = vers =>
  (vers && semver.inc(vers, 'minor')) || '1.0.0';

export const getRegisteredModelURL = (workspace, modelName) => {
  return `/${workspace}/${SUB_PATHS.MODEL_REGISTRY}/${encodeURIComponent(
    modelName
  )}`;
};

export const getModelNameValidationTooltipText = (
  newModelName,
  modelNameValidation
) => {
  const { finalModelName, modelNameExists, originalModelName } =
    modelNameValidation || {};

  if (!isEmpty(originalModelName) && isEmpty(finalModelName)) {
    return 'A model name cannot contain only special characters';
  }

  if (modelNameExists) {
    return 'This name already exist, choose another name.';
  }

  if (!finalModelName || finalModelName === newModelName) return '';

  return `Your model will be named ${finalModelName}`;
};
