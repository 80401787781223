import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Resizable } from 're-resizable';
import { EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT } from '@experiment-management-shared/constants/experimentConstants';

import debounce from 'lodash/debounce';

import DashboardTable from '@projects/components/DashboardTable';
import EmptySectionMessage from '@projects/components/EmptySectionMessage';
import {
  MAX_COLUMN_NAME_WIDTH,
  MIN_COLUMN_NAME_WIDTH,
  getNameColumnWidth,
  hasDashboardAnyRule,
  hasDashboardAnyGrouping,
  hasDashboardTableSearch
} from '@experiment-management-shared/utils/EMView';
import { COMET_HEADER_HEIGHT } from '@/constants/generalConstants';
import visualizationsActions from '@/actions/visualizationsActions';

import { PanelsIcon } from '@shared/utils/svgIcons';
import SmallLoader from '@shared/components/SmallLoader';

import DashboardChartsWrapper from './DashboardChartsWrapper';
import { useExperimentEmptyStateConfigs } from '@projects/hooks';

const DASHBOARD_WRAPPER_PADDING = 10;
const ACTIONS_FOOTER_HEIGHT = 60;
const STICKY_HEADER_HEIGHT = 52;
const HEADERS_HEIGHT = COMET_HEADER_HEIGHT + STICKY_HEADER_HEIGHT;
const SCROLL_THRESHOLD = 30;
// where 51px width of select and pinned columns
const SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN = 37;
const MAX_SIDEBAR_WIDTH =
  SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN + MAX_COLUMN_NAME_WIDTH;
const MIN_SIDEBAR_WIDTH =
  SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN + MIN_COLUMN_NAME_WIDTH;

const SCROLL = { DOWN: 'down', UP: 'up' };

const SIDEBAR_COLUMNS = ['pinned', 'Name'];

const GRID_CONFIG = {
  allowColumnReordering: false,
  hideColumnsHeader: true,
  resizingEnabled: false
};

const PanelsPage = (
  {
    availableColumns,
    activeExperimentsHeaderTab,
    dashboard,
    displayGroups,
    experimentsGroupsMap,
    groupAggregations,
    experiments,
    panelExperiments,
    isArchive,
    isFetchingExperiments,
    isFetchingExperimentGroups,
    onSave,
    isPreviousDataExperiments,
    onChangeDashboard,
    onChangeHeaderVisibility,
    pageTotalGrouped,
    selectedExperiments,
    totalExperiments
  },
  exportRef
) => {
  const dispatch = useDispatch();
  const { projectName } = useParams();
  const currentScrollBehaviorRef = useRef(SCROLL.DOWN);
  const prevScrollPosition = useRef(window.scrollY);
  const pageRef = useRef(null);
  const sidebarRef = useRef(null);
  const allowSorting = activeExperimentsHeaderTab === 0;
  const hasSelectedExperiments = dashboard.table.selection.length > 0;

  const reactGridProps = useMemo(() => {
    return {
      ...GRID_CONFIG,
      allowSorting,
      displayGroups
    };
  }, [allowSorting, displayGroups]);

  useEffect(() => {
    const updateSidebarHeight = () => {
      if (!pageRef.current) return;

      const footerHeight = hasSelectedExperiments ? ACTIONS_FOOTER_HEIGHT : 0;
      let headerVisibleHeight = Math.max(HEADERS_HEIGHT - window.scrollY, 0);

      if (currentScrollBehaviorRef.current === SCROLL.UP) {
        headerVisibleHeight = Math.max(
          headerVisibleHeight,
          STICKY_HEADER_HEIGHT
        );
      }

      pageRef.current.style.setProperty(
        '--page-content-height',
        `calc(100vh - ${headerVisibleHeight + footerHeight}px)`
      );

      // onChangeHeaderVisibility is the react useState hook,
      // here is used trick to reuse existing scroll  handlers,
      // and not re attach them when isHeaderSticky is changed
      onChangeHeaderVisibility(value => {
        const opacity =
          window.scrollY >=
          DASHBOARD_WRAPPER_PADDING +
            COMET_HEADER_HEIGHT +
            (value ? 0 : STICKY_HEADER_HEIGHT)
            ? '1'
            : '0';
        pageRef.current.style.setProperty('--sticky-border-opacity', opacity);
        return value;
      });
    };

    const handleScroll = () => {
      if (!pageRef.current) return;

      // scrolls up
      if (prevScrollPosition.current > window.scrollY + SCROLL_THRESHOLD) {
        pageRef.current.style.setProperty(
          '--header-offset',
          `${STICKY_HEADER_HEIGHT}px`
        );
        onChangeHeaderVisibility(true);
        currentScrollBehaviorRef.current = SCROLL.UP;
        prevScrollPosition.current = window.scrollY;
      }

      // scrolls down
      if (prevScrollPosition.current < window.scrollY - SCROLL_THRESHOLD) {
        pageRef.current.style.setProperty('--header-offset', 0);
        onChangeHeaderVisibility(false);
        currentScrollBehaviorRef.current = SCROLL.DOWN;
        prevScrollPosition.current = window.scrollY;
      }

      updateSidebarHeight();
    };

    window.addEventListener('scroll', handleScroll);

    updateSidebarHeight();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasSelectedExperiments, onChangeHeaderVisibility]);

  useEffect(() => {
    return () => onChangeHeaderVisibility(false);
  }, [onChangeHeaderVisibility]);

  const setHoveredExperimentTrace = useCallback(
    experimentKey => {
      dispatch(
        visualizationsActions.setHoveredExperimentTrace({ experimentKey })
      );
    },
    [dispatch]
  );

  const handleHoverExperimentNameCell = useMemo(
    () =>
      debounce(setHoveredExperimentTrace, EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT),
    [setHoveredExperimentTrace]
  );

  useEffect(() => {
    return () => {
      handleHoverExperimentNameCell.cancel();
    };
  }, []);

  const displayColumnOrders = useMemo(() => {
    return [...SIDEBAR_COLUMNS, ...displayGroups];
  }, [displayGroups]);

  const dashboardTable = useMemo(() => {
    return {
      ...dashboard.table,
      displayColumnOrders
    };
  }, [dashboard.table, displayColumnOrders]);

  const renderLoading = () => {
    return (
      <div id="table" className="table-wrapper">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={
            <span>
              Fetching project data for <b>{projectName}</b>
            </span>
          }
        />
      </div>
    );
  };

  const hasNoExperiments =
    !experiments.length &&
    !(
      hasDashboardAnyRule(dashboard) ||
      hasDashboardTableSearch(dashboard?.table)
    ) &&
    !hasDashboardAnyGrouping(dashboard);

  const emptyState = useExperimentEmptyStateConfigs(false);

  if (hasNoExperiments) {
    if (isPreviousDataExperiments && isFetchingExperiments) {
      return renderLoading();
    }

    return (
      <EmptySectionMessage
        buttonText={emptyState.buttonText}
        description={emptyState.description}
        icon={<PanelsIcon />}
        title="This project has no experiments"
        ButtonProps={{
          onClick: emptyState.onButtonClick
        }}
      />
    );
  }

  const handleSidebarResize = () => {
    const nameColumnWidth =
      sidebarRef.current.clientWidth - SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN;
    const newColumnWidths = dashboard.table.columnWidths.map(column => {
      if (column.columnName !== 'Name') return column;

      return { columnName: 'Name', width: nameColumnWidth };
    });

    onChangeDashboard({
      table: {
        columnWidths: newColumnWidths
      }
    });
  };

  const nameWidth = getNameColumnWidth(dashboard.table.columnWidths);
  const sidebarWidth = nameWidth + SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN;

  return (
    <div className="panels-page" ref={pageRef}>
      <Resizable
        className="panels-page-experiments-resizable"
        size={{ width: sidebarWidth }}
        minWidth={MIN_SIDEBAR_WIDTH}
        maxWidth={MAX_SIDEBAR_WIDTH}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        style={{
          '--max-no-data-cell-width': `${sidebarWidth}px`,
          '--composed-name-column-width': `${sidebarWidth}px`
        }}
        handleStyles={{ right: { zIndex: 100 } }}
        onResizeStop={handleSidebarResize}
      >
        <DashboardTable
          availableColumns={availableColumns}
          className="panels-page-experiments"
          dashboardTable={dashboardTable}
          dashboardQuery={dashboard?.query}
          hiddenExperimentKeys={dashboard?.hiddenExperimentKeys}
          pinnedExperimentKeys={dashboard?.pinnedExperimentKeys}
          experimentsGroupsMap={experimentsGroupsMap}
          groupAggregations={groupAggregations}
          experiments={experiments}
          onSave={onSave}
          isArchive={isArchive}
          isFetchingExperiments={
            isFetchingExperiments || isFetchingExperimentGroups
          }
          isPreviousDataExperiments={isPreviousDataExperiments}
          onChange={onChangeDashboard}
          onHoverExperimentNameCell={handleHoverExperimentNameCell}
          pageTotalGrouped={pageTotalGrouped}
          ref={{ ref: sidebarRef, exportRef }}
          selectedExperiments={selectedExperiments}
          totalExperiments={totalExperiments}
          ReactGridProps={reactGridProps}
        />
      </Resizable>

      <DashboardChartsWrapper
        dashboard={dashboard}
        experiments={panelExperiments}
        onChange={onChangeDashboard}
      />
    </div>
  );
};

const PanelsPageRef = forwardRef(PanelsPage);

PanelsPageRef.defaultProps = {
  availableColumns: []
};

PanelsPageRef.propTypes = {
  availableColumns: PropTypes.array,
  activeExperimentsHeaderTab: PropTypes.number.isRequired,
  dashboard: PropTypes.object.isRequired,
  displayGroups: PropTypes.array.isRequired,
  experimentsGroupsMap: PropTypes.object,
  groupAggregations: PropTypes.object,
  experiments: PropTypes.array.isRequired,
  isArchive: PropTypes.bool.isRequired,
  isFetchingExperiments: PropTypes.bool.isRequired,
  isFetchingExperimentGroups: PropTypes.bool.isRequired,
  isPreviousDataExperiments: PropTypes.bool.isRequired,
  onChangeDashboard: PropTypes.func.isRequired,
  onChangeHeaderVisibility: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  pageTotalGrouped: PropTypes.number,
  panelExperiments: PropTypes.array.isRequired,
  selectedExperiments: PropTypes.array.isRequired,
  totalExperiments: PropTypes.number.isRequired
};

export default PanelsPageRef;
