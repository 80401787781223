// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepSlider-module__dsStepSliderFlex--BDfBH{display:flex;flex-direction:column}.StepSlider-module__dsStepSliderRow--yepI4{height:36px}.StepSlider-module__dsStepSliderContainer--xsdUF{min-width:150px}.StepSlider-module__dsStepSliderSlider--EX1UB{margin-top:12px;margin-right:0 !important;margin-left:3px}.StepSlider-module__dsStepSliderLabelContainer--Ir7Y7{padding:0 2px;text-align:center;display:inline-block;cursor:pointer;position:relative;height:27px;line-height:27px;min-width:30px;white-space:nowrap;overflow:hidden;vertical-align:bottom;font-size:14px;margin-left:4px;margin-right:-4px;color:var(--gray-6)}.StepSlider-module__dsStepSliderLabelContainer--Ir7Y7:not(.StepSlider-module__show-border--sscGQ){border:0 !important}.StepSlider-module__dsStepSliderLabelContainer--Ir7Y7.StepSlider-module__show-border--sscGQ{border-bottom:1px solid #b7b7b7}.StepSlider-module__dsStepSliderLabelContainerInput--q1IVr{width:33px}.StepSlider-module__dsStepSliderInput--cjuE_{min-width:10px;text-align:center;border:0 !important}.StepSlider-module__dsStepSliderAnimate--wFXCv{display:inline-block;text-align:center;margin-left:5px;width:20px;margin-top:6px}.StepSlider-module__dsStepSliderAnimateButton--kTzN4{font-size:18px;vertical-align:middle;text-align:center;display:flex;color:var(--gray-6);width:15px;height:15px;border-radius:50%;border:1px solid var(--gray-6)}.StepSlider-module__dsStepSliderAnimateButton--kTzN4 svg{width:13px;height:13px}.StepSlider-module__dsStepSliderAnimateButton--kTzN4:hover{color:var(--primary-color);border:1px solid var(--primary-color)}.StepSlider-module__dsStepSliderAnimateButton--kTzN4:hover svg{color:var(--primary-color);fill:var(--primary-color)}.StepSlider-module__dsStepSliderReset--SHjIL{text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsStepSliderFlex": `StepSlider-module__dsStepSliderFlex--BDfBH`,
	"dsStepSliderRow": `StepSlider-module__dsStepSliderRow--yepI4`,
	"dsStepSliderContainer": `StepSlider-module__dsStepSliderContainer--xsdUF`,
	"dsStepSliderSlider": `StepSlider-module__dsStepSliderSlider--EX1UB`,
	"dsStepSliderLabelContainer": `StepSlider-module__dsStepSliderLabelContainer--Ir7Y7`,
	"show-border": `StepSlider-module__show-border--sscGQ`,
	"dsStepSliderLabelContainerInput": `StepSlider-module__dsStepSliderLabelContainerInput--q1IVr`,
	"dsStepSliderInput": `StepSlider-module__dsStepSliderInput--cjuE_`,
	"dsStepSliderAnimate": `StepSlider-module__dsStepSliderAnimate--wFXCv`,
	"dsStepSliderAnimateButton": `StepSlider-module__dsStepSliderAnimateButton--kTzN4`,
	"dsStepSliderReset": `StepSlider-module__dsStepSliderReset--SHjIL`
};
module.exports = ___CSS_LOADER_EXPORT___;
