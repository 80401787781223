import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import loginActions from '@/actions/loginActions';
import { getIsUserLoggedInByAll } from '@/reducers/userReducer';
import { useActiveWorkspaceName } from '@shared/hooks';

import useClaimAnonymousExperimentMutation from '@experiment-management-shared/api/useClaimAnonymousExperimentMutation';
import { SimpleModal } from '@shared/components/SimpleModal';
import classNames from './AnonymousMode.module.scss';

type AnonymousModeClaimProps = {
  open: boolean;
  onClose: () => void;
  openSuccess: () => void;
};

export default function AnonymousModeClaim({
  open,
  openSuccess,
  onClose
}: AnonymousModeClaimProps) {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(getIsUserLoggedInByAll);
  const currentActiveWorkspaceName = useActiveWorkspaceName();
  const claimAnonymousExperimentMutation = useClaimAnonymousExperimentMutation();

  const handleClickButton = useCallback(() => {
    if (!isUserLoggedIn) {
      dispatch(loginActions.openRegistrationModal());
    } else {
      claimAnonymousExperimentMutation.mutate({
        workspaceName: currentActiveWorkspaceName,
        isNewUser: false
      });
      onClose();
      openSuccess();
    }
  }, [isUserLoggedIn, currentActiveWorkspaceName]);

  return (
    <SimpleModal
      dataTest="anonymous-user-modal"
      title="Claim anonymous experiment"
      primaryButtonText="Claim"
      secondaryButtonText="Leave unclaimed"
      open={open}
      onClose={onClose}
      onSubmit={handleClickButton}
    >
      <p className={classNames.anonymousModeClaimContent}>
        <b>This experiment expires in 30 days.</b> <br />
        By claiming <b>{currentActiveWorkspaceName}</b> all projects and
        experiments will be transferred to your account.
      </p>
    </SimpleModal>
  );
}
