export const MAX_LENGTH_EXPERIMENT_KEY = 7;
export const MAX_LENGTH_EXPERIMENT_NAME_FROM_KEY = 9;
export const NUM_OF_PERCENT_CHANGE_DECIMALS = 2;
export const DEFAULT_DECIMAL_PRECISION = 3;

export const SORT_BY_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};

export const TABLE_COLUMN_KEYS = {
  KEY: 'key',
  EXPERIMENT: 'experiment',
  VALUE_MIN: 'valueMin',
  VALUE_MAX: 'valueMax',
  VALUE_CURRENT: 'valueCurrent'
};

export const TABLE_COLUMN_HEADERS = [
  {
    id: TABLE_COLUMN_KEYS.KEY,
    label: 'KEY',
    isSortable: true,
    isRequired: true
  },
  {
    id: TABLE_COLUMN_KEYS.EXPERIMENT,
    label: 'EXPERIMENT',
    isRequired: true
  },
  {
    id: TABLE_COLUMN_KEYS.VALUE_MIN,
    label: 'MIN VALUE'
  },
  {
    id: TABLE_COLUMN_KEYS.VALUE_MAX,
    label: 'MAX VALUE'
  },
  {
    id: TABLE_COLUMN_KEYS.VALUE_CURRENT,
    label: 'VALUE'
  }
];
