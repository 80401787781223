import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const userApi = {
  fetchUser({ apiKey: apiKeyFromPathname, workspaceName } = {}) {
    const url = `${BASE_API_URL}auth/test?apiKey=${apiKeyFromPathname}${
      workspaceName ? '&workspaceName=' + workspaceName : ''
    }`;
    const options = {
      withCredentials: true
    };
    return axios.get(url, options);
  },

  generateApiKey(apiKey) {
    const url = `${BASE_API_URL}auth/api/rotate?oldApiKey=${apiKey}`;
    return axios.get(url);
  },

  loginUser(data, workspaceCode = null) {
    let url = `${BASE_API_URL}auth/login`;
    if (workspaceCode) {
      url = `${url}?workspaceCode=${workspaceCode}`;
    }
    return axios(url, {
      method: 'post',
      data: JSON.stringify(data),
      withCredentials: true
    });
  },

  createUser(data, workspaceCode = null) {
    let url = `${BASE_API_URL}auth/new`;
    if (workspaceCode) {
      url = `${url}?workspaceCode=${workspaceCode}`;
    }
    return axios.post(url, JSON.stringify(data), {
      withCredentials: true
    });
  },

  changeEmail({ newEmail, password }) {
    const url = `${BASE_API_URL}auth/email/change-start`;
    return axios.post(url, { newEmail, password });
  },

  changeProfilePicture(file) {
    const url = `${BASE_API_URL}upload/profile`;
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  logout() {
    return axios.get(`${BASE_API_URL}auth/logout`);
  },

  addUserInfoPostSignup(data) {
    const url = `${BASE_API_URL}postsignup/user-info`;
    return axios.post(url, JSON.stringify(data));
  },

  getOptInNotification() {
    const url = `${BASE_API_URL}notifications/user/get`;
    return axios.get(url);
  },

  getOptInMpmNotification() {
    const url = `${BASE_API_URL}notifications/mpm/user/get`;
    return axios.get(url);
  },

  setOptInNotification(optIn) {
    const url = `${BASE_API_URL}notifications/user/update?optIn=${optIn}`;
    return axios.post(url);
  },

  setMpmOptInNotification(optIn) {
    const url = `${BASE_API_URL}notifications/mpm/user/update?optIn=${optIn}`;
    return axios.post(url);
  },

  updateUserFlags({ flags, username }) {
    const url = `${BASE_API_URL}user/${username}/settings`;
    return axios.patch(url, flags);
  },

  getUserSettings({ username, signal }) {
    const url = `${BASE_API_URL}user/${username}/settings`;
    return axios.get(url, {
      signal
    });
  }
};

export default userApi;
