import { ReportEditMode } from '@routes/types';

export const REPORTS_BASE = '/:workspace/:projectName/reports';

export const REPORTS_TABLE = REPORTS_BASE;

export const REPORT_VIEW = `${REPORTS_BASE}/:reportName`;

export const REPORT_EDIT = `${REPORTS_BASE}/:reportName/:editMode`;

export const REPORT_TEMPLATE_VIEW = `${REPORTS_BASE}/template/:reportName`;

export const REPORT_TEMPLATE_EDIT = `${REPORTS_BASE}/template/:reportName/:editMode`;

export const REPORT_EDIT_MODES: Record<string, ReportEditMode> = {
  EDIT: 'edit',
  PREVIEW: 'preview',
  VIEW: ''
} as const;
