if (typeof global === 'undefined') {
  window.global = window;
}

if (!global.environmentVariablesOverwrite) {
  console.error('Missing config file in public dir');
}

/* Global variable from config.js */
const overwriteVariables = global.environmentVariablesOverwrite;

if (typeof overwriteVariables === 'object') {
  Object.entries(overwriteVariables).forEach(([key, value]) => {
    process.env[key] = value;
  });
}
