import Markdown from '@reports/components/Markdown';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ReportDescription.module.scss';

const ReportDescription = ({ description, editMode, onChange }) => {
  return (
    <div
      className={cx(styles.description, {
        [styles.viewOnly]: editMode !== REPORT_EDIT_MODES.EDIT
      })}
    >
      <Markdown
        editable={editMode === REPORT_EDIT_MODES.EDIT}
        markdown={description}
        onChange={onChange}
        placeholder="Add description text/markdown..."
      />
    </div>
  );
};

ReportDescription.propTypes = {
  description: PropTypes.string.isRequired,
  editMode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReportDescription;
