import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { goBack } from '@shared/utils/url';

import { isIframed } from '@/helpers/generalHelpers';
import LoginModal from '@auth/components/LoginModal';

const customTitles = {
  academic: 'Sign up for Comet with a free academic account',
  startups: 'Sign up for Comet with a free startup account'
};

const SignupPage = ({ workspaceCode }) => {
  const queryParams = queryString.parse(window.location.search);
  const { title, plan } = queryParams;
  const isInIframe = isIframed();

  const customTitle = customTitles[plan] || title;

  return (
    <div className="full-page-view signup-page">
      <LoginModal
        cancelText="Back"
        customTitle={customTitle}
        isModal={false}
        onClose={goBack}
        open
        showLogin={false}
        showRegister
        workspaceCode={workspaceCode}
        DialogProps={{
          disableBackdropClick: true,
          hideBackdrop: true,
          disableAutoFocus: isInIframe,
          disableEnforceFocus: isInIframe
        }}
      />
    </div>
  );
};

SignupPage.defaultProps = {
  workspaceCode: null
};

SignupPage.propTypes = {
  workspaceCode: PropTypes.string
};

export default SignupPage;
