// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldsList-module__dsFieldsListAddBtn--ujfEO{margin-top:12px !important;font-size:14px !important;padding:0 !important;background-color:rgba(0,0,0,0) !important;background:rgba(0,0,0,0) !important;color:var(--primary-color);min-width:40px}.FieldsList-module__dsFieldsListAddBtn--ujfEO .MuiButton-label{color:var(--primary-color)}.FieldsList-module__dsFieldsListAddBtn--ujfEO:hover{color:var(--primary-color-hover)}.FieldsList-module__dsFieldsListAddBtnDisabled--alIHs .MuiButton-label{color:var(--gray-4)}.FieldsList-module__dsFieldsListRemoveBtn--G4ZZK{margin:0 auto;padding:0 !important;background-color:rgba(0,0,0,0) !important;background:rgba(0,0,0,0) !important;min-width:40px}.FieldsList-module__dsFieldsListRemoveBtnWrapper--NCxn1{margin-top:8px !important}.FieldsList-module__dsFieldsListRemoveBtn--G4ZZK svg{color:var(--gray-4)}.FieldsList-module__dsFieldsListRemoveBtn--G4ZZK:disabled svg{color:var(--gray-3)}.FieldsList-module__dsFieldsListRemoveBtn--G4ZZK:hover svg{color:var(--gray-6)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsFieldsListAddBtn": `FieldsList-module__dsFieldsListAddBtn--ujfEO`,
	"dsFieldsListAddBtnDisabled": `FieldsList-module__dsFieldsListAddBtnDisabled--alIHs`,
	"dsFieldsListRemoveBtn": `FieldsList-module__dsFieldsListRemoveBtn--G4ZZK`,
	"dsFieldsListRemoveBtnWrapper": `FieldsList-module__dsFieldsListRemoveBtnWrapper--NCxn1`
};
module.exports = ___CSS_LOADER_EXPORT___;
