import { dashboardChartActionTypes } from '@/constants/actionTypes';

const dashboardChartsActions = {
  updateChartFormKey(chartType, key, updatedValue) {
    return dispatch => {
      const updatedForm = {
        [key]: updatedValue
      };

      dispatch({
        type: dashboardChartActionTypes.UPDATE_CHART_FORM,
        payload: {
          chartType,
          updatedForm
        }
      });
    };
  },

  updateChartForm(chartType, updatedForm) {
    return dispatch => {
      dispatch({
        type: dashboardChartActionTypes.UPDATE_CHART_FORM,
        payload: {
          chartType,
          updatedForm
        }
      });
    };
  },

  resetChartFormByType(chartType) {
    return dispatch => {
      dispatch({
        type: dashboardChartActionTypes.RESET_CHART_FORM_BY_TYPE,
        payload: chartType
      });
    };
  },

  resetChartForm() {
    return dispatch => {
      dispatch({
        type: dashboardChartActionTypes.RESET_ENTIRE_CHART_FORM
      });
    };
  },

  setActiveSectionId(sectionId) {
    return dispatch => {
      dispatch({
        type: dashboardChartActionTypes.SET_ACTIVE_SECTION_ID,
        payload: {
          sectionId
        }
      });
    };
  }
};

export default dashboardChartsActions;
