import { Avatar } from '@DesignSystem/data-display';
import React from 'react';
import PropTypes from 'prop-types';
import { DSColors } from '@design-system-outdated/constants';

const MemberAvatar = ({ avatar, isWsOwner }) => {
  if (avatar) {
    return (
      <Avatar
        src={avatar}
        borderColor={isWsOwner && DSColors.orangeColor}
        width="32px"
      />
    );
  }

  return (
    <Avatar
      backgroundColor={DSColors.grayColor1}
      borderColor={isWsOwner && DSColors.orangeColor}
      width="32px"
      showLetters
      type="small"
      letters=""
      outlined
    />
  );
};

MemberAvatar.propTypes = {
  avatar: PropTypes.string,
  isWsOwner: PropTypes.bool
};

MemberAvatar.defaultProps = {
  avatar: null,
  isWsOwner: undefined
};

export default MemberAvatar;
