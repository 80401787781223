import React from 'react';
import cx from 'classnames';
import typography from '@ds-typography';

interface AssetChartEmptyViewProps {
  docLink: string;
  methodName: string;
  entityName: string;
  label: string;
}

const AssetChartEmptyView = ({
  docLink,
  methodName,
  entityName,
  label
}: AssetChartEmptyViewProps) => (
  <div className={cx(typography.dsLightXS, 'preview-empty-state-text')}>
    <div>{label}</div>
    <div className="preview-empty-state-description">
      <div>
        You can use your{' '}
        <a
          href="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/"
          target="_blank"
        >
          Experiment object
        </a>{' '}
        to log {entityName}
      </div>
      <div>
        using the{' '}
        <a href={docLink} target="_blank" rel="noreferrer">
          {methodName}
        </a>{' '}
        method
      </div>
    </div>
  </div>
);

export default AssetChartEmptyView;
