import { ExperimentDetails } from '@shared/types';
import { useMemo } from 'react';
import ComparisonTableKeyCell from '../cells/NewComparisonTableKeyCell';
import {
  COMPARISON_COLUMNS_NAME,
  KEY_TITLE
} from '../newComparisonTableConfig';
import ComparisonTableValueCell from '../cells/NewComparisonTableValueCell';
import { Column } from '@devexpress/dx-react-grid-export';
import { ColumnBands } from '@devexpress/dx-react-grid';
import { AllowedCompareKeys } from '../types';
import { getExperimentValueName } from '../utils';

type UseTableColumnNamesOpts = {
  experiments: ExperimentDetails[];
  valueKeys: AllowedCompareKeys[];
};
const useTableColumnNames = ({
  experiments,
  valueKeys
}: UseTableColumnNamesOpts) => {
  const columns: Column[] = useMemo(() => {
    const result = [{ name: COMPARISON_COLUMNS_NAME.KEY, title: KEY_TITLE }];

    const experimentColumns = valueKeys
      .map(valueKey =>
        experiments.map(experiment => ({
          name: getExperimentValueName(valueKey, experiment.experimentKey),
          title: experiment.Name || experiment.experimentKey,
          color: experiment.comet_chart_color as string
        }))
      )
      .flat();

    result.push(...experimentColumns);

    return result;
  }, [experiments, valueKeys]);

  const experimentKeys = useMemo(
    () => experiments.map(experiment => experiment.experimentKey),
    [experiments]
  );

  const experimentColumnNames = useMemo(
    () =>
      valueKeys
        .map(valueKey =>
          experimentKeys.map(experimentKey =>
            getExperimentValueName(valueKey, experimentKey)
          )
        )
        .flat(),
    [experimentKeys, valueKeys]
  );

  const dataTypes = useMemo(
    () => [
      {
        cols: [COMPARISON_COLUMNS_NAME.KEY],
        cell: ComparisonTableKeyCell
      },
      {
        cols: experimentColumnNames,
        cell: ComparisonTableValueCell
      }
    ],
    [experimentColumnNames]
  );

  const columnBands: ColumnBands[] = useMemo(
    () => [
      {
        title: 'Last',
        children: experimentKeys.map(experimentKey => ({
          columnName: getExperimentValueName('valueCurrent', experimentKey)
        }))
      },
      {
        title: 'Min',
        children: experimentKeys.map(experimentKey => ({
          columnName: getExperimentValueName('valueMin', experimentKey)
        }))
      },
      {
        title: 'Max',
        children: experimentKeys.map(experimentKey => ({
          columnName: getExperimentValueName('valueMax', experimentKey)
        }))
      }
    ],
    [experimentKeys]
  );

  return {
    dataTypes,
    columns,
    columnBands
  };
};

export default useTableColumnNames;
