import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CopyButton } from '@design-system-outdated';
import styles from './IntegerSamples.module.scss';

const IntegerSamples = ({ samples, onCopyClick }) => {
  const list = samples.map((value, index) => {
    const key = `${value}-${index}`;

    return (
      <CopyToClipboard key={key} text={value}>
        <div className={styles.sampleInteger} onClick={onCopyClick}>
          {value}
          <div className={styles.iconWrapper}>
            <CopyButton text={value} tooltipText={null} />
          </div>
        </div>
      </CopyToClipboard>
    );
  });

  return <div className={styles.integerSamplesList}>{list}</div>;
};

IntegerSamples.propTypes = {
  onCopyClick: PropTypes.func.isRequired,
  samples: PropTypes.array.isRequired
};

export default IntegerSamples;
