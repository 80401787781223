import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import Tooltip from '@material-ui/core/Tooltip';

import { InputNodeIcon, OutputNodeIcon, DeleteNodeIcon } from '@Icons-outdated';
import classNames from './DAGContent.module.scss';
import { NODE_TYPES } from '../../constants';

type NodeTypesType = typeof NODE_TYPES;
type NodeTypeValuesType = NodeTypesType[keyof NodeTypesType];

export type NodeDataType = {
  id: string;
  inputModel: boolean;
  outputModel: boolean;
  title: string;
  onDeleteNode: (id: string) => void;
  onSetNodeType: (nodeId: string, nodeType: NodeTypeValuesType) => void;
};

export type ModelNodeProps = NodeProps<NodeDataType> & { preview?: boolean };

export function ModelNode({ data, preview = false }: ModelNodeProps) {
  const { title, id, inputModel, outputModel, onDeleteNode, onSetNodeType } =
    data || {};
  const [showTitleTooltip, setShowTitleTooltip] = useState(false);

  const titleRef = useCallback(node => {
    if (node !== null) {
      setShowTitleTooltip(node.offsetWidth < node.scrollWidth);
    }
  }, []);

  const deleteHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteNode(id);
  };

  const setTypeHandler = (
    event: React.MouseEvent<HTMLElement>,
    nodeId: string,
    nodeType: NodeTypeValuesType
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onSetNodeType(nodeId, nodeType);
  };

  return (
    <div
      data-test={`model-node-${title}`}
      className={classNames.modelNodeContainer}
    >
      <Handle
        type="target"
        position={Position.Left}
        id={id}
        className={classNames.reactFlowHandle}
        data-test={`${title}-left-handler`}
      />
      {inputModel && (
        <div className={cx(classNames.nodeLabelBox, classNames.inputLabel)}>
          <span>Input</span>
        </div>
      )}
      <div className={classNames.nodeMainSection}>
        <Tooltip
          title={showTitleTooltip ? title : ''}
          placement="top"
          arrow
          classes={{
            tooltip: classNames.iconTooltip,
            arrow: classNames.iconTooltipArrow
          }}
        >
          <span className={classNames.actionIconWrapper}>
            <div
              className={cx(classNames.nodeTitleSection, {
                [classNames.nodeTitlePreview]: preview
              })}
              ref={titleRef}
            >
              {title}
            </div>
          </span>
        </Tooltip>
        {!preview && (
          <div className={classNames.nodeControlsSection}>
            <span className={classNames.actionsWrapper}>
              <Tooltip
                title="Mark as Input"
                placement="top"
                arrow
                classes={{
                  tooltip: classNames.iconTooltip,
                  arrow: classNames.iconTooltipArrow
                }}
              >
                <span className={classNames.actionIconWrapper}>
                  <InputNodeIcon
                    className={cx({
                      [classNames.inputIcon]: inputModel
                    })}
                    data-test={`${title}-input-icon`}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      setTypeHandler(event, id, NODE_TYPES.INPUT)
                    }
                  />
                </span>
              </Tooltip>
              <Tooltip
                title="Mark as Output"
                placement="top"
                arrow
                classes={{
                  tooltip: classNames.iconTooltip,
                  arrow: classNames.iconTooltipArrow
                }}
              >
                <span className={classNames.actionIconWrapper}>
                  <OutputNodeIcon
                    className={cx({
                      [classNames.outputIcon]: outputModel
                    })}
                    data-test={`${title}-output-icon`}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      setTypeHandler(event, id, NODE_TYPES.OUTPUT)
                    }
                  />
                </span>
              </Tooltip>
            </span>
            <Tooltip
              title="Remove Node"
              placement="top"
              arrow
              classes={{
                tooltip: classNames.iconTooltip,
                arrow: classNames.iconTooltipArrow
              }}
            >
              <span className={classNames.actionIconWrapper}>
                <DeleteNodeIcon
                  className={classNames.deleteIcon}
                  onClick={deleteHandler}
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {outputModel && (
        <div className={cx(classNames.nodeLabelBox, classNames.outputLabel)}>
          <span>Output</span>
        </div>
      )}
      <Handle
        type="source"
        position={Position.Right}
        className={classNames.reactFlowHandle}
        data-test={`${title}-right-handler`}
      />
    </div>
  );
}
