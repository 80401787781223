import { AssetStep, ImageAssetData } from '@experiment-management-shared/types';
import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

type ImageAssetsData = {
  images: Omit<ImageAssetData, 'name'>[];
};

const getImagePanelData = async ({
  assetNames,
  experimentKeys,
  step
}: {
  assetNames: string[];
  experimentKeys: string[];
  step: AssetStep;
}) => {
  const { data } = await api.post<ImageAssetsData>('image/filter-data', {
    imageNames: assetNames,
    experiments: experimentKeys,
    step: step || 0
  });

  return data.images.map(image => ({
    ...image,
    name: image.figName
  }));
};

type ImagePanelDataQueryParams = {
  assetNames: string[];
  experimentKeys: string[];
  step: number | null;
};

export function useImagePanelData(
  { assetNames, experimentKeys, step }: ImagePanelDataQueryParams,
  config: QueryConfig<ImageAssetData[]>
) {
  const configEnabled = config?.enabled ?? true;
  const conditionsEnabled = !!assetNames?.length && !!experimentKeys?.length;

  return useQuery(
    [
      'imagePanelData',
      // copy and sort arrays to not trigger request every time order of parameters is changed
      {
        assetNames: assetNames.slice().sort(),
        experimentKeys: experimentKeys.slice().sort(),
        step
      }
    ],
    () => getImagePanelData({ assetNames, experimentKeys, step }),
    { ...config, enabled: configEnabled && conditionsEnabled }
  );
}
