import { useQuery } from 'react-query';

import { AlertNotification } from '@mpm-druid/types';
import api, { QueryConfig } from '@shared/api';

type MonitorNotification = {
  id: string;
  creationDate: string;
  resolvedDate: string;
  modelVersion: string | null;
  monitorId: string;
  monitorName: string;
  runCronExpression: string;
  status: 'RESOLVED' | 'OPEN';
};

type AlertNotificationsParams = {
  modelId: string;
  page: number;
  pageSize: number;
};

type AlertNotificationsAPI = {
  monitorNotifications: MonitorNotification[];
  resolvedNotificationsTotal: number;
  openNotificationsTotal: number;
};

// Hook response
type NotificationsResponse = {
  alertNotifications: AlertNotification[];
  resolvedNotificationsTotal: number;
  openNotificationsTotal: number;
};

const getAlertNotifications = async ({
  modelId,
  page,
  pageSize
}: AlertNotificationsParams) => {
  const { data } = await api.get<AlertNotificationsAPI>(
    `/mpm/v2/monitor-notification?modelId=${modelId}&page=${page}&pageSize=${pageSize}`
  );

  return {
    ...data,
    alertNotifications: data.monitorNotifications.map(
      (alertNotification: MonitorNotification) =>
        alertNotificationTransform(alertNotification)
    )
  };
};

export function useAlertNotifications(
  params: AlertNotificationsParams,
  config: QueryConfig<NotificationsResponse>
) {
  return useQuery(
    ['alertNotifications', { ...params }],
    () => getAlertNotifications({ ...params }),
    {
      ...config,
      enabled: !!params.page && !!params.pageSize,
      retry: false
    }
  );
}

export const alertNotificationTransform = (
  notification: MonitorNotification
): AlertNotification => {
  return {
    id: notification.id,
    intervalType: notification.runCronExpression,
    modelVersion: notification.modelVersion,
    notificationDate: notification.creationDate,
    ruleId: notification.id,
    status: notification.status,
    trigger: notification.monitorName
  };
};
