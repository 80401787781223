import React from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@DesignSystem/modals';
import { Checkbox, Input, RadioButton } from '@DesignSystem/controllers';

import styles from '../TemplateSelect.module.scss';

const ThisViewWasntSaved = ({
  isWasntSavedViewOutOfProject,
  setIsSaveNewView,
  isSaveNewView,
  newViewName,
  setNewViewName,
  setAsDefault,
  asDefault,
  handleSaveNewView,
  handleDiscardNewView,
  viewToGo,
  closeViewWasntSaved,
  currentTemplateId
}) => {
  return (
    <BasicModal
      title="The view wasn't saved"
      content={
        <div className={styles.viewWasntSavedContainer}>
          {!isWasntSavedViewOutOfProject && currentTemplateId && (
            <div
              className={styles.saveNewViewFieldContainer}
              onClick={() => setIsSaveNewView(false)}
            >
              <RadioButton checked={!isSaveNewView} />
              <span className={styles.saveViewFieldLabel}>
                Save changes to the view
              </span>
            </div>
          )}

          <div
            className={styles.saveNewViewFieldContainer}
            onClick={() => setIsSaveNewView(true)}
          >
            <RadioButton checked={isSaveNewView} />
            <span className={styles.saveViewFieldLabel}>Save new view</span>
          </div>
          {isSaveNewView && (
            <div className={styles.saveViewSubsection}>
              <Input
                value={newViewName}
                onChange={(val, e) => {
                  e.stopPropagation();
                  setNewViewName(val);
                }}
                placeholder="Name of new view"
              />
              <div
                className={styles.setAsDefaultContainer}
                onClick={() => setAsDefault(val => !val)}
              >
                <Checkbox checked={asDefault} />
                <span className={styles.setAsDefaultLabel}>Set as default</span>
              </div>
            </div>
          )}
        </div>
      }
      primaryButtonText="Save"
      secondaryButtonText="Discard"
      isPrimaryButtonDisabled={isSaveNewView && !newViewName}
      onPrimaryButtonClick={handleSaveNewView}
      onSecondaryButtonClick={handleDiscardNewView}
      open={Boolean(viewToGo)}
      onClose={closeViewWasntSaved}
    />
  );
};

ThisViewWasntSaved.propTypes = {
  currentTemplateId: PropTypes.string.isRequired,
  isWasntSavedViewOutOfProject: PropTypes.bool.isRequired,
  setIsSaveNewView: PropTypes.func.isRequired,
  asDefault: PropTypes.bool.isRequired,
  isSaveNewView: PropTypes.bool.isRequired,
  newViewName: PropTypes.string.isRequired,
  setNewViewName: PropTypes.func.isRequired,
  setAsDefault: PropTypes.func.isRequired,
  handleSaveNewView: PropTypes.func.isRequired,
  handleDiscardNewView: PropTypes.func.isRequired,
  viewToGo: PropTypes.object.isRequired,
  closeViewWasntSaved: PropTypes.func.isRequired
};

export default ThisViewWasntSaved;
