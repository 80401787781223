export default {
  parentOrigin: window.location.origin,
  messageTypes: {
    ADD_PY_OUTPUT: 'add py output',
    CALL_PY_FUNCTION: 'call py function',
    CLEAR_CONSOLE_LIST: 'clear console list',
    CLEAR_CONSOLE: 'clear console ouput',
    IFRAME_LOADED: 'iframe loaded',
    REDRAW: 'chart redraw',
    RUN_PY_CODE: 'run py code',
    SEND_CHART_OPTIONS: 'send chart options',
    SEND_SCREENSHOT: 'send screenshot',
    SET_PY_EXPERIMENT_KEYS: 'set py experiment keys',
    TAKE_SCREENSHOT: 'take screenshot',
    UPDATE_CONSOLE: 'update console output',
    UPDATE_DATA_PANELS_ROWS: 'update data panels rows'
  },
  consoleMethodTypes: {
    log: 'log',
    error: 'error',
    warn: 'warn'
  },
  chartMountId: 'chart-mount',
  iframeId: 'codePreviewIframe',
  defaultInstanceId: 'DEFAULT_INSTANCE_ID'
};
