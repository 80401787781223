// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BarChartTooltip-module__popper--Rq0Y8{pointer-events:none}.BarChartTooltip-module__container--Kq0y0{min-width:100px;max-width:48vw;max-height:624px;border-radius:10px;border:1px solid var(--gray-2);background:var(--white);box-shadow:0 0 4px 0 rgba(0,0,0,.08)}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__header--E0nDq{height:24px;padding:8px;border-bottom:1px solid var(--gray);display:flex;flex-direction:row;align-items:center;gap:8px}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__header--E0nDq .BarChartTooltip-module__color--gQGop{--size: 8px;flex-shrink:0;width:var(--size);height:var(--size);border-radius:var(--size);background:var(--line-color, transparent)}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__header--E0nDq .BarChartTooltip-module__title--nQbyx{color:var(--gray-7);font-size:12px;font-weight:500;line-height:18px}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__body--fchZ9{padding:4px 8px;color:var(--gray-7)}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__body--fchZ9 .BarChartTooltip-module__line--MV4qz{display:flex;flex-direction:row;align-items:center;font-size:12px;line-height:18px;padding:0 3px}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__body--fchZ9 .BarChartTooltip-module__line--MV4qz .BarChartTooltip-module__value--JlS6O{margin-left:4px;font-weight:500}.BarChartTooltip-module__container--Kq0y0 .BarChartTooltip-module__body--fchZ9 .BarChartTooltip-module__spacer--IzKCc{height:6px}.BarChartTooltip-module__textOverflow--cuHyC{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.BarChartTooltip-module__circle--RUd07{width:var(--size);height:var(--size);position:absolute;pointer-events:none;transform:translate(-50%, -50%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": `BarChartTooltip-module__popper--Rq0Y8`,
	"container": `BarChartTooltip-module__container--Kq0y0`,
	"header": `BarChartTooltip-module__header--E0nDq`,
	"color": `BarChartTooltip-module__color--gQGop`,
	"title": `BarChartTooltip-module__title--nQbyx`,
	"body": `BarChartTooltip-module__body--fchZ9`,
	"line": `BarChartTooltip-module__line--MV4qz`,
	"value": `BarChartTooltip-module__value--JlS6O`,
	"spacer": `BarChartTooltip-module__spacer--IzKCc`,
	"textOverflow": `BarChartTooltip-module__textOverflow--cuHyC`,
	"circle": `BarChartTooltip-module__circle--RUd07`
};
module.exports = ___CSS_LOADER_EXPORT___;
