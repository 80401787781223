import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Alert, ALERT_TYPES } from '../../../DesignSystem/components/alert';
import alertsActions from '../../../actions/alertsActions';
import { IconButton } from '@ds';
import {
  DSArrowLeftIcon,
  DSArrowLeftSecondaryIcon,
  DSArrowRightIcon,
  DSArrowRightSecondaryIcon
} from '@ds-icons';

const StyledSelect = withStyles({
  icon: {
    color: '#5155F5',
    height: 20,
    width: 20
  },
  root: {
    color: '#5155F5',
    fontSize: 14
  }
})(Select);

const Pagination = ({
  currentPage,
  onCurrentPageChange,
  onPageSizeChange,
  pageSize,
  pageSizes,
  pageTotalGrouped,
  totalCount,
  totalPages,
  width
}) => {
  const dispatch = useDispatch();
  const showGroupedExperimentsAlert = useSelector(
    state => state.alerts.limitedGroupedExperiments
  );
  const from = Math.max(pageSize * currentPage + 1, 0);
  const to = Math.min(pageSize * (currentPage + 1), totalCount);

  const showGroupingAlert = useMemo(() => {
    return showGroupedExperimentsAlert && pageTotalGrouped > 1;
  }, [pageTotalGrouped, showGroupedExperimentsAlert]);

  const selectPageSize = (
    <StyledSelect
      disableUnderline
      onChange={event => onPageSizeChange(event.target.value)}
      renderValue={() => `${from}-${to}`}
      value={pageSize}
    >
      {pageSizes.map(size => (
        <MenuItem key={size} value={size}>
          {size}
        </MenuItem>
      ))}
    </StyledSelect>
  );

  return (
    <>
      {showGroupingAlert && (
        <Alert
          message="Only first 100 groups are displayed"
          type={ALERT_TYPES.warning}
          closeHandler={() =>
            dispatch(alertsActions.hideGroupedExperimentsAlert())
          }
        />
      )}
      <div className="react-grid-pagination" style={{ width }}>
        <div className="react-grid-pagination-buttons">
          <IconButton
            Icon={<DSArrowLeftSecondaryIcon />}
            aria-label="first-page"
            disabled={currentPage === 0}
            onClick={() => onCurrentPageChange(0)}
          />

          <IconButton
            Icon={<DSArrowLeftIcon />}
            aria-label="previous-page"
            disabled={currentPage === 0}
            onClick={() => onCurrentPageChange(currentPage - 1)}
            data-test="prev-pagination-btn"
          />
        </div>

        <div className="react-grid-pagination-page-info">
          <span>Showing</span>
          {selectPageSize}
          <span>of {totalCount}</span>
        </div>

        <div className="react-grid-pagination-buttons">
          <IconButton
            Icon={<DSArrowRightIcon />}
            aria-label="next-page"
            disabled={currentPage === totalPages - 1}
            onClick={() => onCurrentPageChange(currentPage + 1)}
          />

          <IconButton
            Icon={<DSArrowRightSecondaryIcon />}
            aria-label="last-page"
            disabled={currentPage === totalPages - 1}
            onClick={() => onCurrentPageChange(totalPages - 1)}
          />
        </div>
      </div>
    </>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizes: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageTotalGrouped: PropTypes.number
};

Pagination.defaultProps = {
  pageTotalGrouped: null
};

export default Pagination;
