import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import {
  CHART_BASIC_LAYOUT,
  getMPMPanelURL,
  getMPMChartType,
  transformMatrixToPlotPoints,
  transformCategoricalDataToPlotPoints,
  transformNumericalDataToPlotPoints,
  transformSegmentsToStackedPlotData,
  transformToSegmentPlotPoints,
  transformFeaturesToBarPlot,
  formatToLocal,
  transformToLinePlotFromBarData
} from '@mpm/utils';
import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { CHART_TYPES, PANEL_SECTIONS } from '../constants';

const NULL_DATA = {
  panelData: [],
  chartType: null
};

const FEATURE_PERFORMANCE_PAGE_SECTIONS = [
  PANEL_SECTIONS.DRIFT,
  PANEL_SECTIONS.MISSING_VALUES
];

const getPanelData = async ({
  workspaceId,
  modelId,
  featureId,
  colors,
  from,
  to,
  version,
  intervalType,
  section,
  activeTabData,
  propFeatureId,
  segmentColorIds,
  isLabeled,
  title,
  metricType,
  driftAlgorithmType
}) => {
  const modelFeatureId = featureId || propFeatureId || activeTabData?.featureId;

  if (
    !modelId ||
    !workspaceId ||
    !section ||
    (FEATURE_PERFORMANCE_PAGE_SECTIONS.includes(section) && !modelFeatureId)
  ) {
    return NULL_DATA;
  }
  const segmentIds = segmentColorIds.map(item => item.id);
  const segmentsIdColorMap = segmentColorIds.reduce(
    (previousValue, currentValue) => {
      const { id, color, segmentValue } = currentValue;
      return { [id]: color, [`${id}_name`]: segmentValue, ...previousValue };
    },
    {}
  );
  const requestURL = getMPMPanelURL(
    workspaceId,
    modelId,
    modelFeatureId,
    section
  );

  try {
    const serverResult = await api.get(requestURL, {
      from,
      to,
      version,
      intervalType,
      segmentId: segmentIds,
      metricType,
      driftAlgorithm: driftAlgorithmType
    });

    let result = [];
    let layoutData = {};
    const chartType = getMPMChartType(
      section,
      serverResult.data,
      activeTabData?.transformType,
      serverResult.additionalInfo,
      segmentIds,
      isLabeled,
      title
    );

    if (
      serverResult.data?.length &&
      (serverResult.data.every(itemData => itemData?.data === null) ||
        serverResult.data.every(featureData => featureData?.data === null))
    ) {
      return {
        ...NULL_DATA
      };
    }

    if (chartType === CHART_TYPES.CONFUSION_MATRIX) {
      const matrixData = {
        ...serverResult,
        data: [
          {
            ...serverResult?.data?.[0],
            data: serverResult?.data?.[0].data.map(item => {
              return {
                ...item,
                x: formatToLocal(item.x)
              };
            })
          }
        ]
      };
      return {
        panelData: matrixData,
        chartType
      };
    }

    if (chartType === CHART_TYPES.CUSTOM_METRIC_CONFUSION_MATRIX) {
      let matrixData;
      try {
        matrixData = transformMatrixToPlotPoints(
          serverResult.labels,
          serverResult.data[0].data
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return NULL_DATA;
      }
      return {
        panelData: [matrixData],
        chartType
      };
    }

    if (chartType === CHART_TYPES.CUSTOM_METRIC_CATEGORICAL_FEATURE) {
      let chartData;
      try {
        chartData = transformCategoricalDataToPlotPoints(
          serverResult.data,
          activeTabData.definition
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return NULL_DATA;
      }
      return {
        panelData: [chartData],
        chartType: CHART_TYPES.LINE
      };
    }

    if (chartType === CHART_TYPES.CUSTOM_METRIC_NUMERICAL_FEATURE) {
      let chartData;
      try {
        chartData = transformNumericalDataToPlotPoints(
          serverResult.data,
          activeTabData.definition
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return NULL_DATA;
      }
      return {
        panelData: [chartData],
        chartType: CHART_TYPES.LINE
      };
    }

    if (chartType === CHART_TYPES.STACKED) {
      result = transformSegmentsToStackedPlotData(
        serverResult.data,
        colors,
        intervalType
      );
    }

    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      result = serverResult.data.filter(res => res.data !== null);
    }

    if (chartType === CHART_TYPES.LINE) {
      result = transformToSegmentPlotPoints(
        serverResult.data,
        colors,
        intervalType,
        segmentsIdColorMap
      );
    }

    if (chartType === CHART_TYPES.BAR) {
      result = transformFeaturesToBarPlot(
        serverResult.data,
        colors,
        intervalType
      );
    }

    if (chartType === CHART_TYPES.LINE_SEGMENTS_BARS_DATA) {
      result = transformToLinePlotFromBarData(
        serverResult.data,
        colors,
        intervalType,
        segmentsIdColorMap
      );
    }

    if (chartType === CHART_TYPES.FAIRNESS_TIME) {
      result = transformToLinePlotFromBarData(
        serverResult.data,
        colors,
        intervalType,
        segmentsIdColorMap
      );
    }

    if (chartType === CHART_TYPES.FAIRNESS_GROUPED) {
      result = serverResult.data;
    }

    if (
      chartType === CHART_TYPES.NON_SUPPORTED_SEGMENTS ||
      chartType === CHART_TYPES.NON_LABELED
    ) {
      result = [];
      layoutData = {
        yaxis: {
          ...CHART_BASIC_LAYOUT.yaxis,
          range: [0, 1.01]
        },
        xaxis: {
          ...CHART_BASIC_LAYOUT.xaxis,
          autorange: false,
          range: [from, to]
        }
      };
    }

    return {
      panelData: result,
      layoutData,
      chartType
    };
  } catch (err) {
    return err.response?.data?.msg;
  }
};

export function useMPMPanelData(
  {
    colors,
    from,
    intervalType,
    section,
    to,
    version,
    activeTabData,
    propFeatureId,
    segmentColorIds,
    isLabeled,
    title,
    metricType,
    driftAlgorithmType
  },
  config = {}
) {
  const activeWorkspace = useActiveWorkspace();
  const { featureId, modelId } = useParams();

  return useQuery(
    [
      'mpm-panel',
      {
        workspaceId: activeWorkspace?.id,
        modelId,
        featureId,
        from,
        to,
        intervalType,
        version,
        section,
        activeTabData,
        propFeatureId,
        segmentColorIds,
        isLabeled,
        title,
        metricType,
        driftAlgorithmType
      }
    ],
    () =>
      getPanelData({
        workspaceId: activeWorkspace?.id,
        modelId,
        featureId,
        colors,
        from,
        to,
        intervalType,
        version,
        section,
        activeTabData,
        propFeatureId,
        segmentColorIds,
        isLabeled,
        title,
        metricType,
        driftAlgorithmType
      }),
    config
  );
}
