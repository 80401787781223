export const BETA_FEATURE_CUSTOM_LOGO = 'react_header_icon_override';
export const BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL =
  'model-production-monitoring-internal';
export const BETA_FEATURE_MODEL_PRODUCTION_MONITORING_PARSER =
  'testing-infix-postfix-parser';
export const BETA_FEATURE_MODEL_PRODUCTION_MONITORING_MODELS_PIPELINE =
  'model-production-monitoring-models-pipeline';
export const BETA_FEATURE_MODEL_PRODUCTION_MONITORING_DRUID =
  'model-production-monitoring-druid';

export const BETA_FEATURE_SERVICE_ACCOUNTS = 'service-accounts';
export const BETA_FEATURE_LINE_CHART_HOVER_MODE = 'line-chart-hover-mode';
export const BETA_FEATURE_MODEL_REGISTRY_CARD_DETAILS_V2 =
  'model-registry-card-details-v2';
export const BETA_FEATURE_MODEL_APPROVAL_PROCESS = 'model-approval-process';
export const BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS =
  'model-registry-stages-to-tags';
export const BETA_FEATURE_MODEL_REGISTRY_STREAMLINE =
  'model-registry-streamline';
export const BETA_FEATURE_MODEL_REGISTRY_WEBHOOKS = 'model-registry-webhooks';
export const BETA_FEATURE_DATA_PANEL = 'data-panel';
export const BETA_FEATURE_DATA_PANEL_ADVANCED_FEATURES =
  'data-panel-advanced-features';
export const BETA_FEATURE_MOVE_EXPERIMENT_TO_ANOTHER_WS =
  'move-experiments-to-ws';
export const BETA_FEATURE_VIDEO_PANEL = 'video-panel';
export const BETA_FEATURE_MODEL_REGISTRY_WEBHOOKS_GITHUB =
  'model-registry-webhooks-github';
export const BETA_FEATURE_MOVE_PROJECT_TO_ANOTHER_WORKSPACE =
  'feature-move-project-to-workspace';
export const BETA_FEATURE_SYSTEM_METRICS_FETCH_TINY_SAMPLE =
  'system-metrics-fetch-tiny-sample';
export const BETA_FEATURE_ANONYMOUS_MODE = 'anonymous-mode';
export const BETA_FEATURE_3D_PANEL = '3d-panel';
export const BETA_FEATURE_CURVES_PANEL = 'curves-panel';
export const BETA_FEATURES_DOWNLOAD_REPORTS =
  'admin-dashboard-download-reports';

export const BETA_SERVER_CUSTOM_PANELS = 'server-custom-panels';
export const BETA_PYTHON_ENVIRONMENT = 'python-environments';
