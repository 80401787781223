import React from 'react';
import { Divider } from '@DesignSystem/data-display';
import { getToChatWithUsLink } from '@shared';
import { TextButton } from '@ds';

import styles from './HelpSidebar.module.scss';
import { DSChatIcon, DSHelpIcon, DSVideoIcon } from '@ds-icons';

const HelpActions = () => (
  <div className={styles.helpActionsContainer}>
    <a href={getToChatWithUsLink()} target="_blank" rel="noreferrer">
      <TextButton PrefixIcon={<DSChatIcon />}>Chat with us</TextButton>
    </a>

    <Divider orientation="vertical" margin={0} />

    <a
      href="https://www.youtube.com/@cometml8614"
      target="_blank"
      rel="noreferrer"
    >
      <TextButton PrefixIcon={<DSVideoIcon />}>Youtube</TextButton>
    </a>

    <Divider orientation="vertical" margin={0} />
    <a
      href="https://www.comet.com/site/about-us/contact-us/"
      target="_blank"
      rel="noreferrer"
    >
      <TextButton PrefixIcon={<DSHelpIcon />}>Contact us</TextButton>
    </a>
  </div>
);

export default HelpActions;
