import { useEffect, useMemo, useState, useCallback } from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useQueryString } from 'use-route-as-state';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { add, sub } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RouteObject } from 'use-route-as-state';

import {
  DEFAULT_INTERVAL_VALUE,
  INITIAL_QUERY_STATE,
  INTERVAL_TYPE_VALUES,
  PERFORMANCE_DATE_RANGES
} from '@mpm-druid/constants';
import {
  getMPMFrom,
  getMPMIntervalType,
  getMPMTo
} from '../../../reducers/ui/mpm';
import mpmActions from '../../../actions/mpmActions';
import {
  formatDateFromCalendar,
  handleIntervalTypeValue,
  handleVersionValue,
  fromUTCToDateWithoutTimezone,
  isValidTimeRangeValues,
  calculateRangeTo,
  calculateRangeFrom
} from '@mpm-druid/utils';
import { Version, IntervalType } from '@mpm-druid/types';

type SavedParamsState = {
  prevStartDate: Date | null;
  prevEndDate: Date | null;
  prevDateRange: number | null;
  prevIntervalType: IntervalType | null | string;
  intervalType: IntervalType;
};

export const useMPMPerformanceParams = (versions: Version[]) => {
  const [params, updateParams] = useQueryString(INITIAL_QUERY_STATE);
  const [
    savedDailyHourlyParams,
    setSavedDailyHourlyParams
  ] = useState<SavedParamsState | null>(null);
  const [areParamsInitialized, setAreParamsInitialized] = useState(false);

  const storeFrom = useSelector(getMPMFrom);
  const storeTo = useSelector(getMPMTo);
  const storeIntervalType = useSelector(getMPMIntervalType);

  // Use always the same now date while the user is in the performance page
  // @todo: It's still going to change on every time the user moves to another tab
  //        and go back to the performance page, making the panels cache useless
  const now = useMemo(() => new Date().toISOString(), []);

  const [dateRange, setDateRange] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const dispatch = useDispatch();
  const from = useMemo(() => {
    if (startDate && endDate) {
      return formatDateFromCalendar(startDate, true);
    }

    if (dateRange) {
      return calculateRangeFrom(now, dateRange);
    }

    if (storeFrom) {
      return storeFrom as string;
    }

    if (params.from) {
      return params.from as string;
    }

    return formatDateFromCalendar(moment(now), true);
  }, [dateRange, endDate, now, startDate, params?.from, storeFrom]);

  const to = useMemo(() => {
    if (startDate && endDate) {
      return formatDateFromCalendar(endDate, false);
    }
    if (dateRange) {
      return calculateRangeTo(now);
    }

    if (storeTo) {
      return storeTo as string;
    }

    if (params.to) {
      return params.to as string;
    }

    return calculateRangeTo(now);
  }, [dateRange, endDate, now, startDate, params?.to, storeTo]);

  useEffect(() => {
    if (areParamsInitialized) {
      dispatch(
        mpmActions.setTimeRange({
          from,
          to
        })
      );
    }
  }, [from, to, dispatch, areParamsInitialized]);

  useEffect(() => {
    if (areParamsInitialized) {
      dispatch(mpmActions.setIntervalType(params?.intervalType));
    }
  }, [params?.intervalType, dispatch, areParamsInitialized]);

  useEffect(() => {
    if (from && to && areParamsInitialized) {
      updateParams(prevParams => ({
        ...prevParams,
        from,
        to
      }));
    }
  }, [from, to, updateParams, areParamsInitialized]);

  useEffect(() => {
    // put some value to params if date range is not set
    if ((!params.from && !from) || (!params?.to && !to)) {
      setDateRange(DEFAULT_INTERVAL_VALUE);
    }
  }, [params?.from, from, params?.to, to]);

  // INIT AND VALIDATIONS
  useEffect(() => {
    if (!areParamsInitialized && from && to && now) {
      const result = {
        ...INITIAL_QUERY_STATE,
        ...params
      };

      result.from = from;
      result.to = to;
      result.intervalType = handleIntervalTypeValue(
        storeIntervalType || result.intervalType
      );

      if (!isValidTimeRangeValues(result)) {
        result.from = calculateRangeFrom(now);
        result.to = calculateRangeTo(now);
      }

      result.version = handleVersionValue(result.version, versions);

      updateParams(result);
      setAreParamsInitialized(true);
    }
  }, [
    areParamsInitialized,
    from,
    to,
    updateParams,
    versions,
    params,
    now,
    storeIntervalType
  ]);

  const calcMaxDate = (start: Date) => {
    const currentTime = new Date(now);

    const maxInterval =
      PERFORMANCE_DATE_RANGES[
        params?.intervalType as keyof typeof PERFORMANCE_DATE_RANGES
      ][2];

    const localMaxDate = add(start, {
      days: maxInterval
    });

    const isAllowedTime = localMaxDate <= currentTime;

    if (isAllowedTime) {
      return localMaxDate;
    }
    return currentTime;
  };

  const isEndDateToday = useMemo(
    () => (
      end: { toDateString: () => string },
      start: { toDateString: () => string }
    ) => end?.toDateString() === start?.toDateString(),
    []
  );

  const handleNewRange = useCallback(
    ([start, end]) => {
      batchedUpdates(() => {
        setDateRange(null);
        setStartDate(start);
        if (
          isEndDateToday(end, start) &&
          params?.intervalType === INTERVAL_TYPE_VALUES.DAILY
        ) {
          setEndDate(
            add(start, {
              days: 1
            })
          );
        } else {
          setEndDate(end);
        }
      });
    },
    [params?.intervalType, isEndDateToday]
  );

  const handleUpdateIntervalType = (intervalType: IntervalType) => {
    batchedUpdates(() => {
      if (params.intervalType === intervalType) return;

      if (savedDailyHourlyParams?.intervalType !== intervalType) {
        setSavedDailyHourlyParams({
          prevStartDate: startDate,
          prevEndDate: endDate,
          prevDateRange: dateRange,
          prevIntervalType: params?.intervalType
        } as RouteObject);
      }

      if (
        !isValidTimeRangeValues({
          from: params.from as string,
          to: params.to as string,
          intervalType
        })
      ) {
        setDateRange(DEFAULT_INTERVAL_VALUE);
        setStartDate(new Date(calculateRangeFrom(now)));
        setEndDate(new Date(calculateRangeTo(now)));
        updateParams(prevParams => ({ ...prevParams, intervalType }));
        return;
      }

      if (intervalType === INTERVAL_TYPE_VALUES.HOURLY) {
        updateParams(prevParams => ({ ...prevParams, intervalType }));
        return;
      }

      if (
        intervalType === INTERVAL_TYPE_VALUES.DAILY &&
        dateRange !== DEFAULT_INTERVAL_VALUE
      ) {
        if (dateRange) {
          setDateRange(null);
          setStartDate(sub(new Date(), { days: dateRange }));
          setEndDate(new Date());

          updateParams(prevParams => ({ ...prevParams, intervalType }));
        } else updateParams(prevParams => ({ ...prevParams, intervalType }));

        return;
      }

      if (savedDailyHourlyParams) {
        const {
          prevStartDate,
          prevEndDate,
          prevDateRange
        } = savedDailyHourlyParams;

        setStartDate(prevStartDate);
        setEndDate(prevEndDate);
        setDateRange(prevDateRange);
      } else {
        setDateRange(DEFAULT_INTERVAL_VALUE);
        setStartDate(new Date(calculateRangeFrom(now)));
        setEndDate(new Date(calculateRangeTo(now)));
      }

      updateParams(prevParams => ({ ...prevParams, intervalType }));
    });
  };

  const handleUpdateVersion = (version: Version) => {
    updateParams((prevParams: RouteObject) => ({ ...prevParams, version }));
  };

  const handledCalendarStartDate =
    startDate ||
    (!dateRange ? fromUTCToDateWithoutTimezone(params.from as string) : null);

  const handledCalendarEndDate =
    endDate ||
    (!dateRange ? fromUTCToDateWithoutTimezone(params.to as string) : null);

  return {
    version: handleVersionValue(params.version as string, versions),
    onUpdateVersion: handleUpdateVersion,

    // time values
    calcMaxDate,
    from,
    to,
    intervalType: params.intervalType as IntervalType,
    dateRange,
    setStartDate,
    setEndDate,
    setDateRange,
    onUpdateIntervalType: handleUpdateIntervalType,
    onChangeRange: handleNewRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  };
};
