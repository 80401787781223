import { MetricOptions } from '@Icons-outdated';
import React from 'react';
import cx from 'classnames';
import { DropdownList } from '..';
import './DropdownList.scss';

function ExtraOptions(
  { options, onClick, setIsSubmenuOpen, extraOptionsProps },
  ref
) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onIconClick = e => {
    if (ref.customRef) {
      setAnchorEl(ref.customRef.current);
    } else {
      setAnchorEl(e.target);
    }

    setIsSubmenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsSubmenuOpen(false);
  };

  return (
    <>
      <div
        data-test="extra-options"
        onClick={onIconClick}
        className={cx('extra-options-wrapper', { isOpen: Boolean(anchorEl) })}
        ref={ref.extraOptionsRef || ref}
      >
        <div style={{ pointerEvents: 'none' }}>
          <MetricOptions />
        </div>
      </div>
      <DropdownList
        anchorEl={anchorEl}
        onClick={onClick}
        onClose={handleClose}
        items={options}
        {...extraOptionsProps}
      />
    </>
  );
}

export default React.forwardRef(ExtraOptions);
