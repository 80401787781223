// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextChunksHighlight-module__textHighlightContainer--VJRHI mark{background-color:#83c5be;border-radius:5px;line-height:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textHighlightContainer": `TextChunksHighlight-module__textHighlightContainer--VJRHI`
};
module.exports = ___CSS_LOADER_EXPORT___;
