export enum WebhookStatus {
  SUCCESS = 'SUCCESS',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  HTTP_ERROR = 'HTTP_ERROR'
}

export type WebhookHttpResponse = {
  statusCode: number;
  headers: { [key: string]: string };
  body: string;
  statusText: string;
};

export type WebhookNotifierResponse =
  | {
      status: WebhookStatus.INTERNAL_ERROR;
      internalError: string;
      httpResponse?: never;
    }
  | {
      status: WebhookStatus.SUCCESS | WebhookStatus.HTTP_ERROR;
      httpResponse: WebhookHttpResponse;
      internalError?: never;
    };

// The structure has been updated to WebhookNotifierResponse to provide a clearer separation between
// different types of responses and to ensure that error handling is more explicit.
// WebhookStatus/helper.ts has helper function to convert WebhookTestResponse to WebhookNotifierResponse
export interface WebhookTestResponse {
  statusText: string;
  statusCode: number;
  body: string;
  headers: { [key: string]: string };
  errorMsg: string;
  isError: boolean;
}
