import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import cx from 'classnames';
import { useIsUserMemberOfWorkspace } from '@shared/hooks';
import SmallLoader from '@shared/components/SmallLoader';
import {
  getArtifactVersion,
  getArtifactVersionFiles
} from '@/reducers/artifactsReducer';
import artifactActions from '@/actions/artifactActions';

import {
  ARTIFACT_VERSION_COMPOSED_TYPE,
  ARTIFACT_VERSION_PAGE_TABS
} from '@artifacts/constants/constants';
import ArtifactVersionPageHeader from './ArtifactVersionPageHeader';

import ArtifactVersionAssetsTab from './ArtifactVersionAssetsTab';
import ArtifactVersionSnowflakeTab from './ArtifactVersionSnowflakeTab';
import ArtifactVersionMetadataTab from './ArtifactVersionMetadataTab';
import ArtifactVersionGraphTab from './ArtifactVersionGraphTab';

import GoBackLink from '@shared/components/GoBackLink';
import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';
import { calculateArtifactVersionType } from '@artifacts/utils/helpers';

const ArtifactVersionPage = ({
  artifactName,
  artifactVersion,
  artifactVersionNumber,
  artifactVersionComposedType,
  numberOfFiles,
  dispatch,
  workspace
}) => {
  const [hasFetched, setHasFetched] = useState(false);
  const [activeTab, setActiveTab] = useState(ARTIFACT_VERSION_PAGE_TABS.Assets);
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();

  useEffect(() => {
    if (!hasFetched) {
      dispatch(
        artifactActions.fetchArtifactVersion(
          workspace,
          artifactName,
          artifactVersionNumber
        )
      ).then(() => {
        setHasFetched(true);
      });
    }
  }, [artifactName, artifactVersionNumber, dispatch, hasFetched, workspace]);

  const tabs = useMemo(() => {
    const retVal = [
      {
        id: ARTIFACT_VERSION_PAGE_TABS.Metadata,
        name: 'Metadata',
        suffix: '',
        component: ArtifactVersionMetadataTab
      },
      {
        id: ARTIFACT_VERSION_PAGE_TABS.Lineage,
        name: 'Lineage',
        suffix: '',
        component: ArtifactVersionGraphTab
      }
    ];

    if (
      artifactVersionComposedType === ARTIFACT_VERSION_COMPOSED_TYPE.DEFAULT
    ) {
      retVal.unshift({
        id: ARTIFACT_VERSION_PAGE_TABS.Assets,
        name: 'Assets',
        suffix: `(${numberOfFiles})`,
        component: ArtifactVersionAssetsTab
      });
    }

    if (
      artifactVersionComposedType === ARTIFACT_VERSION_COMPOSED_TYPE.SNOWFLAKE
    ) {
      retVal.unshift({
        id: ARTIFACT_VERSION_PAGE_TABS.Assets,
        name: 'Assets',
        suffix: '',
        component: ArtifactVersionSnowflakeTab
      });
    }

    return retVal;
  }, [artifactVersionComposedType, numberOfFiles]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderActiveTab = () => {
    const TabComponent = tabs.find(t => t.id === activeTab).component;

    return (
      <TabComponent
        artifactVersion={artifactVersion}
        isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
      />
    );
  };

  if (!hasFetched) {
    return (
      <div className="flex grow">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Fetching artifact"
        />
      </div>
    );
  }

  if (isEmpty(artifactVersion)) {
    return (
      <div className="content-not-found flex column grow">
        <h3 className="page-notice">No artifact version found.</h3>
        <GoBackLink
          to={`/${workspace}${WORKSPACE_URL_HASHES.ARTIFACTS}`}
          text="Go back to artifacts"
        />
      </div>
    );
  }

  return (
    <div
      className={cx('artifact-container', {
        isLineageView: activeTab === ARTIFACT_VERSION_PAGE_TABS.Lineage
      })}
    >
      <ArtifactVersionPageHeader
        artifactVersion={artifactVersion}
        artifactVersionNumber={artifactVersionNumber}
        tabs={tabs}
        activeTab={activeTab}
        isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
        tabChangeHandler={handleTabChange}
      />
      <div className="artifact-body">{renderActiveTab()}</div>
    </div>
  );
};

ArtifactVersionPage.propTypes = {
  artifactName: PropTypes.string.isRequired,
  artifactVersion: PropTypes.object.isRequired,
  artifactVersionNumber: PropTypes.string.isRequired,
  artifactVersionComposedType: PropTypes.string.isRequired,
  numberOfFiles: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    workspace,
    artifactName: artifactNameEncoded,
    artifactVersionNumber
  } = props.match.params;
  const artifactName = decodeURIComponent(artifactNameEncoded);
  const artifactVersion = getArtifactVersion(state, {
    artifactName,
    artifactVersionNumber,
    workspace
  });
  const numberOfFiles = getArtifactVersionFiles(state, {
    artifactVersionId: artifactVersion.artifactVersionId
  }).length;
  const artifactVersionComposedType = calculateArtifactVersionType(
    artifactVersion
  );

  return {
    artifactName,
    artifactVersion,
    artifactVersionNumber,
    artifactVersionComposedType,
    numberOfFiles,
    workspace
  };
};

export default connect(mapStateToProps)(ArtifactVersionPage);
