import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import CodeMirror from 'codemirror';

import { generatePath, withRouter } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import defaultTo from 'lodash/defaultTo';
import last from 'lodash/last';

import isEqual from 'fast-deep-equal';
import prettier from 'prettier/standalone';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Tooltip } from '@ds';

import { PANEL_ENTITY_NAME } from '@experiment-management-shared/constants/visualizationConstants';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';

import DescriptionTab from './DescriptionTab';
import MetaBadge from './MetaBadge';
import QueryTab from './QueryTab';
import ResourcesTab from './ResourcesTab';
import SaveViewModal from '@experiment-management-shared/components/SaveViewModal';
import visualizationsActions from '@/actions/visualizationsActions';

import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import {
  NEW_TEMPLATE,
  getResourcesForTemplate,
  makeGetVisualizationTemplateRevisionIds,
  makeGetVisualizationTemplate,
  getPyConfigPreview,
  getCodeLanguagePreview
} from '@/reducers/visualizationsReducer';
import {
  getInternalResourcesForNewTemplate,
  getMutableTemplateName,
  selectIsTemplateSaved
} from '@/reducers/ui/visualizationsUiReducer';
import alertsUtil from '@/util/alertsUtil';
import { getTemplateIdFromURL } from '@experiment-management-shared/utils/visualizationsHelper';
import { dialogTypes } from '@/constants/alertTypes';
import EDITOR_TABS, { DEFAULT_EDITOR_OPTIONS } from './editorConfig';
import './codeMirrorImports';
import { SUB_PATHS } from '@/constants/urlConstants';
import {
  getTemplateName,
  selectIsQueryBuilderIdChanged
} from '@/selectors/visualizationSelectors';
import VizHistoryPopover from '@experiment-management-shared/components/VizHistoryPopover';
import TabLabelWithBadge from '@shared/components/TabLabelWithBadge';
import { getExportTemplateURL } from '@/util/visualizationsApi';
import PyConfigTabContainer from './PyConfigTabContainer';
import debounce from 'lodash/debounce';

const getTabByCodeLanguage = isPy => EDITOR_TABS.keys[isPy ? 1 : 0];

const debouncedSetCodePreview = debounce((codeMirror, dispatch) => {
  dispatch(visualizationsActions.setCodePreview(codeMirror));
}, 500);

const CodeEditor = ({
  isQueryBuilderIdChanged,
  canEdit,
  currentTemplate,
  experiments,
  dispatch,
  internalJSResourcesForNewTemplate,
  isTemplateSaved,
  location,
  match,
  mutableTemplateName,
  revisionIds,
  templateId,
  templateName,
  totalExperiments,
  currentCodeLanguage,
  pyConfig,
  onRunButtonClick,
  queryBuilderId,
  queryBuilderIdRevision
}) => {
  const { code, editable, revisionId } = currentTemplate;
  const latestRevisionId = last(revisionIds);
  const isLatestRevision = revisionId === latestRevisionId;

  const isPy = currentCodeLanguage === PANEL_CODE_LANGUAGES.PYTHON;

  const [editorMenuAnchorEl, setEditorMenuAnchorEl] = useState(null);
  const [historyMenuAnchorEl, setHistoryMenuAnchorEl] = useState(null);

  const [activeTab, setActiveTab] = useState(getTabByCodeLanguage(isPy));
  const [codeMirror, setCodeMirror] = useState(code);

  const isReadOnly = !canEdit;

  const codeMirrorDependencyRef = useRef();

  useEffect(() => {
    setActiveTab(getTabByCodeLanguage(isPy));
    setCodeMirror(prevCodeMirror => ({
      ...prevCodeMirror,
      type: currentCodeLanguage
    }));
  }, [currentCodeLanguage, isPy, dispatch, setCodeMirror]);

  useEffect(() => {
    setCodeMirror(code);
  }, [revisionId, code]);

  useEffect(() => {
    setCodeMirror(state => {
      if (isEqual(state.pyConfig, pyConfig)) {
        return state;
      }

      return {
        ...state,
        pyConfig
      };
    });
  }, [pyConfig, setCodeMirror]);

  const generateLink = useCallback(
    revisionId => {
      const { search } = location;
      const revisionIdPath =
        revisionId === latestRevisionId
          ? SUB_PATHS.LATEST_REVISION
          : revisionId;

      const basePath = generatePath(match.path, {
        ...match.params,
        revisionId: revisionIdPath
      });

      return `${basePath}${search}`;
    },
    [latestRevisionId, match.params, match.path, location]
  );

  const handleRunCodePreview = useCallback(() => {
    onRunButtonClick();

    dispatch(
      visualizationsActions.runCodePreview(
        defaultTo(templateId, 'new-chart'),
        codeMirror,
        experiments
      )
    );
  }, [templateId, experiments, codeMirror, dispatch, onRunButtonClick]);

  const handleUpdateTemplate = useCallback(() => {
    dispatch(
      visualizationsActions.updateVisualizationTemplate(templateId, revisionId)
    ).then(() => {
      if (match.params.revisionId) {
        dispatch(push(generateLink()));
      }
    });
  }, [generateLink, match.params.revisionId, templateId, dispatch, revisionId]);

  const handleSaveNewTemplate = useCallback(
    newTemplateName => {
      const { thumbnail, version, ...newCode } = codeMirror;

      const changedTemplateFields = {
        code: { ...code, ...newCode },
        templateName: newTemplateName
      };

      dispatch(visualizationsActions.createTemplate(changedTemplateFields));
    },
    [code, codeMirror, dispatch]
  );

  const handleUpdateResourcesByType = (resourceType, updatedResources) => {
    setCodeMirror(prevCodeMirror => ({
      ...prevCodeMirror,
      [resourceType]: updatedResources
    }));
  };

  const handleCloseEditorMenu = () => {
    setEditorMenuAnchorEl(null);
    setHistoryMenuAnchorEl(null);
  };

  const openSaveViewModal = useCallback(
    (openWithoutUpdateTemplate = false) => {
      handleCloseEditorMenu();

      // refresh chart.options
      handleRunCodePreview();

      const saveViewModal = (
        <SaveViewModal
          entityType={PANEL_ENTITY_NAME}
          templateName={templateName}
          templateId={templateId}
          saveNewTemplateHandler={handleSaveNewTemplate}
          updateTemplateHandler={handleUpdateTemplate}
          modalId={dialogTypes.SAVE_TEMPLATE_MODAL}
          defaultNewTemplateName={mutableTemplateName}
          isUpdateTemplateDisabled={openWithoutUpdateTemplate || !editable}
        />
      );

      dispatch(
        alertsUtil.openCustomModal(
          dialogTypes.SAVE_TEMPLATE_MODAL,
          saveViewModal
        )
      );
    },
    [
      editable,
      handleRunCodePreview,
      handleSaveNewTemplate,
      handleUpdateTemplate,
      mutableTemplateName,
      templateId,
      templateName,
      dispatch
    ]
  );

  const handleSaveShortcut = useCallback(() => {
    if (isTemplateSaved) return;

    if (templateId) {
      handleUpdateTemplate();
    } else {
      openSaveViewModal();
    }
  }, [templateId, openSaveViewModal, handleUpdateTemplate, isTemplateSaved]);

  useEffect(() => {
    setCodeMirror(prevCodeMirror => ({
      ...prevCodeMirror,
      internalResources:
        isPy || !internalJSResourcesForNewTemplate
          ? []
          : internalJSResourcesForNewTemplate
    }));
  }, [isPy, internalJSResourcesForNewTemplate]);

  useEffect(() => {
    debouncedSetCodePreview(codeMirror, dispatch);

    dispatch(
      visualizationsActions.setIsTemplateSaved(
        isLatestRevision &&
          isEqual(codeMirror, code) &&
          !isQueryBuilderIdChanged
      )
    );
  }, [code, codeMirror, dispatch, isLatestRevision, isQueryBuilderIdChanged]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    codeMirrorDependencyRef.current = codeMirror;
  }, [codeMirror]);

  const handleOpenEditorMenu = event => {
    setEditorMenuAnchorEl(event.currentTarget);
  };

  const handleOpenHistoryMenu = event => {
    setHistoryMenuAnchorEl(event.currentTarget);
  };

  const handleFormatCode = useCallback(() => {
    try {
      const formattedCode = prettier.format(codeMirror.code);

      setCodeMirror(prevCodeMirror => ({
        ...prevCodeMirror,
        code: formattedCode
      }));
    } catch (error) {
      console.error(error);
    }
  }, [codeMirror.code]);

  const handleUpdateTemplateWithConfirm = () => {
    const type = dialogTypes.CONFIRM_UPDATE_PANEL_TEMPLATE;
    const msg = (
      <span>
        <p>This will become the latest version of the panel.</p>
        <p>All versions are still saved in the Version History.</p>
      </span>
    );
    const confirmHandler = () => {
      handleUpdateTemplate();
      dispatch(alertsUtil.closeDialog(type));
    };
    const buttonText = 'Save';

    dispatch(
      alertsUtil.openConfirmDialog(type, msg, confirmHandler, buttonText)
    );
  };

  const handleSaveBtnClick = () => {
    if (!currentTemplate.editable) {
      openSaveViewModal(true);
      return;
    }

    if (isEmpty(templateId)) {
      openSaveViewModal();
    } else if (!isLatestRevision) {
      handleUpdateTemplateWithConfirm();
    } else {
      handleUpdateTemplate();
    }
  };

  const renderCodeMirror = () => {
    const { tabOptions } = EDITOR_TABS.map[activeTab];

    return (
      <ReactCodeMirror
        value={codeMirror[activeTab]}
        options={{
          ...DEFAULT_EDITOR_OPTIONS,
          readOnly: isReadOnly,
          ...tabOptions
        }}
        onBeforeChange={(editor, data, value) => {
          setCodeMirror({ ...codeMirror, [activeTab]: value });
        }}
      />
    );
  };

  const renderTabs = () => {
    const tabNames = EDITOR_TABS.keys;
    return tabNames
      .filter(tabName => {
        const { supportedLanguages } = EDITOR_TABS.map[tabName];
        return supportedLanguages.includes(currentCodeLanguage);
      })
      .map(tabName => {
        const editorTabsMap = EDITOR_TABS.map;
        let { label } = editorTabsMap[tabName];

        if (label === editorTabsMap.query.label) {
          label = (
            <TabLabelWithBadge
              labelText={label}
              badgeCount={totalExperiments}
            />
          );
        }

        return <Tab key={tabName} value={tabName} label={label} />;
      });
  };

  const renderTabContent = () => {
    const { label } = EDITOR_TABS.map[activeTab];

    if (label === EDITOR_TABS.map.description.label) {
      return (
        <DescriptionTab
          templateId={templateId}
          description={codeMirror.description}
        >
          {renderCodeMirror()}
        </DescriptionTab>
      );
    }

    if (label === EDITOR_TABS.map.query.label) {
      return (
        <QueryTab
          queryBuilderId={queryBuilderId}
          queryBuilderIdRevision={queryBuilderIdRevision}
        />
      );
    }

    if (label === EDITOR_TABS.map.resources.label) {
      return (
        <ResourcesTab
          internalResources={codeMirror.internalResources}
          userResources={codeMirror.userResources}
          updateResourceHandler={handleUpdateResourcesByType}
        />
      );
    }

    if (label === EDITOR_TABS.map.pyConfig.label) {
      return <PyConfigTabContainer />;
    }

    return renderCodeMirror();
  };

  const renderActiveTab = () => {
    return (
      <div className={`tab-window ${activeTab}`}>{renderTabContent()}</div>
    );
  };

  const renderTabBar = () => {
    return (
      <Tabs
        className="code-editor-tabs"
        classes={{ scrollable: 'code-editor-tabs-scrollable' }}
        variant="scrollable"
        value={activeTab}
        onChange={handleTabChange}
      >
        {renderTabs()}
      </Tabs>
    );
  };

  const renderEditorMenu = () => {
    const fontSize = 14;
    return (
      <>
        {canEdit || currentTemplate.templateId ? (
          <Tooltip content="Click for more actions" placement="top">
            <i
              className="material-icons code-editor-menu-btn"
              onClick={handleOpenEditorMenu}
              role="button"
            >
              more_vert
            </i>
          </Tooltip>
        ) : (
          <div />
        )}
        <Menu
          anchorEl={editorMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={Boolean(editorMenuAnchorEl)}
          onClose={handleCloseEditorMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {!isReadOnly && (
            <MenuItem
              style={{ fontSize }}
              onClick={() => openSaveViewModal(true)}
            >
              Save As
            </MenuItem>
          )}
          {templateId && (
            <div>
              <MenuItem style={{ fontSize }} onClick={handleOpenHistoryMenu}>
                Version history{' '}
                <i
                  style={{ fontSize: 18, marginLeft: 8 }}
                  className="material-icons"
                >
                  keyboard_arrow_right
                </i>
              </MenuItem>
              <MenuItem style={{ fontSize }}>
                <a
                  onClick={handleCloseEditorMenu}
                  href={getExportTemplateURL(templateId)}
                  style={{ color: 'inherit' }}
                >
                  Export
                </a>
              </MenuItem>
              <MenuItem
                style={{ fontSize }}
                onClick={() => {
                  handleCloseEditorMenu();
                  dispatch(visualizationsActions.openImportTemplateModal());
                }}
              >
                Import
              </MenuItem>
            </div>
          )}
        </Menu>

        <VizHistoryPopover
          anchorEl={historyMenuAnchorEl}
          currentRevisionId={revisionId}
          generateLink={generateLink}
          onClose={handleCloseEditorMenu}
          revisionIds={revisionIds}
        />
      </>
    );
  };

  const renderHeaderButtons = () => {
    return (
      <div className="btn-group right">
        {isReadOnly ? <MetaBadge colorClass="gray" text="Read only" /> : null}
        <Tooltip content="Click to run code" placement="top">
          <Button
            className="primary-light-button code-editor-btn run"
            onClick={() => handleRunCodePreview()}
          >
            <i className="material-icons">play_arrow</i>
          </Button>
        </Tooltip>
        {!isReadOnly && (
          <Tooltip content="Click to save code" placement="top">
            <span className="tooltip-btn-wrapper">
              <Button
                className="primary-light-button code-editor-btn"
                onClick={handleSaveBtnClick}
                disabled={currentTemplate.editable && isTemplateSaved}
              >
                <i className="material-icons">save_alt</i>
                {!isTemplateSaved && (
                  <span className="unsaved-template-warning" />
                )}
              </Button>
            </span>
          </Tooltip>
        )}

        {renderEditorMenu()}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="editor-window-header">
        {renderTabBar()}
        {renderHeaderButtons()}
      </div>
    );
  };

  // Note: these commands are global and not set per instance
  CodeMirror.commands.save = handleSaveShortcut;
  CodeMirror.commands.run = handleRunCodePreview;
  CodeMirror.commands.format = handleFormatCode;

  return (
    <Resizable
      className="code-editor"
      defaultSize={{
        width: '50%',
        height: '100%'
      }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
    >
      {renderHeader()}
      {renderActiveTab()}
    </Resizable>
  );
};

CodeEditor.defaultProps = {
  templateId: null
};

CodeEditor.propTypes = {
  isQueryBuilderIdChanged: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  experiments: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  internalJSResourcesForNewTemplate: PropTypes.array.isRequired,
  isTemplateSaved: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mutableTemplateName: PropTypes.string.isRequired,
  revisionIds: PropTypes.array.isRequired,
  templateId: PropTypes.string,
  templateName: PropTypes.string.isRequired,
  totalExperiments: PropTypes.number.isRequired,
  pyConfig: PropTypes.object.isRequired,
  currentCodeLanguage: PropTypes.string.isRequired,
  onRunButtonClick: PropTypes.func.isRequired,
  queryBuilderId: PropTypes.string.isRequired,
  queryBuilderIdRevision: PropTypes.number.isRequired
};

const makeMapStateToProps = () => {
  const getVisualizationTemplateRevisionIds = makeGetVisualizationTemplateRevisionIds();

  return (state, props) => {
    const handledCode = NEW_TEMPLATE.code;

    handledCode.type = PANEL_CODE_LANGUAGES.PYTHON;

    const getVisualizationTemplate = makeGetVisualizationTemplate({
      ...NEW_TEMPLATE,
      code: handledCode
    });

    const { codeTemplateId, revisionId } = props.match.params;
    const templateId = getTemplateIdFromURL(codeTemplateId);

    return {
      canEdit: isUserAllowedToEditProject(state),
      isQueryBuilderIdChanged: selectIsQueryBuilderIdChanged(state, {
        templateId,
        revisionId
      }),
      currentTemplate: getVisualizationTemplate(state, {
        revisionId,
        templateId
      }),
      internalJSResourcesForNewTemplate: getInternalResourcesForNewTemplate(
        state
      ),
      isTemplateSaved: selectIsTemplateSaved(state),
      mutableTemplateName: getMutableTemplateName(state),
      resourcesForTemplate: getResourcesForTemplate(state, { templateId }),
      revisionIds: getVisualizationTemplateRevisionIds(state, {
        templateId
      }),
      templateId,
      templateName: getTemplateName(state, { templateId, revisionId }),
      currentCodeLanguage: getCodeLanguagePreview(state),
      pyConfig: getPyConfigPreview(state)
    };
  };
};

export default withRouter(connect(makeMapStateToProps)(CodeEditor));
