// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print{.ReportSections-module__sections--zzXM9{position:relative}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sections": `ReportSections-module__sections--zzXM9`
};
module.exports = ___CSS_LOADER_EXPORT___;
