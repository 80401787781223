import { useState } from 'react';
import {
  defaultRulesSortingColumns,
  disabledRulesSortingColumns
} from '../SystemMetricsComparisonTable/systemMetricsComparisonConfig';

const useSystemMetricsTableSorting = () => {
  const [columnSorting, onSortingChange] = useState(
    () => defaultRulesSortingColumns
  );

  return {
    isDisabled: false,
    columnSorting,
    disabledColumns: disabledRulesSortingColumns,
    onSortingChange
  };
};

export default useSystemMetricsTableSorting;
