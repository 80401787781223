import React from 'react';
import { useSearchListContext } from '../useSearchListContext';
import classes from '../List.module.scss';

type SearchableListResultProps<T> = {
  maxHeight?: number;
  children: (item: T) => React.ReactNode;
};
const SearchableListResult = <T extends object>({
  maxHeight,
  children
}: SearchableListResultProps<T>) => {
  const { itemList } = useSearchListContext();

  return (
    <div
      className={classes.searchableResultContainer}
      style={
        {
          '--container-max-height': `${maxHeight}px`
        } as React.CSSProperties
      }
    >
      {itemList.map(item => children(item as T))}
    </div>
  );
};

export default SearchableListResult;
