import cx from 'classnames';
import { isArray, noop } from 'lodash';
import React, { useMemo, useState } from 'react';

import { UsePanelConfigsParams } from '@experiment-management-shared/hooks';
import { GridAsset } from '@experiment-management-shared/types';

import AssetNameCell from './AssetNameCell';
import AssetThumbnail from './AssetThumbnail';
import { ExperimentNameCell } from './ExperimentNameCell';
import { useCellStyles } from './useCellStyles';

import styles from './GridPanelTiles.module.scss';
import { useObserveResizeNode } from '@shared/hooks';

export type AssetThumbnailRendererProps<T extends GridAsset> = {
  asset?: T;
  cellSize: { height: number; width: number };
  onClick: () => void;
  src?: string;
};

const defaultGetAssetThumbnail = <T extends GridAsset>(asset?: T) => {
  return asset?.thumbnailPath;
};

const defaultRenderAssetThumbnail = <T extends GridAsset>({
  onClick,
  src
}: AssetThumbnailRendererProps<T>) => {
  return <AssetThumbnail onClick={onClick} src={src} />;
};

type GridPanelTilesProps<T extends GridAsset> = {
  assetNames: string[];
  assets?: T[];
  experimentKeys: string[];
  experimentNameAsLink?: boolean;
  // @todo: move to types folder
  experiments?: UsePanelConfigsParams['experiments'];
  getAssetThumbnail?: (asset?: T) => string | undefined;
  isAssetClickable?: boolean;
  onAssetClick: (asset: T) => void;
  renderAssetThumbnail?: (
    props: AssetThumbnailRendererProps<T>
  ) => React.JSX.Element;
};

export function GridPanelTiles<T extends GridAsset>({
  assetNames,
  assets = [],
  experimentKeys = [],
  experimentNameAsLink = false,
  experiments = [],
  getAssetThumbnail = defaultGetAssetThumbnail,
  isAssetClickable = false,
  onAssetClick = noop,
  renderAssetThumbnail = defaultRenderAssetThumbnail
}: GridPanelTilesProps<T>) {
  const [containerHeight, setContainerHeight] = useState(0);
  const resizeRef = useObserveResizeNode(node => {
    setContainerHeight(node.offsetHeight);
  });
  const { cellSize, styles: containerStyle } = useCellStyles({
    assetsAmount: assetNames?.length,
    containerHeight,
    targetExperimentsAmount: experimentKeys?.length
  });

  const experimentsNameMap = useMemo(() => {
    return experimentKeys.reduce<Record<string, string>>((acc, key) => {
      acc[key] = experiments.find(e => e.experimentKey === key)?.Name || key;
      return acc;
    }, {});
  }, [experiments, experimentKeys]);

  if (!isArray(assets)) {
    return null;
  }

  // initial top/left empty element
  const tiles = [<div key="empty" className={styles.emptyTile} />];

  // first row with experiments names
  experimentKeys.forEach(key =>
    tiles.push(
      <ExperimentNameCell
        experimentKey={key}
        experimentName={experimentsNameMap[key]}
        shouldDisplayLink={experimentNameAsLink}
      />
    )
  );

  assetNames.forEach((name, i) => {
    tiles.push(<AssetNameCell assetName={name} />);

    experimentKeys.forEach((key, j) => {
      const asset = assets?.find(
        asset => asset.experimentKey === key && asset.name === name
      );

      const className = cx(styles.assetTile, {
        [styles.lastRow]: i === assetNames.length - 1,
        [styles.lastColumn]: j === experimentKeys.length - 1,
        [styles.clickable]: isAssetClickable && !!asset
      });

      const handleClick = () => {
        if (isAssetClickable && asset) {
          onAssetClick(asset);
        }
      };
      const src = getAssetThumbnail(asset);

      tiles.push(
        <div key={`${name}_${key}`} className={className}>
          {renderAssetThumbnail({ asset, cellSize, onClick: handleClick, src })}
        </div>
      );
    });
  });

  return (
    <div ref={resizeRef} className={styles.container}>
      <div style={containerStyle}>{tiles}</div>
    </div>
  );
}
