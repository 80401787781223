import React, { useState } from 'react';
import useExperimentTags from '@shared/hooks/useExperimentTags';
import ExperimentTag from '@shared/components/TableCells/ExperimentTag';
import { TextButton } from '@ds';

type ExperimentTagListProps = {
  tagList: string[];
  experimentKey: string;
  maxVisibleCount?: number;
};

const ExperimentTagList: React.FC<ExperimentTagListProps> = ({
  tagList,
  experimentKey,
  maxVisibleCount = 5
}) => {
  const [showAll, setShowAll] = useState(false);
  const experimentTagList = useExperimentTags({
    filteredList: tagList
  });

  if (!experimentTagList.length) {
    return null;
  }

  const hiddenTagsCount = experimentTagList.length - maxVisibleCount;
  const buttonText = showAll
    ? 'Minimize'
    : `Show ${hiddenTagsCount} more tags...`;
  const toggleShowAll = () => setShowAll(prev => !prev);

  const renderedList = showAll
    ? experimentTagList
    : experimentTagList.slice(0, maxVisibleCount);

  return (
    <div className="flex column alignStart">
      <div className="tag-row-container">
        {renderedList.map(tag => (
          <ExperimentTag
            key={tag.name}
            tagName={tag.name}
            tagColor={tag.color}
            experimentKey={experimentKey}
          />
        ))}
      </div>
      {hiddenTagsCount > 0 && (
        <TextButton
          size="small"
          className="expand-tags-button"
          type="tertiary"
          onClick={toggleShowAll}
        >
          {buttonText}
        </TextButton>
      )}
    </div>
  );
};

export default ExperimentTagList;
