import keymirror from 'keymirror';

/*

FETCH
RECEIVE

CREATE
SET
UPDATE
DELETE

CLEAR

BEGIN
COMPLETED

*/

const actionTypeCreator = (entity, actionTypes) => {
  const actionType = {};
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const key in actionTypes) {
    if (actionTypes.hasOwnProperty(key)) {
      actionType[key] = `[${entity}] ${actionTypes[key]}`;
    }
  }

  return actionType;
};

// prettier-ignore
export const experimentActionTypes = actionTypeCreator("Experiments", {
  SET_EXPERIMENT_KEYS_FOR_DETAILS_PAGE: "Set experiment keys for details page",

  CLEAR_EXPERIMENTS_UI: "Clear the ui state for experiments",

  FETCH_EXPERIMENT_WITH_USER_UNSUCCESSFUL: "Fetch experiment with user unsuccessful",

  RECEIVED_WITHOUT_USERS_LATEST_EXPERIMENT: "Received without users latest experiment",

  RECEIVED_USERS_LATEST_EXPERIMENT: "Received users latest experiment",

  SET_EXPERIMENT_NAME: "Set experiment name"
});

export const projectsActionTypes = actionTypeCreator('Projects', {
  CLEAR_PROJECTS_ERRORS: 'Clear project errors',

  VALIDATE_PROJECT_BEING_ADDED: 'Validate project being added',

  SET_SELECTED_PROJECT_ID: 'Set selected project id',

  FETCH_PROJECT: 'Fetch data for single project',

  SET_PROJECT: 'Set data for single project',

  SET_ACTIVE_EM_HEADER_TAB: 'Set active EM header tab',

  SET_ACTIVE_LLM_HEADER_TAB: 'Set active LLM header tab',

  CLEAR_PROJECTS_UI: 'Clear projects ui state',

  SET_SHAREABLE_LINKS: 'Set shareable project links',

  SET_ACTIVE_EXPERIMENTS_HEADER_TAB: 'Sets active tab in ExperimentsHeader',

  SET_STORED_EXPERIMENT_SELECTION: 'Sets stored experiment selection',

  SET_EDIT_VIEW_NAME: 'Set Edit View Name',

  SET_VIEW_HAS_UNSAVED_CHANGES: 'Set view has unsaved changes'
});

export const userActionTypes = actionTypeCreator('User', {
  LOGGED_OUT_USER: 'Logged out user',

  REQUEST_USER: 'Requesting user authentication information',

  RECEIVE_USER: 'Received user authentication information',

  TOGGLE_LOGIN_MODAL: 'Toggled login modal',

  RECEIVED_API_KEY: 'Received backend API key',

  RECEIVED_OPT_IN_NOTIFICATION: 'Received opt-in notification for workspace',

  RECEIVED_OPT_IN_MPM_NOTIFICATION:
    'Received opt-in mpm notification for workspace',

  SET_USER_ACADEMIC_DETAILS: 'Set user academic information.',

  SET_USER_PROFILE_IMAGES: 'Set user profile images',

  SET_ORGANIZATION: 'Set organization',

  SET_IS_FIRST_SESSION: 'Set is first session'
});

export const notesActionTypes = actionTypeCreator('Notes', {
  RECEIVE_PROJECT_NOTE: 'Received note used on project page',

  BEGIN_FETCH_NOTE: 'Begin fetching notes for projects and experiments',

  CLEAN_NOTES: 'Clean notes for projects and experiments',

  SET_LAST_SAVE: 'Set last notes save'
});

export const chartActionTypes = actionTypeCreator('Chart Tab Charts', {
  FETCH_PROJECT_CHART_TEMPLATE: 'Fetching project chart template',

  RECEIVED_PROJECT_CHART_TEMPLATE: 'Received project chart template',

  SET_CURRENT_TEMPLATE: 'Set current template',

  FETCH_USER_TEMPLATES: 'Fetching user templates',

  RECEIVED_USER_TEMPLATES: 'Received user templates',

  RECEIVED_SAVED_TEMPLATE: 'Received saved template after saved to server',

  UPDATE_CHART_TEMPLATE: 'Update chart template'
});

export const experimentDetailsUiActionTypes = actionTypeCreator(
  'Experiment Details UI',
  {
    SET_IS_HEADER_TABLE_SHOWN: 'Set Is Header Table Shown',
    SET_IS_NEEDED_TO_RESET_LOCAL_CHANGES: 'Set Is Needed to Reset Local Changes'
  }
);

// prettier-ignore
export const teamsActionTypes = actionTypeCreator("Teams", {
  COMPLETED_USER_DEFAULT_TEAM_SET: "Completed setting default team"
});

export const imagesActionTypes = keymirror({
  // Action to start fetch graphics data.
  ADD_DATA_TO_IMAGES: null
});

export const filesActionTypes = keymirror({
  // Makes request for the files associated with a project
  REQUEST_FILES_FOR_PROJECT: null,
  // After receiving files for project
  RECEIVE_FILES_FOR_PROJECT: null
});

export const alertsActionTypes = actionTypeCreator('Alerts', {
  OPEN_SNACKBAR: 'Open snackbar',

  CLOSE_SNACKBAR: 'Close snackbar',

  OPEN_DIALOG: 'Open dialog',

  CLOSE_DIALOG: 'Close dialog',

  OPEN_CUSTOM_DIALOG: 'Open custom dialog',

  CLOSE_CUSTOM_DIALOG: 'Close custom dialog',

  ADD_CUSTOM_DIALOG_STATE: 'Add custom dialog state',

  RESET_CUSTOM_DIALOG_STATE: 'Reset custom dialog state',
  // Hides warning alert about limited list of grouped experiments to one hundred
  HIDE_GROUPED_EXPERIMENTS_ALERT: 'Hide limited list of grouped experiments',

  OPEN_THROTTLING_POPUP: 'Open throttling pop-up',
  CLOSE_THROTTLING_POPUP: 'Close throttling pop-up'
});

export const paymentsActionTypes = keymirror({
  // No onboarded collaborators
  NO_ONBOARDED_COLLABORATORS_INVITED: null,
  // Completed inviting all collaborators
  COMPLETED_INVITING_ONBOARDED_COLLABORATORS: null,
  // Begin sending notification email
  BEGIN_SENDING_NOTIFICATION_EMAIL: null,
  // Sent notification email
  SENT_NOTIFICATION_EMAIL: null,
  // Revert from payment to new workspace
  REVERT_FROM_PAYMENT_TO_NEW_WORKSPACE: null,
  // Pay before setting up the plan setup
  PAY_BEFORE_FINISHED_ALL_PLAN_SETUP: null,
  // Failed payment
  FAILED_PAYMENT_TRY_AGAIN: null,
  // Prevent trying to send during send
  ATTEMPT_TO_SUBMIT_DURING_WHEN_IS_SENDING: null,
  // Update tenative upgrade plan
  UPDATE_TENATIVE_UPGRADE_PLAN: null,
  // Return to plan on onboard
  RETURN_TO_PLAN_SECTION: null,
  // Completed to plan selection
  COMPLETED_PLAN_SELECTION: null,
  // Finish all plan set up
  FINISHED_ALL_PLAN_SETUP: null,
  // Error getting workspace
  ERROR_GETTING_WORKSPACE_PAYMENT_SETTINGS: null,
  // Fetch workspace payment settings
  FETCH_WORKSPACE_PAYMENT_SETTINGS: null,
  // Received workspace payment settings
  RECEIVED_WORKSPACE_PAYMENT_SETTINGS: null,
  // Update payment plan for new team
  UPDATE_PAYMENT_PLAN_FOR_NEW_TEAM: null,
  // Trigger action for purchase
  PURCHASE_ACTION_PLAN_FOR_ONBOARDING: null,
  // Completed plan setup
  COMPLETE_PLAN_SETUP: null,
  // Start public workspace
  START_INDIVIDUAL_WORKSPACE_ON_USER: null,
  // Submit final onboarding step
  SUBMIT_FINAL_ONBOARDING_STEP: null,
  // Proceed through plan selection
  PROCEEDING_THROUGH_PLAN_SELECTION: null,
  // Edit workspace name
  EDIT_WORKSPACE_NAME: null,
  // Add queued collaborator
  ADD_ONBOARDED_COLLABORATOR: null,
  // Save workspace name for onboard
  SAVE_WORKSPACE_NAME_FOR_ONBOARDING: null,
  // Save onboarding payment plan
  SAVE_PAYMENT_PLAN_FOR_ONBOARDING: null,
  // Giving user a payment plan from pricing table modal
  ADD_PAYMENT_PLAN_FROM_MODAL: null,
  // Giving user a payment plan from payment page
  ADD_PAYMENT_PLAN_FROM_PAGE: null,
  // Clear all messages associated with payments for snackbar
  CLEAR_PAYMENT_MESSAGES: null,
  // Show payment success button
  SHOW_PAYMENT_SUCCESS_BTN: null,
  // Hide payment success button
  HIDE_PAYMENT_SUCCESS_BTN: null,
  // Receive user payment plan after fetch
  RECEIVED_PAYMENT_PLAN: null,
  // Store stripe object
  STORE_STRIPE_OBJECT: null,
  // Submit user payments stripe plan
  PAYMENT_STRIPE_USER_PLAN: null,
  // Submit users personal plan payload
  SUBMIT_USERS_PERSONAL_PLAN: null,
  // Success submited users personal plan payload
  SUCCESS_SUBMITED_USERS_PERSONAL_PLAN: null,
  // Submit after no token found for personal plan
  NO_TOKEN_USERS_PERSONAL_PLAN: null,
  // Success submited team plan payload
  SUCCESS_SUBMITED_TEAM_PLAN: null,
  // Submit after no token found for team
  NO_TOKEN_TEAM_PLAN: null,
  // Save team name for stripe
  SAVE_TEAM_NAME_FOR_STRIPE: null,
  // Save billing email for stripe
  SAVE_BILLING_EMAIL_FOR_STRIPE: null,
  // Save team seat amount for stripe
  SAVE_TEAM_SEAT_AMOUNT_FOR_STRIPE: null,
  // Submit after no token found for personal plan
  SKIP_USERS_PERSONAL_PLAN_SETUP: null,
  // Submit no team plan
  SKIP_NO_TEAM_PLAN: null,
  // Submit stripe payment for team pro plan
  START_SUBMIT_STRIPE_PAYMENT_PLAN: null,
  // Submit team pro plan
  SUBMIT_WORKSPACE_PAID_PLAN: null,
  // Success submitted team pro plan
  SUCCESS_SUBMITED_WORKSPACE_PAYMENT_PLAN: null,
  // Submit after no token found for team plan
  SKIP_USERS_TEAM_PLAN_SETUP: null,
  // Check for whether sign up should redirect to team setup
  SAVE_TEAM_NEW_STATUS_DURING_SIGN_UP: null,
  // Process validated team name
  RECEIVED_VALID_TEAM_NAME: null,
  // Error received invalid team name
  ERROR_RECEIVED_INVALID_TEAM_NAME: null,
  // Error with team name request
  ERROR_TEAM_NAME_REQUEST: null,
  // Start trial for the user team plan
  START_TRIAL_USERS_TEAM_PLAN_SETUP: null,
  // Confirmed a user has a plan
  CONFIRMED_USER_HAS_PLAN: null,
  // No user plan found so redirect to signup
  NO_USER_PLAN_FOUND_REDIRECT: null,
  // Confirmed a team has a plan
  CONFIRMED_TEAM_HAS_PLAN: null,
  // No team plan found so redirect to signup
  NO_TEAM_PLAN_FOUND_REDIRECT: null,
  // Payment plan does not match the permissions for the action
  PAYMENT_PLAN_NOT_MATCH_ACTION_PERMISSIONS: null
});

export const systemMetricsTableActionTypes = keymirror({
  // Update the system metric entire row charts
  UPDATE_ENTIRE_ROW_CHARTS: null,
  // Update the system metric x-axis
  UPDATE_X_AXIS_TYPE: null,
  // Update the system metric x-axis for compare page
  UPDATE_COMPARE_X_AXIS_TYPE: null
});

export const forgotPasswordActionTypes = keymirror({
  // rReset password success
  RESET_SUCCESS: null,
  // Email sent
  EMAIL_SENT: null,
  // Request email
  EMAIL_SEND_START: null,
  // Loading
  LOADING: null,
  // Received token
  RECEIVED_TOKEN: null,
  // Error of any kind (with text)
  ERROR_WITH_TEXT: null,
  // Error sending email
  EMAIL_SENT_ERROR: null,
  // reset error for ALL events
  RESET_ERROR: null,
  // The user's email
  UPDATE_EMAIL: null,
  // Reset the state to the initial state
  RESET_STATE: null,
  // Error in sending new password
  NEW_PASSWORD_ERROR: null
});

export const dashboardChartActionTypes = keymirror({
  // Update the chart form on the dashboard chart modal
  UPDATE_CHART_FORM: null,
  // reset the chart form by chart type on the dashboard chart modal
  RESET_CHART_FORM_BY_TYPE: null,
  // reset all fields for all types on the dashboard chart modal
  RESET_ENTIRE_CHART_FORM: null,
  // set section id for Panel Modal
  SET_ACTIVE_SECTION_ID: null
});

export const betaFeaturesActionTypes = keymirror({
  // Set beta feature toggles
  SET_FEATURE_TOGGLES: null
});

export const rpcActionTypes = actionTypeCreator('RPC', {
  SEND_RPC: 'Sent RPC with function name: '
});

export const vizActionTypes = actionTypeCreator('Visualizations', {
  SET_CODE_PREVIEW: 'Set state to render code preview',

  SET_CODE_PREVIEW_LANGUAGE: 'Set state to render code preview language',

  SET_DEFAULT_CONFIG: 'Set default config preview',

  SET_PY_CONFIG: 'Set py config preview',

  FETCH_VISUALIZATION_TEMPLATE: 'Fetch template',

  FETCH_ALL_VISUALIZATION_TEMPLATES: 'Fetch all templates',

  UPDATE_VISUALIZATION_TEMPLATE: 'Update template',

  SET_VISUALIZATION_TEMPLATE: 'Set template',

  DELETE_VISUALIZATION_TEMPLATE: 'Delete template',

  SET_VISUALIZATION_TEMPLATES: 'Set templates',

  SET_VISUALIZATION_INSTANCE: 'Set instance',

  DELETE_VISUALIZATION_INSTANCE: 'Delete instance',

  SET_SRC_DOC: 'Set srcDoc for code preview',

  SET_IS_TEMPLATE_SAVED: 'Set whether the template changes are saved',

  SET_THUMBNAIL: 'Set thumbnail for new template',

  SET_INTERNAL_RESOURCES: 'Set internal resources',

  SET_TEMPLATE_NAME_FIELD: 'Set template name field',

  SET_TEMPLATE_QUERY_BUILDER_ID: 'Set template query builder ID',

  SET_TEMPLATE_SCOPE_TYPE: 'Set template scope type',

  RESET_MUTABLE_TEMPLATE_FIELDS: 'Reset mutable template fields',

  SET_SAVED_GALLERY_TAB: 'Set saved gallery tab',

  SET_REVISION_IDS: 'Set revision IDs',

  SET_PROJECT_TOKEN: 'Set project token',

  SET_GALLERY_TEMPLATE_MAP: 'Set gallery template map',

  SET_GALLERY_TEMPLATE: 'Set gallery template',

  SET_GALLERY_TEMPLATE_COUNTS: 'Set gallery template counts',

  SET_PREVIEW_TEMPLATE_INSTANCES: 'Set preview template instances',

  SET_HOVERED_EXPERIMENT_TRACE: 'Set hovered experiment key in table',

  ADD_TEMPLATE_FETCHING_ERROR: 'Add template fetching error'
});

export const workspaceActionTypes = actionTypeCreator('Workspace', {
  SET_TAB_STATE_BY_KEY: 'Set tab state by key',

  SET_TAB_STATE: 'Set tab state',

  SET_ITEM_COUNT_AND_DISPLAY: 'Set tab badge count and display format',

  SET_MANAGE_WORKSPACE_MODAL_STATE: 'Set Manage Users Modal state',

  SET_ACTIVE_WORKSPACE_NAME: 'Set users active workspace name',

  SET_ALL_USER_WORKSPACE_LIST: 'Set all user workspacecs',

  SET_BREADCRUMBS_ITEMS: 'Set breadcrumbs items'
});

export const modelRegistryActionTypes = actionTypeCreator('Model Registry', {
  SET_REGISTERED_MODEL: 'Set registered model',

  SET_REGISTERED_MODEL_WITH_NEW_NAME: 'Set registered model with a new name',

  SET_MODEL_REGISTRY_MAP: 'Set models map',

  SET_MODEL_REGISTRY_COUNT: 'Set number of registered models',

  SET_MODEL_NAME_VALIDATION: 'Set validated model name',

  SET_MODEL_VERSION_VALIDATION: 'Set validated model version',

  DELETE_REGISTERED_MODEL: 'Delete registered model',

  OPEN_DELETE_MODEL_MODAL: 'Open delete model modal',

  CLOSE_DELETE_MODEL_MODAL: 'Close delete model modal',

  OPEN_EDIT_MODEL_MODAL: ' Open edit model modal',

  CLOSE_EDIT_MODEL_MODAL: 'Close edit model modal'
});

export const artifactActionTypes = actionTypeCreator('Artifacts', {
  SET_ARTIFACTS_MAP: 'Set artifacts',

  SET_ARTIFACT: 'Set artifact',

  DELETE_ARTIFACT: 'Delete artifact',

  DELETE_ARTIFACT_VERSION: 'Delete artifact version',

  SET_ARTIFACTS_COUNT: 'Set number of artifacts for workspace',

  SET_ARTIFACT_VERSION: 'Set artifact version files',

  SET_ARTIFACT_VERSION_COMMENTS: 'Set artifact version comments',

  SET_ARTIFACT_VERSION_COMMENT: 'Set artifact version comment',

  DELETE_ARTIFACT_VERSION_COMMENT: 'Delete artifact version comment',

  SET_ARTIFACT_VERSION_USAGE: 'Set artifact version usage'
});

export const reportActionTypes = actionTypeCreator('Reports', {
  SET_REPORTS: 'Set list of all reports'
});

export const mpmActionTypes = actionTypeCreator('MPM', {
  SET_TIME_RANGE: 'Set time range',
  SET_INTERVAL_TYPE: 'Set interval type'
});

export const organizationActionTypes = actionTypeCreator('Organization', {
  SET_ACTIVE: 'Set active organization',
  SET_ORGANIZATION_LIST: 'Set organization list'
});

export const biEventActionTypes = actionTypeCreator('BI event', {
  ADD_SENT_RENDERED_PANEL: 'Add sent rendered panel',
  FIRST_QUICK_START_CLICK_DONE: 'First quick start page click is done',
  FIRST_GET_STARTED_CLICK_DONE: 'First get started page click is done'
});

export const onboardingActionTypes = actionTypeCreator('Onboarding', {
  SET_FRAMEWORK: 'Set framework in the quickstart page'
});
