import React from 'react';
import PropTypes from 'prop-types';

import ActiveStatusIcon from '@experiment-management-shared/components/ActiveStatusIcon';

const RunActive = ({ row }) => {
  const { runActive, throttle, hasCrashed, crashReason } = row;

  return (
    <ActiveStatusIcon
      loading={runActive}
      throttle={throttle}
      crash={hasCrashed || crashReason}
      crashMessage={crashReason}
      success={!runActive}
    />
  );
};

RunActive.propTypes = {
  row: PropTypes.object.isRequired
};

export default RunActive;
