import { useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ViolinPlotHelpers } from '@DesignSystem/charts/Plot';

import {
  PanelCometMetadataLegendKey,
  TooltipData,
  TooltipPosition
} from '@experiment-management-shared/types';
import {
  BAR_CHART_TRACE_TYPE_BOX,
  BAR_CHART_TRACE_TYPE_VIOLIN,
  BUILT_IN_CHART_TYPES,
  TOOLTIP_WAIT_TIMEOUT_FOR_RESET
} from '@experiment-management-shared/constants/chartConstants';
import { getFormattedPlotlyData } from '@experiment-management-shared/components/Charts/ChartHelpers';
import useChartTooltipTrackEvent from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartTooltipTrackEvent';

type PlotlyComponentRef = {
  current: {
    el: {
      calcdata: { [key: number]: { [key: number]: { [key: string]: number } } };
    };
  };
};

const getCalcData = (
  plotComponent: PlotlyComponentRef,
  curveNumber: number,
  pointNumber: number
) => {
  return (
    plotComponent.current?.el?.calcdata?.[curveNumber]?.[pointNumber] || {}
  );
};

const calculateViolinDataKeys = (
  event: MouseEvent,
  calcData: { [key: string]: number },
  type: string,
  xaxis: Plotly.LayoutAxis,
  yaxis: Plotly.LayoutAxis,
  orientation: string
) => {
  let retVal: PanelCometMetadataLegendKey[] = [];
  let axis, vVal, title;

  if (orientation === 'h') {
    title = 'x';
    axis = xaxis;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    vVal = xaxis.p2l(event.offsetX - xaxis._offset);
  } else {
    title = 'y';
    axis = yaxis;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    vVal = yaxis.p2l(event.offsetY - yaxis._offset);
  }

  if (type === BAR_CHART_TRACE_TYPE_VIOLIN) {
    let kde = '';

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ViolinPlotHelpers.getKdeValue) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        kde = ViolinPlotHelpers.getKdeValue(calcData, calcData.trace, vVal);
      }
    } catch (e) {
      /* empty */
    }

    retVal = [
      {
        title,
        value: getFormattedPlotlyData(vVal, axis),
        formatted: true
      },
      {
        title: 'kde',
        value: kde
      }
    ];
  }

  return retVal;
};

const calculateDataKeys = (
  calcData: { [key: string]: number },
  metricName: string,
  xaxis: Plotly.LayoutAxis,
  yaxis: Plotly.LayoutAxis,
  orientation: string,
  violinDataKeys: PanelCometMetadataLegendKey[]
) => {
  let axis, title;
  if (orientation === 'h') {
    title = 'X-Axis';
    axis = xaxis;
  } else {
    title = 'Y-Axis';
    axis = yaxis;
  }

  const baseKeys = [
    {
      title: title,
      value: metricName,
      formatted: true
    }
  ];

  const genericKeys = [
    {
      title: 'max',
      value: getFormattedPlotlyData(calcData['max'], axis),
      formatted: true
    },
    {
      title: 'q3',
      value: getFormattedPlotlyData(calcData['q3'], axis),
      formatted: true
    },
    {
      title: 'median',
      value: getFormattedPlotlyData(calcData['med'], axis),
      formatted: true
    },
    {
      title: 'q1',
      value: getFormattedPlotlyData(calcData['q1'], axis),
      formatted: true
    },
    {
      title: 'min',
      value: getFormattedPlotlyData(calcData['min'], axis),
      formatted: true
    }
  ];

  return [...baseKeys, ...violinDataKeys, ...genericKeys];
};

export const useBarChartTooltip = (plotComponent: PlotlyComponentRef) => {
  const { interact, stop } = useChartTooltipTrackEvent();
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);

  const resetTooltip = useMemo(() => {
    return debounce(() => {
      stop();
      setTooltipData(null);
    }, TOOLTIP_WAIT_TIMEOUT_FOR_RESET);
  }, [stop]);

  const handlePointHover = useCallback(
    eventData => {
      resetTooltip.cancel();
      const point = eventData.points[0];
      if (
        !point ||
        !(point?.data?.cometMetadata || point?.data?.cometMetadataArray)
      )
        return;

      const { xaxis, yaxis, x, y, pointNumber, curveNumber, data } = point;
      let { color } = data.line || data.marker;
      const position: TooltipPosition = {
        left: point.bbox.x0 + (point.bbox.x1 - point.bbox.x0) / 2,
        top: point.bbox.y0 + (point.bbox.y1 - point.bbox.y0) / 2
      };
      const pointSize = point.bbox.x1 - point.bbox.x0;

      if (isArray(data?.cometMetadataArray)) {
        data.cometMetadata = data?.cometMetadataArray[pointNumber];
        color = color[pointNumber];
      }

      if (
        (BAR_CHART_TRACE_TYPE_VIOLIN === data.type ||
          BAR_CHART_TRACE_TYPE_BOX === data.type) &&
        plotComponent
      ) {
        const calcData = getCalcData(plotComponent, curveNumber, pointNumber);

        const violinDataKeys = calculateViolinDataKeys(
          eventData.event,
          calcData,
          point.data.type,
          xaxis,
          yaxis,
          point.data.orientation
        );

        const dataKeys = calculateDataKeys(
          calcData,
          data.cometMetadata.metricName,
          xaxis,
          yaxis,
          point.data.orientation,
          violinDataKeys
        );

        data.cometMetadata = {
          ...data.cometMetadata,
          dataKeys
        };
      }

      const groupingPoint =
        point?.data?.cometMetadata?.paramGroupKeys?.[0]?.title ||
        point?.data?.cometMetadataArray?.[0]?.paramGroupKeys?.[0]?.title;

      const grouping = groupingPoint
        ? {
            enabled: true,
            groupByParameter: groupingPoint
          }
        : null;

      interact(BUILT_IN_CHART_TYPES['BuiltIn/Bar'], grouping);
      setTooltipData({
        x,
        y,
        color,
        pointSize,
        point,
        position
      });
    },
    [plotComponent, resetTooltip, interact]
  );

  const handlePointUnHover = useCallback(() => {
    resetTooltip();
  }, [resetTooltip]);

  const handlePointBeforeHover = useCallback(
    event => {
      setTooltipData(state => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const point = plotComponent.current.el._hoverdata?.[0];

        if (
          point &&
          state?.point &&
          state?.point?.data?.type === BAR_CHART_TRACE_TYPE_VIOLIN
        ) {
          const { xaxis, yaxis, pointNumber, curveNumber } = point;
          const calcData = getCalcData(plotComponent, curveNumber, pointNumber);

          const violinDataKeys = calculateViolinDataKeys(
            event,
            calcData,
            point.data.type,
            xaxis,
            yaxis,
            point.data.orientation
          );

          const dataKeys = calculateDataKeys(
            calcData,
            point.data.cometMetadata?.metricName as string,
            xaxis,
            yaxis,
            point.data.orientation,
            violinDataKeys
          );

          return {
            ...state,
            position: {
              left: point.bbox.x0 + (point.bbox.x1 - point.bbox.x0) / 2,
              top: point.bbox.y0 + (point.bbox.y1 - point.bbox.y0) / 2
            },
            point: {
              ...state.point,
              data: {
                ...state.point.data,
                cometMetadata: {
                  ...state.point.data.cometMetadata,
                  dataKeys
                }
              }
            }
          };
        }

        return state;
      });
    },
    [plotComponent]
  );

  return {
    handlePointBeforeHover,
    handlePointHover,
    handlePointUnHover,
    tooltipData
  };
};
