import React from 'react';

import styles from './EmptyExperimentTab.module.scss';
import EmptyExperimentTabTitle from './components/EmptyExperimentTabTitle';
import EmptyExperimentTabIcon from './components/EmptyExperimentTabIcon';
import EmptyExperimentTabDescription from './components/EmptyExperimentTabDescription';
import EmptyExperimentTabDocsLink from './components/EmptyExperimentTabDocsLink';
import EmptyExperimentLink from './components/EmptyExperimentLink';
import EmptyExperimentTabCell from './components/EmptyExperimentTabCell';
import cx from 'classnames';

type EmptyExperimentTabProps = {
  children: React.ReactNode;
  className?: string;
};
const EmptyExperimentTab = ({
  children,
  className
}: EmptyExperimentTabProps) => {
  return (
    <div
      className={cx(styles.emptyTabContainer, className, 'empty-tab-message')}
    >
      {children}
    </div>
  );
};

EmptyExperimentTab.Icon = EmptyExperimentTabIcon;
EmptyExperimentTab.Title = EmptyExperimentTabTitle;
EmptyExperimentTab.Description = EmptyExperimentTabDescription;
EmptyExperimentTab.DocsLink = EmptyExperimentTabDocsLink;
EmptyExperimentTab.Link = EmptyExperimentLink;
EmptyExperimentTab.Cell = EmptyExperimentTabCell;

export default EmptyExperimentTab;
