import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@ds';
import { BasicModal } from '@DesignSystem/modals';

import './APIKeys.scss';

const ManageAPIKey = ({ onGenerateKey, isGenerating }) => {
  const [isOpenAreYouSure, setIsOpenAreYouSure] = useState(false);

  const openAreYouSure = () => setIsOpenAreYouSure(true);
  const closeAreYouSure = () => setIsOpenAreYouSure(false);

  const handleContinueClick = async () => {
    await onGenerateKey();
    closeAreYouSure();
  };

  const renderAreYouSureContent = () => (
    <div className="are-you-sure-container">
      The API Key belonging to this user will be removed and a new one will be
      created.
    </div>
  );

  return (
    <>
      <BasicModal
        open={isOpenAreYouSure}
        onClose={closeAreYouSure}
        title="Are You Sure?"
        primaryButtonText="Continue"
        onPrimaryButtonClick={handleContinueClick}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeAreYouSure}
        content={renderAreYouSureContent()}
        isPrimaryButtonDisabled={isGenerating}
      />
      <span className="section-title">Manage Your API Key</span>
      <div className="field-label">
        You can generate a new API key. This action will delete your current API
        Key and create a new one.
      </div>
      <TextButton
        className="generate-key-button"
        type="tertiary"
        onClick={openAreYouSure}
      >
        Generate new API key
      </TextButton>
    </>
  );
};

ManageAPIKey.propTypes = {
  onGenerateKey: PropTypes.func.isRequired,
  isGenerating: PropTypes.bool.isRequired
};

export default ManageAPIKey;
