import React, { useState, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { matchPath, useHistory, RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Button } from '@ds';
import { SectionNavBar, SectionTabs } from '@DesignSystem/navigation';
import {
  useFeature,
  useGetModelRegistryNameById,
  useAlertsNotificationCount
} from '@mpm/api';
import { BETA_FEATURE_MODEL_PRODUCTION_MONITORING_PARSER } from '@/lib/betaFeatures';
import { SUB_PATHS } from '@/constants/urlConstants';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import useMPMPageBreadcrumbs from '@mpm/hooks/useMPMPageBreadcrumbs';
import SmallLoader from '@shared/components/SmallLoader';
import { ModelFeaturePerformancePage } from '../ModelFeaturePerformancePage';
import { ModelFeatures } from '../ModelFeatures';
import { InfixPostfixParserPage } from '../InfixPostfixParserPage';
import { RecursiveParserPage } from '../RecursiveParserPage';
import { CustomTransformationsPage } from '../CustomTransformationsPage';
import { DebuggerPage } from '../DebuggerPage';
import { ModelPerformancePage } from '../ModelPerformancePage';
import { useActiveWorkspace } from '@shared/hooks';

import { AlertNotificationsPage } from '../AlertNotificationsPage';
import { AlertRulesPage } from '../AlertRulesPage';
import { AlertsPage } from '../AlertsPage';
import { AddAlertPage } from '../AddAlertPage';
import { FairnessPage } from '../FairnessPage';
import {
  DEFAULT_SEGMENTS_ID,
  DEFAULT_SEGMENTS_LINE_COLOR,
  DEFAULT_SEGMENT_VALUE
} from '@mpm/constants';
import './ModelsPage.scss';
import { DSPlusIcon } from '@ds-icons';

type MatchParams = {
  modelId: string;
  workspace: string;
  url: string;
};

type ModelsPageProps = {
  match: RouteComponentProps<MatchParams>['match'];
};

export const ModelsPage = ({ match }: ModelsPageProps) => {
  const [activeSegmentGroupColorIds, setActiveSegmentGroupColorIds] = useState([
    {
      color: DEFAULT_SEGMENTS_LINE_COLOR,
      id: DEFAULT_SEGMENTS_ID,
      segmentValue: DEFAULT_SEGMENT_VALUE
    }
  ]);

  const [
    fairnessSegmentGroupColorIds,
    setFairnessSegmentGroupColorIds
  ] = useState([
    {
      color: DEFAULT_SEGMENTS_LINE_COLOR,
      id: DEFAULT_SEGMENTS_ID,
      segmentValue: DEFAULT_SEGMENT_VALUE
    }
  ]);

  const isSegmentsData =
    activeSegmentGroupColorIds?.length > 1 ||
    activeSegmentGroupColorIds[0]?.id !== DEFAULT_SEGMENTS_ID;

  const activeWorkspace = useActiveWorkspace();

  const { workspace, modelId } = match.params;
  const { data: modelData, isLoading } = useGetModelRegistryNameById({
    modelId,
    workspaceId: activeWorkspace?.id
  });
  const history = useHistory();

  const { data: alertNotificationData } = useAlertsNotificationCount(
    {
      modelId
    },
    {
      refetchInterval: 15000,
      notifyOnChangeProps: ['data']
    }
  );

  const modelPageTabs = useMemo(() => {
    const notifications =
      alertNotificationData && alertNotificationData.count > 99
        ? '99+'
        : alertNotificationData?.count || 0;
    return [
      { id: 'PERFORMANCE', label: 'Model Performance', url: 'performance' },
      { id: 'FEATURES', label: 'Features', url: 'features' },
      {
        id: 'FAIRNESS',
        label: 'Fairness',
        url: 'fairness'
      },
      {
        id: 'ALERTS',
        label: 'Alerts',
        url: 'alerts',
        notificationCount: notifications
      },
      { id: 'SETTINGS', label: 'Settings', url: 'settings' },
      { id: 'DEBUGGER', label: 'Debugger', url: 'debugger' }
    ];
  }, [alertNotificationData?.count]);

  const isParserEnabled = useSelector(state =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_PARSER
    })
  );

  const featurePageMatchPatch = matchPath(history.location.pathname, {
    path: `${match.path}/features/:featureId`
  });

  const alertsPageMatchPath = matchPath(history.location.pathname, {
    path: `${match.path}/alerts`
  });

  const { data: featureData } = useFeature({
    modelId,
    featureId: (featurePageMatchPatch?.params as { featureId?: string })
      ?.featureId
  });

  const modelTabsIndexes = modelPageTabs.reduce((map, { id }, index) => {
    map[id] = index;
    return map;
  }, {} as Record<string, number>);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useMPMPageBreadcrumbs({
    model: modelData?.model?.name,
    feature: featureData?.name
  });

  const handleTabChange = (newActiveTabIndex: number) => {
    history.push(`${match.url}/${modelPageTabs[newActiveTabIndex].url}`);
    setActiveTabIndex(newActiveTabIndex);
  };

  const handleAddAlert = () => {
    history.push(`${match.url}/alerts/add`);
  };

  if (isLoading || !activeWorkspace)
    return (
      <div style={{ height: 'calc(100vh - var(--navigation-header-height))' }}>
        <SmallLoader isFlexDisplay />
      </div>
    );

  if (!activeWorkspace?.id) {
    return <h4>This is a private project</h4>;
  }

  return (
    <>
      <SectionNavBar
        isSticky={!!alertsPageMatchPath}
        style={{ top: 'var(--navigation-header-height)' }}
        documentTitle={`${workspace}/${SUB_PATHS.MODEL_PRODUCTION_MONITORING}/${modelId}`}
      >
        <div className="model-page-header">
          <SectionTabs
            variant="secondary"
            tabs={modelPageTabs}
            tabHeight={39}
            activeTabIndex={activeTabIndex}
            onTabChange={handleTabChange}
            withBottomBorder={false}
          />
          {activeTabIndex === modelTabsIndexes.ALERTS &&
            !history.location.pathname.includes('/alerts/add') && (
              <Button onClick={handleAddAlert} PrefixIcon={<DSPlusIcon />}>
                Add Alert Rule
              </Button>
            )}
        </div>
      </SectionNavBar>

      <Switch>
        <Route
          path={`${match.path}/features`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.FEATURES);
            return <ModelFeatures model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/features/:featureId`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.FEATURES);
            return (
              <ModelFeaturePerformancePage
                activeSegmentGroupColorIds={activeSegmentGroupColorIds}
                match={match}
                feature={featureData}
                model={modelData.model}
                setActiveSegmentGroupColorIds={setActiveSegmentGroupColorIds}
                isSegmentsData={isSegmentsData}
              />
            );
          }}
        />
        <Route
          path={`${match.path}/fairness`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.FAIRNESS);
            return (
              <FairnessPage
                model={modelData.model}
                activeSegmentGroupColorIds={fairnessSegmentGroupColorIds}
                setActiveSegmentGroupColorIds={setFairnessSegmentGroupColorIds}
              />
            );
          }}
        />
        <Route
          path={`${match.path}/performance`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.PERFORMANCE);
            return (
              <ModelPerformancePage
                activeSegmentGroupColorIds={activeSegmentGroupColorIds}
                match={match}
                model={modelData.model}
                setActiveSegmentGroupColorIds={setActiveSegmentGroupColorIds}
                isSegmentsData={isSegmentsData}
              />
            );
          }}
        />
        {isParserEnabled && (
          <Route
            path={`${match.path}/parser`}
            exact
            render={() => {
              return <InfixPostfixParserPage />;
            }}
          />
        )}
        {isParserEnabled && (
          <Route
            path={`${match.path}/recursive-parser`}
            exact
            render={() => {
              return <RecursiveParserPage model={modelData.model} />;
            }}
          />
        )}
        <Route
          path={`${match.path}/settings`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.SETTINGS);
            return <CustomTransformationsPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/debugger`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.DEBUGGER);
            return <DebuggerPage />;
          }}
        />
        <Route
          path={`${match.path}/alerts`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AlertsPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/alerts/notifications`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AlertNotificationsPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/alerts/rules`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AlertRulesPage model={modelData.model} />;
          }}
        />
        <Route
          path={`${match.path}/alerts/add`}
          exact
          render={() => {
            setActiveTabIndex(modelTabsIndexes.ALERTS);
            return <AddAlertPage model={modelData.model} />;
          }}
        />
        <Route path="*">
          <Redirect to={`${match.url}/performance`} />
        </Route>
      </Switch>
    </>
  );
};
