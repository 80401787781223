import React, {
  useState,
  useMemo,
  useCallback,
  Fragment,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FixedSizeList as List } from 'react-window';

import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import toLower from 'lodash/toLower';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import first from 'lodash/first';
import isNumber from 'lodash/isNumber';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Divider from '@material-ui/core/Divider';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { getArtifactVersionFiles } from '@/reducers/artifactsReducer';
import {
  getAssetPreviewConfig,
  isAssetWithPreview,
  isRemoteFile
} from '@experiment-management-shared/utils/assetsUtils';

import { COLORS } from '@/constants/colorConstants';
import {
  formatBytes,
  getUniqValuesFromArrayOfObjects
} from '@/helpers/generalHelpers';
import FilterPopover from '@shared/components/FilterPopover';
import StyledSearchBar from '@shared/components/StyledComponents/StyledSearchBar';
import { filterItems, searchItems } from '@/constants/workspaceConstants';
import AssetPreviewContainer from '@experiment-management-shared/components/AssetsPreview/AssetPreviewContainer';
import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import { getDownloadAssetURL } from '@/util/assetsApi';
import { IconButton, Tooltip } from '@ds';
import { DSDownloadIcon } from '@ds-icons';

const SORT_BY_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};

const StyledTreeItem = withStyles(() => ({
  root: {
    '&:focus > $content, &$selected > $content': {
      backgroundColor: COLORS.treeItemHover
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    },
    '&$selected > $content $label': {
      backgroundColor: 'transparent !important',
      fontWeight: 'bold'
    }
  },
  content: {
    '&:hover': {
      backgroundColor: COLORS.treeItemHover
    }
  },
  group: {
    marginLeft: 0
  },
  expanded: {},
  selected: {},
  label: {
    width: 'calc(100% - 30px)'
  },
  labelText: {
    fontWeight: 'inherit'
  }
}))(TreeItem);

const getDirectoryFilters = items => {
  return [
    {
      groupLabel: 'Asset type',
      filterKey: 'type',
      options: getUniqValuesFromArrayOfObjects(items, 'type').map(type => ({
        label: type,
        value: type
      }))
    }
  ];
};

const TREE_COLUMNS = {
  NAME: { key: 'fileName', label: 'NAME', width: '70%' },
  SIZE: { key: 'fileSize', label: 'SIZE', width: '30%' }
};

const searchableFields = ['fileName', 'size'];

const UNKNOWN_DIR = 'null';

const ArtifactVersionAssetsTab = ({
  artifactVersionId,
  experimentKey,
  files
}) => {
  const [order, setOrder] = useState(SORT_BY_DIRECTION.ASC);
  const [orderedBy, setOrderedBy] = useState(TREE_COLUMNS.NAME.key);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [directoryMap, setDirectoryMap] = useState({});
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const filteredItems = filterItems(files, selectedFilters);

    const searchedItems = searchItems(
      filteredItems,
      searchText,
      searchableFields
    );

    const filesSortedByDir = orderBy(searchedItems, ['dir']);

    const updatedDirectoryMap = filesSortedByDir.reduce((map, file) => {
      const { dir } = file;
      if (!map.hasOwnProperty(dir)) {
        map[dir] = [file];
      } else {
        map[dir].push(file);
      }

      return map;
    }, {});

    setDirectoryMap(updatedDirectoryMap);
  }, [searchText, selectedFilters, files]);

  useEffect(() => {
    setIsSearching(false);
  }, [directoryMap]);

  const handleOrderBy = property => {
    const { ASC, DESC } = SORT_BY_DIRECTION;
    const isAsc = orderedBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderedBy(property);
  };

  const debouncedSearch = React.useMemo(
    () =>
      debounce(text => {
        setSearchText(text);
      }, 750),
    []
  );

  const handleSearchChange = useCallback(
    text => {
      setIsSearching(true);
      debouncedSearch(text);
    },
    [debouncedSearch]
  );

  const handleCancelSearch = useCallback(() => setSearchText(''), []);

  const handleFilterChange = useCallback(
    (filterKey, values) => {
      const update = {
        ...selectedFilters,
        [filterKey]: values
      };
      setSelectedFilters(update);
    },
    [selectedFilters]
  );

  const handleRowClick = useCallback(file => {
    setSelectedFile(file);

    if (isAssetWithPreview(file, true)) {
      setIsPreviewOpen(true);
    } else {
      setIsPreviewOpen(false);
    }
  }, []);

  const renderColumnSortIcon = columnName => {
    const SortIcon =
      order === SORT_BY_DIRECTION.ASC ? ArrowUpwardIcon : ArrowDownwardIcon;

    const visibility = columnName === orderedBy ? 'visible' : 'hidden';

    return (
      <SortIcon
        className="tree-column-sort-icon"
        style={{
          visibility
        }}
      />
    );
  };

  const renderTreeColumns = () => {
    return Object.values(TREE_COLUMNS).map(({ key, label, width }) => {
      return (
        <div
          key={key}
          className="tree-column-label"
          role="button"
          onClick={() => handleOrderBy(key)}
          style={{ width }}
        >
          {label} {renderColumnSortIcon(key)}
        </div>
      );
    });
  };

  const renderAssetActions = useCallback(
    file => {
      const isRemote = isRemoteFile(file);
      const downloadURL = getDownloadAssetURL(
        experimentKey,
        file.assetId,
        artifactVersionId
      );
      const tooltipMessage = isRemote
        ? 'Remote synced artifacts are not downloadable through the Comet UI, please use the Comet SDK to downloadable this artifact'
        : 'Download asset';

      return (
        <Tooltip content={tooltipMessage}>
          <a href={downloadURL}>
            <IconButton
              Icon={<DSDownloadIcon />}
              type="secondary"
              size="small"
              onClick={e => {
                e.stopPropagation();
              }}
              disabled={isRemote}
            />
          </a>
        </Tooltip>
      );
    },
    [artifactVersionId, experimentKey]
  );

  const treeItems = useMemo(() => {
    return Object.keys(directoryMap).map(dir => {
      const sortedFiles = orderBy(
        directoryMap[dir],
        [
          file => {
            const value = file[orderedBy];
            return isNumber(value) ? value : toLower(value);
          }
        ],
        [order]
      );
      const children = sortedFiles.map(file => {
        const Icon = get(
          getAssetPreviewConfig(file),
          'Icon',
          InsertDriveFileIcon
        );

        const { fileName, fileSize } = file;

        return (
          <StyledTreeItem
            key={fileName}
            nodeId={fileName}
            onClick={() => handleRowClick(file)}
            label={
              <div className="tree-item-label">
                <div className="label-with-icon">
                  <Icon className="tree-item-icon" />
                  <span title={fileName}>{fileName}</span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    {isRemoteFile(file) ? 'remote' : formatBytes(fileSize)}
                  </span>

                  {renderAssetActions(file)}
                </div>
              </div>
            }
          />
        );
      });

      if (dir === UNKNOWN_DIR) {
        return children;
      }

      return (
        <StyledTreeItem
          key={dir}
          nodeId={dir}
          label={
            <div className="label-with-icon">
              <FolderIcon className="tree-item-icon" />
              <span title={dir}>{dir}</span>
            </div>
          }
        >
          <span className="nested-tree-items">{children}</span>
        </StyledTreeItem>
      );
    });
  }, [directoryMap, order, orderedBy, handleRowClick, renderAssetActions]);

  const renderActionFields = () => {
    const filters = getDirectoryFilters(files);
    return (
      <div className="action-fields-container">
        <div className="field-group">
          {filters.map(filter => (
            <Fragment key={filter.filterKey}>
              <FilterPopover
                className="light"
                handleFilterChange={handleFilterChange}
                selectedFilters={get(selectedFilters, [filter.filterKey], [])}
                filter={filter}
              />

              <Divider orientation="vertical" style={{ height: 20 }} />
            </Fragment>
          ))}
          <StyledSearchBar
            entityName="files"
            handleCancelSearch={handleCancelSearch}
            handleSearchChange={handleSearchChange}
            style={{ marginLeft: 10 }}
          />
        </div>
      </div>
    );
  };

  // TODO: (camden) currently the 'dir' field for all assets is set to 'null'
  // This is so that they are rendered in a flat directory structure
  // The intent is to add support for custom directories in the future
  // so that we can render nested files under their directory
  const treeItemsToRender = first(treeItems) || [];

  const renderDirectory = () => {
    if (!isEmpty(searchText) && isEmpty(directoryMap)) {
      return <EmptyPreview message="No matching artifact files found." />;
    }

    const iconStyle = { color: COLORS.primary, fontSize: 24 };

    return (
      <TreeView
        className="file-tree"
        defaultCollapseIcon={<ArrowDropDownIcon style={iconStyle} />}
        defaultExpandIcon={<ArrowRightIcon style={iconStyle} />}
        defaultExpanded={Object.keys(directoryMap)}
        style={{ opacity: isSearching ? 0.5 : 1 }}
      >
        <List
          className="List"
          itemCount={treeItemsToRender.length}
          height={500}
          itemSize={42}
        >
          {({ index, style }) => (
            <div style={style}>{treeItemsToRender[index]}</div>
          )}
        </List>
      </TreeView>
    );
  };

  return (
    <div className="artifact-version-files-tab">
      <div className="file-tree-header">
        {renderActionFields()}
        <Divider />
        <div className="file-tree-columns">{renderTreeColumns()}</div>
        <Divider />
      </div>

      {renderDirectory()}

      {isPreviewOpen && (
        <AssetPreviewContainer
          experimentKey={experimentKey}
          file={selectedFile}
          closeHandler={() => setIsPreviewOpen(false)}
        />
      )}
    </div>
  );
};

ArtifactVersionAssetsTab.propTypes = {
  artifactVersionId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => {
  const { artifactVersionId, experimentKey } = props.artifactVersion;

  return {
    artifactVersionId,
    experimentKey,
    files: getArtifactVersionFiles(state, { artifactVersionId })
  };
};

export default connect(mapStateToProps)(ArtifactVersionAssetsTab);
