import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MaterialCollapse from '@material-ui/core/Collapse';
import { TightArrowIcon } from '@Icons-outdated';

import './Collapse.scss';

const Collapse = ({ title, children, expandedByDefault }) => {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  const handleExpandedStatus = () => setIsExpanded(status => !status);
  return (
    <div className="ds-collapse">
      <div
        className={cx('collapse-title', {
          open: isExpanded
        })}
        onClick={handleExpandedStatus}
      >
        <span>{title}</span>
        <TightArrowIcon />
      </div>
      <div className="collapse-content">
        <MaterialCollapse in={isExpanded}>{children}</MaterialCollapse>
      </div>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  expandedByDefault: PropTypes.bool
};

Collapse.defaultProps = {
  title: '',
  children: null,
  expandedByDefault: false
};

export default Collapse;
