import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { Button } from '@ds';
import noop from 'lodash/noop';

import styles from './SecondaryModal.module.scss';

const SecondaryModal = ({
  content,
  primaryButtonText,
  title,
  onPrimaryButtonClick,
  open,
  onClose,
  primaryButtonDisabled
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.secondaryModalContainer}
    >
      <h2 className={styles.modalTitle}>{title}</h2>
      <div className={styles.modalContent}>{content}</div>
      <div className={styles.modalActionButtons}>
        {primaryButtonText && (
          <Button
            onClick={onPrimaryButtonClick}
            disabled={primaryButtonDisabled}
          >
            {primaryButtonText}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

SecondaryModal.propTypes = {
  content: PropTypes.node,
  primaryButtonText: PropTypes.string,
  title: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool
};

SecondaryModal.defaultProps = {
  content: null,
  primaryButtonText: '',
  title: '',
  onPrimaryButtonClick: noop,
  open: false,
  onClose: noop,
  primaryButtonDisabled: false
};

export default SecondaryModal;
