import api from '@shared/api';
import { useQuery } from 'react-query';

const getExperimentInstalledPackages = async ({ experimentKey, signal }) => {
  const { data } = await api.get('data-fetch/get/installed-packages', {
    params: { experimentKey },
    signal
  });

  return (data.packages ?? []).join('\n');
};

export default function useExperimentInstalledPackages(
  { experimentKey },
  config
) {
  return useQuery(
    ['experiment-installed-packages', { experimentKey }],
    ({ signal }) => {
      return getExperimentInstalledPackages({
        experimentKey,
        signal
      });
    },
    { enabled: !!experimentKey, refetchOnMount: true, ...config }
  );
}
