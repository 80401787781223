import { useCallback } from 'react';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { viewEvents } from '@/constants/trackingEventTypes';
import { EMTabPathType } from './useEMProjectRoutes';

const eventsMap: Record<EMTabPathType, string> = {
  panels: viewEvents.PROJECT_PANELS_PAGE_VIEWED,
  experiments: viewEvents.PROJECT_EXPERIMENT_TABLE_PAGE_VIEWED,
  notes: viewEvents.PROJECT_NOTES_PAGE_VIEWED,
  archive: viewEvents.PROJECT_ARCHIVE_PAGE_VIEWED,
  manage: viewEvents.PROJECT_MANAGE_PAGE_VIEWED,
  reports: viewEvents.PROJECT_REPORTS_PAGE_VIEWED
};

const useProjectViewedBI = () => {
  const baseTrackEvent = useBaseTrackEvent();

  return useCallback(
    (pageName: EMTabPathType) => {
      baseTrackEvent(eventsMap[pageName]);
    },
    [baseTrackEvent]
  );
};

export default useProjectViewedBI;
