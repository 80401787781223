export const AVATAR_WIDTH = 114;

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = image.width;
  canvas.height = image.height;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/png');
  });
}

export const formImageFileFromUrl = async (imgUrl, successHandler) => {
  const image = new Image();
  const canvas = document.createElement('canvas');
  image.src = imgUrl;

  image.addEventListener('load', async () => {
    canvas.height = image.height;
    canvas.width = image.width;

    const ctx = canvas.getContext('2d');

    // This is the most absurdly overloaded function in JS.
    ctx.drawImage(image, 0, 0);
    const dataURL = canvas.toDataURL('image/png');

    const res = await fetch(dataURL);
    const buff = await res.arrayBuffer();
    const file = new File([buff], 'photo.png', {
      type: 'image/png'
    });
    const finalUrl = URL.createObjectURL(file);

    const finalRes = await fetch(finalUrl);
    const finalBuffer = await finalRes.arrayBuffer();

    const finalFile = new File([finalBuffer], 'photo.png', {
      type: 'image/png'
    });

    successHandler(finalFile);
  });
};
