import { reportActionTypes } from '../constants/actionTypes';

const initialState = { reports: [] };

const reportsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === reportActionTypes.SET_REPORTS) {
    const { reports } = payload;

    return {
      ...state,
      reports
    };
  }

  return state;
};

export default reportsReducer;

export const getReports = state => state.reports.reports;
