import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

type AlertRulesDeleteByIdMutationProps = {
  monitorIds: string[];
  deleteCallback?: (ids: string[]) => void;
};

export function useAlertRulesDeleteById() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ monitorIds }: AlertRulesDeleteByIdMutationProps) => {
      return api.post(`/mpm/v2/monitors/delete-by-id`, {
        modelId,
        monitorIds
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['alert-rules']);
        await queryClient.cancelQueries(['alertRulesById']);
      },
      onSuccess: (_, variables) => {
        const { monitorIds, deleteCallback } = variables;
        if (deleteCallback) deleteCallback(monitorIds);
        queryClient.invalidateQueries(['alert-rules']);
        queryClient.invalidateQueries(['alertRulesById']);
        queryClient.invalidateQueries(['alerts-count']);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_DELETE_ALERT_RULE,
            'Alert rules were successfully deleted.'
          )
        );
      }
    }
  );
}
