import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import SearchBar from 'material-ui-search-bar';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const SEARCH_ICON_STYLE = { color: '#5c64f3', opacity: 1 };

const GallerySearchBar = ({
  entityName,
  handleCancelSearch,
  handleSearchChange,
  SearchBarProps
}) => (
  <SearchBar
    className="list-search-bar gallery-search-bar"
    placeholder={`Search ${entityName}s`}
    searchIcon={<SearchIcon style={SEARCH_ICON_STYLE} />}
    closeIcon={<CloseIcon style={SEARCH_ICON_STYLE} />}
    onCancelSearch={handleCancelSearch}
    onChange={handleSearchChange}
    onRequestSearch={handleSearchChange}
    {...SearchBarProps}
  />
);

GallerySearchBar.defaultProps = {
  SearchBarProps: {},
  entityName: null,
  handleCancelSearch: noop,
  handleSearchChange: noop
};

GallerySearchBar.propTypes = {
  SearchBarProps: PropTypes.object,
  entityName: PropTypes.string,
  handleCancelSearch: PropTypes.func,
  handleSearchChange: PropTypes.func
};

export default GallerySearchBar;
