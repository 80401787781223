import React, { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

import { SortModal } from '@DesignSystem/controllers';
import Select from '@shared/components/Select';
import { DEFAULT_FEATURES_SORTING } from '../../constants';

const formatColumnsToSelectOptions = (columns: Record<string, string>[]) => {
  return columns
    .filter(column => !column.hideForSorting)
    .map(column => ({ value: column.name, label: column.title }));
};

type Column = {
  columnName: string;
  direction: string;
  sortKey?: string;
};

type SortPopoverProps = {
  anchorEl: HTMLElement | null;
  anchorOrigin: { horizontal: string; vertical: string };
  columnSorting: Column[];
  columns: Record<string, string>[];
  onChange: (columns: Column[]) => void;
  onClose: () => void;
  open: boolean;
  transformOrigin: {
    horizontal: string;
    vertical: string;
  };
};

export const SortPopover = ({
  columnSorting,
  onChange,
  columns: allColumns,
  ...PopoverProps
}: SortPopoverProps) => {
  const isLoading = false;
  const [sorting, setSorting] = useState<Column[]>(columnSorting);

  const columns = useMemo(() => {
    return allColumns.filter(({ source }) => source !== 'tags');
  }, [allColumns]);

  useEffect(() => {
    setSorting(columnSorting);
  }, [columnSorting]);

  if (isLoading) return null;

  const updateSorting = (newSorting: Column[]) => {
    setSorting(newSorting);

    const newColumnSorting = newSorting.filter(({ columnName }) => columnName);

    if (!isEqual(newColumnSorting, columnSorting)) {
      onChange(newColumnSorting);
    }
  };

  const handleReset = () => {
    updateSorting(DEFAULT_FEATURES_SORTING);
  };

  const handleToggleDirection = (index: number) => {
    updateSorting(
      sorting.map((sortingRow, mapIndex) => {
        if (mapIndex === index) {
          const newDirection = sortingRow.direction === 'asc' ? 'desc' : 'asc';

          return { ...sortingRow, direction: newDirection };
        }

        return sortingRow;
      })
    );
  };

  const handleChangeFields = (fields: Array<Column | ''>) => {
    const nextFields = fields.map(field => {
      if (field === '') return { columnName: '', direction: 'desc' };

      return find(sorting, { columnName: field.sortKey });
    });
    updateSorting(nextFields as Column[]);
  };

  const handleChangeField = (sortKey: string, index: number) => {
    updateSorting(
      sorting.map((field, fieldIndex) => {
        if (fieldIndex === index) {
          return { ...field, columnName: sortKey };
        }

        return field;
      })
    );
  };

  const selectOptions = formatColumnsToSelectOptions(columns);

  const hasEmptyValue = sorting.some(({ columnName }) => !columnName);

  const currentFields = sorting.map(({ columnName, direction }) => ({
    sortKey: columnName,
    direction
  }));

  return (
    <SortModal
      currentFields={currentFields}
      onChangeFields={handleChangeFields}
      onReset={handleReset}
      onToggleDirection={(index: number) => handleToggleDirection(index)}
      isAddFieldButtonDisabled={hasEmptyValue}
      isAddFieldButtonHidden
      renderSelectField={(sortKey: string, index: number) => {
        const value = find(selectOptions, { value: sortKey });

        return (
          <div className="full-width">
            <Select
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={({ value: sortKey }) =>
                handleChangeField(sortKey, index)
              }
              options={selectOptions}
              placeholder="Select column"
              value={value}
              displaySource
              isSearchable
              data-test="sorting-popover-select"
            />
          </div>
        );
      }}
      {...PopoverProps}
    />
  );
};
