import {
  DETAIL_VIEWS,
  DETAIL_VIEW_SETTINGS
} from '@experiment-management-shared/constants/experimentConstants';

import PanelsTab from '@experiment-details/components/panels/PanelsTab';
import HyperParametersTab from '@experiment-details/components/params/HyperParametersTab';
import GraphicsTab from '@experiment-details/components/Graphics/GraphicsTab';
import GraphicsCompareTab from '@experiment-details/components/Graphics/GraphicsCompare';
import SystemMetricsTab from '@experiment-details/components/system-metrics/SystemMetricsTab';
import MetricsCompareTab from '@experiment-details/components/metrics/MetricsCompareTab';
import MetricsTab from '@experiment-details/components/metrics/MetricsTab';
import OtherParamsCompareTab from '@experiment-details/components/other/OtherParamsCompareTab';
import OtherParamsTab from '@experiment-details/components/other/OtherParamsTab';
import AssetsAndArtifactsTab from '@experiment-details/components/AssetsAndArtifactsTab';
import AudioTab from '@experiment-details/components/AudioTab';
import CodeTab from '@experiment-details/components/Code/CodeTab';
import CodeCompareTab from '@experiment-details/components/Code/CodeCompareTab';
import ConfusionMatrixTab from '@experiment-details/components/ConfusionMatrixTab';
import GraphDefinitionTab from '@experiment-details/components/GraphDefinitionTab';
import GraphDefinitionCompareTab from '@experiment-details/components/GraphDefinitionCompareTab';
import HistogramTab from '@experiment-details/components/HistogramTab';
import HTMLTab from '@experiment-details/components/HTMLTab';
import HTMLCompareTab from '@experiment-details/components/HTMLCompareTab';
import InstalledPackagesTab from '@experiment-details/components/InstalledPackagesTab';
import InstalledPackagesCompareTab from '@experiment-details/components/InstalledPackagesCompareTab';
import NoteTab from '@experiment-details/components/NoteTab';
import NoteCompareTab from '@experiment-details/components/NoteCompareTab';
import OutputTab from '@experiment-details/components/OutputTab';
import OutputCompareTab from '@experiment-details/components/OutputCompareTab';
import TextTab from '@experiment-details/components/TextTab';

const {
  ASSETS_AND_ARTIFACTS,
  AUDIO,
  CODE,
  CONFUSION_MATRIX,
  GRAPH_DEFINITION,
  HISTOGRAM,
  HTML,
  HYPER_PARAMETERS,
  IMAGES,
  INSTALLED_PACKAGES,
  METRICS,
  NOTE,
  OTHERPARAMS,
  PANELS,
  STDOUT,
  SYSTEM_METRICS,
  TEXT
} = DETAIL_VIEWS;

export const VIEW_INFO = {
  [PANELS]: {
    compareComponent: PanelsTab,
    component: PanelsTab,
    ...DETAIL_VIEW_SETTINGS[PANELS]
  },
  [CODE]: {
    compareComponent: CodeCompareTab,
    component: CodeTab,
    ...DETAIL_VIEW_SETTINGS[CODE]
  },
  [HYPER_PARAMETERS]: {
    compareComponent: HyperParametersTab,
    component: HyperParametersTab,
    ...DETAIL_VIEW_SETTINGS[HYPER_PARAMETERS]
  },
  [METRICS]: {
    compareComponent: MetricsCompareTab,
    component: MetricsTab,
    ...DETAIL_VIEW_SETTINGS[METRICS]
  },
  [GRAPH_DEFINITION]: {
    compareComponent: GraphDefinitionCompareTab,
    component: GraphDefinitionTab,
    ...DETAIL_VIEW_SETTINGS[GRAPH_DEFINITION]
  },
  [STDOUT]: {
    compareComponent: OutputCompareTab,
    component: OutputTab,
    ...DETAIL_VIEW_SETTINGS[STDOUT]
  },
  [SYSTEM_METRICS]: {
    compareComponent: SystemMetricsTab,
    component: SystemMetricsTab,
    ...DETAIL_VIEW_SETTINGS[SYSTEM_METRICS]
  },
  [INSTALLED_PACKAGES]: {
    compareComponent: InstalledPackagesCompareTab,
    component: InstalledPackagesTab,
    ...DETAIL_VIEW_SETTINGS[INSTALLED_PACKAGES]
  },
  [NOTE]: {
    compareComponent: NoteCompareTab,
    component: NoteTab,
    ...DETAIL_VIEW_SETTINGS[NOTE]
  },
  [IMAGES]: {
    compareComponent: GraphicsCompareTab,
    component: GraphicsTab,
    ...DETAIL_VIEW_SETTINGS[IMAGES]
  },
  [AUDIO]: {
    compareComponent: AudioTab,
    component: AudioTab,
    ...DETAIL_VIEW_SETTINGS[AUDIO]
  },
  [TEXT]: {
    compareComponent: TextTab,
    component: TextTab,
    ...DETAIL_VIEW_SETTINGS[TEXT]
  },
  [CONFUSION_MATRIX]: {
    compareComponent: ConfusionMatrixTab,
    component: ConfusionMatrixTab,
    ...DETAIL_VIEW_SETTINGS[CONFUSION_MATRIX]
  },
  [HISTOGRAM]: {
    compareComponent: HistogramTab,
    component: HistogramTab,
    ...DETAIL_VIEW_SETTINGS[HISTOGRAM]
  },
  [OTHERPARAMS]: {
    compareComponent: OtherParamsCompareTab,
    component: OtherParamsTab,
    ...DETAIL_VIEW_SETTINGS[OTHERPARAMS]
  },
  [HTML]: {
    compareComponent: HTMLCompareTab,
    component: HTMLTab,
    ...DETAIL_VIEW_SETTINGS[HTML]
  },
  [ASSETS_AND_ARTIFACTS]: {
    component: AssetsAndArtifactsTab,
    ...DETAIL_VIEW_SETTINGS[ASSETS_AND_ARTIFACTS]
  }
};
