import { createSelector } from 'reselect';
import { organizationActionTypes } from '../../constants/actionTypes';

const initialState = {
  activeOrganizationId: null,
  list: []
};

const organizationUIReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === organizationActionTypes.SET_ACTIVE) {
    return {
      ...state,
      activeOrganizationId: payload.activeOrganizationId
    };
  }

  if (type === organizationActionTypes.SET_ORGANIZATION_LIST) {
    return {
      ...state,
      list: payload.organizations
    };
  }

  return state;
};

export const getUIOrganizations = state => state.ui.organizations;

export const getActiveOrganizationId = createSelector(
  getUIOrganizations,
  organizations => organizations?.activeOrganizationId
);

export const getOrganizations = state => state?.ui?.organizations;
export const getOrganizationList = createSelector(
  [getOrganizations],
  organizations => organizations?.list
);

export const getActiveUserOrganization = createSelector(
  [getOrganizations],
  organizations => {
    const organization = organizations?.list.find(
      org => org?.id === organizations?.activeOrganizationId
    );

    return organization;
  }
);

export default organizationUIReducer;
