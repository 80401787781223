// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrButton-module__removeButtonContainer--fVXT3{display:inline-block}.OrButton-module__removeButtonContainer--fVXT3 .OrButton-module__label--GR24l{height:30px;color:var(--gray-05);font-size:14px;font-weight:500}.OrButton-module__removeButtonContainer--fVXT3 button{display:none}.OrButton-module__removeButtonContainer--fVXT3:hover .OrButton-module__label--GR24l{display:none}.OrButton-module__removeButtonContainer--fVXT3:hover button{display:flex}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeButtonContainer": `OrButton-module__removeButtonContainer--fVXT3`,
	"label": `OrButton-module__label--GR24l`
};
module.exports = ___CSS_LOADER_EXPORT___;
