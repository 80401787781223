import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from '@ds';

const EmptySectionMessage = ({
  button,
  buttonText,
  className,
  description,
  icon,
  title,
  ButtonProps
}) => {
  return (
    <div className={cx('empty-section-message', className)}>
      {icon && <div className="empty-section-message-icon">{icon}</div>}

      <div className="empty-section-message-title">{title}</div>

      <div className="empty-section-message-description">{description}</div>

      {button || (
        <Button size="large" {...ButtonProps}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

EmptySectionMessage.defaultProps = {
  button: null,
  buttonText: '',
  className: '',
  description: '',
  icon: null,
  title: '',
  ButtonProps: {}
};

EmptySectionMessage.propTypes = {
  button: PropTypes.node,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  ButtonProps: PropTypes.object
};

export default EmptySectionMessage;
