import React, { createContext, useContext, useState } from 'react';

type AnchorElement = EventTarget & Element;
export type ActionMenuListContextType = {
  openPopover: React.MouseEventHandler<Element>;
  closePopover: () => void;
  onClosePopover: React.MouseEventHandler<Element>;
  isPopoverOpened: boolean;
  anchorElement: AnchorElement | null;
};

export const ActionMenuListContext = createContext<
  ActionMenuListContextType | undefined
>(undefined);

export type ActionMenuListProviderProps = {
  menuIsOpened?: boolean;
  children: React.ReactNode;
};
export const ActionMenuListProvider = ({
  children
}: ActionMenuListProviderProps) => {
  const [anchorElement, setAnchorElement] = useState<AnchorElement | null>(
    null
  );

  const openPopover: React.MouseEventHandler = e => {
    setAnchorElement(e.currentTarget);
  };

  const closePopover = () => {
    setAnchorElement(null);
  };

  const onClosePopover: React.MouseEventHandler = () => {
    closePopover();
  };

  const isPopoverOpened = Boolean(anchorElement);

  const value = {
    openPopover,
    closePopover,
    onClosePopover,
    isPopoverOpened,
    anchorElement
  };

  return (
    <ActionMenuListContext.Provider value={value}>
      {children}
    </ActionMenuListContext.Provider>
  );
};

export const useActionMenuListContext = (): ActionMenuListContextType => {
  const ctx = useContext(ActionMenuListContext);

  if (ctx === undefined) {
    throw new Error(
      'useActionMenuListContext must be used within a ActionMenuListProvider'
    );
  }

  return ctx;
};
