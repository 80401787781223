// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportHeader-module__header--KOH6p{align-items:center;display:flex;flex-wrap:wrap;justify-content:space-between;padding:0 16px;width:100%}.ReportHeader-module__actionsSection--cifei{align-items:center;display:flex;justify-content:flex-end;flex:1;gap:8px}.ReportHeader-module__breadcrumbsSection--GBkNa{height:51px;align-items:center;display:flex;white-space:nowrap}.ReportHeader-module__draftLabel--wMxM9{background-color:#fee8d9;border-radius:4px;color:#fa7115;font-size:12px;font-weight:500;padding:0 12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ReportHeader-module__header--KOH6p`,
	"actionsSection": `ReportHeader-module__actionsSection--cifei`,
	"breadcrumbsSection": `ReportHeader-module__breadcrumbsSection--GBkNa`,
	"draftLabel": `ReportHeader-module__draftLabel--wMxM9`
};
module.exports = ___CSS_LOADER_EXPORT___;
