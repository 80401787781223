import React, { useCallback, useMemo } from 'react';

import { Button as DsButton } from '@design-system-outdated/components';
import { Select, IconButton } from '@ds';
import { isMetricNumerical } from '@API/experiments/utils';
import { Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSprings } from 'react-spring';
import {
  AGGREGATION_TYPES_ARRAY,
  AGGREGATION_TYPES_OBJECT,
  CHART_CONFIG_MODIFIERS,
  DISABLED_BAR_CHART_AGGREGATION,
  NON_NUMERIC_AGGREGATIONS
} from '@experiment-management-shared/constants/chartConstants';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { StyledTooltip } from '@DesignSystem/data-display';

import { KIND_UNIQ_SYMBOL } from '@API/helpers/v2_helpers';
import dashboardChartsActions from '@/actions/dashboardChartsActions';

import './BarChartDataTab.scss';
import useChartTabYValues from '@experiment-management-shared/hooks/useChartTabYValues';
import { disableNotNumericalOptionsForGrouping } from '@experiment-management-shared/utils/buildinVisualisationHelpers';
import {
  DSBarHorizontalIcon,
  DSBoxPlotHorizontalIcon,
  DSRemoveIcon,
  DSStackedBarHorizontalIcon,
  DSViolinPlotHorizontalIcon
} from '@ds-icons';

const CHART_TYPE = 'bar';

const { BAR, BOX_PLOT, STACKED_BAR, VIOLIN_PLOT } = CHART_CONFIG_MODIFIERS;

const BAR_TYPES_OPTIONS = [
  BAR,
  BOX_PLOT,
  STACKED_BAR,
  VIOLIN_PLOT,
  BAR,
  BOX_PLOT,
  STACKED_BAR,
  VIOLIN_PLOT
];

const horizontalIconsMap = {
  [BAR.label]: <DSBarHorizontalIcon />,
  [BOX_PLOT.label]: <DSBoxPlotHorizontalIcon />,
  [STACKED_BAR.label]: <DSStackedBarHorizontalIcon />,
  [VIOLIN_PLOT.label]: <DSViolinPlotHorizontalIcon />
};

const BAR_TYPE_GROUP_DIVIDER = BAR_TYPES_OPTIONS.length / 2;

const BarChartDataTab = () => {
  const dispatch = useDispatch();
  const barChartForm = useSelector(
    state => state.dashboardCharts.chartForm.bar
  );

  const yValues = useChartTabYValues({ type: CHART_TYPE });

  const handledYValues = useMemo(() => {
    if (barChartForm.grouping.enabled) {
      return disableNotNumericalOptionsForGrouping(yValues);
    }

    return yValues;
  }, [yValues, barChartForm.grouping?.enabled]);

  const plotType = get(
    barChartForm,
    'plotType',
    `${CHART_CONFIG_MODIFIERS.BAR.value + KIND_UNIQ_SYMBOL}h`
  );

  const plotOrientation = barChartForm.orientation;

  const renderAxisSelect = ({ onChange, value, index, disabled = false }) => {
    const spring = springs[index];
    return (
      <animated.div style={{ marginBottom: 12, width: 260, ...spring }}>
        <Select
          data-test="bar-chart-axis-select"
          value={value}
          maxWidth={260}
          truncateMiddle
          onValueChange={onChange}
          options={handledYValues}
          disabled={disabled}
          maxHeight={280}
          isSearchable
          isClearable={false}
          menuShouldBlockScroll
          isRegexSearchEnabled
        />
      </animated.div>
    );
  };

  const metrics = useMemo(() => {
    if (barChartForm?.metrics) return barChartForm.metrics;

    const aggregation =
      barChartForm.aggregationX || AGGREGATION_TYPES_OBJECT.LAST;
    const metricNames = barChartForm.metricNames || [barChartForm.metricName];

    return metricNames.map(metricName => ({
      aggregation,
      name: metricName
    }));
  }, [
    barChartForm?.metrics,
    barChartForm.aggregationX,
    barChartForm?.metricNames,
    barChartForm?.metricName
  ]);

  const [springs] = useSprings(metrics.length, index => ({
    opacity: metrics.length <= index ? 0 : 1,
    marginTop: metrics.length <= index ? index * -20 : 0,
    from: { opacity: 0, marginTop: index * -20 },
    config: { duration: 100, bounce: 300 }
  }));

  const renderAggregationSelect = ({
    onChange,
    value,
    index,
    disabled = false,
    metricName
  }) => {
    const formValue = defaultTo(value, AGGREGATION_TYPES_OBJECT.LAST);
    const selectValue = formValue;
    const spring = springs[index];

    const isCategorical = !isMetricNumerical(
      yValues?.find(metric => metric.value === metricName)
    );

    const aggregationOptions = isCategorical
      ? NON_NUMERIC_AGGREGATIONS
      : AGGREGATION_TYPES_ARRAY;

    const options = aggregationOptions?.map(type =>
      formatValueForSelectOption(type)
    );

    const isAggregationDisabled = DISABLED_BAR_CHART_AGGREGATION.includes(
      plotType
    );

    const handleRemoveAxis = axisIndex => () => {
      const newMetrics = metrics.filter((_, i) => i !== axisIndex);

      dispatch(
        dashboardChartsActions.updateChartForm(CHART_TYPE, {
          metrics: newMetrics
        })
      );
    };

    return (
      <animated.div
        style={{ marginLeft: '10px', ...spring }}
        key={`${index}_${value}`}
        className="bar-axis"
      >
        <Box display="flex" alignItems="center">
          <Box width={130} marginRight="6px">
            <Select
              value={selectValue}
              onValueChange={onChange}
              maxWidth={130}
              truncateMiddle
              options={options}
              disabled={isAggregationDisabled}
              isClearable={false}
              menuShouldBlockScroll
            />
          </Box>
          {metrics.length >= 2 && !disabled && (
            <StyledTooltip title="Remove field" placement="top" arrow>
              <span>
                <IconButton
                  type="secondary"
                  Icon={<DSRemoveIcon />}
                  className="bar-axis-remove-icon"
                  onClick={handleRemoveAxis(index)}
                />
              </span>
            </StyledTooltip>
          )}
        </Box>
      </animated.div>
    );
  };

  const handleAddAxis = useCallback(
    name => {
      dispatch(
        dashboardChartsActions.updateChartForm(CHART_TYPE, {
          metrics: [
            ...metrics,
            {
              aggregation: AGGREGATION_TYPES_OBJECT.LAST,
              name: name || ''
            }
          ]
        })
      );
    },
    [dispatch, metrics]
  );

  const renderAxis = () => {
    const hasEmptyMetric = metrics.some(({ name }) => !name);

    if (metrics.length === 0) {
      metrics.push({
        aggregation: AGGREGATION_TYPES_OBJECT.LAST,
        name: ''
      });
    }

    const handleAxisAggregationChange = axisIndex => aggregation => {
      const newMetrics = metrics.map((metric, index) => {
        if (index === axisIndex) {
          return { ...metric, aggregation };
        }

        return metric;
      });

      dispatch(
        dashboardChartsActions.updateChartForm(CHART_TYPE, {
          metrics: newMetrics
        })
      );
    };

    const handleAxisMetricChange = (axisIndex, disabled) => name => {
      if (disabled) {
        return;
      }

      const newMetrics = metrics.map((metric, index) => {
        if (index === axisIndex) {
          return { ...metric, name };
        }

        return metric;
      });

      dispatch(
        dashboardChartsActions.updateChartForm(CHART_TYPE, {
          metrics: newMetrics
        })
      );
    };

    return (
      <div style={{ marginBottom: 40 }}>
        <Box display="flex" width="400" alignItems="center">
          <InputLabel style={{ width: '270px' }} className="modal-input-label">
            Y-Axis
          </InputLabel>
          <InputLabel className="modal-input-label">Aggregation</InputLabel>
        </Box>
        {metrics.map(({ aggregation, name }, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box display="flex" width="400" key={`${name}_${index}`}>
              <div>
                {renderAxisSelect({
                  onChange: handleAxisMetricChange(index),
                  index,
                  value: name
                })}
              </div>

              {renderAggregationSelect({
                onChange: handleAxisAggregationChange(index),
                index,
                value: aggregation,
                metricName: name
              })}
            </Box>
          );
        })}

        <DsButton
          disabled={hasEmptyMetric}
          onClick={() => handleAddAxis()}
          style={{ marginTop: 12 }}
          withoutPadding
          type="text2"
          text="+ Add field"
        />
      </div>
    );
  };

  const renderPlotTypeControls = () => {
    const groupedOptions = [
      { label: 'Vertical', options: [] },
      { label: 'Horizontal', options: [] }
    ];
    BAR_TYPES_OPTIONS.forEach((item, i) => {
      if (i >= BAR_TYPE_GROUP_DIVIDER) {
        groupedOptions[0].options.push({
          ...item,
          label: `${item.label} Vertical`,
          value: `${item.value + KIND_UNIQ_SYMBOL + 'v'}`
        });
      } else {
        groupedOptions[1].options.push({
          ...item,
          label: `${item.label} Horizontal`,
          value: `${item.value + KIND_UNIQ_SYMBOL + 'h'}`,
          prefixIcon: horizontalIconsMap[item.label]
        });
      }
    });

    return (
      <div className="full-width-input mb-40">
        <InputLabel
          className="modal-input-label"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Bar type
        </InputLabel>
        <Select
          value={`${plotType + KIND_UNIQ_SYMBOL + plotOrientation}`}
          onValueChange={value => {
            const [barType, orientation] = value.split(KIND_UNIQ_SYMBOL);

            dispatch(
              dashboardChartsActions.updateChartForm(CHART_TYPE, {
                orientation,
                plotType: barType,
                grouping: {
                  ...barChartForm.grouping,
                  plotType: barType
                }
              })
            );
          }}
          options={groupedOptions}
          isClearable={false}
          isSearchable={false}
          menuShouldBlockScroll
        />
      </div>
    );
  };

  return (
    <div>
      {renderPlotTypeControls()}
      {renderAxis()}
    </div>
  );
};

export default BarChartDataTab;
