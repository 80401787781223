import React from 'react';
import SmallLoader from '@shared/components/SmallLoader';
import Highlight from '@shared/components/Highlight';
import { ExperimentDetails } from '@shared/types';
import { EmptyExperimentTab } from '@shared';

type CodeTabProps = {
  code?: string;
  experiment: ExperimentDetails;
  isLoading?: boolean;
};

const SimpleCode = ({ code, isLoading = false }: CodeTabProps) => {
  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  if (!code) {
    return (
      <EmptyExperimentTab>
        <EmptyExperimentTab.Icon iconName="code" />
        <EmptyExperimentTab.Title>
          This experiment did not report source code
        </EmptyExperimentTab.Title>
        <EmptyExperimentTab.Description>
          Enable <b>log_code</b>{' '}
          <EmptyExperimentTab.Link path="api-and-sdk/python-sdk/reference/Experiment/#experimentinit">
            on experiment creation
          </EmptyExperimentTab.Link>
          , or use <b>log_code()</b> on your Experiment to capture code during
          experiments.
        </EmptyExperimentTab.Description>
        <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/code/" />
      </EmptyExperimentTab>
    );
  }

  return (
    <div className="code-content">
      <Highlight code={code} />
    </div>
  );
};

export default SimpleCode;
