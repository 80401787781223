import PropTypes from 'prop-types';

const ManageUsersModalRemoveCellTooltip = ({
  ifRowUserWsOwner,
  ifRowUserCurrentUser,
  ifCurrentWsOnlyOne,
  ifRowUserInvitedByEmail
}) => {
  // if current user want to remove himself from WS, but it is the only one or default, he cannot do it
  if (ifRowUserCurrentUser && ifRowUserWsOwner && ifCurrentWsOnlyOne) {
    return 'Cannot remove yourself from last workspace';
  }

  if (ifRowUserInvitedByEmail) {
    return 'Cannot remove users invited by email';
  }

  return 'Cannot remove user from workspace';
};

ManageUsersModalRemoveCellTooltip.propTypes = {
  ifRowUserWsOwner: PropTypes.bool.isRequired,
  ifRowUserCurrentUser: PropTypes.bool.isRequired,
  ifRowUserInvitedByEmail: PropTypes.bool.isRequired,
  ifCurrentWsOnlyOne: PropTypes.bool.isRequired
};

ManageUsersModalRemoveCellTooltip.defaultProps = {
  permissions: []
};

export default ManageUsersModalRemoveCellTooltip;
