import React, { useMemo } from 'react';
import { Resizable } from 're-resizable';

import { Button } from '@ds';
import { Input, Select } from '@DesignSystem/controllers';
import { LineChart } from '../LineChart';
import { CHART_BASIC_LAYOUT, getChartLinesFromData } from '@mpm-druid/utils';
import { useCustomMetricQuery } from '@mpm-druid/api';
import {
  INTERVAL_TYPE_OPTIONS,
  INTERVAL_TYPE_VALUES
} from '@mpm-druid/constants';
import styles from './CustomTransformationsPage.modules.scss';
import { CustomMetricType, Feature, Label } from '@mpm-druid/types';
import { CometSQLSyntax } from '../CometSQLSyntax/CometSQLSyntax';

type CustomMetricPreviewProps = {
  features: Feature[];
  labels: Label[];
  metricData: CustomMetricType & { intervalType: string };
  metricDataHandler: (
    data: CustomMetricType & { intervalType: string }
  ) => void;
};

const getISODate = (daysAgoFromToday: number) => {
  return new Date(
    new Date().getTime() - daysAgoFromToday * 24 * 60 * 60 * 1000
  ).toISOString();
};

export function CustomMetricPreview({
  features,
  labels,
  metricData,
  metricDataHandler
}: CustomMetricPreviewProps) {
  const { name, cometSql, intervalType } = metricData;

  const dateRangeISO = useMemo(() => {
    const today = new Date().toISOString();
    const startPeriod =
      intervalType === INTERVAL_TYPE_VALUES.DAILY
        ? getISODate(30)
        : getISODate(7);
    return { from: startPeriod, to: today };
  }, [intervalType]);

  const {
    data,
    isError,
    isLoading,
    isFetching,
    refetch
  } = useCustomMetricQuery(
    {
      cometSql,
      intervalType,
      from: dateRangeISO.from,
      to: dateRangeISO.to
    },
    {
      enabled: false,
      keepPreviousData: true,
      retry: false
    }
  );

  const handleChange = async (key: string, newValue: string) => {
    await metricDataHandler({
      ...metricData,
      [key]: newValue
    });
    if (key === 'intervalType' && metricData.cometSql) {
      refetch();
    }
  };

  const handlePreviewMetric = () => {
    if (cometSql) {
      refetch();
    }
  };

  const chartData = useMemo(() => {
    if (data) {
      const { x, y } = data;
      return getChartLinesFromData({ pointsBatch: [{ x, y }] });
    }

    return [];
  }, [data]);

  return (
    <div className={styles.previewContainer}>
      <div className={styles.previewWrapper}>
        <Resizable
          minWidth="35%"
          maxWidth="80%"
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          defaultSize={{
            width: '60%',
            height: 'auto'
          }}
          style={{ borderRight: '1px solid #e2e2e2', paddingRight: '20px' }}
        >
          <div className={styles.leftPanel}>
            <Input
              title="Metric name"
              value={name}
              onChange={(val: string) => handleChange('name', val)}
              height="35px"
            />
            <div className={styles.previewMetricFormulaWrapper}>
              <div className={styles.previewMetricFormulaTitle}>
                Metric formula
              </div>
              <CometSQLSyntax
                features={features}
                labels={labels}
                onValidQueryChange={query => handleChange('cometSql', query)}
                query={cometSql}
                onChange={(val: string) => handleChange('cometSql', val)}
              />
              {isError ? (
                <div className={styles.errorMessage}>
                  There was an error on executing the query
                </div>
              ) : null}
            </div>
            <div className={styles.previewButton}>
              <Button
                onClick={handlePreviewMetric}
                loading={isLoading || isFetching}
              >
                Preview Result
              </Button>
            </div>
          </div>
        </Resizable>
        <div className={styles.rightPanel}>
          <div className={styles.titleRangeSection}>
            <span className={styles.chartPreviewTitle}>Metric Preview</span>
            <span className={styles.rangeSection}>
              <Select
                value={intervalType}
                onChange={val => handleChange('intervalType', val)}
                options={INTERVAL_TYPE_OPTIONS}
                variant="outlined"
              />
            </span>
          </div>
          <div className={styles.previewContainerChart}>
            <LineChart
              data={chartData}
              disableZoom
              showTooltip={false}
              layoutData={{
                yaxis: {
                  ...CHART_BASIC_LAYOUT.yaxis,
                  range: [0, 1.01]
                },
                xaxis: {
                  ...CHART_BASIC_LAYOUT.xaxis,
                  autorange: chartData.length ? true : false,
                  range: [dateRangeISO.from, dateRangeISO.to]
                }
              }}
              height={undefined}
              alertNotifications={undefined}
              emptyStateBoxConfig={undefined}
              percentileMode={undefined}
              featureValue={undefined}
              section={undefined}
              setHoverLineData={undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
