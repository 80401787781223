import { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { PARSE_OPTIONS, STRINGIFY_OPTIONS } from '@shared/constants/url';

import history from '@/history';

const useQueryParamsForTab = (
  updatedParams,
  dependencies,
  { preventUpdate = false } = {}
) => {
  const { search, state } = useLocation();
  const parsedQuery = queryString.parse(search, PARSE_OPTIONS);

  useEffect(() => {
    if (preventUpdate) return;

    const stringifiedQuery = queryString.stringify(
      {
        ...parsedQuery,
        ...updatedParams
      },
      STRINGIFY_OPTIONS
    );
    history.replace(
      { search: `?${stringifiedQuery}` },
      { preventScroll: true, ...state }
    );
  }, dependencies);

  return parsedQuery;
};

export default useQueryParamsForTab;
