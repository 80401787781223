import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';
import { SortableCombobox } from '@ds';

const CustomizeColumnsModalSelect = ({
  allSelectedOptions,
  handleColumnToggle,
  options,
  fixedColumns
}) => {
  const value = allSelectedOptions.map(({ value }) => value);

  return (
    <div className="columns-modal-select-container">
      <Typography className="basic-modal-subtitle" align="center">
        Add, remove, or drag and drop the column tags in order to customize the
        table
      </Typography>

      <SortableCombobox
        className="customize-columns-modal-select"
        value={value}
        onValueChange={(_, selectedOptions, action) =>
          handleColumnToggle(selectedOptions, action)
        }
        options={options}
        placeholder="Select columns to display in table"
        fixedOptionValues={fixedColumns}
        maxWidth={460}
        truncateMiddle
        maxHeight={300}
        extraOptionsWidth={100}
        isRegexSearchEnabled
        searchPlaceholder="Search (regex)"
      />
    </div>
  );
};

CustomizeColumnsModalSelect.propTypes = {
  allSelectedOptions: PropTypes.array.string,
  handleColumnToggle: PropTypes.func,
  options: PropTypes.arrayOf({
    value: PropTypes.string,
    name: PropTypes.string
  }),
  fixedColumns: PropTypes.arrayOf({
    value: PropTypes.string,
    name: PropTypes.string
  })
};

CustomizeColumnsModalSelect.defaultProps = {
  allSelectedOptions: [],
  handleColumnToggle: noop,
  options: [],
  fixedColumns: []
};

export default CustomizeColumnsModalSelect;
