import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import get from 'lodash/get';
import first from 'lodash/first';

import useProject from '@API/project/useProject';
import useColumns from '@API/project/useColumns';
import useExperimentsDetails from '@API/experiments/useExperimentsDetails';
import {
  COMPARE_EXPERIMENT_1_KEY,
  COMPARE_EXPERIMENT_2_KEY,
  EXPERIMENT_DETAILS_POLLING_LENGTH
} from '@experiment-management-shared/constants/experimentConstants';
import useCompareExperimentsKeys from '@shared/hooks/useCompareExperimentsKeys';
import {
  getExperimentActive,
  getExperimentArchived
} from '@experiment-management-shared/utils/experimentHelpers';
import { trackCompareViewEvent, trackEvent } from '@shared/utils/eventTrack';
import { PARSE_OPTIONS, STRINGIFY_OPTIONS } from '@shared/constants/url';

import { getIsExperimentArchivePage } from '@/selectors/routeSelectors';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import { getIsUserLoggedInWithGitHub } from '@/reducers/userReducer';

import ExperimentDetails from '../ExperimentDetails';

import { experimentEvents } from '@/constants/trackingEventTypes';
import useExperimentDetailsTemplateWrapper from '@experiment-details/hooks/useExperimentDetailsTemplateWrapper';

const CompareExperimentsDetails = () => {
  const { projectName, workspace } = useParams();
  const { data: columns } = useColumns();
  const { experimentKeys } = useCompareExperimentsKeys();
  const history = useHistory();
  const location = useLocation();

  const projectId = useSelector(getSelectedProjectId);
  const isArchive = useSelector(getIsExperimentArchivePage);
  const isUserLoggedIn = useSelector(getIsUserLoggedInWithGitHub);

  const [refetchInterval, setRefetchInterval] = useState(null);
  const [sentBIEvent, setSentBIEvent] = useState(false);

  const { data: project } = useProject();
  const templateWrapper = useExperimentDetailsTemplateWrapper(
    first(experimentKeys)
  );

  const {
    data: experimentsData,
    isLoading: isLoadingMetadata,
    isFetching: isFetchingMetadata,
    isIdle: isIdleMetadata
  } = useExperimentsDetails(
    {
      experimentKeys,
      isArchive,
      projectId,
      template: templateWrapper.currentTemplate
    },
    {
      enabled: Boolean(projectId) && !templateWrapper.isLoadingProjectTemplate,
      refetchInterval,
      refetchOnMount: true
    }
  );

  const experiments = useMemo(() => {
    const localExperiments = (experimentsData || [])
      // filter out cached data in case url is changed
      .filter(e => getExperimentArchived(e) === isArchive);

    // handle case where some experiments are archived and some are not
    if (localExperiments.length !== (experimentsData || []).length) {
      localExperiments.length = 0;
    }

    return localExperiments;
  }, [experimentsData, isArchive]);

  const handleDetailViewChange = useCallback(
    tabName => {
      trackEvent(experimentEvents.EXPERIMENT_DETAIL_TAB_SELECTED, {
        tab_selected: tabName.toUpperCase()
      });

      const parsedQuery = queryString.parse(location.search, PARSE_OPTIONS);

      const experimentKey1 = get(parsedQuery, COMPARE_EXPERIMENT_1_KEY, '');
      const experimentKey2 = get(parsedQuery, COMPARE_EXPERIMENT_2_KEY, '');

      const queryParamsObject = {
        'experiment-tab': tabName,
        viewId: parsedQuery.viewId,
        experiments: parsedQuery.experiments
      };

      if (experimentKey1) {
        queryParamsObject[COMPARE_EXPERIMENT_1_KEY] = experimentKey1;
      }

      if (experimentKey2) {
        queryParamsObject[COMPARE_EXPERIMENT_2_KEY] = experimentKey2;
      }

      const search = queryString.stringify(
        queryParamsObject,
        STRINGIFY_OPTIONS
      );

      const locationState = location.state || {};

      history.push({ search }, { preventScroll: true, ...locationState });
    },
    [history, location]
  );

  useEffect(() => {
    // enable/disable metadata pooling depend on if at least one experiment is active
    if (!refetchInterval && experiments?.some(e => getExperimentActive(e))) {
      setRefetchInterval(EXPERIMENT_DETAILS_POLLING_LENGTH);
    } else if (
      refetchInterval &&
      experiments?.every(e => !getExperimentActive(e))
    ) {
      setRefetchInterval(null);
    }
  }, [refetchInterval, experiments]);

  useEffect(() => {
    if (experimentKeys?.length && !sentBIEvent) {
      trackCompareViewEvent({
        canEdit: project.canEdit,
        experimentKeys,
        isOwner: project.isOwner,
        isPublic: project.isPublic,
        isShared: project.isShared,
        isUserLoggedIn,
        projectId
      });

      setSentBIEvent(true);
    }
  }, [sentBIEvent, experimentKeys?.length]);

  if (
    !isLoadingMetadata &&
    !isFetchingMetadata &&
    !isIdleMetadata &&
    !experiments?.length
  ) {
    return (
      <Redirect
        to={{
          pathname: `/${workspace}/${projectName}`,
          state: {
            nonExistentExperiment: true
          }
        }}
      />
    );
  }

  return (
    <ExperimentDetails
      experimentKeys={experimentKeys}
      experiments={experiments}
      isArchive={isArchive}
      columns={columns}
      projectId={projectId}
      isLoadingMetadata={isLoadingMetadata}
      isIdleMetadata={isIdleMetadata}
      workspace={workspace}
      projectName={projectName}
      onDetailViewChange={handleDetailViewChange}
      isComparison
    />
  );
};

export default CompareExperimentsDetails;
