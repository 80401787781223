export const countDecimals = value => {
  if (isNaN(Number(value))) return 0;

  if (Math.floor(value) === value) return 0;

  if (Number(value) < 0) {
    return countDecimals(Math.abs(Number(value)));
  }

  const str = value.toString();

  if (str.includes('.') && !str.includes('-')) {
    return str.split('.')[1].length || 0;
  }

  return Number(str.split('-')[1]) || 0;
};

export const maxRowDecimals = row => {
  return Math.max(
    countDecimals(row.valueCurrent),
    countDecimals(row.valueMin),
    countDecimals(row.valueMax)
  );
};

export const truncateValue = (value, decimalsPrecision) => {
  if (decimalsPrecision === null || decimalsPrecision === undefined) {
    return value;
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) return value;

  if (Number.isInteger(numberValue)) {
    return numberValue;
  }

  return Number(numberValue.toFixed(decimalsPrecision));
};
