import React from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';

import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import StyledSwitch from '@shared/components/StyledComponents/StyledSwitch';

const AutoRefreshToggle = ({ enabled, onChange }) => {
  const tooltipText = `Click to ${
    enabled ? 'disable' : 'enable'
  } auto refreshing data`;

  return (
    <Tooltip title={tooltipText} placement="top">
      <FormControlLabel
        className="auto-refresh-toggle-label"
        value="Auto Refresh"
        control={
          <StyledSwitch
            checked={enabled}
            onChange={() => onChange(!enabled)}
            color="primary"
          />
        }
        label="Auto Refresh"
        labelPlacement="end"
      />
    </Tooltip>
  );
};

AutoRefreshToggle.defaultProps = {
  enabled: false,
  onChange: noop
};

AutoRefreshToggle.propTypes = {
  enabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default AutoRefreshToggle;
