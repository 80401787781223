import React from 'react';
import PropTypes from 'prop-types';

import EmptyTabMessage from '@model-registry/components/EmptyTabMessage';
import classNames from './ModelRegistryNotes.module.scss';
import { MarkdownEditor } from '@shared/components';
import SmallLoader from '@shared/components/SmallLoader';

const ModelRegistryNotes = ({
  isUserAMemberOfWorkspace,
  modelNotes,
  isLoading,
  handleUpdate,
  dataTest,
  isEmpty
}) => {
  if (isLoading) {
    return (
      <SmallLoader
        dataTest="loading-model-registry-notes"
        primaryMessage="Loading..."
        secondaryMessage="Fetching model notes"
      />
    );
  }

  if (isEmpty) {
    return (
      <div
        data-test="model-registry-notes-empty"
        className={classNames.modelRegistryHistoryTabContainer}
      >
        <EmptyTabMessage type="notes" />
      </div>
    );
  }

  return (
    <div data-test={dataTest} className={classNames.modelRegistryNotes}>
      <div className={classNames.modelRegistryNotesInner}>
        <MarkdownEditor
          editable={isUserAMemberOfWorkspace}
          markdown={modelNotes || ''}
          onChange={handleUpdate}
        />
      </div>
    </div>
  );
};

ModelRegistryNotes.defaultProps = {
  dataTest: 'model-registry-notes'
};

ModelRegistryNotes.propTypes = {
  isUserAMemberOfWorkspace: PropTypes.bool.isRequired,
  dataTest: PropTypes.string,
  isEmpty: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  modelNotes: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    .isRequired
};

export default ModelRegistryNotes;
