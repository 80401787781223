export const OLD_GENERAL_PROJECT_NAME = 'general';
export const NEW_GENERAL_PROJECT_NAME = 'Uncategorized Experiments';

export const PROJECT_VISIBILITY = {
  PRIVATE: 'private',
  PUBLIC: 'public'
};

export const PROJECT_TYPE = {
  EM: 'EM',
  LLM: 'LLM'
};
