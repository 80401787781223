import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router';

import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import first from 'lodash/first';
import find from 'lodash/find';

import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  CONFUSION_MATRIX_CELL_VALUES,
  CONFUSION_MATRIX_COLOR_DISTRIBUTIONS,
  EXPERIMENT_VIEW_TAB_FIELDS
} from '@experiment-management-shared/constants/chartConstants';
import { getExperimentActive } from '@experiment-management-shared/utils/experimentHelpers';

import useAssetsForExperiments from '@experiment-management-shared/api/useAssetsForExperiments';
import chartActions from '@/actions/experimentDetails/chartActionsPlotly';
import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';

import SmallLoader from '@shared/components/SmallLoader';
import ConfusionMatrixBar from './ConfusionMatrixBar';
import { getMetricCharts } from '@/reducers/experimentDetails/chartsReducer';
import ConfusionMatricesContainer from './ConfusionMatricesContainer';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import ConfusionMatrixTabEmpty from './ConfusionMatrixTabEmpty';

const CONFUSION_MATRIX_ASSET_TYPE = 'confusion-matrix';
const ASSETS_IDS_QUERY_PARAM = 'assetId';
const COLOR_DISTRIBUTION_QUERY_PARAM = 'colorDistribution';
const CELL_VALUE_QUERY_PARAM = 'cellValue';

const ConfusionMatrixTab = ({ experiments }) => {
  const [experiment] = experiments;
  const { experimentKey } = experiment;

  const dispatch = useDispatch();
  const isComparePage = useSelector(selectIsComparePage);
  const chartsPlotly = useSelector(getMetricCharts);

  const { data: assetSummaries, isLoading } = useAssetsForExperiments({
    experiments,
    type: CONFUSION_MATRIX_ASSET_TYPE
  });

  const { currentTemplate } = useMemo(
    () => get(chartsPlotly, experimentKey, chartsPlotly),
    [chartsPlotly, experimentKey]
  );

  const {
    colorDistribution,
    cellValue,
    assetsIds: templateAssetsIds,
    compareAssetsIds: templateCompareAssetsIds
  } = currentTemplate[EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX];
  const tempAssetsIds = isComparePage
    ? templateCompareAssetsIds
    : templateAssetsIds;
  const { pathname, search } = useLocation();
  const parsedQuery = queryString.parse(search);

  const [selectedAssets, setSelectedAssets] = useState([]);
  const [assetsIds, setAssetId] = useState([]);

  useQueryParamsForExperiments(
    {
      [ASSETS_IDS_QUERY_PARAM]: assetsIds,
      [CELL_VALUE_QUERY_PARAM]: cellValue,
      [COLOR_DISTRIBUTION_QUERY_PARAM]: colorDistribution
    },
    [assetsIds, cellValue, colorDistribution, pathname],
    experimentKey,
    { preventUpdate: !assetsIds.length }
  );

  // when page is loaded first time parameters fromURL string should be set into template
  useEffect(() => {
    const parsedUrlAssetsIds = get(parsedQuery, ASSETS_IDS_QUERY_PARAM, null);
    const parsedUrlCellValue = get(parsedQuery, CELL_VALUE_QUERY_PARAM, null);
    const parsedUrlColorDistribution = get(
      parsedQuery,
      COLOR_DISTRIBUTION_QUERY_PARAM,
      null
    );
    const urlAssetsIds = isString(parsedUrlAssetsIds)
      ? parsedUrlAssetsIds.split(',')
      : [];

    const templateData = {};

    if (urlAssetsIds.length > 0) {
      templateData[
        isComparePage ? 'compareAssetsIds' : 'assetsIds'
      ] = urlAssetsIds;
    }

    if (
      Object.values(CONFUSION_MATRIX_CELL_VALUES).includes(parsedUrlCellValue)
    ) {
      templateData.cellValue = parsedUrlCellValue;
    }

    if (
      Object.values(CONFUSION_MATRIX_COLOR_DISTRIBUTIONS).includes(
        parsedUrlColorDistribution
      )
    ) {
      templateData.colorDistribution = parsedUrlColorDistribution;
    }

    if (!isEmpty(templateData)) {
      dispatch(
        chartActions.updateChartTemplate(experimentKey, {
          [EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX]: templateData
        })
      );
    }
  }, []);

  const calculateSelectedAssets = () => {
    let assets = [];

    // try to select artifacts from save template
    if (isArray(tempAssetsIds) && tempAssetsIds.length > 0) {
      assets = tempAssetsIds
        .map(id => assetSummaries.find(a => a.assetId === id))
        .filter(Boolean);
    }

    // try to select every first asset for every experiment
    if (assets.length === 0) {
      experiments.forEach(e => {
        let asset = null;
        const experimentAssets = assetSummaries.filter(
          a => a.experimentKey === e.experimentKey
        );

        const isActive = getExperimentActive(experiment);
        // in case the experiment is an active select the latest logged asset
        if (isActive) {
          asset = first(
            experimentAssets.sort((a, b) => b.createdAt - a.createdAt)
          );
          // otherwise select the first in sorted list
        } else {
          const experimentAssetsGroups = groupBy(experimentAssets, 'fileName');
          const firstMatrixName = Object.keys(experimentAssetsGroups).sort()[0];

          if (firstMatrixName) {
            asset = first(
              experimentAssetsGroups[firstMatrixName].sort(
                (assetA, assetB) => assetA.step - assetB.step
              )
            );
          }
        }

        if (asset) {
          assets.push(asset);
        }
      });
    }

    return assets;
  };

  useEffect(() => {
    if (isLoading) return;

    setSelectedAssets(calculateSelectedAssets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, assetSummaries]);

  // this effect is used to reset component state in case view was changes (for example: Discard Changes, select saved view)
  useEffect(() => {
    if (isArray(tempAssetsIds)) {
      const selectedAssetsIds = selectedAssets.map(a => a.assetId);

      if (!isEqual(selectedAssetsIds, tempAssetsIds)) {
        setSelectedAssets(calculateSelectedAssets());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempAssetsIds]);

  useEffect(() => {
    setAssetId(selectedAssets.map(a => a.assetId));
  }, [selectedAssets]);

  const handleCellValueChange = useCallback(
    value => {
      dispatch(
        chartActions.updateChartTemplate(experimentKey, {
          [EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX]: {
            cellValue: value
          }
        })
      );
    },
    [dispatch, experimentKey, isComparePage]
  );

  const handleColorDistributionChange = useCallback(
    value => {
      dispatch(
        chartActions.updateChartTemplate(experimentKey, {
          [EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX]: {
            colorDistribution: value
          }
        })
      );
    },
    [dispatch, experimentKey, isComparePage]
  );

  const handleChangeAssets = useCallback(
    values => {
      dispatch(
        chartActions.updateChartTemplate(experimentKey, {
          [EXPERIMENT_VIEW_TAB_FIELDS.CONFUSION_MATRIX]: {
            [isComparePage ? 'compareAssetsIds' : 'assetsIds']: values
          }
        })
      );
      setSelectedAssets(
        values.map(assetId => find(assetSummaries, a => a.assetId === assetId))
      );
    },
    [assetSummaries, dispatch, experimentKey, isComparePage]
  );

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  const isTabEmpty = !assetSummaries.length;

  return (
    <div className="gallery confusion-matrix">
      <ConfusionMatrixBar
        assetSummaries={assetSummaries}
        experiments={experiments}
        assetsIds={assetsIds}
        cellValue={cellValue}
        colorDistribution={colorDistribution}
        onChangeCellValue={handleCellValueChange}
        onChangeColorDistributionValue={handleColorDistributionChange}
        onChangeAssets={handleChangeAssets}
        isTabEmpty={isTabEmpty}
      />

      {isTabEmpty ? (
        <ConfusionMatrixTabEmpty isComparePage={isComparePage} />
      ) : (
        <ConfusionMatricesContainer
          experiments={experiments}
          assetSummaries={assetSummaries}
          assets={selectedAssets}
          assetsIds={assetsIds}
          onChangeAssets={handleChangeAssets}
          cellValue={cellValue}
          colorDistribution={colorDistribution}
        />
      )}
    </div>
  );
};

ConfusionMatrixTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default ConfusionMatrixTab;
