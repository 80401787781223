import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import {
  createArtifactVersionKey,
  TABLE_COLUMN_KEYS
} from '@artifacts/constants/constants';
import ArtifactTags from '@experiment-management-shared/components/ArtifactTags';
import { formatBytes, formatTimestampForTable } from '@/helpers/generalHelpers';
import { SUB_PATHS } from '@/constants/urlConstants';
import ArtifactAliases from '@experiment-management-shared/components/ArtifactAliases';
import EditArtifactVersionModalBtn from '@artifacts/components/EditArtifactVersionModalBtn';
import artifactActions from '@/actions/artifactActions';
import DownloadArtifactVersionBtn from '@artifacts/components/DownloadArtifactVersionBtn';
import { renderVersionState } from '@artifacts/utils/helpers';
import { IconButton, Tooltip } from '@ds';
import { DSDeleteIcon } from '@ds-icons';
import { useIsUserMemberOfWorkspace } from '@shared/hooks';

const { VERSION, CREATED_AT, SIZE, ALIAS, TAGS, STATE } = TABLE_COLUMN_KEYS;

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
    whiteSpace: 'nowrap'
  }
}))(TableCell);

const ArtifactVersionsTableRow = ({
  artifactVersion,
  artifactVersionId,
  isArtifactPublic
}) => {
  const {
    [VERSION]: versionNumber,
    [CREATED_AT]: createdAt,
    [STATE]: state,
    [SIZE]: size,
    [ALIAS]: aliases,
    [TAGS]: tags
  } = artifactVersion;

  const { workspace, artifactName } = useParams();
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();

  const dispatch = useDispatch();

  const handleOpenDeleteArtifactVersionModal = e => {
    if (e) {
      e.preventDefault();
    }

    const versionMapId = createArtifactVersionKey(
      workspace,
      artifactName,
      versionNumber
    );

    dispatch(
      artifactActions.openDeleteArtifactVersionModal(
        artifactVersionId,
        versionMapId
      )
    );
  };

  const renderRowActions = () => {
    return (
      <>
        {isUserAMemberOfWorkspace && (
          <EditArtifactVersionModalBtn
            artifactVersion={artifactVersion}
            versionNumber={versionNumber}
          />
        )}
        {(isUserAMemberOfWorkspace || isArtifactPublic) && (
          <DownloadArtifactVersionBtn artifactVersionId={artifactVersionId} />
        )}

        {isUserAMemberOfWorkspace && (
          <Tooltip content="Delete version">
            <IconButton
              Icon={<DSDeleteIcon />}
              type="secondary"
              onClick={handleOpenDeleteArtifactVersionModal}
            />
          </Tooltip>
        )}
      </>
    );
  };
  const artifactVersionURL = `/${workspace}/${
    SUB_PATHS.ARTIFACTS
  }/${encodeURIComponent(artifactName)}/${encodeURIComponent(versionNumber)}`;

  return (
    <TableRow
      key={artifactVersionId}
      component={Link}
      to={artifactVersionURL}
      className="artifact-table-row-container"
      hover
    >
      <StyledTableCell align="center" style={{ width: '1em' }}>
        {renderVersionState(state)}
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 100 }}>
        {versionNumber}
      </StyledTableCell>
      <StyledTableCell data-mask-test="00/00/0000 00:00:00 AM">
        {formatTimestampForTable(createdAt)}
      </StyledTableCell>
      <StyledTableCell>{formatBytes(size)}</StyledTableCell>
      <StyledTableCell>
        <ArtifactAliases aliases={aliases} />
      </StyledTableCell>
      <StyledTableCell>
        <ArtifactTags tagNames={tags} />
      </StyledTableCell>
      <StyledTableCell align="right">{renderRowActions()}</StyledTableCell>
    </TableRow>
  );
};

ArtifactVersionsTableRow.propTypes = {
  isArtifactPublic: PropTypes.bool,
  artifactVersion: PropTypes.object.isRequired,
  artifactVersionId: PropTypes.string.isRequired
};

export default ArtifactVersionsTableRow;
