import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import { notesActionTypes } from '../../../constants/actionTypes';

const useModelRegistryVersionNotesMutation = registryModelItemId => {
  const dispatch = useDispatch();
  const query = useQueryClient();

  return useMutation(
    notes =>
      modelRegistryApi.saveModelVersionNotes({
        registryModelItemId,
        notes
      }),
    {
      onSuccess: async () => {
        await query.invalidateQueries([
          'modelRegistryVersionNotes',
          { registryModelItemId }
        ]);

        dispatch({
          type: notesActionTypes.SET_LAST_SAVE,
          payload: { lastSave: Date.now() }
        });
      }
    }
  );
};

export default useModelRegistryVersionNotesMutation;
