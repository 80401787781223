// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-module__dsTagChip--A0OFH{color:#fff;height:20px;border-radius:50px;display:inline-block;padding:0 6px 0 6px;align-items:center;margin:4px;pointer-events:none}.Tag-module__dsTagChip--A0OFH>div{height:20px;line-height:20px}.Tag-module__dsTagChip--A0OFH:active{box-shadow:none;background-color:#fff;border-color:#000}.Tag-module__dsTagChipLabel--M6LLx{font-size:12px;line-height:12px;margin-right:4px;white-space:nowrap}.Tag-module__dsTagContainer--wxZmc{max-height:30px;overflow:hidden;white-space:nowrap}.Tag-module__dsTagContainerWrapper--N3fiH{position:relative}.Tag-module__dsTagContainerPopover--pCGWc{display:flex;gap:4px;flex-wrap:wrap;overflow:visible !important}.Tag-module__dsTagContainerOverflowed--jzAGS{padding-right:15px}.Tag-module__dsTagContainerOverflowedPopup--D_SOf{visibility:hidden;opacity:0;will-change:opacity,visibility;transition:all 100ms ease-in}.Tag-module__dsTagContainerOverflowedPopupHandler--PefGm:hover+.Tag-module__dsTagContainerOverflowedPopup--D_SOf{visibility:visible;opacity:1}.Tag-module__dsTagContainerOverflowedThreeDots--bJTP2{position:absolute;z-index:3;bottom:1px;right:-9px;padding:0 5px;width:28px;height:11px;cursor:initial !important}.Tag-module__dsTagContainerOverflowedThreeDotsAction--OSkHH{cursor:pointer !important;height:20px;line-height:11px}.Tag-module__dsTagPopover--DyPHL{position:absolute;z-index:3;right:0;top:36px;background-color:#fff;padding:20px;width:297px;min-width:297px;max-width:297px;box-shadow:0 0 4px rgba(0,0,0,.25);border-radius:4px;display:flex;gap:4px;flex-wrap:wrap}.Tag-module__dsTagPopover--DyPHL .Tag-module__dsTagChip--A0OFH{margin-bottom:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsTagChip": `Tag-module__dsTagChip--A0OFH`,
	"dsTagChipLabel": `Tag-module__dsTagChipLabel--M6LLx`,
	"dsTagContainer": `Tag-module__dsTagContainer--wxZmc`,
	"dsTagContainerWrapper": `Tag-module__dsTagContainerWrapper--N3fiH`,
	"dsTagContainerPopover": `Tag-module__dsTagContainerPopover--pCGWc`,
	"dsTagContainerOverflowed": `Tag-module__dsTagContainerOverflowed--jzAGS`,
	"dsTagContainerOverflowedPopup": `Tag-module__dsTagContainerOverflowedPopup--D_SOf`,
	"dsTagContainerOverflowedPopupHandler": `Tag-module__dsTagContainerOverflowedPopupHandler--PefGm`,
	"dsTagContainerOverflowedThreeDots": `Tag-module__dsTagContainerOverflowedThreeDots--bJTP2`,
	"dsTagContainerOverflowedThreeDotsAction": `Tag-module__dsTagContainerOverflowedThreeDotsAction--OSkHH`,
	"dsTagPopover": `Tag-module__dsTagPopover--DyPHL`
};
module.exports = ___CSS_LOADER_EXPORT___;
