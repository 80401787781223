import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { QueryConfig } from '@shared/api';

type CustomMetricQueryParams = {
  name?: string;
  cometSql: string;
  from: string;
  to: string;
  intervalType: string;
  modelId: string;
  version?: string;
  predicates?: string[];
};

type CustomMetricQueryResponse = {
  data: {
    alertNotifications: { x: string; y: number }[];
    data: { x: string; y: number }[];
    predicateKey: string;
  }[];
};

const getChartPoints = (response: CustomMetricQueryResponse) => {
  let x: string[] = [];
  let y: number[] = [];
  if (response?.data?.length) {
    for (const slice of response.data) {
      const { data } = slice;

      x = data.map(point => point.x);
      y = data.map(point => point.y);
    }
  }

  return {
    data: response.data,
    x,
    y
  };
};

const getCustomMetricQuery = ({ ...params }: CustomMetricQueryParams) =>
  api.post(`/mpm/v2/custom-metrics/query`, params);

export function useCustomMetricQuery(
  params: Omit<CustomMetricQueryParams, 'modelId'>,
  config: QueryConfig<CustomMetricQueryResponse>
) {
  const { modelId } = useParams<{ modelId: string }>();

  return useQuery(
    ['metric-query', { ...params }],
    () => getCustomMetricQuery({ ...params, modelId }),
    {
      ...config,
      select: getChartPoints
    }
  );
}
