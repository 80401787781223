import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import useDeepMemo from '@shared/hooks/useDeepMemo';

import { BETA_FEATURE_SYSTEM_METRICS_FETCH_TINY_SAMPLE } from '@/lib/betaFeatures';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { EXPERIMENT_DETAILS_SAMPLE_SIZES } from '@experiment-details/utils/panels';
import LineChart from '@experiment-management-shared/components/Charts/PlotlyChart/LineChart';
import {
  BUILT_IN_CHART_TYPES,
  LINE_CHART_X_AGGREGATIONS
} from '@experiment-management-shared/constants/chartConstants';
import { WALL } from '@experiment-management-shared/constants/experimentConstants';
import {
  CPU_MEMORY_AVAILABLE_PATTERN,
  CPU_MEMORY_USAGE_PATTERN,
  CPU_OVERALL_PATTERN,
  CPU_PERCENT_PATTERN,
  CPU_UTILIZED_PATTERN,
  DISK_PERCENT_USED_PATTERN,
  DISK_READ_PATTERN,
  DISK_USED_PATTERN,
  DISK_WRITE_PATTERN,
  GPU_MEMORY_USAGE_PATTERN,
  GPU_MEMORY_UTILIZATION_PATTERN,
  GPU_POWER_USAGE_PATTERN,
  GPU_TEMPERATURE_PATTERN,
  GPU_UTILIZATION_PATTERN,
  NETWORK_RECEIVE_PATTERN,
  NETWORK_SEND_PATTERN
} from '@experiment-management-shared/utils/experimentHelpers';
import withWidth from '@material-ui/core/withWidth';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import SystemMetricsChartsHeader from './SystemMetricsChartsHeader';
import { calculateLineTypeStrategy } from '@experiment-management-shared';
import { LEGEND_MODE } from '@experiment-management-shared/constants';

const HIDE_MENU_ITEMS = [
  'delete-chart',
  'edit-chart',
  'embed-panel',
  'share-panel'
];

/**
 * What the metrics is using to present charts (collected data from SDK team)
 *
 * Possible params for GPU_UTILIZATION
 * - NODE_PREFIX?.sys.gpu.GPU_INDEX.gpu_utilization
 * Possible params for GPU_MEMORY_USAGE
 * - NODE_PREFIX?.sys.gpu.GPU_INDEX.used_memory
 * Possible params for CPU_UTILIZATION
 * - NODE_PREFIX?.sys.cpu.percent.CPU_INDEX
 * - NODE_PREFIX?.sys.compute.overall (Not available on old SDK, older than 3.31.21)
 * - NODE_PREFIX?.sys.compute.utilized  (Not available on old SDK, older than 3.31.21)
 * Or for distributed nodes
 * - NODE_PREFIX?.sys.cpu.percent.CPU_INDEX
 * - NODE_PREFIX?.sys.compute.overall (Not available on old SDK, older than 3.31.21)
 * - NODE_PREFIX?.sys.compute.utilized (Not available on old SDK, older than 3.31.21)
 * Possible params for CPU_MEMORY_USAGE
 * - NODE_PREFIX?.sys.ram.used
 * Possible params for NETWORK
 * - NODE_PREFIX?.sys.network.send_bps
 * - NODE_PREFIX?.sys.network.receive_bps
 *
 * Average properties names and formulas rules for calculation
 * GPU_UTILIZATION
 * - sys.gpu_utilization_avg = AVG([XX]?.sys.gpu[XX].gpu_utilization)
 *
 * GPU_MEMORY_USAGE
 * - sys.gpu_used_memory_avg = AVG([XX]?.sys.gpu[XX].used_memory)
 *
 * CPU_UTILIZATION
 * If exist [XX]?.sys.compute.overall, use
 * - sys.cpu_overall_avg = AVG([XX]?.sys.compute.overall)
 * Otherwise
 * - sys.cpu_overall_avg = AVG([XX]?.sys.cpu.percent[XX])
 *
 * If exist [XX]?.sys.compute.utilized, use
 * - sys.cpu_utilized_avg = AVG([XX]?.sys.compute.utilized)
 * Otherwise not possible to calculate
 *
 * CPU_MEMORY_USAGE
 * - sys.used_ram_avg = AVG([XX]?.sys.ram.used)
 *
 * NETWORK
 * - sys.network.send_bps_avg = AVG([XX]?.sys.network.send_bps)
 * - sys.network.receive_bps_avg = AVG([XX]?.sys.network.receive_bps)
 *
 */

const AGGREGATED_METRIC_NAMES = {
  CPU_OVERALL_AVG: 'sys.cpu_overall_avg',
  CPU_UTILIZED_AVG: 'sys.cpu_utilized_avg',
  DISK_IO_UTILIZATION_READ_AVG: 'sys.disk.read_bps_avg',
  DISK_IO_UTILIZATION_WRITE_AVG: 'sys.disk.write_bps_avg',
  DISK_PERCENT_UTILIZATION_AVG: 'sys.disk.root.percent.used_avg',
  DISK_UTILIZATION_AVG: 'sys.disk.root.used_avg',
  GPU_MEMORY_UTILIZATION_AVG: 'sys.gpu_memory_utilization_avg',
  GPU_POWER_USAGE_AVG: 'sys.gpu_power_usage_avg',
  GPU_TEMPERATURE_AVG: 'sys.gpu_temperature_avg',
  GPU_USED_MEMORY_AVG: 'sys.gpu_used_memory_avg',
  GPU_UTILIZATION_AVG: 'sys.gpu_utilization_avg',
  NETWORK_RECEIVE_AVG: 'sys.network.receive_bps_avg',
  NETWORK_SEND_AVG: 'sys.network.send_bps_avg',
  RAM_AVAILABLE_AVG: 'sys.available_ram_avg',
  RAM_USED_AVG: 'sys.used_ram_avg'
};

const SYSTEM_METRIC_TYPES = {
  CPU_MEMORY_AVAILABLE: 'ram_available',
  CPU_MEMORY_USAGE: 'ram',
  CPU_UTILIZATION: 'cpu',
  DISK_IO_UTILIZATION: 'disk_io',
  DISK_PERCENT_UTILIZATION: 'disk_percent',
  DISK_UTILIZATION: 'disk',
  GPU_MEMORY_USAGE: 'used_memory',
  GPU_POWER_USAGE: 'gpu_power_usage',
  GPU_TEMPERATURE: 'gpu_temperature',
  GPU_UTILIZATION: 'gpu_utilization',
  GPU_MEMORY_UTILIZATION: 'gpu_memory_utilization',
  NETWORK: 'network'
};

export const SYSTEM_METRIC_CHART_TITLES = {
  CPU_MEMORY_USAGE: 'Memory Usage',
  CPU_UTILIZATION: 'CPU Utilization',
  DISK_UTILIZATION: 'Disk Utilization',
  DISK_PERCENT_UTILIZATION: 'Disk Utilization (%)',
  DISK_IO_UTILIZATION: 'Disk I/O Utilization',
  GPU_MEMORY_USAGE: 'GPU Memory Usage',
  GPU_POWER_USAGE: 'GPU Power Usage',
  GPU_TEMPERATURE: 'GPU Temperature',
  GPU_UTILIZATION: 'GPU Utilization',
  GPU_MEMORY_UTILIZATION: 'GPU Memory Utilization',
  NETWORK_USAGE: 'Network Usage'
};

const METRICS_PATTERNS = {
  [SYSTEM_METRIC_TYPES.CPU_MEMORY_USAGE]: [
    CPU_MEMORY_USAGE_PATTERN,
    CPU_MEMORY_AVAILABLE_PATTERN
  ],
  [SYSTEM_METRIC_TYPES.CPU_UTILIZATION]: [
    CPU_OVERALL_PATTERN,
    CPU_UTILIZED_PATTERN,
    CPU_PERCENT_PATTERN
  ],
  [SYSTEM_METRIC_TYPES.DISK_IO_UTILIZATION]: [
    DISK_READ_PATTERN,
    DISK_WRITE_PATTERN
  ],
  [SYSTEM_METRIC_TYPES.DISK_PERCENT_UTILIZATION]: [DISK_PERCENT_USED_PATTERN],
  [SYSTEM_METRIC_TYPES.DISK_UTILIZATION]: [DISK_USED_PATTERN],
  [SYSTEM_METRIC_TYPES.GPU_MEMORY_USAGE]: [GPU_MEMORY_USAGE_PATTERN],
  [SYSTEM_METRIC_TYPES.GPU_POWER_USAGE]: [GPU_POWER_USAGE_PATTERN],
  [SYSTEM_METRIC_TYPES.GPU_TEMPERATURE]: [GPU_TEMPERATURE_PATTERN],
  [SYSTEM_METRIC_TYPES.GPU_UTILIZATION]: [GPU_UTILIZATION_PATTERN],
  [SYSTEM_METRIC_TYPES.GPU_MEMORY_UTILIZATION]: [
    GPU_MEMORY_UTILIZATION_PATTERN
  ],
  [SYSTEM_METRIC_TYPES.NETWORK]: [NETWORK_RECEIVE_PATTERN, NETWORK_SEND_PATTERN]
};

const convertBytesToGigabytes = bytes => bytes / Math.pow(1024, 3);

const convertYValuesToGigabytes = yValues =>
  yValues.map(convertBytesToGigabytes);

const convertBpsToKBps = bps => bps / 1000;

const convertMWToWatts = mw => mw / 1000;

const convertYValuesToWatts = yValues => yValues.map(convertMWToWatts);
const convertYValuesToKBps = yValues => yValues.map(convertBpsToKBps);

const calculateMetricNames = (allMetricNames, type) =>
  allMetricNames.filter(m => METRICS_PATTERNS[type].some(p => p.test(m)));

const calculateCPUUtilizationMetricNames = (
  allMetricNames,
  type,
  isCompare
) => {
  if (!isCompare) {
    const sysComputeMetrics = allMetricNames.filter(
      metricName =>
        CPU_OVERALL_PATTERN.test(metricName) ||
        CPU_UTILIZED_PATTERN.test(metricName)
    );

    // In case we have computed metric we only need to request that information
    if (sysComputeMetrics.length > 0) return sysComputeMetrics;
  }

  return calculateMetricNames(allMetricNames, type);
};

const aggregateMetric = (metricName, metrics) => {
  if (metrics.length === 1) {
    return {
      ...metrics[0],
      metricName
    };
  }

  const retVal = {
    metricName,
    values: [],
    steps: [],
    epochs: [],
    timestamps: [],
    durations: []
  };

  // found the metric with the biggest amount of values
  const iterations = Math.max.apply(
    null,
    metrics.map(m => m.values.length)
  );

  for (let i = 0; i < iterations; i++) {
    let metricsCount = 0;
    let valuesSum = 0;
    let step = null;
    let epoch = null;
    let timestamp = null;
    let duration = null;

    metrics.forEach(metric => {
      // in case we don't have value we are not calculate it in the average
      if (!isNull(metric.values[i]) && !isUndefined(metric.values[i])) {
        metricsCount += 1;
        valuesSum += parseFloat(metric.values[i]);
        step =
          isNull(step) && isNull(metric.steps[i])
            ? null
            : Math.max(step, metric.steps[i]);
        epoch =
          isNull(epoch) && isNull(metric.epochs[i])
            ? null
            : Math.max(epoch, metric.epochs[i]);
        timestamp =
          isNull(timestamp) && isNull(metric.timestamps[i])
            ? null
            : Math.max(timestamp, metric.timestamps[i]);
        duration =
          isNull(duration) && isNull(metric.durations[i])
            ? null
            : Math.max(duration, metric.durations[i]);
      }
    });

    retVal.values.push((valuesSum / metricsCount).toFixed(3));
    retVal.steps.push(step);
    retVal.epochs.push(epoch);
    retVal.timestamps.push(timestamp);
    retVal.durations.push(duration);
  }

  return retVal;
};

const transformData = (type, data) => {
  const retVal = {
    empty: false,
    experiments: {}
  };

  if (data?.experiments) {
    Object.keys(data.experiments).forEach(experimentKey => {
      let experiment = { ...data.experiments[experimentKey], metrics: [] };
      const { metrics } = data.experiments[experimentKey];
      let metricsToAggregate;

      // @todo: refactor to automatically map metrics to avg metrics
      switch (type) {
        case SYSTEM_METRIC_TYPES.GPU_TEMPERATURE:
          metricsToAggregate = metrics.filter(m =>
            GPU_TEMPERATURE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.GPU_TEMPERATURE_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.GPU_POWER_USAGE:
          metricsToAggregate = metrics.filter(m =>
            GPU_POWER_USAGE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.GPU_POWER_USAGE_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.DISK_IO_UTILIZATION:
          metricsToAggregate = metrics.filter(m =>
            DISK_READ_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.DISK_IO_UTILIZATION_READ_AVG,
                metricsToAggregate
              )
            );
          }

          metricsToAggregate = metrics.filter(m =>
            DISK_WRITE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.DISK_IO_UTILIZATION_WRITE_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.DISK_UTILIZATION:
          metricsToAggregate = metrics.filter(m =>
            DISK_USED_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.DISK_UTILIZATION_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.DISK_PERCENT_UTILIZATION:
          metricsToAggregate = metrics.filter(m =>
            DISK_PERCENT_USED_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.DISK_PERCENT_UTILIZATION_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.NETWORK:
          metricsToAggregate = metrics.filter(m =>
            NETWORK_RECEIVE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.NETWORK_RECEIVE_AVG,
                metricsToAggregate
              )
            );
          }

          metricsToAggregate = metrics.filter(m =>
            NETWORK_SEND_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.NETWORK_SEND_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.GPU_UTILIZATION:
          metricsToAggregate = metrics.filter(m =>
            GPU_UTILIZATION_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.GPU_UTILIZATION_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.GPU_MEMORY_UTILIZATION:
          metricsToAggregate = metrics.filter(m =>
            GPU_MEMORY_UTILIZATION_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.GPU_MEMORY_UTILIZATION_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.GPU_MEMORY_USAGE:
          metricsToAggregate = metrics.filter(m =>
            GPU_MEMORY_USAGE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.GPU_USED_MEMORY_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        case SYSTEM_METRIC_TYPES.CPU_UTILIZATION:
          // aggregate sys.compute.overall
          // In case we have calculated overall for experiments we need to aggregate it
          metricsToAggregate = metrics.filter(m =>
            CPU_OVERALL_PATTERN.test(m.metricName)
          );

          // In case we don't have calculated overall (SDK version before 3.31.21),
          // try to calculate overall base on [XX]?.sys.cpu.percent[XX]
          if (!metricsToAggregate.length) {
            metricsToAggregate = metrics.filter(m =>
              CPU_PERCENT_PATTERN.test(m.metricName)
            );
          }

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.CPU_OVERALL_AVG,
                metricsToAggregate
              )
            );
          }

          // aggregate sys.compute.utilized
          metricsToAggregate = metrics.filter(m =>
            CPU_UTILIZED_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.GPU_UTILIZATION_AVG,
                metricsToAggregate
              )
            );
          }

          break;
        case SYSTEM_METRIC_TYPES.CPU_MEMORY_USAGE:
          metricsToAggregate = metrics.filter(m =>
            CPU_MEMORY_USAGE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.RAM_USED_AVG,
                metricsToAggregate
              )
            );
          }

          metricsToAggregate = metrics.filter(m =>
            CPU_MEMORY_AVAILABLE_PATTERN.test(m.metricName)
          );

          if (metricsToAggregate.length) {
            experiment.metrics.push(
              aggregateMetric(
                AGGREGATED_METRIC_NAMES.RAM_AVAILABLE_AVG,
                metricsToAggregate
              )
            );
          }
          break;
        default:
          experiment = data.experiments[experimentKey];
          break;
      }

      retVal.experiments[experimentKey] = experiment;
    });
  }

  return retVal;
};

const SystemMetricCharts = props => {
  const {
    entireRowCharts,
    experiments,
    maxTotalMemory,
    isComparePage,
    totalRam,
    metricNames,
    xAxisType,
    width
  } = props;
  const isFetchTinySampleEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_SYSTEM_METRICS_FETCH_TINY_SAMPLE
  );

  const experimentKeys = useDeepMemo(() => {
    return experiments.map(experiment => experiment.experimentKey);
  }, [experiments]);

  const currentTemplate = {
    charts: [
      {
        chartId: 'SYSTEM_METRICS_GPU_UTILIZATION',
        title: SYSTEM_METRIC_CHART_TITLES.GPU_UTILIZATION,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.GPU_UTILIZATION,
        aggregationX: LINE_CHART_X_AGGREGATIONS.ALL.value,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: '%'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.GPU_UTILIZATION_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_GPU_MEMORY_UTILIZATION',
        title: SYSTEM_METRIC_CHART_TITLES.GPU_MEMORY_UTILIZATION,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.GPU_MEMORY_UTILIZATION,
        aggregationX: LINE_CHART_X_AGGREGATIONS.ALL.value,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: '%'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.GPU_MEMORY_UTILIZATION_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_GPU_POWER_USAGE',
        title: SYSTEM_METRIC_CHART_TITLES.GPU_POWER_USAGE,
        calculateMetricNames,
        customTransformY: convertYValuesToWatts,
        type: SYSTEM_METRIC_TYPES.GPU_POWER_USAGE,
        aggregationX: LINE_CHART_X_AGGREGATIONS.ALL.value,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: ' W'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.GPU_POWER_USAGE_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_GPU_MEMORY_USAGE',
        customTransformY: convertYValuesToGigabytes,
        title: SYSTEM_METRIC_CHART_TITLES.GPU_MEMORY_USAGE,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.GPU_MEMORY_USAGE,
        aggregationX: LINE_CHART_X_AGGREGATIONS.ALL.value,
        yAxisRange: {
          range: [0, convertBytesToGigabytes(maxTotalMemory) * 1.01],
          ticksuffix: ' GB'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.GPU_USED_MEMORY_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_GPU_TEMPERATURE',
        title: SYSTEM_METRIC_CHART_TITLES.GPU_TEMPERATURE,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.GPU_TEMPERATURE,
        aggregationX: LINE_CHART_X_AGGREGATIONS.ALL.value,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: ' C'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.GPU_TEMPERATURE_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_NETWORK',
        customTransformY: convertYValuesToKBps,
        title: SYSTEM_METRIC_CHART_TITLES.NETWORK_USAGE,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.NETWORK,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: ' kBps'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.NETWORK_SEND_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_CPU_UTILIZATION',
        title: SYSTEM_METRIC_CHART_TITLES.CPU_UTILIZATION,
        calculateMetricNames: calculateCPUUtilizationMetricNames,
        type: SYSTEM_METRIC_TYPES.CPU_UTILIZATION,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: '%'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.CPU_OVERALL_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_RAM_USAGE',
        customTransformY: convertYValuesToGigabytes,
        title: SYSTEM_METRIC_CHART_TITLES.CPU_MEMORY_USAGE,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.CPU_MEMORY_USAGE,
        yAxisRange: {
          range: [0, convertBytesToGigabytes(totalRam) * 1.01],
          ticksuffix: ' GB'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.RAM_USED_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_DISK_UTILIZATION',
        customTransformY: convertYValuesToGigabytes,
        title: SYSTEM_METRIC_CHART_TITLES.DISK_UTILIZATION,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.DISK_UTILIZATION,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: ' GB'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.DISK_UTILIZATION_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_DISK_PERCENT_UTILIZATION',
        customTransformY: convertYValuesToKBps,
        title: SYSTEM_METRIC_CHART_TITLES.DISK_PERCENT_UTILIZATION,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.DISK_PERCENT_UTILIZATION,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: '%'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.DISK_PERCENT_UTILIZATION_AVG
          : null
      },
      {
        chartId: 'SYSTEM_METRICS_DISK_IO_UTILIZATION',
        customTransformY: convertYValuesToKBps,
        title: SYSTEM_METRIC_CHART_TITLES.DISK_IO_UTILIZATION,
        calculateMetricNames,
        type: SYSTEM_METRIC_TYPES.DISK_IO_UTILIZATION,
        yAxisRange: {
          rangemode: 'tozero',
          ticksuffix: ' kBps'
        },
        selectedYAxis: isComparePage
          ? AGGREGATED_METRIC_NAMES.DISK_IO_UTILIZATION_READ_AVG
          : null
      }
    ],
    smoothingY: 0
  };

  currentTemplate.charts = currentTemplate.charts
    .map(chart => {
      return {
        ...chart,
        chartResponseDataTransform: isComparePage
          ? transformData.bind(null, chart.type)
          : null,
        metricNames: chart
          .calculateMetricNames(metricNames, chart.type, isComparePage)
          .sort()
      };
    })
    .filter(chart => chart.metricNames.length);

  const getChartItemClass = useCallback(() => {
    const baseClass = 'chart-grid-item';
    const widthClass = entireRowCharts
      ? 'grow-to-full-width'
      : `grow-to-viewport-${width}`;

    return `${baseClass} ${widthClass}`;
  }, [entireRowCharts, width]);

  const getChartGridClass = () => {
    const chartGridExpandedClass = entireRowCharts ? 'entire-row' : '';
    return `chart-grid ${width} ${chartGridExpandedClass}`;
  };

  const { charts, smoothingY } = currentTemplate;

  const renderCharts = () => {
    const itemClassName = getChartItemClass();

    return charts.map(chart => {
      const containerProps = {
        chartId: chart.chartId,
        chartType: BUILT_IN_CHART_TYPES['BuiltIn/Line'],
        experimentKeys
      };

      return (
        <div
          className={itemClassName}
          key={chart.chartId}
          data-test={chart.type}
        >
          <LineChart
            containerProps={containerProps}
            experimentKeys={experimentKeys}
            experiments={experiments}
            isAutoRefreshEnabled
            title={chart.title}
            fetchFull={!isFetchTinySampleEnabled}
            sampleSizes={EXPERIMENT_DETAILS_SAMPLE_SIZES}
            metricNames={chart.metricNames}
            aggregationX={chart.aggregationX}
            customTransformY={chart.customTransformY}
            chartResponseDataTransform={chart.chartResponseDataTransform}
            showDefaultColors={!isComparePage}
            selectedXAxis={xAxisType}
            selectedYAxis={chart.selectedYAxis}
            smoothingY={smoothingY}
            lineTypeStrategy={calculateLineTypeStrategy({
              isComparePage
            })}
            legendMode={LEGEND_MODE.ON}
            chartClickEnabled={isComparePage}
            hiddenMenuItems={HIDE_MENU_ITEMS}
            yAxisRange={chart.yAxisRange}
          />
        </div>
      );
    });
  };

  if (isEmpty(charts)) return null;

  return (
    <>
      <SystemMetricsChartsHeader
        entireRowCharts={entireRowCharts}
        xAxisType={xAxisType}
        isComparePage={isComparePage}
      />

      <div className="charts-container system-metrics-container">
        <div className={getChartGridClass()}>{renderCharts()}</div>
      </div>
    </>
  );
};

SystemMetricCharts.defaultProps = {
  entireRowCharts: false,
  maxTotalMemory: 0,
  totalRam: 16000000000,
  metricNames: [],
  xAxisType: WALL
};

SystemMetricCharts.propTypes = {
  entireRowCharts: PropTypes.bool,
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  isComparePage: PropTypes.bool.isRequired,
  maxTotalMemory: PropTypes.number,
  totalRam: PropTypes.number,
  metricNames: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.string.isRequired,
  xAxisType: PropTypes.string
};

export default withWidth()(SystemMetricCharts);
