import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import api from '@shared/api';
import { useQuery } from 'react-query';

const getExperimentImages = async ({ experimentKey, signal }) => {
  const { data } = await api.get('data-fetch/get/images', {
    params: { experimentKey },
    signal
  });

  return data.images;
};

export default function useExperimentImages(
  { experimentKey, isActive },
  config
) {
  return useQuery(
    ['experiment-images', { experimentKey }],
    ({ signal }) => {
      return getExperimentImages({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      refetchInterval: isActive ? FETCH_EXPERIMENT_POLLING_LENGTH : null,
      ...config
    }
  );
}
