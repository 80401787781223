import { noop } from 'lodash';
import React from 'react';

import styles from './AssetThumbnail.module.scss';

function AssetThumbnail({
  name = '',
  onClick = noop,
  src = ''
}: {
  name?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  src?: string;
}) {
  return (
    <div className={styles.thumbnail} onClick={onClick}>
      {src ? (
        <img alt={name} src={src} />
      ) : (
        <div className={styles.noThumbnail}>Thumbnail not found</div>
      )}
    </div>
  );
}

export default AssetThumbnail;
