import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputLabel } from '@material-ui/core';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { useDebouncedFilter } from '@shared/hooks';

import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import { MultipleAutocomplete } from '@DesignSystem/controllers';
import {
  FILTERING_MODE,
  NO_ITEMS_STRATEGY
} from '@DesignSystem/controllers/MultipleAutocomplete/MultipleAutocomplete';
import { CHIP_TYPE_BADGE } from '@DesignSystem/data-display/Chip/Chip';
import { SearchIcon } from '@Icons-outdated';
import { Slider } from '@design-system-outdated/components';
import {
  usePCDPanelSteps,
  usePCDSearch
} from '@experiment-management-shared/api';
import AssetThumbnail from '@experiment-management-shared/components/Charts/NoPlotlyCharts/GridPanel/AssetThumbnail';
import { useStepsProps } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/GridPanel/useStepsProps';
import { useTargetExperimentKeys } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/GridPanel/useTargetExperimentKeys';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import { PCDAssetData } from '@experiment-management-shared/types';

const DELAYS_MS = 250;
const MIN_EXPERIMENT_COUNT = 1;
const MAX_EXPERIMENT_COUNT = 25;

type Option = {
  asset: PCDAssetData;
  value: string;
};

const PCDPanelDataTab = ({
  experimentKeys,
  hiddenExperimentKeys
}: {
  experimentKeys: string[];
  hiddenExperimentKeys: string[];
}) => {
  const dispatch = useDispatch();
  const panelForm = useSelector(state =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.pcd })
  );

  const [assetNames, setAssetNames] = useState(panelForm.assetNames);
  const [experimentsCountValue, setExperimentsCountValue] = useState(
    panelForm.experimentsCount
  );

  const {
    debouncedFilterValue: autocompleteDebouncedFilterValue,
    setFilterValue: setAutocompleteFilterValue,
    filterValue: autocompleteFilterValue
  } = useDebouncedFilter('');

  const targetExperimentKeys = useTargetExperimentKeys({
    experimentKeys,
    experimentsCount: experimentsCountValue,
    hiddenExperimentKeys
  });

  const { data: assets, isLoading: isLoadingSearchData } = usePCDSearch({
    experimentKeys: targetExperimentKeys,
    searchPhrase: autocompleteDebouncedFilterValue
  });

  const { data: steps, isLoading: isLoadingStepsData } = usePCDPanelSteps(
    { assetNames, experimentKeys: targetExperimentKeys },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const { sliderProps } = useStepsProps({
    isLoading: isLoadingStepsData,
    step: 0,
    steps
  });

  useEffect(() => {
    const isFormErased =
      panelForm.assetNames.length === 0 && assetNames.length > 0;
    if (!isFormErased) return;

    setAssetNames([]);
  }, [panelForm.assetNames.length, assetNames.length]);

  const options: Option[] = useMemo(() => {
    return (assets || [])
      .map(asset => ({
        asset,
        value: asset.name
      }))
      .filter(o => !assetNames.includes(o.value));
  }, [assets, assetNames]);

  const handleChangeAssetsValue = (value: string[]) => {
    setAssetNames(value);

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.pcd,
        'assetNames',
        value
      )
    );
  };

  const handleDebouncedChangeExperimentsCount = useMemo(() => {
    return debounce(
      count =>
        dispatch(
          dashboardChartsActions.updateChartFormKey(
            BUILT_IN_CHART_TYPES.pcd,
            'experimentsCount',
            count
          )
        ),
      DELAYS_MS
    );
  }, [dispatch]);

  const handleChangeExperimentsCount = (count: number) => {
    setExperimentsCountValue(count);
    handleDebouncedChangeExperimentsCount(count);
  };

  const renderOption = (option: Option) => {
    const src = option.asset.thumbnailPath;

    return (
      <>
        <div className="ds-dropdown-popper-item-content">
          <AssetThumbnail name={option.value} src={src} />
        </div>
        <div className="ds-dropdown-popper-label">{option.value}</div>
      </>
    );
  };

  const checkValueToAdd = (value: string) => {
    return options.find(o => o.value === value) && !assetNames.includes(value);
  };

  const renderAssetsAutocomplete = () => {
    const isInputDisabled = assetNames.length >= 10;
    const noItemsStrategy =
      autocompleteDebouncedFilterValue.length >= 3 && options.length === 0
        ? NO_ITEMS_STRATEGY.SHOW_MESSAGE
        : NO_ITEMS_STRATEGY.NO_MESSAGE;

    return (
      <MultipleAutocomplete
        checkValueToAdd={checkValueToAdd}
        chipType={CHIP_TYPE_BADGE}
        classes="grid graphics-panel-data-tab-autocomplete"
        description="The 3D asset names will be presented as a row, you can move the badges to change asset order. Select up to 10 assets."
        disabled={isInputDisabled}
        filterMode={FILTERING_MODE.NONE}
        filterValue={autocompleteFilterValue}
        label="Asset name"
        loading={isLoadingSearchData}
        noItemsStrategy={noItemsStrategy}
        noValuePlaceholder="Enter at least 3 characters to begin search"
        onChangeFilterValue={setAutocompleteFilterValue}
        onChangeValue={handleChangeAssetsValue}
        options={options}
        renderListItem={renderOption}
        sortable
        SuffixIcon={<SearchIcon />}
        value={assetNames}
        withValuePlaceholder="Select more assets"
        maxValuesLimit={10}
      />
    );
  };

  const renderStepsSlider = () => {
    return (
      <div
        className="modal-input-group"
        style={{ marginBottom: 60, marginTop: 60 }}
      >
        <InputLabel className="modal-input-label">Step</InputLabel>
        <div className="slider-wrapper">
          <Slider {...sliderProps} />
        </div>
      </div>
    );
  };

  const renderExperimentsSlider = () => (
    <div className="modal-input-group" style={{ marginBottom: 60 }}>
      <InputLabel className="modal-input-label">
        Max number of experiments to show
      </InputLabel>
      <div className="slider-wrapper">
        <Slider
          max={MAX_EXPERIMENT_COUNT}
          min={MIN_EXPERIMENT_COUNT}
          onChange={handleChangeExperimentsCount}
          step={1}
          value={experimentsCountValue}
          valueLabelFormat={(value: number) => String(value)}
        />
      </div>
    </div>
  );

  return (
    <div className="image-video-panel-data-tab">
      {renderAssetsAutocomplete()}
      {renderStepsSlider()}
      {renderExperimentsSlider()}
    </div>
  );
};

export default PCDPanelDataTab;
