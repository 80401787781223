import styles from '@projects/components/LLMDashboardHeader/LLMDashboardHeader.module.scss';
import { InfoIcon } from '@Icons-outdated';
import React from 'react';
import { Divider } from '@DesignSystem/data-display';

export default function InputDropdownHeader() {
  return (
    <>
      <Divider margin="12px" orientation="horizontal" />
      <div className={styles.operatorsListHeader}>
        <InfoIcon
          color="var(--gray-6)"
          width="16"
          height="17"
          viewBox="0 0 12 13"
          style={{ color: 'var(--gray-6)' }}
        />
        <p style={{ fontSize: '12px', color: 'var(--gray-6)' }}>
          The search field supports wildcards, use{' '}
          <span className={styles.markdown}>*value*</span> to return all prompts
          that contain value
        </p>
      </div>
    </>
  );
}
