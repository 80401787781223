import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import RegisterModelSelect from './RegisterModelSelect';
import RegisterModelGoToModelExperimentAssets from './RegisterModelGoToModelExperimentAssets';

const RegisterModelSelectModelToRegister = ({
  allAvailableModelsOptions,
  selectedModel,
  setSelectedModel,
  modelAssetsInfo
}) => {
  return (
    <Grid item xs={12}>
      <RegisterModelSelect
        dataTest="streamline-model-sidebar-select-model-to-register"
        options={allAvailableModelsOptions}
        label="Select a model to register"
        placeholder="model name"
        value={selectedModel?.value}
        onChange={(_, data) => setSelectedModel(data)}
      />
      <RegisterModelGoToModelExperimentAssets
        modelAssetsInfo={modelAssetsInfo}
        experimentKey={selectedModel?.experimentKey}
        selectedModel={selectedModel}
      />
    </Grid>
  );
};

RegisterModelSelectModelToRegister.defaultProps = {
  modelAssetsInfo: null,
  selectedModel: null,
  allAvailableModelsOptions: []
};

RegisterModelSelectModelToRegister.propTypes = {
  selectedModel: PropTypes.shape({
    experimentKey: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  modelAssetsInfo: PropTypes.shape({
    assetId: PropTypes.string,
    assetPath: PropTypes.string
  }),
  setSelectedModel: PropTypes.func.isRequired,
  allAvailableModelsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default RegisterModelSelectModelToRegister;
