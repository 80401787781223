import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import { dashboardEvents } from '@/constants/trackingEventTypes';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { PanelGlobalConfig } from '@experiment-management-shared/types';
import typography from '@ds-typography';
import { IconButton, Tooltip } from '@ds';
import { DSSmoothingIcon } from '@ds-icons';
import { Slider } from '@design-system-outdated';

import Popover from '@material-ui/core/Popover';

import styles from './Button.module.scss';

const SMOOTHING_CHANGE_DELAY = 300;

type SmoothingButtonProps = {
  disabled: boolean;
  panelGlobalConfig: PanelGlobalConfig;
  onChange: (changes: unknown) => void;
  showLockedPanelsWarning: boolean;
};
const SmoothingButton = ({
  disabled,
  panelGlobalConfig,
  onChange,
  showLockedPanelsWarning
}: SmoothingButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const baseTrackEvent = useBaseTrackEvent();

  const [localSmoothing, setLocalSmoothing] = useState<number | null>(null);

  useEffect(() => {
    setLocalSmoothing(null);
  }, [panelGlobalConfig?.smoothingY]);

  const debouncedSmoothingChange = useCallback(
    debounce(value => {
      onChange({
        panels: {
          config: {
            ...panelGlobalConfig,
            smoothingY: value
          }
        }
      });

      baseTrackEvent(dashboardEvents.PROJECT_PAGE_PANELS_SMOOTHING_UPDATED, {
        old_value: panelGlobalConfig.smoothingY,
        new_value: value
      });
    }, SMOOTHING_CHANGE_DELAY),
    [baseTrackEvent, panelGlobalConfig]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip content="Smoothing dashboard settings" placement="top">
        <IconButton
          onClick={handleClick}
          type="secondary"
          Icon={<DSSmoothingIcon />}
          disabled={disabled}
          active={Boolean(anchorEl)}
          data-test="dashboard-smoothing"
        />
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: -6,
          horizontal: 'right'
        }}
        classes={{ paper: styles.popoverContainer }}
      >
        <div className={typography.dsBody}>
          <div
            className={cx(
              typography.dsBody,
              typography.dsAccented,
              styles.label
            )}
          >
            Y-axis smoothing
          </div>
          <Slider
            onChange={setLocalSmoothing}
            onChangeCommitted={debouncedSmoothingChange}
            min={0}
            max={1}
            step={0.001}
            value={localSmoothing ?? panelGlobalConfig?.smoothingY}
            variant={undefined}
            disabled={undefined}
            marks={undefined}
            valueLabelFormat={undefined}
            sliderSuffixFormat={undefined}
            valueLabelDisplay={undefined}
            hideValueLabel={undefined}
          />
        </div>
        {showLockedPanelsWarning && (
          <div className={cx(styles.warning, typography.dsBody)}>
            Some panels use the panel level settings. You can unlock a panel to
            use the dashboard one.
          </div>
        )}
      </Popover>
    </>
  );
};

export default SmoothingButton;
