import { PARAM_ACTION_COLUMN } from '@experiment-details/components/params/helpers';

const KEY_TITLE = 'Name';
const VALUE_TITLE = 'Value';

export const OTHERS_COLUMNS_NAME = {
  KEY: 'name',
  VALUE: 'valueCurrent'
};

export const OTHERS_TABLE_COLUMNS = [
  { name: OTHERS_COLUMNS_NAME.KEY, title: KEY_TITLE },
  { name: OTHERS_COLUMNS_NAME.VALUE, title: VALUE_TITLE },
  { name: PARAM_ACTION_COLUMN, title: '  ' }
];
