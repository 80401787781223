import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import lastIndexOf from 'lodash/lastIndexOf';
import isString from 'lodash/isString';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import Popper from '@material-ui/core/Popper';
import {
  LEGEND_ICON_MAP,
  LINE_DASH_OPTIONS
} from '@experiment-management-shared/constants';

import {
  LineChartAggregationX,
  LineDash,
  PanelCometMetadata,
  PanelCometMetadataLegendKey,
  TooltipData
} from '@experiment-management-shared/types';
import { truncateMiddleOfStringByMaxCharacters } from '@shared/utils/displayHelpers';
import {
  LINE_CHART_X_AGGREGATIONS,
  TOOLTIP_MAX_EXPERIMENT_NAME_WIDTH
} from '@experiment-management-shared/constants/chartConstants';
import { getFormattedPlotlyData } from '@experiment-management-shared/components/Charts/ChartHelpers';

import styles from './LineChartTooltip.module.scss';

type LinesMetricsMap = {
  lines: number;
  groups: number;
};

const INFINITE_METRICS_INDEX = 14;

const LINES_METRICS_COUNT_MAP: {
  [key: number]: LinesMetricsMap;
} = {
  1: { lines: 25, groups: 1 },
  2: { lines: 12, groups: 2 },
  3: { lines: 6, groups: 3 },
  4: { lines: 5, groups: 4 },
  5: { lines: 4, groups: 5 },
  6: { lines: 3, groups: 6 },
  7: { lines: 2, groups: 7 },
  8: { lines: 1, groups: 8 },
  9: { lines: 1, groups: 9 },
  10: { lines: 1, groups: 10 },
  11: { lines: 1, groups: 11 },
  12: { lines: 1, groups: 12 },
  13: { lines: 1, groups: 13 },
  [INFINITE_METRICS_INDEX]: { lines: 1, groups: 1 }
};

type TooltipParsedLine = {
  tooltipGroupName: string;
  tooltipGroupKey: string;
  lineName: string;
  lineKey: string;
  legendKeys: PanelCometMetadataLegendKey[];
  paramGroupKeys: PanelCometMetadataLegendKey[];
  color: string;
  lineType: LineDash;
  x: Plotly.Datum[];
  y: Plotly.Datum[];
  upperBound?: Plotly.Datum[];
  lowerBound?: Plotly.Datum[];
};

type TooltipLine = TooltipParsedLine & {
  selectedLine: boolean;
  selectedGroup: boolean;
  rawValue: number;
  value: string | React.JSX.Element;
};

type TooltipLinesGroup = {
  key: string;
  title: string;
  value: string | number | React.JSX.Element;
  lines: TooltipLine[];
};

interface ExtendedPlotData extends Plotly.PlotData {
  cometMetadata: PanelCometMetadata;
}

interface IndexGetter {
  getter<T>(
    array: ArrayLike<T> | null | undefined,
    value: T,
    fromIndex?: number
  ): number;
}

const getTime = (value: string) => {
  return new Date(value).getTime();
};

const getTraceXValueIndexByOrder = (
  line: TooltipParsedLine,
  x: string | number,
  getter: IndexGetter['getter']
) => {
  let index = getter(line.x, x);

  // in case we cannot find the right index of element we can and x is string we can have assumption that this value is date
  // we converted all values in timestamps and trying to find index one more time
  if (index === -1 && isString(x) && !isNaN(getTime(x))) {
    index = getter((line.x as string[]).map(getTime), getTime(x));
  }

  return index;
};

const getTraceXValueIndex = (
  line: TooltipParsedLine,
  x: string | number,
  aggregation: LineChartAggregationX
) => {
  switch (aggregation) {
    case 'first':
      return getTraceXValueIndexByOrder(line, x, indexOf);
    case 'last':
      return getTraceXValueIndexByOrder(line, x, lastIndexOf);
    case 'all':
    case 'min':
    case 'max':
    // TODO we need to add handling for aggregation by value, fro now the handling will be default
    // eslint-disable-next-line no-fallthrough
    default:
      return getTraceXValueIndexByOrder(line, x, indexOf);
  }
};

const getYValueFromDatum = (datum: Plotly.Datum[], index: number) => {
  let value = null;
  if (index !== -1 && datum[index] !== undefined) {
    value = datum[index];
  }
  return value;
};

const getTraceYValue = (
  line: TooltipParsedLine,
  x: string | number,
  aggregation: LineChartAggregationX
) => {
  const index = getTraceXValueIndex(line, x, aggregation);
  const value = getYValueFromDatum(line.y, index);

  return {
    index,
    value
  };
};

const generateKeys = (cometMetadata: PanelCometMetadata) => {
  if (cometMetadata.groupKey) {
    return {
      tooltipGroupKey: cometMetadata.group as string,
      lineKey: cometMetadata.subGroup as string
    };
  }

  return {
    tooltipGroupKey: cometMetadata.metricName as string,
    lineKey: cometMetadata.experimentKey as string
  };
};

const getMetadata = (cometMetadata: PanelCometMetadata) => {
  if (cometMetadata.groupKey) {
    return {
      tooltipGroupName: `${cometMetadata.groupName} ${cometMetadata.metricName}`,
      lineName: '',
      legendKeys: [],
      paramGroupKeys: cometMetadata.paramGroupKeys || []
    };
  }

  return {
    tooltipGroupName: cometMetadata.metricName as string,
    lineName: truncateMiddleOfStringByMaxCharacters(
      cometMetadata.experimentName,
      TOOLTIP_MAX_EXPERIMENT_NAME_WIDTH
    ),
    legendKeys: cometMetadata.legendKeys || [],
    paramGroupKeys: []
  };
};

const calculateTargetKeys = (
  groupsOrder: string[] | null,
  parsedChartData: TooltipParsedLine[],
  experimentsCount: number
) => {
  const allTooltipGroups = uniq(parsedChartData.map(l => l.tooltipGroupKey));
  const boundaries =
    LINES_METRICS_COUNT_MAP[allTooltipGroups.length] ||
    LINES_METRICS_COUNT_MAP[INFINITE_METRICS_INDEX];

  return {
    groups: sortBy(allTooltipGroups, g => (groupsOrder || []).indexOf(g)),
    groupCount: boundaries.groups,
    linesCount: Math.min(experimentsCount, boundaries.lines)
  };
};

const traceToParsedLine = (trace: Partial<ExtendedPlotData>) => ({
  color: trace.line?.color as string,
  lineType: (trace.line?.dash || 'solid') as LineDash,
  ...generateKeys(trace.cometMetadata as PanelCometMetadata),
  ...getMetadata(trace.cometMetadata as PanelCometMetadata),
  x: trace.x as Plotly.Datum[],
  y: trace.y as Plotly.Datum[]
});

export type LineChartTooltipProps = {
  tooltipData: TooltipData;
  chartData: Partial<ExtendedPlotData>[];
  groupsOrder: string[] | null;
  aggregationX: LineChartAggregationX;
  experimentsCount?: number;
  highlightRelatedGroups?: boolean;
};

interface GroupsMap {
  // group name
  [key: string]: {
    // subgroup name
    [key: string]: Partial<ExtendedPlotData>[];
  };
}

export const LineChartTooltip = ({
  tooltipData,
  chartData,
  groupsOrder = [],
  aggregationX = LINE_CHART_X_AGGREGATIONS.ALL.value as LineChartAggregationX,
  experimentsCount = 25,
  highlightRelatedGroups = true
}: LineChartTooltipProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const setRef = useCallback(el => {
    setAnchorEl(el);
  }, []);

  const { x, color, point, position, normalizedPosition } = tooltipData;

  const parsedChartData = useMemo(() => {
    const filteredChartData = chartData.filter(
      trace =>
        trace?.visible !== 'legendonly' &&
        trace.hoverinfo !== 'skip' &&
        trace.cometMetadata
    );

    const hasGrouping = Boolean(filteredChartData[0]?.cometMetadata?.groupKey);

    if (hasGrouping) {
      const groups = groupBy(
        filteredChartData,
        trace => trace.cometMetadata?.group
      );

      const groupsMap: GroupsMap = Object.keys(groups).reduce<GroupsMap>(
        (acc, key) => {
          acc[key] = groupBy(
            groups[key],
            trace => trace.cometMetadata?.subGroup
          );
          return acc;
        },
        {}
      );

      const traces: TooltipParsedLine[] = [];

      Object.values(groupsMap).forEach(group => {
        Object.values(group).forEach(subGroup => {
          const tracesGroup = groupBy(
            subGroup,
            trace => trace.cometMetadata?.groupKey
          );

          const lineData = traceToParsedLine(
            tracesGroup['baseLine'][0] as Partial<ExtendedPlotData>
          );

          const lowerBoundTrace = tracesGroup['lowerBound']?.[0];
          const upperBoundTrace = tracesGroup['upperBound']?.[0];

          let tooltipGroupName = lineData.tooltipGroupName;

          if (lowerBoundTrace && upperBoundTrace) {
            const lowerGroupName =
              lowerBoundTrace.cometMetadata?.groupName || '';
            const upperGroupName =
              upperBoundTrace.cometMetadata?.groupName || '';
            tooltipGroupName = `${lineData.tooltipGroupName} (${lowerGroupName}, ${upperGroupName})`;
          }

          const trace = {
            ...lineData,
            lowerBound: (lowerBoundTrace?.y as Plotly.Datum[]) || undefined,
            upperBound: (upperBoundTrace?.y as Plotly.Datum[]) || undefined,
            tooltipGroupName
          };

          traces.push(trace);
        });
      });

      return traces;
    }

    return filteredChartData.map(
      trace => traceToParsedLine(trace) as TooltipParsedLine
    );
  }, [chartData]);

  const parsedTooltipData: TooltipLinesGroup[] = useMemo(() => {
    const targetKeys = calculateTargetKeys(
      groupsOrder,
      parsedChartData,
      experimentsCount
    );

    const {
      lineKey: pointLineKey,
      tooltipGroupKey: pointTooltipGroupKey
    } = generateKeys(point.data.cometMetadata as PanelCometMetadata);

    const tooltipGroups = targetKeys.groups
      .map(tooltipGroupKey => {
        const allGroupLines = parsedChartData.filter(
          parsedLine => parsedLine.tooltipGroupKey === tooltipGroupKey
        );
        const lines = allGroupLines
          .map(line => {
            const trace = find(allGroupLines, {
              lineKey: line.lineKey,
              tooltipGroupKey: tooltipGroupKey
            });

            if (trace) {
              const { index, value: rawValue } = getTraceYValue(
                line,
                x,
                aggregationX
              );

              if (rawValue === null) {
                return null;
              }

              const value = getFormattedPlotlyData(rawValue, point.yaxis);

              const rawSelectedLine = trace.lineKey === pointLineKey;
              const selectedGroup =
                trace.tooltipGroupKey === pointTooltipGroupKey;

              const selectedLine = highlightRelatedGroups
                ? rawSelectedLine
                : rawSelectedLine && selectedGroup;

              if (trace.upperBound && trace.lowerBound) {
                const upperValue = getFormattedPlotlyData(
                  getYValueFromDatum(trace.upperBound, index),
                  point.yaxis
                );
                const lowerValue = getFormattedPlotlyData(
                  getYValueFromDatum(trace.lowerBound, index),
                  point.yaxis
                );

                return {
                  ...trace,
                  selectedLine,
                  selectedGroup,
                  value: `${value} (${lowerValue}, ${upperValue})`,
                  rawValue,
                  lineName: ''
                };
              }

              return {
                ...trace,
                selectedLine,
                selectedGroup,
                rawValue,
                value
              };
            }

            return null;
          })
          .filter(l => l !== null) as TooltipLine[];

        if (lines.length === 0) {
          return null;
        }

        // sort lines and cut maximum amount
        const sortedLines = lines
          .sort((l1, l2) => l2.rawValue - l1.rawValue)
          .slice(0, targetKeys.linesCount);

        const pointGroupLine = find(lines, { lineKey: pointLineKey });
        const finalPointGroupLine = find(sortedLines, {
          lineKey: pointLineKey
        });

        // here we are ensuring that selected lineKey will be in each group after sorting and cutting
        if (pointGroupLine && !finalPointGroupLine) {
          sortedLines[sortedLines.length - 1] = pointGroupLine;
        }

        return {
          key: lines[0]?.tooltipGroupKey || '',
          title: lines[0]?.tooltipGroupName || '',
          value: getFormattedPlotlyData(x, point.xaxis),
          lines: sortedLines
        };
      })
      .filter(l => l !== null) as TooltipLinesGroup[];

    const sortedGroups = tooltipGroups.slice(0, targetKeys.groupCount);

    const pointGroup = find(tooltipGroups, { key: pointTooltipGroupKey });
    const finalPointGroup = find(sortedGroups, {
      key: pointTooltipGroupKey
    });

    // here we are ensuring that selected group will be shown even is out of boundaries
    if (pointGroup && !finalPointGroup) {
      sortedGroups[sortedGroups.length - 1] = pointGroup;
    }

    return sortedGroups;
  }, [
    groupsOrder,
    parsedChartData,
    experimentsCount,
    point.data.cometMetadata,
    point.xaxis,
    point.yaxis,
    x,
    aggregationX,
    highlightRelatedGroups
  ]);

  const relatedPoints = useMemo(() => {
    const { tooltipGroupKey, lineKey } = generateKeys(
      point.data.cometMetadata as PanelCometMetadata
    );

    // Skipping to draw additional points for stacked charts,
    // because there is no easy way to calculate points on chart
    if (point.data.stackgroup || !highlightRelatedGroups) {
      return [];
    }

    return parsedChartData
      .filter(
        l => l.lineKey === lineKey && l.tooltipGroupKey !== tooltipGroupKey
      )
      .map(line => {
        const { value } = getTraceYValue(line, x, aggregationX);

        if (value) {
          try {
            return {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              left: point.xaxis.d2p(x) + point.xaxis._offset,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              top: point.yaxis.d2p(value) + point.yaxis._offset,
              color: line.color
            };
          } catch (e) {
            return null;
          }
        }

        return null;
      })
      .filter(position => position !== null);
  }, [
    point.data.cometMetadata,
    point.data.stackgroup,
    point.xaxis,
    point.yaxis,
    highlightRelatedGroups,
    parsedChartData,
    x,
    aggregationX
  ]);

  const renderMainPoint = () => {
    let pointStyle: React.CSSProperties = { ...position, borderColor: color };

    if (normalizedPosition) {
      pointStyle = { ...normalizedPosition, borderColor: color, opacity: 0 };
    }

    return <div ref={setRef} className={styles.mainPoint} style={pointStyle} />;
  };

  const renderRelatedPoints = () => {
    if (!relatedPoints.length) return null;

    return relatedPoints.map(pointOptions => {
      const pointStyle: React.CSSProperties = {
        top: pointOptions?.top,
        left: pointOptions?.left,
        background: pointOptions?.color
      };

      return (
        // eslint-disable-next-line react/jsx-key
        <div className={styles.secondaryPoint} style={pointStyle} />
      );
    });
  };

  const renderTooltipGroups = (tooltipGroups: TooltipLinesGroup[]) => {
    const showLineType =
      tooltipGroups.length > 1 &&
      uniq(tooltipGroups.map(g => get(g, ['lines', 0, 'lineType'], 'solid')))
        .length > 1;

    return tooltipGroups.map(tooltipGroup => {
      const LegendIcon =
        LEGEND_ICON_MAP[
          tooltipGroup.lines?.[0]?.lineType || LINE_DASH_OPTIONS[0]
        ];

      return (
        <>
          <div
            className={styles.titleCol}
            data-test="chart-tooltip-group-title"
          >
            <div className={cx(styles.groupTitle, styles.textOverflow)}>
              {tooltipGroup.title}
            </div>
            <div className={styles.groupValue}>x:</div>
            <div className={styles.groupValue}>{tooltipGroup.value}</div>
            {showLineType && (
              <div className={styles.groupIcon}>
                <LegendIcon />
              </div>
            )}
          </div>
          {renderLines(tooltipGroup.lines)}
        </>
      );
    });
  };

  const renderLines = (lines: TooltipLine[]) => {
    return lines.map(line => {
      return (
        <>
          <div
            style={{ '--line-color': line.color } as React.CSSProperties}
            data-test="chart-tooltip-line-title"
            className={cx(styles.col, styles.col1, {
              [styles.selectedLine]: line.selectedLine,
              [styles.selectedGroup]: line.selectedGroup
            })}
          >
            <div className={styles.prefix}>
              <div className={styles.color} />
            </div>
            <div className={cx(styles.lineValue, styles.textOverflow)}>
              {line.value}
            </div>
          </div>
          <div
            className={cx(styles.col, styles.col2, {
              [styles.selectedLine]: line.selectedLine
            })}
          >
            <div className={styles.lineName}>{line.lineName}</div>
            {line.legendKeys.map(legendKey => (
              <div key={legendKey.title} className={styles.textOverflow}>
                {getFormattedPlotlyData(legendKey.value, null)}
              </div>
            ))}
            {line.paramGroupKeys.map(paramGroupKey => (
              <div key={paramGroupKey.title} className={styles.textOverflow}>
                {paramGroupKey.title}: {paramGroupKey.value}
              </div>
            ))}
          </div>
        </>
      );
    });
  };

  return (
    <>
      <div className={styles.pointsContainer}>
        {renderMainPoint()}
        {renderRelatedPoints()}
      </div>
      <Popper
        className={styles.popper}
        anchorEl={anchorEl}
        placement="right"
        open={Boolean(anchorEl)}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport'
          },
          offset: {
            enabled: true,
            offset: '0, 8px'
          },
          flip: {
            enabled: true,
            boundariesElement: 'viewport',
            flipVariationsByContent: true
          }
        }}
      >
        <div className={styles.container}>
          {renderTooltipGroups(parsedTooltipData)}
        </div>
      </Popper>
    </>
  );
};

export default LineChartTooltip;
