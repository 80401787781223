import AddCircleIcon from '@material-ui/icons/AddCircle';
import PanelsHeader from '@reports/components/SectionPanelsHeader';
import cx from 'classnames';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { withRouter } from 'react-router';
import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import {
  CUSTOM_CHART_TYPE,
  defaultFormFields
} from '@experiment-management-shared/constants/chartConstants';
import { generatePanelId } from '@experiment-management-shared/utils/panel';
import {
  calculateLayoutForAddingPanel,
  calculateLayoutForRemovingPanel
} from '@experiment-management-shared/utils/layout';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import BatchChartContainer from '@experiment-management-shared/components/Charts/PlotlyChart/BatchChartContainer';
import { useComputeClearCacheMutation } from '@experiment-management-shared/api';

import {
  CHART_GALLERY_TYPES,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import visualizationsActions from '@/actions/visualizationsActions';

import styles from './SectionPanels.module.scss';

const EDITABLE_HIDE_MENU_ITEMS = ['embed-panel', 'share-panel'];
const READ_ONLY_HIDE_MENU_ITEMS = [
  'delete-chart',
  'edit-chart',
  'embed-panel',
  'share-panel'
];

const SectionPanels = ({
  editable,
  experiments,
  hiddenExperimentKeys,
  history,
  location,
  onChange,
  onChangeHiddenExperimentKeys,
  panels: sectionConfig,
  projectId,
  projectName
}) => {
  const baseTrackEvent = useBaseTrackEvent();
  const queryClient = useQueryClient();
  const experimentKeys = useMemo(
    () => experiments.map(({ experimentKey }) => experimentKey),
    [experiments]
  );

  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isAutoRefreshEnabled, isVisible, layout, panels } = sectionConfig;

  const prevLocationHash = useRef(location.hash);
  const computeClearCacheMutation = useComputeClearCacheMutation(projectId);

  useEffect(() => {
    if (!isModalOpen) return;

    // handle cancel modal
    if (prevLocationHash.current && !location.hash) {
      setIsModalOpen(false);
    }
  }, [isModalOpen, location.hash]);

  const configs = useMemo(() => {
    const handleClearChartConstraints = () => {
      onChangeHiddenExperimentKeys([]);
    };

    const handleDeletePanel = ({ chartId }) => {
      const panelsUpdate = panels.filter(panel => panel.chartId !== chartId);

      const panelsData = calculateLayoutForRemovingPanel(
        panelsUpdate,
        layout,
        panels
      );

      onChange({
        ...sectionConfig,
        ...panelsData
      });
    };

    const handleEditChart = config => {
      if (config.chartType === CUSTOM_CHART_TYPE) {
        return handleEditCustomChart(config);
      }

      return handleEditBuiltInChart(config);
    };

    const handleTransformYChange = ({ chartId, transformY }) => {
      let hasChanges = false;

      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId && panel.transformY !== transformY) {
          hasChanges = true;

          return {
            ...panel,
            transformY: transformY
          };
        }

        return panel;
      });

      if (hasChanges) {
        onChange({
          ...sectionConfig,
          panels: updatedPanels
        });
      }
    };

    const handleLegendModeChange = ({ chartId, legendMode }) => {
      let eventPayload = {};
      let hasChanges = false;
      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId && panel.legendMode !== legendMode) {
          hasChanges = true;

          eventPayload = {
            legend_display: legendMode,
            panel_type: panel.chartType,
            grouping: panel?.grouping?.enabled ? panel.grouping : null
          };

          return {
            ...panel,
            legendMode
          };
        }

        return panel;
      });

      if (hasChanges) {
        onChange({
          ...sectionConfig,
          panels: updatedPanels
        });

        baseTrackEvent('PANEL_LEGEND_DISPLAY', eventPayload);
      }
    };

    const handleEditBuiltInChart = config => {
      const { chartId, chartType } = config;

      const chartCategory = 'built-in';
      const hash = `#${SELECT_PANEL_PATH}?chartCategory=${chartCategory}&chartType=${chartType}&chartId=${chartId}`;
      const formKeys = Object.keys(defaultFormFields[chartType]);
      const chartForm = pick(config, formKeys);

      dispatch(dashboardChartsActions.resetChartForm());
      dispatch(dashboardChartsActions.updateChartForm(chartType, chartForm));

      history.push({ ...location, hash });

      setIsModalOpen(true);
    };

    const handleEditCustomChart = ({
      chartId,
      scopeType,
      templateId,
      instanceId
    }) => {
      const { FEATURED, CUSTOM } = CHART_GALLERY_TYPES;
      const { INTERNAL } = TEMPLATE_SCOPE_TYPES;
      const chartCategory = scopeType === INTERNAL ? FEATURED : CUSTOM;
      const hash = `#${SELECT_PANEL_PATH}?chartCategory=${chartCategory}&chartType=${templateId}&chartId=${chartId}&instanceId=${instanceId}`;

      history.push({ ...location, hash });

      setIsModalOpen(true);
    };

    const handleMarkExperimentsVisible = (
      chartId,
      highlightedExperimentKeys
    ) => {
      const newHiddenExperimentKeys = experimentKeys.filter(
        experimentKey => !highlightedExperimentKeys.includes(experimentKey)
      );

      onChangeHiddenExperimentKeys(newHiddenExperimentKeys);
    };

    return panels.map(baseConfig => {
      return {
        metricNames: [baseConfig.metricName],
        ...baseConfig,
        deleteConfig: baseConfig,
        editConfig: baseConfig,
        onClearChartConstraints: handleClearChartConstraints,
        onDeleteChart: handleDeletePanel,
        onEditChart: handleEditChart,
        onMarkExperimentsVisible: handleMarkExperimentsVisible,
        onTransformYChange: handleTransformYChange,
        onChangeLegendMode: handleLegendModeChange,
        projectId
      };
    });
  }, [
    panels,
    onChangeHiddenExperimentKeys,
    layout,
    onChange,
    sectionConfig,
    baseTrackEvent,
    dispatch,
    history,
    location,
    experimentKeys,
    projectId
  ]);

  const panelSharedConfig = useMemo(
    () => ({
      isAutoRefreshEnabled,
      activeIntervalFetchDelay: 10000,
      showLockCharts: [],
      hiddenMenuItems: editable
        ? EDITABLE_HIDE_MENU_ITEMS
        : READ_ONLY_HIDE_MENU_ITEMS,
      fetchFull: false,
      hiddenExperimentKeys,
      experiments,
      experimentKeys
    }),
    [
      editable,
      experimentKeys,
      experiments,
      hiddenExperimentKeys,
      isAutoRefreshEnabled
    ]
  );

  const handleAddPanelButtonClick = () => {
    setIsModalOpen(true);
    history.replace({ ...location, hash: SELECT_PANEL_PATH });
    dispatch(dashboardChartsActions.resetChartForm());
  };

  const handleAddPanel = useCallback(
    panels => {
      const panelsData = calculateLayoutForAddingPanel(panels, layout);

      onChange({
        ...sectionConfig,
        ...panelsData
      });
    },
    [layout, onChange, sectionConfig]
  );

  const handleAddBuiltInPanel = (panel, chartType) => {
    const chartId = generatePanelId();

    handleAddPanel([...panels, { ...panel, chartId, chartType }]);

    setIsModalOpen(false);
  };

  const handleAddCustomPanel = async instance => {
    const chartId = generatePanelId();
    const chartType = CUSTOM_CHART_TYPE;
    const { instanceId } = await dispatch(
      visualizationsActions.createInstance(instance)
    );

    handleAddPanel([...panels, { chartId, chartType, instanceId }]);

    setIsModalOpen(false);
  };

  const handleAutoRefreshChange = isAutoRefreshEnabled => {
    onChange({
      ...sectionConfig,
      isAutoRefreshEnabled
    });
  };

  const handleEditBuiltInPanel = (chartId, panelForm, chartType) => {
    const editedPanel = panelForm[chartType];
    const panelsUpdate = panels.map(panel => {
      if (panel.chartId === chartId) {
        return { ...editedPanel, chartId, chartType };
      }

      return panel;
    });

    onChange({
      ...sectionConfig,
      panels: panelsUpdate
    });

    setIsModalOpen(false);
  };

  const handlePanelChange = useCallback(
    (chartId, changes) => {
      const newPanels = panels.map(panel => {
        if (panel.chartId !== chartId) return panel;

        return {
          ...panel,
          ...changes
        };
      });

      onChange({
        ...sectionConfig,
        panels: newPanels
      });
    },
    [onChange, panels, sectionConfig]
  );

  const handleEditCustomPanel = async (chartId, instance) => {
    const chartType = CUSTOM_CHART_TYPE;
    const { instanceId } = await dispatch(
      visualizationsActions.createInstance(instance)
    );
    const panelsUpdate = panels.map(panel => {
      if (panel.chartId === chartId) {
        return { chartId, chartType, instanceId };
      }

      return panel;
    });

    onChange({
      ...sectionConfig,
      panels: panelsUpdate
    });

    setIsModalOpen(false);
  };

  const handleLayoutChange = newLayout => {
    onChange({
      ...sectionConfig,
      layout: newLayout,
      layoutV2: newLayout
    });
  };

  const handleRefreshSection = async () => {
    await queryClient.invalidateQueries(['experimentGroups', { projectId }]);
    await queryClient.invalidateQueries(['experiments', { projectId }]);
    await queryClient.invalidateQueries(['panel', { projectId }]);
    await queryClient.invalidateQueries([
      'custom-panel-server-url',
      { projectName }
    ]);

    computeClearCacheMutation.mutate();
  };

  const renderBody = () => {
    if (!configs.length) {
      return (
        <div className="panels-tab-empty">
          <div className="panels-tab-empty-background">
            <div className="panels-tab-empty-button-container">
              <div
                className="panels-tab-empty-button-container-inner"
                onClick={handleAddPanelButtonClick}
              >
                <div className="panels-tab-empty-button">
                  <AddCircleIcon className="panels-tab-empty-button-icon" />
                  <span className="panels-tab-empty-button-label">
                    Add your first panel
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={cx('charts-container', styles.reportChartsContainer)}>
        <div className="chart-grid grid">
          <BatchChartContainer
            configs={configs}
            layout={layout}
            onLayoutChange={handleLayoutChange}
            onPanelChange={handlePanelChange}
            panelSharedConfig={panelSharedConfig}
            shouldSkipLayoutInitialization
          />
        </div>
      </div>
    );
  };

  return (
    <div className="panels-tab">
      <div className={cx({ [styles.hidden]: !isVisible })}>
        {editable && (
          <PanelsHeader
            experiments={experiments}
            experimentKeys={experimentKeys}
            hiddenExperimentKeys={hiddenExperimentKeys}
            isAutoRefreshEnabled={isAutoRefreshEnabled}
            isModalOpen={isModalOpen}
            handleAddPanelButtonClick={handleAddPanelButtonClick}
            onAddBuiltInPanel={handleAddBuiltInPanel}
            onAddCustomPanel={handleAddCustomPanel}
            onAutoRefreshChange={handleAutoRefreshChange}
            onEditBuiltInPanel={handleEditBuiltInPanel}
            onEditCustomPanel={handleEditCustomPanel}
            onRefreshPanelsClick={handleRefreshSection}
          />
        )}

        {renderBody()}
      </div>
    </div>
  );
};

SectionPanels.defaultProps = {
  editable: true
};

SectionPanels.propTypes = {
  editable: PropTypes.bool,
  experiments: PropTypes.array.isRequired,
  hiddenExperimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeHiddenExperimentKeys: PropTypes.func.isRequired,
  panels: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired
};

export default withRouter(SectionPanels);
