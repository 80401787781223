import React, { useState } from 'react';
import { Slider } from '@design-system-outdated';
import { COMPARISON_TABLE_DISPLAY_CONTENT } from './comparisonTableConfig';
import { DetailViewValue } from './types';
import ExperimentBar from '@experiment-details/components/ExperimentBar';
import { debounce } from 'lodash';

type ComparisonTableHeaderProps = {
  searchHandler: (text: string) => void;
  searchValue: string;
  decimalsPrecision: number;
  detailView: DetailViewValue;
  maxDecimalsInItems: number;
  onChangeDecimalsPrecision: (v: number) => void;
  onChangeRemoveDuplicates: (v: boolean) => void;
};
const ComparisonTableHeader: React.FC<ComparisonTableHeaderProps> = ({
  searchHandler,
  searchValue,
  decimalsPrecision,
  detailView,
  maxDecimalsInItems,
  onChangeDecimalsPrecision,
  onChangeRemoveDuplicates
}) => {
  const { name } = COMPARISON_TABLE_DISPLAY_CONTENT[detailView];

  const [
    tempDecimalsPrecision,
    onChangeTempDecimalsPrecision
  ] = useState<number>();
  const debouncedOnChangeDecimalsPrecision = debounce(
    onChangeDecimalsPrecision,
    300
  );

  return (
    <ExperimentBar>
      <ExperimentBar.Search
        searchText={searchValue}
        setSearchText={searchHandler}
        placeholder="Name (regex)"
      />
      <ExperimentBar.Custom
        label="Decimal precision"
        component={
          <Slider
            min={0}
            max={maxDecimalsInItems}
            step={1}
            value={tempDecimalsPrecision ?? decimalsPrecision}
            onChange={onChangeTempDecimalsPrecision}
            onChangeCommitted={debouncedOnChangeDecimalsPrecision}
          />
        }
      />
      <ExperimentBar.Toggle
        label="Hide identical parameters"
        name={name}
        onChange={onChangeRemoveDuplicates}
      />
    </ExperimentBar>
  );
};

export default ComparisonTableHeader;
