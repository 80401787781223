// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoBlock-module__dsInfoBlockText--ef85O{color:var(--gray-6);font-size:14px}.InfoBlock-module__dsInfoBlockIcon--m6oSw{margin-top:2px;display:inline-block}.InfoBlock-module__dsInfoBlockIcon--m6oSw svg{fill:var(--gray-6)}.InfoBlock-module__dsInfoBlockIcon--m6oSw svg path{fill:var(--gray-6)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsInfoBlockText": `InfoBlock-module__dsInfoBlockText--ef85O`,
	"dsInfoBlockIcon": `InfoBlock-module__dsInfoBlockIcon--m6oSw`
};
module.exports = ___CSS_LOADER_EXPORT___;
