import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { CHART_TYPES, LINE_VARIANTS } from '../../constants';
import { MPMConfusionMatrix } from '../MPMConfusionMatrix';
import { BarChartContainer } from '../BarChartContainer';
import { LineChartContainer } from '../LineChartContainer';
import './PanelContainer.scss';

const PanelContent = ({
  data,
  chartType,
  onChangePercentileMode,
  percentileMode,
  disableZoom,
  section,
  ...props
}) => {
  if (
    chartType === CHART_TYPES.BAR ||
    chartType === CHART_TYPES.FAIRNESS_GROUPED
  ) {
    return (
      <BarChartContainer
        data={data}
        disableZoom={disableZoom}
        section={section}
        chartType={chartType}
        {...props}
      />
    );
  }
  if (chartType === CHART_TYPES.CONFUSION_MATRIX) {
    return <MPMConfusionMatrix {...data.panelData} {...props} />;
  }

  const lineChartVariant = LINE_VARIANTS[chartType];

  return (
    <LineChartContainer
      variant={lineChartVariant}
      chartType={chartType}
      data={data}
      percentileMode={percentileMode}
      onChangePercentileMode={onChangePercentileMode}
      disableZoom={disableZoom}
      section={section}
      {...props}
    />
  );
};

PanelContent.propTypes = {
  chartType: PropTypes.string,
  layoutData: PropTypes.object,
  percentileMode: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChangePercentileMode: PropTypes.func,
  disableZoom: PropTypes.bool,
  section: PropTypes.string
};

PanelContent.defaultProps = {
  chartType: '',
  layoutData: {},
  percentileMode: '',
  data: [],
  onChangePercentileMode: noop,
  disableZoom: false,
  section: ''
};

export default PanelContent;
