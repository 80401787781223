import { MODEL_VERSION_STATUSES } from '@shared/constants/model-registry-request-status';

export const formatModelVersionStatusToText = (
  status,
  { extendedText = false } = {}
) => {
  if (!status && !extendedText) return MODEL_VERSION_STATUSES.NA;
  if (!status && extendedText) return 'Select a status';

  return status;
};
