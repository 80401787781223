import { createSelector } from 'reselect';
import { find, get } from 'lodash';

import {
  WORKSPACE_PAGE_TABS,
  DEFAULT_SORT_STATE,
  TAB_STATE_KEYS
} from '../../constants/workspaceConstants';
import { workspaceActionTypes } from '../../constants/actionTypes';

const {
  PROJECTS,
  ARTIFACTS,
  MODEL_REGISTRY,
  MODEL_PRODUCTION_MONITORING
} = WORKSPACE_PAGE_TABS;
const {
  FILTERABLE_VALUES,
  FILTERED_ROW_COUNT,
  ITEM_COUNT,
  IS_CARD_DISPLAY,
  SORT_PROPERTY,
  SORT_DIRECTION,
  SELECTED_FILTERS,
  PAGE_SIZE,
  PAGE_NUMBER,
  VISIBLE_ITEMS
} = TAB_STATE_KEYS;

const initialState = {
  [PROJECTS]: {
    [FILTERABLE_VALUES]: {},
    [FILTERED_ROW_COUNT]: 0,
    [ITEM_COUNT]: 0,
    [IS_CARD_DISPLAY]: false,
    [SORT_PROPERTY]: DEFAULT_SORT_STATE.PROJECTS.PROPERTY,
    [SORT_DIRECTION]: DEFAULT_SORT_STATE.PROJECTS.DIRECTION,
    [SELECTED_FILTERS]: {},
    [PAGE_SIZE]: 25,
    [PAGE_NUMBER]: 0,
    [VISIBLE_ITEMS]: []
  },
  [ARTIFACTS]: {
    [ITEM_COUNT]: 0,
    [SORT_PROPERTY]: DEFAULT_SORT_STATE.ARTIFACTS.PROPERTY,
    [SORT_DIRECTION]: DEFAULT_SORT_STATE.ARTIFACTS.DIRECTION,
    [SELECTED_FILTERS]: {}
  },
  [MODEL_REGISTRY]: {
    [ITEM_COUNT]: 0,
    [IS_CARD_DISPLAY]: false,
    [SORT_PROPERTY]: DEFAULT_SORT_STATE.MODEL_REGISTRY.PROPERTY,
    [SORT_DIRECTION]: DEFAULT_SORT_STATE.MODEL_REGISTRY.DIRECTION,
    [SELECTED_FILTERS]: {},
    [PAGE_SIZE]: 25,
    [PAGE_NUMBER]: 0
  },
  [MODEL_PRODUCTION_MONITORING]: {
    [ITEM_COUNT]: 0
  },
  activeWorkspaceName: '',
  allWorkspaces: [],
  manageUsersModal: {
    open: false,
    workspaceName: '',
    workspaceId: ''
  },
  breadcrumbsItems: []
};

const workspaceUiReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === workspaceActionTypes.SET_TAB_STATE_BY_KEY) {
    const { tabKey, stateKey, update } = payload;

    return {
      ...state,
      [tabKey]: {
        ...state[tabKey],
        [stateKey]: update
      }
    };
  }

  if (type === workspaceActionTypes.SET_TAB_STATE) {
    const { tabKey, update } = payload;

    return {
      ...state,
      [tabKey]: {
        ...state[tabKey],
        ...update
      }
    };
  }

  if (type === workspaceActionTypes.SET_ITEM_COUNT_AND_DISPLAY) {
    const { tabKey, itemCount, isCardDisplay } = payload;

    return {
      ...state,
      [tabKey]: {
        ...state[tabKey],
        [ITEM_COUNT]: itemCount,
        [IS_CARD_DISPLAY]: isCardDisplay
      }
    };
  }

  if (type === workspaceActionTypes.SET_ACTIVE_WORKSPACE_NAME) {
    const { workspaceName } = payload;

    return { ...state, activeWorkspaceName: workspaceName };
  }

  if (type === workspaceActionTypes.SET_MANAGE_WORKSPACE_MODAL_STATE) {
    const { open, workspaceName, workspaceId } = payload;

    return {
      ...state,
      manageUsersModal: {
        open,
        workspaceName,
        workspaceId
      }
    };
  }

  if (type === workspaceActionTypes.SET_ALL_USER_WORKSPACE_LIST) {
    const { workspaces } = payload;

    return { ...state, allWorkspaces: workspaces };
  }

  if (type === workspaceActionTypes.SET_BREADCRUMBS_ITEMS) {
    const { items } = payload;

    return {
      ...state,
      breadcrumbsItems: items
    };
  }

  return state;
};

const getWorkspaceUiState = state => get(state, ['ui', 'workspace'], {});
const getTabKey = (_, props) => props.tabKey;

export const getTabState = createSelector(
  [getWorkspaceUiState, getTabKey],
  (state, tabKey) => {
    return state[tabKey];
  }
);

export const getItemCountForTab = createSelector(
  [getWorkspaceUiState, getTabKey],
  (workspaceUiState, tabKey) => {
    return get(workspaceUiState, [tabKey, ITEM_COUNT], 0);
  }
);

export const getItemCountsForAllTabs = createSelector(
  [getWorkspaceUiState],
  workspaceUiState => {
    return Object.keys(workspaceUiState).reduce((map, tabKey) => {
      map[tabKey] =
        workspaceUiState[tabKey] && workspaceUiState[tabKey][ITEM_COUNT];
      return map;
    }, {});
  }
);

export const getManageUsersModalState = createSelector(
  [getWorkspaceUiState],
  workspaceUiState => {
    return get(workspaceUiState, 'manageUsersModal', {
      open: false,
      workspaceName: '',
      workspaceId: ''
    });
  }
);

export const getActiveWorkspaceName = createSelector(
  [getWorkspaceUiState],
  workspaceUiState => {
    return get(workspaceUiState, 'activeWorkspaceName', '');
  }
);

export const getAllUserWorkspaces = createSelector(
  [getWorkspaceUiState],
  workspaceUiState => {
    return get(workspaceUiState, 'allWorkspaces', []);
  }
);

const getWorkspaceName = (_, props) => props?.workspaceName;

export const getIsUserAMemberOfWorkspace = createSelector(
  [getAllUserWorkspaces, getWorkspaceName],
  (workspaces, workspaceName) => {
    return workspaces.some(workspace => workspace.teamName === workspaceName);
  }
);

export const getActiveWorkspace = createSelector(
  [getAllUserWorkspaces, getActiveWorkspaceName],
  (workspaces, workspaceName) => {
    return find(workspaces, { name: workspaceName }) || [];
  }
);

export const selectDefaultWorkspaceInOrganization = workspaces =>
  workspaces?.find(workspace => workspace.isDefault) || workspaces[0];

export const selectDefaultWorkspaceByOrganization = (
  allWorkspaces,
  organizationId
) =>
  allWorkspaces?.find(
    workspace =>
      workspace.isDefault && workspace.organizationId === organizationId
  ) ||
  allWorkspaces?.find(workspace => workspace.organizationId === organizationId);

export const getIsCollaborationEnabled = createSelector(
  [getActiveWorkspace],
  workspace => !get(workspace, 'collaborationFeaturesDisabled', false)
);

export const getTeamIdForActiveWorkspace = createSelector(
  [getActiveWorkspace],
  workspace => get(workspace, 'id', '')
);

export const getBreadcrumbsItems = createSelector(
  [getWorkspaceUiState],
  state => state.breadcrumbsItems
);

export default workspaceUiReducer;
