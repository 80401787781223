import React from 'react';
import { GET_STARTED_ROUTE, FREE_PLANS } from '@shared/constants';

import { trackEvent } from '@shared/utils/eventTrack';
import queryString from 'query-string';
import { getPasswordValidationErrorMsg } from '@auth/utils';
import userApi from '../util/userApi';
import { userActionTypes } from '@/constants/actionTypes';
import alertsUtil from '../util/alertsUtil';
import betaFeaturesActions from './betaFeaturesActions';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import { IS_ON_PREMISE } from '@/constants/configConstants';
import alertsActions from './alertsActions';
import LicenseTokenExpiredModal from '@platform/components/LicenseTokenExpiredModal';
import { getWorkspaceName } from '@shared/utils/url';

const userActions = {
  createUser(
    data,
    { workspaceCode = null, workspaceName = null, canClaimAnonData = false }
  ) {
    return (dispatch, getState) => {
      const state = getState();
      const { router } = state;
      const queryParams = queryString.parse(router.location.search);
      const { payNow, plan, postSignup } = queryParams;
      const payPath = payNow ? '&payNow=1' : '';
      const postSignupPath = postSignup ? '&postSignup=1' : '';
      const returnPath = plan ? `?plan=${plan}${payPath}${postSignupPath}` : '';

      return userApi
        .createUser(data, workspaceCode)
        .then(response => {
          const { claimResult } = response.data;

          trackEvent('signup', { method: 'Form' });

          if (canClaimAnonData && workspaceName) {
            window.location.href = `/claim-anonymous?workspaceName=${workspaceName}&isNewUser=true&redirectTo=${window.location.pathname}`;
            return;
          } else if (claimResult) {
            const { claimMsg, claimSuccess } = claimResult;
            const claimQueryString = queryString.stringify({
              claimResult: claimMsg,
              claimStatus: claimSuccess
            });
            window.location.href = `/${GET_STARTED_ROUTE}?${claimQueryString}`;
          } else if (plan === 'academic') {
            // Hard redirect in order to refetch the user
            // @todo: dispatch fetchUser, avoid a hard redirect and fix the issues related
            window.location.href = '/academics';
          } else if (IS_ON_PREMISE || FREE_PLANS.includes(plan)) {
            // Hard redirect in order to refetch the user
            // @todo: dispatch fetchUser, avoid a hard redirect and fix the issues related
            window.location.href = `/${GET_STARTED_ROUTE}`;
          } else if (returnPath) {
            // Hard redirect in order to refetch the user
            // @todo: dispatch fetchUser, avoid a hard redirect and fix the issues related

            window.location.href = `/${GET_STARTED_ROUTE}${returnPath}`;
          } else {
            window.location.href = `/${GET_STARTED_ROUTE}`;
          }
        })
        .catch(error => {
          let failureMsg = 'Failed to create an account';

          if (error?.response?.data?.msg) {
            failureMsg = getPasswordValidationErrorMsg(
              error?.response?.data?.msg
            );
          }

          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_KEY_GEN,
              failureMsg
            )
          );
        });
    };
  },

  requestUser() {
    return {
      type: userActionTypes.REQUEST_USER
    };
  },

  receiveUser(user) {
    return {
      type: userActionTypes.RECEIVE_USER,
      user
    };
  },

  setUserProfileImages(profileImages) {
    return {
      type: userActionTypes.SET_USER_PROFILE_IMAGES,
      profileImages
    };
  },

  setIsFirstSession(value) {
    return {
      type: userActionTypes.SET_IS_FIRST_SESSION,
      payload: {
        value
      }
    };
  },

  fetchUser() {
    return dispatch => {
      dispatch(userActions.requestUser());

      const workspaceName = getWorkspaceName();
      const { workspaceName: workspaceNameInQuery = '' } = queryString.parse(
        window.location.search
      );

      return userApi
        .fetchUser({
          workspaceName: workspaceName || workspaceNameInQuery
        })
        .then(response => {
          const userResponse = response.data;
          const isUserLoggedIn = response.data.loggedIn;
          const isReadOnly = response.data.readOnly;
          let username;
          if (isUserLoggedIn) {
            username = response.data.userName;
          }

          const user = {
            ...userResponse,
            isUserLoggedIn,
            username,
            apiKey: isUserLoggedIn ? response.data.apiKeys[0] : '',
            isUserLoggedInWithGitHub: response.data.gitHub,
            loading: false,
            isReadOnly,
            paymentPlan: response.data.paymentPlan || {},
            defaultWorkspaceName: response.data.defaultWorkspace,
            showPostSignupForm: response.data.showPostSignupForm
          };
          const { featureToggles } = userResponse;
          dispatch(betaFeaturesActions.setFeatures(featureToggles));
          dispatch(userActions.receiveUser(user));
        })
        .catch(error => {
          if (IS_ON_PREMISE) {
            if (error?.response?.status === 401) {
              dispatch(
                alertsActions.openDialog(
                  dialogTypes.ERROR_LICENSE_VERIFICATION_MODAL,
                  {
                    children: (
                      <LicenseTokenExpiredModal
                        content={error?.response?.data?.msg}
                      />
                    )
                  }
                )
              );
            } else if (error?.response?.status === 423) {
              dispatch(
                alertsUtil.openErrorDialog(
                  dialogTypes.CATCH_ERROR_API,
                  error?.response?.data?.msg
                )
              );
            }
          } else {
            const isUserLoggedIn = false;
            const isReadOnly = false;

            const user = {
              isUserLoggedIn,
              username: null,
              apiKey: isUserLoggedIn ? false : '',
              isUserLoggedInWithGitHub: false,
              loading: false,
              isReadOnly,
              defaultWorkspaceName: null,
              showPostSignupForm: false
            };
            dispatch(userActions.receiveUser(user));
          }
        });
    };
  }
};

export default userActions;
