import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButton from '@DesignSystem/controllers/RadioButton/RadioButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import { MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE } from '@shared/constants/model-streamline-registration';
import classNames from './RegisterModel.module.scss';

const RegisterModelRadioButtons = ({ onChange, value }) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={classNames.registerModelRadioButtonsGroup}
    >
      <Grid
        container
        justifyContent="center"
        className={classNames.registerModelRadioButtons}
      >
        <Grid item xs={12}>
          <FormControlLabel
            data-test="streamline-model-sidebar-register-as-a-new-version"
            value={MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION}
            control={
              <RadioButton className={classNames.registerModelRadioButton} />
            }
            label="As a new version of an existing model"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value={MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL}
            data-test="streamline-model-sidebar-register-as-a-new-model"
            control={
              <RadioButton className={classNames.registerModelRadioButton} />
            }
            label="As a new registered model"
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

RegisterModelRadioButtons.defaultProps = {};

RegisterModelRadioButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION,
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL
  ]).isRequired
};

export default RegisterModelRadioButtons;
