import isEqual from 'fast-deep-equal';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';

import { KIND_TYPE } from '@API/helpers/v2_helpers';
import { SOURCE } from '@shared/utils/filterHelpers';

export const DEFAULT_LLM_COLUMNS = {
  PROMPT: 'prompt',
  OUTPUT: 'chain_outputs.output',
  PROMPT_TEMPLATE: 'prompt_template',
  USER_FEEDBACK: 'user_feedback',
  USER_NAME: 'user_name'
};

export const GRID_COLUMNS = {
  NAME: 'Name',
  TAGS: 'experimentTags',
  TIMESTAMP: 'end_server_timestamp',
  CHAIN_DURATION: 'chain_duration',
  ...DEFAULT_LLM_COLUMNS
};

export const LLM_NAME_MAP = {
  [GRID_COLUMNS.TAGS]: 'tags',
  [GRID_COLUMNS.TIMESTAMP]: 'timestamp',
  [GRID_COLUMNS.CHAIN_DURATION]: 'duration',
  [DEFAULT_LLM_COLUMNS.OUTPUT]: 'output.output'
};

export const LLM_MANUALLY_ADDED_COLUMNS = [
  {
    id: `${GRID_COLUMNS.NAME}--log_other`,
    name: GRID_COLUMNS.NAME,
    type: 'string',
    source: 'log_other',
    kinds: [KIND_TYPE.LAST]
  },
  {
    id: `${GRID_COLUMNS.TAGS}--metadata`,
    name: GRID_COLUMNS.TAGS,
    type: 'string',
    source: 'tags',
    kinds: [KIND_TYPE.LAST]
  },
  {
    id: `${GRID_COLUMNS.TIMESTAMP}--end_time`,
    name: GRID_COLUMNS.TIMESTAMP,
    type: 'datetime',
    source: 'end_time',
    kinds: [KIND_TYPE.LAST]
  },
  {
    id: `${GRID_COLUMNS.CHAIN_DURATION}--metrics`,
    name: GRID_COLUMNS.CHAIN_DURATION,
    type: 'double',
    source: 'metrics',
    kinds: [KIND_TYPE.LAST, KIND_TYPE.MAX, KIND_TYPE.MIN]
  },
  {
    id: `${DEFAULT_LLM_COLUMNS.USER_FEEDBACK}-metrics`,
    name: DEFAULT_LLM_COLUMNS.USER_FEEDBACK,
    type: 'double',
    source: 'metrics',
    kinds: [KIND_TYPE.LAST, KIND_TYPE.MAX, KIND_TYPE.MIN]
  },
  {
    id: `${DEFAULT_LLM_COLUMNS.USER_NAME}-metadata`,
    name: DEFAULT_LLM_COLUMNS.USER_NAME,
    type: 'string',
    source: 'metadata',
    kinds: [KIND_TYPE.LAST]
  }
];

export const METADATA_FETCH_ONLY_COLUMNS = [
  {
    source: 'llm_data',
    keyName: GRID_COLUMNS.PROMPT
  },
  {
    source: 'llm_data',
    keyName: GRID_COLUMNS.PROMPT_TEMPLATE
  },
  {
    source: 'llm_data',
    keyName: GRID_COLUMNS.OUTPUT
  },
  {
    source: 'log_other',
    keyName: GRID_COLUMNS.NAME
  },
  {
    source: SOURCE.PARAMS,
    keyName: GRID_COLUMNS.CHAIN_DURATION
  },
  {
    source: SOURCE.METRICS,
    keyName: GRID_COLUMNS.USER_FEEDBACK
  },
  {
    source: SOURCE.METRICS,
    keyName: GRID_COLUMNS.PROMPT_TEMPLATE
  },
  {
    source: SOURCE.PARAMS,
    keyName: GRID_COLUMNS.TIMESTAMP
  },
  {
    source: SOURCE.METADATA,
    keyName: GRID_COLUMNS.USER_NAME
  }
];

export const DEFAULT_PROMPTS_PER_PAGE = 100;

export const DEFAULT_COLUMN_WIDTH = 130;

export const DEFAULT_COLUMN_NAMES = [
  GRID_COLUMNS.NAME,
  GRID_COLUMNS.TAGS,
  GRID_COLUMNS.TIMESTAMP,
  GRID_COLUMNS.CHAIN_DURATION,
  GRID_COLUMNS.USER_FEEDBACK,
  GRID_COLUMNS.USER_NAME
];

export const DEFAULT_COLUMN_MAX_MAX_WIDTH = {
  width: DEFAULT_COLUMN_WIDTH,
  min: 100,
  max: 800
};
export const MIN_MAX_COLUMN_WIDTH_MAP = {
  [GRID_COLUMNS.NAME]: {
    min: 300,
    width: 300,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [GRID_COLUMNS.PROMPT]: {
    min: 250,
    width: 350,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [GRID_COLUMNS.OUTPUT]: {
    min: 250,
    width: 350,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [GRID_COLUMNS.PROMPT_TEMPLATE]: {
    min: 250,
    width: 350,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [GRID_COLUMNS.USER_FEEDBACK]: {
    min: 120,
    width: 120,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  },
  [GRID_COLUMNS.USER_NAME]: {
    min: 250,
    width: 350,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  }
};

export const DISABLED_COLUMN_SORTING = [
  {
    columnName: GRID_COLUMNS.TAGS,
    sortingEnabled: false
  }
];

export const DISABLED_COLUMN_ORDERING = [GRID_COLUMNS.NAME];

export const FIXED_LEFT_COLUMNS = [GRID_COLUMNS.NAME];

export const DEFAULT_COLUMN_WIDTHS = DEFAULT_COLUMN_NAMES.map(columnName => ({
  columnName,
  width: (MIN_MAX_COLUMN_WIDTH_MAP[columnName] || DEFAULT_COLUMN_MAX_MAX_WIDTH)
    .width
}));

export const DEFAULT_SORTING = [
  { columnName: 'end_server_timestamp', direction: 'desc' }
];

export const DEFAULT_TABLE = {
  columnGrouping: [],
  columnOrders: [...DEFAULT_COLUMN_NAMES],
  columnSorting: DEFAULT_SORTING,
  columnWidths: [...DEFAULT_COLUMN_WIDTHS],
  expandedGroups: [],
  pageNumber: 0,
  pageSize: DEFAULT_PROMPTS_PER_PAGE,
  selection: []
};

export const GROUPED_COLUMN_EXTENSION = [
  { columnName: GRID_COLUMNS.NAME, showWhenGrouped: true }
];

const safeParseJSON = str => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return {};
  }
};

const OMIT_KEYS_ON_COMPARE = ['table.pageNumber'];

export const areDashboardsEqual = (
  dashboard1,
  dashboard2,
  omitKeys = OMIT_KEYS_ON_COMPARE
) => {
  return isEqual(omit(dashboard1, omitKeys), omit(dashboard2, omitKeys));
};

const DEPRECATED_COLUMN_MAP = {
  [GRID_COLUMNS.PROMPT]: 'chain_inputs.final_prompt',
  [GRID_COLUMNS.PROMPT_TEMPLATE]: 'chain_inputs.prompt_template',
  chain_outputs: 'chain_outputs.output',
  output: 'chain_outputs.output'
};

const transformDeprecatedColumns = tableState => {
  const newTableState = { ...tableState };

  if (tableState?.columnOrders) {
    newTableState.columnOrders = tableState.columnOrders.map(columnOrder => {
      return DEPRECATED_COLUMN_MAP[columnOrder] || columnOrder;
    });
  }

  if (tableState?.columnWidths) {
    newTableState.columnWidths = tableState.columnWidths.map(columnWidth => {
      return {
        ...columnWidth,
        columnName:
          DEPRECATED_COLUMN_MAP[columnWidth.columnName] ||
          columnWidth.columnName
      };
    });
  }

  return newTableState;
};

export const convertViewToDashboard = (view, dynamicLLMColumns = []) => {
  const { reactGridTableState } = view;

  const {
    columnOrders = [...DEFAULT_COLUMN_NAMES, ...dynamicLLMColumns],
    ...table
  } = transformDeprecatedColumns(safeParseJSON(reactGridTableState));

  return {
    table: {
      ...DEFAULT_TABLE,
      ...table,
      columnOrders: uniq([GRID_COLUMNS.NAME, ...columnOrders])
    }
  };
};

export const convertDashboardToView = (dashboard, view) => {
  const { table } = dashboard;

  const reactGridTableState = JSON.stringify({
    ...omit(table, ['pageNumber'])
  });

  return {
    ...view,
    reactGridTableState,
    v2: {},
    v3: {}
  };
};

export const dashboardHasChanges = (dashboard, view) => {
  return !areDashboardsEqual(dashboard, convertViewToDashboard(view));
};
