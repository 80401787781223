import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import uniq from 'lodash/uniq';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import { Tooltip } from '@ds';
import { Table } from '@DesignSystem/tables';
import TextCell from './TextCell';

import styles from './TextTab.module.scss';
import { EmptyTableMessage } from '@shared';

export const COLUMN = {
  TEXT: 'text',
  CONTEXT: 'runContext',
  STEP: 'step',
  METADATA_KEY: 'metadata'
};

const DEFAULT_COLUMNS = [
  {
    name: COLUMN.TEXT,
    title: 'Text',
    type: 'string'
  },
  {
    name: COLUMN.CONTEXT,
    title: 'Context',
    type: 'string'
  },
  {
    name: COLUMN.STEP,
    title: 'Step',
    type: 'string'
  }
];
export const DEFAULT_COLUMN_WIDTH = 100;

export const DEFAULT_COLUMN_MAX_MAX_WIDTH = {
  width: DEFAULT_COLUMN_WIDTH,
  min: 100,
  max: 800
};
export const MIN_MAX_COLUMN_WIDTH_MAP = {
  [COLUMN.TEXT]: {
    min: 340,
    width: 540,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  }
};

export const COMPARE_MIN_MAX_COLUMN_WIDTH_MAP = {
  [COLUMN.TEXT]: {
    min: 340,
    width: 340,
    max: DEFAULT_COLUMN_MAX_MAX_WIDTH.max
  }
};

const parseMetadata = (metadata: string): MetadataMap => {
  try {
    return JSON.parse(metadata) || {};
  } catch (_) {
    return {};
  }
};

const calculateWidthsMap = (
  columns: TableColumn[],
  hasMultipleExperiments: boolean
) => {
  return columns.map(column => {
    const defaultBoundaries =
      MIN_MAX_COLUMN_WIDTH_MAP[column.name] ?? DEFAULT_COLUMN_MAX_MAX_WIDTH;
    const boundaries = hasMultipleExperiments
      ? COMPARE_MIN_MAX_COLUMN_WIDTH_MAP[column.name] ?? defaultBoundaries
      : defaultBoundaries;

    return {
      columnName: column.name,
      ...boundaries
    };
  });
};

type TableColumn = {
  title: string;
  name: string;
  type: string;
};

type MetadataMap = Record<string, string | number | object>;

type RawTextAsset = {
  assetId: string;
  fileName: string;
  link: string;
  metadata: string;
  runContext: string;
  step: number;
};

type TextAsset = RawTextAsset & {
  metadataMap: MetadataMap;
};

type TextTableProps = {
  assets: RawTextAsset[];
  isFullHeight: boolean;
  experimentKey: string;
  getAssetHeight: (assetId: string) => string | number;
  setAssetHeight: (assetId: string, height: number) => void;
  hasMultipleExperiments: boolean;
};

const TextTable = ({
  assets,
  isFullHeight,
  experimentKey,
  getAssetHeight,
  setAssetHeight,
  hasMultipleExperiments
}: TextTableProps) => {
  const [columnWidths, setColumnWidths] = useState(
    calculateWidthsMap(DEFAULT_COLUMNS, hasMultipleExperiments)
  );
  const [columnOrder, setColumnOrder] = useState();

  const processedAssets = useMemo(() => {
    return assets.map<TextAsset>(asset => {
      return {
        ...asset,
        metadataMap: parseMetadata(asset.metadata)
      };
    });
  }, [assets]);

  const metadataColumns = useMemo(
    () =>
      uniq(
        processedAssets.reduce<string[]>(
          (result, { metadataMap }) => result.concat(Object.keys(metadataMap)),
          []
        )
      ),
    [processedAssets]
  );

  const columns: TableColumn[] = useMemo(() => {
    return [
      ...DEFAULT_COLUMNS,
      ...metadataColumns.map(name => ({ name, title: name, type: 'string' }))
    ];
  }, [metadataColumns]);

  const dataTypes = useMemo(() => {
    return [
      {
        cols: [COLUMN.TEXT],
        cell: ({ row }: { row: TextAsset }) => {
          return (
            <TextCell
              experimentKey={experimentKey}
              assetId={row.assetId}
              height={getAssetHeight(row.assetId)}
              setHeight={setAssetHeight}
            />
          );
        }
      },
      {
        cols: [COLUMN.CONTEXT, COLUMN.STEP],
        cell: ({ value }: { value: string }) => {
          return (
            <div className={cx(styles.cellContent, styles.ellipsis)}>
              {value}
            </div>
          );
        }
      },
      {
        cols: metadataColumns,
        cell: ({
          row,
          column
        }: {
          row: TextAsset;
          column: { name: string };
        }) => {
          const value = row.metadataMap[column.name];
          const formattedValue = isObject(value)
            ? JSON.stringify(value, null, '  ')
            : value;

          return (
            <Tooltip content={formattedValue} placement="top" wrapper={false}>
              <div className={cx(styles.cellContent, styles.ellipsis)}>
                {formattedValue}
              </div>
            </Tooltip>
          );
        }
      }
    ];
  }, [metadataColumns, experimentKey, getAssetHeight, setAssetHeight]);

  const handleColumnWidthsChange = useCallback(rawColumnWidths => {
    // check min/max boundaries and fix if size is out
    const columnWidths = rawColumnWidths.map(
      (c: { columnName: string | number; width?: number }) => {
        if (isNumber(c.width)) {
          const boundaries =
            MIN_MAX_COLUMN_WIDTH_MAP[c.columnName] ||
            DEFAULT_COLUMN_MAX_MAX_WIDTH;

          return {
            ...c,
            width: Math.max(Math.min(c.width, boundaries.max), boundaries.min)
          };
        }

        return c;
      }
    );

    setColumnWidths(columnWidths);
  }, []);

  // every time full height is changes table should be re-rendered to correctly handle virtual rows
  const key = isFullHeight ? 'full-height' : 'predefined-height';

  return (
    <div className={styles.tableWrapper} key={key}>
      <Table
        columns={columns}
        dataTypes={dataTypes}
        rows={processedAssets}
        isFetching={false}
        columnWidthsConfig={{
          isDisabled: isEmpty(processedAssets),
          columnWidths,
          onColumnWidthsChange: handleColumnWidthsChange
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder: columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        renderEmptyState={materialProps => (
          <EmptyTableMessage {...materialProps} message="No text samples" />
        )}
        paginationConfig={{ isDisabled: true }}
        selectionConfig={{ isDisabled: true }}
        sortingConfig={{ isDisabled: true }}
        rowIdKey="assetId"
        hideLastRowBorder={false}
      />
    </div>
  );
};

export default TextTable;
