export const MODEL_REGISTRY_WEBHOOKS_COLUMNS = {
  id: 'id',
  webhookName: 'name',
  status: 'status',
  headers: 'header',
  authKey: 'authKey',
  url: 'url',
  githubUrl: 'githubUrl',
  events: 'triggers',
  triggers: 'triggers',
  eventTriggers: 'eventTriggers',
  createdBy: 'created_by',
  createdAt: 'createdAt',
  rowActionMenu: 'rowActionMenu'
};

export const MODEL_REGISTRY_WEBHOOKS_TABLE_COLUMNS = [
  { name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName, title: 'Webhook Name' },
  { name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.status, title: 'Status' },
  { name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.url, title: 'URL' },
  {
    name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers,
    title: 'Trigger events'
  },
  { name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdAt, title: 'Created At' },
  { name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdBy, title: 'Created By' },
  { name: MODEL_REGISTRY_WEBHOOKS_COLUMNS.rowActionMenu, title: '' }
];

export const MODEL_REGISTRY_WEBHOOKS_LEFT_COLUMNS = [
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.webhookName
];
export const MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS = [
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.rowActionMenu
];

export const MODEL_VERSION_WEBHOOKS_DEFAULT_COLUMNS_ORDER = [
  ...MODEL_REGISTRY_WEBHOOKS_LEFT_COLUMNS,
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.status,
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.url,
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.triggers,
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdAt,
  MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdBy,
  ...MODEL_REGISTRY_WEBHOOKS_RIGHT_COLUMNS
];

export const WEBHOOK_AUTHORIZATION_HEADER_KEY = 'Authorization';

export const WEBHOOKS_EVENT_TRIGGERS = {
  MODEL_VERSION_CREATED: 'MODEL_VERSION_CREATED',
  MODEL_VERSION_DELETED: 'MODEL_VERSION_DELETED',
  MODEL_TAG_CHANGED: 'MODEL_TAG_CHANGED',
  MODEL_STATUS_CHANGED: 'MODEL_STATUS_CHANGED',
  MODEL_STAGE_CHANGED: 'MODEL_STAGE_CHANGED'
};

export const WEBHOOKS_EVENT_TRIGGERS_MAP = {
  MODEL_VERSION_CREATED: 'version created',
  MODEL_VERSION_DELETED: 'version deleted',
  MODEL_TAG_CHANGED: 'tag changed',
  MODEL_STATUS_CHANGED: 'status changed',
  MODEL_STAGE_CHANGED: 'stage changed'
};
