import axios from 'axios';
import queryString from 'query-string';

import { BASE_API_URL } from '../lib/appConstants';

const BASE_ARTIFACTS_URL = `${BASE_API_URL}artifacts`;

const artifactsApi = {
  fetchArtifacts(workspace, type = null) {
    const url = `${BASE_ARTIFACTS_URL}/get-all`;
    return axios.get(url, {
      params: {
        workspace,
        type
      }
    });
  },

  fetchArtifact(workspace, artifactName) {
    const url = `${BASE_ARTIFACTS_URL}/details`;

    return axios.get(url, {
      params: {
        workspace,
        artifact: artifactName
      }
    });
  },

  fetchArtifactVersion(workspace, artifactName, version) {
    const url = `${BASE_ARTIFACTS_URL}/version`;

    return axios.get(url, {
      params: {
        workspace,
        artifactName,
        version
      }
    });
  },

  fetchArtifactLineage({ workspace, artifactId }) {
    return axios.get(
      `${BASE_ARTIFACTS_URL}/lineage?workspace=${workspace}&artifactId=${artifactId}`
    );
  },

  updateArtifact(requestBody) {
    const url = `${BASE_ARTIFACTS_URL}/details`;

    return axios.post(url, JSON.stringify(requestBody));
  },

  updateArtifactVersionMetadata(artifactVersionId, metadata) {
    const url = `${BASE_ARTIFACTS_URL}/version/metadata`;

    return axios.post(url, { artifactVersionId, metadata });
  },

  updateArtifactVersionLabels(artifactVersionId, tags, alias) {
    const url = `${BASE_ARTIFACTS_URL}/version/labels`;

    return axios.post(url, { artifactVersionId, tags, alias });
  },

  deleteArtifact(artifactId) {
    const url = `${BASE_ARTIFACTS_URL}/details/delete`;

    return axios.post(url, { artifactId });
  },

  deleteArtifactVersion(artifactVersionId) {
    const url = `${BASE_ARTIFACTS_URL}/version/delete`;

    return axios.post(url, { artifactVersionId });
  },

  fetchArtifactsCount(workspace) {
    const url = `${BASE_ARTIFACTS_URL}/count`;

    return axios.get(url, {
      params: {
        workspace
      }
    });
  },

  getRedirectURL(artifactId) {
    // TODO: this is not working !!!!
    return `${BASE_API_URL}artifacts/redirect/${artifactId}`;
  },

  getVersionRedirectURL(artifactVersionId) {
    // TODO: this is not working !!!!
    return `${BASE_API_URL}artifacts/version/redirect?artifactVersionId=${artifactVersionId}`;
  }
};

export const getDownloadArtifactVersionURL = artifactVersionId => {
  const searchQuery = queryString.stringify({
    versionId: artifactVersionId
  });

  return `${BASE_ARTIFACTS_URL}/version/download?${searchQuery}`;
};

export default artifactsApi;
