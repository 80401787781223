// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelRegistryNotes-module__modelRegistryNotes--Se413{margin:42px 90px 0 98px;display:flex}.ModelRegistryNotes-module__modelRegistryNotes--Se413 .markdown-editor{max-width:1244px}.ModelRegistryNotes-module__modelRegistryNotesInner--Vc2Ov{flex:1;min-height:500px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelRegistryNotes": `ModelRegistryNotes-module__modelRegistryNotes--Se413`,
	"modelRegistryNotesInner": `ModelRegistryNotes-module__modelRegistryNotesInner--Vc2Ov`
};
module.exports = ___CSS_LOADER_EXPORT___;
