import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import SmallLoader from '@shared/components/SmallLoader';
import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import { BASE_API_URL } from '@/lib/appConstants';

const type = 'image';

const ImagePreview = ({ artifactVersionId, assetId, experimentKey }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFinishLoad = () => {
    setHasLoaded(true);
  };

  const handleError = () => {
    setHasLoaded(true);
    setIsError(true);
  };

  const baseURL = `${BASE_API_URL}/asset/download`;
  const searchQuery = queryString.stringify({
    assetId,
    experimentKey,
    artifactVersionId
  });

  return (
    <>
      {!hasLoaded && (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={`Fetching ${type} data`}
        />
      )}

      {isError && <EmptyPreview message={`No ${type} preview available.`} />}
      {!isError && (
        <img
          src={`${baseURL}?${searchQuery}`}
          style={{ width: '100%' }}
          onLoad={handleFinishLoad}
          onError={handleError}
        />
      )}
    </>
  );
};

ImagePreview.defaultProps = {
  artifactVersionId: null
};

ImagePreview.propTypes = {
  artifactVersionId: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired
};

export default ImagePreview;
