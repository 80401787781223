import { difference } from 'lodash';
import { useMemo } from 'react';

export function useTargetExperimentKeys({
  experimentKeys = [],
  experimentsCount = 0,
  hiddenExperimentKeys = []
}: {
  experimentKeys: string[];
  experimentsCount: number;
  hiddenExperimentKeys: string[];
}) {
  return useMemo(() => {
    return difference(experimentKeys, hiddenExperimentKeys).slice(
      0,
      experimentsCount
    );
  }, [experimentKeys, experimentsCount, hiddenExperimentKeys]);
}
