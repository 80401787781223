import React, { ComponentType, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { Table as TableBase } from '@devexpress/dx-react-grid';

import { useCurrentOrganization } from '@shared/hooks';
import TableNoSearchResults from '@shared/components/TableNoSearchResults';
import TableEmptyState from '@shared/components/TableEmptyState';

import typography from '@ds-typography';
import { Button, SearchInput } from '@ds';
import { Table } from '@DesignSystem/tables';

import { useOrganizationPythonEnvironmentVersions } from '@account-settings/api/python-environments';
import { PythonEnvironmentVersion } from '@shared/types';
import PythonEnvironmentVersionModal from '@account-settings/components/AdminSettings/PythonEnvironment/PythonEnvironmentVersionModal';

import {
  getPythonEnvironmentVersionDataTypes,
  PYTHON_ENVIRONMENT_COLUMNS,
  PYTHON_ENVIRONMENT_VERSION_COLUMN_WIDTHS
} from './PythonEnvironmentColumns';

import classes from './PythonEnvironment.module.scss';

const PythonEnvironment = () => {
  const [columnWidths, setColumnWidths] = useState(
    PYTHON_ENVIRONMENT_VERSION_COLUMN_WIDTHS
  );
  const [columnOrder, setColumnOrder] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [
    versionToShow,
    setVersionToShow
  ] = useState<PythonEnvironmentVersion | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = useCallback((version?: PythonEnvironmentVersion) => {
    if (version) {
      setVersionToShow(version);
    }

    setIsOpenModal(true);
  }, []);

  const closeModal = () => {
    setIsOpenModal(false);
    setVersionToShow(null);
  };

  const currentOrganization = useCurrentOrganization();
  const {
    data: pythonEnvironmentVersions
  } = useOrganizationPythonEnvironmentVersions(currentOrganization?.id || '', {
    enabled: Boolean(currentOrganization?.id),
    refetchOnMount: true
  });

  const filteredPythonEnvironmentVersions = useMemo(() => {
    if (searchInput === '') {
      return pythonEnvironmentVersions;
    }

    return pythonEnvironmentVersions?.filter(p => {
      return p.version.toLowerCase().includes(searchInput.toLowerCase());
    });
  }, [pythonEnvironmentVersions, searchInput]);

  const dataTypes = useMemo(
    () => getPythonEnvironmentVersionDataTypes(openModal),
    [openModal]
  );

  const renderEmptyState = (props: ComponentType<TableBase.RowProps>) => {
    if (searchInput?.length && pythonEnvironmentVersions?.length) {
      return (
        <TableNoSearchResults buttonText="" clickHandler={noop} {...props} />
      );
    }

    if (!pythonEnvironmentVersions?.length) {
      return <TableEmptyState label="No data" />;
    }

    return null;
  };

  return (
    <>
      <div className={classes.pythonEnvironmentsContainer}>
        <h4>Python Environment</h4>
        <p
          className={cx(
            classes.pythonEnvironmentsDescription,
            typography.dsBody
          )}
        >
          Python Environment versions allow you to customize the packages
          available when creating a Python Panel. The latest successfully built
          version will be used in panels.
        </p>

        <div className={classes.pythonEnvironmentsSearchCreateContainer}>
          <div className={classes.pythonEnvironmentsInputContainer}>
            <SearchInput
              onClearIconClick={() => setSearchInput('')}
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
              placeholder="Search"
            />
          </div>

          <Button size="large" onClick={() => openModal()}>
            Create New Version
          </Button>
        </div>

        <Table
          columns={PYTHON_ENVIRONMENT_COLUMNS}
          isFetching={false}
          rowIdKey="id"
          dataTypes={dataTypes}
          rows={filteredPythonEnvironmentVersions}
          columnWidthsConfig={{
            isDisabled: false,
            columnWidths,
            onColumnWidthsChange: setColumnWidths
          }}
          columnOrderConfig={{
            isDisabled: false,
            columnOrder,
            onColumnOrderChange: setColumnOrder
          }}
          renderEmptyState={renderEmptyState}
          paginationConfig={{ isDisabled: true }}
          selectionConfig={{ isDisabled: true }}
          sortingConfig={{ isDisabled: true }}
        />
      </div>

      <PythonEnvironmentVersionModal
        versionToShow={versionToShow}
        isOpen={isOpenModal}
        onClose={closeModal}
        organizationId={currentOrganization?.id || ''}
      />
    </>
  );
};

export default PythonEnvironment;
