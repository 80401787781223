import cx from 'classnames';
import defaultTo from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { StyledTooltip } from '@DesignSystem/data-display';
import MarkdownIFrame from '@shared/components/MarkdownIFrame';

const TextOverflow = ({
  className,
  disableHoverListener,
  emptyTextPlaceholder,
  maxVisibleLength,
  originalText,
  tooltipMode,
  renderAsHTML,
  ...rest
}) => {
  const originalTextString = defaultTo(originalText, '');

  const truncatedText = useMemo(() => {
    if (originalTextString.length < maxVisibleLength) {
      return isEmpty(originalTextString)
        ? emptyTextPlaceholder
        : originalTextString;
    }

    return `${originalTextString.slice(0, maxVisibleLength)}...`;
  }, [emptyTextPlaceholder, maxVisibleLength, originalTextString]);

  const isTooltipDisabled =
    disableHoverListener ||
    isEmpty(originalText) ||
    (originalTextString.length < maxVisibleLength && !renderAsHTML);

  return (
    <StyledTooltip
      title={
        renderAsHTML ? (
          <MarkdownIFrame textString={originalTextString} />
        ) : (
          originalTextString
        )
      }
      placement="bottom"
      type="base"
      enterDelay={300}
      disableHoverListener={isTooltipDisabled}
      interactive
      arrow
      mode={tooltipMode}
    >
      <span className={cx('text-overflow-content', className)} {...rest}>
        {truncatedText}
      </span>
    </StyledTooltip>
  );
};

TextOverflow.defaultProps = {
  className: '',
  disableHoverListener: false,
  emptyTextPlaceholder: '',
  maxVisibleLength: 50,
  originalText: '',
  tooltipMode: 'dark',
  renderAsHTML: false
};

TextOverflow.propTypes = {
  className: PropTypes.string,
  disableHoverListener: PropTypes.bool,
  emptyTextPlaceholder: PropTypes.string,
  maxVisibleLength: PropTypes.number,
  originalText: PropTypes.string,
  tooltipMode: PropTypes.string,
  renderAsHTML: PropTypes.bool
};

export default TextOverflow;
