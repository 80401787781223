import React, { useState } from 'react';
import { noop } from 'lodash';

type UsePopoverStateOpts = {
  isBlocked?: boolean;
  onClose?: React.MouseEventHandler<HTMLElement>;
};
const usePopoverState = <T extends HTMLElement>({
  isBlocked = false,
  onClose = noop
}: UsePopoverStateOpts = {}) => {
  const [anchorElement, setAnchorElement] = useState<T | null>(null);

  const openPopover: React.MouseEventHandler<T> = e => {
    if (isBlocked) return;
    setAnchorElement(e.currentTarget);
  };

  const closePopover = () => {
    setAnchorElement(null);
  };

  const onClosePopover: React.MouseEventHandler<T> = e => {
    closePopover();
    onClose(e);
  };

  const isPopoverOpened = Boolean(anchorElement);

  return {
    openPopover,
    closePopover,
    onClosePopover,
    isPopoverOpened,
    anchorElement
  };
};

export default usePopoverState;
