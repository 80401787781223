import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import AudioPlayer from '@experiment-management-shared/components/AudioPlayer';
import { useInView } from 'react-intersection-observer';

import styles from './AudioTab.module.scss';

const VISIBILITY_DELAY = 300;

type AudioCellProps = {
  link: string;
};

const AudioCell = ({ link }: AudioCellProps) => {
  const [wasVisible, setWasVisible] = useState(false);
  const [ref, inView] = useInView();

  const changeToVisible = useCallback(
    debounce(() => setWasVisible(true), VISIBILITY_DELAY),
    []
  );

  useEffect(() => {
    if (inView) {
      changeToVisible();
    } else {
      changeToVisible.cancel();
    }
  }, [changeToVisible, inView]);

  return (
    <div className={styles.audioCell} ref={ref}>
      {wasVisible && <AudioPlayer src={link} height={60} />}
    </div>
  );
};

export default AudioCell;
