// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageWithAnnotations-module__imageWithAnnotationsContainer--FrTGT{position:relative}.ImageWithAnnotations-module__imageWithAnnotationsContainer--FrTGT img{display:block}.ImageWithAnnotations-module__imageWrapper--u1JhJ{height:100%;max-width:100%;object-fit:contain}.ImageWithAnnotations-module__pixelated--p7pem{image-rendering:pixelated}.ImageWithAnnotations-module__grayscale--gLEhA{filter:grayscale(100%);-webkit-filter:grayscale(100%)}.ImageWithAnnotations-module__labelsContainer--pY8Vn{position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;overflow:hidden}.ImageWithAnnotations-module__pill--DqZVI{z-index:60;border-radius:0;position:absolute;left:0;top:0;font-size:12px;padding:7px;will-change:transform;transition:transform .05s ease-out 0s;transform-origin:bottom left}.ImageWithAnnotations-module__pill--DqZVI.ImageWithAnnotations-module__isTopOverflowing--XXdND{transform-origin:top left}.ImageWithAnnotations-module__pill--DqZVI:hover{padding:7px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWithAnnotationsContainer": `ImageWithAnnotations-module__imageWithAnnotationsContainer--FrTGT`,
	"imageWrapper": `ImageWithAnnotations-module__imageWrapper--u1JhJ`,
	"pixelated": `ImageWithAnnotations-module__pixelated--p7pem`,
	"grayscale": `ImageWithAnnotations-module__grayscale--gLEhA`,
	"labelsContainer": `ImageWithAnnotations-module__labelsContainer--pY8Vn`,
	"pill": `ImageWithAnnotations-module__pill--DqZVI`,
	"isTopOverflowing": `ImageWithAnnotations-module__isTopOverflowing--XXdND`
};
module.exports = ___CSS_LOADER_EXPORT___;
