import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { CHART_TYPES } from '@mpm-druid/constants';
import { BarChartContainer } from '../BarChartContainer';
import { LineChartContainer } from '../LineChartContainer';
import { NumericalDistributionContainer } from '../NumericalDistributionContainer';
import './PanelContainer.scss';

const PanelContent = ({
  data,
  chartType,
  onChangePercentileMode,
  percentileMode,
  disableZoom,
  section,
  ...props
}) => {
  if (chartType === CHART_TYPES.BAR) {
    return (
      <BarChartContainer
        data={data}
        disableZoom={disableZoom}
        section={section}
        chartType={chartType}
        {...props}
      />
    );
  }

  if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
    return (
      <NumericalDistributionContainer
        chartType={chartType}
        data={data}
        percentileMode={percentileMode}
        onChangePercentileMode={onChangePercentileMode}
        section={section}
        disableZoom={disableZoom}
        {...props}
      />
    );
  }

  return (
    <LineChartContainer
      chartType={chartType}
      data={data}
      percentileMode={percentileMode}
      onChangePercentileMode={onChangePercentileMode}
      disableZoom={disableZoom}
      section={section}
      {...props}
    />
  );
};

PanelContent.propTypes = {
  chartType: PropTypes.string,
  layoutData: PropTypes.object,
  percentileMode: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChangePercentileMode: PropTypes.func,
  disableZoom: PropTypes.bool,
  section: PropTypes.string
};

PanelContent.defaultProps = {
  chartType: '',
  layoutData: {},
  percentileMode: '',
  data: [],
  onChangePercentileMode: noop,
  disableZoom: false,
  section: ''
};

export default PanelContent;
