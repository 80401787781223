import dagre from 'dagre';
import isEmpty from 'lodash/isEmpty';
import { Position } from 'react-flow-renderer';

const getLineageLayout = (data = {}, direction = 'LR') => {
  if (isEmpty(data)) {
    return {
      nodes: [],
      edges: []
    };
  }

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  data.nodes.forEach(el => {
    dagreGraph.setNode(el.id, { width: 150, height: 50 });
  });

  const formattedEdges = data.edges.map(el => {
    dagreGraph.setEdge(el.source, el.target);

    return {
      ...el,
      animated: true,
      type: 'smoothstep'
    };
  });

  dagre.layout(dagreGraph);

  const nodes = data.nodes.map(el => {
    const { x, y } = dagreGraph.node(el.id);
    let targetPosition = null;
    let sourcePosition = null;

    const isTarget = !!dagreGraph.edges().find(edge => edge.v === el.id);

    const isSource = !!dagreGraph.edges().find(edge => edge.w === el.id);

    // since CustomLineageNode can be both target and source node
    // we set 'none' if its input/output to hide the handles
    if (isSource) {
      sourcePosition = 'none';
      targetPosition = Position.Left;
    }

    if (isTarget) {
      sourcePosition = Position.Right;
      targetPosition = 'none';
    }

    if (isSource && isTarget) {
      sourcePosition = Position.Right;
      targetPosition = Position.Left;
    }

    return {
      ...el,
      targetPosition,
      sourcePosition,
      data: {
        ...el.data,
        url: el.url
      },
      position: {
        x,
        y
      }
    };
  });

  return { nodes, edges: formattedEdges, dagreGraph };
};

export default getLineageLayout;
