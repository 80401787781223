import React, { useState } from 'react';
import { DSHelpIcon } from '@ds-icons';
import HelpSidebar from './HelpSidebar';
import { IconButton } from '@ds';

const HelpHeaderButton = () => {
  const [open, setOpen] = useState(false);

  const openSidebar = () => setOpen(true);
  const closeSidebar = () => setOpen(false);

  return (
    <>
      <HelpSidebar onClose={closeSidebar} open={open} />
      <IconButton
        className="header-button"
        type="secondary"
        Icon={<DSHelpIcon />}
        onClick={openSidebar}
      />
    </>
  );
};

export default HelpHeaderButton;
