// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoBox-module__infoBox--j3lRG{max-width:425px;padding:16px;border:1px solid var(--section-border-color);border-radius:4px;background-color:var(--background-color);overflow:hidden}.InfoBox-module__infoBox--j3lRG .InfoBox-module__boxTitle--wZkL8{display:flex;align-items:center;align-self:flex-start;gap:8px;font-size:14px;line-height:16px;font-weight:500;color:var(--gray-5);margin-bottom:8px}.InfoBox-module__infoBox--j3lRG .InfoBox-module__boxSubtitle--ShWOz{width:100%;font-size:13px;line-height:16px;color:var(--gray-5)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `InfoBox-module__infoBox--j3lRG`,
	"boxTitle": `InfoBox-module__boxTitle--wZkL8`,
	"boxSubtitle": `InfoBox-module__boxSubtitle--ShWOz`
};
module.exports = ___CSS_LOADER_EXPORT___;
