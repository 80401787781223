import React, { useMemo, useState } from 'react';

import { DateRange, RadioSelector, Select } from '@DesignSystem/controllers';
import {
  INPUT_PANEL_TABS,
  INTERVAL_TYPE_OPTIONS,
  MPM_CHART_DISABLED_ZOOM,
  PERFORMANCE_DATE_RANGES,
  PANEL_NAMES,
  SOURCE_TYPE,
  PANEL_SECTIONS,
  DEFAULT_VERSION,
  CHART_COLORS,
  FEATURE_TYPES
} from '@mpm-druid/constants';
import { useMPMPerformanceParams } from '@mpm-druid/hooks';
import { PanelContainer } from '../PanelContainer';
import { Button } from '@ds';
import { PredicatesPopover } from '../PredicatesPopover';
import { mapModelVersions } from '@mpm-druid/utils';
import { ModelDetailsType, PanelTab, PredicateQuery } from '@mpm-druid/types';
import styles from './ModelPerformancePage.module.scss';

type ModelPerformancePageProps = {
  model: ModelDetailsType;
  predicates: PredicateQuery[];
  setPredicates: (predicates: PredicateQuery[]) => void;
};

export const ModelPerformancePage = ({
  model,
  predicates,
  setPredicates
}: ModelPerformancePageProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const versions = DEFAULT_VERSION.concat(mapModelVersions(model.versions));
  const { features } = model;

  const {
    version,
    onUpdateVersion,
    from,
    to,
    intervalType,
    dateRange,
    setStartDate,
    setEndDate,
    calcMaxDate,
    setDateRange,
    onUpdateIntervalType,
    onChangeRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  } = useMPMPerformanceParams(versions);

  const customMetricsTabs = useMemo(() => {
    const panelTabs: PanelTab[] = [];
    if (model?.customMetrics?.length) {
      model?.customMetrics.forEach(metric => {
        const { name, metricId, cometSql } = metric;
        panelTabs.push({
          label: name,
          value: PANEL_SECTIONS.CUSTOM_METRICS,
          section: PANEL_SECTIONS.CUSTOM_METRICS,
          cometSql: cometSql,
          metricId: metricId,
          key: metricId
        });
      });
    }

    return panelTabs;
  }, [model]);

  const outputFeaturesTabs = useMemo(() => {
    if (features?.length) {
      const tabs: PanelTab[] = features
        .filter(
          feature => feature?.source === SOURCE_TYPE.model_output_features
        )
        .map(feature => {
          const { name, type } = feature;
          const section =
            type === FEATURE_TYPES.CATEGORICAL
              ? PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES
              : PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL;
          return {
            label: name,
            value: section,
            feature: feature,
            section: section
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      return tabs;
    }
    return [];
  }, [features]);

  const openPredicatesPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={styles.modelPerformanceTab}>
      <div className={styles.modelPerformanceTabPanels}>
        <div className={styles.modelPerformanceTabHeader}>
          <Button
            size="medium"
            type="secondary"
            onClick={openPredicatesPopover}
            active={!!anchorEl}
          >
            Filters{' '}
            {predicates.length - 1 > 0 ? `(${predicates.length - 1})` : ''}
          </Button>
          <PredicatesPopover
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handlePredicatesChange={setPredicates}
          />
          <Select
            onChange={onUpdateVersion}
            options={versions}
            value={version}
            withInput
          />
          <div className={styles.modelPerformanceTabHeaderDivider} />
          <Select
            onChange={onUpdateIntervalType}
            options={INTERVAL_TYPE_OPTIONS}
            value={intervalType}
          />
          <div className={styles.modelPerformanceTabHeaderDivider} />
          <div className={styles.modelPerformanceTabHeaderDateRanges}>
            {PERFORMANCE_DATE_RANGES[intervalType]?.map((days: number) => (
              <RadioSelector
                isSelected={days === dateRange}
                key={`date-range-${days}`}
                onClick={() => {
                  setDateRange(days);
                  setEndDate(null);
                  setStartDate(new Date());
                }}
                text={`${days}d`}
              />
            ))}
          </div>
          <DateRange
            startDate={handledCalendarStartDate}
            endDate={handledCalendarEndDate}
            onChange={onChangeRange}
            calcMaxDate={calcMaxDate}
          />
        </div>

        <div className={styles.modelPerformanceTabContent}>
          <PanelContainer
            title={PANEL_NAMES.CUSTOM_METRICS}
            tabs={customMetricsTabs}
            section={PANEL_SECTIONS.CUSTOM_METRICS}
            from={from}
            to={to}
            intervalType={intervalType}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            model={model}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
          />

          <PanelContainer
            title={PANEL_NAMES.OUTPUT_FEATURES}
            tabs={outputFeaturesTabs}
            section={PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL}
            from={from}
            to={to}
            intervalType={intervalType}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            model={model}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
          />

          <PanelContainer
            title={PANEL_NAMES.INPUT_METRICS}
            tabs={INPUT_PANEL_TABS}
            section={PANEL_SECTIONS.DATA_DRIFT}
            from={from}
            to={to}
            intervalType={intervalType}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            model={model}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
          />

          <PanelContainer
            title={PANEL_NAMES.NUMBER_OF_PREDICTIONS}
            section={PANEL_SECTIONS.NUMBER_OF_PREDICTIONS}
            from={from}
            to={to}
            intervalType={intervalType}
            version={version}
            predicates={predicates}
            colors={CHART_COLORS}
            model={model}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
          />
        </div>
      </div>
    </div>
  );
};
