import React from 'react';
import cx from 'classnames';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import classNames from './RoundCheckbox.module.scss';

type RoundCheckboxProps = CheckboxProps & {
  dataTest?: string;
  className: string;
};
const RoundCheckbox: React.FC<RoundCheckboxProps> = ({
  checked,
  className,
  dataTest,
  ...props
}) => (
  <div
    className={cx(classNames.dsRoundCheckboxContainer, className, {
      [classNames.dsRoundCheckboxContainerActive]: checked
    })}
  >
    <Checkbox
      data-test={dataTest}
      disableRipple
      className={cx(classNames.dsRoundCheckbox)}
      checked={!!checked}
      {...props}
    />
  </div>
);

export default RoundCheckbox;
