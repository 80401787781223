import { useState, useCallback, useEffect } from 'react';

export function useCarrousel<T>({
  items,
  selectedIndex = 0
}: {
  items: T[];
  selectedIndex?: number;
}): {
  goNext: () => void;
  goPrevious: () => void;
  goToIndex: (newIndex: number) => void;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  item: T;
} {
  const [index, setIndex] = useState(selectedIndex);
  const isFirst = index === 0;
  const isLast = index === items.length - 1;

  const goToIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0 || newIndex >= items.length) return;

      setIndex(newIndex);
    },
    [items.length]
  );

  const goNext = useCallback(() => goToIndex(index + 1), [goToIndex, index]);
  const goPrevious = useCallback(() => goToIndex(index - 1), [
    goToIndex,
    index
  ]);

  useEffect(() => {
    setIndex(selectedIndex);
  }, [selectedIndex]);

  return {
    goNext,
    goPrevious,
    goToIndex,
    index,
    isFirst,
    isLast,
    item: items[index]
  };
}

export default useCarrousel;
