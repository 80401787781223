import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Divider from '@material-ui/core/Divider';

import SmallLoader from '@shared/components/SmallLoader';
import SortByPopover from '@shared/components/WorkspaceSubComponents/SortByPopover';
import StyledSearchBar from '@shared/components/StyledComponents/StyledSearchBar';
import { useIsUserMemberOfWorkspace } from '@shared/hooks';
import workspaceActions from '@/actions/workspaceActions';
import {
  FILTERS,
  SEARCHABLE_FIELDS,
  WORKSPACE_PAGE_TABS,
  TAB_STATE_KEYS,
  getSortByItemsByKey,
  searchItems,
  sortItems,
  filterItems
} from '@/constants/workspaceConstants';
import artifactActions from '@/actions/artifactActions';
import { getArtifactsArray } from '@/reducers/artifactsReducer';
import ArtifactRow from './ArtifactRow';
import { getTabState } from '@/reducers/ui/workspaceUiReducer';
import FilterPopover from '@shared/components/FilterPopover';

const { ARTIFACTS } = WORKSPACE_PAGE_TABS;
const { SORT_PROPERTY, SORT_DIRECTION, SELECTED_FILTERS } = TAB_STATE_KEYS;

const ArtifactsTab = ({
  dispatch,
  entityName,
  items,
  selectedFilters,
  sortByDirection,
  sortByProperty
}) => {
  const { workspace } = useParams();
  const [searchText, setSearchText] = useState('');
  const [hasFetched, setHasFetched] = useState(false);
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();

  useEffect(() => {
    dispatch(artifactActions.fetchArtifactsForWorkspace(workspace)).then(() => {
      setHasFetched(true);
    });
  }, []);

  const filters = useMemo(() => {
    return FILTERS[ARTIFACTS].getFilters(items);
  }, [items]);

  const handleSearchChange = useCallback(text => setSearchText(text), []);
  const handleCancelSearch = useCallback(() => setSearchText(''), []);
  const handleSortByChange = useCallback(
    (property, direction) => {
      dispatch(
        workspaceActions.updateTabStateByKey(
          ARTIFACTS,
          SORT_DIRECTION,
          direction
        )
      );
      dispatch(
        workspaceActions.updateTabStateByKey(ARTIFACTS, SORT_PROPERTY, property)
      );
    },
    [dispatch]
  );

  const handleFilterChange = useCallback(
    (filterKey, values) => {
      const update = {
        ...selectedFilters,
        [filterKey]: values
      };

      dispatch(
        workspaceActions.updateTabStateByKey(
          ARTIFACTS,
          SELECTED_FILTERS,
          update
        )
      );
    },
    [selectedFilters, dispatch]
  );

  const filteredItems = useMemo(() => {
    return filterItems(items, selectedFilters);
  }, [selectedFilters, items]);

  const searchedItems = useMemo(() => {
    const searchableFields = SEARCHABLE_FIELDS[ARTIFACTS];
    return searchItems(filteredItems, searchText, searchableFields);
  }, [filteredItems, searchText]);

  const visibleItems = useMemo(() => {
    return sortItems(searchedItems, sortByDirection, sortByProperty);
  }, [searchedItems, sortByDirection, sortByProperty]);

  const renderActionFields = () => {
    return (
      <div className="action-fields-container">
        <div className="field-group">
          {filters.map((filter, index) => (
            <Fragment key={filter.filterKey}>
              <FilterPopover
                className="light"
                handleFilterChange={handleFilterChange}
                selectedFilters={get(selectedFilters, [filter.filterKey], [])}
                filter={filter}
              />

              {index + 1 < filters.length ? (
                <Divider orientation="vertical" style={{ height: 20 }} />
              ) : null}
            </Fragment>
          ))}

          <div style={{ marginLeft: 12 }}>
            <StyledSearchBar
              entityName={entityName}
              handleCancelSearch={handleCancelSearch}
              handleSearchChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="field-group">
          <SortByPopover
            handleSortByChange={handleSortByChange}
            sortByProperty={sortByProperty}
            sortByDirection={sortByDirection}
            items={getSortByItemsByKey(ARTIFACTS)}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (!hasFetched) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={`Fetching ${entityName}`}
        />
      );
    }

    if (isEmpty(visibleItems)) {
      return (
        <div className="content-not-found">
          <h3 className="page-notice">
            {`This workspace has no matching ${entityName}.`}
          </h3>
        </div>
      );
    }

    return visibleItems.map(artifact => (
      <ArtifactRow
        key={artifact.artifactId}
        workspaceName={workspace}
        artifact={artifact}
        isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
      />
    ));
  };

  return (
    <div className="artifacts-tab">
      {renderActionFields()}
      {renderBody()}
    </div>
  );
};

ArtifactsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  sortByDirection: PropTypes.string.isRequired,
  sortByProperty: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const {
    itemCount,
    sortByProperty,
    sortByDirection,
    selectedFilters
  } = getTabState(state, { tabKey: WORKSPACE_PAGE_TABS.ARTIFACTS });

  return {
    itemCount,
    items: getArtifactsArray(state),
    selectedFilters,
    sortByDirection,
    sortByProperty
  };
};

export default withRouter(connect(mapStateToProps)(ArtifactsTab));
