import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import FileSaver from 'file-saver';

import { Plotly } from '@DesignSystem/charts';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const useChartExport = ({ graphDiv, title, chartType }) => {
  const dispatch = useDispatch();
  const dataRef = useRef(null);

  const setExportData = useCallback(
    exportData => {
      dataRef.current = exportData;
    },
    [dataRef]
  );

  const layoutRef = useRef(null);

  const setExportLayout = useCallback(
    exportLayout => {
      layoutRef.current = exportLayout;
    },
    [layoutRef]
  );

  const handleExportData = useCallback(
    format => {
      let gd = graphDiv;

      if (dataRef.current && layoutRef.current) {
        gd = {
          config: { displayModeBar: false },
          data: dataRef.current,
          layout: layoutRef.current
        };
      }

      if (gd) {
        Plotly.downloadImage(gd, {
          format,
          width: 1200,
          height: 600,
          filename: title
        });
      }
    },
    [graphDiv, title]
  );

  const getDataForExport = useCallback(() => {
    if (isEmpty(dataRef.current)) return [];

    if (
      chartType === BUILT_IN_CHART_TYPES['BuiltIn/Line'] ||
      chartType === BUILT_IN_CHART_TYPES['BuiltIn/Bar'] ||
      chartType === BUILT_IN_CHART_TYPES['BuiltIn/Scatter'] ||
      chartType === BUILT_IN_CHART_TYPES.curves
    ) {
      return dataRef.current
        .filter(trace => !trace.name.includes('(last point)'))
        .map(trace => {
          const { x, y, type, name } = trace;
          return {
            x,
            y,
            type,
            name
          };
        });
    }

    return dataRef.current;
  }, [dataRef, chartType]);

  const handleExportJSON = useCallback(
    filename => {
      const dataForExport = getDataForExport();

      let json = null;
      try {
        json = JSON.stringify(dataForExport);
      } catch (_) {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_FAILED_JSON_STRINGIFY,
            'There was an error exporting your data.'
          )
        );
        return;
      }

      const blob = new window.Blob([json], {
        type: 'application/json'
      });

      FileSaver.saveAs(blob, filename);
    },
    [dispatch, getDataForExport]
  );

  return {
    setExportData,
    setExportLayout,
    handleExportData,
    handleExportJSON
  };
};

export default useChartExport;
