import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatModelVersionStatusToText } from '@shared/utils/model-registry-request-status';
import classNames from './ModelVersionRequestStatusModal.module.scss';

const RequestStatusAlreadyExist = ({ status, requestedStatus }) => {
  return (
    <Box mt={2}>
      <Box className={classNames.modalVersionRequestStatusLabel}>
        {`You already have a pending request to change the status from '${formatModelVersionStatusToText(
          status
        )}' to '${formatModelVersionStatusToText(requestedStatus)}'.`}
      </Box>
      <Box mt={4} className={classNames.modalVersionRequestStatusLabel}>
        To request a new change, the pending request needs to be approved,
        rejected or canceled. You can manage pending requests at the
        &apos;Pending request&apos; table.
      </Box>
    </Box>
  );
};

RequestStatusAlreadyExist.defaultProps = {
  status: null,
  requestedStatus: null
};

RequestStatusAlreadyExist.propTypes = {
  status: PropTypes.string,
  requestedStatus: PropTypes.string
};

export default RequestStatusAlreadyExist;
