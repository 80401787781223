import React from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import typography from '@ds-typography';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Button } from '@ds';
import classNames from './SimpleModal.module.scss';

const StyledDialog = withStyles({
  paper: {
    padding: '32px 40px',
    fontSize: '14px',
    lineHeight: '21px',
    maxWidth: '566px'
  }
})(Dialog);

type SimpleModalProps = DialogProps & {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  dataTest?: string;
  onSubmit?: () => void;
  onClose?: () => void;
};

const SimpleModal: React.FC<SimpleModalProps> = ({
  title,
  children,
  footer,
  primaryButtonText = 'Confirm',
  secondaryButtonText = 'Cancel',
  dataTest = 'simple-modal',
  onSubmit,
  onClose,
  ...dialogProps
}) => {
  return (
    <StyledDialog {...dialogProps} onClose={onClose} data-test={dataTest}>
      <h3
        className={cx(
          typography.dsH3FontSize,
          typography.dsH3LineHeight,
          classNames.title
        )}
      >
        {title}
      </h3>

      <div className={classNames.simpleModalContent}>{children}</div>

      {footer ?? (
        <div className={classNames.simpleModalFooter}>
          <Button
            type="tertiary"
            size="large"
            data-test={`${dataTest}-secondary-button`}
            onClick={onClose}
          >
            {secondaryButtonText}
          </Button>
          <Button
            type="primary"
            size="large"
            data-test={`${dataTest}-primary-button`}
            onClick={onSubmit}
          >
            {primaryButtonText}
          </Button>
        </div>
      )}
    </StyledDialog>
  );
};

export default SimpleModal;
