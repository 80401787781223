import { useQuery } from 'react-query';

import { axiosInstance, cancelWrapper } from '../api';
import useProject from '../project/useProject';
import chartHelpers from '../../experiment-management-shared/utils/chartHelpers';

// eslint-disable-next-line camelcase
const SCALAR_SAMPLE_SIZE = 15000;

const getPanelParameters = ({
  experimentKeys,
  fetchFull,
  metricName,
  projectId,
  paramName
}) => {
  const data = {
    fetchFull,
    independentMetrics: true,
    projectId,
    sampleSize: SCALAR_SAMPLE_SIZE,
    targetExperiments: experimentKeys
  };

  if (metricName) {
    data.metrics = [metricName];
  } else if (paramName) {
    data.params = [paramName];
  }

  return data;
};

const getNoPlotlyPanelData = async ({ data, type }) => {
  return cancelWrapper(async cancelToken => {
    const response = await axiosInstance({
      cancelToken,
      data,
      method: 'post',
      url: 'dashboard/plotly-multi-metric-chart'
    });

    return chartHelpers.toDataSources({
      data: response.data,
      experimentKeys: data.targetExperiments,
      type
    });
  });
};

export default function useScalarChartData(data, config) {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  const panelParameters = getPanelParameters({
    projectId,
    ...data
  });

  return useQuery(
    ['panel', { panelParameters, projectId, type: data.type }],
    () => getNoPlotlyPanelData({ data: panelParameters, type: data.type }),
    {
      ...config
    }
  );
}
