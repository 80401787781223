import React, { useCallback, useMemo } from 'react';
import find from 'lodash/find';
import noop from 'lodash/noop';
import cloneDeep from 'lodash/cloneDeep';

import useColumns from '@API/project/useColumns';
import useListFilters from '@experiment-management-shared/api/useListFilters';
import { useDispatch } from 'react-redux';
import useDeleteFilterMutation from '@experiment-management-shared/api/useDeleteFilterMutation';
import useCreateFilterMutation from '@experiment-management-shared/api/useCreateFilterMutation';
import useUpdateFilterMutation from '@experiment-management-shared/api/useUpdateFilterMutation';

import {
  extendColumnsWithTagSource,
  generateEmptyRulesTree
} from '@shared/utils/filterHelpers';

import { dialogTypes } from '@/constants/alertTypes';
import SaveViewModal from '@experiment-management-shared/components/SaveViewModal';
import alertsUtil from '@/util/alertsUtil';

const useFiltersLibraryManagement = ({
  filterId,
  rulesTree = generateEmptyRulesTree(),
  onChange = noop
}) => {
  const dispatch = useDispatch();

  const { data: columns, isLoading: isLoadingColumns } = useColumns();
  const { data: filters, isLoading: isLoadingFilters } = useListFilters();

  const deleteFilterMutation = useDeleteFilterMutation();
  const updateFilterMutation = useUpdateFilterMutation();
  const createFilterMutation = useCreateFilterMutation();

  const allColumns = useMemo(() => {
    if (!columns) return columns;

    return extendColumnsWithTagSource(columns);
  }, [columns]);

  const activeFilter = useMemo(() => {
    return find(filters, { filterId }) || {};
  }, [filterId, filters]);

  const handleSelectFilter = useCallback(
    (newFilterId, filter = null) => {
      onChange(
        newFilterId,
        filter?.rulesTree ? cloneDeep(filter.rulesTree) : undefined
      );
    },
    [onChange]
  );

  const handleSaveNewFilter = useCallback(
    async name => {
      const data = await createFilterMutation.mutateAsync({
        rulesTree,
        name
      });

      onChange(data.filterId, rulesTree);
    },
    [onChange, rulesTree, createFilterMutation]
  );

  const handleUpdateQuery = useCallback(() => {
    updateFilterMutation.mutate({
      rulesTree,
      name: activeFilter.name,
      filterId
    });
  }, [activeFilter.name, rulesTree, filterId, updateFilterMutation]);

  const handleSaveFilter = useCallback(() => {
    const modalId = dialogTypes.SAVE_EXPERIMENT_FILTER_SEGMENT_MODAL;
    const saveQueryModal = (
      <SaveViewModal
        entityType="filter"
        modalId={modalId}
        saveNewTemplateHandler={handleSaveNewFilter}
        templateName={activeFilter.name}
        templateId={activeFilter.filterId}
        updateTemplateHandler={handleUpdateQuery}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, saveQueryModal));
  }, [
    activeFilter.name,
    activeFilter.filterId,
    dispatch,
    handleSaveNewFilter,
    handleUpdateQuery
  ]);

  const handleDeleteFilter = useCallback(
    (filterName, filterIdForRemoval) => {
      const confirmHandler = () => {
        dispatch(alertsUtil.closeDialog(dialogTypes.DELETE_SEGMENT_LIST_ITEM));
        deleteFilterMutation.mutate({ filterId: filterIdForRemoval });

        if (activeFilter.filterId === filterIdForRemoval) {
          handleSelectFilter(null);
        }
      };

      dispatch(
        alertsUtil.openConfirmDialog(
          dialogTypes.DELETE_SEGMENT_LIST_ITEM,
          <span>
            Clicking continue will delete your filter named: <b>{filterName}</b>
            .
          </span>,
          confirmHandler
        )
      );
    },
    [activeFilter.filterId, dispatch, handleSelectFilter, deleteFilterMutation]
  );

  return {
    columns: allColumns,
    activeFilter,
    filters,
    isLoading: isLoadingColumns || isLoadingFilters,
    handleSaveFilter,
    handleDeleteFilter,
    handleSelectFilter
  };
};

export default useFiltersLibraryManagement;
