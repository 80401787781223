import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { trackEvent } from '@shared/utils/eventTrack';
import { emailEvents } from '@/constants/trackingEventTypes';

import userApi from '@/util/userApi';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes, dialogTypes } from '@/constants/alertTypes';

const useUserEmailUpdateMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    ({ newEmail, password }) => userApi.changeEmail({ newEmail, password }),
    {
      onSuccess: () => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_CHANGE_EMAIL,
            'Successfully changed email address. Check your new email address for confirmation.'
          )
        );
        trackEvent(emailEvents.EMAIL_CHANGED);
      },

      onError: error => {
        const errorMessage =
          error?.response?.data?.msg ||
          'An error occured trying to change your email address.';

        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_CHANGE_EMAIL,
            errorMessage
          )
        );
      }
    }
  );
};

export default useUserEmailUpdateMutation;
