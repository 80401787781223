import { useQuery } from 'react-query';

import api from '@API/api';

type FeatureDetailsParams = {
  modelId: string;
  featureName: string;
  featureSource: string;
  includeMetrics: boolean;
};

const getFeatureDetails = ({
  modelId,
  featureName,
  featureSource,
  includeMetrics
}: FeatureDetailsParams) =>
  api.get(
    `/mpm/v2/features/details?modelId=${modelId}&name=${featureName}&source=${featureSource}&includeMetrics=${includeMetrics}`
  );

export function useFeatureDetails({
  modelId,
  featureName,
  featureSource,
  includeMetrics
}: FeatureDetailsParams) {
  return useQuery(
    ['model-feature-details', { modelId, featureName, featureSource }],
    () =>
      getFeatureDetails({
        modelId,
        featureName,
        featureSource,
        includeMetrics
      }),
    {
      enabled: !!featureName && !!featureSource
    }
  );
}
