import { createSelector } from 'reselect';

import { biEventActionTypes } from '../../constants/actionTypes';

const initialState = {
  sentRenderedPanelIds: {}
};

export function panelsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case biEventActionTypes.ADD_SENT_RENDERED_PANEL:
      return {
        ...state,
        sentRenderedPanelIds: {
          ...state.sentRenderedPanelIds,
          [payload.id]: true
        }
      };

    default:
      break;
  }

  return state;
}

export const getBIEventsPanels = state => state?.biEvents?.panels;

export const getBIEventsSentRenderedPanels = createSelector(
  [getBIEventsPanels],
  biEventPanels => biEventPanels?.sentRenderedPanelIds
);
