import { OtherParam } from '@shared/types';
import { noop } from 'lodash';
import { Context, createContext, useContext } from 'react';

interface OtherSingleTableContextType {
  onResetOtherParam: (key: string) => void;
  onEditOtherParams: (key: string, row: OtherParam) => void;
  onDeleteOtherParam: (key: string) => void;
  onSubmitOtherParam: (key: string) => void;
}

export const OtherSingleTableContext: Context<OtherSingleTableContextType> = createContext<OtherSingleTableContextType>(
  {
    onResetOtherParam: noop,
    onEditOtherParams: noop,
    onDeleteOtherParam: noop,
    onSubmitOtherParam: noop
  }
);

export const useOtherSingleTableContext = (): OtherSingleTableContextType =>
  useContext<OtherSingleTableContextType>(OtherSingleTableContext);
