import { useQuery } from 'react-query';
import userApi from '@/util/userApi';

const getCurrentOptInMpmNotification = async () => {
  const isActiveNotification = await userApi.getOptInMpmNotification();
  return !!isActiveNotification?.data?.active;
};

export default function useOptInMpmNotifications() {
  return useQuery(['opt-in-mpm-notification'], getCurrentOptInMpmNotification);
}
