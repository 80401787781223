import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import TextTable from './TextTable';

const MultipleTextTable = ({
  assets,
  isFullHeight,
  experimentKeys,
  setAssetHeight,
  getAssetHeight
}) => {
  const experimentsAssets = useMemo(() => {
    return experimentKeys.map(experimentKey => {
      return assets.filter(asset => asset.experimentKey === experimentKey);
    });
  }, [assets, experimentKeys]);

  const tables = experimentKeys.map((experimentKey, index) => {
    return (
      <Grid key={experimentKey} xs={12 / experimentKeys.length} item>
        <TextTable
          assets={experimentsAssets[index]}
          isFullHeight={isFullHeight}
          experimentKey={experimentKey}
          setAssetHeight={setAssetHeight}
          getAssetHeight={getAssetHeight}
          hasMultipleExperiments={experimentKeys.length > 1}
        />
      </Grid>
    );
  });

  return (
    <Grid direction="column" container>
      <Grid container>{tables}</Grid>
    </Grid>
  );
};

MultipleTextTable.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFullHeight: PropTypes.bool.isRequired,
  experimentKeys: PropTypes.array.isRequired,
  setAssetHeight: PropTypes.func.isRequired,
  getAssetHeight: PropTypes.func.isRequired
};

export default MultipleTextTable;
