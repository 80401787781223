import React from 'react';
import TableEmptyState from '@shared/components/TableEmptyState';
import TableNoSearchResults from '@shared/components/TableNoSearchResults';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { EmptyTabCellProps } from './types';

type ComparisonEmptyStateProps = {
  searchValue: string;
  isLoading: boolean;
  resetSearch: () => void;
  emptyView: (materialProps: EmptyTabCellProps) => React.ReactNode;
};
const ComparisonEmptyState: React.FC<ComparisonEmptyStateProps> = ({
  searchValue,
  resetSearch,
  isLoading,
  emptyView,
  ...props
}) => {
  if (searchValue) {
    return (
      <TableNoSearchResults
        buttonText="Clear search"
        clickHandler={resetSearch}
        colSpan="10"
        className="metricsTableBorder"
      />
    );
  }

  if (isLoading) {
    return (
      <TableEmptyState
        pictureHeight="50px"
        pictureWidth="55px"
        picturePath="/images/empty-states/no-paramaters.svg"
        materialProps={props}
        label={'Loading metrics...'}
      />
    );
  }

  const cellProps = props as Table.DataCellProps;

  return <>{emptyView({ ...cellProps, isComparePage: true })}</>;
};

export default ComparisonEmptyState;
