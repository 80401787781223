import React, { useCallback, useEffect, useMemo } from 'react';

import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isNull from 'lodash/isNull';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import noop from 'lodash/noop';
import useDeepMemo from '@shared/hooks/useDeepMemo';

import {
  EMPTY_METRIC_DATA_OBJECT,
  AGGREGATION_TYPES_OBJECT,
  DEFAULT_CUSTOM_RANGE,
  ACTIVE_FETCH_INTERVAL,
  BUILT_IN_CHART_TYPES,
  CHART_ANNOTATIONS,
  OUTLIERS_VALUES
} from '@experiment-management-shared/constants/chartConstants';
import { STEP } from '@experiment-management-shared/constants/experimentConstants';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import { CHART_COLORS } from '@/lib/appConstants';
import {
  getLegendsKeysMap,
  getMetricNameFromDataSource,
  getTraceNameFromDataSource,
  isVisibleOnChart
} from '../../ChartHelpers';

import { OptimizedPlot } from '@DesignSystem/charts';
import ScatterChartTooltip from './ScatterChartTooltip';
import { useScatterChartTooltip } from './useScatterChartTooltip';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';
import usePanelSampleSize from '@experiment-management-shared/hooks/usePanelSampleSize';
import ChartContainer, {
  GeneralChartContainerProps
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import {
  Annotations,
  CustomRange,
  Experiment,
  LegendMode,
  PanelCometMetadataLegendKey,
  PanelGlobalConfig,
  PanelTrace,
  PanelType,
  SelectedOutliers
} from '@experiment-management-shared/types';
import useExperimentsPanelData from '@experiment-management-shared/api/useExperimentsPanelData';
import useChartDataSourceChangeNotificator from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataSourceChangeNotificator';
import useChartDataRevision from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataRevision';
import usePlotlyBaseChart from '@experiment-management-shared/components/Charts/Chart/chartHooks/usePlotlyBaseChart';
import { getExperimentsColorMap } from '@experiment-management-shared/utils/experimentHelpers';
import { getListAggregatedValue } from '@experiment-management-shared/utils';
import useChartTraceClick from '@experiment-management-shared/hooks/useChartTraceClick';
import { LEGEND_MODE } from '@experiment-management-shared/constants';
import LegendWrapper from '@experiment-management-shared/components/Charts/Legends/LegendWrapper';
import { Legend } from '@experiment-management-shared/components/Charts/Legends';
import useScatterChartLegend from '@experiment-management-shared/components/Charts/PlotlyChart/ScatterChart/useScatterChartLegend';
import {
  generateGuaranteeUniquenessFunction,
  generateLegendLabelForScatterChart,
  truncateLegendValueForExport
} from '@experiment-management-shared/components/Charts/Legends/helpers';

const MIN_SIZE = 3;
const MAX_SIZE = 30;

type ScatterDataSourceMetric = {
  metricName: string;
  values: Array<string | number | null>;
  timestamps: Array<string | number | null>;
  durations: Array<number | null>;
};

type ScatterDataSourceParam = {
  metricName: string;
  values: string[] | number[] | null[];
};

type ScatterDataSource = {
  experiment_key?: string;
  metrics: ScatterDataSourceMetric[];
  params: Record<string, string | number | null>;
};

type ScatterChartProps = {
  containerProps: GeneralChartContainerProps;
  isChartPreview?: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  hiddenExperimentKeys?: string[];
  isAutoRefreshEnabled: boolean;
  activeIntervalFetchDelay?: number;
  title?: string;
  chartName?: string;
  onChangeLegendMode?: never;
  onDataSourceChange?: () => void;
  onFinishRender?: () => void;
  enableDataRevision?: boolean;
  isPendingRendering?: boolean;
  dataRevision?: number;
  revision?: number;
  sampleSize?: number;
  sampleSizes?: never;
  handleSampleSizeChange?: never;
  fetchFull?: boolean;
  selectedLegendKeys: never;
  legendMode: LegendMode;
  hiddenMenuItems?: string[];
  chartClickEnabled?: boolean;
  customRange: CustomRange;
  customXAxisTitle: string;
  showXAxisTitle: boolean;
  showXAxisTickLabels: boolean;
  customYAxisTitle: string;
  showYAxisTitle: boolean;
  showYAxisTickLabels: boolean;
  params: string[];
  metrics: { name: string }[]; // looks like this param is not used by scatter chart now, but probably was used previously
  metricNames: string[];
  annotations: Annotations;
  selectedOutliers: SelectedOutliers;
  selectedXAxis: string;
  selectedYAxis: string;
  selectedZAxis: string | null;
  aggregationX: string;
  aggregationY: string;
  aggregationZ: string;
  panelGlobalConfig?: PanelGlobalConfig;
  locked?: boolean;
  showLock?: boolean;
  onLockClick?: unknown;
  globalConfigMap: Record<string, string[]>;
};
const ScatterChart = ({
  containerProps,
  isChartPreview = false,
  experiments,
  experimentKeys,
  hiddenExperimentKeys,
  isAutoRefreshEnabled,
  activeIntervalFetchDelay = ACTIVE_FETCH_INTERVAL,
  title,
  chartName = '',
  onChangeLegendMode,
  onDataSourceChange = noop,
  onFinishRender = noop,
  enableDataRevision = false,
  isPendingRendering = false,
  dataRevision = 0,
  revision = 0,
  sampleSize: panelSampleSize,
  sampleSizes,
  handleSampleSizeChange,
  fetchFull = false,
  selectedLegendKeys,
  legendMode = LEGEND_MODE.AUTO,
  hiddenMenuItems,
  chartClickEnabled = true,
  panelGlobalConfig,
  customXAxisTitle = '',
  showXAxisTitle = false,
  showXAxisTickLabels = true,
  customYAxisTitle = '',
  showYAxisTitle = false,
  showYAxisTickLabels = true,
  params,
  metrics,
  metricNames,
  annotations = CHART_ANNOTATIONS,
  customRange = DEFAULT_CUSTOM_RANGE,
  selectedOutliers = OUTLIERS_VALUES.SHOW as SelectedOutliers,
  selectedXAxis,
  selectedYAxis,
  selectedZAxis = null,
  aggregationX = AGGREGATION_TYPES_OBJECT.LAST,
  aggregationY = AGGREGATION_TYPES_OBJECT.LAST,
  aggregationZ = AGGREGATION_TYPES_OBJECT.LAST,
  locked = false,
  showLock,
  onLockClick,
  globalConfigMap
}: ScatterChartProps) => {
  const chartType: PanelType = BUILT_IN_CHART_TYPES[
    'BuiltIn/Scatter'
  ] as PanelType;
  const showLegend = legendMode === LEGEND_MODE.ON;

  const { sampleSize } = useMemo(() => {
    const extendedConfig = chartHelpers.extendConfigWithGlobalConfig(
      {
        sampleSize: panelSampleSize,
        locked,
        chartType
      },
      panelGlobalConfig,
      globalConfigMap
    );

    return {
      ...extendedConfig
    };
  }, [locked, chartType, panelSampleSize, panelGlobalConfig, globalConfigMap]);

  const {
    computedSampleSize,
    computedHandleSampleSizeChange
  } = usePanelSampleSize({
    sampleSize,
    sampleSizes,
    handleSampleSizeChange
  });

  const { shouldRefetch, actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    title,
    chartName,
    chartType,
    titleProps: {
      selectedXAxis,
      selectedYAxis,
      selectedZAxis
    }
  });

  const {
    data: panelData,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useExperimentsPanelData(
    {
      experimentKeys,
      fetchFull,
      metrics,
      metricNames,
      params,
      sampleSize: computedSampleSize,
      type: chartType
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );

  const rawDataSources = useDeepMemo(() => {
    if (isLoading) return [];

    const data = chartHelpers.toDataSources({
      data: panelData,
      experimentKeys,
      selectedXAxis,
      type: chartType
    });

    const localDataSources = chartHelpers.normalizeDataSource({
      dataSources: data,
      selectedXAxis,
      selectedYAxis,
      chartType
    });

    if (isArray(hiddenExperimentKeys)) {
      return chartHelpers.applyVisibilityOfExperiments(
        localDataSources,
        hiddenExperimentKeys
      );
    } else {
      return localDataSources;
    }
  }, [
    isLoading,
    chartType,
    panelData,
    selectedXAxis,
    selectedYAxis,
    experimentKeys,
    hiddenExperimentKeys
  ]);

  useChartDataSourceChangeNotificator({
    isLoading,
    dataSources: rawDataSources,
    onDataSourceChange
  });

  const {
    delayedDataSources: dataSources,
    hasDelayedDataSources,
    isInitialRenderDelayed,
    calculatedRevision
  } = useChartDataRevision<ScatterDataSource[]>({
    enableDataRevision,
    isPendingRendering,
    dataRevision,
    dataSources: rawDataSources,
    revision
  });

  const {
    setExportData,
    setExportLayout,
    containerRef,
    handleExportJSON,
    handleExportData,
    handleResetZoom,
    disableResetZoom,
    chartBaseLayout,
    PlotlyProps
  } = usePlotlyBaseChart({
    revision: calculatedRevision,
    isChartPreview,
    onFinishRender,
    selectedXAxis,
    title: actualTitle,
    chartType
  });

  const { xAxisTitle, yAxisTitle } = useMemo(() => {
    return chartHelpers.calculateScatterChartAxisTitles({
      showXAxisTitle,
      customXAxisTitle,
      selectedXAxis,
      showYAxisTitle,
      customYAxisTitle,
      selectedYAxis
    });
  }, [
    showXAxisTitle,
    customXAxisTitle,
    selectedXAxis,
    showYAxisTitle,
    customYAxisTitle,
    selectedYAxis
  ]);

  const experimentColorMap = useDeepMemo(
    () => getExperimentsColorMap(experiments),
    [experiments]
  );

  const legendKeysMap = useDeepMemo(() => {
    return getLegendsKeysMap(dataSources, experiments, selectedLegendKeys);
  }, [dataSources, experiments, selectedLegendKeys]);

  useEffect(() => {
    if (isError || isEmpty(dataSources)) {
      onFinishRender();
    }
  }, [dataSources, isError, isLoading, onFinishRender]);

  const {
    handlePointHover,
    handlePointUnHover,
    tooltipData
  } = useScatterChartTooltip();

  const { handleChartClick } = useChartTraceClick({
    hoveredPoint: tooltipData?.point,
    traceClickable: chartClickEnabled
  });

  const getAggregatedValueByAxis = useCallback(
    (values, axis) => {
      const selectedAggregations: Record<string, string> = {
        x: aggregationX,
        y: aggregationY,
        z: aggregationZ
      };

      const selectedAggregation = selectedAggregations[axis];

      if (isEmpty(values)) {
        return null;
      }

      if (isNull(selectedAggregation) || isString(values[0])) {
        return [last(values)];
      }

      return [getListAggregatedValue(values, selectedAggregation)];
    },
    [aggregationX, aggregationY, aggregationZ]
  );

  const getLineColor = useCallback(
    (index, dataSource) => {
      const primaryColor = CHART_COLORS[index % CHART_COLORS.length];

      const { experiment_key: experimentKey } = dataSource;

      return get(experimentColorMap, [experimentKey, 'primary'], primaryColor);
    },
    [experimentColorMap]
  );

  const getMetricsByAxis = useCallback(
    (dataSource: ScatterDataSource) => {
      const { metrics, params } = dataSource;

      const metricsByAxis: Record<
        string,
        ScatterDataSourceMetric | ScatterDataSourceParam
      > = {
        xAxisMetric: EMPTY_METRIC_DATA_OBJECT,
        yAxisMetric: EMPTY_METRIC_DATA_OBJECT,
        zAxisMetric: EMPTY_METRIC_DATA_OBJECT
      };

      metrics.forEach(metric => {
        if (metric.metricName === selectedXAxis) {
          metricsByAxis.xAxisMetric = metric;
        }

        if (metric.metricName === selectedYAxis) {
          metricsByAxis.yAxisMetric = metric;
        }

        if (metric.metricName === selectedZAxis) {
          metricsByAxis.zAxisMetric = metric;
        }
      });

      if (params && params.hasOwnProperty(selectedXAxis)) {
        metricsByAxis.xAxisMetric = {
          metricName: selectedXAxis,
          values: [params[selectedXAxis]]
        } as ScatterDataSourceParam;
      }

      if (params && params.hasOwnProperty(selectedYAxis)) {
        metricsByAxis.yAxisMetric = {
          metricName: selectedYAxis,
          values: [params[selectedYAxis]]
        } as ScatterDataSourceParam;
      }

      if (params && params.hasOwnProperty(selectedZAxis as string)) {
        metricsByAxis.zAxisMetric = {
          metricName: selectedZAxis,
          values: [params[selectedZAxis as string]]
        } as ScatterDataSourceParam;
      }

      return metricsByAxis;
    },
    [selectedXAxis, selectedYAxis, selectedZAxis]
  );

  const getPoints = useCallback(
    (xValues, yValues, zValues) => {
      const percentile = chartHelpers.calculatePercentile(selectedOutliers);

      if (percentile > 0) {
        return chartHelpers.getPointsWithOutliersRemoved({
          xValues,
          yValues,
          zValues,
          percentile
        });
      }

      return {
        x: xValues,
        y: yValues,
        z: zValues
      };
    },
    [selectedOutliers]
  );

  const getValues = useCallback(
    dataSource => {
      const { xAxisMetric, yAxisMetric, zAxisMetric } = getMetricsByAxis(
        dataSource
      );

      if (selectedXAxis === STEP) {
        return {
          xValues: dataSource.metrics[0].steps,
          yValues: yAxisMetric.values,
          zValues: zAxisMetric.values
        };
      }

      return {
        xValues: xAxisMetric.values,
        yValues: yAxisMetric.values,
        zValues: zAxisMetric.values
      };
    },
    [getMetricsByAxis, selectedXAxis]
  );

  const getValuesForDataSource = useCallback(
    dataSource => {
      const { xValues, yValues, zValues } = getValues(dataSource);
      const { x, y, z } = getPoints(xValues, yValues, zValues);

      const xValue = getAggregatedValueByAxis(x, 'x');
      const yValue = getAggregatedValueByAxis(y, 'y');
      const zValue = getAggregatedValueByAxis(z, 'z');

      return { xValue, yValue, zValue };
    },
    [getValues, getPoints, getAggregatedValueByAxis]
  );

  const data = useMemo(() => {
    const values = dataSources.map(dataSource => {
      return getValuesForDataSource(dataSource);
    });

    const maxZValue = Math.max(
      ...values.map(({ zValue }) => Math.max(...((zValue || []) as number[])))
    );

    return dataSources.map((dataSource, index) => {
      const metricName = getMetricNameFromDataSource(dataSource);
      const name = getTraceNameFromDataSource(dataSource);
      const { xValue, yValue, zValue } = values[index];

      const dataKeys: PanelCometMetadataLegendKey[] = [
        {
          title: selectedYAxis,
          value: first(yValue),
          axis: 'yaxis'
        },
        {
          title: selectedXAxis,
          value: first(xValue),
          axis: 'xaxis'
        }
      ];

      if (zValue !== null) {
        dataKeys.push({
          title: selectedZAxis as string,
          value: first(zValue)
        });
      }

      return {
        cometMetadata: {
          ...legendKeysMap[dataSource.experiment_key],
          dataKeys,
          metricName
        },
        type: 'scattergl',
        mode: 'markers',
        x: xValue,
        y: yValue,
        line: {
          color: getLineColor(index, dataSource),
          width: 1.5
        },
        visible: isVisibleOnChart(dataSource),
        name,
        showlegend: true,
        legendgroup: metricName,
        marker: {
          symbol: 'circle',
          size: zValue,
          sizemin: zValue ? MIN_SIZE : 6,
          sizemode: 'area',
          sizeref: maxZValue / (MAX_SIZE * MAX_SIZE)
        },
        hoverlabel: {
          namelength: -1
        }
      };
    });
  }, [
    dataSources,
    getLineColor,
    getValuesForDataSource,
    legendKeysMap,
    selectedXAxis,
    selectedYAxis,
    selectedZAxis
  ]);

  const layout = useMemo(
    () => ({
      ...chartBaseLayout,
      hovermode: 'closest',
      xaxis: {
        ...chartBaseLayout.xaxis,
        ...chartHelpers.generateAxisTitle(xAxisTitle),
        showticklabels: showXAxisTickLabels
      },
      yaxis: {
        ...chartBaseLayout.yaxis,
        ...chartHelpers.generateAxisTitle(yAxisTitle),
        showticklabels: showYAxisTickLabels
      }
    }),
    [
      chartBaseLayout,
      showXAxisTickLabels,
      showYAxisTickLabels,
      xAxisTitle,
      yAxisTitle
    ]
  );

  const downloadableLayout = useMemo(
    () => ({
      ...layout,
      margin: {
        ...layout.margin,
        b: 40,
        l: 40,
        r: 100,
        t: 16
      },
      showlegend: showLegend
    }),
    [layout, showLegend]
  );

  useEffect(() => {
    setExportLayout(downloadableLayout);
  }, [downloadableLayout, setExportLayout]);

  const annotationsMap = useMemo(() => {
    const isAnyAnnotations = Object.values(annotations).some(
      annotation => annotation.checked
    );

    const sortedByXValues = sortBy(data, ['x[0]']);
    if (isAnyAnnotations) {
      return sortedByXValues.reduce((map, dataPoint) => {
        const currentXValue = get(dataPoint, ['x', 0], null);
        const currentYValue = get(dataPoint, ['y', 0], null);

        if (map.has(currentXValue)) {
          const { min, max, values } = map.get(currentXValue);
          map.set(currentXValue, {
            min: Math.min(min, currentYValue),
            max: Math.max(max, currentYValue),
            values: [...values, currentYValue]
          });
        } else {
          map.set(currentXValue, {
            min: currentYValue,
            max: currentYValue,
            values: [currentYValue]
          });
        }

        return map;
      }, new Map());
    }
    return new Map();
  }, [annotations, data]);

  const activeAnnotationTraces = useMemo(() => {
    const activeAnnotations = Object.values(annotations).filter(
      annotation => annotation.checked
    );

    const baseTrace = {
      x: [...annotationsMap.keys()],
      mode: 'lines',
      type: 'scattergl'
    };

    const annotationValues = [...annotationsMap.values()];
    const getYValues: Record<string, number[]> = {
      MIN_Y: annotationValues.map(({ min }) => min),
      MAX_Y: annotationValues.map(({ max }) => max),
      AVG_Y: annotationValues.map(
        ({ values }) =>
          values.reduce((a: number, b: number) => a + b, 0) / values.length
      )
    };

    return activeAnnotations.map(({ name, label }) => {
      return {
        ...baseTrace,
        y: getYValues[name],
        name: label
      };
    });
  }, [annotations, annotationsMap]);

  const dataWithAnnotationTraces = useMemo(() => {
    if (isEmpty(activeAnnotationTraces)) return data;

    return [...data, ...activeAnnotationTraces];
  }, [activeAnnotationTraces, data]);

  useEffect(() => {
    const guaranteeUniqueness = generateGuaranteeUniquenessFunction();

    const exportData = data
      .filter(trace => trace?.visible !== 'legendonly')
      .map(trace => {
        if (!trace?.cometMetadata) return trace;

        return {
          ...trace,
          name: guaranteeUniqueness(
            truncateLegendValueForExport(
              generateLegendLabelForScatterChart(trace.cometMetadata)
            )
          )
        };
      });

    setExportData([...exportData, ...activeAnnotationTraces] as never);
  }, [data, activeAnnotationTraces, setExportData]);

  const {
    items,
    calculateIsItemHighlighted,
    onHoverItem,
    onUnhoverItem,
    dataWithHighlight
  } = useScatterChartLegend({
    data: dataWithAnnotationTraces as PanelTrace[],
    hoveredPoint: tooltipData?.point
  });

  const layoutWithAnnotations = useMemo(() => {
    if (isEmpty(activeAnnotationTraces)) return layout;

    const layoutAnnotations = activeAnnotationTraces.map(({ name, y }) => {
      return {
        xref: 'paper',
        x: 0.05,
        y: y[0],
        xanchor: 'right',
        yanchor: 'middle',
        text: name,
        showarrow: false,
        font: {
          family: 'Arial',
          size: 11,
          color: 'black'
        }
      };
    });

    return {
      ...layout,
      annotations: layoutAnnotations
    };
  }, [layout, activeAnnotationTraces]);

  return (
    <ChartContainer
      {...containerProps}
      isError={isError}
      isFetching={isFetching || hasDelayedDataSources}
      isLoading={isLoading || isInitialRenderDelayed}
      isPreviousData={isPreviousData}
      title={actualTitle}
      hasData={!isEmpty(dataSources)}
      hiddenMenuItems={hiddenMenuItems}
      locked={locked}
      showLock={showLock}
      onLockClick={onLockClick as never}
      onExportJSON={handleExportJSON}
      onExportData={handleExportData}
      onResetZoom={handleResetZoom}
      disableResetZoom={disableResetZoom}
      sampleSize={computedSampleSize}
      sampleSizes={sampleSizes}
      onChangeSampleSize={computedHandleSampleSizeChange}
      legendMode={legendMode}
      onChangeLegendMode={onChangeLegendMode}
      fullScreenType={'badges'}
    >
      <LegendWrapper
        legend={
          showLegend && (
            <Legend
              items={items}
              calculateIsItemHighlighted={calculateIsItemHighlighted}
              onHoverItem={onHoverItem}
              onUnhoverItem={onUnhoverItem}
            />
          )
        }
        chartContainerRef={containerRef as React.RefObject<HTMLDivElement>}
      >
        {tooltipData && <ScatterChartTooltip tooltipData={tooltipData} />}
        <OptimizedPlot
          {...PlotlyProps}
          customRange={customRange}
          data={dataWithHighlight}
          layout={layoutWithAnnotations}
          onClick={handleChartClick}
          onHover={handlePointHover}
          onUnhover={handlePointUnHover}
        />
      </LegendWrapper>
    </ChartContainer>
  );
};

ScatterChart.CONFIG_PROPERTIES = [
  'containerProps',
  'isChartPreview',
  'experiments',
  'experimentKeys',
  'hiddenExperimentKeys',
  'isAutoRefreshEnabled',
  'activeIntervalFetchDelay',
  'title',
  'chartName',
  'onChangeLegendMode',
  'onDataSourceChange',
  'onFinishRender',
  'enableDataRevision',
  'isPendingRendering',
  'dataRevision',
  'revision',
  'sampleSize',
  'sampleSizes',
  'handleSampleSizeChange',
  'fetchFull',
  'selectedLegendKeys',
  'hiddenMenuItems',
  'chartClickEnabled',
  'panelGlobalConfig',
  'customXAxisTitle',
  'showXAxisTitle',
  'showXAxisTickLabels',
  'customYAxisTitle',
  'showYAxisTitle',
  'showYAxisTickLabels',
  'params',
  'metrics',
  'metricNames',
  'annotations',
  'customRange',
  'selectedOutliers',
  'selectedXAxis',
  'selectedYAxis',
  'selectedZAxis',
  'aggregationX',
  'aggregationY',
  'aggregationZ',
  'locked',
  'showLock',
  'onLockClick',
  'globalConfigMap',
  'legendMode'
];
export default ScatterChart;
