import React, { useState } from 'react';
import { IconButton, List, ListItem } from '@ds';
import { DSDownloadIcon, DSMoreOptionsVerticalIcon } from '@ds-icons';
import Popover from '@material-ui/core/Popover';

import styles from './AudioTab.module.scss';

type AudioActionsCellProps = {
  link: string;
};

const TextCell = ({ link }: AudioActionsCellProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const renderMoreItemsMenu = () => {
    return (
      <Popover
        classes={{
          paper: styles.audioActionCellDropdown
        }}
        anchorEl={anchorEl}
        open
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <a href={link} onClick={() => setAnchorEl(null)}>
          <List>
            <ListItem>
              <ListItem.PrefixContainer>
                <ListItem.Icon Icon={<DSDownloadIcon />} />
              </ListItem.PrefixContainer>
              <ListItem.Text primary="Download file" />
            </ListItem>
          </List>
        </a>
      </Popover>
    );
  };

  return (
    <div className={styles.audioActionCell}>
      <IconButton
        onClick={e => setAnchorEl(e.currentTarget)}
        size="medium"
        Icon={<DSMoreOptionsVerticalIcon />}
        disabled={!link}
        type="secondary"
        active={!!anchorEl}
      />
      {!!anchorEl && renderMoreItemsMenu()}
    </div>
  );
};

export default TextCell;
