import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { useQuery } from 'react-query';

import { ProjectTemplate } from '@experiment-management-shared/types';
import { projectViewsStore } from '@projects/utils';
import api, { QueryConfig } from '@shared/api';
import useProject from './useProject';

const VIEW_VERSION = 3;

const getLocalViews = async ({ projectId }: { projectId: string }) => {
  const rows = await projectViewsStore.where({ projectId }).toArray();

  return rows.map(row => row.view);
};

const filterAndCombineViews = async (
  serverViews: ProjectTemplate[],
  localViews: ProjectTemplate[],
  projectId: string
) => {
  const removedViewIds = localViews
    .filter(localView => {
      return !serverViews.some(
        serverView => serverView.templateId === localView.templateId
      );
    })
    .map<[string, string]>(localView => [
      projectId,
      localView.templateId ?? 'new'
    ]);

  projectViewsStore.bulkDelete(removedViewIds);

  const matchedLocalViews = localViews.filter(localView => {
    return serverViews.some(
      serverView => serverView.templateId === localView.templateId
    );
  });

  return [...serverViews, ...matchedLocalViews];
};

const getProjectViews = async ({
  projectId,
  signal
}: {
  projectId: string;
  signal?: AbortSignal;
}) => {
  const { data: serverViews } = await api.get<ProjectTemplate[]>(
    'dashboard-templates/project/get-all',
    {
      params: { projectId, version: VIEW_VERSION },
      signal
    }
  );
  const normalizedServerViews = serverViews.map(view => {
    return mapKeys(view, (_, key) => camelCase(key)) as ProjectTemplate;
  });
  const localViews = await getLocalViews({ projectId });
  return filterAndCombineViews(normalizedServerViews, localViews, projectId);
};

export default function useProjectViews<SelectType = ProjectTemplate | null>(
  params: null,
  config: QueryConfig<ProjectTemplate[], SelectType>
) {
  const { data: project } = useProject();
  const projectId = project?.projectId;
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['projectViews', { projectId }],
    ({ signal }) => getProjectViews({ projectId, signal }),
    {
      ...config,
      enabled: configEnabled && !!projectId
    }
  );
}
