import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import uniqBy from 'lodash/uniqBy';

import { LegendItem } from '@experiment-management-shared/types';
import { LEGEND_ICON_MAP } from '@experiment-management-shared/constants';
import { Tooltip } from '@ds';
import { useObserveResizeNode } from '@shared/hooks';

import styles from './Legend.module.scss';

const checkNeedFadeEffect = (element?: HTMLElement | null) => {
  if (!element) return false;
  const FADE_EFFECT_THRESHOLD = 2;

  return (
    element.scrollHeight > element.clientHeight &&
    Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) >
      FADE_EFFECT_THRESHOLD
  );
};

const Legend = ({
  items,
  calculateIsItemHighlighted,
  onHoverItem,
  onUnhoverItem
}: {
  items: LegendItem[];
  calculateIsItemHighlighted: (item: LegendItem) => boolean;
  onHoverItem: (item: LegendItem) => void;
  onUnhoverItem: () => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasFadeEffect, setHasFadeEffect] = useState(false);

  useEffect(() => {
    setHasFadeEffect(checkNeedFadeEffect(containerRef.current));
  }, [containerRef]);

  useObserveResizeNode(node => setHasFadeEffect(checkNeedFadeEffect(node)))(
    containerRef.current
  );

  const handleScroll = useCallback((event: React.UIEvent<HTMLElement>) => {
    setHasFadeEffect(checkNeedFadeEffect(event.target as HTMLElement));
  }, []);

  // workaround to prevent caching in case legends items was supplied with identical key,
  const legendItems = uniqBy(items, i => i.key);

  return (
    <div
      ref={containerRef}
      className={cx(styles.legendContainer, {
        [styles.fadeEffect]: hasFadeEffect
      })}
      data-test="legend-container"
      onScroll={handleScroll}
    >
      {legendItems?.map(item => {
        const isHighlighted = calculateIsItemHighlighted(item);
        const LegendIcon = LEGEND_ICON_MAP[item.iconType];

        let label = <span>{item.label}</span>;

        if (item.fullLabel) {
          label = (
            <Tooltip
              size="small"
              arrow={false}
              content={item.fullLabel}
              wrapper={false}
            >
              {label}
            </Tooltip>
          );
        }

        return (
          <div
            onMouseEnter={() => onHoverItem(item)}
            onMouseLeave={() => onUnhoverItem()}
            className={cx(styles.legendItem, {
              [styles.highlighted]: isHighlighted
            })}
            key={item.key}
            style={
              {
                '--line-color': item.color
              } as React.CSSProperties
            }
          >
            <LegendIcon />
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
