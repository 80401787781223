import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { QUERY_PARAM_KEYS } from '../PanelConfigBar/useMetricChartQueryParams';
import { debounce } from 'lodash';

export const SEARCH_TEXT_DELAY = 300;

const usePanelsTabSearch = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  const [searchValue, setSearchValueAction] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState('');

  const debouncedSetSearch = debounce((v: string) => {
    setDebouncedSearchValue(v);
  }, SEARCH_TEXT_DELAY);

  const setSearchValue = (v: string) => {
    setSearchValueAction(v);
    debouncedSetSearch(v);
  };

  useEffect(() => {
    const defaultValue = queryParams.get(QUERY_PARAM_KEYS.SEARCH) ?? '';
    setSearchValueAction(defaultValue);
  }, []);

  useEffect(() => {
    setDebouncedSearchValue(searchValue);
  }, [searchValue]);

  const resetSearch = useCallback(() => {
    setSearchValueAction('');
    setDebouncedSearchValue('');
  }, []);

  return {
    searchValue,
    setSearchValue,
    debouncedSearchValue,
    resetSearch
  };
};

export default usePanelsTabSearch;
