import cx from 'classnames';
import { noop } from 'lodash';
import isEqual from 'fast-deep-equal';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import typography from '@ds-typography';
import {
  BUILT_IN_CHART_TYPES,
  DASHBOARD_PREVIEW_CHART_ID
} from '@experiment-management-shared/constants/chartConstants';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import { calculateLineTypeStrategy } from '@experiment-management-shared/utils/panel';
import {
  BarChartIcon,
  CurvesPanelIcon,
  DataPanelIcon,
  ImagePanelIcon,
  LineChartIcon,
  VideoPanelIcon,
  ParallelCoordinatesChartIcon,
  PCDPanelIcon,
  ScalarChartIcon
} from '@Icons-outdated';
import { getIsExperimentPage } from '@shared/utils/url';

import { DEFAULT_DASHBOARD_SAMPLE_SIZE } from '@/constants/configConstants';
import useBuiltInChart from '@experiment-management-shared/hooks/useBuiltInChart';
import { PythonEmptyStateIcon } from './icons';
import { AssetChartEmptyView } from '@experiment-management-shared/components/AddBuiltInVisualizationModal/subcomponents/AssetChartEmptyView';
const { isRequiredFieldsEmptyForChartType } = chartHelpers;

const DashboardChartPreview = React.memo(
  function DashboardChartPreviewMemo({
    chartId,
    chartForm,
    chartType,
    experimentKeys,
    hiddenExperimentKeys,
    experiments,
    isEditing,
    projectId,
    isComparePage,
    onPanelChange
  }) {
    const chartFormByType = chartForm[chartType];
    const isExperimentPage = getIsExperimentPage();

    const { selectedXAxis, selectedYAxis } = chartFormByType;

    // looks like one more migration, but it has no sense because in panel itself it is now happening, only for this preview
    const metricNames = useMemo(() => {
      return chartType === 'bar' ? [selectedXAxis] : [selectedYAxis];
    }, [chartType, selectedXAxis, selectedYAxis]);

    const builtInChart = useBuiltInChart();

    const containerProps = useMemo(() => {
      return {
        chartId,
        chartType,
        experimentKeys,
        hideHeader: true,
        projectId
      };
    }, [chartId, chartType, experimentKeys, projectId]);

    const createChartByChartType = () => {
      const chartConfig = {
        chartType,
        metricNames,
        sampleSize: DEFAULT_DASHBOARD_SAMPLE_SIZE,
        ...chartFormByType,
        chartId,
        experimentKeys,
        experiments,
        isChartPreview: true,
        isEditing,
        hiddenExperimentKeys,
        chartClickEnabled: false
      };

      if (isExperimentPage) {
        chartConfig.lineTypeStrategy = calculateLineTypeStrategy({
          isComparePage
        });
      }

      return builtInChart.getComponent({
        chartType,
        chartConfig,
        config: {
          containerProps,
          onPanelChange,
          hideVideo: true
        }
      });
    };

    const getEmptyPlaceholderByChartType = () => {
      const placeholder = {
        [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: {
          text: 'Select a y-axis metric to preview chart',
          icon: <LineChartIcon />
        },
        [BUILT_IN_CHART_TYPES['BuiltIn/Scatter']]: {
          text: 'Select an x and y axis value to preview chart',
          icon: <LineChartIcon />
        },
        [BUILT_IN_CHART_TYPES['BuiltIn/Bar']]: {
          text: 'Select an x-axis metric to preview chart',
          icon: <BarChartIcon />
        },
        [BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']]: {
          text: !chartForm.parallel.selectedTargetVariable
            ? 'Select a target variable'
            : 'Select at least two y-axis metrics to preview chart',
          icon: <ParallelCoordinatesChartIcon />
        },
        [BUILT_IN_CHART_TYPES.scalar]: {
          text: 'Select a value to preview chart',
          icon: <ScalarChartIcon />
        },
        [BUILT_IN_CHART_TYPES.image]: {
          text: (
            <AssetChartEmptyView
              label="Select images to create the grid"
              docLink="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentlog_image"
              entityName="an image"
              methodName="log_image"
            />
          ),
          icon: <ImagePanelIcon />
        },
        [BUILT_IN_CHART_TYPES.video]: {
          text: (
            <AssetChartEmptyView
              label="Select videos to create the grid"
              docLink="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentlog_video"
              entityName="a video"
              methodName="log_video"
            />
          ),
          icon: <VideoPanelIcon />
        },
        [BUILT_IN_CHART_TYPES.pcd]: {
          text: (
            <AssetChartEmptyView
              label="Select 3D assets to create the grid"
              docLink="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentlog_points_3d"
              entityName="a 3d asset"
              methodName="log_points_3d"
            />
          ),
          icon: <PCDPanelIcon />
        },
        [BUILT_IN_CHART_TYPES.curves]: {
          text: (
            <AssetChartEmptyView
              label="Select a curve to display"
              docLink="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentlog_curve"
              entityName="a curve"
              methodName="log_curve"
            />
          ),
          icon: <CurvesPanelIcon />
        },
        [BUILT_IN_CHART_TYPES.data]: {
          text: (
            <AssetChartEmptyView
              label="Select data to preview the table"
              docLink="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentlog_table"
              entityName="a table"
              methodName="log_table"
            />
          ),
          icon: <DataPanelIcon />
        },
        [BUILT_IN_CHART_TYPES.python]: {
          text: (
            <div
              className={cx(
                typography.dsLightXS,
                'preview-empty-state-text',
                'python-panel-preview-empty-state'
              )}
            >
              <div className="preview-empty-state-description">
                Press the ‘Run code’ button in the top right corner of the code
                editor to view your data
              </div>
              <div className="preview-empty-state-description">
                <div>
                  You can learn more about{' '}
                  <a
                    href="/docs/v2/guides/comet-dashboard/code-panels/python-panels/"
                    target="_blank"
                  >
                    Python Panels
                  </a>{' '}
                  in our documentation
                </div>
              </div>
            </div>
          ),
          icon: <PythonEmptyStateIcon />
        }
      };

      return placeholder[chartType];
    };

    const emptyChartView = getEmptyPlaceholderByChartType();
    const isRequiredFieldsEmpty = isRequiredFieldsEmptyForChartType(
      chartType,
      chartForm[chartType]
    );

    return (
      <div
        className={cx('dashboard-chart-preview', {
          'without-margin': isRequiredFieldsEmpty
        })}
      >
        {isRequiredFieldsEmpty ? (
          <div className="preview-empty">
            {emptyChartView.icon}
            <div
              className={cx(typography.dsLightXS, 'preview-empty-state-text')}
            >
              {emptyChartView.text}
            </div>
          </div>
        ) : (
          createChartByChartType()
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  }
);

DashboardChartPreview.defaultProps = {
  experimentKeys: [],
  hiddenExperimentKeys: null,
  isEditing: false,
  chartId: DASHBOARD_PREVIEW_CHART_ID,
  isComparePage: false,
  onPanelChange: noop
};

DashboardChartPreview.propTypes = {
  chartId: PropTypes.string,
  chartType: PropTypes.string.isRequired,
  chartForm: PropTypes.object.isRequired,
  experimentKeys: PropTypes.arrayOf(PropTypes.string),
  hiddenExperimentKeys: PropTypes.array,
  experiments: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  isComparePage: PropTypes.bool,
  onPanelChange: PropTypes.func
};

export default DashboardChartPreview;
