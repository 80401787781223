import { useQuery } from 'react-query';
import billingApi from '@/util/billingApi';

const mapBillingDetails = billingDetails => {
  return {
    organizationId: billingDetails?.organizationId,
    id: billingDetails?.id,
    creditCard: {
      id: billingDetails?.creditCard?.id,
      last4: billingDetails?.creditCard?.last4,
      expMonth: billingDetails?.creditCard?.exp_month,
      expYear: billingDetails?.creditCard?.exp_year
    },
    invoices: billingDetails?.invoices,
    adminMembersCount: billingDetails.adminMembersCount,
    pendingMembersCount: billingDetails?.pendingMembersCount,
    allMembersCount: billingDetails?.allMembersCount,
    paymentPlanName: billingDetails?.paymentPlanName,
    startedAt: billingDetails?.startedAt,
    nextPaymentDate: billingDetails?.nextPaymentDate,
    estimatedNextInvoiceToPay: billingDetails?.estimatedNextInvoiceToPay,
    currentUsageHours: billingDetails?.currentUsageHours,
    seatsCount: billingDetails?.seatsCount,
    viewOnlyMembersCount: billingDetails?.viewOnlyMembersCount || 0
  };
};
const getBillingDetails = async organizationId => {
  if (!organizationId) {
    return null;
  }

  const response = await billingApi.fetchBilling(organizationId);
  return mapBillingDetails(response.data);
};

const useOrganizationBilling = (organizationId, config = {}) => {
  return useQuery(
    ['organizations', organizationId, 'billing'],
    () => getBillingDetails(organizationId),
    config
  );
};

export default useOrganizationBilling;
