import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import EmptyExperimentTab from '../EmptyExperimentTab/EmptyExperimentTab';

type EmptyTableMessageProps = {
  message: string;
} & Table.DataCellProps;
const EmptyTableMessage: React.FC<EmptyTableMessageProps> = ({
  message,
  ...cellProps
}) => {
  return (
    <EmptyExperimentTab.Cell {...cellProps}>
      <EmptyExperimentTab>
        <EmptyExperimentTab.Title>{message}</EmptyExperimentTab.Title>
      </EmptyExperimentTab>
    </EmptyExperimentTab.Cell>
  );
};

export default EmptyTableMessage;
