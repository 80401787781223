import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import currency from 'currency.js';

import { StyledTooltip } from '@DesignSystem/data-display';
import { Table } from '@DesignSystem/tables';
import { DownloadIcon } from '@Icons-outdated';
import { DSColors } from '@design-system-outdated/constants';
import { useActiveOrganizationPaymentDetails } from '@account-settings/api';
import SmallLoader from '@shared/components/SmallLoader';

import TableEmptyState from '@shared/components/TableEmptyState';

const columns = [
  { name: 'id', title: 'Id' },
  { name: 'date', title: 'Date' },
  { name: 'description', title: 'Description' },
  { name: 'total', title: 'Total' },
  { name: 'export', title: ' ' }
];

const dataTypes = [
  {
    cols: ['id'],
    cell: ({ value }) => {
      return <p className="invoice-id-column">{value}</p>;
    }
  },
  {
    cols: ['export'],
    cell: ({ row }) => {
      return (
        <StyledTooltip
          title="Download PDF"
          type="base"
          showTooltip
          placement="top-end"
        >
          <a
            href={row.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-pdf-icon"
          >
            <DownloadIcon />
          </a>
        </StyledTooltip>
      );
    }
  }
];

export default function Invoices() {
  const {
    data: billingDetails,
    isLoading
  } = useActiveOrganizationPaymentDetails();
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'export', width: 80 },
    { columnName: 'total', width: 100 }
  ]);

  const rows = useMemo(
    () =>
      billingDetails?.invoices.map(invoice => ({
        date: moment(invoice.date).format('D MMM YYYY'),
        description: invoice.description,
        total: currency(invoice.total).format(true),
        id: invoice.id,
        fileUrl: invoice.fileUrl
      })),
    [billingDetails?.invoices]
  );

  const renderEmptyState = useCallback(props => {
    return (
      <TableEmptyState
        label="No invoices were sent yet"
        materialProps={props}
        pictureHeight="56px"
        pictureWidth="64.4px"
        picturePath="/images/account-settings/no-invoices.svg"
      />
    );
  }, []);

  return !isLoading ? (
    <div className="invoices-container">
      <Table
        columns={columns}
        dataTypes={dataTypes}
        rowHeight="60px"
        rowIdKey="uniqueName"
        rows={rows || []}
        paginationConfig={{
          isDisabled: true
        }}
        selectionConfig={{
          isDisabled: true
        }}
        header={{
          headerColor: DSColors.grayColor1
        }}
        maxHeight={333}
        isFetching={false}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          onColumnWidthsChange: setColumnWidths
        }}
        renderEmptyState={renderEmptyState}
      />
    </div>
  ) : (
    <SmallLoader
      primaryMessage="Loading..."
      secondaryMessage="Loading invoices"
    />
  );
}
