import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import api from '@shared/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const removeExperimentMetric = async ({ experimentKey, metricName }) => {
  const { data } = await api.delete('experiment/delete/metric', {
    params: {
      experimentKey,
      metricName
    }
  });

  return data;
};

export default function useRemoveExperimentMetricMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ experimentKey, metricName }) => {
      return removeExperimentMetric({
        experimentKey,
        metricName
      });
    },
    {
      onMutate: async ({ experimentKey }) => {
        await queryClient.cancelQueries([
          'experiment-metrics',
          { experimentKey }
        ]);
      },
      onSuccess: async (data, { experimentKey }) => {
        await queryClient.invalidateQueries([
          'experiment-metrics',
          { experimentKey }
        ]);
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error removing this metric.'
          )
        );
      }
    }
  );
}
