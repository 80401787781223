import axios from 'axios';
import { BASE_API_URL } from '@/constants/configConstants';
import { useQuery } from 'react-query';
import { OrganizationUserRole } from '@shared/types';

interface UseOrganizationRolesConfig {
  refetchOnMount?: boolean;
}

interface GetOrganizationUserRolesResponse {
  roles: OrganizationUserRole[];
}

const DEFAULT_ROLES: OrganizationUserRole[] = ['ADMIN', 'MEMBER'];

const getOrganizationUserRoles = async (
  organizationId: string,
  signal: AbortSignal | undefined
): Promise<string[]> => {
  try {
    const response = await axios.get<GetOrganizationUserRolesResponse>(
      `${BASE_API_URL}organizations/${organizationId}/roles`,
      { signal }
    );

    return response.data?.roles;
  } catch {
    return DEFAULT_ROLES;
  }
};
const useOrganizationUserRoles = (
  organizationId: string,
  config: UseOrganizationRolesConfig = {}
) => {
  return useQuery(
    ['organization-user-roles', organizationId],
    ({ signal }) => getOrganizationUserRoles(organizationId, signal),
    config
  );
};

export default useOrganizationUserRoles;
