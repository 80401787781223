import { useMemo } from 'react';

type Annotation = {
  label: string;
  score: number;
};

export const useVisibleAnnotations = ({
  annotations,
  hiddenLabels,
  score
}: {
  annotations?: Annotation[];
  hiddenLabels?: string[];
  score: number;
}) => {
  return useMemo(() => {
    if (!annotations?.length) return [];

    const isActive = (annotation: Annotation) => {
      if (hiddenLabels === undefined) return true;
      return !hiddenLabels.includes(annotation.label);
    };

    const isPassScoreLimit = (annotation: Annotation) =>
      annotation?.score ? annotation.score >= score : true;

    return annotations.filter(
      annotation => isActive(annotation) && isPassScoreLimit(annotation)
    );
  }, [annotations, hiddenLabels, score]);
};
