import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './Tabs.module.scss';
import { LLMImagesTabProps } from '../types/SidebarTabProps';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { DSArrowLeftIcon } from '@ds-icons';
import { IconButton } from '@ds';

const isBase64 = (str: string) => {
  if (!str) return false;
  return str.startsWith('data:image/');
};

export default function LLMImagesTab({ images }: LLMImagesTabProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentImageIndex, setCurrentImageIndexIndex] = React.useState(0);

  const isLastImage = useMemo(() => currentImageIndex === images.length - 1, [
    currentImageIndex,
    images.length
  ]);

  const isFirstImage = useMemo(() => currentImageIndex === 0, [
    currentImageIndex
  ]);

  const onPrev = useCallback(() => {
    if (!isFirstImage) {
      setCurrentImageIndexIndex(currentImageIndex - 1);
    }
  }, [currentImageIndex, isFirstImage]);

  const onNext = useCallback(() => {
    if (!isLastImage) {
      setCurrentImageIndexIndex(currentImageIndex + 1);
    }
  }, [currentImageIndex, isLastImage]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onPrev();
      }
      if (event.key === 'ArrowRight') {
        onNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onNext, onPrev]);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const renderAssetContainer = ({ imageUrl }: { imageUrl: string }) => {
    return (
      <div className={styles.imageViewContainer}>
        <IconButton
          className={styles.previousArrow}
          aria-label="Previous"
          disabled={isFirstImage}
          onClick={onPrev}
          Icon={<DSArrowLeftIcon />}
        />
        <img
          loading="lazy"
          className={styles.imageWrapper}
          src={imageUrl}
          alt={imageUrl}
        />
        <IconButton
          className={styles.nextArrow}
          aria-label="Next"
          disabled={isLastImage}
          onClick={onNext}
          Icon={<DSArrowLeftIcon />}
        />
      </div>
    );
  };

  const imageTitle = useMemo(() => {
    return isBase64(images[currentImageIndex])
      ? 'Image'
      : images[currentImageIndex];
  }, [currentImageIndex, images]);

  return (
    <>
      <div className={styles.imageTabContainer}>
        <div className={styles.imageGrid}>
          {images.map((imageUrl, index) => {
            const onImageClick = () => {
              setCurrentImageIndexIndex(index);
              setIsModalOpen(true);
            };

            return (
              // eslint-disable-next-line react/jsx-key
              <div onClick={onImageClick} className={styles.imageContainer}>
                <img src={imageUrl} loading="lazy" alt={`image-${index}`} />
              </div>
            );
          })}
        </div>
      </div>
      <FullWidthBasicModal
        data-test="llm-image-tab-modal"
        open={isModalOpen}
        onClose={onModalClose}
        variant="base"
        content={renderAssetContainer({
          imageUrl: images[currentImageIndex] ?? ''
        })}
        withoutFooter={false}
        customHeaderContent={undefined}
        className="llm-image-tab-modal"
        // TODO: DELETE props below later after ts migration main Component
        title={imageTitle}
        primaryButtonText={undefined}
        onPrimaryButtonClick={undefined}
        secondaryButtonText={undefined}
        stepActiveIndex={undefined}
        stepAmount={undefined}
        onSecondaryButtonClick={undefined}
        isPrimaryButtonDisabled={undefined}
        isPrimaryButtonLoading={undefined}
        footer={undefined}
        withBackButton={undefined}
        onBackButtonClick={undefined}
        headerTitle={undefined}
        titleMaskTest={undefined}
      />
    </>
  );
}
