import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Fab } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { animated, useSpring } from 'react-spring';

import { Tooltip } from '@ds';
import { snackbarTypes } from '@/constants/alertTypes';
import alertUtils from '@/util/alertsUtil';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import {
  ArrowTightIcon,
  BackCircleIcon,
  CopyIcon,
  DownloadIcon
} from '@Icons-outdated';

import typography from '@ds-typography';

import { useCarrousel } from './useCarrousel';
import styles from './GraphicsDetailsModal.module.scss';
import { useExperimentName } from '@experiment-management-shared/hooks';
import { BASE_API_URL } from '@/constants/configConstants';

export const COLLAPSED_MENU_WIDTH = '25px';
export const EXPANDED_MENU_WIDTH = '340px';

export type GraphicDetailsAsset = {
  experimentKey: string;
  name: string;
  id: string;
  thumbnailPath: string;
  experimentName?: string;
};

export type GraphicsDetailsModalProps<TAsset extends GraphicDetailsAsset> = {
  assets: TAsset[];
  onChange?: (asset: TAsset) => void;
  onClose: () => void;
  renderAssetViewer: ({ asset }: { asset: TAsset }) => React.JSX.Element;
  sections: React.ReactElement | null;
  selectedAsset: TAsset;
};

const ABSOLUTE_BASE_API_URL = new URL(
  BASE_API_URL || '',
  window.location.origin
).href;

export const GraphicsDetailsModal = <TAsset extends GraphicDetailsAsset>({
  assets,
  onChange,
  onClose,
  renderAssetViewer,
  sections,
  selectedAsset
}: GraphicsDetailsModalProps<TAsset>) => {
  const selectedIndex = assets.indexOf(selectedAsset);
  const { item: assetItem, isFirst, isLast, goNext, goPrevious } = useCarrousel(
    {
      items: assets,
      selectedIndex
    }
  );

  const asset = assetItem ?? selectedAsset;

  const { data: experimentNames } = useExperimentName({
    experimentKeys: [asset.experimentKey]
  });

  const experimentName = experimentNames?.[0] || '';

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const collapseAnimationProps = useSpring({
    width: isMenuCollapsed ? COLLAPSED_MENU_WIDTH : EXPANDED_MENU_WIDTH
  });
  const dispatch = useDispatch();

  const downloadURL = `${ABSOLUTE_BASE_API_URL}asset/download?experimentKey=${asset.experimentKey}&assetId=${asset.id}`;

  const arrowAnimationProps = useSpring({
    transform: isMenuCollapsed ? 'rotate(1turn)' : 'rotate(0turn)',
    left: isMenuCollapsed ? 20 - 8 : 340 - 15
  });

  const handleCopyClick = () => {
    dispatch(
      alertUtils.openSnackbarDialog(
        snackbarTypes.COPY_IMAGE_PATH_TO_CLIPBOARD,
        `Successfully copied asset path to clipboard`
      )
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        goPrevious();
      }
      if (event.key === 'ArrowRight') {
        goNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [goNext, goPrevious]);

  useEffect(() => {
    onChange?.(asset);
  }, [asset, onChange]);

  const renderSidebar = () => {
    return (
      <animated.div
        className={cx(styles.sidebar, {
          [styles.collapsed]: isMenuCollapsed
        })}
        style={collapseAnimationProps}
      >
        <div className={styles.sidebarSections}>{sections}</div>
      </animated.div>
    );
  };

  const renderAssetContainer = () => {
    const renderAssetActions = () => {
      return (
        <div className={styles.assetActions}>
          <div className={styles.iconsContainer}>
            <CopyToClipboard onCopy={handleCopyClick} text={downloadURL}>
              <Tooltip content="Copy asset path">
                <div className={styles.iconWrapper}>
                  <CopyIcon />
                </div>
              </Tooltip>
            </CopyToClipboard>
            <Tooltip content="Download asset">
              <a className={styles.iconWrapper} href={downloadURL} download>
                <DownloadIcon width="100%" height="100%" viewBox="0 0 18 19" />
              </a>
            </Tooltip>
          </div>
        </div>
      );
    };

    return (
      <div className={styles.contentWrapper}>
        {renderAssetActions()}
        <div className={styles.assetViewContainer}>
          <Fab
            className={styles.previousArrow}
            size="small"
            aria-label="Previous"
            disabled={isFirst}
            onClick={goPrevious}
          >
            <BackCircleIcon />
          </Fab>
          <div className={styles.assetContainer}>
            {renderAssetViewer({ asset })}
          </div>
          <Fab
            className={styles.nextArrow}
            size="small"
            aria-label="Next"
            disabled={isLast}
            onClick={goNext}
          >
            <BackCircleIcon />
          </Fab>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.content}>
        {renderSidebar()}

        <div className={styles.toggleButtonWrapper}>
          <animated.div
            className={cx(styles.toggleButton, {
              [styles.toggleButtonCollapsed]: isMenuCollapsed
            })}
            style={arrowAnimationProps}
            onClick={() => setIsMenuCollapsed(isCollapsed => !isCollapsed)}
          >
            <ArrowTightIcon />
          </animated.div>
        </div>

        {renderAssetContainer()}
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <span>
        {asset.name || 'Preview'}{' '}
        {experimentName && (
          <span
            className={cx(styles.titleExperimentName, typography.dsBodySmall)}
          >
            {experimentName}
          </span>
        )}
      </span>
    );
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FullWidthBasicModal
        className={styles.modal}
        content={renderContent()}
        data-test="graphics-preview-modal"
        disableRestoreFocus
        onClose={onClose}
        open
        PaperProps={{ className: styles.imagePreviewPaper }}
        transitionDuration={0}
        title={renderTitle()}
        variant="base"
        withoutFooter
      />
    </>
  );
};

export default GraphicsDetailsModal;
