import { useQuery } from 'react-query';
import assetsApi from '@/util/assetsApi';

const getExperimentAssets = async (experimentKey, type, signal) => {
  const { data } = await assetsApi.fetchAssetsForExperiment(
    experimentKey,
    type,
    signal
  );

  return data || [];
};

const useExperimentAssets = (experimentKey, config, type = 'model-element') => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['modelRegistry', { experimentKey }, 'experimentsAssets'],
    ({ signal }) => getExperimentAssets(experimentKey, type, signal),
    {
      ...config,
      enabled: !!experimentKey && configEnabled
    }
  );
};

export default useExperimentAssets;
