import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import workspaceApi from '../../util/workspaceApi';
import { teamsActionTypes } from '../../constants/actionTypes';
import { useCurrentOrganization } from '@shared/hooks';

const useUpdateDefaultWorkspaceMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const currentOrganization = useCurrentOrganization();

  return useMutation(
    workspaceId => workspaceApi.setUsersDefaultWorkspace(workspaceId),
    {
      onSuccess: (res, workspaceId) => {
        dispatch({
          type: teamsActionTypes.COMPLETED_USER_DEFAULT_TEAM_SET,
          payload: {
            teamId: workspaceId
          }
        });

        queryClient.invalidateQueries(['workspaces', 'all']);

        queryClient.invalidateQueries(
          ['organizations', currentOrganization?.id],
          { active: true }
        );

        queryClient.resetQueries(['organizations', currentOrganization?.id], {
          inactive: true
        });
      }
    }
  );
};

export default useUpdateDefaultWorkspaceMutation;
