import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import api from '@shared/api';
import { useCombinedQueries } from '@shared/hooks';
import { ExperimentDetails, OtherParam } from '@shared/types';
import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';

type UseExperimentsMetricsOptions = {
  experiments: ExperimentDetails[];
};

const getExperimentsOthers = async ({
  experimentKey,
  signal
}: {
  experimentKey: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get<{ logOtherList: OtherParam[] }>(
    'data-fetch/get/log-other',
    {
      params: { experimentKey },
      signal
    }
  );

  return data.logOtherList;
};

export default function useExperimentsOthers(
  { experiments }: UseExperimentsMetricsOptions,
  config: object = {}
) {
  const queries = experiments.map<UseQueryOptions<OtherParam[], AxiosError>>(
    experiment => {
      const { experimentKey, runActive: isActive } = experiment;
      return {
        queryKey: ['experiment-others', { experimentKey }],
        queryFn: ({ signal }) => {
          return getExperimentsOthers({
            experimentKey,
            signal
          });
        },
        enabled: !!experimentKey,
        refetchOnMount: true,
        refetchInterval: isActive ? FETCH_EXPERIMENT_POLLING_LENGTH : false,
        ...config
      };
    }
  );

  const { data = [], isLoading } = useCombinedQueries(queries);

  return {
    isLoading,
    data: data as OtherParam[][]
  };
}
