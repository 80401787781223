import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { snackbarTypes } from '../../../constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';
import projectsApi from '@/util/projectsApi';

export default function useMoveProjectMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const workspaceName = useWorkspaceName();

  return useMutation(projectsApi.updateProject, {
    onMutate: async () => {
      await queryClient.cancelQueries(['projects', { workspaceName }]);
    },
    onSuccess: async response => {
      await queryClient.invalidateQueries(['projects', { workspaceName }]);
      await queryClient.invalidateQueries(['workspaces', 'all']);
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_MOVED_PROJECT_TO_OTHER_WORKSPACE,
          `You have successfully moved project to "${response?.data?.data?.teamName}" workspace`
        )
      );
    },
    onError: error => {
      dispatch(
        alertsUtil.openErrorDialog(
          snackbarTypes.ERROR_MOVED_PROJECT_TO_OTHER_WORKSPACE,
          error?.response?.data?.msg
        )
      );
    }
  });
}
