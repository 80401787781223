import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import { CONFUSION_MATRIX_TYPES } from '@experiment-management-shared/constants/chartConstants';

import cx from 'classnames';
import { TextButton } from '@ds';
import ImageSamples from './exampleTypes/ImageSamples';
import IntegerSamples from './exampleTypes/IntegerSamples';
import LinkSamples from './exampleTypes/LinkSamples';
import StringSamples from './exampleTypes/StringSamples';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

import styles from './ConfusionMatrixExample.module.scss';

const LIST_COMPONENT_BY_TYPE = {
  [CONFUSION_MATRIX_TYPES.IMAGE]: ImageSamples,
  [CONFUSION_MATRIX_TYPES.INTEGER]: IntegerSamples,
  [CONFUSION_MATRIX_TYPES.LINK]: LinkSamples,
  [CONFUSION_MATRIX_TYPES.STRING]: StringSamples
};

const IMAGE_SAMPLE_KEYS = ['sample', 'assetId', 'index'];

const ConfusionMatrixExample = ({
  isHighlighted,
  actualValue,
  experimentKey,
  onClose,
  predictedValue,
  samples,
  type
}) => {
  const dispatch = useDispatch();

  const inferredType = useMemo(() => {
    if (!samples || !samples.length) return type;
    const isImage =
      isEqual(Object.keys(samples[0]), IMAGE_SAMPLE_KEYS) &&
      samples[0]?.assetId;
    return isImage ? CONFUSION_MATRIX_TYPES.IMAGE : type;
  }, [samples, type]);

  const renderSampleList = () => {
    if (!samples) {
      return <div className={styles.empty}>No example discovered</div>;
    }

    const handleCopyClick = () => {
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_COPIED_TEXT,
          'Successfully copied content to clipboard'
        )
      );
    };

    const orderedSamples =
      inferredType === CONFUSION_MATRIX_TYPES.INTEGER
        ? sortBy(samples)
        : sortBy(samples, 'index');
    const SampleList = LIST_COMPONENT_BY_TYPE[inferredType];
    const ListProps = {
      actualValue,
      experimentKey,
      predictedValue,
      samples: orderedSamples,
      onCopyClick: handleCopyClick
    };

    return <SampleList {...ListProps} />;
  };

  const renderExampleLabel = () => {
    const isCorrectlyClassified = actualValue === predictedValue;
    const label = isCorrectlyClassified
      ? 'Correctly classified as'
      : 'Misclassified as';

    return (
      <div
        className={cx(styles.title, {
          [styles.correct]: isCorrectlyClassified
        })}
      >
        {`${actualValue} ${label} ${predictedValue}`}
      </div>
    );
  };

  return (
    <div
      className={cx(styles.confusionMatrixExample, {
        [styles.highlighted]: isHighlighted
      })}
    >
      <div className={styles.header}>
        {renderExampleLabel()}
        <TextButton size="small" onClick={onClose}>
          Close
        </TextButton>
      </div>
      <div className={styles.body}>{renderSampleList()}</div>
    </div>
  );
};

ConfusionMatrixExample.defaultProps = {
  isHighlighted: false,
  type: null,
  actualValue: '',
  experimentKey: '',
  onClose: noop,
  predictedValue: '',
  samples: []
};

ConfusionMatrixExample.propTypes = {
  isHighlighted: PropTypes.bool,
  type: PropTypes.string,
  actualValue: PropTypes.string,
  experimentKey: PropTypes.string,
  onClose: PropTypes.func,
  predictedValue: PropTypes.string,
  samples: PropTypes.array
};

export default ConfusionMatrixExample;
