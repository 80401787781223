import React from 'react';
import { Input } from '@DesignSystem/controllers';
import { Box } from '@material-ui/core';

type FieldStateType = {
  alertName: null | string;
  alertDescription: null | string;
};

type CallBackType = (prev: FieldStateType) => void;

type StepThreeProps = {
  fieldsState: FieldStateType;
  setFieldsState: (func: CallBackType) => void;
};

export default function StepThree({
  fieldsState,
  setFieldsState
}: StepThreeProps) {
  return (
    <div>
      <h6>Choose the notification name & description: </h6>
      <Box marginY="20px" width="400px">
        <Input
          height="36px"
          required
          title="Alert name:"
          value={fieldsState.alertName}
          onChange={(val: string) =>
            setFieldsState((prev: FieldStateType) => ({
              ...prev,
              alertName: val
            }))
          }
        />
        <Box marginTop="15px">
          <Input
            height="117px"
            value={fieldsState.alertDescription}
            onChange={(val: string) =>
              setFieldsState((prev: FieldStateType) => ({
                ...prev,
                alertDescription: val
              }))
            }
            multiline
            title="Alert description (optional):"
          />
        </Box>
      </Box>
    </div>
  );
}
