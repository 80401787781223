import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import capitalize from 'lodash/capitalize';

import Divider from '@material-ui/core/Divider';

import ArtifactAliases from '@experiment-management-shared/components/ArtifactAliases';
import ArtifactTags from '@experiment-management-shared/components/ArtifactTags';
import { formatBytes, formatTimestampForTable } from '@/helpers/generalHelpers';
import ArtifactDetails from '@experiment-management-shared/components/ArtifactDetails';
import RedirectLink from '@shared/components/RedirectLink';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';

const ArtifactVersionRow = ({ artifactVersion }) => {
  const {
    added,
    alias,
    artifactName,
    artifactType,
    artifactEmoji,
    direction,
    sizeInBytes,
    versionId,
    tags,
    version
  } = artifactVersion;

  const name = (
    <div className="flex justifyCenter alignCenter">
      <span className="artifact-name-version">Version {version}</span>
      <ArtifactAliases aliases={alias} />
    </div>
  );

  return (
    <RedirectLink
      target="_self"
      resource={REDIRECT_LINK_RESOURCES.ARTIFACT_VERSION}
      args={[versionId]}
      className="artifact-row-container artifact-version-row"
      data-test={`artifact-version-${versionId}`}
    >
      <div className="artifact-version-row-header">
        {artifactEmoji && (
          <span className="artifact-version-row-emoji">{artifactEmoji}</span>
        )}
        <span className="artifact-version-row-name">{artifactName}</span>
        {artifactType && (
          <span className="artifact-version-row-type">({artifactType})</span>
        )}
      </div>
      <Divider />
      <div className="flex justifySpaceBetween">
        <div>
          <ArtifactDetails
            name={name}
            metadata={[
              { label: 'Added on', value: formatTimestampForTable(added) },
              {
                label: 'Size',
                value: isNull(sizeInBytes) ? 'N/A' : formatBytes(sizeInBytes)
              },
              {
                label: 'Direction',
                value: capitalize(direction)
              }
            ]}
          />
        </div>
        <div className="artifact-version-row-tags">
          {!isEmpty(tags) && (
            <div className="artifact-header-tags">
              <ArtifactTags tagNames={tags} maxVisible={3} />
            </div>
          )}
        </div>
      </div>
    </RedirectLink>
  );
};

ArtifactVersionRow.propTypes = {
  artifactVersion: PropTypes.object.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default ArtifactVersionRow;
