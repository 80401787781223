import { useQuery } from 'react-query';

const getOrganizationSettings = async () => {
  return {
    disablePublicProjects: false,
    disableShareableLink: false,
    allowAllMembersToInvite: true
  };
};

export default function useOrganizationSettings(organizationId) {
  return useQuery(['organizations', organizationId, 'settings'], () =>
    getOrganizationSettings(organizationId)
  );
}
