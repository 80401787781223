import md5 from 'md5';

export const getUserAvatarURL = ({
  isUserLoggedInWithGitHub,
  username,
  userEmail,
  width
}) => {
  if (isUserLoggedInWithGitHub) {
    return normalizeAvatarURL(`https://github.com/${username}.png`, width);
  }

  if (!userEmail) return null;

  const hash = md5(userEmail.trim().toLowerCase(), { encoding: 'binary' });

  return normalizeAvatarURL(`https://www.gravatar.com/avatar/${hash}`, width);
};

// used for admin dashboard indication
export const isEmail = value => value?.includes('@');

export function isValidEmail(emailAddress) {
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
  );
  return pattern.test(emailAddress);
}

export const normalizeAvatarURL = (url, width) => {
  if (/^https:\/\/www\.gravatar\.com/.test(url)) {
    return `${url}?s=${width}&r=g&d=mp`;
  }

  return `${url}?size=${width}`;
};
