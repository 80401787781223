// This function abbreviates the POSITIVE numbers. It returns string
export const abbreviateNumber = (value, precision = 3) => {
  let newValue = +value;
  let strValue = newValue.toString();

  if (strValue.length <= precision) {
    return strValue;
  }

  const suffixes = ['', 'k', 'm', 'b', 't'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum += 1;
  }

  newValue = newValue.toPrecision(precision);

  newValue += suffixes[suffixNum];
  return newValue;
};

export const toFixedNoRound = (value, precision = 1) => {
  const signMultiplier = value < 0 ? -1 : 1;
  if (precision === 0) {
    return signMultiplier * Math.trunc(Math.abs(value));
  }

  const factor = 10 ** precision;
  return (signMultiplier * Math.floor(Math.abs(value * factor))) / factor;
};
