import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';

import { getIsCollaborationEnabled } from '@/reducers/ui/workspaceUiReducer';
import ManagePageWrapper from '../ManagePageWrapper';
import AddReportButton from './AddReportButton';
import DashboardOptionsButton from './DashboardOptionsButton';
import DashboardTemplateSelect from './DashboardTemplateSelect';
import SaveTemplateButton from './SaveTemplateButton';

const EMDashboardHeader = ({
  dashboard,
  canEdit,
  onChange,
  hasUnsavedChanges,
  onDiscardChanges
}) => {
  const location = useLocation();
  const isCollaborationEnabled = useSelector(getIsCollaborationEnabled);

  const renderModals = () => {
    if (
      location.hash === `#${EXPERIMENT_TAB_PATHNAME.manage}` &&
      isCollaborationEnabled
    ) {
      return <ManagePageWrapper />;
    }

    return null;
  };

  const rightSection = () => {
    return (
      <>
        <DashboardTemplateSelect
          hasUnsavedChanges={hasUnsavedChanges}
          onDiscardChanges={onDiscardChanges}
          dashboard={dashboard}
        />
        <div className="dashboard-header-divider"></div>
        <SaveTemplateButton
          canEdit={canEdit}
          dashboard={dashboard}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <div className="dashboard-header-divider" />
        <DashboardOptionsButton dashboard={dashboard} onChange={onChange} />
        <div className="dashboard-header-divider" />
        <AddReportButton canEdit={canEdit} dashboard={dashboard} />
      </>
    );
  };

  const renderRightSection =
    location.hash !== `#${EXPERIMENT_TAB_PATHNAME.manage}` && rightSection();

  return (
    <div className="dashboard-header">
      {renderRightSection}
      {renderModals()}
    </div>
  );
};

EMDashboardHeader.propTypes = {
  dashboard: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  onDiscardChanges: PropTypes.func.isRequired
};

export default EMDashboardHeader;
