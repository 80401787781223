import React from 'react';

import { DragModelNodeIcon } from '@Icons-outdated';
import classNames from './DAGContent.module.scss';

type DragModelItemType = {
  id: string;
  name: string;
  onDragStart: (event: React.DragEvent, id: string, name: string) => void;
  key: string;
};

export const DragModelItem = ({
  id,
  name,
  onDragStart,
  key
}: DragModelItemType) => {
  return (
    <div
      data-test={name}
      id={id}
      className={classNames.dragModelItem}
      onDragStart={event => onDragStart(event, id, name)}
      draggable
      key={key}
    >
      {name}
      <DragModelNodeIcon />
    </div>
  );
};
