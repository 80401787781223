import React from 'react';
import { TreeRenderProps } from 'react-complex-tree';
import styles from './chain.module.scss';
import cx from 'classnames';
import { ArrowIcon } from '@Icons-outdated';
import { isEmpty } from 'lodash';

const generateStubCells = (depth: number) => {
  const items = [];
  for (let i = 0; i < depth; i++) {
    items.push(<div className={styles.stubCell} />);
  }
  return items;
};

export const chainRenderers: TreeRenderProps = {
  renderTreeContainer: props => (
    <div className={styles.chainsContainer}>
      <ul {...props.containerProps}>{props.children}</ul>
    </div>
  ),

  renderItemsContainer: props => (
    <ul {...props.containerProps}>{props.children}</ul>
  ),

  renderItem: props => {
    const widthPercentage =
      (props.item.data.duration / props.item.data.maxDuration) * 100;

    const offset =
      props.item.data.start_timestamp - props.item.data.maxStartTime;
    const offsetPercentage = (offset / props.item.data.maxDuration) * 100;

    return (
      <li {...(props.context.itemContainerWithChildrenProps as object)}>
        <button
          className={cx(
            styles.chainItemOuterContainer,
            props.context.isFocused && styles.focused
          )}
          {...(props.context.itemContainerWithoutChildrenProps as object)}
          onFocus={props.context.interactiveElementProps.onFocus}
        >
          <div className={cx(styles.chainItemContainer)}>
            <div className={styles.detailsContainerWithArrow}>
              {generateStubCells(props.depth)}
              {props.arrow}
              <div className={styles.detailsOuterContainer}>
                <div className={styles.detailsInnerContainer}>
                  <p> {props.item.data.name} </p>
                  <div className={styles.detailsDivider} />
                </div>
                <span>{props.item.data.category} </span>
              </div>
            </div>
            <div className={styles.chainSpanOuterContainer}>
              <div className={styles.chainSpanContainer}>
                <div className={styles.chainItemSpanDivider} />
                <div
                  className={styles.chainSpanWrapper}
                  style={{
                    background: '#' + props.item.data.spanColor,
                    width: widthPercentage + '%',
                    left: offsetPercentage + '%'
                  }}
                />
              </div>
              <span> {props.item.data.duration} ms</span>
            </div>
          </div>
        </button>

        {props.children}
      </li>
    );
  },

  renderItemArrow: props => {
    return (
      <div
        className={cx(
          styles.chainNodeArrowContainer,
          isEmpty(props.item.children) && styles.empty
        )}
        {...(props.context.arrowProps as object)}
        {...(props.context.interactiveElementProps as object)}
      >
        <p>{props.item.data?.childrenIncludingIndirect.length}</p>
        <div
          className={cx(
            styles.arrowIconWrapper,
            props.context.isExpanded && styles.expended
          )}
        >
          <ArrowIcon />
        </div>
      </div>
    );
  },

  renderDepthOffset: 1
};
