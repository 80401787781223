import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';

import some from 'lodash/some';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import has from 'lodash/has';
import debounce from 'lodash/debounce';
import { intersection } from 'lodash/array';

import { Popover } from '@material-ui/core';
import cx from 'classnames';
import { KeyboardArrowDownIcon } from '@Icons-outdated';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  calculateExperimentName,
  getExperimentColor
} from '@experiment-management-shared/utils/experimentHelpers';
import { Button, TextButton } from '@ds';
import { Checkbox } from '@DesignSystem/controllers';
import DsAccordion from '@design-system-outdated/components/Accordion/Accordion';

import styles from './AssetsMultiSelect.module.scss';

const getSelectedValue = (value, options) => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    for (let j = 0; j < option.assets.length; j++) {
      const asset = option.assets[j];
      if (asset.values.includes(value)) {
        return {
          title: asset.title,
          color: getExperimentColor(option.experiment).primary,
          assetId: value
        };
      }
    }
  }

  return {
    title: value,
    color: '',
    assetId: value
  };
};

const AssetsMultiSelect = ({
  label,
  popoverLabel,
  emptySectionMessage,
  maxSelectValues,
  options,
  values: originalValues,
  onChange,
  disabled
}) => {
  const popoverActionRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const [expandedMap, setExpandedMap] = useState({});
  useEffect(() => {
    setExpandedMap(
      options.reduce((acc, option) => {
        const { experimentKey } = option.experiment;
        acc[experimentKey] = has(expandedMap, experimentKey)
          ? expandedMap[experimentKey]
          : true;
        return acc;
      }, {})
    );
    // expandedMap ignored in purpose
  }, [options]);

  const [values, setValues] = useState(originalValues);
  useEffect(() => {
    if (values !== originalValues) {
      setValues(originalValues);
    }
    // values ignored in purpose
  }, [originalValues]);

  const isOpen = Boolean(anchorEl);

  const openPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };

  const applyChanges = () => {
    onChange(values);
    closePopover();
  };

  const cancelChanges = () => {
    setValues(originalValues);
    closePopover();
  };

  const isAllCollapsed = !some(Object.values(expandedMap));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePopupPosition = useCallback(
    debounce(() => {
      popoverActionRef.current.updatePosition();
    }, 50),
    [popoverActionRef]
  );

  const toggleExpandAll = () => {
    setExpandedMap(mapValues(expandedMap, () => isAllCollapsed));
  };

  const toggleExpand = key => {
    setExpandedMap({ ...expandedMap, [key]: !expandedMap[key] });
  };

  const onAssetChecked = (event, asset) => {
    const { checked } = event.target;

    if (checked) {
      // in case of checking a new asset need to be evaluated if this asset was checked in original values, in case true,
      // the ID from original values need to be used, otherwise first from the list
      const idToAdd =
        intersection(originalValues, asset.values)[0] || asset.values[0];
      setValues([...values, idToAdd]);
    } else {
      setValues(values.filter(v => !asset.values.includes(v)));
    }
  };

  const hasChanges = !isEqual(sortBy(originalValues), sortBy(values));

  const selectedValues = useMemo(() => {
    return values.map(v => getSelectedValue(v, options));
  }, [options, values]);

  const renderChipsContainer = () => {
    return (
      <div className={styles.chipsContainer}>
        {selectedValues.map(value => {
          return (
            <div key={value.assetId} className={styles.chips}>
              <div
                className={styles.color}
                style={{ background: value.color }}
              />
              <div className={styles.text}>{value.title}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAssets = assets => {
    if (assets.length === 0) {
      return (
        <div className={styles.noAssetsMessage}>{emptySectionMessage}</div>
      );
    }

    return assets.map(asset => {
      const checked = intersection(values, asset.values).length > 0;
      const isDisabled = !checked && values.length >= maxSelectValues;

      return (
        <label key={asset.id} className={styles.checkboxLabel}>
          <Checkbox
            disabled={isDisabled}
            checked={checked}
            onChange={event => onAssetChecked(event, asset)}
          />
          <span className={styles.checkboxLabelText}>{asset.title}</span>
        </label>
      );
    });
  };

  const renderOption = option => {
    const title = calculateExperimentName(option.experiment);
    const background = getExperimentColor(option.experiment).primary;
    const { experimentKey } = option.experiment;
    const expanded = expandedMap[experimentKey];

    return (
      <div key={experimentKey} className={styles.optionContainer}>
        <DsAccordion
          classes={{
            details: styles.popoverDetails
          }}
          title={
            <div className={styles.optionTitle}>
              <div className={styles.optionColor} style={{ background }} />
              <div className={styles.optionText}>{title}</div>
            </div>
          }
          id={experimentKey}
          expanded={expanded}
          handleExpandChange={() => toggleExpand(experimentKey)}
          content={renderAssets(option.assets)}
          TransitionProps={{
            onEntered: updatePopupPosition,
            onExited: updatePopupPosition
          }}
        />
      </div>
    );
  };

  const isApplyDisabled = !hasChanges || values.length === 0;

  const renderPopover = () => {
    const width = anchorEl?.offsetWidth;

    return (
      <Popover
        action={popoverActionRef}
        classes={{
          paper: styles.assetsSelectPopoverContainer
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={cancelChanges}
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          style: {
            width
          }
        }}
      >
        <div className={styles.headerContainer}>
          <span className={styles.popoverLabel}>{popoverLabel}</span>
          <TextButton onClick={toggleExpandAll} size="small">
            {isAllCollapsed ? 'Expand all' : 'Collapse all'}
          </TextButton>
        </div>
        <div className={styles.bodyContainer}>
          {options.map(option => renderOption(option))}
        </div>
        <div className={styles.footerContainer}>
          <TextButton onClick={cancelChanges}>Close</TextButton>{' '}
          <Button disabled={isApplyDisabled} onClick={applyChanges}>
            Apply
          </Button>
        </div>
      </Popover>
    );
  };

  const handleClick = event => {
    if (disabled) return;
    openPopover(event);
  };

  return (
    <div className={styles.assetsSelectWrapper}>
      <div className={styles.assetsSelectLabel}>
        <span>{label}</span>
      </div>

      <div
        className={cx(styles.selectContainer, {
          [styles.focused]: isOpen,
          [styles.disabled]: disabled
        })}
        onClick={handleClick}
      >
        {renderChipsContainer()}
        <div className={styles.iconsContainer}>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      {renderPopover()}
    </div>
  );
};

AssetsMultiSelect.propTypes = {
  label: PropTypes.string,
  popoverLabel: PropTypes.string,
  emptySectionMessage: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxSelectValues: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      experiment: IExperimentDetails.isRequired,
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired
        })
      ).isRequired
    })
  ).isRequired,
  onChange: PropTypes.func
};

AssetsMultiSelect.defaultProps = {
  label: '',
  popoverLabel: '',
  emptySectionMessage: 'No items to select',
  maxSelectValues: 4,
  onChange: noop
};

export default AssetsMultiSelect;
