import { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {
  CHART_BASIC_LAYOUT,
  transformNumericalDistributionToPlotData
} from '@mpm-druid/utils';
import { CHART_TYPES } from '@mpm-druid/constants';
import { useNumericalDistribution } from '@mpm-druid/api';
import { IntervalType, PredicateQuery } from '@mpm-druid/types';

const initialState = {
  panelData: [],
  layoutData: {
    ...CHART_BASIC_LAYOUT,
    spikedistance: -1,
    hoverdistance: -1,
    hovermode: 'closest',
    xaxis: {
      ...CHART_BASIC_LAYOUT.xaxis,
      type: 'linear',
      range: [0, 1],
      zeroline: false
    },
    yaxis: {
      ...CHART_BASIC_LAYOUT.yaxis,
      showgrid: false,
      showticklabels: false
    }
  },
  chartType: null
};

const defaultState = {
  plotData: initialState,
  numericalLoading: false,
  numericalError: false
};

type MPMPNumericalDistributionProps = {
  from: string;
  to: string;
  intervalType: IntervalType;
  predicates: PredicateQuery[];
  version: string | null;
  hoverLineData: null;
  name?: string;
  source?: string;
};

type MPMPNumericalDistributionConfig = {
  enabled: boolean;
  keepPreviousData: boolean;
  retry: boolean;
};

export function useMPMNumericalDistribution(
  {
    from,
    to,
    intervalType,
    predicates,
    version,
    hoverLineData,
    name,
    source
  }: MPMPNumericalDistributionProps,
  config: MPMPNumericalDistributionConfig
) {
  const [plotData, setPlotData] = useState<typeof initialState>(initialState);

  const {
    data: numericalDistributionData,
    isLoading: numericalLoading,
    isError: numericalError
  } = useNumericalDistribution(
    {
      from,
      to,
      intervalType,
      predicates,
      version,
      name,
      source
    },
    {
      ...config
    }
  );

  const debouncedPlotDataSetter = useCallback(debounce(setPlotData, 50), []);

  const chartType = CHART_TYPES.NUMERICAL_DISTRIBUTION;

  const noDataTimeRange = useMemo(() => {
    if (numericalDistributionData?.data.length) {
      const segmentData = numericalDistributionData.data.find(
        item => !isEmpty(item)
      );
      if (!segmentData) return [0, 1];
      const xPoints = segmentData.pdfDistributionGraphs.wholeTimeRangeDistribution.graphPoints.map(
        point => point.x
      );
      if (!xPoints?.length) return [0, 1];
      const min = Math.min(...xPoints);
      const max = Math.max(...xPoints);
      return [min, max];
    }

    return [0, 1];
  }, [numericalDistributionData]);

  useEffect(() => {
    if (numericalDistributionData?.data.length) {
      const result = transformNumericalDistributionToPlotData({
        serverResponseData: numericalDistributionData,
        hoverLineData,
        predicates
      });
      const isEmptyData =
        isEmpty(result.lineData[0]) || isEmpty(result.lineData[0]?.x);
      const layoutData = {
        ...CHART_BASIC_LAYOUT,
        spikedistance: -1,
        hoverdistance: -1,
        hovermode: 'closest',
        xaxis: {
          ...CHART_BASIC_LAYOUT.xaxis,
          type: 'linear',
          range: isEmptyData ? noDataTimeRange : 'auto',
          zeroline: false,
          tickformat: '.5'
        },
        yaxis: {
          ...CHART_BASIC_LAYOUT.yaxis,
          showgrid: false,
          showticklabels: false
        }
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      debouncedPlotDataSetter(prev => {
        return { ...prev, panelData: result.lineData, layoutData, chartType };
      });
      return;
    }

    return debouncedPlotDataSetter(initialState);
  }, [
    numericalDistributionData,
    hoverLineData,
    chartType,
    debouncedPlotDataSetter,
    noDataTimeRange,
    predicates
  ]);

  if (!config?.enabled) {
    return defaultState;
  }

  return {
    plotData,
    numericalLoading: numericalLoading,
    numericalError
  };
}
