import api, { QueryConfig } from '@shared/api';
import { flatten, isEmpty, sortBy } from 'lodash';
import { useQuery } from 'react-query';

const IMAGE_ANNOTATIONS_COLORS = [
  'E51772',
  '0096C7',
  '00B4D8',
  '12A592',
  '16CAB2',
  'FB7628',
  'FF4747',
  'FF8900',
  'FFBD00',
  '41EAD4',
  '49A5BD',
  '6E1D89',
  '860DAB',
  'CF0057',
  'FFD51D'
];

const labelColorMap: Record<string, string> = {};
let colorCount = 0;

type ImageAPI = {
  imageId: string;
  experimentKey: string;
  metadata?: string | null;
};

type ImagesAPI = ImageAPI[];

type ImageAnnotationDataAPI = {
  boxes: number[][];
  id: string;
  label: string;
  score: number;
};

type ImageAnnotationAPI = {
  data: ImageAnnotationDataAPI[];
  name: string;
};

type ImageMetadataAPI = {
  annotations?: ImageAnnotationAPI[];
};

type ImageAnnotation = {
  id: number;
  boxes: number[][];
  label: string;
  score: number;
};

export type ImageMetadata = {
  annotations: ImageAnnotation[];
  experimentKey: string;
  imageId: string;
  labelColorMap: Record<string, string>;
  labels: string[];
  metadata: string;
};

const getMetadata = async ({
  experimentKeys,
  imagesIds,
  signal
}: {
  experimentKeys?: string[];
  imagesIds?: string[];
  signal?: AbortSignal;
}) => {
  if (!experimentKeys?.length || !imagesIds?.length) {
    return [];
  }

  const { data: images } = await api.post<ImagesAPI>(
    '/image/get-metadata',
    { experimentKeys, imagesIds },
    { signal }
  );

  return images.map(image => imageToAnnotations(image));
};

export const useImagePanelMetadata = (
  {
    experimentKeys,
    imagesIds
  }: { experimentKeys?: string[]; imagesIds?: string[] },
  config?: QueryConfig<ImageMetadata[]>
) => {
  const configEnabled = config?.enabled ?? true;
  const isEnabled = !!experimentKeys?.length && !!imagesIds?.length;

  return useQuery(
    ['imageMetadata', { experimentKeys, imagesIds }],
    ({ signal }) => getMetadata({ experimentKeys, imagesIds, signal }),
    { ...config, enabled: configEnabled && isEnabled }
  );
};

export const imageToAnnotations = ({
  experimentKey,
  imageId,
  metadata
}: ImageAPI) => {
  const emptyData = {
    annotations: [],
    experimentKey,
    imageId,
    labelColorMap,
    labels: [],
    metadata
  } as ImageMetadata;

  if (!metadata) return emptyData;

  const { annotations: layers } = JSON.parse(metadata) as ImageMetadataAPI;

  if (!layers || isEmpty(layers)) {
    return emptyData;
  }

  const labels = new Set();
  const allAnnotations = layers.map(layer => {
    const sortedLayer = sortBy(layer.data, 'label');

    sortedLayer.forEach(({ label }) => {
      labels.add(label);

      if (!labelColorMap[label]) {
        const colorIndex = colorCount % IMAGE_ANNOTATIONS_COLORS.length;
        const nextColor = IMAGE_ANNOTATIONS_COLORS[colorIndex];

        labelColorMap[label] = nextColor;
        colorCount += 1;
      }
    });

    return sortedLayer.map((val, id) => ({ ...val, id }));
  });

  const annotations = flatten(allAnnotations);

  return {
    annotations,
    experimentKey,
    imageId,
    labelColorMap,
    labels: Array.from(labels),
    metadata
  } as ImageMetadata;
};
