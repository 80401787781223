import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';

// The purpose of this component make redirect from old URL structure to new one for experiment comparison page
const CompareExperimentsRedirect = ({ location, history }) => {
  const {
    workspace,
    projectName,
    baseExperimentKey,
    compareExperimentKey
  } = useParams();

  useEffect(() => {
    const { pathname } = location;
    const archivePath = pathname.includes('/archive/') ? 'archive/' : '';
    const parsedQuery = queryString.parse(location.search);

    const query = queryString.stringify(
      {
        ...parsedQuery,
        experiments: `${baseExperimentKey},${compareExperimentKey}`
      },
      {
        skipNull: true,
        skipEmptyString: true
      }
    );

    history.replace({
      pathname: `/${workspace}/${projectName}/${archivePath}compare`,
      search: `?${query}`,
      state: {
        preventScroll: true
      }
    });
  }, []);

  return null;
};

CompareExperimentsRedirect.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(CompareExperimentsRedirect);
