import {
  debounce,
  get,
  isEmpty,
  isEqual,
  isFunction,
  mapValues,
  uniqBy
} from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router';

import FileSaver from 'file-saver';

import projectsActions from '@/actions/projectsActions';
import { dialogTypes } from '@/constants/alertTypes';
import { NEW_VIEW } from '@/constants/dashboardConstants';
import { dashboardEvents, viewEvents } from '@/constants/trackingEventTypes';
import usePrevious from '@/helpers/custom-hooks/usePrevious';
import {
  getActiveExperimentsHeaderTab,
  getStoredSelectedExperiments,
  getViewHasUnsavedChanges
} from '@/reducers/ui/projectsUiReducer';
import alertsUtil from '@/util/alertsUtil';
import useExperiments from '@API/experiments/useExperiments';
import useColumns from '@API/project/useColumns';
import useProject from '@API/project/useProject';
import useProjectTags from '@API/project/useProjectTags';
import useProjectView from '@API/project/useProjectView';
import useRemoveViewMutation from '@API/project/useRemoveViewMutation';
import useSelectedProjectView from '@API/project/useSelectedProjectView';
import useSuggestedViewFields from '@API/project/useSuggestedViewFields';
import useUpsertViewMutation from '@API/project/useUpsertViewMutation';
import useGroupAggregations from '@experiment-management-shared/api/useGroupAggregations';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import { useExperimentsGroups } from '@experiment-management-shared/hooks';
import {
  fromDashboardToTemplate,
  fromTemplateToDashboard,
  getOriginalViewId,
  getSuggestedView,
  hasDashboardAnyRule,
  isSameView,
  shouldKeepCurrentView
} from '@experiment-management-shared/utils/EMView';
import EMDashboardHeader from '@projects/components/EMDashboardHeader';
import ExperimentActions from '@projects/components/ExperimentActions';
import ExperimentsPage from '@projects/components/ExperimentsPage';
import PanelsPage from '@projects/components/PanelsPage';
import useAutoGeneratedViewEvent from '@projects/hooks/useAutoGeneratedViewEvent';
import useDashboardPageSectionName from '@projects/hooks/useDashboardPageSectionName';
import EMProjectPageHeader from '@shared/components/EMProjectPageHeader';
import ProjectPageWrapper from '@shared/components/ProjectPageWrapper';
import SmallLoader from '@shared/components/SmallLoader';
import { useCurrentPaymentPlan } from '@shared/hooks';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import { trackEvent } from '@shared/utils/eventTrack';
import { getRulesCount } from '@shared/utils/filterHelpers';

const UNSAVED_VIEW_DELAY = 5_000;

const EMDashboardPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { projectName, viewId, workspace } = useParams();
  const section = useDashboardPageSectionName();
  const isArchive = section === 'archive';

  const previousSection = usePrevious(section);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [selectedExperiments, setSelectedExperiments] = useState([]);
  const [
    hasSyncedStoredSelectedExperiments,
    setHasSyncedStoredSelectedExperiments
  ] = useState(false);

  const hasUnsavedChanges = useSelector(getViewHasUnsavedChanges);
  const setHasUnsavedChanges = hasUnsavedChanges =>
    dispatch(projectsActions.setViewHasUnsavedChanges(hasUnsavedChanges));

  const activeExperimentsHeaderTab = useSelector(getActiveExperimentsHeaderTab);
  const storedSelectedExperiments = useSelector(getStoredSelectedExperiments);
  const isAllExperimentsTab = activeExperimentsHeaderTab === 0;
  const exportRef = useRef(null);
  const baseTrackEvent = useBaseTrackEvent();

  const [refetchInterval, setRefetchInterval] = useState(false);
  const {
    data: selectedView,
    isIdle: isIdleSelectedView,
    isLoading: isLoadingSelectedView
  } = useSelectedProjectView();
  const { isLoading: isLoadingColumns, data: columns } = useColumns(
    { extraCols: true },
    { refetchInterval, refetchOnMount: true }
  );
  const {
    data: suggestedViewFields,
    isIdle: isIdleSuggestedViewFields,
    isLoading: isLoadingSuggestedViewFields
  } = useSuggestedViewFields();
  const defaultView = useDeepMemo(() => {
    if (
      isLoadingColumns ||
      isIdleSuggestedViewFields ||
      isLoadingSuggestedViewFields
    ) {
      return null;
    }

    return getSuggestedView(suggestedViewFields);
  }, [
    isIdleSuggestedViewFields,
    isLoadingColumns,
    isLoadingSuggestedViewFields,
    suggestedViewFields
  ]);
  const prevSelectedViewRef = useRef(null);
  const [dashboard, setDashboard] = useState(() => {
    if (isIdleSelectedView || isLoadingSelectedView || !defaultView) {
      return null;
    }

    if (!selectedView) {
      return defaultView;
    }

    prevSelectedViewRef.current = selectedView;

    return fromTemplateToDashboard(selectedView, defaultView, columns);
  });

  const experimentKeysForExperimentsTab = useMemo(() => {
    const experimentsForTab = [
      null,
      dashboard?.table.selection || [],
      dashboard?.hiddenExperimentKeys || []
    ];

    return experimentsForTab[activeExperimentsHeaderTab];
  }, [
    activeExperimentsHeaderTab,
    dashboard?.hiddenExperimentKeys,
    dashboard?.table.selection
  ]);

  useProjectTags({}, { notifyOnChangeProps: [], refetchInterval });

  const groups = useMemo(() => {
    const grouping = dashboard?.table.columnGrouping || [];

    return isAllExperimentsTab ? grouping : [];
  }, [dashboard?.table.columnGrouping, isAllExperimentsTab]);

  const handleDashboardChange = useCallback(changesOrGetter => {
    setDashboard(prevView => {
      if (!prevView) return prevView;

      let changes = changesOrGetter;
      if (isFunction(changesOrGetter)) {
        changes = changesOrGetter(prevView);
      }

      return mapValues(prevView, (value, key) => {
        if (!changes[key]) return value;

        if (Array.isArray(changes[key])) return changes[key];

        return {
          ...value,
          ...changes[key]
        };
      });
    });
  }, []);

  const applyChangeExpandedGroups = useCallback(
    newExpandedGroups => {
      handleDashboardChange({
        table: { expandedGroups: newExpandedGroups, pageNumber: 0 }
      });
    },
    [handleDashboardChange]
  );

  const {
    isFetchingExperimentGroups,
    totalGroups,
    experimentsGroupsMap
  } = useExperimentsGroups({
    columnGrouping: groups,
    isArchive,
    view: dashboard,
    onExpand: applyChangeExpandedGroups,
    queryConfig: {
      refetchInterval,
      refetchOnMount: true
    }
  });

  const { data: groupAggregations } = useGroupAggregations(
    {
      groups,
      view: dashboard
    },
    { refetchInterval, refetchOnMount: true }
  );

  const { data: project, isLoading: isLoadingProject } = useProject();
  const { data: originalView } = useProjectView();

  const [exportName, setExportName] = useState(null);

  const {
    data: experimentsData,
    isFetching: isFetchingExperiments,
    isLoading: isLoadingExperiments,
    isPreviousData: isPreviousDataExperiments
  } = useExperiments(
    {
      isArchive,
      groupsQuery: groups.length ? experimentsGroupsMap.path : undefined,
      targetExperimentKeys: experimentKeysForExperimentsTab,
      view: dashboard,
      viewId: selectedView?.templateId || viewId
    },
    { refetchInterval, refetchOnMount: true }
  );

  const {
    data: pinnedExperimentData,
    isFetching: isFetchingPinnedExperiments,
    isLoading: isLoadingPinnedExperiments,
    isPreviousData: isPreviousDataPinnedExperiments
  } = useExperiments(
    {
      ignorePagination: true,
      targetExperimentKeys: dashboard?.pinnedExperimentKeys || [],
      view: dashboard,
      viewId: selectedView?.templateId || viewId
    },
    {
      initialData: {
        experiments: [],
        total: 0
      },
      refetchInterval,
      refetchOnMount: true
    }
  );
  const upsertViewMutation = useUpsertViewMutation();
  const removeViewMutation = useRemoveViewMutation();
  const { paymentPlanName } = useCurrentPaymentPlan();

  const nonExistentExperiment = get(
    location,
    'state.nonExistentExperiment',
    false
  );

  const createdFromTemplateId = getOriginalViewId(selectedView);

  useAutoGeneratedViewEvent({
    defaultView,
    isIdleSelectedView,
    isLoadingSelectedView,
    selectedView,
    suggestedViewFields
  });

  useEffect(() => {
    setRefetchInterval(dashboard?.panels.isAutoRefreshEnabled ? 30000 : false);
  }, [dashboard?.panels.isAutoRefreshEnabled]);

  const upsertView = useMemo(() => {
    return debounce(
      newView => upsertViewMutation.mutate(newView),
      UNSAVED_VIEW_DELAY
    );
  }, []);

  const handleDiscardChanges = () => {
    const selectedDashboard = fromTemplateToDashboard(
      selectedView,
      defaultView,
      columns
    );

    // workaround to force reset state of search input
    selectedDashboard.table.searchMode = {};

    if (selectedView?.unsavedView) {
      removeViewMutation.mutate({
        createdFromTemplateId: selectedView.createdFromTemplateId,
        isLocalView: selectedView.isLocalView,
        viewId: selectedView.templateId
      });
    }

    setDashboard(selectedDashboard);

    upsertView.cancel();

    baseTrackEvent(dashboardEvents.PROJECT_PAGE_DISCARD_CHANGED_VIEW);
  };

  useEffect(() => {
    if (!dashboard) return;

    const selectedDashboard = fromTemplateToDashboard(
      selectedView,
      defaultView,
      columns
    );

    if (isSameView(selectedDashboard, dashboard)) {
      setHasUnsavedChanges(false);
      upsertView.cancel();
    }
  }, [dashboard, defaultView, project?.projectId, selectedView, upsertView]);

  useEffect(() => {
    setHasUnsavedChanges(false);
    upsertView.cancel();
  }, [createdFromTemplateId, upsertView]);

  useEffect(() => {
    return () => {
      upsertView.flush();
    };
  }, [upsertView]);

  useEffect(() => {
    if (
      !dashboard ||
      isEmpty(storedSelectedExperiments) ||
      hasSyncedStoredSelectedExperiments
    ) {
      return;
    }

    handleDashboardChange({
      table: { selection: storedSelectedExperiments }
    });

    setHasSyncedStoredSelectedExperiments(true);
  }, [
    dashboard,
    handleDashboardChange,
    hasSyncedStoredSelectedExperiments,
    storedSelectedExperiments
  ]);

  useEffect(() => {
    if (isLoadingProject) return;

    trackEvent(viewEvents.PROJECT_VIEWED, {
      canEdit: project?.canEdit,
      isArchive,
      projectId: project?.projectId,
      projectName,
      workspace,
      workspacePlan: paymentPlanName
    });
  }, [
    isArchive,
    isLoadingProject,
    project?.canEdit,
    project?.projectId,
    projectName,
    workspace,
    paymentPlanName
  ]);

  useEffect(() => {
    if (!nonExistentExperiment) return;

    dispatch(
      alertsUtil.openErrorDialog(
        dialogTypes.CATCH_ERROR_API,
        'The experiment does not exist anymore'
      )
    );
  }, [dispatch, nonExistentExperiment]);

  useEffect(() => {
    if (
      !location.hash.includes(`#${SELECT_PANEL_PATH}`) &&
      !location.hash.includes(`#${EXPERIMENT_TAB_PATHNAME.manage}`)
    ) {
      dispatch(projectsActions.setActiveEMHeaderTab(section));
    }
  }, [dispatch, location.hash, section]);

  useEffect(() => {
    if (!previousSection || previousSection === section) return;

    if (
      (previousSection === 'archive' &&
        ['panels', 'experiments'].includes(section)) ||
      (section === 'archive' &&
        ['panels', 'experiments'].includes(previousSection))
    ) {
      handleDashboardChange({
        table: {
          selection: []
        }
      });
    }
  }, [handleDashboardChange, previousSection, section]);

  // Revert changes if there is an error on saving
  useEffect(() => {
    if (upsertViewMutation.isError) {
      setDashboard(fromTemplateToDashboard(selectedView, defaultView, columns));
    }
  }, [upsertViewMutation.isError]);

  // Upsert unsaved view on dashboard change
  useEffect(() => {
    if (
      isLoadingSelectedView ||
      !dashboard ||
      prevSelectedViewRef.current !== selectedView ||
      upsertViewMutation.isLoading
    ) {
      return;
    }

    // calculate state of dashboard with original view (in case we don't have it, we have default state )
    const originalDashboard = fromTemplateToDashboard(
      originalView,
      defaultView,
      columns
    );

    // in case we already have an unsaved view, and we see that unsaved view are equal to original view
    // we need to delete already created unsavedView
    if (selectedView?.unsavedView && isSameView(dashboard, originalDashboard)) {
      setHasUnsavedChanges(false);
      upsertView.cancel();
      removeViewMutation.mutate({ viewId: selectedView.templateId });
    } else {
      // otherwise we need to update unsaved view in case it has any changes in comparison with local state of dashboard
      const selectedDashboard = fromTemplateToDashboard(
        selectedView,
        defaultView,
        columns
      );

      if (isSameView(selectedDashboard, dashboard)) {
        return;
      }

      setHasUnsavedChanges(true);

      if (
        selectedView?.projectId &&
        selectedView?.projectId !== project?.projectId
      ) {
        return;
      }

      const view = fromDashboardToTemplate({
        dashboard,
        project,
        selectedView
      });

      upsertView({
        view: {
          ...view,
          createdFromTemplateId,
          templateId: createdFromTemplateId,
          templateName: 'Unsaved Changes',
          unsavedView: true
        }
      });
    }
  }, [
    createdFromTemplateId,
    dashboard,
    defaultView,
    isLoadingSelectedView,
    project,
    selectedView,
    originalView,
    upsertView,
    upsertViewMutation.isLoading
  ]);

  useEffect(() => {
    const newViewId = createdFromTemplateId || NEW_VIEW;

    if (
      isIdleSelectedView ||
      isLoadingSelectedView ||
      (section && viewId === newViewId)
    ) {
      return;
    }

    const currentSection = section ?? EXPERIMENT_TAB_PATHNAME.panels;

    history.replace({
      ...location,
      pathname: `/${workspace}/${projectName}/view/${newViewId}/${currentSection}`
    });
  }, [
    createdFromTemplateId,
    history,
    isIdleSelectedView,
    isLoadingSelectedView,
    location,
    projectName,
    section,
    viewId,
    workspace
  ]);

  useEffect(() => {
    if (isIdleSelectedView || isLoadingSelectedView || !defaultView) {
      return;
    }

    if (!selectedView) {
      prevSelectedViewRef.current = null;
      setDashboard(defaultView);
      return;
    }

    const shouldKeepView = shouldKeepCurrentView(
      prevSelectedViewRef.current,
      selectedView
    );
    const selectedDashboard = fromTemplateToDashboard(
      selectedView,
      defaultView,
      columns
    );
    prevSelectedViewRef.current = selectedView;

    setDashboard(currentDashboard => {
      // keep the local dashboard state if the user introduced changes
      // while the unsaved changes view is saved
      if (shouldKeepView || isSameView(selectedDashboard, currentDashboard)) {
        return currentDashboard;
      }
      return selectedDashboard;
    });
  }, [defaultView, isIdleSelectedView, isLoadingSelectedView, selectedView]);

  useEffect(() => {
    handleDashboardChange({
      table: { pageNumber: 0 }
    });
  }, [activeExperimentsHeaderTab, handleDashboardChange]);

  // Filter any archived pinned experiments. When the view is
  // eventually saved, those pinned experiments will be removed.
  useEffect(() => {
    if (
      !dashboard?.pinnedExperimentKeys ||
      isFetchingPinnedExperiments ||
      isLoadingPinnedExperiments
    ) {
      return;
    }

    const filtered = (pinnedExperimentData?.experiments || [])
      .filter(exp => !exp.archived)
      .map(exp => exp.experimentKey);

    if (!isEqual(filtered, dashboard?.pinnedExperimentKeys)) {
      handleDashboardChange({
        pinnedExperimentKeys: filtered
      });
    }
  }, [
    dashboard?.pinnedExperimentKeys,
    handleDashboardChange,
    isFetchingPinnedExperiments,
    isLoadingPinnedExperiments,
    pinnedExperimentData
  ]);

  const totalExperiments = get(experimentsData, 'total', 0);
  const rulesCount = getRulesCount(dashboard?.query?.rulesTree);

  const tableExperiments = useMemo(() => {
    const experiments = experimentsData?.experiments || [];

    if (isArchive) {
      return experiments;
    }

    const isPinnedExperimentsEmpty =
      hasDashboardAnyRule(dashboard) && !experiments.length;

    const pinnedExperimentsGroup = (
      (!isPinnedExperimentsEmpty && pinnedExperimentData?.experiments) ||
      []
    ).map(experiment => {
      return {
        ...experiment,
        isInPinnedGroup: true
      };
    });

    const pinnedExperimentKeys = pinnedExperimentsGroup.map(
      ({ experimentKey }) => experimentKey
    );

    const unpinnedExperimentsGroup = experiments
      .filter(({ experimentKey }) => {
        return !pinnedExperimentKeys.includes(experimentKey);
      })
      .map((experiment, index) => {
        if (index === 0 && pinnedExperimentsGroup.length) {
          return {
            ...experiment,
            isFirstOfUnpinnedGroup: true
          };
        }

        return experiment;
      });

    return [...pinnedExperimentsGroup, ...unpinnedExperimentsGroup];
  }, [
    experimentsData?.experiments,
    isArchive,
    pinnedExperimentData?.experiments,
    rulesCount
  ]);

  const panelExperiments = useMemo(
    () => uniqBy(tableExperiments, 'experimentKey'),
    [tableExperiments]
  );

  useEffect(() => {
    setSelectedExperiments(previousSelectedExperiments => {
      const combinedExperiments = [
        ...tableExperiments,
        ...previousSelectedExperiments
      ];

      return (dashboard?.table.selection || [])
        .map(experimentKey =>
          combinedExperiments.find(e => experimentKey.includes(e.experimentKey))
        )
        .filter(Boolean);
    });
  }, [dashboard?.table.selection, tableExperiments]);

  const exportHandler = useCallback(name => {
    setExportName(name);
    exportRef.current.exportGrid();
  }, []);

  const onSave = useCallback(
    workbook => {
      workbook.csv.writeBuffer().then(buffer => {
        FileSaver.saveAs(
          new Blob([buffer], { type: 'text/csv' }),
          `${exportName}.csv`
        );
      });
    },
    [exportName]
  );

  const hasLoadedExperiments =
    !isIdleSelectedView &&
    !isLoadingSelectedView &&
    !isLoadingColumns &&
    !isLoadingExperiments &&
    !isLoadingPinnedExperiments &&
    !!dashboard;

  if (!hasLoadedExperiments) {
    return (
      <div id="table" className="table-wrapper">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={
            <span>
              Fetching project data for <b>{projectName}</b>
            </span>
          }
        />
      </div>
    );
  }

  const isFetchingAnyExperiments =
    isFetchingExperiments || isFetchingPinnedExperiments;
  const isPreviousDataAnyExperiments =
    isPreviousDataExperiments || isPreviousDataPinnedExperiments;
  const isLoadingAnyExperiments =
    isLoadingExperiments || isLoadingPinnedExperiments;

  const pageProps = {
    availableColumns: columns,
    activeExperimentsHeaderTab,
    dashboard,
    displayGroups: groups,
    experimentsGroupsMap,
    groupAggregations,
    experiments: tableExperiments,
    isArchive,
    isFetchingExperiments: isFetchingAnyExperiments,
    isLoadingExperiments: isLoadingAnyExperiments,
    isFetchingExperimentGroups,
    isPreviousDataExperiments: isPreviousDataAnyExperiments,
    onChangeDashboard: handleDashboardChange,
    onChangeHeaderVisibility: setIsHeaderSticky,
    onSave,
    pageTotalGrouped: totalGroups,
    ref: exportRef,
    selectedExperiments,
    totalExperiments
  };

  return (
    <ProjectPageWrapper
      header={
        <EMProjectPageHeader
          subheaderRightSection={
            <EMDashboardHeader
              dashboard={dashboard}
              canEdit={project?.canEdit}
              onChange={handleDashboardChange}
              hasUnsavedChanges={hasUnsavedChanges}
              onDiscardChanges={handleDiscardChanges}
            />
          }
          sticky={isHeaderSticky}
        />
      }
      content={
        <>
          <Switch>
            <Route
              path="/:workspace/:projectName/view/:viewId/archive"
              exact
              render={() => <ExperimentsPage {...pageProps} isArchive />}
            />
            <Route
              path="/:workspace/:projectName/view/:viewId/experiments"
              exact
              render={() => <ExperimentsPage {...pageProps} />}
            />
            <Route
              path="/:workspace/:projectName/view/:viewId/panels"
              exact
              render={() => (
                <PanelsPage
                  panelExperiments={panelExperiments}
                  {...pageProps}
                />
              )}
            />
          </Switch>

          {selectedExperiments.length > 0 && (
            <ExperimentActions
              dashboard={dashboard}
              hiddenExperimentKeys={dashboard.hiddenExperimentKeys}
              onChange={handleDashboardChange}
              exportHandler={exportHandler}
              selectedExperiments={selectedExperiments}
              totalExperiments={totalExperiments}
              experiments={tableExperiments}
            />
          )}
        </>
      }
    />
  );
};

export default EMDashboardPage;
