import { changeColorOpacity } from '@shared';
import moment from 'moment';

import { flatten, get, zipObject, transform } from 'lodash';
import { evaluate } from 'mathjs';

import {
  CHART_TYPES,
  CHART_TYPES_BY_SECTION,
  DEFAULT_SEGMENTS_ID,
  DEFAULT_SEGMENTS_LINE_COLOR,
  INTERVAL_TYPE_VALUES,
  MPM_CHART_DATA_TYPES,
  MULTIPLE_TYPE_SECTIONS,
  PERCENTILE_OPTION_LIST,
  PERCENTILE_VALUES,
  NON_HANDLED_SEGMENTS_SECTIONS,
  PANEL_NAMES,
  MPM_OPERATOR_FILTERS,
  PANEL_DATA_URL_MAP_SECTION,
  CONFUSION_MATRIX_NBP_CUSTOM_METRIC_PROPERTY,
  CONFUSION_MATRIX_CUSTOM_METRIC_PROPERTY,
  NUMERICAL_FEATURE_CUSTOM_METRIC_PROPERTY,
  CATEGORICAL_FEATURE_CUSTOM_METRIC_PROPERTY
} from '../constants';

const mapPercentile = per => {
  if (per?.includes('p')) return `${per.substring(1)}th percentile`;
  return per;
};

const setHovertemplate = ({
  type,
  secondPercentileName,
  firstPercentileName,
  isHourly = false
}) => {
  if (isHourly) {
    return ` %{text.date}, %{y} <extra></extra>`;
  }

  if (!secondPercentileName || !firstPercentileName) {
    return null;
  }
  const end = `${mapPercentile(secondPercentileName)}: %{text.max:.10f}`;
  const start = `${mapPercentile(firstPercentileName)}: %{text.min:.10f}`;
  const median = `median: %{text.median:.10f}`;
  const endText = type === 'end' ? `<b>${end}</b>` : end;
  const startText = type === 'start' ? `<b>${start}</b>` : start;
  const medianText = type === 'median' ? `<b>${median}</b>` : median;

  return ` %{text.date} <br> ${endText} <br> ${medianText} <br> ${startText} <extra></extra> `;
};

const formatHoverDate = (x, intervalType) => {
  const showHours =
    intervalType === INTERVAL_TYPE_VALUES.HOURLY
      ? { hour: 'numeric', minute: '2-digit' }
      : {};

  return new Date(x).toLocaleString([], {
    month: 'short',
    day: '2-digit',
    ...showHours
  });
};

const addGeneralHoverTemplate = (data, intervalType) => {
  const isHourly = intervalType === INTERVAL_TYPE_VALUES.HOURLY;
  if (isHourly && data) {
    return data.map(dt => ({
      ...dt,
      text: dt?.x.map(x => ({ date: formatHoverDate(x, intervalType) })),
      hovertemplate: setHovertemplate({ isHourly: true })
    }));
  }

  return data;
};

export const selectColorFromList = (colors, index, defaultValue) => {
  if (!colors || !colors.length) {
    return defaultValue;
  }

  return colors[index % colors.length] || defaultValue;
};

export const transformToSegmentPoints = panel => {
  const segmentListPoints = panel.data.map(segment => segment.data);
  return segmentListPoints;
};

export const formatToLocal = (utcDate, format = 'YYYY-MM-DD HH:mm') => {
  try {
    return moment.utc(utcDate).local().format(format);
  } catch (_) {
    return utcDate;
  }
};

export const transformToSegmentPlotPoints = (
  panel,
  colors = [],
  intervalType,
  segmentsIdColorMap
) => {
  const plotData = panel
    .filter(line => line.data)
    .map((lineData, index) => {
      const x = [];
      const y = [];
      const segmentId = lineData?.segment?.id;
      const linePoints = lineData.data;
      linePoints?.forEach(point => {
        x.push(formatToLocal(point.x));
        y.push(point.y);
      });

      const isSingleDot = x.length === 1;
      return {
        x,
        y,
        alertNotifications: panel[0].alertNotifications,
        line: {
          color: segmentsIdColorMap[segmentId] || get(colors, index, ''),
          dash: 'solid'
        },
        segmentId,
        name: segmentsIdColorMap[`${segmentId}_name`],
        mode: isSingleDot ? 'markers' : 'lines'
      };
    });

  return addGeneralHoverTemplate(plotData, intervalType);
};

const getTransformedMatrix = (labels, matrix) => {
  const transformedMatrix = {};
  labels.forEach((label, index) => {
    const zippedRow = zipObject(labels, matrix[index]);
    transformedMatrix[label] = zippedRow;
  });
  return transformedMatrix;
};

export const transformMatrixToPlotPoints = (labels, matrices) => {
  const x = [];
  const y = [];
  for (let i = 0; i < matrices.length; i++) {
    x.push(formatToLocal(matrices[i]?.x));
    y.push({
      [CONFUSION_MATRIX_CUSTOM_METRIC_PROPERTY]: getTransformedMatrix(
        labels,
        matrices[i]?.y
      )
    });
  }
  return {
    x,
    y,
    line: {
      color: '#5899DA',
      dash: 'solid'
    },
    mode: x.length === 1 ? 'markers' : 'lines'
  };
};

export const transformCMCustomMetricToPlot = (data, mpmData, formula) => {
  if (!mpmData || !data?.panelData) return data;
  const numberOfPrecictions = mpmData.data[0].data;
  data.panelData[0].y = data.panelData[0].y.map((item, idx) => {
    return evaluate(formula.toLowerCase(), {
      ...item,
      [CONFUSION_MATRIX_NBP_CUSTOM_METRIC_PROPERTY]: numberOfPrecictions[idx].y
    });
  });
  return data.panelData;
};

function transformKeysLowercase(obj) {
  return transform(obj, (result, val, key) => {
    result[key.toLowerCase()] = val;
  });
}

export const transformCategoricalDataToPlotPoints = (serverData, formula) => {
  const x = [];
  const y = [];
  serverData.forEach(item => {
    x.push(formatToLocal(item.x));
    y.push(
      evaluate(formula.toLowerCase(), {
        [CATEGORICAL_FEATURE_CUSTOM_METRIC_PROPERTY]: transformKeysLowercase(
          item.y
        )
      })
    );
  });
  return {
    x,
    y,
    line: {
      color: '#5899DA',
      dash: 'solid'
    },
    mode: x.length === 1 ? 'markers' : 'lines'
  };
};

export const transformNumericalDataToPlotPoints = (serverData, formula) => {
  const x = [];
  const y = [];
  serverData.forEach(item => {
    x.push(item.x);
    y.push(
      evaluate(formula.toLowerCase(), {
        [NUMERICAL_FEATURE_CUSTOM_METRIC_PROPERTY]: item.y
      })
    );
  });
  return {
    x,
    y,
    line: {
      color: '#5899DA',
      dash: 'solid'
    },
    mode: x.length === 1 ? 'markers' : 'lines'
  };
};

const mappedFeaturesToColors = {};

const getColorForFeature = (value, index, colors) => {
  const color = mappedFeaturesToColors[value];
  if (color) {
    return color;
  }

  const takenColors = Object.values(mappedFeaturesToColors);

  const firstAvailableColor = colors.find(
    color => !takenColors.includes(color)
  );

  mappedFeaturesToColors[value] =
    firstAvailableColor || colors.map[index % colors.length];

  return mappedFeaturesToColors[value];
};

const transformFeatureToBarPlot = (featureData, colors, intervalType) => {
  const results = [];

  featureData.forEach(({ data, value }, index) => {
    const result = {
      x: [],
      y: [],
      name: value,
      marker: {
        color: getColorForFeature(value, index, colors),
        opacity: 1
      },
      type: 'bar'
    };

    data?.forEach(({ x, y }) => {
      result.x.push(formatToLocal(x));
      result.y.push(y);
    });

    results.push(result);
  });

  return addGeneralHoverTemplate(results, intervalType);
};

export const transformFeaturesToBarPlot = (segment, colors, intervalType) =>
  flatten(
    segment.map(({ featureData }) =>
      transformFeatureToBarPlot(featureData, colors, intervalType)
    )
  );

const transformSegmentToAreaPlotData = (
  intervalType,
  segment,
  color = '',
  firstPercentileName,
  secondPercentileName,
  segmentColorIds
) => {
  const medianPercentile = 'p50';
  const medianData =
    segment.percentiles.find(({ value }) => value === medianPercentile)?.data ||
    [];

  const startArea =
    segment.percentiles.find(({ value }) => value === firstPercentileName)
      ?.data || [];
  const endArea =
    segment.percentiles.find(({ value }) => value === secondPercentileName)
      ?.data || [];

  const fillColor = (color && changeColorOpacity(color, 0.14)) || '';

  const setHoverData = (y, index, x) => {
    if (startArea[index] && endArea[index] && medianData[index])
      return {
        min: startArea[index].y,
        max: endArea[index].y,
        median: medianData[index].y,
        date: formatHoverDate(x, intervalType)
      };
  };

  const legendValue =
    segmentColorIds.find(item => item.id === segment?.segment?.id)
      ?.segmentValue || segment?.segment?.segmentValue;
  const segmentId = segment?.segment?.id;

  const result = [
    {
      x: [],
      y: [],
      text: [],
      percentileType: 'min',
      type: 'scatter',
      fillcolor: fillColor,
      mode: 'lines',
      hovertemplate: setHovertemplate({
        type: 'start',
        secondPercentileName,
        firstPercentileName
      }),
      line: { width: 0, color },
      segmentId
    },
    {
      x: [],
      y: [],
      text: [],
      percentileType: 'max',
      type: 'scatter',
      mode: 'lines',
      fillcolor: fillColor,
      line: { width: 0, color },
      hovertemplate: setHovertemplate({
        type: 'end',
        secondPercentileName,
        firstPercentileName
      }),
      fill: 'tonexty',
      segmentId
    },
    // median
    {
      x: [],
      y: [],
      text: [],
      percentileType: 'median',
      hovertemplate: setHovertemplate({
        type: 'median',
        secondPercentileName,
        firstPercentileName
      }),
      lineTrace: true,
      segmentId,
      type: 'scatter',
      mode: 'lines',
      name: legendValue,
      line: {
        color
      }
    }
  ];

  startArea.forEach(({ x, y }, index) => {
    result[0].x.push(formatToLocal(x));
    result[0].y.push(y);
    result[0].text.push(setHoverData(y, index, x));
  });

  endArea.forEach(({ x, y }, index) => {
    result[1].x.push(formatToLocal(x));
    result[1].y.push(y);
    result[1].text.push(setHoverData(y, index, x));
  });

  medianData.forEach(({ x, y }, index) => {
    result[2].x.push(formatToLocal(x));
    result[2].y.push(y);
    result[2].text.push(setHoverData(y, index, x));
  });

  if (!startArea?.length || !endArea?.length) {
    return result[2];
  }

  return result;
};

const findColorById = (colorsMap, segmentId) => {
  return colorsMap.find(item => item?.id === segmentId)?.color;
};

export const transformSegmentsToAreaPlotData = (
  intervalType,
  segments,
  colors = [],
  segmentColorIds = [],
  firstPercentileName,
  secondPercentileName
) =>
  flatten(
    segments.map((item, i) => {
      const color =
        findColorById(segmentColorIds, item.segment?.id) || get(colors, i, '');
      return transformSegmentToAreaPlotData(
        intervalType,
        item,
        color,
        firstPercentileName,
        secondPercentileName,
        segmentColorIds
      );
    })
  );

// minmax -> min, max
// p10-p90 -> p10, p90
export const transformModeToPercentileValues = mode => {
  if (mode === PERCENTILE_OPTION_LIST[0].value) {
    return [PERCENTILE_VALUES.MIN, PERCENTILE_VALUES.MAX];
  }

  if (mode === PERCENTILE_OPTION_LIST[3].value) {
    return [null, null];
  }

  return mode.split('-');
};

const getYs = data => data.y;

const transformSegmentToStackedPlotData = (segment, colors, intervalType) => {
  const plotFeatures = [];

  segment.featureData?.forEach((feature, index) => {
    const color = selectColorFromList(colors, index, '');
    const result = {
      x: [],
      y: [],
      stackgroup: 'one',
      name: feature.name,
      featureId: feature?.value,
      line: {
        color
      },
      hovertemplate: `%{text.date},<br>Current: <b>%{y}</b><br>Total: <b>%{text.sum}</b>`
    };

    feature.data?.forEach(({ x, y }) => {
      result.x.push(formatToLocal(x));
      result.y.push(y);
    });

    if (color) {
      result.fillcolor = changeColorOpacity(color, 0.3);
    }

    plotFeatures.push(result);
  });

  plotFeatures.sort((featureA, featureB) => {
    const maxValueA = Math.max(...getYs(featureA));
    const maxValueB = Math.max(...getYs(featureB));

    return maxValueA - maxValueB;
  });

  plotFeatures.forEach((feature, featureIndex) => {
    feature.text = feature.y.map((y, yIndex) => {
      const x = feature.x[yIndex];
      const sum =
        !featureIndex || !plotFeatures[featureIndex - 1]?.text?.[yIndex]?.sum
          ? y
          : plotFeatures[featureIndex - 1].text[yIndex]?.sum + y;

      return {
        sum,
        date: formatHoverDate(x, intervalType)
      };
    });
  });

  return plotFeatures;
};

export const transformSegmentsToStackedPlotData = (
  segments,
  colors = [],
  intervalType
) => {
  const result = segments.map(segment =>
    transformSegmentToStackedPlotData(segment, colors, intervalType)
  );

  return flatten(result);
};

const getFormattedPoints = data => {
  const countPoints = data.length;
  const xAxis = Array(countPoints);
  const yAxis = Array(countPoints);
  data?.forEach(({ x, y }, index) => {
    xAxis[index] = x;
    yAxis[index] = y;
  });
  return { xAxis, yAxis };
};

export const transformToLinePlotFromBarData = (
  serverData,
  colors = [],
  intervalType,
  segmentsIdColorMap
) => {
  const groupedData = [];
  for (let i = 0; i < serverData.length; i++) {
    const feature = serverData[i];
    const { id } = feature.segment;
    const { alertNotifications } = feature;
    for (let j = 0; j < feature.featureData.length; j++) {
      const valueData = feature.featureData[j];
      const { xAxis, yAxis } = getFormattedPoints(valueData.data);
      const lineData = {
        x: xAxis.map(xVal => formatToLocal(xVal)),
        y: yAxis,
        segmentId: id,
        alertNotifications,
        line: {
          color: segmentsIdColorMap[id] || get(colors, i, ''),
          dash: 'solid'
        },
        name: segmentsIdColorMap[`${id}_name`],
        mode: xAxis.length === 1 ? 'markers' : 'lines',
        featureValue: valueData.value
      };
      groupedData.push(lineData);
    }
  }
  return groupedData;
};

export const transformNumericalDistributionToPlotData = ({
  serverResponseData,
  hoverLineData,
  segmentColorIds
}) => {
  const { xAxis } = hoverLineData || {};

  const filteredData = serverResponseData.data.map(item => {
    const lineColor = segmentColorIds.find(
      colorsId => colorsId.id === item.segment.id
    )?.color;
    const itemSegment = { ...item.segment, color: lineColor };
    if (xAxis) {
      const xKey = moment(xAxis).utc().toISOString();
      // TODO: Remove if block when backend fixes date format value
      // With if block both variants will work
      if (item.graphs.distributionsPerTimeInterval[xKey]) {
        return {
          ...item.graphs.distributionsPerTimeInterval[xKey],
          segment: itemSegment
        };
      }
      return {
        // ...item.graphs.distributionsPerTimeInterval[xAxis],
        ...item.graphs.distributionsPerTimeInterval[xKey.slice(0, -5) + 'Z'],
        segment: itemSegment
      };
    }

    return { ...item.graphs.wholeTimerangeDistribution, segment: itemSegment };
  });

  const lineData = filteredData.map(segmentItem => {
    const xAxis = [];
    const yAxis = [];
    if (!segmentItem.graphPoints) return {};
    segmentItem.graphPoints.forEach(({ x, y }) => {
      xAxis.push(x);
      yAxis.push(y);
    });
    const isSingleDot = xAxis.length === 1;
    return {
      x: xAxis,
      y: yAxis,
      fill: 'tozeroy',
      fillcolor: `${segmentItem.segment.color}` + 25,
      line: {
        color: segmentItem.segment.color || DEFAULT_SEGMENTS_LINE_COLOR,
        dash: 'solid',
        shape: 'spline'
      },
      name: segmentItem.segment.segmentValue,
      mode: isSingleDot ? 'markers' : 'lines'
    };
  });

  return { lineData };
};

const checkIsCategoricalElement = elem => !!elem?.featureData;

export const getMPMPanelURL = (
  workspaceId,
  modelId,
  modelFeatureId,
  section
) => {
  const baseUrl = `mpm/${workspaceId}/models/${modelId}`;

  return modelFeatureId
    ? `${baseUrl}/features/${modelFeatureId}/${PANEL_DATA_URL_MAP_SECTION[section]}`
    : `${baseUrl}/${PANEL_DATA_URL_MAP_SECTION[section]}`;
};

const isForAllResponse = segmentIds => {
  return segmentIds.length === 1 && segmentIds[0] === DEFAULT_SEGMENTS_ID;
};

const RESPONSE_MESSAGE_NOT_SUPPORTED_SEGMENTS = 'segments not supported';

export const getMPMChartType = (
  section,
  data,
  transformType,
  additionalInfo,
  segmentIds,
  isLabeled,
  title
) => {
  const segmentsResponseData = !isForAllResponse(segmentIds);
  if (
    (!isLabeled && title === PANEL_NAMES.ACCURACY_METRICS) ||
    (segmentsResponseData &&
      NON_HANDLED_SEGMENTS_SECTIONS.includes(section) &&
      additionalInfo === RESPONSE_MESSAGE_NOT_SUPPORTED_SEGMENTS)
  ) {
    return CHART_TYPES.NON_SUPPORTED_SEGMENTS;
  }

  const chartType = CHART_TYPES_BY_SECTION[section];

  if (chartType === CHART_TYPES.MULTIPLE_TYPE_CHART) {
    const isCategorical = checkIsCategoricalElement(data[0]);
    let dataType;
    if (segmentsResponseData) {
      dataType = isCategorical
        ? MPM_CHART_DATA_TYPES.SEGMENTS_CATEGORICAL
        : MPM_CHART_DATA_TYPES.SEGMENTS_NUMERICAL;
    } else {
      dataType = isCategorical
        ? MPM_CHART_DATA_TYPES.CATEGORICAL
        : MPM_CHART_DATA_TYPES.NUMERICAL;
    }

    return MULTIPLE_TYPE_SECTIONS[section][dataType];
  }

  return chartType;
};

export const getMPMOperatorsForRule = (source, type) => {
  return get(MPM_OPERATOR_FILTERS, type, []);
};

export * from './utilsWithTypes';
export * from './helpers';
