import React from 'react';
import PropTypes from 'prop-types';

import useWorkspaceMembers from '@API/workspace/useWorkspaceMembers';
import useInvitesForTeam from '@API/workspace/useInvitesForTeam';

import ManageUsersModalTable from '@shared/components/ManageUsersModalTable';
import useCurrentUser from '@API/auth/useCurrentUser';

import './WorkspaceMembersTable.scss';

const WorkspaceMembersTable = ({ workspaceId, workspaceName }) => {
  const { data: members = [] } = useWorkspaceMembers(workspaceId);
  const { data: invitedMembers = [] } = useInvitesForTeam(workspaceId);
  const { data: user = {} } = useCurrentUser() || { data: {} };

  return (
    <ManageUsersModalTable
      workspaceName={workspaceName}
      disableColumnsWidthChange
      disableActionColumns
      filterValue=""
      members={[...members, ...invitedMembers]}
      currentUser={user}
    />
  );
};

WorkspaceMembersTable.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default WorkspaceMembersTable;
