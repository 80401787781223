import { useRefSizes } from '@shared/hooks';
import React from 'react';

type ChildListener = (maxHeight: number) => React.ReactNode;

type TableMaxHeightProviderProps = {
  children: React.ReactNode | ChildListener;
};
const TableMaxHeightProvider: React.FC<TableMaxHeightProviderProps> = ({
  children
}) => {
  const { refHeight = 0, sizeRef } = useRefSizes();

  let height = 700;

  if (refHeight !== 0) {
    height = refHeight;
  }

  const style = {
    '--table-maxheight': `${height}px`,
    height: '100%'
  } as React.CSSProperties;

  return (
    <div ref={sizeRef} style={style} className="table-height-provider">
      {typeof children === 'function'
        ? (children as ChildListener)(height)
        : children}
    </div>
  );
};

export default TableMaxHeightProvider;
