import React from 'react';
import FormMetricModal from './FormMetricModal';
import { ExperimentDetails, Metric } from '@shared/types';
import useEditMetricApi from '../hooks/useEditMetricApi';

export type EditMetricParamModalProps = {
  experiment: ExperimentDetails;
  onClose: () => void;
  isOpened: boolean;
  defaultFormData: Metric;
};
const EditMetricParamModal: React.FC<EditMetricParamModalProps> = ({
  experiment,
  onClose,
  isOpened,
  defaultFormData
}) => {
  const editModalConfig = useEditMetricApi({
    experiment,
    onClose,
    isOpened,
    defaultFormData
  });
  return (
    <FormMetricModal
      {...editModalConfig}
      title="Edit metric"
      primaryButtonText="Done"
      isEditMode
      isOpened={isOpened}
    />
  );
};

export default EditMetricParamModal;
