import { IS_ON_PREMISE, CONTACT_US_URL } from './configConstants';

const BASE_URL = 'https://www.comet.com';

/* eslint-disable import/prefer-default-export */
const URLS = {
  ABOUT_US: '/site/about-us/',
  BLOG: '/site/blog/',
  CAREERS: '/site/career/',
  CONTACT_US_NEW_SITE: CONTACT_US_URL || '/site/about-us/contact-us/',
  DOCS: '/docs/v2/',
  FAQ: '/site/faq/',
  PRICING: '/site/pricing/',
  PRIVACY: '/site/privacy-policy/',
  RELEASES: '/docs/releases/',
  REPORTS: '/site/report-library',
  ROOT: '/site/',
  TERMS: '/site/terms-of-service/'
};

if (IS_ON_PREMISE) {
  Object.entries(URLS).forEach(([key, path]) => {
    if ([URLS.DOCS, URLS.RELEASES].includes(path)) return;

    URLS[key] = `${BASE_URL}${path}`;
  });
}

export { URLS };

export const SUB_PATHS = {
  MODEL_REGISTRY: 'model-registry',
  LATEST_REVISION: 'latest',
  ARTIFACTS: 'artifacts',
  ARCHIVE: 'archive',
  MODEL_PRODUCTION_MONITORING: 'model-production-monitoring'
};

export const DOCS_LINK_TYPE = {
  ASSETS: 'ASSETS',
  AUDIO: 'AUDIO',
  CODE: 'CODE',
  CONFUSION_MATRIX: 'CONFUSION_MATRIX',
  GRAPH: 'GRAPH',
  GRAPH_DEFINITION: 'GRAPH_DEFINITION',
  HISTOGRAM: 'HISTOGRAM',
  HTML: 'HTML',
  HYPER_PARAMETERS: 'HYPER_PARAMETERS',
  IMAGES: 'IMAGES',
  INIT_EXPERIMENT: 'INIT_EXPERIMENT',
  INSTALLED_PACKAGES: 'INSTALLED_PACKAGES',
  MANAGE_VISIBILITY_SECTION: 'MANAGE_VISIBILITY_SECTION',
  METRICS: 'METRICS',
  OTHER: 'OTHER',
  JAVASCRIPT_PANELS: 'JAVASCRIPT_PANELS',
  PYTHON_PANELS: 'PYTHON_PANELS',
  STDOUT: 'STDOUT',
  SYSTEM_METRICS: 'SYSTEM_METRICS',
  TEXT: 'TEXT'
};

export const DOCS_LINKS = {
  [DOCS_LINK_TYPE.ASSETS]: {
    label: 'Assets',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_asset'
  },
  [DOCS_LINK_TYPE.AUDIO]: {
    label: 'Audios',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_audio'
  },
  [DOCS_LINK_TYPE.CODE]: {
    label: 'Log Code',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_code'
  },
  [DOCS_LINK_TYPE.CONFUSION_MATRIX]: {
    label: 'Confusion Matrices',
    path:
      'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_confusion_matrix'
  },
  [DOCS_LINK_TYPE.GRAPH]: {
    label: 'Graphs',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_metric'
  },
  [DOCS_LINK_TYPE.GRAPH_DEFINITION]: {
    label: 'Graph Definitions',
    path:
      'api-and-sdk/python-sdk/reference/Experiment/#experimentset_model_graph'
  },
  [DOCS_LINK_TYPE.HISTOGRAM]: {
    label: 'Histograms',
    path:
      'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_histogram_3d'
  },
  [DOCS_LINK_TYPE.HTML]: {
    label: 'HTML',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_html'
  },
  [DOCS_LINK_TYPE.HYPER_PARAMETERS]: {
    label: 'Hyperparameters',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_parameter'
  },
  [DOCS_LINK_TYPE.IMAGES]: {
    label: 'Images',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_image'
  },
  [DOCS_LINK_TYPE.INIT_EXPERIMENT]: {
    label: 'Experiment Init',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentinit'
  },
  [DOCS_LINK_TYPE.INSTALLED_PACKAGES]: {
    label: 'Set Installed packages',
    path:
      'api-and-sdk/python-sdk/reference/Experiment/#experimentset_pip_packages'
  },
  [DOCS_LINK_TYPE.MANAGE_VISIBILITY_SECTION]: {
    label: 'Manage Visibility',
    path: 'guides/comet-dashboard/find-your-way/#manage-projects'
  },
  [DOCS_LINK_TYPE.METRICS]: {
    label: 'Metrics',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_metric'
  },
  [DOCS_LINK_TYPE.OTHER]: {
    label: 'Log Other',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_other'
  },
  [DOCS_LINK_TYPE.JAVASCRIPT_PANELS]: {
    label: 'Panels',
    path: 'api-and-sdk/javascript-panels-sdk/overview/'
  },
  [DOCS_LINK_TYPE.STDOUT]: {
    label: 'Output',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentinit'
  },
  [DOCS_LINK_TYPE.SYSTEM_METRICS]: {
    label: 'System Metrics',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentinit'
  },
  [DOCS_LINK_TYPE.TEXT]: {
    label: 'Texts',
    path: 'api-and-sdk/python-sdk/reference/Experiment/#experimentlog_text'
  },
  [DOCS_LINK_TYPE.PYTHON_PANELS]: {
    label: 'Panels',
    path: 'guides/comet-dashboard/code-panels/python-panels/'
  }
};

export const ROOT_ROUTES = [
  '404',
  'about',
  'academics',
  'account-settings',
  'auth',
  'careers',
  'claim',
  'closed',
  'contact',
  'embedded-panel',
  'claim-anonymous',
  'faq',
  'get-started',
  'login',
  'organizations',
  'pricing',
  'privacy',
  'reset-password',
  'signup',
  'space',
  'spaces',
  'terms'
];

export const WORKSPACE_ROUTES = [
  'artifacts',
  'model-production-monitoring',
  'model-registry',
  'quickstart',
  'settings'
];
