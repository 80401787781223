import React from 'react';
import PropTypes from 'prop-types';

import DialogContentText from '@material-ui/core/DialogContentText';
import { BasicModal } from '@DesignSystem/modals';
import { SUPPORT_EMAIL } from '@/lib/appConstants';
import './LicenseTokenExpiredModal.scss';

function LicenseTokenExpiredModal({ content }) {
  return (
    <BasicModal
      title="Your license expired"
      hideCross
      open
      content={
        <DialogContentText>
          <span className="modal-group">
            <i className="material-icons warning-icon">warning</i>
            {content}
          </span>
        </DialogContentText>
      }
    />
  );
}

LicenseTokenExpiredModal.defaultProps = {
  content: `Installation Token has expired, please contact us at ${SUPPORT_EMAIL}`
};

LicenseTokenExpiredModal.propTypes = {
  content: PropTypes.string
};

export default LicenseTokenExpiredModal;
