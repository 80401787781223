import { EmptyExperimentTab } from '@shared';
import React from 'react';
import { EmptyTabCellProps } from '../shared/ComparisonTable/types';

const MetricsTabEmpty = ({
  isComparePage,
  ...cellProps
}: EmptyTabCellProps) => {
  const experimentText = isComparePage
    ? 'These experiments'
    : 'This experiment';
  return (
    <EmptyExperimentTab.Cell {...cellProps}>
      <EmptyExperimentTab>
        <EmptyExperimentTab.Icon iconName="metrics" />
        <EmptyExperimentTab.Title>
          {experimentText} did not log any metrics
        </EmptyExperimentTab.Title>
        <EmptyExperimentTab.Description>
          Use <b>log_metric()</b> on your Experiment object to log metrics
          during experiments.
        </EmptyExperimentTab.Description>
        <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/metrics-and-parameters/" />
      </EmptyExperimentTab>
    </EmptyExperimentTab.Cell>
  );
};

export default MetricsTabEmpty;
