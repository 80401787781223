// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenu-module__userMenuContainer--KcGat{translate:0px 6px;width:240px;padding-bottom:4px;box-shadow:-5px 8px 12px rgba(0,0,0,.12),0px 0px 1px rgba(0,0,0,.14)}.UserMenu-module__userMenuContainer--KcGat.MuiPaper-rounded{border-radius:5px}.UserMenu-module__divider--A6CY5{margin-top:4px;margin-bottom:4px;background:#e2e2e2;height:1px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenuContainer": `UserMenu-module__userMenuContainer--KcGat`,
	"divider": `UserMenu-module__divider--A6CY5`
};
module.exports = ___CSS_LOADER_EXPORT___;
