import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const YLabelCell = ({
  cellValue,
  cellSize,
  cellFontSize,
  mousePosition,
  yCoordinate
}) => {
  const activeRowCell = mousePosition?.y === yCoordinate;
  return (
    <div
      className={cx('confusion-matrix-chart-ylabel-cell', {
        'confusion-matrix-chart-ylabel-cell-activeCell': activeRowCell
      })}
      style={{
        fontSize: cellFontSize,
        height: cellSize
      }}
    >
      <span
        className="confusion-matrix-chart-ylabel-cell-content"
        style={{
          height: cellSize,
          lineHeight: `${cellSize}px`
        }}
      >
        {cellValue}
      </span>
    </div>
  );
};

YLabelCell.defaultProps = {
  mousePosition: null
};

YLabelCell.propTypes = {
  cellValue: (PropTypes.string || PropTypes.number).isRequired,
  mousePosition: PropTypes.object || null,
  cellSize: PropTypes.number.isRequired,
  cellFontSize: PropTypes.number.isRequired,
  yCoordinate: PropTypes.number.isRequired
};
