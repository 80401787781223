export const DEFAULT_CUSTOM_TRANSFORMATIONS_SORTING = [
  { columnName: 'name', direction: 'asc' }
];

export const CUSTOM_TRANSFORMATIONS_COLUMNS = [
  {
    name: 'name',
    title: 'METRIC NAME',
    id: 'name--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'name',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'cometSql',
    title: 'METRIC DEFINITION',
    id: 'cometSql--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'cometSql',
    hideForFilters: true,
    hideForSorting: true
  },
  {
    name: 'actions',
    title: ' ',
    id: 'actions--metadata',
    type: 'string',
    source: 'metadata',
    serverField: 'metricId',
    hideForFilters: true,
    hideForSorting: true
  }
];

export const CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS = ['actions'];

export const RIGHT_COLUMNS = ['actions'];

export const CUSTOM_TRANSFORMATIONS_TABLE_HEIGHT = 223;

export const NAV_ITEMS_VALUES = {
  MODEL_OVERVIEW: 'modelOverview',
  CUSTOM_METRICS: 'customMetrics'
};

export const CLASSIFICATION_METRICS = [
  {
    name: 'Accuracy',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: false
  },
  {
    name: 'F1',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  },
  {
    name: 'FNR',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  },
  {
    name: 'FPR',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  },
  {
    name: 'Precision',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  },
  {
    name: 'Recall',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  },
  {
    name: 'TNR',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  },
  {
    name: 'TPR',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: true
  }
] as const;

export const REGRESSION_METRICS = [
  {
    name: 'MAE',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: false
  },
  {
    name: 'MAPE',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: false
  },
  {
    name: 'MAX_ERROR',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: false
  },
  // {
  //   name: 'R2SCORE',
  //   labelColumn: true,
  //   predictionColumn: true,
  //   featureColumn: false,
  //   customValueColumn: false
  // },
  {
    name: 'RMSE',
    labelColumn: true,
    predictionColumn: true,
    featureColumn: false,
    customValueColumn: false
  },
  {
    name: 'VAR_POP',
    labelColumn: false,
    predictionColumn: false,
    featureColumn: true,
    customValueColumn: false
  },
  {
    name: 'VAR_SAMP',
    labelColumn: false,
    predictionColumn: false,
    featureColumn: true,
    customValueColumn: false
  }
] as const;

export const DELETE_METRIC_BODY_TEXT = (name: string) => {
  return `The metric '${name}' will be permanently deleted and cannot be recovered.`;
};

export const TOOLTIP_MESSAGE =
  'Data drift will be computed by comparing the baseline distribution to the latest distribution in production';
