import React from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from '@Icons-outdated';

import cx from 'classnames';
import styles from './TreeTable.module.scss';

const ExpandTreeCell = ({ visible, expanded, onToggle }) => {
  return (
    <div className={styles.expandIconContainer} onClick={onToggle}>
      {visible && (
        <ArrowIcon className={cx({ [styles.rotatedIcon]: expanded })} />
      )}
    </div>
  );
};

ExpandTreeCell.propTypes = {
  visible: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default ExpandTreeCell;
