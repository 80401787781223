import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';
import { getModelNameValidationTooltipText } from '@/constants/modelRegistryConstants';

const ManageModelName = ({
  modelName,
  modelNameValidation,
  model,
  handleModelNameChange,
  isInvalidModelName
}) => {
  return (
    <div className="generic-modal-section">
      <InputLabel className="modal-input-label">Name</InputLabel>
      <StyledValidationTooltip
        title={getModelNameValidationTooltipText(
          modelName,
          modelNameValidation
        )}
        open={
          (isInvalidModelName || isEmpty(modelName)) &&
          !isEqual(modelName, model.modelName)
        }
      >
        <input
          className="native-input"
          onChange={handleModelNameChange}
          value={modelName}
          placeholder="Enter model name"
        />
      </StyledValidationTooltip>
    </div>
  );
};

ManageModelName.propTypes = {
  modelName: PropTypes.string.isRequired,
  modelNameValidation: PropTypes.shape({
    finalModelName: PropTypes.string.isRequired,
    modelNameExists: PropTypes.bool.isRequired,
    originalModelName: PropTypes.string.isRequired
  }).isRequired,
  model: PropTypes.shape({
    versions: PropTypes.array,
    modelName: PropTypes.string
  }).isRequired,
  handleModelNameChange: PropTypes.func.isRequired,
  isInvalidModelName: PropTypes.bool.isRequired
};

export default ManageModelName;
