import cx from 'classnames';
import { findIndex, round } from 'lodash';
import React, { ComponentRef, useMemo, useRef, useState } from 'react';
import PrismaZoom from 'react-prismazoom';

import { usePreloadGraphics } from '../Charts/NoPlotlyCharts/ImagePanel/usePreloadGraphics';
import { ZoomImage } from '@DesignSystem/images';
import { ImageWithAnnotations } from '@DesignSystem/images/ImageWithAnnotations/ImageWithAnnotations';
import { ZoomInIcon, ZoomOutIcon } from '@Icons-outdated';
import { useVisibleAnnotations } from '../Charts/NoPlotlyCharts/ImagePanel/useVisibleAnnotations';

import styles from './ImageDetail.module.scss';
import { GraphicDetailsAsset } from '../Charts';
import { PRE_FETCH_IMAGE_COUNT } from '@/constants/configConstants';
import { ImageMetadata } from '@experiment-management-shared';

const prefetchAssetAmount = PRE_FETCH_IMAGE_COUNT as number;
const IMAGE_ZOOM_STEP = 0.2;
const MIN_ZOOM_LEVEL = 100;
const MAX_ZOOM_LEVEL = 1000;

export type ImageDetail = GraphicDetailsAsset & {
  imagePath: string;
  originalImageWidth?: number;
  originalImageHeight?: number;
};

type ImageDetailProps = {
  asset: ImageDetail;
  assets: ImageDetail[];
  isGrayScale?: boolean;
  isSmoothed?: boolean;
  hiddenLabelNames?: string[];
  metadata?: ImageMetadata;
  score?: number;
  isLabelsTextHidden?: boolean;
  title?: string;
};

export const ImageDetail: React.FC<ImageDetailProps> = ({
  asset,
  assets,
  hiddenLabelNames,
  isGrayScale = false,
  isSmoothed = false,
  metadata,
  score = 0,
  isLabelsTextHidden,
  title
}) => {
  const visibleAnnotations = useVisibleAnnotations({
    annotations: metadata?.annotations,
    hiddenLabels: hiddenLabelNames,
    score
  });

  const [zoomLevel, setZoomLevel] = useState(100);
  const zoomRef = useRef<ComponentRef<typeof PrismaZoom>>(null);
  const [isLoadingAsset, setIsLoadingAsset] = useState(true);

  const zoomActionHandler = (value: number) => {
    setZoomLevel(round(value * 100));
  };

  const currentAssetIndex = useMemo(() => {
    return findIndex(assets, { id: asset.id });
  }, [asset.id, assets]);

  const prefetchAssetsSrcs = useMemo(() => {
    const startValue = Math.max(currentAssetIndex - prefetchAssetAmount, 0);
    const endValue = Math.min(
      currentAssetIndex + prefetchAssetAmount + 1,
      assets.length
    );

    const previousImages = assets
      .slice(startValue, currentAssetIndex)
      .reverse();
    const nextImages = assets.slice(currentAssetIndex + 1, endValue);

    const orderedImages = interleaveArrays(nextImages, previousImages);

    return orderedImages.map(image => image.imagePath);
  }, [currentAssetIndex, assets]);

  usePreloadGraphics({ enabled: !isLoadingAsset, sources: prefetchAssetsSrcs });

  return (
    <div className={styles.container}>
      <div className={styles.zoomControlContainer}>
        <ZoomOutIcon
          onClick={() => zoomRef.current?.zoomOut(IMAGE_ZOOM_STEP)}
          className={cx(styles.zoomIconWrapper, {
            [styles.zoomActive]: zoomLevel > MIN_ZOOM_LEVEL
          })}
        />
        <p>{zoomLevel}%</p>
        <ZoomInIcon
          onClick={() => zoomRef.current?.zoomIn(IMAGE_ZOOM_STEP)}
          className={cx(styles.zoomIconWrapper, {
            [styles.zoomActive]: zoomLevel < MAX_ZOOM_LEVEL
          })}
        />
      </div>

      <div className={styles.image}>
        <ZoomImage
          ref={zoomRef}
          renderCustomImageComponent={(
            maxHeight: number,
            imageWidth: number
          ) => (
            <ImageWithAnnotations
              alt={asset.name}
              annotations={visibleAnnotations}
              imagePath={asset.imagePath}
              isGrayScale={isGrayScale}
              isSmoothed={isSmoothed}
              labelColorMap={metadata?.labelColorMap}
              onImageLoaded={() => setIsLoadingAsset(false)}
              placeholderSrc={asset.thumbnailPath}
              zoomLevel={zoomLevel}
              isLabelsTextHidden={isLabelsTextHidden}
              maxHeight={maxHeight}
              imageWidth={imageWidth}
              originalImageSize={{
                originalImageWidth: asset.originalImageWidth ?? 0,
                originalImageHeight: asset.originalImageHeight ?? 0
              }}
            />
          )}
          zoomActionHandler={zoomActionHandler}
          alt={undefined}
          disableZooming={undefined}
          src={undefined}
          className={undefined}
        />
      </div>
      {title && <p className={styles.title}>{title}</p>}
    </div>
  );
};

const interleaveArrays = <T,>(arr1: T[], arr2: T[]) => {
  const result = [];
  const maxLength = Math.max(arr1.length, arr2.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < arr1.length) {
      result.push(arr1[i]);
    }
    if (i < arr2.length) {
      result.push(arr2[i]);
    }
  }

  return result;
};
