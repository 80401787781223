import { NEW_VIEW } from '@/constants/dashboardConstants';
import { projectViewsStore } from '@projects/utils';
import { useMutation, useQueryClient } from 'react-query';
import api from '../api';
import useProject from './useProject';

const removeView = async ({
  canEdit,
  createdFromTemplateId,
  isLocalView,
  projectId,
  viewId
}: {
  canEdit: boolean;
  createdFromTemplateId?: string;
  isLocalView: boolean;
  projectId: string;
  viewId?: string;
}) => {
  const hasLocalView = await projectViewsStore.get({
    projectId,
    viewId: createdFromTemplateId ?? NEW_VIEW
  });

  if (hasLocalView) {
    projectViewsStore.delete([projectId, createdFromTemplateId ?? NEW_VIEW]);
  }

  if (!canEdit || isLocalView) return;

  return api.post('dashboard-templates/project/delete', {
    project_id: projectId,
    template_id: viewId
  });
};

export default function useRemoveViewMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({
      isLocalView = false,
      createdFromTemplateId,
      projectId: viewProjectId = projectId,
      viewId
    }: {
      isLocalView?: boolean;
      createdFromTemplateId?: string;
      projectId?: string;
      viewId?: string;
    }) => {
      return removeView({
        canEdit: project?.canEdit ?? false,
        createdFromTemplateId,
        isLocalView,
        projectId: viewProjectId,
        viewId
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projectViews', { projectId }]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['projectViews', { projectId }]);
      }
    }
  );
}
