import React from 'react';
import PropTypes from 'prop-types';
import OptionsEditorJSON from '@experiment-management-shared/components/OptionsEditorJSON';

export const ModalOptionsContainer = ({
  optionsToResetTo,
  onChangeModifiedOptions,
  currentOptions,
  isPy
}) => {
  const onSetOptions = value => {
    onChangeModifiedOptions(value);
  };

  return (
    currentOptions &&
    isPy !== null && (
      <OptionsEditorJSON
        options={currentOptions}
        onApply={onSetOptions}
        optionsToResetTo={optionsToResetTo}
      />
    )
  );
};

ModalOptionsContainer.propTypes = {
  optionsToResetTo: PropTypes.object.isRequired,
  onChangeModifiedOptions: PropTypes.func.isRequired,
  currentOptions: PropTypes.object.isRequired,
  isPy: PropTypes.bool.isRequired
};

export default ModalOptionsContainer;
