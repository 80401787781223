import React from 'react';
import { withRouter } from 'react-router';
import { isValidEmail } from '@/lib/utils';
import DialogContent from '@material-ui/core/DialogContent';
import alertsActions from '@/actions/alertsActions';
import forgotPasswordActions from '@/actions/forgotPasswordActions';
import { forgotPasswordActionTypes } from '@/constants/actionTypes';
import { dialogTypes } from '@/constants/alertTypes';
import { FORGOT_PASSWORD_STEPS } from '@/lib/appConstants';
import GenericModal from '@shared/components/GenericModal';
import ForgotPasswordFillEmail from './ForgotPasswordFillEmail';
import ChangePasswordModal from './ChangePasswordModal';
import withForgotPassword from './withForgotPassword';
import {
  EMAIL_SENT,
  INVALID_EMAIL_ADDRESS,
  LOADING_SEND_EMAIL,
  NOT_MATCH_PASSWORD,
  ON_SUCCESS,
  TOO_SHORT_PASSWORD
} from './texts';

const CATEGORY = 'FORGOT_PASSWORD';

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.buttonAction = this.buttonAction.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderEmailSent = this.renderEmailSent.bind(this);
    this.dispatchError = this.dispatchError.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch({ type: forgotPasswordActionTypes.RESET_STATE });
    this.props.dispatch(alertsActions.closeDialog(dialogTypes.FORGOT_PASSWORD));
  }

  buttonAction() {
    const {
      step,
      emailForm,
      onFinish,
      passwordForm,
      token,
      buttonText,
      dispatch
    } = this.props;

    if (buttonText === 'Go back') {
      dispatch({ type: forgotPasswordActionTypes.RESET_STATE });
    } else if (step === FORGOT_PASSWORD_STEPS.FILL_EMAIL) {
      /**
       Fill email address to send email to
       * */
      const email = emailForm?.values?.email;
      const apiFunctionArgs = { email };
      if (!isValidEmail(email)) {
        this.dispatchError(INVALID_EMAIL_ADDRESS);
        return;
      }
      this.props.dispatch(
        forgotPasswordActions.sendEmail({ apiFunctionArgs }, false, CATEGORY)
      );
    } else if (step === FORGOT_PASSWORD_STEPS.FILL_PASSWORD) {
      /**
       Choose new password step
      * */
      const { password, confirmPassword } = passwordForm.values;
      if (!password || !confirmPassword || password !== confirmPassword) {
        /** Error: no passwords or no match * */
        this.dispatchError(NOT_MATCH_PASSWORD);
      } else if (password.length < 7) {
        /** Error: pass should be at least 6 chars * */
        this.dispatchError(TOO_SHORT_PASSWORD);
      } else {
        const apiFunctionArgs = {
          password,
          resetToken: token
        };
        this.props.dispatch(
          forgotPasswordActions.sendNewPassword(
            { apiFunctionArgs },
            false,
            CATEGORY
          )
        );
      }
    } else if (
      /**
       Close the modal if in those stages
       * */
      step === FORGOT_PASSWORD_STEPS.EMAIL_SENT ||
      step === FORGOT_PASSWORD_STEPS.SUCCESS
    ) {
      this.props.dispatch(
        alertsActions.closeDialog(dialogTypes.FORGOT_PASSWORD)
      );

      if (onFinish) onFinish();
    }
  }

  dispatchError(msg) {
    this.props.dispatch({
      type: forgotPasswordActionTypes.ERROR_WITH_TEXT,
      error: msg
    });
  }

  renderSuccess() {
    return <div>{ON_SUCCESS}</div>;
  }

  renderLoading() {
    return <div>{LOADING_SEND_EMAIL}</div>;
  }

  renderEmailSent() {
    return (
      <div>
        <div>
          Email sent to{' '}
          <b data-test-mask="email@domain.com">{this.props.email}</b>
        </div>
        <p className="margin-top-20">{EMAIL_SENT}</p>
      </div>
    );
  }

  renderError() {
    return <div className="error">{this.props.error}</div>;
  }

  render() {
    const { step, buttonText, token } = this.props;

    if (step === FORGOT_PASSWORD_STEPS.FILL_PASSWORD)
      return <ChangePasswordModal token={token} />;

    return (
      <GenericModal
        title="Forgot Password"
        onCancel={() => {}}
        onClose={this.buttonAction}
        buttonText={buttonText}
        customClass="forgot-password-modal"
      >
        <DialogContent>
          {this.renderError()}
          {step === FORGOT_PASSWORD_STEPS.FILL_EMAIL && (
            <ForgotPasswordFillEmail />
          )}

          {step === FORGOT_PASSWORD_STEPS.SUCCESS && this.renderSuccess()}
          {step === FORGOT_PASSWORD_STEPS.LOADING &&
            !this.props.error &&
            this.renderLoading()}
          {step === FORGOT_PASSWORD_STEPS.EMAIL_SENT && this.renderEmailSent()}
        </DialogContent>
      </GenericModal>
    );
  }
}

export default withRouter(withForgotPassword(ForgotPasswordModal));
