import { useDispatch } from 'react-redux';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { AxiosError } from 'axios';

type UseExperimentHistogramStateMutationParams = {
  experimentKey: string;
  state: string[];
};

const mutateExperimentHistogramState = async ({
  experimentKey,
  state
}: UseExperimentHistogramStateMutationParams) => {
  await api.post('asset/set-histogram-tab-state', {
    experimentKey,
    tabState: state.join(',')
  });
};

export default function useExperimentHistogramStateMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<
    void,
    AxiosError,
    UseExperimentHistogramStateMutationParams
  >(
    ({ experimentKey, state }) => {
      return mutateExperimentHistogramState({
        experimentKey,
        state
      });
    },
    {
      onMutate: async ({ experimentKey, state }) => {
        const queryKey = ['experiment-histogram-state', { experimentKey }];
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey, () => state);
      },
      onSuccess: async (_, { experimentKey }) => {
        await queryClient.invalidateQueries([
          'experiment-histogram-state',
          { experimentKey }
        ]);
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_FETCH_ASSETS_FOR_EXPERIMENT,
            'There was an error saving the histograms state.'
          )
        );
      }
    }
  );
}
