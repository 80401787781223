import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import {
  TABLE_COLUMN_KEYS,
  SORT_BY_DIRECTION,
  TABLE_COLUMN_HEADERS
} from '@artifacts/constants/constants';
import ArtifactVersionsTableHeader from './ArtifactVersionsTableHeader';
import ArtifactVersionsTableRow from './ArtifactVersionsTableRow';
import { compareSemanticVersions } from '@/helpers/generalHelpers';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  if (orderBy === TABLE_COLUMN_KEYS.VERSION) {
    return order === SORT_BY_DIRECTION.ASC
      ? (a, b) => compareSemanticVersions(a[orderBy], b[orderBy])
      : (a, b) => -compareSemanticVersions(a[orderBy], b[orderBy]);
  }

  return order === SORT_BY_DIRECTION.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const ArtifactVersionsTable = ({ rows, isArtifactPublic }) => {
  const [order, setOrder] = useState(SORT_BY_DIRECTION.DESC);
  const [orderBy, setOrderBy] = useState(TABLE_COLUMN_KEYS.VERSION);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const { ASC, DESC } = SORT_BY_DIRECTION;
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const renderEmptyMessage = () => {
    return (
      <div style={{ fontSize: 20, textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <span style={{ marginBottom: 25 }}>
            No versions found for this artifact
          </span>
        </div>
      </div>
    );
  };

  const renderTemplateRow = artifactVersion => {
    const { artifactVersionId } = artifactVersion;

    return (
      <ArtifactVersionsTableRow
        key={artifactVersionId}
        artifactVersionId={artifactVersionId}
        artifactVersion={artifactVersion}
        isArtifactPublic={isArtifactPublic}
      />
    );
  };

  return (
    <TableContainer>
      <Table classes={{ root: 'artifact-versions-table' }}>
        <ArtifactVersionsTableHeader
          columns={TABLE_COLUMN_HEADERS}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(renderTemplateRow)}

          {isEmpty(rows) && (
            <TableRow style={{ height: 59 * emptyRows }}>
              <TableCell colSpan={6}>{renderEmptyMessage()}</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

ArtifactVersionsTable.propTypes = {
  isArtifactPublic: PropTypes.bool,
  rows: PropTypes.array.isRequired
};

export default ArtifactVersionsTable;
