import { SortableElement } from 'react-sortable-hoc';
import { MultiValueProps, components } from 'react-select';
import React, { MouseEventHandler } from 'react';
import { OptionType } from '@design-system/types';

import styles from '../Select.module.scss';

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<OptionType>) => {
    const onMouseDown: MouseEventHandler = e => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <components.MultiValue
        className={styles.sortMultiValue}
        {...props}
        innerProps={{ ...props.innerProps, onMouseDown }}
      />
    );
  }
);

export default SortableMultiValue;
