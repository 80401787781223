import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import isPlainObject from 'lodash/isPlainObject';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { useDispatch } from 'react-redux';

const normalizeAssetAsText = (data: unknown) => {
  if (data === null) {
    return 'null';
  }

  if (isPlainObject(data) || Array.isArray(data)) {
    return JSON.stringify(data, null, 2);
  }

  return data;
};

const getAssetById = async ({
  experimentKey,
  assetId,
  artifactVersionId,
  asText
}: UseAssetByIdOpts) => {
  const { data } = await api.get('asset/download', {
    params: {
      experimentKey,
      assetId,
      ...(artifactVersionId && { artifactVersionId })
    }
  });

  if (asText) {
    return normalizeAssetAsText(data);
  }

  return data;
};

type UseAssetByIdOpts = {
  experimentKey: string;
  assetId: string;
  artifactVersionId?: string;
  queryKeyPrefix?: string;
  asText?: boolean;
  silent?: boolean;
};

export default function useAssetById<T>(
  params: UseAssetByIdOpts,
  options: QueryConfig<T>
) {
  const dispatch = useDispatch();
  const queryKeyPrefix = params.queryKeyPrefix ?? 'experimentAsset';

  return useQuery([queryKeyPrefix, params], () => getAssetById(params), {
    onError: () => {
      if (!params.silent) {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_FETCH_ASSETS_FOR_EXPERIMENT,
            'There was an error fetching the asset for this experiment.'
          )
        );
      }
    },
    ...options
  });
}
