export const NO_STEP = -2;

export const DISABLE_SEARCH_FILTER = -1;

export const MAX_STEP_SIZE = 11;

export const SORT_TYPES = {
  STEP: 'step',
  ASSET_TYPE: 'type',
  NAME: 'name',
  CONTEXT: 'runContext',
  FIGURE: 'figName',
  CREATED_AT: 'createdAt',
  METADATA: 'metadata'
};
