// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenuOrganization-module__organizationInfo--dk9cB{display:flex;align-items:center;gap:10px;width:100%;min-width:0;translate:-2px 0px}.UserMenuOrganization-module__organizationInfo--dk9cB.UserMenuOrganization-module__organizationInfoBold--SdiTe span{font-weight:500}.UserMenuOrganization-module__organizationSelectContainer--KGHur{translate:-2px 0px;width:240px;box-shadow:-5px 8px 12px rgba(0,0,0,.12),0px 0px 1px rgba(0,0,0,.14)}.UserMenuOrganization-module__organizationSelectList--qJeoV{padding-top:4px;padding-bottom:4px;max-height:360px;overflow-y:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"organizationInfo": `UserMenuOrganization-module__organizationInfo--dk9cB`,
	"organizationInfoBold": `UserMenuOrganization-module__organizationInfoBold--SdiTe`,
	"organizationSelectContainer": `UserMenuOrganization-module__organizationSelectContainer--KGHur`,
	"organizationSelectList": `UserMenuOrganization-module__organizationSelectList--qJeoV`
};
module.exports = ___CSS_LOADER_EXPORT___;
