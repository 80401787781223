import { push } from 'connected-react-router';
import { getSettingsRoute, SETTING_SECTIONS } from '@shared/utils/url';
import { dialogTypes } from '../constants/alertTypes';
import alertsUtil from '../util/alertsUtil';
import { paymentsActionTypes } from '../constants/actionTypes';
import { getActiveWorkspaceName } from '../reducers/ui/workspaceUiReducer';
import { getActiveUserOrganization } from '../reducers/ui/organizationsUiReducer';

const billingActions = {
  paymentPlanPermissionsError(errorMessage = null) {
    return (dispatch, getState) => {
      const state = getState();
      const activeWorkspaceName = getActiveWorkspaceName(state);
      const activeOrganization = getActiveUserOrganization(state);

      dispatch({
        type: paymentsActionTypes.PAYMENT_PLAN_NOT_MATCH_ACTION_PERMISSIONS
      });

      if (activeWorkspaceName) {
        const urlToGo = getSettingsRoute({
          organizationId: activeOrganization?.id,
          section: SETTING_SECTIONS.PAYMENT_PLAN,
          isAdmin: activeOrganization?.isAdmin
        });

        dispatch(push(urlToGo));

        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.PAYMENT_PLAN_NOT_MATCH_ACTION_PERMISSIONS,
            errorMessage
          )
        );
      }

      return Promise.resolve();
    };
  }
};

export default billingActions;
