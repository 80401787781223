import { useExperimentUniqueParams } from '@experiment-management-shared';
import { useDispatch } from 'react-redux';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import FileSaver from 'file-saver';
import { generateNestedTreeByName } from '@shared/utils/paramsHelpers';

type useHyperParametersDownloadOpts = {
  experimentName: string;
};
const useHyperParametersDownload = ({
  experimentName
}: useHyperParametersDownloadOpts) => {
  const dispatch = useDispatch();
  const {
    data: uniqueParams = [],
    isLoading: isUniqueParamsLoading
  } = useExperimentUniqueParams();

  const hasUniqueParams = Boolean(uniqueParams?.length);
  const isDownloadButtonDisabled = isUniqueParamsLoading || !hasUniqueParams;

  const onDownload = () => {
    if (isDownloadButtonDisabled) return;

    let json = null;
    try {
      json = JSON.stringify(generateNestedTreeByName(uniqueParams), null, 2);
    } catch (_) {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_FAILED_JSON_STRINGIFY,
          'There was an error exporting your data.'
        )
      );
      return;
    }

    const blob = new window.Blob([json], {
      type: 'application/json'
    });

    FileSaver.saveAs(blob, `${experimentName}_hyper_parameters.json`);
  };

  return {
    onDownload,
    isDownloadButtonDisabled
  };
};

export default useHyperParametersDownload;
