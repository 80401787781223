import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import useProject from '@API/project/useProject';
import { useDispatch } from 'react-redux';
import useProjectViews from '@API/project/useProjectViews';
import useRemoveViewMutation from '@API/project/useRemoveViewMutation';
import useSelectDefaultProjectMutation from '@API/project/useSelectDefaultProjectMutation';
import useUpsertViewMutation from '@API/project/useUpsertViewMutation';
import omit from 'lodash/omit';

import { fromDashboardToTemplate } from '@experiment-management-shared/utils/EMView';
import { NEW_VIEW } from '@/lib/appConstants';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';

const useDashboardTemplateActions = ({
  currentViewId,
  currentView,
  dashboard
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: views } = useProjectViews();

  const { data: project } = useProject();
  const { projectName, section, workspace } = useParams();

  const removeViewMutation = useRemoveViewMutation();
  const selectDefaultProjectMutation = useSelectDefaultProjectMutation();
  const upsertViewMutation = useUpsertViewMutation();

  const handleDeleteTemplate = useCallback(
    view => {
      removeViewMutation.mutate({
        projectId: view.projectId,
        viewId: view.templateId
      });

      const unsavedView = views.find(v => {
        return v.unsavedView && v.createdFromTemplateId === view.templateId;
      });

      if (unsavedView) {
        removeViewMutation.mutate({ viewId: unsavedView.templateId });
      }
    },
    [removeViewMutation, views]
  );

  const changeTemplate = useCallback(
    template => {
      const viewId = template.templateId || NEW_VIEW;
      history.push(`/${workspace}/${projectName}/view/${viewId}/${section}`);
    },
    [history, workspace, projectName, section]
  );

  const handleTemplateChange = useCallback(
    template => {
      const isThisTemplateAlreadySelected =
        template.templateId === currentViewId;

      if (isThisTemplateAlreadySelected) return;

      changeTemplate(template);
    },
    [changeTemplate, currentViewId]
  );

  const handleSetProjectTemplate = useCallback(
    view => {
      selectDefaultProjectMutation.mutate({ viewId: view.templateId });

      handleTemplateChange(view);
    },
    [selectDefaultProjectMutation, handleTemplateChange]
  );

  const handleDiscardNewView = useCallback(
    view => {
      if (currentView?.unsavedView) {
        removeViewMutation.mutate({ viewId: currentView.templateId });
      }

      handleTemplateChange(view);
    },
    [currentView, removeViewMutation, handleTemplateChange]
  );

  const handleSaveNewView = useCallback(
    async ({ asDefault, newViewName, viewToGo }) => {
      try {
        const viewToSave = fromDashboardToTemplate({
          dashboard,
          project,
          selectedView: currentView
        });

        const view = {
          ...omit(viewToSave, 'templateId'),
          createdFromTemplateId: viewToSave.templateId,
          templateName: newViewName,
          unsavedView: false
        };

        const { template_id: newViewId } = await upsertViewMutation.mutateAsync(
          {
            view
          }
        );

        if (asDefault) {
          await selectDefaultProjectMutation.mutateAsync({ viewId: newViewId });
        }

        handleTemplateChange(viewToGo);
      } catch (e) {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_SAVE_TEMPLATE,
            `There was an error saving your dashboard view: ${e?.response?.data?.msg}`
          )
        );
      }
    },
    [
      handleTemplateChange,
      project,
      dashboard,
      currentView,
      upsertViewMutation,
      selectDefaultProjectMutation,
      dispatch
    ]
  );

  const handleUpdateCurrentView = useCallback(
    async ({ viewToGo }) => {
      const viewToSave = fromDashboardToTemplate({
        dashboard,
        project,
        selectedView: currentView
      });

      await upsertViewMutation.mutateAsync({
        view: {
          ...viewToSave,
          templateId: currentViewId,
          unsavedView: false
        }
      });

      handleTemplateChange(viewToGo);
    },
    [
      currentView,
      currentViewId,
      dashboard,
      handleTemplateChange,
      project,
      upsertViewMutation
    ]
  );

  const handleSaveUnsavedView = useCallback(
    ({ asDefault, newViewName, viewToGo, isSaveNewView }) => {
      if (isSaveNewView) {
        handleSaveNewView({ asDefault, newViewName, viewToGo });
      } else {
        handleUpdateCurrentView({ viewToGo });
      }
    },
    [handleSaveNewView, handleUpdateCurrentView]
  );

  const handleChangeName = useCallback(
    ({ newName, template }) => {
      upsertViewMutation.mutate({
        view: {
          ...template,
          templateName: newName
        }
      });
    },
    [upsertViewMutation]
  );

  return {
    handleTemplateChange,
    handleSetProjectTemplate,
    handleDeleteTemplate,
    handleDiscardNewView,
    handleSaveUnsavedView,
    handleChangeName
  };
};

export default useDashboardTemplateActions;
