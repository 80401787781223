import { useMutation, useQueryClient } from 'react-query';

const useOrganizationSettingsMutation = organizationId => {
  const queryClient = useQueryClient();

  return useMutation(async ({ fieldName }) => {
    return new Promise(resolve =>
      setTimeout(() => {
        queryClient.setQueriesData(
          ['organizations', organizationId, 'settings'],
          prevSettings => ({
            ...prevSettings,
            [fieldName]: !prevSettings[fieldName]
          })
        );

        resolve();
      }, 500)
    );
  });
};

export default useOrganizationSettingsMutation;
