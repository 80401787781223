import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ColorPickerPopover } from '@design-system-outdated';

import { SmallPencilIcon } from '@Icons-outdated';
import { Button, Tooltip } from '@ds';
import useSetExperimentColorMutation from '@API/experiments/useSetExperimentColorMutation';
import { getExperimentColor } from '@experiment-management-shared/utils/experimentHelpers';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';

import { CHART_COLORS } from '@/lib/appConstants';
import styles from './ExperimentCircleColorPicker.module.scss';

const ExperimentCircleColorPicker = ({ experiment }) => {
  const color = getExperimentColor(experiment)?.primary;
  const experimentKey = experiment?.experimentKey;

  const isSymlink = experiment.symlink;

  const colorRef = useRef();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [localColor, setLocalColor] = useState(color);

  const setExperimentColorMutation = useSetExperimentColorMutation();

  useEffect(() => {
    setLocalColor(color);
  }, [color]);

  const openPopover = () => {
    if (!isSymlink) {
      setIsPopoverOpen(true);
    }
  };

  const closePopover = () => setIsPopoverOpen(false);

  const handleSave = () => {
    if (localColor !== color) {
      setExperimentColorMutation.mutate({
        color: localColor?.hex,
        experimentKey
      });
    }

    closePopover();
  };

  const renderFooter = () => {
    return (
      <div className={styles.actionFooterContainer}>
        <Button type="tertiary" onClick={closePopover}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </div>
    );
  };

  return (
    <>
      <Tooltip
        content={
          !isSymlink
            ? 'Click to edit experiment color'
            : 'Color is not changeable'
        }
        placement="top"
      >
        <div
          className={cx(styles.colorPickerIcon, {
            [styles.notClickablePicker]: isSymlink
          })}
          ref={colorRef}
          style={{ backgroundColor: color }}
          onClick={openPopover}
        >
          <SmallPencilIcon />
        </div>
      </Tooltip>

      <ColorPickerPopover
        value={localColor}
        anchorEl={colorRef?.current}
        open={isPopoverOpen}
        onClose={closePopover}
        onChange={setLocalColor}
        presetColors={CHART_COLORS}
        renderFooter={renderFooter}
      />
    </>
  );
};

ExperimentCircleColorPicker.propTypes = {
  experiment: IExperimentDetails.isRequired
};

export default ExperimentCircleColorPicker;
