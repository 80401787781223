// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectPageHeader-module__projectPageHeader--jAw_5{background-color:#fff;box-shadow:0 4px 4px rgba(0,0,0,.05);padding:0 16px;position:sticky;top:-100%;transition:top .3s ease;z-index:500}.ProjectPageHeader-module__projectPageHeader--jAw_5.ProjectPageHeader-module__sticky--ythqB{top:0}.ProjectPageHeader-module__projectPageHeader--jAw_5 .ProjectPageHeader-module__subheader--o7FAy{align-items:center;display:flex;flex-direction:row;justify-content:space-between;height:52px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectPageHeader": `ProjectPageHeader-module__projectPageHeader--jAw_5`,
	"sticky": `ProjectPageHeader-module__sticky--ythqB`,
	"subheader": `ProjectPageHeader-module__subheader--o7FAy`
};
module.exports = ___CSS_LOADER_EXPORT___;
