import { useMutation, useQueryClient } from 'react-query';
import pick from 'lodash/pick';

import api from '../api';

const UPDATE_INSTANCE_FIELDS = [
  'config',
  'instanceId',
  'instanceName',
  'projectId',
  'queryBuilderId',
  'metadata',
  'source',
  'templateId',
  'templateRevisionId',
  'useDefaultConfig',
  'userSkippedVersion'
  // 'order',
  // 'viewId',
  // @todo: backend doesn't support update these fields
];

const updatePanelInstance = ({ instance }) => {
  return api.post('code-visualizations/instance/update', instance);
};

export default function useUpdatePanelInstanceMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ instance: allInstanceFields }) => {
      const instance = pick(allInstanceFields, UPDATE_INSTANCE_FIELDS);

      return updatePanelInstance({ instance });
    },
    {
      onMutate: async ({ instance }) => {
        await queryClient.cancelQueries([
          'panelInstance',
          { instanceId: instance.instanceId }
        ]);
      },
      onSuccess: async (_data, { instance }) => {
        await queryClient.invalidateQueries([
          'panelInstance',
          { instanceId: instance.instanceId }
        ]);
      }
    }
  );
}
