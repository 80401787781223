import React from 'react';
import PropTypes from 'prop-types';

const ManageUsersModalFooterResetText = ({ usersRemoved, usersChanged }) => {
  const removedUsersCount = usersRemoved?.length;
  const changedUsersCount = usersChanged?.length;
  const changedOnlyOneUser = changedUsersCount === 1;
  const removedOnlyOneUser = removedUsersCount === 1;

  const removedUsersText = !removedUsersCount
    ? ''
    : `${removedUsersCount} ${removedOnlyOneUser ? 'member' : 'members'} ${
        removedOnlyOneUser ? 'was' : 'were'
      } removed`;
  const rolesChangedText = !changedUsersCount
    ? ''
    : `${changedUsersCount} ${changedOnlyOneUser ? 'role' : 'roles'} ${
        changedOnlyOneUser ? 'was' : 'were'
      } changed`;

  return (
    <>
      {`${rolesChangedText}${
        usersRemoved?.length && usersChanged?.length ? ', ' : ''
      }${removedUsersText}`}
    </>
  );
};

ManageUsersModalFooterResetText.propTypes = {
  usersChanged: PropTypes.arrayOf(PropTypes.string),
  usersRemoved: PropTypes.arrayOf(PropTypes.string)
};

ManageUsersModalFooterResetText.defaultProps = {
  usersChanged: [],
  usersRemoved: []
};

export default ManageUsersModalFooterResetText;
