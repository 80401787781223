import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputLabel } from '@material-ui/core';
import { Input } from '@DesignSystem/controllers';
import { StyledTooltip } from '@DesignSystem/data-display';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';

const ScalarChartDescriptionTab = () => {
  const dispatch = useDispatch();
  const scalarChartForm = useSelector(state =>
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.scalar })
  );

  const handleChangeDescription = description => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'description',
        description
      )
    );

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'isCustomDescription',
        true
      )
    );
  };

  const isDisabled = !(
    scalarChartForm?.metricName || scalarChartForm?.paramName
  );

  return (
    <div className="scalar-chart-description-tab">
      <InputLabel className="modal-input-label">Description</InputLabel>

      <div>
        <StyledTooltip
          type="base"
          placement="top"
          title={isDisabled ? 'Select a value to enable chart description' : ''}
        >
          <div>
            <Input
              placeholder="Chart description"
              value={scalarChartForm?.description}
              onChange={handleChangeDescription}
              height={36}
              disabled={isDisabled}
            />
          </div>
        </StyledTooltip>
      </div>
    </div>
  );
};

ScalarChartDescriptionTab.propTypes = {};

export default ScalarChartDescriptionTab;
