import { QueryConfig } from '@shared/api';
import useComputeEngine from './useComputeEngine';
import { GET_PYTHON_PANEL_URL } from './constants';
import useComputeKeepAlive from './useComputeKeepAlive';

import { COMPUTE_ENGINE_KEEP_ALIVE_INTERVAL } from '@experiment-management-shared/constants';

const useComputeURL = (projectId: string, config?: QueryConfig<string>) => {
  const getComputeUrl = (computeEngine: string) =>
    `${computeEngine}${GET_PYTHON_PANEL_URL}`;

  const {
    isError: isKeepAliveError,
    error: keepAliveError
  } = useComputeKeepAlive(projectId, {
    refetchInterval: COMPUTE_ENGINE_KEEP_ALIVE_INTERVAL,
    refetchIntervalInBackground: true
  });

  const computeEngineQuery = useComputeEngine(projectId, {
    ...config,
    select: getComputeUrl
  });

  return {
    ...computeEngineQuery,
    error: computeEngineQuery.error || keepAliveError,
    isError: computeEngineQuery.isError || isKeepAliveError
  };
};

export default useComputeURL;
