import React from 'react';

import classNames from './DebuggerPage.module.scss';

type DebuggerEmptyEventsPanelProps = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  message: string;
};

const DebuggerEmptyEventsPanel = ({
  Icon,
  message
}: DebuggerEmptyEventsPanelProps) => {
  return (
    <div className={classNames.debuggerEmptyEvents}>
      <Icon />
      <span className={classNames.debuggerEmptyEventsMessage}>{message}</span>
    </div>
  );
};

export default DebuggerEmptyEventsPanel;
