import React from 'react';
import PropTypes from 'prop-types';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Highlight = ({ code, ...HighlighterProps }) => {
  const lineNumberContainerStyle = {
    backgroundColor: 'white',
    borderRight: '1px solid #eeeeee',
    color: 'rgba(0, 0, 0, 0.3)',
    float: 'left',
    marginRight: 10,
    paddingRight: 10,
    textAlign: 'right'
  };

  return (
    <SyntaxHighlighter
      customStyle={{ backgroundColor: 'white' }}
      language="python"
      lineNumberContainerStyle={lineNumberContainerStyle}
      showLineNumbers
      style={github}
      {...HighlighterProps}
    >
      {code}
    </SyntaxHighlighter>
  );
};

Highlight.defaultProps = {
  code: ''
};

Highlight.propTypes = {
  code: PropTypes.string
};

export default Highlight;
