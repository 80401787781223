import React from 'react';

import {
  useOptInNotifications,
  useUpdateOptInNotifications,
  useUpdateOptInMpmNotifications,
  useOptInMpmNotifications
} from '@account-settings/api';

import NotificationsTab from './NotificationsTab';
import './Notifications.scss';

const Notifications = () => {
  const { data: isActiveOptInNotification } = useOptInNotifications();
  const updateOptInNotificationsMutation = useUpdateOptInNotifications();
  const { data: isActiveOptInMpmNotification } = useOptInMpmNotifications();
  const updateOptInMpmNotificationsMutation = useUpdateOptInMpmNotifications();

  const closeSnackbar = () => {
    updateOptInNotificationsMutation.reset();
    updateOptInMpmNotificationsMutation.reset();
  };

  const handleChangeExperimentNotifications = () => {
    updateOptInNotificationsMutation.mutate();
  };

  const handleChangeMpmNotifications = () => {
    updateOptInMpmNotificationsMutation.mutate();
  };

  const renderTabContent = () => {
    return (
      <NotificationsTab
        mpmNotifications={!!isActiveOptInMpmNotification}
        experimentNotifications={!!isActiveOptInNotification}
        onChangeExperimentNotifications={handleChangeExperimentNotifications}
        onChangeMpmNotifications={handleChangeMpmNotifications}
        isSaving={
          updateOptInNotificationsMutation.isLoading ||
          updateOptInMpmNotificationsMutation.isLoading
        }
        isSuccess={
          updateOptInNotificationsMutation?.isSuccess &&
          updateOptInMpmNotificationsMutation?.isSuccess
        }
        onCloseSuccessSnackbar={closeSnackbar}
      />
    );
  };

  return (
    <div className="notifications-container">
      <h4 className="page-title">Notifications</h4>
      <div className="notification-tabs-container">{renderTabContent()}</div>
    </div>
  );
};

export default Notifications;
