import { EmptyExperimentTab } from '@shared';
import React from 'react';
import classes from './HTMLTab.module.scss';

const HTMLTabEmpty = () => (
  <EmptyExperimentTab className={classes.emptyMessage}>
    <EmptyExperimentTab.Icon iconName="html" />
    <EmptyExperimentTab.Title>
      This experiment did not report any HTML
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      Use <b>log_html()</b> on your Experiment object to report HTML during
      experiments.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="guides/experiment-management/log-data/html/" />
  </EmptyExperimentTab>
);

export default HTMLTabEmpty;
