import moment, { Moment } from 'moment';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import md5 from 'md5';

import { NULL_OPERATORS, RULE_TYPE } from '@shared/utils/filterHelpers';
import {
  APIPredicateGroup,
  RuleGroup,
  RuleSimple
} from '@experiment-management-shared/types';
import {
  INTERVAL_TYPE_VALUES,
  DEFAULT_INTERVAL_VALUE,
  PERFORMANCE_DATE_RANGES,
  MIN_AMOUNT_DAYS_DAILY_MODE,
  DEFAULT_INTERVAL_TYPE_VALUE,
  PANEL_SECTIONS
} from '@mpm-druid/constants';
import {
  FeatureColumnType,
  IntervalType,
  DebuggerEventType,
  Version
} from '@mpm-druid/types';

export const constructMPMFilterDefinition = (columns: FeatureColumnType[]) => {
  if (columns.length === 0) {
    return [];
  }

  const definition = columns
    .filter(column => !column.hideForFilters)
    .map(column => {
      const { type, source, title: label, serverField } = column;
      const columnMap = {
        field: serverField,
        label,
        type,
        source
      };

      return columnMap;
    });
  return definition;
};

export const constructMPMRuleLabel = (
  rule: RuleSimple,
  columns: FeatureColumnType[]
) => {
  let label = rule.field;

  if (columns.length === 0) {
    return label;
  }

  columns.forEach(column => {
    if (
      !column.hideForFilters &&
      column.serverField === rule.field &&
      column.source === rule.source
    ) {
      label = column.title;
    }
  });

  return label;
};

export const rulesTreeToMPMPredicates = (rulesTree: RuleGroup) => {
  const rules: RuleSimple[] =
    ((rulesTree?.rules?.[0] as RuleGroup)?.rules as RuleSimple[]) || [];

  const filterQueryTemplate: APIPredicateGroup[] = uniq(
    rules.map(rule => rule.source)
  ).map(category => {
    return {
      source: category,
      query: {
        condition: 'AND',
        rules: [],
        valid: true
      }
    };
  });

  const reducer = (
    cumulativeFilterQueries: APIPredicateGroup[],
    currentRule: RuleSimple
  ) => {
    const sourceFilterQueryIndex = cumulativeFilterQueries.findIndex(
      filter => filter.source === currentRule.source
    );

    const rule = {
      field: currentRule.field,
      id: currentRule.field,
      operator: currentRule.operator,
      ...(!NULL_OPERATORS.includes(currentRule.operator) && {
        value: currentRule.value
      }),
      ...(currentRule.kind && { kind: currentRule.kind }),
      type:
        currentRule.type === RULE_TYPE.TIME_NUMBER
          ? 'integer'
          : currentRule.type
    };

    if (NULL_OPERATORS.includes(currentRule.operator)) {
      delete rule.value;
    }

    cumulativeFilterQueries[sourceFilterQueryIndex].query.rules.push(rule);

    return cumulativeFilterQueries;
  };

  return rules
    .reduce(reducer, filterQueryTemplate)
    .filter(predicate => !isEmpty(predicate.query.rules));
};

export const getColorNumber = (id: string, maxHashValue: number) => {
  const hashCode = parseInt(md5(id).substring(0, 8), 16);
  return hashCode % maxHashValue;
};

export const getHashedColor = (colorsMap: string[], id?: string) => {
  if (!id) return colorsMap[0];
  return colorsMap[Number(getColorNumber(id, colorsMap.length))];
};

export const mergeDebuggerEvents = (
  prevData: DebuggerEventType[],
  newData: DebuggerEventType[],
  elementsLimit: number
) => {
  if (!prevData.length || !newData.length) {
    return newData.concat(prevData);
  }

  const mergeIndex = newData.findIndex(item => prevData[0]?.id === item.id);

  const mergeResult =
    mergeIndex === -1
      ? newData.concat(prevData).slice(0, elementsLimit)
      : newData.slice(0, mergeIndex).concat(prevData).slice(0, elementsLimit);

  return mergeResult;
};

export const mapModelVersions = (versions: string[] | null) => {
  if (!versions) {
    return [];
  }
  return versions.map(v => ({
    label: v,
    value: v
  }));
};

type SegmentPostRequestProps = {
  id: string;
  name: string;
  featureBreakdownId: string;
  segmentValues: string[];
  description: string;
};

export const createSegmentPostRequest = (formData: SegmentPostRequestProps) => {
  const { id, name, featureBreakdownId, segmentValues, description } = formData;
  const requestData = {
    id,
    name,
    description,
    segmentRule: {
      feature: { id: featureBreakdownId }
    },
    segments: segmentValues.map(value => {
      return { segmentValue: value };
    })
  };

  return requestData;
};

export const isValidIntervalType = (intervalType: IntervalType) =>
  intervalType === INTERVAL_TYPE_VALUES.DAILY ||
  intervalType === INTERVAL_TYPE_VALUES.HOURLY;

export const handleIntervalTypeValue = (intervalType: IntervalType) =>
  isValidIntervalType(intervalType)
    ? intervalType
    : DEFAULT_INTERVAL_TYPE_VALUE;

export const handleVersionValue = (
  version: string | null,
  possibleVersions: Array<Version>
) => {
  if (
    version === 'null' ||
    !possibleVersions.find(({ value }) => value === version)
  ) {
    return null;
  }

  return version;
};

export const fromUTCToDateWithoutTimezone = (utcValue: string) => {
  return moment(utcValue).toDate();
};

export const formatDateFromCalendar = (
  date: Date | Moment | string,
  isStartDay: boolean
) => {
  if (isStartDay) {
    return moment(date).startOf('day').utc().format();
  }

  return moment(date).utc().format();
};

type ValidTimeRangeProps = {
  from: string;
  to: string;
  intervalType: IntervalType;
};

export const isValidTimeRangeValues = ({
  from,
  to,
  intervalType
}: ValidTimeRangeProps) => {
  const fromDate = moment.utc(from);
  const toDate = moment.utc(to);

  if (fromDate.isValid() && toDate.isValid()) {
    const diff = toDate.diff(fromDate, 'days');

    if (diff < 0) {
      return false;
    }

    if (intervalType === INTERVAL_TYPE_VALUES.HOURLY) {
      return diff <= PERFORMANCE_DATE_RANGES[INTERVAL_TYPE_VALUES.HOURLY][2];
    }

    if (intervalType === INTERVAL_TYPE_VALUES.DAILY) {
      return (
        diff <= PERFORMANCE_DATE_RANGES[INTERVAL_TYPE_VALUES.DAILY][2] &&
        diff >= MIN_AMOUNT_DAYS_DAILY_MODE
      );
    }
  }

  return false;
};

export const calculateRangeFrom = (
  now: Moment | Date | string,
  dateRange = DEFAULT_INTERVAL_VALUE
) => {
  return formatDateFromCalendar(moment(now).subtract(dateRange, 'd'), true);
};
export const calculateRangeTo = (now: Moment | Date | string) => {
  return formatDateFromCalendar(now, false);
};

const DRIFT_TO_VALUE = {
  EMD: 'EMD_DRIFT',
  PSI: 'PSI_DRIFT',
  KL: 'KL_DIVERGENCE_DRIFT'
};

export const getMetricValue = ({
  section,
  driftAlgorithm,
  featureName,
  features
}: {
  section: string;
  driftAlgorithm: 'EMD' | 'PSI' | 'KL';
  featureName: string;
  features: { value: string; type: string }[];
}) => {
  if (section === PANEL_SECTIONS.FEATURE_MISSING_VALUES)
    return 'MISSING_VALUES';
  if (section === PANEL_SECTIONS.NUMBER_OF_PREDICTIONS) return 'COUNT';
  if (driftAlgorithm && featureName && features?.length) {
    const featureType = features?.find(feature => feature.value === featureName)
      ?.type;
    return `${featureType}_${DRIFT_TO_VALUE[driftAlgorithm]}`;
  }
  return undefined;
};

export const getAlertOperatorSign = (value: string) => {
  switch (value) {
    case 'GREATER_THAN':
      return '>';
    case 'LESS_THAN':
      return '<';
    case 'GREATER_EQUAL':
      return '≥';
    case 'LESS_EQUAL':
      return '≤';
    default:
      return '-';
  }
};
