import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import classNames from './PageInfoMessage.module.scss';

const PageInfoMessage = ({ message, title, goBackLink }) => {
  return (
    <div className={classNames.pageInfoMessageWrapper}>
      <DocumentTitle title={title}>
        <div className={classNames.pageInfoMessage}>
          {message}
          <Link
            className="flex alignCenter justifyCenter"
            data-test={'page-info-message-link'}
            to={goBackLink}
          >
            <ArrowBackIcon fontSize="small" /> Go back to workspace
          </Link>
        </div>
      </DocumentTitle>
    </div>
  );
};

PageInfoMessage.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  goBackLink: PropTypes.string.isRequired
};

export default PageInfoMessage;
