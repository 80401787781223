import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import projectsActions from '@/actions/projectsActions';
import { projectsActionTypes } from '@/constants/actionTypes';

// this hook is workaround that uses to set information about project to redux
// this hook should be deleted as soon as all places in code that uses information
// from redux regards to project will be removed
const useSetProjectToRedux = ({ project, isLoading }) => {
  const dispatch = useDispatch();
  const [hasProjectIdBeenSaved, setHasProjectIdBeenSaved] = useState(false);

  useEffect(() => {
    if (!project?.projectId) return;

    dispatch(projectsActions.setSelectedProjectId(project.projectId));
    dispatch({
      type: projectsActionTypes.SET_PROJECT,
      payload: {
        projectId: project.projectId,
        projectData: project
      }
    });

    setHasProjectIdBeenSaved(true);
  }, [dispatch, isLoading, project]);

  return hasProjectIdBeenSaved;
};

export default useSetProjectToRedux;
