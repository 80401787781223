import { useQuery } from 'react-query';
import assetsApi from '../../util/assetsApi';

const getAssets = async (experimentKey, { filterByDir }) => {
  const { data = {} } =
    (await assetsApi.fetchAssetsForExperiment(experimentKey)) || [];

  if (filterByDir) {
    return data.filter(({ dir }) => dir.includes(filterByDir));
  }

  return data;
};

const useAssetsForExperiment = (experimentKey, { filterByDir }, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['assetsForExperiment', { experimentKey }],
    () => getAssets(experimentKey, { filterByDir }),
    {
      enabled: configEnabled && !!experimentKey
    }
  );
};

export default useAssetsForExperiment;
