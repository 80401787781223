import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  GRAPHICS_ASSETS_FIELDS_MAP,
  GRAPHICS_ASSETS_TYPES,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from '@experiment-details/constants/graphics';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';
import { ImageDetailsModal } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/ImagePanel/ImageDetailsModal';
import { VideoDetailsModal } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/VideoPanel/VideoDetailsModal';

import VirtualizedGrid from '../GraphicsVirtualizedGrid';
import GroupedGraphicsList from './GroupedGraphicsList';
import { TableMaxHeightProvider } from '@DesignSystem/tables';

import styles from './GraphicsGallery.module.scss';

const GraphicsGallery = ({
  experiment,
  filteredGraphicsAssets,
  graphicsAssets,
  groupBy,
  location,
  selectedItems,
  setSelectedItems
}) => {
  const { experimentKey } = experiment;
  const { pathname, search } = location;
  const [graphicsAsset, setInitialGraphicsAsset] = useState();
  const parsedQuery = queryString.parse(search, {
    parseBooleans: true
  });
  const graphicsAssetType = graphicsAsset?.[GRAPHICS_ASSETS_FIELDS_MAP.type];

  const [previewGraphicsAssetId, setPreviewGraphicsAssetId] = useState(
    get(parsedQuery, 'graphicsAssetId', null)
  );

  useQueryParamsForExperiments(
    { graphicsAssetId: previewGraphicsAssetId },
    [previewGraphicsAssetId, pathname],
    experimentKey
  );

  useEffect(() => {
    if (previewGraphicsAssetId) {
      openGraphicsAssetModal(previewGraphicsAssetId, graphicsAssets);
    }
  }, [previewGraphicsAssetId, graphicsAssets]);

  const onClose = () => {
    setInitialGraphicsAsset(null);
    setPreviewGraphicsAssetId(null);
  };

  const openGraphicsAssetModal = (
    graphicsAssetId,
    graphicsAssetsFilteredByStep
  ) => {
    const graphicsAssetInside = find(
      graphicsAssetsFilteredByStep,
      asset => asset[GRAPHICS_ASSETS_FIELDS_MAP.id] === graphicsAssetId
    );

    if (!graphicsAssetInside) return;

    setInitialGraphicsAsset(graphicsAssetInside);
  };

  const [previewGraphicsAssets, setPreviewGraphicsAssets] = useState(
    graphicsAssets
  );

  const handleOpenImagePreview = (graphicsAsset, graphicsAssets) => {
    const graphicsAssetId = graphicsAsset[GRAPHICS_ASSETS_FIELDS_MAP.id];

    setPreviewGraphicsAssetId(graphicsAssetId);
    setPreviewGraphicsAssets(graphicsAssets);
  };

  const handleSelectItem = asset => {
    const { [GRAPHICS_ASSETS_FIELDS_MAP.id]: assetId } = asset;

    setSelectedItems(oldItems => {
      const newItems = [...oldItems];
      const existingImageIndex = selectedItems.indexOf(assetId);

      if (existingImageIndex !== -1) {
        newItems.splice(existingImageIndex, 1);
      } else {
        newItems.push(assetId);
      }

      return newItems;
    });
  };

  const renderGallery = containerHeight => {
    if (isEmpty(filteredGraphicsAssets))
      return <div className="charts-gallery-empty-results">No Results</div>;

    if (groupBy) {
      return (
        <GroupedGraphicsList
          handleOpenImagePreview={handleOpenImagePreview}
          filteredImages={filteredGraphicsAssets}
          groupBy={groupBy}
          handleSelectItem={handleSelectItem}
          selectedItems={selectedItems}
        />
      );
    }

    return (
      <VirtualizedGrid
        showSelection
        itemWidth={IMAGE_WIDTH}
        handleOpenImagePreview={handleOpenImagePreview}
        rowHeight={IMAGE_HEIGHT}
        items={filteredGraphicsAssets}
        handleSelectItem={handleSelectItem}
        selectedItems={selectedItems}
        containerHeight={containerHeight}
      />
    );
  };

  return (
    <div className={styles.galleryContainer}>
      <TableMaxHeightProvider>{renderGallery}</TableMaxHeightProvider>

      {graphicsAsset && graphicsAssetType === GRAPHICS_ASSETS_TYPES.image ? (
        <ImageDetailsModal
          assets={previewGraphicsAssets}
          onClose={onClose}
          onChange={asset => setPreviewGraphicsAssetId(asset.id)}
          selectedAsset={graphicsAsset}
        />
      ) : null}
      {graphicsAsset && graphicsAssetType === GRAPHICS_ASSETS_TYPES.video ? (
        <VideoDetailsModal
          assets={previewGraphicsAssets}
          onClose={onClose}
          onChange={asset => setPreviewGraphicsAssetId(asset.id)}
          selectedAsset={graphicsAsset}
        />
      ) : null}
    </div>
  );
};

GraphicsGallery.defaultProps = {
  graphicsAssets: []
};

GraphicsGallery.propTypes = {
  experiment: IExperimentDetails.isRequired,
  filteredImages: PropTypes.string.isRequired,
  graphicsAssets: PropTypes.array,
  groupBy: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  videos: PropTypes.array
};

export default withRouter(connect()(GraphicsGallery));
