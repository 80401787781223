import { useQuery } from 'react-query';
import { cancelWrapper, axiosInstance } from '../api';

const MIN_LENGTH_FOR_SERVER = 3;

const getMembersList = ({ filterValue, organizationId, workspaceId }) => {
  return cancelWrapper(async cancelToken => {
    const resp = await axiosInstance.get('autocomplete/username', {
      cancelToken,
      params: {
        query: filterValue,
        organizationId,
        excludedWorkspaceId: workspaceId
      }
    });

    return resp.data;
  });
};

export default function useInviteMembersList({
  filterValue,
  organizationId,
  workspaceId
}) {
  return useQuery(
    [
      'invite-to-workspace-members',
      { filterValue, organizationId, workspaceId }
    ],
    () => getMembersList({ filterValue, organizationId, workspaceId }),
    {
      retry: false,
      enabled: !!(
        filterValue &&
        filterValue?.length >= MIN_LENGTH_FOR_SERVER &&
        organizationId &&
        workspaceId
      )
    }
  );
}
