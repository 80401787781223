import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { DropdownList } from '@DesignSystem/controllers';

const MENU_HEIGHT_NUMBERS = {
  PADDING: 6,
  LIST_ITEM: 38,
  MAX_HEIGHT: 330,
  MARGIN: 8
};

const POSITION_VALUES = {
  BOTTOM: 'bottom',
  TOP: 'top'
};

const calculateMenuHeight = memberAmount => {
  if (memberAmount > 0) {
    const height =
      MENU_HEIGHT_NUMBERS.LIST_ITEM * memberAmount +
      MENU_HEIGHT_NUMBERS.PADDING * 2;

    return height > MENU_HEIGHT_NUMBERS.MAX_HEIGHT
      ? MENU_HEIGHT_NUMBERS.MAX_HEIGHT
      : height;
  }

  return 0;
};

const MembersDropdownMenu = ({
  anchorEl,
  handleCloseMenu,
  dropdownMenuMembers,
  menuMembers,
  renderMemberAvatar,
  renderMemberName,
  className
}) => {
  const [verticalPosition, transformOriginVertical] = useMemo(() => {
    const domRect = anchorEl?.getBoundingClientRect();
    const spaceBelow =
      window.innerHeight - (domRect?.bottom || 0) - MENU_HEIGHT_NUMBERS.MARGIN;

    const isDotAtBottom =
      calculateMenuHeight(menuMembers?.length || 0) < spaceBelow;

    return [
      isDotAtBottom ? POSITION_VALUES.BOTTOM : POSITION_VALUES.TOP,
      isDotAtBottom ? POSITION_VALUES.TOP : POSITION_VALUES.BOTTOM
    ];
  }, [anchorEl, menuMembers?.length]);

  const renderListItem = member => (
    <div className="member-menu-item">
      {renderMemberAvatar(member)}
      <span>{renderMemberName(member)}</span>
    </div>
  );

  return (
    <DropdownList
      anchorEl={anchorEl}
      className={cx(className, 'members-list')}
      onClose={handleCloseMenu}
      items={dropdownMenuMembers}
      minWidth="180px"
      maxHeight={MENU_HEIGHT_NUMBERS.MAX_HEIGHT}
      withInput={false}
      renderListItem={renderListItem}
      horizontalPosition="right"
      transformOriginHorizontal="right"
      verticalPosition={verticalPosition}
      transformOriginVertical={transformOriginVertical}
      withoutHover
    />
  );
};

MembersDropdownMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  className: PropTypes.string,
  dropdownMenuMembers: PropTypes.array,
  menuMembers: PropTypes.array,
  handleCloseMenu: PropTypes.func.isRequired,
  renderMemberName: PropTypes.func.isRequired,
  renderMemberAvatar: PropTypes.func.isRequired
};

MembersDropdownMenu.defaultProps = {
  menuMembers: [],
  className: null,
  dropdownMenuMembers: []
};

export default MembersDropdownMenu;
