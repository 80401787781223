import React from 'react';
import { Tooltip } from '@ds';
import { Column } from '@devexpress/dx-react-grid';

type SystemMetricSingleTableCellProps = {
  column: Column;
  row: {
    key: string;
    currentValue: string;
  };
  value: string;
};

const SystemMetricSingleTableCell: React.FC<SystemMetricSingleTableCellProps> = ({
  value
}) => {
  return (
    <Tooltip content={value} placement="top" truncateOverflow>
      <>{value}</>
    </Tooltip>
  );
};

export default SystemMetricSingleTableCell;
