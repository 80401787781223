import { useRemoveExperimentMetricMutation } from '@experiment-details/api';
import { useCallback, useState } from 'react';
import { DeleteMetricParamModalProps } from '../modals/DeleteMetricParamModal';

type UseDeleteMetricApiOpts = {
  experimentKey: string;
};
const useDeleteMetricApi = ({ experimentKey }: UseDeleteMetricApiOpts) => {
  const removeExperimentMetricMutation = useRemoveExperimentMetricMutation();

  const [deleteModalState, setDeleteModalState] = useState({
    opened: false,
    paramKey: ''
  });

  const onDeleteMetric = useCallback(
    (metricName: string) => {
      setDeleteModalState({
        opened: true,
        paramKey: metricName
      });
    },
    [setDeleteModalState]
  );

  const deleteModalConfig: DeleteMetricParamModalProps = {
    onConfirm: () => {
      removeExperimentMetricMutation.mutate(
        ({
          experimentKey,
          metricName: deleteModalState.paramKey
        } as unknown) as void,
        {
          onSuccess: () => {
            setDeleteModalState({
              opened: false,
              paramKey: ''
            });
          }
        }
      );
    },
    onClose: () => {
      setDeleteModalState(prev => ({
        ...prev,
        opened: false
      }));
    },
    isOpened: deleteModalState.opened,
    paramKey: deleteModalState.paramKey
  };

  return {
    onDeleteMetric,
    deleteModalConfig
  };
};

export default useDeleteMetricApi;
