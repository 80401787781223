import React from 'react';
import cx from 'classnames';

import { components, DropdownIndicatorProps } from 'react-select';
import { OptionType } from '../../../../types';
import classes from '../Select.module.scss';
import { DSArrowDownIcon } from '@ds-icons';

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        className={cx(classes.dropdownIconContainer, {
          [classes.invalidDropdownIndicator]: props.selectProps.invalid
        })}
      >
        <DSArrowDownIcon />
      </div>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
