import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import classNames from './RegisterModel.module.scss';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import RedirectLink from '@shared/components/RedirectLink';
import { Button } from '@design-system-outdated/components';

const RegisterModelGoToModelExperimentAssets = ({
  experimentKey,
  modelAssetsInfo,
  selectedModel
}) => {
  return (
    <Box className={classNames.registerModelViewFiles}>
      <RedirectLink
        resource={REDIRECT_LINK_RESOURCES.EXPERIMENT}
        type="link"
        component={Button}
        className={classNames.registerModelViewFilesBtn}
        args={[
          experimentKey,
          {
            'experiment-tab': DETAIL_VIEWS.ASSETS_AND_ARTIFACTS,
            ...(modelAssetsInfo || {})
          }
        ]}
        dataTest="streamline-model-sidebar-view-files"
        disabled={!selectedModel?.label}
      >
        View model files
      </RedirectLink>
    </Box>
  );
};

RegisterModelGoToModelExperimentAssets.defaultProps = {
  modelAssetsInfo: null,
  selectedModel: {}
};

RegisterModelGoToModelExperimentAssets.propTypes = {
  experimentKey: PropTypes.string.isRequired,
  modelAssetsInfo: PropTypes.shape({
    assetId: PropTypes.string,
    assetPath: PropTypes.string
  }),
  selectedModel: PropTypes.shape({
    experimentKey: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  })
};

export default RegisterModelGoToModelExperimentAssets;
