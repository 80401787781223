import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import api from '@API/api';
import { PANEL_SECTIONS } from '@mpm/constants';
import { QueryConfig } from '@shared/api';
import { NumericalDistributionData, IntervalType } from '@mpm/types';
import { useActiveWorkspace } from '@shared/hooks';

type GetNumericalDistributionsProps = {
  from: string;
  to: string;
  intervalType: IntervalType;
  version: string;
  segmentId: string[];
  workspaceId: string;
  modelId: string;
  featureId?: string;
  propFeatureId?: string;
};

type PanelSection = typeof PANEL_SECTIONS;

type NumericalDistributionParams = {
  section: PanelSection[keyof PanelSection];
  from: string;
  to: string;
  intervalType: IntervalType;
  version: string;
  segmentId: string[];
  propFeatureId?: string;
};

type NumericalDistributionResponse = { data: NumericalDistributionData[] };

const getNumericalDistributions = ({
  workspaceId,
  modelId,
  featureId,
  ...restParams
}: GetNumericalDistributionsProps) =>
  api.get(
    `mpm/${workspaceId}/models/${modelId}/features/${featureId}/numerical-distribution-pdf`,
    restParams
  );

export function useNumericalDistribution(
  params: NumericalDistributionParams,
  config: QueryConfig<NumericalDistributionResponse>
) {
  const activeWorkspace = useActiveWorkspace();
  const { propFeatureId } = params || {};
  const { featureId, modelId } = useParams<{
    featureId: string;
    modelId: string;
  }>();
  const requestFeaturId = featureId || propFeatureId;
  return useQuery(
    [
      PANEL_SECTIONS.NUMERICAL_DISTRIBUTION,
      {
        workspaceId: activeWorkspace?.id,
        modelId,
        requestFeaturId,
        ...params
      }
    ],
    () =>
      getNumericalDistributions({
        workspaceId: activeWorkspace?.id,
        modelId,
        featureId: requestFeaturId,
        ...params
      }),
    config
  );
}
