import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE } from '../../../lib/appConstants';
export const EXPERIMENT_DETAILS_SAMPLE_SIZES = sortBy(
  uniq([DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE, 50, 150, 500, 10000])
).map(value => ({
  isDefault: DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE === value,
  value
}));
