import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

import { SegmentDataType } from '@mpm/types';

type CreateSegmentProps = {
  workspaceId: string;
  modelId: string;
  segmentData: SegmentDataType;
};

type CreateSegmentMutationProps = {
  segmentData: SegmentDataType;
};

const createSegment = ({
  workspaceId,
  modelId,
  segmentData
}: CreateSegmentProps) => {
  return api.post(`mpm/${workspaceId}/models/${modelId}/segments`, segmentData);
};

export function useCreateSegmentMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ segmentData }: CreateSegmentMutationProps) =>
      createSegment({ workspaceId: activeWorkspace?.id, modelId, segmentData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'mpm-segments',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.SUCCESS_SEGMENT_CREATED,
            'The segment was succesfully created.'
          )
        );
      },
      onError: (err: Error) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_SEGMENT_CREATED,
            err?.message
          )
        );
      }
    }
  );
}
