import React from 'react';
import PropTypes from 'prop-types';

import TableNoSearchResults from '@shared/components/TableNoSearchResults';
import TableEmptyState from '@shared/components/TableEmptyState';

const ManageUsersModalTableEmptyState = ({ filterValue, ...props }) => {
  if (filterValue?.length) {
    return <TableNoSearchResults {...props} />;
  }

  return (
    <TableEmptyState
      label="This workspace doesn't have members"
      materialProps={props}
      pictureHeight="50px"
      pictureWidth="67px"
      picturePath="/images/account-settings/no-users.svg"
    />
  );
};

ManageUsersModalTableEmptyState.propTypes = {
  filterValue: PropTypes.string
};

ManageUsersModalTableEmptyState.defaultProps = {
  filterValue: ''
};

export default ManageUsersModalTableEmptyState;
