import React from 'react';
import cx from 'classnames';

import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';
import useProjectPageBreadcrumbs from '@shared/hooks/useProjectPageBreadcrumbs';

import styles from './ProjectPageHeader.module.scss';
import { Link } from 'react-router-dom';
import { ProjectHeaderRoute } from './types';

type ProjectPageHeaderProps = {
  subheaderRightSection?: React.ReactNode;
  routes: ProjectHeaderRoute[];
  sticky?: boolean;
  activeTab: string;
  handleTabChange: (tabName: string) => void;
};
const ProjectPageHeader: React.FC<ProjectPageHeaderProps> = ({
  subheaderRightSection = null,
  routes,
  sticky = false,
  activeTab,
  handleTabChange
}) => {
  useProjectPageBreadcrumbs();

  const renderTabs = () => {
    return routes.map(({ id, name, path }) => (
      <StyledTab
        key={id}
        value={id}
        // @ts-expect-error TODO move StyledTabs to TS
        component={Link}
        to={path}
        label={<span data-test={`single-project-nav-${id}`}>{name}</span>}
      />
    ));
  };

  const renderTabBar = () => {
    return (
      <StyledTabs
        className="charts-gallery-tab-bar"
        onChange={(_, tabName) => handleTabChange(tabName)}
        value={activeTab}
      >
        {renderTabs()}
      </StyledTabs>
    );
  };

  const renderSubheader = () => {
    return (
      <div className={styles.subheader}>
        {renderTabBar()}

        {subheaderRightSection}
      </div>
    );
  };

  return (
    <div
      className={cx(styles.projectPageHeader, {
        [styles.sticky]: sticky
      })}
    >
      {renderSubheader()}
    </div>
  );
};

export default ProjectPageHeader;
