import React from 'react';
import alertsActions from '../actions/alertsActions';
import AlertWarning from '@shared/components/AlertWarning';
import ConfirmWarning from '@shared/components/ConfirmWarning';
import { snackbarTypes } from '@/constants/alertTypes';

const alertsUtil = {
  openErrorDialog(id, msg, title = 'Error', callback) {
    return alertsActions.openDialog(id, {
      children: (
        <AlertWarning
          modalId={id}
          title={title}
          content={msg}
          callback={callback}
        />
      )
    });
  },

  openConfirmDialog(
    id,
    headerText,
    confirmHandler,
    buttonText = 'Continue',
    title = 'Are you sure?',
    bodyContent = null
  ) {
    return alertsActions.openDialog(id, {
      children: (
        <ConfirmWarning
          modalId={id}
          onConfirm={confirmHandler}
          headerText={headerText}
          buttonText={buttonText}
          bodyContent={bodyContent}
          title={title}
        />
      )
    });
  },

  openCustomModal(id, child, options = {}) {
    return alertsActions.openDialog(id, {
      children: child,
      ...options
    });
  },

  openSnackbarDialog(id, msg) {
    return alertsActions.openSnackbar(id, { message: msg });
  },

  closeDialog(id) {
    return alertsActions.closeDialog(id);
  },

  openCopySuccessSnackbar(msg = 'Successfully copied text to clipboard') {
    return alertsUtil.openSnackbarDialog(
      snackbarTypes.SUCCESS_COPIED_API_KEY,
      msg
    );
  }
};

export default alertsUtil;
