// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelVersionRequestStatusModal-module__modalVersionRequestStatus--Y9e4R{width:340px;display:flex;flex-direction:column}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusModal--Wwicy{width:460px;min-height:250px}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusLabel--HjT5m{color:var(--gray-6)}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusWrapper--ul14C{margin-top:10px}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusDescription--MuWJl{margin-top:32px;display:flex;color:var(--gray-6);align-items:center}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusComment--R6Ivm{margin-top:32px}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusCommentIcon--PywpZ{display:flex;margin-left:4px;align-items:center}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusCommentInput--IvDye{margin-top:10px}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusConfirmationModal--c8Rn2{width:460px !important}.ModelVersionRequestStatusModal-module__modalVersionRequestStatusConfirmationModal--c8Rn2 .basic-modal-content{font-weight:400;font-size:14px;line-height:21px;color:var(--gray-6)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalVersionRequestStatus": `ModelVersionRequestStatusModal-module__modalVersionRequestStatus--Y9e4R`,
	"modalVersionRequestStatusModal": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusModal--Wwicy`,
	"modalVersionRequestStatusLabel": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusLabel--HjT5m`,
	"modalVersionRequestStatusWrapper": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusWrapper--ul14C`,
	"modalVersionRequestStatusDescription": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusDescription--MuWJl`,
	"modalVersionRequestStatusComment": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusComment--R6Ivm`,
	"modalVersionRequestStatusCommentIcon": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusCommentIcon--PywpZ`,
	"modalVersionRequestStatusCommentInput": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusCommentInput--IvDye`,
	"modalVersionRequestStatusConfirmationModal": `ModelVersionRequestStatusModal-module__modalVersionRequestStatusConfirmationModal--c8Rn2`
};
module.exports = ___CSS_LOADER_EXPORT___;
