import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Dialog from '@material-ui/core/Dialog';
import ForgotPasswordModal from '@auth/components/ForgotPasswordModal';

import forgotPasswordActions from '@/actions/forgotPasswordActions';

const ResetPasswordPage = ({ dispatch, token }) => {
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const { token } = queryParams;

    if (token) {
      dispatch(forgotPasswordActions.setToken(token));
    }
  }, [dispatch]);

  const handleFinish = () => {
    window.location.href = '/login';
  };

  return (
    <div className="full-page-view reset-password-page">
      <Dialog
        className="text-center login-modal"
        disableBackdropClick
        hideBackdrop
        maxWidth="sm"
        open
        TransitionProps={{ tabIndex: 'none' }}
      >
        <ForgotPasswordModal onFinish={handleFinish} token={token} />
      </Dialog>
    </div>
  );
};

ResetPasswordPage.defaultProps = {
  token: ''
};

ResetPasswordPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string
};

export default connect()(ResetPasswordPage);
