import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Select } from '@DesignSystem/controllers';
import { matchPath, useLocation } from 'react-router';
import uniqBy from 'lodash/uniqBy';

import {
  CHART_TYPES,
  TOOLTIP_FEATURE_VIEW_SECTION,
  PREFIX_MPM_PATH,
  PERCENTILE_OPTION_LIST
} from '@mpm-druid/constants';
import { usePanelResize } from '@/DesignSystem/hooks';
import { useTooltip } from '@mpm-druid/hooks';
import {
  CHART_BASIC_CONFIG,
  CHART_BASIC_LAYOUT,
  makeLineChartTooltipShape,
  makeTriangleTooltipShape,
  addHoverLinesOpacity,
  makeLineChartNotifShape
} from '@mpm-druid/utils';
import OptimizedPlot from '@DesignSystem/charts/OptimizedPlot';
import { ChartTooltip, Indicator } from '../ChartTooltip';
import { NumericalDistributionChart } from '../NumericalDistributionChart/NumericalDistributionChart';
import { TableLegend } from '@DesignSystem/data-display';
import { LineChartIcon } from '@Icons-outdated';
import './NumericalDistributionContainer.scss';
import { changeColorOpacity } from '@shared';

export const NumericalDistributionContainer = ({
  data,
  percentileMode,
  onChangePercentileMode,
  disableZoom,
  section,
  chartType,
  tabData,
  from,
  to,
  setHoverLineData,
  height,
  layoutData,
  numericalDistributionData,
  numericalLoading,
  feature
}) => {
  const containerRef = useRef();
  const plotRef = useRef();
  const [counter, setCounter] = useState(1);
  const [handledPlotData, setHandledPlotData] = useState([]);

  const {
    inspectMode,
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    hideTooltipHandler,
    closeTooltipHandler,
    handleClick
  } = useTooltip();

  useEffect(() => {
    if (tooltipVisible) {
      const pointData = tooltipData.data.data;
      const hoverInfo = {
        xAxis: pointData?.x[tooltipData.data.pointIndex],
        color: tooltipData.color,
        tooltipVisible
      };
      setHoverLineData(prev => {
        return { ...prev, ...hoverInfo };
      });
    } else {
      setHoverLineData({});
    }
  }, [tooltipData, tooltipVisible, chartType, setHoverLineData]);

  usePanelResize(plotRef, containerRef);

  const chartShapes = useMemo(() => {
    const alertShapes = [];
    if (!data || data?.length === 0) return alertShapes;
    for (const slice of data) {
      if (!slice.alertNotifications?.length) continue;
      for (const notification of slice.alertNotifications) {
        alertShapes.push(
          makeLineChartNotifShape(notification, slice.line.color)
        );
      }
    }
    if (tooltipData?.x && tooltipVisible) {
      return [
        ...alertShapes,
        makeLineChartTooltipShape(tooltipData),
        makeTriangleTooltipShape(tooltipData)
      ];
    }
    return alertShapes;
  }, [tooltipData, tooltipVisible]);

  const { pathname } = useLocation();
  const isPerformancePage = matchPath(
    pathname,
    `${PREFIX_MPM_PATH}/performance`
  )?.isExact;

  const showViewFeatureAction =
    TOOLTIP_FEATURE_VIEW_SECTION.includes(section) && isPerformancePage;

  useEffect(() => {
    if (!tooltipVisible || !tooltipData) {
      setHandledPlotData(data);
      return;
    }
    const lineId = tooltipData?.data?.data?.query;
    setHandledPlotData(addHoverLinesOpacity(data, tooltipData?.title, lineId));
  }, [data, tooltipData, tooltipVisible]);

  const pillsData = useMemo(() => {
    if (!data) return [];
    if (data.length === 1 && data[0].query === 'all') return [];
    if (data.every(item => item.query === 'all')) {
      return [];
    }

    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      return uniqBy(
        data
          .filter(item => item.lineTrace === true)
          .map(trace => ({
            dotColor: trace.line?.color,
            text: trace.query || 'all',
            id: trace.predicateKey
          })),
        'text'
      );
    }

    return [];
  }, [data, chartType]);

  const layout = useMemo(() => {
    return {
      ...CHART_BASIC_LAYOUT,
      height,
      shapes: chartShapes,
      spikedistance: -1,
      hoverdistance: -1,
      hovermode: 'closest',
      datarevision: counter,
      ...layoutData
    };
  }, [counter, height, chartShapes, inspectMode, layoutData]);

  useEffect(() => {
    setCounter(prevVal => prevVal + 1);
    closeTooltipHandler();
  }, [data, layoutData.shapes, percentileMode]);

  const handleLegendHover = (id, text) => {
    let hoverData = handledPlotData.map(trace => {
      if (trace?.predicateKey === id || trace?.query === text) {
        return trace;
      }

      return {
        ...trace,
        fillcolor: changeColorOpacity(trace.fillcolor, 0.1),
        line: {
          ...trace.line,
          color: changeColorOpacity(trace.line?.color, 0.2)
        }
      };
    });
    setHandledPlotData(hoverData);
  };

  const handleLegendUnhover = () => {
    setHandledPlotData(data);
  };

  if (!data?.length) {
    return (
      <div>
        <div className="no-data-container">
          <LineChartIcon />
          <span>No data for this chart</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="labeled-select-container">
        <Select
          label="Percentiles"
          horizontalLabel={true}
          options={PERCENTILE_OPTION_LIST}
          value={percentileMode}
          onChange={onChangePercentileMode}
          variant="outlined"
        />
      </div>
      {pillsData.length > 0 && (
        <div className="chart-legend-container">
          <TableLegend
            onUnhover={handleLegendUnhover}
            onHover={handleLegendHover}
            pills={pillsData}
          />
        </div>
      )}

      <div>
        <div ref={containerRef} className="ds-chart-container">
          {tooltipVisible && (
            <>
              <Indicator position={tooltipPosition} color={tooltipData.color} />
              <ChartTooltip
                tooltipData={tooltipData}
                position={tooltipPosition}
                closeHandler={closeTooltipHandler}
                inspectMode={inspectMode}
                orientation={tooltipOrientation}
                section={section}
                chartType={chartType}
                tabData={tabData}
                showViewFeatureAction={showViewFeatureAction}
                percentileMode={percentileMode}
                feature={feature}
              />
            </>
          )}
          <OptimizedPlot
            config={CHART_BASIC_CONFIG}
            data={handledPlotData}
            layout={layout}
            useResizeHandler
            ref={plotRef}
            disableZoom={disableZoom}
            onHover={handleLinePointHover}
            onUnhover={hideTooltipHandler}
            onClick={handleClick}
            layoutProps={layoutData}
          />
          <div className="numerical-chart-wrapper">
            <NumericalDistributionChart
              numericalDistributionData={numericalDistributionData}
              layoutData={layoutData}
              tabData={tabData}
              outerTooltipData={tooltipData}
              outerTooltipVisible={tooltipVisible}
              from={from}
              to={to}
              featureName={feature?.name}
              isLoading={numericalLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
