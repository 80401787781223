import { useQuery } from 'react-query';
import organizationApi from '@shared/api/organizationApi';

const getWorkspaceUserPermissions = async ({ workspaceId, signal }) => {
  const { data } = await organizationApi.fetchWorkspaceUsersPermissions({
    workspaceId,
    signal
  });

  return data?.usersPermissions;
};

const useWorkspaceUsersPermissions = (workspaceId, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['workspaceUserPermissions', { workspaceId }],
    ({ signal }) => getWorkspaceUserPermissions({ workspaceId, signal }),
    {
      ...config,
      enabled: !!workspaceId && configEnabled,
      refetchOnMount: true
    }
  );
};

export default useWorkspaceUsersPermissions;
