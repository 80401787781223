import { useMutation } from 'react-query';
import modelRegistryApi from '../../util/modelRegistryApi';

const getModelRegistryVersionValidationResult = async jsonData => {
  const { data } = await modelRegistryApi.validateModelVersion(jsonData);

  return data;
};

const useModelRegistryVersionValidationMutation = () =>
  useMutation(data => getModelRegistryVersionValidationResult(data));

export default useModelRegistryVersionValidationMutation;
