import find from 'lodash/find';
import { createSelector } from 'reselect';
import { vizActionTypes } from '@/constants/actionTypes';
import get from 'lodash/get';

const initialState = {
  srcDoc: '',
  isFetchingTemplateMap: {},
  isTemplateSaved: true,
  isFetchingAllTemplates: true,
  thumbnail: null,
  hasInternalResourcesBeenFetched: false,
  internalResources: [],
  // counter that increments each time when 'queryBuilderId' is set
  queryBuilderIdRevision: 0,
  mutableTemplateFields: {
    templateName: '',
    scopeType: '',
    queryBuilderId: ''
  },
  savedGalleryTab: null,
  templateCountsMap: {},
  hoveredExperimentTrace: { experimentKey: null, source: null },
  dataFetchingErrors: {}
};

const visualizationsUiReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === vizActionTypes.FETCH_ALL_VISUALIZATION_TEMPLATES) {
    return {
      ...state,
      isFetchingAllTemplates: true
    };
  }

  if (type === vizActionTypes.FETCH_VISUALIZATION_TEMPLATE) {
    return {
      ...state,
      isFetchingTemplateMap: {
        ...state.isFetchingTemplateMap,
        [payload.templateId]: true
      }
    };
  }

  if (type === vizActionTypes.SET_VISUALIZATION_TEMPLATE) {
    return {
      ...state,
      isFetchingTemplateMap: {
        ...state.isFetchingTemplateMap,
        [payload.templateId]: false
      }
    };
  }

  if (type === vizActionTypes.SET_VISUALIZATION_TEMPLATES) {
    return {
      ...state,
      isFetchingAllTemplates: false
    };
  }

  if (type === vizActionTypes.DELETE_VISUALIZATION_TEMPLATE) {
    return {
      ...state,
      isFetchingTemplateMap: {
        ...state.isFetchingTemplateMap,
        [payload.templateId]: false
      }
    };
  }

  if (type === vizActionTypes.SET_SRC_DOC) {
    return {
      ...state,
      srcDoc: payload
    };
  }

  if (type === vizActionTypes.SET_IS_TEMPLATE_SAVED) {
    return {
      ...state,
      isTemplateSaved: payload
    };
  }

  if (type === vizActionTypes.SET_THUMBNAIL) {
    return {
      ...state,
      thumbnail: payload.thumbnail
    };
  }

  if (type === vizActionTypes.SET_INTERNAL_RESOURCES) {
    return {
      ...state,
      hasInternalResourcesBeenFetched: true,
      internalResources: payload.internalResources
    };
  }

  if (type === vizActionTypes.SET_TEMPLATE_QUERY_BUILDER_ID) {
    const { queryBuilderId } = payload;
    return {
      ...state,
      queryBuilderIdRevision: state.queryBuilderIdRevision + 1,
      mutableTemplateFields: {
        ...state.mutableTemplateFields,
        queryBuilderId
      }
    };
  }

  if (type === vizActionTypes.SET_TEMPLATE_NAME_FIELD) {
    const { templateName } = payload;
    return {
      ...state,
      mutableTemplateFields: {
        ...state.mutableTemplateFields,
        templateName
      }
    };
  }

  if (type === vizActionTypes.SET_TEMPLATE_SCOPE_TYPE) {
    const { scopeType } = payload;

    return {
      ...state,
      mutableTemplateFields: {
        ...state.mutableTemplateFields,
        scopeType
      }
    };
  }

  if (type === vizActionTypes.RESET_MUTABLE_TEMPLATE_FIELDS) {
    return {
      ...state,
      mutableTemplateFields: initialState.mutableTemplateFields
    };
  }

  if (type === vizActionTypes.SET_SAVED_GALLERY_TAB) {
    return {
      ...state,
      savedGalleryTab: payload.activeTab
    };
  }

  if (type === vizActionTypes.SET_GALLERY_TEMPLATE_COUNTS) {
    const { templateCountsMap } = payload;

    return {
      ...state,
      templateCountsMap
    };
  }

  if (type === vizActionTypes.SET_HOVERED_EXPERIMENT_TRACE) {
    const { experimentKey, source } = payload;

    return {
      ...state,
      hoveredExperimentTrace: { experimentKey, source }
    };
  }

  if (type === vizActionTypes.ADD_TEMPLATE_FETCHING_ERROR) {
    const { chartId, lastError, reason } = payload;

    return {
      ...state,
      dataFetchingErrors: {
        ...state.dataFetchingErrors,
        [chartId]: {
          lastError,
          reason
        }
      }
    };
  }

  return state;
};

export default visualizationsUiReducer;

export const getChartId = (_, props) => props.chartId;
export const getDataFetchingErrors = state => {
  return state.ui.visualizations.dataFetchingErrors;
};

export const getInternalResources = state =>
  state.ui.visualizations.internalResources;

export const getMutableTemplateFields = state =>
  state.ui.visualizations.mutableTemplateFields;

export const getSavedGalleryTab = state =>
  state.ui.visualizations.savedGalleryTab;

export const selectQueryBuilderIdRevision = state =>
  state.ui.visualizations.queryBuilderIdRevision;

export const getSrcDoc = state => state.ui.visualizations.srcDoc;

export const hasInternalResourcesBeenFetched = state =>
  state.ui.visualizations.hasInternalResourcesBeenFetched;

export const getCapturedThumbnail = state => state.ui.visualizations.thumbnail;

export const getHoveredExperimentTrace = state =>
  state.ui.visualizations.hoveredExperimentTrace;

export const selectIsTemplateSaved = state =>
  state.ui.visualizations.isTemplateSaved;

export const getInternalResourcesForNewTemplate = createSelector(
  [getInternalResources],
  internalResources => {
    return internalResources.map(resource => {
      const defaultVersion = find(resource.versions, 'isDefault');

      return {
        name: resource.name,
        version: defaultVersion.version,
        enabled: true
      };
    });
  }
);

export const getInternalResourceVersionMap = createSelector(
  [getInternalResources],
  internalResources => {
    return internalResources.reduce((result, resource) => {
      const versionsMap = resource.versions.reduce((map, resourceVersion) => {
        map[resourceVersion.version] = resourceVersion.url;

        return map;
      }, {});

      result[resource.name] = versionsMap;

      return result;
    }, {});
  }
);

export const getMutableTemplateQueryBuilderId = createSelector(
  [getMutableTemplateFields],
  mutableTemplateFields => {
    return mutableTemplateFields.queryBuilderId;
  }
);

export const getMutableTemplateName = createSelector(
  [getMutableTemplateFields],
  mutableTemplateFields => {
    return mutableTemplateFields.templateName;
  }
);

export const getMutableScopeType = createSelector(
  [getMutableTemplateFields],
  mutableTemplateFields => {
    return mutableTemplateFields.scopeType;
  }
);

export const isErrorFetchingDataForChart = createSelector(
  [getDataFetchingErrors, getChartId],
  (errors, chartId) => {
    return !!get(errors, chartId, false);
  }
);
