import { sortBy } from 'lodash';
import { useQuery } from 'react-query';

import { MANUALLY_ADDED_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import { LLM_MANUALLY_ADDED_COLUMNS } from '@experiment-management-shared/utils/LLMView';
import { SOURCE } from '@shared/utils/filterHelpers';
import api from '../api';

import useProject from './useProject';
import { PROJECT_TYPE } from '@/constants/projectConstants';

const llmColumnsModifier = columns => [
  ...LLM_MANUALLY_ADDED_COLUMNS,
  ...columns.filter(
    column => column.source === SOURCE.PARAMS || column.source === SOURCE.LLM
  )
];

const emColumnsModifier = columns => {
  return [...MANUALLY_ADDED_COLUMNS, ...columns];
};

const getFullColumnsData = ({ projectId, projectType }) => {
  return api.get('query/column-names', { projectId }).then(data => {
    // The API is inconsistent in the order of columns (on each request
    // it returns a different order), in order to improve the react-query
    // cache the returned object should be deeply equal among the requests
    const retVal = { ...data, columns: sortBy(data?.columns || [], 'id') };

    // Apply columns list modification according to project type
    switch (projectType) {
      case PROJECT_TYPE.LLM:
        retVal.columns = llmColumnsModifier(retVal.columns);
        break;
      case PROJECT_TYPE.EM:
        retVal.columns = emColumnsModifier(retVal.columns);
        break;
      default:
        retVal.columns = emColumnsModifier(retVal.columns);
        break;
    }

    return retVal;
  });
};

export default function useFullColumnsData(params, config, projectConfig) {
  const { data: project } = useProject({}, projectConfig || {});
  const projectId = project?.projectId;
  const projectType = project?.type;

  return useQuery(
    ['columns', { projectId }],
    () => getFullColumnsData({ projectId, projectType }),
    {
      enabled: !!projectId,
      ...config
    }
  );
}
