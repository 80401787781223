import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

const createServiceAccount = ({ organizationId, name, defaultWorkspaceId }) =>
  organizationApi.createServiceAccount({
    organizationId,
    name,
    defaultWorkspaceId
  });

const useCreateServiceAccountMutation = organizationId => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ name, defaultWorkspaceId }) =>
      createServiceAccount({ name, organizationId, defaultWorkspaceId }),
    {
      onSuccess: res => {
        const createdServiceAccount = res?.data || {};

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SERVICE_ACCOUNT_CREATED,
            'Successfully created a service account '
          )
        );

        queryClient.setQueriesData(
          ['organizations', organizationId, 'service-accounts'],
          oldSettingsAccounts => [
            ...(oldSettingsAccounts || []),
            createdServiceAccount
          ]
        );
      },
      onError: e => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SERVICE_ACCOUNT_NOT_CREATED,
            e?.response?.data?.msg || 'Service account has not been created '
          )
        );
      }
    }
  );
};

export default useCreateServiceAccountMutation;
