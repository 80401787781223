import { useQuery } from 'react-query';

import api from '../api';
import useProjectParams from './useProjectParams';

const getProjects = async ({ workspace }) => {
  const { projects = [] } = await api.get('projects/getAll', {
    workspace
  });

  return projects;
};

export default function useProjects(selectedWorkspace, config) {
  const configEnabled = config?.enabled ?? true;
  const { workspace } = useProjectParams();
  const activeWorkspace = selectedWorkspace || workspace;

  return useQuery(
    ['projects', { workspace: activeWorkspace }],
    () => getProjects({ workspace: activeWorkspace }),
    { enabled: configEnabled, ...config }
  );
}
