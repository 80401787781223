import { Table } from '@devexpress/dx-react-grid-material-ui';
import { EmptyExperimentTab } from '@shared';
import React from 'react';

const SystemMetricsEmptyTable = (celllProps: Table.DataCellProps) => {
  return (
    <EmptyExperimentTab.Cell {...celllProps}>
      <EmptyExperimentTab>
        <EmptyExperimentTab.Icon iconName="systemMetrics" />
        <EmptyExperimentTab.Title>
          No report system information
        </EmptyExperimentTab.Title>
      </EmptyExperimentTab>
    </EmptyExperimentTab.Cell>
  );
};

export default SystemMetricsEmptyTable;
