import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MarkdownIFrame from '@shared/components/MarkdownIFrame';
import SmallLoader from '@shared/components/SmallLoader';
import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import useAssetById from '@experiment-management-shared/api/useAssetById';

const MarkdownPreviewStyles = `<style>
html,
body {
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
}
</style>`;

const MarkdownPreview = ({ artifactVersionId, assetId, experimentKey }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const { data: asset } = useAssetById({
    experimentKey,
    assetId,
    artifactVersionId,
    asText: true
  });

  const handleFinishLoad = () => {
    setHasLoaded(true);
  };

  const handleError = () => {
    setHasLoaded(true);
    setIsError(true);
  };

  return (
    <div className="markdown-preview">
      {!hasLoaded && <SmallLoader primaryMessage="Loading..." />}
      {isError && <EmptyPreview message="No  preview available." />}
      {!isError && (
        <MarkdownIFrame
          textString={asset}
          onLoadHandler={handleFinishLoad}
          onErrorHandler={handleError}
          styles={MarkdownPreviewStyles}
        />
      )}
    </div>
  );
};

MarkdownPreview.defaultProps = {
  artifactVersionId: undefined
};

MarkdownPreview.propTypes = {
  artifactVersionId: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired
};

export default MarkdownPreview;
