import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import BaseSharePanelModal from '@experiment-management-shared/components/BaseSharePanelModal/BaseSharePanelModal';
import { IconButton, Tooltip } from '@ds';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import InfoIcon from '@material-ui/icons/Info';

import { snackbarTypes } from '../../../../constants/alertTypes';
import alertsUtil from '../../../../util/alertsUtil';

import useProjectParams from '../../../../api/project/useProjectParams';
import { DSCopyIcon } from '@ds-icons';

const ShareEmbeddedPanelCodeModal = props => {
  const dispatch = useDispatch();
  const { projectName } = useProjectParams();
  const {
    chartId,
    experimentKeys: panelExperimentKeys,
    instanceId,
    isCustomPanel,
    projectId,
    templateId,
    viewId
  } = props;
  const [includesExperimentKeys, setIncludesExperimentKeys] = useState(false);
  const experimentKeys = includesExperimentKeys ? panelExperimentKeys : null;
  const queryParameters = queryString.stringify(
    {
      chartId: isCustomPanel ? null : chartId,
      instanceId,
      projectId,
      templateId,
      viewId,
      experimentKeys
    },
    {
      arrayFormat: 'comma',
      skipEmptyString: true,
      skipNull: true
    }
  );
  const pathURL = isCustomPanel ? '/embedded' : '/embedded-panel';
  const embeddedPanelURL = `${window.location.origin}${pathURL}/?${queryParameters}`;
  const embeddedPanelCode = `<iframe width="800" height="600" frameborder="0" src="${embeddedPanelURL}"></iframe>`;

  const handleChange = event => {
    setIncludesExperimentKeys(!event.target.checked);
  };

  const handleClick = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_SHARE_LINK,
        'Successfully copied HTML embed panel code to clipboard'
      )
    );
  };

  return (
    <BaseSharePanelModal {...props} title="Embed Panel">
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">HTML Embed Code</InputLabel>

        <div className="share-panel-link">
          <InputBase
            className="modal-input-base share-panel-link-input"
            value={embeddedPanelCode}
            minRows={3}
            maxRows={8}
            multiline
            readOnly
          />
          <Tooltip
            aria-label="copy"
            placement="bottom"
            content="Copy HTML embed panel code to clipboard"
          >
            <CopyToClipboard text={embeddedPanelCode}>
              <IconButton
                edge="end"
                onClick={handleClick}
                Icon={<DSCopyIcon />}
              />
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>

      <div className="generic-modal-section share-embedded-panel-code-help">
        <InputLabel className="modal-input-label">Embed options</InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={!includesExperimentKeys}
              className="share-embedded-panel-include-experiments"
              color="primary"
              onChange={handleChange}
              size="small"
            />
          }
          label={
            <div className="share-embedded-panel-code-help-text">
              <span>Auto update with new experiments</span>
              <Tooltip
                aria-label="auto update experiments help"
                arrow
                placement="top"
                content={`Select this option to automatically fetch new experiments from ${projectName} that match the data filters for this panel.`}
              >
                <InfoIcon fontSize="small" style={{ color: '#b7bcc8' }} />
              </Tooltip>
            </div>
          }
        />
      </div>
    </BaseSharePanelModal>
  );
};

ShareEmbeddedPanelCodeModal.defaultProps = {
  instanceId: null,
  templateId: null
};

ShareEmbeddedPanelCodeModal.propTypes = {
  chartId: PropTypes.string.isRequired,
  experimentKeys: PropTypes.array.isRequired,
  instanceId: PropTypes.string,
  isCustomPanel: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  templateId: PropTypes.string,
  viewId: PropTypes.string.isRequired
};

export default ShareEmbeddedPanelCodeModal;
