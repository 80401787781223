import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import { useInView } from 'react-intersection-observer';
import { IconButton, Tooltip } from '@ds';
import { DSCopyIcon } from '@ds-icons';

import { snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import ReactPlaceholder from 'react-placeholder';
import { Resizable } from 're-resizable';
import useAssetById from '@experiment-management-shared/api/useAssetById';

import styles from './TextTab.module.scss';

const VISIBILITY_DELAY = 1000;

type TextCellProps = {
  assetId: string;
  experimentKey: string;
  setHeight: (assetId: string, height: number) => void;
  height: string | number | null;
};

const TextCell = ({
  assetId,
  experimentKey,
  setHeight,
  height
}: TextCellProps) => {
  const dispatch = useDispatch();
  const [wasVisible, setWasVisible] = useState(false);
  const [ref, inView] = useInView();

  const changeToVisible = useCallback(
    debounce(() => setWasVisible(true), VISIBILITY_DELAY),
    []
  );

  useEffect(() => {
    if (inView) {
      changeToVisible();
    } else {
      changeToVisible.cancel();
    }
  }, [changeToVisible, inView]);

  const { data: text, refetch } = useAssetById<string>(
    {
      assetId,
      experimentKey,
      asText: true,
      silent: true
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (wasVisible && isUndefined(text)) refetch();
  }, [assetId, experimentKey, wasVisible, text, refetch]);

  const handleCopyClick = useCallback(() => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_TEXT,
        'Successfully copied text'
      )
    );
  }, [dispatch]);

  const handleResize = useCallback(
    (e: MouseEvent | TouchEvent, direction: string, ref: HTMLDivElement) => {
      setHeight(assetId, ref.clientHeight);
    },
    [assetId, setHeight]
  );

  return (
    <div ref={ref} className={styles.textCellWrapper}>
      <ReactPlaceholder
        showLoadingAnimation
        type="text"
        rows={3}
        ready={!!text}
      >
        <Resizable enable={{ bottom: true }} onResizeStop={handleResize}>
          <div
            className={cx(styles.textContainer, {
              [styles.fullHeight]: height === 'auto'
            })}
            style={{ maxHeight: height ?? '100px' }}
          >
            {text}
          </div>
        </Resizable>
      </ReactPlaceholder>
      <Tooltip content="Click to copy text" placement="top">
        <CopyToClipboard text={text ?? ''}>
          <IconButton
            Icon={<DSCopyIcon />}
            onClick={handleCopyClick}
            type="secondary"
          />
        </CopyToClipboard>
      </Tooltip>
    </div>
  );
};

export default TextCell;
