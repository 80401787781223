import { useEffect, useState } from 'react';

const BOX_LINE_WIDTH_MAP_CONFIG: Record<
  string,
  { lineWidth: [number, number]; minContainerWidth: number }
> = {
  BIG: {
    lineWidth: [3, 5],
    minContainerWidth: 800
  },
  MEDIUM: {
    lineWidth: [3, 5],
    minContainerWidth: 500
  },
  SMALL: {
    lineWidth: [2, 3],
    minContainerWidth: 300
  },
  PREVIEW: {
    lineWidth: [1, 2],
    minContainerWidth: 0
  }
};

function calculateLineWidth(
  containerWidth: number,
  zoomLevel: number,
  scalingFactor: number
) {
  const [minLineWidth] = BOX_LINE_WIDTH_MAP_CONFIG.PREVIEW.lineWidth;
  const scaleFactor = 1 / (zoomLevel / 100);

  for (const size in BOX_LINE_WIDTH_MAP_CONFIG) {
    const {
      minContainerWidth,
      lineWidth: [minWidth, maxWidth]
    } = BOX_LINE_WIDTH_MAP_CONFIG[size];
    if (containerWidth >= minContainerWidth) {
      const valueRange = maxWidth - minWidth;

      return parseFloat(
        ((minWidth + scaleFactor * valueRange) * scalingFactor).toFixed(2)
      );
    }
  }

  // Return default size if no threshold is met
  return minLineWidth;
}

type UseBoundingBoxLineWidthOpts = {
  canvasWidth: number;
  imageRenderingWidth: number;
  zoomLevel: number;
  scalingFactor: number;
};
const useBoundingBoxLineWidth = ({
  canvasWidth,
  imageRenderingWidth,
  zoomLevel,
  scalingFactor
}: UseBoundingBoxLineWidthOpts) => {
  const [lineWidth, setLineWidth] = useState(() =>
    calculateLineWidth(imageRenderingWidth, zoomLevel, scalingFactor)
  );

  useEffect(() => {
    const computedWidthSize = calculateLineWidth(
      imageRenderingWidth,
      zoomLevel,
      scalingFactor
    );

    if (computedWidthSize !== lineWidth) setLineWidth(computedWidthSize);
  }, [canvasWidth, imageRenderingWidth, zoomLevel, lineWidth, scalingFactor]);

  return lineWidth;
};

export default useBoundingBoxLineWidth;
