import React from 'react';
import PropTypes from 'prop-types';

import DecisionTreeListByControlType from './DecisionTreeListByControlType';

const DecisionTree = ({ options, onChange, controlType, controlValue }) => {
  return (
    <DecisionTreeListByControlType
      onChange={onChange}
      controlType={controlType}
      controlValue={controlValue}
      options={options}
    />
  );
};

DecisionTree.defaultProps = {
  options: [],
  controlValue: false
};

DecisionTree.propTypes = {
  controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  controlValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
      tooltip: PropTypes.shape({
        title: PropTypes.string,
        placement: PropTypes.string,
        arrow: PropTypes.bool
      }),
      value: PropTypes.string,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      color: PropTypes.string,
      list: PropTypes.shape({
        options: PropTypes.array,
        controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
        onChange: PropTypes.func.isRequired
      })
    })
  )
};

export default DecisionTree;
