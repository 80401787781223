import { useCallback } from 'react';

import { onboardingEvents } from '@onboarding/constants';
import { trackEvent } from '@shared/utils/eventTrack';
import useOnboardingFirstClickStatus from './useOnboardingFirstClickStatus';

const useOnboardingFirstClick = () => {
  const {
    shouldSend,
    onFirstClick,
    pageName
  } = useOnboardingFirstClickStatus();

  const handleSendFirstClickEvent = useCallback(
    eventName => {
      if (shouldSend) {
        onFirstClick();
        trackEvent(onboardingEvents.FIRST_CLICK, {
          event_page: pageName,
          click_event: eventName
        });
      }
    },
    [shouldSend, onFirstClick, pageName]
  );

  return { onSendFirstClickEvent: handleSendFirstClickEvent };
};

export default useOnboardingFirstClick;
