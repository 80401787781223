import { useQuery } from 'react-query';

import { getOrganizationSettingsByName } from '@shared/utils/organization';
import organizationApi from '@shared/api/organizationApi';

const getOrganizationSettings = async (
  organizationId,
  { signal, settingName }
) => {
  const { data } = await organizationApi.fetchOrganizationSettings(
    organizationId,
    {
      signal
    }
  );

  return getOrganizationSettingsByName(data?.settings, settingName);
};

const useOrganizationSettings = (organizationId, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['organizationPermissions', { organizationId }],
    ({ signal }) =>
      getOrganizationSettings(organizationId, {
        signal,
        ...config
      }),
    {
      enabled: !!organizationId && configEnabled
    }
  );
};

export default useOrganizationSettings;
