import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import loginActions from '@/actions/loginActions';
import withUser from '@auth/utils/withUser';

import { DISABLE_SIGN_UP } from '@/constants/configConstants';

export class TryCometBtnComponent extends Component {
  constructor(props) {
    super(props);

    this.handleSignUp = this.handleSignUp.bind(this);
  }

  handleSignUp() {
    this.props.dispatch(loginActions.openRegistrationModal());
  }

  render() {
    if (this.props.isUserLoggedIn || DISABLE_SIGN_UP) return null;

    return (
      <div>
        <Button
          className={`button blue ${this.props.className}`}
          onClick={this.handleSignUp}
          data-test="signup-btn"
        >
          Sign Up
        </Button>
      </div>
    );
  }
}

TryCometBtnComponent.defaultProps = {
  className: 'signup-btn',
  isUserLoggedIn: false
};

TryCometBtnComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool,
  className: PropTypes.string
};

export default withUser(TryCometBtnComponent);
