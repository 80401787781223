import { Table } from '@DesignSystem/tables';
import cx from 'classnames';
import {
  concat,
  difference,
  findIndex,
  get,
  isArray,
  isEmpty,
  last,
  union,
  unionBy,
  uniq
} from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import useExperiments from '@API/experiments/useExperiments';
import { normalizeColumnName } from '@API/helpers/v2_helpers';
import useColumns from '@API/project/useColumns';
import useProjectTags from '@API/project/useProjectTags';
import { TextChunksHighlight } from '@DesignSystem/data-display/TextHighligt/TextChunksHighlight';
import { LLMEmptyState } from '@Icons-outdated';
import { Button } from '@ds';
import {
  allowToHaveOnlyOneAnyLevelGroupOpened,
  groupsMapToExperimentsGroups
} from '@experiment-management-shared';
import { TABLE_PAGE_SIZES } from '@experiment-management-shared/constants/experimentGridConstants';
import { LLM_TAB_PATHNAME } from '@experiment-management-shared/constants/llmConstants';
import { useExperimentsGroups } from '@experiment-management-shared/hooks';
import {
  DEFAULT_COLUMN_MAX_MAX_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_COLUMN_WIDTHS,
  DISABLED_COLUMN_ORDERING,
  DISABLED_COLUMN_SORTING,
  FIXED_LEFT_COLUMNS,
  GRID_COLUMNS,
  GROUPED_COLUMN_EXTENSION,
  LLM_NAME_MAP,
  METADATA_FETCH_ONLY_COLUMNS,
  MIN_MAX_COLUMN_WIDTH_MAP
} from '@experiment-management-shared/utils/LLMView';
import EmptySectionMessage from '@projects/components/EmptySectionMessage';
import LLMDashboardHeader from '@projects/components/LLMDashboardHeader';
import { QUERY_SEPARATOR } from '@projects/components/LLMDashboardHeader/LLMSearchForm/LLMSearchInput/consts';
import LLMGroupedCellComponent from '@projects/components/LLMGroupedCellComponent';
import useDashboardPageSectionName from '@projects/hooks/useDashboardPageSectionName';
import useDashboardSectionRedirect from '@projects/hooks/useDashboardSectionRedirect';
import useLLMDashboard from '@projects/hooks/useLLMDashboard';
import SmallLoader from '@shared/components/SmallLoader';
import {
  formatDateTime,
  formatNameForDisplay
} from '@shared/utils/displayHelpers';
import { trackEvent } from '@shared/utils/eventTrack';
import { SOURCE } from '@shared/utils/filterHelpers';

import DateCell from '@shared/components/TableCells/DateCell';
import ResizableExperimentTagList from '@shared/components/TableCells/ResizableExperimentTagList';
import TableEmptyState from '@shared/components/TableEmptyState';
import { NoResultsIcon } from '@shared/utils/svgIcons';
import { llmEvents } from '@/constants/trackingEventTypes';

import useArchiveExperimentsMutation from '@API/experiments/useArchiveExperimentsMutation';
import { BottomActionsPanel } from '@DesignSystem/bottom-actions-panel';
import { DeleteSelectionModalButton } from '@DesignSystem/bottom-actions-panel/panel-buttons';
import { LinkCell } from '@DesignSystem/data-display/LinkCell/LinkCell';
import useGroupAggregations from '@experiment-management-shared/api/useGroupAggregations';
import useGridSummary from '@experiment-management-shared/hooks/useGridSummary';
import isString from 'lodash/isString';
import isURL from 'validator/lib/isURL';
import LLMDashboardPageSidebar from '../LLMDashboardPageSidebar';
import styles from './LLMDashboardPage.module.scss';

const SELECT_COLUMN_WIDTH = 53;

const modifyQuery = searchQuery => {
  if (searchQuery.startsWith('"') && searchQuery.endsWith('"')) {
    return searchQuery.slice(1, -1);
  } else {
    return `*${searchQuery}*`;
  }
};

function getIsValidUrl(value) {
  return isString(value) && isURL(value, { require_protocol: true });
}

const getCellDataMask = ({ column }) => {
  if (column.name === GRID_COLUMNS.NAME) {
    return 'Prompt name';
  }

  if (column.name === GRID_COLUMNS.TIMESTAMP) {
    return '00/00/00 00:00:00 AM';
  }
};

const LLMDashboardPage = () => {
  const { projectName, workspace } = useParams();
  const section = useDashboardPageSectionName();
  const [queryColumn, setQueryColumn] = useState();
  const [queryText, setQueryText] = useState('');
  const [sidebarExperiment, setSidebarExperiment] = useState(null);
  const history = useHistory();
  const { promptId: selectedPromptId } = queryString.parse(
    window.location.search
  );

  const { isLoading: isLoadingColumns, data: columns } = useColumns(
    { extraCols: true },
    { refetchOnMount: true }
  );

  const llmColumns = useMemo(() => {
    if (!columns) return [];
    const filterColumns = columns?.filter(
      ({ source }) => source === SOURCE.LLM
    );
    setQueryColumn(filterColumns[0]?.name);
    return filterColumns;
  }, [columns]);

  const { dashboard, handleDashboardChange } = useLLMDashboard({ llmColumns });

  const hasGrouping = Boolean(dashboard?.table?.columnGrouping?.length);

  useDashboardSectionRedirect({
    section,
    workspace,
    projectName,
    targetSection: LLM_TAB_PATHNAME.prompts
  });

  useProjectTags({}, { notifyOnChangeProps: [] });

  const groups = useMemo(() => {
    return dashboard?.table.columnGrouping || [];
  }, [dashboard?.table.columnGrouping]);

  const chainsQueryRules = useMemo(
    () =>
      queryText
        ? [
            {
              column: queryColumn,
              searchPhrase: modifyQuery(queryText)
            }
          ]
        : [],
    [queryColumn, queryText]
  );

  const applyChangeExpandedGroups = useCallback(
    newExpandedGroups => {
      handleDashboardChange({
        table: { expandedGroups: newExpandedGroups, pageNumber: 0 }
      });
    },
    [handleDashboardChange]
  );

  const {
    isFetchingExperimentGroups,
    isLoadingExperimentGroups,
    experimentsGroupsMap
  } = useExperimentsGroups({
    columnGrouping: groups,
    chainsQueryRules,
    isArchive: false,
    view: dashboard,
    onExpand: applyChangeExpandedGroups,
    queryConfig: { refetchOnMount: true }
  });

  const {
    data: experimentsData,
    isFetching: isFetchingExperiments,
    isLoading: isLoadingExperiments
  } = useExperiments(
    {
      isArchive: false,
      groupsQuery: groups.length ? experimentsGroupsMap.path : undefined,
      chainsQueryRules,
      targetExperimentKeys: null,
      metadataColumns: concat(columns, METADATA_FETCH_ONLY_COLUMNS),
      view: dashboard
    },
    { refetchOnMount: true }
  );
  const { data: selectedSidebarPrompts } = useExperiments(
    {
      isArchive: false,
      targetExperimentKeys: [selectedPromptId],
      metadataColumns: concat(columns, METADATA_FETCH_ONLY_COLUMNS),
      view: dashboard
    },
    { enabled: !!selectedPromptId, retry: false }
  );

  const selectedSidebarPrompt = selectedSidebarPrompts?.experiments[0];
  useEffect(() => {
    if (selectedSidebarPrompt) {
      setSidebarExperiment(selectedSidebarPrompt);
    }
  }, [selectedSidebarPrompt]);

  const [selectedPrompts, onSelectedPrompt] = useState([]);

  const [hasLoadedExperiments, setHasLoadedExperiments] = useState(
    !isLoadingExperiments &&
      !isLoadingColumns &&
      dashboard &&
      !isLoadingExperimentGroups
  );

  const prompts = useMemo(() => {
    if (isEmpty(experimentsData?.experiments) && queryText !== '') {
      trackEvent(llmEvents.EMPTY_SEARCH_QUERY_RESULT, {
        queryColumn,
        queryText
      });
    }
    return experimentsData?.experiments || [];
  }, [experimentsData?.experiments, hasGrouping]);

  const sidebarExperimentIndex = findIndex(prompts, {
    experimentKey: sidebarExperiment?.experimentKey
  });
  const hasNext =
    sidebarExperimentIndex >= 0
      ? sidebarExperimentIndex < prompts.length - 1
      : false;
  const hasPrevious =
    sidebarExperimentIndex >= 0 ? sidebarExperimentIndex > 0 : false;

  const handleColumnWidthsChange = useCallback(
    rawColumnWidths => {
      // check min/max boundaries and fix if size is out
      const columnWidths = rawColumnWidths.map(c => {
        const boundaries =
          MIN_MAX_COLUMN_WIDTH_MAP[c.columnName] ||
          DEFAULT_COLUMN_MAX_MAX_WIDTH;

        return {
          ...c,
          width: Math.max(Math.min(c.width, boundaries.max), boundaries.min)
        };
      });

      handleDashboardChange({
        table: {
          columnWidths
        }
      });
    },
    [handleDashboardChange, dashboard?.table?.columnOrders, experimentsData]
  );

  const handlePageNumberChange = useCallback(
    pageNumber => {
      handleDashboardChange({
        table: { pageNumber }
      });
    },
    [handleDashboardChange]
  );

  const handleSortingChange = useCallback(
    columnSorting => {
      handleDashboardChange({
        table: { columnSorting }
      });
    },
    [handleDashboardChange]
  );

  const handleOrderChange = useCallback(
    columnOrders => {
      handleDashboardChange({
        table: {
          columnOrders: uniq([GRID_COLUMNS.NAME, ...columnOrders])
        }
      });
    },
    [handleDashboardChange]
  );

  const handlePageSizeChange = useCallback(
    newPageSize => {
      handleDashboardChange({
        table: {
          pageNumber: 0,
          pageSize: newPageSize
        }
      });
    },
    [handleDashboardChange]
  );

  const handleExpandedGroupsChange = useCallback(
    expandedGroups => {
      const newExpandedGroups = allowToHaveOnlyOneAnyLevelGroupOpened(
        expandedGroups
      );
      applyChangeExpandedGroups(newExpandedGroups);
    },
    [applyChangeExpandedGroups]
  );

  const getChildGroups = useCallback(groups => {
    return groups || [];
  }, []);

  const handleRowClick = useCallback(experiment => {
    setSidebarExperiment(experiment);
    history.replace({ search: `?promptId=${experiment.experimentKey}` });
  }, []);

  const handleSidebarNavigation = useCallback(
    shift => {
      const experiment = prompts[sidebarExperimentIndex + shift];
      setSidebarExperiment(experiment);
      history.replace({ search: `?promptId=${experiment.experimentKey}` });
    },
    [history, prompts, sidebarExperimentIndex]
  );

  useEffect(() => {
    if (
      !isLoadingExperiments &&
      !isLoadingColumns &&
      dashboard &&
      !isLoadingExperimentGroups
    ) {
      setHasLoadedExperiments(true);
    }
  }, [
    isLoadingExperiments,
    isLoadingExperimentGroups,
    isLoadingColumns,
    dashboard,
    prompts
  ]);

  const getRowClassName = useCallback(
    row => {
      // row can be empty in case it is header row
      if (isEmpty(row)) {
        return '';
      }

      if (
        sidebarExperiment &&
        row?.experimentKey === sidebarExperiment.experimentKey
      ) {
        return cx(styles.clickableRow, styles.selectedRow, {
          [styles.withGrouping]: hasGrouping
        });
      }

      return cx(styles.clickableRow, {
        [styles.withGrouping]: hasGrouping
      });
    },
    [sidebarExperiment, hasGrouping]
  );

  const queryTextToArray = useMemo(
    () =>
      modifyQuery(queryText)
        .split(QUERY_SEPARATOR)
        .filter(e => !!e),
    [queryText]
  );

  const labelColorMap = useMemo(() => ({}), []);

  const handleSearchQueryChange = useCallback(
    value => {
      setQueryText(value);
      handlePageNumberChange(0);
    },
    [handlePageNumberChange]
  );

  const dataTypes = useMemo(() => {
    return [
      {
        cols: columns
          ?.filter(
            column =>
              column.source !== SOURCE.LLM &&
              ![GRID_COLUMNS.TAGS, GRID_COLUMNS.TIMESTAMP].includes(column.name)
          )
          .map(({ name }) => name),
        cell: ({ value }) => {
          const isValidURL = getIsValidUrl(value);

          if (isValidURL) {
            return <LinkCell urlValue={value}> {value}</LinkCell>;
          }
          return <p>{value}</p>;
        }
      },
      {
        cols: llmColumns.map(({ name }) => name),
        cell: ({ value, column }) => {
          const textChunksHighlight = (
            <TextChunksHighlight
              enabled={column.name === queryColumn}
              chunks={queryTextToArray}
              className={styles.promptResponseCell}
            >
              {value}
            </TextChunksHighlight>
          );

          const isValidURL = getIsValidUrl(value);

          return isValidURL ? (
            <LinkCell urlValue={value}>{textChunksHighlight}</LinkCell>
          ) : (
            textChunksHighlight
          );
        }
      },
      {
        cols: [GRID_COLUMNS.TAGS],
        cell: ({ row }) => {
          return <ResizableExperimentTagList row={row} isDeleteDisabled />;
        }
      },
      {
        cols: [GRID_COLUMNS.TIMESTAMP],
        cell: ({ value }) => {
          return <DateCell value={value} formatter={formatDateTime} />;
        }
      }
    ];
  }, [llmColumns, queryColumn, queryTextToArray]);

  const groupedColumns = useMemo(() => {
    return (dashboard?.table?.columnGrouping || []).map(columnName => ({
      columnName
    }));
  }, [dashboard?.table?.columnGrouping]);

  const { data: groupAggregations } = useGroupAggregations(
    {
      groups,
      view: dashboard
    },
    { refetchOnMount: false, enabled: !!groups }
  );

  const {
    columnsForSummary,
    isWithSummary,
    renderSummaryCellComponent
  } = useGridSummary({
    columns,
    columnGrouping: groupedColumns.map(({ columnName }) => columnName),
    aggregations: groupAggregations,
    decimalsPrecision: 2
  });

  const prepareTableRows = useCallback(
    rows => {
      if (groupedColumns.length) {
        return groupsMapToExperimentsGroups(
          experimentsGroupsMap,
          experimentsGroupsMap.path,
          rows
        );
      }

      return rows;
    },
    [groupedColumns, experimentsGroupsMap]
  );

  const dynamicLLMNameMap = useMemo(() => {
    return llmColumns.reduce((acc, { name }) => {
      acc[name] = name
        .replace('chain_inputs', 'input')
        .replace('chain_outputs', 'output');
      return acc;
    }, LLM_NAME_MAP);
  }, [llmColumns]);

  const tableColumns = useMemo(() => {
    return union(
      dashboard?.table?.columnOrders,
      dashboard?.table?.columnGrouping
    ).map(columnName => {
      const { keyName } = normalizeColumnName(columnName);

      const cellColumn = columns?.find(column => column.name === keyName);
      return {
        name: columnName,
        title: formatNameForDisplay(columnName, dynamicLLMNameMap),
        type: cellColumn?.type || 'string',
        source: cellColumn?.source || ''
      };
    });
  }, [
    dashboard?.table?.columnOrders,
    dashboard?.table?.columnGrouping,
    columns,
    dynamicLLMNameMap
  ]);

  const disabledColumnsSorting = useMemo(() => {
    return [
      ...DISABLED_COLUMN_SORTING,
      ...llmColumns.map(({ name }) => ({
        columnName: name,
        sortingEnabled: false
      }))
    ];
  }, [llmColumns]);

  const archiveExperimentsMutation = useArchiveExperimentsMutation();

  const archiveExperiment = () => {
    archiveExperimentsMutation.mutate({ experimentKeys: selectedPrompts });
    onSelectedPrompt([]);

    const lastPageIndex =
      Math.ceil(totalExperiments / dashboard.table.pageSize) - 1;
    const isLastPage = lastPageIndex === dashboard.table.pageNumber;

    if (selectedPrompts.length === prompts.length) {
      if (isLastPage) {
        handleDashboardChange({
          table: {
            pageNumber: lastPageIndex === 0 ? lastPageIndex : lastPageIndex - 1
          }
        });
      }
    }
  };

  const columnWidths = useMemo(() => {
    const retVal = unionBy(
      dashboard?.table?.columnWidths || [],
      DEFAULT_COLUMN_WIDTHS,
      dashboard?.table?.columnOrders.map(columnName => ({
        columnName,
        width: DEFAULT_COLUMN_MAX_MAX_WIDTH.width
      })),
      dashboard?.table?.columnGrouping.map(columnName => ({
        columnName,
        width: DEFAULT_COLUMN_MAX_MAX_WIDTH.width
      })),
      'columnName'
    );

    // we need always keep width of the latest columns as auto to force table not to create empty cell in the end of the row
    if (
      isArray(retVal) &&
      isArray(dashboard?.table?.columnOrders) &&
      isArray(dashboard?.table?.columnGrouping)
    ) {
      const lastColumnName = last(
        union(
          [GRID_COLUMNS.NAME],
          difference(
            dashboard.table.columnOrders,
            dashboard.table.columnGrouping
          )
        )
      );

      retVal.forEach(colWidth => {
        if (
          colWidth.width === null ||
          (colWidth.width === 'auto' && colWidth.columnName !== lastColumnName)
        ) {
          colWidth.width = (
            MIN_MAX_COLUMN_WIDTH_MAP[colWidth.columnName] ||
            DEFAULT_COLUMN_MAX_MAX_WIDTH
          ).width;
        }

        if (
          colWidth.columnName === lastColumnName &&
          lastColumnName !== GRID_COLUMNS.NAME
        ) {
          colWidth.width = 'auto';
        }
      });
    }

    return retVal;
  }, [
    dashboard?.table?.columnOrders,
    dashboard?.table?.columnGrouping,
    dashboard?.table?.columnWidths
  ]);

  const renderTableEmptyState = useCallback(
    props => {
      return (
        <TableEmptyState
          additionalText={`We couldn't find prompts \n with matching query`}
          props={props}
          clickHandler={() => setQueryText('')}
          buttonText="Clear search"
          icon={<NoResultsIcon />}
          label="No results found"
          materialProps={props}
        />
      );
    },
    [setQueryText]
  );

  if (!hasLoadedExperiments) {
    return (
      <div id="table" className="table-wrapper">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={
            <span>
              Fetching project data for <b>{projectName}</b>
            </span>
          }
        />
      </div>
    );
  }

  if (!prompts.length && !hasGrouping && !queryText) {
    return (
      <EmptySectionMessage
        description="Check our documentation to learn how to run your first prompt"
        icon={<LLMEmptyState />}
        title="This project has no prompts"
        button={
          <a
            href="/docs/v2/guides/large-language-models/overview/"
            target="_blank"
          >
            <Button size="large">Go to LLM documentation</Button>
          </a>
        }
      />
    );
  }

  const totalExperiments = get(experimentsData, 'total', 0);

  const style = {
    '--ds-react-grid-pagination-width': `${
      columnWidths?.[0].width + SELECT_COLUMN_WIDTH || 0
    }px`,
    '--action-footer-height': `${selectedPrompts.length > 0 ? '60px' : '0px'}`
  };

  const getPromptLabel = () => {
    const promptLabel = selectedPrompts.length === 1 ? 'prompt' : 'prompts';
    return `${selectedPrompts.length} ${promptLabel}`;
  };

  return (
    <div className={styles.llmDashboard} style={style}>
      <LLMDashboardHeader
        onChange={handleDashboardChange}
        queryColumn={queryColumn}
        llmColumns={llmColumns}
        dashboardTable={dashboard?.table}
        dynamicLLMNameMap={dynamicLLMNameMap}
        controlledQueryText={queryText}
        handleQueryColumnChange={setQueryColumn}
        handleSearchQueryChange={handleSearchQueryChange}
      />
      <Table
        columns={tableColumns}
        dataTypes={dataTypes}
        rows={prompts}
        isFetching={isFetchingExperiments || isFetchingExperimentGroups}
        leftColumns={FIXED_LEFT_COLUMNS}
        onRowClick={handleRowClick}
        getCellDataMask={getCellDataMask}
        getRowClassName={getRowClassName}
        prepareTableRows={prepareTableRows}
        selectionConfig={{
          isDisabled: false,
          selection: selectedPrompts,
          onSelectionChange: onSelectedPrompt
        }}
        paginationConfig={{
          isDisabled: false,
          isServerSidePagination: true,
          pageNumber: dashboard?.table?.pageNumber,
          pageSize: dashboard?.table?.pageSize,
          pageSizes: TABLE_PAGE_SIZES,
          onPageNumberChange: handlePageNumberChange,
          onPageSizeChange: handlePageSizeChange
        }}
        columnWidthsConfig={{
          isDisabled: isEmpty(prompts),
          columnWidths,
          disabledColumns: [GRID_COLUMNS.NAME],
          onColumnWidthsChange: handleColumnWidthsChange,
          minColumnWidth: DEFAULT_COLUMN_WIDTH
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder: dashboard?.table?.columnOrders,
          onColumnOrderChange: handleOrderChange,
          disabledColumns: DISABLED_COLUMN_ORDERING
        }}
        sortingConfig={{
          isDisabled: false,
          columnSorting: dashboard?.table?.columnSorting,
          onSortingChange: handleSortingChange,
          disabledColumns: disabledColumnsSorting
        }}
        groupingConfig={{
          isDisabled: false,
          expandedGroups: dashboard?.table.expandedGroups,
          groupedColumns,
          groupAggregations,
          onExpandedGroupsChange: handleExpandedGroupsChange,
          getChildGroups,
          groupColumnExtensions: GROUPED_COLUMN_EXTENSION,
          GroupedCellComponent: LLMGroupedCellComponent,
          columnsForSummary,
          isWithSummary,
          renderSummaryCellComponent
        }}
        renderEmptyState={renderTableEmptyState}
        totalRowCount={totalExperiments}
        rowIdKey="experimentKey"
      />

      {selectedPrompts.length > 0 && (
        <BottomActionsPanel
          selectedItemsTotal={selectedPrompts.length}
          labelItems="prompts"
        >
          <DeleteSelectionModalButton
            modalTitle={`Delete ${getPromptLabel()}`}
            deleteHandler={archiveExperiment}
            className={styles.deleteButton}
            minWidth={'400px'}
          />
        </BottomActionsPanel>
      )}

      <LLMDashboardPageSidebar
        experiment={sidebarExperiment}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        onExperimentChange={handleSidebarNavigation}
        onClose={() => {
          setSidebarExperiment(null);
          history.replace({ search: '' });
        }}
        labelColorMap={labelColorMap}
      />
    </div>
  );
};

export default LLMDashboardPage;
