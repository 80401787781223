import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';

const uploadProjectImage = ({ imageFile, projectId }) => {
  return api.post('upload/project-image', imageFile, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { projectId }
  });
};

export default function useUpdateProjectImageMutation() {
  const queryClient = useQueryClient();
  const workspaceName = useWorkspaceName();

  return useMutation(
    ({ imageFile, projectId }) => uploadProjectImage({ imageFile, projectId }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projects', { workspaceName }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['projects', { workspaceName }]);
      }
    }
  );
}
