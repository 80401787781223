import React from 'react';
import { TextButton } from '@ds';
import useRestoreExperimentsMutation from '@API/experiments/useRestoreExperimentsMutation';
import { useHistory, useParams } from 'react-router';
import { DSRestoreIcon } from '@ds-icons';

const RestoreButton = () => {
  const { experimentKey, workspace, projectName } = useParams();
  const history = useHistory();
  const restoreExperimentsMutation = useRestoreExperimentsMutation();

  const handleRestoreExperimentFromArchive = async () => {
    try {
      await restoreExperimentsMutation.mutateAsync({
        experimentKeys: [experimentKey]
      });
      history.push(`/${workspace}/${projectName}/${experimentKey}`);
    } catch {
      // eslint-disable-next-line no-empty
    }
  };

  return (
    <TextButton
      onClick={handleRestoreExperimentFromArchive}
      PrefixIcon={<DSRestoreIcon />}
      type="secondary"
    >
      Restore
    </TextButton>
  );
};

export default RestoreButton;
