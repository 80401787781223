import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

export type PCDBox = {
  color: number[];
  id: string;
  label: string;
  name: string;
  score: number;
  segments: number[][][];
};

const jsonlToJSON = (lines: string) => {
  try {
    return JSON.parse(`[${lines.trim().replace(/\n/g, ',')}]`);
  } catch (e) {
    return [];
  }
};

const getPCDBoxes = async ({ url }: { url?: string }) => {
  if (!url) {
    return [];
  }

  const { data } = await api.get(url);

  return jsonlToJSON(data);
};

type UsePCDBoxesParams = {
  url?: string;
};

export function usePCDBoxes(
  { url }: UsePCDBoxesParams,
  config?: QueryConfig<PCDBox[]>
) {
  return useQuery(['pcdBoxes', { url }], () => getPCDBoxes({ url }), config);
}
