import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import cx from 'classnames';

import { CrossIcon, SearchIcon } from '@Icons-outdated';

import styles from './ExpandingSearchBar.module.scss';

export const SEARCH_BAR_VARIANT = {
  DARK: 'dark',
  BRIGHT: 'bright'
};

export const SEARCH_MODE_TYPE = {
  STANDARD: 'standard',
  FOCUS: 'focus'
};

export const IExpandingSearchBarMode = PropTypes.shape({
  type: PropTypes.oneOf([SEARCH_MODE_TYPE.STANDARD, SEARCH_MODE_TYPE.FOCUS])
});

const ExpandingSearchBar = ({
  value: searchValue,
  mode,
  variant,
  placeholder,
  onChange,
  debounceTimeout
}) => {
  const [value, setValue] = useState(searchValue);
  const [isOpen, setIsOpen] = useState(
    Boolean(value) || mode?.type === SEARCH_MODE_TYPE.FOCUS
  );
  const inputRef = useRef();
  const showCross = value !== '';

  const focusInput = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (mode?.type === SEARCH_MODE_TYPE.FOCUS) {
      setIsOpen(true);
      focusInput();
    }
  }, [mode]);

  const handleSearchChange = useCallback(search => onChange(search), [
    onChange
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchChange = useCallback(
    debounce(handleSearchChange, debounceTimeout),
    [debounceTimeout, handleSearchChange]
  );

  const handleInputChange = useCallback(
    event => {
      const inputValue = event.target.value;

      setValue(inputValue);
      handleDebouncedSearchChange(inputValue);
    },
    [handleDebouncedSearchChange]
  );

  const handleInputBlur = useCallback(() => {
    if (value === '') {
      setIsOpen(false);
    }
  }, [value]);

  const handleMagnifierClick = () => {
    if (!isOpen) {
      setIsOpen(true);
      focusInput();
    }
  };

  const handleCrossClick = () => {
    setIsOpen(false);
    setValue('');
    onChange('');
  };

  return (
    <div
      className={cx(styles.expandingSearchBox, {
        [styles.isOpen]: isOpen,
        [styles.dark]: variant === SEARCH_BAR_VARIANT.DARK,
        [styles.bright]: variant === SEARCH_BAR_VARIANT.BRIGHT
      })}
    >
      <input
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder={placeholder}
      />
      <div
        className={cx(styles.iconsContainer, {
          [styles.search]: !showCross,
          [styles.cross]: showCross
        })}
      >
        {!showCross && <SearchIcon onClick={handleMagnifierClick} />}
        {showCross && <CrossIcon onClick={handleCrossClick} />}
      </div>
    </div>
  );
};

ExpandingSearchBar.defaultProps = {
  value: '',
  mode: { type: SEARCH_MODE_TYPE.STANDARD },
  variant: SEARCH_BAR_VARIANT.BRIGHT,
  placeholder: 'Search',
  debounceTimeout: 300,
  onChange: noop
};

ExpandingSearchBar.propTypes = {
  value: PropTypes.string,
  mode: IExpandingSearchBarMode,
  variant: PropTypes.oneOf([
    SEARCH_BAR_VARIANT.BRIGHT,
    SEARCH_BAR_VARIANT.DARK
  ]),
  placeholder: PropTypes.string,
  debounceTimeout: PropTypes.number,
  onChange: PropTypes.func
};

export default ExpandingSearchBar;
