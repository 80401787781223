import { Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import './RadioButton.scss';

export const RADIO_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small'
};

function RadioButton(props) {
  const { size, ...radioProps } = props;
  return (
    <div className="ds-radio-btn">
      <Radio
        disableRipple
        checkedIcon={<div className={`icon checked ${size}`} />}
        icon={<div className={`icon ${size}`} />}
        {...radioProps}
      />
    </div>
  );
}

RadioButton.defaultProps = {
  size: RADIO_SIZE.STANDARD
};

RadioButton.propTypes = {
  size: PropTypes.oneOf([RADIO_SIZE.STANDARD, RADIO_SIZE.SMALL])
};

export default RadioButton;
