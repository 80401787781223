import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { AxiosError } from 'axios';

type RemoveExperimentOtherParams = {
  experimentKey: string;
  name: string;
};

const removeExperimentOther = async ({
  experimentKey,
  name
}: RemoveExperimentOtherParams) => {
  await api.delete('experiment/delete/other', {
    params: {
      experimentKey,
      metricName: name
    }
  });
};

export default function useRemoveExperimentOtherMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, RemoveExperimentOtherParams>(
    ({ experimentKey, name }) => {
      return removeExperimentOther({
        experimentKey,
        name
      });
    },
    {
      onMutate: async ({ experimentKey }) => {
        await queryClient.cancelQueries([
          'experiment-others',
          { experimentKey }
        ]);
      },
      onSuccess: async (_, { experimentKey }) => {
        await queryClient.invalidateQueries([
          'experiment-others',
          { experimentKey }
        ]);
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error trying to delete this param.'
          )
        );
      }
    }
  );
}
