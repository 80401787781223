import { IconButton, Tooltip } from '@ds';
import PropTypes from 'prop-types';
import React from 'react';
import { DSDownloadIcon } from '@ds-icons';

const DownloadReportButton = ({ onDownload }) => {
  return (
    <Tooltip content="Download" placement="bottom" arrow={false}>
      <IconButton
        onClick={onDownload}
        Icon={<DSDownloadIcon />}
        type="secondary"
      />
    </Tooltip>
  );
};

DownloadReportButton.propTypes = {
  onDownload: PropTypes.func.isRequired
};

export default DownloadReportButton;
