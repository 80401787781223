import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopySecondaryIcon, EnvelopeIcon } from '@Icons-outdated';
import { SUPPORT_EMAIL } from '@/lib/appConstants';

import styles from './UnhandledSegmentsChartBox.module.scss';

const TooltipTitle = ({ copyClickHandler }) => {
  return (
    <div className={styles.tooltipWrapper}>
      <span className={styles.clickSection}>
        <CopySecondaryIcon />{' '}
        <CopyToClipboard text={SUPPORT_EMAIL}>
          <span onClick={copyClickHandler}>Copy</span>
        </CopyToClipboard>
      </span>
      <div className={styles.separatLine} />
      <span className={styles.clickSection}>
        <EnvelopeIcon />{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
          Send Mail
        </a>
      </span>
    </div>
  );
};

const UnhandledSegmentsChartBox = ({ copyClickHandler }) => {
  return (
    <div className={styles.unhandledSegmentsWrapper}>
      <div className={styles.comingSoon}>Coming soon</div>
      <div className={styles.title}>Segments support is on its way!</div>
      <div className={styles.infoMessage}>
        Register your interest by getting in touch at{' '}
        <span>
          <Tooltip
            placement="top"
            arrow
            interactive
            classes={{
              tooltip: styles.tooltipBox,
              arrow: styles.tooltipArrow
            }}
            title={<TooltipTitle copyClickHandler={copyClickHandler} />}
          >
            <span className={styles.link}>Support@comet.com </span>
          </Tooltip>
        </span>
      </div>
    </div>
  );
};

TooltipTitle.propTypes = {
  copyClickHandler: PropTypes.func.isRequired
};

UnhandledSegmentsChartBox.propTypes = {
  copyClickHandler: PropTypes.func.isRequired
};

export default UnhandledSegmentsChartBox;
