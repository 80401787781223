import useCurrentUser from '@API/auth/useCurrentUser';
import { useMutation, useQueryClient } from 'react-query';
import userApi from '@/util/userApi';

const useUpdateOnboardingFlagMutation = () => {
  const { data: currentUser } = useCurrentUser();
  const username = currentUser?.username;
  const queryClient = useQueryClient();

  return useMutation(
    ({ flags }) => userApi.updateUserFlags({ flags, username }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-user']);
      }
    }
  );
};

export default useUpdateOnboardingFlagMutation;
