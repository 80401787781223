import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Box } from '@material-ui/core';
import classNames from './Tag.module.scss';
import Tag from './Tag';

const TagsPopup = ({ tags, className }) => (
  <Box className={cx(classNames.dsTagPopover, className)}>
    <Box className={classNames.dsTagContainerPopover}>
      {tags?.map(tag => (
        <Tag key={tag.tag} {...tag} />
      ))}
    </Box>
  </Box>
);

TagsPopup.propTypes = {
  className: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      color: PropTypes.string
    })
  ).isRequired
};

export default TagsPopup;
