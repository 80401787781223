const trackingSelectors = {
  setupIntercomUser(props) {
    const user = {};

    if (props.userName) {
      user.user_id = props.userName;
      if (props.hasExperiments) {
        user.has_experiments = props.hasExperiments;
      }
      if (props.isSkipQuickstart) {
        user.skipped_quickstart = props.isSkipQuickstart;
      }
      if (props.github) {
        user.connected_github = props.github;
      }
    }
    if (props.email) {
      user.email = props.email;
    }
    if (
      props.paymentPlan &&
      props.paymentPlan.user_plan &&
      props.paymentPlan.created_at
    ) {
      if (props.paymentPlan.user_plan) {
        user.payment_plan = props.paymentPlan.user_plan;
      }
      if (props.paymentPlan.created_at) {
        user.payment_plan_started_at = props.paymentPlan.created_at;
      }
    }
    if (!user.payment_plan) {
      user.payment_plan = 'none';
    }
    return user;
  }
};

export default trackingSelectors;
