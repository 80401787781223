import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import SortingIcon from './SortingIcon';

const SortLabel = ({ onSort, children, direction, disabled }) => {
  const label = children.props.children;

  if (disabled) {
    return (
      <ButtonBase className="sort-label" onClick={onSort} disabled={disabled}>
        {children}
        {direction && <SortingIcon direction={direction} />}
      </ButtonBase>
    );
  }

  return (
    <Tooltip title={label} placement="top">
      <ButtonBase className="sort-label" onClick={onSort} disabled={disabled}>
        {children}
        {direction && <SortingIcon direction={direction} />}
      </ButtonBase>
    </Tooltip>
  );
};

SortLabel.propTypes = {
  onSort: PropTypes.func.isRequired,
  direction: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.string,
    PropTypes.array
  ]).isRequired
};

SortLabel.defaultProps = {
  direction: null
};

export default SortLabel;
