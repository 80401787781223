import axios from 'axios';
import queryString from 'query-string';
import { BASE_API_URL } from '../../lib/appConstants';
import { RuleGroup } from '@experiment-management-shared/types';

export type AggregationTableColumn = {
  kind?: string;
  keyName: string;
  source: string;
};

export type GetGroupByAggregationsArgs = {
  projectId: string;
  rulesTree: RuleGroup;
  groupByColumns: AggregationTableColumn[];
  tableColumns: AggregationTableColumn[];
};

export type AggregationEntity = {
  aggregatedNumLast: number;
  aggregatedNumMax: number;
  aggregatedNumMin: number;
  experimentsCount: number;
  keyName: string;
};

export type AggregationDto = {
  aggregations: AggregationEntity[];
  children: AggregationDto[];
  groupColumn: string;
  groupNameValue: string;
  kind: string;
  source: string;
  path?: { value: string; column: string; kind: string }[];
};

export type GroupByAggregationsSuccessResponse = {
  metrics: string[];
  validationMessages: null;
  rootDto: AggregationDto | null;
  message?: string;
};

const experimentApi = {
  fetchLatestExperiment() {
    const url = `${BASE_API_URL}data-fetch/get/latest-experiment`;
    const options = {
      withCredentials: true
    };
    return axios.get(url, options);
  },

  fetchExperimentModels(experimentKey: string, signal: AbortSignal) {
    const url = `${BASE_API_URL}experiment/model?experimentKey=${experimentKey}`;
    return axios.get(url, { signal });
  },

  getExperimentNameByExperimentKey(experimentKey: string) {
    const url = `${BASE_API_URL}experiment-name/names`;
    return axios.post(url, JSON.stringify({ experimentKeys: [experimentKey] }));
  },

  getExperimentInfo(experimentKey: string) {
    const url = `${BASE_API_URL}experiment/get-info?experimentKey=${experimentKey}`;
    return axios.get(url);
  },

  getGroupByAggregations({
    projectId,
    rulesTree,
    groupByColumns = [],
    tableColumns = []
  }: GetGroupByAggregationsArgs) {
    const url = `${BASE_API_URL}query/experiments/aggregate`;
    const body = {
      projectId,
      rulesTree,
      groupByColumns,
      tableColumns
    };

    return axios.post<GroupByAggregationsSuccessResponse>(url, body);
  },

  getRedirectURL(experimentKey: string, params = {}) {
    const searchQuery = queryString.stringify(
      {
        experimentKey,
        ...params
      },
      {
        arrayFormat: 'comma',
        skipEmptyString: true,
        skipNull: true
      }
    );

    return `${BASE_API_URL}experiment/redirect?${searchQuery}`;
  }
};

export default experimentApi;
