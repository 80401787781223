import React, { useState } from 'react';

import DetectedExperimentUI from '@experiment-management-shared/components/DetectedExperiment';
import AnonymousModeClaim from './AnonymousModeClaim';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import { BETA_FEATURE_ANONYMOUS_MODE } from '@/lib/betaFeatures';
import AnonymousModeClaimedSuccesfully from './AnonymousModeClaimedSuccesfully';
import { useLocation } from 'react-router';
import useIsAnonymousUser from '@experiment-management-shared/hooks/useIsAnonymousUser';

type AnonymousModeBannerProps = {
  setOpenedSuccessClaimedModal: (t: boolean) => void;
  openedSuccessClaimedModal: boolean;
};

const AnonymousModeBanner = ({
  setOpenedSuccessClaimedModal,
  openedSuccessClaimedModal
}: AnonymousModeBannerProps) => {
  const { pathname } = useLocation();
  const isAnonymousModeEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_ANONYMOUS_MODE
  );
  const isAnonymousUser = useIsAnonymousUser();
  const matchClaimPage = pathname.match('claim-anonymous');
  const [openClaimModal, setOpenClaimModal] = useState<boolean>(false);

  if (!isAnonymousModeEnabled || matchClaimPage?.length) return null;

  return (
    <>
      {isAnonymousUser && (
        <AnonymousModeClaim
          open={openClaimModal}
          onClose={() => setOpenClaimModal(false)}
          openSuccess={() => setOpenedSuccessClaimedModal(true)}
        />
      )}
      {!isAnonymousUser && (
        <AnonymousModeClaimedSuccesfully
          open={openedSuccessClaimedModal}
          onClose={() => setOpenedSuccessClaimedModal(false)}
        />
      )}
      {isAnonymousUser && (
        <DetectedExperimentUI
          dataTest="anonymous-mode-banner"
          handleClickButton={() => {
            setOpenClaimModal(true);
          }}
          isSuccessfulExperimentRun={false}
          title="Comet detected this data was submitted in anonymous mode"
          label="Save this data by claiming it for your account."
          buttonLabel="Claim your data"
          isRunExperiment={false}
        />
      )}
    </>
  );
};

export default AnonymousModeBanner;
