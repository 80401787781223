import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@ds';

import CreateProjectModal from '@projects/components/CreateProjectModal';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { DSPlusIcon } from '@ds-icons';

const CreateProjectModalBtn = ({ dispatch }) => {
  const modalId = dialogTypes.CREATE_PROJECT_MODAL;
  const modal = <CreateProjectModal modalId={modalId} dispatch={dispatch} />;

  return (
    <Button
      onClick={() => dispatch(alertsUtil.openCustomModal(modalId, modal))}
      id="new-project-button"
      size="medium"
      PrefixIcon={<DSPlusIcon />}
      data-test="new-project-button"
    >
      New project
    </Button>
  );
};

CreateProjectModalBtn.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect()(CreateProjectModalBtn);
