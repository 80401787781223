import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

import { TextButton, Button } from '@ds';

import artifactActions from '@/actions/artifactActions';
import { parseMetadata } from '@artifacts/utils/helpers';
import { DSDiscardChangesIcon } from '@ds-icons';

const ArtifactVersionMetadataTab = ({
  artifactVersion,
  isUserAMemberOfWorkspace
}) => {
  const dispatch = useDispatch();
  const {
    workspace,
    artifactName: artifactNameEncoded,
    artifactVersionNumber
  } = useParams();
  const artifactName = decodeURIComponent(artifactNameEncoded);

  const [isMetadataUpdated, setIsMetadataUpdated] = useState(false);
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);

  const { artifactVersionId, metadata } = artifactVersion;

  const parsedMetadata = useMemo(() => parseMetadata(metadata), [metadata]);

  const onEditorChange = useCallback(() => {
    try {
      editorRef.current.get();
      setIsMetadataUpdated(true);
    } catch {
      setIsMetadataUpdated(false);
    }
  }, []);

  useEffect(() => {
    const container = editorContainerRef.current;
    if (!container) return;

    const editor = new JSONEditor(container, {
      mainMenuBar: false,
      mode: 'code',
      onChange: onEditorChange
    });

    if (!isUserAMemberOfWorkspace) {
      editor.aceEditor.setReadOnly(true);
    }

    editorRef.current = editor;

    return () => editor.destroy();
  }, [onEditorChange, isUserAMemberOfWorkspace]);

  useEffect(() => {
    if (editorRef.current && editorRef.current._debouncedValidate) {
      editorRef.current.set(parsedMetadata);
    }
  }, [parsedMetadata]);

  const handleReset = () => {
    if (editorRef.current && editorRef.current._debouncedValidate) {
      editorRef.current.set(parsedMetadata);
      setIsMetadataUpdated(true);
    }
  };

  const handleSave = () => {
    const updatedMetadata = editorRef.current.get();

    const updatedArtifactVersionFields = {
      artifactVersionId,
      metadata: updatedMetadata
    };

    dispatch(
      artifactActions.updateArtifactVersionMetadata(
        updatedArtifactVersionFields,
        workspace,
        artifactName,
        artifactVersionNumber
      )
    );

    setIsMetadataUpdated(false);
  };

  return (
    <div className="artifact-version-metadata-tab">
      <div className="custom-chart-options-editor-container">
        <div
          className="custom-chart-options-editor"
          ref={editorContainerRef}
          style={{ height: '49vh' }}
        />
        <div className="artifact-version-metadata-editor-footer">
          <TextButton
            size="large"
            onClick={handleReset}
            PrefixIcon={<DSDiscardChangesIcon />}
            disabled={!isUserAMemberOfWorkspace}
          >
            Reset
          </TextButton>
          <Button
            size="large"
            disabled={!isUserAMemberOfWorkspace || !isMetadataUpdated}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

ArtifactVersionMetadataTab.propTypes = {
  artifactVersion: PropTypes.object.isRequired,
  isUserAMemberOfWorkspace: PropTypes.bool.isRequired
};

export default ArtifactVersionMetadataTab;
