export const MODEL_VERSION_STATUSES = {
  NA: 'None',
  DEVELOPMENT: 'Development',
  STAGING: 'Staging',
  QA: 'QA',
  PRODUCTION: 'Production'
};

export const MODEL_VERSION_STATUSES_COLORS = {
  [MODEL_VERSION_STATUSES.NA]: 'grey',
  [MODEL_VERSION_STATUSES.DEVELOPMENT]: 'orange',
  [MODEL_VERSION_STATUSES.STAGING]: 'purple',
  [MODEL_VERSION_STATUSES.QA]: 'red',
  [MODEL_VERSION_STATUSES.PRODUCTION]: 'turquoise'
};
