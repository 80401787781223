import PropTypes from 'prop-types';
import React from 'react';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ThreeDotsMenuItem from './ThreeDotsMenuItem';
import classNames from './ThreeDotsMenu.module.scss';

const BUTTON_HALF_WIDTH_HEIGHT = 10;

const ThreeDotsMenu = ({
  anchorEl,
  handleClose,
  activeRowData,
  items,
  id,
  dataTest
}) => (
  <Popover
    id={id}
    PaperProps={{
      style: {
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)'
      },
      'data-test': dataTest
    }}
    onClose={handleClose}
    open={Boolean(anchorEl)}
    anchorReference="anchorPosition"
    anchorPosition={{
      top: anchorEl?.y - BUTTON_HALF_WIDTH_HEIGHT || 0,
      left: anchorEl?.x - BUTTON_HALF_WIDTH_HEIGHT || 0
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
  >
    <Box className={classNames.dsThreeDotsMenu}>
      {items.map(({ disabled, tooltip, text, getIcon, action, dataTest }) => {
        return (
          <ThreeDotsMenuItem
            key={text}
            tooltip={disabled && tooltip}
            disabled={disabled}
            onClick={ev => action(ev, activeRowData)}
            dataTest={dataTest}
          >
            <Grid
              container
              alignContent="center"
              alignItems="stretch"
              justifyContent="flex-start"
            >
              <Grid item className={classNames.dsThreeDotsMenuItemIconWrapper}>
                {getIcon({
                  className: classNames.dsThreeDotsMenuItemIcon
                })}
              </Grid>
              <Grid item className={classNames.dsThreeDotsMenuItemText}>
                {text}
              </Grid>
            </Grid>
          </ThreeDotsMenuItem>
        );
      })}
    </Box>
  </Popover>
);

ThreeDotsMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      disabled: PropTypes.bool.isRequired,
      getIcon: PropTypes.func.isRequired,
      dataTest: PropTypes.string,
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired
    })
  ).isRequired,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataTest: PropTypes.string.isRequired,
  activeRowData: PropTypes.object.isRequired
};

ThreeDotsMenu.defaultProps = {
  anchorEl: null
};

export default ThreeDotsMenu;
