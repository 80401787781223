import { MoveIcon } from '@Icons-outdated';
import TablePagination from '@material-ui/core/TablePagination';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import PeopleIcon from '@material-ui/icons/People';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { useIsUserMemberOfWorkspace } from '@shared/hooks';

import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import useDeleteExampleProjectMutation from '@projects/api/useDeleteExampleProjectMutation';
import useDeleteProjectMutation from '@projects/api/useDeleteProjectMutation';
import useProjects from '@projects/api/useProjects';
import useUpdateProjectViewMutation from '@projects/api/useUpdateProjectViewMutation';
import useUserSettings from '@projects/api/useUserSettings';
import useWorkspaceFilters from '@projects/api/useWorkspaceFilters';
import { PROJECT_CARD_DISPLAY } from '@projects/constants';
import SmallLoader from '@shared/components/SmallLoader';
import { PROJECT_CREATED_BY_DEFAULT_NAME } from '@shared/constants';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';

import projectsActions from '@/actions/projectsActions';
import workspaceActions from '@/actions/workspaceActions';
import { dialogTypes } from '@/constants/alertTypes';
import {
  NEW_GENERAL_PROJECT_NAME,
  OLD_GENERAL_PROJECT_NAME,
  PROJECT_TYPE
} from '@/constants/projectConstants';
import {
  SEARCH_TEXT_FETCH_FREQUENCY,
  TAB_STATE_KEYS,
  WORKSPACE_PAGE_TABS,
  WORKSPACE_TABS_COLUMNS,
  getSortByItemsByKey
} from '@/constants/workspaceConstants';
import { BETA_FEATURE_MOVE_PROJECT_TO_ANOTHER_WORKSPACE } from '@/lib/betaFeatures';
import { getTabState } from '@/reducers/ui/workspaceUiReducer';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import alertsUtil from '@/util/alertsUtil';
import StyledSearchBar from '@shared/components/StyledComponents/StyledSearchBar';
import CardsDisplay from '@shared/components/WorkspaceSubComponents/CardsDisplay';
import DisplayToggle from '@shared/components/WorkspaceSubComponents/DisplayToggle';
import FiltersPopover from '@shared/components/WorkspaceSubComponents/FiltersPopover';
import SingleCard from '@shared/components/WorkspaceSubComponents/SingleCard';
import SortByPopover from '@shared/components/WorkspaceSubComponents/SortByPopover';
import TableDisplay from '@shared/components/WorkspaceSubComponents/TableDisplay';
import CreateProjectModal from '@projects/components/CreateProjectModal/CreateProjectModal';

const { PROJECTS } = WORKSPACE_PAGE_TABS;
const {
  IS_CARD_DISPLAY,
  SORT_PROPERTY,
  SORT_DIRECTION,
  SELECTED_FILTERS,
  PAGE_NUMBER,
  PAGE_SIZE
} = TAB_STATE_KEYS;

const PINNED_ITEM_COLORS = ['#d1d3f4', '#c4e7e6', '#f7ead0'];
const PINNED_LLM_ITEM_COLOR = '#C4E7E6';

const ProjectsTab = ({ entityName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspace: activeWorkspaceName } = useParams();
  const [searchText, setSearchText] = useState('');

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const isMovingProjectToOtherWsEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MOVE_PROJECT_TO_ANOTHER_WORKSPACE
  );
  const { data: filters } = useWorkspaceFilters();
  const {
    sortByProperty,
    sortByDirection,
    selectedFilters,
    pageNumber,
    pageSize,
    isCardDisplay
  } = useSelector(state =>
    getTabState(state, { tabKey: WORKSPACE_PAGE_TABS.PROJECTS })
  );

  const {
    data: userSettings = {},
    isIdle: isIdleUserSettings = {},
    isLoading: isLoadingUserSettings
  } = useUserSettings({
    enabled: isUserLoggedIn
  });

  const {
    data: projectsData,
    isFetching,
    isLoading: areProjectsLoading,
    isPreviousData
  } = useProjects({
    filters: selectedFilters,
    pageSize,
    pageNumber,
    searchText,
    sortByDirection,
    sortByProperty
  });
  const { filteredRowCount = 0, rows: projects = [], totalRowCount = 0 } =
    projectsData || {};

  const deleteProjectMutation = useDeleteProjectMutation();
  const deleteExampleProjectMutation = useDeleteExampleProjectMutation();
  const updateProjectViewMutation = useUpdateProjectViewMutation();

  // sync server state with redux only first time
  // after only redux is better due performance
  const { visualizationType } = userSettings;
  useEffect(() => {
    if (!isLoadingUserSettings && !isIdleUserSettings && visualizationType) {
      dispatch(
        workspaceActions.updateTabStateByKey(
          PROJECTS,
          IS_CARD_DISPLAY,
          visualizationType === PROJECT_CARD_DISPLAY.PANEL
        )
      );
    }
  }, [dispatch, isLoadingUserSettings, isIdleUserSettings, visualizationType]);

  useEffect(() => {
    if (areProjectsLoading) return;

    dispatch(
      workspaceActions.setItemCountAndDisplay(
        PROJECTS,
        totalRowCount,
        searchText !== ''
      )
    );
  }, [areProjectsLoading, dispatch, totalRowCount, searchText]);

  useEffect(() => {
    dispatch(workspaceActions.updateTabStateByKey(PROJECTS, PAGE_NUMBER, 0));
    setSearchText('');
  }, [activeWorkspaceName, dispatch]);

  const debouncedSearchText = useCallback(
    debounce(text => {
      setSearchText(text);
      dispatch(workspaceActions.updateTabStateByKey(PROJECTS, PAGE_NUMBER, 0));
    }, SEARCH_TEXT_FETCH_FREQUENCY),
    [dispatch]
  );

  const handleSearchChange = useCallback(text => debouncedSearchText(text), [
    debouncedSearchText
  ]);

  const handleCancelSearch = useCallback(() => debouncedSearchText(''), [
    debouncedSearchText
  ]);

  const handleSortByChange = useCallback(
    (property, direction) => {
      dispatch(
        workspaceActions.updateTabStateByKey(
          PROJECTS,
          SORT_DIRECTION,
          direction
        )
      );
      dispatch(
        workspaceActions.updateTabStateByKey(PROJECTS, SORT_PROPERTY, property)
      );
    },
    [dispatch]
  );

  const handlePageNumberChange = (event, newPage) => {
    dispatch(
      workspaceActions.updateTabStateByKey(PROJECTS, PAGE_NUMBER, newPage)
    );
  };

  const handlePageSizeChange = event => {
    const newPageSize = parseInt(event.target.value, 10);

    dispatch(
      workspaceActions.updateTabState(PROJECTS, {
        [PAGE_SIZE]: newPageSize,
        [PAGE_NUMBER]: 0
      })
    );
  };

  const handleFilterChange = useCallback(
    updatedFilter => {
      let update = {};

      if (!isEmpty(updatedFilter)) {
        update = {
          ...selectedFilters,
          ...updatedFilter
        };
      }

      dispatch(
        workspaceActions.updateTabState(PROJECTS, {
          [SELECTED_FILTERS]: update,
          [PAGE_NUMBER]: 0
        })
      );
    },
    [dispatch, selectedFilters]
  );

  const handleSetIsCardDisplay = useCallback(
    async isCardDisplay => {
      dispatch(
        workspaceActions.updateTabStateByKey(
          PROJECTS,
          IS_CARD_DISPLAY,
          isCardDisplay
        )
      );

      const savedOnBEIsCardView =
        visualizationType === PROJECT_CARD_DISPLAY.PANEL;
      // check if user logged in and to not save the same data again and again
      if (isUserLoggedIn && isCardDisplay !== savedOnBEIsCardView) {
        await updateProjectViewMutation.mutate({
          visualizationType: isCardDisplay
            ? PROJECT_CARD_DISPLAY.PANEL
            : PROJECT_CARD_DISPLAY.LIST
        });
      }
    },
    [dispatch, isUserLoggedIn, updateProjectViewMutation, visualizationType]
  );

  const getProjectUrl = projectName => {
    const projectPath = encodeURIComponent(projectName);
    return `/${activeWorkspaceName}/${projectPath}`;
  };

  const getPinnedItemConfig = (item, index) => {
    const { projectId, isStarterProject, type } = item;

    if (isStarterProject) {
      return {
        isPinned: true,
        color: PINNED_ITEM_COLORS[index % PINNED_ITEM_COLORS.length],
        text: 'Example',
        handler: () => {
          const confirmMessage = (
            <span className="confirm-message-container">
              <span>
                Are you sure you want to remove this example project from your
                workspace?
              </span>
            </span>
          );

          dispatch(
            alertsUtil.openConfirmDialog(
              dialogTypes.CONFIRM_REMOVE_STARTER_PROJECT,
              confirmMessage,
              () => {
                dispatch(
                  alertsUtil.closeDialog(
                    dialogTypes.CONFIRM_REMOVE_STARTER_PROJECT
                  )
                );
                deleteExampleProjectMutation.mutate({ projectId });
              },
              'Remove'
            )
          );
        }
      };
    }

    if (type === PROJECT_TYPE.LLM) {
      return {
        isPinned: true,
        color: PINNED_LLM_ITEM_COLOR,
        text: 'LLM',
        handler: null
      };
    }

    return { isPinned: false };
  };

  const getMenuItemConfigs = item => {
    const isEM = item.type === PROJECT_TYPE.EM;

    const retVal = [];
    const canEditProject =
      item?.projectName !== PROJECT_CREATED_BY_DEFAULT_NAME;

    if (canEditProject) {
      retVal.push({
        label: 'Edit',
        Icon: CreateIcon,
        handler: () => {
          const {
            projectName,
            projectDesc,
            userName,
            isPublic,
            projectId,
            type
          } = item;

          const modalId = dialogTypes.CREATE_PROJECT_MODAL;

          const modal = (
            <CreateProjectModal
              modalId={modalId}
              dispatch={dispatch}
              editConfig={{
                projectName,
                projectDesc,
                isPublic,
                userName,
                projectId,
                type
              }}
            />
          );

          dispatch(alertsUtil.openCustomModal(modalId, modal));
        }
      });

      if (isMovingProjectToOtherWsEnabled) {
        retVal.push({
          label: 'Move',
          Icon: MoveIcon,
          dataTest: `move-project-btn-${item?.projectName}`,
          handler: () => {
            dispatch(projectsActions.openMoveProjectModal(item));
          }
        });
      }

      retVal.push({
        label: 'Delete',
        Icon: DeleteIcon,
        handler: () => {
          const { projectName, experimentCount } = item;

          const confirmMessage = (
            <span className="confirm-message-container">
              <span>
                This project contains <b>{experimentCount}</b>
                {isEM ? ' experiments' : ' prompts'}.
              </span>
              <span>
                Are you sure you want to delete <b>{projectName}</b> and all of
                its contents?
              </span>
              <span className="permanent-action-text">
                This action is permanent.
              </span>
            </span>
          );

          dispatch(
            alertsUtil.openConfirmDialog(
              dialogTypes.CONFIRM_DELETE_PROJECT_WITH_ALL_EXPERIMENTS,
              confirmMessage,
              () => {
                dispatch(
                  alertsUtil.closeDialog(
                    dialogTypes.CONFIRM_DELETE_PROJECT_WITH_ALL_EXPERIMENTS
                  )
                );

                deleteProjectMutation.mutate({ projectId: item.projectId });
              },
              'Delete'
            )
          );
        }
      });
    }

    if (isEM) {
      retVal.push({
        label: 'Share',
        Icon: PeopleIcon,
        handler: () => {
          history.push(
            `/${activeWorkspaceName}/${item.projectName}/view/new#${EXPERIMENT_TAB_PATHNAME.manage}`
          );
        }
      });
    }

    retVal.push({
      label: '  Change Image',
      Icon: ImageIcon,
      handler: () =>
        dispatch(projectsActions.openUpdateImageModal(item.projectId))
    });

    return retVal;
  };

  const hasDefaultView =
    !isUserLoggedIn || (!isIdleUserSettings && !isLoadingUserSettings);
  const isTabLoading = areProjectsLoading || !hasDefaultView;

  const renderBody = () => {
    if (isTabLoading) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={`Fetching ${entityName}`}
        />
      );
    }

    if (isEmpty(projects)) {
      return (
        <div className="content-not-found">
          <h3 className="page-notice">
            {`This workspace has no matching ${entityName}.`}
          </h3>
        </div>
      );
    }

    const renderTablePagination = () => {
      return (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={filteredRowCount}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handlePageNumberChange}
          onRowsPerPageChange={handlePageSizeChange}
          labelRowsPerPage={isCardDisplay ? 'cards per page' : 'rows per page'}
        />
      );
    };

    const isFetchingNewData = isFetching && isPreviousData;

    return isCardDisplay ? (
      <CardsDisplay
        items={projects}
        renderPagination={renderTablePagination}
        renderCard={(item, index) => {
          const {
            projectName,
            isPublic,
            projectDesc,
            experimentCount,
            lastUpdated,
            imagePath,
            projectId,
            type
          } = item;

          const title =
            projectName === OLD_GENERAL_PROJECT_NAME
              ? NEW_GENERAL_PROJECT_NAME
              : projectName;

          return (
            <SingleCard
              key={projectId}
              title={title}
              dataTestId={projectName}
              isFetchingNewData={isFetchingNewData}
              isEditable={isUserAMemberOfWorkspace}
              isPublic={isPublic}
              description={projectDesc}
              lastUpdated={lastUpdated}
              itemCount={{
                label: type === PROJECT_TYPE.LLM ? 'Prompts' : 'Experiments',
                count: experimentCount
              }}
              imagePath={imagePath}
              viewLinkConfig={{
                entityName: 'project',
                url: getProjectUrl(projectName),
                dataTest: 'view-project'
              }}
              menuItemConfigs={getMenuItemConfigs(item)}
              pinnedItemConfig={getPinnedItemConfig(item, index)}
              hideBackgroundImage={title === NEW_GENERAL_PROJECT_NAME}
            />
          );
        }}
      />
    ) : (
      <TableDisplay
        isEditable={isUserAMemberOfWorkspace}
        isFetchingNewData={isFetchingNewData}
        items={projects}
        columns={WORKSPACE_TABS_COLUMNS.PROJECTS}
        handleSortByChange={handleSortByChange}
        sortByDirection={sortByDirection}
        sortByProperty={sortByProperty}
        getURLForRowItem={item => {
          const { projectName } = item;
          return getProjectUrl(projectName);
        }}
        getMenuItemConfigs={getMenuItemConfigs}
        getPinnedItemConfig={getPinnedItemConfig}
        renderTablePagination={renderTablePagination}
      />
    );
  };

  return (
    <div>
      <div className="action-fields-container">
        <div className="field-group">
          <FiltersPopover
            handleFilterChange={handleFilterChange}
            selectedFilters={selectedFilters}
            filters={filters}
          />
          <StyledSearchBar
            searchValue={searchText}
            entityName={entityName}
            handleCancelSearch={handleCancelSearch}
            handleSearchChange={handleSearchChange}
          />
        </div>
        <div className="field-group">
          <SortByPopover
            handleSortByChange={handleSortByChange}
            sortByProperty={sortByProperty}
            sortByDirection={sortByDirection}
            items={getSortByItemsByKey(PROJECTS)}
          />
          {!isTabLoading && (
            <DisplayToggle
              isCardDisplay={isCardDisplay}
              handleIsCardDisplay={handleSetIsCardDisplay}
            />
          )}
        </div>
      </div>
      {renderBody()}
    </div>
  );
};

ProjectsTab.propTypes = {
  entityName: PropTypes.string.isRequired
};

export default ProjectsTab;
