import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TextButton } from '@ds';

import get from 'lodash/get';
import identity from 'lodash/identity';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  DETAIL_VIEWS,
  EXP_KEY_LENGTH_EXPORT
} from '@experiment-management-shared/constants/experimentConstants';

import { FILE_EXTENSIONS } from '@experiment-management-shared/utils/filesTreeUtils';
import ExportFileModal from '@experiment-management-shared/components/ExportFileModal';
import Highlight from '@shared/components/Highlight';
import SmallLoader from '@shared/components/SmallLoader';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { DSDownloadIcon, DSOpenInNewIcon } from '@ds-icons';
import ExperimentBar from '@experiment-details/components/ExperimentBar';

const VIEW_DETAILS = {
  [DETAIL_VIEWS.INSTALLED_PACKAGES]: {
    label: 'Packages',
    path: 'installedPackages',
    extension: FILE_EXTENSIONS.txt
  },
  [DETAIL_VIEWS.GRAPH_DEFINITION]: {
    label: 'Graph Definition',
    path: 'graph',
    extension: FILE_EXTENSIONS.json
  }
};

const BaseCodeView = ({
  data,
  detailView,
  experiments,
  isLoading,
  showContentHeader,
  transformData,
  emptyView
}) => {
  const [experiment] = experiments;
  const { label, path, extension } = get(VIEW_DETAILS, detailView);
  const { projectName, workspace } = useParams();
  const dispatch = useDispatch();
  const { experimentKey } = experiment;

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  const code = transformData(data);

  const isEmpty = !code;

  const handleDownloadClick = downloadFileName => {
    if (isEmpty) return;

    const blob = new window.Blob([code], {
      type: 'text/plain;charset=utf-8'
    });

    FileSaver.saveAs(blob, `${downloadFileName}.${extension}`);
  };

  const handleExportData = () => {
    const modalId = dialogTypes.EXPORT_FILE_MODAL;

    const exportFileModal = (
      <ExportFileModal
        submitHandler={handleDownloadClick}
        modalId={modalId}
        fileExtension={extension}
        modalTitle={`Export ${label}`}
        customFilenamePaths={[
          experimentKey.slice(0, EXP_KEY_LENGTH_EXPORT),
          path
        ]}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, exportFileModal));
  };

  const handleViewRaw = () => {
    const rawUrl = `${window.location.origin}/${workspace}/${projectName}/${experimentKey}/raw/${path}`;
    window.open(rawUrl, '_blank', 'noopener');
  };

  return (
    <>
      {showContentHeader && (
        <ExperimentBar
          rightContainerChildren={
            <ExperimentBar.ActionList>
              <TextButton
                onClick={handleViewRaw}
                type="secondary"
                PrefixIcon={<DSOpenInNewIcon />}
                disabled={isEmpty}
              >
                View raw
              </TextButton>

              <TextButton
                onClick={handleExportData}
                type="secondary"
                PrefixIcon={<DSDownloadIcon />}
                disabled={isEmpty}
              >
                Download
              </TextButton>
            </ExperimentBar.ActionList>
          }
        />
      )}

      {isEmpty ? (
        emptyView
      ) : (
        <div className="code-content">
          <Highlight code={code} />
        </div>
      )}
    </>
  );
};

BaseCodeView.defaultProps = {
  data: null,
  isLoading: false,
  showContentHeader: true,
  transformData: identity
};

BaseCodeView.propTypes = {
  data: PropTypes.string,
  detailView: PropTypes.string.isRequired,
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  isLoading: PropTypes.bool,
  showContentHeader: PropTypes.bool,
  transformData: PropTypes.func,
  emptyView: PropTypes.node
};

export default BaseCodeView;
