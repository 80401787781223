import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uniq from 'lodash/uniq';
import some from 'lodash/some';

import { withStyles } from '@material-ui/core/styles';

import StyledCheckbox from '@shared/components/StyledComponents/StyledCheckbox';

const DefaultCheckbox = withStyles({
  root: {
    color: '#4f55f5',
    fill: '#4f55f5'
  }
})(props => <StyledCheckbox {...props} />);

const SelectAllBtn = ({
  experimentKeys,
  isArchivePage,
  onSelectionChange,
  pageSize,
  pageNumber,
  selection,
  shouldUseServerSidePagination
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const pageExperimentKeys = useMemo(() => {
    if (shouldUseServerSidePagination) return experimentKeys;

    return experimentKeys.slice(
      pageNumber * pageSize,
      (pageNumber + 1) * pageSize
    );
  }, [experimentKeys, pageNumber, pageSize, shouldUseServerSidePagination]);

  useEffect(() => {
    if (selection.length === 0) {
      setIsIndeterminate(false);
      setIsChecked(false);
      return;
    }

    const isAnyOnPageUnchecked = some(pageExperimentKeys, experimentKey => {
      return !selection.includes(experimentKey);
    });

    if (isAnyOnPageUnchecked) {
      setIsIndeterminate(true);
    } else {
      setIsIndeterminate(false);
      setIsChecked(true);
    }
  }, [pageSize, pageNumber, selection, pageExperimentKeys]);

  const handleSelectExperimentsCurrentPage = () => {
    onSelectionChange(pageExperimentKeys);
    setIsChecked(true);
  };

  const handleToggleCheckbox = () => {
    if (isIndeterminate) {
      onSelectionChange(uniq([...selection, ...pageExperimentKeys]));
    } else if (isChecked) {
      onSelectionChange([]);
    } else {
      handleSelectExperimentsCurrentPage();
    }

    setIsChecked(!isChecked);
  };

  const renderCheckbox = () => {
    const checkboxProps = {
      className: classnames('select-all-checkbox', {
        'is-checked': isChecked || isIndeterminate,
        'is-archive': isArchivePage
      }),
      checked: isChecked,
      onChange: handleToggleCheckbox,
      indeterminate: isIndeterminate
    };

    return <DefaultCheckbox {...checkboxProps} />;
  };

  return (
    <div className="select-all-btn-container">
      <div className="select-all-checkbox-container">{renderCheckbox()}</div>
    </div>
  );
};

SelectAllBtn.defaultProps = {
  isArchivePage: false,
  selection: []
};

SelectAllBtn.propTypes = {
  experimentKeys: PropTypes.array.isRequired,
  isArchivePage: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  selection: PropTypes.array,
  shouldUseServerSidePagination: PropTypes.bool.isRequired
};

export default SelectAllBtn;
