import { useEffect, useMemo, useState } from 'react';
import { COMPARISON_COLUMNS_NAME } from '../newComparisonTableConfig';
import { ComparisonTableRow } from '../types';
import { Column, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { getTextWidth } from '@shared/hooks/useMiddleTruncation';
import { useScrollbarWidth } from '@shared/hooks';
import { VIRTUALIZATION_TABLE_LIMIT } from '@DesignSystem/tables/Table/Table';

const getDefaultColumnWidths = (columns: Column[]) =>
  [
    {
      columnName: COMPARISON_COLUMNS_NAME.KEY,
      width: 300
    },
    columns.map(col => ({
      columnName: col.name,
      width: 250
    }))
  ].flat();

type UseComparisonTableColumnWidthsOpts = {
  rowData: ComparisonTableRow[];
  columns: Column[];
  defaultHiddenColumnNames: string[];
  containerWidth: number;
};
const useComparisonTableColumnWidths = ({
  rowData,
  columns,
  defaultHiddenColumnNames,
  containerWidth
}: UseComparisonTableColumnWidthsOpts) => {
  const [columnWidths, onColumnWidthsChange] = useState<TableColumnWidthInfo[]>(
    () => getDefaultColumnWidths(columns)
  );
  let scrollbarWidth = useScrollbarWidth();
  const shouldBeVirtualized = rowData.length > VIRTUALIZATION_TABLE_LIMIT;
  if (!shouldBeVirtualized) {
    scrollbarWidth = 0;
  }

  useEffect(() => {
    let keyColumnWidth = 200;

    rowData.forEach(row => {
      const contentWidth =
        getTextWidth(row[COMPARISON_COLUMNS_NAME.KEY as 'key'], {
          fontSize: 14,
          fontWeight: 'bold'
        }) + 50;
      keyColumnWidth = Math.max(keyColumnWidth, contentWidth);
    });

    const availableWidth = containerWidth - keyColumnWidth - scrollbarWidth - 2;
    const visibleColumnNames = columns
      .filter(
        col =>
          !defaultHiddenColumnNames.includes(col.name) &&
          col.name !== COMPARISON_COLUMNS_NAME.KEY
      )
      .map(col => col.name);

    onColumnWidthsChange(prev =>
      prev.map(col => {
        if (visibleColumnNames.includes(col.columnName)) {
          return {
            ...col,
            width: Math.max(availableWidth / visibleColumnNames.length, 100)
          };
        }

        if (col.columnName === COMPARISON_COLUMNS_NAME.KEY) {
          return {
            ...col,
            width: keyColumnWidth
          };
        }

        return col;
      })
    );
  }, [defaultHiddenColumnNames, containerWidth]);

  const disabledColumns = useMemo(
    () => (rowData.length ? [] : columns.map(col => col.name)),
    [rowData.length]
  );

  return {
    columnWidths,
    onColumnWidthsChange,
    disabledColumns
  };
};

export default useComparisonTableColumnWidths;
