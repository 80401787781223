import { useEffect } from 'react';
import get from 'lodash/get';
import { useLocation } from 'react-router';

const ScrollToTop = () => {
  const { pathname, state } = useLocation();
  const preventScroll = get(state, 'preventScroll', false);

  useEffect(() => {
    if (preventScroll) return;

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
