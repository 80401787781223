import React, { useState } from 'react';

import useIsNestedParamsAllowed from '@experiment-details/components/params/hooks/useIsNestedParamsAllowed';

import HyperParametersConfigBar from './HyperParametersConfigBar';
import HyperParametersTable from './HyperParametersTable';
import useHyperParametersModalState from './hooks/useHyperParametersModalState';
import HyperParameterModals from './modals/HyperParameterModals';
import { ExperimentDetails } from '@shared/types';
import useInitialHyperParametersBI from './hooks/useInitialHyperParametersBI';

const DEFAULT_DECIMAL_PRECISION = 3;

export type HyperParametersTabProps = {
  experiments: ExperimentDetails[];
};

const HyperParametersTab: React.FC<HyperParametersTabProps> = ({
  experiments
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [decimalsPrecision, setDecimalsPrecision] = useState(
    DEFAULT_DECIMAL_PRECISION
  );
  const [hideIdenticalParams, setHideIdenticalParams] = useState(false);
  const isNestedParamApplied = useIsNestedParamsAllowed(experiments);
  const [firstExperiment] = experiments;

  const {
    isUpdateModalOpen,
    openUpdateParameterModal,
    closeUpdateParameterModal,
    isEdit,
    editParamName,
    editParamValue,
    deleteParamName,
    openDeleteParam,
    closeDeleteParam
  } = useHyperParametersModalState();

  useInitialHyperParametersBI({
    isNestedParamApplied,
    experiments
  });

  return (
    <div className="table-tab-container">
      <HyperParameterModals
        isUpdateModalOpen={isUpdateModalOpen}
        closeUpdateModal={closeUpdateParameterModal}
        isEdit={isEdit}
        parameterKey={editParamName}
        parameterValue={editParamValue}
        deleteParamName={deleteParamName}
        closeDeleteParam={closeDeleteParam}
        isNestedParamApplied={isNestedParamApplied}
      />
      <HyperParametersConfigBar
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
        decimalsPrecision={decimalsPrecision}
        onChangeDecimalsPrecision={setDecimalsPrecision}
        hideIdenticalParams={hideIdenticalParams}
        onChangeHideIdenticalParams={setHideIdenticalParams}
        onAddParameterClick={openUpdateParameterModal}
        experimentName={firstExperiment.Name}
      />
      <HyperParametersTable
        experiments={experiments}
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
        decimalsPrecision={decimalsPrecision}
        hideIdenticalParams={hideIdenticalParams}
        onEditParameterClick={openUpdateParameterModal}
        onDeleteParameterClick={openDeleteParam}
        isNestedParamApplied={isNestedParamApplied}
      />
    </div>
  );
};

export default HyperParametersTab;
