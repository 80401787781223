import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Box, Typography } from '@material-ui/core';
import {
  NoModelAssetsIcon,
  NoApiIcon,
  NoLineageIcon,
  NoHistoryIcon
} from '@Icons-outdated';
import classNames from './EmptyTabMessage.module.scss';

const EMPTY_TAB_MAPPING = {
  assets: {
    icon: <NoModelAssetsIcon />,
    message: 'No assets for this version'
  },
  lineage: {
    icon: <NoLineageIcon />,
    message: 'No lineage was created yet'
  },
  history: {
    icon: <NoHistoryIcon />,
    message: 'No history was recorded yet'
  },
  api: {
    icon: <NoApiIcon />,
    message: 'No API download instructions available'
  },
  notes: {
    icon: <span />,
    message: 'No notes was added yet'
  },
  default: {
    icon: <span />,
    message: 'No data for this version'
  }
};

const EmptyTabMessage = ({ type }) => {
  const { icon, message } =
    EMPTY_TAB_MAPPING[type] || EMPTY_TAB_MAPPING.default;

  return (
    <Box p={7} width="100%" data-test={`empty-data-${type || 'default'}`}>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <span>{icon}</span>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classNames.emptyTabMessageText}>
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

EmptyTabMessage.propTypes = {
  type: PropTypes.string.isRequired
};

export default EmptyTabMessage;
