import React from 'react';
import PropTypes from 'prop-types';
import UploadAvatar from './UploadAvatar';
import './PhotoSection.scss';

const PhotoSection = ({ onUploadPhoto, isPhotoUpdating, profileImage }) => (
  <div className="photo-section-container">
    <p className="field-label">Your photo</p>

    <div className="photo-block">
      <div>
        <UploadAvatar
          profileImage={profileImage}
          onUploadPhoto={onUploadPhoto}
          isPhotoUpdating={isPhotoUpdating}
        />
      </div>

      <div className="photo-label-container value-label">
        <span>Photos help your teammates recognize you in Comet</span>
      </div>
    </div>
  </div>
);

PhotoSection.propTypes = {
  onUploadPhoto: PropTypes.func.isRequired,
  isPhotoUpdating: PropTypes.bool.isRequired,
  profileImage: PropTypes.string.isRequired
};

export default PhotoSection;
