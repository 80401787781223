import React from 'react';
import { Tooltip } from '@ds';
import { ComparisonTableCellRendererData } from '../types';
import { ExperimentDetails } from '@shared/types';
import { MAX_LENGTH_EXPERIMENT_KEY } from '@experiment-management-shared/constants/compareTableConstants';
import { ExperimentCircleColorPicker } from '@experiment-management-shared/components';

import styles from './ComparisonTableCell.module.scss';

type ComparisonTableExperimentCellProps = ComparisonTableCellRendererData<ExperimentDetails>;

const ComparisonTableExperimentCell: React.FC<ComparisonTableExperimentCellProps> = ({
  value
}) => {
  return (
    <div className={styles.comparisonTableExperimentCell}>
      <div className={styles.comparisonTableExperimentCellColor}>
        <ExperimentCircleColorPicker experiment={value} />
      </div>

      <Tooltip content={value.Name} placement="top" truncateOverflow>
        <>{value.Name}</>
      </Tooltip>
      <span className={styles.comparisonTableExperimentCellKey}>
        {value.experimentKey.slice(0, MAX_LENGTH_EXPERIMENT_KEY)}
      </span>
    </div>
  );
};

export default ComparisonTableExperimentCell;
