import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';
import { CustomMetricType } from '@mpm/types';

type GetCustomMetricsProps = {
  modelId: string;
  workspaceId: string;
};

type CustomMetricsResponse = CustomMetricType[];

const getCustomMetrics = ({
  workspaceId,
  modelId,
  ...params
}: GetCustomMetricsProps) =>
  api.get(
    `mpm/${workspaceId}/models/${modelId}/custom_transformations`,
    params
  );

export function useCustomMetrics(
  params: { modelId: string },
  config: QueryConfig<CustomMetricsResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['custom-metrics', { workspaceId: activeWorkspace?.id, ...params }],
    () => getCustomMetrics({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config,
      enabled: !!activeWorkspace?.id
    }
  );
}
