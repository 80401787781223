import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, TextButton } from '@ds';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import useCreateProjectMutation from '@projects/api/useCreateProjectMutation';
import useEditProjectMutation from '@projects/api/useEditProjectMutation';
import useUserPermission from '@shared/api/useUserPermission';
import { MANAGEMENT_PERMISSIONS } from '@shared/constants/permissions';

import projectsActions from '@/actions/projectsActions';
import { DISABLE_PUBLIC_ACCESS } from '@/constants/configConstants';
import { useActiveWorkspace } from '@shared/hooks';

import { getValidatedProjectName } from '@/reducers/ui/projectsUiReducer';
import { getIsCollaborationEnabled } from '@/reducers/ui/workspaceUiReducer';
import alertsUtil from '@/util/alertsUtil';
import GenericModal from '@shared/components/GenericModal';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';
import { PROJECT_TYPE, PROJECT_VISIBILITY } from '@/constants/projectConstants';

const MAX_PROJECT_NAME_CHARS = 100;

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start'
  },
  label: {
    fontSize: '16px',
    padding: '8px 0'
  }
});

const CreateProjectModal = ({
  dispatch,
  editConfig,
  isCollaborationEnabled,
  modalId,
  validatedProjectName
}) => {
  const activeWorkspace = useActiveWorkspace();
  const { getPermissionStatus } = useUserPermission();
  const createProjectMutation = useCreateProjectMutation();
  const editProjectMutation = useEditProjectMutation();

  const isEditMode = !isEmpty(editConfig.projectName);
  const editProjectVisibility =
    editConfig.isPublic && !DISABLE_PUBLIC_ACCESS
      ? PROJECT_VISIBILITY.PUBLIC
      : PROJECT_VISIBILITY.PRIVATE;

  const [projectName, setProjectName] = useState(editConfig.projectName);
  const [projectDesc, setProjectDesc] = useState(editConfig.projectDesc);
  const [projectType, setProjectType] = useState(
    editConfig.type || PROJECT_TYPE.EM
  );

  const [projectVisibility, setProjectVisibility] = useState(
    editProjectVisibility
  );
  const [isInvalidProjectName, setIsInvalidProjectName] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (editConfig.projectName) {
      dispatch(projectsActions.validateProjectName(editConfig.projectName));
    }

    return () => {
      dispatch(projectsActions.validateProjectBeingAdded(null));
    };
  }, []);

  useEffect(() => {
    if (isNull(validatedProjectName)) return;

    if (isEmpty(validatedProjectName) || projectName !== validatedProjectName) {
      setIsInvalidProjectName(true);
    }
  }, [validatedProjectName]);

  const handleSubmit = () => {
    const isPublic = projectVisibility === PROJECT_VISIBILITY.PUBLIC;

    if (isEditMode) {
      const { projectId, userName } = editConfig;

      editProjectMutation.mutate({
        description: projectDesc,
        isPublic,
        type: projectType,
        name: projectName,
        projectId,
        userName,
        workspaceId: activeWorkspace?.id
      });
    } else {
      createProjectMutation.mutate({
        description: projectDesc,
        name: projectName,
        isPublic,
        type: projectType,
        workspaceId: activeWorkspace?.id
      });
    }
    handleClose();
  };

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleValidateProjectName = e => {
    dispatch(projectsActions.validateProjectName(e.target.value));
  };

  const handleProjectNameChange = e => {
    const { value } = e.target;
    setProjectName(value);
    setIsInvalidProjectName(false);
  };

  const renderProjectTypeField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Project Type</InputLabel>
        <RadioGroup
          value={projectType}
          onChange={e => setProjectType(e.target.value)}
          style={{ fontSize: '15px' }}
        >
          <FormControlLabel
            classes={classes}
            value={PROJECT_TYPE.EM}
            control={
              <Radio color="primary" size="small" disabled={isEditMode} />
            }
            label="Experiment Tracking (View and analyze model training metrics)"
            labelPlacement="end"
          />
          <FormControlLabel
            classes={classes}
            value={PROJECT_TYPE.LLM}
            control={
              <Radio color="primary" size="small" disabled={isEditMode} />
            }
            label={
              <>
                Large Language Models (Tailored visualizations for analyzing{' '}
                <br /> prompts and chains)
              </>
            }
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
    );
  };

  const renderProjectVisibilityField = () => {
    if (!isCollaborationEnabled || DISABLE_PUBLIC_ACCESS) return null;

    const isDisabled = !getPermissionStatus({
      workspaceName: activeWorkspace?.name,
      permissionKey: MANAGEMENT_PERMISSIONS.CHANGE_PROJECT_PRIVACY
    });

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">
          Project Visibility
        </InputLabel>
        <RadioGroup
          value={projectVisibility}
          onChange={e => setProjectVisibility(e.target.value)}
          style={{ fontSize: '15px' }}
        >
          <FormControlLabel
            classes={classes}
            value={PROJECT_VISIBILITY.PUBLIC}
            control={
              <Radio color="primary" size="small" disabled={isDisabled} />
            }
            label="Public (Anyone on the internet can see this project)"
            labelPlacement="end"
          />
          <FormControlLabel
            classes={classes}
            value={PROJECT_VISIBILITY.PRIVATE}
            control={
              <Radio color="primary" size="small" disabled={isDisabled} />
            }
            label="Private (You choose who can see experiments for this project)"
            labelPlacement="end"
            data-test="new-project-private"
          />
        </RadioGroup>
      </div>
    );
  };

  const getProjectNameTooltipText = () => {
    if (isNull(validatedProjectName)) return '';

    if (isEmpty(projectName)) {
      return 'You must give your project a name';
    }

    if (isEmpty(validatedProjectName)) {
      return 'A project name cannot contain only special characters';
    }

    if (isInvalidProjectName) {
      return `Your project will be named ${validatedProjectName}`;
    }

    return '';
  };

  const renderCharsLeft = () => {
    return (
      <Tooltip
        title={`Project names can be a maximum of ${MAX_PROJECT_NAME_CHARS} characters`}
        placement="top"
      >
        <span style={{ alignSelf: 'flex-end' }}>
          {Math.min(projectName.length, MAX_PROJECT_NAME_CHARS)}/
          {MAX_PROJECT_NAME_CHARS} left
        </span>
      </Tooltip>
    );
  };

  const renderProjectNameField = () => {
    const tooltipText = getProjectNameTooltipText();
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Name</InputLabel>
        <StyledValidationTooltip
          title={tooltipText}
          open={isInvalidProjectName && !isEmpty(tooltipText)}
        >
          <input
            className="native-input"
            value={projectName}
            onChange={handleProjectNameChange}
            onBlur={handleValidateProjectName}
            placeholder="Enter project name"
            data-test="new-project-name"
            maxLength={MAX_PROJECT_NAME_CHARS}
          />
        </StyledValidationTooltip>
        {renderCharsLeft()}
      </div>
    );
  };

  const renderProjectDescriptionField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Description</InputLabel>
        <InputBase
          className="modal-input-base"
          onChange={e => setProjectDesc(e.target.value)}
          value={projectDesc}
          placeholder="Enter description"
          minRows={3}
          maxRows={8}
          multiline
          data-test="project-description-input"
        />
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title={isEditMode ? 'Edit project' : 'Create new project'}
      hideFooter
    >
      <DialogContent className="generic-modal-body">
        <div
          className="generic-modal-group"
          style={{
            flexDirection: 'column',
            alignSelf: 'flexStart',
            width: '100%'
          }}
        >
          {renderProjectNameField()}
          {renderProjectDescriptionField()}
          {renderProjectTypeField()}
          {renderProjectVisibilityField()}
        </div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton onClick={handleClose} size="large">
          Cancel
        </TextButton>
        <Button
          size="large"
          onClick={handleSubmit}
          disabled={isEmpty(projectName) || isEmpty(validatedProjectName)}
          data-test="new-project-save"
        >
          {isEditMode ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

CreateProjectModal.defaultProps = {
  editConfig: {
    projectName: '',
    projectDesc: '',
    isPublic: false,
    type: PROJECT_TYPE.EM
  },
  validatedProjectName: null
};

CreateProjectModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  editConfig: PropTypes.object,
  isCollaborationEnabled: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  validatedProjectName: PropTypes.string
};

const mapStateToProps = state => ({
  isCollaborationEnabled: getIsCollaborationEnabled(state),
  validatedProjectName: getValidatedProjectName(state)
});

export default connect(mapStateToProps)(CreateProjectModal);
