import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const EmptyCell = ({ width, cellWidth }) => (
  <Box
    width={cellWidth}
    display="flex"
    alignItems="center"
    justifyContent="start"
  >
    <Box width={width} height="2px" bgcolor="rgb(140 150 167 / 35%)" />
  </Box>
);

EmptyCell.defaultProps = {
  cellWidth: '130px',
  width: '30px'
};

EmptyCell.propTypes = {
  width: PropTypes.string,
  cellWidth: PropTypes.string
};

export default EmptyCell;
