import { useMemo, useState } from 'react';
import useAssetsForExperiments from '@experiment-management-shared/api/useAssetsForExperiments';
import { useExperimentCode } from '@experiment-details/api';
import { prepareFileOptions } from '@experiment-details/utils/code';
import { ExperimentDetails } from '@shared/types';
import useAssetById from '@experiment-management-shared/api/useAssetById';

const CODE_ASSET_TYPE = 'source_code';

type UseExperimentCodeWrapperProps = {
  experiment: ExperimentDetails;
  file: string;
  fileField: 'value' | 'fullLabel';
  maxSelectWidth?: number;
};

const useExperimentCodeWrapper = ({
  experiment,
  file,
  fileField,
  maxSelectWidth
}: UseExperimentCodeWrapperProps) => {
  const {
    data: assetSummaries,
    isLoading: isLoadingAssets
  } = useAssetsForExperiments(
    {
      experiments: [experiment],
      type: CODE_ASSET_TYPE
    },
    {
      refetchInterval: false
    }
  );

  const { data: codeData, isLoading: isLoadingCode } = useExperimentCode({
    experimentKey: experiment.experimentKey
  });

  const { code: codeRequestCode = null, fileName: codeFileName = '' } =
    codeData || {};

  const fileOptions = useMemo(() => {
    if (isLoadingCode || isLoadingAssets) return [];

    const assetOptions = prepareFileOptions(assetSummaries, maxSelectWidth);

    if (!codeRequestCode) return assetOptions;

    return [
      {
        label: codeFileName,
        fullLabel: codeFileName,
        shortLabel: codeFileName,
        experimentKey: experiment.experimentKey,
        value: '',
        searchString: codeFileName
      },
      ...assetOptions
    ];
  }, [
    isLoadingCode,
    isLoadingAssets,
    assetSummaries,
    maxSelectWidth,
    codeRequestCode,
    codeFileName,
    experiment.experimentKey
  ]);

  const [fileOption, setFileOption] = useState(
    fileOptions.find(option => option[fileField] === file) ?? {
      value: '',
      fullLabel: '',
      experimentKey: ''
    }
  );

  const isLoadAssetEnabled =
    Boolean(fileOption.value) &&
    fileOption.experimentKey === experiment.experimentKey;

  const { data: assetCode, isLoading: isLoadingAsset } = useAssetById(
    {
      experimentKey: experiment.experimentKey,
      asText: true,
      assetId: fileOption.value
    },
    {
      enabled: isLoadAssetEnabled
    }
  );

  return {
    fileOption,
    setFileOption,
    fileOptions,
    isLoading:
      isLoadingAssets ||
      isLoadingCode ||
      isLoadingAsset ||
      Boolean(isLoadAssetEnabled && !assetCode),
    code: fileOption.value ? assetCode : codeRequestCode
  };
};

export default useExperimentCodeWrapper;
