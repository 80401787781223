// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoDetail-module__container--Ijb0X{display:flex;flex-direction:column;align-items:center;height:100%}.VideoDetail-module__video--P4gQu{width:100%;height:100vh;align-self:center;justify-content:center;object-fit:contain}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VideoDetail-module__container--Ijb0X`,
	"video": `VideoDetail-module__video--P4gQu`
};
module.exports = ___CSS_LOADER_EXPORT___;
