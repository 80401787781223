import AddSectionButton from '@reports/components/AddSectionButton';
import ReportSection from '@reports/components/ReportSection';
import { generateNewSection, generateNewSectionId } from '@reports/utils';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import dashboardHelpers from '@shared/utils/dashboardHelpers';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ReportSections.module.scss';

const ReportSections = ({
  canEdit,
  editMode,
  isNewReport,
  onChange,
  projectId,
  projectName,
  sections
}) => {
  const isEditable = editMode === REPORT_EDIT_MODES.EDIT;
  const sectionsAmount = sections.length;

  const handleAddNewSectionToTheEnd = () =>
    onChange([...sections, generateNewSection()]);

  const sectionsList = sections.map(section => {
    const position = sections.findIndex(({ id }) => section.id === id);
    const hasStartFocus =
      editMode === REPORT_EDIT_MODES.EDIT && position === 0 && isNewReport;

    const handleAddNewSection = () =>
      onChange([
        ...sections.slice(0, position),
        generateNewSection(),
        ...sections.slice(position)
      ]);

    const handleChange = section => {
      onChange([
        ...sections.slice(0, position),
        section,
        ...sections.slice(position + 1)
      ]);
    };

    const handleDelete = () => {
      const newSections = sections.filter(s => s !== section);

      onChange(newSections);
    };

    const handleDuplicate = () => {
      const clonedSection = cloneDeep(section);
      const newSection = dashboardHelpers.generatePanelIdsForSection(
        clonedSection
      );

      newSection.id = generateNewSectionId();

      onChange([
        ...sections.slice(0, position + 1),
        newSection,
        ...sections.slice(position + 1)
      ]);
    };

    const handleOnMoveDown = () => {
      const newSections = [...sections];
      const nextSection = newSections[position + 1];

      newSections[position] = nextSection;
      newSections[position + 1] = section;

      onChange(newSections);
    };

    const handleOnMoveUp = () => {
      const newSections = [...sections];
      const previousSection = newSections[position - 1];

      newSections[position - 1] = section;
      newSections[position] = previousSection;

      onChange(newSections);
    };

    return (
      <>
        {isEditable && <AddSectionButton onClick={handleAddNewSection} />}

        <ReportSection
          key={section.id}
          canEdit={canEdit}
          editMode={editMode}
          hasStartFocus={hasStartFocus}
          onChange={handleChange}
          onDelete={handleDelete}
          onDuplicate={handleDuplicate}
          onMoveDown={handleOnMoveDown}
          onMoveUp={handleOnMoveUp}
          position={position}
          projectId={projectId}
          projectName={projectName}
          section={section}
          sectionsAmount={sectionsAmount}
        />
      </>
    );
  });

  return (
    <div className={styles.sections}>
      {sectionsList}

      {isEditable && <AddSectionButton onClick={handleAddNewSectionToTheEnd} />}
    </div>
  );
};

ReportSections.defaultProps = {
  sections: []
};

ReportSections.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  editMode: PropTypes.string.isRequired,
  isNewReport: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  sections: PropTypes.array
};

export default ReportSections;
