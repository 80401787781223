import { useQuery } from 'react-query';

import { AlertNotification } from '@mpm-druid/types';
import api, { QueryConfig } from '@shared/api';
import { alertNotificationTransform } from './useAlertNotifications';

type MonitorNotification = {
  id: string;
  creationDate: string;
  resolvedDate: string;
  modelVersion: string | null;
  monitorId: string;
  monitorName: string;
  runCronExpression: string;
  status: 'RESOLVED' | 'OPEN';
};

// Hook response
export type AlertNotificationsByIDResponse = {
  alertNotifications: AlertNotification[];
  total: number;
};

type AlertNotificationsByIDParams = {
  modelId: string;
  selectedAlertNotificationIds: string[];
};

const getAlertNotificationsByID = async ({
  modelId,
  selectedAlertNotificationIds
}: AlertNotificationsByIDParams) => {
  const { data } = await api.post(`/mpm/v2/monitor-notification/get-by-id`, {
    monitorNotificationIds: selectedAlertNotificationIds,
    modelId
  });

  return {
    ...data,
    alertNotifications: data.monitorNotifications.map(
      (alertNotification: MonitorNotification) =>
        alertNotificationTransform(alertNotification)
    )
  };
};

export function useAlertNotificationsByID(
  params: AlertNotificationsByIDParams,
  config: QueryConfig<AlertNotificationsByIDResponse>
) {
  return useQuery(
    ['alertNotificationsById', { ...params }],
    () =>
      getAlertNotificationsByID({
        ...params
      }),
    {
      enabled: config.enabled
    }
  );
}
