import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './BottomActionsPanel.scss';

export const BottomActionsPanel = ({
  selectedItemsTotal,
  absolute,
  labelItems,
  children
}) => {
  return (
    <div
      className={cx('panel-actions-wrapper', {
        'panel-actions-absolute': absolute
      })}
    >
      <div className="panel-actions-text">
        {selectedItemsTotal} selected {labelItems}
      </div>

      <div className="panel-actions-controlers">{children}</div>
    </div>
  );
};

BottomActionsPanel.defaultProps = {
  absolute: false
};

BottomActionsPanel.propTypes = {
  children: PropTypes.node.isRequired,
  selectedItemsTotal: PropTypes.number.isRequired,
  labelItems: PropTypes.string.isRequired,
  absolute: PropTypes.bool
};
