import { AxiosError } from 'axios';
import api, { QueryConfig } from '@shared/api';
import { useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import useComputeURL from '@experiment-management-shared/api/custom-server-panels/useComputeURL';
import useIsServerCustomPanelsEnabled from '@experiment-management-shared/hooks/custom-server-panels/useIsServerCustomPanelsEnabled';

import { useSelector } from 'react-redux';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';
import hash from 'object-hash';
import { getURLOverride } from '@experiment-management-shared/helpers';
import randomstring from 'randomstring';
import {
  ExperimentColorMap,
  MetricColorMap
} from '@experiment-management-shared/types';
import { restartComputeEngine } from '@experiment-management-shared/api/custom-server-panels/helpers';

const PANEL_PREFIX = 'page_';

type CustomPanelOptionType = { [key: string]: string | number };

interface GetUsePythonPanelUrl {
  url: string;
  code: string;
  workspaceName?: string;
  projectName?: string;
  instanceId?: string;
  experimentKeys: string[];
  options?: CustomPanelOptionType;
  urlOverride?: string;
  revisionId?: string;
  codeVersion?: string;
  projectToken: string;
  sessionId?: string;
  metricColorMap?: MetricColorMap;
  experimentColorMap?: ExperimentColorMap;
  panelHeight?: number;
  panelWidth?: number;
}

type GetUsePythonPanelURLArgs = Omit<
  GetUsePythonPanelUrl,
  'url' | 'urlOverride'
>;

interface GetUsePythonPanelUrlResponse {
  url: string;
}
const SESSION_ID_LENGTH = 6;

const getInstanceId = (fieldsToHash: Partial<GetUsePythonPanelUrl>): string => {
  return PANEL_PREFIX + hash(fieldsToHash);
};

const getPythonPanelURL = async (
  {
    url,
    code,
    workspaceName,
    projectName,
    instanceId = '',
    experimentKeys,
    options,
    urlOverride,
    revisionId = '',
    projectToken,
    sessionId,
    metricColorMap,
    experimentColorMap,
    panelWidth,
    panelHeight
  }: GetUsePythonPanelUrl,
  signal: AbortSignal | undefined
): Promise<string> => {
  const id = getInstanceId({
    instanceId,
    revisionId,
    sessionId
  });

  const response = await api.post<GetUsePythonPanelUrlResponse>(
    `${url}`,
    {
      code,
      workspace: workspaceName,
      project: projectName,
      instanceId: id,
      experimentKeys,
      options,
      metricColorMap,
      experimentColorMap,
      url_override: urlOverride,
      width: panelWidth,
      height: panelHeight
    },
    {
      signal,
      headers: { 'X-API-KEY': projectToken }
    }
  );

  return response?.data?.url + '?embed=true';
};

// the main usage of this function is to catch an error while getting access to the panel
const getIsPythonPanelAvailable = async (
  url: string,
  signal: AbortSignal | undefined
): Promise<void> => {
  try {
    await api.get(url, { signal });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw Error();
    }
  }
};

const usePythonPanelURL = (
  options: GetUsePythonPanelURLArgs,
  config: QueryConfig<string> = {}
) => {
  const [hasTriedRestartCompute, setHasTriedRestartCompute] = useState(false);
  const queryClient = useQueryClient();
  const projectId = useSelector(getSelectedProjectId);
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();
  // needed to kill streamlit caching between sessions
  const sessionIdRef = useRef(randomstring.generate(SESSION_ID_LENGTH));

  // if there is a code version
  // the code should be sent depending on that version
  const cachedCode = useMemo(() => {
    return options.code;
  }, [options.codeVersion]);

  const {
    data: computeURL,
    isLoading: computeURLLoading,
    isFetching: computeURLFetching,
    isError: computeURLIsError,
    isPreviousData: computeURLIsPreviousData,
    error: computeURLError
  } = useComputeURL(projectId, {
    enabled: isServerCustomPanelsEnabled,
    retry: 0
  });

  const outEnabled = config.enabled ?? true;

  const query = useQuery(
    [
      'custom-panel-server-url',
      {
        projectName: options.projectName,
        workspaceName: options.workspaceName,
        experimentKeys: options?.experimentKeys,
        instanceId: options.instanceId,
        options: options.options,
        revisionId: options?.revisionId,
        codeVersion: options?.codeVersion,
        metricColorMap: options?.metricColorMap,
        experimentColorMap: options?.experimentColorMap,
        panelWidth: options?.panelWidth,
        panelHeight: options?.panelHeight,
        computeURL
      }
    ],
    async ({ signal }) => {
      const url = await getPythonPanelURL(
        {
          urlOverride: getURLOverride(),
          url: computeURL || '',
          ...options,
          code: cachedCode || options.code,
          sessionId: sessionIdRef.current
        },
        signal
      );

      if (url) {
        await getIsPythonPanelAvailable(url, signal);
      }

      return url;
    },
    {
      ...config,
      enabled:
        !!computeURL &&
        outEnabled &&
        !!options.projectToken &&
        !computeURLLoading &&
        !computeURLFetching,
      onError: async () => {
        if (!hasTriedRestartCompute) {
          setHasTriedRestartCompute(true);
          await restartComputeEngine(queryClient, projectId);
        }
      },
      onSuccess: () => {
        setHasTriedRestartCompute(false);
      }
    }
  );

  return {
    ...query,
    isLoading: query.isLoading || computeURLLoading,
    isFetching: query.isFetching || computeURLFetching,
    isError: query.isError || computeURLIsError,
    isPreviousData: query.isPreviousData || computeURLIsPreviousData,
    error: (query.error || computeURLError) as AxiosError,
    isComputeEngineLoading: computeURLLoading || computeURLFetching,
    onError: async (err: Error) => {
      config?.onError?.(err);

      if (!hasTriedRestartCompute) {
        setHasTriedRestartCompute(true);
        await restartComputeEngine(queryClient, projectId);
      }
    }
  };
};

export default usePythonPanelURL;
