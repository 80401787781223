import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import noop from 'lodash/noop';

import Card from './Card';
import useCurrentUser from '@API/auth/useCurrentUser';
import { MENU_VALUES } from '@API/workspace';
import { bytesToGB } from '@account-settings/utils';
import {
  MANAGEMENT_PERMISSIONS,
  MANAGEMENT_PERMISSIONS_SERVER
} from '@shared/constants/permissions';
import useUserPermission from '@shared/api/useUserPermission';
import { DSColors } from '@design-system-outdated/constants';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import useWorkspaceUsersPermissions from '@shared/api/useWorkspaceUsersPermissions';
import {
  getPermissionValueByType,
  getUserPermissionsByUserName,
  getUserUniqueName,
  ifPermissionValueTruthful
} from '@shared/utils/permissions';

const AMOUNT_OF_SHOWN_MEMBERS = 4;

const checkIsWithCollaborators = membersCount => membersCount >= 1;

const generateTooltipForWorkspaceDeletion = ({ membersCount, isAdmin }) => {
  if (!isAdmin) {
    return 'Workspace can only be deleted by an organization admin';
  }

  if (checkIsWithCollaborators(membersCount)) {
    return 'Workspace can only be deleted if it does not have any members';
  }

  return '';
};

const generateTooltipForSetDefault = ({ isMember, isDefault }) => {
  if (!isMember) {
    return `Only members of a workspace can assign it as default`;
  }

  if (isDefault) {
    return 'Workspace is already marked as Default';
  }

  return '';
};

const generateTooltipForManageUsers = ({ membersCount, canManageUsers }) => {
  if (!checkIsWithCollaborators(membersCount)) {
    return `This workspace doesn’t have any member`;
  }

  if (!canManageUsers) {
    return 'Only owners can ‘Manage users’ (remove and change their role and permissions)';
  }

  return '';
};

const generateMoreMenuOptions = ({
  isDefault,
  membersCount,
  isAdmin,
  isMember,
  canManageUsers
}) => [
  {
    label: 'Make as default',
    value: MENU_VALUES.default,
    disabled: isDefault || !isMember,
    tooltipText: generateTooltipForSetDefault({ isMember, isDefault })
  },
  {
    label: 'Delete workspace',
    value: MENU_VALUES.deleteWorkspace,
    disabled: checkIsWithCollaborators(membersCount) || !isAdmin,
    tooltipText: generateTooltipForWorkspaceDeletion({
      membersCount,
      isAdmin
    })
  },
  {
    label: 'Manage users',
    value: MENU_VALUES.manageUsers,
    disabled: !checkIsWithCollaborators(membersCount) || !canManageUsers,
    tooltipText: generateTooltipForManageUsers({
      membersCount,
      canManageUsers
    })
  }
];

const WorkspaceCard = ({
  workspaceId,
  workspaceName,
  workspaceStorageUsage,
  members,
  onLoadMenuMembers,
  createdBy,
  createdAt,
  onTitleClick,
  isDefault,
  membersCount,
  projectsCount,
  isAdmin,
  onClickExtraMenu,
  onClickInvite,
  showIsMember,
  disabledInviteButton
}) => {
  const { data: currentUser } = useCurrentUser();
  const { getPermissionStatus } = useUserPermission();
  const ifCanRemoveUsersFromWs =
    getPermissionStatus({
      workspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.REMOVE_YOURSELF_FROM_WORKSPACE
    }) ||
    getPermissionStatus({
      workspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.REMOVE_USERS_FROM_WORKSPACE
    });
  const ifCanChangeWsRoles =
    getPermissionStatus({
      workspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE
    }) ||
    getPermissionStatus({
      workspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE_FOR_YOURSELF
    });
  const ifCanInviteUsers =
    getPermissionStatus({
      workspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_FROM_ORGANIZATION
    }) &&
    getPermissionStatus({
      workspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_OUT_OF_ORGANIZATION
    });
  const { data: permissions = [] } = useWorkspaceUsersPermissions(workspaceId, {
    enabled: ifCanInviteUsers || ifCanChangeWsRoles || ifCanRemoveUsersFromWs
  });
  const membersWithAdminFlag = useMemo(
    () =>
      members.map(m => {
        const uniqueName = m.isMember ? getUserUniqueName(m) : m.email;
        return {
          ...m,
          isWsOwner: ifPermissionValueTruthful(
            getPermissionValueByType(
              getUserPermissionsByUserName(permissions, uniqueName),
              MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
            )
          ),
          isAdmin: uniqueName === getUserUniqueName(currentUser)
        };
      }),
    [members, permissions, currentUser]
  );
  const membersPreview = useMemo(
    () => (membersWithAdminFlag || []).slice(0, AMOUNT_OF_SHOWN_MEMBERS),
    [membersWithAdminFlag]
  );

  const {
    isOwner: ifCurrentUserWsOwner,
    isMember: ifCurrentUserWsMember
  } = useMemo(() => {
    let isOwner = false;
    let isMember = false;
    (membersWithAdminFlag || []).forEach(m => {
      if (m.username === currentUser?.username) {
        isMember = true;
        if (m.isWsOwner) {
          isOwner = true;
        }
      }
    });
    return {
      isMember,
      isOwner
    };
  }, [currentUser, membersWithAdminFlag]);
  const menuMembers = useMemo(() => {
    return (membersWithAdminFlag || []).slice(membersPreview.length);
  }, [membersWithAdminFlag, membersPreview]);

  const renderFooter = () => {
    const url = `${window.location.origin}/${workspaceName?.toLowerCase()}`;

    return (
      <div className="card-footer-container">
        <div className="card-footer-fields">
          <div className="card-footer-field">
            <span className="card-footer-field-no-wrap">Created</span>
            <span
              className="card-footer-field-no-wrap"
              data-mask-test="a few seconds ago"
            >
              {moment(createdAt).fromNow()}
            </span>
          </div>
          <div className="card-footer-field">
            <span className="card-footer-field-no-wrap">Projects</span>
            <span className="card-footer-field-no-wrap">{projectsCount}</span>
          </div>
          <div className="card-footer-field">
            <span className="card-footer-field-no-wrap">Storage usage</span>
            <span className="card-footer-field-no-wrap">
              {bytesToGB(workspaceStorageUsage)} GB
            </span>
          </div>
          <div className="card-footer-field">
            <span className="card-footer-field-no-wrap">Created by</span>
            <Tooltip arrow placement="top" title={createdBy}>
              <Typography
                variant="text2"
                noWrap
                className="card-footer-field-no-wrap"
                data-mask-test="username"
              >
                {createdBy}
              </Typography>
            </Tooltip>
          </div>
          <div className="card-footer-field">
            <span className="card-footer-field-no-wrap">URL</span>
            <Tooltip arrow placement="top" title={url}>
              <Typography
                variant="text2"
                noWrap
                className="card-footer-field-no-wrap"
                data-mask-test={`${window.location.origin}/username`}
              >
                {url}
              </Typography>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card
      name={workspaceName}
      nameMaskTest="workspace"
      onTitleClick={onTitleClick}
      membersPreview={membersPreview}
      menuMembers={menuMembers}
      membersCount={membersCount}
      checked={!showIsMember && ifCurrentUserWsMember}
      onLoadMenuMembers={onLoadMenuMembers}
      moreMenuOptions={generateMoreMenuOptions({
        isDefault,
        membersCount,
        isAdmin,
        isMember: ifCurrentUserWsMember,
        canManageUsers: ifCanChangeWsRoles
      })}
      isDefault={isDefault}
      tags={
        ifCurrentUserWsOwner && [
          { label: 'Owner', color: DSColors.orangeColor }
        ]
      }
      cardFooter={renderFooter()}
      onClickMenuOption={onClickExtraMenu}
      onClickInvite={onClickInvite}
      disabledInviteButton={disabledInviteButton}
    />
  );
};

WorkspaceCard.propTypes = {
  members: PropTypes.array,
  workspaceStorageUsage: PropTypes.number,
  workspaceName: PropTypes.string,
  workspaceId: PropTypes.string,
  createdBy: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  isDefault: PropTypes.bool,
  membersCount: PropTypes.number,
  projectsCount: PropTypes.number,
  onClickExtraMenu: PropTypes.func,
  onClickInvite: PropTypes.func,
  onLoadMenuMembers: PropTypes.func,
  showIsMember: PropTypes.bool,
  isAdmin: PropTypes.bool,
  disabledInviteButton: PropTypes.bool
};

WorkspaceCard.defaultProps = {
  members: [],
  workspaceStorageUsage: 0,
  workspaceName: null,
  workspaceId: null,
  createdBy: null,
  createdAt: null,
  isDefault: false,
  membersCount: 0,
  projectsCount: null,
  onClickExtraMenu: noop,
  onClickInvite: noop,
  onLoadMenuMembers: noop,
  showIsMember: false,
  isAdmin: false,
  disabledInviteButton: false
};
export default WorkspaceCard;
