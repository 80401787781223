// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaveViewButton-module__saveViewModalContainer--tWUKd{width:375px;color:var(--gray-6);margin-top:24px}.SaveViewButton-module__saveViewField--YwbRe:not(:last-of-type){margin-bottom:42px}.SaveViewButton-module__saveViewFieldLabelContainer--e8RdJ{display:flex;align-items:center;font-weight:500}.SaveViewButton-module__saveViewFieldLabel--AOVXV{cursor:pointer;margin-left:8px}.SaveViewButton-module__saveNewViewInputs--vOMx8{padding-left:25px}.SaveViewButton-module__saveNewViewInputs--vOMx8 .ds-input{margin-top:18px;margin-bottom:18px}.SaveViewButton-module__setAsDefaultContainer--gJt0Y{display:flex;align-items:center;cursor:pointer}.SaveViewButton-module__setAsDefaultLabel--znJg0{margin-left:12px}button.SaveViewButton-module__saveViewButton--C1yXa{white-space:nowrap}button.SaveViewButton-module__saveViewButton--C1yXa:hover:not(:disabled){background-color:#ffdb8e}button.SaveViewButton-module__saveViewButton--C1yXa:not([disabled]){background-color:#ffe3a6}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveViewModalContainer": `SaveViewButton-module__saveViewModalContainer--tWUKd`,
	"saveViewField": `SaveViewButton-module__saveViewField--YwbRe`,
	"saveViewFieldLabelContainer": `SaveViewButton-module__saveViewFieldLabelContainer--e8RdJ`,
	"saveViewFieldLabel": `SaveViewButton-module__saveViewFieldLabel--AOVXV`,
	"saveNewViewInputs": `SaveViewButton-module__saveNewViewInputs--vOMx8`,
	"setAsDefaultContainer": `SaveViewButton-module__setAsDefaultContainer--gJt0Y`,
	"setAsDefaultLabel": `SaveViewButton-module__setAsDefaultLabel--znJg0`,
	"saveViewButton": `SaveViewButton-module__saveViewButton--C1yXa`
};
module.exports = ___CSS_LOADER_EXPORT___;
