import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PencilIcon, StarCheckedIcon, TrashIcon } from '@Icons-outdated';

import { StyledTooltip } from '@DesignSystem/data-display';

import styles from './TemplateSelect.module.scss';

const ViewListItemActions = ({
  isHovered,
  isEditDisabled,
  templateName,
  setEditNameView,
  template,
  isDeleteViewDisabled,
  openDeleteModal,
  isDefaultView,
  onEditNewName,
  handleSetProjectTemplate
}) => {
  return (
    <div className={styles.actionsContainer}>
      {isHovered && (
        <StyledTooltip
          title={!isEditDisabled ? 'Edit view name' : ''}
          placement="top"
        >
          <div
            className={cx(styles.actionIconContainer, {
              [styles.disabled]: isEditDisabled
            })}
            onClick={e => {
              if (!isEditDisabled) {
                e.stopPropagation();
                onEditNewName(templateName);
                setEditNameView(template);
              }
            }}
          >
            <PencilIcon />
          </div>
        </StyledTooltip>
      )}
      {isHovered && (
        <StyledTooltip
          title={!isDeleteViewDisabled ? 'Delete view' : ''}
          placement="top"
        >
          <div
            className={cx(styles.actionIconContainer, {
              [styles.disabled]: isDeleteViewDisabled
            })}
            onClick={event => {
              if (!isDeleteViewDisabled) {
                event.stopPropagation();
                openDeleteModal(template);
              }
            }}
          >
            <TrashIcon />
          </div>
        </StyledTooltip>
      )}

      {(isHovered || isDefaultView) && (
        <StyledTooltip title="Set as project default view" placement="top">
          <div
            className={cx(styles.actionIconContainer, {
              [styles.activeActionIcon]: isDefaultView
            })}
            onClick={e => {
              e.stopPropagation();
              handleSetProjectTemplate(template);
            }}
          >
            <StarCheckedIcon />
          </div>
        </StyledTooltip>
      )}
    </div>
  );
};

ViewListItemActions.propTypes = {
  isHovered: PropTypes.bool.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  onEditNewName: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  setEditNameView: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
  isDeleteViewDisabled: PropTypes.bool.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  isDefaultView: PropTypes.bool.isRequired,
  handleSetProjectTemplate: PropTypes.func.isRequired
};

export default ViewListItemActions;
