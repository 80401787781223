import moment from 'moment';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { IconButton } from '@ds';
import { StyledTooltip } from '@DesignSystem/data-display';
import useModelRegistryPageBreadcrumbs from '@model-registry/hooks/useModelRegistryPageBreadcrumbs';
import './ModelRegistryHeader.scss';

const ModelRegistryHeader = ({
  model,
  modelName,
  actions,
  tabs,
  nameLabel,
  children
}) => {
  useModelRegistryPageBreadcrumbs();

  return (
    <Box className="model-registry-container" data-test="model-registry-header">
      <Box className="model-registry-wrapper">
        <Box className="card-title-row">
          <Box className="card-model-name">{modelName}</Box>
          {nameLabel ? (
            <Box className="card-model-label">{nameLabel}</Box>
          ) : null}
          {actions.map(
            ({ key, icon, href, onClick, disabled, tooltip }, index) => {
              let btn = (
                <IconButton
                  type="secondary"
                  disabled={disabled}
                  onClick={onClick}
                  Icon={icon}
                />
              );

              if (href) {
                btn = <a href={href}>{btn}</a>;
              }

              return (
                <Box
                  key={key}
                  className={cx('title-icon', {
                    'title-icon-first': index === 0
                  })}
                >
                  {tooltip ? (
                    <StyledTooltip title={tooltip} placement="top" arrow>
                      <div>{btn}</div>
                    </StyledTooltip>
                  ) : (
                    btn
                  )}
                </Box>
              );
            }
          )}
        </Box>
        <Box className="brief-row">
          <Box className="brief-label">Owner</Box>
          <Box className="owner-photo">
            <img src={model.imagePath} />
          </Box>
          <Box>{model.userName}</Box>
          <Box className="brief-label">Created at</Box>
          <Box className="brief">
            {moment(model.createdAt).format('MM/DD/YYYY')}
          </Box>
          <Box className="brief-label">Last updated</Box>
          <Box className="brief">
            {moment(model.updatedAt).format('MM/DD/YYYY')}
          </Box>
          {children}
        </Box>
      </Box>
      {tabs}
    </Box>
  );
};

ModelRegistryHeader.propTypes = {
  model: PropTypes.object.isRequired,
  modelName: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      onClick: PropTypes.func,
      href: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      tooltip: PropTypes.string,
      disabled: PropTypes.bool
    })
  ).isRequired,
  nameLabel: PropTypes.elementType,
  tabs: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};

ModelRegistryHeader.defaultProps = {
  nameLabel: null,
  modelName: ''
};

export default ModelRegistryHeader;
