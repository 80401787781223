import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Checkbox from '@material-ui/core/Checkbox';

import './Checkbox.scss';
import { makeStyles } from '@material-ui/core';

const checkedIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" />
    <path
      d="M4.81657 6.43965L3.5 7.87504L7.03863 11L12.5 5.32595L11.0692 4L6.92706 8.30342L4.81657 6.43965Z"
      fill="white"
    />
  </svg>
);

const icon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="2.5"
      stroke="#E2E2E2"
      fill="inherit"
    />
  </svg>
);

const indeterminateIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" />
    <rect x="4" y="7" width="8" height="2" fill="white" />
  </svg>
);

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      '& svg rect': {
        stroke: 'var(--primary-color) !important'
      }
    },
    '& svg': {
      fill: 'white'
    }
  },
  checked: {
    '& svg': {
      fill: 'var(--primary-color) !important'
    }
  },
  disabled: {
    '& svg': {
      fill: 'var(--gray-1) !important'
    },
    '& svg rect': {
      stroke: 'var(--gray-3) !important'
    },
    '& svg path': {
      fill: 'var(--gray-4) !important'
    }
  }
}));

const CustomCheckbox = ({
  checked,
  onChange,
  indeterminate,
  inputProps,
  isLastToggled,
  disabled,
  'data-test': dataTest
}) => {
  const classes = useStyles();
  return (
    <Checkbox
      data-test={dataTest}
      disableRipple
      classes={classes}
      className={cx('ds-checkbox', { 'last-toggled': isLastToggled })}
      checked={!!checked || indeterminate}
      onChange={onChange}
      checkedIcon={checkedIcon}
      icon={icon}
      indeterminateIcon={indeterminateIcon}
      indeterminate={indeterminate}
      inputProps={inputProps}
      disabled={disabled}
    />
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  inputProps: PropTypes.object,
  isLastToggled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

CustomCheckbox.defaultProps = {
  indeterminate: false,
  inputProps: {},
  isLastToggled: false,
  disabled: false
};

export default CustomCheckbox;
