import React, { ForwardedRef, forwardRef } from 'react';
import { useGroupButtonContext } from './useGroupButtonContext';
import { ButtonProps } from '@design-system/types';
import { buttonTypographyMap, DEFAULT_BUTTON_SIZE } from '../constants';
import cx from 'classnames';

import genericButtonClasses from '../GenericButton.module.scss';
import classes from './GroupButton.module.scss';

type GroupButtonItemProps = {
  value: string;
} & ButtonProps<'button'>;

const GroupButtonItem = (
  {
    value,
    size = DEFAULT_BUTTON_SIZE,
    onClick,
    children,
    ...buttonProps
  }: GroupButtonItemProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { activeItem, changeActiveItem } = useGroupButtonContext();

  const isActive = activeItem === value;

  const onButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    ...args
  ) => {
    changeActiveItem(value);
    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={cx(
        buttonProps.className,
        genericButtonClasses.dsButtonContainer,
        classes.button,
        classes[size],
        buttonTypographyMap[size],
        {
          [classes.active]: isActive
        }
      )}
      onClick={onButtonClick}
    >
      {children}
    </button>
  );
};

const ForwardedGroupButtonItem = forwardRef<
  HTMLButtonElement,
  GroupButtonItemProps
>(GroupButtonItem);

ForwardedGroupButtonItem.displayName = 'GroupButtonItem';

export default ForwardedGroupButtonItem;
