import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

type AddAlertParams = {
  modelId: string;
  name: string;
  runCronExpression: string;
  evaluationWindowHours: number;
  alertSpec: {
    type: string;
    rule: string;
    threshold: number;
  };
  metricSpec: {
    customMetricId?: string;
    type?: string;
    attributeName?: string;
  };
  predicate?: string;
  delayHours: number;
  modelVersion?: string | null;
  refDriftDatasetModelId: string | null;
  totalRangeWindowHours: number;
  totalRangeIntervalType: 'DAILY' | 'HOURLY';
  description?: string;
};

const addAlert = (restParams: AddAlertParams) => {
  return api.post(`/mpm/v2/monitors`, restParams);
};

export function useAddAlertMutation() {
  const history = useHistory();
  const dispatch = useDispatch();

  return useMutation((params: AddAlertParams) => addAlert(params), {
    onSuccess: async () => {
      history.push('./rules');
    },
    onError: (error: Error) => {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.ERROR_ALERT_CREATED,
          error?.message
        )
      );
    }
  });
}
