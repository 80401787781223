import React from 'react';
import { SimpleCard } from '@DesignSystem/surfaces';
import { Avatar } from '@DesignSystem/data-display';
import { useDispatch } from 'react-redux';
import { ArrowRightIcon } from '@Icons-outdated';
import first from 'lodash/first';
import { useOrganizations, useAllUserWorkspaces } from '@shared/hooks';

import organizationsActions from '@/actions/organizationsActions';
import './OrganizationList.scss';
import workspaceActions from '@/actions/workspaceActions';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { selectDefaultWorkspaceByOrganization } from '@/reducers/ui/workspaceUiReducer';

const OrganizationList = () => {
  const { data: organizations } = useOrganizations();
  const { data: workspaces } = useAllUserWorkspaces();
  const dispatch = useDispatch();

  const changeOrganization = organization => () => {
    const defaultWorkspaceName = selectDefaultWorkspaceByOrganization(
      workspaces,
      organization?.id
    )?.name;

    if (defaultWorkspaceName) {
      dispatch(organizationsActions.setActiveOrganization(organization?.id));
      dispatch(workspaceActions.switchTeam(defaultWorkspaceName));
    } else {
      dispatch(
        alertsUtil.openErrorDialog(
          snackbarTypes.ERROR_SWITCH_ORGANIZATION,
          `You are not part of any workspaces in ${organization?.name}, please ask to be invited to one`
        )
      );
    }
  };

  return (
    <div className="organization-list-page">
      <div className="organization-list-container">
        <h4>Select Organization</h4>
        <span className="organization-list-label">
          Your organization represent your company and lets you manage users,
          security settings across multiple workspaces.
        </span>

        <div className="organization-list">
          {organizations?.map(organization => (
            <div
              key={organization?.id}
              className="simple-card-wrapper"
              onClick={changeOrganization(organization)}
            >
              <SimpleCard withShadow hoverable>
                <div className="organization-simple-card-container">
                  <div className="organization-name">
                    <Avatar
                      src={organization?.logoUrl}
                      square
                      width="32px"
                      showLetters
                      lettersLineHeight="25px"
                      letters={first(organization?.name)}
                      backgroundColor="#12A4B4"
                      lettersFontSize="15px"
                    />

                    <span className="organization-name-label">
                      {organization?.name}
                    </span>
                  </div>

                  <div className="go-to-label">
                    Go to
                    <ArrowRightIcon />
                  </div>
                </div>
              </SimpleCard>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

OrganizationList.propTypes = {};

export default OrganizationList;
