import { useMutation, useQueryClient } from 'react-query';
import { useActiveWorkspace } from '@shared/hooks';

import api from '../api';

const votePanel = ({ templateId, voteType }) => {
  return api.post('code-visualizations/template/vote', {
    templateId,
    voteType
  });
};

export default function useVotePanelMutation() {
  const queryClient = useQueryClient();
  const activeWorkspace = useActiveWorkspace();

  return useMutation(
    ({ templateId, voteType }) => votePanel({ templateId, voteType }),
    {
      onMutate: async ({ scope }) => {
        await queryClient.cancelQueries([
          'panelTemplates',
          { teamId: activeWorkspace?.id, scope }
        ]);
      },
      onSuccess: async (_data, { scope }) => {
        await queryClient.invalidateQueries([
          'panelTemplates',
          { teamId: activeWorkspace?.id, scope }
        ]);
      }
    }
  );
}
