import { useQueryClient, useMutation } from 'react-query';

import workspaceApi from '@/util/workspaceApi';
import { useCurrentOrganization } from '@shared/hooks';

const NO_ALTERNATE_WORKSPACE = `can't remove without alternate default workspace`;

const isErrorNoAlternateWorkspace = response =>
  !!response?.data?.msg?.startsWith(NO_ALTERNATE_WORKSPACE);

const handleRemovingResponses = async ({ members, workspaceId }) => {
  let removedAmount = 0;
  const noAlternateWorkaspcesUsers = [];
  const notRemovedUsers = [];
  const messages = [];

  const removingPromises = members.map(member =>
    workspaceApi.removeTeamMember({ teamId: workspaceId, userName: member })
  );

  const removingResponses = await Promise.allSettled(removingPromises);

  for (let i = 0; i < removingResponses.length; i++) {
    const removingResponse = removingResponses[i];

    if (removingResponse.status === 'rejected') {
      if (isErrorNoAlternateWorkspace(removingResponse.reason?.response)) {
        noAlternateWorkaspcesUsers.push(members[i]);
      } else {
        notRemovedUsers.push(members[i]);
      }
    } else {
      removedAmount += 1;
    }

    if (removingResponse.reason?.response?.data?.msg) {
      messages.push(removingResponse.reason?.response?.data?.msg);
    }
  }

  return {
    removedAmount,
    noAlternateWorkaspcesUsers,
    notRemovedUsers,
    messages
  };
};

export const handleRemoveUsersResponseMsg = ({
  removedAmount,
  noAlternateWorkaspcesUsers,
  notRemovedUsers,
  workspaceName
}) => {
  const msg = [];

  if (removedAmount) {
    msg.push(
      `You have successfully removed ${removedAmount} ${
        removedAmount === 1 ? 'user' : 'users'
      } from ${workspaceName} workspace.`
    );
  }

  if (noAlternateWorkaspcesUsers?.length) {
    const len = noAlternateWorkaspcesUsers?.length;
    msg.push(
      `You cannot remove ${
        len === 1 ? 'user' : 'users'
      } ${noAlternateWorkaspcesUsers.join(
        ', '
      )} from workspace ${workspaceName} since ${
        len === 1 ? 'he' : 'they'
      } is only a member of a single workspace.`
    );
  }

  if (notRemovedUsers?.length) {
    msg.push(
      `${
        notRemovedUsers?.length === 1 ? 'User' : 'Users'
      } ${notRemovedUsers.join(', ')} can't be removed. `
    );
  }

  return msg;
};

const removeWorkspaceMembers = ({ members, workspaceId }) =>
  handleRemovingResponses({
    members,
    workspaceId
  });

const useRemoveMembersMutation = () => {
  const queryClient = useQueryClient();
  const currentOrganization = useCurrentOrganization();

  return useMutation(removeWorkspaceMembers, {
    onSuccess: async (res, { workspaceId }) => {
      queryClient.resetQueries(['workspace', workspaceId, 'members']);
      queryClient.invalidateQueries([
        'organizations',
        currentOrganization?.id,
        'current-user',
        'workspaces'
      ]);
      queryClient.invalidateQueries(['workspaces', 'all']);

      if (currentOrganization?.id) {
        queryClient.invalidateQueries(
          ['organizations', currentOrganization?.id],
          {
            active: true
          }
        );
        queryClient.resetQueries(['organizations', currentOrganization?.id], {
          inactive: true
        });
      }
    }
  });
};

export default useRemoveMembersMutation;
