import { NUM_OF_PERCENT_CHANGE_DECIMALS } from '@experiment-management-shared/constants/compareTableConstants';
import get from 'lodash/get';
import toString from 'lodash/toString';
import {
  AllowedCompareKeys,
  ComparisonTableProps,
  ComparisonTableRow,
  GroupedParamsMap
} from './types';

export const getParamsEqualityMap = (
  groupListMap: GroupedParamsMap,
  compareKeys: AllowedCompareKeys[]
) => {
  const equalityMap = new Map<string, boolean>();

  for (const [rootKey, rowData] of groupListMap) {
    equalityMap.set(rootKey, true);
    for (const key of compareKeys) {
      const [firstItem] = rowData;
      const isNotEqual = rowData.some(
        groupItem => groupItem[key] !== firstItem[key]
      );

      if (isNotEqual) {
        equalityMap.set(rootKey, false);
        break;
      }
    }
  }

  return equalityMap;
};

function valueToNumber(value: string | number | boolean | undefined): number {
  if (typeof value === 'boolean' || typeof value === 'undefined') {
    return 0;
  }

  if (typeof value === 'string') {
    const convertedNumber = parseFloat(value);
    if (!isNaN(convertedNumber)) {
      return convertedNumber;
    }
  }

  if (typeof value === 'number') {
    return value;
  }

  return 0;
}

export const calcPercentageChange = (
  a: string | number | boolean | undefined,
  b: string | number | boolean | undefined
) => {
  const numberA = valueToNumber(a);
  const numberB = valueToNumber(b);

  if (numberA === 0 || numberB === 0) {
    return '';
  }

  const diff = ((numberB - numberA) / Math.abs(numberA)) * 100;

  const numOfZerosAfterDecimal =
    get(toString(diff).match(/(\.0*)/), ['0', 'length'], 1) - 1;

  const decimalsToShow = Math.max(
    NUM_OF_PERCENT_CHANGE_DECIMALS,
    numOfZerosAfterDecimal + 1
  );

  return diff.toFixed(decimalsToShow);
};

export const isValidDiffValue = (value: string) => {
  const number = parseFloat(value);
  return !isNaN(number) && isFinite(number) && number !== 0;
};

export const getCellClassNameComparisonTable = (
  props: ComparisonTableProps
) => {
  const isMain = props.row.isMain;
  const isKey = props.column.name === 'key';

  if (!isKey) return '';

  if (isMain) {
    return 'dx-table-cell-row-owner';
  }

  return 'dx-table-cell-row-child';
};

export const getRowClassNameComparisonTable = (row: ComparisonTableRow) => {
  return {
    'table-row-view-not-equal': !row.isEqual
  };
};

export const getCellRowspanComparisonTable = (rowspan: number) => (
  props: ComparisonTableProps
) => {
  const isMain = props.row.isMain;
  const isKey = props.column.name === 'key';

  if (isKey && isMain) return rowspan;

  return 1;
};

export const getExperimentValueName = (
  valueKey: AllowedCompareKeys,
  experimentName: string
) => `${valueKey}_${experimentName}`;
