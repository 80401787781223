import React from 'react';
import keymirror from 'keymirror';
import { sum, mean, median, mode, std, sqrt, min, max, square } from 'mathjs';
import first from 'lodash/first';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

import {
  LineIcon,
  AreaIcon,
  AreaStackedIcon,
  AreaNormalizedIcon
} from '@Icons-outdated';
import { DEFAULT_DASHBOARD_SAMPLE_SIZE } from '@/constants/configConstants';

import { WALL, STEP, DURATION, EPOCH } from './experimentConstants';
import { LEGEND_MODE } from '@experiment-management-shared/constants/panels';

export const DEFAULT_CHART_TEMPLATE_NAME = 'Auto Generated';
export const UNTITLED_CHART_TEMPLATE_NAME = 'untitled';
export const EMPTY_CHART_TEMPLATE_NAME = 'Empty view';
export const MAX_LEGEND_LENGTH_EXPORT = 40;
export const MAX_LEGEND_LENGTH = 80;
export const COMET_TIME_SERIES_AXES_MAP = {
  [DURATION]: DURATION,
  [EPOCH]: EPOCH,
  [STEP]: STEP,
  [WALL]: WALL
};
import defaultPyTemplate from '@experiment-management-shared/constants/customPanelTemplates/defaultPyTemplate';
import {
  DSBarVerticalIcon,
  DSBoxPlotVerticalIcon,
  DSStackedBarVerticalIcon,
  DSViolinPlotVerticalIcon
} from '@design-system/icons';

export const DASHBOARD_PREVIEW_CHART_ID = 'DASHBOARD_PREVIEW_CHART';

export const CHART_CATEGORIES = {
  BUILT_IN: 'built-in'
};

export const chartDataErrorTypes = keymirror({
  FETCHING_ERROR: null,
  EMPTY_DATA: null,
  TEMPLATE_NOT_FOUND: null,
  NO_PERMISSION_TO_SEE_PANEL: null,
  IS_NOT_AVAILABLE: null,
  PYODIDE_PY_ERROR: null,
  PY_SERVER_ERROR: null,
  PY_BUILT_IN_PANEL_NOT_AVAILABLE_NOT_LOGGED_IN: null
});

export const HOVER_MODE_OPTIONS = [
  { value: 'closest', label: 'Closest' },
  { value: 'x unified', label: 'X Axis' }
];

export const X_AXIS_OPTIONS_MAP = {
  [WALL]: 'Wall Time',
  [STEP]: 'Step',
  [DURATION]: 'Duration',
  [EPOCH]: 'Epoch'
};

export const X_AXIS_OPTIONS = [
  { value: WALL, label: X_AXIS_OPTIONS_MAP[WALL] },
  { value: STEP, label: X_AXIS_OPTIONS_MAP[STEP] },
  { value: DURATION, label: X_AXIS_OPTIONS_MAP[DURATION] },
  { value: EPOCH, label: X_AXIS_OPTIONS_MAP[EPOCH] }
];

export const SAMPLE_SIZES = sortBy(
  uniq([Number(DEFAULT_DASHBOARD_SAMPLE_SIZE), 50, 100, 500, 1000])
).map(value => ({
  isDefault: DEFAULT_DASHBOARD_SAMPLE_SIZE === value,
  value
}));
export const ACTIVE_FETCH_INTERVAL = 10000;

export const CUSTOM_CHART_TYPE = 'custom';

export const BUILT_IN_CHART_TYPES = {
  'BuiltIn/Line': 'line',
  'BuiltIn/Scatter': 'scatter',
  'BuiltIn/Bar': 'bar',
  'BuiltIn/ParallelCoordinates': 'parallel',
  scalar: 'scalar',
  curves: 'curves',
  image: 'image',
  video: 'video',
  pcd: 'pcd',
  data: 'data',
  python: 'python'
};

export const EMPTY_METRIC_DATA_OBJECT = {
  metricName: '',
  values: [],
  timestamps: [],
  durations: []
};

export const TRANSFORM_TYPES_OBJECT = {
  LOG_SCALE: 'log scale',
  LOG_VALUE: 'log(value)',
  LOG_BASE_10: 'log(10)',
  MOVING_AVERAGE: 'moving average'
};

export const TRANSFORM_TYPES_ARRAY = Object.values(TRANSFORM_TYPES_OBJECT);

export const AGGREGATION_TYPES_OBJECT = {
  COUNT: 'count',
  SUM: 'sum',
  AVG: 'avg',
  MEDIAN: 'median',
  MODE: 'mode',
  RMS: 'rms',
  STDDEV: 'stddev',
  MIN: 'min',
  MAX: 'max',
  FIRST: 'first',
  LAST: 'last'
};

export const NON_NUMERIC_AGGREGATIONS = [
  AGGREGATION_TYPES_OBJECT.FIRST,
  AGGREGATION_TYPES_OBJECT.LAST,
  AGGREGATION_TYPES_OBJECT.COUNT
];

export const AGGREGATION_TYPES_ARRAY = Object.values(AGGREGATION_TYPES_OBJECT);

export const AGGREGATIONS = {
  count: arr => arr.length,
  sum,
  avg: mean,
  mean,
  median,
  mode,
  rms: arr => sqrt(mean(arr.map(val => square(val)))),
  stddev: std,
  min,
  max,
  first,
  last
};

export const CONFUSION_MATRIX_COLOR_DISTRIBUTIONS = {
  EQUAL: 'Equal',
  SMART: 'Smart'
};

export const CONFUSION_MATRIX_CELL_VALUES = {
  COUNTS: 'Counts',
  PERCENT_BY_ROW: 'Percent by row',
  PERCENT_BY_COLUMN: 'Percent by column'
};

export const CONFUSION_MATRIX_TYPES = {
  IMAGE: 'image',
  INTEGER: 'integer',
  LINK: 'link',
  STRING: 'string'
};

export const EXPERIMENT_VIEW_TAB_FIELDS = {
  AUDIO: 'audio',
  CONFUSION_MATRIX: 'confusionMatrix',
  HISTOGRAM: 'histogram',
  IMAGES: 'images',
  METRICS: 'metrics',
  PANELS: 'panels',
  TEXT: 'text',
  HYPERPARAMS: 'hyperParams',
  OTHER: 'otherParams'
};

export const BADGE_COLORS = {
  blue: 'blue',
  gray: 'gray',
  warning: 'warning'
};

export const BAR_CHART_TRACE_TYPE_BOX = 'box';
export const BAR_CHART_TRACE_TYPE_VIOLIN = 'violin';

export const LINE_CHART_LINE_WIDTH = 1.5;

export const CHART_AXIS_STYLING = {
  gridcolor: '#eeeeee',
  gridwidth: 1,
  zerolinecolor: '#eeeeee',
  zerolinewidth: 1,
  tickfont: {
    color: '#5f677e',
    size: 12,
    family: 'Roboto, sans-serif'
  },
  automargin: true
};

export const CHART_CONFIG_MODIFIERS = {
  LINE: {
    label: 'Line',
    value: 'LINE',
    config: {},
    prefixIcon: <LineIcon />
  },
  AREA: {
    label: 'Area',
    value: 'AREA',
    config: {
      fill: 'tozeroy'
    },
    prefixIcon: <AreaIcon />
  },
  STACKED: {
    label: 'Stacked',
    value: 'STACKED',
    config: {
      stackgroup: 'one'
    },
    prefixIcon: <AreaStackedIcon />
  },
  NORMALIZED_STACKED: {
    label: 'Normalized Stacked',
    value: 'NORMALIZED_STACKED',
    config: {
      stackgroup: 'one',
      groupnorm: 'percent'
    },
    prefixIcon: <AreaNormalizedIcon />
  },
  BAR: {
    label: 'Bar',
    value: 'BAR',
    prefixIcon: <DSBarVerticalIcon />,
    config: {
      type: 'bar',
      hoverinfo: 'name',
      hoverlabel: { namelength: -1 }
    }
  },
  STACKED_BAR: {
    label: 'Stacked Bar',
    value: 'STACKED_BAR',
    prefixIcon: <DSStackedBarVerticalIcon />,
    config: {
      type: 'bar',
      hoverinfo: 'name',
      hoverlabel: { namelength: -1 }
    }
  },
  BOX_PLOT: {
    label: 'Box Plot',
    value: 'BOX_PLOT',
    prefixIcon: <DSBoxPlotVerticalIcon />,
    config: {
      type: BAR_CHART_TRACE_TYPE_BOX,
      boxpoints: false
    }
  },
  VIOLIN_PLOT: {
    label: 'Violin Plot',
    value: 'VIOLIN_PLOT',
    prefixIcon: <DSViolinPlotVerticalIcon />,
    config: {
      type: BAR_CHART_TRACE_TYPE_VIOLIN,
      points: 'none',
      box: {
        visible: true
      },
      boxpoints: false,
      line: {
        color: 'black'
      },
      opacity: 0.6,
      meanline: {
        visible: true
      }
    }
  }
};

export const DISABLED_BAR_CHART_AGGREGATION = [
  CHART_CONFIG_MODIFIERS.VIOLIN_PLOT.value,
  CHART_CONFIG_MODIFIERS.BOX_PLOT.value
];

export const CHART_ANNOTATIONS = {
  MIN_Y: { name: 'MIN_Y', label: 'Min y-values', checked: false },
  MAX_Y: { name: 'MAX_Y', label: 'Max y-values', checked: false },
  AVG_Y: { name: 'AVG_Y', label: 'Avg y-values', checked: false }
};

export const CHART_GROUPING_AGGREGATIONS = {
  mean: { label: 'Mean', value: 'mean' },
  median: { label: 'Median', value: 'median' },
  min: { label: 'Min', value: 'min' },
  max: { label: 'Max', value: 'max' }
};

export const CHART_GROUPING_RANGE = {
  none: { label: 'None', value: 'none' },
  minMax: { label: 'Min/Max', value: 'minMax' },
  stddev: { label: 'Std Dev', value: 'stddev' }
};

export const TRACE_NAME_MAP = {
  minMax: {
    lowerBound: CHART_GROUPING_AGGREGATIONS.min.label,
    upperBound: CHART_GROUPING_AGGREGATIONS.max.label
  },
  stddev: {
    lowerBound: 'Lower std dev',
    upperBound: 'Upper std dev'
  }
};

export const DEFAULT_GROUPING_STATE = {
  LINE: {
    enabled: false,
    aggregation: CHART_GROUPING_AGGREGATIONS.mean.value,
    range: CHART_GROUPING_RANGE.none.value,
    groupByParameter: null
  },
  BAR: {
    enabled: false,
    plotType: CHART_CONFIG_MODIFIERS.BAR.value,
    aggregation: CHART_GROUPING_AGGREGATIONS.mean.value,
    groupByParameter: null
  }
};

export const LINE_CHART_X_AGGREGATIONS = {
  ALL: { label: 'All', value: 'all' },
  FIRST: { label: 'First', value: 'first' },
  LAST: { label: 'Last', value: 'last' },
  MIN: { label: 'Min', value: 'min' },
  MAX: { label: 'Max', value: 'max' }
};

export const LINE_TYPE_STRATEGY = {
  // calculate line type according to existing logic
  AUTO: 'auto',
  // select first from possible options, now in strain line
  FIRST: 'first'
};

export const BATCH_CHART_CONFIG = {
  COLUMNS: 6,
  MIN_COLUMN_WIDTH: 2,
  ROW_HEIGHT: 302,
  MAX_COLUMN_HEIGHT: 3
};

export const GRAPHIC_DEFAULT_STEP = 0;
export const IMAGE_PANEL_DEFAULT_STEP = GRAPHIC_DEFAULT_STEP;
export const VIDEO_PANEL_DEFAULT_STEP = GRAPHIC_DEFAULT_STEP;
export const PCD_PANEL_DEFAULT_STEP = GRAPHIC_DEFAULT_STEP;

export const DATA_PANEL_CONCAT_OPTIONS = {
  rows: 0,
  columns: 1
};

export const DATA_PANEL_DEFAULT_CONCATONATE_VALUE =
  DATA_PANEL_CONCAT_OPTIONS.rows;

export const DATA_PANEL_MAX_EXPERIMENTS = 25;

export const DATA_PANEL_FILE_SIZE_LIMIT = 10000000; // 10MB

export const DATA_PANEL_DEFAULT_JOIN_VALUE = 'outer';

export const UNSET_CUSTOM_RANGE = { min: '', max: '' };

export const DEFAULT_CUSTOM_RANGE = {
  x: UNSET_CUSTOM_RANGE,
  y: UNSET_CUSTOM_RANGE
};

export const DEFAULT_LEGEND_KEYS = [
  { value: 'Name', label: 'Name', source: 'log_other' }
];

export const OUTLIERS_VALUES = {
  SHOW: 'show',
  NOT_VISIBLE: 'ignore'
};

export const defaultFormFields = {
  [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: {
    chartName: '',
    selectedXAxis: STEP,
    selectedYAxis: [],
    selectedOutliers: OUTLIERS_VALUES.SHOW,
    selectedLegendKeys: DEFAULT_LEGEND_KEYS,
    transformX: null,
    transformY: null,
    smoothingX: 0,
    smoothingY: 0,
    sampleSize: DEFAULT_DASHBOARD_SAMPLE_SIZE,
    metricName: '',
    metricNames: [],
    fillType: CHART_CONFIG_MODIFIERS.LINE.value,
    grouping: DEFAULT_GROUPING_STATE.LINE,
    aggregationX: AGGREGATION_TYPES_OBJECT.LAST,
    customRange: DEFAULT_CUSTOM_RANGE,
    hoverMode: 'closest',
    experimentsCount: 25,
    legendMode: LEGEND_MODE.AUTO,
    locked: false,
    customXAxisTitle: '',
    showXAxisTitle: false,
    showXAxisTickLabels: true,
    customYAxisTitle: '',
    showYAxisTitle: false,
    showYAxisTickLabels: true
  },
  [BUILT_IN_CHART_TYPES['BuiltIn/Scatter']]: {
    chartName: '',
    selectedXAxis: null,
    selectedYAxis: null,
    selectedZAxis: null,
    selectedOutliers: OUTLIERS_VALUES.SHOW,
    sampleSize: DEFAULT_DASHBOARD_SAMPLE_SIZE,
    selectedLegendKeys: DEFAULT_LEGEND_KEYS,
    aggregationX: AGGREGATION_TYPES_OBJECT.LAST,
    aggregationY: AGGREGATION_TYPES_OBJECT.LAST,
    aggregationZ: AGGREGATION_TYPES_OBJECT.LAST,
    metrics: [],
    params: [],
    metricName: '',
    metricNames: [],
    annotations: CHART_ANNOTATIONS,
    customRange: DEFAULT_CUSTOM_RANGE,
    legendMode: LEGEND_MODE.AUTO,
    locked: false,
    customXAxisTitle: '',
    showXAxisTitle: false,
    showXAxisTickLabels: true,
    customYAxisTitle: '',
    showYAxisTitle: false,
    showYAxisTickLabels: true
  },
  [BUILT_IN_CHART_TYPES['BuiltIn/Bar']]: {
    chartName: '',
    selectedXAxis: null,
    selectedLegendKeys: DEFAULT_LEGEND_KEYS,
    metricName: '',
    metrics: null,
    aggregationX: AGGREGATION_TYPES_OBJECT.LAST,
    sampleSize: DEFAULT_DASHBOARD_SAMPLE_SIZE,
    orientation: 'v',
    grouping: DEFAULT_GROUPING_STATE.BAR,
    plotType: CHART_CONFIG_MODIFIERS.BAR.value,
    customRange: DEFAULT_CUSTOM_RANGE,
    legendMode: LEGEND_MODE.AUTO,
    locked: false,
    customXAxisTitle: '',
    showXAxisTitle: false,
    customYAxisTitle: '',
    showYAxisTitle: false
  },
  [BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']]: {
    chartName: '',
    metrics: [],
    params: [],
    selectedTargetVariable: '',
    selectedDecimalPrecision: '.3f'
  },
  custom: {
    instanceId: ''
  },
  [BUILT_IN_CHART_TYPES.scalar]: {
    chartName: '',
    metricName: '',
    paramName: '',
    aggregation: AGGREGATION_TYPES_ARRAY[0],
    precision: 3,
    description: '',
    isCustomDescription: false,
    isNumber: true
  },
  [BUILT_IN_CHART_TYPES.image]: {
    chartName: '',
    step: IMAGE_PANEL_DEFAULT_STEP,
    filteredAnnotations: [],
    confidenceScore: null,
    images: [],
    experimentsCount: 5
  },
  [BUILT_IN_CHART_TYPES.video]: {
    chartName: '',
    step: VIDEO_PANEL_DEFAULT_STEP,
    videos: [],
    experimentsCount: 5
  },
  [BUILT_IN_CHART_TYPES.pcd]: {
    assetNames: [],
    chartName: '',
    experimentsCount: 5,
    step: PCD_PANEL_DEFAULT_STEP
  },
  [BUILT_IN_CHART_TYPES.curves]: {
    chartName: '',
    assetNames: [],
    step: undefined,
    selectedLegendKeys: DEFAULT_LEGEND_KEYS,
    fillType: CHART_CONFIG_MODIFIERS.LINE.value,
    customRange: DEFAULT_CUSTOM_RANGE,
    hoverMode: 'closest',
    experimentsCount: 25,
    legendMode: LEGEND_MODE.AUTO,
    customXAxisTitle: '',
    showXAxisTitle: false,
    showXAxisTickLabels: true,
    customYAxisTitle: '',
    showYAxisTitle: false,
    showYAxisTickLabels: true
  },
  [BUILT_IN_CHART_TYPES.data]: {
    chartName: '',
    fileName: null,
    index: null,
    hasData: true,
    experimentsCount: 5,
    axis: DATA_PANEL_DEFAULT_CONCATONATE_VALUE,
    joinType: DATA_PANEL_DEFAULT_JOIN_VALUE,
    columnFilters: null,
    columnState: null
  },
  [BUILT_IN_CHART_TYPES.python]: {
    chartName: '',
    revisionId: '',
    templateId: '',

    // needed for editing
    codeVersion: '',
    code: defaultPyTemplate.pyCode
  }
};

export const TOOLTIP_MAX_DIGITS_AFTER_PERIOD = 5;

export const TOOLTIP_WAIT_TIMEOUT_FOR_RESET = 20;

export const TOOLTIP_MAX_EXPERIMENT_NAME_WIDTH = 60;

export const PANEL_WITH_SAMPLE_SIZES = [
  BUILT_IN_CHART_TYPES['BuiltIn/Line'],
  BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
  BUILT_IN_CHART_TYPES['BuiltIn/Bar']
];

export const PANEL_WITH_LEGEND_MODE = [
  BUILT_IN_CHART_TYPES['BuiltIn/Line'],
  BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
  BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
  BUILT_IN_CHART_TYPES.curves
];

export const EXPERIMENT_PANELS_GLOBAL_CONFIG_MAP = {
  [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: [
    'selectedXAxis',
    'transformY',
    'smoothingY',
    'selectedOutliers'
  ]
};

export const DASHBOARD_PANELS_GLOBAL_CONFIG_MAP = {
  [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: [
    'selectedXAxis',
    'transformY',
    'smoothingY',
    'sampleSize',
    'selectedOutliers'
  ],
  [BUILT_IN_CHART_TYPES['BuiltIn/Scatter']]: ['sampleSize'],
  [BUILT_IN_CHART_TYPES['BuiltIn/Bar']]: ['sampleSize']
};
export const PYTHON_PANEL_REFETCH_INTERVAL = 1 * 60 * 1000;
// rerun python server engine 1 minute to let it live
export const COMPUTE_ENGINE_KEEP_ALIVE_INTERVAL = 1 * 60 * 1000;

export const PROJECT_TOKEN_REFRESH_INTERVAL = 230 * 60 * 1000; // 230 minutes (BE = 240 minutes)
export const PYTHON_PANEL_RETRY_COUNT = 3;
export const PYTHON_PANEL_RETRY_DELAY = 1000;
