import { cancelWrapper } from '@API/api';
import { useQuery } from 'react-query';
import workspaceApi from '../../util/workspaceApi';

const mapMembers = members => {
  return members.map(member => ({
    username: member.userName,
    email: member.email,
    firstName: member.firstName,
    lastName: member.lastName,
    lastActivityAt: member.lastActivityAt,
    avatar: member.avatar,
    joinedAt: member.joinedAt,
    isMember: true
  }));
};

const getWorkspaceMembers = workspaceId => {
  return cancelWrapper(async cancelToken => {
    const res = await workspaceApi.fetchWorkspaceMembers(
      workspaceId,
      cancelToken
    );
    return mapMembers(res?.data || []);
  });
};

const useWorkspaceMembers = (workspaceId, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['workspace', workspaceId, 'members'],
    () => getWorkspaceMembers(workspaceId),
    { ...config, enabled: !!workspaceId && configEnabled }
  );
};

export default useWorkspaceMembers;
