import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useExperimentsSystemMetrics } from '@experiment-details/api';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import SmallLoader from '@shared/components/SmallLoader';
import { ExperimentDetails } from '@shared/types';
import useSystemMetricsMeta from './hooks/useSystemMetricsMeta';
import useSystemMetricBI from './hooks/useSystemMetricBI';
import SystemMetricsSingleTable from './SystemMetricsSingleTable/SystemMetricsSingleTable';
import SystemMetricChartsWrapper from './SystemMetricsCharts/SystemMetricChartsWrapper';
import SystemMetricsComparisonTable from './SystemMetricsComparisonTable/SystemMetricsComparisonTable';
import SystemMetricsEmptyMessage from './SystemMetricsEmptyMessage';
import cx from 'classnames';
import styles from './SystemMetricsTab.module.scss';

type SystemMetricsTabProps = {
  experiments: ExperimentDetails[];
};
const SystemMetricsTab: React.FC<SystemMetricsTabProps> = ({ experiments }) => {
  const isComparePage = useSelector(selectIsComparePage);
  const { data: systemMetrics, isLoading } = useExperimentsSystemMetrics({
    experiments
  });

  const { totalRam, maxTotalMemory, systemMetricNames } = useSystemMetricsMeta(
    systemMetrics
  );

  useSystemMetricBI({
    isLoading,
    systemMetricNames
  });

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  const isEmptyTab =
    !systemMetrics.length ||
    systemMetrics?.every(systemMetric =>
      Object.values(systemMetric)?.every(isEmpty)
    );

  if (isEmptyTab) {
    return <SystemMetricsEmptyMessage isComparePage={isComparePage} />;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <SystemMetricChartsWrapper
            experiments={experiments}
            metricNames={systemMetricNames}
            totalRam={totalRam}
            maxTotalMemory={maxTotalMemory}
            isComparePage={isComparePage}
          />
        </Grid>
      </Grid>

      <div
        className={cx(styles.systemMetricTable, {
          [styles.systemMetricComparePage]: isComparePage
        })}
      >
        {isComparePage ? (
          <SystemMetricsComparisonTable
            systemMetrics={systemMetrics}
            experiments={experiments}
            isLoading={isLoading}
          />
        ) : (
          <SystemMetricsSingleTable
            systemMetrics={systemMetrics[0]}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export default SystemMetricsTab;
