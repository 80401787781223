import React from 'react';
import cx from 'classnames';
import { Select } from '@ds';
import { OptionType } from '@design-system/types';
import { SingleValue } from 'react-select';
import { ArrowDownward } from '@Icons-outdated';

import styles from './ExperimentBar.module.scss';

type ExperimentBarSortSelectProps = {
  label: string;
  options: OptionType[];
  changeHandler: (newValue: SingleValue<OptionType>) => void;
  orderChangeHandler: () => void;
  orderDirection: 'desc' | 'asc';
  value: string;
  disabled?: boolean;
};

const ExperimentBarSortSelect = ({
  changeHandler,
  orderChangeHandler,
  orderDirection,
  label,
  options,
  value,
  disabled
}: ExperimentBarSortSelectProps) => {
  return (
    <div className={cx(styles.component, styles.select, styles.sort)}>
      <div className={styles.title}>{label}</div>
      <div className={styles.componentWrapper}>
        <Select
          value={value}
          onValueChange={(_, v) => changeHandler(v)}
          options={options}
          isClearable={false}
          isDisabled={disabled}
        />
        <button
          className={cx(styles.button, {
            [styles.asc]: orderDirection === 'asc',
            [styles.disabled]: disabled
          })}
          onClick={orderChangeHandler}
          disabled={disabled}
        >
          <ArrowDownward />
        </button>
      </div>
    </div>
  );
};

export default ExperimentBarSortSelect;
