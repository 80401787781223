import { useMemo, useEffect } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import first from 'lodash/first';
import noop from 'lodash/noop';

import useColumns from '@API/project/useColumns';
import useExperimentGroups from '@API/experiments/useExperimentGroups';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import { castValue } from '@API/experiments/utils';
import { normalizeColumnName } from '@API/helpers/v2_helpers';
import {
  getGroupPossibleValuesFromGroupsMap,
  groupsMapToPossibleExpandedGroups
} from '@experiment-management-shared/utils';
import { COMPOUND_KEY_SEPARATOR } from '@experiment-management-shared/constants/aggregation';
import useExpandedExperimentsGroupsInvalidator from './useExpandedExperimentsGroupsInvalidator';
import {
  DashboardView,
  ExperimentGroupsMap,
  ExtendedGroupColumn,
  GroupsPreQueryData
} from '@experiment-management-shared/types';

// eslint-disable-next-line import/no-unresolved
import { QueryObserverOptions } from 'react-query/types/core/types';
import { UseQueryResult } from 'react-query';

export const ROOT_GROUP_VALUE_PATH = 'root_group_value_path';

type UseExperimentsGroupsParams = {
  columnGrouping: string[];
  expandedGroups: string[];
  chainsQueryRules?: unknown[];
  onExpand: (expandedGroups: string[]) => void;
  view: DashboardView;
  isArchive: boolean;
  queryConfig?: QueryObserverOptions;
};

type GroupQueryData = {
  fullGroupName: string;
  valuePath: string;
  groupsQuery: GroupsPreQueryData[];
};

type DataForRequest = {
  groupsQueryData: GroupQueryData[];
  experimentsQueryData: GroupsPreQueryData[];
};

type ExperimentGroupsResponse = {
  experimentGroups: { value: string | number | boolean }[];
  pageTotal: number;
};

export const prepareDataForRequest = (
  extendedGroupingColumns: ExtendedGroupColumn[],
  parsedExpandedGroups: string[][]
): DataForRequest => {
  const retVal: DataForRequest = {
    groupsQueryData: [],
    experimentsQueryData: []
  };

  if (isArray(parsedExpandedGroups) && extendedGroupingColumns.length > 0) {
    const { fullName } = first(extendedGroupingColumns) as ExtendedGroupColumn;
    retVal.groupsQueryData.push({
      fullGroupName: fullName,
      valuePath: ROOT_GROUP_VALUE_PATH,
      groupsQuery: []
    });

    parsedExpandedGroups.forEach(parsedExpandedGroup => {
      // found first group that is lowLevel group
      if (
        parsedExpandedGroup.length === extendedGroupingColumns.length &&
        retVal.experimentsQueryData.length === 0
      ) {
        parsedExpandedGroup.forEach((value, index) => {
          const extendedColumn = extendedGroupingColumns[index];

          retVal.experimentsQueryData.push({
            ...extendedColumn,
            value: castValue(value, extendedColumn.type)
          });
        });
      }

      if (parsedExpandedGroup.length < extendedGroupingColumns.length) {
        const groupsWithValues = parsedExpandedGroup.map((value, index) => {
          const extendedColumn = extendedGroupingColumns[index];

          return {
            ...extendedColumn,
            value: castValue(value, extendedColumn.type)
          };
        });

        retVal.groupsQueryData.push({
          fullGroupName:
            extendedGroupingColumns[groupsWithValues.length].fullName,
          valuePath: parsedExpandedGroup.join(COMPOUND_KEY_SEPARATOR),
          groupsQuery: groupsWithValues
        });
      }
    });
  }

  return retVal;
};

const useExperimentsGroups = (params: UseExperimentsGroupsParams) => {
  const { onExpand } = params;

  const {
    isAutoGroupExpandEnabled,
    disableAutoGroupExpand,
    invalidateExpandedGroups
  } = useExpandedExperimentsGroupsInvalidator();

  const { data: columns } = useColumns({
    extraCols: true
  });

  const expandedGroups: string[] = get(params.view, 'table.expandedGroups', []);
  const tableSearch: string = get(params.view, 'table.search', '');

  const parsedExpandedGroups: string[][] = useDeepMemo(() => {
    return expandedGroups.map(expandedGroup =>
      expandedGroup.split(COMPOUND_KEY_SEPARATOR)
    );
  }, [expandedGroups]);

  const extendedGroupingColumns = useMemo(() => {
    if (isArray(params.columnGrouping) && isArray(columns)) {
      return params.columnGrouping.map(fullName => {
        const { keyName: columnName, kind } = normalizeColumnName(fullName);
        const column = columns.find(col => col.name === columnName);
        return {
          fullName,
          columnName,
          kind,
          type: column?.type
        };
      });
    }

    return [];
  }, [params.columnGrouping, columns]);

  const maxGroupingLevel = extendedGroupingColumns.length;
  const isGroupingEnabled = maxGroupingLevel > 0;

  // in case expanded groups are invalid we need to trigger auto open functionality
  const { groupsQueryData, experimentsQueryData } = useMemo(() => {
    return prepareDataForRequest(extendedGroupingColumns, parsedExpandedGroups);
  }, [extendedGroupingColumns, parsedExpandedGroups]);

  const experimentGroupsData: UseQueryResult<
    ExperimentGroupsResponse,
    unknown
  >[] = useExperimentGroups(
    {
      groupsQueryData,
      chainsQueryRules: params.chainsQueryRules,
      isArchive: params.isArchive,
      view: params.view
    },
    params.queryConfig
  ) as UseQueryResult<ExperimentGroupsResponse, unknown>[];

  const isFetchingExperimentGroups = experimentGroupsData.some(
    ({ isFetching }) => isFetching
  );

  const isLoadingExperimentGroups = experimentGroupsData.some(
    ({ isLoading }) => isLoading
  );

  const experimentsGroupsMap = useDeepMemo(() => {
    const groupsMap: ExperimentGroupsMap = {
      path: experimentsQueryData,
      order: [],
      children: {}
    };

    groupsQueryData.forEach(({ valuePath }, index) => {
      const values = (experimentGroupsData[index]?.data?.experimentGroups.map(
        ({ value }) => value
      ) || []) as string[];

      if (valuePath === ROOT_GROUP_VALUE_PATH) {
        groupsMap.order = values;
      } else {
        const groupsValues = valuePath.split(COMPOUND_KEY_SEPARATOR);
        let levelGroupsMap = groupsMap;

        groupsValues.forEach((value, index) => {
          const isLast = index + 1 === groupsValues.length;

          if (!levelGroupsMap.children[value]) {
            levelGroupsMap.children[value] = {
              order: isLast ? values : [],
              children: {}
            };
          }

          levelGroupsMap = levelGroupsMap.children[value];
        });
      }
    });

    return groupsMap;
  }, [experimentGroupsData, groupsQueryData, experimentsQueryData]);

  // The auto expanded functionality should be enabled only for next cases:
  // - changing into grouping (table.columnGrouping)
  // - changing into table search (table.search)
  // - changing page to archived experiments
  useEffect(() => {
    if (extendedGroupingColumns.length > 0) {
      invalidateExpandedGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedGroupingColumns, tableSearch, params.isArchive]);

  // post validation of request, all expanded leafs will be removed in case they don't have data
  useEffect(() => {
    // any calculation can be done only in case the loading is done
    if (isLoadingExperimentGroups || !isGroupingEnabled) return;

    const expandFunction = isFunction(onExpand) ? onExpand : noop;

    // we are building all possible combination for expanded groups base on data we already have in FE
    const possibleExpandedGroups = groupsMapToPossibleExpandedGroups(
      experimentsGroupsMap
    );

    // in case we don't have possible expanded groups we need to keep state the same as we have
    // to present no data information for underling charts
    // the typical reason for that are search or filtering
    if (possibleExpandedGroups.length === 0) return;

    const filteredExpandedGroups = expandedGroups.filter(expandedGroup =>
      possibleExpandedGroups.includes(expandedGroup)
    );

    // in case some expanded groups is already invalid we need to update expanded groups
    if (filteredExpandedGroups.length !== expandedGroups.length) {
      return expandFunction(filteredExpandedGroups);
    }

    // we proceed with auto-expand functionality only if any of next condition are true
    // - auto-open functionality is set to true
    // - we don't have valid query params for experiments request
    if (!isAutoGroupExpandEnabled || experimentsQueryData.length) return;

    // need to find most deep open group
    const parsedFilteredExpandedGroups = filteredExpandedGroups.map(
      filteredExpandedGroup =>
        filteredExpandedGroup.split(COMPOUND_KEY_SEPARATOR)
    );

    const mostOpenGroup =
      first(
        parsedFilteredExpandedGroups.sort((a1, a2) => a2.length - a1.length)
      ) ?? [];

    const possibleValues = getGroupPossibleValuesFromGroupsMap(
      experimentsGroupsMap,
      mostOpenGroup
    );

    // if there are possible values in most deep group we need to initialize auto-expand for first possible value
    if (possibleValues.length > 0) {
      const autoOpenGroup = [...mostOpenGroup, possibleValues[0]];

      expandFunction([
        ...filteredExpandedGroups,
        autoOpenGroup.join(COMPOUND_KEY_SEPARATOR)
      ]);

      if (autoOpenGroup.length === maxGroupingLevel) {
        disableAutoGroupExpand();
      }
    }
  }, [
    isLoadingExperimentGroups,
    experimentsGroupsMap,
    expandedGroups,
    isAutoGroupExpandEnabled,
    experimentsQueryData.length,
    params,
    onExpand,
    maxGroupingLevel,
    isGroupingEnabled,
    disableAutoGroupExpand
  ]);

  return {
    isFetchingExperimentGroups,
    isLoadingExperimentGroups,
    totalGroups: experimentGroupsData[0]?.data?.pageTotal ?? 0,
    experimentsGroupsMap
  };
};

export default useExperimentsGroups;
