import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import NewReportButton from '@reports/components/NewReportButton';
import ReportsTableHeader from '@reports/components/ReportsTableHeader';
import ReportsTableRow from '@reports/components/ReportsTableRow';
import { REPORT_COLUMNS, SORT_BY_DIRECTION } from '@reports/constants';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styles from './ReportsTable.module.scss';
import useTrackTableEventsBI from '@/shared/hooks/useTrackTableEventsBI';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === SORT_BY_DIRECTION.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const ReportsTable = ({
  canEdit,
  onPageChange,
  onRowsPerPageChange,
  onSelect,
  page,
  rows,
  rowsPerPage,
  searchText,
  selectedReports
}) => {
  const [order, setOrder] = useState(SORT_BY_DIRECTION.DESC);
  const [orderBy, setOrderBy] = useState(REPORT_COLUMNS.LAST_UPDATED);
  const { onSortingSendBI } = useTrackTableEventsBI();

  const hasTemplates = useMemo(
    () => rows.some(({ isTemplate }) => isTemplate),
    [rows]
  );

  const handleRequestSort = (event, property) => {
    const { ASC, DESC } = SORT_BY_DIRECTION;
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);

    onSortingSendBI({
      sortedColumnsCount: 1
    });
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newlySelected = rows.map(row => row[REPORT_COLUMNS.REPORT_ID]);
      onSelect(newlySelected);
      return;
    }
    onSelect([]);
  };

  const handleSelectRow = (event, reportId) => {
    const selectedIndex = selectedReports.indexOf(reportId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReports, reportId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedReports.slice(1));
    } else if (selectedIndex === selectedReports.length - 1) {
      newSelected = newSelected.concat(selectedReports.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedReports.slice(0, selectedIndex),
        selectedReports.slice(selectedIndex + 1)
      );
    }

    onSelect(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = event => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
    onPageChange(0);
  };

  const isSelected = reportId => selectedReports.indexOf(reportId) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const renderEmptyMessage = () => {
    return (
      <div style={{ fontSize: 20, textAlign: 'center' }}>
        {isEmpty(searchText) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <span style={{ marginBottom: 25 }}>Create your first report!</span>
            <NewReportButton canEdit={canEdit} />
          </div>
        ) : (
          'No reports matching your search'
        )}
      </div>
    );
  };

  const renderTemplateRow = row => {
    return (
      <ReportsTableRow
        key={row[REPORT_COLUMNS.REPORT_ID]}
        {...row}
        canEdit={canEdit}
        hasTemplates={hasTemplates}
        isItemSelected={isSelected(row[REPORT_COLUMNS.REPORT_ID])}
        onSelectRow={handleSelectRow}
      />
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table classes={{ root: styles.table }} size="small">
        <ReportsTableHeader
          numSelected={selectedReports.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          hasTemplates={hasTemplates}
          rowCount={rows.length}
        />

        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(renderTemplateRow)}

          {isEmpty(rows) && (
            <TableRow style={{ height: 59 * emptyRows }}>
              <TableCell colSpan={6}>{renderEmptyMessage()}</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

ReportsTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedReports: PropTypes.array.isRequired
};

export default ReportsTable;
