import { useQuery } from 'react-query';
import api from '@shared/api';

type UseExperimentHistogramStateParams = {
  experimentKey: string;
};

const getExperimentHistogramState = async ({
  experimentKey,
  signal
}: {
  experimentKey: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get<{
    tabState: string;
  }>('asset/get-histogram-tab-state', {
    params: { experimentKey },
    signal
  });

  return data.tabState ? data.tabState.split(',') : [];
};

export default function useExperimentHistogramState({
  experimentKey
}: UseExperimentHistogramStateParams) {
  return useQuery(
    ['experiment-histogram-state', { experimentKey }],
    ({ signal }) => {
      return getExperimentHistogramState({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true
    }
  );
}
