// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridPanelTiles-module__container--bLw4z{--margin-top: 0;--margin-right: 0;--margin-bottom: 0;--margin-left: 0;width:calc(100% - var(--margin-left) - var(--margin-right));height:calc(100% - var(--margin-top) - var(--margin-bottom));display:grid;overflow:auto;position:relative;margin:var(--margin-top) var(--margin-right) var(--margin-bottom) var(--margin-left)}.chart-internal-container .GridPanelTiles-module__container--bLw4z{--margin-top: 18px;--margin-right: 21px;--margin-bottom: 22px;--margin-left: 21px}.ds-full-width-modal .GridPanelTiles-module__container--bLw4z{--margin-top: 40px;--margin-right: 30px;--margin-bottom: 30px;--margin-left: 30px}.GridPanelTiles-module__emptyTile--U2nYs{background-color:var(--white);color:var(--gray-5);cursor:default;font-size:12px;font-weight:500;height:30px;left:0;line-height:18px;overflow:hidden;padding:0 4px 10px;position:sticky;text-overflow:ellipsis;white-space:nowrap;z-index:200}.GridPanelTiles-module__emptyTile--U2nYs a{color:var(--gray-5);font-weight:500}.GridPanelTiles-module__emptyTile--U2nYs a:visited{color:var(--gray-5)}.GridPanelTiles-module__emptyTile--U2nYs a:hover{color:var(--gray-6)}.GridPanelTiles-module__emptyTile--U2nYs a:active{color:var(--gray-7)}.GridPanelTiles-module__assetTile--g5sjC{display:flex;align-items:center;justify-content:center;overflow:hidden;border-left:1px solid var(--gray-3);border-top:1px solid var(--gray-3);padding:14px}.GridPanelTiles-module__assetTile--g5sjC.GridPanelTiles-module__clickable--Eurjn img{cursor:pointer}.GridPanelTiles-module__assetTile--g5sjC.GridPanelTiles-module__lastRow--TcvU9{border-bottom:1px solid var(--gray-3)}.GridPanelTiles-module__assetTile--g5sjC.GridPanelTiles-module__lastColumn--ot463{border-right:1px solid var(--gray-3)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GridPanelTiles-module__container--bLw4z`,
	"emptyTile": `GridPanelTiles-module__emptyTile--U2nYs`,
	"assetTile": `GridPanelTiles-module__assetTile--g5sjC`,
	"clickable": `GridPanelTiles-module__clickable--Eurjn`,
	"lastRow": `GridPanelTiles-module__lastRow--TcvU9`,
	"lastColumn": `GridPanelTiles-module__lastColumn--ot463`
};
module.exports = ___CSS_LOADER_EXPORT___;
