import { Popover } from '@material-ui/core';
import React, { forwardRef } from 'react';
import styles from './ActiveStatusIconDialog.module.scss';

function ActiveStatusIconDialog({ title, content, ...popoverProps }, ref) {
  return (
    <Popover
      PaperProps={{ className: styles.statusIconDialogPopover }}
      anchorEl={ref.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      elevation={0}
      {...popoverProps}
    >
      <div className={styles.statusIconDialogContainer}>
        <h6 className={styles.statusIconDialogTitle}>{title}</h6>
        <p className={styles.statusIconDialogParagraph}>{content}</p>
      </div>
    </Popover>
  );
}

export default forwardRef(ActiveStatusIconDialog);
