import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withWidth from '@material-ui/core/withWidth';
import Pagination from '@material-ui/lab/Pagination';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import Histogram from './Histogram';
import useProject from '@API/project/useProject';

const PAGE_SIZE = 10;

const HistogramList = ({
  assets,
  experiments,
  experimentKey,
  ignoreOutliers,
  maxSteps,
  fullWidth,
  width,
  handleDeleteHistogram
}) => {
  const { data: project } = useProject();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [assets.length]);

  const chartItemClassName = useMemo(() => {
    const baseClass = 'histogram-chart chart-grid-item';
    const widthClass = fullWidth
      ? 'grow-to-full-width'
      : `grow-to-viewport-${width}`;

    return `${baseClass} ${widthClass}`;
  }, [width, fullWidth]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const histograms = assets
    .map(asset => {
      return (
        <div className={chartItemClassName} key={asset.assetId}>
          <Histogram
            {...asset}
            projectId={project?.projectId}
            experiments={experiments}
            experimentKey={experimentKey}
            ignoreOutliers={ignoreOutliers}
            maxSteps={maxSteps}
            handleDeleteHistogram={handleDeleteHistogram}
          />
        </div>
      );
    })
    .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  const pages = Math.ceil(assets.length / PAGE_SIZE);

  return (
    <div className={`chart-grid`}>
      {histograms}

      {pages > 1 && (
        <div className="histogram-pagination">
          <Pagination page={page} onChange={handleChange} count={pages} />
        </div>
      )}
    </div>
  );
};

HistogramList.defaultProps = {
  fullWidth: false
};

HistogramList.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      assetId: PropTypes.string,
      fileName: PropTypes.string,
      link: PropTypes.string,
      step: PropTypes.number
    })
  ).isRequired,
  experimentKey: PropTypes.string.isRequired,
  ignoreOutliers: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool,
  width: PropTypes.string.isRequired,
  handleDeleteHistogram: PropTypes.func.isRequired
};

export default connect()(withWidth()(HistogramList));
