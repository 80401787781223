import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Fragment
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Divider from '@material-ui/core/Divider';

import SmallLoader from '@shared/components/SmallLoader';
import { useIsUserMemberOfWorkspace } from '@shared/hooks';
import {
  getArtifactByName,
  getArtifactVersionsArray
} from '@/reducers/artifactsReducer';
import artifactActions from '@/actions/artifactActions';
import ArtifactVersionsTable from './ArtifactVersionsTable';
import FilterPopover from '@shared/components/FilterPopover';
import {
  formatBytes,
  getUniqValuesFromArray,
  getUniqValuesFromArrayOfObjects
} from '@/helpers/generalHelpers';
import { TABLE_COLUMN_KEYS } from '@artifacts/constants/constants';
import {
  filterItems,
  WORKSPACE_URL_HASHES
} from '@/constants/workspaceConstants';
import ArtifactPageHeader from './ArtifactPageHeader';
import GoBackLink from '@shared/components/GoBackLink';
import { renderVersionState } from '@artifacts/utils/helpers';

const getTableFilters = items => {
  return [
    {
      groupLabel: 'Tags',
      filterKey: TABLE_COLUMN_KEYS.TAGS,
      options: getUniqValuesFromArray(items, TABLE_COLUMN_KEYS.TAGS).map(
        tag => ({
          label: tag,
          value: tag
        })
      )
    },

    {
      groupLabel: 'Status',
      filterKey: TABLE_COLUMN_KEYS.STATE,
      options: getUniqValuesFromArrayOfObjects(
        items,
        TABLE_COLUMN_KEYS.STATE
      ).map(state => ({
        label: renderVersionState(state, { isDropdown: true }),
        value: state
      }))
    },
    {
      groupLabel: 'Size',
      filterKey: TABLE_COLUMN_KEYS.SIZE,
      options: getUniqValuesFromArrayOfObjects(
        items,
        TABLE_COLUMN_KEYS.SIZE
      ).map(size => ({
        label: formatBytes(size),
        value: size
      }))
    }
  ];
};

const ArtifactPage = ({
  artifact,
  artifactVersions,
  artifactName,
  dispatch
}) => {
  const { workspace } = useParams();
  const [hasFetched, setHasFetched] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();

  useEffect(() => {
    if (!hasFetched) {
      dispatch(artifactActions.fetchArtifact(workspace, artifactName)).then(
        () => {
          setHasFetched(true);
        }
      );
    }
  }, [artifactName, dispatch, hasFetched, workspace]);

  const handleFilterChange = useCallback(
    (filterKey, values) => {
      const update = {
        ...selectedFilters,
        [filterKey]: values
      };
      setSelectedFilters(update);
    },
    [selectedFilters]
  );

  const visibleRows = useMemo(() => {
    return filterItems(artifactVersions, selectedFilters);
  }, [selectedFilters, artifactVersions]);

  const renderActionFields = () => {
    const filters = getTableFilters(artifactVersions);
    return (
      <div className="action-fields-container">
        <div className="field-group">
          {filters.map((filter, index) => (
            <Fragment key={filter.filterKey}>
              <FilterPopover
                className="light"
                handleFilterChange={handleFilterChange}
                selectedFilters={get(selectedFilters, [filter.filterKey], [])}
                filter={filter}
              />

              {index !== filters.length - 1 && (
                <Divider orientation="vertical" style={{ height: 20 }} />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

  const renderArtifactBody = () => {
    if (isEmpty(artifact)) {
      return (
        <div className="content-not-found">
          <h3 className="page-notice">No artifact found.</h3>
          <GoBackLink
            to={`/${workspace}${WORKSPACE_URL_HASHES.ARTIFACTS}`}
            text="Go back to artifacts"
          />
        </div>
      );
    }

    return (
      <>
        {renderActionFields()}
        <Divider />
        <ArtifactVersionsTable
          rows={visibleRows}
          isArtifactPublic={artifact.isPublic}
        />
      </>
    );
  };

  if (!hasFetched) {
    return (
      <div className="flex grow">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage="Fetching artifact"
        />
      </div>
    );
  }

  return (
    <div className="artifact-container">
      <ArtifactPageHeader
        artifact={artifact}
        isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
      />
      <div className="artifact-body">{renderArtifactBody()}</div>
    </div>
  );
};

ArtifactPage.propTypes = {
  artifact: PropTypes.object.isRequired,
  artifactName: PropTypes.string.isRequired,
  artifactVersions: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const { artifactName: artifactNameEncoded } = props.match.params;
  const artifactName = decodeURIComponent(artifactNameEncoded);

  return {
    artifact: getArtifactByName(state, { artifactName }),
    artifactVersions: getArtifactVersionsArray(state),
    artifactName
  };
};

export default connect(mapStateToProps)(ArtifactPage);
