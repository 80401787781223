import { experimentActionTypes } from '@/constants/actionTypes';

const initialState = {
  map: {},
  metadata: {},
  keys: []
};

const experimentsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case experimentActionTypes.SET_EXPERIMENT_NAME: {
      const { experimentKey, experimentName } = payload;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          [experimentKey]: {
            ...state.metadata[experimentKey],
            Name: experimentName
          }
        }
      };
    }

    default:
      return state;
  }
};

export default experimentsReducer;
