import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { formatDateTimeZone } from '@shared/utils/displayHelpers';

const DateCell = ({ value, formatter }) => {
  return (
    <Box display="flex">
      <span>{formatter(value)}</span>
    </Box>
  );
};

DateCell.defaultProps = {
  formatter: formatDateTimeZone,
  value: ''
};

DateCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatter: PropTypes.func
};

export default DateCell;
