import React, { useState } from 'react';

import { Button } from '@ds';
import { SortPopover } from '../SortPopover';
import { DSSortIcon } from '@ds-icons';
import { ColumnType, SortingColumnType } from '@mpm-druid/types';

type SortButtonProps = {
  columnSorting: SortingColumnType[];
  columns: ColumnType[];
  onChange: (columns: SortingColumnType[]) => void;
  disabled: boolean;
};

export const SortButton = ({
  columnSorting,
  columns,
  onChange,
  disabled
}: SortButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const countLabel = columnSorting.length ? ` (${columnSorting.length})` : '';

  return (
    <>
      <Button
        type="secondary"
        size="small"
        iconSize="small"
        PrefixIcon={<DSSortIcon />}
        onClick={handleOpen}
        disabled={disabled}
        active={!!anchorEl}
      >
        Sort {countLabel}
      </Button>

      <SortPopover
        columns={columns}
        anchorEl={anchorEl}
        columnSorting={columnSorting}
        onChange={onChange}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </>
  );
};
