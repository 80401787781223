import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OptionsEditorJSON from '@experiment-management-shared/components/OptionsEditorJSON';
import visualizationsActions from '@/actions/visualizationsActions';
import { getPyConfigPreview } from '@/reducers/visualizationsReducer';

const PyConfigTabContainer = () => {
  const dispatch = useDispatch();
  const options = useSelector(getPyConfigPreview);

  const handleSetPyConfig = useCallback(
    config => {
      dispatch(visualizationsActions.setPyConfig(config));
    },
    [dispatch]
  );

  return (
    <OptionsEditorJSON
      options={options}
      onApply={handleSetPyConfig}
      optionsToResetTo={options}
    />
  );
};

export default PyConfigTabContainer;
