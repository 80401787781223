import React from 'react';
import PropTypes from 'prop-types';

import { ClickAwayListener } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { Checkbox } from '@ds';
import { IS_REGEX, IS_EXACT_MATCH } from './constants';

const SettingsPanel = ({
  anchorEl,
  open,
  handleCheckboxChange,
  isExactMatch,
  isRegex,
  onClose
}) => (
  <Popper
    id="setting-panel-search"
    open={open}
    anchorEl={anchorEl}
    placement="bottom-end"
  >
    <ClickAwayListener onClickAway={onClose}>
      <Paper className="searchbox-textbox-panel" elevation={2} open={open}>
        <div className="searchbox-textbox-panel__control">
          <Checkbox
            id={`${IS_EXACT_MATCH}_checkbox`}
            onChange={() => handleCheckboxChange(IS_EXACT_MATCH)}
            checked={isExactMatch}
          />
          <label
            className="searchbox-textbox-panel__label"
            htmlFor={`${IS_EXACT_MATCH}_checkbox`}
          >
            Exact match
          </label>
        </div>
        <div className="searchbox-textbox-panel__control">
          <Checkbox
            id={`${IS_REGEX}_checkbox`}
            onChange={() => handleCheckboxChange(IS_REGEX)}
            checked={isRegex}
          />
          <label
            className="searchbox-textbox-panel__label"
            htmlFor={`${IS_REGEX}_checkbox`}
          >
            Regex Search
          </label>
        </div>
      </Paper>
    </ClickAwayListener>
  </Popper>
);

SettingsPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isExactMatch: PropTypes.bool.isRequired,
  isRegex: PropTypes.bool.isRequired
};

export default SettingsPanel;
