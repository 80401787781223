import React from 'react';
import styles from './OtherSingleTableCell.module.scss';
import { Tooltip } from '@ds';
import { OtherCellRendererData } from './types';

type OthersTableKeyCellProps = OtherCellRendererData;

const OthersTableKeyCell: React.FC<OthersTableKeyCellProps> = ({ value }) => {
  return (
    <div className={styles.othersTableKeyCell}>
      <Tooltip content={value} placement="top" truncateOverflow>
        <>{value}</>
      </Tooltip>
    </div>
  );
};

export default OthersTableKeyCell;
