import { useEffect } from 'react';
import queryString from 'query-string';

import { trackEvent } from '@shared/utils/eventTrack';

const useGoogleAnalytics = () => {
  const { githubAction } = queryString.parse(window.location.search);

  useEffect(() => {
    if (!githubAction) return;

    if (['login', 'signup'].includes(githubAction)) {
      trackEvent(githubAction, { method: 'Github' });
    }
  }, []);
};

export default useGoogleAnalytics;
