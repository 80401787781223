import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import find from 'lodash/find';
import noop from 'lodash/noop';

import { Divider } from '@DesignSystem/data-display';
import { IconButton, Button, TextButton } from '@ds';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popover from '@material-ui/core/Popover';
import SearchBar from 'material-ui-search-bar';
import Tooltip from '@material-ui/core/Tooltip';
import { KeyboardArrowDownIcon } from '@Icons-outdated';
import { matchString } from '@shared/utils/filterHelpers';

import './FiltersSelect.scss';
import { IFilterDetails } from '@experiment-management-shared/api/useListFilters';
import { DSDeleteIcon } from '@ds-icons';

const FiltersSelect = ({
  entityName,
  activeFilter,
  children,
  onSelectFilter,
  onDeleteFilter,
  onOpenList,
  PopoverProps,
  filters,
  totalEntities
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const filteredFilters = useMemo(() => {
    if (!searchText) return filters;

    return filters.filter(filter => {
      const sourceText = ['name', 'created_by', 'updated_by']
        .map(key => filter[key])
        .join(' ');

      return matchString(sourceText, searchText);
    });
  }, [filters, searchText]);

  const getFilterByFilterId = filterId => {
    return find(filters, { filterId });
  };

  const handleToggle = event => {
    const newStatus = !open;

    // from close to open
    if (newStatus) {
      onOpenList();
    }

    setOpen(newStatus);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSearchText('');
  };

  const handleFilterSelect = filterId => {
    handleClose();
    onSelectFilter(filterId, getFilterByFilterId(filterId));
  };

  const handleResetQuery = () => {
    setOpen(false);
    onSelectFilter(null, getFilterByFilterId(null));
  };

  const renderAllExperimentsButton = () => (
    <ListSubheader className="list-message flex justifyCenter" component="div">
      <Button onClick={handleResetQuery}>
        <span>
          All {entityName}&nbsp;
          {!!totalEntities && (
            <small className="num-of-experiments-button-value">
              {totalEntities}
            </small>
          )}
        </span>
      </Button>
    </ListSubheader>
  );

  const renderButton = () => {
    const buttonProps = {
      onClick: handleToggle
    };

    if (children) {
      return React.cloneElement(children, { ...buttonProps });
    }

    return (
      <TextButton className="saved-segment-list-button" {...buttonProps}>
        <span className="template-label">
          {!activeFilter ? `All ${entityName}` : activeFilter.name}{' '}
          <KeyboardArrowDownIcon fontSize="small" style={{ marginLeft: 5 }} />
        </span>
      </TextButton>
    );
  };

  const renderFilterList = () => {
    if (!filters.length) {
      return (
        <ListSubheader className="list-message" component="div">
          <span className="list-message-content">
            You don&apos;t have any saved filters. Use the &quot;Add to
            library&quot; button to make a new filter.
          </span>
        </ListSubheader>
      );
    }

    return (
      <div className="segment-list">
        {filteredFilters.map(renderFilterItem)}
      </div>
    );
  };

  const renderFilterItem = (filter, index) => {
    const { filterId, name } = filter;

    return (
      <ListItem
        key={`${filterId}-${index}`}
        onClick={() => handleFilterSelect(filterId)}
        selected={filterId === activeFilter?.filterId}
      >
        <ListItemText
          primary={filter.name}
          role="button"
          classes={{ root: 'segment-list-item' }}
        />
        <ListItemSecondaryAction>
          <Tooltip
            title="Delete filter"
            placement="left"
            arrow
            classes={{
              arrow: 'action-tooltip-arrow',
              popper: 'action-tooltip-root',
              tooltip: 'action-tooltip'
            }}
          >
            <IconButton
              Icon={<DSDeleteIcon />}
              type="secondary"
              className="action-button"
              onClick={() => onDeleteFilter(name, filterId)}
              aria-label="Delete"
            />
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <div className="segment-list-collapse segment-buttons-wrapper">
      <Tooltip title="Select filters to construct a segment" placement="top">
        {/* Fragment is workaround not to trigger next error:
         Failed prop type: Invalid prop `children` supplied to `ForwardRef(Tooltip)` */}
        <>{renderButton()}</>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="select-list-container"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...PopoverProps}
      >
        <List className="select-list select-query-segment-list">
          <ListItem className="select-list-search">
            <SearchBar
              autoFocus
              placeholder="Search filters"
              onChange={setSearchText}
              onRequestSearch={setSearchText}
              onCancelSearch={() => setSearchText('')}
            />
          </ListItem>

          <Divider className="query-segment-divider" margin={0} />

          <div>
            <div className="saved-segment-list">
              {renderAllExperimentsButton()}

              <Divider className="query-segment-divider" margin={0} />

              {renderFilterList()}
            </div>
          </div>
        </List>
      </Popover>
    </div>
  );
};

FiltersSelect.defaultProps = {
  entityName: 'experiments',
  totalEntities: 0,
  activeFilter: null,
  children: null,
  onSelectFilter: noop,
  onOpenList: noop,
  onDeleteFilter: noop,
  PopoverProps: {}
};

FiltersSelect.propTypes = {
  entityName: PropTypes.string,
  totalEntities: PropTypes.number,
  activeFilter: IFilterDetails,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onSelectFilter: PropTypes.func,
  onOpenList: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  PopoverProps: PropTypes.object,
  filters: PropTypes.arrayOf(IFilterDetails).isRequired
};

export default FiltersSelect;
