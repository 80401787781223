import { useCallback } from 'react';
import { formatModelVersionStatusToText } from '@shared/utils/model-registry-request-status';
import { getSnackbarMessagesForStatusUpdate } from '@model-registry/utils/request-status';
import useModelRegistryChangeItemStatusMutation from '@model-registry/api/useModelRegistryChangeItemStatusMutation';

export default function useHandleStatusUpdate({
  currentVersion,
  registryModelId,
  registryModelItemId,
  workspace,
  modelName,
  ifCurrentUserWsOwner,
  deps = [],
  invalidateQueriesKeys = {}
}) {
  const updateModelRegistryItemStateMutation = useModelRegistryChangeItemStatusMutation(
    { registryModelId, workspace, modelName, invalidateQueriesKeys }
  );

  const handleUpdate = useCallback(
    ({ status, comment, isReviewRequired, isOwner }) => {
      const statusName = formatModelVersionStatusToText(status);

      return updateModelRegistryItemStateMutation.mutate({
        values: {
          comment,
          status,
          isReviewRequired
        },
        registryModelItemId,
        snackbarMsg: getSnackbarMessagesForStatusUpdate({
          statusName,
          currentVersion,
          modelName,
          isReviewRequired,
          isOwner
        }),
        snackbarMsgErr: getSnackbarMessagesForStatusUpdate({
          isError: true,
          statusName,
          currentVersion,
          modelName,
          isReviewRequired,
          isOwner
        })
      });
    },
    [
      ifCurrentUserWsOwner,
      modelName,
      currentVersion,
      registryModelItemId,
      ...deps
    ]
  );

  return {
    handler: handleUpdate,
    mutation: updateModelRegistryItemStateMutation
  };
}
