import React from 'react';
import PropTypes from 'prop-types';
import { SourceExperiment } from '@DesignSystem/data-display';

const SourceExperimentCell = props => {
  const {
    row: { SourceExperiment: text = '', rowData }
  } = props;
  return <SourceExperiment data={rowData} text={text} />;
};

SourceExperimentCell.propTypes = {
  row: PropTypes.shape({
    SourceExperiment: '',
    rowData: PropTypes.shape({
      experimentModel: PropTypes.shape({
        experimentKey: PropTypes.string
      }),
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          assetId: PropTypes.string,
          dir: PropTypes.string
        })
      )
    }).isRequired
  }).isRequired
};

export default SourceExperimentCell;
