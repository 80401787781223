import { useState } from 'react';
import { OTHERS_COLUMNS_NAME } from '../otherSingleTableConfig';
import { PARAM_ACTION_COLUMN } from '@experiment-details/components/params/helpers';

const defaultColumnWidths = [
  {
    columnName: OTHERS_COLUMNS_NAME.KEY,
    width: 300
  },
  {
    columnName: PARAM_ACTION_COLUMN,
    width: 60
  }
];

const useOtherSingleTableColumnWidths = () => {
  const [columnWidths, onColumnWidthsChange] = useState(defaultColumnWidths);

  return {
    columnWidths,
    onColumnWidthsChange
  };
};

export default useOtherSingleTableColumnWidths;
