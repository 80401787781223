import { Completion } from '@codemirror/autocomplete';
import { SyntaxNode } from '@lezer/common';
import { EditorView } from '@uiw/react-codemirror';
import {
  CATEGORICAL_COMET_FUNCTIONS,
  KEYWORDS,
  MATH_OPERATORS,
  NUMERICAL_COMET_FUNCTIONS,
  NUMERIC_MANY_ARGUMENTS_OPERATORS,
  NUMERIC_ONE_ARGUMENT_FUNCTIONS,
  NUMERIC_ONE_ARGUMENT_OPERATORS,
  WHERE_OPERATORS
} from './constants';

const applyAutoComplete = ({
  moveCursor = 0,
  replaceNode
}: {
  moveCursor?: number;
  replaceNode?: SyntaxNode | null;
}) => {
  return (
    view: EditorView,
    completion: Completion,
    from: number,
    to: number
  ) => {
    const insertText = completion.label;
    const changes = {
      from: replaceNode?.from ?? from,
      to: replaceNode?.to ?? to,
      insert: insertText
    };
    const docLength = view.state.doc.length;
    const currentNodeLength = replaceNode
      ? replaceNode.to - replaceNode.from
      : 0;

    const anchor = Math.max(
      0,
      Math.min(
        docLength + insertText.length - currentNodeLength,
        changes.from + insertText.length + moveCursor
      )
    );

    view.dispatch({
      changes,
      selection: { anchor }
    });

    view.focus();
  };
};

type SuggestionFunction = (node: SyntaxNode | null) => Completion;
const keywordsSuggestions = Object.fromEntries(
  KEYWORDS.map(keyword => [
    keyword,
    (replaceNode: SyntaxNode | null) => ({
      displayLabel: keyword,
      label: `${keyword} `,
      type: 'keyword',
      apply: applyAutoComplete({ replaceNode }),
      boost: 10
    })
  ])
) as Record<typeof KEYWORDS[number], SuggestionFunction>;

const numericOneArgumentFunctionSuggestions = Object.fromEntries(
  NUMERIC_ONE_ARGUMENT_FUNCTIONS.map(name => [
    name,
    {
      displayLabel: name,
      label: `${name}("")`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -2 }),
      boost: 10
    }
  ])
) as Record<typeof NUMERIC_ONE_ARGUMENT_FUNCTIONS[number], Completion>;

const categoricalCometFunctionSuggestions = Object.fromEntries(
  CATEGORICAL_COMET_FUNCTIONS.map(name => [
    name,
    {
      displayLabel: name,
      label: `${name}("", "", '')`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -10 }),
      boost: 10
    }
  ])
) as Record<typeof CATEGORICAL_COMET_FUNCTIONS[number], Completion>;

const numericalCometFunctionSuggestions = Object.fromEntries(
  NUMERICAL_COMET_FUNCTIONS.map(name => [
    name,
    {
      displayLabel: name,
      label: `${name}("", "")`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -6 }),
      boost: 10
    }
  ])
) as Record<typeof NUMERICAL_COMET_FUNCTIONS[number], Completion>;

const numericalOneArgumentOperatorSuggestions = Object.fromEntries(
  NUMERIC_ONE_ARGUMENT_OPERATORS.map(name => [
    name,
    {
      displayLabel: name,
      label: `${name}("")`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -2 }),
      boost: 10
    }
  ])
) as Record<typeof NUMERIC_ONE_ARGUMENT_OPERATORS[number], Completion>;

const numericalManyArgumentOperatorSuggestions = Object.fromEntries(
  NUMERIC_MANY_ARGUMENTS_OPERATORS.map(name => [
    name,
    {
      displayLabel: name,
      label: `${name}("", "")`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -6 }),
      boost: 10
    }
  ])
) as Record<typeof NUMERIC_MANY_ARGUMENTS_OPERATORS[number], Completion>;

export const SUGGESTIONS = {
  ...keywordsSuggestions,
  ComputeNumericFunction: {
    ...numericOneArgumentFunctionSuggestions,
    APPROX_QUANTILE_DS: {
      displayLabel: 'APPROX_QUANTILE_DS',
      label: `APPROX_QUANTILE_DS("", 0.5)`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -7 }),
      boost: 10
    },
    ...categoricalCometFunctionSuggestions,
    ...numericalCometFunctionSuggestions
  },
  NumericOperators: {
    ...numericalOneArgumentOperatorSuggestions,
    ...numericalManyArgumentOperatorSuggestions,
    POW: {
      displayLabel: 'POW',
      label: `POW("", 2)`,
      type: 'keyword',
      apply: applyAutoComplete({ moveCursor: -5 }),
      boost: 10
    }
  },
  All: (replaceNode: SyntaxNode) => ({
    label: '*',
    type: 'variable',
    apply: applyAutoComplete({
      moveCursor: 1,
      replaceNode
    })
  }),
  ColumnName: (names: string[], replaceNode: SyntaxNode | null, query = '') => {
    return names
      .filter(name => {
        return name.toLowerCase().includes(query.toLowerCase());
      })
      .map(name => ({
        displayName: name,
        label: `"${name}"`,
        type: 'variable',
        apply: applyAutoComplete({
          moveCursor: 2,
          replaceNode
        })
      }));
  },
  MathOperators: (replaceNode: SyntaxNode | null) => {
    return MATH_OPERATORS.map(operator => ({
      label: operator,
      type: 'operator',
      apply: applyAutoComplete({ replaceNode })
    }));
  },
  WhereOperators: (replaceNode: SyntaxNode | null) => {
    const whereOperators: Completion[] = WHERE_OPERATORS.map(operator => ({
      label: operator,
      type: 'operator',
      apply: applyAutoComplete({ replaceNode })
    }));

    whereOperators.push({
      label: 'IS NULL',
      type: 'operator',
      apply: applyAutoComplete({ replaceNode })
    });

    whereOperators.push({
      label: 'IS NOT NULL',
      type: 'operator',
      apply: applyAutoComplete({ replaceNode })
    });

    return whereOperators;
  }
};
