import { experimentEvents } from '@/constants/trackingEventTypes';
import { trackEvent } from '@shared/utils/eventTrack';
import { useParams } from 'react-router';
import useModelRegistryList from '@shared/api/useModelRegistryList';

const useRegisterModelSidebarIsOpenedEvent = () => {
  const { workspace } = useParams<{
    workspace: string;
  }>();
  const { data: allRegisteredModels } = useModelRegistryList(workspace);

  return () => {
    if (!allRegisteredModels) return;

    trackEvent(
      experimentEvents.SINGLE_EXPERIMENT_PAGE_REGISTER_MODEL_SIDEBAR_OPENED,
      {
        experiment_model_count: allRegisteredModels.length
      }
    );
  };
};

export default useRegisterModelSidebarIsOpenedEvent;
