import { Metric } from '@shared/types';
import { useState } from 'react';

type OnOpenModalOpts = { defaultFormData?: Metric; callback?: () => void };
const useMetricModalState = () => {
  const emptyMetricData = {} as Metric;
  const initialState = {
    isOpened: false,
    defaultFormData: emptyMetricData
  };

  const [{ isOpened, defaultFormData }, setModalState] = useState(initialState);

  const onOpenModal = ({
    defaultFormData = emptyMetricData,
    callback
  }: OnOpenModalOpts) => {
    setModalState({
      defaultFormData,
      isOpened: true
    });
    if (callback) {
      callback();
    }
  };

  const onCloseModal = () => {
    setModalState(initialState);
  };

  return {
    onOpenModal,
    onCloseModal,
    isOpened,
    defaultFormData
  };
};

export default useMetricModalState;
