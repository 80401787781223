import useCurrentUser from '@API/auth/useCurrentUser';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';
import organizationsActions from '../../../actions/organizationsActions';
import { ORGANIZATION_USER_ROLES } from '@shared/constants';
import { APIOrganization, Organization } from '@shared/types';

export const mapOrganization = (organization: APIOrganization) => ({
  ...organization,
  isAdmin: organization?.role === ORGANIZATION_USER_ROLES.ADMIN,
  isMember: organization?.role === ORGANIZATION_USER_ROLES.MEMBER
});

const getOrganizations = async (): Promise<Organization[]> => {
  const resp = await organizationApi.fetchOrganizations();

  return resp?.data?.map(mapOrganization);
};

export default function useOrganizations() {
  const dispatch = useDispatch();
  const { data: currentUser } = useCurrentUser();

  return useQuery(['organizations', 'list'], getOrganizations, {
    onSuccess: organizations => {
      dispatch(organizationsActions.setOrganizations(organizations));
    },
    enabled: !!currentUser?.apiKey
  });
}
