import { useQuery } from 'react-query';
import { normalizeColumnName } from '@API/helpers/v2_helpers';

import { axiosInstance, cancelWrapper } from '@API/api';
import useProject from '@API/project/useProject';
const fixParallelCoordinatesColumns = column => {
  if (!column?.kind) {
    return { ...column, kind: 'last' };
  }

  return column;
};

const getParallelCoordinatesPanelParameters = ({
  experimentKeys,
  metrics,
  projectId,
  params,
  selectedDecimalPrecision,
  selectedTargetVariable
}) => {
  /* Since now the selected keys might have %%%MIN/MAX we have to normalize them */
  const selectedTargetVariableWithKind = normalizeColumnName(
    selectedTargetVariable
  );
  const paramsWithKind = params?.map(normalizeColumnName);
  const metricsWithKind = metrics?.map(normalizeColumnName);

  return {
    metrics: metricsWithKind?.map(fixParallelCoordinatesColumns),
    params: paramsWithKind?.map(fixParallelCoordinatesColumns),
    projectId,
    target_experiments: experimentKeys,
    target_variable: fixParallelCoordinatesColumns(
      selectedTargetVariableWithKind
    ),
    tickformat: selectedDecimalPrecision || '.3f'
  };
};

const getExperimentsPanelData = async params => {
  return cancelWrapper(async cancelToken => {
    const response = await axiosInstance({
      cancelToken,
      data: params,
      method: 'post',
      url: 'dashboard/parallel-coordinates-plotly-chart-v2'
    });

    return response.data;
  });
};

export default function useParallelCoordinatesPanelData(params, config) {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  const panelParameters = getParallelCoordinatesPanelParameters({
    projectId,
    ...params
  });

  return useQuery(
    [
      'panel',
      {
        panelParameters,
        projectId,
        type: params.type
      }
    ],
    () => getExperimentsPanelData(panelParameters),
    {
      keepPreviousData: true,
      ...config
    }
  );
}
