import React, { useCallback, useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import { OptimizedPlot } from '@DesignSystem/charts';

import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';
import {
  formatColumnLabelWithKind,
  normalizeColumnName
} from '@API/helpers/v2_helpers';
import {
  ACTIVE_FETCH_INTERVAL,
  BUILT_IN_CHART_TYPES
} from '@experiment-management-shared/constants/chartConstants';
import ChartContainer, {
  GeneralChartContainerProps
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import useParallelCoordinatesPanelData from '@experiment-management-shared/api/useParallelCoordinatesPanelData';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import dashboardHelpers from '@shared/utils/dashboardHelpers';
import useChartDataSourceChangeNotificator from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataSourceChangeNotificator';
import useChartDataRevision from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataRevision';
import usePlotlyBaseChart from '@experiment-management-shared/components/Charts/Chart/chartHooks/usePlotlyBaseChart';
import { Experiment, PanelType } from '@experiment-management-shared/types';
import { truncateMiddleOfStringByMaxCharacters } from '@shared/utils/displayHelpers';

const NULL_VALUE = 0;
const MAX_LABEL_LENGTH = 30;

type ParallelDataSourceMetric = {
  range: number[];
  label: string;
  values: number[];
  constraintrange: number[][];
  tickformat: string;
};

type ParallelDataSource = {
  target_variable: number[];
  metrics: ParallelDataSourceMetric[];
  experiments: string[];
  experimentToIndexInAxisMap: Map<string, number>;
};

type ParallelCoordinatesChartProps = {
  containerProps: GeneralChartContainerProps;
  chartId: string;
  isChartPreview?: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  isAutoRefreshEnabled: boolean;
  activeIntervalFetchDelay?: number;
  title?: string;
  chartName?: string;
  onDataSourceChange?: () => void;
  onFinishRender?: () => void;
  enableDataRevision?: boolean;
  isPendingRendering?: boolean;
  dataRevision?: number;
  revision?: number;
  hiddenMenuItems?: string[];
  onClearChartConstraints?: () => void;
  onMarkExperimentsVisible?: (
    chartId: string,
    highlightedExperimentKeys: string[]
  ) => void;
  selectedTargetVariable?: string;
  metrics: string[];
  params: string[];
  selectedDecimalPrecision: string;
};

const ParallelCoordinatesChart = ({
  containerProps,
  chartId,
  isChartPreview = false,
  experiments,
  experimentKeys,
  isAutoRefreshEnabled,
  activeIntervalFetchDelay = ACTIVE_FETCH_INTERVAL,
  title,
  chartName = '',
  onDataSourceChange = noop,
  onFinishRender = noop,
  enableDataRevision = false,
  isPendingRendering = false,
  dataRevision = 0,
  revision = 0,
  hiddenMenuItems,
  onClearChartConstraints = noop,
  onMarkExperimentsVisible = noop,
  selectedTargetVariable = '',
  metrics,
  params,
  selectedDecimalPrecision
}: ParallelCoordinatesChartProps) => {
  const chartType: PanelType = BUILT_IN_CHART_TYPES[
    'BuiltIn/ParallelCoordinates'
  ] as PanelType;
  // Keep the labels order if the user changes them
  // Plotly mutates the dimensions array, so we have to save the order
  const [labelsOrder, setLabelsOrder] = useState<string[] | null>(null);

  const { shouldRefetch, actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    title,
    chartName,
    chartType,
    titleProps: {
      selectedTargetVariable
    }
  });

  const {
    data: panelData,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useParallelCoordinatesPanelData(
    {
      experimentKeys,
      metrics,
      params,
      selectedDecimalPrecision,
      selectedTargetVariable,
      type: chartType
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );

  const dataSources = useDeepMemo(() => {
    if (isLoading) return {};

    const updatedParaCoordsData = chartHelpers.removeExistingConstraints(
      panelData
    );

    if (dashboardHelpers.checkForInvalidMetricData([updatedParaCoordsData])) {
      return {};
    }

    return updatedParaCoordsData;
  }, [isLoading, panelData]);

  useChartDataSourceChangeNotificator({
    isLoading,
    dataSources,
    onDataSourceChange
  });

  const {
    delayedDataSources,
    hasDelayedDataSources,
    isInitialRenderDelayed,
    calculatedRevision
  } = useChartDataRevision({
    enableDataRevision,
    isPendingRendering,
    dataRevision,
    dataSources,
    revision
  });

  const {
    setExportData,
    setCurrentLayout,
    containerRef,
    handleExportJSON,
    handleExportData,
    chartBaseLayout,
    PlotlyProps
  } = usePlotlyBaseChart({
    revision: calculatedRevision,
    isChartPreview,
    onFinishRender,
    title: actualTitle,
    chartType
  });

  useEffect(() => {
    setExportData(
      isEmpty(dataSources)
        ? []
        : [omit(dataSources, 'experimentToIndexInAxisMap')]
    );
  }, [dataSources, setExportData]);

  const handleLocalClearChartConstraints = useCallback(() => {
    setCurrentLayout(null);
    onClearChartConstraints();
  }, [onClearChartConstraints, setCurrentLayout]);

  const [constraintsReset, setConstraintsReset] = useState<number>(0);
  const handleClearChartConstraints = useCallback(() => {
    setConstraintsReset(value => value + 1);
    handleLocalClearChartConstraints();
  }, [handleLocalClearChartConstraints]);

  const isEmptyDataSource =
    get(delayedDataSources, 'experiments', []).length === 0;

  useEffect(() => {
    if (isError || isEmptyDataSource) {
      onFinishRender();
    }
  }, [isEmptyDataSource, isError, isLoading, onFinishRender]);

  const {
    metrics: parallelChartMetrics,
    target_variable
  } = delayedDataSources as ParallelDataSource;

  const parallelCoordinatesLayout = useMemo(() => {
    return {
      ...chartBaseLayout,
      font: {
        color: '#5f677e',
        size: 12,
        family: 'Roboto, sans-serif'
      },
      margin: { t: 40, b: 36 }
    };
  }, [chartBaseLayout]);

  const { keyName, kind } = normalizeColumnName(selectedTargetVariable);
  // @TODO once the backend returns label as an object with kind and keyName we can remove this formattedTargetVariable
  const formattedTargetVariable = formatColumnLabelWithKind(keyName, kind);

  const sortParallelCoordinateMetrics = useCallback(() => {
    if (labelsOrder) {
      return [...parallelChartMetrics].sort((firstMetric, secondMetric) => {
        const firstIndex = labelsOrder.indexOf(firstMetric.label);
        const secondIndex = labelsOrder.indexOf(secondMetric.label);

        return firstIndex - secondIndex;
      });
    }

    return chartHelpers.sortParallelCoordinateMetrics(
      parallelChartMetrics,
      formattedTargetVariable
    );
  }, [formattedTargetVariable, labelsOrder, parallelChartMetrics]);

  const dimensions: ParallelDataSourceMetric[] = useMemo(() => {
    if (isEmpty(parallelChartMetrics)) return;

    return sortParallelCoordinateMetrics().map(
      (metric: ParallelDataSourceMetric) => {
        const restMetric = omit(metric, ['range']);

        const hasNullValues = restMetric?.values?.some(value => {
          // only could happen for numerical metrics
          return value === null;
        });

        if (!hasNullValues) return restMetric;

        const minRange = Math.min(...restMetric.values, NULL_VALUE);
        const maxRange = Math.max(...restMetric.values, NULL_VALUE);

        return {
          ...restMetric,
          range: [minRange, maxRange],
          values: restMetric.values.map(value => {
            return value === null ? NULL_VALUE : value;
          })
        };
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parallelChartMetrics,
    sortParallelCoordinateMetrics,
    constraintsReset // we need this force recalculate data in case we reset constrains
  ]);

  const data = useMemo(() => {
    if (!dimensions) return;

    return [
      {
        type: 'parcoords',
        line: {
          showscale: true,
          reversescale: true,
          colorscale: [
            [0, '#feac71'],
            [1, '#5e65f1']
          ],
          color: target_variable,
          colorbar: { thickness: 12, outlinecolor: '#FFF' }
        },
        dimensions: dimensions?.map(dimension => ({
          ...dimension,
          label: truncateMiddleOfStringByMaxCharacters(
            dimension?.label,
            MAX_LABEL_LENGTH
          )
        }))
      }
    ];
  }, [dimensions, target_variable]);

  const getHighlightedExperiments = useCallback(
    (dimensions: ParallelDataSourceMetric[] = []) => {
      const { experiments } = dataSources;
      let experimentMap = {};

      const dimensionsWithConstraints = dimensions.filter(
        dimension => dimension.constraintrange
      );

      dimensionsWithConstraints.forEach(dimension => {
        const constraintRanges = isArray(dimension.constraintrange[0])
          ? dimension.constraintrange
          : [dimension.constraintrange];

        constraintRanges.forEach(range => {
          const [min, max] = range;

          experimentMap = chartHelpers.getDimensionMap(
            experimentMap,
            experiments,
            dimension.values,
            min,
            max
          );
        });
      });

      return keys(
        pickBy(experimentMap, keyCount => {
          return keyCount === dimensionsWithConstraints.length;
        })
      );
    },
    [dataSources]
  );

  const handleRestyle = useCallback(
    event => {
      if (isChartPreview) return;

      const dimensions: ParallelDataSourceMetric[] = get(
        event,
        [0, 'dimensions', 0],
        null
      );

      // Dimensions has been updated (order)
      if (dimensions) {
        const labelsOrder = dimensions.map(({ label }) => label);

        setLabelsOrder(labelsOrder);
      }

      // here we are relying on that data will be modified by Plotly library,
      // it will modify object by link on it in memory
      const dataDimensions = data?.[0]?.dimensions;
      const isConstraintsRemoved = isEmpty(
        chartHelpers.getConstraintsFromDimensions(dataDimensions)
      );

      if (isConstraintsRemoved) {
        handleLocalClearChartConstraints();
      } else {
        const highlightedExperimentKeys = getHighlightedExperiments(
          dataDimensions
        );
        onMarkExperimentsVisible(chartId, highlightedExperimentKeys);
      }
    },
    [
      isChartPreview,
      data,
      getHighlightedExperiments,
      onMarkExperimentsVisible,
      chartId,
      handleLocalClearChartConstraints
    ]
  );

  return (
    <ChartContainer
      {...containerProps}
      chartContainerRef={containerRef as React.RefObject<HTMLDivElement>}
      isError={isError}
      isFetching={isFetching || hasDelayedDataSources}
      isLoading={isLoading || isInitialRenderDelayed}
      isPreviousData={isPreviousData}
      title={actualTitle}
      hasData={!isEmptyDataSource}
      hiddenMenuItems={hiddenMenuItems}
      onExportJSON={handleExportJSON}
      onExportData={handleExportData}
      onClearChartConstraints={handleClearChartConstraints}
      fullScreenType={'badges'}
    >
      <OptimizedPlot
        {...(PlotlyProps as object)}
        data={data}
        layout={parallelCoordinatesLayout}
        onRestyle={handleRestyle}
      />
    </ChartContainer>
  );
};

ParallelCoordinatesChart.CONFIG_PROPERTIES = [
  'containerProps',
  'chartId',
  'isChartPreview',
  'experiments',
  'experimentKeys',
  'isAutoRefreshEnabled',
  'activeIntervalFetchDelay',
  'title',
  'chartName',
  'onDataSourceChange',
  'onFinishRender',
  'enableDataRevision',
  'isPendingRendering',
  'dataRevision',
  'revision',
  'hiddenMenuItems',
  'onClearChartConstraints',
  'onMarkExperimentsVisible',
  'selectedTargetVariable',
  'metrics',
  'params',
  'selectedDecimalPrecision'
];

export default ParallelCoordinatesChart;
