import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Scene } from '@babylonjs/core/scene';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { usePCDBoxes } from '@experiment-management-shared/api';
import { PCDAssetData } from '@experiment-management-shared/types';
import {
  BoxState,
  MESH_NAMES,
  addBoxes,
  addPointsToScene,
  centerCamera,
  createChangeAnchor,
  initializeScene,
  processJSONLFileInChunks
} from './utils';

import styles from './PCDDetail.module.scss';

export const PCDDetail = ({
  asset,
  hiddenLabelNames,
  score
}: {
  asset: PCDAssetData;
  hiddenLabelNames: string[];
  score: number;
}) => {
  const cameraRef = useRef<ArcRotateCamera | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const dataPointsControllerRef = useRef<AbortController | null>(null);
  const sceneRef = useRef<Scene | null>(null);

  const { data: boxes } = usePCDBoxes({ url: asset?.boxes });

  // Clean up
  useEffect(() => {
    return () => {
      dataPointsControllerRef.current?.abort();
    };
  }, []);

  const streamDataPoints = useMemo(() => {
    dataPointsControllerRef.current?.abort();
    dataPointsControllerRef.current = new AbortController();

    return processJSONLFileInChunks({
      signal: dataPointsControllerRef.current.signal,
      url: asset.points
    });
  }, [asset.points]);

  // Data points effect
  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas || !streamDataPoints) return;

    const { camera, engine, scene } = initializeScene(canvas);
    cameraRef.current = camera;
    sceneRef.current = scene;

    let shouldCenterCamera = true;
    streamDataPoints(async (textPoints: string[]) => {
      await addPointsToScene({ scene, textPoints });

      if (shouldCenterCamera) {
        centerCamera({ camera, scene });
        shouldCenterCamera = false;
      }
    });

    const resize = () => engine.resize();
    const changeAnchor = createChangeAnchor({ camera, scene });

    canvas.addEventListener('dblclick', changeAnchor);
    window.addEventListener('resize', resize);

    return () => {
      canvas.removeEventListener('dblclick', changeAnchor);
      window.removeEventListener('resize', resize);

      scene.dispose();
      engine.dispose();
    };
  }, [streamDataPoints]);

  // Toggling visibility effect (labels + score)
  const updateBoxesVisibility = useCallback(() => {
    if (!sceneRef.current) return;

    const boxMeshes = sceneRef.current.meshes.filter(
      mesh => mesh.name === MESH_NAMES.BOX
    );

    for (const boxMesh of boxMeshes) {
      const { labelName, score: boxScore } = JSON.parse(
        boxMesh.state
      ) as BoxState;

      const isVisible =
        score <= boxScore && !hiddenLabelNames.includes(labelName);

      boxMesh.visibility = isVisible ? 1 : 0;
    }
  }, [hiddenLabelNames, score]);
  useEffect(updateBoxesVisibility, [updateBoxesVisibility]);

  // Boxes effect
  useEffect(() => {
    if (!sceneRef.current || !cameraRef.current || !boxes || isEmpty(boxes)) {
      return;
    }

    addBoxes({ boxes, scene: sceneRef.current });
    centerCamera({ camera: cameraRef.current, scene: sceneRef.current });
    updateBoxesVisibility();
  }, [boxes]);

  return <canvas className={styles.canvas} ref={canvasRef}></canvas>;
};
