import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  COMPARE_EXPERIMENT_1_KEY,
  COMPARE_EXPERIMENT_2_KEY
} from '@experiment-management-shared/constants/experimentConstants';

import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import GraphicsEmptyState from '../GraphicsEmptyState';
import GraphicsGalleryCompare from '../GraphicsGalleryCompare';
import GraphicsLoading from '../GraphicsLoading';
import useExperimentGraphics from '@experiment-details/api/useExperimentGraphics';

const GraphicsCompareTab = ({ experiments }) => {
  const location = useLocation();
  const [experiment] = experiments;

  const {
    [COMPARE_EXPERIMENT_1_KEY]: experimentKey1 = experiments[0].experimentKey,
    [COMPARE_EXPERIMENT_2_KEY]: experimentKey2 = experiments[1].experimentKey
  } = queryString.parse(location.search);
  const experiment1 = experiments.find(e => e.experimentKey === experimentKey1);
  const experiment2 = experiments.find(e => e.experimentKey === experimentKey2);

  const {
    data: experiment1Graphics,
    isLoading: isExperiment1GraphicsLoading
  } = useExperimentGraphics({
    isActive: experiment1.runActive,
    experimentKey: experiment1.experimentKey
  });

  const {
    data: experiment2Graphics,
    isLoading: isExperiment2GraphicsLoading
  } = useExperimentGraphics({
    isActive: experiment2.runActive,
    experimentKey: experiment2.experimentKey
  });

  const allGraphics = useMemo(() => {
    return experiment1Graphics?.concat(experiment2Graphics) || [];
  }, [experiment1Graphics, experiment2Graphics]);

  if (isExperiment1GraphicsLoading || isExperiment2GraphicsLoading) {
    return <GraphicsLoading />;
  }

  if (!allGraphics?.length) {
    return (
      <>
        <CompareContentSelector
          experiments={experiments}
          experiment1={experiment1}
          experiment2={experiment2}
        />
        <GraphicsEmptyState experiment={experiment} isComparePage />
      </>
    );
  }

  return (
    <GraphicsGalleryCompare
      experiments={experiments}
      experiment1={experiment1}
      experiment2={experiment2}
      experimentKey={experiment.experimentKey}
      experiment1Graphics={experiment1Graphics}
      experiment2Graphics={experiment2Graphics}
      graphics={allGraphics}
    />
  );
};

GraphicsCompareTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default GraphicsCompareTab;
