// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperimentDetailsNavBar-module__experimentDetailsNavBar--QjFKr{background-color:#fff;box-shadow:0 4px 4px rgba(0,0,0,.05);padding:0 16px;height:52px;position:relative;z-index:1;display:flex;align-items:center;justify-content:space-between}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentDetailsNavBar": `ExperimentDetailsNavBar-module__experimentDetailsNavBar--QjFKr`
};
module.exports = ___CSS_LOADER_EXPORT___;
