import React from 'react';
import { components, GroupHeadingProps } from 'react-select';
import { OptionType } from '../../../../types';

import ListItemTitle from '../../../lists/ListItemTitle/ListItemTitle';

export type CustomHeadingProps = GroupHeadingProps<OptionType> & {
  onToggleExpand?: () => void;
};
const GroupHeading = (props: CustomHeadingProps) => {
  const propsData = props.data as OptionType;
  const { label, render, isExpanded } = propsData;

  if (render) {
    return (
      <components.GroupHeading {...props}>
        {render(propsData)}
      </components.GroupHeading>
    );
  }

  return (
    <components.GroupHeading {...props}>
      <ListItemTitle
        onClick={props.onToggleExpand}
        type="secondary"
        withIcon={isExpanded !== undefined}
        text={label}
        active={isExpanded}
      />
    </components.GroupHeading>
  );
};

export default GroupHeading;
