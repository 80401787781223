import { Box } from '@material-ui/core';
import { components } from 'react-select';
import React from 'react';
import cx from 'classnames';

const Control = props => (
  <Box
    className={cx('manage-stages-select-control', props.isFocused && 'focused')}
  >
    <components.Control {...props} />
  </Box>
);

export default Control;
