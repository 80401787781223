import styles from './DetectedExperiment.module.scss';
import cx from 'classnames';
import { Button } from '@ds';
import { Loader } from '@design-system-outdated';
import React from 'react';

export interface DetectedExperimentProps {
  handleClickButton: () => void;
  isSuccessfulExperimentRun: boolean;
  title: string;
  dataTest?: string;
  label: string;
  buttonLabel: string;
  isRunExperiment: boolean;
}

export default function DetectedExperiment({
  handleClickButton,
  isSuccessfulExperimentRun,
  title,
  label,
  isRunExperiment,
  buttonLabel,
  dataTest = 'detected-experiment'
}: DetectedExperimentProps) {
  return (
    <>
      <div
        data-test={`${dataTest}-container`}
        className={cx(styles.detectedExperimentContainer, {
          [styles.successfulExperimentRun]: isSuccessfulExperimentRun
        })}
      >
        <div
          className={styles.detectedExperimentContent}
          data-test={`${dataTest}-content`}
        >
          <div
            className={styles.detectedExperimentTitle}
            data-test={`${dataTest}-title`}
          >
            {title}
          </div>
          <div
            className={styles.detectedExperimentLabel}
            data-test={`${dataTest}-label`}
          >
            {label}
          </div>
        </div>

        <div className={styles.detectedExperimentButton}>
          <Button onClick={handleClickButton} data-test={`${dataTest}-button`}>
            {buttonLabel}
          </Button>
        </div>
      </div>
      <Loader show={isRunExperiment} />
    </>
  );
}
