import useProjects from './useProjects';
import { SORT_BY_DIRECTION } from '../../../constants/workspaceConstants';

const useProjectsCount = (): number => {
  // TODO Should be changed by specific BE endpoint /projects/count
  const { data, isLoading } = useProjects({
    filters: {},
    pageSize: 1,
    pageNumber: 0,
    searchText: '',
    sortByDirection: SORT_BY_DIRECTION.ASC,
    sortByProperty: 'projectName'
  });

  if (isLoading) {
    return 0;
  }

  return data?.totalRowCount ?? 0;
};

export default useProjectsCount;
