import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const useClaimInvite = (claimResult, claimStatus) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!claimResult) return;

    if (claimStatus) {
      dispatch(
        alertsUtil.openSnackbarDialog(
          dialogTypes.TEAM_INVITE_CODE_MESSAGE,
          claimResult
        )
      );
    } else {
      dispatch(
        alertsUtil.openErrorDialog(
          'error-snackbar-dialog',
          `Error: ${claimResult}`
        )
      );
    }
  }, [claimResult, claimStatus, dispatch]);
};

export default useClaimInvite;
