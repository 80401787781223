import React from 'react';

import { DebuggerEmptyPanelIcon } from '@Icons-outdated';
import classNames from './DebuggerPage.module.scss';

const DebuggerEmptyDetailsPanel = () => {
  return (
    <div
      data-test="debugger-empty-event-details"
      className={classNames.debuggerEmptyDetails}
    >
      <DebuggerEmptyPanelIcon />
      <span className={classNames.debuggerEmptyDetailsMessage}>
        Select an Event to view the details
      </span>
    </div>
  );
};

export default DebuggerEmptyDetailsPanel;
