import { FullWidthBasicModal } from '@DesignSystem/modals';
import { isNull } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import usePanelTemplate from '@API/panels/usePanelTemplate';
import usePanelTemplates from '@API/panels/usePanelTemplates';
import { PREVIEW_TEMPLATE_ID } from '@experiment-management-shared/constants/visualizationConstants';
import { getURLQueryParams } from '@shared/utils/url';
import {
  CHART_GALLERY_TYPES,
  getBuildInCharts,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';

import { BUILT_IN_CHART_TYPES } from '@/lib/appConstants';
import { getSavedGalleryTab } from '@/reducers/ui/visualizationsUiReducer';
import { AddVisualizationModal } from './AddVisualizationModal';

import GalleryModal from './GalleryModal';
import PanelPreviewDialog from './PanelPreviewDialog';
import { TextButton } from '@ds';
import { DSDocumentationIcon } from '@design-system/icons';

const { BUILT_IN, INTERNAL, WORKSPACE, PUBLIC } = TEMPLATE_SCOPE_TYPES;

const PANEL_NAME_MAP = {
  [BUILT_IN_CHART_TYPES.pcd]: '3D'
};

const PanelModal = props => {
  const location = useLocation();
  const queryParams = getURLQueryParams(location.hash);
  const chartCategory = queryParams.get('chartCategory');
  const chartType = queryParams.get('chartType');
  const chartId = queryParams.get('chartId');
  const instanceId = queryParams.get('instanceId');
  const isPanelSelected = Boolean(chartType);
  const isEditMode = !isNull(chartId);
  const buildInCharts = useSelector(state => getBuildInCharts(state));

  const isCustomVisualization = [
    CHART_GALLERY_TYPES.CUSTOM,
    CHART_GALLERY_TYPES.FEATURED
  ].includes(chartCategory);
  const { data: template } = usePanelTemplate(
    { templateId: chartType },
    { enabled: isCustomVisualization }
  );

  const {
    data: internalTemplates,
    isLoading: isLoadingInternal
  } = usePanelTemplates({ scope: INTERNAL });
  const {
    data: publicTemplates,
    isLoading: isLoadingPublic
  } = usePanelTemplates({ scope: PUBLIC });
  const {
    data: workspaceTemplates,
    isLoading: isLoadingWorkspace
  } = usePanelTemplates({ scope: WORKSPACE });

  const isLoading = {
    [INTERNAL]: isLoadingInternal,
    [PUBLIC]: isLoadingPublic,
    [WORKSPACE]: isLoadingWorkspace
  };

  const savedGalleryTab = useSelector(getSavedGalleryTab);
  const previewTemplateId = queryParams.get(PREVIEW_TEMPLATE_ID);
  const history = useHistory();

  const handleCloseModal = () => {
    history.push({ ...location, hash: '' }, { preventScroll: true });
  };

  const templateByLabelMap = useMemo(() => {
    return {
      [BUILT_IN]: buildInCharts,
      [INTERNAL]: internalTemplates,
      [WORKSPACE]: workspaceTemplates,
      [PUBLIC]: publicTemplates
    };
  }, [internalTemplates, publicTemplates, workspaceTemplates]);

  const templatesByTabLabel = useMemo(
    () => templateByLabelMap[savedGalleryTab] || [],
    [savedGalleryTab, templateByLabelMap]
  );

  const renderContent = () => {
    if (previewTemplateId) {
      if (isLoading[savedGalleryTab]) {
        return null;
      }

      const previewTemplate = templatesByTabLabel.find(
        t => t.id === previewTemplateId
      );

      if (previewTemplate) {
        return <PanelPreviewDialog open template={previewTemplate} />;
      }
    }

    if (isPanelSelected) {
      return <AddVisualizationModal instanceId={instanceId} {...props} />;
    }

    return <GalleryModal />;
  };

  const chartName =
    template?.templateName ?? (PANEL_NAME_MAP[chartType] || chartType);
  const isGridPanel = [
    BUILT_IN_CHART_TYPES.image,
    BUILT_IN_CHART_TYPES.video,
    BUILT_IN_CHART_TYPES.pcd
  ].includes(chartType);
  const action = isEditMode ? 'Edit' : 'Add';
  const type = isGridPanel || !chartType ? 'panel' : 'chart';
  const postfix = isEditMode ? '' : 'to project';
  const modalTitle = `${action} ${chartName || 'new'} ${type} ${postfix}`;
  const showPythonDocBtn = chartType === BUILT_IN_CHART_TYPES.python;

  return (
    <FullWidthBasicModal
      variant="base"
      className="gallery-modal"
      maxWidth="xl"
      title={modalTitle}
      onClose={handleCloseModal}
      withoutFooter
      content={renderContent()}
      open
      disableEnforceFocus
      customHeaderContent={
        showPythonDocBtn ? (
          <div className="python-panel-doc-button">
            <a
              href="/docs/v2/guides/comet-dashboard/code-panels/python-panels/"
              target="_blank"
            >
              <TextButton type="secondary" PrefixIcon={<DSDocumentationIcon />}>
                Python panel doc
              </TextButton>
            </a>
          </div>
        ) : null
      }
    />
  );
};

export default PanelModal;
