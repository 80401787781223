// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VirtualizedList-module__virtualizedBodyContainer--K0ADu{width:100%;overflow-y:auto;contain:strict}.VirtualizedList-module__virtualizedBody--M8qf3{width:100%;position:relative}.VirtualizedList-module__virtualizedItemList--c5D6l{position:absolute;top:0;left:0;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"virtualizedBodyContainer": `VirtualizedList-module__virtualizedBodyContainer--K0ADu`,
	"virtualizedBody": `VirtualizedList-module__virtualizedBody--M8qf3`,
	"virtualizedItemList": `VirtualizedList-module__virtualizedItemList--c5D6l`
};
module.exports = ___CSS_LOADER_EXPORT___;
