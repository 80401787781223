import { isEmpty, last } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useParams } from 'react-router';
import { Prompt } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import { dialogTypes } from '@/constants/alertTypes';
import { DISABLE_SHARE } from '@/constants/configConstants';
import alertsUtil from '@/util/alertsUtil';
import { Button, TextButton } from '@ds';
import {
  DSArrowDownIcon,
  DSCloseIcon,
  DSRunningIcon,
  DSShowIcon
} from '@ds-icons';
import DownloadReportButton from '@reports/components/DownloadReportButton';
import DownloadReportModal from '@reports/components/DownloadReportModal';
import DuplicateReportButton from '@reports/components/DuplicateReportButton';
import EditReportButton from '@reports/components/EditReportButton';
import ReportHistoryPopover from '@reports/components/ReportHistoryPopover';
import ShareReportButton from '@reports/components/ShareReportButton';
import ShareReportModal from '@reports/components/ShareReportModal';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import { ReportEditMode } from '@routes/types';
import { NEW_REPORT_NAME } from '@shared/constants';
import useProjectPageBreadcrumbs from '@shared/hooks/useProjectPageBreadcrumbs';

import styles from './ReportHeader.module.scss';

type ReportRevision = {
  revisionId: string;
};

type ReportHeaderProps = {
  canEdit: boolean;
  canUndo: boolean;
  editMode: ReportEditMode;
  name: string;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
  isTemplate: boolean;
  onChangeEditMode: (editMode: ReportEditMode) => void;
  onDuplicate: () => void;
  onSave: () => void;
  onSelectRevisionId: (revisionId: string) => void;
  onUndo: () => void;
  revisionId: string;
  revisions: ReportRevision[];
};

type ReportHeaderParams = {
  projectName: string;
  reportName: string;
  workspace: string;
};

const ReportHeader = ({
  canEdit,
  canUndo,
  editMode,
  name,
  hasUnsavedChanges,
  isSaving,
  isTemplate,
  onChangeEditMode,
  onDuplicate,
  onSave,
  onSelectRevisionId,
  onUndo,
  revisionId,
  revisions
}: ReportHeaderProps) => {
  const dispatch = useDispatch();
  const {
    projectName,
    reportName,
    workspace
  } = useParams<ReportHeaderParams>();
  const historyButtonRef = useRef<HTMLButtonElement>(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  useProjectPageBreadcrumbs();

  const isDraft = reportName === NEW_REPORT_NAME;

  const handleDownloadClick = () => {
    const downloadReportModal = (
      <DownloadReportModal
        modalId={dialogTypes.DOWNLOAD_REPORT_MODAL}
        name={name}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.DOWNLOAD_REPORT_MODAL,
        downloadReportModal
      )
    );
  };

  const handleShareClick = () => {
    const url = `${window.location.origin}/${workspace}/${projectName}/reports/${reportName}`;
    const shareReportModal = (
      <ShareReportModal modalId={dialogTypes.SHARE_REPORT_MODAL} url={url} />
    );

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.SHARE_REPORT_MODAL,
        shareReportModal
      )
    );
  };

  const handleExitPreviewClick = () => {
    onChangeEditMode(REPORT_EDIT_MODES.EDIT);
  };

  const handlePreviewClick = () => {
    onChangeEditMode(REPORT_EDIT_MODES.PREVIEW);
  };

  const currentRevisionId = revisionId || last(revisions)?.revisionId;
  const shouldDisplayHistory =
    editMode === REPORT_EDIT_MODES.EDIT && !isEmpty(revisions);
  const shouldDisplayShare =
    [REPORT_EDIT_MODES.PREVIEW, REPORT_EDIT_MODES.VIEW].includes(editMode) &&
    !DISABLE_SHARE;
  const shouldSaveChanges = canEdit && editMode !== REPORT_EDIT_MODES.VIEW;

  return (
    <Paper className={styles.header} elevation={4}>
      <div className={styles.breadcrumbsSection}>
        {shouldSaveChanges && (
          <>
            <Prompt
              when={isDraft || hasUnsavedChanges}
              message={({ pathname }) => {
                const isEditingPath = [
                  '/:workspace/:projectName/reports/template/:reportName/:editMode',
                  '/:workspace/:projectName/reports/:reportName/:editMode'
                ].some(path =>
                  matchPath(pathname, {
                    path,
                    exact: true,
                    strict: false
                  })
                );

                if (isEditingPath) return true;

                return 'Are you sure you leave without saving the changes?';
              }}
            />

            {isDraft && <div className={styles.draftLabel}>Draft</div>}
            {!isDraft && hasUnsavedChanges && (
              <div className={styles.draftLabel}>Unsaved Changes</div>
            )}
          </>
        )}
      </div>

      <div className={styles.actionsSection}>
        {shouldDisplayHistory && (
          <>
            <TextButton
              onClick={() => setIsHistoryOpen(true)}
              ref={historyButtonRef}
              type="secondary"
              PostfixIcon={<DSArrowDownIcon />}
            >
              History
            </TextButton>

            <ReportHistoryPopover
              anchorEl={historyButtonRef.current}
              currentRevisionId={currentRevisionId}
              onClose={() => setIsHistoryOpen(false)}
              onItemClick={onSelectRevisionId}
              open={isHistoryOpen}
              revisions={revisions}
            />
          </>
        )}

        {shouldDisplayShare && (
          <ShareReportButton
            onShare={handleShareClick}
            iconStyles={{ fontSize: 16 }}
          />
        )}

        {editMode === REPORT_EDIT_MODES.VIEW && (
          <DownloadReportButton onDownload={handleDownloadClick} />
        )}

        {![REPORT_EDIT_MODES.EDIT, REPORT_EDIT_MODES.PREVIEW].includes(
          editMode
        ) && (
          <EditReportButton
            canEdit={canEdit}
            isTemplate={isTemplate}
            name={reportName}
          />
        )}

        {editMode !== REPORT_EDIT_MODES.PREVIEW && (
          <DuplicateReportButton
            canEdit={canEdit}
            onDuplicate={onDuplicate}
            iconStyles={{ fontSize: 16 }}
          />
        )}

        {editMode === REPORT_EDIT_MODES.EDIT && (
          <TextButton onClick={handlePreviewClick} PrefixIcon={<DSShowIcon />}>
            Preview
          </TextButton>
        )}
        {editMode === REPORT_EDIT_MODES.EDIT && (
          <TextButton
            type="secondary"
            disabled={!canUndo}
            onClick={onUndo}
            PrefixIcon={<DSRunningIcon />}
          >
            Undo
          </TextButton>
        )}
        {editMode === REPORT_EDIT_MODES.EDIT && (
          <Button disabled={isSaving || !hasUnsavedChanges} onClick={onSave}>
            Save
          </Button>
        )}
        {editMode === REPORT_EDIT_MODES.PREVIEW && (
          <Button onClick={handleExitPreviewClick} PrefixIcon={<DSCloseIcon />}>
            Exit Preview
          </Button>
        )}
      </div>
    </Paper>
  );
};

ReportHeader.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  canUndo: PropTypes.bool.isRequired,
  editMode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  onChangeEditMode: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectRevisionId: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired,
  revisionId: PropTypes.string.isRequired,
  revisions: PropTypes.array.isRequired
};

export default ReportHeader;
