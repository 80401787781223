import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { Button, Tooltip } from '@ds';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import useExperiments from '@API/experiments/useExperiments';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import useColumns from '@API/project/useColumns';
import useProject from '@API/project/useProject';
import useSelectedProjectView from '@API/project/useSelectedProjectView';
import useUpsertViewMutation from '@API/project/useUpsertViewMutation';
import GraphicsConfigBar from '@experiment-details/components/Graphics/GraphicsConfigBar';
import GraphicsGallery from '@experiment-details/components/Graphics/GraphicsGallery';
import GraphicsLoading from '@experiment-details/components/Graphics/GraphicsLoading/GraphicsLoading';
import { generatePanelId } from '@experiment-management-shared';
import {
  BUILT_IN_CHART_TYPES,
  CHART_CATEGORIES
} from '@experiment-management-shared/constants/chartConstants';
import {
  EXPERIMENT_TAB_PATHNAME,
  MAX_EXPERIMENTS_PER_PAGE
} from '@experiment-management-shared/constants/experimentConstants';
import { DEFAULT_VIEW } from '@experiment-management-shared/utils/view';
import {
  fromDashboardToTemplate,
  fromTemplateToDashboard
} from '@experiment-management-shared/utils/EMView';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { dialogTypes } from '@/constants/alertTypes';
import AddBuiltInVisualizationModal from '@experiment-management-shared/components/AddBuiltInVisualizationModal';
import alertsUtil from '@/util/alertsUtil';

import GraphicsEmptyState from '../GraphicsEmptyState';
import GraphicsTabConfirmationDialog from '../GraphicsTabConfirmationDialog';
import useDefaultProjectView from '@API/project/useDefaultProjectView';
import { GRAPHICS_ASSETS_FIELDS_MAP } from '@experiment-details/constants/graphics';
import useExperimentGraphics from '@experiment-details/api/useExperimentGraphics';
import { initializeLayout } from '@experiment-management-shared/utils/layout';

import styles from './GraphicsTab.module.scss';

const GraphicsTab = ({ experiments }) => {
  const [experiment] = experiments;
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { experimentKey } = experiment;
  const { data: defaultProjectView } = useDefaultProjectView();
  const [filteredGraphicsAssets, setFilteredGraphicsAssets] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [isOpenImagePanel, setIsOpenImagePanel] = useState(false);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState(
    false
  );
  const { workspace: activeWorkspaceName } = useParams();
  const { data: selectedView } = useSelectedProjectView();
  const [selectedItems, setSelectedItems] = useState([]);
  const { data: project } = useProject();
  const { data: columns } = useColumns(
    { extraCols: true },
    { refetchInterval: false, refetchOnMount: true }
  );

  const { data: graphicAssets, isLoading } = useExperimentGraphics({
    experimentKey: experiment.experimentKey,
    isActive: experiment.runActive
  });

  const upsertViewMutation = useUpsertViewMutation();

  useEffect(() => {
    if (upsertViewMutation.isError && isOpenImagePanel) {
      setIsOpenImagePanel(false);
      setIsOpenConfirmationDialog(false);
      setSelectedItems([]);

      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.ERROR_SAVE_GRAPHICS_COMPARE_PANEL_TO_DEFAULT_VIEW,
          'Image panel could not be added to the default view.'
        )
      );
    }
  }, [upsertViewMutation.isError]);

  useEffect(() => {
    if (upsertViewMutation.isSuccess && isOpenImagePanel) {
      setIsOpenImagePanel(false);
      setIsOpenConfirmationDialog(true);
    }
  }, [upsertViewMutation.isSuccess]);

  const handleAddBuiltInPanel = panel => {
    const chartId = generatePanelId();

    const defaultProjectViewParsed = fromTemplateToDashboard(
      defaultProjectView,
      DEFAULT_VIEW,
      columns
    );

    defaultProjectViewParsed.panels.sections[0].panels = [
      ...defaultProjectViewParsed.panels.sections[0].panels,
      { ...panel, chartId }
    ];

    defaultProjectViewParsed.panels.sections[0].layout = initializeLayout({
      panels: defaultProjectViewParsed.panels.sections[0].panels,
      initialLayout: defaultProjectViewParsed.panels.sections[0].layout
    });

    const viewToSave = fromDashboardToTemplate({
      dashboard: defaultProjectViewParsed,
      project,
      selectedView
    });

    upsertViewMutation.mutate({
      view: {
        ...viewToSave,
        createdFromTemplateId: defaultProjectView.templateId,
        templateId: defaultProjectView.templateId,
        templateName: !defaultProjectView.templateId
          ? 'Unsaved Changes'
          : defaultProjectView.templateName,
        unsavedView: true
      }
    });
  };

  const {
    data: { experiments: allExperiments = [], total } = {}
  } = useExperiments(
    {
      isArchive: false,
      view: {
        table: {
          columnSorting: [],
          columnOrders: [],
          pageSize: MAX_EXPERIMENTS_PER_PAGE,
          pageNumber: 0
        }
      },
      viewId: defaultProjectView?.templateId
    },
    { refetchOnMount: true }
  );

  const selectedGraphicsNames = useMemo(
    () =>
      graphicAssets?.reduce((acc, image) => {
        if (selectedItems.includes(image?.[GRAPHICS_ASSETS_FIELDS_MAP.id])) {
          if (!acc.includes(image?.[GRAPHICS_ASSETS_FIELDS_MAP.name])) {
            acc.push(image?.[GRAPHICS_ASSETS_FIELDS_MAP.name]);
          }
        }

        return acc;
      }, []),
    [selectedItems, graphicAssets]
  );

  const onGraphicAssetsFilter = (newFilteredGraphicAssets, groupedBy) => {
    setFilteredGraphicsAssets(newFilteredGraphicAssets);
    setGroupBy(groupedBy);
  };

  const allExperimentKeys = useMemo(
    () => allExperiments.map(exp => exp.experimentKey),
    [allExperiments]
  );

  if (isLoading) {
    return <GraphicsLoading />;
  }

  const isTabEmpty = !graphicAssets?.length;

  return (
    <div className={styles.galleryTabContainer}>
      <GraphicsTabConfirmationDialog
        onPrimaryButtonClick={() => {
          history.push(
            `/${activeWorkspaceName}/${project.projectName}/view/${
              defaultProjectView.templateId || 'new'
            }#${EXPERIMENT_TAB_PATHNAME.panels}`
          );
          setIsOpenConfirmationDialog(false);
        }}
        onSecondaryButtonClick={() => {
          setIsOpenConfirmationDialog(false);
          setSelectedItems([]);
        }}
        onClose={() => {
          setIsOpenConfirmationDialog(false);
          setSelectedItems([]);
        }}
        open={isOpenConfirmationDialog}
      />
      <FullWidthBasicModal
        variant="base"
        onClose={() => {
          setIsOpenImagePanel(false);
          setSelectedItems([]);
        }}
        className={cx('gallery-modal')}
        maxWidth="xl"
        withoutFooter
        open={isOpenImagePanel}
        title="Create Image Panel"
        disableEnforceFocus
        content={
          upsertViewMutation.isLoading ? (
            <GraphicsLoading />
          ) : (
            <AddBuiltInVisualizationModal
              hideGoBackButton
              onAddBuiltInPanel={handleAddBuiltInPanel}
              chartForm={{
                [BUILT_IN_CHART_TYPES.image]: {
                  chartName: 'Grid title',
                  confidenceScore: null,
                  experimentsCount: total,
                  filteredAnnotations: [],
                  images: selectedGraphicsNames
                }
              }}
              experimentKeys={allExperimentKeys}
              experiments={allExperiments}
            />
          )
        }
      />
      <GraphicsConfigBar
        experimentKey={experimentKey}
        graphicAssets={graphicAssets}
        onGraphicAssetsFilter={onGraphicAssetsFilter}
        isTabEmpty={isTabEmpty}
        actions={
          <Tooltip
            content={
              !selectedItems?.length
                ? 'Select images to compare them across multiple experiments'
                : 'Compare images across multiple experiments by creating an image panel in the project panels page'
            }
          >
            <Button
              disabled={!selectedItems?.length}
              onClick={() => {
                dispatch(
                  dashboardChartsActions.updateChartFormKey(
                    BUILT_IN_CHART_TYPES.image,
                    'images',
                    selectedGraphicsNames
                  )
                );

                setIsOpenImagePanel(true);
                history.replace(
                  `${pathname}${search}#&chartCategory=${CHART_CATEGORIES.BUILT_IN}&chartType=${BUILT_IN_CHART_TYPES.image}`
                );
              }}
            >
              Compare
            </Button>
          </Tooltip>
        }
      />

      {isTabEmpty ? (
        <GraphicsEmptyState />
      ) : (
        <GraphicsGallery
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          groupBy={groupBy}
          filteredGraphicsAssets={filteredGraphicsAssets}
          graphicsAssets={graphicAssets}
          experiment={experiment}
        />
      )}
    </div>
  );
};

GraphicsTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default GraphicsTab;
