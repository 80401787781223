import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { CHART_AXIS_STYLING } from '@experiment-management-shared/constants';

const useChartLayout = ({
  selectedXAxis,
  yAxisRange,
  orientation,
  initialLayout,
  setInitialLayout
}) => {
  const prevSelectedXAxisRef = useRef(selectedXAxis);

  const [currentLayout, setCurrentLayout] = useState(null);
  const handleResetChartLayout = useCallback(() => {
    setCurrentLayout(null);
  }, []);

  useEffect(() => {
    prevSelectedXAxisRef.current = selectedXAxis;

    handleResetChartLayout();
  }, [selectedXAxis, handleResetChartLayout]);

  const xAxisFormat = useMemo(() => {
    return {
      ...CHART_AXIS_STYLING
    };
  }, []);

  useEffect(() => {
    handleResetChartLayout();
  }, [orientation, handleResetChartLayout]);

  const getBaseLayout = useCallback(
    (baseLayout = null) => {
      if (isEmpty(baseLayout)) {
        return {
          autosize: true,
          xaxis: xAxisFormat,
          yaxis: {
            ...yAxisRange,
            ...CHART_AXIS_STYLING
          },
          showlegend: false,
          margin: { b: 0, l: 0, r: 0, t: 8, pad: 12 }
        };
      }
      return baseLayout;
    },
    [xAxisFormat, yAxisRange]
  );

  const baseLayout = useMemo(() => {
    return getBaseLayout(currentLayout);
  }, [currentLayout, getBaseLayout]);

  const hasSelectedXAxisChanged = () => {
    return selectedXAxis !== prevSelectedXAxisRef.current;
  };

  const chartBaseLayout = hasSelectedXAxisChanged()
    ? getBaseLayout()
    : baseLayout;

  const handleSaveLayout = useCallback(
    figure => {
      if (isEmpty(initialLayout)) {
        const clonedInitialLayout = cloneDeep(figure.layout);
        const clonedUpdatedLayout = cloneDeep(figure.layout);

        setInitialLayout(clonedInitialLayout);
        setCurrentLayout(clonedUpdatedLayout);
      } else if (isEmpty(currentLayout)) {
        const clonedLayout = cloneDeep(figure.layout);

        setCurrentLayout(clonedLayout);
      }
    },
    [currentLayout, initialLayout, setInitialLayout]
  );

  return {
    setCurrentLayout,
    currentLayout,
    chartBaseLayout,
    handleSaveLayout
  };
};

export default useChartLayout;
