// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportName-module__name--XGPQj{align-items:center;display:flex;margin-bottom:16px;padding:0 21px;width:100%}.ReportName-module__name--XGPQj .MuiFormControl-root{width:100%}.ReportName-module__name--XGPQj input{font-size:30px;font-weight:500;padding:8px}.ReportName-module__name--XGPQj .ReportName-module__valid--rH57k{margin-left:16px}.ReportName-module__name--XGPQj.ReportName-module__viewOnly--F2lf8{color:#000;font-size:30px;font-weight:500;padding:8px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": `ReportName-module__name--XGPQj`,
	"valid": `ReportName-module__valid--rH57k`,
	"viewOnly": `ReportName-module__viewOnly--F2lf8`
};
module.exports = ___CSS_LOADER_EXPORT___;
