export const STRINGIFY_OPTIONS = {
  skipNull: true,
  arrayFormat: 'comma',
  skipEmptyString: true
};

export const PARSE_OPTIONS = {
  arrayFormat: 'comma',
  parseBooleans: true
};
