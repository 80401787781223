import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    ...state.user,
    username: state.user.username ? state.user.username : ''
  };
};

const withUser = connect(mapStateToProps);

export default withUser;
