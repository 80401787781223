import { useEffect, useMemo, useState } from 'react';
import { Metric } from '@shared/types';
import { isStringInSearchText } from '@/helpers/generalHelpers';
import { orderBy } from 'lodash';
import { PARAM_ACTION_COLUMN } from '@experiment-details/components/params/helpers';
import { generateRegexFromString } from '@shared/utils/displayHelpers';

type UseMetricRowDataOptions = {
  items: Metric[];
  searchText: string;
};

const useMetricRowData = ({ items, searchText }: UseMetricRowDataOptions) => {
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = useState<
    string[]
  >([]);

  const rowList = useMemo(() => {
    const searchRegex = generateRegexFromString(searchText);

    const rowsUnsorted = items.filter(item => {
      if (searchRegex) {
        return searchRegex.test(item.name);
      }

      return isStringInSearchText(item.name, searchText);
    });

    return orderBy(rowsUnsorted, ['editable'], ['desc']);
  }, [items, searchText]);

  const hasEditableRows = rowList.some(row => row.editable);

  useEffect(() => {
    if (!hasEditableRows) {
      setDefaultHiddenColumnNames([PARAM_ACTION_COLUMN]);
      return;
    }

    setDefaultHiddenColumnNames([]);
  }, [hasEditableRows]);

  return {
    rowData: rowList,
    defaultHiddenColumnNames
  };
};

export default useMetricRowData;
