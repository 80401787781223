import { Metric } from '@shared/types';
import { useMemo } from 'react';
import { maxRowDecimals } from '@shared/utils/decimalUtils';
import useExperimentViewState from '@experiment-management-shared/hooks/useExperimentViewState';
import { getMetricChartsTemplate } from '@/reducers/experimentDetails/chartsReducer';
import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';
import { useSelector } from 'react-redux';

type useMetricDecimalsPrecisionOptions = {
  items: Metric[];
  experimentKey: string;
};
const useMetricDecimalsPrecision = ({
  items,
  experimentKey
}: useMetricDecimalsPrecisionOptions) => {
  const currentTemplate = useSelector(getMetricChartsTemplate);
  const maxDecimalsInItems = useMemo(
    () => Math.max(...items.map(maxRowDecimals), 0),
    [items]
  );

  const [decimalsPrecision, setDecimalsPrecision] = useExperimentViewState({
    experimentKey,
    queryStringParameter: 'decimals',
    template: currentTemplate,
    valuePath: `${EXPERIMENT_VIEW_TAB_FIELDS.METRICS}.decimalsPrecision`
  });

  const onChangeDecimalsPrecision = (value: number) => {
    const precision = value === maxDecimalsInItems ? null : value;

    setDecimalsPrecision(precision);
  };

  return {
    decimalsPrecision,
    maxDecimalsInItems,
    onChangeDecimalsPrecision
  };
};

export default useMetricDecimalsPrecision;
