// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DisparateValueBox-module__boxWrapper--yYCd9{display:flex;flex-direction:column;border-radius:5px;border:1px solid var(--gray-3);background:var(--white);width:267px;height:172px;padding:32px}.DisparateValueBox-module__boxValue--rpTej{color:var(--black);text-align:center;font-size:48px;font-weight:300;line-height:64px}.DisparateValueBox-module__boxMessage--E3LB0{color:var(--gray-06);text-align:center;font-size:18px;font-weight:300;line-height:27px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxWrapper": `DisparateValueBox-module__boxWrapper--yYCd9`,
	"boxValue": `DisparateValueBox-module__boxValue--rpTej`,
	"boxMessage": `DisparateValueBox-module__boxMessage--E3LB0`
};
module.exports = ___CSS_LOADER_EXPORT___;
