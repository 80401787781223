// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectPageWrapper-module__contentContainer--bu57F{background-color:var(--background-color);display:flex;flex:1;flex-direction:column}.ProjectPageWrapper-module__contentContainer--bu57F.ProjectPageWrapper-module__fixedHeight--O5K1W{height:calc(100vh - var(--navigation-header-height) - var(--header-height, 0));position:relative;overflow-y:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `ProjectPageWrapper-module__contentContainer--bu57F`,
	"fixedHeight": `ProjectPageWrapper-module__fixedHeight--O5K1W`
};
module.exports = ___CSS_LOADER_EXPORT___;
