import { useQuery } from 'react-query';
import { useActiveWorkspace } from '@shared/hooks';

import { normalizeTemplateForGallery } from '@experiment-management-shared/utils/visualizationsHelper';

import { axiosInstance, cancelWrapper } from '../api';

const getPanelTemplates = async ({ scope, teamId }) =>
  cancelWrapper(async cancelToken => {
    const {
      data: { templates }
    } = await axiosInstance.get('code-visualizations/template/get-all', {
      cancelToken,
      params: { scope, teamId }
    });

    return templates.map(template =>
      normalizeTemplateForGallery(template, scope)
    );
  });

export default function usePanelTemplates({ scope }) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['panelTemplates', { teamId: activeWorkspace?.id, scope }],
    () => getPanelTemplates({ teamId: activeWorkspace?.id, scope })
  );
}
