import React, { useCallback } from 'react';
import visualizationsActions from '@/actions/visualizationsActions';
import { useDispatch } from 'react-redux';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import ThumbnailModal from '../ThumbnailModal';
import { iFrameConfig } from '@experiment-management-shared/constants';
import { isValidMessage } from '@experiment-management-shared/utils/visualizationsHelper';
import {
  PanelScopeType,
  SendScreenshotMessage
} from '@experiment-management-shared/types';

type SaveThumbnailRedirect = (image: Blob) => void;
type OpenThumbnailModal = (
  image: string,
  saveHandler: (file: Blob) => void
) => void;
type ClearThumbnail = () => void;
type OnReceiveMessage = (event: SendScreenshotMessage) => void;
type OpenModal = (shouldRedirect?: boolean) => void;

interface ThumbnailActions {
  clearThumbnail: ClearThumbnail;
  onReceiveMessage: OnReceiveMessage;
  openModal: OpenModal;
}

export default function useThumbnailActions(
  templateId: string,
  scopeType?: PanelScopeType
): ThumbnailActions {
  const dispatch = useDispatch();

  const onSaveThumbnail = useCallback(
    async (croppedImage: Blob) => {
      const localImageSrc = window.URL.createObjectURL(croppedImage);

      dispatch(visualizationsActions.setThumbnail(localImageSrc));

      if (templateId) {
        return dispatch(
          visualizationsActions.uploadThumbnail(templateId, croppedImage)
        );
      }
    },
    [templateId]
  );

  const onSaveThumbnailRedirect: SaveThumbnailRedirect = useCallback(
    croppedImage => {
      onSaveThumbnail(croppedImage)?.then?.(() => {
        window.close();
      });
    },
    [onSaveThumbnail]
  );

  const openThumbnailModal: OpenThumbnailModal = useCallback(
    (image, saveHandler) => {
      const thumbnailModal = (
        <ThumbnailModal
          capture={image}
          modalId={dialogTypes.SAVE_PROJECT_DASHBOARD_TEMPLATE}
          onSave={saveHandler}
        />
      );

      dispatch(
        alertsUtil.openCustomModal(
          dialogTypes.SAVE_PROJECT_DASHBOARD_TEMPLATE,
          thumbnailModal
        )
      );
    },
    []
  );

  const onReceiveMessage: OnReceiveMessage = useCallback(
    event => {
      const { data, origin } = event;
      const { messageTypes } = iFrameConfig;

      if (isValidMessage(origin, scopeType)) {
        const { messageType, ...payload } = data;

        if (messageType === messageTypes.SEND_SCREENSHOT) {
          const { shouldRedirectToGallery, image } = payload;

          const saveHandler = shouldRedirectToGallery
            ? onSaveThumbnailRedirect
            : onSaveThumbnail;

          openThumbnailModal(image, saveHandler);
        }
      }
    },
    [onSaveThumbnailRedirect, onSaveThumbnail, scopeType]
  );

  const clearThumbnail: ClearThumbnail = useCallback(() => {
    dispatch(visualizationsActions.clearThumbnail());
  }, []);

  const openModal: OpenModal = useCallback(
    (shouldRedirectToGallery = false) => {
      const { messageTypes, iframeId } = iFrameConfig;

      const codePreviewIframe = document.getElementById(
        iframeId
      ) as HTMLIFrameElement;

      if (!codePreviewIframe || !codePreviewIframe?.srcdoc) {
        const saveHandler = shouldRedirectToGallery
          ? onSaveThumbnailRedirect
          : onSaveThumbnail;

        openThumbnailModal('', saveHandler);

        return;
      }

      // Since we are using a sandboxed iframe without the allow-same-origin
      // attribute, the recipient iframe's origin will be null, so we have to set
      // the target origin as '*' for the iframe to receive the message.
      // This is safe in this case since we aren't sending senstive data.
      codePreviewIframe.contentWindow?.postMessage(
        {
          messageType: messageTypes.TAKE_SCREENSHOT,
          shouldRedirectToGallery
        },
        '*'
      );
    },
    [openThumbnailModal, onSaveThumbnail, onSaveThumbnailRedirect]
  );

  return {
    clearThumbnail,
    onReceiveMessage,
    openModal
  };
}
