import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import {
  LegendItem,
  LegendSortMap,
  LegendMetadataKeys,
  PanelCometMetadata,
  PanelCometMetadataLegendKey,
  PanelCometMetadataType,
  PanelHoverData,
  PanelParamValue,
  PanelTrace,
  GroupLineRange
} from '@experiment-management-shared/types';
import isArray from 'lodash/isArray';
import { changeColorOpacity } from '@shared';
import { getFormattedPlotlyData } from '@experiment-management-shared/components/Charts/ChartHelpers';
import {
  MAX_LEGEND_LENGTH,
  MAX_LEGEND_LENGTH_EXPORT,
  TRACE_NAME_MAP
} from '@experiment-management-shared/constants';

import { truncateByMaxCharacters } from '@shared/utils/displayHelpers';

export const getCometMetadataType = (
  trace: PanelTrace
): PanelCometMetadataType => {
  return get(trace, 'cometMetadataType', 'metric');
};

export const getGroupByParamName = (
  cometMetadata?: PanelCometMetadata
): string => {
  return get(cometMetadata, 'paramGroupKeys[0].title', '');
};
export const getGroupByParamValue = (
  cometMetadata?: PanelCometMetadata
): PanelParamValue => {
  return get(cometMetadata, 'paramGroupKeys[0].value', '');
};
export const getMetricName = (cometMetadata?: PanelCometMetadata): string => {
  return get(cometMetadata, 'metricName', '');
};
export const getExperimentKey = (
  cometMetadata?: PanelCometMetadata
): string => {
  return get(cometMetadata, 'experimentKey', '');
};

export const getHighlightProperties = (
  trace: PanelTrace,
  hoverData: PanelHoverData,
  keys: string[]
) => {
  const opacity = getTraceOpacity(
    getIsHighlightedTrace(
      getHoverParamsFromCometMetadata(trace?.cometMetadata),
      hoverData,
      keys
    )
  );

  if (trace.fill === 'tonexty') {
    const color = changeColorOpacity(trace?.line?.color ?? '', opacity);
    return {
      fillcolor: color,
      line: {
        ...trace?.line,
        color
      }
    };
  }

  return {
    opacity
  };
};

export const getIsHighlightedTrace = (
  group1: Record<string, string | number | undefined | null>,
  group2: Record<string, string | number | undefined | null>,
  keys: string[]
): boolean => {
  for (const key of keys) {
    if (group1[key] !== group2[key]) {
      return false;
    }
  }

  return true;
};

export const getTraceOpacity = (condition: boolean) => (condition ? 1 : 0.2);

export const getHoverParamsFromCometMetadata = (
  cometMetadata?: PanelCometMetadata
) => {
  return {
    experimentKey: getExperimentKey(cometMetadata),
    metricName: getMetricName(cometMetadata),
    paramValue: getGroupByParamValue(cometMetadata),
    paramName: getGroupByParamName(cometMetadata)
  };
};

export const generateLegendKeysString = (
  legendKeys?: PanelCometMetadataLegendKey[]
) => {
  return isArray(legendKeys) && legendKeys.length > 0
    ? ` - ${legendKeys
        .map(({ value }) => getFormattedPlotlyData(value))
        .join(' - ')}`
    : '';
};

export const generateLegendLabelForLineChart = (
  cometMetadataType: PanelCometMetadataType,
  cometMetadata?: PanelCometMetadata,
  isMultiMetricsChart = false,
  groupingRange?: GroupLineRange
) => {
  const { metricName, paramName, paramValue } = getHoverParamsFromCometMetadata(
    cometMetadata
  );

  const {
    experimentName,
    legendKeys,
    groupName
  } = cometMetadata as PanelCometMetadata;

  let value = '';

  switch (cometMetadataType) {
    case 'experiment_metric':
      value = `${metricName}${generateLegendKeysString(legendKeys)}`;
      break;
    case 'metric':
      if (isMultiMetricsChart) {
        value = `${metricName} - ${experimentName}${generateLegendKeysString(
          legendKeys
        )}`;
      } else {
        value = `${experimentName}${generateLegendKeysString(legendKeys)}`;
      }
      break;
    case 'group_by_metric':
      value = `${groupName} ${metricName}`;
      if (groupingRange && groupingRange != 'none') {
        value += ` (${TRACE_NAME_MAP[groupingRange]['lowerBound']}, ${TRACE_NAME_MAP[groupingRange]['upperBound']})`;
      }
      break;
    case 'group_by_param':
      value = `${metricName} - ${paramName}: ${paramValue}`;
      break;
  }

  return value;
};

export const generateLegendLabelForScatterChart = (
  cometMetadata?: PanelCometMetadata
) => {
  const { experimentName, legendKeys } = cometMetadata as PanelCometMetadata;

  return `${experimentName}${generateLegendKeysString(legendKeys)}`;
};

export const truncateLegendValue = (value: string) => {
  let processedValue = value;
  let fullLabel = '';

  if (processedValue.length > MAX_LEGEND_LENGTH) {
    fullLabel = processedValue;
    processedValue = truncateByMaxCharacters(processedValue, MAX_LEGEND_LENGTH);
  }
  return {
    fullLabel,
    processedValue
  };
};

export const truncateLegendValueForExport = (value: string) => {
  return value.length > MAX_LEGEND_LENGTH_EXPORT
    ? truncateByMaxCharacters(value, MAX_LEGEND_LENGTH_EXPORT)
    : value;
};

export const generateSortLegendItemsFunction = (
  rules: {
    property: LegendMetadataKeys;
    map: LegendSortMap;
  }[]
) => {
  return (i1: LegendItem, i2: LegendItem): number => {
    const { property, map } = rules[0];

    const index1 = map[i1.metaData?.[property] ?? ''] ?? 0;
    const index2 = map[i2.metaData?.[property] ?? ''] ?? 0;

    return rules.length > 1 && index1 == index2
      ? generateSortLegendItemsFunction(rules.slice(1))(i1, i2)
      : index1 - index2;
  };
};

export const generateGuaranteeUniquenessFunction = () => {
  const uniqueMap: Record<string, number> = {};

  return (value: string) => {
    if (isUndefined(uniqueMap[value])) {
      uniqueMap[value] = 0;
      return value;
    }

    uniqueMap[value] += 1;
    return `${value}(${uniqueMap[value]})`;
  };
};
