import {
  REQUEST_INVITED_PROJECT_USERS,
  RECEIVE_INVITED_PROJECT_USERS
} from '../actions/invitedProjectUsers';

const invitedProjectUsers = (state = { invitedProjectUsers: {} }, action) => {
  switch (action.type) {
    case REQUEST_INVITED_PROJECT_USERS:
      return { ...state, loading: true };
    case RECEIVE_INVITED_PROJECT_USERS: {
      return {
        ...state,
        loading: false,
        invitedProjectUsers: action.invitedProjectUsers || {}
      };
    }
    default:
      return state;
  }
};

export default invitedProjectUsers;
