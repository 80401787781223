import { loadStripe } from '@stripe/stripe-js/pure';
import { IS_ONLINE_MODE } from '@/lib/appConstants';

class StripeService {
  constructor() {
    const stripeApiKey = process.env.STRIPE_API_KEY;

    if (stripeApiKey && IS_ONLINE_MODE) {
      this.stripeApiKey = stripeApiKey;
      this.stripePromise = null;
      this.stripePromise = loadStripe(this.stripeApiKey);
    } else if (IS_ONLINE_MODE) {
      console.error('Missing environment variable STRIPE_API_KEY');
    }
  }
}

export default new StripeService();
