import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

const backgroundColor = '#ffffff';

const useStyles = makeStyles({
  tooltip: props => ({
    backgroundColor,
    color: 'rgba(0, 0, 0, 0.87)',
    padding: props.padding,
    fontSize: '14px',
    minWidth: props.minWidth,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.12)',
    border: 'solid 1px #e2e2e2'
  }),
  arrow: () => ({
    color: backgroundColor
  })
});

const StyledTooltip = ({ minWidth, padding, children, ...otherProps }) => {
  const classes = useStyles({ minWidth, padding });
  return (
    <Tooltip classes={classes} {...otherProps}>
      {children}
    </Tooltip>
  );
};

StyledTooltip.defaultProps = {
  minWidth: 'none',
  padding: '20px'
};

StyledTooltip.propTypes = {
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

export default StyledTooltip;
