import React, { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { Box } from '@material-ui/core';

import {
  ModelOverviewIcon,
  CustomMetricsIcon,
  SegmentsIcon
} from '@Icons-outdated';
import ModelSettings from './ModelSettings';
import CustomMetrics from './CustomMetrics';
import ModelOverview from './ModelOverview';
import Segments from './Segments';
import { NAV_ITEMS_VALUES } from '../../constants';
import './CustomTransformationsPage.scss';
import { ModelType } from '@mpm/types';

type CustomTransformationsPageProps = {
  model: ModelType;
};

type NavItem = {
  label: string;
  prefixIcon: React.ReactNode;
  value: string;
};

export const CustomTransformationsPage = ({
  model
}: CustomTransformationsPageProps) => {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const { panel } = queryParams;

  const navItems = useMemo(() => {
    return [
      {
        label: 'Model overview',
        value: NAV_ITEMS_VALUES.MODEL_OVERVIEW,
        prefixIcon: ModelOverviewIcon
      },
      {
        label: 'Custom metrics',
        value: NAV_ITEMS_VALUES.CUSTOM_METRICS,
        prefixIcon: CustomMetricsIcon
      },
      {
        label: 'Segments',
        value: NAV_ITEMS_VALUES.SEGMENTS,
        prefixIcon: SegmentsIcon
      }
    ];
  }, []);

  const [activeItem, setActiveItem] = useState(() => {
    return panel === NAV_ITEMS_VALUES.SEGMENTS
      ? navItems[2].value
      : navItems[0].value;
  });
  const { createdAt, name, distributionId, id } = model;
  const handleNavChange = (menuItem: NavItem) => {
    setActiveItem(menuItem.value);
  };

  const renderActivePanel = useCallback(
    param => {
      switch (param) {
        case NAV_ITEMS_VALUES.MODEL_OVERVIEW:
          return (
            <ModelOverview createdAt={createdAt} modelId={id} name={name} />
          );
        case NAV_ITEMS_VALUES.CUSTOM_METRICS:
          return <CustomMetrics />;
        case NAV_ITEMS_VALUES.SEGMENTS:
          return <Segments />;
        default:
          return <CustomMetrics />;
      }
    },
    [createdAt, name, distributionId, id]
  );

  return (
    <Box className="custom-transformation-wrapper">
      <ModelSettings
        navItems={navItems}
        activeItem={activeItem}
        handleNavChange={handleNavChange}
      />
      <Box className="right-panel-wrapper">{renderActivePanel(activeItem)}</Box>
    </Box>
  );
};
