import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

import useExperimentModels from '@shared/api/useExperimentsModels';
import useQueryParamsForTab from '@shared/hooks/useQueryParamsForTab';
import EmptyTabMessage from '@model-registry/components/EmptyTabMessage';

import AssetsStorage from '@experiment-management-shared/components/AssetsStorage';
import SmallLoader from '@shared/components/SmallLoader';

const DetailsAssetsTab = ({
  selectedVersion,
  location,
  assets,
  isLoadingAssets
}) => {
  const { experimentKey } = selectedVersion?.experimentModel || {};
  const {
    data: experimentModels = [],
    isLoading: isLoadingModel
  } = useExperimentModels(experimentKey);

  const isLoading = isLoadingAssets && isLoadingModel;
  // A weird thing happens here, probably this functionality should be revised in the future
  const experiment = experimentModels[0] || {};

  if (isLoading) {
    return <SmallLoader primaryMessage="Loading..." />;
  }

  return (
    <AssetsStorage
      showDirActions={false}
      isLoading={isLoading}
      assets={assets}
      useQueryParams={useQueryParamsForTab}
      experiment={experiment}
      experimentModels={experimentModels}
      location={location}
      emptyRowsComponent={<EmptyTabMessage type="assets" />}
    />
  );
};

DetailsAssetsTab.defaultProps = {
  assets: [],
  selectedVersion: {},
  location: {}
};

DetailsAssetsTab.propTypes = {
  assets: PropTypes.arrayOf(),
  isLoadingAssets: PropTypes.bool.isRequired,
  selectedVersion: PropTypes.shape({
    experimentModel: PropTypes.shape({
      models: PropTypes.array,
      experimentKey: PropTypes.string
    }),
    stages: PropTypes.arrayOf(PropTypes.string),
    restApiUrl: PropTypes.string,
    userName: PropTypes.string,
    comment: PropTypes.string,
    version: PropTypes.string,
    registryModelItemId: PropTypes.string
  }),
  location: PropTypes.object
};

export default withRouter(DetailsAssetsTab);
