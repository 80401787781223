import get from 'lodash/get';
import { paymentsActionTypes } from '../constants/actionTypes';

const initialState = {
  settings: {
    fetching: false,
    loading: false
  },
  onboarding: {
    payNow: false,
    plan: null,
    workspaceName: null,
    collaborators: [],
    trial: true,
    completed: false,
    isStartupFree: false
  }
};

const paymentsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === paymentsActionTypes.PURCHASE_ACTION_PLAN_FOR_ONBOARDING) {
    const { plan } = payload;
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        payNow: true,
        trial: false,
        plan
      }
    };
  }
  if (type === paymentsActionTypes.UPDATE_PAYMENT_PLAN_FOR_NEW_TEAM) {
    const { plan, teamName, teamId } = payload;
    return {
      ...state,
      paymentPlan: {
        ...state.paymentPlan,
        user_plan: plan,
        teamName,
        teamId
      }
    };
  }
  if (type === paymentsActionTypes.SUBMIT_FINAL_ONBOARDING_STEP) {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        completed: true
      }
    };
  }
  if (type === paymentsActionTypes.FETCH_WORKSPACE_PAYMENT_SETTINGS) {
    return {
      ...state,
      settings: {
        loading: true,
        fetching: true
      },
      onboarding: {
        ...state.onboarding,
        completed: true
      }
    };
  }
  if (type === paymentsActionTypes.RECEIVED_WORKSPACE_PAYMENT_SETTINGS) {
    const { settings } = payload;
    return {
      ...state,
      settings: {
        loading: false,
        fetching: false,
        ...settings
      },
      onboarding: {
        ...state.onboarding,
        completed: true
      }
    };
  }
  if (type === paymentsActionTypes.EDIT_WORKSPACE_NAME) {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        workspaceName: null
      }
    };
  }
  if (type === paymentsActionTypes.SAVE_PAYMENT_PLAN_FOR_ONBOARDING) {
    const { plan, isStartupFree = false } = payload;
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        plan,
        isStartupFree
      }
    };
  }
  if (type === paymentsActionTypes.ADD_ONBOARDED_COLLABORATOR) {
    const { collaboratorEmail } = payload;
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        collaborators: [...state.onboarding.collaborators, collaboratorEmail]
      }
    };
  }
  if (type === paymentsActionTypes.SAVE_WORKSPACE_NAME_FOR_ONBOARDING) {
    const { workspaceName } = payload;
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        workspaceName
      }
    };
  }
  if (type === paymentsActionTypes.REVERT_FROM_PAYMENT_TO_NEW_WORKSPACE) {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        completed: false
      }
    };
  }
  if (type === paymentsActionTypes.ADD_PAYMENT_PLAN_FROM_MODAL) {
    return {
      ...state,
      paymentPlan: action.paymentInfo,
      isPaymentSuccessBtnVisible: true
    };
  }
  if (type === paymentsActionTypes.ADD_PAYMENT_PLAN_FROM_PAGE) {
    return {
      ...state,
      paymentPlan: action.paymentInfo,
      isPaymentSuccessBtnVisible: true
    };
  }
  if (type === paymentsActionTypes.CLEAR_PAYMENT_MESSAGES) {
    return {
      ...state,
      errorMessage: ''
    };
  }
  if (type === paymentsActionTypes.SAVE_TEAM_NAME_FOR_STRIPE) {
    return {
      ...state,
      newTeamName: payload
    };
  }
  if (type === paymentsActionTypes.SAVE_BILLING_EMAIL_FOR_STRIPE) {
    return {
      ...state,
      newBillingEmail: payload
    };
  }
  if (type === paymentsActionTypes.SAVE_TEAM_SEAT_AMOUNT_FOR_STRIPE) {
    return {
      ...state,
      newTeamSeatAmount: payload
    };
  }
  if (type === paymentsActionTypes.RECEIVED_VALID_TEAM_NAME) {
    return {
      ...state,
      validTeamName: payload,
      errorTeamNameMessage: ''
    };
  }
  if (type === paymentsActionTypes.ERROR_RECEIVED_INVALID_TEAM_NAME) {
    return {
      ...state,
      validTeamName: '',
      errorTeamNameMessage: payload
    };
  }
  if (type === paymentsActionTypes.UPDATE_TENATIVE_UPGRADE_PLAN) {
    const { plan } = payload;
    return {
      ...state,
      upgradePlan: plan
    };
  }
  if (type === paymentsActionTypes.SUCCESS_SUBMITED_WORKSPACE_PAYMENT_PLAN) {
    return {
      ...state,
      upgradePlan: null,
      isSending: false
    };
  }
  if (type === paymentsActionTypes.START_SUBMIT_STRIPE_PAYMENT_PLAN) {
    return {
      ...state,
      isSending: true
    };
  }
  if (
    type === paymentsActionTypes.FAILED_PAYMENT_TRY_AGAIN ||
    type === paymentsActionTypes.FAILED_PAYMENT_ISSUE ||
    type === paymentsActionTypes.NO_TOKEN_TEAM_PLAN
  ) {
    return {
      ...state,
      isSending: false
    };
  }
  if (type === paymentsActionTypes.ERROR_TEAM_NAME_REQUEST) {
    return {
      ...state,
      validTeamName: '',
      errorTeamNameMessage: payload
    };
  }
  if (type === paymentsActionTypes.HIDE_PAYMENT_SUCCESS_BTN) {
    return {
      ...state,
      isPaymentSuccessBtnVisible: false
    };
  }
  if (type === paymentsActionTypes.RECEIVED_PAYMENT_PLAN) {
    return {
      ...state,
      paymentPlan: action.data
    };
  }
  if (type === paymentsActionTypes.STORE_STRIPE_OBJECT) {
    return {
      ...state,
      savedStripe: payload
    };
  }
  if (type === paymentsActionTypes.SKIP_USERS_PERSONAL_PLAN_SETUP) {
    return {
      ...state
    };
  }
  if (type === paymentsActionTypes.SAVE_TEAM_NEW_STATUS_DURING_SIGN_UP) {
    return {
      ...state,
      setupTeam: payload
    };
  }
  return state;
};

export default paymentsReducer;

export const getIsOnboardingTrial = state =>
  get(state, ['payments', 'onboarding', 'trial'], true);

export const getPaymentSettings = state =>
  get(state, ['payments', 'settings'], {});
