import { useSelector } from 'react-redux';
import { getIsUserLoggedIn } from '@/reducers/userReducer';

const useIsUserLoggedIn = () => {
  const isUserLogged = useSelector(state => getIsUserLoggedIn(state));

  return Boolean(isUserLogged);
};

export default useIsUserLoggedIn;
