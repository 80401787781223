import { StyledTooltip } from '@DesignSystem/data-display';
import { truncateMiddleOfStringToFitWidth } from '@shared/utils/displayHelpers';

import { Box } from '@material-ui/core';
import cx from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import './DropdownList.scss';
import ExtraOptions from './ExtraOptions';

const ListItem = ({
  item,
  onClick,
  isWithGroups,
  selectedValue,
  hideSource,
  extraOptionsProps,
  parentRenderListItem,
  style,
  withoutHover,
  hoverType,
  width,
  truncateMiddle
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const extraOptionsRef = React.useRef();
  // extra options
  const [showTooltip, setShowTooltip] = React.useState(false);

  const secondaryLabel = item.source || item.secondaryLabel;

  const listRef = useRef();

  const isWithTruncation = truncateMiddle && width && !item.groupName;

  const displayedListItem = useMemo(() => {
    if (parentRenderListItem) {
      return parentRenderListItem(item);
    }

    if (isWithTruncation) {
      return truncateMiddleOfStringToFitWidth(item?.label, width);
    }

    return item?.label;
  }, [item, width, isWithTruncation, parentRenderListItem]);

  const tooltipText = useMemo(() => {
    if (item.tooltipText) {
      return item.tooltipText;
    }

    if (isWithTruncation && item.label !== displayedListItem) {
      return item.label;
    }

    return '';
  }, [item.tooltipText, item.label, isWithTruncation, displayedListItem]);

  const tooltipPlacement = useMemo(() => {
    if (item.tooltipPlacement) {
      return item.tooltipPlacement;
    }

    if (isWithTruncation) {
      return 'top';
    }

    return 'left';
  }, [item.tooltipPlacement, isWithTruncation]);

  const renderContent = () => {
    if (item.render) {
      return item.render(item);
    }

    return (
      <>
        <div>
          <Box display="flex" alignItems="center">
            {item?.prefixIcon && (
              <div className="prefix-icon"> {item?.prefixIcon}</div>
            )}
            <p
              className="list-item-label"
              style={{
                lineHeight: 1.1,
                fontSize: 13
              }}
            >
              {displayedListItem}
              {item.predefined && (
                <span className="coming-soon-label">Coming Soon</span>
              )}
            </p>
          </Box>
          {secondaryLabel && !hideSource ? (
            <p className="ds-select-secondary-label">{secondaryLabel}</p>
          ) : null}
        </div>
        <div>{item.renderActions && item.renderActions(isHovered)}</div>
      </>
    );
  };

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={listRef}
      key={item.value || item.label}
      className={cx('dropdown-list-item', hoverType, {
        selected: item.value === selectedValue,
        'group-name': !!item.groupName,
        'no-group-name': isWithGroups && !item.groupName,
        disabled: !!item.disabled,
        'no-hover': withoutHover,
        'with-bottom-divider': item.withBottomDivider
      })}
      style={{
        height: secondaryLabel && !hideSource ? 59 : 38,
        ...style
      }}
      onClick={node => {
        if (
          !item.groupName &&
          !item.disabled &&
          node.target !== extraOptionsRef.current &&
          !isSubmenuOpen
        )
          onClick(item.value, item, node);
      }}
    >
      <StyledTooltip
        type="base"
        title={tooltipText}
        maxWidth={200}
        placement={tooltipPlacement}
      >
        <div className="ds-dropdown-list-item-container">{renderContent()}</div>
      </StyledTooltip>
      {item.extraOptions && (
        <StyledTooltip
          title="Additional Options"
          type="base"
          placement="top"
          open={showTooltip}
          disableHoverListener
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <div
            onClick={() => setShowTooltip(false)}
            style={{ pointerEvents: 'all' }}
          >
            <ExtraOptions
              setIsSubmenuOpen={setIsSubmenuOpen}
              extraOptionsProps={extraOptionsProps}
              ref={{
                customRef: extraOptionsProps?.useListAsAnchor ? listRef : null,
                extraOptionsRef
              }}
              options={item.extraOptions}
              onClick={onClick}
            />
          </div>
        </StyledTooltip>
      )}
    </div>
  );
};

export default ListItem;
