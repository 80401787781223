import React from 'react';
import { LinkButton } from '@design-system/components/buttons';
import { LinkButtonProps } from '@design-system/components/buttons/LinkButton/LinkButton';

import classes from '../List.module.scss';

type ListFooterActionProps = Omit<
  LinkButtonProps,
  'accented' | 'fullWidth' | 'type'
>;
const ListFooterActionLink: React.FC<ListFooterActionProps> = buttonProps => {
  return (
    <LinkButton
      {...buttonProps}
      className={classes.footerActionLinkBtn}
      accented
      type="tertiary"
      fullWidth
    />
  );
};

export default ListFooterActionLink;
