import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from '@ds';
import { Input, Select } from '@DesignSystem/controllers';
import { mapModelVersions } from '@mpm/utils';
import {
  ALERT_OPERATOR_OPTIONS,
  ALERT_TYPE_OPTIONS,
  DEFAULT_VERSIONS,
  FEATURE_TYPES,
  METRIC_CATEGORY_OPTIONS,
  METRIC_TYPE_WITH_DRIFT_METHODS,
  NUMERICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS,
  CATEGORICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS,
  SEGMENT_OPTIONS,
  TIME_PERIOD_OPTIONS,
  METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL
} from '@mpm/constants';

const mapFeatures = modelFeatures =>
  modelFeatures.map(({ key, id, type, valType }) => ({
    value: id,
    label: key,
    type,
    valType
  }));

const StepOne = ({
  setActiveStep,
  setIscurrentStepDone,
  fieldsState,
  setFieldsState,
  handleDropdownState,
  modelFeatures,
  model
}) => {
  const firstRow = useMemo(() => {
    const row = [
      {
        name: 'metricCategory',
        label: 'Metric category',
        options: METRIC_CATEGORY_OPTIONS
      }
    ];
    if (fieldsState.metricCategory) {
      row.push({
        name: 'metricType',
        label:
          METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL[fieldsState.metricCategory],
        options:
          fieldsState.metricCategory === 'feature_metrics' && modelFeatures
            ? mapFeatures(modelFeatures)
            : METRIC_TYPE_WITH_DRIFT_METHODS[fieldsState.metricCategory] || [],
        withInput: fieldsState.metricCategory === 'feature_metrics'
      });

      let featureDistributionOptions = [];
      if (fieldsState.metricCategory === 'feature_metrics') {
        const featureType =
          fieldsState?.metricType &&
          modelFeatures?.find(feature => feature.id === fieldsState.metricType)
            ?.valType;
        featureDistributionOptions =
          featureType === FEATURE_TYPES.NUMERICAL
            ? [NUMERICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS]
            : [CATEGORICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS];
      }

      if (featureDistributionOptions.length) {
        row.push(featureDistributionOptions[0]);
      }
    }
    return row;
  }, [fieldsState.metricCategory, fieldsState.metricType, modelFeatures]);

  const secondRow = [
    {
      name: 'modelVersion',
      options: DEFAULT_VERSIONS.concat(mapModelVersions(model.versions)),
      label: 'Model version',
      withInput: true
    },
    {
      name: 'segment',
      options: SEGMENT_OPTIONS,
      label: 'Segment',
      withInput: true
    },
    {
      name: 'timePeriod',
      hideInput: true,
      options: TIME_PERIOD_OPTIONS,
      label: 'Daily or hourly',
      width: 100
    }
  ];

  const thirdRow = [
    {
      name: 'thresholdType',
      options: ALERT_TYPE_OPTIONS,
      label: 'Alert Type'
    },
    {
      name: 'thresholdOperator',
      options: ALERT_OPERATOR_OPTIONS,
      label: 'Alert operator'
    },
    {
      name: 'thresholdValue',
      label: 'Alert Threshold'
    }
  ];

  const isFirstRowDone = firstRow.every(field => !!fieldsState[field.name]);
  const isFirstStepDone = [...firstRow, ...secondRow, ...thirdRow].every(
    field => !!fieldsState[field.name]
  );

  useEffect(() => {
    if (isFirstStepDone) {
      setIscurrentStepDone(true);
    } else {
      setIscurrentStepDone(false);
    }
  }, [isFirstStepDone, setIscurrentStepDone]);

  const renderField = (field, index) => {
    if (!field?.options)
      return (
        <div className="thresholdInput" key={index}>
          <Input
            title={field.label}
            value={fieldsState[field.name]}
            type="number"
            minNum={0.0}
            maxNum={1.0}
            step={0.001}
            onChange={val =>
              setFieldsState(prevState => ({
                ...prevState,
                thresholdValue: val
              }))
            }
          />
        </div>
      );

    return (
      <div
        className="step-one-input"
        style={{ width: field.width || '220px' }}
        key={index}
      >
        <Select
          label={`${field.label}:`}
          value={fieldsState[field.name]}
          width={field.width || '220px'}
          onChange={(newValue, optionData) =>
            handleDropdownState(newValue, field, optionData)
          }
          options={field?.options || []}
          key={field.value}
          withInput={field.withInput}
          variant="outlined"
        />
      </div>
    );
  };

  return (
    <>
      <h6>Choose the metric you want track</h6>
      <div className="inputs-row">{firstRow.map(renderField)}</div>
      <>
        <h6 className={cx({ fieldsHidden: !isFirstRowDone })}>
          Choose the data you want to track
        </h6>
        {isFirstRowDone && (
          <div className="inputs-row">{secondRow.map(renderField)}</div>
        )}

        <h6 className={cx({ fieldsHidden: !isFirstRowDone })}>
          Define the alert
        </h6>
        {isFirstRowDone && (
          <div className="inputs-row">{thirdRow.map(renderField)}</div>
        )}
      </>

      <Button
        text="Next"
        onClick={() => setActiveStep(prev => prev + 1)}
        disabled={!isFirstStepDone}
      >
        Next
      </Button>
    </>
  );
};

StepOne.propTypes = {
  fieldsState: PropTypes.shape({
    outputDistribution: PropTypes.any
  }).isRequired,
  model: PropTypes.any.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setFieldsState: PropTypes.func.isRequired,
  setIscurrentStepDone: PropTypes.func.isRequired
};

export default StepOne;
