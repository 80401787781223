import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import queryString from 'query-string';
import { last } from 'lodash';
import { TextButton } from '@ds';

import FileSaver from 'file-saver';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { EXP_KEY_LENGTH_EXPORT } from '@experiment-management-shared/constants/experimentConstants';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import ExportFileModal from '@experiment-management-shared/components/ExportFileModal';
import {
  FILE_EXTENSIONS,
  removeFileExtension
} from '@experiment-management-shared/utils/filesTreeUtils';
import StyledCopyButton from '@shared/components/StyledComponents/StyledCopyButton';
import { DSDownloadIcon, DSOpenInNewIcon } from '@ds-icons';
import ExperimentBar from '@experiment-details/components/ExperimentBar';
import SimpleCode from '@experiment-details/components/Code/SimpleCode';
import useExperimentCodeWrapper from '@experiment-details/hooks/useExperimentCodeWrapper';
import { getFileExtension } from '@experiment-details/utils/code';

import styles from './CodeTab.module.scss';

const CodeTab = ({ experiments }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { projectName, workspace } = match.params;
  const [experiment] = experiments;
  const { experimentKey } = experiment;

  const location = useLocation();
  const { file = '' } = queryString.parse(location.search);

  const {
    isLoading,
    fileOption,
    setFileOption,
    fileOptions,
    code
  } = useExperimentCodeWrapper({
    experiment,
    file,
    fileField: 'value',
    maxSelectWidth: 290
  });

  const fileNameWithoutExtension = removeFileExtension(
    last((fileOption?.label || '').split(/(\/|\\)/))
  );
  const extension = getFileExtension(
    fileOption?.label || '',
    FILE_EXTENSIONS.py
  );
  const isEmptyTab = !code;

  useQueryParamsForExperiments(
    { file: fileOption.value },
    [fileOption.value],
    experimentKey
  );

  useEffect(() => {
    if (!fileOptions.length) return;

    const fileOption =
      fileOptions.find(option => option.value === file) || fileOptions[0];

    setFileOption(fileOption);
  }, [fileOptions, file]);

  const handleAssetChange = fileOption => {
    setFileOption(fileOption);
  };

  const handleDownloadClick = downloadFileName => {
    if (isEmptyTab) return;

    const blob = new window.Blob([code], {
      type: 'text/plain;charset=utf-8'
    });

    FileSaver.saveAs(blob, `${downloadFileName}.${extension}`);
  };

  const handleExportData = () => {
    const modalId = dialogTypes.EXPORT_FILE_MODAL;

    const exportFileModal = (
      <ExportFileModal
        submitHandler={handleDownloadClick}
        modalId={modalId}
        fileExtension={extension || 'py'}
        modalTitle="Export Code"
        customFilenamePaths={[
          experimentKey.slice(0, EXP_KEY_LENGTH_EXPORT),
          fileNameWithoutExtension
        ]}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, exportFileModal));
  };

  const handleViewRaw = () => {
    const rawUrl = !fileOption.value
      ? `${window.location.origin}/${workspace}/${projectName}/${experimentKey}/raw/code`
      : `${window.location.origin}/${workspace}/${projectName}/${experimentKey}/${fileOption.value}/raw/asset`;

    window.open(rawUrl, '_blank', 'noopener');
  };

  return (
    <div className={styles.codePanelTab}>
      <ExperimentBar
        rightContainerChildren={
          <ExperimentBar.ActionList>
            <TextButton
              onClick={handleViewRaw}
              type="secondary"
              PrefixIcon={<DSOpenInNewIcon />}
              disabled={isEmptyTab}
            >
              View raw
            </TextButton>

            <TextButton
              onClick={handleExportData}
              type="secondary"
              PrefixIcon={<DSDownloadIcon />}
              disabled={isEmptyTab}
            >
              Download
            </TextButton>
          </ExperimentBar.ActionList>
        }
      >
        <div className={styles.codePanel}>
          <ExperimentBar.Select
            label="File"
            changeHandler={handleAssetChange}
            options={fileOptions}
            value={fileOption.value}
            isSearchable
            disabled={isEmptyTab}
            isRegexSearchEnabled
          />
          <StyledCopyButton
            className={styles.copyIcon}
            text={fileOption.fullLabel}
            tooltipContent="Copy full filename"
            disabled={isEmptyTab}
          />
        </div>
      </ExperimentBar>

      <SimpleCode code={code} experiment={experiment} isLoading={isLoading} />
    </div>
  );
};

CodeTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default CodeTab;
