import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ROOT_URL } from '@/constants/configConstants';
import { BETA_FEATURE_MODEL_REGISTRY_WEBHOOKS_GITHUB } from '@/lib/betaFeatures';
import { Checkbox, Input, Switch } from '@DesignSystem/controllers';
import FieldsList from '@design-system-outdated/components/FieldsList/FieldsList';
import { InfoIconWithTooltip } from '@design-system-outdated/components/InfoIconWithTooltip/InfoIconWithTooltip';
import ModelRegistryInvalidUrlWarning from '@model-registry/components/ModelRegistryWebhooksTab/ModelRegistryInvalidUrlWarning';
import {
  WEBHOOKS_EVENT_TRIGGERS,
  WEBHOOK_AUTHORIZATION_HEADER_KEY
} from '@model-registry/constants/webhooks';
import { checkDomain } from '@model-registry/utils/webhooks';
import { APIKeyInputWritable } from '@shared';
import { useBetaFeatureEnabled } from '@shared/hooks';
import ModelRegistryHeadersFields from './ModelRegistryHeadersFields';
import classNames from './ModelRegistryWebhooksTab.module.scss';

const { origin } = new URL(ROOT_URL, window.location.origin);

const getDataTest = (dataTest, name) =>
  `${dataTest?.replace(/-create|-edit/g, '')}-${name}`;

const githubActionFormat = {
  event_type: '<comet_event_name>',
  client_payload: '{<comet_payload>}'
};

const ModelRegistryCreateEditModalContent = ({
  dataTest,
  eventTrigger = [],
  setEventTrigger,
  webhookName,
  setWebhookName,
  authKey,
  setAuthKey,
  url,
  setUrl,
  isUrlValid,
  headers = [{ key: '', value: '' }],
  setHeaders,
  isGithubIntegrationActive,
  setIsGithubIntegrationActive
}) => {
  const isGitHubIntegrationFeatureEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_REGISTRY_WEBHOOKS_GITHUB
  );
  const [
    ifGithubActionsTurnedOffByUser,
    setIfGithubActionsTurnedOffByUser
  ] = useState(false);
  const isOldWebhook = eventTrigger.includes(
    WEBHOOKS_EVENT_TRIGGERS.MODEL_STAGE_CHANGED
  );

  useEffect(() => {
    if (isEmpty(headers)) {
      setHeaders(0, { key: '', value: '' });
    }
  }, [headers]);

  return (
    <Box className={classNames.modelRegistryWebhooksFullWidthModalContent}>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Box
            className={classNames.modelRegistryWebhooksFullWidthModalSubtitle}
          >
            Webhooks are the best way to integrate the Comet model registry with
            your CI/CD pipeline. You can configure the webhooks to trigger on
            specific events. When one of those events is triggered, Comet sends
            a POST request with event details to the webhook’s configured URL.
            More information can be found in{' '}
            <a
              href={`${origin}/docs/v2/guides/model-management/webhooks/#configure-the-webhook`}
              target="_blank"
              rel="noreferrer"
              data-test={`${dataTest}-docs-link`}
            >
              our documentation.
            </a>
          </Box>
        </Grid>
        <Grid item>
          <Input
            required
            title="Webhook name"
            className=""
            onChange={setWebhookName}
            value={webhookName}
            placeholder="Enter name"
            {...{
              'data-test': getDataTest(dataTest, 'webhook-name')
            }}
          />
        </Grid>
        <Grid item>
          <Input
            helpMsg="We will send a POST request to this URL after each webhook event."
            required
            title="URL"
            className=""
            onChange={val => {
              setUrl(val);
              if (!ifGithubActionsTurnedOffByUser) {
                setIsGithubIntegrationActive(
                  checkDomain('github.com', val?.toLocaleLowerCase())
                );
              }
            }}
            value={url}
            placeholder="http://example.com/postreceive"
            {...{
              'data-test': getDataTest(dataTest, 'webhook-url')
            }}
          />
          <ModelRegistryInvalidUrlWarning
            dataTest={getDataTest(dataTest, 'webhook-url-warning')}
            isUrlValid={isUrlValid}
          />
        </Grid>
        <Grid item data-test={getDataTest(dataTest, `webhook-secret-wrapper`)}>
          <APIKeyInputWritable
            {...{
              'data-test': getDataTest(dataTest, `webhook-secret`)
            }}
            title={
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs="auto">
                  Secret
                </Grid>
                <Grid item xs="auto">
                  <InfoIconWithTooltip
                    data-test={getDataTest(dataTest, `info-icon-secret`)}
                    className={classNames.modelRegistryWebhooksInfoIcon}
                    title={
                      'The secret will be sent as part of the header as "Authorization": "secret_token"'
                    }
                  />
                </Grid>
              </Grid>
            }
            onChange={setAuthKey}
            value={authKey}
          />
        </Grid>
        <Grid item>
          <Box
            className={cx(
              classNames.modelRegistryWebhooksFullWidthModalSubtitle,
              classNames.modelRegistryWebhooksFullWidthModalEvents
            )}
          >
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs="auto">
                Trigger events
              </Grid>
              <Grid item xs="auto">
                <InfoIconWithTooltip
                  data-test={getDataTest(dataTest, `info-icon-triggers`)}
                  className={classNames.modelRegistryWebhooksInfoIcon}
                  title="The webhook will trigger each time the selected events occur"
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box
                    className={
                      classNames.modelRegistryWebhooksFullWidthModalLabel
                    }
                  >
                    Model version
                  </Box>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...{
                          'data-test': getDataTest(
                            dataTest,
                            `webhook-trigger-${WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_CREATED}`
                          )
                        }}
                        onChange={ev =>
                          setEventTrigger(
                            WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_CREATED,
                            ev.target.checked
                          )
                        }
                        value="exact match"
                      />
                    }
                    classes={{
                      root:
                        classNames.modelRegistryWebhooksFullWidthModalCheckbox,
                      label:
                        classNames.modelRegistryWebhooksFullWidthModalCheckboxLabel
                    }}
                    name={WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_CREATED}
                    label="Created"
                    checked={eventTrigger.includes(
                      WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_CREATED
                    )}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...{
                          'data-test': getDataTest(
                            dataTest,
                            `webhook-trigger-${WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_DELETED}`
                          )
                        }}
                        onChange={ev =>
                          setEventTrigger(
                            WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_DELETED,
                            ev.target.checked
                          )
                        }
                        value="exact match"
                      />
                    }
                    classes={{
                      root:
                        classNames.modelRegistryWebhooksFullWidthModalCheckbox,
                      label:
                        classNames.modelRegistryWebhooksFullWidthModalCheckboxLabel
                    }}
                    name={WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_DELETED}
                    label="Deleted"
                    checked={eventTrigger.includes(
                      WEBHOOKS_EVENT_TRIGGERS.MODEL_VERSION_DELETED
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box
                    className={
                      classNames.modelRegistryWebhooksFullWidthModalLabel
                    }
                  >
                    Model tag
                  </Box>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...{
                          'data-test': getDataTest(
                            dataTest,
                            `webhook-trigger-${WEBHOOKS_EVENT_TRIGGERS.MODEL_TAG_CHANGED}`
                          )
                        }}
                        onChange={ev =>
                          setEventTrigger(
                            WEBHOOKS_EVENT_TRIGGERS.MODEL_TAG_CHANGED,
                            ev.target.checked
                          )
                        }
                        value="exact match"
                      />
                    }
                    classes={{
                      root:
                        classNames.modelRegistryWebhooksFullWidthModalCheckbox,
                      label:
                        classNames.modelRegistryWebhooksFullWidthModalCheckboxLabel
                    }}
                    name={WEBHOOKS_EVENT_TRIGGERS.MODEL_TAG_CHANGED}
                    label="Changed"
                    checked={eventTrigger.includes(
                      WEBHOOKS_EVENT_TRIGGERS.MODEL_TAG_CHANGED
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box
                    className={
                      classNames.modelRegistryWebhooksFullWidthModalLabel
                    }
                  >
                    Model status
                  </Box>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...{
                          'data-test': getDataTest(
                            dataTest,
                            `webhook-trigger-${WEBHOOKS_EVENT_TRIGGERS.MODEL_STATUS_CHANGED}`
                          )
                        }}
                        onChange={ev =>
                          setEventTrigger(
                            WEBHOOKS_EVENT_TRIGGERS.MODEL_STATUS_CHANGED,
                            ev.target.checked
                          )
                        }
                        value="exact match"
                      />
                    }
                    classes={{
                      root:
                        classNames.modelRegistryWebhooksFullWidthModalCheckbox,
                      label:
                        classNames.modelRegistryWebhooksFullWidthModalCheckboxLabel
                    }}
                    name={WEBHOOKS_EVENT_TRIGGERS.MODEL_STATUS_CHANGED}
                    label="Changed"
                    checked={eventTrigger.includes(
                      WEBHOOKS_EVENT_TRIGGERS.MODEL_STATUS_CHANGED
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {isOldWebhook && (
              <Grid item xs={3}>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Box
                      className={
                        classNames.modelRegistryWebhooksFullWidthModalLabel
                      }
                    >
                      Model stage (deprecated)
                    </Box>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...{
                            'data-test': getDataTest(
                              dataTest,
                              `webhook-trigger-${WEBHOOKS_EVENT_TRIGGERS.MODEL_STAGE_CHANGED}`
                            )
                          }}
                          onChange={ev =>
                            setEventTrigger(
                              WEBHOOKS_EVENT_TRIGGERS.MODEL_STAGE_CHANGED,
                              ev.target.checked
                            )
                          }
                          value="exact match"
                        />
                      }
                      classes={{
                        root:
                          classNames.modelRegistryWebhooksFullWidthModalCheckbox,
                        label:
                          classNames.modelRegistryWebhooksFullWidthModalCheckboxLabel
                      }}
                      name={WEBHOOKS_EVENT_TRIGGERS.MODEL_STAGE_CHANGED}
                      label="Changed"
                      checked={eventTrigger.includes(
                        WEBHOOKS_EVENT_TRIGGERS.MODEL_STAGE_CHANGED
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Box
            className={cx(
              classNames.modelRegistryWebhooksFullWidthModalSubtitle,
              classNames.modelRegistryWebhooksFullWidthModalEvents
            )}
          >
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs="auto">
                Header
              </Grid>
              <Grid item xs="auto">
                <InfoIconWithTooltip
                  data-test={getDataTest(dataTest, `info-icon-headers`)}
                  className={classNames.modelRegistryWebhooksInfoIcon}
                  title="The key-value pairs will be sent as part of the HTTP header to provide addition context or as a way of adding custom fields"
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <FieldsList
              dataTest={getDataTest(dataTest, `headers-list`)}
              removeButtonTooltip="Remove field"
              onAddButtonClick={() => {
                setHeaders(headers?.length, {
                  key: '',
                  value: ''
                });
              }}
              renderItem={(data, index) => (
                <ModelRegistryHeadersFields
                  dataTest={getDataTest(dataTest, `webhook-header-${index}`)}
                  setHeaders={data => setHeaders(index, data)}
                  keyName={data?.key}
                  value={data?.value}
                />
              )}
              isItemValid={data =>
                !!data?.key &&
                !!data?.value &&
                String(data?.key).toLocaleLowerCase() !==
                  WEBHOOK_AUTHORIZATION_HEADER_KEY.toLocaleLowerCase()
              }
              onRemoveButtonClick={index => setHeaders(index)}
              items={headers}
              header={
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        Key
                      </Grid>
                      <Grid item xs={6}>
                        Value
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Box>
        </Grid>
        {isGitHubIntegrationFeatureEnabled && (
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                className={
                  classNames.modelRegistryWebhooksFullWidthModalSubtitle
                }
              >
                GitHub Actions Integration
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      {...{
                        'data-test': getDataTest(
                          dataTest,
                          `webhook-github-actions`
                        )
                      }}
                      className={
                        classNames.modelRegistryWebhooksFullWidthModalSwitch
                      }
                      onChange={() => {
                        setIsGithubIntegrationActive(
                          !isGithubIntegrationActive
                        );
                        setIfGithubActionsTurnedOffByUser(true);
                      }}
                    />
                  }
                  classes={{
                    root:
                      classNames.modelRegistryWebhooksFullWidthModalCheckbox,
                    label:
                      classNames.modelRegistryWebhooksFullWidthModalCheckboxLabel
                  }}
                  name="webhook-github-actions"
                  label={
                    <div>
                      <div>Reformat payload for GitHub actions</div>
                      <div
                        className={
                          classNames.modelRegistryWebhooksFullWidthModalSmallLabel
                        }
                      >
                        The webhook payload will be reformatted to match github
                        actions format `${JSON.stringify(githubActionFormat)}`
                      </div>
                    </div>
                  }
                  checked={isGithubIntegrationActive}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box mb={3} />
    </Box>
  );
};

ModelRegistryCreateEditModalContent.defaultProps = {
  authKey: '',
  eventTrigger: [],
  headers: [{ key: '', value: '' }],
  dataTest: ''
};

ModelRegistryCreateEditModalContent.propTypes = {
  dataTest: PropTypes.string,
  authKey: PropTypes.string,
  isGithubIntegrationActive: PropTypes.bool.isRequired,
  setIsGithubIntegrationActive: PropTypes.func.isRequired,
  eventTrigger: PropTypes.arrayOf(PropTypes.string),
  webhookName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isUrlValid: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ),
  setEventTrigger: PropTypes.func.isRequired,
  setWebhookName: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
  setAuthKey: PropTypes.func.isRequired,
  setHeaders: PropTypes.func.isRequired
};

export default ModelRegistryCreateEditModalContent;
