import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import uniq from 'lodash/uniq';

import ColumnsButton from '@experiment-management-shared/components/DashboardButton/ColumnsButton';
import GroupByButton from '@experiment-management-shared/components/DashboardButton/GroupByButton';

import {
  DEFAULT_COLUMN_NAMES,
  GRID_COLUMNS,
  LLM_NAME_MAP
} from '@experiment-management-shared/utils/LLMView';
import LLMSearchForm from '@projects/components/LLMDashboardHeader/LLMSearchForm/LLMSearchForm';
import styles from './LLMDashboardHeader.module.scss';
import { MAX_DEFAULT_LLM_COLUMNS_COUNT } from '../../../../constants/configConstants';
import union from 'lodash/union';

const LLMDashboardHeader = ({
  onChange,
  dashboardTable,
  handleQueryColumnChange,
  handleSearchQueryChange,
  queryColumn,
  llmColumns,
  controlledQueryText,
  dynamicLLMNameMap
}) => {
  const { columnGrouping, columnOrders } = dashboardTable;

  const handleColumnsChange = useCallback(
    (selectedColumnNames = []) => {
      const columnNames = uniq([GRID_COLUMNS.NAME, ...selectedColumnNames]);

      onChange({
        table: {
          columnOrders: columnNames
        }
      });
    },
    [onChange]
  );

  const handleGroupingChange = useCallback(
    newColumnGrouping => {
      onChange({
        table: {
          columnGrouping: newColumnGrouping,
          expandedGroups: [],
          pageNumber: 0
        }
      });
    },
    [onChange]
  );

  const [isSubmitDisabled] = useState(false);

  const handleQuerySubmitted = event => {
    event.preventDefault();
    handleSearchQueryChange(event.target.queryText.value);
  };

  // @todo exclude all llm columns from grouping
  return (
    <div className={styles.llmDashboardHeader}>
      <LLMSearchForm
        onSubmit={handleQuerySubmitted}
        queryColumn={queryColumn}
        llmColumns={llmColumns}
        handleQueryColumnChange={handleQueryColumnChange}
        dynamicLLMNameMap={dynamicLLMNameMap}
        disabled={isSubmitDisabled}
        controlledQueryText={controlledQueryText}
      />
      <div className={styles.controlsContainer}>
        <ColumnsButton
          showButtonLabel
          onChange={handleColumnsChange}
          selectedColumnNames={columnOrders}
          defaultColumns={union(
            DEFAULT_COLUMN_NAMES,
            Object.keys(dynamicLLMNameMap).slice(
              0,
              MAX_DEFAULT_LLM_COLUMNS_COUNT
            )
          )}
          columnsCountCorrection={0}
          columnsNameMap={dynamicLLMNameMap}
        />
        <div className={styles.spacer} />
        <GroupByButton
          showButtonLabel
          tooltipTitle="Group prompts"
          modalTitle="Group prompts by columns"
          excludedColumns={llmColumns}
          modalPlaceholder="Select columns to group prompts by"
          onChange={handleGroupingChange}
          selectedColumnNames={columnGrouping}
          columnsNameMap={LLM_NAME_MAP}
        />
      </div>
    </div>
  );
};

PropTypes.defaultProps = {
  llmColumns: []
};

LLMDashboardHeader.propTypes = {
  dashboardTable: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleQueryColumnChange: PropTypes.func.isRequired,
  handleSearchQueryChange: PropTypes.func.isRequired,
  queryColumn: PropTypes.string.isRequired,
  llmColumns: PropTypes.array,
  controlledQueryText: PropTypes.string.isRequired,
  dynamicLLMNameMap: PropTypes.object.isRequired
};

export default LLMDashboardHeader;
