import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { BasicModal } from '@DesignSystem/modals';
import { useAllUserWorkspaces } from '@shared/hooks';
import useModelRegistryList from '@shared/api/useModelRegistryList';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'lodash';
import { useModelRegistryVersionMoveMutation } from '@model-registry/api';
import {
  formatRegistryModelsListToReactSelectOptions,
  formatWorkspacesListToReactSelectOptions
} from '@model-registry/utils';
import { useDispatch } from 'react-redux';
import { Select } from '@ds';
import MoveModelConfirmDialog from './MoveModelConfirmDialog';
import classNames from './MoveModelVersionModal.module.scss';
import modelRegistryApi from '@/util/modelRegistryApi';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';

const loadListOfWorkspaces = async workspace => {
  const {
    data: { registryModels = [] } = {}
  } = await modelRegistryApi.fetchModelRegistryList(workspace);

  return formatRegistryModelsListToReactSelectOptions(registryModels);
};

const TIMER_DELAY = 500;

const MoveModelVersionModal = ({
  model: { registryModelId, modelName } = {},
  modelVersionData: { registryModelItemId, version: movedVersion } = {},
  workspace,
  onClose,
  onSuccess,
  isOpen,
  isLastModelVersion
}) => {
  const { data: workspaces = [] } = useAllUserWorkspaces();
  const workspacesOptions = formatWorkspacesListToReactSelectOptions(
    workspaces
  );
  const { data: defaultModelsList = [] } = useModelRegistryList(workspace);
  const [comment, setComment] = useState('');
  const [modelsOptions, setModelsOptions] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({
    value: workspace,
    label: workspace
  });
  const dispatch = useDispatch();
  const onCloseCb = () => {
    onClose();
    setComment('');
  };

  const [selectedModel, setSelectedModel] = useState({});
  const modelVersionMoveMutation = useModelRegistryVersionMoveMutation({
    registryModelId,
    registryModelItemId
  });

  useEffect(() => {
    setSelectedModel({
      value: registryModelId,
      label: modelName
    });
  }, [registryModelId, modelName]);
  useEffect(() => {
    if (!modelsOptions?.length && defaultModelsList?.length) {
      setModelsOptions(
        formatRegistryModelsListToReactSelectOptions(defaultModelsList)
      );
    }
  }, [defaultModelsList]);

  const handleLoadFormattedListOfWorkspaces = newWorkspace => {
    setSelectedModel(null);
    (async () => {
      const newList = await loadListOfWorkspaces(newWorkspace);

      setModelsOptions(newList);
      setSelectedModel(newList[0]);
    })();
  };

  const debouncedLoadListOfWorkspaces = useCallback(
    debounce(handleLoadFormattedListOfWorkspaces, TIMER_DELAY),
    [selectedWorkspace]
  );

  const handleWorkspaceChange = workspaceVal => {
    setSelectedWorkspace(workspaceVal);
    debouncedLoadListOfWorkspaces(workspaceVal?.value);
  };
  const moveModelConfirm = async () => {
    try {
      await modelVersionMoveMutation.mutateAsync({
        targetRegistryModelId: selectedModel?.value,
        movedVersion,
        comment
      });

      onSuccess({
        targetModelName: selectedModel?.label,
        currentModelName: modelName,
        version: movedVersion,
        targetWorkspaceName: selectedWorkspace?.value
      });
    } catch (e) {
      // no-op
    } finally {
      onCloseCb();
      dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_MOVE_MODEL));
    }
  };
  const handleConfirm = async () => {
    if (isLastModelVersion) {
      dispatch(
        alertsUtil.openConfirmDialog(
          dialogTypes.CONFIRM_MOVE_MODEL,
          <MoveModelConfirmDialog
            modelName={modelName}
            text="This model will be empty but you can register additional items to this model in the future."
          />,
          moveModelConfirm,
          'Move'
        )
      );
    } else {
      await moveModelConfirm();
    }
  };

  return (
    <BasicModal
      additionalClasses={{
        paper: classNames.moveModelVersionsModal
      }}
      open={isOpen}
      title="Move Model Version"
      isPrimaryButtonDisabled={
        !selectedModel || selectedModel?.value === registryModelId
      }
      primaryButtonText="Move"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      onSecondaryButtonClick={onClose}
      disableEnforceFocus
      content={
        <Box mt={2}>
          <Grid container spacing={4} direction="column">
            <Grid item xs={12}>
              <Box className={classNames.moveModelVersionsModalLabel}>
                Select workspace
              </Box>
              <Select
                className={classNames.moveModelVersionsModalSelect}
                isClearable={false}
                label="Select workspace"
                placeholder="Select workspace"
                value={selectedWorkspace?.value}
                onValueChange={(_, option) => handleWorkspaceChange(option)}
                options={workspacesOptions}
                isSearchable
              />
            </Grid>
            <Grid item xs={12}>
              <Box className={classNames.moveModelVersionsModalLabel}>
                Select registry model
              </Box>
              <Select
                className={classNames.moveModelVersionsModalSelect}
                isClearable={false}
                noOptionsMessage={() => (
                  <Typography
                    variant="body1"
                    className={classNames.moveModelVersionsNoModels}
                  >
                    No models found in workspace
                  </Typography>
                )}
                label="Select registry model"
                placeholder="Select registry model"
                openMenuOnClick
                value={selectedModel?.value}
                onValueChange={(_, option) => setSelectedModel(option)}
                options={modelsOptions}
                isSearchable
              />
            </Grid>
            {/* <Grid item xs={12}> */}
            {/*  <Grid */}
            {/*    container */}
            {/*    spacing={1} */}
            {/*    alignContent="center" */}
            {/*    alignItems="center" */}
            {/*    className={classNames.moveModelVersionsModalLabel} */}
            {/*  > */}
            {/*    <Grid item>Document your changes</Grid> */}
            {/*    <Grid item> */}
            {/*      <Tooltip */}
            {/*        title="All model changes will be reflected in History tab" */}
            {/*        placement="top" */}
            {/*        arrow */}
            {/*      > */}
            {/*        <Box className="model-registry-manage-stages-description-icon"> */}
            {/*          <MetadataIcon /> */}
            {/*        </Box> */}
            {/*      </Tooltip> */}
            {/*    </Grid> */}
            {/*  </Grid> */}
            {/*  <Box mt={1}> */}
            {/*    <Input */}
            {/*      required */}
            {/*      multiline */}
            {/*      rows={3} */}
            {/*      placeholder="Enter changes ..." */}
            {/*      value={comment} */}
            {/*      onChange={setComment} */}
            {/*    /> */}
            {/*  </Box> */}
            {/* </Grid> */}
          </Grid>
          <Box mt={2} />
        </Box>
      }
      onClose={onCloseCb}
    />
  );
};

MoveModelVersionModal.defaultProps = {
  modelVersionData: {},
  model: {}
};

MoveModelVersionModal.propTypes = {
  model: PropTypes.shape({
    versions: PropTypes.array,
    registryModelId: PropTypes.string,
    modelName: PropTypes.string,
    isPublic: PropTypes.bool,
    description: PropTypes.string
  }),
  modelVersionData: PropTypes.shape({
    registryModelItemId: PropTypes.string,
    version: PropTypes.string
  }),
  workspace: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLastModelVersion: PropTypes.bool.isRequired
};

export default MoveModelVersionModal;
