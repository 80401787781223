import { convertMetadataV1 } from '@API/helpers/v2_helpers';
import { isArray, keys } from 'lodash';
import uniq from 'lodash/uniq';
import { useQuery } from 'react-query';
import { METADATA_FETCH_ONLY_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import { prepareRulesTreeForRequest } from '@shared/utils/filterHelpers';
import { axiosInstance, cancelWrapper } from '../api';
import useColumns from '../project/useColumns';
import useProject from '../project/useProject';
import {
  generateToColumn,
  generateToRule,
  getPanelsColumns,
  getSearchRules,
  toMultipleSort,
  uniqColumns
} from './utils';
import { NEW_VIEW } from '@/constants/dashboardConstants';

const getExperiments = ({
  columns,
  chainsQueryRules,
  ignorePagination = false,
  isArchive,
  multipleSort,
  limit,
  pageNumber,
  projectId,
  additionalRules,
  rulesTree,
  targetExperimentKeys,
  viewId
}) => {
  return cancelWrapper(async cancelToken => {
    if (!targetExperimentKeys) {
      // the preparation is done here because as a part of preparation we are doing
      // converting from relative data to absolute that invalidate queryParams every
      // time and course to unnecessary request to BE
      const preparedRulesTree = prepareRulesTreeForRequest(
        rulesTree,
        additionalRules
      );

      const {
        data: { experiments, experimentTotal: total }
      } = await axiosInstance({
        cancelToken,
        data: {
          deleted: isArchive,
          ignorePinnedExperimentsFirst: true,
          multipleSort,
          chainsQueryRules,
          limit,
          rulesTree: preparedRulesTree,
          projectId,
          page: pageNumber + 1,
          viewId
        },
        method: 'post',
        url: 'query/experiments/search'
      });

      const experimentKeys = experiments.map(
        experiment => experiment.experimentKey
      );

      const {
        data: { metadata }
      } = await axiosInstance({
        cancelToken,
        data: {
          columns,
          experiments: experimentKeys,
          projectId
        },
        method: 'post',
        url: 'query/metadata-v2'
      });

      const mappedMetadata = metadata.map((obj, index) => {
        const metadataV1 = obj[experimentKeys[index]];
        return convertMetadataV1(metadataV1);
      });

      return {
        experiments: mappedMetadata.map(m => ({
          ...m,
          tags: m.tag || []
        })),
        total
      };
    }

    if (targetExperimentKeys.length === 0) {
      return Promise.resolve({
        experiments: [],
        total: 0
      });
    }

    const pageExperimentKeys = ignorePagination
      ? targetExperimentKeys
      : targetExperimentKeys.slice(
          pageNumber * limit,
          (pageNumber + 1) * limit
        );

    const {
      data: { metadata }
    } = await axiosInstance({
      cancelToken,
      data: {
        columns,
        experiments: pageExperimentKeys,
        projectId
      },
      method: 'post',
      url: 'query/metadata-v2'
    });

    const mappedMetadata = metadata.map(obj => {
      const extercatedMetadata = obj[keys(obj)[0]];
      return convertMetadataV1(extercatedMetadata);
    });

    return {
      experiments: mappedMetadata.map(m => ({ ...m, tags: m.tag || [] })),
      total: targetExperimentKeys.length
    };
  });
};

const getQueryParams = ({ columns, metadataColumns, params, projectId }) => {
  const {
    groupsQuery,
    isArchive = false,
    chainsQueryRules,
    view,
    viewId = NEW_VIEW,
    targetExperimentKeys,
    ignorePagination
  } = params;

  if (!columns || !view) return null;
  const toColumn = generateToColumn(columns);
  const toRule = generateToRule(columns);

  const { query, table, panels } = view;
  const { columnOrders, columnSorting, pageNumber, pageSize, search } = table;

  const panelsColumns =
    panels && isArray(panels.panels) ? getPanelsColumns(panels.panels) : [];

  const formattedColumns = uniq([...columnOrders, 'Name'])
    .map(toColumn)
    .filter(column => Boolean(column));
  const processedColumns = uniqColumns([
    ...formattedColumns,
    ...metadataColumns,
    ...panelsColumns
  ]);

  if (targetExperimentKeys) {
    return {
      columns: processedColumns,
      limit: pageSize,
      pageNumber,
      projectId,
      targetExperimentKeys,
      ignorePagination
    };
  }

  // in case groupsQuery is specified, but array is empty that means we don't
  //  have enough information to query the experiments, and we need to wait
  // until user (or auto open group functionality) open low level group
  if (isArray(groupsQuery) && groupsQuery.length === 0) return null;

  let multipleSort = toMultipleSort(columnSorting, toColumn);
  // Default sorting
  if (!multipleSort.length) {
    multipleSort = toMultipleSort(
      [
        { columnName: 'runActive', direction: 'desc' },
        { columnName: 'end_server_timestamp', direction: 'desc' }
      ],
      toColumn
    );
  }

  const additionalRules = [
    ...(groupsQuery || []).map(toRule),
    ...getSearchRules(search)
  ];

  return {
    columns: processedColumns,
    isArchive,
    multipleSort,
    chainsQueryRules,
    limit: pageSize,
    pageNumber,
    projectId,
    additionalRules,
    rulesTree: query?.rulesTree,
    viewId,
    ignorePagination
  };
};

export default function useExperiments(params, config) {
  const { data: columns, isLoading: isLoadingColumns } = useColumns({
    extraCols: true
  });

  const { data: project, isLoading: isLoadingProject } = useProject();
  const projectId = params.projectId || project?.projectId;
  const metadataColumns = params.metadataColumns || METADATA_FETCH_ONLY_COLUMNS;

  const queryParams = getQueryParams({
    columns,
    metadataColumns,
    params,
    projectId
  });

  const isConfigEnabled = config?.enabled ?? true;
  const enabled =
    isConfigEnabled && !isLoadingColumns && !isLoadingProject && !!queryParams;

  return useQuery(
    ['experiments', queryParams],
    () => getExperiments(queryParams),
    {
      keepPreviousData: true,
      ...config,
      enabled
    }
  );
}
