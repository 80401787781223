import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Box } from '@material-ui/core';
import ColorBox from '@shared/components/ColorBox';
import classNames from './Tag.module.scss';

const Tag = ({ tag, color, className }) => (
  <ColorBox
    bgColor={color}
    className={classNames.dsTagChip}
    text={tag}
    filled
    noHover
  >
    <Box display="flex" alignItems="center">
      <span className={cx(classNames.dsTagChipLabel, className)}>{tag}</span>
    </Box>
  </ColorBox>
);

Tag.defaultProps = {
  color: null,
  className: null
};

Tag.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default Tag;
