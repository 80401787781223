import React, { useEffect, useMemo, useState } from 'react';
import { TextArea, TextInput } from '@ds';
import { BasicModal } from '@DesignSystem/modals';
import { useExperimentKeys } from '@experiment-management-shared/hooks';
import { useExperimentUniqueParams } from '@experiment-management-shared/api';

import { MetadataIcon } from '@Icons-outdated';
import { StyledTooltip } from '@DesignSystem/data-display';
import useUpdateHyperParameterMutation from '../../../api/hyperParameters/useUpdateHyperParameterMutation';

import classNames from '../HyperParametersTab.module.scss';
import {
  MAX_NESTED_PARAM_DEPTH,
  PARAM_FE_SYSTEM_SEPARATOR,
  PARAM_SDK_SEPARATOR
} from '../helpers';

type UpdateHyperParameterModalProps = {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  parameterKey?: string;
  parameterValue?: string;
  isNestedParamApplied: boolean;
};

const UpdateHyperParameterModal: React.FC<UpdateHyperParameterModalProps> = ({
  open,
  onClose,
  parameterKey: parentParameterKey = '',
  parameterValue: parentParameterValue = '',
  isEdit = false,
  isNestedParamApplied
}) => {
  const [
    { paramKey, paramValue, serverValidationErorr },
    setFormData
  ] = useState(() => ({
    paramKey: parentParameterKey,
    paramValue: parentParameterValue,
    serverValidationErorr: ''
  }));

  // it's shown on one experiment detail view only, so there is one experiment only
  const [experimentKey] = useExperimentKeys();
  const { data: params } = useExperimentUniqueParams();

  const isNameAlreadyTaken = useMemo(() => {
    if (isEdit) {
      return false;
    }

    return !!params?.find(param => param.name === paramKey);
  }, [isEdit, paramKey, params]);

  const updateHyperParameterMutation = useUpdateHyperParameterMutation();

  const handleChangeInput = (name: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
      serverValidationErorr: ''
    }));
  };

  useEffect(() => {
    if (!open) {
      setFormData({ paramKey: '', paramValue: '', serverValidationErorr: '' });
    }
  }, [open]);

  useEffect(() => {
    if (parentParameterKey) {
      handleChangeInput('paramKey', parentParameterKey);
    }
  }, [parentParameterKey]);

  useEffect(() => {
    if (parentParameterValue) {
      handleChangeInput('paramValue', parentParameterValue);
    }
  }, [parentParameterValue]);

  const validationErrorMsg = useMemo(() => {
    if (isNameAlreadyTaken) {
      return 'This hyperparameter already exists';
    }

    if (!isNestedParamApplied) {
      return '';
    }

    return paramKey?.split(PARAM_FE_SYSTEM_SEPARATOR)?.length >
      MAX_NESTED_PARAM_DEPTH
      ? `The maximum depth of nested params is ${MAX_NESTED_PARAM_DEPTH}`
      : ``;
  }, [isNameAlreadyTaken, isNestedParamApplied, paramKey]);

  const handlePrimaryButtonClick = () => {
    updateHyperParameterMutation.mutate(
      {
        experimentKey,
        name: paramKey,
        value: paramValue
      },
      {
        onSuccess: onClose,
        onError: e => {
          if (!e.response?.data.msg) {
            return;
          }
          setFormData(prev => ({
            ...prev,
            serverValidationErorr: e.response?.data.msg || 'validation error'
          }));
        }
      }
    );
  };

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title={isEdit ? 'Edit parameter' : 'Add parameter'}
      content={
        <div className={classNames.addParameterModalContainer}>
          <div className={classNames.enterKeyField}>
            <div className={classNames.addParameterInputLabel}>
              Enter key
              {isNestedParamApplied && (
                <StyledTooltip
                  title="You can add a key in a nested way."
                  placement="top"
                >
                  <div className={classNames.infoIconContainer}>
                    <MetadataIcon />
                  </div>
                </StyledTooltip>
              )}
            </div>
            <TextArea
              value={paramKey}
              onChange={event => {
                const textarea = event.target;
                const BORDER = 2;
                textarea.style.height = 'inherit';
                textarea.style.height = `${textarea.scrollHeight + BORDER}px`;

                handleChangeInput('paramKey', event.target.value);
              }}
              disabled={isEdit}
              placeholder={
                isNestedParamApplied
                  ? `param_name${PARAM_SDK_SEPARATOR}sub_param1${PARAM_SDK_SEPARATOR}sub_param 2...`
                  : 'param_name'
              }
              invalid={!!validationErrorMsg}
              helperText={validationErrorMsg}
              rows={1}
              style={{
                height: 'inherit',
                maxHeight: '96px'
              }}
            />
          </div>
          <div className={classNames.enterValueField}>
            <div className={classNames.addParameterInputLabel}>Enter value</div>
            <TextInput
              value={paramValue}
              onChange={event => {
                handleChangeInput('paramValue', event.target.value);
              }}
              placeholder="Enter value"
            />
          </div>

          {serverValidationErorr && (
            <p className={classNames.serverErrorText}>
              {serverValidationErorr}
            </p>
          )}
        </div>
      }
      primaryButtonText={isEdit ? 'Update' : 'Create'}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      isPrimaryButtonDisabled={!!validationErrorMsg || !paramValue || !paramKey}
    />
  );
};

export default UpdateHyperParameterModal;
