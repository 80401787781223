import { KIND_TYPE, KIND_UNIQ_SYMBOL } from '@API/helpers/v2_helpers';
import { SortModal } from '@DesignSystem/controllers';
import { Box } from '@material-ui/core';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { trackEvent } from '@shared/utils/eventTrack';
import {
  minMaxColumnToOption,
  formatValueForSelectOption
} from '@shared/utils/selectComponentHelper';
import { DEFAULT_SORTING } from '@experiment-management-shared/utils/view';
import useColumns from '@API/project/useColumns';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import { Select } from '@ds';

const formatColumnsToSelectOptions = columns => {
  return columns.map(column => {
    const subOptions = minMaxColumnToOption(column);
    return formatValueForSelectOption(
      column.name,
      column.source,
      [],
      subOptions
    );
  });
};

const SortPopover = ({ columnSorting, onChange, ...PopoverProps }) => {
  const { data: columns, isLoading } = useColumns();

  const [sorting, setSorting] = useState(columnSorting);

  const selectOptions = useMemo(() => {
    const filteredColumns = columns.filter(({ source }) => source !== 'tags');
    const options = formatColumnsToSelectOptions(filteredColumns);

    return options.map(option => {
      const isSelectedOption = sorting.some(
        ({ columnName }) => columnName === option.value
      );

      if (option.extraOptions?.length) {
        option.extraOptions = option.extraOptions.map(extraOption => {
          const isSelectedExtraOption = sorting.some(
            ({ columnName }) => columnName === extraOption.value
          );

          if (isSelectedExtraOption) {
            return { ...extraOption, isDisabled: true };
          }

          return extraOption;
        });
      }
      if (isSelectedOption) {
        return { ...option, isDisabled: true };
      }
      return option;
    });
  }, [columns, sorting]);

  useEffect(() => {
    setSorting(columnSorting);
  }, [columnSorting]);

  if (isLoading) return null;

  const updateSorting = newSorting => {
    setSorting(newSorting);

    const newColumnSorting = newSorting.filter(({ columnName }) => columnName);

    if (!isEqual(newColumnSorting, columnSorting)) {
      onChange(newColumnSorting);
    }
  };

  const handleReset = () => {
    updateSorting(DEFAULT_SORTING);
  };

  const handleToggleDirection = index => {
    updateSorting(
      sorting.map((sortingRow, mapIndex) => {
        if (mapIndex === index) {
          const newDirection = sortingRow.direction === 'asc' ? 'desc' : 'asc';

          return { ...sortingRow, direction: newDirection };
        }

        return sortingRow;
      })
    );
  };

  const handleChangeFields = fields => {
    const nextFields = fields.map(field => {
      if (field === '') return { columnName: '', direction: 'desc' };

      return find(sorting, { columnName: field.sortKey });
    });

    updateSorting(nextFields);
  };

  const handleChangeField = (sortKey, index) => {
    updateSorting(
      sorting.map((field, fieldIndex) => {
        if (fieldIndex === index) {
          return { ...field, columnName: sortKey };
        }

        return field;
      })
    );
  };

  const hasEmptyValue = sorting.some(({ columnName }) => !columnName);

  const currentFields = sorting.map(({ columnName, direction }) => ({
    sortKey: columnName,
    direction
  }));

  return (
    <SortModal
      currentFields={currentFields}
      onChangeFields={handleChangeFields}
      onReset={handleReset}
      onToggleDirection={index => handleToggleDirection(index)}
      isAddFieldButtonDisabled={hasEmptyValue}
      renderSelectField={(sortKey, index) => {
        return (
          <Box width="246px" height="36px">
            <Select
              value={sortKey}
              onValueChange={key => {
                const [columnName, kind] = key.split(KIND_UNIQ_SYMBOL);

                if (kind === KIND_TYPE.MAX || kind === KIND_TYPE.MIN) {
                  trackEvent(dashboardEvents.SELECT_METRIC_KIND_OPTION, {
                    dropdownName: 'sort-by',
                    columnName,
                    kind
                  });
                }

                handleChangeField(key, index);
              }}
              maxWidth={244}
              truncateMiddle
              options={selectOptions}
              displaySource
              key={sortKey}
              isRegexSearchEnabled
              maxHeight={300}
              extraOptionsProps={{
                useListAsAnchor: true,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
              isClearable={false}
              isSearchable
            />
          </Box>
        );
      }}
      {...PopoverProps}
    />
  );
};

SortPopover.propTypes = {
  columnSorting: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SortPopover;
