import { OptionType } from '@design-system/types';
import React from 'react';
import { PlaceholderProps, components } from 'react-select';

const Placeholder: React.FC<PlaceholderProps<OptionType>> = props => {
  const { isFocused, selectProps } = props;

  if (isFocused && selectProps.searchPlaceholder) {
    return (
      <components.Placeholder {...props}>
        {selectProps.searchPlaceholder}
      </components.Placeholder>
    );
  }

  return <components.Placeholder {...props} />;
};

export default Placeholder;
