import { useParams } from 'react-router-dom';
import useAllUserWorkspaces from './useAllUserWorkspaces';

interface Params {
  workspace: string;
}
export default function useIsUserMemberOfWorkspace() {
  const { workspace } = useParams<Params>();
  const { data: workspaces = [], isLoading } = useAllUserWorkspaces();

  return {
    data: isLoading
      ? false
      : workspaces?.some(({ name }) => name === workspace),
    isLoading
  };
}
