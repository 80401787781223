import React from 'react';
import { useParams } from 'react-router';

import PageInfoMessage from '@shared/components/PageInfoMessage';

const PrivateProjectMessage = () => {
  const { projectName, workspace } = useParams();

  return (
    <PageInfoMessage
      title={`${workspace}/${projectName} · Error`}
      goBackLink={`/${workspace}`}
      message="This is a private project."
    />
  );
};

export default PrivateProjectMessage;
