import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import defaultTo from 'lodash/defaultTo';

import { createSelector } from 'reselect';

import { DEFAULT_EXPERIMENT_DETAILS_TEMPLATE } from '@experiment-details/constants/template';
import {
  COLOR_SINGLE_EXPERIMENT_KEY,
  METRIC_COLORS_V2
} from '@experiment-details/constants/panels';
import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';

import { chartActionTypes } from '@/constants/actionTypes';

import {
  selectExperimentKeys,
  selectIsComparePage
} from '../ui/experimentsUiReducer';
import { CHART_COLORS } from '@/constants/colorConstants';

const initialChartState = {
  currentTemplate: DEFAULT_EXPERIMENT_DETAILS_TEMPLATE,
  projectTemplate: {
    template_name: '',
    charts: []
  },
  loading: true,
  isLoadingProjectTemplate: true,
  isLoadingUserTemplates: true,
  layouts: {},
  metricNames: [],
  userTemplates: []
};

const chartsReducer = (state = initialChartState, action) => {
  const { type, payload } = action;

  if (type === chartActionTypes.RECEIVED_PROJECT_CHART_TEMPLATE) {
    const { experimentKey, projectTemplate } = payload;

    const currentState = state[experimentKey]
      ? state[experimentKey]
      : initialChartState;

    return {
      ...state,
      [experimentKey]: {
        ...currentState,
        projectTemplate,
        isLoadingProjectTemplate: false
      }
    };
  }

  if (type === chartActionTypes.SET_CURRENT_TEMPLATE) {
    const { experimentKey, template } = payload;

    return {
      ...state,
      [experimentKey]: {
        ...state[experimentKey],
        currentTemplate: {
          ...initialChartState.currentTemplate,
          ...template
        }
      }
    };
  }

  if (type === chartActionTypes.RECEIVED_USER_TEMPLATES) {
    const { experimentKey, userTemplates } = payload;

    const currentState = state[experimentKey]
      ? state[experimentKey]
      : initialChartState;

    return {
      ...state,
      [experimentKey]: {
        ...currentState,
        userTemplates,
        isLoadingUserTemplates: false
      }
    };
  }

  if (type === chartActionTypes.RECEIVED_SAVED_TEMPLATE) {
    const { experimentKey, template } = payload;
    const currentState = state[experimentKey]
      ? state[experimentKey]
      : initialChartState;

    const userTemplates = currentState.userTemplates.filter(userTemplate => {
      return userTemplate.template_id !== template.template_id;
    });

    const projectTemplate =
      currentState.projectTemplate.template_id === template.template_id
        ? template
        : currentState.projectTemplate;

    return {
      ...state,
      [experimentKey]: {
        ...currentState,
        projectTemplate,
        userTemplates: [...userTemplates, template],
        isTemplateChanged: false
      }
    };
  }

  if (type === chartActionTypes.UPDATE_CHART_TEMPLATE) {
    const { experimentKey, updatedTemplate } = payload;

    const currentState = state[experimentKey]
      ? state[experimentKey]
      : initialChartState;

    const deepUpdates = Object.values(EXPERIMENT_VIEW_TAB_FIELDS).reduce(
      (updates, key) => {
        updates[key] = {
          ...initialChartState.currentTemplate[key],
          ...currentState.currentTemplate[key],
          ...updatedTemplate[key]
        };

        return updates;
      },
      {}
    );

    const currentTemplate = {
      ...initialChartState.currentTemplate,
      ...currentState.currentTemplate,
      ...updatedTemplate,
      ...deepUpdates
    };

    return {
      ...state,
      [experimentKey]: {
        ...currentState,
        currentTemplate,
        isTemplateChanged: action.showUnsavedChangesWarning
      }
    };
  }

  return state;
};

export default chartsReducer;

export const getMetricCharts = state => {
  return state.runDetails.chartsPlotly;
};

export const getMetricChartsTemplate = createSelector(
  [getMetricCharts, selectExperimentKeys],
  (charts, experimentKeys) => {
    const baseExperimentKey = experimentKeys[0];

    return get(
      charts,
      [baseExperimentKey, 'currentTemplate'],
      DEFAULT_EXPERIMENT_DETAILS_TEMPLATE
    );
  }
);

const getMetricColorsV2 = createSelector(
  [getMetricChartsTemplate, selectIsComparePage],
  (template, isCompare) => {
    return get(template, [METRIC_COLORS_V2, COLOR_SINGLE_EXPERIMENT_KEY], {
      isSingleExperimentColor: isCompare,
      colors: []
    });
  }
);

export const isSingleExperimentColorByDefault = createSelector(
  [getMetricColorsV2],
  metricColorsV2 => metricColorsV2.isSingleExperimentColor
);

const getLegacyMetricColors = createSelector(
  [getMetricChartsTemplate],
  template => get(template, 'metricColors', [])
);

export const getMetricColorsForCurrentTemplate = createSelector(
  [getMetricColorsV2, getLegacyMetricColors, isSingleExperimentColorByDefault],
  (metricColorsV2, legacyMetricColors, isSingleExperimentColor) => {
    const { colors } = metricColorsV2;

    const metricColors = uniqBy(
      [...colors, ...legacyMetricColors],
      'metricName'
    );

    const firstColor = defaultTo(metricColors[0], { color: CHART_COLORS[0] })
      .color;
    return metricColors.reduce((map, metricColor) => {
      const { metricName, color } = metricColor;
      map[metricName] = isSingleExperimentColor ? firstColor : color;
      return map;
    }, {});
  }
);
