import { useQuery } from 'react-query';

import { useActiveWorkspace } from '@shared/hooks';

import { AlertNotification, AlertNotificationAPI } from '@mpm/types';
import api, { QueryConfig } from '@shared/api';

type AlertNotificationsParams = {
  modelId: string;
  page: number;
  pageSize: number;
};

type AlertNotificationsAPI = {
  alertNotifications: AlertNotificationAPI[];
  closedNotificationsTotal: number;
  openNotificationsTotal: number;
};

// Hook response
type NotificationsResponse = {
  alertNotifications: AlertNotification[];
  closedNotificationsTotal: number;
  openNotificationsTotal: number;
};

const getAlertNotifications = async ({
  workspaceId,
  modelId,
  ...params
}: AlertNotificationsParams & { workspaceId: string }) => {
  const { data } = await api.get<AlertNotificationsAPI>(
    `mpm/${workspaceId}/models/${modelId}/alert-notifications`,
    { params }
  );

  return {
    ...data,
    alertNotifications: data.alertNotifications.map(alertNotification =>
      alertNotificationTransform(alertNotification)
    )
  };
};

export function useAlertNotifications(
  params: AlertNotificationsParams,
  config: QueryConfig<NotificationsResponse>
) {
  const activeWorkspace = useActiveWorkspace();
  return useQuery(
    ['alertNotifications', { workspaceId: activeWorkspace?.id, ...params }],
    () =>
      getAlertNotifications({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config,
      enabled: !!activeWorkspace?.id
    }
  );
}

export const alertNotificationTransform = (
  notification: AlertNotificationAPI
): AlertNotification => {
  return {
    id: notification.id,
    intervalType: notification.intervalType,
    modelVersion: notification.modelVersion,
    notificationDate: notification.creationDate,
    ruleId: notification.ruleId,
    status: notification.status,
    trigger: notification.ruleName
  };
};
