import React from 'react';
import { LinkButton, TextButton, Tooltip } from '@ds';
import {
  DSCheckMarkIcon,
  DSCloseIcon,
  DSDefaultViewFilledIcon,
  DSRunningIcon
} from '@ds-icons';
import moment from 'moment/moment';
import { PYTHON_ENVIRONMENTS_COLUMN } from './constants';

import classes from './PythonEnvironment.module.scss';
import {
  PythonEnvironmentVersion,
  PythonEnvironmentVersionStatusType
} from '@shared/types';

type TableColumn = {
  type: string;
  name: string;
  title: string;
};

type StatusMap = {
  [key in PythonEnvironmentVersionStatusType]: {
    className: string;
    Icon: React.ReactNode;
    tooltipText: string;
  };
};

const STATUS_MAP: StatusMap = {
  ERROR: {
    tooltipText: 'There was an error while creating the python environment',
    className: classes.errorStatus,
    Icon: <DSCloseIcon />
  },
  PENDING: {
    tooltipText: 'Building is pending',
    className: classes.loadingStatus,
    Icon: <DSRunningIcon />
  },
  IN_PROGRESS: {
    tooltipText:
      'Building is in process. This process can take up to 20 minutes',
    className: classes.loadingStatus,
    Icon: <DSRunningIcon />
  },
  READY: {
    tooltipText: 'Valid python environment',
    className: classes.successStatus,
    Icon: <DSCheckMarkIcon />
  }
};

type PythonEnvironmentRowProps = {
  row: PythonEnvironmentVersion;
};

export const PYTHON_ENVIRONMENT_COLUMNS: TableColumn[] = [
  {
    name: PYTHON_ENVIRONMENTS_COLUMN.VERSION,
    type: 'string',
    title: 'Version'
  },
  {
    name: PYTHON_ENVIRONMENTS_COLUMN.CREATED_AT,
    type: 'string',
    title: 'Created Date'
  },
  {
    name: PYTHON_ENVIRONMENTS_COLUMN.CREATED_BY,
    type: 'string',
    title: 'Created By'
  },
  {
    name: PYTHON_ENVIRONMENTS_COLUMN.STATUS,
    type: 'string',
    title: 'Status'
  },
  {
    name: PYTHON_ENVIRONMENTS_COLUMN.LOGS,
    type: 'string',
    title: 'Build Logs'
  }
];

export const PYTHON_ENVIRONMENT_VERSION_COLUMN_WIDTHS = {
  [PYTHON_ENVIRONMENTS_COLUMN.VERSION]: {
    width: 400
  }
};

export const getPythonEnvironmentVersionDataTypes = (
  openModal: (row: PythonEnvironmentVersion) => void
) => [
  {
    cols: [PYTHON_ENVIRONMENTS_COLUMN.VERSION],
    cell: ({ row }: PythonEnvironmentRowProps) => (
      <div className={classes.versionColumn}>
        <Tooltip wrapperDisplay="flex" content="Click to see the packages">
          <TextButton type="tertiary" onClick={() => openModal(row)}>
            {row.version}
          </TextButton>
        </Tooltip>
        {row.active && (
          <Tooltip wrapperDisplay="flex" content="Current active version">
            <DSDefaultViewFilledIcon />
          </Tooltip>
        )}
      </div>
    )
  },
  {
    cols: [PYTHON_ENVIRONMENTS_COLUMN.STATUS],
    cell: ({ row }: PythonEnvironmentRowProps) => {
      const statusConfig = STATUS_MAP[row.status] || {};
      return (
        <div className={statusConfig.className}>
          <Tooltip content={statusConfig.tooltipText}>
            <div>{statusConfig.Icon}</div>
          </Tooltip>
        </div>
      );
    }
  },
  {
    cols: [PYTHON_ENVIRONMENTS_COLUMN.CREATED_AT],
    cell: ({ row }: PythonEnvironmentRowProps) =>
      moment(row.createdAt).format('DD MMM YYYY')
  },
  {
    cols: [PYTHON_ENVIRONMENTS_COLUMN.LOGS],
    cell: ({ row }: PythonEnvironmentRowProps) => (
      <LinkButton to={row.buildLogsLink} text="View" openInNewTab />
    )
  }
];
