import {
  CONTACT_US_URL,
  DOCS_ROOT_URL,
  SLACK_INVITE_URL
} from '@/lib/appConstants';

export const getDocLink = (url = '') => `${DOCS_ROOT_URL}/${url}`;

export const getToChatWithUsLink = () => {
  return CONTACT_US_URL || SLACK_INVITE_URL;
};
