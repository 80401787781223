import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, TextButton } from '@ds';
import FullWidthSidebar from '@design-system-outdated/components/FullWidthSidebar/FullWidthSidebar';
import useExperimentModels from '@shared/api/useExperimentsModels';
import { Box } from '@material-ui/core';
import {
  MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE,
  MODEL_STREAMLINE_REGISTRATION_VISIBILITY
} from '@shared/constants/model-streamline-registration';
import {
  findModelDirInAssets,
  getRegisteredModelURL
} from '@shared/utils/model-streamline-registration';
import { useHistory, useParams } from 'react-router';
import useExperimentAssets from '@shared/api/useExperimentsAssets';
import useModelRegistryNameValidationMutation from '@shared/api/useModelRegistryNameValidationMutation';
import useModelRegistryVersionValidationMutation from '@shared/api/useModelRegistryVersionValidationMutation';
import useModelRegistryAddNewModelMutation from '@shared/api/useModelRegistryAddNewModelMutation';
import useModelRegistryAddNewVersOfExistModelMutation from '@shared/api/useModelRegistryAddNewVersOfExistModelMutation';
import useModelRegistryList from '@shared/api/useModelRegistryList';
import useDebounce from '@shared/hooks/useDebounce';
import RegisterModelFlowError from './RegisterModelFlowError';
import RegisterModelFlowSuccess from './RegisterModelFlowSuccess';
import RegisterModelFlowNoExperimentModels from './RegisterModelFlowNoExperimentModels';
import RegisterModelFlowAsNewVersion from './RegisterModelFlowAsNewVersion';
import RegisterModelFlowAsNewModel from './RegisterModelFlowAsNewModel';
import classNames from './RegisterModel.module.scss';

const DEBOUNCE_DELAY = 500;

const RegisterModelSidebar = ({ experimentKey, open, setOpen }) => {
  const { workspace } = useParams();
  const history = useHistory();

  // --------------
  // load data for the models
  const { data: allRegisteredModels = [] } = useModelRegistryList(workspace);
  const registerNewModelMutation = useModelRegistryAddNewModelMutation({
    workspaceName: workspace,
    experimentKey
  });
  const registerNewVersionOfExistingModelMutation = useModelRegistryAddNewVersOfExistModelMutation(
    {
      workspaceName: workspace,
      experimentKey
    }
  );
  const modelRegistryNameValidationMutation = useModelRegistryNameValidationMutation(
    workspace
  );
  const modelRegistryVersionValidationMutation = useModelRegistryVersionValidationMutation();

  const ifRegisteringModelInProgress =
    registerNewModelMutation.isLoading ||
    registerNewVersionOfExistingModelMutation.isLoading ||
    modelRegistryVersionValidationMutation.isLoading;

  // get all models for the experiment in all Workspaces!!!
  const { data: experimentModels = [], isLoading } = useExperimentModels(
    experimentKey,
    {
      enabled: !!experimentKey,
      refetchOnMount: !open
    }
  );

  const { data: experimentAssets = [] } = useExperimentAssets(experimentKey, {
    enabled: !!experimentKey,
    refetchOnMount: !open
  });
  // --------------
  // END load data for the models

  // --------------------------
  // All needed data is state and memo
  const [creationStatus, setCreationStatus] = useState(
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION
  );
  const [description, setDescription] = useState('');
  const [selectedModel, setSelectedModel] = useState(null);
  const [
    modelNameToRegister,
    setModelNameToRegister,
    debouncedModelNameToRegister
  ] = useDebounce({
    value: '',
    delay: DEBOUNCE_DELAY
  });
  const [
    modelVersionToRegister,
    setModelVersionToRegister,
    debouncedModelVersionToRegister
  ] = useDebounce({
    value: '',
    delay: DEBOUNCE_DELAY * 3
  });
  const [validationNewModelName, setValidationNewModelName] = useState({});
  const [validationNewModelVersion, setValidationNewModelVersion] = useState(
    {}
  );
  const [modelVisibility, setModelVisibility] = useState(
    MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE
  );

  const allRegisteredModelsRegistryIds = useMemo(
    () => allRegisteredModels.map(({ registryModelId }) => registryModelId),
    [allRegisteredModels]
  );

  const experimentModelsInCurrentWS = useMemo(
    () =>
      experimentModels.map(experimentModel => ({
        ...experimentModel,
        registryRecords: experimentModel?.registryRecords
          ?.filter(record =>
            allRegisteredModelsRegistryIds.includes(record.registryModelId)
          )
          .filter(Boolean)
      })),
    [experimentModels, allRegisteredModelsRegistryIds]
  );

  const allAvailableExperimentModelsOptions = useMemo(
    () =>
      experimentModelsInCurrentWS?.map(
        ({
          modelName,
          experimentKey: experimentKeyIns,
          experimentModelId
        }) => ({
          value: experimentModelId,
          label: modelName,
          experimentKey: experimentKeyIns
        })
      ),
    [experimentModelsInCurrentWS]
  );

  const registryRecordsForSelectedModel = useMemo(() => {
    const selectedModelRecords = selectedModel
      ? experimentModels.filter(
          ({ modelName }) => modelName === selectedModel?.label
        )
      : [];
    return get(selectedModelRecords, '0.registryRecords', []);
  }, [experimentModels, selectedModel]);
  const allRegisteredModelsOptions = useMemo(
    () =>
      allRegisteredModels?.map(({ modelName, latestVersion }) => ({
        value: modelName,
        label: modelName,
        latestVersion
      })),
    [allRegisteredModels]
  );

  const modelAssetsInfo = useMemo(() => {
    const modelsDirFiles = selectedModel
      ? findModelDirInAssets(experimentAssets, selectedModel?.label)
      : [];

    return {
      assetId: get(modelsDirFiles, '0.assetId'),
      assetPath: get(modelsDirFiles, '0.dir')?.split('/')
    };
  }, [experimentAssets, selectedModel]);

  const latestVersionForSelectedModel = useMemo(() => {
    const records = modelNameToRegister
      ? allRegisteredModelsOptions.filter(
          ({ label }) => label === modelNameToRegister
        )
      : [];

    return get(records, '0.latestVersion.version');
  }, [allRegisteredModelsOptions, modelNameToRegister]);

  const isFirstModelRegistration = useMemo(() => !allRegisteredModels?.length, [
    allRegisteredModels
  ]);

  const allAvailableExperimentModelsLen =
    allAvailableExperimentModelsOptions?.length;
  const cleanForm = () => {
    setCreationStatus(MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION);
    setModelVisibility(MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE);
    setDescription('');
    setSelectedModel('');
    setModelNameToRegister('');
    setModelVersionToRegister('');
  };
  // --------------------------
  // END All needed data is state and memo

  // --------------------------
  // use effects to update the states
  useEffect(() => {
    if (!open) {
      cleanForm();
    }
  }, [open]);

  // Clean the selection of model name and model version when we change the type
  // of model we want to create - new or existing with a new version
  useEffect(() => {
    if (creationStatus !== MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS) {
      setModelNameToRegister('');
    }

    setModelVersionToRegister('');
    setDescription('');
    setModelVisibility(MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PRIVATE);
  }, [creationStatus]);

  // update creationStatus when all experimentModels loaded
  useEffect(() => {
    if (isLoading) return;
    if (!allAvailableExperimentModelsLen) {
      setCreationStatus(MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NO_MODELS);
      return;
    }

    if (!registryRecordsForSelectedModel?.length) {
      setCreationStatus(MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL);
      return;
    }

    setCreationStatus(MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION);
  }, [
    isLoading,
    allAvailableExperimentModelsLen,
    registryRecordsForSelectedModel
  ]);

  useEffect(() => {
    (async () => {
      if (
        creationStatus === MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL
      ) {
        let validationResult;
        if (debouncedModelNameToRegister) {
          validationResult = await modelRegistryNameValidationMutation.mutateAsync(
            debouncedModelNameToRegister
          );
        }

        return setValidationNewModelName({
          ...validationResult,
          isValid: !validationResult?.modelNameExists
        });
      }

      return setValidationNewModelName({
        ...(validationNewModelName || {}),
        isValid: true
      });
    })();
  }, [debouncedModelNameToRegister]);
  useEffect(() => {
    (async () => {
      if (
        [
          MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL,
          MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION
        ].includes(creationStatus)
      ) {
        let registryModelId;

        if (debouncedModelNameToRegister) {
          registryModelId = allRegisteredModels.find(
            ({ modelName }) => debouncedModelNameToRegister === modelName
          )?.registryModelId;
        }

        let validationResult;
        if (debouncedModelVersionToRegister) {
          validationResult = await modelRegistryVersionValidationMutation.mutateAsync(
            {
              version: debouncedModelVersionToRegister,
              registryModelId
            }
          );
          return setValidationNewModelVersion({
            ...validationResult,
            isValid:
              validationResult?.validVersion && !validationResult?.versionExists
          });
        }
      }

      return setValidationNewModelVersion({
        isValid: true
      });
    })();
  }, [debouncedModelVersionToRegister, debouncedModelNameToRegister]);

  // --------------------------
  // END use effects to update the states

  // CHECK IF FOM VALID AFTER ALL DATA UPDATED
  // ===================================================
  const isFormDataValid = useMemo(() => {
    return (
      validationNewModelVersion?.isValid && validationNewModelName?.isValid
    );
  }, [validationNewModelVersion, validationNewModelName]);
  // CHECK IF FOM VALID AFTER ALL DATA UPDATED
  // ===================================================

  const registerModelSuccess = useCallback(() => {
    history.push({
      pathname: getRegisteredModelURL(
        workspace,
        validationNewModelName?.finalModelName || debouncedModelNameToRegister
      )
    });
    setOpen(false);
    cleanForm();
  }, [
    workspace,
    validationNewModelName?.finalModelName,
    debouncedModelNameToRegister
  ]);

  const onSubmitModelCreation = useCallback(async () => {
    let error;

    if (creationStatus === MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL) {
      try {
        await registerNewModelMutation.mutateAsync({
          experimentModelId: selectedModel?.value,
          registryModelName: modelNameToRegister,
          description,
          isPublic:
            modelVisibility === MODEL_STREAMLINE_REGISTRATION_VISIBILITY.PUBLIC,
          version: modelVersionToRegister
        });
      } catch (err) {
        error = err;
      }
    } else {
      try {
        await registerNewVersionOfExistingModelMutation.mutateAsync({
          experimentModelId: selectedModel?.value,
          registryModelName: modelNameToRegister,
          description,
          version: modelVersionToRegister
        });
      } catch (err) {
        error = err;
      }
    }

    setCreationStatus(
      error
        ? MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.ERROR
        : MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS
    );
  }, [
    creationStatus,
    selectedModel,
    modelVisibility,
    modelNameToRegister,
    modelVersionToRegister,
    description
  ]);

  const renderContent = () => {
    let content;

    if (!allAvailableExperimentModelsLen) {
      return (
        <RegisterModelFlowNoExperimentModels
          experimentModels={experimentModelsInCurrentWS}
        />
      );
    }

    switch (creationStatus) {
      case MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.ERROR:
        content = (
          <RegisterModelFlowError
            onClick={() => {
              setCreationStatus(
                MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION
              );
              cleanForm();
            }}
          />
        );
        break;
      case MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS:
        content = (
          <RegisterModelFlowSuccess
            onSuccess={registerModelSuccess}
            onClose={() => setOpen(false)}
          />
        );
        break;
      case MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION:
        content = (
          <RegisterModelFlowAsNewVersion
            workspace={workspace}
            experimentKey={experimentKey}
            latestVersion={latestVersionForSelectedModel}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            modelNameToRegister={modelNameToRegister}
            setModelNameToRegister={setModelNameToRegister}
            modelVersion={modelVersionToRegister}
            setModelVersion={setModelVersionToRegister}
            description={description}
            setDescription={setDescription}
            allRegisteredModelsOptions={allRegisteredModelsOptions}
            allAvailableModelsOptions={allAvailableExperimentModelsOptions}
            setCreationStatus={setCreationStatus}
            creationStatus={creationStatus}
            registryRecords={registryRecordsForSelectedModel}
            modelAssetsInfo={modelAssetsInfo}
            validationNewModelVersion={validationNewModelVersion}
          />
        );
        break;
      case MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL:
        content = (
          <RegisterModelFlowAsNewModel
            isFirstModelRegistration={isFirstModelRegistration}
            modelAssetsInfo={modelAssetsInfo}
            experimentKey={experimentKey}
            creationStatus={creationStatus}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            modelNameToRegister={modelNameToRegister}
            setModelNameToRegister={setModelNameToRegister}
            modelVersion={modelVersionToRegister}
            setModelVersion={setModelVersionToRegister}
            description={description}
            setDescription={setDescription}
            modelVisibility={modelVisibility}
            setModelVisibility={setModelVisibility}
            setCreationStatus={setCreationStatus}
            allAvailableModelsOptions={allAvailableExperimentModelsOptions}
            validationNewModelName={validationNewModelName}
            validationNewModelVersion={validationNewModelVersion}
          />
        );
        break;
      default:
        content = (
          <RegisterModelFlowNoExperimentModels
            experimentModels={experimentModelsInCurrentWS}
          />
        );
        break;
    }

    return <Box className={classNames.registerModelContent}>{content}</Box>;
  };

  const secondaryButton = useMemo(() => {
    let buttonContent = 'Cancel';

    if (
      [
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS,
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.ERROR,
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NO_MODELS
      ].includes(creationStatus)
    ) {
      buttonContent = 'Close';
    }

    return (
      <TextButton
        onClick={() => setOpen(false)}
        disabled={ifRegisteringModelInProgress}
        data-test="streamline-model-sidebar-secondary-button"
      >
        {buttonContent}
      </TextButton>
    );
  }, [
    creationStatus,
    allAvailableExperimentModelsLen,
    ifRegisteringModelInProgress
  ]);

  const primaryButton = useMemo(() => {
    if (
      !allAvailableExperimentModelsLen ||
      [
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS,
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.ERROR,
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NO_MODELS
      ].includes(creationStatus)
    ) {
      return null;
    }

    const isButtonDisabled =
      !(
        selectedModel &&
        modelNameToRegister &&
        modelVersionToRegister &&
        isFormDataValid
      ) || ifRegisteringModelInProgress;

    return (
      <Button
        onClick={onSubmitModelCreation}
        disabled={isButtonDisabled}
        data-test="streamline-model-sidebar-primary-button"
      >
        Register your model
      </Button>
    );
  }, [
    selectedModel,
    modelVisibility,
    modelNameToRegister,
    modelVersionToRegister,
    isFormDataValid,
    creationStatus,
    allAvailableExperimentModelsLen,
    description,
    ifRegisteringModelInProgress
  ]);

  const titleText = useMemo(() => {
    if (
      [
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS,
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.ERROR,
        MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NO_MODELS
      ].includes(creationStatus)
    ) {
      return null;
    }

    if (isFirstModelRegistration) {
      return 'Easily keep track of your models from development to production and use Model Registry webhooks to automate your deployment process';
    }

    return allAvailableExperimentModelsLen
      ? `You have ${allAvailableExperimentModelsLen} ${
          allAvailableExperimentModelsLen === 1 ? 'model' : 'models'
        } in your experiment`
      : null;
  }, [
    creationStatus,
    allAvailableExperimentModelsLen,
    isFirstModelRegistration
  ]);

  return (
    <FullWidthSidebar
      dataTest="streamline-model-sidebar"
      isOpen={open}
      onClose={() => setOpen(false)}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title={
        creationStatus === MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.SUCCESS
          ? 'Register experiment models'
          : 'Add model to Comet Model Registry'
      }
      titleText={titleText}
      renderContent={renderContent}
    />
  );
};

RegisterModelSidebar.defaultProps = {};

RegisterModelSidebar.propTypes = {
  experimentKey: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default RegisterModelSidebar;
