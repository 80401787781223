import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import './DateRange.scss';
import DateRangeCustomInput from './DateRangeCustomInput';

const initialMaxDate = new Date(new Date().toISOString());
const DateRange = ({
  startDate,
  endDate,
  onChange: onNewRange,
  calcMaxDate,
  onOpenStatusChange
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localStart, setLocalStart] = useState(startDate);
  const [localEnd, setLocaEnd] = useState(endDate);
  const [maxDate, setMaxDate] = useState(initialMaxDate);

  useEffect(() => {
    if (!startDate && !endDate) {
      setMaxDate(initialMaxDate);
    }
  }, [startDate, endDate]);

  const handleChange = ([start, end]) => {
    if (start && !end) setMaxDate(calcMaxDate(start));
    setLocalStart(start);
    setLocaEnd(end);
    if (start && end) return onNewRange([start, end]);
  };

  const handleCalendarOpen = () => {
    onOpenStatusChange(true);
    setLocalStart(startDate);
    setLocaEnd(endDate);
    setIsOpen(true);
  };
  const handleCalendarClose = () => {
    onOpenStatusChange(false);
    setLocaEnd(null);
    setLocalStart(null);
    setIsOpen(false);
  };

  return (
    <div className="ds-date-range">
      <DatePicker
        onChange={handleChange}
        startDate={localStart}
        endDate={localEnd}
        selectsRange
        calendarClassName="ds-calendar"
        dayClassName={() => 'ds-calendar-day'}
        popperPlacement="bottom-start"
        disabledKeyboardNavigation
        customInput={
          <DateRangeCustomInput
            isOpen={isOpen}
            startDate={startDate}
            endDate={endDate}
          />
        }
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        maxDate={maxDate}
      />
    </div>
  );
};

DateRange.defaultProps = {
  startDate: null,
  endDate: null,
  onChange: noop,
  onOpenStatusChange: noop,
  calcMaxDate: noop
};

DateRange.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func,
  onOpenStatusChange: PropTypes.func,
  calcMaxDate: PropTypes.func
};

export default DateRange;
