import { PencilIcon, ResetIcon } from '@Icons-outdated';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import AutosizeInput from '@shared/components/AutosizeInput';
import classNames from './EditableField.module.scss';

const RESET_CLASSNAME = 'reset-icon';
const INPUT_TAG_NAME = 'INPUT';

function EditableField({
  editHandler,
  currentName,
  maxWidth,
  isCodeEditor,
  placeholder,
  label,
  tooltipText,
  className,
  canEdit,
  dataTestPrefix
}) {
  const [entityName, setEntityName] = useState(currentName || placeholder);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleSubmit = useCallback(() => {
    editHandler(entityName);
    setIsEditMode(false);
  }, [entityName, editHandler]);

  const handleOutsideClick = useCallback(
    event => {
      if (
        isEditMode &&
        event.target.className !== RESET_CLASSNAME &&
        // autosize input
        event.target.tagName !== INPUT_TAG_NAME
      ) {
        handleSubmit();
      }
    },
    [handleSubmit, isEditMode]
  );

  useEffect(() => {
    if (!isEditMode && !currentName) {
      setEntityName(placeholder);
    }
  }, [placeholder]);

  useEffect(() => {
    if (currentName) {
      setEntityName(currentName);
    }
  }, [currentName]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const tooltipMsg = tooltipText || `default ${entityName} name`;

  const resetInput = e => {
    e.preventDefault();
    setEntityName(placeholder);
    editHandler(placeholder);
    setIsEditMode(false);
  };

  return (
    <>
      {isEditMode && (
        <div
          className={classNames.editableField}
          onClick={handleOutsideClick}
        />
      )}
      <div
        className={cx(className, classNames.editableFieldContainer, {
          [classNames.editableFieldContainerEditable]: isEditMode,
          [classNames.editableFieldContainerEditor]: isCodeEditor
        })}
      >
        {!!label && <p>{label}</p>}
        <div>
          <AutosizeInput
            value={entityName}
            maxWidth={maxWidth}
            onKeyDown={handleKeyDown}
            onChange={event => setEntityName(event.target.value)}
            disabled={!isEditMode}
            data-test={`${dataTestPrefix}-input`}
          />
        </div>
        {canEdit && (
          <Tooltip
            title={`Click to ${isEditMode ? 'reset' : 'edit'} ${tooltipMsg}`}
            placement="top"
          >
            {!isEditMode ? (
              <div
                data-test={`${dataTestPrefix}-edit`}
                className={classNames.editableFieldPencil}
                onClick={() => {
                  setIsEditMode(true);
                }}
              >
                <PencilIcon />
              </div>
            ) : (
              <div
                data-test={`${dataTestPrefix}-reset`}
                className={classNames.editableFieldReset}
                onClick={resetInput}
              >
                <ResetIcon />
              </div>
            )}
          </Tooltip>
        )}
      </div>
    </>
  );
}

EditableField.defaultProps = {
  isCodeEditor: false,
  placeholder: 'Name',
  maxWidth: 300,
  tooltipText: '',
  label: '',
  className: null,
  dataTestPrefix: 'edit-field'
};

EditableField.propTypes = {
  className: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  editHandler: PropTypes.func.isRequired,
  currentName: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  isCodeEditor: PropTypes.bool,
  placeholder: PropTypes.string,
  maxWidth: PropTypes.number,
  label: PropTypes.string,
  dataTestPrefix: PropTypes.string
};

export default EditableField;
