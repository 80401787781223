// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportTableRowActions-module__actions--Op4y9{display:flex;justify-content:flex-end;align-items:center}.ReportTableRowActions-module__actions--Op4y9 .ReportTableRowActions-module__MuiIconButton-root--JsmxU{margin:0 5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `ReportTableRowActions-module__actions--Op4y9`,
	"MuiIconButton-root": `ReportTableRowActions-module__MuiIconButton-root--JsmxU`
};
module.exports = ___CSS_LOADER_EXPORT___;
