import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import { X_AXIS_OPTIONS } from '@experiment-management-shared/constants/chartConstants';
import { trackEvent } from '@shared/utils/eventTrack';
import { singleExperimentPanelEvents } from '@experiment-details/constants/events';

import { Slider } from '@design-system-outdated/components';
import { IconButton, Tooltip } from '@ds';

import { useDispatch } from 'react-redux';

import ExperimentBar from '@experiment-details/components/ExperimentBar';
import chartActions from '@/actions/experimentDetails/chartActionsPlotly';

import { getOutliers } from './useMetricChartQueryParams';

import useIsNeededToResetLocalChanges from './useIsNeededToResetLocalChanges';
import useExperimentMetrics from '@experiment-management-shared/api/useExperimentMetrics';
import { DSRefreshIcon } from '@ds-icons';
import ChangeColorButton from './ChangeColorButton/ChangeColorButton';
import AdditionalSettingsButton from './AdditionalSettingsButton/AdditionalSettingsButton';

import styles from './PanelConfigBar.module.scss';

const PanelConfigBar = ({
  isComparePage,
  currentTemplate,
  experimentKey,
  setSearchValue,
  searchValue,
  disabled,
  isAutoRefreshEnabled,
  onAutoRefreshChange,
  onRefreshPanelsClick,
  hasLockedPanels
}) => {
  const dispatch = useDispatch();

  const {
    outliers: { isVisible: showOutliers },
    smoothing,
    transformY
  } = currentTemplate;

  const xAxis = isComparePage
    ? currentTemplate.panels.compareXAxis
    : currentTemplate.x_axis;

  const [localSmoothing, setLocalSmoothing] = useState(null);

  useIsNeededToResetLocalChanges({ setLocalSmoothing });

  useEffect(() => {
    setLocalSmoothing(null);
  }, [currentTemplate?.template_id]);

  const debouncedSmoothingChange = useCallback(
    debounce(sm => {
      dispatch(
        chartActions.updateChartTemplate(experimentKey, { smoothing: sm })
      );

      trackEvent(
        singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_SMOOTHING,
        {
          value: sm
        }
      );
    }, 300),
    []
  );

  const handleXAxisChange = selectValue => {
    const value = selectValue?.value;
    const payload = isComparePage
      ? {
          panels: {
            compareXAxis: value
          }
        }
      : { x_axis: value };
    dispatch(chartActions.updateChartTemplate(experimentKey, payload));

    trackEvent(
      singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_X_AXIS_UPDATED,
      {
        value
      }
    );
  };

  const handleOutliersChange = checked => {
    const handledValue = getOutliers(checked);
    dispatch(
      chartActions.updateChartTemplate(experimentKey, {
        outliers: handledValue
      })
    );

    trackEvent(
      singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_OUTLIERS,
      {
        previous_value: getOutliers(!checked),
        new_value: checked
      }
    );
  };

  const handleTransformYChange = value => {
    dispatch(
      chartActions.updateChartTemplate(experimentKey, {
        transformY: value
      })
    );

    trackEvent(
      singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_Y_AXIS_TRANSFORMATION,
      {
        value
      }
    );
  };

  const { data: experimentMetrics = [] } = useExperimentMetrics({
    enabled: !isComparePage
  });

  const renderRightContainer = () => {
    return (
      <div className={styles.rightContainer}>
        <Tooltip content="Refresh" placement="top">
          <IconButton
            onClick={onRefreshPanelsClick}
            type="secondary"
            Icon={<DSRefreshIcon />}
            disabled={disabled}
          />
        </Tooltip>
        {!isComparePage && (
          <ChangeColorButton disabled={disabled || !experimentMetrics.length} />
        )}
        <AdditionalSettingsButton
          disabled={disabled}
          showLockedPanelsWarning={hasLockedPanels}
          isAutoRefreshEnabled={isAutoRefreshEnabled}
          onAutoRefreshChange={onAutoRefreshChange}
          handleTransformYChange={handleTransformYChange}
          handleOutliersChange={handleOutliersChange}
          transformY={transformY}
          showOutliers={showOutliers}
        />
      </div>
    );
  };

  const renderSmoothingSlider = () => {
    return (
      <ExperimentBar.Custom
        label="Y-axis smoothing"
        component={
          <Slider
            onChange={setLocalSmoothing}
            onChangeCommitted={debouncedSmoothingChange}
            min={0}
            max={1}
            step={0.001}
            value={localSmoothing ?? smoothing}
            disabled={disabled}
          />
        }
      />
    );
  };

  const renderAxisSelect = () => {
    return (
      <ExperimentBar.Select
        label="X-axis"
        changeHandler={handleXAxisChange}
        options={X_AXIS_OPTIONS}
        disabled={disabled}
        value={xAxis}
      />
    );
  };

  const renderTextSearch = () => {
    return (
      <ExperimentBar.Search
        searchText={searchValue}
        setSearchText={setSearchValue}
        placeholder="Search panel (regex)"
        disabled={disabled}
      />
    );
  };

  return (
    <ExperimentBar rightContainerChildren={renderRightContainer()}>
      {renderTextSearch()}
      {renderAxisSelect()}
      {renderSmoothingSlider()}
    </ExperimentBar>
  );
};

PanelConfigBar.propTypes = {
  isComparePage: PropTypes.bool.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  experimentKey: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAutoRefreshChange: PropTypes.func.isRequired,
  isAutoRefreshEnabled: PropTypes.bool.isRequired,
  onRefreshPanelsClick: PropTypes.func.isRequired,
  hasLockedPanels: PropTypes.bool.isRequired
};

export default PanelConfigBar;
