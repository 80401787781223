import { useCallback, useState } from 'react';
import isFunction from 'lodash/isFunction';
import { DEFAULT_DASHBOARD_SAMPLE_SIZE } from '@/constants/configConstants';

type UsePanelSampleSizeProps = {
  sampleSize?: number;
  sampleSizes?: {
    isDefault: boolean;
    value: number;
  }[];
  handleSampleSizeChange?: (config: {
    chartId: string;
    newSampleSize: number;
  }) => void;
};

const usePanelSampleSize = ({
  sampleSize,
  sampleSizes,
  handleSampleSizeChange
}: UsePanelSampleSizeProps) => {
  const defaultValue =
    sampleSizes?.find(({ isDefault }) => isDefault)?.value ??
    Number(DEFAULT_DASHBOARD_SAMPLE_SIZE);

  const [sampleSizeLocal, setSampleSizeLocal] = useState<number>(defaultValue);

  const computedHandleSampleSizeChange = useCallback(
    (config: { chartId: string; newSampleSize: number }) => {
      if (isFunction(handleSampleSizeChange)) {
        handleSampleSizeChange(config);
      } else {
        setSampleSizeLocal(config.newSampleSize);
      }
    },
    [handleSampleSizeChange]
  );

  return {
    computedSampleSize: sampleSize ?? sampleSizeLocal,
    computedHandleSampleSizeChange
  };
};

export default usePanelSampleSize;
