import React from 'react';
import { FixedSizeList as List } from 'react-window';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Scroll = ({ rows, height, width, rowHeight, listClass }) => {
  const classes = classnames('infinite', 'list-scroll', listClass);

  return (
    <List
      className={classes}
      itemCount={rows.length}
      height={height}
      width={width}
      itemSize={rowHeight}
    >
      {({ index, style }) => (
        <div key={rows[index].key} style={style} className="files-browser-row">
          {rows[index]}
        </div>
      )}
    </List>
  );
};

Scroll.propTypes = {
  width: PropTypes.any.isRequired,
  height: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  rows: PropTypes.array,
  listClass: PropTypes.string
};

Scroll.defaultProps = {
  rows: [],
  listClass: ''
};

export default Scroll;
