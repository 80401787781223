// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArtifactVersionSnowflakeTab-module__sectionHeader--_wzGj{display:flex;flex-direction:row;justify-content:space-between;align-items:center;height:47px;margin-top:18px}.ArtifactVersionSnowflakeTab-module__sectionTitle--SnVOt{font-size:14px;line-height:21px;letter-spacing:.002em;color:var(--gray-7)}.ArtifactVersionSnowflakeTab-module__errorWrapper--z5vJY{display:flex;justify-content:center;align-items:center;min-height:40vh}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionHeader": `ArtifactVersionSnowflakeTab-module__sectionHeader--_wzGj`,
	"sectionTitle": `ArtifactVersionSnowflakeTab-module__sectionTitle--SnVOt`,
	"errorWrapper": `ArtifactVersionSnowflakeTab-module__errorWrapper--z5vJY`
};
module.exports = ___CSS_LOADER_EXPORT___;
