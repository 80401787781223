import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import { useLocation } from 'react-router';
import {
  COMPARE_EXPERIMENT_1_KEY,
  COMPARE_EXPERIMENT_2_KEY
} from '@experiment-management-shared/constants/experimentConstants';
import queryString from 'query-string';
import { ExperimentDetails } from '@shared/types';

const useSelectedExperiments = (experiments: ExperimentDetails[]) => {
  const isComparePage = useSelector(selectIsComparePage);

  const location = useLocation();

  const {
    [COMPARE_EXPERIMENT_1_KEY]: experimentKey1 = experiments[0]?.experimentKey,
    [COMPARE_EXPERIMENT_2_KEY]: experimentKey2 = experiments[1]?.experimentKey
  } = queryString.parse(location.search);

  return useMemo(() => {
    if (isComparePage) {
      return {
        experiment1: experiments.find(e => e.experimentKey === experimentKey1),
        experiment2: experiments.find(e => e.experimentKey === experimentKey2)
      };
    } else {
      return {
        experiment1: experiments[0],
        experiment2: undefined
      };
    }
  }, [experimentKey1, experimentKey2, experiments, isComparePage]);
};

export default useSelectedExperiments;
