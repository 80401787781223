import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

type DeletePipelineMutationProps = { pipelinelId: string };

export function useDeletePipelineMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();

  return useMutation(
    ({ pipelinelId }: DeletePipelineMutationProps) => {
      return api.delete(
        `mpm/${activeWorkspace?.id}/models/pipeline/${pipelinelId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'models',
          { workspaceId: activeWorkspace?.id }
        ]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_DELETE_PIPELINE,
            'The pipeline was successfully deleted.'
          )
        );
      }
    }
  );
}
