import notesApi from '../util/experimentDetails/notesApi';
import notesSelectors from '../selectors/notesSelectors';
import { notesActionTypes } from '@/constants/actionTypes';

const notesActions = {
  fetchProjectNote(projectId) {
    return dispatch => {
      dispatch(notesActions.beginFetchNotes());
      return notesApi.fetchProjectNote(projectId).then(response => {
        const note = notesSelectors.manageNoteApiVersion(response.data);
        dispatch(notesActions.receiveProjectNote(note, projectId));

        const lastSave = response.data.timestamp;
        dispatch({
          type: notesActionTypes.SET_LAST_SAVE,
          payload: { lastSave }
        });
      });
    };
  },

  receiveProjectNote(noteValue, projectId) {
    return {
      type: notesActionTypes.RECEIVE_PROJECT_NOTE,
      payload: {
        note: {
          value: noteValue,
          projectId
        }
      }
    };
  },

  saveProjectNote(noteValue, projectId, shouldPreventSaveNote = false) {
    return dispatch => {
      const data = {
        projectId,
        notes_v2: noteValue
      };
      return notesApi.saveProjectNote(data).then(() => {
        dispatch({
          type: notesActionTypes.SET_LAST_SAVE,
          payload: { lastSave: Date.now() }
        });

        if (!shouldPreventSaveNote) {
          dispatch(notesActions.receiveProjectNote(noteValue, projectId));
        }
      });
    };
  },

  beginFetchNotes() {
    return {
      type: notesActionTypes.BEGIN_FETCH_NOTE
    };
  },

  cleanNotes() {
    return {
      type: notesActionTypes.CLEAN_NOTES
    };
  }
};

export default notesActions;
