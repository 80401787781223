import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import findLast from 'lodash/findLast';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import useScalarChartData from '@API/panels/useScalarChartData';
import { getIsSingleExperimentPage } from '@shared/utils/url';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';
import { toFixedNoRound } from '@shared/utils/formatNumber';
import {
  AGGREGATION_TYPES_OBJECT,
  ACTIVE_FETCH_INTERVAL,
  BUILT_IN_CHART_TYPES
} from '@/lib/appConstants';

import './ScalarChart.scss';
import ChartContainer, {
  GENERAL_CHART_CONTAINER_PROPS
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import RedirectLink from '@shared/components/RedirectLink';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import { getListAggregatedValue } from '@experiment-management-shared/utils';
import {
  CHART_EXPORT_KEY,
  RESET_ZOOM
} from '@experiment-management-shared/components/Charts/Chart/useChartHeaderMenu';

const ownAggregationValues = [
  AGGREGATION_TYPES_OBJECT.MEDIAN,
  AGGREGATION_TYPES_OBJECT.MIN,
  AGGREGATION_TYPES_OBJECT.MAX,
  AGGREGATION_TYPES_OBJECT.FIRST,
  AGGREGATION_TYPES_OBJECT.LAST,
  AGGREGATION_TYPES_OBJECT.MODE
];
const multipleValueAggregations = [AGGREGATION_TYPES_OBJECT.MODE];
const MODE_AMOUNT_SHOWN = 3;

const END_SERVER_TIMESTAMP = 'end_server_timestamp';

const HIDDEN_MENU_ITEMS = [CHART_EXPORT_KEY, RESET_ZOOM];

const formatIfNumber = (value, precision) => {
  if (!Number.isNaN(+value)) {
    return toFixedNoRound(+value, precision);
  }

  return value;
};

const formatValue = ({ aggregation, value, precision }) => {
  if (multipleValueAggregations.includes(aggregation) && !isEmpty(value)) {
    let firstValuesString = value
      .slice(0, MODE_AMOUNT_SHOWN)
      .map(val => formatIfNumber(val, precision))
      .join(', ');

    if (value?.length > MODE_AMOUNT_SHOWN) {
      firstValuesString += '...';
    }

    return firstValuesString;
  }

  return formatIfNumber(value, precision);
};

const ScalarChart = ({
  aggregation,
  description,
  hiddenExperimentKeys,
  hiddenMenuItems,
  metricName,
  paramName,
  precision,
  activeIntervalFetchDelay,
  fetchFull,
  containerProps,
  // needed for embedded panels
  experimentKeys: globalExperimentKeys,
  experiments,
  chartName,
  title,
  isAutoRefreshEnabled
}) => {
  const { shouldRefetch, actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    chartName,
    chartType: BUILT_IN_CHART_TYPES.scalar,
    titleProps: { aggregation, metricName, paramName },
    title
  });

  const sortedByTimestampExperiments = useMemo(() => {
    if (!experiments?.length) {
      return [];
    }

    return [...experiments].sort(
      (exp1, exp2) =>
        exp2?.[END_SERVER_TIMESTAMP] - exp1?.[END_SERVER_TIMESTAMP]
    );
  }, [experiments]);

  // the order of global experiment keys is not needed for scalar charts
  // because we stick the param logged timestamp to experiment logged end_server_timestamp
  const sortedExperimentKeys = useMemo(() => {
    if (!sortedByTimestampExperiments?.length) {
      return globalExperimentKeys;
    }
    return sortedByTimestampExperiments.map(exp => exp.experimentKey);
  }, [sortedByTimestampExperiments, globalExperimentKeys]);

  const {
    data: panelData,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useScalarChartData(
    {
      experimentKeys: sortedExperimentKeys,
      fetchFull,
      metricName,
      paramName,
      type: BUILT_IN_CHART_TYPES.scalar
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );

  const calculatedHiddenMenuItems = useMemo(() => {
    return uniq([...(hiddenMenuItems || []), ...HIDDEN_MENU_ITEMS]);
  }, [hiddenMenuItems]);

  const [flattenedDataSource, flattenedValues] = useMemo(
    () =>
      isEmpty(panelData)
        ? []
        : chartHelpers.flattenExperimentsAndValues({
            dataSources: panelData,
            metricName,
            paramName,
            hiddenExperimentKeys
          }),
    [panelData, metricName, hiddenExperimentKeys, paramName]
  );

  const value = useMemo(() => {
    return getListAggregatedValue(flattenedValues, aggregation);
  }, [flattenedValues, aggregation]);

  const displayedExperiment = useMemo(() => {
    if (ownAggregationValues.includes(aggregation)) {
      if (multipleValueAggregations.includes(aggregation)) {
        return findLast(
          flattenedDataSource,
          experiment => experiment.value === last(value)
        );
      }

      return findLast(
        flattenedDataSource,
        experiment =>
          experiment.value === value ||
          Number(experiment.value) === Number(value)
      );
    }

    return null;
  }, [value, flattenedDataSource, aggregation]);

  const isSingleExperiment = useSelector(getIsSingleExperimentPage);
  const text = formatValue({
    value,
    precision,
    aggregation
  });

  const linkLabel =
    displayedExperiment?.experiment_key && !isSingleExperiment ? (
      <RedirectLink
        resource={REDIRECT_LINK_RESOURCES.EXPERIMENT}
        args={[displayedExperiment?.experiment_key]}
      >
        {text}
      </RedirectLink>
    ) : (
      <span>{text}</span>
    );

  return (
    <ChartContainer
      {...containerProps}
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
      isPreviousData={isPreviousData}
      hasData={!isEmpty(panelData)}
      title={actualTitle}
      hiddenMenuItems={calculatedHiddenMenuItems}
    >
      <div className="scalar-chart-container">
        <div
          className={cx('scalar-chart-link', {
            clickable:
              !!displayedExperiment?.experiment_key && !isSingleExperiment
          })}
        >
          {linkLabel}
        </div>
        <div className="scalar-chart-description">{description}</div>
      </div>
    </ChartContainer>
  );
};

ScalarChart.propTypes = {
  experimentKeys: PropTypes.arrayOf(PropTypes.string),
  activeIntervalFetchDelay: PropTypes.number,
  fetchFull: PropTypes.bool,
  containerProps: PropTypes.shape(GENERAL_CHART_CONTAINER_PROPS),
  aggregation: PropTypes.string,
  description: PropTypes.string,
  hiddenExperimentKeys: PropTypes.array,
  hiddenMenuItems: PropTypes.arrayOf(PropTypes.string),
  metricName: PropTypes.string,
  paramName: PropTypes.string,
  precision: PropTypes.number,
  experiments: PropTypes.array,
  chartName: PropTypes.string,
  title: PropTypes.string,
  isAutoRefreshEnabled: PropTypes.bool
};

ScalarChart.defaultProps = {
  activeIntervalFetchDelay: ACTIVE_FETCH_INTERVAL,
  fetchFull: false,
  containerProps: {},
  aggregation: null,
  metricName: null,
  paramName: null,
  precision: null,
  description: null,
  hiddenExperimentKeys: [],
  hiddenMenuItems: undefined,
  experimentKeys: [],
  experiments: [],
  chartName: '',
  title: null,
  isAutoRefreshEnabled: false
};

ScalarChart.CONFIG_PROPERTIES = [
  'aggregation',
  'description',
  'hiddenExperimentKeys',
  'hiddenMenuItems',
  'metricName',
  'paramName',
  'precision',
  'activeIntervalFetchDelay',
  'fetchFull',
  'containerProps',
  'experimentKeys',
  'experiments',
  'chartName',
  'title',
  'isAutoRefreshEnabled'
];

export default ScalarChart;
