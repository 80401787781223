import React, { MouseEvent } from 'react';
import cx from 'classnames';
import Checkbox from '../../../controllers/Checkbox/Checkbox';
import { useListItemContext } from '../useListItemContext';
import type { CheckboxProps } from '../../../controllers/Checkbox/Checkbox';
import classes from './ListComponents.module.scss';

export type ListCheckboxProps = CheckboxProps;

const preventPropagation = (e: MouseEvent<HTMLInputElement>) => {
  e?.stopPropagation();
};
const ListCheckbox = (props: ListCheckboxProps) => {
  const { disabled, active, isWithSecondaryText } = useListItemContext();

  return (
    <div
      className={cx(classes.checkboxContainer, classes.prefixElementContainer, {
        [classes.moreTopCheckboxMargin]: isWithSecondaryText
      })}
    >
      <Checkbox
        disabled={disabled}
        checked={active}
        onClick={preventPropagation}
        {...props}
      />
    </div>
  );
};

export default ListCheckbox;
