import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import { Button } from '@ds';
import { RoundedTabs } from '@DesignSystem/navigation';
import { Divider } from '@DesignSystem/data-display';
import { FiltersButton } from '../FiltersButton';
import { SortPopover } from '../SortPopover';
import styles from './ModelsHeader.module.scss';
import { DSSortIcon } from '@ds-icons';
import { ColumnType, QueryRule, SortingColumnType } from '@mpm-druid/types';

type SortButtonProps = {
  columns: ColumnType[];
  anchorEl: HTMLElement | null;
  columnSorting: SortingColumnType[];
  onChange: (newSort: SortingColumnType[]) => void;
  setAnchorEl: (val: HTMLElement | null) => void;
};

const SortButton = ({
  columns,
  anchorEl,
  columnSorting,
  onChange,
  setAnchorEl
}: SortButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const countLabel = columnSorting.length ? ` (${columnSorting.length})` : '';

  return (
    <div>
      <Button
        type="secondary"
        size="small"
        active={Boolean(anchorEl)}
        iconSize="small"
        onClick={handleClick}
        PrefixIcon={<DSSortIcon />}
        data-test="model-heading-sorting"
      >
        Sort {countLabel}
      </Button>
      <SortPopover
        columns={columns}
        anchorEl={anchorEl}
        columnSorting={columnSorting}
        onChange={onChange}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </div>
  );
};

type ModelsHeaderProps = {
  activeTabIndex: number;
  columnSorting: { columnName: string; direction: string }[];
  columns: ColumnType[];
  onFilterChange: (newQuery: QueryRule) => void;
  onSortingChange: (newSort: SortingColumnType[]) => void;
  query: QueryRule;
  selection: string[];
  setActiveTabIndex: (val: number) => void;
};

function ModelsHeader({
  columns,
  onSortingChange,
  columnSorting,
  selection,
  activeTabIndex,
  setActiveTabIndex,
  query,
  onFilterChange
}: ModelsHeaderProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box className={styles.wrapper}>
      <div className={styles.filters}>
        <Box alignItems="center" display="flex">
          <p className={styles.title}>Models</p>
          <FiltersButton
            onChange={onFilterChange}
            query={query}
            columns={columns}
          />
          <SortButton
            onChange={onSortingChange}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            columnSorting={columnSorting}
            columns={columns}
          />
        </Box>
      </div>
      <>
        <Divider margin="15px" />
        <div className={styles.roundButtonsWrapper}>
          <RoundedTabs
            tabs={[
              { label: 'All' },
              {
                label: selection.length
                  ? `Selected (${selection.length})`
                  : 'Selected  ',
                disabled: selection.length === 0
              }
            ]}
            activeTabIndex={activeTabIndex}
            onTabChange={setActiveTabIndex}
          />
        </div>
      </>
    </Box>
  );
}

export default React.memo(ModelsHeader);
