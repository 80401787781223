// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignInSSOModal-module__closeButton--BFDFU{border-radius:10px !important;line-height:71px;padding:5px !important;position:absolute !important;right:20px;top:10px}.SignInSSOModal-module__title--JRcpD{font-size:22px;font-weight:300;line-height:1rem;margin:0;padding-top:12px}.SignInSSOModal-module__subtitle--FFakF{font-size:14px;font-weight:300;margin:0;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeButton": `SignInSSOModal-module__closeButton--BFDFU`,
	"title": `SignInSSOModal-module__title--JRcpD`,
	"subtitle": `SignInSSOModal-module__subtitle--FFakF`
};
module.exports = ___CSS_LOADER_EXPORT___;
