// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-module__dsTag--sV4q1{display:flex;justify-content:center;align-items:center;background-color:var(--primary-color);color:#fff;font-size:12px;line-height:18px;font-weight:500;padding:1px 6px;border-radius:50px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsTag": `Tag-module__dsTag--sV4q1`
};
module.exports = ___CSS_LOADER_EXPORT___;
