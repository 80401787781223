import React, { useCallback, useState } from 'react';

import {
  ACTIVE_FETCH_INTERVAL,
  BUILT_IN_CHART_TYPES
} from '@/lib/appConstants';
import {
  useImagePanelData,
  useImagePanelMetadata,
  useImagePanelSteps
} from '@experiment-management-shared/api';
import ChartContainer, {
  GeneralChartContainerProps
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import {
  UsePanelConfigsParams,
  usePanelConfigs
} from '@experiment-management-shared/hooks';
import { AssetStep, ImageAssetData } from '@experiment-management-shared/types';

import {
  AssetThumbnailRendererProps,
  GridPanelSlider,
  GridPanelTiles,
  usePreviewAssets,
  useStepsProps,
  useTargetExperimentKeys
} from '../GridPanel';
import { ImageDetailsModal } from './ImageDetailsModal';
import { ImageThumbnail } from './ImageThumbnail';
import {
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
} from '@experiment-management-shared/components/Charts/Chart/useChartHeaderMenu';

const HIDDEN_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
];

const IMAGE_THUMBNAIL_MAX_SIZE = 250;

type Experiment = UsePanelConfigsParams['experiments'][number];

type ImagePanelProps = {
  activeIntervalFetchDelay?: number;
  chartName?: string;
  confidenceScore?: number | null;
  containerProps: GeneralChartContainerProps;
  experimentKeys: string[];
  experimentNameAsLink?: boolean;
  experiments?: Experiment[];
  experimentsCount?: number;
  filteredAnnotations?: string[];
  hiddenExperimentKeys?: string[];
  // backward compatibility - assetNames
  images: string[];
  isAssetClickable?: boolean;
  isAutoRefreshEnabled?: boolean;
  isStepsEditable?: boolean;
  step?: AssetStep;
  title?: string;
};

const DEFAULT_ANNOTATIONS: string[] = [];
const DEFAULT_EXPERIMENTS: Experiment[] = [];
const DEFAULT_HIDDEN_EXPERIMENT_KEYS: string[] = [];

const ImagePanel = ({
  activeIntervalFetchDelay = ACTIVE_FETCH_INTERVAL,
  chartName = '',
  confidenceScore = null,
  containerProps,
  experimentKeys,
  experimentNameAsLink = false,
  experiments = DEFAULT_EXPERIMENTS,
  experimentsCount = 5,
  filteredAnnotations = DEFAULT_ANNOTATIONS,
  hiddenExperimentKeys = DEFAULT_HIDDEN_EXPERIMENT_KEYS,
  images: assetNames,
  isAssetClickable = false,
  isAutoRefreshEnabled = false,
  isStepsEditable = true,
  step = 0,
  title
}: ImagePanelProps) => {
  const { actualTitle, shouldRefetch } = usePanelConfigs({
    chartName,
    chartType: BUILT_IN_CHART_TYPES.image,
    experiments,
    isAutoRefreshEnabled,
    title
  });
  const targetExperimentKeys = useTargetExperimentKeys({
    experimentKeys,
    experimentsCount,
    hiddenExperimentKeys
  });
  const { data: steps, isLoading: isLoadingStepsData } = useImagePanelSteps(
    { assetNames, experimentKeys: targetExperimentKeys },
    {
      enabled: isStepsEditable,
      keepPreviousData: true,
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );
  const { sliderProps, stepAPIValue } = useStepsProps({
    isLoading: isLoadingStepsData,
    step,
    steps
  });
  const {
    data: serverAssets,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useImagePanelData(
    {
      assetNames,
      experimentKeys: targetExperimentKeys,
      step: stepAPIValue || 0
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true,
      keepPreviousData: true
    }
  );

  const { data: imagesMetadata } = useImagePanelMetadata(
    {
      experimentKeys: targetExperimentKeys,
      imagesIds: serverAssets?.map(val => val.id)
    },
    { refetchOnMount: true }
  );

  const previewAssets = usePreviewAssets<ImageAssetData>({
    assetNames,
    assets: serverAssets,
    experimentKeys: targetExperimentKeys
  });

  const [previewAsset, setPreviewAsset] = useState<ImageAssetData | null>(null);

  const imagePanelRenderAssetThumbnail = useCallback(
    ({
      asset,
      cellSize,
      onClick,
      src
    }: AssetThumbnailRendererProps<ImageAssetData>) => {
      const metadata = imagesMetadata?.find(
        imageMetadata => imageMetadata.imageId === asset?.id
      );

      if (
        !src ||
        IMAGE_THUMBNAIL_MAX_SIZE < Math.min(cellSize.width, cellSize.height) ||
        metadata
      ) {
        src = asset?.imagePath;
      }

      return (
        <ImageThumbnail
          confidenceScore={confidenceScore ?? 0}
          hiddenLabels={filteredAnnotations}
          metadata={metadata}
          name={asset?.name}
          onClick={onClick}
          src={src}
          originalImageSize={{
            originalImageWidth: asset?.originalImageWidth || 0,
            originalImageHeight: asset?.originalImageHeight || 0
          }}
        />
      );
    },
    [confidenceScore, filteredAnnotations, imagesMetadata]
  );

  const handleAssetClick = (asset?: ImageAssetData) => {
    if (isAssetClickable && asset) {
      setPreviewAsset(asset);
    }
  };

  const renderStepsSlider = () => {
    if (!isStepsEditable) {
      return null;
    }

    return <GridPanelSlider {...sliderProps} />;
  };

  return (
    <>
      {previewAsset && (
        <ImageDetailsModal
          assets={previewAssets}
          hiddenLabelNames={filteredAnnotations}
          onClose={() => setPreviewAsset(null)}
          selectedAsset={previewAsset}
          score={confidenceScore ?? 0}
        />
      )}
      <ChartContainer
        {...containerProps}
        customHeaderContent={renderStepsSlider()}
        hasData={Boolean(assetNames?.length)}
        hiddenMenuItems={HIDDEN_MENU_ITEMS}
        isError={isError}
        isFetching={isFetching}
        isLoading={isLoading}
        isPreviousData={isPreviousData}
        title={actualTitle}
      >
        <GridPanelTiles
          assetNames={assetNames}
          assets={serverAssets}
          experimentKeys={targetExperimentKeys}
          experimentNameAsLink={experimentNameAsLink}
          experiments={experiments}
          isAssetClickable={isAssetClickable}
          onAssetClick={handleAssetClick}
          renderAssetThumbnail={imagePanelRenderAssetThumbnail}
        />
      </ChartContainer>
    </>
  );
};

ImagePanel.CONFIG_PROPERTIES = [
  'activeIntervalFetchDelay',
  'chartName',
  'confidenceScore',
  'containerProps',
  'experimentKeys',
  'experimentNameAsLink',
  'experiments',
  'experimentsCount',
  'filteredAnnotations',
  'hiddenExperimentKeys',
  'images',
  'isAssetClickable',
  'isAutoRefreshEnabled',
  'isStepsEditable',
  'step',
  'title'
];

export default ImagePanel;
