import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { DSColors } from '@design-system-outdated/constants';
import { INTERVAL_TYPE_VALUES, CHART_TYPES } from '@mpm/constants';

// we have to replace 'T' with an underscore - something to do with plotly config
export const mapDate = date => {
  return date.replace('T', '_').replace('Z', '');
};

export const makeLineChartNotifShape = (point, lineColor) => {
  if (isNil(point)) return null;
  return {
    type: 'line',
    xref: 'x',
    yref: 'paper',
    x0: point,
    x1: point,
    y0: 0.05,
    y1: 0.95,
    line: {
      color: lineColor || '#f14668',
      dash: '4px,4px',
      width: 1.0
    }
  };
};

export const makeLineChartTooltipShape = tooltipData => {
  const pointX = tooltipData.x;
  const zeroAxisAlign = +tooltipData.data.yaxis._rl[0].toFixed(2) === 0;
  return {
    type: 'line',
    xref: 'x',
    yref: 'paper',
    x0: pointX,
    x1: pointX,
    y0: zeroAxisAlign ? 0 : 0.05,
    y1: 0.95,
    line: {
      color: '#b3b9c8',
      dash: 'solid',
      width: 1.0
    }
  };
};

export const makeTriangleTooltipShape = (tooltipData, allYZeroes) => {
  const { data, x } = tooltipData;
  // Another possible solution. But the second one looks more correct logically
  // const point = (data.pointIndex + 0.5) / data.data.x.length;
  const pointDate = data.xaxis.d2c(x);
  const tmin = data.xaxis._rl[0];
  const tmax = data.xaxis._rl[1];
  const point = (pointDate - tmin) / (tmax - tmin);
  const zeroAxisAlign = +tooltipData.data.yaxis._rl[0].toFixed(2) === 0;
  return {
    type: 'path',
    xref: 'paper',
    yref: 'paper',
    path:
      zeroAxisAlign || allYZeroes
        ? `M ${point}, 0.015 L ${point + 0.0017}, 0.005 L ${
            point - 0.0017
          }, 0.005 Z`
        : `M ${point}, 0.067 L ${point + 0.0017}, 0.055 L ${
            point - 0.0017
          }, 0.055 Z`,
    fillcolor: '#8c95a8',
    line: {
      color: '#8c95a8'
    }
  };
};

export const makeLineChartThresholdShape = (point, enabledChartData) => {
  if (!enabledChartData || isNaN(point) || !point) {
    return [];
  }
  return [
    {
      type: 'line',
      xref: 'paper',
      yref: 'y',
      x0: 0,
      x1: 1,
      y0: point,
      y1: point,
      line: {
        color: 'red',
        dash: '4px,4px',
        width: 1.5
      }
    }
  ];
};

export const CHART_BASIC_LAYOUT = {
  autosize: true,
  bargap: 0.875,
  showlegend: false,
  margin: { b: 40, l: 40, pad: 10, r: 10, t: 16 },
  hovermode: 'closest',
  hoverlabel: { bgcolor: '#FFF' },
  yaxis: {
    automargin: true,
    autorange: true,
    zerolinecolor: '#E2E2E2',
    gridcolor: DSColors.grayColor3,
    zeroline: false,
    tickfont: {
      color: DSColors.grayColor5,
      size: 11
    }
  },
  xaxis: {
    automargin: true,
    autorange: true,
    type: 'date',
    showgrid: false,
    tickformat: '%d %b',
    tickfont: {
      color: DSColors.grayColor6,
      size: 11
    },
    zerolinecolor: '#E2E2E2'
  }
};

export const CHART_BASIC_CONFIG = {
  displayModeBar: false
};

export const addHoverBarsOpacity = (data, title, index) => {
  let hoverData = cloneDeep(data);
  const countBars = hoverData[0].x.length;
  const opacityUnactiveBars = Array(countBars).fill(0.4);
  const opacityActiveBars = [...opacityUnactiveBars];
  opacityActiveBars[index] = 1;
  hoverData = hoverData.map(element => {
    if (element.name && element.name === title) {
      element.marker.opacity = opacityActiveBars;
    } else if (element.name && element.name !== title) {
      element.marker.opacity = opacityUnactiveBars;
    }
    return element;
  });
  return hoverData;
};

export const addHoverLinesOpacity = (data, title, id) => {
  let hoverData = cloneDeep(data);
  const filtered = data.filter(item => item.id !== 'TEST');
  if (filtered?.length === 1) return hoverData;
  hoverData = hoverData.map(element => {
    const { color } = element?.line || {};
    if (!color) return element;
    if ((id && element.segmentId !== id) || element?.name !== title) {
      element.line.color = `${color}40`;
    }
    return element;
  });
  return hoverData;
};

// Function to get the end of the day or current moment if it's in the future
function getEndOfDayOrCurrent(xValueString, formatString) {
  const momentDate = moment(xValueString, formatString);
  const currentMoment = moment();
  const endDayOfDate = momentDate.endOf('day');

  if (endDayOfDate.isAfter(currentMoment)) {
    return currentMoment.format('Do MMM, H:mm');
  } else {
    return endDayOfDate.format('Do MMM, H:mm');
  }
}

export const getTooltipHeaderTitle = ({
  headerTitle,
  xValue,
  xValuesList,
  intervalType,
  chartType,
  pointIdx
}) => {
  if (headerTitle) return headerTitle;
  if (chartType === CHART_TYPES.FAIRNESS_GROUPED) return xValue;
  if (
    intervalType === INTERVAL_TYPE_VALUES.DAILY &&
    (chartType === CHART_TYPES.LINE ||
      chartType === CHART_TYPES.LINE_WITH_PERCENTILE ||
      chartType === CHART_TYPES.LINE_SEGMENTS_BARS_DATA ||
      chartType === CHART_TYPES.STACKED)
  ) {
    try {
      if (pointIdx === xValuesList?.length - 1) {
        return `${moment(xValue, 'YYYY-MM-DD ha').format(
          'Do MMM, H:mm'
        )} to ${getEndOfDayOrCurrent(xValue, 'YYYY-MM-DD ha')}`;
      }
      const range = `${moment(xValue, 'YYYY-MM-DD ha').format(
        'Do MMM, H:mm'
      )} to ${moment(xValuesList[pointIdx + 1]).format('Do MMM, H:mm')}`;
      return range;
    } catch (e) {
      return xValue;
    }
  }
  return intervalType === INTERVAL_TYPE_VALUES.HOURLY
    ? moment(xValue, 'YYYY-MM-DD HH:mm').format('Do MMM, ha')
    : moment(xValue, 'YYYY-MM-DD HH:mm').format('Do MMM');
};

const getDataValue = (data, propertyString) => {
  let path = propertyString.slice(
    propertyString.indexOf('{') + 1,
    propertyString.lastIndexOf('}')
  );
  if (path.split('.').length > 2) {
    path = path.slice(0, path.lastIndexOf('.'));
  }
  const searchValue = get(data, path);
  if (Number.isNaN(parseFloat(searchValue))) {
    return searchValue;
  }
  return parseFloat(searchValue).toFixed(4);
};

const generateTextData = rows => {
  return rows.map(row => {
    const isBold = row.startsWith('<b>');
    const text = row.replace(/<b>/g, '');
    const rowValue = isBold ? <b>{text}</b> : row;

    return (
      <span className="tooltip-data-value" key={row}>
        {rowValue}
      </span>
    );
  });
};

export const parseTooltipTemplate = (hovertemplate, text, y, data) => {
  const textData = { ...text };
  delete textData.date;

  if (isEmpty(textData) || !hovertemplate) {
    return <span className="tooltip-data-value">{y}</span>;
  }

  const rows = hovertemplate.split('<br>');
  rows.shift();

  const parsedRows = rows.map(row => {
    const [first, ...rest] = row.split(':');
    const splittedRow = [
      `${first.trim()}: `,
      getDataValue(data, rest.join(''))
    ];
    return splittedRow.join('');
  });

  return generateTextData(parsedRows);
};

export const getDateRangeMessage = (tooltipData, tooltipVisible, from, to) => {
  try {
    if (!tooltipVisible) {
      return `${moment
        .utc(from)
        .local()
        .format('MMM DD, YYYY')} to ${moment
        .utc(to)
        .local()
        .format('MMM DD, YYYY')}`;
    }
    if (tooltipVisible)
      return moment
        .utc(tooltipData.x, 'YYYY-MM-DD')
        .local()
        .format('MMMM DD, YYYY');
  } catch (_) {
    return 'invalid date';
  }

  return 'invalid date';
};

export const modifyBarData = data => {
  const localOffset = moment().utcOffset();
  const deepCopyData = cloneDeep(data);
  if (localOffset > 0) {
    deepCopyData.forEach(slice => {
      slice.x.shift();
      slice.y.shift();
    });
  }
  if (localOffset < 0) {
    deepCopyData.forEach(slice => {
      if (slice.x.length >= 2) {
        const date1 = moment(slice.x.at(-1));
        const date2 = moment(slice.x.at(-2));
        const sameDay = date1.isSame(date2, 'day');
        if (sameDay) {
          slice.x.pop();
          slice.y.pop();
        }
      }
    });
  }
  return deepCopyData;
};
