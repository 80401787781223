import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BasicModal, DeleteModalBody } from '@DesignSystem/modals';

const DeleteWorkspaceModal = ({ open, onClose, onConfirm, workspaceName }) => {
  const [inputValue, setInputValue] = useState('');

  const renderDeleteWorkspace = () => {
    return (
      <DeleteModalBody
        description={`The workspace “${workspaceName}” and all associated data will be
    removed. Once deleted the data cannot be recovered.`}
        onChangeCallback={setInputValue}
      />
    );
  };

  const isPossibleToRemove = inputValue === 'DELETE';

  const handleConfirm = () => {
    if (isPossibleToRemove) {
      onConfirm();
      setInputValue(null);
    }
  };

  const handleClose = () => {
    onClose();
    setInputValue(null);
  };

  return (
    <BasicModal
      open={open}
      onClose={handleClose}
      title="Delete Workspace"
      content={renderDeleteWorkspace()}
      primaryButtonText="Confirm"
      onPrimaryButtonClick={handleConfirm}
      isPrimaryButtonDisabled={!isPossibleToRemove}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={handleClose}
    />
  );
};

DeleteWorkspaceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  workspaceName: PropTypes.string.isRequired
};

export default DeleteWorkspaceModal;
