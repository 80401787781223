import { Switch, SwitchProps } from '@material-ui/core';
import { Theme, styled } from '@material-ui/core/styles';
import * as React from 'react';

const getTrackColor = (disabled?: boolean) => {
  if (disabled) {
    return 'var(--gray-4)';
  }

  return '#5155f5';
};

const StyledSwitch = styled(Switch)<Theme, SwitchProps>(
  ({ theme, disabled }) => ({
    width: 25,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 12
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(9px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: getTrackColor(disabled)
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    }
  })
);

export default function CustomSwitch({
  className,
  checked,
  onChange,
  disabled,
  ...props
}: SwitchProps) {
  return (
    <StyledSwitch
      className={className}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  );
}
