import React, { useEffect, useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

import { Panel } from '../Panel';
import PanelContent from './PanelContent';
import {
  transformToAreaPlotData,
  transformModeToPercentileValues
} from '@mpm-druid/utils';
import { useMPMPanelData, useMPMNumericalDistribution } from '@mpm-druid/hooks';
import {
  CHART_TYPES,
  PERCENTILE_OPTION_LIST,
  DRIFT_ALGORITHMS_OPTIONS,
  PANEL_SECTIONS,
  SOURCE_TYPE
} from '@mpm-druid/constants';
import {
  IntervalType,
  PanelTab,
  FeatureDetails,
  PredicateQuery,
  ModelDetailsType,
  PanelDataType
} from '@mpm-druid/types';
import './PanelContainer.scss';

type PanelContainerProps = {
  from: string;
  to: string;
  intervalType: IntervalType;
  section: PANEL_SECTIONS;
  version: string | null;
  predicates?: PredicateQuery[];
  disableZoom: boolean;
  title: string;
  modelId?: string;
  cometSql?: string;
  feature?: FeatureDetails;
  model: ModelDetailsType;
  tabs?: PanelTab[];
  driftAlgorithmType?: string;
  colors?: string[];
};

const FEATURES_RELATED_SECTION = [
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL,
  PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES,
  PANEL_SECTIONS.NUMERICAL_DISTRIBUTION,
  PANEL_SECTIONS.FEATURE_MISSING_VALUES,
  PANEL_SECTIONS.FEATURE_DRIFT
];

export const PanelContainer = ({
  from,
  to,
  intervalType,
  section: controlledSection,
  tabs = [],
  title,
  version,
  feature,
  model,
  predicates = [],
  disableZoom = false,
  colors = [],
  ...props
}: PanelContainerProps) => {
  const [percentileMode, setPercentileMode] = useState(
    PERCENTILE_OPTION_LIST[0].value
  );
  const [driftAlgorithmType, setDriftAlgorithmType] = useState(
    DRIFT_ALGORITHMS_OPTIONS[0].value
  );

  const [activeTabData, setActiveTabData] = useState<PanelTab | null>(
    tabs?.[0]
  );
  const [section, setSection] = useState<PANEL_SECTIONS>(
    tabs?.[0]?.section || controlledSection
  );

  const [hoverLineData, setHoverLineData] = useState(null);
  const [
    checkedPercentCategorySwitch,
    setCheckedPercentCategorySwitch
  ] = useState(false);
  const [featureValue, setFeatureValue] = useState(null);
  const [driftFeaturesValue, setDriftFeaturesValue] = useState<string | null>(
    null
  );
  const [driftFeaturesList, setDriftFeaturesList] = useState<string[] | null>(
    null
  );

  const isCategoricalDistribution =
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL ||
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES;

  const availableDriftAlgorithms =
    section === PANEL_SECTIONS.DATA_DRIFT ||
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE ||
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED ||
    section === PANEL_SECTIONS.FEATURE_DRIFT;

  const driftAlgorithm = availableDriftAlgorithms
    ? driftAlgorithmType
    : undefined;

  const allowedNumericalDistribution =
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_NUMERICAL;

  useEffect(() => {
    if (
      controlledSection ===
        PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES &&
      checkedPercentCategorySwitch
    ) {
      setSection(PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL);
      return;
    }
    setSection(tabs?.[0]?.section || controlledSection);
  }, [tabs, controlledSection]);

  useEffect(() => {
    if (tabs.length === 0) {
      setActiveTabData(null);
    }
  }, [tabs.length]);

  const featureName = feature?.name || activeTabData?.feature?.name;

  const isDriftSingleFeatureStacked =
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE && predicates.length > 1;

  const isDriftMultiFeatures =
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED ||
    (section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE &&
      predicates.length === 1);

  const featureDriftKeys = isDriftMultiFeatures
    ? driftFeaturesList
    : isDriftSingleFeatureStacked
    ? [driftFeaturesValue]
    : undefined;

  const driftSections =
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE_AGGREGATED ||
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE;

  const isFeatureRelatedSection =
    section && FEATURES_RELATED_SECTION.includes(section);
  const hasFeatureName = !!featureName;
  const hasFromAndTo = !!from && !!to;
  const hasFeatureDriftKeys = !!featureDriftKeys?.[0];

  const enabled = isFeatureRelatedSection
    ? hasFeatureName && hasFromAndTo
    : driftSections
    ? hasFeatureDriftKeys
    : section === PANEL_SECTIONS.CUSTOM_METRICS
    ? !!activeTabData?.cometSql
    : hasFromAndTo;

  const { data, isLoading, isFetching, isIdle, isError } = useMPMPanelData(
    {
      from,
      to,
      version,
      intervalType,
      predicates,
      section,
      featureName,
      featureSource: feature?.source || activeTabData?.feature?.source,
      cometSql: activeTabData?.cometSql,
      algorithmType: driftAlgorithm,
      inputFeatureKeys: featureDriftKeys,
      colors
    },
    {
      enabled,
      keepPreviousData: section !== PANEL_SECTIONS.CUSTOM_METRICS,
      retry: false
    }
  );

  useEffect(() => {
    if (isCategoricalDistribution && data?.panelData.length && !featureValue) {
      setFeatureValue(data.panelData[0].featureValue);
    }
  }, [data]);

  const {
    plotData: numericalDistributionData,
    numericalLoading
  } = useMPMNumericalDistribution(
    {
      from,
      to,
      intervalType,
      predicates,
      version,
      hoverLineData,
      name: feature?.name || activeTabData?.feature?.name,
      source: feature?.source || activeTabData?.feature?.source
    },
    {
      enabled:
        allowedNumericalDistribution &&
        !!from &&
        !!to &&
        !!(feature?.name || activeTabData?.feature?.name) &&
        !!(feature?.source || activeTabData?.feature?.source),
      keepPreviousData: true,
      retry: false
    }
  );

  const chartType = data?.chartType;

  const panelData = useMemo(() => {
    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      return transformToAreaPlotData(
        intervalType,
        data?.panelData,
        colors,
        predicates,
        ...transformModeToPercentileValues(percentileMode)
      );
    }

    if (
      chartType === CHART_TYPES.LINE_CATEGORICAL_BAR_DATA &&
      data?.panelData?.length
    ) {
      const filterVal = featureValue || data.panelData[0].featureValue;
      const dataByFeature = data.panelData.filter(
        (slice: PanelDataType) => slice.featureValue === filterVal
      );
      return dataByFeature;
    }

    return data?.panelData;
  }, [
    chartType,
    data,
    intervalType,
    colors,
    percentileMode,
    featureValue,
    predicates
  ]);

  const featureValueOptions = useMemo(() => {
    if (isCategoricalDistribution && data?.panelData.length) {
      const options = uniqBy(data?.panelData, 'featureValue').map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (item: PanelDataType) => {
          const label =
            item?.featureValue === '_MISSING-VALUES_'
              ? 'MISSING VALUES'
              : item.featureValue;
          return {
            label: label as string,
            value: item.featureValue as string
          };
        }
      );
      return options;
    }
    return [];
  }, [data]);

  const driftFeatureOptions = useMemo(() => {
    if (model?.features.length) {
      return sortBy(
        model?.features
          .filter(
            feature => feature.source === SOURCE_TYPE.model_input_features
          )
          .map(item => {
            return { label: item.name, value: item.name };
          }),
        'value'
      );
    }
    return [];
  }, [model?.features]);

  useEffect(() => {
    if (driftFeatureOptions?.length) {
      setDriftFeaturesValue(driftFeatureOptions[0].value);
      setDriftFeaturesList(
        driftFeatureOptions.slice(0, 10).map(option => option.value)
      );
    }
  }, [driftFeatureOptions]);

  const handleChangeTab = (tab: PanelTab | null) => {
    setActiveTabData(tab);
    if (tab?.section) {
      setSection(tab.section);
    }
  };

  const handlePercentCategorySwitch = () => {
    setCheckedPercentCategorySwitch(prevChecked => {
      return !prevChecked;
    });
    if (section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL) {
      setSection(PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL_PERCENTAGES);
    } else {
      setSection(PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL);
    }
  };

  const renderChart = (tabData: PanelTab | null) => {
    if (
      isLoading ||
      (isIdle && !(section === PANEL_SECTIONS.CUSTOM_METRICS && !tabData))
    ) {
      return (
        <div>
          <div className="mpm-chart-loading">
            <span className="primary-label">Loading... </span>
            <span className="secondary-label">Fetching chart data</span>
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="mpm-chart-error">Error on trying to fetch the data</div>
      );
    }

    return (
      <PanelContent
        data={panelData}
        from={from}
        to={to}
        numericalDistributionData={numericalDistributionData}
        numericalLoading={numericalLoading}
        chartType={chartType}
        percentileMode={percentileMode}
        onChangePercentileMode={setPercentileMode}
        disableZoom={disableZoom}
        section={section}
        tabData={tabData}
        panelTitle={title}
        setHoverLineData={setHoverLineData}
        allowedNumericalDistribution={allowedNumericalDistribution}
        driftAlgorithm={driftAlgorithm}
        availableDriftAlgorithms={availableDriftAlgorithms}
        driftAlgorithmType={driftAlgorithmType}
        setDriftAlgorithmType={setDriftAlgorithmType}
        setSection={setSection}
        checkedPercentCategorySwitch={checkedPercentCategorySwitch}
        handlePercentCategorySwitch={handlePercentCategorySwitch}
        feature={feature}
        intervalType={intervalType}
        featureValue={featureValue}
        setFeatureValue={setFeatureValue}
        featureValueOptions={featureValueOptions}
        driftFeatureOptions={driftFeatureOptions}
        driftFeaturesValue={driftFeaturesValue}
        setDriftFeaturesValue={setDriftFeaturesValue}
        driftFeaturesList={driftFeaturesList}
        setDriftFeaturesList={setDriftFeaturesList}
        {...props}
      />
    );
  };

  return (
    <Panel
      title={title}
      renderChart={renderChart}
      onChangeTab={handleChangeTab}
      tabs={tabs}
      isFetching={isFetching}
      disableZoom={disableZoom}
      section={section}
      model={model}
    />
  );
};
