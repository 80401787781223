import React, { useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

export const DeleteModalBody = ({ description, onChangeCallback }) => {
  const [confirmField, setConfirmField] = useState('');

  const onChangeHandler = e => {
    const { value } = e.target;
    setConfirmField(value);
    onChangeCallback(value);
  };

  return (
    <div className="delete-modal-content-wrapper">
      <span className="delete-modal-body-text">
        Enter “DELETE” to confirm <span className="required-field-mark">*</span>
      </span>
      <input
        className="delete-modal-input"
        onChange={e => onChangeHandler(e)}
        value={confirmField}
      />
      <span className="delete-modal-body-text">{description}</span>
    </div>
  );
};

DeleteModalBody.defaultProps = {
  onChangeCallback: noop
};

DeleteModalBody.propTypes = {
  description: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func
};
