import { noop } from 'lodash';
import { useEffect } from 'react';

// @todo: remove once the BE will fix the image cache
//        in the download endpoint (CM-8244)
function canCacheImage(src: string): boolean {
  return !!src && !src.includes('/image/download');
}

function preloadGraphics(src: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!canCacheImage(src)) {
      return resolve(src);
    }

    const img = new Image();

    img.onload = function () {
      resolve(src);
    };

    img.onerror = img.onabort = function () {
      reject(src);
    };

    img.src = src;
  });
}

export function usePreloadGraphics({
  enabled = true,
  sources
}: {
  enabled: boolean;
  sources: string[];
}) {
  useEffect(() => {
    if (!enabled) return;

    let cancelled = false;
    let chainPromise = Promise.resolve('');

    for (const src of sources) {
      chainPromise = chainPromise.then(() => {
        if (cancelled) return Promise.reject();

        return preloadGraphics(src);
      });
    }

    chainPromise.catch(noop);

    return () => {
      cancelled = true;
    };
  }, [enabled, sources]);
}
