import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import cx from 'classnames';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SingleAction from './SingleAction';
import '../TableCells.scss';

export const MoreActionsCell = ({
  row,
  actionsList,
  isDisabled,
  dataTestId
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    if (isDisabled) return;
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        PaperProps={{
          style: {
            borderRadius: '10px',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
            padding: '5px 0',
            transform: 'translateY(-10px)'
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="actions-menu"
      >
        {actionsList.map(action => {
          const { title, subtitle, icon, clickHandler } = action;
          return (
            <SingleAction
              title={title}
              subtitle={subtitle}
              key={title}
              clickHandler={clickHandler}
              handleMenuClose={handleMenuClose}
              icon={icon}
              row={row}
            />
          );
        })}
      </Popover>
      <span data-test={dataTestId}>
        <MoreVertIcon
          onClick={handleOpen}
          className={cx('options-cell', {
            hover: true,
            'active-cell': Boolean(anchorEl),
            'disabled-cell': isDisabled
          })}
          fontSize="small"
        />
      </span>
    </>
  );
};

MoreActionsCell.defaultProps = {
  isDisabled: false,
  dataTestId: 'actions-cell'
};

MoreActionsCell.propTypes = {
  actionsList: PropTypes.array.isRequired,
  dataTestId: PropTypes.string,
  isDisabled: PropTypes.bool,
  row: PropTypes.object.isRequired
};

export default MoreActionsCell;
