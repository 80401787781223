import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { CustomMetricType } from '@mpm-druid/types';

export function useMetricEdit() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    (metricData: CustomMetricType) => {
      return api.put(`/mpm/v2/custom-metrics`, metricData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['custom-metrics', { modelId }]);
        queryClient.invalidateQueries(['model-details', { modelId }]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_EDIT_METRIC,
            'The metric was successfully edited.'
          )
        );
      },
      onError: (error: AxiosError<{ msg?: string }>) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_EDIT_METRIC,
            error?.response?.data?.msg || error.message
          )
        );
      }
    }
  );
}
