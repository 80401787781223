import { useMutation, useQueryClient } from 'react-query';
import { useActiveWorkspace } from '@shared/hooks';

import { axiosInstance, cancelWrapper } from '../api';

const importPanel = ({ teamId, templateToImport }) => {
  return cancelWrapper(cancelToken => {
    return axiosInstance({
      cancelToken,
      data: templateToImport,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `code-visualizations/template/upload?teamId=${teamId}`
    });
  });
};

export default function useImportPanelMutation() {
  const queryClient = useQueryClient();
  const activeWorkspace = useActiveWorkspace();

  return useMutation(
    ({ templateToImport }) =>
      importPanel({ teamId: activeWorkspace?.id, templateToImport }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries([
          'panelTemplates',
          { teamId: activeWorkspace?.id }
        ]);
        await queryClient.cancelQueries([
          'panelTemplatesCount',
          { teamId: activeWorkspace?.id }
        ]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'panelTemplates',
          { teamId: activeWorkspace?.id }
        ]);
        await queryClient.invalidateQueries([
          'panelTemplatesCount',
          { teamId: activeWorkspace?.id }
        ]);
      }
    }
  );
}
