import React from 'react';
import { Link } from 'react-router-dom';
import { unix } from 'moment';

import { IconButton } from '@ds';
import { COLUMN_FORMAT_KEYS } from '@/constants/workspaceConstants';
import TextOverflow from '@shared/components/TextOverflow';
import ItemMenuBtn from '@shared/components/WorkspaceSubComponents/ItemMenuBtn';
import experimentApi from '@experiment-management-shared/api/experimentApi';
import { DSCloseIcon } from '@ds-icons';

const tableCellFormats = {
  [COLUMN_FORMAT_KEYS.IS_PUBLIC]: function IsPublicColumn(isPublic) {
    return (
      <i className="material-icons project-list-visibility-icon">
        {isPublic ? 'public' : 'lock'}
      </i>
    );
  },
  [COLUMN_FORMAT_KEYS.DATE]: timestamp =>
    unix(timestamp / 1000).format('MM/DD/YYYY'),
  [COLUMN_FORMAT_KEYS.LONG_TEXT]: function LongTextColumn(text) {
    return <TextOverflow originalText={text} maxVisibleLength={50} />;
  },
  [COLUMN_FORMAT_KEYS.IMAGE]: function ImageColumn(
    imagePath,
    url,
    pinnedItemConfig
  ) {
    const { isPinned, color, text } = pinnedItemConfig;

    return (
      <div style={{ position: 'relative' }}>
        {isPinned && (
          <div className="pinned-row-badge" style={{ backgroundColor: color }}>
            {text}
          </div>
        )}
        <Link to={url}>
          <img src={imagePath} className="list-image" />
        </Link>
      </div>
    );
  },
  [COLUMN_FORMAT_KEYS.LINK]: function LinkColumn(text, url) {
    return (
      <Link to={url} className="truncated-string">
        <TextOverflow
          className="unset-wordbreak"
          originalText={text}
          maxVisibleLength={28}
        />
      </Link>
    );
  },
  [COLUMN_FORMAT_KEYS.SIMPLE_LINK]: function LinkColumn(
    text,
    url,
    _,
    className
  ) {
    return (
      <Link to={url} className={className}>
        {text}
      </Link>
    );
  },
  [COLUMN_FORMAT_KEYS.MENU_BTN]: function MenuColumn(menuItemConfigs) {
    return <ItemMenuBtn menuItemConfigs={menuItemConfigs} />;
  },
  [COLUMN_FORMAT_KEYS.STAGE_BADGE]: function StageBadgeColumn(stages) {
    return (
      <div className="stage-badge-container">
        {stages.map(stageName => (
          <div className="stage-badge" key={stageName}>
            {stageName}
          </div>
        ))}
      </div>
    );
  },
  [COLUMN_FORMAT_KEYS.EXPERIMENT_KEY]: function ExperimentKeyColumn(
    experimentKey,
    optionalQuery
  ) {
    const url = experimentApi.getRedirectURL(experimentKey, optionalQuery);
    return <a href={url}>{experimentKey.slice(0, 9)}</a>;
  },
  [COLUMN_FORMAT_KEYS.PINNED_ITEM]: function PinndedItemColumn(handler) {
    if (!handler) return '';

    return (
      <IconButton Icon={<DSCloseIcon />} type="secondary" onClick={handler} />
    );
  }
};

export default tableCellFormats;
