import React from 'react';

import { Table, TableMaxHeightProvider } from '@DesignSystem/tables';
import { paramTableConfig } from './helpers';

import ParameterHeaderContent from './ParameterHeaderContent';
import HyperParametersEmptyState from './HyperParametersEmptyState';

import classNames from './HyperParametersTab.module.scss';
import useHyperparametersTableData from './hooks/useHyperParametersTableData';
import { ExperimentDetails } from '@shared/types';
import cx from 'classnames';

type HyperParametersTableProps = {
  experiments: ExperimentDetails[];
  searchValue: string;
  decimalsPrecision: number;
  hideIdenticalParams: boolean;
  isNestedParamApplied: boolean;
  onChangeSearchValue: (v: string) => void;
  onEditParameterClick: (paramName: string) => void;
  onDeleteParameterClick: (paramName: string) => void;
};
const HyperParametersTable: React.FC<HyperParametersTableProps> = ({
  experiments,
  searchValue,
  decimalsPrecision,
  hideIdenticalParams,
  isNestedParamApplied,
  onChangeSearchValue,
  onEditParameterClick,
  onDeleteParameterClick
}) => {
  const {
    treeConfig,
    handledRows,
    disabledColumnsForSorting,
    isLoading,
    columnOrder,
    setColumnOrder,
    columnWidths,
    setColumnWidths,
    sorting,
    setSorting,
    columns,
    dataTypes,
    onToggleExpandedRow,
    isComparePage
  } = useHyperparametersTableData({
    experiments,
    hideIdenticalParams,
    searchValue,
    decimalsPrecision,
    onEditParameterClick,
    onDeleteParameterClick,
    isNestedParamApplied
  });

  return (
    <div
      className={cx(classNames.hyperParamsTable, {
        [classNames.comparePage]: isComparePage
      })}
    >
      <TableMaxHeightProvider>
        <Table
          height="auto"
          dataTypes={dataTypes}
          columns={columns}
          rows={handledRows}
          rowIdKey="id"
          isFetching={false}
          paginationConfig={paramTableConfig.disableConfig}
          treeDataConfig={treeConfig}
          selectionConfig={paramTableConfig.disableConfig}
          getRowClassName={paramTableConfig.getRowClassName}
          leftColumns={paramTableConfig.leftColumns}
          onRowMouseDown={onToggleExpandedRow}
          renderEmptyState={materialProps => (
            <HyperParametersEmptyState
              isLoading={isLoading}
              searchValue={searchValue}
              clickHandler={() => onChangeSearchValue('')}
              {...materialProps}
            />
          )}
          header={{
            renderContent: ParameterHeaderContent
          }}
          columnWidthsConfig={{
            isDisabled: false,
            columnWidths,
            onColumnWidthsChange: setColumnWidths,
            columnExtensions: paramTableConfig.columnWidthExtensions
          }}
          columnOrderConfig={{
            isDisabled: false,
            columnOrder,
            onColumnOrderChange: setColumnOrder
          }}
          sortingConfig={{
            columnSorting: sorting,
            onSortingChange: setSorting,
            disabledColumns: disabledColumnsForSorting,
            isDisabled: false
          }}
        />
      </TableMaxHeightProvider>
    </div>
  );
};

export default HyperParametersTable;
