import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import isBoolean from 'lodash/isBoolean';
import omit from 'lodash/omit';

import {
  BuiltInPanelType,
  CustomPanelInstance,
  Panel,
  PanelGlobalConfig,
  PanelGlobalConfigMap,
  PanelSection,
  PanelSections,
  PanelType
} from '@experiment-management-shared/types';
import { calculateLayoutForAddingPanel } from '@experiment-management-shared/utils/layout';
import {
  calculateLockedState,
  generateEmptySection,
  generatePanelId,
  getSectionById
} from '@experiment-management-shared';
import { CUSTOM_CHART_TYPE } from '@experiment-management-shared/constants';
import { getSectionId } from '@/reducers/dashboardChartsReducer';
import visualizationsApi from '@/util/visualizationsApi';
import useProject from '@API/project/useProject';

const OMIT_PANEL_FIELDS = ['metricColorMap'];

export type UsePanelModalHandlersParams = {
  sections: PanelSections;
  onSectionsChange: (sections: PanelSections) => void;
  panelGlobalConfig: PanelGlobalConfig;
  globalConfigMap: PanelGlobalConfigMap;
};

const usePanelModalHandlers = ({
  sections,
  onSectionsChange,
  panelGlobalConfig,
  globalConfigMap
}: UsePanelModalHandlersParams) => {
  const sectionId: string = useSelector(getSectionId);
  const sectionRef = useRef<PanelSection>(generateEmptySection());
  const { data: project } = useProject();

  useEffect(() => {
    sectionRef.current = getSectionById(sections, sectionId);
  }, [sectionId, sections]);

  const handleSectionChange = useCallback(
    updatedSection => {
      onSectionsChange(
        sections.map(section => {
          if (section.id === updatedSection.id) {
            return updatedSection;
          }

          return section;
        })
      );
    },
    [onSectionsChange, sections]
  );

  const handleAddPanel = useCallback(
    panels => {
      const { layout } = sectionRef.current;
      const panelsData = calculateLayoutForAddingPanel(panels, layout);

      handleSectionChange({
        ...sectionRef.current,
        ...panelsData
      });
    },
    [handleSectionChange]
  );

  const handleAddBuiltInPanel = useCallback(
    (panel: Panel, chartType: BuiltInPanelType) => {
      const { panels } = sectionRef.current;
      const chartId = generatePanelId();
      const locked = calculateLockedState(
        panelGlobalConfig,
        panel,
        globalConfigMap
      );
      handleAddPanel([
        ...panels,
        {
          ...omit(panel, OMIT_PANEL_FIELDS),
          chartId,
          chartType,
          ...(isBoolean(locked) ? { locked } : {})
        }
      ]);
    },
    [handleAddPanel, globalConfigMap, panelGlobalConfig]
  );

  const handleAddCustomPanel = useCallback(
    async (instance: CustomPanelInstance) => {
      const { panels } = sectionRef.current;
      const chartId = generatePanelId();
      const chartType = CUSTOM_CHART_TYPE;
      const instanceResponse = await visualizationsApi.createInstance(instance);
      const {
        instanceId,
        templateId,
        templateRevisionId
      } = instanceResponse.data;
      const templateResponse = await visualizationsApi.fetchTemplate(
        templateId,
        templateRevisionId,
        project.projectId
      );
      const { defaultConfig } = templateResponse.data.code;

      handleAddPanel([
        ...panels,
        {
          chartId,
          chartType,
          instanceId,
          instanceName: instance.instanceName,
          defaultConfig
        }
      ]);
    },
    [handleAddPanel, project.projectId]
  );

  const handleEditBuiltInPanel = useCallback(
    (
      chartId: string,
      panelForm: Record<string, Panel>,
      chartType: PanelType
    ) => {
      const { panels } = sectionRef.current;
      const editedPanel: Panel = {
        ...(omit(panelForm[chartType], OMIT_PANEL_FIELDS) as Panel),
        chartId,
        chartType
      };

      const locked = calculateLockedState(
        panelGlobalConfig,
        editedPanel,
        globalConfigMap
      );
      const editedPanelObject = {
        ...editedPanel,
        ...(isBoolean(locked) ? { locked } : {})
      };

      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId) {
          return editedPanelObject;
        }

        return panel;
      });

      handleSectionChange({
        ...sectionRef.current,
        panels: updatedPanels
      });
    },
    [handleSectionChange, globalConfigMap, panelGlobalConfig]
  );

  const handleEditCustomPanel = useCallback(
    async (chartId: string, instance: CustomPanelInstance) => {
      const { panels } = sectionRef.current;
      const chartType = CUSTOM_CHART_TYPE;
      const instanceResponse = await visualizationsApi.createInstance(instance);
      const { instanceId } = instanceResponse.data;

      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId) {
          return {
            chartId,
            chartType,
            instanceId,
            instanceName: instance.instanceName
          };
        }

        return panel;
      });

      handleSectionChange({
        ...sectionRef.current,
        panels: updatedPanels
      });
    },
    [handleSectionChange]
  );

  return {
    handleAddBuiltInPanel,
    handleAddCustomPanel,
    handleEditBuiltInPanel,
    handleEditCustomPanel
  };
};

export default usePanelModalHandlers;
