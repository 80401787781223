import { useQuery } from 'react-query';

import { QueryConfig } from '@shared/api';
import api from '@API/api';

type ModelsCountResponse = {
  data: { monitoredModelCount: number };
};

type ModelsCountParams = { workspace: string };
type ModelsCountConfig = QueryConfig<ModelsCountResponse>;

const getModelsCount = (params: ModelsCountParams) =>
  api.get(`/mpm/v2/model/count?workspaceId=${params.workspace}`);

export function useModelsCount(
  params: ModelsCountParams,
  config: ModelsCountConfig
) {
  return useQuery(
    ['model-details', { ...params }],
    () => getModelsCount(params),
    {
      ...config
    }
  );
}
