/* eslint-disable react/prop-types */
import { Checkbox } from '@DesignSystem/controllers';
import {
  CustomGrouping,
  CustomPaging,
  CustomSummary,
  CustomTreeData,
  DataTypeProvider,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SelectionState,
  SortingState,
  SummaryState,
  TableSummaryRow,
  TreeDataState
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableBandHeader,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditRow,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableSelection,
  TableTreeColumn,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import isEqual from 'fast-deep-equal';
import { difference, last, throttle, uniq } from 'lodash';
import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTableHeight } from '@shared/hooks/useTableHeight';
import { StyledTooltip } from '../../data-display';
import Pagination from '../Pagination/Pagination';
import { sortedRows } from './sort/sortedRows';
import GroupedColumnCell from './GroupedColumnCell';
import './Table.scss';
import { ExpandTreeCell, IndentTree } from './tree';

const DEFAULT_PAGE_SIZES = [10, 25, 50, 100];
export const VIRTUALIZATION_TABLE_LIMIT = 25;

const TableRootComponent = props => (
  <Grid.Root {...props} className={cx(props.className, 'table-root')} />
);

const InvisibleCellComponent = () => {
  return <VirtualTable.Cell className="ds-table-band-invisible-header-cell" />;
};

const EmptyStubComponent = () => {
  return <div className="ds-table-empty-stub" />;
};

const getCellBandClass = (columnName, columnBands) => {
  if (columnBands === null) return '';
  let className = 'bandsMiddleCol';
  columnBands.forEach(band => {
    const colList = band.children;

    if (colList[0].columnName === columnName) {
      className = 'bandsFirstCol';
    }
    if (colList[colList.length - 1].columnName === columnName) {
      className = 'bandsLastCol';
    }
  });
  return className;
};
const SelectionCell = ({
  lastToggledRowId,
  dataId,
  onToggle,
  row,
  rowIdKey,
  hideCheckBox,
  disabled,
  tooltipLabel,
  selected: controlledSelected,
  ...props
}) => {
  const [selected, setSelected] = useState(controlledSelected);

  const rowId = row[rowIdKey];

  useEffect(() => {
    setSelected(controlledSelected);
  }, [controlledSelected]);

  const handleChange = event => {
    setSelected(event.target.checked);
    onToggle(event);
  };

  return (
    <Table.Cell className="selection-cell" {...props}>
      {!hideCheckBox && (
        <StyledTooltip maxWidth="200px" title={tooltipLabel} type="base">
          <span>
            <Checkbox
              checked={selected}
              disabled={disabled}
              onChange={handleChange}
              isLastToggled={rowId === lastToggledRowId}
              inputProps={{ 'data-id': dataId }}
            />
          </span>
        </StyledTooltip>
      )}
    </Table.Cell>
  );
};

// eslint-disable-next-line react/prop-types
const SortLabel = ({ onSort, children, direction, disabled }) => {
  const label = children.props.children;

  const SortIcon = direction === 'asc' ? ArrowUpward : ArrowDownward;

  return (
    <button
      type="button"
      className="sort-label"
      onClick={onSort}
      disabled={disabled}
    >
      <Tooltip title={label} placement="top">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="sort-label-text">{children}</span>
          {direction && <SortIcon style={{ fontSize: '14px' }} />}
        </div>
      </Tooltip>
    </button>
  );
};

const renderDataTypes = dataTypes => {
  return dataTypes?.map(({ cols, cell, editorCell }) => (
    <DataTypeProvider
      key={cols[0]}
      for={cols}
      editorComponent={editorCell}
      formatterComponent={cell}
    />
  ));
};

const selectCheckboxDataId = 'selection-checkbox';

const TableComponent = ({ style, ...props }) => (
  <VirtualTable.Table {...props} className="virtual-table-container" />
);

const TableBodyComponent = ({ isFetching, ...restProps }) => (
  <VirtualTable.TableBody
    {...restProps}
    className={cx('ds-table-body', {
      'ds-table-body-loading': isFetching
    })}
  />
);

const FixedCell = props => {
  return (
    <TableFixedColumns.Cell
      {...props}
      className={cx(
        'ds-table-fixed-column',
        props?.className,
        `ds-table-cell__${props.tableColumn?.column?.name}`
      )}
      style={{ borderLeft: 'none' }}
    />
  );
};

const TableBase = ({
  columnOrderConfig,
  columnWidthsConfig,
  columns,
  headerCellCentered,
  columnBands,
  customPaginationStyle,
  dataTest = '',
  dataTypes,
  groupingConfig,
  height,
  hideLastRowBorder,
  isFetching,
  isRootCellSpan = false,
  leftColumns,
  maxHeight,
  noPaddingColumns,
  onHoverMenuRender,
  onRowClick,
  onRowMouseDown,
  prepareTableRows,
  paginationConfig,
  renderEmptyState,
  rightColumns,
  getRowClassName,
  getCellClassName,
  getCellDataMask,
  getCellRowspan,
  rowHeight,
  rowIdKey,
  rows,
  selectionConfig,
  shouldHighlightLinks,
  shouldRemoveStubCells,
  sortingConfig,
  editingConfig,
  treeDataConfig,
  totalRowCount,
  className,
  defaultHiddenColumnNames,
  header,
  resizingMode
}) => {
  const { columnOrder: _columnOrder, onColumnOrderChange } = columnOrderConfig;
  const { renderContent: renderHeaderContent, headerColor } = header;

  const {
    columnWidths: _columnWidths,
    onColumnWidthsChange,
    columnExtensions
  } = columnWidthsConfig;
  const {
    pageSize,
    pageNumber,
    pageSizes,
    onPageNumberChange,
    onPageSizeChange,
    isServerSidePagination
  } = paginationConfig;

  const {
    columnSorting: sorting,
    onSortingChange,
    disabledColumns
  } = sortingConfig;

  const {
    groupedColumns,
    expandedGroups,
    onExpandedGroupsChange,
    groupedColumnRowsData,
    GroupedCellComponent,
    columnsForSummary,
    groupedCellsClassName,
    groupedColumnCellClassName,
    summaryCellCalculator,
    getChildGroups,
    groupColumnExtensions,
    isWithSummary,
    renderSummaryCellComponent
  } = groupingConfig;

  const isGroupingWithServerPagination = Boolean(getChildGroups);

  const columnWidths = useMemo(() => {
    if (isEmpty(_columnWidths)) {
      return columns.map(({ name }) => ({
        columnName: name,
        width: 'auto'
      }));
    }

    return columns.map(({ name }) => {
      const widthDefinition = find(_columnWidths, { columnName: name });
      return {
        columnName: name,
        width: get(widthDefinition, 'width', 'auto')
      };
    });
  }, [_columnWidths, columns]);

  const columnOrder = useMemo(() => {
    if (isEmpty(_columnOrder)) {
      return columns.map(({ name }) => name);
    }

    return _columnOrder;
  }, [_columnOrder, columns]);

  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);
  const [lastToggledRowId, setLastToggledRowId] = useState(null);
  const [selectionBlock, setSelectionBlock] = useState([]);

  const orderedRowIds = useMemo(() => {
    const orderedRows = isServerSidePagination
      ? rows
      : sortedRows(rows, sorting);

    return orderedRows?.map(row => row[rowIdKey]);
  }, [sorting, rows, isServerSidePagination, rowIdKey]);

  const getOrderedSelectionRange = useCallback(
    rowIdRange => {
      const selectionIndices = rowIdRange.map(rowId =>
        orderedRowIds.indexOf(rowId)
      );
      const startIndex = Math.min(...selectionIndices);
      const endIndex = Math.max(...selectionIndices);

      return orderedRowIds.slice(startIndex, endIndex + 1);
    },
    [orderedRowIds]
  );

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Shift') {
        setIsShiftKeyPressed(true);
      } else {
        setIsShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    const handleKeyUp = event => {
      if (event.key === 'Shift') {
        setIsShiftKeyPressed(false);
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);
  }, []);

  useEffect(() => {
    const handleClick = event => {
      if (get(event, 'target.dataset.id', '') !== selectCheckboxDataId) {
        setLastToggledRowId(null);
      }
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (!isEmpty(selectionBlock)) {
      setSelectionBlock([]);
    }
  }, [selectionBlock, rows, getOrderedSelectionRange]);

  const handlePageNumberChange = newPageNumber => {
    onPageNumberChange(newPageNumber);
  };

  const handlePageSizeChange = newPageSize => {
    onPageSizeChange(newPageSize);
  };

  const handleSortingChange = newSorting => {
    onSortingChange(newSorting);
  };

  const handleColumnWidthsChange = newColumnWidths => {
    onColumnWidthsChange(newColumnWidths);
  };

  const handleColumnOrderChange = newColumnOrder => {
    onColumnOrderChange(newColumnOrder);
  };

  const handleRenderEmptyState = props =>
    renderEmptyState ? (
      renderEmptyState(props)
    ) : (
      <Table.NoDataCell getMessage={() => 'Empty Table State'} {...props} />
    );

  const renderSelectionCell = useCallback(
    props => {
      const isSelected = selectionConfig.selection.includes(
        props.row[rowIdKey]
      );

      const { checkboxConfig = {} } = props.row;

      return (
        <SelectionCell
          {...props}
          className={cx('selection-cell', props.className)}
          lastToggledRowId={lastToggledRowId}
          dataId={selectCheckboxDataId}
          rowIdKey={rowIdKey}
          selected={isSelected}
          disabled={checkboxConfig?.disabled}
          tooltipLabel={checkboxConfig?.tooltipLabel || ''}
        />
      );
    },
    [lastToggledRowId, rowIdKey, selectionConfig.selection]
  );

  useEffect(() => {
    if (!isEmpty(selectionBlock)) {
      const newSelectionBlock = getOrderedSelectionRange(selectionBlock);
      selectionConfig.onSelectionChange(
        uniq([...selectionConfig.selection, ...newSelectionBlock])
      );

      setSelectionBlock([]);
    }
  }, [selectionBlock, rows, getOrderedSelectionRange, selectionConfig]);

  const CustomHeaderCellWithCheckbox = headerCellProps => {
    const { disabled, allSelected, someSelected, onToggle } = headerCellProps;

    return (
      <TableHeaderRow.Cell
        style={{ backgroundColor: headerColor }}
        className={cx(headerCellProps.className, 'header-selection-cell')}
      >
        {!selectionConfig.disableHeaderCell && (
          <Checkbox
            disabled={disabled}
            indeterminate={someSelected}
            checked={allSelected}
            onChange={() => onToggle(!someSelected && !allSelected)}
          />
        )}
      </TableHeaderRow.Cell>
    );
  };

  const tableRef = React.createRef();

  const handleSelectionChange = throttle(
    useCallback(
      newSelection => {
        const { onSelectionChange, selection } = selectionConfig;

        const isChecked = newSelection.length > selection.length;

        if (isChecked && last(rows)[rowIdKey] === last(newSelection)) {
          tableRef?.current?.scrollToRow(last(newSelection));
        }

        const newlySelectedRowId = first(difference(newSelection, selection));

        const newlyDeselectedRowId = first(difference(selection, newSelection));

        if (isShiftKeyPressed) {
          if (isChecked) {
            if (isEmpty(selection)) {
              // If no current selection, select all rows from the
              // beginning of table to the new row selected
              setSelectionBlock([first(orderedRowIds), newlySelectedRowId]);
            } else {
              setSelectionBlock([lastToggledRowId, newlySelectedRowId]);
            }

            setLastToggledRowId(newlySelectedRowId);
          } else {
            const rowIdsToDeselect = getOrderedSelectionRange([
              lastToggledRowId,
              newlyDeselectedRowId
            ]);

            onSelectionChange(
              selection.filter(rowId => !rowIdsToDeselect.includes(rowId))
            );

            setLastToggledRowId(newlyDeselectedRowId);
          }

          return;
        }

        if (isEmpty(newSelection)) {
          setLastToggledRowId(null);
        } else {
          setLastToggledRowId(
            isChecked ? newlySelectedRowId : newlyDeselectedRowId
          );
        }

        onSelectionChange(newSelection);
      },
      [
        selectionConfig,
        isShiftKeyPressed,
        orderedRowIds,
        lastToggledRowId,
        getOrderedSelectionRange
      ]
    ),
    300
  );

  const HeaderRow = useCallback(props => {
    const { className } = props;
    return (
      <TableHeaderRow.Row
        className={cx(className, getRowClassName({}))}
        {...props}
      />
    );
  }, []);

  const TableBandCell = props => {
    return (
      <TableBandHeader.Cell
        className={cx(
          'dx-table-header-cell',
          'dx-table-band-cell',
          {
            'is-centered': headerCellCentered
          },
          props.className,
          `banded_header__${props.column?.title}`
        )}
        {...props}
        style={{
          ...props.style,
          backgroundColor: headerColor
        }}
        padding="none"
        align="center"
      />
    );
  };

  const HeaderContent = props => {
    if (!renderHeaderContent) {
      return (
        <TableHeaderRow.Content
          {...props}
          className={cx('table-content', {
            'is-centered': headerCellCentered
          })}
        />
      );
    }

    return renderHeaderContent(props);
  };

  const HeaderCell = props => {
    const isNoPadding = noPaddingColumns.includes(props.column.name);

    return (
      <TableHeaderRow.Cell
        {...props}
        colSpan={isRootCellSpan ? 2 : 1}
        className={cx(
          props.className,
          props.column.name,
          'dx-table-header-cell',
          {
            'no-padding': isNoPadding,
            'no-border-left': props.column.name === 'rowActionMenu',
            'is-centered': headerCellCentered
          }
        )}
        style={{
          ...props.style,
          fontSize: '12px',
          color: '#8C95A8',
          backgroundColor: headerColor
        }}
        padding="none"
        align="center"
        resizingEnabled={
          columnWidthsConfig?.disabledColumns?.includes(props.column.name)
            ? false
            : props.resizingEnabled
        }
        draggingEnabled={
          columnOrderConfig?.disabledColumns?.includes(props.column.name)
            ? false
            : props.draggingEnabled
        }
      />
    );
  };

  const CellView = props => {
    const isNoPadding = noPaddingColumns.includes(props.column.name);
    const customCellName = getCellClassName(props);
    const rowspan = getCellRowspan(props);
    const dataMaskTest = getCellDataMask(props);

    return (
      <VirtualTable.Cell
        {...props}
        data-mask-test={dataMaskTest}
        rowSpan={rowspan}
        className={cx(
          props.className,
          customCellName,
          props.column.name,
          getCellBandClass(props.column.name, columnBands),
          'dx-table-cell',
          {
            'no-padding': isNoPadding,
            'no-border-left': props.column.name === 'rowActionMenu'
          }
        )}
      />
    );
  };

  const RowView = props => {
    const { children, ...otherProps } = props;
    return (
      <VirtualTable.Row
        style={{
          display: 'none'
        }}
        {...otherProps}
        className={cx(
          'default-table-row-view',
          props.className,
          getRowClassName(props?.row),
          {
            'hide-last-row-border': hideLastRowBorder,
            'is-editable-row': props?.row?.editable,
            'is-not-editable-row': !props?.row?.editable
          }
        )}
        data-test="table-row"
        onMouseDown={() => onRowMouseDown(props?.row)}
        {...(onRowClick && { onClick: () => onRowClick(props?.row) })}
      >
        {children}
        {onHoverMenuRender(props)}
      </VirtualTable.Row>
    );
  };

  const ReactGridRow = props => {
    const { highlighted, children, ...restProps } = props;

    return (
      <TableSelection.Row
        className={cx(
          'table-selection-row',
          {
            'table-selection-row-highlighted': highlighted
          },
          {
            highlightLinks: shouldHighlightLinks
          },
          getRowClassName(props.tableRow.row)
        )}
        onClick={event => {
          if (event.target.dataset.id === selectCheckboxDataId) {
            return;
          }
          onRowClick(props.tableRow.row);
        }}
        height={rowHeight}
        data-test="default-table-selection-row"
        {...restProps}
      >
        {children}
      </TableSelection.Row>
    );
  };

  const localMaxHeight = useTableHeight({
    maxHeight,
    rowHeight,
    rows,
    currentPage: pageNumber
  });

  const tableData = useMemo(() => {
    if (isFunction(prepareTableRows)) {
      return prepareTableRows(rows);
    }

    return rows;
  }, [prepareTableRows, rows]);

  const PagingContainer = props => (
    <Pagination {...props} customStyle={customPaginationStyle || undefined} />
  );

  const TableBody = useCallback(
    props => <TableBodyComponent {...props} isFetching={isFetching} />,
    [isFetching]
  );

  const getRowId = row => {
    return row[rowIdKey];
  };

  const shouldBeVirtualized = rows.length > VIRTUALIZATION_TABLE_LIMIT;

  const stubProps = useMemo(() => {
    if (!shouldRemoveStubCells) return {};

    return {
      stubCellComponent: EmptyStubComponent,
      stubHeaderCellComponent: EmptyStubComponent
    };
  }, [shouldRemoveStubCells]);

  return (
    <div
      className={cx(
        'ds-react-grid',
        { virtualized: shouldBeVirtualized },
        className
      )}
      data-test={dataTest}
    >
      <Grid
        resize
        rows={tableData}
        columns={columns}
        getRowId={getRowId}
        rootComponent={TableRootComponent}
      >
        {renderDataTypes(dataTypes)}

        {!editingConfig.isDisabled && (
          <EditingState
            editingRowIds={editingConfig.editingRowIds}
            addedRows={editingConfig.addedRows}
            onRowChangesChange={editingConfig.onRowChangesChange}
            onAddedRowsChange={editingConfig.onAddedRowsChange}
          />
        )}

        {!sortingConfig.isDisabled && (
          <SortingState
            sorting={sorting}
            onSortingChange={handleSortingChange}
            columnExtensions={disabledColumns}
          />
        )}
        {!paginationConfig.isDisabled && (
          <PagingState
            currentPage={pageNumber}
            onCurrentPageChange={handlePageNumberChange}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
          />
        )}

        {!groupingConfig.isDisabled && (
          <GroupingState
            expandedGroups={expandedGroups}
            grouping={groupedColumns}
            onExpandedGroupsChange={onExpandedGroupsChange}
          />
        )}

        {!groupingConfig.isDisabled && isGroupingWithServerPagination && (
          <CustomGrouping
            expandedGroups={expandedGroups}
            getChildGroups={getChildGroups}
            grouping={groupedColumns}
          />
        )}

        {!groupingConfig.isDisabled && (
          <SummaryState groupItems={columnsForSummary} />
        )}

        {!groupingConfig.isDisabled && <CustomSummary />}

        {!selectionConfig.isDisabled && (
          <SelectionState
            selection={selectionConfig.selection}
            onSelectionChange={handleSelectionChange}
          />
        )}

        {!treeDataConfig?.isDisabled && (
          <TreeDataState
            expandedRowIds={treeDataConfig.expandedRowIds}
            onExpandedRowIdsChange={treeDataConfig.onExpandedRowIdsChange}
          />
        )}

        {!sortingConfig.isDisabled && !isServerSidePagination && (
          <IntegratedSorting />
        )}
        {!selectionConfig.isDisabled && <IntegratedSelection />}
        {!paginationConfig.isDisabled && !isServerSidePagination && (
          <IntegratedPaging />
        )}

        {!columnOrderConfig.isDisabled && <DragDropProvider />}

        {!groupingConfig.isDisabled && !isGroupingWithServerPagination && (
          <IntegratedGrouping />
        )}

        {!groupingConfig.isDisabled && (
          <IntegratedSummary calculator={summaryCellCalculator || (() => 1)} />
        )}

        {!treeDataConfig?.isDisabled && (
          <CustomTreeData getChildRows={treeDataConfig.getChildRows} />
        )}

        <VirtualTable
          ref={tableRef}
          tableComponent={TableComponent}
          bodyComponent={TableBody}
          cellComponent={CellView}
          rowComponent={RowView}
          noDataRowComponent={handleRenderEmptyState}
          height={height || localMaxHeight}
          columnExtensions={columnExtensions}
          {...stubProps}
        />

        {!columnWidthsConfig.isDisabled && (
          <TableColumnResizing
            columnWidths={columnWidths}
            defaultColumnWidths={columnWidths}
            onColumnWidthsChange={handleColumnWidthsChange}
            resizingMode={resizingMode}
            columnExtensions={columnExtensions}
          />
        )}

        {!columnOrderConfig.isDisabled && (
          <TableColumnReordering
            columnWidths={columnWidths}
            order={columnOrder}
            onOrderChange={handleColumnOrderChange}
          />
        )}

        <TableHeaderRow
          cellComponent={HeaderCell}
          contentComponent={HeaderContent}
          rowComponent={HeaderRow}
          showSortingControls={!sortingConfig.isDisabled}
          sortLabelComponent={SortLabel}
        />

        {Boolean(defaultHiddenColumnNames.length) && (
          <TableColumnVisibility
            key={defaultHiddenColumnNames.join('-')}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
          />
        )}

        {!editingConfig.isDisabled && <TableEditRow />}

        {!treeDataConfig?.isDisabled && (
          <TableTreeColumn
            for={treeDataConfig.treeColumn}
            expandButtonComponent={ExpandTreeCell}
            indentComponent={IndentTree}
          />
        )}

        {!selectionConfig.isDisabled && (
          <TableSelection
            rowComponent={ReactGridRow}
            headerCellComponent={CustomHeaderCellWithCheckbox}
            cellComponent={renderSelectionCell}
            selectionColumnWidth={53}
            highlightRow
            showSelectAll
          />
        )}

        {columnBands !== null && (
          <TableBandHeader
            cellComponent={TableBandCell}
            columnBands={columnBands}
            invisibleCellComponent={InvisibleCellComponent}
          />
        )}

        {!groupingConfig.isDisabled && (
          <TableGroupRow
            summaryCellComponent={props =>
              renderSummaryCellComponent ? (
                renderSummaryCellComponent(props)
              ) : (
                <TableGroupRow.SummaryCell {...props} />
              )
            }
            cellComponent={props => {
              return (
                <GroupedColumnCell
                  {...props}
                  groupedColumns={groupedColumns}
                  groupedColumnRowsData={groupedColumnRowsData}
                  GroupedCellComponent={GroupedCellComponent}
                  className={cx(groupedColumnCellClassName, props.className)}
                />
              );
            }}
            indentColumnWidth={0.000001} // Cannot be supplied 0 because in this case table component incorrectly calculate the width of table.
            summaryItemComponent={({ ...props }) => {
              return (
                <TableGroupRow.SummaryItem
                  {...props}
                  className={groupedCellsClassName}
                />
              );
            }}
            columnExtensions={groupColumnExtensions}
          />
        )}

        {isWithSummary && <TableSummaryRow />}

        <TableFixedColumns
          rightColumns={rightColumns}
          cellComponent={FixedCell}
          leftColumns={[
            TableSelection.COLUMN_TYPE,
            TableGroupRow.COLUMN_TYPE,
            ...leftColumns
          ]}
        />
        {!paginationConfig.isDisabled && isServerSidePagination && (
          <CustomPaging totalCount={totalRowCount} />
        )}
        {!paginationConfig.isDisabled && (
          <PagingPanel
            containerComponent={PagingContainer}
            pageSizes={pageSizes}
          />
        )}
      </Grid>
    </div>
  );
};

const columnBandsPropTypes = PropTypes.shape({
  columnName: PropTypes.string,
  title: PropTypes.string
});
columnBandsPropTypes.children = PropTypes.arrayOf(columnBandsPropTypes);

TableBase.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      type: PropTypes.string // Type is using only GroupCellComponent component
    })
  ).isRequired,
  columnBands: PropTypes.arrayOf(columnBandsPropTypes),
  headerCellCentered: PropTypes.bool,
  getRowClassName: PropTypes.func,
  getCellClassName: PropTypes.func,
  getCellRowspan: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object),
  rowIdKey: PropTypes.string.isRequired,
  leftColumns: PropTypes.array,
  rightColumns: PropTypes.array,
  noPaddingColumns: PropTypes.array,
  sortingConfig: PropTypes.object,
  groupingConfig: PropTypes.object,
  onHoverMenuRender: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowMouseDown: PropTypes.func,
  prepareTableRows: PropTypes.func,
  customPaginationStyle: PropTypes.object,
  paginationConfig: PropTypes.object,
  columnWidthsConfig: PropTypes.object,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cellPadding: PropTypes.string,
  columnOrderConfig: PropTypes.object,
  hideLastRowBorder: PropTypes.bool,
  renderEmptyState: PropTypes.func,
  rowHeight: PropTypes.string,
  shouldHighlightLinks: PropTypes.bool,
  shouldRemoveStubCells: PropTypes.bool,
  selectionConfig: PropTypes.object,
  editingConfig: PropTypes.shape({
    isDisabled: PropTypes.bool,
    editingRowIds: PropTypes.arrayOf(PropTypes.string),
    addedRows: PropTypes.arrayOf(PropTypes.object),
    onRowChangesChange: PropTypes.func,
    onAddedRowsChange: PropTypes.func
  }),
  height: PropTypes.string,
  dataTypes: PropTypes.arrayOf(
    PropTypes.shape({
      cols: PropTypes.arrayOf(PropTypes.string),
      cell: PropTypes.any
    })
  ),
  totalRowCount: PropTypes.number,
  className: PropTypes.string,
  treeDataConfig: PropTypes.shape({
    getChildRows: PropTypes.func,
    isDisabled: PropTypes.bool,
    treeColumn: PropTypes.string,
    defaultExpandedRowIds: PropTypes.arrayOf(
      PropTypes.oneOf([PropTypes.string, PropTypes.number])
    ),
    expandedRowIds: PropTypes.arrayOf(
      PropTypes.oneOf([PropTypes.string, PropTypes.number])
    ),
    onExpandedRowIdsChange: PropTypes.func
  }),
  defaultHiddenColumnNames: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.shape({
    headerColor: PropTypes.string,
    renderContent: PropTypes.func
  })
};

TableBase.defaultProps = {
  leftColumns: [],
  getRowClassName: () => '',
  getCellDataMask: () => undefined,
  getCellClassName: () => '',
  getCellRowspan: () => 1,
  totalRowCount: 0,
  noPaddingColumns: [],
  rightColumns: [],
  rows: [],
  renderEmptyState: null,
  height: null,
  headerCellCentered: false,
  hideLastRowBorder: true,
  dataTypes: null,
  rowHeight: 'auto',
  cellPadding: '15px',
  customPaginationStyle: {},
  shouldHighlightLinks: false,
  shouldRemoveStubCells: false,
  maxHeight: 'auto',
  onHoverMenuRender: noop,
  onRowClick: noop,
  onRowMouseDown: noop,
  prepareTableRows: undefined,
  sortingConfig: {
    isDisabled: false,
    columnSorting: [],
    disabledColumns: [],
    onSortingChange: noop
  },
  groupingConfig: {
    isDisabled: true,
    expandedGroups: [],
    groupedColumns: [],
    onExpandedGroupsChange: noop,
    columnsForSummary: [],
    getChildGroups: undefined,
    groupColumnExtensions: undefined,
    GroupedCellComponent: null,
    renderSummaryCellComponent: null,
    isWithSummary: false
  },
  paginationConfig: {
    isDisabled: false,
    isServerSidePagination: false,
    pageNumber: 0,
    pageSize: first(DEFAULT_PAGE_SIZES),
    pageSizes: DEFAULT_PAGE_SIZES,
    onPageNumberChange: noop,
    onPageSizeChange: noop
  },
  columnWidthsConfig: {
    isDisabled: false,
    columnWidths: [],
    minColumnWidth: 'auto',
    disabledColumns: [],
    onColumnWidthsChange: noop
  },
  resizingMode: 'nextColumn',
  columnOrderConfig: {
    isDisabled: false,
    columnOrder: [],
    disabledColumns: [],
    onColumnOrderChange: noop
  },
  selectionConfig: {
    selection: [],
    isDisabled: false,
    onSelectionChange: noop,
    disableHeaderCell: false
  },
  editingConfig: {
    isDisabled: true,
    createRowChange: noop,
    editingRowIds: []
  },
  className: null,
  defaultHiddenColumnNames: [],
  treeDataConfig: {
    isDisabled: true,
    treeColumn: [],
    getChildRows: noop,
    defaultExpandedRowIds: [],
    expandedRowIds: [],
    onExpandedRowIdsChange: noop
  },
  header: {
    headerColor: 'transparent',
    renderContent: null
  },
  columnBands: null
};

export default React.memo(TableBase, isEqual);
