import {
  getIfPasswordValid,
  getNormalizedPasswordPolicies,
  getPasswordTooltipText
} from '@auth/utils';

import { Button, TextButton } from '@ds';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import alertsActions from '@/actions/alertsActions';
import forgotPasswordActions from '@/actions/forgotPasswordActions';
import { dialogTypes } from '@/constants/alertTypes';
import { BASE_API_URL } from '@/lib/appConstants';
import GenericModal from '@shared/components/GenericModal';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';
import {
  getIsUserLoggedIn,
  getIsUserLoggedInWithGitHub
} from '@/reducers/userReducer';

const ChangePasswordModal = ({ dispatch, token, isUserLoggedIn }) => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [savingPass, setSavingPass] = useState(false);
  const error = useSelector(state => state.forgotPassword.error);
  const [passwordPolicy, setPasswordPolicy] = useState();

  useEffect(() => {
    axios
      .get(`${BASE_API_URL}auth/password-policy`)
      .then(res => setPasswordPolicy(res.data));
  }, []);

  const handleCloseModal = () => {
    dispatch(alertsActions.closeDialog(dialogTypes.FORGOT_PASSWORD));

    if (!isUserLoggedIn) {
      window.location.href = '/login';
    }
  };

  const handleChangePassword = () => {
    setSavingPass(true);
    dispatch(
      forgotPasswordActions.sendNewPassword(
        {
          password: newPassword,
          resetToken: token
        },
        {
          onSuccess: handleCloseModal,
          onError: () => {
            setNewPassword('');
            setRepeatPassword('');
          }
        }
      )
    ).finally(() => {
      setSavingPass(false);
    });
  };

  const dynamicRegexes = useMemo(() => {
    return getNormalizedPasswordPolicies(passwordPolicy, {
      value: newPassword
    });
  }, [passwordPolicy]);

  const isSubmitBtnDisabled =
    newPassword !== repeatPassword ||
    !getIfPasswordValid({
      password: newPassword,
      resultNotToMatch: dynamicRegexes?.resultNotToMatch,
      resultToMatch: dynamicRegexes?.resultToMatch
    });

  const getNewPasswordTooltipText = useCallback(
    () =>
      getPasswordTooltipText({
        passwordPolicy,
        password: newPassword,
        username: null
      }),
    [newPassword]
  );

  const getRepeatPasswordTooltipText = () => {
    if (repeatPassword.length && repeatPassword !== newPassword) {
      return 'Passwords do not match';
    }

    return null;
  };

  const passwordPlaceholder = '********';

  const renderNewPasswordField = () => {
    const tooltipText = getNewPasswordTooltipText();

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">New Password</InputLabel>
        <StyledValidationTooltip title={tooltipText} open={tooltipText}>
          <InputBase
            name="new-password"
            className="modal-input-base"
            onChange={e => setNewPassword(e.target.value)}
            value={newPassword}
            placeholder={passwordPlaceholder}
            type="password"
          />
        </StyledValidationTooltip>
      </div>
    );
  };

  const renderRepeatPasswordField = () => {
    const tooltipText = getRepeatPasswordTooltipText();

    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">
          Repeat New Password
        </InputLabel>
        <StyledValidationTooltip title={tooltipText} open={tooltipText}>
          <InputBase
            className="modal-input-base"
            onChange={e => setRepeatPassword(e.target.value)}
            value={repeatPassword}
            placeholder={passwordPlaceholder}
            type="password"
          />
        </StyledValidationTooltip>
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title="Change Password"
      hideFooter
    >
      <DialogContent
        style={{ padding: '24px 24px 0px 24px' }}
        className="generic-modal-body"
      >
        <div
          className="generic-modal-group"
          style={{
            flexDirection: 'column',
            alignSelf: 'flexStart',
            width: '100%',
            marginBottom: 0
          }}
        >
          <form autoComplete="off">
            {renderNewPasswordField()}
            {renderRepeatPasswordField()}
          </form>
          {error && (
            <Alert
              variant="filled"
              style={{ marginBottom: 20, fontSize: 14 }}
              severity="error"
            >
              {error}
            </Alert>
          )}
        </div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton size="large" onClick={handleCloseModal}>
          Cancel
        </TextButton>
        <Button
          size="large"
          onClick={handleChangePassword}
          disabled={isSubmitBtnDisabled || savingPass}
          data-test="new-project-save"
        >
          Confirm
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

ChangePasswordModal.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

export default connect(state => ({
  isUserLoggedIn: getIsUserLoggedIn(state) || getIsUserLoggedInWithGitHub(state)
}))(ChangePasswordModal);
