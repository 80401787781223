// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cells-module__pipelineGroupContentWrapper--eD8aF{display:flex;font-size:14px;margin:16px 0 16px 20px;align-items:center;justify-content:space-between;width:285px}.Cells-module__pipelineGroupContentWrapper--eD8aF .Cells-module__pipelineGroupedValue--KqHaf{color:var(--black);text-decoration:underline}.Cells-module__pipelineGroupContentWrapper--eD8aF .Cells-module__pipelineActionsWrapper--XAJVp{display:flex;align-items:center;justify-content:space-between;width:64px}.Cells-module__pipelineGroupContentWrapper--eD8aF .Cells-module__pipelineActionsWrapper--XAJVp .Cells-module__pipelineActionIcon--kmSB1{cursor:pointer;color:var(--gray-5)}.Cells-module__pipelineGroupContentWrapper--eD8aF .Cells-module__pipelineActionsWrapper--XAJVp .Cells-module__pipelineActionIcon--kmSB1:hover{color:var(--gray-7)}.Cells-module__iconTooltip--hiHo_{background-color:var(--main-header-color) !important;color:var(--white) !important}.Cells-module__iconTooltipArrow--yMzPb{color:var(--main-header-color) !important}.Cells-module__iconWrapper--M_eZu{display:flex}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pipelineGroupContentWrapper": `Cells-module__pipelineGroupContentWrapper--eD8aF`,
	"pipelineGroupedValue": `Cells-module__pipelineGroupedValue--KqHaf`,
	"pipelineActionsWrapper": `Cells-module__pipelineActionsWrapper--XAJVp`,
	"pipelineActionIcon": `Cells-module__pipelineActionIcon--kmSB1`,
	"iconTooltip": `Cells-module__iconTooltip--hiHo_`,
	"iconTooltipArrow": `Cells-module__iconTooltipArrow--yMzPb`,
	"iconWrapper": `Cells-module__iconWrapper--M_eZu`
};
module.exports = ___CSS_LOADER_EXPORT___;
