const MODEL_NAME_COLUMN_WIDTH = 298;

const DEFAULT_MODEL_SORTING = [{ columnName: 'modelName', direction: 'desc' }];

const MODEL_COLUMN_WIDTHS = [
  { columnName: 'modelName', width: MODEL_NAME_COLUMN_WIDTH }
];

const NO_PADDING_COLUMNS = ['modelName'];

const AREACHART_Y_RANGE = [-0.09, 1.1];

const MODELS_GROUPED_ROW_NAME = 'ModelsList';
const MODELS_GROUPED_COLUMN_NAME = 'pipelineName';

const MODEL_COLUMNS_NAMES = {
  alerts: 'alerts',
  createdAt: 'createdAt',
  latestVersion: 'latestVersion',
  modelName: 'modelName',
  latestPrediction: 'latestPrediction',
  numberOfPredictions: 'numberOfPredictions',
  pipelineName: MODELS_GROUPED_COLUMN_NAME
};

const MODEL_SUMMARY_COLUMNS = [
  'alerts',
  'createdAt',
  'latestVersion',
  'latestPrediction',
  'numberOfPredictions'
];

const MODEL_SERVER_COLUMNS_NAMES = {
  alerts: 'alerts',
  createdAt: 'created_ts',
  latestVersion: 'version',
  modelName: 'model_name',
  latestPrediction: 'latest_prediction',
  numberOfPredictions: 'prediction_count'
};

const MODEL_LEFT_COLUMNS = [
  MODEL_COLUMNS_NAMES.modelName,
  MODEL_COLUMNS_NAMES.pipelineName
];

const MODEL_DISABLED_COLUMNS = [
  MODEL_COLUMNS_NAMES.modelName,
  MODEL_COLUMNS_NAMES.pipelineName
];

const MODEL_PAGE_SIZES = [10, 25, 50, 100];

export {
  MODEL_NAME_COLUMN_WIDTH,
  DEFAULT_MODEL_SORTING,
  AREACHART_Y_RANGE,
  MODEL_LEFT_COLUMNS,
  NO_PADDING_COLUMNS,
  MODEL_DISABLED_COLUMNS,
  MODEL_PAGE_SIZES,
  MODEL_COLUMNS_NAMES,
  MODEL_COLUMN_WIDTHS,
  MODEL_SERVER_COLUMNS_NAMES,
  MODEL_SUMMARY_COLUMNS,
  MODELS_GROUPED_ROW_NAME,
  MODELS_GROUPED_COLUMN_NAME
};
