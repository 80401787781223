import React from 'react';
import useCompareExperimentsKeys from '@shared/hooks/useCompareExperimentsKeys';
import ProjectTypePageSelector from '@shared/components/ProjectTypePageSelector';
import ProjectPageWrapper from '@shared/components/ProjectPageWrapper';
import CompareExperimentsDetails from '../ExperimentDetailWrappers/CompareExperimentsDetails';
import ExperimentDetailViewConfiguration from './ExperimentDetailViewConfiguration';
import ExperimentDetailsNavBar from '../ExperimentDetailsNavBar/ExperimentDetailsNavBar';

const ComparisonExperimentDetailRoute = () => {
  const { experimentKeys } = useCompareExperimentsKeys();
  const [experimentKey] = experimentKeys || [];

  return (
    <ProjectTypePageSelector
      EMComponent={
        <ProjectPageWrapper
          header={
            <ExperimentDetailsNavBar
              subheaderRightSection={
                <ExperimentDetailViewConfiguration
                  experimentKey={experimentKey}
                  isComparisonView
                />
              }
            />
          }
          content={<CompareExperimentsDetails />}
        />
      }
    />
  );
};

export default ComparisonExperimentDetailRoute;
