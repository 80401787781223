import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { WarningIcon } from '@Icons-outdated';

import Alert from '@material-ui/lab/Alert';
import classNames from './ModelRegistryWebhooksTab.module.scss';

const ModelRegistryInvalidUrlWarning = ({ isUrlValid, dataTest }) => {
  if (isUrlValid) return null;

  return (
    <Alert
      icon={<WarningIcon fill="#F4B400" />}
      classes={{
        root: classNames.modelRegistryWebhooksFullWidthModalAlertWarning
      }}
      data-test={dataTest}
      severity="warning"
      className={cx(classNames.modelRegistryWebhooksFullWidthModalAlertWarning)}
    >
      This doesn&apos;t look like a valid URL
    </Alert>
  );
};

ModelRegistryInvalidUrlWarning.propTypes = {
  isUrlValid: PropTypes.bool.isRequired,
  dataTest: PropTypes.string.isRequired
};

export default ModelRegistryInvalidUrlWarning;
