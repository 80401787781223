import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { CrossIcon, NoSearchResultsIcon, SearchIcon } from '@Icons-outdated';
import { ColorPreview } from '@design-system-outdated';
import { Input, Switch } from '@DesignSystem/controllers';
import { BasicModal } from '@DesignSystem/modals';
import chartActions from '@/actions/experimentDetails/chartActionsPlotly';
import useChangeColorState from './useChangeColorState';
import { CHART_COLORS } from '@/lib/appConstants';

import styles from './ChangeColorButton.module.scss';
import ChangeColorMetricName from './ChangeColorMetricName';

const ChangeColorModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  const cleanSearch = () => setSearchValue('');

  const {
    colorMap,
    onUpdateColorMap,
    onClearChanges,
    onResetDefault,

    isSingleColor,
    onToggleSingleColor
  } = useChangeColorState();

  useEffect(() => {
    if (open) {
      setSearchValue('');
      onClearChanges();
    }
  }, [open, onClearChanges]);

  const filteredMetricNames = useMemo(() => {
    const metricNames = Object.keys(colorMap);
    if (!searchValue) {
      return metricNames;
    }

    return metricNames.filter(metricName =>
      metricName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [colorMap, searchValue]);

  const handleSaveColors = useCallback(() => {
    const newColors = Object.entries(colorMap).map(([metricName, color]) => ({
      metricName,
      color
    }));

    dispatch(
      chartActions.updateChartTemplateColorMap({
        colors: newColors,
        // 2 fields are needed for constistency of the integration
        isSingleExperimentColor: isSingleColor,
        singleExperimentColor: isSingleColor
      })
    );

    onClose();
  }, [colorMap, dispatch, onClose, isSingleColor]);

  const renderMetricList = () => {
    if (!filteredMetricNames.length) {
      return (
        <div className={styles.noFilterResultsContainer}>
          <NoSearchResultsIcon />
          <p>No search results</p>
        </div>
      );
    }

    return filteredMetricNames.map(metricName => (
      <div className={styles.metricColorRow} key={metricName}>
        <ChangeColorMetricName metricName={metricName} />
        <div className={styles.metricColor}>
          <ColorPreview
            color={colorMap[metricName]}
            onChange={({ hex }) => onUpdateColorMap({ metricName, color: hex })}
            presetColors={CHART_COLORS}
          />
        </div>
      </div>
    ));
  };

  const renderContent = () => (
    <div className={styles.changeColorContentContainer}>
      <div className={styles.searchSectionChangeColor}>
        <div className={styles.searchSectionLabelContainer}>
          <p>Change the metrics colors for this view</p>
          <div className={styles.singleColorContainer}>
            <Switch checked={isSingleColor} onChange={onToggleSingleColor} />
            <span className={styles.singleColorLabel}>Single color</span>
          </div>
        </div>

        <Input
          placeholder="Search by metric name"
          InlinePrefixIcon={SearchIcon}
          onChange={setSearchValue}
          value={searchValue}
          PostfixIcon={!!searchValue && CrossIcon}
          onPostfixIconClick={cleanSearch}
        />
      </div>

      <div className={styles.colorsContainer}>
        <div className={cx(styles.metricColorHeader, styles.metricColorRow)}>
          <p>Metric</p>
          <p className={styles.metricColor}>Color</p>
        </div>
        {renderMetricList()}
      </div>
    </div>
  );

  return (
    <BasicModal
      title="Change Colors"
      className={styles.changeColorModal}
      open={open}
      onClose={onClose}
      content={renderContent()}
      secondaryButtonText="Reset default"
      onSecondaryButtonClick={onResetDefault}
      primaryButtonText="Done"
      onPrimaryButtonClick={handleSaveColors}
      footerActions={[
        {
          onClick: onClearChanges,
          disabled: false,
          key: 'clear-changes',
          text: 'Clear changes',
          type: 'tertiary'
        }
      ]}
    />
  );
};

ChangeColorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChangeColorModal;
