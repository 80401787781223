import React, { useEffect, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { OptimizedPlot } from '@DesignSystem/charts';
import ChartContainer, {
  GeneralChartContainerProps
} from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import useAssetById from '@experiment-management-shared/api/useAssetById';
import {
  DEFAULT_IGNORE_OUTLIERS,
  DEFAULT_MAX_STEPS_PER_HISTOGRAM
} from '@experiment-management-shared/constants/histogram';
import { ACTIVE_FETCH_INTERVAL } from '@experiment-management-shared/constants/chartConstants';
import usePlotlyBaseChart from '@experiment-management-shared/components/Charts/Chart/chartHooks/usePlotlyBaseChart';
import {
  getColor,
  getFilteredDataSources,
  getHistogramsBinCounts,
  removeZeroYValues
} from '@experiment-management-shared/components/Charts/PlotlyChart/helpers/HistogramHelpers';

type HistogramData = {
  version: number;
  values: unknown;
  index_values: number[][];
  offset: number;
  start: number;
  stop: number;
  step: number;
};

type HistogramDataSource = {
  epoch: null | number;
  histogram: HistogramData;
  step: null | number;
};

export type Histogram3DChartProps = {
  containerProps: GeneralChartContainerProps;
  isAutoRefreshEnabled: boolean;
  ignoreOutliers?: number;
  maxSteps?: number;
  hiddenMenuItems: never;
  title: string;
};

const MAX_ANNOTATION_LENGTH = 5;

const Histogram3DChart = ({
  containerProps,
  hiddenMenuItems,
  isAutoRefreshEnabled,
  ignoreOutliers = DEFAULT_IGNORE_OUTLIERS,
  maxSteps = DEFAULT_MAX_STEPS_PER_HISTOGRAM,
  title
}: Histogram3DChartProps) => {
  const {
    data: panelData,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useAssetById<{
    histograms: HistogramDataSource[];
  }>(
    {
      assetId: containerProps.chartId,
      experimentKey: containerProps.experimentKeys[0],
      silent: true
    },
    {
      refetchInterval: isAutoRefreshEnabled ? ACTIVE_FETCH_INTERVAL : false,
      refetchOnMount: true
    }
  );

  const dataSources: HistogramDataSource[] = useMemo(() => {
    return panelData?.histograms || [];
  }, [panelData]);

  const {
    setExportData,
    containerRef,
    handleExportJSON,
    handleExportData,
    handleResetZoom,
    disableResetZoom,
    chartBaseLayout,
    PlotlyProps
  } = usePlotlyBaseChart({
    chartType: containerProps.chartType,
    title
  });

  useEffect(() => {
    setExportData(dataSources as never);
  }, [dataSources, setExportData]);

  const histograms = useMemo(() => {
    const filteredDataSources = getFilteredDataSources(dataSources, maxSteps);

    return getHistogramsBinCounts(filteredDataSources, ignoreOutliers);
  }, [dataSources, ignoreOutliers, maxSteps]);

  const { stepMax, stepMin, steps } = histograms;
  const stepsRange = stepMax - stepMin + 1;

  const annotations = useMemo(() => {
    let annotations = steps.map(({ bins, step }) => {
      const x = bins.map(bin => bin.x);
      const maxX = Math.max(...x);
      const stepColor = getColor(step - stepMin, stepsRange);

      return {
        x: maxX,
        y: 0,
        xref: 'x',
        yref: `y${step + 1}`,
        text: `Step ${step}`,
        showarrow: true,
        arrowhead: 0,
        ax: 70,
        ay: 0,
        arrowcolor: stepColor,
        font: {
          color: stepColor
        }
      };
    });

    if (annotations.length > MAX_ANNOTATION_LENGTH) {
      const indexes = [
        0,
        Math.floor(annotations.length / 4),
        Math.floor(annotations.length / 2),
        annotations.length - 1 - Math.floor(annotations.length / 4),
        annotations.length - 1
      ];

      annotations = annotations.filter((_, index) => indexes.includes(index));
    }

    return annotations;
  }, [histograms]);

  const layout = useMemo(() => {
    const yValues: number[] = steps.reduce((result, { bins }) => {
      const y: number[] = bins.map(bin => bin.y);

      return result.concat(y);
    }, [] as number[]);

    const maxYValue = Math.max(...yValues);
    const yMargin = maxYValue * 0.01;
    const maxYValueWithMargin = maxYValue + yMargin;
    const offset = maxYValueWithMargin / stepsRange;
    const range = maxYValueWithMargin * 2;
    const layout: Record<string, unknown> = {
      annotations,
      hoverdistance: -1,
      hoverlabel: { bgcolor: '#FFF' },
      hovermode: 'closest',
      margin: { l: 20, r: 20, t: 30, b: 30, pad: 0 },
      showlegend: false
    };

    steps.forEach(({ step }) => {
      const key = `yaxis${step + 1}`;
      const start = -(stepMax - step) * offset - yMargin;

      layout[key] = {
        range: [start, range + start],
        visible: false,
        overlaying: step != stepMin ? `y${stepMin + 1}` : undefined
      };
    });

    return {
      ...omit(chartBaseLayout, ['xaxis', 'yaxis']),
      ...layout
    };
  }, [annotations, chartBaseLayout, histograms]);

  const data = useMemo(() => {
    return steps.map(({ bins, step }) => {
      const { x, y } = removeZeroYValues(bins);
      const color = getColor(step - stepMin, stepsRange);

      return {
        x,
        y,
        fill: 'tozeroy',
        fillcolor: color,
        hoveron: 'points+fills',
        hovertemplate: `x: %{x:.4f}, y: %{y:.2f}, z(step): ${step}<extra></extra>`,
        line: { color: '#FFCBB3' },
        name: `Step ${step}`,
        mode: 'lines',
        type: 'scattergl',
        yaxis: `y${step + 1}`
      };
    });
  }, [stepMin, steps, stepsRange]);

  return (
    <ChartContainer
      {...containerProps}
      chartContainerRef={containerRef as React.RefObject<HTMLDivElement>}
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
      isPreviousData={isPreviousData}
      hasData={!isEmpty(panelData)}
      title={title}
      hiddenMenuItems={hiddenMenuItems}
      onExportJSON={handleExportJSON}
      onExportData={handleExportData}
      onResetZoom={handleResetZoom}
      disableResetZoom={disableResetZoom}
      fullScreenType={'badges'}
    >
      <OptimizedPlot {...PlotlyProps} data={data} layout={layout} />
    </ChartContainer>
  );
};

export default Histogram3DChart;
