// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupedColumnCell-module__groupContent--xmXZh{color:#191a1c;display:flex;font-size:14px;margin:16px 0 16px 12px}.GroupedColumnCell-module__groupActive--L_3yn{color:#5155f5}.GroupedColumnCell-module__groupContentValue--ZXpgg{font-weight:bold;margin-right:4px}.GroupedColumnCell-module__groupRowWrapper--f_LdP{border-right:1px solid #e0e0e0}.GroupedColumnCell-module__groupRowWrapper--f_LdP>div{align-items:center;display:flex;padding-left:calc(25px*var(--indent-level, 0)) !important}.GroupedColumnCell-module__groupRowWrapper--f_LdP .GroupedColumnCell-module__groupIcon--bYjRP{color:#5155f5;padding-left:2px;transition:transform .2s}.GroupedColumnCell-module__groupRowWrapper--f_LdP .GroupedColumnCell-module__groupIcon--bYjRP.GroupedColumnCell-module__groupIconExpanded--HKA1p{transform:rotate(90deg)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupContent": `GroupedColumnCell-module__groupContent--xmXZh`,
	"groupActive": `GroupedColumnCell-module__groupActive--L_3yn`,
	"groupContentValue": `GroupedColumnCell-module__groupContentValue--ZXpgg`,
	"groupRowWrapper": `GroupedColumnCell-module__groupRowWrapper--f_LdP`,
	"groupIcon": `GroupedColumnCell-module__groupIcon--bYjRP`,
	"groupIconExpanded": `GroupedColumnCell-module__groupIconExpanded--HKA1p`
};
module.exports = ___CSS_LOADER_EXPORT___;
