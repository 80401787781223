import React from 'react';
import PropTypes from 'prop-types';

import * as Showdown from 'showdown';
import noop from 'lodash/noop';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const defaultIframeStyles = `<style>
html,
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>`;

const MarkdownIFrame = ({
  onErrorHandler,
  onLoadHandler,
  styles,
  textString
}) => {
  if (!textString) return null;

  const html = converter.makeHtml(textString);

  const srcDoc = `<!DOCTYPE html><html><head>${styles}</head><body>${html}</body></html>`;

  return (
    <iframe
      onLoad={onLoadHandler}
      onError={onErrorHandler}
      className="textString-iframe"
      style={{ width: '100%', height: '100%' }}
      sandbox=""
      srcDoc={srcDoc}
    />
  );
};

MarkdownIFrame.defaultProps = {
  onErrorHandler: noop,
  onLoadHandler: noop,
  styles: defaultIframeStyles
};

MarkdownIFrame.propTypes = {
  onErrorHandler: PropTypes.func,
  onLoadHandler: PropTypes.func,
  styles: PropTypes.string,
  textString: PropTypes.string.isRequired
};

export default MarkdownIFrame;
