// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicAnnotationsSection-module__container--ji9ZG{padding:10px}.GraphicAnnotationsSection-module__fieldText--W0K7M{line-height:1}.GraphicAnnotationsSection-module__labelsGrid--jPZJP{margin-bottom:25px;display:flex;justify-content:start;flex-wrap:wrap;gap:8px}.GraphicAnnotationsSection-module__slider--f3uer{width:300px;margin-left:3px;margin-top:15px}.GraphicAnnotationsSection-module__sliderBlock--vLtmh{margin-top:30px;margin-bottom:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GraphicAnnotationsSection-module__container--ji9ZG`,
	"fieldText": `GraphicAnnotationsSection-module__fieldText--W0K7M`,
	"labelsGrid": `GraphicAnnotationsSection-module__labelsGrid--jPZJP`,
	"slider": `GraphicAnnotationsSection-module__slider--f3uer`,
	"sliderBlock": `GraphicAnnotationsSection-module__sliderBlock--vLtmh`
};
module.exports = ___CSS_LOADER_EXPORT___;
