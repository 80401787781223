import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip, IconButton } from '@ds';

import noop from 'lodash/noop';

import CreateIcon from '@material-ui/icons/Create';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import EditArtifactModal from './EditArtifactModal';
import StyledMenuItem from '@shared/components/StyledComponents/StyledMenuItem';
import { DSEditIcon } from '@ds-icons';

const EditArtifactModalBtn = ({
  closeHandler,
  isIconButton,
  isMenuButton,
  disabled,
  ...modalProps
}) => {
  const dispatch = useDispatch();
  const { workspace } = useParams();

  const modalId = dialogTypes.EDIT_ARTIFACT_MODAL;
  const modal = (
    <EditArtifactModal
      modalId={modalId}
      dispatch={dispatch}
      workspaceName={workspace}
      {...modalProps}
    />
  );

  const handleClick = e => {
    e.stopPropagation();
    closeHandler();
    dispatch(alertsUtil.openCustomModal(modalId, modal));
  };

  if (isIconButton) {
    return (
      <Tooltip content="Edit artifact" placement="top">
        <IconButton
          data-test="edit-artifact-button"
          type="secondary"
          Icon={<DSEditIcon />}
          onClick={handleClick}
          disabled={disabled}
        />
      </Tooltip>
    );
  }

  if (isMenuButton) {
    return (
      <StyledMenuItem
        Icon={CreateIcon}
        text="Edit"
        onClick={handleClick}
        disabled={disabled}
      />
    );
  }

  return null;
};

EditArtifactModalBtn.defaultProps = {
  closeHandler: noop,
  disabled: false,
  isIconButton: false,
  isMenuButton: false
};

EditArtifactModalBtn.propTypes = {
  closeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  isIconButton: PropTypes.bool,
  isMenuButton: PropTypes.bool
};

export default EditArtifactModalBtn;
