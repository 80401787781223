import React from 'react';
import PropTypes from 'prop-types';

import useSelectedProjectView from '@API/project/useSelectedProjectView';
import useReports from '@API/reports/useReports';
import Popover from '@material-ui/core/Popover';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import { reportRoute } from '@routes/utils/reports';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NEW_VIEW_TEMPLATE_NAME } from '@/constants/dashboardConstants';

const AddToReportPopover = ({ anchorEl, dashboard, onClose }) => {
  const { projectName, workspace } = useParams();
  const {
    data: selectedView,
    isLoading: isSelectedProjectViewLoading
  } = useSelectedProjectView();
  const { data: reports, isLoading: isReportsLoading } = useReports();

  if (isSelectedProjectViewLoading || isReportsLoading) {
    return null;
  }

  const payload = {
    dashboard,
    name: selectedView?.templateName || NEW_VIEW_TEMPLATE_NAME
  };

  const renderAddToReportItem = report => {
    const { isTemplate, reportName } = report;

    const editURL = reportRoute({
      editMode: REPORT_EDIT_MODES.EDIT,
      isTemplate,
      projectName,
      reportName,
      workspaceName: workspace
    });

    return (
      <div className="add-view-to-report-list-item" key={report.reportId}>
        <Link to={{ pathname: editURL, state: payload }}>
          {report.reportName}
        </Link>
      </div>
    );
  };

  const newReportURL = reportRoute({
    editMode: REPORT_EDIT_MODES.EDIT,
    isTemplate: false,
    projectName,
    workspaceName: workspace
  });

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={{
        vertical: -6,
        horizontal: 'right'
      }}
    >
      <div className="add-view-to-report-list">
        {!!reports?.length && (
          <div className="add-view-to-report-list-container">
            {reports.map(renderAddToReportItem)}
          </div>
        )}
        <div className="add-view-to-report-list-item add-view-to-new-report-item">
          <Link to={{ pathname: newReportURL, state: payload }}>
            + New Report
          </Link>
        </div>
      </div>
    </Popover>
  );
};

AddToReportPopover.defaultProps = {
  anchorEl: null,
  dashboard: null
};

AddToReportPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  dashboard: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

export default AddToReportPopover;
