import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import cx from 'classnames';

import { Button } from '@ds';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  DEFAULT_IGNORE_OUTLIERS,
  DEFAULT_MAX_STEPS_PER_HISTOGRAM
} from '@experiment-management-shared/constants/histogram';
import { SORT_TYPES } from '@experiment-management-shared/constants/tabConfigs';

import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';
import {
  useExperimentHistogramState,
  useExperimentHistogramStateMutation
} from '@experiment-details/api';
import useExperimentViewState from '@experiment-management-shared/hooks/useExperimentViewState';

import { dialogTypes } from '@/constants/alertTypes';
import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import useAssetsForExperiments from '@experiment-management-shared/api/useAssetsForExperiments';
import useSelectedExperiments from '@experiment-details/hooks/useSelectedExperiments';
import { getMetricChartsTemplate } from '@/reducers/experimentDetails/chartsReducer';
import alertsUtil from '@/util/alertsUtil';

import SmallLoader from '@shared/components/SmallLoader';
import ExperimentAssetsConfigBar from '@experiment-details/components/ExperimentAssetsConfigBar';
import HistogramsGroupedBy from './HistogramsGroupedBy';
import MultipleHistogramList from './MultipleHistogramList';
import SelectHistogramsModal from './SelectHistogramsModal';

import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import { Slider } from '@design-system-outdated';

import styles from './HistogramTab.module.scss';
import HistogramTabEmpty from './HistogramTabEmpty';

const HISTOGRAM_ASSET_TYPE = 'histogram_combined_3d';

const groupByOptions = [
  { value: false, label: 'None' },
  { value: 'fileName', label: 'Name' },
  { value: SORT_TYPES.CONTEXT, label: 'Context' }
];

const sortByOptions = [{ value: SORT_TYPES.NAME, label: 'Name' }];

const HistogramTab = ({ experiments }) => {
  const currentTemplate = useSelector(getMetricChartsTemplate);
  const [experiment] = experiments;

  const experimentHistogramStateMutation = useExperimentHistogramStateMutation();
  const dispatch = useDispatch();
  const isComparePage = useSelector(selectIsComparePage);
  const { experiment1, experiment2 } = useSelectedExperiments(experiments);

  const memorizedExperiments = useMemo(() => {
    if (isEmpty(experiment2)) {
      return [experiment1];
    }
    return [experiment1, experiment2];
  }, [experiment1, experiment2]);

  const {
    data: assetSummaries,
    isLoading: isAssetsLoading
  } = useAssetsForExperiments({
    experiments,
    type: HISTOGRAM_ASSET_TYPE
  });

  const {
    data: hiddenHistograms,
    isLoading: isHistogramStateLoading
  } = useExperimentHistogramState({
    experimentKey: isComparePage ? undefined : experiment.experimentKey
  });

  const isLoading = isHistogramStateLoading || isAssetsLoading;

  const [ignoreOutliers, setIgnoreOutliers] = useExperimentViewState({
    experimentKey: experiment.experimentKey,
    queryStringParameter: 'ignoreOutliers',
    template: currentTemplate,
    valuePath: `${EXPERIMENT_VIEW_TAB_FIELDS.HISTOGRAM}.ignoreOutliers`
  });
  const [maxSteps, setMaxSteps] = useExperimentViewState({
    experimentKey: experiment.experimentKey,
    queryStringParameter: 'maxSteps',
    template: currentTemplate,
    valuePath: `${EXPERIMENT_VIEW_TAB_FIELDS.HISTOGRAM}.maxSteps`
  });

  const [assets, setAssets] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [tempMaxSteps, setTempMaxSteps] = useState(
    maxSteps ?? DEFAULT_MAX_STEPS_PER_HISTOGRAM
  );
  const [tempIgnoreOutliers, setTempIgnoreOutliers] = useState(ignoreOutliers);

  const isTabEmpty = !assetSummaries.length && !isLoading;

  useEffect(() => {
    setTempIgnoreOutliers(ignoreOutliers);
  }, [ignoreOutliers]);

  useEffect(() => {
    setTempMaxSteps(maxSteps);
  }, [maxSteps]);

  const handleDeleteHistogram = useCallback(
    ({ chartId }) => {
      experimentHistogramStateMutation.mutate({
        experimentKey: experiment.experimentKey,
        state: uniq((hiddenHistograms || []).concat([chartId]))
      });
    },
    [
      experiment.experimentKey,
      experimentHistogramStateMutation,
      hiddenHistograms
    ]
  );

  const handleAssetsSearch = ({ assets: assetsFiltered, groupBy }) => {
    if (!isEqual(assets, assetsFiltered)) {
      setAssets(assetsFiltered);
    }

    setGroupBy(groupBy);
  };

  const { hiddenHistogramsItems, visibleHistograms } = useMemo(() => {
    const retVal = {
      hiddenHistogramsItems: [],
      visibleHistograms: []
    };

    assetSummaries.forEach(asset => {
      if ((hiddenHistograms || []).includes(asset.assetId)) {
        retVal.hiddenHistogramsItems.push({
          id: asset.assetId,
          name: asset.fileName
        });
      } else {
        retVal.visibleHistograms.push(asset);
      }
    });

    return retVal;
  }, [assetSummaries, hiddenHistograms]);

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  const renderContent = () => {
    if (groupBy) {
      return (
        <HistogramsGroupedBy
          experiments={memorizedExperiments}
          assets={assets}
          ignoreOutliers={ignoreOutliers}
          maxSteps={maxSteps}
          handleDeleteHistogram={handleDeleteHistogram}
        />
      );
    }

    return (
      <MultipleHistogramList
        experiments={memorizedExperiments}
        assets={assets}
        ignoreOutliers={ignoreOutliers}
        maxSteps={maxSteps}
        handleDeleteHistogram={handleDeleteHistogram}
      />
    );
  };

  const addChart = () => {
    const handleSaveState = state => {
      experimentHistogramStateMutation.mutate({
        experimentKey: experiment.experimentKey,
        state
      });
    };

    const selectHistogramsModal = (
      <SelectHistogramsModal
        items={hiddenHistogramsItems}
        onSaveState={handleSaveState}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.SELECT_HISTOGRAMS_MODAL,
        selectHistogramsModal
      )
    );
  };

  const buttons = isComparePage ? null : (
    <Button disabled={!hiddenHistogramsItems.length} onClick={addChart}>
      Add panel
    </Button>
  );

  const customControlComponents = [
    {
      label: 'Sample size',
      component: (
        <Slider
          min={0}
          max={500}
          step={1}
          onChange={setTempMaxSteps}
          onChangeCommitted={setMaxSteps}
          value={tempMaxSteps}
          disabled={isTabEmpty}
        />
      )
    },
    {
      label: 'Ignore outliers',
      component: (
        <Slider
          min={0}
          max={1}
          step={0.1}
          onChange={setTempIgnoreOutliers}
          onChangeCommitted={setIgnoreOutliers}
          value={tempIgnoreOutliers ?? DEFAULT_IGNORE_OUTLIERS}
          sliderSuffixFormat={value => `${value}%`}
          suffixWidth={31}
          disabled={isTabEmpty}
        />
      )
    }
  ];

  return (
    <div
      className={cx(
        styles.container,
        { [styles.compare]: isComparePage },
        'histogram-tab'
      )}
    >
      <ExperimentAssetsConfigBar
        assets={visibleHistograms}
        buttons={buttons}
        customControlComponents={customControlComponents}
        enableAutoResearch
        experimentKey={experiment.experimentKey}
        groupByOptions={groupByOptions}
        hideSortBy
        hideStepSlider
        hideStepPlayer
        onAssetsSearch={handleAssetsSearch}
        sortByOptions={sortByOptions}
        valuePathCategory={EXPERIMENT_VIEW_TAB_FIELDS.HISTOGRAM}
        isTabEmpty={isTabEmpty}
      />
      {isComparePage && (
        <CompareContentSelector
          experiments={experiments}
          experiment1={experiment1}
          experiment2={experiment2}
        />
      )}
      {isTabEmpty ? (
        <HistogramTabEmpty isComparePage={isComparePage} />
      ) : (
        renderContent()
      )}
    </div>
  );
};

HistogramTab.displayName = 'HistogramTab';

HistogramTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};
export default HistogramTab;
