import { Column } from '@devexpress/dx-react-grid';
import { DATETIME_CELL_KEYS, METRIC_TYPES } from './metricsTableConfig';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import { Metric } from '@shared/types';
import moment from 'moment';

export type MetricTypeKey = keyof typeof METRIC_TYPES;
export type MetricCellRendererData = {
  column: Column;
  row: Metric;
  value: string;
};

export const getDefaultNewMetricMap = (): Metric => {
  const map = {} as Record<MetricTypeKey, string | number>;

  (Object.keys(METRIC_TYPES) as MetricTypeKey[]).forEach(key => {
    if (DATETIME_CELL_KEYS.includes(key)) {
      map[key] = Date.now();
      return;
    }

    map[key] = '';
  });

  return {
    name: '',
    valueCurrent: '',
    timestampMax: Date.now(),
    timestampMin: Date.now(),
    timestampCurrent: Date.now()
  };
};

export const validateMetricData = (data: Partial<Metric> | null): boolean => {
  if (data === null) return false;

  const isInValidValue = (value: string | number | null | undefined) =>
    isNil(value) || isEmpty(value.toString()) || isNaN(value);

  return !(Object.keys(METRIC_TYPES) as MetricTypeKey[]).some(key => {
    return isInValidValue(data[key]);
  });
};

export const getFormattedTime = (timestamp: number | undefined) => {
  return moment(timestamp).format('YYYY-MM-DDTHH:mm');
};
