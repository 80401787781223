// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.GenericButton-module__dsButtonContainer--rwTAw{box-sizing:border-box;border:0;cursor:pointer;border-radius:5px;outline:none;display:flex;align-items:center;max-width:100%}button.GenericButton-module__dsButtonContainer--rwTAw:disabled{cursor:not-allowed}.GenericButton-module__prefixIcon--i_czo{display:flex;align-items:center;justify-content:center;margin-right:6px}.GenericButton-module__postfixIcon--C76N3{display:flex;align-items:center;justify-content:center;margin-left:6px}.GenericButton-module__loader--CWiLl{display:flex;margin-right:6px}.GenericButton-module__loader--CWiLl svg{color:inherit}.GenericButton-module__iconContainer--ejTUk{width:16px;height:16px}.GenericButton-module__iconContainer--ejTUk.GenericButton-module__mediumIconContainer--krhml{width:14px;height:14px}.GenericButton-module__iconContainer--ejTUk.GenericButton-module__smallIconContainer--c_reH{width:12px;height:12px}.GenericButton-module__iconContainer--ejTUk svg{width:100%;height:auto}.GenericButton-module__childrenWrapper--zIR3O{text-overflow:ellipsis;overflow:hidden;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsButtonContainer": `GenericButton-module__dsButtonContainer--rwTAw`,
	"prefixIcon": `GenericButton-module__prefixIcon--i_czo`,
	"postfixIcon": `GenericButton-module__postfixIcon--C76N3`,
	"loader": `GenericButton-module__loader--CWiLl`,
	"iconContainer": `GenericButton-module__iconContainer--ejTUk`,
	"mediumIconContainer": `GenericButton-module__mediumIconContainer--krhml`,
	"smallIconContainer": `GenericButton-module__smallIconContainer--c_reH`,
	"childrenWrapper": `GenericButton-module__childrenWrapper--zIR3O`
};
module.exports = ___CSS_LOADER_EXPORT___;
