import { useQuery } from 'react-query';

import useProject from '../project/useProject';

import api from '../api';

const getPanelTemplate = async ({ projectId, revisionId, templateId }) => {
  return api.get('code-visualizations/template/get', {
    projectId,
    revisionId,
    templateId
  });
};

export default function usePanelTemplates(
  { projectId: selectedProjectId = null, revisionId = null, templateId },
  config
) {
  const { data: project } = useProject();
  const projectId = selectedProjectId ?? project?.projectId;
  const enabled = Boolean(projectId && templateId);

  return useQuery(
    ['panelTemplate', { projectId, revisionId, templateId }],
    () => getPanelTemplate({ projectId, revisionId, templateId }),
    { enabled, ...config }
  );
}
