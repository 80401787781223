import React from 'react';
import { useExperimentOthers } from '@experiment-details/api';
import SmallLoader from '@shared/components/SmallLoader';
import { ExperimentDetails } from '@shared/types';
import OtherSingleTable from './OtherSingleTable/OtherSingleTable';

type OtherParamsTabProps = {
  experiments: ExperimentDetails[];
};
const OtherParamsTab: React.FC<OtherParamsTabProps> = ({ experiments }) => {
  const [experiment] = experiments;

  const { data: params = [], isLoading } = useExperimentOthers({
    experimentKey: experiment?.experimentKey,
    isActive: Boolean(experiment?.runActive)
  });

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  return (
    <OtherSingleTable
      items={params}
      experimentName={experiment.Name}
      experimentKey={experiment.experimentKey}
    />
  );
};

export default OtherParamsTab;
