import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { BasicModal } from '@DesignSystem/modals';
import { PythonEnvironmentVersion } from '@shared/types';

import CodeMirror, { EditorView } from '@uiw/react-codemirror';

import classes from './PythonEnvironment.module.scss';
import { DSInformationIcon } from '@ds-icons';
import { LinkButton, Tooltip } from '@ds';
import { useCreatePythonEnvironmentVersionMutation } from '@account-settings/api/python-environments';
import { getDefaultPythonCodeExtensions } from '@shared';
import { codeSnippetClasses } from '@shared/styles';

interface PythonEnvironmentVersionModalProps {
  versionToShow: PythonEnvironmentVersion | null;
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
}

const PythonEnvironmentVersionModal = ({
  versionToShow,
  onClose,
  isOpen,
  organizationId
}: PythonEnvironmentVersionModalProps) => {
  const [customPackagesCode, setCustomPackagesCode] = useState('');

  const createPythonEnvironmentVersionMutation = useCreatePythonEnvironmentVersionMutation(
    organizationId
  );

  const isCreate = !versionToShow;
  const title = isCreate
    ? 'Create New Version'
    : `Version ${versionToShow?.version}`;
  const primaryTextButton = isCreate ? 'Create' : null;
  const secondaryTextButton = isCreate ? 'Cancel' : null;
  const customPackagesTooltip = isCreate
    ? 'Add additional packages you would like installed in this Python environment. They should follow the format of requirements.txt files'
    : 'Additional packages installed in this Python environment. They should follow the format of requirements.txt files';
  const placeholder = isCreate
    ? 'You can enter the packages here'
    : 'No additional packages';
  const parsedCustomPackagesCode = useMemo(() => {
    if (customPackagesCode === '') {
      return [];
    }

    return (
      customPackagesCode
        ?.split('\n')
        ?.map(p => p.trim())
        .filter(p => p) || []
    );
  }, [customPackagesCode]);

  const onCreateNewVersion = () => {
    createPythonEnvironmentVersionMutation.mutate({
      additionalPackages: parsedCustomPackagesCode
    });

    onClose();
  };

  useEffect(() => {
    setCustomPackagesCode(versionToShow?.customRequirements?.join('\n') || '');
  }, [versionToShow?.customRequirements]);

  useEffect(() => {
    if (!isOpen) {
      setCustomPackagesCode('');
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <BasicModal
      open
      title={title}
      onSecondaryButtonClick={onClose}
      primaryButtonText={primaryTextButton}
      onPrimaryButtonClick={onCreateNewVersion}
      secondaryButtonText={secondaryTextButton}
      content={
        <div className={classes.versionModalContainer}>
          <div className={classes.packageTypeContainer}>
            <p>
              Comet pre-installs a set of Python packages in all environments,
              read more about this in{' '}
              <LinkButton
                text="our documentation"
                to="/docs/v2/guides/comet-ui/experiment-management/visualizations/python-panel/"
              />
              .
            </p>
          </div>

          <div className={classes.packageTypeContainer}>
            <div className={classes.packageTypeLabel}>
              Additional python packages
              <Tooltip wrapperDisplay="flex" content={customPackagesTooltip}>
                <DSInformationIcon />
              </Tooltip>
            </div>
            <div
              className={cx({
                [classes.createRequirementsTxtContainer]: isCreate
              })}
            >
              <CodeMirror
                value={customPackagesCode}
                onChange={setCustomPackagesCode}
                extensions={[
                  ...getDefaultPythonCodeExtensions(),
                  EditorView.editable.of(isCreate)
                ]}
                className={cx(classes.requirementsTxtSnippet, {
                  [classes.editableCodeSnippet]: isCreate,
                  [codeSnippetClasses.readOnlyPythonSnippet]: !isCreate
                })}
                placeholder={placeholder}
                basicSetup={{
                  highlightActiveLine: isCreate,
                  highlightActiveLineGutter: isCreate,
                  lineNumbers: false
                }}
              />
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

export default PythonEnvironmentVersionModal;
