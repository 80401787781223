import { useMutation, useQueryClient } from 'react-query';

import userApi from '@/util/userApi';
import useOptInMpmNotifications from './useOptInMpmNotifications';

const useUpdateOptInMpmNotifications = () => {
  const queryClient = useQueryClient();

  const { data: isActiveOptInMpmNotification } = useOptInMpmNotifications();

  return useMutation(
    () => userApi.setMpmOptInNotification(!isActiveOptInMpmNotification),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['opt-in-mpm-notification']);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['opt-in-mpm-notification']);
      }
    }
  );
};

export default useUpdateOptInMpmNotifications;
