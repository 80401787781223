import { useEffect } from 'react';
import { useSpring } from 'react-spring';

const ROLE_SPRING_STATE = {
  MARGIN: {
    FROM: '200px',
    TO: '0px'
  }
};

const MODELING_SPRING_STATE = {
  MARGIN: {
    FROM: '40px',
    TO: '0px'
  }
};

const usePostSignUpSprings = ({ isTeamSizeInitialized, isRoleInitialized }) => {
  const [springsRole, apiRole] = useSpring(() => ({
    from: {
      marginTop: ROLE_SPRING_STATE.MARGIN.FROM
    }
  }));

  const [springsModeling, apiModeling] = useSpring(() => ({
    from: {
      marginTop: MODELING_SPRING_STATE.MARGIN.FROM
    }
  }));

  useEffect(() => {
    if (isTeamSizeInitialized) {
      apiRole.start({
        from: {
          marginTop: ROLE_SPRING_STATE.MARGIN.FROM
        },
        to: {
          marginTop: ROLE_SPRING_STATE.MARGIN.TO
        }
      });
    }
  }, [isTeamSizeInitialized]);

  useEffect(() => {
    if (isRoleInitialized) {
      apiModeling.start({
        from: {
          marginTop: MODELING_SPRING_STATE.MARGIN.FROM
        },
        to: {
          marginTop: MODELING_SPRING_STATE.MARGIN.TO
        }
      });
    }
  }, [isRoleInitialized]);

  return {
    springsRole,
    springsModeling
  };
};

export default usePostSignUpSprings;
