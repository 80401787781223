import { COLORS } from '@/constants/colorConstants';

export default {
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiCheckbox: {
      disableRipple: true
    },
    MuiRadio: {
      disableRipple: true
    },
    MuiIconButton: {
      disableRipple: true
    },
    MuiInputLabel: {
      disableAnimation: true
    },
    MuiMenu: {
      transitionDuration: 0
    }
  },
  palette: {
    primary: {
      main: COLORS.primary
    }
  }
};
