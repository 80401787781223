import React, { useState } from 'react';
import ListItem from '@ds/lists/ListItem/ListItem';
import { Popover } from '@material-ui/core';
import ConditionalTooltip from '@design-system/components/shared/ConditionalTooltip/ConditionalTooltip';
import { DSMoreIcon } from '@design-system/icons';
import styles from '../../Select.module.scss';

type ExtraOptionItemsProps = {
  label: string;
  isExtraDisabled?: boolean;
  extraOptionsActionTooltipText?: string;
  maxWidth?: number;
  children: React.ReactNode;
};
const ExtraOptionItems: React.FC<ExtraOptionItemsProps> = ({
  label,
  extraOptionsActionTooltipText,
  isExtraDisabled,
  maxWidth,
  children
}) => {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const openPopover: React.MouseEventHandler = e => {
    setAnchorElement(e.currentTarget);
  };

  const closePopover = () => {
    setAnchorElement(null);
  };

  const isPopoverOpened = Boolean(anchorElement);
  let containerStyles;
  if (maxWidth) {
    containerStyles = {
      '--select-extra-options-width': `${maxWidth}px`
    } as React.CSSProperties;
  }

  return (
    <>
      <ListItem.ActionContainer>
        <ConditionalTooltip content={extraOptionsActionTooltipText}>
          <ListItem.Action
            disabled={isExtraDisabled}
            style={{ pointerEvents: 'all' }}
            Icon={<DSMoreIcon />}
            onClick={e => {
              e.stopPropagation();
              openPopover(e);
            }}
          />
        </ConditionalTooltip>
      </ListItem.ActionContainer>

      <Popover
        id={`${label}-extra-options-container`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          className: styles.extraOptionsContainer,
          style: containerStyles
        }}
        elevation={0}
        transitionDuration={0}
        open={isPopoverOpened}
        anchorEl={anchorElement}
        disableAutoFocus
        disableEnforceFocus
        onClose={closePopover}
      >
        {children}
      </Popover>
    </>
  );
};

export default ExtraOptionItems;
