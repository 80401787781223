import { useEffect, useRef } from 'react';
import useHyperParametersBI from './useHyperParametersBI';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import { useSelector } from 'react-redux';
import { ExperimentDetails } from '@shared/types';
import { experimentEvents } from '@/constants/trackingEventTypes';

type UseInitialHyperParametersBIOpts = {
  isNestedParamApplied: boolean;
  experiments: ExperimentDetails[];
};
const useInitialHyperParametersBI = ({
  isNestedParamApplied,
  experiments
}: UseInitialHyperParametersBIOpts) => {
  const isComparePage = useSelector(selectIsComparePage);

  const {
    sendHyperParametersTabBI,
    sendCompareHyperParametersTabBI
  } = useHyperParametersBI();
  const hasSentRef = useRef(false);

  useEffect(() => {
    if (hasSentRef.current || !isNestedParamApplied) return;

    if (isComparePage) {
      sendCompareHyperParametersTabBI(
        experiments,
        experimentEvents.EXPERIMENT_DETAIL_NESTED_PARAM_VIEWED
      );
    } else {
      sendHyperParametersTabBI(
        experiments[0],
        experimentEvents.EXPERIMENT_DETAIL_NESTED_PARAM_VIEWED
      );
    }

    hasSentRef.current = true;
  }, [
    isNestedParamApplied,
    sendHyperParametersTabBI,
    sendCompareHyperParametersTabBI,
    isComparePage,
    experiments
  ]);
};

export default useInitialHyperParametersBI;
