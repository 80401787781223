import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import noop from 'lodash/noop';
import startCase from 'lodash/startCase';

import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import GenericModal from '@shared/components/GenericModal';
import alertsUtil from '@/util/alertsUtil';
import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';

const DuplicateItemModal = ({
  entityName,
  GenericModalProps,
  modalId,
  onSave,
  originalItemName
}) => {
  const dispatch = useDispatch();
  const [newItemName, setNewItemName] = useState(`Copy of ${originalItemName}`);

  const onClose = () => {
    onSave(newItemName);
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const isBtnDisabled = () => {
    return newItemName.length === 0 || newItemName === originalItemName;
  };

  return (
    <GenericModal
      customClass="generic-modal-alt light"
      title={`Duplicate ${startCase(entityName)}`}
      onClose={onClose}
      isButtonDisabled={isBtnDisabled()}
      buttonText="Save"
      {...GenericModalProps}
    >
      <DialogContent className="generic-modal-body">
        <div className="generic-modal-group">
          <div className="generic-modal-group-item">
            <FormGroup>
              <StyledValidationTooltip
                title={`Your new ${entityName} name must be different from the current one`}
                open={newItemName === originalItemName}
              >
                <TextField
                  label={`${startCase(entityName)} name`}
                  placeholder="name"
                  value={newItemName}
                  onChange={event => setNewItemName(event.target.value)}
                  required
                  margin="normal"
                  id="name"
                />
              </StyledValidationTooltip>
            </FormGroup>
          </div>
        </div>
      </DialogContent>
    </GenericModal>
  );
};

DuplicateItemModal.defaultProps = {
  GenericModalProps: {},
  onSave: noop
};

DuplicateItemModal.propTypes = {
  entityName: PropTypes.string.isRequired,
  GenericModalProps: PropTypes.object,
  modalId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  originalItemName: PropTypes.string.isRequired
};

export default DuplicateItemModal;
