import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';
import { TextButton, Button, IconButton } from '@ds';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';

import './FullWidthBasicModal.scss';
import { DSArrowLeftIcon, DSCloseIcon } from '@ds-icons';

const StyledDialog = withStyles({
  paper: {
    fontSize: '14px',
    lineHeight: '21px',
    borderRadius: '5px'
  }
})(Dialog);

const FullWidthBasicModal = ({
  open,
  title,
  content,
  onClose,
  primaryButtonText,
  variant,
  onPrimaryButtonClick,
  secondaryButtonText,
  stepActiveIndex,
  stepAmount,
  onSecondaryButtonClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  withoutFooter,
  className,
  footer,
  withBackButton,
  onBackButtonClick,
  customHeaderContent,
  headerTitle,
  titleMaskTest,
  ...restProps
}) => {
  const isBase = variant?.startsWith('base');

  const renderSteps = () => {
    if (!stepAmount) {
      return null;
    }

    const range = new Array(stepAmount).fill(0).map((elem, index) => index);

    return (
      <div className="step-container">
        {range.map(idx => (
          <div
            className={cx('step-dot', { active: stepActiveIndex === idx })}
            key={idx}
          />
        ))}
      </div>
    );
  };

  const renderFooter = () => {
    if (footer) {
      return footer;
    }

    return (
      <>
        {secondaryButtonText && (
          <TextButton
            size="large"
            className="footer-button"
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonText}
          </TextButton>
        )}

        {primaryButtonText && (
          <Button
            size="large"
            className="footer-button"
            onClick={onPrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
            loading={isPrimaryButtonLoading}
          >
            {primaryButtonText}
          </Button>
        )}
      </>
    );
  };
  return (
    <StyledDialog
      {...restProps}
      fullScreen
      className={cx(className, 'ds-full-width-modal-wrapper')}
      open={open}
      onClose={onClose}
    >
      <div className={cx('ds-full-width-modal', variant)}>
        <div
          className={cx('full-width-modal-header', {
            'only-close-icon': !stepAmount && !(isBase && title),
            'modal-header-title': headerTitle
          })}
        >
          {!!stepAmount && (
            <div className="arrow-back-icon-container">
              {withBackButton && (
                <IconButton
                  onClick={onBackButtonClick}
                  size="XL"
                  type="secondary"
                  Icon={<DSArrowLeftIcon />}
                />
              )}
            </div>
          )}
          {isBase && (
            <div
              className="full-width-modal-title"
              data-test={`${
                restProps?.['data-test'] || 'full-width-modal'
              }-title`}
            >
              <span data-mask-test={titleMaskTest}>{title}</span>
            </div>
          )}
          {renderSteps()}
          {customHeaderContent && (
            <div className="full-width-header-custom-container">
              {customHeaderContent}
            </div>
          )}
          {headerTitle && <span data-mask-test={titleMaskTest}>{title}</span>}
          <IconButton
            onClick={onClose}
            size="XL"
            data-test="modal-close-icon"
            Icon={<DSCloseIcon />}
            type="secondary"
          />
        </div>
        {!isBase && !headerTitle && (
          <div className="full-width-modal-title">
            <span data-mask-test={titleMaskTest}>{title}</span>
          </div>
        )}
        <div className="full-width-modal-content">{content}</div>
        {!withoutFooter && (
          <div
            className={cx('full-width-modal-footer', {
              'without-end-align': Boolean(footer)
            })}
          >
            {renderFooter()}
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

FullWidthBasicModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node,
  onClose: PropTypes.func,
  primaryButtonText: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  secondaryButtonText: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  onSecondaryButtonClick: PropTypes.func,
  isPrimaryButtonDisabled: PropTypes.bool,
  isPrimaryButtonLoading: PropTypes.bool,
  withoutFooter: PropTypes.bool,
  footer: PropTypes.node,
  stepActiveIndex: PropTypes.number,
  stepAmount: PropTypes.number,
  withBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  customHeaderContent: PropTypes.object,
  headerTitle: PropTypes.bool,
  titleMaskTest: PropTypes.string
};

FullWidthBasicModal.defaultProps = {
  open: false,
  title: '',
  content: null,
  onClose: noop,
  primaryButtonText: null,
  onPrimaryButtonClick: noop,
  secondaryButtonText: null,
  onSecondaryButtonClick: noop,
  isPrimaryButtonDisabled: false,
  isPrimaryButtonLoading: false,
  withoutFooter: false,
  footer: null,
  stepAmount: 0,
  stepActiveIndex: 0,
  withBackButton: false,
  onBackButtonClick: noop,
  customHeaderContent: null,
  headerTitle: false,
  titleMaskTest: undefined
};

export default FullWidthBasicModal;
