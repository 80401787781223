import keymirror from 'keymirror';

export const singleExperimentPanelEvents = keymirror({
  SINGLE_EXPERIMENT_PAGE_PANELS_CLICKED_SEARCH_SETTINGS: null,
  SINGLE_EXPERIMENT_PAGE_PANELS_OUTLIERS: null,
  SINGLE_EXPERIMENT_PAGE_PANELS_SMOOTHING: null,
  SINGLE_EXPERIMENT_PAGE_PANEL_ADD_PANEL: null,
  SINGLE_EXPERIMENT_PAGE_PANEL_OPTIONS: null,
  SINGLE_EXPERIMENT_PAGE_PANEL_OPTIONS_DELETE: null,
  SINGLE_EXPERIMENT_PAGE_PANELS_SAVE_VIEW: null,
  SINGLE_EXPERIMENT_PAGE_PANELS_AUTO_REFRESH: null,
  SINGLE_EXPERIMENT_PAGE_PANELS_SEARCH: null,
  SINGLE_EXPERIMENT_PAGE_STOP: null,
  SINGLE_EXPERIMENT_PAGE_PANELS_LOCK: null
});

singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_X_AXIS_UPDATED =
  'SINGLE_EXPERIMENT_PAGE_PANELS_X-AXIS_UPDATED';

singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_Y_AXIS_TRANSFORMATION =
  'SINGLE_EXPERIMENT_PAGE_PANELS_Y-AXIS_TRANSFORMATION';
