import React from 'react';
import { ToggleButton } from '@ds';

import styles from './ExperimentBar.module.scss';

export type ExperimentBarToggleProps = {
  name: string;
  onChange: (value: boolean) => void;
  label: string;
  disabled?: boolean;
  defaultChecked?: boolean;
};

export const ExperimentBarToggle = ({
  name,
  onChange,
  label,
  disabled = false,
  defaultChecked = false
}: ExperimentBarToggleProps) => {
  return (
    <div className={styles.toggleContainer}>
      <div className={styles.toggleLabel}>{label}</div>
      <ToggleButton
        id={`${name}_duplicates-checkbox`}
        onChange={e => onChange(e.target.checked)}
        color="primary"
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
    </div>
  );
};

export default ExperimentBarToggle;
