// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportDescription-module__description--VvW9l{font-size:18px;margin-bottom:16px;padding:0 21px;width:100%}.ReportDescription-module__description--VvW9l.ReportDescription-module__viewOnly--UiWJm{padding:0}.ReportDescription-module__description--VvW9l .MuiFormControl-root{width:100%}.ReportDescription-module__description--VvW9l textarea{font-size:20px;font-weight:500;padding:0px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `ReportDescription-module__description--VvW9l`,
	"viewOnly": `ReportDescription-module__viewOnly--UiWJm`
};
module.exports = ___CSS_LOADER_EXPORT___;
