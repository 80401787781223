import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import EditArtifactVersionModal from './EditArtifactVersionModal';
import { IconButton, Tooltip } from '@ds';
import { DSEditIcon } from '@ds-icons';

const EditArtifactVersionModalBtn = ({
  disabled,
  versionNumber,
  ...modalProps
}) => {
  const dispatch = useDispatch();
  const {
    workspace,
    artifactName: artifactNameEncoded,
    projectName,
    artifactVersionNumber
  } = useParams();
  const artifactName = decodeURIComponent(artifactNameEncoded);

  const modalId = dialogTypes.EDIT_ARTIFACT_VERSION_MODAL;
  const modal = (
    <EditArtifactVersionModal
      modalId={modalId}
      dispatch={dispatch}
      workspaceName={workspace}
      projectName={projectName}
      artifactName={artifactName}
      artifactVersionNumber={versionNumber || artifactVersionNumber}
      {...modalProps}
    />
  );

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(alertsUtil.openCustomModal(modalId, modal));
  };

  return (
    <Tooltip content="Edit version">
      <IconButton
        data-test="edit-artifact-version"
        Icon={<DSEditIcon />}
        type="secondary"
        disabled={disabled}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

EditArtifactVersionModalBtn.defaultProps = {
  disabled: false,
  versionNumber: ''
};

EditArtifactVersionModalBtn.propTypes = {
  disabled: PropTypes.bool,
  versionNumber: PropTypes.string
};

export default EditArtifactVersionModalBtn;
