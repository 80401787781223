import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import Switch from '@material-ui/core/Switch';
import { MODEL_SCOPE_TYPES } from '../../../../constants/modelRegistryConstants';
import classNames from './ManageModelModal.module.scss';

const { PUBLIC, PRIVATE } = MODEL_SCOPE_TYPES;

const renderSwitchLabel = (scopeType, labelScopeType) => (
  <label
    className={cx('scope-switch-label', {
      active: scopeType === labelScopeType
    })}
    style={{ fontSize: '13px' }}
  >
    {labelScopeType}
  </label>
);

const ManageModelVisibilityToggle = ({
  DISABLE_PUBLIC_ACCESS,
  scopeType,
  handleSwitchChange
}) => {
  if (DISABLE_PUBLIC_ACCESS) return null;

  return (
    <div className="generic-modal-section">
      <div>
        {renderSwitchLabel(scopeType, PUBLIC)}
        <Switch
          classes={{
            root: classNames.manageModalToggle
          }}
          checked={scopeType === PRIVATE}
          onChange={handleSwitchChange}
          color="primary"
        />
        {renderSwitchLabel(scopeType, PRIVATE)}
      </div>
    </div>
  );
};

ManageModelVisibilityToggle.propTypes = {
  DISABLE_PUBLIC_ACCESS: PropTypes.bool.isRequired,
  scopeType: PropTypes.string.isRequired,
  handleSwitchChange: PropTypes.func.isRequired
};

export default ManageModelVisibilityToggle;
