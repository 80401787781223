import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isNull from 'lodash/isNull';
import get from 'lodash/get';

import { Button, TextButton } from '@ds';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import GenericModal from '@shared/components/GenericModal';

import alertsUtil from '@/util/alertsUtil';

const IMPORT_INPUT_ID = 'import-file-input';

const ImportFileModal = ({
  title,
  acceptedFileTypes,
  dispatch,
  modalId,
  submitHandler
}) => {
  const [importedFile, setImportedFile] = useState(null);

  const element = document.getElementById(IMPORT_INPUT_ID);
  const file = get(element, ['files', 0], null);

  const handleSubmit = () => {
    submitHandler(file);
    handleClose();
  };

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title={title}
      onClose={() => {}}
      onCancel={handleClose}
      hideFooter
      isButtonDisabled
    >
      <DialogContent className="generic-modal-body">
        <div className="generic-modal-section row">
          <input
            id={IMPORT_INPUT_ID}
            type="file"
            className="modal-input-base"
            onChange={e => setImportedFile(e.target.value)}
            style={{ display: 'none' }}
            accept={acceptedFileTypes.join(',')}
          />
          <Button size="large" className="choose-file-button">
            <label htmlFor={IMPORT_INPUT_ID} className="choose-file-label">
              <span>Choose File</span>
            </label>
          </Button>

          {file && <div>{file.name}</div>}
        </div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton onClick={handleClose}>Cancel</TextButton>
        <Button onClick={handleSubmit} disabled={isNull(importedFile)}>
          Import
        </Button>
      </DialogActions>
    </GenericModal>
  );
};
ImportFileModal.defaultProps = {
  acceptedFileTypes: ['.zip'],
  title: 'Import File'
};

ImportFileModal.propTypes = {
  acceptedFileTypes: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  submitHandler: PropTypes.func.isRequired
};

export default connect()(ImportFileModal);
