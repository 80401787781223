// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenuProfile-module__userMenuProfile--C5gRB{margin:6px;display:flex;align-items:center;gap:12px;border-radius:5px;background:#f5f7fa;padding:10px 12px;max-width:100%}.UserMenuProfile-module__userMenuProfileSkeleton--vp32o{margin:6px;height:58px;border-radius:5px;opacity:.4}.UserMenuProfile-module__userMenuProfileInfo--kL9cK{min-width:0}.UserMenuProfile-module__userMenuProfileUsername--ocw4h{font-weight:700;font-size:14px;color:#191a1c;line-height:20px;display:flex;min-height:20px;cursor:default}.UserMenuProfile-module__userMenuProfileCurrentPlan--l01nf{font-size:12px;color:#8c95a8;line-height:18px;cursor:default}.UserMenuProfile-module__userMenuProfileGuestInfo--GvM1G{display:inline-flex;align-items:center;gap:4px}.UserMenuProfile-module__userMenuProfileGuestInfo--GvM1G svg{cursor:pointer}.UserMenuProfile-module__userMenuProfileGuestInfo--GvM1G svg:hover{color:#5155f5}.UserMenuProfile-module__userMenuProfileAvatar--iE8wb{min-width:32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenuProfile": `UserMenuProfile-module__userMenuProfile--C5gRB`,
	"userMenuProfileSkeleton": `UserMenuProfile-module__userMenuProfileSkeleton--vp32o`,
	"userMenuProfileInfo": `UserMenuProfile-module__userMenuProfileInfo--kL9cK`,
	"userMenuProfileUsername": `UserMenuProfile-module__userMenuProfileUsername--ocw4h`,
	"userMenuProfileCurrentPlan": `UserMenuProfile-module__userMenuProfileCurrentPlan--l01nf`,
	"userMenuProfileGuestInfo": `UserMenuProfile-module__userMenuProfileGuestInfo--GvM1G`,
	"userMenuProfileAvatar": `UserMenuProfile-module__userMenuProfileAvatar--iE8wb`
};
module.exports = ___CSS_LOADER_EXPORT___;
