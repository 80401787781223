import { useMemo } from 'react';
import { union } from 'lodash';
import { SystemMetrics } from '@shared/types/systemMetrics';

type UseSystemMetricsMetaReturn = Pick<
  SystemMetrics,
  'systemMetricNames' | 'totalRam' | 'maxTotalMemory'
>;
const useSystemMetricsMeta = (
  systemMetrics: SystemMetrics[]
): UseSystemMetricsMetaReturn => {
  return useMemo(() => {
    return systemMetrics.reduce(
      (acc, data) => {
        acc.totalRam = Math.max(acc.totalRam, data.totalRam || 0);
        acc.maxTotalMemory = Math.max(
          acc.maxTotalMemory,
          data.maxTotalMemory || 0
        );
        acc.systemMetricNames = union(
          acc.systemMetricNames,
          data.systemMetricNames || []
        );

        return acc;
      },
      {
        totalRam: 0,
        maxTotalMemory: 0,
        systemMetricNames: [] as string[]
      }
    );
  }, [systemMetrics]);
};

export default useSystemMetricsMeta;
