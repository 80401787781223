import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useProject from '@API/project/useProject';
import useProjectParams from '@API/project/useProjectParams';
import { ReportData, SavedReport } from '@reports/types';
import { reportRoute } from '@routes/utils/reports';
import api from '@shared/api';

import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const upsertReport = async ({
  data,
  description,
  displayName,
  projectId,
  reportId,
  reportName
}: {
  data: ReportData;
  description: string;
  displayName: string;
  projectId: string;
  reportId?: string | null;
  reportName: string;
}) => {
  const { data: savedReport } = await api.post<SavedReport>('reports/upsert', {
    data,
    description,
    displayName,
    projectId,
    reportId,
    reportName
  });

  return savedReport;
};

interface UpsertReportParams {
  data: ReportData;
  description: string;
  displayName: string;
  editMode: string;
  reportId?: string | null;
  reportName: string;
}

export default function useUpsertReportMutation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: project } = useProject();
  const { workspace, projectName } = useProjectParams();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({
      data,
      description,
      displayName,
      reportId,
      reportName
    }: UpsertReportParams) => {
      return upsertReport({
        data,
        description,
        displayName,
        projectId,
        reportId,
        reportName
      });
    },
    {
      onMutate: async ({ reportId }) => {
        await queryClient.cancelQueries(['reportRevisions', { reportId }]);
      },
      onSuccess: async ({ reportName }, { editMode, reportId }) => {
        await queryClient.invalidateQueries(['reportRevisions', { reportId }]);
        await queryClient.invalidateQueries([
          'report',
          { projectName, reportName, workspace }
        ]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_SAVE_REPORT,
            'Successfully saved report.'
          )
        );

        if (!projectName || !workspace) return;

        const url = reportRoute({
          editMode,
          projectName,
          reportName,
          workspaceName: workspace
        });

        history.push(url);
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error creating or updating your report'
          )
        );
      }
    }
  );
}
