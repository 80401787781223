import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SmallLoader from '@shared/components/SmallLoader';
import { DateRange, RadioSelector, Select } from '@DesignSystem/controllers';
import { chartColors } from '@design-system-outdated/constants';
import { useFeatureValues, useFeatures, useSegments } from '@mpm/api';
import {
  INTERVAL_TYPE_OPTIONS,
  MODEL_TYPES,
  MPM_CHART_DISABLED_ZOOM,
  PANEL_NAMES,
  PERFORMANCE_DATE_RANGES,
  MODEL_FEATURES_TYPES,
  PANEL_SECTIONS,
  INTERVAL_TYPE_VALUES
} from '@mpm/constants';
import { useMPMPerformanceParams } from '@mpm/hooks';
import { mapModelVersions } from '@mpm/utils';
import { PanelContainer } from '../PanelContainer';
import styles from './FairnessPage.module.scss';
import { GetStartedFairness } from './GetStartedFairness';
import { ModelType } from '@mpm/types';

type GroupSegment = {
  color: string;
  id: string;
  segmentValue: string;
};

type FairnessPageProps = {
  model: ModelType;
  activeSegmentGroupColorIds: GroupSegment[];
  setActiveSegmentGroupColorIds: (vals: GroupSegment[]) => void;
};

type IntervalValueType = typeof INTERVAL_TYPE_VALUES;

type VersionOption = {
  label: string;
  value: string | null;
};

const defaultVersions: VersionOption[] = [
  {
    label: 'All versions',
    value: null as string | null
  }
];

const chartColorsArr = Object.values(chartColors);

export const FairnessPage = ({
  model,
  activeSegmentGroupColorIds,
  setActiveSegmentGroupColorIds
}: FairnessPageProps) => {
  const versions = defaultVersions.concat(mapModelVersions(model.versions));

  const [positiveOutcome, setPositiveOutcome] = useState('');
  const [protectedAttribute, setProtectedAttribute] = useState('');
  const [referenceGroup, setReferenceGroup] = useState('');
  const [sensitiveGroup, setSensitiveGroup] = useState('');

  const [segmentsValuesOptions, setSegmentsValuesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [showCharts, setShowCharts] = useState(false);
  const [isFairnessSupported, setIsFairnessSupported] = useState(
    model?.modelType === MODEL_TYPES.CLASSIFICATION
  );

  const {
    version,
    onUpdateVersion,

    from,
    to,
    intervalType,
    dateRange,
    setStartDate,
    setEndDate,
    calcMaxDate,
    setDateRange,
    onUpdateIntervalType,
    onChangeRange,
    handledCalendarStartDate,
    handledCalendarEndDate
  } = useMPMPerformanceParams(versions);

  const { data: featuresList, isLoading: featuresListLoading } = useFeatures(
    {
      modelId: model.id,
      page: 1,
      pageSize: 1000
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const valueFeatureId = useMemo(() => {
    if (featuresList && featuresList.features.length > 0) {
      const featureValueId = featuresList.features.find(
        feature =>
          feature.name === 'value' &&
          feature.source === MODEL_FEATURES_TYPES.OUTPUT_FEATURES
      )?.id;
      if (!featureValueId) setIsFairnessSupported(false);
      return featureValueId;
    }
    return null;
  }, [featuresList]);

  const {
    data: featureValues,
    isLoading: featureValuesLoading
  } = useFeatureValues(
    {
      modelId: model.id,
      featureId: valueFeatureId
    },
    {
      keepPreviousData: true,
      refetchOnMount: true,
      enabled: isFairnessSupported
    }
  );

  const { isLoading: segmentsLoading, data: segmentsData } = useSegments(
    {
      modelId: model.id
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const loadingState =
    featuresListLoading || featureValuesLoading || segmentsLoading;

  const areSegmentsDefined =
    (segmentsData && segmentsData?.segmentDefinitions?.length > 0) || false;

  useEffect(() => {
    if (protectedAttribute && segmentsData) {
      const selectedSegment = segmentsData.segmentDefinitions.find(
        segment => segment.name === protectedAttribute
      );

      if (!selectedSegment) {
        setActiveSegmentGroupColorIds([]);
        setSegmentsValuesOptions([]);
        return;
      }

      const segmentFeaturesOptions = selectedSegment.segments.map(item => {
        return {
          label: item.segmentValue,
          value: item.segmentValue
        };
      });

      const segmentColoIds = selectedSegment.segments.map(item => {
        return {
          id: item.id,
          segmentValue: item.segmentValue,
          color: '#5899DA'
        };
      });
      setActiveSegmentGroupColorIds(segmentColoIds);
      setSegmentsValuesOptions(segmentFeaturesOptions);
    }
  }, [segmentsData, protectedAttribute]);

  const featureValuesOptions = useMemo(() => {
    if (featureValues) {
      const { values } = featureValues;
      return values.map(feature => {
        return { label: feature, value: feature };
      });
    }
    return [];
  }, [featureValues]);

  const protectedAttributeHandler = useCallback(value => {
    setProtectedAttribute(value);
  }, []);

  const segmentsOptions = useMemo(() => {
    if (segmentsData?.segmentDefinitions) {
      return segmentsData?.segmentDefinitions
        .filter(segment => {
          const { key, type } = segment.segmentRule.feature;
          return !(
            key === 'value' && type === MODEL_FEATURES_TYPES.OUTPUT_FEATURES
          );
        })
        .map(segment => {
          return {
            label: segment.name,
            value: segment.name
          };
        });
    }
    return [];
  }, [segmentsData]);

  const setReferenceGroupHandler = (
    value: string,
    isFromGettingStarted: boolean
  ) => {
    setReferenceGroup(value);
    if (isFromGettingStarted) {
      const firstInList = segmentsValuesOptions[0].value;
      const sensitiveValue =
        segmentsValuesOptions.length === 1 || firstInList !== value
          ? firstInList
          : segmentsValuesOptions[1].value;
      setSensitiveGroup(sensitiveValue);
    }
  };

  if (loadingState)
    return (
      <div className={styles.fairnessAvailableWrapper}>
        <SmallLoader isFlexDisplay />
      </div>
    );

  if (!showCharts) {
    return (
      <div className={styles.fairnessAvailableWrapper}>
        <GetStartedFairness
          isFairnessSupported={isFairnessSupported}
          areSegmentsDefined={areSegmentsDefined}
          positiveOutcome={positiveOutcome}
          setPositiveOutcome={setPositiveOutcome}
          protectedAttribute={protectedAttribute}
          setProtectedAttribute={protectedAttributeHandler}
          referenceGroup={referenceGroup}
          setReferenceGroup={setReferenceGroupHandler}
          setShowCharts={setShowCharts}
          positiveOutcomeOptions={featureValuesOptions}
          protectedAttributeOptions={segmentsOptions}
          referenceGroupOptions={segmentsValuesOptions}
        />
      </div>
    );
  }

  return (
    <div className={styles.fairnessTab}>
      <div className={styles.fairnessTabPanels}>
        <div className={styles.fairnessTabHeader}>
          <span className={styles.fairnessTabHeaderLabel}>
            Positive Outcome:{' '}
            <Select
              onChange={setPositiveOutcome}
              options={featureValuesOptions}
              value={positiveOutcome}
            />
          </span>
          <span className={styles.fairnessTabHeaderLabel}>
            Protected Attribute:{' '}
            <Select
              onChange={protectedAttributeHandler}
              options={segmentsOptions}
              value={protectedAttribute}
              disabled={segmentsOptions.length === 0}
            />
          </span>
          <span className={styles.fairnessTabHeaderLabel}>
            Reference Group:
            <Select
              onChange={setReferenceGroup}
              options={segmentsValuesOptions}
              value={referenceGroup}
              disabled={!protectedAttribute || !positiveOutcome}
            />
          </span>
          <div className={styles.fairnessTabHeaderDivider} />
          <Select
            onChange={onUpdateVersion}
            options={versions}
            value={version}
            withInput
          />
          <Select
            onChange={onUpdateIntervalType}
            options={INTERVAL_TYPE_OPTIONS}
            value={intervalType}
          />
          <div className={styles.fairnessTabHeaderDateRanges}>
            {PERFORMANCE_DATE_RANGES[
              intervalType as IntervalValueType[keyof typeof INTERVAL_TYPE_VALUES]
            ]?.map((days: number) => (
              <RadioSelector
                isSelected={days === dateRange}
                key={`date-range-${days}`}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setDateRange(days);
                  setEndDate(null);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setStartDate(new Date());
                }}
                text={`${days}d`}
              />
            ))}
          </div>
          <DateRange
            startDate={handledCalendarStartDate}
            endDate={handledCalendarEndDate}
            onChange={onChangeRange}
            calcMaxDate={calcMaxDate}
          />
        </div>

        <div className={styles.fairnessTabContent}>
          <PanelContainer
            title={PANEL_NAMES.DISPARATE_IMPACT_TIME}
            from={from}
            intervalType={intervalType}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            section={PANEL_SECTIONS.DISTRIBUTION_PER_TIME}
            isSegmentsData
            segmentColorIds={activeSegmentGroupColorIds}
            propFeatureId={valueFeatureId}
            fairnessData={{
              filterFeature: positiveOutcome,
              referenceGroup,
              setReferenceGroup: setReferenceGroupHandler,
              segmentsValuesOptions,
              sensitiveGroup,
              setSensitiveGroup,
              showCharts
            }}
            // TODO: Remove props below after the PanelContainer Component migration to typescript
            badgeLabels={undefined}
            tabs={undefined}
            isLabeled={undefined}
            feature={undefined}
          />
          <PanelContainer
            title={PANEL_NAMES.DISPARATE_IMPACT_GROUP}
            from={from}
            intervalType={intervalType}
            to={to}
            version={version}
            colors={chartColorsArr}
            disableZoom={MPM_CHART_DISABLED_ZOOM}
            section={PANEL_SECTIONS.DISTRIBUTION_PER_GROUP}
            isSegmentsData
            segmentColorIds={activeSegmentGroupColorIds}
            propFeatureId={valueFeatureId}
            fairnessData={{
              filterFeature: positiveOutcome,
              referenceGroup,
              setReferenceGroup: setReferenceGroupHandler,
              segmentsValuesOptions,
              sensitiveGroup,
              setSensitiveGroup,
              showCharts
            }}
            // TODO: Remove props below after the PanelContainer Component migration to typescript
            badgeLabels={undefined}
            tabs={undefined}
            isLabeled={undefined}
            feature={undefined}
          />
        </div>
      </div>
    </div>
  );
};
