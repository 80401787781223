import useCurrentUser from '@API/auth/useCurrentUser';
import { useIsOnOnboardingPage } from '@onboarding/hooks';
import React from 'react';
import DetectedExperiment from './DetectedExperiment';

// used for showing / hiding detected experiment banner from the perspective of routes
const DetectedExperimentRouterWrapper = () => {
  const { data: currentUser, isLoading: isLoadingUser } = useCurrentUser();

  const areNeededRoutes = useIsOnOnboardingPage();

  if (isLoadingUser || !currentUser?.loggedIn || !areNeededRoutes) {
    return null;
  }

  return <DetectedExperiment />;
};

export default DetectedExperimentRouterWrapper;
