import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { PARSE_OPTIONS } from '@shared/constants/url';

import chartActions from '@/actions/experimentDetails/chartActionsPlotly';
import { getSelectedProjectId } from '@/reducers/ui/projectsUiReducer';

const useExperimentDetailViewInitialization = ({
  isLoadingProjectTemplate,
  isLoadingUserTemplates,
  isUserLoggedIn,
  userTemplates,
  experimentKey
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [viewIsInitialized, setViewIsInitialized] = useState(false);
  const projectId = useSelector(getSelectedProjectId);

  const isLoading =
    isLoadingProjectTemplate || (isLoadingUserTemplates && isUserLoggedIn);
  const { viewId: initialQueryViewId } = queryString.parse(
    location.search,
    PARSE_OPTIONS
  );
  const initialQueryViewIdRef = useRef(initialQueryViewId);
  useEffect(() => {
    if (isLoading || !initialQueryViewIdRef.current || viewIsInitialized)
      return;

    const template = userTemplates.find(
      temp => temp.template_id === initialQueryViewIdRef.current
    );

    if (template) {
      setViewIsInitialized(true);
      dispatch(
        chartActions.setCurrentTemplateAndMigrateCharts(template, experimentKey)
      );
    }
  }, [dispatch, experimentKey, isLoading, userTemplates, viewIsInitialized]);

  useEffect(() => {
    // projectId can be empty in case we do move project
    if (isEmpty(projectId)) return;
    dispatch(chartActions.fetchProjectTemplate(projectId, experimentKey));
  }, [dispatch, experimentKey, projectId]);

  useEffect(() => {
    // projectId can be empty in case we do move project
    if (!isUserLoggedIn || isEmpty(projectId)) return;

    dispatch(chartActions.fetchUserTemplates(projectId, experimentKey));
  }, [dispatch, experimentKey, isUserLoggedIn, projectId]);
};

export default useExperimentDetailViewInitialization;
