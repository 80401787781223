import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const getServiceAccounts = async organizationId => {
  const resp = await organizationApi.fetchServiceAccounts(organizationId);

  return resp?.data || [];
};

const useServiceAccounts = organizationId => {
  const dispatch = useDispatch();
  return useQuery(
    ['organizations', organizationId, 'service-accounts'],
    () => getServiceAccounts(organizationId),
    {
      onError: err => {
        const msg =
          err?.response?.data?.msg ||
          'Error while trying to get the list of service accounts';

        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.SERVICE_ACCOUNT_LIST_FAILED,
            msg
          )
        );
      }
    }
  );
};

export default useServiceAccounts;
