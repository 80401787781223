import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DashboardButton from '@experiment-management-shared/components/DashboardButton/index';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';

import CustomizeColumnsModalContainer from '@experiment-management-shared/components/ColumnsModal/CustomizeColumnsContainer';

const modalId = dialogTypes.CUSTOMIZE_COLUMNS_MODAL;

const columnsIcon = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999999 12C0.999999 12.2761 1.22386 12.5 1.5 12.5C1.77614 12.5 2 12.2761 2 12L2 4C2 3.72386 1.77614 3.5 1.5 3.5C1.22386 3.5 0.999997 3.72386 0.999997 4L0.999999 12Z"
      fill="currentColor"
    />
    <path
      d="M6.57143 4C6.57143 3.72386 6.34757 3.5 6.07143 3.5C5.79529 3.5 5.57143 3.72386 5.57143 4L5.57143 12C5.57143 12.2761 5.79529 12.5 6.07143 12.5C6.34757 12.5 6.57143 12.2761 6.57143 12L6.57143 4Z"
      fill="currentColor"
    />
    <path
      d="M11 4C11 3.72386 10.7761 3.5 10.5 3.5C10.2239 3.5 10 3.72386 10 4L10 12C10 12.2761 10.2239 12.5 10.5 12.5C10.7761 12.5 11 12.2761 11 12L11 4Z"
      fill="currentColor"
    />
    <path
      d="M11.5 1.5C11.7761 1.5 12 1.27614 12 1C12 0.723858 11.7761 0.5 11.5 0.5L0.5 0.5C0.223857 0.5 3.29296e-09 0.723858 0 1C-3.29296e-09 1.27614 0.223858 1.5 0.5 1.5L11.5 1.5Z"
      fill="currentColor"
    />
  </svg>
);

const ColumnsButton = ({
  disabled,
  onChange,
  selectedColumnNames,
  columnsCountCorrection,
  defaultColumns,
  showButtonLabel,
  columnsNameMap
}) => {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);

  const closeCustomizeColumnsModal = () => {
    dispatch(alertsUtil.closeDialog(modalId));
    setIsOpened(false);
  };
  const handleUpdateColumns = data => {
    onChange(data);
    closeCustomizeColumnsModal();
  };

  const handleClick = () => {
    const customizeColumnsModal = (
      <CustomizeColumnsModalContainer
        onUpdateColumns={handleUpdateColumns}
        selectedColumnNames={selectedColumnNames}
        defaultColumns={defaultColumns}
        columnsNameMap={columnsNameMap}
        onClose={closeCustomizeColumnsModal}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(modalId, customizeColumnsModal, {
        onClose: () => setIsOpened(false)
      })
    );

    setIsOpened(true);
  };

  const countLabel = selectedColumnNames.length
    ? `${selectedColumnNames.length + columnsCountCorrection}`
    : '';
  const label = showButtonLabel ? `Columns` : '';
  const tooltipTitle = disabled
    ? 'Columns can be edited in the Experiments table view'
    : 'Customize visible table columns';

  return (
    <DashboardButton
      tooltipTitle={tooltipTitle}
      disabled={disabled}
      isOpened={isOpened}
      label={label}
      subLabel={countLabel}
      onClick={handleClick}
      icon={columnsIcon}
    />
  );
};

ColumnsButton.defaultProps = {
  // The experiment name has 4 columns in 1
  columnsCountCorrection: -3,
  disabled: false,
  selectedColumnNames: [],
  defaultColumns: undefined,
  columnsNameMap: {}
};

ColumnsButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  columnsCountCorrection: PropTypes.number,
  selectedColumnNames: PropTypes.array,
  showButtonLabel: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  columnsNameMap: PropTypes.object
};

export default ColumnsButton;
