import { useMutation, useQueryClient } from 'react-query';
import api from '@shared/api';
import { AxiosError } from 'axios';

type UseExperimentNoteMutationParams = {
  experimentKey: string;
  note: string;
};

const mutateExperimentNote = async ({
  experimentKey,
  note
}: UseExperimentNoteMutationParams) => {
  await api.post('notes/experiment/update', {
    experimentKey,
    notes_v2: note
  });
};

export default function useExperimentNoteMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, UseExperimentNoteMutationParams>(
    ({ experimentKey, note }) => {
      return mutateExperimentNote({
        experimentKey,
        note
      });
    },
    {
      onMutate: async ({ experimentKey, note }) => {
        const queryKey = ['experiment-note', { experimentKey }];
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData(queryKey, () => note);
      },
      onSuccess: async (_, { experimentKey }) => {
        await queryClient.invalidateQueries([
          'experiment-note',
          { experimentKey }
        ]);
      }
    }
  );
}
