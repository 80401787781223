import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DashboardButton from '@experiment-management-shared/components/DashboardButton/index';
import SortPopover from './SortPopover';

const sortIcon = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.04163C0.723858 1.04163 0.5 1.26549 0.5 1.54163C0.5 1.81777 0.723858 2.04163 1 2.04163L11 2.04163C11.2761 2.04163 11.5 1.81777 11.5 1.54163C11.5 1.26548 11.2761 1.04163 11 1.04163L1 1.04163Z"
      fill="currentColor"
    />
    <path
      d="M5 5.49996C5.27614 5.49996 5.5 5.2761 5.5 4.99996C5.5 4.72382 5.27614 4.49996 5 4.49996L1 4.49996C0.723858 4.49996 0.5 4.72382 0.5 4.99996C0.5 5.2761 0.723858 5.49996 1 5.49996L5 5.49996Z"
      fill="currentColor"
    />
    <path
      d="M3 8.99996C3.27614 8.99996 3.5 8.7761 3.5 8.49996C3.5 8.22382 3.27614 7.99996 3 7.99996H1C0.723858 7.99996 0.5 8.22382 0.5 8.49996C0.5 8.7761 0.723858 8.99996 1 8.99996H3Z"
      fill="currentColor"
    />
    <path
      d="M5.35355 8.14641C5.15829 7.95115 4.84171 7.95115 4.64645 8.14641C4.45118 8.34168 4.45118 8.65826 4.64645 8.85352L7.64645 11.8535C7.84171 12.0488 8.15829 12.0488 8.35355 11.8535L11.3536 8.85352C11.5488 8.65826 11.5488 8.34168 11.3536 8.14641C11.1583 7.95115 10.8417 7.95115 10.6464 8.14641L8.5 10.2929L8.5 4.99997C8.5 4.72383 8.27614 4.49997 8 4.49997C7.72386 4.49997 7.5 4.72383 7.5 4.99997L7.5 10.2929L5.35355 8.14641Z"
      fill="currentColor"
    />
  </svg>
);

const SortButton = ({ columnSorting, onChange, showButtonLabel, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const countLabel = columnSorting.length ? `${columnSorting.length}` : '';
  const label = showButtonLabel ? 'Sort' : '';
  const tooltipTitle = disabled
    ? 'Sorting is not available'
    : 'Sort experiments';

  return (
    <>
      <DashboardButton
        tooltipTitle={tooltipTitle}
        disabled={disabled}
        isOpened={Boolean(anchorEl)}
        label={label}
        subLabel={countLabel}
        onClick={handleOpen}
        ref={anchorEl}
        icon={sortIcon}
      />
      <SortPopover
        anchorEl={anchorEl}
        columnSorting={columnSorting}
        onChange={onChange}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </>
  );
};

SortButton.defaultProps = {
  disabled: false
};

SortButton.propTypes = {
  columnSorting: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  showButtonLabel: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

export default SortButton;
