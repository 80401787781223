import React from 'react';
import PropTypes from 'prop-types';

import { COMPARE_TAB } from '@experiment-details/constants/code';
import StyledTabs from '@shared/components/StyledComponents/StyledTabs';
import StyledTab from '@shared/components/StyledComponents/StyledTab';

const BaseCodeCompareTabBar = ({
  experimentName1,
  experimentName2,
  isDiff,
  selectedTab,
  tabSelectHandler
}) => (
  <div className="diff-header-row">
    <StyledTabs
      className="compare-tabs"
      value={selectedTab}
      onChange={tabSelectHandler}
    >
      <StyledTab
        value={COMPARE_TAB.EXPERIMENT_1}
        label={
          <span className="compare-tab">
            <i className="material-icons">flash_on</i>
            <span className="text-overflow">{experimentName1}</span>
          </span>
        }
      />
      <StyledTab
        value={COMPARE_TAB.EXPERIMENT_2}
        label={
          <span className="compare-tab text-overflow">
            <i className="material-icons">flash_on</i>
            <span className="text-overflow">{experimentName2}</span>
          </span>
        }
      />
      <StyledTab
        disabled={!isDiff}
        value={COMPARE_TAB.SPLIT}
        label={
          <span className="compare-tab">
            <i className="material-icons">call_split</i>Split Diff
          </span>
        }
      />
      <StyledTab
        disabled={!isDiff}
        value={COMPARE_TAB.UNIFIED}
        label={
          <span className="compare-tab">
            <i className="material-icons">call_merge</i>Unified Diff
          </span>
        }
      />
    </StyledTabs>
  </div>
);

BaseCodeCompareTabBar.propTypes = {
  experimentName1: PropTypes.string.isRequired,
  experimentName2: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
  tabSelectHandler: PropTypes.func.isRequired,
  isDiff: PropTypes.bool.isRequired
};

export default BaseCodeCompareTabBar;
