import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { Box } from '@material-ui/core';
import Snackbar from '@design-system-outdated/components/Snackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CopyIcon, SuccessCopiedIcon } from '@Icons-outdated';
import classNames from './CopyButton.module.scss';

export const CopyButton = ({
  text,
  message,
  tooltipText,
  successIconTimeout,
  icon: ParentIcon
}) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);

  useEffect(() => {
    let timer;
    if (showSuccessIcon) {
      timer = setTimeout(() => setShowSuccessIcon(false), successIconTimeout);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showSuccessIcon, successIconTimeout]);

  const copyHandlerClick = () => {
    setIsSnackbarOpen(true);
    setShowSuccessIcon(true);
  };

  const renderIcon = () => {
    const IconComponent = ParentIcon || CopyIcon;
    const copyButton = (
      <div className="code-button-container">
        <CopyToClipboard text={text}>
          <IconComponent onClick={copyHandlerClick} />
        </CopyToClipboard>
      </div>
    );

    if (showSuccessIcon && !ParentIcon) {
      return <SuccessCopiedIcon />;
    }

    if (tooltipText) {
      return (
        <Tooltip arrow title={tooltipText} placement="top">
          {copyButton}
        </Tooltip>
      );
    }

    return copyButton;
  };

  return (
    <>
      {message && (
        <Snackbar
          onClose={() => setIsSnackbarOpen(false)}
          open={isSnackbarOpen}
          message={message}
        />
      )}
      <Box className={cx(classNames.copyIconWrapper, 'copyIconWrapper')}>
        {renderIcon()}
      </Box>
    </>
  );
};

CopyButton.defaultProps = {
  message: '',
  tooltipText: 'Copy',
  successIconTimeout: 3000,
  icon: null
};

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.string,
  tooltipText: PropTypes.string,
  successIconTimeout: PropTypes.number,
  icon: PropTypes.element
};
