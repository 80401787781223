import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import classNames from './RegisterModel.module.scss';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import RedirectLink from '@shared/components/RedirectLink';

const RegisterModelFilesViewBlock = ({
  modelName,
  version,
  createdAt,
  registryModelItemId
}) => {
  return (
    <Box
      className={classNames.registerModelFilesViewBlock}
      data-test={`streamline-model-sidebar-registry-records-item-${version}`}
    >
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Box className={classNames.registerModelFilesViewBlockLabel}>
                Name:
              </Box>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography
                noWrap
                className={classNames.registerModelFilesViewBlockText}
              >
                <RedirectLink
                  data-test={`streamline-model-sidebar-registry-records-item-${version}-name`}
                  resource={REDIRECT_LINK_RESOURCES.MODEL_REGISTRY}
                  args={[registryModelItemId]}
                >
                  {modelName}
                </RedirectLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Box className={classNames.registerModelFilesViewBlockLabel}>
                Version:
              </Box>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography
                noWrap
                className={classNames.registerModelFilesViewBlockText}
              >
                {version}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Box className={classNames.registerModelFilesViewBlockLabel}>
                Date created:
              </Box>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography
                noWrap
                className={classNames.registerModelFilesViewBlockText}
              >
                {createdAt}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

RegisterModelFilesViewBlock.defaultProps = {
  registryModelItemId: '',
  modelName: '',
  workspace: '',
  version: '',
  createdAt: ''
};

RegisterModelFilesViewBlock.propTypes = {
  registryModelItemId: PropTypes.string,
  modelName: PropTypes.string,
  workspace: PropTypes.string,
  version: PropTypes.string,
  createdAt: PropTypes.string
};

export default RegisterModelFilesViewBlock;
