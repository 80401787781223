import React from 'react';
import PropTypes from 'prop-types';

import { BasicModal } from '@DesignSystem/modals';

const ModelStatusChangeConfirmation = ({
  isPrimaryButtonDisabled,
  onPrimaryButtonClick,
  onClose,
  additionalClasses
}) => {
  return (
    <BasicModal
      open
      additionalClasses={additionalClasses}
      onClose={onClose}
      title="Are You Sure?"
      dataTest="model-status-change-confirmation"
      primaryButtonText="Yes, change the status"
      secondaryButtonText="No, I will do it at another time"
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      content={
        <div>
          Changing model status will automatically reject all other pending
          requests for that model version
        </div>
      }
    />
  );
};

ModelStatusChangeConfirmation.defaultProps = {
  additionalClasses: null
};

ModelStatusChangeConfirmation.propTypes = {
  additionalClasses: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  isPrimaryButtonDisabled: PropTypes.bool.isRequired
};

export default ModelStatusChangeConfirmation;
