import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TextButton, IconButton } from '@ds';

import classNames from './DebuggerPage.module.scss';
import { DEBUGGER_GROUP_BUTTONS, STREAM_ACTIONS } from '../../constants';
import { DSPauseIcon, DSPlayIcon } from '@ds-icons';
import { ActiveDebuggerButtonType, ActiveStreamActionType } from '../../types';

type DebuggerActionsPanelProps = {
  activeButton: ActiveDebuggerButtonType;
  setActiveButton: (arg0: ActiveDebuggerButtonType) => void;
  activeAction: ActiveStreamActionType;
  setActiveAction: (arg0: ActiveStreamActionType) => void;
};

export const DebuggerActionsPanel = ({
  activeButton,
  setActiveButton,
  activeAction,
  setActiveAction
}: DebuggerActionsPanelProps) => {
  const isActiveErrors = activeButton === DEBUGGER_GROUP_BUTTONS.INGESTIONS;
  const isActiveLabels = activeButton === DEBUGGER_GROUP_BUTTONS.LABELS;
  const isActivePredictions =
    activeButton === DEBUGGER_GROUP_BUTTONS.PREDICTIONS;

  return (
    <div className={classNames.debuggerActionsPannelWrapper}>
      <div className={classNames.debuggerActionsPannel}>
        <span>Show:</span>
        <div className={classNames.debuggerActionButtonsWrapper}>
          <TextButton
            type="secondary"
            onClick={() => setActiveButton(DEBUGGER_GROUP_BUTTONS.PREDICTIONS)}
            className={cx({
              [classNames.debuggerPredictionsButton]: isActivePredictions
            })}
          >
            Predictions
          </TextButton>
          <TextButton
            type="secondary"
            onClick={() => setActiveButton(DEBUGGER_GROUP_BUTTONS.LABELS)}
            className={cx({
              [classNames.debuggerLabelsButton]: isActiveLabels
            })}
          >
            Labels
          </TextButton>
          <TextButton
            type="secondary"
            onClick={() => setActiveButton(DEBUGGER_GROUP_BUTTONS.INGESTIONS)}
            className={cx({ [classNames.debuggerErrorButton]: isActiveErrors })}
          >
            Ingestion Errors
          </TextButton>
        </div>
        <div className={classNames.debuggerStreamActionsWrapper}>
          <IconButton
            active={activeAction === STREAM_ACTIONS.PLAY}
            data-test="debugger-play-action"
            Icon={<DSPlayIcon />}
            onClick={() => setActiveAction(STREAM_ACTIONS.PLAY)}
          />
          <IconButton
            active={activeAction === STREAM_ACTIONS.PAUSE}
            data-test="debugger-pause-action"
            Icon={<DSPauseIcon />}
            onClick={() => setActiveAction(STREAM_ACTIONS.PAUSE)}
          />
        </div>
      </div>
    </div>
  );
};

DebuggerActionsPanel.propTypes = {
  activeButton: PropTypes.string.isRequired,
  setActiveButton: PropTypes.func.isRequired,
  activeAction: PropTypes.string.isRequired,
  setActiveAction: PropTypes.func.isRequired
};
