import React from 'react';
import PropTypes from 'prop-types';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import isEmpty from 'lodash/isEmpty';

import SmallLoader from '@shared/components/SmallLoader';
import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import useAssetById from '@experiment-management-shared/api/useAssetById';

const SourceCodePreview = ({
  artifactVersionId,
  assetId,
  experimentKey,
  mode
}) => {
  const { data: asset, isLoading } = useAssetById({
    experimentKey,
    assetId,
    artifactVersionId,
    asText: true
  });

  if (isLoading) {
    return <SmallLoader primaryMessage="Loading..." />;
  }

  if (isEmpty(asset)) {
    return <EmptyPreview message="No preview available." />;
  }

  return (
    <CodeMirror
      value={asset}
      options={{
        mode,
        lineNumbers: true,
        readOnly: true
      }}
    />
  );
};

SourceCodePreview.defaultProps = {
  artifactVersionId: undefined
};

SourceCodePreview.propTypes = {
  artifactVersionId: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired
};

export default SourceCodePreview;
