import { components } from 'react-select';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Control = props => (
  <components.Control
    {...props}
    className={cx(
      props.isFocused && 'sortable-select-control-focused',
      'sortable-select-control'
    )}
  />
);

Control.propTypes = {
  isFocused: PropTypes.bool
};

Control.defaultProps = {
  isFocused: false
};

export default Control;
