import React, { useState, useRef, useMemo } from 'react';
import { Input } from '@DesignSystem/controllers';
import { Button } from '@design-system-outdated/components';
// import Functions from '@material-ui/icons/Functions';
import PetsIcon from '@material-ui/icons/Pets';

import { tokenize } from './tokenizer';
import { parse } from './parser';
import { getTokensList } from './helper';
import './RecursiveParserPage.scss';
import { AutoCompleterTokenized } from '../AutoCompleter';
import { AUTO_COMPLETER_RULES } from '@mpm/constants';

const oprators = '+   -   *   /   (   )';

const SUPPORTED_FUNCTIONS = [
  'if',
  'isnull',
  'abs',
  'floor',
  'ceil',
  'isnan',
  'ln',
  'pow',
  'random',
  'round',
  'sqrt',
  'log',
  'avg',
  'count',
  'first',
  'last',
  'min',
  'max',
  'sum',
  'approx_count_distinct',
  'approx_quantile',
  'median',
  'regr_r2'
];

export const RecursiveParserPage = ({ model }) => {
  const [inputValue, setInputValue] = useState('');
  const [tokenizedExpression, setTokenizedExpression] = useState('');
  const [parsedExpression, setParsedExpression] = useState('');
  const [expressionError, setExpressionError] = useState(false);

  const inputRef = useRef(null);

  const inputHandler = value => {
    setInputValue(value);
  };

  const prefixedFeatureNames = useMemo(() => {
    if (model) {
      return model.features
        .filter(
          feature =>
            feature.type === 'model_input_features' ||
            feature.type === 'model_output_features'
        )
        .map(feature => {
          if (feature.type === 'model_input_features') {
            return `input.${feature.key}`;
          }
          if (feature.type === 'model_output_features') {
            return `output.${feature.key}`;
          }
        });
    }
    return [];
  }, [model?.features]);

  const parseHandler = () => {
    try {
      const tokenObjects = tokenize(inputValue);
      if (tokenObjects?.errors.length > 0) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { message, pos } = tokenObjects?.errors[0];
        setTokenizedExpression('');
        setParsedExpression('');
        setExpressionError(`${message} position: ${pos}`);
        return;
      }
      const tokenValues = getTokensList(inputValue, tokenObjects?.tokens);
      setTokenizedExpression(tokenValues);
      setParsedExpression(JSON.stringify(parse(inputValue)));
      setExpressionError('');
    } catch (e) {
      setTokenizedExpression('');
      setParsedExpression('');
      setExpressionError(e?.message);
    }
  };

  return (
    <div className="recursive-parser-wrapper">
      <h3>Recursive parser</h3>
      <div className="recursive-parser-content">
        <div className="usage-description">
          <p>Allowed variables format in the square brackets: [featureName]</p>
          <p>Allowed functions: {SUPPORTED_FUNCTIONS.join(', ')}</p>
          <p>Allowed operators: {oprators}</p>
        </div>
        <label htmlFor="expression">Input expression</label>
        <div className="input-section">
          <span className="input-wrapper">
            <Input
              value={inputValue}
              onChange={inputHandler}
              name="expression"
              placeholder="Expression ..."
              className="formula-input"
              ref={inputRef}
            />
          </span>
          <AutoCompleterTokenized
            inputStr={inputValue}
            inputStrSetter={inputHandler}
            rules={[
              {
                Icon: PetsIcon,
                values: prefixedFeatureNames,
                openingChars: '[',
                closingChars: ']',
                name: AUTO_COMPLETER_RULES.FEATURES
              },
              {
                Icon: PetsIcon,
                values: SUPPORTED_FUNCTIONS,
                closingChars: '(',
                name: AUTO_COMPLETER_RULES.FUNCTIONS
              }
            ]}
            ref={inputRef}
          />
          <Button
            text="Parse expression"
            type="primary"
            onClick={parseHandler}
            disabled={!inputValue}
          />
        </div>
        {expressionError && <p className="formula-error">{expressionError}</p>}
        <h4>Output results</h4>
        <label htmlFor="tokens">Tokenized Expression:</label>
        <Input readOnly name="tokens" value={tokenizedExpression} />
        <label htmlFor="recursive-parser">Parsed Expression:</label>
        <Input readOnly name="recursive-parser" value={parsedExpression} />
      </div>
    </div>
  );
};
