import React, { ForwardedRef, forwardRef, useMemo } from 'react';

interface CodeIframeProps {
  className?: string;
  srcDoc?: string;
  id?: string;
  url?: string;
  isExtendedPermissions: boolean;
  isInternalScopeType?: boolean;
}
const CodeIframe = (
  {
    className,
    srcDoc,
    id,
    url,
    isExtendedPermissions,
    isInternalScopeType
  }: CodeIframeProps,
  ref: ForwardedRef<HTMLIFrameElement>
) => {
  const permissions = useMemo(() => {
    const allowSameOrigin =
      isInternalScopeType || url ? 'allow-same-origin' : '';
    if (isExtendedPermissions) {
      return `allow-scripts allow-downloads allow-top-navigation allow-popups allow-popups-to-escape-sandbox ${allowSameOrigin}`;
    }

    return `allow-scripts ${allowSameOrigin}`;
  }, [isExtendedPermissions, isInternalScopeType, url]);

  if (url) {
    return <iframe ref={ref} className={className} id={id} src={url} />;
  }

  return (
    <iframe
      ref={ref}
      className={className}
      id={id}
      srcDoc={srcDoc}
      sandbox={permissions}
    />
  );
};

const CodeIframeRef = forwardRef<HTMLIFrameElement, CodeIframeProps>(
  CodeIframe
);
CodeIframeRef.displayName = 'CodeIframe';
export default CodeIframeRef;
