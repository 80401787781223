// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CometSQLSyntax-module__editor--CqooY{font-size:13px;height:100%}.CometSQLSyntax-module__editor--CqooY .cm-editor{background-color:#f6f8fa;letter-spacing:0}.CometSQLSyntax-module__codemirror--CmsXR{height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `CometSQLSyntax-module__editor--CqooY`,
	"codemirror": `CometSQLSyntax-module__codemirror--CmsXR`
};
module.exports = ___CSS_LOADER_EXPORT___;
