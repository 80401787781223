import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { Box } from '@material-ui/core';

import { Button, TextButton } from '@ds';

import { Divider } from '@DesignSystem/data-display';
import VerticalLinearStepper from '@DesignSystem/data-display/VerticalStepper/VerticalStepper';
import { LineChart } from '../LineChart';
import { CHART_BASIC_LAYOUT, makeLineChartThresholdShape } from '@mpm/utils';
import NoAlertPreviewIcon from '@Icons-outdated/NoAlertPreviewIcon.svg';
import { chartColors } from '@design-system-outdated/constants';
import { useMPMPanelData } from '@mpm/hooks';
import { useAddAlertMutation, useAllFeatures } from '@mpm/api';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
import {
  EMPTY_DEFAULT_CHART_OVERVIEW_MESSAGE,
  EMPTY_HOURLY_CHART_OVERVIEW_MESSAGE,
  EMPTY_DAILY_CHART_OVERVIEW_MESSAGE,
  DEFAULT_VERSIONS,
  RESPONSE_PERCENTILES,
  NAME_TO_METRIC_TYPE_MAP,
  NOTIFICATION_TYPES,
  MPM_ALERT_ALLOWED_CHARTS,
  METRIC_TYPES_MAP_SECTION,
  OUTPUT_DISTRIBUTION_MAP_SECTION
} from '@mpm/constants';
import './AddAlertPage.scss';

const colors = Object.values(chartColors);

const getISODate = daysAgoFromToday => {
  return new Date(
    new Date().getTime() - daysAgoFromToday * 24 * 60 * 60 * 1000
  ).toISOString();
};

const initialStepOneState = {
  metricCategory: null,
  metricType: null,
  outputDistribution: null,
  thresholdOperator: 'greater_than',
  thresholdType: 'threshold',
  thresholdValue: '0',
  segment: 'all',
  modelVersion: 'all',
  timePeriod: 'daily',
  driftAlgorithm: undefined
};

export function AddAlertPage({ model }) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepOneFieldsState, setStepOneFieldsState] = useState(
    initialStepOneState
  );
  const [stepTwoFieldsState, setStepTwoFieldsState] = useState({
    emailAlertOn: false,
    slackAlertOn: false,
    teamsAlertOn: false,
    slackChannelMail: '',
    teamsChannelMail: ''
  });

  const [stepThreeFieldsState, setStepThreeFieldsState] = useState({
    alertName: null,
    alertDescription: null
  });
  const [isStepOneDone, setIsStepOneDone] = useState(false);

  const history = useHistory();
  const {
    section,
    intervalType,
    version,
    modelFeatureId,
    driftAlgorithmParam,
    percentileType
  } = queryString.parse(history.location.search);

  const dailyIntervalType = stepOneFieldsState.timePeriod === 'daily';
  const hourlyIntervalType = stepOneFieldsState.timePeriod === 'hourly';

  const modelVersion =
    stepOneFieldsState.modelVersion !== 'all'
      ? stepOneFieldsState.modelVersion
      : null;

  const formDataSection =
    METRIC_TYPES_MAP_SECTION[stepOneFieldsState.metricType] ||
    OUTPUT_DISTRIBUTION_MAP_SECTION[stepOneFieldsState.outputDistribution];

  const { data: modelFeaturesData } = useAllFeatures({
    modelVersion,
    modelId: model.id
  });

  const todayDateISO = useMemo(() => new Date().toISOString(), []);

  const previousDateISO = useMemo(() => {
    if (dailyIntervalType) {
      return getISODate(30);
    }
    return getISODate(7);
  }, [dailyIntervalType]);

  const emptyStateChartMessage = useMemo(() => {
    if (dailyIntervalType) {
      return EMPTY_DAILY_CHART_OVERVIEW_MESSAGE;
    }
    if (hourlyIntervalType) {
      return EMPTY_HOURLY_CHART_OVERVIEW_MESSAGE;
    }
    return EMPTY_DEFAULT_CHART_OVERVIEW_MESSAGE;
  }, [hourlyIntervalType, dailyIntervalType]);

  const featureData = useMemo(() => {
    return modelFeaturesData
      ? modelFeaturesData.features.find(
          feature => feature.id === stepOneFieldsState.metricType
        )
      : null;
  }, [modelFeaturesData, stepOneFieldsState.metricType]);

  const handleDropdownState = useCallback(
    (newValue, input, optionData) => {
      setStepOneFieldsState(prev => {
        if (
          input.name === 'metricCategory' &&
          newValue !== stepOneFieldsState.metricCategory
        ) {
          return { ...initialStepOneState, [input.name]: newValue };
        }
        if (
          stepOneFieldsState.metricCategory === 'feature_metrics' &&
          input.name === 'metricType'
        ) {
          if (
            (featureData?.valType &&
              optionData?.valType &&
              optionData.valType === featureData.valType) ||
            (!featureData?.valType && optionData?.valType)
          ) {
            return { ...prev, [input.name]: newValue };
          }
          return {
            ...prev,
            [input.name]: newValue,
            outputDistribution: undefined,
            driftAlgorithm: undefined
          };
        }
        const newState = { ...prev, [input.name]: newValue };
        if ('driftAlgorithm' in optionData) {
          newState.driftAlgorithm = optionData.driftAlgorithm;
        }

        return newState;
      });
    },
    [stepOneFieldsState, featureData?.valType]
  );

  const { data } = useMPMPanelData(
    {
      section: formDataSection,
      colors,
      from: previousDateISO,
      to: todayDateISO,
      intervalType: stepOneFieldsState.timePeriod,
      version: modelVersion,
      propFeatureId: featureData?.id,
      segmentColorIds: [],
      isLabeled: false,
      title: '',
      metricType: null,
      driftAlgorithmType: stepOneFieldsState.driftAlgorithm
    },
    {
      enabled: !!todayDateISO && !!previousDateISO,
      keepPreviousData: true
    }
  );

  const chartData = useMemo(() => {
    if (data?.panelData?.length) {
      if (data.panelData[0]?.percentiles) {
        const percentileData =
          data.panelData[0].percentiles.find(
            item =>
              RESPONSE_PERCENTILES[stepOneFieldsState.outputDistribution] ===
              item.value
          )?.data || [];
        return [
          {
            line: { color: '#5899DA', dash: 'solid' },
            mode: 'lines',
            x: percentileData.map(val => val.x),
            y: percentileData.map(val => val.y)
          }
        ];
      }
      return data.panelData;
    }

    return [];
  }, [data, stepOneFieldsState]);

  const enabledChartData = !(
    data?.panelData?.length === 0 ||
    !data?.panelData ||
    data?.panelData.every(
      item =>
        item.x?.length === 0 ||
        item.y?.length === 0 ||
        (item.data?.length === 0 && !item.x)
    )
  );

  const addAlertMutation = useAddAlertMutation();
  const featureMetricsPayload = stepOneFieldsState.metricCategory ===
    'feature_metrics' && {
    featureId: stepOneFieldsState.metricType,
    metricType:
      NAME_TO_METRIC_TYPE_MAP[stepOneFieldsState.outputDistribution] ||
      stepOneFieldsState.outputDistribution
  };

  const handleAddAlert = () => {
    const payload = {
      name: stepThreeFieldsState.alertName,
      description: stepThreeFieldsState.alertDescription,
      intervalType: stepOneFieldsState.timePeriod,
      metricType:
        NAME_TO_METRIC_TYPE_MAP[stepOneFieldsState.metricType] ||
        stepOneFieldsState.metricType,
      modelVersion,
      ...featureMetricsPayload,
      threshold: {
        thresholdType: stepOneFieldsState.thresholdType,
        thresholdOperator: stepOneFieldsState.thresholdOperator,
        value: stepOneFieldsState.thresholdValue
      },
      notificationMethods: null,
      notificationMethodsV2: [],
      driftAlgorithm: stepOneFieldsState.driftAlgorithm
    };
    const {
      emailAlertOn,
      slackChannelMail,
      teamsChannelMail
    } = stepTwoFieldsState;
    if (emailAlertOn) {
      payload.notificationMethodsV2.push({
        type: NOTIFICATION_TYPES.EMAIL,
        metadata: {}
      });
    }
    if (slackChannelMail) {
      payload.notificationMethodsV2.push({
        type: NOTIFICATION_TYPES.SLACK,
        metadata: {
          email: slackChannelMail
        }
      });
    }
    if (teamsChannelMail) {
      payload.notificationMethodsV2.push({
        type: NOTIFICATION_TYPES.TEAMS,
        metadata: {
          email: teamsChannelMail
        }
      });
    }

    addAlertMutation.mutate({ payload, modelId: model.id });
  };

  const getPrefilledData = (
    sectionType,
    period,
    versionModel,
    id,
    driftAlgorithmParam,
    percentileType
  ) => {
    const algorithm =
      driftAlgorithmParam !== 'undefined' ? `_${driftAlgorithmParam}` : '';
    const percentileValue =
      percentileType !== 'undefined' ? `_${percentileType}` : '';
    const prefilledData = {
      metricCategory: MPM_ALERT_ALLOWED_CHARTS[sectionType][0],
      modelVersion:
        versionModel === 'null' ? DEFAULT_VERSIONS[0].value : version,
      timePeriod: period
    };
    if (driftAlgorithmParam !== 'undefined') {
      prefilledData.driftAlgorithm = driftAlgorithmParam;
    }
    if (id !== 'undefined') {
      return {
        ...prefilledData,
        metricType: id,
        outputDistribution: `${MPM_ALERT_ALLOWED_CHARTS[sectionType][1]}${
          algorithm || percentileValue
        }`
      };
    }
    return {
      ...prefilledData,
      metricType: `${MPM_ALERT_ALLOWED_CHARTS[sectionType][1]}${
        algorithm || percentileValue
      }`
    };
  };

  const alertOperatorSign = useMemo(() => {
    switch (stepOneFieldsState.thresholdOperator) {
      case 'greater_than':
        return '>';
      case 'less_than':
        return '<';
      case 'greater_equal':
        return '≥';
      case 'less_equal':
        return '≤';
      default:
        return '-';
    }
  }, [stepOneFieldsState.thresholdOperator]);

  // Pre-fill data after redirection from MPM Charts pages
  useEffect(() => {
    if (section) {
      setStepOneFieldsState(prevState => ({
        ...prevState,
        ...getPrefilledData(
          section,
          intervalType,
          version,
          modelFeatureId,
          driftAlgorithmParam,
          percentileType
        )
      }));
    }
  }, [section]);

  // AUTO SET ALERT NAME
  useEffect(() => {
    setStepThreeFieldsState(prevState => ({
      ...prevState,
      alertName: `${
        stepOneFieldsState.outputDistribution || stepOneFieldsState.metricType
      } ${alertOperatorSign} ${stepOneFieldsState.thresholdValue}`
    }));
  }, [
    alertOperatorSign,
    stepOneFieldsState.metricType,
    stepOneFieldsState.outputDistribution,
    stepOneFieldsState.thresholdValue
  ]);

  const steps = useMemo(
    () => [
      {
        label: 'What do you want to track?',
        isCompleted: false,
        content: (
          <StepOne
            model={model}
            setIscurrentStepDone={setIsStepOneDone}
            modelFeatures={modelFeaturesData?.features}
            fieldsState={stepOneFieldsState}
            setFieldsState={setStepOneFieldsState}
            setActiveStep={setActiveStep}
            handleDropdownState={handleDropdownState}
          />
        )
      },
      {
        label: 'Who should be notified?',
        isCompleted: false,
        content: (
          <StepTwo
            fieldsState={stepTwoFieldsState}
            setFieldsState={setStepTwoFieldsState}
            setActiveStep={setActiveStep}
          />
        )
      },
      {
        label: 'Whats the alert name?',
        content: (
          <StepThree
            fieldsState={stepThreeFieldsState}
            setFieldsState={setStepThreeFieldsState}
            handleAddAlert={handleAddAlert}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      model,
      modelFeaturesData,
      stepOneFieldsState,
      stepThreeFieldsState,
      stepTwoFieldsState
    ]
  );

  return (
    <div className="add-alert-container">
      <div className="add-alert-wrapper">
        <h5>Add Alert Rule</h5>
        <Divider margin={0} />
        <Box display="flex" flexDirection="row" width="100%">
          <Box width="780px">
            <VerticalLinearStepper
              changeCurrentStep={index =>
                index < activeStep || isStepOneDone
                  ? setActiveStep(index)
                  : null
              }
              steps={steps}
              activeStep={activeStep}
            />
          </Box>
          <Box
            height="354px"
            marginTop="63px"
            borderLeft="1px solid #e2e2e2"
            padding="0 20px 0 32px"
            flexGrow={1}
          >
            <>
              <span className="chart-preview-title">Alert Preview</span>
              <LineChart
                data={chartData}
                height={350}
                disableZoom
                showCustomTooltip={false}
                emptyStateBoxConfig={{
                  showEmptyStateBox: !enabledChartData,
                  boxIcon: <NoAlertPreviewIcon />,
                  boxMessage: emptyStateChartMessage
                }}
                layoutData={{
                  shapes: makeLineChartThresholdShape(
                    stepOneFieldsState.thresholdValue,
                    enabledChartData
                  ),
                  yaxis: {
                    ...CHART_BASIC_LAYOUT.yaxis,
                    range: [0, 1.01]
                  },
                  xaxis: {
                    ...CHART_BASIC_LAYOUT.xaxis,
                    autorange: !!enabledChartData,
                    range: [previousDateISO, todayDateISO]
                  }
                }}
              />
            </>
          </Box>
        </Box>
        <Box display="flex" padding="0 24px 35px 55px">
          <Button
            onClick={handleAddAlert}
            disabled={
              stepThreeFieldsState.alertName === '' ||
              !isStepOneDone ||
              addAlertMutation.isLoading
            }
          >
            Save
          </Button>
          <TextButton
            className="add-alert-cancel-btn"
            onClick={() => history.goBack()}
          >
            Cancel
          </TextButton>
        </Box>
      </div>
    </div>
  );
}
