import { DropdownListPopper, Input } from '@DesignSystem/controllers';
import { SearchIcon } from '@Icons-outdated';
import {
  InputDropdownHeader,
  InputDropdownItem
} from '@projects/components/LLMDashboardHeader/LLMSearchForm/LLMSearchInput/components';
import {
  OPERATORS_LIST,
  SEARCH_PLACEHOLDER
} from '@projects/components/LLMDashboardHeader/LLMSearchForm/LLMSearchInput/consts';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../../LLMDashboardHeader.module.scss';

export default function LLMSearchInput({ controlledQueryText }) {
  const inputRef = useRef();
  const [isDropdownFocus, setIsDropdownFocus] = useState(false);
  const [queryValue, setQueryValue] = useState('');

  useEffect(() => {
    setQueryValue(controlledQueryText);
  }, [controlledQueryText]);

  return (
    <div className={styles.searchInputWrapper}>
      <Input
        data-test="llm-query-search-input"
        id="llm-query-search-input"
        ref={inputRef}
        withInnerInput
        onFocus={() => setIsDropdownFocus(true)}
        onBlur={() => setIsDropdownFocus(false)}
        variant="outlined"
        placeholder={SEARCH_PLACEHOLDER}
        onChange={(value, e) => {
          e.preventDefault();
          setQueryValue(value);
        }}
        InlinePrefixIcon={SearchIcon}
        height={36}
        value={queryValue}
        name="queryText"
      />
      <DropdownListPopper
        onMouseDown={e => e.preventDefault()}
        items={OPERATORS_LIST}
        renderPopperHeader={InputDropdownHeader}
        width={800}
        classes={cx(styles.popperContainer, 'list')}
        open={isDropdownFocus && queryValue.length === 0}
        renderListItem={InputDropdownItem}
        onClick={(value, item) => {
          inputRef.current.value = value;
          inputRef.current.selectionStart = item.index;
          inputRef.current.selectionEnd = item.index;
          setQueryValue(value);
        }}
        anchorEl={inputRef.current}
      />
    </div>
  );
}
