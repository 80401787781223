import React from 'react';
import { Tooltip, ToggleButton } from '@ds';

import styles from './TextTab.module.scss';

type FullHeightToggleProps = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
};

const FullHeightToggle = ({ enabled, onChange }: FullHeightToggleProps) => {
  const tooltipText = `Click to ${
    enabled ? 'disable' : 'enable'
  } full height in the text content`;

  return (
    <Tooltip content={tooltipText} placement="top">
      <label className={styles.fullHeightToggle}>
        <ToggleButton
          id="full-height-text"
          checked={enabled}
          onChange={() => onChange(!enabled)}
          color="primary"
        />
        Full height text
      </label>
    </Tooltip>
  );
};

export default FullHeightToggle;
