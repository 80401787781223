import React from 'react';
import PropTypes from 'prop-types';
import './EmptyStatePreviewBox.scss';

const EmptyStatePreviewBox = ({ icon, message }) => {
  return (
    <div className="empty-state-box">
      {icon}
      <span className="empty-state-box-message">{message}</span>
    </div>
  );
};

EmptyStatePreviewBox.propTypes = {
  icon: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired
};

export default EmptyStatePreviewBox;
