import React from 'react';
import PropTypes from 'prop-types';

import { BasicModal } from '@DesignSystem/modals';

import './ServiceAccounts.scss';

const DeleteServiceAccount = ({
  open,
  onClose,
  onPrimaryButtonClick,
  isPrimaryButtonDisabled,
  serviceAccountName
}) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Are You Sure?"
      primaryButtonText="Continue"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      content={
        <div className="delete-service-account-content">
          <div className="delete-account-label-with-name">
            The Service Account “{serviceAccountName}” will be removed and
            it&apos;s API key can no longer be used.
          </div>
          <div>Once deleted the service account can not be recovered.</div>
        </div>
      }
    />
  );
};

DeleteServiceAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  isPrimaryButtonDisabled: PropTypes.func.isRequired,
  serviceAccountName: PropTypes.string.isRequired
};

export default DeleteServiceAccount;
