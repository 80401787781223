import React from 'react';

import {
  PASSWORD_ERRORS_MAP,
  PASSWORD_POLICIES_NON_APPLICABLE_VALUES
} from '@auth/constants';
import { isNull, isUndefined } from 'lodash';
import CheckCircleIcon from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';

const isNumPlural = num => num !== 1;

export const PASSWORD_POLICIES = {
  minPasswordLength: {
    dynamicMsg: policyValue =>
      `Password has ${policyValue} or more ${
        isNumPlural(policyValue) ? 'characters' : 'character'
      }`,
    dynamicRegex: policyValue => `(?=.{${policyValue}})`
  },
  minUpperCaseChars: {
    dynamicMsg: policyValue =>
      `Password has ${policyValue} uppercased  ${
        isNumPlural(policyValue) ? 'characters' : 'character'
      }`,
    dynamicRegex: policyValue => `(?=.*[A-Z]{${policyValue}})`
  },
  minLowerCaseChars: {
    dynamicMsg: policyValue =>
      `Password has ${policyValue} lowercased ${
        isNumPlural(policyValue) ? 'characters' : 'character'
      }`,
    dynamicRegex: policyValue => `(?=.*[a-z]{${policyValue}})`
  },
  minDigits: {
    dynamicMsg: policyValue =>
      `Password has ${policyValue} ${
        isNumPlural(policyValue) ? 'numbers' : 'number'
      }`,
    dynamicRegex: policyValue => `(?=.*\\d{${policyValue}})`
  },
  minSpecialChars: {
    dynamicMsg: policyValue =>
      `Password has ${policyValue} special ${
        isNumPlural(policyValue) ? 'characters' : 'character'
      } `,
    dynamicRegex: policyValue => `(?=.*[-+_!@#$%^&*., ?]{${policyValue}})`
  },
  maxRepeatChars: {
    dynamicMsg: policyValue =>
      `Password must not have ${policyValue} or more characters repeated`,
    dynamicRegex: policyValue => `(?=(\\S)(\\1{${policyValue - 1},}))`,
    reverted: true,
    dynamicCondition: (policyValue, { value }) =>
      value?.length < 12 && value?.length >= policyValue
  },
  allowWhitespaceInPassword: {
    dynamicMsg: () => `Password does not contain whitespaces`,
    dynamicRegex: () => `(?=^\\S*$)`
  },
  allowUserNameInPassword: {
    dynamicMsg: () => `Password does not contain username`,
    dynamicRegex: (policyValue, { username }) => `(?=^((?!${username}).)*$)`,
    dynamicCondition: (policyValue, { value, username }) =>
      username && value?.length >= username?.length
  }
};

const normalizePasswordPolicy = (key, policyValue, props) => {
  const obj = PASSWORD_POLICIES[key];
  let dynamicRegex = null;

  if (!obj || PASSWORD_POLICIES_NON_APPLICABLE_VALUES.includes(policyValue)) {
    return null;
  }

  dynamicRegex = obj.dynamicRegex && obj.dynamicRegex(policyValue, props);

  return {
    key,
    regex: dynamicRegex,
    condition: obj.dynamicCondition,
    msg: obj.dynamicMsg ? obj.dynamicMsg(policyValue, props) : null,
    reverted: obj.reverted
  };
};

export const getNormalizedPasswordPolicies = (passwordPolicy, props) => {
  const normalizedPasswordPolicies = passwordPolicy
    ? Object.keys(passwordPolicy)
        .map(key => normalizePasswordPolicy(key, passwordPolicy[key], props))
        .filter(Boolean)
    : null;

  if (normalizedPasswordPolicies !== null) {
    let resultToMatch = '';
    let resultNotToMatch = '';

    normalizedPasswordPolicies.forEach(normalizedPolicy => {
      const ifShouldCheckRegExp = getIfShouldCheckRegexp(
        passwordPolicy[normalizedPolicy?.key],
        normalizedPolicy,
        props
      );

      if (!ifShouldCheckRegExp) return;

      if (normalizedPolicy.regex) {
        if (normalizedPolicy.reverted) {
          resultNotToMatch += normalizedPolicy.regex;
        } else {
          resultToMatch += normalizedPolicy.regex;
        }
      }
    });

    return {
      policies: normalizedPasswordPolicies,
      resultToMatch,
      resultNotToMatch
    };
  }

  return null;
};

export const getIfPasswordValid = ({
  password,
  resultToMatch,
  resultNotToMatch
}) => {
  return (
    (resultToMatch ? password.match(new RegExp(resultToMatch)) : true) &&
    (resultNotToMatch ? !password.match(new RegExp(resultNotToMatch)) : true)
  );
};

const SuccessIcon = (
  <CheckCircleIcon htmlColor="springgreen" fontSize="inherit" />
);
const FailedIcon = (
  <Close htmlColor="#ff9087" color="error" fontSize="inherit" />
);

export const getPasswordTooltipText = ({
  passwordPolicy,
  password,
  username,
  fallback
}) => {
  if (isUndefined(passwordPolicy) || isNull(passwordPolicy)) return null;

  const normalizedPasswordPoliciesResults = getNormalizedPasswordPolicies(
    passwordPolicy,
    {
      value: password,
      username
    }
  );

  if (!normalizedPasswordPoliciesResults) {
    if (fallback && typeof fallback === 'function') {
      fallback();
    }
  }

  if (
    getIfPasswordValid({
      password,
      resultNotToMatch: normalizedPasswordPoliciesResults?.resultNotToMatch,
      resultToMatch: normalizedPasswordPoliciesResults?.resultToMatch
    })
  ) {
    return null;
  }

  return normalizedPasswordPoliciesResults?.policies
    .map(rule => {
      const ifShouldCheckRegexp = getIfShouldCheckRegexp(
        passwordPolicy[rule?.key],
        rule,
        {
          value: password,
          username
        }
      );

      const matchRegexp = ifShouldCheckRegexp ? new RegExp(rule.regex) : false;

      let icon =
        !matchRegexp ||
        (matchRegexp &&
          (rule?.reverted
            ? !password.match(matchRegexp)
            : password.match(matchRegexp)))
          ? SuccessIcon
          : FailedIcon;

      return rule.msg ? (
        <Box key={rule.msg} alignItems="center" display="flex">
          {icon}
          <p
            style={{
              fontWeight: 500,
              marginLeft: '5px'
            }}
          >
            {rule.msg}
          </p>
        </Box>
      ) : null;
    })
    .filter(Boolean);
};

export const getIfShouldCheckRegexp = (policyValue, rule, props) => {
  const ifShouldCheckRegExp = rule?.condition;

  return ifShouldCheckRegExp
    ? typeof ifShouldCheckRegExp === 'function' &&
        ifShouldCheckRegExp(policyValue, props)
    : true;
};

export const getPasswordValidationErrorMsg = failureMsg => {
  let formattedFailureMsg = failureMsg;
  const errorKeys = [];

  if (
    Object.keys(PASSWORD_ERRORS_MAP).filter(key => {
      if (failureMsg.includes(key)) {
        errorKeys.push(key);
        return true;
      }

      return false;
    })?.length
  ) {
    errorKeys.forEach(errorKey => {
      formattedFailureMsg = formattedFailureMsg.replace(
        errorKey,
        PASSWORD_ERRORS_MAP[errorKey]
      );
    });
  }

  return formattedFailureMsg;
};
