import { useMutation, useQueryClient } from 'react-query';
import api from '@shared/api';

interface CreatePythonEnvironmentVersionRequest {
  additionalPackages: string[];
}

const createNewVersion = async (
  requirements: string[],
  organizationId: string
) => {
  return api.put(`/code-panels/requirements?orgId=${organizationId}`, {
    requirements
  });
};

const useCreatePythonEnvironmentVersionMutation = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ additionalPackages }: CreatePythonEnvironmentVersionRequest) => {
      return createNewVersion(additionalPackages, organizationId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['python-environment-versions']);
      }
    }
  );
};

export default useCreatePythonEnvironmentVersionMutation;
