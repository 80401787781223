import React, { useCallback } from 'react';
import cx from 'classnames';

import Popover from '@material-ui/core/Popover';
import { BreadcrumbItemProps, BreadcrumbItemType } from '@shared/types';
import { DSMoreOptionsHorizontalIcon } from '@ds-icons';
import HomeItem from '@platform/components/Breadcrumbs/components/HomeItem';
import LinkItem from '@platform/components/Breadcrumbs/components/LinkItem';
import TextItem from '@platform/components/Breadcrumbs/components/TextItem';
import ExperimentItem from '@platform/components/Breadcrumbs/components/ExperimentItem';
import CompareItem from '@platform/components/Breadcrumbs/components/CompareItem';

import styles from './BreadcrumbItem.module.scss';

const COMPONENTS_MAP: Record<
  BreadcrumbItemType,
  React.FunctionComponent<BreadcrumbItemProps>
> = {
  home: HomeItem,
  link: LinkItem,
  text: TextItem,
  experiment: ExperimentItem,
  compare: CompareItem,
  collapse: TextItem // Wrong mapping to prevent circular dependency
};

const CollapseItem = ({ collapsed }: BreadcrumbItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <button
        className={cx(styles.item, styles.collapseItem, {
          [styles.active]: Boolean(anchorEl)
        })}
        data-test="breadcrumbs-collapse"
        onClick={handleClick}
      >
        <DSMoreOptionsHorizontalIcon />
      </button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: styles.popoverContainer }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -6,
          horizontal: 'left'
        }}
      >
        <div
          className={styles.listContainer}
          data-test="breadcrumbs-collapse-container"
        >
          {(collapsed || []).map(item => {
            const Item = COMPONENTS_MAP[item.type];
            return <Item key={item.id} {...item} onClick={handleClose}></Item>;
          })}
        </div>
      </Popover>
    </>
  );
};

export default CollapseItem;
