import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import ProjectPageHeader from '@shared/components/ProjectPageHeader';
import projectsActions from '../../../actions/projectsActions';
import useProjectViewedBI from './hooks/useProjectViewedBI';

import { getActiveEMHeaderTab } from '@/reducers/ui/projectsUiReducer';
import useEMProjectRoutes, { EMTabPathType } from './hooks/useEMProjectRoutes';

type EMProjectPageHeaderProps = {
  subheaderRightSection?: React.ReactNode;
  sticky?: boolean;
};
const EMProjectPageHeader: React.FC<EMProjectPageHeaderProps> = ({
  subheaderRightSection = null,
  sticky = false
}) => {
  const dispatch = useDispatch();
  const routes = useEMProjectRoutes();
  const activeTab = useSelector(getActiveEMHeaderTab);

  const sentProjectViewedBI = useProjectViewedBI();

  const handleTabChange = (tabName: EMTabPathType) => {
    const { panels, experiments, archive } = EXPERIMENT_TAB_PATHNAME;

    if (activeTab === tabName && tabName !== experiments) return;

    if ([panels, experiments, archive].includes(tabName)) {
      dispatch(projectsActions.setActiveEMHeaderTab(tabName));
    }

    sentProjectViewedBI(tabName);
  };

  return (
    <ProjectPageHeader
      activeTab={activeTab}
      sticky={sticky}
      routes={routes}
      handleTabChange={handleTabChange}
      subheaderRightSection={subheaderRightSection}
    />
  );
};

export default EMProjectPageHeader;
