import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@ds';
import { Box } from '@material-ui/core';

import { DeleteSecondaryIcon, EditIcon } from '@Icons-outdated';
import { DSColors } from '@design-system-outdated/constants';
import { Table } from '@/DesignSystem/components/tables';
import DateCell from '@shared/components/TableCells/DateCell';
import TextCell from '@shared/components/TableCells/TextCell';
import { dialogTypes } from '@/constants/alertTypes';
import {
  useSegments,
  useCreateSegmentMutation,
  useDeleteSegmentMutation,
  useEditSegmentMutation
} from '@mpm/api';
import {
  MODAL_TYPE,
  SEGMENTS_COLUMNS,
  SEGMENTS_TABLE_HEIGHT,
  CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS,
  RIGHT_COLUMNS,
  DELETE_SEGMENT_BODY_TEXT,
  LIMITED_SEGMENTS_TOOLTIP_MESSAGE,
  MAX_ALLOWED_SEGMENTS
} from '@mpm/constants';
import alertsUtil from '@/util/alertsUtil';
import CreateEditSegmentModal from '@mpm/components/CreateEditSegmentModal';
import DeleteMetricModal from '@shared/components/DeleteMetricModal';
import SmallLoader from '@shared/components/SmallLoader';
import TableEmptyState from '@shared/components/TableEmptyState';
import MoreActionsCell from '@shared/components/TableCells/MoreActionsCell';
import './CustomTransformationsPage.scss';
import { SegmentDefinitionType, SegmentDataType } from '@mpm/types';

const mapDataToRows = (segmentsData: SegmentDefinitionType[]) => {
  return segmentsData.map(segment => {
    const {
      id,
      name,
      createdAt,
      createdBy,
      description,
      segmentRule,
      segments
    } = segment;

    return {
      id,
      name,
      createdAt,
      createdBy,
      description,
      segmentRule,
      segments,
      featureBreakdown: segmentRule.feature?.key
    };
  });
};

const Segments = () => {
  const [rows, setRows] = useState<SegmentDefinitionType[]>([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'actions', width: 25 }
  ]);

  const dispatch = useDispatch();
  const createSegment = useCreateSegmentMutation();
  const deleteSegment = useDeleteSegmentMutation();
  const editSegment = useEditSegmentMutation();

  const { modelId } = useParams<{ modelId: string }>();
  const { isLoading, isError, data, isFetching, isPreviousData } = useSegments(
    {
      modelId
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  const deleteSegmentHandler = (row: SegmentDefinitionType) => {
    const modalId = dialogTypes.CONFIRM_DELETE_SEGMENT;
    const deleteSegmentById = () => {
      const segmentId = row.id;
      deleteSegment.mutate({ segmentId });
    };

    const deleteRuleModal = (
      <DeleteMetricModal
        modalId={modalId}
        title="Delete this segment from MPM?"
        subTitle={DELETE_SEGMENT_BODY_TEXT(row.name)}
        submitHandler={deleteSegmentById}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, deleteRuleModal));
  };

  const editSegmentHandler = (row: SegmentDefinitionType) => {
    const modalId = dialogTypes.CONFIRM_EDIT_SEGMENT;
    const { id, name, segmentRule, segments, description } = row;
    const editSegmentByID = (formData: SegmentDataType) => {
      editSegment.mutate({ id, segmentData: formData });
    };
    const editData = {
      name,
      id,
      featureBreakdownId: segmentRule.feature?.id,
      segmentValues: segments.map(segment => segment.segmentValue),
      description
    };

    const editSegmentModal = (
      <CreateEditSegmentModal
        title="Edit Segment"
        modalId={modalId}
        editFormData={editData}
        submitHandler={editSegmentByID}
        type={MODAL_TYPE.EDIT}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, editSegmentModal));
  };

  const allowedCreateSegment = useMemo(
    () => rows.length < MAX_ALLOWED_SEGMENTS,
    [rows]
  );

  const actionsList = useMemo(
    () => [
      {
        title: 'Edit',
        icon: <EditIcon fill="#8C95A8" />,
        clickHandler: editSegmentHandler
      },
      {
        title: 'Delete',
        icon: <DeleteSecondaryIcon />,
        clickHandler: deleteSegmentHandler
      }
    ],
    []
  );

  const dataTypes = useMemo(
    () => [
      {
        cols: ['name', 'featureBreakdown', 'description'],
        cell: TextCell
      },
      {
        cols: ['createdAt'],
        cell: DateCell
      },
      {
        cols: ['actions'],
        cell: ({ row }: { row: SegmentDefinitionType }) => (
          <MoreActionsCell row={row} actionsList={actionsList} />
        )
      }
    ],
    []
  );

  const handleCreateSegment = () => {
    const modalId = dialogTypes.SUCCESS_SEGMENT_CREATED;
    const createSegmentHandler = (formData: SegmentDataType) => {
      createSegment.mutate({ segmentData: formData });
    };

    const createSegmentModal = (
      <CreateEditSegmentModal
        title="Create Segment"
        modalId={modalId}
        submitHandler={createSegmentHandler}
        type={MODAL_TYPE.CREATE}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, createSegmentModal));
  };

  useEffect(() => {
    if (data?.segmentDefinitions) {
      setRows(mapDataToRows(data?.segmentDefinitions));
    }
  }, [data]);

  if (isLoading)
    return <SmallLoader primaryMessage={<p>Fetching model segments</p>} />;

  if (isError) {
    return <h1> Error while fetching model segments. </h1>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="1"
      className="segments-wrapper"
    >
      <Box display="flex" justifyContent="space-between" marginBottom="31px">
        <span className="segment-subtitle">Segments</span>
        <Tooltip content={LIMITED_SEGMENTS_TOOLTIP_MESSAGE} placement="top">
          <span>
            <Button
              onClick={handleCreateSegment}
              disabled={!allowedCreateSegment}
              style={!allowedCreateSegment ? { pointerEvents: 'none' } : {}}
            >
              Create Segment
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Table
        isFetching={isFetching && isPreviousData}
        dataTypes={dataTypes}
        columns={SEGMENTS_COLUMNS}
        rows={rows}
        rowHeight="72px"
        rowIdKey="id"
        renderEmptyState={() => (
          <TableEmptyState
            label="There are no segments"
            pictureHeight="52px"
            pictureWidth="52px"
            picturePath="/images/noSegmentsIcon.svg"
            buttonText="Create Segment"
            clickHandler={handleCreateSegment}
            tooltipMessage={LIMITED_SEGMENTS_TOOLTIP_MESSAGE}
          />
        )}
        header={{
          headerColor: DSColors.grayColor1
        }}
        maxHeight={SEGMENTS_TABLE_HEIGHT}
        noPaddingColumns={CUSTOM_TRANSFORMATIONS_NO_PADDING_COLUMNS}
        rightColumns={RIGHT_COLUMNS}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          onColumnWidthsChange: setColumnWidths
        }}
        paginationConfig={{
          isDisabled: true
        }}
        selectionConfig={{
          isDisabled: true
        }}
      />
    </Box>
  );
};

export default Segments;
