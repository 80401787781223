import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';

import Paper from '@material-ui/core/Paper';
import {
  SELECT_PANEL_PATH,
  GALLERY_TAB,
  PREVIEW_TEMPLATE_ID
} from '@experiment-management-shared/constants/visualizationConstants';

import ChartTemplateActions from './ChartTemplateActions';
import ChartVote from './ChartVote';

const GALLERY_TAB_HASH = `${SELECT_PANEL_PATH}?${GALLERY_TAB}`;

const ChartTemplateRow = ({ activeTab, template, onChangeTemplate }) => {
  const history = useHistory();
  const location = useLocation();

  const {
    description = '-',
    id,
    lastSaved,
    name,
    thumbnail,
    type,
    owner = '-',
    rank = { templateId: '', voteCount: 0, userVoteType: '' },
    editable = false,
    galleryScopeType,
    codeLanguage = PANEL_CODE_LANGUAGES.JAVASCRIPT
  } = template;

  const isPy = codeLanguage === PANEL_CODE_LANGUAGES.PYTHON;

  const parsedHash = queryString.parse(location.hash);
  const { [GALLERY_TAB_HASH]: tabName } = parsedHash;
  const hashBase = `#${GALLERY_TAB_HASH}=${tabName}`;

  const handleOpenPreview = () => {
    const previewHash = `${hashBase}&${PREVIEW_TEMPLATE_ID}=${id}`;

    history.push({ ...location, hash: previewHash }, { preventScroll: true });
  };

  const renderActions = () => {
    return (
      <ChartTemplateActions
        isPy={isPy}
        id={id}
        name={name}
        type={type}
        editable={editable}
        onChangeTemplate={onChangeTemplate}
        onOpenExamples={handleOpenPreview}
        scope={galleryScopeType}
      />
    );
  };

  const renderDescription = () => {
    return (
      <div className="charts-gallery-row-description">{description || '-'}</div>
    );
  };

  const renderRankCell = () => {
    return (
      <div className="charts-gallery-vote">
        <ChartVote
          rank={rank}
          galleryScopeType={galleryScopeType}
          isUnrankable={isEmpty(rank.templateId)}
        />
      </div>
    );
  };

  const renderThumbnail = () => {
    return (
      <div className="charts-gallery-thumbnail-container">
        {!isPy && (
          <div
            className="charts-gallery-thumbnail-action"
            onClick={handleOpenPreview}
          >
            <div className="charts-gallery-thumbnail-action-inner">
              <span>Preview</span> <i className="material-icons">view_list</i>
            </div>
          </div>
        )}

        <img
          src={thumbnail}
          className="charts-gallery-thumbnail"
          style={{ borderRadius: 0 }}
          loading="lazy"
        />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="charts-gallery-row-title">
        <div className="charts-gallery-row-name">{name}</div>
        <div className="charts-gallery-row-created">
          Last saved: {moment(lastSaved).format('MMM DD, YY')} by {owner}
        </div>
      </div>
    );
  };

  return (
    <Paper className={cx('charts-gallery-row', activeTab)} elevation={3}>
      {renderRankCell()}
      {renderThumbnail()}
      {renderTitle()}
      {renderDescription()}
      {renderActions()}
    </Paper>
  );
};

ChartTemplateRow.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTemplate: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired
};

export default ChartTemplateRow;
