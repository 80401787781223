import useProject from '@API/project/useProject';
import useDeleteReportsMutation from '@reports/api/useDeleteReportsMutation';
import useReports from '@reports/api/useReports';
import ReportsHeader from '@reports/components/ReportsHeader';
import ReportsTable from '@reports/components/ReportsTable';
import { DEFAULT_ROWS_PER_PAGE, REPORT_COLUMNS } from '@reports/constants';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';
import defaultTo from 'lodash/defaultTo';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import projectsActions from '../../../../actions/projectsActions';
import SmallLoader from '@shared/components/SmallLoader';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '../../../../util/alertsUtil';
import styles from './ReportsPage.module.scss';

const { AUTHOR, DESCRIPTION, REPORT_NAME } = REPORT_COLUMNS;

const ReportsPage = () => {
  const { data: project } = useProject();
  const { data: reports = [], isLoading } = useReports();
  const deleteReportsMutation = useDeleteReportsMutation();
  const dispatch = useDispatch();
  const [selectedReports, setSelectedReports] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  useEffect(() => {
    dispatch(
      projectsActions.setActiveEMHeaderTab(EXPERIMENT_TAB_PATHNAME.reports)
    );

    return () => {
      dispatch(
        projectsActions.setActiveEMHeaderTab(
          EXPERIMENT_TAB_PATHNAME.experiments
        )
      );
    };
  }, [dispatch]);

  const filteredReports = useMemo(() => {
    if (isEmpty(searchText)) return reports;

    return reports.filter(
      ({
        [REPORT_NAME]: reportName,
        [AUTHOR]: author,
        [DESCRIPTION]: description
      }) => {
        const searchableContent = [
          reportName,
          author,
          description
        ].map(content => defaultTo(content, '').toLowerCase());

        return searchableContent.some(content =>
          content.includes(searchText.toLowerCase())
        );
      }
    );
  }, [searchText, reports]);

  const handleConfirmDelete = useCallback(() => {
    const reportIdsToDelete = selectedReports.map(reportId => {
      const report = find(reports, { reportId });
      return report.reportId;
    });

    deleteReportsMutation.mutate({ reportIds: reportIdsToDelete });
    setSelectedReports([]);

    dispatch(alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_REPORT));
  }, [dispatch, reports, selectedReports]);

  const handleDelete = useCallback(() => {
    dispatch(
      alertsUtil.openConfirmDialog(
        dialogTypes.CONFIRM_DELETE_REPORT,
        `This will delete the ${selectedReports.length} report(s)`,
        handleConfirmDelete,
        'Delete'
      )
    );
  }, [selectedReports, handleConfirmDelete, dispatch]);

  const handleSearch = useCallback(searchText => {
    const newSearchText = defaultTo(searchText, '');
    setSearchText(newSearchText);
    setPage(0);
  }, []);

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching reports"
      />
    );
  }

  return (
    <div className={styles.page}>
      <ReportsHeader
        canEdit={project?.canEdit}
        onDelete={handleDelete}
        reports={filteredReports}
        selectedReports={selectedReports}
        onSearch={handleSearch}
        table={{ page, rowsPerPage }}
      />

      <ReportsTable
        canEdit={project?.canEdit}
        rows={filteredReports}
        onSelect={setSelectedReports}
        selectedReports={selectedReports}
        searchText={searchText}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
};

export default ReportsPage;
