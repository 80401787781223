import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { TextButton } from '@ds';

import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';

import { trackEvent } from '@shared/utils/eventTrack';
import { panelEvents } from '@/constants/trackingEventTypes';
import { DSArrowRightIcon } from '@ds-icons';

const AddVisualizationModalBtn = ({ chartType, chartCategory }) => {
  const location = useLocation();
  const hash = `#${SELECT_PANEL_PATH}?chartCategory=${chartCategory}&chartType=${chartType}`;

  const handleLinkClick = useCallback(() => {
    if (chartType === BUILT_IN_CHART_TYPES.scalar) {
      trackEvent(panelEvents.OPEN_SCALAR_PANEL_ADDING);
    }
  }, [chartType]);

  return (
    <Link
      to={{
        ...location,
        hash
      }}
    >
      <TextButton
        component={Link}
        to={{
          ...location,
          hash
        }}
        onClick={handleLinkClick}
        PostfixIcon={<DSArrowRightIcon />}
      >
        Add
      </TextButton>
    </Link>
  );
};

AddVisualizationModalBtn.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartCategory: PropTypes.string.isRequired
};

export default AddVisualizationModalBtn;
