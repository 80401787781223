import React, { useEffect, useMemo, useState, Dispatch } from 'react';
import { RoundedTabs } from '@DesignSystem/navigation';
import cx from 'classnames';
import { difference, intersection, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import noop from 'lodash/noop';

import { Divider } from '@DesignSystem/data-display';
import { Table } from '@DesignSystem/tables';
import { InfoWithTooltipIcon, DismissMenuIcon } from '@Icons-outdated';
import { AlertNotification, ModelType } from '@mpm/types';
import AlertNotificationsActions from './AlertNotificationsActions';
import TextCell from '@shared/components/TableCells/TextCell';
import SmallLoader from '@shared/components/SmallLoader';
import { Tooltip } from '@ds';
import BadgeCell from '@shared/components/TableCells/BadgeCell';
import DateCell from '@shared/components/TableCells/DateCell';
import MoreActionsCell from '@shared/components/TableCells/MoreActionsCell';
import TableEmptyState from '@shared/components/TableEmptyState';
import {
  useAlertNotifications,
  useAlertNotificationsByID,
  useDismissAlertNotification
} from '@mpm/api';
import {
  ALERT_NOTIFICATIONS_COLUMNS,
  ALERT_NOTIFICATIONS_LEFT_COLUMN,
  ALERT_NOTIFICATIONS_NAME_COLUMN_WIDTH,
  ALERT_NOTIFICATIONS_PAGE_SIZES,
  ALERT_NOTIFICATIONS_PREVIEW_PAGE_SIZES,
  ALERT_NOTIFICATIONS_STATUSES,
  DEFAULT_ALERT_NOTIFICATIONS_SORTING,
  disabledNotificationSortingColumns,
  NOTIFICATIONS_INFO_TOOLTIP_MESSAGE,
  uniqueRowIdKey
} from '@mpm/constants';
import { formatToLocal } from '../../utils';

type AlertNotificationsTableCellType = {
  value: string | null;
  row: AlertNotification;
};

type TableDataType = {
  cols: [string];
  cell: React.FC<AlertNotificationsTableCellType>;
};

const badgeColorsConfig = {
  open: { color: '#fff', backgroundColor: '#f14668' },
  resolved: { color: '#fff', backgroundColor: '#00d41f' }
} as const;

const disabledColumns = ['name', 'trigger'] as const;

type AlertNotificationsPageProps = {
  model: ModelType;
  previewPage?: boolean;
  dataTracker?: Dispatch<boolean>;
};

export const AlertNotificationsPage = ({
  model,
  previewPage = false,
  dataTracker = noop
}: AlertNotificationsPageProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [columnSorting, setColumnSorting] = useState(
    DEFAULT_ALERT_NOTIFICATIONS_SORTING
  );
  const [selection, setSelection] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(ALERT_NOTIFICATIONS_PAGE_SIZES[0]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [rows, setRows] = useState<AlertNotification[]>([]);
  const ALERT_RULES_ACTIONS_COLUMN_WIDTH = previewPage ? 25 : 33;

  const [totalNotifications, setTotalNotifications] = useState(0);
  const [activeOpenStatusIds, setActiveOpenStatusIds] = useState<string[]>([]);
  const [
    openStatusSelectedNotifications,
    setOpenStatusSelectedNotifications
  ] = useState<string[]>([]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'name', width: ALERT_NOTIFICATIONS_NAME_COLUMN_WIDTH },
    { columnName: 'actions', width: ALERT_RULES_ACTIONS_COLUMN_WIDTH }
  ]);

  const noPaddingColumns = ['actions', ...(previewPage ? [] : ['name'])];

  const tableHeight = selection.length > 0 ? 336 : 276;

  const { modelId } = useParams<{ modelId: string }>();

  const dismissAlertNotification = useDismissAlertNotification();

  useEffect(() => {
    const selectedOpenStatusActiveRows = intersection(
      activeOpenStatusIds,
      selection
    );
    const prevSelectedOpenRows = difference(
      openStatusSelectedNotifications,
      activeOpenStatusIds
    );
    setOpenStatusSelectedNotifications([
      ...prevSelectedOpenRows,
      ...selectedOpenStatusActiveRows
    ]);
  }, [selection]);

  useEffect(() => {
    setActiveOpenStatusIds(
      rows
        .filter(
          row => row.status.toLowerCase() === ALERT_NOTIFICATIONS_STATUSES[0]
        )
        .map(row => row.id)
    );
  }, [rows]);

  useEffect(() => {
    if (previewPage) {
      setPageSize(ALERT_NOTIFICATIONS_PREVIEW_PAGE_SIZES);
    }
  }, [previewPage]);

  const dismissCallback = (notificationIds: string[]): void => {
    setOpenStatusSelectedNotifications(prevNotifications =>
      difference(prevNotifications, notificationIds)
    );
    setSelection(selected => difference(selected, notificationIds));
  };

  const handleDismiss = (row: AlertNotification) => {
    const notificationIds = [row.id];
    dismissAlertNotification.mutate({
      notificationIds,
      dismissCallback
    });
  };

  const actionsList = useMemo(
    () => [
      {
        title: 'Dismiss Alert',
        subtitle: 'Mark as resolved',
        icon: <DismissMenuIcon />,
        clickHandler: handleDismiss
      }
    ],
    []
  );

  const dataTypes = useMemo<TableDataType[]>(
    () => [
      {
        cols: ['name'],
        cell: ({ row }) => {
          const { notificationDate, trigger } = row || {};
          const value =
            trigger &&
            `${formatToLocal(
              notificationDate,
              'YYYY-MM-DD HH:mm:ss'
            )} - ${trigger}`;
          return <TextCell value={value} />;
        }
      },
      {
        cols: ['status'],
        cell: ({ value }) => (
          <BadgeCell colorsConfig={badgeColorsConfig} value={value} />
        )
      },
      {
        cols: ['notificationDate'],
        cell: ({ value }) => (
          <DateCell
            value={value}
            formatter={value => {
              return formatToLocal(value, 'YYYY-MM-DD HH:mm:ss');
            }}
          />
        )
      },
      {
        cols: ['modelVersion'],
        cell: ({ value }) => <TextCell value={value || 'All'} />
      },
      {
        cols: ['actions'],
        cell: ({ row }) => {
          const disabledNotificationOption =
            row.status.toLowerCase() ===
            ALERT_NOTIFICATIONS_STATUSES[1].toLowerCase();
          return (
            <MoreActionsCell
              row={row}
              actionsList={actionsList}
              isDisabled={disabledNotificationOption}
            />
          );
        }
      }
    ],
    []
  );

  const {
    isLoading,
    isError,
    data,
    isFetching,
    isPreviousData
  } = useAlertNotifications(
    {
      modelId,
      page: pageNumber + 1,
      pageSize
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  useEffect(() => {
    if (data && data.alertNotifications.length > 0) {
      setTotalNotifications(
        data.closedNotificationsTotal + data.openNotificationsTotal
      );
      /* tslint:disable-next-line */
      setRows(data.alertNotifications);
    }
  }, [data]);

  useEffect(() => {
    if (typeof dataTracker === 'function') {
      if (data && data.alertNotifications.length > 0 && previewPage) {
        dataTracker(true);
      } else if (previewPage) {
        dataTracker(false);
      }
    }
  }, [data, previewPage, dataTracker]);

  useEffect(() => {
    if (isEmpty(selection)) {
      setActiveTabIndex(0);
    }
  }, [selection]);

  const {
    data: selectionRowsData,
    isLoading: isLoadingSelection
  } = useAlertNotificationsByID(
    {
      modelId,
      selectedAlertNotificationIds: selection
    },
    { enabled: activeTabIndex === 1 }
  );

  useEffect(() => {
    setPageNumber(0);
  }, [pageSize]);

  const rowsFilteredForActiveTab = useMemo(() => {
    if (activeTabIndex === 1 && selectionRowsData) {
      return selectionRowsData.alertNotifications;
    }

    return rows;
  }, [activeTabIndex, rows, selectionRowsData]);

  if (isLoading)
    return (
      <SmallLoader
        primaryMessage={
          <p>
            Fetching alert notifications for <b>{model.name}</b>
          </p>
        }
      />
    );

  if (isError)
    return <h1> Error while fetching the model`s alert notifications. </h1>;

  return (
    <>
      <div
        className={cx('model-features-header', {
          'alerts-preview-header': previewPage
        })}
      >
        <div className="model-features-header-top">
          <div className="model-features-header-title">Alert Notifications</div>
          {previewPage && totalNotifications > 0 && (
            <Tooltip
              content={NOTIFICATIONS_INFO_TOOLTIP_MESSAGE}
              placement="top"
            >
              <span>
                <InfoWithTooltipIcon className="alerts-info-icon" />
              </span>
            </Tooltip>
          )}
        </div>
        {!previewPage && (
          <>
            <Divider margin={0} />
            <div className="model-features-header-tabs">
              <RoundedTabs
                tabs={[
                  { label: 'All' },
                  {
                    label: selection.length
                      ? `Selected (${selection.length})`
                      : 'Selected',
                    disabled: selection.length === 0
                  }
                ]}
                activeTabIndex={activeTabIndex}
                onTabChange={setActiveTabIndex}
              />
            </div>
          </>
        )}
      </div>
      <Table
        shouldHighlightLinks
        noPaddingColumns={noPaddingColumns}
        rightColumns={['actions']}
        isFetching={(isFetching && isPreviousData) || isLoadingSelection}
        dataTypes={dataTypes}
        columns={ALERT_NOTIFICATIONS_COLUMNS}
        maxHeight={tableHeight}
        rows={rowsFilteredForActiveTab}
        leftColumns={ALERT_NOTIFICATIONS_LEFT_COLUMN}
        rowHeight="60px"
        rowIdKey={uniqueRowIdKey}
        renderEmptyState={props => (
          <TableEmptyState
            label="You have no open alert notifications, awesome!"
            materialProps={props}
            pictureHeight="56px"
            pictureWidth="62px"
            picturePath="/images/alert-notifications-empty.svg"
          />
        )}
        totalRowCount={
          activeTabIndex === 0
            ? totalNotifications
            : rowsFilteredForActiveTab.length
        }
        sortingConfig={{
          isDisabled: false,
          columnSorting,
          disabledColumns: disabledNotificationSortingColumns,
          onSortingChange: setColumnSorting
        }}
        paginationConfig={{
          isDisabled: previewPage,
          isServerSidePagination: activeTabIndex !== 1,
          pageNumber,
          pageSize,
          pageSizes: ALERT_NOTIFICATIONS_PAGE_SIZES,
          onPageNumberChange: setPageNumber,
          onPageSizeChange: setPageSize
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          disabledColumns,
          onColumnWidthsChange: setColumnWidths
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        selectionConfig={{
          selection,
          isDisabled: previewPage,
          onSelectionChange: setSelection
        }}
      />
      {selection.length > 0 && (
        <AlertNotificationsActions
          selectedItemsIds={selection}
          openStatusNotifications={openStatusSelectedNotifications}
          dismissCallback={dismissCallback}
        />
      )}
    </>
  );
};
