import '@experiment-management-shared/components/Charts/NoPlotlyCharts/DataPanel/DataPanel.scss';
import React from 'react';

export const NoLoggedDataView = ({ title, body, linkText, link, icon }) => {
  return (
    <div className="data-panel-no-data-container">
      <div className="card">
        {icon}
        <h3>{title}</h3>
        <p>
          {body}
          <br />
          <span>
            <a href={link}>{linkText}</a>
          </span>
        </p>
      </div>
    </div>
  );
};
