import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  generateEmptyRulesTree,
  getOperatorsForRule,
  getRulesCount
} from '@shared/utils/filterHelpers';
import FiltersSidebar from '@shared/components/FiltersSidebar';

import { constructFilterDefinition } from '@experiment-management-shared/utils/constructFilterDefinition';
import useFiltersLibraryManagement from '@experiment-management-shared/hooks/useFiltersLibraryManagement';
import { FILTER_BEHAVIOUR_MODE } from '@shared/components/QueryFiltersBody/QueryFiltersBody';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import useTrackEventLocation from '@shared/hooks/useTrackEventLocation';
import { dashboardEvents } from '@/constants/trackingEventTypes';

const ExperimentsFiltersSidebarContainer = ({
  isOpen,
  onChange,
  onClose,
  query
}) => {
  const { rulesTree, segmentId } = query;
  const baseTrackEvent = useBaseTrackEvent();
  const trackEventLocation = useTrackEventLocation();

  const updateFilters = useCallback(
    updatedQuery => {
      const rulesCount = getRulesCount(updatedQuery.rulesTree);
      if (rulesCount > 0) {
        baseTrackEvent(dashboardEvents.PROJECT_FILTER, {
          location: trackEventLocation,
          number_of_filter_conditions: rulesCount,
          or_filtering: updatedQuery.rulesTree.rules.length > 1
        });
      }
      onChange(updatedQuery);
    },
    [baseTrackEvent, onChange, trackEventLocation]
  );

  const handleFilterUpdate = useCallback(
    (newFilterId, newRulesTree = null) => {
      const updatedQuery = {
        ...query,
        segmentId: newFilterId
      };

      if (newRulesTree) {
        updatedQuery.rulesTree = newRulesTree;
      }

      updateFilters(updatedQuery);
    },
    [query, updateFilters]
  );

  const {
    isLoading,
    columns,
    filters,
    activeFilter,
    handleSaveFilter,
    handleSelectFilter,
    handleDeleteFilter
  } = useFiltersLibraryManagement({
    filterId: segmentId,
    rulesTree,
    onChange: handleFilterUpdate
  });

  if (isLoading) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  const handleChangeFilters = newRulesTree => {
    updateFilters({ rulesTree: newRulesTree, segmentId });
  };

  const handleClearQuery = () => {
    updateFilters({ rulesTree: generateEmptyRulesTree(), segmentId: '' });
  };

  return (
    <FiltersSidebar
      isOpen={isOpen}
      onChange={handleChangeFilters}
      onClose={handleClose}
      columns={columns}
      editable
      rulesTree={rulesTree}
      filters={filters}
      activeFilter={activeFilter}
      filterMode={FILTER_BEHAVIOUR_MODE.OR_AND}
      onClearQuery={handleClearQuery}
      onSaveQuery={handleSaveFilter}
      onDeleteFilter={handleDeleteFilter}
      onSelectFilter={handleSelectFilter}
      getOperatorsForRule={getOperatorsForRule}
      constructFilterDefinition={constructFilterDefinition}
    />
  );
};

ExperimentsFiltersSidebarContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired
};

export default ExperimentsFiltersSidebarContainer;
