import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import debounce from 'lodash/debounce';

import { MAX_DECIMAL_PRECISION } from '@experiment-management-shared/constants/experimentGridConstants';
import { useComputeClearCacheMutation } from '@experiment-management-shared/api';

import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import { TextButton, Button } from '@ds';
import { DSOptionsIcon } from '@ds-icons';
import { withStyles } from '@material-ui/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { LinkIcon, TipIcon } from '@Icons-outdated';

import useProject from '@API/project/useProject';
import { snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

const StyledSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 18,
    marginRight: 8,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(10px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 11,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

const SLIDER_CHANGE_DEBOUNCE_TIME = 300;

const DashboardOptionsButton = ({ dashboard, onChange }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data: project } = useProject();
  const [anchorEl, setAnchorEl] = useState(null);

  const [currentDecimalPrecision, setCurrentDecimalPrecision] = useState(
    dashboard.table.decimalsPrecision ?? MAX_DECIMAL_PRECISION
  );

  const computeClearCacheMutation = useComputeClearCacheMutation(
    project?.projectId
  );

  useEffect(() => {
    setCurrentDecimalPrecision(
      dashboard.table.decimalsPrecision ?? MAX_DECIMAL_PRECISION
    );
  }, [dashboard.table.decimalsPrecision]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveDecimalPrecisionChange = useMemo(() => {
    return debounce(newValue => {
      onChange({
        table: { decimalsPrecision: newValue }
      });
    }, SLIDER_CHANGE_DEBOUNCE_TIME);
  }, [onChange]);

  const handleCopyClick = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_SHARE_LINK,
        'Successfully copied share link to clipboard'
      )
    );
  };

  const handleDecimalPrecisionChange = (event, newValue) => {
    saveDecimalPrecisionChange(newValue);
    setCurrentDecimalPrecision(newValue);
  };

  const handleToggleAutoRefresh = () => {
    onChange({
      panels: {
        isAutoRefreshEnabled: !dashboard.panels.isAutoRefreshEnabled
      }
    });
  };

  const refreshDashboard = async () => {
    const projectId = project?.projectId;
    const projectName = project?.projectName;
    await queryClient.invalidateQueries(['experiments', { projectId }]);
    await queryClient.invalidateQueries(['panel', { projectId }]);
    await queryClient.invalidateQueries(['aggregations', { projectId }]);
    await queryClient.invalidateQueries([
      'custom-panel-server-url',
      { projectName }
    ]);

    computeClearCacheMutation.mutate();
  };

  const currentURL = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <TextButton
        type="secondary"
        size="medium"
        PrefixIcon={<DSOptionsIcon />}
        onClick={handleClick}
        active={Boolean(anchorEl)}
      >
        Options
      </TextButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        classes={{ paper: 'dashboard-options-popover' }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: -6,
          horizontal: 'right'
        }}
      >
        <div className="dashboard-options-popover-section">
          <div className="dashboard-options-popover-title">Share</div>

          <div className="dashboard-options-popover-content dashboard-options-popover-content-share">
            <div className="dashboard-options-popover-share-container">
              <div className="dashboard-options-popover-share-input">
                <LinkIcon />
                <span className="dashboard-options-popover-share-url">
                  {currentURL}
                </span>
              </div>

              <CopyToClipboard text={currentURL}>
                <Button onClick={handleCopyClick} type="primary">
                  Copy
                </Button>
              </CopyToClipboard>
            </div>

            <div className="dashboard-options-popover-share-tip">
              <TipIcon />

              <div className="dashboard-options-popover-share-tip-text">
                <span className="dashboard-options-popover-share-tip-text-title">
                  Tip:
                </span>{' '}
                In order to change permissions, go to the manage tab
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div className="dashboard-options-popover-section">
          <div className="dashboard-options-popover-title">
            Decimal precision ({currentDecimalPrecision})
          </div>

          <div className="dashboard-options-popover-content">
            <Slider
              min={0}
              step={1}
              max={MAX_DECIMAL_PRECISION}
              onChange={handleDecimalPrecisionChange}
              value={currentDecimalPrecision}
            />
          </div>
        </div>

        <Divider />

        <div className="dashboard-options-popover-section">
          <div className="dashboard-options-popover-title">Data</div>

          <div className="dashboard-options-popover-content">
            <FormControlLabel
              classes={{
                label: 'dashboard-options-refresh-label',
                root: 'dashboard-options-refresh'
              }}
              control={
                <StyledSwitch
                  checked={dashboard.panels.isAutoRefreshEnabled}
                  color="primary"
                  onChange={handleToggleAutoRefresh}
                />
              }
              label="Auto Refresh"
            />

            <TextButton
              className="dashboard-options-refresh-button"
              onClick={refreshDashboard}
              type="tertiary"
            >
              Refresh now
            </TextButton>
          </div>
        </div>
      </Popover>
    </>
  );
};

DashboardOptionsButton.propTypes = {
  dashboard: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DashboardOptionsButton;
