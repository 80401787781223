import { useQuery } from 'react-query';

import { Metric } from '@shared/types';
import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import api, { QueryConfig } from '@shared/api';

type MetricsResponse = {
  results: Metric[];
  version: string;
};

type GetExperimentMetricsParams = {
  experimentKey: string;
  signal?: AbortSignal;
};

const getExperimentMetrics = async ({
  experimentKey,
  signal
}: GetExperimentMetricsParams) => {
  const { data } = await api.get<MetricsResponse>('data-fetch/get/metrics', {
    params: { experimentKey },
    signal
  });

  return data.results;
};

type UseExperimentMetricsParams = {
  experimentKey: string;
  isActive?: boolean;
};

// @todo: merge with the other `useExperimentMetrics` hook
export default function useExperimentMetrics(
  { experimentKey, isActive = false }: UseExperimentMetricsParams,
  config?: QueryConfig<Metric[]>
) {
  return useQuery(
    ['experiment-metrics', { experimentKey }],
    ({ signal }) => {
      return getExperimentMetrics({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      refetchInterval: isActive ? FETCH_EXPERIMENT_POLLING_LENGTH : false,
      ...config
    }
  );
}
