import { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {
  CHART_BASIC_LAYOUT,
  transformNumericalDistributionToPlotData
} from '@mpm/utils';
import { CHART_TYPES, PANEL_SECTIONS } from '../constants';
import { useNumericalDistribution } from '../api';
import { IntervalType, SegmentColorId } from '@mpm/types';

const initialState = {
  panelData: [],
  layoutData: {
    ...CHART_BASIC_LAYOUT,
    spikedistance: -1,
    hoverdistance: -1,
    hovermode: 'closest',
    xaxis: {
      ...CHART_BASIC_LAYOUT.xaxis,
      type: 'linear',
      range: [0, 1],
      zeroline: false
    },
    yaxis: {
      ...CHART_BASIC_LAYOUT.yaxis,
      showgrid: false,
      showticklabels: false
    }
  },
  chartType: null
};

const defaultState = {
  plotData: initialState,
  numericalLoading: false,
  numericalError: false
};

type MPMPNumericalDistributionProps = {
  from: string;
  to: string;
  intervalType: IntervalType;
  version: string;
  segmentColorIds: SegmentColorId[];
  hoverLineData: { segmentId: string };
  propFeatureId?: string;
};

type MPMPNumericalDistributionConfig = {
  enabled: boolean;
  keepPreviousData: boolean;
};

export function useMPMNumericalDistribution(
  {
    from,
    to,
    intervalType,
    version,
    segmentColorIds,
    hoverLineData,
    propFeatureId
  }: MPMPNumericalDistributionProps,
  config: MPMPNumericalDistributionConfig
) {
  const [plotData, setPlotData] = useState<typeof initialState>(initialState);

  const segmentIds = useMemo(() => segmentColorIds.map(item => item.id), [
    segmentColorIds
  ]);

  const {
    data: numericalDistributionData,
    isLoading: numericalLoading,
    isFetching: numericalFetching,
    isError: numericalError
  } = useNumericalDistribution(
    {
      section: PANEL_SECTIONS.NUMERICAL_DISTRIBUTION,
      from,
      to,
      intervalType,
      version,
      segmentId: segmentIds,
      propFeatureId
    },
    {
      ...config
    }
  );

  const debouncedPlotDataSetter = useCallback(debounce(setPlotData, 50), []);

  const chartType = CHART_TYPES.NUMERICAL_DISTRIBUTION;

  const noDataTimeRange = useMemo(() => {
    const { segmentId } = hoverLineData || {};
    if (numericalDistributionData?.data.length) {
      const id = segmentId || 'all';
      const segmentData =
        numericalDistributionData.data.find(item => item?.segment.id === id) ||
        numericalDistributionData.data.find(item => !isEmpty(item));
      if (!segmentData) return [0, 1];
      const xPoints = segmentData.graphs.wholeTimerangeDistribution.graphPoints.map(
        point => point.x
      );
      if (!xPoints?.length) return [0, 1];
      const min = Math.min(...xPoints);
      const max = Math.max(...xPoints);
      return [min, max];
    }

    return [0, 1];
  }, [numericalDistributionData, hoverLineData]);

  useEffect(() => {
    if (numericalDistributionData?.data.length) {
      const result = transformNumericalDistributionToPlotData({
        serverResponseData: numericalDistributionData,
        hoverLineData,
        segmentColorIds
      });
      const isEmptyData =
        isEmpty(result.lineData[0]) || isEmpty(result.lineData[0]?.x);
      const layoutData = {
        ...CHART_BASIC_LAYOUT,
        spikedistance: -1,
        hoverdistance: -1,
        hovermode: 'closest',
        xaxis: {
          ...CHART_BASIC_LAYOUT.xaxis,
          type: 'linear',
          range: isEmptyData ? noDataTimeRange : 'auto',
          zeroline: false,
          tickformat: '.5'
        },
        yaxis: {
          ...CHART_BASIC_LAYOUT.yaxis,
          showgrid: false,
          showticklabels: false
        }
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      debouncedPlotDataSetter(prev => {
        return { ...prev, panelData: result.lineData, layoutData, chartType };
      });
      return;
    }

    return debouncedPlotDataSetter(initialState);
  }, [
    numericalDistributionData,
    hoverLineData,
    chartType,
    segmentColorIds,
    debouncedPlotDataSetter,
    noDataTimeRange
  ]);

  if (!config?.enabled) {
    return defaultState;
  }

  return {
    plotData,
    numericalLoading: numericalLoading || numericalFetching,
    numericalError
  };
}
