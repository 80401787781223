import { useQuery } from 'react-query';
import { IS_EMAIL_NEEDED } from '../../lib/appConstants';
import { axiosInstance, cancelWrapper } from '../api';

const getIsUserVerified = async () => {
  return cancelWrapper(async cancelToken => {
    if (!IS_EMAIL_NEEDED) {
      return true;
    }

    const {
      data: { verified }
    } = await axiosInstance({
      cancelToken,
      method: 'get',
      url: 'auth/email/verification/get'
    });

    return verified;
  });
};

export default function useIsUserVerified() {
  return useQuery(['is-user-verified'], getIsUserVerified, {
    refetchOnMount: true
  });
}
