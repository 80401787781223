import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import organizationApi from '@shared/api/organizationApi';

import alertsUtil from '../../../../util/alertsUtil';
import { snackbarTypes } from '../../../../constants/alertTypes';

const removeUserFromOrganization = ({ organizationId, username }) =>
  organizationApi.removeMember({ organizationId, username });

const useRemoveUserFromOrganizationMutation = organizationId => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    username => removeUserFromOrganization({ organizationId, username }),
    {
      onSuccess: (resp, username) => {
        queryClient.invalidateQueries(['organizations', organizationId], {
          active: true
        });
        queryClient.resetQueries(['organizations', organizationId], {
          inactive: true
        });

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_REMOVE_USERS_FROM_ORGANIZATION,
            `You have successfully deleted “${username}” from your organization`
          )
        );
      },
      onError: e => {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_PAYMENT_CHANGED,
            e?.response?.data?.msg || e?.message
          )
        );
      }
    }
  );
};

export default useRemoveUserFromOrganizationMutation;
