import { useQuery } from 'react-query';

import { axiosInstance, cancelWrapper } from '../api';

const getPanelTemplateRevisions = async () =>
  cancelWrapper(async cancelToken => {
    const {
      data: { resources }
    } = await axiosInstance.get(
      `code-visualizations/template/code/resources/defaults/get`,
      { cancelToken }
    );

    return resources;
  });

export default function usePanelTemplateInternalResources() {
  return useQuery(['panelTemplateInternalResources'], () =>
    getPanelTemplateRevisions()
  );
}
