import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import artifactsApi from '@/util/artifactsApi';
import modelRegistryApi from '@/util/modelRegistryApi';
import experimentApi from '@experiment-management-shared/api/experimentApi';

export const getRedirectUrl = (
  resource: REDIRECT_LINK_RESOURCES,
  args: Array<string>
) => {
  let getRedirectURL;

  switch (resource) {
    case REDIRECT_LINK_RESOURCES.ARTIFACT:
      getRedirectURL = artifactsApi.getRedirectURL.bind(artifactsApi, ...args);
      break;
    case REDIRECT_LINK_RESOURCES.ARTIFACT_VERSION:
      getRedirectURL = artifactsApi.getVersionRedirectURL.bind(
        artifactsApi,
        ...args
      );
      break;
    case REDIRECT_LINK_RESOURCES.MODEL_REGISTRY:
      getRedirectURL = modelRegistryApi.getRedirectURL.bind(
        modelRegistryApi,
        ...args
      );
      break;
    case REDIRECT_LINK_RESOURCES.EXPERIMENT:
    default:
      getRedirectURL = experimentApi.getRedirectURL.bind(
        experimentApi,
        ...args
      );
      break;
  }

  return getRedirectURL();
};
