import cx from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import './Input.scss';

const DEFAULT = 'default';
const UNDERLINED = 'underlined';

export const DS_INPUT_TYPES = {
  DEFAULT,
  UNDERLINED
};

const Input = (
  {
    onChange,
    multiline,
    type,
    required,
    InlinePrefixIcon,
    PrefixIcon,
    PostfixIcon,
    withoutBorder,
    title,
    height,
    disabled,
    asButton,
    invalidMsg,
    helpMsg,
    invalidBorder,
    lineHeight,
    fontSize,
    onClick,
    onKeyDown,
    postfixLabel,
    onPostfixIconClick,
    inputType,
    ...inputProps
  },
  inputRef
) => {
  const handleChange = event => {
    onChange(event.target.value, event);
  };

  return (
    <div className="ds-input-wrapper" onClick={onClick}>
      {title && (
        <p className="ds-input-title">
          {title} {required && <span style={{ color: 'red' }}>*</span>}
        </p>
      )}
      <div
        className={cx('ds-input', {
          'as-button': asButton,
          'with-postfix-label': Boolean(postfixLabel),
          'with-postfix-icon': Boolean(PostfixIcon),
          [type]: true,
          disabled
        })}
      >
        {PostfixIcon && (
          <div
            className={cx('postfix-icon-container', {
              'clickable-icon': onPostfixIconClick
            })}
          >
            <PostfixIcon
              className="input-postfix-icon"
              {...(onPostfixIconClick && { onClick: onPostfixIconClick })}
            />
          </div>
        )}
        {postfixLabel && (
          <div className="input-postfix-label">{postfixLabel}</div>
        )}

        {PrefixIcon && <PrefixIcon className="input-prefix-icon" />}
        {InlinePrefixIcon && (
          <InlinePrefixIcon className="inline-input-prefix-icon" />
        )}
        {multiline ? (
          <textarea
            ref={inputRef}
            onChange={handleChange}
            disabled={disabled}
            style={{ lineHeight, height, fontSize }}
            className={cx(
              {
                invalid: invalidBorder
              },
              inputProps?.className
            )}
            {...inputProps}
          />
        ) : (
          <input
            className={cx({
              'without-border': withoutBorder,
              invalid: invalidBorder,
              'with-inline-prefix-icon': Boolean(InlinePrefixIcon),
              'not-focusable': asButton || withoutBorder
            })}
            ref={inputRef}
            disabled={disabled}
            onChange={handleChange}
            style={{ lineHeight, height, fontSize }}
            onKeyDown={onKeyDown}
            readOnly={asButton}
            type={inputType}
            {...inputProps}
          />
        )}
      </div>
      {invalidMsg && <p className="invalid-label">{invalidMsg}</p>}
      {!invalidMsg && helpMsg && <p className="helper-label">{helpMsg}</p>}
    </div>
  );
};

Input.defaultProps = {
  placeholder: '',
  onChange: noop,
  value: '',
  PrefixIcon: null,
  InlinePrefixIcon: null,
  PostfixIcon: null,
  withoutBorder: false,
  title: null,
  helpMsg: null,
  required: false,
  multiline: false,
  disabled: false,
  asButton: false,
  invalidMsg: null,
  invalidBorder: false,
  height: '',
  lineHeight: '',
  fontSize: '',
  onClick: noop,
  onPostfixIconClick: null,
  onKeyDown: noop,
  inputType: null,
  postfixLabel: null,
  type: DS_INPUT_TYPES.DEFAULT
};

Input.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onPostfixIconClick: PropTypes.func,
  value: PropTypes.string,
  PrefixIcon: PropTypes.elementType,
  InlinePrefixIcon: PropTypes.elementType,
  PostfixIcon: PropTypes.elementType,
  withoutBorder: PropTypes.bool,
  helpMsg: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
  inputType: PropTypes.string,
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  asButton: PropTypes.bool,
  invalidMsg: PropTypes.string,
  invalidBorder: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  postfixLabel: PropTypes.string,
  type: PropTypes.oneOf(Object.values(DS_INPUT_TYPES))
};

export default forwardRef(Input);
