import React, { useMemo } from 'react';
import ChartContainer, {
  GeneralChartContainerProps
} from '../../Chart/ChartContainer/ChartContainer';
import {
  Experiment,
  MetricColorMap,
  PanelType,
  PythonPanel as PythonPanelType
} from '@experiment-management-shared/types';
import {
  BUILT_IN_CHART_TYPES,
  PROJECT_TOKEN_REFRESH_INTERVAL,
  PYTHON_PANEL_REFETCH_INTERVAL,
  PYTHON_PANEL_RETRY_COUNT,
  PYTHON_PANEL_RETRY_DELAY,
  chartDataErrorTypes
} from '@experiment-management-shared/constants';
import {
  useIsServerCustomPanelsEnabled,
  usePanelConfigs
} from '@experiment-management-shared/hooks';
import {
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
} from '../../Chart/useChartHeaderMenu';
import {
  usePythonPanelCode,
  usePythonPanelURL
} from '@experiment-management-shared/api';
import useProjectToken from '@API/panels/useProjectToken';
import useProject from '@API/project/useProject';
import { CodeIframe } from '@experiment-management-shared/components/CodeIframe';
import classes from './PythonPanel.module.scss';
import difference from 'lodash/difference';
import { generatePythonPanelCodeVersion } from '@experiment-management-shared/utils';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import { getExperimentsColorMap } from '@experiment-management-shared/utils/experimentHelpers';
import useRefSizes from '@shared/hooks/useRefSizes';
import { useIsUserLoggedIn } from '@shared/hooks';

const HIDDEN_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  RESET_ZOOM,
  EMBED_PANEL,
  SAMPLE_SIZE,
  SHARE_PANEL
];

const BADGES = [{ label: 'Python panel' }];

type PythonPanelProps = {
  chartId: string;
  containerProps: GeneralChartContainerProps;
  isChartPreview?: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  hiddenExperimentKeys?: string[];
  isAutoRefreshEnabled: boolean;
  title?: string;
  chartName?: string;
  hiddenMenuItems?: string[];
  metricColorMap: MetricColorMap;
} & Partial<PythonPanelType>;

const PythonPanel = ({
  containerProps,
  experiments,
  experimentKeys,
  hiddenExperimentKeys = [],
  isAutoRefreshEnabled,
  title,
  chartName = '',
  code: editCode,
  codeVersion: editCodeVersion = '',
  templateId,
  revisionId,
  chartId,
  metricColorMap
}: PythonPanelProps) => {
  const { sizeRef: codeIframeRef, refWidth, refHeight } = useRefSizes({
    debounceWait: 1500
  });

  const isUserLoggedIn = useIsUserLoggedIn();

  const chartType = BUILT_IN_CHART_TYPES.python as PanelType;
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();

  const { actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    title,
    chartName,
    chartType
  });

  const experimentColorMap = useDeepMemo(
    () => getExperimentsColorMap(experiments),
    [experiments]
  );

  const isFromEditCode = !!editCode;

  const {
    data: revisionCode = null,
    isLoading: isLoadingRevisionCode,
    isFetching: isFetchingRevisionCode,

    isError: isErrorRevisionCode
  } = usePythonPanelCode(
    { templateId, revisionId },
    {
      enabled: !!(!isFromEditCode && templateId && revisionId),
      refetchOnMount: true
    }
  );

  const panelCode = isFromEditCode ? editCode : revisionCode;

  const codeVersion = useMemo(() => {
    // needed for open edit / add gallery
    if (editCodeVersion) {
      return editCodeVersion;
    }

    // needed for refreshing streamlit after updating panel
    if (revisionCode !== null) {
      return generatePythonPanelCodeVersion();
    }

    return '';
  }, [editCodeVersion, revisionCode]);

  const visibleExperimentKeys = useMemo(
    () => difference(experimentKeys, hiddenExperimentKeys),
    [experimentKeys, hiddenExperimentKeys]
  );

  const {
    data: projectToken,
    isLoading: isLoadingProjectToken,
    isFetching: isFetchingProjectToken,
    isError: isLoadingProjectTokenError
  } = useProjectToken(
    { chartId },
    {
      refetchInterval: PROJECT_TOKEN_REFRESH_INTERVAL,
      refetchOnMount: true
    }
  );

  const { data: project, isLoading: isLoadingProject } = useProject();

  const projectName = project?.projectName || '';
  const workspaceName = project?.teamName || '';

  const {
    data: pythonPanelServerURL,
    isLoading: isLoadingPythonPanelServer,
    isError: pythonPanelServerURLError,
    isFetching: isPythonPanelURLFetching,
    isPreviousData: isPythonPanelPreviousData,
    error: pythonPanelServerError,
    isComputeEngineLoading
  } = usePythonPanelURL(
    {
      code: panelCode || '',
      instanceId: chartId,
      projectName,
      experimentKeys: visibleExperimentKeys,
      options: {},
      workspaceName,
      revisionId,
      projectToken,
      codeVersion,
      metricColorMap,
      experimentColorMap,
      panelWidth: refWidth,
      panelHeight: refHeight
    },
    {
      enabled: !!(
        panelCode &&
        projectName &&
        workspaceName &&
        visibleExperimentKeys &&
        projectToken &&
        isServerCustomPanelsEnabled &&
        refWidth &&
        refHeight
      ),
      retry: PYTHON_PANEL_RETRY_COUNT,
      refetchInterval: PYTHON_PANEL_REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      retryDelay: PYTHON_PANEL_RETRY_DELAY
    }
  );
  const panelNotAvailable = pythonPanelServerError?.response?.status === 401;

  const isLoading =
    isComputeEngineLoading ||
    isLoadingPythonPanelServer ||
    isLoadingProjectToken ||
    isLoadingProject ||
    isLoadingRevisionCode;

  const isFetching =
    isFetchingRevisionCode ||
    isPythonPanelURLFetching ||
    isFetchingProjectToken;

  const isError =
    !isLoading &&
    !isFetching &&
    (isErrorRevisionCode ||
      pythonPanelServerURLError ||
      isLoadingProjectTokenError ||
      !isServerCustomPanelsEnabled);

  const errorType = useMemo(() => {
    if (!isServerCustomPanelsEnabled) {
      return chartDataErrorTypes.PY_SERVER_ERROR;
    }

    if (panelNotAvailable && isUserLoggedIn) {
      return chartDataErrorTypes.NO_PERMISSION_TO_SEE_PANEL;
    }

    if (panelNotAvailable && !isUserLoggedIn) {
      return chartDataErrorTypes.PY_BUILT_IN_PANEL_NOT_AVAILABLE_NOT_LOGGED_IN;
    }

    return null;
  }, [isUserLoggedIn, panelNotAvailable, isServerCustomPanelsEnabled]);

  return (
    <ChartContainer
      {...containerProps}
      isFetching={isFetching}
      errorType={errorType}
      isError={isError}
      isLoading={isLoading}
      isPreviousData={isPythonPanelPreviousData}
      title={actualTitle}
      hasData={!!(panelCode !== null || editCodeVersion)}
      badges={BADGES}
      fullScreenType="badges"
      hiddenMenuItems={HIDDEN_MENU_ITEMS}
      showLoadingDuringFullscreen
    >
      <div className={classes.pythonIframeContainer}>
        <CodeIframe
          ref={codeIframeRef}
          isExtendedPermissions
          url={pythonPanelServerURL}
        />
      </div>
    </ChartContainer>
  );
};

PythonPanel.CONFIG_PROPERTIES = [
  // obligatory
  'chartId',
  'containerProps',
  'isChartPreview',
  'experiments',
  'experimentKeys',
  'hiddenExperimentKeys',
  'isAutoRefreshEnabled',
  'title',
  'chartName',
  'hiddenMenuItems',
  'metricColorMap',

  //   panel props
  'code',
  'codeVersion',
  'revisionId',
  'templateId'
];

export default PythonPanel;
