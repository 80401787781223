import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextButton } from '@ds';

import ReactCrop from 'react-image-crop';

import noop from 'lodash/noop';

import DialogContent from '@material-ui/core/DialogContent';
import { THUMBNAIL_ASPECT_RATIO } from '@experiment-management-shared/constants/chartsGallery';

import GenericModal from '@shared/components/GenericModal';
import alertsUtil from '@/util/alertsUtil';
import { getCroppedImage, getCropToAspectRatio } from '@/helpers/imageHelpers';

import 'react-image-crop/dist/ReactCrop.css';

const ThumbnailModal = ({
  capture,
  dispatch,
  modalId,
  onSave,
  GenericModalProps
}) => {
  const [crop, setCrop] = useState({
    aspect: THUMBNAIL_ASPECT_RATIO
  });
  const [image, setImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(capture);

  const closeModal = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const onClose = () => {
    if (!image) {
      closeModal();
      return;
    }

    getCroppedImage(image, crop, 'thumbnail.jpg').then(blob => {
      onSave(blob);
      closeModal();
    });
  };

  const handleImageLoaded = image => {
    const newCrop = getCropToAspectRatio(image);

    setCrop(newCrop);
    setImage(image);

    return false; // Return false if you set crop state in here.
  };

  const isBtnDisabled = () => {
    return false;
  };

  const handleImageChange = useCallback(event => {
    const [file] = event.target.files;
    const fileReader = new FileReader();

    fileReader.addEventListener('load', loadEvent => {
      setImageSrc(loadEvent.target.result);
    });
    fileReader.readAsDataURL(file);
  }, []);

  const renderReactCrop = () => {
    if (!imageSrc) {
      return (
        <>
          <p>The thumbnail capture could not be generated. </p>
          <p> Please upload your own image.</p>
        </>
      );
    }

    return (
      <ReactCrop
        src={imageSrc}
        crop={crop}
        keepSelection
        minWidth={100}
        onChange={newCrop => setCrop(newCrop)}
        onImageLoaded={handleImageLoaded}
      />
    );
  };

  return (
    <GenericModal
      title="Thumbnail Capture"
      onCancel={closeModal}
      onClose={onClose}
      isButtonDisabled={isBtnDisabled()}
      buttonText="Done"
      isConfirmModal
      {...GenericModalProps}
    >
      <DialogContent className="generic-modal-body">
        <div
          className="generic-modal-group"
          style={{ flexDirection: 'column' }}
        >
          <div className="generic-modal-group-item">{renderReactCrop()}</div>
          <div className="generic-modal-group-item">
            <input
              accept="image/*"
              id="thumbnail-contained-button-file"
              onChange={handleImageChange}
              type="file"
              style={{ display: 'none' }}
            />

            <TextButton size="large" className="upload-thumbnail-btn">
              <label htmlFor="thumbnail-contained-button-file">
                Upload Image
              </label>
            </TextButton>
          </div>
        </div>
      </DialogContent>
    </GenericModal>
  );
};

ThumbnailModal.defaultProps = {
  GenericModalProps: {},
  onSave: noop
};

ThumbnailModal.propTypes = {
  capture: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  GenericModalProps: PropTypes.object
};

export default connect()(ThumbnailModal);
