import { useQuery } from 'react-query';
import userApi from '@/util/userApi';

const getCurrentOptInNotification = async () => {
  const isActiveNotification = await userApi.getOptInNotification();
  return !!isActiveNotification?.data?.active;
};

export default function useOptInNotifications() {
  return useQuery(['opt-in-notification'], getCurrentOptInNotification);
}
