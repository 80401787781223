import { useQuery } from 'react-query';
import workspacesApi from '@/util/workspaceApi';

const mapWorkspace = workspace => {
  return {
    id: workspace.workspaceId,
    projectsCount: workspace.projectCount,
    name: workspace.workspaceName,
    createdBy: workspace.workspaceCreator,
    membersCount: workspace.totalMembers,
    createdAt: workspace.createdAt,
    isDefault: workspace.default,
    storageUsage: workspace.storageUsage,
    collaborationFeaturesDisabled: workspace.collaborationFeaturesDisabled
  };
};

const getOrganizationWorkspaces = async organizationId => {
  const resp = await workspacesApi.fetchOrganizationWorkspaces(organizationId);
  return resp.data.map(mapWorkspace);
};

export default function useOrganizationWorkspaces(organizationId, config) {
  return useQuery(
    ['organizations', organizationId, 'workspaces'],
    () => getOrganizationWorkspaces(organizationId),
    config
  );
}
