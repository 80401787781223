import React from 'react';
import PropTypes from 'prop-types';

import { useIsUserMemberOfWorkspace } from '@shared/hooks';
import useModelRegistryVersionNotes from '@model-registry/api/useModelRegistryVersionNotes';
import useModelRegistryVersionNotesMutation from '@model-registry/api/useModelRegistryVersionNotesMutation';
import ModelRegistryNotes from '@model-registry/components/ModelRegistryNotes';

const ModelVersionNotesTab = ({
  selectedVersion: { registryModelItemId } = {}
}) => {
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const { data: modelNotes, isLoading, isError } = useModelRegistryVersionNotes(
    registryModelItemId
  );

  const updateNotesMutation = useModelRegistryVersionNotesMutation(
    registryModelItemId
  );

  return (
    <ModelRegistryNotes
      isEmpty={isError}
      isUserAMemberOfWorkspace={isUserAMemberOfWorkspace}
      isLoading={isLoading}
      modelNotes={modelNotes}
      handleUpdate={newNotes => {
        updateNotesMutation.mutate(newNotes);
      }}
    />
  );
};

ModelVersionNotesTab.propTypes = {
  selectedVersion: PropTypes.shape({
    version: PropTypes.string.isRequired,
    registryModelItemId: PropTypes.string.isRequired
  }).isRequired
};

export default ModelVersionNotesTab;
