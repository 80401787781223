import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import {
  BETA_FEATURE_3D_PANEL,
  BETA_FEATURE_CURVES_PANEL,
  BETA_FEATURE_DATA_PANEL,
  BETA_FEATURE_VIDEO_PANEL,
  BETA_SERVER_CUSTOM_PANELS
} from '@/lib/betaFeatures';

export const SORT_BY_DIRECTION = {
  ASC: 'SORT/ASC',
  DESC: 'SORT/DESC'
};

export const CHART_GALLERY_COLUMNS = {
  ACTIONS: 'actions',
  DESCRIPTION: 'description',
  ID: 'id',
  LAST_SAVED: 'lastSaved',
  NAME: 'name',
  THUMBNAIL: 'thumbnail',
  IS_NEW: 'isNew',
  TYPE: 'type',
  VISIBILITY: 'visibility',
  OWNER: 'owner',
  RANK: 'rank',
  VOTE_COUNT: 'voteCount',
  CODE_LANGUAGE: 'codeLanguage',
  GALLERY_SCOPE_TYPE: 'galleryScopeType',
  DESCRIPTION_TITLE: 'descriptionTitle',
  EXTENDED: 'isExtended'
};

export const CHART_GALLERY_COLUMN_NAMES = {
  [CHART_GALLERY_COLUMNS.ACTIONS]: '',
  [CHART_GALLERY_COLUMNS.DESCRIPTION]: 'Description',
  [CHART_GALLERY_COLUMNS.ID]: '',
  [CHART_GALLERY_COLUMNS.LAST_SAVED]: 'Last Saved',
  [CHART_GALLERY_COLUMNS.NAME]: 'Panel Name',
  [CHART_GALLERY_COLUMNS.THUMBNAIL]: '',
  [CHART_GALLERY_COLUMNS.TYPE]: 'Type',
  [CHART_GALLERY_COLUMNS.VISIBILITY]: 'Visibility',
  [CHART_GALLERY_COLUMNS.OWNER]: 'Owner',
  [CHART_GALLERY_COLUMNS.VOTE_COUNT]: 'Rank'
};

export const CHART_GALLERY_TYPES = {
  BUILT_IN: 'built-in',
  CUSTOM: 'custom',
  FEATURED: 'featured'
};

export const TEMPLATE_SCOPE_TYPES = {
  BUILT_IN: 'BUILT_IN',
  INTERNAL: 'INTERNAL',
  WORKSPACE: 'WORKSPACE',
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
};

export const BUILT_IN_CHARTS = [
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES['BuiltIn/Line'],
    [CHART_GALLERY_COLUMNS.NAME]: 'Line Chart',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/line-chart.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
    [CHART_GALLERY_COLUMNS.NAME]: 'Scatter Chart',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/scatter-chart.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES['BuiltIn/Bar'],
    [CHART_GALLERY_COLUMNS.NAME]: 'Bar Chart',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/bar-chart.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: [
      BUILT_IN_CHART_TYPES['BuiltIn/ParallelCoordinates']
    ],
    [CHART_GALLERY_COLUMNS.NAME]: 'Parallel Coordinates Chart',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/parcoords-chart.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.scalar,
    [CHART_GALLERY_COLUMNS.NAME]: 'Scalar Chart',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/scalar-chart.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.curves,
    [CHART_GALLERY_COLUMNS.NAME]: 'Logged Curve',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/curves-chart.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.image,
    [CHART_GALLERY_COLUMNS.NAME]: 'Image Panel',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/image-panel.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.data,
    [CHART_GALLERY_COLUMNS.NAME]: 'Data Panel',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/data-panel.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.video,
    [CHART_GALLERY_COLUMNS.NAME]: 'Video Panel',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/video-panel.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.IS_NEW]: true
  },
  {
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.pcd,
    [CHART_GALLERY_COLUMNS.NAME]: '3D Panel',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/3d-panel.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.IS_NEW]: true
  },
  {
    [CHART_GALLERY_COLUMNS.DESCRIPTION_TITLE]: 'Create your own panel',
    [CHART_GALLERY_COLUMNS.DESCRIPTION]:
      'Use Pandas, Matplotlib, Plotly or Seaborn to create your own visualizations',
    [CHART_GALLERY_COLUMNS.ID]: BUILT_IN_CHART_TYPES.python,
    [CHART_GALLERY_COLUMNS.NAME]: 'Python panel',
    [CHART_GALLERY_COLUMNS.OWNER]: 'comet',
    [CHART_GALLERY_COLUMNS.THUMBNAIL]: '/images/charts/python-panel.svg',
    [CHART_GALLERY_COLUMNS.TYPE]: CHART_GALLERY_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.GALLERY_SCOPE_TYPE]: TEMPLATE_SCOPE_TYPES.BUILT_IN,
    [CHART_GALLERY_COLUMNS.EXTENDED]: true,
    [CHART_GALLERY_COLUMNS.IS_NEW]: true
  }
];

export const getBuildInCharts = state => {
  let retVal = [...BUILT_IN_CHARTS];
  const isDataPanelEnabled = isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_DATA_PANEL
  });
  const isVideoPanelEnabled = isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_VIDEO_PANEL
  });
  const isPCDPanelEnabled = isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_3D_PANEL
  });
  const isCurvesPanelEnabled = isBetaFeatureEnabled(state, {
    featureName: BETA_FEATURE_CURVES_PANEL
  });
  const isPythonPanelEnabled = isBetaFeatureEnabled(state, {
    featureName: BETA_SERVER_CUSTOM_PANELS
  });
  const disabledCharts = [
    !isDataPanelEnabled && BUILT_IN_CHART_TYPES.data,
    !isVideoPanelEnabled && BUILT_IN_CHART_TYPES.video,
    !isPCDPanelEnabled && BUILT_IN_CHART_TYPES.pcd,
    !isCurvesPanelEnabled && BUILT_IN_CHART_TYPES.curves,
    !isPythonPanelEnabled && BUILT_IN_CHART_TYPES.python
  ].filter(Boolean);

  return retVal.filter(
    panel => !disabledCharts.includes(panel[CHART_GALLERY_COLUMNS.ID])
  );
};

export const CHART_TYPE_CLASS_NAMES = {
  [CHART_GALLERY_TYPES.BUILT_IN]: 'chart-type-built-in',
  [CHART_GALLERY_TYPES.CUSTOM]: 'chart-type-custom',
  [CHART_GALLERY_TYPES.FEATURED]: 'chart-type-featured'
};

export const PANEL_CODE_LANGUAGES = {
  PYTHON: 'py',
  JAVASCRIPT: 'js'
};

export const TEMPLATE_SCOPE_TYPE_LABELS = {
  [TEMPLATE_SCOPE_TYPES.BUILT_IN]: 'Built-in',
  [TEMPLATE_SCOPE_TYPES.INTERNAL]: 'Featured',
  [TEMPLATE_SCOPE_TYPES.WORKSPACE]: 'Workspace',
  [TEMPLATE_SCOPE_TYPES.PUBLIC]: 'Public',
  [TEMPLATE_SCOPE_TYPES.PRIVATE]: 'Private'
};

export const TEMPLATE_SCOPE_TYPES_BY_LABEL = Object.keys(
  TEMPLATE_SCOPE_TYPE_LABELS
).reduce((map, scopeType) => {
  const key = TEMPLATE_SCOPE_TYPE_LABELS[scopeType];
  map[key] = scopeType;
  return map;
}, {});

export const VOTE_TYPES = {
  UPVOTE: 'UPVOTE',
  DOWNVOTE: 'DOWNVOTE',
  NOVOTE: 'NOVOTE'
};

export const THUMBNAIL_ASPECT_RATIO = 3 / 2;
