import React, { HTMLProps } from 'react';
import classes from './ListComponents.module.scss';
import { useListItemContext } from '../useListItemContext';
import cx from 'classnames';

export type ListImageProps = HTMLProps<HTMLImageElement>;
const ListImage = ({ alt, ...props }: ListImageProps) => {
  const { isWithSecondaryText } = useListItemContext();

  return (
    <div
      className={cx(classes.imgContainer, classes.prefixElementContainer, {
        [classes.bigImg]: isWithSecondaryText
      })}
    >
      <img alt={alt} {...props} />
    </div>
  );
};

export default ListImage;
