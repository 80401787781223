import { Popover, PopoverProps } from '@material-ui/core';
import React from 'react';
import styles from './ActionMenuList.module.scss';
import { useActionMenuListContext } from './useActionMenuListContext';

type ActionMenuListBodyProps = Omit<
  PopoverProps,
  'open' | 'onClose' | 'anchorEl'
> & {
  closeMenuOnSelect?: boolean;
};
const ActionMenuListBody: React.FC<ActionMenuListBodyProps> = ({
  children,
  closeMenuOnSelect = true,
  ...props
}) => {
  const {
    anchorElement,
    onClosePopover,
    isPopoverOpened,
    closePopover
  } = useActionMenuListContext();

  const onBodyClick = () => {
    if (!closeMenuOnSelect) return;

    closePopover();
  };

  return (
    <Popover
      classes={{
        paper: styles.actionListPaper
      }}
      anchorEl={anchorElement}
      open={isPopoverOpened}
      onClose={onClosePopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    >
      <div className={styles.actionListContainer} onClick={onBodyClick}>
        {children}
      </div>
    </Popover>
  );
};

export default ActionMenuListBody;
