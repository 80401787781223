import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import userApi from '@/util/userApi';
import userActions from '@/actions/userActions';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes, dialogTypes } from '@/constants/alertTypes';

import { uncacheImage } from '../../utils';

const useUserPhotoUpdateMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(photo => userApi.changeProfilePicture(photo), {
    onSuccess: res => {
      const uncachedImages = {
        large: uncacheImage(res.data.large),
        small: uncacheImage(res.data.small)
      };

      dispatch(userActions.setUserProfileImages(uncachedImages));
      queryClient.setQueriesData('current-user', oldUser => ({
        ...oldUser,
        profileImage: uncachedImages.large
      }));

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_CHANGE_PROFILE_PICTURE,
          'Successfully changed profile picture.'
        )
      );
    },

    onError: error => {
      const errorMessage =
        error?.response?.data?.msg ||
        'An error occured trying to change your profile picture.';
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_CHANGE_PROFILE_PICTURE,
          errorMessage
        )
      );
    }
  });
};

export default useUserPhotoUpdateMutation;
