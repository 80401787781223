import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import {
  getMutableTemplateName,
  getMutableTemplateQueryBuilderId
} from '../reducers/ui/visualizationsUiReducer';
import {
  getQueryBuilderId,
  getTemplateNameById
} from '../reducers/visualizationsReducer';

export const getTemplateName = createSelector(
  [getMutableTemplateName, getTemplateNameById],
  (mutableTemplateName, savedTemplateName) => {
    return isEmpty(mutableTemplateName)
      ? savedTemplateName
      : mutableTemplateName;
  }
);
export const selectTemplateQueryBuilderId = createSelector(
  [getMutableTemplateQueryBuilderId, getQueryBuilderId],
  (mutableQueryBuilderId, queryBuilderId) => {
    return isEmpty(mutableQueryBuilderId)
      ? queryBuilderId
      : mutableQueryBuilderId;
  }
);

export const selectIsQueryBuilderIdChanged = createSelector(
  [getMutableTemplateQueryBuilderId, getQueryBuilderId],
  (mutableQueryBuilderId, queryBuilderId) => {
    return queryBuilderId !== mutableQueryBuilderId;
  }
);
