import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

export const REQUEST_PROJECT_SECRET_LINKS = 'REQUEST_PROJECT_SECRET_LINKS';
function requestProjectSecretLinks() {
  return {
    type: REQUEST_PROJECT_SECRET_LINKS
  };
}

export const RECEIVE_PROJECT_SECRET_LINKS = 'RECEIVE_PROJECT_SECRET_LINKS';
function receiveProjectSecretLinks(projectSecretLinks) {
  return {
    type: RECEIVE_PROJECT_SECRET_LINKS,
    projectSecretLinks
  };
}

export function fetchProjectSecretLinks() {
  return dispatch => {
    dispatch(requestProjectSecretLinks());

    return axios
      .get(`${BASE_API_URL}project/secret-link/list`)
      .then(response => dispatch(receiveProjectSecretLinks(response.data)));
  };
}

export function createProjectSecretLink(projectId) {
  return dispatch =>
    axios
      .get(`${BASE_API_URL}project/secret-link/new?projectId=${projectId}`)
      .then(() => dispatch(fetchProjectSecretLinks()));
}

export function deleteProjectSecretLink(projectId) {
  return (dispatch, getState) => {
    const state = getState();
    const sharableLink = state.projectSecretLinks.projectSecretLinks.find(
      l => l.projectId === projectId
    );
    const data = {
      projectId,
      link: sharableLink.link
    };
    return axios
      .post(`${BASE_API_URL}project/secret-link/remove`, data)
      .then(() => dispatch(fetchProjectSecretLinks()));
  };
}
