// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueryTab-module__container--gAEWg{--header-height: 52px;--footer-height: 60px;height:100%;display:flex;flex-direction:column;align-items:stretch;overflow:hidden;padding:20px}.QueryTab-module__container--gAEWg .QueryTab-module__header--UxiNk{display:flex;justify-content:space-between;align-items:center;height:var(--header-height);background:var(--white);border-bottom:1px solid var(--gray);padding:0 20px}.QueryTab-module__container--gAEWg .QueryTab-module__header--UxiNk .QueryTab-module__title--P25qU{font-weight:700;font-size:16px;color:var(--black)}.QueryTab-module__container--gAEWg .QueryTab-module__body--If72X{background:var(--white);height:calc(100% - var(--header-height) - var(--footer-height));overflow-y:auto;padding:20px}.QueryTab-module__container--gAEWg .QueryTab-module__footer--nAveQ{display:flex;justify-content:flex-end;align-items:center;gap:8px;background:var(--white);height:var(--footer-height);border-top:1px solid var(--gray);padding:0 20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QueryTab-module__container--gAEWg`,
	"header": `QueryTab-module__header--UxiNk`,
	"title": `QueryTab-module__title--P25qU`,
	"body": `QueryTab-module__body--If72X`,
	"footer": `QueryTab-module__footer--nAveQ`
};
module.exports = ___CSS_LOADER_EXPORT___;
