import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { IconButton } from '@ds';
import ChartHeaderFullscreen from './ChartHeaderFullscreen';
import { DSCloseIcon } from '@ds-icons';

const FullscreenPlot = ({ onClose, content, title, badges }) => {
  return (
    <Dialog
      className="fullscreen-chart-modal"
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      open={true}
    >
      <ChartHeaderFullscreen title={title} badges={badges} />
      <IconButton
        className="close-fullscreen-btn"
        onClick={onClose}
        type="secondary"
        size="XL"
        Icon={<DSCloseIcon />}
      />

      <div style={{ height: '100%', paddingTop: 55 }}>{content}</div>
    </Dialog>
  );
};

FullscreenPlot.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node,
  title: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired
};

export default FullscreenPlot;
