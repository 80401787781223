import React, { useMemo } from 'react';
import find from 'lodash/find';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';
import {
  constructMPMFilterDefinition,
  constructMPMRuleLabel,
  getMPMOperatorsForRule
} from '@mpm-druid/utils';
import FiltersSidebar from '@shared/components/FiltersSidebar';
import { ColumnType, QueryRule } from '@mpm-druid/types';

type FiltersSidebarContainerProps = {
  isOpen: boolean;
  onChange: (q: QueryRule) => void;
  onClose: () => void;
  query: QueryRule;
  hideClearButton?: () => void;
  columns: ColumnType[];
  title: string;
};

export const FiltersSidebarContainer = ({
  isOpen,
  onChange,
  onClose,
  query,
  hideClearButton,
  columns,
  title
}: FiltersSidebarContainerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters: Array<never> = [];
  const canEdit = true;

  const { rulesTree, segmentId } = query;

  const activeFilter = useMemo(() => {
    return find(filters, { filterId: segmentId }) || {};
  }, [segmentId, filters]);

  const handleClose = () => {
    onClose();
  };

  const handleChangeFilters = (newRulesTree: QueryRule['rulesTree']) => {
    onChange({ rulesTree: newRulesTree });
  };

  const handleClearQuery = () => {
    onChange({ rulesTree: generateEmptyRulesTree(), segmentId: '' });
  };

  return (
    <FiltersSidebar
      entityName={title}
      isOpen={isOpen}
      hideClearButton={hideClearButton}
      onChange={handleChangeFilters}
      onClose={handleClose}
      columns={columns}
      editable={canEdit}
      rulesTree={rulesTree}
      filters={filters}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      activeFilter={activeFilter}
      onClearQuery={handleClearQuery}
      getOperatorsForRule={getMPMOperatorsForRule}
      constructFilterDefinition={constructMPMFilterDefinition}
      constructRuleLabel={constructMPMRuleLabel}
    />
  );
};
