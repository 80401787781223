import { useState, useCallback } from 'react';
import { CHART_TYPES } from '@mpm/constants';

const TOOLTIP_ORIENTATION = {
  LEFT: 'left',
  RIGHT: 'right'
};
// 160 is a width of a version-tooltip-wrapper class
const TOOLTIP_WIDTH = 160;

export const useTooltip = (tabData, chartType) => {
  const initialPosition = { left: 0, top: 0 };
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipOrientation, setTooltipOrientation] = useState(
    TOOLTIP_ORIENTATION.RIGHT
  );
  const [inspectMode, setInspectMode] = useState(() => false);
  const [tooltipPosition, setTooltipPosition] = useState(initialPosition);

  const handleClick = () => {
    setTooltipVisible(true);
    setInspectMode(true);
  };

  const handleLinePointHover = useCallback(
    eventData => {
      eventData.event.preventDefault();
      eventData.event.stopPropagation();
      if (inspectMode === true) {
        return;
      }
      const point = eventData.points[0];
      const barChart = point.fullData?.type === 'bar';
      const lineChartStackGrouped = point.data.stackgroup;
      const { xaxis, yaxis, text, x, data } = point;
      let yPlotly = point.y;
      if (barChart) {
        [yPlotly] = eventData.yvals;
      } else if (lineChartStackGrouped) {
        yPlotly = point.text.sum;
      }
      let xCoord = xaxis.d2p(point.x) + xaxis._offset;
      if (chartType === CHART_TYPES.FAIRNESS_GROUPED) {
        xCoord = eventData?.event?.pointerX || xCoord;
      }
      const yCoord = yaxis.l2p(yPlotly) + yaxis._offset;
      const orientation =
        xaxis.d2p(point.x) + TOOLTIP_WIDTH > xaxis._length
          ? TOOLTIP_ORIENTATION.LEFT
          : TOOLTIP_ORIENTATION.RIGHT;
      setTooltipOrientation(orientation);
      let title = point.data.name || tabData?.label;
      if (tabData?.transformType && tabData?.label) {
        title = tabData.label;
      }
      let { color } = point.data.line ||
        point.data.marker || { color: '#5899DA' };
      if (color?.length > 7) {
        color = color.slice(0, 7);
      }
      const { hovertemplate } = point.data;
      setTooltipData({
        title,
        color,
        hovertemplate,
        text,
        x,
        y: Number.parseFloat(point.y).toFixed(4),
        data: point,
        percentileType: data?.percentileType
      });
      setTooltipPosition({ left: xCoord, top: yCoord });
      setTooltipVisible(true);
    },
    [inspectMode]
  );

  const hideTooltipHandler = () => {
    if (inspectMode) {
      return;
    }
    setTooltipVisible(false);
  };

  const closeTooltipHandler = () => {
    setTooltipVisible(false);
    setInspectMode(false);
  };

  return {
    inspectMode,
    setInspectMode,
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    setTooltipVisible,
    hideTooltipHandler,
    closeTooltipHandler,
    handleClick
  };
};
