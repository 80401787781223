import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import workspaceApi from '@/util/workspaceApi';

import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { useCurrentOrganization } from '@shared/hooks';
import { isEmail } from '@/lib/utils';

const USERNAME_ALREADY_EXISTS_SERVER_LABEL = 'user exists in team already';
const EMAIL_ALREADY_EXISTS = 'User is already workspace member!';

const isErrorUserAlreadyExists = response => {
  return (
    response?.data?.msg?.startsWith(USERNAME_ALREADY_EXISTS_SERVER_LABEL) ||
    response?.data?.msg?.startsWith(EMAIL_ALREADY_EXISTS)
  );
};

const inviteUsersByEmail = async (emails, workspaceId) => {
  const emailPromises = [];
  const otherErrors = [];
  let invitedAmount = 0;
  const alreadyMemberEmailUsers = [];
  for (let i = 0; i < emails.length; i++) {
    const email = emails[i];

    emailPromises.push(
      workspaceApi.inviteUserByEmailToWorkspace(workspaceId, email)
    );
  }

  const emailResponses = await Promise.allSettled(emailPromises);

  for (let i = 0; i < emailResponses.length; i++) {
    const emailResponse = emailResponses[i];
    if (emailResponse.status === 'rejected') {
      if (isErrorUserAlreadyExists(emailResponse.reason?.response)) {
        alreadyMemberEmailUsers.push(emails[i]);
      } else {
        otherErrors.push(emailResponse.reason?.response?.data?.msg);
      }
    } else {
      invitedAmount += 1;
    }
  }

  return { invitedAmount, alreadyMemberEmailUsers, otherErrors };
};

const inviteUsers = async (users, workspaceId) => {
  const usernames = [];
  const emails = [];
  let errorMsg = [];
  let invitedUsersAmount = 0;
  let alreadyMemberEmailUsers = [];

  for (let i = 0; i < users.length; i++) {
    const name = users[i];

    if (isEmail(name)) {
      emails.push(name);
    } else {
      usernames.push(name);
    }
  }

  if (usernames.length) {
    try {
      await workspaceApi.addWorkspaceMemberBulk({
        users: usernames.map(username => ({
          userName: username,
          teamId: workspaceId,
          admin: false
        }))
      });
      invitedUsersAmount += usernames?.length;
    } catch (err) {
      errorMsg.push(err?.response?.data?.msg || err?.message);
    }
  }

  if (emails.length) {
    const emailsResult = await inviteUsersByEmail(emails, workspaceId);
    invitedUsersAmount += emailsResult.invitedAmount || 0;
    alreadyMemberEmailUsers = emailsResult.alreadyMemberEmailUsers;
    errorMsg = errorMsg.concat(...(emailsResult?.otherErrors || []));
  }

  return {
    alreadyMemberEmailUsers,
    addedAmountUsers: invitedUsersAmount,
    errorMsg: errorMsg.join(', ')
  };
};

const handleSnackbarMessage = ({ addedAmountUsers, generalAmount }) => {
  const uninvitedAmount = generalAmount - addedAmountUsers;
  const couldntInvitedPart =
    uninvitedAmount > 0
      ? `${uninvitedAmount} ${
          uninvitedAmount === 1 ? 'user' : 'users'
        } can't be invited. `
      : '';
  const invitedPart =
    addedAmountUsers > 0 ? `${addedAmountUsers} users have been invited.` : '';

  return `${couldntInvitedPart}${invitedPart}`;
};

const useInviteWorkspaceUsersMutation = ({ workspaceId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentOrganization = useCurrentOrganization();

  return useMutation(users => inviteUsers(users, workspaceId), {
    onSuccess: (res, users) => {
      if (res.addedAmountUsers > 0) {
        queryClient.resetQueries(['workspace', workspaceId, 'members']);
        queryClient.resetQueries(['workspace', workspaceId, 'invites']);
        queryClient.resetQueries(['workspaces', 'all']);
        queryClient.resetQueries([
          'organizations',
          currentOrganization?.id,
          'current-user',
          'workspaces'
        ]);
      }

      queryClient.resetQueries(['organizations', currentOrganization?.id], {
        inactive: true
      });

      queryClient.invalidateQueries(
        ['organizations', currentOrganization?.id],
        {
          active: true
        }
      );

      const msg = handleSnackbarMessage({
        generalAmount: users?.length,
        addedAmountUsers: res.addedAmountUsers
      });

      if (!msg.length && res.errorMsg) {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_INVITE_BULK_USERS,
            res.errorMsg
          )
        );
      } else if (msg.length) {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_INVITE_BULK_USERS,
            msg
          )
        );
      }
    },
    onMutate: (users = []) => {
      if (!users?.length) {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_INVITE_BULK_USERS,
            'Users were succesfully invited'
          )
        );
      }
    }
  });
};

export default useInviteWorkspaceUsersMutation;
