import { useSelector } from 'react-redux';
import { getActiveOrganizationId } from '../../../reducers/ui/organizationsUiReducer';
import useOrganizations from './useOrganizations';
import { Organization } from '@shared/types';

const useCurrentOrganization = (): Organization | undefined => {
  const { data: organizations } = useOrganizations();
  const activeOrganizationId = useSelector(getActiveOrganizationId);
  const activeOrganization = organizations?.find(
    organization => organization?.id === activeOrganizationId
  );

  return activeOrganization;
};

export default useCurrentOrganization;
