import { debounce, find, get, isFunction, isNull, noop, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import { IconButton } from '@ds';
import { dialogTypes } from '@/constants/alertTypes';
import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import { getAPIKey } from '@/reducers/userReducer';
import { getResourcesBySource } from '@/reducers/visualizationsReducer';
import alertsUtil from '@/util/alertsUtil';
import useExperimentsForCustomPanels from '@API/experiments/useExperimentsForCustomPanels';
import usePanelTemplate from '@API/panels/usePanelTemplate';
import usePanelTemplateInternalResources from '@API/panels/usePanelTemplateInternalResources';
import usePanelTemplateRevisions from '@API/panels/usePanelTemplateRevisions';
import useProjectToken from '@API/panels/useProjectToken';
import useUpdatePanelInstanceMutation from '@API/panels/useUpdatePanelInstanceMutation';
import useProject from '@API/project/useProject';
import ChartHeader from '@experiment-management-shared/components/Charts/Chart/ChartHeader';
import ChartHeaderFullscreen from '@experiment-management-shared/components/Charts/Chart/ChartHeaderFullscreen';
import {
  iFrameConfig,
  PROJECT_TOKEN_REFRESH_INTERVAL,
  PYTHON_PANEL_REFETCH_INTERVAL,
  PYTHON_PANEL_RETRY_COUNT,
  PYTHON_PANEL_RETRY_DELAY
} from '@experiment-management-shared/constants';
import {
  BADGE_COLORS,
  chartDataErrorTypes
} from '@experiment-management-shared/constants/chartConstants';
import {
  PANEL_CODE_LANGUAGES,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';
import createSrcDocWithUserCode from '@experiment-management-shared/utils/customPanelDoc/srcDocCreator';
import { isValidMessage } from '@experiment-management-shared/utils/visualizationsHelper';
import SmallLoader from '@shared/components/SmallLoader';
import { parseJSON } from '@shared/utils/jsonHelpers';

import { ChartError } from '../ChartError';
import UpdateVersionModal from './UpdateVersionModal';
import { DSCloseIcon } from '@ds-icons';
import { CodeIframe } from '@experiment-management-shared/components';

import { usePythonPanelURL } from '@experiment-management-shared/api';
import { useIsServerCustomPanelsEnabled } from '@experiment-management-shared/hooks';
import { CHART_EXPORT_KEY } from '@experiment-management-shared/components/Charts/Chart/useChartHeaderMenu';

const { messageTypes } = iFrameConfig;

const CUSTOM_CHART_HIDE_MENU_ITEMS = [CHART_EXPORT_KEY, 'reset-zoom'];

const CUSTOM_PYTHON_CHART_HIDE_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  'embed-panel',
  'reset-zoom',
  'share-panel'
];

const CUSTOM_CHART_LOADING_HIDE_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  'edit-chart',
  'embed-panel',
  'reset-zoom',
  'share-panel'
];

const getResourcesForTemplate = (code, internalResources) => {
  const modifiedInternalResources = internalResources.map(
    ({ name, versions }) => {
      const defaultVersion = find(versions, 'isDefault');

      return {
        name,
        version: defaultVersion.version,
        enabled: true
      };
    }
  );

  const versionMap = internalResources.reduce((result, resource) => {
    const versionsMap = resource.versions.reduce((map, resourceVersion) => {
      // eslint-disable-next-line no-param-reassign
      map[resourceVersion.version] = resourceVersion.url;

      return map;
    }, {});

    // eslint-disable-next-line no-param-reassign
    result[resource.name] = versionsMap;

    return result;
  }, {});

  return getResourcesBySource(code, versionMap, modifiedInternalResources);
};

const EMPTY_EXPERIMENT_KEYS = [];

const CustomChart = ({
  chartId,
  dataPanelsRows,
  deleteConfig,
  editConfig,
  experimentKeys: projectExperimentKeys,
  experiments,
  hiddenExperimentKeys,
  hiddenMenuItems,
  instance,
  instanceId,
  isPreview,
  onChangeNumberOfExperiments,
  onDeleteChart,
  onEditChart,
  onOptionsClick,
  onUpdateVersion,
  panelMoveConfig,
  sectionId,
  options,
  templateId
}) => {
  const {
    config,
    instanceName,
    queryBuilderId,
    templateRevisionId,
    userSkippedVersion
  } = instance;

  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();

  const isStatic = get(instance, ['metadata', 'isStatic'], false);

  const dispatch = useDispatch();
  const apiKey = useSelector(getAPIKey);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { data: project, isLoading: isLoadingProject } = useProject();

  const projectName = project?.projectName || '';
  const workspaceName = project?.teamName || '';

  const {
    data: queryExperimentKeys,
    isLoading: isLoadingExperimentKeys
  } = useExperimentsForCustomPanels({
    queryBuilderId
  });
  // @todo: see error management for charts: visualizationsActions#fetchVisualizationTemplateForChart
  const {
    data: template,
    isError: isErrorTemplate,
    isLoading: isLoadingTemplate
  } = usePanelTemplate({
    revisionId: templateRevisionId,
    templateId
  });
  // @todo: only canEdit
  const {
    data: templateRevisions,
    isLoading: isLoadingTemplateRevisions
  } = usePanelTemplateRevisions({
    templateId
  });
  const {
    data: internalResources,
    isLoading: isLoadingInternalResources
  } = usePanelTemplateInternalResources();

  const {
    data: projectToken,
    isLoading: isLoadingProjectToken
  } = useProjectToken(
    { chartId },
    {
      refetchInterval: PROJECT_TOKEN_REFRESH_INTERVAL,
      refetchOnMount: true
    }
  );

  const updatePanelInstanceMutation = useUpdatePanelInstanceMutation();

  const iframeRef = useRef(null);
  const prevExperimentKeysRef = useRef(null);

  const baseExperimentKeys = queryBuilderId
    ? queryExperimentKeys || EMPTY_EXPERIMENT_KEYS
    : projectExperimentKeys;
  const isLoadingBaseExperimentKeys = queryBuilderId && isLoadingExperimentKeys;
  const hasNewActiveExperimentKeys = experiments.some(experiment => {
    return (
      experiment.runActive &&
      baseExperimentKeys.includes(experiment.experimentKey)
    );
  });

  useEffect(() => {
    if (isFunction(onChangeNumberOfExperiments)) {
      onChangeNumberOfExperiments(baseExperimentKeys.length);
    }
  }, [onChangeNumberOfExperiments, baseExperimentKeys.length]);

  const experimentKeys = useMemo(() => {
    if (isLoadingBaseExperimentKeys) return [];

    if (
      prevExperimentKeysRef.current &&
      hasNewActiveExperimentKeys &&
      isStatic
    ) {
      return prevExperimentKeysRef.current;
    }

    const visibleExperimentKeys = baseExperimentKeys.filter(
      experimentKey => !hiddenExperimentKeys.includes(experimentKey)
    );

    prevExperimentKeysRef.current = visibleExperimentKeys;

    return visibleExperimentKeys;
  }, [
    baseExperimentKeys,
    hasNewActiveExperimentKeys,
    hiddenExperimentKeys,
    isLoadingBaseExperimentKeys,
    isStatic
  ]);

  const userCode = template?.code;
  const isPy = userCode?.type === PANEL_CODE_LANGUAGES.PYTHON;
  const isRunPyodidePanels = isPy && !isServerCustomPanelsEnabled;
  const isRunPyServerPanels = isPy && isServerCustomPanelsEnabled;

  const {
    data: customPanelServerURL,
    isLoading: isLoadingCustomPanelServer,
    isError: customPanelServerURLError
  } = usePythonPanelURL(
    {
      code: template?.code.pyCode,
      instanceId: instance?.instanceId || 'NEW_CUSTOM_PANEL',
      projectName,
      experimentKeys: experimentKeys,
      options: options || {},
      workspaceName,
      revisionId: templateRevisionId,
      projectToken
    },
    {
      enabled: !!(
        isPy &&
        template?.code.pyCode &&
        projectName &&
        workspaceName &&
        experimentKeys &&
        projectToken
      ),
      retry: PYTHON_PANEL_RETRY_COUNT,
      refetchInterval: PYTHON_PANEL_REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      retryDelay: PYTHON_PANEL_RETRY_DELAY
    }
  );

  const [isPyodideCodeError, setIsPyCodeError] = useState(false);
  const [resizeRevision, setResizeRevision] = useState(0);

  const latestRevisionId = useMemo(() => {
    if (isLoadingTemplateRevisions) return 0;

    if (templateRevisions && templateRevisions.length) {
      return Math.max(...templateRevisions);
    }

    return 0;
  }, [isLoadingTemplateRevisions, templateRevisions]);

  const resourcesForTemplate = useMemo(() => {
    if (isLoadingTemplate || isLoadingInternalResources) {
      return null;
    }

    return getResourcesForTemplate(template?.code, internalResources);
  }, [
    internalResources,
    isLoadingInternalResources,
    isLoadingTemplate,
    template?.code
  ]);

  // TODO: (camden-todo) This only works some of the time for experiment keys,
  // but it tends to get blocked by CORS which prevents the charts from
  // redrawing with new keys, for now, only using for options
  useEffect(() => {
    if (!iframeRef.current || isPy) return;

    const { contentWindow } = iframeRef.current;
    contentWindow.postMessage(
      {
        messageType: messageTypes.REDRAW,
        experimentKeys,
        options: parseJSON(config, {})
      },
      '*'
    );
  }, [config, experimentKeys, resizeRevision, isPy]);

  const updateDataPanelsRows = useCallback(() => {
    if (!iframeRef.current || isPy) return;

    const { contentWindow } = iframeRef.current;
    contentWindow.postMessage(
      {
        messageType: messageTypes.UPDATE_DATA_PANELS_ROWS,
        dataPanelsRows
      },
      '*'
    );
  }, [dataPanelsRows, isPy]);

  const scopeType = get(template, 'scopeType', '');
  useEffect(() => {
    const handleReceiveMessage = event => {
      const { data, origin } = event;

      if (isValidMessage(origin, scopeType)) {
        const { messageType, ...payload } = data;

        if (instanceId !== payload.instanceId) {
          return;
        }

        if (messageType === messageTypes.IFRAME_LOADED) {
          updateDataPanelsRows();
        }
      }
    };

    window.addEventListener('message', handleReceiveMessage);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [instanceId, scopeType, updateDataPanelsRows]);

  useEffect(updateDataPanelsRows, [updateDataPanelsRows]);

  useEffect(() => {
    if (isRunPyodidePanels) {
      if (!iframeRef.current) return;
      const { messageTypes } = iFrameConfig;
      const { contentWindow } = iframeRef.current;

      contentWindow.postMessage(
        {
          messageType: messageTypes.SET_PY_EXPERIMENT_KEYS,
          experimentKeys,
          instanceId
        },
        '*'
      );
    }
  }, [template, experimentKeys, instance, isRunPyodidePanels]);

  useEffect(() => {
    setIsPyCodeError(false);
  }, [templateRevisionId, experimentKeys]);

  useEffect(() => {
    if (isRunPyodidePanels) {
      const handlePyOutput = event => {
        if (!iframeRef.current) return;
        const { messageTypes } = iFrameConfig;
        const { contentWindow } = iframeRef.current;

        // the second case works when the panel hasn't been created yet
        if (
          instanceId === event.data.instanceId ||
          (!instanceId &&
            event.data.instanceId === iFrameConfig.defaultInstanceId)
        ) {
          if (event.data.type === 'error') {
            setIsPyCodeError(true);
          } else {
            contentWindow.postMessage(
              {
                messageType: messageTypes.ADD_PY_OUTPUT,
                output: event.data.output,
                instanceId: event.data.instanceId,
                toClean: event.data.toClean,
                type: 'msg'
              },
              '*'
            );
          }
        }
      };

      window.PYODIDE_WORKER.addEventListener('message', handlePyOutput);

      return () => {
        window.PYODIDE_WORKER.removeEventListener('message', handlePyOutput);
      };
    }
  }, [isRunPyodidePanels, instanceId, template, setIsPyCodeError]);

  const srcDoc = useMemo(() => {
    if (
      isLoadingProject ||
      isLoadingTemplate ||
      !resourcesForTemplate ||
      !userCode ||
      !options ||
      isRunPyServerPanels
    ) {
      return '';
    }

    return createSrcDocWithUserCode({
      experimentKeys,
      options: {
        ...parseJSON(userCode.defaultConfig),
        ...options,
        ...parseJSON(config)
      },
      projectToken,
      userCode,
      resources: resourcesForTemplate,
      instanceId,
      projectId: project?.projectId,
      pyConfig: options,
      isPy,
      apiKey
    });
  }, [
    apiKey,
    config,
    experimentKeys,
    instanceId,
    isLoadingProject,
    isLoadingTemplate,
    options,
    project?.projectId,
    projectToken,
    resourcesForTemplate,
    userCode,
    isRunPyodidePanels,
    isPy
  ]);

  const handleChangeIframeRef = useCallback(ref => {
    iframeRef.current = ref;

    if (!ref) return;

    let wasAlreadyCalled = false;

    const handleResize = debounce(() => {
      setResizeRevision(revision => revision + 1);
    }, 1000);

    const resizeObserver = new ResizeObserver(() => {
      // Avoid first automatic call for the resize observer
      if (!wasAlreadyCalled) {
        wasAlreadyCalled = true;
        return;
      }

      window.requestAnimationFrame(handleResize);
    });

    resizeObserver.observe(iframeRef.current);
  }, []);

  const handledHiddenMenuItems = useMemo(() => {
    if (isNull(srcDoc)) {
      return uniq([
        ...CUSTOM_CHART_LOADING_HIDE_MENU_ITEMS,
        ...hiddenMenuItems
      ]);
    }

    if (isPy) {
      return uniq([...CUSTOM_PYTHON_CHART_HIDE_MENU_ITEMS, ...hiddenMenuItems]);
    }

    return uniq([...CUSTOM_CHART_HIDE_MENU_ITEMS, ...hiddenMenuItems]);
  }, [hiddenMenuItems, isPy, srcDoc]);

  const renderChartError = codeType => {
    return (
      <div className="chart-data-error-container">
        <ChartError type={codeType} />
      </div>
    );
  };

  const skipVersion = () => {
    dispatch(alertsUtil.closeDialog(dialogTypes.PANEL_UPDATES_AVAILABLE_MODAL));

    updatePanelInstanceMutation.mutate({
      instance: {
        ...instance,
        config: JSON.stringify(instance.config),
        userSkippedVersion: latestRevisionId
      }
    });
  };

  const updateVersion = () => {
    dispatch(alertsUtil.closeDialog(dialogTypes.PANEL_UPDATES_AVAILABLE_MODAL));

    if (isPreview) {
      onUpdateVersion();
      return;
    }

    updatePanelInstanceMutation.mutate({
      instance: {
        ...instance,
        templateRevisionId: latestRevisionId
      }
    });
  };

  const openModal = () => {
    const modalId = dialogTypes.PANEL_UPDATES_AVAILABLE_MODAL;
    const updateVersionModal = (
      <UpdateVersionModal
        chartId={chartId}
        handleCloseModal={() => dispatch(alertsUtil.closeDialog(modalId))}
        currentRevisionId={templateRevisionId}
        latestRevisionId={latestRevisionId}
        onSkipVersion={skipVersion}
        onUpdateVersion={updateVersion}
        templateId={templateId}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, updateVersionModal));
  };

  const renderBanner = () => {
    if (!project?.canEdit) return null;

    const areUpdatesAvailable =
      templateRevisionId < latestRevisionId &&
      (!userSkippedVersion || userSkippedVersion < latestRevisionId);

    if (!areUpdatesAvailable) return null;

    return (
      <div className="custom-chart-banner" onClick={openModal}>
        Updates available
      </div>
    );
  };

  const renderChart = () => {
    if (customPanelServerURLError && isRunPyServerPanels) {
      return renderChartError(chartDataErrorTypes.PY_SERVER_ERROR);
    }

    if (isPyodideCodeError) {
      return renderChartError(chartDataErrorTypes.PYODIDE_PY_ERROR);
    }

    if (isNull(srcDoc) || isErrorTemplate) {
      return renderChartError(chartDataErrorTypes.TEMPLATE_NOT_FOUND);
    }

    if (!template) return null;

    const { INTERNAL, PRIVATE } = TEMPLATE_SCOPE_TYPES;
    const isUserAllowedToExtendedPermissions =
      project?.canEdit || [INTERNAL, PRIVATE].includes(template.scopeType);

    return (
      <CodeIframe
        className="custom-chart-iframe"
        ref={handleChangeIframeRef}
        url={customPanelServerURL}
        isExtendedPermissions={isUserAllowedToExtendedPermissions}
        isInternalScopeType={template.scopeType === INTERNAL}
        srcDoc={srcDoc}
      />
    );
  };

  const chartTitle = instanceName || get(template, 'templateName', '');

  const badges = [
    { label: 'Static', value: isStatic },
    {
      label: 'Using panel filters',
      value: Boolean(queryBuilderId),
      color: BADGE_COLORS.warning
    }
  ]
    .filter(({ value }) => value)
    .map(({ label, color }) => ({
      label,
      color
    }));

  const clearFullscreen = () => {
    setIsFullscreen(false);
  };

  const renderHeader = () => {
    if (isLoadingProject || isPreview) return null;

    return (
      <ChartHeader
        badges={badges}
        chartId={chartId}
        experimentKeys={experimentKeys}
        hiddenMenuItems={handledHiddenMenuItems}
        instanceId={instanceId}
        isCustomPanel
        isFullscreen={isFullscreen}
        onCollapseChart={clearFullscreen}
        onDeleteChart={() => onDeleteChart(deleteConfig)}
        onEditChart={() =>
          onEditChart({
            scopeType: template.scopeType,
            templateId,
            ...editConfig
          })
        }
        onExpandChart={() => {
          setIsFullscreen(true);
        }}
        onOptionsClick={onOptionsClick}
        panelMoveConfig={panelMoveConfig}
        sectionId={sectionId}
        projectId={project?.projectId}
        templateId={templateId}
        title={chartTitle}
      />
    );
  };

  const renderFullscreenChart = () => {
    return (
      <Dialog
        className="fullscreen-chart-modal custom"
        fullWidth
        maxWidth="xl"
        open={isFullscreen}
        onClose={clearFullscreen}
      >
        <ChartHeaderFullscreen title={chartTitle} customClass="custom" />
        <IconButton
          type="secondary"
          size="XL"
          className="close-fullscreen-btn"
          onClick={clearFullscreen}
          Icon={<DSCloseIcon />}
        />
        {renderChart()}
      </Dialog>
    );
  };

  if (
    isLoadingTemplate ||
    isLoadingProjectToken ||
    isLoadingCustomPanelServer
  ) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
      />
    );
  }

  return (
    <>
      <div className="chart-container">
        {renderHeader()}
        {renderBanner()}
        {isFullscreen ? renderFullscreenChart() : renderChart()}
      </div>
    </>
  );
};

CustomChart.defaultProps = {
  chartId: '',
  dataPanelsRows: {},
  experiments: [],
  hiddenExperimentKeys: [],
  hiddenMenuItems: [],
  instance: {},
  isFullscreen: false,
  isPreview: false,
  onChangeNumberOfExperiments: undefined,
  onDeleteChart: noop,
  onEditChart: noop,
  onOptionsClick: noop,
  onUpdateVersion: noop,
  options: {}
};

CustomChart.propTypes = {
  chartId: PropTypes.string,
  dataPanelsRows: PropTypes.object,
  deleteConfig: PropTypes.object.isRequired,
  editConfig: PropTypes.object.isRequired,
  experimentKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  experiments: PropTypes.array,
  hiddenExperimentKeys: PropTypes.array,
  hiddenMenuItems: PropTypes.array,
  instance: PropTypes.object,
  instanceId: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool,
  isPreview: PropTypes.bool,
  onChangeNumberOfExperiments: PropTypes.func,
  onDeleteChart: PropTypes.func,
  onEditChart: PropTypes.func,
  onOptionsClick: PropTypes.func,
  onUpdateVersion: PropTypes.func,
  panelMoveConfig: PropTypes.object,
  sectionId: PropTypes.string,
  options: PropTypes.object,
  templateId: PropTypes.string.isRequired
};

export default CustomChart;
