import useImagesSearch from '@API/panels/useImagesSearch';
import useImagesSteps from '@API/panels/useImagesSteps';
import { MultipleAutocomplete } from '@DesignSystem/controllers';
import { Slider } from '@design-system-outdated/components';
import {
  FILTERING_MODE,
  NO_ITEMS_STRATEGY
} from '@DesignSystem/controllers/MultipleAutocomplete/MultipleAutocomplete';
import { InputLabel } from '@material-ui/core';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CHIP_TYPE_BADGE } from '@DesignSystem/data-display/Chip/Chip';

import { ImageThumbnail } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/ImagePanel/ImageThumbnail';
import {
  BUILT_IN_CHART_TYPES,
  IMAGE_PANEL_DEFAULT_STEP
} from '@experiment-management-shared/constants/chartConstants';
import { SearchIcon } from '@Icons-outdated';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { useDebouncedFilter } from '@shared/hooks';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';

const DELAYS_MS = 250;
const MIN_EXPERIMENT_COUNT = 1;
const MAX_EXPERIMENT_COUNT = 25;

const ImagePanelDataTab = ({ experimentKeys, hiddenExperimentKeys }) => {
  const dispatch = useDispatch();
  const imagePanelForm = useSelector(state =>
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.image })
  );

  const [imagesValue, setImagesValue] = useState(
    chartHelpers.sanitizeImagesNameArray(imagePanelForm.images)
  );
  const [stepValue, setStepValue] = useState(0);
  const [experimentsCountValue, setExperimentsCountValue] = useState(
    imagePanelForm.experimentsCount
  );

  const {
    debouncedFilterValue: autocompleteDebouncedFilterValue,
    setFilterValue: setAutocompleteFilterValue,
    filterValue: autocompleteFilterValue
  } = useDebouncedFilter('');

  const targetExperimentKeys = useMemo(() => {
    let retVal = [...experimentKeys];

    if (isArray(hiddenExperimentKeys)) {
      retVal = retVal.filter(key => !hiddenExperimentKeys.includes(key));
    }

    if (retVal.length > imagePanelForm?.experimentsCount) {
      retVal.length = imagePanelForm?.experimentsCount;
    }

    return retVal;
  }, [experimentKeys, hiddenExperimentKeys, imagePanelForm?.experimentsCount]);

  const { data: searchData, isLoading: isLoadingSearchData } = useImagesSearch({
    experimentKeys: targetExperimentKeys,
    imageSearchPhrase: autocompleteDebouncedFilterValue
  });

  const { data: stepsData, isLoading: isLoadingStepsData } = useImagesSteps(
    {
      experimentKeys: targetExperimentKeys,
      images: imagesValue
    },
    {
      keepPreviousData: true,
      refetchOnMount: true
    }
  );

  const stepsArray = useMemo(() => {
    const sortedSteps = (stepsData?.data?.steps || []).slice();

    // in case we don't have any step available we need to add 0 because it is treated as default value
    if (!sortedSteps.length) {
      sortedSteps.push(IMAGE_PANEL_DEFAULT_STEP);
    }

    // edge case: if array steps includes null and 0 remove null as it's the same as 0
    if (sortedSteps.includes(0) && sortedSteps.includes(null)) {
      const filtered = sortedSteps.filter(e => e !== null);
      return filtered;
    }

    return sortedSteps.sort((s1, s2) => s1 - s2);
  }, [stepsData]);

  useEffect(() => {
    if (isLoadingStepsData) {
      return;
    }

    // check if saved value available for current images and experiments
    const localStepInvalid = !stepsArray.includes(imagePanelForm?.step);

    if (localStepInvalid) {
      // Select the first available option from list
      setStepValue(0);
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.image,
          'step',
          stepsArray[0]
        )
      );
    } else {
      // invalidate stepValue base on saved data in redux
      const index = stepsArray.indexOf(imagePanelForm.step);

      if (index !== -1 && index !== stepValue) {
        setStepValue(index);
      }
    }
    // stepValue ignored in purpose
  }, [stepsArray, imagePanelForm.step]);

  useEffect(() => {
    const isFormErased =
      imagePanelForm.images.length === 0 && imagesValue.length > 0;
    if (!isFormErased) return;

    setImagesValue([]);
  }, [imagePanelForm.images.length, imagesValue.length]);

  const options = useMemo(() => {
    const retVal = (searchData?.data?.images || []).map(image => ({
      value: image.figName,
      image
    }));

    return retVal.filter(o => !imagesValue.includes(o.value));
  }, [searchData, imagesValue]);

  const handleChangeImagesValue = value => {
    setImagesValue(value);

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.image,
        'images',
        value
      )
    );
  };

  const handleDebouncedChangeStep = useCallback(
    debounce(
      step =>
        dispatch(
          dashboardChartsActions.updateChartFormKey(
            BUILT_IN_CHART_TYPES.image,
            'step',
            step
          )
        ),
      DELAYS_MS
    ),
    []
  );

  const handleChangeStep = index => {
    setStepValue(index);
    handleDebouncedChangeStep(stepsArray[index]);
  };

  const handleDebouncedChangeExperimentsCount = useCallback(
    debounce(
      count =>
        dispatch(
          dashboardChartsActions.updateChartFormKey(
            BUILT_IN_CHART_TYPES.image,
            'experimentsCount',
            count
          )
        ),
      DELAYS_MS
    ),
    []
  );

  const handleChangeExperimentsCount = count => {
    setExperimentsCountValue(count);
    handleDebouncedChangeExperimentsCount(count);
  };

  const renderOption = option => {
    // taking into account the issue on BE side that image thumbnail not always exist UI will try to take thumbnail, otherwise original image
    // https://comet-ml.atlassian.net/browse/CM-4513
    const src = option.image.thumbnailPath || option.image.imagePath;

    return (
      <>
        <div className="ds-dropdown-popper-item-content">
          <ImageThumbnail
            name={option.value}
            src={src}
            originalImageSize={{
              originalImageWidth: option.image.originalImageWidth || 0,
              originalImageHeight: option.image.originalImageHeight || 0
            }}
          />
        </div>
        <div className="ds-dropdown-popper-label">{option.value}</div>
      </>
    );
  };

  const checkValueToAdd = value =>
    options.find(o => o.value === value) && !imagesValue.includes(value);

  const renderImagesAutocomplete = () => {
    const isInputDisabled = imagesValue.length >= 10;
    const noItemsStrategy =
      autocompleteDebouncedFilterValue.length >= 3 && options.length === 0
        ? NO_ITEMS_STRATEGY.SHOW_MESSAGE
        : NO_ITEMS_STRATEGY.NO_MESSAGE;

    const withValuePlaceholder =
      imagesValue.length === 9
        ? 'You can select one more image'
        : 'Select more images';

    return (
      <MultipleAutocomplete
        noValuePlaceholder="Enter at least 3 characters to begin search"
        withValuePlaceholder={withValuePlaceholder}
        label="Image name"
        description="The image names will appear in a row. Select up to 10 images and rearrange the badges to change their order."
        options={options}
        value={imagesValue}
        onChangeValue={handleChangeImagesValue}
        filterValue={autocompleteFilterValue}
        onChangeFilterValue={setAutocompleteFilterValue}
        renderListItem={renderOption}
        checkValueToAdd={checkValueToAdd}
        disabled={isInputDisabled}
        classes="grid graphics-panel-data-tab-autocomplete"
        loading={isLoadingSearchData}
        sortable
        chipType={CHIP_TYPE_BADGE}
        filterMode={FILTERING_MODE.NONE}
        SuffixIcon={<SearchIcon />}
        noItemsStrategy={noItemsStrategy}
        maxValuesLimit={10}
      />
    );
  };

  const stepSliderLabelFormat = value => {
    const lastStep = last(stepsArray) || IMAGE_PANEL_DEFAULT_STEP;
    const currentStep = stepsArray[value] || IMAGE_PANEL_DEFAULT_STEP;

    return isNumber(lastStep) && isNumber(currentStep)
      ? `${currentStep}/${lastStep}`
      : '';
  };

  const renderStepsSlider = () => {
    // workaround to recreate slider component in case we have only one possible value, it will force mark to be rendered from the beginning of component
    const key = stepsArray.length === 1 ? 'one-value' : 'multiple-values';

    return (
      <div
        className="modal-input-group"
        style={{ marginBottom: 60, marginTop: 60 }}
      >
        <InputLabel className="modal-input-label">Step</InputLabel>
        <div className="slider-wrapper">
          <Slider
            key={key}
            disabled={isLoadingStepsData}
            min={0}
            max={stepsArray.length - 1}
            step={1}
            valueLabelFormat={value => stepsArray[value] || 0}
            sliderSuffixFormat={stepSliderLabelFormat}
            value={stepValue}
            onChange={handleChangeStep}
          />
        </div>
      </div>
    );
  };

  const renderExperimentsSlider = () => (
    <div className="modal-input-group" style={{ marginBottom: 60 }}>
      <InputLabel className="modal-input-label">
        Max number of experiments to show
      </InputLabel>
      <div className="slider-wrapper">
        <Slider
          value={experimentsCountValue}
          min={MIN_EXPERIMENT_COUNT}
          max={MAX_EXPERIMENT_COUNT}
          step={1}
          valueLabelFormat={value => value}
          onChange={handleChangeExperimentsCount}
        />
      </div>
    </div>
  );

  return (
    <div className="image-video-panel-data-tab">
      {renderImagesAutocomplete()}
      {renderStepsSlider()}
      {renderExperimentsSlider()}
    </div>
  );
};

ImagePanelDataTab.defaultProps = {
  experimentKeys: [],
  hiddenExperimentKeys: []
};

ImagePanelDataTab.propTypes = {
  experimentKeys: PropTypes.array,
  hiddenExperimentKeys: PropTypes.array
};

export default ImagePanelDataTab;
