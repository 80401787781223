import { useMemo } from 'react';
import { useParams } from 'react-router';
import useCompareExperimentsKeys from '@shared/hooks/useCompareExperimentsKeys';

const useExperimentKeys = () => {
  const { experimentKey: pathExperimentKey } = useParams<{
    experimentKey: string;
  }>();
  const {
    experimentKeys: queryStringExperimentKeys
  } = useCompareExperimentsKeys();

  // experimentKeys are used for comparison view
  return useMemo(() => {
    return pathExperimentKey ? [pathExperimentKey] : queryStringExperimentKeys;
  }, [pathExperimentKey, queryStringExperimentKeys]);
};

export default useExperimentKeys;
