// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pill-module__pill--PZNb_{background:#fb7628;display:flex;align-items:center;justify-content:center;box-sizing:border-box;padding:5px 10px;border-radius:12px;cursor:pointer;user-select:none}.Pill-module__pill--PZNb_ p{color:#fff !important;font-size:12px;line-height:1}.Pill-module__animation--Il27l:hover{padding:5px 13px}.Pill-module__clickable--jPOyt{cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": `Pill-module__pill--PZNb_`,
	"animation": `Pill-module__animation--Il27l`,
	"clickable": `Pill-module__clickable--jPOyt`
};
module.exports = ___CSS_LOADER_EXPORT___;
