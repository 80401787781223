import React, { memo, useCallback, useMemo } from 'react';

import { Slider } from '@design-system-outdated/components';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import { Combobox, Select } from '@ds';
import cx from 'classnames';
import { flatten, get, isEmpty, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  AGGREGATION_TYPES_OBJECT,
  BUILT_IN_CHART_TYPES,
  CHART_CONFIG_MODIFIERS,
  COMET_TIME_SERIES_AXES_MAP,
  TRANSFORM_TYPES_ARRAY,
  OUTLIERS_VALUES,
  LINE_CHART_X_AGGREGATIONS
} from '@experiment-management-shared/constants/chartConstants';

import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';

import RadioButton from '@DesignSystem/controllers/RadioButton/RadioButton';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';

import useChartTabYValues from '@experiment-management-shared/hooks/useChartTabYValues';
import { disableNotNumericalOptionsForGrouping } from '@experiment-management-shared/utils/buildinVisualisationHelpers';

const CHART_TYPE = BUILT_IN_CHART_TYPES['BuiltIn/Line'];
// const DEBOUNCED_CHART_TIMEOUT = 500;
const X_AXIS_KEY = 'selectedXAxis';

const LineChartDataTab = () => {
  const lineChartForm = useSelector(state =>
    getChartFormByType(state, {
      chartType: BUILT_IN_CHART_TYPES['BuiltIn/Line']
    })
  );

  const yValues = useChartTabYValues({ type: CHART_TYPE });

  const handledYValues = useMemo(() => {
    if (lineChartForm.grouping.enabled) {
      return disableNotNumericalOptionsForGrouping(yValues);
    }

    return yValues;
  }, [yValues, lineChartForm.grouping?.enabled]);

  const dispatch = useDispatch();

  const fillType = get(
    lineChartForm,
    'fillType',
    CHART_CONFIG_MODIFIERS.LINE.value
  );

  const handleUpdateChartForm = useCallback(
    (key, valueToUpdate) => {
      const updatedForm = {
        ...lineChartForm,
        [key]: valueToUpdate
      };

      dispatch(dashboardChartsActions.updateChartForm(CHART_TYPE, updatedForm));
    },
    [lineChartForm, dispatch]
  );

  const handleUpdateChartFormKey = useCallback(
    (key, value) => {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES['BuiltIn/Line'],
          key,
          value
        )
      );
    },
    [dispatch]
  );

  const handleSliderChange = useCallback(
    (key, value) => {
      handleUpdateChartFormKey(key, value);
    },
    [handleUpdateChartFormKey]
  );

  const getFilteredMetricNames = metricNames => {
    return metricNames.filter(
      metricName =>
        !COMET_TIME_SERIES_AXES_MAP.hasOwnProperty(metricName) &&
        !isNull(metricName)
    );
  };

  const handleSelectYAxis = selectedOptions => {
    if (!isEmpty(selectedOptions)) {
      const yAxisMetrics = selectedOptions;
      const metricNames = getFilteredMetricNames([
        ...yAxisMetrics,
        lineChartForm.selectedXAxis
      ]);

      const updatedForm = {
        ...lineChartForm,
        selectedYAxis: yAxisMetrics,
        metricNames
      };

      dispatch(dashboardChartsActions.updateChartForm(CHART_TYPE, updatedForm));
    } else {
      handleUpdateChartForm('selectedYAxis', selectedOptions);
    }
  };

  const handleSelectXAxis = (axisType, selectedOption) => {
    if (selectedOption) {
      const value = selectedOption;
      const { selectedYAxis } = lineChartForm;

      const handledYAxis = Array.isArray(selectedYAxis)
        ? selectedYAxis
        : [selectedYAxis];

      const metricNames = getFilteredMetricNames([value, ...handledYAxis]);

      const updatedForm = {
        ...lineChartForm,
        [axisType]: value,
        metricNames
      };

      dispatch(dashboardChartsActions.updateChartForm(CHART_TYPE, updatedForm));
    } else {
      handleUpdateChartForm(X_AXIS_KEY, selectedOption);
    }
  };

  const renderYAxisSelect = () => {
    const { selectedYAxis } = lineChartForm;

    return (
      <div className="full-width-input mb-30">
        <InputLabel className="modal-input-label">Y-Axis</InputLabel>
        <Combobox
          data-test="line-chart-y-axis-select"
          value={selectedYAxis}
          onValueChange={selectedOptions => {
            handleSelectYAxis(selectedOptions);
          }}
          options={handledYValues}
          maxHeight={280}
          maxWidth={400}
          truncateMiddle
          menuShouldBlockScroll
          isRegexSearchEnabled
          searchPlaceholder="Search (regex)"
        />
      </div>
    );
  };

  const getXAxisGroupedOptions = () => {
    const timeSeriesTypes = Object.values(COMET_TIME_SERIES_AXES_MAP);
    const timeOptions = timeSeriesTypes.map(type =>
      formatValueForSelectOption(type, undefined, timeSeriesTypes)
    );

    return [
      {
        label: 'Times series',
        options: timeOptions
      },
      {
        label: 'Metrics',
        options: yValues
      }
    ];
  };

  const renderXAxisSelect = () => {
    const formValue = lineChartForm[X_AXIS_KEY];
    const selectValue = formValue || null;
    const groupedOptions = getXAxisGroupedOptions();
    const flattenedOptions = flatten(groupedOptions.map(arr => arr.options));

    return (
      <div className="mb-30">
        <InputLabel className="modal-input-label">X-Axis</InputLabel>
        <div style={{ width: '260px' }}>
          <Select
            data-test="line-chart-x-axis-select"
            value={selectValue}
            onValueChange={selectedOption => {
              handleSelectXAxis(X_AXIS_KEY, selectedOption);
            }}
            maxWidth={260}
            truncateMiddle
            options={flattenedOptions}
            isClearable={false}
            maxHeight={280}
            isSearchable
            menuShouldBlockScroll
            isRegexSearchEnabled
          />
        </div>
      </div>
    );
  };

  const handleOutlierSelect = value => {
    handleUpdateChartFormKey('selectedOutliers', value);
  };

  const renderOutliersSelect = () => {
    const formKey = 'selectedOutliers';
    const formValue = lineChartForm[formKey];

    const options = [
      { label: 'Show', value: OUTLIERS_VALUES.SHOW },
      { label: 'Ignore', value: OUTLIERS_VALUES.NOT_VISIBLE }
    ];

    return (
      <>
        <InputLabel className="modal-input-label">Outliers</InputLabel>

        <Box display="flex">
          {options.map(({ value, label }) => (
            <Box
              marginRight="28px"
              display="flex"
              alignItems="center"
              key={value}
            >
              <RadioButton
                onClick={() => handleOutlierSelect(value)}
                checked={value === formValue}
              />
              <p className="radio-label">{label}</p>
            </Box>
          ))}
        </Box>
      </>
    );
  };

  const renderXAggregationControls = () => {
    const formKey = 'aggregationX';
    const formValue = get(
      lineChartForm,
      formKey,
      AGGREGATION_TYPES_OBJECT.LAST
    );

    const options = Object.values(LINE_CHART_X_AGGREGATIONS);

    return (
      <div className="modal-input-group half">
        <InputLabel
          className="modal-input-label"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Aggregation
        </InputLabel>

        <Select
          value={formValue}
          onValueChange={value => {
            return handleUpdateChartForm(formKey, value);
          }}
          noOptionsMessage={() =>
            'To select more than one y-axis metric at a time, set the x-axis to a time series'
          }
          options={options}
          isClearable={false}
          menuShouldBlockScroll
          maxWidth={130}
          truncateMiddle
        />
      </div>
    );
  };

  const renderTransformSelect = (type, selectOptions) => {
    const labelPrefix = type === 'transformY' ? 'Y' : 'X';
    const labelSuffix = 'Axis Transformation';
    const label = `${labelPrefix}-${labelSuffix}`;
    const { width, ...props } = selectOptions;
    const selectValue = lineChartForm[type];

    const options = [{ value: null, label: 'None' }].concat(
      TRANSFORM_TYPES_ARRAY.map(transformType =>
        formatValueForSelectOption(transformType)
      )
    );

    return (
      <div className={cx({ 'mb-30': type !== 'transformY' })}>
        <InputLabel className="modal-input-label">{label}</InputLabel>
        <div style={{ width: width || 400 }}>
          <Select
            {...(props || {})}
            maxWidth={width || 400}
            value={selectValue}
            onValueChange={value => {
              return handleUpdateChartFormKey(type, value);
            }}
            noOptionsMessage={() =>
              'To select more than one y-axis metric at a time, set the x-axis to a time series'
            }
            options={options}
            isClearable={false}
            menuShouldBlockScroll
          />
        </div>
      </div>
    );
  };

  const renderSmoothingSlider = type => {
    const labelPrefix = type === 'smoothingY' ? 'Y' : 'X';
    const labelSuffix = 'Axis Smoothing';
    const label = `${labelPrefix}-${labelSuffix}`;

    return (
      <div
        style={{
          marginLeft: '22px'
        }}
      >
        <InputLabel className="modal-input-label">{label}</InputLabel>
        <Box width={180} height={30} display="flex" alignItems="center">
          <Slider
            variant="type-2"
            onChange={value => handleSliderChange(type, value)}
            min={0}
            max={1}
            step={0.001}
            value={lineChartForm[type]}
          />
        </Box>
      </div>
    );
  };

  const renderYAxisControls = () => {
    return (
      <>
        {renderYAxisSelect()}
        <Box className="mb-60" display="flex">
          {renderTransformSelect('transformY', {
            width: 200
          })}
          {renderSmoothingSlider('smoothingY')}
        </Box>
      </>
    );
  };

  const renderXAxisControls = () => {
    return (
      <>
        <Box display="flex" width={400} justifyContent="space-between">
          {renderXAxisSelect()}
          {renderXAggregationControls()}
        </Box>
        <Box display="flex" width={400} justifyContent="space-between">
          {renderTransformSelect('transformX', { width: 200 })}
          {renderSmoothingSlider('smoothingX')}
        </Box>
      </>
    );
  };

  const renderFillTypeSelect = () => {
    const formKey = 'fillType';
    const { LINE, AREA, STACKED, NORMALIZED_STACKED } = CHART_CONFIG_MODIFIERS;
    const options = [LINE, AREA, STACKED, NORMALIZED_STACKED];
    return (
      <div className="full-width-input mb-60">
        <InputLabel className="modal-input-label">Line type</InputLabel>

        <div>
          <Select
            value={fillType}
            onValueChange={val => handleUpdateChartForm(formKey, val)}
            options={options}
            isClearable={false}
            menuShouldBlockScroll
            maxWidth={400}
            truncateMiddle
          />
        </div>
      </div>
    );
  };

  return (
    <div className="line-chart-data-tab">
      {renderFillTypeSelect()}
      {renderYAxisControls()}
      {renderXAxisControls()}
      {renderOutliersSelect()}
    </div>
  );
};

export default memo(LineChartDataTab);
