import { normalizeColumnName } from '@API/helpers/v2_helpers';
import { capitalize } from 'lodash';
import isString from 'lodash/isString';
import { Duration } from 'luxon';
import moment from 'moment';
import React from 'react';

const DISPLAY_NAMES = {
  '#': '#',
  'project-name': 'Project name',
  acc: 'Accuracy',
  addToGraph: 'Display on chart',
  apiKey: 'Api Key',
  base: 'Experiment 1',
  baseRun: 'Experiment 1',
  baseRunCurrent: 'Experiment 1',
  baseRunMax: 'Max',
  baseRunMin: 'Min',
  baseRunValue: 'Experiment 1',
  batch_size: 'Batch size',
  batch_loss: 'Batch Loss',
  batch_accuracy: 'Batch Accuracy',
  batch: 'Batch',
  code: 'Code',
  codeSHA: 'Code SHA',
  comet_chart_color: ' ',
  compare: 'Experiment 2',
  compareRun: 'Experiment 2',
  compareRunCurrent: 'Experiment 2',
  compareRunMax: 'Max',
  compareRunMin: 'Min',
  compareRunValue: 'Experiment 2',
  curr_epoch: 'Current epoch',
  curr_step: 'Current Step',
  colsample_bytree: 'Colsample Bytree',
  CreatedAt: 'Created At',
  LastUpdatedAt: 'Last Updated At',
  current: 'Value',
  dataset_hash: 'Dataset hash',
  delete: ' ',
  do_validation: 'Do validation',
  duration: 'Duration',
  empty: ' ',
  end_local_timestamp: 'Local end time',
  env_user: 'Environment User',
  end_server_timestamp: 'Server end time',
  hasCrashed: 'Has crashed',
  epochs: 'Epochs',
  epoch_duration: 'Epoch Duration',
  eval_metric: 'Eval Metric',
  expand: ' ',
  experimentKey: 'Experiment key',
  experimentKeys: 'experiment keys',
  experimentNames: 'experiment names',
  experimentTags: 'Tags',
  hasImages: 'Has Images',
  hasHtml: 'Has HTML',
  hasNotes: 'Has Notes',
  file_name: 'File name',
  filePath: 'File path',
  graph: 'Graph',
  index: '#',
  installedPackages: 'Installed packages',
  installed_packages: 'Installed packages',
  isVisibleOnDashboard: 'Visible',
  key: 'Key',
  local_timestamp: 'Local time',
  'log(value)': (
    <span>
      log<sub>e</sub>(x)
    </span>
  ),
  'log(10)': (
    <span>
      log<sub>10</sub>(x)
    </span>
  ),
  loss: 'Loss',
  learning_rate: 'Learning Rate',
  last_message_time: 'Last Message Time',
  max: 'Max value',
  max_depth: 'Max Depth',
  metricName: 'Key',
  metricValue: 'Value',
  min: 'Min value',
  name: 'Name',
  num: '#',
  optimizationId: 'Optimization Id',
  os_type: 'OS Type',
  paramName: 'Key',
  python_version_verbose: 'Python Version Verbose',
  python_version: 'Python Version',
  python_exe: 'Python Execution',
  paramValue: 'Value',
  runActive: 'Status',
  RMSprop_learning_rate: 'RMSprop Learning Rate',
  RMSprop_epsilon: 'RMSprop Epsilon',
  RMSprop_rho: 'RMSprop RHO',
  RMSprop_decay: 'RMSprop Decay',
  trainable_params: 'Trainable Params',
  runName: ' ',
  samples: 'Samples',
  server_timestamp: 'Server time',
  storage_size_bytes: 'Storage Size (Bytes)',
  singleExperimentBtn: ' ',
  size: 'Size',
  start_local_timestamp: 'Local start time',
  start_server_timestamp: 'Server start time',
  'sys.ram.total': 'System Total Ram',
  'sys.load.avg': 'System Average Load',
  'sys.ram.used': 'System Used Ram',
  SourceExperiment: 'Source Experiment',
  'sys.cpu.percent.01': 'System CPU 1% percent',
  'sys.cpu.percent.02': 'System CPU 2% percent',
  'sys.cpu.percent.03': 'System CPU 3% percent',
  'sys.cpu.percent.04': 'System CPU 4% percent',
  'sys.cpu.percent.05': 'System CPU 5% percent',
  'sys.cpu.percent.06': 'System CPU 6% percent',
  'sys.cpu.percent.07': 'System CPU 7% percent',
  'sys.cpu.percent.08': 'System CPU 8% percent',
  'sys.cpu.percent.avg': 'System CPU Average Percent',
  stdout: 'Stdout',
  user_name: 'User Name',
  value: 'Value',
  validation_1_curr_step: 'Validation current Step',
  validation_0_rmse: 'Validation 0 rmse',
  validation_1_rmse: 'Validation 1 rmse',
  val_accuracy: 'Validation Accuracy',
  validate_curr_step: 'Validate Current Step',
  val_loss: 'Validation Loss',
  validate_batch_accuracy: 'Validate Batch Accuracy',
  validate_batch_loss: 'Validate Batch Loss',
  validation_0_curr_step: 'Validation 0 Current Step',
  valueCurrent: 'Value',
  valueMax: 'Max value',
  valueMin: 'Min value',
  verbose: 'Verbose'
};

export const DATE_TIME_COLUMN_NAMES = [
  'last_message_time',
  'end_server_timestamp',
  'local_timestamp',
  'server_timestamp'
];

export const formatNameForDisplay = (name, nameMap = {}) => {
  const { keyName, kind } = normalizeColumnName(name);

  const formattedName = nameMap[keyName] || DISPLAY_NAMES[keyName] || keyName;

  if (kind) {
    return `${formattedName} (${capitalize(kind.toLowerCase())})`;
  }
  return formattedName;
};

export const formatDateTime = value => {
  const dateTimeFormat = 'M/D/YY hh:mm A';
  if (moment(value).isValid()) {
    const utcValue = isString(value) ? `${value} UTC` : value;

    return moment(utcValue).format(dateTimeFormat);
  }
  return '';
};

export const formatTimeNumber = seconds => {
  return Duration.fromObject({ seconds }).toFormat('hh:mm:ss');
};

export const formatDateAsUtc = datetime => {
  return moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss');
};

export const formatDateTimeZone = datetime => {
  return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
};

export const getTextWidth = (text, font, properties) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  if (properties) {
    Object.keys(properties).forEach(key => {
      context[key] = properties[key];
    });
  }

  return context.measureText(text).width;
};

export const truncateMiddleOfStringToFitWidth = (
  string,
  maxWidth,
  symbol = '...'
) => {
  if (!string || !maxWidth) return string;

  const parsedMaxWidth = Number.parseFloat(maxWidth);

  const textWidth = getTextWidth(string);

  const widthOfTextToTruncate = textWidth - parsedMaxWidth;
  const avgWidthOfCharacter = textWidth / string.length;
  const numCharactersToTruncate = Math.ceil(
    widthOfTextToTruncate / avgWidthOfCharacter
  );

  if (textWidth > parsedMaxWidth) {
    const halfLength = string.length / 2;

    const start = string.substring(0, halfLength - numCharactersToTruncate / 2);
    const end = string.substring(halfLength + numCharactersToTruncate / 2);

    return `${start}${symbol}${end}`;
  }

  return string;
};

export const truncateMiddleOfStringByMaxCharacters = (
  string,
  maxCharacters,
  symbol = '...'
) => {
  if (!string || !maxCharacters || string.length < maxCharacters) return string;

  const halfLength = Math.ceil((maxCharacters - symbol.length) / 2);

  const start = string.slice(0, halfLength);
  const end = string.slice(-1 * halfLength);

  return `${start}${symbol}${end}`;
};

export const truncateByMaxCharacters = (
  string,
  maxCharacters,
  symbol = '...'
) => {
  if (!string || !maxCharacters || string.length < maxCharacters) return string;
  return `${string.slice(0, maxCharacters - symbol.length)}${symbol}`;
};

export const generateRegexFromString = search => {
  try {
    return new RegExp(search, 'i');
  } catch (e) {
    return null;
  }
};

export const getFileBasename = path => {
  return path.split('/').reverse()[0];
};
