import React from 'react';

import { Menu } from '@DesignSystem/navigation';
import styles from './CustomTransformationsPage.modules.scss';

type NavItem = {
  label: string;
  prefixIcon: React.ReactNode;
  value: string;
};

type ModelSettingsProps = {
  navItems: NavItem[];
  activeItem: string;
  handleNavChange: (menuItem: NavItem) => void;
};

const ModelSettings = ({
  navItems,
  activeItem,
  handleNavChange
}: ModelSettingsProps) => {
  return (
    <div className={styles.modelSettings}>
      <h2>Model Settings</h2>
      <Menu
        items={navItems}
        onClickItem={handleNavChange}
        activeItem={activeItem}
      />
    </div>
  );
};

export default ModelSettings;
