import React from 'react';
import cx from 'classnames';
import { OptionType } from '@design-system/types';
import { MenuProps, components } from 'react-select';
import { SearchInput } from '../../SearchInput';
import classes from '../Select.module.scss';

const Menu = (props: MenuProps<OptionType>) => {
  const {
    selectProps: {
      withSearch,
      onInputChange,
      inputValue,
      onMenuInputFocus,
      searchPlaceholder,
      isRegexSearchEnabled
    }
  } = props;

  const focusMenu = onMenuInputFocus as (v: boolean) => void;

  const onMouseDown: React.MouseEventHandler<HTMLInputElement> = e => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    target.focus();
  };
  const onTouchEnd: React.TouchEventHandler<HTMLInputElement> = e => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    target.focus();
  };
  const onFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    focusMenu(true);
    e.stopPropagation();
  };

  const defaultPlaceholder = isRegexSearchEnabled
    ? 'Search (regex)'
    : 'Search...';

  return (
    <div
      className={cx(classes.menuContainer, {
        [classes.topPlacement]: props.placement === 'top'
      })}
    >
      {withSearch && (
        <div className={classes.listSearchField}>
          <SearchInput
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            placeholder={searchPlaceholder || defaultPlaceholder}
            autoFocus
            value={inputValue}
            onChange={e =>
              onInputChange(e.currentTarget.value, {
                action: 'input-change',
                prevInputValue: inputValue
              })
            }
            onMouseDown={onMouseDown}
            onTouchEnd={onTouchEnd}
            onFocus={onFocus}
            onClearIconClick={() => {
              onInputChange('', {
                action: 'input-change',
                prevInputValue: inputValue
              });
            }}
            searchIconClassName={classes.searchIcon}
            data-test="select-search-input"
          />
        </div>
      )}
      <components.Menu {...props}>{props.children}</components.Menu>
    </div>
  );
};

export default Menu;
