import React, { useMemo, useState } from 'react';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import ExperimentTag from '../ExperimentTag';
import RemainingTags from './RemainingTags';
import useExperimentTags, {
  ExperimentTagData
} from '@shared/hooks/useExperimentTags';
import useResizeObserver, {
  ObservedSize
} from '@/helpers/custom-hooks/useResizeObserver';

// should be
const TAG_SYMBOL_WIDTH = 6;
const TAG_PADDING = 16;
const TAG_SYMBOL_GAP = 5;

const getTagWidth = (tag: ExperimentTagData) =>
  TAG_PADDING + TAG_SYMBOL_GAP + TAG_SYMBOL_WIDTH * tag.name.length;

type ResizableExperimentTagListProps = {
  row: {
    tags: string[];
    experimentKey: string;
  };
  isDeleteDisabled?: boolean;
};

const ResizableExperimentTagList: React.FC<ResizableExperimentTagListProps> = ({
  row,
  isDeleteDisabled = false
}) => {
  const [{ width: containerWidth = 100 }, setSize] = useState<ObservedSize>(
    {} as ObservedSize
  );
  const onResize = useMemo(() => debounce(setSize, 100, { leading: true }), []);
  const { ref: tagsContainerRef } = useResizeObserver({ onResize });

  const experimentTagList = useExperimentTags({
    filteredList: row.tags
  });

  if (!experimentTagList.length || isEmpty(row.tags) || !isArray(row.tags))
    return null;

  const totalTagListWidth = experimentTagList.reduce(
    (acc, tag) => acc + getTagWidth(tag),
    0
  );

  const renderExperimentTag = (tag: ExperimentTagData) => (
    <ExperimentTag
      key={tag.name}
      tagName={tag.name}
      tagColor={tag.color}
      experimentKey={row.experimentKey}
      isDeleteDisabled={isDeleteDisabled}
    />
  );

  return (
    <div
      ref={tagsContainerRef}
      className="tag-row-container column-tag-list"
      onClick={e => e.stopPropagation()}
    >
      {experimentTagList.map(renderExperimentTag)}

      {totalTagListWidth > containerWidth && (
        <RemainingTags>
          {experimentTagList.map(renderExperimentTag)}
        </RemainingTags>
      )}
    </div>
  );
};

export default React.memo(ResizableExperimentTagList);
