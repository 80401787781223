import React from 'react';
import { BasicModal } from '@DesignSystem/modals';
import styles from '../OtherSingleTable.module.scss';

export type DeleteOtherParamModalProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  paramKey: string;
};
const DeleteOtherParamModal: React.FC<DeleteOtherParamModalProps> = ({
  isOpened,
  onClose,
  onConfirm,
  paramKey
}) => {
  return (
    <BasicModal
      open={isOpened}
      onClose={onClose}
      title="Are you sure?"
      content={
        <div className={styles.otherTableDeleteModalContent}>
          The parameter <b>{paramKey}</b> will be removed. Once deleted, it
          cannot be recovered.
        </div>
      }
      primaryButtonText="Yes, delete"
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={onConfirm}
    />
  );
};

export default DeleteOtherParamModal;
