import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Button } from '@ds';
import { RoundedTabs } from '@DesignSystem/navigation';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import { FullWidthBasicModal } from '@DesignSystem/modals';
import { Divider } from '@DesignSystem/data-display';
import { FiltersButton } from '../FiltersButton';
import { SortPopover } from '../SortPopover';
import { BETA_FEATURE_MODEL_PRODUCTION_MONITORING_MODELS_PIPELINE } from '@/lib/betaFeatures';
import { DAGContent } from '../DAGContent';
import './ModelsHeader.scss';
import { DSSortIcon } from '@ds-icons';

const useStyles = makeStyles({
  wrapper: {
    padding: '15px 20px',
    '& .filters': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    '& .title': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#191A1C',
      margin: 0,
      padding: 0
    },
    '& .selections': {
      '& svg': {
        margin: '0 5px'
      }
    },
    '& .alerts-container': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    '& .alerts-container p': {
      fontSize: 13,
      marginLeft: 5,
      color: 'var(--gray-6)'
    }
  }
});

type Column = {
  columnName: string;
  direction: string;
  sortKey?: string;
};

type SortButtonProps = {
  columns: Record<string, string>[];
  anchorEl: HTMLElement | null;
  columnSorting: Column[];
  onChange: () => void;
  setAnchorEl: (val: HTMLElement | null) => void;
};

const SortButton = ({
  columns,
  anchorEl,
  columnSorting,
  onChange,
  setAnchorEl
}: SortButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const countLabel = columnSorting.length ? ` (${columnSorting.length})` : '';

  return (
    <div>
      <Button
        type="secondary"
        size="small"
        active={Boolean(anchorEl)}
        iconSize="small"
        onClick={handleClick}
        PrefixIcon={<DSSortIcon />}
        data-test="model-heading-sorting"
      >
        Sort {countLabel}
      </Button>
      <SortPopover
        columns={columns}
        anchorEl={anchorEl}
        columnSorting={columnSorting}
        onChange={onChange}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </div>
  );
};

type TabsProps = {
  activeTabIndex: number;
  setActiveTabIndex: (idx: number) => void;
  selection: string[];
};

export const Tabs = ({
  activeTabIndex,
  setActiveTabIndex,
  selection
}: TabsProps) => {
  return (
    <div className="header-tabs-wrapper">
      {/* // Fixes blinking issue  style={{ width: '163px' }} below */}
      <div style={{ width: '163px' }}>
        <RoundedTabs
          tabs={[
            { label: 'All' },
            {
              label: selection.length
                ? `Selected (${selection.length})`
                : 'Selected  ',
              disabled: selection.length === 0
            }
          ]}
          activeTabIndex={activeTabIndex}
          onTabChange={setActiveTabIndex}
        />
      </div>
    </div>
  );
};

type Rule = {
  condition: string;
  rules: Rule[];
};

type ModelsHeaderProps = {
  activeTabIndex: number;
  columnSorting: { columnName: string; direction: string }[];
  columns: Record<string, string>[];
  onFilterChange: () => void;
  onSortingChange: () => void;
  query: {
    rulesTree: { condition: string; rules: Rule[] };
    segmentId: string;
  };
  selection: string[];
  setActiveTabIndex: (val: number) => void;
};

function ModelsHeader({
  columns,
  onSortingChange,
  columnSorting,
  selection,
  activeTabIndex,
  setActiveTabIndex,
  query,
  onFilterChange
}: ModelsHeaderProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const renderSortAndFilters = useMemo(
    () => (
      <>
        <Box alignItems="center" display="flex">
          <p className="title">Models</p>
          <FiltersButton
            onChange={onFilterChange}
            query={query}
            columns={columns}
          />
          <SortButton
            onChange={onSortingChange}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            columnSorting={columnSorting}
            columns={columns}
          />
        </Box>
      </>
    ),
    [anchorEl, columnSorting, columns, onFilterChange, onSortingChange, query]
  );

  const modelsPipelineEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_PRODUCTION_MONITORING_MODELS_PIPELINE
  );

  const { wrapper } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Box className={wrapper}>
      <div className="filters">
        {renderSortAndFilters}
        {modelsPipelineEnabled && (
          <>
            <FullWidthBasicModal
              data-test="pipeline-modal"
              open={isOpen}
              onClose={handleCloseModal}
              content={<DAGContent closeModal={handleCloseModal} />}
              withoutFooter
              customHeaderContent={
                <span className="header-section">Create DAG</span>
              }
              className="DAGModal"
              // TODO: DELETE props below later after ts migration main Component
              title={undefined}
              primaryButtonText={undefined}
              variant={undefined}
              onPrimaryButtonClick={undefined}
              secondaryButtonText={undefined}
              stepActiveIndex={undefined}
              stepAmount={undefined}
              onSecondaryButtonClick={undefined}
              isPrimaryButtonDisabled={undefined}
              isPrimaryButtonLoading={undefined}
              footer={undefined}
              withBackButton={undefined}
              onBackButtonClick={undefined}
              headerTitle={undefined}
              titleMaskTest={undefined}
            />
            <Button onClick={handleOpenModal}>Create DAG</Button>
          </>
        )}
      </div>
      {!modelsPipelineEnabled && (
        <>
          <Divider margin="15px" />
          <Tabs
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            selection={selection}
          />
        </>
      )}
    </Box>
  );
}

export default React.memo(ModelsHeader);
