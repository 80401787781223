import React, { ReactNode } from 'react';

import { ListFooter, ListFooterActionLink } from './components';
import classes from './List.module.scss';

interface ListProps {
  children: ReactNode;
}

const List = ({ children }: ListProps) => {
  return <ul className={classes.listContainer}>{children}</ul>;
};

List.Footer = ListFooter;
List.FooterActionLink = ListFooterActionLink;
export default List;
