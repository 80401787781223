import React from 'react';

import cx from 'classnames';
import styles from './ExperimentBar.module.scss';

type WrapperType = 'custom' | 'stepSlider';

type ExperimentBarCustomProps = {
  label?: string;
  component: React.ReactNode;
  wrapperType?: WrapperType;
};

export const ExperimentBarCustom = ({
  label = '',
  component,
  wrapperType = 'custom'
}: ExperimentBarCustomProps) => {
  return (
    <div className={cx(styles.component, styles[wrapperType])}>
      <div className={styles.title}>{label}</div>
      <div className={styles.componentWrapper}>{component}</div>
    </div>
  );
};

export default ExperimentBarCustom;
