import React from 'react';
import { push } from 'connected-react-router';

import projectsApi from '../util/projectsApi';
import { projectsActionTypes } from '@/constants/actionTypes';
import alertsUtil from '../util/alertsUtil';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import experimentActions from './experimentActions';
import MoveProjectModal from '@projects/components/MoveProjectModal';
import UpdateImageModal from '@projects/components/UpdateImageModal';

const projectsActions = {
  clearErrors() {
    return {
      type: projectsActionTypes.CLEAR_PROJECTS_ERRORS
    };
  },

  validateProjectBeingAdded(validatedProjectName) {
    return {
      type: projectsActionTypes.VALIDATE_PROJECT_BEING_ADDED,
      validatedProjectName
    };
  },

  validateProjectName(projectName) {
    return dispatch => {
      const data = { projectName };
      return projectsApi.validateProjectName(data).then(response => {
        const validatedProjectName = response.msg;
        dispatch(
          projectsActions.validateProjectBeingAdded(validatedProjectName)
        );
      });
    };
  },

  changeProjectsPrivacy(projectId, isPublic, projectName, ownerName) {
    return dispatch => {
      const data = { projectId, isPublic };
      return projectsApi
        .changeProjectsPrivacy(data)
        .then(() => {
          projectsApi
            .fetchProjectDataByOwner(ownerName, projectName)
            .then(response => {
              const project = response.data;
              if (!project) {
                dispatch(
                  experimentActions.fetchExperimentWithUserUnsuccessful()
                );
                dispatch(push(`/${ownerName}`));
                return Promise.resolve({ projectId: null });
              }
              dispatch({
                type: projectsActionTypes.SET_PROJECT,
                payload: {
                  projectId,
                  projectData: project
                }
              });
              dispatch(
                alertsUtil.openSnackbarDialog(
                  snackbarTypes.SUCCESS_EDIT_PROJECT,
                  'Project successfully updated.'
                )
              );
            });
        })
        .catch(error => {
          const { msg } = error.response.data;

          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_EDIT_PROJECT,
              msg
            )
          );
        });
    };
  },

  setSelectedProjectId(projectId) {
    return {
      type: projectsActionTypes.SET_SELECTED_PROJECT_ID,
      payload: projectId
    };
  },

  addShareableLink(projectId) {
    return dispatch => {
      return projectsApi
        .addShareableLink(projectId)
        .then(() => dispatch(projectsActions.getShareableLinks(projectId)))
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              projectsActionTypes.FETCH_PROJECT,
              'There was an error adding a shareable link'
            )
          );
        });
    };
  },

  deleteShareableLink(projectId, shareCode) {
    return dispatch => {
      return projectsApi
        .deleteShareableLink(projectId, shareCode)
        .then(() => dispatch(projectsActions.getShareableLinks(projectId)))
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              projectsActionTypes.FETCH_PROJECT,
              'There was an error deleting a shareable link'
            )
          );
        });
    };
  },

  getShareableLinks(projectId) {
    return dispatch => {
      return projectsApi
        .getShareableLinks(projectId)
        .then(res => res.data.shareCodes)
        .then(shareableCodes => {
          dispatch({
            type: projectsActionTypes.SET_SHAREABLE_LINKS,
            payload: shareableCodes
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              projectsActionTypes.FETCH_PROJECT,
              'There was an error fetching shareable links'
            )
          );
        });
    };
  },

  setActiveEMHeaderTab(tabName) {
    return {
      type: projectsActionTypes.SET_ACTIVE_EM_HEADER_TAB,
      payload: tabName
    };
  },

  setActiveLLMHeaderTab(tabName) {
    return {
      type: projectsActionTypes.SET_ACTIVE_LLM_HEADER_TAB,
      payload: tabName
    };
  },

  clearProjectsUi() {
    return {
      type: projectsActionTypes.CLEAR_PROJECTS_UI
    };
  },

  setStoredSelectedExperiments(selection) {
    return {
      type: projectsActionTypes.SET_STORED_EXPERIMENT_SELECTION,
      payload: { selection }
    };
  },

  openMoveProjectModal(project) {
    return dispatch => {
      const modalId = dialogTypes.CREATE_PROJECT_MODAL;

      const modal = (
        <MoveProjectModal
          modalId={modalId}
          dispatch={dispatch}
          moveConfig={project}
        />
      );

      dispatch(alertsUtil.openCustomModal(modalId, modal));
    };
  },

  openUpdateImageModal(projectId) {
    return dispatch => {
      const modalId = dialogTypes.CREATE_PROJECT_MODAL;

      const modal = (
        <UpdateImageModal projectId={projectId} modalId={modalId} />
      );

      dispatch(alertsUtil.openCustomModal(modalId, modal));
    };
  },

  setEditViewName(viewName) {
    return {
      type: projectsActionTypes.SET_EDIT_VIEW_NAME,
      payload: viewName
    };
  },

  setViewHasUnsavedChanges(hasUnsavedChanges) {
    return {
      type: projectsActionTypes.SET_VIEW_HAS_UNSAVED_CHANGES,
      payload: hasUnsavedChanges
    };
  }
};

export default projectsActions;
