import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledTab = withStyles({
  root: {
    color: '#5F677E',
    fontWeight: 'normal',
    fontSize: '14px',
    minWidth: 0,
    opacity: 1,
    minHeight: '51px',

    '&:last-child': {
      margin: 0
    }
  },
  selected: {
    color: '#191A1C'
  }
})(Tab);

export default StyledTab;
