import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { EMPTY_TABLE_COLSPAN } from '@shared/constants/empty-table';
import { NoTrackedModelsView } from '../NoTrackedModelsView';
import { FeaturesIcon } from '@Icons-outdated';

export const RenderEmptyState: React.FC<Table.CellProps> = props => {
  return (
    <Table.Cell
      {...props}
      value={null}
      row={{}}
      column={{ name: 'feature_key' }}
      style={{ borderBottom: 'unset' }}
      colSpan={EMPTY_TABLE_COLSPAN}
    >
      <NoTrackedModelsView
        icon={<FeaturesIcon />}
        title="No features are being tracked"
      />
    </Table.Cell>
  );
};
