import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  CHART_GALLERY_COLUMNS,
  TEMPLATE_SCOPE_TYPES
} from '@experiment-management-shared/constants/chartsGallery';

import ChartTemplateRow from './ChartTemplateRow';
import { BuiltInGallery } from './BuiltInGallery';

const GalleryTable = ({ activeTab, chartTemplates, onChangeTemplate }) => {
  const isBuiltIn = activeTab === TEMPLATE_SCOPE_TYPES.BUILT_IN;

  if (isBuiltIn) {
    return <BuiltInGallery charts={chartTemplates} />;
  }

  const renderTemplateRow = template => (
    <ChartTemplateRow
      activeTab={activeTab}
      key={template[CHART_GALLERY_COLUMNS.ID]}
      template={template}
      onChangeTemplate={onChangeTemplate}
    />
  );

  return (
    <div className={cx('charts-gallery-table-container', activeTab)}>
      {chartTemplates.map(renderTemplateRow)}
    </div>
  );
};

GalleryTable.defaultProps = {
  chartTemplates: []
};

GalleryTable.propTypes = {
  activeTab: PropTypes.string.isRequired,
  chartTemplates: PropTypes.array,
  onChangeTemplate: PropTypes.func.isRequired
};

export default GalleryTable;
