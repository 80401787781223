import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const height = 23;
const borderRadius = 25;

const StyledTabs = withStyles({
  root: {
    backgroundColor: '#F7F8FA',
    borderRadius,
    height,
    minHeight: 'unset'
  },
  indicator: {
    backgroundColor: 'unset'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    display: 'flex',
    minWidth: 'fit-content',
    height,
    minHeight: 'unset',
    borderRadius,
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 500,
    color: '#8C95A8',
    opacity: 1,
    animation: '0.3s ease-in-out',
    '&.shortTab': {
      flex: 0.4
    },
    '&:hover': {
      color: '#5155F5'
    }
  },
  wrapper: {
    pointerEvents: 'none'
  }
})(Tab);

const RoundedTabs = ({
  activeTabIndex,
  onTabChange,
  tabs,
  equalWidth,
  containerWidth
}) => {
  const [width, setWidth] = useState();
  const [translateValue, setTranslate] = useState(0);

  useEffect(() => {
    const node = tabsRef.current?.querySelectorAll('button');
    if (node && node[activeTabIndex]) {
      const w = node[activeTabIndex].offsetWidth;
      let t = 0;
      for (let i = 0; i < activeTabIndex; i++) {
        t += node[i].offsetWidth;
      }

      setWidth(w);
      setTranslate(t);
    }
  }, [activeTabIndex, tabs.length, containerWidth]);

  const tabsRef = useRef();

  const handleChange = (event, tabIndex) => {
    onTabChange(tabIndex);
  };

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <div
        style={{
          background: '#5155F5',
          width,
          transition: 'all 0.4s ease',
          transform: `translateX(${translateValue}px)`,
          left: '0',
          height: 23,
          borderRadius,
          position: 'absolute',
          flex: 1
        }}
      />
      <StyledTabs
        value={activeTabIndex}
        onChange={handleChange}
        variant="fullWidth"
        ref={tabsRef}
      >
        {tabs.map(({ label, disabled = false }, i) => (
          <StyledTab
            className={i === 0 && !equalWidth ? 'shortTab' : ''}
            key={label}
            label={label}
            disabled={disabled}
            style={{ color: activeTabIndex === i ? 'white' : '#8C95A8' }}
          />
        ))}
      </StyledTabs>
    </div>
  );
};

RoundedTabs.defaultProps = {
  equalWidth: false,
  containerWidth: 0
};

RoundedTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  equalWidth: PropTypes.bool,
  containerWidth: PropTypes.number
};

export default RoundedTabs;
