// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsHeader-module__header--pZ_VX{align-items:center;display:flex;justify-content:space-between;padding:16px 0}.ReportsHeader-module__actions--S84Gc{display:flex;gap:16px}.ReportsHeader-module__divider--d3XV5{background-color:#bebebe;flex-shrink:0;height:16px;margin:0 16px;width:1px}.ReportsHeader-module__reportsAmount--MkC7Y{color:#000;font-weight:500}.ReportsHeader-module__rightSection--UAnHr{align-items:center;display:flex}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ReportsHeader-module__header--pZ_VX`,
	"actions": `ReportsHeader-module__actions--S84Gc`,
	"divider": `ReportsHeader-module__divider--d3XV5`,
	"reportsAmount": `ReportsHeader-module__reportsAmount--MkC7Y`,
	"rightSection": `ReportsHeader-module__rightSection--UAnHr`
};
module.exports = ___CSS_LOADER_EXPORT___;
