import React from 'react';
import { useParams } from 'react-router';

import { useModelRegistryGroupedHistory } from '@API/model-registry/useModelRegistryHistory';
import ModelRegistryHistory from '@model-registry/components/ModelRegistryHistory';

function ModelRegistryHistoryTab() {
  const { modelName, workspace: workspaceName } = useParams();

  const {
    data: groupedHistoryItemsByDate = {},
    isLoading
  } = useModelRegistryGroupedHistory(
    { modelName, workspaceName },
    { enabled: !!modelName && !!workspaceName }
  );

  const allHistoryItemsKeys = Object.keys(groupedHistoryItemsByDate) || [];
  const isHistoryEmpty = !allHistoryItemsKeys.length;

  return (
    <ModelRegistryHistory
      allHistoryItemsKeys={allHistoryItemsKeys}
      isHistoryEmpty={isHistoryEmpty}
      isLoading={isLoading}
      groupedHistoryItemsByDate={groupedHistoryItemsByDate}
    />
  );
}

ModelRegistryHistoryTab.propTypes = {};

export default ModelRegistryHistoryTab;
