/* eslint-disable react/no-unknown-property */
import React from 'react';

export const CancelIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="8.5" r="8.5" fill="#B3B9C8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2409 8.49997L11.6194 7.11257L11.9376 6.79444C12.0539 6.67879 12.1459 6.54102 12.2081 6.38925C12.2704 6.23747 12.3016 6.07477 12.2999 5.91074C12.293 5.58724 12.1596 5.27933 11.9283 5.05301C11.697 4.8267 11.3863 4.7 11.0627 4.70008C10.8987 4.6984 10.736 4.7296 10.5842 4.79183C10.4324 4.85406 10.2947 4.94605 10.179 5.06239L8.49999 6.75909L6.80329 5.06239C6.68706 4.94685 6.54918 4.85537 6.39754 4.7932C6.2459 4.73103 6.08349 4.69938 5.9196 4.70008C5.59607 4.70469 5.28723 4.83586 5.05927 5.06547C4.83131 5.29509 4.70237 5.60487 4.7001 5.92841C4.6994 6.0923 4.73105 6.25472 4.79322 6.40636C4.85539 6.55799 4.94687 6.69588 5.06241 6.81211L6.75911 8.49997L5.06241 10.1878C4.94825 10.3046 4.85818 10.4428 4.7973 10.5943C4.73309 10.7421 4.69995 10.9015 4.69995 11.0627C4.69995 11.2238 4.73309 11.3833 4.7973 11.5311C4.8561 11.6811 4.94652 11.8167 5.06241 11.9287C5.17553 12.0454 5.3107 12.1386 5.46008 12.2027C5.60893 12.2624 5.76804 12.2924 5.92843 12.291C6.09177 12.2934 6.25396 12.2633 6.40563 12.2027C6.55501 12.1386 6.69017 12.0454 6.80329 11.9287L8.49999 10.2409L10.1967 11.9376C10.3123 12.0539 10.4501 12.1459 10.6019 12.2081C10.7537 12.2703 10.9164 12.3015 11.0804 12.2999C11.4054 12.2952 11.7156 12.1628 11.9438 11.9313C12.172 11.6998 12.2999 11.3878 12.2999 11.0627C12.3016 10.8987 12.2704 10.736 12.2081 10.5842C12.1459 10.4324 12.0539 10.2946 11.9376 10.179L10.2409 8.49997Z"
      fill="white"
    />
  </svg>
);

export const PanelsIcon = () => (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M54.8945 29.972L40.9329 43.9336C40.3939 44.4186 39.6934 44.6881 39.0462 44.6881C38.3457 44.6881 37.6445 44.4186 37.1055 43.9336C36.0814 42.8555 36.0814 41.1302 37.1055 40.1061L51.067 26.1445C52.0918 25.1204 53.8164 25.1204 54.8952 26.1445C55.9186 27.2226 55.9186 28.9479 54.8945 29.972Z"
        fill="#B3B9C8"
      />
      <path
        d="M54.8945 29.972L46 38.8665V31.2116L51.067 26.1445C52.0911 25.1204 53.8164 25.1204 54.8945 26.1445C55.9186 27.2226 55.9186 28.9479 54.8945 29.972Z"
        fill="#A3AABB"
      />
      <path
        d="M30.3939 46.1045C29.9889 46.1045 29.5783 46.0126 29.1908 45.8203L17.2732 39.8611C15.941 39.195 15.4012 37.5764 16.0673 36.2449C16.7363 34.9155 18.3548 34.3729 19.6843 35.039L31.6019 40.9982C32.9341 41.6643 33.4739 43.2829 32.8078 44.6144C32.334 45.5598 31.3836 46.1045 30.3939 46.1045Z"
        fill="#A3AABB"
      />
      <path
        d="M61.0951 23.718C60.2738 23.718 59.4659 23.3474 58.9367 22.6392C58.0418 21.4495 58.2839 19.7593 59.4765 18.8672L72.4161 9.16546C73.603 8.27054 75.2904 8.50708 76.1881 9.70453C77.083 10.8943 76.8409 12.5844 75.6483 13.4766L62.7087 23.1783C62.2244 23.5419 61.6587 23.718 61.0951 23.718Z"
        fill="#B3B9C8"
      />
      <path
        d="M13.6562 26.9531C9.18161 26.9531 5.57031 30.5644 5.57031 35.0391C5.57031 39.513 9.18161 43.125 13.6562 43.125C18.1309 43.125 21.7422 39.513 21.7422 35.0391C21.7422 30.5644 18.1309 26.9531 13.6562 26.9531Z"
        fill="#BFC4D1"
      />
      <path
        d="M35.2188 37.7344C30.7441 37.7344 27.1328 41.3457 27.1328 45.8203C27.1328 50.2943 30.7441 53.9062 35.2188 53.9062C39.6934 53.9062 43.3047 50.2943 43.3047 45.8203C43.3047 41.3457 39.6934 37.7344 35.2188 37.7344Z"
        fill="#BFC4D1"
      />
      <path
        d="M56.7812 16.1719C52.3066 16.1719 48.6953 19.7832 48.6953 24.2578C48.6953 28.7318 52.3066 32.3438 56.7812 32.3438C61.2559 32.3438 64.8672 28.7318 64.8672 24.2578C64.8672 19.7832 61.2559 16.1719 56.7812 16.1719Z"
        fill="#BFC4D1"
      />
      <path
        d="M78.3438 0C73.8691 0 70.2578 3.6113 70.2578 8.08594C70.2578 12.5599 73.8691 16.1719 78.3438 16.1719C82.8184 16.1719 86.4297 12.5599 86.4297 8.08594C86.4297 3.6113 82.8184 0 78.3438 0Z"
        fill="#BFC4D1"
      />
      <path
        d="M86.4297 29.6484V88.1185H70.2578V29.6484C70.2578 28.1386 71.4433 26.9531 72.9531 26.9531H83.7344C85.2442 26.9531 86.4297 28.1386 86.4297 29.6484Z"
        fill="#DBDEE7"
      />
      <path
        d="M64.8672 45.8203V88.1185H48.6953V45.8203C48.6953 44.3105 49.8808 43.125 51.3906 43.125H62.1719C63.6817 43.125 64.8672 44.3105 64.8672 45.8203Z"
        fill="#DBDEE7"
      />
      <path
        d="M43.3047 67.3828V88.1185H27.1328V67.3828C27.1328 65.873 28.3183 64.6875 29.8281 64.6875H40.6094C42.1192 64.6875 43.3047 65.873 43.3047 67.3828Z"
        fill="#DBDEE7"
      />
      <path
        d="M21.7422 56.6016V88.1185H5.57031V56.6016C5.57031 55.0918 6.75583 53.9062 8.26562 53.9062H19.0469C20.5567 53.9062 21.7422 55.0918 21.7422 56.6016Z"
        fill="#DBDEE7"
      />
      <path
        d="M78.3438 16.1719V0C82.8184 0 86.4297 3.6113 86.4297 8.08594C86.4297 12.5599 82.8184 16.1719 78.3438 16.1719Z"
        fill="#B3B9C8"
      />
      <path
        d="M86.4297 29.6484V88.1185H78.3438V26.9531H83.7344C85.2442 26.9531 86.4297 28.1386 86.4297 29.6484Z"
        fill="#C6CBD7"
      />
      <path
        d="M56.7812 32.3438V16.1719C61.2559 16.1719 64.8672 19.7832 64.8672 24.2578C64.8672 28.7318 61.2559 32.3438 56.7812 32.3438Z"
        fill="#B3B9C8"
      />
      <path
        d="M64.8672 45.8203V88.1185H56.7812V43.125H62.1719C63.6817 43.125 64.8672 44.3105 64.8672 45.8203Z"
        fill="#C6CBD7"
      />
      <path
        d="M35.2188 53.9062V37.7344C39.6934 37.7344 43.3047 41.3457 43.3047 45.8203C43.3047 50.2943 39.6934 53.9062 35.2188 53.9062Z"
        fill="#B3B9C8"
      />
      <path
        d="M43.3047 67.3828V88.1185H35.2188V64.6875H40.6094C42.1192 64.6875 43.3047 65.873 43.3047 67.3828Z"
        fill="#C6CBD7"
      />
      <path
        d="M13.6562 43.125V26.9531C18.1309 26.9531 21.7422 30.5644 21.7422 35.0391C21.7422 39.513 18.1309 43.125 13.6562 43.125Z"
        fill="#B3B9C8"
      />
      <path
        d="M21.7422 56.6016V88.1185H13.6562V53.9062H19.0469C20.5567 53.9062 21.7422 55.0918 21.7422 56.6016Z"
        fill="#C6CBD7"
      />
      <path
        d="M92 89.3047C92 90.8138 90.8145 92 89.3047 92H2.69531C1.18552 92 0 90.8138 0 89.3047C0 87.7949 1.18552 86.6094 2.69531 86.6094H89.3047C90.8145 86.6094 92 87.7949 92 89.3047Z"
        fill="#B3B9C8"
      />
      <path
        d="M92 89.3047C92 90.8138 90.8145 92 89.3047 92H46V86.6094H89.3047C90.8145 86.6094 92 87.7949 92 89.3047Z"
        fill="#A3AABB"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="92" height="92" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const NoResultsIcon = () => (
  <svg
    width="71"
    height="75"
    viewBox="0 0 71 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="35" cy="69.7249" rx="34" ry="4.5" fill="#F5F7FA" />
    <path
      d="M35.9003 34.1941L35.9003 66.2249"
      stroke="#3D4355"
      stroke-linecap="round"
    />
    <path
      d="M35.9003 34.1941L56.3817 69.5069"
      stroke="#3D4355"
      stroke-linecap="round"
    />
    <path
      d="M35.194 34.1941L14.7126 69.5069"
      stroke="#3D4355"
      stroke-linecap="round"
    />
    <path
      d="M3.63166 26.4397L3.63167 26.4397L11.4597 24.3423C12.3599 24.1011 13.0925 24.5399 13.2413 25.0952C13.2413 25.0952 13.2413 25.0952 13.2413 25.0952L14.9892 31.6184L15.4722 31.489L14.9892 31.6184C15.0624 31.8915 15.009 32.2105 14.7994 32.5076C14.5877 32.8077 14.2392 33.0497 13.8227 33.1614L5.99467 35.2588C5.1806 35.477 4.34382 34.9938 4.1257 34.1797L4.12569 34.1797L2.55255 28.3087C2.55255 28.3087 2.55255 28.3087 2.55255 28.3087C2.33444 27.4946 2.81758 26.6579 3.63166 26.4397Z"
      fill="white"
      stroke="#3D4355"
    />
    <path
      d="M10.1184 25.2327L9.63543 25.3621L10.1184 25.2327C9.5152 22.9816 10.8566 20.6584 13.1077 20.0552L18.3263 18.6569C18.3263 18.6569 18.3263 18.6569 18.3263 18.6569C19.1404 18.4387 19.9771 18.9219 20.1953 19.736L20.1953 19.736L23.6911 32.7825L23.6911 32.7826C23.7959 33.1733 23.741 33.5899 23.5386 33.9404C23.3362 34.2909 23.003 34.5467 22.612 34.6515C22.612 34.6515 22.612 34.6515 22.6119 34.6515L17.3933 36.0498C15.1421 36.653 12.8191 35.3117 12.2159 33.0606L10.1184 25.2327Z"
      fill="white"
      stroke="#3D4355"
    />
    <path
      d="M19.5841 15.5233L19.5841 15.5233L51.5483 6.9585C52.3624 6.74038 53.1991 7.22352 53.4173 8.03761L58.3115 26.3029C58.5296 27.117 58.0464 27.9538 57.2323 28.1719L25.2683 36.7366C23.3733 37.2443 21.4256 36.1198 20.9179 34.2249L20.435 34.3543L20.9179 34.2249L17.0724 19.8736C17.0724 19.8736 17.0724 19.8736 17.0724 19.8736C16.5647 17.9787 17.6892 16.031 19.5841 15.5233Z"
      fill="#E5E5FE"
      stroke="#3D4355"
    />
    <path
      d="M49.77 6.21805L49.7699 6.21799C49.6652 5.82727 49.7201 5.41066 49.9225 5.06012C50.1248 4.70965 50.4581 4.45389 50.8491 4.34908C50.8491 4.34908 50.8491 4.34908 50.8491 4.34908L61.2864 1.55249C62.1004 1.33438 62.9372 1.81751 63.1553 2.6316L63.1553 2.63161L69.4479 26.1156L69.4479 26.1156C69.5527 26.5064 69.4979 26.923 69.2954 27.2734L69.2954 27.2735C69.093 27.624 68.7598 27.8797 68.3688 27.9845C68.3688 27.9845 68.3688 27.9845 68.3687 27.9845L57.9315 30.7813C57.1174 30.9994 56.2807 30.5162 56.0625 29.7021L55.5796 29.8315L56.0625 29.7021L49.77 6.21805Z"
      fill="white"
      stroke="#3D4355"
    />
    <path
      d="M35.9999 40.8249C32.4367 40.8249 29.5369 37.9252 29.5369 34.3619C29.5369 30.7987 32.4367 27.8989 35.9999 27.8989C39.5632 27.8989 42.4629 30.7987 42.4629 34.3619C42.4629 37.9252 39.5632 40.8249 35.9999 40.8249Z"
      fill="white"
      stroke="#3D4355"
    />
  </svg>
);

export const SolidLineIcon = () => (
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 2H12.25"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const DashLineIcon = () => (
  <svg
    width="20"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 2H12.25"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="3 3"
    />
  </svg>
);

export const DottedLineIcon = () => (
  <svg
    width="13"
    height="4"
    viewBox="0 0 13 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.75" cy="2" r="1" fill="currentColor" />
    <circle cx="6.25" cy="2" r="1" fill="currentColor" />
    <circle cx="10.75" cy="2" r="1" fill="currentColor" />
  </svg>
);

export const DashDotLineIcon = () => (
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 2H7.75"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="12.25" cy="2" r="1" fill="currentColor" />
  </svg>
);

export const SquareIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="8" height="8" rx="1" fill="currentColor" />
  </svg>
);

export const CircleIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="currentColor" />
  </svg>
);
