import { useMutation, useQueryClient } from 'react-query';
import hyperParamsApi from '@experiment-management-shared/api/API/hyperParamsApi';

type MutationArgs = {
  experimentKey: string;
  name: string;
};
const useDeleteHyperParameterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ experimentKey, name }: MutationArgs) =>
      hyperParamsApi.deleteHyperParam({ experimentKey, name }),
    {
      onMutate: async () => {
        queryClient.cancelQueries(['experiment-params']);
      },
      onSuccess: async () => {
        queryClient.invalidateQueries(['experiment-params']);
      }
    }
  );
};

export default useDeleteHyperParameterMutation;
