import { useCallback } from 'react';
import { orderColumnsInAscOrder } from '@shared/utils/selectComponentHelper';

import { getExtraCols } from '@API/helpers/v2_helpers';

import useFullColumnsData from './useFullColumnsData';

export default function useColumns(
  { includeTags = false, extraCols = false, divideExtraCols = false } = {},
  config,
  projectConfig
) {
  const getColumns = useCallback(
    ({ columns }) => {
      const filteredColumns = includeTags
        ? columns
        : columns.filter(column => column.source !== 'tag');

      const orderedCols = orderColumnsInAscOrder(filteredColumns);

      if (extraCols) {
        const extraOptions = getExtraCols(columns);
        if (divideExtraCols) {
          return [orderedCols, extraOptions];
        }
        return [...orderedCols, ...extraOptions];
      }

      return orderedCols;
    },
    [divideExtraCols, extraCols, includeTags]
  );

  return useFullColumnsData(
    null,
    {
      ...config,
      select: getColumns
    },
    projectConfig
  );
}
