import { batch } from 'react-redux';
import { replace } from 'connected-react-router';
import { uniqWith } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';

import { normalizeTemplate } from '@experiment-details/utils/templateMigrations';

import { PARSE_OPTIONS, STRINGIFY_OPTIONS } from '@shared/constants/url';
import {
  COLOR_SINGLE_EXPERIMENT_KEY,
  METRIC_COLORS_V2
} from '@experiment-details/constants/panels';
import {
  BUILT_IN_CHART_TYPES,
  DEFAULT_CHART_TEMPLATE_NAME
} from '@experiment-management-shared/constants/chartConstants';
import { generatePanelId } from '@experiment-management-shared';

import { chartActionTypes } from '@/constants/actionTypes';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import chartApi from '../../util/experimentDetails/chartApi';
import alertsUtil from '../../util/alertsUtil';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import { getMetricChartsTemplate } from '@/reducers/experimentDetails/chartsReducer';
import { selectExperimentKeys } from '@/reducers/ui/experimentsUiReducer';
import assetsApi from '@/util/assetsApi';
import { BETA_FEATURE_CURVES_PANEL } from '@/lib/betaFeatures';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import { initializeLayout } from '@experiment-management-shared/utils/layout';

const chartActions = {
  fetchProjectTemplate(projectId, experimentKey) {
    return (dispatch, getState) => {
      dispatch({
        type: chartActionTypes.FETCH_PROJECT_CHART_TEMPLATE
      });

      return chartApi
        .fetchProjectTemplate(projectId, experimentKey)
        .then(async response => {
          const template = response.data;
          const isCurvesPanelEnabled = isBetaFeatureEnabled(getState(), {
            featureName: BETA_FEATURE_CURVES_PANEL
          });
          const normalizedTemplate = normalizeTemplate(template);
          const {
            data: curveAssets
          } = await assetsApi.fetchAssetsForExperiment(experimentKey, 'curve');
          const templateWithCurvePanels = isCurvesPanelEnabled
            ? addCurvePanelsToTemplate(normalizedTemplate, curveAssets)
            : normalizedTemplate;

          dispatch(
            chartActions.receivedProjectTemplate(
              templateWithCurvePanels,
              experimentKey
            )
          );

          dispatch(
            chartActions.setCurrentTemplateAndMigrateCharts(
              templateWithCurvePanels,
              experimentKey
            )
          );
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_FETCH_PROJECT_TEMPLATE,
              'There was an error fetching your project template.'
            )
          );
        });
    };
  },

  fetchUserTemplates(projectId, experimentKey) {
    return (dispatch, getState) => {
      dispatch({
        type: chartActionTypes.FETCH_USER_TEMPLATES
      });
      return chartApi
        .fetchUserTemplates(projectId, experimentKey)
        .then(async response => {
          const { templates } = response.data;

          const isCurvesPanelEnabled = isBetaFeatureEnabled(getState(), {
            featureName: BETA_FEATURE_CURVES_PANEL
          });
          const normalizedTemplates = templates.map(normalizeTemplate);
          const {
            data: curveAssets
          } = await assetsApi.fetchAssetsForExperiment(experimentKey, 'curve');
          const templatesWithCurvePanels = isCurvesPanelEnabled
            ? normalizedTemplates.map(template => {
                return addCurvePanelsToTemplate(template, curveAssets);
              })
            : normalizedTemplates;

          dispatch({
            type: chartActionTypes.RECEIVED_USER_TEMPLATES,
            payload: { experimentKey, userTemplates: templatesWithCurvePanels }
          });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_FETCH_USER_TEMPLATES,
              'There was an error fetching your chart templates.'
            )
          );
        });
    };
  },

  saveTemplate(experimentKey, template) {
    return dispatch => {
      return chartApi
        .saveTemplate(template)
        .then(response => {
          const template = response.data;

          dispatch({
            type: chartActionTypes.RECEIVED_SAVED_TEMPLATE,
            payload: { experimentKey, template }
          });

          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_SAVE_TEMPLATE,
              'Your new view was saved successfully.'
            )
          );

          return response.data;
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_SAVE_TEMPLATE,
              'There was an error saving your chart template.'
            )
          );
        });
    };
  },

  deleteTemplate(template, experimentKey) {
    const data = {
      project_id: template.project_id,
      template_id: template.template_id
    };
    return dispatch => {
      return chartApi
        .deleteTemplate(data)
        .then(() => {
          dispatch(
            chartActions.fetchUserTemplates(template.project_id, experimentKey)
          );
          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.SUCCESS_DELETE_CHART_TAB_TEMPLATE,
              'Successfully deleted chart template.'
            )
          );
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_DELETE_TEMPLATE,
              'There was an error deleting your chart template.'
            )
          );
        });
    };
  },

  receivedProjectTemplate(template, experimentKey) {
    return dispatch => {
      const normalizedTemplate = template;

      batch(() => {
        dispatch({
          type: chartActionTypes.RECEIVED_PROJECT_CHART_TEMPLATE,
          payload: { experimentKey, projectTemplate: normalizedTemplate }
        });
      });
    };
  },

  setProjectTemplate(template, experimentKey, withoutNotification = false) {
    const data = {
      project_id: template.project_id,
      template_id: template.template_id || null
    };

    return dispatch => {
      return chartApi
        .setProjectTemplate(data)
        .then(() => {
          dispatch(
            chartActions.receivedProjectTemplate(template, experimentKey)
          );

          if (!withoutNotification) {
            dispatch(
              alertsUtil.openSnackbarDialog(
                snackbarTypes.SUCCESS_SAVE_TEMPLATE,
                'Successfully set view.'
              )
            );
          }
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_SET_PROJECT_TEMPLATE,
              'There was an error setting your view.'
            )
          );
        });
    };
  },

  setCurrentTemplateAndMigrateCharts(template, experimentKey) {
    return async (dispatch, getState) => {
      const viewId = template?.template_id;
      const state = getState();

      const queryParsed = queryString.parse(
        state.router.location.search,
        PARSE_OPTIONS
      );
      const search = queryString.stringify(
        { ...queryParsed, viewId },
        STRINGIFY_OPTIONS
      );

      batch(() => {
        dispatch(replace({ search, state: state.router.location.state }));

        dispatch({
          type: chartActionTypes.SET_CURRENT_TEMPLATE,
          payload: { experimentKey, template }
        });
      });

      const isLoggedIn = getIsUserLoggedIn(state);
      const canEdit = isUserAllowedToEditProject(state);

      if (template.autoSave && isLoggedIn && canEdit) {
        await chartApi.saveTemplate(template);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_INTEGRATED_CHARTS_TO_PANELS,
            'Your View template has been integrated to a new version.'
          )
        );
      }
    };
  },

  updateChartTemplate(
    experimentKey,
    updatedTemplate,
    showUnsavedChangesWarning = true
  ) {
    return {
      type: chartActionTypes.UPDATE_CHART_TEMPLATE,
      payload: {
        experimentKey,
        updatedTemplate: {
          ...updatedTemplate,
          version: generatePanelId()
        }
      },
      showUnsavedChangesWarning
    };
  },

  updateChartTemplateColorMap(updates, showUnsavedChangesWarning = true) {
    return (dispatch, getState) => {
      const state = getState();
      const [experimentKey] = selectExperimentKeys(state);
      const currentTemplate = getMetricChartsTemplate(state);

      const metricColors =
        currentTemplate[METRIC_COLORS_V2][COLOR_SINGLE_EXPERIMENT_KEY];

      return dispatch(
        this.updateChartTemplate(
          experimentKey,
          {
            [METRIC_COLORS_V2]: {
              [COLOR_SINGLE_EXPERIMENT_KEY]: {
                ...metricColors,
                ...updates
              }
            }
          },
          showUnsavedChangesWarning
        )
      );
    };
  }
};

const MAX_AUTO_GENERATED_CURVE_PANELS = 25;
function addCurvePanelsToTemplate(template, curveAssets) {
  if (
    template.template_id ||
    template.template_name !== DEFAULT_CHART_TEMPLATE_NAME
  ) {
    return template;
  }

  const curvePanels = uniqWith(curveAssets, (a, b) => {
    return a.fileName === b.fileName;
  })
    .slice(0, MAX_AUTO_GENERATED_CURVE_PANELS)
    .map(asset => {
      return {
        chartType: BUILT_IN_CHART_TYPES.curves,
        chartName: asset.fileName,
        assetNames: [asset.fileName],
        step: asset.step,
        chartId: generatePanelId()
      };
    });

  const templateCopy = cloneDeep(template);

  templateCopy.panels.sections[0].panels = [
    ...templateCopy.panels.sections[0].panels,
    ...curvePanels
  ];

  templateCopy.panels.sections[0].layout = initializeLayout({
    panels: templateCopy.panels.sections[0].panels,
    initialLayout: templateCopy.panels.sections[0].layout
  });

  return templateCopy;
}

export default chartActions;
