import React from 'react';
import cx from 'classnames';

import { Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from './ThreeDotsMenu.module.scss';

// [DS]: Change it as a part of menu changes
export default function ThreeDotsMenuItem({
  children,
  disabled,
  tooltip,
  onClick,
  dataTest
}) {
  const btn = (
    <Button
      data-test={dataTest}
      disabled={disabled}
      onClick={onClick}
      className={cx(classNames.dsThreeDotsMenuItem, {
        [classNames.dsThreeDotsMenuItemTextDisabled]: disabled
      })}
    >
      {children}
    </Button>
  );

  return (
    <Box>
      {tooltip ? (
        <Tooltip title={tooltip} placement="left" arrow>
          <span>{btn}</span>
        </Tooltip>
      ) : (
        btn
      )}
    </Box>
  );
}

ThreeDotsMenuItem.defaultProps = {
  tooltip: null,
  dataTest: null
};

ThreeDotsMenuItem.propTypes = {
  dataTest: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
