import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import StyledTooltip from '@DesignSystem/data-display/Tooltip';

const DisplayToggle = ({ handleIsCardDisplay, isCardDisplay }) => {
  return (
    <div className="item-display-toggle-container">
      <StyledTooltip title="Panel view">
        <i
          data-test="panel-view"
          className={cx(
            'material-icons cursor-pointer item-display-toggle panel-view',
            {
              'is-active': isCardDisplay
            }
          )}
          onClick={() => handleIsCardDisplay(true)}
        >
          view_module
        </i>
      </StyledTooltip>
      <StyledTooltip title="List view">
        <i
          data-test="list-view"
          className={cx(
            'material-icons cursor-pointer item-display-toggle list-view',
            {
              'is-active': !isCardDisplay
            }
          )}
          onClick={() => handleIsCardDisplay(false)}
        >
          view_headline
        </i>
      </StyledTooltip>
    </div>
  );
};

DisplayToggle.propTypes = {
  handleIsCardDisplay: PropTypes.func.isRequired,
  isCardDisplay: PropTypes.bool.isRequired
};

export default DisplayToggle;
