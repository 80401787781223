import {
  IS_ONLINE_MODE,
  PY_PANELS_API_URL,
  PY_PANELS_CDN_URL,
  ROOT_URL
} from '@/constants/configConstants';
import { iFrameConfig } from '@experiment-management-shared/constants';
// eslint-disable-next-line
import code from '!!raw-loader!./sandboxCode.js';
// eslint-disable-next-line
import codePy from '!!raw-loader!./sandboxCodePy.js';

const buildHost = url => (url.endsWith('/') ? url : `${url}/`);

// Move to CDN?
const plotlySrcURL = IS_ONLINE_MODE
  ? '/vendors/js/plotly-2.31.1.min.js'
  : '/vendors/js/plotly-2.31.1.min.js';

const sdkApiUrl = new URL(
  `${buildHost(ROOT_URL || '/')}api/rest/v2/`,
  PY_PANELS_API_URL || window.location.origin
).href;

const sdkCdnUrl = new URL(
  `${buildHost(ROOT_URL || '/')}`,
  PY_PANELS_CDN_URL || window.location.origin
).href;

export default function ({
  code: userCode = '',
  colorMap,
  experimentKeys,
  experimentNames,
  options,
  projectToken,
  showConsole,
  instanceId,
  projectId,
  isPy,
  pyConfig,
  apiKey
}) {
  const customCode = isPy
    ? codePy.replace(
        '// %__USER_CODE__%',
        `const USER_CUSTOM_PY_CODE = ${JSON.stringify(userCode)}`
      )
    : code.replace('// %__USER_CODE__%', userCode);

  return `
    <!-- Used for pyodide Plotly Python Panels -->
    <script src="${plotlySrcURL}"></script>
    <script type="text/javascript">
      window.CHART_OPTIONS = ${JSON.stringify(options || {})};
      window.COLOR_MAP = ${JSON.stringify(colorMap || {})};
      window.IFRAME_CONFIG = ${JSON.stringify(iFrameConfig)};
      window.INITIAL_EXPERIMENT_KEYS = ${JSON.stringify(experimentKeys)};
      window.INSTANCE_ID = '${instanceId || iFrameConfig.defaultInstanceId}';
      window.EXPERIMENT_NAMES = ${JSON.stringify(experimentNames)};
      window.PROJECT_ID = '${projectId}';
      window.PROJECT_TOKEN = '${projectToken}';
      window.ROOT_URL = '${ROOT_URL}';
      window.PY_CONFIG = ${JSON.stringify(pyConfig || {})};
      window.SHOW_CONSOLE = ${!!showConsole};
      window.API_KEY = '${apiKey}';
      window.API_URL = '${sdkApiUrl}';
      window.CDN_URL = '${sdkCdnUrl}';
      
      ${customCode}
    </script>
  `;
}
