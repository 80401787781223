import { createRef, useCallback, useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import zipWith from 'lodash/zipWith';

import { CHART_TYPES } from '../constants';

type ServerDataType = {
  alertNotifications?: Record<string, string>[];
  marker: Record<string, string | number>;
  name: string;
  type: string;
  id?: string;
  featureData?: Array<{
    value: string;
    data: Array<Record<string, string | number>>;
  }>;
  segment?: Record<string, string>;
  x: Array<string | undefined>;
  y: Array<number | null> | null;
};

type PillsDataType = {
  dotColor: string | number;
  text: string;
};

type FairnessType = {
  filterFeature: string;
  referenceGroup: string;
  setReferenceGroup: () => void;
  segmentsValuesOptions: string[];
  sensitiveGroup: string[];
  setSensitiveGroup: () => void;
  showCharts: boolean;
};

type BarChartContatinerHookProps = {
  serverData: ServerDataType[];
  chartType: ChartType;
  fairnessData: FairnessType;
};

type ChartType = typeof CHART_TYPES[keyof typeof CHART_TYPES];

export const useBarChartContainer = ({
  serverData,
  chartType,
  fairnessData
}: BarChartContatinerHookProps) => {
  const [data, setData] = useState<ServerDataType[]>([]);
  const [pillsData, setPillsData] = useState<PillsDataType[]>([]);

  const chartRef = createRef();

  useEffect(() => {
    if (chartType === CHART_TYPES.FAIRNESS_GROUPED) {
      const { filterFeature, referenceGroup } = fairnessData || {};
      if (!filterFeature || !referenceGroup) {
        setData([]);
        return;
      }
      const filteredData = serverData.filter(item => item.id !== 'TEST');
      let referenceGroupValue: (number | null)[] = [];
      if (
        filteredData.every(
          item => item.featureData && item.featureData.length === 0
        )
      ) {
        setData([]);
        return;
      }
      const xGroups = filteredData.map(item => item.segment?.segmentValue);

      const yGroups = filteredData.map(item => {
        const feature = item.featureData?.find(
          item => item.value === filterFeature
        );
        const featureSum = feature
          ? sumBy(feature.data, dot => Number(dot.y))
          : NaN;

        const allSegmentFeaturesSum = item.featureData
          ? sum(
              zipWith(
                ...item.featureData.map(val => val.data.map(item => item.y)),
                (...args) => sum(args)
              )
            )
          : NaN;
        const percentage = featureSum / Number(allSegmentFeaturesSum);
        if (item.segment?.segmentValue === referenceGroup) {
          referenceGroupValue = new Array(filteredData.length).fill(percentage);
        }

        return percentage || null;
      });

      if (
        referenceGroupValue.every(item => !item) &&
        yGroups.every(item => !item)
      ) {
        setData([]);
        return;
      }
      const trace1 = {
        x: xGroups,
        y: referenceGroupValue,
        type: 'bar',
        name: 'Reference Group',
        marker: { color: '#5899DA', opacity: 1 }
      };
      const trace2 = {
        x: xGroups,
        y: yGroups,
        type: 'bar',
        name: 'Sensitive Group',
        marker: { color: '#F4B400', opacity: 1 }
      };
      setData([trace1, trace2]);
      return;
    }
    setData(serverData);
  }, [serverData]);

  const handleUnhover = useCallback(() => {
    const shallowedData = [...data];
    shallowedData.forEach(val => {
      val.marker.opacity = 1;
    });

    setData(shallowedData);
  }, [data]);

  const handleHover = useCallback(
    (idx, text) => {
      const shallowedData = [...data];
      shallowedData.forEach(val => {
        if (val.name !== text) {
          val.marker = { ...val.marker, opacity: 0.4 };
        } else {
          val.marker = { ...val.marker, opacity: 1 };
        }
      });

      setData(shallowedData);
    },
    [data]
  );

  useEffect(() => {
    const pillsValues = uniqBy(data, 'name')
      .filter(trace => trace.name)
      .map(trace => ({ dotColor: trace?.marker?.color, text: trace.name }));
    setPillsData(pillsValues);
  }, [data]);

  return {
    handledPlotData: data,
    handleHover,
    chartRef,
    pillsData,
    handleUnhover
  };
};
