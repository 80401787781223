import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';

type UseExperimentGitCompareParams = {
  experimentKey1: string;
  experimentKey2: string;
};

const getExperimentGitCompare = async ({
  experimentKey1,
  experimentKey2,
  signal
}: {
  experimentKey1: string;
  experimentKey2: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get('git/github-diff-url', {
    params: { experimentKeyA: experimentKey1, experimentKeyB: experimentKey2 },
    signal
  });

  return data;
};

export default function useExperimentGitCompare(
  { experimentKey1, experimentKey2 }: UseExperimentGitCompareParams,
  options: QueryConfig<{
    url: string;
    provider: string;
  }> = {}
) {
  return useQuery(
    ['experiment-git-compare', { experimentKey1, experimentKey2 }],
    ({ signal }) => {
      return getExperimentGitCompare({
        experimentKey1,
        experimentKey2,
        signal
      });
    },
    {
      enabled: !!experimentKey1 && !!experimentKey2,
      refetchOnMount: true,
      ...options
    }
  );
}
