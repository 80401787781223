import { changeColorOpacity } from '@shared';
import moment from 'moment';
import { flatten, get } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import { chartColors } from '@design-system-outdated/constants';
import { formatToLocal } from './transformers';
import {
  CHART_TYPES,
  CHART_TYPE_BY_SECTION,
  INTERVAL_TYPE_VALUES,
  PERCENTILE_OPTION_LIST,
  PERCENTILE_VALUES,
  MPM_OPERATOR_FILTERS,
  PANEL_DATA_URL_MAP_SECTION
} from '@mpm-druid/constants';
import { getHashedColor } from './utilsWithTypes';

const CHART_COLORS = Object.values(chartColors);

const mapPercentile = per => {
  if (per?.includes('p')) return `${per.substring(1)}th percentile`;
  return per;
};

const setHovertemplate = ({
  type,
  secondPercentileName,
  firstPercentileName,
  isHourly = false
}) => {
  if (isHourly) {
    return ` %{text.date}, %{y} <extra></extra>`;
  }

  if (!secondPercentileName || !firstPercentileName) {
    return null;
  }
  const end = `${mapPercentile(secondPercentileName)}: %{text.max:.10f}`;
  const start = `${mapPercentile(firstPercentileName)}: %{text.min:.10f}`;
  const median = `median: %{text.median:.10f}`;
  const endText = type === 'end' ? `<b>${end}</b>` : end;
  const startText = type === 'start' ? `<b>${start}</b>` : start;
  const medianText = type === 'median' ? `<b>${median}</b>` : median;

  return ` %{text.date} <br> ${endText} <br> ${medianText} <br> ${startText} <extra></extra> `;
};

const formatHoverDate = (x, intervalType) => {
  const showHours =
    intervalType === INTERVAL_TYPE_VALUES.HOURLY
      ? { hour: 'numeric', minute: '2-digit' }
      : {};

  return new Date(x).toLocaleString([], {
    month: 'short',
    day: '2-digit',
    ...showHours
  });
};

const addGeneralHoverTemplate = (data, intervalType) => {
  const isHourly = intervalType === INTERVAL_TYPE_VALUES.HOURLY;
  if (isHourly && data) {
    return data.map(dt => ({
      ...dt,
      text: dt?.x.map(x => ({ date: formatHoverDate(x, intervalType) })),
      hovertemplate: setHovertemplate({ isHourly: true })
    }));
  }

  return data;
};

export const selectColorFromList = (colors, index, defaultValue) => {
  if (!colors || !colors.length) {
    return defaultValue;
  }

  return colors[index % colors.length] || defaultValue;
};

export const transformToSegmentPlotPoints = (
  panel,
  colors = [],
  intervalType,
  segmentsIdColorMap
) => {
  const plotData = panel
    .filter(line => line.data)
    .map((lineData, index) => {
      const x = [];
      const y = [];
      const segmentId = lineData?.segment?.id;
      const linePoints = lineData.data;
      linePoints?.forEach(point => {
        x.push(formatToLocal(point.x));
        y.push(point.y);
      });

      const isSingleDot = x.length === 1;
      return {
        x,
        y,
        alertNotifications: panel[0].alertNotifications,
        line: {
          color: segmentsIdColorMap[segmentId] || get(colors, index, ''),
          dash: 'solid'
        },
        segmentId,
        name: segmentsIdColorMap[`${segmentId}_name`],
        mode: isSingleDot ? 'markers' : 'lines'
      };
    });

  return addGeneralHoverTemplate(plotData, intervalType);
};

export const transformFeaturesToBarPlot = (
  responseData,
  colors,
  intervalType
) => {
  const results = [];
  responseData[0].chartData.forEach(({ points, value }, index) => {
    const result = {
      x: [],
      y: [],
      name: value,
      marker: {
        color: colors[index % (colors.length - 1)],
        opacity: 1
      },
      type: 'bar'
    };

    points?.forEach(({ x, y }) => {
      result.x.push(formatToLocal(x));
      result.y.push(y);
    });

    results.push(result);
  });
  return addGeneralHoverTemplate(results, intervalType);
};

const transformSegmentToAreaPlotData = (
  intervalType,
  segment,
  color = '',
  firstPercentileName,
  secondPercentileName,
  query
) => {
  const medianPercentile = 'p50';
  const medianData =
    segment.chartData.find(({ value }) => value === medianPercentile)?.points ||
    [];
  const startArea =
    segment.chartData.find(({ value }) => value === firstPercentileName)
      ?.points || [];
  const endArea =
    segment.chartData.find(({ value }) => value === secondPercentileName)
      ?.points || [];

  const fillColor = (color && changeColorOpacity(color, 0.14)) || '';

  const setHoverData = (y, index, x) => {
    if (startArea[index] && endArea[index] && medianData[index])
      return {
        min: startArea[index].y,
        max: endArea[index].y,
        median: medianData[index].y,
        date: formatHoverDate(x, intervalType)
      };
  };

  const result = [
    {
      x: [],
      y: [],
      text: [],
      percentileType: 'min',
      type: 'scatter',
      fillcolor: fillColor,
      query,
      predicateKey: segment.predicateKey,
      mode: 'lines',
      // alertNotifications: segment?.monitorNotifications,
      hovertemplate: setHovertemplate({
        type: 'start',
        secondPercentileName,
        firstPercentileName
      }),
      line: { width: 0, color }
    },
    {
      x: [],
      y: [],
      text: [],
      percentileType: 'max',
      type: 'scatter',
      mode: 'lines',
      // alertNotifications: segment?.monitorNotifications,
      fillcolor: fillColor,
      query,
      predicateKey: segment.predicateKey,
      line: { width: 0, color },
      hovertemplate: setHovertemplate({
        type: 'end',
        secondPercentileName,
        firstPercentileName
      }),
      fill: 'tonexty'
    },
    // median
    {
      x: [],
      y: [],
      text: [],
      percentileType: 'median',
      hovertemplate: setHovertemplate({
        type: 'median',
        secondPercentileName,
        firstPercentileName
      }),
      lineTrace: true,
      query,
      predicateKey: segment.predicateKey,
      type: 'scatter',
      mode: 'lines',
      alertNotifications: segment?.monitorNotifications,
      line: {
        color
      }
    }
  ];

  startArea.forEach(({ x, y }, index) => {
    result[0].x.push(formatToLocal(x));
    result[0].y.push(y);
    result[0].text.push(setHoverData(y, index, x));
  });

  endArea.forEach(({ x, y }, index) => {
    result[1].x.push(formatToLocal(x));
    result[1].y.push(y);
    result[1].text.push(setHoverData(y, index, x));
  });

  medianData.forEach(({ x, y }, index) => {
    result[2].x.push(formatToLocal(x));
    result[2].y.push(y);
    result[2].text.push(setHoverData(y, index, x));
  });

  if (!startArea?.length || !endArea?.length) {
    return result[2];
  }

  return result;
};

export const transformToAreaPlotData = (
  intervalType,
  segments,
  colors = [],
  predicates,
  firstPercentileName,
  secondPercentileName
) => {
  return flatten(
    segments.map(item => {
      const query =
        predicates.find(predicate => predicate.key === item.predicateKey)
          ?.query || 'all';
      const color =
        item.predicateKey === 'all' ? colors[0] : getHashedColor(colors, query);
      return transformSegmentToAreaPlotData(
        intervalType,
        item,
        color,
        firstPercentileName,
        secondPercentileName,
        query
      );
    })
  );
};

// minmax -> min, max
// p10-p90 -> p10, p90
export const transformModeToPercentileValues = mode => {
  if (mode === PERCENTILE_OPTION_LIST[0].value) {
    return [PERCENTILE_VALUES.MIN, PERCENTILE_VALUES.MAX];
  }

  if (mode === PERCENTILE_OPTION_LIST[3].value) {
    return [null, null];
  }

  return mode.split('-');
};

const getYs = data => data.y;

const transformSegmentToStackedPlotData = (segment, colors, intervalType) => {
  const plotFeatures = [];
  segment.featuresDrift?.forEach((feature, index) => {
    const color = selectColorFromList(colors, index, '');
    const result = {
      x: [],
      y: [],
      stackgroup: 'one',
      name: feature.featureName,
      featureId: `${feature.featureName}-${feature.featureSource}`,
      featureSource: feature.featureSource,
      line: {
        color
      },
      hovertemplate: `%{text.date},<br>Current: <b>%{y}</b><br>Total: <b>%{text.sum}</b>`
    };

    feature.driftData?.forEach(({ x, y }) => {
      result.x.push(formatToLocal(x));
      result.y.push(y);
    });

    if (color) {
      result.fillcolor = changeColorOpacity(color, 0.3);
    }

    plotFeatures.push(result);
  });

  plotFeatures.sort((featureA, featureB) => {
    const maxValueA = Math.max(...getYs(featureA));
    const maxValueB = Math.max(...getYs(featureB));

    return maxValueA - maxValueB;
  });

  plotFeatures.forEach((feature, featureIndex) => {
    feature.text = feature.y.map((y, yIndex) => {
      const x = feature.x[yIndex];
      const sum =
        !featureIndex || !plotFeatures[featureIndex - 1]?.text?.[yIndex]?.sum
          ? y
          : plotFeatures[featureIndex - 1].text[yIndex]?.sum + y;

      return {
        sum,
        date: formatHoverDate(x, intervalType)
      };
    });
  });

  return plotFeatures;
};

export const transformSegmentsToStackedPlotData = (
  segments,
  colors = [],
  intervalType
) => {
  const result = segments.map(segment =>
    transformSegmentToStackedPlotData(segment, colors, intervalType)
  );

  return flatten(result);
};

const getFormattedPoints = data => {
  const countPoints = data.length;
  const xAxis = Array(countPoints);
  const yAxis = Array(countPoints);
  data?.forEach(({ x, y }, index) => {
    xAxis[index] = x;
    yAxis[index] = y;
  });
  return { xAxis, yAxis };
};

export const transformToLinePlotFromBarData = (
  serverData,
  colors = [],
  predicates
) => {
  const groupedData = [];
  for (let i = 0; i < serverData.length; i++) {
    const serverPredicate = serverData[i];
    const query =
      predicates.find(
        predicate => predicate.key === serverPredicate.predicateKey
      )?.query || 'all';
    const color =
      serverPredicate.predicateKey === 'all'
        ? colors[0]
        : getHashedColor(colors, query);
    for (let j = 0; j < serverPredicate.chartData.length; j++) {
      const { value, points } = serverPredicate.chartData[j];
      const { xAxis, yAxis } = getFormattedPoints(points);
      const lineData = {
        x: xAxis.map(xVal => formatToLocal(xVal)),
        y: yAxis,
        name: value || 'all',
        query: query,
        featureValue: value || 'all',
        predicateKey: serverPredicate.predicateKey,
        line: {
          color,
          dash: 'solid'
        },
        mode: 'lines'
      };
      groupedData.push(lineData);
    }
  }
  return groupedData;
};

export const transformNumericalDistributionToPlotData = ({
  serverResponseData,
  hoverLineData,
  predicates
}) => {
  const { xAxis } = hoverLineData || {};
  const filteredData = serverResponseData.data.map(item => {
    const query =
      predicates.find(predicate => predicate.key === item.predicateKey)
        ?.query || 'all';
    const color =
      item.predicateKey === 'all'
        ? CHART_COLORS[0]
        : getHashedColor(CHART_COLORS, query);
    if (xAxis) {
      const xKey = moment(xAxis).utc().toISOString();
      if (item.pdfDistributionGraphs.distributionsPerTimeInterval[xKey]) {
        return {
          ...item.pdfDistributionGraphs.distributionsPerTimeInterval[xKey],
          predicateKey: item.predicateKey,
          query,
          color
        };
      }
      return {
        ...item.pdfDistributionGraphs.distributionsPerTimeInterval[
          xKey.slice(0, -5) + 'Z'
        ],
        predicateKey: item.predicateKey,
        query,
        color
      };
    }
    return {
      ...item.pdfDistributionGraphs.wholeTimeRangeDistribution,
      predicateKey: item.predicateKey,
      query,
      color
    };
  });

  const lineData = filteredData.map(segmentItem => {
    const xAxis = [];
    const yAxis = [];
    if (!segmentItem.graphPoints) return {};
    segmentItem.graphPoints.forEach(({ x, y }) => {
      xAxis.push(x);
      yAxis.push(y);
    });
    const isSingleDot = xAxis.length === 1;
    return {
      x: xAxis,
      y: yAxis,
      fill: 'tozeroy',
      fillcolor: `${segmentItem.color}` + 25,
      line: {
        color: segmentItem.color,
        dash: 'solid',
        shape: 'spline'
      },
      name: segmentItem.query,
      query: segmentItem.query,
      predicateKey: segmentItem.predicateKey,
      mode: isSingleDot ? 'markers' : 'lines'
    };
  });

  return { lineData };
};

export const makeLineChartThresholdShape = (point, enabledChartData) => {
  if (!enabledChartData || isNaN(point) || !point) {
    return [];
  }
  return [
    {
      type: 'line',
      xref: 'paper',
      yref: 'y',
      x0: 0,
      x1: 1,
      y0: point,
      y1: point,
      line: {
        color: 'red',
        dash: '4px,4px',
        width: 1.5
      }
    }
  ];
};

export const getMPMPanelURL = section => {
  const baseUrl = `mpm/v2`;
  return `${baseUrl}/${PANEL_DATA_URL_MAP_SECTION[section]}`;
};

export const getMPMChartType = (section, data) => {
  const chartType = CHART_TYPE_BY_SECTION[section];

  if (chartType === CHART_TYPES.MULTIPLE_TYPE_CHART) {
    if (data.length === 1) return CHART_TYPES.BAR;
    return CHART_TYPES.LINE_CATEGORICAL_BAR_DATA;
  }

  if (chartType === CHART_TYPES.STACKED && data.length > 1) {
    return CHART_TYPES.STACKED_TO_LINES;
  }

  return chartType;
};

export const getMPMOperatorsForRule = (source, type) => {
  return get(MPM_OPERATOR_FILTERS, type, []);
};

export const modifyBarData = data => {
  const localOffset = moment().utcOffset();
  const deepCopyData = cloneDeep(data);
  if (localOffset > 0) {
    deepCopyData.forEach(slice => {
      slice.x.shift();
      slice.y.shift();
    });
  }
  if (localOffset < 0) {
    deepCopyData.forEach(slice => {
      if (slice.x.length >= 2) {
        const date1 = moment(slice.x.at(-1));
        const date2 = moment(slice.x.at(-2));
        const sameDay = date1.isSame(date2, 'day');
        if (sameDay) {
          slice.x.pop();
          slice.y.pop();
        }
      }
    });
  }
  return deepCopyData;
};

export * from './utilsWithTypes';
export * from './helpers';
export * from './transformers';
