import PropTypes from 'prop-types';
import React from 'react';

import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import noop from 'lodash/noop';

import { Tooltip } from '@ds';
import { truncateValue } from '@shared/utils/decimalUtils';

import { normalizeColumnName } from '@API/helpers/v2_helpers';
import { formatDateTime, formatTimeNumber } from '@shared/utils/displayHelpers';
import StringOrLink from '@shared/components/StringOrLink';

const DATE_COLUMNS = [
  'Deleted At',
  'CreatedAt',
  'LastUpdatedAt',
  'end_server_timestamp',
  'start_server_timestamp'
];

const TEXT_COLUMNS = [
  'baseRunMax',
  'baseRunMin',
  'compareRunMax',
  'compareRunMin',
  'metricName',
  'paramName',
  'Owner'
];

export const isCellDataInvalid = cellData => {
  return isUndefined(cellData) || isNull(cellData) || cellData === 'null';
};

export const wrapWithTooltip = (withTooltip, title, body) => {
  if (withTooltip) {
    const tooltipBody = body || <span>{title}</span>;

    return (
      <Tooltip content={title} placement="top" wrapperDisplay="block">
        {tooltipBody}
      </Tooltip>
    );
  }

  return body || title;
};

const Cell = ({
  column,
  columns,
  cellsMap,
  columnsActions,
  decimalsPrecision,
  openLinksInNewTab,
  onToggleVisibility,
  onHoverExperimentNameCell,
  onClickRowActionMenu,
  row,
  fixedColumnTitle,
  withTooltip,
  onTogglePinned,
  pinnedExperimentKeys
}) => {
  /* since we will use the plain value to find the needed cell we need to normalize the name */
  const { keyName } = normalizeColumnName(column.name);

  const SpecialCellComponent = cellsMap?.[keyName];

  if (SpecialCellComponent) {
    const columnAction = columnsActions.find(
      columnActionIns => columnActionIns.columnName === keyName
    );
    return (
      <SpecialCellComponent
        action={columnAction?.action}
        columns={columns}
        decimalsPrecision={decimalsPrecision}
        fixedColumnTitle={fixedColumnTitle}
        onClickRowActionMenu={onClickRowActionMenu}
        onHoverExperimentNameCell={onHoverExperimentNameCell}
        onToggleVisibility={onToggleVisibility}
        openInNewTab={openLinksInNewTab}
        row={row}
        onTogglePinned={onTogglePinned}
        pinnedExperimentKeys={pinnedExperimentKeys}
      />
    );
  }

  const value = row[column.name] || row[keyName];

  if (isCellDataInvalid(value)) {
    return null;
  }

  if (isBoolean(value) || column.type === 'boolean') {
    return wrapWithTooltip(withTooltip, value.toString());
  }

  if (TEXT_COLUMNS.includes(keyName)) {
    return wrapWithTooltip(
      withTooltip,
      value,
      <div className="text-center overflow-ellipsis">{value}</div>
    );
  }

  if (DATE_COLUMNS.includes(keyName) || column.type === 'datetime') {
    return wrapWithTooltip(withTooltip, formatDateTime(value));
  }

  if (column.type === 'timenumber') {
    return wrapWithTooltip(withTooltip, formatTimeNumber(value));
  }

  if (!column.type) {
    return null;
  }

  const truncatedValue = truncateValue(value, decimalsPrecision);

  if (column.type === 'double') {
    return wrapWithTooltip(
      withTooltip,
      isNaN(Number(truncatedValue))
        ? truncatedValue.toString()
        : Number(truncatedValue)
    );
  }

  return wrapWithTooltip(
    withTooltip,
    truncatedValue,
    <span>
      <StringOrLink str={truncatedValue} />
    </span>
  );
};

Cell.defaultProps = {
  columns: [],
  cellsMap: undefined,
  columnsActions: [],
  decimalsPrecision: null,
  onClickRowActionMenu: noop,
  onHoverExperimentNameCell: noop,
  openLinksInNewTab: false,
  fixedColumnTitle: null,
  withTooltip: true
};

Cell.propTypes = {
  column: PropTypes.object.isRequired,
  columns: PropTypes.array,
  cellsMap: PropTypes.object,
  decimalsPrecision: PropTypes.number,
  onToggleVisibility: PropTypes.func,
  onClickRowActionMenu: PropTypes.func,
  fixedColumnTitle: PropTypes.string,
  onHoverExperimentNameCell: PropTypes.func,
  openLinksInNewTab: PropTypes.bool,
  row: PropTypes.object.isRequired,
  columnsActions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      action: PropTypes.func
    })
  ),
  withTooltip: PropTypes.bool,
  onTogglePinned: PropTypes.func,
  pinnedExperimentKeys: PropTypes.arrayOf(PropTypes.string)
};

export default Cell;
