export const EXPERIMENTS_SIDEBAR_TABS = [
  { label: 'All' },
  { label: 'Selected' },
  { label: 'Hidden' }
];

export const ARCHIVED_EXPERIMENTS_SIDEBAR_TABS = [
  { label: 'All' },
  { label: 'Selected' }
];
