import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { getExtraCols } from '@API/helpers/v2_helpers';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import toLower from 'lodash/toLower';
import { GRID_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import { formatNameForDisplay } from '@shared/utils/displayHelpers';
import modelRegistryApi from '../../util/modelRegistryApi';
import alertsUtil from '../../util/alertsUtil';
import { dialogTypes } from '../../constants/alertTypes';

// TODO: fix defect later, for now we will not show this column CM-4699
const EXCLUDED_COLUMNS_BY_NAME = ['runActive'];
const EXCLUDED_COLUMNS_BY_METADATA = ['message_time'];
const EXCLUDED_COLUMNS_BY_FF = ['Status'];

const getModelRegistryColumnNames = async ({
  registryModelId,
  isModelApprovalProcessEnabled,
  isModelRegistryTagsFromStagesEnabled
}) => {
  const resp = await modelRegistryApi.fetchModelRegistryColumnNames(
    registryModelId
  );

  const { columns = [] } = resp?.data || {};
  let filteredColumns = columns.filter(
    ({ name, source }) =>
      !EXCLUDED_COLUMNS_BY_NAME.includes(name) &&
      !EXCLUDED_COLUMNS_BY_METADATA.includes(source) &&
      (!isModelApprovalProcessEnabled
        ? !EXCLUDED_COLUMNS_BY_FF.includes(name)
        : true)
  );

  // SMELLY: Stages to tags migration on FE
  if (isModelRegistryTagsFromStagesEnabled) {
    filteredColumns = filteredColumns.map(({ name, ...props }) => ({
      name: name === GRID_COLUMNS.STAGE ? GRID_COLUMNS.TAGS : name,
      ...props
    }));
  }

  const orderedColumns = orderBy(
    uniqBy(filteredColumns, ({ id }) => id),
    ({ name }) => toLower(formatNameForDisplay(name)),
    'asc'
  );
  const extraCols = getExtraCols(filteredColumns) || [];

  return {
    columns: orderedColumns,
    extraCols
  };
};

const useModelRegistryColumnNames = ({
  registryModelId,
  isModelApprovalProcessEnabled,
  isModelRegistryTagsFromStagesEnabled
}) => {
  const dispatch = useDispatch();

  return useQuery(
    ['modelRegistryById', { registryModelId }, 'columnNames'],
    () =>
      getModelRegistryColumnNames({
        registryModelId,
        isModelApprovalProcessEnabled,
        isModelRegistryTagsFromStagesEnabled
      }),
    {
      enabled: !!registryModelId,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CUSTOMIZE_MODEL_REGISTRY_COLUMNS_ERRORS,
            'Unable to get data for columns'
          )
        );
      }
    }
  );
};

export default useModelRegistryColumnNames;
