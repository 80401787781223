import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes } from '../../../constants/alertTypes';

const getModelRegistryNotes = async (workspace, modelName) => {
  const { data = {} } =
    (await modelRegistryApi.fetchModelNotes(workspace, modelName)) || {};

  return data?.notes;
};

const useModelRegistryNotes = ({ workspace, modelName }) => {
  const dispatch = useDispatch();

  return useQuery(
    ['modelRegistryNotes', { workspace, modelName }],
    () => getModelRegistryNotes(workspace, modelName),
    {
      enabled: !!modelName && !!workspace,
      refetchOnMount: true,
      onError: () =>
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            'There was an error fetching your model notes.'
          )
        )
    }
  );
};

export default useModelRegistryNotes;
