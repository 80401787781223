import PropTypes from 'prop-types';
import React from 'react';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@material-ui/lab';
import { Box } from '@material-ui/core';
import classNames from './ModelRegistryHistory.module.scss';

export default function ModelRegistryHistoryTimelineGroup({ title, children }) {
  return (
    <TimelineItem
      data-test="model-registry-timeline-item"
      classes={{
        root: classNames.modelRegistryHistoryTimelineItem
      }}
    >
      <TimelineSeparator data-test="model-registry-timeline-separator">
        <TimelineDot
          classes={{
            root: classNames.modelRegistryHistoryTimelineDot
          }}
        />
        <TimelineConnector
          data-test="model-registry-timeline-connector"
          classes={{
            root: classNames.modelRegistryHistoryTimelineLine
          }}
        />
      </TimelineSeparator>
      <TimelineContent
        data-test="model-registry-timeline-content"
        classes={{
          root: classNames.modelRegistryHistoryTimelineContent
        }}
      >
        <h6
          className={classNames.modelRegistryHistoryTimelineGroupTitle}
          data-test="model-registry-timeline-title"
        >
          {title}
        </h6>
        <Box data-test="model-registry-timeline-children">{children}</Box>
      </TimelineContent>
    </TimelineItem>
  );
}

ModelRegistryHistoryTimelineGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.elementType.isRequired
};
