import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { IconButton, Tooltip } from '@ds';
import {
  getPermissionValueByType,
  getUserUniqueName
} from '@shared/utils/permissions';
import {
  MANAGEMENT_PERMISSIONS_SERVER,
  WORKSPACE_OWNER_VALUE
} from '@shared/constants/permissions';
import ManageUsersModalRemoveCellTooltip from './ManageUsersModalRemoveCellTooltip';
import { DSRemoveIcon } from '@ds-icons';

const ManageUsersModalRemoveCell = ({
  row,
  currentUser,
  canRemoveUsers,
  onRemoveUsers,
  ifCurrentWsOnlyOne
}) => {
  const userPermissions = row?.permissions;
  const wsManagementPermissionValue = getPermissionValueByType(
    userPermissions,
    MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
  );
  const ifRowUserWsOwner =
    wsManagementPermissionValue === WORKSPACE_OWNER_VALUE;
  const ifRowUserCurrentUser =
    row?.uniqueName === getUserUniqueName(currentUser);
  const ifInvitedByEmail = !row.isMember;
  const ifTryRemoveYourselfFromLastWs =
    ifRowUserCurrentUser && ifRowUserWsOwner && ifCurrentWsOnlyOne;
  const disabled =
    // cannot manage users/invite/remove (permission from BE)
    !canRemoveUsers ||
    // if current user want to remove himself from WS, but it is the only one or default, he cannot do it
    ifTryRemoveYourselfFromLastWs ||
    // if user is not a member, he cannot remove users
    ifInvitedByEmail;

  return (
    <Box>
      <Tooltip
        content={
          !disabled ? (
            'Remove user'
          ) : (
            <ManageUsersModalRemoveCellTooltip
              ifCurrentWsOnlyOne={ifCurrentWsOnlyOne}
              ifRowUserCurrentUser={ifRowUserCurrentUser}
              ifRowUserWsOwner={ifRowUserWsOwner}
              ifRowUserInvitedByEmail={ifInvitedByEmail}
            />
          )
        }
        placement={!disabled ? 'top' : 'left'}
        arrow
      >
        <IconButton
          Icon={<DSRemoveIcon />}
          type="secondary"
          disabled={disabled}
          onClick={() => onRemoveUsers(row?.uniqueName)}
          aria-label="Delete"
        />
      </Tooltip>
    </Box>
  );
};

ManageUsersModalRemoveCell.propTypes = {
  row: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        permissionName: PropTypes.string,
        permissionValue: PropTypes.string
      })
    ),
    avatar: PropTypes.string,
    uniqueName: PropTypes.string,
    isMember: PropTypes.bool,
    isAdmin: PropTypes.bool
  }),
  currentUser: PropTypes.shape({
    isAdmin: PropTypes.bool
  }),
  canRemoveUsers: PropTypes.bool.isRequired,
  onRemoveUsers: PropTypes.func.isRequired,
  ifCurrentWsOnlyOne: PropTypes.bool.isRequired
};

ManageUsersModalRemoveCell.defaultProps = {
  row: {},
  currentUser: {}
};

export default ManageUsersModalRemoveCell;
