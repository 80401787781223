import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import MembersDropdownMenu from './MembersDropdownMenu';
import MemberAvatar from './MemberAvatar';
import MemberName from './MemberName';
import MemberTooltip from './MemberTooltip';
import { DSColors } from '@design-system-outdated/constants';
import { Avatar, StyledTooltip } from '../../data-display';

const Members = ({ membersPreview, menuMembers, onLoadMenuMembers }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dropdownMenuMembers = useMemo(() => {
    return menuMembers.map(member => {
      return {
        value: member.email,
        ...(member?.isWsOwner ? { tooltipText: 'Owner' } : {}),
        ...member
      };
    });
  }, [menuMembers]);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    onLoadMenuMembers();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className="members-container">
      <ul>
        {membersPreview.map(member => (
          <StyledTooltip
            key={member.email}
            title={<MemberTooltip {...member} />}
            placement="top"
          >
            <li>
              <MemberAvatar {...member} />
            </li>
          </StyledTooltip>
        ))}
        {!!menuMembers?.length && (
          <li onClick={handleOpen} className="amount-members">
            <Avatar
              letters={`+${menuMembers?.length}`}
              showLetters
              type="small"
              width="32px"
              backgroundColor={DSColors.grayColor5}
              lettersFontSize="14px"
              lettersFontWeight={400}
              lettersLineHeight="15px"
            />
          </li>
        )}
      </ul>
      <MembersDropdownMenu
        anchorEl={anchorEl}
        renderMemberName={MemberName}
        renderMemberAvatar={MemberAvatar}
        handleCloseMenu={handleCloseMenu}
        dropdownMenuMembers={dropdownMenuMembers}
        menuMembers={menuMembers}
      />
    </div>
  );
};

Members.propTypes = {
  membersPreview: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      isWsOwner: PropTypes.bool
    })
  ),
  menuMembers: PropTypes.array,
  onLoadMenuMembers: PropTypes.func
};

Members.defaultProps = {
  membersPreview: [],
  menuMembers: [],
  onLoadMenuMembers: noop
};

export default Members;
