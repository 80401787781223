import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE } from '@shared/constants/model-streamline-registration';
import RegisterModelSelectModelToRegister from './RegisterModelSelectModelToRegister';
import RegisterModelSubtitleLabel from './RegisterModelSubtitleLabel';
import RegisterModelSelect from './RegisterModelSelect';
import RegisterModelVersionTextField from './RegisterModelVersionTextField';
import RegisterModelRadioButtons from './RegisterModelRadioButtons';
import RegisterModelFilesView from './RegisterModelFilesView';

const RegisterModelFlowAsNewVersion = ({
  allAvailableModelsOptions,
  allRegisteredModelsOptions,
  latestVersion,
  registryRecords,
  modelNameToRegister,
  setModelNameToRegister,
  modelVersion,
  setModelVersion,
  selectedModel,
  setSelectedModel,
  setCreationStatus,
  modelAssetsInfo,
  creationStatus,
  validationNewModelVersion,
  workspace
}) => {
  return (
    <Grid container spacing={4} direction="column">
      <RegisterModelSelectModelToRegister
        selectedModel={selectedModel}
        allAvailableModelsOptions={allAvailableModelsOptions}
        setSelectedModel={setSelectedModel}
        modelAssetsInfo={modelAssetsInfo}
      />
      {selectedModel && (
        <>
          {registryRecords?.length ? (
            <Grid item xs={12}>
              <RegisterModelFilesView
                selectedModel={selectedModel}
                registryRecords={registryRecords}
                workspace={workspace}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <RegisterModelSubtitleLabel
              text={`
              Register ${selectedModel?.label}
            `}
              isFirstModelRegistration={false}
            />
            <RegisterModelRadioButtons
              value={creationStatus}
              onChange={(e, val) => setCreationStatus(val)}
            />
          </Grid>
          <Grid item xs={12}>
            <RegisterModelSelect
              dataTest="streamline-model-sidebar-select-existing-model-to-register"
              placeholder="model name"
              options={allRegisteredModelsOptions}
              label="Select a registered model"
              value={modelNameToRegister}
              onChange={setModelNameToRegister}
              extraOptionsProps={{
                withInput: true,
                isClearable: true,
                useListAsAnchor: true,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RegisterModelVersionTextField
              setModelVersion={setModelVersion}
              modelVersion={modelVersion}
              validationNewModelVersion={validationNewModelVersion}
              latestVersion={latestVersion}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

RegisterModelFlowAsNewVersion.defaultProps = {
  selectedModel: null,
  workspace: '',
  modelNameToRegister: '',
  modelVersion: '',
  modelAssetsInfo: null,
  latestVersion: null,
  allAvailableModelsOptions: [],
  allRegisteredModelsOptions: [],
  registryRecords: [],
  validationNewModelVersion: {}
};

RegisterModelFlowAsNewVersion.propTypes = {
  validationNewModelVersion: PropTypes.shape({
    validVersion: PropTypes.bool,
    versionExists: PropTypes.bool,
    isValid: PropTypes.bool
  }),
  creationStatus: PropTypes.oneOf([
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_VERSION,
    MODEL_STREAMLINE_REGISTRATION_FLOW_TYPE.NEW_MODEL
  ]).isRequired,
  latestVersion: PropTypes.string,
  modelVersion: PropTypes.string,
  workspace: PropTypes.string,
  selectedModel: PropTypes.shape({
    experimentKey: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  modelAssetsInfo: PropTypes.shape({
    assetId: PropTypes.string,
    assetPath: PropTypes.string
  }),
  setSelectedModel: PropTypes.func.isRequired,
  setModelNameToRegister: PropTypes.func.isRequired,
  modelNameToRegister: PropTypes.string,
  setModelVersion: PropTypes.func.isRequired,
  setCreationStatus: PropTypes.func.isRequired,
  allAvailableModelsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  allRegisteredModelsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      latestVersion: PropTypes.shape({
        version: PropTypes.string
      })
    })
  ),
  registryRecords: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string,
      createdAt: PropTypes.string,
      registryModelItemId: PropTypes.string,
      registryModelName: PropTypes.string,
      registryModelId: PropTypes.string
    })
  )
};

export default RegisterModelFlowAsNewVersion;
