// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadReportModal-module__modal--LQxob{display:flex;flex-direction:row;max-width:700px;padding:24px 24px 0px 24px}.DownloadReportModal-module__modal--LQxob .DownloadReportModal-module__content--aDf9g{font-size:14px}.DownloadReportModal-module__modal--LQxob .DownloadReportModal-module__text--I08j6{margin-left:16px}.DownloadReportModal-module__modal--LQxob .DownloadReportModal-module__text--I08j6 p{margin-bottom:16px}.DownloadReportModal-module__printingReport--Do458.DownloadReportModal-module__fullWidthLayout--xKGyA .react-grid-layout.edit-dashboard-layout-container{height:auto !important}.DownloadReportModal-module__printingReport--Do458.DownloadReportModal-module__fullWidthLayout--xKGyA .react-grid-layout.edit-dashboard-layout-container .react-grid-item{margin:16px 0;position:static !important;transform:none !important;width:100% !important}.DownloadReportModal-module__printingReport--Do458 #report-page-body{max-width:960px;padding:16px}.DownloadReportModal-module__fullWithLayoutToggleContainer--QRqZp{display:flex;align-items:center;margin-left:16px}.DownloadReportModal-module__fullWithLayoutToggleLabel--zV0J2{margin-left:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `DownloadReportModal-module__modal--LQxob`,
	"content": `DownloadReportModal-module__content--aDf9g`,
	"text": `DownloadReportModal-module__text--I08j6`,
	"printingReport": `DownloadReportModal-module__printingReport--Do458`,
	"fullWidthLayout": `DownloadReportModal-module__fullWidthLayout--xKGyA`,
	"fullWithLayoutToggleContainer": `DownloadReportModal-module__fullWithLayoutToggleContainer--QRqZp`,
	"fullWithLayoutToggleLabel": `DownloadReportModal-module__fullWithLayoutToggleLabel--zV0J2`
};
module.exports = ___CSS_LOADER_EXPORT___;
