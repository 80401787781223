import React, { Dispatch, SetStateAction } from 'react';
import { FEATURE_TYPES } from '@mpm-druid/constants';

import { Select, TextInput } from '@ds';
import {
  ClassificationMetricType,
  RegressionMetricType
} from './MetricPopover';

export type OptionType = {
  label: string;
  value: string;
  type: FEATURE_TYPES;
};

type CreateMetricContentProps = {
  configToCreateMetric: ClassificationMetricType | RegressionMetricType | null;
  columnsOptions: {
    featureOptions: OptionType[];
    labelsOptions: OptionType[];
    predictionsOptions: OptionType[];
  };
  createMetricValues: {
    label: string;
    prediction: string;
    feature: string;
    customValue: string;
  };
  metricValuesHandler: Dispatch<
    SetStateAction<CreateMetricContentProps['createMetricValues']>
  >;
};

export const CreateMetricContent = ({
  configToCreateMetric,
  columnsOptions,
  createMetricValues,
  metricValuesHandler
}: CreateMetricContentProps) => {
  const { featureColumn, predictionColumn, labelColumn, customValueColumn } =
    configToCreateMetric || {};
  const { labelsOptions, predictionsOptions, featureOptions } =
    columnsOptions || {};
  const { label, prediction, feature, customValue } = createMetricValues || {};

  const handleValueChange = (
    newValue: string | number | readonly string[] | undefined,
    key: string
  ) => {
    metricValuesHandler(
      (prev: CreateMetricContentProps['createMetricValues']) => {
        return {
          ...prev,
          [key]: newValue
        };
      }
    );
  };
  return (
    <>
      {labelColumn && (
        <div className="element-wrapper">
          <span className="form-element-label">Label column: </span>
          <Select
            value={label}
            onValueChange={optionData => handleValueChange(optionData, 'label')}
            options={labelsOptions}
            placeholder="Label"
            isClearable={false}
          />
        </div>
      )}
      {predictionColumn && (
        <div className="element-wrapper">
          <span className="form-element-label">Prediction column: </span>
          <Select
            value={prediction}
            onValueChange={optionData =>
              handleValueChange(optionData, 'prediction')
            }
            options={predictionsOptions}
            placeholder="Prediction"
            isClearable={false}
          />
        </div>
      )}
      {featureColumn && (
        <div className="element-wrapper">
          <span className="form-element-label">Feature column: </span>
          <Select
            value={feature}
            onValueChange={optionData =>
              handleValueChange(optionData, 'feature')
            }
            options={featureOptions}
            placeholder="Feature"
            isClearable={false}
          />
        </div>
      )}

      {customValueColumn && (
        <div className="element-wrapper">
          <span className="form-element-label">Positive class: </span>
          <TextInput
            value={customValue}
            height="36px"
            onChange={event => {
              handleValueChange(event.target.value, 'customValue');
            }}
          />
        </div>
      )}
    </>
  );
};
