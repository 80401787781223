import React from 'react';
import PropTypes from 'prop-types';

import { RemoveMetricIcon } from '@Icons-outdated';
import { Box, Button } from '@material-ui/core';
import classNames from './FieldsList.module.scss';

const FieldsListRemoveIcon = ({
  index,
  disabled,
  onRemoveButtonClick,
  dataTest
}) => (
  <Box textAlign="center">
    <Button
      data-test={
        dataTest
          ? `${dataTest}-remove-btn-${index}`
          : `ds-fields-list-remove-btn-${index}`
      }
      variant="text"
      disabled={disabled}
      onClick={() => onRemoveButtonClick(index)}
      className={classNames.dsFieldsListRemoveBtn}
    >
      <RemoveMetricIcon />
    </Button>
  </Box>
);

FieldsListRemoveIcon.defaultProps = {
  disabled: false,
  dataTest: null
};

FieldsListRemoveIcon.propTypes = {
  dataTest: PropTypes.string,
  onRemoveButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired
};

export default FieldsListRemoveIcon;
