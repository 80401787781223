import rpcApi from '../util/rpcApi';
import { rpcActionTypes } from '../constants/actionTypes';
import alertsUtil from '../util/alertsUtil';
import { dialogTypes } from '../constants/alertTypes';

const rpcActions = {
  sendRPC(RPC) {
    return dispatch => {
      return rpcApi
        .sendRPC(RPC)
        .then(() => {
          dispatch({ type: `${rpcActionTypes.SEND_RPC}${RPC.functionName}` });
        })
        .catch(() => {
          dispatch(
            alertsUtil.openErrorDialog(
              dialogTypes.CATCH_ERROR_SEND_RPC,
              `There was an error sending the Remote Procedure Call named: ${RPC.functionName}`
            )
          );
        });
    };
  }
};

export default rpcActions;
