import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import notesSelectors from '@/selectors/notesSelectors';

type UseExperimentNoteParams = {
  experimentKey: string;
};

const getExperimentNote = async ({
  experimentKey,
  signal
}: {
  experimentKey: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get('notes/experiment/get', {
    params: { experimentKey },
    signal
  });

  return notesSelectors.manageNoteApiVersion(data);
};

export default function useExperimentNote(
  { experimentKey }: UseExperimentNoteParams,
  options: QueryConfig<string> = {}
) {
  return useQuery(
    ['experiment-note', { experimentKey }],
    ({ signal }) => {
      return getExperimentNote({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      ...options
    }
  );
}
