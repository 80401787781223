import {
  FREE_PLANS,
  ORGANIZATION_PLAN_COMMUNITY,
  ORGANIZATION_PLAN_ENTERPRISE
} from '@shared/constants';
import { IS_ON_PREMISE } from '@/lib/appConstants';
import useCurrentOrganization from './useCurrentOrganization';
import { OrganizationPaymentPlan } from '@shared/types';

interface CurrentPaymentPlan {
  paymentPlanName: OrganizationPaymentPlan | null;
  timestampWhenTrialEnds: number | null;
  isTrial: boolean;
  isAdmin: boolean;
  isAcademic: boolean;
  isStartupFree: boolean;
  isSuspended: boolean;
  isFreePlan: boolean;
  isFree: boolean;
  isWithHours: boolean;
  isPossibleToInviteUsers: boolean;
  isWithBillingDetails: boolean;
}

const useCurrentPaymentPlan = (): CurrentPaymentPlan => {
  const currentOrganization = useCurrentOrganization();

  const timestampWhenTrialEnds = currentOrganization?.trialEnds || null;

  const isTrial = !!currentOrganization?.trial;

  const isAdmin = !!currentOrganization?.isAdmin;

  const isAcademic = !!currentOrganization?.academic;

  const isStartupFree = !!currentOrganization?.startup;

  const isSuspended = !!currentOrganization?.suspended;

  const currentPaymentPlanName = currentOrganization?.paymentPlan || null;

  const isFreePlan = FREE_PLANS.includes(currentPaymentPlanName || '');

  const isWithHours = !!currentOrganization?.withUsageBasedPaymentPlan;

  // if there is no need to pay for a paid payment plan
  const isFree = isAcademic || isStartupFree;

  const isPossibleToInviteUsers =
    isFree ||
    !!IS_ON_PREMISE ||
    currentOrganization?.paymentPlan !== ORGANIZATION_PLAN_COMMUNITY;

  const isWithBillingDetails =
    currentPaymentPlanName !== ORGANIZATION_PLAN_ENTERPRISE && !isFreePlan;

  return {
    paymentPlanName: currentPaymentPlanName,
    timestampWhenTrialEnds,
    isTrial,
    isAdmin,
    isAcademic,
    isStartupFree,
    isSuspended,
    isFreePlan,
    isFree,
    isWithHours,
    isPossibleToInviteUsers,
    isWithBillingDetails
  };
};

export default useCurrentPaymentPlan;
