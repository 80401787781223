import PropTypes from 'prop-types';
import React from 'react';
import './RadioSelector.scss';
import cx from 'classnames';

export default function RadioSelector(props) {
  const { text, isSelected, onClick } = props;

  return (
    <div
      onClick={onClick}
      className={cx('ds-radio-button', { selected: isSelected })}
    >
      <p> {text} </p>
    </div>
  );
}

RadioSelector.propTypes = {
  isSelected: PropTypes.any.isRequired,
  onClick: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired
};
