import { maxBy } from 'lodash';
import {
  AllowedCompareKeys,
  ComparisonTableDefaultData,
  ComparisonTableRow,
  GroupedParamsMap
} from '../types';
import { ExperimentDetails } from '@shared/types';
import {
  calcPercentageChange,
  getParamsEqualityMap,
  isValidDiffValue
} from '../utils';
import { isStringInSearchText } from '@/helpers/generalHelpers';
import { generateRegexFromString } from '@shared/utils/displayHelpers';

const EXPERIMENT_COUNT_FOR_PERCENTAGE = 2;

type UseComparisonRowDataOpts<TData> = {
  experiments: ExperimentDetails[];
  experimentsData: TData[][];
  filterDuplicates: boolean;
  searchText: string;
  valueKeys: AllowedCompareKeys[];
};
/// TODO Algoritrm should be improved for performance and refactored for maintance
const useComparisonRowData = <TData extends ComparisonTableDefaultData>({
  experiments,
  experimentsData,
  searchText,
  filterDuplicates,
  valueKeys
}: UseComparisonRowDataOpts<TData>) => {
  let rowData: ComparisonTableRow[] = [];

  if (!experimentsData.length) {
    return rowData;
  }

  const isShouldCalculateDiffs =
    experiments.length === EXPERIMENT_COUNT_FOR_PERCENTAGE;

  /// get list of full available params to be able fill empty rows if experiment has not this param
  const fullParamsList = maxBy(experimentsData, arr => arr.length) ?? [];
  /// Map to store items by group and then check equality
  const groupByKeyMap: GroupedParamsMap = new Map();

  /// Map to store all items by unique key (experimentKey+param name) and then get it by Index (performance)
  const experimentDetailsMap = new Map<string, TData>();
  experimentsData.forEach((expDetailsList, experimentIdx) => {
    const { experimentKey } = experiments[experimentIdx];
    expDetailsList.forEach(experimentDetails => {
      experimentDetailsMap.set(
        `${experimentKey}_${experimentDetails.name}`,
        experimentDetails
      );
    });
  });

  const searchRegex = generateRegexFromString(searchText);
  // first of all, filter all params
  const searchList = fullParamsList.filter(item => {
    if (searchRegex) {
      return searchRegex.test(item.name);
    }

    return isStringInSearchText(item.name, searchText);
  });

  // prepare data for table and fill Map for Equality logic
  searchList.forEach(defaultParamData => {
    experiments.forEach((experimentDetails, experimentIdx) => {
      const currentParamData = experimentDetailsMap.get(
        `${experimentDetails.experimentKey}_${defaultParamData.name}`
      );

      const key = currentParamData?.name ?? defaultParamData.name;
      const rowId = `${key}_${experimentDetails.experimentKey}`;
      const isMain = experimentIdx === 0;
      const groupList = groupByKeyMap.get(key);

      const rowItem = {
        rowId,
        key,
        experiment: experimentDetails,
        isMain,
        ...currentParamData
      };

      rowData.push(rowItem);

      if (!groupList) {
        groupByKeyMap.set(key, [rowItem]);
      } else {
        groupList.push(rowItem);
      }
    });
  });

  /// get Equality map and fill rowData
  const equalityMap = getParamsEqualityMap(groupByKeyMap, valueKeys);
  rowData = rowData.map(data => {
    data.isEqual = equalityMap.get(data.key);
    return data;
  });

  // filter rowData by isEqual or not
  if (filterDuplicates) {
    rowData = rowData.filter(item => !item.isEqual);
  }

  // calculate diffs
  if (isShouldCalculateDiffs) {
    for (let i = 0; i < rowData.length; i += 2) {
      const firstItem = rowData[i];
      const secondItem = rowData[i + 1];

      if (firstItem.isEqual) {
        continue;
      }

      valueKeys.forEach(key => {
        const firstValue = firstItem[key] || '';
        const secondValue = secondItem[key] || '';

        if (!isValidDiffValue(firstValue) || !isValidDiffValue(secondValue)) {
          return;
        }

        firstItem[`${key}Diff`] = calcPercentageChange(secondValue, firstValue);
        secondItem[`${key}Diff`] = calcPercentageChange(
          firstValue,
          secondValue
        );
      });
    }
  }

  return rowData;
};

export default useComparisonRowData;
