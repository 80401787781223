import { AssetStep } from '@experiment-management-shared/types';
import api from '@shared/api';
import { useCombinedQueries } from '@shared/hooks';
import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';
import { CurveAssetInfo, useCurveAssets } from './useCurveAssets';

type CurveAsset = {
  x: number[];
  y: number[];
  name: string;
};

type GetAssetResult = CurveAsset & { info: CurveAssetInfo };

function hasValue<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export type CurvesDataPanelTraceItem = {
  experiment_key: string;
  id: string;
  name: string;
  step: number | null;
  x: number[] | string[];
  y: number[] | string[];
};

const getAsset = async ({
  assetInfo,
  signal
}: {
  assetInfo: CurveAssetInfo;
  signal?: AbortSignal;
}) => {
  const { data } = await api.get<CurveAsset>('asset/download', {
    params: {
      assetId: assetInfo.assetId,
      experimentKey: assetInfo.experimentKey
    },
    signal
  });

  return { ...data, info: assetInfo };
};

type CurveAssetIdentifier = { name: string; step: AssetStep };

export function useCurvesPanelData({
  assets: assetIdentifiers,
  experimentKeys
}: {
  assets: CurveAssetIdentifier[];
  experimentKeys: string[];
}) {
  const {
    data: { assets, steps },
    isLoading: isLoadingAssetsList
  } = useCurveAssets({
    experimentKeys
  });

  const requestedAssetsInfo = assets.filter(assetInfo => {
    return assetIdentifiers.some(assetIdentifier => {
      return (
        assetIdentifier.name === assetInfo.fileName &&
        assetIdentifier.step === assetInfo.step
      );
    });
  });

  const queries = requestedAssetsInfo.map(assetInfo => {
    return {
      queryKey: [
        'curveAsset',
        { experimentKey: assetInfo.experimentKey, id: assetInfo.assetId }
      ],
      queryFn: ({ signal }: { signal?: AbortSignal }) => {
        return getAsset({
          assetInfo,
          signal
        });
      },
      keepPreviousData: true
    } as UseQueryOptions<GetAssetResult, AxiosError, GetAssetResult>;
  });

  const assetsQuery = useCombinedQueries(queries);
  const traces = assetsQuery.data.filter(hasValue).map(asset => {
    return {
      experiment_key: asset.info.experimentKey,
      id: asset.info.assetId,
      name: asset.info.fileName,
      step: asset.info.step,
      x: asset.x,
      y: asset.y
    } as CurvesDataPanelTraceItem;
  });

  return {
    data: { steps, traces },
    isLoading: isLoadingAssetsList || assetsQuery.isLoading,
    isError: assetsQuery.isError,
    isFetching: assetsQuery.isFetching,
    isPreviousData: assetsQuery.isFetching
  };
}
