import React, { useCallback } from 'react';
import { Box, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import { LearnMoreIcon } from '@Icons-outdated';
import PropTypes from 'prop-types';
import { alertsActionTypes } from '@/constants/actionTypes';

const useStyles = makeStyles(theme => ({
  popoverWrapper: {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.13)'
  },
  popover: {
    width: '356px',
    border: '1px solid #F0F2F6',
    borderRadius: '4px'
  },
  popoverTitle: {
    paddingLeft: theme.spacing(2.25),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e2e2e2'
  },
  titleText: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500
  },
  popoverContent: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.75),
    fontSize: '12px',
    lineHeight: '18px'
  },
  learnMore: {
    color: '#5155F5',
    fontSize: 14,
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:visited': {
      color: '#5155F5'
    },
    '& svg': {
      marginLeft: '6px',
      marginBottom: '-1px'
    }
  },
  buttons: {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: '14px',
    height: '20px'
  },
  button: {
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 6px',
    borderRadius: '50px',
    marginRight: 1,
    backgroundColor: '#12A4B4'
  },
  metrics: {
    backgroundColor: '#FB9341'
  },
  params: {
    backgroundColor: '#ED4A7B'
  },
  stdout: {
    backgroundColor: '#945FCF'
  },
  graph: {
    backgroundColor: '#5899DA'
  },
  system_metrics: {
    backgroundColor: '#19A979'
  }
}));

const ThrottlingPopover = ({ rows }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const throttlingPopupOpen = useSelector(s => s.alerts.throttlingPopupOpen);
  const throttlingExperimentKey = useSelector(s => s.alerts.experimentKey);
  const throttledExperiment = find(
    rows,
    r => r.experimentKey === throttlingExperimentKey
  );

  const throttlingPopoverAnchor = useSelector(
    s => s.alerts.throttlingPopoverAnchor
  );

  const handlePopoverClose = useCallback(() => {
    dispatch({
      type: alertsActionTypes.CLOSE_THROTTLING_POPUP
    });
  }, []);

  return (
    <Popover
      open={throttlingPopupOpen}
      anchorEl={throttlingPopoverAnchor}
      onClose={handlePopoverClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      elevation={0}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      classes={{
        paper: classes.popoverWrapper
      }}
    >
      <div className={classes.popover}>
        <div className={classes.popoverTitle}>
          <div className={classes.titleText}>Throttled experiment</div>
          <Box
            component="a"
            className={classes.learnMore}
            href="/docs/v2/api-and-sdk/python-sdk/warnings-errors/#rate-limits"
          >
            Learn more
            <LearnMoreIcon />
          </Box>
        </div>
        <div className={classes.popoverContent}>
          <div>Rate limit reached!</div>
          <Box mt={1.5}>
            The experiment has been throttled due to logging too many data too
            quickly. Mission-critical data, like Artifacts, are never throttled.
          </Box>
          {throttledExperiment?.throttlingReasons?.length && (
            <div className={classes.buttons}>
              {throttledExperiment.throttlingReasons.map(reason => {
                const color = classes[reason] || {};
                return (
                  <div key={reason} className={cx(classes.button, color)}>
                    on {reason}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Popover>
  );
};

ThrottlingPopover.defaultProps = {
  rows: []
};

ThrottlingPopover.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object)
};

export default ThrottlingPopover;
