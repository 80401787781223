import { Checkbox } from '@DesignSystem/controllers';
import { Collapse, StyledTooltip } from '@DesignSystem/data-display';
import { Snackbar } from '@DesignSystem/feedback';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUndoSnackbarState } from '@account-settings/hooks';

import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import { BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL } from '@/lib/betaFeatures';
import { VerifyEmailLink } from '../Profile/VerifyEmailLink';
import useIsUserVerified from '@API/auth/useIsUserVerified';

const CHECKBOX_THROTTLE = 500;

const NotificationsTab = ({
  mpmNotifications,
  experimentNotifications,
  onChangeExperimentNotifications,
  onChangeMpmNotifications,
  isSaving,
  isSuccess,
  onCloseSuccessSnackbar
}) => {
  const [isChecked, setIsChecked] = useState(experimentNotifications);

  const [isMpmChecked, setMpmChecked] = useState(mpmNotifications);

  const handleChangeExperimentNotifications = () => {
    if (!isSaving) {
      onChangeExperimentNotifications();
    }
  };

  const isMPMInternalEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL
    });
  });

  const handleChangeMpmNotifications = () => {
    if (!isSaving) {
      onChangeMpmNotifications();
    }
  };

  const debouncedCheckboxChange = useCallback(
    debounce(handleChangeExperimentNotifications, CHECKBOX_THROTTLE),
    []
  );

  const debouncedMpmCheckboxChange = useCallback(
    debounce(handleChangeMpmNotifications, CHECKBOX_THROTTLE),
    []
  );

  useEffect(() => {
    setIsChecked(experimentNotifications);
    setMpmChecked(mpmNotifications);
  }, [experimentNotifications, mpmNotifications]);

  const handleUndoClick = () => {
    handleChangeExperimentNotifications();
    onCloseSuccessSnackbar();
  };

  const {
    isOpen: isOpenSnackbar,
    onClose: onCloseSnackbar,
    onUndoClick
  } = useUndoSnackbarState({
    isSuccess,
    resetMutationState: onCloseSuccessSnackbar,
    onUndoClick: handleUndoClick,
    onClose: onCloseSuccessSnackbar,
    isLoading: isSaving
  });

  const { data: isVerified } = useIsUserVerified();

  return (
    <>
      <Snackbar
        open={isOpenSnackbar}
        onClose={onCloseSnackbar}
        message="Settings saved"
        withUndo
        onUndoClick={onUndoClick}
      />

      <Snackbar open={isSaving} message="Saving..." />

      <div className="notifications-tab-container">
        <div className="field-label send-me-label">
          An email notification will be sent to you at the end of each training
          run. For more information &nbsp;
          <a
            href="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentsend_notification"
            target="_blank"
            rel="noreferrer"
          >
            <span className="link">check out our documentaion.</span>{' '}
          </a>
        </div>

        <div className="notification-collapse-container">
          <Collapse title="Experiment Management" expandedByDefault>
            <div className="notification-collapse-container">
              <div className="checkbox-field field-label">
                <StyledTooltip
                  placement="top"
                  PopperProps={{ style: { width: '207px' } }}
                  title={
                    !isVerified
                      ? 'In order to get notifications you need to verify your email. You can do this from the email section at the profile tab or below.'
                      : ''
                  }
                >
                  <div>
                    <Checkbox
                      disabled={!isVerified}
                      checked={isChecked}
                      onChange={() => {
                        setIsChecked(prev => !prev);
                        debouncedCheckboxChange();
                      }}
                    />
                  </div>
                </StyledTooltip>
                <div className="checkbox-label">
                  <span>Experiment notifications</span>
                  <VerifyEmailLink />
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        {isMPMInternalEnabled && (
          <div className="notification-collapse-container">
            <Collapse title="Model Production Monitoring" expandedByDefault>
              <div className="notification-collapse-container">
                <div className="checkbox-field field-label">
                  <StyledTooltip
                    placement="top"
                    PopperProps={{ style: { width: '207px' } }}
                    title={
                      !isVerified
                        ? 'In order to get MPM alerts you need to verify your email. You can do this from the email section at the profile tab or below.'
                        : ''
                    }
                  >
                    <div>
                      <Checkbox
                        disabled={!isVerified}
                        checked={isMpmChecked}
                        onChange={() => {
                          setMpmChecked(prev => !prev);
                          debouncedMpmCheckboxChange();
                        }}
                      />
                    </div>
                  </StyledTooltip>
                  <span className="checkbox-label">
                    MPM email alerts
                    <VerifyEmailLink />
                  </span>
                </div>
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </>
  );
};

NotificationsTab.propTypes = {
  mpmNotifications: PropTypes.bool.isRequired,
  experimentNotifications: PropTypes.bool.isRequired,
  onChangeExperimentNotifications: PropTypes.func.isRequired,
  onChangeMpmNotifications: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  onCloseSuccessSnackbar: PropTypes.func.isRequired
};

export default NotificationsTab;
