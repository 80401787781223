import { Context, createContext, useContext } from 'react';
import { RADIO_PROP_NO_VALUE } from './constants';

interface RadioContextType {
  value?: string;
  name?: string;
}

export const RadioContext: Context<RadioContextType> = createContext<RadioContextType>(
  {
    value: RADIO_PROP_NO_VALUE,
    name: RADIO_PROP_NO_VALUE
  }
);

export const useRadioContext = (): RadioContextType =>
  useContext<RadioContextType>(RadioContext);
