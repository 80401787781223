import { useQuery } from 'react-query';

import api from '@API/api';

type ModelRegistryParams = {
  modelId: string;
  workspaceId: string;
};

const getModel = ({ workspaceId, modelId }: ModelRegistryParams) =>
  api.get(`/mpm/${workspaceId}/models/${modelId}`);

export function useGetModelRegistryNameById({
  modelId,
  workspaceId
}: ModelRegistryParams) {
  return useQuery(
    ['getModelRegistryNameById', { workspaceId, modelId }],
    () => getModel({ workspaceId, modelId }),
    {
      refetchOnMount: true,
      enabled: !!workspaceId
    }
  );
}
