import React from 'react';
import styles from './Tabs.module.scss';
import { SidebarTabProps } from '../types/SidebarTabProps';
import { formatDateTime } from '@shared/utils/displayHelpers';

export default function LLMDetailsTab({
  data,
  experiment,
  selectedChainNode
}: SidebarTabProps) {
  return (
    <div>
      <div className={styles.dataBlock}>
        <div className={styles.title}>Name: &nbsp;</div>
        <span>
          {selectedChainNode
            ? selectedChainNode?.name || '-'
            : experiment?.Name}
        </span>
      </div>
      <div className={styles.dataBlock}>
        <div className={styles.title}>Category: &nbsp;</div>
        <span> {selectedChainNode?.category || data?.category}</span>
      </div>

      <div className={styles.dataBlock}>
        <div className={styles.title}>Timestamp: &nbsp;</div>
        <span>
          {formatDateTime(
            selectedChainNode?.start_timestamp ?? data?.start_timestamp
          )}
        </span>
      </div>

      <div className={styles.dataBlock}>
        <div className={styles.title}>Duration: &nbsp;</div>
        <span>
          {(selectedChainNode?.duration ?? data?.chain_duration) || 'NA'}
          {!!data?.chain_duration && ' ms'}
        </span>
      </div>

      <div className={styles.dataBlock}>
        <div className={styles.title}>User name: &nbsp;</div>
        <span>{experiment?.user_name || 'NA'}</span>
      </div>
    </div>
  );
}
