import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import noop from 'lodash/noop';
import isNull from 'lodash/isNull';
import { Button } from '@ds';
import Grid from '@material-ui/core/Grid';

import { useDispatch } from 'react-redux';
import {
  formatFileSize,
  formatDate
} from '@experiment-management-shared/utils/filesTreeUtils';
import {
  DIR_TYPE,
  DIR_TYPES
} from '@experiment-management-shared/constants/fileBrowser';
import { getDownloadAssetURL, getEmbeddedProjectorURL } from '@/util/assetsApi';
import alertsUtil from '@/util/alertsUtil';
import { isAssetWithPreview } from '@experiment-management-shared/utils/assetsUtils';

const Metadata = ({ experimentKey, isModal, item, onViewClick }) => {
  const dispatch = useDispatch();

  if (!item) return null;

  const {
    assetId,
    createdAt,
    dir,
    fileName,
    files,
    fileSize,
    link,
    relativePath,
    remote,
    runContext,
    step,
    type
  } = item;
  const downloadAssetURL = getDownloadAssetURL(experimentKey, assetId);

  const isDir = type === DIR_TYPE;
  const classes = classnames('asset-metadata-details', { 'is-modal': isModal });

  return (
    <div className={classes}>
      <div className="detail name">
        <div className="key">{isDir ? 'Directory' : 'File Name'}</div>
        <div className="value">{fileName || dir}</div>
      </div>

      {remote && !isNull(type) && (
        <div className="detail">
          <div className="key">Type</div>
          <div className="value">{type}</div>
        </div>
      )}
      {createdAt && (
        <div className="detail">
          <div className="key">Last Modified</div>
          <div className="value">{formatDate(createdAt)}</div>
        </div>
      )}
      <div className="detail">
        <div className="key">Path</div>
        <div className="value">{relativePath}</div>
      </div>
      {isDir && (
        <div className="detail">
          <div className="key">Files Count</div>
          <div className="value">{files.length}</div>
        </div>
      )}

      {!remote && fileSize && (
        <div className="detail">
          <div className="key">Files Size</div>
          <div className="value">{formatFileSize(fileSize)}</div>
        </div>
      )}
      {step && (
        <div className="detail">
          <div className="key">Step</div>
          <div className="value">{step}</div>
        </div>
      )}
      {runContext && (
        <div className="detail">
          <div className="key">Context</div>
          <div className="value">{runContext}</div>
        </div>
      )}

      <div className="buttons">
        <Grid container spacing={2}>
          {!isDir && !isModal && !remote && (
            <Grid item>
              <a href={downloadAssetURL} download={fileName}>
                <Button type="tertiary" className="download">
                  Download
                </Button>
              </a>
            </Grid>
          )}

          {remote && (
            <Grid item>
              <CopyToClipboard text={link}>
                <Button
                  type="tertiary"
                  onClick={() => dispatch(alertsUtil.openCopySuccessSnackbar())}
                >
                  Copy URL
                </Button>
              </CopyToClipboard>
            </Grid>
          )}

          {isAssetWithPreview(item) && (
            <Grid item>
              <Button
                type="tertiary"
                className=" button-spacing"
                onClick={onViewClick}
              >
                View
              </Button>
            </Grid>
          )}

          {dir === DIR_TYPES.EMBEDDINGS && (
            <Grid item>
              <a
                href={getEmbeddedProjectorURL(assetId, experimentKey)}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="tertiary" className="button-spacing">
                  Open in Embedding Projector
                </Button>
              </a>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

Metadata.defaultProps = {
  onViewClick: noop,
  isModal: false
};

Metadata.propTypes = {
  experimentKey: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onViewClick: PropTypes.func
};

export default Metadata;
