import React, {
  ChangeEvent,
  HTMLProps,
  useRef,
  useState,
  FocusEvent
} from 'react';
import cx from 'classnames';
import typography from '@ds-typography';
import classes from './NumberInput.module.scss';
import { NumberInputSize, NumberInputType } from '@design-system/types';
import { DSArrowDownIcon } from '@ds-icons';
import noop from 'lodash/noop';

interface NumberInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'size' | 'onChange'> {
  onChange?: (val: string) => void;
  size?: NumberInputSize;
  type?: NumberInputType;
  helperText?: string;
  invalid?: boolean;
}

// onChange event has been overwritten
// since arrows are not quite changeable and don't trigger the onChange event
const NumberInput = ({
  size = 'default',
  type = 'full',
  disabled,
  onChange = noop,
  helperText = '',
  invalid = false,
  onFocus,
  onBlur,
  ...restProps
}: NumberInputProps) => {
  const [hovered, setHovered] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }

    if (!disabled) {
      setInputFocused(true);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }

    if (!disabled) {
      setInputFocused(false);
    }
  };

  return (
    <div
      className={cx(
        classes.numberInputContainer,
        classes[size],
        classes[type],
        typography.dsBody,
        {
          [classes.invalidContainer]: invalid
        }
      )}
      onMouseEnter={() => !disabled && setHovered(true)}
      onMouseLeave={() => !disabled && setHovered(false)}
    >
      <input
        type="number"
        ref={inputRef}
        disabled={disabled}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...restProps}
      />

      {(hovered || inputFocused) && (
        <div className={classes.arrowContainer}>
          <button
            className={classes.arrowUp}
            disabled={disabled}
            onClick={() => {
              inputRef?.current?.stepUp();

              const value = inputRef?.current?.value || '';

              if (onChange) {
                onChange(value);
              }
            }}
          >
            <div className={classes.arrowIconContainer}>
              <DSArrowDownIcon />
            </div>
          </button>
          <button
            disabled={disabled}
            onClick={() => {
              inputRef?.current?.stepDown();

              const value = inputRef?.current?.value || '';

              if (onChange) {
                onChange(value);
              }
            }}
          >
            <div className={classes.arrowIconContainer}>
              <DSArrowDownIcon />
            </div>
          </button>
        </div>
      )}

      {helperText && (
        <div
          className={cx(classes.helperTextContainer, typography.dsBodySmall)}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};

export default NumberInput;
