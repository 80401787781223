// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportAuthor-module__author--JOWwD{display:flex;margin:24px 0;padding:0 30px}.ReportAuthor-module__author--JOWwD.ReportAuthor-module__viewOnly--Uj8BF{padding:0}.ReportAuthor-module__author--JOWwD .ReportAuthor-module__label--MDea3{font-size:18px;margin-left:8px}.ReportAuthor-module__author--JOWwD .ReportAuthor-module__username--OT9Jg a{font-weight:800}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"author": `ReportAuthor-module__author--JOWwD`,
	"viewOnly": `ReportAuthor-module__viewOnly--Uj8BF`,
	"label": `ReportAuthor-module__label--MDea3`,
	"username": `ReportAuthor-module__username--OT9Jg`
};
module.exports = ___CSS_LOADER_EXPORT___;
