import React from 'react';
import defaultTo from 'lodash/defaultTo';
import { isString } from 'lodash';
import Tooltip from 'rc-tooltip';
import { ARTIFACT_VERSION_COMPOSED_TYPE } from '@artifacts/constants/constants';

export const renderVersionState = (state, isDropdown = false) => {
  switch (state) {
    default:
    case 'CLOSED':
      if (isDropdown) {
        return (
          <div className="artifact-version-state-dropdown">
            <span>Finished</span>
            <i className="material-icons middle-align done">done</i>
          </div>
        );
      }
      return (
        <Tooltip overlay="Finished">
          <i className="material-icons middle-align done">done</i>
        </Tooltip>
      );
    case 'OPEN':
      if (isDropdown) {
        return (
          <div className="artifact-version-state-dropdown">
            <span>In Progress</span>
            <i className="material-icons middle-align autorenew">autorenew</i>
          </div>
        );
      }
      return (
        <Tooltip overlay="In Progress">
          <i className="material-icons middle-align autorenew">autorenew</i>
        </Tooltip>
      );
    case 'ERROR':
      if (isDropdown) {
        return (
          <div className="artifact-version-state-dropdown">
            <span>Failed</span>
            <img
              src="/images/artifacts/status-error.png"
              className="artifact-version-state-icon"
            />
          </div>
        );
      }
      return (
        <Tooltip overlay="Failed">
          <img
            src="/images/artifacts/status-error.png"
            className="artifact-version-state-icon"
          />
        </Tooltip>
      );
  }
};

export const parseMetadata = metadata => {
  let retVal = {};
  const metadataString = defaultTo(metadata, '{}');

  try {
    retVal = JSON.parse(metadataString);
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return retVal;
};

export const calculateArtifactVersionType = artifactVersion => {
  let retVal = ARTIFACT_VERSION_COMPOSED_TYPE.DEFAULT;

  const parsedMetadata = parseMetadata(artifactVersion.metadata);

  // here is using duck type checking
  if (
    isString(parsedMetadata.query) &&
    isString(parsedMetadata.sample) &&
    isString(parsedMetadata.snowflake_ui_url)
  ) {
    retVal = ARTIFACT_VERSION_COMPOSED_TYPE.SNOWFLAKE;
  }

  return retVal;
};
