export const TABLE_COLUMN_KEYS = {
  VERSION: 'version',
  CREATED_AT: 'added',
  STATE: 'state',
  SIZE: 'sizeInBytes',
  ALIAS: 'alias',
  TAGS: 'tags',
  ACTIONS: 'actions'
};

export const TABLE_COLUMN_HEADERS = [
  {
    id: TABLE_COLUMN_KEYS.STATE,
    label: 'STATUS'
  },
  {
    id: TABLE_COLUMN_KEYS.VERSION,
    label: 'VERSION'
  },
  {
    id: TABLE_COLUMN_KEYS.CREATED_AT,
    label: 'CREATED_AT'
  },
  // {
  //   id: TABLE_COLUMN_KEYS.STATE,
  //   label: 'STATUS'
  // },
  {
    id: TABLE_COLUMN_KEYS.SIZE,
    label: 'SIZE'
  },
  {
    id: TABLE_COLUMN_KEYS.ALIAS,
    label: 'ALIASES'
  },
  {
    id: TABLE_COLUMN_KEYS.TAGS,
    label: 'TAGS'
  },
  {
    id: TABLE_COLUMN_KEYS.ACTIONS,
    label: ''
  }
];

export const SORT_BY_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};

export const ARTIFACT_VERSION_PAGE_TABS = {
  SnowFlake: 'SnowFlake',
  Assets: 'Assets',
  Metadata: 'Metadata',
  Lineage: 'Lineage'
  // Comments: 'Comments',
  // Usage: 'Used By'
};

export const ARTIFACT_VERSION_COMPOSED_TYPE = {
  DEFAULT: 'DEFAULT',
  SNOWFLAKE: 'SNOWFLAKE'
};

export const createArtifactVersionKey = (
  workspaceName,
  artifactName,
  versionNumber
) => {
  return `${workspaceName}-${artifactName}-${versionNumber}`;
};

export const EMOJI_LIST = [
  '🍇',
  '🍈',
  '🍉',
  '🍊',
  '🍋',
  '🍍',
  '🥭',
  '🍎',
  '🍏',
  '🍐',
  '🍑',
  '🍒',
  '🍓',
  '🥔',
  '🥕',
  '🌽',
  '🌶️',
  '🥬',
  '🥦',
  '🧄',
  '🧅',
  '🍄',
  '🥜',
  '🌰',
  '🍞',
  '🥐',
  '🥨',
  '🥯',
  '🥞',
  '🧇',
  '🧀',
  '🥣',
  '🥗',
  '🍿',
  '🧈',
  '🧂',
  '🥫',
  '🍱',
  '🍘',
  '🍙',
  '🍚',
  '🍛',
  '🍜',
  '🍝',
  '🍠',
  '🍢',
  '🍣',
  '🍤',
  '🍥',
  '🥮',
  '🍡',
  '🥟',
  '🥠',
  '🥡',
  '🦪',
  '🍦',
  '🍧',
  '🍨',
  '🦓',
  '🦒',
  '🦔',
  '🦕',
  '🦖',
  '🦗',
  '🥥',
  '🥦',
  '🥨',
  '🥩',
  '🥪',
  '🥣',
  '🥫',
  '🥟',
  '🥠',
  '🥡',
  '🥧',
  '🥤',
  '🥢',
  '🛸',
  '🛷',
  '🥌',
  '🧣',
  '🧤',
  '🧥',
  '🧦',
  '🧢',
  '🙈',
  '🙉',
  '🙊',
  '💥',
  '💫',
  '🐵',
  '🐒',
  '🦍',
  '🦧',
  '🐶',
  '🐕',
  '🦮',
  '🐕‍🦺',
  '🐩',
  '🐺',
  '🦊',
  '🦝',
  '🐱',
  '🐈',
  '🦁',
  '🐯',
  '🐅',
  '🐆',
  '🐴',
  '🐎',
  '🦄',
  '🦓',
  '🦌',
  '🐨',
  '🐼',
  '🦥',
  '🦦',
  '🦨',
  '🦘',
  '🦡',
  '🐾',
  '🦃',
  '🐔',
  '🐓',
  '🐣',
  '🐤',
  '🐥',
  '🐦',
  '🐧',
  '🕊️',
  '🦅',
  '🦆',
  '🦢',
  '🦉',
  '🌲',
  '🌳',
  '🌴',
  '🌵',
  '🌾',
  '🌿',
  '☘️',
  '🍀',
  '🍁',
  '🍂',
  '🍃',
  '🍄',
  '🌰',
  '🦀',
  '🦞',
  '🦐',
  '🦑',
  '🌍',
  '🌑',
  '🌒',
  '🌓',
  '🌔',
  '🌕',
  '🌖',
  '🌗',
  '🌘',
  '🌙',
  '🌚',
  '🌛',
  '🌜',
  '☀️',
  '🌝',
  '🌞',
  '⭐',
  '🌟',
  '🌠',
  '☂️',
  '☔',
  '⚡',
  '❄️',
  '☃️',
  '⛄',
  '☄️',
  '🔥',
  '💧',
  '🌊',
  '🎄',
  '✨',
  '🎋',
  '🎍',
  '🗺️',
  '🧭',
  '🧱',
  '💈',
  '🦽',
  '🦼',
  '🛢️',
  '🛎️',
  '🧳',
  '⌛',
  '⏳',
  '⌚',
  '⏰',
  '⏱️',
  '⏲️',
  '🕰️',
  '🌡️',
  '⛱️',
  '🧨',
  '🎈',
  '🎉',
  '🎊',
  '🎎',
  '🎏',
  '🎐',
  '🧧',
  '🎀',
  '🎁',
  '🤿',
  '🪀',
  '🪁',
  '🔮'
];
