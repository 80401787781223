import { useQuery } from 'react-query';

import get from 'lodash/get';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';

import { generateToColumn, getSearchRules } from '@API/experiments/utils';
import useProject from '@API/project/useProject';
import useColumns from '@API/project/useColumns';
import { KIND_UNIQ_SYMBOL } from '@API/helpers/v2_helpers';
import {
  AGGREGATION_NAME_KEY,
  GROUP_VALUE_KEY
} from '@experiment-management-shared/constants/aggregation';

import { prepareRulesTreeForRequest } from '@shared/utils/filterHelpers';
import { COLUMNS_DISABLED_FOR_SUMMARY } from '../../lib/appConstants';
import experimentApi, {
  AggregationDto,
  AggregationEntity,
  AggregationTableColumn,
  GetGroupByAggregationsArgs
} from './experimentApi';
import { ProjectTemplate } from '@experiment-management-shared/types';
import { QueryConfig } from '@shared/api';

type Path = {
  value: string;
  column: string;
  kind: string;
};

type ColumnGroup = {
  groupNameValue: string;
  groupColumn: string;
  kind: string;
};

const createPathFromGroup = (group: ColumnGroup) => ({
  value: group.groupNameValue,
  column: group.groupColumn,
  kind: group.kind
});

const createKeyFromPath = (path: Path) => {
  return `${path.column}${KIND_UNIQ_SYMBOL}${path.kind}${GROUP_VALUE_KEY}${path.value}`;
};

const ALLOWED_SOURCES_FOR_COLUMNS = ['metrics', 'params', 'log_other'];

// it's built not recursively down to potentially too nested objects
const orderGroupsToGoOver = (aggregations: AggregationDto | null) => {
  const groupsToCheck = [];

  let orderedGroups = aggregations;
  let checkedGroupIndex = -1;

  // it's done with looping for the sake of performance
  while (orderedGroups) {
    const subGroups = orderedGroups?.children || [];

    const currentPath = orderedGroups?.path?.length
      ? [...orderedGroups.path]
      : [];

    const childrenPath = orderedGroups?.groupColumn ? [...currentPath] : [];

    for (let i = 0; i < subGroups?.length; i++) {
      const subGroup = subGroups[i];

      groupsToCheck.push({
        ...subGroup,
        path: [...childrenPath, createPathFromGroup(subGroup)]
      });
    }

    checkedGroupIndex += 1;
    orderedGroups =
      groupsToCheck?.length && checkedGroupIndex < groupsToCheck.length
        ? groupsToCheck[checkedGroupIndex]
        : null;
  }

  return groupsToCheck;
};

type AggregationMap = { [key: string]: AggregationMap } & {
  aggregations: Record<string, AggregationEntity>;
};
// this function returns a nested object of aggregations
// the result is about: { `${GROUP_NAME}%%%${GROUP_KIND}%VAL%${GROUP_VALUE}`: { ..., aggregations: {} }`
const createAggregationMap = (aggregations: AggregationDto | null) => {
  const aggregationMap = {} as AggregationMap;

  const orderedGroups = orderGroupsToGoOver(aggregations);

  for (let i = 0; i < orderedGroups?.length; i++) {
    const group = orderedGroups[i];

    const groupParentKeyPath = group.path.map(createKeyFromPath);
    const groupAggregations = keyBy(group?.aggregations, AGGREGATION_NAME_KEY);

    const groupValue = get(aggregationMap, groupParentKeyPath, {});
    set(aggregationMap, groupParentKeyPath, {
      ...groupValue,
      aggregations: groupAggregations
    });
  }

  return aggregationMap;
};

const getGroupsWithAggregations = async ({
  projectId,
  rulesTree,
  groupByColumns,
  tableColumns
}: GetGroupByAggregationsArgs) => {
  const response = await experimentApi.getGroupByAggregations({
    projectId,
    rulesTree,
    groupByColumns,
    tableColumns
  });

  if (response?.data?.message) {
    return { msg: response?.data?.message, data: null };
  }

  return { msg: null, data: createAggregationMap(response?.data?.rootDto) };
};

type UseGroupAggregationsOpts = {
  view: ProjectTemplate;
  groups: { columnName: string }[];
};

const useGroupAggregations = (
  { view, groups: paramGroups }: UseGroupAggregationsOpts,
  config: QueryConfig<AggregationMap | null>
) => {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  const { data: allColumns, isLoading: isLoadingColumns } = useColumns({
    extraCols: true
  });

  const toColumn = generateToColumn(allColumns);

  const normalizedGroups = paramGroups.map(
    toColumn
  ) as AggregationTableColumn[];

  const columnOrders: string[] = get(view, 'table.columnOrders', []);
  const search = get(view, 'table.search', '');
  const filteredByEnablingColumns = columnOrders.filter(
    columnName => !COLUMNS_DISABLED_FOR_SUMMARY.includes(columnName)
  );
  const normalizedFilteredColumns = filteredByEnablingColumns
    .map(toColumn)
    .filter(column =>
      ALLOWED_SOURCES_FOR_COLUMNS.includes(column?.source)
    ) as AggregationTableColumn[];

  const rulesTree = get(view, 'query.rulesTree', null);

  return useQuery(
    [
      'aggregations',
      {
        projectId,
        paramGroups,
        columnOrders,
        rulesTree,
        search
      }
    ],
    async () => {
      const { data } = await getGroupsWithAggregations({
        groupByColumns: normalizedGroups,
        tableColumns: normalizedFilteredColumns,
        projectId,
        rulesTree: prepareRulesTreeForRequest(rulesTree, getSearchRules(search))
      });

      return data;
    },
    {
      ...config,
      enabled:
        !!projectId &&
        !!normalizedGroups?.length &&
        !!normalizedFilteredColumns?.length &&
        !isLoadingColumns
    }
  );
};

export default useGroupAggregations;
