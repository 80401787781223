import { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';

import useUpsertViewMutation from '@API/project/useUpsertViewMutation';
import {
  convertDashboardToView,
  convertViewToDashboard,
  dashboardHasChanges
} from '@experiment-management-shared/utils/LLMView';
import useLLMProjectView from '@projects/api/useLLMProjectView';
import { MAX_DEFAULT_LLM_COLUMNS_COUNT } from '../../../constants/configConstants';

const UNSAVED_VIEW_DELAY = 20000;

export default function useLLMDashboard({ llmColumns }) {
  const upsertViewMutation = useUpsertViewMutation();
  const { data: view, isError, isLoading } = useLLMProjectView();

  const [dashboard, setDashboard] = useState(
    view ? convertViewToDashboard(view) : null
  );

  useEffect(() => {
    if (llmColumns && view && view.reactGridTableState == '{}') {
      setDashboard(
        convertViewToDashboard(
          view,
          llmColumns
            .map(({ name }) => name)
            .slice(0, MAX_DEFAULT_LLM_COLUMNS_COUNT)
        )
      );
    }
  }, [llmColumns, view]);

  useEffect(() => {
    if (!isLoading && view) {
      setDashboard(d => {
        // we need to set dashboard in cases:
        // 1) we don't have dashboard
        // 2) existing dashboard different from saved view
        if (!d || dashboardHasChanges(d, view)) {
          return convertViewToDashboard(view);
        }

        return d;
      });
    }
  }, [view, isLoading]);

  const upsertView = useMemo(() => {
    return debounce(
      newView => upsertViewMutation.mutate(newView),
      UNSAVED_VIEW_DELAY
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save view immediately in case user leave the page
  useEffect(() => {
    return () => {
      upsertView.flush();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Revert changes if there is an error on saving
  useEffect(() => {
    if (upsertViewMutation.isError) {
      setDashboard(convertViewToDashboard(view));
    }
  }, [upsertViewMutation.isError, view]);

  useEffect(() => {
    if (dashboard && view && dashboardHasChanges(dashboard, view)) {
      upsertView({
        view: {
          ...convertDashboardToView(dashboard, view),
          templateName: 'Unsaved Changes',
          unsavedView: true
        }
      });
    }
  }, [dashboard, view, upsertView]);

  const handleDashboardChange = useCallback(changesOrGetter => {
    setDashboard(prevView => {
      if (!prevView) return prevView;

      let changes = changesOrGetter;
      if (isFunction(changesOrGetter)) {
        changes = changesOrGetter(prevView);
      }

      return mapValues(prevView, (value, key) => {
        if (!changes[key]) return value;

        if (Array.isArray(changes[key])) return changes[key];

        return {
          ...value,
          ...changes[key]
        };
      });
    });
  }, []);

  if (isError) {
    console.error('Failed to receive View');
  }

  return {
    dashboard,
    handleDashboardChange
  };
}
