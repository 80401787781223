import { Box } from '@material-ui/core';
import { getStageColor } from '@DesignSystem/data-display/Stages/Stages';
import { components } from 'react-select';
import React from 'react';

const MultiValueContainer = props => (
  <Box
    className="manage-stages-select-container"
    sx={{
      background: props?.data?.color || getStageColor(props?.data?.label)
    }}
  >
    <components.MultiValueContainer {...props} />
  </Box>
);

export default MultiValueContainer;
