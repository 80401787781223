import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { formatNameForDisplay } from '@shared/utils/displayHelpers';

import ExperimentTagList from './ExperimentTagList';
import EditExperimentNameField from './EditExperimentNameField';
import Cell, { isCellDataInvalid } from '../../Cell';

const EXCLUDE_COLUMNS_IN_TOOLTIP = [
  'pinned',
  'singleExperimentBtn',
  'isVisibleOnDashboard',
  'comet_chart_color',
  'Name'
];

const ExperimentMetadataPopover = ({
  anchorEl,
  columns,
  decimalsPrecision,
  row,
  ...PopoverProps
}) => {
  const renderCell = column => {
    const { name } = column;
    if (['experimentKey'].includes(name)) {
      return get(row, name, null);
    }

    if (name === 'experimentTags') {
      return (
        <ExperimentTagList
          tagList={row.tags}
          experimentKey={row.experimentKey}
        />
      );
    }

    return (
      <Cell
        column={column}
        decimalsPrecision={decimalsPrecision}
        row={row}
        withTooltip={false}
      />
    );
  };

  const isRowWithValidValue = ({ name }) => {
    if (EXCLUDE_COLUMNS_IN_TOOLTIP.includes(name)) {
      return false;
    }

    if (name === 'experimentTags') {
      return !isEmpty(row.tags);
    }

    const value = get(row, name, null);

    return !isCellDataInvalid(value);
  };

  const renderRows = () => {
    const selectedColumnNames = columns.filter(isRowWithValidValue);

    return selectedColumnNames.map(column => {
      const isStatusColumn = column.name === 'runActive';

      return (
        <div
          key={column.name}
          className={cx('experiment-card-column', {
            'experiment-card-column-status': isStatusColumn
          })}
        >
          <div className="experiment-card-column-title">
            {formatNameForDisplay(column.name)}
          </div>

          <div className="experiment-card-column-value">
            {renderCell(column)}
          </div>
        </div>
      );
    });
  };

  const renderHeader = () => {
    return (
      <div className="experiment-metadata-popover-header">
        <EditExperimentNameField row={row} />
      </div>
    );
  };

  const renderContent = () => {
    if (!row) {
      return null;
    }

    return (
      <Paper className="experiment-metadata-popover" variant="outlined">
        {renderHeader()}
        <div className="experiment-metadata-popover-body">{renderRows()}</div>
      </Paper>
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      className="experiment-card-tooltip"
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      {...PopoverProps}
    >
      {renderContent()}
    </Popover>
  );
};

ExperimentMetadataPopover.defaultProps = {
  anchorEl: null,
  decimalsPrecision: null
};

ExperimentMetadataPopover.propTypes = {
  anchorEl: PropTypes.object,
  columns: PropTypes.array.isRequired,
  decimalsPrecision: PropTypes.number,
  row: PropTypes.object.isRequired
};

export default ExperimentMetadataPopover;
