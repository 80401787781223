import React from 'react';
import cx from 'classnames';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { ExperimentCircleColorPicker } from '@experiment-management-shared/components';
import { PARAM_TREE_COLUMNS } from '@experiment-details/components/params/helpers';
import { Tooltip } from '@ds';

import styles from './HyperParametersTab.module.scss';

type ParameterHeaderContentProps = {
  column: {
    color: string;
    name: string;
    title: string;
  };
};
const ParameterHeaderContent: React.FC<ParameterHeaderContentProps> = ({
  ...props
}) => {
  const color = props?.column?.color;
  const columnName = props?.column?.name;
  const columnTitle = props?.column?.title;
  const hasExperimentColor = columnName !== PARAM_TREE_COLUMNS.NAME;

  return (
    <TableHeaderRow.Content
      {...props}
      align="left"
      className={cx(styles.headerExperimentNameContent, {
        [styles.headerWithColor]: hasExperimentColor
      })}
    >
      {hasExperimentColor && (
        <ExperimentCircleColorPicker
          experiment={{ comet_chart_color: color, experimentKey: columnName }}
        />
      )}

      <Tooltip content={columnTitle} placement="top" truncateOverflow>
        <>{columnTitle}</>
      </Tooltip>
    </TableHeaderRow.Content>
  );
};

export default ParameterHeaderContent;
