import { EmptyExperimentTab } from '@shared';
import React from 'react';
import classes from './SystemMetricsTab.module.scss';
import cx from 'classnames';

type SystemMetricsEmptyMessageProps = {
  isComparePage: boolean;
};
const SystemMetricsEmptyMessage = ({
  isComparePage
}: SystemMetricsEmptyMessageProps) => {
  const experimentText = isComparePage
    ? 'These experiments'
    : 'This experiment';
  return (
    <EmptyExperimentTab
      className={cx(classes.emptyMessage, {
        [classes.isComparePage]: isComparePage
      })}
    >
      <EmptyExperimentTab.Icon iconName="systemMetrics" />
      <EmptyExperimentTab.Title>
        {experimentText} did not report system information
      </EmptyExperimentTab.Title>
      <EmptyExperimentTab.Description>
        Enable system metrics logging on your Experiment object to log system
        metrics during experiments.
      </EmptyExperimentTab.Description>
      <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentinit" />
    </EmptyExperimentTab>
  );
};

export default SystemMetricsEmptyMessage;
