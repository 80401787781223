import { useRef, useState, useLayoutEffect } from 'react';

const useDimensions = dependencies => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({});

  useLayoutEffect(() => {
    if (!ref.current) return;
    setDimensions(ref.current.getBoundingClientRect().toJSON());
  }, [ref.current, ...dependencies]);

  return [ref, dimensions];
};

export default useDimensions;
