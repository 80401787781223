import React, { useMemo, useState } from 'react';
import cx from 'classnames';

import { BreadcrumbItemProps } from '@shared/types';
import BreadcrumbItem from '@platform/components/Breadcrumbs/components/BreadcrumbItem';

import typography from '@ds-typography';
import {
  APP_BREADCRUMBS_CONTAINER_ID,
  MIN_BREADCRUMBS_WIDTH
} from '@shared/constants/breadcrumbs';
import { getTextWidth } from '@shared/utils/displayHelpers';
import { useObserveResizeNode } from '@shared/hooks';
import { useSelector } from 'react-redux';
import { getBreadcrumbsItems } from '@/reducers/ui/workspaceUiReducer';

import styles from './Breadcrumbs.module.scss';

const DIVIDER = 16;

const ITEM_TYPE_ADDITIONAL_SPACE = {
  home: 36,
  link: 12,
  text: 12,
  experiment: 12,
  compare: 12,
  collapse: 16
};
const calculateBreadcrumbsWidth = (items: BreadcrumbItemProps[]) => {
  return items.reduce((acc, i) => acc + (i.width || 0), 0);
};

const calculateTextWidth = (text: string) => {
  return Math.ceil(
    getTextWidth(text, '400 14px / 20px "Roboto", system-ui, sans-serif', {
      letterSpacing: '0.28px'
    })
  );
};

const collapseItems = (
  containerWidth: number,
  items: BreadcrumbItemProps[]
): BreadcrumbItemProps[] => {
  const calculatedWidth = calculateBreadcrumbsWidth(items);

  if (calculatedWidth > containerWidth) {
    if (items[1].type !== 'collapse') {
      items.splice(1, 0, {
        type: 'collapse',
        title: '',
        width: ITEM_TYPE_ADDITIONAL_SPACE.collapse + DIVIDER,
        collapsed: []
      });
    }

    items[1].collapsed?.push(items.splice(2, 1)[0]);

    // minimum items with collapse is 3
    if (items.length <= 3) {
      return items;
    }

    return collapseItems(containerWidth, items);
  }

  return items;
};

const Breadcrumbs = () => {
  const items: BreadcrumbItemProps[] = useSelector(getBreadcrumbsItems);
  const container = document.getElementById(APP_BREADCRUMBS_CONTAINER_ID);
  const [containerWidth, setContainerWidth] = useState(
    container?.offsetWidth || 0
  );

  const processedItems: BreadcrumbItemProps[] = useMemo(() => {
    return items.map((item: BreadcrumbItemProps, index: number) => {
      const retVal = {
        ...item
      };

      // calculate width
      const divider = index === items.length - 1 ? 0 : DIVIDER;
      retVal.width =
        Math.min(MIN_BREADCRUMBS_WIDTH, calculateTextWidth(item.title)) +
        ITEM_TYPE_ADDITIONAL_SPACE[retVal.type] +
        divider;

      return retVal;
    });
  }, [items]);

  useObserveResizeNode(node => setContainerWidth(node.offsetWidth))(container);

  const breadcrumbsItems = useMemo(() => {
    // we are not collapsing first and last breadcrumb item
    if (processedItems.length <= 2) {
      return processedItems;
    }

    return collapseItems(containerWidth, processedItems.slice());
  }, [containerWidth, processedItems]);

  const key = breadcrumbsItems.map(i => i.title).join('-');

  return (
    <div key={key} className={cx(styles.container, typography.dsBody)}>
      {breadcrumbsItems.map((item, index) => {
        const { id } = item;
        const isFirst = index === 0;
        const isLast = index === breadcrumbsItems.length - 1;

        return (
          <BreadcrumbItem
            key={id}
            isLast={isLast}
            isFirst={isFirst}
            {...item}
          />
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
