import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import noop from 'lodash/noop';

import { IconButton, Tooltip } from '@ds';
import { CircularProgress } from '@material-ui/core';

import {
  DSCloseIcon,
  DSFullScreenIcon,
  DSLockIcon,
  DSMoreOptionsVerticalIcon,
  DSUnlockIcon
} from '@ds-icons';

import ChartHeaderMenu from './ChartHeaderMenu';
import styles from './ChartHeader.module.scss';

const ChartHeader = ({
  badges,
  chartId,
  chartType,
  customContent,
  deleteConfig,
  disableResetZoom,
  editConfig,
  experimentKeys,
  hiddenMenuItems,
  hideFullscreenBtn,
  instanceId,
  isBackgroundLoading,
  isCustomPanel,
  isFullscreen,
  legendMode,
  locked,
  onChangeSampleSize,
  onChangeLegendMode,
  onClearChartConstraints,
  onCollapseChart,
  onDeleteChart,
  onEditChart,
  onExpandChart,
  onExportData,
  onExportJSON,
  onLockClick,
  onOptionsClick,
  onResetZoom,
  panelMoveConfig,
  projectId,
  sampleSize,
  sampleSizes,
  sectionId,
  showLock,
  templateId,
  title,
  transformYConfig
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    onOptionsClick();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLockClick = useCallback(() => {
    onLockClick({
      chartId,
      value: !locked
    });
  }, [onLockClick, locked, chartId]);

  const handleDeleteChart = useCallback(() => {
    onDeleteChart(deleteConfig);
  }, [onDeleteChart, deleteConfig]);

  const handleEditChart = useCallback(() => {
    onEditChart(editConfig);
  }, [onEditChart, editConfig]);

  const renderButtons = () => {
    if (isFullscreen) {
      return (
        <IconButton
          Icon={<DSCloseIcon />}
          size="XL"
          type="secondary"
          className="close-fullscreen-btn"
          onClick={onCollapseChart}
        />
      );
    }

    return (
      <>
        {showLock && !locked && (
          <Tooltip content="Click to use panel settings" placement="top">
            <DSUnlockIcon
              onClick={handleLockClick}
              className={styles.lockIcon}
            />
          </Tooltip>
        )}
        {showLock && locked && (
          <Tooltip content="Click to use dashboard settings" placement="top">
            <DSLockIcon
              data-test="chart-locked"
              onClick={handleLockClick}
              className={cx(styles.locked, styles.lockIcon)}
            />
          </Tooltip>
        )}

        {!hideFullscreenBtn && (
          <Tooltip
            content="Click to expand panel to fullscreen"
            placement="top"
          >
            <DSFullScreenIcon onClick={onExpandChart} />
          </Tooltip>
        )}
        <Tooltip content="Options" placement="top">
          <DSMoreOptionsVerticalIcon
            data-test="chart-menu"
            onClick={handleOpen}
            className={cx(styles.menu, {
              [styles.opened]: Boolean(anchorEl)
            })}
          />
        </Tooltip>
      </>
    );
  };

  return (
    <div className="chart-header">
      {isBackgroundLoading && (
        <div className="chart-fetching-icon">
          <CircularProgress size={16} />
        </div>
      )}

      <div className="chart-title-extended">
        <div className="chart-title">
          <Tooltip content={title} wrapperDisplay="inline">
            {title}
          </Tooltip>
        </div>
        <div className="chart-badges">
          {badges.map(({ label }) => {
            return (
              <span key={label} className="chart-badge">
                {label}
              </span>
            );
          })}
        </div>
      </div>

      {customContent && (
        <div className="chart-custom-container">{customContent}</div>
      )}

      <div className="chart-actions">
        <div className={styles.buttonsContainer}>{renderButtons()}</div>
      </div>

      <ChartHeaderMenu
        anchorEl={anchorEl}
        instanceId={instanceId}
        panelMoveConfig={panelMoveConfig}
        projectId={projectId}
        templateId={templateId}
        chartId={chartId}
        title={title}
        chartType={chartType}
        sampleSize={sampleSize}
        sampleSizes={sampleSizes}
        sectionId={sectionId}
        isCustomPanel={isCustomPanel}
        legendMode={legendMode}
        experimentKeys={experimentKeys}
        hiddenMenuItems={hiddenMenuItems}
        disableResetZoom={disableResetZoom}
        onChangeLegendMode={onChangeLegendMode}
        onChangeSampleSize={onChangeSampleSize}
        onClose={handleClose}
        onClearChartConstraints={onClearChartConstraints}
        onDeleteChart={handleDeleteChart}
        onEditChart={handleEditChart}
        onExportJSON={onExportJSON}
        onResetZoom={onResetZoom}
        onExportData={onExportData}
        transformYConfig={transformYConfig}
      />
    </div>
  );
};

ChartHeader.defaultProps = {
  badges: [],
  chartId: '',
  chartType: '',
  customContent: null,
  deleteConfig: {},
  disableResetZoom: false,
  editConfig: {},
  experimentKeys: [],
  hiddenMenuItems: [],
  hideFullscreenBtn: false,
  instanceId: '',
  isBackgroundLoading: false,
  isCustomPanel: false,
  isFullscreen: false,
  legendMode: 'auto',
  locked: false,
  onChangeLegendMode: undefined,
  onChangeSampleSize: noop,
  onClearChartConstraints: noop,
  onCollapseChart: noop,
  onDeleteChart: noop,
  onEditChart: noop,
  onExpandChart: noop,
  onExportData: noop,
  onExportJSON: noop,
  onLockClick: noop,
  onOptionsClick: noop,
  onResetZoom: noop,
  projectId: '',
  sampleSize: null,
  sampleSizes: [],
  showLock: false,
  templateId: '',
  title: 'Chart'
};

ChartHeader.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.object),
  chartId: PropTypes.string,
  chartType: PropTypes.string,
  customContent: PropTypes.object,
  deleteConfig: PropTypes.object,
  disableResetZoom: PropTypes.bool,
  editConfig: PropTypes.object,
  experimentKeys: PropTypes.array,
  hiddenMenuItems: PropTypes.arrayOf(PropTypes.string),
  hideFullscreenBtn: PropTypes.bool,
  instanceId: PropTypes.string,
  isBackgroundLoading: PropTypes.bool,
  isCustomPanel: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  legendMode: PropTypes.string,
  locked: PropTypes.bool,
  onChangeLegendMode: PropTypes.func,
  onChangeSampleSize: PropTypes.func,
  onClearChartConstraints: PropTypes.func,
  onCollapseChart: PropTypes.func,
  onDeleteChart: PropTypes.func,
  onEditChart: PropTypes.func,
  onExpandChart: PropTypes.func,
  onExportData: PropTypes.func,
  onExportJSON: PropTypes.func,
  onLockClick: PropTypes.func,
  onOptionsClick: PropTypes.func,
  onResetZoom: PropTypes.func,
  panelMoveConfig: PropTypes.object,
  projectId: PropTypes.string,
  sampleSize: PropTypes.number,
  sampleSizes: PropTypes.array,
  sectionId: PropTypes.string,
  showLock: PropTypes.bool,
  templateId: PropTypes.string,
  title: PropTypes.string,
  transformYConfig: PropTypes.object
};

export default ChartHeader;
