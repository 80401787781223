import { forOwn, split, mapValues } from 'lodash';

export const KIND_UNIQ_SYMBOL = '%%%';
export const KIND_TYPE = {
  LAST: 'LAST',
  MIN: 'MIN',
  MAX: 'MAX'
};

/**
 Normalize /metadata-v2 response to /metadata structure
 * @param {object} metadataV2
 * @returns {object} metadataV1
 */

export const convertMetadataV1 = (
  metadataV2,
  preserveKeysIfUndefined = false
) => {
  const mappedObject = {};

  forOwn(metadataV2, (value, key) => {
    const extraValuesForValue = value?.values;

    if (extraValuesForValue || preserveKeysIfUndefined) {
      if (preserveKeysIfUndefined) {
        mappedObject[key] = '';
      }

      return extraValuesForValue.forEach(({ value, kind }) => {
        if (kind === KIND_TYPE.LAST) {
          mappedObject[key] = value;
          return;
        }

        const customKey = key + KIND_UNIQ_SYMBOL + kind;
        mappedObject[customKey] = value;
      });
    }
    mappedObject[key] = value;
  });

  return mappedObject;
};

export const normalizeColumnName = nameWithMaxMinPrefix => {
  const [keyName, kind] = split(nameWithMaxMinPrefix, KIND_UNIQ_SYMBOL);
  return { keyName, kind };
};

export const mixColumnNameWithKind = (keyName, kind) => {
  if (kind === KIND_TYPE.MIN || kind === KIND_TYPE.MAX) {
    return `${keyName}${KIND_UNIQ_SYMBOL}${kind}`;
  }
  return keyName;
};

export const formatColumnLabelWithKind = (label, kind) => {
  if (kind === KIND_TYPE.MIN || kind === KIND_TYPE.MAX) {
    return `${label} (${kind})`;
  }
  return label;
};

/*
 takes columns backend structure and for each KIND create
 a mapped object. this way we can add the min/max cols to our total columns
*/
export function getExtraCols(columns) {
  const extraOptions = [];
  columns.forEach(col =>
    col.kinds?.map(kind => {
      if (kind === KIND_TYPE.LAST) return null;

      return extraOptions.push(
        mapValues(col, (value, key) => {
          if (key === 'kinds') {
            return null;
          }

          if (key === 'id' || key === 'name') {
            return mixColumnNameWithKind(value, kind);
          }

          return value;
        })
      );
    })
  );

  return extraOptions;
}

export const getColumnName = columnName => {
  return columnName?.length ? columnName.split(KIND_UNIQ_SYMBOL)[0] : null;
};

export const getColumnType = columnName => {
  if (columnName.endsWith(`${KIND_UNIQ_SYMBOL}${KIND_TYPE.MIN}`)) {
    return KIND_TYPE.MIN;
  }

  if (columnName.endsWith(`${KIND_UNIQ_SYMBOL}${KIND_TYPE.MAX}`)) {
    return KIND_TYPE.MAX;
  }

  return KIND_TYPE.LAST;
};
