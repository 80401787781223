import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Tooltip from '@material-ui/core/Tooltip';

const ChartHeaderFullscreen = ({ badges, customClass, title }) => {
  return (
    <div className={cx('chart-header-fullscreen', customClass)}>
      <Tooltip title={title}>
        <div className="chart-title truncated-string">{title}</div>
      </Tooltip>

      <div className="chart-badges">
        {badges.map(({ label }) => {
          return (
            <span key={label} className="chart-badge">
              {label}
            </span>
          );
        })}
      </div>
    </div>
  );
};

ChartHeaderFullscreen.defaultProps = {
  badges: [],
  customClass: '',
  title: 'Chart'
};

ChartHeaderFullscreen.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.object),
  customClass: PropTypes.string,
  title: PropTypes.string
};

export default ChartHeaderFullscreen;
