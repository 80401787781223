import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const blue = '#5055f5';
const lightBlue = '#8eb6e4';

const StyledSwitch = withStyles({
  switchBase: {
    color: blue,
    '&$checked': {
      color: blue
    },
    '&$checked + $track': {
      backgroundColor: lightBlue
    }
  },
  checked: {},
  track: {}
})(Switch);

export default StyledSwitch;
