export const { ROOT_URL } = process.env;
export const BASE_API_URL = process.env.BASE_URL;
export const IS_ON_PREMISE = process.env.ON_PREMISE;
export const IS_ONLINE_MODE = !process.env.ON_PREMISE;
export const IS_LDAP = !!process.env.LDAP;
export const { DISABLE_SIGN_UP } = process.env;
export const SHOULD_SHOW_SIGN_UP_BUTTON =
  !process.env.DISABLE_SIGN_UP && !process.env.LDAP;
export const SHOULD_SHOW_FORGOT_PASSWORD = !process.env.LDAP;
export const SHOULD_SHOW_RESET_PASSWORD = !process.env.LDAP;
export const SHOULD_SHOW_BASIC_AUTH = !process.env.DISABLE_SIMPLE_AUTH;
export const SHOULD_SHOW_GITHUB_AUTH = !process.env.DISABLE_GITHUB_AUTH;
export const SHOULD_SHOW_SSO_AUTH = process.env.SHOW_SSO_AUTH;
export const SHOULD_SHOW_GOOGLE_AUTH = process.env.SHOW_GOOGLE_AUTH;
export const SHOULD_SHOW_OKTA_AUTH = process.env.SHOW_OKTA_AUTH;
export const SHOULD_SHOW_ONELOGIN_AUTH = process.env.SHOW_ONELOGIN_AUTH;
export const {
  BEAMER_ID,
  CONTACT_US_URL,
  DISABLE_INVITES,
  DISABLE_PUBLIC_ACCESS,
  DISABLE_STOP_BUTTON,
  HIDE_API_KEY,
  INTERCOM_ID,
  SHOULD_LOAD_ANALYTICS
} = process.env;
export const LOGIN_BUTTON_REDIRECT_URL = process.env.LOGIN_URL;
export const { DISABLE_SHARE } = process.env;
export const { DISABLE_LOGOUT } = process.env;

export const { LOGIN_REDIRECT_URL_GUEST_USER } = process.env;

export const IS_STAGING_ENV = process.env.ENV === 'staging';
export const IS_PRODUCTION_ENV = process.env.ENV === 'production';
export const IS_DEVELOPMENT_ENV = process.env.ENV === 'dev';

export const DOCS_ROOT_URL = '/docs/v2';
export const { VERSION } = global;

export const PY_PANELS_API_URL = '';
export const PY_PANELS_CDN_URL = IS_ONLINE_MODE && 'https://cdn.comet.com/';

export const { SENTRY_ENVIRONMENT } = process.env;

export const { HOMEPAGE_BANNER_TEXT } = process.env;
export const { HOMEPAGE_BANNER_LINK } = process.env;

export const { GOOGLE_ANALYTICS_ID } = global;

export const { JAVASCRIPT_SDK_VERSION } = process.env;
export const DEFAULT_DASHBOARD_SAMPLE_SIZE =
  Number(process.env.DEFAULT_DASHBOARD_SAMPLE_SIZE) || 100;
export const DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE =
  process.env.DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE || 1000;
export const SHOULD_SHOW_CREATE_WORKSPACE = !process.env
  .DISABLE_CREATE_WORKSPACE;

export const MAX_DEFAULT_LLM_COLUMNS_COUNT =
  process.env.MAX_LLM_COLUMNS_COUNT || 100;

export const PRE_FETCH_IMAGE_COUNT = process.env.PRE_FETCH_IMAGE_COUNT || 10;

export const IS_EMAIL_NEEDED = !IS_ON_PREMISE;

export const MAX_METADATA_SORTING_KEYS =
  process.env.MAX_METADATA_SORTING_KEYS || 500;
