import { sortableHandle } from 'react-sortable-hoc';
import { components } from 'react-select';
import React from 'react';
import { CrossIcon } from '@Icons-outdated';

const ClearIndicator = sortableHandle(props => (
  <components.ClearIndicator
    className="react-select-clear-indicator"
    {...props}
  >
    <CrossIcon />
  </components.ClearIndicator>
));

export default ClearIndicator;
