import React from 'react';
import cx from 'classnames';
import typography from '@ds-typography';

import styles from '../EmptyExperimentTab.module.scss';

const EmptyExperimentTabDescription = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cx(typography.dsBody, styles.emptyTabDescription, className)}
    {...props}
  />
));
EmptyExperimentTabDescription.displayName = 'EmptyExperimentTabDescription';

export default EmptyExperimentTabDescription;
