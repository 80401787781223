/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query';
import artifactsApi from '../../../util/artifactsApi';

const getLineage = async ({ workspace, artifactId }) => {
  const { data } = await artifactsApi.fetchArtifactLineage({
    workspace,
    artifactId
  });

  return data?.lineage;
};

export default function useLineage({ artifactId, workspace }, config) {
  return useQuery(
    ['lineage', { workspace, artifactId }],
    () => getLineage({ workspace, artifactId }),
    {
      ...config,
      enabled: !!workspace
    }
  );
}
