import organizationsActions from '@/actions/organizationsActions';
import workspaceActions from '@/actions/workspaceActions';
import { snackbarTypes } from '@/constants/alertTypes';
import { selectDefaultWorkspaceByOrganization } from '@/reducers/ui/workspaceUiReducer';
import alertsUtil from '@/util/alertsUtil';
import { useAllUserWorkspaces, useOrganizations } from '@shared/hooks';
import { Organization } from '@shared/types';
import { useDispatch } from 'react-redux';

const useOrganizationSelect = () => {
  const dispatch = useDispatch();
  const { data: organizations = [] } = useOrganizations();
  const { data: workspaces } = useAllUserWorkspaces();

  const onChangeOrganization = (organization: Organization) => {
    const defaultWorkspaceName = selectDefaultWorkspaceByOrganization(
      workspaces,
      organization?.id
    )?.name;

    if (defaultWorkspaceName) {
      dispatch(organizationsActions.setActiveOrganization(organization?.id));
      dispatch(workspaceActions.switchTeam(defaultWorkspaceName));
    } else {
      dispatch(
        alertsUtil.openErrorDialog(
          snackbarTypes.ERROR_SWITCH_ORGANIZATION,
          `You are not part of any workspaces in ${organization?.name}, please ask to be invited to one`
        )
      );
    }
  };

  return {
    organizationList: organizations,
    onChangeOrganization
  };
};

export default useOrganizationSelect;
