// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderTooltip-module__iconWrapper--cKB5D{width:fit-content;margin:0 0 3px 4px;color:var(--gray-4)}.RenderTooltip-module__tooltipIcon--P83nD{color:#b3b9c8}.RenderTooltip-module__tooltipIcon--P83nD:hover{color:#5e687e;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": `RenderTooltip-module__iconWrapper--cKB5D`,
	"tooltipIcon": `RenderTooltip-module__tooltipIcon--P83nD`
};
module.exports = ___CSS_LOADER_EXPORT___;
