import React from 'react';

import SmallLoader from '@shared/components/SmallLoader';

const GraphicsLoading = () => {
  return (
    <div className="empty-detail">
      <SmallLoader />
    </div>
  );
};

export default GraphicsLoading;
