import React from 'react';
import { MetricCellRendererData } from '../helpers';
import MetricsTableEditableActions from '../actions/MetricsTableEditableActions';
import metricCellStyles from './MetricCell.module.scss';
import { useMetricDataContext } from '../hooks/metricDataContext';
import { useSelector } from 'react-redux';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';

type MetricsTableActionCellProps = MetricCellRendererData;

const MetricsTableActionCell: React.FC<MetricsTableActionCellProps> = ({
  row
}) => {
  const { onEditMetric, onDeleteMetric } = useMetricDataContext();
  const canEdit = useSelector(isUserAllowedToEditProject);
  return (
    <div className={metricCellStyles.metricActionCell}>
      <div className={metricCellStyles.buttonWrapper}>
        <MetricsTableEditableActions
          onEdit={() => onEditMetric(row)}
          onDelete={() => onDeleteMetric(row.name)}
          disabled={!row.editable || !canEdit}
        />
      </div>
    </div>
  );
};

export default MetricsTableActionCell;
