import React from 'react';
import useHyperParametersDownload from './hooks/useHyperParametersDownload';
import DownloadParamsButton from '@shared/components/DownloadParamsButton/DownloadParamsButton';

type HyperParametersDownloadProps = {
  experimentName?: string;
};
const HyperParametersDownload: React.FC<HyperParametersDownloadProps> = ({
  experimentName = ''
}) => {
  const { onDownload, isDownloadButtonDisabled } = useHyperParametersDownload({
    experimentName
  });

  return (
    <DownloadParamsButton
      onDownload={onDownload}
      isDownloadButtonDisabled={isDownloadButtonDisabled}
    />
  );
};

export default HyperParametersDownload;
