import { useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';

import {
  TooltipData,
  TooltipPosition
} from '@experiment-management-shared/types';
import {
  BUILT_IN_CHART_TYPES,
  TOOLTIP_WAIT_TIMEOUT_FOR_RESET
} from '@experiment-management-shared/constants/chartConstants';
import useChartTooltipTrackEvent from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartTooltipTrackEvent';

export const useScatterChartTooltip = () => {
  const { interact, stop } = useChartTooltipTrackEvent();
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);

  const resetTooltip = useMemo(() => {
    return debounce(() => {
      stop();
      setTooltipData(null);
    }, TOOLTIP_WAIT_TIMEOUT_FOR_RESET);
  }, [stop]);

  const handlePointHover = useCallback(
    eventData => {
      resetTooltip.cancel();
      const point = eventData.points[0];
      if (!point || !point?.data?.cometMetadata) return;

      const { xaxis, yaxis, x, y } = point;
      const xCoord = xaxis.d2p(point.x) + xaxis._offset;
      const yCoord = yaxis.d2p(point.y) + yaxis._offset;
      const { color } = point.data.line;
      const position: TooltipPosition = { left: xCoord, top: yCoord };
      const pointSize = point.bbox.x1 - point.bbox.x0;

      interact(BUILT_IN_CHART_TYPES['BuiltIn/Scatter']);
      setTooltipData({
        x,
        y,
        color,
        pointSize,
        point,
        position
      });
    },
    [resetTooltip, interact]
  );

  const handlePointUnHover = useCallback(() => {
    resetTooltip();
  }, [resetTooltip]);

  return {
    handlePointHover,
    handlePointUnHover,
    tooltipData
  };
};
