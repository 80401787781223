import React, { useState } from 'react';
import { Popover, PopoverProps } from '@material-ui/core';
import { ListItem } from '@ds';
import UserMenuProfile from './components/UserMenuProfile/UserMenuProfile';
import UserMenuAppVersion from './components/UserMenuAppVersion/UserMenuAppVersion';
import {
  DSAdminIcon,
  DSChatIcon,
  DSDocumentationIcon,
  DSInviteMemberIcon,
  DSLogoutIcon,
  DSQuickstartGuideIcon,
  DSSettingsIcon,
  DSUsersIcon
} from '@ds-icons';

import styles from './UserMenu.module.scss';
import { Link } from 'react-router-dom';
import {
  useActiveWorkspace,
  useActiveWorkspaceName,
  useCurrentOrganization
} from '@shared/hooks';
import { QUICK_START_ROUTE } from '@shared/constants';
import { getToChatWithUsLink } from '@shared';
import loginActions from '@/actions/loginActions';
import { useDispatch } from 'react-redux';
import UserMenuWorkspaces from './components/UserMenuWorkspaces/UserMenuWorkspaces';
import UserMenuApiKeyMenuItem from './components/UserMenuApiKey/UserMenuApiKeyMenuItem';
import UserMenuOrganization from './components/UserMenuOrganization/UserMenuOrganization';
import workspaceActions from '@/actions/workspaceActions';
import useHiddenMenuItems from './hooks/useHiddenMenuItems';
import { UserMenuContext } from './userMenuContext';

type UserMenuProps = Omit<
  PopoverProps,
  'id' | 'anchorOrigin' | 'transformOrigin' | 'transitionDuration' | 'className'
> & {
  onClose: () => void;
};
const UserMenu: React.FC<UserMenuProps> = ({ ...menuProps }) => {
  const dispatch = useDispatch();

  const currentWorkspaceName = useActiveWorkspaceName();
  const currentWorkspace = useActiveWorkspace();
  const currentOrganization = useCurrentOrganization();
  const hiddenItemsMap = useHiddenMenuItems();
  const currentOrganizationId = currentOrganization?.id || '';
  const currentWorkspaceId = currentWorkspace?.id;

  const currentWorkspaceQuery = currentWorkspaceId
    ? `?initialInviteId=${currentWorkspaceId}`
    : '';

  const [mainMenuRef, setMainMenuRef] = useState<HTMLDivElement | null>(null);

  const chatWithUsURL = getToChatWithUsLink();

  const closeMainMenu = () => {
    menuProps.onClose();
  };

  const onManageUsers = () => {
    dispatch(
      workspaceActions.setManageWorkspaceModalState({
        open: true,
        workspaceId: '',
        workspaceName: ''
      })
    );
    closeMainMenu();
  };
  const onLogout = () => {
    dispatch(loginActions.logout());
    closeMainMenu();
  };

  return (
    <Popover
      id="user-menu"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        className: styles.userMenuContainer
      }}
      transitionDuration={0}
      elevation={0}
      {...menuProps}
    >
      <div ref={setMainMenuRef}>
        <UserMenuContext.Provider
          value={{
            closeMainMenu,
            mainMenuRef
          }}
        >
          <UserMenuProfile />
          <UserMenuWorkspaces />
          <div className={styles.divider} />
          {!hiddenItemsMap.accountSettingsHidden && (
            <Link to="/account-settings">
              <ListItem
                onClick={closeMainMenu}
                data-test="user-menu-account-settings-link"
              >
                <ListItem.PrefixContainer>
                  <ListItem.Icon Icon={<DSSettingsIcon />} />
                </ListItem.PrefixContainer>
                <ListItem.Text primary="Account settings" />
              </ListItem>
            </Link>
          )}

          {!hiddenItemsMap.adminDashboardHidden && (
            <Link to={`/organizations/${currentOrganizationId}`}>
              <ListItem
                onClick={closeMainMenu}
                data-test="user-menu-admin-dashboard-link"
              >
                <ListItem.PrefixContainer>
                  <ListItem.Icon Icon={<DSAdminIcon />} />
                </ListItem.PrefixContainer>
                <ListItem.Text primary="Admin dashboard" />
              </ListItem>
            </Link>
          )}
          {!hiddenItemsMap.apiKeyHidden && <UserMenuApiKeyMenuItem />}

          {!hiddenItemsMap.inviteMemberHidden && (
            <>
              <div className={styles.divider} />
              <Link to={`/account-settings/workspaces${currentWorkspaceQuery}`}>
                <ListItem
                  onClick={closeMainMenu}
                  data-test="user-menu-invite-member-link"
                >
                  <ListItem.PrefixContainer>
                    <ListItem.Icon Icon={<DSInviteMemberIcon />} />
                  </ListItem.PrefixContainer>
                  <ListItem.Text primary="Invite member" />
                </ListItem>
              </Link>
            </>
          )}

          {!hiddenItemsMap.manageUsersHidden && (
            <ListItem
              onClick={onManageUsers}
              data-test="user-menu-manage-users-link"
            >
              <ListItem.PrefixContainer>
                <ListItem.Icon Icon={<DSUsersIcon />} />
              </ListItem.PrefixContainer>
              <ListItem.Text primary="Manage users" />
            </ListItem>
          )}

          {!hiddenItemsMap.quickstartGuideHidden && (
            <>
              <div className={styles.divider} />
              <Link to={`/${currentWorkspaceName}/${QUICK_START_ROUTE}`}>
                <ListItem
                  onClick={closeMainMenu}
                  data-test="user-menu-quickstart-guide-link"
                >
                  <ListItem.PrefixContainer>
                    <ListItem.Icon Icon={<DSQuickstartGuideIcon />} />
                  </ListItem.PrefixContainer>
                  <ListItem.Text primary="Quickstart guide" />
                </ListItem>
              </Link>
              <Link to="/docs/v2" target="_blank" rel="noopener noreferrer">
                <ListItem
                  onClick={closeMainMenu}
                  data-test="user-menu-docs-link"
                >
                  <ListItem.PrefixContainer>
                    <ListItem.Icon Icon={<DSDocumentationIcon />} />
                  </ListItem.PrefixContainer>
                  <ListItem.Text primary="Docs" />
                </ListItem>
              </Link>
            </>
          )}

          <a target="_blank" href={chatWithUsURL} rel="noreferrer">
            <ListItem
              onClick={closeMainMenu}
              data-test="user-menu-chat-with-us-link"
            >
              <ListItem.PrefixContainer>
                <ListItem.Icon Icon={<DSChatIcon />} />
              </ListItem.PrefixContainer>
              <ListItem.Text primary="Chat with us" />
            </ListItem>
          </a>

          <div className={styles.divider} />
          <UserMenuOrganization />
          <ListItem onClick={onLogout} data-test="user-menu-logout-link">
            <ListItem.PrefixContainer>
              <ListItem.Icon Icon={<DSLogoutIcon />} />
            </ListItem.PrefixContainer>
            <ListItem.Text primary="Logout" />
          </ListItem>

          <UserMenuAppVersion />
        </UserMenuContext.Provider>
      </div>
    </Popover>
  );
};

export default UserMenu;
