// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageCard-module__imageCardContainer--tXXB1{border-radius:5px;box-shadow:0px 0px 8px rgba(0,0,0,.12);border:1px solid var(--section-border-color);height:100%;padding-bottom:12px;position:relative;display:flex;flex-direction:column;justify-content:space-between}.ImageCard-module__imageCardContainer--tXXB1 img{max-width:100%;border-top-right-radius:7px;border-top-left-radius:7px}.ImageCard-module__labelContainer--IBwky{padding:12px 20px 0 20px}.ImageCard-module__labelContainer--IBwky .ImageCard-module__title--gSe5o{color:var(--black);font-size:16px;line-height:24px;font-weight:500;margin-bottom:10px}.ImageCard-module__labelContainer--IBwky .ImageCard-module__description--x632D{color:var(--gray-6);line-height:21px}.ImageCard-module__linkContainer--M5kWX{display:flex;justify-content:flex-end;padding-right:18px}.ImageCard-module__linkContainer--M5kWX svg{position:relative}.ImageCard-module__linkContainer--M5kWX .ImageCard-module__imageCardLink--ZYwp_{text-align:right;color:var(--primary-color);margin-left:auto}.ImageCard-module__linkContainer--M5kWX .ImageCard-module__imageCardLink--ZYwp_ svg{margin-left:5px}.ImageCard-module__linkContainer--M5kWX:hover a{color:var(--primary-color-hover)}.ImageCard-module__linkContainer--M5kWX:hover svg{left:.1rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageCardContainer": `ImageCard-module__imageCardContainer--tXXB1`,
	"labelContainer": `ImageCard-module__labelContainer--IBwky`,
	"title": `ImageCard-module__title--gSe5o`,
	"description": `ImageCard-module__description--x632D`,
	"linkContainer": `ImageCard-module__linkContainer--M5kWX`,
	"imageCardLink": `ImageCard-module__imageCardLink--ZYwp_`
};
module.exports = ___CSS_LOADER_EXPORT___;
