import React, { HTMLProps } from 'react';
import cx from 'classnames';
import classes from './Radio.module.scss';
import { RadioButtonSizeType } from '@design-system/types';
import { useRadioContext } from '@ds/controllers/Radio/useRadioContext';
import omitBy from 'lodash/omitBy';
import { RADIO_PROP_NO_VALUE } from './constants';

export interface RadioButtonProps
  extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  size?: RadioButtonSizeType;
}

const RadioButton = ({ size = 'default', ...props }: RadioButtonProps) => {
  const { value: contextValue, name: contextName } = useRadioContext();

  const renderInput = () => {
    const contextProps = omitBy(
      {
        defaultChecked:
          contextValue === RADIO_PROP_NO_VALUE
            ? RADIO_PROP_NO_VALUE
            : contextValue === props.value,
        name: contextName
      },
      val => val === RADIO_PROP_NO_VALUE
    );

    return <input type="radio" {...contextProps} {...props} />;
  };

  return (
    <label className={cx(classes.radioButtonLabel, classes[size])}>
      {renderInput()}
      <span className={classes.radioButton} />
    </label>
  );
};

export default RadioButton;
