import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ds';

import ExperimentsFiltersSidebarContainer from '@experiment-management-shared/components/ExperimentFiltersSidebarContainer';
import { getRulesCount } from '@shared/utils/filterHelpers';
import { DSFilterIcon } from '@ds-icons';

const FiltersButton = ({ onChange, query }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const rulesCount = getRulesCount(query.rulesTree);
  const label = `Filters ${rulesCount ? `(${rulesCount})` : ''}`;

  return (
    <>
      <Button
        onClick={() => setIsFiltersOpen(true)}
        PrefixIcon={<DSFilterIcon />}
      >
        {label}
      </Button>
      <ExperimentsFiltersSidebarContainer
        isOpen={isFiltersOpen}
        onChange={onChange}
        onClose={() => setIsFiltersOpen(false)}
        query={query}
      />
    </>
  );
};

FiltersButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired
};

export default FiltersButton;
