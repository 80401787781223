import * as Sentry from '@sentry/browser';
import {
  IS_DEVELOPMENT_ENV,
  IS_ONLINE_MODE,
  SENTRY_ENVIRONMENT
} from '../constants/configConstants';

const initSentry = () => {
  if (IS_ONLINE_MODE && !IS_DEVELOPMENT_ENV) {
    Sentry.init({
      dsn: 'https://75bbdeb75bfb4501a70a30a057daf40a@sentry.io/1242988',
      ignoreErrors: [
        // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/2
        'Non-Error promise rejection captured',
        // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/2
        'Object Not Found Matching Id',
        // Source maps warning
        "Unexpected token '<'",
        // Warning: https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.'
      ],
      environment: SENTRY_ENVIRONMENT,
      maxValueLength: 2500,
      release: window.VERSION
    });
  }
};

export default initSentry;
