import React from 'react';
import { useExperimentsMetrics } from '@experiment-management-shared/api';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import { ExperimentDetails } from '@shared/types';
import NewComparisonTable from '../shared/NewComparisonTable/NewComparisonTable';
import { SmallLoader } from '@shared';
import MetricsTabEmpty from './MetricsTabEmpty';

type MetricsCompareTabProps = {
  experiments: ExperimentDetails[];
};
const MetricsCompareTab: React.FC<MetricsCompareTabProps> = ({
  experiments
}) => {
  const { data, isLoading } = useExperimentsMetrics({
    experiments
  });

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  return (
    <NewComparisonTable
      experiments={experiments}
      detailView={DETAIL_VIEWS.METRICS}
      experimentsData={data}
      isLoading={isLoading}
      valueKeys={['valueCurrent', 'valueMin', 'valueMax']}
      emptyView={MetricsTabEmpty}
    />
  );
};

export default MetricsCompareTab;
