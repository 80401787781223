import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import HistogramList from './HistogramList';

import styles from './HistogramTab.module.scss';

const MultipleHistogramList = ({
  assets,
  experiments,
  ignoreOutliers,
  maxSteps,
  handleDeleteHistogram
}) => {
  const lists = experiments.map(experiment => {
    const multiExperiments = experiments.length > 1;
    const { experimentKey } = experiment;
    const experimentAssets = assets.filter(
      asset => asset.experimentKey === experimentKey
    );

    return (
      <Grid
        className={styles.chartsWrapper}
        key={experimentKey}
        xs={12 / experiments.length}
        item
      >
        <HistogramList
          experiments={experiments}
          assets={experimentAssets}
          experimentKey={experimentKey}
          ignoreOutliers={ignoreOutliers}
          maxSteps={maxSteps}
          fullWidth={multiExperiments}
          handleDeleteHistogram={handleDeleteHistogram}
        />
      </Grid>
    );
  });

  return (
    <Grid
      classes={{ container: 'charts-container' }}
      direction="column"
      container
    >
      <Grid container>{lists}</Grid>
    </Grid>
  );
};

MultipleHistogramList.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  ignoreOutliers: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  handleDeleteHistogram: PropTypes.func.isRequired
};

export default MultipleHistogramList;
