// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageInfoMessage-module__pageInfoMessage--xdXxs{display:flex;align-items:center;margin-top:100px;flex-direction:column;font-size:20px;height:65px;justify-content:space-between}.PageInfoMessage-module__pageInfoMessageWrapper--Q7ExV{flex:1;min-height:580px;height:100%;padding-bottom:3rem;background-color:var(--gray-line)}@media screen and (min-height: 1000px){.PageInfoMessage-module__pageInfoMessageWrapper--Q7ExV{min-height:calc(100vh - 400px)}}.PageInfoMessage-module__pageInfoMessage--xdXxs svg{margin-right:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageInfoMessage": `PageInfoMessage-module__pageInfoMessage--xdXxs`,
	"pageInfoMessageWrapper": `PageInfoMessage-module__pageInfoMessageWrapper--Q7ExV`
};
module.exports = ___CSS_LOADER_EXPORT___;
