import useWorkspaceName from '@projects/hooks/useWorkspaceName';
import api from '@shared/api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { dialogTypes } from '../../../constants/alertTypes';
import alertsUtil from '../../../util/alertsUtil';
import { PROJECT_TYPE } from '@/constants/projectConstants';

const createProject = ({ description, isPublic, type, name, workspaceId }) => {
  return api.post('projects/new', {
    projectName: name,
    projectDesc: description,
    isPublic,
    type,
    teamId: workspaceId
  });
};

export default function useCreateProjectMutation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const workspaceName = useWorkspaceName();

  return useMutation(
    ({
      description,
      isPublic = true,
      type = PROJECT_TYPE.EM,
      name,
      workspaceId
    }) => {
      return createProject({ description, isPublic, type, name, workspaceId });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projects', { workspaceName }]);
      },
      onSuccess: async response => {
        await queryClient.invalidateQueries(['projects', { workspaceName }]);

        const { teamName, projectName } = response.data.data;
        const urlFirstSegment = encodeURI(teamName);
        const urlSecondSegment = encodeURI(projectName);

        history.push(`/${urlFirstSegment}/${urlSecondSegment}`);
      },
      onError: error => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_CREATE_PROJECT,
            error?.response?.data?.msg
          )
        );
      }
    }
  );
}
