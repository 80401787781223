import { useSelector } from 'react-redux';
import { getMetricCharts } from '@/reducers/experimentDetails/chartsReducer';
import { useMemo } from 'react';
import get from 'lodash/get';

const useExperimentDetailsTemplateWrapper = (experimentKey: string) => {
  const chartsPlotly = useSelector(getMetricCharts);

  return useMemo(() => get(chartsPlotly, experimentKey, chartsPlotly), [
    chartsPlotly,
    experimentKey
  ]);
};

export default useExperimentDetailsTemplateWrapper;
