import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { ifAllAssetsIsRemote } from '@model-registry/utils';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import {
  BETA_FEATURE_MODEL_APPROVAL_PROCESS,
  BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
} from '@/lib/betaFeatures';
import { getDownloadModelRegistryItemURL } from '@model-registry/utils/utils';
import {
  useCurrentOrganization,
  useIsUserMemberOfWorkspace,
  useActiveWorkspace
} from '@shared/hooks';

import { MANAGEMENT_PERMISSIONS_SERVER } from '@shared/constants/permissions';
import useUserPermission from '@shared/api/useUserPermission';
import { ListItem, Tooltip } from '@ds';
import {
  DSCopyIcon,
  DSDeleteIcon,
  DSDownloadIcon,
  DSMoveIcon,
  DSStatusIcon,
  DSTagIcon
} from '@ds-icons';

const ModelVersionsGridRowActionMenu = ({
  anchorEl,
  handleRowMenuClose,
  handleAddStageClick,
  handleManageStatusClick,
  hoveredRowData,
  handleCopyLinkClick,
  handleDeleteVersionClick,
  handleMoveVersionClick
}) => {
  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();

  const currentOrganization = useCurrentOrganization();
  const isAdmin = currentOrganization?.isAdmin;
  const activeWorkspace = useActiveWorkspace();
  const { getServerPermissionStatus } = useUserPermission();
  const ifCurrentUserWsOwner = getServerPermissionStatus({
    workspaceName: activeWorkspace?.name,
    permissionKey: MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
  });

  const isAdminOrWsOwner = isAdmin || ifCurrentUserWsOwner;
  const canChangeStatus = isUserAMemberOfWorkspace;

  const isRemoteModel = ifAllAssetsIsRemote(hoveredRowData?.rowData?.assets);
  const downloadUrl =
    !isRemoteModel && getDownloadModelRegistryItemURL(hoveredRowData?.rowData);

  const { autoGenerated } = hoveredRowData?.rowData || {};
  const isModelApprovalProcessEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_APPROVAL_PROCESS
    });
  });
  const isModelRegistryTagsFromStagesEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_REGISTRY_STAGES_TO_TAGS
    });
  });

  const disabledDeleteButton = !isUserAMemberOfWorkspace || !isAdminOrWsOwner;

  const deleteButton = (
    <ListItem
      disabled={disabledDeleteButton}
      onClick={() => {
        if (!disabledDeleteButton) {
          handleDeleteVersionClick();
        }
      }}
    >
      <ListItem.PrefixContainer>
        <ListItem.Icon Icon={<DSDeleteIcon />} />
      </ListItem.PrefixContainer>
      <ListItem.Text primary="Delete" />
    </ListItem>
  );
  const downloadButton = (
    <a className="model-version-download-action-menu-item" href={downloadUrl}>
      <ListItem disabled={autoGenerated || isRemoteModel}>
        <ListItem.PrefixContainer>
          <ListItem.Icon Icon={<DSDownloadIcon />} />
        </ListItem.PrefixContainer>
        <ListItem.Text primary="Download" />
      </ListItem>
    </a>
  );

  const disabledCopyDownloadLink = autoGenerated || isRemoteModel;
  const copyDownloadLink = (
    <CopyToClipboard text={downloadUrl}>
      <ListItem
        disabled={disabledCopyDownloadLink}
        onClick={() => {
          if (!disabledCopyDownloadLink) {
            handleCopyLinkClick();
            handleRowMenuClose();
          }
        }}
      >
        <ListItem.PrefixContainer>
          <ListItem.Icon Icon={<DSCopyIcon />} />
        </ListItem.PrefixContainer>
        <ListItem.Text primary="Copy download link" />
      </ListItem>
    </CopyToClipboard>
  );

  return (
    <Popover
      id="model-version-grid-row-action-menu"
      className="model-version-grid-row-action-menu"
      PaperProps={{
        style: {
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)'
        },
        'data-test': 'model-version-grid-row-action-menu'
      }}
      onClose={handleRowMenuClose}
      open={Boolean(anchorEl)}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: anchorEl?.y - 10 || 0,
        left: anchorEl?.x - 10 || 0
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: 'white',
          width: 185,
          py: '6px',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {isModelApprovalProcessEnabled && (
          <ListItem
            disabled={!canChangeStatus}
            onClick={handleManageStatusClick}
          >
            <ListItem.PrefixContainer>
              <ListItem.Icon Icon={<DSStatusIcon />} />
            </ListItem.PrefixContainer>
            <ListItem.Text primary="Change status" />
          </ListItem>
        )}
        <ListItem
          disabled={!isUserAMemberOfWorkspace}
          onClick={handleAddStageClick}
        >
          <ListItem.PrefixContainer>
            <ListItem.Icon Icon={<DSTagIcon />} />
          </ListItem.PrefixContainer>
          {/* SMELLY: Stages to tags migration on FE */}
          <ListItem.Text
            primary={`Manage ${
              isModelRegistryTagsFromStagesEnabled ? 'tags' : 'stage'
            }`}
          />
        </ListItem>
        {isRemoteModel ? (
          <Tooltip
            placement="left"
            content="You cannot directly download remote models"
          >
            <Box width="100%">{downloadButton}</Box>
          </Tooltip>
        ) : (
          downloadButton
        )}

        <ListItem
          disabled={!isUserAMemberOfWorkspace}
          onClick={handleMoveVersionClick}
        >
          <ListItem.PrefixContainer>
            <ListItem.Icon Icon={<DSMoveIcon />} />
          </ListItem.PrefixContainer>
          <ListItem.Text primary="Move" />
        </ListItem>

        {isRemoteModel ? (
          <Tooltip
            arrow
            placement="left"
            content="You cannot directly download remote models"
          >
            <Box width="100%">{copyDownloadLink}</Box>
          </Tooltip>
        ) : (
          copyDownloadLink
        )}

        {!isAdminOrWsOwner ? (
          <Tooltip
            arrow
            placement="left"
            content="Only admins or workspace owners can delete models"
          >
            <Box width="100%">{deleteButton}</Box>
          </Tooltip>
        ) : (
          deleteButton
        )}
      </Box>
    </Popover>
  );
};

ModelVersionsGridRowActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleRowMenuClose: PropTypes.func.isRequired,
  handleManageStatusClick: PropTypes.func.isRequired,
  handleAddStageClick: PropTypes.func.isRequired,
  hoveredRowData: PropTypes.object.isRequired,
  handleCopyLinkClick: PropTypes.func.isRequired,
  handleDeleteVersionClick: PropTypes.func.isRequired,
  handleMoveVersionClick: PropTypes.func.isRequired
};

ModelVersionsGridRowActionMenu.defaultProps = {
  anchorEl: null
};

export default ModelVersionsGridRowActionMenu;
