import { EmptyExperimentTab } from '@shared';
import React from 'react';
import classes from './HistogramTab.module.scss';

type HistogramTabEmptyProps = {
  isComparePage?: boolean;
};
const HistogramTabEmpty = ({ isComparePage }: HistogramTabEmptyProps) => {
  if (isComparePage) {
    return (
      <div className="full-height overflow-hidden">
        <div className="col-md-6 full-height">
          <EmptyExperimentTab>
            <EmptyExperimentTab.Title>No histograms</EmptyExperimentTab.Title>
          </EmptyExperimentTab>
        </div>

        <div className="col-md-6 full-height">
          <EmptyExperimentTab>
            <EmptyExperimentTab.Title>No histograms</EmptyExperimentTab.Title>
          </EmptyExperimentTab>
        </div>
      </div>
    );
  }

  return (
    <EmptyExperimentTab className={classes.emptyMessage}>
      <EmptyExperimentTab.Icon iconName="histograms" />
      <EmptyExperimentTab.Title>
        This experiment did not log any histograms
      </EmptyExperimentTab.Title>
      <EmptyExperimentTab.Description>
        To report a histogram during experiments use <b>log_histogram_3d()</b>{' '}
        on your Experiment object, or use a{' '}
        <EmptyExperimentTab.Link path="integrations/overview/">
          Comet integration
        </EmptyExperimentTab.Link>{' '}
        to do it automatically.
      </EmptyExperimentTab.Description>
      <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentlog_histogram_3d" />
    </EmptyExperimentTab>
  );
};

export default HistogramTabEmpty;
