// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoteCompareTab-module__compareNotesContainer--_kPSR{display:flex;flex-direction:column;gap:24px;padding-top:24px}.NoteCompareTab-module__compareNotesTitle--W89ny{margin-top:0;margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compareNotesContainer": `NoteCompareTab-module__compareNotesContainer--_kPSR`,
	"compareNotesTitle": `NoteCompareTab-module__compareNotesTitle--W89ny`
};
module.exports = ___CSS_LOADER_EXPORT___;
