import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { snackbarTypes } from '../../../constants/alertTypes';

const moveModelVersion = ({
  targetRegistryModelId,
  registryModelId,
  registryModelItemId,
  comment
}) =>
  modelRegistryApi.moveModelVersion({
    targetRegistryModelId,
    registryModelId,
    registryModelItemId,
    comment
  });

const useModelRegistryVersionMoveMutation = ({
  registryModelId,
  registryModelItemId
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ targetRegistryModelId, comment }) =>
      moveModelVersion({
        targetRegistryModelId,
        registryModelId,
        registryModelItemId,
        comment
      }),
    {
      onSuccess: () => {
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_SNACKBAR_DIALOG,
            'Model version was successfully moved to new model'
          )
        );

        queryClient.invalidateQueries(['modelRegistry']);
        queryClient.invalidateQueries(['modelRegistryById']);
      },
      onError: err => {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.FAILED_MODEL_VERSION_REASSIGNMENT,
            err?.response?.data?.msg ||
              'Model version was not moved due to some error'
          )
        );
      }
    }
  );
};

export default useModelRegistryVersionMoveMutation;
