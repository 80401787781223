import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CrossedEyeIcon, EyeIcon } from '@Icons-outdated';
import useCurrentUser from '@API/auth/useCurrentUser';
import { hideApiKeyPart } from '@shared/utils/apiKeys';
import { Input } from '@DesignSystem/controllers';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import styles from './APIKeyInput.module.scss';

const APIKeyInput = ({ CopyComponent, title, onClick }) => {
  const [isOpenKey, setIsOpenKey] = useState(false);
  const dispatch = useDispatch();

  const { data: user } = useCurrentUser();
  const handledKey = isOpenKey ? user?.apiKey : hideApiKeyPart(user?.apiKey);

  const handleIsOpenKey = () => setIsOpenKey(prevVal => !prevVal);

  const handleClick = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_API_KEY,
        'Successfully copied API key to clipboard'
      )
    );
    onClick?.();
  };
  return (
    <div className={styles.APIInputContainer}>
      <Input
        title={title}
        value={handledKey}
        lineHeight="21px"
        PostfixIcon={isOpenKey ? EyeIcon : CrossedEyeIcon}
        onPostfixIconClick={handleIsOpenKey}
        asButton
      />
      {CopyComponent && (
        <div onClick={handleClick}>
          <CopyToClipboard text={user?.apiKey}>
            {typeof CopyComponent === 'function'
              ? CopyComponent?.({ value: user?.apiKey })
              : CopyComponent}
          </CopyToClipboard>
        </div>
      )}
    </div>
  );
};

APIKeyInput.propTypes = {
  onClick: PropTypes.func,
  CopyComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string
};

APIKeyInput.defaultProps = {
  onClick: null,
  CopyComponent: null,
  title: ''
};

export default APIKeyInput;
