import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@ds';
import { Slider } from '@design-system-outdated/components';
import { Box, InputLabel } from '@material-ui/core';
import {
  AGGREGATION_TYPES_ARRAY,
  BUILT_IN_CHART_TYPES
} from '@experiment-management-shared/constants/chartConstants';
import { trackEvent } from '@shared/utils/eventTrack';
import dashboardHelpers from '@shared/utils/dashboardHelpers';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import { panelEvents } from '@/constants/trackingEventTypes';
import '../charts-tabs.scss';
import { NON_NUMERIC_AGGREGATIONS } from '@/lib/appConstants';
import useChartTabYValues from '@experiment-management-shared/hooks/useChartTabYValues';

const buildDescription = ({ aggregation, metricName, paramName }) => {
  const name = paramName || metricName;
  const isParam = !!paramName;
  const featureName = isParam ? 'param' : 'metric';

  return `The ${aggregation} value of ${name} ${featureName}`;
};

const ScalarChartDataTab = () => {
  const { search, pathname } = useLocation();
  const isParamsAllowed = dashboardHelpers.isOneExperimentAddBuiltInPanel({
    search,
    pathname
  });
  const yValues = useChartTabYValues({ type: BUILT_IN_CHART_TYPES.scalar });

  const scalarChartForm = useSelector(state =>
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.scalar })
  );

  const aggregationTypes = useMemo(
    () =>
      scalarChartForm.isNumber
        ? AGGREGATION_TYPES_ARRAY
        : AGGREGATION_TYPES_ARRAY.filter(val =>
            NON_NUMERIC_AGGREGATIONS.includes(val)
          ),
    [scalarChartForm.isNumber]
  );

  const aggregationOptions = useMemo(
    () =>
      aggregationTypes.map(type => ({
        value: type,
        label: type
      })),
    [aggregationTypes]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !scalarChartForm?.isCustomDescription &&
      (scalarChartForm?.paramName ||
        (scalarChartForm?.aggregation && scalarChartForm?.metricName))
    ) {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.scalar,
          'description',
          buildDescription({
            aggregation: scalarChartForm?.aggregation,
            metricName: scalarChartForm?.metricName,
            paramName: scalarChartForm?.paramName
          })
        )
      );
    }
  }, [
    scalarChartForm?.aggregation,
    scalarChartForm?.metricName,
    scalarChartForm?.paramName,
    scalarChartForm?.isCustomDescription,
    dispatch
  ]);

  const handleChangeValue = (value, option) => {
    const isMetrics = option.source === 'metrics';

    const isNumber = option.type === 'double';

    if (!isNumber) {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.scalar,
          'aggregation',
          'count'
        )
      );
    }

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'isNumber',
        isNumber
      )
    );

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'metricName',
        isMetrics ? value : ''
      )
    );

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'paramName',
        isMetrics ? '' : value
      )
    );
  };

  useEffect(() => {
    if (
      scalarChartForm?.paramName ||
      (scalarChartForm?.metricName && scalarChartForm?.aggregation)
    ) {
      trackEvent(panelEvents.ADD_BUILT_IN_SCALAR_PANEL_CLICK, {
        isSavePanel: false,
        isSingleExperiment: isParamsAllowed,
        paramName: scalarChartForm?.paramName,
        metricName: scalarChartForm?.metricName,
        aggregation: !scalarChartForm?.paramName
          ? scalarChartForm?.aggregation
          : ''
      });
    }
  }, [
    isParamsAllowed,
    scalarChartForm?.paramName,
    scalarChartForm?.metricName,
    scalarChartForm?.aggregation
  ]);

  const handleChangeAggregation = aggregation => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'aggregation',
        aggregation
      )
    );
  };

  const handleChangePrecision = precision => {
    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.scalar,
        'precision',
        precision
      )
    );
  };

  const renderValueField = () => (
    <div className="modal-input-group mb-60">
      <InputLabel className="modal-input-label">Value</InputLabel>

      <div style={{ width: '260px' }}>
        <Select
          onValueChange={handleChangeValue}
          maxWidth={260}
          truncateMiddle
          options={yValues}
          placeholder="Value"
          value={scalarChartForm?.metricName || scalarChartForm?.paramName}
          maxHeight={280}
          isClearable={false}
          isSearchable
          menuShouldBlockScroll
          isRegexSearchEnabled
        />
      </div>
    </div>
  );

  const renderAggregationField = () => (
    <div className="modal-input-group half">
      <InputLabel className="modal-input-label">Aggregation</InputLabel>

      <div style={{ width: '130px' }}>
        <Select
          maxWidth={130}
          truncateMiddle
          onValueChange={handleChangeAggregation}
          options={aggregationOptions}
          placeholder="Aggregation"
          value={scalarChartForm?.aggregation}
          isClearable={false}
          menuShouldBlockScroll
        />
      </div>
    </div>
  );

  const renderDecimalSlider = () => (
    <div className="modal-input-group" style={{ marginBottom: 60 }}>
      <InputLabel className="modal-input-label">Decimal Precision</InputLabel>
      <div className="slider-wrapper">
        <Slider
          disabled={!scalarChartForm?.isNumber}
          value={scalarChartForm?.precision}
          min={0}
          max={9}
          step={1}
          onChange={handleChangePrecision}
        />
      </div>
    </div>
  );

  return (
    <div className="scalar-chart-data-tab">
      <Box width="400px" display="flex" justifyContent="space-between">
        {renderValueField()}
        {renderAggregationField()}
      </Box>
      {renderDecimalSlider()}
    </div>
  );
};

ScalarChartDataTab.propTypes = {};

export default ScalarChartDataTab;
