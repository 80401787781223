import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import cx from 'classnames';

import DsSyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter';
import SmallLoader from '@shared/components/SmallLoader';
import classNames from './DebuggerPage.module.scss';
import DebuggerEvent from './DebuggerEvent';
import DebuggerEmptyDetailsPanel from './DebuggerEmptyDetailsPanel';
import { useDebuggerEvents } from '@mpm/api';
import { mergeDebuggerEvents } from '@mpm/utils';
import DebuggerEmptyEventsPanel from './DebuggerEmptyEventsPanel';
import {
  STREAM_ACTIONS,
  RESPONSE_DATA,
  EMPTY_DEBUGGER_EVENTS_ICONS,
  EMPTY_DEBUGGER_EVENTS_MESSAGES
} from '@mpm/constants';
import {
  ActiveDebuggerButtonType,
  ActiveStreamActionType,
  DebuggerEventType,
  DebuggerResponse
} from '../../types';

const DEBUGGER_REFETCH_INTERVAL = 5000;
const EVENTS_LIMIT = 500;

type DebuggerMainSectionProps = {
  activeButton: ActiveDebuggerButtonType;
  activeAction: ActiveStreamActionType;
};

type ResponseValue = DebuggerResponse[keyof DebuggerResponse];

const DebuggerMainSection = ({
  activeButton,
  activeAction
}: DebuggerMainSectionProps) => {
  const [
    activeDebugEvent,
    setActiveDebugEvent
  ] = useState<DebuggerEventType | null>(null);
  const [prevFetchedEvent, setPrevFetchedEvent] = useState(activeButton);
  const [streamingData, setStreamingData] = useState<ResponseValue | []>([]);

  const { modelId } = useParams<{ modelId: string }>();
  const queryClient = useQueryClient();

  const fetchInterval =
    activeAction === STREAM_ACTIONS.PLAY ? DEBUGGER_REFETCH_INTERVAL : false;

  const { data: debuggerData, isSuccess, isLoading } = useDebuggerEvents(
    {
      modelId,
      activeButton
    },
    {
      enabled: !!activeButton,
      refetchInterval: fetchInterval
    }
  );

  useEffect(() => {
    if (prevFetchedEvent !== activeButton) {
      setActiveDebugEvent(null);
      setStreamingData(debuggerData?.[RESPONSE_DATA[activeButton]] || []);
      queryClient.removeQueries('debugger-events');
    } else {
      setStreamingData(prevData => {
        return mergeDebuggerEvents(
          prevData,
          debuggerData?.[RESPONSE_DATA[activeButton]] || [],
          EVENTS_LIMIT
        );
      });
    }
    setPrevFetchedEvent(activeButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeButton, debuggerData]);

  return (
    <div className={classNames.debuggerMainSectionWrapper}>
      <div className={classNames.debuggerMainSectionEventsList}>
        {isLoading && <SmallLoader isFlexDisplay />}
        {isSuccess && !debuggerData?.[RESPONSE_DATA[activeButton]].length && (
          <DebuggerEmptyEventsPanel
            Icon={EMPTY_DEBUGGER_EVENTS_ICONS[activeButton]}
            message={EMPTY_DEBUGGER_EVENTS_MESSAGES[activeButton]}
          />
        )}
        {streamingData?.length > 0 &&
          streamingData.map(item => {
            const { id, timestamp } = item || {};
            return (
              <DebuggerEvent
                activeButton={activeButton}
                eventData={item}
                timestamp={timestamp}
                isActive={id === activeDebugEvent?.id}
                clickEventHandler={setActiveDebugEvent}
                key={id}
              />
            );
          })}
      </div>
      <div
        className={cx(classNames.debuggerMainSectionEventView, {
          [classNames.debuggerMainSectionEventViewActive]: activeDebugEvent
        })}
      >
        {activeDebugEvent && (
          <div data-test="debugger-event-details">
            <div className={classNames.debuggerEventViewHeader}>
              <DebuggerEvent
                activeButton={activeButton}
                eventData={activeDebugEvent}
                timestamp={activeDebugEvent?.timestamp}
              />
            </div>
            <DsSyntaxHighlighter
              language="json"
              style={vs}
              withCopyBtn
              code={JSON.stringify(activeDebugEvent, null, 2)}
            >
              {JSON.stringify(activeDebugEvent, null, 2)}
            </DsSyntaxHighlighter>
          </div>
        )}
        {!activeDebugEvent && <DebuggerEmptyDetailsPanel />}
      </div>
    </div>
  );
};

export default DebuggerMainSection;
