import { isString } from 'lodash';

// returns an obj if its type is not a string
// otherwise returns a parsed JSON
export const parseJSON = (obj, defaultValue = null) => {
  try {
    if (obj && !isString(obj)) return obj;

    return JSON.parse(obj);
  } catch (_) {
    return defaultValue;
  }
};

export const stringifyJSON = (str, defaultValue = null) => {
  try {
    return isString(str) ? str : JSON.stringify(str);
  } catch (_) {
    return defaultValue;
  }
};
