import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

const DELAYS_MS = 250;

const useDebouncedFilter = defaultFilterValue => {
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [debouncedFilterValue, setDebouncedFilterValue] = useState(
    defaultFilterValue
  );

  const debouncedFilterChange = useCallback(
    debounce(value => setDebouncedFilterValue(value), DELAYS_MS),
    []
  );

  useEffect(() => {
    debouncedFilterChange(filterValue);
  }, [filterValue, debouncedFilterChange]);

  return { debouncedFilterValue, setFilterValue, filterValue };
};

export default useDebouncedFilter;
