export const DELETE_SINGLE_RULE_MODAL_TITLE = 'Delete Alert Rule';

export const DELETE_SINGLE_RULE_MODAL_BODY_TEXT = (name: string) =>
  `The rule "${name}" will be deleted and will stop triggering alert notifications.`;

export const DELETE_MULTY_RULES_MODAL_TITLE = (totalRules: number) =>
  `Delete ${totalRules} Alert Rules.`;

export const DELETE_MULTY_RULES_MODAL_BODY_TEXT = (totalRules: number) =>
  `${totalRules} rules you selected will be deleted and will stop triggering alert notifications.`;
