import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress as CircularProgressMaterial } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { DSColors } from '@design-system-outdated/constants';

const StyledCircularProgress = withStyles({
  root: {
    height: props => props.size,
    width: props => props.size
  },
  circle: {
    color: props => props.color
  }
})(CircularProgressMaterial);

const CircularProgress = ({ color, size }) => {
  return <StyledCircularProgress color={color} size={size} />;
};

CircularProgress.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CircularProgress.defaultProps = {
  color: DSColors.whiteColor,
  size: '20px'
};

export default CircularProgress;
