import styles from '@projects/components/LLMDashboardHeader/LLMDashboardHeader.module.scss';
import { Select } from '@DesignSystem/controllers';
import LLMSearchInput from '@projects/components/LLMDashboardHeader/LLMSearchForm/LLMSearchInput/LLMSearchInput';
import { Button } from '@ds';
import { BUTTON_SIZES } from '@design-system-outdated/components/Button/Button';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { SOURCE } from '@shared/utils/filterHelpers';

export default function LLMSearchForm({
  onSubmit,
  queryColumn,
  llmColumns,
  handleQueryColumnChange,
  disabled,
  dynamicLLMNameMap,
  controlledQueryText
}) {
  const llmColumnsToOptions = useMemo(() => {
    return llmColumns.map(({ name }) => ({
      ...formatValueForSelectOption(
        name,
        SOURCE.LLM,
        null,
        null,
        null,
        dynamicLLMNameMap
      )
    }));
  }, [dynamicLLMNameMap]);

  return (
    <form
      autoComplete="off"
      className={styles.searchBarContainer}
      onSubmit={onSubmit}
    >
      <div data-test="llm-query-column-select" className={styles.selectWrapper}>
        <Select
          width="200px"
          withInput
          value={queryColumn}
          onChange={handleQueryColumnChange}
          options={llmColumnsToOptions}
          variant="outlined"
          truncateMiddle
        />
      </div>
      <LLMSearchInput controlledQueryText={controlledQueryText} />
      <Button
        data-test="llm-query-btn-submit"
        formType="submit"
        size={BUTTON_SIZES.large}
        disabled={disabled}
      >
        Search
      </Button>
    </form>
  );
}

PropTypes.defaultProps = {
  llmColumns: []
};

LLMSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  queryColumn: PropTypes.any.isRequired,
  handleQueryColumnChange: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  llmColumns: PropTypes.array,
  controlledQueryText: PropTypes.string.isRequired,
  dynamicLLMNameMap: PropTypes.object.isRequired
};
