export const SEARCH_PLACEHOLDER =
  'Search supports wildcards, use *value* to return all prompts that contain value';
export const QUERY_SEPARATOR = `*`;
export const OPERATORS_LIST = [
  {
    label: 'contains search',
    formattedValue: 'value',
    index: 0,
    value: ''
  },
  {
    label: 'wildcard search',
    formattedValue: `value_a${QUERY_SEPARATOR}value_b`,
    index: 0,
    value: QUERY_SEPARATOR
  },
  {
    label: 'exact search',
    formattedValue: `"value"`,
    index: 1,
    value: `""`
  }
];
