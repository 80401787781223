import React from 'react';

import { ModelDetailsType } from '@mpm-druid/types';
import { CometSQLSyntax } from './CometSQLSyntax';

const EXAMPLE_QUERY = `SELECT
  COUNT(*) FILTER (WHERE "agility" IS NOT NULL) /
  SUM("agility") FILTER (WHERE "agility" IS NOT NULL)
FROM MODEL`;

export const CometSQLSyntaxPage = ({ model }: { model: ModelDetailsType }) => {
  console.info('model', model);

  const { features, labels } = model || {};

  return (
    <CometSQLSyntax
      debug
      features={features}
      labels={labels}
      query={EXAMPLE_QUERY}
    />
  );
};
