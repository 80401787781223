import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ds';
import { BasicModal, DeleteModalBody } from '@DesignSystem/modals';
import { DSDeleteIcon } from '@ds-icons';

export const DeleteSelectionModalButton = ({
  modalTitle,
  bodyText,
  deleteHandler,
  className
}) => {
  const [inputValue, setInputValue] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const closeModalHandler = () => {
    setInputValue('');
    setOpenModal(false);
  };

  return (
    <>
      <BasicModal
        open={openModal}
        onClose={closeModalHandler}
        title={modalTitle}
        primaryButtonText="Confirm"
        onPrimaryButtonClick={deleteHandler}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeModalHandler}
        content={
          <DeleteModalBody
            description={bodyText}
            onChangeCallback={value => setInputValue(value)}
          />
        }
        isPrimaryButtonDisabled={inputValue !== 'DELETE'}
      />
      <Button
        PrefixIcon={<DSDeleteIcon />}
        size="large"
        className={className}
        onClick={() => setOpenModal(true)}
      >
        Delete
      </Button>
    </>
  );
};

DeleteSelectionModalButton.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  minWidth: PropTypes.string
};
