import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from './ThreeDotsMenu.module.scss';

const ThreeDotsMenuAnchor = ({
  setHoveredRowData,
  rowData,
  active,
  setAnchorEl,
  dataTest
}) => {
  const handleClick = ev => {
    setHoveredRowData(rowData);
    setAnchorEl({ x: ev.clientX, y: ev.clientY });
  };

  return (
    <Box
      data-test={dataTest}
      onClick={handleClick}
      className={cx(classNames.dsThreeDotsMenuAnchorIcon, {
        [classNames.dsThreeDotsMenuAnchorIconActive]: active
      })}
    >
      <MoreVertIcon fontSize="small" />
    </Box>
  );
};

ThreeDotsMenuAnchor.defaultProps = {
  dataTest: ''
};

ThreeDotsMenuAnchor.propTypes = {
  dataTest: PropTypes.string,
  setHoveredRowData: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  rowData: PropTypes.object.isRequired
};

export default ThreeDotsMenuAnchor;
