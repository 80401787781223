/**
Generates an array within the range [a, b] with N elements.
 * @param a - The x-coordinates at which to evaluate the interpolated values
 * @param b - The x-coordinates of the data points, must be increasing
 * @param n - The y-coordinates of the data points, same length as x.
 * @returns range
 */
export const generateEvenArray = (a: number, b: number, n: number) => {
  if (n <= 1) {
    return [a];
  }

  const step = (b - a) / (n - 1);
  const result = [];

  for (let i = 0; i < n; i++) {
    if (i === n - 1) {
      result.push(b);
    } else {
      result.push(a + i * step);
    }
  }

  return result;
};
