import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import classNames from 'classnames';

const CustomTableHeaderRow = props => (
  <TableHeaderRow.Row
    className={classNames(props.className, 'react-grid-header-row')}
    {...props}
  />
);

CustomTableHeaderRow.propTypes = {
  className: PropTypes.string
};

CustomTableHeaderRow.defaultProps = {
  className: undefined
};

export default CustomTableHeaderRow;
