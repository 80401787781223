import { createSelector } from 'reselect';

import { experimentDetailsUiActionTypes } from '@/constants/actionTypes';

const initialChartState = {
  isHeaderTableShown: true,
  isNeededToResetLocalChanges: false
};

const uiReducer = (state = initialChartState, action) => {
  const { type, payload } = action;

  if (type === experimentDetailsUiActionTypes.SET_IS_HEADER_TABLE_SHOWN) {
    const { status } = payload;

    return {
      ...state,
      isHeaderTableShown: status
    };
  }

  if (
    type === experimentDetailsUiActionTypes.SET_IS_NEEDED_TO_RESET_LOCAL_CHANGES
  ) {
    const { status } = payload;
    return {
      ...state,
      isNeededToResetLocalChanges: status
    };
  }

  return state;
};

export default uiReducer;

export const getUIExperimentDetails = state => {
  return state.runDetails.ui;
};

export const getIsHeaderTableShown = createSelector(
  [getUIExperimentDetails],
  ui => ui?.isHeaderTableShown
);

export const getIsNeededToResetLocalChanges = createSelector(
  [getUIExperimentDetails],
  ui => ui?.isNeededToResetLocalChanges
);
