import get from 'lodash/get';
import {
  MODEL_REGISTRY_WEBHOOKS_COLUMNS,
  WEBHOOK_AUTHORIZATION_HEADER_KEY
} from '@model-registry/constants/webhooks';
import moment from 'moment';

export const isValidHttpUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getFieldValue = (fieldName, data) => get(data, fieldName);

export const convertObjectHeadersToArray = obj => {
  const arr = [];

  if (obj) {
    Object.keys(obj).forEach(key => {
      arr.push({
        key,
        value: obj[key]
      });
    });
  }

  return arr;
};

export const convertArrayHeadersToObject = arr => {
  const obj = {};
  if (arr?.length) {
    arr.forEach(item => {
      if (item.key && item.value) {
        obj[item.key] = item.value;
      }
    });
  }

  return obj;
};

export const convertBEWebhookToFEWebhook = item => {
  const data = {
    ...item
  };

  // and then do as always
  const headers = {
    ...(data[MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers] || {})
  };
  const authKeyHeader = headers[WEBHOOK_AUTHORIZATION_HEADER_KEY];
  delete headers[WEBHOOK_AUTHORIZATION_HEADER_KEY];

  return {
    ...data,
    [MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers]: [
      ...convertObjectHeadersToArray(headers)
    ],
    [MODEL_REGISTRY_WEBHOOKS_COLUMNS.authKey]: authKeyHeader,
    [MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdAt]:
      data.createdAt && moment(data.createdAt).format('D/MM/YYYY')
  };
};

export const convertWebhookDataToCometPayload = (
  { workspaceName, modelName },
  item = {}
) => {
  const dataToSave = {
    ...item
  };

  delete dataToSave[MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdAt];
  delete dataToSave[MODEL_REGISTRY_WEBHOOKS_COLUMNS.createdBy];
  const headers = {
    [WEBHOOK_AUTHORIZATION_HEADER_KEY]:
      dataToSave[MODEL_REGISTRY_WEBHOOKS_COLUMNS.authKey],
    ...convertArrayHeadersToObject(
      dataToSave[MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers]
    )
  };
  dataToSave[MODEL_REGISTRY_WEBHOOKS_COLUMNS.headers] = headers;

  return {
    urlConfig: {
      ...dataToSave
    },
    workspaceName,
    modelName
  };
};

export const checkDomain = (domain, url) => {
  try {
    const { hostname } = new URL(url);
    const urlDomain = hostname.split('.').slice(-2).join('.');

    return urlDomain === domain;
  } catch (_) {
    return false;
  }
};
