import {
  mixColumnNameWithKind,
  normalizeColumnName
} from '@API/helpers/v2_helpers';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import {
  OPERATOR_TYPE,
  RULE_TYPE,
  TAG_COLUMN_NAME
} from '@shared/utils/filterHelpers';
import { METADATA_VIRTUAL_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import { NAME_KEY } from '@experiment-management-shared/constants/experimentConstants';

export const castValue = (strValue, type) => {
  if (strValue === undefined) return undefined;
  if (strValue.toLowerCase() === 'undefined') return undefined;
  if (type === 'boolean') return strValue === 'true';
  if (['double', 'timenumber'].includes(type)) return Number(strValue);
  return strValue;
};

export const isMetricNumerical = metric => metric?.type === 'double';

export const uniqColumns = columns => {
  // sorting here applied to maintain the same order of columns that should improve caching
  return sortBy(
    uniqBy(columns, column =>
      column.kind
        ? mixColumnNameWithKind(column.keyName, column.kind)
        : column.keyName
    ),
    'keyName'
  );
};

export const processColumnForRequest = column => {
  if (!column) return null;
  if (['message_time'].includes(column.source)) return null;
  if (METADATA_VIRTUAL_COLUMNS.includes(column.keyName)) return null;

  if (column.name === 'experimentTags') {
    return {
      keyName: TAG_COLUMN_NAME,
      source: TAG_COLUMN_NAME
    };
  }

  const { keyName, kind } = normalizeColumnName(column.name);

  return {
    keyName,
    source: column.source,
    ...(kind && { kind })
  };
};

export const generateToColumn = columns => columnName => {
  let column;
  if (columnName === 'experimentTags') {
    column = { name: columnName };
  } else {
    column = columns?.find(col => col.name === columnName);
  }

  return processColumnForRequest(column);
};

export const getPanelsColumns = panels => {
  return panels
    .reduce((acc, panel) => {
      if (isArray(panel.selectedLegendKeys)) {
        panel.selectedLegendKeys
          .filter(option => option.value !== NAME_KEY)
          .forEach(option => {
            acc.push(
              processColumnForRequest({
                source: option.source,
                name: option.value
              })
            );
          });
      }

      return acc;
    }, [])
    .filter(column => Boolean(column));
};

export const toMultipleSort = (sorting, toColumn) => {
  return sorting
    .filter(
      ({ columnName }) =>
        !['isVisibleOnDashboard', 'pinned'].includes(columnName)
    )
    .map(({ columnName, direction }) => ({
      order: direction,
      ...toColumn(columnName)
    }))
    .filter(column => column.keyName);
};

export const generateToRule = columns => ({ columnName, kind, value }) => {
  const column = columns?.find(col => col.name === columnName) || {};
  const { name, type, source } = column;
  const isTags = columnName === 'experimentTags';

  const retVal = {
    field: name,
    type,
    operator: OPERATOR_TYPE.EQUAL,
    ...(kind && { kind }),
    source,
    value
  };

  if (isTags) {
    retVal.field = TAG_COLUMN_NAME;
    retVal.source = TAG_COLUMN_NAME;

    // handle undefined value case for tags
    if (value === undefined || value === null) {
      retVal.operator = OPERATOR_TYPE.NOT_HAS_TAG;
      delete retVal.value;
    }
  } else {
    // handle undefined value case for other types (all types)
    if (value === undefined || value === null) {
      retVal.operator = OPERATOR_TYPE.IS_NULL;
      delete retVal.value;
    }

    // handle empty string value case for other types (strings)
    if (type === RULE_TYPE.STRING && value === '') {
      retVal.operator = OPERATOR_TYPE.IS_EMPTY;
      delete retVal.value;
    }
  }

  return retVal;
};

export const getSearchRules = value => {
  if (value) {
    return [
      {
        field: 'Name',
        operator: 'regexp',
        source: 'log_other',
        type: 'string',
        value
      }
    ];
  }
  return [];
};
