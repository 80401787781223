import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import queryString from 'query-string';

import useProjectParams from '@API/project/useProjectParams';
import { snackbarTypes } from '@/constants/alertTypes';
import { getIsArchivePage } from '@/selectors/routeSelectors';
import alertUtils from '@/util/alertsUtil';

import RedirectLink from '@shared/components/RedirectLink';
import { REDIRECT_LINK_RESOURCES } from '@shared/constants';
import { getExperimentUrl } from '@shared/utils/url';
import { DSCopyIcon } from '@ds-icons';
import { IconButton } from '@ds';

const ExperimentKey = ({ openInNewTab, row = {} }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isArchive = useSelector(getIsArchivePage);
  const { projectName, workspace } = useProjectParams();

  const { experimentKey = '' } = row || row?.rowData || {};

  if (!experimentKey) return <div className="experiment-key-cell" />;

  const shortExperimentKey = experimentKey.substr(0, 9);
  const experimentUrl = getExperimentUrl({
    isArchive,
    projectName,
    experimentKey: row?.experimentKey,
    workspace
  });
  const experimentTab = queryString.parse(location.search)['experiment-tab'];
  const experimentTabSearch = experimentTab
    ? `?experiment-tab=${experimentTab}`
    : '';

  function handleSuccessNotification() {
    dispatch(
      alertUtils.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_API_KEY,
        'Successfully copied experiment key to clipboard'
      )
    );
  }

  function renderExperimentKeyText() {
    if (!workspace || !projectName || !experimentKey) {
      return (
        <span className="experiment-key-cell-non-link">
          {shortExperimentKey}
        </span>
      );
    }

    const btn = (
      <button type="button">
        <span className="short-experiment-key">{shortExperimentKey}</span>
        <span className="full-experiment-key">
          <span id={`full-experiment-key-${shortExperimentKey}`}>
            {experimentKey}
          </span>
        </span>
      </button>
    );

    return openInNewTab ? (
      <RedirectLink
        resource={REDIRECT_LINK_RESOURCES.EXPERIMENT}
        args={[row.experimentKey]}
      >
        {btn}
      </RedirectLink>
    ) : (
      <Link to={`${experimentUrl}${experimentTabSearch}`}>{btn}</Link>
    );
  }

  return (
    <div className="experiment-key-cell">
      {renderExperimentKeyText()}

      <CopyToClipboard text={experimentKey}>
        <div className="copy-experiment-key">
          <IconButton
            type="secondary"
            Icon={<DSCopyIcon />}
            size="small"
            onClick={handleSuccessNotification}
          />
        </div>
      </CopyToClipboard>
    </div>
  );
};

ExperimentKey.defaultProps = {
  openInNewTab: false
};

ExperimentKey.propTypes = {
  openInNewTab: PropTypes.bool,
  row: PropTypes.object.isRequired
};

export default ExperimentKey;
