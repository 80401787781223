import { useQuery } from 'react-query';
import { convertBEWebhookToFEWebhook } from '@model-registry/utils/webhooks';
import modelRegistryApi from '../../../util/modelRegistryApi';

const getModelWebhooksList = async ({ workspaceName, modelName }) => {
  const { data = [] } =
    (await modelRegistryApi.fetchWebhooksList({
      workspaceName,
      modelName
    })) || {};

  return {
    webhooksList: data?.map(convertBEWebhookToFEWebhook)
  };
};

const useModelRegistryWebhooks = ({ workspaceName, modelName }, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['modelRegistry', { workspaceName, modelName }, 'webhooks'],
    () =>
      getModelWebhooksList({
        workspaceName,
        modelName
      }),
    {
      refetchOnMount: true,
      ...config,
      enabled: !!modelName && !!workspaceName && configEnabled
    }
  );
};

export default useModelRegistryWebhooks;
