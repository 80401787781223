import Badge from '@material-ui/core/Badge';
import MuiButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router';
import { Route } from 'react-router-dom';
import { trackEvent } from '@shared/utils/eventTrack';

import { Button, IconButton } from '@ds';
import { HelpHeaderButton } from '@onboarding';
import { getTrialInEndsLabel } from '@/helpers/organizationHelpers';
import alertsActions from '@/actions/alertsActions';
import forgotPasswordActions from '@/actions/forgotPasswordActions';
import loginActions from '@/actions/loginActions';
import { dialogTypes } from '@/constants/alertTypes';
import { viewEvents } from '@/constants/trackingEventTypes';
import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';
import { APP_BREADCRUMBS_CONTAINER_ID } from '@shared/constants/breadcrumbs';
import {
  BASE_API_URL,
  IS_ONLINE_MODE,
  LOGIN_BUTTON_REDIRECT_URL,
  SHOULD_SHOW_FORGOT_PASSWORD,
  SHOULD_SHOW_SIGN_UP_BUTTON,
  TOOLTIP_TRIAL_EXPIRED,
  TOOLTIP_TRIAL_REMAINING,
  URLS
} from '@/lib/appConstants';
import { ROUTE_ROOT_PATH } from '@/lib/routeConstants';
import {
  getIsBeta,
  getIsUserLoggedIn,
  getShowPostSignupForm,
  getUsername
} from '@/reducers/userReducer';
import {
  useCurrentOrganization,
  useCurrentPaymentPlan,
  useDefaultWorkspace
} from '@shared/hooks';
import ForgotPasswordModal from '@auth/components/ForgotPasswordModal';
import HomepageBanner from './HomepageBanner';
import UserAvatar from './UserAvatar';
import TextLogo from './TextLogo';
import TryCometBtn from '@auth/components/TryCometBtn';
import Breadcrumbs from '@platform/components/Breadcrumbs';
import { DSNewIcon } from '@ds-icons';
import { UserMenu } from '@platform/components/UserMenu';

const useBeamer = () => {
  const [showUpdate, setShowUpdate] = useState();

  useEffect(() => {
    const handleBeamer = e => setShowUpdate(e.detail.eventCount > 0);
    window.addEventListener('beamerUpdate', handleBeamer);

    return () => {
      window.removeEventListener('beamerUpdate', handleBeamer);
    };
  }, []);

  return showUpdate;
};

export const HeaderNavBarComponent = ({
  dispatch,
  isBeta,
  isUserLoggedIn,
  showPostSignupForm
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    resetToken: forgotPasswordToken,
    openlogin,
    opensignup,
    signuptitle: signupTitle
  } = queryString.parse(window.location.search);
  const isClosedPath = pathname === '/closed';
  const isHomepage = pathname === '/' || isClosedPath || forgotPasswordToken;
  const defaultWorkspace = useDefaultWorkspace();

  const isAlertsPage = !!matchPath(pathname, {
    path: '/:user/model-production-monitoring/:id/alerts',
    exact: false
  });

  const isAccountSettingsPage = !!matchPath(pathname, {
    path: '/account-settings',
    exact: false
  });

  const isAdminSettingsPage = !!matchPath(pathname, {
    path: '/organizations',
    exact: false
  });

  const isModelRegistryPage = !!matchPath(pathname, {
    path: '/:workspace/model-registry',
    exact: false
  });

  const {
    timestampWhenTrialEnds,
    isTrial,
    isAdmin,
    isSuspended,
    isFreePlan,
    isFree
  } = useCurrentPaymentPlan();

  const [anchorElement, setAnchorElement] = useState(null);
  const [openForgotPasswordOnce, setOpenForgotPasswordOnce] = useState(true);
  const beamerAlert = useBeamer();
  const currentOrganization = useCurrentOrganization();

  useEffect(() => {
    if (forgotPasswordToken) {
      dispatch(forgotPasswordActions.setToken(forgotPasswordToken));
    }

    const { resetToken, ...restQueryParams } = queryString.parse(
      window.location.search
    );

    if (!resetToken) return;

    const newQueryString = queryString.stringify(restQueryParams);

    history.push({
      search: newQueryString
    });
  }, [dispatch, forgotPasswordToken, history]);

  useEffect(() => {
    if (openlogin && !isUserLoggedIn) {
      dispatch(loginActions.openLoginModal());
    }

    if (opensignup && !isUserLoggedIn) {
      dispatch(
        loginActions.openRegistrationModal({
          title: signupTitle
        })
      );
    }
  }, [
    dispatch,
    isUserLoggedIn,
    openlogin,
    opensignup,
    showPostSignupForm,
    signupTitle
  ]);

  const handleMenuButtonClick = event => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleLoginClick = () => {
    if (LOGIN_BUTTON_REDIRECT_URL) {
      window.location.href = `${BASE_API_URL}${LOGIN_BUTTON_REDIRECT_URL}`;
    } else {
      dispatch(loginActions.openLoginModal());
    }
  };

  const handleBeamerClick = () => {
    trackEvent(viewEvents.VIEWED_WHATS_NEW_FE);
  };

  function renderPlanBtn() {
    return (
      <Button
        className="upgrade-button"
        onClick={() =>
          dispatch(push(`/organizations/${currentOrganization?.id}/billing`))
        }
      >
        Purchase Plan
      </Button>
    );
  }

  const goToRoot = () => {
    if (isUserLoggedIn) {
      if (defaultWorkspace?.name) {
        dispatch(
          push(`/${defaultWorkspace?.name}${WORKSPACE_URL_HASHES.PROJECTS}`)
        );
      }

      return;
    }

    window.location.href = ROUTE_ROOT_PATH;
  };

  const openForgotPasswordModal = () => {
    if (openForgotPasswordOnce) {
      setOpenForgotPasswordOnce(false);
    }

    dispatch(
      alertsActions.openDialog(dialogTypes.FORGOT_PASSWORD, {
        children: <ForgotPasswordModal />
      })
    );
  };

  const loggedOutNav = (
    <div className="right-nav right-nav-logged-out">
      <span className="hide-for-mobile-nav">
        <Route
          render={() =>
            IS_ONLINE_MODE && (
              <a
                className="button login-nav-btn"
                id="pricing-link"
                href={URLS.PRICING}
              >
                Pricing
              </a>
            )
          }
        />
        <MuiButton
          className={cx('button login-nav-btn', {
            dot: IS_ONLINE_MODE
          })}
          onClick={handleLoginClick}
          id="login-link"
          data-test="login-btn"
        >
          Log In
        </MuiButton>
      </span>
      {SHOULD_SHOW_SIGN_UP_BUTTON && <TryCometBtn className="signup-nav-btn" />}
    </div>
  );

  const isBannerVisible =
    IS_ONLINE_MODE &&
    !isEmpty(currentOrganization) &&
    (isTrial || isFreePlan || isFree);

  const renderExpireState = () => {
    if (isSuspended) {
      return (
        <Tooltip
          id="tooltip-icon"
          title={TOOLTIP_TRIAL_EXPIRED}
          placement="top"
        >
          <span>Expired</span>
        </Tooltip>
      );
    }

    if (isTrial) {
      return (
        <Tooltip
          id="tooltip-icon"
          title={TOOLTIP_TRIAL_REMAINING}
          placement="top"
        >
          <span>{getTrialInEndsLabel(timestampWhenTrialEnds)}</span>
        </Tooltip>
      );
    }

    return null;
  };

  const isCTAVisible = isAdmin && !isFree;
  const loggedInNav = (
    <>
      <div className="right-nav">
        <Grid container spacing={0}>
          {isBannerVisible && (
            <Grid item>
              <Grid container spacing={0} className="nav-plan-state">
                {!isFree && (
                  <Grid item className="expired-state">
                    {renderExpireState()}
                  </Grid>
                )}
                {isCTAVisible && (
                  <Grid item>{!isFreePlan && renderPlanBtn()}</Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item className="header-button-wrapper help-button">
            <HelpHeaderButton />
          </Grid>

          {IS_ONLINE_MODE && (
            <Grid
              item
              id="togglebeamer"
              className="header-button-wrapper beamer-button"
            >
              <IconButton
                className={cx('header-button', {
                  'beamer-alert': beamerAlert
                })}
                type="secondary"
                Icon={<DSNewIcon />}
                onClick={handleBeamerClick}
              />
            </Grid>
          )}
          <Grid
            item
            className={cx('login-menu', {
              'is-opened': Boolean(anchorElement)
            })}
            data-test="login-menu"
            onClick={handleMenuButtonClick}
          >
            <UserAvatar width={32} />
          </Grid>
        </Grid>
      </div>
      <UserMenu
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleMenuClose}
      />
    </>
  );

  return (
    <header
      className={cx({
        'is-homepage': isHomepage,
        'is-sticky ':
          isAlertsPage ||
          isAccountSettingsPage ||
          isAdminSettingsPage ||
          isModelRegistryPage,
        'on-top': isSuspended
      })}
    >
      {isUserLoggedIn || isHomepage ? null : <HomepageBanner />}
      <div className="app-bar">
        <nav>
          <div className="left-nav">
            <Route
              render={() => {
                if (isBeta) {
                  return (
                    <Badge
                      className="beta-badge"
                      badgeContent="beta"
                      color="primary"
                    >
                      <TextLogo onClick={goToRoot} />
                    </Badge>
                  );
                }
                return <TextLogo onClick={goToRoot} />;
              }}
            />
            <div
              className="breadcrumbs-container"
              id={APP_BREADCRUMBS_CONTAINER_ID}
            >
              <Breadcrumbs />
            </div>
          </div>
          {isUserLoggedIn ? loggedInNav : loggedOutNav}
        </nav>
      </div>
      {SHOULD_SHOW_FORGOT_PASSWORD &&
        forgotPasswordToken &&
        openForgotPasswordOnce &&
        openForgotPasswordModal()}
    </header>
  );
};

HeaderNavBarComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isBeta: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  showPostSignupForm: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isBeta: getIsBeta(state),
  isUserLoggedIn: getIsUserLoggedIn(state),
  showPostSignupForm: getShowPostSignupForm(state),
  username: getUsername(state)
});

export default connect(mapStateToProps)(HeaderNavBarComponent);
