import React, { forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useParams } from 'react-router';
import { isEqual } from 'lodash';

import DashboardTable from '@projects/components/DashboardTable';
import EmptySectionMessage from '@projects/components/EmptySectionMessage';
import {
  getNameColumnWidth,
  hasDashboardAnyGrouping,
  hasDashboardAnyRule,
  hasDashboardTableSearch
} from '@experiment-management-shared/utils/EMView';
import { PanelsIcon } from '@shared/utils/svgIcons';
import SmallLoader from '@shared/components/SmallLoader';
import { useExperimentEmptyStateConfigs } from '@projects/hooks';

const GRID_CONFIG = {
  resizingEnabled: true
};

const SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN = 37;
const ExperimentsPage = (
  {
    availableColumns,
    activeExperimentsHeaderTab,
    dashboard,
    displayGroups,
    experimentsGroupsMap,
    groupAggregations,
    experiments,
    isArchive,
    isFetchingExperiments,
    isFetchingExperimentGroups,
    isPreviousDataExperiments,
    onChangeDashboard,
    onSave,
    pageTotalGrouped,
    selectedExperiments,
    totalExperiments
  },
  exportRef
) => {
  const { projectName } = useParams();
  const hasSelectedExperiments = dashboard.table.selection.length > 0;
  const reactGridProps = useMemo(() => {
    return {
      ...GRID_CONFIG,
      allowSorting: activeExperimentsHeaderTab === 0,
      displayGroups,
      shouldResizePaginationWidth: true
    };
  }, [displayGroups, activeExperimentsHeaderTab]);

  const renderLoading = () => {
    return (
      <div id="table" className="table-wrapper">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={
            <span>
              Fetching project data for <b>{projectName}</b>
            </span>
          }
        />
      </div>
    );
  };

  const emptyState = useExperimentEmptyStateConfigs(isArchive);

  // Workaround that prevent crash inside BaseGrid component
  // here is the error that occurs for any new added columns
  // the workaround unmount/mount table that resolve issue in table but

  // Example of error from console
  // bundle.js:3135 Uncaught TypeError: Cannot read properties of undefined (reading '0')
  // at bundle.js:3135:33
  // at Array.map (<anonymous>)
  //     at getColumnSummaries (bundle.js:3131:10)
  //     at bundle.js:11497:119
  //     at TemplateConnectorBase.render (bundle.js:4334:16)
  //     at finishClassComponent (bundle.js:1029867:31)
  //     at updateClassComponent (bundle.js:1029817:24)
  //     at beginWork (bundle.js:1031327:16)
  //     at HTMLUnknownElement.callCallback (bundle.js:1012895:14)
  //     at Object.invokeGuardedCallbackDev (bundle.js:1012944:16)
  const displayGroupsRef = useRef(displayGroups);
  if (!isEqual(displayGroupsRef.current, displayGroups)) {
    displayGroupsRef.current = displayGroups;
    return renderLoading();
  }

  const hasNoExperiments =
    !experiments.length &&
    !(
      hasDashboardAnyRule(dashboard) ||
      hasDashboardTableSearch(dashboard?.table)
    ) &&
    !hasDashboardAnyGrouping(dashboard);

  if (hasNoExperiments) {
    if (isPreviousDataExperiments && isFetchingExperiments) {
      return renderLoading();
    }

    return (
      <EmptySectionMessage
        buttonText={emptyState.buttonText}
        description={emptyState.description}
        icon={<PanelsIcon />}
        title={emptyState.title}
        ButtonProps={{
          onClick: emptyState.onButtonClick
        }}
      />
    );
  }

  const nameWidth = getNameColumnWidth(dashboard.table?.columnWidths);
  const sidebarWidth = nameWidth + SIDEBAR_WIDTH_WITHOUT_NAME_COLUMN;

  return (
    <div
      className={cx('experiments-page', {
        'experiments-page-with-selection': hasSelectedExperiments
      })}
      style={{
        '--composed-name-column-width': `${sidebarWidth}px`
      }}
    >
      <DashboardTable
        availableColumns={availableColumns}
        activeExperimentsHeaderTab={activeExperimentsHeaderTab}
        className="experiments-page-experiments"
        dashboardTable={dashboard.table}
        dashboardQuery={dashboard.query}
        hiddenExperimentKeys={dashboard.hiddenExperimentKeys}
        pinnedExperimentKeys={dashboard.pinnedExperimentKeys}
        experimentsGroupsMap={experimentsGroupsMap}
        groupAggregations={groupAggregations}
        experiments={experiments}
        onSave={onSave}
        isArchive={isArchive}
        ref={{ ref: null, exportRef }}
        isFetchingExperiments={
          isFetchingExperiments || isFetchingExperimentGroups
        }
        isPreviousDataExperiments={isPreviousDataExperiments}
        onChange={onChangeDashboard}
        pageTotalGrouped={pageTotalGrouped}
        showButtonLabels
        selectedExperiments={selectedExperiments}
        totalExperiments={totalExperiments}
        ReactGridProps={reactGridProps}
      />
    </div>
  );
};

const ExperimentsPageRef = forwardRef(ExperimentsPage);

ExperimentsPageRef.defaultProps = {
  availableColumns: [],
  pageTotalGrouped: null,
  groupAggregations: null
};

ExperimentsPageRef.propTypes = {
  availableColumns: PropTypes.array,
  activeExperimentsHeaderTab: PropTypes.number.isRequired,
  dashboard: PropTypes.object.isRequired,
  displayGroups: PropTypes.array.isRequired,
  experimentsGroupsMap: PropTypes.object,
  groupAggregations: PropTypes.object,
  experiments: PropTypes.array.isRequired,
  isArchive: PropTypes.bool.isRequired,
  isFetchingExperiments: PropTypes.bool.isRequired,
  isFetchingExperimentGroups: PropTypes.bool.isRequired,
  isPreviousDataExperiments: PropTypes.bool.isRequired,
  onChangeDashboard: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  pageTotalGrouped: PropTypes.number,
  selectedExperiments: PropTypes.array.isRequired,
  totalExperiments: PropTypes.number.isRequired
};

export default ExperimentsPageRef;
