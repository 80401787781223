// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageFilterSection-module__container--LiUrL{display:flex;flex-direction:column;gap:16px;padding:10px}.ImageFilterSection-module__row--ZhFUx{display:flex;gap:14px;font-weight:400;font-size:14px;color:var(--gray-6);user-select:none;line-height:16px;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ImageFilterSection-module__container--LiUrL`,
	"row": `ImageFilterSection-module__row--ZhFUx`
};
module.exports = ___CSS_LOADER_EXPORT___;
