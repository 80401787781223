import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { SketchPicker } from 'react-color';
import { Popover } from '@material-ui/core';
import { DSRefPropType } from '@design-system-outdated/constants';

import styles from './ColorPickerPopover.module.scss';

const ColorPickerPopover = ({
  value,
  onChange,
  anchorEl,
  open,
  onClose,
  presetColors,
  renderFooter
}) => {
  const [localVal, setLocalVal] = useState(value);

  useEffect(() => {
    setLocalVal(value);
  }, [value]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div>
        <SketchPicker
          color={localVal}
          onChangeComplete={onChange}
          onChange={setLocalVal}
          presetColors={presetColors}
          disableAlpha
        />
        <div className={styles.colorPickerFooter}>{renderFooter()}</div>
      </div>
    </Popover>
  );
};

ColorPickerPopover.defaultProps = {
  value: '',
  onChange: noop,
  anchorEl: null,
  open: false,
  onClose: noop,
  presetColors: [],
  renderFooter: noop
};

ColorPickerPopover.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  anchorEl: DSRefPropType,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  presetColors: PropTypes.arrayOf(PropTypes.string),
  renderFooter: PropTypes.func
};

export default ColorPickerPopover;
