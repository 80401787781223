import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_API_URL } from '@/constants/configConstants';
import ImageLoader from './ImageLoader';

import styles from './ImageSamples.module.scss';
import { GraphicsDetailsModal } from '@experiment-management-shared/components/Charts';
import DsAccordion from '@design-system-outdated/components/Accordion/Accordion';
import {
  ImageDetail,
  ImageFilterSection
} from '@experiment-management-shared/components/ImageDetails';

export type Sample = {
  assetId: string;
  index: number;
  sample: string;
};

export interface ImageSamplesProps {
  actualValue: string;
  experimentKey: string;
  predictedValue: string;
  samples: Sample[];
}

const assetIdToImageSrc = (
  assetId: string,
  experimentKey: string,
  isCompressed = false
): string =>
  `${BASE_API_URL}asset/download?assetId=${assetId}&experimentKey=${experimentKey}&isCompressed=${isCompressed}`;

const ImageSamples: React.FC<ImageSamplesProps> = ({
  actualValue,
  experimentKey,
  predictedValue,
  samples
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [asset, setAsset] = useState<ImageDetail | null>(null);
  const [isGrayScale, setIsGrayScale] = useState<boolean>(false);
  const [isSmoothed, setIsSmoothed] = useState<boolean>(false);

  const images: ImageDetail[] = useMemo(
    () =>
      samples.map(({ assetId, index, sample }) => ({
        sampleIndex: index,
        id: assetId,
        imagePath: assetIdToImageSrc(assetId, experimentKey),
        experimentKey,
        thumbnailPath: assetIdToImageSrc(assetId, experimentKey, true),
        name: sample
      })),
    [samples, experimentKey]
  );

  const handleImageClick = useCallback(asset => {
    setAsset(asset);
    setIsOpen(true);
  }, []);

  const renderImageList = useMemo(
    () =>
      images.map(asset => (
        <div
          key={asset.id}
          className={styles.sampleImage}
          onClick={() => handleImageClick(asset)}
        >
          <ImageLoader
            fallbackSrc={asset.imagePath}
            loader={<CircularProgress />}
            loadingClassName={styles.loaderContainer}
            src={asset.thumbnailPath}
          />
        </div>
      )),
    [images, handleImageClick]
  );

  const renderTitle = (): string => {
    const title =
      actualValue === predictedValue
        ? ' correctly classified as'
        : 'misclassified as';
    return `"${actualValue}" ${title} "${predictedValue}"`;
  };

  const renderSections = useCallback(
    () => (
      <DsAccordion
        content={
          <ImageFilterSection
            isSmoothed={isSmoothed}
            isGrayScale={isGrayScale}
            onChangeSettings={(changes: {
              isGrayScale: boolean;
              isSmoothed: boolean;
            }) => {
              setIsGrayScale(changes.isGrayScale);
              setIsSmoothed(changes.isSmoothed);
            }}
          />
        }
        defaultExpanded
        id="image-analysis"
        title="Image Analysis"
      />
    ),
    [isSmoothed, isGrayScale]
  );

  return (
    <>
      {isOpen && asset && (
        <GraphicsDetailsModal
          assets={images}
          onChange={setAsset}
          onClose={() => setIsOpen(false)}
          renderAssetViewer={() => (
            <ImageDetail
              asset={asset}
              assets={images}
              isGrayScale={isGrayScale}
              isSmoothed={isSmoothed}
              title={renderTitle()}
            />
          )}
          sections={renderSections()}
          selectedAsset={asset}
        />
      )}
      <div className={styles.imageSamplesList}>{renderImageList}</div>
    </>
  );
};

export default connect()(ImageSamples);
