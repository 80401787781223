import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import find from 'lodash/find';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { IconButton, Tooltip } from '@ds';
import {
  SORT_BY_DIRECTION,
  SORT_TOOLTIP
} from '@/constants/workspaceConstants';
import { DSArrowDownIcon, DSArrowUpIcon } from '@ds-icons';

const SortByPopover = ({
  className,
  items,
  sortByProperty,
  sortByDirection,
  handleSortByChange,
  disabled
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSort, setCurrentSort] = useState({
    [sortByProperty]: sortByDirection
  });

  const handleOpen = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSelect = property => {
    const currentDirection = defaultTo(
      currentSort[property],
      SORT_BY_DIRECTION.ASC
    );
    const newDirection =
      currentDirection === SORT_BY_DIRECTION.ASC
        ? SORT_BY_DIRECTION.DESC
        : SORT_BY_DIRECTION.ASC;

    handleSortByChange(property, newDirection);

    setCurrentSort({ [property]: newDirection });

    handleClose();
  };

  const getButtonText = () => {
    const item = find(items, item => item.key === sortByProperty);
    return get(item, 'label', '');
  };

  const renderItems = () => {
    return items.map(item => {
      const { key, label, tooltip = SORT_TOOLTIP.ALPHA } = item;
      const currentSortDirection = defaultTo(
        currentSort[key],
        SORT_BY_DIRECTION.ASC
      );

      const Icon =
        currentSortDirection === SORT_BY_DIRECTION.ASC
          ? DSArrowUpIcon
          : DSArrowDownIcon;

      return (
        <ListItem onClick={() => handleSelect(key)} key={key}>
          <ListItemText>{label}</ListItemText>
          <ListItemSecondaryAction className="select-list-secondary-actions">
            <Tooltip
              content={tooltip[currentSortDirection]}
              placement="left"
              arrow={false}
            >
              <IconButton
                className="sort-project-icon"
                onClick={() => handleSelect(key)}
                Icon={<Icon />}
                type="secondary"
              />
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <div className={cx('project-list-sort-container', className)}>
      <label className="project-sort-label">Sort By</label>
      <div className="project-filter-button-container">
        <Button
          onClick={handleOpen}
          className="project-filter-button"
          disabled={disabled}
        >
          {getButtonText()}
          <i className="material-icons filter-label-icons">
            keyboard_arrow_down
          </i>
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Paper className="project-list-sort-popover">
            <List className="project-list-sort-list">{renderItems()}</List>
          </Paper>
        </Popover>
      </div>
    </div>
  );
};

SortByPopover.propTypes = {
  className: PropTypes.string,
  handleSortByChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  sortByProperty: PropTypes.string.isRequired,
  sortByDirection: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

SortByPopover.defaultProps = {
  className: null
};

export default SortByPopover;
