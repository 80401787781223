import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';

import {
  formatColumnLabelWithKind,
  KIND_TYPE,
  mixColumnNameWithKind
} from '@API/helpers/v2_helpers';
import { Popover } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import { trackEvent } from '@shared/utils/eventTrack';
import FilterInput from '@shared/components/QueryFiltersBody/subcomponents/FilterInput';
import {
  getSummaryString,
  ISimpleRule,
  isRuleValueDefined
} from '@shared/utils/filterHelpers';
import QueryFilterList from './QueryFilterList';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import OperatorsList from './OperatorsList';

const RuleSummary = ({
  columns,
  editable,
  ruleIndex,
  rule: savedRule,
  onChange,
  onRemove,
  constructFilterDefinition,
  getOperatorsForRule,
  constructRuleLabel
}) => {
  const [inputError, setInputError] = useState(null);
  const [isEditing, setIsEditing] = useState(Boolean(savedRule.isNewRule));
  const [rule, setRule] = useState(savedRule);
  const [filterListAnchorEl, setFilterListAnchorEl] = useState();
  const ref = useRef();
  const ruleLabel = isFunction(constructRuleLabel)
    ? constructRuleLabel(rule, columns)
    : formatColumnLabelWithKind(rule.field, rule.kind);

  useEffect(() => {
    if (!rule.source) {
      setFilterListAnchorEl(ref);
    }
  }, [rule.source, ref]);

  useEffect(() => {
    setRule(savedRule);
  }, [savedRule]);

  const handleClose = () => {
    if (rule.isNewRule) {
      handleRemoveRule();
    }

    setRule(savedRule);
    setIsEditing(false);
    setFilterListAnchorEl(null);
  };

  const handleOperatorChange = event => {
    setRule({
      ...rule,
      operator: event.target.value,
      invalid: false
    });
    setInputError(null);
  };

  const handleSelectFilter = (filterValue, item) => {
    const { field, source, kind, value, type } = item;

    if ((kind === KIND_TYPE.MAX || kind === KIND_TYPE.MIN) && value) {
      trackEvent(dashboardEvents.SELECT_METRIC_KIND_OPTION, {
        dropdownName: 'filters',
        columnName: field,
        kind
      });
    }

    const newRule = {
      type,
      field,
      ...(kind && { kind }),
      source,
      value: ''
    };

    setFilterListAnchorEl(null);

    setRule({
      ...rule,
      ...newRule
    });
  };

  const handleSubmitFilter = () => {
    if (!isRuleValueDefined(rule) || rule.invalid) {
      setInputError('Enter a value before saving.');
      return;
    }

    const { isNewRule, invalid, ...restRule } = rule;

    setInputError(null);
    setIsEditing(false);
    onChange(ruleIndex, restRule);
  };

  const handleRemoveRule = useCallback(() => {
    onRemove(ruleIndex);
  }, [ruleIndex, onRemove]);

  const handleValueChange = useCallback((value, invalid) => {
    setRule(prevRule => ({
      ...prevRule,
      value,
      invalid
    }));
    setInputError(null);
  }, []);

  const renderRuleEdit = () => {
    if (!filterListAnchorEl?.current) {
      return null;
    }

    return (
      <QueryFilterList
        anchorEl={filterListAnchorEl}
        columns={columns}
        onClose={handleClose}
        onSelect={handleSelectFilter}
        constructFilterDefinition={constructFilterDefinition}
      />
    );
  };

  const renderRuleSummary = () => {
    if (!rule.source) {
      return null;
    }

    return (
      <>
        <div
          className="filter-group-summary-wrap"
          onClick={event => {
            if (!editable) return;

            if (event.target.className !== 'rqb-filter-group__close-filter') {
              setIsEditing(true);
            }
          }}
        >
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <span className="filter-group-summary-text">
              <span
                style={{ textTransform: 'capitalize' }}
              >{`${ruleLabel}`}</span>
              <span className="filter-summary-string">
                {getSummaryString(rule)}
              </span>
            </span>
          </div>
          <span className="filter-sub-string">{rule.source}</span>

          {editable && (
            <a
              role="button"
              tabIndex={0}
              className="rqb-filter-group__close-filter"
              onClick={handleRemoveRule}
            >
              –
            </a>
          )}
        </div>
        <Popover
          PaperProps={{
            style: {
              overflow: 'unset',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25'
            }
          }}
          anchorEl={ref?.current}
          open={isEditing}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handleClose}
          data-test="filter-input-portal"
          id="filter-input-portal"
          disableEnforceFocus
        >
          <div className="filter-group-input-wrapper">
            <div className="filter-group-title-wrapper">
              <div className="filter-editing-title">
                <p className="truncated-string"> {ruleLabel} </p>
              </div>
            </div>
            <div className="filter-group-input-fields">
              <a
                role="button"
                tabIndex={0}
                className="rqb-filter-group__close-filter"
                onClick={handleClose}
              >
                –
              </a>
              <FormControl component="fieldset" required>
                <RadioGroup
                  tabIndex="0"
                  aria-label="operator"
                  name="operator"
                  value={rule.operator}
                  onChange={handleOperatorChange}
                >
                  <OperatorsList
                    rule={rule}
                    renderInputField={renderInputField}
                    getOperatorsForRule={getOperatorsForRule}
                  />
                </RadioGroup>

                {inputError && <FormHelperText>{inputError}</FormHelperText>}
              </FormControl>
            </div>

            <div
              className="filter-group-button-wrapper"
              data-test="filter-summary-done"
            >
              <a
                className="rqb-filter-group__button"
                onClick={handleSubmitFilter}
              >
                Done
              </a>
            </div>
          </div>
        </Popover>
      </>
    );
  };

  const renderInputField = (operator, disabled) => {
    return (
      <FilterInput
        disabled={disabled}
        filterValue={rule.value}
        columnName={mixColumnNameWithKind(rule.field, rule.kind)}
        onChange={handleValueChange}
        onSubmit={handleSubmitFilter}
        operator={operator}
      />
    );
  };

  return (
    <div ref={ref}>
      {renderRuleEdit()}
      {renderRuleSummary()}
    </div>
  );
};

RuleSummary.defaultProps = {
  editable: true,
  onChange: noop,
  onRemove: noop,
  constructFilterDefinition: noop,
  constructRuleLabel: undefined
};

RuleSummary.propTypes = {
  columns: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  ruleIndex: PropTypes.number.isRequired,
  rule: ISimpleRule.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  constructFilterDefinition: PropTypes.func,
  getOperatorsForRule: PropTypes.func.isRequired,
  constructRuleLabel: PropTypes.func
};

export default RuleSummary;
