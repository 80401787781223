import { useMutation, useQueryClient } from 'react-query';

import organizationApi from '@shared/api/organizationApi';

const updateUserPermissionsWorkspace = ({ workspaceId, usersPermissions }) =>
  organizationApi.updateUsersPermissionsInWorkspace({
    workspaceId,
    usersPermissions
  });

const useWorkspaceUsersPermissionsUpdateMutation = workspaceId => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ usersPermissions }) =>
      updateUserPermissionsWorkspace({
        workspaceId,
        usersPermissions
      }),
    {
      onMutate: () => {
        queryClient.cancelQueries([
          'workspaceUserPermissions',
          { workspaceId }
        ]);
        queryClient.cancelQueries(['workspace', workspaceId, 'members']);
        queryClient.cancelQueries(['workspace', workspaceId, 'invites']);
      },
      onSuccess: async () => {
        await queryClient.resetQueries([
          'workspaceUserPermissions',
          { workspaceId }
        ]);
        await queryClient.resetQueries(['workspace', workspaceId, 'members']);
        await queryClient.resetQueries(['workspace', workspaceId, 'invites']);
      }
    }
  );
};

export default useWorkspaceUsersPermissionsUpdateMutation;
