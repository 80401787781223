import React, { useCallback, useEffect, useMemo } from 'react';

import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import useDeepMemo from '@shared/hooks/useDeepMemo';
import isArray from 'lodash/isArray';
import noop from 'lodash/noop';

import {
  ACTIVE_FETCH_INTERVAL,
  AGGREGATION_TYPES_OBJECT,
  BUILT_IN_CHART_TYPES,
  CHART_CONFIG_MODIFIERS,
  DEFAULT_CUSTOM_RANGE,
  DEFAULT_GROUPING_STATE,
  LINE_CHART_LINE_WIDTH,
  LINE_CHART_X_AGGREGATIONS,
  LINE_TYPE_STRATEGY,
  OUTLIERS_VALUES,
  TRANSFORM_TYPES_OBJECT
} from '@experiment-management-shared/constants/chartConstants';
import {
  LEGEND_MODE,
  LINE_DASH_OPTIONS
} from '@experiment-management-shared/constants';

import { DURATION } from '@experiment-management-shared/constants/experimentConstants';
import chartHelpers from '@experiment-management-shared/utils/chartHelpers';

import { OptimizedPlot } from '@DesignSystem/charts';
import LineChartTooltip from './LineChartTooltip';
import { useLineChartTooltip } from './useLineChartTooltip';

import {
  getLegendsKeysMap,
  getMetricNameFromDataSource,
  getTraceNameFromDataSource,
  isTransformActive,
  isVisibleOnChart
} from '../../ChartHelpers';

import { CHART_COLORS, CHART_COLORS_LIGHT } from '@/constants/colorConstants';
import {
  getGroupMetricLineMap,
  getGroupParamColorMap,
  getXAndYValues,
  groupLineData
} from '@experiment-management-shared/components/Charts/PlotlyChart/helpers/lineChartHelpers';
import ChartContainer from '@experiment-management-shared/components/Charts/Chart/ChartContainer';
import { GeneralChartContainerProps } from '@experiment-management-shared/components/Charts/Chart/ChartContainer/ChartContainer';
import {
  CustomRange,
  DataSource,
  Experiment,
  GroupAggregation,
  Grouping,
  GroupingPanelTrace,
  GroupLineRange,
  LegendMode,
  LineChartAggregationX,
  LineChartFillType,
  LineChartHoverMode,
  LineTypeStrategy,
  MetricColorMap,
  PanelGlobalConfig,
  PanelTrace,
  PanelType,
  SelectedOutliers
} from '@experiment-management-shared/types';
import usePanelSampleSize from '@experiment-management-shared/hooks/usePanelSampleSize';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';
import useExperimentsPanelData from '@experiment-management-shared/api/useExperimentsPanelData';

import useChartDataSourceChangeNotificator from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataSourceChangeNotificator';
import useChartDataRevision from '@experiment-management-shared/components/Charts/Chart/chartHooks/useChartDataRevision';
import usePlotlyBaseChart from '@experiment-management-shared/components/Charts/Chart/chartHooks/usePlotlyBaseChart';
import { getExperimentsColorMap } from '@experiment-management-shared/utils/experimentHelpers';
import useChartTraceClick from '@experiment-management-shared/hooks/useChartTraceClick';
import LegendWrapper from '@experiment-management-shared/components/Charts/Legends/LegendWrapper';
import { Legend } from '@experiment-management-shared/components/Charts/Legends';
import useLineChartLegend from '@experiment-management-shared/components/Charts/PlotlyChart/LineChart/useLineChartLegend';
import {
  generateGuaranteeUniquenessFunction,
  generateLegendLabelForLineChart,
  truncateLegendValueForExport
} from '@experiment-management-shared/components/Charts/Legends/helpers';

type TransformYConfig = {
  isLog: boolean;
  onChange: () => void;
  value: string | null;
};

type LineChartProps = {
  containerProps: GeneralChartContainerProps;
  isChartPreview?: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  hiddenExperimentKeys?: string[];
  isAutoRefreshEnabled: boolean;
  activeIntervalFetchDelay?: number;
  title?: string;
  chartName?: string;
  onChangeLegendMode?: never;
  onTransformYChange?: (config: {
    chartId: string;
    transformY: string | null;
  }) => void;
  onDataSourceChange?: () => void;
  onFinishRender?: () => void;
  enableDataRevision?: boolean;
  isPendingRendering?: boolean;
  dataRevision?: number;
  revision?: number;
  sampleSize?: number;
  sampleSizes?: never;
  handleSampleSizeChange?: never;
  fetchFull?: boolean;
  selectedLegendKeys?: never;
  legendMode: LegendMode;
  hiddenMenuItems?: string[];
  chartClickEnabled?: boolean;
  customRange?: CustomRange;
  locked?: boolean;
  showLock?: boolean;
  onLockClick?: unknown;
  panelGlobalConfig?: PanelGlobalConfig;
  customXAxisTitle: string;
  showXAxisTitle: boolean;
  showXAxisTickLabels: boolean;
  customYAxisTitle: string;
  showYAxisTitle: boolean;
  showYAxisTickLabels: boolean;
  yAxisRange?: {
    range: number[];
    ticksuffix: string;
  };
  metricNames: string[];
  aggregationX?: LineChartAggregationX;
  customTransformY?: (value: number[] | string[]) => number | string;
  chartResponseDataTransform?: (data: unknown) => unknown;
  fillType?: LineChartFillType;
  grouping?: Grouping;
  selectedOutliers?: SelectedOutliers;
  selectedXAxis?: string;
  selectedYAxis: string[];
  smoothingX?: number;
  smoothingY?: number;
  transformX?: string | null;
  transformY?: string | null;
  hoverMode?: LineChartHoverMode;
  lineTypeStrategy?: LineTypeStrategy;
  experimentsCount?: number;
  metricColorMap?: MetricColorMap;
  globalConfigMap: Record<string, string[]>;
};
const LineChart = ({
  containerProps,
  isChartPreview = false,
  experiments,
  experimentKeys,
  hiddenExperimentKeys,
  isAutoRefreshEnabled,
  activeIntervalFetchDelay = ACTIVE_FETCH_INTERVAL,
  title,
  chartName = '',
  onChangeLegendMode,
  onTransformYChange,
  onDataSourceChange = noop,
  onFinishRender = noop,
  enableDataRevision = false,
  isPendingRendering = false,
  dataRevision = 0,
  revision = 0,
  sampleSize: panelSampleSize,
  sampleSizes,
  handleSampleSizeChange,
  fetchFull = false,
  selectedLegendKeys,
  legendMode = LEGEND_MODE.AUTO,
  hiddenMenuItems,
  chartClickEnabled = true,
  customRange = DEFAULT_CUSTOM_RANGE,
  panelGlobalConfig,
  customXAxisTitle = '',
  showXAxisTitle = false,
  showXAxisTickLabels = true,
  customYAxisTitle = '',
  showYAxisTitle = false,
  showYAxisTickLabels = true,
  locked = false,
  showLock = false,
  onLockClick,
  metricColorMap,
  lineTypeStrategy = LINE_TYPE_STRATEGY.AUTO as LineTypeStrategy,
  hoverMode = 'closest',
  experimentsCount,
  yAxisRange,
  metricNames,
  aggregationX = AGGREGATION_TYPES_OBJECT.LAST as LineChartAggregationX,
  customTransformY = identity,
  chartResponseDataTransform,
  fillType = CHART_CONFIG_MODIFIERS.LINE.value as LineChartFillType,
  grouping = DEFAULT_GROUPING_STATE.LINE as Grouping,
  selectedOutliers = OUTLIERS_VALUES.SHOW as SelectedOutliers,
  selectedXAxis: panelSelectedXAxis = DURATION,
  selectedYAxis,
  smoothingX = 0,
  smoothingY: panelSmoothingY = 0,
  transformX = null,
  transformY: panelTransformY = null,
  globalConfigMap
}: LineChartProps) => {
  const chartType: PanelType = BUILT_IN_CHART_TYPES[
    'BuiltIn/Line'
  ] as PanelType;
  const fill = get(CHART_CONFIG_MODIFIERS, [fillType, 'config'], {});

  const isMultiMetricsChart = metricNames.length > 1;
  const showLegend = useMemo(() => {
    const hasGroping = grouping?.enabled;
    const multiMetricWithColorPerMetric =
      lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST && isMultiMetricsChart;

    if (
      legendMode === LEGEND_MODE.AUTO &&
      (hasGroping || multiMetricWithColorPerMetric)
    ) {
      return true;
    }

    return legendMode === LEGEND_MODE.ON;
  }, [grouping?.enabled, isMultiMetricsChart, legendMode, lineTypeStrategy]);

  const params = useMemo(() => {
    return grouping?.groupByParameter ? [grouping.groupByParameter] : [];
  }, [grouping?.groupByParameter]);

  const {
    selectedXAxis,
    transformY,
    smoothingY,
    percentile,
    sampleSize
  } = useMemo(() => {
    const extendedConfig = chartHelpers.extendConfigWithGlobalConfig(
      {
        chartType,
        sampleSize: panelSampleSize,
        locked,
        selectedOutliers,
        selectedXAxis: panelSelectedXAxis,
        smoothingY: panelSmoothingY,
        transformY: panelTransformY
      },
      panelGlobalConfig,
      globalConfigMap
    );

    return {
      ...extendedConfig,
      percentile: chartHelpers.calculatePercentile(
        extendedConfig.selectedOutliers
      )
    };
  }, [
    chartType,
    locked,
    panelSampleSize,
    selectedOutliers,
    panelSelectedXAxis,
    panelSmoothingY,
    panelTransformY,
    panelGlobalConfig,
    globalConfigMap
  ]);

  const {
    computedSampleSize,
    computedHandleSampleSizeChange
  } = usePanelSampleSize({
    sampleSize,
    sampleSizes,
    handleSampleSizeChange
  });

  const { shouldRefetch, actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    title,
    chartName,
    chartType,
    titleProps: {
      selectedXAxis,
      selectedYAxis
    }
  });

  const {
    data: panelData,
    isError,
    isFetching,
    isLoading,
    isPreviousData
  } = useExperimentsPanelData(
    {
      experimentKeys,
      fetchFull,
      metricNames,
      params,
      sampleSize: computedSampleSize,
      type: chartType
    },
    {
      refetchInterval: shouldRefetch ? activeIntervalFetchDelay : false,
      refetchOnMount: true
    }
  );

  const transformedPanelData = useMemo(() => {
    return isFunction(chartResponseDataTransform)
      ? chartResponseDataTransform(panelData)
      : panelData;
  }, [chartResponseDataTransform, panelData]);

  const rawDataSources = useDeepMemo(() => {
    if (isLoading) return [];

    const data = chartHelpers.toDataSources({
      data: transformedPanelData,
      experimentKeys,
      selectedXAxis,
      type: chartType
    });

    const localDataSources = chartHelpers.normalizeDataSource({
      dataSources: data,
      selectedXAxis,
      selectedYAxis,
      chartType
    });

    if (isArray(hiddenExperimentKeys)) {
      return chartHelpers.applyVisibilityOfExperiments(
        localDataSources,
        hiddenExperimentKeys
      );
    } else {
      return localDataSources;
    }
  }, [
    isLoading,
    chartType,
    hiddenExperimentKeys,
    transformedPanelData,
    selectedXAxis,
    selectedYAxis,
    experimentKeys
  ]);

  useChartDataSourceChangeNotificator({
    isLoading,
    dataSources: rawDataSources,
    onDataSourceChange
  });

  const {
    delayedDataSources: dataSources,
    hasDelayedDataSources,
    isInitialRenderDelayed,
    calculatedRevision
  } = useChartDataRevision<DataSource[]>({
    enableDataRevision,
    isPendingRendering,
    dataRevision,
    dataSources: rawDataSources,
    revision
  });

  const {
    setExportData,
    setExportLayout,
    containerRef,
    handleExportJSON,
    handleExportData,
    handleResetZoom,
    disableResetZoom,
    chartBaseLayout,
    PlotlyProps
  } = usePlotlyBaseChart({
    revision: calculatedRevision,
    isChartPreview,
    onFinishRender,
    yAxisRange,
    selectedXAxis,
    title: actualTitle,
    chartType
  });

  const { xAxisTitle, yAxisTitle } = useMemo(() => {
    return chartHelpers.calculateLineChartAxisTitles({
      showXAxisTitle,
      customXAxisTitle,
      selectedXAxis,
      showYAxisTitle,
      customYAxisTitle,
      metricNames
    });
  }, [
    showXAxisTitle,
    customXAxisTitle,
    selectedXAxis,
    showYAxisTitle,
    customYAxisTitle,
    metricNames
  ]);

  const experimentColorMap = useDeepMemo(
    () => getExperimentsColorMap(experiments),
    [experiments]
  );

  const legendKeysMap = useDeepMemo(() => {
    return getLegendsKeysMap(dataSources, experiments, selectedLegendKeys);
  }, [dataSources, experiments, selectedLegendKeys]);

  useEffect(() => {
    if (isError || isEmpty(dataSources)) {
      onFinishRender();
    }
  }, [dataSources, isError, isLoading, onFinishRender]);

  const {
    handleLinePointHover,
    handleLinePointBeforeHover,
    handleLinePointUnHover,
    tooltipData,
    clickableRef
  } = useLineChartTooltip();

  const { handleChartClick } = useChartTraceClick({
    hoveredPoint: tooltipData?.point,
    traceClickable: chartClickEnabled,
    traceClickableRef: clickableRef
  });

  const getPoints = useCallback(
    (xValues, yValues) => {
      if (percentile > 0) {
        return chartHelpers.getPointsWithOutliersRemoved({
          xValues,
          yValues,
          percentile
        });
      }

      return {
        x: xValues,
        y: yValues
      };
    },
    [percentile]
  );

  const isTransformActiveConnected = useMemo(() => {
    return isTransformActive(smoothingX, smoothingY, transformX, transformY);
  }, [smoothingX, smoothingY, transformX, transformY]);

  const getTransforms = useCallback(
    groups => {
      return aggregationX === LINE_CHART_X_AGGREGATIONS.ALL.value
        ? []
        : [
            {
              type: 'aggregate',
              groups,
              aggregations: [
                {
                  target: 'y',
                  func: aggregationX,
                  enabled: true
                }
              ]
            }
          ];
    },
    [aggregationX]
  );

  const getDefaultLineChartConfig = useCallback(
    dataSource => {
      const { xValues, yValues } = getXAndYValues(
        dataSource,
        selectedXAxis
      ) as {
        xValues: number[] | string[];
        yValues: number[] | string[];
      };
      const transformedYValues = customTransformY(yValues);
      const { x, y } = getPoints(xValues, transformedYValues);
      const metricName = getMetricNameFromDataSource(dataSource);
      const name = getTraceNameFromDataSource(dataSource);

      // needed for grouping by param
      const params = dataSource.params;

      return {
        cometMetadataType:
          lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST
            ? 'experiment_metric'
            : 'metric',
        cometMetadata: {
          ...legendKeysMap[dataSource.experiment_key],
          metricName
        },
        x,
        y,
        params,
        type: 'scattergl',
        mode: 'lines',
        transforms: getTransforms(x),
        name,
        legendgroup: metricName,
        metricName,
        ...fill
      };
    },
    [
      selectedXAxis,
      customTransformY,
      getPoints,
      lineTypeStrategy,
      legendKeysMap,
      getTransforms,
      fill
    ]
  );

  const getLineColorFromMetricMap = useCallback(
    (dataSource, showMainColor, defaultPrimary, defaultLight) => {
      const metricName = getMetricNameFromDataSource(dataSource);

      const primary = get(
        metricColorMap,
        [metricName, 'primary'],
        defaultPrimary
      );
      const light = get(metricColorMap, [metricName, 'light'], defaultLight);

      return showMainColor ? primary : light;
    },
    [metricColorMap]
  );

  const getLineColor = useCallback(
    (dataSource, index, showMainColor = !isTransformActiveConnected) => {
      const defaultPrimary = CHART_COLORS[index % CHART_COLORS.length];
      const defaultLight =
        CHART_COLORS_LIGHT[index % CHART_COLORS_LIGHT.length];

      if (metricColorMap) {
        return getLineColorFromMetricMap(
          dataSource,
          showMainColor,
          defaultPrimary,
          defaultLight
        );
      }

      if (lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST) {
        return showMainColor ? defaultPrimary : defaultLight;
      }

      const { experiment_key } = dataSource;

      return showMainColor
        ? get(experimentColorMap, [experiment_key, 'primary'], defaultPrimary)
        : get(experimentColorMap, [experiment_key, 'light'], defaultLight);
    },
    [
      experimentColorMap,
      getLineColorFromMetricMap,
      isTransformActiveConnected,
      lineTypeStrategy,
      metricColorMap
    ]
  );

  const getLineDash = useCallback(
    ({ experiment_key }, lineDashIndexMap) => {
      if (lineTypeStrategy === LINE_TYPE_STRATEGY.FIRST) {
        return LINE_DASH_OPTIONS[0];
      }

      if (lineDashIndexMap.hasOwnProperty(experiment_key)) {
        lineDashIndexMap[experiment_key] += 1;
      } else {
        lineDashIndexMap[experiment_key] = 0;
      }

      const index = lineDashIndexMap[experiment_key];
      return LINE_DASH_OPTIONS[index % LINE_DASH_OPTIONS.length];
    },
    [lineTypeStrategy]
  );

  const defaultSeries = useMemo(() => {
    const lineDashIndexMap = {};

    return dataSources.map((dataSource, index) => {
      return {
        ...getDefaultLineChartConfig(dataSource),
        showlegend: !isTransformActiveConnected,
        hoverinfo: isTransformActiveConnected ? 'skip' : 'name',
        line: {
          color: getLineColor(dataSource, index),
          dash: getLineDash(dataSource, lineDashIndexMap),
          width: LINE_CHART_LINE_WIDTH
        },
        visible: isVisibleOnChart(dataSource)
      };
    });
  }, [
    dataSources,
    getDefaultLineChartConfig,
    isTransformActiveConnected,
    getLineColor,
    getLineDash
  ]);

  const createLineChartSeriesWithTransforms = useCallback(() => {
    const lineDashIndexMap = {};

    return dataSources.map((dataSource, index) => {
      const defaultConfig = getDefaultLineChartConfig(dataSource);

      const { x, y } = defaultConfig;

      const xValues = chartHelpers.applyTransformByType(
        transformX,
        x,
        smoothingX
      );

      const yValues = chartHelpers.applyTransformByType(
        transformY,
        y,
        smoothingY
      );

      return {
        ...defaultConfig,
        x: xValues,
        y: yValues,
        line: {
          color: getLineColor(dataSource, index, true),
          dash: getLineDash(dataSource, lineDashIndexMap),
          width: LINE_CHART_LINE_WIDTH
        },
        visible: isVisibleOnChart(dataSource),
        showlegend: true,
        hoverinfo: 'name'
      };
    });
  }, [
    dataSources,
    getDefaultLineChartConfig,
    getLineColor,
    getLineDash,
    transformX,
    transformY,
    smoothingX,
    smoothingY
  ]);

  const createMarkersForLastPoints = useCallback(() => {
    return dataSources.map((dataSource, index) => {
      const {
        name,
        legendgroup,
        cometMetadataType,
        cometMetadata,
        x,
        y
      } = getDefaultLineChartConfig(dataSource);
      const lastDataPointX = chartHelpers.getLastDataPoint(x);
      const lastDataPointY = chartHelpers.getLastDataPoint(y);

      return {
        cometMetadataType,
        cometMetadata,
        name: `${name} (last point)`,
        x: lastDataPointX ? [lastDataPointX] : [],
        y: lastDataPointY ? [lastDataPointY] : [],
        mode: 'markers',
        cliponaxis: false,
        type: 'scattergl',
        showlegend: false,
        hoverinfo: 'skip',
        visible: isVisibleOnChart(dataSource),
        legendgroup,
        marker: {
          color: getLineColor(dataSource, index),
          size: 4
        },
        ...fill
      };
    });
  }, [dataSources, getDefaultLineChartConfig, getLineColor, fill]);

  const createMarkersForLastTransformedPoints = useCallback(
    transformedSeries => {
      return transformedSeries.map((trace: Partial<Plotly.PlotData>) => {
        const { name, x, y } = trace;
        const lastDataPointX = chartHelpers.getLastDataPoint(x);
        const lastDataPointY = chartHelpers.getLastDataPoint(y);

        return {
          ...trace,
          name: `${name} (last point)`,
          x: [lastDataPointX],
          y: [lastDataPointY],
          mode: 'markers',
          cliponaxis: false,
          type: 'scattergl',
          showlegend: false,
          hoverinfo: 'skip',
          marker: {
            size: 4
          },
          ...fill
        };
      });
    },
    [fill]
  );

  const layout = useMemo(() => {
    return {
      ...chartBaseLayout,
      spikedistance: -1,
      hoverdistance: -1,
      hovermode: hoverMode,
      xaxis: {
        ...chartBaseLayout.xaxis,
        ...chartHelpers.generateAxisTitle(xAxisTitle),
        showticklabels: showXAxisTickLabels,
        type: chartHelpers.calculateAxisType(
          chartBaseLayout.xaxis.type,
          transformX
        ),
        showspikes: true,
        spikemode: 'across',
        spikedash: 'dot',
        spikecolor: '#8C95A8',
        spikethickness: 1
      },
      yaxis: {
        ...chartBaseLayout.yaxis,
        ...chartHelpers.generateAxisTitle(yAxisTitle),
        showticklabels: showYAxisTickLabels,
        type: chartHelpers.calculateAxisType(
          chartBaseLayout.yaxis.type,
          transformY
        )
      }
    };
  }, [
    chartBaseLayout,
    transformX,
    transformY,
    hoverMode,
    xAxisTitle,
    yAxisTitle,
    showXAxisTickLabels,
    showYAxisTickLabels
  ]);

  const data = useMemo(() => {
    const { ALL, LAST } = LINE_CHART_X_AGGREGATIONS;
    const showMarkersForLastPoints = [ALL, LAST].some(
      ({ value }) => aggregationX === value
    );

    if (isTransformActiveConnected) {
      const transformedSeries = createLineChartSeriesWithTransforms();

      if (showMarkersForLastPoints) {
        const markersForLastPoints = createMarkersForLastTransformedPoints(
          transformedSeries
        );

        return [
          ...defaultSeries,
          ...transformedSeries,
          ...markersForLastPoints
        ];
      }

      return [...defaultSeries, ...transformedSeries];
    }

    if (showMarkersForLastPoints) {
      const markersForLastPoints = createMarkersForLastPoints();
      return [...defaultSeries, ...markersForLastPoints];
    }

    return defaultSeries;
  }, [
    aggregationX,
    defaultSeries,
    createLineChartSeriesWithTransforms,
    createMarkersForLastPoints,
    createMarkersForLastTransformedPoints,
    isTransformActiveConnected
  ]);

  const downloadableLayout = useMemo(
    () => ({
      ...layout,
      margin: {
        ...layout.margin,
        b: 40,
        l: 40,
        r: 100,
        t: 16
      },
      showlegend: showLegend
    }),
    [layout, showLegend]
  );

  useEffect(() => {
    setExportLayout(downloadableLayout);
  }, [downloadableLayout, setExportLayout]);

  const isAllExperimentsHidden = useMemo(
    () => dataSources.every(val => !(val.isVisibleOnDashboard ?? true)),
    [dataSources]
  );

  // grouping
  const groupData = useMemo(() => {
    if (!grouping.enabled) {
      return [];
    }

    const metricLineMap = getGroupMetricLineMap(
      defaultSeries as PanelTrace[],
      metricColorMap as MetricColorMap,
      grouping.groupByParameter as string
    );

    const paramColorMap = getGroupParamColorMap(
      defaultSeries as PanelTrace[],
      grouping.groupByParameter as string
    );

    return groupLineData(
      defaultSeries as GroupingPanelTrace[],
      grouping.range as GroupLineRange,
      grouping.aggregation as GroupAggregation,
      grouping.groupByParameter as string,
      isAllExperimentsHidden,
      metricLineMap,
      paramColorMap,
      selectedXAxis === DURATION
    );
  }, [
    metricColorMap,
    isAllExperimentsHidden,
    defaultSeries,
    grouping.enabled,
    grouping.aggregation,
    grouping.range,
    grouping.groupByParameter,
    selectedXAxis
  ]);

  const dataPlot = useMemo(() => {
    if (grouping.enabled) {
      return groupData;
    }

    return data;
  }, [data, grouping.enabled, groupData]);

  useEffect(() => {
    const guaranteeUniqueness = generateGuaranteeUniquenessFunction();

    const exportData = dataPlot
      .filter(trace => trace?.visible !== 'legendonly')
      .map(trace => {
        if (!(trace?.cometMetadata && trace?.showlegend !== false)) {
          return trace;
        }

        return {
          ...trace,
          name: guaranteeUniqueness(
            truncateLegendValueForExport(
              generateLegendLabelForLineChart(
                trace.cometMetadataType,
                trace.cometMetadata,
                isMultiMetricsChart
              )
            )
          )
        };
      });

    setExportData(exportData as never);
  }, [dataPlot, isMultiMetricsChart, setExportData]);

  const transformYConfig = useMemo(() => {
    if (!isFunction(onTransformYChange)) return undefined;

    return {
      onChange: () => {
        onTransformYChange({
          chartId: containerProps.chartId,
          transformY:
            transformY === TRANSFORM_TYPES_OBJECT.LOG_SCALE
              ? null
              : TRANSFORM_TYPES_OBJECT.LOG_SCALE
        });
      },
      isLog: transformY === TRANSFORM_TYPES_OBJECT.LOG_SCALE
    } as TransformYConfig;
  }, [containerProps.chartId, onTransformYChange, transformY]);

  const {
    items,
    calculateIsItemHighlighted,
    onHoverItem,
    onUnhoverItem,
    dataWithHighlight
  } = useLineChartLegend({
    data: dataPlot,
    hoveredPoint: tooltipData?.point,
    isMultiMetricsChart,
    groupingRange: grouping.range as GroupLineRange,
    experimentKeys,
    metricNames
  });

  return (
    <ChartContainer
      {...containerProps}
      isError={isError}
      isFetching={isFetching || hasDelayedDataSources}
      isLoading={isLoading || isInitialRenderDelayed}
      isPreviousData={isPreviousData}
      title={actualTitle}
      hasData={!isEmpty(dataSources)}
      locked={locked}
      showLock={showLock}
      onLockClick={onLockClick as never}
      hiddenMenuItems={hiddenMenuItems}
      transformYConfig={transformYConfig as never}
      onExportJSON={handleExportJSON}
      onExportData={handleExportData}
      onResetZoom={handleResetZoom}
      disableResetZoom={disableResetZoom}
      sampleSize={computedSampleSize}
      sampleSizes={sampleSizes}
      onChangeSampleSize={computedHandleSampleSizeChange}
      legendMode={legendMode}
      onChangeLegendMode={onChangeLegendMode}
      fullScreenType={'badges'}
    >
      <LegendWrapper
        legend={
          showLegend && (
            <Legend
              items={items}
              calculateIsItemHighlighted={calculateIsItemHighlighted}
              onHoverItem={onHoverItem}
              onUnhoverItem={onUnhoverItem}
            />
          )
        }
        chartContainerRef={containerRef as React.RefObject<HTMLDivElement>}
      >
        {tooltipData && (
          <LineChartTooltip
            tooltipData={tooltipData}
            chartData={dataPlot}
            groupsOrder={selectedYAxis}
            aggregationX={aggregationX}
            experimentsCount={experimentsCount}
            highlightRelatedGroups={
              lineTypeStrategy === LINE_TYPE_STRATEGY.AUTO
            }
          />
        )}
        <OptimizedPlot
          {...PlotlyProps}
          customRange={customRange}
          data={dataWithHighlight}
          layout={layout}
          onClick={handleChartClick}
          onBeforeHover={handleLinePointBeforeHover}
          onHover={handleLinePointHover}
          onUnhover={handleLinePointUnHover}
        />
      </LegendWrapper>
    </ChartContainer>
  );
};

LineChart.CONFIG_PROPERTIES = [
  'containerProps',
  'isChartPreview',
  'experiments',
  'experimentKeys',
  'hiddenExperimentKeys',
  'isAutoRefreshEnabled',
  'activeIntervalFetchDelay',
  'title',
  'chartName',
  'onChangeLegendMode',
  'onTransformYChange',
  'onDataSourceChange',
  'onFinishRender',
  'enableDataRevision',
  'isPendingRendering',
  'dataRevision',
  'revision',
  'sampleSize',
  'sampleSizes',
  'handleSampleSizeChange',
  'fetchFull',
  'selectedLegendKeys',
  'hiddenMenuItems',
  'chartClickEnabled',
  'customRange',
  'locked',
  'showLock',
  'onLockClick',
  'panelGlobalConfig',
  'customXAxisTitle',
  'showXAxisTitle',
  'showXAxisTickLabels',
  'customYAxisTitle',
  'showYAxisTitle',
  'showYAxisTickLabels',
  'yAxisRange',
  'metricNames',
  'aggregationX',
  'customTransformY',
  'chartResponseDataTransform',
  'fillType',
  'grouping',
  'selectedOutliers',
  'selectedXAxis',
  'selectedYAxis',
  'smoothingX',
  'smoothingY',
  'transformX',
  'transformY',
  'hoverMode',
  'lineTypeStrategy',
  'experimentsCount',
  'metricColorMap',
  'globalConfigMap',
  'legendMode'
];

export default LineChart;
