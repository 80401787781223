import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DashboardButton from '@experiment-management-shared/components/DashboardButton/index';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

import GroupByModal from '@experiment-management-shared/components/ColumnsModal/GroupByModal';

const modalId = dialogTypes.GROUPING_MODAL;

const groupByIcon = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5385 1.88462C11.7934 1.88462 12 2.09125 12 2.34615V10.6538C12 11.6735 11.1735 12.5 10.1538 12.5H1.84615C1.59125 12.5 1.38461 12.2934 1.38461 12.0385C1.38461 11.7836 1.59125 11.5769 1.84615 11.5769H10.1538C10.6636 11.5769 11.0769 11.1637 11.0769 10.6538V2.34615C11.0769 2.09125 11.2836 1.88462 11.5385 1.88462Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.461539 11.1154C0.206638 11.1154 -2.22841e-08 10.9087 0 10.6538L7.26282e-07 2.34615C8.15418e-07 1.32655 0.826552 0.5 1.84616 0.5L10.1538 0.500001C10.4088 0.500001 10.6154 0.706639 10.6154 0.961539C10.6154 1.21644 10.4088 1.42308 10.1538 1.42308L1.84616 1.42308C1.33635 1.42308 0.923078 1.83635 0.923078 2.34615L0.923077 10.6538C0.923077 10.9087 0.716439 11.1154 0.461539 11.1154Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2309 4.52196C3.2309 4.08499 3.58514 3.73075 4.02211 3.73075H7.97815C8.41513 3.73075 8.76936 4.08499 8.76936 4.52196V8.47801C8.76936 8.91498 8.41513 9.26922 7.97815 9.26922H4.02211C3.58513 9.26922 3.2309 8.91498 3.2309 8.47801V4.52196ZM7.97815 4.52196H4.02211V8.47801H7.97815V4.52196Z"
      fill="currentColor"
    />
  </svg>
);

const GroupByButton = ({
  tooltipTitle,
  modalTitle,
  modalPlaceholder,
  onChange,
  selectedColumnNames,
  excludedColumns,
  columnsNameMap,
  showButtonLabel,
  disabled
}) => {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);

  const closeGroupByModal = () => {
    dispatch(alertsUtil.closeDialog(modalId));
    setIsOpened(false);
  };

  const handleUpdateSelectedColumns = columnNames => {
    onChange(columnNames);
    closeGroupByModal();
  };

  const handleClick = () => {
    const groupByModal = (
      <GroupByModal
        onClose={closeGroupByModal}
        onChange={handleUpdateSelectedColumns}
        selectedColumnNames={selectedColumnNames}
        excludedColumns={excludedColumns}
        columnsNameMap={columnsNameMap}
        title={modalTitle}
        placeholder={modalPlaceholder}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(modalId, groupByModal, {
        onClose: () => setIsOpened(false)
      })
    );

    setIsOpened(true);
  };

  const countLabel = selectedColumnNames.length
    ? `${selectedColumnNames.length}`
    : '';
  const label = showButtonLabel ? 'Group by' : '';

  return (
    <DashboardButton
      tooltipTitle={tooltipTitle}
      disabled={disabled}
      isOpened={isOpened}
      label={label}
      subLabel={countLabel}
      onClick={handleClick}
      icon={groupByIcon}
    />
  );
};

GroupByButton.defaultProps = {
  tooltipTitle: 'Group experiments',
  modalTitle: undefined,
  modalPlaceholder: undefined,
  disabled: false,
  selectedColumnNames: [],
  excludedColumns: [],
  columnsNameMap: {}
};

GroupByButton.propTypes = {
  tooltipTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showButtonLabel: PropTypes.bool.isRequired,
  selectedColumnNames: PropTypes.array,
  disabled: PropTypes.bool,
  excludedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired
    })
  ),
  columnsNameMap: PropTypes.object,
  modalTitle: PropTypes.string,
  modalPlaceholder: PropTypes.string
};

export default GroupByButton;
