import React from 'react';
import PropTypes from 'prop-types';
import { TextButton, LinkButton } from '@ds';
import SimpleCard, {
  DS_SIMPLE_CARD_TYPES
} from '@DesignSystem/surfaces/SimpleCard/SimpleCard';
import { Button } from '@design-system-outdated/components';
import {
  DownloadIcon,
  ExternalPageIcon,
  GoogleColabIcon
} from '@Icons-outdated';
import { APIKeyInput, downloadCodeFile, getDocLink } from '@shared';
import { useOnboardingFirstClick } from '@onboarding/hooks/biEvents';

import styles from './QuickStartGuide.module.scss';

const QuickStartExample = ({
  codeSnippet,
  snippetFileName,
  colabLink,
  docLink,
  frameworkValue
}) => {
  const { onSendFirstClickEvent } = useOnboardingFirstClick();

  const handleDownloadFile = () => {
    onSendFirstClickEvent(`[Download framework file]: ${snippetFileName}`);
    downloadCodeFile(codeSnippet, snippetFileName);
  };

  const handleOpenColabClick = () => {
    onSendFirstClickEvent(`[Colab link click]: ${frameworkValue}`);
  };

  const handleClickExploreDocumentation = () => {
    onSendFirstClickEvent(`[Documentation click]: ${frameworkValue}`);
  };

  const handleFirstClickEvent = () => {
    onSendFirstClickEvent('[Copy api key]');
  };

  return (
    <>
      <SimpleCard withShadow type={DS_SIMPLE_CARD_TYPES.SECONDARY}>
        <div className={styles.fullExampleContainer}>
          <div className={styles.fullExampleTitle}>
            Try one of our full examples
          </div>

          <div className={styles.fullExampleBlock}>
            <p>
              Download the example file and run script to see Comet in action.
            </p>

            <Button
              PostfixIcon={DownloadIcon}
              type="outlined1"
              onClick={handleDownloadFile}
            >
              {snippetFileName}
            </Button>
          </div>

          <div className={styles.fullExampleBlock}>
            <p>Or try this end to end example in Google Colab:</p>

            <a href={colabLink} target="_blank" rel="noreferrer">
              <Button
                PostfixIcon={ExternalPageIcon}
                type="outlined1"
                onClick={handleOpenColabClick}
              >
                <div className={styles.openInColabContainer}>
                  Open in Colab <GoogleColabIcon />
                </div>
              </Button>
            </a>
          </div>

          <LinkButton
            to={getDocLink(docLink)}
            text="Explore at documentation"
            openInNewTab
            className={styles.exploreDocumentationButton}
            onClick={handleClickExploreDocumentation}
          />
        </div>
      </SimpleCard>
      <SimpleCard
        withShadow
        type={DS_SIMPLE_CARD_TYPES.SECONDARY}
        className={styles.quickstartGuideCopyApiKey}
      >
        <div className={styles.apiKeyHeader}>
          <div className={styles.fullExampleTitle}>Your API key</div>
        </div>

        <APIKeyInput
          CopyComponent={
            <TextButton
              size="small"
              type="tertiary"
              className={styles.copyApiKey}
              onClick={handleFirstClickEvent}
            >
              Copy
            </TextButton>
          }
        />
      </SimpleCard>
    </>
  );
};

QuickStartExample.propTypes = {
  codeSnippet: PropTypes.string.isRequired,
  snippetFileName: PropTypes.string.isRequired,
  colabLink: PropTypes.string.isRequired,
  docLink: PropTypes.string.isRequired,
  frameworkValue: PropTypes.string.isRequired
};

export default QuickStartExample;
