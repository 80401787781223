import useComputeEngine from './useComputeEngine';
import { useMutation, useQueryClient } from 'react-query';
import api from '@shared/api';
import { restartComputeEngine } from '@experiment-management-shared/api/custom-server-panels/helpers';

const clearCacheComputeEngine = (computeEngine: string) => {
  return api.get(`${computeEngine}/api/clear-cache`);
};

const useComputeClearCacheMutation = (projectId: string) => {
  const { data: computeEngine = null } = useComputeEngine(projectId);
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      if (computeEngine) {
        return clearCacheComputeEngine(computeEngine);
      }
    },
    {
      onError: async () => {
        await restartComputeEngine(queryClient, projectId);
      }
    }
  );
};

export default useComputeClearCacheMutation;
