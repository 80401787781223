import React from 'react';
import { Popover } from '@material-ui/core';

import styles from './UserMenuOrganization.module.scss';
import {
  useCurrentOrganization,
  useOrganizations,
  usePopoverState
} from '@shared/hooks';
import UserMenuOrganizationSelector from './UserMenuOrganizationSelector';
import UserMenuOrganizationItem from './UserMenuOrganizationItem';
import useCloseMainMenuOnBackdrop from '../UserMenuWorkspaces/hooks/useCloseMainMenuOnBackdrop';
import useIsGuestUser from '@shared/hooks/organization/useIsGuestUser';

const UserMenuOrganization = () => {
  const isGuestUser = useIsGuestUser();
  const { data: organizations = [] } = useOrganizations();
  const userHasMultipleOrganizations = organizations.length > 1;
  const closeMainMenuOnBackdrop = useCloseMainMenuOnBackdrop();

  const isSelectorBlocked = !userHasMultipleOrganizations && !isGuestUser;

  const {
    openPopover,
    onClosePopover,
    isPopoverOpened,
    anchorElement
  } = usePopoverState({
    isBlocked: isSelectorBlocked,
    onClose: closeMainMenuOnBackdrop
  });
  const currentOrganization = useCurrentOrganization();

  if (!currentOrganization) return null;

  const { logoUrl, name } = currentOrganization;

  let currentOrganizationName = name;
  if (isGuestUser) {
    currentOrganizationName = 'Organization';
  }

  return (
    <>
      <UserMenuOrganizationItem
        isActive={isPopoverOpened}
        title={currentOrganizationName}
        avatarLetters={currentOrganizationName[0]}
        avatarUrl={logoUrl}
        onClick={openPopover}
        isReadonly={isSelectorBlocked}
        showRightArrow={!isSelectorBlocked}
        isGuestUser={isGuestUser}
        dataTestId="user-menu-current-organization"
      />
      <Popover
        id="organization-menu-popover"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          className: styles.organizationSelectContainer
        }}
        elevation={0}
        transitionDuration={0}
        open={isPopoverOpened}
        anchorEl={anchorElement}
        onClose={onClosePopover}
      >
        <UserMenuOrganizationSelector
          currentOrganizationName={currentOrganizationName}
        />
      </Popover>
    </>
  );
};

export default UserMenuOrganization;
