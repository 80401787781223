import { useCallback } from 'react';

import { trackEvent } from '@shared/utils/eventTrack';
import useProject from '@API/project/useProject';
import useCurrentUser from '@API/auth/useCurrentUser';

import useActiveWorkspace from './workspace/useActiveWorkspace';
import useCurrentOrganization from './organization/useCurrentOrganization';

const useBaseTrackEvent = () => {
  const currentOrganization = useCurrentOrganization();
  const currentWorkspace = useActiveWorkspace();
  const { data: project } = useProject();
  const { data: currentUser } = useCurrentUser();

  return useCallback(
    (name, params = {}, callback = () => {}) => {
      const eventParams = {
        ...params,
        organization_id: currentOrganization?.id,
        organization_name: currentOrganization?.name,
        workspace_id: currentWorkspace?.id,
        workspace_name: currentWorkspace?.name,
        team_name: currentWorkspace?.name,
        project_id: project?.projectId,
        project_name: project?.projectName,
        user_id: currentUser?.username
      };

      trackEvent(name, eventParams, callback);
    },
    [
      currentOrganization?.id,
      currentOrganization?.name,
      currentUser?.username,
      currentWorkspace?.id,
      currentWorkspace?.name,
      project?.projectId,
      project?.projectName
    ]
  );
};

export default useBaseTrackEvent;
