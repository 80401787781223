import { InputLabel } from '@material-ui/core';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MetadataIcon } from '@Icons-outdated';

import {
  getCommonIndexesForTables,
  getActiveExperiments
} from '@experiment-management-shared/components/Charts/NoPlotlyCharts/DataPanel/helpers';
import { formatValueForSelectOption } from '@shared/utils/selectComponentHelper';
import { Slider } from '@design-system-outdated/components';
import { useFilterAssetsInExperimentsByName } from '@experiment-management-shared/api/usefilterAssetsInExperimentsByName';
import { useFilterAssetsNamesInExperimentsByType } from '@API/panels/useFilterCsvAssetsNamesInExperiments';
import { Select } from '@ds';
import dashboardChartsActions from '@/actions/dashboardChartsActions';

import {
  BUILT_IN_CHART_TYPES,
  DATA_PANEL_MAX_EXPERIMENTS
} from '@/lib/appConstants';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';

import { concatenateOptions, DEBOUNCE_DELAY, joinOptions } from './Consts';
import ConcatenatePreviewImage from './images/ConcatenatePreviewImage.svg';
import IndexPreviewImage from './images/IndexPreviewImage.svg';
import JoinTypeImage from './images/JoinTypeImage.svg';
import { CustomTooltip, stepSliderLabelFormat } from './helpers';
import { WhiteTooltip } from '@experiment-management-shared/utils/buildinVisualisationHelpers';

import './DataPanelTabs.scss';

export function DataPanelDataTab({ experimentKeys, hiddenExperimentKeys }) {
  const dispatch = useDispatch();

  const dataPanelForm = useSelector(state =>
    getChartFormByType(state, {
      chartType: BUILT_IN_CHART_TYPES.data
    })
  );

  const updateForm = useCallback(
    (key, value) => {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          BUILT_IN_CHART_TYPES.data,
          key,
          value
        )
      );
    },
    [dispatch]
  );

  const handleDebouncedChangeExperimentsCount = useCallback(
    debounce(count => updateForm('experimentsCount', count), DEBOUNCE_DELAY),
    []
  );

  const [experimentsCount, setExperimentsCount] = useState(
    dataPanelForm.experimentsCount
  );

  const handleSliderChange = val => {
    setExperimentsCount(val);
    handleDebouncedChangeExperimentsCount(val);
  };

  const targetExperimentKeys = useMemo(
    () =>
      getActiveExperiments({
        experimentKeys,
        experimentsCount,
        hiddenExperimentKeys
      }),
    [experimentKeys, experimentsCount, hiddenExperimentKeys]
  );

  // Fetch list of assets that match selected file name
  const { data: tables } = useFilterAssetsInExperimentsByName({
    experiments: targetExperimentKeys,
    fileName: dataPanelForm.fileName
  });

  const {
    data: assetsNames = [],
    isFetched
  } = useFilterAssetsNamesInExperimentsByType({
    experiments: targetExperimentKeys,
    type: 'CSV'
  });

  useEffect(() => {
    if (isEmpty(assetsNames) && isFetched) {
      updateForm('hasData', false);
    }
  }, [assetsNames, updateForm]);

  const indexSelectOptions = useMemo(() => {
    if (!tables) return [];

    const cols = getCommonIndexesForTables(tables);
    const formattedCols = cols.map(col => ({
      value: col,
      label: col
    }));

    return formattedCols;
  }, [tables]);

  useEffect(() => {
    if (isEmpty(indexSelectOptions)) return;

    const hasValidIndex = indexSelectOptions.find(
      option => option.value === dataPanelForm.index
    );

    if (!hasValidIndex) {
      updateForm('index', indexSelectOptions[0].value);
    }
  }, [dataPanelForm.index, indexSelectOptions, updateForm]);

  const assetsNamesToSelectOptions = useMemo(
    () => assetsNames?.map(formatValueForSelectOption),
    [assetsNames]
  );

  return (
    <div className="data-panel-container">
      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">Data selection</InputLabel>

        <CustomTooltip
          arrow
          disableFocusListener
          disableTouchListener
          disableHoverListener={!isEmpty(assetsNames)}
          title="To view data using the Data Table you need to log it using `Experiment.log_table`"
        >
          <div>
            <Select
              maxWidth={400}
              isClearable
              isSearchable
              hideSecondary
              value={dataPanelForm.fileName}
              onValueChange={value => updateForm('fileName', value)}
              options={assetsNamesToSelectOptions}
              isDisabled={isEmpty(assetsNames)}
            />
          </div>
        </CustomTooltip>
        <p className="modal-input-label-subtext">
          Files that are larger than 10MB will be disabled and wont be displayed
        </p>
      </div>

      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">
          <span> Choose your index</span>
          <WhiteTooltip title={<IndexPreviewImage />} placement="bottom-start">
            <div className="metadata-icon">
              <MetadataIcon />
            </div>
          </WhiteTooltip>
        </InputLabel>

        <CustomTooltip
          arrow
          disableHoverListener={!isEmpty(indexSelectOptions)}
          title="Index is only available when data is selected"
        >
          <div>
            <Select
              maxWidth={400}
              isClearable={false}
              onValueChange={value => updateForm('index', value)}
              value={dataPanelForm.index}
              isDisabled={!indexSelectOptions || isEmpty(indexSelectOptions)}
              options={indexSelectOptions}
            />
          </div>
        </CustomTooltip>
      </div>

      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">
          <span> Concatenation options</span>
          <WhiteTooltip
            title={<ConcatenatePreviewImage />}
            placement="bottom-start"
          >
            <div className="metadata-icon">
              <MetadataIcon />
            </div>
          </WhiteTooltip>
        </InputLabel>
        <Select
          maxWidth={400}
          isClearable={false}
          onValueChange={value => {
            updateForm('axis', value);
          }}
          value={dataPanelForm.axis}
          options={concatenateOptions}
        />
      </div>

      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">
          <span> Join type</span>
          <WhiteTooltip title={<JoinTypeImage />} placement="bottom-start">
            <div className="metadata-icon">
              <MetadataIcon />
            </div>
          </WhiteTooltip>
        </InputLabel>
        <Select
          maxWidth={400}
          isClearable={false}
          onValueChange={value => updateForm('joinType', value)}
          value={dataPanelForm.joinType}
          options={joinOptions}
        />
      </div>

      <div className="mb-40">
        <InputLabel className="modal-input-label">
          Max number of experiments to show
        </InputLabel>
        <div className="slider-wrapper">
          <Slider
            min={0}
            max={DATA_PANEL_MAX_EXPERIMENTS}
            step={1}
            valueLabelFormat={value => value}
            value={experimentsCount}
            sliderSuffixFormat={stepSliderLabelFormat}
            onChange={handleSliderChange}
          />
        </div>
      </div>
    </div>
  );
}

export default DataPanelDataTab;
