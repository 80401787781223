import { useQuery } from 'react-query';
import useCurrentUser from '@API/auth/useCurrentUser';
import useCurrentOrganization from '../organization/useCurrentOrganization';

import useAllUserWorkspaces from './useAllUserWorkspaces';

// the workspaces of a current user of an organization
export default function useWorkspaces() {
  const organization = useCurrentOrganization();
  const { data: currentUser } = useCurrentUser();
  const { data: allWorkspaces, isLoading, isFetching } = useAllUserWorkspaces();

  return useQuery(
    ['organizations', organization?.id, 'current-user', 'workspaces'],
    async () => {
      const filteredWorkspaces =
        allWorkspaces?.filter(
          workspace => workspace?.organizationId === organization?.id
        ) || [];

      return filteredWorkspaces;
    },
    {
      enabled: !!currentUser?.apiKey && !isLoading && !isFetching
    }
  );
}
