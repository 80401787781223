import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TextButton } from '@ds';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { formatDateTime } from '@shared/utils/displayHelpers';
import { GRID_COLUMNS } from '@experiment-management-shared/utils/LLMView';
import { SectionTabs } from '@DesignSystem/navigation';
import { Button, BUTTON_TYPES } from '@design-system-outdated';
import { MinusIcon, PlusIcon } from '@Icons-outdated';
import { Divider, StyledTooltip } from '@DesignSystem/data-display';
import { DIVIDER_ORIENTATIONS } from '@DesignSystem/data-display/Divider/Divider';
import InfoBox from '@design-system-outdated/components/InfoBox';
import useAssetByName from '@experiment-management-shared/api/useAssetByName';
import { Resizable } from 're-resizable';
import 'react-complex-tree/lib/style-modern.css';
import styles from './LLMDashboardPageSidebar.module.scss';
import SmallLoader from '@shared/components/SmallLoader';
import { Experiment } from '@experiment-management-shared/types';
import {
  LLMDetailsTab,
  LLMInputsOutputsTab,
  LLMMetadataTab
} from './components/tabs';
import {
  ControlledTreeEnvironment,
  Tree,
  TreeItem,
  TreeItemIndex
} from 'react-complex-tree';
import { chainRenderers } from './components/chain/chainRenderers';
import { useWindowSize } from '@/helpers/custom-hooks/useWindowSize';
import { CHAIN_COLORS, RESIZEABLE_POSITIONS } from './components/chain/helpers';
import { isEmpty, toNumber } from 'lodash';
import find from 'lodash/find';
import last from 'lodash/last';
import union from 'lodash/union';
import { DSArrowLeftIcon, DSArrowRightIcon } from '@ds-icons';
import LLMImagesTab from './components/tabs/LLMImagesTab';
import {
  ImageContent,
  ImageContentSchema,
  LLMAsset,
  LLMChainNode
} from '@shared/types/LLMAsset';

const chainRootIndex = 'chainRoot';

const DEFAULT_CHAIN_DETAILS_WIDTH = 550;

const LLM_CATEGORY_SINGLE_PROMPT = 'single_prompt';

const isImageContent = (content: unknown) => {
  try {
    ImageContentSchema.parse(content);
    return true;
  } catch (error) {
    return false;
  }
};

function extractImageUrls(messages: unknown) {
  if (!Array.isArray(messages)) return [];

  const images: string[] = [];

  messages.forEach(message => {
    const imageContent: ImageContent[] = Array.isArray(message?.content)
      ? message.content.filter(isImageContent)
      : [];

    images.push(...imageContent.map(content => content.image_url.url));
  });

  return images;
}

const LLMDashboardPageSidebar = ({
  experiment,
  hasNext,
  hasPrevious,
  onExperimentChange,
  onClose,
  labelColorMap = {}
}: {
  experiment?: Experiment;
  hasNext: boolean;
  hasPrevious: boolean;
  onExperimentChange: (index: number) => void;
  labelColorMap: Record<string, string>;
  onClose: () => void;
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { data, isLoading, isError } = useAssetByName<LLMAsset>(
    {
      experimentKey: experiment?.experimentKey || '',
      userFileName: 'comet_llm_data.json'
    },
    {
      enabled: Boolean(experiment),
      keepPreviousData: true,
      retry: false
    }
  );

  const chainNodes = data?.chain_nodes ?? [];

  const size = useWindowSize();

  const [
    focusedChainNodeIndex,
    setFocusedChainNodeIndex
  ] = useState<TreeItemIndex>();
  const [expandedChainNodes, setExpandedChainNodes] = useState<TreeItemIndex[]>(
    []
  );
  const [selectedChainNodes, setSelectedChainNodes] = useState<TreeItemIndex[]>(
    []
  );

  const focusedChainNode = useMemo(() => {
    return find(chainNodes, function (node) {
      return node.id == focusedChainNodeIndex;
    });
  }, [data?.chain_nodes, focusedChainNodeIndex]);

  const imagesUrls = useMemo(
    () =>
      extractImageUrls(
        focusedChainNode?.inputs?.messages || data?.chain_inputs?.messages || []
      ),
    [focusedChainNode, data]
  );

  useEffect(() => {
    if (activeTabIndex === 1) {
      const isMetadataDisabled = focusedChainNode?.metadata
        ? isEmpty(focusedChainNode?.metadata)
        : isEmpty(data?.metadata);

      if (isMetadataDisabled) {
        setActiveTabIndex(0);
      }
    }
    if (activeTabIndex === 2) {
      if (isEmpty(imagesUrls)) {
        setActiveTabIndex(0);
      }
    }
  }, [activeTabIndex, data?.metadata, focusedChainNode?.metadata, imagesUrls]);

  const llmSidebarTabs = useMemo(() => {
    const disabled = focusedChainNode?.metadata
      ? isEmpty(focusedChainNode?.metadata)
      : isEmpty(data?.metadata);
    const noImages = isEmpty(imagesUrls);
    return [
      {
        value: 'inputs_outputs',
        label: 'Inputs / Outputs',
        component: LLMInputsOutputsTab
      },
      {
        value: 'metadata',
        label: 'Metadata',
        tooltip: disabled ? 'No metadata logged' : false,
        disabled: disabled,
        component: LLMMetadataTab
      },
      {
        value: 'images',
        label: 'Images',
        component: LLMImagesTab,
        tooltip: noImages ? 'No images logged' : false,
        disabled: noImages
      },
      {
        value: 'details',
        label: 'Details',
        component: LLMDetailsTab
      }
    ];
  }, [data, focusedChainNode]);

  const ActiveTab = llmSidebarTabs[activeTabIndex].component;

  const defaultChainsViewWidth = useMemo(() => {
    const [windowWidth] = size;
    return windowWidth - 330 - DEFAULT_CHAIN_DETAILS_WIDTH;
  }, [size]);

  function expendAll() {
    setExpandedChainNodes([
      chainRootIndex,
      ...chainNodes.map((chain: LLMChainNode) => chain.id)
    ]);
  }

  const toggle = () => {
    if (chainNodes.length > expandedChainNodes.length) {
      expendAll();
    } else {
      setExpandedChainNodes([]);
    }
  };

  const mapLLMChainToTree = useCallback(
    (
      accumulator: Record<string, TreeItem<LLMChainNode>>,
      chain: LLMChainNode
    ) => {
      const spanColor =
        labelColorMap[chain.category] ||
        CHAIN_COLORS[toNumber(chain.id) % CHAIN_COLORS.length];
      labelColorMap[chain.category] = spanColor;

      const newItem = {
        [chain.id]: {
          canMove: false,
          canRename: false,
          data: {
            ...chain,
            spanColor,
            maxStartTime: data?.start_timestamp,
            maxEndTime: data?.end_timestamp,
            maxDuration: data?.chain_duration,
            childrenIncludingIndirect: []
          },
          isFolder: true,
          index: chain.id,
          children: []
        }
      };

      const directParentKey = last(chain.parent_ids);

      if (!directParentKey) {
        accumulator.chainRoot.children?.push(chain.id);
      } else {
        const directParentSpan = accumulator[directParentKey];
        directParentSpan.children?.push(chain.id);
        chain.parent_ids.forEach(parentId => {
          const parentSpan = accumulator[parentId];
          parentSpan.data.childrenIncludingIndirect = union(
            parentSpan.data.childrenIncludingIndirect,
            [chain.id]
          );
        });
      }

      return { ...accumulator, ...newItem };
    },
    [data, labelColorMap]
  );

  // @ts-expect-error TODO should be fixed return type
  const chainsTreeData = chainNodes.reduce(mapLLMChainToTree, {
    root: {
      canMove: false,
      canRename: false,
      children: [chainRootIndex],
      isFolder: true
    },
    chainRoot: {
      canMove: false,
      canRename: false,
      index: chainRootIndex,
      isFolder: true,
      children: [],
      data: {
        ...data,
        childrenIncludingIndirect: chainNodes,
        category: experiment?.Name,
        duration: data?.chain_duration,
        name: 'Chain'
      }
    }
  }) as Record<string, TreeItem<LLMChainNode>>;

  const [resizeActive, setResizeActive] = useState(false);

  const renderChainDetailsView = () => {
    if (!experiment) return null;
    return (
      <Resizable
        style={{
          borderLeft: resizeActive ? '1px solid #B3B9C8' : '1px solid #E2E2E2'
        }}
        onResizeStop={() => setResizeActive(false)}
        onResizeStart={() => setResizeActive(true)}
        minWidth={350}
        minHeight="100%"
        defaultSize={{
          width: DEFAULT_CHAIN_DETAILS_WIDTH + 'px',
          height: '100%'
        }}
        enable={RESIZEABLE_POSITIONS}
      >
        <div className={styles.header}>
          <div className={styles.titleRow}>
            <div className={styles.title}>
              <span className={styles.bold}>Prompt run analysis | </span>
              {experiment?.Name}
            </div>
          </div>
          <div className={styles.metadataRow}>
            <p>
              <span>Timestamp: </span>
              {formatDateTime(experiment?.[GRID_COLUMNS.TIMESTAMP] || 'NA')}
            </p>
            <p>
              <span>Duration: </span>
              {data?.chain_duration ?? 'NA'}
              {!!data?.chain_duration && ' ms'}
            </p>
          </div>
          <SectionTabs
            tabHeight={30}
            variant="secondary"
            activeTabIndex={activeTabIndex}
            tabs={llmSidebarTabs}
            onTabChange={setActiveTabIndex}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.sidebarBodyWrapper}>
            <ActiveTab
              images={imagesUrls}
              data={data as LLMAsset}
              selectedChainNode={focusedChainNode}
              experiment={experiment}
            />
          </div>
        </div>
      </Resizable>
    );
  };

  const renderChainView = () => {
    const isAllExpended = chainNodes.length > expandedChainNodes.length;
    return (
      <Resizable
        style={{
          borderLeft: resizeActive ? '1px solid #B3B9C8' : '1px solid #E2E2E2'
        }}
        onResizeStop={() => setResizeActive(false)}
        onResizeStart={() => setResizeActive(true)}
        minWidth={350}
        minHeight="100%"
        defaultSize={{ width: defaultChainsViewWidth, height: '100%' }}
        enable={RESIZEABLE_POSITIONS}
      >
        <div className={styles.header}>
          <div className={styles.titleRow}>
            <div className={styles.title}>
              <span className={styles.bold}>Chain trace</span>
            </div>
          </div>
          <div className={styles.metadataRow}>
            <p>{chainNodes.length} spans</p>
          </div>
          <StyledTooltip
            placement="top"
            type="type-2"
            title={isAllExpended ? 'Expand all' : 'Collapse all'}
          >
            <div className={styles.collapseContainer}>
              <Button
                onClick={toggle}
                type={BUTTON_TYPES.textOnly}
                size={'small'}
                className={styles.collapseButtonWrapper}
              >
                {isAllExpended ? (
                  <PlusIcon viewBox="0 0 17 16" height="14" width="13" />
                ) : (
                  <MinusIcon viewBox="0 0 17 16" height="14" width="13" />
                )}
              </Button>
            </div>
          </StyledTooltip>
        </div>
        <ControlledTreeEnvironment
          items={chainsTreeData}
          onRegisterTree={() => {
            expendAll();
          }}
          onFocusItem={item => setFocusedChainNodeIndex(item.index)}
          viewState={{
            ['tree-1']: {
              focusedItem: focusedChainNodeIndex,
              expandedItems: expandedChainNodes,
              selectedItems: selectedChainNodes
            }
          }}
          onExpandItem={item =>
            setExpandedChainNodes(prev => [...prev, item.index])
          }
          onCollapseItem={item =>
            setExpandedChainNodes(
              expandedChainNodes.filter(
                expandedItemIndex => expandedItemIndex !== item.index
              )
            )
          }
          onSelectItems={items => setSelectedChainNodes(items)}
          renderDepthOffset={chainRenderers.renderDepthOffset}
          renderTreeContainer={chainRenderers.renderTreeContainer}
          renderItemsContainer={chainRenderers.renderItemsContainer}
          renderItem={chainRenderers.renderItem}
          renderItemArrow={chainRenderers.renderItemArrow}
          getItemTitle={item => item.data.name}
        >
          <Tree treeId="tree-1" rootItem={'root'} treeLabel="Tree Example" />
        </ControlledTreeEnvironment>
      </Resizable>
    );
  };

  const renderSidebarContent = () => {
    if (isError)
      return (
        <div className={styles.errorContainer}>
          <InfoBox title="Error" text="Error fetching Prompt detail" />
        </div>
      );
    if (isLoading)
      return (
        <div className={styles.loaderContainer}>
          <SmallLoader isFlexDisplay />
        </div>
      );
    if (!data || !experiment) return null;

    return (
      <>
        {chainNodes.length !== 0 &&
          data.category !== LLM_CATEGORY_SINGLE_PROMPT &&
          renderChainView()}
        {renderChainDetailsView()}
      </>
    );
  };

  return (
    <Dialog
      className={styles.llmDashboardSidebar}
      data-test="llm-sidebar"
      fullScreen
      open={Boolean(experiment)}
      TransitionComponent={Transition}
      onClose={onClose}
      hideBackdrop
    >
      <div className={styles.sidebarContainer}>{renderSidebarContent()}</div>
      <div className={styles.footer}>
        <TextButton onClick={onClose} className={styles.closeChainButton}>
          Close
        </TextButton>
        <div className={styles.navigation}>
          <TextButton
            onClick={() => onExperimentChange(-1)}
            PrefixIcon={<DSArrowLeftIcon />}
            disabled={!hasPrevious}
          >
            Previous
          </TextButton>
          <Divider
            className={styles.divider}
            orientation={DIVIDER_ORIENTATIONS.vertical}
          />
          <TextButton
            className={styles.nextButton}
            onClick={() => onExperimentChange(1)}
            PostfixIcon={<DSArrowRightIcon />}
            disabled={!hasNext}
          >
            Next
          </TextButton>
        </div>
      </div>
    </Dialog>
  );
};

export default LLMDashboardPageSidebar;

const Transition = React.forwardRef(function Transition(
  props: SlideProps,
  ref
) {
  return <Slide direction="left" ref={ref} {...props} />;
});
