import React from 'react';
import { Box } from '@material-ui/core';

import { Checkbox } from '@DesignSystem/controllers';
import classNames from './SegmentsPopover.module.scss';

type SelectedSegmentProps = {
  title: string;
  value: string;
  checkHandler: (groupId: string, id: string, checked: boolean) => void;
  groupId: string;
  id: string;
  isDisabled: boolean;
};

export const SelectedSegment = ({
  title,
  value,
  checkHandler,
  groupId,
  id,
  isDisabled = false
}: SelectedSegmentProps) => {
  const handleChange = (_: Event, checked: boolean) => {
    checkHandler(groupId, id, checked);
  };
  return (
    <Box className={classNames.selectedValueContainer}>
      <Checkbox
        checked
        onChange={handleChange}
        disabled={isDisabled}
        data-test={`selected-segment-checkbox-${title}`}
      />
      <span className={classNames.titleText}>{title} </span> &nbsp;/&nbsp;
      <span className={classNames.valueText}>{value}</span>
    </Box>
  );
};
