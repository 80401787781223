/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CircularProgress from '@design-system-outdated/components/CircularProgress';
import './Button.scss';
import { noop } from 'lodash';

export const BUTTON_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  actionsPanel: 'actions-panel',
  text: 'text',
  text2: 'text-2',
  text3: 'text-3',
  text4: 'text-4',
  text5: 'text-5',
  text6: 'text-6',
  text7: 'text-7',
  textPrimary: 'text-primary',
  ghost: 'ghost',
  menu: 'menu',
  table: 'table-action',
  textOnly: 'text-only',
  outlined: 'outlined',
  outlinedRounded: 'outlined-rounded',
  outlinedPrimary: 'outlined-primary',
  outlined1: 'outlined-1',
  link: 'link',
  warning: 'warning',
  burgundy: 'burgundy'
};

export const BUTTON_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large'
};

const DIFFERENCE_BETWEEN_LOADER_AND_FONT_SIZE = 5;

const Button = (props, buttonRef) => {
  const {
    active,
    children,
    type,
    PrefixIcon,
    PostfixIcon,
    size,
    light,
    text,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    disabled,
    withoutPadding,
    fontSize,
    width,
    isLoading,
    style,
    color,
    dataTest,
    formType
  } = props;

  const handleMouseOver = ev => {
    if (type === BUTTON_TYPES.outlined && buttonRef?.current?.style && color) {
      buttonRef.current.style.backgroundColor = color;
      buttonRef.current.style.color = 'white';
    }

    onMouseEnter(ev);
  };

  const handleMouseOut = ev => {
    if (type === BUTTON_TYPES.outlined && buttonRef?.current?.style && color) {
      buttonRef.current.style.backgroundColor = 'white';
      buttonRef.current.style.color = color;
    }

    onMouseLeave(ev);
  };

  return (
    <button
      className={cx(
        'ds-button',
        BUTTON_TYPES[type],
        BUTTON_SIZES[size],
        {
          light,
          'without-padding': withoutPadding,
          active,
          disabled
        },
        className
      )}
      ref={buttonRef}
      style={{
        fontSize,
        width,
        ...style,
        color: color || style?.color || null
      }}
      type={formType}
      onClick={onClick}
      disabled={disabled}
      data-test={dataTest}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {isLoading && (
        <CircularProgress
          size={`${
            Number.parseInt(fontSize, 10) +
            DIFFERENCE_BETWEEN_LOADER_AND_FONT_SIZE
          }px`}
          color="white"
        />
      )}
      {PrefixIcon && <PrefixIcon />} {children}
      {text} {PostfixIcon && <PostfixIcon />}
    </button>
  );
};

const ButtonWithRef = React.forwardRef(Button);

ButtonWithRef.propTypes = {
  active: PropTypes.bool,
  PostfixIcon: PropTypes.elementType,
  PrefixIcon: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array
  ]),
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  light: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  formType: PropTypes.string,
  withoutPadding: PropTypes.bool,
  color: PropTypes.string
};

ButtonWithRef.defaultProps = {
  active: false,
  PostfixIcon: null,
  PrefixIcon: null,
  children: null,
  className: '',
  dataTest: null,
  disabled: false,
  fontSize: '13px',
  isLoading: false,
  light: false,
  onClick: noop,
  size: '',
  text: '',
  type: BUTTON_TYPES.primary,
  formType: 'button',
  withoutPadding: false,
  color: null,
  onMouseEnter: noop,
  onMouseLeave: noop
};

export default ButtonWithRef;
