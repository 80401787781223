import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Box } from '@material-ui/core';
import { getChartNameByText } from '@shared/utils';
import classNames from './ColorBox.module.scss';
import { capitalizeFirstLetter } from '../../../helpers/generalHelpers';

const ColorBox = ({
  bgColor,
  text,
  children,
  component,
  filled,
  className,
  ...props
}) => {
  const nameColor = bgColor || getChartNameByText(text);

  return (
    <Box
      className={cx(
        className,
        filled &&
          classNames[`dsColorBoxFilled${capitalizeFirstLetter(nameColor)}`],
        !filled && classNames[`dsColorBox${capitalizeFirstLetter(nameColor)}`]
      )}
      sx={{ bgcolor: bgColor }}
      component={component}
      {...props}
    >
      <Box display="flex" alignItems="center">
        {children || text}
      </Box>
    </Box>
  );
};

ColorBox.defaultProps = {
  bgColor: null,
  component: 'div',
  className: '',
  filled: false,
  children: null,
  text: ''
};

ColorBox.propTypes = {
  bgColor: PropTypes.string,
  className: PropTypes.string,
  filled: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.oneOf([PropTypes.elementType, PropTypes.string]),
  text: PropTypes.string
};

export default ColorBox;
