import React from 'react';
import { push } from 'connected-react-router';
import userApi from '../util/userApi';
import { userActionTypes } from '@/constants/actionTypes';
import alertsUtil from '../util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import LoginModal from '@auth/components/LoginModal/LoginModal';

const loginActions = {
  logout() {
    return dispatch => {
      return userApi.logout().then(() => {
        dispatch({
          type: userActionTypes.LOGGED_OUT_USER
        });
        const randomCacheNumber = Math.floor(1e8 * Math.random());
        window.location.href = `${window.location.origin}?cache=${randomCacheNumber}`;
      });
    };
  },

  openLoginModal() {
    const isIframed = window.location !== window.parent.location;
    return dispatch => {
      dispatch(
        alertsUtil.openCustomModal(
          dialogTypes.LOGIN_MODAL,
          <LoginModal
            showRegister={false}
            showLogin
            open
            DialogProps={{
              disableAutoFocus: isIframed,
              disableEnforceFocus: isIframed
            }}
            onClose={() => {
              dispatch(loginActions.closeLoginModal());
            }}
          />
        )
      );
      return Promise.resolve();
    };
  },

  closeLoginModal() {
    return dispatch => {
      dispatch(alertsUtil.closeDialog(dialogTypes.LOGIN_MODAL));
      return Promise.resolve();
    };
  },

  openRegistrationModal(config) {
    const { title = false } = config || {};
    return (dispatch, getState) => {
      const { router } = getState();

      dispatch(
        alertsUtil.openCustomModal(
          dialogTypes.REGISTRATION_MODAL,
          <LoginModal
            showRegister
            showLogin={false}
            customTitle={title}
            open
            onClose={() => {
              dispatch(push(`${router.location.pathname}`));
              dispatch(loginActions.closeRegistrationModal());
            }}
          />
        )
      );
      return Promise.resolve();
    };
  },

  closeRegistrationModal() {
    return dispatch => {
      dispatch(alertsUtil.closeDialog(dialogTypes.REGISTRATION_MODAL));
      return Promise.resolve();
    };
  }
};

export default loginActions;
