import { useQuery } from 'react-query';
import modelRegistryApi from '../../util/modelRegistryApi';

const getModelRegistryList = async (workspace, signal) => {
  const { data } = await modelRegistryApi.getAllWorkspaceModels(
    workspace,
    signal
  );

  return data?.registryModels;
};

const useModelRegistryList = workspaceName =>
  useQuery(
    ['modelRegistryList', { workspaceName }],
    ({ signal }) => getModelRegistryList(workspaceName, signal),
    {
      enabled: !!workspaceName,
      refetchOnMount: 'always'
    }
  );

export default useModelRegistryList;
