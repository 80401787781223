import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import uniq from 'lodash/uniq';

import {
  PINNED_LEFT_MODEL_REGISTRY_COLUMNS,
  PINNED_MODEL_REGISTRY_COLUMNS,
  PINNED_RIGHT_MODEL_REGISTRY_COLUMNS
} from '@API/model-registry/useModelRegistryView';
import { useSelector } from 'react-redux';
import ReactGrid from '@experiment-management-shared/components/ReactGrid';
import { SPECIAL_CELL_COMPONENTS } from '@experiment-management-shared/components/TableCells';
import { TABLE_PAGE_SIZES } from '@experiment-management-shared/constants/experimentGridConstants';
import { transformColumnNameToColumn } from '@experiment-management-shared/utils/reactGridHelpers';

import './ModelVersionsGrid.scss';
import { isBetaFeatureEnabled } from '@/reducers/betaFeaturesReducer';
import { BETA_FEATURE_MODEL_REGISTRY_CARD_DETAILS_V2 } from '@/lib/betaFeatures';
import { formatAndFilterDataForGrid } from '@model-registry/utils/utils';

const messages = {
  empty_data: 'No data found',
  empty_search: 'There are no matching versions for this search'
};

const MODEL_VERSIONS_GRID_TABLE_PAGE_SIZES = [5, ...TABLE_PAGE_SIZES];
const FIXED_VERSION_COLUMN_WIDTH = 90;
const FIXED_COLUMNS_WIDTH = 180;

const FIXED_TABLE_CELL_NAME = 'Version';

const getColumnOrders = selectedColumns =>
  uniq([
    ...PINNED_LEFT_MODEL_REGISTRY_COLUMNS,
    ...selectedColumns,
    ...PINNED_RIGHT_MODEL_REGISTRY_COLUMNS
  ]);

const ModelVersionsGrid = props => {
  const {
    workspace,
    isAdminOrWsOwner,
    isUserAMemberOfWorkspace,
    model,
    gridData = [],
    availableColumns,
    selectedColumns,
    handleRowMenuClick,
    isLoading,
    isRowMenuOpened,
    searchValue
  } = props;

  const isBetaModelRegistryDetailsPageEnabled = useSelector(state => {
    return isBetaFeatureEnabled(state, {
      featureName: BETA_FEATURE_MODEL_REGISTRY_CARD_DETAILS_V2
    });
  });

  const [columnOrders, setColumnOrders] = useState(
    getColumnOrders(selectedColumns)
  );

  useEffect(() => {
    if (columnOrders?.length !== selectedColumns?.length) {
      setColumnOrders(getColumnOrders(selectedColumns));
    }
  }, [selectedColumns]);

  const defaultColumnWidth = useMemo(
    () =>
      columnOrders.map((columnName, index) => ({
        columnName,
        width: index === 0 ? FIXED_VERSION_COLUMN_WIDTH : FIXED_COLUMNS_WIDTH
      })),
    [columnOrders]
  );

  const REACT_GRID_DEFAULT = useMemo(
    () => ({
      selection: [],
      pageSize: TABLE_PAGE_SIZES[3],
      pageNumber: 0,
      columnSorting: [{ columnName: 'CreatedAt', direction: 'desc' }],
      columnOrders,
      columnWidths: defaultColumnWidth,
      columnGrouping: []
    }),
    [defaultColumnWidth, columnOrders]
  );

  const { versions: modelVersions, modelName } = model;
  const filteredAndFormattedGridData = useMemo(
    () =>
      formatAndFilterDataForGrid({
        gridData,
        modelName,
        modelVersions,
        searchedValue: searchValue,
        workspace,
        ifDetailsPageEnabled: isBetaModelRegistryDetailsPageEnabled,
        isAdminOrWsOwner,
        isUserAMemberOfWorkspace
      }),
    [
      gridData,
      isBetaModelRegistryDetailsPageEnabled,
      modelName,
      modelVersions,
      searchValue,
      workspace
    ]
  );

  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 0,
    pageSizes: MODEL_VERSIONS_GRID_TABLE_PAGE_SIZES,
    pageSize: MODEL_VERSIONS_GRID_TABLE_PAGE_SIZES[0]
  });
  const [columnWidths, setColumnWidths] = useState([
    ...defaultColumnWidth.filter(({ columnName }) =>
      [...PINNED_MODEL_REGISTRY_COLUMNS, ...selectedColumns].includes(
        columnName
      )
    )
  ]);
  const [columnSorting, setColumnSorting] = useState(
    REACT_GRID_DEFAULT.columnSorting
  );
  const reactGrid = useMemo(() => {
    return {
      ...REACT_GRID_DEFAULT,
      columnWidths,
      columnSorting,
      columnOrders,
      ...paginationConfig
    };
  }, [columnSorting, columnWidths, paginationConfig, columnOrders]);

  const updateColumnOrders = newColOrder =>
    setColumnOrders(getColumnOrders(newColOrder));

  return (
    <ReactGrid
      loadingMessage="Fetching model versions"
      id="model-registry-grid"
      hasRowDetail
      allowColumnReordering
      onColumnWidthsChange={setColumnWidths}
      reactGridClass={['model-registry-grid']}
      reactGrid={reactGrid}
      rows={filteredAndFormattedGridData}
      columns={columnOrders.map(transformColumnNameToColumn)}
      cellsMap={SPECIAL_CELL_COMPONENTS}
      availableColumns={availableColumns}
      rowActionMenu={{
        onClick: handleRowMenuClick,
        isVisible: true,
        isOpened: isRowMenuOpened
      }}
      pageSizes={paginationConfig.pageSizes}
      onPageSizeChange={pageSize =>
        setPaginationConfig(config => ({
          ...config,
          pageSize
        }))
      }
      onCurrentPageChange={pageNumber =>
        setPaginationConfig(config => ({
          ...config,
          pageNumber
        }))
      }
      onSortingChange={setColumnSorting}
      onOrderChange={updateColumnOrders}
      columnOrderConfig={{
        isDisabled: false,
        columnOrder: columnOrders,
        onColumnOrderChange: updateColumnOrders
      }}
      emptyMessage={searchValue ? messages.empty_search : messages.empty_data}
      fixedColumnTitle={FIXED_TABLE_CELL_NAME}
      fixedColumnMaxWidth={FIXED_VERSION_COLUMN_WIDTH}
      allowFiltering
      allowSorting
      integratedSortingColumnExtensions={[
        {
          columnName: 'Name',
          compare: (a, b) =>
            a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
        }
      ]}
      isLoadingExperimentsData={isLoading}
      allowPagination
      resizingEnabled
      {...props}
    />
  );
};

ModelVersionsGrid.propTypes = {
  workspace: PropTypes.string.isRequired,
  availableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchValue: PropTypes.string,
  isAdminOrWsOwner: PropTypes.bool.isRequired,
  isUserAMemberOfWorkspace: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  gridData: PropTypes.arrayOf(PropTypes.object),
  handleRowMenuClick: PropTypes.func.isRequired,
  isRowMenuOpened: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  columnsActions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      action: PropTypes.func
    })
  )
};

ModelVersionsGrid.defaultProps = {
  searchValue: '',
  columnsActions: [],
  gridData: []
};

export default ModelVersionsGrid;
