import React from 'react';
import { Organization } from '@shared/types';
import UserMenuOrganizationItem from './UserMenuOrganizationItem';

type UserMenuOrganizationSelectItemProps = {
  organization: Organization;
  currentOrganizationName: string;
  onSelect: (organization: Organization) => void;
};
const UserMenuOrganizationSelectItem: React.FC<UserMenuOrganizationSelectItemProps> = ({
  organization,
  currentOrganizationName,
  onSelect
}) => {
  const isActive = organization.name === currentOrganizationName;
  const handleSelect = () => {
    onSelect(organization);
  };

  return (
    <UserMenuOrganizationItem
      isActive={isActive}
      title={organization.name}
      avatarLetters={organization.name[0]}
      avatarUrl={organization.logoUrl}
      onClick={handleSelect}
      dataTestId="user-menu-organization-select-item"
    />
  );
};

export default UserMenuOrganizationSelectItem;
