import { useDispatch } from 'react-redux';
import useProjectTags from '@API/project/useProjectTags';

import useUpdateTagsMutation from '@API/experiments/useUpdateTagsMutation';
import useRemoveProjectTagMutation from '@API/project/useRemoveProjectTagMutation';
import { useParams } from 'react-router';

import alertsUtil from '@/util/alertsUtil';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';

const useExperimentTagActions = ({
  closeDeleteModal,
  isTagSelected,
  searchValue,
  setSearchValue
}) => {
  const dispatch = useDispatch();
  const { experimentKey } = useParams();
  const updateTagsMutation = useUpdateTagsMutation();
  const removeProjectTagMutation = useRemoveProjectTagMutation();

  const { data: tags } = useProjectTags();

  const handleRemoveTag = async tagName => {
    try {
      await removeProjectTagMutation.mutateAsync({ tagName });
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_DELETE_TAG_FROM_PROJECT,
          'Successfully deleted tag from your project'
        )
      );
    } catch {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_DELETE_TAG_FROM_PROJECT,
          'There was an error deleting a tag from your project.'
        )
      );
    }

    closeDeleteModal();
  };

  const handleUpdateTags = async tagUpdates => {
    try {
      await updateTagsMutation.mutateAsync({ tagUpdates });
    } catch {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_UPDATING_TAGS_FOR_EXPERIMENT,
          'There was an updating tags associated with your experiment.'
        )
      );
    }
  };

  const getTagUpdates = tagName => {
    const isSelected = isTagSelected(tagName);

    const addedTags = isSelected ? [] : [tagName];
    const deletedTags = isSelected ? [tagName] : [];

    return [
      {
        experimentId: experimentKey,
        addedTags,
        deletedTags
      }
    ];
  };

  const applySelectedTag = tagName => {
    const tagUpdates = getTagUpdates(tagName);

    handleUpdateTags(tagUpdates);
  };

  const handleCreateTagForProject = () => {
    if (!searchValue) return;

    if (!tags.find(tag => tag.name === searchValue)) {
      setSearchValue('');
    }

    applySelectedTag(searchValue);
  };

  return {
    applySelectedTag,
    handleCreateTagForProject,
    handleRemoveTag
  };
};

export default useExperimentTagActions;
