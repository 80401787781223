import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import first from 'lodash/first';

import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import {
  CustomGrouping,
  CustomPaging,
  CustomSummary,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SelectionState,
  SortingState,
  SummaryState,
  TableSummaryRow
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  TableColumnReordering,
  TableColumnResizing,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import Tooltip from '@material-ui/core/Tooltip';

import useGridSummary from '@experiment-management-shared/hooks/useGridSummary';
import { ReactGridContext } from '@experiment-management-shared/components/ReactGrid/reactGridContext';
import { formatNameForDisplay } from '@shared/utils/displayHelpers';

import { normalizeColumnName } from '@API/helpers/v2_helpers';
import {
  getNameColumnWidth,
  MIN_COLUMN_NAME_WIDTH
} from '@experiment-management-shared/utils/EMView';
import { groupsMapToExperimentsGroups } from '@experiment-management-shared/utils';
import {
  COLUMNS_DISABLED_DRAG,
  COLUMNS_DISABLED_DRAG_AND_RESIZE,
  COLUMNS_DISABLED_SORTING,
  TABLE_PAGE_SIZES,
  COLUMNS_DISABLED_FOR_EXPORT,
  GRID_COLUMNS
} from '../../constants/experimentGridConstants';
import { getRowValue, getRowValueExport } from '@/util/metadataUtil';
import { sortedRows } from './sortedRows';

import SmallLoader from '@shared/components/SmallLoader';
import GroupCellComponent from './GroupCellComponent';
import SelectAllBtn from './SelectAllBtn';
import SelectionCell from './SelectionCell';
import TableCellTemplate from './TableCellTemplate';
import EmptyMessage from './EmptyMessage';
import Pagination from './Pagination';
import ReactGridRow from './Row';
import RowView from './RowView';
import CustomTableHeaderRow from './CustomTableHeaderRow';
import SortLabel from './SortLabel';
import StubHeaderCell from './StubHeaderCell';
import FixedColumnCell from './FixedColumnsCell';

const DEFAULT_COLUMN_WIDTHS = [
  {
    columnName: GRID_COLUMNS.RUN_ACTIVE,
    width: 80
  },
  {
    columnName: GRID_COLUMNS.PINNED,
    width: 1
  },
  {
    columnName: GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME,
    align: 'right',
    width: 25
  }
];
const LEFT_COLUMNS = [
  TableSelection.COLUMN_TYPE,
  GRID_COLUMNS.PINNED,
  GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
  GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD,
  GRID_COLUMNS.NAME,
  TableGroupRow.COLUMN_TYPE
];
const MIN_COLUMN_WIDTH = 100;
const PINNED_EXPERIMENT_SUFFIX = '-pinned-group';

const removePinnedSuffix = key => key.replace(PINNED_EXPERIMENT_SUFFIX, '');
const getNewSelection = (newFullSelection, fullSelection) => {
  const removedKeys = difference(fullSelection, newFullSelection).map(
    removePinnedSuffix
  );

  return uniq(
    newFullSelection
      .map(removePinnedSuffix)
      .filter(key => !removedKeys.includes(key))
  );
};

const getRowId = ({ experimentKey, isInPinnedGroup = false, rowData = {} }) =>
  `${rowData?.version ? rowData?.version : ''}${experimentKey}${
    isInPinnedGroup ? PINNED_EXPERIMENT_SUFFIX : ''
  }`;

const groupingTags = (_, row) => {
  const tags = get(row, 'tags', []);
  const key = tags.slice().sort().join('');

  return { key, value: tags };
};

const selectCheckboxDataId = 'selection-checkbox';

const tableGroupColumnExtension = [
  { columnName: GRID_COLUMNS.NAME, showWhenGrouped: true }
];

const ReactGrid = React.forwardRef(
  (
    {
      availableColumns,
      allowColumnReordering,
      allowFiltering,
      allowPagination,
      allowSelection,
      allowSorting,
      columns: displayColumns,
      contextAdditionalData,
      cellsMap,
      columnsActions,
      decimalsPrecision,
      displayGroups,
      experimentsGroupsMap,
      experimentsForExport,
      exportHandler,
      emptyMessage,
      integratedSortingColumnExtensions,
      isArchive,
      isLoadingExperimentsData,
      fixedColumnTitle = 'Name',
      fixedColumnMaxWidth = MIN_COLUMN_NAME_WIDTH,
      leftFooterSide,
      onColumnWidthsChange,
      onCurrentPageChange,
      onExpandedGroupsChange,
      onHoverExperimentNameCell,
      rowActionMenu: {
        isVisible: ifRowActionMenuVisible,
        isOpened: ifRowActionMenuOpened,
        onClick: onClickRowActionMenu
      },
      onOrderChange,
      onPageSizeChange,
      onSelectionChange,
      onSortingChange,
      onTogglePinned,
      onToggleVisibility,
      openLinksInNewTab,
      pageSizes,
      pageTotalGrouped,
      pinnedExperimentKeys,
      reactGrid,
      resizingEnabled,
      renderNoDataCell,
      rows,
      shouldResizePaginationWidth,
      shouldUseServerSidePagination,
      totalExperiments,
      groupAggregations,
      rightColumns: parentRightColumns,
      headerRowComponents,
      hideSortingNotAvailable,
      loadingMessage
    },
    ref
  ) => {
    const {
      columnGrouping: allColumnGrouping,
      columnOrders,
      columnSorting,
      columnWidths,
      displayColumnOrders,
      expandedGroups,
      pageNumber: controlledPageNumber,
      pageSize,
      selection
    } = reactGrid;

    const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);
    const [pageNumber, setPageNumber] = useState(controlledPageNumber || 0);

    useEffect(() => {
      // reset pagination when user switch between experiments page and archive
      if (controlledPageNumber !== 0) {
        onCurrentPageChange(0);
      }
    }, [isArchive]);

    useEffect(() => {
      setPageNumber(controlledPageNumber);
    }, [controlledPageNumber]);

    const columnGrouping = useMemo(() => {
      return displayGroups ?? allColumnGrouping;
    }, [allColumnGrouping, displayGroups]);

    const columns = useMemo(() => {
      return uniqBy(
        [
          { name: GRID_COLUMNS.PINNED, title: GRID_COLUMNS.PINNED },
          { name: GRID_COLUMNS.NAME, title: fixedColumnTitle },
          ...columnGrouping.map(name => ({
            name,
            title: formatNameForDisplay(name)
          })),
          ...displayColumns.filter(column => {
            return ![
              GRID_COLUMNS.PINNED,
              GRID_COLUMNS.SINGLE_EXPERIMENT_BTN,
              GRID_COLUMNS.IS_VISIBLE_ON_DASHBOARD,
              GRID_COLUMNS.NAME,
              GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME
            ].includes(column.name);
          }),
          ifRowActionMenuVisible && {
            name: GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME,
            title: ' '
          }
        ].filter(Boolean),
        col => col.name
      ).map(col => {
        const { keyName } = normalizeColumnName(col.name);
        const cellColumn = availableColumns?.find(
          column => column.name === keyName
        );

        if (cellColumn) {
          return {
            ...col,
            type: cellColumn.type
          };
        }

        return col;
      });
    }, [
      availableColumns,
      columnGrouping,
      displayColumns,
      ifRowActionMenuVisible
    ]);

    const popoverColumns = useMemo(() => {
      return (columnOrders || [])
        .map(columnName => {
          const { keyName } = normalizeColumnName(columnName);
          const cellColumn = availableColumns?.find(
            column => column.name === keyName
          );

          if (cellColumn) {
            return {
              name: columnName,
              type: cellColumn.type
            };
          }

          return null;
        })
        .filter(Boolean);
    }, [columnOrders, availableColumns]);

    // include the keys as pinned experiments in case the experiments are pinned as well
    const fullSelection = useMemo(() => {
      return [
        ...selection,
        ...selection.map(key => `${key}${PINNED_EXPERIMENT_SUFFIX}`)
      ];
    }, [selection]);

    const [lastToggledExperimentKey, setLastToggledExperimentKey] = useState(
      null
    );

    const [selectionBlock, setSelectionBlock] = useState([]);

    const orderedExperimentKeys = useMemo(() => {
      const orderedExperimentRows = shouldUseServerSidePagination
        ? rows
        : sortedRows(rows, columnSorting);

      return orderedExperimentRows.map(row => row.experimentKey);
    }, [columnSorting, rows, shouldUseServerSidePagination]);

    const getOrderedSelectionRange = useCallback(
      experimentKeyRange => {
        const selectionIndices = experimentKeyRange.map(experimentKey =>
          orderedExperimentKeys.indexOf(experimentKey)
        );
        const startIndex = Math.min(...selectionIndices);
        const endIndex = Math.max(...selectionIndices);

        return orderedExperimentKeys.slice(startIndex, endIndex + 1);
      },
      [orderedExperimentKeys]
    );

    useEffect(() => {
      if (!isEmpty(selectionBlock)) {
        const newSelectionBlock = getOrderedSelectionRange(selectionBlock);
        onSelectionChange(uniq([...selection, ...newSelectionBlock]));

        setSelectionBlock([]);
      }
    }, [
      selectionBlock,
      selection,
      orderedExperimentKeys,
      onSelectionChange,
      getOrderedSelectionRange
    ]);

    useEffect(() => {
      const handleKeyDown = event => {
        if (event.key === 'Shift') {
          setIsShiftKeyPressed(true);
        } else {
          setIsShiftKeyPressed(false);
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
      const handleClick = event => {
        if (get(event, 'target.dataset.id', '') !== selectCheckboxDataId) {
          setLastToggledExperimentKey(null);
        }
      };

      window.addEventListener('click', handleClick);

      return () => window.removeEventListener('click', handleClick);
    }, []);

    useEffect(() => {
      const handleKeyUp = event => {
        if (event.key === 'Shift') {
          setIsShiftKeyPressed(false);
        }
      };

      window.addEventListener('keyup', handleKeyUp);

      return () => window.removeEventListener('keyup', handleKeyUp);
    }, []);

    const getChildGroups = useCallback(groups => {
      return groups || [];
    }, []);

    const handleSelectAll = useCallback(
      newSelection => {
        setLastToggledExperimentKey(null);
        onSelectionChange(newSelection);
      },
      [onSelectionChange]
    );

    const handleSelectionChange = useCallback(
      newFullSelection => {
        const newSelection = getNewSelection(newFullSelection, fullSelection);
        const isChecked = newSelection.length > selection.length;

        const newlySelectedExperimentKey = first(
          difference(newSelection, selection)
        );

        const newlyDeselectedExperimentKey = first(
          difference(selection, newSelection)
        );

        if (isShiftKeyPressed) {
          if (isChecked) {
            if (isEmpty(selection)) {
              // If no current selection, select all rows from the
              // beginning of table to the new row selected
              setSelectionBlock([
                first(orderedExperimentKeys),
                newlySelectedExperimentKey
              ]);
            } else {
              setSelectionBlock([
                lastToggledExperimentKey,
                newlySelectedExperimentKey
              ]);
            }

            setLastToggledExperimentKey(newlySelectedExperimentKey);
          } else {
            const experimentKeysToDeselect = getOrderedSelectionRange([
              lastToggledExperimentKey,
              newlyDeselectedExperimentKey
            ]);

            onSelectionChange(
              selection.filter(
                expKey => !experimentKeysToDeselect.includes(expKey)
              )
            );

            setLastToggledExperimentKey(newlyDeselectedExperimentKey);
          }

          return;
        }

        if (isEmpty(newSelection)) {
          setLastToggledExperimentKey(null);
        } else {
          setLastToggledExperimentKey(
            isChecked
              ? newlySelectedExperimentKey
              : newlyDeselectedExperimentKey
          );
        }

        onSelectionChange(newSelection);
      },
      [
        getOrderedSelectionRange,
        isShiftKeyPressed,
        onSelectionChange,
        orderedExperimentKeys,
        selection,
        lastToggledExperimentKey
      ]
    );

    const columnsForExport = useMemo(() => {
      return columns.filter(
        column => !COLUMNS_DISABLED_FOR_EXPORT.includes(column.name)
      );
    }, [columns]);

    const normalizedGroupingColumns = useMemo(() => {
      return columnGrouping.map(columnName => ({ columnName }));
    }, [columnGrouping]);

    const isGroupingApplied = !!normalizedGroupingColumns?.length;

    const {
      columnsForSummary,
      isWithSummary,
      renderSummaryCellComponent
    } = useGridSummary({
      columns,
      columnGrouping,
      aggregations: groupAggregations,
      decimalsPrecision
    });

    const handleCurrentPageChange = pageNumber => {
      setPageNumber(pageNumber);

      onCurrentPageChange(pageNumber);
    };

    const renderHeaderRow = useCallback(
      headerRowProps => {
        const { column } = headerRowProps;

        let updatedHeaderRowProps = headerRowProps;

        if (COLUMNS_DISABLED_DRAG.includes(column.name)) {
          updatedHeaderRowProps = {
            ...updatedHeaderRowProps,
            draggingEnabled: false
          };
        }

        if (COLUMNS_DISABLED_DRAG_AND_RESIZE.includes(column.name)) {
          updatedHeaderRowProps = {
            ...updatedHeaderRowProps,
            resizingEnabled: false,
            draggingEnabled: false
          };
        }

        const disableTooltip = allowSorting || hideSortingNotAvailable;
        const component = headerRowComponents[column.name];

        return (
          <Tooltip
            title="Sorting is not available"
            placement="top"
            disableFocusListener={disableTooltip}
            disableHoverListener={disableTooltip}
          >
            <TableHeaderRow.Cell
              className={classNames('react-grid-header-cell', column.name)}
              {...updatedHeaderRowProps}
            >
              {component?.prefix}
              {updatedHeaderRowProps.children}
              {component?.suffix}
            </TableHeaderRow.Cell>
          </Tooltip>
        );
      },
      [allowSorting, headerRowComponents]
    );

    const renderGridRootComponent = useCallback(
      props => {
        const className = classNames({
          'is-archive': isArchive
        });

        return <Grid.Root {...props} className={className} />;
      },
      [isArchive]
    );

    const renderSelectAllComponent = useCallback(
      headerCellProps => {
        const { allSelected, someSelected, ...cellProps } = headerCellProps;

        return (
          <TableHeaderRow.Cell
            {...cellProps}
            className={classNames(
              headerCellProps.className,
              'react-grid-select-all-cell'
            )}
          >
            <SelectAllBtn
              experimentKeys={orderedExperimentKeys}
              isArchivePage={isArchive}
              onSelectionChange={handleSelectAll}
              pageNumber={pageNumber}
              pageSize={pageSize}
              selection={selection}
              shouldUseServerSidePagination={shouldUseServerSidePagination}
            />
          </TableHeaderRow.Cell>
        );
      },
      [
        handleSelectAll,
        isArchive,
        orderedExperimentKeys,
        pageNumber,
        pageSize,
        selection,
        shouldUseServerSidePagination
      ]
    );

    const renderCell = useCallback(
      props => {
        return (
          <TableCellTemplate
            columns={popoverColumns}
            cellsMap={cellsMap}
            columnsActions={columnsActions}
            decimalsPrecision={decimalsPrecision}
            openLinksInNewTab={openLinksInNewTab}
            onToggleVisibility={onToggleVisibility}
            onHoverExperimentNameCell={onHoverExperimentNameCell}
            onClickRowActionMenu={onClickRowActionMenu}
            onTogglePinned={onTogglePinned}
            pinnedExperimentKeys={pinnedExperimentKeys}
            {...props}
          />
        );
      },
      [
        popoverColumns,
        cellsMap,
        columnsActions,
        decimalsPrecision,
        openLinksInNewTab,
        onToggleVisibility,
        onHoverExperimentNameCell,
        onClickRowActionMenu,
        onTogglePinned,
        pinnedExperimentKeys
      ]
    );

    const normalizeOrder = useMemo(() => {
      return uniq([
        GRID_COLUMNS.PINNED,
        GRID_COLUMNS.NAME,
        ...(displayColumnOrders ?? columnOrders)
      ]);
    }, [columnOrders, displayColumnOrders]);

    const nameColumnWidth = getNameColumnWidth(
      columnWidths,
      fixedColumnMaxWidth
    );
    const normalizeWidths = useMemo(() => {
      return uniqBy(
        [
          { columnName: GRID_COLUMNS.NAME, width: nameColumnWidth },
          ...DEFAULT_COLUMN_WIDTHS,
          ...columnWidths,
          ...columns.map(column => ({
            columnName: column.name,
            width: MIN_COLUMN_WIDTH
          }))
        ],
        column => column.columnName
      );
    }, [columns, columnWidths, nameColumnWidth]);

    const columnsWithWidth = useMemo(() => {
      return columns.map(column => {
        const col = {
          columnName: column.name,
          width:
            normalizeWidths.find(({ columnName }) => column.name === columnName)
              ?.width ?? MIN_COLUMN_WIDTH
        };

        if (column.align) {
          col.align = column.align;
        }

        return col;
      });
    }, [columns, normalizeWidths]);

    const renderPagination = useCallback(
      props => (
        <Pagination
          {...props}
          width={shouldResizePaginationWidth ? nameColumnWidth + 37 : null}
          pageTotalGrouped={pageTotalGrouped}
        />
      ),
      [nameColumnWidth, pageTotalGrouped, shouldResizePaginationWidth]
    );

    const renderSelectionCell = useCallback(
      props => {
        return (
          <SelectionCell
            {...props}
            lastToggledExperimentKey={lastToggledExperimentKey}
            dataId={selectCheckboxDataId}
          />
        );
      },
      [lastToggledExperimentKey]
    );

    const renderTableContainer = useCallback(
      props => (
        <VirtualTable.Container
          className="react-grid-table-container"
          {...props}
        />
      ),
      []
    );

    const renderTable = useCallback(() => {
      return (
        <VirtualTable
          allowColumnReordering={allowColumnReordering}
          containerComponent={renderTableContainer}
          cellComponent={renderCell}
          columnExtensions={columnsWithWidth}
          stubHeaderCellComponent={StubHeaderCell}
          height="100%"
          {...(renderNoDataCell && { noDataCellComponent: renderNoDataCell })}
          {...(ifRowActionMenuVisible && {
            rowComponent: props => (
              <RowView {...props} isActionMenuOpened={ifRowActionMenuOpened} />
            )
          })}
        />
      );
    }, [
      allowColumnReordering,
      columnsWithWidth,
      renderCell,
      ifRowActionMenuOpened,
      ifRowActionMenuVisible,
      renderNoDataCell
    ]);

    const renderEmptyContent = useCallback(() => {
      if (isLoadingExperimentsData) {
        return <SmallLoader primaryMessage="Loading..." />;
      }

      return <EmptyMessage message={emptyMessage} />;
    }, [isLoadingExperimentsData, emptyMessage]);

    const tableData = useMemo(() => {
      if (columnGrouping.length) {
        return groupsMapToExperimentsGroups(
          experimentsGroupsMap,
          experimentsGroupsMap.path,
          rows
        );
      }

      return rows;
    }, [columnGrouping, experimentsGroupsMap, rows]);

    const renderSelectRowComponent = useCallback(
      props => <ReactGridRow {...props} withGrouping={isGroupingApplied} />,
      [isGroupingApplied]
    );

    const rightColumns = useMemo(() => {
      const res = [...parentRightColumns];

      if (ifRowActionMenuVisible) {
        res.push(GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME);
      }

      return res;
    }, [parentRightColumns, ifRowActionMenuVisible]);

    if (isLoadingExperimentsData) {
      return (
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={loadingMessage}
        />
      );
    }

    if (!rows.length && !columnGrouping.length && emptyMessage) {
      return renderEmptyContent();
    }

    return (
      <ReactGridContext.Provider
        value={{
          experimentKeys: orderedExperimentKeys,
          ...contextAdditionalData
        }}
      >
        <Grid
          columns={columns}
          getCellValue={getRowValue}
          getRowId={getRowId}
          rootComponent={renderGridRootComponent}
          rows={tableData}
        >
          <SortingState
            sorting={columnSorting}
            onSortingChange={onSortingChange}
            columnExtensions={COLUMNS_DISABLED_SORTING}
          />

          {allowSelection && (
            <SelectionState
              onSelectionChange={handleSelectionChange}
              selection={fullSelection}
            />
          )}

          {!shouldUseServerSidePagination && (
            <GroupingState grouping={normalizedGroupingColumns} />
          )}

          {shouldUseServerSidePagination && (
            <GroupingState
              grouping={normalizedGroupingColumns}
              expandedGroups={expandedGroups}
              onExpandedGroupsChange={onExpandedGroupsChange}
            />
          )}

          {shouldUseServerSidePagination && (
            <CustomGrouping
              grouping={normalizedGroupingColumns}
              expandedGroups={expandedGroups}
              getChildGroups={getChildGroups}
            />
          )}

          {isWithSummary && <SummaryState groupItems={columnsForSummary} />}
          {isWithSummary && <CustomSummary />}

          {allowPagination && (
            <PagingState
              currentPage={pageNumber}
              onCurrentPageChange={handleCurrentPageChange}
              onPageSizeChange={onPageSizeChange}
              pageSize={pageSize}
            />
          )}

          {allowPagination && shouldUseServerSidePagination && (
            <CustomPaging totalCount={totalExperiments} />
          )}

          {!shouldUseServerSidePagination && (
            <IntegratedGrouping
              columnExtensions={[
                { columnName: 'experimentTags', criteria: groupingTags }
              ]}
            />
          )}

          {isWithSummary && <IntegratedSummary calculator={() => 1} />}

          {allowSorting && !shouldUseServerSidePagination && (
            <IntegratedSorting
              {...(integratedSortingColumnExtensions && {
                columnExtensions: integratedSortingColumnExtensions
              })}
            />
          )}
          {allowFiltering && <IntegratedFiltering />}
          {allowPagination && !shouldUseServerSidePagination && (
            <IntegratedPaging />
          )}
          {allowSelection && <IntegratedSelection />}
          {allowColumnReordering && <DragDropProvider />}
          {renderTable()}
          {allowColumnReordering && (
            <TableColumnReordering
              order={normalizeOrder}
              onOrderChange={onOrderChange}
            />
          )}
          {resizingEnabled && (
            <TableColumnResizing
              columnWidths={normalizeWidths}
              onColumnWidthsChange={onColumnWidthsChange}
              minColumnWidth={MIN_COLUMN_WIDTH}
            />
          )}
          <TableHeaderRow
            cellComponent={renderHeaderRow}
            resizingEnabled={resizingEnabled}
            rowComponent={CustomTableHeaderRow}
            showSortingControls={allowSorting}
            sortLabelComponent={SortLabel}
          />

          {allowSelection && (
            <TableSelection
              cellComponent={renderSelectionCell}
              headerCellComponent={renderSelectAllComponent}
              highlightRow
              rowComponent={renderSelectRowComponent}
              selectionColumnWidth={36}
              showSelectAll
            />
          )}

          <TableGroupRow
            cellComponent={props => (
              <GroupCellComponent columnGrouping={columnGrouping} {...props} />
            )}
            columnExtensions={tableGroupColumnExtension}
            indentColumnWidth={0}
            summaryCellComponent={renderSummaryCellComponent}
          />

          {isWithSummary && <TableSummaryRow />}

          <TableFixedColumns
            leftColumns={LEFT_COLUMNS}
            rightColumns={rightColumns}
            cellComponent={FixedColumnCell}
          />

          {allowPagination && (
            <PagingPanel
              containerComponent={renderPagination}
              pageSizes={pageSizes}
            />
          )}

          {leftFooterSide && (
            <Template name="footer">
              <div className="react-grid-footer">
                {leftFooterSide}
                <TemplatePlaceholder />
              </div>
            </Template>
          )}
        </Grid>
        <GridExporter
          ref={ref}
          columns={columnsForExport}
          getCellValue={getRowValueExport}
          rows={experimentsForExport}
          onSave={exportHandler}
        />
      </ReactGridContext.Provider>
    );
  }
);

ReactGrid.displayName = 'ReactGrid';

ReactGrid.defaultProps = {
  allowColumnReordering: false,
  allowFiltering: false,
  allowPagination: false,
  allowSelection: false,
  allowSorting: false,
  availableColumns: [],
  columns: [],
  cellsMap: undefined,
  columnsActions: [],
  decimalsPrecision: null,
  displayGroups: null,
  experimentsGroupsMap: null,
  groupAggregations: null,
  emptyMessage: null,
  hideColumnsHeader: false,
  integratedSortingColumnExtensions: [],
  isArchive: false,
  isLoadingExperimentsData: false,
  leftFooterSide: null,
  onColumnWidthsChange: noop,
  onCurrentPageChange: noop,
  onExpandedGroupsChange: noop,
  onHoverExperimentNameCell: noop,
  onOrderChange: noop,
  onPageSizeChange: noop,
  onSelectionChange: noop,
  onSortingChange: noop,
  onTogglePinned: undefined,
  onToggleVisibility: undefined,
  openLinksInNewTab: false,
  pageTotalGrouped: 0,
  pageSizes: TABLE_PAGE_SIZES,
  pinnedExperimentKeys: [],
  resizingEnabled: false,
  renderNoDataCell: null,
  rows: [],
  rowActionMenu: {
    isVisible: false,
    isOpened: () => false,
    onClick: noop
  },
  experimentsForExport: [],
  shouldResizePaginationWidth: false,
  shouldUseServerSidePagination: false,
  totalExperiments: null,
  fixedColumnTitle: GRID_COLUMNS.NAME,
  fixedColumnMaxWidth: MIN_COLUMN_NAME_WIDTH,
  rightColumns: [],
  headerRowComponents: {},
  hideSortingNotAvailable: false,
  loadingMessage: 'Fetching experiments'
};

ReactGrid.propTypes = {
  allowColumnReordering: PropTypes.bool,
  allowFiltering: PropTypes.bool,
  allowPagination: PropTypes.bool,
  allowSelection: PropTypes.bool,
  allowSorting: PropTypes.bool,
  availableColumns: PropTypes.array,
  columns: PropTypes.array,
  contextAdditionalData: PropTypes.object,
  cellsMap: PropTypes.object,
  columnsActions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      action: PropTypes.func
    })
  ),
  decimalsPrecision: PropTypes.number,
  displayGroups: PropTypes.array,
  experimentsGroupsMap: PropTypes.object,
  groupAggregations: PropTypes.object,
  experimentsForExport: PropTypes.array,
  exportHandler: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string,
  hideColumnsHeader: PropTypes.bool,
  integratedSortingColumnExtensions: PropTypes.arrayOf({
    columnName: PropTypes.string,
    compare: PropTypes.func
  }),
  isArchive: PropTypes.bool,
  isLoadingExperimentsData: PropTypes.bool, // using to show small loader on experiment details and report page
  loadingMessage: PropTypes.string, // using to show small loader on experiment details and report page
  fixedColumnTitle: PropTypes.string,
  fixedColumnMaxWidth: PropTypes.number,
  leftFooterSide: PropTypes.node,
  onColumnWidthsChange: PropTypes.func,
  onCurrentPageChange: PropTypes.func,
  onExpandedGroupsChange: PropTypes.func,
  onHoverExperimentNameCell: PropTypes.func,
  onOrderChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSortingChange: PropTypes.func,
  onTogglePinned: PropTypes.func,
  onToggleVisibility: PropTypes.func,
  openLinksInNewTab: PropTypes.bool,
  pageSizes: PropTypes.array,
  pageTotalGrouped: PropTypes.number,
  pinnedExperimentKeys: PropTypes.arrayOf(PropTypes.string),
  reactGrid: PropTypes.shape({
    columnGrouping: PropTypes.array,
    columnOrders: PropTypes.array,
    columnSorting: PropTypes.array,
    columnWidths: PropTypes.array,
    displayColumnOrders: PropTypes.array,
    expandedGroups: PropTypes.array,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    selection: PropTypes.array
  }).isRequired,
  resizingEnabled: PropTypes.bool,
  renderNoDataCell: PropTypes.func,
  rows: PropTypes.array,
  rowActionMenu: PropTypes.shape({
    isVisible: PropTypes.bool,
    isOpened: PropTypes.func,
    onClick: PropTypes.func
  }),
  shouldResizePaginationWidth: PropTypes.bool,
  shouldUseServerSidePagination: PropTypes.bool,
  totalExperiments: PropTypes.number,
  rightColumns: PropTypes.arrayOf(PropTypes.string),
  headerRowComponents: PropTypes.objectOf(
    PropTypes.shape({
      prefix: PropTypes.elementType,
      suffix: PropTypes.elementType
    })
  ),
  hideSortingNotAvailable: PropTypes.bool
};

export default React.memo(ReactGrid);
