import { useQuery } from 'react-query';

import sortBy from 'lodash/sortBy';

import { axiosInstance, cancelWrapper } from '../api';

import useProject from './useProject';

const getTags = ({ projectId }) =>
  cancelWrapper(async cancelToken => {
    const { data } = await axiosInstance.get('tags/get-project-tags', {
      cancelToken,
      params: { projectId }
    });

    return sortBy(data.tags, tag => tag.name.toLowerCase());
  });

export default function useProjectTags(params, config) {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  return useQuery(
    ['projectTags', { projectId }],
    () => getTags({ projectId }),
    { enabled: !!projectId, ...config }
  );
}
