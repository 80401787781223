import { cancelWrapper } from '@API/api';
import { useQuery } from 'react-query';
import workspaceApi from '../../util/workspaceApi';

const mapInvites = invites =>
  invites
    .filter(({ claimed }) => !claimed)
    .map(({ inviteEmail }) => ({ email: inviteEmail }));

const getWorkspaceInvites = workspaceId => {
  return cancelWrapper(async cancelToken => {
    const { data } = await workspaceApi.fetchInvitesForWorkspace(
      workspaceId,
      cancelToken
    );

    return mapInvites(data?.invites || []);
  });
};

const useInvitesForTeam = (workspaceId, config) =>
  useQuery(
    ['workspace', workspaceId, 'invites'],
    () => getWorkspaceInvites(workspaceId),
    config
  );

export default useInvitesForTeam;
