import React from 'react';

import { VideoAssetData } from '@experiment-management-shared/types';

import styles from './VideoDetail.module.scss';

export const VideoDetail = ({ asset }: { asset: VideoAssetData }) => {
  const isGIF = asset.name.endsWith('.gif');

  if (isGIF) {
    return (
      <div className={styles.container}>
        <img
          className={styles.video}
          data-test={`video-thumbnail-${asset.name}-video`}
          loading="lazy"
          src={asset.videoPath}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <video
        autoPlay
        className={styles.video}
        controls
        data-test={`video-thumbnail-${asset.name}-video`}
        poster={asset.thumbnailPath}
        preload="none"
        src={asset.videoPath}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
