import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import onboardingActions from '@/actions/onboardingActions';
import { CODE_FRAMEWORKS_LIST } from '@onboarding/constants';

const QUICKSTART_FRAMEWORK_KEY = 'quickstart-framework';

const useQuickStartFramework = () => {
  const dispatch = useDispatch();
  let { framework } = queryString.parse(window.location.search, {
    parseBooleans: true
  });
  framework ??= localStorage.getItem(QUICKSTART_FRAMEWORK_KEY);

  useEffect(() => {
    if (!framework) return;

    const frameworkExists = CODE_FRAMEWORKS_LIST.some(
      fw => fw.value === framework
    );

    if (!frameworkExists) return;

    localStorage.setItem(QUICKSTART_FRAMEWORK_KEY, framework);

    dispatch(onboardingActions.setFramework(framework));
  }, [dispatch, framework]);
};

export default useQuickStartFramework;
