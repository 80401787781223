import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TextButton, Button } from '@ds';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DsSelect from '@DesignSystem/controllers/Select/Select';

import { useActiveWorkspace } from '@shared/hooks';

import alertsUtil from '@/util/alertsUtil';
import {
  useCurrentOrganization,
  useAllUserWorkspacesByOrganizationId
} from '@shared/hooks';

import GenericModal from '@shared/components/GenericModal';
import useMoveProjectMutation from '@projects/api/useMoveProjectMutation';

const MoveProjectModal = ({
  moveConfig,
  moveConfig: { projectName } = {},
  modalId
}) => {
  const dispatch = useDispatch();
  const currentOrganization = useCurrentOrganization();
  const activeWorkspace = useActiveWorkspace();
  const { data: allWorkspacesByOrgId } =
    useAllUserWorkspacesByOrganizationId(currentOrganization?.id) || [];
  const moveProjectMutation = useMoveProjectMutation();

  const [allWorkspacesOptions, setAllWorkspacesOptions] = useState([]);
  const [workspaceId, setWorkspaceId] = useState(null);

  useEffect(() => {
    setAllWorkspacesOptions(
      allWorkspacesByOrgId
        ?.map(({ name, id }) => ({
          label: name,
          value: id
        }))
        .filter(({ value }) => value !== activeWorkspace?.id)
    );
  }, [allWorkspacesByOrgId]);

  const handleSubmit = () => {
    moveProjectMutation.mutate({
      ...moveConfig,
      teamId: workspaceId
    });
    handleClose();
  };

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title={`Move ${projectName} to a different workspace`}
      hideFooter
    >
      <DialogContent className="generic-modal-body">
        <div
          className="generic-modal-group"
          style={{
            flexDirection: 'column',
            alignSelf: 'flexStart',
            width: '100%'
          }}
        >
          <DsSelect
            data-test="move-project-options"
            label="Select workspace"
            options={allWorkspacesOptions}
            value={workspaceId}
            onChange={setWorkspaceId}
            variant="outlined"
            width="340px"
            withInput
            hideSource
          />
        </div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
        <Button
          onClick={handleSubmit}
          size="large"
          disabled={isEmpty(workspaceId)}
          data-test="move-project-submit"
        >
          Done
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

MoveProjectModal.defaultProps = {
  moveConfig: {}
};

MoveProjectModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  moveConfig: PropTypes.shape({
    isPublic: PropTypes.bool.isRequired,
    projectName: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    projectDesc: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })
};

export default MoveProjectModal;
