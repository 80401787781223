import { Column } from '@devexpress/dx-react-grid-export';
import { VisibleColumnsTypeValue } from '../types';
import { COMPARISON_COLUMNS_NAME } from '../newComparisonTableConfig';
import { useEffect, useState } from 'react';

type UseDefaultHiddenColumnNamesOpts = {
  visibleColumnsType: VisibleColumnsTypeValue;
  columns: Column[];
};
const useDefaultHiddenColumnNames = ({
  visibleColumnsType,
  columns
}: UseDefaultHiddenColumnNamesOpts) => {
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = useState<
    string[]
  >([]);

  useEffect(() => {
    setDefaultHiddenColumnNames([]);
    const newList = columns
      .filter(col => {
        if (
          visibleColumnsType === 'all' ||
          col.name === COMPARISON_COLUMNS_NAME.KEY
        ) {
          return false;
        }

        return !col.name.startsWith(visibleColumnsType);
      })
      .map(col => col.name);
    setDefaultHiddenColumnNames(newList);
  }, [visibleColumnsType, columns]);

  return defaultHiddenColumnNames;
};

export default useDefaultHiddenColumnNames;
