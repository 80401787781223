import React, { useEffect, ReactElement, ReactNode } from 'react';
import { useListItemContext } from '../useListItemContext';
import cx from 'classnames';

import classes from './ListComponents.module.scss';

import ListCheckbox, { ListCheckboxProps } from './ListCheckbox';
import ListRadioButton, { ListRadioButtonProps } from './ListRadioButton';
import { ListImageProps } from './ListImage';
import { ListIconProps } from './ListIcon';
import { ListColorProps } from './ListColor';
import isArray from 'lodash/isArray';

type PrefixListItemChild =
  | ReactElement<ListImageProps>
  | ReactElement<ListColorProps>
  | ReactElement<ListCheckboxProps>
  | ReactElement<ListRadioButtonProps>
  | ReactElement<ListIconProps>;

export interface ListPrefixContainerProps {
  children: ReactNode;
}

const ListPrefixContainer = ({ children }: ListPrefixContainerProps) => {
  const {
    setIsWithRadioButton,
    setIsWithCheckbox,
    isWithSecondaryText
  } = useListItemContext();

  const isWithCheckbox = isArray(children)
    ? children?.some(child => child && child.type === ListCheckbox)
    : (children as PrefixListItemChild)?.type === ListCheckbox;

  const isWithRadioButton = isArray(children)
    ? children?.some(child => child && child.type === ListRadioButton)
    : (children as PrefixListItemChild)?.type === ListRadioButton;

  useEffect(() => {
    setIsWithCheckbox(isWithCheckbox);
  }, [isWithCheckbox, setIsWithCheckbox]);

  useEffect(() => {
    setIsWithRadioButton(isWithRadioButton);
  }, [isWithRadioButton, setIsWithRadioButton]);

  return (
    <div
      className={cx(classes.prefixContainer, {
        [classes.atTheTop]: isWithSecondaryText
      })}
    >
      {children}
    </div>
  );
};

export default ListPrefixContainer;
