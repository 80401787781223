// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PCDDetail-module__canvas--cgf_6{height:100%;width:100%;overflow:hidden;overscroll-behavior-x:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": `PCDDetail-module__canvas--cgf_6`
};
module.exports = ___CSS_LOADER_EXPORT___;
