/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Legend.module.scss';

export const LegendPill = ({
  text,
  id,
  dotColor,
  onMouseEnter,
  onMouseLeave
}) => {
  return (
    <div
      onMouseEnter={() => onMouseEnter(id, text)}
      onMouseLeave={() => onMouseLeave()}
      className={styles.legendItemWrapper}
    >
      <div className={styles.legendDot} style={{ background: dotColor }} />
      <p>{text}</p>
    </div>
  );
};

LegendPill.propTypes = {
  dotColor: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};
