import { useEffect } from 'react';
import noop from 'lodash/noop';

export const usePanelResize = (plotRef, containerRef) => {
  useEffect(() => {
    if (!containerRef.current || !plotRef.current) return noop;

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (plotRef.current && plotRef.current.resizeHandler) {
          plotRef.current.resizeHandler();
        }
      });
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef.current, plotRef.current]);
};
