import { experimentActionTypes } from '@/constants/actionTypes';

import rpcActions from './rpcActions';

const experimentActions = {
  fetchExperimentWithUserUnsuccessful() {
    return {
      type: experimentActionTypes.FETCH_EXPERIMENT_WITH_USER_UNSUCCESSFUL
    };
  },

  setExperimentKeysForDetailsPage(experimentKeys) {
    return {
      type: experimentActionTypes.SET_EXPERIMENT_KEYS_FOR_DETAILS_PAGE,
      payload: experimentKeys
    };
  },

  clearExperimentsUI() {
    return {
      type: experimentActionTypes.CLEAR_EXPERIMENTS_UI
    };
  },

  stopRunningExperiment(experimentKey) {
    return dispatch => {
      const stopRPC = {
        experimentKey,
        functionName: 'stop',
        cometDefined: true,
        arguments: [],
        UID: `${experimentKey}-stop`
      };

      dispatch(rpcActions.sendRPC(stopRPC));
    };
  }
};

export default experimentActions;
