import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FullWidthBasicModal } from '@DesignSystem/modals';
import {
  BUILT_IN_CHART_TYPES,
  chartDataErrorTypes
} from '@experiment-management-shared/constants/chartConstants';
import ChartHeader from '@experiment-management-shared/components/Charts/Chart/ChartHeader';

import {
  renderLoader,
  renderChartError
} from '@experiment-management-shared/components/Charts/ChartHelpers';
import { LegendMode, PanelType } from '@experiment-management-shared/types';
import FullscreenPlot from '@experiment-management-shared/components/Charts/Chart/FullscreenPlot';
import { PanelMoveConfig } from '@experiment-management-shared/hooks/usePanelMoveToSection';

import styles from './ChartContainer.module.scss';

type FullScreenType = 'default' | 'badges';

// this part of code is kept to save ProTypes validation for chart that is not migrated to TS yet
export const GENERAL_CHART_CONTAINER_PROPS = {
  children: PropTypes.node,
  chartId: PropTypes.string.isRequired,
  chartType: PropTypes.oneOf([
    BUILT_IN_CHART_TYPES.scalar,
    BUILT_IN_CHART_TYPES.image,
    BUILT_IN_CHART_TYPES.video,
    BUILT_IN_CHART_TYPES.data
  ]).isRequired,
  deleteConfig: PropTypes.object,
  editConfig: PropTypes.object,
  experimentKeys: PropTypes.array.isRequired,
  hideHeader: PropTypes.bool,
  onDeleteChart: PropTypes.func,
  onEditChart: PropTypes.func,
  onOptionsClick: PropTypes.func,
  sectionId: PropTypes.string,
  panelMoveConfig: PropTypes.object,
  projectId: PropTypes.string
};

export type GeneralChartContainerProps = {
  children: React.ReactElement;
  chartId: string;
  chartType: PanelType;
  deleteConfig: never;
  editConfig: never;
  experimentKeys: string[];
  hideHeader?: boolean;
  onDeleteChart: never;
  onEditChart: never;
  onOptionsClick: never;
  panelMoveConfig?: PanelMoveConfig;
  projectId: never;
  badges?: unknown;
  chartContainerRef?: React.RefObject<HTMLDivElement>;
  onExportJSON?: (name: string) => void;
  onExportData?: (format: string) => void;
  onResetZoom?: () => void;
  disableResetZoom?: boolean;
  sectionId?: string;
};

export type ChartContainerProps = GeneralChartContainerProps & {
  onClearChartConstraints?: () => void;
  customNoDataComponent?: React.ReactElement;
  transformYConfig?: never;
  errorType?: string;
  title: string;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isPreviousData: boolean;
  hasData: boolean;
  locked?: boolean;
  showLock?: boolean;
  onLockClick?: (data: { chartId: string; value: boolean }) => void;
  hiddenMenuItems?: string[];
  customHeaderContent?: React.JSX.Element | null;
  sampleSize?: unknown;
  sampleSizes?: unknown;
  onChangeSampleSize?: unknown;
  legendMode?: LegendMode;
  onChangeLegendMode?: never;
  fullScreenType?: FullScreenType;
  showLoadingDuringFullscreen?: boolean;
};

const ChartContainer = ({
  children,
  chartId,
  chartType,
  deleteConfig,
  editConfig,
  experimentKeys,
  customNoDataComponent,
  errorType = '',
  hideHeader = false,
  onDeleteChart,
  onEditChart,
  onOptionsClick,
  panelMoveConfig,
  projectId,
  badges,
  sampleSize,
  sampleSizes,
  sectionId,
  onChangeSampleSize,
  legendMode,
  onChangeLegendMode,
  chartContainerRef,
  transformYConfig,
  title = '',
  isError = false,
  isFetching = false,
  isLoading = false,
  isPreviousData = false,
  hasData = false,
  locked = false,
  showLock = false,
  onLockClick,
  hiddenMenuItems,
  customHeaderContent,
  onExportJSON,
  onExportData,
  onResetZoom,
  onClearChartConstraints,
  disableResetZoom = false,
  fullScreenType = 'default',
  showLoadingDuringFullscreen = false
}: ChartContainerProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const closeFullscreen = () => setIsFullscreen(false);

  const renderHeader = () => {
    if (hideHeader) return null;

    return (
      <ChartHeader
        chartId={chartId}
        chartType={chartType}
        deleteConfig={deleteConfig}
        editConfig={editConfig}
        experimentKeys={experimentKeys}
        hiddenMenuItems={hiddenMenuItems}
        isBackgroundLoading={isFetching}
        onChangeLegendMode={onChangeLegendMode}
        onChangeSampleSize={onChangeSampleSize as never}
        onDeleteChart={onDeleteChart}
        onEditChart={onEditChart}
        onOptionsClick={onOptionsClick}
        onExpandChart={() => setIsFullscreen(true)}
        projectId={projectId}
        legendMode={legendMode}
        locked={locked}
        showLock={showLock}
        onLockClick={onLockClick}
        badges={badges as never}
        sampleSize={sampleSize as never}
        sampleSizes={sampleSizes as never}
        panelMoveConfig={panelMoveConfig}
        sectionId={sectionId}
        title={title}
        transformYConfig={transformYConfig}
        customContent={customHeaderContent}
        onExportJSON={onExportJSON}
        onExportData={onExportData}
        onResetZoom={onResetZoom}
        onClearChartConstraints={onClearChartConstraints}
        disableResetZoom={disableResetZoom}
        hideFullscreenBtn={!hasData}
      />
    );
  };

  if (isLoading && (showLoadingDuringFullscreen || !isFullscreen)) {
    return renderLoader();
  }

  if (isError) {
    return renderChartError(
      errorType || chartDataErrorTypes.FETCHING_ERROR,
      renderHeader
    );
  }

  const renderFullscreenChart = () => {
    switch (fullScreenType) {
      case 'badges':
        return (
          <FullscreenPlot
            onClose={closeFullscreen}
            content={children}
            title={title}
            badges={badges as never}
          />
        );
      case 'default':
      default:
        return (
          <FullWidthBasicModal
            open
            title={title}
            onClose={closeFullscreen}
            withoutFooter
            content={children}
            variant="base-2"
            customHeaderContent={customHeaderContent}
            primaryButtonText={undefined}
            onPrimaryButtonClick={undefined}
            secondaryButtonText={undefined}
            stepActiveIndex={undefined}
            stepAmount={undefined}
            onSecondaryButtonClick={undefined}
            isPrimaryButtonDisabled={undefined}
            isPrimaryButtonLoading={undefined}
            className={undefined}
            footer={undefined}
            withBackButton={undefined}
            onBackButtonClick={undefined}
            headerTitle={undefined}
            titleMaskTest={undefined}
          />
        );
    }
  };

  if (!hasData) {
    if (customNoDataComponent) {
      return customNoDataComponent;
    }
    return renderChartError(chartDataErrorTypes.EMPTY_DATA, renderHeader);
  }

  return (
    <div ref={chartContainerRef} className={`chart-container ${chartType}`}>
      {renderHeader()}
      <div className={cx('chart-internal-container', styles.contentContainer)}>
        {isFetching && isPreviousData && (
          <div className="chart-fetching-overlay" />
        )}
        {isFullscreen ? renderFullscreenChart() : children}
      </div>
    </div>
  );
};

export default ChartContainer;
