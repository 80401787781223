import axios from 'axios';
import { BASE_API_URL } from '../lib/appConstants';

const forgotPasswordApi = {
  sendEmail({ email }) {
    const encodedEmail = encodeURIComponent(email);
    const url = `${BASE_API_URL}auth/reset-start?email=${encodedEmail}`;
    return axios.get(url);
  },
  sendNewPassword({ password, resetToken }) {
    const url = `${BASE_API_URL}auth/reset-complete`;
    const body = { newPlainTextPassword: password, resetToken };
    return axios.post(url, body);
  }
};

export default forgotPasswordApi;
