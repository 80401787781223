import React from 'react';
import {
  ActionMenuListProvider,
  ActionMenuListProviderProps
} from './useActionMenuListContext';
import ActionMenuListTrigger from './ActionMenuListTrigger';
import ActionMenuListBody from './ActionMenuListBody';

type ActionMenuListProps = ActionMenuListProviderProps;

const ActionMenuList = ({ children, ...props }: ActionMenuListProps) => {
  return <ActionMenuListProvider {...props}>{children}</ActionMenuListProvider>;
};

ActionMenuList.Trigger = ActionMenuListTrigger;
ActionMenuList.Body = ActionMenuListBody;
export default ActionMenuList;
