import { Column } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import { ExperimentDetails } from '@shared/types';

export const valuesDiffKeyMap = {
  valueCurrent: 'valueCurrentDiff' as const,
  valueMin: 'valueMinDiff' as const,
  valueMax: 'valueMaxDiff' as const
};

export type DiffValuesKeys = typeof valuesDiffKeyMap[keyof typeof valuesDiffKeyMap];
export type ComparisonTableRow = {
  rowId: string;
  key: string;
  experiment: ExperimentDetails;
  valueCurrent?: string;
  valueMin?: string;
  valueMax?: string;
  isMain: boolean;
  isEqual?: boolean;
} & Partial<Record<DiffValuesKeys, string>>;

export type ComparisonTableProps = {
  column: Column;
  row: ComparisonTableRow;
};

export type ComparisonTableCellRendererData<V> = ComparisonTableProps & {
  value: V;
};

export type ComparisonTableDefaultData = {
  name: string;
  valueCurrent: string;
};

export type DetailViewValue = typeof DETAIL_VIEWS[keyof typeof DETAIL_VIEWS];
export type AllowedCompareKeys = 'valueCurrent' | 'valueMin' | 'valueMax';
export type GroupedParamsMap = Map<string, ComparisonTableRow[]>;
export type EmptyTabCellProps = {
  isComparePage?: boolean;
} & Table.DataCellProps;
