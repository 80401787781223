import { useSelector } from 'react-redux';
import {
  getIsArchivePage,
  getIsExperimentsPage,
  getIsPanelsPage,
  getIsReportsPage
} from '@/selectors/routeSelectors';
import {
  getIsCompareExperimentPage,
  getIsSingleExperimentPage
} from '@shared/utils/url';

const TRACK_EVENT_LOCATION = {
  PANELS_PAGE: 'panels_page',
  EXPERIMENTS_PAGE: 'experiments_page',
  ARCHIVE_PAGE: 'archive_page',
  REPORT_PAGE: 'report_page',
  SINGLE_EXPERIMENT_PAGE: 'single_experiment_page',
  COMPARE_EXPERIMENT_PAGE: 'compare_experiment_page',
  UNKNOWN: 'unknown'
};

const useTrackEventLocation = () => {
  const isExperimentsPage = useSelector(getIsExperimentsPage);
  const isPanelsPage = useSelector(getIsPanelsPage);
  const isArchivePage = useSelector(getIsArchivePage);
  const isReportPage = useSelector(getIsReportsPage);
  const isSingleExperimentPage = useSelector(getIsSingleExperimentPage);
  const isCompareExperimentPage = useSelector(getIsCompareExperimentPage);

  const calculateTrackEventLocation = () => {
    let retVal = TRACK_EVENT_LOCATION.UNKNOWN;

    if (isPanelsPage) {
      retVal = TRACK_EVENT_LOCATION.PANELS_PAGE;
    }

    if (isSingleExperimentPage) {
      retVal = TRACK_EVENT_LOCATION.SINGLE_EXPERIMENT_PAGE;
    }

    if (isCompareExperimentPage) {
      retVal = TRACK_EVENT_LOCATION.COMPARE_EXPERIMENT_PAGE;
    }

    if (isExperimentsPage) {
      retVal = TRACK_EVENT_LOCATION.EXPERIMENTS_PAGE;
    }

    if (isArchivePage) {
      retVal = TRACK_EVENT_LOCATION.ARCHIVE_PAGE;
    }

    if (isReportPage) {
      retVal = TRACK_EVENT_LOCATION.REPORT_PAGE;
    }

    return retVal;
  };

  return calculateTrackEventLocation();
};

export default useTrackEventLocation;
