import React from 'react';
import { TextInput } from '@ds';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import styles from './OtherSingleTableCell.module.scss';

type OtherSingleTableValueEditCellProps = DataTypeProvider.ValueEditorProps;

const OtherSingleTableValueEditCell: React.FC<OtherSingleTableValueEditCellProps> = ({
  column,
  value,
  onValueChange,
  onBlur,
  onFocus,
  autoFocus
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onValueChange(e.target.value);
  return (
    <div className={styles.othersTableEditCellContainer}>
      <TextInput
        value={value}
        placeholder={column.title}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        size="small"
      />
    </div>
  );
};

export default OtherSingleTableValueEditCell;
