import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import InfoBlock from '@design-system-outdated/components/InfoBlock';
import { KeyboardArrowDownIcon } from '@Icons-outdated';

import classNames from './RegisterModel.module.scss';
import RegisterModelFilesViewBlock from './RegisterModelFilesViewBlock';

const RegisterModelFilesView = ({
  registryRecords = [],
  modelName,
  workspace
}) => {
  const [viewAll, setViewAll] = useState(false);
  const registryRecordsLen = registryRecords?.length;
  const registryRecordsToShow = registryRecords;

  useEffect(() => {
    setViewAll(false);
  }, [modelName]);

  return (
    <Box
      className={classNames.registerModelFilesView}
      data-test="streamline-model-sidebar-registry-records-container"
    >
      <Box
        className={cx(
          classNames.registerModelFilesViewContainer,
          !viewAll && classNames.registerModelFilesViewContainerOverflow
        )}
      >
        <InfoBlock
          classes={{
            text: classNames.registerModelFilesViewInfoText,
            container: classNames.registerModelFilesViewInfo,
            icon: classNames.registerModelFilesViewInfoIcon
          }}
          text="This model was already registered as:"
        />
        <Box className={classNames.registerModelFilesViewAll}>
          {registryRecordsToShow.map(
            ({
              registryModelName,
              version,
              createdAt,
              registryModelItemId
            }) => (
              <RegisterModelFilesViewBlock
                workspace={workspace}
                key={`${registryModelName}-${version}`}
                modelName={registryModelName}
                createdAt={moment(createdAt).format('MM/DD/YYYY')}
                version={version}
                registryModelItemId={registryModelItemId}
              />
            )
          )}
        </Box>
      </Box>
      {registryRecordsLen >= 2 ? (
        <Box
          className={classNames.registerModelFilesViewAllButton}
          type="link"
          onClick={() => setViewAll(!viewAll)}
        >
          <span>{viewAll ? 'View less' : 'View more'}</span>
          <span
            className={cx(
              classNames.registerModelFilesViewAllIcon,
              viewAll && classNames.registerModelFilesViewAllIconRotated
            )}
          >
            <KeyboardArrowDownIcon />
          </span>
        </Box>
      ) : null}
    </Box>
  );
};

RegisterModelFilesView.defaultProps = {
  registryRecords: [],
  modelName: '',
  workspace: ''
};

RegisterModelFilesView.propTypes = {
  registryRecords: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.number,
      registryModelName: PropTypes.string,
      version: PropTypes.string
    })
  ),
  modelName: PropTypes.string,
  workspace: PropTypes.string
};

export default RegisterModelFilesView;
