import React from 'react';
import PropTypes from 'prop-types';

import './SimpleTable.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

const SimpleTable = ({ columns, rows }) => {
  return (
    <div className="ds-simple-table-container">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.key}
                  className={column.headClassName}
                  align={column.headAlign}
                  style={column?.headStyle || {}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.key || row.id}>
                {columns.map(column => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    className={column.className}
                  >
                    {column.render
                      ? column.render(row, row[column.key], column.key)
                      : row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

export default SimpleTable;
