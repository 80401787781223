import PropTypes from 'prop-types';
import React from 'react';
import { Avatar } from '@DesignSystem/data-display';
import { Box } from '@material-ui/core';
import {
  getHistoryCardComment,
  getHistoryCardContentByType,
  getHistoryCardTitleByType
} from '../../utils/history';
import { MODEL_REGISTRY_HISTORY_TYPES } from '../../constants';
import classNames from './ModelRegistryHistory.module.scss';

function ModelRegistryHistoryCard(props) {
  const { userName, userAvatarLink, date } = props;

  return (
    <div className={classNames.modelRegistryHistoryCardContainer}>
      <h6 className={classNames.modelRegistryHistoryCardTitle}>
        {getHistoryCardTitleByType(props)}
      </h6>
      <Box display="flex" alignItems="center">
        <Avatar src={userAvatarLink} width={22} />
        <p>
          <b data-mask-test="username">{userName} </b>
          {getHistoryCardContentByType(props)} on {date}
          <div>{getHistoryCardComment(props)}</div>
        </p>
      </Box>
    </div>
  );
}

ModelRegistryHistoryCard.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  isModelRegistryTagsFromStagesEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isModelApprovalProcessEnabled: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  userAvatarLink: PropTypes.string.isRequired,
  newValue: PropTypes.shape({
    note: PropTypes.string
  }).isRequired,
  previousValue: PropTypes.shape({
    note: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  actionType: PropTypes.oneOf(Object.values(MODEL_REGISTRY_HISTORY_TYPES))
    .isRequired,
  userName: PropTypes.string.isRequired
};

export default ModelRegistryHistoryCard;
