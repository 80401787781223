import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

const TOOLTIP_TYPES = {
  base: 'base',
  type2: 'type2'
};

const tooltipTypesMap = {
  [TOOLTIP_TYPES.type2]: {
    color: '#373D4D',
    padding: '1px 4px',
    opacity: '0.9 !important',
    maxWidth: '150px'
  },
  [TOOLTIP_TYPES.base]: {
    color: '#4B505F',
    padding: '10px',
    opacity: 1,
    // default value of Material UI
    maxWidth: '300px'
  }
};

const useStyles = makeStyles({
  arrow: {
    color: ({ type }) => tooltipTypesMap[type]?.color
  },
  tooltip: {
    lineHeight: '16px',
    backgroundColor: ({ type }) => tooltipTypesMap[type]?.color,
    padding: ({ type }) => tooltipTypesMap[type]?.padding,
    opacity: ({ type }) => tooltipTypesMap[type]?.opacity,
    maxWidth: ({ type, maxWidth }) =>
      maxWidth || tooltipTypesMap[type]?.maxWidth,
    textAlign: 'center',
    borderRadius: '6px',
    color: '#fff',
    zIndex: 5000
  }
});

const StyledTooltip = ({ children, type, maxWidth, ...otherProps }, ref) => {
  const classes = useStyles({ type, maxWidth });
  return (
    <Tooltip arrow classes={classes} {...otherProps} ref={ref}>
      {children}
    </Tooltip>
  );
};

const StyledTooltipRef = React.forwardRef(StyledTooltip);

StyledTooltipRef.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  type: PropTypes.string
};

StyledTooltipRef.defaultProps = {
  type: 'base'
};

export default StyledTooltipRef;
