// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelPerformancePage-module__modelPerformanceTab--kPxrI{background:var(--background-color);display:flex}.ModelPerformancePage-module__modelPerformanceTab--kPxrI .ModelPerformancePage-module__modelPerformanceTabPanels--DCLLQ{display:flex;flex-direction:column;overflow:auto;width:100%}.ModelPerformancePage-module__modelPerformanceTab--kPxrI .ModelPerformancePage-module__modelPerformanceTabHeader--KURlp{align-items:center;display:flex;background:var(--background-color);padding:10px 25px;justify-content:flex-end}.ModelPerformancePage-module__modelPerformanceTab--kPxrI .ModelPerformancePage-module__modelPerformanceTabHeader--KURlp button{margin-right:auto}.ModelPerformancePage-module__modelPerformanceTab--kPxrI .ModelPerformancePage-module__modelPerformanceTabHeader--KURlp .ModelPerformancePage-module__modelPerformanceTabHeaderDivider--EB_sb{background-color:var(--gray-3);height:29px;margin:0 21px;width:1px}.ModelPerformancePage-module__modelPerformanceTab--kPxrI .ModelPerformancePage-module__modelPerformanceTabHeader--KURlp .ModelPerformancePage-module__modelPerformanceTabHeaderDateRanges--Xedtx{display:flex;margin-right:8px}.ModelPerformancePage-module__modelPerformanceTab--kPxrI .ModelPerformancePage-module__modelPerformanceTabContent--ZPZbf{display:flex;flex-direction:column;gap:16px;height:calc(100vh - var(--mpm-section-navbar-height) - var(--navigation-header-height) - 57px);overflow:overlay;overflow-x:hidden;padding:0 20px 20px 20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelPerformanceTab": `ModelPerformancePage-module__modelPerformanceTab--kPxrI`,
	"modelPerformanceTabPanels": `ModelPerformancePage-module__modelPerformanceTabPanels--DCLLQ`,
	"modelPerformanceTabHeader": `ModelPerformancePage-module__modelPerformanceTabHeader--KURlp`,
	"modelPerformanceTabHeaderDivider": `ModelPerformancePage-module__modelPerformanceTabHeaderDivider--EB_sb`,
	"modelPerformanceTabHeaderDateRanges": `ModelPerformancePage-module__modelPerformanceTabHeaderDateRanges--Xedtx`,
	"modelPerformanceTabContent": `ModelPerformancePage-module__modelPerformanceTabContent--ZPZbf`
};
module.exports = ___CSS_LOADER_EXPORT___;
