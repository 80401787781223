import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

export default class SimpleSnackbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let autoHideDuration;
    if (this.props.enableAutoHide) {
      autoHideDuration = this.props.autoHideDuration || 2000;
    } else {
      autoHideDuration = null;
    }

    return (
      <Snackbar
        id="snackbar"
        className={this.props.isError ? 'snackbar-error' : 'snackbar-success'}
        open={this.props.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={autoHideDuration}
        onClose={this.props.onClose}
        ContentProps={{ 'aria-describedby': 'message-id' }}
        message={<span id="message-id">{this.props.message}</span>}
        action={
          this.props.action || [
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <i className="material-icons">close</i>
            </IconButton>
          ]
        }
      />
    );
  }
}

SimpleSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  enableAutoHide: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired
};
