import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import DsAccordion from '@design-system-outdated/components/Accordion/Accordion';
import SyntaxHighlighter from '@design-system-outdated/components/SyntaxHighlighter/SyntaxHighlighter';
import { VideoAssetData } from '@experiment-management-shared/types';

import {
  GraphicStepSection,
  GraphicsDetailsModal,
  GraphicsDetailsModalProps
} from '../GridPanel';
import { VideoDetail } from './VideoDetail';

type VideoDetailsModalProps = Omit<
  GraphicsDetailsModalProps<VideoAssetData>,
  'renderAssetViewer' | 'sections'
>;

export const VideoDetailsModal = ({
  assets,
  onChange,
  onClose,
  selectedAsset
}: VideoDetailsModalProps) => {
  const [asset, setAsset] = useState(selectedAsset);

  useEffect(() => {
    setAsset(selectedAsset);
  }, [selectedAsset]);

  const metadata = asset.metadata;
  const formattedMetadata = useMemo(() => {
    if (!metadata) return '';

    return getFormattedMetadata(metadata);
  }, [metadata]);

  const handleChange = useCallback(
    (asset: VideoAssetData) => {
      onChange?.(asset);
      setAsset(asset);
    },
    [onChange]
  );

  const renderAssetViewer = ({ asset }: { asset: VideoAssetData }) => {
    return <VideoDetail asset={asset} />;
  };

  const renderSections = () => {
    if (!asset) return null;

    return (
      <>
        <DsAccordion
          content={
            <GraphicStepSection
              asset={asset}
              assets={assets}
              onChangeSettings={({ asset: selectedAsset }) => {
                setAsset(selectedAsset as VideoAssetData);
              }}
            />
          }
          defaultExpanded
          id="asset-analysis"
          title="Asset Analysis"
        />
        {formattedMetadata ? (
          <DsAccordion
            content={
              <SyntaxHighlighter
                code={formattedMetadata}
                fullWidth
                language="json"
                showLineNumbers={false}
                style={github}
                withCopyBtn
              >
                {formattedMetadata}
              </SyntaxHighlighter>
            }
            defaultExpanded
            id="video-metadata-accordion"
            title="Video Metadata"
          />
        ) : null}
      </>
    );
  };

  return (
    <GraphicsDetailsModal
      assets={assets}
      onChange={handleChange}
      onClose={onClose}
      renderAssetViewer={renderAssetViewer}
      sections={renderSections()}
      selectedAsset={asset}
    />
  );
};

const getFormattedMetadata = (metadata: string) => {
  try {
    return JSON.stringify(JSON.parse(metadata), null, 2);
  } catch (_) {
    return null;
  }
};
