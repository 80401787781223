import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { SectionTabs } from '@DesignSystem/navigation';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useModelRegistry from '@API/model-registry/useModelRegistry';
import get from 'lodash/get';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';

import { StyledTooltip } from '@DesignSystem/data-display';
import ModelRegistryHistoryTab from '@model-registry/components/ModelRegistryHistoryTab';
import ModelRegistryNotesTab from '@model-registry/components/ModelRegistryNotesTab';
import DeleteModelModal from '@model-registry/components/DeleteModelModal';
import EditModalModel from '@model-registry/components/EditModalModel';
import ModelRegistryPendingRequestsTab from '@model-registry/components/ModelRegistryPendingRequestsTab';
import ModelRegistryWebhooksTab from '@model-registry/components/ModelRegistryWebhooksTab';
import {
  MODEL_REGISTRY_TABS_ORDER,
  PENDING_REQUESTS_HEADER_TAB,
  WEBHOOKS_HEADER_TAB,
  HEADER_TABS,
  MODEL_REGISTRY_TAB_ID,
  VERSIONS_TAB_ID,
  NOTES_TAB_ID,
  HISTORY_TAB_ID,
  WEBHOOKS_TAB_ID,
  PENDING_REQUESTS_TAB_ID
} from '@model-registry/constants';
import useModelRegistryPendingRequests from '@model-registry/api/useModelRegistryPendingRequests';
import { MANAGEMENT_PERMISSIONS_SERVER } from '@shared/constants/permissions';
import modelRegistryApi from '@/util/modelRegistryApi';
import useUserPermission from '@shared/api/useUserPermission';
import useCurrentUser from '@API/auth/useCurrentUser';
import PageInfoMessage from '@shared/components/PageInfoMessage';
import { ifAllAssetsIsRemote } from '@model-registry';
import useActiveTabFromURL from '@/helpers/custom-hooks/useActiveTabFromUrl';

import ModelVersionsTab from '@model-registry/components/ModelVersionsTab';

import ModelRegistryHeader from '@model-registry/components/ModelRegistryHeader';
import {
  BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL,
  BETA_FEATURE_MODEL_APPROVAL_PROCESS,
  BETA_FEATURE_MODEL_REGISTRY_WEBHOOKS
} from '@/lib/betaFeatures';
import SmallLoader from '@shared/components/SmallLoader';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import {
  useCurrentOrganization,
  useIsUserMemberOfWorkspace
} from '@shared/hooks';

import { DSDeleteIcon, DSDownloadIcon, DSEditIcon } from '@ds-icons';
import { LinkButton } from '@ds';

const ModelRegistryPage = () => {
  const { modelName, workspace } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updatePendingRequestsLen, setUpdatePendingLenRequests] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const isUserLoggedIn = useSelector(state => getIsUserLoggedIn(state));
  const { data: currentUser } = useCurrentUser({
    enabled: isUserLoggedIn
  });

  const [activeTabIndex, setActiveTabIndex] = useActiveTabFromURL({
    tabNameInURL: MODEL_REGISTRY_TAB_ID,
    tabsOrder: MODEL_REGISTRY_TABS_ORDER
  });
  const {
    data: model = {},
    isLoading: isLoadingModel,
    isError: isErrorLoadingModelRegistry,
    error: modelRegistryRequestError
  } = useModelRegistry({
    modelName,
    workspace
  });

  const { data: isUserAMemberOfWorkspace } = useIsUserMemberOfWorkspace();
  const { autoGenerated } = model;
  const isLastModelVersion = model?.versions?.length <= 1;
  const isMPMInternalEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_PRODUCTION_MONITORING_INTERNAL
  );
  const isModelApprovalProcessEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_APPROVAL_PROCESS
  );
  const isModelWebhooksEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_MODEL_REGISTRY_WEBHOOKS
  );
  const {
    data: { totalResultsCount } = {},
    isLoading: isLoadingPendingRequests
  } = useModelRegistryPendingRequests(
    {
      workspace,
      modelName,
      page: 1,
      limit: 1,
      updateLen: updatePendingRequestsLen
    },
    {
      retry: 1,
      enabled:
        isUserLoggedIn &&
        isModelApprovalProcessEnabled &&
        !!workspace &&
        !!modelName
    }
  );

  const { getServerPermissionStatus } = useUserPermission({
    enabled: isUserLoggedIn
  });
  const ifCurrentUserWsOwner = getServerPermissionStatus({
    workspaceName: workspace,
    permissionKey: MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
  });

  const currentOrganization = useCurrentOrganization();
  const isAdmin = currentOrganization?.isAdmin;

  const canDeleteModel = isAdmin || ifCurrentUserWsOwner;

  const latestModelVersion = useMemo(() => {
    const versions = get(model, 'versions', []);
    return last(sortBy(versions, 'createdAt')) || {};
  }, [model]);

  const isLatestModelVersionAssetsRemote = ifAllAssetsIsRemote(
    latestModelVersion?.assets
  );
  const isLatestModelVersionAutoGenerated = latestModelVersion?.autoGenerated;

  const handleDeleteClick = () => setIsDeleteModalOpen(true);

  const handleEditClick = () => setIsEditModalOpen(true);

  const headerTabs = useMemo(() => {
    if (!isUserLoggedIn) {
      return [HEADER_TABS[0], HEADER_TABS[1]];
    }

    const headerTabsMemo = [...HEADER_TABS];

    if (isModelWebhooksEnabled) {
      headerTabsMemo.push(WEBHOOKS_HEADER_TAB);
    }

    if (isModelApprovalProcessEnabled) {
      headerTabsMemo.push({
        ...PENDING_REQUESTS_HEADER_TAB,
        notificationCount: totalResultsCount || 0
      });
    }

    return headerTabsMemo;
  }, [
    isModelApprovalProcessEnabled,
    isModelWebhooksEnabled,
    totalResultsCount,
    isUserLoggedIn
  ]);
  const activeHeaderTab = useMemo(() => headerTabs[activeTabIndex], [
    activeTabIndex,
    headerTabs
  ]);

  if (isLoadingModel) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Fetching model"
      />
    );
  }

  if (isErrorLoadingModelRegistry) {
    return (
      <PageInfoMessage
        title={`${workspace}/${model.modelName} · Error`}
        goBackLink={`/${workspace}`}
        message={
          modelRegistryRequestError?.response?.status === 403
            ? 'This is a private model.'
            : 'There was an error fetching model.'
        }
      />
    );
  }

  return (
    <Box>
      <DeleteModelModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        isLastModelVersion={isLastModelVersion}
      />
      <EditModalModel
        model={model}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
      <ModelRegistryHeader
        model={model}
        nameLabel={model.isPublic ? 'Public' : 'Private'}
        modelName={model.modelName}
        handleDeleteClick={handleDeleteClick}
        handleEditClick={handleEditClick}
        tabs={
          <Box className="model-registry-tabs" data-test="model-registry-tabs">
            <SectionTabs
              tabs={headerTabs}
              activeTabIndex={activeTabIndex}
              onTabChange={index => {
                setActiveTabIndex(index);
                if (index === 3) {
                  setUpdatePendingLenRequests(updatePendingRequestsLen + 1);
                }
              }}
              primaryColorSelectedTab={false}
              selectedTabColor="#191A1C"
            />
          </Box>
        }
        actions={[
          {
            key: 'export',
            icon: <DSDownloadIcon />,
            href: modelRegistryApi.getDownloadModelItemURL(
              latestModelVersion?.registryModelItemId
            ),
            disabled:
              autoGenerated ||
              isLatestModelVersionAutoGenerated ||
              isLatestModelVersionAssetsRemote,
            tooltip:
              isLatestModelVersionAssetsRemote &&
              'You cannot directly download remote models'
          },
          {
            key: 'edit',
            icon: <DSEditIcon />,
            onClick: handleEditClick,
            disabled: !isUserAMemberOfWorkspace
          },
          {
            key: 'delete',
            icon: <DSDeleteIcon />,
            onClick: handleDeleteClick,
            disabled: !isUserAMemberOfWorkspace || !canDeleteModel,
            tooltip:
              !canDeleteModel &&
              'Only admins or workspace owners can delete models'
          }
        ]}
        latestModelVersion={latestModelVersion}
      >
        <Box className="brief-label">Versions</Box>
        <Box className="brief">{model.versions?.length || 0}</Box>
        {model.description && (
          <>
            <Box className="brief-label">Description</Box>
            <StyledTooltip title={model.description} type="type2">
              <Box className="overflow">{model.description}</Box>
            </StyledTooltip>
          </>
        )}
        {model?.monitored && isMPMInternalEnabled && (
          <>
            <Box className="brief-label">MPM</Box>
            <Box className="brief">
              <LinkButton
                to={`/${workspace}/model-production-monitoring/${model.registryModelId}/performance`}
                text="See model performance"
              />
            </Box>
          </>
        )}
      </ModelRegistryHeader>
      {activeHeaderTab?.id === VERSIONS_TAB_ID && (
        <ModelVersionsTab
          model={model}
          isUserLoggedIn={isUserLoggedIn}
          ifCurrentUserWsOwner={ifCurrentUserWsOwner}
        />
      )}
      {activeHeaderTab?.id === NOTES_TAB_ID && <ModelRegistryNotesTab />}
      {isUserLoggedIn && activeHeaderTab?.id === HISTORY_TAB_ID && (
        <ModelRegistryHistoryTab />
      )}
      {isModelWebhooksEnabled && activeHeaderTab?.id === WEBHOOKS_TAB_ID && (
        <ModelRegistryWebhooksTab
          workspace={workspace}
          modelName={model?.modelName}
          isLoading={isLoadingPendingRequests}
          ifCurrentUserWsOwner={ifCurrentUserWsOwner}
          currentUser={currentUser}
        />
      )}
      {isUserLoggedIn &&
        isModelApprovalProcessEnabled &&
        activeHeaderTab?.id === PENDING_REQUESTS_TAB_ID && (
          <ModelRegistryPendingRequestsTab
            modelName={modelName}
            workspace={workspace}
            model={model}
            isLoading={isLoadingPendingRequests}
            ifCurrentUserWsOwner={ifCurrentUserWsOwner}
            currentUser={currentUser}
            totalRowCount={totalResultsCount}
            isModelApprovalProcessEnabled
          />
        )}
    </Box>
  );
};

export default ModelRegistryPage;
