import React from 'react';
import { Slider } from '@design-system-outdated';
import { TextButton } from '@ds';
import { useSelector } from 'react-redux';
import useDebounce from '@shared/hooks/useDebounce';
import { selectIsComparePage } from '@/reducers/ui/experimentsUiReducer';
import { DSPlusIcon } from '@ds-icons';
import HyperParametersDownload from './HyperParametersDownload';
import ExperimentBar from '../ExperimentBar';
import useHyperParametersIsDisabled from './hooks/useHyperParametersIsEmpty';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';

const SEARCH_VALUE_CHANGE_DELAY = 1000;

type HyperParametersConfigBarProps = {
  experimentName?: string;
  searchValue: string;
  onChangeSearchValue: (v: string) => void;
  decimalsPrecision: number;
  onChangeDecimalsPrecision: (v: number) => void;
  hideIdenticalParams: boolean;
  onChangeHideIdenticalParams: (v: boolean) => void;
  onAddParameterClick: (paramName?: string) => void;
};

const HyperParametersConfigBar: React.FC<HyperParametersConfigBarProps> = ({
  experimentName,
  searchValue,
  onChangeSearchValue,
  decimalsPrecision,
  onChangeDecimalsPrecision,
  hideIdenticalParams,
  onChangeHideIdenticalParams,
  onAddParameterClick
}) => {
  const isComparePage = useSelector(selectIsComparePage);
  const isDisabled = useHyperParametersIsDisabled();
  const canEdit = useSelector(isUserAllowedToEditProject);

  const [localDecimalsPrecision, setLocalDecimalsPrecision] = useDebounce({
    value: decimalsPrecision,
    onDebounceChange: onChangeDecimalsPrecision
  });

  const [localSearchValue, setLocalSearchValue] = useDebounce({
    delay: SEARCH_VALUE_CHANGE_DELAY,
    value: searchValue,
    onDebounceChange: onChangeSearchValue
  });

  return (
    <ExperimentBar
      rightContainerChildren={
        !isComparePage && (
          <ExperimentBar.ActionList>
            <HyperParametersDownload experimentName={experimentName} />
            <TextButton
              PrefixIcon={<DSPlusIcon />}
              onClick={() => onAddParameterClick()}
              disabled={!canEdit}
            >
              Add parameter
            </TextButton>
          </ExperimentBar.ActionList>
        )
      }
    >
      <ExperimentBar.Search
        searchText={localSearchValue}
        setSearchText={setLocalSearchValue}
        placeholder="Name (regex)"
        disabled={isDisabled}
      />

      <ExperimentBar.Custom
        label="Decimal precision"
        component={
          <Slider
            onChange={setLocalDecimalsPrecision}
            min={0}
            max={20}
            step={1}
            value={localDecimalsPrecision}
            disabled={isDisabled}
          />
        }
      />

      {isComparePage && (
        <ExperimentBar.Toggle
          label="Hide identical parameters"
          name={'hyper-hide-toggle'}
          onChange={onChangeHideIdenticalParams}
          defaultChecked={hideIdenticalParams}
          disabled={isDisabled}
        />
      )}
    </ExperimentBar>
  );
};

export default HyperParametersConfigBar;
