import React from 'react';
import { Tooltip } from '@ds';
import { MetricCellRendererData } from '../helpers';

type MetricsTableStepCellProps = MetricCellRendererData;

const MetricsTableStepCell: React.FC<MetricsTableStepCellProps> = ({
  value
}) => {
  return (
    <Tooltip content={value} placement="top" truncateOverflow>
      <>{value}</>
    </Tooltip>
  );
};

export default MetricsTableStepCell;
