import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  arrow: {
    color: props => (props.mode === 'dark' ? '#262730' : '#fff')
  },
  tooltip: {
    backgroundColor: props => (props.mode === 'dark' ? '#262730' : '#fff'),
    borderRadius: '3px',
    fontSize: '12px',
    padding: '10px',
    color: '#fff',
    zIndex: 5000
  }
});

const NewStyledTooltip = ({ children, ...props }) => {
  const classes = useStyles(props);
  return (
    <Tooltip arrow classes={{ ...classes, ...props.modifiedstyles }} {...props}>
      {children}
    </Tooltip>
  );
};

NewStyledTooltip.defaultProps = {
  modifiedstyles: null,
  mode: 'dark'
};

NewStyledTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  modifiedstyles: PropTypes.object,
  mode: PropTypes.string
};

export default NewStyledTooltip;
