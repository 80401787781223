// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import styles from '!!raw-loader!./sandboxStyles.css';
import { iFrameConfig } from '@experiment-management-shared/constants';

const topChartMountStyle = `#${iFrameConfig.chartMountId} {
    justify-content: flex-start !important;
}`;

export default (userCSS, isCenter) => `<style>${styles}${userCSS}
${!isCenter ? topChartMountStyle : ''}
</style>`;
