import { ColumnBands } from '@devexpress/dx-react-grid';
import { PARAM_ACTION_COLUMN } from '../params/helpers';

const NAME_TITLE = 'Name';
const VALUE_TITLE = 'Value';
const STEP_TITLE = 'Step';
const TIME_TITLE = 'Time';

export const disableConfig = {
  isDisabled: true
};

export const METRIC_COLUMNS_NAME = {
  NAME: 'name',
  CURRENT_VALUE: 'valueCurrent',
  CURRENT_STEP: 'stepCurrent',
  CURRENT_TIME: 'timestampCurrent',
  MIN_VALUE: 'valueMin',
  MIN_STEP: 'stepMin',
  MIN_TIME: 'timestampMin',
  MAX_VALUE: 'valueMax',
  MAX_STEP: 'stepMax',
  MAX_TIME: 'timestampMax'
};

export const METRIC_TYPES = {
  name: {
    key: METRIC_COLUMNS_NAME.NAME,
    label: 'name'
  },
  valueCurrent: {
    key: METRIC_COLUMNS_NAME.CURRENT_VALUE,
    label: 'last value'
  },
  stepCurrent: {
    key: METRIC_COLUMNS_NAME.CURRENT_STEP,
    label: 'last step'
  },
  timestampCurrent: {
    key: METRIC_COLUMNS_NAME.CURRENT_TIME
  },
  valueMin: {
    key: METRIC_COLUMNS_NAME.MIN_VALUE,
    label: 'min value'
  },
  stepMin: {
    key: METRIC_COLUMNS_NAME.MIN_STEP,
    label: 'min step'
  },
  timestampMin: {
    key: METRIC_COLUMNS_NAME.MIN_TIME
  },
  valueMax: {
    key: METRIC_COLUMNS_NAME.MAX_VALUE,
    label: 'max value'
  },
  stepMax: {
    key: METRIC_COLUMNS_NAME.MAX_STEP,
    label: 'max step'
  },
  timestampMax: {
    key: METRIC_COLUMNS_NAME.MAX_TIME
  }
};

export const METRIC_TABLE_COLUMNS = [
  { name: METRIC_COLUMNS_NAME.NAME, title: NAME_TITLE },
  { name: METRIC_COLUMNS_NAME.CURRENT_VALUE, title: VALUE_TITLE },
  { name: METRIC_COLUMNS_NAME.CURRENT_STEP, title: STEP_TITLE },
  { name: METRIC_COLUMNS_NAME.CURRENT_TIME, title: TIME_TITLE },
  { name: METRIC_COLUMNS_NAME.MIN_VALUE, title: VALUE_TITLE },
  { name: METRIC_COLUMNS_NAME.MIN_STEP, title: STEP_TITLE },
  { name: METRIC_COLUMNS_NAME.MIN_TIME, title: TIME_TITLE },
  { name: METRIC_COLUMNS_NAME.MAX_VALUE, title: VALUE_TITLE },
  { name: METRIC_COLUMNS_NAME.MAX_STEP, title: STEP_TITLE },

  { name: METRIC_COLUMNS_NAME.MAX_TIME, title: TIME_TITLE },
  { name: PARAM_ACTION_COLUMN, title: ' ' }
];

export const METRIC_TABLE_COLUMN_BANDS: ColumnBands[] = [
  {
    title: 'Last',
    children: [
      { columnName: METRIC_COLUMNS_NAME.CURRENT_VALUE },
      { columnName: METRIC_COLUMNS_NAME.CURRENT_STEP },
      { columnName: METRIC_COLUMNS_NAME.CURRENT_TIME }
    ]
  },
  {
    title: 'Min',
    children: [
      { columnName: METRIC_COLUMNS_NAME.MIN_VALUE },
      { columnName: METRIC_COLUMNS_NAME.MIN_STEP },
      { columnName: METRIC_COLUMNS_NAME.MIN_TIME }
    ]
  },
  {
    title: 'Max',
    children: [
      { columnName: METRIC_COLUMNS_NAME.MAX_VALUE },
      { columnName: METRIC_COLUMNS_NAME.MAX_STEP },
      { columnName: METRIC_COLUMNS_NAME.MAX_TIME },
      { columnName: PARAM_ACTION_COLUMN }
    ]
  }
];

export const DATETIME_CELL_KEYS = [
  METRIC_COLUMNS_NAME.CURRENT_TIME,
  METRIC_COLUMNS_NAME.MIN_TIME,
  METRIC_COLUMNS_NAME.MAX_TIME
];
export const STEP_CELL_KEYS = [
  METRIC_COLUMNS_NAME.CURRENT_STEP,
  METRIC_COLUMNS_NAME.MIN_STEP,
  METRIC_COLUMNS_NAME.MAX_STEP
];
export const VALUE_CELL_KEYS = [
  METRIC_COLUMNS_NAME.CURRENT_VALUE,
  METRIC_COLUMNS_NAME.MIN_VALUE,
  METRIC_COLUMNS_NAME.MAX_VALUE
];

export type MetricTypeData = {
  key: string;
  label?: string;
};
export type MetricTypeMap = Record<keyof typeof METRIC_TYPES, MetricTypeData>;
