import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@ds';

import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import leftPadding from '../utils/leftPadding';
import { removeLastSlashFromPath } from '@experiment-management-shared/utils/filesTreeUtils';
import RegisterModelModalBtn from '@experiment-management-shared/components/RegisterModelModalBtn';
import useResponsiveWidth from '../utils/useResponsiveWidth';
import ViewExistingModelsPopper from './ViewExistingModelsPopper';
import assetsApi from '@/util/assetsApi';
import { find, takeRight } from 'lodash';
import DirActions from './DirActions';

const normalizeModelName = modelName => {
  if (modelName?.[0] === '/') return modelName.slice(1);

  return modelName;
};

export const getExperimentModel = (experimentModels, dir) =>
  find(experimentModels, model => {
    const paths = dir.relativePath.split('/');
    const rest = `${takeRight(paths, paths.length - 2).join('/')}/`;
    const modelName = normalizeModelName(model.modelName);

    return modelName === dir.dir || modelName === rest;
  });

const DirRow = ({
  showDirActions,
  directoriesState,
  dir,
  experimentKey,
  handleOnClick,
  handleOnHover,
  experimentModels,
  onDirStateChanged
}) => {
  const isNoRemoteFile = useMemo(() => {
    return dir?.files?.some(file => !file.remote);
  }, [dir?.files]);

  const relativePath = removeLastSlashFromPath(dir.relativePath);
  const respWidth = useResponsiveWidth();
  const onCurrentDirChange = event => {
    if (event.target === event.currentTarget) {
      const isOpen = !directoriesState.isOpen(relativePath);
      handleOnClick(dir, isOpen);
      onDirStateChanged(relativePath, isOpen);
    }
  };

  const renderViewExistingModelsBtn = () => {
    const foundModel = getExperimentModel(experimentModels, dir);

    if (foundModel && !isEmpty(foundModel.registryRecords)) {
      return <ViewExistingModelsPopper experimentModel={foundModel} />;
    }
  };

  const renderRegisterModelBtn = () => {
    const foundModel = getExperimentModel(experimentModels, dir);

    if (foundModel) {
      return <RegisterModelModalBtn experimentModel={foundModel} />;
    }
  };

  const nameStyle = {
    paddingLeft: leftPadding(dir.level),
    pointerEvents: 'none'
  };
  const isOpen = directoriesState.isOpen(relativePath);

  return (
    <Grid
      container
      onClick={onCurrentDirChange}
      onMouseEnter={() => handleOnHover(dir)}
      className="files-system-dir-row"
      style={{ cursor: 'pointer' }}
      data-test="dir-row"
    >
      <Grid
        item
        xs={4}
        style={{ pointerEvents: 'none' }}
        className={cx('files-system-file-name', respWidth.name)}
      >
        <div style={nameStyle} className="files-system-row-name-wrapper dir">
          <span className="files-system-row-name-icon">
            <KeyboardArrowDownIcon
              className={cx('icons', 'arrow', isOpen && 'open')}
            />
            <i className="material-icons icons folder-icon">folder</i>
          </span>

          <Tooltip content={dir.dir} placement="top" truncateOverflow>
            <span
              className={cx(
                'files-system-row-name-text',
                'name',
                respWidth.dirName
              )}
            >
              {dir.dir}
            </span>
          </Tooltip>
        </div>
      </Grid>
      {showDirActions && (
        <Grid item sx={{ alignSelf: 'flex-end' }}>
          <div className="dir-row-buttons">
            {renderViewExistingModelsBtn()}
            {renderRegisterModelBtn()}
            <DirActions
              downloadAssetURL={
                (dir?.relativePath !== '/models/' || isNoRemoteFile) &&
                assetsApi.returnRequestLink({
                  experimentKey,
                  dir: dir?.relativePath?.slice(1)
                })
              }
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

DirRow.propTypes = {
  showDirActions: PropTypes.bool.isRequired,
  directoriesState: PropTypes.object.isRequired,
  dir: PropTypes.shape({
    relativePath: PropTypes.string,
    dir: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        remote: PropTypes.bool
      })
    )
  }).isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnHover: PropTypes.func.isRequired,
  experimentModels: PropTypes.array.isRequired,
  onDirStateChanged: PropTypes.func.isRequired,
  experimentKey: PropTypes.string.isRequired
};

export default DirRow;
