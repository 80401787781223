import React from 'react';
import { BasicModal } from '@DesignSystem/modals';
import styles from '../MetricsTable.module.scss';

export type DeleteMetricParamModalProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  paramKey: string;
};
const DeleteMetricParamModal: React.FC<DeleteMetricParamModalProps> = ({
  isOpened,
  onClose,
  onConfirm,
  paramKey
}) => {
  return (
    <BasicModal
      open={isOpened}
      onClose={onClose}
      title="Delete Metric?"
      className={styles.metricTableDeleteModal}
      content={
        <div className={styles.metricTableDeleteModalContent}>
          The metric &apos;{paramKey}&apos;{' '}
          {paramKey.length > 50 ? <br /> : null} will be permanently deleted and
          cannot be recovered.
        </div>
      }
      primaryButtonText="Yes, delete"
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={onConfirm}
    />
  );
};

export default DeleteMetricParamModal;
