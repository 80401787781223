import { useMutation, useQueryClient } from 'react-query';
import useCurrentUser from '@API/auth/useCurrentUser';
import { useDispatch } from 'react-redux';
import alertsUtil from '@/util/alertsUtil';
import { useAppStripe } from '../../hooks';
import { snackbarTypes } from '@/constants/alertTypes';
import { FREE_PLANS, PAYMENT_PLANS } from '@shared/constants';

import { paymentsActionTypes } from '@/constants/actionTypes';
import billingApi from '@/util/billingApi';

const useAddOrganizationPaymentMutation = organization => {
  const queryClient = useQueryClient();
  const { getToken } = useAppStripe();
  const dispatch = useDispatch();
  const { data: currentUser } = useCurrentUser();
  const acitvePaymentPlan = organization?.paymentPlan;

  return useMutation(
    async ({ address, city, state, postalCode, country, plan, amount }) => {
      const { token, error } = await getToken({
        address,
        city,
        state,
        postalCode,
        country
      });

      if (error) {
        throw error;
      }

      const { id, card } = token;

      return billingApi.addPayment(organization?.id, {
        customerId: organization?.id,
        token: id,
        email: currentUser?.email,
        plan,
        amount,
        card: {
          id: card.id,
          exp_month: card.exp_month,
          funding: card.funding,
          exp_year: card.exp_year,
          last4: card.last4,
          name: card.name
        },
        stripeResponse: token,
        organizationName: organization?.name
      });
    },
    {
      onSuccess: (resp, { plan, fromTrial }) => {
        const msg = fromTrial
          ? `You have successfully upgraded your plan`
          : `You have successfully upgraded your plan from ${PAYMENT_PLANS[acitvePaymentPlan]?.displayName} to ${PAYMENT_PLANS[plan]?.displayName} `;
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_PAYMENT_CHANGED,
            msg
          )
        );

        queryClient.invalidateQueries([
          'organizations',
          organization?.id,
          'billing'
        ]);

        queryClient.invalidateQueries(['organizations', 'list']);

        if (!FREE_PLANS.includes(plan)) {
          billingApi.sendPlanNotify(organization?.id);

          dispatch({
            type: paymentsActionTypes.SENT_NOTIFICATION_EMAIL
          });
        }
      },
      onError: e => {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_PAYMENT_CHANGED,
            e?.response?.data?.msg || e?.message
          )
        );
      }
    }
  );
};

export default useAddOrganizationPaymentMutation;
