import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import workspaceActions from '@/actions/workspaceActions';
import { SHOULD_SHOW_SIGN_UP_BUTTON } from '@/constants/configConstants';
import withUser from '@auth/utils/withUser';
import LoginPage from '@auth/components/LoginPage';
import SignupPage from '@auth/components/SignupPage';

const ClaimInvite = ({ dispatch, history, isUserLoggedIn, username }) => {
  const { workspaceCode = '' } = queryString.parse(window.location.search);

  useEffect(() => {
    if (!isUserLoggedIn || !workspaceCode) return;

    dispatch(workspaceActions.claimWorkspaceInvite(workspaceCode)).then(
      response => {
        const urlSuffix = response ? `/${encodeURI(response.projectName)}` : '';
        history.push(`/${username}${urlSuffix}`);
      }
    );
  }, [dispatch, history, isUserLoggedIn, username, workspaceCode]);

  const PageComponent = SHOULD_SHOW_SIGN_UP_BUTTON ? SignupPage : LoginPage;

  return <PageComponent workspaceCode={workspaceCode} />;
};

ClaimInvite.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired
};

export default withUser(withRouter(ClaimInvite));
