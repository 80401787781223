import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { Button, TextButton } from '@ds';
import { Divider } from '@DesignSystem/data-display';
import VerticalLinearStepper from '@DesignSystem/data-display/VerticalStepper/VerticalStepper';
import { LineChart } from '../LineChart';
import {
  CHART_BASIC_LAYOUT,
  makeLineChartShape,
  transformToSingleLine,
  getMetricValue,
  getAlertOperatorSign,
  makeLineChartThresholdShape
} from '@mpm-druid/utils';
import NoAlertPreviewIcon from '@Icons-outdated/NoAlertPreviewIcon.svg';
import { useAddAlertMutation, useAlertPreview } from '@mpm-druid/api';
import { StepOne } from './StepOne';
import StepThree from './StepThree';
import {
  EMPTY_OVERVIEW_MESSAGE,
  DEFAULT_VERSIONS,
  PANEL_SECTION_TO_METRIC_CATEGORY,
  INTERVAL_TYPE_VALUES,
  SOURCE_TYPE,
  FEATURE_TYPES,
  HOURLY_CRON_VALUE,
  DAILY_CRON_VALUE
} from '@mpm-druid/constants';
import { getPredicateFromNode } from '../PredicatesPopover';
import './AddAlertPage.scss';

const getCronValue = timeInterval => {
  if (timeInterval === INTERVAL_TYPE_VALUES.HOURLY) {
    return HOURLY_CRON_VALUE;
  }
  return DAILY_CRON_VALUE;
};

const getISODate = daysAgoFromToday => {
  return new Date(
    new Date().getTime() - daysAgoFromToday * 24 * 60 * 60 * 1000
  ).toISOString();
};

const splittingOperators = ['>', '<', '<=', '>=', '=', '!='];

const initialPredicate = {
  id: uuidv4(),
  isTextMode: false,
  isDisabledTextSwitch: false,
  feature: null,
  operator: null,
  threshold: '',
  predicateStr: '',
  errorMessage: ''
};

const RangeInterval = INTERVAL_TYPE_VALUES.HOURLY;

const initialStepOneState = {
  metricCategory: undefined,
  attributeName: undefined,
  metricSpecType: undefined,
  customMetricId: undefined,
  refDriftDatasetModelId: undefined,
  thresholdOperator: 'GREATER_THAN',
  thresholdType: 'Manual',
  delayValue: 1,
  thresholdValue: 1,
  modelVersion: 'all',
  timePeriod: INTERVAL_TYPE_VALUES.DAILY
};

export function AddAlertPage({ model }) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepOneFieldsState, setStepOneFieldsState] = useState(
    initialStepOneState
  );
  const [predicateData, setPredicateData] = useState(initialPredicate);
  const [stepThreeFieldsState, setStepThreeFieldsState] = useState({
    alertName: null,
    alertDescription: null
  });
  const [showPredicate, setShowPredicate] = useState(false);
  const [isStepOneDone, setIsStepOneDone] = useState(false);
  const evaluationWindowHours =
    stepOneFieldsState.timePeriod === INTERVAL_TYPE_VALUES.DAILY ? 24 : 1;
  const delayHours =
    stepOneFieldsState.timePeriod === INTERVAL_TYPE_VALUES.DAILY
      ? stepOneFieldsState.delayValue * 24
      : Number(stepOneFieldsState.delayValue);

  const history = useHistory();
  const {
    section,
    intervalType,
    version,
    featureName,
    customMetricId,
    driftAlgorithm,
    percentileType,
    filterData
  } = queryString.parse(history.location.search);

  const modelVersion =
    stepOneFieldsState.modelVersion !== 'all'
      ? stepOneFieldsState.modelVersion
      : null;

  const todayDateISO = useMemo(() => new Date().toISOString(), []);

  const previousDateISO = useMemo(() => {
    return getISODate(30);
  }, []);

  const { data } = useAlertPreview(
    {
      modelId: model.id,
      name: 'Monitor Preview !',
      runCronExpression: getCronValue(stepOneFieldsState.timePeriod),
      evaluationWindowHours,
      alertSpec: {
        type: 'Manual',
        rule: stepOneFieldsState.thresholdOperator,
        threshold: Number(stepOneFieldsState.thresholdValue)
      },
      metricSpec: {
        customMetricId: stepOneFieldsState.customMetricId,
        type: stepOneFieldsState.metricSpecType,
        attributeName: stepOneFieldsState.attributeName
      },
      predicate: getPredicateFromNode(predicateData) || undefined,
      delayHours,
      modelVersion: modelVersion || undefined,
      refDriftDatasetModelId: stepOneFieldsState.refDriftDatasetModelId,
      totalRangeWindowHours: 720, // Time period for chart data preview 720 hours
      totalRangeIntervalType: RangeInterval,
      description: stepOneFieldsState.description
    },
    {
      enabled: isStepOneDone,
      keepPreviousData: true,
      retry: false
    }
  );

  const chartData = useMemo(() => {
    if (data?.metricResults?.length) {
      return [
        ...transformToSingleLine({
          points: data.metricResults,
          queryString: getPredicateFromNode(predicateData)
        })
      ];
    }

    return [];
  }, [data, predicateData]);

  const enabledChartData = chartData.length > 0;

  const handleValueChange = (_, newValue, key) => {
    setPredicateData(prev => {
      if (
        key === 'feature' &&
        newValue &&
        typeof newValue === 'object' &&
        'type' in newValue &&
        prev.feature?.type !== newValue.type
      ) {
        return {
          ...prev,
          operator: null,
          threshold: newValue.type === FEATURE_TYPES.CATEGORICAL ? '' : 0,
          [key]: newValue
        };
      }
      return {
        ...prev,
        [key]: newValue
      };
    });
  };

  const handleSwitchText = () => {
    setPredicateData(prev => {
      return {
        ...prev,
        predicateStr: getPredicateFromNode(prev),
        feature: null,
        operator: null,
        threshold: '',
        isTextMode: !prev.isTextMode
      };
    });
  };

  const handleRemoveItem = () => {
    setPredicateData(initialPredicate);
  };

  const FEATURES_OPTIONS = useMemo(() => {
    if (!model) return [];

    if (model.features?.length) {
      return [
        ...model.features
          .filter(
            feature => feature.source === SOURCE_TYPE.model_input_features
          )
          .map(feature => {
            return {
              label: `feature_${feature.name}`,
              value: `feature_${feature.name}`,
              type: feature.type
            };
          }),
        ...model.features
          .filter(
            feature => feature.source === SOURCE_TYPE.model_output_features
          )
          .map(feature => {
            return {
              label: `prediction_${feature.name}`,
              value: `prediction_${feature.name}`,
              type: feature.type
            };
          })
      ];
    }
    return [];
  }, [model]);

  const handleDropdownState = useCallback(
    (newValue, input, optionData) => {
      setStepOneFieldsState(prev => {
        if (
          input.name === 'metricCategory' &&
          newValue !== stepOneFieldsState.metricCategory
        ) {
          return { ...initialStepOneState, [input.name]: newValue };
        }
        if (
          input.name === 'attributeName' &&
          !!stepOneFieldsState.attributeName
        ) {
          const prevFeatureType = FEATURES_OPTIONS.find(
            item => item.value === stepOneFieldsState.attributeName
          )?.type;
          if (prevFeatureType !== optionData.type) {
            const newState = {
              ...prev,
              [input.name]: newValue,
              metricSpecType: undefined
            };
            return newState;
          }
        }
        if (input.name === 'timePeriod') {
          return {
            ...prev,
            [input.name]: newValue,
            delayValue: 1
          };
        }
        const newState = { ...prev, [input.name]: newValue };

        return newState;
      });
    },
    [stepOneFieldsState, FEATURES_OPTIONS]
  );

  const addAlertMutation = useAddAlertMutation();

  const handleAddAlert = () => {
    const payload = {
      modelId: model.id,
      runCronExpression: getCronValue(stepOneFieldsState.timePeriod),
      evaluationWindowHours,
      alertSpec: {
        type: 'Manual',
        rule: stepOneFieldsState.thresholdOperator,
        threshold: Number(stepOneFieldsState.thresholdValue)
      },
      metricSpec: {
        customMetricId: stepOneFieldsState.customMetricId,
        type: stepOneFieldsState.metricSpecType,
        attributeName: stepOneFieldsState.attributeName
      },
      predicate: getPredicateFromNode(predicateData) || undefined,
      delayHours,
      modelVersion: modelVersion || undefined,
      refDriftDatasetModelId: stepOneFieldsState.refDriftDatasetModelId,
      totalRangeWindowHours: 720, // Time period for chart data preview 720 hours
      totalRangeIntervalType: RangeInterval,
      name: stepThreeFieldsState.alertName,
      description: stepThreeFieldsState.alertDescription
    };

    addAlertMutation.mutate({ ...payload });
  };

  // Pre-fill data after redirection from the MPM Charts pages
  useEffect(() => {
    if (section) {
      setStepOneFieldsState(prevState => ({
        ...prevState,
        metricCategory: PANEL_SECTION_TO_METRIC_CATEGORY[section],
        timePeriod: intervalType,
        modelVersion: version === 'null' ? DEFAULT_VERSIONS[0].value : version,
        attributeName: featureName,
        customMetricId: customMetricId || undefined,
        metricSpecType:
          percentileType ||
          getMetricValue({
            section,
            driftAlgorithm,
            featureName,
            features: FEATURES_OPTIONS
          }) ||
          undefined
      }));
    }

    if (filterData) {
      setShowPredicate(true);
      const [feature, operator, threshold] = filterData.split(' ');
      const matchFeatureOption = FEATURES_OPTIONS.find(
        option => option?.value === feature
      );
      if (splittingOperators.includes(operator) && matchFeatureOption) {
        setPredicateData(prevState => ({
          ...prevState,
          feature: matchFeatureOption,
          operator: { label: operator, value: operator },
          threshold: threshold.replace(/^'|'$/g, '')
        }));
      } else {
        setPredicateData(prevState => ({
          ...prevState,
          predicateStr: filterData,
          isTextMode: true
        }));
      }
    }
  }, [section]);

  // AUTO SET ALERT NAME
  useEffect(() => {
    let name = '';
    if (stepOneFieldsState.attributeName) {
      name = `${stepOneFieldsState.attributeName} ${stepOneFieldsState.metricSpecType}`;
    } else if (stepOneFieldsState.customMetricId) {
      name = `${
        model.customMetrics.find(
          metric => metric.metricId === stepOneFieldsState.customMetricId
        )?.name
      }`;
    } else {
      name = stepOneFieldsState.metricSpecType;
    }
    setStepThreeFieldsState(prevState => ({
      ...prevState,
      alertName: `${name} ${getAlertOperatorSign(
        stepOneFieldsState.thresholdOperator
      )} ${stepOneFieldsState.thresholdValue}`
    }));
  }, [
    model.customMetrics,
    stepOneFieldsState.thresholdOperator,
    stepOneFieldsState.attributeName,
    stepOneFieldsState.metricSpecType,
    stepOneFieldsState.metricCategory,
    stepOneFieldsState.thresholdValue,
    stepOneFieldsState.customMetricId
  ]);

  const steps = useMemo(
    () => [
      {
        label: 'What do you want to track?',
        isCompleted: false,
        content: (
          <StepOne
            model={model}
            setIscurrentStepDone={setIsStepOneDone}
            featureOptions={FEATURES_OPTIONS}
            modelCustomMetrics={model?.customMetrics}
            modelDistributions={model?.availableDistributions}
            previewMetricResultValue={data?.previewMetricResultValue}
            fieldsState={stepOneFieldsState}
            setFieldsState={setStepOneFieldsState}
            setActiveStep={setActiveStep}
            handleDropdownState={handleDropdownState}
            predicateData={predicateData}
            handleSwitchText={handleSwitchText}
            handleRemoveItem={handleRemoveItem}
            handleValueChange={handleValueChange}
            showPredicate={showPredicate}
            setShowPredicate={setShowPredicate}
          />
        )
      },
      {
        label: 'Whats the alert name?',
        content: (
          <StepThree
            fieldsState={stepThreeFieldsState}
            setFieldsState={setStepThreeFieldsState}
          />
        )
      }
    ],

    [
      model,
      stepOneFieldsState,
      stepThreeFieldsState,
      predicateData,
      showPredicate,
      handleDropdownState,
      data?.previewMetricResultValue,
      FEATURES_OPTIONS,
      showPredicate,
      handleAddAlert,
      handleDropdownState
    ]
  );

  return (
    <div className="add-alert-container">
      <div className="add-alert-wrapper">
        <h5>Add Alert Rule</h5>
        <Divider margin={0} />
        <Box display="flex" flexDirection="row" width="100%">
          <Box width="780px">
            <VerticalLinearStepper
              changeCurrentStep={index =>
                index < activeStep || isStepOneDone
                  ? setActiveStep(index)
                  : null
              }
              steps={steps}
              activeStep={activeStep}
            />
          </Box>
          <Box
            height="354px"
            marginTop="63px"
            borderLeft="1px solid #e2e2e2"
            padding="0 20px 0 32px"
            flexGrow={1}
          >
            <div className="preview-container-chart">
              <span className="chart-preview-title">Alert Preview</span>
              <LineChart
                data={chartData}
                height={350}
                disableZoom
                showTooltip={false}
                emptyStateBoxConfig={{
                  showEmptyStateBox: !enabledChartData,
                  boxIcon: <NoAlertPreviewIcon />,
                  boxMessage: EMPTY_OVERVIEW_MESSAGE
                }}
                layoutData={{
                  shapes: [
                    ...makeLineChartThresholdShape(
                      stepOneFieldsState.thresholdValue,
                      enabledChartData
                    ),
                    ...makeLineChartShape(
                      enabledChartData,
                      evaluationWindowHours,
                      delayHours
                    )
                  ],
                  yaxis: {
                    ...CHART_BASIC_LAYOUT.yaxis,
                    range: [0, 1.01]
                  },
                  xaxis: {
                    ...CHART_BASIC_LAYOUT.xaxis,
                    autorange: !!enabledChartData,
                    range: [previousDateISO, todayDateISO]
                  }
                }}
              />
            </div>
          </Box>
        </Box>
        <Box display="flex" padding="0 24px 35px 55px">
          <Button
            onClick={handleAddAlert}
            disabled={
              stepThreeFieldsState.alertName === '' ||
              !isStepOneDone ||
              addAlertMutation.isLoading
            }
          >
            Save
          </Button>
          <TextButton
            className="add-alert-cancel-btn"
            onClick={() => history.goBack()}
          >
            Cancel
          </TextButton>
        </Box>
      </div>
    </div>
  );
}
