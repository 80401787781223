import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { IconButton, Tooltip } from '@ds';
import { getMaxAllowedPinnedExperiments } from '@/reducers/ui/projectsUiReducer';
import { DSPinIcon } from '@ds-icons';

const PinExperimentButton = ({
  experimentKey,
  onTogglePinned,
  pinned,
  pinnedExperimentKeys
}) => {
  const maxAllowedPinnedExperiments = useSelector(
    getMaxAllowedPinnedExperiments
  );

  const hasReachedMaxedPinned =
    pinnedExperimentKeys.length === maxAllowedPinnedExperiments && !pinned;

  const handleClick = () => {
    if (hasReachedMaxedPinned) return;

    onTogglePinned(experimentKey);
  };

  const title = hasReachedMaxedPinned
    ? `Only ${maxAllowedPinnedExperiments} pinned experiments are allowed`
    : pinned
    ? 'Unpin experiment'
    : 'Pin experiment';

  return (
    <Tooltip content={title} placement="top">
      <IconButton type="secondary" onClick={handleClick} Icon={<DSPinIcon />} />
    </Tooltip>
  );
};

PinExperimentButton.defaultProps = {
  pinnedExperimentKeys: []
};

PinExperimentButton.propTypes = {
  experimentKey: PropTypes.string.isRequired,
  onTogglePinned: PropTypes.func.isRequired,
  pinned: PropTypes.bool.isRequired,
  pinnedExperimentKeys: PropTypes.array
};

export default PinExperimentButton;
