import styles from '@projects/components/LLMDashboardHeader/LLMDashboardHeader.module.scss';
import React from 'react';

export default function inputDropdownItem(item) {
  return (
    <div className={styles.operatorItemRowContainer}>
      <div className={styles.operatorItemRowInnerContainer}>
        <p>{item.label}</p>
        <div className={styles.operatorContainer}>
          attribute: {item.formattedValue}
        </div>
      </div>
      <p>operator</p>
    </div>
  );
}
