import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@DesignSystem/controllers';
import { SearchIcon } from '@Icons-outdated';
import ManageUsersModalTable from '@shared/components/ManageUsersModalTable';

import classNames from './ManageUsersModal.module.scss';

const ManageUsersModalContent = ({
  filterValue,
  setFilterValue,
  members,
  manageUsersModalCount,
  workspace,
  onChangePermissions,
  debouncedFilterValue,
  onRemoveUsers,
  currentUser,
  ifCurrentWsOnlyOne,
  isFetching
}) => (
  <div className={classNames.manageUsersModal}>
    <div className={classNames.manageUsersModalController}>
      <Input
        placeholder="Search"
        value={filterValue}
        onChange={setFilterValue}
        InlinePrefixIcon={SearchIcon}
      />

      <div className={classNames.manageUsersModalCount}>
        {`${manageUsersModalCount} ${
          manageUsersModalCount === 1 ? 'user' : 'users'
        }`}
      </div>
    </div>

    <ManageUsersModalTable
      isFetching={isFetching}
      ifCurrentWsOnlyOne={ifCurrentWsOnlyOne}
      filterValue={debouncedFilterValue}
      members={members}
      onChangePermissions={onChangePermissions}
      onRemoveUsers={onRemoveUsers}
      workspaceName={workspace.workspaceName}
      currentUser={currentUser}
    />
  </div>
);

ManageUsersModalContent.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      isMember: PropTypes.bool,
      isAdmin: PropTypes.bool,
      username: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  isFetching: PropTypes.bool,
  ifCurrentWsOnlyOne: PropTypes.bool.isRequired,
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  onRemoveUsers: PropTypes.func.isRequired,
  debouncedFilterValue: PropTypes.func.isRequired,
  onChangePermissions: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    workspaceName: PropTypes.string,
    workspaceId: PropTypes.string
  }).isRequired,
  manageUsersModalCount: PropTypes.number.isRequired
};

ManageUsersModalContent.defaultProps = {
  isFetching: false
};

export default ManageUsersModalContent;
