import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

import { SORT_BY_DIRECTION } from '@artifacts/constants/constants';
import { COLORS } from '@/constants/colorConstants';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: 'white',
    color: '#9f9f9f',
    fontSize: 12
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const ArtifactVersionsTableHeader = ({
  columns,
  order,
  orderBy,
  onRequestSort
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const getSortIcon = id => {
    if (orderBy !== id) return null;

    const SortIcon =
      order === SORT_BY_DIRECTION.ASC ? ArrowUpwardIcon : ArrowDownwardIcon;

    const style = {
      color: COLORS.primary,
      fontSize: 20,
      paddingLeft: 5,
      marginBottom: 2
    };

    return <SortIcon style={style} />;
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ id, label, align = 'left' }) => (
          <StyledTableCell
            key={id}
            align={align}
            sortDirection={orderBy === id ? order : false}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : SORT_BY_DIRECTION.ASC}
              onClick={createSortHandler(id)}
              IconComponent={() => getSortIcon(id)}
              style={{ whiteSpace: 'nowrap', color: '#9f9f9f' }}
            >
              {label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ArtifactVersionsTableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf([SORT_BY_DIRECTION.ASC, SORT_BY_DIRECTION.DESC])
    .isRequired,
  orderBy: PropTypes.string.isRequired
};

export default ArtifactVersionsTableHeader;
