import React, { useEffect, useState } from 'react';

import { Checkbox } from '@ds';

import styles from './ImageFilterSection.module.scss';

export type ImageFilterSectionChanges = {
  isGrayScale: boolean;
  isSmoothed: boolean;
};

export function ImageFilterSection({
  isGrayScale: controlledIsGrayScale = false,
  isSmoothed: controlledIsSmoothed = true,
  onChangeSettings
}: {
  isGrayScale?: boolean;
  isSmoothed?: boolean;
  onChangeSettings: (changes: ImageFilterSectionChanges) => void;
}) {
  const [isGrayScale, setIsGrayScale] = useState(controlledIsGrayScale);
  const [isSmoothed, setIsSmoothed] = useState(controlledIsSmoothed);

  useEffect(() => {
    setIsGrayScale(controlledIsGrayScale);
  }, [controlledIsGrayScale]);

  useEffect(() => {
    setIsSmoothed(controlledIsSmoothed);
  }, [controlledIsSmoothed]);

  return (
    <div className={styles.container}>
      <label className={styles.row}>
        <Checkbox
          checked={isSmoothed}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newIsSmoothed = event.target.checked;
            setIsSmoothed(newIsSmoothed);
            onChangeSettings({
              isGrayScale,
              isSmoothed: newIsSmoothed
            });
          }}
        />
        <span>Smoothing</span>
      </label>

      <label className={styles.row}>
        <Checkbox
          checked={isGrayScale}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newIsGrayScale = event.target.checked;
            setIsGrayScale(newIsGrayScale);
            onChangeSettings({
              isGrayScale: newIsGrayScale,
              isSmoothed
            });
          }}
        />
        <span>Grayscale</span>
      </label>
    </div>
  );
}
