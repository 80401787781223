import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';

import {
  DEFAULT_CHART_TEMPLATE_NAME,
  UNTITLED_CHART_TEMPLATE_NAME
} from '@experiment-management-shared/constants/chartConstants';

export const createDefaultFileName = ({
  entityType,
  isArchive,
  customPaths,
  projectName,
  viewName,
  workspace
}) => {
  const defaultPaths = [
    workspace,
    projectName,
    viewName,
    ...customPaths,
    entityType
  ];
  const fileNamePaths = isArchive
    ? defaultPaths.concat('archived')
    : defaultPaths;

  return fileNamePaths
    .filter(
      str =>
        !isEmpty(str) &&
        ![UNTITLED_CHART_TEMPLATE_NAME, DEFAULT_CHART_TEMPLATE_NAME].includes(
          str
        )
    )
    .map(str =>
      toLower(str)
        .replace(/[,' '-]/gi, '_') // replace comma,whitespace,hyphen with _
        .trim()
    )
    .join('_');
};
