import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BasicModal, DeleteModalBody } from '@DesignSystem/modals';

const DeleteUserFromOrganizationModal = ({
  open,
  onClose,
  onConfirm,
  isDeleting,
  userName
}) => {
  const [inputValue, setInputValue] = useState('');

  const renderContent = () => (
    <DeleteModalBody
      description={`The user “${userName}” will be removed. Once deleted, This user will no longer be a part of your organization.`}
      onChangeCallback={setInputValue}
    />
  );

  const isPossibleToRemove = inputValue === 'DELETE';

  const handleConfirm = () => {
    if (isPossibleToRemove) {
      onConfirm();
      setInputValue(null);
    }
  };

  const handleClose = () => {
    onClose();
    setInputValue(null);
  };

  return (
    <BasicModal
      open={open}
      onClose={handleClose}
      title="Delete User"
      content={renderContent()}
      primaryButtonText="Confirm"
      onPrimaryButtonClick={handleConfirm}
      isPrimaryButtonDisabled={!isPossibleToRemove || isDeleting}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={handleClose}
    />
  );
};

DeleteUserFromOrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired
};

export default DeleteUserFromOrganizationModal;
