import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';

import { Button, TextButton } from '@ds';
import { Input, Select } from '@DesignSystem/controllers';
import { mapModelVersions, getDelayValue } from '@mpm-druid/utils';
import {
  ALERT_OPERATOR_OPTIONS,
  ALERT_TYPE_OPTIONS,
  DEFAULT_VERSIONS,
  FEATURE_TYPES,
  METRIC_CATEGORY_OPTIONS,
  NUMERICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS,
  CATEGORICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS,
  DRIFT_CALCULATION_OPTIONS,
  INTERVAL_TYPE_OPTIONS,
  METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL,
  SYSTEM_METRICS,
  DRIFT_METRICS,
  INTERVAL_TYPE_VALUES
} from '@mpm-druid/constants';
import { DSPlusIcon } from '@ds-icons';
import { SinglePredicate } from '../PredicatesPopover/SinglePredicate';

export const isValidPredicate = node => {
  const { isTextMode, feature, operator, threshold, predicateStr } = node;
  if (isTextMode) {
    return !!predicateStr;
  }
  return (
    (!!feature && !!operator && threshold !== '') ||
    (!feature && !operator && threshold === '')
  );
};

const mapCustomMetrics = modelCustomMetrics =>
  modelCustomMetrics.map(({ name, metricId }) => ({
    value: metricId,
    label: name
  }));

export const StepOne = ({
  setActiveStep,
  setIscurrentStepDone,
  fieldsState,
  setFieldsState,
  handleDropdownState,
  featureOptions,
  modelCustomMetrics,
  modelDistributions,
  predicateData,
  handleSwitchText,
  handleRemoveItem,
  handleValueChange,
  showPredicate,
  setShowPredicate,
  model
}) => {
  const isDaily = fieldsState.timePeriod === INTERVAL_TYPE_VALUES.DAILY;
  const firstRow = useMemo(() => {
    const row = [
      {
        name: 'metricCategory',
        label: 'Metric category',
        options: METRIC_CATEGORY_OPTIONS
      }
    ];
    if (fieldsState.metricCategory === 'feature_metrics') {
      row.push({
        name: 'attributeName',
        label:
          METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL[fieldsState.metricCategory],
        options: featureOptions,
        withInput: true
      });

      const featureType =
        fieldsState?.attributeName &&
        featureOptions?.find(
          feature => feature.value === fieldsState.attributeName
        )?.type;

      row.push(
        featureType === FEATURE_TYPES.NUMERICAL
          ? NUMERICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS
          : CATEGORICAL_OUTPUT_DISTRIBUTION_WITH_DRIFT_METHODS_OPTIONS
      );
    }
    if (fieldsState.metricCategory === 'system_metrics') {
      row.push({
        name: 'metricSpecType',
        label:
          METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL[fieldsState.metricCategory],
        options: SYSTEM_METRICS
      });
    }

    if (fieldsState.metricCategory === 'custom_metrics') {
      row.push({
        name: 'customMetricId',
        label:
          METRIC_CATEGORY_MAP_METRIC_TYPE_LABEL[fieldsState.metricCategory],
        options: mapCustomMetrics(modelCustomMetrics),
        withInput: true
      });
    }
    return row;
  }, [
    fieldsState.metricCategory,
    fieldsState.attributeName,
    featureOptions,
    modelCustomMetrics
  ]);

  const driftOptions = useMemo(() => {
    let options = [...DRIFT_CALCULATION_OPTIONS];
    if (modelDistributions?.length) {
      options = options.concat(
        modelDistributions.map(({ name, id }) => {
          return {
            label: name,
            value: id
          };
        })
      );
    } else {
      options.push({
        label: 'No training distributions available',
        value: 'not_available_distributions',
        disabled: true
      });
    }

    return options;
  }, [modelDistributions]);

  const firstDistributionRow = [
    {
      name: 'refDriftDatasetModelId',
      options: driftOptions,
      label: 'Drift reference data',
      width: 250
    }
  ];

  const secondRow = [
    {
      name: 'timePeriod',
      hideInput: true,
      options: INTERVAL_TYPE_OPTIONS,
      label: 'Check the alert every',
      width: 220
    },
    {
      name: 'delayValue',
      label: `Evaluation Delay (in ${isDaily ? 'days' : 'hours'})`
    },
    {
      name: 'modelVersion',
      options: DEFAULT_VERSIONS.concat(mapModelVersions(model.versions)),
      label: 'Model version',
      withInput: true
    }
  ];

  const thirdRow = [
    {
      name: 'thresholdType',
      options: ALERT_TYPE_OPTIONS,
      label: 'Alert Type'
    },
    {
      name: 'thresholdOperator',
      options: ALERT_OPERATOR_OPTIONS,
      label: 'Alert operator'
    },
    {
      name: 'thresholdValue',
      label: 'Alert Threshold'
    }
  ];

  const isFirstRowDone = firstRow.every(field => !!fieldsState[field.name]);
  const isFirstStepDone =
    ((fieldsState.attributeName && fieldsState.metricSpecType) ||
      fieldsState.metricSpecType === 'COUNT' ||
      fieldsState.customMetricId) &&
    (!showPredicate || isValidPredicate(predicateData));

  useEffect(() => {
    if (isFirstStepDone) {
      setIscurrentStepDone(true);
    } else {
      setIscurrentStepDone(false);
    }
  }, [isFirstStepDone, setIscurrentStepDone]);

  const handleRemovePredicate = () => {
    setShowPredicate(false);
    handleRemoveItem();
  };

  const renderField = (field, index) => {
    const isDelayField = field.name === 'delayValue';
    if (!field?.options)
      return (
        <div
          className="thresholdInput"
          style={{ width: field.width || '220px' }}
          key={index}
        >
          <Input
            title={field.label}
            value={fieldsState[field.name]}
            inputType={isDelayField ? 'string' : 'number'}
            step={1}
            height="36px"
            style={{ width: field.width || '220px' }}
            onChange={val => {
              const newVal = isDelayField
                ? getDelayValue(val, fieldsState.delayValue, isDaily)
                : val;
              setFieldsState(prevState => ({
                ...prevState,
                [field.name]: newVal
              }));
            }}
          />
        </div>
      );

    return (
      <div
        className="step-one-input"
        style={{ width: field.width || '220px' }}
        key={index}
      >
        <Select
          label={`${field.label}`}
          value={fieldsState[field.name]}
          width={field.width || '220px'}
          onChange={(newValue, optionData) =>
            handleDropdownState(newValue, field, optionData)
          }
          options={field?.options || []}
          key={field.value}
          withInput={field.withInput}
          variant="outlined"
          hideSource
        />
      </div>
    );
  };

  return (
    <>
      <h6>Choose the metric you want track</h6>
      <div className="inputs-row">{firstRow.map(renderField)}</div>
      {DRIFT_METRICS.includes(fieldsState.metricSpecType) && (
        <div className="inputs-row">
          {firstDistributionRow.map(renderField)}
        </div>
      )}
      <>
        <h6 className={cx({ fieldsHidden: !isFirstRowDone })}>
          Define te alert data with
        </h6>
        {isFirstRowDone && (
          <div className="inputs-row second-row">
            {secondRow.map(renderField)}
          </div>
        )}
        {showPredicate && (
          <div className="predicate-wrapper">
            <SinglePredicate
              handleSwitchText={handleSwitchText}
              handleRemoveItem={handleRemovePredicate}
              handleValueChange={handleValueChange}
              featureOptions={featureOptions}
              {...predicateData}
            />
          </div>
        )}
        {!showPredicate && isFirstRowDone && (
          <div className="add-filter-container">
            <TextButton
              type="primary"
              size="medium"
              PrefixIcon={<DSPlusIcon />}
              onClick={() => {
                setShowPredicate(true);
              }}
            >
              Add Filter
            </TextButton>
          </div>
        )}

        <h6 className={cx({ fieldsHidden: !isFirstRowDone })}>
          Define the alert trigger
        </h6>
        {isFirstRowDone && (
          <div className="inputs-row">{thirdRow.map(renderField)}</div>
        )}
      </>

      <Button
        text="Next"
        onClick={() => setActiveStep(prev => prev + 1)}
        disabled={!isFirstStepDone}
      >
        Next
      </Button>
    </>
  );
};
