import { DATA_PANEL_CONCAT_OPTIONS } from '@experiment-management-shared/constants/chartConstants';

export const DEBOUNCE_DELAY = 300;

export const concatenateOptions = [
  {
    value: DATA_PANEL_CONCAT_OPTIONS.rows,
    label: 'Rows'
  },
  {
    value: DATA_PANEL_CONCAT_OPTIONS.columns,
    label: 'Columns'
  }
];
export const joinOptions = [
  { value: 'outer', label: 'Outer' },
  { value: 'inner', label: 'Inner' }
];
