// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComparisonTable-module__comparisonTable--f_Mqn{padding-left:var(--experiment-details-layout-padding);padding-right:var(--experiment-details-layout-padding);padding-bottom:var(--experiment-details-layout-padding)}.ComparisonTable-module__comparisonTable--f_Mqn .ds-react-grid{border:1px solid var(--gray);border-radius:5px;overflow:hidden}.ComparisonTable-module__comparisonTable--f_Mqn .ds-react-grid.virtualized{height:var(--table-maxheight)}.ComparisonTable-module__comparisonTable--f_Mqn .dx-table-cell-row-child{display:none}.ComparisonTable-module__comparisonTable--f_Mqn tr.MuiTableRow-root:has(+ tr:last-child) td:first-child{border-bottom:0 !important}.ComparisonTable-module__comparisonTable--f_Mqn .table-row-view-not-equal{background:#ffeae9}.ComparisonTable-module__comparisonTable--f_Mqn .table-row-view-is-main{border-top:1px solid #ffeae9 !important}.table-tab-contaner__metrics .empty-tab-message{padding-top:101px}.table-tab-contaner__other .empty-tab-message{padding-top:101px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comparisonTable": `ComparisonTable-module__comparisonTable--f_Mqn`
};
module.exports = ___CSS_LOADER_EXPORT___;
