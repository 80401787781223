import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import TooltipKeys from './TooltipKeys';
import { InputLabel } from '@material-ui/core';
import { Slider } from '@design-system-outdated';
import { useDispatch, useSelector } from 'react-redux';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { WhiteTooltip } from '@experiment-management-shared/utils/buildinVisualisationHelpers';
import {
  BUILT_IN_CHART_TYPES,
  HOVER_MODE_OPTIONS
} from '@experiment-management-shared/constants/chartConstants';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import { BETA_FEATURE_LINE_CHART_HOVER_MODE } from '@/lib/betaFeatures';
import LegendTooltipImage from '@Icons-outdated/panels-modal/LegendTooltipImage.svg';
import { InfoIcon } from '@Icons-outdated';
import { Select } from '@DesignSystem/controllers';
import { LEGEND_MODE } from '@experiment-management-shared/constants';
import { getIsSingleExperimentPage } from '@shared/utils/url';

import styles from './GenericVizLegendTab.module.scss';

const DELAYS_MS = 250;
const MIN_EXPERIMENT_COUNT = 1;
const MAX_EXPERIMENT_COUNT = 25;

function GenericVizLegendTab({ chartType }) {
  const dispatch = useDispatch();
  const isSingleExperimentPage = useSelector(getIsSingleExperimentPage);
  const isLineTypeChart =
    chartType === BUILT_IN_CHART_TYPES['BuiltIn/Line'] ||
    chartType === BUILT_IN_CHART_TYPES.curves;
  const needNumberOfExperiments = isLineTypeChart && !isSingleExperimentPage;

  const needHoverModeSelect =
    useBetaFeatureEnabled(BETA_FEATURE_LINE_CHART_HOVER_MODE) &&
    isLineTypeChart;

  const chartForm = useSelector(state =>
    getChartFormByType(state, { chartType })
  );
  const [experimentsCount, setExperimentsCount] = useState(
    chartForm.experimentsCount
  );

  const groupingEnabled = get(chartForm, ['grouping', 'enabled'], false);

  const handleDebouncedChangeExperimentsCount = useCallback(
    debounce(
      count =>
        dispatch(
          dashboardChartsActions.updateChartFormKey(
            chartType,
            'experimentsCount',
            count
          )
        ),
      DELAYS_MS
    ),
    []
  );

  const handleChangeExperimentsCount = count => {
    setExperimentsCount(count);
    handleDebouncedChangeExperimentsCount(count);
  };

  const handleChangeLegendDisplay = useCallback(
    legendMode => () => {
      dispatch(
        dashboardChartsActions.updateChartFormKey(
          chartType,
          'legendMode',
          legendMode
        )
      );
    },
    [chartType, dispatch]
  );

  const renderTooltipContent = () => {
    return (
      <div className={styles.tooltipContent}>
        <div className={styles.text}>
          We show the top n experiments values from the left legend, including
          hovered one. You can always filter, sort, select or pin experiments.
        </div>
        <LegendTooltipImage />
      </div>
    );
  };

  const renderLegendDisplay = () => {
    return (
      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">Legend display</InputLabel>
        <div className={styles.buttonsGroupContainer}>
          <button
            onClick={handleChangeLegendDisplay(LEGEND_MODE.AUTO)}
            className={cx({
              [styles.active]: chartForm.legendMode === LEGEND_MODE.AUTO
            })}
          >
            Auto
          </button>
          <button
            onClick={handleChangeLegendDisplay(LEGEND_MODE.ON)}
            className={cx({
              [styles.active]: chartForm.legendMode === LEGEND_MODE.ON
            })}
          >
            On
          </button>
          <button
            onClick={handleChangeLegendDisplay(LEGEND_MODE.OFF)}
            className={cx({
              [styles.active]: chartForm.legendMode === LEGEND_MODE.OFF
            })}
          >
            Off
          </button>
        </div>
      </div>
    );
  };

  const renderNumberOfExperiments = () => {
    return (
      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">
          <span>Max number of experiments to show on chart tooltip</span>
          <WhiteTooltip title={renderTooltipContent()} placement="bottom-start">
            <div className={styles.inputIcon}>
              <InfoIcon />
            </div>
          </WhiteTooltip>
        </InputLabel>
        <div className={styles.subLabel}>
          This is the max number of experiments in tooltip that will show on
          chart hover, actual number will depend on number of metrics selected.
        </div>
        <div className="slider-wrapper">
          <Slider
            value={experimentsCount}
            min={MIN_EXPERIMENT_COUNT}
            max={MAX_EXPERIMENT_COUNT}
            step={1}
            valueLabelFormat={value => value}
            onChange={handleChangeExperimentsCount}
          />
        </div>
      </div>
    );
  };

  const renderHoverModeSelect = () => {
    return (
      <div className="full-width-input mb-40">
        <InputLabel className="modal-input-label">Hover Mode</InputLabel>
        <div>
          <Select
            value={chartForm.hoverMode}
            onChange={value => {
              dispatch(
                dashboardChartsActions.updateChartFormKey(
                  chartType,
                  'hoverMode',
                  value
                )
              );
            }}
            options={HOVER_MODE_OPTIONS}
            variant="outlined"
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderLegendDisplay()}
      <div className="full-width-input mb-40">
        <TooltipKeys chartType={chartType} disabled={groupingEnabled} />
      </div>
      {needNumberOfExperiments && renderNumberOfExperiments()}
      {needHoverModeSelect && renderHoverModeSelect()}
    </div>
  );
}

GenericVizLegendTab.propTypes = {
  chartType: PropTypes.string
};

export default GenericVizLegendTab;
