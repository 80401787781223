import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';

import chartActions from '@/actions/experimentDetails/chartActionsPlotly';

const STRINGIFY_OPTIONS = {
  skipNull: true,
  arrayFormat: 'comma',
  skipEmptyString: true
};

const PARSE_OPTIONS = {
  arrayFormat: 'comma',
  parseBooleans: true,
  parseNumbers: true
};

const useExperimentViewState = ({
  defaultValue = null,
  experimentKey,
  queryStringParameter,
  template,
  valuePath
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const value = get(template, valuePath, defaultValue);

  // For debounced inputs
  const [tempValue, setTempValue] = useState(value);

  const updateQueryString = useCallback(
    newValue => {
      if (!queryStringParameter) return;

      const parsedQuery = queryString.parse(location.search, PARSE_OPTIONS);
      const stringifiedQuery = queryString.stringify(
        {
          ...parsedQuery,
          [queryStringParameter]: newValue
        },
        STRINGIFY_OPTIONS
      );

      const search = `?${stringifiedQuery}`;

      history.replace({ search }, { preventScroll: true });
    },
    [history, location.search, queryStringParameter]
  );

  const setTemplateValue = useCallback(
    newValue => {
      const update = set({}, valuePath, newValue);

      dispatch(chartActions.updateChartTemplate(experimentKey, update));

      updateQueryString(newValue);
    },
    [dispatch, experimentKey, updateQueryString, valuePath]
  );

  useEffect(() => setTempValue(value), [value]);

  useEffect(() => {
    if (!queryStringParameter) return;

    const { [queryStringParameter]: initialValue } = queryString.parse(
      location.search,
      PARSE_OPTIONS
    );

    if (isUndefined(initialValue)) return;

    setTemplateValue(initialValue);
  }, [queryStringParameter]);

  return [value, setTemplateValue, tempValue, setTempValue];
};

export default useExperimentViewState;
