import React from 'react';
import styles from './OtherSingleTableCell.module.scss';
import { useOtherSingleTableContext } from '../otherSingleTableContext';
import { OtherCellRendererData } from './types';
import MetricsTableEditableActions from '@experiment-details/components/metrics/actions/MetricsTableEditableActions';
import { useSelector } from 'react-redux';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';

type OtherSingleTableActionCellProps = OtherCellRendererData;

const OtherSingleTableActionCell: React.FC<OtherSingleTableActionCellProps> = ({
  row
}) => {
  const {
    onEditOtherParams,
    onDeleteOtherParam
  } = useOtherSingleTableContext();
  const canEdit = useSelector(isUserAllowedToEditProject);

  return (
    <div className={styles.othersTableActionCell}>
      {row.editable && (
        <MetricsTableEditableActions
          onEdit={() => onEditOtherParams(row.name, row)}
          onDelete={() => onDeleteOtherParam(row.name)}
          disabled={!canEdit}
        />
      )}
    </div>
  );
};

export default OtherSingleTableActionCell;
