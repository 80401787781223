import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';

import { Tooltip } from '@ds';
import { Select } from '@DesignSystem/controllers';
import { InfoIcon } from '@Icons-outdated';
import { useModelDetails, useModelDistributionSet } from '@mpm-druid/api';
import { TOOLTIP_MESSAGE } from '@mpm-druid/constants';
import styles from './CustomTransformationsPage.modules.scss';

const DRIFT_CALCULATION_OPTIONS = [
  { groupName: 'Time based', label: 'Time based' },
  {
    label: 'Previous Time Window',
    value: 'prev_time_window',
    disabled: false
  },
  {
    groupName: 'Training distribution',
    label: 'Training distribution',
    disabled: false
  }
];

const RenderTooltip = () => {
  return (
    <Tooltip content={TOOLTIP_MESSAGE} placement="right">
      <span className={styles.iconWrapper}>
        <InfoIcon className={styles.overviewInfoIcon} />
      </span>
    </Tooltip>
  );
};

type ModelOverviewProps = {
  createdAt: string;
  modelId: string;
  name: string;
};

type DistributionData = {
  name: string;
  id: string;
};

type DistributionState = {
  modelHealth: string;
  baseLine: string;
};

const initialState = {
  modelHealth: '',
  baseLine: 'prev_time_window'
};

const ModelOverview = ({ createdAt, modelId, name }: ModelOverviewProps) => {
  const [formData, setFormData] = useState<DistributionState>(initialState);
  const { baseLine } = formData;

  const { data: modelData, isLoading: modelDataLoading } = useModelDetails({
    modelId
  });
  const { distributionId, availableDistributions } = modelData?.model || {};

  const setModelDistribution = useModelDistributionSet();

  useEffect(() => {
    if (distributionId && availableDistributions?.length) {
      const activeOption = availableDistributions.find(
        ({ id }: DistributionData) => id === distributionId
      )?.id;
      if (activeOption) {
        setFormData(prevState => {
          return { ...prevState, baseLine: activeOption };
        });
      }
    } else {
      setFormData(prevState => {
        return { ...prevState, baseLine: initialState.baseLine };
      });
    }
  }, [distributionId, availableDistributions, modelData]);

  const driftOptions = useMemo(() => {
    let options = [...DRIFT_CALCULATION_OPTIONS];
    if (availableDistributions?.length) {
      options = options.concat(
        availableDistributions.map(({ name, id }: DistributionData) => {
          return {
            label: name,
            value: id,
            disabled: false
          };
        })
      );
    } else {
      options.push({
        label: 'No training distributions available',
        value: 'not_available_distributions',
        disabled: true
      });
    }

    return options;
  }, [modelData]);

  const changeHandler = (value: string) => {
    if (value !== formData.baseLine) {
      const id = value === DRIFT_CALCULATION_OPTIONS[1].value ? null : value;
      setModelDistribution.mutate({
        distributionId: id,
        prevValue: formData.baseLine,
        changeDistributionHandler: setFormData
      });
    }
    setFormData(prevState => {
      return { ...prevState, baseLine: value };
    });
  };

  return (
    <div className={styles.modelOverviewWrapper}>
      <h4>Model Overview</h4>
      <div className={styles.overviewItemWrapper}>
        <span className={styles.dataContent}>Model name:&nbsp;</span>
        <span className={styles.dataValue}>{name}</span>
      </div>
      <div className={styles.overviewItemWrapper}>
        <span className={styles.dataContent}>Created date:&nbsp;</span>
        <span className={styles.dataValue}>
          {moment(createdAt).format('MM/DD/YYYY')}
        </span>
      </div>
      <Select
        label="Baseline for data drift calculation:"
        value={baseLine}
        onChange={changeHandler}
        disabled={modelDataLoading}
        options={driftOptions}
        width="260px"
        variant="outlined"
        withInput={false}
        withTooltip
        renderTooltip={RenderTooltip}
      />
    </div>
  );
};

export default ModelOverview;
