import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const GoBackLink = ({ to, text }) => {
  return (
    <Link className="flex alignCenter justifyCenter" to={to}>
      <ArrowBackIcon fontSize="small" /> {text}
    </Link>
  );
};

GoBackLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default GoBackLink;
