import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import isDate from 'lodash/isDate';
import find from 'lodash/find';

import { Input } from '@DesignSystem/controllers';
import { styled } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { createPortal } from 'react-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateAsUtc } from '@shared/utils/displayHelpers';

import UseFilterOptionsWrapper from '@API/query/useFilterOptions';

import {
  getFilterDefaultValue,
  getIsBetween,
  getIsEqual,
  HMSFormatToSeconds,
  INPUT_TYPE
} from '@shared/utils/filterHelpers';

import ColumnValues from './ColumnValues';

const MAX_HOURS_DURATION = 9999;

const StyledTextField = styled(TextField)({
  '& .MuiFormLabel-root': {
    fontSize: '14px'
  },
  '& .MuiInputBase-input': {
    fontSize: '14px'
  }
});

const FilterInput = ({
  disabled,
  filterValue,
  columnName,
  onChange,
  onSubmit,
  operator
}) => {
  const { inputType } = operator;
  const [options, setOptions] = useState([]);
  const isBetween = getIsBetween(operator.operator);
  const isEqual = getIsEqual(operator.operator);

  const needOptions =
    isEqual && [INPUT_TYPE.STRING, INPUT_TYPE.DOUBLE].includes(inputType);

  // eslint-disable-next-line no-unused-vars
  const [defaultRelative, _] = getFilterDefaultValue(
    filterValue,
    inputType,
    isBetween
  );

  const [defaultStartDate, defaultEndDate] = getFilterDefaultValue(
    filterValue,
    inputType,
    isBetween
  );

  const [defaultTimeStart, defaultTimeEnd] = getFilterDefaultValue(
    filterValue,
    inputType,
    isBetween
  );

  const [defaultStartINumber, defaultEndNumber] = getFilterDefaultValue(
    filterValue,
    inputType,
    isBetween
  );

  // INPUT_TYPE.RELATIVE
  const [relative, setRelative] = useState(defaultRelative);

  // INPUT_TYPE.DATETIME
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  // INPUT_TYPE.TIME_NUMBER
  const [timeSecondStart, setTimeSecondStart] = useState(defaultTimeStart[2]);
  const [timeMinuteStart, setTimeMinuteStart] = useState(defaultTimeStart[1]);
  const [timeHourStart, setTimeHourStart] = useState(defaultTimeStart[0]);
  const [timeSecondEnd, setTimeSecondEnd] = useState(defaultTimeEnd[2]);
  const [timeMinuteEnd, setTimeMinuteEnd] = useState(defaultTimeEnd[1]);
  const [timeHourEnd, setTimeHourEnd] = useState(defaultTimeEnd[0]);

  // INPUT_TYPE.DOUBLE
  const [startNumber, setStartNumber] = useState(defaultStartINumber);
  const [endNumber, setEndNumber] = useState(defaultEndNumber);

  // hooks that marks rule as invalid in case we don't have selected variant in possible options
  useEffect(() => {
    if (disabled) return;
    onChange(
      filterValue,
      needOptions && !find(options, { value: filterValue?.toString() })
    );
  }, [options, disabled, filterValue, onChange, needOptions]);

  useEffect(() => {
    if (inputType !== INPUT_TYPE.DATETIME || disabled) return;

    if (!isDate(startDate) || !isDate(endDate)) {
      return;
    }

    let value;
    if (isBetween) {
      value = [formatDateAsUtc(startDate), formatDateAsUtc(endDate)];
    } else {
      value = formatDateAsUtc(startDate);
    }

    onChange(value);
  }, [startDate, endDate, inputType, isBetween, onChange, disabled]);

  useEffect(() => {
    if (inputType !== INPUT_TYPE.RELATIVE || disabled) return;

    onChange(parseInt(relative, 10));
  }, [relative, inputType, onChange, disabled]);

  useEffect(() => {
    if (inputType !== INPUT_TYPE.DOUBLE || disabled) return;

    if (isBetween) {
      if (isNaN(startNumber) || isNaN(endNumber)) {
        return;
      }

      onChange([parseFloat(startNumber), parseFloat(endNumber)]);
    } else {
      if (isNaN(startNumber)) {
        return;
      }

      onChange(parseFloat(startNumber));
    }
  }, [startNumber, endNumber, inputType, isBetween, onChange, disabled]);

  useEffect(() => {
    if (inputType !== INPUT_TYPE.TIME_NUMBER || disabled) return;

    const timeStart =
      `${parseInt(timeHourStart, 10) || 0}:` +
      `${parseInt(timeMinuteStart, 10) || 0}:` +
      `${parseInt(timeSecondStart, 10) || 0}`;

    if (isBetween) {
      const timeEnd =
        `${parseInt(timeHourEnd, 10) || 0}:` +
        `${parseInt(timeMinuteEnd, 10) || 0}:` +
        `${parseInt(timeSecondEnd, 10) || 0}`;

      onChange([HMSFormatToSeconds(timeStart), HMSFormatToSeconds(timeEnd)]);
    } else {
      onChange(HMSFormatToSeconds(timeStart));
    }
  }, [
    timeHourStart,
    timeMinuteStart,
    timeSecondStart,
    timeHourEnd,
    timeMinuteEnd,
    timeSecondEnd,
    inputType,
    isBetween,
    onChange,
    disabled
  ]);

  const handleTimeChange = (setter, max = 59) => event => {
    const { value } = event.target;

    if (value < 0 || value > max) {
      return;
    }

    setter(value);
  };

  const overrideEnter = event => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const renderContent = () => {
    if (inputType === INPUT_TYPE.NONE || inputType === INPUT_TYPE.BOOLEAN) {
      return null;
    }

    if (inputType === INPUT_TYPE.TIME_NUMBER) {
      const hoursInputProperty = { min: 0, max: MAX_HOURS_DURATION };
      const minAndSecInputProperty = { min: 0, max: 59 };

      if (isBetween) {
        return (
          <div className="between-date-form">
            <h5>Start time</h5>
            <div className="time-wrapper-input">
              <StyledTextField
                disabled={disabled}
                id="time-hour"
                onKeyPress={overrideEnter}
                label="Hours"
                value={timeHourStart}
                onChange={handleTimeChange(
                  setTimeHourStart,
                  MAX_HOURS_DURATION
                )}
                type="number"
                inputProps={hoursInputProperty}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
              />
              <StyledTextField
                disabled={disabled}
                onKeyPress={overrideEnter}
                id="time-minute"
                label="Minutes"
                value={timeMinuteStart}
                onChange={handleTimeChange(setTimeMinuteStart)}
                type="number"
                inputProps={minAndSecInputProperty}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
              />
              <StyledTextField
                disabled={disabled}
                id="time-second"
                label="Seconds"
                onKeyPress={overrideEnter}
                value={timeSecondStart}
                onChange={handleTimeChange(setTimeSecondStart)}
                type="number"
                inputProps={minAndSecInputProperty}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
              />
            </div>
            <hr />
            <h5>End time</h5>
            <div className="time-wrapper-input">
              <StyledTextField
                disabled={disabled}
                id="time-hour"
                onKeyPress={overrideEnter}
                label="Hours"
                value={timeHourEnd}
                onChange={handleTimeChange(setTimeHourEnd, MAX_HOURS_DURATION)}
                type="number"
                inputProps={hoursInputProperty}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
              />
              <StyledTextField
                disabled={disabled}
                id="time-minute"
                onKeyPress={overrideEnter}
                label="Minutes"
                value={timeMinuteEnd}
                onChange={handleTimeChange(setTimeMinuteEnd)}
                type="number"
                inputProps={minAndSecInputProperty}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
              />

              <StyledTextField
                disabled={disabled}
                id="time-second"
                onKeyPress={overrideEnter}
                label="Seconds"
                value={timeSecondEnd}
                onChange={handleTimeChange(setTimeSecondEnd)}
                type="number"
                inputProps={minAndSecInputProperty}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
              />
            </div>
          </div>
        );
      }

      return (
        <div className="time-wrapper-input">
          <StyledTextField
            disabled={disabled}
            id="time-hour"
            onKeyPress={overrideEnter}
            label="Hours"
            value={timeHourStart}
            onChange={handleTimeChange(setTimeHourStart, MAX_HOURS_DURATION)}
            type="number"
            inputProps={hoursInputProperty}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
          />

          <StyledTextField
            disabled={disabled}
            id="time-minute"
            onKeyPress={overrideEnter}
            label="Minutes"
            value={timeMinuteStart}
            onChange={handleTimeChange(setTimeMinuteStart)}
            type="number"
            inputProps={minAndSecInputProperty}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
          />

          <StyledTextField
            disabled={disabled}
            id="time-second"
            onKeyPress={overrideEnter}
            label="Seconds"
            value={timeSecondStart}
            onChange={handleTimeChange(setTimeSecondStart)}
            type="number"
            inputProps={minAndSecInputProperty}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
          />
        </div>
      );
    }

    if (inputType === INPUT_TYPE.DATETIME) {
      if (isBetween) {
        return (
          <div className="between-date-form">
            <small className="label-description">Start date</small>
            <div className="datetime-wrapper-input">
              <DatePicker
                disabled={disabled}
                popperContainer={CalendarContainer}
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={setStartDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                onKeyPress={overrideEnter}
                dateFormat="MMMM dd yyyy, h:mm a"
                timeCaption="time"
                popperModifiers={{
                  preventOverflow: {
                    escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
                  }
                }}
              />
            </div>
            <small className="label-description">End time</small>
            <div className="datetime-wrapper-input">
              <DatePicker
                disabled={disabled}
                popperContainer={CalendarContainer}
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                onChange={setEndDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                onKeyPress={overrideEnter}
                dateFormat="MMMM dd yyyy, h:mm a"
                timeCaption="time"
                popperModifiers={{
                  preventOverflow: {
                    escapeWithReference: false
                  }
                }}
              />
            </div>
          </div>
        );
      }

      return (
        <div className="datetime-wrapper-input">
          <DatePicker
            disabled={disabled}
            popperContainer={CalendarContainer}
            selected={startDate}
            onChange={setStartDate}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            onKeyPress={overrideEnter}
            dateFormat="MMMM dd yyyy, h:mm a"
            timeCaption="time"
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false
              }
            }}
          />
        </div>
      );
    }

    if (inputType === INPUT_TYPE.DOUBLE) {
      if (isEqual) {
        return (
          <div onClick={e => e.preventDefault()}>
            <ColumnValues
              disabled={disabled}
              options={options}
              onChange={setStartNumber}
              initValue={filterValue?.toString()}
            />
          </div>
        );
      }

      if (isBetween) {
        return (
          <>
            <small className="label-description">Start</small>
            <Input
              data-test="filter-input-1"
              disabled={disabled}
              type="number"
              value={startNumber}
              onChange={setStartNumber}
              onKeyPress={overrideEnter}
            />
            <small className="label-description">End</small>
            <Input
              data-test="filter-input-2"
              disabled={disabled}
              type="number"
              value={endNumber}
              onChange={setEndNumber}
              onKeyPress={overrideEnter}
            />
          </>
        );
      }

      return (
        <Input
          data-test="filter-input"
          disabled={disabled}
          type="number"
          value={startNumber}
          onChange={setStartNumber}
          onKeyPress={overrideEnter}
        />
      );
    }

    if (inputType === INPUT_TYPE.STRING) {
      if (isEqual) {
        return (
          <div onClick={e => e.preventDefault()}>
            <ColumnValues
              disabled={disabled}
              options={options}
              onChange={onChange}
              initValue={filterValue}
            />
          </div>
        );
      }

      return (
        <div style={{ marginTop: '10px', marginLeft: '10px' }}>
          <Input
            data-test="filter-input"
            disabled={disabled}
            height="36px"
            type="text"
            value={filterValue || ''}
            onChange={onChange}
            onKeyPress={overrideEnter}
          />
        </div>
      );
    }

    if (inputType === INPUT_TYPE.RELATIVE) {
      const relativeInputProperty = { min: 0, max: 1000 };

      return (
        <div className="relative-datetime-wrapper-input">
          <StyledTextField
            disabled={disabled}
            id="relative-days"
            onKeyPress={overrideEnter}
            value={relative}
            onChange={handleTimeChange(setRelative, relativeInputProperty.max)}
            type="number"
            inputProps={relativeInputProperty}
            InputLabelProps={{
              shrink: true
            }}
          />
          <span className="relative-input-label"> days ago</span>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <UseFilterOptionsWrapper
        columnName={columnName}
        shouldFetchOptions={needOptions}
        setOptions={setOptions}
      />
      {renderContent()}
    </>
  );
};

FilterInput.defaultProps = {
  disabled: false
};

FilterInput.propTypes = {
  disabled: PropTypes.bool,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  columnName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  operator: PropTypes.object.isRequired
};

export default FilterInput;

const CalendarContainer = ({ children }) => {
  // document.body is used as temporary container until 'filter-input-portal' is ready
  const node = document.getElementById('filter-input-portal') || document.body;

  return createPortal(children, node);
};
