import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';
import { ModelType, PipelineType } from '@mpm/types';

type ModelsByIDParams = {
  models: string[];
};

type ModelsByIDResponse = {
  columns: string[];
  models: ModelType[];
  pipelines: PipelineType[];
  total: number;
};

const getModelsByID = ({
  workspaceId,
  ...restParams
}: ModelsByIDParams & { workspaceId: string }) => {
  return api.post(`mpm/${workspaceId}/models/getById`, restParams);
};

export function useModelsByID(
  params: ModelsByIDParams,
  config: QueryConfig<ModelsByIDResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['modelsByID', { workspaceId: activeWorkspace?.id, ...params }],
    () => getModelsByID({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config
    }
  );
}
