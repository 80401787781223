import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import OperatorListGroup from './OperatorListGroup';
import OperatorListItem from './OperatorListItem';

const OperatorsList = ({ rule, renderInputField, getOperatorsForRule }) => {
  const operators = useMemo(() => {
    return getOperatorsForRule(rule.source, rule.type);
  }, [getOperatorsForRule, rule.source, rule.type]);

  return operators.map(operator => {
    if (operator.operators) {
      const selectedGroupOperator = operator.operators.find(
        o => o.operator === rule?.operator
      );

      return (
        <OperatorListGroup
          key={operator.operator}
          selectedGroupOperator={selectedGroupOperator}
          operator={operator}
          renderInputField={renderInputField}
        />
      );
    }

    const isOperatorSelected = rule?.operator === operator.operator;
    return (
      <OperatorListItem
        key={operator.operator}
        showInput={isOperatorSelected}
        operator={operator}
        renderInputField={renderInputField}
      />
    );
  });
};

OperatorsList.propTypes = {
  getOperatorsForRule: PropTypes.func.isRequired,
  rule: PropTypes.object.isRequired,
  renderInputField: PropTypes.func.isRequired
};

export default OperatorsList;
