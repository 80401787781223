import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import { useMutation, useQueryClient } from 'react-query';

import { NEW_VIEW } from '@/constants/dashboardConstants';
import { ProjectTemplate } from '@experiment-management-shared/types';
import { projectViewsStore } from '@projects/utils';
import api from '../api';
import useProject from './useProject';

const SNAKE_CASE_KEYS = [
  'createdBy',
  'createdFromTemplateId',
  'currentProjectId',
  'projectDefault',
  'projectId',
  'templateId',
  'templateName',
  'unsavedView'
];

const normalizeView = (view: ProjectTemplate) => {
  return mapKeys(view, (value, key) => {
    if (SNAKE_CASE_KEYS.includes(key)) return snakeCase(key);

    return key;
  });
};

const upsertView = async ({
  canEdit,
  projectId,
  view
}: {
  canEdit: boolean;
  projectId: string;
  view: ProjectTemplate;
}) => {
  const viewId = view.templateId ?? view.createdFromTemplateId ?? NEW_VIEW;

  if (!canEdit) {
    return await projectViewsStore.put({
      projectId,
      view: { ...view, isLocalView: true },
      viewId
    });
  }

  await projectViewsStore.delete([projectId, viewId]);

  return api.post('dashboard-templates/project/upsert', {
    current_project_id: projectId,
    ...normalizeView(view)
  });
};

export default function useUpsertViewMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ view }: { view: ProjectTemplate }) =>
      upsertView({ canEdit: project.canEdit, projectId, view }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['projectViews', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['projectViews', { projectId }], {
          refetchInactive: true
        });
        await queryClient.invalidateQueries(['llmProjectView', { projectId }], {
          refetchInactive: true
        });
      }
    }
  );
}
