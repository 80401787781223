import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './admin-settings.scss';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from 'react-router-dom';
import { TextButton } from '@ds';
import { LinkedMenu } from '@DesignSystem/navigation';
import Box from '@material-ui/core/Box';
import {
  CustomizationIcon,
  GeneralIcon,
  GridIcon,
  BillingIcon,
  UsersIcon,
  FilterIcon
} from '@Icons-outdated';

import { Avatar } from '@DesignSystem/data-display';

import CustomizeOrganizationSettings from '@account-settings/components/CustomizeOrganizationSettings';

import { ServiceAccounts } from './ServiceAccounts';
import { General } from './General';
import { Workspaces } from './Workspaces';
import { Users } from './Users';
import { Billing } from './Billing';

import { IS_ON_PREMISE } from '@/lib/appConstants';
import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';
import { BETA_FEATURE_SERVICE_ACCOUNTS } from '@/lib/betaFeatures';
import organizationsActions from '@/actions/organizationsActions';
import {
  useOrganizations,
  useAllUserWorkspaces,
  useCurrentOrganization,
  useDefaultWorkspace,
  useBetaFeatureEnabled,
  useIsEnvironmentsEnabled
} from '@shared/hooks';
import workspaceActions from '@/actions/workspaceActions';
import { DSArrowDirectionLeftIcon, DSInstalledPackagesIcon } from '@ds-icons';
import { selectDefaultWorkspaceByOrganization } from '@/reducers/ui/workspaceUiReducer';
import { PythonEnvironment } from './PythonEnvironment';

type GetMenuItemsArgs = {
  organizationId: string;
  isServiceAccountEnabled: boolean;
  isOnPremise: boolean;
  isEnvironmentsEnabled: boolean;
};

const getMenuItems = ({
  organizationId,
  isServiceAccountEnabled,
  isEnvironmentsEnabled,
  isOnPremise
}: GetMenuItemsArgs) => {
  const menu = [
    {
      label: 'General',
      path: `/organizations/${organizationId}/general`,
      routePath: '/organizations/:organizationId/general',
      PrefixIcon: GeneralIcon,
      component: General
    },
    {
      label: 'Workspaces',
      path: `/organizations/${organizationId}/workspaces`,
      routePath: '/organizations/:organizationId/workspaces',
      PrefixIcon: GridIcon,
      component: Workspaces
    },
    {
      label: 'Users',
      path: `/organizations/${organizationId}/users`,
      routePath: '/organizations/:organizationId/users',
      PrefixIcon: UsersIcon,
      component: Users
    }
  ];

  // the order is important
  if (!isOnPremise) {
    menu.push({
      label: 'Organization settings',
      path: `/organizations/${organizationId}/settings`,
      routePath: '/organizations/:organizationId/settings',
      PrefixIcon: () => <Box component={FilterIcon} m={0.3} />,
      component: () => <CustomizeOrganizationSettings />
    });
  }

  if (isServiceAccountEnabled) {
    menu.push({
      label: 'Service Accounts',
      path: `/organizations/${organizationId}/service-accounts`,
      routePath: '/organizations/:organizationId/service-accounts',
      PrefixIcon: CustomizationIcon,
      component: ServiceAccounts
    });
  }

  if (isEnvironmentsEnabled) {
    menu.push({
      label: 'Python Environment',
      path: `/organizations/${organizationId}/python-environment`,
      routePath: '/organizations/:organizationId/python-environment',
      PrefixIcon: DSInstalledPackagesIcon,
      component: () => <PythonEnvironment />
    });
  }

  if (!isOnPremise) {
    menu.push({
      label: 'Billing',
      path: `/organizations/${organizationId}/billing`,
      routePath: '/organizations/:organizationId/billing',
      PrefixIcon: BillingIcon,
      component: () => <Billing />
    });
  }

  return menu;
};

const AdminSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isServiceAccountEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_SERVICE_ACCOUNTS
  );
  const isEnvironmentsEnabled = useIsEnvironmentsEnabled();

  const organization = useCurrentOrganization();
  const { data: organizations } = useOrganizations();
  const { data: allWorkspaces } = useAllUserWorkspaces();
  const defaultWorkspace = useDefaultWorkspace();

  const { organizationId: organizationIdFromParams } = useParams<{
    organizationId: string;
  }>();

  const adminMenuItems = getMenuItems({
    organizationId: organizationIdFromParams,
    isServiceAccountEnabled,
    isEnvironmentsEnabled,
    isOnPremise: Boolean(IS_ON_PREMISE)
  });

  const goToActiveWorkspaceProjects = useCallback(() => {
    history.replace(
      `/${defaultWorkspace?.name}${WORKSPACE_URL_HASHES.PROJECTS}`
    );
  }, [defaultWorkspace?.name, history]);

  // when a user is not an admin and tries to open the page
  useEffect(() => {
    if (
      organization &&
      !organization.isAdmin &&
      // if they're not equal it means the active organization hasnt been updated yet
      organization?.id === organizationIdFromParams &&
      defaultWorkspace?.name
    ) {
      goToActiveWorkspaceProjects();
    }
  }, [
    organization,
    history,
    goToActiveWorkspaceProjects,
    organizationIdFromParams,
    defaultWorkspace?.name
  ]);

  // set the organization from params
  useEffect(() => {
    if (organization?.id !== organizationIdFromParams) {
      dispatch(
        organizationsActions.setActiveOrganization(organizationIdFromParams)
      );
      const workspaceToMakeActive = selectDefaultWorkspaceByOrganization(
        allWorkspaces,
        organizationIdFromParams
      )?.name;

      if (workspaceToMakeActive) {
        dispatch(workspaceActions.switchTeam(workspaceToMakeActive, true));
      }
    }
  }, [organizationIdFromParams, organization?.id, dispatch, allWorkspaces]);

  // when it's not a user's organization
  useEffect(() => {
    if (
      organizations?.length &&
      !organizations.find(org => org.id === organization?.id) &&
      defaultWorkspace?.name
    ) {
      goToActiveWorkspaceProjects();
    }
  }, [
    organizations,
    organization?.id,
    dispatch,
    history,
    goToActiveWorkspaceProjects,
    defaultWorkspace?.name
  ]);

  return (
    (organization?.isAdmin && (
      <div className="admin-settings-container">
        <div className="return-to-projects-container">
          <TextButton
            type="secondary"
            PrefixIcon={<DSArrowDirectionLeftIcon />}
            onClick={goToActiveWorkspaceProjects}
          >
            Return to Projects
          </TextButton>
        </div>
        <div className="flex">
          <div className="menu-container">
            <div className="flex">
              <div className="organization-logo">
                <Avatar
                  src={organization?.logoUrl}
                  square
                  width="28px"
                  lettersLineHeight="20px"
                  showLetters
                  backgroundColor="#12A4B4"
                  letters={organization?.name && organization?.name[0]}
                />
              </div>
              <div className="organization-title-role">Admin</div>
            </div>
            <div className="organization-menu-container">
              <LinkedMenu items={adminMenuItems} />
            </div>
          </div>
          <div className="admin-settings-content-wrapper">
            <Switch>
              {adminMenuItems.map(item => (
                <Route
                  key={item.routePath}
                  path={item.routePath}
                  exact
                  component={item.component}
                />
              ))}
              <Route path="*">
                <Redirect to={adminMenuItems[0].path} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    )) ||
    null
  );
};

export default AdminSettings;
