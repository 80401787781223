import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import MaterialSnackbar from '@material-ui/core/Snackbar';

import './Snackbar.scss';
import { CrossIcon } from '@Icons-outdated';
import { IconButton } from '@material-ui/core';

const Snackbar = ({
  open,
  autoHideDuration,
  onClose,
  message,
  withUndo,
  onUndoClick
}) => {
  const action = withUndo ? (
    <>
      <button className="undo-button" type="button" onClick={onUndoClick}>
        Undo
      </button>
      <IconButton onClick={onClose} color="inherit">
        <CrossIcon />
      </IconButton>
    </>
  ) : null;

  return (
    <MaterialSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      action={action}
    />
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  message: PropTypes.string,
  withUndo: PropTypes.bool,
  onUndoClick: PropTypes.func
};

Snackbar.defaultProps = {
  open: false,
  autoHideDuration: 4000,
  onClose: noop,
  message: null,
  withUndo: false,
  onUndoClick: noop
};

export default Snackbar;
