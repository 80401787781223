// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageUsersModal-module__manageUsersModal--BlAMv{padding-left:30px;padding-right:30px}.ManageUsersModal-module__manageUsersModalPopup--k6EjJ .full-width-modal-content{width:100%}.ManageUsersModal-module__manageUsersModalController--m99qO{display:flex;justify-content:space-between;align-items:center;margin-bottom:18px}.ManageUsersModal-module__manageUsersModalController--m99qO svg{color:var(--primary-color);fill:var(--primary-color) !important}.ManageUsersModal-module__manageUsersModalController--m99qO svg path{color:var(--primary-color);fill:var(--primary-color) !important}.ManageUsersModal-module__manageUsersModalController--m99qO .ds-input-wrapper{width:400px}.ManageUsersModal-module__manageUsersModalController--m99qO .ds-input-wrapper input{height:36px}.ManageUsersModal-module__manageUsersModalCount--suAgU{font-size:14px;line-height:21px;color:var(--gray-5)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageUsersModal": `ManageUsersModal-module__manageUsersModal--BlAMv`,
	"manageUsersModalPopup": `ManageUsersModal-module__manageUsersModalPopup--k6EjJ`,
	"manageUsersModalController": `ManageUsersModal-module__manageUsersModalController--m99qO`,
	"manageUsersModalCount": `ManageUsersModal-module__manageUsersModalCount--suAgU`
};
module.exports = ___CSS_LOADER_EXPORT___;
