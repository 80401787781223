import { Context, createContext, useContext } from 'react';
import noop from 'lodash/noop';
import { ListActionSize } from '@design-system/types';

interface ListItemContextType {
  disabled: boolean;
  active: boolean;
  isWithCheckbox: boolean;
  setIsWithCheckbox: (value: boolean) => void;
  isWithRadioButton: boolean;
  setIsWithRadioButton: (value: boolean) => void;
  actionSize: ListActionSize;
  setActionSize: (value: ListActionSize) => void;
  isWithSecondaryText: boolean;
  setIsWithSecondaryText: (value: boolean) => void;
  setActionsVisibleOnHoverOnly: (value: boolean) => void;
  actionsVisibleOnHoverOnly: boolean;
  itemHovered: boolean;
}

export const ListItemContext: Context<ListItemContextType> = createContext<ListItemContextType>(
  {
    disabled: false,
    active: false,
    isWithCheckbox: false,
    setIsWithCheckbox: noop,
    isWithRadioButton: false,
    setIsWithRadioButton: noop,
    actionSize: 'small',
    setActionSize: noop,
    isWithSecondaryText: false,
    setIsWithSecondaryText: noop,
    actionsVisibleOnHoverOnly: false,
    setActionsVisibleOnHoverOnly: noop,
    itemHovered: false
  }
);

export const useListItemContext = (): ListItemContextType =>
  useContext<ListItemContextType>(ListItemContext);
