import { Button } from '@ds';
import React from 'react';
import styles from './AddSectionButton.module.scss';

const AddSectionButton = props => {
  return (
    <div className={styles.button}>
      <div className={styles.separator} />

      <Button {...props}>Add section here</Button>

      <div className={styles.separator} />
    </div>
  );
};

export default AddSectionButton;
