import cx from 'classnames';
import omit from 'lodash/omit';
import { truncateValue } from '@shared/utils/decimalUtils';

import styles from './HyperParametersTab.module.scss';

export const PARAM_FE_SYSTEM_SEPARATOR = '|';
export const PARAM_SDK_SEPARATOR = '|';

export const MAX_NESTED_PARAM_DEPTH = 10;

const PARAM_NAME_KEY = 'name';
export const LEFT_COLUMNS = [PARAM_NAME_KEY];
export const PARAM_ACTION_COLUMN = 'actions';

export const PARAM_TREE_COLUMNS = {
  NAME: PARAM_NAME_KEY,
  ID: 'id',
  PARENT_ID: 'parentId',
  LEAF: 'leaf',
  FILTERING_VALUES: 'filteringValues',
  EQUAL: 'equal',
  EDITABLE: 'editable',
  ORIGINAL_NAME: 'originalName'
};

export const getParamValuesAmongExperiments = param => {
  const experimentParamMap = omit(param, Object.values(PARAM_TREE_COLUMNS));

  return experimentParamMap;
};

export const getParamValuesWithDecimalPrecisions = (
  param,
  decimalsPrecision
) => {
  const experimentValueMap = getParamValuesAmongExperiments(param);

  Object.entries(experimentValueMap).forEach(([experimentKey, value]) => {
    experimentValueMap[experimentKey] = truncateValue(value, decimalsPrecision);
  });

  return {
    ...param,
    ...experimentValueMap
  };
};

// finds whether the tree node has some children
const isWithChildren = (arrayTree, node) => {
  const found = arrayTree.find(
    currentNode =>
      currentNode[PARAM_TREE_COLUMNS.PARENT_ID] === node[PARAM_TREE_COLUMNS.ID]
  );

  return !!found;
};

const hasSomeInternalNodesWithoutChildren = (node, idx, arrayTree) => {
  if (node.leaf) {
    return true;
  }

  return isWithChildren(arrayTree, node);
};

const isWithoutInternalNodesWithoutChildren = (node, idx, arrayTree) => {
  return !hasSomeInternalNodesWithoutChildren(node, idx, arrayTree);
};

export const cleanInternalNodesWithoutChildren = arrayTree => {
  const removeChildlessInternalNodes = tree => {
    const res = tree.filter(hasSomeInternalNodesWithoutChildren);

    if (res.filter(isWithoutInternalNodesWithoutChildren).length) {
      return removeChildlessInternalNodes(res);
    }

    return res;
  };

  return removeChildlessInternalNodes(arrayTree);
};

export const paramTableConfig = {
  disableConfig: {
    isDisabled: true
  },
  treeDataConfig: {
    isDisabled: false,
    treeColumn: PARAM_NAME_KEY,
    getChildRows: (row, rootRows) => {
      const childRows = rootRows.filter(
        r =>
          r[PARAM_TREE_COLUMNS.PARENT_ID] ===
          (row ? row[PARAM_TREE_COLUMNS.ID] : null)
      );
      return childRows.length ? childRows : null;
    }
  },
  getRowClassName: row => {
    const baseRowClassName = row.leaf ? '' : styles.treeGroup;

    if (row.leaf) {
      // pink
      if (!row[PARAM_TREE_COLUMNS.EQUAL]) {
        return cx(baseRowClassName, styles.unequalValueRow);
      }

      // gray
      if (row[PARAM_TREE_COLUMNS.PARENT_ID]) {
        return cx(baseRowClassName, styles.paramWithValueRow);
      }
    }

    // white
    return cx(baseRowClassName, styles.paramLevelRow);
  },
  leftColumns: LEFT_COLUMNS,
  defaultColumnWidths: [
    {
      columnName: PARAM_TREE_COLUMNS.NAME,
      width: 300
    },
    {
      columnName: PARAM_ACTION_COLUMN,
      width: 48
    }
  ],
  columnWidthExtensions: [
    {
      columnName: PARAM_TREE_COLUMNS.NAME,
      minWidth: 300
    },
    {
      columnName: PARAM_ACTION_COLUMN,
      minWidth: 48
    }
  ]
};

// returns { [experiment_key_1]: value, [experiment_key_2]: value } for a paramName from experimentKeyParamMap
const generateParamValuesAmongExperiments = (
  experimentKeyParamMap,
  paramName
) => {
  const experimentKeys = Object.keys(experimentKeyParamMap);
  let firstValue = 0;

  return experimentKeys.reduce(
    (res, experimentKey, idx) => {
      const value = experimentKeyParamMap[experimentKey].find(
        param => param.name === paramName
      )?.valueCurrent;
      res[experimentKey] = value;

      if (!idx) {
        firstValue = value;
        // if not all elements are equal
      } else if (value !== firstValue) {
        res[PARAM_TREE_COLUMNS.EQUAL] = false;
      }

      return res;
    },
    { [PARAM_TREE_COLUMNS.EQUAL]: true }
  );
};

// from ['1.2.3.4'] returns ['1', '1.2', '1.2.3']
const breakPath = (path, separator = PARAM_SDK_SEPARATOR) => {
  const pathChains = path.split(separator);
  const pathVariations = pathChains.reduce((res, item, idx) => {
    if (!item) {
      return res;
    }

    if (!idx) {
      return [item];
    }

    return [...res, `${res[idx - 1]}${PARAM_FE_SYSTEM_SEPARATOR}${item}`];
  }, []);

  return pathVariations;
};

// returns '4' for the path = '1.2.3.4'
const getLastChain = (path, separator = PARAM_FE_SYSTEM_SEPARATOR) => {
  const pathChains = path.split(separator);

  return pathChains?.length ? pathChains[pathChains.length - 1] : null;
};

// when nesting is not applied
const generateFlatTree = (params, experimentKeyParamMap) =>
  params.map(param => ({
    [PARAM_TREE_COLUMNS.NAME]: param.name,
    [PARAM_TREE_COLUMNS.ID]: param.name,
    [PARAM_TREE_COLUMNS.PARENT_ID]: null,
    [PARAM_TREE_COLUMNS.FILTERING_VALUES]: [param.name],
    [PARAM_TREE_COLUMNS.EDITABLE]: param.editable,
    [PARAM_TREE_COLUMNS.LEAF]: true,
    [PARAM_TREE_COLUMNS.ORIGINAL_NAME]: param.name,

    ...generateParamValuesAmongExperiments(experimentKeyParamMap, param.name)
  }));

const generateNestedTree = (params, experimentKeyParamMap) => {
  const initializedParamSet = new Set();
  const rows = [];

  params.forEach(param => {
    const variations = breakPath(param.name);

    variations.forEach((variation, idx) => {
      if (!initializedParamSet.has(variation)) {
        initializedParamSet.add(variation);

        let row = {
          [PARAM_TREE_COLUMNS.NAME]: getLastChain(variation),
          [PARAM_TREE_COLUMNS.ID]: variation,
          [PARAM_TREE_COLUMNS.PARENT_ID]: idx ? variations[idx - 1] : null,
          [PARAM_TREE_COLUMNS.FILTERING_VALUES]: [param.name]
        };

        // if it's the leaf of the tree
        if (variations.length - 1 === idx) {
          row = {
            ...row,
            [PARAM_TREE_COLUMNS.EDITABLE]: param.editable,
            [PARAM_TREE_COLUMNS.LEAF]: true,
            [PARAM_TREE_COLUMNS.ORIGINAL_NAME]: param.name,

            ...generateParamValuesAmongExperiments(
              experimentKeyParamMap,
              param.name
            )
          };
        }

        rows.push(row);
      } else {
        // if a variation is already in the set, it just extends the filtering options
        const rowToExtendFilteringValue = rows.find(
          row => row[PARAM_TREE_COLUMNS.ID] === variation
        );

        rowToExtendFilteringValue[PARAM_TREE_COLUMNS.FILTERING_VALUES].push(
          param.name
        );
      }
    });
  });

  return rows;
};

// generates a plain array with { 'parentId', 'id', .. } to build a tree
export const generatedParameterTree = (
  params,
  experimentKeyParamMap,
  isNestedParamApplied
) => {
  if (!isNestedParamApplied) {
    return generateFlatTree(params, experimentKeyParamMap);
  }

  return generateNestedTree(params, experimentKeyParamMap);
};
