import { createSelector } from 'reselect';
import {
  MAX_COMPARE_EXPERIMENTS_COUNT,
  MIN_COMPARE_EXPERIMENTS_COUNT,
  MIN_COMPARE_EXPERIMENTS_COUNT_TO_SELECT
} from '@experiment-management-shared/constants/experimentConstants';
import { experimentActionTypes } from '@/constants/actionTypes';

const experimentsUiInitialState = {
  isFetching: true,
  experimentNames: {},
  experimentKeysForDetailsPage: []
};

export function experimentsUiReducer(
  state = experimentsUiInitialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case experimentActionTypes.SET_EXPERIMENT_KEYS_FOR_DETAILS_PAGE:
      return {
        ...state,
        experimentKeysForDetailsPage: payload
      };

    case experimentActionTypes.SET_EXPERIMENT_NAME:
      return {
        ...state,
        experimentNames: {
          ...state.experimentNames,
          [payload.experimentKey]: payload.experimentName
        }
      };

    case experimentActionTypes.CLEAR_EXPERIMENTS_UI:
      return experimentsUiInitialState;

    // TODO: - Refactor -  unsure if these are used
    case experimentActionTypes.FETCH_EXPERIMENT_WITH_USER_UNSUCCESSFUL:
      return { isFetching: true };
    case experimentActionTypes.RECEIVED_WITHOUT_USERS_LATEST_EXPERIMENT:
    case experimentActionTypes.RECEIVED_USERS_LATEST_EXPERIMENT:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
}

export const selectExperimentKeys = state => {
  return state.ui.experiments.experimentKeysForDetailsPage;
};

export const selectIsComparePage = createSelector(
  [selectExperimentKeys],
  experimentKeys => {
    return (
      experimentKeys.length >= MIN_COMPARE_EXPERIMENTS_COUNT &&
      experimentKeys.length <= MAX_COMPARE_EXPERIMENTS_COUNT
    );
  }
);

export const selectAreComparePageExperimentsSelectable = createSelector(
  [selectExperimentKeys],
  experimentKeys => {
    return experimentKeys.length >= MIN_COMPARE_EXPERIMENTS_COUNT_TO_SELECT;
  }
);
