import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { SELECT_PANEL_PATH } from '@experiment-management-shared/constants/visualizationConstants';
import { IconButton, Tooltip } from '@ds';
import { DSEditIcon, DSShowIcon } from '@ds-icons';

const EditTemplateButton = ({ editable, id, location, onChangeTemplate }) => {
  const tooltipText = editable ? 'Edit' : 'View';
  const Icon = editable ? DSEditIcon : DSShowIcon;

  const openEditCustomPanel = () => {
    const url = `${location.pathname}/${SELECT_PANEL_PATH}/edit-code/${id}`;

    const opener = window.open(url);

    opener.onCustomPanelSave = onChangeTemplate;
  };

  return (
    <Tooltip content={tooltipText} placement="top">
      <IconButton
        type="secondary"
        Icon={<Icon />}
        className="charts-gallery-actions-icon-button"
        onClick={openEditCustomPanel}
      />
    </Tooltip>
  );
};

EditTemplateButton.propTypes = {
  editable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onChangeTemplate: PropTypes.func.isRequired
};

export default withRouter(EditTemplateButton);
