import { RoundedTabs } from '@DesignSystem/navigation';
import { Table as DxTable } from '@devexpress/dx-react-grid-material-ui';
import { FeaturesIcon } from '@Icons-outdated';
import { Box } from '@material-ui/core';
import { first, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { EMPTY_TABLE_COLSPAN } from '@shared/constants/empty-table';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';

import { Divider } from '@DesignSystem/data-display';
import { Table } from '@DesignSystem/tables';
import { rulesTreeToMPMPredicates } from '@mpm/utils';
import { useFeatures, useFeaturesByID } from '@mpm/api';
import SmallLoader from '@shared/components/SmallLoader';
import BadgeCell from '@shared/components/TableCells/BadgeCell';
import { AreaChartCell, OpenNotificationsCell } from '../Cells';
import {
  MODEL_PAGE_SIZES,
  DEFAULT_FEATURES_SORTING,
  FEATURE_LEFT_COLUMNS,
  FEATURE_MODEL_NAME_COLUMN_WIDTH,
  FEATURE_PAGE_SIZES,
  FEATURE_SERVER_COLUMNS_NAMES,
  MODEL_FEATURES_TYPES,
  uniqueRowId
} from '@mpm/constants';
import { NoTrackedModelsView } from '../NoTrackedModelsView';
import { FiltersButton } from '../FiltersButton';
import { SortButton } from '../SortButton';

const badgeColorsConfig = {
  input: { color: '#191a1c', backgroundColor: '#e5e5fe' },
  output: { color: '#191a1c', backgroundColor: '#fee8d7' }
};

const LinkCell = ({ value, row }) => {
  const history = useHistory();

  return (
    <Box display="flex">
      <Link
        style={{ color: 'black' }}
        to={`${history.location.pathname}/${row.id}`}
      >
        <span style={{ textDecoration: 'underline' }}>{value}</span>
      </Link>
    </Box>
  );
};

const dataTypes = [
  {
    cols: ['featureType'],
    cell: ({ value }) => (
      <BadgeCell colorsConfig={badgeColorsConfig} value={value} />
    )
  },
  {
    cols: ['alerts'],
    cell: OpenNotificationsCell
  },
  {
    cols: ['missingValues', 'drift'],
    cell: ({ value, column }) => <AreaChartCell value={value} column={column} />
  },
  {
    cols: ['name'],
    cell: LinkCell
  }
];

const noPaddingCols = ['name'];
const disabledColumns = ['name'];

const mapDataToRows = features => {
  return features.map(feature => {
    return {
      id: feature.id,
      alerts: feature.numberOfAlerts,
      name: feature.name,
      featureType:
        feature.source === MODEL_FEATURES_TYPES.OUTPUT_FEATURES
          ? 'Output'
          : 'Input',
      metricType: feature.type,
      drift: feature.drift,
      missingValues: feature.missingValues
    };
  });
};

const STORAGE_PAGES_KEY = 'features_pageSize';

export const ModelFeatures = ({ model }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [columnSorting, setColumnSorting] = useState(DEFAULT_FEATURES_SORTING);
  const [selection, setSelection] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(() => {
    const pages = Number(localStorage.getItem(STORAGE_PAGES_KEY));
    if (MODEL_PAGE_SIZES.includes(pages)) return pages;
    return MODEL_PAGE_SIZES[0];
  });
  const [columnOrder, setColumnOrder] = useState([]);
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState({
    rulesTree: generateEmptyRulesTree(),
    segmentId: ''
  });
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'name', width: FEATURE_MODEL_NAME_COLUMN_WIDTH }
  ]);

  const { modelId } = useParams();

  const FEATURE_COLUMNS = [
    {
      name: 'name',
      title: 'Name',
      id: 'name--metadata',
      type: 'string',
      source: 'metadata',
      serverField: 'feature_key'
    },
    {
      name: 'alerts',
      title: 'Alerts',
      id: 'alerts--metadata',
      type: 'string',
      source: 'metadata',
      hideForFilters: true,
      hideForSorting: true
    },
    {
      name: 'featureType',
      title: 'Feature Type',
      id: 'featureType--metadata',
      type: 'string',
      source: 'metadata',
      serverField: 'feature_type',
      hideForFilters: true
    },
    {
      name: 'metricType',
      title: 'Metric Type',
      id: 'metricType--metadata',
      type: 'string',
      source: 'metadata',
      serverField: 'feature_val_type',
      hideForFilters: true
    },
    {
      name: 'drift',
      title: 'Drift',
      id: 'drift--metadata',
      type: 'double',
      source: 'metadata',
      serverField: 'drift',
      hideForFilters: true,
      hideForSorting: true
    },
    {
      name: 'missingValues',
      title: 'Missing Values',
      id: 'missingValues--metadata',
      type: 'double',
      source: 'metadata',
      serverField: 'missing_values',
      hideForFilters: true,
      hideForSorting: true
    }
  ];

  const pagesSizeChangeHandler = value => {
    setPageSize(value);
    localStorage.setItem(STORAGE_PAGES_KEY, value);
  };

  const disabledFatureSortingColumns = FEATURE_COLUMNS.filter(
    column => column.hideForSorting
  ).map(column => ({
    columnName: column.name,
    sortingEnabled: false
  }));

  const sort = first(columnSorting);
  const sortColumn = FEATURE_SERVER_COLUMNS_NAMES[sort?.columnName];
  const order = sort?.direction;

  const { isLoading, isError, data, isFetching, isPreviousData } = useFeatures(
    {
      modelId,
      page: pageNumber + 1,
      pageSize,
      sortColumn,
      order,
      predicates: query?.rulesTree
        ? rulesTreeToMPMPredicates(query.rulesTree)
        : []
    },
    { keepPreviousData: true, refetchOnMount: true }
  );

  useEffect(() => {
    if (isEmpty(selection)) {
      setActiveTabIndex(0);
    }
  }, [selection]);

  const {
    data: selectionRowsData,
    isLoading: isLoadingSelection
  } = useFeaturesByID(
    {
      features: selection,
      modelId
    },
    { enabled: activeTabIndex === 1 }
  );

  useEffect(() => {
    setPageNumber(0);
  }, [pageSize, query]);

  useEffect(() => {
    if (data) {
      setRows(mapDataToRows(data.features));
    }
  }, [data]);

  const handleSortClick = newSort => {
    setColumnSorting(newSort);
  };

  const renderEmptyState = props => {
    return (
      <DxTable.Cell
        {...props}
        style={{ borderBottom: 'unset' }}
        colSpan={EMPTY_TABLE_COLSPAN}
      >
        <NoTrackedModelsView
          icon={<FeaturesIcon />}
          title="No features are being tracked"
        />
      </DxTable.Cell>
    );
  };

  const rowsFilteredForActiveTab = useMemo(() => {
    if (activeTabIndex === 1 && selectionRowsData) {
      return mapDataToRows(selectionRowsData.features);
    }

    return rows;
  }, [activeTabIndex, rows, selectionRowsData]);

  if (isLoading)
    return (
      <SmallLoader
        primaryMessage={
          <p>
            Fetching features for <b>{model.name}</b>
          </p>
        }
      />
    );

  if (isError)
    return <h1> Error while fetching the model`&apos;`s features. </h1>;

  return (
    <div>
      <div className="model-features-header">
        <div className="model-features-header-top">
          <div className="model-features-header-title">Features</div>

          <FiltersButton
            onChange={setQuery}
            columns={FEATURE_COLUMNS}
            query={query}
          />

          <SortButton
            columnSorting={columnSorting}
            columns={FEATURE_COLUMNS}
            onChange={handleSortClick}
            disabled={false}
          />
        </div>
        <Divider margin={0} />
        <div className="model-features-header-tabs">
          <RoundedTabs
            tabs={[
              { label: 'All' },
              {
                label: selection.length
                  ? `Selected (${selection.length})`
                  : 'Selected',
                disabled: selection.length === 0
              }
            ]}
            activeTabIndex={activeTabIndex}
            onTabChange={setActiveTabIndex}
          />
        </div>
      </div>
      <Table
        shouldHighlightLinks
        isFetching={(isFetching && isPreviousData) || isLoadingSelection}
        dataTypes={dataTypes}
        columns={FEATURE_COLUMNS}
        noPaddingColumns={noPaddingCols}
        maxHeight={276}
        rows={rowsFilteredForActiveTab}
        leftColumns={FEATURE_LEFT_COLUMNS}
        rowHeight="60px"
        rowIdKey={uniqueRowId}
        renderEmptyState={renderEmptyState}
        totalRowCount={
          activeTabIndex === 0 ? data?.total : rowsFilteredForActiveTab.length
        }
        sortingConfig={{
          isDisabled: false,
          columnSorting,
          disabledColumns: disabledFatureSortingColumns,
          onSortingChange: setColumnSorting
        }}
        paginationConfig={{
          isDisabled: false,
          isServerSidePagination: activeTabIndex !== 1,
          pageNumber,
          pageSize,
          pageSizes: MODEL_PAGE_SIZES,
          FEATURE_PAGE_SIZES,
          onPageNumberChange: setPageNumber,
          onPageSizeChange: pagesSizeChangeHandler
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          minColumnWidth: 220,
          disabledColumns,
          onColumnWidthsChange: setColumnWidths
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        selectionConfig={{
          selection,
          isDisabled: false,
          onSelectionChange: setSelection
        }}
      />
    </div>
  );
};

ModelFeatures.propTypes = {};

ModelFeatures.defaultProps = {};
