import React from 'react';
import useColumns from '@API/project/useColumns';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { DEFAULT_COLUMN_NAMES } from '@experiment-management-shared/constants/experimentGridConstants';
import CustomizeColumnsModal from '@experiment-management-shared/components/ColumnsModal/CustomizeColumnsModal';

const CustomizeColumnsModalContainer = ({
  onUpdateColumns,
  selectedColumnNames,
  defaultColumns,
  fixedColumnsNames,
  columnsNameMap,
  onClose
}) => {
  const {
    data: [data, extraCols],
    isLoading
  } = useColumns({
    divideExtraCols: true,
    extraCols: true
  });

  return (
    <CustomizeColumnsModal
      onUpdateColumns={onUpdateColumns}
      selectedColumnNames={selectedColumnNames}
      isLoading={isLoading}
      data={data || []}
      extraCols={extraCols}
      onClose={onClose}
      defaultColumns={defaultColumns}
      fixedColumnsNames={fixedColumnsNames}
      columnsNameMap={columnsNameMap}
    />
  );
};

CustomizeColumnsModalContainer.defaultProps = {
  onClose: noop,
  onUpdateColumns: noop,
  selectedColumnNames: [],
  defaultColumns: DEFAULT_COLUMN_NAMES,
  fixedColumnsNames: ['Name'],
  columnsNameMap: {}
};

CustomizeColumnsModalContainer.propTypes = {
  onClose: PropTypes.func,
  onUpdateColumns: PropTypes.func,
  selectedColumnNames: PropTypes.arrayOf(PropTypes.string),
  fixedColumnsNames: PropTypes.arrayOf(PropTypes.string),
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  columnsNameMap: PropTypes.object
};

export default CustomizeColumnsModalContainer;
