import colorString from 'color-string';
import Color from 'color';

export const changeColorOpacity = (color: string, opacity: number): string => {
  if (!color) return color;

  const RGBAColor = colorString.get.rgb(color);
  // change the opacity of color
  if (!RGBAColor) return color;

  RGBAColor[3] = opacity;
  return colorString.to.rgb(RGBAColor);
};

export const darkenColor = (color: string, range: number): string => {
  try {
    return Color(color).darken(range).rgb().toString();
  } catch {
    return '';
  }
};
