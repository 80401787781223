import React, { ReactNode } from 'react';
import classes from './ListComponents.module.scss';
import cx from 'classnames';
import { useListItemContext } from '../useListItemContext';

export interface ListIconProps {
  Icon: ReactNode;
}

const ListIcon = ({ Icon }: ListIconProps) => {
  const { isWithSecondaryText } = useListItemContext();
  return (
    <div
      className={cx(
        classes.prefixIconContainer,
        classes.prefixElementContainer,
        {
          [classes.moreTopIconMargin]: isWithSecondaryText
        }
      )}
    >
      {Icon}
    </div>
  );
};

export default ListIcon;
