import { useUpdateOnboardingFlagMutation } from '@onboarding/api';
import { useActionForNotMatchingRoute } from '@shared/hooks';
import { useCallback } from 'react';
import useIsGetStartedAvailable from './useIsGetStartedAvailable';

// the flag is not updated if a user continues onboarding by going to
// the quick start page only
const useUpdateGetStartedShownStatus = ({ isQuickStart }) => {
  const updateOnboardingFlagMutation = useUpdateOnboardingFlagMutation();
  const isAvailable = useIsGetStartedAvailable();

  const changeFlagStatus = useCallback(() => {
    if (isAvailable) {
      updateOnboardingFlagMutation.mutate({
        flags: { shownGetStarted: true }
      });
    }
  }, [updateOnboardingFlagMutation, isAvailable]);

  useActionForNotMatchingRoute({
    pathname: isQuickStart ? '/get-started' : '/:workspace/quick-start',
    callback: changeFlagStatus
  });
};

export default useUpdateGetStartedShownStatus;
