import { useMutation, useQueryClient } from 'react-query';
import hyperParamsApi from '@experiment-management-shared/api/API/hyperParamsApi';
import { AxiosError, AxiosResponse } from 'axios';

type MutationArgs = {
  experimentKey: string;
  name: string;
  value: string;
};
const useUpdateHyperParameterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<unknown>,
    AxiosError<{ msg?: string }>,
    MutationArgs
  >(
    ({ experimentKey, name, value }) =>
      hyperParamsApi.updateHyperParam({ experimentKey, name, value }),
    {
      onMutate: async () => {
        queryClient.cancelQueries(['experiment-params']);
      },
      onSuccess: async () => {
        queryClient.invalidateQueries(['experiment-params']);
      }
    }
  );
};

export default useUpdateHyperParameterMutation;
