import get from 'lodash/get';
import { createSelector } from 'reselect';
import { projectsActionTypes } from '@/constants/actionTypes';
import { getSelectedProjectId } from './ui/projectsUiReducer';

const initialState = {
  map: {},
  keys: []
};

export const projectsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === projectsActionTypes.SET_PROJECT) {
    const { projectId, projectData } = payload;
    const projectMap = {
      ...state.map,
      [projectId]: projectData
    };

    return {
      map: projectMap,
      keys: Object.keys(projectMap)
    };
  }

  return state;
};

export const getProjectsMap = state => state.projects.map;

export const getCurrentProject = createSelector(
  [getSelectedProjectId, getProjectsMap],
  (projectId, projectsMap) => {
    return get(projectsMap, projectId, {});
  }
);

export const getCurrentProjectName = createSelector(
  [getCurrentProject],
  project => project.projectName || ''
);

export const isUserAllowedToEditProject = createSelector(
  [getCurrentProject],
  currentProject => currentProject?.canEdit
);

export const getTotalExperimentsInProject = createSelector(
  [getCurrentProject],
  currentProject => get(currentProject, 'numOfExperiments', 0)
);
