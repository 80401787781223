// Copied from ./displayHelpers.js to avoid dependency violations error
type FontConfig = {
  fontSize: number;
  fontFamily?: string;
  fontWeight?: 'bold' | 'normal';
};
export const getTextWidth = (text: string, fontConfig?: FontConfig) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  context.font = getComputedStyle(document.body).font;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  context.letterSpacing = '0.02em';
  if (fontConfig) {
    context.font = `${fontConfig.fontWeight || 'normal'} ${
      fontConfig.fontSize || getComputedStyle(document.body).fontSize
    }px ${fontConfig.fontFamily || getComputedStyle(document.body).fontFamily}`;
  }

  return context.measureText(text).width;
};

const truncateMiddleOfStringToFitWidth = (data: {
  text: string;
  maxWidth?: number;
  symbol?: string;
  fontConfig?: FontConfig;
}) => {
  const { text, maxWidth, symbol = '...', fontConfig } = data;

  if (!text || !maxWidth) return text;

  const textWidth = getTextWidth(text, fontConfig);

  const widthOfTextToTruncate = textWidth - maxWidth;
  const avgWidthOfCharacter = textWidth / text.length;

  if (textWidth > maxWidth) {
    const numCharactersToTruncate =
      Math.ceil(widthOfTextToTruncate / avgWidthOfCharacter) + symbol.length;
    const halfLength = text.length / 2;

    const start = text.substring(0, halfLength - numCharactersToTruncate / 2);
    const end = text.substring(halfLength + numCharactersToTruncate / 2);

    return `${start}${symbol}${end}`;
  }

  return text;
};

type UseMiddleTruncationOpts = {
  enabled?: boolean;
  text: string;
  maxWidth?: number;
  fontConfig?: FontConfig;
};
const useMiddleTruncation = ({
  enabled = true,
  text,
  maxWidth,
  fontConfig
}: UseMiddleTruncationOpts) => {
  const isEnabled = enabled && maxWidth;

  if (!isEnabled) {
    return {
      isTextTruncated: false,
      truncatedValue: text
    };
  }

  const truncatedValue = truncateMiddleOfStringToFitWidth({
    text,
    maxWidth,
    fontConfig
  });
  const isTextTruncated = truncatedValue !== text;

  return {
    isTextTruncated,
    truncatedValue
  };
};

export default useMiddleTruncation;
