import React from 'react';
import { Box, Grid } from '@material-ui/core';
import classNames from './ModelRegistryTabEmptyState.modules.scss';
import { ROOT_URL } from '@/constants/configConstants';

const listItems = [
  'Log a model using a Python SDK Experiment',
  'Register an Experiment model from the UI or SDK',
  'Track model versions of the registered model',
  'Deploy a registered model'
];
const { origin } = new URL(ROOT_URL, window.location.origin);

const ModelRegistryTabEmptyState = () => {
  return (
    <Box className={classNames.modelRegistryTabEmptyContainer}>
      <Grid container spacing={4} alignItems="stretch" alignContent="center">
        <Grid item xs={6} className={classNames.modelRegistryTabEmptyIcon}>
          <Grid
            container
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <Grid item xs="auto">
              <img
                src="/images/model-registry/register-model-example.png"
                alt="register-model-example"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box className={classNames.modelRegistryTabEmptyRightPane}>
            <Box className={classNames.modelRegistryTabEmptyTitle}>
              Lets get started with registering your models
            </Box>
            <Box className={classNames.modelRegistryTabEmptyText}>
              Registering your models allows you to centralize all your model
              information in a single place. Through the model registry you can
              view your model card, lineage, usage instructions, set tags, and
              model status. Easily connect all your actions with web-hooks to
              your CI/CD pipeline.
            </Box>
            <Box className={classNames.modelRegistryTabEmptySmallTitle}>
              The general steps in creating a Comet model pipeline are:
            </Box>
            <Box className={classNames.modelRegistryTabEmptyList}>
              {listItems.map((item, index) => (
                <Grid
                  justifyContent="flex-start"
                  alignContent="center"
                  alignItems="center"
                  container
                  key={item}
                >
                  <Grid
                    item
                    xs="auto"
                    className={classNames.modelRegistryTabEmptyListNumber}
                  >
                    {index + 1}
                  </Grid>
                  <Grid item xs="auto">
                    {item}
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box className={classNames.modelRegistryTabEmptyTextLink}>
              <a
                href={`${origin}/docs/v2/guides/model-management/using-model-registry/`}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about how to log and register models
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ModelRegistryTabEmptyState.defaultProps = {};

ModelRegistryTabEmptyState.propTypes = {};

export default ModelRegistryTabEmptyState;
