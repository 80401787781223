import React from 'react';
import cx from 'classnames';

import styles from './UnhandledSegmentsChartBox.module.scss';

const UnhandledSegmentsAccuracyTab = () => {
  return (
    <div
      className={cx(
        styles.unhandledSegmentsWrapper,
        styles.unhandledSegmentsAccuracyWrapper
      )}
    >
      <div className={styles.accuracyTitle}>
        Start tracking your model accuracy today{' '}
      </div>
      <div className={styles.accuracyInfoMessage}>
        Ground truth labels are used to compute accuracy related metrics. You
        can send the labels at any time after a prediction has been logged to
        MPM, we will take care of updating the relevant metrics.
      </div>
      <div className={styles.infoMessage}>
        <span className={styles.link}>
          <a
            href="/docs/v2/guides/model-production-monitoring/mpm-integrations/#sending-labels-to-mpm"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about how to send labels to MPM
          </a>
        </span>
      </div>
    </div>
  );
};

export default UnhandledSegmentsAccuracyTab;
