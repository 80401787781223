import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import useCurrentUser from '@API/auth/useCurrentUser';

import useProject from '@API/project/useProject';
import { useActiveWorkspace, useCurrentOrganization } from '@shared/hooks';
import useCurrentPanelSource from '@/helpers/custom-hooks/useCurrentPanelSource';
import { PANEL_WITH_LEGEND_MODE } from '@experiment-management-shared/constants';

import biEventActions from '../../actions/biEventsActions';

export const usePanelRenderedEvent = ({ chartProps, isRendered }) => {
  const currentOrganization = useCurrentOrganization();
  const currentWorkspace = useActiveWorkspace();
  const { data: project } = useProject();
  const currentSource = useCurrentPanelSource();
  const { data: currentUser } = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isRendered) return;

    const config = chartProps?.config || {};
    const panelId = config.chartId;

    // the condition for custom is temporary solution to prevent send event about custom panel because we don't have any information about it now
    if (panelId && config.chartType !== 'custom') {
      dispatch(
        biEventActions.sendRenderedIfNotSentPanel(
          {
            organization_id: currentOrganization?.id,
            organization_name: currentOrganization?.name,
            workspace_id: currentWorkspace?.id,
            workspace_name: currentWorkspace?.name,
            project_id: project?.projectId,
            project_name: project?.projectName,
            panel_location: currentSource,
            panel_name: config.chartType,
            grouping: config?.grouping?.enabled ? config.grouping : null,
            legend_display: PANEL_WITH_LEGEND_MODE.includes(config.chartType)
              ? config.legendMode
              : 'NA',
            user_id: currentUser?.username,
            panel_id: panelId
          },
          panelId
        )
      );
    }
  }, [
    chartProps?.config,
    dispatch,
    currentOrganization?.id,
    currentOrganization?.name,
    currentWorkspace?.id,
    currentWorkspace?.name,
    project?.projectId,
    project?.projectName,
    currentSource,
    currentUser?.username,
    isRendered
  ]);
};
