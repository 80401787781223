import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import {
  MatrixGrid,
  ExperimentsMatrixTooltip
} from '@DesignSystem/confusion-matrix';
import { CONFUSION_MATRIX_CELL_VALUES } from '@experiment-management-shared/constants/chartConstants';
import { IConfusionMatrixData } from './IConfusionMatrixData';

import styles from './ConfusionMatrix.module.scss';

const ConfusionMatrix = ({
  cellValue,
  ranges,
  isPercentage,
  highlightPositions,
  onCellClick,
  onCellHighlight,
  data,
  computedMatrixMap
}) => {
  const { columnLabel = '', labels = [], rowLabel = '' } = data;

  const cellClickHandler = (x, y) => {
    onCellClick({ row: y, col: x, data });
  };

  return (
    <div className={styles.confusionMatrix}>
      <div>
        <div className={styles.xAxisTitle}>{columnLabel}</div>
      </div>
      <div className={styles.confusionMatrixContainer}>
        <div className={styles.yAxisTitle}>{rowLabel}</div>
        <MatrixGrid
          matrix={computedMatrixMap[cellValue]}
          ranges={ranges}
          labels={labels}
          highlightPositions={highlightPositions}
          onCellClick={cellClickHandler}
          onCellHoverChange={onCellHighlight}
          isPercentage={isPercentage}
          tooltipConfig={{
            visibleTooltip: true,
            TooltipOverlayComponent: ExperimentsMatrixTooltip,
            tooltipOverlayProps: {
              rowMatrix:
                computedMatrixMap[CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_ROW],
              columnMatrix:
                computedMatrixMap[
                  CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_COLUMN
                ]
            },
            tooltipClassname: 'confusion-matrix-chart-tooltip'
          }}
        />
      </div>
    </div>
  );
};

ConfusionMatrix.defaultProps = {
  cellValue: CONFUSION_MATRIX_CELL_VALUES.COUNTS,
  highlightPositions: [],
  onCellHighlight: noop,
  onCellClick: noop
};

ConfusionMatrix.propTypes = {
  data: IConfusionMatrixData.isRequired,
  computedMatrixMap: PropTypes.shape({
    [CONFUSION_MATRIX_CELL_VALUES.COUNTS]: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number)
    ).isRequired,
    [CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_ROW]: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number)
    ).isRequired,
    [CONFUSION_MATRIX_CELL_VALUES.PERCENT_BY_COLUMN]: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number)
    ).isRequired
  }).isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      minValue: PropTypes.number.isRequired,
      maxValue: PropTypes.number.isRequired
    })
  ).isRequired,
  isPercentage: PropTypes.bool.isRequired,
  cellValue: PropTypes.string,
  highlightPositions: PropTypes.array,
  onCellHighlight: PropTypes.func,
  onCellClick: PropTypes.func
};

export default ConfusionMatrix;
