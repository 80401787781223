import {
  REPORTS_TABLE,
  REPORT_EDIT,
  REPORT_TEMPLATE_EDIT,
  REPORT_TEMPLATE_VIEW,
  REPORT_VIEW
} from '@routes/constants/reports';
import { generatePath } from 'react-router';
import { NEW_REPORT_NAME } from '@shared/constants';

export function reportRoute({
  editMode,
  isTemplate = false,
  projectName,
  reportName = NEW_REPORT_NAME,
  workspaceName
}: {
  editMode?: string;
  isTemplate?: boolean;
  projectName: string;
  reportName: string;
  workspaceName: string;
}) {
  let route;

  if (isTemplate) {
    route = editMode ? REPORT_TEMPLATE_EDIT : REPORT_TEMPLATE_VIEW;
  } else {
    route = editMode ? REPORT_EDIT : REPORT_VIEW;
  }

  return generatePath(route, {
    editMode,
    projectName,
    reportName,
    workspace: workspaceName
  });
}

export function reportsRoute({
  projectName,
  workspaceName
}: {
  projectName: string;
  workspaceName: string;
}) {
  return generatePath(REPORTS_TABLE, {
    projectName,
    workspace: workspaceName
  });
}
