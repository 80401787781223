import {
  cloneDeepWith,
  fromPairs,
  isPlainObject,
  sortBy,
  toPairs
} from 'lodash';

interface TreeNode<T> {
  [key: string]: TreeNode<T> | T;
}

function isPlainObjectWithTyping(
  value: unknown
): value is Record<string, unknown> {
  return isPlainObject(value);
}

function sortObjectKeys<T>(obj: T): T {
  return cloneDeepWith(obj, (value: unknown) => {
    if (isPlainObjectWithTyping(value)) {
      return fromPairs(
        sortBy(toPairs(value), 0).map(pair => {
          return [pair[0], sortObjectKeys(pair[1])];
        })
      );
    }
  });
}

export const generateNestedTreeByName = (
  params: { name: string; valueCurrent: string }[]
): TreeNode<string> => {
  const json: TreeNode<string> = {};
  const sortedParams = [...params].sort((a, b) => {
    return b.name.split('|').length - a.name.split('|').length;
  });

  for (const param of sortedParams) {
    const pathList = param.name.split('|');
    let currentNode: TreeNode<string> = json;

    for (let i = 0; i < pathList.length - 1; i++) {
      const nodeName = pathList[i];
      currentNode[nodeName] = isPlainObject(currentNode[nodeName])
        ? currentNode[nodeName]
        : {};
      currentNode = currentNode[nodeName] as TreeNode<string>;
    }

    const leafName = pathList[pathList.length - 1];

    if (currentNode[leafName]) {
      let nextId = 1;
      let key = `${leafName} (${nextId})`;

      while (currentNode[key]) {
        nextId += 1;
        key = `${leafName} (${nextId})`;
      }

      currentNode[key] = param.valueCurrent;
    } else {
      currentNode[leafName] = param.valueCurrent;
    }
  }

  return sortObjectKeys(json);
};
