import { Box } from '@material-ui/core';
import { components } from 'react-select';
import React from 'react';

const MultiValueLabel = props => (
  <Box className="manage-stages-select-label">
    <components.MultiValueLabel {...props} />
  </Box>
);

export default MultiValueLabel;
