import React from 'react';
import useOtherSingleParamsDownload from './hooks/useOtherSingleParamsDownload';
import { OtherParam } from '@shared/types';
import DownloadParamsButton from '@shared/components/DownloadParamsButton/DownloadParamsButton';

type OtherSingleDownloadProps = {
  params: OtherParam[];
  experimentName?: string;
};
const OtherSingleDownload: React.FC<OtherSingleDownloadProps> = ({
  params,
  experimentName = ''
}) => {
  const { onDownload, isDownloadButtonDisabled } = useOtherSingleParamsDownload(
    {
      experimentName,
      params
    }
  );

  return (
    <DownloadParamsButton
      onDownload={onDownload}
      isDownloadButtonDisabled={isDownloadButtonDisabled}
    />
  );
};

export default OtherSingleDownload;
