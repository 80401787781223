import useUserPermission from '@shared/api/useUserPermission';
import { MANAGEMENT_PERMISSIONS } from '@shared/constants/permissions';
import {
  useActiveWorkspaceName,
  useCurrentOrganization,
  useCurrentPaymentPlan,
  useIsViewOnlyUser
} from '@shared/hooks';
import useIsGuestUser from '@shared/hooks/organization/useIsGuestUser';

const useHiddenMenuItems = () => {
  const isGuestUser = useIsGuestUser();
  const currentOrganization = useCurrentOrganization();
  const currentWorkspaceName = useActiveWorkspaceName();
  const {
    isSuspended: isMenuDisabledForExpiredTrial
  } = useCurrentPaymentPlan();
  const isViewOnlyUser = useIsViewOnlyUser();
  const { getPermissionStatus } = useUserPermission();
  const canManageUsers = getPermissionStatus({
    workspaceName: currentWorkspaceName,
    permissionKey: MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE
  });
  const canInviteUsers =
    getPermissionStatus({
      workspaceName: currentWorkspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_FROM_ORGANIZATION
    }) ||
    getPermissionStatus({
      workspaceName: currentWorkspaceName,
      permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_OUT_OF_ORGANIZATION
    });
  const isAdmin = Boolean(currentOrganization?.isAdmin);

  return {
    workspaceSelectHidden: isMenuDisabledForExpiredTrial,
    accountSettingsHidden: isMenuDisabledForExpiredTrial,
    adminDashboardHidden:
      !isAdmin || isGuestUser || isMenuDisabledForExpiredTrial,
    apiKeyHidden: isMenuDisabledForExpiredTrial || isViewOnlyUser,
    inviteMemberHidden:
      isGuestUser || !canInviteUsers || isMenuDisabledForExpiredTrial,
    manageUsersHidden:
      isGuestUser ||
      !canManageUsers ||
      isMenuDisabledForExpiredTrial ||
      isViewOnlyUser,
    quickstartGuideHidden: isMenuDisabledForExpiredTrial || isViewOnlyUser
  };
};

export default useHiddenMenuItems;
