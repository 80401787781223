import { useQuery } from 'react-query';

import api from '@API/api';
import { QueryConfig } from '@shared/api';
import { DEBUGGER_GROUP_BUTTONS } from '@mpm-druid/constants';
import { ActiveDebuggerButtonType, DebuggerResponse } from '@mpm-druid/types';

type GetEventsProps = {
  modelId: string;
  activeButton: ActiveDebuggerButtonType;
  eventsLimit?: number;
};

type DebuggerEventsParams = {
  modelId: string;
  activeButton: ActiveDebuggerButtonType;
  eventsLimit?: number;
};

const PATH = {
  [DEBUGGER_GROUP_BUTTONS.INGESTIONS]: '/errors/recent',
  [DEBUGGER_GROUP_BUTTONS.LABELS]: '/labels/recent',
  [DEBUGGER_GROUP_BUTTONS.PREDICTIONS]: '/events/recent'
};

const getEvents = ({ modelId, activeButton, eventsLimit }: GetEventsProps) =>
  api.get(
    `mpm/v2${PATH[activeButton]}?modelId=${modelId}&limit=${eventsLimit}`
  );

export function useDebuggerEvents(
  params: DebuggerEventsParams,
  config: QueryConfig<DebuggerResponse>
) {
  return useQuery(
    ['debugger-events', { ...params }],
    () => getEvents({ ...params }),
    {
      refetchOnMount: true,
      ...config
    }
  );
}
