import React from 'react';
import PropTypes from 'prop-types';
import { refPropType } from '@shared/constants/propTypes';
import ArchiveExperimentModal from './ArchiveExperimentModal';
import TagExperimentPopover, { TagPropType } from './TagExperimentPopover';
import DeleteModal from './archived/DeleteModal';
import { MoveExperimentModal } from '@experiment-management-shared/components';

const ExperimentActionModals = ({
  isArchiveModalOpen,
  onIsArchiveModalOpen,
  isMoveModalOpen,
  onIsMoveModalOpen,
  tagAnchorEl,
  setTagAnchorEl,
  symlink,
  tags,
  isDeleteModalOpen,
  onIsDeleteModalOpen
}) => {
  const handleCloseArchiveModalOpen = () => onIsArchiveModalOpen(false);
  const handleCloseMoveModal = () => onIsMoveModalOpen(false);
  const handleCloseTagPopover = () => setTagAnchorEl(null);

  const handleCloseDeleteModal = () => onIsDeleteModalOpen(false);
  return (
    <>
      <ArchiveExperimentModal
        open={isArchiveModalOpen}
        onClose={handleCloseArchiveModalOpen}
        symlink={symlink}
      />

      <MoveExperimentModal
        open={isMoveModalOpen}
        onClose={handleCloseMoveModal}
      />

      <TagExperimentPopover
        anchorEl={tagAnchorEl}
        onClose={handleCloseTagPopover}
        tags={tags}
      />

      <DeleteModal open={isDeleteModalOpen} onClose={handleCloseDeleteModal} />
    </>
  );
};

ExperimentActionModals.propTypes = {
  isArchiveModalOpen: PropTypes.bool.isRequired,
  onIsArchiveModalOpen: PropTypes.func.isRequired,
  isMoveModalOpen: PropTypes.bool.isRequired,
  onIsMoveModalOpen: PropTypes.func.isRequired,
  tagAnchorEl: refPropType.isRequired,
  setTagAnchorEl: PropTypes.func.isRequired,
  symlink: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(TagPropType).isRequired,
  isDeleteModalOpen: PropTypes.bool.isRequired,
  onIsDeleteModalOpen: PropTypes.func.isRequired
};

export default ExperimentActionModals;
