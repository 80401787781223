import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';

import cx from 'classnames';
import noop from 'lodash/noop';

import { TextButton } from '@ds';
import { DSCloseIcon } from '@ds-icons';

import styles from './Sidebar.module.scss';

const DS_SIDEBAR_SIDES = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom'
};

const Sidebar = ({ open, onClose, title, contentClassName, content, side }) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={side}>
      <div>
        <div className={styles.sidebarTitle}>
          <h2>{title}</h2>
        </div>

        <div className={cx(styles.sidebarContent, contentClassName)}>
          {content}
        </div>

        <div className={styles.sidebarFooter}>
          <TextButton
            PrefixIcon={<DSCloseIcon />}
            type="secondary"
            onClick={onClose}
          >
            Close
          </TextButton>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  contentClassName: PropTypes.string,
  content: PropTypes.node,
  side: PropTypes.oneOf(Object.values(DS_SIDEBAR_SIDES))
};

Sidebar.defaultProps = {
  open: false,
  onClose: noop,
  title: null,
  contentClassName: null,
  content: null,
  side: DS_SIDEBAR_SIDES.RIGHT
};

export default Sidebar;
