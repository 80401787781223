import useCurrentUser from '@API/auth/useCurrentUser';
import { useQuery } from 'react-query';
import userApi from '@/util/userApi';

const getUserSettings = async (username, signal) => {
  const { data } = await userApi.getUserSettings({ username, signal });

  return data;
};

const useUserSettings = (config = {}) => {
  const { data: currentUser } = useCurrentUser();
  const username = currentUser?.username;
  const configEnabled = config?.enabled && !!username;

  return useQuery(
    'userSettings',
    ({ signal }) => getUserSettings(username, signal),
    {
      ...config,
      enabled: configEnabled
    }
  );
};

export default useUserSettings;
