import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import cx from 'classnames';
import './SectionNavBar.scss';

const SectionNavBar = ({ documentTitle, children, isSticky, ...restProps }) => {
  return (
    <DocumentTitle title={documentTitle}>
      <div
        className={cx('ds-section-nav-bar', { sticky: isSticky })}
        {...restProps}
      >
        {children}
      </div>
    </DocumentTitle>
  );
};

SectionNavBar.defaultProps = {
  isSticky: false
};

SectionNavBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  documentTitle: PropTypes.string.isRequired,
  isSticky: PropTypes.bool
};

export default SectionNavBar;
