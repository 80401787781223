import { useMutation, useQueryClient } from 'react-query';

import api from '@API/api';

import useProject from '@API/project/useProject';

const removeQuerySegment = ({ projectId, filterId }) => {
  return api.delete(`query/filters/${filterId}`, {
    filterId,
    projectId
  });
};

export default function useDeleteFilterMutation() {
  const { data: project } = useProject();
  const queryClient = useQueryClient();
  const projectId = project?.projectId;

  return useMutation(
    ({ filterId }) => removeQuerySegment({ projectId, filterId }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['filters', { projectId }]);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['filters', { projectId }]);
      }
    }
  );
}
