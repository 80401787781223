import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import createRootReducer from '../reducers';
import { logger } from '../middleware/logger';

window.LOGS = false;

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export default function configureStore(initialState, history) {
  const middlewareForRouter = routerMiddleware(history);
  const middleware = [thunkMiddleware, middlewareForRouter, logger];
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    store.replaceReducer(createRootReducer(history));
  }

  return store;
}
