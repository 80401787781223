import { ROOT_URL } from '@/lib/appConstants';
import { getURLOverride } from '@experiment-management-shared/helpers';

export const addEnvImports = (packageName = 'comet_ml') => {
  const { hostname } = new URL(ROOT_URL, window.location.origin);

  if (
    [
      'comet.ml',
      'www.comet.ml',
      'comet.com',
      'www.comet.com',
      'comet-ml.com',
      'www.comet-ml.com'
    ].includes(hostname)
  ) {
    return '\n';
  }

  return `# point ${packageName} SDK to ${hostname} installation
import os
os.environ["COMET_URL_OVERRIDE"] = "${getURLOverride()}/"
`;
};
