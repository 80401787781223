import React from 'react';
import validator from 'validator';
import { Button } from '@ds';
import { Field, reduxForm } from 'redux-form';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';

import withAcademicSignup from './withAcademicSignup';
import { ACADEMIC_SIGNUP_FORM } from '@/constants/formTypes';
import { academicSignupValidation } from '@/helpers/formValidationHelpers';

const validate = values => {
  const errors = {};
  const requiredFields = ['position', 'isPublishing', 'email'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (values.email && !validator.isEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const renderOtherPublishing = ({ input, meta }) => (
  <FormControl>
    <Input disableUnderline {...input} placeholder="Enter position" />
    {meta.touched && meta.error && <span className="error">{meta.error}</span>}
  </FormControl>
);

const renderGraduationDate = ({ input }) => {
  const incrementArray = [...Array(10).keys()];
  const thisYear = new Date().getFullYear();
  const yearArray = incrementArray.map(
    incrementValue => incrementValue + thisYear
  );
  return (
    <>
      <InputLabel shrink disableAnimation>
        Year of Graduation
      </InputLabel>
      <NativeSelect disableUnderline {...input}>
        <option value="">Enter graduation year</option>
        {yearArray.map((year, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <option key={`year-array-el-${index}`} value={year}>
            {year}
          </option>
        ))}
      </NativeSelect>
    </>
  );
};

const renderTextField = ({ input, meta }) => (
  <FormControl>
    <InputLabel shrink disableAnimation>
      Academic email *
    </InputLabel>
    <Input disableUnderline {...input} placeholder="Enter email" />
    {meta.touched && meta.error && <span className="error">{meta.error}</span>}
  </FormControl>
);

const renderRadioGroup = ({ input, meta }) => (
  <>
    <InputLabel shrink disableAnimation>
      Have you previously published or plan to publish a research paper? *
    </InputLabel>
    <RadioGroup
      {...input}
      className="radio-group-row"
      valueSelected={input.value}
      onChange={(event, value) => input.onChange(value)}
    >
      <FormControlLabel
        value="yes-publishing"
        control={<Radio />}
        label="Yes"
      />
      <FormControlLabel value="no-publishing" control={<Radio />} label="No" />
    </RadioGroup>
    {meta.touched && meta.error && <span className="error">{meta.error}</span>}
  </>
);

const renderSelectField = ({ input, meta }) => (
  <>
    <InputLabel shrink disableAnimation>
      Position *
    </InputLabel>
    <NativeSelect disableUnderline {...input}>
      <option value="">Select position</option>
      <option value="student">Student</option>
      <option value="professor">Professor</option>
      <option value="researcher">Researcher</option>
      <option value="other">Other</option>
    </NativeSelect>
    {meta.touched && meta.error && <span className="error">{meta.error}</span>}
  </>
);

const AcademicSignupReduxForm = props => {
  const { handleSubmit, position, invalid } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={0}>
        <Grid
          container
          direction="column"
          spacing={2}
          className="academic-paper-wrapper"
        >
          <Grid item xs={12} className="academic-content-wrapper">
            <h3>Academic Registration</h3>
          </Grid>
          <Grid item className="academic-email-field-wrap">
            <Field
              placeholder="Enter email"
              name="email"
              component={renderTextField}
              label="Academic email"
            />
          </Grid>
          <Grid item className="academic-position-field-wrap">
            <Grid container direction="column">
              <Field
                name="position"
                component={renderSelectField}
                label="What is your position"
              />
            </Grid>
          </Grid>
          {position == 'other' && (
            <Grid item className="academic-other-publishing-wrap">
              <Field name="otherPublishing" component={renderOtherPublishing} />
            </Grid>
          )}
          {position == 'student' && (
            <Grid item className="academic-graduation-year-wrap">
              <Grid container direction="column">
                <Field name="graduationDate" component={renderGraduationDate} />
              </Grid>
            </Grid>
          )}
          <Grid item className="academic-is-publishing-wrap">
            <Field name="isPublishing" component={renderRadioGroup} />
          </Grid>
          <Grid item>
            <Button disabled={invalid}>Submit registration</Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

const AcademicSignupForm = withAcademicSignup(
  reduxForm({
    form: ACADEMIC_SIGNUP_FORM,
    validate,
    academicSignupValidation
  })(AcademicSignupReduxForm)
);

export default AcademicSignupForm;
