import { useMutation, useQueryClient } from 'react-query';
import modelRegistryApi from '../../util/modelRegistryApi';

const registerNewModel = async ({
  experimentModelId,
  registryModelName,
  description,
  isPublic,
  version
}) => {
  const newModel = {
    experimentModelId,
    registryModelName,
    description,
    isPublic,
    version,
    comment: '',
    stages: []
  };

  await modelRegistryApi.createNewModel(newModel);
};

const useModelRegistryAddNewModelMutation = ({ workspaceName }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      experimentModelId,
      registryModelName,
      description,
      isPublic,
      version
    }) =>
      registerNewModel({
        experimentModelId,
        registryModelName,
        description,
        isPublic,
        version
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'modelRegistryList',
          { workspaceName }
        ]);
      }
    }
  );
};

export default useModelRegistryAddNewModelMutation;
