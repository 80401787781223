import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { IconButton, Tooltip } from '@ds';
import { X_AXIS_OPTIONS } from '@experiment-management-shared/constants/chartConstants';

import { DSListViewIcon, DSPanelsIcon } from '@ds-icons';
import systemMetricActions from './systemMetricActions';
import ExperimentBar from '@experiment-details/components/ExperimentBar';

const SystemMetricsChartsHeader = ({
  entireRowCharts,
  xAxisType,
  isComparePage
}) => {
  const dispatch = useDispatch();
  const handleXAxisChange = ({ value }) => {
    dispatch(systemMetricActions.updateXAxisType(value, isComparePage));
  };

  const handleExpandCharts = () => {
    dispatch(systemMetricActions.updateEntireRowCharts(!entireRowCharts));
  };

  const tooltipText = entireRowCharts ? 'Collapse to grid' : 'Expand to row';

  const defaultValue = find(X_AXIS_OPTIONS, {
    value: xAxisType
  });

  return (
    <ExperimentBar
      rightContainerChildren={
        <ExperimentBar.ActionList>
          <Tooltip id="tooltip-icon" content={tooltipText} placement="top">
            <IconButton
              type="secondary"
              onClick={handleExpandCharts}
              Icon={!entireRowCharts ? <DSListViewIcon /> : <DSPanelsIcon />}
            />
          </Tooltip>
        </ExperimentBar.ActionList>
      }
    >
      <ExperimentBar.Select
        dataTest="system-metric-x-axis-container"
        label="X-axis"
        changeHandler={handleXAxisChange}
        value={defaultValue.value}
        options={X_AXIS_OPTIONS}
        isClearable={false}
      />
    </ExperimentBar>
  );
};

SystemMetricsChartsHeader.propTypes = {
  isComparePage: PropTypes.bool.isRequired,
  entireRowCharts: PropTypes.bool.isRequired,
  xAxisType: PropTypes.string.isRequired
};

export default SystemMetricsChartsHeader;
