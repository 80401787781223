import api from '@API/api';
import { useQuery } from 'react-query';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';

type AlertNotificationParams = {
  modelId: string;
};

type AlertCountResponse = {
  count: number;
};

const getAlertCount = ({
  modelId,
  workspaceId
}: AlertNotificationParams & { workspaceId: string }) =>
  api.get(`/mpm/${workspaceId}/models/${modelId}/alert-notifications/count `);

export function useAlertsNotificationCount(
  { modelId }: AlertNotificationParams,
  config: QueryConfig<AlertCountResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['alerts-count', { workspaceId: activeWorkspace?.id, modelId }],
    () => getAlertCount({ workspaceId: activeWorkspace?.id, modelId }),
    {
      ...config,
      enabled: !!activeWorkspace?.id
    }
  );
}
