import React, { useMemo } from 'react';
import find from 'lodash/find';

import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';
import {
  constructMPMFilterDefinition,
  constructMPMRuleLabel,
  getMPMOperatorsForRule
} from '@mpm/utils';
import FiltersSidebar from '@shared/components/FiltersSidebar';

type Rule = {
  condition: string;
  rules: Rule[];
};

type RulesTree = { condition: string; rules: Rule[] };

type Query = {
  rulesTree: RulesTree;
  segmentId?: string;
};

type FiltersSidebarContainerProps = {
  isOpen: boolean;
  onChange: (q: Query) => void;
  onClose: () => void;
  query: Query;
  hideClearButton?: () => void;
  columns: Record<string, string>[];
  title: string;
};

export const FiltersSidebarContainer = ({
  isOpen,
  onChange,
  onClose,
  query,
  hideClearButton,
  columns,
  title
}: FiltersSidebarContainerProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters: Array<never> = [];
  const canEdit = true;
  const { rulesTree, segmentId } = query;

  const activeFilter = useMemo(() => {
    return find(filters, { filterId: segmentId }) || {};
  }, [segmentId, filters]);

  const handleClose = () => {
    onClose();
  };

  const handleChangeFilters = (newRulesTree: Rule) => {
    onChange({ rulesTree: newRulesTree });
  };

  const handleClearQuery = () => {
    onChange({ rulesTree: generateEmptyRulesTree(), segmentId: '' });
  };

  return (
    <FiltersSidebar
      entityName={title}
      isOpen={isOpen}
      hideClearButton={hideClearButton}
      onChange={handleChangeFilters}
      onClose={handleClose}
      columns={columns}
      editable={canEdit}
      rulesTree={rulesTree}
      filters={filters}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      activeFilter={activeFilter}
      onClearQuery={handleClearQuery}
      getOperatorsForRule={getMPMOperatorsForRule}
      constructFilterDefinition={constructMPMFilterDefinition}
      constructRuleLabel={constructMPMRuleLabel}
    />
  );
};
