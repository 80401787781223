import { useMutation } from 'react-query';
import { trackEvent } from '@shared/utils/eventTrack';
import { useDispatch, useSelector } from 'react-redux';
import { fireTapfiliateConv } from '@shared/utils/useTapfiliate';

import userApi from '@/util/userApi';
import { signupEvents } from '@/constants/trackingEventTypes';
import { getUser } from '@/reducers/userReducer';
import userActions from '@/actions/userActions';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';

const useSendPostSignUpMutation = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  return useMutation(
    async data => {
      await userApi.addUserInfoPostSignup(data);

      trackEvent(signupEvents.POST_SIGNUP_USER_INFO_SUBMITTED);
      fireTapfiliateConv(user?.username, data);
    },
    {
      onError: () => {
        const errorMessage =
          'Failed to send additional post-signup user information.';

        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_POST_SIGNUP,
            errorMessage
          )
        );
      },
      onSettled: () => {
        const newUser = {
          ...user,
          showPostSignupForm: true
        };

        // update states
        dispatch(userActions.receiveUser(newUser));
      }
    }
  );
};

export default useSendPostSignUpMutation;
