import { useQuery } from 'react-query';
import experimentApi from '@experiment-management-shared/api/experimentApi';

const getExperimentModels = async (experimentKey, signal) => {
  const { data } = await experimentApi.fetchExperimentModels(
    experimentKey,
    signal
  );

  return data?.models || [];
};

const useExperimentModels = (experimentKey, config) => {
  const configEnabled = config?.enabled ?? true;

  return useQuery(
    ['modelRegistry', { experimentKey }, 'experiments'],
    ({ signal }) => getExperimentModels(experimentKey, signal),
    {
      ...config,
      enabled: !!experimentKey && configEnabled
    }
  );
};

export default useExperimentModels;
