/* eslint-disable no-multi-assign */
import { useEffect } from 'react';

const useHubspot = pathname => {
  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);
  }, [pathname]);
};

export default useHubspot;
