import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { trackEvent } from '@shared/utils/eventTrack';

import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import userApi from '@/util/userApi';

const useLoginToken = () => {
  const dispatch = useDispatch();
  const { loginError, loginToken } = queryString.parse(window.location.search, {
    parseBooleans: true
  });

  useEffect(() => {
    if (!loginToken) return;

    const url = queryString.parseUrl(window.location.href);

    delete url.query.loginToken;

    userApi
      .loginUser({ token: loginToken })
      .then(() => {
        trackEvent('login', { method: 'Token' });
      })
      .catch(() => {
        url.query.loginError = true;
      })
      .then(() => {
        // Force refresh to login user
        window.location.href = queryString.stringifyUrl(url);
      });
  }, [loginToken]);

  useEffect(() => {
    if (loginError) {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_LOGIN,
          'Login token is no longer valid'
        )
      );
    }
  }, [dispatch, loginError]);
};

export default useLoginToken;
