import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import ArtifactPage from '@artifacts/components/ArtifactPage';
import ArtifactVersionPage from '@artifacts/components/ArtifactVersionPage';

const ArtifactRoutes = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/:artifactName`}
        exact
        component={ArtifactPage}
      />
      <Route
        path={`${match.path}/:artifactName/:artifactVersionNumber`}
        exact
        component={ArtifactVersionPage}
      />
    </Switch>
  );
};

ArtifactRoutes.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ArtifactRoutes);
