import React, { useMemo } from 'react';
import { Button } from '@ds';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import union from 'lodash/union';
import { BottomActionsPanel } from '@DesignSystem/bottom-actions-panel';
import {
  MAX_COMPARE_EXPERIMENTS_COUNT,
  MIN_COMPARE_EXPERIMENTS_COUNT
} from '@experiment-management-shared/constants/experimentConstants';
import { useExpandedExperimentsGroupsInvalidator } from '@experiment-management-shared/hooks';
import { useActiveWorkspaceName } from '@shared/hooks';

import useArchiveExperimentsMutation from '@API/experiments/useArchiveExperimentsMutation';
import useRemoveExperimentsMutation from '@API/experiments/useRemoveExperimentsMutation';
import useRestoreExperimentsMutation from '@API/experiments/useRestoreExperimentsMutation';
import useUpdateTagsMutation from '@API/experiments/useUpdateTagsMutation';
import useProject from '@API/project/useProject';
import useRemoveProjectTagMutation from '@API/project/useRemoveProjectTagMutation';
import { dialogTypes } from '@/constants/alertTypes';
import { DISABLE_STOP_BUTTON } from '@/constants/configConstants';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import {
  getIsArchivePage,
  getIsExperimentsPage
} from '@/selectors/routeSelectors';
import alertsUtil from '@/util/alertsUtil';
import ArchiveExperimentButton from './ArchiveExperimentButton';
import ExportTableModalButton from './ExportTableModalButton';
import MoveExperimentModalButton from './MoveExperimentModalButton';
import StopExperimentButton from './StopExperimentButton';
import TagSelectButton from './TagSelectButton';
import { getLinkLocationFrom } from '@shared/utils/experimentNavHelpers';
import {
  DSDeleteIcon,
  DSDiffIcon,
  DSHideIcon,
  DSRestoreIcon,
  DSShowIcon
} from '@ds-icons';

export const ExperimentActions = ({
  dashboard,
  experiments,
  exportHandler,
  hiddenExperimentKeys,
  onChange,
  selectedExperiments,
  totalExperiments
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentWorkspaceName = useActiveWorkspaceName();
  const { data: project, isLoading: isLoadingProject } = useProject();
  const archiveExperimentsMutation = useArchiveExperimentsMutation();
  const removeExperimentsMutation = useRemoveExperimentsMutation();
  const removeProjectTagMutation = useRemoveProjectTagMutation();
  const restoreExperimentsMutation = useRestoreExperimentsMutation();
  const updateTagsMutation = useUpdateTagsMutation();
  const isArchive = useSelector(getIsArchivePage);
  const isExperimentsPage = useSelector(getIsExperimentsPage);

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const {
    invalidateExpandedGroups
  } = useExpandedExperimentsGroupsInvalidator();

  const linkLocationFrom = getLinkLocationFrom({
    isArchive,
    isExperimentsPage
  });

  const selectedExperimentKeys = useMemo(() => {
    return selectedExperiments.map(({ experimentKey }) => experimentKey);
  }, [selectedExperiments]);

  if (isLoadingProject) return null;

  const { canEdit, projectName, owner: isUserOwnProject } = project;
  const workspace = isUserOwnProject ? project.teamName : currentWorkspaceName;

  const calculateTableState = () => {
    const table = { selection: [] };
    const lastPageIndex =
      Math.ceil(totalExperiments / dashboard.table.pageSize) - 1;
    const isLastPage = lastPageIndex === dashboard.table.pageNumber;

    if (selectedExperiments.length === experiments.length) {
      // invalidate pagination state
      if (isLastPage) {
        table.pageNumber =
          lastPageIndex === 0 ? lastPageIndex : lastPageIndex - 1;
      }

      // invalidate expanded groups state,
      // in case we have some group opened, we will try to keep them opened or open first from the list
      if (!isEmpty(dashboard.table.expandedGroups)) {
        invalidateExpandedGroups();
      }
    }

    return table;
  };

  const handleArchiveExperiments = experimentKeys => {
    archiveExperimentsMutation.mutate({ experimentKeys });
    const newHiddenExperimentKeys = difference(
      hiddenExperimentKeys,
      experimentKeys
    );

    const table = calculateTableState();

    onChange({
      hiddenExperimentKeys: newHiddenExperimentKeys,
      pinnedExperimentKeys: dashboard.pinnedExperimentKeys.filter(
        experimentKey => !experimentKeys.includes(experimentKey)
      ),
      table
    });
  };

  const handleRemoveTag = tagName => {
    removeProjectTagMutation.mutate({ tagName });
  };

  const handleUpdateTags = tagUpdates => {
    updateTagsMutation.mutate({ tagUpdates });
  };

  const handleHideExperiments = () => {
    onChange({
      hiddenExperimentKeys: union(
        hiddenExperimentKeys,
        dashboard.table.selection
      )
    });
  };

  const handleShowExperiments = () => {
    onChange({
      hiddenExperimentKeys: difference(
        hiddenExperimentKeys,
        dashboard.table.selection
      )
    });
  };

  const handleRouteToExperimentDiffPage = () => {
    const experimentsQuery = selectedExperimentKeys.join(',');
    const archivePath = isArchive ? 'archive/' : '';

    const url = `/${workspace}/${projectName}/${archivePath}compare?experiments=${experimentsQuery}`;

    history.push(url, {
      from: linkLocationFrom,
      prevLocation: history.location
    });
  };

  const handleConfirmOverride = () => {
    dispatch(
      alertsUtil.closeDialog(dialogTypes.CONFIRM_DELETE_EXPERIMENT_PERMANENTLY)
    );

    const experimentKeys = selectedExperiments.map(
      ({ experimentKey }) => experimentKey
    );

    const table = calculateTableState();

    removeExperimentsMutation.mutate({ experimentKeys });

    onChange({
      table
    });
  };

  const handleDeleteExperimentPermanently = () => {
    dispatch(
      alertsUtil.openConfirmDialog(
        dialogTypes.CONFIRM_DELETE_EXPERIMENT_PERMANENTLY,
        'This will permanently delete your experiment.',
        handleConfirmOverride,
        'Delete'
      )
    );
  };

  const handleRestoreExperimentFromArchive = () => {
    const experimentKeys = selectedExperiments.map(
      ({ experimentKey }) => experimentKey
    );

    const table = calculateTableState();

    restoreExperimentsMutation.mutate({ experimentKeys });

    onChange({
      table
    });
  };

  const handleExperimentsMove = () => {
    const table = calculateTableState();

    onChange({
      table
    });
  };

  const isHideVisibilityButtonDisabled = selectedExperiments.every(experiment =>
    hiddenExperimentKeys.includes(experiment.experimentKey)
  );
  const isShowVisibilityButtonDisabled = !selectedExperiments.some(experiment =>
    hiddenExperimentKeys.includes(experiment.experimentKey)
  );
  const isExperimentActionBtnDisabled =
    isEmpty(selectedExperiments) || !canEdit || !isUserLoggedIn;
  const isDiffExperimentsButtonDisabled =
    selectedExperiments.length < MIN_COMPARE_EXPERIMENTS_COUNT ||
    selectedExperiments.length > MAX_COMPARE_EXPERIMENTS_COUNT;

  const BUTTONS = {
    ARCHIVE: (
      <ArchiveExperimentButton
        key="archive"
        disabled={isExperimentActionBtnDisabled}
        onArchiveExperiments={handleArchiveExperiments}
        selection={selectedExperiments}
      />
    ),
    DELETE: (
      <Button
        key="delete"
        size="large"
        onClick={handleDeleteExperimentPermanently}
        disabled={isExperimentActionBtnDisabled}
        PrefixIcon={<DSDeleteIcon />}
      >
        Delete
      </Button>
    ),
    RESTORE: (
      <Button
        key="restore"
        size="large"
        disabled={isExperimentActionBtnDisabled}
        onClick={handleRestoreExperimentFromArchive}
        PrefixIcon={<DSRestoreIcon />}
      >
        Restore
      </Button>
    ),
    MOVE: (
      <MoveExperimentModalButton
        key="move"
        disabled={isExperimentActionBtnDisabled}
        selectedExperiments={selectedExperiments}
        onMove={handleExperimentsMove}
      />
    ),
    TAGS: (
      <TagSelectButton
        key="tags"
        canEdit={canEdit}
        disabled={isExperimentActionBtnDisabled}
        onRemove={handleRemoveTag}
        onChange={handleUpdateTags}
        selectedExperiments={selectedExperiments}
      />
    ),
    DIFF: (
      <Button
        size="large"
        key="diff"
        data-test="experiment-diff-action"
        disabled={isDiffExperimentsButtonDisabled}
        onClick={handleRouteToExperimentDiffPage}
        PrefixIcon={<DSDiffIcon />}
      >
        Compare
      </Button>
    ),
    SHOW: (
      <Button
        size="large"
        key="show"
        disabled={isShowVisibilityButtonDisabled}
        onClick={handleShowExperiments}
        PrefixIcon={<DSShowIcon />}
      >
        Show
      </Button>
    ),
    HIDE: (
      <Button
        size="large"
        key="hide"
        disabled={isHideVisibilityButtonDisabled}
        onClick={handleHideExperiments}
        PrefixIcon={<DSHideIcon />}
      >
        Hide
      </Button>
    ),
    STOP: (
      <StopExperimentButton
        key="stop"
        disabled={DISABLE_STOP_BUTTON || isExperimentActionBtnDisabled}
        experiments={selectedExperiments}
      />
    ),
    EXPORT: (
      <ExportTableModalButton
        key="export"
        customFilenamePaths={['table', 'data']}
        fileExtension="csv"
        modalTitle="Export table data as CSV"
        isArchive={isArchive}
        closeMenuHandler={() => {}}
        submitHandler={exportHandler}
        totalExperiments={totalExperiments}
      />
    )
  };

  const renderProjectPageButtons = () => {
    if (isArchive) {
      return [
        BUTTONS.DELETE,
        BUTTONS.RESTORE,
        BUTTONS.DIFF,
        BUTTONS.TAGS,
        BUTTONS.STOP,
        BUTTONS.EXPORT
      ];
    }

    return [
      BUTTONS.ARCHIVE,
      BUTTONS.MOVE,
      BUTTONS.DIFF,
      BUTTONS.TAGS,
      BUTTONS.SHOW,
      BUTTONS.HIDE,
      BUTTONS.STOP,
      BUTTONS.EXPORT
    ];
  };

  return (
    <BottomActionsPanel
      selectedItemsTotal={selectedExperiments.length}
      labelItems="experiments"
    >
      {renderProjectPageButtons()}
    </BottomActionsPanel>
  );
};

ExperimentActions.defaultProps = {
  exportHandler: noop,
  hiddenExperimentKeys: [],
  onChange: noop,
  experiments: []
};

ExperimentActions.propTypes = {
  dashboard: PropTypes.object.isRequired,
  exportHandler: PropTypes.func,
  hiddenExperimentKeys: PropTypes.array,
  onChange: PropTypes.func,
  selectedExperiments: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalExperiments: PropTypes.number.isRequired,
  experiments: PropTypes.array
};

export default ExperimentActions;
