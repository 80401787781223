import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, IconButton } from '@ds';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

import { MANAGEMENT_PERMISSIONS } from '@shared/constants/permissions';
import useUserPermission from '@shared/api/useUserPermission';
import ManagePageWorkspaces from './ManagePageWorkspaces';
import projectsActions from '@/actions/projectsActions';
import VisibilitySection from './VisibilitySection';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import {
  DISABLE_INVITES,
  DISABLE_PUBLIC_ACCESS
} from '@/constants/configConstants';
import {
  getSelectedProjectId,
  getShareableLinks
} from '@/reducers/ui/projectsUiReducer';
import { getCurrentProjectName } from '@/reducers/projectsReducer';
import {
  getActiveWorkspaceName,
  getIsCollaborationEnabled
} from '@/reducers/ui/workspaceUiReducer';
import { useActiveWorkspace } from '@shared/hooks';

import { DSDeleteIcon, DSPlusIcon } from '@ds-icons';

const ManagePage = ({
  dispatch,
  isCollaborationEnabled,
  teamName,
  shareableLinks,
  projectId,
  projectName
}) => {
  const activeWorkspace = useActiveWorkspace();
  const history = useHistory();
  const { getPermissionStatus } = useUserPermission();

  const canInviteUsers =
    getPermissionStatus({
      workspaceName: activeWorkspace?.name,
      permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_FROM_ORGANIZATION
    }) ||
    getPermissionStatus({
      workspaceName: activeWorkspace?.name,
      permissionKey: MANAGEMENT_PERMISSIONS.INVITE_USERS_OUT_OF_ORGANIZATION
    });

  useEffect(() => {
    dispatch(projectsActions.getShareableLinks(projectId));
  }, [projectId]);

  const handleCopyLink = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_SHARE_LINK,
        'Successfully copied share link to clipboard'
      )
    );
  };

  const renderShareableLinkSection = () => {
    if (DISABLE_PUBLIC_ACCESS) return null;

    const rootUrl = `${window.location.origin}`;

    return (
      <div className="shareable-container">
        <Button
          disabled={!isEmpty(shareableLinks)}
          onClick={() => dispatch(projectsActions.addShareableLink(projectId))}
          data-test="create-shareable-link"
        >
          Create Shareable Link
        </Button>
        <div className="shareable-links-list">
          {shareableLinks &&
            shareableLinks.map(shareCode => {
              const displayLink = `${rootUrl}/${teamName}/${projectName}?shareable=${shareCode}`;

              return (
                <div className="shareable-link-container" key={shareCode}>
                  <div className="modal-input-with-btn">
                    <input
                      className="modal-input"
                      id="displayLink"
                      value={displayLink}
                      placeholder={displayLink}
                      readOnly
                      data-test="shareable-link-input"
                      style={{
                        margin: 0
                      }}
                    />
                    <Tooltip
                      title="Copy shareable link to clipboard"
                      placement="top"
                    >
                      <CopyToClipboard text={displayLink}>
                        <FileCopyIcon onClick={handleCopyLink} />
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                  <Tooltip title="Delete shareable link" placement="top">
                    <span>
                      <IconButton
                        type="secondary"
                        Icon={<DSDeleteIcon />}
                        onClick={() =>
                          dispatch(
                            projectsActions.deleteShareableLink(
                              projectId,
                              shareCode
                            )
                          )
                        }
                      />
                    </span>
                  </Tooltip>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderProjectVisibility = () => {
    if (DISABLE_PUBLIC_ACCESS) return null;

    return <VisibilitySection />;
  };

  const renderAddCollaboratorsButton = () => {
    const isEnabled = !!activeWorkspace && canInviteUsers;
    return (
      !DISABLE_INVITES && (
        <div className="collaborators-wrapper">
          <Button
            size="large"
            PrefixIcon={<DSPlusIcon />}
            className="add-member btn-blue"
            disabled={!isEnabled}
            onClick={() =>
              history.push(
                `/account-settings/workspaces?initialInviteId=${activeWorkspace?.id}`
              )
            }
          >
            Collaborators
          </Button>
        </div>
      )
    );
  };

  const renderWorkspaces = () => {
    if (!isCollaborationEnabled) return null;

    return (
      <section>
        <h4 className="section workspace-collaborators">
          <span>Workspace Collaborators</span>

          {renderAddCollaboratorsButton()}
        </h4>
        <ManagePageWorkspaces />
      </section>
    );
  };

  const renderContent = () => {
    return (
      <Grid item xs={12} className="settings-content">
        {renderProjectVisibility()}
        {renderShareableLinkSection()}
        {renderWorkspaces()}
      </Grid>
    );
  };

  return (
    <div
      id="settings"
      className="settings-page wallpaper-plain width-restricted-wrapper"
    >
      <div className="container page-container">
        <StickyContainer>
          <Grid container spacing={3} className="manage-tab-wrapper">
            {renderContent()}
          </Grid>
        </StickyContainer>
      </div>
    </div>
  );
};

ManagePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isCollaborationEnabled: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  shareableLinks: PropTypes.array.isRequired,
  teamName: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    isCollaborationEnabled: getIsCollaborationEnabled(state),
    projectId: getSelectedProjectId(state),
    projectName: getCurrentProjectName(state),
    shareableLinks: getShareableLinks(state),
    teamName: getActiveWorkspaceName(state)
  };
};

export default connect(mapStateToProps)(ManagePage);
