import { APP_LOCATION } from '@/constants/generalConstants';
import { experimentEvents } from '@/constants/trackingEventTypes';
import useCurrentUser from '@API/auth/useCurrentUser';
import useProject from '@API/project/useProject';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { ExperimentDetails } from '@shared/types';
import { useCallback } from 'react';

const useHyperParametersBI = () => {
  const baseTrackEvent = useBaseTrackEvent();
  const { data: project } = useProject();
  const { data: currentUser } = useCurrentUser();

  const sendHyperParametersTabBI = useCallback(
    (
      experiment: ExperimentDetails,
      event: keyof typeof experimentEvents,
      params: Record<string, unknown> = {}
    ) => {
      baseTrackEvent(event, {
        canEdit: project?.canEdit,
        createdAt: experiment.start_server_timestamp,
        experimentKeys: [experiment.experimentKey],
        isActive: experiment.runActive,
        isOwner: project?.isOwner,
        isPublic: project?.isPublic,
        isShared: project?.isShared,
        isUserLoggedIn: currentUser?.isLoggedInWithGithub,
        usedWhere: APP_LOCATION.SINGLE_EXPERIMENT,
        ...params
      });
    },
    [baseTrackEvent, currentUser, project]
  );

  const sendCompareHyperParametersTabBI = useCallback(
    (
      experiments: ExperimentDetails[],
      event: keyof typeof experimentEvents,
      params: Record<string, unknown> = {}
    ) => {
      baseTrackEvent(event, {
        canEdit: project?.canEdit,
        experimentKeys: experiments.map(exp => exp.experimentKey),
        isOwner: project?.isOwner,
        isPublic: project?.isPublic,
        isShared: project?.isShared,
        isUserLoggedIn: currentUser?.isLoggedInWithGithub,
        usedWhere: APP_LOCATION.SINGLE_EXPERIMENT,
        ...params
      });
    },
    [baseTrackEvent, currentUser, project]
  );

  return { sendHyperParametersTabBI, sendCompareHyperParametersTabBI };
};
export default useHyperParametersBI;
