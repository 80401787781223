import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Tooltip, IconButton } from '@ds';

import { getIsArchivePage } from '@/selectors/routeSelectors';
import { getExperimentUrl } from '@shared/utils/url';
import { DSOpenInNewIcon } from '@ds-icons';

const SingleExperimentButton = ({ row }) => {
  const { projectName, workspace } = useParams();
  const isArchive = useSelector(getIsArchivePage);

  const experimentUrl = getExperimentUrl({
    isArchive,
    projectName,
    experimentKey: row?.experimentKey,
    workspace
  });

  return (
    <div className="react-grid-table-icon">
      <Tooltip content="Click to open single experiment" placement="top">
        <Link to={experimentUrl} data-test="go-to-single-experiment">
          <IconButton type="seconary" Icon={<DSOpenInNewIcon />} />
        </Link>
      </Tooltip>
    </div>
  );
};

SingleExperimentButton.propTypes = {
  row: PropTypes.object.isRequired
};

export default SingleExperimentButton;
