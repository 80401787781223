import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { IS_DEVELOPMENT_ENV } from '@/constants/configConstants';
import { getIsUserLoggedIn } from '@/reducers/userReducer';

const PrivateRoute = ({ isUserLoggedIn, redirectTo, ...routeProps }) => {
  if (!isUserLoggedIn) {
    window.location.href = redirectTo;
    return null;
  }

  return <Route {...routeProps} />;
};

PrivateRoute.defaultProps = {
  redirectTo: IS_DEVELOPMENT_ENV ? '/login' : '/site/'
};

PrivateRoute.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string
};

const mapStateToProps = state => ({
  isUserLoggedIn: getIsUserLoggedIn(state)
});

export default connect(mapStateToProps)(PrivateRoute);
