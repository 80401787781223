import '@app/environmentVariablesOverwrite';
import initSentry from '@app/sentryConfig';
import reduxPromise, { store } from '@app/setupRedux';
import usePyodide from '@app/usePyodide';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { createGenerateClassName, StylesProvider } from '@material-ui/styles';

import '@ds-styles-init';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'highlight.js/styles/github.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import UpdateProvider from '@platform/components/UpdateProvider';
import { DEFAULT_APP_TITLE } from './constants/generalConstants';
import './css/style.scss';
import './DesignSystem/styles/styles.scss';
import history from './history';
import MATERIAL_THEME_OVERRIDE from './lib/materialOverride';
import Routes from './routes';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c'
});

const theme = createTheme(MATERIAL_THEME_OVERRIDE);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  usePyodide();

  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <UpdateProvider>
              <DocumentTitle title={DEFAULT_APP_TITLE}>
                <Routes history={history} />
              </DocumentTitle>
            </UpdateProvider>
          </QueryClientProvider>
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

reduxPromise.then(() => {
  initSentry();
  render(<App />, document.getElementById('root'));
});

export default App;
