import useProjectParams from '@API/project/useProjectParams';
import ReactGrid from '@experiment-management-shared/components/ReactGrid/BaseGrid';
import { SPECIAL_CELL_COMPONENTS } from '@experiment-management-shared/components/TableCells';
import { EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT } from '@experiment-management-shared/constants/experimentConstants';
import {
  EXCLUDED_VIRTUAL_COLUMNS,
  GRID_COLUMNS
} from '@experiment-management-shared/constants/experimentGridConstants';
import { transformColumnNameToColumn } from '@experiment-management-shared/utils/reactGridHelpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import FiltersButton from '@reports/components/FiltersButton';
import {
  REPORT_TABLE_DEFAULT_COLUMN_WIDTH,
  REPORT_TABLE_PAGE_SIZES,
  REPORT_TABLE_REQUIRED_COLUMNS
} from '@reports/constants';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import cx from 'classnames';
import { debounce, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import visualizationsActions from '@/actions/visualizationsActions';
import CustomizeColumnsModalContainer from '@experiment-management-shared/components/ColumnsModal/CustomizeColumnsContainer';
import GroupByModal from '@experiment-management-shared/components/ColumnsModal/GroupByModal';
import StyledSwitch from '@shared/components/StyledComponents/StyledSwitch';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

import DecimalPrecisionSlider from './DecimalPrecisionSlider';
import styles from './SectionTable.module.scss';
import { allowToHaveOnlyOneLowLevelGroupOpened } from '@experiment-management-shared';
import { Button } from '@ds';
import { DSEditIcon, DSGroupIcon, DSHideIcon, DSShowIcon } from '@ds-icons';
import { useTrackTableEventsBI } from '@shared/hooks';
import VisibilityAllButton from '@experiment-management-shared/components/TableCells/cell/VisibilityAllButton';

const SectionTable = ({
  availableColumns,
  onQueryChange,
  query,
  editMode,
  experiments: currentPageExperiments,
  experimentsGroupsMap,
  groupAggregations,
  hiddenExperimentKeys,
  isLoading,
  onChange,
  onChangeHiddenExperimentKeys,
  onCurrentPageChange,
  pageNumber,
  pageTotalGrouped,
  table,
  totalExperiments
}) => {
  const {
    columnGrouping,
    columnOrders,
    columnSorting,
    columnWidths,
    decimalPrecision,
    expandedGroups,
    frozenExperimentKeys,
    isFrozen,
    pageSize,
    pinnedExperimentKeys,
    isVisible
  } = table;

  const dispatch = useDispatch();
  const { projectName } = useProjectParams();
  const [selection, setSelection] = useState([]);

  const {
    onSortingSendBI,
    onUpdateColumnsSendBI,
    onUpdateGroupsSendBI
  } = useTrackTableEventsBI();

  const reactGrid = useMemo(
    () => ({
      columnGrouping,
      columnOrders,
      columnSorting,
      columnWidths,
      expandedGroups,
      pageNumber,
      pageSize,
      selection
    }),
    [
      columnGrouping,
      columnOrders,
      columnSorting,
      columnWidths,
      expandedGroups,
      pageNumber,
      pageSize,
      selection
    ]
  );

  const experiments = useMemo(() => {
    return currentPageExperiments.filter(experiment => {
      return (
        !frozenExperimentKeys ||
        frozenExperimentKeys.includes(experiment.experimentKey)
      );
    });
  }, [currentPageExperiments, frozenExperimentKeys]);

  const rows = useMemo(() => {
    return experiments.map(experiment => ({
      ...experiment,
      isVisibleOnDashboard: !hiddenExperimentKeys.includes(
        experiment.experimentKey
      )
    }));
  }, [experiments, hiddenExperimentKeys]);

  const selectedColumns = useMemo(() => {
    return table.columnOrders.map(transformColumnNameToColumn);
  }, [table.columnOrders]);

  useEffect(() => {
    setSelection([]);
  }, [editMode]);

  const handleChange = key => value => {
    onChange({
      ...table,
      [key]: value
    });

    if (key === 'columnSorting') {
      onSortingSendBI({
        sortedColumnsCount: value.length
      });
    }
  };

  const handleColumnsUpdate = selectedColumnNames => {
    const newColumnOrders = uniq([
      ...REPORT_TABLE_REQUIRED_COLUMNS,
      ...selectedColumnNames
    ]);

    const newColumnWidths = newColumnOrders.map(columnName => {
      const columnWidth = columnWidths.find(
        column => column.columnName === columnName
      );

      if (columnWidth) {
        return columnWidth;
      }

      return {
        columnName,
        width: REPORT_TABLE_DEFAULT_COLUMN_WIDTH
      };
    });

    onChange({
      ...table,
      columnOrders: newColumnOrders,
      columnWidths: newColumnWidths
    });

    onUpdateColumnsSendBI({
      columnNames: selectedColumnNames
    });

    dispatch(alertsUtil.closeDialog(dialogTypes.CUSTOMIZE_COLUMNS_MODAL));
  };

  const handleGroupByColumnsUpdate = columnGrouping => {
    const newColumnOrders = uniq([...table.columnOrders, ...columnGrouping]);

    const newColumnWidths = newColumnOrders.map(columnName => {
      if (columnWidths[columnName]) {
        return columnWidths[columnName];
      }

      return {
        columnName,
        width: REPORT_TABLE_DEFAULT_COLUMN_WIDTH
      };
    });

    onChange({
      ...table,
      columnGrouping,
      columnOrders: newColumnOrders,
      columnWidths: newColumnWidths,
      expandedGroups: []
    });

    onCurrentPageChange(0);

    onUpdateGroupsSendBI({
      columnNames: columnGrouping
    });

    dispatch(alertsUtil.closeDialog(dialogTypes.GROUPING_MODAL));
  };

  const handleIsFrozenChange = event => {
    const experimentKeys = currentPageExperiments.map(
      experiment => experiment.experimentKey
    );
    const isFrozen = event.target.checked;
    const frozenExperimentKeys = isFrozen ? experimentKeys : null;

    onChange({
      ...table,
      frozenExperimentKeys,
      isFrozen
    });

    onCurrentPageChange(0);
  };

  const handleExpandedGroupsChange = expandedGroups => {
    let newExpandedGroups = allowToHaveOnlyOneLowLevelGroupOpened(
      expandedGroups,
      table?.columnGrouping
    );

    onChange({
      ...table,
      expandedGroups: newExpandedGroups,
      pageNumber: 0
    });

    onCurrentPageChange(0);
  };

  const handleMouseOut = () => {
    dispatch(
      visualizationsActions.setHoveredExperimentTrace({
        experimentKey: null,
        source: null
      })
    );
  };

  const setHoveredExperimentTrace = useCallback(
    experimentKey => {
      dispatch(
        visualizationsActions.setHoveredExperimentTrace({ experimentKey })
      );
    },
    [dispatch]
  );

  const handleHoverExperimentNameCell = useMemo(
    () =>
      debounce(setHoveredExperimentTrace, EXPERIMENT_NAME_HOVER_DEBOUNCE_WAIT),
    [setHoveredExperimentTrace]
  );

  useEffect(() => {
    return () => {
      handleHoverExperimentNameCell.cancel();
    };
  }, [handleHoverExperimentNameCell]);

  const handleOpenCustomizeColumnsModal = () => {
    const modalId = dialogTypes.CUSTOMIZE_COLUMNS_MODAL;
    const customizeColumnsModal = (
      <CustomizeColumnsModalContainer
        onUpdateColumns={handleColumnsUpdate}
        selectedColumnNames={columnOrders}
        onClose={() =>
          dispatch(alertsUtil.closeDialog(dialogTypes.CUSTOMIZE_COLUMNS_MODAL))
        }
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, customizeColumnsModal));
  };

  const handleOpenGroupByModal = () => {
    const modalId = dialogTypes.GROUPING_MODAL;

    const groupByModal = (
      <GroupByModal
        onChange={handleGroupByColumnsUpdate}
        selectedColumnNames={table.columnGrouping}
        excludedColumns={EXCLUDED_VIRTUAL_COLUMNS}
        onClose={() =>
          dispatch(alertsUtil.closeDialog(dialogTypes.GROUPING_MODAL))
        }
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, groupByModal));
  };

  const handleTogglePinned = experimentKey => {
    const isExperimentPinned = pinnedExperimentKeys.includes(experimentKey);
    const newPinnedExperimentKeys = isExperimentPinned
      ? pinnedExperimentKeys.filter(key => key !== experimentKey)
      : [...pinnedExperimentKeys, experimentKey];

    onChange({
      ...table,
      pinnedExperimentKeys: newPinnedExperimentKeys
    });
  };

  const handleToggleVisibility = experimentKey => {
    const newHiddenExperimentKeys = hiddenExperimentKeys.includes(experimentKey)
      ? hiddenExperimentKeys.filter(key => key !== experimentKey)
      : [...hiddenExperimentKeys, experimentKey];

    onChangeHiddenExperimentKeys(newHiddenExperimentKeys);
  };

  const hideSelectedExperiments = () => {
    const newHiddenExperimentKeys = uniq([
      ...hiddenExperimentKeys,
      ...selection
    ]);

    onChangeHiddenExperimentKeys(newHiddenExperimentKeys);
  };

  const renderLeftFooterSide = () => {
    return (
      <div className={styles.freezeDataContainer}>
        <FormControlLabel
          control={
            <StyledSwitch
              checked={isFrozen}
              onChange={handleIsFrozenChange}
              color="primary"
            />
          }
          className={styles.freezeData}
          disabled={editMode !== REPORT_EDIT_MODES.EDIT}
          label="Freeze table data"
        />

        <Tooltip
          aria-label="auto update experiments help"
          arrow
          placement="top"
          title={`Disable fetching new experiments automatically from '${projectName}' that match the filters for this section`}
        >
          <InfoIcon fontSize="small" style={{ color: '#b7bcc8' }} />
        </Tooltip>
      </div>
    );
  };

  const renderSearchResultSummary = () => {
    const pagingOffset = pageSize * pageNumber;
    const startOfSubset = Math.min(totalExperiments, pagingOffset + 1);
    const endOfSubset = Math.min(totalExperiments, pageSize + pagingOffset);
    const summaryMsg = `Showing ${startOfSubset}-${endOfSubset} of ${totalExperiments} total experiments`;

    return (
      <div className={styles.searchResultSummary}>
        <span>{summaryMsg}</span>
      </div>
    );
  };

  const showSelectedExperiments = () => {
    const newHiddenExperimentKeys = hiddenExperimentKeys.filter(
      experimentKey => {
        return !selection.includes(experimentKey);
      }
    );

    onChangeHiddenExperimentKeys(newHiddenExperimentKeys);
  };

  const handleVisibilityChange = useCallback(
    keys => onChangeHiddenExperimentKeys(keys),
    [onChangeHiddenExperimentKeys]
  );

  const experimentKeys = useMemo(() => rows.map(e => e.experimentKey), [rows]);

  const reactGridContextAdditionalData = useMemo(() => {
    return {
      handleAllVisibilityChange: keys => onChangeHiddenExperimentKeys(keys),
      hiddenExperimentKeys
    };
  }, [hiddenExperimentKeys, onChangeHiddenExperimentKeys]);

  const headerRowComponents = useMemo(() => {
    return {
      [GRID_COLUMNS.NAME]: {
        prefix: (
          <VisibilityAllButton
            experimentKeys={experimentKeys}
            hiddenExperimentKeys={hiddenExperimentKeys}
            onChange={handleVisibilityChange}
          />
        )
      }
    };
  }, [experimentKeys, hiddenExperimentKeys, handleVisibilityChange]);

  return (
    <div>
      <div
        className={cx(styles.table, {
          [styles.hidden]: !isVisible
        })}
      >
        <div className={styles.header}>
          <div className={styles.searchResultSummaryRow}>
            {renderSearchResultSummary()}
          </div>
          <div className={styles.buttonsRow}>
            <div className="btn-group small">
              <FiltersButton onChange={onQueryChange} query={query} />
            </div>
            <div className="btn-group small">
              <DecimalPrecisionSlider onChange={onChange} table={table} />

              <Button
                onClick={showSelectedExperiments}
                disabled={!selection.length}
                PrefixIcon={<DSShowIcon />}
              >
                Show
              </Button>

              <Button
                onClick={hideSelectedExperiments}
                disabled={!selection.length}
                PrefixIcon={<DSHideIcon />}
              >
                Hide
              </Button>

              <Button
                className="primary-button"
                label="Dialog"
                onClick={handleOpenGroupByModal}
                PrefixIcon={<DSGroupIcon />}
              >
                Group by
              </Button>

              <Button
                onClick={handleOpenCustomizeColumnsModal}
                PrefixIcon={<DSEditIcon />}
              >
                Customize columns
              </Button>
            </div>
          </div>
        </div>

        <div className="react-grid" onMouseOut={handleMouseOut}>
          <ReactGrid
            availableColumns={availableColumns}
            allowColumnReordering
            allowPagination
            allowSelection
            allowSorting
            canHideDetails
            columns={selectedColumns}
            contextAdditionalData={reactGridContextAdditionalData}
            cellsMap={SPECIAL_CELL_COMPONENTS}
            decimalsPrecision={decimalPrecision}
            experimentsGroupsMap={experimentsGroupsMap}
            groupAggregations={groupAggregations}
            hasRowDetail
            headerRowComponents={headerRowComponents}
            isLoadingExperimentsData={isLoading}
            leftFooterSide={renderLeftFooterSide()}
            onColumnWidthsChange={handleChange('columnWidths')}
            onCurrentPageChange={onCurrentPageChange}
            onExpandedGroupsChange={handleExpandedGroupsChange}
            onHoverExperimentNameCell={handleHoverExperimentNameCell}
            onOrderChange={handleChange('columnOrders')}
            onPageSizeChange={handleChange('pageSize')}
            onSelectionChange={setSelection}
            onSortingChange={handleChange('columnSorting')}
            onTogglePinned={handleTogglePinned}
            onToggleVisibility={handleToggleVisibility}
            openLinksInNewTab
            pageSizes={REPORT_TABLE_PAGE_SIZES}
            pageTotalGrouped={pageTotalGrouped}
            pinnedExperimentKeys={pinnedExperimentKeys}
            reactGrid={reactGrid}
            resizingEnabled
            rows={rows}
            shouldUseServerSidePagination
            totalExperiments={totalExperiments}
          />
        </div>
      </div>
    </div>
  );
};

SectionTable.defaultProps = {
  availableColumns: [],
  groupAggregations: null
};

SectionTable.propTypes = {
  availableColumns: PropTypes.array,
  onQueryChange: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  editMode: PropTypes.string.isRequired,
  experiments: PropTypes.array.isRequired,
  experimentsGroupsMap: PropTypes.object,
  groupAggregations: PropTypes.object,
  hiddenExperimentKeys: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeHiddenExperimentKeys: PropTypes.func.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageTotalGrouped: PropTypes.number.isRequired,
  table: PropTypes.object.isRequired,
  totalExperiments: PropTypes.number.isRequired
};

export default SectionTable;
