import { useCallback } from 'react';
import find from 'lodash/find';
import { useCurrentOrganization } from '@shared/hooks';
import {
  MANAGEMENT_PERMISSIONS,
  MANAGEMENT_PERMISSIONS_SERVER
} from '../constants/permissions';
import {
  formatPermissionValueToBoolean,
  getPermissionStatusByKey,
  getPermissionValueByType,
  ifPermissionValueTruthful
} from '../utils/permissions';
import useUserOrganizationPermissions from './useUserOrganizationPermissions';

const useUserPermission = config => {
  const configEnabled = config?.enabled ?? true;
  const currentOrganization = useCurrentOrganization();
  const { data: userPermissions } = useUserOrganizationPermissions(null, {
    refetchOnMount: true,
    // there is no need in permissions, if a user is admin
    enabled: configEnabled && !currentOrganization?.isAdmin
  });

  const getServerPermissionStatus = useCallback(
    ({ workspaceName, permissionKey }) => {
      const workspacePermissions =
        find(
          userPermissions || [],
          permission => permission?.workspaceName === workspaceName
        )?.permissions || [];

      return formatPermissionValueToBoolean(
        getPermissionValueByType(workspacePermissions, permissionKey)
      );
    },
    [userPermissions, currentOrganization]
  );

  const getPermissionStatus = useCallback(
    ({ workspaceName, permissionKey }) => {
      if (!currentOrganization) {
        return false;
      }

      if (
        permissionKey ===
        MANAGEMENT_PERMISSIONS.CHANGE_WORKSPACE_ROLE_FOR_YOURSELF
      ) {
        return false;
      }

      if (currentOrganization.isAdmin) {
        return true;
      }

      if (!userPermissions?.length) {
        return false;
      }

      const workspacePermissions =
        find(
          userPermissions || [],
          permission => permission?.workspaceName === workspaceName
        )?.permissions || [];

      if (!workspacePermissions?.length) {
        return false;
      }

      const projectVisibilityStatus = ifPermissionValueTruthful(
        getPermissionValueByType(
          workspacePermissions,
          MANAGEMENT_PERMISSIONS_SERVER.PROJECT_VISIBILITY
        )
      );

      const inviteUsersStatus = ifPermissionValueTruthful(
        getPermissionValueByType(
          workspacePermissions,
          MANAGEMENT_PERMISSIONS_SERVER.INVITE_USERS
        )
      );
      const managementStatus = ifPermissionValueTruthful(
        getPermissionValueByType(
          workspacePermissions,
          MANAGEMENT_PERMISSIONS_SERVER.MANAGEMENT
        )
      );

      return getPermissionStatusByKey({
        permissionKey,
        projectVisibilityStatus,
        inviteUsersStatus,
        managementStatus,
        onlyAdminsCanInviteOutsideOrganizationStatus:
          currentOrganization?.onlyAdminsInviteByEmail
      });
    },
    [userPermissions, currentOrganization]
  );

  return { getPermissionStatus, getServerPermissionStatus };
};

export default useUserPermission;
