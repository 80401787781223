import { useExperimentUniqueParams } from '@experiment-management-shared';

const useHyperParametersIsDisabled = () => {
  const { data = [], isLoading } = useExperimentUniqueParams();

  const hasData = Boolean(data?.length);

  return isLoading || !hasData;
};

export default useHyperParametersIsDisabled;
