import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Table } from '@devexpress/dx-react-grid-material-ui';

const ReactGridRowView = props => {
  const { children, row, isActionMenuOpened, className } = props;

  return (
    <Table.Row
      row={row}
      className={cx(
        'default-table-row-view',
        className,
        isActionMenuOpened(row) && 'active-table-row-view'
      )}
    >
      {children}
    </Table.Row>
  );
};

ReactGridRowView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  row: PropTypes.object.isRequired,
  isActionMenuOpened: PropTypes.func,
  className: PropTypes.string
};

ReactGridRowView.defaultProps = {
  className: '',
  isActionMenuOpened: () => false
};

export default ReactGridRowView;
