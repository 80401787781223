import {
  BlogIcon,
  DocumentationIcon,
  EventIcon,
  ResourcesIcon
} from '@Icons-outdated';
import { getDocLink } from '@shared';
import React from 'react';

import styles from './HelpSidebar.module.scss';

const CometResources = () => (
  <div>
    <div className={styles.helpSidebarSectionTitle}>Comet resources</div>

    <div className={styles.resourcesContainer}>
      <a
        href={getDocLink()}
        target="_blank"
        rel="noreferrer"
        className={styles.resourceContainerLink}
      >
        <div className={styles.resourceContainer}>
          <div className={styles.helpSidebarFieldTitle}>
            <DocumentationIcon />
            <span>Docs</span>
          </div>

          <div className={styles.resourceSecondaryLabel}>
            Learn more about how to use Comet through the UI and SDK
          </div>
        </div>
      </a>

      <a
        href="https://www.comet.com/site/blog/"
        target="_blank"
        rel="noreferrer"
        className={styles.resourceContainerLink}
      >
        <div className={styles.resourceContainer}>
          <div className={styles.helpSidebarFieldTitle}>
            <BlogIcon />
            <span>Blog</span>
          </div>

          <div className={styles.resourceSecondaryLabel}>
            Stay up to date with our latest thought leadership and articles
          </div>
        </div>
      </a>

      <a
        href="https://www.comet.com/site/learn/resources/"
        target="_blank"
        rel="noreferrer"
        className={styles.resourceContainerLink}
      >
        <div className={styles.resourceContainer}>
          <div className={styles.helpSidebarFieldTitle}>
            <ResourcesIcon />
            <span>Resources</span>
          </div>

          <div className={styles.resourceSecondaryLabel}>
            Get more out of your ML organization with best practices and
            insights
          </div>
        </div>
      </a>

      <a
        href="https://www.comet.com/site/about-us/news-and-events/events/"
        target="_blank"
        rel="noreferrer"
        className={styles.resourceContainerLink}
      >
        <div className={styles.resourceContainer}>
          <div className={styles.helpSidebarFieldTitle}>
            <EventIcon />
            <span>Events</span>
          </div>

          <div className={styles.resourceSecondaryLabel}>
            Find out about our latest events, webinars, conferences and meetups
          </div>
        </div>
      </a>
    </div>
  </div>
);

export default CometResources;
