import cx from 'classnames';
import React from 'react';

import { ImageWithAnnotations } from '@/DesignSystem/components/images/ImageWithAnnotations/ImageWithAnnotations';
import { ImageMetadata } from '@experiment-management-shared/api';
import { useVisibleAnnotations } from './useVisibleAnnotations';

import styles from './ImageThumbnail.module.scss';
import { useRefSizes } from '@shared/hooks';

type ImageThumbnailProps = {
  confidenceScore?: number;
  hiddenLabels?: string[];
  metadata?: ImageMetadata;
  name?: string;
  onClick?: () => void;
  src?: string;
  originalImageSize?: {
    originalImageHeight: number;
    originalImageWidth: number;
  };
};

export function ImageThumbnail({
  confidenceScore = 0,
  hiddenLabels = [],
  metadata,
  name = '',
  onClick,
  src,
  originalImageSize
}: ImageThumbnailProps) {
  const visibleAnnotations = useVisibleAnnotations({
    annotations: metadata?.annotations,
    hiddenLabels,
    score: confidenceScore
  });
  const { refHeight, sizeRef } = useRefSizes();
  const renderImage = () => {
    if (!src) {
      return <div className={styles.noImage}>Image not found</div>;
    }

    if (!metadata)
      return <img src={src} alt={name} style={{ maxHeight: refHeight }} />;

    return (
      <ImageWithAnnotations
        alt={name}
        annotations={visibleAnnotations}
        className={cx({ [styles.pointer]: !!onClick })}
        imagePath={src}
        labelColorMap={metadata.labelColorMap}
        isLabelsTextHidden
        maxHeight={refHeight}
        originalImageSize={originalImageSize}
      />
    );
  };

  return (
    <div onClick={onClick} ref={sizeRef} className={styles.thumbnail}>
      {renderImage()}
    </div>
  );
}
