import React from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';

import { IConfusionMatrixData } from './IConfusionMatrixData';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import ConfusionMatrixExamples from './ConfusionMatrixExamples';

export const IConfusionMatrixExampleData = PropTypes.shape({
  data: IConfusionMatrixData.isRequired,
  experiment: IExperimentDetails.isRequired,
  title: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      col: PropTypes.number.isRequired
    })
  ).isRequired,
  popupPosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
});

const ConfusionMatricesExamples = ({
  highlightedCellObject,
  matricesExampleMap,
  onCloseExample,
  onCloseExampleCell
}) => {
  const examples = Object.keys(matricesExampleMap).map(assetId => {
    const {
      data,
      positions,
      experiment,
      title,
      popupPosition
    } = matricesExampleMap[assetId];
    if (!data) return null;

    const key = `confusion-matrix-example-${assetId}`;

    return (
      <ConfusionMatrixExamples
        key={key}
        experiment={experiment}
        title={title}
        data={data}
        positions={positions}
        onClose={() => onCloseExample({ assetId })}
        highlightedCell={highlightedCellObject[assetId]}
        onCloseCell={cell => onCloseExampleCell({ assetId }, cell)}
        DraggableProps={{
          defaultPosition: popupPosition
        }}
      />
    );
  });

  return <>{examples}</>;
};

ConfusionMatricesExamples.defaultProps = {
  onCloseExample: noop,
  onCloseExampleCell: noop
};

ConfusionMatricesExamples.propTypes = {
  matricesExampleMap: PropTypes.objectOf(IConfusionMatrixExampleData)
    .isRequired,
  highlightedCellObject: PropTypes.objectOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    })
  ).isRequired,
  onCloseExample: PropTypes.func,
  onCloseExampleCell: PropTypes.func
};

export default ConfusionMatricesExamples;
