import Dexie, { Table } from 'dexie';
import { ProjectTemplate } from '@experiment-management-shared/types';

type ProjectViewRow = {
  projectId: string;
  viewId: string;
  view: ProjectTemplate;
};

export class CometDB extends Dexie {
  projectViews: Table<ProjectViewRow, [string, string]>;

  constructor() {
    super('CometDB');

    this.version(1).stores({
      projectViews: '[projectId+viewId], projectId'
    });

    this.projectViews = this.table('projectViews');
  }
}

const db = new CometDB();

export const projectViewsStore = db.projectViews;
