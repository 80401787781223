// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyContainer--rz_v_{padding:20px;font-weight:300}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyRightPane--Gvc8h{max-width:540px;height:440px}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyIcon--_zrtt img{width:556px;height:338px;max-width:100%}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyTitle--Mc4d2{color:var(--gray-7);font-size:24px;line-height:36px;margin-bottom:11px}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptySmallTitle--sjVjT{color:var(--gray-6);font-size:16px;line-height:24px;margin-bottom:15px;font-weight:500}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyText--T_fHl{color:var(--gray-7);font-size:16px;line-height:24px;margin-bottom:37px}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyTextLink--MSqMt{margin-top:25px;color:var(--primary-color);font-size:16px;line-height:24px;text-decoration:none}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyTextLink--MSqMt:hover{text-decoration:underline}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyList--Cp8m_{color:var(--gray-6);font-size:16px;line-height:40px}.ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyListNumber--sEsiL{margin-right:12px !important;margin-left:-5px;text-align:center;width:30px;height:30px;line-height:30px;border-radius:50%;background-color:var(--primary-color-100);color:var(--primary-color)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelRegistryTabEmptyContainer": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyContainer--rz_v_`,
	"modelRegistryTabEmptyRightPane": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyRightPane--Gvc8h`,
	"modelRegistryTabEmptyIcon": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyIcon--_zrtt`,
	"modelRegistryTabEmptyTitle": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyTitle--Mc4d2`,
	"modelRegistryTabEmptySmallTitle": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptySmallTitle--sjVjT`,
	"modelRegistryTabEmptyText": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyText--T_fHl`,
	"modelRegistryTabEmptyTextLink": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyTextLink--MSqMt`,
	"modelRegistryTabEmptyList": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyList--Cp8m_`,
	"modelRegistryTabEmptyListNumber": `ModelRegistryTabEmptyState-modules__modelRegistryTabEmptyListNumber--sEsiL`
};
module.exports = ___CSS_LOADER_EXPORT___;
