import React from 'react';
import { GRAPHICS_TYPE } from '@experiment-details/constants/graphics';
import { imageToAnnotations } from '@experiment-management-shared';
import { ImageThumbnail } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/ImagePanel/ImageThumbnail';
import { VideoThumbnail } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/VideoPanel/VideoThumbnail';
import {
  ImageAssetData,
  VideoAssetData
} from '@experiment-management-shared/types';

import classNames from './GraphicsTile.module.scss';

type GraphicsTileBodyProps = {
  asset: VideoAssetData | ImageAssetData;
};
const GraphicsTileBody: React.FC<GraphicsTileBodyProps> = ({ asset }) => {
  const { name: assetName, thumbnailPath, path } = asset;

  const imageUrl = thumbnailPath || path;

  if (asset.type === GRAPHICS_TYPE.IMAGE) {
    const imageAsset = asset as ImageAssetData;
    const { originalImageHeight, originalImageWidth } = imageAsset;

    let originalImageSize = null;
    if (originalImageHeight && originalImageWidth) {
      originalImageSize = {
        originalImageWidth,
        originalImageHeight
      };
    }

    if (originalImageSize === null) {
      return (
        <div className={classNames.bodyContainer}>
          <img src={thumbnailPath} alt={assetName} loading="lazy" />
        </div>
      );
    }
    return (
      <div className={classNames.bodyContainer}>
        <ImageThumbnail
          name={assetName}
          src={thumbnailPath}
          metadata={imageToAnnotations(imageAsset)}
          originalImageSize={originalImageSize}
        />
      </div>
    );
  }

  if (asset.type === GRAPHICS_TYPE.VIDEO) {
    return (
      <div className={classNames.bodyContainer}>
        <VideoThumbnail name={assetName} src={imageUrl} />
      </div>
    );
  }

  return null;
};

export default GraphicsTileBody;
