import { get } from 'lodash';
import { useQuery } from 'react-query';
import { GRID_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';

import api, { QueryConfig } from '@shared/api';
import useProject from '@API/project/useProject';

const NAME_COLUMN = {
  source: 'log_other',
  keyName: GRID_COLUMNS.NAME
};

const getExperimentName = async ({
  experimentKeys,
  projectId
}: UseExperimentNameParams): Promise<Array<string>> => {
  const {
    data: { metadata }
  } = await api.post('query/metadata-v2', {
    columns: [NAME_COLUMN],
    experiments: experimentKeys,
    projectId
  });

  return experimentKeys.map((experimentKey, index) =>
    get(
      metadata,
      [index, experimentKey, 'Name', 'values', 0, 'value'],
      experimentKey?.substring(0, 9) || ''
    )
  );
};

interface UseExperimentNameParams {
  experimentKeys: Array<string>;
  projectId?: string;
}

export default function useExperimentName(
  params: UseExperimentNameParams,
  config?: QueryConfig<Array<string>>
) {
  const { experimentKeys } = params;
  const { data: project } = useProject();
  const projectId = params.projectId || project?.projectId;

  return useQuery(
    ['experimentName', { experimentKeys, projectId }],
    () => getExperimentName({ experimentKeys, projectId }),
    {
      enabled: !!projectId && !!experimentKeys?.[0],
      initialData: (experimentKeys || ['']).map(n => n?.substring(0, 9) || ''),
      refetchOnMount: true,
      ...config
    }
  );
}
