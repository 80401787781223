// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionTable-module__freezeDataContainer--dpZL0{align-items:center;display:flex;padding:0 32px}.SectionTable-module__freezeData--r79cU.MuiFormControlLabel-root{margin:0 16px 0 0;padding:0}.SectionTable-module__freezeData--r79cU.MuiFormControlLabel-root .MuiSwitch-switchBase.Mui-checked.Mui-disabled+.MuiSwitch-track{opacity:.35}.SectionTable-module__freezeData--r79cU.MuiFormControlLabel-root .MuiTypography-body1{color:#475062;font-size:15px;font-weight:500}.SectionTable-module__header--olicf{display:flex;flex-direction:column}.SectionTable-module__searchResultSummaryRow--p5EoW{color:#475062}.SectionTable-module__searchResultSummary--oZqOL{display:flex}.SectionTable-module__searchResultSummary--oZqOL span{font-weight:500}.SectionTable-module__buttonsRow--kUlK6{display:flex;align-items:center;justify-content:space-between;padding:8px 0;margin:0 0 0 -8px}.SectionTable-module__hidden--wfKHl{display:none}.SectionTable-module__table--g3S3G .react-grid{border:1px solid #dadada}.SectionTable-module__table--g3S3G .react-grid-footer{align-items:center;display:flex;justify-content:space-between}@media print{.SectionTable-module__freezeDataContainer--dpZL0{visibility:hidden}.SectionTable-module__header--olicf .btn-group{display:none}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"freezeDataContainer": `SectionTable-module__freezeDataContainer--dpZL0`,
	"freezeData": `SectionTable-module__freezeData--r79cU`,
	"header": `SectionTable-module__header--olicf`,
	"searchResultSummaryRow": `SectionTable-module__searchResultSummaryRow--p5EoW`,
	"searchResultSummary": `SectionTable-module__searchResultSummary--oZqOL`,
	"buttonsRow": `SectionTable-module__buttonsRow--kUlK6`,
	"hidden": `SectionTable-module__hidden--wfKHl`,
	"table": `SectionTable-module__table--g3S3G`
};
module.exports = ___CSS_LOADER_EXPORT___;
