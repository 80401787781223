import isEqual from 'lodash/isEqual';

import { cleanTemplateFromNotNeededFields } from '@experiment-details/utils/templates';
import { cleanLayoutToCompare } from '@experiment-management-shared/utils/layout';
import omit from 'lodash/omit';

const cleanPanelsToCompare = panels => {
  return (panels || []).map(p => omit(p, ['chartId']));
};

const cleanTemplateForCompare = template => {
  const { version, panels, ...restTemplate } = cleanTemplateFromNotNeededFields(
    template
  );

  return {
    ...restTemplate,
    panels: {
      ...panels,
      panels: [],
      layout: [],
      sections: (panels?.sections ?? []).map(section => {
        // Here is workaround for views that migrates from charts to panels,
        // it mostly Auto Generated view now
        // Taking into account that we have two sources of templates
        // - /api/chart-templates/project/get
        // - /api/chart-templates/project/get-all
        // we run our migration on it that force to generate new IDs for charts and layouts,
        // so to be able to identify if we have a changes in view we need to get read of IDs in panels and layout

        return {
          ...section,
          id: '',
          panels: cleanPanelsToCompare(section.panels),
          layout: cleanLayoutToCompare(panels.layout, false)
        };
      })
    }
  };
};

const useExperimentViewUnsavedChanges = ({
  userTemplates,
  currentTemplate,
  originalTemplate,
  isLoadingUserTemplates,
  isLoadingProjectTemplate
}) => {
  return (
    !isLoadingUserTemplates &&
    !isLoadingProjectTemplate &&
    userTemplates.length &&
    currentTemplate &&
    originalTemplate &&
    !isEqual(
      cleanTemplateForCompare(currentTemplate),
      cleanTemplateForCompare(originalTemplate)
    )
  );
};

export default useExperimentViewUnsavedChanges;
