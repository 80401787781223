import React from 'react';
import { EmptyExperimentTab } from '@shared';
import { EmptyTabCellProps } from '../shared/ComparisonTable/types';

const OtherParamsTabEmpty = ({
  isComparePage,
  ...cellProps
}: EmptyTabCellProps) => {
  const experimentText = isComparePage
    ? 'These experiments'
    : 'This experiment';
  return (
    <EmptyExperimentTab.Cell {...cellProps}>
      <EmptyExperimentTab>
        <EmptyExperimentTab.Icon iconName="other" />
        <EmptyExperimentTab.Title>
          {experimentText} did not log any custom key/value pairs
        </EmptyExperimentTab.Title>
        <EmptyExperimentTab.Description>
          Use <b>log_other()</b> on your Experiment object to report custom
          key/value pairs during experiments.
        </EmptyExperimentTab.Description>
        <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentlog_other" />
      </EmptyExperimentTab>
    </EmptyExperimentTab.Cell>
  );
};

export default OtherParamsTabEmpty;
