import MUISnackbar from '@material-ui/core/Snackbar';
import React from 'react';
import styles from './Snackbar.module.scss';

const Snackbar = props => {
  return (
    <MUISnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      className={styles.snackbar}
      {...props}
    />
  );
};

export default Snackbar;
