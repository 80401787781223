import { useQuery } from 'react-query';

import useProject from '@API/project/useProject';
import api, { QueryConfig } from '@shared/api';

type GetShareCode = {
  projectId: string;
  signal?: AbortSignal;
};

type ShareCodeAPI = {
  shareCodes: string[];
};

const getShareCode = async ({ projectId, signal }: GetShareCode) => {
  const {
    data: { shareCodes }
  } = await api.get<ShareCodeAPI>('share-project/get-project-share-links', {
    params: {
      projectId
    },
    signal
  });

  return shareCodes[0];
};

export function useShareCode(_params?: unknown, config?: QueryConfig<string>) {
  const { data: project } = useProject();
  const projectId = project?.projectId;
  const canEdit = project?.canEdit ?? false;
  const configEnabled = config?.enabled ?? true;
  const enabled = configEnabled && !!projectId && canEdit;

  return useQuery(
    ['share-code', { projectId }],
    ({ signal }) => {
      if (!projectId) {
        throw new Error('Project ID is empty');
      }

      return getShareCode({ projectId, signal });
    },
    { ...config, enabled }
  );
}
