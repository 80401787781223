import React from 'react';
import cx from 'classnames';

import classes from './ListComponents.module.scss';
import { useListItemContext } from '../useListItemContext';

export interface ListColorProps {
  color: string;
}

const ListColor = ({ color }: ListColorProps) => {
  const { isWithSecondaryText } = useListItemContext();
  return (
    <div
      className={cx(classes.colorContainer, classes.prefixElementContainer, {
        [classes.moreTopColorMargin]: isWithSecondaryText
      })}
      style={{ '--item-color': color } as React.CSSProperties}
    />
  );
};

export default ListColor;
