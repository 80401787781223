import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import get from 'lodash/get';
import toLower from 'lodash/toLower';

import { PanelDocsIcon } from '@Icons-outdated';
import { DOCS_ROOT_URL } from '@/constants/configConstants';
import { DOCS_LINKS } from '@/constants/urlConstants';

const DocsLink = ({ type }) => {
  const { label, path } = get(DOCS_LINKS, type, { label: '', path: '/' });

  return (
    <a
      className={cx('docs-link', toLower(type))}
      href={`${DOCS_ROOT_URL}/${path}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="docs-link-icon">
        <PanelDocsIcon />
      </div>
      <span className="docs-link-span">{label} Docs</span>
    </a>
  );
};

DocsLink.propTypes = {
  type: PropTypes.string.isRequired
};

export default DocsLink;
