import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Select } from '@DesignSystem/controllers';
import { Panel } from '../Panel';
import PanelContent from './PanelContent';
import {
  transformSegmentsToAreaPlotData,
  transformModeToPercentileValues,
  transformCMCustomMetricToPlot
} from '../../utils';
import { useMPMPanelData, useMPMNumericalDistribution } from '../../hooks';
import { useMPMQueryBySection } from '@mpm/api';
import {
  CHART_TYPES,
  FEATURE_TYPES,
  PERCENTILE_OPTION_LIST,
  PANEL_NAMES,
  DRIFT_ALGORITHMS_OPTIONS,
  PANEL_SECTIONS
} from '../../constants';
import './PanelContainer.scss';

export const PanelContainer = ({
  colors,
  badgeLabels,
  from,
  intervalType,
  section: controlledSection,
  tabs,
  title,
  to,
  version,
  disableZoom,
  segmentColorIds,
  isLabeled,
  isSegmentsData,
  propFeatureId,
  feature,
  ...props
}) => {
  const [percentileMode, setPercentileMode] = useState(
    PERCENTILE_OPTION_LIST[0].value
  );
  const [driftAlgorithmType, setDriftAlgorithmType] = useState(
    DRIFT_ALGORITHMS_OPTIONS[0].value
  );

  const [activeTabData, setActiveTabData] = useState(tabs[0] || {});
  const [section, setSection] = useState();
  const [hoverLineData, setHoverLineData] = useState({});
  const [
    checkedPercentCategorySwitch,
    setCheckedPercentCategorySwitch
  ] = useState(false);

  useEffect(() => {
    setSection(controlledSection || tabs[0]?.value);
  }, [tabs, controlledSection]);

  const allowedDriftAlgorithms =
    section === PANEL_SECTIONS.DATA_DRIFT ||
    section === PANEL_SECTIONS.DATA_DRIFT_BY_FEATURE ||
    section === PANEL_SECTIONS.DRIFT;

  const driftAlgorithm = allowedDriftAlgorithms
    ? driftAlgorithmType
    : undefined;

  const { data, isLoading, isFetching, isError } = useMPMPanelData(
    {
      section,
      colors,
      from,
      to,
      intervalType,
      version,
      propFeatureId,
      activeTabData,
      segmentColorIds,
      isLabeled,
      title,
      metricType: activeTabData?.metricType,
      driftAlgorithmType: driftAlgorithm
    },
    {
      enabled: !!from && !!to,
      keepPreviousData: true
    }
  );

  const allowedNumericalDistribution = useMemo(() => {
    const { type } = feature || {};
    return (
      (type === 'numerical' ||
        activeTabData?.valType === FEATURE_TYPES.NUMERICAL) &&
      section === PANEL_SECTIONS.FEATURE_DISTRIBUTION
    );
  }, [feature, section, activeTabData?.valType]);

  const {
    plotData: numericalDistributionData,
    numericalLoading
  } = useMPMNumericalDistribution(
    {
      section: PANEL_SECTIONS.NUMERICAL_DISTRIBUTION,
      from,
      to,
      intervalType,
      version,
      segmentColorIds,
      hoverLineData,
      propFeatureId: activeTabData?.featureId
    },
    {
      enabled: !!from && !!to && allowedNumericalDistribution,
      keepPreviousData: true
    }
  );

  const { data: mpmData, isLoading: mpmDataLoading } = useMPMQueryBySection(
    {
      section: PANEL_SECTIONS.NUMBER_OF_PREDICTIONS,
      from,
      to,
      intervalType,
      version,
      propFeatureId
    },
    {
      enabled:
        !!from &&
        !!to &&
        section === PANEL_SECTIONS.CONFUSION_MATRIX_CUSTOM_METRIC,
      keepPreviousData: true
    }
  );

  let chartType = data?.chartType;
  const layoutData = data?.layoutData;

  const panelData = useMemo(() => {
    // it's used here instead of useMPMPanelData hook
    // to avoid repetitive requests for percentiles
    if (chartType === CHART_TYPES.LINE_WITH_PERCENTILE) {
      return transformSegmentsToAreaPlotData(
        intervalType,
        data?.panelData,
        colors,
        segmentColorIds,
        ...transformModeToPercentileValues(percentileMode)
      );
    }
    if (chartType === CHART_TYPES.CUSTOM_METRIC_CONFUSION_MATRIX) {
      chartType = CHART_TYPES.LINE;
      let evaluatedData;
      try {
        evaluatedData = transformCMCustomMetricToPlot(
          data,
          mpmData,
          activeTabData.definition
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return [];
      }
      return evaluatedData;
    }

    if (chartType === CHART_TYPES.CONFUSION_MATRIX) {
      return data;
    }

    if (!isLabeled && title === PANEL_NAMES.ACCURACY_METRICS) {
      return [];
    }

    const name = tabs.find(tab => tab.value === section)?.label || title;

    return data?.panelData?.map(trace => ({ name, ...trace }));
  }, [
    chartType,
    data,
    mpmData,
    intervalType,
    colors,
    percentileMode,
    section,
    tabs,
    title
  ]);

  const handleChangeTab = tab => {
    setActiveTabData(tab);
    setSection(tab.value);
  };

  const handlePercentCategorySwitch = () => {
    setCheckedPercentCategorySwitch(prevChecked => {
      return !prevChecked;
    });
    if (section === PANEL_SECTIONS.FEATURE_DISTRIBUTION) {
      setSection(PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL);
      return;
    }
    setSection(PANEL_SECTIONS.FEATURE_DISTRIBUTION);
  };

  const renderChart = tabData => {
    if (isLoading || isFetching || mpmDataLoading) {
      return (
        <div>
          {allowedDriftAlgorithms && (
            <div className="labeled-select-container">
              <Select
                label="Drift Metrics"
                options={DRIFT_ALGORITHMS_OPTIONS}
                value={driftAlgorithmType}
                onChange={setDriftAlgorithmType}
                horizontalLabel={true}
                variant="outlined"
              />
            </div>
          )}
          <div className="mpm-chart-loading">
            <span className="primary-label">Loading... </span>
            <span className="secondary-label">Fetching chart data</span>
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="mpm-chart-error">Error on trying to fetch the data</div>
      );
    }

    return (
      <PanelContent
        data={panelData}
        from={from}
        to={to}
        numericalDistributionData={numericalDistributionData}
        numericalLoading={numericalLoading}
        layoutData={layoutData}
        chartType={chartType}
        percentileMode={percentileMode}
        onChangePercentileMode={setPercentileMode}
        disableZoom={disableZoom}
        section={section}
        tabData={tabData}
        segmentColorIds={segmentColorIds}
        panelTitle={title}
        isLabeled={isLabeled}
        setHoverLineData={setHoverLineData}
        allowedNumericalDistribution={allowedNumericalDistribution}
        driftAlgorithm={driftAlgorithm}
        allowedDriftAlgorithms={allowedDriftAlgorithms}
        driftAlgorithmType={driftAlgorithmType}
        setDriftAlgorithmType={setDriftAlgorithmType}
        setSection={setSection}
        checkedPercentCategorySwitch={checkedPercentCategorySwitch}
        handlePercentCategorySwitch={handlePercentCategorySwitch}
        feature={feature}
        intervalType={intervalType}
        {...props}
      />
    );
  };

  return (
    <Panel
      title={title}
      badgeLabels={badgeLabels}
      renderChart={renderChart}
      onChangeTab={handleChangeTab}
      tabs={tabs}
      disableZoom={disableZoom}
      isLabeled={isLabeled}
      isSegmentsData={isSegmentsData}
    />
  );
};

PanelContainer.propTypes = {
  badgeLabels: PropTypes.array,
  colors: PropTypes.array,
  from: PropTypes.string,
  intervalType: PropTypes.string,
  section: PropTypes.string,
  tabs: PropTypes.array,
  title: PropTypes.string,
  to: PropTypes.string,
  version: PropTypes.string,
  disableZoom: PropTypes.bool,
  segmentColorIds: PropTypes.array,
  isLabeled: PropTypes.bool,
  isSegmentsData: PropTypes.bool.isRequired,
  propFeatureId: PropTypes.string,
  feature: PropTypes.object
};

PanelContainer.defaultProps = {
  badgeLabels: [],
  colors: [],
  from: '',
  intervalType: 'daily',
  section: null,
  tabs: [],
  title: '',
  to: '',
  version: null,
  disableZoom: false,
  segmentColorIds: [],
  isLabeled: undefined,
  propFeatureId: '',
  feature: {}
};
