import { useState } from 'react';

const useOverflowRow = ({ onlyVertical = true } = {}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const setRef = ref => {
    if (ref !== null) {
      setIsOverflowing(
        onlyVertical
          ? ref.scrollWidth - 2 > ref.offsetWidth
          : ref.scrollHeight - 2 > ref.offsetHeight
      );
    }
  };

  return {
    isOverflowing,
    setIsOverflowing,
    setRef
  };
};

export default useOverflowRow;
