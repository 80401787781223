import cx from 'classnames';
import { difference, identity, sortBy, uniq } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dashboardChartsActions from '@/actions/dashboardChartsActions';
import { getChartFormByType } from '@/reducers/dashboardChartsReducer';
import { Slider } from '@design-system-outdated/components';
import { Combobox } from '@ds';
import typography from '@ds-typography';
import { useCurveAssets } from '@experiment-management-shared/api';
import { BUILT_IN_CHART_TYPES } from '@experiment-management-shared/constants/chartConstants';
import styles from './CurvesPanelDataTab.module.scss';

const CurvesPanelDataTab = ({
  experimentKeys,
  hiddenExperimentKeys
}: {
  experimentKeys: string[];
  hiddenExperimentKeys: string[];
}) => {
  const dispatch = useDispatch();
  const panelForm = useSelector(state =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    getChartFormByType(state, { chartType: BUILT_IN_CHART_TYPES.curves })
  );

  const visibleExperimentKeys = useMemo(
    () => difference(experimentKeys, hiddenExperimentKeys),
    [experimentKeys, hiddenExperimentKeys]
  );
  const {
    data: { assets, names }
  } = useCurveAssets({
    experimentKeys: visibleExperimentKeys
  });

  const assetSteps = useMemo(() => {
    const allSteps = assets
      .filter(asset => panelForm.assetNames.includes(asset.fileName))
      .map(asset => asset.step);

    return sortBy(uniq(allSteps));
  }, [assets, panelForm.assetNames]);

  useEffect(() => {
    if (panelForm.step !== undefined || assetSteps.length === 0) return;

    dispatch(
      dashboardChartsActions.updateChartFormKey(
        BUILT_IN_CHART_TYPES.curves,
        'step',
        assetSteps[0]
      )
    );
  }, [assetSteps, dispatch, panelForm.step]);

  const assetNameOptions = useMemo(() => {
    return names.map(name => {
      return {
        label: name,
        value: name
      };
    });
  }, [names]);

  const renderAssetNamesSelect = () => {
    return (
      <div className={styles.section}>
        <div className={cx(typography.dsBody, typography.dsAccented)}>
          Curve name
        </div>
        <div className={typography.dsBodySmall}>
          Select the curves you would like to view.
        </div>
        <div>
          <Combobox
            onValueChange={options => {
              dispatch(
                dashboardChartsActions.updateChartFormKey(
                  BUILT_IN_CHART_TYPES.curves,
                  'assetNames',
                  options
                )
              );
            }}
            options={assetNameOptions}
            value={panelForm.assetNames}
          />
        </div>
      </div>
    );
  };

  const renderStepsSlider = () => {
    const marks = assetSteps.map(step => ({
      label: step,
      value: step ?? 0
    }));
    const minStep = assetSteps.length
      ? Math.min(...marks.map(mark => mark.value))
      : 0;
    const maxStep = assetSteps.length
      ? Math.max(...marks.map(mark => mark.value))
      : 0;

    return (
      <div className={styles.section}>
        <div className={cx(typography.dsBody, typography.dsAccented)}>Step</div>
        <div className={styles.slider}>
          <Slider
            max={maxStep}
            min={minStep}
            marks={marks}
            step={null}
            onChange={step => {
              dispatch(
                dashboardChartsActions.updateChartFormKey(
                  BUILT_IN_CHART_TYPES.curves,
                  'step',
                  step
                )
              );
            }}
            sliderSuffixFormat={value => {
              return (
                <span className={styles.sliderSuffix}>
                  {value} / {maxStep}
                </span>
              );
            }}
            value={panelForm.step}
            valueLabelFormat={identity}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.tab}>
      {renderAssetNamesSelect()}
      {renderStepsSlider()}
    </div>
  );
};

export default CurvesPanelDataTab;
