import { useMemo } from 'react';

export const EXPERIMENT_NAME_ROW_HEIGHT = 30;
export const MAX_CELL_HEIGHT = 570;
export const MAX_CELL_WIDTH = 512;
export const NAME_COLUMN_WIDTH = 140;

export function useCellStyles({
  assetsAmount = 0,
  containerHeight = 0,
  targetExperimentsAmount = 0
}: {
  assetsAmount?: number;
  containerHeight?: number;
  targetExperimentsAmount?: number;
}) {
  const [cellWidth, cellHeight] = useMemo(() => {
    if (!containerHeight || !assetsAmount || !targetExperimentsAmount) {
      return [0, 0];
    }
    const aspectRatio = 140 / 116;

    const height = Math.max(
      116,
      Math.min(
        MAX_CELL_HEIGHT,
        (containerHeight - EXPERIMENT_NAME_ROW_HEIGHT) / assetsAmount
      )
    );

    const width = Math.min(MAX_CELL_WIDTH, height * aspectRatio);

    return [Math.floor(width), Math.floor(height)];
  }, [assetsAmount, containerHeight, targetExperimentsAmount]);

  return {
    cellSize: {
      height: cellHeight,
      width: cellWidth
    },
    styles: {
      display: 'grid',
      gridTemplateColumns: `${NAME_COLUMN_WIDTH}px repeat(${targetExperimentsAmount}, ${cellWidth}px)`,
      gridTemplateRows: `${EXPERIMENT_NAME_ROW_HEIGHT}px repeat(${assetsAmount}, ${cellHeight}px)`
    }
  };
}
