// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoThumbnail-module__thumbnail--au7B7{display:flex;align-items:center;justify-content:center;width:100%;position:relative}.VideoThumbnail-module__thumbnail--au7B7 .VideoThumbnail-module__pointer--Heytl{cursor:pointer}.VideoThumbnail-module__thumbnail--au7B7 a{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.VideoThumbnail-module__thumbnail--au7B7 img{width:100%;height:100%;object-fit:cover}.VideoThumbnail-module__noAsset--C1W7i{display:flex;align-items:center;text-align:center;width:88px;height:88px;border:1px solid var(--section-border-color);border-radius:10px;padding:16px;font-size:12px;line-height:18px;color:var(--gray-5);cursor:default}.VideoThumbnail-module__playIcon--UoF0u{width:40px;height:40px;background:rgba(0,0,0,.5);border-radius:50%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:3;cursor:pointer;display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": `VideoThumbnail-module__thumbnail--au7B7`,
	"pointer": `VideoThumbnail-module__pointer--Heytl`,
	"noAsset": `VideoThumbnail-module__noAsset--C1W7i`,
	"playIcon": `VideoThumbnail-module__playIcon--UoF0u`
};
module.exports = ___CSS_LOADER_EXPORT___;
