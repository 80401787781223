import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import groupBy from 'lodash/groupBy';

import cx from 'classnames';
import {
  CONFUSION_MATRIX_CELL_VALUES,
  CONFUSION_MATRIX_COLOR_DISTRIBUTIONS
} from '@experiment-management-shared/constants/chartConstants';
import AssetsMultiSelect from '@experiment-management-shared/components/AssetsMultiSelect/AssetsMultiSelect';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { IAssetData } from '@experiment-management-shared/api/useAssetsForExperiments';
import { InfoOutlinedIcon } from '@Icons-outdated';
import {
  DS_SELECT_HOVER_TYPES,
  DS_SELECT_TYPES
} from '@DesignSystem/controllers/Select/Select';
import { StyledTooltip } from '@DesignSystem/data-display';
import { Select } from '@DesignSystem/controllers';

import styles from './ConfusionMatrixBar.module.scss';

const CELL_VALUE_OPTIONS = Object.values(
  CONFUSION_MATRIX_CELL_VALUES
).map(value => ({ label: value, value }));

const COLOR_DISTRIBUTION_VALUE_OPTIONS = Object.values(
  CONFUSION_MATRIX_COLOR_DISTRIBUTIONS
).map(value => ({ label: value, value }));

const ConfusionMatrixBar = ({
  experiments,
  assetSummaries,
  assetsIds,
  cellValue,
  colorDistribution,
  onChangeAssets,
  onChangeCellValue,
  onChangeColorDistributionValue,
  isTabEmpty
}) => {
  const options = useMemo(() => {
    const retVal = [];

    experiments.forEach(experiment => {
      const assets = [];

      const experimentAssets = groupBy(
        assetSummaries.filter(
          a => a.experimentKey === experiment.experimentKey
        ),
        'fileName'
      );

      Object.keys(experimentAssets)
        .sort()
        .forEach(groupKey => {
          assets.push({
            title: groupKey,
            values: experimentAssets[groupKey]
              .sort((assetA, assetB) => assetA.step - assetB.step)
              .map(a => a.assetId)
          });
        });

      retVal.push({
        experiment,
        assets
      });
    });

    return retVal;
  }, [experiments, assetSummaries]);

  const renderTooltip = () => {
    return (
      <StyledTooltip
        title="Equal distribution divides the range into 6 equal buckets each with their own color. Smart distribution ensures that colors are more evenly distributed between cells as the range of values gets bigger"
        placement="top"
      >
        <span className={styles.infoIconContainer}>
          <InfoOutlinedIcon />
        </span>
      </StyledTooltip>
    );
  };

  return (
    <div className={styles.confusionMatrixBar}>
      <AssetsMultiSelect
        label="Confusion matrices"
        popoverLabel="Selected confusion matrices"
        emptySectionMessage="No confusion matrices were logged for this experiment"
        options={options}
        values={assetsIds}
        onChange={onChangeAssets}
        disabled={isTabEmpty}
      />
      <div className={cx(styles.selectContainer, styles.cellSelect)}>
        <Select
          variant={DS_SELECT_TYPES.OUTLINED}
          label="Cell value"
          data-test="matrix-cell-select"
          onChange={onChangeCellValue}
          options={CELL_VALUE_OPTIONS}
          value={cellValue}
          hoverType={DS_SELECT_HOVER_TYPES.MENU}
          disabled={isTabEmpty}
        />
      </div>
      <div className={cx(styles.selectContainer, styles.distributionSelect)}>
        <Select
          variant={DS_SELECT_TYPES.OUTLINED}
          label="Color distribution"
          data-test="matrix-color-select"
          onChange={onChangeColorDistributionValue}
          options={COLOR_DISTRIBUTION_VALUE_OPTIONS}
          value={colorDistribution}
          hoverType={DS_SELECT_HOVER_TYPES.MENU}
          withTooltip
          renderTooltip={renderTooltip}
          disabled={isTabEmpty}
        />
      </div>
    </div>
  );
};

ConfusionMatrixBar.defaultProps = {
  onChangeAssets: noop,
  onChangeCellValue: noop,
  onChangeColorDistributionValue: noop
};

ConfusionMatrixBar.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  assetSummaries: PropTypes.arrayOf(IAssetData).isRequired,
  assetsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorDistribution: PropTypes.oneOf(
    Object.values(CONFUSION_MATRIX_COLOR_DISTRIBUTIONS)
  ).isRequired,
  cellValue: PropTypes.oneOf(Object.values(CONFUSION_MATRIX_CELL_VALUES))
    .isRequired,
  onChangeAssets: PropTypes.func,
  onChangeCellValue: PropTypes.func,
  onChangeColorDistributionValue: PropTypes.func
};

export default ConfusionMatrixBar;
