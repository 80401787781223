import classNames from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import ThrottlingPopover from '@experiment-management-shared/components/ThrottlingPopover';
import { MIN_COLUMN_NAME_WIDTH } from '@experiment-management-shared/utils/EMView';
import BaseGrid from './BaseGrid';

const ReactGrid = (
  {
    id,
    isBackgroundFetchingExperiments,
    selectedExperiments,
    reactGridClass,
    groupAggregations,
    ...reactGridProps
  },
  ref
) => {
  const { isPreviousDataExperiments, rows } = reactGridProps;

  const className = classNames('react-grid', ...reactGridClass);

  return (
    <div
      className="react-grid-container"
      id={id}
      data-test={reactGridProps['data-test']}
    >
      <div className={className}>
        {isBackgroundFetchingExperiments && isPreviousDataExperiments && (
          <div className="react-grid-background-fetching" />
        )}

        <ThrottlingPopover rows={rows} />
        <BaseGrid
          groupAggregations={groupAggregations}
          {...reactGridProps}
          ref={ref}
        />
      </div>
    </div>
  );
};

const ReactGridRef = forwardRef(ReactGrid);

ReactGridRef.defaultProps = {
  allowColumnReordering: false,
  allowFiltering: false,
  allowPagination: false,
  allowSelection: false,
  allowSorting: false,
  availableColumns: null,
  canHideDetails: false,
  columns: [],
  columnsActions: [],
  emptyMessage: null,
  id: null,
  hasRowDetail: false,
  hideColumnsHeader: false,
  reactGridClass: [],
  integratedSortingColumnExtensions: [],
  isArchive: false,
  isBackgroundFetchingExperiments: false,
  isPreviousDataExperiments: false,
  exportHandler: noop,
  resizingEnabled: false,
  renderNoDataCell: null,
  selectedExperiments: [],
  rows: [],
  rowActionMenu: {
    isVisible: false,
    isOpened: noop,
    onClick: noop
  },
  shouldUseServerSidePagination: false,
  totalExperiments: 0,
  fixedColumnMaxWidth: MIN_COLUMN_NAME_WIDTH,
  groupAggregations: null,
  rightColumns: [],
  headerRowComponents: {}
};

ReactGridRef.propTypes = {
  allowColumnReordering: PropTypes.bool,
  availableColumns: PropTypes.array,
  allowFiltering: PropTypes.bool,
  allowPagination: PropTypes.bool,
  allowSelection: PropTypes.bool,
  allowSorting: PropTypes.bool,
  canHideDetails: PropTypes.bool,
  columns: PropTypes.array,
  contextAdditionalData: PropTypes.object,
  columnsActions: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      action: PropTypes.func
    })
  ),
  exportHandler: PropTypes.func,
  emptyMessage: PropTypes.string,
  id: PropTypes.string,
  fixedColumnMaxWidth: PropTypes.number,
  hasRowDetail: PropTypes.bool,
  hideColumnsHeader: PropTypes.bool,
  integratedSortingColumnExtensions: PropTypes.arrayOf({
    columnName: PropTypes.string,
    compare: PropTypes.func
  }),
  isArchive: PropTypes.bool,
  isBackgroundFetchingExperiments: PropTypes.bool,
  isPreviousDataExperiments: PropTypes.bool,
  reactGridClass: PropTypes.array,
  reactGrid: PropTypes.shape({
    columnGrouping: PropTypes.array,
    columnOrders: PropTypes.array,
    columnSorting: PropTypes.array,
    columnWidths: PropTypes.array,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    selection: PropTypes.array
  }).isRequired,
  rowActionMenu: PropTypes.shape({
    isVisible: PropTypes.bool,
    isOpened: PropTypes.func,
    onClick: PropTypes.func
  }),
  resizingEnabled: PropTypes.bool,
  renderNoDataCell: PropTypes.func,
  selectedExperiments: PropTypes.array,
  shouldUseServerSidePagination: PropTypes.bool,
  rows: PropTypes.array,
  totalExperiments: PropTypes.number,
  groupAggregations: PropTypes.object,
  rightColumns: PropTypes.arrayOf(PropTypes.string),
  headerRowComponents: PropTypes.objectOf(PropTypes.elementType)
};

export default ReactGridRef;
