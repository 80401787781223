import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/browser';

import { IS_ONLINE_MODE } from '@/constants/configConstants';

import ErrorPage from '@platform/components/ErrorPage';
import projectsActions from '@/actions/projectsActions';

class SentryBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };

    this.handleClearError = this.handleClearError.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (IS_ONLINE_MODE) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
        Sentry.showReportDialog();
      });
    }
  }

  handleClearError() {
    const { dispatch, history } = this.props;

    this.setState({ error: null });
    dispatch(projectsActions.clearErrors());
    history.push('/');
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) return children;

    return (
      <ErrorPage
        hasContinueBtn
        isDefaultError
        handleError={this.handleClearError}
      />
    );
  }
}

SentryBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(connect()(SentryBoundary));
