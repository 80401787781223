// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TreeTable-module__expandIconContainer--iVXJJ{width:14px;cursor:pointer}.TreeTable-module__expandIconContainer--iVXJJ svg{color:var(--primary-color);margin-right:8px}.TreeTable-module__expandIconContainer--iVXJJ .TreeTable-module__rotatedIcon--dNvLQ{transform:rotate(90deg)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandIconContainer": `TreeTable-module__expandIconContainer--iVXJJ`,
	"rotatedIcon": `TreeTable-module__rotatedIcon--dNvLQ`
};
module.exports = ___CSS_LOADER_EXPORT___;
