import React from 'react';
import moment from 'moment';
import { DSCheckMarkIcon, DSCloseIcon } from '@ds-icons';
import { IconButton, Tooltip } from '@ds';
import WebhookResponsePopover from './WebhookResponsePopover';
import { WebhookNotifierResponse, WebhookStatus } from '@model-registry/types';
import classNames from './ModelRegistryWebhooksStatusCell.module.scss';
import cx from 'classnames';
import { Box } from '@material-ui/core';

interface Props {
  row: {
    lastResponse?: WebhookNotifierResponse;
    url: string;
  };
}

const getTooltipText = (webhookResponse?: WebhookNotifierResponse): string => {
  if (!webhookResponse) {
    return "This webhook hasn't been triggered yet";
  }

  const { status, httpResponse } = webhookResponse;

  const date = moment(httpResponse?.headers.Date).format('MM/DD/YYYY hh:mm A');

  const statusCode = httpResponse?.statusCode;

  switch (status) {
    case WebhookStatus.INTERNAL_ERROR:
      return `Last delivery failed with an internal error.\nClick for more details`;
    case WebhookStatus.HTTP_ERROR:
      return `Last delivery on ${date} failed with status code ${statusCode}.\nClick for more details`;
    default:
      return `Last delivery on ${date} was successful with status code ${statusCode}.\nClick for more details`;
  }
};

const getIconByStatus = (status?: WebhookStatus) => {
  if (status === WebhookStatus.SUCCESS) {
    return (
      <div
        className={classNames.statusIconWrapper}
        data-test={`model-webhooks-status-icon-active`}
      >
        <DSCheckMarkIcon />
      </div>
    );
  }

  if (
    status === WebhookStatus.INTERNAL_ERROR ||
    status === WebhookStatus.HTTP_ERROR
  ) {
    return (
      <div
        className={cx(classNames.statusIconWrapper, classNames.error)}
        data-test={`model-webhooks-status-error`}
      >
        <DSCloseIcon />
      </div>
    );
  }

  return (
    <div className={classNames.modelRegistryWebhooksTableNonActiveStatus} />
  );
};

const ModelRegistryWebhooksStatusCell: React.FC<Props> = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const lastResponse = row.lastResponse;

  return (
    <div className={classNames.modelRegistryWebhooksStatusCellContainer}>
      <Tooltip
        placement="top"
        content={
          <Box whiteSpace="pre-wrap">{getTooltipText(lastResponse)}</Box>
        }
      >
        <div data-test={`model-webhooks-status`}>
          <div className={classNames.modelRegistryWebhooksStatusIconButton}>
            <IconButton
              onClick={el => setAnchorEl(el.currentTarget)}
              type="secondary"
              Icon={getIconByStatus(lastResponse?.status)}
              active={!!anchorEl}
              disabled={!lastResponse}
            />
          </div>
        </div>
      </Tooltip>
      {lastResponse && (
        <WebhookResponsePopover
          response={lastResponse}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 10,
            horizontal: -8
          }}
          anchorEl={anchorEl}
          url={row.url}
          onClose={() => setAnchorEl(null)}
          hideStatusIcon
        />
      )}
    </div>
  );
};

export default ModelRegistryWebhooksStatusCell;
