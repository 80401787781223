import { useQuery } from 'react-query';

import api from '@API/api';
import { useActiveWorkspace } from '@shared/hooks';

import { QueryConfig } from '@shared/api';
import { FeatureType } from '@mpm/types';

type FeaturesParams = {
  modelId: string;
  page: number;
  pageSize: number;
  sortColumn?: string;
  order?: string;
  predicates?: [] | Array<unknown>;
};

type FeaturesResponse = {
  features: FeatureType[];
  total: number;
};

const getFeatures = ({
  modelId,
  workspaceId,
  ...restParams
}: FeaturesParams & { workspaceId: string }) =>
  api.post(`/mpm/${workspaceId}/models/${modelId}/features/search`, restParams);

export function useFeatures(
  params: FeaturesParams,
  config: QueryConfig<FeaturesResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['model-features', { workspaceId: activeWorkspace?.id, ...params }],
    () => getFeatures({ workspaceId: activeWorkspace?.id, ...params }),
    {
      ...config,
      enabled: !!activeWorkspace?.id
    }
  );
}
