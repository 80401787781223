import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

const SingleAction = ({
  title,
  subtitle,
  icon,
  clickHandler,
  handleMenuClose,
  row
}) => {
  const clickBtnHandler = () => {
    clickHandler(row);
    handleMenuClose();
  };

  return (
    <Box
      onClick={clickBtnHandler}
      className="actions-menu-item"
      data-test={`${title.toLowerCase()}-action`}
    >
      {icon && <span className="actions-menu-icon">{icon}</span>}
      <div className="actions-menu-text">
        <p className="actions-menu-title">{title}</p>
        {subtitle && <p className="actions-menu-subtitle">{subtitle}</p>}
      </div>
    </Box>
  );
};

SingleAction.defaultProps = {
  subtitle: '',
  icon: null
};

SingleAction.propTypes = {
  title: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  row: PropTypes.object.isRequired
};

export default SingleAction;
