import React from 'react';

import { SignInSSOModal } from '../SignInSSOModal';

const SignInSSOPage = () => {
  return (
    <div className="full-page-view">
      <SignInSSOModal shouldKeepLocation={false} />
    </div>
  );
};

export default SignInSSOPage;
