import keymirror from 'keymirror';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import find from 'lodash/find';
import toUpper from 'lodash/toUpper';

import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import CodeIcon from '@material-ui/icons/Code';
import ImageIcon from '@material-ui/icons/Image';
import SubjectIcon from '@material-ui/icons/Subject';
import VideocamIcon from '@material-ui/icons/Videocam';
import ViewListIcon from '@material-ui/icons/ViewList';

import {
  AUDIO_EXTENSIONS,
  FILE_EXTENSIONS,
  getFileExtension,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS
} from '@experiment-management-shared/utils/filesTreeUtils';
import SourceCodePreview from '@experiment-management-shared/components/AssetsPreview/SourceCodePreview';
import AudioPreview from '@experiment-management-shared/components/AssetsPreview/AudioPreview';
import CSVPreview from '@experiment-management-shared/components/AssetsPreview/CSVPreview';
import ImagePreview from '@experiment-management-shared/components/AssetsPreview/ImagePreview';
import TextPreview from '@experiment-management-shared/components/AssetsPreview/TextPreview';
import VideoPreview from '@experiment-management-shared/components/AssetsPreview/VideoPreview';
import MarkdownPreview from '@experiment-management-shared/components/AssetsPreview/MarkdownPreview';
import MarkdownSVG from '@experiment-management-shared/components/AssetsPreview/MarkdownSVG';

export const BACKEND_ASSET_TYPES = keymirror({
  all: null,
  unknown: null,
  audio: null,
  video: null,
  image: null,
  histogram3d: null,
  histogram2d: null,
  histogram_combined_3d: null,
  'confusion-matrix': null,
  'text-sample': null,
  'model-element': null,
  curve: null,
  notebook: null,
  embeddings: null,
  '3d-points': null,
  dataframe: null,
  'dataframe-profile': null,
  source_code: null,
  'tensorflow-model-graph-text': null,
  '3d-image': null
});

const CODE_EXTENSIONS_WITH_PREVIEW = {
  html: { label: 'HTML', mode: 'htmlmixed' },
  js: { label: 'JavaScript', mode: 'javascript' },
  json: { label: 'JSON', mode: 'json' },
  py: { label: 'Python', mode: 'python' },
  R: { label: 'R', mode: 'r' }
};

export const ASSET_PREVIEW_CONFIGS = {
  audio: {
    extensions: AUDIO_EXTENSIONS,
    backendType: BACKEND_ASSET_TYPES.audio,
    PreviewComponent: AudioPreview,
    Icon: AudiotrackIcon,
    label: 'Audio'
  },
  code: {
    extensions: Object.keys(CODE_EXTENSIONS_WITH_PREVIEW),
    backendType: BACKEND_ASSET_TYPES.source_code,
    PreviewComponent: SourceCodePreview,
    Icon: CodeIcon
  },
  csv: {
    extensions: ['csv', 'tsv'],
    PreviewComponent: CSVPreview,
    Icon: ViewListIcon
  },
  image: {
    extensions: IMAGE_EXTENSIONS,
    backendType: BACKEND_ASSET_TYPES.image,
    PreviewComponent: ImagePreview,
    Icon: ImageIcon,
    label: 'Image'
  },
  markdown: {
    extensions: [FILE_EXTENSIONS.md],
    backendType: null,
    PreviewComponent: MarkdownPreview,
    Icon: MarkdownSVG,
    label: 'Markdown'
  },
  text: {
    extensions: [FILE_EXTENSIONS.txt],
    backendType: BACKEND_ASSET_TYPES['text-sample'],
    PreviewComponent: TextPreview,
    Icon: SubjectIcon,
    label: 'Text'
  },
  video: {
    extensions: VIDEO_EXTENSIONS,
    backendType: BACKEND_ASSET_TYPES.video,
    PreviewComponent: VideoPreview,
    Icon: VideocamIcon,
    label: 'Video'
  },
  general: {
    extensions: [],
    PreviewComponent: TextPreview,
    Icon: ViewListIcon
  }
};

const hasExtension = (typeExtensions, fileExtensions) => {
  return typeExtensions.some(typeExtension =>
    fileExtensions.includes(typeExtension)
  );
};

export const getAssetPreviewConfig = ({ fileName, fileSize, link, type }) => {
  const assetExtensions = [getFileExtension(link), getFileExtension(fileName)];

  // Currently there is no way to know if the file is a binary or not
  if (type === BACKEND_ASSET_TYPES['3d-image']) {
    return null;
  }

  if (
    hasExtension(ASSET_PREVIEW_CONFIGS.text.extensions, assetExtensions) ||
    ASSET_PREVIEW_CONFIGS.text.backendType === type
  ) {
    return {
      ...ASSET_PREVIEW_CONFIGS.text,
      optionalProps: { fileName, fileSize }
    };
  }

  if (
    hasExtension(ASSET_PREVIEW_CONFIGS.markdown.extensions, assetExtensions)
  ) {
    return {
      ...ASSET_PREVIEW_CONFIGS.markdown,
      optionalProps: { fileName, fileSize }
    };
  }

  if (hasExtension(ASSET_PREVIEW_CONFIGS.csv.extensions, assetExtensions)) {
    const label = toUpper(
      find(assetExtensions, ext =>
        ASSET_PREVIEW_CONFIGS.csv.extensions.includes(ext)
      )
    );
    return { ...ASSET_PREVIEW_CONFIGS.csv, label, optionalProps: { fileSize } };
  }

  if (
    hasExtension(ASSET_PREVIEW_CONFIGS.audio.extensions, assetExtensions) ||
    ASSET_PREVIEW_CONFIGS.audio.backendType === type
  ) {
    return ASSET_PREVIEW_CONFIGS.audio;
  }

  if (
    hasExtension(ASSET_PREVIEW_CONFIGS.video.extensions, assetExtensions) ||
    ASSET_PREVIEW_CONFIGS.video.backendType === type
  ) {
    return {
      ...ASSET_PREVIEW_CONFIGS.video,
      optionalProps: { fileName }
    };
  }

  if (
    hasExtension(ASSET_PREVIEW_CONFIGS.image.extensions, assetExtensions) ||
    ASSET_PREVIEW_CONFIGS.image.backendType === type
  ) {
    return ASSET_PREVIEW_CONFIGS.image;
  }

  if (
    hasExtension(ASSET_PREVIEW_CONFIGS.code.extensions, assetExtensions) ||
    ASSET_PREVIEW_CONFIGS.code.backendType === type
  ) {
    const { label = 'code', mode = '' } = get(
      CODE_EXTENSIONS_WITH_PREVIEW,
      getFileExtension(fileName),
      {}
    );

    return {
      ...ASSET_PREVIEW_CONFIGS.code,
      label,
      optionalProps: { mode }
    };
  }

  return {
    ...ASSET_PREVIEW_CONFIGS.general,
    label: assetExtensions?.[0]?.toUpperCase()
  };
};

export const isRemoteFile = ({ fileSize }) => {
  return fileSize === 0;
};

export const isAssetWithPreview = (file, isArtifacts = false) => {
  if (!file) return false;

  return !isNull(getAssetPreviewConfig(file, isArtifacts));
};
