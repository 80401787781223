import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

import './ConfusionMatrix.scss';
import { abbreviateNumber } from '@shared/utils/formatNumber';

export const MatrixCell = ({
  cellValue,
  color,
  backgroundColor,
  isHighlighted,
  isPercentage,
  cellSize,
  cellFontSize,
  cellCoordinates,
  setMousePosition,
  clickCellHandler
}) => {
  const { x, y } = cellCoordinates;
  return (
    <div
      onMouseLeave={() => setMousePosition(null)}
      onMouseOver={() => setMousePosition({ x, y })}
      onClick={() => clickCellHandler({ x, y })}
      className={cx('confusion-matrix-chart-cell', {
        'confusion-matrix-chart-cell-highlighted': isHighlighted
      })}
      style={{
        backgroundColor,
        color,
        fontSize: cellFontSize, // 12
        height: cellSize, // 40
        width: cellSize // 40
      }}
    >
      <div className="confusion-matrix-chart-cell-content">
        {abbreviateNumber(cellValue, isPercentage ? 4 : 3)}
      </div>
    </div>
  );
};

MatrixCell.defaultProps = {
  clickCellHandler: noop
};

MatrixCell.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  cellValue: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isPercentage: PropTypes.bool.isRequired,
  cellCoordinates: PropTypes.object.isRequired,
  cellSize: PropTypes.number.isRequired,
  cellFontSize: PropTypes.number.isRequired,
  setMousePosition: PropTypes.func.isRequired,
  clickCellHandler: PropTypes.func
};
