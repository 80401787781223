import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import alertsUtil from '@/util/alertsUtil';
import { useAppStripe } from '../../hooks';
import { snackbarTypes } from '@/constants/alertTypes';
import billingApi from '@/util/billingApi';

const useUpdateOrganizationPaymentMutation = organizationId => {
  const queryClient = useQueryClient();
  const { getToken } = useAppStripe();
  const dispatch = useDispatch();

  return useMutation(
    async ({ address, city, state, postalCode, country }) => {
      const { token, error } = await getToken({
        address,
        city,
        state,
        postalCode,
        country
      });

      if (error) {
        throw error;
      }

      return billingApi.updatePayment(organizationId, token.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'organizations',
          organizationId,
          'billing'
        ]);

        queryClient.invalidateQueries(['organizations', 'list']);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_PAYMENT_CHANGED,
            'Payment has been changed'
          )
        );
      },
      onError: e => {
        dispatch(
          alertsUtil.openErrorDialog(
            snackbarTypes.ERROR_PAYMENT_CHANGED,
            e.message
          )
        );
      }
    }
  );
};

export default useUpdateOrganizationPaymentMutation;
