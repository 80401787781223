import { useExperimentImages } from '@experiment-details/api/index';
import useExperimentVideos from '@experiment-details/api/useExperimentVideos';
import useBetaFeatureEnabled from '@shared/hooks/useBetaFeatureEnabled';
import { BETA_FEATURE_VIDEO_PANEL } from '@/lib/betaFeatures';
import { useMemo } from 'react';
import { mapItemsToGraphicsAssets } from '@experiment-details/utils/graphics';
import { GRAPHICS_ASSETS_TYPES } from '@experiment-details/constants/graphics';

export default function useExperimentGraphics(
  { experimentKey, isActive },
  config
) {
  const isVideoPanelEnabled = useBetaFeatureEnabled(BETA_FEATURE_VIDEO_PANEL);
  const configEnabled = config?.enabled ?? true;

  const { data: images, isLoading: isLoadingImages } = useExperimentImages(
    {
      experimentKey,
      isActive
    },
    {
      ...config,
      enabled: configEnabled
    }
  );
  const { data: videos, isLoading: isLoadingVideos } = useExperimentVideos(
    {
      experimentKey,
      isActive
    },
    {
      enabled: configEnabled && isVideoPanelEnabled
    }
  );
  const isLoading = isLoadingImages || isLoadingVideos;

  const graphicAssets = useMemo(
    () => [
      ...(images?.map(image =>
        mapItemsToGraphicsAssets(image, GRAPHICS_ASSETS_TYPES.image)
      ) || []),
      ...((isVideoPanelEnabled &&
        videos?.map(image =>
          mapItemsToGraphicsAssets(image, GRAPHICS_ASSETS_TYPES.video)
        )) ||
        [])
    ],
    [images, videos, isVideoPanelEnabled]
  );

  return { data: graphicAssets, isLoading };
}
