// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicsVirtualizedGrid-module__hiddenFlexItem--ipFnw{content:"";flex:1 0 280px;padding:5px;margin-top:10px;margin-right:5px;visibility:hidden}.GraphicsVirtualizedGrid-module__row--HnKdu{width:100%;height:100%;display:flex;flex-direction:row;justify-content:center;align-items:center;padding:5px var(--experiment-details-layout-padding);gap:10px}.GraphicsVirtualizedGrid-module__listContainer--O0GAS{padding-top:10px;padding-bottom:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hiddenFlexItem": `GraphicsVirtualizedGrid-module__hiddenFlexItem--ipFnw`,
	"row": `GraphicsVirtualizedGrid-module__row--HnKdu`,
	"listContainer": `GraphicsVirtualizedGrid-module__listContainer--O0GAS`
};
module.exports = ___CSS_LOADER_EXPORT___;
