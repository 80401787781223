import React from 'react';
import { useExperimentsOthers } from '@experiment-details/api';
import { DETAIL_VIEWS } from '@experiment-management-shared/constants/experimentConstants';
import { ExperimentDetails } from '@shared/types';
import ComparisonTable from '../shared/ComparisonTable/ComparisonTable';
import { SmallLoader } from '@shared';
import OtherParamsTabEmpty from './OtherParamsTabEmpty';

type OtherParamsCompareTabProps = {
  experiments: ExperimentDetails[];
};
const OtherParamsCompareTab: React.FC<OtherParamsCompareTabProps> = ({
  experiments
}) => {
  const { data, isLoading } = useExperimentsOthers({
    experiments
  });

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  return (
    <ComparisonTable
      experiments={experiments}
      detailView={DETAIL_VIEWS.OTHERPARAMS}
      experimentsData={data}
      isLoading={isLoading}
      valueKeys={['valueCurrent']}
      emptyView={OtherParamsTabEmpty}
    />
  );
};

export default OtherParamsCompareTab;
