import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './StringLinkSamples.module.scss';

const StringSamples = ({ samples, onCopyClick }) => {
  const list = samples.map(({ index, sample }) => {
    const key = `${sample}-${index}`;

    return (
      <Tooltip
        key={key}
        mouseLeaveDelay={0}
        overlay={
          <div className="confusion-matrix-chart-tooltip-container">
            <div className="confusion-matrix-chart-tooltip-wrappable-label">
              {sample}
            </div>
            <div className="confusion-matrix-chart-tooltip-divider" />
            <div className="confusion-matrix-chart-tooltip-text">
              Click value to copy
            </div>
          </div>
        }
        overlayClassName="confusion-matrix-chart-tooltip"
        placement="top"
      >
        <CopyToClipboard text={sample}>
          <div className={styles.sampleString} onClick={onCopyClick}>
            #{index}: {sample}
          </div>
        </CopyToClipboard>
      </Tooltip>
    );
  });

  return <div className={styles.stringSamplesList}>{list}</div>;
};

StringSamples.propTypes = {
  onCopyClick: PropTypes.func.isRequired,
  samples: PropTypes.array.isRequired
};

export default StringSamples;
