import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import noop from 'lodash/noop';

import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import FullWidthSidebarTransition from './FullWidthSidebarTransition';
import classNames from './FullWidthSidebar.module.scss';

const FullWidthSidebar = ({
  dataTest,
  disableBackdropClick,
  title,
  titleText,
  isOpen,
  onClose,
  secondaryButton,
  primaryButton,
  renderTitleContent,
  renderContent,
  classes
}) => {
  const containerRef = useRef();

  return (
    <Dialog
      data-test={dataTest}
      className={cx(classNames.dsFullWidthSidebar, classes?.dialog)}
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={FullWidthSidebarTransition}
      disableBackdropClick={disableBackdropClick}
    >
      <div
        className={cx(classNames.dsFullWidthSidebarHeader, classes?.header)}
        data-test={`${dataTest}-header`}
      >
        <Box
          className={cx(classNames.dsFullWidthSidebarTitle, classes?.title)}
          data-test={`${dataTest}-title`}
        >
          {title}
        </Box>
        {titleText && (
          <Box
            className={classNames.dsFullWidthSidebarTitleText}
            data-test={`${dataTest}-subtitle`}
          >
            {titleText}
          </Box>
        )}
        {renderTitleContent()}
      </div>
      <div ref={containerRef} className={classNames.dsFullWidthSidebarContent}>
        {renderContent(containerRef)}
      </div>
      <div
        className={cx(classNames.dsFullWidthSidebarActions, classes?.actions)}
      >
        {secondaryButton}
        {primaryButton}
      </div>
    </Dialog>
  );
};

FullWidthSidebar.defaultProps = {
  disableBackdropClick: false,
  isOpen: false,
  onClose: noop,
  secondaryButton: null,
  primaryButton: null,
  renderContent: noop,
  renderTitleContent: noop,
  titleText: null,
  title: 'Sidebar',
  classes: null,
  dataTest: 'full-width-sidebar'
};

FullWidthSidebar.propTypes = {
  dataTest: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  titleText: PropTypes.string,
  onClose: PropTypes.func,
  secondaryButton: PropTypes.node,
  classes: PropTypes.shape({
    actions: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.string,
    dialog: PropTypes.string
  }),
  primaryButton: PropTypes.node,
  renderContent: PropTypes.func,
  renderTitleContent: PropTypes.func
};

export default FullWidthSidebar;
