import { createSelector } from 'reselect';
import get from 'lodash/get';
import { betaFeaturesActionTypes } from '../constants/actionTypes';

const initialState = {
  features: []
};

const betaFeaturesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === betaFeaturesActionTypes.SET_FEATURE_TOGGLES) {
    const { features } = payload;
    return {
      ...state,
      features
    };
  }
  return state;
};

export const getBetaFeatures = state => state.betaFeatures?.features;
const getFeatureName = (_, props) => props.featureName;

export const isBetaFeatureEnabled = createSelector(
  [getBetaFeatures, getFeatureName],
  (features, featureName) => {
    const feature = features.find(feature => feature.name === featureName);

    return get(feature, 'enabled', false);
  }
);

export default betaFeaturesReducer;
