import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Popover from '@material-ui/core/Popover';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory, RouteComponentProps } from 'react-router';

import { Input } from '@DesignSystem/controllers';
import { TextButton, Button } from '@ds';

import {
  SearchIcon,
  EmptySegmentsIcon,
  CrossIcon,
  NoSearchResultsIcon
} from '@Icons-outdated';
import { chartColors } from '@design-system-outdated/constants';
import classNames from './SegmentsPopover.module.scss';
import { SingleSegment } from './SingleSegment';
import { SelectedSegment } from './SelectedSegment';
import { useSegments } from '../../api';
import { getHashedColor } from '../../utils';
import {
  DEFAULT_SEGMENTS_ID,
  DEFAULT_SEGMENTS_LINE_COLOR,
  DEFAULT_SEGMENT_VALUE,
  DEFAULT_MAX_SELECTED_SEGMENT_VALUES
} from '../../constants';

type MatchParams = {
  modelId: string;
  workspace: string;
};

type GroupSegment = {
  color: string;
  id: string;
  segmentValue: string;
};

type SelectedSegment = {
  color: string;
  groupId: string;
  id: string;
  segmentValue: string;
  title: string;
};

type SegmentsPopoverProps = {
  activeSegmentGroupColorIds: GroupSegment[];
  anchorEl: HTMLElement | null;
  counterHandler: (count: number | undefined) => void;
  match: RouteComponentProps<MatchParams> & { url: string };
  setActiveSegmentGroupColorIds: (
    ids: { id: string; segmentValue: string; color: string }[]
  ) => void;
  setAnchorEl: (element: HTMLElement | null) => void;
};

type Value = {
  id: string;
  segmentValue: string;
  checked: boolean;
  color: string;
  name?: string;
};

type PanelItem = {
  expanded: boolean;
  groupId: string;
  title: string;
  values: Value[];
};

export const SegmentsPopover = ({
  activeSegmentGroupColorIds,
  anchorEl,
  counterHandler,
  match,
  setActiveSegmentGroupColorIds,
  setAnchorEl
}: SegmentsPopoverProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [segmentsState, setSegmentsState] = useState<PanelItem[]>([]);
  const [appliedSegmentsState, setAppliedSegmentsState] = useState<PanelItem[]>(
    []
  );
  const [filteredSegmentsState, setFilteredSegmentsState] = useState<
    PanelItem[]
  >([]);
  const [expandedAll, setExpandedAll] = useState<boolean>(false);

  const filteringMode = searchValue.length > 0;
  const history = useHistory();

  const { modelId } = useParams<{ modelId: string }>();
  const { data: { segmentDefinitions } = {} } = useSegments(
    {
      modelId
    },
    { keepPreviousData: true }
  );
  const hasSegments = !!(segmentDefinitions && segmentDefinitions?.length > 0);

  useEffect(() => {
    const lineColors = Object.values(chartColors);
    const checkedIds = activeSegmentGroupColorIds.map(value => value.id);
    const panelState = [
      {
        groupId: DEFAULT_SEGMENTS_ID,
        title: 'Default',
        values: [
          {
            id: DEFAULT_SEGMENTS_ID,
            segmentValue: DEFAULT_SEGMENT_VALUE,
            checked: checkedIds.includes(DEFAULT_SEGMENTS_ID),
            color: DEFAULT_SEGMENTS_LINE_COLOR,
            name: DEFAULT_SEGMENT_VALUE
          }
        ],
        expanded: true
      }
    ];
    if (segmentDefinitions?.length) {
      segmentDefinitions.forEach(definition => {
        const { name, id, segments } = definition;
        const segmentsData = segments.map(value => {
          return {
            ...value,
            checked: checkedIds.includes(value?.id),
            color: getHashedColor(value.id, lineColors),
            name: value.segmentValue
          };
        });
        panelState.push({
          groupId: id,
          title: name,
          values: segmentsData,
          expanded: false
        });
      });
    }

    setSegmentsState(panelState);
    setAppliedSegmentsState(panelState);
    setFilteredSegmentsState(panelState);
  }, [segmentDefinitions]);

  const resetAllHandler = useCallback(() => {
    setSegmentsState(prevSegments =>
      prevSegments.map(segment => ({
        ...segment,
        expanded: segment.groupId === DEFAULT_SEGMENTS_ID,
        values: segment.values.map(value => {
          return {
            ...value,
            checked: value.id === DEFAULT_SEGMENTS_ID
          };
        })
      }))
    );
    setExpandedAll(false);
  }, []);

  const selectedSegemntValues = useMemo(() => {
    const selectedValues: SelectedSegment[] = [];
    segmentsState.forEach(segment => {
      segment.values.forEach(value => {
        if (value.checked) {
          const { id, segmentValue, color } = value;
          selectedValues.push({
            color,
            groupId: segment.groupId,
            id,
            segmentValue,
            title: segment.title
          });
        }
      });
    });
    return selectedValues;
  }, [segmentsState]);

  const isMaxSegmentsSelected =
    selectedSegemntValues.length >= DEFAULT_MAX_SELECTED_SEGMENT_VALUES;

  const filterSegments = useCallback(
    searchVal => {
      if (!searchVal) {
        setFilteredSegmentsState(segmentsState);
        return;
      }
      const filteredData = segmentsState
        .filter(group => {
          if (group?.title.toLowerCase().includes(searchVal.toLowerCase()))
            return true;
          const { values } = group;
          return values.some(({ segmentValue }) =>
            segmentValue.toLowerCase().includes(searchVal.toLowerCase())
          );
        })
        .map(groupItem => {
          const { values } = groupItem;
          const filteredValues = values.filter(({ segmentValue }) =>
            segmentValue.toLowerCase().includes(searchVal.toLowerCase())
          );
          if (filteredValues.length)
            return { ...groupItem, values: filteredValues, expanded: true };
          return { ...groupItem, expanded: true };
        });

      setFilteredSegmentsState(filteredData);
    },
    [segmentsState]
  );

  useEffect(() => {
    counterHandler(selectedSegemntValues?.length);
  }, [selectedSegemntValues, counterHandler]);

  useEffect(() => {
    if (filteringMode) {
      filterSegments(searchValue);
    }
  }, [segmentsState, filterSegments, searchValue, filteringMode]);

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue('');
  };

  const changeSearchHandler = useCallback(value => {
    setSearchValue(value);
  }, []);

  const checkHandler = useCallback(
    (groupId, id, checked) => {
      const copySegmentsState = cloneDeep(segmentsState);
      if (copySegmentsState?.length) {
        const group = copySegmentsState.find(item => item.groupId === groupId);
        if (group) {
          const value = group.values.find(val => val.id === id);
          if (value) {
            value.checked = checked;
          }
        }
      }
      setSegmentsState(copySegmentsState);
    },
    [segmentsState]
  );

  const collapseExpandHandler = useCallback(() => {
    setSegmentsState(prevSegments =>
      prevSegments.map(segment => ({
        ...segment,
        expanded: !expandedAll
      }))
    );
    setExpandedAll(expanded => !expanded);
  }, [expandedAll]);

  const expandHandler = useCallback(
    (id, expandedItem) => {
      if (filteringMode) return;
      const copySegmentsState = cloneDeep(segmentsState);
      const idx = copySegmentsState.findIndex(
        segment => segment.groupId === id
      );
      copySegmentsState[idx].expanded = expandedItem;
      setSegmentsState(copySegmentsState);
    },
    [segmentsState, filteringMode]
  );

  const handleApplySegments = () => {
    const selectedColorIds = selectedSegemntValues.map(value => {
      const { color, id, segmentValue } = value;
      return { color, id, segmentValue };
    });
    setActiveSegmentGroupColorIds(selectedColorIds);
    setAppliedSegmentsState(segmentsState);
    setAnchorEl(null);
  };

  const handleManageSegments = () => {
    history.push(`${match.url}/settings?panel=segments`);
  };

  return (
    <Popover
      id="segments-panel-selection"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      classes={{ paper: `${classNames.segmentsPanelPopover}` }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      TransitionProps={{
        onExited: () => {
          if (!anchorEl) {
            setSegmentsState(appliedSegmentsState);
          }
        }
      }}
    >
      <div className={classNames.segmentsMenuWrapper}>
        <div className={classNames.menuLeftColumn}>
          <div className={classNames.inputContainer}>
            <Input
              placeholder="Search..."
              onChange={changeSearchHandler}
              value={searchValue}
              PrefixIcon={SearchIcon}
              withoutBorder
              PostfixIcon={CrossIcon}
              onPostfixIconClick={() => setSearchValue('')}
            />
          </div>
          <div className={classNames.actionsContainer}>
            <span className={classNames.actionsContainerText}>
              All Segments
            </span>
            <TextButton
              size="small"
              type="tertiary"
              onClick={collapseExpandHandler}
              disabled={filteringMode}
            >
              {expandedAll ? 'Collapse All' : 'Expand All'}
            </TextButton>
          </div>
          <div className={classNames.segmentsDataContainer}>
            {(filteringMode ? filteredSegmentsState : segmentsState).map(
              definition => {
                const { expanded, groupId, title, values } = definition;
                return (
                  <SingleSegment
                    title={title}
                    values={values}
                    expanded={expanded}
                    groupId={groupId}
                    key={groupId}
                    checkHandler={checkHandler}
                    expandHandler={expandHandler}
                    isDisabled={!hasSegments}
                    isMaxSegmentsSelected={isMaxSegmentsSelected}
                  />
                );
              }
            )}
            {!hasSegments && (
              <div className={classNames.emptySegmentsSection}>
                <EmptySegmentsIcon />
                <span className={classNames.emptySegmentsMessage}>
                  You can create new segments on the model setting page.
                </span>
                <Button size="large" onClick={handleManageSegments}>
                  Take me there
                </Button>
              </div>
            )}
            {!filteredSegmentsState.length && filteringMode && (
              <div className={classNames.emptySearchSection}>
                <NoSearchResultsIcon />
                <span className={classNames.emptySegmentsMessage}>
                  No search results
                </span>
              </div>
            )}
          </div>
          <div className={classNames.bottomActions}>
            <TextButton onClick={handleClose}>Close</TextButton>{' '}
            <Button
              disabled={!hasSegments || selectedSegemntValues.length === 0}
              onClick={handleApplySegments}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className={classNames.menuRightColumn}>
          <div className={classNames.selectedSegmentsSection}>
            <div className={classNames.actionsContainer}>
              <span className={classNames.actionsContainerText}>
                Selected ({selectedSegemntValues.length} out of{' '}
                {DEFAULT_MAX_SELECTED_SEGMENT_VALUES})
              </span>
              <TextButton
                type="tertiary"
                size="small"
                onClick={resetAllHandler}
              >
                Reset to default
              </TextButton>
            </div>
            <div className={classNames.selectedValuesContainer}>
              {selectedSegemntValues?.length > 0 &&
                selectedSegemntValues.map(item => {
                  const { title, segmentValue, id, groupId } = item;
                  return (
                    <SelectedSegment
                      key={id}
                      title={title}
                      value={segmentValue}
                      checkHandler={checkHandler}
                      groupId={groupId}
                      id={id}
                      isDisabled={!hasSegments}
                    />
                  );
                })}
            </div>
          </div>
          <div className={classNames.emptpySection} onClick={handleClose} />
        </div>
      </div>
    </Popover>
  );
};
