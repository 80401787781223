import { createRef, useCallback, useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';

type ServerDataType = {
  alertNotifications?: Record<string, string>[];
  marker: Record<string, string | number>;
  name: string;
  type: string;
  id?: string;
  featureData?: Array<{
    value: string;
    data: Array<Record<string, string | number>>;
  }>;
  segment?: Record<string, string>;
  x: Array<string | undefined>;
  y: Array<number | null> | null;
};

type PillsDataType = {
  dotColor: string | number;
  text: string;
};

type BarChartContatinerHookProps = {
  serverData: ServerDataType[];
};

export const useBarChartContainer = ({
  serverData
}: BarChartContatinerHookProps) => {
  const [data, setData] = useState<ServerDataType[]>([]);
  const [pillsData, setPillsData] = useState<PillsDataType[]>([]);

  const chartRef = createRef();

  useEffect(() => {
    setData(serverData);
  }, [serverData]);

  const handleUnhover = useCallback(() => {
    const shallowedData = [...data];
    shallowedData.forEach(val => {
      val.marker.opacity = 1;
    });

    setData(shallowedData);
  }, [data]);

  const handleHover = useCallback(
    (idx, text) => {
      const shallowedData = [...data];
      shallowedData.forEach(val => {
        if (val.name !== text) {
          val.marker = { ...val.marker, opacity: 0.4 };
        } else {
          val.marker = { ...val.marker, opacity: 1 };
        }
      });
      setData(shallowedData);
    },
    [data]
  );

  useEffect(() => {
    const pillsValues = uniqBy(data, 'name')
      .filter(trace => trace.name)
      .map(trace => ({ dotColor: trace?.marker?.color, text: trace.name }));
    setPillsData(pillsValues);
  }, [data]);

  return {
    handledPlotData: data,
    handleHover,
    chartRef,
    pillsData,
    handleUnhover
  };
};
