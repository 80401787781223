/* eslint-disable import/prefer-default-export */
import { GRID_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';
import get from 'lodash/get';
import { truncateValue } from '@shared/utils/decimalUtils';

// Don't include the `tags` here because it has another
// grouping criteria (groupingTags function) and `throttle`
// is not used as a column
const POLLING_CELLS = ['experimentKeys', 'runActive', 'pinned'];

export const getRowValue = (row, columnName) => {
  if (row?.render) {
    return row?.render(row, columnName);
  }

  return row[columnName];
};

export const getRowValueExport = (row, columnName) => {
  if (POLLING_CELLS.includes(columnName)) return row[columnName];

  if (columnName === GRID_COLUMNS.EXPERIMENT_TAGS) {
    return row.tags.join(',');
  }

  if (columnName === GRID_COLUMNS.NAME) {
    return get(row, GRID_COLUMNS.NAME, row.experimentKey);
  }

  const value = get(row, columnName);

  return truncateValue(value, row.decimalsPrecision);
};
