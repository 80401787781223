import React from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@DesignSystem/modals';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { trackEvent } from '@shared/utils/eventTrack';
import useArchiveExperimentsMutation from '@API/experiments/useArchiveExperimentsMutation';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import styles from './ExperimentActionsModal.module.scss';

const ArchiveExperimentModal = ({ open, onClose, symlink }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { experimentKey, projectName, workspace } = useParams();
  const archiveExperimentsMutation = useArchiveExperimentsMutation();

  const archivePageUrl = `/${workspace}/${projectName}/archive`;

  const handleArchiveExperiments = async () => {
    try {
      await archiveExperimentsMutation.mutateAsync({
        experimentKeys: [experimentKey]
      });

      trackEvent('EXPERIMENTS_ARCHIVED_FE');
      const linkToGo = symlink
        ? `/${workspace}/${projectName}`
        : `/${workspace}/${projectName}/archive/${experimentKey}`;

      history.push(linkToGo);
      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_ARCHIVED_EXPERIMENTS,
          'Your experiment has been archived.'
        )
      );
    } catch {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_ARCHIVED_EXPERIMENTS,
          'There was an error trying to archive your experiment.'
        )
      );
    }
  };

  const contentText = symlink ? (
    <span>Symlink experiment will be removed from this project only.</span>
  ) : (
    <span>
      After archiving the experiment, it can be restored on the{' '}
      <Link to={{ pathname: archivePageUrl }}>archive page</Link>.
    </span>
  );

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Archive this experiment?"
      content={
        <div className={styles.archiveExperimentModalContainer}>
          {contentText}
        </div>
      }
      onPrimaryButtonClick={handleArchiveExperiments}
      onSecondaryButtonClick={onClose}
      primaryButtonText="Archive"
      secondaryButtonText="Cancel"
    />
  );
};

ArchiveExperimentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  symlink: PropTypes.bool.isRequired
};

export default ArchiveExperimentModal;
