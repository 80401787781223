import React, { HTMLProps, useState, ReactNode } from 'react';
import cx from 'classnames';
import classes from './ListItem.module.scss';
import typography from '@ds-typography';
import { ListItemContext } from './useListItemContext';
import { getIsDefaultActiveStyle } from './helpers';
import {
  ListCheckbox,
  ListColor,
  ListIcon,
  ListImage,
  ListPrefixContainer,
  ListText,
  ListRadioButton,
  ListActionContainer,
  ListAction
} from './components';
import { ListActionSize } from '@design-system/types';

export interface ListItemProps extends HTMLProps<HTMLLIElement> {
  children: ReactNode;
  disabled?: boolean;
  active?: boolean;
  defaultCursor?: boolean;
  isReadonly?: boolean;
  dataTest?: string;
}

const ListItem = ({
  disabled = false,
  active = false,
  defaultCursor = false,
  isReadonly = false,
  children,
  onMouseEnter: parentOnMouseEnter,
  onMouseLeave: parentOnMouseLeave,
  dataTest,
  ...restProps
}: ListItemProps) => {
  const [isWithRadioButton, setIsWithRadioButton] = useState(false);
  const [isWithCheckbox, setIsWithCheckbox] = useState(false);
  const [actionSize, setActionSize] = useState<ListActionSize>('small');
  const [isWithSecondaryText, setIsWithSecondaryText] = useState(false);
  const [actionsVisibleOnHoverOnly, setActionsVisibleOnHoverOnly] = useState(
    false
  );

  const [hovered, setHovered] = useState(false);

  return (
    <ListItemContext.Provider
      value={{
        disabled,
        active,
        isWithRadioButton,
        isWithCheckbox,
        actionSize,
        isWithSecondaryText,
        actionsVisibleOnHoverOnly,
        itemHovered: hovered,
        setIsWithRadioButton,
        setIsWithCheckbox,
        setActionSize,
        setIsWithSecondaryText,
        setActionsVisibleOnHoverOnly
      }}
    >
      <li
        className={cx(classes.listItemContainer, typography.dsBody, {
          [classes.disabled]: disabled,
          [classes.active]: getIsDefaultActiveStyle(
            active,
            isWithCheckbox,
            isWithRadioButton
          ),
          [classes.primaryTextOnly]: !isWithSecondaryText,
          [classes.biggerMenuItem]: isWithSecondaryText,
          [classes.defaultCursor]: defaultCursor,
          [classes.isReadonly]: isReadonly
        })}
        data-test={dataTest}
        onMouseEnter={e => {
          if (parentOnMouseEnter) {
            parentOnMouseEnter(e);
          }
          setHovered(true);
        }}
        onMouseLeave={e => {
          if (parentOnMouseLeave) {
            parentOnMouseLeave(e);
          }
          setHovered(false);
        }}
        {...restProps}
      >
        {children}
      </li>
    </ListItemContext.Provider>
  );
};

ListItem.Text = ListText;
ListItem.Checkbox = ListCheckbox;
ListItem.PrefixContainer = ListPrefixContainer;
ListItem.RadioButton = ListRadioButton;
ListItem.Color = ListColor;
ListItem.Image = ListImage;
ListItem.Icon = ListIcon;
ListItem.ActionContainer = ListActionContainer;
ListItem.Action = ListAction;
export default ListItem;
