import React, { ChangeEvent, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import moment from 'moment';

import { StyledSlider, ValueLabel } from '@DesignSystem/slider';
import { MatrixGrid, MatrixLegend } from '@DesignSystem/confusion-matrix';
import { ConfusionMatrixEmpty } from '@Icons-outdated';
import { MATRIX_COLOR_SCALE } from '@mpm/constants';
import { getRanges } from '@/helpers/confusionMatrixHelpers';
import './MPMConfusionMatrix.scss';
import { ResponseIntervalType } from '@mpm/types';

type MPMConfusionMatrixProps = {
  labels: string[];
  data: [{ data: { x: string; y: number[][] }[]; segment: null }];
  intervalType: ResponseIntervalType;
  colorScale: typeof MATRIX_COLOR_SCALE;
  highlightPositions: number[];
  onCellHoverChange: () => void;
};

const DEFAULT_HIGHLIGHT_POSITIONS = [] as number[];

export const MPMConfusionMatrix = ({
  labels,
  data,
  intervalType,
  colorScale = MATRIX_COLOR_SCALE,
  highlightPositions = DEFAULT_HIGHLIGHT_POSITIONS,
  onCellHoverChange = noop
}: MPMConfusionMatrixProps) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const hourlyFormat = intervalType === 'HOURLY';

  const confMatrices = useMemo(() => {
    if (data?.length) {
      setSliderValue(data[0].data.length - 1 || 0);
      return data[0].data.map(item => item.y);
    }
    return [];
  }, [data]);

  const currentMatrix = useMemo(() => {
    return confMatrices[sliderValue];
  }, [confMatrices, sliderValue]);

  const matrixDates = useMemo(() => {
    if (data?.length) {
      return data[0].data.map(item => item.x);
    }
    return [];
  }, [data]);

  const handleChange = (
    _: ChangeEvent<object>,
    newValue: number | number[]
  ) => {
    if (typeof newValue === 'number') {
      setSliderValue(newValue);
    }
  };

  const ranges = useMemo(() => {
    return getRanges(currentMatrix, colorScale);
  }, [currentMatrix, colorScale]);

  const renderNoData = () => {
    return (
      <div className="chart-no-data-container">
        <ConfusionMatrixEmpty />

        <span>No data for this chart</span>
      </div>
    );
  };

  if (!data.length || data[0].data.length === 0) {
    return renderNoData();
  }

  return (
    <div className="confusion-matrix-wrapper">
      {confMatrices.length - 1 > 0 && (
        <div className="styled-slider-wrapper">
          <StyledSlider
            aria-label="Dates"
            defaultValue={0}
            step={1}
            marks
            min={0}
            max={confMatrices.length - 1}
            value={sliderValue}
            onChange={handleChange}
            valueLabelDisplay="auto"
            ValueLabelComponent={props =>
              ValueLabel(props, matrixDates, hourlyFormat)
            }
            track={false}
          />
          <div className="slider-selected-date-wrapper">
            <span className="slider-selected-date">
              Selected Date:·
              {moment
                .utc(matrixDates[sliderValue])
                .format(hourlyFormat ? 'Do MMM YYYY, ha' : 'Do MMM YYYY')}
            </span>
          </div>
        </div>
      )}

      <div className="confusion-matrix-board-wrapper">
        <div className="confusion-matrix-main-part-contatiner">
          <div className="confusion-matrix-aggregation-type">
            <span className="confusion-matrix-color-indicator" />
            Aggregated: All
          </div>
          <div className="confusion-matrix-main-part">
            <div className="confusion-matrix-yaxis-title">Actual Category</div>
            {currentMatrix && (
              <MatrixGrid
                matrix={currentMatrix}
                ranges={ranges}
                labels={labels}
                highlightPositions={highlightPositions}
                onCellHoverChange={onCellHoverChange}
                cellStyles={{ customCellSize: 40, cutomCellFontSize: 13 }}
              />
            )}
          </div>
          <div>
            <div className="confusion-matrix-xaxis-title">
              Predicted Category
            </div>
          </div>
        </div>
        <div className="confusion-matrix-legend">
          <MatrixLegend ranges={ranges} />
        </div>
      </div>
    </div>
  );
};
