import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Chip, {
  CHIP_TYPE_BADGE,
  CHIP_TYPE_TAG
} from '@DesignSystem/data-display/Chip/Chip';

const SortableChip = ({ id, label, onClick, onClose, classes, type }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      className={classes}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Chip label={label} onClose={onClose} onClick={onClick} type={type} />
    </div>
  );
};

SortableChip.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  classes: PropTypes.string,
  type: PropTypes.oneOf([CHIP_TYPE_TAG, CHIP_TYPE_BADGE])
};

SortableChip.defaultProps = {
  label: '',
  onClick: noop,
  onClose: noop,
  classes: '',
  type: CHIP_TYPE_TAG
};

export default SortableChip;
