import React from 'react';
import PageInfoMessage from '@shared/components/PageInfoMessage';

import queryString from 'query-string';

export enum NoAccessResources {
  Artifact = 'Artifact',
  Experiment = 'Experiment',
  Model = 'Model'
}

const NoAccessToResource = () => {
  const { resource, resourceName, workspace } = queryString.parse(
    location.search,
    {
      parseBooleans: true
    }
  );

  return (
    <PageInfoMessage
      title={`${resource} · Error`}
      goBackLink={workspace ? `/${workspace}` : '/'}
      message={`${
        NoAccessResources[
          (resource as unknown) as keyof typeof NoAccessToResource
        ] || ''
      } "${resourceName}" is private.`}
    />
  );
};

export default NoAccessToResource;
