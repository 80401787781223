import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import experimentActions from '@/actions/experimentActions';
import projectsActions from '@/actions/projectsActions';
import { EXPERIMENT_TAB_PATHNAME } from '@experiment-management-shared/constants/experimentConstants';

import SmallLoader from '@shared/components/SmallLoader';
import ExperimentDetailsWrapper from './ExperimentDetailsWrapper';
import ExperimentPageHeader from './ExperimentPageHeader';

import styles from './ExperimentDetails.module.scss';

const ExperimentDetails = ({
  experimentKeys,
  isArchive,
  columns,
  projectId,
  isLoadingMetadata,
  isIdleMetadata,
  experiments,
  workspace,
  projectName,
  isComparison,
  onDetailViewChange
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(experimentActions.setExperimentKeysForDetailsPage(experimentKeys));
    dispatch(
      projectsActions.setActiveEMHeaderTab(
        isArchive
          ? EXPERIMENT_TAB_PATHNAME.archive
          : EXPERIMENT_TAB_PATHNAME.experiments
      )
    );

    return () => {
      dispatch(experimentActions.clearExperimentsUI());
    };
  }, [dispatch, experimentKeys, isArchive]);

  if (isLoadingMetadata || isIdleMetadata) {
    return <SmallLoader />;
  }

  if (!isLoadingMetadata && !isIdleMetadata && !experiments?.length) {
    return (
      <Redirect
        to={{
          pathname: `/${workspace}/${projectName}`,
          state: {
            nonExistentExperiment: true
          }
        }}
      />
    );
  }

  return (
    <div className={cx(styles.experimentDetailsContainer)}>
      <ExperimentPageHeader
        availableColumns={columns}
        projectId={projectId}
        isLoadingExperimentsData={isLoadingMetadata || isIdleMetadata}
        isArchive={isArchive}
        rows={experiments}
        isComparison={isComparison}
      />

      <div
        className={cx(styles.experimentDetailContainer, {
          [styles.comparison]: isComparison,
          'single-experiment': !isComparison
        })}
      >
        {!!experiments?.length && (
          <ExperimentDetailsWrapper
            projectId={projectId}
            experiments={experiments}
            onDetailViewChange={onDetailViewChange}
            isComparison={isComparison}
          />
        )}
      </div>
    </div>
  );
};

ExperimentDetails.propTypes = {
  isComparison: PropTypes.bool,
  experimentKeys: PropTypes.array.isRequired,
  isArchive: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  isLoadingMetadata: PropTypes.bool.isRequired,
  isIdleMetadata: PropTypes.bool.isRequired,
  experiments: PropTypes.array.isRequired,
  workspace: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  onDetailViewChange: PropTypes.func.isRequired
};

ExperimentDetails.defaultProps = {
  isComparison: false
};

export default ExperimentDetails;
