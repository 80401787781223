import { ROOT_DIR } from '@experiment-management-shared/constants/fileBrowser';
import {
  processDir,
  getDirInSubTree,
  subTreeTemplate,
  getFileLevel,
  removeLastSlashFromPath
} from '@experiment-management-shared/utils/filesTreeUtils';

export default class FilesTree {
  constructor(files = []) {
    this.tree = subTreeTemplate(ROOT_DIR, ROOT_DIR, 0);
    files.forEach(file => this.addFile(file));
  }

  addFile(file) {
    const { relativePath, dirLevel, subTree } = this.pathToFile(file);

    file.level = getFileLevel(relativePath, dirLevel);
    file.relativePath = relativePath;
    file.id = file.link;

    subTree.files.push(file);
  }

  createSubTree(subTree, dir, relativePath, level, parentRelativePath) {
    const newSubTree = subTreeTemplate(
      relativePath,
      dir,
      level,
      parentRelativePath
    );
    subTree.dirs[dir] = newSubTree;

    return newSubTree;
  }

  pathToFile(file) {
    const { dir: _dir } = file;
    const { dir, dirArr } = processDir(_dir);

    let subTree = this.tree;
    let relativePath = ROOT_DIR;

    if (dir !== ROOT_DIR) {
      dirArr.forEach((dir, level) => {
        if (!dir || !dir.length) return;

        let nextSubTree = getDirInSubTree(subTree, dir);

        const parentRelativePath = removeLastSlashFromPath(relativePath);

        relativePath += dir;
        if (level !== dirArr.length - 1) relativePath += '/';

        if (!nextSubTree) {
          nextSubTree = this.createSubTree(
            subTree,
            dir,
            relativePath,
            level,
            parentRelativePath
          );
        }
        subTree = nextSubTree;
      });
    }

    return { relativePath, dirLevel: dirArr.length, subTree };
  }
}
