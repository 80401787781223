import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Avatar } from '@DesignSystem/data-display';
import {
  getUsername,
  getUserEmail,
  getIsUserLoggedInWithGitHub,
  getProfileImages
} from '@/reducers/userReducer';

const UserAvatar = ({
  isUserLoggedInWithGitHub,
  username,
  width,
  style,
  outlined
}) => {
  const profileImages = useSelector(getProfileImages);

  if (!profileImages) return null;

  const profileImage = width > 30 ? profileImages.large : profileImages.small;
  const url =
    profileImage.slice(0, 24) === 'https://www.gravatar.com' &&
    isUserLoggedInWithGitHub
      ? `https://github.com/${username}.png?size=${width}`
      : profileImage;

  return <Avatar src={url} width={width} style={style} outlined={outlined} />;
};

UserAvatar.defaultProps = {
  width: 30,
  style: {},
  outlined: false
};

UserAvatar.propTypes = {
  isUserLoggedInWithGitHub: PropTypes.bool.isRequired,
  style: PropTypes.object,
  username: PropTypes.string.isRequired,
  width: PropTypes.number,
  outlined: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isUserLoggedInWithGitHub: getIsUserLoggedInWithGitHub(state),
    userEmail: getUserEmail(state),
    username: getUsername(state)
  };
};

export default connect(mapStateToProps)(UserAvatar);
