import { useEffect, useState } from 'react';

const useUndoSnackbarState = ({
  isSuccess,
  isLoading,
  resetMutationState,
  onUndoClick,
  onClose
}) => {
  const [isUndoClicked, setIsUndoClicked] = useState(false);

  const handleCloseSuccessSnackbar = () => {
    resetMutationState();
    onClose();
  };

  const handleUndoClick = () => {
    onUndoClick();
    setIsUndoClicked(true);
    handleCloseSuccessSnackbar();
  };

  useEffect(() => {
    if (!isLoading && !isSuccess) {
      setIsUndoClicked(false);
    }
  }, [isLoading, isSuccess]);

  return {
    isOpen: isSuccess && !isUndoClicked,
    onClose: handleCloseSuccessSnackbar,
    onUndoClick: handleUndoClick
  };
};

export default useUndoSnackbarState;
