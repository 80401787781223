import { makeStyles } from '@material-ui/core';
import {
  MIN_WIDTH_FIRST_CELL,
  WIDTH_FIRST_CELL_LG,
  WIDTH_FIRST_CELL_MD,
  WIDTH_FIRST_CELL_XL
} from '@experiment-management-shared/constants/fileBrowser';

const useResponsiveWidth = makeStyles(theme => ({
  name: {
    [theme.breakpoints.down('sm')]: {
      minWidth: MIN_WIDTH_FIRST_CELL,
      width: MIN_WIDTH_FIRST_CELL,
      maxWidth: MIN_WIDTH_FIRST_CELL
    },
    [theme.breakpoints.only('md')]: {
      minWidth: WIDTH_FIRST_CELL_MD,
      width: WIDTH_FIRST_CELL_MD,
      maxWidth: WIDTH_FIRST_CELL_MD
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: WIDTH_FIRST_CELL_LG,
      width: WIDTH_FIRST_CELL_LG,
      maxWidth: WIDTH_FIRST_CELL_LG
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: WIDTH_FIRST_CELL_XL,
      width: WIDTH_FIRST_CELL_XL,
      maxWidth: WIDTH_FIRST_CELL_XL
    }
  },
  location: {
    minWidth: 100
  },
  dirName: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 5px) !important'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 15px) !important'
    }
  }
}));

export default useResponsiveWidth;
