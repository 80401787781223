import { onboardingActionTypes } from '@/constants/actionTypes';
import { CODE_FRAMEWORKS_LIST } from '@onboarding/constants';

const initialState = {
  framework: CODE_FRAMEWORKS_LIST[0]
};

export function onboardingReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case onboardingActionTypes.SET_FRAMEWORK:
      return {
        ...state,
        framework: payload.framework
      };

    default:
      break;
  }

  return state;
}

export const getSelectedFramework = state => state?.ui?.onboarding?.framework;
