import {
  DEFAULT_COLUMN_NAMES,
  DEFAULT_COLUMN_WIDTH
} from '@experiment-management-shared/constants/experimentGridConstants';

export const REPORT_COLUMNS = {
  ACTIONS: 'actions',
  AUTHOR: 'author',
  DESCRIPTION: 'description',
  DISPLAY_NAME: 'displayName',
  IS_TEMPLATE: 'isTemplate',
  LAST_UPDATED: 'lastUpdated',
  REPORT_ID: 'reportId',
  REPORT_NAME: 'reportName'
};

export const REPORTS_TABLE_COLUMN_HEADERS = [
  {
    id: REPORT_COLUMNS.REPORT_NAME,
    label: 'Report Name'
  },
  {
    id: REPORT_COLUMNS.IS_TEMPLATE,
    label: ''
  },
  {
    id: REPORT_COLUMNS.AUTHOR,
    label: 'Author'
  },
  {
    id: REPORT_COLUMNS.LAST_UPDATED,
    label: 'Last Saved'
  },
  {
    id: REPORT_COLUMNS.DESCRIPTION,
    label: 'Description'
  },
  { id: REPORT_COLUMNS.ACTIONS, label: '' }
];

export const DEFAULT_ROWS_PER_PAGE = 10;

export const REPORT_TABLE_DEFAULT_COLUMN_WIDTH = DEFAULT_COLUMN_WIDTH;

export const REPORT_TABLE_DEFAULT_WIDTHS = [
  {
    columnName: 'singleExperimentBtn',
    width: 60
  },
  {
    columnName: 'runActive',
    width: 45
  },
  {
    columnName: 'isVisibleOnDashboard',
    width: 46
  }
];

export const REPORT_TABLE_EXCLUDED_COLUMNS = ['pinned', 'comet_chart_color'];

export const REPORT_TABLE_PAGE_SIZES = [5, 10, 25, 50];

export const REPORT_TABLE_REQUIRED_COLUMNS = [
  'singleExperimentBtn',
  'isVisibleOnDashboard'
];

export const REPORT_TABLE_DEFAULT_COLUMNS = [
  ...REPORT_TABLE_REQUIRED_COLUMNS,
  ...DEFAULT_COLUMN_NAMES
];

export const SORT_BY_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};
