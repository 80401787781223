import { useQueries } from 'react-query';
import { prepareRulesTreeForRequest } from '@shared/utils/filterHelpers';

import {
  castValue,
  generateToColumn,
  generateToRule,
  getSearchRules,
  toMultipleSort
} from './utils';

import { axiosInstance, cancelWrapper } from '../api';
import useColumns from '../project/useColumns';
import useProject from '../project/useProject';

const MAX_COUNT_OF_GROUPS = 100;

const getExperimentGroups = ({
  group,
  groupType,
  isArchive,
  chainsQueryRules,
  projectId,
  additionalRules,
  rulesTree,
  multipleSort,
  viewId
}) => {
  return cancelWrapper(async cancelToken => {
    // the preparation is done here because as a part of preparation we are doing
    // converting from relative data to absolute that invalidate queryParams every
    // time and course to unnecessary request to BE
    const preparedRulesTree = prepareRulesTreeForRequest(
      rulesTree,
      additionalRules
    );

    const {
      data: { groups }
    } = await axiosInstance({
      cancelToken,
      data: {
        deleted: isArchive,
        group,
        chainsQueryRules,
        projectId,
        rulesTree: preparedRulesTree,
        multipleSort,
        viewId
      },
      method: 'post',
      url: 'query/experiments/groups/search'
    });

    const pageTotal = Math.ceil(groups.length / MAX_COUNT_OF_GROUPS);

    return {
      experimentGroups: groups
        .slice(0, MAX_COUNT_OF_GROUPS)
        .filter(group => group.hasOwnProperty('value'))
        .map(currentGroup => ({
          ...currentGroup,
          value: castValue(currentGroup.value, groupType)
        })),
      pageTotal
    };
  });
};

const getQueryParams = ({
  columns,
  fullGroupName,
  params,
  groupsQuery,
  projectId
}) => {
  const { isArchive, view } = params;

  const { query, table, templateId: viewId } = view;
  const { columnSorting, search } = table;

  const toColumn = generateToColumn(columns);
  const toRule = generateToRule(columns);
  const multipleSort = toMultipleSort(columnSorting, toColumn);
  const additionalRules = [
    ...groupsQuery.map(toRule),
    ...getSearchRules(search)
  ];

  const group = toColumn(fullGroupName);
  const groupType = columns?.find(col => col.name === group?.keyName)?.type;

  return {
    group,
    groupType,
    isArchive,
    chainsQueryRules: params.chainsQueryRules,
    additionalRules,
    rulesTree: query?.rulesTree,
    multipleSort,
    projectId,
    viewId
  };
};

export default function useExperimentGroups(params, config) {
  const { data: columns, isLoading: isLoadingColumns } = useColumns({
    extraCols: true
  });
  const { data: project, isLoading: isLoadingProject } = useProject();
  const projectId = params.projectId || project?.projectId;

  const queries = (params.groupsQueryData || []).map(data => {
    const { fullGroupName, groupsQuery } = data;
    const queryParams = getQueryParams({
      columns,
      fullGroupName,
      params,
      groupsQuery,
      projectId
    });

    const enabled = !isLoadingColumns && !isLoadingProject && !!queryParams;

    return {
      queryKey: ['experimentGroups', queryParams],
      queryFn: () => getExperimentGroups(queryParams),
      enabled,
      ...config
    };
  });

  return useQueries(queries);
}
