import { z } from 'zod';

export type LLMAsset = {
  version: number;
  chain_nodes: LLMChainNode[];
  chain_inputs?: {
    messages?: UserMessage[];
  };
  chain_outputs: object[];
  metadata: object;
  category: string;
  start_timestamp: number;
  end_timestamp: number;
  chain_duration: number;
};

export type LLMChainNode = {
  id: string;
  category: string;
  name: string;
  inputs?: {
    messages?: UserMessage[];
  };
  outputs: {
    messages?: UserMessage[];
  };
  duration: number;
  start_timestamp: number;
  end_timestamp: number;
  parent_ids: string[];
  metadata: object[];
  childrenIncludingIndirect?: string[];
};

export const ImageContentSchema = z.object({
  type: z.literal('image_url'),
  image_url: z.object({
    url: z.string()
  })
});

export type ImageContent = {
  type: 'image_url';
  image_url: {
    url: string;
  };
};

export type TextContent = {
  type: string;
  text: string;
};

export type UserMessage = {
  role: string;
  content: (ImageContent | TextContent)[] | string;
};
