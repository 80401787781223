import { EmptyExperimentTab } from '@shared';
import React from 'react';

const GraphDefinitionTabEmpty = () => (
  <EmptyExperimentTab>
    <EmptyExperimentTab.Icon iconName="graphDefinition" />
    <EmptyExperimentTab.Title>
      This experiment did not report a computation graph
    </EmptyExperimentTab.Title>
    <EmptyExperimentTab.Description>
      To set a computation graph during experiments use <b>set_model_graph()</b>{' '}
      on your Experiment object, or use a{' '}
      <EmptyExperimentTab.Link path="integrations/overview/">
        Comet integration
      </EmptyExperimentTab.Link>{' '}
      to do it automatically.
    </EmptyExperimentTab.Description>
    <EmptyExperimentTab.DocsLink path="api-and-sdk/python-sdk/reference/Experiment/#experimentset_model_graph" />
  </EmptyExperimentTab>
);

export default GraphDefinitionTabEmpty;
