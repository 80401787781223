import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useFilterAssetsInExperimentsByName } from '@experiment-management-shared/api/usefilterAssetsInExperimentsByName';
import usePanelConfigs from '@experiment-management-shared/hooks/usePanelConfigs';

import { BUILT_IN_CHART_TYPES } from '@/lib/appConstants';
import { BETA_FEATURE_DATA_PANEL_ADVANCED_FEATURES } from '@/lib/betaFeatures';
import { NoLoggedDataView } from '@DesignSystem/charts/NoLoggedDataView';
import { NoAssetsIcon } from '@design-system-outdated/icons';
import ChartContainer from '@experiment-management-shared/components/Charts/Chart/ChartContainer';
import {
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
} from '@experiment-management-shared/components/Charts/Chart/useChartHeaderMenu';
import './DataPanel.scss';
import { COLUMN_CONFIG, getActiveExperiments } from './helpers';
import useConcatTables from './hooks/useConcatTables';
import { useBetaFeatureEnabled } from '@shared/hooks';

const ADVANCED_FEATURES_CONFIG = {
  enableAdvancedFilter: true,
  enableCharts: true,
  enableRangeSelection: true,
  rowGroupPanelShow: 'always',
  groupDisplayType: 'multipleColumns',
  sideBar: 'columns'
};

const DEFAULT_COLUMN_WIDTH = 170;

const HIDDEN_MENU_ITEMS = [
  CHART_EXPORT_KEY,
  EMBED_PANEL,
  RESET_ZOOM,
  SAMPLE_SIZE,
  SHARE_PANEL
];
export const DataPanel = ({
  columnFilters,
  columnState,
  experimentKeys,
  hiddenExperimentKeys,
  containerProps,
  experiments,
  experimentsCount,
  chartName,
  title,
  isAutoRefreshEnabled,
  fileName,
  index,
  hasData,
  axis,
  joinType,
  onChange,
  onUpdateRows
}) => {
  const areAdvancedFeaturesEnabled = useBetaFeatureEnabled(
    BETA_FEATURE_DATA_PANEL_ADVANCED_FEATURES
  );
  const { actualTitle } = usePanelConfigs({
    experiments,
    isAutoRefreshEnabled,
    chartName,
    chartType: BUILT_IN_CHART_TYPES.data,
    titleProps: { fileName },
    title
  });

  const targetExperimentKeys = useMemo(
    () =>
      getActiveExperiments({
        experimentKeys,
        experimentsCount,
        hiddenExperimentKeys
      }),
    [experimentKeys, experimentsCount, hiddenExperimentKeys]
  );

  // Fetch list of assets that match selected file name
  const {
    data: tables,
    isFetching,
    isPreviousData,
    isError
  } = useFilterAssetsInExperimentsByName(
    {
      experiments: targetExperimentKeys,
      fileName
    },
    {
      refetchOnMount: true
    }
  );

  const gridRef = useRef();

  const { rows, columns } = useConcatTables({
    areAdvancedFeaturesEnabled,
    tables,
    joinType,
    index,
    axis
  });

  useEffect(() => {
    if (!rows) return;

    gridRef.current.api?.sizeColumnsToFit({
      defaultMinWidth: DEFAULT_COLUMN_WIDTH
    });
  }, [rows, columns]);

  const handleInitializeGrid = useCallback(event => {
    event.api?.setFilterModel(columnFilters);
    event.columnApi?.applyColumnState({ state: columnState, applyOrder: true });
  }, []);

  useEffect(() => {
    gridRef.current?.api.setFilterModel(columnFilters);
  }, [columnFilters]);

  useEffect(() => {
    gridRef.current?.columnApi.applyColumnState({
      state: columnState,
      applyOrder: true
    });
  }, [columnState]);

  const handleUpdateRows = useCallback(() => {
    if (gridRef.current?.api?.rowModel?.rowsToDisplay) {
      const filteredRows = gridRef.current.api.rowModel.rowsToDisplay.map(
        node => node.data
      );

      onUpdateRows(actualTitle, filteredRows);
    }
  }, [actualTitle, onUpdateRows]);

  const handleUpdateFilters = useCallback(
    event => {
      const filters = event.api?.getFilterModel();

      onChange({ columnFilters: filters });
    },
    [onChange]
  );

  const handleUpdateColumnState = useCallback(
    event => {
      const newColumnState = event.columnApi.getColumnState();

      onChange({ columnState: newColumnState });
    },
    [onChange]
  );

  return (
    <ChartContainer
      {...containerProps}
      isError={isError}
      isFetching={isFetching}
      isLoading={!rows && hasData}
      hiddenMenuItems={HIDDEN_MENU_ITEMS}
      isPreviousData={isPreviousData}
      title={actualTitle}
      customNoDataComponent={
        <NoLoggedDataView
          icon={<NoAssetsIcon />}
          title="No logged assets"
          link="/docs/v2/api-and-sdk/python-sdk/reference/Experiment/#experimentlog_table"
          linkText="&nbsp;check out our documentation"
          body="In order to view data using the Data Table you need to log it using `Experiment.log_table`"
        />
      }
      hasData={!!rows}
    >
      <div className="data-panel">
        <AgGridReact
          rowHeight={35}
          columnDefs={columns}
          rowData={rows}
          headerHeight={40}
          ref={gridRef}
          defaultColDef={COLUMN_CONFIG}
          suppressDragLeaveHidesColumns
          className="ag-grid-custom ag-theme-alpine"
          animateRows
          enableCellTextSelection
          onGridReady={event => {
            handleInitializeGrid(event);
            handleUpdateRows(event);
          }}
          onSortChanged={event => {
            handleUpdateColumnState(event);
            handleUpdateRows(event);
          }}
          onColumnResized={handleUpdateColumnState}
          onColumnMoved={handleUpdateColumnState}
          onFilterChanged={event => {
            handleUpdateFilters(event);
            handleUpdateRows(event);
          }}
          {...(areAdvancedFeaturesEnabled ? ADVANCED_FEATURES_CONFIG : {})}
        />
        <div className="data-panel-footer">
          <p>
            Total rows: <b>{rows?.length.toLocaleString('en-US')} </b> &nbsp; ||
            &nbsp; Total tables:{' '}
            <b>{tables?.length.toLocaleString('en-US') || 0}</b>
          </p>
        </div>
      </div>
    </ChartContainer>
  );
};

DataPanel.CONFIG_PROPERTIES = [
  'columnFilters',
  'columnState',
  'experimentKeys',
  'hiddenExperimentKeys',
  'containerProps',
  'experiments',
  'experimentsCount',
  'chartName',
  'title',
  'isAutoRefreshEnabled',
  'fileName',
  'index',
  'hasData',
  'axis',
  'joinType',
  'onChange',
  'onUpdateRows'
];

DataPanel.defaultProps = {
  activeIntervalFetchDelay: null,
  chartName: null,
  columnFilters: null,
  columnState: null,
  fileName: null,
  index: null,
  isAutoRefreshEnabled: false,
  title: null,
  hasData: true,
  onChange: noop,
  onUpdateRows: noop
};

DataPanel.propTypes = {
  activeIntervalFetchDelay: PropTypes.number,
  axis: PropTypes.number.isRequired,
  chartName: PropTypes.string,
  columnFilters: PropTypes.array,
  columnState: PropTypes.array,
  containerProps: PropTypes.object.isRequired,
  experimentKeys: PropTypes.array.isRequired,
  experiments: PropTypes.array.isRequired,
  experimentsCount: PropTypes.number.isRequired,
  fileName: PropTypes.string,
  hiddenExperimentKeys: PropTypes.array.isRequired,
  index: PropTypes.string,
  hasData: PropTypes.bool,
  isAutoRefreshEnabled: PropTypes.bool,
  joinType: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpdateRows: PropTypes.func,
  title: PropTypes.string
};
