import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

import { TightMoreIcon } from '@Icons-outdated';
import { DropdownList } from '@DesignSystem/controllers';

import './ActionsCell.scss';

export const GenericActionsCell = ({ options, onClick, disabled }) => {
  const [moreIconMenuElem, setMoreIconMenuElem] = useState(null);

  const handleCloseMenu = () => {
    setMoreIconMenuElem(null);
  };

  const handleClickMoreIcon = event => {
    if (!disabled) {
      setMoreIconMenuElem(event.currentTarget);
    }
  };

  return (
    <>
      <div
        className={cx('action-cell-wrapper', {
          open: !!moreIconMenuElem,
          disabled
        })}
        style={{ paddingLeft: 10 }}
        onClick={handleClickMoreIcon}
      >
        <TightMoreIcon />
      </div>
      <DropdownList
        anchorEl={moreIconMenuElem}
        onClose={handleCloseMenu}
        items={options}
        width="145px"
        withInput={false}
        horizontalPosition="left"
        verticalPosition="top"
        transformOriginHorizontal="right"
        onClick={onClick}
      />
    </>
  );
};

GenericActionsCell.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

GenericActionsCell.defaultProps = {
  options: [],
  onClick: noop,
  disabled: false
};
