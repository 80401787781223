import { OptionType } from '@design-system/types';
import React from 'react';
import { components, SingleValueProps } from 'react-select';

const SingleValue = ({ ...props }: SingleValueProps<OptionType>) => {
  const { prefixIcon, label } = props.data;
  return (
    <components.SingleValue {...props}>
      <>
        {prefixIcon}
        <span className="text-overflow">{label}</span>
      </>
    </components.SingleValue>
  );
};

export default SingleValue;
