import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

type DismissAlertMutationProps = {
  notificationIds: string[];
  dismissCallback: (notificationIds: string[]) => void;
};

export function useDismissAlertNotification() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ notificationIds }: DismissAlertMutationProps) => {
      return api.post(
        `mpm/${activeWorkspace?.id}/models/${modelId}/alert-notifications/dismiss`,
        {
          alertNotificationIds: notificationIds
        }
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries([
          'alertNotifications',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        await queryClient.cancelQueries([
          'alertNotificationsById',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
      },
      onSuccess: (_data, { notificationIds, dismissCallback }) => {
        queryClient.invalidateQueries([
          'alertNotifications',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        queryClient.invalidateQueries([
          'alertNotificationsById',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        queryClient.invalidateQueries([
          'alert-rules',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        queryClient.invalidateQueries([
          'alerts-count',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        dismissCallback(notificationIds);
        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_DISMISSED_ALERT_NOTIFICATIONS,
            'Alert notifications were successfully dismissed.'
          )
        );
      }
    }
  );
}
