import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import { COLORS } from '@/constants/colorConstants';

export const MarkdownSVG = ({ color, width, style }) => {
  const foregroundColor = get(style, 'color', color);

  return (
    <svg
      className="markdown-svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 208 128"
      fill={foregroundColor}
      style={style}
    >
      <rect
        width="198"
        height="118"
        x="5"
        y="5"
        ry="10"
        stroke={foregroundColor}
        strokeWidth="10"
        fill="none"
      />
      <path d="M30 98v-68h20l20 25 20-25h20v68h-20v-39l-20 25-20-25v39zM155 98l-30-33h20v-35h20v35h20z" />
    </svg>
  );
};

MarkdownSVG.defaultProps = {
  color: COLORS.tableIconColor,
  style: {},
  width: 14
};

MarkdownSVG.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string
};

export default MarkdownSVG;
