import { round, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { Slider } from '@design-system-outdated/components';
import { Pill } from '@shared/components/Pill/Pill';

import styles from './GraphicAnnotationsSection.module.scss';

export type Label = {
  color: string;
  name: string;
  score: number;
};

export type GraphicAnnotationSectionChanges = {
  hiddenLabelNames: string[];
  score: number;
};

export function GraphicAnnotationsSection({
  hiddenLabelNames,
  labels,
  onChangeSettings,
  score: initialScore,
  scoreSliderDisabled
}: {
  hiddenLabelNames: string[];
  labels: Label[];
  onChangeSettings: (changes: GraphicAnnotationSectionChanges) => void;
  score: number;
  scoreSliderDisabled?: boolean;
}) {
  const [score, setScore] = useState(initialScore);

  useEffect(() => {
    setScore(initialScore);
  }, [initialScore]);

  const uniqueLabels = useMemo(() => {
    return uniqBy(labels.flat(), 'name');
  }, [labels]);

  const minScore = useMemo(() => {
    return Math.min(...labels.map(label => label.score));
  }, [labels]);

  const maxScore = useMemo(() => {
    return Math.max(...labels.map(label => label.score));
  }, [labels]);

  const renderLabel = (label: Label) => {
    const isDisabled = hiddenLabelNames.includes(label.name);
    const handleToggle = () => {
      const newHiddenLabelNames = isDisabled
        ? hiddenLabelNames.filter(labelName => labelName !== label.name)
        : [...hiddenLabelNames, label.name];

      onChangeSettings({
        hiddenLabelNames: newHiddenLabelNames,
        score
      });
    };

    return (
      <Pill
        clickable
        color={label.color}
        disabled={isDisabled}
        key={label.name}
        onClick={handleToggle}
        text={label.name}
        animation={undefined}
        className={undefined}
        fontSize={undefined}
        style={undefined}
      />
    );
  };

  const scoreWithBoundaries = Math.max(Math.min(score, maxScore), minScore);

  return (
    <div className={styles.container}>
      <div className={styles.labelsGrid}>
        {uniqueLabels.map(label => renderLabel(label))}
      </div>

      <div className={styles.fieldText}>
        <p>Confidence Score</p>
        <div className={styles.slider}>
          <Slider
            max={maxScore}
            min={minScore}
            onChange={newScore => {
              setScore(newScore);
            }}
            onChangeCommitted={newScore => {
              onChangeSettings({
                hiddenLabelNames,
                score: newScore
              });
            }}
            sliderSuffixFormat={val => String(round(val, 1))}
            step={0.0001}
            value={scoreWithBoundaries}
            valueLabelDisplay="off"
            suffixWidth={30}
            disabled={scoreSliderDisabled}
          />
        </div>
      </div>
    </div>
  );
}
