import { SyntaxNode, SyntaxNodeRef } from '@lezer/common';
import { EditorView } from '@uiw/react-codemirror';
import { COLUMN_PREFIXES } from './constants';

export function getArgumentNumber(node: SyntaxNode) {
  let argumentNumber = 0;
  let currentNode = node.prevSibling;

  while (currentNode && argumentNumber < 100) {
    argumentNumber += 1;
    currentNode = currentNode.prevSibling;
  }

  return argumentNumber;
}

export const getText = (view: EditorView, node: SyntaxNodeRef | null) => {
  if (!node) return '';

  return view.state.doc.sliceString(node.from, node.to);
};

export function isFeatureColumn(name: string) {
  return name.startsWith(COLUMN_PREFIXES.feature);
}

export function isLabelColumn(name: string) {
  return name.startsWith(COLUMN_PREFIXES.label);
}

export function isPredictionColumn(name: string) {
  return name.startsWith(COLUMN_PREFIXES.prediction);
}

const REGEX_COLUMN_PREFIX = `^(${Object.values(COLUMN_PREFIXES).join('|')})`;
export const removeColumnPrefix = (name: string) => {
  return name.replace(new RegExp(REGEX_COLUMN_PREFIX), '');
};
