// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.CodeTab-module__codePanel--S2vgr{display:flex;gap:8px;align-items:flex-end;--experiment-select-width: 300px}div.CodeTab-module__codePanel--S2vgr .CodeTab-module__copyIcon--YIc8C{height:36px;display:flex;align-items:center;justify-content:center}.CodeTab-module__codePanelTab--rpz6e .empty-tab-message{padding-top:161px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codePanel": `CodeTab-module__codePanel--S2vgr`,
	"copyIcon": `CodeTab-module__copyIcon--YIc8C`,
	"codePanelTab": `CodeTab-module__codePanelTab--rpz6e`
};
module.exports = ___CSS_LOADER_EXPORT___;
