import useResizeObserver, {
  ObservedSize
} from '@/helpers/custom-hooks/useResizeObserver';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

interface UseRefSizesConfigs {
  debounceWait?: number;
}

const useRefSizes = ({ debounceWait = 100 }: UseRefSizesConfigs = {}) => {
  const [
    { height: refHeight, width: refWidth },
    setSize
  ] = useState<ObservedSize>({} as ObservedSize);
  const onResize = useMemo(
    () => debounce(setSize, debounceWait, { leading: true }),
    [debounceWait]
  );
  const { ref: sizeRef } = useResizeObserver({ onResize });

  return {
    sizeRef,
    refHeight,
    refWidth
  };
};

export default useRefSizes;
