import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';

const useSentry = username => {
  useEffect(() => {
    Sentry.setUser({ username });
  }, [username]);
};

export default useSentry;
