import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import GridRowRenderer from './GridRowRenderer';
import classNames from './GraphicsVirtualizedGrid.module.scss';

export default function VirtualizedGrid({
  items,
  showSelection,
  rowHeight,
  itemWidth,
  containerHeight,
  handleOpenImagePreview,
  selectedItems,
  handleSelectItem,
  rowItemsCount
}) {
  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        const itemsPerRow =
          width < itemWidth
            ? 1
            : rowItemsCount || Math.floor(width / itemWidth);
        const rowCount = Math.ceil(items.length / itemsPerRow);

        return (
          <List
            className={classNames.listContainer}
            width={width}
            height={containerHeight}
            rowCount={rowCount}
            rowHeight={rowHeight}
            rowRenderer={({ index, key, style }) => (
              <GridRowRenderer
                showSelection={showSelection}
                key={key}
                assets={items}
                rowIndex={index}
                onClick={asset =>
                  handleOpenImagePreview(asset, items.filter(Boolean))
                }
                itemsPerRow={itemsPerRow}
                style={style}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
              />
            )}
          />
        );
      }}
    </AutoSizer>
  );
}
