import { useQuery } from 'react-query';
import { useActiveWorkspace } from '@shared/hooks';

import api from '../api';

const getPanelTemplatesCount = async ({ teamId }) => {
  return api.get('code-visualizations/template/get-counts', {
    teamId
  });
};

export default function usePanelTemplatesCount() {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['panelTemplatesCount', { teamId: activeWorkspace?.id }],
    () => getPanelTemplatesCount({ teamId: activeWorkspace?.id })
  );
}
