// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DecimalPrecisionSlider-module__label--L6ka4{font-size:14px;font-weight:500;line-height:14px;white-space:nowrap}.DecimalPrecisionSlider-module__root--b6Pty{display:flex;flex-direction:column;gap:8px;margin:0 16px;min-width:145px}.DecimalPrecisionSlider-module__sliderContainer--gNkeQ{align-items:center;display:flex;font-size:14px;gap:8px;line-height:14px}.DecimalPrecisionSlider-module__sliderContainer--gNkeQ .ds-slider-container .value-label{width:22px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `DecimalPrecisionSlider-module__label--L6ka4`,
	"root": `DecimalPrecisionSlider-module__root--b6Pty`,
	"sliderContainer": `DecimalPrecisionSlider-module__sliderContainer--gNkeQ`
};
module.exports = ___CSS_LOADER_EXPORT___;
