import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import Popper from '@material-ui/core/Popper';

import {
  PanelCometMetadata,
  PanelCometMetadataLegendKey,
  TooltipData
} from '@experiment-management-shared/types';
import { TOOLTIP_MAX_EXPERIMENT_NAME_WIDTH } from '@experiment-management-shared/constants/chartConstants';
import { truncateMiddleOfStringByMaxCharacters } from '@shared/utils/displayHelpers';
import { getFormattedPlotlyData } from '@experiment-management-shared/components/Charts/ChartHelpers';

import styles from './ScatterChartTooltip.module.scss';

const getMetadata = (cometMetadata: PanelCometMetadata) => {
  return {
    experimentName: truncateMiddleOfStringByMaxCharacters(
      cometMetadata.experimentName,
      TOOLTIP_MAX_EXPERIMENT_NAME_WIDTH
    ) as string,
    dataKeys: cometMetadata.dataKeys || [],
    legendKeys: cometMetadata.legendKeys || []
  };
};

export type ScatterChartTooltipProps = {
  tooltipData: TooltipData;
};

export const ScatterChartTooltip = ({
  tooltipData
}: ScatterChartTooltipProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const setRef = useCallback(el => {
    setAnchorEl(el);
  }, []);

  const { color, point, pointSize, position } = tooltipData;
  const { dataKeys, legendKeys, experimentName } = getMetadata(
    point.data.cometMetadata as PanelCometMetadata
  );

  const renderLine = (key: PanelCometMetadataLegendKey) => {
    let value: string | number | React.JSX.Element = key.value;

    if (!key.formatted) {
      value = getFormattedPlotlyData(
        key.value,
        key.axis ? point[key.axis] : null
      );
    }

    return (
      <div
        key={key.title}
        className={cx(styles.line)}
        data-test="chart-tooltip-line-title"
      >
        <div className={cx(styles.title, styles.textOverflow)}>{key.title}</div>
        :<div className={cx(styles.value, styles.textOverflow)}>{value}</div>
      </div>
    );
  };

  const pointStyle = {
    ...position,
    '--size': `${pointSize || 0}px`
  } as React.CSSProperties;

  return (
    <>
      <div ref={setRef} className={styles.circle} style={pointStyle} />
      <Popper
        className={styles.popper}
        anchorEl={anchorEl}
        placement="right"
        open={Boolean(anchorEl)}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport'
          },
          offset: {
            enabled: true,
            offset: '0, 8px'
          },
          flip: {
            enabled: true,
            boundariesElement: 'viewport',
            flipVariationsByContent: true
          }
        }}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <div
              className={styles.color}
              style={{ '--line-color': color } as React.CSSProperties}
            ></div>
            <div className={cx(styles.title, styles.textOverflow)}>
              {experimentName}
            </div>
          </div>
          <div className={styles.body}>
            {dataKeys.map(key => renderLine(key))}
            {legendKeys.length > 0 && <div className={styles.spacer} />}
            {legendKeys.map(key => renderLine(key))}
          </div>
        </div>
      </Popper>
    </>
  );
};

export default ScatterChartTooltip;
