import React, { ForwardedRef, forwardRef } from 'react';

import classes from './ListComponents.module.scss';
import IconButton, {
  IconButtonProps
} from '../../../buttons/IconButton/IconButton';
import { useListItemContext } from '../useListItemContext';
import { ButtonSize, ListActionSize } from '@design-system/types';

export type ListActionProps = IconButtonProps;

const getIconButtonSize = (size: ListActionSize): ButtonSize => {
  if (size === 'small') {
    return 'small';
  }

  return 'medium';
};

const ListAction = (
  props: ListActionProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    disabled,
    actionSize,
    actionsVisibleOnHoverOnly,
    itemHovered
  } = useListItemContext();

  if (
    (disabled && actionsVisibleOnHoverOnly) ||
    (actionsVisibleOnHoverOnly && !itemHovered)
  ) {
    return null;
  }

  return (
    <div className={classes.actionContainer}>
      <IconButton
        type="secondary"
        size={getIconButtonSize(actionSize)}
        disabled={disabled}
        {...props}
        ref={ref}
      />
    </div>
  );
};

const ForwardedListAction = forwardRef(ListAction);
ForwardedListAction.displayName = 'ListAction';

export default ForwardedListAction;
