import { useState, useCallback } from 'react';

const TOOLTIP_ORIENTATION = {
  LEFT: 'left',
  RIGHT: 'right'
};
// 160 is a width of a version-tooltip-wrapper class
const TOOLTIP_WIDTH = 160;
const initialPosition = { left: 0, top: 0 };

export const useNumericalTooltip = ({ title, subTitle }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipOrientation, setTooltipOrientation] = useState(
    TOOLTIP_ORIENTATION.RIGHT
  );
  const [tooltipPosition, setTooltipPosition] = useState(initialPosition);

  const handleLinePointHover = useCallback(eventData => {
    eventData.event.preventDefault();
    eventData.event.stopPropagation();

    const point = eventData.points[0];
    const { xaxis, yaxis, x } = point;
    let yPlotly = point.y;
    let xCoord = xaxis.d2p(point.x) + xaxis._offset;
    const yCoord = yaxis.l2p(yPlotly) + yaxis._offset;
    const orientation =
      xaxis.d2p(point.x) + TOOLTIP_WIDTH > xaxis._length
        ? TOOLTIP_ORIENTATION.LEFT
        : TOOLTIP_ORIENTATION.RIGHT;
    setTooltipOrientation(orientation);
    let { color } = point.data.line || point.data.marker || '#5899DA';
    const headerTitle = title
      ? `${title} ${Number.parseFloat(point.x).toFixed(4)}`
      : Number.parseFloat(point.x).toFixed(4);
    const yVal = subTitle
      ? `${subTitle} ${Number.parseFloat(point.y).toFixed(4)}`
      : Number.parseFloat(point.y).toFixed(4);

    setTooltipData({
      headerTitle,
      title: point.data.name,
      color,
      x,
      y: yVal,
      data: point
    });
    setTooltipPosition({ left: xCoord, top: yCoord });
    setTooltipVisible(true);
  }, []);

  const hideTooltipHandler = () => {
    setTooltipVisible(false);
  };

  return {
    handleLinePointHover,
    tooltipData,
    tooltipPosition,
    tooltipOrientation,
    tooltipVisible,
    setTooltipVisible,
    hideTooltipHandler
  };
};
