import React from 'react';

import { useActiveWorkspaceName } from '@shared/hooks';
import { BasicModal } from '@DesignSystem/modals';
import history from '@/history';

type AnonymousModeClaimProps = {
  open: boolean;
  onClose: () => void;
};

export default function AnonymousModeClaimedSuccesfully({
  open,
  onClose
}: AnonymousModeClaimProps) {
  const currentActiveWorkspaceName = useActiveWorkspaceName();
  const handleClickButton = () => {
    history.push(`/${currentActiveWorkspaceName}`);
    onClose();
  };

  return (
    <BasicModal
      dataTest="anonymous-claimed-successfully"
      open={open}
      onClose={onClose}
      title={`Anonymous workspace ${currentActiveWorkspaceName} was successfully claimed`}
      primaryButtonText="Go To Workspace"
      secondaryButtonText="Close"
      onPrimaryButtonClick={handleClickButton}
      onSecondaryButtonClick={onClose}
    />
  );
}
