import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import isPlainObject from 'lodash/isPlainObject';
import ReactPlaceholder from 'react-placeholder';
import { getLanguageByFileName } from '@experiment-management-shared/utils/filesTreeUtils';
import useAssetById from '@experiment-management-shared/api/useAssetById';
import Highlight from '@shared/components/Highlight';

const toString = obj => {
  if (!isPlainObject(obj)) return obj;

  return JSON.stringify(obj, null, 2);
};

const TextPreview = ({
  artifactVersionId,
  assetId,
  experimentKey,
  fileName,
  fileSize,
  className
}) => {
  const { data: asset } = useAssetById({
    experimentKey,
    assetId,
    artifactVersionId,
    asText: true
  });

  let language = getLanguageByFileName(fileName);

  // Don't use highlight for files bigger than 10KB (performance issues)
  if (fileSize > 1024 * 10) {
    language = 'plaintext';
  }

  return (
    <div className={cx(className)}>
      <ReactPlaceholder
        showLoadingAnimation
        type="text"
        rows={6}
        ready={!!asset}
      >
        <Highlight code={toString(asset)} language={language} />
      </ReactPlaceholder>
    </div>
  );
};

TextPreview.defaultProps = {
  artifactVersionId: undefined,
  className: ''
};

TextPreview.propTypes = {
  artifactVersionId: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  className: PropTypes.string,
  experimentKey: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired
};

export default TextPreview;
