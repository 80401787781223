import api from '@shared/api';
import { useQuery } from 'react-query';

const getExperimentHTML = async ({ experimentKey, signal }) => {
  const { data } = await api.get('data-fetch/get/html', {
    params: { experimentKey },
    signal
  });

  return data.html;
};

export default function useExperimentHTML({ experimentKey }, config) {
  return useQuery(
    ['experiment-html', { experimentKey }],
    ({ signal }) => {
      return getExperimentHTML({
        experimentKey,
        signal
      });
    },
    { enabled: !!experimentKey, refetchOnMount: true, ...config }
  );
}
