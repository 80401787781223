import { Column } from '@devexpress/dx-react-grid';
import { SORT_BY_DIRECTION } from '@experiment-management-shared/constants/chartsGallery';

const KEY_TITLE = 'Name';
const EXPERIMENT_NAME_TITLE = 'Experiment';
const VALUE_TITLE = 'Value';

export const SYSTEM_METRICS_COMPARISON_COLUMNS_NAME = {
  KEY: 'key',
  EXPERIMENT: 'experiment',
  VALUE: 'value'
};

export const SYSTEM_METRICS_COMPARISON_TABLE_COLUMNS: Column[] = [
  { name: SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.KEY, title: KEY_TITLE },
  {
    name: SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.EXPERIMENT,
    title: EXPERIMENT_NAME_TITLE
  },
  {
    name: SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.VALUE,
    title: VALUE_TITLE
  }
];

export const defaultRulesSortingColumns = [
  {
    columnName: SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.KEY,
    direction: SORT_BY_DIRECTION.ASC
  }
];

export const disabledRulesSortingColumns = [
  {
    columnName: SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.EXPERIMENT,
    sortingEnabled: false
  },
  {
    columnName: SYSTEM_METRICS_COMPARISON_COLUMNS_NAME.VALUE,
    sortingEnabled: false
  }
];
