import { useCallback } from 'react';

import {
  PanelGlobalConfig,
  PanelGlobalConfigMap,
  PanelSection
} from '@experiment-management-shared/types';

import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { calculateLayoutForRemovingPanel } from '@experiment-management-shared/utils/layout';
import { calculateLockedState } from '@experiment-management-shared';

export type UsePanelMenuHandlersParams = {
  section: PanelSection;
  onSectionChange: (section: PanelSection) => void;
  panelGlobalConfig: PanelGlobalConfig;
  globalConfigMap: PanelGlobalConfigMap;
  lockClickEventName: string;
  deletePanelEventName?: string;
};

const usePanelMenuHandlers = ({
  section,
  onSectionChange,
  panelGlobalConfig,
  globalConfigMap,
  lockClickEventName,
  deletePanelEventName
}: UsePanelMenuHandlersParams) => {
  const baseTrackEvent = useBaseTrackEvent();
  const { panels, layout } = section;

  const handleDeletePanelClick = useCallback(
    ({ chartId }) => {
      const updatedPanels = panels.filter(panel => panel.chartId !== chartId);

      const panelsUpdate = calculateLayoutForRemovingPanel(
        updatedPanels,
        layout,
        panels
      );

      onSectionChange({
        ...section,
        ...panelsUpdate
      });

      if (deletePanelEventName) {
        baseTrackEvent(deletePanelEventName);
      }
    },
    [
      panels,
      layout,
      onSectionChange,
      section,
      deletePanelEventName,
      baseTrackEvent
    ]
  );

  const handleSampleSizeChange = useCallback(
    ({ chartId, newSampleSize }) => {
      let hasChanges = false;

      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId && panel.sampleSize !== newSampleSize) {
          hasChanges = true;

          const updatedPanel = {
            ...panel,
            sampleSize: newSampleSize
          };

          // This functionality is needed now only for Dashboard page,
          // because only this page has sampleSize as a part of global config
          updatedPanel.locked = calculateLockedState(
            panelGlobalConfig,
            updatedPanel,
            globalConfigMap
          );

          return updatedPanel;
        }

        return panel;
      });

      if (hasChanges) {
        onSectionChange({
          ...section,
          panels: updatedPanels
        });
      }
    },
    [globalConfigMap, onSectionChange, panelGlobalConfig, panels, section]
  );

  const handleTransformYChange = useCallback(
    ({ chartId, transformY }) => {
      let hasChanges = false;

      const updatedPanels = panels.map(panel => {
        // here we are not checking panel.transformY !== transformY because it can be a
        // situation that the chart setting is equal to new 'transformY' value but it is overwritten by global config,
        // and this fact doesn't allow to trigger invalidation of lock state
        if (panel.chartId === chartId) {
          hasChanges = true;

          const updatedPanel = {
            ...panel,
            transformY: transformY
          };

          updatedPanel.locked = calculateLockedState(
            panelGlobalConfig,
            updatedPanel,
            globalConfigMap
          );

          return updatedPanel;
        }

        return panel;
      });

      if (hasChanges) {
        onSectionChange({
          ...section,
          panels: updatedPanels
        });
      }
    },
    [globalConfigMap, onSectionChange, panelGlobalConfig, panels, section]
  );

  const handleLegendModeChange = useCallback(
    ({ chartId, legendMode }) => {
      let eventPayload = {};
      let hasChanges = false;
      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId && panel.legendMode !== legendMode) {
          hasChanges = true;

          eventPayload = {
            legend_display: legendMode,
            panel_type: panel.chartType,
            grouping: panel?.grouping?.enabled ? panel.grouping : null
          };

          return {
            ...panel,
            legendMode
          };
        }

        return panel;
      });

      if (hasChanges) {
        onSectionChange({
          ...section,
          panels: updatedPanels
        });

        baseTrackEvent('PANEL_LEGEND_DISPLAY', eventPayload);
      }
    },
    [baseTrackEvent, onSectionChange, panels, section]
  );

  const handleLockClick = useCallback(
    ({ chartId, value: isLocked }) => {
      let eventPayload = {};
      let hasChanges = false;

      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId && panel.locked !== isLocked) {
          hasChanges = true;

          eventPayload = {
            locked_state: isLocked
          };

          return {
            ...panel,
            locked: isLocked
          };
        }

        return panel;
      });

      if (hasChanges) {
        onSectionChange({
          ...section,
          panels: updatedPanels
        });

        baseTrackEvent(lockClickEventName, eventPayload);
      }
    },
    [baseTrackEvent, onSectionChange, lockClickEventName, panels, section]
  );

  const handlePanelChange = useCallback(
    (chartId, changes) => {
      const updatedPanels = panels.map(panel => {
        if (panel.chartId === chartId) {
          return {
            ...panel,
            ...changes
          };
        }

        return panel;
      });

      onSectionChange({
        ...section,
        panels: updatedPanels
      });
    },
    [onSectionChange, panels, section]
  );

  const handleLayoutChange = useCallback(
    newLayout => {
      onSectionChange({
        ...section,
        layout: newLayout
      });
    },
    [onSectionChange, section]
  );

  return {
    handleDeletePanelClick,
    handleSampleSizeChange,
    handleTransformYChange,
    handleLegendModeChange,
    handlePanelChange,
    handleLockClick,
    handleLayoutChange
  };
};

export default usePanelMenuHandlers;
