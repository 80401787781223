import { useQuery } from 'react-query';
import { downloadAndParseCSVsBatch } from '@experiment-management-shared/components/Charts/NoPlotlyCharts/DataPanel/helpers';
import api from '@API/api';
import { DATA_PANEL_FILE_SIZE_LIMIT } from '@/lib/appConstants';

const filterAssetsInExperimentsByName = async params => {
  const assets = await api.post(`/asset/filter-by-name`, params);
  const assetsWithSizeLimit = assets.filter(
    ({ fileSize }) => fileSize < DATA_PANEL_FILE_SIZE_LIMIT
  );

  return Promise.all(downloadAndParseCSVsBatch(assetsWithSizeLimit));
};

export function useFilterAssetsInExperimentsByName(
  { fileName, experiments },
  config
) {
  return useQuery(
    ['data-panel-assets', { fileName, experiments }],
    () => filterAssetsInExperimentsByName({ fileName, experiments }),
    {
      ...config,
      enabled: fileName !== null
    }
  );
}
