import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { chartColors } from '@design-system-outdated/constants';
import Tag from '../Tag/Tag';

const stagesColors = [
  chartColors.light_blue,
  '#e8733b',
  chartColors.green,
  chartColors.yellow,
  chartColors.pink,
  chartColors.purple,
  chartColors.turquoise,
  chartColors.blue,
  chartColors.burgundy,
  chartColors.grey,
  chartColors.red,
  chartColors.dark_blue
];

// this is done in order to match the same colors as BE does
// JAVA: colors[Math.floorMod(tagName.hashCode(), colors.length)]
function hashCode(str) {
  let hash = 0;
  let i = 0;
  const len = str.length;
  while (i < len) {
    // eslint-disable-next-line no-bitwise,no-plusplus
    hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
  }
  return hash;
}

const mod = function (n, m) {
  const remain = n % m;
  return Math.floor(remain >= 0 ? remain : remain + m);
};

// this is done in order to match the same colors as BE does

export function getStageColor(name = '') {
  const len = stagesColors.length;
  const positiveHashCodeForName = mod(hashCode(name), len);

  return stagesColors[positiveHashCodeForName];
}

export function getStageValues(stage) {
  if (typeof stage === 'string') {
    return {
      stage,
      color: getStageColor(stage)
    };
  }

  return stage;
}

const Stages = ({ stages = [] }) => (
  <Box className="ds-stages" sx={{ display: 'flex' }}>
    {stages?.length
      ? stages.map(stageIns => {
          const { color, stage } = getStageValues(stageIns);

          return (
            <Tag
              key={stage}
              backgroundColor={color}
              label={stage}
              marginLeft={4}
              color="white"
            />
          );
        })
      : null}
  </Box>
);

Stages.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        stage: PropTypes.string,
        color: PropTypes.string
      })
    ])
  ).isRequired
};

export default Stages;
