import { useDispatch } from 'react-redux';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import FileSaver from 'file-saver';
import { OtherParam } from '@shared/types';
import { generateNestedTreeByName } from '@shared/utils/paramsHelpers';

type UseOtherSingleParamsDownloadOpts = {
  params: OtherParam[];
  experimentName: string;
};
const useOtherSingleParamsDownload = ({
  params,
  experimentName
}: UseOtherSingleParamsDownloadOpts) => {
  const dispatch = useDispatch();

  const hasParams = Boolean(params?.length);

  const onDownload = () => {
    if (!hasParams) return;

    let json = null;
    try {
      json = JSON.stringify(generateNestedTreeByName(params), null, 2);
    } catch (_) {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_FAILED_JSON_STRINGIFY,
          'There was an error exporting your data.'
        )
      );
      return;
    }

    const blob = new window.Blob([json], {
      type: 'application/json'
    });

    FileSaver.saveAs(blob, `${experimentName}_other_parameters.json`);
  };

  return {
    onDownload,
    isDownloadButtonDisabled: !hasParams
  };
};

export default useOtherSingleParamsDownload;
