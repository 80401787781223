import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LinkedMenu } from '@DesignSystem/navigation';
import { TextButton } from '@ds';
import { PersonIcon, KeyIcon, BellIcon, GridIcon } from '@Icons-outdated';

import { Profile } from './Profile';
import { APIKeys } from './APIKeys';
import { Notifications } from './Notifications';
import { Workspaces } from './Workspaces';

import './account-settings.scss';

import { useActiveWorkspaceName } from '@shared/hooks';

import { WORKSPACE_URL_HASHES } from '@/constants/workspaceConstants';

import { DSArrowDirectionLeftIcon } from '@ds-icons';

const getMenuItems = () => [
  {
    label: 'Profile',
    path: '/account-settings/profile',
    PrefixIcon: PersonIcon,
    component: Profile
  },
  {
    label: 'API Keys',
    path: '/account-settings/apiKeys',
    PrefixIcon: KeyIcon,
    component: APIKeys
  },
  {
    label: 'Notifications',
    path: '/account-settings/notifications',
    PrefixIcon: BellIcon,
    component: Notifications
  },
  {
    label: 'Workspaces',
    path: '/account-settings/workspaces',
    PrefixIcon: GridIcon,
    component: Workspaces
  }
];

const AccountSettings: React.FC = () => {
  const history = useHistory();
  const currentWorkspaceName = useActiveWorkspaceName();

  const goToActiveWorkspaceProjects = useCallback(() => {
    history.replace(`/${currentWorkspaceName}${WORKSPACE_URL_HASHES.PROJECTS}`);
  }, [currentWorkspaceName, history]);

  const menuItems = getMenuItems();

  return (
    <div className="account-settings-container">
      <div className="account-settings-menu">
        <div className="menu-container">
          <div className="return-to-projects-container">
            <TextButton
              type="secondary"
              PrefixIcon={<DSArrowDirectionLeftIcon />}
              onClick={goToActiveWorkspaceProjects}
            >
              Return to Projects
            </TextButton>
          </div>
          <h2 className="account-settings-label">Account Settings</h2>
          <LinkedMenu items={menuItems} />
        </div>
      </div>
      <div className="account-settings-content">
        <Switch>
          {menuItems.map(item => (
            <Route
              key={item.path}
              path={item.path}
              exact
              component={item.component}
            />
          ))}
          <Route path="*">
            <Redirect to={menuItems[0].path} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AccountSettings;
