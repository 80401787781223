import { useState, useEffect } from 'react';

export const useDetectYScroll = elementRef => {
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (elementRef?.current) {
      setHasScroll(
        elementRef.current.scrollHeight > elementRef.current.clientHeight
      );
    }
  }, [elementRef]);

  return { hasScroll };
};
