import {
  ORGANIZATION_PLAN_COMMUNITY,
  ORGANIZATION_PLAN_STARTER
} from '@shared/constants';
import { Organization } from '@shared/types';

export const bytesToGB = (bytes = 0, decimals = 2) => {
  const usageInGB = bytes / 1024 ** 3;

  return (
    Math.floor(usageInGB * Math.pow(10, decimals)) / Math.pow(10, decimals)
  );
};

export const getAvailableStorage = (organization?: Organization) => {
  if (!organization) return null;
  if (organization.academic) return 100;
  if (organization.paymentPlan === ORGANIZATION_PLAN_COMMUNITY) return 100;
  if (organization.paymentPlan === ORGANIZATION_PLAN_STARTER) return 500;

  return null;
};
