import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, TextButton } from '@ds';

import { CloseModalIcon } from '@Icons-outdated';
import alertsUtil from '@/util/alertsUtil';
import GenericModal from '@shared/components/GenericModal';

const DeleteItemsModal = ({ modalId, title, subTitle, submitHandler }) => {
  const dispatch = useDispatch();
  const [confirmField, setConfirmField] = useState('');

  const handleInputChange = e => {
    const { value } = e.target;
    setConfirmField(value);
  };

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    submitHandler();
    handleClose();
  };

  const renderModalBody = () => {
    return (
      <div className="generic-modal-section">
        <span className="generic-modal-body-text">
          Enter “DELETE” to confirm.
        </span>
        <input
          className="native-input"
          onChange={handleInputChange}
          value={confirmField}
        />
        <span className="generic-modal-body-text">{subTitle}</span>
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt delete-modal"
      title={title}
      onCancel={handleClose}
      hideFooter
    >
      <span className="close-modal-icon-wrapper" onClick={handleClose}>
        <CloseModalIcon />
      </span>
      <div className="generic-modal-section">{renderModalBody()}</div>
      <div className="delete-modal-footer">
        <Button
          size="large"
          onClick={handleSubmit}
          disabled={confirmField !== 'DELETE'}
        >
          Confirm
        </Button>
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
      </div>
    </GenericModal>
  );
};

DeleteItemsModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired
};

export default DeleteItemsModal;
