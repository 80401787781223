import React from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import { TextButton } from '@ds';
import './FiltersSidebar.scss';
import FullWidthSidebar from '@design-system-outdated/components/FullWidthSidebar/FullWidthSidebar';
import QueryFiltersBody from '@shared/components/QueryFiltersBody';
import QueryFiltersHeader from '@shared/components/QueryFiltersHeader';
import { getRulesCount } from '@shared/utils/filterHelpers';
import { FILTER_BEHAVIOUR_MODE } from '@shared/components/QueryFiltersBody/QueryFiltersBody';
import { IFilterDetails } from '@experiment-management-shared/api/useListFilters';

const FiltersSidebar = ({
  entityName,
  isOpen,
  onChange,
  onClose,
  columns,
  editable,
  rulesTree,
  filters,
  activeFilter,
  filterMode,
  onClearQuery,
  onSaveQuery,
  onSelectFilter,
  onDeleteFilter,
  getOperatorsForRule,
  constructFilterDefinition,
  constructRuleLabel
}) => {
  const rulesCount = getRulesCount(rulesTree);

  const displaySidebarButtons = () => {
    return (
      <QueryFiltersHeader
        disableSave={!rulesCount}
        disableClear={!rulesCount}
        entityName={entityName}
        activeFilter={activeFilter}
        filters={filters}
        canEdit={editable}
        onSaveQuery={onSaveQuery}
        onClearQuery={onClearQuery}
        onSelectFilter={onSelectFilter}
        onDeleteFilter={onDeleteFilter}
      />
    );
  };

  return (
    <FullWidthSidebar
      classes={{
        dialog: 'ds-filters-sidebar',
        actions: 'filters-sidebar-actions-panel',
        title: 'filters-sidebar-title-wrapper',
        header: 'filters-sidebar-header'
      }}
      isOpen={isOpen}
      onClose={onClose}
      secondaryButton={
        <TextButton withoutPadding onClick={onClose}>
          Close
        </TextButton>
      }
      title={
        <>
          <h6 className="filters-sidebar-title">Filters</h6>
          {displaySidebarButtons()}
        </>
      }
      renderContent={() => (
        <QueryFiltersBody
          mode={filterMode}
          columns={columns}
          editable={editable}
          rulesTree={rulesTree}
          onChange={onChange}
          getOperatorsForRule={getOperatorsForRule}
          constructFilterDefinition={constructFilterDefinition}
          constructRuleLabel={constructRuleLabel}
        />
      )}
    />
  );
};

FiltersSidebar.defaultProps = {
  entityName: '',
  filters: [],
  activeFilter: null,
  filterMode: undefined,
  onClearQuery: undefined,
  onSaveQuery: undefined,
  onSelectFilter: undefined,
  onDeleteFilter: undefined,
  constructFilterDefinition: noop,
  constructRuleLabel: undefined
};

FiltersSidebar.propTypes = {
  entityName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  rulesTree: PropTypes.object.isRequired,
  filterMode: PropTypes.oneOf([
    FILTER_BEHAVIOUR_MODE.AND,
    FILTER_BEHAVIOUR_MODE.OR_AND
  ]),
  filters: PropTypes.arrayOf(IFilterDetails),
  activeFilter: IFilterDetails,
  onClearQuery: PropTypes.func,
  onSaveQuery: PropTypes.func,
  onSelectFilter: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  getOperatorsForRule: PropTypes.func.isRequired,
  constructFilterDefinition: PropTypes.func,
  constructRuleLabel: PropTypes.func
};

export default FiltersSidebar;
