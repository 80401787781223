import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import workspacesApi from '@/util/workspaceApi';

const claimAnonymousExperiment = async (
  workspaceName: string,
  isNewUser: boolean
) => {
  const { data } = await workspacesApi.claimAnonymousData(
    workspaceName,
    isNewUser
  );

  return data;
};

export default function useClaimAnonymousExperimentMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      workspaceName,
      isNewUser
    }: {
      workspaceName: string;
      isNewUser: boolean;
    }) => claimAnonymousExperiment(workspaceName, isNewUser),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['current-user']);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['current-user']);
        await queryClient.resetQueries(['current-user']);
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            `There was an error claiming the workspace`
          )
        );
      }
    }
  );
}
