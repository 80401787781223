import React from 'react';
import { components, ControlProps } from 'react-select';
import { OptionType } from '@design-system/types';
import classes from '../Select.module.scss';

const Control = (props: ControlProps<OptionType>) => {
  const { PrefixIcon } = props.selectProps;
  return (
    <components.Control {...props}>
      {PrefixIcon && (
        <div className={classes.prefixIconContainer}>{PrefixIcon}</div>
      )}
      {props.children}
    </components.Control>
  );
};

export default Control;
