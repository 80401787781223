import React from 'react';

import useCurrentUser from '@API/auth/useCurrentUser';
import {
  useUserPasswordUpdateMutation,
  useUserEmailUpdateMutation,
  useUserPhotoUpdateMutation
} from '@account-settings/api';

import { AccountInfo } from './AccountInfo';
import { PhotoSection } from './PhotoSection';

import './Profile.scss';

const Profile = () => {
  const { data: user } = useCurrentUser();

  const updatePasswordMutation = useUserPasswordUpdateMutation(user?.email);
  const updateEmailMutation = useUserEmailUpdateMutation();
  const updatePhotoMutation = useUserPhotoUpdateMutation();

  if (!user) {
    return null;
  }

  const handleUploadPhoto = async photo => {
    try {
      await updatePhotoMutation.mutateAsync(photo);
    } catch {
      // ignore because error is handled by onError
    }
  };

  const handleUpdateEmail = async ({ newEmail, password }) => {
    try {
      await updateEmailMutation.mutateAsync({
        newEmail,
        password
      });
    } catch {
      // ignore because error is handled by onError
    }
  };

  return (
    <div className="profile-container">
      <h4 className="page-title">Profile information</h4>
      <div>
        <PhotoSection
          profileImage={user.profileImage}
          onUploadPhoto={handleUploadPhoto}
          isPhotoUpdating={updatePhotoMutation.isLoading}
        />
      </div>
      <div>
        <AccountInfo
          email={user.email}
          username={user.username}
          isGitHub={user.isLoggedInWithGithub}
          onChangeEmail={handleUpdateEmail}
          onChangePassword={updatePasswordMutation.mutateAsync}
          isEmailUpdating={updateEmailMutation.isLoading}
          isPasswordUpdating={updatePasswordMutation.isLoading}
        />
      </div>
    </div>
  );
};

export default Profile;
