import React from 'react';
import { Popover } from '@material-ui/core';
import classNames from './RemainingTags.module.scss';
import { IconButton } from '@ds';
import { DSMoreOptionsHorizontalIcon } from '@design-system/icons';
import cx from 'classnames';

type RemainingTagsProps = {
  children: React.ReactNode;
};

const RemainingTags: React.FC<RemainingTagsProps> = ({ children }) => {
  const id = 'remaining-tag-list';
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpened = Boolean(anchorEl);

  return (
    <>
      <div className={classNames.remainingTagsContainer}>
        <IconButton
          onClick={handleClick}
          size="small"
          Icon={<DSMoreOptionsHorizontalIcon />}
          type="secondary"
          active={isOpened}
        />
      </div>

      <Popover
        id={id}
        open={isOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          className: classNames.container
        }}
      >
        <div className={cx(classNames.remainingTagsList, 'remaining-tag-list')}>
          {children}
        </div>
      </Popover>
    </>
  );
};

export default RemainingTags;
