import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import billingApi from '@/util/billingApi';

const useCancelPaymentPlan = organizationId => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(async () => billingApi.cancelPaymentPlan(organizationId), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'organizations',
        organizationId,
        'billing'
      ]);

      queryClient.invalidateQueries(['organizations', 'list']);

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.CANCEL_PAYMENT_PLAN,
          'Payment plan has been cancelled'
        )
      );
    },
    onError: e => {
      dispatch(
        alertsUtil.openErrorDialog(
          snackbarTypes.ERROR_CANCEL_PAYMENT_PLAN,
          e.message
        )
      );
    }
  });
};

export default useCancelPaymentPlan;
