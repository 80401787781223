import { isNumber } from 'lodash';
import clone from 'lodash/clone';
import randomstring from 'randomstring';

import { normalizePanel } from '@experiment-management-shared';
import {
  DEFAULT_COLUMN_SORTING,
  DEFAULT_COLUMN_WIDTHS,
  DEFAULT_DECIMAL_PRECISION
} from '@experiment-management-shared/constants/experimentGridConstants';
import {
  DashboardView,
  Panel,
  PanelsLayout,
  RuleGroup
} from '@experiment-management-shared/types';
import { initializeLayout } from '@experiment-management-shared/utils/layout';
import {
  REPORT_TABLE_DEFAULT_COLUMNS,
  REPORT_TABLE_DEFAULT_WIDTHS,
  REPORT_TABLE_EXCLUDED_COLUMNS
} from '@reports/constants';
import { Report, ReportSection } from '@reports/types';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';

const getDefaultDescription = (title: string) => `## ${title}

When editing a report, click here to edit this section:

1. Edit Heading and this text
2. Add Panels
3. Select particular Experiments for Panels`;

export const generateNewSectionId = () => randomstring.generate(6);

export const generateNewSection = (): ReportSection => ({
  bottomText: '',
  hiddenExperimentKeys: [],
  id: generateNewSectionId(),
  panels: {
    isAutoRefreshEnabled: false,
    isVisible: true,
    layout: null,
    layoutV2: null,
    panels: []
  },
  query: {
    segmentId: '',
    rulesTree: generateEmptyRulesTree() as RuleGroup,
    isVisible: true
  },
  table: {
    columnGrouping: [],
    columnOrders: [...REPORT_TABLE_DEFAULT_COLUMNS],
    // @todo: check this change from [DEFAULT_COLUMN_SORTING] -> DEFAULT_COLUMN_SORTING
    columnSorting: DEFAULT_COLUMN_SORTING,
    columnWidths: [...REPORT_TABLE_DEFAULT_WIDTHS, ...DEFAULT_COLUMN_WIDTHS],
    decimalPrecision: DEFAULT_DECIMAL_PRECISION,
    frozenExperimentKeys: null,
    isFrozen: false,
    isVisible: true,
    pageNumber: 0,
    pageSize: 10,
    pinnedExperimentKeys: []
  },
  topText: '## Section'
});

export const normalizeLayout = (layout?: PanelsLayout | null) => {
  return layout?.map(layoutItem => {
    const x = isNumber(layoutItem.x) ? layoutItem.x * 3 : layoutItem.x;
    const w = isNumber(layoutItem.w) ? layoutItem.w * 3 : 3;

    return { ...layoutItem, x, w };
  });
};

export const normalizeSection = (section: ReportSection): ReportSection => {
  const emptySection = generateNewSection();

  const panels = {
    ...emptySection.panels,
    ...section.panels,
    layout: section.panels.layoutV2 || normalizeLayout(section.panels.layout)
  };

  panels.panels = panels?.panels?.map(panel => normalizePanel(panel)) || [];
  panels.layout = initializeLayout({
    panels: panels?.panels,
    initialLayout: panels?.layout,
    entireRowCharts: false
  });

  return {
    ...emptySection,
    ...section,
    panels,
    query: {
      ...emptySection.query,
      segmentId: section.query?.activeSegmentId || '',
      rulesTree: section.query?.rulesTree || generateEmptyRulesTree()
    }
  };
};

export const normalizeSections = (sections: ReportSection[]) => {
  return sections.map(section => normalizeSection(section));
};

export const normalizeReport = (report: Report) => {
  return {
    ...report,
    data: {
      ...report.data,
      sections: normalizeSections(report.data.sections)
    }
  } as Report;
};

export const generateNewReport = (): Report => {
  return {
    data: {
      description: '',
      displayName: '',
      sections: [
        {
          ...generateNewSection(),
          topText: getDefaultDescription('HEADING')
        }
      ],
      reportName: ''
    },
    description: '',
    displayName: '',
    isTemplate: false,
    reportName: ''
  };
};

const COLUMN_NAMES_WITH_WIDTH = REPORT_TABLE_DEFAULT_WIDTHS.map(
  column => column.columnName
);

export const generateSectionFromView = ({
  dashboard,
  name
}: {
  dashboard: DashboardView;
  name: string;
}) => {
  const emptySection = generateNewSection();
  const panels = dashboard.panels.sections.reduce<Panel[]>(
    (acc, section) => acc.concat(section.panels),
    []
  );
  const layout = initializeLayout({
    panels,
    initialLayout: dashboard.panels.sections?.[0]?.layout ?? null,
    entireRowCharts: false
  });

  return {
    ...emptySection,
    hiddenExperimentKeys: dashboard.hiddenExperimentKeys?.slice() ?? [],
    panels: {
      ...emptySection.panels,
      isAutoRefreshEnabled: dashboard.panels.isAutoRefreshEnabled,
      layout,
      layoutV2: layout,
      panels
    },
    query: {
      ...emptySection.query,
      segmentId: dashboard.query.segmentId,
      rulesTree: clone(dashboard.query.rulesTree)
    },
    table: {
      ...emptySection.table,
      columnGrouping: dashboard.table.columnGrouping?.slice() ?? [],
      columnOrders: dashboard.table.columnOrders.filter(
        columnName => !REPORT_TABLE_EXCLUDED_COLUMNS.includes(columnName)
      ),
      columnSorting: dashboard.table.columnSorting.filter(
        column => !REPORT_TABLE_EXCLUDED_COLUMNS.includes(column.columnName)
      ),
      columnWidths: [
        ...REPORT_TABLE_DEFAULT_WIDTHS,
        ...dashboard.table.columnWidths.filter(
          column =>
            !REPORT_TABLE_EXCLUDED_COLUMNS.includes(column.columnName) &&
            !COLUMN_NAMES_WITH_WIDTH.includes(column.columnName)
        )
      ],
      decimalPrecision:
        dashboard.table.decimalPrecision ?? DEFAULT_DECIMAL_PRECISION,
      expandedGroups: dashboard.table.expandedGroups.slice() ?? [],
      pinnedExperimentKeys: dashboard.pinnedExperimentKeys?.slice() ?? []
    },
    topText: getDefaultDescription(name)
  };
};

export const generateReportURL = ({
  editMode,
  isTemplate,
  projectName,
  reportName,
  workspace
}: {
  editMode?: string;
  isTemplate?: boolean;
  projectName: string;
  reportName: string;
  workspace: string;
}) => {
  const baseURL = `/${workspace}/${projectName}/reports`;
  const viewURL = isTemplate
    ? `${baseURL}/template/${reportName}`
    : `${baseURL}/${reportName}`;

  return editMode ? `${viewURL}/${editMode}` : viewURL;
};

export const addViewToReport = ({
  isNewReport,
  report,
  dashboardData
}: {
  isNewReport: boolean;
  report: Report;
  dashboardData: {
    dashboard: DashboardView;
    name: string;
  };
}) => {
  try {
    const viewSection = generateSectionFromView(dashboardData);

    return {
      ...report,
      data: {
        ...report.data,
        sections: [...(isNewReport ? [] : report.data.sections), viewSection]
      }
    };
  } catch (_) {
    return report;
  }
};
