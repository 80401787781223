// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistogramTab-module__container--OMU5i{display:flex;flex-direction:column;min-height:100%;overflow:hidden;width:100%;padding-bottom:24px}.HistogramTab-module__container--OMU5i .HistogramTab-module__chartsWrapper--n5bZo{padding:0 var(--experiment-details-layout-padding)}.HistogramTab-module__container--OMU5i.HistogramTab-module__compare--lSXu_ .HistogramTab-module__chartsWrapper--n5bZo:first-child{padding:0 12px 0 var(--experiment-details-layout-padding)}.HistogramTab-module__container--OMU5i.HistogramTab-module__compare--lSXu_ .HistogramTab-module__chartsWrapper--n5bZo:last-child{padding:0 var(--experiment-details-layout-padding) 0 12px}.HistogramTab-module__emptyMessage--SUq1j{padding-top:161px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HistogramTab-module__container--OMU5i`,
	"chartsWrapper": `HistogramTab-module__chartsWrapper--n5bZo`,
	"compare": `HistogramTab-module__compare--lSXu_`,
	"emptyMessage": `HistogramTab-module__emptyMessage--SUq1j`
};
module.exports = ___CSS_LOADER_EXPORT___;
