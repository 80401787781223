import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import isNull from 'lodash/isNull';
import get from 'lodash/get';

import { Button, TextButton } from '@ds';

import visualizationsActions from '@/actions/visualizationsActions';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import GenericModal from '@shared/components/GenericModal';
import { getTemplateIdFromURL } from '@experiment-management-shared/utils/visualizationsHelper';
import {
  getCodeLanguagePreview,
  getCurrentTemplate
} from '@/reducers/visualizationsReducer';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import {
  getCapturedThumbnail,
  selectIsTemplateSaved
} from '@/reducers/ui/visualizationsUiReducer';
import { useThumbnailActions } from '@experiment-management-shared/components/VizEditor/hooks';
import { useIsServerCustomPanelsEnabled } from '@experiment-management-shared';
import { PANEL_CODE_LANGUAGES } from '@experiment-management-shared/constants/chartsGallery';

const VizEditorFooter = ({
  canEdit,
  capturedThumbnail,
  currentTemplate,
  dispatch,
  isTemplateSaved,
  openSaveModal,
  redirectHandler,
  templateId,
  currentCodeLanguage
}) => {
  const { openModal } = useThumbnailActions(templateId);
  const isServerCustomPanelsEnabled = useIsServerCustomPanelsEnabled();
  const { revisionId } = currentTemplate;

  const templateThumbnail = get(currentTemplate, 'thumbnailName', null);
  const isPy = currentCodeLanguage === PANEL_CODE_LANGUAGES.PYTHON;

  const doesThumbnailNeedCaptured =
    !(isServerCustomPanelsEnabled && isPy) &&
    isNull(templateThumbnail) &&
    isNull(capturedThumbnail);

  const [hasSavedNewTemplate, setHasSavedNewTemplate] = useState(false);

  useEffect(() => {
    if (hasSavedNewTemplate && templateId && doesThumbnailNeedCaptured) {
      openModal();
    }
  }, [hasSavedNewTemplate, templateId, doesThumbnailNeedCaptured, openModal]);

  const handleConfirmOverride = () => {
    dispatch(
      visualizationsActions.updateVisualizationTemplate(templateId, revisionId)
    ).then(redirectHandler);
  };

  const handleCancelConfirm = () => {
    redirectHandler();
  };

  const confirmModal = (
    <GenericModal
      title="You have unsaved changes."
      onClose={handleConfirmOverride}
      buttonText="Save and continue"
      customButtons={[
        {
          onClick: handleCancelConfirm,
          disabled: false,
          buttonText: 'Continue without saving',
          type: 'tertiary'
        }
      ]}
    />
  );

  const handleCancel = () => {
    window.close();
  };

  const handleSubmit = () => {
    if (isNull(templateId)) {
      openSaveModal();
      setHasSavedNewTemplate(true);
    } else if (doesThumbnailNeedCaptured) {
      openModal();
    } else if (!isTemplateSaved) {
      // save chart.options
      dispatch(visualizationsActions.runCodePreview(templateId));

      dispatch(
        alertsUtil.openCustomModal(
          dialogTypes.CONFIRM_SAVE_VISUALIZATION_TEMPLATE,
          confirmModal
        )
      );
    } else {
      redirectHandler();
    }
  };

  const getSubmitButtonText = () => {
    if (isNull(templateId) && doesThumbnailNeedCaptured) {
      return 'Save & Capture Thumbnail';
    }

    if (isNull(templateId)) {
      return 'Save';
    }

    if (doesThumbnailNeedCaptured) {
      return 'Capture Thumbnail';
    }

    return 'Save & Exit';
  };

  return (
    <div className="viz-editor-footer">
      <div className="btn-group">
        <TextButton size="XL" onClick={handleCancel}>
          Cancel
        </TextButton>
        {canEdit && (
          <Button size="XL" onClick={handleSubmit}>
            {getSubmitButtonText()}
          </Button>
        )}
      </div>
    </div>
  );
};

VizEditorFooter.defaultProps = {
  templateId: null
};

VizEditorFooter.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  capturedThumbnail: PropTypes.string.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isTemplateSaved: PropTypes.bool.isRequired,
  openSaveModal: PropTypes.func.isRequired,
  redirectHandler: PropTypes.func.isRequired,
  templateId: PropTypes.string,
  currentCodeLanguage: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const { codeTemplateId, revisionId } = props.match.params;
  const templateId = getTemplateIdFromURL(codeTemplateId);

  return {
    canEdit: isUserAllowedToEditProject(state),
    capturedThumbnail: getCapturedThumbnail(state),
    currentTemplate: getCurrentTemplate(state, {
      templateId,
      revisionId
    }),
    isTemplateSaved: selectIsTemplateSaved(state),
    templateId,
    currentCodeLanguage: getCodeLanguagePreview(state)
  };
};

export default withRouter(connect(mapStateToProps)(VizEditorFooter));
