import { useQuery } from 'react-query';
import api from '../api';

const filterAssetsNamesInExperimentsByType = ({ type, ...params }) =>
  api.post(`/asset/filter-name-by?type=${type}`, params);

export function useFilterAssetsNamesInExperimentsByType(
  { experiments, type },
  config
) {
  return useQuery(
    ['data-panel-assets', { experiments, type }],
    () => filterAssetsNamesInExperimentsByType({ experiments, type }),
    {
      ...config
    }
  );
}
