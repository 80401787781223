import React, { useState } from 'react';
import PropTypes from 'prop-types';
import first from 'lodash/first';
import capitalize from 'lodash/capitalize';
import noop from 'lodash/noop';
import cx from 'classnames';

import { CheckIcon } from '@Icons-outdated';
import { Button, IconButton, Tooltip } from '@ds';
import { DSInviteMemberIcon, DSMoreOptionsVerticalIcon } from '@ds-icons';
import { Link } from 'react-router-dom';
import { Avatar, Tag } from '@DesignSystem/data-display';
import { DropdownList } from '@DesignSystem/controllers';
import { Members } from '@DesignSystem/surfaces';

import './Card.scss';

const Card = ({
  logo,
  withLogo,
  name,
  membersPreview,
  menuMembers,
  membersCount,
  onLoadMenuMembers,
  isDefault,
  checked,
  cardFooter,
  backgroundLogoColor,
  moreMenuOptions,
  onClickMenuOption,
  disabledInviteButton,
  tooltipTextInviteButton,
  onClickInvite,
  tags = [],
  nameMaskTest
}) => {
  const [moreIconMenuElem, setMoreIconMenuElem] = useState(null);

  const handleCloseMenu = () => {
    setMoreIconMenuElem(null);
  };

  const handleClickMoreIcon = event => {
    setMoreIconMenuElem(event.currentTarget);
  };

  const handleClickMenuOption = (optionValue, option) => {
    onClickMenuOption(option);
    handleCloseMenu();
  };

  return (
    <>
      <div className={cx('ds-card')}>
        <div className="ds-card-content">
          <div className="name-container">
            {withLogo && (
              <Avatar
                src={logo}
                width="26px"
                square
                showLetters={!logo}
                type={!logo ? 'small' : 'basic'}
                backgroundColor={backgroundLogoColor}
                letters={capitalize(first(name))}
              />
            )}
            <Link
              className="organization-name-label"
              to={`/${name}`}
              data-mask-test={nameMaskTest}
            >
              {name}
            </Link>
            {checked && <StyledCheck />}
            {isDefault && <Tag label="Default" marginLeft={16} />}
            {tags &&
              tags.map(({ label, color }) => (
                <Tag
                  key={label}
                  label={label}
                  backgroundColor={color}
                  marginLeft={16}
                />
              ))}
          </div>

          <div className="users-controller-container">
            <div className="ds-card-users-container">
              {!!membersPreview?.length && (
                <Members
                  membersCount={membersCount}
                  membersPreview={membersPreview}
                  menuMembers={menuMembers}
                  onLoadMenuMembers={onLoadMenuMembers}
                />
              )}
            </div>
            <Tooltip content={tooltipTextInviteButton}>
              {/* the span is needed to make Tooltip work for disabled buttons */}
              <span>
                <Button
                  type="secondary"
                  className="invite-member-btn"
                  PrefixIcon={<DSInviteMemberIcon />}
                  disabled={disabledInviteButton}
                  onClick={onClickInvite}
                >
                  Invite
                </Button>
              </span>
            </Tooltip>
            <div onClick={handleClickMoreIcon} className="actions-icon-wrapper">
              <IconButton
                active={!!moreIconMenuElem}
                Icon={<DSMoreOptionsVerticalIcon />}
                type="secondary"
              />
            </div>
          </div>
        </div>
        <div className="ds-card-footer">{cardFooter}</div>
      </div>
      <DropdownList
        anchorEl={moreIconMenuElem}
        onClose={handleCloseMenu}
        items={moreMenuOptions}
        width="135px"
        withInput={false}
        horizontalPosition="left"
        verticalPosition="top"
        transformOriginHorizontal="right"
        onClick={handleClickMenuOption}
      />
    </>
  );
};

Card.propTypes = {
  logo: PropTypes.string,
  withLogo: PropTypes.bool,
  name: PropTypes.string,
  membersPreview: PropTypes.array,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string
    })
  ),
  menuMembers: PropTypes.array,
  membersCount: PropTypes.number,
  onLoadMenuMembers: PropTypes.func,
  cardFooter: PropTypes.node,
  checked: PropTypes.bool,
  isDefault: PropTypes.bool,
  backgroundLogoColor: PropTypes.string,
  moreMenuOptions: PropTypes.array,
  onClickMenuOption: PropTypes.func,
  disabledInviteButton: PropTypes.bool,
  tooltipTextInviteButton: PropTypes.string,
  onClickInvite: PropTypes.func,
  nameMaskTest: PropTypes.string
};

Card.defaultProps = {
  logo: null,
  withLogo: false,
  name: null,
  membersPreview: [],
  tags: [],
  menuMembers: [],
  membersCount: 0,
  onLoadMenuMembers: noop,
  cardFooter: null,
  checked: false,
  isDefault: false,
  backgroundLogoColor: null,
  moreMenuOptions: [],
  onClickMenuOption: noop,
  disabledInviteButton: false,
  tooltipTextInviteButton: '',
  onClickInvite: noop,
  nameMaskTest: undefined
};

export default Card;

const StyledCheck = () => {
  return (
    <Tooltip content="Member of this workspace">
      <div className="styled-check">
        <CheckIcon />
      </div>
    </Tooltip>
  );
};
