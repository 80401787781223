import { autocompletion, startCompletion } from '@codemirror/autocomplete';
import { LanguageSupport, syntaxHighlighting } from '@codemirror/language';
import { lintGutter, linter } from '@codemirror/lint';
import { EditorView, ViewUpdate, basicSetup } from '@uiw/react-codemirror';
import { sqlCompletionSource } from './autocompleter';
import { customSqlHighlightStyle, customSqlLanguage } from './highlighting';
import { customSqlLinter } from './linter';

const autoSuggestOnFocus = () => {
  return EditorView.updateListener.of((update: ViewUpdate) => {
    if (update.focusChanged || update.docChanged || update.selectionSet) {
      startCompletion(update.view);
    }
  });
};

export const customSql = ({
  columnNames,
  debug = false
}: {
  columnNames: string[];
  debug?: boolean;
}) => {
  const customLinter = customSqlLinter(columnNames);
  const language = new LanguageSupport(customSqlLanguage, [
    basicSetup(),
    syntaxHighlighting(customSqlHighlightStyle),
    autocompletion({
      override: [sqlCompletionSource(columnNames, debug)]
    }),
    autoSuggestOnFocus(),
    linter(customLinter),
    lintGutter()
  ]);

  return { language, linter: customLinter };
};

export { parser as customSqlParser } from './comet-sql.grammar.js';
export { COLUMN_PREFIXES } from './constants';
export type { CometSQLColumnType } from './constants';
