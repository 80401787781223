// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphicsGalleryCompare-module__graphicsTabCompare--bGj9l{display:flex;flex-direction:column;height:100%}.GraphicsGalleryCompare-module__graphicsTabCompareList--v4Msw{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphicsTabCompare": `GraphicsGalleryCompare-module__graphicsTabCompare--bGj9l`,
	"graphicsTabCompareList": `GraphicsGalleryCompare-module__graphicsTabCompareList--v4Msw`
};
module.exports = ___CSS_LOADER_EXPORT___;
