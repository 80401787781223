import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import noop from 'lodash/noop';

import { TextButton } from '@ds';
import DialogContent from '@material-ui/core/DialogContent';

import {
  SELECT_PANEL_PATH,
  PANEL_PREVIEW_PATH
} from '@experiment-management-shared/constants/visualizationConstants';

import { formatRevision } from '@experiment-management-shared/utils/visualizationsHelper';
import GenericModal from '@shared/components/GenericModal';

const UpdateVersionModal = ({
  chartId,
  currentRevisionId,
  handleCloseModal,
  latestRevisionId,
  onSkipVersion,
  onUpdateVersion,
  templateId,
  GenericModalProps
}) => {
  const location = useLocation();

  const buildViewSourceURL = revisionId => {
    const basePath = `${location.pathname}/${SELECT_PANEL_PATH}/${PANEL_PREVIEW_PATH}/edit-code/${templateId}/${revisionId}`;

    return `${basePath}?chartId=${chartId}`;
  };

  const openCurrentVersion = () => {
    const currentVersionURL = buildViewSourceURL(currentRevisionId);

    window.open(currentVersionURL);

    handleCloseModal();
  };

  const openNewVersion = () => {
    const newVersionURL = buildViewSourceURL(latestRevisionId);

    window.open(newVersionURL);

    handleCloseModal();
  };

  return (
    <GenericModal
      buttonText="Update"
      cancelText="Skip this version"
      hasCancelButton
      onCancel={onSkipVersion}
      onClose={onUpdateVersion}
      title="Update Panel"
      {...GenericModalProps}
    >
      <DialogContent className="generic-modal-body update-version-modal-container">
        <div className="update-version-modal-text">
          This panel has been updated
        </div>
        <div className="update-version-modal-version-container">
          <div className="update-version-modal-version-text">
            Current Version:
          </div>
          <div className="update-version-modal-version-date">
            {formatRevision(currentRevisionId)}
          </div>
          <TextButton onClick={openCurrentVersion}>View source code</TextButton>
        </div>
        <div className="update-version-modal-version-container">
          <div className="update-version-modal-version-text">New Version:</div>
          <div className="update-version-modal-version-date">
            {formatRevision(latestRevisionId)}
          </div>
          <TextButton onClick={openNewVersion}>View source code</TextButton>
        </div>{' '}
      </DialogContent>
    </GenericModal>
  );
};

UpdateVersionModal.defaultProps = {
  onSkipVersion: noop,
  onUpdateVersion: noop,
  GenericModalProps: {}
};

UpdateVersionModal.propTypes = {
  chartId: PropTypes.string.isRequired,
  currentRevisionId: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  latestRevisionId: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  onSkipVersion: PropTypes.func,
  onUpdateVersion: PropTypes.func,
  templateId: PropTypes.string.isRequired,
  GenericModalProps: PropTypes.object
};

export default UpdateVersionModal;
