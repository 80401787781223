import React, { useMemo } from 'react';
import { last } from 'lodash';

import { Plot } from '@DesignSystem/charts';

type Point = {
  x: string;
  y: number;
};

type AreaChartProps = {
  data: Point[];
  yRange: number[] | null;
  layout: { showticklabels?: boolean };
  fillColor?: string;
  lineColor?: string;
  style: { marginRight?: string };
};

const AREACHART_Y_RANGE = [-0.09, 1.1];
const mapData = (data: Point[], fillColor?: string, lineColor?: string) => [
  {
    x: data?.map(pos => pos.x) || [],
    y: data?.map(pos => pos.y) || [],
    line: {
      shape: 'spline',
      color: lineColor || '#777AF7',
      width: 2
    },
    fill: 'tonexty',
    fillcolor: fillColor || '#F2F2FF',
    mode: 'lines',
    name: 'Provider'
  },
  /* by default plotly removes the chart padding when there is a fill
  to zero trace which causes the line to cut off so here we add invisble
  trace to fix this unwanted behavior */
  {
    x: [last(data)?.x],
    y: [0],
    line: {
      color: 'transparent'
    }
  }
];

const reducer = (previousValue: number, currentValue: number) =>
  previousValue + currentValue;

function AreaChart({
  data,
  yRange,
  layout,
  fillColor,
  lineColor,
  ...restProps
}: AreaChartProps) {
  const normalizedData = useMemo(() => {
    return mapData(data, fillColor, lineColor);
  }, [data, fillColor, lineColor]);

  const isYDataSum0 = normalizedData[0].y.reduce(reducer) === 0;

  return (
    <div>
      <Plot
        data={normalizedData}
        config={{
          displayModeBar: false,
          showAxisDragHandles: false
        }}
        layout={{
          width: 130,
          height: 40,
          plot_bgcolor: 'transparent',
          paper_bgcolor: 'transparent',
          autosize: true,
          xaxis: {
            showgrid: false,
            fixedrange: true,
            showticklabels: false
          },
          hovermode: false,
          yaxis: {
            showgrid: false,
            fixedrange: true,
            zeroline: false,
            range: yRange || (isYDataSum0 && AREACHART_Y_RANGE)
          },
          showlegend: false,
          margin: { b: 0, t: 0, l: 0, r: 0 },
          ...layout
        }}
        {...restProps}
      />
    </div>
  );
}

export default AreaChart;
