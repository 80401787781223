import keymirror from 'keymirror';

export const dialogTypes = keymirror({
  // Confirm plan academic modal
  CONFIRM_PLAN_ACADEMIC_MODAL: null,
  // Error on academic modal submission
  ERROR_ACADEMIC_MODAL: null,
  // Error license verification modal
  ERROR_LICENSE_VERIFICATION_MODAL: null,
  // Claim invite
  CLAIM_INVITE_MODAL: null,
  // Team invite code
  TEAM_INVITE_CODE_MESSAGE: null,
  // Login modal
  LOGIN_MODAL: null,
  // Registration modal
  REGISTRATION_MODAL: null,
  // Error on create project
  CATCH_ERROR_CREATE_PROJECT: null,
  // Error on edit project
  CATCH_ERROR_EDIT_PROJECT: null,
  // Catch error delete experiment(s)
  CATCH_ERROR_DELETE_EXPERIMENT: null,
  // Open and close modal that allows user to select histograms
  SELECT_HISTOGRAMS_MODAL: null,
  // Save prompt for saving experiment filter
  SAVE_EXPERIMENT_FILTER_SEGMENT_MODAL: null,
  // Prompt to delete a segment item
  DELETE_SEGMENT_LIST_ITEM: null,
  // Prompt to open the forgot password modal
  FORGOT_PASSWORD: null,
  // Catch error when saving chart tab view
  CATCH_ERROR_SAVE_TEMPLATE: null,
  // Catch error when deleting chart tab view
  CATCH_ERROR_DELETE_TEMPLATE: null,
  // Catch error when setting project view
  CATCH_ERROR_SET_PROJECT_TEMPLATE: null,
  // Catch error when fetching project view
  CATCH_ERROR_FETCH_PROJECT_TEMPLATE: null,
  // Catch error when fetching chart tab user views
  CATCH_ERROR_FETCH_USER_TEMPLATES: null,
  // Catch error when saving new view with an existing view name
  CATCH_ERROR_SAVE_DUPLICATE_TEMPLATE_NAME: null,
  // Catch error when user login fails
  CATCH_ERROR_LOGIN: null,
  // Catch error when user is changing password
  CATCH_ERROR_CHANGE_PASSWORD: null,
  // Catch error when user is changing email
  CATCH_ERROR_CHANGE_EMAIL: null,
  // Catch error when user is changing profile picture
  CATCH_ERROR_CHANGE_PROFILE_PICTURE: null,
  // Catch error in notes file upload
  CATCH_NOTES_FILE_UPLOAD_ERROR: null,
  // Payment plan does not match the action
  PAYMENT_PLAN_NOT_MATCH_ACTION_PERMISSIONS: null,
  // Catch error when fetching git details for reproduce button
  CATCH_ERROR_FETCH_GIT_DETAILS: null,
  // Save view for project
  SAVE_PROJECT_DASHBOARD_TEMPLATE: null,
  // Confirm delete view
  CONFIRM_DELETE_DASHBOARD_TEMPLATE: null,
  // Confirm delete experiment tag
  CONFIRM_DELETE_EXPERIMENT_TAG: null,
  // Error fetching tags associated with a user's project
  CATCH_ERROR_FETCH_TAGS_FOR_PROJECT: null,
  // Error updating tags for an experiment
  CATCH_ERROR_UPDATING_TAGS_FOR_EXPERIMENT: null,
  // Catch error when user tries to delete a tag from project
  CATCH_ERROR_DELETE_TAG_FROM_PROJECT: null,
  // Catch error when fetching assets for an experiment
  CATCH_ERROR_FETCH_ASSETS_FOR_EXPERIMENT: null,
  // Image gallery modal
  IMAGE_GALLERY_MODAL: null,
  // Error archiving experiments
  CATCH_ERROR_ARCHIVED_EXPERIMENTS: null,
  // Error deleting experiments permanently
  CATCH_ERROR_DELETE_EXPERIMENTS_PERMANENTLY: null,
  // Error restoring experiments
  CATCH_ERROR_RESTORE_EXPERIMENTS_FROM_ARCHIVE: null,
  // Confirm delete experiment permanently
  CONFIRM_DELETE_EXPERIMENT_PERMANENTLY: null,
  // Confirm archive experiment which is temporary
  CONFIRM_ARCHIVE_EXPERIMENT: null,
  // Confirm delete project and all experiments in it
  CONFIRM_DELETE_PROJECT_WITH_ALL_EXPERIMENTS: null,
  // Modal for editing table columns
  CUSTOMIZE_COLUMNS_MODAL: null,
  // Modal for editing table columns in model registry
  CUSTOMIZE_MODEL_REGISTRY_COLUMNS_MODAL: null,
  // Modal for editing table grouping
  GROUPING_MODAL: null,
  // Modal that allows users to copy/share experiment link
  SHARE_EXPERIMENT_MODAL: null,
  // Modal that shows user how to add experiment
  HOW_TO_ADD_EXPERIMENT_MODAL: null,
  // Modal that allows users to move experiments to different project
  MOVE_EXPERIMENT_MODAL: null,
  // Error fetching workspace projects
  ERROR_FETCHING_WORKSPACE_PROJECTS: null,
  // Subscribe modal
  SUBSCRIBE_MODAL: null,
  // Error sending post signup form
  CATCH_ERROR_POST_SIGNUP: null,
  CONFIRM_STOP_EXPERIMENT: null,
  CATCH_ERROR_SEND_RPC: null,
  CATCH_ERROR_API: null,
  SAVE_TEMPLATE_MODAL: null,
  // Error getting opt-in notification
  ERROR_FETCHING_OPTIN_NOTIFICATION: null,

  ERROR_FETCHING_OPTIN_MPM_NOTIFICATION: null,

  CATCH_ERROR_DELETE_WORKSPACE: null,
  ADD_VISUALIZATION_MODAL: null,
  CONFIRM_SAVE_VISUALIZATION_TEMPLATE: null,
  CREATE_PROJECT_MODAL: null,
  REGISTER_MODEL_MODAL: null,
  MANAGE_MODEL_MODAL: null,
  CONFIRM_DELETE_MODEL: null,
  CONFIRM_MOVE_MODEL: null,
  // Custom panel updates available
  PANEL_UPDATES_AVAILABLE_MODAL: null,
  CONFIRM_REMOVE_STARTER_PROJECT: null,
  IMPORT_FILE_MODAL: null,
  CONFIRM_UPDATE_PANEL_TEMPLATE: null,
  // Share panel
  SHARE_PANEL_MODAL: null,
  ERROR_MESSAGE_PARAM_MODAL: null,
  CONFIRM_DELETE_CUSTOM_METRIC: null,
  CATCH_ERROR_DUPLICATE_ENTRY: null,
  EXPORT_TABLE_MODAL: null,
  CATCH_ERROR_FAILED_JSON_STRINGIFY: null,
  CONFIRM_DELETE_REPORT: null,
  DUPLICATE_ITEM_MODAL: null,
  DOWNLOAD_REPORT_MODAL: null,
  SHARE_REPORT_MODAL: null,
  EMBEDDED_CODE_REPORT_MODAL: null,
  CATCH_ERROR_WAVESURFER_AUDIO: null,
  CONFIRM_DELETE_TAG_FROM_EXPERIMENT: null,
  EDIT_ARTIFACT_MODAL: null,
  EDIT_ARTIFACT_VERSION_MODAL: null,
  EXPORT_FILE_MODAL: null,
  CONFIRM_DELETE_MODAL: null,
  CONFIRM_STATUS_CHANGE: null,
  // Alert Rules Page
  CONFIRM_DELETE_ALERT_RULE: null,
  CONFIRM_MODEL_REGISTRY_COLUMNS_ORDER_CHANGED: null,
  CUSTOMIZE_MODEL_REGISTRY_COLUMNS_ERRORS: null,
  MOVE_PROJECT_ERROR: null,
  ERROR_ALERT_CREATED: null,
  SERVICE_ACCOUNT_LIST_FAILED: null,
  // Metric has been successfully created
  SUCCESS_CUSTOM_METRIC_CREATED: null,
  ERROR_CUSTOM_METRIC_CREATED: null,
  CONFIRM_DELETE_METRIC: null,
  ERROR_DELETE_METRIC: null,
  CONFIRM_EDIT_METRIC: null,
  ERROR_EDIT_METRIC: null,
  CONFIRM_CHANGE_MODEL_APPROVAL_STATUS: null,
  // Segment has been successfully created
  SUCCESS_SEGMENT_CREATED: null,
  ERROR_SEGMENT_CREATED: null,
  ERROR_SEGMENT_EDITED: null,
  CONFIRM_DELETE_SEGMENT: null,
  CONFIRM_EDIT_SEGMENT: null,
  // Model pipeline
  SUCCESS_MODEL_PIPELINE_CREATED: null,
  CONFIRM_DELETE_PIPELINE: null,
  ERROR_PIPELINE_CREATED: null,
  ERROR_SAVE_GRAPHICS_COMPARE_PANEL_TO_DEFAULT_VIEW: null,
  // Changed Data Drift Baseline
  SUCCESS_BASELINE_DATA_DRIFT_CHANGED: null
});

export const snackbarTypes = keymirror({
  // Successful snackbar dialog
  SUCCESS_SNACKBAR_DIALOG: null,
  // Open snackbar for rest api key generation
  SUCCESS_KEY_GEN: null,
  // User successfully edits project
  SUCCESS_EDIT_PROJECT: null,
  // User failed payment issue
  FAILED_PAYMENT_ISSUE: null,
  // User failed payment issue
  FAILED_MODEL_VERSION_REASSIGNMENT: null,
  // User has no charts anymore, but has added panels
  SUCCESS_INTEGRATED_CHARTS_TO_PANELS: null,
  // User successfully saved chart tab view
  SUCCESS_SAVE_TEMPLATE: null,
  // User successfully deletes chart tab view
  SUCCESS_DELETE_CHART_TAB_TEMPLATE: null,
  // User successfully changes email
  SUCCESS_CHANGE_EMAIL: null,
  // User successfully changes profile picture
  SUCCESS_CHANGE_PROFILE_PICTURE: null,
  // User successfully copied api key
  SUCCESS_COPIED_API_KEY: null,
  // User successfully deleted a tag from a project
  SUCCESS_DELETE_TAG_FROM_PROJECT: null,
  // Copy image file path to clipboard
  COPY_IMAGE_PATH_TO_CLIPBOARD: null,
  // User successfully archived experiments
  SUCCESS_ARCHIVED_EXPERIMENTS: null,
  // User successfully deleted experiments permanently
  SUCCESS_DELETED_EXPERIMENTS_PERMANENTLY: null,
  // User successfully restored experiments
  SUCCESS_RESTORED_EXPERIMENTS_FROM_ARCHIVE: null,
  // User successfully deleted project
  SUCCESS_DELETE_PROJECT: null,
  // User successfully copied share link
  SUCCESS_COPIED_SHARE_LINK: null,
  SUCCESS_COPIED_TEXT: null,
  // Failed to cancel payment
  ERROR_CANCEL_PAYMENT_PLAN: null,
  // Catch update organization permissions or user removal errors
  CATCH_ERROR_MANAGE_USERS_IN_WORKSPACE: null,
  CATCH_ERROR_LOADING_QUERY_FILTERS: null,
  SUCCESS_API_RESPONSE: null,
  SUCCESS_REQUEST_MODEL_STATUS_CHANGE: null,
  SUCCESS_MODEL_REGISTRY_DELETE_WEBHOOK: null,
  SUCCESS_MODEL_REGISTRY_EDIT_WEBHOOK: null,
  SUCCESS_MODEL_REGISTRY_CREATE_WEBHOOK: null,
  SUCCESS_DELETE_WEBHOOK: null,
  // Reports
  SUCCESS_SAVE_REPORT: null,
  SUCCESS_ADD_VIEW_TO_REPORT: null,
  // New frontend is available
  FRONTEND_OUT_OF_DATE: null,
  // Charts are available
  CHARTS_AVAILABLE: null,
  // User successfully dismissed alert notifications
  SUCCESS_DISMISSED_ALERT_NOTIFICATIONS: null,
  // The bulk of uses have been successfully invited
  SUCCESS_INVITE_BULK_USERS: null,

  // Service account has been created
  SERVICE_ACCOUNT_CREATED: null,
  // Service account has not been created
  SERVICE_ACCOUNT_NOT_CREATED: null,
  // Regenerated API key for a service account,
  SERVICE_ACCOUNT_REGENERATE_API_KEY: null,
  // Removed a service account,
  SERVICE_ACCOUNT_REMOVED: null,
  // Success when a user has been removed successfully from the list of users
  SUCCESS_REMOVE_USERS_FROM_ORGANIZATION: null,
  // Success when an invitation has been removed successfully from the list of users
  SUCCESS_REMOVE_INVITATION_FROM_ORGANIZATION: null,
  ERROR_INVITE_BULK_USERS: null,
  // Success when a card has been changed
  SUCCESS_PAYMENT_CHANGED: null,
  // Error  when a card hasn't been changed
  ERROR_PAYMENT_CHANGED: null,
  // PAYMENT PLAN HAS BEEN CANCELLED
  CANCEL_PAYMENT_PLAN: null,
  // Error when user can't switch the organization
  ERROR_SWITCH_ORGANIZATION: null,
  // Error when user can't switch the organization
  ERROR_CHANGE_PASSWORD: null,
  // successfully moved the project to another workspace
  SUCCESS_MOVED_PROJECT_TO_OTHER_WORKSPACE: null,
  // error moving the project to another workspace
  ERROR_MOVED_PROJECT_TO_OTHER_WORKSPACE: null
});
