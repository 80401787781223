import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import api from '@API/api';

import useProject from '@API/project/useProject';
import { IOrGroupRule } from '@shared/utils/filterHelpers';

const getListFilters = ({ projectId }) => {
  return api.get('query/filters', { projectId });
};

export default function useListFilters() {
  const { data: project } = useProject();
  const projectId = project?.projectId;

  return useQuery(
    ['filters', { projectId }],
    () => getListFilters({ projectId }),
    { enabled: !!projectId }
  );
}

export const IFilterDetails = PropTypes.shape({
  name: PropTypes.string.isRequired,
  filterId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  rulesTree: IOrGroupRule.isRequired,
  created_at: PropTypes.number.isRequired,
  created_by: PropTypes.string.isRequired,
  updated_at: PropTypes.number.isRequired,
  updated_by: PropTypes.string.isRequired
});
