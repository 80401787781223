import React from 'react';
import { Input } from '@DesignSystem/controllers';
import { Box } from '@material-ui/core';

export default function StepThree({ fieldsState, setFieldsState }) {
  return (
    <div>
      <h6>Choose the notification name & description: </h6>
      <Box marginY="20px" width="400px">
        <Input
          height="36px"
          required
          title="Alert name:"
          value={fieldsState.alertName}
          onChange={val =>
            setFieldsState(prev => ({ ...prev, alertName: val }))
          }
        />
        <Box marginTop="15px">
          <Input
            height="117px"
            value={fieldsState.alertDescription}
            onChange={val =>
              setFieldsState(prev => ({ ...prev, alertDescription: val }))
            }
            multiline
            title="Alert description (optional):"
          />
        </Box>
      </Box>
    </div>
  );
}
