import { useQuery } from 'react-query';

import { useActiveWorkspace } from '@shared/hooks';

import { AlertNotification, AlertNotificationAPI } from '@mpm/types';
import api, { QueryConfig } from '@shared/api';
import { alertNotificationTransform } from './useAlertNotifications';

export type AlertNotificationsByIDAPI = {
  alertNotifications: AlertNotificationAPI[];
  total: number;
};

// Hook response
export type AlertNotificationsByIDResponse = {
  alertNotifications: AlertNotification[];
  total: number;
};

type GetAlertNotificationsByIDProps = {
  modelId: string;
  selectedAlertNotificationIds: string[];
  workspaceId: string;
};

type AlertNotificationsByIDParams = {
  modelId: string;
  selectedAlertNotificationIds: string[];
  workspaceId?: string;
};

const getAlertNotificationsByID = async ({
  modelId,
  selectedAlertNotificationIds,
  workspaceId
}: GetAlertNotificationsByIDProps) => {
  const { data } = await api.post<AlertNotificationsByIDAPI>(
    `mpm/${workspaceId}/models/${modelId}/alert-notifications/getById`,
    { alertNotificationIds: selectedAlertNotificationIds }
  );

  return {
    ...data,
    alertNotifications: data.alertNotifications.map(alertNotification =>
      alertNotificationTransform(alertNotification)
    )
  };
};

export function useAlertNotificationsByID(
  params: AlertNotificationsByIDParams,
  config: QueryConfig<AlertNotificationsByIDResponse>
) {
  const activeWorkspace = useActiveWorkspace();

  return useQuery(
    ['alertNotificationsById', { workspaceId: activeWorkspace?.id, ...params }],
    () =>
      getAlertNotificationsByID({
        workspaceId: activeWorkspace?.id,
        ...params
      }),
    {
      enabled: !!activeWorkspace?.id && config.enabled
    }
  );
}
