import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '@ds';

import AddToReportPopover from './AddToReportPopover';
import { DSPlusIcon } from '@ds-icons';

const AddReportButton = ({ canEdit, dashboard }) => {
  const [reportAnchorEl, setReportAnchorEl] = useState(null);

  return (
    <>
      <TextButton
        type="secondary"
        size="medium"
        PrefixIcon={<DSPlusIcon />}
        onClick={event => {
          setReportAnchorEl(event.currentTarget);
        }}
        disabled={!canEdit}
        active={Boolean(reportAnchorEl)}
      >
        Add to Report
      </TextButton>
      <AddToReportPopover
        anchorEl={reportAnchorEl}
        dashboard={dashboard}
        onClose={() => setReportAnchorEl(null)}
      />
    </>
  );
};

AddReportButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dashboard: PropTypes.object.isRequired
};

export default AddReportButton;
