import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import { Button } from '@ds';
import { Input } from '@DesignSystem/controllers';
import CustomSwitch from '@DesignSystem/controllers/Switch/Switch';
import EmailIcon from '@Icons-outdated/EmailIcon.svg';
import { MicrosoftTeamsLogoIcon, SlackLogoIcon } from '@Icons-outdated';

const learnPath =
  '/docs/v2/guides/model-production-monitoring/mpm-ui/#alerts-tab';

export default function StepTwo({
  setActiveStep,
  fieldsState,
  setFieldsState
}) {
  const {
    emailAlertOn,
    slackAlertOn,
    teamsAlertOn,
    slackChannelMail,
    teamsChannelMail
  } = fieldsState;

  useEffect(() => {
    if (!slackAlertOn) {
      setFieldsState(prev => ({
        ...prev,
        slackChannelMail: ''
      }));
    }
    if (!teamsAlertOn) {
      setFieldsState(prev => ({
        ...prev,
        teamsChannelMail: ''
      }));
    }
  }, [slackAlertOn, teamsAlertOn, setFieldsState]);

  return (
    <Box>
      <h6> Choose the notification channels you want to get notified with:</h6>
      <Box marginY={2.25} display="flex" alignItems="center">
        <CustomSwitch
          onChange={() =>
            setFieldsState(prev => ({
              ...prev,
              emailAlertOn: !prev.emailAlertOn
            }))
          }
          checked={emailAlertOn}
        />
        <div className="emailIcon">
          <EmailIcon />
        </div>
        <span>Email alert notification </span>
      </Box>
      <Box marginY={2.25} display="flex" alignItems="center">
        <CustomSwitch
          onChange={() =>
            setFieldsState(prev => ({
              ...prev,
              slackAlertOn: !prev.slackAlertOn
            }))
          }
          checked={slackAlertOn}
        />
        <div className="emailIcon">
          <SlackLogoIcon />
        </div>
        <span>Slack alert notification </span>
      </Box>
      {slackAlertOn && (
        <div className="inputMessageContainer">
          <Input
            title="Slack Channel email address"
            placeholder="Enter email"
            value={slackChannelMail}
            onChange={value =>
              setFieldsState(prev => ({
                ...prev,
                slackChannelMail: value
              }))
            }
            lineHeight="21px"
            height="36px"
          />
          <div className="inputMessageContainer">
            The Slack channel email address can be found by going to the channel
            name, navigating to the channel settings, and choosing the
            `Integrations` tab. <a href={learnPath}> Learn more </a>
          </div>
        </div>
      )}
      <Box marginY={2.25} display="flex" alignItems="center">
        <CustomSwitch
          onChange={() =>
            setFieldsState(prev => ({
              ...prev,
              teamsAlertOn: !prev.teamsAlertOn
            }))
          }
          checked={teamsAlertOn}
        />
        <div className="emailIcon">
          <MicrosoftTeamsLogoIcon />
        </div>
        <span>Teams alert notification </span>
      </Box>
      {teamsAlertOn && (
        <div className="inputMessageContainer">
          <Input
            title="Teams Channel email address"
            placeholder="Enter email"
            value={teamsChannelMail}
            onChange={value =>
              setFieldsState(prev => ({
                ...prev,
                teamsChannelMail: value
              }))
            }
            lineHeight="21px"
            height="36px"
          />
          <div className="inputMessageContainer">
            The Teams channel email address can be found by going to the channel
            name, selecting More options (...), and choosing ‘Get email
            address’. <a href={learnPath}> Learn more </a>
          </div>
        </div>
      )}
      <div className="stepTwoButtonWrapper">
        <Button onClick={() => setActiveStep(prev => prev + 1)}>Next</Button>
      </div>
    </Box>
  );
}
