import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

type DeleteSegmentMutationProps = {
  segmentId: string;
};

export function useDeleteSegmentMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ segmentId }: DeleteSegmentMutationProps) => {
      return api.delete(
        `mpm/${activeWorkspace?.id}/models/${modelId}/segments/${segmentId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'mpm-segments',
          { workspaceId: activeWorkspace?.id, modelId }
        ]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_DELETE_SEGMENT,
            'The segment was successfully deleted.'
          )
        );
      }
    }
  );
}
