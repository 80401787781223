import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';

import {
  COMPARE_EXPERIMENT_1_KEY,
  COMPARE_EXPERIMENT_2_KEY
} from '@experiment-management-shared/constants/experimentConstants';
import { calculateExperimentName } from '@experiment-management-shared/utils/experimentHelpers';
import useQueryParamsForExperiments from '@experiment-details/hooks/useQueryParamsForExperiments';

import noop from 'lodash/noop';
import { DiffSelection } from '@Icons-outdated';

import './CompareContentSelector.scss';
import { Select } from '@DesignSystem/controllers';
import StyledCopyButton from '@shared/components/StyledComponents/StyledCopyButton';
import {
  EXPERIMENT_NUMBER,
  SELECTOR_TYPE
} from '@experiment-details/constants/general';

const CompareContentSelector = ({
  type,
  onExperimentChange,
  experiment1,
  experiment2,
  experiments,
  experiment1FileOptions,
  experiment1FileOption,
  experiment2FileOptions,
  experiment2FileOption,
  onExperimentFileChange,
  disabledFileChange
}) => {
  const [experiment] = experiments;
  const [experiment1OptionForQuery, setExperiment1OptionForQuery] = useState(
    experiment1.experimentKey
  );
  const [experiment2OptionForQuery, setExperiment2OptionForQuery] = useState(
    experiment2.experimentKey
  );

  useQueryParamsForExperiments(
    {
      [COMPARE_EXPERIMENT_1_KEY]: experiment1OptionForQuery,
      [COMPARE_EXPERIMENT_2_KEY]: experiment2OptionForQuery
    },
    [experiment1OptionForQuery, experiment2OptionForQuery],
    experiment.experimentKey
  );

  const experiment1Options = useMemo(() => {
    return experiments.map(e => {
      return {
        label: calculateExperimentName(e),
        value: e.experimentKey,
        disabled: e.experimentKey === experiment2.experimentKey
      };
    });
  }, [experiments, experiment2.experimentKey]);

  const experiment2Options = useMemo(() => {
    return experiments.map(e => {
      return {
        label: calculateExperimentName(e),
        value: e.experimentKey,
        disabled: e.experimentKey === experiment1.experimentKey
      };
    });
  }, [experiments, experiment1.experimentKey]);

  const setExperiment1Option = value => {
    if (value !== experiment1.experimentKey) {
      onExperimentChange(EXPERIMENT_NUMBER.EXPERIMENT_1, value);
      setExperiment1OptionForQuery(value);
    }
  };

  const setExperiment2Option = value => {
    if (value !== experiment2.experimentKey) {
      onExperimentChange(EXPERIMENT_NUMBER.EXPERIMENT_2, value);
      setExperiment2OptionForQuery(value);
    }
  };

  const setExperiment1FileNameOption = value => {
    if (value !== experiment1FileOption.value) {
      onExperimentFileChange(
        EXPERIMENT_NUMBER.EXPERIMENT_1,
        experiment1FileOptions.find(option => option.value === value)
      );
    }
  };

  const setExperiment2FileNameOption = value => {
    if (value !== experiment2FileOption.value) {
      onExperimentFileChange(
        EXPERIMENT_NUMBER.EXPERIMENT_2,
        experiment2FileOptions.find(option => option.value === value)
      );
    }
  };

  const renderFileNameValue = option => {
    return option?.shortLabel || option?.label;
  };

  const renderStandardSelector = () => {
    return (
      <>
        <div className="content-selector-wrapper">
          <Select
            onChange={setExperiment1Option}
            options={experiment1Options}
            value={experiment1.experimentKey}
          />
        </div>
        <div className="content-selector-wrapper">
          <Select
            onChange={setExperiment2Option}
            options={experiment2Options}
            value={experiment2.experimentKey}
          />
        </div>
      </>
    );
  };

  const renderOutlinedSelector = () => {
    return (
      <>
        <div className="content-selector-wrapper">
          <div className="select-wrapper">
            <div className="selector-label">Experiment</div>
            <Select
              onChange={setExperiment1Option}
              options={experiment1Options}
              value={experiment1.experimentKey}
              variant="outlined"
            />
          </div>
          <div className="select-wrapper">
            <div className="selector-label">File</div>
            <Select
              onChange={setExperiment1FileNameOption}
              options={experiment1FileOptions}
              value={experiment1FileOption.value}
              disabled={disabledFileChange}
              renderSelectedValue={renderFileNameValue}
              variant="outlined"
            />
            <div className="compare-copy-button-container">
              <StyledCopyButton
                text={experiment1FileOption.fullLabel}
                tooltipContent="Copy full filename"
                disabled={
                  disabledFileChange || !experiment1FileOption.fullLabel
                }
              />
            </div>
          </div>
        </div>
        <div className="compare-icon">
          <DiffSelection />
        </div>
        <div className="content-selector-wrapper">
          <div className="select-wrapper">
            <div className="selector-label">Experiment</div>
            <Select
              onChange={setExperiment2Option}
              options={experiment2Options}
              value={experiment2.experimentKey}
              variant="outlined"
            />
          </div>
          <div className="select-wrapper">
            <div className="selector-label">File</div>
            <Select
              onChange={setExperiment2FileNameOption}
              options={experiment2FileOptions}
              value={experiment2FileOption.value}
              disabled={disabledFileChange}
              renderSelectedValue={renderFileNameValue}
              variant="outlined"
            />
            <div className="compare-copy-button-container">
              <StyledCopyButton
                text={experiment2FileOption.fullLabel}
                tooltipContent="Copy full filename"
                disabled={
                  disabledFileChange || !experiment2FileOption.fullLabel
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const isStandardType = type === SELECTOR_TYPE.STANDARD;
  const isOutlinedType = type === SELECTOR_TYPE.OUTLINED;

  const rootClasses = {
    standard: isStandardType,
    outlined: isOutlinedType
  };

  return (
    <div className={cx('compare-content-selector', rootClasses)}>
      {isStandardType && renderStandardSelector()}
      {isOutlinedType && renderOutlinedSelector()}
    </div>
  );
};

CompareContentSelector.defaultProps = {
  onExperimentChange: noop,
  onExperimentFileChange: noop,
  disabledFileChange: false,
  type: SELECTOR_TYPE.STANDARD,
  experiment1FileOptions: [],
  experiment1FileOption: {},
  experiment2FileOptions: [],
  experiment2FileOption: {}
};

CompareContentSelector.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  experiment1: IExperimentDetails.isRequired,
  experiment2: IExperimentDetails.isRequired,
  onExperimentChange: PropTypes.func,
  experiment1FileOptions: PropTypes.array,
  experiment1FileOption: PropTypes.object,
  experiment2FileOptions: PropTypes.array,
  experiment2FileOption: PropTypes.object,
  onExperimentFileChange: PropTypes.func,
  disabledFileChange: PropTypes.bool,
  type: PropTypes.oneOf([SELECTOR_TYPE.STANDARD, SELECTOR_TYPE.OUTLINED])
};

export default CompareContentSelector;
