import React, { useEffect, useMemo, useState } from 'react';
import { first, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Column } from '@devexpress/dx-react-grid';

import { RuleGroup } from '@experiment-management-shared/types';
import { Table } from '@DesignSystem/tables';
import SmallLoader from '@shared/components/SmallLoader';
import { generateEmptyRulesTree } from '@shared/utils/filterHelpers';
import { rulesTreeToMPMPredicates } from '@mpm-druid/utils';
import { useModels } from '@mpm-druid/api';
import { getActiveWorkspaceName } from '@/reducers/ui/workspaceUiReducer';
import TextCell from '@shared/components/TableCells/TextCell';
import {
  AreaChartCell,
  LinkCell,
  OpenNotificationsCell
} from '@mpm-druid/components/Cells';
import ModelsHeader from './ModelsHeader';
import { RenderEmptyState } from './RenderEmptyState';
import {
  DEFAULT_MODEL_SORTING,
  MODEL_COLUMNS_NAMES,
  MODEL_COLUMNS,
  MODEL_COLUMN_WIDTHS,
  MODEL_DISABLED_COLUMNS,
  MODEL_LEFT_COLUMNS,
  MODEL_PAGE_SIZES,
  MODEL_SERVER_COLUMNS_NAMES,
  NO_PADDING_COLUMNS
} from '@mpm-druid/constants';
import { ModelType, QueryRule, SortingColumnType } from '@mpm-druid/types';

const mapDataToRows = (models: ModelType[], currentWorkspaceName: string) => {
  const modelRows =
    models?.map((model: ModelType) => {
      return {
        id: model.id,
        currentWorkspaceName,
        alerts: model.numberOfAlerts,
        modelName: model.name || 'no name available',
        latestVersion: first(model.versions),
        latestPrediction:
          (model.latestPrediction &&
            new Date(model.latestPrediction).toLocaleString()) ||
          '',
        createdAt:
          (model.createdAt && new Date(model.createdAt).toLocaleString()) || '',
        numberOfPredictions: model.numberOfPredictions
      };
    }) || [];

  return [...modelRows];
};

const MODELS_PAGE_SIZE = 1000;

export const ModelProductionMonitoringHome = () => {
  const [currentPage, setCurrentPage] = useState(0);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState<ReturnType<typeof mapDataToRows>>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selection, setSelection] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnWidths, setColumnWidths] = useState(MODEL_COLUMN_WIDTHS);
  const [columnSorting, setColumnSorting] = useState(DEFAULT_MODEL_SORTING);
  const [query, setQuery] = useState<QueryRule>({
    rulesTree: generateEmptyRulesTree(),
    segmentId: ''
  });

  const currentWorkspaceName = useSelector(getActiveWorkspaceName);

  const dataTypes = [
    {
      cols: [MODEL_COLUMNS_NAMES.numberOfPredictions],
      cell: ({
        value,
        column
      }: {
        value: { x: string; y: number }[];
        column: Column;
      }) => <AreaChartCell value={value} column={column} />
    },
    {
      cols: [MODEL_COLUMNS_NAMES.modelName],
      cell: ({
        value,
        row
      }: {
        value: string;
        row: { currentWorkspaceName: string; id: string };
      }) => (
        <LinkCell
          value={value}
          to={`/${row.currentWorkspaceName}/model-production-monitoring/${row.id}`}
        />
      )
    },
    { cols: [MODEL_COLUMNS_NAMES.alerts], cell: OpenNotificationsCell },
    {
      cols: [
        MODEL_COLUMNS_NAMES.createdAt,
        MODEL_COLUMNS_NAMES.latestPrediction,
        MODEL_COLUMNS_NAMES.latestVersion
      ],
      cell: TextCell
    }
  ];

  const handleFilterQueryChange = (newQuery: QueryRule) => {
    setCurrentPage(0);
    setQuery(newQuery);
  };

  const sortColumn = MODEL_SERVER_COLUMNS_NAMES[columnSorting[0].columnName];
  const order = columnSorting[0].direction;

  const { data, isLoading, isError, isFetching, isPreviousData } = useModels(
    {
      page: currentPage + 1,
      pageSize: MODELS_PAGE_SIZE,
      sortColumn,
      order,
      predicates: query?.rulesTree
        ? rulesTreeToMPMPredicates(query.rulesTree as RuleGroup)
        : []
    },
    {
      keepPreviousData: true,
      refetchOnMount: true
    }
  );

  const handlePageChange = (num: number) => {
    setCurrentPage(num);
  };

  useEffect(() => {
    if (window.innerWidth < 1800) {
      setColumnWidths(MODEL_COLUMN_WIDTHS);
    } else {
      setColumnWidths([MODEL_COLUMN_WIDTHS[0]]);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(selection)) {
      setActiveTabIndex(0);
    }
  }, [selection]);

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (data) {
      const { models = [] } = data;
      const tableRows = mapDataToRows(models, currentWorkspaceName);
      setRows(tableRows);
    }
  }, [data, currentWorkspaceName]);

  const onSortingChange = (newSort: SortingColumnType[]) => {
    setColumnSorting(newSort);
  };

  const { data: selectionRowsData, isLoading: isLoadingSelection } = useModels(
    {
      modelIds: selection
    },
    {
      keepPreviousData: true,
      enabled: activeTabIndex === 1
    }
  );

  const rowsFilteredForActiveTab = useMemo(() => {
    if (activeTabIndex === 1 && selectionRowsData) {
      return mapDataToRows(selectionRowsData.models, currentWorkspaceName);
    }

    return rows;
  }, [activeTabIndex, rows, selectionRowsData, currentWorkspaceName]);

  const DISABLED_MODEL_COLUMN_SORTING = MODEL_COLUMNS.filter(
    column => column.hideForSorting
  ).map(column => ({
    columnName: column.name,
    sortingEnabled: false
  }));

  if (isLoading || (!isError && !data))
    return (
      <div id="table">
        <SmallLoader
          primaryMessage="Loading..."
          secondaryMessage={<span>Fetching models</span>}
        />
      </div>
    );

  if (isError) return <h1>Error while fetching the models.</h1>;

  return (
    <Box display="flex" flexDirection="column" bgcolor="white">
      <ModelsHeader
        setActiveTabIndex={setActiveTabIndex}
        activeTabIndex={activeTabIndex}
        columns={MODEL_COLUMNS}
        columnSorting={columnSorting}
        selection={selection}
        onSortingChange={onSortingChange}
        onFilterChange={handleFilterQueryChange}
        query={query}
      />
      <Table
        renderEmptyState={RenderEmptyState}
        columns={MODEL_COLUMNS}
        rowHeight="58px"
        isFetching={(isFetching && isPreviousData) || isLoadingSelection}
        shouldHighlightLinks={true}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        removeHeaderPadding={true}
        noPaddingColumns={NO_PADDING_COLUMNS}
        maxHeight={265}
        rows={rowsFilteredForActiveTab}
        dataTypes={dataTypes}
        leftColumns={MODEL_LEFT_COLUMNS}
        rowIdKey="id"
        totalRowCount={activeTabIndex === 0 ? data?.total : selection.length}
        sortingConfig={{
          isDisabled: false,
          columnSorting,
          onSortingChange,
          disabledColumns: DISABLED_MODEL_COLUMN_SORTING
        }}
        paginationConfig={{
          pageNumber: currentPage,
          isDisabled: true,
          isServerSidePagination: activeTabIndex !== 1,
          pageSizes: MODEL_PAGE_SIZES,
          onPageNumberChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange
        }}
        columnWidthsConfig={{
          isDisabled: false,
          columnWidths,
          disabledColumns: MODEL_DISABLED_COLUMNS,
          onColumnWidthsChange: setColumnWidths
        }}
        columnOrderConfig={{
          isDisabled: false,
          columnOrder,
          onColumnOrderChange: setColumnOrder
        }}
        selectionConfig={{
          selection,
          isDisabled: false,
          onSelectionChange: setSelection
        }}
        setActiveTabIndex={setActiveTabIndex}
        activeTabIndex={activeTabIndex}
      />
    </Box>
  );
};
