import experimentApi from '@experiment-management-shared/api/experimentApi';
import { useQuery } from 'react-query';

const useLatestExperiment = (config = {}) => {
  return useQuery(
    ['latest-experiment'],
    async () => {
      const result = await experimentApi.fetchLatestExperiment();

      return result?.data;
    },
    config
  );
};

export default useLatestExperiment;
