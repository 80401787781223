// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoundCheckbox-module__dsRoundCheckbox--BaaWo{padding:0 !important}.RoundCheckbox-module__dsRoundCheckboxContainer--Paed6{opacity:0;width:18px;height:18px;border-radius:50%;border:1px solid var(--primary-color);right:11px;bottom:11px;position:absolute}.RoundCheckbox-module__dsRoundCheckboxContainerActive--uQtm3{background:var(--primary-color);opacity:1}.RoundCheckbox-module__dsRoundCheckbox--BaaWo input{width:18px;height:18px;margin:0}.RoundCheckbox-module__dsRoundCheckbox--BaaWo svg{width:14px;height:14px;fill:#fff}.RoundCheckbox-module__dsRoundCheckbox--BaaWo .MuiIconButton-label{margin-top:-13px;margin-left:1px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsRoundCheckbox": `RoundCheckbox-module__dsRoundCheckbox--BaaWo`,
	"dsRoundCheckboxContainer": `RoundCheckbox-module__dsRoundCheckboxContainer--Paed6`,
	"dsRoundCheckboxContainerActive": `RoundCheckbox-module__dsRoundCheckboxContainerActive--uQtm3`
};
module.exports = ___CSS_LOADER_EXPORT___;
