import React from 'react';
import ListItem from '@ds/lists/ListItem/ListItem';
import useMiddleTruncatedLabel from './hooks/useTruncatedLabel';
import ConditionalTooltip from '@design-system/components/shared/ConditionalTooltip/ConditionalTooltip';
import { TooltipPlacementType } from '@design-system/types';

type ExtraOptionItemProps = {
  label: string;
  secondary?: string;
  isActive: boolean;
  isDisabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  truncateMiddle: boolean;
  maxWidth?: number;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacementType;
};
const ExtraOptionItem: React.FC<ExtraOptionItemProps> = ({
  label,
  secondary,
  isActive,
  isDisabled,
  onClick,
  truncateMiddle,
  maxWidth,
  tooltipText,
  tooltipPlacement
}) => {
  const { isTextTruncated, truncatedValue } = useMiddleTruncatedLabel({
    enabled: truncateMiddle,
    text: label,
    maxWidth
  });

  let tooltipContent = tooltipText;
  if (isTextTruncated) {
    tooltipContent = label;
  }

  return (
    <ListItem
      active={isActive}
      disabled={isDisabled}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
    >
      <ConditionalTooltip
        content={tooltipContent}
        placement={tooltipPlacement}
        wrapperDisplay="block"
      >
        <ListItem.Text primary={truncatedValue} secondary={secondary} />
      </ConditionalTooltip>
    </ListItem>
  );
};

export default ExtraOptionItem;
