import usePathname from '@shared/hooks/usePathname';
import { matchPath } from 'react-router';

export default function useWorkspaceName() {
  const pathname = usePathname();

  const route = matchPath(pathname, {
    path: '/:workspace'
  });

  return route?.params?.workspace ?? '';
}
