import { useMemo } from 'react';
import { getHighlightProperties } from '@experiment-management-shared/components/Charts/Legends/helpers';
import useBaseChartTraceHighlight from '@experiment-management-shared/hooks/useBaseChartTraceHighlight';
import {
  PanelHoverDataSource,
  PanelTrace,
  TooltipDataPoint
} from '@experiment-management-shared/types';

type UseScatterChartTraceHighlightParams = {
  data: PanelTrace[];
  hoveredPoint?: TooltipDataPoint;
};

const useScatterChartTraceHighlight = ({
  data,
  hoveredPoint = undefined
}: UseScatterChartTraceHighlightParams) => {
  const { hoveredData, updateHoveredData } = useBaseChartTraceHighlight({
    hoveredPoint,
    tracesNumber: data?.length ?? 0
  });

  const dataWithHighlight = useMemo(() => {
    if (!hoveredData.source) return data;

    return data.map(trace => {
      const map: Record<PanelHoverDataSource, string[]> = {
        global: ['experimentKey'],
        table: ['experimentKey'],
        chart: ['experimentKey'],
        legend: ['experimentKey']
      };

      const keys = map[hoveredData.source as PanelHoverDataSource] ?? [];

      return keys.length > 0
        ? {
            ...trace,
            ...getHighlightProperties(trace, hoveredData, keys)
          }
        : trace;
    });
  }, [data, hoveredData]);

  return {
    dataWithHighlight,
    hoveredData,
    updateHoveredData
  };
};

export default useScatterChartTraceHighlight;
