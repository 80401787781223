import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, TextButton } from '@ds';

import useWorkspaceUsersPermissionsUpdateMutation from '@account-settings/api/useWorkspaceUsersPermissionsUpdateMutation';
import Typography from '@material-ui/core/Typography';
import useRemoveMembersMutation, {
  handleRemoveUsersResponseMsg
} from '@API/workspace/useRemoveMembersMutation';
import { useDispatch } from 'react-redux';

import { getUserUniqueName } from '@shared/utils/permissions';
import { useHistory } from 'react-router';
import ManageUsersModalFooterResetText from './ManageUsersModalFooterResetText';
import workspaceActions from '@/actions/workspaceActions';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

import classNames from './ManageUsersModalFooter.module.scss';
import { selectDefaultWorkspaceInOrganization } from '@/reducers/ui/workspaceUiReducer';

const WORKSPACES_URL = '/account-settings/workspaces';

const ManageUsersModalFooter = ({
  resetChangesForRoles,
  permissions,
  changedPermissions,
  usernamesOfUsersWithChangedPermissions,
  removedUsersUsernames,
  workspace,
  activeWorkspace,
  workspaces,
  currentUser,
  ifCurrentWsDefault
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const removeMembersMutation = useRemoveMembersMutation();
  const workspaceUserPermissionsUpdateMutation = useWorkspaceUsersPermissionsUpdateMutation(
    workspace.workspaceId
  );

  useEffect(() => {
    const ifUserStillInActiveWorkspace = workspaces?.find(
      workspaceIns => workspaceIns.name === activeWorkspace?.name
    );

    if (!ifUserStillInActiveWorkspace) {
      const nextActiveWorkspace = selectDefaultWorkspaceInOrganization(
        workspaces
      );

      if (nextActiveWorkspace?.name) {
        const currentUrlMatchesWorkspacesPage = history.location?.pathname?.match(
          WORKSPACES_URL
        );
        dispatch(
          workspaceActions.switchTeam(nextActiveWorkspace?.name, false, {
            url: currentUrlMatchesWorkspacesPage && WORKSPACES_URL,
            showDialog: false
          })
        );
      }
    }
  }, [workspaces]);

  const onReset = useCallback(() => {
    resetChangesForRoles();
  }, [permissions]);

  const onSave = useCallback(async () => {
    let removedResults = null;
    let ifErrorsOccurDuringSaving = false;
    const messagesForSnackbar = [];
    const allChangedPermissions = changedPermissions.filter(({ userName }) =>
      usernamesOfUsersWithChangedPermissions.includes(userName)
    );

    if (removedUsersUsernames?.length) {
      try {
        // for some reason BE request for remove users is very long, so I added some saving indicator
        // that user cannot click on save button several times while we are saving results
        removedResults = await removeMembersMutation.mutateAsync({
          members: removedUsersUsernames,
          workspaceId: workspace.workspaceId,
          workspaceName: workspace.workspaceName
        });
      } finally {
        if (Object.keys(removedResults).length) {
          const messagesForRemoval = handleRemoveUsersResponseMsg({
            ...(removedResults || {}),
            workspaceName: workspace.workspaceName
          });

          if (
            removedResults?.noAlternateWorkaspcesUsers?.length ||
            removedResults?.notRemovedUsers?.length
          ) {
            ifErrorsOccurDuringSaving = true;
          }

          messagesForSnackbar.push(...messagesForRemoval);

          // if user was removed from his active workspace we need to change active workspace
          if (
            removedResults?.removedAmount &&
            !removedResults?.noAlternateWorkaspcesUsers?.length &&
            workspace.workspaceName === activeWorkspace?.name &&
            removedUsersUsernames.includes(getUserUniqueName(currentUser))
          ) {
            messagesForSnackbar.push(
              `You have removed user ${getUserUniqueName(
                currentUser
              )} from their ${
                ifCurrentWsDefault ? 'default' : 'active'
              } workspace. We will automatically reassign them to another ${
                ifCurrentWsDefault ? 'default' : ''
              } workspace.`
            );
          }
        }
      }
    }

    dispatch(workspaceActions.setManageWorkspaceModalState({ open: false }));
    if (allChangedPermissions?.length) {
      try {
        await workspaceUserPermissionsUpdateMutation.mutateAsync({
          usersPermissions: allChangedPermissions
        });
        messagesForSnackbar.push(
          'Permissions for workspace were successfully updated.'
        );
      } catch (err) {
        ifErrorsOccurDuringSaving = true;
        messagesForSnackbar.push('Permissions for workspace were not updated.');
      }
    }

    const alertsCb = !ifErrorsOccurDuringSaving
      ? alertsUtil.openSnackbarDialog
      : alertsUtil.openErrorDialog;

    dispatch(
      alertsCb(
        !ifErrorsOccurDuringSaving
          ? snackbarTypes.SUCCESS_SNACKBAR_DIALOG
          : snackbarTypes.CATCH_ERROR_MANAGE_USERS_IN_WORKSPACE,
        messagesForSnackbar.join('\n'),
        ifErrorsOccurDuringSaving && 'Warning'
      )
    );
  }, [changedPermissions, permissions, workspaces, removedUsersUsernames]);

  return (
    <div className={classNames.manageUsersModalFooter}>
      {usernamesOfUsersWithChangedPermissions?.length ||
      removedUsersUsernames?.length ? (
        <Typography variant="text2">
          <ManageUsersModalFooterResetText
            usersChanged={usernamesOfUsersWithChangedPermissions}
            usersRemoved={removedUsersUsernames}
          />
        </Typography>
      ) : null}
      <TextButton
        onClick={onReset}
        size="large"
        className={classNames.resetButton}
        disabled={
          (!usernamesOfUsersWithChangedPermissions?.length &&
            !removedUsersUsernames?.length) ||
          removeMembersMutation.status === 'loading'
        }
      >
        Reset
      </TextButton>
      <Button
        onClick={onSave}
        className={classNames.saveButton}
        size="large"
        disabled={
          (!usernamesOfUsersWithChangedPermissions?.length &&
            !removedUsersUsernames?.length) ||
          removeMembersMutation.status === 'loading'
        }
      >
        {removeMembersMutation.status === 'loading' ? 'Saving ...' : 'Save'}
      </Button>
    </div>
  );
};

ManageUsersModalFooter.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string
  }).isRequired,
  changedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      permissionName: PropTypes.string,
      permissionValue: PropTypes.string
    })
  ),
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      permissionName: PropTypes.string,
      permissionValue: PropTypes.string
    })
  ),
  usernamesOfUsersWithChangedPermissions: PropTypes.arrayOf(PropTypes.string),
  ifCurrentWsDefault: PropTypes.bool.isRequired,
  removedUsersUsernames: PropTypes.arrayOf(PropTypes.string),
  resetChangesForRoles: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    workspaceName: PropTypes.string,
    workspaceId: PropTypes.string
  }).isRequired,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      isDefault: PropTypes.bool
    })
  ),
  activeWorkspace: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  })
};

ManageUsersModalFooter.defaultProps = {
  usernamesOfUsersWithChangedPermissions: [],
  removedUsersUsernames: [],
  changedPermissions: [],
  permissions: [],
  workspaces: [],
  activeWorkspace: {
    name: '',
    id: ''
  }
};

export default ManageUsersModalFooter;
