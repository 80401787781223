import { FETCH_EXPERIMENT_POLLING_LENGTH } from '@experiment-management-shared/constants/experimentConstants';
import api from '@shared/api';
import { OtherParam } from '@shared/types/otherParams';
import { useQuery } from 'react-query';

type UseExperimentOthersOptions = {
  experimentKey: string;
  isActive: boolean;
};

const getExperimentOthers = async ({
  experimentKey,
  signal
}: {
  experimentKey: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get<{
    logOtherList: OtherParam[];
  }>('data-fetch/get/log-other', {
    params: { experimentKey },
    signal
  });

  return data.logOtherList;
};

export default function useExperimentOthers({
  experimentKey,
  isActive
}: UseExperimentOthersOptions) {
  return useQuery(
    ['experiment-others', { experimentKey }],
    ({ signal }) => {
      return getExperimentOthers({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      refetchInterval: isActive ? FETCH_EXPERIMENT_POLLING_LENGTH : false
    }
  );
}
