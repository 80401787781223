import React from 'react';
import { Link } from 'react-router-dom';
import { useExperimentNameFromUrlString } from '../../../hooks/useExperimentNameFromUrlString';
import styles from './LinkCell.module.scss';

interface LinkCellProps {
  urlValue: string;
  children: React.ReactNode;
}

export const LinkCell: React.FC<LinkCellProps> = ({ urlValue, children }) => {
  const experimentName = useExperimentNameFromUrlString(urlValue);
  const content = experimentName || children;

  const url = new URL(urlValue);
  const isExternalLink = url.origin !== window.location.origin;

  if (isExternalLink) {
    return (
      <a
        href={urlValue}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {content}
      </a>
    );
  }

  return (
    <Link
      className={styles.link}
      to={`${url.pathname}${url.search}`}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {content}
    </Link>
  );
};
