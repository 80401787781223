import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import chartHelpers from '@experiment-management-shared/utils/chartHelpers';
import TemplateSelect, {
  BY_COMET_LABEL
} from '@experiment-management-shared/components/TemplateSelect/TemplateSelect';

import useProjectViews from '@API/project/useProjectViews';
import useSelectedProjectView from '@API/project/useSelectedProjectView';
import useDefaultProjectView from '@API/project/useDefaultProjectView';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { dashboardEvents } from '@/constants/trackingEventTypes';
import { getOriginalViewId } from '@experiment-management-shared/utils/EMView';
import { AUTO_GENERATED_TEMPLATE_NAME } from '@/constants/dashboardConstants';
import useDashboardTemplateActions from '../../hooks/useDashboardTemplateActions';

const filterByUnsavedView = views => views.filter(view => !view.unsavedView);

const getSource = view => {
  const isAutoGeneratedView = !view.templateId;

  const viewSource = isAutoGeneratedView ? BY_COMET_LABEL : view.source;
  return viewSource;
};

const sortTemplateList = (templatesToSort = []) => {
  const userSavedTemplatesSorted = templatesToSort
    .filter(template => {
      return chartHelpers.isUserSavedTemplate(template.templateId);
    })
    .sort((template1, template2) => {
      return template1.templateName.localeCompare(template2.templateName);
    });

  const staticTemplates = templatesToSort
    .filter(template => {
      return !chartHelpers.isUserSavedTemplate(template.templateId);
    })
    .sort((template1, template2) => {
      return template1.templateName.localeCompare(template2.templateName);
    });

  return [...staticTemplates, ...userSavedTemplatesSorted].sort(
    (template1, template2) =>
      Number(!!template2.current) - Number(!!template1.current)
  );
};

const DashboardTemplateSelect = ({
  hasUnsavedChanges,
  onDiscardChanges,
  dashboard
}) => {
  const { data: currentView } = useSelectedProjectView();
  const { data: projectView } = useDefaultProjectView();
  const { data: views, isLoading } = useProjectViews();
  const baseTrackEvent = useBaseTrackEvent();

  const currentViewId = getOriginalViewId(currentView);
  const currentViewName = get(
    currentView,
    'templateName',
    AUTO_GENERATED_TEMPLATE_NAME
  );
  const currentViewProjectId = get(currentView, 'projectId', null);

  const sortedAndFilteredViews = useMemo(
    () => filterByUnsavedView(sortTemplateList(views)),
    [views]
  );

  const firstOtherViewIndex = useMemo(() => {
    const idx = sortedAndFilteredViews.findIndex(template => !template.current);

    if (idx < 0) {
      return idx;
    }

    // to highlight the bottom line of the last with current === true
    return idx - 1;
  }, [sortedAndFilteredViews]);

  const {
    handleTemplateChange,
    handleSetProjectTemplate,
    handleDeleteTemplate,
    handleDiscardNewView,
    handleSaveUnsavedView,
    handleChangeName
  } = useDashboardTemplateActions({
    dashboard,
    currentViewId,
    currentView
  });

  const onDiscardNewView = view => {
    handleDiscardNewView(view);
    baseTrackEvent(dashboardEvents.PROJECT_PAGE_DISCARD_CHANGED_VIEW);
  };

  const onChangeTemplate = template => {
    handleTemplateChange(template);

    baseTrackEvent(dashboardEvents.PROJECT_PAGE_SWITCHED_VIEW, {
      before_view_name: currentView?.templateName ?? 'Auto Generated',
      after_view_name: template?.templateName
    });
  };

  if (isLoading) return null;

  return (
    <TemplateSelect
      templates={sortedAndFilteredViews}
      disabledDischardChanges={!(currentView?.unsavedView || hasUnsavedChanges)}
      currentTemplateProjectId={currentViewProjectId}
      currentTemplateId={currentViewId}
      currentTemplateName={currentViewName}
      defaultTemplateName={projectView?.templateName}
      isProjectLevel
      dividerIndex={firstOtherViewIndex}
      generateSecondaryLabel={getSource}
      onChangeTemplate={onChangeTemplate}
      onChangeDefaultTemplate={handleSetProjectTemplate}
      onDeleteTemplate={handleDeleteTemplate}
      onDiscardChanges={onDiscardChanges}
      hasUnsavedChanges={currentView?.unsavedView || hasUnsavedChanges}
      onDiscardNewView={onDiscardNewView}
      onSaveUnsavedView={handleSaveUnsavedView}
      onEditName={handleChangeName}
    />
  );
};

DashboardTemplateSelect.propTypes = {
  hasUnsavedChanges: PropTypes.bool.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired
};

export default DashboardTemplateSelect;
