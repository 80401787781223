import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { animated } from 'react-spring';

import { useSendPostSignUpMutation } from '@onboarding/api';
import { Button } from '@design-system-outdated/components';
import { SecondaryModal } from '@DesignSystem/modals';
import { mountTapfiliate } from '@shared/utils/useTapfiliate';
import {
  getIsUserLoggedIn,
  getShowPostSignupForm
} from '@/reducers/userReducer';
import { IS_ON_PREMISE } from '@/constants/configConstants';

import styles from './PostSignUpQuestions.module.scss';

import usePostSignUpSprings from './usePostSignUpSprings';

const ONBOARDING_USER_TEAM_SIZE = ['Under 5', '5-10', '11-50', 'Over 50'];
const ONBOARDING_ROLES = [
  'Data scientist',
  'Data engineer',
  'Software engineer',
  'VP/Dir/Mgr of data science team',
  'Academic',
  'Other'
];
const ONBOARDING_KIND_OF_MODELING = [
  'Computer vision',
  'Natural language processing',
  'Reinforcement learning',
  'Robotics',
  'Generative AI',
  'Speech recognition',
  'Structured data',
  'Other'
];

const PostSignUpQuestions = () => {
  const sendPostSignUpFormMutation = useSendPostSignUpMutation();

  const isUserLoggedIn = useSelector(getIsUserLoggedIn);
  const isPostSignupFormAlreadyShown = useSelector(getShowPostSignupForm);

  const [teamSize, setTeamSize] = useState(null);
  const [role, setRole] = useState(null);
  const [modeling, setModeling] = useState(null);

  const [isRoleInitialized, setIsRoleInitialized] = useState(false);
  const [isTeamSizeInitialized, setIsTeamSizeInitialized] = useState(false);

  const { springsRole, springsModeling } = usePostSignUpSprings({
    isTeamSizeInitialized,
    isRoleInitialized
  });

  const renderContent = () => {
    return (
      <div className={styles.postSignUpForm}>
        <div className={styles.postSignUpField}>
          <div>
            How many people are in your team?
            <span className={styles.asterrisk}> *</span>
          </div>

          <div className={styles.fieldOptions}>
            {ONBOARDING_USER_TEAM_SIZE.map(size => (
              <div key={size} className={styles.fieldOption}>
                <Button
                  type="outlinedRounded"
                  onClick={() => {
                    setTeamSize(size);
                    setIsTeamSizeInitialized(true);
                  }}
                  active={size === teamSize}
                >
                  {size}
                </Button>
              </div>
            ))}
          </div>
        </div>
        {teamSize && (
          <animated.div className={styles.postSignUpField} style={springsRole}>
            <div>
              What best describes your current role?
              <span className={styles.asterrisk}> *</span>
            </div>

            <div className={styles.fieldOptions}>
              {ONBOARDING_ROLES.map(currentRole => (
                <div key={currentRole} className={styles.fieldOption}>
                  <Button
                    type="outlinedRounded"
                    onClick={() => {
                      setRole(currentRole);
                      setIsRoleInitialized(true);
                    }}
                    active={role === currentRole}
                  >
                    {currentRole}
                  </Button>
                </div>
              ))}
            </div>
          </animated.div>
        )}
        {role && (
          <animated.div
            className={styles.postSignUpField}
            style={springsModeling}
          >
            <div className={styles.postSignUpField}>
              <div>
                What kind of modeling are you working on?
                <span className={styles.asterrisk}> *</span>
              </div>

              <div className={styles.fieldOptions}>
                {ONBOARDING_KIND_OF_MODELING.map(currentModeling => (
                  <div key={currentModeling} className={styles.fieldOption}>
                    <Button
                      type="outlinedRounded"
                      onClick={() => setModeling(currentModeling)}
                      active={modeling === currentModeling}
                    >
                      {currentModeling}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </animated.div>
        )}
      </div>
    );
  };

  const handleClickPrimaryButton = e => {
    e.stopPropagation();

    sendPostSignUpFormMutation.mutate({
      role,
      sizeOfTeam: teamSize,
      modellingArea: modeling
    });
  };

  useEffect(() => {
    mountTapfiliate();
  }, []);

  const isModalOpen =
    isUserLoggedIn && !IS_ON_PREMISE && !isPostSignupFormAlreadyShown;

  return (
    <SecondaryModal
      title="Hey, tell us more about yourself"
      open={isModalOpen}
      content={renderContent()}
      primaryButtonText="Continue"
      primaryButtonDisabled={!modeling}
      onPrimaryButtonClick={handleClickPrimaryButton}
    />
  );
};

export default PostSignUpQuestions;
