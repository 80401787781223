import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';

const UpdateProvider = ({ dispatch, children }) => {
  const commithash = useRef();

  const checkHash = useCallback(() => {
    fetch('/build/COMMITHASH')
      .then(response => response.text())
      .then(hash => {
        if (hash !== commithash.current) {
          commithash.current = hash;
          dispatch(
            alertsUtil.openSnackbarDialog(
              snackbarTypes.FRONTEND_OUT_OF_DATE,
              'There is a new version of Comet available. Refresh the page to load.'
            )
          );
        }
      })
      // silent error, no need to display anything if this request fails
      .catch(() => {});
  }, [dispatch]);

  useEffect(() => {
    fetch('/build/COMMITHASH')
      .then(response => response.text())
      .then(hash => {
        commithash.current = hash;
      })
      // silent error, no need to display anything if this request fails
      .catch(() => {});
    const id = setInterval(checkHash, 30000);
    return () => clearInterval(id);
  }, [checkHash]);

  return <>{children}</>;
};

UpdateProvider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default connect()(UpdateProvider);
