// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetectedExperiment-module__detectedExperimentContainer--S1yqR{height:88px;padding:20px;box-shadow:0px 0px 4px rgba(0,0,0,.25);display:flex;justify-content:center;position:relative}.DetectedExperiment-module__detectedExperimentFixed--qYvSS{position:fixed;z-index:9999;background:#fff;top:0;left:0;right:0}.DetectedExperiment-module__detectedExperimentTitle--WpGGp{color:var(--gray-7);font-weight:500;font-size:18px;line-height:27px}.DetectedExperiment-module__detectedExperimentLabel--smVMQ{font-size:14px;line-height:21px;color:var(--gray-6)}.DetectedExperiment-module__detectedExperimentContent--n4MIZ{text-align:center}.DetectedExperiment-module__detectedExperimentButton--Qd_Jc{display:flex;position:absolute;right:20px;top:25px}.DetectedExperiment-module__detectedExperimentButton--Qd_Jc button{height:36px}@keyframes DetectedExperiment-module__moveConfetti--_sOYN{0%{background-size:30%;background-position:0 100%}100%{background-size:30%;background-position:0 0%}}.DetectedExperiment-module__successfulExperimentRun--Jikl0{animation:DetectedExperiment-module__moveConfetti--_sOYN 2s linear 5;background-image:url("/images/onboarding/confetti.svg");background-size:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detectedExperimentContainer": `DetectedExperiment-module__detectedExperimentContainer--S1yqR`,
	"detectedExperimentFixed": `DetectedExperiment-module__detectedExperimentFixed--qYvSS`,
	"detectedExperimentTitle": `DetectedExperiment-module__detectedExperimentTitle--WpGGp`,
	"detectedExperimentLabel": `DetectedExperiment-module__detectedExperimentLabel--smVMQ`,
	"detectedExperimentContent": `DetectedExperiment-module__detectedExperimentContent--n4MIZ`,
	"detectedExperimentButton": `DetectedExperiment-module__detectedExperimentButton--Qd_Jc`,
	"successfulExperimentRun": `DetectedExperiment-module__successfulExperimentRun--Jikl0`,
	"moveConfetti": `DetectedExperiment-module__moveConfetti--_sOYN`
};
module.exports = ___CSS_LOADER_EXPORT___;
