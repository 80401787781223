import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import cx from 'classnames';

import alertsUtil from '@/util/alertsUtil';
import BatchChartContainer from '@experiment-management-shared/components/Charts/PlotlyChart/BatchChartContainer';
import useProject from '@API/project/useProject';
import usePanelModalMenuHandlers from '@experiment-management-shared/hooks/usePanelModalMenuHandlers';
import usePanelMenuHandlers from '@experiment-management-shared/hooks/usePanelMenuHandlers';
import {
  Experiment,
  MetricColorMap,
  PanelGlobalConfig,
  PanelGlobalConfigMap,
  PanelSection,
  PanelType
} from '@experiment-management-shared/types';
import { BasicModal } from '@DesignSystem/modals';
import PanelsNoSearchResults from '@experiment-management-shared/components/PanelsNoSearchResults';
import { PanelMoveConfig } from '@experiment-management-shared/hooks/usePanelMoveToSection';
import PanelSectionEmptyState from './PanelSectionEmptyState';
import PanelSectionTitle from './PanelSectionTitle';
import DragIcon from './DragIcon.svg';

import Popover from '@material-ui/core/Popover';
import Collapse from '@material-ui/core/Collapse';
import { Button, IconButton, List, ListItem } from '@ds';
import {
  DSArrowDownIcon,
  DSArrowRightIcon,
  DSDeleteIcon,
  DSInsertAboveIcon,
  DSInsertBelowIcon,
  DSMoreOptionsVerticalIcon
} from '@ds-icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './PanelSectionsWrapper.module.scss';

export type PanelSharedConfig = {
  isAutoRefreshEnabled: boolean;
  activeIntervalFetchDelay: number;
  globalConfigMap: PanelGlobalConfigMap;
  sampleSizes?: { value: number; isDefault: boolean }[];
  hiddenMenuItems: string[];
  showLockCharts: PanelType[];
  fetchFull: boolean;
  experiments: Experiment[];
  experimentKeys: string[];
  hiddenExperimentKeys: string[];
  metricColorMap?: MetricColorMap;
};

export type PanelSectionEventsMap = {
  addPanel: string;
  deletePanel?: string;
  lockClick: string;
  searchPanels: string;
};

export type FilteredPanelsSectionMap = {
  [key: string]: boolean;
};

export type FilteredPanelsSectionsMap = {
  [key: string]: FilteredPanelsSectionMap | undefined;
};

type PanelSectionWrapperProps = {
  filterMap?: FilteredPanelsSectionMap;
  section: PanelSection;
  panelGlobalConfig: PanelGlobalConfig;
  isSearchMode: boolean;
  onSectionChange: (value: PanelSection) => void;
  panelHandlers?: Record<string, (value: unknown) => void>;
  resetSearch: () => void;
  regexpError: boolean;
  handleDeleteSection: (id: string) => void;
  handleAddSectionById: (id: string, shift: number) => void;
  scrollToRef?: (node: HTMLElement) => void;
  isDragPreview?: boolean;
  defaultSampleSize: number;
  eventsMap: PanelSectionEventsMap;
  configModifier?: (config: unknown) => unknown;
  panelSharedConfig: PanelSharedConfig;
  panelMoveConfig: PanelMoveConfig;
  handleToggleSectionBIEvent: (open: boolean) => void;
};

const PanelSectionWrapper = ({
  filterMap,
  section,
  panelGlobalConfig,
  isSearchMode,
  onSectionChange,
  panelHandlers,
  resetSearch,
  regexpError,
  handleDeleteSection,
  handleAddSectionById,
  scrollToRef,
  isDragPreview = false,
  defaultSampleSize,
  eventsMap,
  configModifier = config => config,
  panelSharedConfig,
  panelMoveConfig,
  handleToggleSectionBIEvent
}: PanelSectionWrapperProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useDispatch();
  const { data: project } = useProject();
  const projectId = project?.projectId;
  const { panels, layout } = section;
  const filteredPanelsLength = filterMap
    ? Object.keys(filterMap).length
    : panels.length;
  const hasPanels = panels.length > 0;
  const hasFilteredPanels = filteredPanelsLength > 0;
  const menuOpened = Boolean(anchorEl);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    isDragging
  } = useSortable({
    id: section.id
  });

  const {
    handleEditPanelClick,
    handleAddPanelClick
  } = usePanelModalMenuHandlers({
    sectionId: section.id,
    panelGlobalConfig,
    defaultSampleSize,
    addPanelEventName: eventsMap.addPanel,
    metricColorMap: panelSharedConfig.metricColorMap
  });

  const {
    handleDeletePanelClick,
    handleLegendModeChange,
    handleSampleSizeChange,
    handleTransformYChange,
    handlePanelChange,
    handleLockClick,
    handleLayoutChange
  } = usePanelMenuHandlers({
    section,
    onSectionChange,
    panelGlobalConfig,
    globalConfigMap: panelSharedConfig.globalConfigMap,
    lockClickEventName: eventsMap.lockClick,
    deletePanelEventName: eventsMap.deletePanel
  });

  const configs = useMemo(() => {
    return panels
      .filter(panel => (filterMap ? filterMap[panel.chartId] : true))
      .map(panel =>
        configModifier({
          metricNames: [panel.metricName],
          ...panel,
          sampleSize: panel.sampleSize || defaultSampleSize,
          deleteConfig: panel,
          editConfig: panel,
          handleSampleSizeChange,
          onTransformYChange: handleTransformYChange,
          onDeleteChart: handleDeletePanelClick,
          onEditChart: handleEditPanelClick,
          onLockClick: handleLockClick,
          sectionId: section.id,
          ...panelHandlers,
          onChangeLegendMode: handleLegendModeChange,
          projectId,
          panelGlobalConfig,
          panelMoveConfig
        })
      );
  }, [
    panels,
    filterMap,
    configModifier,
    defaultSampleSize,
    handleSampleSizeChange,
    handleTransformYChange,
    handleDeletePanelClick,
    handleEditPanelClick,
    handleLockClick,
    section.id,
    panelHandlers,
    handleLegendModeChange,
    projectId,
    panelGlobalConfig,
    panelMoveConfig
  ]);

  const handleExpandedClick = useCallback(() => {
    onSectionChange({
      ...section,
      expanded: !section.expanded
    });
    handleToggleSectionBIEvent(!section.expanded);
  }, [handleToggleSectionBIEvent, onSectionChange, section]);

  const handleAddClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (!section.expanded) {
        handleExpandedClick();
      }
      handleAddPanelClick();
    },
    [handleAddPanelClick, handleExpandedClick, section.expanded]
  );

  const handleAddAboveClick = useCallback(() => {
    setAnchorEl(null);
    handleAddSectionById(section.id, 0);
  }, [handleAddSectionById, section.id]);

  const handleAddBelowClick = useCallback(() => {
    setAnchorEl(null);
    handleAddSectionById(section.id, 1);
  }, [handleAddSectionById, section.id]);

  const handleDeleteClick = useCallback(() => {
    setAnchorEl(null);
    const POPUP_ID = 'CONFIRM_SECTION_DELETE';

    const closeHandler = () => {
      dispatch(alertsUtil.closeDialog(POPUP_ID));
    };

    const confirmHandler = () => {
      closeHandler();
      handleDeleteSection(section.id);
    };

    dispatch(
      alertsUtil.openCustomModal(
        POPUP_ID,
        <BasicModal
          open
          className={styles.confirmPopup}
          title={`Delete ${section.title} section?`}
          primaryButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          onClose={closeHandler}
          onPrimaryButtonClick={confirmHandler}
          onSecondaryButtonClick={closeHandler}
          content={
            <div className={styles.confirmContent}>
              {`The '${section.title}' section will be permanently deleted and cannot be recovered.`}
            </div>
          }
        />
      )
    );
  }, [dispatch, handleDeleteSection, section.id, section.title]);

  const handleTitleChange = useCallback(
    (title: string) =>
      onSectionChange({
        ...section,
        title
      }),
    [onSectionChange, section]
  );

  const renderChartContainer = () => {
    if (!hasPanels) {
      return (
        <PanelSectionEmptyState onAddPanelButtonClick={handleAddPanelClick} />
      );
    }

    if (!hasFilteredPanels && hasPanels) {
      return (
        <PanelsNoSearchResults
          resetSearch={resetSearch}
          regexpError={regexpError}
        />
      );
    }

    return (
      <div className="charts-container chart-grid grid">
        <BatchChartContainer
          configs={configs}
          isSearchMode={isSearchMode}
          layout={layout}
          onPanelChange={handlePanelChange}
          onLayoutChange={handleLayoutChange}
          panelSharedConfig={panelSharedConfig}
        />
      </div>
    );
  };

  const renderAddPanelButton = () => {
    return (
      <Button
        type="primary"
        size="medium"
        onClick={handleAddClick}
        data-test="add-panel-button"
      >
        Add panel
      </Button>
    );
  };

  const renderMenuButton = () => {
    return (
      <div onClick={event => event.stopPropagation()}>
        <IconButton
          type="secondary"
          Icon={<DSMoreOptionsVerticalIcon />}
          active={menuOpened}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }
          data-test="section-menu-button"
        />
        <Popover
          open={menuOpened}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          classes={{ paper: styles.popoverContainer }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: -6,
            horizontal: 'right'
          }}
        >
          <div className={styles.listContainer}>
            <List>
              <ListItem
                onClick={handleAddAboveClick}
                data-test="add-above-panel-button"
              >
                <ListItem.PrefixContainer>
                  <ListItem.Icon Icon={<DSInsertAboveIcon />} />
                </ListItem.PrefixContainer>
                <ListItem.Text primary="Insert section above" />
              </ListItem>

              <ListItem
                onClick={handleAddBelowClick}
                data-test="add-below-panel-button"
              >
                <ListItem.PrefixContainer>
                  <ListItem.Icon Icon={<DSInsertBelowIcon />} />
                </ListItem.PrefixContainer>
                <ListItem.Text primary="Insert section below" />
              </ListItem>

              <ListItem
                onClick={handleDeleteClick}
                data-test="delete-panel-button"
              >
                <ListItem.PrefixContainer>
                  <ListItem.Icon Icon={<DSDeleteIcon />} />
                </ListItem.PrefixContainer>
                <ListItem.Text primary="Delete section" />
              </ListItem>
            </List>
          </div>
        </Popover>
      </div>
    );
  };

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={cx(styles.panelSectionWrapper, {
        [styles.expanded]: !isSorting && section.expanded,
        [styles.empty]: !hasPanels,
        [styles.menuOpened]: menuOpened,
        [styles.sorting]: isSorting,
        [styles.preview]: isDragPreview
      })}
      data-test="panel-section"
    >
      <div
        ref={scrollToRef as never}
        className={styles.header}
        onClick={handleExpandedClick}
        data-test="section-header"
      >
        <div className={styles.left}>
          {section.expanded ? <DSArrowDownIcon /> : <DSArrowRightIcon />}
          <PanelSectionTitle
            placeholder="New section"
            title={section.title}
            count={filteredPanelsLength}
            onChange={handleTitleChange}
          />
        </div>
        <div className={styles.right}>
          <DragIcon className={styles.drag} {...listeners} />
          {renderMenuButton()}
          {renderAddPanelButton()}
        </div>
      </div>
      {!isDragPreview && (
        <div className={styles.body}>
          <Collapse in={section.expanded}>{renderChartContainer()}</Collapse>
        </div>
      )}
    </div>
  );
};

export default PanelSectionWrapper;
