import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { truncateValue } from '@shared/utils/decimalUtils';
import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';

import {
  getAggregationByCompoundKey,
  getAggregationFieldByColumnName
} from '@experiment-management-shared/utils/aggregation';

import { getColumnName } from '@API/helpers/v2_helpers';
import { COLUMNS_DISABLED_FOR_SUMMARY } from '../../lib/appConstants';

const SUMMARY_TYPE = 'aggregation';

const useGridSummary = ({
  columns,
  columnGrouping,
  aggregations,
  decimalsPrecision
}) => {
  const isWithSummary = !!columnGrouping?.length;

  const columnsForSummary = useMemo(() => {
    if (!columnGrouping?.length) {
      return [];
    }

    const filteredColumns = columns?.filter(
      column =>
        !COLUMNS_DISABLED_FOR_SUMMARY.includes(column.name) &&
        !columnGrouping.includes(column.name)
    );

    return filteredColumns?.map(column => ({
      columnName: column.name,
      type: SUMMARY_TYPE,
      showInGroupFooter: false,
      alignByColumn: true
    }));
  }, [columns, columnGrouping]);

  const renderSummaryCellComponent = useCallback(
    props => {
      if (!isWithSummary || !aggregations) {
        return <TableGroupRow.SummaryCell />;
      }

      const columnName = get(props, 'column.name', null);
      const compoundKey = get(props, 'row.compoundKey', null);
      const aggregationValues = getAggregationByCompoundKey({
        compoundKey,
        aggregations
      });
      const aggregationField = getAggregationFieldByColumnName(columnName);

      const value = get(
        aggregationValues,
        [getColumnName(columnName), aggregationField],
        null
      );

      const handledValue = isNull(value)
        ? value
        : truncateValue(value, decimalsPrecision)?.toString();

      return (
        <TableGroupRow.SummaryCell>
          <span data-test={`group-aggregation-[${compoundKey}-${columnName}]`}>
            {handledValue}
          </span>
        </TableGroupRow.SummaryCell>
      );
    },
    [isWithSummary, aggregations, decimalsPrecision]
  );

  return { columnsForSummary, isWithSummary, renderSummaryCellComponent };
};

export default useGridSummary;
