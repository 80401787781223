export const MANAGEMENT_PERMISSIONS = {
  INVITE_USERS_FROM_ORGANIZATION: 'invite-users-from-organization',
  INVITE_USERS_OUT_OF_ORGANIZATION: 'invite-users-out-of-organization',
  CHANGE_WORKSPACE_ROLE: 'change-workspace-role',
  CHANGE_WORKSPACE_ROLE_FOR_YOURSELF: 'change-workspace-role-for-yourself',
  REMOVE_USERS_FROM_WORKSPACE: 'remove-users-from-workspace',
  REMOVE_YOURSELF_FROM_WORKSPACE: 'remove-yourself-from-workspace',
  CHANGE_PROJECT_PRIVACY: 'change-project-privacy'
};

export const MANAGEMENT_PERMISSIONS_SERVER = {
  MANAGEMENT: 'management',
  PROJECT_VISIBILITY: 'project_visibility',
  INVITE_USERS: 'invite_users_to_workspace'
};

export const WORKSPACE_OWNER_VALUE = 'true';
export const WORKSPACE_MEMBER_VALUE = 'false';
