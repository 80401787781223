import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { max, uniq } from 'lodash';

import ReactGrid from '@experiment-management-shared/components/ReactGrid';
import {
  DEFAULT_COLUMN_SORTING,
  GRID_COLUMNS,
  REQUIRED_COLUMN_NAMES,
  REQUIRED_COLUMN_SORTING,
  REQUIRED_COLUMN_WIDTHS
} from '@experiment-management-shared/constants/experimentGridConstants';
import { transformColumnNameToColumn } from '@experiment-management-shared/utils/reactGridHelpers';
import { DEFAULT_COLUMN_WIDTH } from '@/lib/appConstants';
import ExperimentActionsColumn from './ExperimentActionsColumn';
import { SPECIAL_CELL_COMPONENTS } from '@experiment-management-shared/components/TableCells';

const DEFAULT_COLUMNS = uniq([
  ...REQUIRED_COLUMN_NAMES,
  GRID_COLUMNS.EXPERIMENT_TAGS,
  GRID_COLUMNS.END_SERVER_TIMESTAMP,
  GRID_COLUMNS.EXPERIMENT_KEY,
  GRID_COLUMNS.DURATION
]).map(transformColumnNameToColumn);

const MIN_WIDTH_EXPERIMENT_DETAILS_ACTION = 360;
const calculateAlignedColumnWidth = () =>
  max([
    window.innerWidth -
      300 -
      DEFAULT_COLUMN_WIDTH * 2 -
      MIN_WIDTH_EXPERIMENT_DETAILS_ACTION -
      175,
    DEFAULT_COLUMN_WIDTH
  ]);

const REACT_GRID_DEFAULT = {
  selection: [],
  pageSize: 100,
  pageNumber: 0,
  columnSorting: [...REQUIRED_COLUMN_SORTING, ...DEFAULT_COLUMN_SORTING],
  columnOrders: [
    ...REQUIRED_COLUMN_NAMES,
    GRID_COLUMNS.EXPERIMENT_TAGS,
    GRID_COLUMNS.END_SERVER_TIMESTAMP,
    GRID_COLUMNS.EXPERIMENT_KEY,
    GRID_COLUMNS.DURATION
  ],
  columnGrouping: []
};

const CELLS_MAP = {
  ...SPECIAL_CELL_COMPONENTS,
  [GRID_COLUMNS.EXPERIMENT_DETAILS_ACTIONS]: ExperimentActionsColumn
};

const RIGHT_COLUMNS = [GRID_COLUMNS.EXPERIMENT_DETAILS_ACTIONS];

const ExperimentsDetailsGridWrapper = props => {
  const { isComparison } = props;

  const [columnWidths, setColumnWidths] = useState([
    ...REQUIRED_COLUMN_WIDTHS,
    {
      columnName: GRID_COLUMNS.EXPERIMENT_TAGS,
      width: DEFAULT_COLUMN_WIDTH
    },
    {
      columnName: GRID_COLUMNS.END_SERVER_TIMESTAMP,
      width: DEFAULT_COLUMN_WIDTH
    },
    {
      columnName: GRID_COLUMNS.EXPERIMENT_KEY,
      width: DEFAULT_COLUMN_WIDTH
    },
    {
      columnName: GRID_COLUMNS.DURATION,
      width: calculateAlignedColumnWidth()
    },
    {
      columnName: GRID_COLUMNS.EXPERIMENT_DETAILS_ACTIONS,
      width: MIN_WIDTH_EXPERIMENT_DETAILS_ACTION
    }
  ]);

  const reactGrid = useMemo(() => {
    return {
      ...REACT_GRID_DEFAULT,
      columnWidths
    };
  }, [columnWidths]);

  useEffect(() => {
    if (!isComparison) {
      const resizeHandler = () => {
        setColumnWidths(columns => {
          return columns.map(column => {
            if (column.columnName === GRID_COLUMNS.DURATION) {
              return {
                ...column,
                width: calculateAlignedColumnWidth()
              };
            }

            return column;
          });
        });
      };

      window.addEventListener('resize', resizeHandler);

      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, [isComparison]);

  const handleColumnWidthsChange = widths => {
    setColumnWidths(widths);
  };

  const columns = useMemo(() => {
    if (!isComparison) {
      return [
        ...DEFAULT_COLUMNS,
        {
          name: GRID_COLUMNS.EXPERIMENT_DETAILS_ACTIONS,
          title: '  ',
          align: 'right'
        }
      ];
    }

    return DEFAULT_COLUMNS;
  }, [isComparison]);

  return (
    <ReactGrid
      onColumnWidthsChange={handleColumnWidthsChange}
      reactGridClass={['experiment-page', 'experiment-page-view-table']}
      reactGrid={reactGrid}
      columns={columns}
      rightColumns={RIGHT_COLUMNS}
      cellsMap={CELLS_MAP}
      hideSortingNotAvailable
      {...props}
    />
  );
};

ExperimentsDetailsGridWrapper.propTypes = {
  rows: PropTypes.array.isRequired,
  isComparison: PropTypes.bool.isRequired
};

export default ExperimentsDetailsGridWrapper;
