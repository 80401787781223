// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--experiment-details-layout-padding: 24px}.ExperimentDetailsWrapper-module__experimentDetailWrapper--W2mgl{background-color:#fff;overflow:hidden}.ExperimentDetailsWrapper-module__runDetail--fbejn{display:flex;flex-direction:column;flex:1;font-size:14px;width:100%;height:100%;overflow:hidden;position:relative;background-color:var(--background-color)}.ExperimentDetailsWrapper-module__viewMenuWrapper--REdgZ{display:flex;flex:1}.ExperimentDetailsWrapper-module__view--VRCEs{display:inline-block;vertical-align:top;margin-left:2px;background-color:#fff;width:calc(100% - 2px - 184px)}.ExperimentDetailsWrapper-module__collapsed--B48R3{width:calc(100% - 2px - 50px)}.ExperimentDetailsWrapper-module__fullHeight--vEIFt{height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentDetailWrapper": `ExperimentDetailsWrapper-module__experimentDetailWrapper--W2mgl`,
	"runDetail": `ExperimentDetailsWrapper-module__runDetail--fbejn`,
	"viewMenuWrapper": `ExperimentDetailsWrapper-module__viewMenuWrapper--REdgZ`,
	"view": `ExperimentDetailsWrapper-module__view--VRCEs`,
	"collapsed": `ExperimentDetailsWrapper-module__collapsed--B48R3`,
	"fullHeight": `ExperimentDetailsWrapper-module__fullHeight--vEIFt`
};
module.exports = ___CSS_LOADER_EXPORT___;
