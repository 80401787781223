import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { useActiveWorkspace } from '@shared/hooks';

type DistributionState = {
  modelHealth: string;
  baseLine: string;
};

type ChangeDistributionHandler = (
  value: React.SetStateAction<DistributionState>
) => void;

type ModelDistributionMutationProps = {
  distributionId: string | null;
  prevValue: string;
  changeDistributionHandler: ChangeDistributionHandler;
};

export function useModelDistributionSet() {
  const queryClient = useQueryClient();
  const activeWorkspace = useActiveWorkspace();
  const dispatch = useDispatch();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ distributionId }: ModelDistributionMutationProps) => {
      return api.patch(
        `mpm/${activeWorkspace?.id}/models/${modelId}/distribution`,
        { distributionId }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getModelRegistryNameById']);
        queryClient.invalidateQueries([
          'models',
          { workspaceId: activeWorkspace?.id }
        ]);
        queryClient.invalidateQueries([
          'modelsByID',
          { workspaceId: activeWorkspace?.id }
        ]);
        queryClient.invalidateQueries([
          'model-features',
          { workspaceId: activeWorkspace?.id }
        ]);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.SUCCESS_BASELINE_DATA_DRIFT_CHANGED,
            'The reference distribution for the data drift calculation was successfully changed.'
          )
        );
      },
      // in case of the error display prev value to the user
      onError: (_, { prevValue, changeDistributionHandler }) => {
        changeDistributionHandler((prevState: DistributionState) => {
          return { ...prevState, baseLine: prevValue };
        });
      }
    }
  );
}
