import React from 'react';

import DecisionTreeItem, {
  CONTROL_TYPES
} from '@shared/components/DecisionTree/DecisionTreeItem';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import classNames from './DecisionTree.module.scss';

const DecisionTreeListByControlType = ({
  options,
  controlType,
  controlValue,
  onChange,
  secondary
}) => {
  const list = (
    <List
      data-test={`ds-decision-tree-list${(secondary && '-secondary') || ''}`}
      className={secondary && classNames.dsDecisionTreeSecondary}
    >
      {options.map(({ key, list: insideList, ifShowList, ...props }) => {
        const ifShowInsideList = !!insideList;
        return [
          <DecisionTreeItem
            {...props}
            key={key}
            onChange={onChange}
            controlType={controlType}
            classes={
              secondary && {
                primary: classNames.dsDecisionTreeCheckboxMain,
                secondary: classNames.dsDecisionTreeCheckbox
              }
            }
          />,
          ifShowInsideList && (
            <DecisionTreeListByControlType
              key={`${key}-list`}
              secondary
              options={insideList?.options}
              controlType={insideList?.controlType}
              controlValue={insideList?.controlValue}
              onChange={insideList?.onChange}
            />
          )
        ];
      })}
    </List>
  );

  return controlType === CONTROL_TYPES.RADIO ? (
    <RadioGroup
      data-test="ds-decision-tree-radio"
      value={controlValue}
      onChange={onChange}
    >
      {list}
    </RadioGroup>
  ) : (
    <Box data-test="ds-decision-tree-checkbox">{list}</Box>
  );
};

DecisionTreeListByControlType.defaultProps = {
  options: [],
  secondary: false,
  controlValue: false
};

DecisionTreeListByControlType.propTypes = {
  controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  controlValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
      tooltip: PropTypes.shape({
        title: PropTypes.string,
        placement: PropTypes.string,
        arrow: PropTypes.bool
      }),
      value: PropTypes.string,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      color: PropTypes.string,
      list: PropTypes.shape({
        options: PropTypes.array,
        controlType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
        controlValue: PropTypes.bool,
        onChange: PropTypes.func.isRequired
      })
    })
  ),
  secondary: PropTypes.bool
};

export default DecisionTreeListByControlType;
