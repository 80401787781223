import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessageIcon } from '@Icons-outdated';
import styles from './InfoBox.module.scss';

const InfoBox = ({ title, text, icon: IconComponent }) => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.boxTitle}>
        <IconComponent />
        {title}
      </div>
      <div className={styles.boxSubtitle}>{text}</div>
    </div>
  );
};

InfoBox.defaultProps = {
  icon: ErrorMessageIcon
};

InfoBox.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default InfoBox;
