import React from 'react';
import {
  VisaIcon,
  MasterCardIcon,
  AmericanExpressIcon,
  DiscoverIcon
} from '@Icons-outdated';
import './AcceptedCardList.scss';

const AcceptedCardList = () => {
  return (
    <div className="ds-accepted-card-list">
      <VisaIcon />
      <MasterCardIcon />
      <AmericanExpressIcon />
      <DiscoverIcon />
    </div>
  );
};

AcceptedCardList.propTypes = {};

export default AcceptedCardList;
