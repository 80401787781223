import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import noop from 'lodash/noop';
import isBoolean from 'lodash/isBoolean';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FilesTree from './utils/FilesTree';
import DirRow from './components/DirRow';
import Header from './components/Header';
import FileRow from './components/FileRow';
import DFS from './utils/DFS';
import Scroll from './components/Scroll';
import DirectoriesState from './utils/FoldersState';
import {
  DEFAULT_SORT,
  DEFAULT_IS_IN_ORDER,
  DIR_TYPE,
  ROOT_DIR
} from '@experiment-management-shared/constants/fileBrowser';

const getListOfOpenDirectories = pathsArray => {
  return pathsArray.reduce(
    (result, path, index) => {
      const pathSegment = pathsArray.slice(0, index + 1).join('/');
      result[`/${pathSegment}`] = true;

      return result;
    },
    { [ROOT_DIR]: true }
  );
};

export const filesSortFunctionGenerator = (sortBy, isInOrder) => {
  return (a, b) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy];
    let value;

    if (isBoolean(valueA) || isBoolean(valueB)) {
      return isInOrder ? valueA - valueB : valueB - valueA;
    }

    if (!valueA) return -1;
    if (!valueB) return 1;

    if (typeof valueA === 'number') value = valueB - valueA;
    else value = valueB.localeCompare(valueA);

    if (isInOrder) return value;
    return value * -1;
  };
};

class FilesBrowser extends PureComponent {
  constructor(props) {
    super(props);

    const { currentFilePath } = props;
    const listOfOpenDirs = getListOfOpenDirectories(currentFilePath);

    this.state = {
      sortBy: DEFAULT_SORT,
      isInOrder: DEFAULT_IS_IN_ORDER,
      listOfOpenDirs
    };

    this.renderRows = this.renderRows.bind(this);
    this.createTree = this.createTree.bind(this);
    this.directoriesState = new DirectoriesState();

    this.directoriesState.updateState(this.state.listOfOpenDirs);
  }

  onSortChange = (sortBy, isInOrder) =>
    this.setState({ sortBy, isInOrder }, () => this.forceUpdate());

  onDirStateChanged = (relativePath, isOpen) => {
    const { listOfOpenDirs } = this.state;

    if (isOpen === false) {
      // close all the directories in the path
      Object.keys(listOfOpenDirs).forEach(dirPath => {
        if (dirPath.startsWith(relativePath)) listOfOpenDirs[dirPath] = isOpen;
      });
    }

    listOfOpenDirs[relativePath] = isOpen;
    this.directoriesState.updateState(listOfOpenDirs);

    this.forceUpdate();
  };

  createTree() {
    const { files, forceExpansion } = this.props;
    const filesTree = new FilesTree(files);
    this.directoriesState.expansion(forceExpansion);

    return filesTree;
  }

  renderDir(dir, experimentModels) {
    const {
      handleOnClick,
      handleOnHover,
      experimentKey,
      showDirActions
    } = this.props;
    const { relativePath, parentRelativePath } = dir;
    const isParentOpen = this.directoriesState.isOpen(parentRelativePath);

    if (relativePath === ROOT_DIR) return null;

    return isParentOpen ? (
      <DirRow
        showDirActions={showDirActions}
        experimentModels={experimentModels}
        experimentKey={experimentKey}
        dir={dir}
        key={dir.relativePath}
        onDirStateChanged={this.onDirStateChanged}
        handleOnClick={handleOnClick}
        handleOnHover={handleOnHover}
        directoriesState={this.directoriesState}
      />
    ) : null;
  }

  renderFile(file, isCurrent) {
    const { id, relativePath } = file;
    const {
      experimentKey,
      handleOnClick,
      handleOnHover,
      viewClickHandler
    } = this.props;
    const isOpen = this.directoriesState.isOpen(relativePath);

    return isOpen ? (
      <FileRow
        experimentKey={experimentKey}
        file={file}
        key={id}
        handleOnClick={handleOnClick}
        handleOnHover={handleOnHover}
        isCurrent={isCurrent}
        viewClickHandler={viewClickHandler}
      />
    ) : null;
  }

  renderFilesTree(list) {
    const { currentSelectedRow, experimentModels } = this.props;
    return list.map(row => {
      const isCurrent = currentSelectedRow && currentSelectedRow.id === row.id;
      if (row.type === DIR_TYPE) {
        return this.renderDir(row, experimentModels);
      }

      return this.renderFile(row, isCurrent);
    });
  }

  renderRows() {
    const { sortBy, isInOrder } = this.state;
    const { forceExpansion } = this.props;
    const sortFunction = filesSortFunctionGenerator(sortBy, isInOrder);
    const filesTree = this.createTree();
    this.directoriesState.expansion(forceExpansion);

    let list = DFS(filesTree, sortFunction);
    list = this.renderFilesTree(list);
    list = list.filter(row => row);
    return list;
  }

  renderInfinityList(rows) {
    const {
      emptyRowsComponent,
      listContainerHeight: height,
      width,
      rowHeight,
      isSearchActive
    } = this.props;

    if (!rows.length) {
      if (isSearchActive) {
        return (
          <h3 className="empty-preview-message">No matching files found</h3>
        );
      }

      return emptyRowsComponent;
    }

    const style = {
      width,
      height
    };

    return (
      <div className="files-browser-rows-container" style={style}>
        <Scroll
          rows={rows}
          height={height}
          width={width}
          rowHeight={rowHeight}
        />
      </div>
    );
  }

  render() {
    const rows = this.renderRows();
    const { classes: classesList, width } = this.props;
    const classes = classnames('files-browser', classesList);
    const style = {
      width
    };

    return (
      <Box className="files-browser-wrapper">
        <Grid container className={classes} style={style}>
          <Header onSortChange={this.onSortChange} />
          {this.renderInfinityList(rows)}
        </Grid>
      </Box>
    );
  }
}

FilesBrowser.propTypes = {
  showDirActions: PropTypes.bool,
  isSearchActive: PropTypes.bool,
  emptyRowsComponent: PropTypes.elementType,
  forceExpansion: PropTypes.bool,
  experimentModels: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
  classes: PropTypes.string,
  experimentKey: PropTypes.string,
  handleOnClick: PropTypes.func,
  handleOnHover: PropTypes.func,
  listContainerHeight: PropTypes.number,
  width: PropTypes.any,
  rowHeight: PropTypes.number,
  listClass: PropTypes.string,
  currentSelectedRow: PropTypes.object,
  viewClickHandler: PropTypes.func,
  currentFilePath: PropTypes.arrayOf(PropTypes.string).isRequired
};

FilesBrowser.defaultProps = {
  experimentKey: '',
  classes: '',
  emptyRowsComponent: null,
  showDirActions: true,
  forceExpansion: false,
  isSearchActive: false,
  handleOnClick: () => {},
  handleOnHover: () => {},
  listContainerHeight: 500,
  width: '100%',
  rowHeight: 25,
  listClass: '',
  currentSelectedRow: {},
  viewClickHandler: noop
};

export default FilesBrowser;
