import useExperimentsDetails from '@API/experiments/useExperimentsDetails';
import { SelectedLegendKey } from '@experiment-management-shared/types';

const usePanelPreviewExperiments = (
  selectedLegendKeys: SelectedLegendKey[],
  experimentKeys: string[],
  experiments: { [key: string]: string }[],
  projectId: string
) => {
  // in case there is selected legend keys in preview, we need to request additional
  // data to preview to be able to show this keys until they are saved in view.
  // we are checking more than 1 because 1 is default value
  const needUpdatedExperiments = selectedLegendKeys.length > 1;

  const { data: experimentsData } = useExperimentsDetails(
    {
      experimentKeys,
      isArchive: false,
      projectId,
      template: {
        panels: {
          panels: [
            {
              selectedLegendKeys
            }
          ]
        }
      }
    },
    {
      enabled: Boolean(projectId) && needUpdatedExperiments
    }
  );

  return experimentsData || experiments;
};

export default usePanelPreviewExperiments;
