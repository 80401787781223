// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartHeaderMenu-module__dropdownWrapper--xwjUc .MuiPaper-root.ds-dropdown-list{padding:4px}.ChartHeaderMenu-module__dropdownWrapper--xwjUc .MuiPaper-root.ds-dropdown-list .ds-menu li{font-size:12px;line-height:18px;min-height:32px}.ChartHeaderMenu-module__dropdownWrapper--xwjUc .MuiPaper-root.ds-dropdown-list .ds-menu li .menu-item-label{display:inline;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.ChartHeaderMenu-module__subMenu--CWk4q{margin-left:7px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownWrapper": `ChartHeaderMenu-module__dropdownWrapper--xwjUc`,
	"subMenu": `ChartHeaderMenu-module__subMenu--CWk4q`
};
module.exports = ___CSS_LOADER_EXPORT___;
