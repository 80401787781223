import { IconButton, Tooltip } from '@ds';

import PropTypes from 'prop-types';
import React from 'react';
import { DSDeleteIcon } from '@ds-icons';

const RemoveReportButton = ({ canEdit, onRemove }) => {
  return (
    <Tooltip content="Delete" placement="bottom" arrow={false}>
      <IconButton
        type="secondary"
        onClick={onRemove}
        disabled={!canEdit}
        Icon={<DSDeleteIcon />}
      />
    </Tooltip>
  );
};

RemoveReportButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default RemoveReportButton;
