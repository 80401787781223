import { useEffect, useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router';
import {
  WORKSPACE_PAGE_TAB_LABELS,
  WORKSPACE_PAGE_TABS,
  WORKSPACE_URL_HASHES
} from '@/constants/workspaceConstants';
import { capitalizeFirstLetter } from '@/helpers/generalHelpers';
import useBreadcrumbsDataSource from '@shared/hooks/useBreadcrumbsDataSource';

export function useMPMPageBreadcrumbs(props?: {
  model?: string;
  feature: string;
}) {
  const breadcrumbsDataSource = useBreadcrumbsDataSource();
  const model = props?.model;
  const feature = props?.feature;
  const { pathname } = useLocation();
  const { workspace } = useParams<Record<string, string | undefined>>();

  const PREFIX_PATH = '/:workspace/model-production-monitoring/:modelId';
  const isFeaturePage = !!matchPath(pathname, {
    path: `${PREFIX_PATH}/features/:featureId`
  });

  const isAlertPage = !!matchPath(pathname, {
    path: `${PREFIX_PATH}/alerts/add`
  });

  const items = useMemo(() => {
    const retVal = [
      {
        id: 'home',
        type: 'link',
        title: workspace ?? '',
        path: `/${workspace}`,
        dataMaskTest: 'workspace'
      },
      {
        id: 'tab-level-1',
        type: 'link',
        path: `/${workspace}${
          WORKSPACE_URL_HASHES[WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING]
        }`,
        title:
          WORKSPACE_PAGE_TAB_LABELS[
            WORKSPACE_PAGE_TABS.MODEL_PRODUCTION_MONITORING
          ]
      }
    ];

    const splitPathname = pathname.split('/').slice(1);

    // if we on the first level tabs we don't need to show breadcrumb for tabs
    if (splitPathname.length === 4) splitPathname.length = 3;

    splitPathname.forEach((pathPart: string, index) => {
      if (index === 0 || index === 1) return;

      let title = pathPart;

      if (index === 1) {
        title = capitalizeFirstLetter(title);
      }

      if (index === 2) {
        title = model ? model : title.substring(0, 9);
      }

      if (index === 3) {
        title = capitalizeFirstLetter(title);
      }

      if (index === 4 && isFeaturePage) {
        title = feature ? feature : '';
      }

      if (index === 4 && isAlertPage) {
        title = capitalizeFirstLetter(
          title === 'add' ? 'Add alert rule' : title
        );
      }

      retVal.push({
        id: pathPart + index,
        type: 'link',
        path: '/' + splitPathname.slice(0, index + 1).join('/'),
        title
      });
    });

    return retVal;
  }, [workspace, pathname, isFeaturePage, isAlertPage, model, feature]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    breadcrumbsDataSource.setItems(items);
    return () => {
      breadcrumbsDataSource.resetItems();
    };
  }, [breadcrumbsDataSource.setItems, breadcrumbsDataSource.resetItems, items]);
}
