import React from 'react';
import { DSDeleteIcon, DSEditIcon } from '@design-system/icons';
import { ListItem, Tooltip, ActionMenuList } from '@ds';

type MetricsTableEditableActionsProps = {
  onEdit: () => void;
  onDelete: () => void;
  disabled?: boolean;
};
const MetricsTableEditableActions: React.FC<MetricsTableEditableActionsProps> = ({
  onEdit,
  onDelete,
  disabled
}) => {
  return (
    <ActionMenuList>
      <ActionMenuList.Trigger
        disabled={disabled}
        data-test="metric-action-list-trigger"
      />
      <ActionMenuList.Body>
        <Tooltip content="Edit" placement="top">
          <ListItem onClick={onEdit} dataTest="edit-metric-row-button">
            <ListItem.PrefixContainer>
              <ListItem.Icon Icon={<DSEditIcon />} />
            </ListItem.PrefixContainer>
            <ListItem.Text primary="Edit" />
          </ListItem>
        </Tooltip>
        <Tooltip content="Delete" placement="top">
          <ListItem onClick={onDelete} dataTest="delete-metric-row-button">
            <ListItem.PrefixContainer>
              <ListItem.Icon Icon={<DSDeleteIcon />} />
            </ListItem.PrefixContainer>
            <ListItem.Text primary="Delete" />
          </ListItem>
        </Tooltip>
      </ActionMenuList.Body>
    </ActionMenuList>
  );
};

export default MetricsTableEditableActions;
