import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Switch } from '@DesignSystem/controllers';
import GenericModal from '@shared/components/GenericModal';

import alertsUtil from '@/util/alertsUtil';

import styles from './DownloadReportModal.module.scss';

const DownloadReportModal = ({ modalId, name }) => {
  const dispatch = useDispatch();
  const [isFullWidthLayout, setIsFullWidthLayout] = useState(false);

  useEffect(() => {
    const originalTitle = window.document.title;

    const bootstrapStylesheet = [
      ...(document.styleSheets || [])
    ].find(styleSheet => /\/bootstrap\.min\.css$/.test(styleSheet.href || ''));

    if (bootstrapStylesheet) {
      bootstrapStylesheet.disabled = true;
    }

    window.document.title = name;
    window.document.body.classList.add(styles.printingReport);

    return () => {
      window.document.title = originalTitle;
      window.document.body.classList.remove(
        styles.printingReport,
        styles.fullWidthLayout
      );

      if (bootstrapStylesheet) {
        bootstrapStylesheet.disabled = false;
      }
    };
  }, [name]);

  const toggleFullWidthLayout = () => {
    setIsFullWidthLayout(previousIsFullWidthLayout => {
      const newIsFullWidthLayout = !previousIsFullWidthLayout;

      if (newIsFullWidthLayout) {
        window.document.body.classList.add(styles.fullWidthLayout);
      } else {
        window.document.body.classList.remove(styles.fullWidthLayout);
      }

      return newIsFullWidthLayout;
    });
  };

  const onClose = () => {
    window.print();

    dispatch(alertsUtil.closeDialog(modalId));
  };

  return (
    <GenericModal title="Download" onClose={onClose}>
      <div className={styles.modal}>
        <div>
          <img src="/images/download-report-instructions.png" />
        </div>

        <div className={styles.content}>
          <div className={styles.text}>
            <p>Choose &quot;Save as PDF&quot; in the Destination option.</p>

            <p>Select landscape or portrait in the Layout option.</p>

            <p>
              Make sure you un-select &quot;Headers and footers&quot; under
              &quot;More settings&quot;
            </p>
          </div>

          <div className={styles.fullWithLayoutToggleContainer}>
            <Switch
              checked={isFullWidthLayout}
              onChange={toggleFullWidthLayout}
            />

            <span className={styles.fullWithLayoutToggleLabel}>
              Full width panels layout
            </span>
          </div>
        </div>
      </div>
    </GenericModal>
  );
};

DownloadReportModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default DownloadReportModal;
