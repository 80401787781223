import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import { axiosInstance, cancelWrapper } from '../api';

const getParameters = ({ experimentKeys, images, step }) => {
  // copy and sort arrays to not trigger request every time order of parameters is changed
  return {
    experiments: experimentKeys.slice().sort(),
    imageNames: images.slice().sort(),
    step
  };
};

const getImagePanelData = async ({ parameters }) => {
  if (
    isEmpty(parameters.imageNames) ||
    parameters.imageNames.length === 0 ||
    isEmpty(parameters.experiments) ||
    parameters.experiments.length === 0
  ) {
    return null;
  }

  return cancelWrapper(async cancelToken => {
    const response = await axiosInstance({
      cancelToken,
      data: {
        ...parameters,
        step: parameters.step || 0
      },
      method: 'post',
      url: 'image/filter-data'
    });

    return response;
  });
};

export default function useImagePanelData(data, config) {
  const parameters = getParameters({
    ...data
  });

  return useQuery(
    ['imagePanelData', { parameters }],
    () => getImagePanelData({ parameters }),
    {
      ...config
    }
  );
}
