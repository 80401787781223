import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@design-system-outdated/components';
import LineageWithReactFlowProvider, {
  LineageLayoutFlowNode,
  LineageNodeType
} from '@shared/components/LineageLayoutFlow';
import getLineageLayout from '@shared/hooks/useLineageLayout';
import { useLineage } from '@artifacts/api';

const ArtifactVersionGraphTab = ({
  artifactVersion: { artifactVersionId, artifact: { artifactId } = {} } = {}
}) => {
  const { workspace } = useParams();
  const { data = {}, isLoading } = useLineage(
    {
      artifactId,
      workspace
    },
    {
      refetchOnMount: true,
      enabled: !!artifactId
    }
  );
  const { edges: initialEdges, nodes: initialNodes } = getLineageLayout(data);

  const nodeTypes = useMemo(
    () => ({
      [LineageNodeType.ARTIFACT_VERSION]: props => (
        <LineageLayoutFlowNode
          {...props}
          currentVersion={artifactVersionId}
          workspace={workspace}
        />
      ),
      [LineageNodeType.MODEL_VERSION]: props => (
        <LineageLayoutFlowNode
          {...props}
          currentVersion={artifactVersionId}
          workspace={workspace}
        />
      ),
      [LineageNodeType.EXPERIMENT]: props => (
        <LineageLayoutFlowNode
          {...props}
          currentVersion={artifactVersionId}
          workspace={workspace}
        />
      )
    }),
    [artifactVersionId]
  );

  if (isLoading) {
    return (
      <small>
        <CircularProgress color="primary" />
      </small>
    );
  }

  return (
    <LineageWithReactFlowProvider
      nodeTypes={nodeTypes}
      legendNodes={[
        LineageNodeType.MODEL_VERSION,
        LineageNodeType.ARTIFACT_VERSION,
        LineageNodeType.EXPERIMENT
      ]}
      mainNodeId={artifactVersionId}
      defaultEdges={initialEdges}
      defaultNodes={initialNodes}
    />
  );
};

ArtifactVersionGraphTab.propTypes = {
  artifactVersion: PropTypes.shape({
    artifactVersionId: PropTypes.string.isRequired
  }).isRequired
};

export default ArtifactVersionGraphTab;
