import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { Button } from '@ds';
import Box from '@material-ui/core/Box';

import classNames from './RegisterModel.module.scss';

const RegisterModelFlowError = ({ onClick }) => {
  return (
    <Box className={classNames.registerModelErrorContent}>
      <Grid
        container
        direction="column"
        spacing={4}
        justifyContent="center"
        className={classNames.registerModelError}
      >
        <Grid item xs={12}>
          <Box
            className={classNames.registerModelErrorTitle}
            alignContent="center"
          >
            Error creating new model
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            className={classNames.registerModelErrorSubTitle}
            alignContent="center"
          >
            We couldn&apos;t detect the problem, please wait a few minutes
            before you try again
          </Box>
        </Grid>
        <Grid item xs={12} alignContent="center">
          <Button type="primary" onClick={onClick}>
            Dismiss
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

RegisterModelFlowError.defaultProps = {};

RegisterModelFlowError.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default RegisterModelFlowError;
