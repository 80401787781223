import React from 'react';
import PropTypes from 'prop-types';

import useModelRegistryVersionHistory from '@model-registry/api/useModelRegistryVersionHistory';
import ModelRegistryHistory from '@model-registry/components/ModelRegistryHistory';

const ModelVersionHistoryTab = ({ selectedVersion }) => {
  const {
    data: groupedHistoryItemsByDate = {},
    isLoading
  } = useModelRegistryVersionHistory(selectedVersion.registryModelItemId);

  const allHistoryItemsKeys = Object.keys(groupedHistoryItemsByDate) || [];
  const isHistoryEmpty = !allHistoryItemsKeys.length;

  return (
    <ModelRegistryHistory
      allHistoryItemsKeys={allHistoryItemsKeys}
      isHistoryEmpty={isHistoryEmpty}
      isLoading={isLoading}
      groupedHistoryItemsByDate={groupedHistoryItemsByDate}
    />
  );
};

ModelVersionHistoryTab.defaultProps = {
  selectedVersion: {}
};

ModelVersionHistoryTab.propTypes = {
  selectedVersion: PropTypes.shape({
    experimentModel: PropTypes.shape({
      models: PropTypes.array,
      experimentKey: PropTypes.string
    }),
    stages: PropTypes.arrayOf(PropTypes.string),
    restApiUrl: PropTypes.string,
    userName: PropTypes.string,
    comment: PropTypes.string,
    version: PropTypes.string,
    registryModelItemId: PropTypes.string
  })
};

export default ModelVersionHistoryTab;
