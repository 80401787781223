import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { noop } from 'lodash';
import classNames from './Accordion.module.scss';

const DsGreyAccordion = props => {
  const {
    id,
    content,
    title,
    className,
    classes,
    defaultExpanded,
    expanded,
    handleExpandChange,
    TransitionProps,
    summaryExpandedSection
  } = props;

  const handleChange = (event, expandedItem) => {
    event.preventDefault();
    if (handleExpandChange) {
      handleExpandChange(id, expandedItem);
    }
  };
  return (
    <Accordion
      data-test="ds-accordion"
      elevation={0}
      square
      className={cx(className, classNames.dsAccordion)}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      expanded={expanded}
      TransitionProps={TransitionProps}
    >
      <div className={classNames.accordionSummaryContainer}>
        <AccordionSummary
          data-test="ds-accordion-summary"
          className={cx(classNames.dsAccordionSummary, classes.summary)}
          classes={{
            expandIcon: classNames.expandIcon,
            content: classes.summaryContent
          }}
          IconButtonProps={{
            size: 'small'
          }}
          key={id}
          expandIcon={
            <ExpandMoreIcon className={classNames.dsAccordionExpandIcon} />
          }
          aria-controls={id}
          id={id}
        >
          <Typography className={classNames.dsAccordionSummaryName}>
            {title}
          </Typography>
        </AccordionSummary>
        {summaryExpandedSection}
      </div>
      <AccordionDetails
        data-test="ds-accordion-details"
        key={`accordion-details-${id}`}
        className={cx(classNames.dsAccordionDetails, classes.details)}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

DsGreyAccordion.defaultProps = {
  defaultExpanded: false,
  className: '',
  classes: {},
  expanded: undefined,
  handleExpandChange: noop,
  TransitionProps: {},
  summaryExpandedSection: null
};

DsGreyAccordion.propTypes = {
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  classes: PropTypes.shape({
    details: PropTypes.string,
    summary: PropTypes.string,
    summaryContent: PropTypes.string
  }),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  expanded: PropTypes.bool,
  handleExpandChange: PropTypes.func,
  summaryExpandedSection: PropTypes.element,
  TransitionProps: PropTypes.object
};

export default DsGreyAccordion;
