// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionPanels-module__hidden--YLzOr{display:none}.SectionPanels-module__reportChartsContainer--yZi5L{margin-top:-10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `SectionPanels-module__hidden--YLzOr`,
	"reportChartsContainer": `SectionPanels-module__reportChartsContainer--yZi5L`
};
module.exports = ___CSS_LOADER_EXPORT___;
