import Popover from '@material-ui/core/Popover';
import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from './ReportHistoryPopover.module.scss';

const formatRevision = revisionId => {
  return moment(revisionId).format('MM/DD/YYYY hh:mm a');
};

const getLabelFromDate = (date, index) => {
  if (index === 0) return 'Latest';

  return moment(date).calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: 'MMMM',
    sameElse: 'MMMM'
  });
};

const ReportHistoryPopover = ({
  anchorEl,
  currentRevisionId,
  onClose,
  onItemClick,
  open,
  revisions,
  PopoverProps
}) => {
  const groupedRevisions = useMemo(() => {
    if (!revisions.length) return {};

    const revisionsWithLabels = sortBy(revisions, 'createdAt')
      .reverse()
      .map(({ createdAt, revisionId }, index) => {
        return {
          date: formatRevision(createdAt),
          label: getLabelFromDate(createdAt, index),
          revisionId
        };
      });

    return groupBy(revisionsWithLabels, 'label');
  }, [revisions]);

  // eslint-disable-next-line react/prop-types
  const renderRevisionItem = ({ date, revisionId }) => {
    const isCurrentRevision = revisionId == currentRevisionId;
    const itemClassName = cx(styles.item, {
      [styles.itemCurrent]: isCurrentRevision
    });

    return (
      <div
        className={itemClassName}
        key={revisionId}
        onClick={() => onItemClick(revisionId)}
      >
        {date}
      </div>
    );
  };

  const renderRevisions = () => {
    const revisionGroups = Object.entries(groupedRevisions).map(
      ([label, revisions]) => {
        const items = revisions.map(renderRevisionItem);

        const revisionIds = revisions
          .map(revision => revision.revisionId)
          .join('-');

        return (
          <div className={styles.groupContainer} key={revisionIds}>
            <div className={styles.header}>{label}</div>

            <div className={styles.itemsContainer}>{items}</div>
          </div>
        );
      }
    );

    return revisionGroups.reduce((prev, curr) => {
      const separator = <div className={styles.separator} />;

      return [prev, separator, curr];
    }, []);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      classes={{ paper: styles.popover }}
      open={open}
      onClose={onClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      {...PopoverProps}
    >
      {renderRevisions()}
    </Popover>
  );
};

ReportHistoryPopover.defaultProps = {
  anchorEl: null,
  currentRevisionId: null,
  onClose: noop,
  onItemClick: noop,
  open: false,
  revisions: [],
  PopoverProps: {}
};

ReportHistoryPopover.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  currentRevisionId: PropTypes.string,
  onClose: PropTypes.func,
  onItemClick: PropTypes.func,
  open: PropTypes.bool,
  revisions: PropTypes.array,
  PopoverProps: PropTypes.object
};

export default ReportHistoryPopover;
