/* eslint-disable no-bitwise */
import colors from '@ds-colors';

export const COLORS = {
  primary: '#5155f5',
  text: 'rgba(0, 0, 0, 0.87)',
  treeItemHover: colors.dsTableRowHover,
  deleteIcon: '#f36960',
  disabledButton: 'rgba(0, 0, 0, 0.54)',
  tableIconColor: '#9f9f9f'
};

export const CHART_COLORS = [
  '#fb9341',
  '#5899da',
  '#19a979',
  '#ed4a7b',
  '#f4b400',
  '#945fcf',
  '#12a4b4',
  '#525df4',
  '#bf399e',
  '#6c8893',
  '#ef6868',
  '#2e6497'
];

export const lightenColor = (color: string, percent = 0.8) => {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
};

export const CHART_COLORS_LIGHT = CHART_COLORS.map(color =>
  lightenColor(color)
);

export const PRIMARY_TO_LIGHT_COLOR_MAP = CHART_COLORS.reduce((map, color) => {
  map[color] = lightenColor(color);
  return map;
}, {} as Record<string, string>);
