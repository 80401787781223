// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageThumbnail-module__thumbnail--DBQko{display:flex;align-items:center;justify-content:center;width:100%;height:100%;overflow:hidden}.ImageThumbnail-module__thumbnail--DBQko .ImageThumbnail-module__pointer--rl7Vc{cursor:pointer}.ImageThumbnail-module__thumbnail--DBQko a{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.ImageThumbnail-module__thumbnail--DBQko img{max-width:100%;object-fit:cover}.ImageThumbnail-module__noImage--wMxsh{display:flex;align-items:center;text-align:center;height:88px;border-radius:10px;padding:16px;font-size:14px;line-height:18px;color:var(--gray-5);cursor:default}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": `ImageThumbnail-module__thumbnail--DBQko`,
	"pointer": `ImageThumbnail-module__pointer--rl7Vc`,
	"noImage": `ImageThumbnail-module__noImage--wMxsh`
};
module.exports = ___CSS_LOADER_EXPORT___;
