import { useQuery } from 'react-query';
import { GRID_COLUMNS } from '@experiment-management-shared/constants/experimentGridConstants';

import modelRegistryApi from '../../util/modelRegistryApi';

export const PINNED_LEFT_MODEL_REGISTRY_COLUMNS = [GRID_COLUMNS.NAME];
export const PINNED_RIGHT_MODEL_REGISTRY_COLUMNS = [
  GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME
];

// SMELLY: Stages to tags migration on FE
export const getDefaultCustomizeModelColumns = ({
  isModelRegistryTagsFromStagesEnabled,
  isModelApprovalProcessEnabled
}) =>
  [
    ...PINNED_LEFT_MODEL_REGISTRY_COLUMNS,
    GRID_COLUMNS.OWNER,
    'CreatedAt',
    'LastUpdatedAt',
    isModelApprovalProcessEnabled && GRID_COLUMNS.STATUS,
    isModelRegistryTagsFromStagesEnabled
      ? GRID_COLUMNS.TAGS
      : GRID_COLUMNS.STAGE,
    'SourceExperiment',
    GRID_COLUMNS.PINNED_ROW_ACTION_MENU_COLUMN_NAME
  ].filter(Boolean);

export const PINNED_MODEL_REGISTRY_COLUMNS = [
  ...PINNED_LEFT_MODEL_REGISTRY_COLUMNS,
  ...PINNED_RIGHT_MODEL_REGISTRY_COLUMNS
];

const getModelRegistryView = async (
  registryModelId,
  { isModelApprovalProcessEnabled, isModelRegistryTagsFromStagesEnabled }
) => {
  const resp = await modelRegistryApi.fetchModelRegistryView(registryModelId);
  const data = resp?.data || {};
  const { columnsState } = data || {};
  let parsedColumnsState;
  const defaultColumns = getDefaultCustomizeModelColumns({
    isModelRegistryTagsFromStagesEnabled,
    isModelApprovalProcessEnabled
  });

  try {
    parsedColumnsState = JSON.parse(columnsState);

    if (parsedColumnsState) {
      const indexOfStatusColumnInView = parsedColumnsState.indexOf(
        GRID_COLUMNS.STATUS
      );
      const indexOfStageColumnInView = parsedColumnsState.indexOf(
        GRID_COLUMNS.STAGE
      );

      // SMELLY: Stages to tags migration on FE
      if (
        isModelRegistryTagsFromStagesEnabled &&
        indexOfStageColumnInView !== -1
      ) {
        parsedColumnsState = parsedColumnsState.map((parsedColumnName, ind) =>
          ind !== indexOfStageColumnInView
            ? parsedColumnName
            : GRID_COLUMNS.TAGS
        );
      }

      if (!isModelApprovalProcessEnabled && indexOfStatusColumnInView !== -1) {
        parsedColumnsState.splice(indexOfStatusColumnInView, 1);
      }

      // ADD STATUS COLUMN TO VIEW IF IT IS NOT THERE???? hack???
      if (isModelApprovalProcessEnabled && indexOfStatusColumnInView === -1) {
        parsedColumnsState.unshift(GRID_COLUMNS.STATUS);
      }
    }

    return {
      columnsState: parsedColumnsState || defaultColumns,
      registryModelId
    };
  } catch (_) {
    return {
      // TODO: we should get this from BE, this should be fixed
      columnsState: defaultColumns,
      registryModelId
    };
  }
};

const useModelRegistryView = (
  registryModelId,
  config,
  { isModelApprovalProcessEnabled, isModelRegistryTagsFromStagesEnabled }
) => {
  return useQuery(
    ['modelRegistryById', { registryModelId }, 'view'],
    () =>
      getModelRegistryView(registryModelId, {
        isModelApprovalProcessEnabled,
        isModelRegistryTagsFromStagesEnabled
      }),
    {
      enabled: !!registryModelId,
      initialData: {
        columnsState: null,
        registryModelId
      },
      refetchOnMount: true,
      ...config
    }
  );
};

export default useModelRegistryView;
