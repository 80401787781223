import floor from 'lodash/floor';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import round from 'lodash/round';

export const matrixToPercentageValues = matrix => {
  return matrix.map(row => {
    const total = sum(row);

    return row.map(value => floor((value * 100) / total, 2) || 0);
  });
};

export const getRanges = (
  matrix,
  colorScale,
  isPercentage = false,
  smart = false
) => {
  const values = sortBy(uniq(flatten(matrix)));
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  const getSmartColorBarValue = index => {
    if (values.length === 0) return 0;

    const range = Math.ceil(values.length / colorScale.length);
    const maxTargetIndex = values.length - 1;
    const targetIndex = range * index;

    if (index === colorScale.length) {
      return isPercentage ? 100 : values[maxTargetIndex];
    }

    const rawValue =
      targetIndex > maxTargetIndex
        ? values[maxTargetIndex]
        : values[targetIndex];

    return isPercentage ? round(rawValue, 2) : Math.floor(rawValue);
  };
  const getEqualColorBarValue = index => {
    if (values.length === 0) return 0;

    if (isPercentage) {
      return Math.floor((index * 100) / colorScale.length);
    }

    const range = maxValue - minValue;
    return Math.floor(minValue + (index * range) / colorScale.length);
  };

  return colorScale.map((color, index) => {
    return {
      color,
      minValue: smart
        ? getSmartColorBarValue(index)
        : getEqualColorBarValue(index),
      maxValue: smart
        ? getSmartColorBarValue(index + 1)
        : getEqualColorBarValue(index + 1)
    };
  });
};
