import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import { Button, TextButton } from '@ds';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';

import { createDefaultFileName } from '@experiment-management-shared/utils/downloadFile';

import alertsUtil from '@/util/alertsUtil';

import GenericModal from '@shared/components/GenericModal';

// @ToDo: might be replaced afterwards with experiment-management-shared/components/ExportFileDialog
const ExportFileModal = ({
  entityType,
  fileExtension,
  isArchive,
  modalId,
  modalTitle,
  projectName,
  submitHandler,
  viewName,
  workspace,
  customFilenamePaths
}) => {
  const dispatch = useDispatch();

  const defaultFileName = createDefaultFileName({
    entityType,
    isArchive,
    customPaths: customFilenamePaths,
    projectName,
    viewName,
    workspace
  });

  const [fileName, setFileName] = useState(defaultFileName);

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    submitHandler(fileName);
    handleClose();
  };

  const handleFileNameChange = e => {
    const { value } = e.target;
    setFileName(value);
  };

  const renderFileNameField = () => {
    return (
      <div className="generic-modal-section">
        <InputLabel className="modal-input-label">Filename</InputLabel>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <input
            className="native-input"
            onChange={handleFileNameChange}
            value={fileName}
            placeholder="Enter filename"
            style={{ flex: 1 }}
          />
          <span>.{fileExtension}</span>
        </div>
      </div>
    );
  };

  return (
    <GenericModal
      customClass="generic-modal-alt"
      title={modalTitle}
      onClose={() => {}}
      onCancel={handleClose}
      hideFooter
      isButtonDisabled
    >
      <DialogContent className="generic-modal-body">
        <div className="generic-modal-section row">{renderFileNameField()}</div>
      </DialogContent>
      <DialogActions className="generic-modal-footer-alt right">
        <TextButton size="large" onClick={handleClose}>
          Cancel
        </TextButton>
        <Button
          size="large"
          onClick={handleSubmit}
          disabled={isEmpty(fileName)}
        >
          Export
        </Button>
      </DialogActions>
    </GenericModal>
  );
};

ExportFileModal.defaultProps = {
  customFilenamePaths: [],
  entityType: '',
  isArchive: false,
  modalTitle: 'Export file',
  projectName: '',
  viewName: '',
  workspace: ''
};

ExportFileModal.propTypes = {
  customFilenamePaths: PropTypes.array,
  entityType: PropTypes.string,
  isArchive: PropTypes.bool,
  fileExtension: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  viewName: PropTypes.string,
  workspace: PropTypes.string
};

export default ExportFileModal;
