import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import modelRegistryApi from '../../../util/modelRegistryApi';
import alertsUtil from '../../../util/alertsUtil';
import { dialogTypes, snackbarTypes } from '../../../constants/alertTypes';

const useModelRegistryDeleteWebhookMutation = ({
  workspaceName,
  modelName
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    id =>
      modelRegistryApi.deleteWebhook({
        id,
        workspaceName,
        modelName
      }),
    {
      onSuccess: (
        data,
        { snackbarMsg = 'Successfully deleted a webhook.' } = {}
      ) => {
        queryClient.invalidateQueries([
          'modelRegistry',
          { workspaceName, modelName },
          'webhooks'
        ]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            snackbarTypes.SUCCESS_MODEL_REGISTRY_DELETE_WEBHOOK,
            snackbarMsg
          )
        );
      },
      onError: (
        data,
        { snackbarMsgErr = 'There was an error deleting a webhook.' } = {}
      ) => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_API,
            snackbarMsgErr
          )
        );
      }
    }
  );
};

export default useModelRegistryDeleteWebhookMutation;
