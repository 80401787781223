import { User } from '@shared/types';

type useProfileImageUrlOpts = {
  userData?: User;
  width: number;
};
const useProfileImageUrl = ({ userData, width }: useProfileImageUrlOpts) => {
  const profileImages = userData?.profileImages;

  if (!profileImages) return '';

  const profileImage = width > 30 ? profileImages.large : profileImages.small;
  const isGravatarSource =
    profileImage.slice(0, 24) === 'https://www.gravatar.com';

  if (isGravatarSource && userData.isLoggedInWithGithub) {
    return `https://github.com/${userData.username}.png?size=${width}`;
  }
  return profileImage;
};

export default useProfileImageUrl;
