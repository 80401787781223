import { useCallback, useMemo, useState } from 'react';

const useHyperParametersModalState = () => {
  const [addParameterOpen, setAddParameterOpen] = useState(false);
  const [editParam, setEditParam] = useState(null);
  const [deleteParamName, setDeleteParamName] = useState(null);

  const isUpdateModalOpen = useMemo(() => addParameterOpen || editParam?.name, [
    addParameterOpen,
    editParam?.name
  ]);

  const openUpdateParameterModal = useCallback(paramName => {
    if (!paramName) {
      setAddParameterOpen(true);
    } else {
      setEditParam(paramName);
    }
  }, []);

  const closeUpdateParameterModal = useCallback(() => {
    setAddParameterOpen(false);
    setEditParam(null);
  }, []);

  const openDeleteParam = useCallback(name => {
    setDeleteParamName(name);
  }, []);

  const closeDeleteParam = useCallback(() => {
    setDeleteParamName(null);
  }, []);

  return {
    isUpdateModalOpen,
    isEdit: !!editParam,
    openUpdateParameterModal,
    closeUpdateParameterModal,
    editParamName: editParam?.name,
    editParamValue: editParam?.value,
    deleteParamName,
    openDeleteParam,
    closeDeleteParam
  };
};

export default useHyperParametersModalState;
