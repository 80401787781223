import React from 'react';
import PropTypes from 'prop-types';

import classNames from './Tag.module.scss';

const Tag = ({ label, backgroundColor, marginLeft, color, style }) => {
  return (
    <div
      className={classNames.dsTag}
      style={{ marginLeft, backgroundColor, color, ...style }}
    >
      {label}
    </div>
  );
};

Tag.propTypes = {
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  marginLeft: PropTypes.number,
  label: PropTypes.string.isRequired
};

Tag.defaultProps = {
  color: '',
  backgroundColor: '',
  marginLeft: 0,
  style: {}
};

export default Tag;
