import { OutputFormatType } from 'diff2html/lib/types';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';

import { calculateExperimentName } from '@experiment-management-shared/utils/experimentHelpers';
import SimpleDiff from '@experiment-details/components/Code/SimpleDiff';
import SmallLoader from '@shared/components/SmallLoader';
import { selectAreComparePageExperimentsSelectable } from '@/reducers/ui/experimentsUiReducer';
import CompareContentSelector from '@experiment-details/components/CompareContentSelector';
import { SELECTOR_TYPE } from '@experiment-details/constants/general';
import BaseCodeCompareHeader from '../BaseCodeCompareHeader';
import BaseCodeCompareTabBar from '../BaseCodeCompareTabBar';
import { COMPARE_TAB } from '@experiment-details/constants/code';
import { isDiffTab, isSingleTab } from '@experiment-details/utils/code';

const DIFF_OUTPUT_FORMATS = {
  split: OutputFormatType.SIDE_BY_SIDE,
  unified: OutputFormatType.LINE_BY_LINE
};

const BaseCodeCompareView = ({
  hardcodedFileName,
  experiments,
  experiment1,
  experiment2,
  experiment1Code,
  experiment2Code,
  detailView,
  isLoading,
  TabComponent
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(COMPARE_TAB.EXPERIMENT_1);
  const areExperimentsSelectable = useSelector(
    selectAreComparePageExperimentsSelectable
  );

  const isDiff = Boolean(
    experiment1Code && experiment2Code && experiment1Code !== experiment2Code
  );
  const isDataEmpty =
    !experiment1Code && !experiment2Code && !areExperimentsSelectable;

  // handle case when user open the page first time, and we want to select diff view by default
  useEffect(() => {
    if (isLoading || isLoaded) return;
    setIsLoaded(true);

    if (isDiff && !isDiffTab(selectedTab)) {
      setSelectedTab(COMPARE_TAB.SPLIT);
    }
  }, [isDiff, isLoading, isLoaded, selectedTab]);

  // handle case when user select the experiment that doesn't have diff view
  useEffect(() => {
    if (isLoading || !isLoaded) return;

    if (!isDiff && isDiffTab(selectedTab)) {
      setSelectedTab(COMPARE_TAB.EXPERIMENT_1);
    }
  }, [isDiff, isLoading, isLoaded, selectedTab]);

  const handleSelectTab = useCallback((e, value) => {
    setSelectedTab(value);
  }, []);

  const hardcodedFileNameOptions = useMemo(
    () => [
      {
        label: hardcodedFileName,
        value: hardcodedFileName
      }
    ],
    [hardcodedFileName]
  );

  const hardcodedFileNameOption = useMemo(
    () => ({
      label: hardcodedFileName,
      value: hardcodedFileName
    }),
    [hardcodedFileName]
  );

  const renderSingleView = () => {
    const experiment =
      selectedTab === COMPARE_TAB.EXPERIMENT_1 ? experiment1 : experiment2;
    const data =
      selectedTab === COMPARE_TAB.EXPERIMENT_1
        ? experiment1Code
        : experiment2Code;

    return (
      <div>
        <BaseCodeCompareHeader
          detailView={detailView}
          isDiff={isDiff}
          experiment={experiment}
          isDataEmpty={isDataEmpty}
        />

        <TabComponent
          data={data}
          showContentHeader={false}
          needToFetchData={false}
          experiments={[experiment]}
          detailView={detailView}
          isComparePage
        />
      </div>
    );
  };

  const renderDiffView = () => (
    <SimpleDiff
      fileName1={hardcodedFileName}
      content1={experiment1Code}
      fileName2={hardcodedFileName}
      content2={experiment2Code}
      diffOutputFormat={DIFF_OUTPUT_FORMATS[selectedTab]}
    />
  );

  if (isLoading) {
    return <SmallLoader />;
  }

  const diffView = isDiff && isDiffTab(selectedTab);
  const singleView = isSingleTab(selectedTab);

  return (
    <div>
      {areExperimentsSelectable && (
        <CompareContentSelector
          experiment1={experiment1}
          experiment2={experiment2}
          experiments={experiments}
          experiment1FileOptions={hardcodedFileNameOptions}
          experiment1FileOption={hardcodedFileNameOption}
          experiment2FileOptions={hardcodedFileNameOptions}
          experiment2FileOption={hardcodedFileNameOption}
          disabledFileChange
          type={SELECTOR_TYPE.OUTLINED}
        />
      )}
      <BaseCodeCompareTabBar
        experimentName1={calculateExperimentName(experiment1)}
        experimentName2={calculateExperimentName(experiment2)}
        isDiff={isDiff}
        selectedTab={selectedTab}
        tabSelectHandler={handleSelectTab}
      />

      {singleView && renderSingleView()}
      {diffView && renderDiffView()}
    </div>
  );
};

BaseCodeCompareView.defaultProps = {
  experiment1Code: null,
  experiment2Code: null
};

BaseCodeCompareView.propTypes = {
  detailView: PropTypes.string.isRequired,
  experiment1: IExperimentDetails.isRequired,
  experiment1Code: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  experiment2: IExperimentDetails.isRequired,
  experiment2Code: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired,
  hardcodedFileName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  TabComponent: PropTypes.elementType.isRequired
};

export default BaseCodeCompareView;
