import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, TextButton, IconButton, Tooltip } from '@ds';
import FormGroup from '@material-ui/core/FormGroup';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';

import { TEMPLATE_SCOPE_TYPE_LABELS } from '@experiment-management-shared/constants/chartsGallery';
import useDuplicatePanelTemplateMutation from '@API/panels/useDuplicatePanelTemplateMutation';
import useCanEditProject from '@API/project/useCanEditProject';
import { snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

import StyledValidationTooltip from '@shared/components/StyledComponents/StyledValidationTooltip';
import { DSCopyIcon } from '@ds-icons';

const DuplicateTemplateButton = ({ id, name }) => {
  const dispatch = useDispatch();
  const { data: canEdit } = useCanEditProject();
  const duplicatePanelTemplateMutation = useDuplicatePanelTemplateMutation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [newItemName, setNewItemName] = useState(`Copy of ${name}`);
  const anchorEl = useRef(null);

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const duplicateTemplate = async () => {
    try {
      await duplicatePanelTemplateMutation.mutateAsync({
        templateId: id,
        templateName: newItemName
      });

      dispatch(
        alertsUtil.openSnackbarDialog(
          snackbarTypes.SUCCESS_API_RESPONSE,
          `Your newly duplicated panel can be found in the ${TEMPLATE_SCOPE_TYPE_LABELS.WORKSPACE} tab.`
        )
      );
    } finally {
      handleClose();
    }
  };

  const openConfirm = () => {
    setIsPopoverOpen(true);
  };

  const isBtnDisabled = newItemName.length === 0 || newItemName === name;

  return (
    <>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="template-popover duplicate-template-popover">
          <div className="template-popover-title">Duplicate Panel</div>

          <FormGroup>
            <StyledValidationTooltip
              title="Your new panel name must be different from the current one"
              open={newItemName === name}
            >
              <TextField
                label="Panel Name"
                placeholder="name"
                value={newItemName}
                onChange={event => setNewItemName(event.target.value)}
                required
                margin="normal"
                id="name"
              />
            </StyledValidationTooltip>
          </FormGroup>

          <div className="template-popover-actions">
            <div className="template-popover-actions-cancel">
              <TextButton size="large" onClick={handleClose}>
                Cancel
              </TextButton>
            </div>
            <div className="template-popover-actions-confirm">
              <Button
                size="large"
                onClick={duplicateTemplate}
                disabled={isBtnDisabled}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popover>

      <Tooltip content="Duplicate" placement="top">
        <IconButton
          type="secondary"
          className="charts-gallery-actions-icon-button"
          onClick={openConfirm}
          disabled={!canEdit}
          ref={anchorEl}
          Icon={<DSCopyIcon />}
          active={isPopoverOpen}
        />
      </Tooltip>
    </>
  );
};

DuplicateTemplateButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default DuplicateTemplateButton;
