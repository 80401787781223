import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import { dialogTypes, snackbarTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import { Switch } from '@DesignSystem/controllers';
import { BasicModal } from '@DesignSystem/modals';
import { Button, TextButton } from '@ds';

import {
  useCreateShareCodeMutation,
  useShareCode
} from '@experiment-management-shared/api';
import { truncateMiddleOfStringToFitWidth } from '@shared/utils/displayHelpers';
import EmbeddedCodeModal from '../EmbeddedCodeModal';
import styles from './ShareReportModal.module.scss';
import useProject from '@API/project/useProject';
import { DSCodeIcon, DSCopyIcon } from '@ds-icons';
import { AxiosError } from 'axios';
import { NO_PAYMENT_PLAN_ERROR_CODE } from '@app/network';
import { useActiveWorkspace } from '@shared/hooks';

const ShareReportModal = ({
  modalId,
  url
}: {
  modalId: string;
  url: string;
}) => {
  const dispatch = useDispatch();
  const activeWorkspace = useActiveWorkspace();
  const { data: project } = useProject();
  const { data: shareCode } = useShareCode();
  const createShareCodeMutation = useCreateShareCodeMutation();

  const [isShareableURL, setIsShareableURL] = useState(false);
  const canEdit = project?.canEdit ?? false;
  const shareableURL = isShareableURL ? `${url}?shareable=${shareCode}` : url;

  const onClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleClick = () => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_SHARE_LINK,
        'Successfully copied share report link to clipboard'
      )
    );

    onClose();
  };

  const handleOpenEmbeddedCodeModal = () => {
    const shareReportModal = (
      <EmbeddedCodeModal
        modalId={dialogTypes.EMBEDDED_CODE_REPORT_MODAL}
        url={url}
      />
    );

    dispatch(
      alertsUtil.openCustomModal(
        dialogTypes.EMBEDDED_CODE_REPORT_MODAL,
        shareReportModal
      )
    );

    onClose();
  };

  const toggleIsShareableURL = () => {
    setIsShareableURL(prevValue => !prevValue);
  };

  const renderShareableSection = () => {
    if (activeWorkspace.collaborationFeaturesDisabled) return null;

    if (shareCode) {
      return (
        <>
          <div className={styles.toggleContainer}>
            <Switch checked={isShareableURL} onChange={toggleIsShareableURL} />
            <div className={styles.toggleLabel}>Share with everyone</div>
          </div>
          <div className={styles.toggleDescription}>
            Sharing this report with everyone will also give them access to the
            underlying project
          </div>
        </>
      );
    }

    return (
      <Button
        disabled={!canEdit || createShareCodeMutation.isLoading}
        onClick={() =>
          createShareCodeMutation.mutate(undefined, {
            onError: (err: AxiosError) => {
              if (err.response?.status === NO_PAYMENT_PLAN_ERROR_CODE) {
                onClose();
              }
            }
          })
        }
        type="tertiary"
      >
        Create Shareable Link
      </Button>
    );
  };

  const truncatedURL = truncateMiddleOfStringToFitWidth(shareableURL, 380);

  return (
    <BasicModal
      title="Share"
      onClose={onClose}
      open
      content={
        <div className={styles.modal}>
          <div className={styles.description}>
            Share a view of your report with teammates and others
          </div>

          <div className={styles.urlContainer}>
            <span className={styles.url}>
              <span>{truncatedURL}</span>
            </span>
            <CopyToClipboard text={shareableURL}>
              <Button PrefixIcon={<DSCopyIcon />} onClick={handleClick}>
                Copy Link
              </Button>
            </CopyToClipboard>
          </div>

          <div className={styles.footer}>
            <div className={styles.shareableSection}>
              {renderShareableSection()}
            </div>
            <TextButton
              onClick={handleOpenEmbeddedCodeModal}
              PrefixIcon={<DSCodeIcon />}
            >
              Copy embedded code
            </TextButton>
          </div>
        </div>
      }
    />
  );
};

export default ShareReportModal;
