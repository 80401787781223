import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Input } from '@DesignSystem/controllers';
import { Table } from '@DesignSystem/tables';
import { Button, Tooltip } from '@ds';

import { DSColors } from '@design-system-outdated/constants';

import { SearchIcon } from '@Icons-outdated';
import { IS_ON_PREMISE } from '@/constants/configConstants';
import { ORGANIZATION_PLAN_ENTERPRISE } from '@shared/constants';
import { useCurrentOrganization, useDebouncedFilter } from '@shared/hooks';

import {
  useServiceAccounts,
  useCreateServiceAccountMutation,
  useRegenerateApiKeyMutation,
  useDeleteServiceAccountMutation
} from '@account-settings/api';

import SmallLoader from '@shared/components/SmallLoader';
import TableEmptyState from '@shared/components/TableEmptyState';
import TableNoSearchResults from '@shared/components/TableNoSearchResults';

import CustomizationsTableCells from './CustomizationTableCells';
import RegenerateAPIKeyModal from './RegenerateAPIKeyModal';
import DeleteServiceAccount from './DeleteServiceAccount';
import CreateServiceAccountModal from './CreateServiceAccountModal';

import './ServiceAccounts.scss';

const COLUMNS = [
  {
    name: 'name',
    id: 'name',
    title: 'Name'
  },
  {
    name: 'createdAt',
    id: 'createdAt',
    title: 'Created Date'
  },
  {
    name: 'createdBy',
    id: 'createdBy',
    title: 'Created By'
  },
  {
    name: 'defaultWorkspaceName',
    id: 'defaultWorkspaceName',
    title: 'Default workspace'
  },
  {
    name: 'apiKey',
    id: 'apiKey',
    title: 'Api Key'
  },
  {
    name: 'actions',
    id: 'actions',
    title: ' '
  }
];

const NO_PADDING_COLUMNS = ['actions'];

const RIGHT_COLUMNS = ['actions'];

const paginationConfig = {
  isDisabled: true
};

const selectionConfig = {
  isDisabled: true
};

const ServiceAccounts = () => {
  const { organizationId } = useParams();
  const [columnOrder, setColumnOrder] = useState([]);
  const [accountToRegenerate, setAccountToRegenerate] = useState(null);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(null);

  const organization = useCurrentOrganization();
  const { data: serviceAccounts, isLoading } = useServiceAccounts(
    organizationId
  );
  const {
    debouncedFilterValue,
    setFilterValue,
    filterValue
  } = useDebouncedFilter('');

  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'actions', width: 20 },
    { columnName: 'apiKey', width: 280 }
  ]);

  const columnExtensions = [
    {
      columnName: 'apiKey',
      minWidth: 280
    }
  ];

  const createServiceAccountMutation = useCreateServiceAccountMutation(
    organizationId
  );
  const regenerateApiKeyMutation = useRegenerateApiKeyMutation(organizationId);
  const deleteServiceAccountMutation = useDeleteServiceAccountMutation(
    organizationId
  );

  const closeAccountKeyRegenerate = () => setAccountToRegenerate(null);
  const closeAccountDelete = () => setAccountToDelete(null);
  const closeCreateAccount = () => setIsCreateAccountOpen(false);

  const filteredServiceAccounts = useMemo(() => {
    if (!debouncedFilterValue?.length) {
      return serviceAccounts;
    }

    return serviceAccounts.filter(account =>
      account.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())
    );
  }, [serviceAccounts, debouncedFilterValue]);

  const dataTypes = useMemo(
    () => [
      {
        cols: ['createdAt', 'lastActivityAt'],
        cell: CustomizationsTableCells.DateCell
      },
      {
        cols: ['apiKey'],
        cell: CustomizationsTableCells.APIKeyCell
      },
      {
        cols: ['name'],
        cell: CustomizationsTableCells.NameCell
      },
      {
        cols: ['actions'],
        cell: ({ row }) => {
          const handleRegenerateClick = () => setAccountToRegenerate(row);
          const handleDeleteClick = () => setAccountToDelete(row);

          return (
            <CustomizationsTableCells.ActionsCell
              onRegenerateClick={handleRegenerateClick}
              onDeleteClick={handleDeleteClick}
            />
          );
        }
      }
    ],
    []
  );

  const handleAccountKeyRegenerateClick = () => {
    regenerateApiKeyMutation.mutate(accountToRegenerate.name);
    closeAccountKeyRegenerate();
  };

  const handleAccountDeleteClick = () => {
    deleteServiceAccountMutation.mutate(accountToDelete.name);
    closeAccountDelete();
  };

  const handleCreateAccountClick = serviceAccount => {
    createServiceAccountMutation.mutate(serviceAccount);
    closeCreateAccount();
  };

  const openCreateAccountModal = () => {
    setIsCreateAccountOpen(true);
  };

  const renderEmptyState = props => {
    if (filterValue?.length && serviceAccounts.length) {
      return <TableNoSearchResults {...props} />;
    }

    if (!serviceAccounts.length) {
      return (
        <TableEmptyState
          label="This organization has no service accounts"
          materialProps={props}
          pictureHeight="50px"
          pictureWidth="56px"
          picturePath="/images/account-settings/service-accounts.svg"
        />
      );
    }

    return null;
  };

  if (isLoading) {
    return (
      <SmallLoader
        primaryMessage="Loading..."
        secondaryMessage="Loading service accounts"
      />
    );
  }

  const canCreateServiceAccounts =
    IS_ON_PREMISE || organization?.paymentPlan === ORGANIZATION_PLAN_ENTERPRISE;

  return (
    <>
      <div className="service-accounts-wrapper">
        <div className="page-title">Service Accounts</div>
        <span className="service-accounts-label">
          Service accounts allow non-human users to access Comet data, they have
          the same permissions as Admin users.
        </span>
        <div className="service-accounts-controller">
          <Input
            placeholder="Search"
            value={filterValue}
            onChange={setFilterValue}
            InlinePrefixIcon={SearchIcon}
          />
          <Tooltip
            content={
              canCreateServiceAccounts
                ? ''
                : 'Service account creation is available exclusively to members on the Enterprise plan'
            }
          >
            <Button
              size="large"
              onClick={openCreateAccountModal}
              disabled={!canCreateServiceAccounts}
            >
              Create Service Account
            </Button>
          </Tooltip>
        </div>

        <div className="service-accounts-table">
          <Table
            columns={COLUMNS}
            rowHeight="70px"
            rowIdKey="name"
            rows={filteredServiceAccounts}
            paginationConfig={paginationConfig}
            selectionConfig={selectionConfig}
            header={{
              headerColor: DSColors.grayColor1
            }}
            maxHeight={358}
            columnWidthsConfig={{
              isDisabled: false,
              columnWidths,
              onColumnWidthsChange: setColumnWidths,
              columnExtensions
            }}
            dataTypes={dataTypes}
            noPaddingColumns={NO_PADDING_COLUMNS}
            rightColumns={RIGHT_COLUMNS}
            renderEmptyState={renderEmptyState}
            columnOrderConfig={{
              isDisabled: false,
              columnOrder,
              onColumnOrderChange: setColumnOrder
            }}
          />
        </div>
      </div>

      <DeleteServiceAccount
        open={Boolean(accountToDelete)}
        serviceAccountName={accountToDelete?.name}
        onPrimaryButtonClick={handleAccountDeleteClick}
        isPrimaryButtonDisabled={false}
        onClose={closeAccountDelete}
      />

      <RegenerateAPIKeyModal
        open={Boolean(accountToRegenerate)}
        serviceAccountName={accountToRegenerate?.name}
        onPrimaryButtonClick={handleAccountKeyRegenerateClick}
        isPrimaryButtonDisabled={false}
        onClose={closeAccountKeyRegenerate}
      />

      <CreateServiceAccountModal
        open={isCreateAccountOpen}
        onPrimaryButtonClick={handleCreateAccountClick}
        isPrimaryButtonDisabled={false}
        onClose={closeCreateAccount}
      />
    </>
  );
};

export default ServiceAccounts;
