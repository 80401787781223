import cx from 'classnames';
import React from 'react';

import { PlayIconWhite } from '@Icons-outdated';

import styles from './VideoThumbnail.module.scss';

type VideoThumbnailProps = {
  name?: string;
  onClick?: () => void;
  src?: string;
};

export function VideoThumbnail({
  name = '',
  onClick,
  src
}: VideoThumbnailProps) {
  const renderImage = () => {
    if (!src) {
      return <div className={styles.noAsset}>Video not found</div>;
    }

    return (
      <>
        <img
          alt={name}
          className={cx({ [styles.pointer]: !!onClick })}
          loading="lazy"
          src={src}
        />
        <div className={styles.playIcon}>
          <PlayIconWhite />
        </div>
      </>
    );
  };

  return (
    <div
      className={styles.thumbnail}
      onClick={onClick}
      data-test={`video-thumbnail-${name}`}
    >
      {renderImage()}
    </div>
  );
}
