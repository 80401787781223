import React from 'react';
import PropTypes from 'prop-types';

import { BasicModal } from '@DesignSystem/modals';

import './ServiceAccounts.scss';

const RegenerateAPIKeyModal = ({
  open,
  onClose,
  onPrimaryButtonClick,
  isPrimaryButtonDisabled
}) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Are You Sure?"
      primaryButtonText="Continue"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      content={
        <div className="regenerate-api-key-content">
          The API key from this service account will be removed and a new one
          will be created.
        </div>
      }
    />
  );
};

RegenerateAPIKeyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  isPrimaryButtonDisabled: PropTypes.func.isRequired
};

export default RegenerateAPIKeyModal;
