// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import cx from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 as uuidv4 } from 'uuid';

import { SectionTabs } from '@DesignSystem/navigation';
import { KeyboardArrowDownIcon } from '@Icons-outdated';
import { PANEL_SECTIONS, SOURCE_TYPE } from '@mpm-druid/constants';
import { useMetricDelete, useCreateMetric } from '@mpm-druid/api';
import { TextButton } from '@ds';
import { DSPlusIcon } from '@ds-icons';
import {
  MetricPopover,
  ClassificationMetricType,
  RegressionMetricType
} from './MetricPopover';
import { BasicModal } from '@DesignSystem/modals';
import { CreateMetricContent, OptionType } from './CreateMetricContent';
import { PanelTab, ModelDetailsType } from '@mpm-druid/types';
import './Panel.scss';

type PanelProps = {
  title: string;
  renderChart: (tabData: PanelTab | null) => React.ReactNode;
  tabs: PanelTab[] | null;
  onChangeTab: (tab: PanelTab | null) => void;
  isFetching: boolean;
  section: PANEL_SECTIONS;
  model: ModelDetailsType;
  disableZoom: boolean;
};

const orderingColumns = [
  'labelColumn',
  'predictionColumn',
  'featureColumn',
  'customValueColumn'
];

const COLUMN_TO_VALUE = {
  labelColumn: 'label',
  predictionColumn: 'prediction',
  featureColumn: 'feature',
  customValueColumn: 'customValue'
} as const;

const initialMetricCreate = {
  label: '',
  prediction: '',
  feature: '',
  customValue: ''
};

export const Panel = ({
  title,
  renderChart,
  tabs,
  onChangeTab,
  isFetching,
  section,
  model
}: PanelProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isPanelExpanded, setPanelExpanded] = useState(true);
  const [
    metricsListAnchorEl,
    setMetricsListAnchorEl
  ] = useState<HTMLElement | null>(null);
  const [openedMetricsSettingsModal, setOpenedMetricsSettingsModal] = useState(
    false
  );
  const [openedDeleteMetricModal, setOpenedDeleteMetricModal] = useState<
    boolean | null
  >(null);

  const [metricIdToDelete, setMetricIdToDelete] = useState<{
    label: string;
    keyProp: string;
  } | null>({ label: '', keyProp: '' });
  const [metricToCreate, setMetricToCreate] = useState<
    null | ClassificationMetricType | RegressionMetricType
  >(null);
  const [createMetricValues, setCreateMetricValues] = useState(
    initialMetricCreate
  );
  const [newMetricLabel, setNewMetricLabel] = useState<string | null>(null);

  const createMetric = useCreateMetric();
  const deleteMetric = useMetricDelete();

  const customMetricsPanel = section === PANEL_SECTIONS.CUSTOM_METRICS;

  const isCreateMetricDisabled = (() => {
    if (!metricToCreate) return false;
    for (const key in metricToCreate as
      | ClassificationMetricType
      | RegressionMetricType) {
      if (
        metricToCreate[key] === true &&
        !createMetricValues[COLUMN_TO_VALUE[key]]?.value &&
        !createMetricValues[COLUMN_TO_VALUE[key]]
      ) {
        return true;
      }
    }
    return false;
  })();

  useEffect(() => {
    if (tabs && tabs?.length > 0 && newMetricLabel) {
      const index = tabs.findIndex(item => item.label === newMetricLabel);
      if (index < 0) return;
      onChangeTab(tabs[index]);
      setActiveTabIndex(index);
    } else if (tabs && tabs?.length > 0 && activeTabIndex < tabs.length) {
      onChangeTab(tabs[activeTabIndex]);
      setActiveTabIndex(activeTabIndex);
    } else if (tabs && tabs?.length > 0) {
      onChangeTab(tabs[0]);
      setActiveTabIndex(0);
    }
  }, [tabs?.length, newMetricLabel]);

  const columnsOptions = useMemo(() => {
    let featureOptions = [] as OptionType[];
    let predictionsOptions = [] as OptionType[];
    let labelsOptions = [] as OptionType[];
    if (!model) {
      return {
        featureOptions,
        predictionsOptions,
        labelsOptions
      };
    }
    const { features, labels } = model || {};
    featureOptions = features
      .filter(feature => feature.source === SOURCE_TYPE.model_input_features)
      .map(feature => {
        return {
          label: `feature_${feature.name}`,
          value: `feature_${feature.name}`,
          type: feature.type
        };
      });

    predictionsOptions = features
      .filter(feature => feature.source === SOURCE_TYPE.model_output_features)
      .map(feature => {
        return {
          label: `prediction_${feature.name}`,
          value: `prediction_${feature.name}`,
          type: feature.type
        };
      });
    labelsOptions = labels.map(label => {
      return {
        label: `label_${label.name}`,
        value: `label_${label.name}`,
        type: label.type
      };
    });
    return {
      featureOptions,
      predictionsOptions,
      labelsOptions
    };
  }, [model]);

  useEffect(() => {
    const collapsed = localStorage.getItem(title);
    if (collapsed) {
      setPanelExpanded(collapsed === 'true');
    } else setPanelExpanded(true);
  }, [title]);

  const closeClickHandler = useCallback(({ label, keyProp }) => {
    setMetricIdToDelete({ label, keyProp });
    setOpenedDeleteMetricModal(true);
  }, []);

  const handleChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    onChangeTab(tabs[tabIndex] || null);
  };

  const metricClickHandler = (
    metricConfig: ClassificationMetricType | RegressionMetricType
  ) => {
    setMetricToCreate(metricConfig);
    setOpenedMetricsSettingsModal(true);
  };

  const deleteMetricHandler = async () => {
    if (metricIdToDelete?.keyProp) {
      await deleteMetric.mutate({ metricId: metricIdToDelete.keyProp });
    }
    setNewMetricLabel(null);
    setOpenedDeleteMetricModal(false);
    setMetricIdToDelete(null);
  };

  const onCloseDeleteModalHandler = () => {
    setOpenedDeleteMetricModal(false);
  };

  const onCloseMetricSettingsHandler = () => {
    setOpenedMetricsSettingsModal(false);
    setCreateMetricValues(initialMetricCreate);
    setTimeout(() => {
      setMetricToCreate(null);
    }, 300);
  };

  const handleCreateMetric = () => {
    const defaultId = uuidv4();
    if (!metricToCreate) return;
    let baseSql = `SELECT ${metricToCreate.name}(`;
    const ending = ') FROM MODEL';
    orderingColumns.forEach(col => {
      if (metricToCreate[col] === true) {
        baseSql +=
          col === 'customValueColumn'
            ? `'${createMetricValues[COLUMN_TO_VALUE[col]]}', `
            : `"${createMetricValues[COLUMN_TO_VALUE[col]]}", `;
      }
    });
    baseSql = baseSql.trimEnd().slice(0, -1);
    const metricName = `${metricToCreate.name}-${defaultId.substring(0, 3)}`;
    createMetric.mutate({
      name: metricName,
      cometSql: baseSql + ending
    });
    setNewMetricLabel(metricName);
    setOpenedMetricsSettingsModal(false);
    setMetricToCreate(null);
    setCreateMetricValues(initialMetricCreate);
  };

  return (
    <div className="ds-panel">
      <div
        className={cx('ds-panel-header', {
          'ds-panel-header-expanded': isPanelExpanded
        })}
      >
        <KeyboardArrowDownIcon
          onClick={() => {
            setPanelExpanded(!isPanelExpanded);
            localStorage.setItem(title, String(!isPanelExpanded));
          }}
          className={cx('expander-arrow', {
            'expanded-arrow': isPanelExpanded
          })}
        />
        <p
          className="ds-panel-header-title"
          onClick={() => setPanelExpanded(prev => !prev)}
        >
          {title}
          {isFetching && <CircularProgress className="circularProgress" />}
        </p>
        {customMetricsPanel && (
          <>
            <TextButton
              type="secondary"
              size="medium"
              PrefixIcon={<DSPlusIcon />}
              onClick={event => {
                setMetricsListAnchorEl(event.currentTarget);
              }}
              active={Boolean(metricsListAnchorEl)}
              className="addMetricButton"
            >
              Add Metric
            </TextButton>
            <MetricPopover
              anchorEl={metricsListAnchorEl}
              onClose={() => setMetricsListAnchorEl(null)}
              metricClickHandler={metricClickHandler}
            />
            <BasicModal
              open={openedDeleteMetricModal}
              onClose={onCloseDeleteModalHandler}
              title={`Delete "${metricIdToDelete?.label}" metric from MPM?`}
              primaryButtonText="Confirm"
              onPrimaryButtonClick={deleteMetricHandler}
              secondaryButtonText="Cancel"
              onSecondaryButtonClick={onCloseDeleteModalHandler}
            />
            <BasicModal
              open={openedMetricsSettingsModal}
              onClose={onCloseMetricSettingsHandler}
              title={`${metricToCreate?.name} Metric Settings`}
              primaryButtonText="Create Metric"
              isPrimaryButtonDisabled={isCreateMetricDisabled}
              onPrimaryButtonClick={handleCreateMetric}
              secondaryButtonText="Cancel"
              onSecondaryButtonClick={onCloseMetricSettingsHandler}
              className="create-metric-modal"
              content={
                <CreateMetricContent
                  configToCreateMetric={metricToCreate}
                  columnsOptions={columnsOptions}
                  createMetricValues={createMetricValues}
                  metricValuesHandler={setCreateMetricValues}
                />
              }
            />
          </>
        )}
      </div>
      <Collapse in={isPanelExpanded}>
        <div className="ds-panel-content">
          {!!tabs?.length && (
            <SectionTabs
              onTabChange={handleChangeTab}
              activeTabIndex={activeTabIndex}
              tabs={tabs}
              tabHeight={30}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              closeClickHandler={customMetricsPanel && closeClickHandler}
            />
          )}
          {renderChart(tabs[activeTabIndex] || null)}
        </div>
      </Collapse>
    </div>
  );
};
