import React, { ForwardedRef, forwardRef } from 'react';

import { LinkButton } from '@design-system/components';
import { LinkButtonProps } from '@design-system/components/buttons/LinkButton/LinkButton';

import styles from '../EmptyExperimentTab.module.scss';
import { DOCS_ROOT_URL } from '@/constants/configConstants';

type EmptyExperimentTabDocsLinkProps = Omit<
  LinkButtonProps,
  'ref' | 'to' | 'text'
> & {
  text?: string;
  path: string;
};

const EmptyExperimentTabDocsLink = (
  {
    text = 'Check our documentation',
    path,
    ...props
  }: EmptyExperimentTabDocsLinkProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const URL = `${DOCS_ROOT_URL}/${path}`;
  return (
    <LinkButton
      {...props}
      ref={ref}
      className={styles.emptyTabDocsLink}
      to={URL}
      text={text}
      openInNewTab
    />
  );
};

const ForwardedEmptyExperimentTabDocsLink = forwardRef<
  HTMLButtonElement,
  EmptyExperimentTabDocsLinkProps
>(EmptyExperimentTabDocsLink);
ForwardedEmptyExperimentTabDocsLink.displayName = 'EmptyExperimentTabDocsLink';

export default ForwardedEmptyExperimentTabDocsLink;
