// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dsPrimaryBlue": `#5155f5`,
	"dsSecondaryBlue": `#e5e5fe`,
	"dsHoverBlue": `#3438d0`,
	"dsClickBlue": `#262ab5`,
	"dsClickBlue2": `#d3d4f0`,
	"dsClickBlue3": `#a8a9e1`,
	"dsClickBlue4": `#7c7fd2`,
	"dsClickBlue5": `#5154c3`,
	"dsBlack": `#191a1c`,
	"dsGray1": `#f5f7fa`,
	"dsGray2": `#eaecf2`,
	"dsGray3": `#dce0ea`,
	"dsGray4": `#b3b9c8`,
	"dsGray5": `#8c95a8`,
	"dsGray6": `#5f677e`,
	"dsGray7": `#3d4355`,
	"dsGrayMainHeader": `#373d4d`,
	"dsGrayLine": `#e2e2e2`,
	"dsGrayBackground": `#f4f4f5`,
	"dsWhite": `#fff`,
	"dsWarning": `#f14668`,
	"dsSuccess": `#00d41f`,
	"dsActionYellow": `#f3b32a`,
	"dsActionYellowLight": `#fef7e7`,
	"dsChartGreen": `#19a979`,
	"dsChartPink": `#ed4a7b`,
	"dsChartYellow": `#f4b400`,
	"dsChartPurple": `#945fcf`,
	"dsChartTurquoise": `#12a4b4`,
	"dsChartBlue": `#525df4`,
	"dsChartOrange": `#fb9341`,
	"dsChartBurgundy": `#bf399e`,
	"dsChartRed": `#ef6868`,
	"dsChartGrey": `#6c8893`,
	"dsChartDarkBlue": `#2e6497`,
	"dsChartOrangeLight": `#feddc3`,
	"dsChartLightBlue": `#5899da`,
	"dsTeamProRed": `#ff594c`,
	"dsFreeYellow": `#ffb42e`,
	"dsTeamsGreen": `#52aea4`,
	"dsTableRowHover": `#f7f8fa`,
	"dsTableNegativeHighlight": `#ffeae9`,
	"dsTableNegativeHighlightHover": `#ffe0df`,
	"dsSparklinePurple": `#777af7`,
	"dsPrimaryBlue100": `#f3f4fe`,
	"dsPrimaryBlue200": `#cfd0ff`,
	"dsPrimaryBlue300": `#8889f8`,
	"dsPrimaryBlue400": `#6c6ff7`,
	"dsPrimaryBlue500": `#575af5`,
	"dsPrimaryBlue600": `#4449f3`,
	"dsPrimaryBlue700": `#3339f1`,
	"dsPrimaryBlue800": `#1f29ef`,
	"dsPrimaryBlue900": `#1823de`,
	"dsPrimaryBlue1000": `#151fcc`,
	"dsMainHeader100": `#f9f9fa`,
	"dsMainHeader200": `#b5b7bc`,
	"dsMainHeader300": `#91959d`,
	"dsMainHeader400": `#7b7f89`,
	"dsMainHeader500": `#6b6f7b`,
	"dsMainHeader600": `#5e6370`,
	"dsMainHeader700": `#545967`,
	"dsMainHeader800": `#4b505f`,
	"dsMainHeader900": `#434958`,
	"dsMainHeader1000": `#3d4252`,
	"dsWarning100": `#fcdce3`,
	"dsWarning200": `#f9b5c2`,
	"dsWarning300": `#f46d88`,
	"dsWarning400": `#e44262`,
	"dsWarning500": `#c83a56`,
	"dsWarning600": `#b2344d`,
	"dsWarning700": `#a02f45`,
	"dsWarning800": `#922a3f`,
	"dsWarning900": `#852639`,
	"dsWarning1000": `#792334`
};
module.exports = ___CSS_LOADER_EXPORT___;
