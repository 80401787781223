import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '@API/api';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';

type MetricDeleteMutationProps = { metricId: string };

export function useMetricDelete() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { modelId } = useParams<{ modelId: string }>();

  return useMutation(
    ({ metricId }: MetricDeleteMutationProps) => {
      return api.delete(`/mpm/v2/custom-metrics/${metricId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['custom-metrics', { modelId }]);
        queryClient.invalidateQueries(['model-details', { modelId }]);
        queryClient.invalidateQueries(['metric-query']);
        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_DELETE_METRIC,
            'The metric was successfully deleted.'
          )
        );
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.ERROR_DELETE_METRIC,
            'Could not delete the metric.'
          )
        );
      }
    }
  );
}
