import isEqual from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import isBoolean from 'lodash/isBoolean';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_COLUMN_WIDTH } from '@experiment-management-shared/constants/experimentGridConstants';
import {
  calculateLockedState,
  generateEmptySection,
  normalizePanel
} from '@experiment-management-shared/utils';
import { INITIAL_CHART_STATE } from '@experiment-management-shared/constants';
import {
  DashboardQuery,
  DashboardTable,
  DashboardView,
  ExperimentGroupsMap,
  Panel,
  PanelGlobalConfig,
  PanelGlobalConfigMap,
  PanelLayoutItem,
  PanelSections,
  PanelsLayout,
  Project,
  ProjectColumn,
  ProjectQueryState,
  ProjectTemplate,
  ProjectTemplateReactGridState,
  RuleGroup
} from '@experiment-management-shared/types';
import {
  DEFAULT_PANEL_GLOBAL_CONFIG,
  DEFAULT_PANELS,
  DEFAULT_SORTING,
  DEFAULT_TABLE,
  DEFAULT_VIEW
} from '@experiment-management-shared/utils/view';
import {
  cleanLayoutToCompare,
  initializeLayout
} from '@experiment-management-shared/utils/layout';
import dashboardHelpers from '@shared/utils/dashboardHelpers';
import {
  extendColumnsWithTagSource,
  filterRulesTree,
  generateEmptyRulesTree,
  getRulesCount
} from '@shared/utils/filterHelpers';
import {
  BUILT_IN_CHART_TYPES,
  DASHBOARD_PANELS_GLOBAL_CONFIG_MAP,
  OUTLIERS_VALUES,
  REQUIRED_COLUMN_NAMES,
  STEP
} from '@/lib/appConstants';

const DASHBOARD_TOGGLE = 'DASHBOARD_TOGGLE';
const EXPERIMENT_TABLE_TOGGLE = 'EXPERIMENT_TABLE_TOGGLE';
export const MIN_COLUMN_NAME_WIDTH = 300;
export const MAX_COLUMN_NAME_WIDTH = Infinity;

const safeParseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (_) {
    return {};
  }
};

export const getOriginalViewId = (view?: DashboardView) => {
  return view?.unsavedView ? view?.createdFromTemplateId : view?.templateId;
};

export const shouldKeepCurrentView = (
  currentView: DashboardView,
  newView: DashboardView
) => {
  if (!currentView) return false;

  const wasDiscarded = currentView?.unsavedView && !newView?.unsavedView;

  return (
    !wasDiscarded &&
    getOriginalViewId(currentView) === getOriginalViewId(newView)
  );
};

const OMIT_KEYS_ON_COMPARE = [
  'panels.sections',
  'panels.search',
  'pinnedExperimentKeys',
  'table.displayColumnOrders',
  'table.pageNumber',
  'table.search',
  'table.searchMode',
  'table.selection'
];

const sortBySlotId = (layoutSlot: PanelLayoutItem) => layoutSlot.i;
const areSameLayout = (value: PanelsLayout, other: PanelsLayout) => {
  return isEqual(
    cleanLayoutToCompare(sortBy(value, sortBySlotId)),
    cleanLayoutToCompare(sortBy(other, sortBySlotId))
  );
};

const areSameSections = (
  sections1: PanelSections,
  sections2: PanelSections
) => {
  // we omits id because after migration from old structure the id is generated fresh new
  // TODO should be deleted id after implemented template migration mechanism
  const OMIT_KEYS = ['layout', 'id'];
  if (sections1.length !== sections2.length) return false;

  return sections1.every((section1, index) => {
    const section2 = sections2[index];

    return (
      isEqual(omit(section1, OMIT_KEYS), omit(section2, OMIT_KEYS)) &&
      // there are undefined values that should be removed for the compare
      areSameLayout(section1.layout, section2.layout)
    );
  });
};

export const isSameView = (
  value: DashboardView,
  other: DashboardView,
  omitKeys = OMIT_KEYS_ON_COMPARE
) => {
  return (
    isEqual(omit(value, omitKeys), omit(other, omitKeys)) &&
    // the backend returns the pinned experiments in different order
    isEqual(
      sortBy(value.pinnedExperimentKeys),
      sortBy(other.pinnedExperimentKeys)
    ) &&
    areSameSections(value.panels.sections, other.panels.sections)
  );
};

export const normalizeLayout = (layout: PanelsLayout) => {
  return layout?.map(layoutItem => {
    const x = isNumber(layoutItem.x) ? layoutItem.x * 3 : layoutItem.x;
    const w = isNumber(layoutItem.w) ? layoutItem.w * 3 : 3;

    return { ...layoutItem, x, w };
  });
};

// this migration should run only once when concept of Global Panel Config is introduced,
// The default state of lock functionality should be calculated
export const migratePanelsToGlobalConfig = (
  panels: Panel[],
  config: PanelGlobalConfig
) => {
  return panels.map(panel => {
    const locked = calculateLockedState(config, panel, {
      [BUILT_IN_CHART_TYPES['BuiltIn/Line']]: [
        'selectedXAxis',
        'transformY',
        'smoothingY',
        'selectedOutliers'
      ]
    });
    return {
      ...panel,
      ...(isBoolean(locked) ? { locked } : {})
    };
  });
};

export const migratePanelsToGlobalConfigV2 = (
  panels: Panel[],
  config: PanelGlobalConfig
) => {
  return panels.map(panel => {
    const shouldHaveSampleSize = [
      BUILT_IN_CHART_TYPES['BuiltIn/Line'],
      BUILT_IN_CHART_TYPES['BuiltIn/Scatter'],
      BUILT_IN_CHART_TYPES['BuiltIn/Bar']
    ].includes(panel.chartType);

    if (shouldHaveSampleSize) {
      panel.sampleSize =
        panel.sampleSize ?? DEFAULT_PANEL_GLOBAL_CONFIG.sampleSize;
    }

    const locked = calculateLockedState(
      config,
      panel,
      DASHBOARD_PANELS_GLOBAL_CONFIG_MAP as PanelGlobalConfigMap
    );

    return {
      ...panel,
      ...(isBoolean(locked) ? { locked } : {})
    };
  });
};

const panelsByType = (panels: Panel[]) => {
  const byType = cloneDeep(INITIAL_CHART_STATE);

  panels.forEach(panel => {
    byType[panel.chartType].push(panel);
  });

  byType.numberOfCharts = panels.length;

  return byType;
};

export const hasDashboardTableSearch = (table: DashboardTable) => {
  return !!table?.search;
};

export const hasDashboardQueryAnyRule = (query: DashboardQuery) => {
  return !!getRulesCount(query?.rulesTree);
};

export const hasDashboardAnyRule = (dashboard: DashboardView) => {
  return hasDashboardQueryAnyRule(dashboard?.query);
};

export const hasDashboardTableAnyGrouping = (table: DashboardTable) => {
  return !!table?.columnGrouping?.length;
};

export const hasDashboardAnyGrouping = (dashboard: DashboardView) => {
  return hasDashboardTableAnyGrouping(dashboard?.table);
};

export const hasAnyExperimentInGrouping = (
  experimentsGroupsMap: ExperimentGroupsMap
) => {
  return (experimentsGroupsMap?.order || []).length > 0;
};

export const fromDashboardToTemplate = ({
  dashboard,
  project,
  selectedView
}: {
  dashboard: DashboardView;
  project: Project;
  selectedView: DashboardView;
}): ProjectTemplate => {
  const {
    hiddenExperimentKeys,
    panels: {
      isAutoRefreshEnabled,
      isVisible: isPanelsVisible,
      layout,
      panels,
      sections,
      config: config
    },
    pinnedExperimentKeys = [],
    query,
    table
  } = dashboard;
  const { projectId: currentProjectId, projectName } = project;
  const {
    createdFromTemplateId = '',
    projectId: templateProjectId,
    templateId = '',
    templateName = 'Unsaved Changes',
    unsavedView = false
  } = selectedView || {};
  const projectId = templateProjectId || currentProjectId;
  const {
    layout: oldLayout = null
  }: { layout: PanelsLayout | null } = safeParseJSON(
    selectedView?.dashboardChartState
  );

  const dashboardChartState = JSON.stringify({
    charts: panelsByType(panels),
    layout: oldLayout
  });

  const experimentQueryState = JSON.stringify({
    activeSegment: {
      segment_id: query.segmentId
    },
    rulesTree: query.rulesTree,
    // We keep saving old filters structure to make view backward compatible with old versions of FE
    ...(isArray(query.filters) && { filters: query.filters })
  });

  const reactGridTableState = JSON.stringify({
    ...omit(table, ['pageNumber', 'search', 'searchMode']),
    columnGrouping: table.columnGrouping.map(columnName => ({ columnName })),
    hiddenExperiments: hiddenExperimentKeys,
    selection: []
  });

  return {
    createdFromTemplateId,
    current: currentProjectId === projectId,
    currentProjectId,
    dashboardChartState,
    experimentQueryState,
    isAutoRefreshEnabled,
    pinnedExperiments: pinnedExperimentKeys,
    projectId: currentProjectId,
    reactGridTableState,
    source: projectName,
    templateId,
    templateName,
    unsavedView,
    v2: {
      reactGridTableState,
      toggles: {
        [DASHBOARD_TOGGLE]: {
          toggleId: DASHBOARD_TOGGLE,
          isToggled: isPanelsVisible
        },
        [EXPERIMENT_TABLE_TOGGLE]: {
          toggleId: EXPERIMENT_TABLE_TOGGLE,
          isToggled: table.isVisible
        }
      }
    },
    v3: { layout, globalConfig: config, sections }
  };
};

export const fromTemplateToDashboard = (
  template: ProjectTemplate,
  defaultView = DEFAULT_VIEW,
  projectColumns: ProjectColumn[]
) => {
  if (!template || !projectColumns) return defaultView;

  /* filter out template columns that don't exists in current project */
  const extendedProjectColumns: ProjectColumn[] = extendColumnsWithTagSource(
    projectColumns
  );
  const isColNameExistsInProject = (name: string) =>
    extendedProjectColumns.some(col => {
      return col.name === name;
    });

  const {
    current,
    dashboardChartState,
    experimentQueryState,
    isAutoRefreshEnabled,
    pinnedExperiments = [],
    reactGridTableState,
    v2: { toggles } = {},
    v3: { layout: newLayout } = {}
  } = template;

  let { v3: { globalConfig, sections } = {} } = template;

  const { charts = {}, layout: oldLayout } = safeParseJSON(dashboardChartState);
  const {
    activeSegment = {},
    filters,
    rulesTree = generateEmptyRulesTree() as RuleGroup
  }: ProjectQueryState = safeParseJSON(experimentQueryState);
  const {
    columnGrouping = [],
    columnSorting = [],
    columnOrders = [],
    columnWidths = [],
    hiddenExperiments = [],
    ...table
  }: ProjectTemplateReactGridState = safeParseJSON(reactGridTableState);
  const { segment_id: segmentId = '' } = activeSegment;

  let panels = dashboardHelpers
    .toChartsArray(charts)
    .map(chart => normalizePanel(chart));

  const filteredColumnSorting = columnSorting.filter(
    ({ columnName }) =>
      isColNameExistsInProject(columnName) &&
      !['isVisibleOnDashboard', 'pinned'].includes(columnName)
  );

  if (!globalConfig) {
    globalConfig = ({
      selectedXAxis: STEP,
      transformY: null,
      smoothingY: 0,
      selectedOutliers: OUTLIERS_VALUES.SHOW
    } as unknown) as PanelGlobalConfig;
    panels = migratePanelsToGlobalConfig(panels, globalConfig);
  }

  if (!globalConfig.sampleSize) {
    const globalMigrationConfig = {
      ...globalConfig,
      sampleSize: DEFAULT_PANEL_GLOBAL_CONFIG.sampleSize
    };
    panels = migratePanelsToGlobalConfigV2(panels, globalMigrationConfig);
  }

  const layout = initializeLayout({
    panels,
    initialLayout: newLayout || normalizeLayout(oldLayout),
    entireRowCharts: false
  });

  if (!sections) {
    sections = [
      {
        ...generateEmptySection(),
        panels,
        layout
      }
    ];
  }

  return {
    hiddenExperimentKeys: hiddenExperiments,
    panels: {
      ...DEFAULT_PANELS,
      isAutoRefreshEnabled,
      isVisible: toggles?.[DASHBOARD_TOGGLE]?.isToggled ?? true,
      layout,
      panels,
      sections,
      config: { ...DEFAULT_PANEL_GLOBAL_CONFIG, ...globalConfig }
    },
    pinnedExperimentKeys: current ? pinnedExperiments : [],
    query: {
      // We keep saving old filters structure to make view backward compatible with old versions of FE
      ...(isArray(filters) && { filters }),
      rulesTree: filterRulesTree(rulesTree, extendedProjectColumns),
      segmentId
    },
    table: {
      ...DEFAULT_TABLE,
      searchMode: {}, // search mode property is not a part of DEFAULT_TABLE, because the pointer to object should be unique for every view, base on that we rerender input
      ...table,
      columnOrders: uniq([
        ...REQUIRED_COLUMN_NAMES,
        ...columnOrders.filter(isColNameExistsInProject)
      ]),
      columnGrouping: columnGrouping
        .map(({ columnName }) => columnName)
        .filter(isColNameExistsInProject),
      columnSorting:
        filteredColumnSorting.length === 0
          ? DEFAULT_SORTING
          : filteredColumnSorting,
      columnWidths: uniqBy(
        [...columnWidths, { columnName: 'Name', width: MIN_COLUMN_NAME_WIDTH }],
        columnWidth => columnWidth.columnName
      ),
      selection: [],
      isVisible: toggles?.[EXPERIMENT_TABLE_TOGGLE]?.isToggled ?? true
    }
  };
};

const getPanelsLockState = (panels: Panel[]) => {
  return panels.map((panel: Panel) => {
    const locked = calculateLockedState(
      DEFAULT_PANEL_GLOBAL_CONFIG as PanelGlobalConfig,
      panel,
      DASHBOARD_PANELS_GLOBAL_CONFIG_MAP as PanelGlobalConfigMap
    );
    return {
      ...panel,
      ...(isBoolean(locked) ? { locked } : {})
    };
  });
};

export const getSuggestedView = ({
  columns = [],
  groups = [],
  panels = []
}: {
  columns?: string[];
  groups?: string[];
  panels?: Panel[];
}) => {
  const columnGrouping = uniq([
    ...DEFAULT_VIEW.table.columnGrouping,
    ...groups
  ]);
  const columnOrders = uniq([...DEFAULT_VIEW.table.columnOrders, ...columns]);
  const newColumnWidths = columns.map(columnName => ({
    columnName,
    width: DEFAULT_COLUMN_WIDTH
  }));
  const columnWidths = uniqBy(
    [...DEFAULT_VIEW.table.columnWidths, ...newColumnWidths],
    ({ columnName }) => columnName
  );

  let groupedPanels = groupBy(panels, panel => {
    const firstSelectedY = Array.isArray(panel.selectedYAxis)
      ? panel.selectedYAxis[0]
      : panel.selectedYAxis || '';

    const sectionParts = firstSelectedY.split('/');

    return sectionParts.length > 1 ? sectionParts[0] : '';
  });

  if (isEmpty(groupedPanels)) {
    groupedPanels = { '': [] };
  }

  const sections = Object.keys(groupedPanels)
    .sort()
    .map(sectionName => {
      const panels = getPanelsLockState(groupedPanels[sectionName]);

      return {
        ...generateEmptySection(),
        title: sectionName || 'Metrics',
        autogenerated: true,
        panels,
        layout: initializeLayout({ panels, initialLayout: null })
      };
    });

  return {
    ...DEFAULT_VIEW,
    panels: {
      ...DEFAULT_VIEW.panels,
      sections
    },
    table: {
      ...DEFAULT_VIEW.table,
      columnGrouping,
      columnOrders,
      columnWidths
    }
  };
};

export const getNameColumnWidth = (
  columnWidths: { columnName: string; width: number }[],
  fixedColumnMaxWidth: number = MIN_COLUMN_NAME_WIDTH
) => {
  const nameWidth = columnWidths?.find(
    ({ columnName }) => columnName === 'Name'
  )?.width;

  // in case a `User A` has a large screen and saved the view with
  // the Name column too wide that the `User B` cannot see any
  // other column and it's unable to resize the Name column again
  const maxViewportNameWidth = window.document.body.clientWidth * 0.9;

  return Math.max(
    fixedColumnMaxWidth,
    Math.min(nameWidth || 0, maxViewportNameWidth, MAX_COLUMN_NAME_WIDTH)
  );
};
