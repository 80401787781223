import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { useLatestExperiment } from '@shared/hooks';
import { getIsExperimentRun } from '@experiment-management-shared/utils/experimentHelpers';

import useExperimentsDetails from '@API/experiments/useExperimentsDetails';
import useProject from '@API/project/useProject';
import useCurrentUser from '@API/auth/useCurrentUser';
import { useActiveWorkspaceName } from '@shared/hooks';

import DetectedExperimentUI from '@experiment-management-shared/components/DetectedExperiment';
const REFETCH_INTERVAL = 5000;

const DetectedExperiment = () => {
  const history = useHistory();
  const [experimentHasBeenRun, setExperimentHasBeenRun] = useState(false);
  const [isExperimentCompleted, setIsExperimentCompleted] = useState(false);

  const { data: currentUser } = useCurrentUser({ refetchOnMount: true });
  const activeWorkspaceName = useActiveWorkspaceName();

  const initiallyHadExperiments = currentUser?.hasExperiments;
  const shouldCheckLatestExperiment =
    !initiallyHadExperiments && !isExperimentCompleted;

  const { data: latestExperiment } = useLatestExperiment({
    refetchInterval: REFETCH_INTERVAL,
    enabled: shouldCheckLatestExperiment
  });

  const { data: project } = useProject(
    {
      projectName: latestExperiment?.projectName,
      workspace: activeWorkspaceName
    },
    {
      enabled: !!(
        latestExperiment?.projectName &&
        activeWorkspaceName &&
        shouldCheckLatestExperiment
      )
    }
  );

  const experimentKeys = useMemo(() => [latestExperiment?.experimentKey], [
    latestExperiment
  ]);

  const { data: experimentsData } = useExperimentsDetails(
    {
      experimentKeys,
      projectId: project?.projectId
    },
    {
      enabled: !!(
        latestExperiment?.hasExperiment &&
        latestExperiment?.experimentKey &&
        shouldCheckLatestExperiment &&
        project?.projectId
      ),
      refetchInterval: REFETCH_INTERVAL
    }
  );

  const [experiment] = useMemo(() => experimentsData || [], [experimentsData]);

  const isRunExperiment = getIsExperimentRun(experiment);

  useEffect(() => {
    if (!isRunExperiment && experimentHasBeenRun) {
      setIsExperimentCompleted(true);
    }
  }, [isRunExperiment, experimentHasBeenRun]);

  useEffect(() => {
    if (isRunExperiment) {
      setExperimentHasBeenRun(true);
    }
  }, [isRunExperiment]);

  const isSuccessfulExperimentRun = experimentHasBeenRun && !isRunExperiment;

  // works for initial experiment running only
  if (initiallyHadExperiments || !experimentHasBeenRun) {
    return null;
  }

  const handleClickButton = () => {
    history.push(
      `/${activeWorkspaceName}/${latestExperiment?.projectName}/${latestExperiment?.experimentKey}`
    );
  };

  const title = isRunExperiment
    ? 'Comet detected your experiment, we are loading all your data'
    : 'You successfully created your first Comet experiment';
  const label = isRunExperiment
    ? 'You can go to platform to see your charts update live.'
    : 'Visualize your experiment results by creating your first panel!';
  const buttonLabel = isRunExperiment
    ? 'Track your experiment live'
    : 'View your newest experiment';

  return (
    <DetectedExperimentUI
      handleClickButton={handleClickButton}
      isSuccessfulExperimentRun={isSuccessfulExperimentRun}
      label={label}
      isRunExperiment={isRunExperiment}
      title={title}
      buttonLabel={buttonLabel}
    />
  );
};

export default DetectedExperiment;
