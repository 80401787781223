import React, {
  ChangeEvent,
  FormEventHandler,
  HTMLProps,
  ReactNode
} from 'react';
import cx from 'classnames';
import { RadioContext } from './useRadioContext';
import classes from './Radio.module.scss';

interface RadioGroupProps
  extends Omit<HTMLProps<HTMLFieldSetElement>, 'onChange'> {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
}

const RadioGroup = ({
  value,
  name,
  children,
  ...restProps
}: RadioGroupProps) => {
  // there is no way to show that fieldset contains radio buttons only
  const typedOnChange = restProps.onChange
    ? ((restProps.onChange as unknown) as FormEventHandler<HTMLFieldSetElement>)
    : undefined;

  return (
    <RadioContext.Provider value={{ value, name }}>
      <fieldset
        role="radiogroup"
        {...restProps}
        onChange={typedOnChange}
        className={cx(restProps.className, classes.radioGroup)}
      >
        {children}
      </fieldset>
    </RadioContext.Provider>
  );
};

export default RadioGroup;
