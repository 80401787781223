import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { getPlaceholderForModelVersion } from '@shared/utils/model-streamline-registration';
import RegisterModelTextField from './RegisterModelTextField';

const RegisterModelVersionTextField = ({
  latestVersion,
  modelVersion,
  setModelVersion,
  validationNewModelVersion,
  placeholder
}) => {
  const semanticLabel = (
    <Box>
      <a href="https://semver.org/" target="_blank" rel="noreferrer">
        Semantic
      </a>{' '}
      versioning only
    </Box>
  );

  return (
    <RegisterModelTextField
      label={`Model version ${
        latestVersion ? `(last version ${latestVersion})` : ''
      }`}
      value={modelVersion}
      placeholder={
        latestVersion
          ? getPlaceholderForModelVersion(latestVersion)
          : placeholder
      }
      dataTest="streamline-model-sidebar-model-version-to-register"
      onChange={setModelVersion}
      isValid={validationNewModelVersion?.isValid}
      helperText={semanticLabel}
      errorText={
        !validationNewModelVersion?.validVersion
          ? semanticLabel
          : 'This model version already exists, choose another version.'
      }
    />
  );
};

RegisterModelVersionTextField.defaultProps = {
  modelVersion: '',
  latestVersion: null,
  validationNewModelVersion: {},
  placeholder: '1.0.0'
};

RegisterModelVersionTextField.propTypes = {
  validationNewModelVersion: PropTypes.shape({
    validVersion: PropTypes.bool,
    versionExists: PropTypes.bool,
    isValid: PropTypes.bool
  }),
  placeholder: PropTypes.string,
  latestVersion: PropTypes.string,
  setModelVersion: PropTypes.func.isRequired,
  modelVersion: PropTypes.string
};

export default RegisterModelVersionTextField;
