import { useEffect } from 'react';

import useCurrentUser from '@API/auth/useCurrentUser';
import useFullColumnsData from '@API/project/useFullColumnsData';
import useProject from '@API/project/useProject';
import { trackEvent } from '@shared/utils/eventTrack';
import { useActiveWorkspace } from '@shared/hooks';

import { useCurrentOrganization } from '@shared/hooks';

import { viewEvents } from '@/constants/trackingEventTypes';

function useAutoGeneratedViewEvent({
  defaultView,
  isIdleSelectedView,
  isLoadingSelectedView,
  selectedView,
  suggestedViewFields
}) {
  const currentOrganization = useCurrentOrganization();
  const currentWorkspace = useActiveWorkspace();
  const { data: columnsData } = useFullColumnsData();
  const { data: currentUser } = useCurrentUser();
  const { data: project } = useProject();

  const isAutoGeneratedView = Boolean(defaultView && !selectedView);

  useEffect(() => {
    if (isIdleSelectedView || isLoadingSelectedView || !isAutoGeneratedView) {
      return;
    }

    const {
      suggestedCharts = [],
      suggestedFeaturePanels = [],
      suggestedGroupingColumns = [],
      suggestedTableColumns = []
    } = columnsData;

    trackEvent(viewEvents.AUTO_GENERATED_RENDERED, {
      organization_id: currentOrganization?.id,
      organization_name: currentOrganization?.name,
      workspace_id: currentWorkspace?.id,
      workspace_name: currentWorkspace?.name,
      project_id: project?.projectId,
      project_name: project?.projectName,
      user_id: currentUser?.username,
      suggested_table_columns: suggestedTableColumns.map(
        column => column.projectColumnId
      ),
      suggested_grouping: suggestedGroupingColumns.map(
        column => column.projectColumnId
      ),
      suggested_charts: suggestedCharts.map(column => column.projectColumnId),
      suggested_panels: suggestedFeaturePanels.map(panel => panel.templateName)
    });
  }, [
    isAutoGeneratedView,
    isIdleSelectedView,
    isLoadingSelectedView,
    suggestedViewFields
  ]);
}

export default useAutoGeneratedViewEvent;
