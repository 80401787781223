import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IconButton, Tooltip } from '@ds';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styles from './SectionActions.module.scss';
import {
  DSArrowDownIcon,
  DSArrowUpIcon,
  DSCopyIcon,
  DSDeleteIcon,
  DSLinkIcon
} from '@ds-icons';

const nodeToPath = target => {
  let currentNode = target;
  const path = [currentNode];

  while (currentNode.parentNode) {
    currentNode = currentNode.parentNode;
    path.push(currentNode);
  }

  return path;
};

const SectionActions = ({
  children,
  editMode,
  id,
  isEditing,
  onDelete,
  onDuplicate,
  onEditingChange,
  onMoveDown,
  onMoveUp,
  position,
  sectionsAmount
}) => {
  const editable = editMode === REPORT_EDIT_MODES.EDIT;
  const isFirstSection = position === 0;
  const isLastSection = position === sectionsAmount - 1;

  const handleClickAway = ({ target }) => {
    if (!isEditing) return;

    const path = nodeToPath(target);
    const isTarget = path.some(node => {
      return node.id === 'report-page';
    });

    if (isTarget) {
      onEditingChange(false);
    }
  };

  return (
    <div
      className={cx(styles.actions, {
        [styles.editable]: editable,
        [styles.editing]: isEditing,
        [styles.viewOnly]: !editable
      })}
    >
      {editable && (
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <Tooltip content="Duplicate section" placement="right">
              <IconButton
                onClick={onDuplicate}
                size="large"
                active
                Icon={<DSCopyIcon />}
              />
            </Tooltip>
          </div>

          <div className={styles.buttonContainer}>
            <Tooltip content="Delete section" placement="right">
              <IconButton
                onClick={onDelete}
                size="large"
                active
                Icon={<DSDeleteIcon />}
              />
            </Tooltip>
          </div>

          <div className={styles.buttonContainer}>
            <Tooltip content="Move section up" placement="right">
              <IconButton
                onClick={onMoveUp}
                size="large"
                type={isFirstSection ? 'tertiary' : 'primary'}
                active
                disabled={isFirstSection}
                Icon={<DSArrowUpIcon />}
              />
            </Tooltip>
          </div>

          <div className={styles.buttonContainer}>
            <Tooltip content="Move section down" placement="right">
              <IconButton
                onClick={onMoveDown}
                type={isLastSection ? 'tertiary' : 'primary'}
                disabled={isLastSection}
                size="large"
                active
                Icon={<DSArrowDownIcon />}
              />
            </Tooltip>
          </div>
        </div>
      )}

      {editable && !isEditing && (
        <div
          className={styles.childrenOverlay}
          onClick={() => onEditingChange(true)}
        />
      )}

      {editMode === REPORT_EDIT_MODES.VIEW && (
        <Link to={`#${id}`}>
          <IconButton type="secondary" Icon={<DSLinkIcon />} />
        </Link>
      )}

      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={cx(styles.children, {
            [styles.viewOnly]: !editable
          })}
        >
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};

SectionActions.propTypes = {
  children: PropTypes.node.isRequired,
  editMode: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onEditingChange: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  sectionsAmount: PropTypes.number.isRequired
};

export default SectionActions;
