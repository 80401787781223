import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import get from 'lodash/get';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import { trackEvent } from '@shared/utils/eventTrack';
import {
  DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE,
  EXPERIMENT_PANELS_GLOBAL_CONFIG_MAP
} from '@/lib/appConstants';
import { EXPERIMENT_DETAILS_SAMPLE_SIZES } from '@experiment-details/utils/panels';
import { singleExperimentPanelEvents } from '@experiment-details/constants/events';
import { calculatePanelGlobalConfig } from '@experiment-details/utils/templates';
import useExperimentViewState from '@experiment-management-shared/hooks/useExperimentViewState';
import {
  EXPERIMENT_VIEW_TAB_FIELDS,
  BUILT_IN_CHART_TYPES
} from '@experiment-management-shared/constants/chartConstants';

import SmallLoader from '@shared/components/SmallLoader';
import { useLocation } from 'react-router';

import {
  getMetricCharts,
  getMetricChartsTemplate
} from '@/reducers/experimentDetails/chartsReducer';
import {
  selectExperimentKeys,
  selectIsComparePage
} from '@/reducers/ui/experimentsUiReducer';

import {
  PANEL_SOURCES,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';
import { getIsUserLoggedIn } from '@/reducers/userReducer';
import {
  calculateHasLockedPanels,
  calculateLineTypeStrategy,
  calculatePanelsCount,
  useExperimentPanelColors
} from '@experiment-management-shared';
import PanelModal from '@experiment-management-shared/components/PanelModal';
import usePanelModalHandlers from '@experiment-management-shared/hooks/usePanelModalHandlers';
import PanelSectionsWrapper from '@experiment-management-shared/components/PanelSectionsWrapper';
import PanelConfigBar from './PanelConfigBar/PanelConfigBar';

import useMetricChartQueryParams from '@experiment-details/components/panels/PanelConfigBar/useMetricChartQueryParams';
import { useComputeClearCacheMutation } from '@experiment-management-shared/api';
import useSuggestedPanels from './hooks/useSuggestedPanels';

import styles from './PanelsTab.module.scss';
import usePanelsTabSearch from './hooks/usePanelsTabSearch';

const PANELS_WITH_LOCK = [BUILT_IN_CHART_TYPES['BuiltIn/Line']];

const EVENTS_MAP = {
  addPanel: singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANEL_ADD_PANEL,
  deletePanel:
    singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANEL_OPTIONS_DELETE,
  lockClick: singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_LOCK,
  searchPanels: singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_SEARCH
};

const PanelsTab = ({ experiments }) => {
  const location = useLocation();
  const currentTemplate = useSelector(getMetricChartsTemplate);
  const experimentKeys = useSelector(selectExperimentKeys);
  const isComparePage = useSelector(selectIsComparePage);

  const {
    searchValue,
    debouncedSearchValue,
    setSearchValue,
    resetSearch
  } = usePanelsTabSearch();

  const [experimentKey] = experimentKeys;

  const [revision, setRevision] = useState(0);
  const [
    isAutoRefreshEnabled,
    setIsAutoRefreshEnabled
  ] = useExperimentViewState({
    experimentKey,
    template: currentTemplate,
    valuePath: `${EXPERIMENT_VIEW_TAB_FIELDS.PANELS}.isAutoRefreshEnabled`
  });

  const [sections, setSections] = useExperimentViewState({
    experimentKey,
    template: currentTemplate,
    valuePath: `${EXPERIMENT_VIEW_TAB_FIELDS.PANELS}.sections`
  });

  const hasLockedPanels = calculateHasLockedPanels(sections);
  const hasPanels = calculatePanelsCount(sections) > 0;

  useMetricChartQueryParams({
    location,
    experimentKey,
    currentTemplate,
    searchText: debouncedSearchValue
  });

  const handleUpdateAutoRefreshStatus = useCallback(
    e => {
      trackEvent(
        singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANELS_AUTO_REFRESH
      );

      setIsAutoRefreshEnabled(e.target.checked);
    },
    [setIsAutoRefreshEnabled]
  );

  const chartsPlotly = useSelector(getMetricCharts);
  const isUserLoggedIn = useSelector(getIsUserLoggedIn);

  const templateByExperimentKey = useMemo(
    () => get(chartsPlotly, experimentKey, chartsPlotly),
    [chartsPlotly, experimentKey]
  );

  const projectId = get(currentTemplate, 'project_id', '');

  const computeClearCacheMutation = useComputeClearCacheMutation(projectId);

  const panelGlobalConfig = useMemo(() => {
    return calculatePanelGlobalConfig({
      x_axis: isComparePage
        ? currentTemplate.panels.compareXAxis
        : currentTemplate.x_axis,
      transformY: currentTemplate.transformY,
      smoothing: currentTemplate.smoothing,
      outliers: currentTemplate.outliers
    });
  }, [
    isComparePage,
    currentTemplate.panels.compareXAxis,
    currentTemplate.x_axis,
    currentTemplate.transformY,
    currentTemplate.smoothing,
    currentTemplate.outliers
  ]);

  const colorMap = useExperimentPanelColors();
  const metricColorMap = isComparePage ? undefined : colorMap;

  const {
    handleAddBuiltInPanel,
    handleAddCustomPanel,
    handleEditBuiltInPanel,
    handleEditCustomPanel
  } = usePanelModalHandlers({
    sections: sections,
    onSectionsChange: setSections,
    panelGlobalConfig,
    globalConfigMap: EXPERIMENT_PANELS_GLOBAL_CONFIG_MAP
  });

  // @todo should be moved to place where autogenerated template is creating
  useSuggestedPanels({
    experimentKey,
    projectId,
    handleAddCustomPanel,
    currentTemplate,
    sectionId: sections[0]?.id
  });

  const panelHandlers = useMemo(() => {
    return {
      onOptionsClick: () => {
        trackEvent(
          singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_PANEL_OPTIONS
        );
      }
    };
  }, []);

  const configModifier = useCallback(
    config => {
      const modifiedConfig = {
        ...config,
        lineTypeStrategy: calculateLineTypeStrategy({
          isComparePage
        }),
        chartClickEnabled: isComparePage
      };

      if (!isComparePage) {
        // for single experiment view grouping by param is disabled
        if (config.grouping) {
          modifiedConfig.grouping = {
            ...config.grouping,
            groupByParameter: null
          };
        }
      }

      return modifiedConfig;
    },
    [isComparePage]
  );

  const panelSharedConfig = useMemo(
    () => ({
      isAutoRefreshEnabled,
      activeIntervalFetchDelay: 10000,
      showLockCharts: PANELS_WITH_LOCK,
      globalConfigMap: EXPERIMENT_PANELS_GLOBAL_CONFIG_MAP,
      sampleSizes: EXPERIMENT_DETAILS_SAMPLE_SIZES,
      hiddenMenuItems: ['embed-panel', 'share-panel'],
      fetchFull: true,
      hiddenExperimentKeys: [],
      metricColorMap,
      experiments,
      experimentKeys
    }),
    [experimentKeys, experiments, isAutoRefreshEnabled, metricColorMap]
  );

  const handleRefreshPanels = () => {
    setRevision(r => r + 1);

    computeClearCacheMutation.mutate();
  };

  const renderBody = () => {
    return (
      <PanelSectionsWrapper
        key={revision}
        search={debouncedSearchValue}
        sections={sections}
        panelGlobalConfig={panelGlobalConfig}
        panelHandlers={panelHandlers}
        onSectionsChange={setSections}
        resetSearch={resetSearch}
        defaultSampleSize={DEFAULT_SINGLE_EXPERIMENT_SAMPLE_SIZE}
        eventsMap={EVENTS_MAP}
        configModifier={configModifier}
        panelSharedConfig={panelSharedConfig}
      />
    );
  };

  const renderPanelModal = () => {
    if (location.hash.includes(`#${SELECT_PANEL_PATH}`)) {
      return (
        <PanelModal
          experiments={experiments}
          experimentKeys={experimentKeys}
          onAddBuiltInPanel={handleAddBuiltInPanel}
          onAddCustomPanel={handleAddCustomPanel}
          onEditBuiltInPanel={handleEditBuiltInPanel}
          onEditCustomPanel={handleEditCustomPanel}
          source={PANEL_SOURCES.EXPERIMENT}
        />
      );
    }

    return null;
  };

  if (
    templateByExperimentKey?.isLoadingProjectTemplate ||
    (templateByExperimentKey?.isLoadingUserTemplates && isUserLoggedIn)
  ) {
    return <SmallLoader />;
  }

  return (
    <div className={styles.panelsTab}>
      <PanelConfigBar
        isComparePage={isComparePage}
        currentTemplate={currentTemplate}
        experimentKey={experimentKeys[0]}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        disabled={!hasPanels}
        isAutoRefreshEnabled={isAutoRefreshEnabled}
        onAutoRefreshChange={handleUpdateAutoRefreshStatus}
        onRefreshPanelsClick={handleRefreshPanels}
        hasLockedPanels={hasLockedPanels}
      />
      <div className={styles.content}>{renderBody()}</div>
      {renderPanelModal()}
    </div>
  );
};

PanelsTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default PanelsTab;
