import React from 'react';

import { InfoIcon } from '@Icons-outdated';
import { Tooltip } from '@ds';
import styles from './RenderTooltip.module.scss';

type RenderTooltipProps = {
  message: string;
};

export const RenderTooltip = ({ message }: RenderTooltipProps) => {
  return (
    <Tooltip content={message} placement="top">
      <span className={styles.iconWrapper}>
        <InfoIcon className={styles.tooltipIcon} />
      </span>
    </Tooltip>
  );
};
