import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';

const getAssetByType = async ({
  experimentKey,
  userFileName
}: UseAssetByNameOpts) => {
  const { data } = await api.get('asset/download-by-name', {
    params: {
      experimentKey,
      userFileName
    }
  });

  return data;
};

type UseAssetByNameOpts = {
  experimentKey: string;
  userFileName: string;
};

export default function useAssetByName<T>(
  params: UseAssetByNameOpts,
  options: QueryConfig<T>
) {
  return useQuery(
    ['experimentAssetByName', params],
    () => getAssetByType(params),
    {
      ...options
    }
  );
}
