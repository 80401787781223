import React from 'react';
import { DSCheckMarkIcon, DSCloseIcon } from '@design-system/icons';
import { IconButton, Tooltip } from '@ds';
import styles from './MetricRowActions.module.scss';

type MetricsTableSubmitActionsProps = {
  onSubmit: () => void;
  onCancel: () => void;
};
const MetricsTableSubmitActions: React.FC<MetricsTableSubmitActionsProps> = ({
  onSubmit,
  onCancel
}) => {
  return (
    <div className={styles.metricRowActions}>
      <Tooltip content="Click or hit 'enter' to save" placement="top">
        <IconButton
          className="submit-button"
          Icon={<DSCheckMarkIcon />}
          onClick={onSubmit}
          size="small"
        />
      </Tooltip>
      <Tooltip content="Click or hit 'esc' to cancel" placement="top">
        <IconButton
          type="secondary"
          Icon={<DSCloseIcon />}
          size="small"
          onClick={onCancel}
        />
      </Tooltip>
    </div>
  );
};

export default MetricsTableSubmitActions;
