import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextButton } from '@ds';
import { Input } from '@DesignSystem/controllers';
import { BasicModal } from '@DesignSystem/modals';
import { GitHubIcon } from '@Icons-outdated';
import { VerifyEmailLink } from '../VerifyEmailLink';
import './AccountInfo.scss';
import { isValidEmail } from '@/lib/utils';

const AccountInfo = ({
  email,
  username,
  isGitHub,
  onChangePassword,
  onChangeEmail,
  isEmailUpdating,
  isPasswordUpdating
}) => {
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [newEmail, setNewEmail] = useState(null);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  const openPasswordModal = () => setIsOpenPasswordModal(true);
  const closePasswordModal = () => setIsOpenPasswordModal(false);
  const openEmailModal = () => setIsOpenEmailModal(true);

  const closeEmailModal = () => {
    setIsOpenEmailModal(false);
    setNewEmail(null);
    setIsInvalidEmail(false);
    setPassword('');
    setIsInvalidPassword(false);
  };

  const handleChangePassword = async () => {
    await onChangePassword();
    openPasswordModal();
  };

  const handleChangeEmail = async () => {
    let hasErrors = false;

    if (!password) {
      hasErrors = true;
      setIsInvalidPassword(true);
    } else {
      setIsInvalidPassword(false);
    }

    if (!isValidEmail(newEmail)) {
      hasErrors = true;
      setIsInvalidEmail(true);
    } else {
      setIsInvalidEmail(false);
    }

    if (hasErrors) return;

    await onChangeEmail({ newEmail, password });
    closeEmailModal();
  };

  const renderGitHubBlock = () => (
    <>
      <div className="label-container">
        <span className="field-label">Email: </span>
        <span className="value-label">{email}</span>
        <VerifyEmailLink />
      </div>
      <div className="input-container github-label">
        <span className="field-label">Login:&nbsp;</span>
        <span className="value-label">With GitHub</span>
        <GitHubIcon color="#191717" />
      </div>
    </>
  );

  const renderChangePasswordContent = () => (
    <div className="change-password-content">
      <div className="sent-email-block">
        Email sent to <b data-mask-test="email@domain.com">{email}</b>
      </div>

      <div>
        Check your email for a link to reset your password. If it does not
        appear in the next few minutes, check your email spam folder.
      </div>
    </div>
  );

  const renderChangeEmailContent = () => (
    <div className="change-email-content">
      <div className="sent-email-block">
        <Input
          title="New Email"
          placeholder="Enter email"
          value={newEmail}
          onChange={setNewEmail}
          invalidMsg={
            isInvalidEmail
              ? 'Incorrect email address please enter a valid one'
              : null
          }
          invalidBorder={isInvalidEmail}
          lineHeight="21px"
        />
      </div>

      <div className="sent-email-block">
        <Input
          title="Password"
          placeholder="Enter password"
          value={password}
          onChange={setPassword}
          invalidMsg={isInvalidPassword ? 'Incorrect password' : null}
          invalidBorder={isInvalidPassword}
          lineHeight="21px"
          inputType="password"
        />
      </div>
    </div>
  );

  const renderBasicLoginBlock = () => (
    <>
      <BasicModal
        open={isOpenPasswordModal}
        onClose={closePasswordModal}
        title="Change Password"
        content={renderChangePasswordContent()}
        primaryButtonText="Got it"
        onPrimaryButtonClick={closePasswordModal}
      />

      <BasicModal
        open={isOpenEmailModal}
        onClose={closeEmailModal}
        title="Change Email"
        content={renderChangeEmailContent()}
        primaryButtonText="Confirm"
        onPrimaryButtonClick={handleChangeEmail}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeEmailModal}
        isPrimaryButtonDisabled={isEmailUpdating}
      />

      <div className="input-container">
        <Input
          title="Email"
          value={email}
          onClick={openEmailModal}
          asButton
          lineHeight="21px"
        />
        <VerifyEmailLink />
      </div>
      <div className="input-container">
        <Input
          title="Password"
          value="***************"
          disabled
          lineHeight="21px"
        />
      </div>

      <div className="change-password-container">
        <TextButton
          onClick={handleChangePassword}
          disabled={isPasswordUpdating}
          type="tertiary"
        >
          Change password
        </TextButton>
      </div>
    </>
  );

  return (
    <div className="account-info-container">
      <div className="label-container">
        <span className="field-label">Username: </span>
        <span className="value-label" data-mask-test="username">
          {username}
        </span>
      </div>

      <div className="inputs-container">
        {isGitHub ? renderGitHubBlock() : renderBasicLoginBlock()}
      </div>
    </div>
  );
};

AccountInfo.defaultProps = {
  email: '',
  username: '',
  isGitHub: false,
  onChangePassword: noop,
  onChangeEmail: noop,
  isEmailUpdating: false,
  isPasswordUpdating: false
};

AccountInfo.propTypes = {
  email: PropTypes.string,
  username: PropTypes.string,
  isGitHub: PropTypes.bool,
  onChangePassword: PropTypes.func,
  onChangeEmail: PropTypes.func,
  isEmailUpdating: PropTypes.bool,
  isPasswordUpdating: PropTypes.bool
};

export default AccountInfo;
