import { useEffect } from 'react';
import { matchPath, useHistory } from 'react-router';

const useActionForNotMatchingRoute = ({ pathname, callback }) => {
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = history.listen(newLocation => {
      if (
        !matchPath(newLocation.pathname, {
          path: pathname,
          exact: true,
          strict: false
        })
      ) {
        callback();
      }
    });

    return () => unsubscribe();
  }, [history, callback, pathname]);
};

export default useActionForNotMatchingRoute;
