import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '@ds';
import { useDispatch } from 'react-redux';
import { BasicModal } from '@DesignSystem/modals';

import { trackEvent } from '@shared/utils/eventTrack';
import { singleExperimentPanelEvents } from '@experiment-details/constants/events';
import { useParams } from 'react-router';
import experimentActions from '@/actions/experimentActions';
import { DOCS_ROOT_URL } from '@/lib/appConstants';
import { DISABLE_STOP_BUTTON } from '@/constants/configConstants';
import styles from './ExperimentActionsModal.module.scss';
import { DSStopIcon } from '@ds-icons';

const StopRunningExperimentButton = ({
  runActive,
  fileName,
  crashReason,
  disable: parentDisable
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { experimentKey } = useParams();

  const dispatch = useDispatch();
  const [hasUserRequestedStop, setUserRequestedStop] = useState(false);

  const isExperimentActive = !!runActive && !crashReason;
  const isStopping = hasUserRequestedStop && isExperimentActive;
  const isRunningInJupyterNotebook = fileName === 'Jupyter interactive';

  const isDisabled =
    parentDisable ||
    crashReason ||
    DISABLE_STOP_BUTTON ||
    isStopping ||
    !isExperimentActive;

  if (isRunningInJupyterNotebook) return null;

  const closeModal = () => setIsOpen(false);
  const stopExperimentsDocsUrl = `${DOCS_ROOT_URL}/guides/comet-dashboard/more-in-projects/#stop-an-experiment`;

  const handleConfirmOverride = () => {
    if (runActive) {
      dispatch(experimentActions.stopRunningExperiment(experimentKey));
      setUserRequestedStop(true);
    }
    closeModal();
  };

  const getButtonLabel = () => {
    if (isStopping) {
      return 'Stopping...';
    }

    if (hasUserRequestedStop) {
      return 'Stopped';
    }

    return 'Stop';
  };

  return (
    <>
      <TextButton
        PrefixIcon={<DSStopIcon />}
        type="secondary"
        onClick={() => {
          setIsOpen(true);
          trackEvent(singleExperimentPanelEvents.SINGLE_EXPERIMENT_PAGE_STOP);
        }}
        disabled={isDisabled}
      >
        {getButtonLabel()}
      </TextButton>

      <BasicModal
        title="Are you sure?"
        open={isOpen}
        onClose={closeModal}
        content={
          <div className={styles.stopRunningExperimentContainer}>
            Are you sure you want to stop running these experiments? You can
            learn more about stopping a running experiment{' '}
            <a href={stopExperimentsDocsUrl}> here</a>.
          </div>
        }
        primaryButtonText="Stop experiment"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={handleConfirmOverride}
        onSecondaryButtonClick={closeModal}
      />
    </>
  );
};

StopRunningExperimentButton.propTypes = {
  runActive: PropTypes.bool.isRequired,
  fileName: PropTypes.string.isRequired,
  crashReason: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired
};

export default StopRunningExperimentButton;
