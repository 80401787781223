// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImagePanel-module__annotationTabContainer--bXCf6 p{font-size:14px;color:var(--gray-6);font-weight:500}.ImagePanel-module__pillsGrid--NfS9Z{margin-top:16px;display:flex;justify-content:start;flex-wrap:wrap;gap:8px;margin-left:25px}.ImagePanel-module__fieldContainer--k4YAi{margin-bottom:40px;line-height:1}.ImagePanel-module__field--TI9X0{line-height:1;align-items:center;display:flex}.ImagePanel-module__checkbox--o1XqY{margin-top:20px}.ImagePanel-module__checkbox--o1XqY p{margin-left:10px}.ImagePanel-module__slider--hrd8H{width:250px;margin-left:3px;margin-top:30px}.ImagePanel-module__circularProgress--Sx35m{height:100%;width:100%;display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderWidth": `250px`,
	"annotationTabContainer": `ImagePanel-module__annotationTabContainer--bXCf6`,
	"pillsGrid": `ImagePanel-module__pillsGrid--NfS9Z`,
	"fieldContainer": `ImagePanel-module__fieldContainer--k4YAi`,
	"field": `ImagePanel-module__field--TI9X0`,
	"checkbox": `ImagePanel-module__checkbox--o1XqY ImagePanel-module__field--TI9X0`,
	"slider": `ImagePanel-module__slider--hrd8H`,
	"circularProgress": `ImagePanel-module__circularProgress--Sx35m`
};
module.exports = ___CSS_LOADER_EXPORT___;
