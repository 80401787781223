import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import modelRegistryApi from '../../util/modelRegistryApi';
import alertsUtil from '../../util/alertsUtil';
import { dialogTypes } from '../../constants/alertTypes';

const useModelRegistryColumnsMutation = ({ registryModelId }) => {
  const query = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    ({ columns }) =>
      modelRegistryApi.updateModelRegistryView(registryModelId, columns),
    {
      onSuccess: async () => {
        await query.invalidateQueries([
          'modelRegistryById',
          { registryModelId }
        ]);

        dispatch(
          alertsUtil.openSnackbarDialog(
            dialogTypes.CONFIRM_MODEL_REGISTRY_COLUMNS_ORDER_CHANGED,
            'Columns order was successfully saved'
          )
        );
      },
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CUSTOMIZE_MODEL_REGISTRY_COLUMNS_ERRORS,
            'Columns order was not saved, some error occurs'
          )
        );
      }
    },
    {
      enabled: !!registryModelId
    }
  );
};

export default useModelRegistryColumnsMutation;
