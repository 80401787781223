import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { BasicModal } from '@DesignSystem/modals';
import Input from '@DesignSystem/controllers/Input/Input';

import { MetadataIcon } from '@Icons-outdated';

import { MODEL_VERSION_PENDING_REQUESTS_ACTIONS } from '@model-registry/constants/request-status';
import classNames from './ModelVersionStatusActionsModal.module.scss';
import { capitalizeFirstLetter } from '../../../../helpers/generalHelpers';

const getModalTitle = action => {
  const text = 'status change';

  return `${capitalizeFirstLetter(action)} ${text}`;
};

const getModalInfo = action => {
  if (action === MODEL_VERSION_PENDING_REQUESTS_ACTIONS.APPROVE) {
    return (
      <Box
        data-test="modal-version-status-action-modal-approve-reject-text"
        className={classNames.modalVersionStatusActionApproveComment}
      >
        Changing model status will automatically reject all other pending
        requests for that model version
      </Box>
    );
  }

  return null;
};

const ModelVersionStatusActionsModal = ({
  open,
  onClose,
  onUpdate,
  action
}) => {
  const [comment, setComment] = useState('');
  const onPrimaryButtonClick = useCallback(() => {
    onUpdate({ status: action, comment });
  }, [action, comment]);
  const modalTitle = useMemo(() => getModalTitle(action), [action]);

  useEffect(() => {
    if (!open) {
      setComment('');
      onClose();
    }
  }, [open]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      additionalClasses={{
        paper: classNames.modalVersionStatusActionModal
      }}
      title={modalTitle}
      dataTest="modal-version-status-action-modal"
      type="modal-version-status-action-modal"
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      content={
        <Box className={classNames.modalVersionStatusAction}>
          <Box className={classNames.modalVersionStatusActionComment}>
            <Grid
              container
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <Grid item>Comment</Grid>
              <Grid item>
                <Tooltip
                  title="Comments will be sent along with your request and be reflected in History Tab"
                  placement="top"
                  arrow
                >
                  <Box
                    className={classNames.modalVersionStatusActionCommentIcon}
                  >
                    <MetadataIcon />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box className={classNames.modalVersionStatusActionCommentInput}>
            <Input
              data-test="modal-version-status-action-modal-comment-textfield"
              placeholder="Enter changes ..."
              multiline
              value={comment}
              onChange={setComment}
              height="80px"
            />
          </Box>
          {getModalInfo(action)}
        </Box>
      }
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onClose}
    />
  );
};

ModelVersionStatusActionsModal.defaultProps = {
  action: null
};

ModelVersionStatusActionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  action: PropTypes.oneOf([
    MODEL_VERSION_PENDING_REQUESTS_ACTIONS.CANCEL,
    MODEL_VERSION_PENDING_REQUESTS_ACTIONS.APPROVE,
    MODEL_VERSION_PENDING_REQUESTS_ACTIONS.REJECT
  ])
};

export default ModelVersionStatusActionsModal;
