import { trackEvent } from '@shared/utils/eventTrack';
import { panelEvents } from '@/constants/trackingEventTypes';
import { getBIEventsSentRenderedPanels } from '@/reducers/biEvents/panelsReducer';
import { biEventActionTypes } from '../constants/actionTypes';

const biEventActions = {
  addRenderedPanel(id) {
    return dispatch => {
      dispatch({
        type: biEventActionTypes.ADD_SENT_RENDERED_PANEL,
        payload: { id }
      });
    };
  },

  sendRenderedIfNotSentPanel(panelConfig, id) {
    return (dispatch, getState) => {
      const state = getState();
      const alreadySentPanels = getBIEventsSentRenderedPanels(state);

      if (alreadySentPanels[id]) {
        return null;
      }

      dispatch(biEventActions.addRenderedPanel(id));

      trackEvent(panelEvents.PANEL_RENDERED, panelConfig);
    };
  },

  firstQuickstartClick() {
    return dispatch => {
      dispatch({
        type: biEventActionTypes.FIRST_QUICK_START_CLICK_DONE
      });
    };
  },

  firstGetStartedClick() {
    return dispatch =>
      dispatch({
        type: biEventActionTypes.FIRST_GET_STARTED_CLICK_DONE
      });
  }
};

export default biEventActions;
