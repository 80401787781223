import api from '@shared/api';
import { useQuery } from 'react-query';

const getReports = async ({ reportId, signal }) => {
  const { data } = await api.get('reports/report/revisions', {
    params: {
      reportId
    },
    signal
  });

  return data.revisions;
};

export default function useReportRevisions({ reportId }, config) {
  return useQuery(
    ['reportRevisions', { reportId }],
    ({ signal }) => getReports({ reportId, signal }),
    {
      enabled: !!reportId,
      refetchOnMount: true,
      ...config
    }
  );
}
