import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { GenericField } from '@/util/redux-form-input';
import { FORM_FORGOT_PASSSWORD_FILL_EMAIL } from '@/constants/formTypes';
import { isValidEmail } from '@/lib/utils';

const ForgotPasswordFillEmail = props => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        type="text"
        component={GenericField}
        label="Email"
        className="full-width MuiFormControl-marginNormal"
        validate={isValidEmail}
      />
    </form>
  );
};

export default reduxForm({
  form: FORM_FORGOT_PASSSWORD_FILL_EMAIL, // a unique identifier for this form
  onSubmit: () => {} // submit function must be passed to onSubmit
})(ForgotPasswordFillEmail);
