import { IconButton, Tooltip } from '@ds';
import { generateReportURL } from '@reports/utils';
import { REPORT_EDIT_MODES } from '@routes/constants/reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams, useHistory } from 'react-router';
import { DSEditIcon } from '@ds-icons';

const EditReportButton = ({ canEdit, isTemplate, name }) => {
  const { projectName, workspace } = useParams();
  const history = useHistory();
  const editUrl = generateReportURL({
    editMode: REPORT_EDIT_MODES.EDIT,
    isTemplate,
    projectName,
    reportName: name,
    workspace
  });

  const handleClick = () => history.push(editUrl);

  return (
    <Tooltip content="Edit" arrow={false} placement="bottom">
      <IconButton
        disabled={!canEdit}
        Icon={<DSEditIcon />}
        type="secondary"
        onClick={handleClick}
      />
    </Tooltip>
  );
};

EditReportButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};

export default EditReportButton;
