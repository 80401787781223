import 'intersection-observer';
import { debounce, noop, throttle } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useInView } from 'react-intersection-observer';

import { MESSAGE_FETCHING_CHART_DATA } from '@/constants/messages';
import { CUSTOM_CHART_TYPE } from '@experiment-management-shared/constants/chartConstants';
import useBuiltInChart from '@experiment-management-shared/hooks/useBuiltInChart';
import { usePanelRenderedEvent } from '@experiment-management-shared/hooks/usePanelRenderedEvent';
import SmallLoader from '@shared/components/SmallLoader';
import CustomChart from './CustomChart';
import CustomChartInstance from './CustomChartInstance';

const THRESHOLD = [0, 0.25, 0.5, 0.75, 1];
const VISIBILITY_CHANGE_DELAY = 300;

const ChartPlaceholderWrapper = ({
  dataPanelsRows,
  onPanelChange,
  onUpdateDataPanelRows,
  onVisibilityChange,
  visibilityDebounceDelay,
  ChartProps
}) => {
  const {
    config,
    dataRevision,
    enableDataRevision,
    isPendingRendering,
    onDataSourceChange,
    onFinishRender,
    panelSharedConfig
  } = ChartProps;

  const { chartType } = config;
  const [wasVisible, setWasVisible] = useState(false);
  const [ref, inView, entry] = useInView({ threshold: THRESHOLD });
  const changeToVisibleRef = useRef(
    debounce(() => setWasVisible(true), visibilityDebounceDelay)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const visibilityChange = useCallback(
    throttle(onVisibilityChange, VISIBILITY_CHANGE_DELAY, { leading: false }),
    [onVisibilityChange]
  );

  usePanelRenderedEvent({
    chartProps: ChartProps,
    isRendered: wasVisible
  });

  useEffect(() => {
    visibilityChange(inView, entry);
  }, [entry, inView, visibilityChange]);

  useEffect(() => {
    if (inView) {
      changeToVisibleRef.current();
    } else {
      changeToVisibleRef.current.cancel();
    }
  }, [inView]);

  const builtInChart = useBuiltInChart();

  const containerProps = useMemo(() => {
    return {
      chartId: config.chartId,
      chartType: config.chartType,
      deleteConfig: config.deleteConfig,
      editConfig: config.editConfig,
      experimentKeys: panelSharedConfig.experimentKeys,
      hideHeader: config.isChartPreview,
      onDeleteChart: config.onDeleteChart,
      onEditChart: config.onEditChart,
      onOptionsClick: config.onOptionsClick,
      sectionId: config.sectionId,
      panelMoveConfig: config.panelMoveConfig,
      projectId: config.projectId
    };
  }, [
    config.chartId,
    config.chartType,
    config.deleteConfig,
    config.editConfig,
    config.isChartPreview,
    config.onDeleteChart,
    config.onEditChart,
    config.onOptionsClick,
    config.sectionId,
    config.panelMoveConfig,
    config.projectId,
    panelSharedConfig.experimentKeys
  ]);

  if (!wasVisible) {
    return (
      <div ref={ref} className="chart-placeholder-loading">
        <SmallLoader
          disableAnimations
          isFlexDisplay
          primaryMessage="Loading..."
          secondaryMessage={MESSAGE_FETCHING_CHART_DATA}
        />
      </div>
    );
  }

  if (chartType === CUSTOM_CHART_TYPE) {
    const {
      instanceId,
      hiddenMenuItems,
      templateId,
      chartId,
      deleteConfig,
      editConfig,
      hiddenExperimentKeys,
      experiments,
      onDeleteChart,
      onEditChart,
      onOptionsClick,
      panelMoveConfig,
      sectionId
    } = config;

    const { experimentKeys } = panelSharedConfig;

    const CustomChartProps = {
      chartId,
      deleteConfig,
      editConfig,
      hiddenExperimentKeys,
      hiddenMenuItems,
      onDeleteChart,
      onEditChart,
      onOptionsClick,
      panelMoveConfig,
      experimentKeys,
      experiments,
      sectionId
    };

    if (instanceId) {
      return (
        <div
          ref={ref}
          className="chart-placeholder-loading"
          style={{ height: '100%' }}
        >
          <CustomChartInstance
            {...CustomChartProps}
            dataPanelsRows={dataPanelsRows}
            instanceId={instanceId}
          />
        </div>
      );
    }

    if (templateId) {
      return (
        <div
          ref={ref}
          className="chart-placeholder-loading"
          style={{ height: '100%' }}
        >
          <CustomChart
            {...CustomChartProps}
            dataPanelsRows={dataPanelsRows}
            templateId={templateId}
          />
        </div>
      );
    }

    // @todo?
    return null;
  }

  const ChartComponent = builtInChart.getComponent({
    chartType,
    chartConfig: config,
    config: {
      ...panelSharedConfig,
      containerProps,
      dataRevision,
      enableDataRevision,
      isPendingRendering,
      onDataSourceChange,
      onFinishRender,
      showLock: (panelSharedConfig.showLockCharts || []).includes(chartType),
      onPanelChange,
      onUpdateDataPanelRows,
      experimentNameAsLink: true,
      isAssetClickable: true,
      isStepsEditable: true,
      hideVideo: true
    }
  });

  return (
    <div
      ref={ref}
      className="chart-placeholder-loading"
      style={{ height: '100%' }}
    >
      {ChartComponent}
    </div>
  );
};

ChartPlaceholderWrapper.defaultProps = {
  dataPanelsRows: {},
  onPanelChange: noop,
  onUpdateDataPanelRows: noop,
  onVisibilityChange: noop
};

ChartPlaceholderWrapper.propTypes = {
  dataPanelsRows: PropTypes.object,
  onPanelChange: PropTypes.func,
  onUpdateDataPanelRows: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  visibilityDebounceDelay: PropTypes.number.isRequired,
  ChartProps: PropTypes.object.isRequired
};

export default ChartPlaceholderWrapper;
