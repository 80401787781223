import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';
import useResponsiveWidth from '../utils/useResponsiveWidth';

const getSortIcon = (name, { isInOrder, sortBy }) => {
  if (name !== sortBy) return null;
  const iconName = isInOrder ? 'arrow_downward' : 'arrow_upward';

  return <i className="material-icons icons sort-by-icon">{iconName}</i>;
};

const Header = ({ onSortChange }) => {
  const [isInOrder, setIsInOrder] = useState(true);
  const [sortBy, setSortBy] = useState('fileName');
  const respWidth = useResponsiveWidth();

  const handleSortChange = newSortBy => {
    const newIsInOrder = newSortBy === sortBy ? !isInOrder : isInOrder;

    setIsInOrder(newIsInOrder);
    setSortBy(newSortBy);
    onSortChange(newSortBy, newIsInOrder);
  };

  return (
    <Grid container className="files-browser-header">
      <Grid
        item
        xs={5}
        onClick={() => handleSortChange('fileName')}
        className={cx('file-name-header', respWidth.name)}
      >
        <span>File Name {getSortIcon('fileName', { isInOrder, sortBy })}</span>
      </Grid>
      <Grid
        item
        xs={1}
        onClick={() => handleSortChange('remote')}
        className={cx('file-name-header', respWidth.location)}
      >
        <span>Location {getSortIcon('remote', { isInOrder, sortBy })}</span>
      </Grid>
      <Grid
        item
        xs={1}
        onClick={() => handleSortChange('fileSize')}
        className="file-name-header"
      >
        <span>Size {getSortIcon('fileSize', { isInOrder, sortBy })}</span>
      </Grid>
      <Grid
        item
        xs={2}
        onClick={() => handleSortChange('createdAt')}
        className="file-name-header"
      >
        <span>
          Last Modified {getSortIcon('createdAt', { isInOrder, sortBy })}
        </span>
      </Grid>
      <Grid
        item
        xs={1}
        onClick={() => handleSortChange('step')}
        className="file-name-header"
      >
        <span>Step {getSortIcon('step', { isInOrder, sortBy })}</span>
      </Grid>
      <Grid
        item
        xs={1}
        md={1}
        onClick={() => handleSortChange('runContext')}
        className="file-name-header"
      >
        <span>Context {getSortIcon('runContext', { isInOrder, sortBy })}</span>
      </Grid>
      <Grid item xs={1} md={1} className="file-name-header" />
    </Grid>
  );
};

Header.propTypes = {
  onSortChange: PropTypes.func.isRequired
};

export default memo(Header);
