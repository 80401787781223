export const FROM_LOCATION_MAP = {
  PANELS: 'panels',
  EXPERIMENT_TABLE: 'experimentTable',
  ARCHIVE: 'archive'
};

type getLinkLocationFromArgs = {
  isArchive: boolean;
  isExperimentsPage: boolean;
};
export const getLinkLocationFrom = ({
  isArchive,
  isExperimentsPage
}: getLinkLocationFromArgs) => {
  if (isArchive) {
    return FROM_LOCATION_MAP.ARCHIVE;
  }

  if (isExperimentsPage) {
    return FROM_LOCATION_MAP.EXPERIMENT_TABLE;
  }

  return FROM_LOCATION_MAP.PANELS;
};
