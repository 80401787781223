import React, { useMemo } from 'react';
import { MenuListProps, components } from 'react-select';
import { OptionType } from '../../../../types';

import { TextButton } from '../../../buttons';
import VirtualizedList from '../../../shared/VirtualizedList/VirtualizedList';
import classes from '../Select.module.scss';

const VIRTUALIZATION_MIN_LIMIT = 20;
const MAX_HEIGHT_PADDING = 20;

const MenuList = ({ children, ...restProps }: MenuListProps<OptionType>) => {
  const {
    selectProps: {
      FooterActionIcon,
      footerActionName,
      onFooterActionClick,
      maxHeight,
      withSearch
    }
  } = restProps;
  const showFooter = FooterActionIcon || footerActionName;

  const searchHight = withSearch ? 60 : 0;
  const dynamicMaxHeight = restProps.maxHeight - MAX_HEIGHT_PADDING;

  const list = React.Children.toArray(children);
  const rows = useMemo(() => list.map((_, idx) => ({ idx })), [list]);
  const canBeVirtualized = maxHeight && rows.length > VIRTUALIZATION_MIN_LIMIT;

  const virtualizedHeight =
    Math.min(dynamicMaxHeight, maxHeight || 0) - searchHight;

  return (
    <components.MenuList {...restProps} maxHeight={dynamicMaxHeight}>
      {canBeVirtualized ? (
        <VirtualizedList itemList={rows} maxHeight={virtualizedHeight}>
          {({ idx }) => list[idx]}
        </VirtualizedList>
      ) : (
        children
      )}

      {showFooter && (
        <div className={classes.footerButtonContainer}>
          <TextButton
            PrefixIcon={FooterActionIcon}
            onClick={onFooterActionClick}
          >
            {footerActionName}
          </TextButton>
        </div>
      )}
    </components.MenuList>
  );
};

export default MenuList;
