import React from 'react';
import PropTypes from 'prop-types';

const MoveModelConfirmDialog = ({ modelName, text }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>This is the only version of this model.</span>
      <span>
        {text ||
          `Moving this version will delete the model (${modelName}) from the
        registry`}
      </span>
    </div>
  );
};

MoveModelConfirmDialog.defaultProps = {
  text: null
};

MoveModelConfirmDialog.propTypes = {
  modelName: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default MoveModelConfirmDialog;
