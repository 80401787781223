import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Intercom from 'react-intercom';

import {
  INTERCOM_ID,
  IS_ON_PREMISE,
  SHOULD_LOAD_ANALYTICS
} from '@/constants/configConstants';
import trackingSelector from '@/selectors/trackingSelectors';

const Analytics = ({ user }) => {
  const isInIframe = window.parent !== window;
  const shouldLoadIntercom = SHOULD_LOAD_ANALYTICS || INTERCOM_ID;

  if (IS_ON_PREMISE || !shouldLoadIntercom || isInIframe) {
    return null;
  }

  // @todo: is it really necessary a try/catch here?
  try {
    const intercomUser = trackingSelector.setupIntercomUser(user);

    return <Intercom appID={INTERCOM_ID} {...intercomUser} />;
  } catch (error) {
    console.error('Issue with analytics.');
  }

  return null;
};

Analytics.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Analytics);
