import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const MultiValueRemove = props => {
  return (
    <Box className="manage-stages-select-close-icon">
      <Box component={CloseIcon} {...props.innerProps} />
    </Box>
  );
};

export default MultiValueRemove;
