import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PencilIcon } from '@Icons-outdated';
import { DropdownList } from '@DesignSystem/controllers';
import { Avatar } from '@DesignSystem/data-display';

import { AVATAR_WIDTH } from './helpers';

import UploadAvatarModals from './UploadAvatarModals';

import './UploadAvatar.scss';

const UPLOAD = 'upload';

const menuItems = [
  {
    label: 'Upload profile picture',
    value: UPLOAD
  }
  // {
  //   label: 'Remove profile picture',
  //   value: 'remove',
  //   disabled: true
  // },
  // {
  //   label: 'Change avatar color',
  //   value: 'color',
  //   disabled: true
  // }
];

const UploadAvatar = ({ onUploadPhoto, isPhotoUpdating, profileImage }) => {
  const [isImgHovered, setIsImgHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenUploadImageModal, setIsOpenUploadImageModal] = useState(false);

  const openUploadImageModal = () => setIsOpenUploadImageModal(true);
  const closeUploadImageModal = () => setIsOpenUploadImageModal(false);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenu = value => {
    if (value === UPLOAD) {
      setIsOpenUploadImageModal(true);
    }
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsImgHovered(false);
  };

  return (
    <>
      <UploadAvatarModals
        profileImage={profileImage}
        open={isOpenUploadImageModal}
        onOpen={openUploadImageModal}
        onClose={closeUploadImageModal}
        onUploadPhoto={onUploadPhoto}
        isPhotoUpdating={isPhotoUpdating}
      />

      <div
        className="user-avatar-container-upload"
        onMouseEnter={() => setIsImgHovered(true)}
        onMouseLeave={handleCloseMenu}
        onMouseDown={handleOpen}
      >
        {isImgHovered && (
          <div className="edit-container">
            <DropdownList
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              items={menuItems}
              width="178px"
              withInput={false}
              onClick={handleClickMenu}
              verticalPosition="top"
              horizontalPosition="right"
            />

            <button className="edit-button" type="button">
              <PencilIcon />
              Edit
            </button>
          </div>
        )}
        <Avatar
          src={profileImage}
          width={AVATAR_WIDTH}
          data-test="user-avatar"
          outlined
        />
      </div>
    </>
  );
};

UploadAvatar.defaultProps = {};

UploadAvatar.propTypes = {
  onUploadPhoto: PropTypes.func.isRequired,
  isPhotoUpdating: PropTypes.bool.isRequired,
  profileImage: PropTypes.string.isRequired
};

export default UploadAvatar;
