import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button } from '@ds';

import GithubIcon from './GithubIcon';
import { isUserAllowedToEditProject } from '@/reducers/projectsReducer';
import { useExperimentGitCompare } from '@experiment-details/api';

const PROVIDERS = {
  github: {
    label: 'GitHub',
    style: { backgroundColor: '#2ea44e' },
    logo: <GithubIcon />
  },
  gitlab: {
    label: 'GitLab',
    style: { backgroundColor: '#b8b8b8' },
    logo: (
      <img
        src="/images/gitlab.svg"
        style={{
          width: 26,
          transform: 'scale(1.4)'
        }}
      />
    )
  },
  bitbucket: {
    label: 'Bitbucket',
    style: { color: '#253858', backgroundColor: 'white' },
    logo: (
      <img
        src="/images/bitbucket.svg"
        style={{ width: 23, transform: 'scale(1.6)' }}
      />
    )
  }
};

const GitCompareButton = ({ experimentKey1, experimentKey2 }) => {
  const canEdit = useSelector(isUserAllowedToEditProject);
  const { data = {} } = useExperimentGitCompare(
    {
      experimentKey1,
      experimentKey2
    },
    {
      enabled: canEdit
    }
  );

  const { url, provider } = data;

  if (!canEdit || !url || !provider) return null;

  const { logo, label, style } = PROVIDERS[provider];

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Button
        className="github-compare-commits-btn"
        style={style}
        PrefixIcon={logo}
      >
        <span>Compare on {label}</span>
      </Button>
    </a>
  );
};

GitCompareButton.propTypes = {
  experimentKey1: PropTypes.string.isRequired,
  experimentKey2: PropTypes.string.isRequired
};

export default GitCompareButton;
