import { ButtonSize } from '@design-system/types';

export const getIconPaddingClassName = (isLeft: boolean): string => {
  const side = isLeft ? 'left' : 'right';

  return `${side}IconPadding`;
};

export const getIconPaddingClass = (
  isWithPrefixIcon: boolean,
  isWithPostfixIcon: boolean
): string | null =>
  isWithPrefixIcon || isWithPostfixIcon
    ? getIconPaddingClassName(isWithPrefixIcon)
    : null;

type LoaderSize = 14 | 16;
export const getLoaderSize = (buttonSize: ButtonSize): LoaderSize => {
  if (buttonSize === 'small') {
    return 14;
  }

  return 16;
};
