import { useIsFirstSession } from '@shared/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useCallback } from 'react';
import useIsOnOnboardingPage from '../useIsOnOnboardingPage';
import biEventActions from '@/actions/biEventsActions';
import {
  getIsBIEventsGetStartedFirstClick,
  getIsBIEventsQuckstartFirstClick
} from '@/reducers/biEvents/onboardingReducer';
import {
  getIsUserLoggedIn,
  getShowPostSignupForm
} from '@/reducers/userReducer';
import { IS_ON_PREMISE } from '@/lib/appConstants';

// use first click status
const useOnboardingFirstClickStatus = () => {
  const dispatch = useDispatch();

  const isOnboardingPage = useIsOnOnboardingPage();
  const isFirstSession = useIsFirstSession();
  const isPostSignupFormAlreadyShown = useSelector(getShowPostSignupForm);

  const isPostSignupModalOpen =
    getIsUserLoggedIn && !IS_ON_PREMISE && !isPostSignupFormAlreadyShown;

  const location = useLocation();

  const isGetStarted = location.pathname === '/get-started';

  const isQuickstartClick = useSelector(getIsBIEventsQuckstartFirstClick);
  const isGetStartedClick = useSelector(getIsBIEventsGetStartedFirstClick);

  const hasBeenFirstClick = isGetStarted
    ? isGetStartedClick
    : isQuickstartClick;

  const relevantAction = isGetStarted
    ? biEventActions.firstGetStartedClick
    : biEventActions.firstQuickstartClick;

  const onFirstClick = useCallback(() => {
    dispatch(relevantAction());
  }, [dispatch, relevantAction]);

  return {
    shouldSend:
      isOnboardingPage &&
      isFirstSession &&
      !hasBeenFirstClick &&
      !isPostSignupModalOpen,
    onFirstClick,
    pageName: isGetStarted ? 'get_started_page' : 'quickstart_page'
  };
};

export default useOnboardingFirstClickStatus;
