import React from 'react';
import Combobox, { ComboboxProps } from './Combobox';
import { SortEndHandler, SortableContainer } from 'react-sortable-hoc';
import { noop, uniq } from 'lodash';
import { arrayMove } from '@dnd-kit/sortable';
import SortableMultiValue from './components/SortableMultiValue';
import SortableMultiValueLabel from './components/SortableMultiValueLabel';
import { OptionType } from '@design-system/types';
import { valueToList } from './utils';

const SortableSelect = SortableContainer(Combobox);

type SortableComboboxProps = ComboboxProps & {
  fixedOptionValues: OptionType['value'][];
};
const SortableCombobox = ({
  fixedOptionValues = [],
  onValueChange = noop,
  value,
  options = [],
  ...props
}: SortableComboboxProps) => {
  const valueList = valueToList(value);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (!value) return;
    const newlySortedValues = arrayMove([...valueList], oldIndex, newIndex);
    const sortedValuesList = uniq([...fixedOptionValues, ...newlySortedValues]);

    const valuesOptionList = sortedValuesList.map(
      v => options.find(opt => opt.value === v) as OptionType
    );

    onValueChange(sortedValuesList, valuesOptionList);
  };

  return (
    <SortableSelect
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      fixedOptionValues={fixedOptionValues}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      onValueChange={onValueChange}
      value={value}
      options={options}
      components={{
        //@ts-expect-error react-sortable-hoc issue
        MultiValue: SortableMultiValue,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        MultiValueLabel: SortableMultiValueLabel
      }}
      {...props}
    />
  );
};

export default SortableCombobox;
