import { Select } from '@DesignSystem/controllers';
import { Avatar, StyledTooltip } from '@DesignSystem/data-display';
import { GenericActionsCell } from '@DesignSystem/generic-table-cells/ActionsCell/ActionsCell';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import { DSColors } from '@design-system-outdated/constants';
import { ORGANIZATION_USER_ROLES } from '@shared/constants';

const EmptyCell = () => <div className="user-empty-cell" />;

const getUserRoleLabel = role => {
  return capitalize(role.replaceAll('_', ' '));
};

const DateCell = ({ value }) =>
  value ? moment(value).format('DD MMM YYYY') : <EmptyCell />;

const RoleCell = ({ value, onChangeRole, disabled, isSameUser, roles }) => {
  const options = useMemo(() => {
    return roles.map(role => ({
      label: getUserRoleLabel(role),
      value: role
    }));
  }, [roles]);

  return (
    <StyledTooltip
      title={isSameUser ? 'You cannot change role for yourself' : ''}
      placement="top-start"
    >
      <div className="organization-member-role-cell">
        <Select
          onChange={onChangeRole}
          options={options}
          value={value}
          disabled={disabled}
        />
      </div>
    </StyledTooltip>
  );
};

RoleCell.propTypes = {
  value: PropTypes.oneOf([
    ORGANIZATION_USER_ROLES.ADMIN,
    ORGANIZATION_USER_ROLES.MEMBER,
    ORGANIZATION_USER_ROLES.VIEW_ONLY_MEMBER
  ]).isRequired,
  onChangeRole: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isSameUser: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired
};

const NameCell = ({ row }) => {
  const width = '36px';
  const avatarProps = row.avatar
    ? {
        width,
        src: row.avatar
      }
    : {
        width,
        outlined: true,
        backgroundColor: DSColors.grayColor1,
        type: 'small',
        showLetters: true
      };

  return (
    <div className="organization-users-name-cell">
      <Avatar {...avatarProps} />

      <div
        className={cx('name-cell-label', {
          'no-name': !row.username
        })}
      >
        {row.username ? row.username : <EmptyCell />}
      </div>
    </div>
  );
};

const ActionsCell = ({ onDeleteClick, disabled, disabledRemoving }) => {
  const handleClickOption = value => {
    if (value === actionOptions[0].value) {
      return onDeleteClick();
    }
  };

  const actionOptions = useMemo(() => {
    return [
      {
        label: 'Delete user',
        value: 'delete',
        disabled: disabledRemoving,
        tooltipText: disabledRemoving
          ? 'You cannot remove yourself from organization'
          : ''
      }
    ];
  }, [disabledRemoving]);

  return (
    <GenericActionsCell
      options={actionOptions}
      onClick={handleClickOption}
      disabled={disabled}
    />
  );
};

ActionsCell.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledRemoving: PropTypes.bool.isRequired
};

const userCellProptypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  row: PropTypes.object.isRequired
};

NameCell.propTypes = userCellProptypes;
DateCell.propTypes = userCellProptypes;

export default { DateCell, RoleCell, NameCell, ActionsCell };
