import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export const GenericField = ({ input, label, type, className }) => (
  <FormControl aria-describedby={className} className={className}>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <Input {...input} type={type} placeholder="" />
    {/* <FormHelperText error={errorValidation}>{helpertext}</FormHelperText> */}
  </FormControl>
);
