import axios from 'axios';
import { BASE_API_URL } from '@/constants/configConstants';
import { HyperParam } from '@shared/types/hyperParam';

type FetchArgs = {
  experimentKey: string;
  signal?: AbortSignal;
};
type UpdateArgs = {
  experimentKey: string;
  name: string;
  value: string;
};
type DeleteArgs = {
  experimentKey: string;
  name: string;
};
const hyperParamsApi = {
  fetchHyperParams({ experimentKey, signal }: FetchArgs) {
    const url = `${BASE_API_URL}data-fetch/get/params`;

    return axios.get<{
      results: HyperParam[];
      version: string;
    }>(url, {
      params: {
        experimentKey
      },
      signal
    });
  },

  updateHyperParam({ experimentKey, name, value }: UpdateArgs) {
    const url = `${BASE_API_URL}experiment/update/param`;

    return axios.post(url, {
      experimentKey,
      metricName: name,
      metricValue: value
    });
  },

  deleteHyperParam({ experimentKey, name }: DeleteArgs) {
    const url = `${BASE_API_URL}experiment/delete/param`;

    return axios.delete(url, {
      params: { experimentKey, metricName: name }
    });
  }
};

export default hyperParamsApi;
