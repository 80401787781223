import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  HOMEPAGE_BANNER_LINK,
  HOMEPAGE_BANNER_TEXT
} from '@/constants/configConstants';

const HomepageBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (isEmpty(HOMEPAGE_BANNER_TEXT) || !isVisible) {
    return null;
  }

  const link = isEmpty(HOMEPAGE_BANNER_LINK) ? null : (
    <a href={HOMEPAGE_BANNER_LINK} target="_blank" rel="noopener noreferrer">
      Register here
    </a>
  );

  return (
    <div className="homepage-banner">
      <p>
        {HOMEPAGE_BANNER_TEXT} {link}
      </p>
      <button
        className="close-btn"
        type="button"
        onClick={() => setIsVisible(false)}
      >
        <i className="material-icons">close</i>
      </button>
    </div>
  );
};

export default HomepageBanner;
