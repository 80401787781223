// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageUsersModalFooter-module__manageUsersModalFooter--WDj7s{display:flex;justify-content:end;align-items:center;width:100%}.ManageUsersModalFooter-module__manageUsersModalFooter--WDj7s .selection-label{color:var(--gray-6);margin-right:24px}.ManageUsersModalFooter-module__manageUsersModalFooter--WDj7s .ManageUsersModalFooter-module__resetButton--kq8Uf{margin-right:6px;margin-left:6px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageUsersModalFooter": `ManageUsersModalFooter-module__manageUsersModalFooter--WDj7s`,
	"resetButton": `ManageUsersModalFooter-module__resetButton--kq8Uf`
};
module.exports = ___CSS_LOADER_EXPORT___;
