import React from 'react';
import { BasicModal } from '@DesignSystem/modals';
import { Metric } from '@shared/types';
import { NumberInput, TextInput, Tooltip } from '@ds';
import styles from './FormMetricModal.module.scss';
import { getFormattedTime } from '../helpers';
import { DSInformationIcon } from '@design-system/icons';

export type FormMetricModalProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isFetching: boolean;
  title: string;
  primaryButtonText: string;
  isValid: boolean;
  isDuplicateName?: boolean;
  onFieldChange: (v: string, key: keyof Metric) => void;
  formData: Metric;
  isEditMode?: boolean;
};
const FormMetricModal: React.FC<FormMetricModalProps> = ({
  isOpened,
  onClose,
  onConfirm,
  isFetching,
  title,
  primaryButtonText,
  isValid,
  onFieldChange,
  formData,
  isDuplicateName,
  isEditMode
}) => {
  return (
    <BasicModal
      open={isOpened}
      onClose={onClose}
      title={title}
      content={
        <div className={styles.formContainer}>
          <div className={styles.field}>
            <span className={styles.fieldLabel}>
              Metric name<b>*</b>
              <Tooltip content="You can add a key in a nested structure">
                <div className={styles.infoIconContainer}>
                  <DSInformationIcon />
                </div>
              </Tooltip>
            </span>
            <TextInput
              value={formData.name}
              onChange={e => onFieldChange(e.target.value, 'name')}
              placeholder="metric/name"
              disabled={isEditMode}
              maxLength={100}
              autoFocus
              data-test="metric-name-input"
              invalid={isDuplicateName && !isFetching}
              helperText={
                isDuplicateName && !isFetching
                  ? 'This metric name already exists, please choose a different one'
                  : ''
              }
            />
          </div>
          <div className={styles.fieldGroup}>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Last value<b>*</b>
              </span>
              <NumberInput
                value={formData.valueCurrent}
                onChange={v => onFieldChange(v, 'valueCurrent')}
                placeholder="0.0"
                data-test="metric-last-value-input"
              />
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Last step<b>*</b>
              </span>
              <NumberInput
                value={formData.stepCurrent}
                onChange={v => onFieldChange(v, 'stepCurrent')}
                placeholder="000"
                data-test="metric-last-step-input"
              />
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Last time<b>*</b>
              </span>
              <TextInput
                value={getFormattedTime(formData.timestampCurrent)}
                onChange={e =>
                  onFieldChange(e.target.value, 'timestampCurrent')
                }
                placeholder="Input text"
                type="datetime-local"
                data-test="metric-last-time-input"
              />
            </div>
          </div>
          <div className={styles.fieldGroup}>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Min value<b>*</b>
              </span>
              <NumberInput
                value={formData.valueMin}
                onChange={v => onFieldChange(v, 'valueMin')}
                placeholder="0.0"
                data-test="metric-min-value-input"
              />
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Min step<b>*</b>
              </span>
              <NumberInput
                value={formData.stepMin}
                onChange={v => onFieldChange(v, 'stepMin')}
                placeholder="000"
                data-test="metric-min-step-input"
              />
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Min time<b>*</b>
              </span>
              <TextInput
                value={getFormattedTime(formData.timestampMin)}
                onChange={e => onFieldChange(e.target.value, 'timestampMin')}
                placeholder="Input text"
                type="datetime-local"
                data-test="metric-min-time-input"
              />
            </div>
          </div>
          <div className={styles.fieldGroup}>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Max value<b>*</b>
              </span>
              <NumberInput
                value={formData.valueMax}
                onChange={v => onFieldChange(v, 'valueMax')}
                placeholder="0.0"
                data-test="metric-max-value-input"
              />
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Max step<b>*</b>
              </span>
              <NumberInput
                value={formData.stepMax}
                onChange={v => onFieldChange(v, 'stepMax')}
                placeholder="000"
                data-test="metric-max-step-input"
              />
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>
                Max time<b>*</b>
              </span>
              <TextInput
                value={getFormattedTime(formData.timestampMax)}
                onChange={e => onFieldChange(e.target.value, 'timestampMax')}
                placeholder="Input text"
                type="datetime-local"
                data-test="metric-max-time-input"
              />
            </div>
          </div>
        </div>
      }
      primaryButtonText={primaryButtonText}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={onConfirm}
      isPrimaryButtonDisabled={isFetching || !isValid}
    />
  );
};

export default FormMetricModal;
