import { useEffect, useMemo, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

type UseChartDataRevisionRetVal<T> = {
  delayedDataSources: T;
  hasDelayedDataSources: boolean;
  isInitialRenderDelayed: boolean;
  calculatedRevision: number;
};

type UseChartDataRevisionParams<T> = {
  enableDataRevision: boolean;
  isPendingRendering: boolean;
  dataRevision: number;
  dataSources: T;
  revision: number;
};

interface UseChartDataRevisionFn {
  <T>(arg: UseChartDataRevisionParams<T>): UseChartDataRevisionRetVal<T>;
}

const useChartDataRevision: UseChartDataRevisionFn = ({
  enableDataRevision,
  isPendingRendering,
  dataRevision,
  dataSources,
  revision
}) => {
  const prevDataRevision = useRef<number | null>(null);
  const currentDataSources = useRef(dataSources);

  const delayedDataSources = useMemo(() => {
    if (!enableDataRevision) return dataSources;

    if (dataRevision !== prevDataRevision.current) {
      currentDataSources.current = dataSources;
    }

    return currentDataSources.current;
  }, [dataRevision, dataSources, enableDataRevision]);

  useEffect(() => {
    prevDataRevision.current = dataRevision;
  }, [dataRevision]);

  return {
    delayedDataSources,
    calculatedRevision: enableDataRevision ? dataRevision : revision,
    hasDelayedDataSources: delayedDataSources !== dataSources,
    isInitialRenderDelayed:
      enableDataRevision &&
      isPendingRendering &&
      !isEmpty(dataSources) &&
      isEmpty(delayedDataSources)
  };
};
export default useChartDataRevision;
