import React from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@DesignSystem/modals';
import styles from '../TemplateSelect.module.scss';

const DeleteThisView = ({
  handleDeleteTemplate,
  selectedViewToDelete,
  keysMap,
  closeDeleteModal
}) => {
  return (
    <BasicModal
      title="Delete this view?"
      content={
        <div className={styles.deleteViewContainer}>
          The view `
          {selectedViewToDelete && selectedViewToDelete[keysMap.templateName]}`
          will be permanently deleted.
        </div>
      }
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={() => {
        handleDeleteTemplate(selectedViewToDelete);
        closeDeleteModal();
      }}
      onSecondaryButtonClick={closeDeleteModal}
      open={Boolean(selectedViewToDelete)}
      onClose={closeDeleteModal}
    />
  );
};

DeleteThisView.propTypes = {
  handleDeleteTemplate: PropTypes.func.isRequired,
  selectedViewToDelete: PropTypes.object.isRequired,
  keysMap: PropTypes.object.isRequired,
  closeDeleteModal: PropTypes.func.isRequired
};

export default DeleteThisView;
