import moment from 'moment';

import { DSColors, chartColors } from '@design-system-outdated/constants';
import {
  Point,
  LineChartResponse,
  StackedChartResponse,
  PredicateQuery
} from '@mpm-druid/types';
import { DAILY_CRON_VALUE } from '@mpm-druid/constants';
import { getHashedColor } from './utilsWithTypes';

const CHART_COLORS = Object.values(chartColors);

export const formatToLocal = (utcDate: string, format = 'YYYY-MM-DD HH:mm') => {
  try {
    return moment.utc(utcDate).local().format(format);
  } catch (_) {
    return utcDate;
  }
};

export const formatUnixForTable = (timestamp: number, formatStr: string) => {
  return timestamp ? moment.unix(timestamp).format(formatStr) : '-';
};

export const getTimeIntervalFromCron = (cronValue: string | null) => {
  if (!cronValue) return '--';
  return cronValue === DAILY_CRON_VALUE ? 'DAILY' : 'HOURLY';
};

export const CHART_BASIC_LAYOUT = {
  autosize: true,
  bargap: 0.875,
  showlegend: false,
  margin: { b: 40, l: 40, pad: 10, r: 10, t: 16 },
  hovermode: 'closest',
  hoverlabel: { bgcolor: '#FFF' },
  // transition: {
  //   duration: 800,
  //   easing: 'cubic-in-out',
  //   ordering?: 'layout first' | 'traces first' | undefined;
  // },
  yaxis: {
    automargin: true,
    autorange: true,
    zerolinecolor: '#E2E2E2',
    gridcolor: DSColors.grayColor3,
    zeroline: false,
    tickfont: {
      color: DSColors.grayColor5,
      size: 11
    }
  },
  xaxis: {
    automargin: true,
    autorange: true,
    type: 'date',
    showgrid: false,
    tickformat: '%d %b',
    tickfont: {
      color: DSColors.grayColor6,
      size: 11
    },
    zerolinecolor: '#E2E2E2'
  }
};

export const CHART_BASIC_CONFIG = {
  displayModeBar: false
};

export const getChartLinesFromData = ({
  pointsBatch = [],
  lineColors = CHART_COLORS
}: {
  pointsBatch?: {
    x: string[];
    y: (null | number)[];
  }[];
  lineColors?: string[];
}) => {
  const linesData = [];

  for (let idx = 0; idx < pointsBatch.length; idx++) {
    const colorIndex = idx % lineColors.length;
    const color = lineColors[colorIndex];
    const points = pointsBatch[idx];
    const { x, y } = points;
    linesData.push({
      line: { color: color, dash: 'solid' },
      mode: 'lines',
      x,
      y
    });
    const singleDotsX: string[] = [];
    const singleDotsY: (string | number | null)[] = [];
    y.forEach((dot: null | number, idx: number) => {
      if (
        isNullOrUndefined(y[idx - 1]) &&
        isNullOrUndefined(y[idx + 1]) &&
        dot !== null
      ) {
        singleDotsX.push(formatToLocal(x[idx]));
        singleDotsY.push(dot);
      }
    });
    if (singleDotsX.length > 0) {
      linesData.push({
        line: { color: color, dash: 'solid' },
        mode: 'markers',
        x: singleDotsX,
        y: singleDotsY
      });
    }
  }

  return linesData;
};

function isNullOrUndefined(value: unknown) {
  return value === null || value === undefined;
}

export const transformToChartLines = ({
  responseData,
  lineColors = CHART_COLORS,
  predicates
}: {
  responseData: LineChartResponse;
  lineColors?: string[];
  predicates: PredicateQuery[];
}) => {
  const chartData = [];
  if (Array.isArray(responseData?.data) && responseData?.data.length) {
    for (const slice of responseData.data) {
      const x: string[] = [];
      const y: (string | number | null)[] = [];
      const singleDotsX: string[] = [];
      const singleDotsY: (string | number | null)[] = [];

      slice.data.forEach((point: Point, idx: number) => {
        x.push(formatToLocal(point.x));
        y.push(point.y);
        if (
          isNullOrUndefined(slice.data[idx - 1]?.y) &&
          isNullOrUndefined(slice.data[idx + 1]?.y) &&
          !!slice.data[idx]?.y
        ) {
          singleDotsX.push(formatToLocal(point.x));
          singleDotsY.push(point.y);
        }
      });
      const query = predicates.find(
        predicate => predicate.key === slice.predicateKey
      )?.query;
      const color =
        slice.predicateKey === 'all'
          ? lineColors[0]
          : getHashedColor(lineColors, query || '');

      const sliceData = [
        {
          x,
          y,
          alertNotifications: slice?.monitorNotifications,
          line: {
            color: color,
            dash: 'solid'
          },
          mode: 'lines',
          areAllYZeroes: y.every(val => val === 0 || val === '0'),
          predicateKey: slice.predicateKey,
          query: query || 'all'
        }
      ];
      if (singleDotsX.length) {
        sliceData.push({
          x: singleDotsX,
          y: singleDotsY,
          alertNotifications: [],
          line: {
            color: color,
            dash: 'solid'
          },
          mode: 'markers',
          areAllYZeroes: false,
          predicateKey: slice.predicateKey,
          query: query || 'all'
        });
      }
      chartData.push(...sliceData);
    }
  }

  return chartData;
};

export const transformStackedDataToChartLines = ({
  responseData,
  lineColors = CHART_COLORS,
  predicates
}: {
  responseData: StackedChartResponse;
  lineColors?: string[];
  predicates: PredicateQuery[];
}) => {
  const chartData = [];
  if (Array.isArray(responseData?.data) && responseData?.data.length) {
    for (const segment of responseData.data) {
      for (const slice of segment.featuresDrift) {
        const x: string[] = [];
        const y: (string | number | null)[] = [];
        const singleDotsX: string[] = [];
        const singleDotsY: (string | number | null)[] = [];
        slice.driftData.forEach((point: Point, idx: number) => {
          x.push(formatToLocal(point.x));
          y.push(point.y);
          if (
            isNullOrUndefined(slice.driftData[idx - 1]?.y) &&
            isNullOrUndefined(slice.driftData[idx + 1]?.y) &&
            !!slice.driftData[idx].y
          ) {
            singleDotsX.push(formatToLocal(point.x));
            singleDotsY.push(point.y);
          }
        });
        const query =
          predicates.find(predicate => predicate.key === segment.predicateKey)
            ?.query || 'all';
        const color =
          segment.predicateKey === 'all'
            ? lineColors[0]
            : getHashedColor(lineColors, query);

        const sliceData = [
          {
            x,
            y,
            alertNotifications: [],
            line: {
              color: color,
              dash: 'solid'
            },
            mode: 'lines',
            areAllYZeroes: y.every(val => val === 0 || val === '0'),
            predicateKey: segment.predicateKey,
            featureName: slice.featureName,
            featureSource: slice.featureSource,
            query: query || 'all',
            featureId: `${slice.featureName}-${slice.featureSource}-${
              query || 'all'
            }`
          }
        ];
        if (singleDotsX.length) {
          sliceData.push({
            x: singleDotsX,
            y: singleDotsY,
            alertNotifications: [],
            line: {
              color: color,
              dash: 'solid'
            },
            mode: 'markers',
            areAllYZeroes: false,
            predicateKey: segment.predicateKey,
            featureName: slice.featureName,
            featureSource: slice.featureSource,
            query: query || 'all',
            featureId: `${slice.featureName}-${slice.featureSource}`
          });
        }
        chartData.push(...sliceData);
      }
    }
  }

  return chartData;
};

export const transformStackedDataToAggregatedLines = ({
  responseData,
  lineColors = CHART_COLORS,
  predicates
}: {
  responseData: StackedChartResponse;
  lineColors?: string[];
  predicates: PredicateQuery[];
}) => {
  const chartData = [];
  if (Array.isArray(responseData?.data) && responseData?.data.length) {
    for (const slice of responseData.data) {
      const x: string[] = [];
      const y: (string | number | null)[] = [];
      const singleDotsX: string[] = [];
      const singleDotsY: (string | number | null)[] = [];
      slice.aggregatedDrift.forEach((point: Point, idx: number) => {
        x.push(formatToLocal(point.x));
        y.push(point.y);
        if (
          isNullOrUndefined(slice.aggregatedDrift[idx - 1]?.y) &&
          isNullOrUndefined(slice.aggregatedDrift[idx + 1]?.y) &&
          !!slice.aggregatedDrift[idx]?.y
        ) {
          singleDotsX.push(formatToLocal(point.x));
          singleDotsY.push(point.y);
        }
      });
      const query =
        predicates.find(predicate => predicate.key === slice.predicateKey)
          ?.query || 'all';
      const color =
        slice.predicateKey === 'all'
          ? lineColors[0]
          : getHashedColor(lineColors, query);

      const sliceData = [
        {
          x,
          y,
          alertNotifications: [],
          line: {
            color: color,
            dash: 'solid'
          },
          mode: 'lines',
          areAllYZeroes: y.every(val => val === 0 || val === '0'),
          predicateKey: slice.predicateKey,
          query: query || 'all'
        }
      ];
      if (singleDotsX.length) {
        sliceData.push({
          x: singleDotsX,
          y: singleDotsY,
          alertNotifications: [],
          line: {
            color: color,
            dash: 'solid'
          },
          mode: 'markers',
          areAllYZeroes: false,
          predicateKey: slice.predicateKey,
          query: query || 'all'
        });
      }
      chartData.push(...sliceData);
    }
  }

  return chartData;
};

export const transformToSingleLine = ({
  points,
  queryString,
  lineColors = CHART_COLORS
}: {
  points: Point[];
  queryString?: string;
  lineColors?: string[];
}) => {
  const chartData = [];
  const xDots: string[] = [];
  const yDots: (string | number | null)[] = [];
  const singleXDots: string[] = [];
  const singleYDots: (string | number | null)[] = [];

  points.forEach((point: Point, idx: number) => {
    xDots.push(formatToLocal(point.x));
    yDots.push(point.y);
    if (
      isNullOrUndefined(points[idx - 1]?.y) &&
      isNullOrUndefined(points[idx + 1]?.y) &&
      (!!points[idx]?.y || typeof points[idx]?.y === 'number')
    ) {
      singleXDots.push(formatToLocal(point.x));
      singleYDots.push(point.y);
    }
  });
  const color =
    queryString && queryString.length
      ? getHashedColor(lineColors, queryString)
      : lineColors[0];

  const sliceData = [
    {
      x: xDots,
      y: yDots,
      alertNotifications: [],
      line: {
        color: color,
        dash: 'solid'
      },
      mode: 'lines',
      areAllYZeroes: yDots.every(val => val === 0 || val === '0')
    }
  ];
  if (singleXDots.length) {
    sliceData.push({
      x: singleXDots,
      y: singleYDots,
      alertNotifications: [],
      line: {
        color: color,
        dash: 'solid'
      },
      mode: 'markers',
      areAllYZeroes: false
    });
  }
  chartData.push(...sliceData);

  return chartData;
};
