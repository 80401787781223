import React from 'react';
import PropTypes from 'prop-types';
import first from 'lodash/first';
import noop from 'lodash/noop';

import { BarChart } from '../BarChart';
import { TableLegend } from '@DesignSystem/data-display';
import { BarChartIcon } from '@Icons-outdated';
import { Box } from '@material-ui/core';
import { useBarChartContainer } from '../../hooks';
import { Switch } from '@DesignSystem/controllers';
import { PANEL_SECTIONS } from '../../constants';
import './BarChartContainer.scss';

export const BarChartContainer = ({
  data,
  layoutData,
  disableZoom,
  section,
  chartType,
  fairnessData,
  checkedPercentCategorySwitch,
  handlePercentCategorySwitch,
  ...props
}) => {
  const {
    handledPlotData,
    pillsData,
    handleHover,
    handleUnhover,
    chartRef
  } = useBarChartContainer({ serverData: data, chartType, fairnessData });

  const showFeatureDistributionToggle =
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION ||
    section === PANEL_SECTIONS.FEATURE_DISTRIBUTION_CATEGORICAL;

  const renderNoData = () => {
    return (
      <div className="bar-chart-no-data-container">
        <BarChartIcon />

        <span>No data for this chart</span>
      </div>
    );
  };

  if (
    !handledPlotData ||
    !handledPlotData.length ||
    !first(handledPlotData)?.x?.length
  ) {
    return renderNoData();
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <TableLegend
        onUnhover={handleUnhover}
        onHover={handleHover}
        pills={pillsData}
      />
      <BarChart
        height={240}
        ref={chartRef}
        data={handledPlotData}
        layoutData={layoutData}
        disableZoom={disableZoom}
        section={section}
        chartType={chartType}
        {...props}
      />
      {showFeatureDistributionToggle && (
        <div className="data-type-toggle">
          <span className="data-type-title">{'Percentages / Categories'} </span>
          <Switch
            checked={checkedPercentCategorySwitch}
            onChange={handlePercentCategorySwitch}
            color="primary"
          />
        </div>
      )}
    </Box>
  );
};

BarChartContainer.defaultProps = {
  data: [],
  layoutData: {},
  disableZoom: false,
  chartType: '',
  fairnessData: {},
  checkedPercentCategorySwitch: false,
  handlePercentCategorySwitch: noop
};

BarChartContainer.propTypes = {
  data: PropTypes.array,
  layoutData: PropTypes.object,
  disableZoom: PropTypes.bool,
  section: PropTypes.string.isRequired,
  chartType: PropTypes.string,
  fairnessData: PropTypes.object,
  checkedPercentCategorySwitch: PropTypes.bool,
  handlePercentCategorySwitch: PropTypes.func
};
