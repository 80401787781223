export const PANEL_ENTITY_NAME = 'panel';
export const SELECT_PANEL_PATH = 'select-panel';
export const PANEL_PREVIEW_PATH = 'preview';
export const GALLERY_TAB = 'gallery-tab';
export const PREVIEW_TEMPLATE_ID = 'preview-template-id';

export const RESOURCE_TYPES = {
  JAVASCRIPT: { value: 'JAVASCRIPT', label: 'Javascript' },
  CSS: { value: 'CSS', label: 'CSS' }
};

export const PANEL_SOURCES = {
  EXPERIMENT: 'experiment',
  PROJECT: 'project',
  SINGLE_EXPERIMENT: 'single_experiment',
  SINGLE_EXPERIMENT_GRAPHICS_TAB: 'graphics_tab',
  DIFF: 'diff',
  REPORTS: 'report'
};

export const templateDataErrorTypes = {
  TEMPLATE_NOT_FOUND: 'TEMPLATE_NOT_FOUND'
};
