import { IconButton } from '@ds';
import Box from '@material-ui/core/Box';
import NewReportButton from '@reports/components/NewReportButton';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import StyledSearchBar from '@shared/components/StyledComponents/StyledSearchBar';
import styles from './ReportsHeader.module.scss';
import { DSDeleteIcon } from '@ds-icons';

const ReportsHeader = ({
  canEdit,
  onDelete,
  onSearch,
  reports,
  selectedReports,
  table
}) => {
  const renderActions = () => {
    return (
      <Box className={styles.actions} mx={2}>
        <IconButton
          disabled={!selectedReports.length || !canEdit}
          onClick={onDelete}
          Icon={<DSDeleteIcon />}
        />

        <NewReportButton canEdit={canEdit} />
      </Box>
    );
  };

  const renderReportsAmount = () => {
    const { page, rowsPerPage } = table;
    const totalReports = reports.length;
    const pagingOffset = page * rowsPerPage;
    const startOfSubset = Math.min(reports.length, pagingOffset + 1);
    const endOfSubset = rowsPerPage + pagingOffset;

    const isShowingSubset = rowsPerPage < totalReports;
    const endLabel =
      isShowingSubset && endOfSubset <= totalReports
        ? endOfSubset
        : totalReports;

    const text = `Showing ${startOfSubset}-${endLabel} of ${totalReports} total reports`;

    return <div className={styles.reportsAmount}>{text}</div>;
  };

  const renderSearch = () => {
    return (
      <StyledSearchBar
        style={{ minWidth: 235 }}
        entityName="reports"
        handleCancelSearch={onSearch}
        handleSearchChange={onSearch}
      />
    );
  };

  return (
    <div className={styles.header}>
      <div className={styles.rightSection}>
        {renderReportsAmount()}
        <div className={styles.divider} />
        {renderSearch()}
      </div>
      {renderActions()}
    </div>
  );
};

ReportsHeader.defaultProps = {
  onDelete: noop,
  reports: [],
  selectedReports: []
};

ReportsHeader.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  reports: PropTypes.array,
  selectedReports: PropTypes.array,
  table: PropTypes.object.isRequired
};

export default ReportsHeader;
