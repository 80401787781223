import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef
} from 'react';
import { ClickAwayListener } from '@material-ui/core';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import { CompleterItem } from './CompleterItem';
import styles from './AutoCompleter.module.scss';
import { AUTO_COMPLETER_RULES } from '@mpm/constants';
import { useHover } from '@/DesignSystem/hooks';
import {
  tokenize,
  getExpressionFromTokens,
  getTokenSearchValue,
  TOKEN
} from './tokenizer.js';

export const AutoCompleterTokenized = forwardRef((props, ref) => {
  const [activeItemIdx, setActiveItemIdx] = useState(0);
  const [allowAutoCompleter, setAllowAutoCompleter] = useState(true);
  const [caretPosition, setCaretPosition] = useState(-1);
  const [completeItemsList, setCompleteItemsList] = useState([]);
  const [position, setPosition] = useState({});
  const [filterPrefix, setFilterPrefix] = useState('');

  const { inputStr, inputStrSetter, rules } = props || {};
  const [hovered] = useHover(ref);
  const { left, top } = position;

  useEffect(() => {
    const handleResize = () => {
      if (ref?.current) {
        const rect = ref.current.getBoundingClientRect();
        setPosition({ left: rect.left, top: rect.top + rect.height });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref?.current]);

  const { tokens } = useMemo(() => {
    return tokenize(inputStr);
  }, [inputStr]);

  const debouncedCompleteItemsSetter = useCallback(
    debounce(value => setCompleteItemsList(value), 50),
    []
  );

  useEffect(() => {
    if (inputStr.length && caretPosition > -1) {
      const activeTokenIdx = tokens.findIndex(
        token => caretPosition > token.start && caretPosition <= token.end
      );

      const activeToken = tokens[activeTokenIdx];

      if (!activeToken || activeToken.type !== TOKEN.Identifier) {
        debouncedCompleteItemsSetter([]);
        return;
      }

      const completingSubType = activeToken.subType;
      const activeRule = rules.find(rule => rule.name === completingSubType);

      if (
        activeToken.end === caretPosition &&
        activeToken.value.endsWith(activeRule?.closingChars)
      ) {
        debouncedCompleteItemsSetter([]);
        return;
      }

      const tokenValue = getTokenSearchValue(
        activeToken,
        activeRule,
        caretPosition
      );
      const completingValue = tokenValue.slice(
        0,
        caretPosition - activeToken.start
      );
      const completerList = activeRule.values.filter(token =>
        token.startsWith(completingValue)
      );
      setFilterPrefix(completingValue);
      debouncedCompleteItemsSetter(completerList);
      return;
    }
    debouncedCompleteItemsSetter([]);
  }, [inputStr, tokens, caretPosition, rules, debouncedCompleteItemsSetter]);

  const clickCompleterItemHandler = useCallback(
    value => {
      const tokensCopy = cloneDeep(tokens);
      const activeTokenIdx = tokens.findIndex(
        token => caretPosition > token.start && caretPosition <= token.end
      );
      const activeToken = tokensCopy[activeTokenIdx];
      const activeRule = rules.find(rule => rule.name === activeToken?.subType);
      if (!activeRule) return;
      const { openingChars, closingChars } = activeRule || {};

      if (activeRule.name === AUTO_COMPLETER_RULES.FUNCTIONS) {
        const isMidPosition = activeTokenIdx < tokensCopy.length - 1;
        activeToken.value = isMidPosition
          ? `${value}`
          : `${value}${closingChars}`;
      }

      if (activeRule.name === AUTO_COMPLETER_RULES.FEATURES) {
        activeToken.value = `${openingChars}${value}${closingChars}`;
      }
      const newExpression = getExpressionFromTokens(tokensCopy);
      inputStrSetter(newExpression);
      const newCaretPosition = activeToken.start + activeToken.value.length;
      setCaretPosition(newCaretPosition);
      ref?.current.focus();
      setTimeout(() => {
        ref?.current.setSelectionRange(newCaretPosition, newCaretPosition);
      }, 0);
    },
    [inputStrSetter, ref, tokens, caretPosition, rules]
  );

  const handleKeyDown = event => {
    setTimeout(() => {
      setCaretPosition(event.target.selectionStart);
    }, 0);
    if (completeItemsList.length) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (activeItemIdx < completeItemsList.length - 1) {
          setActiveItemIdx(prev => prev + 1);
          return;
        }
        setActiveItemIdx(0);
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (activeItemIdx > 0) {
          setActiveItemIdx(prev => prev - 1);
          return;
        }
        setActiveItemIdx(completeItemsList.length - 1);
      }
      if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault();
        if (completeItemsList[activeItemIdx]) {
          clickCompleterItemHandler(completeItemsList[activeItemIdx]);
          setActiveItemIdx(prev => prev - prev);
        }
      }
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      setAllowAutoCompleter(true);
    };

    const handleMouseUp = event => {
      setCaretPosition(event.target.selectionStart);
    };

    if (ref?.current) {
      ref.current.addEventListener('mouseup', handleMouseUp);
      ref.current.addEventListener('focus', handleFocus);
      ref.current.addEventListener('keydown', handleKeyDown);

      return () => {
        if (ref?.current) {
          ref.current.removeEventListener('mouseup', handleMouseUp);
          ref.current.removeEventListener('focus', handleFocus);
          ref.current.removeEventListener('keydown', handleKeyDown);
        }
      };
    }
  }, [ref, activeItemIdx, clickCompleterItemHandler, completeItemsList]);

  const onClose = () => {
    if (hovered) return;
    setAllowAutoCompleter(false);
    setCaretPosition(-1);
  };

  if (!completeItemsList.length) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div
        className={styles.autoCompleterWrapper}
        style={{ left: `${left}px`, top: `${top}px` }}
      >
        {completeItemsList.length > 0 &&
          allowAutoCompleter &&
          completeItemsList.map((value, idx) => {
            return (
              <CompleterItem
                Icon={rules[0]?.Icon}
                value={value}
                clickHandler={clickCompleterItemHandler}
                isActive={activeItemIdx === idx}
                key={value}
                filterPrefix={filterPrefix}
              />
            );
          })}
      </div>
    </ClickAwayListener>
  );
});

AutoCompleterTokenized.displayName = 'AutoCompleterTokenized';
