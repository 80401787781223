// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar-module__sidebarTitle--oE1JN{padding:18px 22px 13px 22px;border-bottom:1px solid var(--gray)}.Sidebar-module__sidebarTitle--oE1JN h2{font-weight:500;font-size:26px;line-height:39px;margin:0;text-align:start}.Sidebar-module__sidebarContent--C8SlV{padding:32px 22px 20px 22px;overflow-y:auto;overflow-x:hidden;min-height:calc(100vh - 175px);max-height:calc(100vh - 175px)}.Sidebar-module__sidebarFooter--WLJdf{height:52px;background-color:var(--gray-1);display:flex;align-items:center;padding-left:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarTitle": `Sidebar-module__sidebarTitle--oE1JN`,
	"sidebarContent": `Sidebar-module__sidebarContent--C8SlV`,
	"sidebarFooter": `Sidebar-module__sidebarFooter--WLJdf`
};
module.exports = ___CSS_LOADER_EXPORT___;
