import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { InputBase } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import useUpdateProjectImageMutation from '@projects/api/useUpdateProjectImageMutation';
import alertsUtil from '@/util/alertsUtil';
import GenericModal from '@shared/components/GenericModal';

const UpdateImageModal = ({ dispatch, modalId, projectId }) => {
  const [imageFile, setImageFile] = useState('');
  const updateProjectImageMutation = useUpdateProjectImageMutation();

  const handleClose = () => {
    dispatch(alertsUtil.closeDialog(modalId));
  };

  const handleSubmit = () => {
    const imageElement = document.getElementById('fileInput');
    const imageFile = get(imageElement, ['files', 0], null);

    const formData = new FormData();
    formData.append('file', imageFile);

    updateProjectImageMutation.mutate({ imageFile: formData, projectId });
    handleClose();
  };

  const renderImageUploadInput = () => {
    return (
      <InputBase
        id="fileInput"
        type="file"
        className="modal-input-base"
        onChange={e => setImageFile(e.target.value)}
        accept="image/*"
        value={imageFile}
      />
    );
  };

  return (
    <GenericModal
      title="Update Image"
      onClose={handleSubmit}
      onCancel={handleClose}
      isButtonDisabled={isEmpty(imageFile)}
      buttonText="Update"
      hasCancelButton
    >
      <DialogContent className="generic-modal-body">
        <div
          className="generic-modal-group"
          style={{
            flexDirection: 'column',
            alignSelf: 'flexStart',
            width: '100%'
          }}
        >
          <div className="generic-modal-section generic-modal-group-item">
            {renderImageUploadInput()}
          </div>
        </div>
      </DialogContent>
    </GenericModal>
  );
};

UpdateImageModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired
};

export default connect()(UpdateImageModal);
