import React from 'react';
import { TextButton, Tooltip } from '@ds';
import { DSDownloadIcon } from '@ds-icons';

type DownloadParamsButtonProps = {
  isDownloadButtonDisabled: boolean;
  onDownload: () => void;
};
const DownloadParamsButton: React.FC<DownloadParamsButtonProps> = ({
  isDownloadButtonDisabled,
  onDownload
}) => {
  return (
    <>
      <Tooltip content="Download as a JSON">
        <TextButton
          disabled={isDownloadButtonDisabled}
          onClick={onDownload}
          type="secondary"
          PrefixIcon={<DSDownloadIcon />}
        >
          Download
        </TextButton>
      </Tooltip>
    </>
  );
};

export default DownloadParamsButton;
