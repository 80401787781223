import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import api from '@API/api';
import { getTeamIdForActiveWorkspace } from '@/reducers/ui/workspaceUiReducer';
import { QueryConfig } from '@shared/api';
import { SegmentDefinitionType } from '@mpm/types';

type SegmentsParams = {
  modelId: string;
};

type SegmentsResposne = {
  segmentDefinitions: SegmentDefinitionType[];
};

const getSegments = ({
  modelId,
  workspaceId,
  ...restParams
}: SegmentsParams & { workspaceId: string }) =>
  api.get(`mpm/${workspaceId}/models/${modelId}/segments`, restParams);

export function useSegments(
  params: SegmentsParams,
  config: QueryConfig<SegmentsResposne>
) {
  const workspaceId = useSelector(getTeamIdForActiveWorkspace);
  return useQuery(
    ['mpm-segments', { workspaceId, ...params }],
    () => getSegments({ workspaceId, ...params }),
    {
      ...config,
      enabled: !!workspaceId
    }
  );
}
