export const uniqueRowId = 'id';

export const DEFAULT_FEATURES_SORTING = [
  { columnName: 'name', direction: 'desc' }
];

export const FEATURE_SERVER_COLUMNS_NAMES = {
  name: 'feature_key',
  featureType: 'feature_type',
  metricType: 'feature_val_type',
  drift: 'drift',
  missingValues: 'missing_values'
};

export const FEATURE_LEFT_COLUMNS = ['name'];

export const FEATURE_PAGE_SIZES = [10, 25, 50, 100];

export const FEATURE_MODEL_NAME_COLUMN_WIDTH = 298;

export const AUTO_COMPLETER_RULES = {
  FUNCTIONS: 'FUNCTIONS',
  FEATURES: 'FEATURES'
} as const;
