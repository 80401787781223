import React from 'react';
import PropTypes from 'prop-types';

import './ChartTooltip.scss';

export const Indicator = ({ position, color }) => {
  return (
    <span
      className="tooltip-color-indicator"
      style={{ ...position, borderColor: color }}
      id="tooltip-indicator"
    />
  );
};

Indicator.propTypes = {
  position: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};
