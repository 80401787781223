import React from 'react';
import cx from 'classnames';
import classes from './ToggleButton.module.scss';

export type ToggleButtonProps = React.ComponentPropsWithoutRef<'input'> & {
  id: string;
};
const ToggleButton = ({ id, ...restProps }: ToggleButtonProps) => {
  return (
    <label className={cx(classes.toggleButtonContainer)} htmlFor={id}>
      <input {...restProps} type="checkbox" id={id} />
      <div className={classes.sliderContainer}>
        <div className={classes.slider} />
      </div>
    </label>
  );
};

export default ToggleButton;
