import React, { useMemo } from 'react';
import cx from 'classnames';
import queryString from 'query-string';
import { useExperimentName } from '@experiment-management-shared/hooks';

import Popover from '@material-ui/core/Popover';
import { BreadcrumbItemProps } from '@shared/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DSCopyIcon } from '@ds-icons';
import { IconButton, Tooltip } from '@ds';
import { Link } from 'react-router-dom';
import alertsUtil from '@/util/alertsUtil';
import { snackbarTypes } from '@/constants/alertTypes';
import { useDispatch } from 'react-redux';

import styles from './BreadcrumbItem.module.scss';
import useBreadcrumbsTrackEvent from '@shared/hooks/useBreadcrumbsTrackEvent';

const CompareItem = (itemParams: BreadcrumbItemProps) => {
  const { ids, dataMaskTest, path, title, isLast = false } = itemParams;
  const trackEvent = useBreadcrumbsTrackEvent();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { data } = useExperimentName({
    experimentKeys: ids || []
  });

  const itemsList = useMemo(() => {
    return (ids || []).map((id, index) => ({ id, title: data?.[index] }));
  }, [data, ids]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    trackEvent(itemParams);
  };

  const handleLinkClick = (experimentPath: string) => {
    setAnchorEl(null);

    trackEvent(itemParams, { path: experimentPath });
  };

  const handleCopyClick = (id: string) => {
    dispatch(
      alertsUtil.openSnackbarDialog(
        snackbarTypes.SUCCESS_COPIED_TEXT,
        'Successfully copied experiment key to clipboard'
      )
    );

    trackEvent(itemParams, { experimentKey: id });
  };

  const experimentTab = queryString.parse(window.location.search)[
    'experiment-tab'
  ];
  const experimentTabSearch = experimentTab
    ? `?experiment-tab=${experimentTab}`
    : '';

  return (
    <>
      <button
        className={cx(styles.item, styles.compareItem, {
          [styles.last]: isLast,
          [styles.active]: Boolean(anchorEl)
        })}
        data-test="breadcrumbs-compare"
        onClick={handleClick}
      >
        {title}
      </button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: styles.popoverContainer }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -6,
          horizontal: 'left'
        }}
      >
        <div
          className={styles.listContainer}
          data-test="breadcrumbs-compare-container"
        >
          {itemsList.map(item => {
            const experimentPath = `${path}/${item.id}`;
            return (
              <div key={item.id} className={styles.listItem}>
                <Tooltip content={item.title} size="small" arrow={false}>
                  <Link
                    data-mask-test={dataMaskTest}
                    to={location => ({
                      state: location.state,
                      pathname: experimentPath,
                      search: experimentTabSearch
                    })}
                    onClick={() => handleLinkClick(experimentPath)}
                  >
                    {item.title}
                  </Link>
                </Tooltip>
                <CopyToClipboard text={item.id}>
                  <IconButton
                    onClick={() => handleCopyClick(item.id)}
                    type="secondary"
                    Icon={<DSCopyIcon />}
                  />
                </CopyToClipboard>
              </div>
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export default CompareItem;
