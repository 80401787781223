import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import isObject from 'lodash/isObject';
import { EXPERIMENT_VIEW_TAB_FIELDS } from '@experiment-management-shared/constants/chartConstants';
import {
  filterAssets,
  groupAssetsBy,
  IMAGE_KEY_PREFIX,
  sortAssets
} from '@experiment-details/utils/graphics';

import {
  GROUP_BY_OPTIONS,
  SORT_BY_OPTIONS
} from '@experiment-details/constants/graphics';
import ExperimentAssetsConfigBar from '../../ExperimentAssetsConfigBar';
import { MAX_METADATA_SORTING_KEYS } from '@/constants/configConstants';

const GraphicsConfigBar = ({
  experimentKey,
  graphicAssets,
  onGraphicAssetsFilter,
  actions,
  isTabEmpty
}) => {
  const metadataAsOptions = useMemo(() => {
    const addedKeys = new Set();

    return orderBy(
      graphicAssets.reduce((options, { metadata: metadataString }) => {
        if (
          metadataString === undefined ||
          addedKeys.size >= MAX_METADATA_SORTING_KEYS
        ) {
          return options;
        }
        const metadata = JSON.parse(metadataString);
        Object.keys(metadata).forEach(key => {
          if (
            addedKeys.size >= MAX_METADATA_SORTING_KEYS ||
            isObject(metadata[key])
          ) {
            return;
          }

          if (!addedKeys.has(key)) {
            options.push({
              label: key,
              value: 'metadata' + IMAGE_KEY_PREFIX + key
            });

            addedKeys.add(key);
          }
        });

        return options;
      }, []),
      ['label'],
      ['asc']
    );
  }, [graphicAssets]);

  return (
    <ExperimentAssetsConfigBar
      assets={graphicAssets}
      enableAutoResearch
      experimentKey={experimentKey}
      filterAssets={filterAssets}
      groupByOptions={[...GROUP_BY_OPTIONS, ...metadataAsOptions]}
      groupAssetsBy={groupAssetsBy}
      onAssetsSearch={({ assets, groupBy }) =>
        onGraphicAssetsFilter(assets, groupBy)
      }
      sortAssets={sortAssets}
      sortByOptions={[...SORT_BY_OPTIONS, ...metadataAsOptions]}
      valuePathCategory={EXPERIMENT_VIEW_TAB_FIELDS.IMAGES}
      buttons={actions}
      isTabEmpty={isTabEmpty}
    />
  );
};

GraphicsConfigBar.defaultProps = {
  graphicAssets: [],
  onGraphicAssetsFilter: noop
};

GraphicsConfigBar.propTypes = {
  actions: PropTypes.node.isRequired,
  experimentKey: PropTypes.string.isRequired,
  graphicAssets: PropTypes.arrayOf(PropTypes.string),
  onGraphicAssetsFilter: PropTypes.func,
  isTabEmpty: PropTypes.bool
};

export default GraphicsConfigBar;
