import { useMemo } from 'react';

const useScrollbarWidth = () =>
  useMemo((): number => {
    const div = document.createElement('div');
    const { style } = div;

    // for the case of weird css rules where div will not be a block element.
    style.display = 'block';
    style.position = 'absolute';
    style.width = '100px';
    style.height = '100px';
    style.left = '-999px';
    style.top = '-999px';
    style.overflow = 'scroll';

    document.body.insertBefore(div, null);

    const { clientWidth } = div;

    document.body.appendChild(div);
    const scrollbarWidth = 100 - clientWidth;
    document.body.removeChild(div);

    return scrollbarWidth;
  }, []);

export default useScrollbarWidth;
