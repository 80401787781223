import { useQuery } from 'react-query';
import api, { QueryConfig } from '@shared/api';
import alertsUtil from '@/util/alertsUtil';
import { dialogTypes } from '@/constants/alertTypes';
import { useDispatch } from 'react-redux';

export type GitDetails = {
  branch: string;
  gitHead: string;
  gitOrigin: string;
  gitUsername: string;
  hostname: string;
  ip: string;
  linkToBranch: string;
  linkToGitHead: string;
  linkToPatch: string;
  notebookAvailable: boolean;
  notebookLink: string;
  patchExists: boolean;
  reproduceCommands: string[];
  runCommand: string;
};

type UseExperimentGitDetailsParams = {
  experimentKey: string;
};

const getExperimentGitDetails = async ({
  experimentKey,
  signal
}: {
  experimentKey: string;
  signal: AbortSignal | undefined;
}) => {
  const { data } = await api.get('git/get-details', {
    params: { experimentKey },
    signal
  });

  return data;
};

export default function useExperimentGitDetails(
  { experimentKey }: UseExperimentGitDetailsParams,
  options: QueryConfig<GitDetails> = {}
) {
  const dispatch = useDispatch();

  return useQuery(
    ['experiment-git-details', { experimentKey }],
    ({ signal }) => {
      return getExperimentGitDetails({
        experimentKey,
        signal
      });
    },
    {
      enabled: !!experimentKey,
      refetchOnMount: true,
      onError: () => {
        dispatch(
          alertsUtil.openErrorDialog(
            dialogTypes.CATCH_ERROR_FETCH_GIT_DETAILS,
            'There was an error fetching the git details for this experiment.'
          )
        );
      },
      ...options
    }
  );
}
