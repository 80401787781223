import { GET_VALUE_FOR_SORTING } from '@experiment-management-shared/constants/experimentGridConstants';
import { formatNameForDisplay } from '@shared/utils/displayHelpers';

export const namesToColumns = (columns, columnNames) =>
  columnNames
    .map(columnName => columns.find(column => column.name === columnName))
    .filter(Boolean);

export const transformColumnNameToColumn = columnName => {
  const column = {
    name: columnName,
    title: formatNameForDisplay(columnName)
  };

  if (!Object.keys(GET_VALUE_FOR_SORTING).includes(columnName)) {
    return column;
  }

  return {
    ...column,
    getCellValue: GET_VALUE_FOR_SORTING[columnName]
  };
};
