import * as ComLink from 'comlink';
import { useEffect } from 'react';
import { iFrameConfig } from '@experiment-management-shared/constants';
import { PY_PANELS_API_URL, PY_PANELS_CDN_URL } from '../lib/appConstants';
// eslint-disable-next-line import/default
import PyodideWorker from '../workers/pyodide.worker';

window.PYODIDE_WORKER = new PyodideWorker();
const { initPyodide } = ComLink.wrap(window.PYODIDE_WORKER);
window.initPyodideWorker = () => {
  const api = PY_PANELS_API_URL || window.location.origin;
  const cdn = PY_PANELS_CDN_URL || window.location.origin;

  initPyodide(api, cdn);
};
export default function usePyodide() {
  useEffect(() => {
    const handleRunPyCode = event => {
      if (
        event.data.messageType === iFrameConfig.messageTypes.RUN_PY_CODE ||
        event.data.messageType === iFrameConfig.messageTypes.CALL_PY_FUNCTION
      ) {
        window.PYODIDE_WORKER.postMessage(event.data);
      }
    };

    window.addEventListener('message', handleRunPyCode);

    return () => {
      window.removeEventListener('message', handleRunPyCode);
    };
  }, []);
}
