import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { readString } from 'react-papaparse';

import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import SmallLoader from '@shared/components/SmallLoader';
import EmptyPreview from '@experiment-management-shared/components/AssetsPreview/EmptyPreview';
import StyledCheckbox from '@shared/components/StyledComponents/StyledCheckbox';
import useAssetById from '@experiment-management-shared/api/useAssetById';

const useStyles = makeStyles({
  spacer: {
    flex: 'none'
  }
});

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'visible'
  }
})(TableContainer);

const StyledTableCell = withStyles({
  root: {
    fontSize: '13px',
    whiteSpace: 'nowrap'
  }
})(TableCell);

const MAX_FILE_SIZE = 10000000; // 10MB

const CSVPreview = ({
  artifactVersionId,
  assetId,
  experimentKey,
  fileSize
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showHeaderRow, setShowHeaderRow] = useState(true);

  const { data: asset, isLoading } = useAssetById({
    experimentKey,
    assetId,
    artifactVersionId,
    asText: true
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CSVData = useMemo(() => {
    if (asset) {
      const { data } = readString(asset);

      return data;
    }

    return [];
  }, [asset]);

  const renderRow = (rowData, index) => {
    return (
      <TableRow key={index}>
        {rowData.map((cellValue, valueIndex) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <StyledTableCell key={valueIndex} scope="row">
              {cellValue}
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderTableHeader = () => {
    if (!showHeaderRow) return null;

    const tableHeaderRows = first(CSVData).map(columnName => {
      return <StyledTableCell key={columnName}>{columnName}</StyledTableCell>;
    });

    return (
      <TableHead>
        <TableRow>{tableHeaderRows}</TableRow>
      </TableHead>
    );
  };

  const renderShowHeaderCheckbox = () => {
    return (
      <div className="csv-preview-checkbox-row">
        <FormControlLabel
          classes={classes.checkboxLabel}
          control={
            <StyledCheckbox
              checked={showHeaderRow}
              onChange={e => setShowHeaderRow(e.target.checked)}
            />
          }
          label="Data contains header row"
        />
      </div>
    );
  };

  const renderTable = () => {
    const rows = showHeaderRow ? CSVData.slice(1) : CSVData;

    return (
      <StyledTableContainer>
        <Table size="small">
          {renderTableHeader()}

          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(renderRow)}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                classes={classes}
                rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                count={CSVData.length}
                colSpan={6}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StyledTableContainer>
    );
  };

  if (fileSize > MAX_FILE_SIZE) {
    return <EmptyPreview message="File size too large for preview." />;
  }

  if (isLoading) {
    return <SmallLoader primaryMessage="Loading..." />;
  }

  if (isEmpty(asset)) {
    return <EmptyPreview message="No  preview available." />;
  }

  return (
    <div className="flex column">
      {renderShowHeaderCheckbox()}
      {renderTable()}
    </div>
  );
};

CSVPreview.defaultProps = {
  artifactVersionId: undefined
};

CSVPreview.propTypes = {
  artifactVersionId: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  experimentKey: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired
};

export default CSVPreview;
