import { Button } from '@ds';
import React from 'react';
import { DSPlusIcon } from '@ds-icons';

const AddPanelButton = props => {
  return (
    <Button PrefixIcon={<DSPlusIcon />} {...props}>
      Add Panel
    </Button>
  );
};

export default AddPanelButton;
