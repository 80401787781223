import AddPanelButton from '@reports/components/AddPanelButton';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import {
  PANEL_SOURCES,
  SELECT_PANEL_PATH
} from '@experiment-management-shared/constants/visualizationConstants';
import PanelModal from '@experiment-management-shared/components/PanelModal';
import AutoRefreshToggle from './AutoRefreshToggle';

import ManualRefreshBtn from './ManualRefreshBtn';

const SectionPanelsHeader = ({
  experiments,
  experimentKeys,
  hiddenExperimentKeys,
  isAutoRefreshEnabled,
  isModalOpen,
  location,
  handleAddPanelButtonClick,
  onAddBuiltInPanel,
  onAddCustomPanel,
  onAutoRefreshChange,
  onEditBuiltInPanel,
  onEditCustomPanel,
  onRefreshPanelsClick
}) => {
  const [experimentKey] = experimentKeys;

  const renderHeaderButtons = () => {
    return (
      <div className="panels-tab-header-buttons">
        <AutoRefreshToggle
          enabled={isAutoRefreshEnabled}
          onChange={onAutoRefreshChange}
        />
        <ManualRefreshBtn onClick={onRefreshPanelsClick} />
        <AddPanelButton
          experimentKey={experimentKey}
          onClick={handleAddPanelButtonClick}
        />
      </div>
    );
  };

  const renderModals = () => {
    if (!isModalOpen) return null;

    if (location.hash.includes(`#${SELECT_PANEL_PATH}`)) {
      return ReactDOM.createPortal(
        <div className="page-app-wrap">
          <PanelModal
            experiments={experiments}
            experimentKeys={experimentKeys}
            hiddenExperimentKeys={hiddenExperimentKeys}
            onAddBuiltInPanel={onAddBuiltInPanel}
            onAddCustomPanel={onAddCustomPanel}
            onEditBuiltInPanel={onEditBuiltInPanel}
            onEditCustomPanel={onEditCustomPanel}
            source={PANEL_SOURCES.EXPERIMENT}
          />
        </div>,
        document.body
      );
    }

    return null;
  };

  return (
    <div className="panels-tab-header">
      {renderModals()}
      {renderHeaderButtons()}
    </div>
  );
};

SectionPanelsHeader.defaultProps = {
  isAutoRefreshEnabled: false,
  isModalOpen: false,
  hiddenExperimentKeys: null,
  handleAddPanelButtonClick: noop,
  onAddBuiltInPanel: noop,
  onAddCustomPanel: noop,
  onAutoRefreshChange: noop,
  onEditBuiltInPanel: noop,
  onEditCustomPanel: noop,
  onRefreshPanelsClick: noop
};

SectionPanelsHeader.propTypes = {
  experiments: PropTypes.array.isRequired,
  experimentKeys: PropTypes.array.isRequired,
  hiddenExperimentKeys: PropTypes.array,
  isAutoRefreshEnabled: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  handleAddPanelButtonClick: PropTypes.func,
  onAddBuiltInPanel: PropTypes.func,
  onAddCustomPanel: PropTypes.func,
  onAutoRefreshChange: PropTypes.func,
  onEditBuiltInPanel: PropTypes.func,
  onEditCustomPanel: PropTypes.func,
  onRefreshPanelsClick: PropTypes.func,
  section: PropTypes.object.isRequired
};

export default withRouter(SectionPanelsHeader);
