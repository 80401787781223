import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { createSelector } from 'reselect';
import {
  defaultFormFields,
  DEFAULT_LEGEND_KEYS
} from '@experiment-management-shared/constants/chartConstants';

import { dashboardChartActionTypes } from '@/constants/actionTypes';

const initialState = {
  chartForm: defaultFormFields,
  sectionId: ''
};

const dashboardChartsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === dashboardChartActionTypes.UPDATE_CHART_FORM) {
    const updateByType = {
      [payload.chartType]: {
        ...state.chartForm[payload.chartType],
        ...payload.updatedForm
      }
    };

    return {
      ...state,
      chartForm: { ...state.chartForm, ...updateByType }
    };
  }

  if (type === dashboardChartActionTypes.RESET_CHART_FORM_BY_TYPE) {
    const resetByType = {
      [payload]: {
        ...state.chartForm[payload],
        ...initialState.chartForm[payload]
      }
    };

    return {
      ...state,
      chartForm: { ...state.chartForm, ...resetByType }
    };
  }

  if (type === dashboardChartActionTypes.RESET_ENTIRE_CHART_FORM) {
    return {
      ...state,
      chartForm: defaultFormFields
    };
  }

  if (type === dashboardChartActionTypes.SET_ACTIVE_SECTION_ID) {
    return {
      ...state,
      sectionId: payload.sectionId
    };
  }

  return state;
};

export const getChartId = (_, props) => props.chartId;
export const getChartType = (_, props) => props.chartType;

const getChartForm = state => state.dashboardCharts.chartForm;

export const getSectionId = state => state.dashboardCharts.sectionId;

export const getChartFormByType = createSelector(
  [getChartType, getChartForm],
  (chartType, chartForm) => {
    return get(chartForm, chartType);
  }
);

export const getSelectedLegendKeys = createSelector(
  [getChartType, getChartId, getChartForm],
  (chartType, chartId, chartForm) => {
    let selectedLegendKeys = get(chartForm, [chartType, 'selectedLegendKeys']);

    return Array.isArray(selectedLegendKeys) && !isEmpty(selectedLegendKeys)
      ? selectedLegendKeys
      : DEFAULT_LEGEND_KEYS;
  }
);

export default dashboardChartsReducer;
