import React, { useState } from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';
import { Select } from '@ds';

const ColumnValues = ({ initValue, onChange, options, disabled }) => {
  const [value, setValue] = useState(initValue);

  const handleChange = value => {
    onChange(value);
    setValue(value);
  };

  return (
    <Select
      data-test="filter-select"
      disabled={disabled}
      options={options}
      value={value}
      width="228px"
      onValueChange={handleChange}
      placeholder="Click to select:"
      isSearchable
    />
  );
};

ColumnValues.defaultProps = {
  disabled: false,
  initValue: null,
  onChange: noop,
  options: []
};

ColumnValues.propTypes = {
  disabled: PropTypes.bool,
  initValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array
};

export default ColumnValues;
