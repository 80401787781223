import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { IExperimentDetails } from '@API/experiments/useExperimentsDetails';
import {
  useExperimentAllOutput,
  useExperimentOutput
} from '@experiment-details/api';
import {
  EXP_KEY_LENGTH_EXPORT,
  FETCH_EXPERIMENT_POLLING_LENGTH
} from '@experiment-management-shared/constants/experimentConstants';

import { FILE_EXTENSIONS } from '@experiment-management-shared/utils/filesTreeUtils';
import ExportFileModal from '@experiment-management-shared/components/ExportFileModal';
import SmallLoader from '@shared/components/SmallLoader';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import IsTruncatedBlock from './IsTruncatedBlock';
import OutputTabBar from './OutputTabBar';
import Terminal from './Terminal';
import OutputTabEmpty from './OutputTabEmpty';

const OUTPUT_TABS = {
  Combined: 'Combined',
  STDERR: 'STDERR',
  STDOUT: 'STDOUT'
};

const removeInitialRedColor = ({ stderr, stdout }) => {
  return stderr ? stdout.replace(/^\033\[0;31m/, '') : stdout;
};

const OutputTab = ({ experiments }) => {
  const dispatch = useDispatch();
  const [experiment] = experiments;

  const [waitingForDownloadData, setWaitingForDownloadData] = useState(false);
  const [downloadFileName, setDownloadFileName] = useState('');
  const [activeTab, setActiveTab] = useState(OUTPUT_TABS.Combined);

  const match = useRouteMatch();
  const { projectName, workspace } = match.params;
  const { experimentKey } = experiment;
  const { data: outputData, isLoading } = useExperimentOutput(
    { experimentKey },
    { refetchInterval: FETCH_EXPERIMENT_POLLING_LENGTH }
  );
  const { data: allOutputData } = useExperimentAllOutput(
    { experimentKey },
    { refetchInterval: FETCH_EXPERIMENT_POLLING_LENGTH }
  );
  const trimmed = !!outputData?.trimmed;
  const outputTruncated = outputData?.outputTruncated ?? false;
  const stdout = useMemo(() => outputData?.stdout ?? [], [outputData?.stdout]);

  useEffect(() => {
    if (!allOutputData || !waitingForDownloadData) return;

    const blob = new Blob(allOutputData?.stdout, {
      type: 'text/plain;charset=utf-8'
    });

    FileSaver.saveAs(blob, downloadFileName);

    setWaitingForDownloadData(false);
    setDownloadFileName('');
  }, [allOutputData, downloadFileName, waitingForDownloadData]);

  const handleDownload = fileName => {
    setDownloadFileName(fileName);

    setWaitingForDownloadData(true);
  };

  const currentTabData = useMemo(() => {
    let filteredStdout = stdout;

    if (activeTab === OUTPUT_TABS.STDOUT) {
      filteredStdout = filteredStdout.filter(output => output.stderr === false);
    }

    if (activeTab === OUTPUT_TABS.STDERR) {
      filteredStdout = filteredStdout.filter(output => output.stderr === true);
    }

    // The BE adds an unnecessary red color at the beginning of the stderr
    // Remove this once the BE side fix this
    return filteredStdout.map(removeInitialRedColor);
  }, [activeTab, stdout]);

  const handleTabChange = useCallback(
    (event, tabName) => setActiveTab(tabName),
    [setActiveTab]
  );

  if (isLoading) {
    return (
      <div className="empty-detail">
        <SmallLoader />
      </div>
    );
  }

  const handleExportData = () => {
    const modalId = dialogTypes.EXPORT_FILE_MODAL;

    const exportFileModal = (
      <ExportFileModal
        submitHandler={handleDownload}
        modalId={modalId}
        fileExtension={FILE_EXTENSIONS.txt}
        modalTitle="Export Output"
        customFilenamePaths={[
          experimentKey.slice(0, EXP_KEY_LENGTH_EXPORT),
          'output'
        ]}
      />
    );

    dispatch(alertsUtil.openCustomModal(modalId, exportFileModal));
  };

  const handleViewRaw = () => {
    const rawUrl = `${window.location.origin}/${workspace}/${projectName}/${experimentKey}/raw/output`;
    window.open(rawUrl, '_blank', 'noopener');
  };

  const tabIsEmpty = isEmpty(stdout);

  return (
    <div className="contained-wrap output-tab">
      <OutputTabBar
        activeTab={activeTab}
        outputTabs={Object.keys(OUTPUT_TABS)}
        waitingForDownloadData={waitingForDownloadData}
        onExportData={handleExportData}
        onViewRaw={handleViewRaw}
        onTabChange={handleTabChange}
        isDisabled={tabIsEmpty}
      />
      {outputTruncated && <IsTruncatedBlock onExportData={handleExportData} />}

      {tabIsEmpty ? (
        <OutputTabEmpty />
      ) : (
        <div className="terminal-display-content contained-wrap">
          <Terminal
            data={currentTabData}
            Classes="stdout output"
            trimmed={trimmed}
          />
        </div>
      )}
    </div>
  );
};

OutputTab.propTypes = {
  experiments: PropTypes.arrayOf(IExperimentDetails).isRequired
};

export default OutputTab;
