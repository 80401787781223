import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { unix } from 'moment';
import cx from 'classnames';

import { Button } from '@ds';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import TextOverflow from '@shared/components/TextOverflow';
import ItemMenuBtn from '@shared/components/WorkspaceSubComponents/ItemMenuBtn';
import { DEFAULT_PINNED_ITEM_CONFIG } from '@/constants/workspaceConstants';

const SingleCard = ({
  description,
  hideBackgroundImage,
  isFetchingNewData,
  imagePath,
  isEditable,
  isPublic,
  itemCount,
  lastUpdated,
  menuItemConfigs,
  pinnedItemConfig,
  title,
  viewLinkConfig,
  dataTestId
}) => {
  const { isPinned } = pinnedItemConfig;

  const renderItemCount = () => {
    const { count, label } = itemCount;

    return (
      <div className="card-item-detail">
        <i className="material-icons">storage</i> {count} {label}
      </div>
    );
  };

  const renderLastUpdated = () => {
    return (
      <div className="card-item-detail">
        <i className="material-icons">schedule</i>{' '}
        {unix(lastUpdated / 1000).fromNow()}
      </div>
    );
  };

  const renderViewButton = () => {
    const { url, entityName, dataTest } = viewLinkConfig;

    return (
      <Link to={url}>
        <Button className="card-button" size="XL" data-test={dataTest}>
          View {entityName}
        </Button>
      </Link>
    );
  };

  const renderDescription = () => {
    return (
      <TextOverflow
        className="card-description"
        originalText={description}
        maxVisibleLength={90}
      />
    );
  };

  const renderPinnedCardHeader = () => {
    const { handler, color, text } = pinnedItemConfig;

    return (
      <div
        className="pinned-card-header"
        style={{
          backgroundColor: color
        }}
      >
        <span>{text}</span>
        {isEditable && handler && (
          <IconButton className="header-delete-btn" onClick={handler}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  };

  const opacity = isFetchingNewData ? 0.3 : 1.0;

  return (
    <div className="card" style={{ opacity }}>
      <div
        className={cx('card-header', {
          'header-no-image': hideBackgroundImage,
          'is-pinned': isPinned
        })}
      >
        {isPinned && renderPinnedCardHeader()}
        {isEditable && !pinnedItemConfig.handler && (
          <ItemMenuBtn
            dataTestId={dataTestId}
            className="card-menu-button"
            iconType="more_vert"
            iconSize="24px"
            menuItemConfigs={menuItemConfigs}
          />
        )}

        <img className="card-thumbnail" src={encodeURI(imagePath)} />
        <div className="card-title-container">
          <span className="card-title" data-test="card-title">
            <TextOverflow
              emptyTextPlaceholder="-"
              maxVisibleLength={40}
              originalText={title}
            />
          </span>
          <div className="project-badge">
            {' '}
            {isPublic ? 'Public' : 'Private'}{' '}
          </div>
        </div>
      </div>

      <div className="card-body">
        {renderDescription()}
        <div className="card-item-details">
          {renderItemCount()}
          {renderLastUpdated()}
        </div>
        {renderViewButton()}
      </div>
    </div>
  );
};

SingleCard.defaultProps = {
  dataTestId: '',
  hideBackgroundImage: false,
  isEditable: false,
  pinnedItemConfig: DEFAULT_PINNED_ITEM_CONFIG,
  isFetchingNewData: false
};

SingleCard.propTypes = {
  dataTestId: PropTypes.string,
  description: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isFetchingNewData: PropTypes.bool,
  isPublic: PropTypes.bool.isRequired,
  itemCount: PropTypes.object.isRequired,
  lastUpdated: PropTypes.number.isRequired,
  menuItemConfigs: PropTypes.array.isRequired,
  pinnedItemConfig: PropTypes.object,
  hideBackgroundImage: PropTypes.bool,
  title: PropTypes.string.isRequired,
  viewLinkConfig: PropTypes.object.isRequired
};

export default SingleCard;
