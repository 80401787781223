import {
  AssetData,
  AssetStep,
  PCDAssetData
} from '@experiment-management-shared/types';
import { transformAssetDataToPCDAsset } from '@experiment-management-shared/utils';
import api, { QueryConfig } from '@shared/api';
import { useQuery } from 'react-query';

type PCDAssetsData = {
  assets: AssetData[];
};

const getPCDPanelData = async ({
  assetNames,
  experimentKeys,
  step
}: {
  assetNames: string[];
  experimentKeys: string[];
  step: AssetStep;
}) => {
  const { data } = await api.post<PCDAssetsData>('asset/filter-data', {
    assetNames,
    assetType: '3d-image',
    experiments: experimentKeys,
    step: step || 0
  });

  return data.assets.map(asset => transformAssetDataToPCDAsset(asset));
};

type PCDPanelDataQueryParams = {
  assetNames: string[];
  experimentKeys: string[];
  step: AssetStep;
};

export function usePCDPanelData(
  { assetNames, experimentKeys, step }: PCDPanelDataQueryParams,
  config: QueryConfig<PCDAssetData[]>
) {
  const configEnabled = config?.enabled ?? true;
  const conditionsEnabled = !!assetNames?.length && !!experimentKeys?.length;

  return useQuery(
    [
      'pcdPanelData',
      // copy and sort arrays to not trigger request every time order of parameters is changed
      {
        assetNames: assetNames.slice().sort(),
        experimentKeys: experimentKeys.slice().sort(),
        step
      }
    ],
    () => getPCDPanelData({ assetNames, experimentKeys, step }),
    { ...config, enabled: configEnabled && conditionsEnabled }
  );
}
