import React, { useState } from 'react';
import { Slider } from '@design-system-outdated';
import { debounce } from 'lodash';

type ComparisonTableDecimalSliderProps = {
  decimalsPrecision: number;
  maxDecimalsInItems: number;
  onChangeDecimalsPrecision: (v: number) => void;
};
const ComparisonTableDecimalSlider: React.FC<ComparisonTableDecimalSliderProps> = ({
  decimalsPrecision,
  maxDecimalsInItems,
  onChangeDecimalsPrecision
}) => {
  const [
    tempDecimalsPrecision,
    onChangeTempDecimalsPrecision
  ] = useState<number>();
  const debouncedOnChangeDecimalsPrecision = debounce(
    onChangeDecimalsPrecision,
    300
  );

  return (
    <Slider
      min={0}
      max={maxDecimalsInItems}
      step={1}
      value={tempDecimalsPrecision ?? decimalsPrecision}
      onChange={onChangeTempDecimalsPrecision}
      onChangeCommitted={debouncedOnChangeDecimalsPrecision}
    />
  );
};

export default ComparisonTableDecimalSlider;
