import { flatten, isEmpty, sortBy } from 'lodash';
import { useQuery } from 'react-query';
import api from '../api';
import { IMAGE_ANNOTATIONS_COLORS } from './consts';

const labelColorMap = {};
let colorIndex = 0;

const getMetadata = async ({ experimentKeys, imagesIds, signal }) => {
  const imagesData = await api.post(
    '/image/get-metadata',
    {
      experimentKeys,
      imagesIds
    },
    signal
  );

  return imagesData.map(({ metadata, experimentKey, imageId }) => {
    const { annotations: layers } = JSON.parse(metadata);
    if (isEmpty(layers))
      return {
        imageId,
        experimentKey,
        annotations: [],
        labels: [],
        labelColorMap,
        metadata
      };

    // loop through each layer and flatten
    const labels = new Set();
    const annotations = flatten(
      layers.map(layer => {
        // layer has a name & data array with annotations
        const sortedLayer = sortBy(layer.data, 'label');
        sortedLayer.forEach(({ label }) => {
          labels.add(label);
          labelColorMap[label] =
            labelColorMap[label] ||
            IMAGE_ANNOTATIONS_COLORS[
              colorIndex % IMAGE_ANNOTATIONS_COLORS.length
            ];

          if (labelColorMap[label]) {
            colorIndex += 1;
          }
        });

        // add id to each annotation layer
        return sortedLayer.map((val, id) => ({ ...val, id }));
      })
    );

    return {
      imageId,
      experimentKey,
      annotations,
      labels: Array.from(labels),
      labelColorMap,
      metadata
    };
  });
};

const useMetadataForImages = ({ experimentKeys, imagesIds }, config) =>
  useQuery(
    ['metadataForImages', { experimentKeys, imagesIds }],
    ({ signal }) => getMetadata({ experimentKeys, imagesIds, signal }),
    config
  );

export default useMetadataForImages;
