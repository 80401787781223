import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isNumber from 'lodash/isNumber';
import { TEMPLATE_SCOPE_TYPE_LABELS } from '@experiment-management-shared/constants/chartsGallery';

const { BUILT_IN, INTERNAL, WORKSPACE } = TEMPLATE_SCOPE_TYPE_LABELS;

const modifiedClasses = {
  [BUILT_IN]: 'built-in',
  [INTERNAL]: 'featured',
  [WORKSPACE]: 'workspace'
};

const TabLabelWithBadge = ({ labelText, badgeCount, max }) => {
  const countDisplay = badgeCount > max ? `${max}+` : badgeCount;

  const badgeClassName = cx('count-badge', {
    [modifiedClasses[labelText]]: modifiedClasses.hasOwnProperty(labelText)
  });

  const hasBadge = isNumber(badgeCount);

  return (
    <div className="tab-with-badge">
      {labelText}
      {hasBadge && <div className={badgeClassName}>{countDisplay}</div>}
    </div>
  );
};

TabLabelWithBadge.defaultProps = {
  max: 5000,
  badgeCount: 0
};

TabLabelWithBadge.propTypes = {
  labelText: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
  max: PropTypes.number
};

export default TabLabelWithBadge;
