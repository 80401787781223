import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import { dashboardEvents } from '@/constants/trackingEventTypes';
import useBaseTrackEvent from '@shared/hooks/useBaseTrackEvent';
import { PanelGlobalConfig } from '@experiment-management-shared/types';
import { X_AXIS_OPTIONS } from '@experiment-management-shared/constants/chartConstants';
import typography from '@ds-typography';
import { IconButton, Tooltip, Select } from '@ds';
import { DSXAxisIcon } from '@ds-icons';
import Popover from '@material-ui/core/Popover';

import styles from './Button.module.scss';

type XAxisButtonProps = {
  disabled: boolean;
  panelGlobalConfig: PanelGlobalConfig;
  onChange: (changes: unknown) => void;
  showLockedPanelsWarning: boolean;
};
const XAxisButton = ({
  disabled,
  panelGlobalConfig,
  onChange,
  showLockedPanelsWarning
}: XAxisButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const baseTrackEvent = useBaseTrackEvent();

  const onChangeValue = useCallback(
    (selectEvent: unknown) => {
      onChange({
        panels: {
          config: {
            ...panelGlobalConfig,
            selectedXAxis: selectEvent
          }
        }
      });

      baseTrackEvent(dashboardEvents.PROJECT_PAGE_PANELS_X_AXIS_UPDATED, {
        old_value: panelGlobalConfig.selectedXAxis,
        new_value: selectEvent
      });
    },
    [baseTrackEvent, onChange, panelGlobalConfig]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip content="X-axis dashboard settings" placement="top">
        <IconButton
          onClick={handleClick}
          type="secondary"
          Icon={<DSXAxisIcon />}
          disabled={disabled}
          active={Boolean(anchorEl)}
          data-test="dashboard-x-axis"
        />
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: -6,
          horizontal: 'right'
        }}
        classes={{ paper: styles.popoverContainer }}
      >
        <div>
          <div className="select-label">X-axis</div>
          <Select
            dataTest="dashboard-x-axis-select"
            onValueChange={onChangeValue}
            options={X_AXIS_OPTIONS}
            isClearable={false}
            value={panelGlobalConfig?.selectedXAxis}
          />
        </div>

        {showLockedPanelsWarning && (
          <div className={cx(styles.warning, typography.dsBody)}>
            Some panels use the panel level settings. You can unlock a panel to
            use the dashboard one.
          </div>
        )}
      </Popover>
    </>
  );
};

export default XAxisButton;
