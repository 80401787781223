import { useCallback, useMemo, useRef, useState } from 'react';
import {
  useRemoveExperimentOtherMutation,
  useUpsertExperimentOtherMutation
} from '@experiment-details/api';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { OtherParam } from '@shared/types';
import { isStringInSearchText } from '@/helpers/generalHelpers';
import { dialogTypes } from '@/constants/alertTypes';
import alertsUtil from '@/util/alertsUtil';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { DeleteOtherParamModalProps } from '../modals/DeleteOtherParamModel';
import { generateRegexFromString } from '@shared/utils/displayHelpers';

type OtherParamEditingMap = Record<string, Partial<OtherParam>>;

type UseOtherSingleTableAPIOptions = {
  experimentKey: string;
  items: OtherParam[];
  searchText: string;
};
const useOtherSingleTableAPI = ({
  experimentKey,
  items,
  searchText
}: UseOtherSingleTableAPIOptions) => {
  const dispatch = useDispatch();
  const upsertExperimentOtherMutation = useUpsertExperimentOtherMutation();
  const removeExperimentOtherMutation = useRemoveExperimentOtherMutation();

  const [editingRowIds, setEditingRowIds] = useState<string[]>([]);
  const [addedRows, setAddedRows] = useState<OtherParam[]>([]);
  const [deleteModalState, setDeleteModalState] = useState({
    opened: false,
    paramKey: ''
  });

  const editingMap = useRef<OtherParamEditingMap>({});

  const rowList = useMemo(() => {
    const searchRegex = generateRegexFromString(searchText);

    const rowsUnsorted = items.filter(item => {
      if (searchRegex) {
        return searchRegex.test(item.name);
      }

      return isStringInSearchText(item.name, searchText);
    });

    return orderBy(rowsUnsorted, ['editable'], ['desc']);
  }, [items, searchText]);

  const onAddOtherParam = () => setAddedRows([{ name: '', valueCurrent: '' }]);

  const changeAddedOtherParams = useCallback(
    (addedListChanges: OtherParam[]) => {
      setAddedRows([...addedListChanges]);
    },
    []
  );

  const changeEditedOtherParams = useCallback(
    (editedListChangesMap: OtherParamEditingMap) => {
      editingMap.current = editedListChangesMap;
    },
    []
  );

  const onEditOtherParams = (key: string) => {
    setEditingRowIds(prev => [...prev, key]);
  };

  const onResetOtherParam = useCallback((key: string) => {
    if (key === 'newEntity') {
      return setAddedRows([]);
    }
    setEditingRowIds(prev => prev.filter(v => v !== key));
  }, []);

  const onSubmitOtherParam = (key: string) => {
    let submitData = {} as OtherParam;

    const isNewEntity = key === 'newEntity';

    if (isNewEntity) {
      const [addedData] = addedRows;
      submitData = { ...addedData };
    } else {
      const editedData = editingMap.current[key] ?? {};
      const restData = items.find(v => v.name === key);
      submitData = { ...restData, ...editedData } as OtherParam;
    }

    const isValid = !Object.values(submitData).every(
      v => isNil(v) || isEmpty(v.toString())
    );

    if (!isValid) {
      return;
    }
    const isDuplicateName =
      isNewEntity && items.some(item => item.name === submitData.name);

    if (isDuplicateName) {
      dispatch(
        alertsUtil.openErrorDialog(
          dialogTypes.CATCH_ERROR_DUPLICATE_ENTRY,
          'This other key already exists, please edit that param or choose a new name'
        )
      );
      return;
    }

    upsertExperimentOtherMutation.mutate(
      {
        experimentKey,
        name: submitData.name,
        value: submitData.valueCurrent
      },
      {
        onSuccess: () => onResetOtherParam(key)
      }
    );
  };

  const onDeleteOtherParam = (name: string) => {
    setDeleteModalState({
      opened: true,
      paramKey: name
    });
  };

  const isFetching =
    upsertExperimentOtherMutation.isLoading ||
    removeExperimentOtherMutation.isLoading;

  const deleteModalConfig: DeleteOtherParamModalProps = {
    onConfirm: () => {
      removeExperimentOtherMutation.mutate(
        {
          experimentKey,
          name: deleteModalState.paramKey
        },
        {
          onSuccess: () => {
            setDeleteModalState({
              opened: false,
              paramKey: ''
            });
          }
        }
      );
    },
    onClose: () => {
      setDeleteModalState({
        opened: false,
        paramKey: ''
      });
    },
    isOpened: deleteModalState.opened,
    paramKey: deleteModalState.paramKey
  };

  return {
    rowList,
    editingRowIds,
    addedRows,
    onAddOtherParam,
    changeAddedOtherParams,
    onEditOtherParams,
    changeEditedOtherParams,
    onResetOtherParam,
    onSubmitOtherParam,
    onDeleteOtherParam,
    isFetching,
    deleteModalConfig
  };
};

export default useOtherSingleTableAPI;
